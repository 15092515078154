import { useCallback } from 'react';

import LogRocket from 'logrocket';
import { useSnackbar } from 'notistack';

import { useOrgAtsService } from 'src/hooks/atsService';
import useAuth from 'src/hooks/useAuth';

import { enviromentConfig } from 'src/config';

/**
 * This hook is used to copy the debugging information to the clipboard
 * It is used in the DashboardLayout component
 */
const useCopyDebugInfo = () => {
  const { userInfo } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const atsService = useOrgAtsService();

  const text = `DEBUG INFO
============
• ENV: ${enviromentConfig.environment}
• URL: ${window.location.href}
• ORG_NAME: ${userInfo?.orgName}
• ORG_ID: ${userInfo?.orgId}
• ATS_NAME: ${atsService?.name}
• EMP_NAME: ${userInfo?.employeeName}
• EMP_ID: ${userInfo?.employeeId}
• LOG_ROCKET: ${LogRocket.sessionURL}`;

  const handleCopyDebugInfo = useCallback(async (): Promise<void> => {
    await navigator.clipboard.writeText(text);
    enqueueSnackbar('Debugging information copied', {
      variant: 'success',
    });
  }, [enqueueSnackbar, text]);

  return handleCopyDebugInfo;
};

export default useCopyDebugInfo;
