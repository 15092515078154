import { SvgIcon, SvgIconProps } from './SvgIcon';

export const UserSplit = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.25C10.4142 4.25 10.75 4.58579 10.75 5L10.75 15C10.75 15.4142 10.4142 15.75 10 15.75C9.58579 15.75 9.25 15.4142 9.25 15L9.25 5C9.25 4.58579 9.58579 4.25 10 4.25ZM4.87331 7C4.04488 7 3.37331 7.67157 3.37331 8.5C3.37331 9.32843 4.04488 10 4.87331 10C5.70173 10 6.37331 9.32843 6.37331 8.5C6.37331 7.67157 5.70173 7 4.87331 7ZM6.60056 10.9688C7.11437 11.2318 7.55175 11.6509 7.71911 12.246C7.79353 12.5106 7.70991 12.7603 7.553 12.9304C7.40192 13.0942 7.18349 13.1875 6.95616 13.1875L2.79046 13.1875C2.56313 13.1875 2.3447 13.0942 2.19362 12.9304C2.03671 12.7603 1.95309 12.5106 2.02751 12.246C2.19487 11.651 2.63225 11.2318 3.14605 10.9688C3.65824 10.7066 4.27335 10.5833 4.87331 10.5833C5.47327 10.5833 6.08838 10.7066 6.60056 10.9688ZM13.6233 8.5C13.6233 7.67157 14.2949 7 15.1233 7C15.9517 7 16.6233 7.67157 16.6233 8.5C16.6233 9.32843 15.9517 10 15.1233 10C14.2949 10 13.6233 9.32843 13.6233 8.5ZM17.9691 12.246C17.8018 11.6509 17.3644 11.2318 16.8506 10.9688C16.3384 10.7066 15.7233 10.5833 15.1233 10.5833C14.5233 10.5833 13.9082 10.7066 13.3961 10.9688C12.8822 11.2318 12.4449 11.651 12.2775 12.246C12.2031 12.5106 12.2867 12.7603 12.4436 12.9304C12.5947 13.0942 12.8131 13.1875 13.0405 13.1875L17.2062 13.1875C17.4335 13.1875 17.6519 13.0942 17.803 12.9304C17.9599 12.7603 18.0435 12.5106 17.9691 12.246Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
