import React from 'react';

import BaseIcon, { Props } from './Icon';

const CompletedInterviewIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 2C7.75 1.58579 7.41422 1.25 7 1.25C6.58579 1.25 6.25 1.58579 6.25 2V3.25H5C3.48122 3.25 2.25 4.48122 2.25 6L2.25002 15C2.25002 16.5188 3.48124 17.75 5.00002 17.75H8C8.41421 17.75 8.75 17.4142 8.75 17C8.75 16.5858 8.41421 16.25 8 16.25H5.00002C4.30966 16.25 3.75002 15.6904 3.75002 15L3.75 6C3.75 5.30965 4.30965 4.75 5 4.75H6.25V5C6.25 5.41421 6.58579 5.75 7 5.75C7.41422 5.75 7.75 5.41421 7.75 5V4.75H12.25V5C12.25 5.41421 12.5858 5.75 13 5.75C13.4142 5.75 13.75 5.41421 13.75 5V4.75H15C15.6903 4.75 16.25 5.30964 16.25 6L16.25 10C16.25 10.4142 16.5858 10.75 17 10.75C17.4142 10.75 17.75 10.4142 17.75 10L17.75 5.99999C17.75 4.48121 16.5188 3.25 15 3.25H13.75V2C13.75 1.58579 13.4142 1.25 13 1.25C12.5858 1.25 12.25 1.58579 12.25 2V3.25H7.75V2ZM5.25 9C5.25 8.0335 6.0335 7.25 7 7.25H13C13.9665 7.25 14.75 8.0335 14.75 9V11C14.75 11.9665 13.9665 12.75 13 12.75H7C6.0335 12.75 5.25 11.9665 5.25 11V9ZM7 8.75C6.86193 8.75 6.75 8.86193 6.75 9V11C6.75 11.1381 6.86193 11.25 7 11.25H13C13.1381 11.25 13.25 11.1381 13.25 11V9C13.25 8.86193 13.1381 8.75 13 8.75H7ZM17.9804 13.5759C18.2985 13.3106 18.3413 12.8377 18.0759 12.5196C17.8106 12.2015 17.3377 12.1587 17.0196 12.4241C15.4999 13.6916 14.5713 14.6375 13.2618 16.2818C12.7153 15.6195 12.2264 15.1493 11.4818 14.5252C11.1643 14.2591 10.6913 14.3008 10.4252 14.6182C10.1591 14.9357 10.2008 15.4087 10.5182 15.6748C11.4316 16.4403 11.8903 16.9167 12.6597 17.9484C12.7999 18.1365 13.0202 18.2481 13.2549 18.25C13.4895 18.2519 13.7115 18.1438 13.8548 17.9579C15.4501 15.8888 16.341 14.9434 17.9804 13.5759Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CompletedInterviewIcon;
