import { useMemo } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { cloneDeep } from 'lodash';

import { InterviewType, UsePopulateEventData_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';

type Fragments = {
  interviewPlan: UsePopulateEventData_InterviewPlanFragment | undefined;
};

type Props = {
  scheduleId: string | null;
};

const usePopulateEventData: FunctionWithFragments<Fragments, Props, InterviewSchedule | null> = (
  { interviewPlan },
  { scheduleId }
) => {
  const schedule = useScheduleWithoutBreaks(scheduleId);

  const interviews = useMemo(() => {
    return (
      interviewPlan?.jobStageInterviewGroups
        ?.map(
          (group) =>
            group.jobStageInterviews?.filter((i) =>
              Boolean(i.interviewType !== InterviewType.Break && i.interviewType !== InterviewType.DayDivider)
            ) || []
        )
        .flat() || []
    );
  }, [interviewPlan?.jobStageInterviewGroups]);

  return useMemo(() => {
    if (!schedule) return null;

    schedule.events = schedule.events.map((event) => {
      const interview = interviews.find((i) => i.id === event.slotId);

      const eventClone = cloneDeep(event);

      if (interview && !eventClone.atsInterviewDefinitionId) {
        eventClone.atsInterviewDefinitionId = interview.atsInterviewDefinition?.atsId;
      }

      return eventClone;
    });

    return schedule;
  }, [interviews, schedule]);
};

usePopulateEventData.fragments = {
  interviewPlan: gql`
    fragment usePopulateEventData_interviewPlan on JobStage {
      id
      jobStageInterviewGroups {
        id
        jobStageInterviews {
          id
          interviewType
          atsInterviewDefinition {
            atsId
          }
        }
      }
    }
  `,
};

export default usePopulateEventData;
