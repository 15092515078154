import React from 'react';

import { TrashIcon } from '@modernloop/shared/icons';
import { Box, IconButton, Stack } from '@mui/material';
import { parseISO } from 'date-fns';

import { DateTimeRangeInput } from 'src/generated/mloop-graphql';

import DateTimeRangePicker from 'src/components/date-time-picker/DateTimeRangePicker';

interface Props {
  availability: DateTimeRangeInput;
  timezone: string;
  onChange: (start: string, end: string) => void;
  onDelete: () => void;
}

export const AvailabilityContent: React.FC<Props> = ({ availability, timezone, onChange, onDelete }) => {
  return (
    <Stack
      data-testid="candidate-details-add-candidate-availability-time-range"
      key={`${availability.startAt}-${availability.endAt}`}
      direction="row"
      gap={0.5}
    >
      <Box display="flex">
        <DateTimeRangePicker
          coalesceTimeUpdates={false}
          value={{ start: parseISO(availability.startAt), end: parseISO(availability.endAt) }}
          timezone={timezone}
          onChange={onChange}
          hideClockIcons
        />
      </Box>
      <Box display="flex">
        <IconButton onClick={onDelete}>
          <TrashIcon />
        </IconButton>
      </Box>
    </Stack>
  );
};
