import { useMemo } from 'react';

import { gql } from '@apollo/client';

import {
  Permission,
  TaskDefaultAssignee,
  useCoordinatorRecruiterUserInfosQuery,
  useDefaultTaskAssigneeQuery,
} from 'src/generated/mloop-graphql';

import { OrgPrefName } from 'src/utils/api/org';

/**
 * This is a reusable hook that fetches the default task assignee based on the org preference.
 * @param applicationId - the current application
 * @param prefName - the org pref to use
 * @returns - the default assignee for the given application and org pref
 */
const useDefaultTaskAssignee = (applicationId: string, prefName: OrgPrefName) => {
  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useDefaultTaskAssigneeQuery({
    variables: {
      applicationId,
      prefName,
    },
  });

  const userIdsToFetch = useMemo(() => {
    return [appData?.application?.candidate?.coordinator?.id, appData?.application?.candidate?.recruiter?.id].filter(
      Boolean
    ) as string[];
  }, [appData]);

  const {
    data: userInfosData,
    loading: uiLoading,
    error: uiError,
  } = useCoordinatorRecruiterUserInfosQuery({
    variables: {
      ids: userIdsToFetch,
    },
    skip: userIdsToFetch.length === 0,
  });

  const assignee = useMemo(() => {
    if (!appData) {
      return null;
    }

    const pref = appData?.thisOrg?.orgPref?.stringValue;

    // If the org pref is set to candidate recruiter, return recruiter if they are a user with scheduling ability in ModernLoop
    if (pref === TaskDefaultAssignee.CandidateRecruiter) {
      const recruiterId = appData?.application?.candidate?.recruiter?.id;
      const recruiterUser = userInfosData?.userInfoByEmployeeIds?.find(
        (userInfo) => userInfo.employeeId === recruiterId
      );
      const recruiterPermissions = recruiterUser?.permissions ?? [];
      const recruiterCanSchedule = recruiterPermissions.includes(Permission.CanSchedule);

      if (recruiterCanSchedule) {
        return appData?.application?.candidate?.recruiter;
      }

      return null;
    }

    // If the org pref is set to candidate coordinator, return coordinator if they are a user with scheduling ability in ModernLoop
    if (pref === TaskDefaultAssignee.CandidateCoordinator) {
      const coordinatorId = appData?.application?.candidate?.coordinator?.id;
      const coordinatorUser = userInfosData?.userInfoByEmployeeIds?.find(
        (userInfo) => userInfo.employeeId === coordinatorId
      );
      const coordinatorPermissions = coordinatorUser?.permissions ?? [];
      const coordinatorCanSchedule = coordinatorPermissions.includes(Permission.CanSchedule);

      if (coordinatorCanSchedule) {
        return appData?.application?.candidate?.coordinator;
      }

      return null;
    }

    if (pref === TaskDefaultAssignee.TaskCreator) {
      return appData?.thisEmployee;
    }

    return null;
  }, [appData, userInfosData]);

  return {
    assignee,
    loading: appLoading || uiLoading,
    error: appError || uiError,
  };
};

export const DEFAULT_TASK_ASSIGNEE_QUERY = gql`
  query DefaultTaskAssignee($applicationId: uuid!, $prefName: String!) {
    application(id: $applicationId) {
      candidate {
        coordinator {
          id
          fullName
          slackImageUrl
        }
        recruiter {
          id
          fullName
          slackImageUrl
        }
      }
    }
    thisOrg {
      id
      orgPref(prefName: $prefName) {
        id
        orgId
        prefName
        stringValue
      }
    }
    thisEmployee {
      id
      fullName
      slackImageUrl
    }
  }
`;

export const COORDINATOR_RECRUITER_USER_INFOS_QUERY = gql`
  query CoordinatorRecruiterUserInfos($ids: [uuid!]!) {
    userInfoByEmployeeIds(ids: $ids) {
      userId
      employeeId
      permissions
    }
  }
`;

export default useDefaultTaskAssignee;
