import React, { FC, useState } from 'react';

import { ButtonOptions, Dialog } from '@modernloop/shared/components';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';

import useDoNotShowAgainModalEmployeePref from 'src/hooks/useDoNotShowAgainModalsEmployeePref';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { DoNotShowAgainDialogTypes } from 'src/utils/api/employee/constants';

type Props = {
  doNotShowAgainKey: DoNotShowAgainDialogTypes;
  onClose: () => void;
  isOpen: boolean;
  decoration?: JSX.Element;
  cancelOptions?: ButtonOptions;
  title?: string;
  subTitle?: string;

  // added a boolean parameter to the onClick function to let the parent know if the checkbox is checked
  submitOptions?: Omit<ButtonOptions, 'onClick'> & { onClick: (doNotShowChecked?: boolean) => void };
};

const DoNotShowAgainDialog: FC<Props> = ({
  doNotShowAgainKey,
  decoration,
  onClose,
  isOpen,
  cancelOptions,
  submitOptions,
  title,
  subTitle,
  children,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { addToDoNotShowAgainList, showModal, loading } = useDoNotShowAgainModalEmployeePref(doNotShowAgainKey);

  const updatedSubmitOptions = {
    ...submitOptions,
    onClick: () => {
      if (isChecked) {
        addToDoNotShowAgainList(doNotShowAgainKey);
      }
      submitOptions?.onClick(isChecked);
    },
  };

  // calls onSubmit directly if the modal is not shown
  if (!showModal && !loading) {
    updatedSubmitOptions.onClick();
  }

  if (!showModal || loading) {
    return null;
  }

  return (
    <ConditionalThemeProvider>
      <Dialog
        decoration={decoration}
        title={title}
        subTitle={subTitle}
        open={isOpen}
        onClose={onClose}
        cancelOptions={cancelOptions}
        submitOptions={updatedSubmitOptions}
      >
        <Stack>
          {children}
          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />}
            label="Don’t show me this again"
            onChange={() => setIsChecked(!isChecked)}
          />
        </Stack>
      </Dialog>
    </ConditionalThemeProvider>
  );
};

export default DoNotShowAgainDialog;
