import { useMemo } from 'react';

import { QueryResult, gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';

import { RenderType, TemplateToken, useJobTokensQuery } from 'src/generated/mloop-graphql';

import { EMPTY_OBJECT } from 'src/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import placeholders from './utils/placeholders';

/**
 * Todo after org_use_application_posting_job_name is removed delete externalJobNameJobId
 */
export const JobTokensQueryDoc = gql`
  query JobTokens(
    $id: String
    $renderType: RenderType!
    $jobId: uuid!
    $jobStageId: uuid!
    $applicationId: uuid
    $externalJobNameJobId: uuid
  ) {
    templateToken(input: { id: $id, renderType: $renderType }) {
      id
      JOB_NAME(input: { id: $jobId })
      JOB_LOCATION(input: { id: $jobId })
      JOB_STAGE_NAME(input: { id: $jobStageId })
      EXTERNAL_JOB_NAME(input: { id: $externalJobNameJobId, applicationId: $applicationId })
    }
  }
`;

/**
 * TOKENS TO DISPLAY
 * Note Only add if eligible for display
 * */
export enum Tokens {
  JOB_NAME = 'JOB_NAME',
  JOB_STAGE_NAME = 'JOB_STAGE_NAME',
  JOB_LOCATION = 'JOB_LOCATION',
  EXTERNAL_JOB_NAME = 'EXTERNAL_JOB_NAME',
}

/**
 * TOKENS FETCHED
 * Tokens displayed are a subset of the tokens fetched
 */
export type JobPlaceholderFillerOptions = Pick<TemplateToken, keyof typeof Tokens>;

export default class JobPlaceholderFiller {
  static getFilledText = (input: string, options: JobPlaceholderFillerOptions): string => {
    return placeholders(input, options);
  };
}

export type Input = {
  renderType?: RenderType;
  jobId: string | undefined;
  jobStageId: string;
  applicationId: string;
  isFetch?: boolean;
};

export const useJobPlaceholderFiller = ({
  renderType,
  jobId,
  jobStageId,
  isFetch = true,
  applicationId,
}: Input): [JobPlaceholderFillerOptions, Pick<QueryResult, 'data' | 'error' | 'loading'>] => {
  const isApplicationPostingJobName = useFlag('org_use_application_posting_job_name');

  const {
    data: jobTokensData,
    loading: jobTokensLoading,
    error: jobTokensError,
  } = useJobTokensQuery({
    skip: !isFetch || !jobId,
    variables: {
      renderType: renderType || RenderType.Html,
      id: jobId,
      jobId,
      jobStageId,
      applicationId: isApplicationPostingJobName ? applicationId : undefined,
      externalJobNameJobId: !isApplicationPostingJobName ? jobId : undefined,
    },
    errorPolicy: 'all',
  });

  const jobPlaceholderFillerOptionsNew = useMemo(() => {
    if (!jobTokensData) return EMPTY_OBJECT;

    const { templateToken } = jobTokensData;

    const placeholderFiller: JobPlaceholderFillerOptions = {
      JOB_NAME: templateToken?.JOB_NAME || undefined,
      JOB_STAGE_NAME: templateToken?.JOB_STAGE_NAME || undefined,
      JOB_LOCATION: templateToken?.JOB_LOCATION ?? undefined,
      EXTERNAL_JOB_NAME: templateToken?.EXTERNAL_JOB_NAME ?? undefined,
    };

    return placeholderFiller;
  }, [jobTokensData]);

  return useMemo(() => {
    return [jobPlaceholderFillerOptionsNew, { data: jobTokensData, error: jobTokensError, loading: jobTokensLoading }];
  }, [jobPlaceholderFillerOptionsNew, jobTokensData, jobTokensError, jobTokensLoading]);
};
