import React from 'react';

import BaseIcon, { Props } from './Icon';

const DebriefIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 10C3.75 6.54822 6.54822 3.75 10 3.75C13.4518 3.75 16.25 6.54822 16.25 10C16.25 10.9007 16.0599 11.755 15.7185 12.5266C15.6616 12.6551 15.642 12.797 15.6618 12.9362L16.069 15.7861C16.0925 15.9511 15.9511 16.0925 15.7861 16.069L12.9362 15.6618C12.797 15.642 12.6551 15.6616 12.5266 15.7185C11.755 16.0599 10.9007 16.25 10 16.25C6.54822 16.25 3.75 13.4518 3.75 10ZM10 2.25C5.71979 2.25 2.25 5.71979 2.25 10C2.25 14.2802 5.71979 17.75 10 17.75C11.0359 17.75 12.026 17.5464 12.9311 17.1763L15.574 17.5539C16.7289 17.7189 17.7189 16.7289 17.5539 15.574L17.1763 12.9311C17.5464 12.026 17.75 11.0359 17.75 10C17.75 5.71979 14.2802 2.25 10 2.25ZM7 11C7.55228 11 8 10.5523 8 10C8 9.44772 7.55228 9 7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11ZM11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10ZM13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DebriefIcon;
