import { assertIsoTimestamp, isBeforeNow } from '@modernloop/shared/datetime';

import { OrgPrefName, useOrgPrefJson_DEPRECATED } from './api/org';

/**
 * Hook to check if the candidate portal is enabled.
 * Use the premium candidate portal pref's timestamp to check if premium portal options should be available
 * @returns boolean
 */
export const useIsPremiumCandidatePortalEnabled = (): [
  isPremiumCandidatePortalEnabled: boolean,
  loadingPref: boolean
] => {
  // new flag for timestamp-based premium feature control
  const [newPremiumCandidatePortalPref, { loading }] = useOrgPrefJson_DEPRECATED<{ expireAt: string }>(
    OrgPrefName.OBJ_PREMIUM_CANDIDATE_PORTAL
  );

  if (loading) {
    return [false, true];
  }

  // if there's no value set, premium features are not enabled
  if (!newPremiumCandidatePortalPref?.expireAt) {
    return [false, false];
  }
  // if the timestamp is in the past, premium features are not enabled
  if (isBeforeNow(assertIsoTimestamp(newPremiumCandidatePortalPref?.expireAt))) {
    return [false, false];
  }

  return [true, false];
};
