import React, { FC } from 'react';

import Label, { LabelProps } from 'src/components/label';
import Tooltip from 'src/components/tooltip';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ActivityLogTextProps = LabelProps & {
  tooltip?: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const ActivityLogText: FC<ActivityLogTextProps> = (props) => {
  const {
    children,
    tooltip,
    fontWeight = 400,
    component = 'span',
    variant = 'captions',
    color = 'high-contrast-grey',
    ...rest
  } = props;
  return (
    <Label variant={variant} component={component} fontWeight={fontWeight} color={color} {...rest}>
      {!tooltip && children}
      {tooltip && <Tooltip tooltip={tooltip}>{children}</Tooltip>}
    </Label>
  );
};

export default ActivityLogText;
