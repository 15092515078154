import { useCallback } from 'react';

import { gql } from '@apollo/client';
import { max, min } from 'lodash';

import {
  InterviewMeetingLocationType,
  MeetingLocationCreateInput,
  useVideoInterviewMeetingLocationCreateMutation,
} from 'src/generated/mloop-graphql';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import {
  updateInterviewMeetingLocationId,
  updateVideoMeetingHostEmployeeIdForSlotId,
  updateVideoMeetingLink,
  updateVideoMeetingLinkForSlotId,
  updateVideoMeetingLinkHostEmployeeId,
  updateinterviewMeetingLocationIdBySlotId,
} from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import { useDispatch } from 'src/store';

type Props = {
  scheduleId: string;
  interviewMeetingLocationType?: InterviewMeetingLocationType;
};

const usePopulateVideoInterviewMeetingLocation = ({ scheduleId, interviewMeetingLocationType }: Props) => {
  const dispatch = useDispatch();
  const schedule = useScheduleWithoutBreaks(scheduleId);

  const [interviewMeetingLocationCreateMutation] = useVideoInterviewMeetingLocationCreateMutation();

  const callback = useCallback(
    async ({ hostEmployeeId, slotId }: { hostEmployeeId: string; slotId?: string }) => {
      if (!interviewMeetingLocationType) return;

      // If slotId is provided, we are creating a meeting for a specific interview
      if (slotId) {
        const interviewEvent = schedule?.events.find((event) => event.slotId === slotId);
        if (!interviewEvent) return;

        const meetingLocationCreateInput: MeetingLocationCreateInput = {
          locationType: interviewMeetingLocationType,
          videoMeeting: {
            timeRange: { startAt: interviewEvent.startAt, endAt: interviewEvent.endAt },
            title: interviewEvent.name,
            hostEmployeeId,
          },
        };

        const { data } = await interviewMeetingLocationCreateMutation({
          variables: {
            input: meetingLocationCreateInput,
          },
        });

        if (data?.meetingLocationCreate?.meetingLocation?.videoMeetingLink) {
          const { meetingLocation } = data.meetingLocationCreate;

          dispatch(
            updateinterviewMeetingLocationIdBySlotId({ scheduleId, slotId, meetingLocationId: meetingLocation.id })
          );

          if (hostEmployeeId) {
            dispatch(
              updateVideoMeetingHostEmployeeIdForSlotId({
                scheduleId,
                slotId,
                videoMeetingHostEmployeeId: hostEmployeeId,
              })
            );
          }

          if (meetingLocation.videoMeetingLink) {
            dispatch(
              updateVideoMeetingLinkForSlotId({
                scheduleId,
                slotId,
                videoMeetingUrl: meetingLocation.videoMeetingLink,
              })
            );
          }

          dispatch(
            addInterviewScheduleUpdate({
              type: 'ScheduleUpdateVideoUrl',
              updateType: ScheduleUpdateType.EDIT,
              scheduleId,
              applicationStageInterviewId: interviewEvent.id,
            })
          );
        }
      } else if (schedule) {
        // If slotId is not provided, we are creating a meeting for the whole schedule
        const startAt = min(schedule.events.map((event) => event.startAt));
        const endAt = max(schedule.events.map((event) => event.endAt));

        const meetingLocationCreateInput: MeetingLocationCreateInput = {
          locationType: interviewMeetingLocationType,
          videoMeeting: { timeRange: { startAt, endAt }, hostEmployeeId },
        };

        const { data } = await interviewMeetingLocationCreateMutation({
          variables: {
            input: meetingLocationCreateInput,
          },
        });

        const meetingLocation = data?.meetingLocationCreate?.meetingLocation;

        if (meetingLocation?.id) {
          dispatch(updateInterviewMeetingLocationId(scheduleId, meetingLocation.id));
        }

        if (hostEmployeeId) {
          dispatch(updateVideoMeetingLinkHostEmployeeId(scheduleId, hostEmployeeId));
        }

        if (data?.meetingLocationCreate?.meetingLocation?.videoMeetingLink) {
          dispatch(updateVideoMeetingLink(scheduleId, data.meetingLocationCreate.meetingLocation.videoMeetingLink));
        }

        schedule?.events?.forEach((interview) => {
          dispatch(
            addInterviewScheduleUpdate({
              type: 'ScheduleUpdateVideoUrl',
              updateType: ScheduleUpdateType.EDIT,
              scheduleId,
              applicationStageInterviewId: interview.id,
            })
          );
        });
      }
    },
    [dispatch, interviewMeetingLocationCreateMutation, interviewMeetingLocationType, schedule, scheduleId]
  );

  return callback;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InterviewMeetingLocationCreateMutation = gql`
  mutation VideoInterviewMeetingLocationCreate($input: MeetingLocationCreateInput!) {
    meetingLocationCreate(input: $input) {
      meetingLocation {
        id
        type
        videoMeetingLink
      }
    }
  }
`;

export default usePopulateVideoInterviewMeetingLocation;
