export const red = {
  0: '#000000',
  10: '#410002',
  20: '#690005',
  25: '#7E0007',
  30: '#93000A',
  35: '#A80710',
  40: '#BA1A1A',
  50: '#DE3730',
  60: '#FF5449',
  70: '#FF897D',
  80: '#FFB4AB',
  90: '#FFDAD6',
  95: '#FFEDEA',
  98: '#FFF8F7',
  99: '#FFFBFF',
  100: '#FFFFFF',
};
