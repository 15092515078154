import React, { useCallback, useMemo } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { TextField } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { addDays } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import Autocomplete from 'src/components/Autocomplete';
import VirtualList from 'src/components/VirtualList';
import TimeIcon from 'src/components/icons/Time';
import Label from 'src/components/label';
import { MenuOption } from 'src/components/menu';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/theme/type';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface TimePickerProps extends BaseProps {
  /**
   * Default time to initialize the date time picker.
   */
  utcTime: Date;

  /**
   * Should be passed when the TimePicker is used as end time part of TimeRange
   */
  timeRangeUtcStartOfDayForEndTimeSelector?: Date;

  timezone: string;

  onChange: (time: Date) => void;

  forceIcon?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  overrideAutoCompleteClasses?: any;

  /**
   * Icon to display to the left of the input field
   */
  startAdornment?: React.ReactNode;

  error?: boolean;

  disabled?: boolean;
}

const TIME_OPTIONS: MenuOption[] = [];

for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 60; j += 1) {
    const dMinutes = j.toString().length === 2 ? j.toString() : `0${j.toString()}`;
    const meridiem = i < 12 ? ' am' : ' pm';
    TIME_OPTIONS.push({
      id: `${i < 10 ? '0' : ''}${i}:${dMinutes}`, // formaat '01:00, 12:30, 16:00
      value: `${i % 12 === 0 ? '12' : i % 12}:${dMinutes}${meridiem}`,
    });
  }
}

const useAutoCompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ error }: { error?: boolean }) => ({
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${error ? theme.palette.error.light : theme.palette.border}`,
      borderRadius: '6px',
      padding: '2px 8px',
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '&:hover .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '&:hover .MuiInput-underline:after': {
        border: 'none',
      },
    }),
    endAdornment: {
      top: 'auto',
    },
    input: {
      fontSize: '15px',
      lineHeight: '22px',
    },
    popupIndicatorOpen: {
      transform: 'rotate(0deg)',
    },
    paper: {
      borderRadius: '6px',
    },
    listbox: {
      '& ul': {
        margin: 0,
      },
    },
    option: {
      minHeight: 'auto',
    },
    popper: {
      minWidth: 120,
    },
  })
);

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      margin: '0 auto',
    },
  })
);

// If characters of searchStr is present in input in order then returns true
const fuzzySearch = (input: string, searchStr: string): boolean => {
  let index = -1;
  for (let i = 0; i < searchStr.length; i++) {
    index = input.indexOf(searchStr.charAt(i), index + 1);
    if (index === -1) {
      return false;
    }
  }

  return true;
};

const TimePicker = ({
  dataTestId,
  utcTime,
  timeRangeUtcStartOfDayForEndTimeSelector,
  timezone,
  onChange,
  forceIcon = false,
  overrideAutoCompleteClasses = null,
  startAdornment,
  error,
  disabled,
}: TimePickerProps): JSX.Element => {
  const classes = useStyles();

  let autoCompleteClasses = useAutoCompleteStyles({ error });
  if (overrideAutoCompleteClasses) {
    autoCompleteClasses = overrideAutoCompleteClasses;
  }
  // TODO: Figure out a better behaviour here, maybe not render.
  const nonEmptyUTCTime = useMemo(() => {
    return utcTime ? new Date(utcTime) : new Date().toISOString();
  }, [utcTime]);
  const time = utcToZonedTime(nonEmptyUTCTime, timezone);
  const defaultTime = `${time.getHours() < 10 ? '0' : ''}${time.getHours()}:${
    time.getMinutes() < 10 ? '0' : ''
  }${time.getMinutes()}`;

  const defaultSelectedValue = TIME_OPTIONS.find((option) => option.id === defaultTime);

  const filterOptions = (options: MenuOption[], state: FilterOptionsState<MenuOption>) => {
    const { inputValue } = state;

    const sort = (a: string, b: string) => {
      const aHour = parseInt(a.split(':')[0], 10);
      const bHour = parseInt(b.split(':')[0], 10);
      return aHour - bHour;
    };

    // If no input
    if (!inputValue) {
      const minutes = time.getMinutes();
      const filterOption = (option: MenuOption, baseMins: number) => {
        if (!option.value) return false;
        const value = option.value.split(':')[1].slice(0, 2);
        const valueMin = parseInt(value, 10);
        return valueMin % baseMins === 0;
      };

      if (minutes % 30 === 0) {
        return options.filter((option) => filterOption(option, 30));
      }

      if (minutes % 15 === 0) {
        return options.filter((option) => filterOption(option, 15));
      }

      if (minutes % 10 === 0) {
        return options.filter((option) => filterOption(option, 10));
      }

      if (minutes % 5 === 0) {
        return options.filter((option) => filterOption(option, 5));
      }

      // Return all options if none of the above conditions are met.
      // E.g. user has input time as 3:41am
      return options;
    }

    // Show times in 30 mins increment and filter based on input value
    // - If 1 character
    // - If 2 character and second character is 'a' or 'p'
    // - If 2 characters and second character is number (here we are assuming first character is also a number)
    if (
      inputValue.length === 1 ||
      (inputValue.length === 2 && (inputValue.charAt(1) === 'a' || inputValue.charAt(1) === 'p')) ||
      (inputValue.length === 2 && !Number.isNaN(parseInt(inputValue.charAt(1), 10)))
    ) {
      const results = options
        .filter((option) => option.value && (option.value.indexOf(':00') !== -1 || option.value.indexOf(':30') !== -1))
        .filter((option) => option.value && fuzzySearch(option.value, inputValue))
        .filter((option) => option.value && option.value.charAt(0) === inputValue.charAt(0))
        .sort((a, b) => (a.value && b.value ? sort(a.value, b.value) : 0));

      if (results.length) return results;
    }

    // inputString like 3am
    if (
      inputValue.length === 3 &&
      Number.isNaN(parseInt(inputValue.charAt(1), 10)) &&
      Number.isNaN(parseInt(inputValue.charAt(2), 10))
    ) {
      const results = options
        .filter((option) => option.value && option.value.indexOf(':00') !== -1)
        .filter((option) => option.value && fuzzySearch(option.value, inputValue))
        .filter((option) => option.value && option.value.charAt(0) === inputValue.charAt(0))
        .sort((a, b) => (a.value && b.value ? sort(a.value, b.value) : 0));

      if (results.length) return results;
    }

    if (inputValue.length === 3 && Number.isNaN(parseInt(inputValue.charAt(2), 10))) {
      const results = options
        .filter((option) => option.value && option.value.indexOf(':00') !== -1)
        .filter((option) => option.value?.startsWith(inputValue.slice(0, 2)))
        .filter((option) => option.value && fuzzySearch(option.value, inputValue))
        .sort((a, b) => (a.value && b.value ? sort(a.value, b.value) : 0));

      if (results.length) return results;
    }

    if (
      inputValue.length === 4 &&
      Number.isNaN(parseInt(inputValue.charAt(2), 10)) &&
      Number.isNaN(parseInt(inputValue.charAt(3), 10))
    ) {
      const results = options
        .filter((option) => option.value && option.value.indexOf(':00') !== -1)
        .filter((option) => option.value && fuzzySearch(option.value, inputValue))
        .filter((option) => option.value && option.value.charAt(0) === inputValue.charAt(0))
        .sort((a, b) => (a.value && b.value ? sort(a.value, b.value) : 0));

      if (results.length) return results;
    }

    // If none of the above condition are true then filter on input value.
    // This will show options with 1 min increment.
    return options
      .filter((option) => option.value && fuzzySearch(option.value, inputValue))
      .filter((option) => option.value && option.value.charAt(0) === inputValue.charAt(0))
      .sort((a, b) => (a.value && b.value ? sort(a.value, b.value) : 0));
  };

  const updateTime = useCallback(
    (value: string) => {
      if (!value) return;

      let newTime = utcToZonedTime(nonEmptyUTCTime, timezone);

      // If timeRangeUtcStartOfDayForEndTimeSelector !== null then always use it as the base time.
      if (timeRangeUtcStartOfDayForEndTimeSelector) {
        if (value === '00:00') {
          newTime = addDays(utcToZonedTime(timeRangeUtcStartOfDayForEndTimeSelector, timezone), 1);
        } else {
          newTime = utcToZonedTime(timeRangeUtcStartOfDayForEndTimeSelector, timezone);
        }
      }

      const hoursMinutes = value.split(':');
      newTime.setHours(parseInt(hoursMinutes[0], 10));
      newTime.setMinutes(parseInt(hoursMinutes[1], 10));
      newTime.setSeconds(0);

      const newUtcTime = zonedTimeToUtc(newTime, timezone);

      onChange(newUtcTime);
    },
    [nonEmptyUTCTime, timezone, timeRangeUtcStartOfDayForEndTimeSelector, onChange]
  );

  const validateTime = (value: string) => {
    let isValid = /^((1[0-2]|0?[1-9]):([0-5][0-9])([AaPp][Mm]))$/.test(value);
    isValid = isValid || /^0[0-9]:([0-5][0-9])$/.test(value);
    return isValid;
  };

  const handleChange = (event: React.ChangeEvent, value: MenuOption) => {
    if (value.value) {
      validateTime(value.value);
    }
    updateTime(value.id as string);
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (validateTime(event.target.value)) {
        const timeOption = TIME_OPTIONS.find((option) => option.value === event.target.value);
        if (!timeOption) return;
        updateTime(timeOption.id as string);
      }
    },
    [updateTime]
  );
  return (
    <Autocomplete
      disabled={disabled}
      data-testid={dataTestId}
      autoHighlight
      freeSolo
      forcePopupIcon={forceIcon}
      fullWidth
      disableClearable
      openOnFocus
      clearOnBlur
      error={error}
      value={defaultSelectedValue}
      popupIcon={<TimeIcon />}
      disableListWrap
      classes={autoCompleteClasses}
      ListboxComponent={VirtualList as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      options={TIME_OPTIONS}
      getOptionLabel={(option) => option.value ?? ''}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            onBlur={handleBlur}
            onFocus={handleFocus}
            InputProps={{ ...params.InputProps, startAdornment }}
          />
        );
      }}
      renderOption={(option) => (
        <Label variant="body" className={classes.label}>
          {option.value}
        </Label>
      )}
      onChange={handleChange}
      filterOptions={filterOptions}
    />
  );
};

export default TimePicker;
