import React from 'react';

import { Dialog } from '@modernloop/shared/components';
import { assertIsoTimestamp, formatTimeRange } from '@modernloop/shared/datetime';
import { Alert, AlertTitle, Box, List, ListItem } from '@mui/material';

import useDisplayTimezone from 'src/hooks/useDisplayTimezone';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { InterviewEvent } from 'src/utils/api/getScheduleOptions';

type Props = {
  events: InterviewEvent[];
  onClose: () => void;
};

const OverlappingEventsModal = ({ events, onClose }: Props) => {
  const displayTimezone = useDisplayTimezone();

  if (events.length === 0) return null;

  return (
    <ConditionalThemeProvider>
      <Dialog
        open
        decoration={
          <img
            style={{ width: '64px', height: '64px' }}
            src="/static/images/scheduling/warning_64.png"
            alt="Changing calender warning"
          />
        }
        title="Overlapping interviews"
        subTitle="Please address this issue to continue"
        onClose={onClose}
        submitOptions={{
          label: 'Got it',
          onClick: onClose,
        }}
      >
        Adjust the start or end times for the following interviews:
        <Box ml={1}>
          <List component="ul" sx={{ listStyleType: 'disc' }}>
            {events.map((event) => (
              <ListItem key={event.id} sx={{ display: 'list-item' }}>
                {event.name || ''}(
                {formatTimeRange(
                  assertIsoTimestamp(event.startAt),
                  assertIsoTimestamp(event.endAt),
                  displayTimezone,
                  true /* showTzAbbr */
                )}
                )
              </ListItem>
            ))}
          </List>
        </Box>
        <Alert severity="info">
          <AlertTitle>You may want to use hidden events</AlertTitle>
          Events hidden from candidates may overlap with interviews. Select any interview and check the “Hide from
          candidate” option. These events wont show in any candidate communications or Candidate Portal.
        </Alert>
      </Dialog>
    </ConditionalThemeProvider>
  );
};

export default OverlappingEventsModal;
