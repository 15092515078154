import React, { useEffect, useMemo, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { TextField } from '@material-ui/core';
import { MaybeTooltip } from '@modernloop/shared/components';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
// import { Autocomplete, AutocompleteGetTagProps } from '@material-ui/lab';
import { Autocomplete, AutocompleteGetTagProps, ListItem, ListItemButton, Stack } from '@mui/material';
import { useDebounce } from 'use-debounce';

import { useMeetingRoomSuggestionsLazyQuery } from 'src/generated/mloop-graphql';

import { useAutocompleteStyles } from 'src/components/Autocomplete';
import FilterSelect from 'src/components/FilterList/FilterSelect';
import IconButton from 'src/components/IconButton';
// import Stack from 'src/components/Stack';
import VirtualList from 'src/components/VirtualList';
import Chip from 'src/components/chip';
import { CrossIcon, MeetingRoomIcon, PlusIcon } from 'src/components/icons';
import Label from 'src/components/label';

import {
  setCommonMeetingRoomSuggestionsByEventId,
  setMeetingRoomSuggestionsForInterviewEventId,
} from 'src/store/actions/schedule-communications';
import {
  getCommonMeetingRoomSuggestionsByEventId,
  getMeetingRoomSuggestionsByInterviewEventId,
} from 'src/store/selectors/schedule-communications';
import { MeetingRoomSuggestionInterface } from 'src/store/slices/schedule-communications';

import IsoTimestamp from 'src/types/IsoTimestamp';

import { DEBOUNCE_TIMEOUT, PAGER_DEFAULT } from 'src/constants';
import { useDispatch, useSelector } from 'src/store';

import fetchMoreMeetingRooms from './fetchMoreMeetingRooms';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type BaseMeetingRoomProps = {
  startAt: IsoTimestamp;
  endAt: IsoTimestamp;
  selectedMeetingRooms?: MeetingRoomSuggestionInterface[];
  useAutocomplete?: boolean;
  onMeetingRoomsChanged: (roomSuggestions: MeetingRoomSuggestionInterface[]) => void;
};

const ROOM_OPTION_HEIGHT = 50;
const EMPTY_ARRAY = [];

export const BaseMeetingRoom = ({
  startAt,
  endAt,
  selectedMeetingRooms,
  useAutocomplete,
  onMeetingRoomsChanged,
}: BaseMeetingRoomProps) => {
  const autocompleteStyles = useAutocompleteStyles({});
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_TIMEOUT);
  const [fetchingMore, setFetchingMore] = useState(false);

  const [fetchMeetingRoomSuggestions, { data, loading, error, fetchMore, updateQuery }] =
    useMeetingRoomSuggestionsLazyQuery();

  useEffect(() => {
    fetchMeetingRoomSuggestions({
      variables: {
        input: {
          pageInput: { cursor: '', limit: PAGER_DEFAULT },
          search: debouncedSearch,
          timeRange: { startAt, endAt },
        },
      },
      context: {
        batch: false,
      },
    });
  }, [fetchMeetingRoomSuggestions, debouncedSearch, startAt, endAt]);

  const options = useMemo(() => {
    if (!data?.meetingRoomSuggestions) return [] as MeetingRoomSuggestionInterface[];
    const selectedRoomIds = selectedMeetingRooms?.map((selectedRoom) => selectedRoom.room?.id || '') || [];
    const result = data.meetingRoomSuggestions.items
      .map((roomSuggestion) => roomSuggestion)
      .filter((roomSuggestion) => roomSuggestion.room?.id && !selectedRoomIds.includes(roomSuggestion.room.id));

    return result;
  }, [data, selectedMeetingRooms]);

  const lastMeetingRoomId = useMemo(() => {
    if (options.length) return options[options.length - 1];
    return null;
  }, [options]);

  if (error) {
    return (
      <Label backgroundColor="error" color="error" variant="captions">
        {error.message}
      </Label>
    );
  }

  const handleFetchMore = () => {
    if (!loading && !fetchingMore && data?.meetingRoomSuggestions?.nextCursor) {
      setFetchingMore(true);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/catch-or-return
      fetchMoreMeetingRooms(
        fetchMore,
        updateQuery,
        data?.meetingRoomSuggestions,
        debouncedSearch,
        startAt,
        endAt
      ).finally(() => {
        setFetchingMore(false);
      });
    }
  };

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: MeetingRoomSuggestionInterface) => {
    // Try and fetch more meeting rooms if we have rendered the last item
    if (useAutocomplete && option.room?.id === lastMeetingRoomId) {
      handleFetchMore();
    }

    return (
      <ListItem {...props}>
        <ListItemButton sx={{ width: '100%' }}>
          <Stack direction="column" width="100%">
            <Stack direction="row" spacing={1} flexWrap="nowrap">
              <MaybeTooltip
                label={option.room?.description || ''}
                labelProps={{ style: { display: 'flex', maxWidth: '100%' } }}
                title={option.room?.description || ''}
              />
              <Label>·</Label>
              {option.room?.floorName && (
                <MaybeTooltip
                  label={`Floor: ${option.room.floorName}`}
                  labelProps={{ color: 'high-contrast-grey' }}
                  title={`Floor: ${option.room.floorName}`}
                />
              )}
            </Stack>
            <Stack direction="row" spacing={1} flexWrap="nowrap">
              <Label color="high-contrast-grey" noWrap>{`${option.room?.capacity} people`}</Label>
              <Label color="high-contrast-grey">·</Label>
              <Label color={option.isBusy ? 'error' : 'success'} noWrap>{`${
                option.isBusy ? 'Busy at this time' : 'Free at this time'
              }`}</Label>
            </Stack>
          </Stack>
        </ListItemButton>
      </ListItem>
    );
  };

  const renderTags = (selectedOptions: MeetingRoomSuggestionInterface[], getTagProps: AutocompleteGetTagProps) => {
    return selectedOptions.map((option, index) => {
      const tagProps = getTagProps({ index });
      return (
        <Chip key={option.room?.id || ''} label={option.room?.description} tagProps={tagProps} variant="default" />
      );
    });
  };

  const handleInputChange = (event: React.ChangeEvent, value: string) => {
    setSearch(value);
  };

  const handleChange = (event: React.ChangeEvent, values: MeetingRoomSuggestionInterface[]) => {
    onMeetingRoomsChanged(values);
  };

  if (useAutocomplete) {
    return (
      <Autocomplete
        placeholder="Search meeting rooms…"
        classes={autocompleteStyles}
        loading={loading || fetchingMore}
        options={options}
        value={selectedMeetingRooms}
        getOptionLabel={(option) => option.room?.description || ''}
        renderOption={renderOption}
        renderTags={renderTags}
        onInputChange={handleInputChange}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} />}
        ListboxComponent={VirtualList as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
        ListboxProps={
          {
            getChildSize: () => ROOM_OPTION_HEIGHT,
            // We need to typecast to any because we are using a custom ListboxComponent and getChildSize is a prop on VirtualList
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any
        }
        multiple
        filterSelectedOptions
        filterOptions={(opts) => opts}
      />
    );
  }

  const handleFilterSelectChange = (event: React.ChangeEvent, values: MeetingRoomSuggestionInterface[]) => {
    onMeetingRoomsChanged(values);
  };

  const handleMeetingRoomRemoved = (deletedRoom: MeetingRoomSuggestionInterface) => {
    if (!selectedMeetingRooms) return;
    onMeetingRoomsChanged(
      selectedMeetingRooms.filter((selectedRoom) => selectedRoom.room?.id !== deletedRoom.room?.id)
    );
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1} gap={1} flexWrap="wrap">
      {selectedMeetingRooms?.map((selectedRoom) => {
        if (!selectedRoom.room) return null;

        return (
          <Label key={selectedRoom.room.id} backgroundColor="contrast" icon={<MeetingRoomIcon />}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {selectedRoom.room.description}
              <IconButton onClick={() => handleMeetingRoomRemoved(selectedRoom)}>
                <CrossIcon />
              </IconButton>
            </Stack>
          </Label>
        );
      })}

      <FilterSelect
        options={options}
        values={selectedMeetingRooms}
        loading={loading || fetchingMore}
        placeholderText="Search meeting rooms…"
        PaperProps={{ style: { width: 480 } }}
        getLabel={() =>
          selectedMeetingRooms?.length ? (
            <IconButton>
              <PlusIcon />
            </IconButton>
          ) : (
            'Add meeting room'
          )
        }
        getOptionLabel={(option) => option.room?.description || ''}
        renderOption={renderOption}
        onChange={handleFilterSelectChange}
        onInputChange={handleInputChange}
        getChildSize={() => ROOM_OPTION_HEIGHT}
        onPopoverClose={() => setSearch('')}
        filterOptions={(opts) => opts}
        onScrollToEnd={handleFetchMore}
      />
    </Stack>
  );
};

type Props = {
  scheduleId: string;
  startAt: IsoTimestamp;
  endAt: IsoTimestamp;

  /**
   * interviewEventId is used when this component is used to apply meeting room
   * to a single interview event.
   */
  interviewEventId?: string;

  /**
   * commonInterviewEventIds is used when this component is used to bulk apply meeting room
   * to all the events within time range represented by startAt & endAt
   */
  commonInterviewEventIds?: string[];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const MeetingRoom = (props: Props) => {
  const dispatch = useDispatch();
  const { scheduleId, interviewEventId, commonInterviewEventIds } = props;

  const meetingRoomSuggestions = useSelector((state) => {
    if (interviewEventId) return getCommonMeetingRoomSuggestionsByEventId(state, scheduleId, interviewEventId);
    if (commonInterviewEventIds?.length) {
      return getCommonMeetingRoomSuggestionsByEventId(state, scheduleId, commonInterviewEventIds[0]);
    }
    return null;
  });
  const meetingRoomSuggestionsByInterviewEventId = useSelector((state) => {
    if (!interviewEventId) return null;
    return getMeetingRoomSuggestionsByInterviewEventId(state, scheduleId, interviewEventId);
  });

  const defaultMeetingRoomSuggestions = useMemo(() => {
    const result = meetingRoomSuggestionsByInterviewEventId || meetingRoomSuggestions;

    return result || EMPTY_ARRAY;
  }, [meetingRoomSuggestionsByInterviewEventId, meetingRoomSuggestions]);

  const handleMeetingRoomsChanged = (roomSuggestions: MeetingRoomSuggestionInterface[]) => {
    if (interviewEventId) {
      dispatch(setMeetingRoomSuggestionsForInterviewEventId(scheduleId, interviewEventId, roomSuggestions));
      return;
    }

    if (commonInterviewEventIds?.length) {
      dispatch(setCommonMeetingRoomSuggestionsByEventId(scheduleId, commonInterviewEventIds, roomSuggestions));
    }
  };

  return (
    <BaseMeetingRoom
      {...props}
      useAutocomplete={!interviewEventId}
      selectedMeetingRooms={defaultMeetingRoomSuggestions}
      onMeetingRoomsChanged={handleMeetingRoomsChanged}
    />
  );
};

export default MeetingRoom;
