/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { useFlag } from '@modernloop/shared/feature-flag';
import { v4 as uuid } from 'uuid';

import {
  CreateTaskDialogPage_InterviewPlanFragment,
  OptionsInput,
  SelfScheduleLocation,
  SelfScheduleMeetingHost,
  SelfScheduleOptionSettings_JobStageSettingsFragment,
  SelfScheduleOptionsInterviewPlanQuery,
  SelfScheduleOptionsModal_SelfScheduleFragment,
  SelfScheduleOptionsUpsertInput,
  SelfScheduleZoomHost,
  StringOrgPref,
  TaskCreationSource,
  useCandidateCoordinatorAndRecruiterQuery,
  useSelfScheduleCalendarsQuery,
  useSelfScheduleOptionsInterviewPlanLazyQuery,
  useSelfScheduleOptionsInterviewPlanQuery,
  useSelfScheduleOptionsModalQuery,
} from 'src/generated/mloop-graphql';

import ChildPropDialog from 'src/components/Dialog/ChildPropsDialog';
import { CreateType } from 'src/components/Dialogs/ShareDialog';

import { EmployeeFragment } from 'src/entities/EmployeePicker/EmployeeGraphQL';
import cloneInterviewPlan from 'src/entities/InterviewPlan/cloneInterviewPlan';
import getSaveInterviewPlanInput from 'src/entities/InterviewPlan/getSaveInterviewPlanInput';

import useIntegrations from 'src/hooks/api/integration/useIntegrations';
import { useOrgPrefString } from 'src/hooks/api/org';
import { useDefaultCcEmployeeIds } from 'src/hooks/useDefaultCCEmployeeIds';

import { OrgPrefName } from 'src/utils/api/org';

import { TaskShareModalContext } from '../ApplicationDetailsTaskView/ApplicationContent/TaskShareDialogHandler';
import CreateTaskDialogPage from '../ScheduleTask/CreateModals/shared/CreateTaskDialogPage';
import { TaskCreateInputWrapper } from '../ScheduleTask/CreateModals/shared/types';
import useDefaultTaskAssignee from '../ScheduleTask/CreateModals/shared/useDefaultTaskAssignee';

import SelfScheduleEmail from './SelfScheduleEmail';
import SelfScheduleOptions from './SelfScheduleOptions';
import { SelfScheduleSelectedTemplates } from './SelfSchedulePreferences';
import { NumberOfDays } from './TimeframePicker';
import { useSelfScheduleJobStageTemplates } from './useSelfScheduleJobStageTemplates';
import useSelfScheduleOptionsUpsert from './useSelfScheduleOptionsUpsert';

export const GQL_CANDIDATE_INFO = gql`
  query CandidateCoordinatorAndRecruiter($candidateId: uuid!) {
    candidate(id: $candidateId) {
      coordinatorId
      recruiterId
      email
    }
  }
`;

export const GQL_SELF_SCHEDULE = gql`
  ${CreateTaskDialogPage.fragments.application}
  ${EmployeeFragment}
  query SelfScheduleOptionsModal($applicationId: uuid!) {
    application(id: $applicationId) {
      id
      ...CreateTaskDialogPage_application
    }
    thisEmployee {
      id
      ...Employee
    }
  }
`;

export const SelfScheduleCalendars = gql`
  query SelfScheduleCalendars($input: InitScheduleFlowInput!) {
    initScheduleFlow(input: $input) {
      candidateCalendar {
        remoteId
      }
      interviewerCalendar {
        remoteId
      }
    }
  }
`;

export const JobStageInterviewPlan = gql`
  ${CreateTaskDialogPage.fragments.interviewPlan}
  ${cloneInterviewPlan.fragments.interviewPlan}
  ${getSaveInterviewPlanInput.fragments.interviewPlan}
  ${SelfScheduleOptions.fragments.interviewPlan}

  query SelfScheduleOptionsInterviewPlan($jobStageId: uuid!) {
    jobStage(id: $jobStageId) {
      id
      ...CreateTaskDialogPage_interviewPlan
      ...cloneInterviewPlan_interviewPlan
      ...getSaveInterviewPlanInput_interviewPlan
      ...SelfScheduleOptions_interviewPlan
    }
  }
`;

export enum SelfScheduleOptionsModalPage {
  OPTIONS = 0,
  SHARE_SELF_SCHEDULE = 1,
  CREATE_TASK = 2,
}

export type Fragment = {
  selfSchedule?: SelfScheduleOptionsModal_SelfScheduleFragment;
  settings?: SelfScheduleOptionSettings_JobStageSettingsFragment | null;
};

export type Props = {
  applicationId: string;
  candidateId: string;
  jobStageId: string;
  jobId: string;
  /**
   * If taskId is provided, the modal will skip the task create step
   * If taskId is not provided, the modal will show the task create step and allow task creation
   */
  taskId?: string;
  showTaskCreateStep?: boolean;
  customJobStageId?: string;
  selfScheduleOptionsModalPage?: SelfScheduleOptionsModalPage;
  /** Allow consumers to turn off the send later option if the modal is shown from the share modal */
  showSendLater?: boolean;
  onClose: (emailSent?: boolean) => void;
  onJobStageIdChange?: (jobStageId: string) => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
export const SelfScheduleOptionsModal = ({
  applicationId,
  candidateId,
  jobStageId,
  taskId,
  jobId,
  showTaskCreateStep,
  customJobStageId,
  selfSchedule,
  selfScheduleOptionsModalPage,
  onClose,
  showSendLater = true /** Normally default to false for boolean props, but this is a new prop chaning default behavior so defaulting the other way */,
  settings,
  onJobStageIdChange,
}: Fragment & Props) => {
  const shareContext = useContext(TaskShareModalContext);
  const useCustomCandidateEventInvite = useFlag('user_custom_self_schedule_event_template');
  const ignoreRollingDaysSetting = useFlag('user_ignore_rolling_window_setting');
  const interviewPlanEntityInTaskEnabled = useFlag('interview_plan_entity_in_tasks');
  const showDefaultTaskComment = useFlag('org_default_task_comment');
  const orgBreakImprovementsEnabled = useFlag('org_break_improvements');

  const [currentPage, setCurrentPage] = useState(selfScheduleOptionsModalPage || SelfScheduleOptionsModalPage.OPTIONS);
  const [selfScheduleOptions, setSelfScheduleOptions] = useState<SelfScheduleOptionsUpsertInput>({
    taskId,
    candidateEmailContent: {
      toEmailAddress: '',
      description: '',
      summary: '',
    },
    options: {
      candidateCalendarId: settings?.selfScheduleOptions?.candidateCalendarId || '',
      interviewerCalendarId: settings?.selfScheduleOptions?.interviewerCalendarId || '',
      location: settings?.selfScheduleOptions?.location as SelfScheduleLocation,
      customLocation: settings?.selfScheduleOptions?.customLocation || '',
      zoomHost:
        (settings?.selfScheduleOptions?.location === SelfScheduleLocation.Zoom &&
          (settings?.selfScheduleOptions?.zoomHost || SelfScheduleZoomHost.Interviewer)) ||
        SelfScheduleZoomHost.NotSet,
      zoomHostUserId: settings?.selfScheduleOptions?.zoomHostUserId || '',
      isPrivateCalendarEvent: settings?.selfScheduleOptions?.isPrivateCalendarEvent || false,
      rollingDays: ignoreRollingDaysSetting
        ? settings?.selfScheduleOptions?.numberOfDays ?? NumberOfDays.TwoWeeks
        : settings?.selfScheduleOptions?.rollingDays ?? NumberOfDays.TwoWeeks,
      advanceNoticeInHours: settings?.selfScheduleOptions?.advanceNoticeInHours ?? 24,
      shouldRespectLoadLimit: settings?.selfScheduleOptions?.shouldRespectLoadLimit ?? true,
      canScheduleOverAvailableKeywords: settings?.selfScheduleOptions?.canScheduleOverAvailableKeywords ?? false,
      canScheduleOverRecruitingKeywords: settings?.selfScheduleOptions?.canScheduleOverRecruitingKeywords ?? false,
      canScheduleOverFreeTime: settings?.selfScheduleOptions?.canScheduleOverFreeTime ?? true,
      note: settings?.selfScheduleOptions?.candidateNote || '',
      meetingHost: settings?.selfScheduleOptions?.meetingHost,
      videoMeetingHostEmployeeId: settings?.selfScheduleOptions?.videoMeetingHostEmployeeId,
    },
  });

  const { assignee, loading: assigneeLoading } = useDefaultTaskAssignee(
    applicationId,
    OrgPrefName.STR_TASK_DEFAULT_ASSIGNEE_SELF_SCHEDULE_REQUESTS
  );

  const { data: modalData } = useSelfScheduleOptionsModalQuery({
    variables: { applicationId },
    skip: !applicationId,
  });

  const [input, setInput] = useState<TaskCreateInputWrapper>({
    applicationId,
    jobStageId,
    assigneeEmployeeId: assignee?.id,
    assignee,
    candidateTimezone: modalData?.application?.candidate?.timezone || getLocalTimezone(),
    isUrgent: false,
    isDebriefRequired: false,
    creationSource: TaskCreationSource.CandidateAvailabilityRequest,
    customInterviewPlan: [],
    customJobStageId: null,
  });

  const [interviewPlan, setInterviewPlan] = useState<CreateTaskDialogPage_InterviewPlanFragment>({
    id: '',
    jobStageInterviewGroups: [],
  });

  const { data: integrations } = useIntegrations();
  const { templates: selfScheduleTemplates } = useSelfScheduleJobStageTemplates(jobStageId);

  const { data: candidateData } = useCandidateCoordinatorAndRecruiterQuery({ variables: { candidateId } });
  const { data: calendars } = useSelfScheduleCalendarsQuery({
    variables: {
      input: {
        jobStageId,
      },
    },
  });

  const [defaultCommentPrefValue, { loading: defaultTaskCommentLoading }] = useOrgPrefString(
    StringOrgPref.StrDefaultTaskComment
  );

  useEffect(() => {
    if (defaultTaskCommentLoading || !showDefaultTaskComment) {
      return;
    }

    setInput((prev) => ({
      ...prev,
      note: defaultCommentPrefValue || '',
    }));
  }, [defaultCommentPrefValue, defaultTaskCommentLoading, setInput, showDefaultTaskComment]);

  // After we load the assignee, we need to set the assignee in the input
  useEffect(() => {
    if (assigneeLoading) {
      return;
    }

    setInput((prev) => ({
      ...prev,
      assigneeEmployeeId: assignee?.id,
      assignee,
    }));
  }, [assigneeLoading, assignee, setInput]);

  const onInterviewPlanFetchCompleted = (data: SelfScheduleOptionsInterviewPlanQuery) => {
    if (!data.jobStage) return;

    const newCustomJobStageId = customJobStageId || uuid();

    const interviewPlanClone = cloneInterviewPlan(
      { interviewPlan: data.jobStage },
      { customJobStageId: newCustomJobStageId }
    );

    setInterviewPlan(interviewPlanClone);

    const interviewPlanInput = getSaveInterviewPlanInput(
      { interviewPlan: interviewPlanClone },
      { finalJobStageId: newCustomJobStageId }
    );

    setInput((prev) => ({
      ...prev,
      customJobStageId: newCustomJobStageId,
      customInterviewPlan: !orgBreakImprovementsEnabled ? interviewPlanInput.groups : undefined,
      interviewPlan: orgBreakImprovementsEnabled
        ? {
            groups: interviewPlanInput.groups,
            schedulingWindow: interviewPlanInput.schedulingWindow
              ? { seconds: interviewPlanInput.schedulingWindow.seconds }
              : undefined,
            excludedEmployeeIds: interviewPlanInput.excludedEmployeeIds || [],
          }
        : undefined,
    }));
  };

  const interviewPlanId = customJobStageId || input.jobStageId || jobStageId;
  const { loading: interviewPlanLoading } = useSelfScheduleOptionsInterviewPlanQuery({
    variables: { jobStageId: interviewPlanId },
    onCompleted: onInterviewPlanFetchCompleted,
    skip: !interviewPlanEntityInTaskEnabled,
  });

  const [fetchInterviewPlan, { loading: interviewPlanLazyLoading }] = useSelfScheduleOptionsInterviewPlanLazyQuery({
    variables: { jobStageId: interviewPlanId },
    onCompleted: onInterviewPlanFetchCompleted,
  });

  const selfScheduleOptionsUpsert = useSelfScheduleOptionsUpsert(selfScheduleOptions, taskId, input);

  useEffect(() => {
    // Don't use settings if selfSchedule is provided
    if (selfSchedule) return;

    setSelfScheduleOptions((prevOptions) => {
      let ssOptions = {
        ...prevOptions,
        options: {
          ...prevOptions.options,
          candidateCalendarId:
            settings?.selfScheduleOptions?.candidateCalendarId ||
            calendars?.initScheduleFlow?.candidateCalendar?.remoteId ||
            '',
          interviewerCalendarId:
            settings?.selfScheduleOptions?.interviewerCalendarId ||
            calendars?.initScheduleFlow?.interviewerCalendar?.remoteId ||
            '',
          location: settings?.selfScheduleOptions?.location,
          customLocation: settings?.selfScheduleOptions?.customLocation || '',
          zoomHost:
            settings?.selfScheduleOptions?.location === SelfScheduleLocation.Zoom
              ? settings?.selfScheduleOptions?.zoomHost || SelfScheduleZoomHost.Interviewer
              : SelfScheduleZoomHost.NotSet,
          zoomHostUserId: settings?.selfScheduleOptions?.zoomHostUserId || '',
          isPrivateCalendarEvent: settings?.selfScheduleOptions?.isPrivateCalendarEvent || false,
          rollingDays: ignoreRollingDaysSetting
            ? settings?.selfScheduleOptions?.numberOfDays || NumberOfDays.TwoWeeks
            : settings?.selfScheduleOptions?.rollingDays || NumberOfDays.TwoWeeks,
          advanceNoticeInHours: settings?.selfScheduleOptions?.advanceNoticeInHours ?? 24,
          shouldRespectLoadLimit: settings?.selfScheduleOptions?.shouldRespectLoadLimit ?? true,
          canScheduleOverAvailableKeywords: settings?.selfScheduleOptions?.canScheduleOverAvailableKeywords ?? false,
          canScheduleOverRecruitingKeywords: settings?.selfScheduleOptions?.canScheduleOverRecruitingKeywords ?? false,
          canScheduleOverFreeTime: settings?.selfScheduleOptions?.canScheduleOverFreeTime ?? true,
          note: settings?.selfScheduleOptions?.candidateNote || '',
          meetingHost: settings?.selfScheduleOptions?.meetingHost,
          videoMeetingHostEmployeeId: settings?.selfScheduleOptions?.videoMeetingHostEmployeeId,
        },
      } as SelfScheduleOptionsUpsertInput;

      if (!ssOptions.options.location) {
        if (integrations?.zoom?.active) {
          ssOptions = {
            ...ssOptions,
            options: {
              ...ssOptions.options,
              location: SelfScheduleLocation.Zoom,
              zoomHost: SelfScheduleZoomHost.Interviewer,
            },
          };
        } else if (integrations?.google) {
          ssOptions = {
            ...ssOptions,
            options: {
              ...ssOptions.options,
              location: SelfScheduleLocation.Google,
              meetingHost: SelfScheduleMeetingHost.Interviewer,
              videoMeetingHostEmployeeId: undefined,
            },
          };
        } else if (integrations?.microsoft?.active) {
          ssOptions = {
            ...ssOptions,
            options: {
              ...ssOptions.options,
              location: SelfScheduleLocation.MicrosoftTeams,
              meetingHost: SelfScheduleMeetingHost.Interviewer,
              videoMeetingHostEmployeeId: undefined,
            },
          };
        }
      }

      if (selfScheduleTemplates) {
        ssOptions = {
          ...ssOptions,
          ...selfScheduleTemplates,
        };

        // This is a special case since we use a checkbox to determine whether to send a personal email or not.
        // Always fallback to what's in settings. Don't use default template.
        ssOptions.candidateEmailTemplateId = settings?.selfScheduleCandidateEmailTemplateId;

        if (settings?.selfScheduleInterviewerEventTemplateId) {
          ssOptions.interviewerEventTemplateId = settings?.selfScheduleInterviewerEventTemplateId;
        }
      }

      return ssOptions;
    });
  }, [selfScheduleTemplates, integrations, settings, selfSchedule, calendars, ignoreRollingDaysSetting]);

  useEffect(() => {
    if (!selfSchedule) return;
    const ssOptions = selfSchedule;

    if (!ssOptions || !ssOptions.options) return;

    setSelfScheduleOptions({
      taskId,
      candidateEmailContent: {
        toEmailAddress: '',
        description: '',
        summary: '',
      },
      candidateEmailTemplateId: ssOptions.candidateEmailTemplateId,
      candidateEventTemplateId: useCustomCandidateEventInvite
        ? selfScheduleTemplates?.candidateEventTemplateId
        : ssOptions.candidateEventTemplateId,
      interviewerEventTemplateId: ssOptions.interviewerEventTemplateId,
      options: {
        candidateCalendarId: ssOptions.options.candidateCalendarId || '',
        interviewerCalendarId: ssOptions.options.interviewerCalendarId || '',
        location: ssOptions.options.location || SelfScheduleLocation.None,
        inclusionDays: ssOptions.options.inclusionDays,
        advanceNoticeInHours: ssOptions.options.advanceNoticeInHours,
        rollingDays: ssOptions.options.rollingDays,
        zoomHost: ssOptions.options.zoomHost,
        zoomHostUserId: ssOptions.options.zoomHostUserId,
        meetingHost: ssOptions.options.meetingHost,
        videoMeetingHostEmployeeId: ssOptions.options.videoMeetingHostEmployeeId,
      },
    });
  }, [selfSchedule, applicationId, jobStageId, taskId, selfScheduleTemplates, useCustomCandidateEventInvite]);

  const defaultCcEmployeeIds = useDefaultCcEmployeeIds(
    candidateData?.candidate?.coordinatorId,
    candidateData?.candidate?.recruiterId
  );

  const onOptionsBack =
    !taskId && showTaskCreateStep
      ? () => {
          setCurrentPage(SelfScheduleOptionsModalPage.CREATE_TASK);
        }
      : undefined;

  const taskCustomJobStageId = taskId || !showTaskCreateStep ? customJobStageId : input.customJobStageId;

  return (
    <ChildPropDialog isOpen size="md" onClose={onClose}>
      {currentPage === SelfScheduleOptionsModalPage.CREATE_TASK &&
        showTaskCreateStep &&
        modalData?.application &&
        !assigneeLoading &&
        !taskId && (
          <CreateTaskDialogPage
            title="Self-schedule"
            input={input}
            setInput={setInput}
            interviewPlan={interviewPlan}
            interviewPlanLoading={interviewPlanLoading || interviewPlanLazyLoading}
            application={modalData.application}
            onClose={onClose}
            source={TaskCreationSource.SelfScheduleRequest}
            onContinue={(newInput) => {
              setInput(newInput);
              setCurrentPage(SelfScheduleOptionsModalPage.OPTIONS);
            }}
            onJobStageIdChange={(id) => {
              onJobStageIdChange?.(id);

              if (interviewPlanEntityInTaskEnabled) {
                fetchInterviewPlan({ variables: { jobStageId: id } });
              }
            }}
            onInterviewPlanChange={(newInterviewPlan) => {
              setInterviewPlan(newInterviewPlan);

              const interviewPlanInput = getSaveInterviewPlanInput(
                { interviewPlan: newInterviewPlan },
                { finalJobStageId: newInterviewPlan.id }
              );

              setInput((prev) => ({
                ...prev,
                customInterviewPlan: !orgBreakImprovementsEnabled ? interviewPlanInput.groups : undefined,
                interviewPlan: orgBreakImprovementsEnabled
                  ? {
                      groups: interviewPlanInput.groups,
                      schedulingWindow: interviewPlanInput.schedulingWindow
                        ? { seconds: interviewPlanInput.schedulingWindow.seconds }
                        : undefined,
                      excludedEmployeeIds: interviewPlanInput.excludedEmployeeIds || [],
                    }
                  : undefined,
              }));
            }}
          />
        )}
      {currentPage === SelfScheduleOptionsModalPage.OPTIONS && (
        <SelfScheduleOptions
          applicationId={applicationId}
          taskId={taskId}
          options={
            selfScheduleOptions?.options || {
              rollingDays: NumberOfDays.TwoWeeks,
            }
          }
          numberOfDays={settings?.selfScheduleOptions?.numberOfDays || 0}
          jobStageId={jobStageId}
          customJobStageId={taskCustomJobStageId}
          customInterviewPlan={
            !taskId ? input.customInterviewPlan || input.interviewPlan?.groups || undefined : undefined
          }
          interviewPlan={interviewPlan}
          onBack={onOptionsBack}
          onClose={onClose}
          onContinue={() => setCurrentPage(SelfScheduleOptionsModalPage.SHARE_SELF_SCHEDULE)}
          onOptionsChanged={(options: OptionsInput) => {
            setSelfScheduleOptions({ ...selfScheduleOptions, options });
          }}
          templates={{
            interviewerEventTemplateId: selfScheduleOptions.interviewerEventTemplateId,
            candidateEmailTemplateId: selfScheduleOptions.candidateEmailTemplateId,
            candidateEventTemplateId: selfScheduleOptions.candidateEventTemplateId,
          }}
          defaultTemplates={{
            ...selfScheduleTemplates,
          }}
          onTemplatesChanged={(selectedTemplates: SelfScheduleSelectedTemplates) => {
            setSelfScheduleOptions({
              ...selfScheduleOptions,
              interviewerEventTemplateId: selectedTemplates.interviewerEventTemplateId,
              candidateEmailTemplateId: selectedTemplates.candidateEmailTemplateId,
              candidateEventTemplateId: selectedTemplates.candidateEventTemplateId,
            });
          }}
        />
      )}
      {currentPage === SelfScheduleOptionsModalPage.SHARE_SELF_SCHEDULE && (
        <SelfScheduleEmail
          taskId={taskId}
          assigneeId={input.assigneeEmployeeId}
          jobId={jobId}
          canSaveWithoutSending={!!candidateData?.candidate?.email}
          defaultCcEmployeeIds={defaultCcEmployeeIds}
          applicationId={applicationId}
          candidateId={candidateId}
          jobStageId={jobStageId}
          taskCreatorId={modalData?.thisEmployee?.id || ''}
          subscriberEmployeeIds={input?.subscriberEmployeeIds || undefined}
          subTitle={
            taskId || !showTaskCreateStep ? 'Step 2 of 2: Email this request' : 'Step 3 out of 3: Email this request'
          }
          selfScheduleOptionsUpsert={selfScheduleOptionsUpsert}
          goBack={
            selfScheduleOptionsModalPage !== undefined && showSendLater
              ? () => setCurrentPage(SelfScheduleOptionsModalPage.OPTIONS)
              : undefined
          }
          onCancel={onClose}
          onSave={(emailSent: boolean, createdTaskId: string | null) => {
            if (shareContext?.onTaskShare && emailSent !== undefined && !emailSent) {
              shareContext.onTaskShare(CreateType.SELF_SCHEDULE, createdTaskId ?? '');
            }

            onClose(emailSent);
          }}
          selfScheduleRequestEmailTemplateId={settings?.selfScheduleRequestEmailTemplateId}
          ccRecipients={settings?.selfScheduleOptions?.ccRecipients}
          bccRecipients={settings?.selfScheduleOptions?.bccRecipients}
        />
      )}
    </ChildPropDialog>
  );
};
