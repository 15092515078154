import React from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { PublicError } from '@modernloop/shared/helper-components';
import { Alert, Button, Stack, Typography } from '@mui/material';

import {
  RemoveMemberModal_InterviewModuleMemberFragment,
  useInterviewModuleDeleteMemberMutation,
} from 'src/generated/mloop-graphql';

import { resetModuleMemberListCache } from 'src/entities/InterviewModuleMember/utils';

import { apolloCacheDeleteById } from 'src/utils/apolloHelpers';

type Props = {
  open: boolean;
  onDelete: () => void;
  onClose: () => void;
  onPauseClick: () => void;
};

type Fragments = {
  interviewModuleMember: RemoveMemberModal_InterviewModuleMemberFragment;
};

const RemoveMemberModal: FCWithFragments<Fragments, Props> = ({
  open,
  onClose,
  onDelete,
  onPauseClick,
  interviewModuleMember,
}) => {
  const [deleteMembers, { loading: removing, error }] = useInterviewModuleDeleteMemberMutation();

  const handleRemove = async () => {
    try {
      await deleteMembers({
        variables: {
          input: {
            employeeIds: [interviewModuleMember.employeeId],
            interviewModuleId: interviewModuleMember.interviewModuleId,
          },
        },
        update(cache) {
          apolloCacheDeleteById(cache, interviewModuleMember.interviewModuleId, 'InterviewModule');
          resetModuleMemberListCache({
            cache,
            moduleId: interviewModuleMember.interviewModuleId,
            employeeIds: [interviewModuleMember.employeeId],
          });
        },
      });
      onDelete();
    } catch (e) {
      console.error(e);
    }
  };

  if (interviewModuleMember.stats?.upcoming) {
    return (
      <Dialog
        cancelOptions={{ label: 'Cancel', onClick: onClose }}
        submitOptions={{
          label: 'Okay',
          onClick: onClose,
        }}
        title="Cannot remove interviewer"
        open={open}
        onClose={onClose}
      >
        <Stack direction="column" spacing={1}>
          <Typography>
            This interviewer is currently scheduled for an interview using this module and cannot be removed.
          </Typography>
          <Typography>
            To remove this interviewer from this module, first update any upcoming interviews where they’re associated
            with this module by removing or replacing them.
          </Typography>
          <Alert variant="outlined" color="info" severity="info">
            <Stack alignItems="flex-start">
              <Typography>Need to just take a temporary break from this module?</Typography>
              <Button size="medium" variant="text" color="primary" onClick={onPauseClick}>
                Pause this interviewer instead
              </Button>
            </Stack>
          </Alert>
        </Stack>
      </Dialog>
    );
  }

  return (
    <Dialog
      cancelOptions={{ label: 'Cancel', onClick: onClose }}
      submitOptions={{
        label: 'Remove',
        onClick: handleRemove,
        isDisabled: removing,
        isLoading: removing,
      }}
      title="Remove interviewer?"
      open={open}
      onClose={onClose}
    >
      <Stack direction="column" spacing={1}>
        <Typography>
          This interviewer will no longer be eligible for scheduling as part of this module. Records of their interviews
          will be preserved, but <b>any training history will be lost</b>.
        </Typography>
        <Alert variant="outlined" color="info" severity="info">
          <Stack alignItems="flex-start">
            <Typography>Need to just take a temporary break from this module?</Typography>
            <Button size="medium" variant="text" color="primary" onClick={onPauseClick}>
              Pause this interviewer instead
            </Button>
          </Stack>
        </Alert>
      </Stack>

      <PublicError error={error} />
    </Dialog>
  );
};

RemoveMemberModal.fragments = {
  interviewModuleMember: gql`
    fragment RemoveMemberModal_interviewModuleMember on InterviewModuleMember {
      employeeId
      interviewModuleId
      stats {
        upcoming
      }
    }
  `,
};

export const InterviewModuleDeleteMemberMutation = gql`
  mutation InterviewModuleDeleteMember($input: InterviewModuleRemoveMembersInput!) {
    interviewModuleRemoveMembers(input: $input) {
      interviewModule {
        id
      }
    }
  }
`;

export default RemoveMemberModal;
