import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CloudWithKeyIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        d="M7.3416 5.47734C8.00965 4.43677 9.17486 3.75 10.5 3.75C12.4297 3.75 14.0199 5.20822 14.2271 7.08241C14.2691 7.46241 14.5902 7.75 14.9725 7.75H15C15.9665 7.75 16.75 8.5335 16.75 9.5C16.75 10.3458 16.1493 11.0529 15.3508 11.215C14.9449 11.2974 14.6826 11.6933 14.765 12.0992C14.8474 12.5051 15.2433 12.7674 15.6492 12.685C17.133 12.3838 18.25 11.0732 18.25 9.5C18.25 7.91495 17.1153 6.59493 15.6139 6.3079C15.0738 3.98244 12.9896 2.25 10.5 2.25C8.8199 2.25 7.32463 3.03966 6.36454 4.26546C6.24426 4.25522 6.12267 4.25 6 4.25C3.65279 4.25 1.75 6.15279 1.75 8.5C1.75 10.4264 3.03105 12.0516 4.78606 12.5739C5.18307 12.6921 5.60069 12.466 5.71884 12.069C5.83699 11.672 5.61094 11.2544 5.21394 11.1362C4.07746 10.798 3.25 9.74465 3.25 8.5C3.25 6.98122 4.48122 5.75 6 5.75C6.19226 5.75 6.37914 5.76961 6.55901 5.8067C6.86289 5.86936 7.17398 5.73843 7.3416 5.47734ZM12.0156 8.99483C11.5275 8.50667 10.736 8.50667 10.2478 8.99483C9.75969 9.48298 9.75969 10.2744 10.2478 10.7626C10.736 11.2508 11.5275 11.2508 12.0156 10.7626C12.5038 10.2744 12.5038 9.48298 12.0156 8.99483ZM9.18719 7.93417C10.2611 6.86023 12.0023 6.86023 13.0763 7.93417C14.1502 9.00811 14.1502 10.7493 13.0763 11.8233C12.1862 12.7133 10.8377 12.8657 9.79071 12.2804L8.37894 13.6922L9.00046 15.259C9.11079 15.5372 9.04522 15.8543 8.83363 16.0659L7.41942 17.4801C7.12653 17.773 6.65165 17.773 6.35876 17.4801L4.94454 16.0659C4.80389 15.9252 4.72487 15.7345 4.72487 15.5356C4.72487 15.3367 4.80389 15.1459 4.94454 15.0052L6.96967 12.9801L8.73005 11.2197C8.14472 10.1727 8.2971 8.82426 9.18719 7.93417Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
