import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { List, ListItem } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { getTZAbbr } from '@modernloop/shared/datetime';
import { formatDuration } from 'date-fns';

import Label, { LabelProps } from 'src/components/label';

import { CandidateAvailabilityOptions, DEFAULT_NUMBER_OF_DAYS } from 'src/store/slices/candidate-availability-options';

import { formatToTimeZone } from 'src/utils/dateUtils';
import { pluralize } from 'src/utils/strings';

import {
  DAY_CALC_FROM_INTERVIEW_PLAN,
  DURATION_CALC_FROM_INTERVIEW_PLAN,
} from 'src/views-new/JobDetailsPage/OrgSelfScheduleOptions';

import { Theme } from 'src/theme';

export const getFormattedDuration = (mins: number): string => {
  return formatDuration({ hours: Math.floor(mins / 60), minutes: mins % 60 });
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type CandidateAvailabilityOptionsLabelProps = {
  options: CandidateAvailabilityOptions;
  candidateTimezone?: string;
  roundedMinsPerDay?: number;
  variant?: LabelProps['variant'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      listStyle: 'disc',
      marginLeft: theme.spacing(2),
      padding: 0,
    },
    listItem: {
      display: 'list-item',
      paddingBottom: 4,
      paddingLeft: 0,
      paddingTop: 0,
    },
  })
);

const CandidateAvailabilityOptionsLabel = ({
  options,
  candidateTimezone,
  roundedMinsPerDay,
  variant,
}: CandidateAvailabilityOptionsLabelProps): JSX.Element => {
  const classes = useStyles();
  const { timezone } = options;

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Label color="max-contrast-grey" variant={variant}>
          Please provide availability{' '}
          {options.numberOfDays !== DEFAULT_NUMBER_OF_DAYS && options.numberOfDays !== DAY_CALC_FROM_INTERVIEW_PLAN && (
            <>
              on at least{' '}
              <strong>
                {options.numberOfDays} {pluralize('day', options.numberOfDays)}
              </strong>
              ,&nbsp;
            </>
          )}
          at least{' '}
          <strong>
            {options.minutesPerDays === DURATION_CALC_FROM_INTERVIEW_PLAN
              ? 'calculated duration'
              : getFormattedDuration(options.minutesPerDays)}
            {!roundedMinsPerDay ||
            options.minutesPerDays === roundedMinsPerDay ||
            options.minutesPerDays === DURATION_CALC_FROM_INTERVIEW_PLAN
              ? ''
              : '*'}{' '}
            per day
          </strong>
          , and in{' '}
          <strong>
            blocks of{' '}
            {options.minimumTimeBlockMinutes === DURATION_CALC_FROM_INTERVIEW_PLAN
              ? 'calculated duration'
              : getFormattedDuration(options.minimumTimeBlockMinutes)}
          </strong>{' '}
          or more.
        </Label>
      </ListItem>

      {(options.availabilityStartDate || options.availabilityEndDate) && candidateTimezone && (
        <ListItem className={classes.listItem}>
          {/* Availability start & end date and advance notice */}
          <Label color="max-contrast-grey" variant={variant}>
            Availability needs to be&nbsp;
            {options.availabilityStartDate && (
              <strong>
                {`on or after ${formatToTimeZone(options.availabilityStartDate, 'MMM d, yyyy', timezone)}${
                  candidateTimezone !== timezone ? ` ${getTZAbbr(timezone)}` : ''
                }`}
              </strong>
            )}
            {options.availabilityStartDate && options.availabilityEndDate && ' and '}
            {options.availabilityEndDate && (
              <strong>
                {`on or before ${formatToTimeZone(options.availabilityEndDate, 'MMM d, yyyy', timezone)}${
                  candidateTimezone !== timezone ? ` ${getTZAbbr(timezone)}` : ''
                }`}
              </strong>
            )}
            {(options.availabilityStartDate || options.availabilityEndDate) && <>.</>}
          </Label>
        </ListItem>
      )}

      {options.advanceNoticeMinutes !== undefined && options.advanceNoticeMinutes !== 0 && (
        <ListItem className={classes.listItem}>
          <Label color="max-contrast-grey" variant={variant}>
            Provide availability at least{' '}
            <strong>{getFormattedDuration(options.advanceNoticeMinutes)} in advance</strong>.
          </Label>
        </ListItem>
      )}
    </List>
  );
};

export default CandidateAvailabilityOptionsLabel;
