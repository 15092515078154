import { useMemo } from 'react';

import { useTheme } from '@mui/material';

export const useAvatarSxProps = () => {
  const theme = useTheme();
  return useMemo(() => {
    return {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(0.25, 1),
        width: '32px',
        height: '20px',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '18px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.border,
        borderRadius: '19px',
      },
    };
  }, [theme]);
};

export const useGridSxProps = () => {
  const theme = useTheme();
  return useMemo(() => {
    return {
      root: {
        position: 'relative',
        borderRadius: '6px',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    };
  }, [theme.palette.action.hover]);
};

export const useSxProps = () => {
  const theme = useTheme();
  return useMemo(() => {
    return {
      options: {
        visibility: 'collapse',
        position: 'absolute',
        width: 'fit-content',
        right: 0,
        boxShadow: `0 0 12px 4px ${theme.grey.solid.min}`,
        backgroundColor: theme.grey.solid.min,
      },
      actionButton: {
        backgroundColor: theme.palette.background.alternate,
        border: `1px solid ${theme.palette.border}`,
      },
      box: {
        cursor: 'pointer',
        '&:hover': {
          '& .MuiIconButton-root': {
            visibility: 'visible',
          },
        },
        '&:focus-within': {
          '& .MuiIconButton-root': {
            visibility: 'visible',
          },
        },
      },
      one: {
        whiteSpace: 'nowrap',
        marginRight: theme.spacing(1),
      },
      shadow: {
        padding: theme.spacing(0.5, 0.75),
      },
      linkedInterviewLabel: {
        padding: theme.spacing(0, 1),
      },
      linkedInterviewChip: {
        color: theme.palette.primary.main,
      },
      linkedSeatChip: {
        margin: theme.spacing(0.5, 0),
      },
      errorChip: {
        background: `${theme.palette.background.error} !important`,
        border: `1px solid ${theme.palette.error.main} !important`,
        color: `${theme.palette.error.main} !important`,
        '&:hover': {
          background: `${theme.palette.background.error} !important`,
        },
      },
      errorLabel: {
        color: theme.palette.error.main,
      },
    };
  }, [theme]);
};
