import React, { useRef, useState } from 'react';

import { JobStageInterviewSeatType } from 'src/generated/mloop-graphql';

import { getJobStageInterviewSeatById } from 'src/store/selectors/job-stage-interview-seat';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import { useSelector } from 'src/store';

import EditFreeformSeat from './FreeformSeat';
import EditLinkedSeat from './LinkedSeat';
import EditModuleSeat from './ModuleSeat';
import { BaseReadInterviewSeatModuleProps } from './types';

const BaseReadInterviewSeatModule = ({
  jobStageInterviewSeat,
  seatIndex,
  linkableInterviews,
  onEdit,
  onDelete,
  onUpdated,
  onInterviewerPreferenceChanged,
}: BaseReadInterviewSeatModuleProps): JSX.Element | null => {
  const rowRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);

  const originalSeat = useSelector((state): JobStageInterviewSeat | null => {
    if (jobStageInterviewSeat && jobStageInterviewSeat.interviewSeatId) {
      return getJobStageInterviewSeatById(state, jobStageInterviewSeat?.interviewSeatId);
    }
    return null;
  });

  const handleMouseOver = () => {
    setShowOptions(true);
  };

  const handleMouseOut = () => {
    setShowOptions(false);
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    onDelete();

    // This is to prevent onEdit from trigging which is attached to the parent <Grid />
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  if (jobStageInterviewSeat.type === JobStageInterviewSeatType.Module) {
    return (
      <EditModuleSeat
        jobStageInterviewSeat={jobStageInterviewSeat}
        rowRef={rowRef}
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
        seatIndex={seatIndex}
        onEdit={onEdit}
        showOptions={showOptions}
        handleDelete={handleDelete}
        onUpdated={onUpdated}
      />
    );
  }

  if (jobStageInterviewSeat.type === JobStageInterviewSeatType.Freeform) {
    if (!jobStageInterviewSeat.employeeIds) return null;

    return (
      <EditFreeformSeat
        jobStageInterviewSeat={jobStageInterviewSeat}
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
        seatIndex={seatIndex}
        onEdit={onEdit}
        showOptions={showOptions}
        handleDelete={handleDelete}
        onInterviewerPreferenceChanged={onInterviewerPreferenceChanged}
      />
    );
  }

  if (jobStageInterviewSeat.type === JobStageInterviewSeatType.Linked) {
    if (!originalSeat) return null;

    return (
      <EditLinkedSeat
        jobStageInterviewSeat={jobStageInterviewSeat}
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
        seatIndex={seatIndex}
        onEdit={onEdit}
        showOptions={showOptions}
        handleDelete={handleDelete}
        linkableInterviews={linkableInterviews}
        originalSeat={originalSeat}
      />
    );
  }

  return null;
};

export default BaseReadInterviewSeatModule;
