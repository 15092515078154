import React from 'react';

import BaseIcon, { Props } from './Icon';

const ArrowsUpDown = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5303 2.46967C12.3897 2.32902 12.1989 2.25 12 2.25C11.8011 2.25 11.6103 2.32902 11.4697 2.46967L8.96967 4.96967C8.67678 5.26256 8.67678 5.73744 8.96967 6.03033C9.26257 6.32322 9.73744 6.32322 10.0303 6.03033L11.25 4.81066L11.25 10C11.25 10.4142 11.5858 10.75 12 10.75C12.4142 10.75 12.75 10.4142 12.75 10L12.75 4.81066L13.9697 6.03033C14.2626 6.32322 14.7374 6.32322 15.0303 6.03033C15.3232 5.73744 15.3232 5.26256 15.0303 4.96967L12.5303 2.46967ZM8.75 10C8.75 9.58579 8.41421 9.25 8 9.25C7.58579 9.25 7.25 9.58579 7.25 10L7.25 15.1893L6.03033 13.9697C5.73744 13.6768 5.26256 13.6768 4.96967 13.9697C4.67678 14.2626 4.67678 14.7374 4.96967 15.0303L7.46967 17.5303C7.76256 17.8232 8.23744 17.8232 8.53033 17.5303L11.0303 15.0303C11.3232 14.7374 11.3232 14.2626 11.0303 13.9697C10.7374 13.6768 10.2626 13.6768 9.96967 13.9697L8.75 15.1893L8.75 10Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ArrowsUpDown;
