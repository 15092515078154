import { useMemo, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';
import { merge, uniqBy } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

import { EmployeesQuery, useEmployeeByIdsQuery, useEmployeesQuery } from 'src/generated/mloop-graphql';

import useOrgId from 'src/hooks/useOrgId';

import { DEBOUNCE_TIMEOUT, PAGE_SIZE } from 'src/constants';

import { Categories, SMART_OPTIONS_INTERVIEWERS_ID, SMART_OPTIONS_SCHEDULER_ID } from './types';

type Props = {
  showSmartOptions?: boolean;
  interviewerIds?: string[];
};

const useEmployeeSelectData = ({ interviewerIds, showSmartOptions }: Props) => {
  const [search, setSearch] = useState('');
  const debouncedSetSearch = useDebouncedCallback(setSearch, DEBOUNCE_TIMEOUT);
  const orgId = useOrgId();
  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const { data, loading, error, fetchMore, updateQuery } = useEmployeesQuery({
    variables: {
      input: {
        pageInput: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        isAtsDisabled: false,
        isDirectoryDisabled: false,
        search,
      },
    },
  });

  const { data: interviewersData } = useEmployeeByIdsQuery({
    variables: { input: interviewerIds },
    skip: !interviewerIds?.length,
  });

  const options = useMemo(() => {
    const employees =
      data?.employees?.items?.filter((employee) => !!employee?.id && !interviewerIds?.includes(employee.id)) ?? [];
    const memoOptions = employees.map((employee) => ({ type: Categories.AllEmployees, employee }));

    if (showSmartOptions) {
      memoOptions.unshift({
        type: Categories.SmartOptions,
        employee: {
          id: SMART_OPTIONS_INTERVIEWERS_ID,
          fullName: 'Interviewers',
          orgId,
          email: '',
          isDirectoryDisabled: false,
        },
      });

      if (scheduleLocationEnabled) {
        memoOptions.unshift({
          type: Categories.SmartOptions,
          employee: {
            id: SMART_OPTIONS_SCHEDULER_ID,
            fullName: 'Scheduler',
            orgId,
            email: '',
            isDirectoryDisabled: false,
          },
        });
      }
    }

    if (interviewersData?.employeeByIds?.length) {
      memoOptions.unshift(
        ...interviewersData.employeeByIds.map((employee) => ({
          type: Categories.InterviewPanel,
          employee,
        }))
      );
    }

    return memoOptions;
  }, [
    data?.employees?.items,
    interviewerIds,
    interviewersData?.employeeByIds,
    orgId,
    scheduleLocationEnabled,
    showSmartOptions,
  ]);

  const handleScrollToEnd = () => {
    if (!data?.employees?.items || loading || !data?.employees?.nextCursor || !fetchMore) {
      return;
    }

    fetchMore({
      variables: {
        input: {
          pageInput: {
            cursor: data?.employees?.nextCursor,
            limit: PAGE_SIZE,
          },
          isAtsDisabled: false,
          isDirectoryDisabled: false,
          search,
        },
      },
    })?.then((result: ApolloQueryResult<EmployeesQuery>) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      if (!updateQuery) return;

      updateQuery((prevResult: EmployeesQuery) => {
        const { employees } = prevResult;
        const newResults = merge({}, prevResult, result.data);

        if (newResults.employees?.items) {
          newResults.employees.items = uniqBy(
            [...(employees?.items || []), ...(result.data?.employees?.items || [])],
            'id'
          );
        }

        return { ...newResults };
      });
    });
  };

  return {
    options,
    loading,
    error,
    search,
    handleScrollToEnd,
    handleInputChange: debouncedSetSearch,
    hasMore: Boolean(data?.employees?.nextCursor),
  };
};

export default useEmployeeSelectData;
