import React, { useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { getCurrentDateIso, isAfterNow, isBeforeNow, isWithinInterval } from '@modernloop/shared/datetime';
import { getExternalErrorMessage } from '@modernloop/shared/utils';
import { Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  CancelScheduleDialog_ApplicationStageFragment,
  CancelScheduleDialog_InterviewsFragment,
} from 'src/generated/mloop-graphql';

import AtsName from 'src/entities/Ats/AtsName';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useDirectoryInfo from 'src/hooks/useDirectoryInfo';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import cancelSchedule, { DeleteType } from 'src/utils/api/cancelSchedule';

type Props = {
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
};

type Fragments = {
  applicationStage: CancelScheduleDialog_ApplicationStageFragment;
  interviews: CancelScheduleDialog_InterviewsFragment[];
};

const CancelScheduleDialog: FCWithFragments<Fragments, Props> = ({
  applicationStage,
  interviews,
  onSuccess,
  onError,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const directoryInfo = useDirectoryInfo();

  const refetchActivityFeed = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();

  const getInitialDeleteType = () => {
    if (isAfterNow(applicationStage?.minStartAt)) {
      return DeleteType.UPCOMING;
    }
    if (isBeforeNow(applicationStage?.maxEndAt)) {
      return DeleteType.PAST;
    }
    const isUpcomingInterview = interviews?.find((interview) => !!isAfterNow(interview?.startAt));
    if (!isUpcomingInterview) {
      return DeleteType.ALL;
    }
    return DeleteType.UPCOMING;
  };

  const [deleteType, setDeleteType] = useState<DeleteType>(getInitialDeleteType());
  const [canceling, setCanceling] = useState<boolean>(false);

  const handleDeleteTypeChange = (event: React.SyntheticEvent, value: DeleteType) => {
    setDeleteType(value);
  };

  const handleCancel = async () => {
    setCanceling(true);
    try {
      await cancelSchedule({ applicationStageId: applicationStage?.id, deleteType });
      refetchScheduleTaskQuery();
      refetchActivityFeed();
      enqueueSnackbar(`Schedule marked as canceled`, { variant: 'success' });

      onSuccess();
    } catch (error) {
      const errorMsg = getExternalErrorMessage(error) || `Failed to mark schedule as canceled`;
      enqueueSnackbar(errorMsg, { variant: 'error' });

      onError();
    }
  };

  const bodyCommonContent = useMemo((): JSX.Element => {
    return (
      <Stack direction="column" spacing={2}>
        <Typography>
          All records of selected interviews in <AtsName /> will be deleted, including any related scorecards that have
          been submitted.
        </Typography>
        <Typography>
          Events in {directoryInfo.label} Calendar for your candidate and interviewers will be deleted.
        </Typography>
      </Stack>
    );
  }, [directoryInfo.label]);

  const getBody = (): JSX.Element => {
    if (
      isWithinInterval(getCurrentDateIso(), { start: applicationStage?.minStartAt, end: applicationStage?.maxEndAt })
    ) {
      const isUpcomingInterview = interviews?.find((interview) => !!isAfterNow(interview?.startAt));

      return (
        <Stack direction="column" spacing={2}>
          {isUpcomingInterview && (
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="deleteType"
                name="deleteType"
                value={deleteType}
                onChange={handleDeleteTypeChange}
              >
                <FormControlLabel
                  value={DeleteType.ALL}
                  control={<Radio color="primary" />}
                  label="All interviews in this schedule"
                />
                <FormControlLabel
                  value={DeleteType.UPCOMING}
                  control={<Radio color="primary" />}
                  label="Upcoming interviews only"
                />
              </RadioGroup>
            </FormControl>
          )}

          {isUpcomingInterview && <Divider />}

          {bodyCommonContent}
        </Stack>
      );
    }

    return bodyCommonContent;
  };

  return (
    <Dialog
      dataTestId="schedule-object-cancel-schedule-dialog"
      title="Mark as canceled?"
      subTitle="This cannot be undone."
      open
      onClose={onClose}
      submitOptions={{
        label: 'Mark as canceled',
        isDisabled: canceling,
        isLoading: canceling,
        isDangerous: true,
        onClick: handleCancel,
      }}
      cancelOptions={{
        label: 'Keep schedule',
        onClick: onClose,
      }}
    >
      {getBody()}
    </Dialog>
  );
};

CancelScheduleDialog.fragments = {
  applicationStage: gql`
    fragment CancelScheduleDialog_applicationStage on ApplicationStage {
      id
      minStartAt
      maxEndAt
    }
  `,
  interviews: gql`
    fragment CancelScheduleDialog_interviews on ApplicationStageInterview {
      id
      startAt
    }
  `,
};

export default CancelScheduleDialog;
