import { SvgIcon, SvgIconProps } from './SvgIcon';

export const QuestionCircleIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.75C5.99594 2.75 2.75 5.99594 2.75 10C2.75 14.0041 5.99594 17.25 10 17.25C14.0041 17.25 17.25 14.0041 17.25 10C17.25 5.99594 14.0041 2.75 10 2.75ZM1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10ZM11 14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14C9 13.4477 9.44771 13 10 13C10.5523 13 11 13.4477 11 14ZM8.48365 7.62554C8.78736 7.10051 9.35319 6.75 9.99999 6.75C10.9665 6.75 11.75 7.5335 11.75 8.5C11.75 9.4665 10.9665 10.25 9.99999 10.25C9.58578 10.25 9.24999 10.5858 9.24999 11C9.24999 11.4142 9.58578 11.75 9.99999 11.75C11.7949 11.75 13.25 10.2949 13.25 8.5C13.25 6.70507 11.7949 5.25 9.99999 5.25C8.79609 5.25 7.74605 5.90498 7.18524 6.87446C6.97783 7.233 7.10035 7.6918 7.4589 7.8992C7.81744 8.10661 8.27624 7.98409 8.48365 7.62554Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
