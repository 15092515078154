import { gql } from '@apollo/client';

export const EmployeeFragment = gql`
  fragment Employee on Employee {
    fullName
    email
    id
    orgId
    slackImageUrl
    timezone
    atsId
    isDirectoryDisabled
    isArchived
    isAtsDisabled
    hasAtsId
    title
  }
`;

export const EmployeeWorkHoursFragment = gql`
  fragment EmployeeWorkHours on Employee {
    workHours {
      monday {
        start
        end
      }
      tuesday {
        start
        end
      }
      wednesday {
        start
        end
      }
      thursday {
        start
        end
      }
      friday {
        start
        end
      }
      saturday {
        start
        end
      }
      sunday {
        start
        end
      }
    }
  }
`;

export const EmployeeAttributesFragment = gql`
  fragment EmployeeAttributes on Employee {
    id
    isArchived
    fullName
    email
    timezone
    linkedinUrl
    title
    googleUserId
    isDirectoryDisabled
    isAtsDisabled
    hasAtsId
    atsId
    manager {
      id
      fullName
      slackImageUrl
    }
    atsUser {
      atsId
      fullName
      email
      isDisabled
    }
  }
`;

export const EmployeeDetailsFragment = gql`
  ${EmployeeWorkHoursFragment}
  ${EmployeeFragment}
  ${EmployeeAttributesFragment}
  fragment EmployeeDetailsFragment on Employee {
    ...Employee
    linkedinUrl
    googleUserId
    givenName
    useCalendarTimezone
    calendarTimezone
    interviewPauseDates {
      start
      end
    }
    ...EmployeeAttributes
    ...EmployeeWorkHours
  }
`;

export const EmployeeViaES = gql`
  query EmployeeList($input: EmployeeSearchInput!) {
    employeeSearch(input: $input) {
      total
      items {
        ...Employee
        upcomingInterviewsCount
        modulesTrainedCount
        modulesInTrainingCount
      }
    }
  }
`;

export const EmployeeQuery = gql`
  ${EmployeeFragment}
  query Employees($input: EmployeesInput!) {
    employees(input: $input) {
      nextCursor
      items {
        ...Employee
      }
    }
  }
`;

export const EmployeeByIds = gql`
  ${EmployeeFragment}
  query EmployeeByIds($input: [uuid!]!) {
    employeeByIds(ids: $input) {
      ...Employee
    }
  }
`;

export const EmployeeDetailsQuery = gql`
  ${EmployeeDetailsFragment}
  query EmployeeDetails($id: uuid!) {
    employee(id: $id) {
      ...EmployeeDetailsFragment
    }
  }
`;

export const UpdateEmployee = gql`
  mutation UpdateEmployee($input: EmployeeUpdateInput!) {
    employeeUpdate(input: $input) {
      employee {
        ...Employee
      }
    }
  }
`;

export const employeesWorkHoursQuery = gql`
  query EmployeesWorkHours($ids: [uuid!]!) {
    employeeByIds(ids: $ids) {
      id
      timezone
      ...EmployeeWorkHours
    }
  }
`;
