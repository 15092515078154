import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskRescheduleLogCreated_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import ActivityLogText from 'src/entities/ActivityLog/Helpers/ActivityLogText';

interface Fragments {
  activityLog: ActivityLogTaskRescheduleLogCreated_ActivityLogFragment;
}

const ActivityLogTaskRescheduleLogCreated: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (!details || details.__typename !== 'ActivityLogRescheduleLogDetails') {
    throw new Error('ActivityLogTaskRescheduleLogCreated: details is not ActivityLogRescheduleLogDetails');
  }

  const { rescheduleLog } = details;
  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={
        <ActivityLogText>
          logged a reschedule:&nbsp;
          {rescheduleLog?.rescheduleReason?.reason || ''}
        </ActivityLogText>
      }
    />
  );
};

ActivityLogTaskRescheduleLogCreated.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}

    fragment ActivityLogTaskRescheduleLogCreated_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogRescheduleLogDetails {
          __typename
          rescheduleLog {
            id
            rescheduleReason {
              reason
            }
            note
          }
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskRescheduleLogCreated;
