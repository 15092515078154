import { MinusIcon, PlusIcon } from '@modernloop/shared/icons';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import { FC } from 'react';

type Props = {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  increment?: number;
};

const CounterNumberInput: FC<Props> = ({ value, onChange, min = 0, max, increment = 1 }) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) >= max) {
      onChange(max);
      return;
    } else if (Number(event.target.value) <= min) {
      onChange(min);
      return;
    }
    onChange(Number(event.target.value));
  };
  return (
    <Stack display="flex" flexDirection="row" alignItems="center" columnGap={1}>
      <IconButton
        onClick={() => {
          if (Number(value) > min) {
            onChange(value - increment);
            return;
          }
          onChange(min || 0);
        }}
      >
        <MinusIcon fontSize="medium" />
      </IconButton>
      <Box width="60px">
        <TextField value={value} onChange={handleOnChange} inputProps={{ type: 'number' }} variant="outlined" />
      </Box>
      <IconButton
        onClick={() => {
          if (max && Number(value) < max) {
            onChange(value + increment);
            return;
          }
          onChange(max || 0);
        }}
      >
        <PlusIcon fontSize="medium" />
      </IconButton>
    </Stack>
  );
};

export default CounterNumberInput;
