import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type Candidate = {
  id: string;
  givenName: string;
  fullName: string;
  familyName: string;
  email: string;
  emailAddresses: string[];
  phoneNumbers: string[];
  recruiterId: string | null;
  updatedAt: string;
  createdAt: string;
  coordinatorId: string | null;
  applicationIds: string[];
  atsId: string;
  atsUrl: string;
};

export type UpdateCandidatePayload = Pick<Candidate, 'id'> & Partial<Omit<Candidate, 'id'>>;

type CandidateState = {
  byId: { [key: string]: Candidate };
  loadingById: { [key: string]: boolean };
  errorById: { [key: string]: string };
};

const getInitialState = (): CandidateState => {
  return {
    byId: {},
    loadingById: {},
    errorById: {},
  };
};

const candidateSlice = createSlice({
  name: 'jobs',
  initialState: getInitialState(),
  reducers: {
    addCandidate(state: CandidateState, action: PayloadAction<Candidate>) {
      const { payload } = action;
      state.byId[payload.id] = payload;
    },

    updateCandidate(state: CandidateState, action: PayloadAction<UpdateCandidatePayload>) {
      const { payload } = action;
      state.byId[payload.id] = {
        ...state.byId[payload.id],
        ...payload,
      };
    },

    deleteCandidate(state: CandidateState, action: PayloadAction<string>) {
      const { payload: candidateId } = action;
      delete state.byId[candidateId];
    },

    addCandidateError(state: CandidateState, action: PayloadAction<{ candidateId: string; error: string }>) {
      const { candidateId, error } = action.payload;
      state.errorById[candidateId] = error;
    },

    deleteCandidateError(state: CandidateState, action: PayloadAction<string>) {
      const { payload: candidateId } = action;
      delete state.errorById[candidateId];
    },

    addCandidateLoading(state: CandidateState, action: PayloadAction<{ candidateId: string; loading: boolean }>) {
      const { candidateId, loading } = action.payload;
      state.loadingById[candidateId] = loading;
    },

    deleteCandidateLoading(state: CandidateState, action: PayloadAction<string>) {
      const { payload: candidateId } = action;
      delete state.loadingById[candidateId];
    },
  },
});

export const { reducer } = candidateSlice;
export default candidateSlice;
