import React, { useMemo, useRef, useState } from 'react';

import { EyeCrossedIcon, EyeIcon } from '@modernloop/shared/icons';

import IconButton from 'src/components/IconButton';
import { MoreIcon } from 'src/components/icons';
import Menu, { MenuOption } from 'src/components/menu';

import { groupJobStageInterviewGroup, moveJobStageInterviewGroupId } from 'src/store/actions/job-stage';
import {
  hideJobStageInterviewFromCandidate,
  showJobStageInterviewToCandidate,
} from 'src/store/actions/job-stage-interview';
import { moveJobStageInterviewInGroup } from 'src/store/actions/job-stage-interview-group';
import { getFirstJobStageInterviewGroup, getLastJobStageInterviewGroup } from 'src/store/selectors/job-stage';
import { getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';
import {
  getJobStageInterviewGroupById,
  getStageInterviewGroupIndex,
} from 'src/store/selectors/job-stage-interview-group';

import { useDispatch, useSelector } from 'src/store';

export enum InterviewOptions {
  MOVE_UP = 'move_up',
  MOVE_DOWN = 'move_down',
  GROUP_WITH_ABOVE = 'group_with_above',
  GROUP_WITH_BELOW = 'group_with_below',
}

enum InterviewMenuOptions {
  HIDE_FROM_CANDIDATE = 'hide-from-candidate',
  SHOW_TO_CANDIDATE = 'show-to-candidate',
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type MoreMenuOptionsProps = {
  index: number;
  jobStageInterviewId: string;
  onUpdated: () => void;
};

const MoreMenuOptions = ({ index, jobStageInterviewId, onUpdated }: MoreMenuOptionsProps): JSX.Element => {
  const moreOptionsAnchorEl = useRef<HTMLButtonElement>(null);
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const dispatch = useDispatch();
  const jobStageInterview = useSelector((state) => getJobStageInterviewById(state, jobStageInterviewId));
  const group = useSelector((state) => getJobStageInterviewGroupById(state, jobStageInterview.jobStageGroupId));
  const firstGroup = useSelector((state) => getFirstJobStageInterviewGroup(state, jobStageInterview.jobStageId));
  const lastGroup = useSelector((state) => getLastJobStageInterviewGroup(state, jobStageInterview.jobStageId));
  const groupIndex = useSelector((state) => getStageInterviewGroupIndex(state, group.id));

  const options = useMemo(() => {
    /**
     * Default header as options
     */
    const menuOptions: MenuOption[] = [];

    /**
     * Show hide / show interview to candidate option
     */
    menuOptions.push({ isSubheader: true, value: 'Options' });
    if (jobStageInterview.isHiddenFromCandidate) {
      menuOptions.push({
        id: InterviewMenuOptions.SHOW_TO_CANDIDATE,
        value: 'Show to candidate',
        icon: <EyeIcon fontSize="small" />,
      });
    } else {
      menuOptions.push({
        id: InterviewMenuOptions.HIDE_FROM_CANDIDATE,
        value: 'Hide from candidate',
        icon: <EyeCrossedIcon fontSize="small" />,
      });
    }

    /**
     * Divider & header for organize options
     */

    menuOptions.push({ isDivider: true });
    menuOptions.push({ isSubheader: true, value: 'Organize' });

    /**
     * Disabled if slot is:
     * 1. First item in a group with more than one interview
     * 2. First item of first group.
     */
    menuOptions.push({
      id: InterviewOptions.MOVE_UP,
      value: 'Move up',
      disabled:
        (group.jobStageInterviewIds.length > 1 && index === 0) ||
        (firstGroup && firstGroup.jobStageInterviewIds[0] === jobStageInterviewId && groupIndex === 0),
    });

    /**
     * Disabled if slot is:
     * If it is the last item in group with more than one interview.
     * If it is the last item in last group.
     */
    menuOptions.push({
      id: InterviewOptions.MOVE_DOWN,
      value: 'Move down',
      disabled:
        (group.jobStageInterviewIds.length > 1 &&
          group.jobStageInterviewIds[group.jobStageInterviewIds.length - 1] === jobStageInterviewId) ||
        (lastGroup &&
          lastGroup.jobStageInterviewIds[lastGroup.jobStageInterviewIds.length - 1] === jobStageInterviewId),
    });

    /**
     * Show only if it is the only item in group
     */
    if (group.jobStageInterviewIds.length === 1) {
      /**
       * Disabled for first group
       */
      menuOptions.push({
        id: InterviewOptions.GROUP_WITH_ABOVE,
        value: 'Group with above',
        disabled: firstGroup && firstGroup.id === group.id,
      });
    }

    /**
     * Show only if it is the only item in group
     */
    if (group.jobStageInterviewIds.length === 1) {
      /**
       * Disabled for last group
       */
      menuOptions.push({
        id: InterviewOptions.GROUP_WITH_BELOW,
        value: 'Group with below',
        disabled: lastGroup && lastGroup.id === group.id,
      });
    }

    return menuOptions;
  }, [firstGroup, group, groupIndex, index, jobStageInterviewId, lastGroup, jobStageInterview.isHiddenFromCandidate]);

  const toggleMoreOptionsMenu = () => setShowMoreOptions(!showMoreOptions);

  const handleSelect = (option: MenuOption) => {
    toggleMoreOptionsMenu();

    switch (option.id) {
      case InterviewMenuOptions.HIDE_FROM_CANDIDATE: {
        dispatch(hideJobStageInterviewFromCandidate(jobStageInterviewId));
        break;
      }
      case InterviewMenuOptions.SHOW_TO_CANDIDATE: {
        dispatch(showJobStageInterviewToCandidate(jobStageInterviewId));
        break;
      }

      case InterviewOptions.MOVE_UP: {
        if (group.jobStageInterviewIds.length === 1) {
          dispatch(moveJobStageInterviewGroupId(jobStageInterview.jobStageId, group.id, groupIndex - 1));
        } else {
          dispatch(moveJobStageInterviewInGroup(group.id, jobStageInterviewId, index - 1));
        }
        break;
      }
      case InterviewOptions.MOVE_DOWN: {
        if (group.jobStageInterviewIds.length === 1) {
          dispatch(moveJobStageInterviewGroupId(jobStageInterview.jobStageId, group.id, groupIndex + 1));
        } else {
          dispatch(moveJobStageInterviewInGroup(group.id, jobStageInterviewId, index + 1));
        }
        break;
      }
      case InterviewOptions.GROUP_WITH_ABOVE: {
        dispatch(groupJobStageInterviewGroup(jobStageInterview.jobStageId, groupIndex, groupIndex - 1));
        break;
      }
      case InterviewOptions.GROUP_WITH_BELOW: {
        dispatch(groupJobStageInterviewGroup(jobStageInterview.jobStageId, groupIndex, groupIndex + 1));
        break;
      }
      default:
    }

    onUpdated();
  };

  return (
    <>
      <IconButton tooltip="More options" ref={moreOptionsAnchorEl} onClick={toggleMoreOptionsMenu}>
        <MoreIcon fontSize={20} />
      </IconButton>
      <Menu
        id="interview-menu-options"
        options={options}
        anchorEl={moreOptionsAnchorEl.current}
        open={showMoreOptions}
        disableScrollLock
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onSelect={handleSelect}
        onClose={toggleMoreOptionsMenu}
      />
    </>
  );
};

export default MoreMenuOptions;
