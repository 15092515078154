import React, { FC, useMemo } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box, useTheme } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ApexOptions } from 'apexcharts';
import { merge } from 'lodash';

import Label from 'src/components/label';

import { Theme } from 'src/theme/type';

import Stack from '../Stack';
import MaybeTooltip from '../tooltip/MaybeTooltip';

import Chart, { DataPoints, ZeroStateProps, ZeroStateWrapper } from './index';

const DEFAULT_COLORS: string[] = [];

export type Customlabel = {
  key: string;
  label: string;
  percentage: number;
  value: number;
  color: string;
};

export type DataKey = {
  key: string;
  label: string;
};

const defaultApexOptions: ApexOptions = {
  noData: {
    text: 'No data',
    align: 'center',
    verticalAlign: 'middle',
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
    },
  },
  stroke: {
    width: 0,
  },
  legend: {
    fontSize: '16px',
    fontFamily: 'Inter',
  },
  tooltip: {
    fillSeriesColor: false,
  },
};

const useStyles = makeStyles(() =>
  createStyles({
    marker: {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
    },
  })
);

interface Props {
  series: DataPoints;
  dataKeys: DataKey[];
  zeroState: Omit<ZeroStateProps, 'series' | 'type'>;
  options?: Omit<ApexOptions, 'series'>;
  width?: number;
  height?: number;
  showCustomLabels?: boolean;
}

const PieChart: FC<Props> = ({ options, series, dataKeys, width, height, zeroState, showCustomLabels }) => {
  const theme: Theme = useTheme();
  const classes = useStyles();

  const colors =
    options?.colors ||
    DEFAULT_COLORS.concat([
      theme.palette.background.info,
      theme.palette.action.focus,
      theme.palette.primary.light,
      theme.palette.primary.main,
      theme.palette.primary.dark,
      theme.palette.tertiary.main,
      theme.palette.tertiary.dark,
    ]);

  const computedSeries = useMemo(() => {
    return dataKeys.map((d) => {
      return (
        series &&
        series.reduce((partialSum, a) => {
          if (a.x === d.key && a.y) {
            return partialSum + a.y;
          }
          return partialSum;
        }, 0)
      );
    });
  }, [dataKeys, series]);

  const customlabels: Customlabel[] = useMemo(() => {
    if (!showCustomLabels) return [];
    const total = computedSeries.reduce((partialSum, val) => {
      if (val) {
        return partialSum + val;
      }
      return partialSum;
    }, 0);
    return dataKeys.map((d, index) => {
      const val = computedSeries[index] || 0;
      return {
        key: d.key,
        label: d.label,
        percentage: total ? (val / total) * 100 : 0,
        value: val,
        color: colors[index],
      };
    });
  }, [colors, computedSeries, dataKeys, showCustomLabels]);

  const labels = useMemo(() => {
    return dataKeys.map((d) => {
      return d.label;
    });
  }, [dataKeys]);

  if (!dataKeys.length) return null;

  const apexOptions: ApexOptions = merge(
    { ...defaultApexOptions },
    {
      colors,
      labels,
      legend: {
        show: !showCustomLabels,
      },
    },
    options
  );

  if (showCustomLabels) {
    return (
      <ZeroStateWrapper series={computedSeries} {...zeroState} type="pie">
        <Stack itemStyles={{ 0: { width: '45%' }, 1: { width: '55%' } }}>
          <Chart
            options={apexOptions}
            series={computedSeries}
            width={width}
            height={height}
            type={options?.chart?.type || 'pie'}
          />
          <Box mt={2}>
            <Stack direction="column" spacing={1} wrap="nowrap">
              {customlabels.map((customLabel) => (
                <Stack
                  wrap="nowrap"
                  spacing={1}
                  key={customLabel.key}
                  itemStyles={{ 0: { width: '60%' }, 1: { width: '20%' }, 2: { width: '20%' } }}
                >
                  <Stack alignItems="center" spacing={1} wrap="nowrap">
                    <div className={classes.marker} style={{ backgroundColor: customLabel.color }} />
                    <MaybeTooltip label={customLabel.label || ''} tooltip={customLabel.label || ''} />
                  </Stack>
                  <Label variant="body" color="high-contrast-grey">
                    {customLabel.percentage.toFixed(1)}%
                  </Label>
                  <Label variant="body" color="high-contrast-grey">
                    {customLabel.value}
                  </Label>
                </Stack>
              ))}
            </Stack>
          </Box>
        </Stack>
      </ZeroStateWrapper>
    );
  }

  return (
    <ZeroStateWrapper series={computedSeries} {...zeroState} type="pie">
      <Stack direction="row">
        <Chart
          options={apexOptions}
          series={computedSeries}
          width={width}
          height={height}
          type={options?.chart?.type || 'pie'}
        />
      </Stack>
    </ZeroStateWrapper>
  );
};

export default PieChart;
