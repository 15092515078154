import React from 'react';

import BaseIcon, { Props } from './Icon';

const AllSchedulingTasksFilled = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0511 3.50871C8.33206 3.20435 8.31308 2.72985 8.00871 2.4489C7.70435 2.16795 7.22985 2.18693 6.9489 2.49129L4.69923 4.92843L3.52023 3.7931C3.22187 3.50578 2.74708 3.51474 2.45976 3.81311C2.17245 4.11147 2.1814 4.58626 2.47977 4.87358L4.21054 6.54024C4.3558 6.68012 4.55126 6.75561 4.75283 6.74968C4.9544 6.74375 5.14509 6.65689 5.28187 6.50871L8.0511 3.50871ZM9.25 4.5C9.25 4.08579 9.58579 3.75 10 3.75H17C17.4142 3.75 17.75 4.08579 17.75 4.5C17.75 4.91422 17.4142 5.25 17 5.25H10C9.58579 5.25 9.25 4.91422 9.25 4.5ZM8.0511 9.00871C8.33206 8.70434 8.31308 8.22985 8.00871 7.9489C7.70435 7.66794 7.22985 7.68692 6.9489 7.99129L4.69923 10.4284L3.52023 9.29309C3.22187 9.00578 2.74708 9.01474 2.45976 9.3131C2.17244 9.61147 2.1814 10.0863 2.47977 10.3736L4.21054 12.0402C4.3558 12.1801 4.55126 12.2556 4.75283 12.2497C4.9544 12.2437 5.14509 12.1569 5.28187 12.0087L8.0511 9.00871ZM9.25 10C9.25 9.58579 9.58579 9.25 10 9.25H17C17.4142 9.25 17.75 9.58579 17.75 10C17.75 10.4142 17.4142 10.75 17 10.75H10C9.58579 10.75 9.25 10.4142 9.25 10ZM8.0511 14.5087C8.33206 14.2043 8.31308 13.7299 8.00871 13.4489C7.70435 13.1679 7.22985 13.1869 6.9489 13.4913L4.69923 15.9284L3.52023 14.7931C3.22187 14.5058 2.74708 14.5147 2.45976 14.8131C2.17245 15.1115 2.1814 15.5863 2.47977 15.8736L4.21054 17.5402C4.3558 17.6801 4.55126 17.7556 4.75283 17.7497C4.9544 17.7437 5.14509 17.6569 5.28187 17.5087L8.0511 14.5087ZM10 14.75C9.58579 14.75 9.25 15.0858 9.25 15.5C9.25 15.9142 9.58579 16.25 10 16.25H17C17.4142 16.25 17.75 15.9142 17.75 15.5C17.75 15.0858 17.4142 14.75 17 14.75H10Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default AllSchedulingTasksFilled;
