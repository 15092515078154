import { ThemeOptions } from '@mui/material';

/**
 * View Select props and classes at https://mui.com/material-ui/api/switch/#classes
 */
export const Switch: ThemeOptions['components'] = {
  MuiSwitch: {
    defaultProps: {},
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        return {
          width: ownerState.size === 'medium' ? 42 : 34,
          height: ownerState.size === 'medium' ? 22 : 18,
          padding: 0,
          margin: theme.spacing(0.625, 1),
        };
      },
      switchBase: ({ theme, ownerState }) => ({
        padding: 0,
        margin: 3,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: `translateX(${ownerState.size === 'medium' ? 20 : 16}px)`,
          color: theme.palette.primary.contrastText,
          '& + .MuiSwitch-track': {
            opacity: 1,
          },
          '&.Mui-disabled + .MuiSwitch-thumb': {
            backgroundColor: theme.palette.action.disabledBackground,
            opacity: 1,
          },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.action.disabled,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: theme.palette.action.disabledBackground,
          opacity: 1,
        },
      }),
      thumb: ({ ownerState }) => {
        return {
          boxSizing: 'border-box',
          width: ownerState.size === 'medium' ? 16 : 12,
          height: ownerState.size === 'medium' ? 16 : 12,
          padding: ownerState.size === 'medium' ? 4 : 0,
        };
      },
      track: ({ theme, ownerState }) => ({
        borderRadius: ownerState.size === 'medium' ? 11 : 9,
        opacity: 0.38,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      }),
      sizeSmall: () => ({
        '& .MuiSwitch-switchBase': {
          padding: 0,
        },
        '& .MuiSwitch-thumb': {
          width: 12,
          height: 12,
        },
      }),
    },
  },
};
