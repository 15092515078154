import React, { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box, Card, CardHeader, CircularProgress } from '@material-ui/core';
import { Typography } from '@mui/material';

import IconButton from 'src/components/IconButton';
import Paper from 'src/components/Paper';
import Stack from 'src/components/Stack';
import { DownloadIcon, InfoIcon } from 'src/components/icons';
import Label from 'src/components/label';
import Tooltip from 'src/components/tooltip';

interface Props {
  header: string;
  subHeader: string;
  onDownload?: () => void;
  downloading?: boolean;
  infoTooltip?: string | JSX.Element;
}

const SectionWrap: FC<Props> = ({ header, subHeader, onDownload, children, downloading, infoTooltip }) => {
  return (
    <Card style={{ boxShadow: 'none' }}>
      <CardHeader
        style={{
          padding: '8px',
        }}
        title={
          <Label fontWeight={600} variant="body">
            {header}
          </Label>
        }
        subheader={
          <Label variant="captions" color="high-contrast-grey" fontWeight={400}>
            {subHeader}
          </Label>
        }
        action={
          <Stack alignItems="center">
            {infoTooltip && (
              <Tooltip
                tooltip={
                  <Typography variant="body2" color="text.primary">
                    {infoTooltip}
                  </Typography>
                }
              >
                <InfoIcon />
              </Tooltip>
            )}
            <IconButton aria-label="download" onClick={onDownload}>
              {downloading ? <CircularProgress size={20} /> : <DownloadIcon fontSize={20} />}
            </IconButton>
          </Stack>
        }
      />
      <Box m={1} mt={0}>
        <Paper>{children}</Paper>
      </Box>
    </Card>
  );
};

export default SectionWrap;
