import { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { useSnackbar } from 'notistack';

import { CodeLinkType, UseCreateCodingUrls_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { setScheduleContentStepWaiting } from 'src/slices/scheduling';

import { addCodeLinkType, setCodingUrl } from 'src/store/actions/schedule-communications';
import { getAllJobStageInterviewByJobStageId } from 'src/store/selectors/job-stage-interview';
import { getAllCodingUrls } from 'src/store/selectors/schedule-communications';
import { getCandidateEmail, getCandidateFullName, getInterviewerEventContents } from 'src/store/selectors/scheduling';

import createCodingInterviewUrl from 'src/utils/api/createCodingInterviewUrl';
import logError from 'src/utils/logError';

import { useDispatch, useSelector } from 'src/store';

type Fragments = {
  interviewPlan: UseCreateCodingUrls_InterviewPlanFragment | undefined;
};

type Props = {
  scheduleId: string;
};

const useCreateCodingUrls: FunctionWithFragments<Fragments, Props, void> = ({ interviewPlan }, { scheduleId }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [run, setRun] = useState(false);
  const interviewPlanentityInScheduleFlowEnabled = useFlag('interview_plan_entity_in_schedule_flows');

  const interviewContents = useSelector(getInterviewerEventContents);
  const schedule = useScheduleWithoutBreaks(scheduleId);
  const interviews = useSelector((state) => {
    if (!schedule || !schedule.jobStageId) return [];
    return getAllJobStageInterviewByJobStageId(state, schedule.jobStageId);
  });
  const candidateEmail = useSelector(getCandidateEmail);
  const candidateName = useSelector(getCandidateFullName) || '';
  const allCodingUrls = useSelector((state) => getAllCodingUrls(state, scheduleId));

  useEffect(() => {
    if (!interviewContents || run || !candidateEmail) return;

    const interviewsFromFragment = interviewPlan?.jobStageInterviewGroups?.flatMap(
      (group) => group.jobStageInterviews || []
    );

    const promises: Promise<void>[] = [];

    dispatch(setScheduleContentStepWaiting(true));

    interviewContents.forEach((interviewContent) => {
      const { slotId } = interviewContent.event;
      const interviewFromStore = interviews.find((i) => i.id === slotId);
      const interviewFromFragment = interviewsFromFragment?.find((i) => i.id === slotId);
      const interview = interviewPlanentityInScheduleFlowEnabled ? interviewFromFragment : interviewFromStore;

      const dynamicLinkTypes = interview?.dynamicLinkTypes;
      if (dynamicLinkTypes && dynamicLinkTypes.length) {
        const codeLinkType = dynamicLinkTypes[0] as unknown as CodeLinkType;
        const codingUrlsByType = allCodingUrls ? allCodingUrls[codeLinkType] : undefined;

        if (!slotId) return;

        // When in update flow it is possible that there is an existing coding url for this slot.
        // In that case we don't need to create a new one.
        if (codingUrlsByType && codingUrlsByType[slotId]) return;

        const p = createCodingInterviewUrl(codeLinkType, {
          candidateEmail,
          candidateName,
        }) // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line promise/always-return
          .then((url: string) => {
            dispatch(addCodeLinkType(scheduleId, codeLinkType));
            dispatch(setCodingUrl(scheduleId, codeLinkType, slotId, url));
          })
          .catch((error) => {
            logError(error);
            enqueueSnackbar(`Something went wrong while creating ${codeLinkType} link`, {
              variant: 'error',
            });
          });
        promises.push(p);
      }
    });

    // Wait for all code links to be created and then refetch schedule content.
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    Promise.all(promises).finally(() => {
      dispatch(setScheduleContentStepWaiting(false));
    });

    setRun(true);
  }, [
    allCodingUrls,
    candidateEmail,
    candidateName,
    dispatch,
    enqueueSnackbar,
    interviewContents,
    interviewPlan?.jobStageInterviewGroups,
    interviewPlanentityInScheduleFlowEnabled,
    interviews,
    run,
    scheduleId,
  ]);
};

useCreateCodingUrls.fragments = {
  interviewPlan: gql`
    fragment useCreateCodingUrls_interviewPlan on JobStage {
      id
      jobStageInterviewGroups {
        id
        jobStageInterviews {
          id
          dynamicLinkTypes
        }
      }
    }
  `,
};

export default useCreateCodingUrls;
