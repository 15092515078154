import React, { FC } from 'react';

import useHasOrgAdminUser from 'src/hooks/useHasOrgAdminUser';

import AppWideBanner from './AppWideBanner';

const MissingOrgAdminUserBanner: FC = () => {
  const { loading, hasAdmin } = useHasOrgAdminUser();
  if (loading) {
    // No need to show the banner while loading
    return null;
  }

  if (hasAdmin) {
    // No need to show the banner if the org has an admin
    return null;
  }

  return (
    <AppWideBanner
      backgroundColor="warning"
      color="max-contrast-grey"
      text="Your org does not currently have an admin set. Please contact ModernLoop customer support to add one."
    />
  );
};

export default MissingOrgAdminUserBanner;
