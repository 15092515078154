import React, { useEffect } from 'react';

import { gql } from '@apollo/client';
import { Dialog } from '@modernloop/shared/components';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import {
  ScheduleIssuesDialogApplicationStageInterviewFragment,
  ScheduleIssuesDialogApplicationStageInterviewInterviewersFragment,
  useInterviewInterviewerDeclinedLazyQuery,
} from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import ImageBlock from 'src/components/ImageBlock';

import useDisplayTimezone from 'src/hooks/useDisplayTimezone';

import { formatRange } from 'src/utils/dateUtils';

import { InterviewDeclineReasonDescription } from './type';

export const JobStageFragment = gql`
  fragment ScheduleIssuesDialogApplicationStageInterview on ApplicationStageInterview {
    id
    atsInterviewDefinition {
      atsId
      name
    }
    startAt
    endAt
  }
`;

export const ScheduleIssuesDialogApplicationStageInterviewInterviewers = gql`
  fragment ScheduleIssuesDialogApplicationStageInterviewInterviewers on ApplicationStageInterviewInterviewer {
    applicationStageInterviewId
    interviewerId
    interviewer {
      id
      fullName
      slackImageUrl
    }
    declines {
      source
      reason
      comment
    }
  }
`;

export const Query = gql`
  query InterviewInterviewerDeclined($id: uuid!) {
    applicationStage(id: $id) {
      id
      applicationStageInterviews(input: { isCancelled: false }) {
        ...ScheduleIssuesDialogApplicationStageInterview
        applicationStageInterviewInterviewers {
          ...ScheduleIssuesDialogApplicationStageInterviewInterviewers
        }
      }
    }
  }
`;

type DeclinedResult = {
  interview: ScheduleIssuesDialogApplicationStageInterviewFragment;
  interviewer: ScheduleIssuesDialogApplicationStageInterviewInterviewersFragment;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleIssuesDialogProps = {
  applicationStageId: string;
  onClose: () => void;
};

const ScheduleIssuesDialog = ({ applicationStageId, onClose }: ScheduleIssuesDialogProps): JSX.Element => {
  const displayTimezone = useDisplayTimezone();
  const [fetchInterviewInterviewerDeclined, { loading, error, data }] = useInterviewInterviewerDeclinedLazyQuery();

  const declinedResult: DeclinedResult[] = [];
  data?.applicationStage?.applicationStageInterviews?.forEach((asi) => {
    asi?.applicationStageInterviewInterviewers?.forEach((interviewer) => {
      if (interviewer?.declines?.length) {
        declinedResult.push({
          interview: asi,
          interviewer,
        });
      }
    });
  });

  useEffect(() => {
    fetchInterviewInterviewerDeclined({ variables: { id: applicationStageId } });
  }, [applicationStageId, fetchInterviewInterviewerDeclined]);

  let jsx: JSX.Element | null = null;
  if (loading) {
    jsx = <ImageBlock image={<CircularProgress size={24} />} title="Loading…" />;
  } else if (error) jsx = <Typography>Error…</Typography>;
  else if (!declinedResult || declinedResult.length === 0) jsx = <Typography>No issues found</Typography>;
  else if (declinedResult.length) {
    jsx = (
      <Stack direction="column">
        {declinedResult.map((result) => {
          if (!result) return null;
          const { interviewer, interview } = result;

          const declined = result.interviewer.declines && result.interviewer.declines[0];

          const comment =
            declined?.comment ||
            InterviewDeclineReasonDescription.find((option) => option.value === declined?.reason)?.label ||
            '';

          const timeRange = formatRange(interview?.startAt, interview?.endAt, displayTimezone);

          const interviewName = interview.atsInterviewDefinition?.name;

          const title = (
            <Typography variant="body2">{`${interviewer?.interviewer?.fullName} declined ${interviewName}, ${timeRange}`}</Typography>
          );

          return (
            <Box
              p={1}
              sx={{
                '&:hover': {
                  backgroundColor: 'background.contrast',
                  borderRadius: '6px',
                },
              }}
              key={`${interviewer.applicationStageInterviewId}-${interviewer.interviewerId}`}
            >
              <ImageBlock
                alignItems="center"
                image={
                  <Avatar
                    alt={interviewer?.interviewer?.fullName || ''}
                    src={interviewer?.interviewer?.slackImageUrl ?? ''}
                  />
                }
                title={title}
                caption={`"${comment}"`}
              />
            </Box>
          );
        })}
      </Stack>
    );
  }

  return (
    <Dialog
      open
      onClose={onClose}
      title="Previous issues"
      cancelOptions={{
        onClick: onClose,
      }}
    >
      {jsx}
    </Dialog>
  );
};

export default ScheduleIssuesDialog;
