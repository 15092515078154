import React from 'react';

import BaseIcon, { Props } from './Icon';

const CaretRightSmallIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} viewBox="0 0 16 16">
      <path
        d="M7 11.5L10 8L7 4.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default CaretRightSmallIcon;
