/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments, MoreMenuButton } from '@modernloop/shared/components';
import { assertIsoTimestamp, isAfterNow } from '@modernloop/shared/datetime';
import { CopyIcon, EditIcon, EventWithCancelIcon, LinkIcon, OpenInNewTabIcon } from '@modernloop/shared/icons';
import { copyRichTextToClipboard } from '@modernloop/shared/utils';
import { Avatar, Box, ListItemIcon, ListItemText, ListSubheader, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  DebriefContentMenu_DebriefFragment,
  RenderType,
  useDebriefContentInterviewMenuQuery,
} from 'src/generated/mloop-graphql';

import { ZoomIcon } from 'src/components/icons';

import useHistory from 'src/hooks/useHistory';

import getDebriefFlowUrl from 'src/urls/getDebriefFlowUrl';

// eslint-disable-next-line modernloop/restrict-imports.cjs
import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

import DebriefContentCancelDebriefDialog from './DebriefContentCancelDebriefDialog';

export enum MenuOptionsType {
  EditDebrief = 'EDIT_DEBRIEF',
  CancelDebrief = 'CANCEL_DEBRIEF',
  CopyMeetingLink = 'COPY_MEETING_LINK',
  CopyMeetingInfo = 'COPY_MEETING_INFO',
  ViewEvent = 'VIEW_EVENT',
}

type Fragments = {
  debrief: DebriefContentMenu_DebriefFragment;
};

const DebriefContentMenu: FCWithFragments<Fragments> = ({ debrief }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const editDebriefURL = getDebriefFlowUrl(debrief.applicationId, { taskId: debrief.task?.id });
  const isUpcoming = isAfterNow(assertIsoTimestamp(debrief.startAt));

  const [showDeleteInterviewConfirmation, setShowDeleteInterviewConfirmation] = useState(false);

  const { loading: zoomMeetingInfoLoading, data } = useDebriefContentInterviewMenuQuery({
    variables: {
      input: {
        renderType: RenderType.Html,
        id: debrief.id,
      },
      zoomMeetingInfoInput: {
        userId: debrief.zoomHostUserId,
        joinUrl: debrief.videoMeetingUrl || '',
        meetingId: `${debrief.zoomMeetingId || ''}`,
        password: debrief.zoomPassword || '',
        pstnPassword: `${debrief.zoomPstnPassword || ''}`,
        dialInfo: debrief.zoomDialIns || [],
      },
    },
    skip: debrief.videoMeetingUrl?.toLowerCase().indexOf('zoom') === -1,
  });

  const handleMenuOptionSelect = useCallback(
    async (type: MenuOptionsType) => {
      switch (type) {
        case MenuOptionsType.EditDebrief: {
          SidePanelManager.closeSidePanel();
          history.push(editDebriefURL);
          break;
        }
        case MenuOptionsType.CancelDebrief: {
          setShowDeleteInterviewConfirmation(true);
          break;
        }

        case MenuOptionsType.CopyMeetingLink: {
          if (!debrief.videoMeetingUrl) return;
          try {
            navigator.clipboard.writeText(debrief.videoMeetingUrl);
            enqueueSnackbar('Meeting link copied', { variant: 'success' });
          } catch (error) {
            enqueueSnackbar('Failed to copy meeting link', { variant: 'error' });
          }
          break;
        }

        case MenuOptionsType.CopyMeetingInfo: {
          if (!debrief.videoMeetingUrl) return;

          try {
            const zoomMeetingInfoHtml = `<div>
                ${data?.templateToken?.ZOOM_MEETING_INFO}
                <br />
                ${data?.templateToken?.ZOOM_MEETING_DIAL_IN_INFO}
              </div>
            `;
            await copyRichTextToClipboard(zoomMeetingInfoHtml);
            enqueueSnackbar('Meeting info copied', { variant: 'success' });
          } catch (error) {
            enqueueSnackbar('Failed to copy meeting info', { variant: 'error' });
          }
          break;
        }

        case MenuOptionsType.ViewEvent: {
          if (!debrief.googleEventUrl) return;
          window.open(debrief.googleEventUrl, '_blank');
          break;
        }
        default:
      }
    },
    [
      data?.templateToken?.ZOOM_MEETING_DIAL_IN_INFO,
      data?.templateToken?.ZOOM_MEETING_INFO,
      debrief.googleEventUrl,
      debrief.videoMeetingUrl,
      editDebriefURL,
      enqueueSnackbar,
      history,
    ]
  );

  const locationMenuOptions = useMemo(() => {
    // eslint-disable-next-line max-params
    const getMenuItem = (id: MenuOptionsType, text: string, icon: JSX.Element, disabled: boolean) => {
      return (
        <MenuItem
          data-testid={`debrief-content-menu-item-${id}`}
          id={id}
          key={id}
          onClick={() => handleMenuOptionSelect(id)}
          disabled={disabled}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{text}</ListItemText>
          <ListItemIcon>
            <CopyIcon />
          </ListItemIcon>
        </MenuItem>
      );
    };

    const jsx: JSX.Element[] = [];

    if (debrief.videoMeetingUrl?.toLowerCase().indexOf('zoom') !== -1) {
      jsx.push(<ListSubheader>Zoom</ListSubheader>);
      jsx.push(getMenuItem(MenuOptionsType.CopyMeetingLink, 'Copy meeting link', <ZoomIcon />, false));
      jsx.push(getMenuItem(MenuOptionsType.CopyMeetingInfo, 'Copy meeting Info', <ZoomIcon />, zoomMeetingInfoLoading));
    } else if (debrief.videoMeetingUrl?.toLowerCase().indexOf('meet.google.com') !== -1) {
      jsx.push(<ListSubheader>Google meets</ListSubheader>);
      jsx.push(
        getMenuItem(
          MenuOptionsType.CopyMeetingLink,
          'Copy meeting link',
          <Avatar src="/static/images/integrations/google-meets.png" />,
          false
        )
      );
    } else if (debrief.videoMeetingUrl?.toLowerCase().indexOf('teams.microsoft.com') !== -1) {
      jsx.push(<ListSubheader>Microsoft teams</ListSubheader>);
      jsx.push(
        getMenuItem(
          MenuOptionsType.CopyMeetingLink,
          'Copy meeting link',
          <Avatar src="/static/images/integrations/microsoft-teams.png" />,
          false
        )
      );
    } else if (debrief.videoMeetingUrl?.length) {
      jsx.push(<ListSubheader>Custom meeting link</ListSubheader>);
      jsx.push(getMenuItem(MenuOptionsType.CopyMeetingLink, 'Copy meeting link', <LinkIcon />, false));
    }

    return jsx;
  }, [debrief.videoMeetingUrl, handleMenuOptionSelect, zoomMeetingInfoLoading]);

  const calendarMenuOptions = useMemo(() => {
    // eslint-disable-next-line max-params
    const getMenuItem = (id: MenuOptionsType, text: string, icon: JSX.Element) => {
      return (
        <MenuItem
          data-testid={`debrief-content-menu-item-${id}`}
          id={id}
          key={id}
          onClick={() => handleMenuOptionSelect(id)}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{text}</ListItemText>
          <ListItemIcon>
            <OpenInNewTabIcon />
          </ListItemIcon>
        </MenuItem>
      );
    };

    const jsx: JSX.Element[] = [];

    if (debrief.googleEventUrl?.indexOf('google') !== -1) {
      jsx.push(<ListSubheader>Google calendar</ListSubheader>);
    } else if (debrief.googleEventUrl?.indexOf('outlook') !== -1) {
      jsx.push(<ListSubheader>Outlook</ListSubheader>);
    }

    if (debrief.googleEventUrl?.indexOf('google') !== -1) {
      jsx.push(
        getMenuItem(
          MenuOptionsType.ViewEvent,
          'View event',
          <Avatar src="/static/images/integrations/google-calendar.png" />
        )
      );
    } else if (debrief.googleEventUrl?.indexOf('outlook') !== -1) {
      jsx.push(
        getMenuItem(
          MenuOptionsType.ViewEvent,
          'View event',
          <Avatar src="/static/images/integrations/microsoft-teams.png" />
        )
      );
    }

    return jsx;
  }, [handleMenuOptionSelect, debrief.googleEventUrl]);

  return (
    <Box>
      <MoreMenuButton>
        {isUpcoming && debrief.task?.id && (
          <MenuItem
            data-testid={`debrief-content-menu-item-${MenuOptionsType.EditDebrief}`}
            id={MenuOptionsType.EditDebrief}
            key={MenuOptionsType.EditDebrief}
            onClick={() => handleMenuOptionSelect(MenuOptionsType.EditDebrief)}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>Edit debrief</ListItemText>
          </MenuItem>
        )}

        <MenuItem
          data-testid={`debrief-content-menu-item-${MenuOptionsType.CancelDebrief}`}
          id={MenuOptionsType.CancelDebrief}
          key={MenuOptionsType.CancelDebrief}
          onClick={() => handleMenuOptionSelect(MenuOptionsType.CancelDebrief)}
        >
          <ListItemIcon>
            <EventWithCancelIcon />
          </ListItemIcon>
          <ListItemText>Cancel debrief</ListItemText>
        </MenuItem>

        {locationMenuOptions}

        {calendarMenuOptions}
      </MoreMenuButton>

      {showDeleteInterviewConfirmation && (
        <DebriefContentCancelDebriefDialog
          interviewId={debrief.id}
          onClose={() => setShowDeleteInterviewConfirmation(false)}
        />
      )}
    </Box>
  );
};

DebriefContentMenu.fragments = {
  debrief: gql`
    fragment DebriefContentMenu_debrief on ApplicationDebrief {
      id
      startAt
      googleEventUrl
      videoMeetingUrl
      applicationId
      task {
        id
      }
      zoomMeetingId
      zoomHostUserId
      zoomPassword
      zoomPstnPassword
      zoomDialIns
    }
  `,
};

export default DebriefContentMenu;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DebriefContentInterviewMenuQuery = gql`
  query DebriefContentInterviewMenu($input: TemplateTokenInput!, $zoomMeetingInfoInput: TokenZoomMeetingInput!) {
    templateToken(input: $input) {
      ZOOM_MEETING_INFO(input: $zoomMeetingInfoInput)
      ZOOM_MEETING_DIAL_IN_INFO(input: $zoomMeetingInfoInput)
    }
  }
`;
