import React from 'react';

import BaseIcon, { Props } from './Icon';

const CompanyErrorHolidayIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0997 3.49135C14.4751 3.6664 14.6375 4.11264 14.4625 4.48804L14.3398 4.75109C16.5692 6.09443 18.4651 8.85842 17.4026 12.3623C17.3089 12.6712 17.0281 12.8855 16.7053 12.8944C16.3826 12.9032 16.0905 12.7045 15.9801 12.4011C15.8217 11.9659 15.4171 11.5534 14.8722 11.2994C14.3274 11.0453 13.7514 11.0005 13.3161 11.1589C12.9269 11.3006 12.4965 11.0999 12.3549 10.7107C12.2666 10.4682 12.1019 10.2328 11.8792 10.0279L9.87078 14.335C11.7047 14.5634 13.463 15.26 14.5619 16.5032C14.8363 16.8135 14.8071 17.2875 14.4967 17.5618C14.1864 17.8362 13.7124 17.807 13.4381 17.4966C12.3268 16.2395 10.0228 15.5938 7.7147 15.7817C5.95466 15.925 4.40855 16.5389 3.56195 17.4966C3.28762 17.807 2.81364 17.8362 2.50329 17.5619C2.19294 17.2875 2.16374 16.8136 2.43807 16.5032C3.64011 15.1433 5.62643 14.4467 7.59301 14.2867C7.81124 14.2689 8.03193 14.2576 8.25403 14.2528L10.5197 9.39397C10.2196 9.35503 9.93341 9.3802 9.69092 9.46846C9.30168 9.61013 8.8713 9.40944 8.72963 9.0202C8.57122 8.58495 8.16661 8.1725 7.62179 7.91845C7.07697 7.6644 6.50094 7.61957 6.06569 7.77798C5.76233 7.8884 5.42236 7.79231 5.22169 7.53943C5.02101 7.28656 5.00466 6.93365 5.1811 6.66331C7.18223 3.5971 10.5183 3.27285 12.9803 4.11717L13.103 3.85411C13.2781 3.47871 13.7243 3.31629 14.0997 3.49135ZM8.25571 6.55899C8.01498 6.44673 7.75852 6.35578 7.49358 6.29173C8.64116 5.34931 10.05 5.08493 11.3566 5.26238C10.6566 5.74503 9.96945 6.40581 9.37021 7.32972C9.0441 7.00682 8.6586 6.74686 8.25571 6.55899ZM10.7844 7.91667C11.4278 7.00955 12.1667 6.45825 12.885 6.09623C13.0694 6.87917 13.122 7.79956 12.8407 8.87554C12.5502 8.61894 12.2218 8.4084 11.8809 8.24946C11.5401 8.09052 11.1677 7.97428 10.7844 7.91667ZM15.5062 9.93994C15.1033 9.75207 14.6564 9.62386 14.1994 9.5816C14.5219 8.52867 14.5865 7.57756 14.5063 6.7311C15.482 7.61795 16.1851 8.8671 16.2008 10.352C15.9814 10.1902 15.7469 10.0522 15.5062 9.93994Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CompanyErrorHolidayIcon;
