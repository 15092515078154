import { SvgIcon, SvgIconProps } from './SvgIcon';

export const DiamondFilled = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;

  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <rect
        x="2.92896"
        y="10"
        width="10"
        height="10"
        rx="2"
        transform="rotate(-45 2.92896 10)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
};
