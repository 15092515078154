import { isBefore, isSameHour, isSameMinute } from 'date-fns';

import { CompanyHolidaysType, getCompanyHolidayCountryNames } from 'src/hooks/useCompanyHolidays';

import { CandidateAvailability } from 'src/slices/scheduling';

import { isBeforeNow, isHoliday, isIntervalOverlappingTwelveHoursFromNow, isWeekend } from 'src/utils/dateUtils';

const isAvailabilityOverlappingTwelveHoursFromNow = (availability: CandidateAvailability) => {
  const start = new Date(availability.startAt);
  const end = new Date(availability.endAt);
  const interval = { start, end };
  return isIntervalOverlappingTwelveHoursFromNow(interval);
};

const isAvailabilityOverlappingCompanyHolidays = (
  availability: CandidateAvailability,
  holidays: CompanyHolidaysType
): { isHoliday: boolean; holidays: CompanyHolidaysType } => {
  const start = new Date(availability.startAt);
  const end = new Date(availability.endAt);
  const { isHoliday: isOff, holidays: offs } = isHoliday(start, end, holidays);
  if (isOff) {
    return { isHoliday: true, holidays: offs };
  }
  return { isHoliday: false, holidays: offs };
};

export const hasWarning = (
  availability: CandidateAvailability,
  timezone: string,
  holidays: CompanyHolidaysType
  // eslint-disable-next-line max-params
): boolean => {
  const start = new Date(availability.startAt);
  const end = new Date(availability.endAt);
  return (
    isBeforeNow(end) ||
    isIntervalOverlappingTwelveHoursFromNow({ start, end }) ||
    isHoliday(start, end, holidays).isHoliday ||
    isAvailabilityOverlappingCompanyHolidays(availability, holidays).isHoliday ||
    isWeekend(start, timezone)
  );
};

export const hasError = (availability: CandidateAvailability): boolean => {
  const start = new Date(availability.startAt);
  const end = new Date(availability.endAt);
  const inPast = isBeforeNow(end) && isBeforeNow(start);
  const emptyRange = isSameHour(start, end) && isSameMinute(start, end);
  return inPast || emptyRange || isBefore(end, start);
};

export const hasWarningOrError = (
  availability: CandidateAvailability,
  timezone: string,
  holidays: CompanyHolidaysType
): // eslint-disable-next-line max-params
boolean => {
  return hasError(availability) || hasWarning(availability, timezone, holidays);
};

export const getWarningText = (
  availability: CandidateAvailability,
  timezone: string,
  holidays: CompanyHolidaysType
): // eslint-disable-next-line max-params
string => {
  const start = new Date(availability.startAt);
  const end = new Date(availability.endAt);
  if (isBeforeNow(end) && isBeforeNow(start)) {
    return `This block has already happened. ModernLoop unfortunately doesn't support time travel… yet...`;
  }

  const { isHoliday: isOff, holidays: offs } = isAvailabilityOverlappingCompanyHolidays(availability, holidays);
  if (isOff) {
    const countryNames = getCompanyHolidayCountryNames(offs);
    if (countryNames && countryNames.toLowerCase() !== 'all') {
      return `This day is marked as a company-wide off day for ${countryNames}.`;
    }
    return 'This day is marked as a company-wide off day.';
  }

  if (isAvailabilityOverlappingTwelveHoursFromNow(availability)) {
    return 'Heads up, this option is less than 12 hours from now';
  }

  if (isWeekend(start, timezone)) {
    return 'Heads up, this option is a weekend';
  }

  return '';
};

export const getErrorText = (availability: CandidateAvailability): string => {
  const start = new Date(availability.startAt);
  const end = new Date(availability.endAt);
  if (isBeforeNow(end) && isBeforeNow(start)) {
    return `This block has already happened. Whoops!`;
  }
  if (isBefore(end, start)) {
    return `End time is before start!`;
  }
  if (isSameHour(start, end) && isSameMinute(start, end)) {
    return `Whoops! looks like an empty time range`;
  }
  return '';
};
