import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskQueueUnset_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import ActivityLogBold from 'src/entities/ActivityLog/Helpers/ActivityLogBold';
import ActivityLogText from 'src/entities/ActivityLog/Helpers/ActivityLogText';

import logError from 'src/utils/logError';

interface Fragments {
  activityLog: ActivityLogTaskQueueUnset_ActivityLogFragment;
}

const ActivityLogTaskQueueUnset: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;
  let name = 'Unknown';

  // eslint-disable-next-line no-underscore-dangle
  if (details?.__typename === 'ActivityLogTaskQueueDetails') {
    name = details.taskQueue?.name || name;
  } else {
    logError('ActivityLogTaskQueueDetails: details is not ActivityLogTaskQueueDetails');
  }

  const label = (
    <ActivityLogText>
      removed this task from the&nbsp;<ActivityLogBold>{name}</ActivityLogBold>&nbsp;queue
    </ActivityLogText>
  );

  return <ActivityLogBase activityLog={activityLog} actionLabel={label} />;
};

ActivityLogTaskQueueUnset.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskQueueUnset_activityLog on ActivityLog {
      id
      details {
        __typename
        ... on ActivityLogTaskQueueDetails {
          taskQueue {
            id
            name
          }
        }
      }

      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskQueueUnset;
