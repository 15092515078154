import { CaretDownIcon } from '@modernloop/shared/icons';
import { accordionClasses, ThemeOptions } from '@mui/material';

/**
 * View Autocomplete props and classes at https://mui.com/material-ui/api/autocomplete/
 */
export const accordion: ThemeOptions['components'] = {
  MuiAccordion: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.variant === 'outlined' && {
          borderRadius: '12px !important',
          [`&.${accordionClasses.root}`]: { '&::before': { opacity: 0 } },
          overflow: 'hidden',
        }),
      }),
    },
  },

  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: <CaretDownIcon />,
    },
    styleOverrides: {
      root: ({ theme }) => {
        return {
          padding: theme.spacing(0, 2.5),
        };
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }) => {
        return {
          padding: theme.spacing(1, 2.5, 2.5),
        };
      },
    },
  },
};
