/* eslint-disable max-lines */
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { CrossIcon } from '../../icons';
import VirtualList, { VirtualListOnScrollParams, Props as VirtualListboxProps } from '../VirtualList';
import { BaseProps, Search } from '@modernloop/shared/components';
import {
  UseAutocompleteProps,
  useAutocomplete,
  ListItem,
  Stack,
  Typography,
  IconButton,
  AutocompleteProps,
  AutocompleteGroupedOption,
  Box,
  ListItemButton,
  CircularProgress,
  AutocompleteRenderOptionState,
  AutocompleteInputChangeReason,
} from '@mui/material';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

const DEFAULT_ITEM_HEIGHT = 48;

export type FilterListInterface = {
  clearSelected: () => void;
};

export type Props<TData> = BaseProps &
  Pick<VirtualListboxProps, 'getChildSize'> & {
    // Whether the search input should be focused on mount.
    autoFocus?: boolean;

    // Placeholder text for the search input.
    placeholderText?: string;

    // Function to get the height of the list. If not provided, the list will take the full height of it's container.
    getListHeight?: (options: TData[]) => number;

    // Ref to the FilterListInterface. This can be used to clear the input from outside the component.
    filterListInterfaceRef?: MutableRefObject<FilterListInterface | null>;

    // Purpose of this function is determine if user has scroll to end
    // and fetch more data if required.
    onScrollToEnd?: () => void;

    // The children of the component. This can be used to add additional components between the search bar and the list.
    // Ex. - Filter buttons, errors, etc.
    children?: React.ReactNode | undefined;

    // Whether or not to clear the search input once an option is selected. Default is true.
    clearInputOnSelect?: boolean;

    // Re-exposing a limited subset of UseAutocompleteProps
    options: UseAutocompleteProps<TData, true, true, undefined>['options'];

    values?: UseAutocompleteProps<TData, true, true, undefined>['value'];
    isOptionEqualToValue?: UseAutocompleteProps<TData, true, true, undefined>['isOptionEqualToValue'];
    getOptionLabel?: UseAutocompleteProps<TData, true, true, undefined>['getOptionLabel'];
    groupBy?: UseAutocompleteProps<TData, true, true, undefined>['groupBy'];
    filterOptions?: UseAutocompleteProps<TData, true, true, undefined>['filterOptions'];
    getOptionDisabled?: UseAutocompleteProps<TData, true, true, undefined>['getOptionDisabled'];
    onChange: UseAutocompleteProps<TData, true, true, undefined>['onChange'];
    onInputChange?: UseAutocompleteProps<TData, true, true, undefined>['onInputChange'];
    onAutoCompleteClose?: UseAutocompleteProps<TData, true, true, undefined>['onClose'];
    filterSelectedOptions?: UseAutocompleteProps<TData, true, true, undefined>['filterSelectedOptions'];

    // Re-exposing a limited subset of AutocompleteProps that are used when we're rendering the list items ourselves
    // Simplifying the API by not exposing all the function parameters
    // When using this, the consumer needs to wrap the option in a ListItem and spread the props on the ListItem
    renderOption?: (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: TData,
      state: AutocompleteRenderOptionState
    ) => React.ReactNode;
    renderGroup?: AutocompleteProps<TData, true, true, undefined>['renderGroup'];
    loading?: AutocompleteProps<TData, true, true, undefined>['loading'];
    noOptionsText?: AutocompleteProps<TData, true, true, undefined>['noOptionsText'];
  };

/**
 * This component is built like an Autocomplete but with our own custom rendering. It acts as an always open AutoComplete.
 * For implementation details, refer to [Autocomplete](https://github.com/mui/material-ui/blob/next/packages/mui-material/src/Autocomplete/Autocomplete.js)
 * For API reference, visit [Autocomplete API](https://mui.com/api/autocomplete/) and [useAutoComplete](https://mui.com/material-ui/react-autocomplete/#useautocomplete)
 */
const FilterList = <TData,>({
  autoFocus,
  placeholderText,
  clearInputOnSelect = false,
  getListHeight,
  onScrollToEnd,
  filterListInterfaceRef,
  loading,
  noOptionsText,
  getChildSize,
  children,
  values,
  ...autoCompleteProps
}: Props<TData>): JSX.Element => {
  const virtualListInnerRef = useRef<HTMLElement>(null);
  const [listHeight, setListHeight] = useState<number>(DEFAULT_ITEM_HEIGHT);
  const { options, groupBy, renderGroup, renderOption, getOptionLabel, onInputChange } = autoCompleteProps;
  const [inputState, setInputState] = useState<string>('');

  // eslint-disable-next-line max-params
  const onInputChangeWrapper = (event: React.SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => {
    // 'reset' is the reason when user selects an option from the list,
    //  we don't want to reset the input value in this case
    if (reason !== 'reset') setInputState(value);

    if (onInputChange) onInputChange(event, value, reason);
  };

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    inputValue: autocompleteInputValue,
    getClearProps,
  } = useAutocomplete({
    ...autoCompleteProps,
    disableCloseOnSelect: true,
    autoHighlight: false,
    disableListWrap: true,
    multiple: true,
    inputValue: clearInputOnSelect ? undefined : inputState,
    onInputChange: clearInputOnSelect ? undefined : onInputChangeWrapper,
    open: true,
    clearOnBlur: false,
    value: values,
  });

  // If we don't want to clear the input on select, we use internal state to keep track of the input value
  const inputValue = clearInputOnSelect ? autocompleteInputValue : inputState;

  // Give access to external components to clear the input
  useEffect(() => {
    if (!filterListInterfaceRef) return;
    const { onClick } = getClearProps() as unknown as { onClick: () => void };
    const filterListInterface: FilterListInterface = { clearSelected: onClick };
    filterListInterfaceRef.current = filterListInterface;
  }, [filterListInterfaceRef, getClearProps]);

  const handleClearSearch = (event: React.MouseEvent) => {
    if (!clearInputOnSelect) {
      setInputState('');
      if (onInputChange) onInputChange(event, '', 'clear');
      return;
    }
    const { onClick } = getClearProps() as unknown as { onClick: () => void };
    if (!onClick) return;
    onClick();
  };

  // Sometimes it is possible that user has a big screen and the number of records passed
  // on initial render are taking less vertical space than that available,
  // in this case we notify the enclosing component that there is still more
  // space that can be used to show records by invoking `onScrollToEnd()`
  // Also will run when the options passed to the list are updated
  useEffect(() => {
    if (!virtualListInnerRef.current) return;
    if (listHeight >= virtualListInnerRef.current.clientHeight && onScrollToEnd) onScrollToEnd();
  }, [listHeight, onScrollToEnd, options]);

  const renderListOption = (option: TData, index: number) => {
    const { ...optionProps } = getOptionProps({ option, index });

    if (!renderOption) {
      return (
        <ListItem
          // eslint-disable-next-line no-restricted-syntax
          sx={{
            borderRadius: '6px',
          }}
          {...optionProps}
        >
          <ListItemButton>
            <Typography>{getOptionLabel?.(option)}</Typography>
          </ListItemButton>
        </ListItem>
      );
    }

    return renderOption(optionProps, option, {
      selected: Boolean(optionProps['aria-selected']),
      inputValue,
      index,
    });
  };

  const handleScroll = (params: VirtualListOnScrollParams) => {
    if (params.scrollDirection !== 'forward') return;
    if (!virtualListInnerRef.current) return;
    if (!onScrollToEnd) return;

    // Here 10 is a random error offset.
    if (Math.abs(virtualListInnerRef.current.clientHeight - (params.scrollOffset + listHeight)) > 10) return;

    onScrollToEnd();
  };

  const renderOptions = () => {
    if (groupedOptions.length === 0) return null;

    const itemData = groupedOptions.map((option, index) => {
      if (groupBy && renderGroup) {
        const groupOption = option as unknown as AutocompleteGroupedOption<TData>;
        return renderGroup({
          key: `${groupOption.key}`,
          group: groupOption.group,
          children: groupOption.options.map((option2, index2) => renderListOption(option2, groupOption.index + index2)),
        });
      }
      return renderListOption(option as TData, index);
    });

    let itemDataResult: React.ReactNode[] = [];
    itemData.forEach((item) => {
      if (Array.isArray(item)) {
        itemDataResult = itemDataResult.concat(...item);
      } else {
        itemDataResult.push(item);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onScroll, ...listBoxProps } = getListboxProps();

    return (
      <VirtualList
        getChildSize={
          getChildSize
            ? (_, index: number) => {
                if (getChildSize) return getChildSize(itemDataResult[index], index);
                return DEFAULT_ITEM_HEIGHT;
              }
            : undefined
        }
        onScroll={(params) => handleScroll(params)}
        innerRef={virtualListInnerRef}
        height={getListHeight ? getListHeight([...options]) : listHeight}
        {...listBoxProps}
      >
        {itemData}
      </VirtualList>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { color: _unusedColor, size: _unusedSize, ...inputProps } = getInputProps();

  const isEmpty = !groupedOptions?.length || groupedOptions.length === 0;

  return (
    <Box height="100%">
      <Stack
        height="100%"
        direction="column"
        spacing={1}
        // eslint-disable-next-line no-restricted-syntax
        sx={{
          wrap: 'nowrap',
        }}
      >
        <Stack spacing={1}>
          <Box {...getRootProps()}>
            <Search
              autoFocus={autoFocus}
              placeholder={placeholderText}
              dataTestId="filter-list-search-input"
              inputProps={{
                endAdornment: inputValue ? (
                  <IconButton onClick={handleClearSearch}>
                    <CrossIcon />
                  </IconButton>
                ) : undefined,
                ...inputProps,
              }}
            />
          </Box>
        </Stack>

        {children}

        {!loading && !isEmpty && (
          <Box
            minHeight={DEFAULT_ITEM_HEIGHT}
            height="100%"
            ref={(ref: HTMLElement) => {
              if (!ref || !ref.clientHeight || ref.clientHeight === listHeight) return;
              setListHeight(ref.clientHeight);
            }}
          >
            {listHeight > 0 && renderOptions()}
          </Box>
        )}
        {loading && !isEmpty && (
          <Stack justifyContent="center">
            <CircularProgress size={24} />
          </Stack>
        )}
        {!loading && isEmpty && noOptionsText && (
          <Stack justifyContent="center" alignItems="center">
            {noOptionsText}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default FilterList;
