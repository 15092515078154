import React, { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { gql } from '@apollo/client';
import { Avatar, Box, Link, Stack, TableCell, TableRow, Typography } from '@mui/material';

import { ModuleMemberRow_ModuleMemberFragment, TrainingStatus } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ModuleMemberTrainingRole from 'src/entities/InterviewModuleMember/Statuses/ModuleMemberTrainingRole';
import ModuleMemberTrainingStatus from 'src/entities/InterviewModuleMember/Statuses/ModuleMemberTrainingStatus';

import getInterviewModuleDetailsUrl from 'src/urls/getInterviewModuleDetailsUrl';
import getInterviewerDetailsPageUrl from 'src/urls/getInterviewerDetailsPageUrl';

type Fragments = {
  moduleMember: ModuleMemberRow_ModuleMemberFragment;
};

export enum ModuleMemberRowType {
  Member = 'MEMBER',
  Module = 'MODULE',
}
type Props = {
  rowType: ModuleMemberRowType;
  onRowClick: () => void;
  showTableCellCount?: number;
};

/**
 * @param moduleMember , showEmployeeName
 * showEmployeeName is a boolean value to show the employee name module name as the component is used in two places
 */
const ModuleMemberRow: FCWithFragments<Fragments, Props> = ({
  moduleMember,
  rowType,
  onRowClick,
  showTableCellCount = Infinity,
}) => {
  const shouldRender = (colNumber: number) => {
    return showTableCellCount >= colNumber;
  };
  const tableCells = [
    {
      key: 'name',
      label: (
        <>
          {rowType === ModuleMemberRowType.Member && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                sx={{ width: 28, height: 28 }}
                alt={moduleMember.employee?.fullName || ''}
                src={moduleMember.employee?.slackImageUrl || ''}
              />
              <Box onClick={(e) => e.stopPropagation()}>
                <Link
                  underline="hover"
                  color="inherit"
                  component={RouterLink}
                  to={getInterviewerDetailsPageUrl(moduleMember.employeeId)}
                >
                  <Typography variant="body2">{moduleMember.employee?.fullName}</Typography>
                </Link>
                <Typography variant="body2" color="text.secondary">
                  {moduleMember.employee?.title}
                </Typography>
              </Box>
            </Stack>
          )}
          {rowType === ModuleMemberRowType.Module && (
            <Box onClick={(e) => e.stopPropagation()} display="inline-flex">
              <Link
                underline="hover"
                color="inherit"
                component={RouterLink}
                to={getInterviewModuleDetailsUrl(moduleMember?.interviewModuleId)}
              >
                <Typography variant="body2">{moduleMember.interviewModule?.name}</Typography>
              </Link>
            </Box>
          )}
        </>
      ),
    },
    {
      key: 'role',
      label: <ModuleMemberTrainingRole status={moduleMember.status || TrainingStatus.Shadow} />,
    },
    {
      key: 'status',
      label: <ModuleMemberTrainingStatus moduleMember={moduleMember} />,
    },
    {
      key: 'upcoming',
      label: <Typography variant="body2">{moduleMember.stats?.upcoming}</Typography>,
    },
    {
      key: 'shadow',
      label: (
        <Typography variant="body2">
          {moduleMember.stats?.completedAsShadow + moduleMember.stats?.manualCompletedAsShadow || 0} /{' '}
          {moduleMember.shadowsRequired || 0}
        </Typography>
      ),
    },
    {
      key: 'reverse_shadow',
      label: (
        <Typography variant="body2">
          {moduleMember.stats?.completedAsReverseShadow + moduleMember.stats?.manualCompletedAsReverseShadow || 0} /{' '}
          {moduleMember.reverseShadowsRequired || 0}
        </Typography>
      ),
    },
    {
      key: 'trained',
      label: (
        <Typography variant="body2">
          {(moduleMember.stats?.completedAsInterviewer || 0) + (moduleMember.stats?.manualCompletedAsInterviewer || 0)}
        </Typography>
      ),
    },
  ];

  return (
    <TableRow
      key={`${moduleMember.interviewModuleId}-${moduleMember.employeeId}`}
      onClick={() => {
        onRowClick();
      }}
      sx={{ cursor: 'pointer' }}
      hover
    >
      {tableCells.map((cell, index) => {
        if (!shouldRender(index + 1)) {
          return null;
        }
        return <TableCell key={cell.key}>{cell.label}</TableCell>;
      })}
    </TableRow>
  );
};

ModuleMemberRow.fragments = {
  moduleMember: gql`
    ${ModuleMemberTrainingStatus.fragments.moduleMember}
    fragment ModuleMemberRow_moduleMember on InterviewModuleMember {
      id
      ...ModuleMemberTrainingStatus_moduleMember
      status
      employeeId
      interviewModuleId
      shadowsRequired
      reverseShadowsRequired
      employee {
        id
        fullName
        slackImageUrl
        title
      }
      interviewModule {
        id
        name
      }
      stats {
        upcoming
        completedAsShadow
        completedAsReverseShadow
        completed
        completedAsInterviewer
        manualCompletedAsInterviewer
        manualCompletedAsShadow
        manualCompletedAsReverseShadow
      }
    }
  `,
};

export default ModuleMemberRow;
