import { useEffect } from 'react';

import { difference } from 'lodash';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { updateScheduleContent } from 'src/store/actions/schedule-communications';
import {
  getCustomVideoMeetingLink,
  getCustomVideoMeetingLinkUrls,
  getScheduleContent,
  getZoomInfo,
  getZoomInfos,
  getZoomUserId,
  getZoomUserIds,
} from 'src/store/selectors/schedule-communications';
import {
  getCandidateCalendarTemplateID,
  getCandidateTemplateID,
  getInterviewerTemplateIDs,
} from 'src/store/selectors/scheduling';
import { ScheduleContent } from 'src/store/slices/schedule-communications';

import { useDispatch, useSelector } from 'src/store';

const useInitScheduleCommunicationContent = (selectedScheduleId: string | null) => {
  const dispatch = useDispatch();
  const schedule = useScheduleWithoutBreaks(selectedScheduleId);
  const scheduleContent = useSelector(getScheduleContent);

  const candidateTemplateId = useSelector(getCandidateTemplateID);
  const candidateCalendarTemplateId = useSelector(getCandidateCalendarTemplateID);
  const interviewerTemplateIDs = useSelector(getInterviewerTemplateIDs);
  const zoomUserId = useSelector((state) => getZoomUserId(state, selectedScheduleId || ''));
  const zoomUserIds = useSelector((state) => getZoomUserIds(state, selectedScheduleId || ''));
  const zoomInfo = useSelector((state) => getZoomInfo(state, selectedScheduleId || ''));
  const zoomInfos = useSelector((state) => getZoomInfos(state, selectedScheduleId || ''));
  const customVideoMeetingLinkUrl = useSelector((state) => getCustomVideoMeetingLink(state, selectedScheduleId || ''));
  const customVideoMeetingLinkUrls = useSelector((state) =>
    getCustomVideoMeetingLinkUrls(state, selectedScheduleId || '')
  );

  useEffect(() => {
    const scheduleEventIds = schedule?.events?.map((e) => e.id) || [];
    const scheduleContentEventIds = scheduleContent?.interviewerEventContents?.map((e) => e.event.id) || [];
    const missingInterviewsIds = difference(scheduleEventIds, scheduleContentEventIds); // Interviews in schedule but not in schedule content
    const deletedInterviewsIds = difference(scheduleContentEventIds, scheduleEventIds); // Interviews in schedule content but not in schedule
    if (!dispatch || !selectedScheduleId || (missingInterviewsIds.length === 0 && deletedInterviewsIds.length === 0)) {
      return;
    }
    dispatch(
      updateScheduleContent(selectedScheduleId, {
        candidateEventContent: {
          emailTemplateID: candidateTemplateId,
          schedule,
          description: '',
          summary: '',
          location: '',
        },
        candidateCalendarEventContent: {
          templateID: candidateCalendarTemplateId,
          schedule,
          body: '',
          subject: '',
          location: '',
        },
        interviewerEventContents:
          schedule?.events?.map((event) => {
            return {
              event,
              emails: event.interviewers.map((i) => i.employee.email),
              summary: '',
              description: '',
              isCoderPad: false,
              location: '',
              codingInterviewURL: '',
              zoomUserId: zoomUserIds && event.slotId ? zoomUserIds[event.slotId] : zoomUserId,
              zoomInfo: zoomInfos && event.slotId ? zoomInfos[event.slotId] : zoomInfo,
              emailTemplateID: (event.slotId && interviewerTemplateIDs[event.slotId]) || null,
              customVideoMeetingUrl:
                customVideoMeetingLinkUrls && event.slotId
                  ? customVideoMeetingLinkUrls[event.slotId]
                  : customVideoMeetingLinkUrl,
              slotId: event.slotId,
            };
          }) || [],
        slackChannelMessageContent: {
          emailTemplateID: '',
          message: '',
        },
      } as unknown as ScheduleContent)
    );
  }, [
    candidateCalendarTemplateId,
    candidateTemplateId,
    customVideoMeetingLinkUrl,
    customVideoMeetingLinkUrls,
    dispatch,
    interviewerTemplateIDs,
    schedule,
    scheduleContent,
    selectedScheduleId,
    zoomInfo,
    zoomInfos,
    zoomUserId,
    zoomUserIds,
  ]);
};

export default useInitScheduleCommunicationContent;
