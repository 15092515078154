import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type Conflict = {
  /**
   * eventUid is the id of the event, it works across Google, Microsoft, Apple, etc.
   * Since it is a string it will work with any other calendar provider too.
   */
  eventUid: string;
  isHardConflict: boolean;

  /**
   * Below feilds get sent to server while fetching schedule options
   */
  startAt: string | undefined;
  endAt: string | undefined;
  organizerEmail: string;
  attendeesEmails: string[];

  /**
   * Below fields are only used for displaying the conflict on UI
   */
  summary: string;
};

export type ConflictState = {
  byId: { [eventUid: string]: Conflict };
};

const getInitialState = (): ConflictState => {
  return {
    byId: {},
  };
};

const conflictsSlice = createSlice({
  name: 'conflicts',
  initialState: getInitialState(),
  reducers: {
    upsertConflict: (state: ConflictState, action: PayloadAction<{ conflict: Conflict }>) => {
      const { conflict } = action.payload;
      state.byId[conflict.eventUid] = conflict;
    },

    removeConflict: (state: ConflictState, action: PayloadAction<{ eventUid: string }>) => {
      const { eventUid } = action.payload;
      delete state.byId[eventUid];
    },
  },
});

export const { reducer } = conflictsSlice;
export default conflictsSlice;
