import { IntegrationType } from 'src/generated/mloop-graphql';

import { ApiVerb, apiPost } from 'src/utils/api';

import {
  AshbyIntegration,
  CodeSignalIntegration,
  CoderPadIntegration,
  CodilityIntegration,
  GemIntegration,
  GoogleIntegration,
  GreenhouseIntegration,
  HackerRankIntegration,
  WorkdayIntegration,
} from './types';

interface StoreIntegrationRequest {
  integration_type: IntegrationType;
  coderpad?: CoderPadIntegration;
  codesignal?: CodeSignalIntegration;
  ashby?: AshbyIntegration;
  greenhouse?: GreenhouseIntegration;
  codility?: CodilityIntegration;
  hackerrank?: HackerRankIntegration;
  google?: GoogleIntegration;
  gem?: GemIntegration;
  workday?: WorkdayIntegration;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function putIntegration(data: StoreIntegrationRequest): Promise<any> {
  const response = await apiPost(ApiVerb.PUT_INTEGRATION, data);
  return response.data;
}
