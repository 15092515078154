/* eslint-disable max-params */
import { isToday, isTomorrow } from './Comparison';
import { IsoDate } from './IsoDate';
import IsoTimestamp, { assertIsoTimestamp, guessIsoTimestamp } from './IsoTimestamp';
import getTZAbbr from './getTZAbbr';
import {
  formatDuration as dateFnsFormatDuration,
  isSameDay as dateFnsIsSameDay,
  format,
  intervalToDuration,
  isSameDay,
  isSameMonth,
  isSameYear,
  parseISO,
} from 'date-fns';
import { format as formatTZ, utcToZonedTime } from 'date-fns-tz';

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = 1440;

export const getCurrentDateIso = (): IsoTimestamp => assertIsoTimestamp(new Date().toISOString(), 'development');

/**
 * Formats a date using the specified timezone
 * @param date - Date to format
 * @param formatString - https://date-fns.org/v2.24.0/docs/format
 * @param tz - the timezone
 */
export const formatToTimeZone = (date: IsoTimestamp, formatString: string, tz: string): string => {
  const zonedDate = utcToZonedTime(date, tz);
  return formatTZ(zonedDate, formatString, { timeZone: tz });
};

/**
 * Formats a date interval in a string format.
 * @param startAt - start of the interval
 * @param endAt - end of the interval
 * @returns string representing the duration of the interval in the format "DD d HH h MM m"
 */
export const getDurationStringOfInterval = (startAt: IsoTimestamp, endAt: IsoTimestamp) => {
  const { days, hours, minutes } = intervalToDuration({ start: new Date(startAt), end: new Date(endAt) });

  return `${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m` : ''}`;
};

export const formatDateRange = (
  start: IsoTimestamp,
  end: IsoTimestamp,
  timezone: string,
  startDateFormat = 'EEEE, MMMM d, yyyy',
  endDateFormat = startDateFormat
): string => {
  const startTime = utcToZonedTime(start, timezone);
  const endTime = utcToZonedTime(end, timezone);

  let result = '';
  if (dateFnsIsSameDay(startTime, endTime)) {
    result = `${format(startTime, `${startDateFormat}`)}`;
  } else {
    result = `${format(startTime, `${startDateFormat}`)} - ${format(endTime, `${endDateFormat}`)}`;
  }

  return result;
};

export const formatDate = (date: IsoTimestamp | IsoDate, timezone: string, dateFormat: string): string => {
  const dateTime = utcToZonedTime(date, timezone);
  return `${format(dateTime, dateFormat)}`;
};

export const formatDay = (dayDate: IsoTimestamp, timezone: string, dayFormat: string): string => {
  const dateTime = utcToZonedTime(dayDate, timezone);
  let result = `${format(dateTime, dayFormat)}`;
  if (isToday(dayDate)) {
    result = `Today ` + result;
  } else if (isTomorrow(dayDate)) {
    result = `Tomorrow ` + result;
  }
  return result;
};

export const formatTimeRange = (
  start: IsoTimestamp,
  end: IsoTimestamp,
  timezone: string,
  showTzAbbr = false
): string => {
  const startTime = utcToZonedTime(start, timezone);
  const endTime = utcToZonedTime(end, timezone);

  let result = `${format(startTime, `h:mma`)} - ${format(endTime, `h:mma`)}`
    .replaceAll('AM', ' am')
    .replaceAll('PM', ' pm');

  if (showTzAbbr) {
    result += ` ` + getTZAbbr(timezone);
  }
  return result;
};

export const formatDurationFromMinutes = (durationInMinutes: number) => {
  return dateFnsFormatDuration({
    minutes: durationInMinutes % MINUTES_IN_HOUR,
    hours: Math.floor((durationInMinutes % MINUTES_IN_DAY) / MINUTES_IN_HOUR),
    days: Math.floor(durationInMinutes / MINUTES_IN_DAY),
  });
};

/**
 * If start & end are same date show start date only
 * If Same month but different date Dec 22 – 25, 2023
 * If Different year Dec 29, 2023 – Jan 1, 2024
 */
export const formatDayOffDateRange = (startAtDate: IsoDate, endAtDate: IsoDate): string => {
  if (!startAtDate || !endAtDate) return '';
  const start = guessIsoTimestamp(startAtDate);
  const end = guessIsoTimestamp(endAtDate);
  if (isSameDay(parseISO(start), parseISO(end))) {
    return format(parseISO(start), 'MMM d, yyyy');
  }
  if (isSameMonth(parseISO(start), parseISO(end))) {
    return `${format(parseISO(start), 'MMM d')} - ${format(parseISO(end), 'd, yyyy')}`;
  }
  if (isSameYear(parseISO(start), parseISO(end))) {
    return `${format(parseISO(start), 'MMM d')} - ${format(parseISO(end), 'MMM d, yyyy')}`;
  }
  return `${format(parseISO(start), 'MMM d, yyyy')} - ${format(parseISO(end), 'MMM d, yyyy')}`;
};
