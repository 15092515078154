import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';

import AtsSevice from './AtsService';

/* *
 * GEM ATS service
 * Imp: Never have atsType set in ATS service
 * */
class GemAtsService implements AtsSevice {
  readonly name = 'Gem';

  readonly logoUrl = `/static/images/integrations/gem.png`;

  readonly helpCenterUrl = ZenDeskHelpCenterUrl.GEM_INTEGRATION;

  readonly scorecardLabelPlural = 'feedback forms';

  readonly isAtsUserSelectEnabled = false;

  readonly isSourcingLinkEnabled = false;

  readonly isAtsImportAvailabilityEnabled = false;

  readonly skipInterviewKitLinkTokenValidation = true;

  readonly scorecardGroupByKey = null;

  readonly scorecardSortKey = null;

  readonly showEstimatedMinutes = false;

  readonly createCandidatePermissionDialog = null;

  readonly showRequisitionId = true;
}

export default new GemAtsService();
