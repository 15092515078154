import React, { useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Button, Stack, Typography } from '@mui/material';

import Paper from 'src/components/Paper';

import TemplateType from 'src/constants/TemplateType';

import TemplateSelect from 'src/entities/Template/TemplateSelect';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { updateLastUsedInterviewerEventTemplateID } from 'src/slices/persist';
import { addUpdateFlowInterviewExpanded, updateAllInterviewerTemplateID } from 'src/slices/scheduling';

import { getInitialInterviewerEventTemplateID } from 'src/store/selectors/scheduling';

import { EmployeePrefName } from 'src/utils/api/employee';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import SilentErrorBoundary from 'src/shared/components/base/boundaries/SilentErrorBoundary';
import { useDispatch, useSelector } from 'src/store';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { useCommunicationTemplatesPref } from '../../useCommunicationTemplatesPref';
import MeetingRoomCommon from '../MeetingRoom/MeetingRoomCommon';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type InterviewerCommonTemplateProps = {
  scheduleId: string;
  scheduleFlowType?: ScheduleFlowType;
};

const InterviewerCommonTemplate = ({ scheduleId, scheduleFlowType }: InterviewerCommonTemplateProps): JSX.Element => {
  const dispatch = useDispatch();
  const initialTemplateId = useSelector(getInitialInterviewerEventTemplateID) || null;
  const [parentTemplateId, setParentTemplateId] = useState<string | null>(initialTemplateId);
  const showEmployeePrefData = useFlag('user_last_used_template_ids_employee_pref');

  const isUpdateFlow = scheduleFlowType === ScheduleFlowType.UPDATE;

  const schedule = useScheduleWithoutBreaks(scheduleId);

  const handleUpdateLastUsedInterviewerEventTemplateId = useCommunicationTemplatesPref(
    EmployeePrefName.STR_INTERVIEWER_CALENDAR_INVITE_TEMPLATE_ID
  );
  return (
    <Paper color="alternate" sx={{ marginBottom: '8px' }}>
      <Stack spacing={2} direction="column">
        <Typography variant="subtitle2" fontWeight={600}>
          Event details template
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={isUpdateFlow ? 8 : 10} xl={isUpdateFlow ? 8 : 10}>
            <TemplateSelect
              minimalUX
              useSelectButton
              onSelect={(value) => {
                setParentTemplateId(value || null);

                if (value && showEmployeePrefData) {
                  handleUpdateLastUsedInterviewerEventTemplateId(value);
                } else {
                  dispatch(updateLastUsedInterviewerEventTemplateID(value || null));
                }
              }}
              selectedTemplateId={parentTemplateId || undefined}
              types={[TemplateType.InterviewerInvite]}
            />
          </Grid>
          {isUpdateFlow && (
            <Grid item xs={12} md={12} lg={2} xl={2}>
              <Button
                onClick={() => {
                  dispatch(updateAllInterviewerTemplateID(parentTemplateId || ''));
                }}
              >
                Apply to edited
              </Button>
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={2} xl={2}>
            <Button
              onClick={() => {
                if (isUpdateFlow) {
                  schedule?.events.forEach((event) => {
                    dispatch(addUpdateFlowInterviewExpanded(event.id));
                  });
                }
                dispatch(updateAllInterviewerTemplateID(parentTemplateId || ''));
              }}
            >
              Apply to all
            </Button>
          </Grid>
        </Grid>
        <SilentErrorBoundary>
          <MeetingRoomCommon scheduleId={scheduleId} scheduleFlowType={scheduleFlowType} />
        </SilentErrorBoundary>
      </Stack>
    </Paper>
  );
};

export default InterviewerCommonTemplate;
