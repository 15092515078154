/* eslint-disable max-lines */
import React, { useMemo, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { CircularProgress, Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useSnackbar } from 'notistack';

import Alert from 'src/components/Alert';
import IconButton from 'src/components/IconButton';
import ImageBlock from 'src/components/ImageBlock';
import Paper from 'src/components/Paper';
import Stack from 'src/components/Stack';
import Button from 'src/components/button';
import { BreakIcon, CrossIcon, OrderLockIcon, OrderShuffleIcon, OvernightIcon } from 'src/components/icons';
import Label from 'src/components/label';

import { clearInterviewPlanStepError } from 'src/slices/scheduling';

import { updateJobStagePlanConfig } from 'src/store/actions/job-stage-plan-config';
import { getAllJobStageInterviewByJobStageId } from 'src/store/selectors/job-stage-interview';
import { getAllJobStageGroupByJobStageId } from 'src/store/selectors/job-stage-interview-group';
import { getAllJobStageInterviewSeatByJobStageId } from 'src/store/selectors/job-stage-interview-seat';
import { getHasBreaksByJobStageId, getShouldRememberByJobStageId } from 'src/store/selectors/job-stage-plan-config';
import { JobStageInterview } from 'src/store/slices/job-stage-interview';

import { Theme } from 'src/theme/type';

import { Theme as ThemeV5 } from 'src/themeMui5/type';

import { isBreak, isDayBreak } from 'src/utils/interview';
import logError from 'src/utils/logError';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useDispatch, useSelector } from 'src/store';
import { DEFAULT_INTERVIEW_NAME } from 'src/strings';

import useFetchInterviewResponseStatus, { InterviewerDeclinedResultType } from './UseFetchInterviewResponseStatus';
import useIsInterviewPlanValid, { IsInterviewPlanValidResult } from './useIsInterviewPlanValid';
import { getSaveInterviewPlanInput, useSaveInterviewPlanWithInput } from './useSaveInterviewPlan';
import useSubmitInterviewPlan from './useSubmitInterviewPlan';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type BaseInterviewPlanPreviewProps = {
  interviews: JobStageInterview[];
  error: string | null;
  hasBreaks: boolean;
  shouldRemember: boolean;
  schedulable: boolean;
  submitting: boolean;
  scheduleFlowType: ScheduleFlowType;
  interviewerDeclinedResult: InterviewerDeclinedResultType;
  isInterviewPlanValidResult: IsInterviewPlanValidResult;
  clearError: () => void;
  setShouldSave: (save: boolean) => void;
  findSchedulesClicked: () => void;
  saveInterviewPlan: () => Promise<void>;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type InterviewPlanPreviewProps = {
  jobStageId?: string;
  interviewPlanJobStageId: string;
  schedulable: boolean;
  scheduleFlowType: ScheduleFlowType;
  gotoNextStep?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      backgroundColor: theme.palette.background.error,
      borderRadius: '6px',
      padding: '8px',
    },
    helpText: {
      backgroundColor: theme.palette.background.info,
      borderRadius: '6px',
      padding: '8px',
    },
    circularProgress: {
      marginLeft: '4px',
    },
  })
);

const useSxProps = () => {
  return useMemo(() => {
    return {
      paper: {
        backgroundColor: (theme: ThemeV5) => theme.palette.background.alternate,
      },
    };
  }, []);
};

export const BaseInterviewPlanPreview = ({
  interviews,
  error,
  hasBreaks,
  shouldRemember,
  schedulable,
  scheduleFlowType,
  interviewerDeclinedResult,
  isInterviewPlanValidResult,
  submitting,
  clearError,
  setShouldSave,
  findSchedulesClicked,
  saveInterviewPlan,
}: BaseInterviewPlanPreviewProps): JSX.Element => {
  const classes = useStyles();
  const sxProps = useSxProps();
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);

  const { loading: interviewerDeclinedLoading, error: interviewerDeclinedError } = interviewerDeclinedResult;

  const getDurationText = (duration: number) => {
    const start = Date.now();
    const end = start + duration * 60 * 1000;
    return formatDuration(intervalToDuration({ end, start }));
  };

  return (
    <Stack direction="column" spacing={2}>
      <Paper sx={sxProps.paper}>
        <Stack direction="column" wrap="nowrap" spacing={2}>
          <Label variant="title">Setup</Label>
          {schedulable && (
            <Button
              label="Next: Find schedules"
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                submitting ||
                interviews.length === 0 ||
                (scheduleFlowType === ScheduleFlowType.RESCHEDULE &&
                  (interviewerDeclinedLoading || Boolean(interviewerDeclinedError))) ||
                !isInterviewPlanValidResult.isValid
              }
              onClick={findSchedulesClicked}
              endIcon={submitting ? <CircularProgress size={20} className={classes.circularProgress} /> : undefined}
            />
          )}
          {!isInterviewPlanValidResult.isValid && (
            <Alert alignItems="center" status="warning" title={isInterviewPlanValidResult.error} />
          )}
          {!schedulable && (
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              label="Save"
              disabled={saving}
              endIcon={saving ? <CircularProgress size={16} /> : undefined}
              onClick={() => {
                setSaving(true);
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line promise/catch-or-return
                saveInterviewPlan() // TODO: Fix this the next time the file is edited.
                  // eslint-disable-next-line promise/always-return
                  .then(() => {
                    enqueueSnackbar('Interview plan saved', { variant: 'success' });
                  })
                  .catch((interviewPlanMutationError) => {
                    logError(interviewPlanMutationError);
                    enqueueSnackbar('Error occured while saving interview plan', { variant: 'error' });
                  })
                  .finally(() => {
                    setSaving(false);
                  });
              }}
            />
          )}
          {error && (
            <Label variant="captions" className={classes.errorText}>
              <Grid container justifyContent="space-between" alignItems="flex-start" wrap="nowrap">
                {error}
                {scheduleFlowType === ScheduleFlowType.RESCHEDULE &&
                  interviewerDeclinedError &&
                  interviewerDeclinedError.message}
                <IconButton onClick={clearError}>
                  <CrossIcon fontSize={16} />
                </IconButton>
              </Grid>
            </Label>
          )}
          {hasBreaks && (
            <Label variant="captions" className={classes.helpText}>
              Additional breaks may be added to avoid conflicts.
            </Label>
          )}
          <Label variant="captions" fontWeight={600}>
            Sample schedule
          </Label>
          {interviews.map((interview) => {
            const isInterviewBreak = isBreak(interview.type);
            let durationText = getDurationText(interview.duration);

            if (isBreak(interview.type)) {
              durationText = `${durationText} break`;
            } else if (isDayBreak(interview.type)) {
              durationText = `1 day break`;
            }

            const icons = [] as JSX.Element[];
            if (isDayBreak(interview.type)) {
              icons.push(<OvernightIcon fontSize={22} tooltip="Day break" />);
            }
            if (interview.isLockedOrder) {
              icons.push(<OrderLockIcon fontSize={22} tooltip="Locked position" />);
            }
            if (!interview.isLockedOrder) {
              icons.push(<OrderShuffleIcon fontSize={22} tooltip="Shuffle position" />);
            }
            if (isBreak(interview.type)) {
              icons.push(<BreakIcon fontSize={22} tooltip="Break" />);
            }

            const iconWrap = icons.map((img, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={index}>{img}</Grid>
            ));

            return (
              <ImageBlock
                key={interview.id}
                image={<div style={{ display: 'flex' }}>{iconWrap}</div>}
                title={isInterviewBreak ? durationText : interview.name ?? DEFAULT_INTERVIEW_NAME}
                caption={isInterviewBreak ? undefined : durationText}
                alignItems={isInterviewBreak ? 'center' : undefined}
              />
            );
          })}
        </Stack>
      </Paper>
      {schedulable && scheduleFlowType === ScheduleFlowType.SCHEDULE && (
        <FormControlLabel
          disabled={submitting}
          control={<Checkbox checked={shouldRemember} color="primary" />}
          label={
            <Typography py={0.5} color={submitting ? 'text.disabled' : undefined} variant="body2">
              Remember setup of this job stage plan for future candidates
            </Typography>
          }
          onChange={() => setShouldSave(!shouldRemember)}
        />
      )}
    </Stack>
  );
};

const useSaveInterviewPlanNew = (jobStageId: string, interviewPlanJobStageId: string) => {
  const jobStageInterviewGroups = useSelector((state) =>
    getAllJobStageGroupByJobStageId(state, interviewPlanJobStageId)
  );
  const jobStageInterviews = useSelector((state) =>
    getAllJobStageInterviewByJobStageId(state, interviewPlanJobStageId)
  );
  const jobStageInterviewSeats = useSelector((state) =>
    getAllJobStageInterviewSeatByJobStageId(state, interviewPlanJobStageId)
  );

  const groups = getSaveInterviewPlanInput({
    jobStageId,
    generateNewIds: true,
    jobStageInterviewGroups,
    jobStageInterviews,
    jobStageInterviewSeats,
  });

  return useSaveInterviewPlanWithInput({
    jobStageId,
    groups,
  });
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const InterviewPlanPreview = ({
  jobStageId,
  interviewPlanJobStageId,
  schedulable,
  scheduleFlowType,
  gotoNextStep,
}: InterviewPlanPreviewProps): JSX.Element => {
  const dispatch = useDispatch();
  const interviews = useSelector((state) => getAllJobStageInterviewByJobStageId(state, interviewPlanJobStageId));
  const hasBreaks = useSelector((state) => getHasBreaksByJobStageId(state, interviewPlanJobStageId));
  const shouldRemember = useSelector((state) => getShouldRememberByJobStageId(state, interviewPlanJobStageId));

  const [saveInterviewPlanMutation /* { data, loading, error } */] = useSaveInterviewPlanNew(
    jobStageId || interviewPlanJobStageId,
    interviewPlanJobStageId // We want to copy the interview plan from to job stage
  );

  const submitInterviewPlan = useSubmitInterviewPlan(interviewPlanJobStageId, true /* increment */);

  const [responseStatuses, interviewerDeclinedResult] = useFetchInterviewResponseStatus(scheduleFlowType);
  const isInterviewPlanValidResult = useIsInterviewPlanValid(interviewPlanJobStageId, {});

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleShouldSave = (save: boolean) => {
    dispatch(updateJobStagePlanConfig({ jobStageId: interviewPlanJobStageId, shouldRemember: save }));
  };

  const handleFindSchedules = async () => {
    try {
      setSubmitting(true);
      await submitInterviewPlan({ interviewersResponseStatuses: responseStatuses });
      setSubmitting(false);
      if (gotoNextStep) gotoNextStep();
    } catch (e) {
      setError(e.message);
      setSubmitting(false);
    }

    if (shouldRemember) {
      saveInterviewPlanMutation();
    }
  };

  const handleClearError = () => {
    dispatch(clearInterviewPlanStepError());
  };

  const handleSaveInterviewPlan = async () => {
    await saveInterviewPlanMutation();
  };

  return (
    <BaseInterviewPlanPreview
      interviews={interviews}
      hasBreaks={hasBreaks}
      shouldRemember={shouldRemember}
      schedulable={schedulable}
      error={error}
      submitting={submitting}
      scheduleFlowType={scheduleFlowType}
      interviewerDeclinedResult={interviewerDeclinedResult}
      isInterviewPlanValidResult={isInterviewPlanValidResult}
      clearError={handleClearError}
      setShouldSave={handleShouldSave}
      findSchedulesClicked={handleFindSchedules}
      saveInterviewPlan={handleSaveInterviewPlan}
    />
  );
};

export default InterviewPlanPreview;
