import React, { FC, useState } from 'react';

import { Button, Stack, TextField } from '@mui/material';

import useEmployeeId from 'src/hooks/useEmployeeId';
import useHistory from 'src/hooks/useHistory';

import { ApiVerb, apiPost } from 'src/utils/api';

const E2E_JOB_ID = 'fb862355-7e88-42e7-8ad0-99037ac66a51';
const E2E_INITIAL_JOB_STAGE_ID = 'dba8c6c2-7eba-4f99-b21c-3344e0f9b118';

type Props = {
  onClose: () => void;
};

const CandidateTab: FC<Props> = ({ onClose }) => {
  const [name, setName] = useState('');
  const employeeId = useEmployeeId();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCreate = async () => {
    const uniqueName = name.replaceAll(':', '-');
    const response = await apiPost('/fe/interview/createCandidateApplication' as ApiVerb, {
      candidateRecruiterEmployeeId: employeeId,
      candidateCoordinatorEmployeeId: employeeId,
      candidateSourcerEmployeeId: employeeId,
      jobId: E2E_JOB_ID,
      initialJobStageId: E2E_INITIAL_JOB_STAGE_ID,
      firstName: uniqueName,
      lastName: 'DD-E2E',
      primaryEmailAddress: `qa+${uniqueName}@modernloop.pizza`,
    });
    onClose();
    history.push(`/candidates/${response.data.candidate_id}?applicationId=${response.data.application_id}`);
  };

  return (
    <Stack spacing={2}>
      <TextField label="Name" placeholder="Name" value={name} onChange={handleChange} />
      <Button variant="contained" color="primary" onClick={handleCreate}>
        Create
      </Button>
    </Stack>
  );
};

export default CandidateTab;
