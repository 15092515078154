import urlcat from 'urlcat';

import { Routes } from 'src/constants/routes';

export enum TabUrlValueMap {
  Interviewers = '',
  Setup = 'setup',
}
/**
 *
 * @param moduleId
 * @returns
 */
const getInterviewModuleDetailsUrl = (moduleId: string, tab: TabUrlValueMap = TabUrlValueMap.Interviewers) => {
  if (tab) {
    return urlcat('', Routes.InterviewModuleDetails, { interviewModuleId: moduleId, tab });
  }
  return urlcat('', Routes.InterviewModuleDetails, { interviewModuleId: moduleId });
};

export default getInterviewModuleDetailsUrl;
