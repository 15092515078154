import { apiPost } from 'src/utils/api';
import ApiVerb from 'src/utils/api/ApiVerb';

import { OrgPref } from './types';

interface ListOrgPrefRequest {
  org_id?: string;
  exclude_defaults?: boolean;
}

export interface OrgPrefResponse {
  org_pref?: { [key: string]: OrgPref };
}

const listOrgPref = async (exclude_defaults = false): Promise<OrgPrefResponse> => {
  const request: ListOrgPrefRequest = { exclude_defaults };
  const response = await apiPost(ApiVerb.LIST_ORG_PREF, request);
  return response.data as OrgPrefResponse;
};

export default listOrgPref;
