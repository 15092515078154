import React, { FC } from 'react';

import { Box, Stack } from '@mui/material';

import MoreMenuButton, { MoreMenuButtonProps, MoreMenuOption } from 'src/components/MoreMenuButton';
import { Props } from 'src/components/icons/Icon';
import Label from 'src/components/label';
import { IconType, getSharedIconFromEnum } from 'src/components/utils/icons';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type RequirementContainerProps = {
  title: string;
  iconType: IconType;
  iconProps?: Props;
  moreMenuOptions?: MoreMenuOption[];
  onSelect?: MoreMenuButtonProps['onSelect'];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const RequirementContainer: FC<RequirementContainerProps> = ({
  title,
  iconType,
  iconProps,
  moreMenuOptions,
  onSelect,
  children,
}) => {
  return (
    <Stack direction="row" spacing={1} sx={{ minWidth: 0 }}>
      <Box display="flex" alignItems="center" height="28px">
        {getSharedIconFromEnum(iconType, iconProps ?? { color: 'high-contrast-grey' })}
      </Box>
      <Stack sx={{ flexGrow: 1, minWidth: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ height: '28px' }}>
          <Label variant="captions" color="high-contrast-grey" fontWeight={600}>
            {title}
          </Label>
          {moreMenuOptions && onSelect && <MoreMenuButton options={moreMenuOptions} onSelect={onSelect} />}
        </Stack>
        {children}
      </Stack>
    </Stack>
  );
};

export default RequirementContainer;
