import { useMemo } from 'react';
import { UseQueryResult, useQuery } from 'react-query';

import { OrgPref, OrgPrefName, OrgPrefResponse, listOrgPref } from 'src/utils/api/org';

import { QUERY_KEY } from './constants';

export { OrgPrefName } from 'src/utils/api/org';

type UseQueryType = Omit<UseQueryResult, 'data'> & { error: Error | null };

export function useOrgPrefs(): UseQueryResult<OrgPrefResponse, Error> {
  return useQuery(QUERY_KEY, () => listOrgPref());
}

// This single fetch intentially fetches all the org prefrences so we can cache.
export function useOrgPref(pref: OrgPrefName): [OrgPref | null, UseQueryType] {
  const { data, ...rest } = useOrgPrefs();
  return [data?.org_pref ? data.org_pref[pref] : null, rest];
}

export function useOrgPrefBool(pref: OrgPrefName): [boolean | null, UseQueryType] {
  const [value, rest] = useOrgPref(pref);
  return [value?.bool_value || null, rest];
}

export function useOrgPrefString(pref: OrgPrefName, defaultValue: string | null = null): [string | null, UseQueryType] {
  const [value, rest] = useOrgPref(pref);
  return [value?.string_value || defaultValue, rest];
}

export function useOrgPrefInt(pref: OrgPrefName, defaultValue: number | null = null): [number | null, UseQueryType] {
  const [value, rest] = useOrgPref(pref);
  return [value?.int_value || defaultValue, rest];
}

export function useOrgPrefStringArray(
  pref: OrgPrefName,
  defaultValue: string[] | null = null
): [string[] | null, UseQueryType] {
  const [value, rest] = useOrgPref(pref);
  return [value?.string_array?.string || defaultValue, rest];
}

export function useOrgPrefJson<T>(pref: OrgPrefName, defaultValue: T | null = null): [T | null, UseQueryType] {
  const [value, rest] = useOrgPref(pref);

  const newValue = useMemo(() => {
    if (!value?.json) return defaultValue;
    return JSON.parse(value.json);
  }, [defaultValue, value]);

  return [newValue, rest];
}

export function useOrgPrefJsonArray<T>(pref: OrgPrefName, defaultValue: T[] | null = null): [T[] | null, UseQueryType] {
  const [value, rest] = useOrgPref(pref);
  if (!value?.json_array?.json) {
    return [defaultValue, rest];
  }

  const vals = value.json_array.json.map((val: string) => {
    return JSON.parse(val);
  }) as unknown as T[];

  return [vals, rest];
}
