import { AmplitudeLoggingHeaderNames } from 'src/generated/mloop-graphql';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import useIsInExtension from 'src/recruiting-portal/pages/extension/utils/useIsInExtension';

// Enum for the possible values of the X-Amp-Create-Location header
export enum CreateLocations {
  WEB = 'web',
  EXTENSION = 'extension',
}

export const useCreateLocation = () => {
  const isInExtension = useIsInExtension();
  return isInExtension ? CreateLocations.EXTENSION : CreateLocations.WEB;
};

// Custom hook to get the value of the X-Amp-Create-Location header
export const useCreateLocationHeader = () => {
  const createLocation = useCreateLocation();
  const headerKey = AmplitudeLoggingHeaderNames.XAmpCreateLocation.replace(/_/g, '-').toLowerCase();

  return {
    [headerKey]: createLocation,
  };
};
