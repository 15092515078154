import React from 'react';

import {
  LinkIcon,
  NoneIcon,
  PhoneWithArrowPointingInwardsIcon,
  PhoneWithArrowPointingOutwardsIcon,
  TextIcon,
} from '@modernloop/shared/icons';

import { InterviewMeetingLocationType } from 'src/generated/mloop-graphql';

import { GoogleMeetIcon, MicrosoftTeamsIcon, ZoomIcon } from 'src/components/icons';

export const getInterviewMeetingLocationLabel = (interviewMeetingLocationType?: InterviewMeetingLocationType) => {
  switch (interviewMeetingLocationType) {
    case InterviewMeetingLocationType.Zoom: {
      return 'Zoom';
    }
    case InterviewMeetingLocationType.GoogleMeet: {
      return 'Google Meet';
    }
    case InterviewMeetingLocationType.MicrosoftTeams: {
      return 'Microsoft Teams';
    }
    case InterviewMeetingLocationType.CustomLink: {
      return 'Custom link';
    }
    case InterviewMeetingLocationType.CandidatePhone: {
      return 'Candidate phone number';
    }
    case InterviewMeetingLocationType.CustomPhone: {
      return 'Custom phone number';
    }
    case InterviewMeetingLocationType.CustomText: {
      return 'Custom text';
    }
    case InterviewMeetingLocationType.NotSpecified: {
      return 'None';
    }

    default:
      return '';
  }
};

export const getInterviewMeetingLocationIcon = (interviewMeetingLocationType?: InterviewMeetingLocationType) => {
  const dataTestId = `interview-meeting-location-icon-${interviewMeetingLocationType}`;
  switch (interviewMeetingLocationType) {
    case InterviewMeetingLocationType.Zoom: {
      return <ZoomIcon dataTestId={dataTestId} />;
    }
    case InterviewMeetingLocationType.GoogleMeet: {
      return <GoogleMeetIcon dataTestId={dataTestId} />;
    }
    case InterviewMeetingLocationType.MicrosoftTeams: {
      return <MicrosoftTeamsIcon dataTestId={dataTestId} />;
    }
    case InterviewMeetingLocationType.CustomLink: {
      return <LinkIcon data-testid={dataTestId} />;
    }
    case InterviewMeetingLocationType.CandidatePhone: {
      return <PhoneWithArrowPointingOutwardsIcon data-testid={dataTestId} />;
    }
    case InterviewMeetingLocationType.CustomPhone: {
      return <PhoneWithArrowPointingInwardsIcon data-testid={dataTestId} />;
    }
    case InterviewMeetingLocationType.CustomText: {
      return <TextIcon data-testid={dataTestId} />;
    }
    case InterviewMeetingLocationType.NotSpecified: {
      return <NoneIcon data-testid={dataTestId} />;
    }

    default:
      return null;
  }
};
