import React, { FC, useEffect, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/styles';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Stack } from '@mui/material';
import { isEqual } from 'lodash';

import { InterviewMeetingLocationType } from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import Button from 'src/components/button';
import { WarningIcon } from 'src/components/icons';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { setScheduleContentStepWaiting } from 'src/slices/scheduling';

import {
  clearCustomVideoMeetingLink,
  updateCustomVideoMeetingLinkUrlBySlotId,
} from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { getCustomVideoMeetingLinkUrls } from 'src/store/selectors/schedule-communications';
import { getSelectedScheduleId } from 'src/store/selectors/scheduling';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import logError from 'src/utils/logError';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useDispatch, useSelector } from 'src/store';

import CustomLinksContentRow from './CustomLinksContentRow';
import usePopulateCustomInterviewMeetingLocation from './usePopulateCustomInterviewMeetingLocation';

interface Props {
  scheduleFlow: ScheduleFlowType;
  location: InterviewMeetingLocationType;
}

enum SubmitButtonStates {
  Apply,
  Applying,
  Applied,
}

const SubmitButtonStateStringsMap = {
  [SubmitButtonStates.Apply]: 'Apply to interviews',
  [SubmitButtonStates.Applying]: 'Applying...',
  [SubmitButtonStates.Applied]: '✓ Applied!',
};

const useStyles = makeStyles(() => ({
  submitButton: { width: 'max-content' },
}));

const CustomLinksMulti: FC<Props> = ({ scheduleFlow, location }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const scheduleId = useSelector((state) => getSelectedScheduleId(state));
  const existingCustomVideoMeetingLinkUrls = useSelector((state) => getCustomVideoMeetingLinkUrls(state, scheduleId));
  const schedule = useScheduleWithoutBreaks(scheduleId);

  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const populateVideoInterviewMeetingLocation = usePopulateCustomInterviewMeetingLocation({
    scheduleId: schedule?.id || '',
    interviewMeetingLocationType: location,
  });

  // State to maintain the disabled state of apply button.
  const [customLinkSlotIdToUrlMap, setCustomLinkSlotIdToUrlMap] = useState({});

  const [submitButtonState, setSubmitButtonState] = useState<SubmitButtonStates>(SubmitButtonStates.Apply);

  const customLinkEventIdsLength = Object.keys(customLinkSlotIdToUrlMap).length;
  const interviewsLength = schedule?.events?.length ?? 0;

  useEffect(() => {
    if (customLinkEventIdsLength !== interviewsLength) {
      if (scheduleFlow !== ScheduleFlowType.UPDATE) {
        dispatch(setScheduleContentStepWaiting(true));
      }
    }
  }, [interviewsLength, customLinkEventIdsLength, dispatch, scheduleFlow]);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  const onCustomLinkChange = (slotId: string, eventId: string, text: string) => {
    if (text.length === 0) {
      setCustomLinkSlotIdToUrlMap((prevVal) => {
        const newObj = { ...prevVal };
        delete newObj[slotId];

        return newObj;
      });
    } else {
      setCustomLinkSlotIdToUrlMap((prevVal) => {
        const newObj = {
          ...prevVal,
          [slotId]: { text, eventId },
        };

        return newObj;
      });
    }
    if (submitButtonState !== SubmitButtonStates.Apply) {
      setSubmitButtonState(SubmitButtonStates.Apply);
    }
  };

  if (!scheduleId) return null;

  const handleSubmit = async () => {
    setSubmitButtonState(SubmitButtonStates.Applying);
    Object.keys(customLinkSlotIdToUrlMap).forEach((slotId) => {
      const newUrl: string = customLinkSlotIdToUrlMap?.[slotId]?.text ?? '';

      if (scheduleLocationEnabled) {
        populateVideoInterviewMeetingLocation({ slotId, customLocation: newUrl });
      } else {
        dispatch(updateCustomVideoMeetingLinkUrlBySlotId(scheduleId, newUrl, slotId));

        // In update flow, we are checking if the URL has changed.
        if (scheduleFlow === ScheduleFlowType.UPDATE) {
          try {
            const eventId = customLinkSlotIdToUrlMap?.[slotId]?.eventId;

            const existingUrl: string = existingCustomVideoMeetingLinkUrls?.[slotId] ?? '';

            if (!isEqual(existingUrl, newUrl)) {
              if (scheduleId && eventId) {
                dispatch(
                  addInterviewScheduleUpdate({
                    type: 'ScheduleUpdateVideoUrl',
                    updateType: ScheduleUpdateType.EDIT,
                    scheduleId,
                    applicationStageInterviewId: eventId,
                  })
                );
              }
            }
          } catch (err) {
            logError(err);
          }
        }
      }
    });
    try {
      await dispatch(clearCustomVideoMeetingLink(scheduleId));

      setSubmitButtonState(SubmitButtonStates.Applied);
    } catch (e) {
      setSubmitButtonState(SubmitButtonStates.Apply);
    }
  };

  const disableSubmitButton =
    customLinkEventIdsLength !== interviewsLength ||
    submitButtonState === SubmitButtonStates.Applied ||
    submitButtonState === SubmitButtonStates.Applying;

  const displayAlert = submitButtonState === SubmitButtonStates.Apply && !disableSubmitButton;

  return (
    <>
      <Grid container direction="column">
        {schedule?.events?.map((event) => {
          return (
            <CustomLinksContentRow
              key={event.id}
              interview={event}
              onCustomLinkChange={onCustomLinkChange}
              scheduleId={scheduleId}
              disabled={submitButtonState === SubmitButtonStates.Applying}
            />
          );
        })}
      </Grid>
      <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end">
        {displayAlert ? (
          <Alert
            sx={{ width: 'max-content' }}
            icon={<WarningIcon color="warning" />}
            status="warning"
            title="You have not applied your changes."
          />
        ) : null}
        <Button
          label={SubmitButtonStateStringsMap[submitButtonState]}
          variant="outlined"
          disabled={disableSubmitButton}
          onClick={handleSubmit}
          className={classes.submitButton}
        />
      </Stack>
    </>
  );
};

export default CustomLinksMulti;
