// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import type { PaletteColor } from '@material-ui/core/styles/createPalette';

import type { Theme } from 'src/theme/type';

import type { Theme as ThemeV2 } from 'src/themeMui5/type';

/**
 * Status Colors: info, success, warning, error
 */

export type SupportedStatusColor = 'info' | 'warning' | 'error' | 'success';
export function isSupportedStatusColor(color: string | undefined): color is SupportedStatusColor {
  if (!color) return false;
  return ['info', 'warning', 'error', 'success'].includes(color);
}

export function getStatusColorFromTheme(color: SupportedStatusColor, theme: Theme | ThemeV2): string {
  return theme.palette[color].main;
}

/**
 * Function Colors: primary, secondary
 */

export type SupportedFunctionColor = 'primary' | 'secondary' | 'tertiary' | 'pending';
export function isSupportedFunctionColor(color: string | undefined): color is SupportedStatusColor {
  if (!color) return false;
  return ['primary', 'secondary', 'tertiary', 'pending'].includes(color);
}

/**
 * All Colors: primary, secondary, info, success, warning, error
 */

export type SupportedColor = SupportedFunctionColor | SupportedStatusColor;
export function isSupportedColor(color: string | undefined): color is SupportedColor {
  return isSupportedStatusColor(color) || isSupportedFunctionColor(color);
}

export function getPaletteColorFromTheme(color: SupportedColor, theme: Theme | ThemeV2): PaletteColor {
  return theme.palette[color];
}

export function getColorFromTheme(color: SupportedColor, theme: Theme | ThemeV2): string {
  return theme.palette[color].main;
}

/**
 * All Greys: max, high, mid, low, min
 */

export type SupportedGrey =
  | 'max-contrast-grey'
  | 'high-contrast-grey'
  | 'mid-contrast-grey'
  | 'low-contrast-grey'
  | 'min-contrast-grey';

export function isSupportedGrey(grey: string | undefined): grey is SupportedGrey {
  if (!grey) return false;
  return [
    'max-contrast-grey',
    'high-contrast-grey',
    'mid-contrast-grey',
    'low-contrast-grey',
    'min-contrast-grey',
  ].includes(grey);
}

export function getGreyFromTheme(grey: SupportedGrey, theme: Theme | ThemeV2): string {
  switch (grey) {
    default:
    case 'max-contrast-grey':
      return theme.grey.alpha.max;
    case 'high-contrast-grey':
      return theme.grey.alpha.high;
    case 'mid-contrast-grey':
      return theme.grey.alpha.mid;
    case 'low-contrast-grey':
      return theme.grey.alpha.low;
    case 'min-contrast-grey':
      return theme.grey.alpha.min;
  }
}

/**
 * All Greys: max, high, mid, low, min
 */

export type SupportedTextColor = 'inherit' | 'foreground' | 'background' | SupportedStatusColor | SupportedGrey;
export type SupportedBackgroundColor =
  | 'default'
  | 'paper'
  | 'alternate'
  | 'contrast'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'pending';

export function isSupportedTextColor(color: string | undefined): color is SupportedTextColor {
  if (!color) return false;
  return (
    isSupportedStatusColor(color) ||
    ['max-contrast-grey', 'high-contrast-grey', 'mid-contrast-grey', 'foreground', 'background'].includes(color)
  );
}

export function getTextColorFromTheme(color: SupportedTextColor, theme: Theme | ThemeV2): string {
  if (isSupportedGrey(color)) {
    return getGreyFromTheme(color, theme);
  }

  if (isSupportedStatusColor(color)) {
    return getStatusColorFromTheme(color, theme);
  }

  if (color === 'inherit') {
    return 'inherit';
  }

  if (color === 'background') {
    return theme.palette.background.default;
  }

  // foreground
  return theme.palette.text.primary;
}

export function getBackgroundColorFromTheme(color: SupportedBackgroundColor, theme: Theme | ThemeV2): string {
  return theme.palette.background[color] ?? theme.palette.background.default;
}

export function getBackgroundColorForTextColorFromTheme(
  color: SupportedTextColor | SupportedFunctionColor | 'default',
  theme: Theme | ThemeV2
): string {
  switch (color) {
    case 'foreground': {
      return theme.palette.background.default;
    }
    case 'background': {
      return theme.grey.alpha.max;
    }
    case 'primary':
    case 'secondary':
    case 'tertiary':
    case 'info':
    case 'warning':
    case 'error':
    case 'pending':
    case 'success': {
      return theme.palette.background[color];
    }
    case 'max-contrast-grey':
    case 'high-contrast-grey':
    case 'mid-contrast-grey':
    case 'low-contrast-grey':
    case 'min-contrast-grey': {
      return theme.palette.background.contrast;
    }
    default:
  }
  return theme.palette.background.default;
}
