import React, { FC, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { Stack } from '@mui/material';

import Link from 'src/components/Link';
import Label from 'src/components/label';
import {
  SupportedBackgroundColor,
  SupportedTextColor,
  getBackgroundColorFromTheme,
  getTextColorFromTheme,
} from 'src/components/utils/color';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import IconButton from '../../../../components/IconButton';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import CrossIcon from '../../../../components/icons/Cross';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface ThemeProps {
  backgroundColor?: SupportedBackgroundColor;
  color?: SupportedTextColor;
}

export type AppWideBannerAction = {
  label: string;
  href?: string;
  onClick?: () => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface AppWideBannerProps extends ThemeProps {
  text: string | JSX.Element;
  action?: AppWideBannerAction;
  showClose?: boolean | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: ThemeProps) => {
      return {
        padding: '8px 20px',
        backgroundColor: props.backgroundColor
          ? getBackgroundColorFromTheme(props.backgroundColor, theme)
          : theme.palette.primary.dark,
        boxShadow: `0 -1px 0 ${theme.grey.solid.low}`,
      };
    },
    label: (props: ThemeProps) => ({
      color: props.color ? getTextColorFromTheme(props.color, theme) : theme.palette.primary.contrastText,
    }),
    linkLabel: (props: ThemeProps) => ({
      color: props.color ? getTextColorFromTheme(props.color, theme) : theme.palette.primary.contrastText,
      marginLeft: '8px',
      textDecoration: 'underline',
    }),
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const AppWideBanner: FC<AppWideBannerProps> = ({ text, action, showClose, ...themeProps }) => {
  const classes = useStyles(themeProps);
  const [isClosed, setIsClosed] = useState(false);

  if (isClosed) {
    return null;
  }

  const labelJsx =
    typeof text === 'string' ? (
      <Label className={classes.label} variant="body">
        {text}
      </Label>
    ) : (
      text
    );

  let actionJsx: JSX.Element | null = null;

  // linkable action
  if (action && action.href) {
    actionJsx = (
      <Link href={action?.href} target="_blank">
        <Label className={classes.linkLabel} variant="body">
          {action?.label}
        </Label>
      </Link>
    );
  }

  // clickable action
  if (action && action.onClick) {
    actionJsx = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link onClick={action?.onClick}>
        <Label className={classes.linkLabel} variant="body">
          {action?.label}
        </Label>
      </Link>
    );
  }

  return (
    <Stack direction="row" className={classes.root} justifyContent="space-between" spacing={1}>
      <Stack direction="row" justifyContent="center" alignItems="center" width="100%">
        {labelJsx}
        {actionJsx}
      </Stack>
      {showClose && (
        <IconButton onClick={() => setIsClosed(true)}>
          <CrossIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default AppWideBanner;
