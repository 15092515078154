import React, { useRef, useState } from 'react';

import IconButton, { IconButtonProps } from 'src/components/IconButton';
import { MoreIcon } from 'src/components/icons';
import Menu, { MenuOption, MenuProps } from 'src/components/menu';
import { BaseProps } from 'src/components/types';

export type { MenuOption as MoreMenuOption } from 'src/components/menu';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type MoreMenuButtonProps = BaseProps & {
  onSelect: (option: MenuOption) => void;
  options: MenuProps['options'];
  anchorOrigin?: MenuProps['anchorOrigin'];
  transformOrigin?: MenuProps['transformOrigin'];
  className?: string;
  color?: IconButtonProps['color'];
  disabled?: IconButtonProps['disabled'];
  size?: 'small' | 'medium';
  variant?: IconButtonProps['variant'];
  menuDataTestId?: string;
  style?: React.CSSProperties;
};

/**
 * @deprecated - Use `MoreMenuButton` from shared instead
 */
const MoreMenuButton = ({
  onSelect,
  dataTestId,
  menuDataTestId,
  options,
  className,
  anchorOrigin,
  transformOrigin,
  color,
  disabled,
  size,
  variant,
  style,
}: MoreMenuButtonProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const moreButtonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(false);
  };

  const handleSelect = (option: MenuOption, e) => {
    e.preventDefault();
    e.stopPropagation();
    onSelect(option);
    setOpen(false);
  };

  return (
    <>
      <IconButton
        style={style}
        className={className}
        color={color}
        variant={variant}
        disabled={disabled}
        dataTestId={dataTestId}
        ref={moreButtonRef}
        onClick={handleClick}
      >
        <MoreIcon fontSize={size === 'small' ? 16 : 20} />
      </IconButton>
      <Menu
        id="schedule-event-menu"
        dataTestId={menuDataTestId}
        options={options}
        anchorEl={moreButtonRef.current}
        open={open}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onSelect={handleSelect}
        onClose={handleClose}
      />
    </>
  );
};

/**
 * @deprecated - Use `MoreMenuButton` from shared instead
 */
export default MoreMenuButton;
