import { logError } from '@modernloop/shared/utils';

import { ApplicationStatus, ScheduleTaskColumnType, TaskStatus } from 'src/generated/mloop-graphql';

import { snakeToSentenceCase } from 'src/utils/strings';

import { SheduleTaskDashboardFiltersInterface } from './types';

type ScheduleTaskNameMapping = {
  [key in ScheduleTaskColumnType]?: string;
};

export const SCHEDULE_TASK_COLUMNS_NAMES: ScheduleTaskNameMapping = {
  [ScheduleTaskColumnType.Candidate]: 'Candidate',
  [ScheduleTaskColumnType.Asignee]: 'Assignee',
  [ScheduleTaskColumnType.Creator]: 'Created by',
  [ScheduleTaskColumnType.Job]: 'Job',
  [ScheduleTaskColumnType.JobStage]: 'Stage',
  [ScheduleTaskColumnType.Status]: 'Status',
  [ScheduleTaskColumnType.CreatedAt]: 'Created date',
  [ScheduleTaskColumnType.LastActivityAt]: 'Last activity',
  [ScheduleTaskColumnType.ScheduleStartAt]: 'Schedule date',
  [ScheduleTaskColumnType.TaskFlags]: 'Flags',
  [ScheduleTaskColumnType.TaskTags]: 'Tags',
  [ScheduleTaskColumnType.Urgent]: 'Urgent',
  [ScheduleTaskColumnType.ApplicationStatus]: 'Application State',
  [ScheduleTaskColumnType.Queue]: 'Queue',
};

type ScheduleTaskWidthMapping = {
  [key in ScheduleTaskColumnType]?: number;
};

export const SCHEDULE_TASK_COLUMN_WIDTH: ScheduleTaskWidthMapping = {
  [ScheduleTaskColumnType.Candidate]: 292,
  [ScheduleTaskColumnType.Asignee]: 230,
  [ScheduleTaskColumnType.Creator]: 212,
  [ScheduleTaskColumnType.Job]: 212,
  [ScheduleTaskColumnType.JobStage]: 212,
  [ScheduleTaskColumnType.Status]: 212,
  [ScheduleTaskColumnType.CreatedAt]: 96,
  [ScheduleTaskColumnType.LastActivityAt]: 120,
  [ScheduleTaskColumnType.ScheduleStartAt]: 128,
  [ScheduleTaskColumnType.TaskFlags]: 252,
  [ScheduleTaskColumnType.TaskTags]: 252,
  [ScheduleTaskColumnType.Urgent]: 120,
  [ScheduleTaskColumnType.ApplicationStatus]: 152,
  [ScheduleTaskColumnType.Queue]: 120,
};

export const EMPTY_SHEDULE_TASK_DASHBOARD_FILTERS_INTERFACE: SheduleTaskDashboardFiltersInterface = {
  search: '',
  applicationStatuses: [],
  assigneeEmployeeIds: [],
  creatorEmployeeIds: [],
  isUrgent: false,
  showUnassigned: false,
  taskStatuses: [],
  jobIds: [],
  tags: [],
  flags: [],
  jobStageNames: [],
  taskQueueIds: [],
  showNoQueue: false,
};

export const isFiltersEmpty = (filters: SheduleTaskDashboardFiltersInterface) => {
  return Boolean(
    filters.search === '' &&
      filters.assigneeEmployeeIds.length === 0 &&
      filters.creatorEmployeeIds.length === 0 &&
      filters.isUrgent === false &&
      filters.taskStatuses.length === 0 &&
      filters.jobIds.length === 0 &&
      filters.tags.length === 0 &&
      filters.flags.length === 0 &&
      filters.jobStageNames.length === 0 &&
      filters.taskQueueIds.length === 0
  );
};

export const getScheduleTaskColumns = () => [
  ScheduleTaskColumnType.Candidate,
  ScheduleTaskColumnType.Asignee,
  ScheduleTaskColumnType.Creator,
  ScheduleTaskColumnType.Job,
  ScheduleTaskColumnType.JobStage,
  ScheduleTaskColumnType.Status,
  ScheduleTaskColumnType.CreatedAt,
  ScheduleTaskColumnType.LastActivityAt,
  ScheduleTaskColumnType.TaskFlags,
  ScheduleTaskColumnType.TaskTags,
];

export const getDisplayStringForTaskStatus = (taskStatus: TaskStatus) => {
  switch (taskStatus) {
    case TaskStatus.Hold:
      return 'Hold for interviewer RSVPs';

    case TaskStatus.ReadyToSendToCandidate:
      return 'Ready for candidate communications';

    case TaskStatus.NeedsAvailability:
    case TaskStatus.WaitingForCandidate:
    case TaskStatus.ReadyToSchedule:
    case TaskStatus.ReadyToReschedule:
    case TaskStatus.Scheduled:
    case TaskStatus.Completed:
    case TaskStatus.Canceled:
    case TaskStatus.NeedsDebrief:
      return snakeToSentenceCase(taskStatus);

    default:
      logError(`Unknown TaskStatus "${taskStatus}"`);
      return 'Unknown';
  }
};

export const getColorForTaskStatus = (taskStatus: TaskStatus) => {
  switch (taskStatus) {
    case TaskStatus.Hold:
    case TaskStatus.WaitingForCandidate:
    case TaskStatus.ReadyToSendToCandidate:
      return 'pending';

    case TaskStatus.ReadyToSchedule:
    case TaskStatus.ReadyToReschedule:
    case TaskStatus.NeedsDebrief:
      return 'info';

    case TaskStatus.Scheduled:
    case TaskStatus.Completed:
      return 'success';

    case TaskStatus.NeedsAvailability:
      return 'max-contrast-grey';

    case TaskStatus.Canceled:
      return 'high-contrast-grey';

    default:
      logError(`Unknown TaskStatus "${taskStatus}"`);
      return 'default';
  }
};

export const getColorForApplicationStatus = (applicationStatus: ApplicationStatus) => {
  switch (applicationStatus) {
    case ApplicationStatus.Archived:
    case ApplicationStatus.Converted:
      return 'high-contrast-grey';

    case ApplicationStatus.Rejected:
      return 'error';

    case ApplicationStatus.Active:
      return 'max-contrast-grey';

    case ApplicationStatus.Hired:
      return 'success';

    default:
      logError(`Unknown ApplicationStatus "${applicationStatus}"`);
      return 'default';
  }
};
