import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskTagAdded_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import { pluralize } from 'src/utils/strings';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';

interface Fragments {
  activityLog: ActivityLogTaskTagAdded_ActivityLogFragment;
}

const ActivityLogTaskTagAdded: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (!details || details.__typename !== 'ActivityLogTaskTagDetails') {
    throw new Error('ActivityLogTaskTagAdded: details is not ActivityLogTaskTagDetails');
  }

  const tagsList = details.taskTags;
  const tagsString = tagsList.map((taskTag) => taskTag.name).join(', ');
  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={`added ${pluralize('tag', tagsList.length)}: ${tagsString}`}
    />
  );
};

ActivityLogTaskTagAdded.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskTagAdded_activityLog on ActivityLog {
      id
      details {
        __typename
        ... on ActivityLogTaskTagDetails {
          taskTags {
            id
            name
          }
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskTagAdded;
