import React, { useCallback, useMemo, useRef, useState } from 'react';

import { MoreIcon } from '@modernloop/shared/icons';
import { Divider, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';

export enum InterviewGroupOptions {
  MOVE_UP = 'move-up',
  MOVE_DOWN = 'move-down',
  GROUP_WITH_BELOW = 'group-with-below',
  GROUP_WITH_ABOVE = 'group-with-above',
  UNGROUP = 'ungroup',
  RENAME = 'rename-group',
  DELETE = 'delete',
}

type Props = {
  count: number;
  index: number;
  onMenuSelect: (type: InterviewGroupOptions) => void;
};

const InterviewGroupMenu = ({ count, index, onMenuSelect }: Props): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false);
  const moreButtonRef = useRef<HTMLButtonElement>(null);

  const hideMenu = () => setShowMenu(false);

  const handleGroupOptionSelect = useCallback(
    (option: InterviewGroupOptions) => {
      onMenuSelect(option);
      hideMenu();
    },
    [onMenuSelect]
  );

  const menuItems = useMemo(() => {
    return [
      index > 0 && (
        <MenuItem
          key={InterviewGroupOptions.MOVE_UP}
          onClick={() => handleGroupOptionSelect(InterviewGroupOptions.MOVE_UP)}
        >
          Move up
        </MenuItem>
      ),
      index !== count - 1 && (
        <MenuItem
          key={InterviewGroupOptions.MOVE_DOWN}
          onClick={() => handleGroupOptionSelect(InterviewGroupOptions.MOVE_DOWN)}
        >
          Move down
        </MenuItem>
      ),
      <Divider key="interview-group-menu-divider" />,
      <MenuItem
        key={InterviewGroupOptions.RENAME}
        onClick={() => handleGroupOptionSelect(InterviewGroupOptions.RENAME)}
      >
        Rename group
      </MenuItem>,
      index !== count - 1 && count !== 1 && (
        <MenuItem
          key={InterviewGroupOptions.GROUP_WITH_BELOW}
          onClick={() => handleGroupOptionSelect(InterviewGroupOptions.GROUP_WITH_BELOW)}
        >
          Group with below
        </MenuItem>
      ),
      index !== 0 && count !== 1 && (
        <MenuItem
          key={InterviewGroupOptions.GROUP_WITH_ABOVE}
          onClick={() => handleGroupOptionSelect(InterviewGroupOptions.GROUP_WITH_ABOVE)}
        >
          Group with above
        </MenuItem>
      ),
      <MenuItem
        key={InterviewGroupOptions.UNGROUP}
        onClick={() => handleGroupOptionSelect(InterviewGroupOptions.UNGROUP)}
      >
        Ungroup
      </MenuItem>,
      <Divider key="interview-group-menu-divider" />,
      <MenuItem
        key={InterviewGroupOptions.DELETE}
        onClick={() => handleGroupOptionSelect(InterviewGroupOptions.DELETE)}
      >
        Delete group & interviews
      </MenuItem>,
    ];
  }, [count, index, handleGroupOptionSelect]);

  return (
    <>
      <Tooltip title="More options">
        <IconButton ref={moreButtonRef} onClick={() => setShowMenu(!showMenu)}>
          <MoreIcon />
        </IconButton>
      </Tooltip>
      {showMenu && (
        <Menu
          open
          anchorEl={moreButtonRef.current}
          id="interview-group-options"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          disableScrollLock
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={hideMenu}
        >
          {menuItems}
        </Menu>
      )}
    </>
  );
};

export default InterviewGroupMenu;
