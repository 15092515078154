import React, { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import {
  Tab as MuiTab,
  TabProps as MuiTabProps,
  Tabs as MuiTabs,
  Theme,
  alpha,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import * as Typography from 'src/components/constants/Typography';
import { BaseProps } from 'src/components/types';

const useTabStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      borderRadius: '6px',
      height: Typography.SMALL_BUTTON_HEIGHT,
      minWidth: '0',
      minHeight: '0',
      borderBottom: 0,
      color: alpha(theme.palette.text.primary, 0.72),
      '&:hover': {
        color: theme.palette.info.dark,
        backgroundColor: alpha(theme.palette.text.primary, 0.03),
      },
      paddingLeft: `${theme.spacing(1)}px`,
      paddingRight: `${theme.spacing(1)}px`,
      paddingTop: `${theme.spacing(0.5)}px`,
      paddingBottom: `${theme.spacing(0.5)}px`,
    },
    selected: {
      color: theme.palette.info.dark,
      backgroundColor: `${alpha(theme.palette.primary.light, 0.1)} !important`,
    },
    wrapper: {
      fontFamily: theme.typography.fontFamily,
      fontSize: Typography.CAPTION_SIZE,
      lineHeight: Typography.CAPTION_LINE_HEIGHT,
      fontWeight: +Typography.FONT_WEIGHT_MEDIUM,
      flexDirection: 'row',
      '&> svg': {
        marginBottom: '0px !important',
        marginRight: `${theme.spacing(1)}px`,
      },
    },
    textColorInherit: {
      opacity: 1,
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export const PageTab: FC<MuiTabProps> = (props) => {
  const classes = useTabStyles();

  return <MuiTab disableRipple classes={classes} {...props} />;
};

const useTabsStyles = makeStyles(() =>
  createStyles({
    root: {
      height: Typography.SMALL_BUTTON_HEIGHT,
      minHeight: '0',
    },
  })
);

const useTabsStylesV2 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '28px',
      minHeight: '0',
      backgroundColor: 'white',
      borderRadius: '6px',
      outline: `1px solid ${theme.grey.alpha.low}`,
      outlineOffset: '-1px',
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface PageTabsProps extends BaseProps {
  /**
   * The currently selected tab value;
   */
  currentValue: string | number;
  /**
   * Handler for when a tab value changes. Returns the tab value selected.
   */
  onChange: (value: string | number) => void;
  /**
   * The content of the component.
   */
  children?: React.ReactNode;

  /**
   * Classname to apply to the overall MuiTabs component
   */
  className?: string;

  /**
   * Whether to use the new style of tabs
   */
  isNewStyle?: boolean;
}

/**
 * @deprecated - Use MuiTab from @mui/material instead
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/restrict-props-name.cjs
const PageTabs: FC<PageTabsProps> = ({ currentValue, dataTestId, onChange, children, className, isNewStyle }) => {
  const classesV1 = useTabsStyles();
  const classesV2 = useTabsStylesV2();
  const classes = isNewStyle ? classesV2 : classesV1;

  return (
    <MuiTabs
      className={className}
      classes={classes}
      data-testid={dataTestId}
      TabIndicatorProps={{ style: { height: '0' } }}
      value={currentValue}
      onChange={(e, newVal) => onChange(newVal)}
    >
      {children}
    </MuiTabs>
  );
};

export default PageTabs;
