import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CandidateFilledIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 16 16'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40002 0.850098C2.88124 0.850098 1.65002 2.08131 1.65002 3.6001V12.4001C1.65002 13.9189 2.88124 15.1501 4.40002 15.1501H9.57158C10.3009 15.1501 11.0004 14.8604 11.5161 14.3446L13.5446 12.3162C14.0603 11.8005 14.35 11.101 14.35 10.3717V3.6001C14.35 2.08131 13.1188 0.850098 11.6 0.850098H4.40002ZM6.00001 5.6001C6.00001 4.49553 6.89544 3.6001 8.00001 3.6001C9.10458 3.6001 10 4.49553 10 5.6001C10 6.70467 9.10458 7.6001 8.00001 7.6001C6.89544 7.6001 6.00001 6.70467 6.00001 5.6001ZM10.6 12.0001H5.40002C4.84773 12.0001 4.37884 11.5387 4.55225 11.0144C5.49312 8.16935 10.5071 8.16887 11.4478 11.0143C11.6212 11.5387 11.1523 12.0001 10.6 12.0001Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
