/* eslint-disable max-lines */

/* eslint-disable react/no-multi-comp */
import React, { Fragment, Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout';

import ErrorBoundary from 'src/shared/components/base/boundaries/ErrorBoundary';
import LoadingScreen from 'src/shared/components/base/screenLoaders/LoadingScreen';
import UserHasNoOrgGuard from 'src/shared/guards/UserHasNoOrgGuard';
import UserIsAuthenticatedGuard from 'src/shared/guards/UserIsAuthenticatedGuard';
import UserIsLoggedOutGuard from 'src/shared/guards/UserIsLoggedOutGuard';

import { MetricsPageTabs, Routes as RoutesList } from './constants/routes';
import IPUserHasAcceptedTOSGuard from './interviewer-portal/guards/IPUserHasAcceptedTOSGuard';
import IPUserHasInterviewPermissionGuard from './interviewer-portal/guards/IPUserHasInterviewPermissionGuard';
import IPUserNotInIframeGuard from './interviewer-portal/guards/IPUserNotInIframeGuard';
import OrgSettingsLayout from './layouts/OrgSettingsLayout';
import RPOrgHasConnectedDirectoryGuard from './recruiting-portal/guards/RPOrgHasConnectedDirectoryGuard';
import RPUserHasFullyOnboardGuard from './recruiting-portal/guards/RPUserHasFullyOnboardedGuard';
import RPUserHasSchedulerPermissionGuard from './recruiting-portal/guards/RPUserHasSchedulerPermissionGuard';
import UserHasOrgGuard from './shared/guards/UserHasOrgGuard';

type GuardComponent = React.ComponentType<{ children: React.ReactNode }>;
type LayoutComponent = React.ComponentType<{ children: React.ReactNode }>;

type Routes = {
  path?: string | string[];

  // Gaurds in order of outermost to innermost
  guards?: GuardComponent[];
  layout?: LayoutComponent;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<any>;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <ErrorBoundary>
      <Switch>
        {routes.map((route, i) => {
          const guards = route.guards || [Fragment];
          const Layout = route.layout || Fragment;
          const Component = route.component;

          // If the route has a path and no nested routes, it's an exact route
          const exact: boolean = !!route.path && !route.routes;

          return (
            <Route
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              path={route.path}
              exact={exact}
              render={(props) => {
                let guardedComponent = (
                  <Layout>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {route.routes ? renderRoutes(route.routes) : Component ? <Component {...props} /> : null}
                  </Layout>
                );
                // Loop through in reverse order to build the innermost component first
                for (let j = guards.length - 1; j >= 0; j--) {
                  const Guard = guards[j];
                  guardedComponent = <Guard>{guardedComponent}</Guard>;
                }

                return guardedComponent;
              }}
            />
          );
        })}
      </Switch>
    </ErrorBoundary>
  </Suspense>
);

// TODO: (Cam) - ENG-14998 - Move routes to specific app directory
const routes: Routes = [
  {
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    path: '/logout',
    component: lazy(() => import('src/shared/components/base/screenLoaders/SplashScreen')),
  },
  {
    guards: [UserIsLoggedOutGuard],
    path: '/login',
    component: lazy(() => import('src/views-new/auth/OrgLogin')),
  },
  {
    guards: [UserIsLoggedOutGuard],
    path: '/register',
    component: lazy(() => import('src/views-new/auth/OrgLogin')),
  },
  {
    path: '/callback',
    component: lazy(() => import('src/shared/components/base/screenLoaders/SplashScreen')),
  },
  {
    path: '/no-org',
    guards: [UserIsAuthenticatedGuard, UserHasNoOrgGuard],
    component: lazy(() => import('src/views/errors/NoOrgView')),
  },
  {
    path: '/',
    guards: [UserIsAuthenticatedGuard, UserHasOrgGuard, RPUserHasSchedulerPermissionGuard, RPUserHasFullyOnboardGuard],
    routes: [
      {
        path: '/extension',
        component: lazy(() => import('src/recruiting-portal/pages/extension/ExtensionSidebarPage')),
      },
      {
        path: '/extension-redirect',
        component: lazy(() => import('src/recruiting-portal/pages/extension/ExtensionRedirect')),
      },
      {
        path: '/terms-and-privacy',
        component: lazy(() => import('src/recruiting-portal/pages/TermsAndPrivacy')),
      },
      {
        path: '/grant-access/:integrationType',
        component: lazy(() => import('src/views-new/Onboarding/GrantAccess')),
      },
      {
        path: '/gql',
        component: lazy(() => import('src/recruiting-portal/pages/DevGqlExplorer')),
      },
      {
        path: '/organization',
        layout: DashboardLayout,
        routes: [
          {
            path: '/organization',
            component: lazy(() => import('src/views-new/Settings')),
          },
          {
            path: '/organization/:tab',
            component: lazy(() => import('src/views-new/Settings')),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/settings/organization',
        layout: OrgSettingsLayout,
        routes: [
          {
            path: '/settings/organization',
            component: lazy(() => import('src/views-new/Settings')),
          },
          {
            path: '/settings/organization/:tab',
            component: lazy(() => import('src/views-new/Settings')),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/settings/account',
        layout: OrgSettingsLayout,
        routes: [
          {
            path: '/settings/account',
            component: lazy(() => import('src/views-new/account/AccountView')),
          },
          {
            path: '/settings/account/:tab',
            component: lazy(() => import('src/views-new/account/AccountView')),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        path: '/',
        guards: [RPOrgHasConnectedDirectoryGuard],
        layout: DashboardLayout,
        routes: [
          {
            path: '/candidates',
            component: lazy(() => import('src/views-new/CandidateList')),
          },
          {
            path: ['/candidates/:candidateID', '/candidates/:candidateID/:tab'],
            component: lazy(() => import('src/views-new/CandidateDetails')),
          },
          {
            path: ['/upcomingevents', '/upcomingevents/:tab'],
            component: lazy(() => import('src/views-new/UpcomingEventsView')),
          },
          {
            path: '/employees',
            component: lazy(() => import('src/views-new/Employee/EmployeeListView')),
          },
          {
            path: '/interviewers',
            component: lazy(() => import('src/views-new/Employee/EmployeeListView')),
          },
          {
            path: '/interviewers/:employeeID',
            component: lazy(() => import('src/views-new/InterviewerDetailsPage')),
          },
          {
            path: '/interviewers/:employeeID/:tab',
            component: lazy(() => import('src/views-new/InterviewerDetailsPage')),
          },
          {
            path: '/schedule/:applicationID',
            component: lazy(() => import('src/views-new/ScheduleFlow')),
          },
          {
            path: '/schedule/:applicationID/new',
            component: lazy(() => import('src/views-new/ScheduleFlow')),
          },
          {
            path: '/schedule/:applicationID/done',
            component: lazy(() => import('src/views-new/ScheduleFlow/ScheduleFlowDone')),
          },
          {
            path: '/debrief/:applicationID/done',
            component: lazy(() => import('src/views-new/Debrief/DebriefFlowDone')),
          },
          {
            path: '/update/:applicationStageId/done',
            component: lazy(() => import('src/views-new/UpdateFlow/UpdateFlowDone')),
          },
          {
            path: '/reschedule/:applicationStageID',
            component: lazy(() => import('src/views-new/RescheduleFlow')),
          },
          {
            path: '/update/:applicationStageId',
            component: lazy(() => import('src/views-new/UpdateFlow')),
          },
          {
            path: '/debrief/:applicationId/',
            component: lazy(() => import('src/views-new/Debrief')),
          },
          {
            path: '/metrics/:tab',
            component: lazy(() => import('src/views-new/MetricsPage')),
          },
          {
            path: '/account',
            component: lazy(() => import('src/views-new/account/AccountView')),
          },
          {
            path: '/account/:tab',
            component: lazy(() => import('src/views-new/account/AccountView')),
          },
          {
            path: '/jobs',
            component: lazy(() => import('src/views-new/Jobs')),
          },
          {
            path: '/jobs/:jobID',
            component: lazy(() => import('src/views-new/JobDetailsPage')),
          },
          {
            path: '/job-settings-import',
            component: lazy(() => import('src/views-new/JobImportSettings')),
          },
          {
            path: '/jobs/:jobID/:tab',
            component: lazy(() => import('src/views-new/JobDetailsPage')),
          },
          {
            path: '/jobs/:jobID/:tab/:jobStageID',
            component: lazy(() => import('src/views-new/JobDetailsPage')),
          },
          {
            path: '/interviews',
            component: lazy(() => import('src/views-new/InterviewModule')),
          },
          {
            path: '/interviews/:interviewID',
            component: lazy(() => import('src/views-new/InterviewModuleDetails')),
          },
          {
            path: '/interviews/:interviewID/:tab',
            component: lazy(() => import('src/views-new/InterviewModuleDetails')),
          },
          {
            path: '/metrics',
            component: () => <Redirect to={`/metrics/${MetricsPageTabs.RECRUITERS}`} />,
          },
          {
            path: [RoutesList.MySchedulingTasks, `${RoutesList.MySchedulingTasks}/:tab`],
            component: lazy(() => import('src/views-new/SchedulingTasks/MySchedulingTasks')),
          },
          {
            path: RoutesList.AllSchedulingTasks,
            component: lazy(() => import('src/views-new/SchedulingTasks/AllSchedulingTasks')),
          },
          {
            path: ['/', '/app'],
            component: lazy(() => import('src/views-new/SchedulingTasks/MySchedulingTasks')),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

// TODO: (Cam) - ENG-14998 - Move routes to specific app directory
export const interviewerRoutes: Routes = [
  {
    path: '/ip',
    // TODO: (Cam) Do we need to use `OrgOnboarded` guard?
    guards: [UserIsAuthenticatedGuard, UserHasOrgGuard, IPUserHasInterviewPermissionGuard],
    routes: [
      {
        path: '/ip/gql',
        component: lazy(() => import('src/interviewer-portal/pages/DevGqlExplorer')),
      },
      {
        path: '/ip/terms-and-privacy',
        component: lazy(() => import('src/interviewer-portal/pages/TermsAndPrivacy')),
      },
      {
        path: '/ip/extension',
        component: lazy(() => import('src/interviewer-portal/pages/extension/ExtensionSidebarPage')),
      },
      {
        path: '/ip',
        guards: [IPUserNotInIframeGuard, IPUserHasAcceptedTOSGuard],
        routes: [
          {
            path: '/ip',
            component: lazy(() => import('src/interviewer-portal/pages/InterviewerInfo')),
          },
          {
            path: '/ip/:interviewerId',
            component: lazy(() => import('src/interviewer-portal/pages/InterviewerInfo')),
          },
          {
            component: () => <Redirect to="/404" />,
          },
        ],
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

export default routes;
