/* eslint-disable max-lines */
import React, { CSSProperties, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { EventContentArg, EventDropArg } from '@fullcalendar/common';
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { ResourceLabelContentArg } from '@fullcalendar/resource-common';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FCWithFragments } from '@modernloop/shared/components';
import { PlusIcon } from '@modernloop/shared/icons';
import { Box, CircularProgress, Dialog, Divider, Fade, Grid } from '@mui/material';
import { differenceInMinutes, isSameDay } from 'date-fns';
import { noop } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  AtsInterviewDefinitionFragment,
  BaseCalendarView_InterviewPlanFragment,
  CalendarView_InterviewPlanFragment,
  EventContent_InterviewFragment,
  EventContent_OriginalInterviewsFragment,
} from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import IconButton from 'src/components/IconButton';
import Stack from 'src/components/Stack';
import Button from 'src/components/button';
import Calendar from 'src/components/calendar';
import { CaretLeftIcon, MaximizeIcon, MinimizeIcon } from 'src/components/icons';
import Label from 'src/components/label';

import useFetchLoadAndLimit from 'src/hooks/api/interview/useFetchLoadLimit';
import { useOrgAtsService } from 'src/hooks/atsService';
import { getAtsInterviewDefinitionFields } from 'src/hooks/atsService/util';
import useDisplayTimezone from 'src/hooks/useDisplayTimezone';
import useOrgId from 'src/hooks/useOrgId';
import usePrevious from 'src/hooks/usePrevious';
import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { clearSelectedScheduleId, setScheduleOptionsSetupRefresh } from 'src/slices/scheduling';

import { upsertConflict } from 'src/store/actions/conflicts';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { getConflictsById } from 'src/store/selectors/conflicts';
import { getScheduleIndexById } from 'src/store/selectors/schedules';
import { getStepScheduleOptions } from 'src/store/selectors/scheduling';
import { Conflict } from 'src/store/slices/conflicts';

import { Theme } from 'src/theme/type';

import { Theme as ThemeV5 } from 'src/themeMui5/type';

import { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { Event } from 'src/utils/api/getEmployeeCalendarEvents';
import { InterviewEvent, InterviewSchedule, RichInterviewer } from 'src/utils/api/getScheduleOptions';
import { endOfDay } from 'src/utils/dateUtils';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';
import ScheduleIssuesButton from 'src/views-new/ScheduleFlow/Steps/Common/ScheduleIssues/ScheduleIssuesButton';
import InterviewDetails from 'src/views-new/ScheduleFlow/Steps/Schedule/InterviewDetails';
import AtsScorecardModal from 'src/views-new/Scorecards/AtsScorecardModal';

import { useDispatch, useSelector } from 'src/store';
import { DEFAULT_INTERVIEW_NAME } from 'src/strings';

import AllInterviewsHiddenModal from './AllInterviewsHiddenModal';
import CalendarDatePicker from './CalendarDatePicker';
import EventContent from './EventContent';
import OverlappingEventsModal from './OverlappingEventsModal';
import ResourceLabelContent from './ResourceLabelContent';
import ScheduleRefreshOption from './ScheduleRefreshOption';
import { ScheduleFlowType } from './types';
import useEmployeeWorkHoursByIdForDay from './useEmployeeWorkHoursByIdForDay';
import { useGetEmployeesCalendarEventsForDay } from './useGetEmployeesCalendarEventsForDay';
import {
  CalendarEventType,
  EmployeeCalendarEventsById,
  getBusinessHoursFromCandidateAvailability,
  getCalendarEventsFromSchedule,
  getCalendarResourcesFromSchedule,
  getFirstEventStartTimeForDay,
  getHasAllDay,
  getOverlappingInterviewEventsFromSchedule,
  getScheduleEndTimeForDay,
  getUniqueEmployeeIdsFromSchedule,
  getUniqueEventDaysFromSchedule,
} from './utils';

type BaseCalendarViewFragments = {
  interviewPlan: BaseCalendarView_InterviewPlanFragment | undefined;
};

// eslint-disable-next-line modernloop/restrict-props-name.cjs
type BaseCalendarViewProps = {
  schedule: InterviewSchedule;
  index: number;
  employeeIds: string[];
  showRefreshOption?: boolean;
  scheduleFlowType: ScheduleFlowType;
  onBack?: () => void;
  onUseSchedule: (scheduleId: string) => void;
};

type Fragments = {
  interviewPlan: CalendarView_InterviewPlanFragment | undefined;
};

type Props = {
  scheduleId: string;
} & Pick<BaseCalendarViewProps, 'onBack' | 'onUseSchedule' | 'showRefreshOption' | 'scheduleFlowType'>;

const COLUMN_MIN_WIDTH = 200;

const useDialogStyles = makeStyles(() =>
  createStyles({
    paperFullWidth: {
      maxWidth: 'unset',
      height: '100%',
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      marginRight: '8px',
    },
    useScheduleButton: {
      marginLeft: '4px',
    },
    circularProgress: {
      marginLeft: '4px',
    },
    calendarContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      backgroundColor: theme.palette.background.alternate,
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '12px',
      overflow: 'hidden',
    },
    calendarHeaderContainer: {
      margin: '8px',
      width: 'calc(100% - 16px)',
    },
    errorAlertButton: {
      padding: '0 4px',
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const BaseCalendarView: FCWithFragments<BaseCalendarViewFragments, BaseCalendarViewProps> = ({
  interviewPlan,
  schedule,
  index,
  employeeIds,
  scheduleFlowType,
  showRefreshOption = true,
  onBack,
  onUseSchedule,
}): JSX.Element => {
  const atsService = useOrgAtsService();
  const organizationId = useOrgId();
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const dispatch = useDispatch();
  const calendarHeaderRef = useRef<HTMLDivElement>(null);
  const calendarContainerRef = useRef<HTMLDivElement>(null);
  const fullScreenCalendarContainerRef = useRef<HTMLDivElement>(null);
  const [calendarHeaderHeight, setCalendarHeaderHeight] = useState<number>(0);
  const [calendarHeight, setCalendarHeight] = useState<number>(1); // Making 1px so that calendar renders during tests.
  const [fullScreenCalendarHeight, setFullScreenCalendarHeight] = useState<number>(0);

  const scheduleFlowData = useScheduleFlowData();
  const { atsJobId, candidateAvailabilities } = scheduleFlowData;
  const conflictsById = useSelector(getConflictsById);
  const { submitting } = useSelector(getStepScheduleOptions);

  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [showAllDay, setShowAllDay] = useState(true);
  const [activeDay, setActiveDay] = useState(0);
  const timezone = useDisplayTimezone();
  const previousTimezone = usePrevious(timezone);
  const [showScorecardSelectModal, setShowScorecardSelectModal] = useState(false);
  const [showOverlappingEventsModal, setShowOverlappingEventsModal] = useState(false);
  const [showAllInterviewsAreHiddenModal, setShowAllInterviewsAreHiddenModal] = useState(false);

  const { isLoading, isError, data, isFetching, refetch } = useGetEmployeesCalendarEventsForDay(
    organizationId,
    employeeIds,
    activeDay,
    endOfDay(activeDay, timezone).getTime(),
    timezone
  );

  const loadAndLimitResult = useFetchLoadAndLimit(activeDay, timezone, employeeIds);

  const employeeWorkHoursByIdForDay = useEmployeeWorkHoursByIdForDay(
    employeeIds,
    timezone,
    assertIsoTimestamp(new Date(activeDay || Date.now()).toISOString())
  );

  const employeeEventsById = useMemo(() => {
    const result: EmployeeCalendarEventsById = {};
    if (!isLoading && !isError && data && data.calendars) {
      Object.keys(data.calendars).forEach((employeeId) => {
        result[employeeId] = data.calendars[employeeId].events;
      });
    }
    return result;
  }, [data, isError, isLoading]);

  const hasAllDay = useMemo(() => {
    return getHasAllDay(employeeEventsById, activeDay, timezone);
  }, [activeDay, employeeEventsById, timezone]);

  const scrollTime = useMemo(() => {
    return getFirstEventStartTimeForDay(schedule, activeDay, timezone);
  }, [activeDay, schedule, timezone]);

  const uniqueEventDays = useMemo(() => {
    const result = getUniqueEventDaysFromSchedule(schedule, timezone);

    if (result && result.length > 0 && (activeDay === 0 || previousTimezone !== timezone)) {
      setActiveDay(result[0]);
    }

    return result;
  }, [activeDay, previousTimezone, schedule, timezone]);

  const events = useMemo(() => {
    return getCalendarEventsFromSchedule(schedule, employeeEventsById, timezone);
  }, [employeeEventsById, schedule, timezone]);

  const businessHours = useMemo(() => {
    return getBusinessHoursFromCandidateAvailability(candidateAvailabilities, timezone, activeDay);
  }, [activeDay, candidateAvailabilities, timezone]);

  const resources = useMemo(() => {
    return getCalendarResourcesFromSchedule(
      activeDay,
      schedule,
      employeeEventsById,
      employeeWorkHoursByIdForDay,
      timezone,
      true
    );
  }, [activeDay, employeeEventsById, employeeWorkHoursByIdForDay, schedule, timezone]);

  const newInterview = useMemo(() => {
    return schedule.events?.find((event) => !event.interviewers || event.interviewers.length === 0);
  }, [schedule]);

  const overlappingEvents = useMemo(() => {
    return getOverlappingInterviewEventsFromSchedule(schedule);
  }, [schedule]);

  const areAllInterviewsHiddenFromCandidate = useMemo(() => {
    return schedule.events.reduce((isHiddenFromCandidate: boolean, event: InterviewEvent) => {
      return isHiddenFromCandidate && event.isHiddenFromCandidate;
    }, true);
  }, [schedule.events]);

  const handleSetShowAllDay = (show: boolean) => {
    setShowAllDay(show);
  };

  const handleWindowResize = useCallback(() => {
    if (calendarContainerRef.current && calendarContainerRef.current.clientHeight) {
      setCalendarHeight(calendarContainerRef.current.clientHeight);
    }

    if (calendarHeaderRef.current) {
      setCalendarHeaderHeight(calendarHeaderRef.current.clientHeight);
    }

    if (fullScreenCalendarContainerRef.current) {
      setFullScreenCalendarHeight(fullScreenCalendarContainerRef.current.clientHeight);
    } else {
      setFullScreenCalendarHeight(window.innerHeight - (calendarHeaderRef.current?.clientHeight ?? 0));
    }
  }, []);

  useLayoutEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [calendarContainerRef, handleWindowResize]);

  const handleEventDrop = (args: EventDropArg) => {
    const { event } = args;
    const scheduleEvent = event.extendedProps.event as InterviewEvent;
    // console.log(`start = ${event.start.getTime()}, end = ${event.end.getTime()})`);

    if (!schedule.id || !event.start || !event.end || !scheduleEvent) return;

    dispatch(
      addInterviewScheduleUpdate({
        type: 'SchdeuleUpdateInterviewTime',
        scheduleId: schedule.id,
        applicationStageInterviewId: scheduleEvent.id,
        startAt: event.start.toISOString(),
        endAt: event.end.toISOString(),
        duration: differenceInMinutes(event.end, event.start),
      })
    );
  };

  const handleEventResize = (args: EventResizeDoneArg) => {
    const { event } = args;
    const scheduleEvent = event.extendedProps.event as InterviewEvent;

    if (!schedule.id || !event.start || !event.end || !scheduleEvent) return;

    dispatch(
      addInterviewScheduleUpdate({
        type: 'SchdeuleUpdateInterviewTime',
        scheduleId: schedule.id,
        applicationStageInterviewId: scheduleEvent.id,
        startAt: event.start.toISOString(),
        endAt: event.end.toISOString(),
        duration: differenceInMinutes(event.end, event.start),
      })
    );
  };

  const handleMarkAsConflict = (
    event: Event,
    isConflict: boolean,
    eventTimezone: string,
    eventInterviewer: RichInterviewer
    // eslint-disable-next-line max-params
  ) => {
    if (!schedule.id || !event.start || !event.end || !eventInterviewer || !event.uid) {
      return;
    }

    // Using eventInterviewer.employee.email is a workaround to trick the backend into avoiding scheduling over
    // a private event for a particular interviewer. If we pass just the start and end time and no emails
    // then it won't know who the hard conflict is for.
    const organizerEmail = event.organizer?.email || eventInterviewer.employee.email;
    if (!organizerEmail) return;

    const conflict: Conflict = {
      eventUid: event.uid,
      isHardConflict: isConflict,
      startAt: event.start,
      endAt: event.end,
      organizerEmail,
      attendeesEmails: (event.attendee?.map((a) => a.email).filter((value) => !!value) as string[]) ?? [organizerEmail],
      summary: event.title ?? '',
    };

    dispatch(upsertConflict(conflict));
    dispatch(setScheduleOptionsSetupRefresh(true));
  };

  const handleBack = () => {
    if (onBack) {
      onBack();
      return;
    }
    dispatch(clearSelectedScheduleId());
  };

  const handleUseSchedule = () => {
    if (overlappingEvents.length > 0) {
      setShowOverlappingEventsModal(true);
      return;
    }
    if (areAllInterviewsHiddenFromCandidate) {
      setShowAllInterviewsAreHiddenModal(true);
      return;
    }
    onUseSchedule(schedule.id);
  };

  const handleAtsScorecardSelect = (atsInterviewDefinition: AtsInterviewDefinitionFragment) => {
    const startTime = getScheduleEndTimeForDay(schedule, activeDay, timezone);

    dispatch(
      addInterviewScheduleUpdate({
        type: 'ScheduleUpdateNewInterview',
        scheduleId: schedule.id,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line max-lines
        applicationStageInterviewId: uuid(),
        startAt: assertIsoTimestamp(new Date(startTime).toISOString()),
        endAt: assertIsoTimestamp(
          new Date(
            startTime +
              (getAtsInterviewDefinitionFields(atsInterviewDefinition.atsFields, 'estimatedMinutes') ?? 45) * 60 * 1000
          ).toISOString()
        ),
        name: atsInterviewDefinition.name || DEFAULT_INTERVIEW_NAME,
        duration: getAtsInterviewDefinitionFields(atsInterviewDefinition.atsFields, 'estimatedMinutes') || 45,
        atsInterviewDefinitionId: atsInterviewDefinition.atsId,
        atsJobId: atsInterviewDefinition.atsJobId || undefined,
        atsJobStageId: atsInterviewDefinition.atsJobStageId || undefined,
      })
    );

    if (schedule.events.length === 0) {
      setTimeout(() => handleWindowResize(), 0);
    }
  };

  const renderEventContent = (args: EventContentArg) => {
    if (!atsService || !organizationId) return null;

    const employeeEvent = args.event.extendedProps?.employeeEvent as Event;
    const conflict = employeeEvent && employeeEvent.uid ? conflictsById[employeeEvent.uid] : undefined;

    let interview: EventContent_InterviewFragment | undefined;
    let originalInterviews: EventContent_OriginalInterviewsFragment[] = [];
    if (
      [CalendarEventType.CANDIDATE_EVENT, CalendarEventType.INTERVIEWER_EVENT].includes(
        args.event.extendedProps?.type
      ) &&
      args.event.extendedProps?.event
    ) {
      const interviewerEvent = args.event.extendedProps.event as InterviewEvent;
      interview = interviewPlan?.jobStageInterviewGroups
        ?.map((g) => g.jobStageInterviews || [])
        .flat()
        .find((i) => i.id === interviewerEvent.slotId);

      const allInterviewsInPlan =
        interviewPlan?.jobStageInterviewGroups?.map((g) => g.jobStageInterviews || []).flat() || [];
      const linkedSeatIds =
        interview?.jobStageInterviewSeats
          ?.filter((seat) => seat.linkedSeat)
          .map((seat) => seat.linkedSeat?.linkedJobStageInterviewSeatId) || [];
      originalInterviews = linkedSeatIds
        .map(
          (seatId) =>
            allInterviewsInPlan.filter((i) => i.jobStageInterviewSeats?.some((seat) => seat.id === seatId)) || []
        )
        .flat();
    }

    return (
      <EventContent
        organizationId={organizationId}
        scheduleId={schedule.id}
        atsJobId={atsJobId}
        interviewPlanId={interviewPlan?.id}
        interview={interview}
        originalInterviews={originalInterviews}
        args={args}
        conflict={conflict}
        markAsConflict={handleMarkAsConflict}
        scheduleFlowType={scheduleFlowType}
      />
    );
  };

  const renderResourceLabelContent = (args: ResourceLabelContentArg) => {
    if (!schedule.id) return null;
    return (
      <ResourceLabelContent
        args={args}
        activeDay={activeDay}
        scheduleId={schedule.id}
        showAllDay={showAllDay}
        timezone={timezone}
        loadAndLimits={loadAndLimitResult.data || []}
        setShowAllDay={handleSetShowAllDay}
      />
    );
  };

  const handleEnterFullScreenMode = () => {
    setFullScreenMode(true);
    setTimeout(() => handleWindowResize(), 0);
  };

  const itemStyles: { [key: number]: CSSProperties } = { 1: { flexShrink: 0 }, 2: { flexShrink: 0 } };

  const calendarJsx = (
    <>
      <div ref={calendarHeaderRef}>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
          itemStyles={itemStyles}
          spacing={1}
          className={classes.calendarHeaderContainer}
        >
          <CalendarDatePicker
            activeDay={activeDay}
            eventDays={uniqueEventDays}
            timezone={timezone}
            onDayChange={(day) => setActiveDay(day)}
          />
          {isError && (
            <Alert
              alignItems="center"
              sx={{ padding: (theme: ThemeV5) => `0 ${theme.spacing(1)}px` }}
              status="error"
              title={
                <Stack alignItems="center">
                  <Label color="max-contrast-grey" variant="captions">
                    Sorry, something went wrong. There was an error loading interviewer calendar.
                  </Label>
                  <Button
                    label="Try again"
                    size="small"
                    variant={isFetching ? 'unstyled' : 'link'}
                    className={classes.errorAlertButton}
                    onClick={() => refetch()}
                    disabled={isFetching}
                    endIcon={isFetching ? <CircularProgress size="16px" /> : undefined}
                  />
                </Stack>
              }
            />
          )}
          {fullScreenMode && (
            <IconButton onClick={() => setFullScreenMode(false)}>
              <MinimizeIcon color="max-contrast-grey" />
            </IconButton>
          )}
          {!fullScreenMode && (
            <IconButton onClick={handleEnterFullScreenMode}>
              <MaximizeIcon color="max-contrast-grey" />
            </IconButton>
          )}
        </Stack>
      </div>
      <Divider />
      <div style={{ flexGrow: 1, overflow: 'hidden' }} ref={calendarContainerRef}>
        {((!fullScreenMode && calendarHeight !== 0) || (fullScreenMode && fullScreenCalendarHeight !== 0)) && (
          <Calendar
            editable={!submitting}
            allDaySlot={hasAllDay && showAllDay}
            height={
              (fullScreenMode ? fullScreenCalendarHeight : calendarHeight) -
              calendarHeaderHeight -
              1 /* 1px → height of divider */
            }
            businessHours={businessHours}
            dayMinWidth={COLUMN_MIN_WIDTH}
            scrollTime={scrollTime}
            events={events}
            initialDate={activeDay}
            resourceOrderField="order"
            resources={resources}
            timeZone={timezone}
            eventDrop={handleEventDrop}
            eventResize={handleEventResize}
            renderEventContent={renderEventContent}
            renderResourceLabelContent={renderResourceLabelContent}
          />
        )}
      </div>
      {showRefreshOption && <ScheduleRefreshOption scheduleFlowType={scheduleFlowType} />}
    </>
  );

  return (
    <>
      <Box pt={1} pb={2}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Button startIcon={<CaretLeftIcon />} variant="outlined" label="View all options" onClick={handleBack} />
          </Grid>
          <Grid item>
            <Label fontWeight={600}>{`Option ${index + 1}`}</Label>
          </Grid>
          <Grid item>
            <Stack alignItems="center" spacing={1}>
              {uniqueEventDays.length > 1 &&
                uniqueEventDays.map((day, dayIndex) => {
                  return (
                    <Button
                      color={isSameDay(day, activeDay) ? 'info' : 'default'}
                      variant="outlined"
                      key={day}
                      label={`Day ${dayIndex + 1}`}
                      onClick={() => setActiveDay(day)}
                    />
                  );
                })}
              <ScheduleIssuesButton />
              {scheduleFlowType !== ScheduleFlowType.DEBRIEF && (
                <Button
                  dataTestId="schedule-flow-add-interview-button"
                  startIcon={<PlusIcon />}
                  variant="outlined"
                  label="Add interview"
                  disabled={submitting}
                  onClick={() => setShowScorecardSelectModal(true)}
                />
              )}
              <Button
                dataTestId="schedule-flow-use-this-schedule-button"
                className={classes.useScheduleButton}
                color="info"
                variant="contained"
                label="Use this schedule"
                disabled={submitting}
                onClick={handleUseSchedule}
                endIcon={submitting ? <CircularProgress size={20} className={classes.circularProgress} /> : undefined}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {!fullScreenMode && (
        <div className={classes.calendarContainer} ref={calendarContainerRef}>
          {calendarJsx}
        </div>
      )}
      {fullScreenMode && (
        <Dialog open classes={dialogClasses} fullWidth maxWidth="xl" onClose={() => setFullScreenMode(false)}>
          <Fade in timeout={400}>
            <div className={classes.calendarContainer} ref={fullScreenCalendarContainerRef}>
              {calendarJsx}
            </div>
          </Fade>
        </Dialog>
      )}
      {newInterview && (
        <InterviewDetails
          atsJobId={atsJobId}
          interviewPlanId={interviewPlan?.id}
          organizationId={organizationId}
          open
          isNewInterview
          scheduleId={schedule.id}
          interviewEvent={newInterview}
          scheduleFlowType={ScheduleFlowType.UPDATE}
          interview={undefined} // No interview in interview plan for new interview
          originalInterviews={[]} // No original interviews in interview plan for new interview
          onClose={noop}
        />
      )}

      {showScorecardSelectModal && schedule.jobStageId ? (
        <AtsScorecardModal
          atsJobId={atsJobId}
          jobStageId={schedule.jobStageId}
          onClose={() => setShowScorecardSelectModal(false)}
          onSelectScorecard={handleAtsScorecardSelect}
        />
      ) : null}

      {showOverlappingEventsModal && (
        <OverlappingEventsModal events={overlappingEvents} onClose={() => setShowOverlappingEventsModal(false)} />
      )}

      {showAllInterviewsAreHiddenModal && (
        <AllInterviewsHiddenModal onClose={() => setShowAllInterviewsAreHiddenModal(false)} />
      )}
    </>
  );
};

BaseCalendarView.fragments = {
  interviewPlan: gql`
    ${EventContent.fragments.interview}
    ${EventContent.fragments.originalInterviews}
    ${InterviewDetails.fragments.interview}
    ${InterviewDetails.fragments.originalInterviews}
    fragment BaseCalendarView_interviewPlan on JobStage {
      id
      jobStageInterviewGroups {
        id
        jobStageInterviews {
          id
          ...EventContent_interview
          ...EventContent_originalInterviews
          ...InterviewDetails_interview
          ...InterviewDetails_originalInterviews
        }
      }
    }
  `,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const CalendarView: FCWithFragments<Fragments, Props> = ({
  scheduleId,
  showRefreshOption,
  scheduleFlowType,
  interviewPlan,
  onBack,
  onUseSchedule,
}): JSX.Element | null => {
  const schedule = useScheduleWithoutBreaks(scheduleId);
  const index = useSelector((state) => getScheduleIndexById(state, scheduleId));

  const employeeIds = useMemo(() => {
    if (!schedule) return [];

    // The sort helps prevent unnecessary calls to `listEvent`
    // if there are no changes to interviewers in the schedule.
    return getUniqueEmployeeIdsFromSchedule(schedule).sort();
  }, [schedule]);

  if (!schedule) return null;
  return (
    <BaseCalendarView
      schedule={schedule}
      index={index}
      employeeIds={employeeIds}
      showRefreshOption={showRefreshOption}
      scheduleFlowType={scheduleFlowType}
      interviewPlan={interviewPlan}
      onBack={onBack}
      onUseSchedule={onUseSchedule}
    />
  );
};

CalendarView.fragments = {
  interviewPlan: gql`
    ${BaseCalendarView.fragments.interviewPlan}
    fragment CalendarView_interviewPlan on JobStage {
      id
      ...BaseCalendarView_interviewPlan
    }
  `,
};

export default CalendarView;
