import React from 'react';

import BaseIcon, { Props } from './Icon';

const RsvpYes = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} tiny>
      <circle cx="7" cy="7" r="6.5" fill="#E7F9F7" stroke="white" />
      <path d="M4 7L6.5 9.5L10.5 5" stroke="#056667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </BaseIcon>
  );
};

export default RsvpYes;
