import React, { FC } from 'react';

import { gql } from '@apollo/client';
import { ZeroState } from '@modernloop/shared/components';
import { Loader, PublicError } from '@modernloop/shared/helper-components';
import { Stack, Theme } from '@mui/material';

import { useInterviewModuleMemberSidepanelQueryQuery } from 'src/generated/mloop-graphql';

import MembersList from 'src/views-new/InterviewModuleDetails/Members';

import InterviewModuleMemberHeader from './InterviewModuleMemberHeader';
import InterviewModuleMemberKeyDates from './InterviewModuleMemberKeyDates';
import ModuleLoad from './ModuleLoad';
import InterviewModuleMemberModuleLog from './ModuleLogs';
import PendingApproval from './PendingApproval';
import TrainingPlanSection from './TrainingPlanSection';

type Props = {
  interviewModuleId: string;
  moduleMemberId: string;
};

const InterviewModuleMember: FC<Props> = ({ interviewModuleId, moduleMemberId }) => {
  const { data, error, loading } = useInterviewModuleMemberSidepanelQueryQuery({
    variables: {
      interviewModuleId,
      moduleMemberId,
    },
  });

  if (loading && !data) {
    return <Loader loading={loading} />;
  }

  if (error) {
    return <PublicError error={error} />;
  }

  if (!data?.interviewModuleMember) {
    return <ZeroState label="Failed to load data, please try again!" />;
  }

  return (
    <Stack width="100%" height="100%">
      <InterviewModuleMemberHeader moduleMember={data?.interviewModuleMember} />
      <Stack
        padding={(theme: Theme) => theme.spacing(2.5)}
        rowGap={(theme: Theme) => theme.spacing(2.5)}
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.background.contrast,
        })}
        height="100%"
      >
        <PendingApproval moduleMember={data?.interviewModuleMember} />
        <InterviewModuleMemberKeyDates moduleMember={data?.interviewModuleMember} />
        <ModuleLoad moduleMember={data?.interviewModuleMember} disabled={false} />
        <TrainingPlanSection moduleMember={data?.interviewModuleMember} />

        <InterviewModuleMemberModuleLog moduleMember={data.interviewModuleMember} />
      </Stack>
    </Stack>
  );
};

export const ModuleMemberSidePanelFragment = gql`
  ${PendingApproval.fragments.moduleMember}
  ${InterviewModuleMemberHeader.fragments.moduleMember}
  ${InterviewModuleMemberKeyDates.fragments.moduleMember}
  ${ModuleLoad.fragments.moduleMember}
  ${TrainingPlanSection.fragments.moduleMember}
  ${MembersList.fragments.module}
  fragment ModuleMemberSidePanel on InterviewModuleMember {
    id
    employeeId
    interviewModuleId
    ...PendingApproval_moduleMember
    ...InterviewModuleMemberHeader_moduleMember
    ...InterviewModuleMemberKeyDates_moduleMember
    ...moduleLoad_moduleMember
    ...TrainingPlanSection_moduleMember
    #Note interviewModule is not used in this file. But on every modulemember mutation membersTrainedCount can change and theres an Alert to be shown if there are no trained members. So fetching it here instead of refetching at every mutation or via refetchQueries
    interviewModule {
      id
      ...MembersList_module
    }
  }
`;

export const InterviewModuleMemberSidepanelQuery = gql`
  ${ModuleMemberSidePanelFragment}
  ${InterviewModuleMemberModuleLog.fragments.moduleMember}
  query InterviewModuleMemberSidepanelQuery($interviewModuleId: uuid!, $moduleMemberId: uuid!) {
    interviewModuleMember(interviewModuleId: $interviewModuleId, employeeId: $moduleMemberId) {
      ...ModuleMemberSidePanel
      ...InterviewModuleMemberModuleLog_moduleMember
    }
  }
`;

export const InterviewModuleMemberUpdateMutation = gql`
  ${ModuleMemberSidePanelFragment}
  mutation ModuleMemberUpdate($input: InterviewModuleMemberUpdateInput!) {
    interviewModuleMemberUpdate(input: $input) {
      interviewModuleMember {
        ...ModuleMemberSidePanel
      }
    }
  }
`;

export const InterviewModuleMemberApproveMutation = gql`
  ${ModuleMemberSidePanelFragment}
  mutation ModuleMemberApprove($input: InterviewModuleMemberApproveInput!) {
    interviewModuleMemberApprove(input: $input) {
      interviewModuleMember {
        ...ModuleMemberSidePanel
      }
    }
  }
`;

export default InterviewModuleMember;
