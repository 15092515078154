/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

import { JobStage } from 'src/store/slices/job-stage';
import { JobStageInterviewGroup } from 'src/store/slices/job-stage-interview-group';

import { State } from 'src/store';

export const getJobStageById = (state: State, jobStageId: string): JobStage => state.jobStage.byId[jobStageId];

export const getJobStageErrorById = (state: State, jobStageId: string): string => state.jobStage.errorById[jobStageId];

export const getJobStageLoadingById = (state: State, jobStageId: string): boolean =>
  state.jobStage.loadingById[jobStageId];

export const getFirstJobStageInterviewGroup = (
  state: State,
  jobStageId: string
): JobStageInterviewGroup | undefined => {
  const jobStage = state.jobStage.byId[jobStageId];

  if (!jobStage) return undefined;

  const { jobStageInterviewGroupIds } = jobStage;
  if (jobStageInterviewGroupIds.length === 0) return undefined;

  const id = jobStageInterviewGroupIds[0];
  return state.jobStageInterviewGroup.byId[id];
};

export const getLastJobStageInterviewGroup = (state: State, jobStageId: string): JobStageInterviewGroup | undefined => {
  const jobStage = state.jobStage.byId[jobStageId];

  if (!jobStage) return undefined;

  const { jobStageInterviewGroupIds } = jobStage;
  if (jobStageInterviewGroupIds.length === 0) return undefined;

  const id = jobStageInterviewGroupIds[jobStageInterviewGroupIds.length - 1];
  return state.jobStageInterviewGroup.byId[id];
};

export const getAtsJobIdByJobStageId = createSelector(getJobStageById, (jobStage) => jobStage?.atsJobId);
