import { SvgIcon, SvgIconProps } from './SvgIcon';

export const LogoutIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3.25C2.58579 3.25 2.25 3.58579 2.25 4V15C2.25 15.2634 2.38822 15.5076 2.61413 15.6431L6.85688 18.1888C7.69004 18.6887 8.75 18.0885 8.75 17.1169V15.75H9.5C11.0188 15.75 12.25 14.5188 12.25 13V12.5C12.25 12.0858 11.9142 11.75 11.5 11.75C11.0858 11.75 10.75 12.0858 10.75 12.5V13C10.75 13.6904 10.1904 14.25 9.5 14.25H8.75V7.56619C8.75 6.95148 8.42748 6.38184 7.90037 6.06558L5.70774 4.75H9.5C10.1904 4.75 10.75 5.30964 10.75 6V7C10.75 7.41421 11.0858 7.75 11.5 7.75C11.9142 7.75 12.25 7.41421 12.25 7V6C12.25 4.48122 11.0188 3.25 9.5 3.25H3ZM3.75 14.5754V5.32464L7.12862 7.35182C7.20393 7.397 7.25 7.47837 7.25 7.56619V15V16.6754L3.75 14.5754ZM15.0303 6.96967C14.7374 6.67678 14.2626 6.67678 13.9697 6.96967C13.6768 7.26256 13.6768 7.73744 13.9697 8.03033L15.1893 9.25H10.5C10.0858 9.25 9.75 9.58579 9.75 10C9.75 10.4142 10.0858 10.75 10.5 10.75H15.1893L13.9697 11.9697C13.6768 12.2626 13.6768 12.7374 13.9697 13.0303C14.2626 13.3232 14.7374 13.3232 15.0303 13.0303L17.5303 10.5303C17.671 10.3897 17.75 10.1989 17.75 10C17.75 9.80109 17.671 9.61032 17.5303 9.46967L15.0303 6.96967Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
