import React from 'react';

import BaseIcon, { Props } from './Icon';

const TaskTagIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <g clipPath="url(#clip0_2063_18819)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9993 5.37959C16.9993 3.86081 15.7681 2.62959 14.2493 2.62959H9.29954C8.5702 2.62959 7.87072 2.91932 7.355 3.43504L2.28393 8.50611C1.20999 9.58005 1.20999 11.3213 2.28393 12.3952L7.23368 17.3449C8.30762 18.4189 10.0488 18.4189 11.1228 17.3449L16.1938 12.2739C16.7096 11.7582 16.9993 11.0587 16.9993 10.3293L16.9993 5.37959ZM14.2493 4.12959C14.9396 4.12959 15.4993 4.68923 15.4993 5.37959L15.4993 10.3293C15.4993 10.6609 15.3676 10.9788 15.1332 11.2132L10.0621 16.2843C9.57395 16.7724 8.78249 16.7724 8.29434 16.2843L3.34459 11.3345C2.85643 10.8464 2.85643 10.0549 3.34459 9.56677L8.41566 4.4957C8.65008 4.26128 8.96802 4.12959 9.29954 4.12959H14.2493ZM12.3602 7.26867C12.7507 7.65919 13.3839 7.65919 13.7744 7.26867C14.1649 6.87815 14.1649 6.24498 13.7744 5.85446C13.3839 5.46393 12.7507 5.46393 12.3602 5.85446C11.9697 6.24498 11.9697 6.87815 12.3602 7.26867Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2063_18819">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default TaskTagIcon;
