/* eslint-disable max-lines */
import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Dialog, TimezoneSelect } from '@modernloop/shared/components';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { CircleWithCheckIcon, CrossIcon } from '@modernloop/shared/icons';
import {
  getTimeRangesInTimezone,
  getUniqueTimeRanges,
  mergeTimeRanges,
  sortTimeRanges,
} from '@modernloop/shared/utils';
import { Box, Button, CircularProgress, Alert as Mui5Alert, Stack, Tooltip, Typography } from '@mui/material';
import { max } from 'lodash';

import { CandidateDetailsDocument, DateTimeRangeInput } from 'src/generated/mloop-graphql';

import ImportIllustration from 'src/assets/images/import_availability_300_150.png';

import Alert from 'src/components/Alert';
import { Error } from 'src/components/HelperComponents';

import { OrgPrefName, useOrgPrefJson } from 'src/hooks/api/org-grpc';
import { useImportCandidateAvailabilities } from 'src/hooks/scheduling/useImportCandidateAvailabilities';
import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import { useApolloRefetchQuery } from 'src/hooks/useApolloQuery';
import useCompanyHolidays from 'src/hooks/useCompanyHolidays';
import useEmployeeId from 'src/hooks/useEmployeeId';
import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import { CandidateAvailability } from 'src/store/slices/candidate-availability';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { DEFAULT_WORK_HOURS, TimeBlockWeek, isTimeBlockWeekNotEmpty } from 'src/types/preferenes';

import updateCandidateAvailability from 'src/utils/api/candidate/updateCandidateAvailability';
import { apolloCacheDeleteByQuery } from 'src/utils/apolloHelpers';
import { AxiosError } from 'src/utils/axios';
import { startOfNextDay } from 'src/utils/dateUtils';
import { TimeRange, getTimePeriods } from 'src/utils/getTimePeriods';

import { AvailabilityRow } from 'src/views-new/ScheduleFlow/Steps/CandidateAvailability/AvailabilityRow';

import {
  getErrorText,
  getWarningText,
  hasWarningOrError,
} from 'src/views/booking/BookingCreateView/CandidateAvailabilityStep/candidateAvailabilityUtils';

type Props = {
  applicationId: string;
  jobStageId: string;
  taskId?: string;
  candidateTimezone?: string;
  taskAvailability?: CandidateAvailability;
  onClose: () => void;
};

const EditCandidateAvailabilityModal = ({
  applicationId,
  jobStageId,
  taskId,
  candidateTimezone,
  taskAvailability,
  onClose,
}: Props): JSX.Element => {
  const localTimezone = getLocalTimezone();
  const employeeId = useEmployeeId();
  const apolloRefetchQuery = useApolloRefetchQuery();
  const client = useApolloClient();
  const activityFeedRefetch = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();
  const [importSuccessLabel, setImportSuccessLabel] = useState<string>('');
  const [importWarningLabel, setImportWarningLabel] = useState<string>('');
  const clearWarnings = () => {
    setImportSuccessLabel('');
    setImportWarningLabel('');
  };

  const [importComplete, setImportComplete] = useState<boolean>(false);
  const { logEvent } = useLogEvent();

  const [updatingAvailability, setUpdatingAvailability] = useState<boolean>(false);
  const [updateAvailabilityError, setUpdateAvailabilityError] = useState<AxiosError>();

  const isUpdating = (): boolean => {
    return updatingAvailability;
  };

  const holidays = useCompanyHolidays();

  const [orgWorkHours] = useOrgPrefJson<TimeBlockWeek>(OrgPrefName.OBJ_DEFAULT_WORK_HOURS);
  const candidateAvailability = taskAvailability;

  const [rcEnteredAvailabilities, setRCEnteredAvailabilities] = useState<DateTimeRangeInput[]>(
    candidateAvailability?.rcEnteredAvailability
      ? getTimeRangesInTimezone(
          sortTimeRanges([
            ...candidateAvailability.rcEnteredAvailability.map((availability) => ({
              startAt: availability.start,
              endAt: availability.end,
            })),
          ]),
          candidateTimezone || localTimezone
        )
      : []
  );
  const [candidateEnteredAvailabilities, setCandidateEnteredAvailabilities] = useState<DateTimeRangeInput[]>(
    candidateAvailability?.candidateEnteredAvailability
      ? getTimeRangesInTimezone(
          sortTimeRanges([
            ...candidateAvailability.candidateEnteredAvailability.map((availability) => ({
              startAt: availability.start,
              endAt: availability.end,
            })),
          ]),
          candidateTimezone || localTimezone
        )
      : []
  );

  const { importAvailabilities, importingAvailability } = useImportCandidateAvailabilities({
    applicationId,
    candidateTimezone: candidateTimezone ?? localTimezone,
    taskId,
    setImportWarningLabel,
    setImportSuccessLabel,
    // eslint-disable-next-line max-params
    onCompleted: (candidateEnteredImports, rcEnteredImports, totalMinutes) => {
      setRCEnteredAvailabilities(
        getUniqueTimeRanges(
          sortTimeRanges(mergeTimeRanges([...rcEnteredAvailabilities, ...rcEnteredImports, ...candidateEnteredImports]))
        )
      );
      logEvent(LoggerEvent.CLIENT_CANDIDATE_AVAILABILITY_IMPORTED, { importedMinutes: totalMinutes });
      setImportComplete(true);
    },
  });

  const [timezone, setTimezone] = useState(candidateTimezone || localTimezone);

  const handleTimezoneChange = (tz: string) => {
    setTimezone(tz);
    setCandidateEnteredAvailabilities(getTimeRangesInTimezone(candidateEnteredAvailabilities, tz));
    setRCEnteredAvailabilities(getTimeRangesInTimezone(rcEnteredAvailabilities, tz));
  };

  const handleAddRCEnteredAvailability = () => {
    const endTime = startOfNextDay(
      max(
        [...rcEnteredAvailabilities, ...candidateEnteredAvailabilities].map((value) => new Date(value.endAt).getTime())
      ) ?? Date.now(),
      timezone
    );

    // If no work hours are set, default to 9am-5pm so we don't block adding availabilities
    const computedWorkHours = isTimeBlockWeekNotEmpty(orgWorkHours) ? orgWorkHours : DEFAULT_WORK_HOURS;
    const timePeriods = getTimePeriods(endTime.toISOString(), computedWorkHours, timezone);
    if (timePeriods.length === 0) return;
    setRCEnteredAvailabilities([
      ...rcEnteredAvailabilities,
      { startAt: timePeriods[0].start, endAt: timePeriods[0].end },
    ]);
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  const handleUpdateRCEnteredAvailability = (start: string, end: string, index: number) => {
    const newAvailabilities = [...rcEnteredAvailabilities];
    if (index >= newAvailabilities.length) return;

    newAvailabilities[index] = { startAt: start, endAt: end };
    setRCEnteredAvailabilities(newAvailabilities);
  };

  const handleDeleteRCEnteredAvailability = (index: number) => {
    setRCEnteredAvailabilities(rcEnteredAvailabilities.filter((_, i) => index !== i));
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  const handleUpdateCandidateEnteredAvailability = (start: string, end: string, index: number) => {
    const newAvailabilities = [...candidateEnteredAvailabilities];
    if (index >= newAvailabilities.length) return;

    newAvailabilities[index] = { startAt: start, endAt: end };
    setCandidateEnteredAvailabilities(newAvailabilities);
  };

  const handleDeleteCandidateEnteredAvailability = (index: number) => {
    setCandidateEnteredAvailabilities(candidateEnteredAvailabilities.filter((_, i) => index !== i));
  };

  const handleSave = async () => {
    if (!employeeId) return false;

    const mergedAvailability = mergeTimeRanges([...candidateEnteredAvailabilities, ...rcEnteredAvailabilities]).map(
      (availability) => ({
        start: availability.startAt,
        end: availability.endAt,
      })
    );

    setUpdatingAvailability(true);
    return updateCandidateAvailability({
      applicationId,
      jobStageId,
      candidateAvailability: mergedAvailability,
      candidateTimezone: timezone,
      taskId,
    })
      .then(async () => {
        refetchScheduleTaskQuery();
        activityFeedRefetch();
        await apolloRefetchQuery([CandidateDetailsDocument]);
        apolloCacheDeleteByQuery(client.cache, 'candidateAvailability', {
          input: {
            applicationId,
            jobStageId,
          },
        });
        onClose();
        return true;
      })
      .catch((err: AxiosError) => {
        setUpdateAvailabilityError(err);
        return false;
      })
      .finally(() => {
        setUpdatingAvailability(false);
      });
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  const renderAvailability = (availability: TimeRange, index: number, isCandidateEnteredAvailability: boolean) => {
    const warningOrError = hasWarningOrError(
      { id: '', day: availability.start, startAt: availability.start, endAt: availability.end },
      timezone,
      holidays
    );

    const errorText = warningOrError
      ? getErrorText({
          id: '',
          day: availability.start,
          startAt: availability.start,
          endAt: availability.end,
        })
      : '';

    const warningText = warningOrError
      ? getWarningText(
          { id: '', day: availability.start, startAt: availability.start, endAt: availability.end },
          timezone,
          holidays
        )
      : '';

    const deleteHandler = isCandidateEnteredAvailability
      ? () => handleDeleteCandidateEnteredAvailability(index)
      : () => handleDeleteRCEnteredAvailability(index);

    if (!warningOrError) {
      return (
        <Box key={`${availability.start}-${availability.end}-${index}`}>
          <AvailabilityRow
            warningText={warningText}
            errorText={errorText}
            availability={{ startAt: availability.start, endAt: availability.end }}
            timezone={timezone}
            onChange={(start, end) =>
              isCandidateEnteredAvailability
                ? handleUpdateCandidateEnteredAvailability(start, end, index)
                : handleUpdateRCEnteredAvailability(start, end, index)
            }
            onDelete={deleteHandler}
          />
        </Box>
      );
    }

    return (
      <Box key={`${availability.start}-${availability.end}-${index}`}>
        <Alert
          disableIcon
          status={errorText ? 'error' : 'warning'}
          title={
            <AvailabilityRow
              availability={{ startAt: availability.start, endAt: availability.end }}
              timezone={timezone}
              onChange={(start, end) =>
                isCandidateEnteredAvailability
                  ? handleUpdateCandidateEnteredAvailability(start, end, index)
                  : handleUpdateRCEnteredAvailability(start, end, index)
              }
              onDelete={deleteHandler}
            />
          }
          sx={{
            marginLeft: '-12px',
            marginRight: '-12px',
          }}
          caption={
            <Box pt={1}>
              <Typography variant="body2">{errorText || warningText}</Typography>
            </Box>
          }
        />
      </Box>
    );
  };

  const importButtonDecoration = () => {
    if (importingAvailability) {
      return <CircularProgress size={20} />;
    }
    if (importComplete) {
      return <CircleWithCheckIcon color="success" />;
    }
    return null;
  };

  const renderError = (): JSX.Element => {
    return (
      <>
        {updateAvailabilityError && (
          <Box sx={{ padding: (theme) => `${theme.spacing(1.5)} ${theme.spacing(2.5)}` }}>
            <Error error={updateAvailabilityError} />
          </Box>
        )}
      </>
    );
  };

  return (
    <ConditionalThemeProvider>
      <Dialog
        open
        onClose={onClose}
        title="Add availability"
        submitOptions={{
          label: 'Save',
          onClick: handleSave,
          isDisabled: isUpdating() || importingAvailability,
          isLoading: isUpdating(),
        }}
        cancelOptions={{ label: 'Cancel', onClick: onClose }}
      >
        <Stack direction="column" spacing={2.5}>
          {renderError()}

          <Box>
            <Typography variant="subtitle1">Candidate timezone</Typography>
            <TimezoneSelect fullWidth value={timezone} onTimezoneChange={handleTimezoneChange} disableClearable />
          </Box>

          <Box>
            <Stack spacing={1.5}>
              <Box>
                <Box component="span">
                  <Typography component="span" variant="subtitle1">
                    Current availability
                  </Typography>
                  <Typography component="span" color="text.secondary">
                    {' '}
                    (in candidate timezone)
                  </Typography>
                </Box>
                {candidateAvailability?.lastUpdatedByEmployee?.fullName && (
                  <Typography color="text.secondary" variant="body2">
                    Last updated by {candidateAvailability.lastUpdatedByEmployee.fullName}
                  </Typography>
                )}
                {importSuccessLabel && (
                  <Mui5Alert
                    severity="success"
                    action={
                      <CrossIcon
                        onClick={() => {
                          clearWarnings();
                        }}
                        sx={{ cursor: 'pointer' }}
                      />
                    }
                  >
                    <Stack direction="row" alignItems="center" display="flex" flex="1">
                      <Box flex="1" justifyContent="center">
                        <Typography variant="body2">{importSuccessLabel}</Typography>
                      </Box>
                    </Stack>
                  </Mui5Alert>
                )}
                {importWarningLabel && (
                  <Mui5Alert
                    severity="warning"
                    action={
                      <CrossIcon
                        onClick={() => {
                          clearWarnings();
                        }}
                        sx={{ cursor: 'pointer' }}
                      />
                    }
                  >
                    <Stack direction="row" alignItems="center" display="flex" flex="1">
                      <Box flex="1" justifyContent="center">
                        <Typography variant="body2">{importWarningLabel}</Typography>
                      </Box>
                    </Stack>
                  </Mui5Alert>
                )}
              </Box>

              {candidateEnteredAvailabilities &&
                candidateEnteredAvailabilities.length > 0 &&
                candidateEnteredAvailabilities?.map((availability, index) => {
                  return renderAvailability({ start: availability.startAt, end: availability.endAt }, index, true);
                })}

              {rcEnteredAvailabilities &&
                rcEnteredAvailabilities.length > 0 &&
                rcEnteredAvailabilities?.map((availability, index) => {
                  return renderAvailability({ start: availability.startAt, end: availability.endAt }, index, false);
                })}

              <Stack direction="row" gap={2}>
                <Button
                  disabled={importingAvailability || !candidateTimezone}
                  sx={{
                    alignSelf: 'start',
                  }}
                  fullWidth={false}
                  size="small"
                  onClick={handleAddRCEnteredAvailability}
                >
                  Add new
                </Button>
                <Tooltip
                  sx={{ display: 'flex' }}
                  title={
                    <Stack justifyContent="center" alignContent="center" textAlign="center">
                      <img src={ImportIllustration} alt="Import illustration" />
                      <Typography>Import upcoming availability from all other tasks on this application</Typography>
                    </Stack>
                  }
                >
                  <Button
                    startIcon={importButtonDecoration()}
                    disabled={importingAvailability || !candidateTimezone}
                    sx={{
                      alignSelf: 'start',
                    }}
                    fullWidth={false}
                    size="small"
                    onClick={() => {
                      clearWarnings();
                      importAvailabilities();
                    }}
                  >
                    Import availability
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Dialog>
    </ConditionalThemeProvider>
  );
};

export default EditCandidateAvailabilityModal;
