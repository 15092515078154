import React from 'react';

import BaseIcon, { Props } from './Icon';

const MetricsIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 4.75C14.25 4.19772 14.6977 3.75 15.25 3.75C15.8023 3.75 16.25 4.19772 16.25 4.75C16.25 5.30228 15.8023 5.75 15.25 5.75C14.6977 5.75 14.25 5.30228 14.25 4.75ZM15.25 2.25C13.8693 2.25 12.75 3.36929 12.75 4.75C12.75 5.3803 12.9833 5.95612 13.3682 6.39585L11.5107 9.36783C11.2708 9.2913 11.0152 9.25 10.75 9.25C9.81594 9.25 9.00152 9.76226 8.57235 10.5212L7.23884 9.98776C7.24622 9.9095 7.25 9.83019 7.25 9.75C7.25 8.36929 6.13071 7.25 4.75 7.25C3.36929 7.25 2.25 8.36929 2.25 9.75C2.25 11.1307 3.36929 12.25 4.75 12.25C5.51289 12.25 6.19596 11.9083 6.65452 11.3696L8.2637 12.0133C8.39524 13.2702 9.4582 14.25 10.75 14.25C12.1307 14.25 13.25 13.1307 13.25 11.75C13.25 11.18 13.0593 10.6546 12.7381 10.2341L14.6487 7.17721C14.8413 7.22477 15.0427 7.25 15.25 7.25C16.6307 7.25 17.75 6.13071 17.75 4.75C17.75 3.36929 16.6307 2.25 15.25 2.25ZM3.75 9.75C3.75 9.19772 4.19772 8.75 4.75 8.75C5.30228 8.75 5.75 9.19772 5.75 9.75C5.75 10.3023 5.30228 10.75 4.75 10.75C4.19772 10.75 3.75 10.3023 3.75 9.75ZM3 16.25C2.58579 16.25 2.25 16.5858 2.25 17C2.25 17.4142 2.58579 17.75 3 17.75H17C17.4142 17.75 17.75 17.4142 17.75 17C17.75 16.5858 17.4142 16.25 17 16.25H3ZM10.75 10.75C10.1977 10.75 9.75 11.1977 9.75 11.75C9.75 12.3023 10.1977 12.75 10.75 12.75C11.3023 12.75 11.75 12.3023 11.75 11.75C11.75 11.1977 11.3023 10.75 10.75 10.75Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MetricsIcon;
