import React, { FC } from 'react';

import { Stack } from '@mui/material';

import ZeroState from 'src/components/ZeroState';
import { SettingsIcon } from 'src/components/icons';

import { useFeatureFlagString } from 'src/hooks/feature-flag';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface MaintenancePageProps {
  children: JSX.Element;
}

/**
 * This component is used to show a maintenance page when the user_show_maintenance_screen feature flag is enabled.
 * It blocks all child content and only shows the maintenance page.
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const MaintenancePage: FC<MaintenancePageProps> = ({ children }) => {
  const showMaintenancePage = useFeatureFlagString('user_show_maintenance_screen');

  if (showMaintenancePage) {
    return (
      <Stack height="100%" justifyContent="center" alignItems="center" alignContent="center" justifyItems="center">
        <ZeroState icon={<SettingsIcon fontSize={52} />} label={showMaintenancePage} />
      </Stack>
    );
  }

  return children;
};

export default MaintenancePage;
