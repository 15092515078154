import React, { FC } from 'react';

import useTheme from 'src/theme/useTheme';

/**
 * Simple component to render bold text in activity log
 */
const ActivityLogBold: FC = ({ children }) => {
  const theme = useTheme();
  return <span style={{ fontWeight: 600, color: theme.palette.text.primary }}>{children}</span>;
};

export default ActivityLogBold;
