import { useFlag } from '@modernloop/shared/feature-flag';

import { useIntegrations } from '../api/integration';

const useWorkdayEnabled = (): boolean => {
  const isWordayEnabled = useFlag('user_workday_integration');
  const { data } = useIntegrations();
  return isWordayEnabled || !!data?.workday;
};

export default useWorkdayEnabled;
