import { SvgIcon, SvgIconProps } from './SvgIcon';

export const RescheduleReasonsIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <g id="RescheduleReasonsIcon">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 1.25C7.41421 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48122 17.75 6V8C17.75 8.41421 17.4142 8.75 17 8.75C16.5858 8.75 16.25 8.41421 16.25 8V6C16.25 5.30964 15.6903 4.75 15 4.75H13H7H5C4.30964 4.75 3.75 5.30965 3.75 6L3.75001 15C3.75001 15.6904 4.30966 16.25 5.00001 16.25H9C9.41421 16.25 9.75 16.5858 9.75 17C9.75 17.4142 9.41421 17.75 9 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H6.25V2C6.25 1.58579 6.58578 1.25 7 1.25ZM11.2943 15.19C11.2459 15.2107 11.1949 15.2264 11.142 15.2366C10.9434 15.2751 10.7415 15.2305 10.5798 15.1213C10.4518 15.0346 10.3521 14.9094 10.2972 14.7623L9.30364 12.2785C9.19098 11.9969 9.25908 11.6751 9.47617 11.4632C9.69327 11.2514 10.0166 11.1911 10.2954 11.3106L11.2759 11.7308C12.0553 10.8242 13.2106 10.25 14.5 10.25C16.8472 10.25 18.75 12.1528 18.75 14.5C18.75 16.8472 16.8472 18.75 14.5 18.75C13.7271 18.75 13.0003 18.543 12.3745 18.181C12.0159 17.9736 11.8934 17.5148 12.1008 17.1562C12.3082 16.7977 12.767 16.6752 13.1255 16.8826C13.5293 17.1161 13.9979 17.25 14.5 17.25C16.0188 17.25 17.25 16.0188 17.25 14.5C17.25 12.9812 16.0188 11.75 14.5 11.75C13.8411 11.75 13.2364 11.9817 12.7628 12.3681L13.7954 12.8106C14.0743 12.9301 14.2536 13.2058 14.2499 13.5091C14.2462 13.8125 14.0602 14.0837 13.7785 14.1964L11.2943 15.19ZM6 14C6.55228 14 7 13.5523 7 13C7 12.4477 6.55228 12 6 12C5.44772 12 5 12.4477 5 13C5 13.5523 5.44772 14 6 14ZM7 9C7 9.55229 6.55228 10 6 10C5.44772 10 5 9.55229 5 9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9ZM9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75H12C12.4142 9.75 12.75 9.41421 12.75 9C12.75 8.58579 12.4142 8.25 12 8.25H9Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};
