import { ThemeOptions, outlinedInputClasses } from '@mui/material';

/**
 * View TextField props and classes at https://mui.com/material-ui/api/text-field/#css
 *
 * Note - TextField is a higher order component that wraps Input components.
 */
export const textfield: ThemeOptions['components'] = {
  MuiTextField: {
    defaultProps: {
      InputLabelProps: {
        shrink: true,
        size: 'small',
        className: 'mousetrap',
      },
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        ...(ownerState.label !== undefined && {
          [`& .${outlinedInputClasses.root}`]: {
            paddingTop: theme.spacing(2.5),
          },
        }),
        '.MuiInputLabel-root': {
          ...theme.typography.subtitle2,
          top: '6px',
          left: '12px',
          maxWidth: `calc(100% - ${theme.spacing(3)})`,

          // Clear out MUI default styles
          transformOrigin: 'none',
          webkitTransform: 'none',
          webkitTransition: 'none',
          transform: 'none',
          transition: 'none',
        },
      }),
    },
  },
};
