import { useMemo } from 'react';

import { SearchParamKnownKeys, SidePanelType, useUrlSearchParams } from 'src/hooks/useUrlSearchParams';

import { SidePanelData } from '../utils/types';

export const useSidePanelUrlData = (): { data: SidePanelData } | undefined => {
  const urlSearchParams = useUrlSearchParams();
  const panelType = urlSearchParams.get(SearchParamKnownKeys.panelType);
  const id = urlSearchParams.get(SearchParamKnownKeys.panelObjectId);

  const interviewModuleId = urlSearchParams.get(SearchParamKnownKeys.interviewModuleId);
  const moduleMemberId = urlSearchParams.get(SearchParamKnownKeys.moduleMemberId);

  return useMemo(() => {
    if (!panelType) return undefined;

    if (panelType === SidePanelType.ScheduleTask && id) {
      return {
        data: {
          type: SidePanelType.ScheduleTask,
          scheduleTaskId: id,
          showRequirements: true,
        },
      };
    }
    if (panelType === SidePanelType.InterviewModuleMember && interviewModuleId && moduleMemberId) {
      return {
        data: {
          type: SidePanelType.InterviewModuleMember,
          interviewModuleId,
          moduleMemberId,
        },
      };
    }

    // add support for more panel types

    return undefined;
  }, [id, interviewModuleId, moduleMemberId, panelType]);
};
