import React, { useCallback, useMemo } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import { useFlag } from '@modernloop/shared/feature-flag';
import { MagicIcon } from '@modernloop/shared/icons';
import { ListSubheader, Stack, Typography } from '@mui/material';

import useOrgId from 'src/hooks/useOrgId';

import { Theme } from 'src/themeMui5/type';

import ZoomUserSelect from './ZoomUserSelect';
import { Categories, ZoomUserDataWithCategory, ZoomUserSelectProps } from './ZoomUserSelect/types';

export enum SmartOptions {
  Interviewer = 'interviewer-smart-option',
  Scheduler = 'scheduler-smart-option',
}

type Props<TData extends ZoomUserDataWithCategory, InputValue extends string | string[] | undefined> = Omit<
  ZoomUserSelectProps<TData, InputValue>,
  | 'isAdditionalOptionId'
  | 'renderAdditionalOption'
  | 'renderAdditionalOptionGroup'
  | 'getAdditionalOptionSize'
  | 'getAdditionalOptionLabel'
>;

const ZoomUserSelectWithSmartOptions = <
  TData extends ZoomUserDataWithCategory,
  InputValue extends string | string[] | undefined
>(
  props: Props<TData, InputValue>
) => {
  const orgId = useOrgId();
  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const smartOptions = useMemo(() => {
    const result: ZoomUserDataWithCategory[] = [];

    if (orgId) {
      result.push({
        id: SmartOptions.Interviewer,
        orgId,
        zoomUserId: SmartOptions.Interviewer,
        email: SmartOptions.Interviewer,
        firstName: 'Interviewers',
        lastName: '',
        category: Categories.AdditionalOptions,
      });

      if (scheduleLocationEnabled) {
        result.push({
          id: SmartOptions.Scheduler,
          orgId,
          zoomUserId: SmartOptions.Scheduler,
          email: SmartOptions.Scheduler,
          firstName: 'Scheduler',
          lastName: '',
          category: Categories.AdditionalOptions,
        });
      }
    }

    return result;
  }, [orgId, scheduleLocationEnabled]);

  const isAdditionalOptionId = useCallback((zoomUserId: string) => {
    return SmartOptions.Interviewer === zoomUserId || SmartOptions.Scheduler === zoomUserId;
  }, []);

  const getAdditionalOptionLabel = useCallback((option: ZoomUserDataWithCategory) => {
    if (option.id === SmartOptions.Interviewer) {
      return 'Interviewers';
    }
    if (option.id === SmartOptions.Scheduler) {
      return 'Scheduler';
    }
    return '';
  }, []);

  const renderAdditionalOption = useCallback(
    (option: ZoomUserDataWithCategory) => {
      let label = option.firstName;
      let subtitle = 'One interviewer from each interview will be assigned as host';

      if (scheduleLocationEnabled) {
        if (option.id === SmartOptions.Interviewer) {
          label = 'Interviewers';
          subtitle =
            'ModernLoop will create a unique interview link for each interview. Each interview link will have one of its interviewers as the host.';
        } else if (option.id === SmartOptions.Scheduler) {
          label = 'Scheduler';
          subtitle = 'Whoever schedules the interview will be assigned as host.';
        }
      }
      return (
        <Stack direction="row" alignItems="center" spacing={1} flexWrap="nowrap" data-testid={option.id}>
          <MagicIcon color="primary" />
          <Stack direction="column" flexWrap="nowrap">
            <Typography>{label}</Typography>
            <Typography variant="caption" color="text.secondary">
              {subtitle}
            </Typography>
          </Stack>
        </Stack>
      );
    },
    [scheduleLocationEnabled]
  );

  const renderAdditionalOptionGroup = useCallback(
    (params: AutocompleteRenderGroupParams) => {
      const group: React.ReactNode[] = [];

      if (params.group) {
        group.push(
          <ListSubheader
            disableSticky
            key={params.key}
            component="div"
            sx={{
              color: (theme: Theme) => theme.palette.primary.main,
              fontWeight: (theme: Theme) => theme.typography.fontWeightMedium,
              padding: (theme: Theme) => `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
            }}
          >
            <Typography variant="subtitle2" color="info">
              {scheduleLocationEnabled ? 'Dynamic options' : 'Smart options'}
            </Typography>
          </ListSubheader>
        );
      }
      group.push(params.children);

      return group;
    },
    [scheduleLocationEnabled]
  );

  const getAdditionalOptionSize = useCallback(() => {
    if (scheduleLocationEnabled) return 80;
    return 66;
  }, [scheduleLocationEnabled]);

  return (
    <ZoomUserSelect
      {...props}
      additionalOptions={smartOptions}
      isAdditionalOptionId={isAdditionalOptionId}
      renderAdditionalOption={renderAdditionalOption}
      renderAdditionalOptionGroup={renderAdditionalOptionGroup}
      getAdditionalOptionSize={getAdditionalOptionSize}
      getAdditionalOptionLabel={getAdditionalOptionLabel}
    />
  );
};

export default ZoomUserSelectWithSmartOptions;
