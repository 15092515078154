import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Collapse, Divider, Grid, LinearProgress } from '@mui/material';

import { CandidateEventCard_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import Editor from 'src/components/Editor';
import Paper from 'src/components/Paper';
import Stack from 'src/components/Stack';
import TextField from 'src/components/TextField';
import Checkbox from 'src/components/checkbox';
import Label from 'src/components/label';

import TemplateType from 'src/constants/TemplateType';

import TemplateSelect from 'src/entities/Template/TemplateSelect';

import { updateCandidateEventCommunicationsEnabled, updateHasSameContent } from 'src/slices/scheduling';

import { getCandidateCommunicationsEnabled } from 'src/store/selectors/scheduling';

import { useDispatch, useSelector } from 'src/store';

import useCandidateCalendarEventTemplateContent from './useCandidateCalendarEventTemplateContent';

type Fragments = {
  interviewPlan: CandidateEventCard_InterviewPlanFragment | undefined;
};

type Props = {
  subject: string | undefined;
  onSubjectChange: (value: string) => void;
  body: string | undefined;
  onBodyChange: (value: string) => void;
  templateID: string | undefined | null;
  onTemplateIDChange: (value) => void;
  old?: JSX.Element;
  update?: boolean;
};

const useSxProps = (loading: boolean) => {
  return useMemo(() => {
    return {
      editor: {
        '& .ql-editor': {
          height: 500,
        },
        opacity: loading ? 0.5 : 1,
      },
    };
  }, [loading]);
};

const CandidateEventCard: FCWithFragments<Fragments, Props> = ({
  interviewPlan,
  subject,
  body,
  templateID,
  onBodyChange,
  onSubjectChange,
  onTemplateIDChange,
  old,
  update = false,
}) => {
  const dispatch = useDispatch();
  const {
    candidateEventCommunicationsEnabled,
    hasSameContent,
    stepScheduleContent: { isWaiting },
  } = useSelector((state) => state.scheduling);
  const candidateCommunicationsEnabled = useSelector(getCandidateCommunicationsEnabled);

  const toggleCandidateEventComms = () => {
    dispatch(updateCandidateEventCommunicationsEnabled(!candidateEventCommunicationsEnabled));
  };

  const loading = useCandidateCalendarEventTemplateContent({ interviewPlan }, { templateId: templateID || undefined });
  const sxProps = useSxProps(loading);

  return (
    <>
      <Grid container spacing={1} direction="column" style={{ padding: '0px 0px 0px 8px' }}>
        <Grid item>
          <>
            <Checkbox
              checked={candidateEventCommunicationsEnabled}
              label={update ? 'Send event update' : 'Calendar invite'}
              onChange={toggleCandidateEventComms}
            />
          </>
        </Grid>
      </Grid>
      <Collapse in={candidateEventCommunicationsEnabled}>
        <Paper color="alternate">
          <Stack spacing={2} direction="column">
            <Label variant="body" fontWeight={600}>
              Calendar invite to candidate
            </Label>
            {candidateCommunicationsEnabled && (
              <>
                <Divider />
                <Checkbox
                  checked={hasSameContent}
                  onChange={() => dispatch(updateHasSameContent(!hasSameContent))}
                  label="Use same text as email"
                />
              </>
            )}
            <Collapse in={!hasSameContent}>
              <Stack spacing={2} direction="column">
                <Label variant="captions" fontWeight={600}>
                  Event details template
                </Label>

                <TemplateSelect
                  useSelectButton
                  minimalUX
                  onSelect={(id) => onTemplateIDChange(id)}
                  selectedTemplateId={templateID || undefined}
                  types={[TemplateType.CandidateInvite]}
                />

                {loading && <LinearProgress sx={{ width: '100%' }} />}

                <TextField
                  disabled={isWaiting || loading}
                  fullWidth
                  variant="outlined"
                  label=""
                  required
                  id="standard-required"
                  value={subject}
                  autoComplete="off"
                  onChange={(event) => onSubjectChange(event.target.value)}
                />
                <Editor readOnly={loading} onChange={onBodyChange} value={body || ''} sx={sxProps.editor} />
                {old}
              </Stack>
            </Collapse>
          </Stack>
        </Paper>
      </Collapse>
    </>
  );
};

CandidateEventCard.fragments = {
  interviewPlan: gql`
    fragment CandidateEventCard_interviewPlan on JobStage {
      id
      ...useCandidateCalendarEventTemplateContent_interviewPlan
    }
  `,
};

export default CandidateEventCard;
