import { useMutation, useQueryClient } from 'react-query';

import { IntegrationType } from 'src/generated/mloop-graphql';

import { putIntegration } from './integrationPut';
import { QUERY_KEY } from './types';

const INTEGRATION_TYPE: IntegrationType = IntegrationType.CodilityApiKey;

// eslint-disable-next-line @typescript-eslint/ban-types
function putCodilityIntegration(apiKey: string): Promise<{}> {
  return putIntegration({
    integration_type: INTEGRATION_TYPE,
    codility: {
      api_key: apiKey,
    },
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useCodilityIntegrationPut() {
  const queryClient = useQueryClient();
  return useMutation((apiKey: string) => putCodilityIntegration(apiKey), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY);
      queryClient.invalidateQueries([QUERY_KEY, INTEGRATION_TYPE]);
    },
  });
}
