import IsoTimestamp from './IsoTimestamp';
import {
  Interval as IntervalDateFns,
  compareAsc as compareAscDateFns,
  isAfter as isAfterDateFns,
  isBefore as isBeforeDateFns,
  isEqual as isEqualDateFns,
  isFuture as isFutureDateFns,
  isPast as isPastDateFns,
  isToday as isTodayDateFns,
  isTomorrow as isTomorrowDateFns,
  isWithinInterval as isWithinIntervalDateFns,
  isWeekend as isWeekendDateFns,
  parseISO,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

interface Interval {
  start: IsoTimestamp;
  end: IsoTimestamp;
}

export const isToday = (date: IsoTimestamp): boolean => {
  return isTodayDateFns(parseISO(date));
};

export const isTomorrow = (date: IsoTimestamp): boolean => {
  return isTomorrowDateFns(parseISO(date));
};

export const isWeekend = (date: IsoTimestamp, timezone: string): boolean => {
  const zonedTime = utcToZonedTime(date, timezone);
  return isWeekendDateFns(zonedTime);
};

export const isEqual = (d1: IsoTimestamp, d2: IsoTimestamp): boolean => {
  return isEqualDateFns(new Date(d1), new Date(d2));
};

export const isWithinInterval = (date: IsoTimestamp, interval: Interval): boolean => {
  const intervalDateFns: IntervalDateFns = {
    start: parseISO(interval.start),
    end: parseISO(interval.end),
  };

  return isWithinIntervalDateFns(new Date(date), intervalDateFns);
};

export const compareAsc = (d1: IsoTimestamp, d2: IsoTimestamp): number => {
  return compareAscDateFns(parseISO(d1), parseISO(d2));
};

export const isAfter = (startTime: IsoTimestamp, endTime: IsoTimestamp): boolean => {
  return isAfterDateFns(parseISO(startTime), parseISO(endTime));
};

export const isBefore = (d1: IsoTimestamp, d2: IsoTimestamp): boolean => {
  return isBeforeDateFns(new Date(d1), new Date(d2));
};

export const isPast = (timestamp: IsoTimestamp): boolean => {
  return isPastDateFns(parseISO(timestamp));
};

export const isFuture = (timestamp: IsoTimestamp): boolean => {
  return isFutureDateFns(parseISO(timestamp));
};

export const isAfterNow = (date: IsoTimestamp): boolean => {
  return isAfterDateFns(new Date(date), Date.now());
};

export const isBeforeNow = (date: IsoTimestamp): boolean => {
  return isBeforeDateFns(new Date(date), Date.now());
};

export const isEventOngoing = (startDate: IsoTimestamp, endDate: IsoTimestamp) => {
  return isBeforeNow(startDate) && isAfterNow(endDate);
};
