/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { Stack, Tooltip, Typography } from '@mui/material';
import { differenceInMinutes, parseISO } from 'date-fns';

import {
  BaseCandidateAvailabilitySection_ApplicationFragment,
  BaseCandidateAvailabilitySection_AvailabilityRequestFragment,
  CandidateAvailabilityOptionsSettings_JobStageSettingsFragment,
  Maybe,
  useEmployeeByIdsLazyQuery,
} from 'src/generated/mloop-graphql';

import AccordionCard from 'src/components/AccordionCard';
import DurationLabel from 'src/components/DurationLabel';
import useDurationLabel from 'src/components/DurationLabel/useDurationLabel';
import Link from 'src/components/Link';
import Paper from 'src/components/Paper';
import Button from 'src/components/button';
import { PlusIcon, SendIcon } from 'src/components/icons';
import Label from 'src/components/label';

import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';

import { useOrgAtsService } from 'src/hooks/atsService';
import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useIsApplicationRejected from 'src/hooks/useIsApplicationRejected';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import { CandidateAvailability as StoreCandidateAvailability } from 'src/store/slices/candidate-availability';
import { CandidateAvailabilityOptions } from 'src/store/slices/candidate-availability-options';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';
import { Theme as ThemeV5 } from 'src/themeMui5/type';

import IsoTimestamp, { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { getUniqueDays } from 'src/utils/datetime/Conversions';
import { formatDurationFromMinutes } from 'src/utils/datetime/Format';

import { CandidateAvailabilityOptionsModalPage } from 'src/views-new/CandidateAvailabilityOptions/CandidateAvailabilityOptionsModal';
import CandidateAvailabilityOptionsModal from 'src/views-new/CandidateAvailabilityOptions/CandidateAvailabilityOptionsModalWrapper';

import CandidateAvailability from './CandidateAvailability';
import CandidateAvailabilityStatus from './CandidateAvailabilityStatus';
import EditCandidateAvailabilityModal from './EditCandidateAvailabilityModal';
import ResetAvailabilityModal from './ResetAvailabilityModal';

type Props = {
  applicationId: string;
  candidateId: string;
  jobStageId: string;
  jobId: string;
  interviewPlanId?: string;
  taskId?: string;
  candidateTimezone?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  coordinatorId?: string;
  lastApplicationStageCreatedAt?: IsoTimestamp;
  isCurrentAts: boolean;
  showStatusOnly?: boolean;
  readonly?: boolean;
  jobStageName: string;
  selfScheduleRequestId?: string;
  availabilities?: StoreCandidateAvailability;
  options?: CandidateAvailabilityOptions;
  availabilityRequestedAfterSchedule: boolean;
  availabilityReceivedAfterSchedule: boolean;
  settings?: CandidateAvailabilityOptionsSettings_JobStageSettingsFragment | null;
};

type Fragments = {
  application: BaseCandidateAvailabilitySection_ApplicationFragment;
  availabilityRequest?: Maybe<BaseCandidateAvailabilitySection_AvailabilityRequestFragment>;
};

const BaseCandidateAvailabilitySection: FCWithFragments<Fragments, Props> = ({
  applicationId,
  candidateId,
  jobStageId,
  jobId,
  interviewPlanId,
  candidateTimezone,
  taskId,
  jobStageName,
  selfScheduleRequestId,
  lastApplicationStageCreatedAt,
  isCurrentAts,
  showStatusOnly,
  readonly,
  availabilities,
  options,
  availabilityRequestedAfterSchedule,
  availabilityReceivedAfterSchedule,
  settings,
  application,
  availabilityRequest,
}) => {
  const localTimezone = getLocalTimezone();
  const [showAvailabilityOptions, setShowAvailabilityOptions] = useState(false);
  const [showEditAvailabilityModal, setShowEditAvailabilityModal] = useState(false);
  const [showResetAvailabilityModal, setShowResetAvailabilityModal] = useState(false);
  const [candidateAvailabilityOptionsModalPage, setCandidateAvailabilityOptionsModalPage] =
    useState<CandidateAvailabilityOptionsModalPage>();
  const [disableEditOptions, setDisableEditOptions] = useState(false);
  const showApplicationRejected = useIsApplicationRejected(application.status);

  const atsService = useOrgAtsService();
  const activityFeedRefetch = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();

  const isInterviewScheduledAfterRequestCreated =
    lastApplicationStageCreatedAt &&
    options?.createdAt &&
    parseISO(lastApplicationStageCreatedAt).getTime() > parseISO(options.createdAt).getTime();

  const toggleShowAvailabilityOptions = useCallback(
    () => setShowAvailabilityOptions(!showAvailabilityOptions),
    [showAvailabilityOptions]
  );

  const toggleShowEditAvailabilityModal = useCallback(
    () => setShowEditAvailabilityModal(!showEditAvailabilityModal),
    [showEditAvailabilityModal]
  );

  const toggleShowResetAvailabilityModal = useCallback(
    () => setShowResetAvailabilityModal(!showResetAvailabilityModal),
    [showResetAvailabilityModal]
  );

  const [fetchEmployeeById, { data }] = useEmployeeByIdsLazyQuery();

  const durationLabel = useDurationLabel(
    availabilities?.updatedAt ? assertIsoTimestamp(availabilities.updatedAt) : undefined
  );

  useEffect(() => {
    if (!availabilities?.lastUpdatedByEmployeeId) return;
    fetchEmployeeById({ variables: { input: [availabilities.lastUpdatedByEmployeeId] } });
  }, [availabilities, fetchEmployeeById]);

  const availabilityPresent = Boolean(
    availabilities?.candidateEnteredAvailability.length || availabilities?.rcEnteredAvailability.length
  );

  const handleCreateAvailabilityRequest = useCallback(async () => {
    if (taskId && availabilityPresent) {
      toggleShowResetAvailabilityModal();
    } else {
      await refetchScheduleTaskQuery();
      toggleShowAvailabilityOptions();
    }
  }, [
    availabilityPresent,
    taskId,
    toggleShowAvailabilityOptions,
    toggleShowResetAvailabilityModal,
    refetchScheduleTaskQuery,
  ]);

  const refreshScheduleTaskQuery = useCallback(() => {
    if (!taskId) return;
    refetchScheduleTaskQuery();
    activityFeedRefetch();
  }, [activityFeedRefetch, refetchScheduleTaskQuery, taskId]);

  const modalsJsx = (
    <>
      {showEditAvailabilityModal && (
        <EditCandidateAvailabilityModal
          applicationId={applicationId}
          jobStageId={jobStageId}
          taskId={taskId}
          candidateTimezone={candidateTimezone || options?.timezone}
          taskAvailability={availabilities}
          onClose={() => {
            toggleShowEditAvailabilityModal();
            setCandidateAvailabilityOptionsModalPage(CandidateAvailabilityOptionsModalPage.BASIC_CONFIGURATION);
            refreshScheduleTaskQuery();
          }}
        />
      )}
      {showAvailabilityOptions && (
        <CandidateAvailabilityOptionsModal
          isSelfScheduleRequested={Boolean(selfScheduleRequestId)}
          applicationId={applicationId}
          candidateId={candidateId}
          jobStageId={jobStageId}
          jobId={jobId}
          interviewPlanId={interviewPlanId}
          taskId={taskId}
          taskOptions={taskId ? options : undefined}
          timezone={localTimezone}
          initialPage={candidateAvailabilityOptionsModalPage}
          disableEditOptions={disableEditOptions}
          onSaveSuccess={refreshScheduleTaskQuery}
          onClose={() => {
            toggleShowAvailabilityOptions();
            setCandidateAvailabilityOptionsModalPage(undefined);
            setDisableEditOptions(false);
          }}
          onDelete={refreshScheduleTaskQuery}
          settings={settings}
        />
      )}
      {showResetAvailabilityModal && (
        <ResetAvailabilityModal
          applicationId={applicationId}
          jobStageId={jobStageId}
          taskId={taskId}
          candidateTimezone={candidateTimezone || options?.timezone || getLocalTimezone()}
          onClose={toggleShowResetAvailabilityModal}
          onConfirm={() => {
            toggleShowResetAvailabilityModal();
            toggleShowAvailabilityOptions();
          }}
        />
      )}
    </>
  );

  let subTitle = 'No availability';
  if (options?.externalId) {
    if (!availabilityPresent) {
      subTitle = 'No availability, but a request is open';
    }
  }

  if (availabilityPresent) {
    const combinedAvailabilities = [
      ...(availabilities?.candidateEnteredAvailability || []),
      ...(availabilities?.rcEnteredAvailability || []),
    ];
    const totalDurationInMinutes = combinedAvailabilities.reduce((prevDuration, availability) => {
      return prevDuration + differenceInMinutes(parseISO(availability.end), parseISO(availability.start));
    }, 0);
    const totalDays = getUniqueDays(
      combinedAvailabilities.map((a) => assertIsoTimestamp(a.start)),
      availabilities?.candidateTimezone || getLocalTimezone()
    );

    let addedBy = '';
    if (availabilities?.candidateEnteredAvailability.length) addedBy = 'by candidate';
    else if (data?.employeeByIds?.length) addedBy = `by ${data?.employeeByIds[0].fullName}`;

    const requestIsOpen =
      !availabilities?.candidateEnteredAvailability.length &&
      availabilities?.lastUpdatedByEmployeeId &&
      options?.externalId
        ? ', but a request is open'
        : '';

    subTitle = `${formatDurationFromMinutes(totalDurationInMinutes)} over ${totalDays.length} ${
      totalDays.length === 1 ? 'day' : 'days'
    } added ${addedBy} ${durationLabel}${requestIsOpen}`;
  }

  const getAvailabilityRequestActions = () => {
    const tooltipTitle = showApplicationRejected ? 'Application rejected' : '';
    return taskId && !selfScheduleRequestId && options?.externalId ? (
      <Stack alignItems="center" direction="row" sx={{ flexWrap: 'wrap', gap: 1, paddingTop: 1 }}>
        <Tooltip title={tooltipTitle}>
          <span>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={showApplicationRejected}
              label="Re-share request link"
              onClick={() => {
                setCandidateAvailabilityOptionsModalPage(CandidateAvailabilityOptionsModalPage.SAVE_SUCCESS);
                setShowAvailabilityOptions(true);
              }}
            />
          </span>
        </Tooltip>
        <Tooltip title={tooltipTitle}>
          <span>
            <Button
              variant="outlined"
              label="Edit request"
              size="small"
              disabled={showApplicationRejected}
              onClick={() => {
                setCandidateAvailabilityOptionsModalPage(CandidateAvailabilityOptionsModalPage.BASIC_CONFIGURATION);
                setShowAvailabilityOptions(true);
              }}
            />
          </span>
        </Tooltip>
        <Button
          variant="outlined"
          label="Cancel request"
          size="small"
          onClick={() => {
            setCandidateAvailabilityOptionsModalPage(
              CandidateAvailabilityOptionsModalPage.DELETE_AVAILABILITY_CONFIRMATION
            );
            setShowAvailabilityOptions(true);
          }}
        />
      </Stack>
    ) : null;
  };

  // In task requirement section we only want to show CandidateAvailabilityStatus when there is a request open
  const statusJsx = isCurrentAts && !readonly && (
    <CandidateAvailabilityStatus
      taskId={taskId}
      application={application}
      jobStageName={jobStageName}
      options={options}
      availabilityRequest={availabilityRequest}
      availabilities={availabilities}
      selfScheduleOptionsId={selfScheduleRequestId}
      lastApplicationStageCreatedAt={lastApplicationStageCreatedAt}
      onRequestAvailability={() => {
        setCandidateAvailabilityOptionsModalPage(CandidateAvailabilityOptionsModalPage.BASIC_CONFIGURATION);
        setDisableEditOptions(false);
        toggleShowAvailabilityOptions();
      }}
      onReShareAvailabilityRequest={() => {
        setCandidateAvailabilityOptionsModalPage(CandidateAvailabilityOptionsModalPage.SAVE_SUCCESS);
        setDisableEditOptions(true);
        toggleShowAvailabilityOptions();
      }}
      onDeleteAvailabilityRequest={() => {
        setCandidateAvailabilityOptionsModalPage(
          CandidateAvailabilityOptionsModalPage.DELETE_AVAILABILITY_CONFIRMATION
        );
        toggleShowAvailabilityOptions();
      }}
      availabilityRequestActionsJSX={getAvailabilityRequestActions()}
    />
  );

  const hasCandidateSubmittedAvailabilityAfterRequestCreated =
    availabilities?.candidateUpdatedAt &&
    options?.createdAt &&
    parseISO(availabilities.candidateUpdatedAt).getTime() > parseISO(options.createdAt).getTime();

  const availabilityPresentWithNoOptionsForTask = taskId && availabilityPresent && !options?.externalId;

  if (showStatusOnly) {
    if (isInterviewScheduledAfterRequestCreated || hasCandidateSubmittedAvailabilityAfterRequestCreated) {
      return <></>;
    }
    // Don't render status if there is no candidate avaialbility request by candidate
    if (
      !options?.externalId ||
      (availabilities?.candidateEnteredAvailability.length && hasCandidateSubmittedAvailabilityAfterRequestCreated)
    ) {
      return <></>;
    }

    return (
      <>
        {statusJsx}
        {modalsJsx}
      </>
    );
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-lines
  const jsx = (
    <Stack spacing={1}>
      {availabilityPresent && (
        <CandidateAvailability
          candidateTimezone={candidateTimezone}
          availabilities={availabilities}
          options={options}
          readonly={readonly}
          showEditIcon={!taskId}
          onAddAvailability={toggleShowEditAvailabilityModal}
        />
      )}

      {(!options?.externalId ||
        isInterviewScheduledAfterRequestCreated ||
        hasCandidateSubmittedAvailabilityAfterRequestCreated ||
        taskId) &&
        statusJsx}

      {taskId && selfScheduleRequestId && (
        <Label variant="captions" color="high-contrast-grey">
          To request availability, please cancel the self scheduled request.
        </Label>
      )}

      {taskId && availabilityRequestedAfterSchedule && !availabilityReceivedAfterSchedule && options && (
        <Paper
          color="pending"
          disableBorder
          sx={{
            borderRadius: (theme: ThemeV5) => theme.spacing(1.5),
            padding: (theme: ThemeV5) =>
              `${theme.spacing(2.5)} ${theme.spacing(1.5)} ${theme.spacing(2.5)} ${theme.spacing(2)}`,
          }}
        >
          <ConditionalThemeProvider>
            <Stack alignItems="left" direction="row" justifyContent="left" spacing={1}>
              <SendIcon color="pending" />
              {options?.lastCandidateCommunicationSentAt === null ? (
                <Stack alignItems="left" justifyContent="space-between">
                  <Typography variant="subtitle1">
                    Waiting to send the candidate a request to submit availability…
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    A request was created by {options?.creator}{' '}
                    <DurationLabel
                      timestamp={assertIsoTimestamp(options?.updatedAt as string)}
                      labelProps={{
                        variant: 'captions',
                        fontWeight: 400,
                        color: 'high-contrast-grey',
                        noWrap: true,
                        component: 'span',
                      }}
                    />
                    .
                  </Typography>
                  {getAvailabilityRequestActions()}
                </Stack>
              ) : (
                <Stack alignItems="left" justifyContent="space-between">
                  <Typography variant="subtitle1">Waiting for the candidate to submit availability…</Typography>
                  {options?.lastCandidateCommunicationSentAt && (
                    <Typography variant="body2" color="text.secondary">
                      A link was sent to the candidate by {options?.creator}{' '}
                      <DurationLabel
                        timestamp={assertIsoTimestamp(options.lastCandidateCommunicationSentAt)}
                        labelProps={{
                          variant: 'captions',
                          fontWeight: 400,
                          color: 'high-contrast-grey',
                          noWrap: true,
                          component: 'span',
                        }}
                      />
                      .
                    </Typography>
                  )}
                  {getAvailabilityRequestActions()}
                </Stack>
              )}
            </Stack>
          </ConditionalThemeProvider>
        </Paper>
      )}
      {taskId && availabilityReceivedAfterSchedule && options && (
        <Paper
          color="pending"
          disableBorder
          sx={{
            borderRadius: (theme: ThemeV5) => theme.spacing(1.5),
            padding: (theme: ThemeV5) =>
              `${theme.spacing(2.5)} ${theme.spacing(1.5)} ${theme.spacing(2.5)} ${theme.spacing(2)}`,
          }}
        >
          <ConditionalThemeProvider>
            <Stack alignItems="left" direction="row" justifyContent="left" spacing={1}>
              <SendIcon color="pending" />
              <Stack alignItems="left" justifyContent="space-between">
                <Typography variant="subtitle1">An availability request is still open</Typography>
                {options?.lastCandidateCommunicationSentAt && (
                  <Typography variant="body2" color="text.secondary">
                    A link was sent to the candidate by {options?.creator}
                    <DurationLabel
                      timestamp={assertIsoTimestamp(options.lastCandidateCommunicationSentAt)}
                      labelProps={{
                        variant: 'captions',
                        fontWeight: 400,
                        color: 'high-contrast-grey',
                        noWrap: true,
                        component: 'span',
                      }}
                    />
                    .
                  </Typography>
                )}
                {getAvailabilityRequestActions()}
              </Stack>
            </Stack>
          </ConditionalThemeProvider>
        </Paper>
      )}

      {!taskId && !selfScheduleRequestId && (
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          {!availabilityPresent && (
            <Button
              variant="outlined"
              disabled={readonly}
              startIcon={<PlusIcon color={readonly ? 'min-contrast-grey' : undefined} />}
              label="Add availability"
              onClick={toggleShowEditAvailabilityModal}
            />
          )}
          {availabilityPresentWithNoOptionsForTask && (
            <Button variant="outlined" label="Request new availability" onClick={toggleShowAvailabilityOptions} />
          )}
          {atsService?.isAtsImportAvailabilityEnabled && (
            <Link
              color="info"
              href={ZenDeskHelpCenterUrl.MISSING_CANDIDATE_AVAILABILITY}
              style={{ marginLeft: 'auto', textDecoration: 'none' }}
              target="_blank"
            >
              Missing availability?
            </Link>
          )}
        </Stack>
      )}

      {taskId && !selfScheduleRequestId && (
        <Stack alignItems="center" direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
          {!options?.externalId && (
            <Tooltip title={showApplicationRejected ? 'Application rejected' : ''}>
              <span>
                <Button
                  variant={availabilityPresent ? 'outlined' : 'contained'}
                  color={availabilityPresent ? undefined : 'primary'}
                  size="small"
                  disabled={showApplicationRejected}
                  label={availabilityPresent ? 'Request new availability' : 'Send availability request'}
                  onClick={handleCreateAvailabilityRequest}
                />
              </span>
            </Tooltip>
          )}

          <Button
            variant="outlined"
            size="small"
            label={availabilityPresent ? 'Edit availability' : 'Add availability manually'}
            onClick={toggleShowEditAvailabilityModal}
          />
          {atsService?.isAtsImportAvailabilityEnabled && (
            <Button
              variant="outlined"
              label="Help"
              size="small"
              onClick={() => {
                window.open(ZenDeskHelpCenterUrl.MISSING_CANDIDATE_AVAILABILITY, '_blank');
              }}
            />
          )}
        </Stack>
      )}
      {modalsJsx}
    </Stack>
  );

  return (
    <AccordionCard title={taskId ? <Label>{subTitle}</Label> : 'Availability'} subTitle={taskId ? undefined : subTitle}>
      {jsx}
    </AccordionCard>
  );
};

BaseCandidateAvailabilitySection.fragments = {
  application: gql`
    ${CandidateAvailabilityStatus.fragments.application}
    fragment BaseCandidateAvailabilitySection_application on Application {
      id
      status
      ...CandidateAvailabilityStatus_application
    }
  `,
  availabilityRequest: gql`
    ${CandidateAvailabilityStatus.fragments.availabilityRequest}
    fragment BaseCandidateAvailabilitySection_availabilityRequest on CandidateAvailabilityOptions {
      id
      ...CandidateAvailabilityStatus_availabilityRequest
    }
  `,
};

export default BaseCandidateAvailabilitySection;
