import React, { FC, useEffect } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import Box from '@material-ui/core/Box';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Calendar } from '@material-ui/pickers';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports, modernloop/restrict-imports.cjs
import { CalendarProps } from '@material-ui/pickers/views/Calendar/Calendar';
import clsx from 'clsx';
import { isFuture, isPast } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import moment from 'moment';

import Label from 'src/components/label';

import IsoTimestamp, { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { endOfDay, startOfDay } from 'src/utils/dateUtils';

import { Theme } from 'src/theme';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type BaseDatePickerProps = {
  /**
   * Default date to initialize the date time picker.
   */
  utcDate: Date;

  minDate?: Date;

  maxDate?: Date;

  timezone: string;

  dataTestId?: string;

  className?: string;

  renderDay?: CalendarProps['renderDay'];

  onChange: (time: Date) => void;

  disablePast?: CalendarProps['disablePast'];

  disableFuture?: CalendarProps['disableFuture'];

  shouldDisableDate?: (day: IsoTimestamp) => boolean;
};

export const useDateButtonStyle = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '6px',
      justifyContent: `stretch`,
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      '& .MuiPickersCalendarHeader-switchHeader': {
        '& .MuiPickersCalendarHeader-transitionContainer': {
          '& p': {
            fontWeight: 600,
          },
        },
      },
    },
    calendarButton: {
      '& button': {
        height: '32px',
        width: '32px',
        margin: '2px 4px',
      },
    },
    calendarButtonBorder: {
      '& button': {
        border: `1px solid ${theme.palette.border}`,
        borderRadius: '50%',
      },
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const BaseDatePicker: FC<BaseDatePickerProps> = ({
  utcDate,
  minDate,
  maxDate,
  timezone,
  className,
  dataTestId,
  renderDay,
  onChange,
  disablePast,
  disableFuture,
  shouldDisableDate,
}: BaseDatePickerProps) => {
  const classes = useStyles();

  const handleSelect = (newDate: moment.Moment) => {
    onChange(zonedTimeToUtc(newDate.toDate(), timezone));
  };

  useEffect(() => {
    // This ensures that the correct date is show as per timezone.
    moment.tz.setDefault(timezone);
    return () => {
      moment.tz.setDefault();
    };
  }, [timezone]);

  const defaultRenderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
    // eslint-disable-next-line max-params
  ) => {
    let showCalendarButtonBorder = true;
    if (disablePast && day) {
      showCalendarButtonBorder = !isPast(endOfDay(day.toDate(), timezone));
    }
    if (disableFuture && day) {
      showCalendarButtonBorder = !isFuture(startOfDay(day.toDate(), timezone));
    }

    const defaultDayJsx = (
      <Label
        dataTestId="multiple-date-picker-default-day"
        className={clsx(classes.calendarButton, { [classes.calendarButtonBorder]: showCalendarButtonBorder })}
      >
        {dayComponent}
      </Label>
    );

    return defaultDayJsx;
  };

  const handleShouldDisableDate = (day: MaterialUiPickersDate): boolean => {
    if (!shouldDisableDate || !day) return false;

    return shouldDisableDate(assertIsoTimestamp(day.toDate().toISOString()));
  };

  const disablePastValue = disablePast ?? true;
  const disableFutureValue = disableFuture ?? false;
  // TODO: Figure out a better behaviour here, maybe not render.
  const nonEmptyUTCTime = utcToZonedTime(utcDate || new Date(), timezone);
  return (
    <Box className={clsx(classes.root, className)} data-testid={dataTestId}>
      <Calendar
        allowKeyboardControl
        date={moment(nonEmptyUTCTime)}
        disableFuture={disableFutureValue}
        disablePast={disablePastValue}
        minDate={minDate ? moment(minDate) : undefined}
        maxDate={maxDate ? moment(maxDate) : undefined}
        renderDay={renderDay || defaultRenderDay}
        onChange={handleSelect}
        shouldDisableDate={handleShouldDisableDate}
      />
    </Box>
  );
};

export default BaseDatePicker;
