import { useEffect } from 'react';
import type { FC } from 'react';

import { useShouldLogStatistics } from '@modernloop/shared/hooks';
import { inIframe, initializeDatadog } from '@modernloop/shared/utils';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as amplitude from 'amplitude-js';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { useQueryStringParam } from 'src/hooks/useQueryStringParam';

// eslint-disable-next-line no-restricted-imports
import {
  DatadogConfig,
  amplitudeConfig,
  enviromentConfig,
  logrocketConfig,
  netlifyConfig,
  sentryConfig,
} from 'src/config';
import { IS_PRODUCTION } from 'src/constants';

// Non Modernloop api urls create a lot of noise in LogRocket
const shouldLogRocketDropUrl = (url: string) => {
  if (url.toLowerCase().includes('api.modernloop.io')) {
    return false;
  }

  if (url.toLowerCase().includes('api.stg.modernloop.io')) {
    return false;
  }

  return true;
};

/**
 * AnalyticsServices
 * - This is a wrapper that bundles loading of analytics services.
 *   It makes loading of our analytics services contingent on accepting of cookies, managed
 *   through OneTrust.
 * - This works in conjunction with the secure-build.js script, which loads OneTrust and hashes the script to enable SRI.
 *   We then check consents from OneTrust to control whether to instantiate our analytics services, and cause cookies to be dropped.
 * - Services impacted:
 *     - Amplitude
 * - Services not impacted:
 *     - Sentry: The Sentry SDK doesn't use cookies so we're free to use regardless of consent
 *     - LogRocket: We use LogRocket for identifying site errors, so we're considering the cookies necessary
 *     - LaunchDarkly: LD is required for the site to work properly, so its cookies are considered necessary
 * - Note:
 *      When a user changes their cookie settings from "accept" to "deny," we don't remove the cookies that have
 *      already been dropped (this wouldn't stop the tracking calls, necessarily). Instead, we stop making
 *      analytics calls through the service APIs, where possible.
 */

const initAnalytics = () => {
  // init Amplitude
  amplitude.getInstance().init(amplitudeConfig.apiKey as string);
};

const useDatadogService = () => {
  useEffect(() => {
    initializeDatadog(DatadogConfig);
  }, []);
};

export const AnalyticsServices: FC = () => {
  useDatadogService();
  // TODO abstract the below services into their own hooks

  const shouldLogStatistics = useShouldLogStatistics();
  const loginWithPopup = useQueryStringParam('loginWithPopup');
  const isInIframe = inIframe();

  useEffect(() => {
    // init Sentry - Sentry doesn't use cookies so we can always use it
    Sentry.init({
      dsn: sentryConfig.dns,
      normalizeDepth: 10,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: enviromentConfig.environment,
      release: `modernloop@${netlifyConfig.commitRef}`,
    });
  }, []);

  useEffect(() => {
    // init LogRocket
    // Only send LogRocket if client side id set
    // We're not recording sessions that happen during loginWithPopup
    // because it creates really short not useful sessions
    if (!loginWithPopup && logrocketConfig.clientSideID) {
      LogRocket.init(isInIframe ? logrocketConfig.candidatePortalClientSideID : logrocketConfig.clientSideID, {
        release: `modernloop@${netlifyConfig.commitRef}`,
        serverURL: logrocketConfig.proxyUrl,
        network: {
          requestSanitizer: (request) => {
            if (shouldLogRocketDropUrl(request.url)) {
              // ignore anything that isn't a modernloop api request
              return null;
            }

            // remove the Authorization header from requests
            if (request.headers) {
              if (request.headers.Authorization) {
                request.headers.Authorization = '***REDACTED***';
              }
            }

            return request;
          },
        },
        /**
         * If only loading *.modernloop.io in an iframe we want to merge the sessions for candidate portal or never merge sessions for app
         * Logrocket say we need to init logrocket in the iframe for this to work as well as parent window, thats why passing mergeIframes conditionally
         */
        mergeIframes: isInIframe,
      });
      setupLogRocketReact(LogRocket);
    }
  }, [isInIframe, loginWithPopup]);

  useEffect(() => {
    if (shouldLogStatistics || !IS_PRODUCTION) {
      initAnalytics();
    }
  }, [shouldLogStatistics]);

  console.log('App version', netlifyConfig.commitRef);

  return null;
};
