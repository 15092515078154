import urlcat from 'urlcat';

import { Routes } from 'src/constants/routes';

export enum OrganizationSettingsTab {
  SCHEDULING = 'scheduling',
  INTERVIEWERS = 'interviewers',
  TEMPLATES = 'templates',
  CANDIDATE_PORTAL = 'candidate-portal',
  NOTIFICATIONS = 'notifications',
  INTEGRATIONS = 'integrations',
  MEMBERS = 'members',
  TASK = 'tasks',
  PRIVACY = 'privacy',
  RESCHEDULE_REASONS = 'reschedule-reasons',
  SETUP = 'setup',
}

export type OrganizationSettingsUrlType = `/settings/organization/${OrganizationSettingsTab}`;

type Props = {
  tab: OrganizationSettingsTab;
};
export const getOrganizationSettingUrls = ({ tab }: Props): OrganizationSettingsUrlType => {
  return urlcat('', Routes.OrganizationSettings, { tab }) as OrganizationSettingsUrlType;
};
