import { components } from './components';
import { palette as lightPalette } from './palette/light';
import { typography } from './typography';
import { ThemeOptions } from '@mui/material';

export const lightThemeOptions: ThemeOptions = {
  typography,
  palette: lightPalette,
  components,
  shape: {
    // TODO: Add shape to new file and start adding components to it
  },
};
