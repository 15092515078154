import { BaseProps } from '../types';
import { CopyIcon } from '@modernloop/shared/icons';
import { Stack, Typography, Button } from '@mui/material';
import { useState } from 'react';

type Props = BaseProps & {
  value: string | undefined;
};

const CopyTextField = ({ dataTestId, value }: Props) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    if (typeof value !== 'string') {
      return;
    }
    navigator.clipboard.writeText(value as string);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const hasCopy = !!navigator?.clipboard?.writeText;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      flexGrow={1}
      data-testid={dataTestId}
    >
      <Typography>{value}</Typography>
      {hasCopy && (
        <Button
          variant="text"
          color="primary"
          startIcon={<CopyIcon />}
          onClick={copyToClipboard}
          // eslint-disable-next-line no-restricted-syntax
          sx={{ flexShrink: 0 }}
        >
          {!copied ? 'Copy link' : 'Copied'}
        </Button>
      )}
    </Stack>
  );
};

export default CopyTextField;
