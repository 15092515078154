import { CaretLeftIcon, CaretRightIcon } from '@modernloop/shared/icons';
import { ThemeOptions } from '@mui/material';

/**
 * View Tabs props and classes at https://mui.com/material-ui/api/tabs/#css
 */
export const tabs: ThemeOptions['components'] = {
  MuiTabs: {
    defaultProps: {
      slots: {
        StartScrollButtonIcon: CaretLeftIcon,
        EndScrollButtonIcon: CaretRightIcon,
      },
    },
    styleOverrides: {
      root: {
        minHeight: '28px',
        height: '100%',
      },
      flexContainer: {
        gap: '20px',
        height: '100%',
      },
      /**
       * Normally, the scroll buttons are hidden when not needed which takes up extra space.
       * This override hides the buttons when they are disabled.
       */
      scrollButtons: {
        '&.Mui-disabled': {
          display: 'none',
        },
      },
    },
  },
  MuiTab: {
    defaultProps: {
      iconPosition: 'start',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: '28px',
        height: '100%',
        padding: theme.spacing(0.5, 0),
        fontSize: '13px',
        minWidth: 'auto',
      }),
    },
  },
};
