import React from 'react';
import type { FC } from 'react';
import { Redirect } from 'react-router';

import useOrgId from 'src/hooks/useOrgId';

/**
 * Guard that only allows users with an org to access the children components
 * Note: Guards should check only one thing. Don't add any other checks to this Guard.
 */
const UserHasOrgGuard: FC = ({ children }) => {
  const orgId = useOrgId();

  if (!orgId) {
    return <Redirect to="/no-org" />;
  }

  return <>{children}</>;
};

export default UserHasOrgGuard;
