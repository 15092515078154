import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { GoogleHealthCheckBanner_GoogleFragment } from 'src/generated/mloop-graphql';

import AppWideBanner, { AppWideBannerAction } from '../AppWideBanner';

import { integrationUnhealthyMessage } from './constants';

interface Fragments {
  google: GoogleHealthCheckBanner_GoogleFragment;
}

interface Props {
  retry: AppWideBannerAction;
}

const GoogleHealthCheckBanner: FCWithFragments<Fragments, Props> = ({ google, retry }) => {
  // hide if its not installed or is healthy
  if (!google || !google?.isInstalled || google?.isHealthy) {
    return null;
  }

  const endpoints = [
    !google.canReadDirectory && 'employee directory',
    !google.canReadCalendars && 'calendars',
    !google.canReadMeetingRooms && 'meeting rooms',
  ].filter(Boolean) as string[];

  return (
    <AppWideBanner
      color="foreground"
      backgroundColor="error"
      text={integrationUnhealthyMessage('Google Workspace', endpoints)}
      action={retry}
    />
  );
};

GoogleHealthCheckBanner.fragments = {
  google: gql`
    fragment GoogleHealthCheckBanner_google on GoogleIntegrationHealth {
      isHealthy
      isInstalled
      canReadDirectory
      canReadCalendars
      canReadMeetingRooms
    }
  `,
};

export default GoogleHealthCheckBanner;
