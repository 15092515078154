import { useState } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';

import {
  DynamicInterviewMeetingHost,
  InterviewMeetingLocationType,
  UsePopulateInterviewMeetingLocation_InterviewMeetingLocationSettingFragment,
  useInterviewMeetingLocationZoomUsersByEmployeeIdsQuery,
} from 'src/generated/mloop-graphql';

import useEmployeeId from 'src/hooks/useEmployeeId';
import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import {
  updateCustomVideoMeetingLinksConfig,
  updateLocation,
  updateVideoMeetingLinkConfig,
  updateZoomMeetingConfig,
} from 'src/store/actions/schedule-communications';
import { MeetingLinksConfig } from 'src/store/slices/schedule-communications';

import { useDispatch } from 'src/store';

import usePopulateCustomInterviewMeetingLocation from './CustomLinks/usePopulateCustomInterviewMeetingLocation';
import usePopulateVideoInterviewMeetingLocation from './VideoMeetingLinks/usePopulateVideoInterviewMeetingLocation';
import usePopulateZoomInterviewMeetingLocation from './Zoom/usePopulateZoomInterviewMeetingLocation';

type Fragments = {
  interviewMeetingLocationSetting:
    | UsePopulateInterviewMeetingLocation_InterviewMeetingLocationSettingFragment
    | undefined;
};

type Props = {
  scheduleId: string;
  candidatePreferredPhoneNumber: string | undefined;
};

const usePopulateInterviewMeetingLocation: FunctionWithFragments<Fragments, Props, void> = (
  { interviewMeetingLocationSetting },
  { scheduleId, candidatePreferredPhoneNumber }
) => {
  const dispatch = useDispatch();
  const [run, setRun] = useState(false);

  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const populateCustomInterviewMeetingLocation = usePopulateCustomInterviewMeetingLocation({
    scheduleId,
    interviewMeetingLocationType: interviewMeetingLocationSetting?.interviewMeetingLocationType,
  });

  const populateVideoInterviewMeetingLocation = usePopulateVideoInterviewMeetingLocation({
    scheduleId,
    interviewMeetingLocationType: interviewMeetingLocationSetting?.interviewMeetingLocationType,
  });

  const populateZoomInterviewMeetingLocation = usePopulateZoomInterviewMeetingLocation({
    scheduleId,
  });

  const schedule = useScheduleWithoutBreaks(scheduleId);
  const employeeId = useEmployeeId();

  const interviewerEmployeeIds = schedule?.events
    .map((event) => event.interviewers.map((interviewer) => interviewer.employeeId))
    .flat();

  if (employeeId) interviewerEmployeeIds?.push(employeeId);

  const { data: zoomUsersByEmployeeIdsData, loading: zoomUsersByEmployeeIdsLoading } =
    useInterviewMeetingLocationZoomUsersByEmployeeIdsQuery({
      variables: { employeeIds: interviewerEmployeeIds },
      skip:
        interviewMeetingLocationSetting?.interviewMeetingLocationType !== InterviewMeetingLocationType.Zoom ||
        !interviewerEmployeeIds?.length,
    });

  if (!scheduleLocationEnabled) return;

  // If we are loading the zoom users, we will not run the mutation
  if (zoomUsersByEmployeeIdsLoading) return;

  if (run) return;
  setRun(true);

  if (
    !interviewMeetingLocationSetting ||
    interviewMeetingLocationSetting.interviewMeetingLocationType === InterviewMeetingLocationType.NotSpecified ||
    !schedule?.events.length
  ) {
    return;
  }

  // Setting the meeting config to Many if the dynamic host is interviewers
  dispatch(updateLocation(scheduleId, interviewMeetingLocationSetting.interviewMeetingLocationType));
  if (interviewMeetingLocationSetting.dynamicHost === DynamicInterviewMeetingHost.Interviewers) {
    switch (interviewMeetingLocationSetting.interviewMeetingLocationType) {
      case InterviewMeetingLocationType.Zoom: {
        dispatch(updateZoomMeetingConfig(scheduleId, MeetingLinksConfig.Many));
        break;
      }
      case InterviewMeetingLocationType.GoogleMeet:
      case InterviewMeetingLocationType.MicrosoftTeams: {
        dispatch(updateVideoMeetingLinkConfig(scheduleId, MeetingLinksConfig.Many));
        break;
      }
      case InterviewMeetingLocationType.CustomLink:
      case InterviewMeetingLocationType.CustomPhone:
      case InterviewMeetingLocationType.CustomText: {
        dispatch(updateCustomVideoMeetingLinksConfig(scheduleId, MeetingLinksConfig.Many));
        break;
      }

      default:
    }
  }

  // If the dymanic host is interviewers, we will create a meeting location for each interview event in the schedule
  if (
    [
      InterviewMeetingLocationType.Zoom,
      InterviewMeetingLocationType.GoogleMeet,
      InterviewMeetingLocationType.MicrosoftTeams,
    ].includes(interviewMeetingLocationSetting.interviewMeetingLocationType) &&
    interviewMeetingLocationSetting.dynamicHost === DynamicInterviewMeetingHost.Interviewers
  ) {
    schedule.events.forEach((event) => {
      if (!event.slotId) return;

      const interviewerIds = event.interviewers.map((interviewer) => interviewer.employeeId);
      let firstInterviewerZoomUserId: string | undefined;
      let firstInterviewerEmployeeId = event.interviewers.length ? event.interviewers[0].employeeId : undefined;

      // If the meeting location is zoom, we will try to find the zoom user of one of the interviewer in the event
      if (interviewMeetingLocationSetting.interviewMeetingLocationType === InterviewMeetingLocationType.Zoom) {
        const zoomUser = zoomUsersByEmployeeIdsData?.zoomUsersByIds?.find((user) =>
          interviewerIds.includes(user.employeeId || '')
        );

        // If none of the interviewers have zoom user, then we will not create a meeting location
        if (!zoomUser) return;

        firstInterviewerZoomUserId = zoomUser.zoomUserId;
        firstInterviewerEmployeeId = zoomUser.employeeId || undefined;

        populateZoomInterviewMeetingLocation({
          zoomUserId: firstInterviewerZoomUserId,
          hostEmployeeId: firstInterviewerEmployeeId,
          slotId: event.slotId,
        });
      } else if (firstInterviewerEmployeeId) {
        populateVideoInterviewMeetingLocation({ hostEmployeeId: firstInterviewerEmployeeId, slotId: event.slotId });
      }
    });
  } else {
    let hostEmployeeId: string | undefined;
    let remoteVideoHostUserId: string | undefined;
    if (interviewMeetingLocationSetting.dynamicHost === DynamicInterviewMeetingHost.Scheduler) {
      hostEmployeeId = employeeId;

      // If the meeting location is zoom, we will use the zoom user of the scheduler
      if (interviewMeetingLocationSetting.interviewMeetingLocationType === InterviewMeetingLocationType.Zoom) {
        const zoomUser = zoomUsersByEmployeeIdsData?.zoomUsersByIds?.find((user) => user.employeeId === employeeId);

        if (zoomUser) {
          remoteVideoHostUserId = zoomUser.zoomUserId;
        } else {
          // If the scheduler does not have a zoom user, we will not create a meeting location
          return;
        }
      }
    } else if (interviewMeetingLocationSetting.dynamicHost === DynamicInterviewMeetingHost.CustomMeetingHost) {
      hostEmployeeId = interviewMeetingLocationSetting.hostEmployeeId || undefined;
    } else if (
      interviewMeetingLocationSetting.dynamicHost === DynamicInterviewMeetingHost.CustomZoomHost ||
      interviewMeetingLocationSetting.remoteVideoMeetingHostUserId
    ) {
      remoteVideoHostUserId = interviewMeetingLocationSetting.remoteVideoMeetingHostUserId || undefined;
    }

    if (
      interviewMeetingLocationSetting.interviewMeetingLocationType === InterviewMeetingLocationType.Zoom &&
      remoteVideoHostUserId
    ) {
      populateZoomInterviewMeetingLocation({
        zoomUserId: remoteVideoHostUserId,
        hostEmployeeId,
      });
    } else if (
      [InterviewMeetingLocationType.GoogleMeet, InterviewMeetingLocationType.MicrosoftTeams].includes(
        interviewMeetingLocationSetting.interviewMeetingLocationType
      ) &&
      hostEmployeeId
    ) {
      populateVideoInterviewMeetingLocation({ hostEmployeeId });
    } else if (
      [
        InterviewMeetingLocationType.CustomLink,
        InterviewMeetingLocationType.CustomPhone,
        InterviewMeetingLocationType.CustomText,
      ].includes(interviewMeetingLocationSetting.interviewMeetingLocationType) &&
      interviewMeetingLocationSetting.customLocation
    ) {
      populateCustomInterviewMeetingLocation({ customLocation: interviewMeetingLocationSetting.customLocation });
    } else if (
      interviewMeetingLocationSetting.interviewMeetingLocationType === InterviewMeetingLocationType.CandidatePhone &&
      candidatePreferredPhoneNumber
    ) {
      populateCustomInterviewMeetingLocation({ customLocation: candidatePreferredPhoneNumber });
    }
  }
};

usePopulateInterviewMeetingLocation.fragments = {
  interviewMeetingLocationSetting: gql`
    fragment usePopulateInterviewMeetingLocation_interviewMeetingLocationSetting on InterviewMeetingLocationSettingPref {
      interviewMeetingLocationType
      dynamicHost
      hostEmployeeId
      remoteVideoMeetingHostUserId
      customLocation
    }
  `,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InterviewMeetingLocationZoomUsersByEmployeeIdsQuery = gql`
  query InterviewMeetingLocationZoomUsersByEmployeeIds($employeeIds: [uuid!]) {
    zoomUsersByIds(input: { employeeIds: $employeeIds }) {
      employeeId
      zoomUserId
    }
  }
`;

export default usePopulateInterviewMeetingLocation;
