/* eslint-disable max-lines */
import { useEffect } from 'react';

import { ApolloClient, gql } from '@apollo/client';
import { v4 as uuid } from 'uuid';

import {
  DynamicLinkType,
  FreeformSeat,
  InterviewPlanDocument,
  InterviewPlanQuery,
  InterviewPlanQueryVariables,
  InterviewType,
  JobStageAtsDetailsDocument,
  JobStageAtsDetailsQuery,
  JobStageAtsDetailsQueryVariables,
  JobStageInterviewSeatType,
  ModuleSeat,
  TrainingStatus,
  useInterviewPlanLazyQuery,
  useJobStageAtsDetailsLazyQuery,
} from 'src/generated/mloop-graphql';

import { getAtsInterviewDefinitionFields } from 'src/hooks/atsService/util';

import { addJobStage, addJobStageError, addJobStageLoading, deleteJobStageError } from 'src/store/actions/job-stage';
import { addJobStageInterview } from 'src/store/actions/job-stage-interview';
import { addJobStageInterviewGroup } from 'src/store/actions/job-stage-interview-group';
import { addJobStageInterviewSeat } from 'src/store/actions/job-stage-interview-seat';
import { getJobStageById } from 'src/store/selectors/job-stage';
import { JobStage } from 'src/store/slices/job-stage';
import { AtsInterviewDefinitionStore, JobStageInterview } from 'src/store/slices/job-stage-interview';
import { JobStageInterviewGroup } from 'src/store/slices/job-stage-interview-group';
import { AttributeMap, JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import logError from 'src/utils/logError';

import { AppDispatch, useDispatch, useSelector } from 'src/store';

import { AtsInterviewDefinitionsFragment } from '../Scorecards/useAtsScorecardOptions';

export const JobStageInterviewPlanFragment = gql`
  ${AtsInterviewDefinitionsFragment}
  fragment JobStage_InterviewPlan on JobStage {
    id
    jobId
    name
    createdAt
    updatedAt
    schedulable
    index
    atsId
    job {
      id
      atsId
    }
    jobStageInterviewGroups {
      locked
      id
      jobStageId
      index
      name
      atsId
      jobStageInterviews {
        id
        jobStageId
        interviewType
        customDuration
        name
        duration
        index
        isLockedOrder
        useAtsName
        useAtsDuration
        schedulable
        dynamicLinkTypes
        staticLinks
        jobStageInterviewGroupId
        atsInterviewDefinitionId
        isHiddenFromCandidate
        atsInterviewDefinition {
          ...AtsInterviewDefinition
        }
        jobStageInterviewSeats {
          id
          type
          moduleSeat {
            interviewModuleId
            interviewModule {
              id
              orgId
              shadowsRequired
              reverseShadowsRequired
              membersCount
              name
              membersTrainedCount
              membersInTrainingCount
              membersInReverseShadowCount
              membersInShadowCount
              membersInPendingApprovalCount
              graduateFromShadowApprovalType
              graduateFromReverseShadowApprovalType
              interviewModuleMembers(input: { pageInput: { limit: 500, offset: 0 } }) {
                items {
                  interviewModuleId
                  employeeId
                  status
                  shadowsRequired
                  reverseShadowsRequired
                  pausedUntil
                  stats {
                    completedAsReverseShadow
                    completedAsShadow
                    shadowOffset
                    reverseShadowOffset
                  }
                  employee {
                    id
                    fullName
                    email
                    slackImageUrl
                    attributes {
                      id
                      value
                    }
                    interviewPauseDates {
                      start
                      end
                    }
                  }
                }
              }
            }
            selectedEmployeeIds
            jobStageInterviewSeatAttributes {
              id
              jobStageInterviewSeatId
              attributeNameId
              attributeTagValueId
            }
          }
          linkedSeat {
            linkedJobStageInterviewSeatId
          }
          freeformSeat {
            jobStageInterviewSeatEmployees {
              jobStageInterviewSeatId
              employeeId
              employee {
                fullName
                email
                slackImageUrl
                id
                orgId
                hasAtsId
                isArchived
                isAtsDisabled
                isDirectoryDisabled
                useCalendarTimezone
                isPaused
              }
              preferenceLevel
            }
          }
        }
      }
    }
  }
`;

export const InterviewPlan = gql`
  ${JobStageInterviewPlanFragment}
  query InterviewPlan($id: uuid!) {
    jobStage(id: $id) {
      ...JobStage_InterviewPlan
    }
  }
`;

export const JobStageATSDetails = gql`
  query JobStageATSDetails($id: uuid!) {
    jobStage(id: $id) {
      id
      atsId
      job {
        id
        atsId
      }
    }
  }
`;

const getAtsDetails = (atsDetails?: JobStageAtsDetailsQuery): { atsId: string; atsJobId: string } => {
  return { atsId: atsDetails?.jobStage?.atsId || '', atsJobId: atsDetails?.jobStage?.job?.atsId || '' };
};

export const saveJobStageInterviewPlanToStore = (
  dispatch: AppDispatch,
  jobStageId: string,
  generateNewId: boolean | undefined,
  jobStageByPk: Exclude<InterviewPlanQuery['jobStage'], undefined | null>,
  atsDetails: JobStageAtsDetailsQuery | undefined // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
void => {
  /**
   * function will always return id depending on to toAssociateJobStageId
   * toAssociateJobStageId this means one jobstage is mapped to another jobstage so always return a new uuid for associated entities
   * So that we dont end up overwritting each other
   * like jobStageInterviewGroup , jobStageInterview , jobStageInterviewSeats
   */
  const getId = (id: string): string => {
    return generateNewId ? uuid() : id;
  };

  const jobStageInterviewGroupIds: string[] = [];

  /**
   * linked Seat id || seat id =>  new Seat id
   * The purpose of linkSeatIdMap is that if a same linked seat id or seat id is used at multiple times,
   * shouldn't end up creating new id every time
   */

  const linkSeatIdMap: {
    [linkedSeatId: string]: string;
  } = {};

  jobStageByPk.jobStageInterviewGroups?.forEach((group) => {
    if (group?.jobStageInterviews?.length === 0) return;

    const jobStageInterviewIds: string[] = [];

    const jobStageInterviewGroupId = getId(group?.id);
    group?.jobStageInterviews?.forEach((interview) => {
      const interviewId = getId(interview?.id);
      jobStageInterviewIds.push(interviewId);

      const seatIds: string[] = [];
      interview?.jobStageInterviewSeats?.forEach((seat, index) => {
        if (seat?.linkedSeat) {
          linkSeatIdMap[seat?.linkedSeat.linkedJobStageInterviewSeatId] =
            linkSeatIdMap[seat?.linkedSeat.linkedJobStageInterviewSeatId] ||
            getId(seat.linkedSeat.linkedJobStageInterviewSeatId);
        }

        linkSeatIdMap[seat?.id] = linkSeatIdMap[seat?.id] || getId(seat?.id);
        const seatId = linkSeatIdMap[seat?.id];
        seatIds.push(seatId);

        const attributeMap: AttributeMap = {};
        if (seat?.moduleSeat) {
          seat?.moduleSeat?.jobStageInterviewSeatAttributes?.forEach((attributes) => {
            if (!attributeMap[attributes?.attributeNameId]) {
              attributeMap[attributes?.attributeNameId] = [];
            }
            attributeMap[attributes?.attributeNameId].push(attributes?.attributeTagValueId);
          });
        }

        const jobStageInterviewSeat: JobStageInterviewSeat = {
          id: seatId,
          index, // : seat.index, // using index because the seat index returned from server is not starting from 0 sometimes
          jobStageInterviewId: interviewId,
          type: seat?.type || JobStageInterviewSeatType.Freeform,
          interviewId: seat?.moduleSeat?.interviewModuleId,
          interviewSeatId: linkSeatIdMap[seat?.linkedSeat?.linkedJobStageInterviewSeatId],
          employeeIds: seat?.freeformSeat?.jobStageInterviewSeatEmployees?.map((value) => value?.employeeId),
          attributeMap,
        };

        if (seat?.freeformSeat) {
          jobStageInterviewSeat.freeformSeat = seat.freeformSeat as FreeformSeat;
        }

        if (seat?.moduleSeat) {
          jobStageInterviewSeat.moduleSeat = seat.moduleSeat as ModuleSeat;
          if (seat.moduleSeat.selectedEmployeeIds?.length) {
            const map: { trained: string[]; shadow: string[]; reverseShadow: string[] } = {
              trained: [],
              shadow: [],
              reverseShadow: [],
            };
            seat.moduleSeat.interviewModule?.interviewModuleMembers?.items.forEach((item) => {
              if (!item) return;
              if (item?.status === TrainingStatus.Trained) {
                map.trained.push(item.employeeId);
              }
              if (item?.status === TrainingStatus.Shadow) {
                map.shadow.push(item.employeeId);
              }
              if (item?.status === TrainingStatus.ReverseShadow) {
                map.reverseShadow.push(item.employeeId);
              }
            });
            const selectedTrainedEmployeeIds = seat.moduleSeat.selectedEmployeeIds.filter((id) =>
              map.trained.includes(id)
            );
            const selectedShadowEmployeeIds = seat.moduleSeat.selectedEmployeeIds.filter((id) =>
              map.shadow.includes(id)
            );
            const selectedReverseShadowEmployeeIds = seat.moduleSeat.selectedEmployeeIds.filter((id) =>
              map.reverseShadow.includes(id)
            );
            jobStageInterviewSeat.selectedTrainedInterviewerIds = selectedTrainedEmployeeIds;
            jobStageInterviewSeat.selectedShadowInterviewerIds = selectedShadowEmployeeIds;
            jobStageInterviewSeat.selectedReverseShadowInterviewerIds = selectedReverseShadowEmployeeIds;
          }
        }

        if (seat?.linkedSeat) {
          jobStageInterviewSeat.linkedSeat = seat.linkedSeat;
        }
        dispatch(addJobStageInterviewSeat(jobStageInterviewSeat));
      });

      const atsInterviewDuration = getAtsInterviewDefinitionFields(
        interview?.atsInterviewDefinition?.atsFields,
        'estimatedMinutes'
      );

      const atsInterviewName = interview?.atsInterviewDefinition?.name;
      const interviewDuration =
        (interview?.useAtsDuration ? atsInterviewDuration : null) ||
        interview?.customDuration ||
        interview?.duration ||
        0;

      const jobStageInterview: JobStageInterview = {
        id: interviewId,
        jobStageId,
        jobStageGroupId: jobStageInterviewGroupId,
        type: (interview?.interviewType || InterviewType.Interview) as InterviewType,
        name: (interview?.useAtsName ? atsInterviewName : null) || interview?.name,
        index: interview?.index || 0,
        duration: interviewDuration,
        schedulable: !!interview?.schedulable,
        isLockedOrder: !!interview?.isLockedOrder,
        forcedStartAt: null,
        atsInterviewName,
        atsInterviewDuration,
        atsInterviewDefinition: interview?.atsInterviewDefinition as AtsInterviewDefinitionStore,
        staticLinks: interview?.staticLinks?.map((link) => link || ''),
        dynamicLinkTypes: interview?.dynamicLinkTypes?.map((linkType) => linkType as DynamicLinkType),
        seatIds,
        isHiddenFromCandidate: interview?.isHiddenFromCandidate,
      };

      dispatch(addJobStageInterview(jobStageInterview));
    });

    jobStageInterviewGroupIds.push(jobStageInterviewGroupId);

    const jobStageInterviewGroup: JobStageInterviewGroup = {
      id: jobStageInterviewGroupId,
      jobStageId,
      locked: !!group?.locked,
      jobStageInterviewIds,
      name: group?.name || 'Group',
      atsId: group?.atsId || '',
    };

    dispatch(addJobStageInterviewGroup(jobStageInterviewGroup));
  });

  const jobStage: JobStage = {
    id: jobStageId,
    jobId: jobStageByPk?.jobId,
    name: jobStageByPk.name,
    schedulable: !!jobStageByPk.schedulable,
    createdAt: jobStageByPk.createdAt,
    updatedAt: jobStageByPk.updatedAt,
    jobStageInterviewGroupIds,
    atsJobId: jobStageByPk?.job?.atsId || getAtsDetails(atsDetails)?.atsJobId,
    atsId: jobStageByPk?.atsId || getAtsDetails(atsDetails)?.atsId,
  };

  dispatch(addJobStage(jobStage));
};

/**
 * @param jobStageId => Interview plan should always be fetched against this id
 * @param toAssociateJobStageId => If passed that means we are trying to associate one jobStage to another
 * @param originalJobStageId => Currently originalJobStageId is only used to fetch the ATS details
 * @returns
 */

export const useFetchInterviewPlanForJobStage = (
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-lines
  jobStageId: string,
  toAssociateJobStageId?: string,
  originalJobStageId?: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
void => {
  const dispatch = useDispatch();
  const [fetchInterviewPlan, { loading, error, data }] = useInterviewPlanLazyQuery();
  const [fetchATSDetails, { loading: atsLoading, data: atsDetails }] = useJobStageAtsDetailsLazyQuery();

  /**
   * After this point everything should be computed or store against finalJobStageId
   */
  const finalJobStageId = toAssociateJobStageId || jobStageId;

  const prevJobStage = useSelector((state) => getJobStageById(state, finalJobStageId));

  /**
   * Fetching the interview plan for jobStageId
   * Fetching ATS details for jobStageId  & toAssociateJobStageId as we are not saving the atsId for custom job stage
   */
  useEffect(() => {
    // If we have the interview plan locally then do not fetch.
    if (prevJobStage) {
      return;
    }

    /** Fetch only if originalJobStageId is present & jobStageId is not same as originalJobStageId */
    if (originalJobStageId && jobStageId !== originalJobStageId && !atsLoading && !atsDetails) {
      fetchATSDetails({
        variables: {
          id: originalJobStageId,
        },
      });
    }

    if (!loading && !data && jobStageId) {
      fetchInterviewPlan({
        variables: {
          id: jobStageId,
        },
      });
    }
  }, [
    prevJobStage,
    jobStageId,
    loading,
    data,
    fetchInterviewPlan,
    fetchATSDetails,
    atsLoading,
    atsDetails,
    originalJobStageId,
  ]);

  // If we have the interview plan locally then do not fetch.
  if (prevJobStage) {
    return;
  }

  /** This will make sure ATS details are loaded  if needed */
  if (loading || atsLoading) {
    dispatch(addJobStageLoading(finalJobStageId, loading));
    return;
  }

  if (error) {
    dispatch(addJobStageError(finalJobStageId, error.message));
    return;
  }

  // The jobStageId check is required because sometimes Apollo returns old data
  if (!data || data.jobStage?.id !== jobStageId) {
    return;
  }

  const { jobStage: jobStageByPk } = data;

  if (!jobStageByPk) return;

  // Reset loading and error state on success
  dispatch(addJobStageLoading(finalJobStageId, loading));
  dispatch(deleteJobStageError(finalJobStageId));

  saveJobStageInterviewPlanToStore(dispatch, finalJobStageId, !!toAssociateJobStageId, jobStageByPk, atsDetails);
};

export const fetchInterviewPlan = async (
  client: ApolloClient<object>,
  dispatch: AppDispatch,
  jobStageId: string, // This is always the original jobStageId
  generateNewIds: boolean
): // eslint-disable-next-line max-params
Promise<void> => {
  dispatch(addJobStageLoading(jobStageId, true));

  const finalJobStageId = jobStageId;

  if (!finalJobStageId) return;

  const interviewPlanQueryResult = client.query<InterviewPlanQuery, InterviewPlanQueryVariables>({
    query: InterviewPlanDocument,
    variables: {
      id: jobStageId,
    },
    fetchPolicy: 'network-only',
  });

  const atsDetailsQueryResult = client.query<JobStageAtsDetailsQuery, JobStageAtsDetailsQueryVariables>({
    query: JobStageAtsDetailsDocument,
    variables: {
      id: jobStageId,
    },
    fetchPolicy: 'network-only',
  });

  const [
    { data: interviewPlanData, error: interviewPlanError },
    { data: jobStageAtsDetailsData, error: jobStageAtsDetailsError },
  ] = await Promise.all([interviewPlanQueryResult, atsDetailsQueryResult]);

  dispatch(addJobStageLoading(jobStageId, false));

  if (interviewPlanError) {
    logError(interviewPlanError);
    return;
  }

  const { jobStage: jobStageByPk } = interviewPlanData;

  // The jobStageId check is required because sometimes Apollo returns old data
  if (!jobStageByPk || finalJobStageId !== jobStageByPk.id) {
    return;
  }
  const atsDetails = jobStageAtsDetailsError ? undefined : jobStageAtsDetailsData;

  if (jobStageByPk?.jobStageInterviewGroups?.length) {
    saveJobStageInterviewPlanToStore(dispatch, jobStageId, generateNewIds, jobStageByPk, atsDetails);
  } else {
    const jobStage: JobStage = {
      id: jobStageId,
      jobId: jobStageByPk?.jobId,
      name: jobStageByPk.name,
      schedulable: !!jobStageByPk.schedulable,
      createdAt: jobStageByPk.createdAt,
      updatedAt: jobStageByPk.updatedAt,
      jobStageInterviewGroupIds: [],
      atsJobId: jobStageByPk?.job?.atsId || getAtsDetails(atsDetails)?.atsJobId,
      atsId: jobStageByPk?.atsId || getAtsDetails(atsDetails)?.atsId,
    };

    dispatch(addJobStage(jobStage));
  }
};
