import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { gql } from '@apollo/client';
import { FCWithFragments, MaybeTooltip } from '@modernloop/shared/components';
import { MentorIcon } from '@modernloop/shared/icons';
import { Avatar, Box, Link, Stack, Theme, Typography } from '@mui/material';

import { InterviewModuleMemberHeader_ModuleMemberFragment, TrainingStatus } from 'src/generated/mloop-graphql';

import ModuleMemberPausedChip from 'src/entities/InterviewModuleMember/Statuses/ModuleMemberPausedChip';
import ModuleMemberPendingChip from 'src/entities/InterviewModuleMember/Statuses/ModuleMemberPendingChip';
import ModuleMemberTrainingRole from 'src/entities/InterviewModuleMember/Statuses/ModuleMemberTrainingRole';

import getInterviewModuleDetailsUrl from 'src/urls/getInterviewModuleDetailsUrl';
import getInterviewerDetailsPageUrl from 'src/urls/getInterviewerDetailsPageUrl';

import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

type Fragments = {
  moduleMember: InterviewModuleMemberHeader_ModuleMemberFragment;
};

const InterviewModuleMemberHeader: FCWithFragments<Fragments> = ({ moduleMember }) => {
  const { employee, interviewModule } = moduleMember;
  return (
    <Stack
      padding={(theme: Theme) => theme.spacing(2.5)}
      rowGap={(theme: Theme) => theme.spacing(1.5)}
      borderBottom={(theme: Theme) => `1px solid ${theme.palette.border}`}
      position="sticky"
      top="49px"
      zIndex={1}
      bgcolor={(theme: Theme) => theme.palette.background.paper}
    >
      <Stack direction="row" spacing={0.75} alignItems="center" width="100%" overflow="hidden" whiteSpace="nowrap">
        <Stack display="flex" flexDirection="row" columnGap={(theme: Theme) => theme.spacing(1)}>
          <Avatar alt="Avatar" src={employee?.slackImageUrl ?? ''} variant="rounded" />
          <Box
            onClick={(e) => {
              e.stopPropagation();
              SidePanelManager.closeSidePanel();
            }}
          >
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              to={getInterviewerDetailsPageUrl(moduleMember.employeeId)}
            >
              <Typography variant="subtitle1">{employee?.fullName}</Typography>
            </Link>
          </Box>
        </Stack>
        <Typography variant="subtitle1" color="text.secondary">
          for
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center" overflow="hidden">
          <MentorIcon />

          <Typography variant="subtitle1" width="100%" color="text.primary" overflow="hidden">
            <Link
              underline="hover"
              color="inherit"
              component={RouterLink}
              onClick={(e) => {
                e.stopPropagation();
                SidePanelManager.closeSidePanel();
              }}
              to={getInterviewModuleDetailsUrl(moduleMember.interviewModule?.id)}
            >
              <MaybeTooltip
                labelProps={{
                  variant: 'subtitle1',
                }}
                label={interviewModule?.name ?? ''}
                title={interviewModule?.name}
              />
            </Link>
          </Typography>
        </Stack>
      </Stack>
      <Stack flexDirection="row" columnGap={(theme: Theme) => theme.spacing(1.5)}>
        <ModuleMemberTrainingRole status={moduleMember?.status || TrainingStatus.Shadow} />
        <ModuleMemberPendingChip moduleMember={moduleMember} />
        <ModuleMemberPausedChip moduleMember={moduleMember} />
      </Stack>
    </Stack>
  );
};

InterviewModuleMemberHeader.fragments = {
  moduleMember: gql`
    ${ModuleMemberPausedChip.fragments.moduleMember}
    ${ModuleMemberPendingChip.fragments.moduleMember}
    fragment InterviewModuleMemberHeader_moduleMember on InterviewModuleMember {
      id
      interviewModuleId
      employeeId
      employee {
        id
        fullName
        slackImageUrl
      }
      interviewModule {
        id
        name
      }
      status
      ...ModuleMemberPausedChip_moduleMember
      ...ModuleMemberPendingChip_moduleMember
    }
  `,
};

export default InterviewModuleMemberHeader;
