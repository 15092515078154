import React, { useMemo } from 'react';

import {
  Box,
  FormControlLabelProps,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  SxProps,
} from '@mui/material';

import { CheckboxIcon, CheckboxSelectedIcon } from 'src/components/icons';
import Label from 'src/components/label';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/themeMui5/type';

interface Props extends BaseProps {
  /**
   * If true, the component is checked.
   */
  checked?: boolean;

  /**
   * If true, the checkbox will be disabled.
   */
  disabled?: boolean;

  /**
   * The text to be used in an enclosing label element.
   */
  label?: React.ReactNode;

  labelPlacement?: FormControlLabelProps['labelPlacement'];

  /**
   * If set then the label will take full width of its container.
   */
  fullWidth?: boolean;

  /**
   * Caption
   */
  caption?: React.ReactNode;

  /**
   * function(event: object) => void
   * event: The event source of the callback. You can pull out the new checked state by accessing event.target.checked (boolean).
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Styles applied to the root element.
   */
  sx?: SxProps<Theme>;

  /**
   * Styles applied to the MUI FormControl label element.
   */
  sxFormControl?: SxProps<Theme>;
}

const useSxProps = () => {
  return useMemo(() => {
    return {
      root: {
        color: (theme: Theme) => theme.grey.alpha.max,
        marginLeft: 0,
        marginRight: 0,
        padding: '1px 0 0 0',
        '& .Mui-checked': {
          color: (theme: Theme) => theme.palette.primary.main,
        },
      },
      checkboxContainer: {
        padding: '6px 12px 6px 8px',
        marginLeft: 0,
        marginRight: 0,
        borderRadius: '6px',
        '&:hover': {
          backgroundColor: (theme: Theme) => theme.grey.alpha.min,
        },
        alignItems: 'start',
      },
      checkboxContainerFullWidth: {
        width: '100%',
      },
      labelContainer: {
        paddingLeft: '8px',
      },
    };
  }, []);
};

/**
 * @deprecated - Use `Checkbox` from `@mui/material` instead
 */
const Checkbox = ({
  checked,
  disabled,
  label,
  labelPlacement,
  fullWidth,
  caption,
  onChange,
  sx,
  dataTestId,
  sxFormControl,
}: Props): JSX.Element => {
  const sxProps = useSxProps();
  const sxRootProps = useMemo(() => ({ ...sxProps.root, ...(sx || {}) }), [sx, sxProps.root]);
  const sxFormControlLabelProps = useMemo(
    () => ({
      ...sxProps.checkboxContainer,
      ...(sxFormControl || {}),
      ...(fullWidth ? sxProps.checkboxContainerFullWidth : {}),
    }),
    [fullWidth, sxProps.checkboxContainer, sxProps.checkboxContainerFullWidth, sxFormControl]
  );

  const checkbox = (
    <MuiCheckbox
      checked={Boolean(checked)}
      sx={sxRootProps}
      onChange={onChange}
      disabled={Boolean(disabled)}
      data-testid={dataTestId}
      checkedIcon={<CheckboxSelectedIcon color={disabled ? 'mid-contrast-grey' : 'primary'} fontSize={20} />}
      icon={<CheckboxIcon color={disabled ? 'mid-contrast-grey' : 'max-contrast-grey'} fontSize={20} />}
    />
  );

  if (label) {
    let labelContent = label;
    if (typeof label === 'string') {
      labelContent = <Label color={disabled ? 'high-contrast-grey' : 'foreground'}>{label}</Label>;
    }

    let captionContent = caption;
    if (typeof captionContent === 'string') {
      captionContent = (
        <Label color={disabled ? 'high-contrast-grey' : 'max-contrast-grey'} variant="captions">
          {caption}
        </Label>
      );
    }
    const labelJsx = (
      <Box sx={sxProps.labelContainer}>
        {labelContent}
        {captionContent}
      </Box>
    );

    return (
      <MuiFormControlLabel
        sx={sxFormControlLabelProps}
        disabled={Boolean(disabled)}
        control={checkbox}
        label={labelJsx}
        labelPlacement={labelPlacement}
      />
    );
  }

  return checkbox;
};

export default Checkbox;
