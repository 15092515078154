import React from 'react';

import BaseIcon, { Props } from './Icon';

const CheckboxSelectedIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2.25C3.48122 2.25 2.25 3.48122 2.25 5L2.25001 15C2.25002 16.5188 3.48123 17.75 5.00001 17.75H15C16.5188 17.75 17.75 16.5188 17.75 15L17.75 5C17.75 3.48121 16.5188 2.25 15 2.25H5Z"
        fill="currentColor"
      />
      <path
        d="M5.5 10.0667C6.75237 11.0477 7.40461 11.6817 8.45652 13C10.5772 10.4292 11.7907 9.22231 14 7.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default CheckboxSelectedIcon;
