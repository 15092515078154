import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskScheduleCanceled_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';

interface Fragments {
  activityLog: ActivityLogTaskScheduleCanceled_ActivityLogFragment;
}

const ActivityLogTaskScheduleCanceled: FCWithFragments<Fragments> = ({ activityLog }) => {
  return <ActivityLogBase activityLog={activityLog} actionLabel="canceled the schedule" />;
};

ActivityLogTaskScheduleCanceled.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskScheduleCanceled_activityLog on ActivityLog {
      id

      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskScheduleCanceled;
