import React from 'react';

import BaseIcon, { Props } from './Icon';

const UserIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6C7 4.34315 8.34315 3 10 3C11.6569 3 13 4.34315 13 6V7C13 8.65685 11.6569 10 10 10C8.34315 10 7 8.65685 7 7V6ZM16.0925 13.9487C14.895 12.0658 12.3431 11.25 9.99999 11.25C7.65692 11.25 5.105 12.0658 3.90749 13.9487C3.41196 14.7278 3.57754 15.559 4.05949 16.1326C4.51343 16.6728 5.2431 17 6.00008 17L13.9999 17C14.7569 17 15.4866 16.6728 15.9405 16.1326C16.4225 15.559 16.588 14.7278 16.0925 13.9487Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default UserIcon;
