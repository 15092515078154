import React, { useState } from 'react';

import { Dialog } from '@modernloop/shared/components';
import { Stack, Typography } from '@mui/material';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type CancelTaskConfirmationDialogProps = {
  onConfirm: () => Promise<void>;
  onClose: () => void;
};

const CancelTaskConfirmationDialog = ({ onConfirm, onClose }: CancelTaskConfirmationDialogProps) => {
  const [canceling, setCanceling] = useState<boolean>(false);
  const handleConfirm = () => {
    setCanceling(true);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    onConfirm().finally(() => {
      setCanceling(false);
    });
  };

  return (
    <ConditionalThemeProvider>
      <Dialog
        open
        title="Cancel schedule task"
        subTitle="Data for requirements will be removed"
        submitOptions={{
          label: 'Cancel schedule task',
          isDangerous: true,
          isDisabled: canceling,
          isLoading: canceling,
          onClick: handleConfirm,
        }}
        cancelOptions={{
          label: 'Cancel',
          onClick: onClose,
        }}
        onClose={onClose}
      >
        <Stack spacing={2}>
          <Typography>
            Canceling this schedule task will immediately cancel or remove all upcoming or open items on this task. This
            includes canceling upcoming interviews and debriefs, removing availability, and closing any open requests to
            the candidate.
          </Typography>
        </Stack>
      </Dialog>
    </ConditionalThemeProvider>
  );
};

export default CancelTaskConfirmationDialog;
