import React, { useRef, useState } from 'react';

import IconButton from 'src/components/IconButton';
import MoreIcon from 'src/components/icons/More';
import Menu, { MenuOption } from 'src/components/menu';

import {
  groupJobStageInterviewGroup,
  moveJobStageInterviewGroupId,
  ungroupJobStageInterviewGroup,
} from 'src/store/actions/job-stage';
import { deleteJobStageInterviewGroup } from 'src/store/actions/job-stage-interview-group';

import { useDispatch } from 'src/store';

enum InterviewGroupOptions {
  MOVE_UP = 'move-up',
  MOVE_DOWN = 'move-down',
  GROUP_WITH_BELOW = 'group-with-below',
  GROUP_WITH_ABOVE = 'group-with-above',
  UNGROUP = 'ungroup',
  RENAME = 'rename-group',
  DELETE = 'delete',
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type InterviewGroupMenuProps = {
  jobStageId: string;
  id: string;
  count: number;
  index: number;
  onUpdated: () => void;
  groupNameInputRef: React.RefObject<HTMLInputElement>;
};

const InterviewGroupMenu = ({
  jobStageId,
  id: groupId,
  count,
  index,
  onUpdated,
  groupNameInputRef,
}: InterviewGroupMenuProps): JSX.Element => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const moreButtonRef = useRef<HTMLButtonElement>(null);

  const options: MenuOption[] = [
    { id: InterviewGroupOptions.MOVE_UP, value: 'Move up', hidden: index === 0 },
    { id: InterviewGroupOptions.MOVE_DOWN, value: 'Move down', hidden: index === count - 1 },
    { isDivider: true, hidden: count === 1 },
    { id: InterviewGroupOptions.RENAME, value: 'Rename group' },
    {
      id: InterviewGroupOptions.GROUP_WITH_BELOW,
      value: 'Group with below',
      hidden: index === count - 1 || count === 1,
    },
    { id: InterviewGroupOptions.GROUP_WITH_ABOVE, value: 'Group with above', hidden: index === 0 || count === 1 },
    { id: InterviewGroupOptions.UNGROUP, value: 'Ungroup' },
    { isDivider: true },
    { id: InterviewGroupOptions.DELETE, value: 'Delete group & interviews' },
  ];

  const hideMenu = () => setShowMenu(false);

  const handleGroupOptionSelect = (option: MenuOption) => {
    switch (option.id) {
      case InterviewGroupOptions.MOVE_UP: {
        dispatch(moveJobStageInterviewGroupId(jobStageId, groupId, index - 1));
        // dispatch(swapJobStageInterviewGroupIndex(id, id));
        break;
      }
      case InterviewGroupOptions.MOVE_DOWN: {
        dispatch(moveJobStageInterviewGroupId(jobStageId, groupId, index + 1));
        // dispatch(swapJobStageInterviewGroupIndex(id, id));
        break;
      }
      case InterviewGroupOptions.GROUP_WITH_BELOW: {
        dispatch(groupJobStageInterviewGroup(jobStageId, index, index + 1));
        break;
      }
      case InterviewGroupOptions.GROUP_WITH_ABOVE: {
        dispatch(groupJobStageInterviewGroup(jobStageId, index, index - 1));
        break;
      }
      case InterviewGroupOptions.UNGROUP: {
        dispatch(ungroupJobStageInterviewGroup(jobStageId, groupId));
        break;
      }
      case InterviewGroupOptions.RENAME: {
        setTimeout(() => groupNameInputRef?.current?.focus(), 1); // without a delay here, the call to .focus() doesn't work
        break;
      }
      case InterviewGroupOptions.DELETE: {
        dispatch(deleteJobStageInterviewGroup(groupId));
        break;
      }
      default:
    }
    hideMenu();
    if (option.id !== InterviewGroupOptions.RENAME) {
      onUpdated();
    }
  };

  return (
    <>
      <IconButton ref={moreButtonRef} onClick={() => setShowMenu(!showMenu)} tooltip="More options">
        <MoreIcon fontSize={20} />
      </IconButton>
      <Menu
        id="interview-group-options"
        options={options}
        anchorEl={moreButtonRef.current}
        open={showMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onSelect={handleGroupOptionSelect}
        onClose={hideMenu}
      />
    </>
  );
};

export default InterviewGroupMenu;
