import { useMemo } from 'react';

import { InterviewMeetingLocationType } from 'src/generated/mloop-graphql';

import {
  getCustomVideoMeetingLink,
  getCustomVideoMeetingLinkUrls,
  getLocation,
  getVideoMeetingLink,
  getVideoMeetingLinkUrls,
  getZoomInfo,
  getZoomInfos,
} from 'src/store/selectors/schedule-communications';
import { getWorkspaceVideoLink } from 'src/store/selectors/scheduling';

import { useSelector } from 'src/store';

const Messages = {
  UNIQUE_ZOOM_LINK_HELPER_EMAIL_TEMPLATE_MSG: 'See schedule details for Zoom meeting links',
  UNIQUE_MULTIPLE_CUSTOM_LINK_HELPER_EMAIL_TEMPLATE_MSG: 'See schedule details for meeting links',
};

const useLocationUrl = (selectedScheduleId: string | null | undefined) => {
  const location = useSelector((state) => getLocation(state, selectedScheduleId || ''));
  const zoomInfo = useSelector((state) => getZoomInfo(state, selectedScheduleId || ''));
  const zoomInfos = useSelector((state) => getZoomInfos(state, selectedScheduleId || ''));
  const customVideoMeetingLinkUrl = useSelector((state) => getCustomVideoMeetingLink(state, selectedScheduleId || ''));
  const customVideoMeetingLinkUrls = useSelector((state) =>
    getCustomVideoMeetingLinkUrls(state, selectedScheduleId || '')
  );
  const workspaceVideoLink = useSelector(getWorkspaceVideoLink);
  const videoMeetingLink = useSelector((state) => getVideoMeetingLink(state, selectedScheduleId || ''));
  const videoMeetingLinkUrls = useSelector((state) => getVideoMeetingLinkUrls(state, selectedScheduleId || ''));

  const locationUrl = useMemo(() => {
    switch (location) {
      case InterviewMeetingLocationType.Zoom: {
        if (Object.keys(zoomInfos).length) return Messages.UNIQUE_ZOOM_LINK_HELPER_EMAIL_TEMPLATE_MSG;
        return zoomInfo?.joinURL || '';
      }
      case InterviewMeetingLocationType.CustomPhone:
      case InterviewMeetingLocationType.CustomText:
      case InterviewMeetingLocationType.CustomLink: {
        if (Object.keys(customVideoMeetingLinkUrls).length)
          return Messages.UNIQUE_MULTIPLE_CUSTOM_LINK_HELPER_EMAIL_TEMPLATE_MSG;
        return customVideoMeetingLinkUrl || '';
      }
      case InterviewMeetingLocationType.GoogleMeet:
      case InterviewMeetingLocationType.MicrosoftTeams: {
        if (Object.keys(videoMeetingLinkUrls).length) {
          return Messages.UNIQUE_MULTIPLE_CUSTOM_LINK_HELPER_EMAIL_TEMPLATE_MSG;
        }
        return videoMeetingLink || workspaceVideoLink || '';
      }
      default:
        return '';
    }
  }, [
    customVideoMeetingLinkUrl,
    customVideoMeetingLinkUrls,
    location,
    videoMeetingLink,
    videoMeetingLinkUrls,
    workspaceVideoLink,
    zoomInfo?.joinURL,
    zoomInfos,
  ]);

  return locationUrl;
};

export default useLocationUrl;
