import React, { FC, useCallback } from 'react';

import { FeatureFlagBool } from '@modernloop/shared/feature-flag';
import { Box, Stack, useTheme } from '@mui/material';

import BetaTag from 'src/components/BetaTag';
import IconButton from 'src/components/IconButton';
import MoreMenuButton from 'src/components/MoreMenuButton';
import { CrossIcon } from 'src/components/icons';
import { MenuOption } from 'src/components/menu';

import { SidePanelManager } from './SidePanelManager';

type BetaFlag = {
  flag: FeatureFlagBool;
  tooltip?: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type SidePanelContainerProps = {
  /**
   * Allow showing the beta tag in the top right of the side panel
   */
  betaFlag?: BetaFlag;
  moreMenuOptions?: MenuOption[];
  onMenuOptionSelected?: (option: MenuOption) => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const SidePanelContainer: FC<SidePanelContainerProps> = ({
  children,
  betaFlag,
  moreMenuOptions,
  onMenuOptionSelected,
}) => {
  const currentTheme = useTheme();
  const hideSidePanelClose = useCallback(() => {
    SidePanelManager.closeSidePanel();
  }, []);

  return (
    <Stack flexGrow={1}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={1}
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          top: 0,
          position: 'sticky',
          zIndex: 1,
        }}
        borderBottom={(theme) => `1px solid ${theme.palette.divider}`}
        padding={1.5}
      >
        {betaFlag && <BetaTag flag={betaFlag.flag} tooltip={betaFlag.tooltip} />}
        {moreMenuOptions?.length && onMenuOptionSelected && (
          <MoreMenuButton
            style={{
              height: '24px',
              width: '32px',
              borderRadius: '4px',
              backgroundColor: currentTheme.grey.solid.min,
            }}
            color="min-contrast-grey"
            options={moreMenuOptions}
            onSelect={onMenuOptionSelected}
          />
        )}
        <IconButton
          style={{
            height: '24px',
            width: '32px',
            borderRadius: '4px',
            backgroundColor: currentTheme.grey.solid.min,
          }}
          color="min-contrast-grey"
          onClick={hideSidePanelClose}
        >
          <CrossIcon />
        </IconButton>
      </Stack>

      <Box sx={{ display: 'flex', flexGrow: 1 }}>{children}</Box>
    </Stack>
  );
};

export default SidePanelContainer;
