/* eslint-disable max-lines */
import React, { useMemo, useRef } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { CanDisable } from '@modernloop/shared/helper-components';
import { IconButton, Paper, TextField, inputBaseClasses } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useDebouncedCallback } from 'use-debounce';

import {
  DateTimeRangeInput,
  InterviewGroupOld_JobFragment,
  InterviewGroupOld_JobStageInterviewGroupFragment,
  InterviewGroupOld_JobStageInterviewGroupsFragment,
  InterviewGroupOld_JobStageInterviewsFragment,
} from 'src/generated/mloop-graphql';

import { OrderLockIcon, OrderShuffleIcon } from 'src/components/icons';

import { DEBOUNCE_TIMEOUT } from 'src/constants';

import InterviewGroupMenu, { InterviewGroupOptions } from './InterviewGroupMenu';
import JobStageInterview from './JobStageInterview';
import { InterviewMenuOptions } from './MoreMenuOptions';

type Props = {
  count: number;
  schedulable: boolean;
  readonly?: boolean;
  candidateAvailabilities?: DateTimeRangeInput[];
  onUpdated: (jobStageInterviewGroup: InterviewGroupOld_JobStageInterviewGroupFragment) => void;
  onDelete: (jobStageInterviewGroupId: string) => void;
  onMoveJobStageInterviewGroup: (groupId: string, position: number) => void;
  onGroupJobStageInterviewGroup: (sourceGroupIndex: number, destGroupIndex: number) => void;
  onUngroupJobStageInterviewGroup: (groupId: string) => void;

  // For Draggable
  index: number;
};

type Fragments = {
  job: InterviewGroupOld_JobFragment;
  jobStageInterviewGroup: InterviewGroupOld_JobStageInterviewGroupFragment;
  jobStageInterviewGroups: InterviewGroupOld_JobStageInterviewGroupsFragment[];
  jobStageInterviews: InterviewGroupOld_JobStageInterviewsFragment[];
};

const useSxProps = () => {
  return useMemo(() => {
    return {
      paper: {
        padding: '8px',
      },
    };
  }, []);
};

const InterviewGroup: FCWithFragments<Fragments, Props> = ({
  job,
  jobStageInterviewGroup,
  jobStageInterviewGroups,
  jobStageInterviews,
  count,
  index,
  schedulable,
  readonly,
  candidateAvailabilities,
  onUpdated,
  onDelete,
  onMoveJobStageInterviewGroup,
  onGroupJobStageInterviewGroup,
  onUngroupJobStageInterviewGroup,
}): JSX.Element => {
  const sxProps = useSxProps();
  const { locked, name } = jobStageInterviewGroup;
  const jobStageInterviewIds: string[] =
    jobStageInterviewGroup.jobStageInterviews?.map((interview) => interview.id) || [];
  const interviewGroupNameRef = useRef<HTMLInputElement>(null);

  const debouncedOnUpdated = useDebouncedCallback(onUpdated, DEBOUNCE_TIMEOUT);

  const handleNameInputOnBlur = () => {
    if (!name) {
      onUpdated({ ...jobStageInterviewGroup, name: 'Group' });
    }
  };

  const handleInterviewGroupNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedOnUpdated({ ...jobStageInterviewGroup, name: event.target.value });
  };

  const handlePlanOrderClick = () => {
    onUpdated({ ...jobStageInterviewGroup, locked: !locked });
  };

  const handleMenuSelect = (type: InterviewGroupOptions) => {
    switch (type) {
      case InterviewGroupOptions.MOVE_UP: {
        onMoveJobStageInterviewGroup(jobStageInterviewGroup.id, index - 1);
        break;
      }
      case InterviewGroupOptions.MOVE_DOWN: {
        onMoveJobStageInterviewGroup(jobStageInterviewGroup.id, index + 1);
        break;
      }
      case InterviewGroupOptions.GROUP_WITH_BELOW: {
        onGroupJobStageInterviewGroup(index, index + 1);
        break;
      }
      case InterviewGroupOptions.GROUP_WITH_ABOVE: {
        onGroupJobStageInterviewGroup(index, index - 1);
        break;
      }
      case InterviewGroupOptions.UNGROUP: {
        onUngroupJobStageInterviewGroup(jobStageInterviewGroup.id);
        break;
      }
      case InterviewGroupOptions.RENAME: {
        setTimeout(() => interviewGroupNameRef?.current?.focus(), 1); // without a delay here, the call to .focus() doesn't work
        break;
      }
      case InterviewGroupOptions.DELETE: {
        onDelete(jobStageInterviewGroup.id);
        break;
      }
      default:
    }
  };

  const handleInterviewMenuSelect = (jobStageInterviewId: string, option: InterviewMenuOptions) => {
    switch (option) {
      case InterviewMenuOptions.MOVE_UP: {
        if (jobStageInterviewGroup.jobStageInterviews?.length === 1) {
          onMoveJobStageInterviewGroup(jobStageInterviewGroup.id, index - 1);
        } else {
          const interviews = [...(jobStageInterviewGroup.jobStageInterviews || [])];
          const interviewIndex = interviews.findIndex((interview) => interview.id === jobStageInterviewId);
          const interview = interviews.splice(interviewIndex, 1);
          interviews.splice(interviewIndex - 1, 0, ...interview);
          onUpdated({ ...jobStageInterviewGroup, jobStageInterviews: interviews });
        }
        break;
      }
      case InterviewMenuOptions.MOVE_DOWN: {
        if (jobStageInterviewGroup.jobStageInterviews?.length === 1) {
          onMoveJobStageInterviewGroup(jobStageInterviewGroup.id, index + 1);
        } else {
          const interviews = [...(jobStageInterviewGroup.jobStageInterviews || [])];
          const interviewIndex = interviews.findIndex((interview) => interview.id === jobStageInterviewId);
          const interview = interviews.splice(interviewIndex, 1);
          interviews.splice(interviewIndex + 1, 0, ...interview);
          onUpdated({ ...jobStageInterviewGroup, jobStageInterviews: interviews });
        }
        break;
      }
      case InterviewMenuOptions.GROUP_WITH_ABOVE: {
        onGroupJobStageInterviewGroup(index, index - 1);
        break;
      }
      case InterviewMenuOptions.GROUP_WITH_BELOW: {
        onGroupJobStageInterviewGroup(index, index + 1);
        break;
      }
      default:
    }
  };

  return (
    <Draggable
      key={jobStageInterviewGroup.id}
      draggableId={jobStageInterviewGroup.id}
      index={index}
      isDragDisabled={readonly}
    >
      {(draggableProvided: DraggableProvided) => {
        const header =
          jobStageInterviewIds.length > 1 ? (
            <CanDisable disabled={Boolean(readonly)}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ paddingLeft: '18px', paddingRight: '8px' }}
              >
                <Grid style={{ flexGrow: 1, marginRight: '8px' }}>
                  <Grid container spacing={2} alignItems="center">
                    <IconButton onClick={handlePlanOrderClick}>
                      {locked && <OrderLockIcon tooltip="Locked position" />}
                      {!locked && <OrderShuffleIcon tooltip="Shuffle position" />}
                    </IconButton>
                    <Grid style={{ flexGrow: 1 }}>
                      <TextField
                        defaultValue={name}
                        fullWidth
                        inputProps={{ style: { fontWeight: 600, width: '100%', flex: 1 } }}
                        onChange={handleInterviewGroupNameChange}
                        placeholder="Group"
                        inputRef={interviewGroupNameRef}
                        sx={{
                          '& fieldset': { border: 'none' },
                          [`& .${inputBaseClasses.root}`]: {
                            backgroundColor: 'transparent',
                            border: '1px solid transparent',
                            '&:hover': { border: (theme) => `1px solid ${theme.palette.border}` },
                          },
                        }}
                        onBlur={handleNameInputOnBlur}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <InterviewGroupMenu count={count} index={index} onMenuSelect={handleMenuSelect} />
                </Grid>
              </Grid>
            </CanDisable>
          ) : null;

        const slots = (
          <>
            {jobStageInterviewIds.map((jobStageInterviewId, slotIndex) => {
              const jobStageInterview = jobStageInterviewGroup?.jobStageInterviews?.find(
                (interview) => interview?.id === jobStageInterviewId
              );
              const linkableInterviews = jobStageInterviews.filter((interview) => interview.id !== jobStageInterviewId);
              return (
                <React.Fragment key={jobStageInterviewId}>
                  {jobStageInterview && (
                    <JobStageInterview
                      key={jobStageInterviewId}
                      job={job}
                      jobStageInterviewId={jobStageInterviewId}
                      jobStageInterview={jobStageInterview}
                      jobStageInterviewGroups={jobStageInterviewGroups}
                      jobStageInterviews={jobStageInterviews}
                      linkableInterviews={linkableInterviews}
                      groupJobStageInterviewIds={jobStageInterviewIds}
                      index={slotIndex}
                      groupIndex={index}
                      schedulable={schedulable}
                      readonly={readonly}
                      candidateAvailabilities={candidateAvailabilities}
                      onUpdated={(newJobStageInterview) => {
                        const updatedGroup: InterviewGroupOld_JobStageInterviewGroupFragment = {
                          ...jobStageInterviewGroup,
                          // The below check is to ensure if there is only one interview in the group,
                          // then the group locked value should be based on the interviews isLockedOrder value.
                          locked:
                            jobStageInterviewGroup?.jobStageInterviews &&
                            jobStageInterviewGroup.jobStageInterviews.length === 1
                              ? newJobStageInterview.isLockedOrder
                              : jobStageInterviewGroup.locked,
                          jobStageInterviews: jobStageInterviewGroup.jobStageInterviews?.map((interview) => {
                            if (interview.id === jobStageInterview.id) {
                              return newJobStageInterview;
                            }
                            return interview;
                          }),
                        };
                        onUpdated(updatedGroup);
                      }}
                      onDelete={(jsiid) => {
                        if (jobStageInterviewGroup.jobStageInterviews?.length === 1) {
                          onDelete(jobStageInterviewGroup.id);
                        } else {
                          onUpdated({
                            ...jobStageInterviewGroup,
                            jobStageInterviews: jobStageInterviewGroup.jobStageInterviews?.filter(
                              (interview) => interview.id !== jsiid
                            ),
                          });
                        }
                      }}
                      onMenuSelect={handleInterviewMenuSelect}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </>
        );

        return (
          <Grid
            container
            direction="column"
            wrap="nowrap"
            data-testid="hemant-sawant-interview-group-old"
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            sx={{ p: '0 !important' }}
          >
            {jobStageInterviewIds.length > 1 && (
              <Paper variant="outlined" sx={sxProps.paper}>
                {header}
                {slots}
              </Paper>
            )}
            {jobStageInterviewIds.length === 1 && slots}
          </Grid>
        );
      }}
    </Draggable>
  );
};

InterviewGroup.fragments = {
  job: gql`
    ${JobStageInterview.fragments.job}
    fragment InterviewGroupOld_job on Job {
      id
      ...JobStageInterview_job
    }
  `,
  jobStageInterviewGroup: gql`
    ${JobStageInterview.fragments.jobStageInterview}
    fragment InterviewGroupOld_jobStageInterviewGroup on JobStageInterviewGroup {
      id
      # index # Not using index in code, only using it while storing it.
      locked
      name
      jobStageInterviews {
        id
        ...JobStageInterview_jobStageInterview
      }
    }
  `,
  jobStageInterviews: gql`
    ${JobStageInterview.fragments.jobStageInterview}
    ${JobStageInterview.fragments.jobStageInterviews}
    ${JobStageInterview.fragments.linkableInterviews}
    fragment InterviewGroupOld_jobStageInterviews on JobStageInterview {
      id
      ...JobStageInterview_jobStageInterview
      ...JobStageInterview_jobStageInterviews
      ...JobStageInterview_linkableInterviews
    }
  `,
  jobStageInterviewGroups: gql`
    ${JobStageInterview.fragments.jobStageInterviewGroups}
    fragment InterviewGroupOld_jobStageInterviewGroups on JobStageInterviewGroup {
      id
      ...JobStageInterview_jobStageInterviewGroups
    }
  `,
};

export default InterviewGroup;
