import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { getExternalErrorMessage, logError } from '@modernloop/shared/utils';
import { useSnackbar } from 'notistack';

import {
  QueueDialogContent_QueueMembersFragment,
  QueueModal_TaskQueueFragment,
  SourceType,
  useCreateTaskQueueMutation,
  useEditTaskQueueMutation,
  useSubscribersListQueueModalQuery,
} from 'src/generated/mloop-graphql';

import { apolloCacheDeleteByQuery } from 'src/utils/apolloHelpers';

import { PAGE_SIZE } from 'src/constants';

import QueueDialogContent from './QueueDialogContent';

type Props = {
  open: boolean;
  onClose: () => void;
};

type Fragments = {
  taskQueue: QueueModal_TaskQueueFragment | null;
};

/** Used to create and edit task queue */
const QueueModal: FCWithFragments<Fragments, Props> = ({ open, onClose, taskQueue }) => {
  const isEdit = !!taskQueue?.id;
  const [queueName, setQueueName] = useState(taskQueue?.name || '');
  const [queueMembers, setQueueMembers] = useState<QueueDialogContent_QueueMembersFragment[]>([]);

  const [createQueue] = useCreateTaskQueueMutation();
  const [updateQueue] = useEditTaskQueueMutation();

  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useSubscribersListQueueModalQuery({
    variables: {
      input: {
        pageInput: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        sourceId: taskQueue?.id,
        sourceType: SourceType.SourceTypeTaskQueue,
      },
    },
    skip: !isEdit,
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (data?.subscribers?.items) {
        setQueueMembers(data?.subscribers?.items);
      }
    },
  });

  const createNewQueue = async () => {
    try {
      await createQueue({
        variables: {
          input: {
            name: queueName,
            employeeIds: queueMembers.map((member) => member.id),
          },
        },
        refetchQueries: ['TaskQueues'],
      });
      enqueueSnackbar('Task queue created', {
        variant: 'success',
      });
      onClose();
    } catch (e) {
      logError(`Failed to create queue: ${e}`);
      enqueueSnackbar(getExternalErrorMessage(e), {
        variant: 'error',
      });
    }
  };

  const editQueue = async () => {
    try {
      await updateQueue({
        variables: {
          input: {
            id: taskQueue?.id,
            name: queueName,
            employeeIds: queueMembers.map((member) => member.id),
          },
        },
        update: (cache) => {
          apolloCacheDeleteByQuery(cache, 'taskQueueSearch');
        },
      });

      enqueueSnackbar('Task queue updated', {
        variant: 'success',
      });
      onClose();
    } catch (e) {
      logError(`Failed to edit queue: ${e}`);
      enqueueSnackbar(getExternalErrorMessage(e), {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog
      title={isEdit ? 'Edit Queue' : 'Create Queue'}
      open={open}
      onClose={() => onClose()}
      submitOptions={{
        label: isEdit ? 'Save changes' : 'Save',
        onClick: isEdit ? editQueue : createNewQueue,
        isDisabled: !queueName && !queueMembers.length,
      }}
      cancelOptions={{
        label: 'Cancel',
        onClick: () => onClose(),
      }}
    >
      <QueueDialogContent
        queueMembers={queueMembers}
        queueName={queueName}
        onMembersChange={setQueueMembers}
        onNameChange={setQueueName}
        loading={loading}
      />
    </Dialog>
  );
};

export const TaskQueueCreate = gql`
  mutation CreateTaskQueue($input: TaskQueueCreateInput!) {
    taskQueueCreate(input: $input) {
      taskQueue {
        id
        ...QueueRow_taskQueue
      }
    }
  }
`;

export const TaskQueueUpdate = gql`
  mutation EditTaskQueue($input: TaskQueueUpdateInput!) {
    taskQueueUpdate(input: $input) {
      taskQueue {
        id
        ...QueueRow_taskQueue
      }
    }
  }
`;

export const SubscribersListGQL = gql`
  ${QueueDialogContent.fragments.queueMembers}
  query SubscribersListQueueModal($input: SubscribersInput!) {
    subscribers(input: $input) {
      items {
        id
        ...QueueDialogContent_queueMembers
      }
    }
  }
`;

QueueModal.fragments = {
  taskQueue: gql`
    fragment QueueModal_taskQueue on TaskQueue {
      id
      name
      memberCount
      taskCount
    }
  `,
};

export default QueueModal;
