import { addDays, getDay, isAfter, isEqual, isPast, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { TimeBlock, TimeBlockWeek } from 'src/types/preferenes';

import { getTimeInTimezone } from 'src/utils/dateUtils';

export type TimeRange = {
  start: string;
  end: string;
};

export const getTimeBlockWeekArray = (timeBlockWeek: TimeBlockWeek): TimeBlock[][] => {
  return [
    timeBlockWeek.sunday,
    timeBlockWeek.monday,
    timeBlockWeek.tuesday,
    timeBlockWeek.wednesday,
    timeBlockWeek.thursday,
    timeBlockWeek.friday,
    timeBlockWeek.saturday,
  ];
};

/**
 * Follows the following logic:
 * - Calculates all the time periods in the local time zone and then converts it to the given timezone.
 * @param startAt Time as which the last interview ends for a candidate.
 * @param timeBlockWeek Working hours.
 * @param timezone The timezone in which the time periods needs to be created.
 * @returns Time periods calculated based on start time, working hours and timezone.
 *          Here the start and end date are ISO date string and not `HH:mm` as expected
 */

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getTimePeriods = (startAt: string, timeBlockWeek: TimeBlockWeek, timezone: string): TimeRange[] => {
  const timeBlockWeekArray = getTimeBlockWeekArray(timeBlockWeek);

  const getTime = (time: Date, hourMins: string) => {
    const parts = hourMins.split(':');

    // Set the time in the local timezone.
    const result = new Date(time.getFullYear(), time.getMonth(), time.getDate());
    result.setHours(parseInt(parts[0], 10), parseInt(parts[1], 10), 0, 0);

    return result;
  };

  let startTime = utcToZonedTime(parseISO(startAt), timezone);

  // If the date is in past then use the next hour as start time.
  if (isPast(startTime)) {
    startTime = new Date();
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    startTime.setHours(startTime.getHours() + 1);
  }

  let day = getDay(startTime);
  let timeBlocks = timeBlockWeekArray[day];
  if (!timeBlocks) {
    timeBlocks = [
      {
        start: '09:00',
        end: '17:00',
      },
    ];
  }

  const timePeriods: TimeRange[] = [];

  // Add available time for the current day
  timeBlocks.forEach((timeBlock) => {
    const timeBlockEnd = getTime(startTime, timeBlock.end);
    if (isAfter(startTime, timeBlockEnd) || isEqual(startTime, timeBlockEnd)) {
      return;
    }

    const timeBlockStart = getTime(startTime, timeBlock.start);

    if (isAfter(startTime, timeBlockStart)) {
      timePeriods.push({
        start: getTimeInTimezone(startTime, timezone).toISOString(),
        end: getTimeInTimezone(timeBlockEnd, timezone).toISOString(),
      });
    } else {
      timePeriods.push({
        start: getTimeInTimezone(timeBlockStart, timezone).toISOString(),
        end: getTimeInTimezone(timeBlockEnd, timezone).toISOString(),
      });
    }
  });

  // Add the next 2 business days as availability
  [1, 2].forEach(() => {
    let dayCount = 0;
    timeBlocks = [];
    while (dayCount < 7) {
      day = ((day + 1) % 7) as 0 | 1 | 2 | 3 | 4 | 5 | 6;
      startTime = addDays(startTime, 1);
      timeBlocks = timeBlockWeekArray[day];
      if (timeBlocks && timeBlocks.length) break;
      dayCount++;
    }

    if (timeBlocks && timeBlocks.length) {
      timeBlocks.forEach((timeBlock) => {
        timePeriods.push({
          start: getTimeInTimezone(getTime(startTime, timeBlock.start), timezone).toISOString(),
          end: getTimeInTimezone(getTime(startTime, timeBlock.end), timezone).toISOString(),
        });
      });
    }
  });

  return timePeriods;
};
