import { logDatadogError } from './datadog';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logError = (error: any, extra: any = null) => {
  Sentry.withScope((scope) => {
    scope.setExtra('error', error);

    if (LogRocket.sessionURL) {
      scope.setExtra('session_url', LogRocket.sessionURL);
      scope.setTag('session_url', LogRocket.sessionURL);
    }

    if (extra) {
      scope.setExtra('extra', extra);
    }

    console.error(error);
    Sentry.captureException(error);
  });

  if (error instanceof Error) {
    LogRocket.captureException(error);
  } else {
    LogRocket.captureMessage(error.toString());
  }

  logDatadogError(error, extra);
};
