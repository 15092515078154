import { QueryResult, gql } from '@apollo/client';

import {
  EmployeePrefDataFragment,
  EmployeePrefsQuery,
  Maybe,
  StringEmployeePref,
  useEmployeePrefsQuery,
} from 'src/generated/mloop-graphql';

import IsoTimestamp from 'src/types/IsoTimestamp';

import { EmployeePrefName } from 'src/utils/api/employee/constants';

import { AllEmployeePref } from './types';

type QueryResultType = Omit<QueryResult, 'data'>;

export const EmployeePrefFragment = gql`
  fragment EmployeePrefData on EmployeePref {
    orgId
    prefName
    json
    boolValue
    stringValue
    intValue
    stringArray
    jsonArray
    employeeId
    timestamp
  }
`;

export const EmployeePrefsGqlQuery = gql`
  ${EmployeePrefFragment}
  query EmployeePrefs($id: uuid!, $prefName: String!) {
    employee(id: $id) {
      id
      employeePref(prefName: $prefName) {
        orgId
        employeeId
        prefName
        createdAt
        updatedAt
        ...EmployeePrefData
      }
    }
  }
`;

export function useEmployeePrefs(
  pref: EmployeePrefName | AllEmployeePref,
  employeeId: string
): QueryResult<EmployeePrefsQuery> {
  return useEmployeePrefsQuery({
    variables: {
      id: employeeId,
      prefName: pref,
    },
    fetchPolicy: 'cache-and-network',
    skip: !employeeId,
  });
}

// This single fetch intentially fetches all the employee prefrences so we can cache.
export function useEmployeePref(
  pref: EmployeePrefName | AllEmployeePref,
  employeeId: string
): [EmployeePrefDataFragment | null, QueryResultType] {
  const { data, ...rest } = useEmployeePrefs(pref, employeeId);

  return [data?.employee?.employeePref ? data.employee?.employeePref : null, rest];
}

export function useEmployeePrefBool(pref: EmployeePrefName, employeeId: string): [boolean | null, QueryResultType] {
  const [value, rest] = useEmployeePref(pref, employeeId);
  return [value?.boolValue || null, rest];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useEmployeePrefString(
  pref: EmployeePrefName | StringEmployeePref,
  employeeId: string,
  defaultValue: string | null = null
): [string | null, QueryResultType] {
  const [value, rest] = useEmployeePref(pref, employeeId);
  return [value?.stringValue || defaultValue, rest];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useEmployeePrefTimestamp(
  pref: EmployeePrefName,
  employeeId: string,
  defaultValue: string | null = null
): [IsoTimestamp | null, QueryResultType] {
  const [value, rest] = useEmployeePref(pref, employeeId);
  return [value?.timestamp || defaultValue, rest];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useEmployeePrefInt(
  pref: EmployeePrefName,
  employeeId: string,
  defaultValue: number | null = null
): [number | null, QueryResultType] {
  const [value, rest] = useEmployeePref(pref, employeeId);
  return [value?.intValue || defaultValue, rest];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useEmployeePrefStringArray(
  pref: EmployeePrefName,
  employeeId: string,
  defaultValue: Maybe<string>[] | null = null
): [Maybe<string>[] | null, QueryResultType] {
  const [value, rest] = useEmployeePref(pref, employeeId);
  return [value?.stringArray || defaultValue, rest];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useEmployeePrefJson<T>(
  pref: EmployeePrefName,
  employeeId: string,
  defaultValue: T | null = null
): [T | null, QueryResultType] {
  const [value, rest] = useEmployeePref(pref, employeeId);
  if (!value?.json) {
    return [defaultValue, rest];
  }
  return [JSON.parse(value.json) as unknown as T, rest];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useEmployeePrefJsonArray<T>(
  pref: EmployeePrefName,
  employeeId: string,
  defaultValue: T[] | null = null
): [T[] | null, QueryResultType] {
  const [value, rest] = useEmployeePref(pref, employeeId);
  if (!value?.jsonArray) {
    return [defaultValue, rest];
  }

  const vals = value.jsonArray.map((val: string) => {
    return JSON.parse(val);
  }) as unknown as T[];

  return [vals, rest];
}
