import React from 'react';

import { Dialog } from '@modernloop/shared/components';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type IncompleteInterviewPlanAlertProps = {
  title: string;
  message: string;
  onClose: () => void;
};

const IncompleteInterviewPlanAlert = ({ title, message, onClose }: IncompleteInterviewPlanAlertProps) => {
  return (
    <Dialog
      open
      dataTestId="self-schedule-incomplete-interview-plan-alert"
      onClose={onClose}
      title={title}
      cancelOptions={{ onClick: onClose }}
    >
      {message}
    </Dialog>
  );
};

export default IncompleteInterviewPlanAlert;
