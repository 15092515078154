import React from 'react';

import BaseIcon, { Props } from './Icon';

const CaretUpIcon = ({ color, fontSize, tooltip, viewBox }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} viewBox={viewBox}>
      <path
        d="M15 11.5L10 7.5L5 11.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default CaretUpIcon;
