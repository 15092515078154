import { SvgIcon, SvgIconProps } from './SvgIcon';

export const PauseIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9 7C9 6.44772 8.55228 6 8 6C7.44772 6 7 6.44772 7 7V13C7 13.5523 7.44772 14 8 14C8.55228 14 9 13.5523 9 13V7ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
