import { Auth0Client, GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { logMessage } from '@modernloop/shared/utils';

import { auth0Config } from 'src/config';

let auth0Client: Auth0Client | null = null;

export const getAuth0Client = async (forceNew?: boolean): Promise<Auth0Client> => {
  if (auth0Client && !forceNew) {
    return auth0Client;
  }

  auth0Client = new Auth0Client({
    redirect_uri: `${window.location.origin}/callback`,
    cacheLocation: 'localstorage',
    ...auth0Config,
  });

  /**
   * This is to handle the case where the user has logged out of Auth0 but the session is still active
   * And we should always return a valid auth0Client instance
   */
  try {
    await auth0Client.checkSession();
  } catch (e) {
    logMessage('User unexpectedly unauthorised', 'error', e);
  }

  return auth0Client;
};

export const getJSONWebToken = async (options?: GetTokenSilentlyOptions): Promise<string | null> => {
  if (!auth0Client) {
    auth0Client = await getAuth0Client();
  }
  if (!auth0Client) {
    return null;
  }

  await auth0Client.getTokenSilently(options);
  const claims = await auth0Client.getIdTokenClaims();
  // eslint-disable-next-line
  return claims?.__raw || null;
};
