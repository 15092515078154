import { SvgIcon, SvgIconProps } from './SvgIcon';

export const InterviewIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.25C7.41421 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48121 17.75 6L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H6.25V2C6.25 1.58579 6.58578 1.25 7 1.25ZM7 4.75H13H15C15.6903 4.75 16.25 5.30964 16.25 6L16.25 15C16.25 15.6904 15.6904 16.25 15 16.25H5.00001C4.30966 16.25 3.75001 15.6904 3.75001 15L3.75 6C3.75 5.30965 4.30964 4.75 5 4.75H7ZM7 7.25C6.0335 7.25 5.25 8.0335 5.25 9V11C5.25 11.9665 6.0335 12.75 7 12.75H13C13.9665 12.75 14.75 11.9665 14.75 11V9C14.75 8.0335 13.9665 7.25 13 7.25H7ZM6.75 9C6.75 8.86193 6.86193 8.75 7 8.75H13C13.1381 8.75 13.25 8.86193 13.25 9V11C13.25 11.1381 13.1381 11.25 13 11.25H7C6.86193 11.25 6.75 11.1381 6.75 11V9Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
