import { getLocalTimezone } from '@modernloop/shared/datetime';
import { isEmpty, uniq } from 'lodash';

import {
  CodeLinkInput,
  CodeLinkType,
  EventInput,
  InterviewerRole,
  TokenInterviewDaysInput,
  TokenInterviewKitLinkInput,
  TokenInterviewNameInput,
  TokenInterviewScheduleInput,
  TokenInterviewScheduleStartTimeInput,
  TokenInterviewerNamesInput,
  TokenVideoMeetingLinkInput,
  TokenZoomMeetingInput,
  ZoomInfoInput,
} from 'src/generated/mloop-graphql';

import {
  CodingUrlBySlotId,
  CustomVideoMeetingLinkUrlBySlotId,
  VideoMeetingLinkBySlotId,
  ZoomInfo,
  ZoomInfoBySlotId,
} from 'src/store/slices/schedule-communications';

import { InterviewEvent, InterviewSchedule, RichInterviewer } from 'src/utils/api/getScheduleOptions';

import {
  getCustomVideoMeetingLinkInput as __getCustomVideoMeetingLinkInput,
  getVideoMeetingLinkInput as __getVideoMeetingLinkInput,
  getZoomInput as __getZoomInput,
  getZoomLinkInput as __getZoomLinkInput,
} from '../MeetingPlaceholderFiller';

/**
 * Returns an array of employee IDs for all interviewers associated with the given InterviewEvent.
 * This includes the main interviewers, shadow interviewers, and reverse shadow interviewers.
 * @param event The InterviewEvent to retrieve the interviewer IDs from.
 * @returns An array of employee IDs for all interviewers associated with the given InterviewEvent.
 */
export const getInterviewerIds = (event: InterviewEvent): string[] => {
  const interviewers = event.interviewers || [];
  return interviewers.map((interviewer) => interviewer.employeeId);
};

/**
 * Returns the appropriate coding link input based on the provided parameters.
 * @param slotId - The ID of the slot for which to retrieve the coding link input.
 * @param codingLinks - An object containing coding links by slot ID.
 * @param coderPadUrls - An object containing coderpad URLs by slot ID.
 * @param codeSignalUrls - An object containing codesignal URLs by slot ID.
 * @param codingUrlByInterviewEvent - An object containing coding URLs by interview event.
 * @returns The appropriate coding link input, or undefined if none is found.
 */
export const getCodingLinkInput = (
  slotId,
  codingUrlByInterviewEvent?: CodingUrlBySlotId
): CodeLinkInput | undefined => {
  if (!slotId) return undefined;

  if (codingUrlByInterviewEvent && !isEmpty(codingUrlByInterviewEvent)) {
    const codeLinkTypes = Object.keys(codingUrlByInterviewEvent);
    if (codeLinkTypes.length === 0 || !codingUrlByInterviewEvent[codeLinkTypes[0]]) return undefined;
    const codingLink = codingUrlByInterviewEvent[codeLinkTypes[0]][slotId];
    if (!codingLink) return undefined;
    return {
      type: codeLinkTypes[0] as unknown as CodeLinkType,
      url: codingLink,
    };
  }

  return undefined;
};

/**
 * Returns a ZoomInfoInput object based on the provided slotId and ZoomInfo objects.
 * If a zoomInfo object is not provided, the function will attempt to retrieve it from the zoomInfos object using the provided slotId.
 * @param slotId - The ID of the slot for which to retrieve the ZoomInfo object.
 * @param zoomInfo - An optional ZoomInfo object to use instead of retrieving one from the zoomInfos object.
 * @param zoomInfos - An optional object containing ZoomInfo objects keyed by slot ID.
 * @returns A ZoomInfoInput object or undefined if no ZoomInfo object could be found.
 */
export const getZoomLinkInput = (
  slotId,
  zoomInfo?: ZoomInfo,
  zoomInfos?: ZoomInfoBySlotId // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
ZoomInfoInput | undefined => {
  let zInfo = zoomInfo;
  if (slotId && zoomInfos && zoomInfos[slotId]) {
    zInfo = zoomInfos[slotId];
  }
  return __getZoomLinkInput(zInfo);
};

/**
 * Returns a `TokenVideoMeetingLinkInput` object based on the provided parameters.
 * @param slotId - The ID of the time slot.
 * @param customVideoMeetingLinkUrl - The custom video meeting link URL.
 * @param customVideoMeetingLinkUrls - An object containing custom video meeting link URLs by slot ID.
 * @returns A `TokenVideoMeetingLinkInput` object.
 */
export const getCustomVideoMeetingLinkInput = (
  slotId?: string,
  customVideoMeetingLinkUrl?: string,
  customVideoMeetingLinkUrls?: CustomVideoMeetingLinkUrlBySlotId // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
TokenVideoMeetingLinkInput => {
  let customUrl = customVideoMeetingLinkUrl;
  if (slotId && customVideoMeetingLinkUrls && customVideoMeetingLinkUrls[slotId]) {
    customUrl = customVideoMeetingLinkUrls[slotId];
  }
  return __getCustomVideoMeetingLinkInput(customUrl);
};

/**
 * Returns a TokenZoomMeetingInput object based on the provided slotId and ZoomInfo objects.
 * If a zoomInfo object is provided, it will be used to create the TokenZoomMeetingInput object.
 * If a zoomInfos object is provided and contains a ZoomInfo object for the provided slotId, that ZoomInfo object will be used instead.
 * @param slotId - The ID of the time slot for which to get the Zoom meeting input.
 * @param zoomInfo - An optional ZoomInfo object to use for creating the TokenZoomMeetingInput object.
 * @param zoomInfos - An optional object containing ZoomInfo objects keyed by slot ID.
 * @returns A TokenZoomMeetingInput object.
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getZoomInput = (slotId, zoomInfo?: ZoomInfo, zoomInfos?: ZoomInfoBySlotId): TokenZoomMeetingInput => {
  let zInfo = zoomInfo;
  if (slotId && zoomInfos && zoomInfos[slotId]) {
    zInfo = zoomInfos[slotId];
  }
  return __getZoomInput(zInfo);
};

export type MeetingType = {
  zoomInfo?: ZoomInfo;
  zoomInfos?: ZoomInfoBySlotId;
  customVideoMeetingLinkUrl?: string;
  customVideoMeetingLinkUrls?: CustomVideoMeetingLinkUrlBySlotId;
  workspaceVideoLink?: string | null;
  videoMeetingLinkUrls?: VideoMeetingLinkBySlotId;
};

/**
 * Returns a TokenVideoMeetingLinkInput object for a given slot ID and MeetingType configuration.
 * @param slotId - The ID of the slot to get the video meeting link for.
 * @param config - The MeetingType configuration object.
 * @returns A TokenVideoMeetingLinkInput object.
 */
export const getVideoMeetingLinkInput = (slotId, config: MeetingType): TokenVideoMeetingLinkInput => {
  const {
    zoomInfo,
    zoomInfos,
    customVideoMeetingLinkUrl,
    customVideoMeetingLinkUrls,
    videoMeetingLinkUrls,
    workspaceVideoLink,
  } = config;

  return __getVideoMeetingLinkInput(
    (zoomInfos && zoomInfos[slotId]) || zoomInfo,
    (customVideoMeetingLinkUrls && customVideoMeetingLinkUrls[slotId]) || customVideoMeetingLinkUrl,
    (videoMeetingLinkUrls && videoMeetingLinkUrls[slotId]) || workspaceVideoLink
  );
};

export const getInterviewNameInput = (event: InterviewEvent): TokenInterviewNameInput => {
  return {
    name: event.name,
    jobStageInterviewId: event.slotId,
  };
};

export const getInterviewerNamesInput = (event: InterviewEvent): TokenInterviewerNamesInput => {
  return {
    employeeIds: getInterviewerIds(event),
  };
};

export const getScheduleInterviewerNamesInput = (events: InterviewEvent[]): TokenInterviewerNamesInput => {
  return {
    employeeIds: uniq(events.map((event) => getInterviewerIds(event)).flat()),
  };
};

/**
 * Returns an object containing input data for retrieving interview kit links.
 * @param jobStageInterview - The job stage interview object.
 * @param applicationId - The ID of the application.
 * @param candidateId - The ID of the candidate.
 * @param atsInterviewDefinitionId - The ID of the ATS interview definition.
 * @param atsScheduledInterviewId - The ID of the ATS scheduled interview.
 * @returns An object containing input data for retrieving interview kit links.
 */
export const getKitLinksInput = (
  applicationId,
  candidateId,
  atsInterviewDefinitionId: string | undefined,
  atsScheduledInterviewId: string | undefined // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
TokenInterviewKitLinkInput => {
  return {
    applicationId,
    candidateId,
    atsInterviewDefinitionId,
    atsScheduledInterviewId,
  };
};

type EventInputType = {
  event: InterviewEvent;
  codingUrlByInterviewEvent?: CodingUrlBySlotId;
  skipMeetingLinkWithSchedule?: boolean;
} & MeetingType;

/**
 * Returns an EventInput object based on the provided EventInputType.
 * @param event - The event object.
 * @param codingLinks - An array of coding links.
 * @param coderPadUrls - An array of CoderPad URLs.
 * @param codeSignalUrls - An array of CodeSignal URLs.
 * @param codingUrlByInterviewEvent - A map of coding URLs by interview event.
 * @param zoomInfo - The Zoom meeting info.
 * @param zoomInfos - An array of Zoom meeting infos.
 * @param customVideoMeetingLinkUrl - The custom video meeting link URL.
 * @param customVideoMeetingLinkUrls - An array of custom video meeting link URLs.
 * @param workspaceVideoLink - The workspace video link.
 * @param skipMeetingLinkWithSchedule - A boolean indicating whether to skip the meeting link with schedule.
 * @returns An EventInput object.
 */
export const getEventInput = ({
  event,
  codingUrlByInterviewEvent,
  zoomInfo,
  zoomInfos,
  customVideoMeetingLinkUrl,
  customVideoMeetingLinkUrls,
  videoMeetingLinkUrls,
  workspaceVideoLink,
  skipMeetingLinkWithSchedule,
}: EventInputType): EventInput => {
  const customVideoMeetings = skipMeetingLinkWithSchedule
    ? {}
    : getCustomVideoMeetingLinkInput(event.slotId, customVideoMeetingLinkUrl, customVideoMeetingLinkUrls);

  let workspaceVideoLinkUrl: string | undefined;
  if (!skipMeetingLinkWithSchedule) {
    workspaceVideoLinkUrl =
      videoMeetingLinkUrls && event.slotId ? videoMeetingLinkUrls[event.slotId] : workspaceVideoLink || undefined;
  }

  const eventInput: EventInput = {
    name: event.name || '',
    timeRange: {
      startAt: event.startAt,
      endAt: event.endAt,
    },
    isBreak: !!event.isBreak,
    interviewerIds: getInterviewerIds(event),
    interviewers:
      event.interviewers?.map((interviewer) => {
        return { employeeId: interviewer.employeeId, role: interviewer.role || InterviewerRole.Interviewer };
      }) || [],
    codeLink: getCodingLinkInput(event.slotId, codingUrlByInterviewEvent),
    zoomInfo: skipMeetingLinkWithSchedule ? undefined : getZoomLinkInput(event.slotId, zoomInfo, zoomInfos),
    // eslint-disable-next-line no-nested-ternary
    workspaceVideoLinkUrl,
    isHiddenFromCandidate: event.isHiddenFromCandidate,
    ...customVideoMeetings,
  };
  return eventInput;
};

type EventsInputType = {
  schedule: InterviewSchedule;
  codingUrlByInterviewEvent?: CodingUrlBySlotId;
  skipMeetingLinkWithSchedule?: boolean;
} & MeetingType;

/**
 * Returns an array of EventInput objects based on the provided EventsInputType object.
 * @param {EventsInputType} input - The input object containing the necessary data to generate the EventInput objects.
 * @returns {EventInput[]} - An array of EventInput objects.
 */
export const getEventsInput = ({
  schedule,
  codingUrlByInterviewEvent,
  zoomInfo,
  zoomInfos,
  customVideoMeetingLinkUrl,
  customVideoMeetingLinkUrls,
  videoMeetingLinkUrls,
  workspaceVideoLink,
  skipMeetingLinkWithSchedule,
}: EventsInputType): EventInput[] => {
  if (!schedule.events?.length) return [];
  return schedule.events.map((event) => {
    return getEventInput({
      event,
      codingUrlByInterviewEvent,
      zoomInfo,
      zoomInfos,
      customVideoMeetingLinkUrl,
      customVideoMeetingLinkUrls,
      videoMeetingLinkUrls,
      workspaceVideoLink,
      skipMeetingLinkWithSchedule,
    });
  });
};

/**
 * Returns an object containing the events and timezone for a token interview days input.
 * @param events - An array of EventInput objects representing the events for the token interview.
 * @param timezone - An optional string representing the timezone for the token interview.
 * @returns An object containing the events and timezone for a token interview days input.
 */
export const getDaysInput = (events: EventInput[], timezone?: string): TokenInterviewDaysInput => {
  return {
    events,
    timezone,
  };
};

/**
 * Returns the most frequent timezone from an array of RichInterviewer objects.
 * If an interviewer's timezone is not defined, the provided timezone is used as a fallback.
 * @param arr An array of RichInterviewer objects.
 * @param timezone A string representing the fallback timezone to use if an interviewer's timezone is not defined.
 * @returns A string representing the most frequent timezone.
 */
const mostFrequentTimezone = (arr: RichInterviewer[], timezone: string) => {
  const entries = Object.entries(
    arr.reduce((a: { [key: string]: number }, v: RichInterviewer) => {
      const tz = v.employee.timezone || timezone;
      a[tz] = a[tz] ? a[tz] + 1 : 1;
      return a;
    }, {})
  );
  const result = entries.reverse().reduce((a, v) => (v[1] >= a[1] ? v : a), [timezone, 0])[0];
  return result;
};

/**
 * Returns an object with the start time and timezone for an interview event.
 * @param event - The interview event.
 * @param timezone - The timezone to use. If not provided, the most frequent timezone of the interviewers will be used.
 * @returns An object with the start time and timezone.
 */
export const getScheduleStartTimeInput = (
  event: InterviewEvent,
  timezone?: string
): TokenInterviewScheduleStartTimeInput => {
  return {
    startAt: event.startAt,
    timezone: timezone || mostFrequentTimezone(event.interviewers || [], timezone || getLocalTimezone()),
  };
};

/**
 * Returns an object containing the input data needed to schedule an interview for a candidate.
 * @param events An array of EventInput objects representing the events to schedule.
 * @param candidateId The ID of the candidate to schedule the interview for.
 * @param timezone (Optional) The timezone to use for the scheduled events.
 * @returns A TokenInterviewScheduleInput object containing the input data needed to schedule an interview.
 */
export const getInternalInterviewScheduleInput = (
  events: EventInput[],
  candidateId: string,
  timezone?: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
TokenInterviewScheduleInput => {
  return {
    candidateId,
    timezone,
    events,
    linkInterviewerName: false,
  };
};

/**
 * Returns an object containing the input data needed to schedule an interview for a candidate.
 * @param events An array of EventInput objects representing the events to schedule.
 * @param candidateId The ID of the candidate for whom the interview is being scheduled.
 * @param timezone (Optional) The timezone in which the interview will take place.
 * @returns A TokenInterviewScheduleInput object containing the input data needed to schedule the interview.
 */
export const getScheduleInput = (
  events: EventInput[],
  candidateId: string,
  timezone?: string,
  applicationId?: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
TokenInterviewScheduleInput => {
  return {
    events,
    candidateId,
    timezone,
    linkInterviewerName: true,
    applicationId,
  };
};
