import { SvgIcon, SvgIconProps } from '@mui/material';

export const MoonWithArchedArrowIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;

  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5557 4.86504L17.9123 4.28361C18.231 4.14705 18.6005 4.21588 18.8486 4.45801C19.0968 4.70015 19.1746 5.0679 19.0458 5.38978L17.903 8.24693C17.7272 8.68646 17.2283 8.90024 16.7888 8.72443L13.9317 7.58157C13.6098 7.45282 13.3971 7.14284 13.3929 6.79619C13.3887 6.44953 13.5937 6.13446 13.9123 5.9979L15.1553 5.4652C14.0285 3.82511 12.1392 2.75001 9.99998 2.75001C7.72737 2.75001 5.73722 3.96251 4.64238 5.77949C4.52495 5.97438 4.3664 6.32755 4.22564 6.66905C4.15837 6.83223 4.09994 6.98091 4.05832 7.08882C4.03756 7.14267 4.02109 7.18608 4.00991 7.21575L3.99728 7.24945L3.99419 7.25774L3.9935 7.2596L3.9934 7.25989C3.84986 7.64844 3.41853 7.84707 3.02998 7.70354C2.64143 7.56001 2.4428 7.12867 2.58633 6.74012L3.28986 7.00001L2.58636 6.74005L2.58639 6.73994L2.5865 6.73967L2.58682 6.7388L2.5879 6.73589L2.59179 6.72542L2.60619 6.68702C2.61859 6.6541 2.63646 6.60699 2.6588 6.54908C2.70337 6.4335 2.76613 6.27378 2.83883 6.0974C2.978 5.75979 3.17452 5.30916 3.3576 5.00533C4.71255 2.75666 7.17995 1.25001 9.99998 1.25001C12.7606 1.25001 15.1829 2.69299 16.5557 4.86504ZM10.0987 6.53978C10.2786 6.7802 10.302 7.10345 10.1584 7.36721C9.82761 7.97483 9.63938 8.67148 9.63938 9.41439C9.63938 11.7832 11.5596 13.7034 13.9284 13.7034C14.2418 13.7034 14.5465 13.6699 14.8394 13.6066C15.1329 13.5432 15.4365 13.6567 15.6165 13.8971C15.7964 14.1375 15.8197 14.4608 15.6762 14.7245C14.6901 16.5359 12.768 17.768 10.557 17.768C7.33994 17.768 4.73197 15.1601 4.73197 11.943C4.73197 9.1491 6.6981 6.81625 9.32161 6.24932C9.61515 6.18588 9.91873 6.29936 10.0987 6.53978ZM8.19871 8.35969C7.03549 9.12699 6.26801 10.4458 6.26801 11.943C6.26801 14.3117 8.18828 16.232 10.557 16.232C11.6169 16.232 12.5875 15.8477 13.3365 15.2098C10.3974 14.9132 8.10334 12.4317 8.10334 9.41439C8.10334 9.0545 8.13606 8.70196 8.19871 8.35969Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
