import React from 'react';
import type { FC } from 'react';

import { ModernLoopLoader } from '@modernloop/shared/helper-components';
import { Box } from '@mui/material';

const SlashScreen: FC = () => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: '100%',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        zIndex: 2000,
      }}
    >
      <ModernLoopLoader />
    </Box>
  );
};

export default SlashScreen;
