/* eslint-disable max-lines */
import React, { CSSProperties, ElementType, ReactNode, forwardRef } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import MUIButton, { ButtonProps as MUIButtonProps } from '@material-ui/core/Button';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, darken, lighten, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { LocationDescriptor } from 'history';

import * as MiscStyles from 'src/components/constants/MiscStyles';
import * as Typography from 'src/components/constants/Typography';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/theme/type';

import ButtonGroupContext from '../button-group/ButtonGroupContext';
import Tooltip, { TooltipProps } from '../tooltip';

export interface Props extends BaseProps {
  /**
   * If the button should take focus on render
   */
  fullWidth?: boolean;

  /**
   * If the button should take focus on render
   */
  autoFocus?: boolean;

  /**
   * The text that will be shown on button
   */
  label?: string | JSX.Element | React.ReactNode;

  /**
   * If true, the button will be disabled.
   */
  disabled?: boolean;

  /**
   * Used in button group, to denote a selected option.
   */
  isActive?: boolean;

  /**
   * The size of the button
   */
  size?: 'medium' | 'small';

  /**
   * The variant to use
   */
  variant: 'contained' | 'outlined' | 'unstyled' | 'link';

  /**
   * The color to use
   */
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'default';

  /**
   * Element placed after the children.
   */
  endIcon?: React.ReactNode;

  /**
   * Element placed before the children.
   */
  startIcon?: React.ReactNode;

  /**
   * Click handler
   */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;

  /**
   * Focus handler
   */
  onFocus?: () => void;

  /**
   * Focus handler
   */
  onBlur?: () => void;

  type?: MUIButtonProps['type'];

  /**
   * Tooltip text or element
   */
  tooltip?: TooltipProps['tooltip'];

  /**
   * Optional className param
   */
  style?: CSSProperties;

  /**
   * Optional className param
   */
  className?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ElementType<any>;

  to?: LocationDescriptor;

  /**
   * Children Prop
   */
  children?: ReactNode;

  placement?: TooltipProps['placement'];
}

const VARIANT_MAP: { [key: string]: MUIButtonProps['variant'] } = {
  contained: 'contained',
  outlined: 'outlined',
  unstyled: 'text',
  link: 'text',
  default: 'outlined',
};

const useGroupStyles = makeStyles((theme: Theme) =>
  createStyles({
    default: {
      borderRadius: '0px',
      '&:first-child': {
        borderRadius: '6px 0 0 6px',
      },
      '&:nth-child(even)': {
        borderLeftWidth: '0',
        borderRightWidth: '0',
        borderRadius: '0',
      },
      '&:last-child': {
        borderRadius: '0 6px 6px 0',
        borderRightWidth: '1px',
      },
    },
    buttonLabel: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    active: (props: Props) => {
      const color = props.color || 'default';
      if (props.variant === 'outlined') {
        if (color === 'default') {
          return {
            background: 'rgba(71, 196, 196, 0.1) !important',
            borderColor: '#06606A !important',
            color: '#06606A !important',
            borderWidth: '1px !important',
          };
        }
        return {
          backgroundColor: `${lighten(theme.palette[color].main, 0.9)} !important`,
          borderColor: `${theme.palette[color].dark} !important`,
          color: `${theme.palette[color].dark} !important`,
          borderWidth: '1px !important',
        };
      }
      if (props.variant === 'contained') {
        if (color === 'default') {
          return {
            // TODO: make a new color "neutral"
            backgroundColor: `${darken('#e0e0e0', 0.25)} !important`,
          };
        }
        return {
          backgroundColor: `${darken(theme.palette[color].main, 0.25)} !important`,
        };
      }
      return {};
    },
  })
);

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'inherit',
      fontSize: (props: Props) => {
        switch (props.size) {
          case 'medium':
            return Typography.STD_BUTTON_SIZE;
          case 'small':
            return Typography.SMALL_BUTTON_SIZE;
          default:
            return 0;
        }
      },
      lineHeight: (props: Props) => {
        switch (props.size) {
          case 'medium':
            return Typography.STD_BUTTON_LINE_HEIGHT;
          case 'small':
            return Typography.SMALL_BUTTON_LINE_HEIGHT;
          default:
            return 0;
        }
      },
      height: (props: Props) => {
        switch (props.size) {
          case 'medium':
            return Typography.STD_BUTTON_HEIGHT;
          case 'small':
            return Typography.SMALL_BUTTON_HEIGHT;
          default:
            return 0;
        }
      },
      borderRadius: (props: Props) => {
        switch (props.size) {
          case 'medium':
            return Typography.STD_BUTTON_BORDER_RADIUS;
          case 'small':
            return Typography.SMALL_BUTTON_BORDER_RADIUS;
          default:
            return 0;
        }
      },
      paddingLeft: `${MiscStyles.STD_BUTTON_HORIZONTAL_PADDING}`,
      paddingRight: `${MiscStyles.STD_BUTTON_HORIZONTAL_PADDING}`,
      '&:focus': {
        border: `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.palette.action.focus}`,
        boxShadow: `0 0 0 1px ${theme.palette.action.focus}`,
      },
    },
    contained: (props: Props) => {
      const color = props.color || 'default';
      if (color === 'default') {
        return {
          color: theme.grey.alpha.max,
          border: `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.palette.common.transparent}`,
          backgroundColor: `${theme.grey.solid.min} !important`,
          '&:hover': props.disabled
            ? {
                color: theme.grey.alpha.low,
                boxShadow: 'none',
                border: `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.grey.alpha.min}`,
              }
            : {
                color: theme.palette.primary.dark,
                backgroundColor: theme.palette.common.white,
                boxShadow: theme.shadows[1],
                border: `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.grey.alpha.low}`,
              },
        };
      }
      if (color === 'secondary') {
        return {
          color: theme.palette[color].contrastText,
          border: props.disabled
            ? `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.grey.solid.min}`
            : `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.grey.alpha.low}`,
          backgroundColor: props.disabled ? `${theme.grey.solid.min} !important` : theme.palette[color].main,
          '&:hover': props.disabled
            ? {
                color: theme.grey.alpha.low,
                boxShadow: 'none',
                border: `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.grey.alpha.min}`,
              }
            : {
                color: theme.palette[color].contrastText,
                backgroundColor: theme.palette.secondary.dark,
                boxShadow: theme.shadows[1],
                border: `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.grey.alpha.low}`,
              },
        };
      }
      return {
        backgroundColor: props.disabled ? `${theme.grey.solid.min} !important` : theme.palette[color].main,
        border: `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.grey.alpha.low}`,
        color: theme.palette[color].contrastText,
        '&:hover': {
          backgroundColor: theme.palette[color].dark,
          boxShadow: theme.shadows[1],
        },
      };
    },
    outlined: (props: Props) => {
      const color = props.color || 'default';
      if (color === 'default') {
        return {
          backgroundColor: theme.palette.background.default,
          border: `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.grey.alpha.low}`,
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[1],
            backgroundColor: theme.palette.background.default,
          },
        };
      }
      return {
        backgroundColor: theme.palette.background.default,
        border: `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.grey.alpha.low}`,
        color: theme.palette[color].main,
        '&:hover': {
          color: theme.palette[color].dark,
          borderColor: theme.grey.alpha.mid,
          boxShadow: theme.shadows[1],
          backgroundColor: theme.palette.background.default,
        },
      };
    },
    text: (props: Props) => {
      let color = theme.palette.link;
      if (props.variant === 'unstyled' && props.color !== 'info') {
        color = theme.palette.text.secondary;
      }
      if (props.variant === 'link' && props.color === 'error') {
        color = theme.palette.error.main;
      }

      return {
        border: `${MiscStyles.DEFAULT_BORDER_WIDTH} ${MiscStyles.DEFAULT_BORDER_STYLE} ${theme.palette.common.transparent}`,
        color,
        '&:hover': {
          backgroundColor: theme.grey.alpha.min,
        },
      };
    },
    startIcon: {
      '& > *:first-child': {
        fontSize: 20,
      },
      '&$iconSizeSmall': {
        marginLeft: '-4px',
      },
    },
    endIcon: {
      '& > *:first-child': {
        fontSize: 20,
      },
      '&$iconSizeSmall': {
        marginRight: '-4px',
      },
    },
    // Don't remove - this allows the above overrides on
    iconSizeSmall: {},
    disabled: {
      color: theme.palette.text.disabled,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  })
);

/**
 * @deprecated - Use `Button` from `@mui/material` instead
 */
const Button: React.ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  props,

  ref: React.Ref<HTMLButtonElement>
): JSX.Element => {
  const {
    autoFocus,
    color = 'default',
    className,
    component,
    disabled,
    endIcon,
    fullWidth,
    isActive,
    label,
    onClick,
    size = 'medium',
    startIcon,
    style,
    to,
    variant = 'outlined',
    tooltip,
    type,
    dataTestId,
    onFocus,
    onBlur,
    children,
    placement,
  } = props;
  const classes = useStyle({
    ...props,
    size,
    variant,
    color,
  });
  const groupClasses = useGroupStyles(props);
  const conditionalProps = onClick
    ? { onClick }
    : {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line max-lines
        component,
        to,
      };

  const button = (
    <ButtonGroupContext.Consumer>
      {(group) => {
        const buttonLabel = React.isValidElement(label) ? (
          label
        ) : (
          <span className={clsx(groupClasses.buttonLabel)}>{label}</span>
        );

        return (
          <MUIButton
            style={style}
            fullWidth={fullWidth}
            autoFocus={autoFocus}
            onFocus={() => {
              if (onFocus) {
                onFocus();
              }
            }}
            onBlur={() => {
              if (onBlur) {
                onBlur();
              }
            }}
            classes={classes}
            className={clsx(className, {
              [groupClasses.default]: !!group,
              [groupClasses.active]: isActive,
            })}
            disabled={disabled}
            disableFocusRipple
            size={size ?? 'medium'}
            endIcon={endIcon}
            startIcon={startIcon}
            ref={ref}
            type={type}
            variant={VARIANT_MAP[variant]}
            data-testid={dataTestId}
            {...conditionalProps}
          >
            {buttonLabel}
            {children}
          </MUIButton>
        );
      }}
    </ButtonGroupContext.Consumer>
  );

  if (tooltip) {
    return (
      <Tooltip tooltip={tooltip} placement={placement || 'top-start'}>
        {button}
      </Tooltip>
    );
  }

  return button;
};

/**
 * @deprecated - Use `Button` from `@mui/material` instead
 */
export default forwardRef<HTMLButtonElement, Props>(Button);
