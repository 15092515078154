import React from 'react';

import BaseIcon, { Props } from './Icon';

const DirectoryIconFilled = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 4L2.25 5.25H1.5C1.08579 5.25 0.75 5.58578 0.75 6C0.75 6.41421 1.08579 6.75 1.5 6.75H2.25001L2.25001 9.25H1.5C1.08579 9.25 0.75 9.58579 0.75 10C0.75 10.4142 1.08579 10.75 1.5 10.75H2.25001L2.25002 13.25H1.5C1.08579 13.25 0.75 13.5858 0.75 14C0.75 14.4142 1.08579 14.75 1.5 14.75H2.25002L2.25002 16C2.25002 17.5188 3.48124 18.75 5.00002 18.75L15 18.75C16.5188 18.75 17.75 17.5188 17.75 16L17.75 3.99999C17.75 2.48121 16.5188 1.24999 15 1.24999L5 1.25C3.48122 1.25 2.25 2.48122 2.25 4ZM8 7.5C8 6.39543 8.89543 5.5 10 5.5C11.1046 5.5 12 6.39543 12 7.5C12 8.60457 11.1046 9.5 10 9.5C8.89543 9.5 8 8.60457 8 7.5ZM6.66014 13.0165C7.60085 10.3278 12.3991 10.3278 13.3399 13.0165C13.5223 13.5378 13.0523 14 12.5 14L7.5 14C6.94771 14 6.47775 13.5378 6.66014 13.0165Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DirectoryIconFilled;
