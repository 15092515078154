import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import { SxProps } from '@mui/material';

import { BaseProps } from 'src/components/types';

import { Theme } from 'src/themeMui5/type';

import Avatar from '../Avatar';

import Chip from '.';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type EmployeeChipProps = BaseProps & {
  name: string;
  photoSrc: string | undefined;
  variant: 'default' | 'outlined';
  className?: string;
  sx?: SxProps<Theme>;
  tagProps?: ReturnType<AutocompleteGetTagProps>;
};

const EmployeeChip: React.FC<EmployeeChipProps> = ({
  className,
  sx,
  name,
  photoSrc,
  variant,
  tagProps,
  dataTestId,
}) => {
  return (
    <Chip
      sx={sx}
      label={name}
      variant={variant}
      tagProps={tagProps}
      className={className}
      dataTestId={dataTestId}
      avatar={<Avatar src={photoSrc} alt={name} size="20px" />}
    />
  );
};

export default EmployeeChip;
