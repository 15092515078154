import { SvgIcon, SvgIconProps } from './SvgIcon';

export const EventIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00003 1.25C7.41424 1.25 7.75003 1.58579 7.75003 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48121 17.75 6L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H5.00004C3.48126 17.75 2.25005 16.5188 2.25004 15L2.25003 6C2.25003 4.48122 3.48125 3.25 5.00003 3.25H6.25003V2C6.25003 1.58579 6.58582 1.25 7.00003 1.25ZM7.00003 4.75H13H15C15.6904 4.75 16.25 5.30964 16.25 6L16.25 15C16.25 15.6904 15.6904 16.25 15 16.25H5.00004C4.30969 16.25 3.75005 15.6904 3.75004 15L3.75003 6C3.75003 5.30965 4.30967 4.75 5.00003 4.75H7.00003ZM6.00003 7H14C14.5523 7 15 7.44772 15 8V10C15 10.5523 14.5523 11 14 11H6.00003C5.44774 11 5.00003 10.5523 5.00003 10V8C5.00003 7.44772 5.44774 7 6.00003 7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
