/* eslint-disable modernloop/restrict-imports.cjs */
import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';

import GreenhouseCreateCandidatePermissionDialog from 'src/views-new/JobDetailsPage/SourcingLinks/AtsCreateCandidatePermissionDialogs/GreenhouseCreateCandidatePermissionDialog';

import AtsSevice, { TScorecardSortKey } from './AtsService';

/* *
 * Greenhouse ATS service
 * Imp: Never have atsType set in ATS service
 * */
class GreenhouseAtsService implements AtsSevice {
  readonly name = 'Greenhouse';

  readonly logoUrl = `/static/images/integrations/greenhouse.png`;

  readonly helpCenterUrl = ZenDeskHelpCenterUrl.GREENHOUSE_INTEGRATION;

  readonly scorecardLabelPlural = 'scorecards';

  readonly isSourcingLinkEnabled = true;

  readonly isAtsUserSelectEnabled = false;

  readonly isAtsImportAvailabilityEnabled = true;

  readonly skipInterviewKitLinkTokenValidation = false;

  readonly scorecardGroupByKey = null;

  readonly scorecardSortKey: TScorecardSortKey = 'index';

  readonly showEstimatedMinutes = true;

  readonly createCandidatePermissionDialog = GreenhouseCreateCandidatePermissionDialog;

  readonly showRequisitionId = true;
}

export default new GreenhouseAtsService();
