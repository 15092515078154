import React, { FC } from 'react';

import { CopyTextField } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { CrossIcon } from '@modernloop/shared/icons';
import { Alert, Box, Divider, IconButton, LinearProgress, Stack } from '@mui/material';

import { useZoomMeetingCreateMutation } from 'src/generated/mloop-graphql';

import EmployeeChip from 'src/components/chip/EmployeeChip';
import Label from 'src/components/label';

import ZoomUserSelect from 'src/entities/Select/ZoomUserSelect';
import { ZoomUserDataWithCategory } from 'src/entities/Select/ZoomUserSelect/types';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { setScheduleContentStepWaiting } from 'src/slices/scheduling';

import {
  clearZoomUserInfoForSlotId,
  updateVideoMeetingHostEmployeeIdForSlotId,
  updateZoomInfoForSlotId,
  updateZoomUserIdForSlotId,
} from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { getZoomInfoBySlotId, getZoomUserIdBySlotId } from 'src/store/selectors/schedule-communications';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import { InterviewEvent } from 'src/utils/api/getScheduleOptions';
import { ZoomDialInInfo } from 'src/utils/api/types';
import logError from 'src/utils/logError';
import { renderTimeRangeUnix } from 'src/utils/renderTimeRange';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { useDispatch, useSelector } from 'src/store';

import usePopulateZoomInterviewMeetingLocation from './usePopulateZoomInterviewMeetingLocation';

interface Props {
  interview: InterviewEvent;
}

const ZoomContentRow: FC<Props> = ({ interview }) => {
  const { scheduling } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { selectedScheduleId } = scheduling;

  const scheduleFlowData = useScheduleFlowData();
  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const { candidateTimezone } = scheduleFlowData;

  const { id, slotId, startAt, endAt } = interview;
  const zoomUserID = useSelector((state) => getZoomUserIdBySlotId(state, selectedScheduleId || '', slotId || ''));
  const zoomMeetingJoinURL = useSelector((state) =>
    getZoomInfoBySlotId(state, selectedScheduleId || '', slotId || '')
  )?.joinURL;

  const [zoomMeetingCreateMutation, { loading, error }] = useZoomMeetingCreateMutation();
  const populateZoomInterviewMeetingLocation = usePopulateZoomInterviewMeetingLocation({
    scheduleId: selectedScheduleId || '',
  });

  if (!selectedScheduleId) return null;

  const handleUserSelect = async (value: ZoomUserDataWithCategory) => {
    const newZoomUserId = value.zoomUserId;

    dispatch(setScheduleContentStepWaiting(true));
    dispatch(updateZoomUserIdForSlotId(selectedScheduleId, slotId || '', newZoomUserId));

    if (slotId && value.employeeId) {
      dispatch(
        updateVideoMeetingHostEmployeeIdForSlotId({
          scheduleId: selectedScheduleId,
          slotId,
          videoMeetingHostEmployeeId: value.employeeId,
        })
      );
    }

    if (newZoomUserId) {
      if (scheduleLocationEnabled) {
        populateZoomInterviewMeetingLocation({
          zoomUserId: newZoomUserId,
          hostEmployeeId: value.employeeId || undefined,
          slotId: interview.slotId,
        });
      } else {
        await zoomMeetingCreateMutation({
          variables: {
            input: {
              zoomUserId: newZoomUserId,
              timeRange: {
                startAt: startAt ? new Date(startAt).toISOString() : new Date().toISOString(),
                endAt: endAt ? new Date(endAt).toISOString() : new Date().toISOString(),
              },
              title: scheduling.candidate?.fullName,
            },
          },
        }).then(async (result) => {
          const { data } = result;
          if (!data?.zoomMeetingCreate) return;
          const { zoomMeetingCreate: zoomData } = data;

          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line promise/always-return
          try {
            dispatch(
              updateZoomInfoForSlotId(selectedScheduleId, slotId || '', {
                joinURL: zoomData.joinUrl || '',
                meetingID: zoomData.meetingId,
                password: zoomData.password || '',
                pstnPassword: zoomData.pstnPassword as unknown as number,
                dialInfo: zoomData.dialInfo as ZoomDialInInfo[],
              })
            );
            dispatch(
              addInterviewScheduleUpdate({
                type: 'ScheduleUpdateVideoUrl',
                updateType: ScheduleUpdateType.EDIT,
                scheduleId: selectedScheduleId,
                applicationStageInterviewId: id,
              })
            );
          } catch (err) {
            logError(err);
          }
        });
      }
    }
  };

  return (
    <Stack data-testid="communications-zoom-multi-row">
      <Label variant="captions" fontWeight={600}>
        {interview.name}
      </Label>

      <Label variant="captions">
        {renderTimeRangeUnix(
          candidateTimezone,
          new Date(interview.startAt).getTime(),
          new Date(interview.endAt).getTime()
        )}
        {interview.interviewers.map((i) => (
          <EmployeeChip
            key={i.employeeId}
            dataTestId="zoom-multi-employee-chip"
            name={i?.employee?.fullName || ''}
            photoSrc={i?.employee?.slackImageUrl || undefined}
            variant="default"
          />
        ))}
      </Label>

      <Stack spacing={1} direction="column" justifyContent="center">
        <ZoomUserSelect
          key={zoomUserID}
          onChange={handleUserSelect}
          value={zoomUserID ?? undefined}
          panelInterviewerIds={interview.interviewers.map((interviewer) => interviewer.employeeId)}
        />
        {loading && (
          <LinearProgress
            sx={{ width: '100%', marginTop: 12, marginBottom: 12 }}
            data-testid="communications-zoom-multi-row-linear-progress"
          />
        )}
        {!scheduleLocationEnabled && zoomMeetingJoinURL && (
          <Box>
            <CopyTextField dataTestId="communications-zoom-multi-row-zoom-url" value={zoomMeetingJoinURL} />
          </Box>
        )}
        {scheduleLocationEnabled && zoomMeetingJoinURL && (
          <Stack direction="row" mt={2} gap={1} data-testid="communications-zoom-single-join-url">
            <CopyTextField dataTestId="communications-zoom-multi-row-zoom-url" value={zoomMeetingJoinURL} />
            <Divider flexItem orientation="vertical" />
            <IconButton
              onClick={() => {
                if (slotId) dispatch(clearZoomUserInfoForSlotId(selectedScheduleId, slotId));
              }}
            >
              <CrossIcon />
            </IconButton>
          </Stack>
        )}
        {error && (
          <Alert severity="error">
            {error.name} - {error.message}
          </Alert>
        )}
      </Stack>
    </Stack>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const ZoomLocationContent: FC = () => {
  const scheduleId = useSelector((state) => state.scheduling.selectedScheduleId);
  const schedule = useScheduleWithoutBreaks(scheduleId);

  if (!schedule) return null;

  return (
    <Stack direction="column" spacing={2}>
      {schedule?.events?.map((event) => {
        return <ZoomContentRow key={event.id} interview={event} />;
      })}
    </Stack>
  );
};

export default ZoomLocationContent;
