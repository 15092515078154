/**
 * Used to check if a string from the Quill Editor is empty
 * @param (string) text - Message entered with HTML inside
 * @returns (boolean) - true if the string is empty, false otherwise.
 * works for any function
 */
const isQuillTextEmpty = (text: string): boolean => {
  if (!text) {
    return true;
  }
  return text.replace(/<(.|\n)*?>/g, '').replace(/&nbsp;/g, '') === '';
};

export default isQuillTextEmpty;
