import React, { useMemo } from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskMeetingRoomRsvp_ActivityLogFragment, ResponseStatus } from 'src/generated/mloop-graphql';

import { MeetingRoomIcon } from 'src/components/icons';
import { FCWithFragments } from 'src/components/types';

import IconWithRsvp from 'src/entities/common/IconWithRsvp';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogContent from '../../Helpers/ActivityLogContent';

interface Fragments {
  activityLog: ActivityLogTaskMeetingRoomRsvp_ActivityLogFragment;
}

const ActivityLogTaskMeetingRoomRsvp: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (!details || details.__typename !== 'ActivityLogTaskMeetingRoomDetails') {
    throw new Error('ActivityLogTaskMeetingRoomRsvp: details is not ActivityLogTaskMeetingRoomDetails');
  }

  const { interviewName, comment, responseStatus, room } = details;
  const actionLabel = useMemo(() => {
    switch (responseStatus) {
      case ResponseStatus.Accepted:
        return `accepted ${interviewName}`;
      case ResponseStatus.Declined:
        return `declined ${interviewName}`;
      default:
        return '';
    }
  }, [interviewName, responseStatus]);

  return (
    <ActivityLogBase
      actorName={room?.name ?? undefined}
      avatar={
        <IconWithRsvp
          responseStatus={responseStatus}
          interviewerRsvpIconDataTestId={`activity-log-task-meeting-room-rsvp-${responseStatus}`}
          icon={<MeetingRoomIcon fontSize={20} />}
        />
      }
      activityLog={activityLog}
      actionLabel={actionLabel}
    >
      <ActivityLogContent content={comment} />
    </ActivityLogBase>
  );
};

ActivityLogTaskMeetingRoomRsvp.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskMeetingRoomRsvp_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogTaskMeetingRoomDetails {
          __typename

          responseStatus
          comment
          interviewName
          room {
            id
            name
          }
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskMeetingRoomRsvp;
