import { UnbrandedLoader } from './UnbrandedLoader';
import { Box } from '@mui/material';
import type { FC } from 'react';

export const UnbrandedLoadingScreen: FC = () => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      minHeight="100%"
      p={3}
    >
      <UnbrandedLoader />
    </Box>
  );
};
