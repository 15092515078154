import React, { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/styles';

import { ResponseStatus } from 'src/generated/mloop-graphql';

import { AvatarProps } from 'src/components/Avatar';

import { Theme } from 'src/theme';

import AvatarWithBadge from './AvatarWithBadge';
import InterviewerRsvpIcon from './InterviewerRsvpIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: (props: { outlineOffset?: string }) => ({
      outline: `1px solid ${theme.palette.border}`,
      outlineOffset: props.outlineOffset ?? '-1px',
    }),
    avatarDeclinedState: (props: { outlineOffset?: string }) => ({
      outline: `1px solid ${theme.palette.error.main}`,
      outlineOffset: props.outlineOffset ?? '-1px',
    }),
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface AvatarWithRsvpProps {
  responseStatus: ResponseStatus | undefined | null;
  dataTestId?: string;
  src?: string;
  outlineOffset?: string;
  alt: string;
  interviewerRsvpIconDataTestId?: string;
  children?: JSX.Element | string;
  size?: AvatarProps['size'];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const AvatarWithRsvp: FC<AvatarWithRsvpProps> = ({
  dataTestId,
  interviewerRsvpIconDataTestId,
  outlineOffset,
  responseStatus,
  src,
  alt,
  children,
  size,
}) => {
  const classes = useStyles({ outlineOffset });

  return (
    <AvatarWithBadge
      dataTestId={dataTestId}
      size={size}
      icon={<InterviewerRsvpIcon dataTestId={interviewerRsvpIconDataTestId} responseStatus={responseStatus} />}
      src={src}
      alt={alt}
      avatarProps={{
        className: responseStatus === ResponseStatus.Declined ? classes.avatarDeclinedState : classes.avatar,
      }}
    >
      {children}
    </AvatarWithBadge>
  );
};

export default AvatarWithRsvp;
