import React, { FC, useRef, useState } from 'react';

import { IsoTimestamp, assertIsoTimestamp, formatTZ, getLocalTimezone } from '@modernloop/shared/datetime';
import { DateIcon } from '@modernloop/shared/icons';
import { FormHelperText, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { getYear } from 'date-fns';

import { TrainingStatus } from 'src/generated/mloop-graphql';

import DateTimePickerPopover from 'src/components/date-time-picker/DatePickerPopoverV2';

type Props = {
  selectedDate: IsoTimestamp;
  setSelectedDate: (date: IsoTimestamp) => void;
  selectedRole: string;
  setSelectedRole: (role: TrainingStatus) => void;
  roleOptions: { key: string; label: string }[];
  isDateDisabled?: boolean;
};

const ModalBody: FC<Props> = ({
  selectedDate,
  selectedRole,
  setSelectedDate,
  setSelectedRole,
  roleOptions,
  isDateDisabled,
}) => {
  const timezone = getLocalTimezone();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const dateAnchorEl = useRef<HTMLInputElement>(null);

  return (
    <Stack spacing={2.5}>
      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle1">When did the interview happen?</Typography>
        <TextField
          ref={dateAnchorEl}
          disabled={isDateDisabled}
          variant="outlined"
          value={
            selectedDate && getYear(new Date(selectedDate)) !== 1970
              ? formatTZ(selectedDate, timezone, 'MMM d, yyyy')
              : ''
          }
          sx={{
            pointerEvents: isDateDisabled ? 'none' : undefined,
          }}
          onClick={() => setShowDatePicker(true)}
          InputProps={{
            startAdornment: <DateIcon />,
          }}
        />
        {showDatePicker && (
          <DateTimePickerPopover
            open
            disableFuture
            disablePast={false}
            timezone={timezone}
            dateAnchorEl={dateAnchorEl}
            utcDate={
              selectedDate && getYear(new Date(selectedDate)) !== 1970
                ? selectedDate
                : assertIsoTimestamp(new Date().toISOString())
            }
            onChange={setSelectedDate}
            onClose={() => setShowDatePicker(false)}
          />
        )}
        {isDateDisabled && <FormHelperText>Update the task to change this interview date</FormHelperText>}{' '}
      </Stack>

      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle1">Role</Typography>
        <Select
          fullWidth
          onChange={(event) => setSelectedRole(event.target.value as TrainingStatus)}
          value={selectedRole}
        >
          {roleOptions.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
};

export default ModalBody;
