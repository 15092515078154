import { gql } from '@apollo/client';
import {
  addMinutes,
  assertIsoTimestamp,
  getHoursAndMinutesString,
  isAfter,
  isAfterNow,
  isBeforeNow,
} from '@modernloop/shared/datetime';
import {
  getTimeRangesInTimezone,
  logError,
  mergeTimeRanges,
  pluralize,
  sortTimeRanges,
} from '@modernloop/shared/utils';
import { differenceInMinutes, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useSnackbar } from 'notistack';

import {
  DateTimeRangeInput,
  useImportCandidateAvailabilityApplicationTasksLazyQuery,
} from 'src/generated/mloop-graphql';

export const roundToNearestHalfHour = (date) => {
  const minutes = date.getMinutes();
  date.setMinutes(minutes >= 30 ? 60 : 30);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

const IMPORT_TIME_BUFFER_MINUTES = 60; // when importing availabilities, we will import availabilities that end at least this many minutes after the current time

export const ApplicationTasksQuery = gql`
  query ImportCandidateAvailabilityApplicationTasks($applicationId: uuid!) {
    application(id: $applicationId) {
      tasks {
        canceledAt
        id
        availabilities {
          id
          candidateEnteredAvailability {
            startAt
            endAt
          }
          rcEnteredAvailability {
            startAt
            endAt
          }
        }
      }
    }
  }
`;

interface ImportCandidateAvailabilitiesArgs {
  applicationId?: string;
  candidateTimezone: string;
  taskId?: string;
  setImportWarningLabel: (warning: string) => void;
  setImportSuccessLabel: (success: string) => void;
  setIsImportingAvailability?: (isImporting: boolean) => void;
  onCompleted: (
    candidateEnteredImports: DateTimeRangeInput[],
    rcEnteredImports: DateTimeRangeInput[],
    totalMinutes: number
  ) => void;
}

export const useImportCandidateAvailabilities = ({
  applicationId,
  candidateTimezone,
  setImportWarningLabel,
  setImportSuccessLabel,
  setIsImportingAvailability,
  onCompleted,
  taskId,
}: ImportCandidateAvailabilitiesArgs) => {
  const { enqueueSnackbar } = useSnackbar();

  const [importAvailabilities, { loading: importingAvailability }] =
    useImportCandidateAvailabilityApplicationTasksLazyQuery({
      variables: {
        applicationId,
      },
      onError: (error) => {
        logError(error);
        enqueueSnackbar('Import failed. Please try again', { variant: 'error' });
        if (setIsImportingAvailability) {
          setIsImportingAvailability(false);
        }
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const importedCandidateEnteredAvailabilities: DateTimeRangeInput[] = [];
        const importedRCEnteredAvailabilities: DateTimeRangeInput[] = [];
        const activeTasks = data?.application?.tasks.filter((task) => !task.canceledAt) || [];

        if ((taskId && !activeTasks.filter((task) => task.id !== taskId).length) || !activeTasks.length) {
          setImportWarningLabel(`No other tasks on this application`);
          if (setIsImportingAvailability) {
            setIsImportingAvailability(false);
          }
          return;
        }

        let tasksWithAvailabilities = 0;

        activeTasks.forEach((task) => {
          if (taskId && task.id === taskId) {
            return;
          }
          let hasAvailability = false;
          if (task.availabilities) {
            task.availabilities.forEach((availability) => {
              if (availability.rcEnteredAvailability) {
                availability.rcEnteredAvailability.forEach((rcAvailability) => {
                  if (isAfterNow(rcAvailability.startAt) && isAfterNow(rcAvailability.endAt)) {
                    importedRCEnteredAvailabilities.push(rcAvailability);
                    hasAvailability = true;
                  } else if (isBeforeNow(rcAvailability.startAt) && isAfterNow(rcAvailability.endAt)) {
                    // if there's same-day availability and the start time has passed, and there's at least IMPORT_TIME_BUFFER_MINUTES left in the availablity,
                    // we'll do a partial import starting at the next half hour
                    const roundedTime = roundToNearestHalfHour(new Date());
                    if (
                      isAfter(
                        rcAvailability.endAt,
                        addMinutes(
                          assertIsoTimestamp(roundedTime.toISOString()),
                          candidateTimezone,
                          IMPORT_TIME_BUFFER_MINUTES
                        )
                      )
                    ) {
                      importedRCEnteredAvailabilities.push({
                        startAt: zonedTimeToUtc(
                          roundedTime,
                          // eslint-disable-next-line no-restricted-syntax
                          Intl.DateTimeFormat().resolvedOptions().timeZone
                        ).toISOString(),
                        endAt: rcAvailability.endAt,
                      });
                      hasAvailability = true;
                    }
                  }
                });
              }
              if (availability.candidateEnteredAvailability) {
                availability.candidateEnteredAvailability.forEach((candidateEnteredAvailability) => {
                  if (
                    isAfterNow(candidateEnteredAvailability.startAt) &&
                    isAfterNow(candidateEnteredAvailability.endAt)
                  ) {
                    importedCandidateEnteredAvailabilities.push(candidateEnteredAvailability);
                    hasAvailability = true;
                  } else if (
                    isBeforeNow(candidateEnteredAvailability.startAt) &&
                    isAfterNow(candidateEnteredAvailability.endAt)
                  ) {
                    // if there's same-day availability and the start time has passed, and there's at least IMPORT_TIME_BUFFER_MINUTES left in the availablity,
                    // we'll do a partial import starting at the next half hour
                    const roundedTime = roundToNearestHalfHour(new Date());
                    if (
                      isAfter(
                        candidateEnteredAvailability.endAt,
                        addMinutes(
                          assertIsoTimestamp(roundedTime.toISOString()),
                          candidateTimezone,
                          IMPORT_TIME_BUFFER_MINUTES
                        )
                      )
                    ) {
                      importedCandidateEnteredAvailabilities.push({
                        startAt: zonedTimeToUtc(
                          roundedTime,
                          // eslint-disable-next-line no-restricted-syntax
                          Intl.DateTimeFormat().resolvedOptions().timeZone
                        ).toISOString(),
                        endAt: candidateEnteredAvailability.endAt,
                      });
                      hasAvailability = true;
                    }
                  }
                });
              }
            });
          }
          if (hasAvailability) {
            tasksWithAvailabilities++;
          }
        });

        if (!importedCandidateEnteredAvailabilities.length && !importedRCEnteredAvailabilities.length) {
          setImportWarningLabel(
            `No upcoming availability from ${activeTasks.length} other ${pluralize(
              'task',
              activeTasks.length as number
            )} on this application`
          );
          if (setIsImportingAvailability) {
            setIsImportingAvailability(false);
          }
          return;
        }

        // using map to add start and end fields to the availabilities, this can be removed when backend removes start and end
        const sortedCandidateEnteredImports = getTimeRangesInTimezone(
          sortTimeRanges(mergeTimeRanges(importedCandidateEnteredAvailabilities)),
          candidateTimezone
        );

        const sortedRcEnteredImports = getTimeRangesInTimezone(
          sortTimeRanges(mergeTimeRanges([...importedRCEnteredAvailabilities])),
          candidateTimezone
        );

        const totalMinutes = mergeTimeRanges(
          Object.values([...sortedCandidateEnteredImports, ...sortedRcEnteredImports]).flat()
        ).reduce((acc, availability) => {
          return acc + differenceInMinutes(parseISO(availability.endAt), parseISO(availability.startAt));
        }, 0);

        onCompleted(sortedCandidateEnteredImports, sortedRcEnteredImports, totalMinutes);

        setImportSuccessLabel(
          `Imported ${getHoursAndMinutesString(
            totalMinutes
          )} of upcoming availability from ${tasksWithAvailabilities} other ${pluralize(
            'task',
            tasksWithAvailabilities
          )}. Availability is imported from all active tasks for this application.`
        );

        if (setIsImportingAvailability) {
          setIsImportingAvailability(false);
        }
      },
    });

  return {
    importAvailabilities,
    importingAvailability,
  };
};
