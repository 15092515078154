import { SvgIcon, SvgIconProps } from './SvgIcon';

export const StarIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99999 4.26287L8.39054 6.7524C8.05321 7.27419 7.53365 7.65167 6.93316 7.81125L4.06813 8.57263L5.93846 10.8726C6.33047 11.3547 6.52893 11.9655 6.49513 12.5859L6.3339 15.546L9.09928 14.4779C9.67889 14.254 10.3211 14.254 10.9007 14.4779L13.6661 15.546L13.5049 12.5859C13.4711 11.9655 13.6695 11.3547 14.0615 10.8726L15.9319 8.57263L13.0668 7.81125C12.4663 7.65167 11.9468 7.27419 11.6094 6.7524L9.99999 4.26287ZM10.8398 2.799C10.4458 2.18956 9.55421 2.18956 9.16021 2.799L7.13086 5.93803C6.99593 6.14675 6.78811 6.29774 6.54791 6.36157L2.93542 7.32158C2.23404 7.50797 1.95853 8.35591 2.4164 8.91895L4.77468 11.819C4.93149 12.0118 5.01087 12.2561 4.99735 12.5043L4.79406 16.2366C4.75458 16.9613 5.47588 17.4853 6.15286 17.2239L9.63971 15.8772C9.87155 15.7876 10.1284 15.7876 10.3603 15.8772L13.8471 17.2239C14.5241 17.4853 15.2454 16.9613 15.2059 16.2366L15.0026 12.5043C14.9891 12.2561 15.0685 12.0118 15.2253 11.819L17.5836 8.91895C18.0415 8.3559 17.7659 7.50797 17.0646 7.32158L13.4521 6.36157C13.2119 6.29774 13.0041 6.14675 12.8691 5.93803L10.8398 2.799Z"
        fill="currentColor"
        fillOpacity="0.55"
      />
    </SvgIcon>
  );
};
