import { getAllJobStageInterviewByJobStageId } from 'src/store/selectors/job-stage-interview';

import { useSelector } from 'src/store';

const useInterviewPlanHasInterviewHiddenFromCandidate = (jobStageId: string): boolean => {
  const jobStageInterviews = useSelector((state) => getAllJobStageInterviewByJobStageId(state, jobStageId));

  return jobStageInterviews.reduce((isHiddenFromCandidate, jobStageInterview) => {
    return Boolean(jobStageInterview.isHiddenFromCandidate) || isHiddenFromCandidate;
  }, false);
};

export default useInterviewPlanHasInterviewHiddenFromCandidate;
