import { SvgIcon, SvgIconProps } from './SvgIcon';

export const StarFilledIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        d="M9.16021 2.799C9.55421 2.18956 10.4458 2.18956 10.8398 2.799L12.8691 5.93803C13.0041 6.14675 13.2119 6.29774 13.4521 6.36157L17.0646 7.32158C17.7659 7.50797 18.0415 8.3559 17.5836 8.91895L15.2253 11.819C15.0685 12.0118 14.9891 12.2561 15.0026 12.5043L15.2059 16.2366C15.2454 16.9613 14.5241 17.4853 13.8471 17.2239L10.3603 15.8772C10.1284 15.7876 9.87155 15.7876 9.63971 15.8772L6.15286 17.2239C5.47588 17.4853 4.75459 16.9613 4.79406 16.2366L4.99735 12.5043C5.01087 12.2561 4.93149 12.0118 4.77468 11.819L2.4164 8.91895C1.95853 8.35591 2.23404 7.50797 2.93542 7.32158L6.54791 6.36157C6.78811 6.29774 6.99593 6.14675 7.13086 5.93803L9.16021 2.799Z"
        fill="currentColor"
        fillOpacity="0.55"
      />
    </SvgIcon>
  );
};
