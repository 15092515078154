import React, { useMemo, useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { addDays, addMonths, isBefore, isSameDay, lastDayOfMonth, parseISO, setDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import IconButton from 'src/components/IconButton';
import Stack from 'src/components/Stack';
import { MoreIcon } from 'src/components/icons';
import Label from 'src/components/label';
import Menu from 'src/components/menu';
import Tabs, { TabProps } from 'src/components/tabs';
import Tooltip from 'src/components/tooltip';

import { Theme } from 'src/theme/type';

import { endOfDay, formatToTimeZone, startOfDay } from 'src/utils/dateUtils';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type CalendarDatePickerProps = {
  activeDay: number;
  eventDays: number[];
  timezone: string;
  onDayChange: (day: number) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    interviewDay: {
      position: 'relative',
      display: 'inline-block',

      '&::before': {
        content: `"."`,
        display: 'inline-block',

        /* position the dot underneath the character */
        position: 'absolute',
        bottom: '-4px',
        left: 0,
        fontSize: '24px',
        fontWeight: theme.typography.fontWeightBold,

        /* center the dot horizontally */
        textAlign: 'center',
        width: '100%',
      },
    },
  })
);

const CalendarDatePicker = ({ activeDay, eventDays, timezone, onDayChange }: CalendarDatePickerProps): JSX.Element => {
  const classes = useStyles();
  const moreButtonRef = useRef<HTMLButtonElement>(null);

  const [showMenu, setShowMenu] = useState(false);
  const [viewAllDays, setViewAllDays] = useState(false);

  const scheduleFlowData = useScheduleFlowData();
  const { candidateAvailabilities } = scheduleFlowData;
  const candidateAvailabilityUniqueDays = useMemo(() => {
    if (!candidateAvailabilities) return [];

    const result: number[] = [];

    candidateAvailabilities.forEach((availability) => {
      const time = startOfDay(parseISO(availability.startAt), timezone).getTime();

      if (result.includes(time)) return;
      result.push(time);
    });

    return result;
  }, [candidateAvailabilities, timezone]);

  const eventDaysTabs: TabProps<number>[] = useMemo(() => {
    if (!viewAllDays) {
      return eventDays.map((eventDay) => ({
        id: eventDay,
        label:
          activeDay === eventDay
            ? formatToTimeZone(eventDay, 'EEE, LLL d', timezone)
            : `${utcToZonedTime(eventDay, timezone).getDate()}`,
      }));
    }

    // eventDays.length should never be 0 but adding a check because of https://linear.app/modernloop/issue/TASK-2091/rangeerror-invalid-time-value
    const firstEventDay = eventDays && eventDays.length > 0 ? eventDays[0] : Date.now();

    let start = startOfDay(setDay(addMonths(firstEventDay, -1), 1), timezone);
    if (start.getTime() < Date.now()) {
      start = startOfDay(new Date(), timezone);
    }

    const end = endOfDay(lastDayOfMonth(addMonths(firstEventDay, 1)), timezone);

    const tabs: TabProps<number>[] = [];
    let currentDay = start;

    while (isBefore(currentDay, end)) {
      let label: string | JSX.Element = `${utcToZonedTime(currentDay, timezone).getDate()}`;
      const dateStr = formatToTimeZone(currentDay, 'EEEE, MMMM d, yyyy', timezone);
      if (isSameDay(activeDay, currentDay)) {
        label = formatToTimeZone(currentDay, 'EEE, LLL d', timezone);
      } else if (eventDays.includes(currentDay.getTime())) {
        label = (
          <Tooltip tooltip="Interview scheduled">
            <Label className={classes.interviewDay} color="info" fontWeight={500} variant="captions">
              {label}
            </Label>
          </Tooltip>
        );
      } else if (candidateAvailabilityUniqueDays.includes(currentDay.getTime())) {
        label = (
          <Tooltip tooltip={`${dateStr} · Candidate available`}>
            <Label color="info" fontWeight={500} variant="captions">
              {label}
            </Label>
          </Tooltip>
        );
      } else {
        label = (
          <Tooltip tooltip={`${dateStr} · Candidate unavailable`}>
            <Label color="mid-contrast-grey" fontWeight={500} variant="captions">
              {label}
            </Label>
          </Tooltip>
        );
      }

      tabs.push({
        id: currentDay.getTime(),
        label,
      });

      // https://modernloop.slack.com/archives/C02CE4U8HPH/p1679313373852939
      // TODO: (hemant) uncomment below line once ENG-5859 & remove the code involving newCurrentDay
      // currentDay = startOfDay(addDays(currentDay, 1), timezone);
      const newCurrentDay = startOfDay(addDays(currentDay, 1), timezone);

      if (newCurrentDay.getTime() === currentDay.getTime()) {
        currentDay = startOfDay(addDays(currentDay, 2), timezone);
      } else {
        currentDay = newCurrentDay;
      }
    }

    return tabs;
  }, [activeDay, candidateAvailabilityUniqueDays, classes.interviewDay, eventDays, timezone, viewAllDays]);

  const handleDayChange = (tab: TabProps<number>) => {
    onDayChange(tab.id);
  };

  return (
    <Stack>
      <Tabs tabs={eventDaysTabs} activeTabId={activeDay} onChange={handleDayChange} />

      {!viewAllDays && (
        <IconButton onClick={() => setShowMenu(true)} ref={moreButtonRef}>
          <MoreIcon />
        </IconButton>
      )}

      {showMenu && (
        <Menu
          anchorEl={moreButtonRef.current}
          open
          id="view-all-days-menu"
          options={[{ id: 'view-all-days', value: 'View all days' }]}
          onSelect={() => {
            setViewAllDays(true);
            setShowMenu(false);
          }}
          onClose={() => setShowMenu(false)}
        />
      )}
    </Stack>
  );
};

export default CalendarDatePicker;
