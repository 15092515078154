/*
 * This function will pluralize a word if the count is not 1.
 * If a plural is provided, it will use that instead of adding an 's'.
 */

// eslint-disable-next-line max-params
export const pluralize = (word: string, count: number, plural = '') => {
  if (count === 1) {
    return word;
  }

  if (plural) {
    return plural;
  }
  return `${word}s`;
};

/**
 * This function will remove all html tags from a string.
 * @param str   The string to remove html tags from.
 * @returns     The string without html tags.
 */
export const removeHtmlTags = (str: string) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = str;
  // Extract the internal text from the HTML using .textContent
  const sanitizedText = tempDiv.innerText || tempDiv.textContent || '';
  return sanitizedText;
};

/**
 * This function will convert a string from snake_case or SNAKE_CASE to sentence Case.
 * @param text The string to convert.
 * @returns The converted string.
 */
export const snakeToSentenceCase = (text: string) => {
  if (!text || typeof text !== 'string') {
    return '';
  }

  // Replace underscores with spaces
  let sentence = text.replace(/_/g, ' ').toLowerCase();

  // Capitalize the first letter of the sentence
  sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);

  return sentence;
};

/**
 *
 * @param inputString
 * @param searchString
 * @returns {boolean}
 *
 * This function will check if a string is duplicated.

 */

export const isStringDuplicated = (inputString: string, searchString: string) => {
  const regex = new RegExp(searchString, 'g');
  const matches = inputString.match(regex);

  return Boolean(matches && matches.length > 1);
};

export const replaceLinksWithAnchorTags = (text: string): { hasLink: boolean; text: string } => {
  const urlRegex =
    /(([a-z]+:\/\/)?(([a-z0-9-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-.~]+)*(\/([a-z0-9_\-.]*)(\?[a-z0-9+_\-.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(?=[\s,]|$)/gi;

  if (text.match(urlRegex)) {
    // eslint-disable-next-line max-params
    const textWithLink = text.replace(urlRegex, (fullMatch, _, protocol) => {
      // If the protocol is missing  prepend 'http://'
      const href = protocol ? fullMatch : `//${fullMatch}`;
      return `<a target='_blank' href='${href}'>${fullMatch}</a>`;
    });

    return { text: textWithLink, hasLink: true };
  }
  return { text, hasLink: false };
};

export const extractTextFromHTML = (htmlString: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString.replace(/<[bB][rR]\s*\/?>/g, ' '), 'text/html');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getText(node: any) {
    let text = '';
    if (node.nodeType === Node.TEXT_NODE) {
      text += node.textContent;
    } else {
      for (const childNode of node.childNodes) {
        text += getText(childNode);
      }
    }
    return text;
  }
  const resultText = getText(doc.body);
  return resultText.trim();
};
