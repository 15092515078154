import React, { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Loader, PublicError } from '@modernloop/shared/helper-components';
import { EventIcon, PlusIcon } from '@modernloop/shared/icons';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
} from '@mui/material';

import {
  InterviewModuleMemberModuleLog_ModuleMemberFragment,
  Sort,
  useInterviewModuleMemberModuleLogSidepanelQuery,
} from 'src/generated/mloop-graphql';

import { PAGE_SIZE } from 'src/constants';

import AddModuleLog from '../Modals/AddModuleLogModal';
import PendingApprovalDisableWrapper from '../PendingApprovalDisableWrapper';

import ModuleLogRow from './InterviewModuleMemberModuleLogRow';

type Fragments = {
  moduleMember: InterviewModuleMemberModuleLog_ModuleMemberFragment;
};

const InterviewModuleMemberModuleLog: FCWithFragments<Fragments> = ({ moduleMember }) => {
  const [opneCreateLog, setOpenCreateLog] = useState(false);
  const [direction, setDirection] = useState<Sort>(Sort.Desc);
  const { data, error, loading, refetch } = useInterviewModuleMemberModuleLogSidepanelQuery({
    variables: {
      interviewModuleId: moduleMember.interviewModuleId,
      moduleMemberId: moduleMember.employeeId,
      moduleMemberInterviewLogsInput: {
        pageInput: {
          limit: PAGE_SIZE,
          offset: 0,
        },
        orderBy: {
          direction,
        },
      },
    },
  });

  /** Always refetch on mount  */
  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch]);

  const getSortDirection = (): 'asc' | 'desc' => {
    return Sort.Asc === direction ? 'asc' : 'desc';
  };

  const getTableContent = () => {
    let jsx;
    if (loading) {
      jsx = <Loader loading />;
    } else if (error) {
      jsx = <PublicError error={error} />;
    }
    if (jsx) {
      return (
        <TableRow>
          <TableCell colSpan={3}>
            <Box justifyContent="center" display="flex">
              {jsx}
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return data?.interviewModuleMember?.moduleMemberInterviewLogs?.items?.map((moduleMemberInterviewLog) => {
      return (
        <ModuleLogRow
          moduleMember={moduleMember}
          key={moduleMemberInterviewLog.id}
          moduleMemberInterview={moduleMemberInterviewLog}
        />
      );
    });
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        overflow: 'hidden',
        borderRadius: (theme: Theme) => `${theme.spacing(2)} !important`,
      }}
    >
      <Stack justifyContent="space-between" alignItems="center" flexDirection="row" p={2} pt={1} pb={1}>
        <Typography variant="subtitle2">Module log</Typography>
        <Box>
          <PendingApprovalDisableWrapper
            currentTrainingSessionStatus={moduleMember.stats?.currentTrainingSessionStatus || undefined}
          >
            <Button
              onClick={() => setOpenCreateLog(true)}
              startIcon={<PlusIcon />}
              variant="contained"
              size="small"
              color="primary"
            >
              Log
            </Button>
          </PendingApprovalDisableWrapper>
        </Box>
      </Stack>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '0px !important',
          borderTop: (theme: Theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Table aria-label="collapsible table">
          {!!data?.interviewModuleMember?.moduleMemberInterviewLogs?.items.length && (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      direction={getSortDirection()}
                      onClick={() => {
                        setDirection(Sort.Desc === direction ? Sort.Asc : Sort.Desc);
                      }}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{getTableContent()}</TableBody>
            </>
          )}
          {!data?.interviewModuleMember?.moduleMemberInterviewLogs?.items.length && (
            <Stack p={2} alignItems="center" gap={2.5}>
              {!loading && (
                <Stack color="text.secondary" fontSize={64}>
                  <EventIcon fontSize="inherit" />
                </Stack>
              )}

              {!loading && (
                <Typography variant="body1">
                  This interviewer is not yet associated with any interviews in this module.
                </Typography>
              )}
              {loading && <CircularProgress size={24} />}
            </Stack>
          )}
        </Table>
      </TableContainer>
      {opneCreateLog && (
        <AddModuleLog
          moduleMember={moduleMember}
          onClose={() => {
            setOpenCreateLog(false);
          }}
        />
      )}
    </Paper>
  );
};

export const InterviewModuleMemberModuleLogSidepanelQuery = gql`
  ${ModuleLogRow.fragments.moduleMemberInterview}
  query InterviewModuleMemberModuleLogSidepanel(
    $interviewModuleId: uuid!
    $moduleMemberId: uuid!
    $moduleMemberInterviewLogsInput: ModuleMemberInterviewLogInput!
  ) {
    interviewModuleMember(interviewModuleId: $interviewModuleId, employeeId: $moduleMemberId) {
      id
      interviewModuleId
      employeeId
      moduleMemberInterviewLogs(input: $moduleMemberInterviewLogsInput) {
        items {
          ...interviewModuleMemberModuleLogRow_moduleMemberInterview
        }
      }
    }
  }
`;

InterviewModuleMemberModuleLog.fragments = {
  moduleMember: gql`
    ${ModuleLogRow.fragments.moduleMember}
    ${AddModuleLog.fragments.moduleMember}
    fragment InterviewModuleMemberModuleLog_moduleMember on InterviewModuleMember {
      id
      interviewModuleId
      employeeId
      employee {
        id
        fullName
        slackImageUrl
      }
      interviewModule {
        id
        name
      }
      stats {
        currentTrainingSessionStatus
      }
      ...AddModuleLog_moduleMember
      ...interviewModuleMemberModuleLogRow_moduleMember
    }
  `,
};

export default InterviewModuleMemberModuleLog;
