import React from 'react';

import BaseIcon, { Props } from './Icon';

const CandidateIconFilled = ({ color, fontSize, tooltip, dataTestId }: Props): JSX.Element => {
  return (
    <BaseIcon dataTestId={dataTestId} color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00002 1.25C3.48123 1.25 2.25002 2.48122 2.25002 4V16C2.25002 17.5188 3.48123 18.75 5.00002 18.75H12.1716C12.9009 18.75 13.6004 18.4603 14.1161 17.9445L16.9445 15.1161C17.4603 14.6004 17.75 13.9009 17.75 13.1716V4C17.75 2.48122 16.5188 1.25 15 1.25H5.00002ZM7.5 7C7.5 5.61929 8.61929 4.5 10 4.5C11.3807 4.5 12.5 5.61929 12.5 7C12.5 8.38071 11.3807 9.5 10 9.5C8.61929 9.5 7.5 8.38071 7.5 7ZM13.5 15H6.50001C5.94772 15 5.48362 14.5432 5.62009 14.008C6.60863 10.1315 13.3916 10.1309 14.38 14.008C14.5164 14.5432 14.0523 15 13.5 15Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CandidateIconFilled;
