import React, { useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { CanDisable } from '@modernloop/shared/helper-components';
import { pluralize } from '@modernloop/shared/utils';
import Grid from '@mui/material/Unstable_Grid2';

import {
  BreakSlot_BreakSlotFragment,
  BreakSlot_JobStageInterviewGroupsFragment,
  InterviewType,
} from 'src/generated/mloop-graphql';

import IconButton from 'src/components/IconButton';
import Paper from 'src/components/Paper';
import Button from 'src/components/button';
import { BreakIcon, OrderLockIcon, OrderShuffleIcon, TrashIcon, TriangleDownIcon } from 'src/components/icons';
import OvernightIcon from 'src/components/icons/Overnight';
import DurationMenu, { DURATION_OPTIONS } from 'src/components/menu/DurationMenu';

import { useFlag } from 'src/hooks/feature-flag/';

import { isBreak, isDayBreak } from 'src/utils/interview';

import MoreMenuOptions, { InterviewMenuOptions } from './MoreMenuOptions';

type Props = {
  index: number;
  readonly?: boolean;
  isOnlyInterviewInGroup: boolean;
  onUpdated: (breakSlot: BreakSlot_BreakSlotFragment) => void;
  onDelete: (jobStageInterviewId: string) => void;
  onMenuSelect: (option: InterviewMenuOptions) => void;
};

type Fragments = {
  breakSlot: BreakSlot_BreakSlotFragment;
  jobStageInterviewGroups: BreakSlot_JobStageInterviewGroupsFragment[];
};

const BreakSlot: FCWithFragments<Fragments, Props> = ({
  breakSlot,
  jobStageInterviewGroups,
  index,
  readonly,
  isOnlyInterviewInGroup,
  onUpdated,
  onDelete,
  onMenuSelect,
}): JSX.Element => {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const orgShuffleAbleBreak = useFlag('org-shuffle-able-break');

  const interviewSlotDuration = breakSlot.duration;
  const durationString = useMemo(() => {
    let result = DURATION_OPTIONS.find((value) => value.id === interviewSlotDuration)?.value;
    if (!result && interviewSlotDuration) {
      result = `${interviewSlotDuration} ${pluralize('min', interviewSlotDuration, 'mins')}`;
    }

    return result;
  }, [interviewSlotDuration]);

  const openBreakDurationMenu = () => {
    setOpen(true);
  };

  const closeBreakDurationMenu = () => {
    setOpen(false);
  };

  const handleDurationSelect = (duration: number) => {
    const interviewType = duration === 0 ? InterviewType.DayDivider : InterviewType.Break;
    onUpdated({ ...breakSlot, duration, interviewType, name: duration === 0 ? 'Day Divider' : 'Break' });
  };

  const handleDeleteBreakSlot = () => {
    onDelete(breakSlot.id);
  };

  const handlePlanOrderClick = () => {
    /* * Addidtional check in case the click is triggered, Day divider are not Shufflable * */
    if (isDayBreak(breakSlot.interviewType)) {
      return;
    }

    onUpdated({ ...breakSlot, isLockedOrder: !breakSlot.isLockedOrder });
  };

  return (
    <Paper color="contrast" size={!isOnlyInterviewInGroup ? 'medium' : 'large'}>
      <CanDisable disabled={Boolean(readonly)}>
        <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
          <Grid xs={10}>
            <Grid container alignItems="center" spacing={1}>
              <Grid>
                <Grid container alignItems="center" spacing={1}>
                  {orgShuffleAbleBreak && !isDayBreak(breakSlot.interviewType) && (
                    // Day divider are not Shufflable
                    <Grid>
                      <IconButton onClick={handlePlanOrderClick}>
                        {breakSlot.isLockedOrder && <OrderLockIcon tooltip="Locked position" />}
                        {!breakSlot.isLockedOrder && <OrderShuffleIcon tooltip="Shuffle position" />}
                      </IconButton>
                    </Grid>
                  )}

                  {!orgShuffleAbleBreak && (
                    <Grid>
                      <OrderLockIcon tooltip="Locked position" />
                    </Grid>
                  )}
                  {isBreak(breakSlot.interviewType) && (
                    <Grid>
                      <BreakIcon />
                    </Grid>
                  )}
                  {isDayBreak(breakSlot.interviewType) && (
                    <Grid>
                      <OvernightIcon />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid>
                <Button
                  label={durationString}
                  variant="unstyled"
                  size="medium"
                  ref={anchorEl}
                  endIcon={<TriangleDownIcon />}
                  onClick={openBreakDurationMenu}
                />
                <DurationMenu
                  id="break-slot-duration-menu"
                  open={open}
                  anchorEl={anchorEl}
                  onSelect={handleDurationSelect}
                  onClose={closeBreakDurationMenu}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid container justifyContent="space-between" wrap="nowrap" spacing={1}>
              <Grid>
                <MoreMenuOptions
                  index={index}
                  jobStageInterview={breakSlot}
                  jobStageInterviewGroups={jobStageInterviewGroups}
                  onMenuSelect={onMenuSelect}
                />
              </Grid>
              <Grid>
                <IconButton onClick={handleDeleteBreakSlot}>
                  <TrashIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CanDisable>
    </Paper>
  );
};

BreakSlot.fragments = {
  breakSlot: gql`
    ${MoreMenuOptions.fragments.jobStageInterview}
    fragment BreakSlot_breakSlot on JobStageInterview {
      id
      interviewType
      name
      duration
      isLockedOrder
      ...MoreMenuOptions_jobStageInterview
    }
  `,
  jobStageInterviewGroups: gql`
    ${MoreMenuOptions.fragments.jobStageInterviewGroups}
    fragment BreakSlot_jobStageInterviewGroups on JobStageInterviewGroup {
      id
      ...MoreMenuOptions_jobStageInterviewGroups
    }
  `,
};

export default BreakSlot;
