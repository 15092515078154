import { useCallback } from 'react';

import { gql } from '@apollo/client';

import { useCheckCreateCandidatePermissionMutation } from 'src/generated/mloop-graphql';

const useCheckCreateCandidatePermission = (jobId: string) => {
  const [checkCreateCandidatePermission] = useCheckCreateCandidatePermissionMutation({
    variables: { input: { jobId } },
  });

  return useCallback(async () => {
    const { data } = await checkCreateCandidatePermission();
    if (data?.checkCreateCandidatePermission.hasPermission) {
      return { hasPermission: true, testCandidateAtsUrl: data.checkCreateCandidatePermission.testCandidateAtsUrl };
    }
    return { hasPermission: false, testCandidateAtsUrl: undefined };
  }, [checkCreateCandidatePermission]);
};

export default useCheckCreateCandidatePermission;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckCreateCandidatePermissionQuery = gql`
  mutation CheckCreateCandidatePermission($input: CheckCreateCandidatePermissionInput!) {
    checkCreateCandidatePermission(input: $input) {
      hasPermission
      testCandidateAtsUrl
    }
  }
`;
