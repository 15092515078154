/* eslint-disable max-lines */
import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments, ZeroState } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { SearchIcon } from '@modernloop/shared/icons';
import { ListSubheader, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';

import { DateBlock, EmployeeFilterFragment, TrainingStatus } from 'src/generated/mloop-graphql';

import FilterList from 'src/components/FilterList';
import { PauseIcon, ReverseShadowIcon, ShadowIcon } from 'src/components/icons';
import Label from 'src/components/label';

import {
  doesModuleMemberNeedsApproval,
  isModuleMemberDisabledForScheduling,
  isModuleMemberTrained,
} from 'src/entities/InterviewModuleMember/utils';
import InterviewModuleMember from 'src/entities/InterviewPlan/InterviewModuleMember';

import {
  updateJobStageInterviewSeatModuleMembers,
  updateJobStageInterviewSeatPeople,
} from 'src/store/actions/job-stage-interview-seat';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { isAfterNow } from 'src/utils/datetime/Comparision';

import { useDispatch } from 'src/store';

import { isEventInProgress } from '../CandidateDetails/utils';

import FilterListDeprecated, { FilterListItem } from './FilterListDeprecated';

/**
 * Not creating the deprecated version of this componenet as the fragment type is used at multiple places.
 * It will be difficult to maintain the deprecated version.
 * Making this file right with marking specific code in this file as deprecated
 */

enum DisplayMemberStatus {
  Trained = 'Trained',
  Trainees = 'Trainees',
}

type Fragments = {
  interviewModuleMembers: EmployeeFilterFragment[];
};

type Props = {
  seat: JobStageInterviewSeat;
  onUpdated: (seat: JobStageInterviewSeat) => void;
};

const EmployeeFilter: FCWithFragments<Fragments, Props> = ({ interviewModuleMembers, seat, onUpdated }) => {
  const isFastTrackEnabled = useFlag('user_fast_track_interview_module');

  const dispatch = useDispatch();

  /* * ***** Deprecated with  isFastTrackEnabled START ***** * */

  const needsApproval = (interviewer: EmployeeFilterFragment): boolean => {
    if (
      interviewer.status.toLowerCase() === TrainingStatus.ReverseShadow.toLowerCase() &&
      interviewer.reverseShadowsRequired
    ) {
      return !!(
        interviewer.reverseShadowsRequired <=
        interviewer.stats?.reverseShadowOffset + interviewer.stats?.completedAsReverseShadow
      );
    }
    if (interviewer.status.toLowerCase() === TrainingStatus.Shadow.toLowerCase() && interviewer.shadowsRequired) {
      return !!(interviewer.shadowsRequired <= interviewer.stats?.shadowOffset + interviewer.stats?.completedAsShadow);
    }
    return false;
  };

  const isInterviewerPaused = (interviewer: EmployeeFilterFragment): boolean => {
    if (interviewer.pausedUntil && isAfterNow(interviewer.pausedUntil)) {
      return true;
    }
    if (interviewer.employee?.interviewPauseDates) {
      const interviewPauseDates: DateBlock[] = JSON.parse(JSON.stringify(interviewer.employee?.interviewPauseDates));
      if (!interviewPauseDates?.length) return false;
      const isPauseRange = interviewPauseDates.find((range) => {
        if (range.start && range.end) {
          return isEventInProgress(range.start, range.end);
        }
        return false;
      });
      return !!isPauseRange;
    }
    return false;
  };

  // Any interviewer in trained status or needs approval where they would go to trained
  const trained = interviewModuleMembers?.filter(
    (interviewer) =>
      interviewer.status === TrainingStatus.Trained ||
      (interviewer.status === TrainingStatus.ReverseShadow && needsApproval(interviewer)) ||
      (interviewer.status === TrainingStatus.Shadow &&
        interviewer.reverseShadowsRequired === 0 &&
        needsApproval(interviewer))
  );

  // Interviewers in shadow but not needing approval
  const shadow = interviewModuleMembers?.filter(
    (interviewer) => interviewer.status === TrainingStatus.Shadow && !needsApproval(interviewer)
  );

  // Any interviewer in reverse shadow but not needing approval and shadow needs approval
  const reverseShadow = interviewModuleMembers.filter(
    (interviewer) =>
      (interviewer.status === TrainingStatus.ReverseShadow && !needsApproval(interviewer)) ||
      (interviewer.status === TrainingStatus.Shadow && interviewer.reverseShadowsRequired && needsApproval(interviewer))
  );

  const getTag = (interviewer: EmployeeFilterFragment): JSX.Element | undefined => {
    if (needsApproval(interviewer)) {
      return (
        <Label
          icon={interviewer.status === TrainingStatus.Shadow.toLowerCase() ? <ShadowIcon /> : <ReverseShadowIcon />}
          color="max-contrast-grey"
          variant="captions"
          backgroundColor="warning"
        >
          Needs approval
        </Label>
      );
    }
    if (isInterviewerPaused(interviewer)) {
      return (
        <Label
          icon={<PauseIcon color="high-contrast-grey" />}
          color="max-contrast-grey"
          variant="captions"
          backgroundColor="contrast"
        >
          Paused
        </Label>
      );
    }
    return undefined;
  };

  let items: FilterListItem<EmployeeFilterFragment>[] = [];

  const convertToFilterListItem = (itemsToFilter: EmployeeFilterFragment[], selectedIds: string[]) => {
    const filtered = itemsToFilter.map((value) => {
      const item: FilterListItem<EmployeeFilterFragment> = {
        id: value.employeeId,
        label: value.employee?.fullName ?? '',
        image_url: value.employee?.slackImageUrl ?? undefined,
        isSelected: selectedIds.includes(value.employeeId),
        data: value,
        isDisabled: needsApproval(value),
        tag: getTag(value),
      };
      return item;
    });

    filtered.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
    return filtered;
  };

  if (trained && trained.length) {
    const trainedListItems = convertToFilterListItem(trained, seat.selectedTrainedInterviewerIds ?? []);
    if (trainedListItems.length) {
      items.push({
        id: isFastTrackEnabled ? DisplayMemberStatus.Trained : 'trained',
        isSubheader: true,
        label: isFastTrackEnabled ? DisplayMemberStatus.Trained : 'Interviewers',
      });
      items = items.concat(trainedListItems);
    }
  }

  if (reverseShadow && reverseShadow.length) {
    const reverseShadowListItems = convertToFilterListItem(
      reverseShadow,
      seat.selectedReverseShadowInterviewerIds ?? []
    );
    if (reverseShadowListItems.length) {
      items.push({ id: 'Reverse shadow', isSubheader: true, label: 'Reverse shadow' });
      items = items.concat(reverseShadowListItems);
    }
  }

  if (shadow && shadow.length) {
    const shadowListItems = convertToFilterListItem(shadow, seat.selectedShadowInterviewerIds ?? []);
    if (shadowListItems.length) {
      items.push({ id: 'shadow', isSubheader: true, label: 'Shadow' });
      items = items.concat(shadowListItems);
    }
  }

  const handleChange = (interviewer: EmployeeFilterFragment, selected: boolean) => {
    // Delete the dispatch once we move to the prop based version of interview plan.
    dispatch(updateJobStageInterviewSeatPeople(seat.id, interviewer.employeeId, interviewer.status, selected));

    const updatedSeat = cloneDeep(seat);
    updatedSeat.selectedTrainedInterviewerIds = updatedSeat.selectedTrainedInterviewerIds ?? [];
    updatedSeat.selectedShadowInterviewerIds = updatedSeat.selectedShadowInterviewerIds ?? [];
    updatedSeat.selectedReverseShadowInterviewerIds = updatedSeat.selectedReverseShadowInterviewerIds ?? [];
    delete updatedSeat.attributeMap;

    if (interviewer.status === TrainingStatus.Trained) {
      if (selected) {
        updatedSeat.selectedTrainedInterviewerIds.push(interviewer.employeeId);
      } else {
        updatedSeat.selectedTrainedInterviewerIds = updatedSeat.selectedTrainedInterviewerIds.filter(
          (id) => id !== interviewer.employeeId
        );
      }
    } else if (interviewer.status === TrainingStatus.Shadow) {
      if (selected) {
        updatedSeat.selectedShadowInterviewerIds.push(interviewer.employeeId);
      } else {
        updatedSeat.selectedShadowInterviewerIds = updatedSeat.selectedShadowInterviewerIds.filter(
          (id) => id !== interviewer.employeeId
        );
      }
    } else if (interviewer.status === TrainingStatus.ReverseShadow) {
      if (selected) {
        updatedSeat.selectedReverseShadowInterviewerIds.push(interviewer.employeeId);
      } else {
        updatedSeat.selectedReverseShadowInterviewerIds = updatedSeat.selectedReverseShadowInterviewerIds.filter(
          (id) => id !== interviewer.employeeId
        );
      }
    }
    onUpdated(updatedSeat);
  };

  if (!isFastTrackEnabled) {
    return <FilterListDeprecated items={items} onItemClick={handleChange} />;
  }

  /* * ***** Deprecated with  isFastTrackEnabled END ***** * */

  const getSelectedEmployees = () => {
    return interviewModuleMembers.filter((interviewer) => {
      if (interviewer.status === TrainingStatus.Trained) {
        return seat.selectedTrainedInterviewerIds?.includes(interviewer.employeeId);
      }
      if (interviewer.status === TrainingStatus.Shadow) {
        return seat.selectedShadowInterviewerIds?.includes(interviewer.employeeId);
      }
      if (interviewer.status === TrainingStatus.ReverseShadow) {
        return seat.selectedReverseShadowInterviewerIds?.includes(interviewer.employeeId);
      }
      return false;
    });
  };

  const handleMemberChange = (_, values) => {
    // Delete the dispatch once we move to the prop based version of interview plan.
    dispatch(updateJobStageInterviewSeatModuleMembers(seat.id, values));

    const updatedSeat = cloneDeep(seat);
    updatedSeat.selectedTrainedInterviewerIds = [];
    updatedSeat.selectedShadowInterviewerIds = [];
    updatedSeat.selectedReverseShadowInterviewerIds = [];
    delete updatedSeat.attributeMap;

    values.forEach((interviewer) => {
      if (interviewer.status === TrainingStatus.Trained && updatedSeat.selectedTrainedInterviewerIds) {
        updatedSeat.selectedTrainedInterviewerIds.push(interviewer.employeeId);
      } else if (interviewer.status === TrainingStatus.Shadow && updatedSeat.selectedShadowInterviewerIds) {
        updatedSeat.selectedShadowInterviewerIds.push(interviewer.employeeId);
      } else if (
        interviewer.status === TrainingStatus.ReverseShadow &&
        updatedSeat.selectedReverseShadowInterviewerIds
      ) {
        updatedSeat.selectedReverseShadowInterviewerIds.push(interviewer.employeeId);
      }
    });

    onUpdated(updatedSeat);
  };

  const groupBy = (option: EmployeeFilterFragment) => {
    return isModuleMemberTrained(option.status) ? DisplayMemberStatus.Trained : DisplayMemberStatus.Trainees;
  };

  return (
    <ConditionalThemeProvider>
      <FilterList
        hideSearch
        options={interviewModuleMembers}
        values={getSelectedEmployees()}
        getOptionLabel={(option) => option.employee?.fullName ?? ''}
        getChildSize={(child: React.ReactNode) => {
          let HEIGHT = 40;
          /* *  TODO Ticket - ENG-13741  * */
          if ((child as React.ReactElement).props.role !== 'option') {
            return HEIGHT;
          }
          const { interviewModuleMember } = (child as React.ReactElement).props.children.props;
          if (interviewModuleMember.employee.title) {
            HEIGHT += 18;
          }
          if (!isModuleMemberTrained(interviewModuleMember.status)) {
            if (
              doesModuleMemberNeedsApproval(
                interviewModuleMember.status,
                interviewModuleMember.stats?.currentTrainingSessionStatus
              )
            ) {
              return HEIGHT + 18;
            }
            return HEIGHT + 18;
          }
          return HEIGHT;
        }}
        getListHeight={() => 300}
        onChange={handleMemberChange}
        zeroState={<ZeroState label="No interviewers found" icon={<SearchIcon />} />}
        wrapInPaper={false}
        renderOption={(option, state) => {
          return <InterviewModuleMember interviewModuleMember={option} selected={state.selected} />;
        }}
        groupBy={groupBy}
        renderGroup={(params) => {
          return [
            <ListSubheader disableSticky key={params.key} component="div" disableGutters>
              <Typography color="text.secondary" fontWeight={500}>
                {params.group === DisplayMemberStatus.Trained && 'Trained'}
                {params.group === DisplayMemberStatus.Trainees && 'Trainees'}
              </Typography>
            </ListSubheader>,
            params.children,
          ];
        }}
        getOptionDisabled={(option) => isModuleMemberDisabledForScheduling({ moduleMember: option }, null)}
      />
    </ConditionalThemeProvider>
  );
};

/**
 * Note: Before deleteing the unused keys from the fragments, make sure that the keys are not used in other components.
 */

EmployeeFilter.fragments = {
  interviewModuleMembers: gql`
    ${InterviewModuleMember.fragments.interviewModuleMember}
    ${isModuleMemberDisabledForScheduling.fragments.moduleMember}
    fragment EmployeeFilter on InterviewModuleMember {
      id
      ...isModuleMemberDisabledForScheduling_ModuleMember
      ...InterviewModuleMember_interviewModuleMember
      interviewModuleId
      employeeId
      status
      shadowsRequired
      reverseShadowsRequired
      pausedUntil
      stats {
        completedAsReverseShadow
        completedAsShadow
        shadowOffset
        reverseShadowOffset
      }
      employee {
        id
        fullName
        email
        title
        slackImageUrl
        attributes {
          id
          value
        }
        interviewPauseDates {
          start
          end
        }
      }
    }
  `,
};

export default EmployeeFilter;
