import { useCallback } from 'react';

import { MySchedulingTasksDocument } from 'src/generated/mloop-graphql';

import { useApolloRefetchQuery } from 'src/hooks/useApolloQuery';

const useMySchedulingTasksRefetch = () => {
  const apolloRefetchQuery = useApolloRefetchQuery();

  return useCallback(async () => {
    await apolloRefetchQuery([MySchedulingTasksDocument]);
  }, [apolloRefetchQuery]);
};

export default useMySchedulingTasksRefetch;
