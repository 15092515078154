import React from 'react';

import BaseIcon, { Props } from './Icon';

const SidebarOnIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00002 5.51801C4.30966 5.51801 3.75002 6.07765 3.75002 6.76801V14.768C3.75002 15.4584 4.30966 16.018 5.00002 16.018L10 16.018L10.0016 16.018C10.6912 16.0172 11.25 15.4578 11.25 14.768V6.76801C11.25 6.07765 10.6904 5.51801 10 5.51801H5.00002ZM5.00002 17.518L10 17.518L10.0023 17.518H15C16.5188 17.518 17.75 16.2868 17.75 14.768L17.75 6.768C17.75 5.24922 16.5188 4.01801 15 4.01801H10H5.00002C3.48124 4.01801 2.25002 5.24922 2.25002 6.76801V14.768C2.25002 16.2868 3.48124 17.518 5.00002 17.518Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SidebarOnIcon;
