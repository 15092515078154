import React, { FC } from 'react';

import { UserSplit } from '@modernloop/shared/icons';
import { Avatar, Chip, IconButton, List, ListItem, ListSubheader, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { DynamicAudience, OptionRenderer_EmployeesFragment } from 'src/generated/mloop-graphql';

import { HoverPopover } from './HoverPopover';
import { DynamicAudienceContext } from './types';
import { getDacIcon, getSmartOptionLabel } from './utils';

type Props = {
  context: DynamicAudienceContext;
  dynamicAudience: DynamicAudience;
  renderData: OptionRenderer_EmployeesFragment[] | null | undefined;
  onDelete: (item: DynamicAudience) => void;
  onSplit: (item: DynamicAudience) => void;
};

const getEmployeeName = (employee?: OptionRenderer_EmployeesFragment) =>
  employee?.fullName ?? employee?.givenName ?? employee?.familyName ?? '';

export const DynamicAudienceChip: FC<Props> = ({ dynamicAudience, renderData, onDelete, context, onSplit }) => {
  const multipleEmployees = !!renderData && !isEmpty(renderData) && renderData?.length > 1;
  const singleEmployeeInArray = !!renderData && !isEmpty(renderData) && renderData?.length === 1;

  let labelJsx = <>{getSmartOptionLabel(dynamicAudience)}</>;
  if (multipleEmployees) {
    labelJsx = (
      <Stack alignItems="center" direction="row">
        {getSmartOptionLabel(dynamicAudience)} ({renderData?.length} people){' '}
        <IconButton size="small" edge="end" onClick={() => onSplit(dynamicAudience)}>
          <UserSplit tooltip="Split" />
        </IconButton>
      </Stack>
    );
  } else if (singleEmployeeInArray) {
    labelJsx = (
      <Stack>
        {getSmartOptionLabel(dynamicAudience)} ({getEmployeeName(renderData?.[0])})
      </Stack>
    );
  }

  let tooltip: React.ReactNode = <></>;
  if (multipleEmployees) {
    tooltip = (
      <List>
        <ListSubheader sx={{ px: 0 }}>In this group</ListSubheader>
        {renderData?.map((employee) => (
          <ListItem key={employee.id}>
            <Stack direction="row" alignItems="center" columnGap={1}>
              <Avatar src={employee.slackImageUrl ?? ''} alt={employee.fullName || ''} />
              <Typography color="text.secondary">{employee.fullName}</Typography>
            </Stack>
          </ListItem>
        ))}
      </List>
    );
  } else if (isEmpty(renderData) && !context.enableOptionsWithMissingData) {
    tooltip = 'No person assigned';
  }

  return (
    <HoverPopover title={tooltip}>
      <Chip
        variant={isEmpty(renderData) && !context.enableOptionsWithMissingData ? 'outlined' : 'filled'}
        icon={getDacIcon(dynamicAudience)}
        size="small"
        color={isEmpty(renderData) && !context.enableOptionsWithMissingData ? 'error' : 'default'}
        label={labelJsx}
        onDelete={() => {
          onDelete(dynamicAudience);
        }}
      />
    </HoverPopover>
  );
};
