import React, { useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { CircularProgress, Divider, Link } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Alert from 'src/components/Alert';
import ImageBlock from 'src/components/ImageBlock';
import Stack from 'src/components/Stack';
import Button from 'src/components/button';
import { EmailIcon, ScheduleIcon } from 'src/components/icons';
import Label from 'src/components/label';
import Tooltip from 'src/components/tooltip';

import { getOriginalCandidateGoogleEventId } from 'src/store/selectors/scheduling';
import { ScheduleContent } from 'src/store/slices/schedule-communications';

import { Theme } from 'src/theme/type';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useSelector } from 'src/store';

import ChangingCalenderWarningDialog from './ChangingCalenderWarningDialog';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    circularProgress: {
      marginLeft: '4px',
    },

    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    button: {
      marginLeft: '-13px',
    },
  })
);
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface CandidateCommunicationsSectionProps {
  error?: string | null | undefined;
  submitting?: boolean;
  disabled?: boolean;
  scheduleContent: ScheduleContent;
  prevSlackChannelName?: string;
  candidateCommunicationsEnabled: boolean;
  candidateEventCommunicationsEnabled: boolean;
  onSubmit: (internalOnly?: boolean) => void;
  onEnableCandidateCommuncations: () => void;
  scheduleFlowType: ScheduleFlowType;
  updateWarning?: string;
  learnMore?: string;
  privateEvents: boolean;
  candidatePrivacyChanged?: boolean;
  scheduleCreated?: boolean;
  warn: boolean;
  showCandidateInRightpane: boolean;
  eventTitle: string;
  renderHours: string;
}

const CandidateCommunicationsSection: React.FC<CandidateCommunicationsSectionProps> = ({
  disabled,
  submitting,
  scheduleCreated,
  onSubmit,
  scheduleFlowType,
  prevSlackChannelName,
  updateWarning,
  learnMore,
  error,
  warn,
  showCandidateInRightpane,
  candidatePrivacyChanged,
  candidateCommunicationsEnabled,
  scheduleContent,
  candidateEventCommunicationsEnabled,
  privateEvents,
  eventTitle,
  renderHours,
  onEnableCandidateCommuncations,
}) => {
  const classes = useStyle();
  const hasPreviousCandidateEvent = !!useSelector(getOriginalCandidateGoogleEventId);
  const [isChangeCalenderWarningOpen, setIsChangeCalenderWarningOpen] = useState(false);

  // if there is a previous candidate event

  // if there is no previous candidate event, hide both

  return (
    <Stack spacing={2} direction="column">
      {error && <Alert status="error" title="Error" caption={<Label variant="captions">{error}</Label>} />}

      {((!hasPreviousCandidateEvent && (candidateCommunicationsEnabled || candidateEventCommunicationsEnabled)) ||
        hasPreviousCandidateEvent) && (
        <Stack spacing={2} direction="column">
          <Button
            disabled={disabled || submitting || scheduleCreated}
            fullWidth
            label="Finish & send"
            variant="contained"
            color="secondary"
            size="medium"
            onClick={() => {
              setIsChangeCalenderWarningOpen(true);
            }}
            endIcon={submitting ? <CircularProgress size={20} className={classes.circularProgress} /> : undefined}
          />
          {(scheduleFlowType === ScheduleFlowType.SCHEDULE || scheduleFlowType === ScheduleFlowType.RESCHEDULE) &&
            scheduleCreated && (
              <Alert status="error" title="Looks like the schedule was created, please check the candidate page." />
            )}
          {scheduleFlowType === ScheduleFlowType.RESCHEDULE && (
            <Alert
              status="warning"
              title="Your previous schedule will be canceled."
              caption=" Interviewers and your candidate will need to RSVP to this new schedule."
            />
          )}
          {scheduleFlowType === ScheduleFlowType.RESCHEDULE && prevSlackChannelName && (
            <Alert
              status="warning"
              title=""
              caption={
                <Label variant="captions">
                  This schedule was previously associated with your Slack channel <b>{prevSlackChannelName}</b>. Be sure
                  to update or archive that channel as necessary.
                </Label>
              }
            />
          )}
          {scheduleFlowType === ScheduleFlowType.UPDATE && (
            <Alert
              status="info"
              title=""
              caption={
                <Stack direction="column">
                  <Label variant="captions">{updateWarning}</Label>
                  <Link href={learnMore} target="_blank">
                    <Label variant="captions" className={classes.link}>
                      Learn more
                    </Label>
                  </Link>
                </Stack>
              }
            />
          )}

          {scheduleFlowType === ScheduleFlowType.UPDATE && candidatePrivacyChanged && warn && (
            <Alert
              status="neutral"
              title=""
              caption={
                <Stack direction="column">
                  <Label variant="captions">Candidate event privacy will still be updated.</Label>
                </Stack>
              }
            />
          )}
          {showCandidateInRightpane && (
            <Stack spacing={2} direction="column">
              <Label variant="captions" fontWeight={600}>
                Candidate communications
              </Label>
              {candidateCommunicationsEnabled && (
                <ImageBlock
                  image={<EmailIcon />}
                  title={scheduleContent.candidateEventContent.summary}
                  caption="Email"
                  noWrap
                />
              )}
              {candidateEventCommunicationsEnabled && (
                <ImageBlock
                  image={<ScheduleIcon />}
                  title={
                    <>
                      <Tooltip tooltip={eventTitle}>
                        <Label variant="body" color="foreground" noWrap>
                          {eventTitle}
                        </Label>
                      </Tooltip>
                      {privateEvents && (
                        <Label variant="body" color="foreground">
                          (Private)
                        </Label>
                      )}
                    </>
                  }
                  caption={renderHours}
                  noWrap
                />
              )}
            </Stack>
          )}
        </Stack>
      )}

      {warn && (
        <Alert
          status="pending"
          title={
            hasPreviousCandidateEvent
              ? "The events on the candidate's calendar will not update"
              : 'No candidate event is being sent yet'
          }
          caption={
            <>
              {!hasPreviousCandidateEvent && (
                <Label variant="captions">It&apos;s okay, you can send this later if you prefer.</Label>
              )}
              {hasPreviousCandidateEvent && (
                <Label variant="captions" color="error">
                  If you are changing critical information like time, date, location, or meeting links, you should
                  enable updates.
                </Label>
              )}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Button
                className={classes.button}
                onClick={onEnableCandidateCommuncations}
                variant="unstyled"
                color="warning"
              >
                <Label variant="captions" className={classes.link}>
                  Enable candidate event
                </Label>
              </Button>
            </>
          }
        />
      )}

      {isChangeCalenderWarningOpen && (
        <ChangingCalenderWarningDialog
          onClose={() => setIsChangeCalenderWarningOpen(false)}
          onSubmit={() => {
            setIsChangeCalenderWarningOpen(false);
            onSubmit(!candidateCommunicationsEnabled && !candidateEventCommunicationsEnabled);
          }}
        />
      )}
      <Divider />
    </Stack>
  );
};

export default CandidateCommunicationsSection;
