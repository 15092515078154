import { OrgSettingsSource } from 'src/constants/routes';

import { OrganizationSettingsTab, getOrganizationSettingUrls } from './getOrganizationSettingUrls';

export const useSyncedOrgSettingUrl = (source: OrgSettingsSource) => {
  switch (source) {
    case OrgSettingsSource.ORG_PRIVACY:
      return getOrganizationSettingUrls({
        tab: OrganizationSettingsTab.PRIVACY,
      });
    case OrgSettingsSource.TEMPLATES:
      return getOrganizationSettingUrls({
        tab: OrganizationSettingsTab.TEMPLATES,
      });
    case OrgSettingsSource.BRANDING_THEME:
      return `${getOrganizationSettingUrls({
        tab: OrganizationSettingsTab.CANDIDATE_PORTAL,
      })}?tab=branding-themes`;
    case OrgSettingsSource.SCHEDULING:
      return getOrganizationSettingUrls({
        tab: OrganizationSettingsTab.SCHEDULING,
      });
    default:
      return '';
  }
};
