import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Divider, Paper } from '@mui/material';

import { DebriefContentMeeting_DebriefFragment } from 'src/generated/mloop-graphql';

import DebriefContentDetails from './DebriefContentDetails';
import DebriefContentHeader from './DebriefContentHeader';

type Fragments = {
  debrief: DebriefContentMeeting_DebriefFragment;
};

type Props = {
  timezone: string;
};

const DebriefContentMeeting: FCWithFragments<Fragments, Props> = ({ debrief, timezone }) => {
  return (
    <Paper variant="outlined" sx={{ borderRadius: (theme) => theme.spacing(0.75) }}>
      <DebriefContentHeader debrief={debrief} timezone={timezone} />
      <Divider />
      <DebriefContentDetails debrief={debrief} />
    </Paper>
  );
};

DebriefContentMeeting.fragments = {
  debrief: gql`
    ${DebriefContentHeader.fragments.debrief}
    ${DebriefContentDetails.fragments.debrief}
    fragment DebriefContentMeeting_debrief on ApplicationDebrief {
      id
      ...DebriefContentHeader_debrief
      ...DebriefContentDetails_debrief
    }
  `,
};

export default DebriefContentMeeting;
