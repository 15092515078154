import { useCookieConsents } from '@modernloop/shared/hooks';

/**
 * Hook to determine if we should log statistics.
 * This is used to gate logging to Amplitude.
 */
export const useShouldLogStatistics = (): boolean => {
  const { consents, cookieConsentEnabled } = useCookieConsents();
  if (!cookieConsentEnabled) {
    return false;
  }

  return !!consents?.statistics;
};
