import { useCallback, useEffect, useState } from 'react';

const SMALL_SCREEN_SIZE = 484;

const useFullScreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const compareSize = useCallback(() => {
    if (window.innerWidth <= SMALL_SCREEN_SIZE && !isFullScreen) {
      setIsFullScreen(true);
    } else if (isFullScreen && window.innerWidth >= SMALL_SCREEN_SIZE) {
      setIsFullScreen(false);
    }
  }, [isFullScreen]);

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, [compareSize]);

  return isFullScreen;
};

export default useFullScreen;
