import React from 'react';

import BaseIcon, { Props } from './Icon';

const MinusIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 9.99999C4.25 9.58578 4.58579 9.24999 5 9.24999L15 9.24999C15.4142 9.24999 15.75 9.58578 15.75 9.99999C15.75 10.4142 15.4142 10.75 15 10.75L5 10.75C4.58579 10.75 4.25 10.4142 4.25 9.99999Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MinusIcon;
