import { LocationDescriptor } from 'history';

import useCurrentRoute from './useCurrentRoute';
import { loginWithPopupParam, useLoginWithPopup } from './useLoginWithPopup';

export type LocationState = {
  redirectUrl: string;
};

const useLoginToParams = (): LocationDescriptor<LocationState> => {
  const currentRoute = useCurrentRoute();
  // Check to see if URL before re-route is requesting a login with popup
  const showLoginWithPopup = useLoginWithPopup();
  return {
    pathname: '/login',
    search: showLoginWithPopup ? `?${loginWithPopupParam}=true` : '',
    state: {
      redirectUrl: currentRoute,
    },
  };
};

export default useLoginToParams;
