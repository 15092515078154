import React from 'react';

import { useFlag } from '@modernloop/shared/feature-flag';
import { Box, Link, Typography } from '@mui/material';

import { OrgSettingsSource } from 'src/constants/routes';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { getOrgSettingsUrls } from 'src/urls/getOrgSettingsUrl';
import { useSyncedOrgSettingUrl } from 'src/urls/useSyncedOrgSettingUrl';

interface Props {
  source: OrgSettingsSource;
}

export const SyncedFromOrgSettings: React.FC<Props> = ({ source }) => {
  const newOrgSettingsSidebar = useFlag('user_new_org_settings_sidebar');
  const settingsUrls = useSyncedOrgSettingUrl(source);
  const href = newOrgSettingsSidebar ? settingsUrls : getOrgSettingsUrls(source);

  return (
    <ConditionalThemeProvider>
      <Box my={1}>
        <Typography variant="body2" color="text.secondary">
          Synced from{' '}
          <Link href={href} target="_blank">
            <Typography variant="body2" color="info" style={{ display: 'inline' }}>
              org settings
            </Typography>
          </Link>
        </Typography>
      </Box>
    </ConditionalThemeProvider>
  );
};
