import timezoneData from './timezone-data';
import { TimezoneOption } from './timezones';
import moment from 'moment-timezone';

const timezoneStrings: TimezoneOption[] = [];
for (const { label, value, keywords } of timezoneData) {
  timezoneStrings.push({
    label: `(GMT${moment().tz(value).format('Z')}) ${label}`,
    value,
    keywords: keywords || '',
    category: 'All Timezones',
  });
}

const timestamp = new Date().getTime();
timezoneStrings.sort((a, b) => {
  return (moment.tz.zone(b.value)?.utcOffset(timestamp) ?? 0) - (moment.tz.zone(a.value)?.utcOffset(timestamp) ?? 0);
});

export { timezoneStrings };
