import React from 'react';

import BaseIcon, { Props } from './Icon';

const SlackIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 4C6.5 3.17157 7.17157 2.5 8 2.5C8.82843 2.5 9.5 3.17157 9.5 4V5.5H8C7.17157 5.5 6.5 4.82843 6.5 4ZM4 10.5C3.17157 10.5 2.5 11.1716 2.5 12C2.5 12.8284 3.17157 13.5 4 13.5C4.82843 13.5 5.5 12.8284 5.5 12V10.5H4ZM8 10.5C7.17157 10.5 6.5 11.1716 6.5 12V16C6.5 16.8284 7.17157 17.5 8 17.5C8.82843 17.5 9.5 16.8284 9.5 16V12C9.5 11.1716 8.82843 10.5 8 10.5ZM4 6.5C3.17157 6.5 2.5 7.17157 2.5 8C2.5 8.82843 3.17157 9.5 4 9.5H8C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5H4ZM14.5 8C14.5 7.17157 15.1716 6.5 16 6.5C16.8284 6.5 17.5 7.17157 17.5 8C17.5 8.82843 16.8284 9.5 16 9.5H14.5V8ZM12 2.5C11.1716 2.5 10.5 3.17157 10.5 4V8C10.5 8.82843 11.1716 9.5 12 9.5C12.8284 9.5 13.5 8.82843 13.5 8V4C13.5 3.17157 12.8284 2.5 12 2.5ZM10.5 12C10.5 11.1716 11.1716 10.5 12 10.5H16C16.8284 10.5 17.5 11.1716 17.5 12C17.5 12.8284 16.8284 13.5 16 13.5H12C11.1716 13.5 10.5 12.8284 10.5 12ZM12 14.5H10.5V16C10.5 16.8284 11.1716 17.5 12 17.5C12.8284 17.5 13.5 16.8284 13.5 16C13.5 15.1716 12.8284 14.5 12 14.5Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SlackIcon;
