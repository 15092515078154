import React from 'react';

import { Dialog } from '@modernloop/shared/components';
import { Typography } from '@mui/material';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type DiscardNoteConfirmationDialogProps = {
  onClose: () => void;
  onDiscard: () => void;
};

const DiscardNoteConfirmationDialog = ({ onClose, onDiscard }: DiscardNoteConfirmationDialogProps) => {
  return (
    <Dialog
      open
      onClose={onClose}
      title="Discard note?"
      cancelOptions={{ label: 'Keep editing', onClick: onClose }}
      submitOptions={{ label: 'Discard note', onClick: onDiscard, isDangerous: true }}
    >
      <Typography>You started composing a note, but haven&apos;t posted it yet.</Typography>
    </Dialog>
  );
};

export default DiscardNoteConfirmationDialog;
