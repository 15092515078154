import { AxiosResponse } from 'axios';

import { apiPost } from 'src/utils/api';
import ApiVerb from 'src/utils/api/ApiVerb';

export type ListCalendarEventRequest = {
  orgId: string;
  timeMin: string;
  timeMax: string;
  timezone: string;
  employeeIds: string[];
};

export enum CalendarEventStatus {
  EVENT_STATUS_CONFIRMED = 'EVENT_STATUS_CONFIRMED',
  EVENT_STATUS_TENTATIVE = 'EVENT_STATUS_TENTATIVE',
  EVENT_STATUS_CANCELLED = 'EVENT_STATUS_CANCELLED',
}

export type Organizer = {
  email?: string;
  name?: string;
  is_self?: boolean;
};

export enum AttendeeRole {
  ATTENDEE_ROLE_REQUIRED = 'ATTENDEE_ROLE_REQUIRED',
  ATTENDEE_ROLE_OPTIONAL = 'ATTENDEE_ROLE_OPTIONAL',
  ATTENDEE_ROLE_CHAIR = 'ATTENDEE_ROLE_CHAIR',
  ATTENDEE_ROLE_NONPARTICIPANT = 'ATTENDEE_ROLE_NONPARTICIPANT',
}

export enum RSVP {
  RSVP_NONE = 'RSVP_NONE',
  RSVP_ACCEPTED = 'RSVP_ACCEPTED',
  RSVP_DECLINED = 'RSVP_DECLINED',
  RSVP_TENTATIVE = 'RSVP_TENTATIVE',
  RSVP_NEEDS_ACTION = 'RSVP_NEEDS_ACTION',
}

export enum AttendeeType {
  ATTENDEE_TYPE_INDIVIDUAL = 'ATTENDEE_TYPE_INDIVIDUAL',
  ATTENDEE_TYPE_GROUP = 'ATTENDEE_TYPE_GROUP',
  ATTENDEE_TYPE_RESOURCE = 'ATTENDEE_TYPE_RESOURCE',
  ATTENDEE_TYPE_ROOM = 'ATTENDEE_TYPE_ROOM',
  ATTENDEE_TYPE_UNKNOWN = 'ATTENDEE_TYPE_UNKNOWN',
}

export const REDACTED_EVENT_ATTENDEE = 'REDACTED_EVENT_ATTENDEE';

export type Attendee = {
  email?: string;
  name?: string;
  is_rsvp_requested?: boolean;
  role?: AttendeeRole;
  rsvp?: RSVP;
  type?: AttendeeType;
};

export enum CalendarFreeBusyStatus {
  STATUS_NONE = 'STATUS_NONE',
  STATUS_BUSY = 'STATUS_BUSY',
  STATUS_FREE = 'STATUS_FREE',
  STATUS_OOO = 'STATUS_OOO',
  STATUS_TENTATIVE = 'STATUS_TENTATIVE',
  STATUS_WORKING_ELSEWHERE = 'STATUS_WORKING_ELSEWHERE',
}

export enum EventType {
  SINGLE = 'SINGLE',
  MASTER = 'MASTER',
  OCCURRENCE = 'OCCURRENCE',
  EXCEPTION = 'EXCEPTION',
}

export enum EventTag {
  ONE_ON_ONE = 'ONE_ON_ONE', // its a meeting between 2 people.
  EXTERNAL = 'EXTERNAL', // all attendees don't belong to single email domain.
  TIME_BLOCK = 'TIME_BLOCK', // meeting has no attendee and the organizer corresponds to the calendar on which this copy of the event appears.
  OOO = 'OOO', // meeting is an OOO event. We look for words like "ooo", "oof", "pto", "vto" in the event title. It also need to be a All day event.
  AVAILABLE_TIME_BLOCK = 'AVAILABLE_TIME_BLOCK', // contains words configured by RC which match the title
  UNAVAILABLE_TIME_BLOCK = 'UNAVAILABLE_TIME_BLOCK', // contains words configured by RC which match the title
  RECRUITING_BLOCK = 'RECRUITING_BLOCK', // contains words configured by RC which match the title
}

export type Event = {
  id?: string;
  calendar_id?: string;
  uid?: string;
  title?: string;
  description?: string;
  location?: string;
  status?: CalendarEventStatus;
  is_all_day?: boolean;
  start: string;
  end: string;
  master_event_id?: string;
  r_rule?: string;
  organizer?: Organizer;
  attendee?: Attendee[];
  rsvp?: RSVP;
  is_private?: boolean;
  is_read_only?: boolean;
  free_busy?: CalendarFreeBusyStatus;
  web_link?: string;
  event_type?: EventType;
  event_tag?: EventTag[];
};

export type ListCalendarEventResponse = {
  orgId: string;
  time_min: string;
  time_max: string;
  calendars: { [employeeId: string]: { events: Event[] } };
};

export const getEmployeesCalendarEventsForDay = async (
  orgId: string,
  employeeIds: string[],
  startTime: number,
  endTime: number,
  timezone: string | null = null // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
Promise<AxiosResponse<ListCalendarEventResponse>> => {
  const request: ListCalendarEventRequest = {
    orgId,
    employeeIds,
    timeMin: new Date(startTime).toISOString(),
    timeMax: new Date(endTime).toISOString(),
    timezone: timezone ?? 'UTC',
  };
  return apiPost(ApiVerb.LIST_CALENDAR_EVENT, request);
};
