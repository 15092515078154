import urlcat from 'urlcat';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { AddQueueModalOpenUrlParam } from 'src/views-new/Settings/Tasks/Queue/Modal/AddQueue';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { SettingsTabs, TaskSettingsTabs } from 'src/views-new/Settings/types';

export const getTaskSettingsTabUrl = (tab: TaskSettingsTabs, isAddQueueModalOpen: boolean) => {
  return urlcat(SettingsTabs.TASK, { tab, [AddQueueModalOpenUrlParam]: isAddQueueModalOpen });
};
export default getTaskSettingsTabUrl;
