import React, { useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { assertIsoTimestamp } from '@modernloop/shared/datetime';
import { getExternalErrorMessage, logDatadogError } from '@modernloop/shared/utils';
import { Alert, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { startOfDay } from 'date-fns';
import { useSnackbar } from 'notistack';

import {
  AddModuleLog_ModuleMemberFragment,
  TrainingStatus,
  useInterviewModuleMemberLogCreateMutation,
} from 'src/generated/mloop-graphql';

import {
  getReverseShadowPendingCount,
  getShadowPendingCount,
  resetModuleMemberListCache,
} from 'src/entities/InterviewModuleMember/utils';

import RoleChangeAlert from '../RoleChangeAlert';

import ModalBody from './Common/ModalBody';
import EditTrainigPlanModal from './EditTrainingPlanModal';

type Props = {
  onClose: () => void;
};

const RoleOptions = [
  {
    key: TrainingStatus.Shadow,
    label: 'Shadow',
  },
  {
    key: TrainingStatus.ReverseShadow,
    label: 'Reverse shadow',
  },
  {
    key: TrainingStatus.Trained,
    label: 'Trained',
  },
];

type Fragments = {
  moduleMember: AddModuleLog_ModuleMemberFragment;
};

const AddModuleLog: FCWithFragments<Fragments, Props> = ({ onClose, moduleMember }) => {
  const [selectedRole, setSelectedRole] = useState(TrainingStatus.Shadow);
  const [selectedDate, setSelectedDate] = useState(assertIsoTimestamp(startOfDay(new Date()).toISOString()));
  const { enqueueSnackbar } = useSnackbar();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [createModuleLog, { loading }] = useInterviewModuleMemberLogCreateMutation();

  const roleImpacted: TrainingStatus | null = useMemo(() => {
    // If member is already trained, no role will be changed
    if (moduleMember.status === TrainingStatus.Trained) {
      return null;
    }

    /** If member in shadow role check & shadow getting added check if shadow requirement is fullfilled */
    if (
      selectedRole === TrainingStatus.Shadow &&
      moduleMember.status === TrainingStatus.Shadow &&
      moduleMember.stats?.manualCompletedAsShadow + moduleMember.stats?.completedAsShadow + 1 >=
        moduleMember.shadowsRequired
    ) {
      /** If shadow requirement is fullfilled check is reverse shadow requirement is already fullfilled */
      if (getReverseShadowPendingCount({ moduleMember }, undefined) <= 0) {
        /** If all requirements are fullfilled */
        return TrainingStatus.Trained;
      }
      // If only shadow requirement is fullfilled
      return TrainingStatus.ReverseShadow;
    }

    /** If member in reverse shadow role check & reverse shadow getting added check if reverse shadow requirement is fullfilled */
    if (
      selectedRole === TrainingStatus.ReverseShadow &&
      getShadowPendingCount({ moduleMember }, undefined) <= 0 &&
      moduleMember.stats?.manualCompletedAsReverseShadow + moduleMember.stats?.completedAsReverseShadow + 1 >=
        moduleMember.reverseShadowsRequired
    ) {
      return TrainingStatus.Trained;
    }
    return null;
  }, [moduleMember, selectedRole]);

  const handleCreate = async () => {
    try {
      await createModuleLog({
        variables: {
          input: {
            interviewDate: selectedDate,
            memberId: moduleMember.employeeId,
            moduleId: moduleMember.interviewModuleId,
            role: selectedRole,
          },
        },
        refetchQueries: ['InterviewModuleMemberSidepanelQuery', 'InterviewModuleMemberModuleLogSidepanel'],
        update(cache) {
          if (roleImpacted) {
            resetModuleMemberListCache({
              cache,
              employeeIds: [moduleMember.employeeId],
              moduleId: moduleMember.interviewModuleId,
            });
          }
        },
      });
      enqueueSnackbar('Module log created successfully', { variant: 'success' });
      onClose();
    } catch (error) {
      logDatadogError(error);
      enqueueSnackbar(getExternalErrorMessage(error), { variant: 'error' });
    }
  };

  return (
    <>
      <Dialog
        decoration={
          <img style={{ width: '64px', height: '64px' }} src="/static/images/uploads/create_64.svg" alt="Share link" />
        }
        cancelOptions={{ label: 'Cancel', onClick: onClose }}
        submitOptions={{
          label: 'Create',
          onClick: handleCreate,
          isDisabled: loading,
          endIcon: loading ? <CircularProgress size="16px" /> : undefined,
        }}
        title="Add to module log"
        subTitle="Add a previous interview that this interviewer attended"
        open
        maxWidth="xs"
        onClose={onClose}
      >
        <Stack spacing={2.5}>
          <ModalBody
            setSelectedDate={setSelectedDate}
            setSelectedRole={setSelectedRole}
            selectedDate={selectedDate}
            selectedRole={selectedRole}
            roleOptions={RoleOptions}
          />

          <Alert
            severity="info"
            action={
              <Button variant="text" color="inherit" size="small" onClick={() => setShowEditModal(true)}>
                Edit training plan
              </Button>
            }
          >
            <Typography variant="body2">Need to add a future training requirement?</Typography>
          </Alert>
          <RoleChangeAlert roleImpacted={roleImpacted} />
        </Stack>
      </Dialog>
      {showEditModal && (
        <EditTrainigPlanModal
          moduleMember={moduleMember}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          onUpdate={() => setShowEditModal(false)}
        />
      )}
    </>
  );
};

AddModuleLog.fragments = {
  moduleMember: gql`
    ${EditTrainigPlanModal.fragments.moduleMember}
    ${getReverseShadowPendingCount.fragments.moduleMember}
    fragment AddModuleLog_moduleMember on InterviewModuleMember {
      id
      employeeId
      interviewModuleId
      shadowsRequired
      status
      reverseShadowsRequired
      stats {
        completedAsReverseShadow
        completedAsShadow
        manualCompletedAsReverseShadow
        manualCompletedAsShadow
      }
      ...EditTrainigPlan_moduleMember
      ...getReverseShadowPendingCount_moduleMember
    }
  `,
};

export const AddModuleLogQuery = gql`
  mutation InterviewModuleMemberLogCreate($input: InterviewModuleMemberLogCreateInput!) {
    interviewModuleMemberLogCreate(input: $input) {
      interviewModuleMemberManualLog {
        id
      }
    }
  }
`;

export default AddModuleLog;
