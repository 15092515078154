/* eslint-disable max-lines */
import React, { useRef } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { CanDisable } from '@modernloop/shared/helper-components';
import { Box, Divider, IconButton, Paper, Stack, TextField, Theme, Typography, inputBaseClasses } from '@mui/material';
import { noop } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

import {
  DateTimeRangeInput,
  InterviewGroup_JobFragment,
  InterviewGroup_JobStageInterviewGroupFragment,
  InterviewGroup_JobStageInterviewGroupsFragment,
  InterviewGroup_JobStageInterviewsFragment,
} from 'src/generated/mloop-graphql';

import { OrderLockIcon, OrderShuffleIcon } from 'src/components/icons';

import { DEBOUNCE_TIMEOUT } from 'src/constants';

import InterviewGroupMenu, { InterviewGroupOptions } from './InterviewGroupMenu';
import InterviewGroupOld from './InterviewGroupOld';
import JobStageInterview from './JobStageInterview';
import { InterviewMenuOptions } from './MoreMenuOptions';

type Props = {
  count: number;
  schedulable: boolean;
  readonly?: boolean;
  candidateAvailabilities?: DateTimeRangeInput[];
  onUpdated: (jobStageInterviewGroup: InterviewGroup_JobStageInterviewGroupFragment) => void;
  onDelete: (jobStageInterviewGroupId: string) => void;
  onMoveJobStageInterviewGroup: (groupId: string, position: number) => void;
  onGroupJobStageInterviewGroup: (sourceGroupIndex: number, destGroupIndex: number) => void;
  onUngroupJobStageInterviewGroup: (groupId: string) => void;

  // For Draggable
  index: number;
};

type Fragments = {
  job: InterviewGroup_JobFragment;
  jobStageInterviewGroup: InterviewGroup_JobStageInterviewGroupFragment;
  jobStageInterviewGroups: InterviewGroup_JobStageInterviewGroupsFragment[];
  jobStageInterviews: InterviewGroup_JobStageInterviewsFragment[];
};

const InterviewGroup: FCWithFragments<Fragments, Props> = ({
  job,
  jobStageInterviewGroup,
  jobStageInterviewGroups,
  jobStageInterviews,
  count,
  index,
  schedulable,
  readonly,
  candidateAvailabilities,
  onUpdated,
  onDelete,
  onMoveJobStageInterviewGroup,
  onGroupJobStageInterviewGroup,
  onUngroupJobStageInterviewGroup,
}): JSX.Element | null => {
  const breakImprovementsEnabled = useFlag('org_break_improvements');
  const { locked, name } = jobStageInterviewGroup;
  const jobStageInterviewIds: string[] =
    jobStageInterviewGroup.jobStageInterviews?.map((interview) => interview.id) || [];
  const interviewGroupNameRef = useRef<HTMLInputElement>(null);

  const debouncedOnUpdated = useDebouncedCallback(onUpdated, DEBOUNCE_TIMEOUT);

  const handleNameInputOnBlur = () => {
    if (!name) {
      onUpdated({ ...jobStageInterviewGroup, name: 'Group' });
    }
  };

  const handleInterviewGroupNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    debouncedOnUpdated({ ...jobStageInterviewGroup, name: event.target.value });
  };

  const handlePlanOrderClick = () => {
    onUpdated({ ...jobStageInterviewGroup, locked: !locked });
  };

  const handleMenuSelect = (type: InterviewGroupOptions) => {
    switch (type) {
      case InterviewGroupOptions.MOVE_UP: {
        onMoveJobStageInterviewGroup(jobStageInterviewGroup.id, index - 1);
        break;
      }
      case InterviewGroupOptions.MOVE_DOWN: {
        onMoveJobStageInterviewGroup(jobStageInterviewGroup.id, index + 1);
        break;
      }
      case InterviewGroupOptions.GROUP_WITH_BELOW: {
        onGroupJobStageInterviewGroup(index, index + 1);
        break;
      }
      case InterviewGroupOptions.GROUP_WITH_ABOVE: {
        onGroupJobStageInterviewGroup(index, index - 1);
        break;
      }
      case InterviewGroupOptions.UNGROUP: {
        onUngroupJobStageInterviewGroup(jobStageInterviewGroup.id);
        break;
      }
      case InterviewGroupOptions.RENAME: {
        setTimeout(() => interviewGroupNameRef?.current?.focus(), 1); // without a delay here, the call to .focus() doesn't work
        break;
      }
      case InterviewGroupOptions.DELETE: {
        onDelete(jobStageInterviewGroup.id);
        break;
      }
      default:
    }
  };

  const handleInterviewMenuSelect = (jobStageInterviewId: string, option: InterviewMenuOptions) => {
    switch (option) {
      case InterviewMenuOptions.MOVE_UP: {
        // If there is only 1 interview and 1 break in the group
        if (jobStageInterviewGroup.jobStageInterviews?.length === 2) {
          onMoveJobStageInterviewGroup(jobStageInterviewGroup.id, index - 1);
        } else {
          const interviews = [...(jobStageInterviewGroup.jobStageInterviews || [])];
          const interviewIndex = interviews.findIndex((interview) => interview.id === jobStageInterviewId);
          const interview = interviews.splice(interviewIndex, 2); // 2 = interview + break
          interviews.splice(interviewIndex - 2, 0, ...interview);
          onUpdated({ ...jobStageInterviewGroup, jobStageInterviews: interviews });
        }
        break;
      }
      case InterviewMenuOptions.MOVE_DOWN: {
        // If there is only 1 interview and 1 break in the group
        if (jobStageInterviewGroup.jobStageInterviews?.length === 2) {
          onMoveJobStageInterviewGroup(jobStageInterviewGroup.id, index + 1);
        } else {
          const interviews = [...(jobStageInterviewGroup.jobStageInterviews || [])];
          const interviewIndex = interviews.findIndex((interview) => interview.id === jobStageInterviewId);
          const interview = interviews.splice(interviewIndex, 2); // 2 = interview + break
          interviews.splice(interviewIndex + 2, 0, ...interview);
          onUpdated({ ...jobStageInterviewGroup, jobStageInterviews: interviews });
        }
        break;
      }
      case InterviewMenuOptions.GROUP_WITH_ABOVE: {
        onGroupJobStageInterviewGroup(index, index - 1);
        break;
      }
      case InterviewMenuOptions.GROUP_WITH_BELOW: {
        onGroupJobStageInterviewGroup(index, index + 1);
        break;
      }
      default:
    }
  };

  if (!breakImprovementsEnabled) {
    return (
      <InterviewGroupOld
        job={job}
        jobStageInterviewGroup={jobStageInterviewGroup}
        jobStageInterviewGroups={jobStageInterviewGroups}
        jobStageInterviews={jobStageInterviews}
        count={count}
        index={index}
        schedulable={schedulable}
        readonly={readonly}
        candidateAvailabilities={candidateAvailabilities}
        onUpdated={onUpdated}
        onDelete={onDelete}
        onMoveJobStageInterviewGroup={onMoveJobStageInterviewGroup}
        onGroupJobStageInterviewGroup={onGroupJobStageInterviewGroup}
        onUngroupJobStageInterviewGroup={onUngroupJobStageInterviewGroup}
      />
    );
  }

  if (!jobStageInterviewGroup.jobStageInterviews) return null;

  // if (jobStageInterviewIds.length < 2) return null;

  const separator = (
    <Box sx={{ m: '0 auto' }}>
      <Divider
        flexItem
        sx={{
          height: (theme) => theme.spacing(2.5),
          width: '1px',
          borderLeft: (theme) => `1px dashed ${theme.palette.border}`,
        }}
      />
    </Box>
  );

  const showBreak = count !== index + 1;

  return (
    <Draggable
      key={jobStageInterviewGroup.id}
      draggableId={jobStageInterviewGroup.id}
      index={index}
      isDragDisabled={readonly}
    >
      {(draggableProvided: DraggableProvided) => {
        const prevInterviewsCount = Math.floor(
          jobStageInterviewGroups
            .filter((g, i) => i < index)
            .map((g) => g.jobStageInterviews)
            .flat().length / 2 // Dividing by 2 to ignore the breaks.
        );

        const header =
          jobStageInterviewIds.length > 2 ? (
            <CanDisable disabled={Boolean(readonly)}>
              {jobStageInterviewIds.length > 2 && (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    backgroundColor: (theme: Theme) => theme.palette.background.paperHeader,
                    padding: (theme) => theme.spacing(1, 2.5),
                    margin: -1.5,
                    marginBottom: 0,
                  }}
                >
                  {locked ? <OrderLockIcon fontSize={16} /> : <OrderShuffleIcon fontSize={16} />}
                  <Typography variant="body2">
                    {jobStageInterviewGroup.locked
                      ? `This group will happen in positions ${prevInterviewsCount + 1} to ${
                          prevInterviewsCount + jobStageInterviewIds.length / 2
                        }`
                      : 'This group may swap positions'}
                  </Typography>
                </Stack>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
                sx={{ mt: 0.5, mb: 1.5 }}
              >
                <Stack direction="row" alignItems="center" spacing={1} flexGrow={1}>
                  <IconButton onClick={handlePlanOrderClick}>
                    {locked && <OrderLockIcon tooltip="Locked position" />}
                    {!locked && <OrderShuffleIcon tooltip="Shuffle position" />}
                  </IconButton>
                  <TextField
                    defaultValue={name}
                    fullWidth
                    placeholder="Group"
                    ref={interviewGroupNameRef}
                    inputProps={{ style: { fontWeight: 600, width: '100%', flex: 1 } }}
                    onChange={handleInterviewGroupNameChange}
                    onBlur={handleNameInputOnBlur}
                    sx={{
                      '& fieldset': { border: 'none' },
                      [`& .${inputBaseClasses.root}`]: {
                        backgroundColor: 'transparent',
                        border: '1px solid transparent',
                        '&:hover': { border: (theme) => `1px solid ${theme.palette.border}` },
                      },
                    }}
                  />
                </Stack>

                <InterviewGroupMenu count={count} index={index} onMenuSelect={handleMenuSelect} />
              </Stack>
            </CanDisable>
          ) : null;

        return (
          <Stack
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <Paper
              variant="outlined"
              sx={{
                padding: jobStageInterviewIds.length > 2 ? 1.5 : 0,
                borderRadius: '12px',
                border: jobStageInterviewIds.length === 2 ? 'none' : undefined,
              }}
            >
              {header}
              <Stack>
                {jobStageInterviewGroup.jobStageInterviews?.map((jobStageInterview, i) => {
                  // We will be rendering the last break in the group separately out of the Paper component.
                  if (i === jobStageInterviewIds.length - 1) return null;

                  const linkableInterviews = jobStageInterviews.filter(
                    (interview) => interview.id !== jobStageInterview.id
                  );

                  if (!jobStageInterview) return null;

                  return [
                    <JobStageInterview
                      key={jobStageInterview.id}
                      job={job}
                      jobStageInterviewId={jobStageInterview.id}
                      jobStageInterview={jobStageInterview}
                      jobStageInterviewGroups={jobStageInterviewGroups}
                      jobStageInterviews={jobStageInterviews}
                      linkableInterviews={linkableInterviews}
                      groupJobStageInterviewIds={jobStageInterviewIds}
                      index={i}
                      groupIndex={index}
                      schedulable={schedulable}
                      readonly={readonly}
                      candidateAvailabilities={candidateAvailabilities}
                      onUpdated={(newJobStageInterview) => {
                        const updatedGroup: InterviewGroup_JobStageInterviewGroupFragment = {
                          ...jobStageInterviewGroup,
                          // The below check is to ensure if there is only one interview in the group,
                          // then the group locked value should be based on the interviews isLockedOrder value.
                          locked:
                            jobStageInterviewGroup?.jobStageInterviews &&
                            jobStageInterviewGroup.jobStageInterviews.length === 2
                              ? newJobStageInterview.isLockedOrder
                              : jobStageInterviewGroup.locked,
                          jobStageInterviews: jobStageInterviewGroup.jobStageInterviews?.map((interview) => {
                            if (interview.id === jobStageInterview.id) {
                              return newJobStageInterview;
                            }
                            return interview;
                          }),
                        };
                        onUpdated(updatedGroup);
                      }}
                      onDelete={() => {
                        // If there is only 1 interview in the group then delete the entire group along with break.
                        if (jobStageInterviewGroup.jobStageInterviews?.length === 2) {
                          onDelete(jobStageInterviewGroup.id);
                        } else {
                          const jobStageInterviewsClone = [...(jobStageInterviewGroup.jobStageInterviews || [])];
                          jobStageInterviewsClone.splice(i, 2);
                          onUpdated({ ...jobStageInterviewGroup, jobStageInterviews: jobStageInterviewsClone });
                        }
                      }}
                      onMenuSelect={handleInterviewMenuSelect}
                    />,
                    i < jobStageInterviewIds.length - 2 ? separator : null,
                  ];
                })}
              </Stack>
            </Paper>

            {jobStageInterviewIds.length >= 2 && separator}

            {showBreak && jobStageInterviewGroup.jobStageInterviews && (
              <JobStageInterview
                job={job}
                jobStageInterviewId={
                  jobStageInterviewGroup.jobStageInterviews[jobStageInterviewGroup.jobStageInterviews.length - 1].id
                }
                jobStageInterview={
                  jobStageInterviewGroup.jobStageInterviews[jobStageInterviewGroup.jobStageInterviews.length - 1]
                }
                jobStageInterviewGroups={jobStageInterviewGroups}
                jobStageInterviews={jobStageInterviews}
                linkableInterviews={jobStageInterviews.filter(
                  (interview) =>
                    jobStageInterviewGroup.jobStageInterviews &&
                    interview.id !==
                      jobStageInterviewGroup.jobStageInterviews[jobStageInterviewGroup.jobStageInterviews.length - 1].id
                )}
                groupJobStageInterviewIds={jobStageInterviewIds}
                index={jobStageInterviewGroup.jobStageInterviews.length - 1}
                groupIndex={index}
                schedulable={schedulable}
                readonly={readonly}
                candidateAvailabilities={candidateAvailabilities}
                onUpdated={(newJobStageInterview) => {
                  onUpdated({
                    ...jobStageInterviewGroup,
                    jobStageInterviews: jobStageInterviewGroup.jobStageInterviews?.map((interview) => {
                      if (interview.id === newJobStageInterview.id) return newJobStageInterview;
                      return interview;
                    }),
                  });
                }}
                onDelete={noop} // break cannot be deleted
                onMenuSelect={noop} // break does not have menu
              />
            )}

            {showBreak && separator}
          </Stack>
        );
      }}
    </Draggable>
  );
};

InterviewGroup.fragments = {
  job: gql`
    ${InterviewGroupOld.fragments.job}
    ${JobStageInterview.fragments.job}
    fragment InterviewGroup_job on Job {
      id
      ...InterviewGroupOld_job
      ...JobStageInterview_job
    }
  `,
  jobStageInterviewGroup: gql`
    ${InterviewGroupOld.fragments.jobStageInterviewGroup}
    ${JobStageInterview.fragments.jobStageInterview}
    fragment InterviewGroup_jobStageInterviewGroup on JobStageInterviewGroup {
      id
      # index # Not using index in code, only using it while storing it.
      locked
      name
      jobStageInterviews {
        id
        ...JobStageInterview_jobStageInterview
      }
      ...InterviewGroupOld_jobStageInterviewGroup
    }
  `,
  jobStageInterviews: gql`
    ${InterviewGroupOld.fragments.jobStageInterviews}
    ${JobStageInterview.fragments.jobStageInterview}
    ${JobStageInterview.fragments.jobStageInterviews}
    ${JobStageInterview.fragments.linkableInterviews}
    fragment InterviewGroup_jobStageInterviews on JobStageInterview {
      id
      ...InterviewGroupOld_jobStageInterviews
      ...JobStageInterview_jobStageInterview
      ...JobStageInterview_jobStageInterviews
      ...JobStageInterview_linkableInterviews
    }
  `,
  jobStageInterviewGroups: gql`
    ${InterviewGroupOld.fragments.jobStageInterviewGroups}
    ${JobStageInterview.fragments.jobStageInterviewGroups}
    fragment InterviewGroup_jobStageInterviewGroups on JobStageInterviewGroup {
      id
      jobStageInterviews {
        id
      }
      ...InterviewGroupOld_jobStageInterviewGroups
      ...JobStageInterview_jobStageInterviewGroups
    }
  `,
};

export default InterviewGroup;
