/* eslint-disable import/prefer-default-export */
import jobStagePlanConfigSlice, { UpdateJobStagePlanConfigPayload } from 'src/store/slices/job-stage-plan-config';

import { AppThunk } from 'src/store';

export const createJobStagePlanConfig =
  (jobStageId: string): AppThunk =>
  async (dispatch) =>
    dispatch(jobStagePlanConfigSlice.actions.createJobStagePlanConfig(jobStageId));

export const updateJobStagePlanConfig =
  (jobStagePlanConfigPayload: UpdateJobStagePlanConfigPayload): AppThunk =>
  async (dispatch) =>
    dispatch(jobStagePlanConfigSlice.actions.updateJobStagePlanConfig(jobStagePlanConfigPayload));

export const clearJobStagePlanConfig = (): AppThunk => async (dispatch) =>
  dispatch(jobStagePlanConfigSlice.actions.clearJobStagePlanConfig());
