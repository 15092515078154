import React from 'react';

import BaseIcon, { Props } from './Icon';

const TimezoneIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 8C5.75 5.65279 7.65279 3.75 10 3.75C12.3472 3.75 14.25 5.65279 14.25 8C14.25 9.22132 13.7358 10.3214 12.9099 11.0976C12.3114 11.6601 11.75 12.4818 11.75 13.4847V14C11.75 14.1381 11.6381 14.25 11.5 14.25H8.5C8.36193 14.25 8.25 14.1381 8.25 14V13.4847C8.25 12.4818 7.6886 11.6601 7.09005 11.0976C6.26419 10.3214 5.75 9.22132 5.75 8Z"
        fill="#FFEB85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.75C7.65279 3.75 5.75 5.65279 5.75 8C5.75 9.22132 6.26419 10.3214 7.09005 11.0976C7.6886 11.6601 8.25 12.4818 8.25 13.4847V14C8.25 14.1381 8.36193 14.25 8.5 14.25H11.5C11.6381 14.25 11.75 14.1381 11.75 14V13.4847C11.75 12.4818 12.3114 11.6601 12.9099 11.0976C13.7358 10.3214 14.25 9.22132 14.25 8C14.25 5.65279 12.3472 3.75 10 3.75ZM4.25 8C4.25 4.82436 6.82436 2.25 10 2.25C13.1756 2.25 15.75 4.82436 15.75 8C15.75 9.65226 15.0521 11.1427 13.9372 12.1906C13.4953 12.6059 13.25 13.0597 13.25 13.4847V14C13.25 14.9665 12.4665 15.75 11.5 15.75H8.5C7.5335 15.75 6.75 14.9665 6.75 14V13.4847C6.75 13.0597 6.50468 12.6059 6.06277 12.1906C4.94786 11.1427 4.25 9.65226 4.25 8ZM8.25 17C8.25 16.5858 8.58579 16.25 9 16.25H11C11.4142 16.25 11.75 16.5858 11.75 17C11.75 17.4142 11.4142 17.75 11 17.75H9C8.58579 17.75 8.25 17.4142 8.25 17ZM10.8931 6.38587C11.1062 6.03069 10.9911 5.56999 10.6359 5.35688C10.2807 5.14377 9.81999 5.25894 9.60688 5.61413L8.10688 8.11413C7.96786 8.34583 7.96422 8.63439 8.09735 8.86952C8.23048 9.10466 8.4798 9.25 8.75 9.25H9.92536L9.10688 10.6141C8.89377 10.9693 9.00894 11.43 9.36413 11.6431C9.71931 11.8562 10.18 11.7411 10.3931 11.3859L11.8931 8.88587C12.0321 8.65417 12.0358 8.36561 11.9026 8.13048C11.7695 7.89534 11.5202 7.75 11.25 7.75H10.0746L10.8931 6.38587Z"
        fill="#4B4C5A"
      />
    </BaseIcon>
  );
};

export default TimezoneIcon;
