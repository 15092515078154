import { ThemeOptions } from '@mui/material';

export const dialogActions: ThemeOptions['components'] = {
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(0, 0, 1.5, 1.5),
        position: 'sticky',
        bottom: 0,
        padding: theme.spacing(2.5),
        paddingLeft: theme.spacing(1.5),
        width: '100%',
        backdropFilter: 'blur(4px)',
        borderTop: `1px solid ${theme.palette.borderState.default}`,
        backgroundColor: theme.palette.background.contrast,
      }),
    },
  },
};
