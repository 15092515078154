import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments, MaybeTooltip } from '@modernloop/shared/components';
import {
  Avatar,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListSubheader,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';

import { DynamicAudience, OptionRenderer_EmployeesFragment } from 'src/generated/mloop-graphql';

import { HoverPopover } from './HoverPopover';
import { getDacIcon, getSmartOptionLabel } from './utils';

type Fragments = {
  employees: OptionRenderer_EmployeesFragment[] | undefined | null;
};

type Props = {
  type: DynamicAudience;
  isChecked?: boolean;
  isDisabled?: boolean;
};

const getEmployeeName = (employee?: OptionRenderer_EmployeesFragment) =>
  employee?.fullName ?? employee?.givenName ?? employee?.familyName ?? employee?.email ?? '';

export const OptionRenderer: FCWithFragments<Fragments, Props> = ({ employees, type, isChecked, isDisabled }) => {
  const multipleEmployees = !!employees && !isEmpty(employees) && employees.length > 1;
  const singleEmployeeInArray = !!employees && !isEmpty(employees) && employees.length === 1;

  const rowLabel = useMemo(() => {
    let jsx = '';

    if (multipleEmployees) {
      jsx = `(${employees.length} people)`;
    } else if (singleEmployeeInArray) {
      jsx = `(${getEmployeeName(employees?.[0])})`;
    }
    return jsx;
  }, [employees, multipleEmployees, singleEmployeeInArray]);

  return (
    <ListItemButton disabled={isDisabled}>
      <Stack
        direction="row"
        alignItems="center"
        columnGap={1}
        sx={{
          width: '100%',
        }}
      >
        <Checkbox checked={isChecked} disabled={isDisabled} />
        <ListItemIcon
          sx={{
            color: (theme: Theme) => (isDisabled ? theme.palette.action.disabled : theme.palette.primary.main),
          }}
        >
          {getDacIcon(type)}
        </ListItemIcon>
        <HoverPopover
          title={
            multipleEmployees ? (
              <List>
                <ListSubheader sx={{ px: 0 }}>In this group</ListSubheader>
                {employees?.map((employee) => (
                  <ListItem key={employee.id} disableGutters>
                    <Stack direction="row" alignItems="center" columnGap={1}>
                      <Avatar src={employee.slackImageUrl ?? ''} alt={employee.fullName || ''} />
                      <Typography color="text.secondary">{employee.fullName}</Typography>
                    </Stack>
                  </ListItem>
                ))}
              </List>
            ) : null
          }
        >
          <MaybeTooltip
            title={`${getSmartOptionLabel(type)} ${rowLabel}`}
            label={`${getSmartOptionLabel(type)} ${rowLabel}`}
            placement="top"
          />
        </HoverPopover>
      </Stack>
    </ListItemButton>
  );
};

OptionRenderer.fragments = {
  employees: gql`
    fragment OptionRenderer_employees on Employee {
      id
      fullName
      givenName
      familyName
      slackImageUrl
      email
      isArchived
      isAtsDisabled
      hasAtsId
      isDisabled
      orgId
    }
  `,
};
