import { useEffect } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';

import { UseSlackTemplateContent_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import { updateSlackChannelMessage } from 'src/store/actions/schedule-communications';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { useDispatch } from 'src/store';

import useScheduleContent from '../TemplateContent/useScheduleContent';

type Fragments = {
  interviewPlan: UseSlackTemplateContent_InterviewPlanFragment | undefined;
};

type Props = {
  templateId?: string;
};

const useSlackTemplateContent: FunctionWithFragments<Fragments, Props, boolean> = (
  { interviewPlan },
  { templateId }
) => {
  const dispatch = useDispatch();

  const scheduleFlowData = useScheduleFlowData();
  const { candidateTimezone } = scheduleFlowData;

  const { filledTemplate, selectedScheduleId, loading } =
    useScheduleContent({ interviewPlan }, { templateId, timezone: candidateTimezone }) || {};
  useEffect(() => {
    if (templateId && filledTemplate && selectedScheduleId) {
      dispatch(updateSlackChannelMessage(selectedScheduleId, filledTemplate?.body || ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filledTemplate, selectedScheduleId]);

  return loading;
};

useSlackTemplateContent.fragments = {
  interviewPlan: gql`
    ${useScheduleContent.fragments.interviewPlan}
    fragment useSlackTemplateContent_interviewPlan on JobStage {
      id
      ...useScheduleContent_interviewPlan
    }
  `,
};

export default useSlackTemplateContent;
