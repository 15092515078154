import React, { FC, useEffect, useMemo, useRef, useState } from 'react';

import { ApolloQueryResult, gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { MaybeTooltip } from '@modernloop/shared/components';
import { CircularProgress, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import { isNil, merge, uniqBy } from 'lodash';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

import {
  InterviewModuleFragment as InterviewModuleFragmentGQL,
  InterviewModuleSearchQuery,
  InterviewModulesOrderByProperty,
  Sort,
  useInterviewModuleSelectLazyQuery,
} from 'src/generated/mloop-graphql';

import FilterSelect, { FilterListSelectInterface } from 'src/components/FilterList/FilterSelect';
import { Error } from 'src/components/HelperComponents';
import SelectButton from 'src/components/SelectButton';

import { DEBOUNCE_TIMEOUT, PAGER_DEFAULT } from 'src/constants';
import { Theme } from 'src/theme';

export const InterviewModuleFragment = gql`
  fragment InterviewModule on InterviewModule {
    id
    name
    shadowsRequired
    reverseShadowsRequired
    weeklyNumInterviewLimit
  }
`;

export const InterviewModulesSearchQuery = gql`
  query InterviewModuleSelect($input: InterviewModuleSearchInput!) {
    interviewModuleSearch(input: $input) {
      total
      items {
        ...InterviewModule
      }
    }
  }
`;

const EMPTY_ROW_ID = 'loading-empty-row';
const PAGE_LIMIT = PAGER_DEFAULT;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      width: '100%',
      marginTop: `${theme.spacing(1.5)}px`,
      marginBottom: `${theme.spacing(1.5)}px`,
    },

    optionSubheaderLabel: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    },

    box: {
      marginTop: `${theme.spacing(1.5)}px`,
    },
  })
);

interface Props {
  interviewModule?: InterviewModuleFragmentGQL;
  onInterviewModuleChange: (interviewModule: InterviewModuleFragmentGQL) => void;
  filterOptions?: (options: InterviewModuleFragmentGQL[]) => InterviewModuleFragmentGQL[];
}

const InterviewModulesSelect: FC<Props> = ({ interviewModule, onInterviewModuleChange, filterOptions }) => {
  const classes = useStyles();

  const filterListSelectInterfaceRef = useRef<FilterListSelectInterface | null>(null);

  const [fetchingMore, setFetchingMore] = useState(false);
  const [search, setSearch] = useState('');

  const [debouncedSearch] = useDebounce(search, DEBOUNCE_TIMEOUT);

  const [
    fetchInterviewModules,
    {
      data: interviewModulesData,
      loading: loadingInterviewerModules,
      error: interviewModulesError,
      updateQuery,
      fetchMore,
    },
  ] = useInterviewModuleSelectLazyQuery();

  useEffect(() => {
    fetchInterviewModules({
      variables: {
        input: {
          pageInput: {
            limit: PAGE_LIMIT,
            offset: 0,
          },
          orderBy: { property: InterviewModulesOrderByProperty.Name, direction: Sort.Asc },
          search: debouncedSearch,
        },
      },
    });
  }, [debouncedSearch, fetchInterviewModules]);

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: InterviewModuleFragmentGQL) => {
    return (
      <ListItem {...props}>
        <ListItemButton>
          {option.id === EMPTY_ROW_ID && (
            <Stack justifyContent="center">
              <CircularProgress size={20} />
            </Stack>
          )}
          {option.id !== EMPTY_ROW_ID && <MaybeTooltip label={option.name} title={option.name} />}
        </ListItemButton>
      </ListItem>
    );
  };

  const getOptionLabel = (option: InterviewModuleFragmentGQL) => {
    if (option.id === EMPTY_ROW_ID) {
      return '';
    }

    return option.name;
  };

  const defaultGetLabel = () => {
    const label = isNil(interviewModule) ? 'Select interview module' : getOptionLabel(interviewModule);

    return (
      <SelectButton
        size="medium"
        onClick={() => {}}
        label={
          <Typography noWrap color="max-contrast-grey">
            {label}
          </Typography>
        }
        className={classes.box}
      />
    );
  };

  const handleChange = (_event, options: InterviewModuleFragmentGQL[]) => {
    onInterviewModuleChange(options[0]);
    filterListSelectInterfaceRef.current?.dismiss();
  };

  const handleScrollToEnd = () => {
    if (
      !fetchMore ||
      !interviewModulesData ||
      loadingInterviewerModules ||
      !interviewModulesData.interviewModuleSearch ||
      !interviewModulesData.interviewModuleSearch.items ||
      interviewModulesData.interviewModuleSearch.items.length === interviewModulesData.interviewModuleSearch.total
    ) {
      return;
    }

    setFetchingMore(true);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    fetchMore({
      variables: {
        input: {
          orderBy: { property: InterviewModulesOrderByProperty.Name, direction: Sort.Asc },
          pageInput: { limit: PAGE_LIMIT, offset: interviewModulesData.interviewModuleSearch.items.length ?? 0 },
          search: '',
        },
      },
    })
      .then((result: ApolloQueryResult<InterviewModuleSearchQuery>) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line promise/always-return
        if (!updateQuery) return;

        updateQuery((prevResult: InterviewModuleSearchQuery) => {
          const { interviewModuleSearch } = prevResult;
          const newResults = merge({}, prevResult, result.data);

          if (newResults.interviewModuleSearch?.items) {
            newResults.interviewModuleSearch.items = uniqBy(
              [...(interviewModuleSearch?.items || []), ...(result.data?.interviewModuleSearch?.items || [])],
              'id'
            );
          }

          return { ...newResults };
        });
      })
      .finally(() => {
        setFetchingMore(false);
      });
  };

  const debouncedHandleScrollToEnd = useDebouncedCallback(handleScrollToEnd, DEBOUNCE_TIMEOUT);

  const options: InterviewModuleFragmentGQL[] = useMemo(() => {
    let result = (interviewModulesData?.interviewModuleSearch?.items || []) as InterviewModuleFragmentGQL[];

    if (fetchingMore) {
      result = [...result, { id: EMPTY_ROW_ID } as InterviewModuleFragmentGQL];
    }
    return result;
  }, [fetchingMore, interviewModulesData?.interviewModuleSearch?.items]);

  if (interviewModulesError) {
    return <Error error="Interview modules failed to load." />;
  }

  return (
    <FilterSelect
      filterListSelectInterfaceRef={filterListSelectInterfaceRef}
      options={options}
      loading={loadingInterviewerModules}
      placeholderText="Select interview module"
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      onChange={handleChange}
      getLabel={defaultGetLabel}
      onScrollToEnd={debouncedHandleScrollToEnd}
      onInputChange={(_, inputValue) => {
        setSearch(inputValue);
      }}
      getChildSize={(child: React.ReactNode) => {
        if (!child) return 0;
        return 32;
      }}
      filterOptions={filterOptions}
    />
  );
};

export default InterviewModulesSelect;
