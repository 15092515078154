import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ImageIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H15C16.5188 3.25 17.75 4.48121 17.75 6L17.75 14C17.75 15.5188 16.5188 16.75 15 16.75H5.00001C3.48123 16.75 2.25002 15.5188 2.25001 14L2.25 6ZM5 4.75C4.30964 4.75 3.75 5.30965 3.75 6L3.75001 12.5714L5.01158 11.8505C5.93388 11.3235 7.06612 11.3235 7.98841 11.8505L8.41568 12.0947C9.31318 12.6075 10.4536 12.352 11.0464 11.5051L11.8615 10.3407C12.6577 9.20323 14.3423 9.20323 15.1385 10.3407L16.25 11.9286L16.25 6C16.25 5.30964 15.6903 4.75 15 4.75H5ZM6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
