import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { CanDisable } from '@modernloop/shared/helper-components';
import { CaretDownIcon, ResetIcon } from '@modernloop/shared/icons';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  accordionClasses,
} from '@mui/material';

import { AdvancedOptions_InterviewPlanFragment, DurationInput, DurationOrgPref } from 'src/generated/mloop-graphql';

import DurationWindowPicker from 'src/components/DurationWindowPicker';

import SmartEmployeeSelect from 'src/entities/Select/SmartEmployeeSelect';

import { useOrgPrefDuration } from 'src/hooks/api/org';

type Fragments = {
  interviewPlan: AdvancedOptions_InterviewPlanFragment | undefined;
};

type Props = {
  readonly?: boolean;
  onSchedulingWindowChange: (duration: DurationInput | null) => void;
  onExcludedInterviewerChange: (employees: AdvancedOptions_InterviewPlanFragment['excludedEmployees']) => void;
  onResetToJobStageDefault?: () => void;
};

const AdvancedOptions: FCWithFragments<Fragments, Props> = ({
  interviewPlan,
  readonly,
  onSchedulingWindowChange,
  onExcludedInterviewerChange,
  onResetToJobStageDefault,
}) => {
  const orgBreakImprovementsEnabled = useFlag('org_break_improvements');
  const resetStagePlanEnabled = useFlag('user_reset_to_stage_plan');
  const [resettingToJobStageDefault, setResettingToJobStageDefault] = useState(false);

  const [orgDefaultSchedulingWindow] = useOrgPrefDuration(DurationOrgPref.DurationDefaultSchedulingWindow);

  let schedulingWindow = interviewPlan?.schedulingWindow;
  if (schedulingWindow === null || schedulingWindow === undefined) {
    schedulingWindow = orgDefaultSchedulingWindow;
  }

  if (!orgBreakImprovementsEnabled) {
    return null;
  }

  const handleResetToJobStageDefault = async () => {
    if (!onResetToJobStageDefault) return;

    setResettingToJobStageDefault(true);
    try {
      await onResetToJobStageDefault();
    } finally {
      setResettingToJobStageDefault(false);
    }
  };

  return (
    <Accordion
      variant="outlined"
      defaultExpanded
      disableGutters
      sx={{
        backgroundColor: 'transparent',
        border: (theme) => `1px dashed ${theme.palette.border}`,
        borderRadius: '12px !important',
        [`&.${accordionClasses.root}:before`]: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <AccordionSummary expandIcon={<CaretDownIcon />}>
        <Typography variant="subtitle2" color={readonly ? 'text.secondary' : undefined}>
          Advanced options
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" gap={1}>
          <Grid container flexDirection="row" alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography color={readonly ? 'text.secondary' : undefined}>Scheduling window</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DurationWindowPicker
                disabled={readonly}
                durationSec={schedulingWindow?.seconds}
                onChange={(seconds) => onSchedulingWindowChange({ seconds })}
              />
            </Grid>
          </Grid>

          <Grid container flexDirection="row" alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography color={readonly ? 'text.secondary' : undefined}>Exclude interviewers</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CanDisable disabled={Boolean(readonly)}>
                <SmartEmployeeSelect
                  selectedEmployeeIds={interviewPlan?.excludedEmployees?.map((employee) => employee.id) || []}
                  label=""
                  context={{ enableOptionsWithMissingData: false }}
                  placeholder="Select interviewers to exclude"
                  onChange={({ employees }) => {
                    onExcludedInterviewerChange(
                      employees.map((member) => ({
                        id: member.id,
                        fullName: member.fullName,
                        slackImageUrl: member.slackImageUrl,
                      }))
                    );
                  }}
                />
              </CanDisable>
            </Grid>
          </Grid>

          {resetStagePlanEnabled && onResetToJobStageDefault && (
            <Grid flexDirection="row" alignItems="center">
              <Grid item>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<ResetIcon color={!resettingToJobStageDefault ? 'primary' : undefined} />}
                  disabled={resettingToJobStageDefault}
                  endIcon={resettingToJobStageDefault ? <CircularProgress size={16} color="primary" /> : undefined}
                  onClick={handleResetToJobStageDefault}
                >
                  Reset interview plan to job stage default
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

AdvancedOptions.fragments = {
  interviewPlan: gql`
    fragment AdvancedOptions_interviewPlan on JobStage {
      id
      schedulingWindow {
        seconds
      }
      excludedEmployees {
        id
        fullName
        slackImageUrl
      }
    }
  `,
};

export default AdvancedOptions;
