import React, { createContext, useContext, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';

import {
  DateTimeRangeInput,
  ScheduleFlowDataProvider_CandidateFragment,
  ScheduleFlowDataProvider_InterviewPlanFragment,
  ScheduleFlowDataProvider_JobFragment,
  ScheduleFlowDataProvider_JobStageSettingsFragment,
} from 'src/generated/mloop-graphql';

import ScheduleInterviewPlan from 'src/views-new/ScheduleFlow/Steps/ScheduleInterviewPlan';
import UpdateCommunications from 'src/views-new/UpdateCommunications';
import UpdateCalendarView from 'src/views-new/UpdateSchedule/Schedule';

import { IS_PRODUCTION } from 'src/constants';

import Communications from './Steps/Communications';
import CalendarView from './Steps/Schedule';

export type ScheduleFlowData = {
  applicationId: string;
  applicationStageId: string | undefined;

  jobId: string;
  atsJobId: string;
  job: ScheduleFlowDataProvider_JobFragment | undefined;

  // jobStageId is always the original job stage against which the schedule is created.
  jobStageId: string;
  jobStageSettings: ScheduleFlowDataProvider_JobStageSettingsFragment | undefined;

  // interviewPlanJobStageId is the id against which the interview plan is created.
  // In case of a schedule being created without a task it will be same as jobStageId above.
  // In case of a schedule being created with a task it will be the job stage id of the interview plan stored in the task.
  interviewPlanJobStageId: string;
  interviewPlan: ScheduleFlowDataProvider_InterviewPlanFragment | undefined;

  // Task data
  createdTaskId: string | undefined;
  taskId: string | undefined;

  // Candidate data
  candidate: ScheduleFlowDataProvider_CandidateFragment | undefined;

  // Candidate availability
  candidateAvailabilities: DateTimeRangeInput[];
  candidateTimezone: string;
  greenhouseAvailabilityText: string | undefined;

  setCreatedTaskId: React.Dispatch<React.SetStateAction<string>>;
  setCandidateAvailabilities: React.Dispatch<React.SetStateAction<DateTimeRangeInput[]>>;
  setCandidateTimezone: React.Dispatch<React.SetStateAction<string>>;
  setInterviewPlan: React.Dispatch<React.SetStateAction<ScheduleFlowDataProvider_InterviewPlanFragment | undefined>>;
};

const ScheduleFlowDataContext = createContext<ScheduleFlowData | undefined>(undefined);

export const useScheduleFlowData = (): ScheduleFlowData => {
  const context = useContext(ScheduleFlowDataContext);

  if (!context) {
    throw new Error('useSchduleFlowData must be used within a ScheduleFlowDataProvider');
  }
  return context;
};

type Props = {
  applicationId: string;
  applicationStageId: string | undefined;
  candidateAvailabilities: DateTimeRangeInput[];
  candidateTimezone: string;
  greenhouseAvailabilityText: string | undefined;
  jobId: string;
  atsJobId: string;
  jobStageId: string;
  interviewPlanJobStageId: string;
  taskId: string | undefined;
};

type Fragments = {
  candidate: ScheduleFlowDataProvider_CandidateFragment | undefined;
  job: ScheduleFlowDataProvider_JobFragment | undefined;
  interviewPlan: ScheduleFlowDataProvider_InterviewPlanFragment | undefined;
  jobStageSettings: ScheduleFlowDataProvider_JobStageSettingsFragment | undefined;
};

const ScheduleFlowDataProvider: FCWithFragments<Fragments, Props> = ({
  applicationId,
  applicationStageId,
  candidateAvailabilities,
  candidateTimezone,
  greenhouseAvailabilityText,
  jobId,
  atsJobId,
  jobStageId,
  jobStageSettings,
  interviewPlanJobStageId,
  candidate,
  job,
  interviewPlan: interviewPlanProp,
  taskId,
  children,
}) => {
  const [createdTaskId, setCreatedTaskId] = useState<string | undefined>(undefined);
  const [availabilities, setCandidateAvailabilities] = useState<DateTimeRangeInput[]>(candidateAvailabilities);
  const [candidateTz, setCandidateTimezone] = useState<string>(candidateTimezone);
  const [interviewPlan, setInterviewPlan] = useState<ScheduleFlowDataProvider_InterviewPlanFragment | undefined>(
    interviewPlanProp
  );

  if (!job && !IS_PRODUCTION) {
    throw new Error('Job is required for ScheduleFlowDataProvider');
  }

  return (
    <ScheduleFlowDataContext.Provider
      value={{
        applicationId,
        applicationStageId,
        candidate,
        candidateTimezone: candidateTz,
        jobId,
        job,
        atsJobId,
        jobStageId,
        jobStageSettings,
        interviewPlanJobStageId,
        interviewPlan,
        taskId,
        createdTaskId,
        candidateAvailabilities: availabilities,
        greenhouseAvailabilityText,

        setCreatedTaskId,
        setCandidateAvailabilities,
        setCandidateTimezone,
        setInterviewPlan,
      }}
    >
      {children}
    </ScheduleFlowDataContext.Provider>
  );
};

ScheduleFlowDataProvider.fragments = {
  candidate: gql`
    ${Communications.fragments.candidate}
    ${UpdateCommunications.fragments.candidate}
    fragment ScheduleFlowDataProvider_candidate on Candidate {
      id
      ...Communications_candidate
      ...UpdateCommunications_candidate
    }
  `,
  job: gql`
    ${ScheduleInterviewPlan.fragments.job}
    fragment ScheduleFlowDataProvider_job on Job {
      id
      ...ScheduleInterviewPlan_job
    }
  `,
  interviewPlan: gql`
    ${CalendarView.fragments.interviewPlan}
    ${UpdateCalendarView.fragments.interviewPlan}
    ${Communications.fragments.interviewPlan}
    ${ScheduleInterviewPlan.fragments.jobStage}
    ${UpdateCommunications.fragments.interviewPlan}

    fragment ScheduleFlowDataProvider_interviewPlan on JobStage {
      id
      # Note (hemant): interviewPlan is not used in this file.
      # But interview plan is used in schedule flow at multiple places.
      ...ScheduleInterviewPlan_jobStage
      ...Communications_interviewPlan
      ...CalendarView_interviewPlan
      ...UpdateCalendarView_interviewPlan
      ...UpdateCommunications_interviewPlan
    }
  `,
  jobStageSettings: gql`
    ${Communications.fragments.jobStageSettings}
    fragment ScheduleFlowDataProvider_jobStageSettings on JobStageSettingsV2 {
      id
      ...Communications_jobStageSettings
    }
  `,
};

export default ScheduleFlowDataProvider;
