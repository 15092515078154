import React, { FC, useEffect, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  MemberSelect_UserInfoFragment,
  SourceType,
  SubscribersSelect_ThisEmployeeFragment,
  useCurrentEmployeeDetailsQuery,
  useSubscriberAddMutation,
  useSubscriberRemoveMutation,
  useSubscribersListQuery,
} from 'src/generated/mloop-graphql';

import IconButton from 'src/components/IconButton';
import Button from 'src/components/button';
import { TriangleDownIcon, UserAddIcon } from 'src/components/icons';
import { createSxProps } from 'src/components/types';

import EmployeeFacePile from 'src/entities/common/EmployeeFacePile';

import { useUserInfo } from 'src/hooks/useEmployeeId';

import { PAGE_SIZE } from 'src/constants';

import MultiMemberSelect from '../Select/MemberSelect/MultiMemberSelect';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CurrentEmployeeDetailsGQL = gql`
  fragment SubscribersSelect_thisEmployee on Employee {
    id
    fullName
    slackImageUrl
  }
  query CurrentEmployeeDetails {
    thisEmployee {
      id
      ...SubscribersSelect_thisEmployee
    }
  }
`;

const SubscribersSelectEmployee = gql`
  ${EmployeeFacePile.fragments.employees}
  fragment SubscribersSelect_employee on Employee {
    id
    ...EmployeeFacePile_employee
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SubscribersListGQL = gql`
  ${SubscribersSelectEmployee}

  query SubscribersList($input: SubscribersInput!) {
    subscribers(input: $input) {
      items {
        id

        ...SubscribersSelect_employee
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SubscriberAddMutation = gql`
  ${SubscribersSelectEmployee}

  mutation SubscriberAdd($input: SubscriberAddInput!) {
    subscriberAdd(input: $input) {
      result
      subscribers {
        id

        ...SubscribersSelect_employee
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SubscriberRemoveMutation = gql`
  ${SubscribersSelectEmployee}

  mutation SubscriberRemove($input: SubscriberRemoveInput!) {
    subscriberRemove(input: $input) {
      result
      subscribers {
        id

        ...SubscribersSelect_employee
      }
    }
  }
`;

const useSxProps = () => {
  const sxProps = useMemo(
    () => ({
      root: {
        marginRight: '-8px',
      },
      employeeFacePile: {
        marginRight: '-4px',
      },
    }),
    []
  );

  return createSxProps(sxProps);
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface SubscribersSelectProps {
  sourceId: string;
  sourceType: SourceType;
  showSubscribeButton?: boolean;
}

const EMPTY_MEMBER_SELECT_USER_INFO_FRAGMENT_OBJECT: MemberSelect_UserInfoFragment = {
  userId: null,
  userName: '',
  employee: {
    id: null,
    slackImageUrl: null,
  },
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const SubscribersSelect: FC<SubscribersSelectProps> = ({ sourceId, sourceType, showSubscribeButton = false }) => {
  const sxProps = useSxProps();
  const [selectedSubscribers, setSelectedSubscribers] = useState<MemberSelect_UserInfoFragment[]>([]);

  const {
    data: currentEmployeeData,
    loading: loadingCurrentEmployeeData,
    error: currentEmployeeDataError,
  } = useCurrentEmployeeDetailsQuery();

  const currentUserInfo = useUserInfo();
  const currentEmployeeDetails =
    currentEmployeeData && !loadingCurrentEmployeeData && !currentEmployeeDataError
      ? currentEmployeeData?.thisEmployee
      : ({} as SubscribersSelect_ThisEmployeeFragment);

  const currentEmployeeId = currentEmployeeDetails?.id ?? '';

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: subscribersListData,
    error,
    loading,
  } = useSubscribersListQuery({
    variables: {
      input: {
        pageInput: {
          limit: PAGE_SIZE,
        },
        sourceId,
        sourceType,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (
      subscribersListData?.subscribers?.items &&
      subscribersListData?.subscribers?.items.length > 0 &&
      !loading &&
      !error
    ) {
      setSelectedSubscribers(
        subscribersListData?.subscribers?.items.map((subscriber) => ({
          userId: subscriber.id,
          userName: subscriber.fullName || '',
          employee: {
            id: subscriber.id,
            slackImageUrl: subscriber.slackImageUrl,
          },
        }))
      );
    }
  }, [error, loading, subscribersListData?.subscribers?.items]);

  const [addSubscribers] = useSubscriberAddMutation();

  const [removeSubscribers] = useSubscriberRemoveMutation();

  const onSelectedSubscribersChange = (newSubscribers: MemberSelect_UserInfoFragment[]) => {
    setSelectedSubscribers(newSubscribers);

    if (newSubscribers.length > selectedSubscribers.length) {
      const difference: MemberSelect_UserInfoFragment[] = newSubscribers.filter(
        (newSubscriber: MemberSelect_UserInfoFragment) =>
          !selectedSubscribers.find(
            (selectedSubscriber: MemberSelect_UserInfoFragment) => selectedSubscriber.userId === newSubscriber.userId
          )
      );

      addSubscribers({
        variables: {
          input: {
            sourceId,
            sourceType,
            employeeIds: difference.map((subscriber: MemberSelect_UserInfoFragment) => subscriber.employee?.id),
          },
        },
      }).catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
    } else {
      const difference: MemberSelect_UserInfoFragment[] = selectedSubscribers.filter(
        (newSubscriber: MemberSelect_UserInfoFragment) =>
          !newSubscribers.find(
            (selectedSubscriber: MemberSelect_UserInfoFragment) => selectedSubscriber.userId === newSubscriber.userId
          )
      );

      removeSubscribers({
        variables: {
          input: {
            sourceId,
            sourceType,
            employeeIds: difference.map((subscriber: MemberSelect_UserInfoFragment) => subscriber.employee?.id),
          },
        },
      }).catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
    }
  };

  const isCurrentEmployeeSubscribed = selectedSubscribers.some(
    (subscriber) => subscriber.employee?.id === currentEmployeeId
  );

  const onSubscribeButtonClick = () => {
    if (isCurrentEmployeeSubscribed) {
      onSelectedSubscribersChange(
        selectedSubscribers.filter((subscriber) => subscriber.employee?.id !== currentEmployeeId)
      );
    } else {
      onSelectedSubscribersChange([
        ...selectedSubscribers,
        {
          ...EMPTY_MEMBER_SELECT_USER_INFO_FRAGMENT_OBJECT,
          userId: currentUserInfo?.userId,
          userName: currentUserInfo?.userName || '',
          employee: {
            id: currentEmployeeId,
            slackImageUrl: currentEmployeeDetails?.slackImageUrl || '',
          },
        },
      ]);
    }
  };

  return (
    <Stack direction="row" alignItems="center" sx={sxProps.root}>
      {showSubscribeButton ? (
        <Button
          dataTestId="subscribe-button"
          variant="unstyled"
          onClick={onSubscribeButtonClick}
          size="small"
          label={isCurrentEmployeeSubscribed ? 'Unsubscribe' : 'Subscribe'}
        />
      ) : null}
      <MultiMemberSelect
        selectedEmployeeIds={selectedSubscribers.map((subscriber) => subscriber.employee?.id)}
        placeholderText="Search employees"
        onChange={onSelectedSubscribersChange}
        getLabel={(values: MemberSelect_UserInfoFragment[]) => {
          if (values.length > 0) {
            return (
              <Button
                dataTestId="filter-select-button"
                endIcon={<TriangleDownIcon />}
                variant="unstyled"
                label={
                  <EmployeeFacePile
                    sx={sxProps.employeeFacePile}
                    employees={selectedSubscribers.map((subscriber) => ({
                      id: subscriber.userId,
                      fullName: subscriber.userName,
                      slackImageUrl: subscriber.employee?.slackImageUrl || '',
                    }))}
                    max={3}
                  />
                }
              />
            );
          }
          return (
            <IconButton dataTestId="filter-select-button">
              <UserAddIcon />
            </IconButton>
          );
        }}
      />
    </Stack>
  );
};

export default SubscribersSelect;
