import { addMinutes, parseISO } from 'date-fns';

import { DateTimeRangeInput } from 'src/generated/mloop-graphql';

import { getAllJobStageInterviewByJobStageId, getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';

import { isOverlappingOnCalendar, sameOrAfter, sameOrBefore } from 'src/utils/dateUtils';

import { useSelector } from 'src/store';

const useWarningForStartAt = (
  jobStageInterviewId: string,
  candidateAvailabilities: DateTimeRangeInput[] | undefined
): string | null => {
  const jobStageInterview = useSelector((state) => getJobStageInterviewById(state, jobStageInterviewId));
  const jobStageInterviews = useSelector((state) =>
    getAllJobStageInterviewByJobStageId(state, jobStageInterview.jobStageId)
  );

  if (!jobStageInterview.forcedStartAt || !candidateAvailabilities) {
    return null;
  }

  const jobStageInterviewStartAt = parseISO(jobStageInterview.forcedStartAt);
  const jobStageInterviewEndAt = addMinutes(jobStageInterviewStartAt, jobStageInterview.duration || 0);

  const isCandidateAvailable = candidateAvailabilities.reduce((result, candidateAvailability) => {
    if (result) return result;
    const candidateStartAt = parseISO(candidateAvailability.startAt);
    const candidateEndAt = parseISO(candidateAvailability.endAt);
    const isInterviewStartValid = sameOrAfter(jobStageInterviewStartAt, candidateStartAt);
    const isInterviewEndValid = sameOrBefore(jobStageInterviewEndAt, candidateEndAt);
    return isInterviewStartValid && isInterviewEndValid;
  }, false);

  if (!isCandidateAvailable) {
    return 'Start time is outside candidate availability';
  }

  const isInterviewOverlapping = jobStageInterviews
    .filter((otherJobStageInterview) => otherJobStageInterview.id !== jobStageInterview.id)
    .reduce((result, otherJobStageInterview) => {
      if (result || !otherJobStageInterview.forcedStartAt) return result;
      const otherStartAt = parseISO(otherJobStageInterview.forcedStartAt);
      const otherEndAt = addMinutes(
        parseISO(otherJobStageInterview.forcedStartAt),
        otherJobStageInterview.duration || 0
      );
      return isOverlappingOnCalendar(jobStageInterviewStartAt, jobStageInterviewEndAt, otherStartAt, otherEndAt);
    }, false);

  if (isInterviewOverlapping) {
    return 'Time overlaps with another interview';
  }

  return null;
};

export default useWarningForStartAt;
