import React, { FC, createContext, useContext, useEffect, useReducer } from 'react';

import { noop } from 'lodash';

import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';

import { OmniSearchAction, OmniSearchActionType } from './types';

/**
 * OmniSearchProvider
 * - The aim for OmniSearchProvider is to allow us to be able to dispatch any action
 *   that OmniSearch responds to from anywhere in the app.
 * - E.g. of some actions:
 *   - Launching or hiding OmniSearch.
 *   - Open a modal to create interview module.
 *   - Pausing an interviewer
 *   - etc.
 * - Creating 2 contexts OmniSearchStateContext & OmniSearchDispatchContext which is used to wrap the App
 *   and make the state and dispatch available to components that want to show UI based or state or dispatch action.
 * - Creating helper hooks useOmniSearchState & useOmniSearchDispatch for ease of use.
 * - Any component that wants to take action needs to dispatch using
 *   - const omniSearchDispatch = useOmniSearchDispatch();
 *   - omniSearchDispatch({...action});
 * - Any component that wants to show some UI based on action taken through omniSearchDispatch can look at state using.
 *   - const omniSearchState = useOmniSearchState();
 *   - // Inspect state and take necessary action like showing dialog, navigating, etc.
 *
 * Note: Please don't use this state and dispatch for actions that are not related to or triggered by omni search.
 */

type OmniSearchProviderState = {
  actionType: OmniSearchActionType | undefined;
  isInExtension: boolean;
};

const inititalState: OmniSearchProviderState = {
  actionType: undefined,
  isInExtension: false,
};

export const ShowOmniSearchAction: OmniSearchAction = { type: OmniSearchActionType.Show };
export const HideOmniSearchAction: OmniSearchAction = { type: OmniSearchActionType.Hide };
export const SetIsInExtensionAction: OmniSearchAction = {
  type: OmniSearchActionType.IsInExtension,
  isInExtension: true,
};

const reducer = (state: OmniSearchProviderState, action: OmniSearchAction): OmniSearchProviderState => {
  const { type } = action;
  switch (type) {
    case OmniSearchActionType.Show:
    case OmniSearchActionType.Hide: {
      return { ...state, actionType: type };
    }

    case OmniSearchActionType.IsInExtension: {
      return { ...state, ...action };
    }

    default:
      return state;
  }
};

const OmniSearchStateContext = createContext<OmniSearchProviderState>(inititalState);
const OmniSearchDispatchContext = createContext<React.Dispatch<OmniSearchAction>>(noop);

export const useOmniSearchState = () => {
  const state = useContext(OmniSearchStateContext);
  return state;
};

export const useOmniSearchDispatch = () => {
  const dispatch = useContext(OmniSearchDispatchContext);
  return dispatch;
};

const OmniSearchProvider: FC = ({ children }) => {
  const { logEvent } = useLogEvent();
  const [state, dispatch] = useReducer(reducer, inititalState);

  useEffect(() => {
    if (state.actionType === OmniSearchActionType.Show) {
      logEvent(LoggerEvent.CLIENT_OMNI_SEARCH_LAUNCHED);
    }
  }, [logEvent, state]);

  return (
    <OmniSearchStateContext.Provider value={state}>
      <OmniSearchDispatchContext.Provider value={dispatch}>{children}</OmniSearchDispatchContext.Provider>
    </OmniSearchStateContext.Provider>
  );
};

export default OmniSearchProvider;
