import { SvgIcon, SvgIconProps } from './SvgIcon';

export const TextIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.43982 4.81982C4.02561 4.81982 3.68982 5.15561 3.68982 5.56982C3.68982 5.98404 4.02561 6.31982 4.43982 6.31982H7.76013L7.76013 15.6425C7.76013 16.0567 8.09592 16.3925 8.51013 16.3925C8.92435 16.3925 9.26013 16.0567 9.26013 15.6425L9.26013 6.31982H12.5804C12.9947 6.31982 13.3304 5.98404 13.3304 5.56982C13.3304 5.15561 12.9947 4.81982 12.5804 4.81982H8.51013H4.43982Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8313 9.32959C11.4171 9.32959 11.0813 9.66538 11.0813 10.0796C11.0813 10.4938 11.4171 10.8296 11.8313 10.8296H13.4555L13.4555 15.955C13.4555 16.3692 13.7913 16.705 14.2055 16.705C14.6197 16.705 14.9555 16.3692 14.9555 15.955L14.9555 10.8296H16.5798C16.994 10.8296 17.3298 10.4938 17.3298 10.0796C17.3298 9.66538 16.994 9.32959 16.5798 9.32959H14.2055H11.8313Z"
      />
    </SvgIcon>
  );
};
