import React from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';

import {
  DynamicAudience,
  UseTaskSmartOptions_TaskAssigneeDataFragment,
  UseTaskSmartOptions_TaskCreatorDataFragment,
  UseTaskSmartOptions_TaskDebriefDataFragment,
  UseTaskSmartOptions_TaskInterviewersDataFragment,
  UseTaskSmartOptions_TaskSubscriberDataFragment,
  useUseTaskSmartOptionsEmployeeByIdsQuery,
} from 'src/generated/mloop-graphql';

import { OptionRenderer } from './OptionRenderer';
import { DynamicAudienceContextTask } from './types';

type Props = {
  task?: DynamicAudienceContextTask;
};

type Fragments = {
  taskAssigneeData: UseTaskSmartOptions_TaskAssigneeDataFragment[];
  taskCreatorData: UseTaskSmartOptions_TaskCreatorDataFragment[];
  taskDebriefData: UseTaskSmartOptions_TaskDebriefDataFragment[];
  taskSubscriberData: UseTaskSmartOptions_TaskSubscriberDataFragment[];
  taskInterviewersData: UseTaskSmartOptions_TaskInterviewersDataFragment[];
};

export const DynamicEmployee = gql`
  ${OptionRenderer.fragments.employees}
  query UseTaskSmartOptionsEmployeeByIds(
    $taskAssigneeEmployeeIds: [uuid!]!
    $taskInterviewersOnScheduleEmployeeIds: [uuid!]!
    $taskCreatorEmployeeIds: [uuid!]!
    $taskSubscriberEmployeeIds: [uuid!]!
    $taskDebriefEmployeeIds: [uuid!]!
  ) {
    taskAssigneeData: employeeByIds(ids: $taskAssigneeEmployeeIds) {
      ...OptionRenderer_employees
    }
    taskSubscriberData: employeeByIds(ids: $taskSubscriberEmployeeIds) {
      ...OptionRenderer_employees
    }
    taskDebriefData: employeeByIds(ids: $taskDebriefEmployeeIds) {
      ...OptionRenderer_employees
    }
    taskInterviewersData: employeeByIds(ids: $taskInterviewersOnScheduleEmployeeIds) {
      ...OptionRenderer_employees
    }
    taskCreatorData: employeeByIds(ids: $taskCreatorEmployeeIds) {
      ...OptionRenderer_employees
    }
  }
`;

type HookReturn = {
  options: DynamicAudience[];
  renderer: (props: { optionType: DynamicAudience; isDisabled: boolean; isChecked: boolean }) => JSX.Element | null;
  getData: (
    optionType: DynamicAudience
  ) =>
    | UseTaskSmartOptions_TaskAssigneeDataFragment[]
    | UseTaskSmartOptions_TaskCreatorDataFragment[]
    | UseTaskSmartOptions_TaskDebriefDataFragment[]
    | UseTaskSmartOptions_TaskSubscriberDataFragment[]
    | UseTaskSmartOptions_TaskInterviewersDataFragment[]
    | undefined
    | null;
};

export const useTaskSmartOptions: FunctionWithFragments<Fragments, Props, HookReturn> = (
  { taskAssigneeData, taskCreatorData, taskDebriefData, taskInterviewersData, taskSubscriberData },
  { task }
) => {
  const localContext = typeof task !== 'string' && typeof task !== 'boolean' && !!task;

  const { data: localContextData } = useUseTaskSmartOptionsEmployeeByIdsQuery({
    variables: {
      taskAssigneeEmployeeIds: localContext ? [task?.TASK_ASSIGNEE] : [],
      taskCreatorEmployeeIds: localContext ? [task?.TASK_CREATOR] : [],
      taskSubscriberEmployeeIds: localContext ? [...(task?.TASK_SUBSCRIBERS ?? [])] : [],
      taskDebriefEmployeeIds: localContext ? [...(task?.DEBRIEF_ATTENDEES ?? [])] : [],
      taskInterviewersOnScheduleEmployeeIds: localContext ? [...(task?.INTERVIEWERS_ON_SCHEDULE ?? [])] : [],
    },
    skip: !localContext,
  });

  const returnOptionData = (optionType: DynamicAudience) => {
    switch (optionType) {
      case DynamicAudience.TaskAssignee:
        if (localContext) {
          return localContextData?.taskAssigneeData;
        }
        return taskAssigneeData;
      case DynamicAudience.TaskCreator:
        if (localContext) {
          return localContextData?.taskCreatorData;
        }
        return taskCreatorData;
      case DynamicAudience.TaskSubscribers:
        if (localContext) {
          return localContextData?.taskSubscriberData;
        }
        return taskSubscriberData;
      case DynamicAudience.DebriefAttendees:
        if (localContext) {
          return localContextData?.taskDebriefData;
        }
        return taskDebriefData;
      case DynamicAudience.InterviewersOnSchedule:
        if (localContext) {
          return localContextData?.taskInterviewersData;
        }
        return taskInterviewersData;
      default:
        return [];
    }
  };

  return {
    options: [
      DynamicAudience.TaskAssignee,
      DynamicAudience.TaskCreator,
      DynamicAudience.TaskSubscribers,
      DynamicAudience.DebriefAttendees,
      DynamicAudience.InterviewersOnSchedule,
    ],
    renderer: ({
      optionType,
      isDisabled,
      isChecked,
    }: {
      optionType: DynamicAudience;
      isDisabled: boolean;
      isChecked: boolean;
    }) => {
      switch (optionType) {
        case DynamicAudience.TaskAssignee:
          return (
            <OptionRenderer
              type={DynamicAudience.TaskAssignee}
              employees={returnOptionData(DynamicAudience.TaskAssignee)}
              isDisabled={isDisabled}
              isChecked={isChecked}
            />
          );
        case DynamicAudience.TaskCreator:
          return (
            <OptionRenderer
              type={DynamicAudience.TaskCreator}
              employees={returnOptionData(DynamicAudience.TaskCreator)}
              isDisabled={isDisabled}
              isChecked={isChecked}
            />
          );
        case DynamicAudience.TaskSubscribers:
          return (
            <OptionRenderer
              type={DynamicAudience.TaskSubscribers}
              employees={returnOptionData(DynamicAudience.TaskSubscribers)}
              isDisabled={isDisabled}
              isChecked={isChecked}
            />
          );
        case DynamicAudience.DebriefAttendees:
          return (
            <OptionRenderer
              type={DynamicAudience.DebriefAttendees}
              employees={returnOptionData(DynamicAudience.DebriefAttendees)}
              isDisabled={isDisabled}
              isChecked={isChecked}
            />
          );
        case DynamicAudience.InterviewersOnSchedule:
          return (
            <OptionRenderer
              type={DynamicAudience.InterviewersOnSchedule}
              employees={returnOptionData(DynamicAudience.InterviewersOnSchedule)}
              isDisabled={isDisabled}
              isChecked={isChecked}
            />
          );
        default:
          return null;
      }
    },
    getData: (optionType: DynamicAudience) => {
      switch (optionType) {
        case DynamicAudience.TaskAssignee:
          return returnOptionData(DynamicAudience.TaskAssignee);
        case DynamicAudience.TaskCreator:
          return returnOptionData(DynamicAudience.TaskCreator);
        case DynamicAudience.TaskSubscribers:
          return returnOptionData(DynamicAudience.TaskSubscribers);
        case DynamicAudience.DebriefAttendees:
          return returnOptionData(DynamicAudience.DebriefAttendees);
        case DynamicAudience.InterviewersOnSchedule:
          return returnOptionData(DynamicAudience.InterviewersOnSchedule);
        default:
          return [];
      }
    },
  };
};

useTaskSmartOptions.fragments = {
  taskAssigneeData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useTaskSmartOptions_taskAssigneeData on Employee {
      ...OptionRenderer_employees
    }
  `,
  taskCreatorData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useTaskSmartOptions_taskCreatorData on Employee {
      ...OptionRenderer_employees
    }
  `,
  taskDebriefData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useTaskSmartOptions_taskDebriefData on Employee {
      ...OptionRenderer_employees
    }
  `,
  taskSubscriberData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useTaskSmartOptions_taskSubscriberData on Employee {
      ...OptionRenderer_employees
    }
  `,
  taskInterviewersData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useTaskSmartOptions_taskInterviewersData on Employee {
      ...OptionRenderer_employees
    }
  `,
};
