import ApiVerb from './ApiVerb';
import { apiPost } from './index';

export interface RequestBody {
  applicationStageInterviewId: string;
}

const cancelInterview = async (data: RequestBody): Promise<void> => {
  const response = await apiPost(ApiVerb.CANCEL_INTERVIEW, data);
  return response.data;
};

export default cancelInterview;
