import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ChatBubbleWithResetArrowIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 8C2.75 5.65279 4.65279 3.75 7 3.75H13C15.3472 3.75 17.25 5.65279 17.25 8C17.25 8.55607 17.1432 9.08719 16.949 9.57398C17.4093 9.80327 17.833 10.0952 18.2088 10.4386C18.556 9.69824 18.75 8.87178 18.75 8C18.75 4.82436 16.1756 2.25 13 2.25H7C3.82436 2.25 1.25 4.82436 1.25 8C1.25 9.74331 2.02649 11.3054 3.24997 12.359V13.8943C3.24997 15.4125 4.87496 16.3771 6.20771 15.6501L8.84267 14.2128L8.28162 12.8102L5.4894 14.3333C5.15621 14.515 4.74997 14.2739 4.74997 13.8943V12.0003C4.74997 11.7645 4.639 11.5423 4.4504 11.4007C3.41645 10.624 2.75 9.38995 2.75 8ZM11.2943 15.19C11.2466 15.2104 11.1963 15.226 11.1442 15.2362C10.9448 15.2754 10.742 15.2308 10.5797 15.1213C10.4518 15.0345 10.3521 14.9094 10.2972 14.7623L9.30364 12.2785C9.19098 11.9969 9.25908 11.6751 9.47618 11.4632C9.69327 11.2514 10.0166 11.1911 10.2954 11.3106L11.2759 11.7308C12.0553 10.8242 13.2106 10.25 14.5 10.25C16.8472 10.25 18.75 12.1528 18.75 14.5C18.75 16.8472 16.8472 18.75 14.5 18.75C13.7271 18.75 13.0003 18.543 12.3745 18.181C12.0159 17.9736 11.8934 17.5148 12.1008 17.1562C12.3082 16.7977 12.767 16.6752 13.1255 16.8826C13.5293 17.1161 13.9979 17.25 14.5 17.25C16.0188 17.25 17.25 16.0188 17.25 14.5C17.25 12.9812 16.0188 11.75 14.5 11.75C13.8411 11.75 13.2364 11.9817 12.7628 12.3681L13.7954 12.8106C14.0743 12.9301 14.2536 13.2058 14.2499 13.5091C14.2462 13.8125 14.0602 14.0837 13.7785 14.1964L11.2943 15.19ZM6.5 9C7.05228 9 7.5 8.55228 7.5 8C7.5 7.44772 7.05228 7 6.5 7C5.94772 7 5.5 7.44772 5.5 8C5.5 8.55228 5.94772 9 6.5 9ZM11 8C11 8.55228 10.5523 9 10 9C9.44771 9 9 8.55228 9 8C9 7.44772 9.44771 7 10 7C10.5523 7 11 7.44772 11 8ZM13.5 9C14.0523 9 14.5 8.55228 14.5 8C14.5 7.44772 14.0523 7 13.5 7C12.9477 7 12.5 7.44772 12.5 8C12.5 8.55228 12.9477 9 13.5 9Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
