/* eslint-disable modernloop/restrict-imports.cjs */
import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';

import LeverCreateCandidatePermissionDialog from 'src/views-new/JobDetailsPage/SourcingLinks/AtsCreateCandidatePermissionDialogs/LeverCreateCandidatePermissionDialog';

import AtsSevice, { TScorecardGroupByKey, TScorecardSortKey } from './AtsService';

/* *
 * Lever ATS service
 * Imp: Never have atsType set in ATS service
 * */

class LeverAtsService implements AtsSevice {
  readonly name = 'Lever';

  readonly logoUrl = `/static/images/integrations/lever.png`;

  readonly helpCenterUrl = ZenDeskHelpCenterUrl.LEVER_INTEGRATION;

  readonly scorecardLabelPlural = 'feedback forms';

  readonly isSourcingLinkEnabled = true;

  readonly isAtsUserSelectEnabled = true;

  readonly isAtsImportAvailabilityEnabled = false;

  readonly skipInterviewKitLinkTokenValidation = true;

  readonly scorecardSortKey: TScorecardSortKey = 'groupId';

  readonly scorecardGroupByKey: TScorecardGroupByKey = 'groupName';

  readonly showEstimatedMinutes = false;

  readonly createCandidatePermissionDialog = LeverCreateCandidatePermissionDialog;

  readonly showRequisitionId = false;
}

export default new LeverAtsService();
