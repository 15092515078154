import { SvgIcon, SvgIconProps } from './SvgIcon';

export const EyeIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.26233 10.0001C4.26233 9.88468 4.30045 9.78518 4.35257 9.71866C4.85647 9.10915 5.66844 8.22844 6.67139 7.50457C7.68332 6.77423 8.82499 6.25 9.99997 6.25C11.175 6.25 12.3166 6.77423 13.3286 7.50458C14.3315 8.22843 15.1435 9.10912 15.6474 9.71862C15.6995 9.78517 15.7376 9.8847 15.7376 10.0001C15.7376 10.1154 15.6995 10.2149 15.6474 10.2815C15.1435 10.891 14.3315 11.7717 13.3286 12.4955C12.3166 13.2259 11.175 13.7501 9.99997 13.7501C8.82499 13.7501 7.68332 13.2259 6.67139 12.4955C5.66843 11.7717 4.85647 10.891 4.35257 10.2815C4.30045 10.2149 4.26233 10.1154 4.26233 10.0001ZM9.99997 4.75C8.38495 4.75 6.93662 5.46328 5.79355 6.28827C4.64439 7.11766 3.73813 8.10711 3.19171 8.76869L3.19165 8.76864L3.18496 8.77698C2.90663 9.12391 2.76233 9.56027 2.76233 10.0001C2.76233 10.4399 2.90663 10.8762 3.18496 11.2231L3.1849 11.2232L3.19171 11.2314C3.73814 11.893 4.64439 12.8825 5.79355 13.7118C6.93662 14.5368 8.38495 15.2501 9.99997 15.2501C11.615 15.2501 13.0633 14.5368 14.2064 13.7118C15.3556 12.8825 16.2618 11.893 16.8083 11.2314L16.8083 11.2315L16.8151 11.2231C17.0933 10.8762 17.2376 10.4399 17.2376 10.0001C17.2376 9.56027 17.0933 9.12395 16.8151 8.77705L16.8151 8.777L16.8083 8.76869C16.2618 8.10711 15.3556 7.11766 14.2064 6.28827C13.0633 5.46328 11.615 4.75 9.99997 4.75ZM10 7.25C8.48122 7.25 7.25 8.48122 7.25 10C7.25 11.5188 8.48122 12.75 10 12.75C11.5188 12.75 12.75 11.5188 12.75 10C12.75 8.48122 11.5188 7.25 10 7.25ZM8.75 10C8.75 9.30964 9.30964 8.75 10 8.75C10.6904 8.75 11.25 9.30964 11.25 10C11.25 10.6904 10.6904 11.25 10 11.25C9.30964 11.25 8.75 10.6904 8.75 10Z"
        fill="currentColor"
        fillOpacity="0.6"
      />
    </SvgIcon>
  );
};
