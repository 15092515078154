/* eslint-disable max-lines */
import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { PublicError } from '@modernloop/shared/helper-components';
import { EyeCrossedIcon, RsvpDeclineLeftIcon, RsvpGoingLeftIcon, RsvpMaybeLeftIcon } from '@modernloop/shared/icons';
import { getExternalErrorMessage } from '@modernloop/shared/utils';
import { Avatar, ListItemIcon, ListItemText, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  AdjustInterviewerRsvp_InterviewFragment,
  DeclineReason,
  ResponseStatus,
  TaskListDocument,
  useInterviewAdjustRsvpMutation,
} from 'src/generated/mloop-graphql';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import { useApolloRefetchQuery } from 'src/hooks/useApolloQuery';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { renderInterviewerHours } from 'src/utils/renderTimeRange';

// eslint-disable-next-line modernloop/restrict-imports.cjs
import { DeclineReasons } from 'src/views-new/MetricsPage/InterviewersOverview/Charts/DeclineReason';

interface Props {
  interviewerId: string;
  onClose: () => void;
}

interface Fragments {
  interview: AdjustInterviewerRsvp_InterviewFragment;
}

const AdjustInterviewerRsvp: FCWithFragments<Fragments, Props> = ({ interview, interviewerId, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const refetchActivityFeed = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();
  const refetchQuery = useApolloRefetchQuery();
  const [adjustInterviewerRsvp] = useInterviewAdjustRsvpMutation();
  const [declineReasonError, setDeclineReasonError] = useState('');
  const [declineCommentError, setDeclineCommentError] = useState('');
  const interviewer = interview?.applicationStageInterviewInterviewers?.find((i) => i.interviewerId === interviewerId);
  const [selectedResponseStatus, setSelectedResponseStatus] = useState(interviewer?.responseStatus);
  const [showDeclineReason, setShowDeclineReason] = useState(interviewer?.responseStatus === ResponseStatus.Declined);
  const [declineReason, setDeclineReason] = useState(
    interviewer?.declines ? interviewer?.declines[0]?.reason?.toLowerCase() : ''
  );
  const [declineComment, setDeclineComment] = useState(interviewer?.declines ? interviewer?.declines[0]?.comment : '');

  if (!interviewer) return null;
  return (
    <ConditionalThemeProvider>
      <Dialog
        dataTestId="adjust-interviewer-rsvp-dialog"
        decoration={
          <img
            style={{ width: '64px', height: '64px' }}
            src="/static/images/scheduling/edit_64.png"
            alt="Adjust RSVP"
          />
        }
        open
        onClose={onClose}
        title="Adjust interviewer RSVP"
        subTitle="Changes show in ModernLoop and interviewer calendars"
        cancelOptions={{
          onClick: onClose,
          label: 'Cancel',
        }}
        submitOptions={{
          onClick: () => {
            if (declineReason === '' && selectedResponseStatus === ResponseStatus.Declined) {
              setDeclineReasonError('Please select a decline reason');
              return;
            }
            if (
              declineComment === '' &&
              selectedResponseStatus === ResponseStatus.Declined &&
              declineReason === DeclineReason.Other.toLowerCase()
            ) {
              setDeclineCommentError('Please add a note');
              return;
            }
            adjustInterviewerRsvp({
              variables: {
                applicationStageInterviewId: interview.id,
                interviewerId,
                responseStatus: selectedResponseStatus as ResponseStatus,
                reason:
                  selectedResponseStatus !== ResponseStatus.Declined
                    ? null
                    : (declineReason?.toUpperCase() as DeclineReason),
                comment: declineComment,
              },
            }) // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line promise/always-return
              .then(() => {
                refetchScheduleTaskQuery();
                refetchActivityFeed();
                refetchQuery([TaskListDocument]);
                enqueueSnackbar(`RSVP adjusted successfully`, { variant: 'success' });
              })
              .catch((error) => {
                enqueueSnackbar(getExternalErrorMessage(error), { variant: 'error' });
              });
            onClose();
          },
          label: 'Save',
        }}
      >
        <Stack direction="column" spacing={1}>
          <Stack direction="column">
            <Typography variant="subtitle1">{interview.name}</Typography>

            {interview.isHiddenFromCandidate && (
              <Stack direction="row" alignItems="center">
                <EyeCrossedIcon />
                <Typography variant="body2" color="text.secondary">
                  Hidden
                </Typography>
              </Stack>
            )}

            <Typography variant="body1" color="text.secondary">
              {renderInterviewerHours(interview.startAt, interview.endAt, getLocalTimezone(), true, false)}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="left" spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              key={interviewer?.interviewerId}
              spacing={1}
              justifyContent="space-between"
            >
              <Avatar
                src={interviewer?.interviewer?.slackImageUrl ?? ''}
                alt={interviewer?.interviewer?.fullName || ''}
              />
              <Typography variant="body1" noWrap minWidth="216px">
                {interviewer?.interviewer?.fullName || ''}
              </Typography>
            </Stack>
            <Select
              fullWidth
              value={selectedResponseStatus}
              onChange={(val) => {
                setShowDeclineReason(val.target.value === ResponseStatus.Declined);
                setSelectedResponseStatus(val.target.value as ResponseStatus);
                if (val.target.value !== ResponseStatus.Declined) {
                  setDeclineComment('');
                }
              }}
              renderValue={(value) => {
                if (value === ResponseStatus.NeedsAction) {
                  return (
                    <Stack gap={1} alignItems="center" direction="row">
                      <Typography variant="body1" color="text.secondary">
                        Select a response
                      </Typography>
                    </Stack>
                  );
                }

                if (value === ResponseStatus.Accepted) {
                  return (
                    <Stack gap={1} alignItems="center" direction="row">
                      <RsvpGoingLeftIcon />
                      Accept
                    </Stack>
                  );
                }

                if (value === ResponseStatus.Declined) {
                  return (
                    <Stack gap={1} alignItems="center" direction="row">
                      <RsvpDeclineLeftIcon />
                      Decline
                    </Stack>
                  );
                }
                if (value === ResponseStatus.Tentative) {
                  return (
                    <Stack gap={1} alignItems="center" direction="row">
                      <RsvpMaybeLeftIcon />
                      Maybe
                    </Stack>
                  );
                }

                return null;
              }}
            >
              <MenuItem value={ResponseStatus.Accepted}>
                <ListItemIcon>
                  <RsvpGoingLeftIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Accept"
                  secondary={interviewer?.responseStatus === ResponseStatus.Accepted ? 'Current RSVP status' : ''}
                />
              </MenuItem>

              <MenuItem value={ResponseStatus.Declined}>
                <ListItemIcon>
                  <RsvpDeclineLeftIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Decline"
                  secondary={interviewer?.responseStatus === ResponseStatus.Declined ? 'Current RSVP status' : ''}
                />
              </MenuItem>

              <MenuItem value={ResponseStatus.Tentative}>
                <ListItemIcon>
                  <RsvpMaybeLeftIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Maybe"
                  secondary={interviewer?.responseStatus === ResponseStatus.Tentative ? 'Current RSVP status' : ''}
                />
              </MenuItem>
            </Select>
          </Stack>
          {showDeclineReason && (
            <Stack direction="column" spacing={1}>
              <Select
                error={declineReasonError !== ''}
                displayEmpty
                value={declineReason}
                onChange={(val) => {
                  setDeclineReason(val.target.value);
                  setDeclineReasonError('');
                }}
                renderValue={(value) => {
                  if (value === '') {
                    return (
                      <Typography variant="body1" color="text.secondary">
                        Reason
                      </Typography>
                    );
                  }
                  return DeclineReasons[value];
                }}
              >
                {Object.keys(DeclineReasons)
                  .filter((key) => key !== 'missed')
                  .map((key) => (
                    <MenuItem key={key} value={key}>
                      {DeclineReasons[key]}
                    </MenuItem>
                  ))}
              </Select>
              {declineReasonError !== '' && <PublicError error={declineReasonError} />}
              <TextField
                placeholder="Note"
                onChange={(val) => {
                  setDeclineComment(val.target.value);
                  setDeclineCommentError('');
                }}
                value={declineComment}
                fullWidth
                multiline
                rows={3}
                error={declineCommentError !== ''}
              />
              {declineCommentError !== '' && <PublicError error={declineCommentError} />}
            </Stack>
          )}
        </Stack>
      </Dialog>
    </ConditionalThemeProvider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InterviewAdjustRsvpMutation = gql`
  mutation interviewAdjustRsvp(
    $applicationStageInterviewId: uuid!
    $interviewerId: uuid!
    $reason: DeclineReason
    $comment: String
    $responseStatus: ResponseStatus!
  ) {
    interviewAdjustRsvp(
      input: {
        applicationStageInterviewId: $applicationStageInterviewId
        interviewerId: $interviewerId
        reason: $reason
        comment: $comment
        responseStatus: $responseStatus
      }
    ) {
      result
    }
  }
`;

AdjustInterviewerRsvp.fragments = {
  interview: gql`
    fragment AdjustInterviewerRsvp_interview on ApplicationStageInterview {
      id
      startAt
      endAt
      name
      isHiddenFromCandidate
      applicationStageInterviewInterviewers {
        interviewerId
        role
        responseStatus
        isOptional
        declines {
          reason
          comment
        }
        interviewer {
          id
          slackImageUrl
          fullName
          givenName
        }
      }
    }
  `,
};

export default AdjustInterviewerRsvp;
