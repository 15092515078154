import { useCallback } from 'react';

import { gql } from '@apollo/client';

import { useSelfScheduleOptionsDeleteMutation } from 'src/generated/mloop-graphql';

import useGenericOnError from 'src/hooks/useGenericOnError';

import { apolloCacheDeleteById } from 'src/utils/apolloHelpers';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SelfScheduleOptionsDeleteMutation = gql`
  mutation SelfScheduleOptionsDelete($id: uuid!) {
    selfScheduleOptionsDelete(id: $id) {
      id
    }
  }
`;

const useSelfScheduleOptionsDelete = () => {
  const genericOnError = useGenericOnError();

  const [selfScheduleDelete, { loading }] = useSelfScheduleOptionsDeleteMutation({
    onError: genericOnError,
  });

  const mutation = useCallback(
    (selfScheduleOptionsId: string) => {
      return selfScheduleDelete({
        variables: { id: selfScheduleOptionsId },
        update: (cache, { data }) => {
          apolloCacheDeleteById(cache, data?.selfScheduleOptionsDelete?.id, 'SelfScheduleOptions');
        },
      });
    },
    [selfScheduleDelete]
  );

  return { mutation, loading };
};

export default useSelfScheduleOptionsDelete;
