import { SvgIcon, SvgIconProps } from './SvgIcon';

export const InterviewerPortalIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2.25C3.48122 2.25 2.25 3.48122 2.25 5V11C2.25 12.5188 3.48122 13.75 5 13.75H5.36292C5.77714 13.75 6.11292 13.4142 6.11292 13C6.11292 12.5858 5.77714 12.25 5.36292 12.25H5C4.30964 12.25 3.75 11.6904 3.75 11V5C3.75 4.30964 4.30964 3.75 5 3.75H15C15.6904 3.75 16.25 4.30964 16.25 5V11C16.25 11.6904 15.6904 12.25 15 12.25H14.6779C14.2636 12.25 13.9279 12.5858 13.9279 13C13.9279 13.4142 14.2636 13.75 14.6779 13.75H15C16.5188 13.75 17.75 12.5188 17.75 11V5C17.75 3.48122 16.5188 2.25 15 2.25H5ZM7.51919 9.48748C7.51919 8.11368 8.63287 7 10.0067 7C11.3805 7 12.4941 8.11368 12.4941 9.48748V10.1825C12.4941 11.5563 11.3805 12.6699 10.0067 12.6699C8.63287 12.6699 7.51919 11.5563 7.51919 10.1825V9.48748ZM10.0067 8.5C9.4613 8.5 9.01919 8.94211 9.01919 9.48748V10.1825C9.01919 10.7278 9.4613 11.1699 10.0067 11.1699C10.552 11.1699 10.9941 10.7278 10.9941 10.1825V9.48748C10.9941 8.94211 10.552 8.5 10.0067 8.5ZM10.0041 14.9278C8.94563 14.9278 7.98107 15.2703 7.43613 15.8378C7.39499 15.8807 7.37886 15.9107 7.37285 15.9245C7.36727 15.9373 7.36721 15.9431 7.36719 15.9452L7.36719 15.9452C7.36716 15.9482 7.36782 15.9884 7.42438 16.0604C7.54844 16.2184 7.8347 16.3815 8.17618 16.3815L11.8319 16.3815C12.1734 16.3815 12.4597 16.2184 12.5837 16.0604C12.6403 15.9884 12.641 15.9481 12.6409 15.9452V15.9452C12.6409 15.9431 12.6409 15.9373 12.6353 15.9245C12.6293 15.9107 12.6131 15.8806 12.572 15.8378C12.0271 15.2703 11.0625 14.9278 10.0041 14.9278ZM10.0041 13.4278C11.3244 13.4278 12.7386 13.8457 13.6539 14.7989C13.9597 15.1173 14.1367 15.5076 14.1409 15.9303C14.1449 16.342 13.9846 16.7053 13.7634 16.987C13.3318 17.5364 12.595 17.8815 11.8319 17.8815L8.17618 17.8815C7.4131 17.8815 6.67631 17.5364 6.24475 16.987C6.02349 16.7053 5.86317 16.3421 5.86727 15.9303C5.87147 15.5077 6.0484 15.1173 6.35418 14.7989C7.26949 13.8457 8.68371 13.4278 10.0041 13.4278Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
