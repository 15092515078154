import { useCallback } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { assertIsoTimestamp } from '@modernloop/shared/datetime';
import { logError } from '@modernloop/shared/utils';

import { InterviewType, UseSubmitInterviewPlan_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import slice, { setScheduleOptionsSetupRefresh } from 'src/slices/scheduling';

import { saveSchedules, setScheduleOverrideConfig } from 'src/store/actions/schedules';
import { ScheduleDatesFilter, ScheduleFilters } from 'src/store/slices/schedule-filters';

import getRescheduleOptions from 'src/utils/api/getRescheduleOptions';
import getScheduleOptions, {
  InterviewSchedule,
  InterviewerResponseStatus,
  RequestBody,
  ResponseBody,
  UICandidateAvailability,
} from 'src/utils/api/getScheduleOptions';
import { isSameDay } from 'src/utils/datetime/Conversions';
import { isBreak } from 'src/utils/interview';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { useDispatch } from 'src/store';

import useScheduleOptionsRequestBody from './useScheduleOptionsRequestBody';

const getCandidateAvailabilities = (
  candidateAvailabilities: UICandidateAvailability[],
  tz: string,
  dates?: ScheduleDatesFilter // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
UICandidateAvailability[] => {
  if (dates?.length) {
    const newCandidateAvailabilities = candidateAvailabilities.filter((availablity) => {
      return !!dates.find((date) => {
        return isSameDay(assertIsoTimestamp(availablity.startAt), date, tz);
      });
    });
    if (newCandidateAvailabilities.length) {
      return newCandidateAvailabilities;
    }
  }
  return candidateAvailabilities;
};

type Fragments = {
  interviewPlan: UseSubmitInterviewPlan_InterviewPlanFragment;
};

type Props = {
  increment: boolean;
};

const useSubmitInterviewPlan: FunctionWithFragments<
  Fragments,
  Props,
  ({
    filters,
    interviewersResponseStatuses,
  }: {
    filters?: ScheduleFilters;
    interviewersResponseStatuses?: InterviewerResponseStatus[];
  }) => Promise<void>
> = ({ interviewPlan }, { increment }) => {
  const dispatch = useDispatch();

  const scheduleFlowData = useScheduleFlowData();
  const { candidateAvailabilities, candidateTimezone } = scheduleFlowData;

  const params = useScheduleOptionsRequestBody({ interviewPlan }, { increment });

  const submitInterviewPlanStep = useCallback(
    async ({
      filters,
      interviewersResponseStatuses,
    }: {
      filters?: ScheduleFilters;
      interviewersResponseStatuses?: InterviewerResponseStatus[];
    }) => {
      dispatch(setScheduleOptionsSetupRefresh(false));
      dispatch(slice.actions.setInterviewPlanStepSubmitting({ submitting: true }));
      dispatch(slice.actions.clearAnyErrors());

      let hasNoInterviewSeat = false;
      Object.values(params.interviewSlots || []).forEach((interview) => {
        if (isBreak(interview?.interviewType || InterviewType.Interview)) return;

        if (!interview.seats || interview.seats.length === 0) {
          hasNoInterviewSeat = true;
        }
      });

      if (hasNoInterviewSeat) {
        throw new Error(
          'Some of your interviews do not have an interviewer added. Please add one or more interviewers.'
        );
      }

      const scheduleOptionsRequestBody: RequestBody = {
        ...params,
        candidateAvailabilities: getCandidateAvailabilities(candidateAvailabilities, candidateTimezone, filters?.dates),
      };

      let data: ResponseBody;

      try {
        if (interviewersResponseStatuses) {
          data = await getRescheduleOptions({
            ...scheduleOptionsRequestBody,
            interviewersResponseStatus: interviewersResponseStatuses,
          });
        } else {
          data = await getScheduleOptions(scheduleOptionsRequestBody);
        }
        if (data.error) {
          throw new Error(data.error);
        }
        const newSchedules = data.schedules as InterviewSchedule[];

        dispatch(slice.actions.setStepCandidateAvailabilityLoading({ loading: false }));
        dispatch(saveSchedules(data.count, newSchedules));
        dispatch(setScheduleOverrideConfig({ multiDay: !!data.config_override?.multi_day }));
        dispatch(slice.actions.clearSelectedScheduleId());
      } catch (error) {
        logError(error);
        dispatch(slice.actions.clearSelectedScheduleId());
        dispatch(slice.actions.setStepCandidateAvailabilityLoading({ loading: false }));

        throw error;
      }

      dispatch(slice.actions.setInterviewPlanStepSubmitting({ submitting: false }));
    },
    [candidateAvailabilities, candidateTimezone, dispatch, params]
  );

  return submitInterviewPlanStep;
};

useSubmitInterviewPlan.fragments = {
  interviewPlan: gql`
    ${useScheduleOptionsRequestBody.fragments.interviewPlan}
    fragment useSubmitInterviewPlan_interviewPlan on JobStage {
      id
      ...useScheduleOptionsRequestBody_interviewPlan
    }
  `,
};

export default useSubmitInterviewPlan;
