/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react';

import {
  ALL_FEATURE_FLAGS,
  FeatureFlagBool,
  FeatureFlagNames,
  JSON_FEATURE_FLAGS,
  LocalStorageFlags,
  useFeatureFlagLocal,
} from '@modernloop/shared/feature-flag';
import { CopyIcon, InfoIcon, OpenInNewTabIcon, QuestionCircleIcon } from '@modernloop/shared/icons';
import {
  Alert,
  Box,
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  Link,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { useFlags } from 'launchdarkly-react-client-sdk';
import { has } from 'lodash';
import { useSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';

const LaunchDarklyEnvMap = {
  development: 'test',
  staging: 'staging',
  production: 'production',
};

const isPlainObject = (val) => {
  return !!val && typeof val === 'object' && val.constructor === Object;
};

const FeatureFlagsTab: FC = () => {
  const ldLink = `https://app.launchdarkly.com/default/${LaunchDarklyEnvMap[process.env.NODE_ENV]}/features`;

  const { enqueueSnackbar } = useSnackbar();
  const [initialSaveSkipped, setInitialSaveSkipped] = useState(false);

  // all flags in LD
  const launchDarklyFlags = useFlags();

  // flags from localStorage
  const { localStorageFeatureFlags, setLocalStorageFeatureFlags } = useFeatureFlagLocal();

  // flags that are displayed in the Feature Flags tab
  const [overrideFlags, setOverrideFlags] = useState<LocalStorageFlags>(
    localStorageFeatureFlags || ({} as LocalStorageFlags)
  );

  const handleOverrideUpdate = (flagName: FeatureFlagNames, overrideOn: boolean) => {
    if (overrideOn) {
      // when turning on the override, set it to the same value as we have in LD
      setOverrideFlags((prev) => ({
        ...prev,
        [flagName]: isPlainObject(launchDarklyFlags[flagName])
          ? JSON.stringify(launchDarklyFlags[flagName])
          : launchDarklyFlags[flagName],
      }));
    }
    if (!overrideOn) {
      // when turning off the override, remove the flag from the override object
      const { [flagName]: removed, ...rest } = overrideFlags;
      setOverrideFlags({ ...rest } as LocalStorageFlags);
    }
  };

  const handleFlagValueUpdate = (flagName: FeatureFlagNames, value: unknown) => {
    setOverrideFlags((prev) => ({ ...prev, [flagName]: value }));
  };

  const handleResetAllOverrides = () => {
    setOverrideFlags({} as LocalStorageFlags);
  };

  const debouncedLocalStorageUpdate = useDebouncedCallback(() => {
    setLocalStorageFeatureFlags(overrideFlags);
    enqueueSnackbar('Feature flags saved', { variant: 'success' });
  }, 250);

  const debouncedValidation = useDebouncedCallback(() => {
    try {
      JSON_FEATURE_FLAGS.forEach((flagName) => {
        if (has(overrideFlags, flagName)) {
          JSON.parse(overrideFlags[flagName] as string);
        }
      });
      debouncedLocalStorageUpdate();
    } catch (error) {
      debouncedLocalStorageUpdate.cancel();
      enqueueSnackbar(`Invalid JSON: ${error}`, { variant: 'error' });
    }
  }, 500);

  useEffect(() => {
    if (!initialSaveSkipped) {
      setInitialSaveSkipped(true);
      return;
    }
    debouncedValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValidation, overrideFlags]);

  return (
    <Stack spacing={1} overflow="auto" width="100%">
      <Stack gap={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">
            Feature flags for <strong>{process.env.NODE_ENV}</strong> environment
          </Typography>

          <Link href={ldLink} target="_blank">
            Launch Darkly flags <OpenInNewTabIcon />
          </Link>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Alert severity="info">
            <Typography variant="body2">Changing feature flags requires a page refresh to take effect</Typography>
          </Alert>
          <Button onClick={handleResetAllOverrides}>Reset all overrides</Button>
        </Stack>
      </Stack>
      <Box pb="80px">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Flag name</TableCell>
              <TableCell>Value in LD</TableCell>
              <TableCell>Override On</TableCell>
              <TableCell>Override Value</TableCell>
              <TableCell>
                <Stack direction="row" spacing={2}>
                  Used in current branch
                  <Tooltip title="Indicates this feature flag is defined in the flag enums on this branch">
                    <IconButton sx={{ py: '0px', px: '2px' }}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>

          {Object.entries(launchDarklyFlags)
            .sort()
            .map(([flagName, flagValue]) => {
              const flagLink = `${ldLink}/${flagName}/targeting`;
              const usedInBranch = ALL_FEATURE_FLAGS.includes(flagName as FeatureFlagBool);
              const overrideEnabled = has(overrideFlags, flagName);

              return (
                <TableRow key={flagName} hover>
                  <TableCell>
                    <Link href={flagLink} target="_blank">
                      {flagName}
                    </Link>
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(flagName);
                        enqueueSnackbar('Copied to clipboard', { variant: 'success' });
                      }}
                    >
                      <CopyIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {typeof flagValue === 'boolean' && (
                      <Chip
                        label={JSON.stringify(flagValue)}
                        // eslint-disable-next-line no-nested-ternary
                        color={flagValue === true ? 'success' : 'error'}
                      />
                    )}

                    {(typeof flagValue === 'string' || typeof flagValue === 'number') && (
                      <TextField disabled value={JSON.stringify(flagValue)} />
                    )}

                    {isPlainObject(flagValue) && (
                      <Stack direction="row" width="100%">
                        <TextField multiline disabled value={JSON.stringify(flagValue)} />
                        <IconButton
                          onClick={() => {
                            navigator.clipboard.writeText(JSON.stringify(flagValue));
                            enqueueSnackbar('Copied JSON to clipboard', { variant: 'success' });
                          }}
                        >
                          <CopyIcon />
                        </IconButton>
                      </Stack>
                    )}
                  </TableCell>
                  <TableCell>
                    {usedInBranch && (
                      <FormControlLabel
                        sx={{
                          justifyContent: 'space-between',
                        }}
                        control={
                          <Switch
                            checked={overrideFlags[flagName] !== undefined}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              handleOverrideUpdate(flagName as FeatureFlagNames, event.target.checked);
                            }}
                          />
                        }
                        labelPlacement="start"
                        label={undefined}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {/* Boolean flag */}
                    {typeof launchDarklyFlags[flagName] === 'boolean' && usedInBranch && overrideEnabled && (
                      <Stack direction="row" justifyContent="space-evenly">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={
                                overrideEnabled ? (overrideFlags[flagName] as boolean) : launchDarklyFlags[flagName]
                              }
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleFlagValueUpdate(flagName as FeatureFlagNames, event.target.checked);
                              }}
                              disabled={overrideFlags[flagName] === undefined}
                            />
                          }
                          labelPlacement="start"
                          label={undefined}
                        />
                        <Chip
                          color={overrideFlags[flagName] === true ? 'success' : 'error'}
                          label={JSON.stringify(overrideFlags[flagName])}
                        />
                      </Stack>
                    )}

                    {/* JSON flag */}
                    {isPlainObject(launchDarklyFlags[flagName]) && usedInBranch && (
                      <FormControlLabel
                        control={
                          <TextField
                            multiline
                            value={overrideFlags[flagName] || JSON.stringify(launchDarklyFlags[flagName]) || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              handleFlagValueUpdate(flagName as FeatureFlagNames, event.target.value);
                            }}
                            disabled={overrideFlags[flagName] === undefined}
                            label={undefined}
                          />
                        }
                        labelPlacement="start"
                        label={undefined}
                      />
                    )}

                    {/* String flag */}
                    {typeof launchDarklyFlags[flagName] === 'string' && usedInBranch && (
                      <FormControlLabel
                        control={
                          <TextField
                            value={overrideFlags[flagName] || launchDarklyFlags[flagName] || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              handleFlagValueUpdate(flagName as FeatureFlagNames, event.target.value);
                            }}
                            disabled={overrideFlags[flagName] === undefined}
                          />
                        }
                        labelPlacement="start"
                        label={undefined}
                      />
                    )}

                    {/* Number flag */}
                    {typeof launchDarklyFlags[flagName] === 'number' && usedInBranch && (
                      <FormControlLabel
                        control={
                          <TextField
                            inputMode="numeric"
                            value={overrideFlags[flagName] || launchDarklyFlags[flagName] || ''}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              handleFlagValueUpdate(flagName as FeatureFlagNames, event.target.value);
                            }}
                            disabled={overrideFlags[flagName] === undefined}
                          />
                        }
                        labelPlacement="start"
                        label={undefined}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      {usedInBranch ? '✅' : '❌'}
                      {flagName.indexOf('org_') === 0 && !usedInBranch && (
                        <Tooltip title="Potentially back end-only flag">
                          <IconButton sx={{ py: '0px', px: '10px' }}>
                            <QuestionCircleIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          <TableBody />
        </Table>
      </Box>
    </Stack>
  );
};

export default FeatureFlagsTab;
