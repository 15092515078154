import { useEffect, useMemo } from 'react';

import { isEqual, uniq } from 'lodash';

import { InterviewType, JobStageInterviewSeatType, useEmployeeByIdsLazyQuery } from 'src/generated/mloop-graphql';

import { useOrgAtsService } from 'src/hooks/atsService';
import usePrevious from 'src/hooks/usePrevious';

import { getAllJobStageInterviewByJobStageId } from 'src/store/selectors/job-stage-interview';
import { getAllJobStageGroupByJobStageId } from 'src/store/selectors/job-stage-interview-group';
import { getAllJobStageInterviewSeatByJobStageId } from 'src/store/selectors/job-stage-interview-seat';
import { JobStageInterview } from 'src/store/slices/job-stage-interview';
import { JobStageInterviewGroup } from 'src/store/slices/job-stage-interview-group';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import { useSelector } from 'src/store';

export type IsInterviewPlanValidResult = {
  isValid: boolean;
  title: string;
  error: string;
};

export const INCOMPLETE_INTERVIEW_PLAN = 'Incomplete interview plan';
export const INTERVIEW_PLAN_WITH_HIDDEN_EVENTS = 'Interview plan with hidden events';
export const INTERVIEW_PLAN_WITH_MULTI_DAY_SCHEDULE = 'Multi-day schedule found';

const useIsInterviewPlanValid = (
  jobStageId: string,
  {
    isDayBreakAllowed = true,
    allowEmptyPlan = false,
    allowEmptyInterviews = false,
    allowAllInterviewsHiddenFromCandiate = false,
    allowSomeInterviewsHiddenFromCandiate = true,
  }: {
    isDayBreakAllowed?: boolean;
    allowEmptyPlan?: boolean;
    allowEmptyInterviews?: boolean;
    allowAllInterviewsHiddenFromCandiate?: boolean;
    allowSomeInterviewsHiddenFromCandiate?: boolean;
  }
): IsInterviewPlanValidResult => {
  const jobStageInterviewGroups = useSelector((state) => getAllJobStageGroupByJobStageId(state, jobStageId));
  const jobStageInterviews = useSelector((state) => getAllJobStageInterviewByJobStageId(state, jobStageId));
  const jobStageInterviewSeats = useSelector((state) => getAllJobStageInterviewSeatByJobStageId(state, jobStageId));

  const atsService = useOrgAtsService();

  /**
   * TODO: (hemant) - Check the following
   * - If interview module has no trained interviewer.
   * - If the attributes applied to module makes the trained interviewer count 0.
   */

  const { employeeIds /* , interviewModuleIds */ } = useMemo(() => {
    const empIds: string[] = [];
    const moduleIds: string[] = [];
    jobStageInterviewSeats.forEach((seat) => {
      if (seat.type === JobStageInterviewSeatType.Freeform) {
        if (seat.freeformSeat && seat.freeformSeat.jobStageInterviewSeatEmployees) {
          seat.freeformSeat.jobStageInterviewSeatEmployees.forEach(
            (employee) => employee && empIds.push(employee.employeeId)
          );
        } else if (seat.employeeIds) {
          seat.employeeIds.forEach((employeeId) => empIds.push(employeeId));
        }
      } else if (seat.type === JobStageInterviewSeatType.Module) {
        if (seat.moduleSeat && seat.moduleSeat.interviewModuleId) {
          moduleIds.push(seat.moduleSeat.interviewModuleId);
        } else if (seat.interviewId) {
          moduleIds.push(seat.interviewId);
        }
      }
    });

    return {
      employeeIds: uniq(empIds),
      interviewModuleIds: uniq(moduleIds),
    };
  }, [jobStageInterviewSeats]);

  const prevEmployeeIds = usePrevious(employeeIds);

  const [fetchEmployees, { data: employeesData, loading: employeesLoading }] = useEmployeeByIdsLazyQuery();

  useEffect(() => {
    if (!employeesLoading && employeeIds.length && !isEqual((prevEmployeeIds || []).sort(), employeeIds.sort())) {
      fetchEmployees({
        variables: { input: employeeIds },
      });
    }
  }, [employeeIds, employeesLoading, fetchEmployees, prevEmployeeIds]);

  /**
   * Ensure that freeform employees in plan are not disabled or archived.
   */

  const isEmployeeDisabled = useMemo(() => {
    if (!employeesData || !employeesData.employeeByIds || employeeIds.length === 0) return false;
    return employeesData.employeeByIds.reduce((isDisabled: boolean, employee) => {
      return isDisabled || employee?.isArchived || employee?.isAtsDisabled || employee?.isDirectoryDisabled;
    }, false);
  }, [employeeIds, employeesData]);

  /**
   * Check if the freeform employees are connected to ATS.
   */

  const isEmployeeNotConnectedToAts = useMemo(() => {
    if (!employeesData || !employeesData.employeeByIds || employeeIds.length === 0) {
      return false;
    }

    return employeesData.employeeByIds.reduce((hasAtsId, employee) => {
      return hasAtsId || !employee?.hasAtsId;
    }, false);
  }, [employeeIds, employeesData]);

  if (isEmployeeDisabled) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error: `Some of the interviewers in interview plan are disabled: ${employeesData?.employeeByIds
        ?.map((employee) => {
          if (employee?.isArchived || employee?.isAtsDisabled || employee?.isDirectoryDisabled)
            return employee.fullName;
          return '';
        })
        .filter((value) => Boolean(value))
        .join(', ')}`,
    };
  }
  if (isEmployeeNotConnectedToAts) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error: `Some of the interviewers in interview plan are disconnected from ${
        atsService?.name
      }: ${employeesData?.employeeByIds
        ?.map((employee) => {
          if (!employee?.hasAtsId) return employee.fullName;
          return '';
        })
        .filter((value) => Boolean(value))
        .join(', ')}`,
    };
  }

  /**
   * Check if there are interviews in the plan.
   */

  const hasInterviews =
    Boolean(jobStageInterviewGroups.length) &&
    jobStageInterviewGroups.reduce((isValid: boolean, group: JobStageInterviewGroup) => {
      return isValid && Boolean(group.jobStageInterviewIds.length);
    }, true);

  if (!hasInterviews && !allowEmptyPlan) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error:
        'The interview plan for this candidate is incomplete. There are no interviews added to your interview plan',
    };
  }

  if (!hasInterviews && allowEmptyPlan) {
    return {
      isValid: true,
      title: '',
      error: 'Empty plan is valid',
    };
  }

  /**
   * Ensure that there is no day break
   */
  if (!isDayBreakAllowed) {
    const hasDayBreak = jobStageInterviews.reduce((hasDayDivider, interview) => {
      return hasDayDivider || interview.type === InterviewType.DayDivider;
    }, false);

    if (hasDayBreak) {
      return {
        isValid: false,
        title: INTERVIEW_PLAN_WITH_MULTI_DAY_SCHEDULE,
        error:
          'ModernLoop does not support self-scheduling of multi-day schedules yet. ' +
          'Please remove any day breaks and try again. We’re working to bring you this feature in the future!',
      };
    }
  }

  /**
   * Ensure that break is not first in plan
   */

  const firstGroup = jobStageInterviewGroups[0];
  const firstInterviewId = firstGroup.jobStageInterviewIds[0];
  const firstInterview = jobStageInterviews.find((interview) => interview.id === firstInterviewId);

  if (firstInterview?.type === InterviewType.Break || firstInterview?.type === InterviewType.DayDivider) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error: 'Break cannot be at the start of interview plan',
    };
  }

  /**
   * Ensure that break is not last in plan
   */

  const lastGroup = jobStageInterviewGroups[jobStageInterviewGroups.length - 1];
  const lastInterviewId = lastGroup.jobStageInterviewIds[lastGroup.jobStageInterviewIds.length - 1];
  const lastInterview = jobStageInterviews.find((interview) => interview.id === lastInterviewId);

  if (lastInterview?.type === InterviewType.Break || lastInterview?.type === InterviewType.DayDivider) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error: 'Break cannot be at the end of interview plan',
    };
  }

  /**
   * Ensure that there are interviewers added (either freeform or module)
   */

  const hasInterviewSeats =
    Boolean(jobStageInterviews.length) &&
    jobStageInterviews.reduce((isValid: boolean, interview: JobStageInterview) => {
      return (
        isValid &&
        (Boolean(interview.seatIds.length) ||
          interview.type === InterviewType.Break ||
          interview.type === InterviewType.DayDivider)
      );
    }, true);

  const hasInterviewer =
    Boolean(jobStageInterviewSeats.length) &&
    jobStageInterviewSeats.reduce((isValid: boolean, seat: JobStageInterviewSeat) => {
      if (seat.type === JobStageInterviewSeatType.Linked) return isValid;

      if (seat.type === JobStageInterviewSeatType.Freeform) {
        return (
          isValid &&
          (Boolean(seat.employeeIds?.length) || Boolean(seat.freeformSeat?.jobStageInterviewSeatEmployees?.length))
        );
      }

      if (seat.type === JobStageInterviewSeatType.Module) {
        return isValid && (Boolean(seat.interviewId) || Boolean(seat.moduleSeat?.interviewModuleId));
      }

      return false;
    }, true);

  if ((!hasInterviewSeats || !hasInterviewer) && !allowEmptyInterviews) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error:
        'The interview plan for this candidate is incomplete. Please check that each interview has at least one interviewer assigned.',
    };
  }

  /**
   * Ensure that not all interviews are hidden from candidate
   */

  const hasOnlyInterviewsHiddenFromCandidate = jobStageInterviews.reduce((hasHidden, interview) => {
    return hasHidden && Boolean(interview.isHiddenFromCandidate);
  }, true);

  if (!allowAllInterviewsHiddenFromCandiate && hasOnlyInterviewsHiddenFromCandidate) {
    return {
      isValid: false,
      title: INTERVIEW_PLAN_WITH_HIDDEN_EVENTS,
      error: 'All interviews are hidden from candidate',
    };
  }

  /**
   * Ensure that there are atleast some interviews visible to candidate
   */

  const hasInterviewsHiddenFromCandidate = jobStageInterviews.reduce((hasHidden, interview) => {
    return hasHidden || Boolean(interview.isHiddenFromCandidate);
  }, false);

  if (!allowSomeInterviewsHiddenFromCandiate && hasInterviewsHiddenFromCandidate) {
    return {
      isValid: false,
      title: INTERVIEW_PLAN_WITH_HIDDEN_EVENTS,
      error: 'Some interviews are hidden from candidate',
    };
  }

  return {
    isValid: true,
    title: '',
    error: 'Is valid',
  };
};

export default useIsInterviewPlanValid;
