import React, { FC, useRef, useState } from 'react';

import SelectButton from '../SelectButton';
import Menu, { MenuOption } from '../menu';
import { IconType } from '../utils/icons';

export type { MenuOption } from '../menu';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface SelectProps {
  dataTestId?: string;
  className?: string;
  placeholder?: string;
  title?: string | null;
  options?: MenuOption[];
  selectedOption?: MenuOption;
  startIcon?: IconType;
  size?: 'small' | 'medium';
  disabled?: boolean;
  onSelect: (option: MenuOption) => void;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const SelectMenu: FC<SelectProps> = (props) => {
  const { title, startIcon, disabled, size, placeholder, className, onSelect, options, selectedOption, dataTestId } =
    props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const selectRef = useRef<HTMLButtonElement>(null);

  function handleSelect(option) {
    onSelect(option);
  }

  return (
    <>
      <SelectButton
        dataTestId={dataTestId}
        isDisabled={disabled}
        startIcon={startIcon}
        ref={selectRef}
        className={className}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        label={selectedOption?.value || placeholder || ''}
        size={size || 'small'}
        title={title}
      />
      <Menu
        dataTestId="select-menu"
        menuWidth={selectRef?.current?.offsetWidth}
        open={isMenuOpen}
        options={(options ?? []).map((option) => {
          if (option.id === selectedOption?.id) {
            return { ...option, selected: true };
          }
          return option;
        })}
        id="menu-option"
        anchorEl={selectRef.current}
        onSelect={(option) => handleSelect(option)}
        onClose={() => setIsMenuOpen(false)}
      />
    </>
  );
};

export default SelectMenu;
