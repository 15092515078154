import React from 'react';

import BaseIcon, { Props } from './Icon';

const MyTasks = (props: Props): JSX.Element => {
  const { color, fontSize, tooltip } = props;
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.25 5C2.25 3.48122 3.48121 2.25 5 2.25H6.49999C6.91421 2.25 7.24999 2.58579 7.24999 3C7.24999 3.41421 6.91421 3.75 6.49999 3.75H5C4.30964 3.75 3.75 4.30964 3.75 5L3.75 6.5C3.75 6.91421 3.41422 7.25 3 7.25C2.58579 7.25 2.25 6.91421 2.25 6.5L2.25 5ZM12.75 3C12.75 2.58579 13.0858 2.25 13.5 2.25H15C16.5188 2.25 17.75 3.48121 17.75 5L17.75 6.5C17.75 6.91421 17.4142 7.25 17 7.25C16.5858 7.25 16.25 6.91421 16.25 6.5L16.25 5C16.25 4.30964 15.6903 3.75 15 3.75H13.5C13.0858 3.75 12.75 3.41421 12.75 3ZM3.00001 12.75C3.41422 12.75 3.75001 13.0858 3.75001 13.5L3.75001 15C3.75002 15.6904 4.30966 16.25 5.00001 16.25H6.50001C6.91423 16.25 7.25001 16.5858 7.25001 17C7.25001 17.4142 6.91423 17.75 6.50001 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25001 13.5C2.25001 13.0858 2.5858 12.75 3.00001 12.75ZM17 12.75C17.4142 12.75 17.75 13.0858 17.75 13.5L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H13.5C13.0858 17.75 12.75 17.4142 12.75 17C12.75 16.5858 13.0858 16.25 13.5 16.25H15C15.6904 16.25 16.25 15.6904 16.25 15L16.25 13.5C16.25 13.0858 16.5858 12.75 17 12.75ZM13.0087 10.4489C13.3131 10.7298 13.3321 11.2043 13.0511 11.5087L10.2819 14.5087C10.1451 14.6569 9.9544 14.7437 9.75283 14.7497C9.55126 14.7556 9.3558 14.6801 9.21054 14.5402L7.47977 12.8736C7.1814 12.5863 7.17244 12.1115 7.45976 11.8131C7.74708 11.5147 8.22186 11.5058 8.52023 11.7931L9.69923 12.9284L11.9489 10.4913C12.2299 10.1869 12.7043 10.1679 13.0087 10.4489ZM13.0511 6.50871C13.3321 6.20434 13.3131 5.72985 13.0087 5.4489C12.7043 5.16794 12.2299 5.18692 11.9489 5.49129L9.69923 7.92843L8.52023 6.79309C8.22186 6.50578 7.74708 6.51474 7.45976 6.8131C7.17244 7.11147 7.1814 7.58626 7.47977 7.87357L9.21054 9.54024C9.3558 9.68012 9.55126 9.75561 9.75283 9.74968C9.9544 9.74374 10.1451 9.65689 10.2819 9.50871L13.0511 6.50871Z"
          fill="currentColor"
        />
      </svg>
    </BaseIcon>
  );
};

export default MyTasks;
