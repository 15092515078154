import { BaseProps } from '../types';
import { Stack, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';

interface Props extends BaseProps {
  /**
   * Optional. String or JSX component that will be the Label of the component
   */
  label?: string | React.ReactNode;
  /**
   * Optional. Icon that will be the main center piece of the ZeroState component
   */
  icon?: React.ReactNode;
  /**
   * Optional. Typically button actions at the bottom of the component
   */
  actions?: React.ReactNode;
}

const useSxProps = () => {
  return useMemo(() => {
    return {
      root: {
        borderRadius: '12px',
        padding: '12px',
      },
    };
  }, []);
};

export const ZeroState: FC<Props> = ({ dataTestId, label, icon, actions }) => {
  const sxProps = useSxProps();

  if (!label && !icon && !actions) return null;

  return (
    <div data-testid={dataTestId}>
      {/* TODO: Fix this the next time the file is edited */}
      {/* eslint-disable-next-line no-restricted-syntax */}
      <Stack sx={sxProps.root} direction="column" alignItems="center" justifyContent="center" spacing={3}>
        {icon}
        {typeof label === 'string' && (
          // TODO: Fix this the next time the file is edited
          // eslint-disable-next-line no-restricted-syntax
          <Typography style={{ textAlign: 'center' }} variant="body1" fontWeight={400} color="text.secondary">
            {label}
          </Typography>
        )}
        {typeof label !== 'string' && label}
        {actions}
      </Stack>
    </div>
  );
};
