import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { isFuture } from '@modernloop/shared/datetime';
import { CaretDownIcon, CaretRightIcon, EditIcon, TraineeIcon } from '@modernloop/shared/icons';
import { Box, Button, Chip, Collapse, IconButton, Stack, TableCell, TableRow, Theme, Typography } from '@mui/material';
import { format, getYear } from 'date-fns';

import {
  InterviewModuleMemberModuleLogRow_ModuleMemberFragment,
  InterviewModuleMemberModuleLogRow_ModuleMemberInterviewFragment,
  ModuleMemberInterviewStatus,
  ResponseStatus,
  TrainingStatus,
} from 'src/generated/mloop-graphql';

import ModuleMemberTrainingRole from 'src/entities/InterviewModuleMember/Statuses/ModuleMemberTrainingRole';

import { isEventInProgress } from 'src/views-new/CandidateDetails/utils';

import InterviewLog from '../InterviewLog';
import UpdateModuleLogModal from '../Modals/UpdateModuleLogModal';
import PendingApprovalDisableWrapper from '../PendingApprovalDisableWrapper';

type Fragments = {
  moduleMemberInterview: InterviewModuleMemberModuleLogRow_ModuleMemberInterviewFragment;
  moduleMember: InterviewModuleMemberModuleLogRow_ModuleMemberFragment;
};

const InterviewModuleMemberModuleLogRow: FCWithFragments<Fragments> = ({ moduleMemberInterview, moduleMember }) => {
  const [open, setOpen] = useState(false);
  const [openUpdatingModuleLog, setOpenUpdatingModuleLog] = useState(false);

  const isEventOngoing = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (moduleMemberInterview.__typename === 'ModuleMemberManualLog') {
      return null;
    }

    return isEventInProgress(
      moduleMemberInterview.applicationStageInterview.startAt,
      moduleMemberInterview.applicationStageInterview.endAt
    );
  };

  const getDeclinedInterviewer = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (moduleMemberInterview.__typename !== 'ModuleMemberApplicationStageInterviewLog') {
      return null;
    }

    return moduleMemberInterview?.applicationStageInterview.applicationStageInterviewInterviewers?.find(
      (interviewer) =>
        interviewer.responseStatus === ResponseStatus.Declined && moduleMember.employeeId === interviewer.interviewerId
    );
  };

  const getStatus = (status: ModuleMemberInterviewStatus) => {
    if (getDeclinedInterviewer()) {
      return <Chip label="Declined" size="medium" variant="outlined" />;
    }

    if (isEventOngoing()) {
      return <Chip label="In Progress" color="warning" size="medium" variant="outlined" />;
    }

    switch (status) {
      case ModuleMemberInterviewStatus.Scheduled:
        return <Chip label="Scheduled" color="success" size="medium" variant="outlined" />;
      case ModuleMemberInterviewStatus.Completed:
        return <Chip label="Completed" color="success" size="medium" variant="filled" />;
      case ModuleMemberInterviewStatus.DidntCount:
        return <Chip label="Didn’t count" size="medium" variant="outlined" />;
      case ModuleMemberInterviewStatus.Cancelled:
        return <Chip label="Canceled" size="medium" variant="outlined" />;
      default:
        return null;
    }
  };

  // eslint-disable-next-line modernloop/validate-component-definition.cjs
  const getRole = ({
    interviewStatus,
    role,
    moduleMemberRole,
  }: {
    role: TrainingStatus;
    interviewStatus: ModuleMemberInterviewStatus;
    moduleMemberRole: TrainingStatus;
  }) => {
    if (
      interviewStatus === ModuleMemberInterviewStatus.DidntCount ||
      interviewStatus === ModuleMemberInterviewStatus.Cancelled ||
      getDeclinedInterviewer()
    ) {
      return <Chip label="None" size="medium" disabled variant="filled" />;
    }
    if (isFuture(moduleMemberInterview.interviewDate)) {
      if (moduleMemberRole === TrainingStatus.Trained) {
        return <ModuleMemberTrainingRole status={moduleMemberRole} />;
      }
      if (role !== TrainingStatus.Trained) {
        return (
          <Chip
            label="Training"
            icon={<TraineeIcon color="inherit" />}
            color="primary"
            size="medium"
            variant="outlined"
          />
        );
      }
    }

    return <ModuleMemberTrainingRole status={moduleMemberInterview.trainingRole} />;
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }} onClick={() => setOpen(!open)} hover>
        <TableCell component="th" scope="row">
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <IconButton aria-label="expand row" size="small">
              {open ? <CaretDownIcon /> : <CaretRightIcon />}
            </IconButton>
            <Typography variant="body2">
              {getYear(new Date(moduleMemberInterview.interviewDate)) === 1970
                ? 'Unknown'
                : format(new Date(moduleMemberInterview.interviewDate), 'MMM dd, yyyy')}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          {getRole({
            role: moduleMemberInterview.trainingRole,
            interviewStatus: moduleMemberInterview.status,
            moduleMemberRole: moduleMember.status,
          })}
        </TableCell>
        <TableCell>{getStatus(moduleMemberInterview.status)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          sx={{
            backgroundImage: (theme: Theme) =>
              `linear-gradient(180deg, transparent 0%, ${theme.palette.background.contrast} 100%)`,
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack spacing={1} py={1}>
              <InterviewLog moduleMember={moduleMember} interviewLog={moduleMemberInterview} showNagButton={false} />
              {(moduleMemberInterview.status === ModuleMemberInterviewStatus.Completed ||
                moduleMemberInterview.status === ModuleMemberInterviewStatus.DidntCount) && (
                <Box textAlign="center">
                  <PendingApprovalDisableWrapper
                    currentTrainingSessionStatus={moduleMember.stats?.currentTrainingSessionStatus || undefined}
                  >
                    <Stack direction="row" alignItems="center" justifyContent="center">
                      <Stack display="inline-block">
                        <Button
                          onClick={() => setOpenUpdatingModuleLog(true)}
                          startIcon={<EditIcon />}
                          variant="text"
                          size="small"
                          color="inherit"
                        >
                          Manage this log
                        </Button>
                      </Stack>
                    </Stack>
                  </PendingApprovalDisableWrapper>
                </Box>
              )}
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
      {openUpdatingModuleLog && (
        <UpdateModuleLogModal
          moduleMemberInterview={moduleMemberInterview}
          moduleMemberId={moduleMember.employeeId}
          onClose={() => setOpenUpdatingModuleLog(false)}
          moduleMember={moduleMember}
        />
      )}
    </>
  );
};

InterviewModuleMemberModuleLogRow.fragments = {
  moduleMember: gql`
    ${UpdateModuleLogModal.fragments.moduleMember}
    ${InterviewLog.fragments.moduleMember}
    fragment interviewModuleMemberModuleLogRow_moduleMember on InterviewModuleMember {
      id
      employeeId
      status
      interviewModuleId
      stats {
        currentTrainingSessionStatus
      }
      ...updateModuleLogModal_moduleMember
      ...InterviewLog_moduleMember
    }
  `,
  moduleMemberInterview: gql`
    ${UpdateModuleLogModal.fragments.moduleMemberInterview}
    ${InterviewLog.fragments.interviewLog}
    fragment interviewModuleMemberModuleLogRow_moduleMemberInterview on ModuleMemberInterview {
      id
      interviewDate
      trainingRole
      status
      ... on ModuleMemberApplicationStageInterviewLog {
        applicationStageInterview {
          startAt
          endAt
          id
        }
        __typename
      }
      ...updateModuleLogModal_moduleMemberInterview
      ...InterviewLog_interviewLog
      __typename
    }
  `,
};

export default InterviewModuleMemberModuleLogRow;
