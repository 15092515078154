import React from 'react';

import BaseIcon, { Props } from './Icon';

const MoreIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <circle cx="5" cy="10.5" r="1.5" fill="currentColor" />
      <circle cx="10" cy="10.5" r="1.5" fill="currentColor" />
      <circle cx="15" cy="10.5" r="1.5" fill="currentColor" />
    </BaseIcon>
  );
};

export default MoreIcon;
