import React, { useEffect, useLayoutEffect, useState } from 'react';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box } from '@material-ui/core';

import '@fullcalendar/react/dist/vdom';
import FullCalendar from '@fullcalendar/react';
import {
  BusinessHoursInput,
  DateInput,
  EventContentArg,
  EventDropArg,
  EventInput,
  OverlapFunc,
  SlotLabelContentArg,
} from '@fullcalendar/common';
import { ResourceLabelContentArg, ResourceInput } from '@fullcalendar/resource-common';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';

import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin, { EventDragStartArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import scrollgridPlugin from '@fullcalendar/scrollgrid';

import { BaseProps } from 'src/components/types';
import Label from 'src/components/label';
import Tooltip from 'src/components/tooltip';

import { fullcalendarConfig } from 'src/config';
import { Theme } from 'src/theme/type';
import { getTZAbbr } from '@modernloop/shared/datetime';
import { formatToTimeZone } from 'src/utils/dateUtils';

export type CalendarBusinessHours = BusinessHoursInput;
export type CalendarEvent = EventInput;
export type CalendarResource = ResourceInput;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type CalendarProps = BaseProps & {
  events: CalendarEvent[];
  resourceOrderField: string;
  resources: CalendarResource[];
  timeZone: string;

  allDaySlot?: boolean;
  editable?: boolean;
  businessHours?: BusinessHoursInput;
  dayMinWidth?: number;
  initialDate?: DateInput;
  height?: number;
  scrollTime?: string | number;

  renderEventContent?: (args: EventContentArg) => React.ReactNode;
  renderResourceLabelContent?: (args: ResourceLabelContentArg) => React.ReactNode;
  renderSlotLabelContent?: (args: SlotLabelContentArg) => React.ReactNode;

  eventDragStart?: (arg: EventDragStartArg) => void;
  eventDrop?: (arg: EventDropArg) => void;

  // Do not use `eventDragStop` because it is returning stale event start and end time.
  // Using `eventDrop` instead.
  // eventDragStop?: (arg: EventDragStopArg) => void;

  eventOverlap?: OverlapFunc;
  eventResize?: (arg: EventResizeDoneArg) => void;
};

const useCalendarStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendar: {
      width: '100%',
      height: '100%',
      background: theme.palette.background.default,
      '& .fc-scrollgrid': {
        // Border of the entire calendar view.
        border: 'none',
      },
      '& .fc .fc-daygrid-day.fc-day-today': {
        // Background color of all day events.
        backgroundColor: 'transparent',
      },
      '& .fc .fc-timegrid-col.fc-day-today': {
        // Background color of rest of the grid.
        backgroundColor: 'transparent',
      },
      '& .fc .fc-timegrid-slot-minor': {
        borderTop: `1px solid ${theme.palette.action.hover}`,
      },
      '& .fc-direction-ltr .fc-timegrid-slot-label-frame': {
        position: 'relative',
      },
      '& .fc .fc-non-business': {
        backgroundImage: `linear-gradient(45deg, ${theme.palette.action.hover} 25%, transparent 25%),
           linear-gradient(-45deg, ${theme.palette.action.hover} 25%, transparent 25%),
           linear-gradient(45deg, transparent 75%, ${theme.palette.action.hover} 75%),
           linear-gradient(-45deg, transparent 75%, ${theme.palette.action.hover} 75%)`,
        backgroundSize: '20px 20px',
        backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
        height: '100%',
        width: '100%',
      },

      '& .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link':
        {
          boxShadow: 'none',
        },

      /**
       * Section - custom header
       */
      '& .fc .fc-col-header-cell-cushion': {
        height: '100%',
        width: '100%',
      },

      '& .fc .fc-timegrid-divider': {
        // Padding between all day slots and rest of the grid below.
        padding: 0,
      },

      '& .fc-theme-standard .fc-scrollgrid': {
        border: 'none',
      },

      // Header section resource labels
      // This ensures that header section aligns to bottom of header section
      '& .fc .fc-scrollgrid-sync-inner': {
        height: '100%',
        padding: '12px 6px',
      },

      // This removes the border between the Timezone shown in top left column and the days list (second column header)
      '& .fc .fc-scrollgrid-section-header > td': {
        border: 'hidden',
      },

      // For ensuring that the left top cell showing timezone takes correct height
      '& .fc-scroller-harness': {
        display: 'flex',
        height: '100%',

        '& .fc-scrollgrid-sync-table': {
          height: '100%',
        },
      },
    },
  })
);

const useStyles = makeStyles(() =>
  createStyles({
    calendarTimezoneLabel: {
      paddingBottom: '8px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'end',
    },
  })
);

const Calendar = ({
  dataTestId,
  events,
  resources,
  resourceOrderField,
  timeZone,
  editable,
  allDaySlot,
  initialDate,
  height,
  scrollTime,
  dayMinWidth = undefined,
  businessHours,
  renderEventContent,
  renderResourceLabelContent,
  renderSlotLabelContent,
  eventDragStart,
  eventDrop,
  eventOverlap,
  eventResize,
}: CalendarProps): JSX.Element => {
  const classes = useStyles();
  const calendarClasses = useCalendarStyles();
  const [calendarVisible, setCalendarVisible] = useState(false);
  // const calendarRef = useRef<FullCalendar>(null);

  // We will try to scroll to the nearest hour instead of exact time.
  const scrollTimeStr = `${formatToTimeZone(scrollTime ?? Date.now(), 'HH', timeZone)}:00:00`;

  /**
   * Toggling showAllDay when dayMinWidth is set was causing an issue
   * where the header row would not scroll horizontally along with rest of the view.
   *
   * As a fix we re-render the entire <FullCalendar /> component by setting this counter as key
   * and incrementing counter on toggling showAllDay.
   */
  const [renderCounter, setRenderCounter] = useState(0);

  useLayoutEffect(() => {
    setCalendarVisible(true);
  }, []);

  /**
   * Disabling react-hooks/exhaustive-deps for this useEffect because we want to re-render calendar
   * when `allDaySlot` or `initialDate` changes.
   */
  useEffect(() => {
    setRenderCounter(renderCounter + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDaySlot, events, initialDate]);

  // This is another way to update Full calendar when going to a different date.
  // But this seems to be costly in terms of re-render according to React dev tools
  // useEffect(() => {
  //   if (!calendarRef.current) return;
  //   calendarRef.current.getApi().gotoDate(initialDate);
  // }, [initialDate]);

  const defaultRenderEventContent = (args: EventContentArg) => {
    return <Label color="max-contrast-grey">{args.event.title}</Label>;
  };

  const defaultRenderSlotLabelContent = (args: SlotLabelContentArg) => {
    // Time shown on left
    return (
      <Label color="high-contrast-grey" variant="captions">
        {args.text}
      </Label>
    );
  };

  return (
    <div className={calendarClasses.calendar} data-testid={dataTestId}>
      {calendarVisible && (
        <FullCalendar
          /**
           * Hard coded
           */
          // ref={calendarRef}
          key={renderCounter}
          dayHeaders
          eventResizableFromStart
          editable={editable}
          dayMaxEventRows={4}
          headerToolbar={false}
          initialView="resourceTimeGridDay"
          plugins={[resourceTimeGridPlugin, interactionPlugin, scrollgridPlugin, momentTimezonePlugin]}
          eventResourceEditable={false} // To prevent dragging across columns
          slotDuration="00:15:00"
          slotLabelInterval={{ hours: 1 }}
          snapDuration={{
            minutes: 5, // min jump interval when dragging events
          }}
          stickyFooterScrollbar
          stickyHeaderDates
          displayEventTime
          eventBackgroundColor="transparent"
          eventBorderColor="transparent"
          eventTextColor="transparent"
          dragScroll
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short',
            omitZeroMinute: true,
          }}
          schedulerLicenseKey={fullcalendarConfig.licenseKey}
          weekNumbers
          weekNumberContent={() => {
            return (
              <Box className={classes.calendarTimezoneLabel}>
                <Tooltip tooltip={timeZone}>
                  <Label color="high-contrast-grey" variant="captions">
                    {getTZAbbr(timeZone)}
                  </Label>
                </Tooltip>
              </Box>
            );
          }}
          /**
           * Props
           */
          initialDate={initialDate}
          timeZone={timeZone}
          resourceOrder={resourceOrderField}
          resources={resources}
          events={events}
          allDaySlot={allDaySlot}
          dayMinWidth={dayMinWidth}
          height={height}
          scrollTime={scrollTimeStr}
          /**
           * render callbacks
           */
          resourceLabelContent={renderResourceLabelContent}
          eventContent={renderEventContent ?? defaultRenderEventContent}
          eventAllow={(args) => {
            // to determine if an event can be dropped at a particular location.
            // don't allow an event to be dragged to all day section
            return !args.allDay;
          }}
          slotLabelContent={renderSlotLabelContent ?? defaultRenderSlotLabelContent}
          allDayContent={(args) => <Label>{args.text}</Label>}
          /**
           * interaction callbacks
           */
          eventDragStart={eventDragStart}
          // eventDragStop={eventDragStop}
          eventDrop={eventDrop}
          eventOverlap={eventOverlap}
          eventResize={eventResize}
          businessHours={businessHours}
        />
      )}
    </div>
  );
};

export default Calendar;