import React, { FC } from 'react';

import { useFlag } from '@modernloop/shared/feature-flag';
import { Box, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';

import Label from 'src/components/label';

import { MeetingLinksConfig } from 'src/store/slices/schedule-communications';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import ZoomMulti from './ZoomMulti';
import ZoomSingle from './ZoomSingle';
import ZoomSingleDebrief from './ZoomSingleDebrief';

interface Props {
  applicationId?: string;
  scheduleFlow: ScheduleFlowType;
  zoomMeetingConfig?: MeetingLinksConfig;
  showUniqueLinksCheckbox: boolean;
  handleZoomMeetingConfigChange?: (config: MeetingLinksConfig) => void;
}

const ZoomMeetingSection: FC<Props> = ({
  scheduleFlow,
  applicationId,
  zoomMeetingConfig,
  showUniqueLinksCheckbox,
  handleZoomMeetingConfigChange,
}) => {
  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const handleMeetingLinksConfigChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!handleZoomMeetingConfigChange) return;
    if (event.target.value === MeetingLinksConfig.One) {
      handleZoomMeetingConfigChange(MeetingLinksConfig.One);
    } else if (event.target.value === MeetingLinksConfig.Many) {
      handleZoomMeetingConfigChange(MeetingLinksConfig.Many);
    }
  };

  return (
    <Stack spacing={1}>
      {scheduleFlow === ScheduleFlowType.DEBRIEF && applicationId ? (
        <Box display="flex" flexDirection="column">
          <>
            <Label fontWeight={600} variant="body">
              Host
            </Label>
            <ZoomSingleDebrief applicationId={applicationId} />
          </>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column">
          {!scheduleLocationEnabled && (
            <Box mb={1}>
              {showUniqueLinksCheckbox && (
                <RadioGroup onChange={handleMeetingLinksConfigChange} sx={{ pb: 1, pl: 1 }}>
                  <FormControlLabel
                    value={MeetingLinksConfig.One}
                    control={<Radio size="small" checked={zoomMeetingConfig === MeetingLinksConfig.One} />}
                    label="Same link for all interviews"
                  />
                  <FormControlLabel
                    value={MeetingLinksConfig.Many}
                    control={<Radio size="small" checked={zoomMeetingConfig === MeetingLinksConfig.Many} />}
                    label="Different links for each interview"
                  />
                </RadioGroup>
              )}
            </Box>
          )}
          {zoomMeetingConfig === MeetingLinksConfig.Many && <ZoomMulti />}
          {zoomMeetingConfig === MeetingLinksConfig.One && (
            <>
              <Label fontWeight={600} variant="body">
                Host
              </Label>
              <ZoomSingle />
            </>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default ZoomMeetingSection;
