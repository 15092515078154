import { useCallback } from 'react';

import { getLocalTimezone } from '@modernloop/shared/datetime';

import { CandidateDetailsQuery, RenderType } from 'src/generated/mloop-graphql';

import { CandidateAvailability } from 'src/store/slices/candidate-availability';

import ApplicationPlaceholderFiller, {
  useApplicationPlaceholderFillerOptions,
} from 'src/utils/PlaceholderFiller/ApplicationPlaceholderFiller';
import CandidateAvailabilityPlaceholderFiller, {
  useCandidateAvailabilityPlaceholderFillerOptions,
} from 'src/utils/PlaceholderFiller/CandidateAvailabilityPlaceholderFiller';
import CandidatePlaceholderFiller, {
  useCandidatePlaceholderFillerOptions,
} from 'src/utils/PlaceholderFiller/CandidatePlaceholderFiller';
import JobPlaceholderFiller, { useJobPlaceholderFiller } from 'src/utils/PlaceholderFiller/JobPlaceholderFiller';
import TaskPlaceholderFiller, { useTaskPlaceholderFiller } from 'src/utils/PlaceholderFiller/TaskPlaceholderFiller';
import UserPlaceholderFiller, {
  useUserPlaceholderFillerOptions,
} from 'src/utils/PlaceholderFiller/UserPlaceholderFiller';

export const useCandidateAvailabilityEmailFiller = (
  applicationId: string,
  jobStageId: string,
  candidateAvailability: CandidateAvailability | undefined,
  candidateDetails?: CandidateDetailsQuery,
  taskId?: string,
  assigneeId?: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
((input: string) => string) => {
  const jobId = candidateDetails?.candidate?.applications?.find((application) => application?.id === applicationId)?.job
    ?.id;

  const [applicationPlaceholderFillerOptions] = useApplicationPlaceholderFillerOptions({ applicationId });

  const [candidatePlaceholderFillerOptions] = useCandidatePlaceholderFillerOptions({
    applicationId,
    candidateId: candidateDetails?.candidate?.id,
    jobId: candidateDetails?.candidate?.applications?.find((application) => application?.id === applicationId)?.job?.id,
  });

  const [candidateAvailabilityPlaceholderFillerOptions] = useCandidateAvailabilityPlaceholderFillerOptions({
    applicationId,
    jobStageId,
    timezone: candidateDetails?.candidate?.timezone || getLocalTimezone(),
    candidateAvailability,
  });

  const [userPlaceholderFillerOptions] = useUserPlaceholderFillerOptions({
    renderType: RenderType.Html,
  });

  const [taskPlaceholderFillerOptions] = useTaskPlaceholderFiller({
    renderType: RenderType.Html,
    taskId,
    assigneeId,
  });

  const [jobPlaceholderFillerOptions] = useJobPlaceholderFiller({
    renderType: RenderType.Html,
    jobId,
    jobStageId,
    applicationId,
  });

  const result = useCallback(
    (input: string) => {
      let filledResult = ApplicationPlaceholderFiller.getFilledText(input, applicationPlaceholderFillerOptions);
      filledResult = CandidatePlaceholderFiller.getFilledText(filledResult, candidatePlaceholderFillerOptions);
      filledResult = CandidateAvailabilityPlaceholderFiller.getFilledText(
        filledResult,
        candidateAvailabilityPlaceholderFillerOptions
      );
      filledResult = TaskPlaceholderFiller.getFilledText(filledResult, taskPlaceholderFillerOptions);
      filledResult = JobPlaceholderFiller.getFilledText(filledResult, jobPlaceholderFillerOptions);
      filledResult = UserPlaceholderFiller.getFilledText(filledResult, userPlaceholderFillerOptions);
      return filledResult;
    },
    [
      applicationPlaceholderFillerOptions,
      candidatePlaceholderFillerOptions,
      candidateAvailabilityPlaceholderFillerOptions,
      taskPlaceholderFillerOptions,
      jobPlaceholderFillerOptions,
      userPlaceholderFillerOptions,
    ]
  );

  return result;
};
