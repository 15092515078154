import { ThemeOptions } from '@mui/material';

/**
 * View Container props and classes at https://mui.com/material-ui/api/container/#css
 */
export const container: ThemeOptions['components'] = {
  MuiContainer: {
    defaultProps: {
      maxWidth: 'xl',
      fixed: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        [theme.breakpoints.only('xs')]: {
          padding: theme.spacing(0, 1.5),
        },
        [theme.breakpoints.between('sm', 'md')]: {
          padding: theme.spacing(0, 2.5),
        },
        [theme.breakpoints.up('lg')]: {
          padding: theme.spacing(0, 4),
        },
      }),
    },
  },
};
