import React from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { getExternalErrorMessage } from '@modernloop/shared/utils';
import { Divider, Paper, Stack, Theme, Typography } from '@mui/material';
import { isAfter } from 'date-fns';
import { format as formatTz } from 'date-fns-tz';
import { useSnackbar } from 'notistack';

import {
  RescheduleLogModal_RescheduleLogFragment,
  RescheduleLogsList_RescheduleLogsFragment,
  useRescheduleLogDeleteMutation,
} from 'src/generated/mloop-graphql';

import TrashIllustration from 'src/assets/images/illustrations/trash_64.png';

import MoreMenuButton from 'src/components/MoreMenuButton';
import { MenuOption } from 'src/components/menu';

import ActivityLogText from 'src/entities/ActivityLog/Helpers/ActivityLogText';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import DurationLabel from '../../../../components/DurationLabel';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import Tooltip from '../../../../components/tooltip';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { assertIsoTimestamp } from '../../../../types/IsoTimestamp';

import RescheduleLogModal from './RescheduleLogModal';

interface Props {
  onModalClose: (val: boolean) => void;
  onRescheduleLogSelect: (rescheduleLog: RescheduleLogModal_RescheduleLogFragment) => void;
}

interface Fragments {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/component-with-fragments.cjs
  rescheduleLogs?: RescheduleLogsList_RescheduleLogsFragment[];
}

enum RescheduleLogOption {
  EDIT = 'edit-reschedule-log',
  DELETE = 'delete-reschedule-log',
}

const RescheduleLogsList: FCWithFragments<Fragments, Props> = ({
  rescheduleLogs,
  onModalClose,
  onRescheduleLogSelect,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [rescheduleLogId, setRescheduleLogId] = React.useState();
  const refetchactivityFeed = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();
  const [rescheduleLogDeleteMutation, { loading: deleting }] = useRescheduleLogDeleteMutation();
  const options = [
    {
      id: RescheduleLogOption.EDIT,
      value: 'Edit',
    },
    {
      id: RescheduleLogOption.DELETE,
      value: 'Delete',
    },
  ];

  const onClose = () => {
    setShowDeleteDialog(false);
  };

  const handleDeleteRescheduleLog = () => {
    rescheduleLogDeleteMutation({
      variables: {
        input: {
          id: rescheduleLogId,
        },
      },
    }) // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      .then(() => {
        setShowDeleteDialog(false);
        refetchScheduleTaskQuery();
        refetchactivityFeed();
        enqueueSnackbar(`Reschedule log deleted successfully`, { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(getExternalErrorMessage(error), { variant: 'error' });
      });
  };

  function handleMenuOptionSelect(option: MenuOption, item) {
    if (option.id === RescheduleLogOption.EDIT) {
      onRescheduleLogSelect(item);
      onModalClose(true);
    } else if (option.id === RescheduleLogOption.DELETE) {
      setRescheduleLogId(item.id);
      setShowDeleteDialog(true);
    }
  }

  return (
    <ConditionalThemeProvider>
      <Stack direction="column" spacing={1}>
        {rescheduleLogs?.map((item) => {
          const isEditedTooltip = (
            <Typography variant="body2" color="text.secondary">
              Edited{' '}
              <DurationLabel
                timestamp={assertIsoTimestamp(item?.updatedAt as string)}
                labelProps={{
                  variant: 'captions',
                  fontWeight: 400,
                  color: 'high-contrast-grey',
                  noWrap: true,
                  component: 'span',
                }}
              />{' '}
              by {item?.lastUpdatedBy?.fullName || ''}
            </Typography>
          );
          const isEdited = isAfter(new Date(item?.updatedAt), new Date(item?.createdAt)) && (
            <Tooltip tooltip={isEditedTooltip}>
              <Typography variant="body2" color="text.secondary">
                &nbsp;&#x2022;&nbsp;Edited
              </Typography>
            </Tooltip>
          );

          return (
            <Paper
              key={item.id}
              sx={{
                padding: (theme: Theme) => theme.spacing(2),
                border: (theme: Theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Stack spacing={1}>
                <Stack direction="row" alignItems="left" justifyContent="space-between">
                  <Stack direction="column" alignItems="left" justifyContent="space-between">
                    <Typography
                      variant="subtitle1"
                      sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    >
                      {item?.rescheduleReason?.reason}
                    </Typography>
                    <Stack direction="row" alignItems="left" justifyContent="left">
                      <Typography variant="body2" color="text.secondary">
                        Rescheduled on{' '}
                        {formatTz(new Date(item?.rescheduledAt), 'MMM d, yyyy', { timeZone: getLocalTimezone() })}{' '}
                        by&nbsp;
                        <ActivityLogText fontWeight={600} color="foreground" noWrap>
                          {item?.performedBy?.fullName}
                        </ActivityLogText>
                      </Typography>
                      {isEdited}
                    </Stack>
                  </Stack>
                  <MoreMenuButton
                    dataTestId="edit-reschedule-log-button"
                    style={{
                      height: '24px',
                      width: '32px',
                      borderRadius: '4px',
                      backgroundColor: 'text.secondary',
                    }}
                    variant="contained"
                    onSelect={(option) => {
                      handleMenuOptionSelect(option, item);
                    }}
                    options={options}
                  />
                </Stack>
                {item?.note && (
                  <>
                    <Divider />
                    <Typography variant="body2" color="text.secondary">
                      {item?.note}
                    </Typography>
                  </>
                )}
              </Stack>
            </Paper>
          );
        })}
      </Stack>
      {showDeleteDialog && (
        <Dialog
          open
          onClose={() => setShowDeleteDialog(false)}
          title="Delete reschedule log?"
          subTitle="This cannot be undone."
          decoration={
            <img style={{ width: '64px', height: '64px' }} src={TrashIllustration} alt="Delete reschedule log" />
          }
          cancelOptions={{
            onClick: onClose,
          }}
          submitOptions={{
            label: 'Delete',
            isDangerous: true,
            isLoading: deleting,
            isDisabled: deleting,
            onClick: handleDeleteRescheduleLog,
          }}
        >
          <></>
        </Dialog>
      )}
    </ConditionalThemeProvider>
  );
};

RescheduleLogsList.fragments = {
  rescheduleLogs: gql`
    ${RescheduleLogModal.fragments.rescheduleLog}
    fragment RescheduleLogsList_rescheduleLogs on RescheduleLog {
      id
      ...RescheduleLogModal_rescheduleLog
    }
  `,
};

export const RescheduleLogDeleteMutation = gql`
  mutation RescheduleLogDelete($input: RescheduleLogDeleteInput!) {
    rescheduleLogDelete(input: $input) {
      result
    }
  }
`;

export default RescheduleLogsList;
