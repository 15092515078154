import React, { useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Button from 'src/components/button';
import { TriangleDownIcon } from 'src/components/icons';
import { MenuOption } from 'src/components/menu';
import DurationMenu, { DURATION_OPTIONS, MaxDuration } from 'src/components/menu/DurationMenu';

import { Theme } from 'src/theme';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type DurationPickerProps = {
  dataTestId?: string;
  duration: number;
  maxDuration?: MaxDuration;
  onChange: (value: number) => void;
  additionalOptions?: MenuOption[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '6px',
      fontWeight: theme.typography.fontWeightRegular,
      minWidth: '200px',
      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  })
);

const DurationPicker = ({
  additionalOptions,
  dataTestId,
  duration,
  maxDuration,
  onChange,
}: DurationPickerProps): JSX.Element => {
  const classes = useStyles();
  const [showDurationMenu, setShowDurationMenu] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const durationString = [...(additionalOptions ?? []), ...DURATION_OPTIONS].find(
    (option) => option.id === duration
  )?.value;

  const handleToggleMinTimeBlockMenu = () => setShowDurationMenu(!showDurationMenu);
  const handleDurationSelect = (mins: number) => {
    handleToggleMinTimeBlockMenu();
    onChange(mins);
  };

  return (
    <>
      <Button
        dataTestId={dataTestId}
        variant="outlined"
        className={classes.button}
        label={durationString}
        ref={buttonRef}
        endIcon={<TriangleDownIcon />}
        onClick={handleToggleMinTimeBlockMenu}
      />
      {showDurationMenu && (
        <DurationMenu
          id="min-time-block"
          selected={duration}
          maxDuration={maxDuration}
          additionalOptions={additionalOptions}
          open
          anchorEl={buttonRef}
          onClose={handleToggleMinTimeBlockMenu}
          onSelect={handleDurationSelect}
        />
      )}
    </>
  );
};

export default DurationPicker;
