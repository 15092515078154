// eslint-disable-next-line modernloop/restrict-imports.cjs
import { CrossIcon, SearchIcon } from '../../icons';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { useState } from 'react';
import type { FC } from 'react';

export type Props = Omit<TextFieldProps, 'onChange'> & {
  dataTestId?: string;
  onChange?: (val: string) => void;
  value?: string;
};

const Search: FC<Props> = ({
  autoFocus,
  dataTestId,
  InputProps,
  placeholder,
  onChange,
  inputProps,
  value: valueProp,
}): JSX.Element => {
  const [value, setVal] = useState<string>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: { target: { value: any } }) => {
    const val = event.target.value;
    setVal(val);

    if (onChange) {
      onChange(val);
    }
  };

  const clearSearchText = () => {
    setVal('');
    if (onChange) {
      onChange('');
    }
  };

  const searchText = valueProp ?? value;

  return (
    <TextField
      autoFocus={autoFocus}
      fullWidth
      value={searchText}
      InputProps={{
        startAdornment: <SearchIcon />,
        endAdornment: searchText ? (
          <IconButton onClick={clearSearchText}>
            <CrossIcon />
          </IconButton>
        ) : undefined,
        ...InputProps,
      }}
      placeholder={placeholder}
      data-testId={dataTestId}
      onChange={handleChange}
      inputProps={inputProps}
    />
  );
};

export default Search;
