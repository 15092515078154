import { datadogRum } from '@datadog/browser-rum';

const SITE = 'datadoghq.com';

export type DatadogServiceConfig = {
  applicationId: string;
  clientToken: string;
  sessionSampleRate: number;
  sessionReplaySampleRate: number;
  service: string; // ex. mloop-employee-website or mloop-candidate-website
  env: 'dev' | 'stage' | 'prod';
};

export const initializeDatadog = async (config: DatadogServiceConfig) => {
  try {
    datadogRum.init({
      ...config,
      site: SITE,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: ['https://api.modernloop.io', 'https://api.stg.modernloop.io'],
    });
  } catch (e) {
    console.log('Error initializing datadog', e);
  }
};

// There are only two types of users on the FE, customer company employees or candidates
type DatadogUserInfoType = 'employee' | 'candidate';

type DatadogUserInfo = {
  id: string; // This is the user's id in the system employee_id or candidate email
  name: string; // This should be the best name we have for the user
  email: string; // This should be the best email we have for the user
  type: DatadogUserInfoType; // employee or candidate
  org_id: string; // This is the customer company id
  org_name: string; // This is the customer company name
};

export const identifyDatadogUser = async (user: DatadogUserInfo) => {
  try {
    datadogRum.setUser(user);
  } catch (e) {
    console.log('Error identifying user to datadog', e);
  }
};

export const logDatadogError = async (error: Error, context?: Record<string, string>) => {
  try {
    datadogRum.addError(error, context);
  } catch (e) {
    console.log('Error logging error to datadog', e);
  }
};
