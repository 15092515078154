import React from 'react';

import BaseIcon, { Props } from './Icon';

const QuestionMarkIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.20615 6.74165C7.71083 5.8692 8.65192 5.28547 9.7283 5.28547C11.336 5.28547 12.6394 6.5888 12.6394 8.19653C12.6394 9.80427 11.336 11.1076 9.7283 11.1076C9.23544 11.1076 8.8359 11.5071 8.8359 12C8.8359 12.4929 9.23544 12.8924 9.7283 12.8924C12.3218 12.8924 14.4242 10.79 14.4242 8.19653C14.4242 5.60308 12.3218 3.50067 9.7283 3.50067C7.98905 3.50067 6.4718 4.44669 5.66121 5.84795C5.41442 6.27458 5.56021 6.82048 5.98683 7.06727C6.41345 7.31406 6.95936 7.16827 7.20615 6.74165ZM10 16.5C10.5523 16.5 11 16.0523 11 15.5C11 14.9477 10.5523 14.5 10 14.5C9.44772 14.5 9 14.9477 9 15.5C9 16.0523 9.44772 16.5 10 16.5Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default QuestionMarkIcon;
