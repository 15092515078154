import { addDays, areIntervalsOverlapping, compareAsc, hoursToMinutes, parseISO } from 'date-fns';
import _, { round } from 'lodash';

import { DateTimeRangeOutput } from 'src/generated/mloop-graphql';

import { isSameDay, startOfDay } from './dateUtils';
import { TimeRange } from './getTimePeriods';

export const sortTimeRanges = (ranges: TimeRange[]): TimeRange[] => {
  return ranges.sort((first, second) => {
    const cmpResult = compareAsc(parseISO(first.start), parseISO(second.start));
    return cmpResult === 0 ? compareAsc(parseISO(first.end), parseISO(second.end)) : cmpResult;
  });
};

export const sortDateTimeRanges = (ranges: DateTimeRangeOutput[]): DateTimeRangeOutput[] => {
  if (!ranges.length) return ranges;
  return ranges.sort((first, second) => {
    const cmpResult = compareAsc(parseISO(first.startAt), parseISO(second.startAt));
    return cmpResult === 0 ? compareAsc(parseISO(first.endAt), parseISO(second.endAt)) : cmpResult;
  });
};

export const mergeTimeRanges = (ranges: TimeRange[]): TimeRange[] => {
  const sortedRanges = sortTimeRanges([...ranges]);

  let index = 0;
  const mergedRanges: TimeRange[] = [];

  sortedRanges.forEach((item, i) => {
    const range = { ...sortedRanges[i] };
    if (i === 0) {
      mergedRanges.push(range);
      return;
    }

    if (
      areIntervalsOverlapping(
        { start: parseISO(mergedRanges[index].start), end: parseISO(mergedRanges[index].end) },
        { start: parseISO(range.start), end: parseISO(range.end) }
      )
    ) {
      mergedRanges[index].end =
        parseISO(mergedRanges[index].end).getTime() > parseISO(range.end).getTime()
          ? mergedRanges[index].end
          : range.end;
    } else {
      index++;
      mergedRanges.push(range);
    }
  });

  return mergedRanges;
};

/**
 * A time range of (9am to 5pm) in PST spans 2 days in IST (9:30pm to midnight) & (midnight to 5:30am)
 * This function helps with the above conversion.
 * The main use case is for showing correct dates in TimeRangePicker and DateTimeRangePicker components.
 * @param ranges The TimeRange array in UTC.
 * @param timezone The timezone to which the time ranges needs to be returned in.
 * @returns An array of TimeRange such that all the TimeRange are within the same day for passed timezone
 */

export const getTimeRangesInTimezone = (ranges: TimeRange[], timezone: string): TimeRange[] => {
  const dateRanges = sortTimeRanges([...ranges]).map((range) => ({
    start: parseISO(range.start),
    end: parseISO(range.end),
  }));

  const tempResult: TimeRange[] = [];

  dateRanges.forEach((dateRange) => {
    if (isSameDay(dateRange.start, dateRange.end, timezone)) {
      tempResult.push({ start: dateRange.start.toISOString(), end: dateRange.end.toISOString() });
      return;
    }

    tempResult.push({
      start: dateRange.start.toISOString(),
      end: startOfDay(addDays(dateRange.start, 1), timezone).toISOString(),
    });

    // TODO: Maybe handle the scenario where the start and end date span multiple days instead of consecutive days.
    tempResult.push({ start: startOfDay(dateRange.end, timezone).toISOString(), end: dateRange.end.toISOString() });
  });

  const mergedResult = mergeTimeRanges(tempResult);
  const result: TimeRange[] = [];
  let index = 0;

  mergedResult.forEach((mergedRange, i) => {
    if (i === 0) {
      result.push(mergedRange);
      return;
    }

    if (result[index].end === mergedRange.start && isSameDay(result[index].start, mergedRange.end, timezone)) {
      result[index].end = mergedRange.end;
    } else {
      result.push(mergedRange);
      index++;
    }
  });

  return result;
};

export const TIME_UNITS = {
  Day: 'day',
  Hour: 'hour',
  Min: 'min',
};

// Function that returns a string depending on the number of decimal points.
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getTimeUnitString = (num: number, decimalPoints: number, dateUnit: string) => {
  // Checking if the number is a whole number.

  const newNumber = round(num, decimalPoints);

  return `${newNumber} ${dateUnit}${newNumber === 1 ? '' : 's'}`;
};

export const hoursToDayDisplay = (hours): string => {
  let time = '';

  const isNegative = !!(hours < 0);

  // Calculating the date according to the absolute value of hours.
  const absoluteHours = Math.abs(hours);

  // If hours are greater than 24 -> String should be in 'days'
  if (absoluteHours > 24) {
    const days = parseFloat((absoluteHours / 24).toFixed(1));

    time += getTimeUnitString(days, 1, TIME_UNITS.Day);
  }
  // If hours are greater than 1 AND less than 24 -> String should be in 'hours'
  else if (absoluteHours > 1) {
    time += getTimeUnitString(absoluteHours, 1, TIME_UNITS.Hour);
  }
  // If hours are less than 1 -> String should be in 'mins'
  else {
    const minutes = parseFloat(hoursToMinutes(absoluteHours).toFixed(1));

    time += getTimeUnitString(minutes, 1, TIME_UNITS.Min);
  }

  return isNegative ? `-${time.trim()}` : time.trim();
};

export const getUniqueTimeRanges = (timeRanges: TimeRange[]): TimeRange[] => {
  return _.uniqWith(timeRanges, _.isEqual);
};
