import React, { FC } from 'react';

import { Badge, BadgeProps } from '@mui/material';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface IconWithBadgeProps {
  badgeProps?: BadgeProps;
  badge: JSX.Element | null;
  icon: JSX.Element | null;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const IconWithBadge: FC<IconWithBadgeProps> = ({ icon, badge, badgeProps }) => {
  return (
    <Badge
      badgeContent={
        badge ? <div style={{ width: 14, height: 14, transform: 'translate (-4px, 4px) ' }}>{badge}</div> : null
      }
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...badgeProps}
    >
      {icon}
    </Badge>
  );
};

export default IconWithBadge;
