/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ErrorIcon } from '@modernloop/shared/icons';
import { CircularProgress, Stack, StackProps } from '@mui/material';
import { v4 as uuid } from 'uuid';

import {
  Schedule_ApplicationFragment,
  SelfScheduleOptionSettings_JobStageSettingsFragment,
  SelfScheduleOptionsModal_SelfScheduleFragment,
  useEmployeeByIdsQuery,
} from 'src/generated/mloop-graphql';

import ShareDialog from 'src/components/Dialogs/ShareDialog';
import { useDurationLabel } from 'src/components/DurationLabel';
import MoreMenuButton from 'src/components/MoreMenuButton';
import Paper, { PaperProps } from 'src/components/Paper';
import Button from 'src/components/button';
import { SendIcon } from 'src/components/icons';
import Label from 'src/components/label';

import useIsApplicationRejected from 'src/hooks/useIsApplicationRejected';

import { Theme as ThemeV5 } from 'src/themeMui5/type';

import { useCandidatePortalUrl } from 'src/urls/hooks/useCandidatePortalUrl';

import { useFetchInterviewPlanForJobStage } from 'src/views-new/InterviewPlan/UseFetchInterviewPlan';

import { Theme } from 'src/theme';

import RejectedScheduleAlert from '../ScheduleTask/ScheduleTaskRequirements/Schedule/RejectedScheduleAlert';

import SelfScheduleDisableAlert from './SelfScheduleDisableAlert';
import { SelfScheduleOptionsModalPage } from './SelfScheduleOptionsModal';
import SelfScheduleOptionsModal from './SelfScheduleOptionsModalWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
    },
    action: {
      alignSelf: 'center',
      flexShrink: 0,
    },
    startButton: {
      alignSelf: 'center',
    },
    alertBackground: {
      backgroundColor: theme.palette.background.default,
    },
    reshareLinkButton: {
      flexShrink: 0,
    },
  })
);

const useSxProps = () => {
  return useMemo(() => {
    return {
      root: {
        borderRadius: (theme: ThemeV5) => theme.spacing(1.5),
        padding: (theme: ThemeV5) =>
          `${theme.spacing(2.5)} ${theme.spacing(1.5)} ${theme.spacing(2.5)} ${theme.spacing(2)}`,
      },
    };
  }, []);
};

type Props = {
  applicationId: string;
  candidateId: string;
  jobStageId: string;
  customJobStageId?: string;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/component-with-fragments.cjs
  taskSelfSchedule: SelfScheduleOptionsModal_SelfScheduleFragment;
  showSelfScheduleDialogs?: boolean;
  onClose?: (emailSent?: boolean) => void;
  settings?: SelfScheduleOptionSettings_JobStageSettingsFragment | null;
  application?: Schedule_ApplicationFragment;
};

const SelfSchedule = ({
  applicationId,
  candidateId,
  jobStageId,
  customJobStageId,
  taskSelfSchedule,
  showSelfScheduleDialogs = false,
  onClose,
  settings,
  application,
}: Props) => {
  const classes = useStyles();
  const sxProps = useSxProps();
  const isApplicationRejected = useIsApplicationRejected(application?.status);
  const [showShareAlert, setShowShareAlert] = useState(false);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showDisableSelfScheduleAlert, setShowDisableSelfScheduleAlert] = useState(false);

  const { candidatePortalUrl: selfScheduleUrl } = useCandidatePortalUrl(applicationId, {
    taskId: taskSelfSchedule?.taskId,
  });

  const { data: employeeData } = useEmployeeByIdsQuery({
    variables: { input: [taskSelfSchedule?.lastUpdatedByEmployeeId] },
    skip: !taskSelfSchedule?.lastUpdatedByEmployeeId,
  });

  /**
   * We always fetch interview plan for customJobStageId if present or fallback to the original jobstage id
   */
  const toFetchJobStageId = customJobStageId || jobStageId;

  /**
   * If there is no custom jobstage id we create a new id locally to map the changes in redux so that original job stage id in redux in not affected
   * If customJobStageId toAssociateJobStageId will always be undefined
   */
  const toAssociateJobStageId = useMemo(() => {
    return customJobStageId ? undefined : uuid();
  }, [customJobStageId]);

  useFetchInterviewPlanForJobStage(toFetchJobStageId, toAssociateJobStageId, jobStageId);

  useEffect(() => {
    // showOnlyShareDialogs will be set to true when a new self schedule request is created without sending email.
    // So lets show the share dialog in that case.
    if (showSelfScheduleDialogs !== undefined) {
      setShowShareAlert(showSelfScheduleDialogs);
    }
  }, [showSelfScheduleDialogs]);

  const durationLabel = useDurationLabel(taskSelfSchedule?.createdAt);
  const emailSentAtDurationLabel = useDurationLabel(taskSelfSchedule?.selfScheduleEmailSentAt);

  const handleShowShareAlert = () => setShowShareAlert(true);
  const handleHideShareAlert = () => {
    setShowShareAlert(false);
    if (onClose) onClose();
  };
  const handleShowOptionsModal = () => {
    setShowOptionsModal(true);
    if (!showSelfScheduleDialogs) handleHideShareAlert();
  };
  const handleHideOptionsModal = (emailSent?: boolean) => {
    if (emailSent) {
      setShowOptionsModal(false);
    } else {
      handleShowShareAlert();
    }

    if (onClose) onClose(emailSent);
  };

  const handleDeleteSelfSchedule = async () => {
    setShowDisableSelfScheduleAlert(false);
  };

  let paperColor: PaperProps['color'];
  let icon = <></>;
  let content = <></>;
  let actions = <CircularProgress size={20} />;
  let alignItems: StackProps['alignItems'];

  if (isApplicationRejected) {
    paperColor = 'error';
    icon = <ErrorIcon color="error" />;
    content = (
      <RejectedScheduleAlert
        selfScheduleOptions={{
          createdAt: taskSelfSchedule.createdAt,
          selfScheduleEmailSentAt: taskSelfSchedule.selfScheduleEmailSentAt,
          creatorName: employeeData?.employeeByIds?.length === 1 ? employeeData?.employeeByIds[0].fullName ?? '' : '',
        }}
        heading="Request cannot be fulfilled: application is rejected"
        actionsJSX={
          <Stack alignItems="center" direction="row" sx={{ flexWrap: 'wrap', gap: 1, paddingTop: 1 }}>
            <Button
              variant="outlined"
              label="Cancel request"
              size="small"
              onClick={() => {
                setShowDisableSelfScheduleAlert(true);
              }}
            />
          </Stack>
        }
      />
    );
    actions = <></>;
  } else {
    paperColor = taskSelfSchedule.taskId ? 'pending' : 'contrast';
    content = (
      <Stack spacing={0.25} className={classes.content}>
        <Label color="high-contrast-grey" fontWeight={600}>
          Waiting for the candidate to self-schedule…
        </Label>
        {employeeData?.employeeByIds?.length === 1 && (
          <>
            {taskSelfSchedule.selfScheduleEmailSentAt && (
              <Label color="high-contrast-grey" variant="captions">
                A link was sent to the candidate by {employeeData?.employeeByIds[0].fullName} {emailSentAtDurationLabel}
                .
              </Label>
            )}
            {!taskSelfSchedule.selfScheduleEmailSentAt && (
              <Label color="high-contrast-grey" variant="captions">
                Created by {employeeData?.employeeByIds[0].fullName} {durationLabel}.
              </Label>
            )}
          </>
        )}
      </Stack>
    );
    icon = <SendIcon color={taskSelfSchedule.taskId ? 'pending' : 'high-contrast-grey'} />;
    actions = (
      <Stack direction="row" alignItems="center" className={classes.reshareLinkButton} spacing={1}>
        <Button
          dataTestId="self-schedule-re-share-link-button"
          variant="outlined"
          color="info"
          label="Re-share link"
          onClick={handleShowShareAlert}
        />
        <MoreMenuButton
          dataTestId="self-schedule-more-menu-button"
          options={[{ id: 'self-schedule-option-delete', color: 'error', value: 'Cancel request' }]}
          onSelect={() => setShowDisableSelfScheduleAlert(true)}
        />
      </Stack>
    );
  }

  const selfScheduleOptionsModalPage = taskSelfSchedule?.id
    ? SelfScheduleOptionsModalPage.SHARE_SELF_SCHEDULE
    : SelfScheduleOptionsModalPage.OPTIONS;

  return (
    <>
      <Paper color={paperColor} disableBorder sx={sxProps.root}>
        <Stack direction="row" alignItems={alignItems} spacing={1}>
          {icon}
          {content}
          {actions}
        </Stack>
      </Paper>
      {showShareAlert && taskSelfSchedule?.id && (
        <ShareDialog
          longUrl={selfScheduleUrl}
          content="This is your candidate’s personal link for self-scheduling this stage of their application."
          onClose={handleHideShareAlert}
          onSendEmail={handleShowOptionsModal}
        />
      )}
      {showOptionsModal && (
        <SelfScheduleOptionsModal
          jobId={application?.job?.id}
          applicationId={applicationId}
          candidateId={candidateId}
          jobStageId={jobStageId}
          customJobStageId={customJobStageId}
          taskId={taskSelfSchedule?.taskId}
          // Only show the send later option if this is the initial create of the self schedule request and not a re-share
          showSendLater={
            !taskSelfSchedule && selfScheduleOptionsModalPage !== SelfScheduleOptionsModalPage.SHARE_SELF_SCHEDULE
          }
          selfSchedule={taskSelfSchedule || undefined}
          selfScheduleOptionsModalPage={selfScheduleOptionsModalPage}
          onClose={handleHideOptionsModal}
          settings={settings}
        />
      )}
      {showDisableSelfScheduleAlert && taskSelfSchedule?.id && (
        <SelfScheduleDisableAlert
          selfScheduleOptionsId={taskSelfSchedule.id}
          onClose={() => setShowDisableSelfScheduleAlert(false)}
          onDelete={handleDeleteSelfSchedule}
        />
      )}
    </>
  );
};

export default SelfSchedule;
