import React, { useCallback, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  ActivityLogNoteDetails,
  ActivityLogNote_ActivityLogFragment,
  useActivityLogDeleteNoteMutation,
  useActivityLogEditNoteMutation,
} from 'src/generated/mloop-graphql';

import DeleteNoteModal from 'src/components/ActivityLogs/HelperModals/Notes/DeleteNoteModal';
import EditNoteModal from 'src/components/ActivityLogs/HelperModals/Notes/EditNoteModal';
import Label from 'src/components/label';
import { MenuOption } from 'src/components/menu';
import { FCWithFragments } from 'src/components/types';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useEmployeeId from 'src/hooks/useEmployeeId';

import { replaceLinksWithAnchorTags } from 'src/utils/strings';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogContent from '../../Helpers/ActivityLogContent';

interface Fragment {
  activityLog: ActivityLogNote_ActivityLogFragment;
}

enum ActivityLogNoteMenuOptionIds {
  EditNote = 'edit-note',
  DeleteNote = 'delete-note',
}

const ActivityLogNoteMenuOptions: MenuOption[] = [
  {
    id: ActivityLogNoteMenuOptionIds.EditNote,
    value: 'Edit your comment',
  },
  {
    id: ActivityLogNoteMenuOptionIds.DeleteNote,
    value: 'Delete your comment',
  },
];

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const ActivityLogNote: FCWithFragments<Fragment> = ({ activityLog }) => {
  const refetchData = useActivityFeedRefetch();
  const { enqueueSnackbar } = useSnackbar();

  const thisEmployeeId = useEmployeeId();

  const { details, activityActorEmployeeId } = activityLog;
  const noteData = details as ActivityLogNoteDetails;

  const doesEmployeeHaveEditAccess = useMemo(
    () => thisEmployeeId === activityActorEmployeeId,
    [activityActorEmployeeId, thisEmployeeId]
  );

  const [activityLogEditNote] = useActivityLogEditNoteMutation();
  const [activityLogDeleteNote] = useActivityLogDeleteNoteMutation();

  const [noteValue, setNoteValue] = useState(noteData?.note ?? '');

  const [isEditNoteModalOpen, setIsEditNoteModalOpen] = useState(false);
  const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState(false);

  const onMenuOptionSelect = useCallback((option: MenuOption) => {
    switch (option.id) {
      case ActivityLogNoteMenuOptionIds.EditNote:
        setIsEditNoteModalOpen(true);
        break;
      case ActivityLogNoteMenuOptionIds.DeleteNote:
        setIsDeleteNoteModalOpen(true);
        break;
      default:
        break;
    }
  }, []);

  const onEditNoteModalClose = useCallback(() => {
    setIsEditNoteModalOpen(false);
  }, []);

  const onDeleteNoteModalClose = useCallback(() => {
    setIsDeleteNoteModalOpen(false);
  }, []);

  const onEditNoteModalSubmit = useCallback(
    (newNote: string) => {
      setIsEditNoteModalOpen(false);

      if (isEmpty(newNote)) {
        setIsDeleteNoteModalOpen(true);
      } else {
        activityLogEditNote({
          variables: {
            input: {
              activityLogId: activityLog.id,
              note: newNote.trim(),
            },
          },
        }) // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line promise/always-return
          .then(() => {
            setNoteValue(newNote);
          })
          .catch((e) => {
            enqueueSnackbar(`Note update failed: ${e}`, { variant: 'error' });
          });
      }
    },
    [activityLog.id, activityLogEditNote, enqueueSnackbar]
  );

  const onDeleteNoteModalSubmit = useCallback(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    activityLogDeleteNote({ variables: { input: { activityLogId: activityLog.id } } }) // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      .then(() => {
        refetchData();
      })
      .catch((e) => {
        enqueueSnackbar(`Comment delete failed: ${e}`, { variant: 'error' });
      })
      .finally(() => {
        setIsDeleteNoteModalOpen(false);
      });
  }, [activityLog.id, activityLogDeleteNote, enqueueSnackbar, refetchData]);

  const NoteLabel = useMemo(() => {
    const { hasLink, text } = replaceLinksWithAnchorTags(noteValue);

    return (
      <Label
        variant="body"
        color="max-contrast-grey"
        style={{ whiteSpace: hasLink ? 'pre-wrap' : 'pre-line', wordBreak: 'break-word' }}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </Label>
    );
  }, [noteValue]);

  return (
    <>
      <ActivityLogBase
        activityLog={activityLog}
        actionLabel="added a comment"
        moreMenuProps={
          doesEmployeeHaveEditAccess
            ? {
                dataTestId: 'activity-log--note-more-menu',
                onSelect: onMenuOptionSelect,
                options: ActivityLogNoteMenuOptions,
              }
            : undefined
        }
      >
        <ActivityLogContent
          sx={{
            borderRadius: '4px 12px 12px 12px',
          }}
          content={
            <Stack direction="column" justifyContent="space-between" alignItems="flex-start">
              {NoteLabel}
              {noteData?.isEdited ? (
                <Label variant="captions" color="high-contrast-grey">
                  Edited
                </Label>
              ) : null}
            </Stack>
          }
        />
      </ActivityLogBase>
      {isEditNoteModalOpen && noteValue ? (
        <EditNoteModal
          onSubmit={onEditNoteModalSubmit}
          note={noteValue}
          onClose={onEditNoteModalClose}
          open={isEditNoteModalOpen}
        />
      ) : null}

      <DeleteNoteModal
        onSubmit={onDeleteNoteModalSubmit}
        onClose={onDeleteNoteModalClose}
        open={isDeleteNoteModalOpen}
      />
    </>
  );
};

ActivityLogNote.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogNote_activityLog on ActivityLog {
      id
      activityActorEmployeeId
      details {
        ... on ActivityLogNoteDetails {
          note
          isEdited
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogNote;

export const ActivityLogEditNoteMutation = gql`
  ${ActivityLogNote.fragments.activityLog}
  mutation ActivityLogEditNote($input: ActivityNoteEditInput!, $showTaskName: Boolean! = false) {
    activityNoteEdit(input: $input) {
      activityLog {
        ...ActivityLogNote_activityLog
      }
    }
  }
`;

export const ActivityLogDeleteNoteMutation = gql`
  mutation ActivityLogDeleteNote($input: ActivityNoteDeleteInput!) {
    activityNoteDelete(input: $input) {
      result
    }
  }
`;
