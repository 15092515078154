import React from 'react';

import BaseIcon, { Props } from './Icon';

const DownloadIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 4C10.75 3.58579 10.4142 3.25 10 3.25C9.58579 3.25 9.25 3.58579 9.25 4V12.1893L7.03033 9.96967C6.73744 9.67678 6.26256 9.67678 5.96967 9.96967C5.67678 10.2626 5.67678 10.7374 5.96967 11.0303L9.46967 14.5303C9.76256 14.8232 10.2374 14.8232 10.5303 14.5303L14.0303 11.0303C14.3232 10.7374 14.3232 10.2626 14.0303 9.96967C13.7374 9.67678 13.2626 9.67678 12.9697 9.96967L10.75 12.1893V4ZM4.75 14.5C4.75 14.0858 4.41421 13.75 4 13.75C3.58579 13.75 3.25 14.0858 3.25 14.5V15C3.25 16.5188 4.48122 17.75 6 17.75H14C15.5188 17.75 16.75 16.5188 16.75 15V14.5C16.75 14.0858 16.4142 13.75 16 13.75C15.5858 13.75 15.25 14.0858 15.25 14.5V15C15.25 15.6904 14.6904 16.25 14 16.25H6C5.30964 16.25 4.75 15.6904 4.75 15V14.5Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DownloadIcon;
