import { gql } from '@apollo/client';

import { Permission, useUserPermissionQuery } from 'src/generated/mloop-graphql';

export { Permission } from 'src/generated/mloop-graphql';

export const GQL_GET_HAS_USER_PERMISSIONS = gql`
  query UserPermission {
    thisUserInfo {
      permissions
    }
  }
`;

export function useHasUserPermission(permission: Permission): boolean {
  const { data, loading } = useUserPermissionQuery();
  const userPermissions = data?.thisUserInfo?.permissions;

  if (loading) {
    // fail closed, if we don't know, assume they don't have the permission
    return false;
  }

  return userPermissions?.includes(permission) ?? false;
}

export function useIsMissingUserPermission(permission: Permission): boolean {
  const hasPerm = useHasUserPermission(permission);
  return !hasPerm;
}
