import React from 'react';

import { Box, Button, Chip, Divider, Stack, Typography } from '@mui/material';

import { Permission } from 'src/generated/mloop-graphql';

import { useIsMissingUserPermission } from 'src/hooks/api/user/get';
import useHistory from 'src/hooks/useHistory';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import getTaskSettingsTabUrl from 'src/urls/getTaskSettingsTabUrl';

// eslint-disable-next-line modernloop/restrict-imports.cjs
import { TaskSettingsTabs } from 'src/views-new/Settings/types';

export const TaskQueueSelectZeroState = () => {
  const history = useHistory();
  const disabled = useIsMissingUserPermission(Permission.CanManageOrgSettings);
  const onAddQueue = () => {
    const redirectUrl = getTaskSettingsTabUrl(TaskSettingsTabs.TASK_QUEUE, true);
    history.push(redirectUrl);
  };

  return (
    <ConditionalThemeProvider>
      <Box mt={1}>
        <Stack rowGap={1}>
          <Divider />
          <Box p={1.5} bgcolor="info.tint" borderRadius="6px" position="relative">
            <Box textAlign="center">
              <Box
                sx={{
                  position: 'absolute',
                  top: 10,
                  left: 10,
                }}
              >
                <Chip label="New!" color="warning" size="small" variant="filled" />
              </Box>
              <Box pt={2}>
                <img width="64px" height="64px" src="/static/images/setup/queues.svg" alt="Checklist" />
              </Box>
            </Box>
            <Stack rowGap={2.5}>
              <Stack rowGap={1} textAlign="center">
                <Typography variant="h5" color="text.primary">
                  Coordinate with Queues
                </Typography>
                <Typography variant="body1" color="text.primary">
                  Use queues to distribute tasks to <br /> teams or groups.
                </Typography>
              </Stack>
              <Button variant="contained" color="primary" disabled={disabled} onClick={onAddQueue}>
                Add Queue
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </ConditionalThemeProvider>
  );
};
