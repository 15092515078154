import React, { useMemo } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { InfoIcon } from '@modernloop/shared/icons';
import { Box, Container, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { isUndefined } from 'lodash';

import {
  EmployeeByIdsQuery,
  NotificationPreferenceInput,
  SelfScheduleLocation,
  SelfScheduleOptionsSettingInput,
} from 'src/generated/mloop-graphql';

import useFullScreen from 'src/components/Dialog/useFullScreen';
import Paper from 'src/components/Paper';
import { MenuOption } from 'src/components/Select';
import useEqualSizeSxProps from 'src/components/utils/useEqualSizeSxProps';

import { ZoomUserDataWithCategory } from 'src/entities/Select/ZoomUserSelect/types';

import { useIntegrations } from 'src/hooks/api/integration';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import SelfSchedulePreferences, { SelfScheduleSelectedTemplates } from './SelfSchedulePreferences';
import TimeFrame, { NumberOfDays } from './TimeframePicker';

interface Props {
  title?: string;
  selectedDatesOptionsCount?: number;
  numberOfDays: NumberOfDays | undefined;
  alertJsx?: JSX.Element | boolean;
  optionsCalendarJsx?: JSX.Element;
  options: SelfScheduleOptionsSettingInput;
  templates: SelfScheduleSelectedTemplates;
  defaultTemplates: SelfScheduleSelectedTemplates;
  missingEmployeesData?: EmployeeByIdsQuery;
  employeeMissingZoomUserIds?: string[];
  onNoteChanged: (note: string) => void;
  onRollingDaysToggle?: (value: boolean) => void;
  onRollingDaysChange: (value: NumberOfDays) => void;
  onTemplatesChanged: (value: SelfScheduleSelectedTemplates) => void;
  onOptionsChanged: (value: SelfScheduleOptionsSettingInput) => void;
  handleCustomLinksInput: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleCustomLinksInputComplete: () => void;
  handleZoomUserChanged: (option: ZoomUserDataWithCategory) => void;
  hideRequestTemplateSelector?: boolean;
  showCandidateEventTemplateSelector?: boolean;
  showScheduleLocation?: boolean;
  shouldSendPersonalizedEmail?: boolean;
  setShouldSendPersonalizedEmail?: (val: boolean) => void;
  handleCcRecipients?: (ccs: NotificationPreferenceInput) => void;
  handleBccRecipients?: (bccs: NotificationPreferenceInput) => void;
  ccRecipients?: NotificationPreferenceInput | null;
  bccRecipients?: NotificationPreferenceInput | null;
}

const useStyles = makeStyles(() =>
  createStyles({
    zoomUserButton: {
      borderRadius: '6px',
      justifyContent: 'start',
    },
    zoomUserLabel: {
      display: 'flex',
      flexGrow: 1,
    },
  })
);

const LOCATION_OPTIONS: MenuOption[] = [
  { id: SelfScheduleLocation.Zoom, value: 'Zoom' },
  { id: SelfScheduleLocation.Google, value: 'Google Meet' },
  { id: SelfScheduleLocation.MicrosoftTeams, value: 'Microsoft Teams' },
  { id: SelfScheduleLocation.Phone, value: 'Phone call' },
  { id: SelfScheduleLocation.Custom, value: 'Custom link' },
  { id: SelfScheduleLocation.None, value: 'None' },
];

export function useLocationOptions() {
  const { data: integrations, isLoading: integrationsLoading } = useIntegrations();
  return useMemo(() => {
    return LOCATION_OPTIONS.filter((option) => {
      if (integrationsLoading) return false;

      if (option.id === SelfScheduleLocation.Google) return Boolean(integrations?.google?.admin_user_id);
      if (option.id === SelfScheduleLocation.MicrosoftTeams) return Boolean(integrations?.microsoft?.active);
      if (option.id === SelfScheduleLocation.Zoom) return Boolean(integrations?.zoom?.active);

      return true;
    });
  }, [integrations, integrationsLoading]);
}

export const ConfigurableSelfScheduleOptions = ({
  title,
  selectedDatesOptionsCount,
  numberOfDays,
  alertJsx,
  optionsCalendarJsx,
  options,
  templates,
  defaultTemplates,
  missingEmployeesData,
  employeeMissingZoomUserIds,
  onRollingDaysToggle,
  onRollingDaysChange,
  onTemplatesChanged,
  onOptionsChanged,
  handleCustomLinksInput,
  handleCustomLinksInputComplete,
  handleZoomUserChanged,
  hideRequestTemplateSelector,
  showCandidateEventTemplateSelector,
  showScheduleLocation,
  shouldSendPersonalizedEmail,
  setShouldSendPersonalizedEmail,
  handleCcRecipients,
  handleBccRecipients,
  ccRecipients,
  bccRecipients,
  onNoteChanged,
}: Props) => {
  const classes = useStyles();
  const equalSizeSxProps = useEqualSizeSxProps();
  const locationOptions = useLocationOptions();
  const isFullScreen = useFullScreen();

  return (
    <>
      <Stack
        direction="column"
        spacing={1}
        sx={{
          width: '100%',
        }}
      >
        <ConditionalThemeProvider>
          <Stack spacing={1} useFlexGap>
            <Typography fontWeight={600}>When do you want the interview to happen?</Typography>

            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
              spacing={1}
              sx={equalSizeSxProps.equalSizeChild}
            >
              <Typography>Timeframe</Typography>
              <TimeFrame timeFrame={numberOfDays || NumberOfDays.TwoWeeks} onChange={onRollingDaysChange} />
            </Stack>

            {onRollingDaysToggle && (
              <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} mb={1}>
                <FormControlLabel
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                  labelPlacement="start"
                  label="Use rolling window"
                  control={
                    <Switch
                      size="small"
                      checked={Boolean(options.rollingDays)}
                      onChange={(event) => {
                        onRollingDaysToggle(event.target.checked);
                      }}
                    />
                  }
                />
                <InfoIcon tooltip="Based on the selection above. Ensures the last selectable date is always that number of days out from when the candidate is viewing their options. Not available when selecting custom dates." />
              </Stack>
            )}
          </Stack>
        </ConditionalThemeProvider>

        {!isUndefined(selectedDatesOptionsCount) && (
          <>
            {!isFullScreen && optionsCalendarJsx && (
              <Container
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Paper>{optionsCalendarJsx}</Paper>
              </Container>
            )}
            {isFullScreen && optionsCalendarJsx && <Box sx={{ margin: '0 auto' }}>{optionsCalendarJsx}</Box>}
          </>
        )}
      </Stack>

      <SelfSchedulePreferences
        alertJsx={alertJsx}
        title={title}
        classes={classes}
        onOptionsChanged={onOptionsChanged}
        options={options}
        templates={templates}
        defaultTemplates={defaultTemplates}
        locationOptions={locationOptions}
        employeeMissingZoomUserIds={employeeMissingZoomUserIds}
        missingEmployeesData={missingEmployeesData}
        isPersonalizedEmailChecked={Boolean(shouldSendPersonalizedEmail)}
        onTemplatesChanged={onTemplatesChanged}
        handleCustomLinksInput={handleCustomLinksInput}
        handleCustomLinksInputComplete={handleCustomLinksInputComplete}
        handleZoomUserChanged={handleZoomUserChanged}
        handlePersonalizedEmailCheckboxChange={(checked) => {
          setShouldSendPersonalizedEmail?.(checked);
        }}
        hideRequestTemplateSelector={Boolean(hideRequestTemplateSelector)}
        showCandidateEventTemplateSelector={Boolean(showCandidateEventTemplateSelector)}
        showScheduleLocation={showScheduleLocation}
        handleCcRecipients={handleCcRecipients}
        handleBccRecipients={handleBccRecipients}
        ccRecipients={ccRecipients}
        bccRecipients={bccRecipients}
        onNoteChanged={onNoteChanged}
      />
    </>
  );
};
