import React, { useEffect, useState } from 'react';

import { Stack } from '@mui/material';
import { isNil } from 'lodash';

import {
  CandidateAvailabilityOptionsSettingInput,
  CandidateAvailabilityOptionsSettings,
  JobStageCommunicationSettings_JobStageSettingsFragment as JobStageSettingsFragment,
  SelfScheduleLocation,
  SelfScheduleOptionSettings,
  SelfScheduleOptionsSettingInput,
  SelfScheduleZoomHost,
  useSelfScheduleCalendarsQuery,
} from 'src/generated/mloop-graphql';

import AccordionCard from 'src/components/AccordionCard';

import { useIntegrations } from 'src/hooks/api/integration';

import { CandidateAvailabilityOptions } from 'src/store/slices/candidate-availability-options';

import { useRoundedMinsPerDay } from 'src/views-new/CandidateAvailabilityOptions/Options';
import { SelfScheduleSelectedTemplates } from 'src/views-new/SelfSchedule/SelfSchedulePreferences';
import { NumberOfDays } from 'src/views-new/SelfSchedule/TimeframePicker';
import { useSelfScheduleJobStageTemplates } from 'src/views-new/SelfSchedule/useSelfScheduleJobStageTemplates';

import { OrgCandidateAvailabilityOptions } from '../OrgCandidateAvailabilityOptions';
import { OrgSelfScheduleOptions, defaultScheduleOptionsState } from '../OrgSelfScheduleOptions';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface CandidateAvailabilityAndSelfSchedulePrefProps {
  jobStageId: string;
  availabilityTemplateId?: string;
  selfScheduleTemplates?: SelfScheduleSelectedTemplates;
  jobStageSettings: JobStageSettingsFragment | null;
  handleAvailabilityTemplateChanged: (templateId: string | undefined) => void;
  handleAvailabilityOptions: (options: CandidateAvailabilityOptionsSettingInput) => void;
  onSelfScheduleOptionsChange: (options: SelfScheduleOptionsSettingInput) => void;
  onSelfScheduleTemplateChanged: (selectedTemplates: SelfScheduleSelectedTemplates) => void;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
export function CandidateAvailabilityAndSelfSchedulePref({
  jobStageId,
  jobStageSettings,
  availabilityTemplateId,
  selfScheduleTemplates,
  handleAvailabilityTemplateChanged,
  handleAvailabilityOptions,
  onSelfScheduleOptionsChange,
  onSelfScheduleTemplateChanged,
}: CandidateAvailabilityAndSelfSchedulePrefProps) {
  const availabilityOptionsSettings = jobStageSettings?.candidateAvailabilityOptions;
  const scheduleOptionsSettings = jobStageSettings?.selfScheduleOptions;
  const [defaultScheduleOptions, setDefaultScheduleOptions] =
    useState<SelfScheduleOptionSettings>(defaultScheduleOptionsState);
  const roundedMinsPerDay = useRoundedMinsPerDay(availabilityOptionsSettings as CandidateAvailabilityOptions);
  const [selfScheduleOptionsTemplates, setselfScheduleOptionsTemplates] = useState<SelfScheduleSelectedTemplates>(
    selfScheduleTemplates || {}
  );
  const { templates: defaultSelfScheduleTemplates } = useSelfScheduleJobStageTemplates(jobStageId);
  const { data: integrations } = useIntegrations();
  const { data: calendars } = useSelfScheduleCalendarsQuery({
    variables: {
      input: {
        jobStageId,
      },
    },
    skip: !jobStageId,
  });

  const isObject = (obj: unknown) => {
    return obj === Object(obj);
  };

  const isEmptyArrayOrObject = (obj: unknown) => {
    if (obj === null || obj === undefined) return true;

    if (Array.isArray(obj)) return obj.length === 0;

    if (isObject(obj)) {
      return Object.keys(obj as object).length === 0;
    }

    return false;
  };

  const areAllFieldsNull = (obj, fieldsToSkip) => {
    return Object.entries(obj)
      .filter((entry) => !fieldsToSkip.includes(entry[0]))
      .every(
        (entry) =>
          entry[1] === null ||
          entry[1] === 'NONE' ||
          entry[1] === 'NOT_SET' ||
          entry[1] === '' ||
          isEmptyArrayOrObject(entry[1]) ||
          (isObject(entry[1]) && areAllFieldsNull(entry[1], fieldsToSkip))
      );
  };
  const shouldUseDefaultScheduleOptions =
    !scheduleOptionsSettings || areAllFieldsNull(scheduleOptionsSettings, ['__typename']);
  useEffect(() => {
    let ssOptions = defaultScheduleOptionsState as SelfScheduleOptionSettings;
    if (!ssOptions.location) {
      if (integrations?.zoom?.active) {
        ssOptions = {
          ...ssOptions,
          location: SelfScheduleLocation.Zoom,
          zoomHost: SelfScheduleZoomHost.Interviewer,
        };
      } else if (integrations?.google) {
        ssOptions = {
          ...ssOptions,
          location: SelfScheduleLocation.Google,
        };
      } else if (integrations?.microsoft?.active) {
        ssOptions = {
          ...ssOptions,
          location: SelfScheduleLocation.MicrosoftTeams,
        };
      }
    }
    if (!ssOptions.interviewerCalendarId) {
      ssOptions.interviewerCalendarId = calendars?.initScheduleFlow?.interviewerCalendar?.remoteId || '';
    }
    if (!ssOptions.candidateCalendarId) {
      ssOptions.candidateCalendarId = calendars?.initScheduleFlow?.candidateCalendar?.remoteId || '';
    }
    setDefaultScheduleOptions(ssOptions);
    const templateOptions = {
      ...selfScheduleTemplates,
    };
    const defaultTemplates = defaultSelfScheduleTemplates as SelfScheduleSelectedTemplates;
    if (!templateOptions.interviewerEventTemplateId && !!defaultTemplates.interviewerEventTemplateId) {
      templateOptions.interviewerEventTemplateId = defaultTemplates.interviewerEventTemplateId;
    }
    setselfScheduleOptionsTemplates(templateOptions);
  }, [calendars, integrations, selfScheduleTemplates, defaultSelfScheduleTemplates]);

  const addPlaceholderOptions = (
    availabilityOption: CandidateAvailabilityOptionsSettings
  ): CandidateAvailabilityOptionsSettings => {
    const options = { ...availabilityOption };
    if (!options.numberOfDays) {
      options.numberOfDays = -2;
    }
    if (!options.minutesPerDays) {
      options.minutesPerDays = -1;
    }
    if (!options.minimumTimeBlockMinutes) {
      options.minimumTimeBlockMinutes = -1;
    }
    if (isNil(options.advanceNoticeMinutes)) {
      options.advanceNoticeMinutes = 24 * 60;
    }

    if (isNil(options.timeframeNumberOfDays)) {
      options.timeframeNumberOfDays = NumberOfDays.TwoWeeks;
    }

    if (isNil(options.useRollingDays)) {
      options.useRollingDays = false;
    }

    if (isNil(options.shouldRespectLoadLimit)) {
      options.shouldRespectLoadLimit = true;
    }

    return options;
  };

  return (
    <Stack direction="column" spacing={1}>
      <AccordionCard title="Availability requests">
        <OrgCandidateAvailabilityOptions
          candidateAvailabilityOptions={addPlaceholderOptions(
            availabilityOptionsSettings as CandidateAvailabilityOptionsSettings
          )}
          roundedMinsPerDay={roundedMinsPerDay}
          availabilityTemplateId={availabilityTemplateId}
          onAvailabilityRequestTemplateChange={(availabilityRequestEmailTemplateId) =>
            handleAvailabilityTemplateChanged(availabilityRequestEmailTemplateId)
          }
          onOptionsChanged={(candidateAvailabilityOptions) => {
            handleAvailabilityOptions(candidateAvailabilityOptions);
          }}
        />
      </AccordionCard>
      <AccordionCard title="Self-scheduling">
        <OrgSelfScheduleOptions
          jobStageId={jobStageId}
          selfScheduleOptions={
            shouldUseDefaultScheduleOptions
              ? defaultScheduleOptions
              : ({
                  ...scheduleOptionsSettings,
                  ccRecipients: {
                    ...scheduleOptionsSettings?.ccRecipients,
                  },
                  bccRecipients: {
                    ...scheduleOptionsSettings?.bccRecipients,
                  },
                } as SelfScheduleOptionSettings)
          }
          onTemplatesChanged={(template) => {
            onSelfScheduleTemplateChanged(template);
          }}
          onOptionsChanged={(selfScheduleOptions) => {
            onSelfScheduleOptionsChange(selfScheduleOptions);
          }}
          templates={selfScheduleOptionsTemplates}
        />
      </AccordionCard>
    </Stack>
  );
}
