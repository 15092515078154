import React, { useMemo } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { noop } from 'lodash';

import Paper from 'src/components/Paper';
import Button from 'src/components/button';
import Label from 'src/components/label';

import { submitDebriefSetupStep } from 'src/store/actions/debrief';
import { getShouldRefreshOptions } from 'src/store/selectors/scheduling';

import { Theme } from 'src/theme/type';

import { Theme as ThemeV5 } from 'src/themeMui5/type';

import useSubmitInterviewPlan from 'src/views-new/InterviewPlan/useSubmitInterviewPlan';
import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { useDispatch, useSelector } from 'src/store';

import { ScheduleFlowType } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.info.contrastText,
    },
    refresh: {
      color: theme.palette.info.contrastText,
      float: 'right',
      fontWeight: 500,
    },
  })
);

const useSxProps = () => {
  return useMemo(() => {
    return {
      paper: {
        position: 'fixed',
        bottom: (theme: ThemeV5) => theme.spacing(1),
        left: (theme: ThemeV5) => theme.spacing(1),
        backgroundColor: (theme: ThemeV5) => theme.grey.solid.max,
        pointerEvents: 'auto',
        width: '240px',
        zIndex: 3,
      },
    };
  }, []);
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleRefreshOptionProps = {
  scheduleFlowType: ScheduleFlowType;
};

const ScheduleRefreshOption = ({ scheduleFlowType }: ScheduleRefreshOptionProps): JSX.Element | null => {
  const classes = useStyles();
  const sxProps = useSxProps();
  const dispatch = useDispatch();
  const shouldRefreshOptions = useSelector(getShouldRefreshOptions);

  const scheduleFlowData = useScheduleFlowData();

  const { applicationId, interviewPlanJobStageId } = scheduleFlowData;

  const submitInterviewPlan = useSubmitInterviewPlan(interviewPlanJobStageId, false /* increment */);

  if (!shouldRefreshOptions) return null;

  const handleRefresh = () => {
    if (scheduleFlowType === ScheduleFlowType.DEBRIEF && applicationId) {
      dispatch(submitDebriefSetupStep(applicationId, noop));
      return;
    }
    submitInterviewPlan({});
  };

  return (
    <Paper sx={sxProps.paper}>
      <Grid container direction="column">
        <Grid item>
          <Label className={classes.label}>Scheduling preferences updated.</Label>
        </Grid>
        <Grid item>
          <Button
            variant="unstyled"
            color="default"
            label="Refresh"
            className={classes.refresh}
            onClick={handleRefresh}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ScheduleRefreshOption;
