import { SvgIcon, SvgIconProps } from './SvgIcon';

export const LinkIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87789 5.96253C9.585 6.25542 9.585 6.73029 9.87789 7.02319C10.1708 7.31608 10.6457 7.31608 10.9386 7.02319L12.8675 5.09424C13.6242 4.33757 14.6774 4.29204 15.3004 4.89879C15.3806 4.97681 15.4598 5.05603 15.5378 5.13615C16.1446 5.75919 16.099 6.81243 15.3423 7.56911L12.2909 10.6205C11.5343 11.3772 10.481 11.4227 9.85798 10.816C9.77786 10.7379 9.69864 10.6587 9.62062 10.5786C9.56811 10.5247 9.52142 10.4692 9.48008 10.4126C9.23575 10.0781 8.76654 10.0051 8.43206 10.2494C8.09758 10.4937 8.0245 10.963 8.26883 11.2974C8.35222 11.4116 8.44464 11.521 8.546 11.6251C8.63327 11.7147 8.72185 11.8033 8.81146 11.8906C10.1608 13.2047 12.1614 12.8713 13.3516 11.6812L16.403 8.62977C17.5931 7.43963 17.9265 5.439 16.6124 4.08963C16.5251 4.00002 16.4366 3.91144 16.347 3.82418C14.9976 2.51008 12.997 2.84345 11.8068 4.03358L9.87789 5.96253ZM8.56633 16.4664L10.585 14.4478C10.8779 14.1549 10.8779 13.68 10.585 13.3871C10.2921 13.0942 9.81719 13.0942 9.5243 13.3871L7.50567 15.4057C6.749 16.1624 5.69578 16.2079 5.07274 15.6012C4.99262 15.5232 4.9134 15.444 4.83538 15.3638C4.22862 14.7408 4.27416 13.6875 5.03084 12.9309L8.08225 9.87947C8.83892 9.1228 9.89215 9.07726 10.5152 9.68401C10.5953 9.76204 10.6745 9.84126 10.7526 9.92138C10.8051 9.97529 10.8517 10.0308 10.8931 10.0874C11.1374 10.4218 11.6066 10.4949 11.9411 10.2506C12.2756 10.0062 12.3487 9.53703 12.1043 9.20255C12.0209 9.0884 11.9285 8.97894 11.8272 8.87486C11.7399 8.78525 11.6513 8.69666 11.5617 8.6094C10.2123 7.2953 8.21172 7.62868 7.02159 8.81881L3.97018 11.8702C2.78004 13.0604 2.44667 15.061 3.76077 16.4104C3.84803 16.5 3.93661 16.5885 4.02622 16.6758C5.37559 17.9899 7.3762 17.6565 8.56633 16.4664Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
