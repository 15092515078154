import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Gets query string param by name from location.
 *
 * Returns the value associated to the query param.
 *
 * Examples:
 * ?foo=123 => useQueryStringParam('foo') => '123'
 * ?foo => useQueryStringParam('foo') => ''
 *
 */
export function useQueryStringParam(key: string): string | null {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search).get(key), [key, search]);
}
