/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Box, Skeleton } from '@mui/material';

import {
  CandidateAvailabilityOptionsSettingInput,
  DynamicAudience,
  EmployeeFragment,
  JobStageCommunicationSettings_JobStageSettingsFragment as JobStageSettingsFragment,
  NotificationPreferenceInput,
  SelfScheduleOptionsSettingInput,
  TemplateType,
} from 'src/generated/mloop-graphql';

import AccordionCard from 'src/components/AccordionCard';
import Divider from 'src/components/Divider';
import Stack from 'src/components/Stack';
import { SyncedFromOrgSettings } from 'src/components/SyncedFromOrgSettings';
import Button from 'src/components/button';
import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';

import { OrgSettingsSource } from 'src/constants/routes';

import EmployeePickerExtended from 'src/entities/EmployeePicker/EmployeePickerExtended';
import SmartEmployeeSelect from 'src/entities/Select/SmartEmployeeSelect';
import TemplateSelect from 'src/entities/Template/TemplateSelect';

import { useEmployeePref } from 'src/hooks/api/employee/get';
import { useIntegrations } from 'src/hooks/api/integration';
import { OrgPrefName, useOrgPref } from 'src/hooks/api/org';
import useEmployeeId from 'src/hooks/useEmployeeId';

import { Theme as ThemeV5 } from 'src/themeMui5/type';

import { EmployeePrefName } from 'src/utils/api/employee';

import CalendarSelect from 'src/views-new/ScheduleFlow/Steps/Communications/CalendarCard/CalendarSelect';
import PersonalCalendarAlert from 'src/views-new/ScheduleFlow/Steps/Communications/CalendarCard/PersonalCalendarAlert';
import { SelfScheduleSelectedTemplates } from 'src/views-new/SelfSchedule/SelfSchedulePreferences';

import { CandidateAvailabilityAndSelfSchedulePref } from './CandidateAvailabilityAndSelfSchedulePref';
import { EventVisibilitySection } from './EventVisibilitySection';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface JobStageCommunicationSettingsProps {
  jobStageId: string;
  setIsPrivateEvent: (isPrivateEvent: boolean) => void;
  isPrivateEvent: boolean | null;
  handleCandidateEmailTemplateSelect: (templateId: string | undefined) => void;
  handleSlackTemplateSelect: (templateId: string | undefined) => void;
  handleCandidateEventTemplateSelect: (templateId: string | undefined) => void;
  handleCandidateCalendarSelect: (calendarId: string | null) => void;
  handleCcRecipients: (recipients: NotificationPreferenceInput) => void;
  handleBccRecipients: (recipients: NotificationPreferenceInput) => void;
  handleAvailabilityOptions: (options: CandidateAvailabilityOptionsSettingInput) => void;
  handleSelfScheduleOptions: (options: SelfScheduleOptionsSettingInput) => void;
  onSelfScheduleTemplateChanged: (selectedTemplates: SelfScheduleSelectedTemplates) => void;
  handleAvailabilityTemplateChanged: (templateId: string | undefined) => void;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface JobStageCommunicationSettingsFragmentProps {
  jobStageSettings: JobStageSettingsFragment | null;
}

const useCandidateCalendarId = () => {
  const employeeId = useEmployeeId();
  const [orgCandidateCalendarId] = useOrgPref(OrgPrefName.STR_DEFAULT_CANDIDATE_CALENDAR);
  const [empCandidateCalendarId] = useEmployeePref(
    EmployeePrefName.STR_DEFAULT_CANDIDATE_CALENDAR,
    employeeId as string
  );

  return [empCandidateCalendarId?.stringValue, orgCandidateCalendarId?.stringValue];
};

const JobStageCommunicationSettings: FCWithFragments<
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/restric-fragments-name.cjs
  JobStageCommunicationSettingsFragmentProps,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/restric-fragments-name.cjs
  JobStageCommunicationSettingsProps
> = ({
  jobStageId,
  setIsPrivateEvent,
  isPrivateEvent,
  jobStageSettings,
  handleCandidateEmailTemplateSelect,
  handleSlackTemplateSelect,
  handleCandidateEventTemplateSelect,
  handleCandidateCalendarSelect,
  handleCcRecipients,
  handleBccRecipients,
  handleAvailabilityOptions,
  handleAvailabilityTemplateChanged,
  handleSelfScheduleOptions,
  onSelfScheduleTemplateChanged,
}) => {
  const { data: integrations, isLoading: integrationsLoading } = useIntegrations();
  const isSlackActive = integrations?.slack && integrations.slack.active;
  const [showBcc, setShowBcc] = useState(false);
  const smartOptionsEnabled = useFlag('user_employee_smart_options');

  const { jobID } = useParams<{ jobID: string }>();

  const [empCandidateCalendarId, orgCandidateCalendarId] = useCandidateCalendarId();
  const [defaultCandidateCalendarId, setDefaultCandidateCalendarId] = useState('');
  const [defaultCalendarLabel, setDefaultCalendarLabel] = useState('');

  useEffect(() => {
    if (empCandidateCalendarId) {
      setDefaultCandidateCalendarId(empCandidateCalendarId);
      setDefaultCalendarLabel('Personal default');
    } else if (orgCandidateCalendarId) {
      setDefaultCandidateCalendarId(orgCandidateCalendarId);
      setDefaultCalendarLabel('Org default');
    }
  }, [empCandidateCalendarId, orgCandidateCalendarId]);

  useEffect(() => {
    if (
      jobStageSettings?.bccRecipients?.employeeIds?.length ||
      jobStageSettings?.bccRecipients?.externalEmailAddresses?.length ||
      jobStageSettings?.bccRecipients?.dynamicAudiences?.length
    ) {
      setShowBcc(true);
    } else {
      setShowBcc(false);
    }
  }, [jobStageSettings]);

  if (integrationsLoading) {
    return <Skeleton height="300px" width="100%" />;
  }

  const templateSelectItemStyles = {
    0: { flexBasis: '200px' },
    1: { flexGrow: 1, flexBasis: '250px', maxWidth: '480px' },
  };

  return (
    <Stack direction="column" spacing={1}>
      <AccordionCard title="Candidate communications" subTitle="Confirmation email, calendar invite">
        <Stack spacing={2} direction="column">
          <Stack spacing={1} style={{ alignItems: 'center' }}>
            <Label variant="body" fontWeight={600}>
              Email
            </Label>
          </Stack>

          <Stack style={{ flexWrap: 'nowrap' }} itemStyles={templateSelectItemStyles}>
            <Stack spacing={1} style={{ alignItems: 'center' }}>
              <Label>Email content</Label>
            </Stack>
            <Box>
              <TemplateSelect
                showOrgDefaultOption
                useSelectButton
                selectedTemplateId={jobStageSettings?.candidateEmailTemplateId}
                onSelect={(value) => handleCandidateEmailTemplateSelect(value)}
                types={[TemplateType.CandidateConfirmation]}
              />
              {jobStageSettings?.candidateEmailTemplateId === null && (
                <SyncedFromOrgSettings source={OrgSettingsSource.TEMPLATES} />
              )}
            </Box>
          </Stack>
          <Divider />
          <Stack style={{ flexWrap: 'nowrap' }} itemStyles={templateSelectItemStyles}>
            <Stack spacing={1} style={{ alignItems: 'center' }}>
              <Label>Additional recipients</Label>
            </Stack>
            <Stack direction="column" spacing={1}>
              {smartOptionsEnabled && (
                <SmartEmployeeSelect
                  label="CC"
                  placeholder="Add names or emails"
                  context={{
                    task: true,
                    application: true,
                    job: jobID,
                    enableOptionsWithMissingData: true,
                  }}
                  filterDynamicAudiences={[DynamicAudience.DebriefAttendees]}
                  selectedFreeformEmails={jobStageSettings?.ccRecipients?.externalEmailAddresses || []}
                  selectedEmployeeIds={jobStageSettings?.ccRecipients?.employeeIds || []}
                  selectedDynamicAudiences={jobStageSettings?.ccRecipients?.dynamicAudiences || []}
                  onChange={({ dynamicAudiences, employees, freeformEmails }) => {
                    const ids = employees.map((e) => e.id);
                    handleCcRecipients({
                      employeeIds: ids,
                      externalEmailAddresses: freeformEmails,
                      dynamicAudiences,
                    });
                  }}
                />
              )}
              {!smartOptionsEnabled && (
                <EmployeePickerExtended
                  label="CC"
                  placeholder="Add names or emails"
                  dynamicAudiencesEnabled={false}
                  freeformEnabled
                  freeformEmails={jobStageSettings?.ccRecipients?.externalEmailAddresses || []}
                  selectedEmployeeIds={jobStageSettings?.ccRecipients?.employeeIds || []}
                  onChange={(selectedEmployees: EmployeeFragment[], freeformEmails: string[]) => {
                    const ids = selectedEmployees.map((e) => e.id);
                    handleCcRecipients({
                      employeeIds: ids,
                      externalEmailAddresses: freeformEmails,
                    });
                  }}
                />
              )}
              {!showBcc && (
                <Button variant="link" onClick={() => setShowBcc(!showBcc)}>
                  Add BCC
                </Button>
              )}

              {smartOptionsEnabled && showBcc && (
                <SmartEmployeeSelect
                  label="BCC"
                  placeholder="Add names or emails"
                  context={{
                    task: true,
                    application: true,
                    job: jobID,
                    enableOptionsWithMissingData: true,
                  }}
                  filterDynamicAudiences={[DynamicAudience.DebriefAttendees]}
                  onChange={({ employees, freeformEmails, dynamicAudiences }) => {
                    const ids = employees.map((e) => e.id);
                    handleBccRecipients({
                      employeeIds: ids,
                      externalEmailAddresses: freeformEmails,
                      dynamicAudiences,
                    });
                  }}
                  selectedFreeformEmails={jobStageSettings?.bccRecipients?.externalEmailAddresses || []}
                  selectedEmployeeIds={jobStageSettings?.bccRecipients?.employeeIds || []}
                  selectedDynamicAudiences={jobStageSettings?.bccRecipients?.dynamicAudiences || []}
                />
              )}
              {!smartOptionsEnabled && showBcc && (
                <Stack itemStyles={{ 0: { flexGrow: 1, flexBasis: '250px', maxWidth: '480px' } }}>
                  <EmployeePickerExtended
                    label="BCC"
                    dynamicAudiencesEnabled={false}
                    placeholder="Add names or emails"
                    freeformEnabled
                    freeformEmails={jobStageSettings?.bccRecipients?.externalEmailAddresses || []}
                    selectedEmployeeIds={jobStageSettings?.bccRecipients?.employeeIds || []}
                    onChange={(selectedEmployees: EmployeeFragment[], freeformEmails: string[]) => {
                      const ids = selectedEmployees.map((e) => e.id);
                      handleBccRecipients({
                        employeeIds: ids,
                        externalEmailAddresses: freeformEmails,
                      });
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing={1} style={{ alignItems: 'center' }}>
            <Label variant="body" fontWeight={600}>
              Calendar event
            </Label>
          </Stack>

          <Stack style={{ flexWrap: 'nowrap' }} itemStyles={templateSelectItemStyles}>
            <Stack spacing={1} style={{ alignItems: 'center' }}>
              <Label>Add to calendar</Label>
            </Stack>
            <Stack direction="column" spacing={1}>
              <CalendarSelect
                resetUnknownCalendarsOnLoad={false}
                fullWidth
                onChange={(id) => {
                  handleCandidateCalendarSelect(id);
                }}
                calendarId={jobStageSettings?.candidateCalendarId || defaultCandidateCalendarId}
                useTooltip
                defaultCalendarLabel={
                  !jobStageSettings?.candidateCalendarId && defaultCandidateCalendarId ? defaultCalendarLabel : ''
                }
              />
              <PersonalCalendarAlert
                sx={{ marginTop: (theme: ThemeV5) => theme.spacing(1) }}
                remoteCalendarId={jobStageSettings?.candidateCalendarId || defaultCandidateCalendarId || ''}
              />
            </Stack>
          </Stack>

          <Divider />

          <Stack style={{ flexWrap: 'nowrap' }} itemStyles={templateSelectItemStyles}>
            <Stack spacing={1} style={{ alignItems: 'center' }}>
              <Label>Event visibility</Label>
              <Label variant="captions" color="high-contrast-grey">
                Applies to both internal and candidate events
              </Label>
            </Stack>
            <EventVisibilitySection isPrivateEvent={isPrivateEvent} setIsPrivateEvent={setIsPrivateEvent} />
          </Stack>
          <Divider />
          <Stack style={{ flexWrap: 'nowrap' }} itemStyles={templateSelectItemStyles}>
            <Stack spacing={1} style={{ alignItems: 'center' }}>
              <Label>Event description</Label>
            </Stack>
            <Stack direction="column">
              <TemplateSelect
                showOrgDefaultOption
                useSelectButton
                selectedTemplateId={jobStageSettings?.candidateEventTemplateId}
                onSelect={(value) => handleCandidateEventTemplateSelect(value)}
                types={[TemplateType.CandidateInvite]}
              />
              {jobStageSettings?.candidateEventTemplateId === null && (
                <SyncedFromOrgSettings source={OrgSettingsSource.TEMPLATES} />
              )}
            </Stack>
          </Stack>
        </Stack>
      </AccordionCard>

      <CandidateAvailabilityAndSelfSchedulePref
        jobStageId={jobStageId}
        jobStageSettings={jobStageSettings}
        availabilityTemplateId={jobStageSettings?.availabilityRequestEmailTemplateId}
        selfScheduleTemplates={{
          candidateEmailTemplateId: jobStageSettings?.selfScheduleCandidateEmailTemplateId,
          interviewerEventTemplateId: jobStageSettings?.selfScheduleInterviewerEventTemplateId,
          requestEmailTemplateId: jobStageSettings?.selfScheduleRequestEmailTemplateId,
        }}
        handleAvailabilityTemplateChanged={(templateId) => handleAvailabilityTemplateChanged(templateId)}
        handleAvailabilityOptions={(options) => handleAvailabilityOptions(options)}
        onSelfScheduleOptionsChange={(options) => handleSelfScheduleOptions(options)}
        onSelfScheduleTemplateChanged={(templates) => onSelfScheduleTemplateChanged(templates)}
      />
      {isSlackActive && (
        <AccordionCard title="Use Slack for this stage" subTitle="Use Slack for new schedules, updates">
          <Stack style={{ flexWrap: 'nowrap' }} itemStyles={templateSelectItemStyles}>
            <Label>Template</Label>
            <Box>
              <TemplateSelect
                showOrgDefaultOption
                useSelectButton
                selectedTemplateId={jobStageSettings?.slackTemplateId}
                onSelect={(value) => handleSlackTemplateSelect(value)}
                types={[TemplateType.SlackChannelMessage]}
              />
              {jobStageSettings?.slackTemplateId === null && (
                <SyncedFromOrgSettings source={OrgSettingsSource.TEMPLATES} />
              )}
            </Box>
          </Stack>
        </AccordionCard>
      )}
    </Stack>
  );
};

export const candidateAvailabilityOptionsFragment = gql`
  fragment CandidateAvailabilityOptions_jobStageSettings on JobStageSettings {
    candidateAvailabilityOptions {
      numberOfDays
      minutesPerDays
      minimumTimeBlockMinutes
      advanceNoticeMinutes
      candidateNote
      ccRecipients {
        employeeIds
        dynamicAudiences
        externalEmailAddresses
      }
      bccRecipients {
        employeeIds
        dynamicAudiences
        externalEmailAddresses
      }
      shouldRespectLoadLimit
      canScheduleOverRecruitingKeywords
      canScheduleOverAvailableKeywords
      canScheduleOverFreeTime
      useRollingDays
      timeframeNumberOfDays
    }
  }
`;

export const selfScheduleOptionsFragment = gql`
  fragment SelfScheduleOptions_jobStageSettings on JobStageSettings {
    selfScheduleOptions {
      numberOfDays
      advanceNoticeInHours
      rollingDays
      candidateCalendarId
      interviewerCalendarId
      location
      zoomHost
      isPrivateCalendarEvent
      zoomHostUserId
      customLocation
      shouldRespectLoadLimit
      canScheduleOverRecruitingKeywords
      canScheduleOverAvailableKeywords
      canScheduleOverFreeTime
      candidateNote
      ccRecipients {
        dynamicAudiences
        employeeIds
        externalEmailAddresses
      }
      bccRecipients {
        dynamicAudiences
        employeeIds
        externalEmailAddresses
      }
      useRollingDays
      meetingHost
      videoMeetingHostEmployeeId
    }
  }
`;

JobStageCommunicationSettings.fragments = {
  jobStageSettings: gql`
    ${candidateAvailabilityOptionsFragment}
    ${selfScheduleOptionsFragment}
    fragment JobStageCommunicationSettings_jobStageSettings on JobStageSettings {
      id
      jobStageId
      candidateEmailTemplateId
      candidateEventTemplateId
      candidateCalendarId
      slackTemplateId
      ccRecipients {
        employeeIds
        dynamicAudiences
        externalEmailAddresses
      }
      bccRecipients {
        employeeIds
        dynamicAudiences
        externalEmailAddresses
      }
      ...CandidateAvailabilityOptions_jobStageSettings
      ...SelfScheduleOptions_jobStageSettings
      selfScheduleRequestEmailTemplateId
      selfScheduleCandidateEmailTemplateId
      selfScheduleInterviewerEventTemplateId
      availabilityRequestEmailTemplateId
    }
  `,
};

export default JobStageCommunicationSettings;
