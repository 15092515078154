/* eslint-disable import/prefer-default-export */
import { State } from 'src/store';

export const getHasBreaksByJobStageId = (state: State, jobStageId: string): boolean =>
  state.jobStagePlanConfig.byId[jobStageId]?.hasBreaks;

export const getHasMultiDayByJobStageId = (state: State, jobStageId: string): boolean =>
  state.jobStagePlanConfig.byId[jobStageId]?.hasMultiDay;

export const getNumberOfDaysByJobStageId = (state: State, jobStageId: string): number =>
  state.jobStagePlanConfig.byId[jobStageId]?.numberOfDays;

export const getShouldRememberByJobStageId = (state: State, jobStageId: string): boolean =>
  state.jobStagePlanConfig.byId[jobStageId]?.shouldRemember;

export const getExcludeInterviewerIdsByJobStageId = (state: State, jobStageId: string): string[] | undefined =>
  state.jobStagePlanConfig.byId[jobStageId]?.excludeInterviewerIds;
