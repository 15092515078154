import React from 'react';

import BaseIcon, { Props } from './Icon';

const ScorecardIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 6.5V15C15.25 15.6904 14.6904 16.25 14 16.25H6.00003C5.30967 16.25 4.75003 15.6904 4.75003 15V6.5L15.25 6.5ZM10 1.25C8.46321 1.25 7.17555 2.31665 6.83697 3.75H6.00003C4.48125 3.75 3.25003 4.98121 3.25003 6.5V15C3.25003 16.5188 4.48125 17.75 6.00003 17.75H14C15.5188 17.75 16.75 16.5188 16.75 15V6.5C16.75 4.98121 15.5188 3.75 14 3.75H13.163C12.8245 2.31665 11.5368 1.25 10 1.25ZM6.25 9C6.25 8.58578 6.58579 8.25 7 8.25H13C13.4142 8.25 13.75 8.58578 13.75 9C13.75 9.41421 13.4142 9.75 13 9.75H7C6.58579 9.75 6.25 9.41421 6.25 9ZM6.25 12C6.25 11.5858 6.58579 11.25 7 11.25H9C9.41421 11.25 9.75 11.5858 9.75 12C9.75 12.4142 9.41421 12.75 9 12.75H7C6.58579 12.75 6.25 12.4142 6.25 12ZM11 4C11 4.55228 10.5523 5 10 5C9.44772 5 9 4.55228 9 4C9 3.44771 9.44772 3 10 3C10.5523 3 11 3.44771 11 4Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ScorecardIcon;
