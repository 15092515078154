import { RenderType, TemplateToken } from 'src/generated/mloop-graphql';

import { CodingUrlBySlotId } from 'src/store/slices/schedule-communications';

import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';

import { MeetingType } from './input';

/**
 * TOKENS TO DISPLAY
 * Note Only add if eligible for display
 * */
export enum Tokens {
  VIDEO_MEETING_LINK = 'VIDEO_MEETING_LINK',
  INTERVIEW_DAYS = 'INTERVIEW_DAYS',
  CODE_LINK = 'CODE_LINK',
  INTERVIEWER_NAMES = 'INTERVIEWER_NAMES',
  INTERVIEW_KIT_LINK = 'INTERVIEW_KIT_LINK',
  INTERVIEW_NAME = 'INTERVIEW_NAME',
  INTERVIEW_SCHEDULE = 'INTERVIEW_SCHEDULE',
  INTERVIEW_SCHEDULE_START_TIME = 'INTERVIEW_SCHEDULE_START_TIME',
  INTERNAL_INTERVIEW_SCHEDULE = 'INTERNAL_INTERVIEW_SCHEDULE',
  INTERVIEW_SCORECARD_NAME = 'INTERVIEW_SCORECARD_NAME',
  ZOOM_MEETING_INFO = 'ZOOM_MEETING_INFO',
  ZOOM_MEETING_DIAL_IN_INFO = 'ZOOM_MEETING_DIAL_IN_INFO',
}
/**
 * Represents the input object for the InterviewPlaceholderFiller utility function.
 */
export type Input = {
  /**
   * The type of rendering to use.
   */
  renderType?: RenderType;
  /**
   * The ID of the application associated with the interview.
   */
  applicationId: string;
  /**
   * The ID of the candidate associated with the interview.
   */
  candidateId: string;
  /**
   * The schedule for the interview.
   */
  schedule: InterviewSchedule;
  /**
   * The timezone to use for the interview.
   */
  timezone?: string;
  /**
   * The coding URLs for the interview, organized by interview event.
   */
  codingUrlByInterviewEvent?: CodingUrlBySlotId;
  /**
   * Whether to fetch the meeting link.
   */
  isFetch?: boolean;
  /**
   * Whether to skip the meeting link if a schedule is present.
   */
  skipMeetingLinkWithSchedule?: boolean;
  /**
   * The ID of the slot associated with the interview.
   */
  slotId?: string;
  /**
   * The timezone of the candidate.
   */
  candidateTimezone?: string; // Expliclity pass for INTERVIEW_SCHEDULE  token to be computed in candidate timezone
} & MeetingType;

/**
 * An object representing empty merged options for an interview placeholder filler.
 * @property {string} INTERVIEWER_TRAINING_INFO - An empty string representing interviewer training information.
 */
export const EMPTY_MERGED_OPTIONS = {
  INTERVIEWER_TRAINING_INFO: '',
};

/**
 * Enum containing non-display tokens used by the InterviewPlaceholderFiller.
 */
export enum NonDisplayTokens {
  INTERVIEWER_TRAINING_INFO = 'INTERVIEWER_TRAINING_INFO',
}
/**
 * Options for filling interview placeholders in a template.
 */
export type InterviewPlaceholderFillerOptions =
  // Pick only the keys from TemplateToken that correspond to display tokens
  Pick<TemplateToken, keyof Pick<TemplateToken, Tokens>> &
    // Pick only the keys from TemplateToken that correspond to non-display tokens
    Pick<TemplateToken, keyof Pick<TemplateToken, NonDisplayTokens>> & {
      /**
       * The names of the interviewers to schedule.
       */
      SCHEDULE_INTERVIEWER_NAMES?: string | undefined;
    };
