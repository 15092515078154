/* eslint-disable max-lines */
import React, { useEffect, useMemo } from 'react';
import type { FC } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@material-ui/core/styles';
import { FCWithFragments } from '@modernloop/shared/components';
import { EyeCrossedIcon } from '@modernloop/shared/icons';
import { LinearProgress, Stack as MuiStack, Theme as MuiTheme, Typography } from '@mui/material';
import { parseISO } from 'date-fns';

import { CodeLinkType, InterviewerContentCard_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import Divider from 'src/components/Divider';
import Editor from 'src/components/Editor';
import Link from 'src/components/Link';
import Paper from 'src/components/Paper';
import Stack from 'src/components/Stack';
import Tag from 'src/components/Tag';
import TextField from 'src/components/TextField';
import { MeetingRoomIcon, SuccessIcon, VideoIcon } from 'src/components/icons';
import Label from 'src/components/label';

import TemplateType from 'src/constants/TemplateType';

import TemplateSelect from 'src/entities/Template/TemplateSelect';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { addUpdateFlowInterviewExpanded, updateInterviewerTemplateID } from 'src/slices/scheduling';

import {
  updateInterviewerContentDescription,
  updateInterviewerContentSummary,
} from 'src/store/actions/schedule-communications';
import { getAllCodingUrls } from 'src/store/selectors/schedule-communications';
import { getScheduleById } from 'src/store/selectors/schedules';
import { getEventOldContent, getInterviewerEventContents } from 'src/store/selectors/scheduling';
import {
  SchdeuleUpdateInterviewName,
  SchdeuleUpdateInterviewTime,
  ScheduleUpdateAddInterviewMeetingRoom,
  ScheduleUpdateCodingUrl,
  ScheduleUpdateDeleteInterviewMeetingRoom,
  ScheduleUpdateEditInterview,
  ScheduleUpdateInterviewHiddenFromCandidate,
  ScheduleUpdateInterviewSeat,
  ScheduleUpdateInterviewerOptional,
  ScheduleUpdateNewInterview,
  ScheduleUpdateScorecard,
  ScheduleUpdateVideoUrl,
} from 'src/store/slices/schedule-update';

import { Theme } from 'src/theme/type';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { RichInterviewer } from 'src/utils/api/getScheduleOptions';
import groupBy from 'src/utils/groupby';
import { renderTimeRangeWithDayMthHrMinTz } from 'src/utils/renderTimeRange';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';
import { iconMap } from 'src/views-new/ScheduleFlow/Steps/Communications/CodingCard';
import InterviewerRow from 'src/views-new/ScheduleFlow/Steps/Communications/InterviewerCommunicationCards/InterviewerContentCard/InterviewerRow';
import useInterviewEventTemplateContent from 'src/views-new/ScheduleFlow/Steps/Communications/InterviewerCommunicationCards/InterviewerContentCard/useInterviewEventTemplateContent';
import { useDefaultTemplateForTypeQuery } from 'src/views-new/Settings/TemplateComposer/useTemplateQueries';
import MeetingRoom from 'src/views-new/UpdateCommunications/InterviewerCommunicationCards/MeetingRoom';
import OldContent from 'src/views-new/UpdateCommunications/OldContent';

import { useDispatch, useSelector } from 'src/store';

export type UpdateType = 'none' | 'changed' | 'removed';

type Fragments = {
  interviewPlan: InterviewerContentCard_InterviewPlanFragment | undefined;
};

type Props = {
  eventId: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface InterviewerProps {
  updateType: UpdateType;
  employeeId: string;
  fullName: string;
  slackImageUrl: string | undefined | null;
  startAt: number | Date | null;
  endAt: number | Date | null;
  timezone: string | null;
  timeChange: boolean;
  scheduleId: string;
  eventId: string;
  isOptional: boolean;
  interviewersCount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  changed: { backgroundColor: theme.palette.background.info },
  error: { backgroundColor: theme.palette.background.error },
  interviewerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
    borderRadius: '6px',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.grey.solid.min,
    },
  },
}));

const useSxProps = (loading) => {
  return useMemo(() => {
    return {
      root: { marginBottom: '8px' },
      editor: {
        '& .ql-editor': {
          height: 500,
        },
        opacity: loading ? 0.5 : 1,
      },
    };
  }, [loading]);
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const Interviewer: FC<InterviewerProps> = ({
  updateType,
  employeeId,
  fullName,
  slackImageUrl,
  startAt,
  endAt,
  timezone,
  timeChange,
  scheduleId,
  eventId,
  isOptional = false,
  interviewersCount,
}) => {
  const classes = useStyles();

  const getHighlightClass = (type: string) => {
    if (type === 'changed') return classes.changed;
    if (type === 'removed') return classes.error;
    return undefined;
  };

  const getLabelColor = (type: string) => {
    if (type === 'removed') return 'error';
    return 'info';
  };

  return (
    <div className={classes.interviewerRow} key={employeeId}>
      {updateType !== 'none' && (
        <Label
          className={getHighlightClass(updateType)}
          style={{ marginTop: '6px' }}
          variant="captions"
          fontWeight={600}
          color={getLabelColor(updateType)}
        >
          {updateType === 'changed' && 'Changed'}
          {updateType === 'removed' && 'Removed'}
        </Label>
      )}

      <InterviewerRow
        employeeId={employeeId}
        eventStartAt={startAt}
        eventEndAt={endAt}
        fullName={fullName}
        slackImageUrl={slackImageUrl}
        timezone={timezone}
        interviewEventId={eventId}
        scheduleId={scheduleId}
        employeeChipClassName={getHighlightClass(updateType)}
        employeeNameLabelClassName={getHighlightClass(timeChange ? 'Changed' : '')}
        isOptional={isOptional}
        interviewersCount={interviewersCount}
      />
    </div>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const InterviewerContentCard: FCWithFragments<Fragments, Props> = ({ eventId, interviewPlan }) => {
  const classes = useStyles();
  const scheduleFlowData = useScheduleFlowData();
  const { candidateTimezone } = scheduleFlowData;

  const theme = useTheme() as Theme;
  const dispatch = useDispatch();
  const {
    stepScheduleContent: { isWaiting },
    selectedScheduleId,
    updateFlowInterviewExpanded,
  } = useSelector((state) => state.scheduling);
  const [content] = useSelector((state) => {
    const interviewerEventContents = getInterviewerEventContents(state);
    if (!interviewerEventContents) return [undefined];
    return [interviewerEventContents.find((c) => c.event.id === eventId)];
  });

  const isExpanded = updateFlowInterviewExpanded.includes(eventId);
  const slotId = content?.event.slotId;
  const [templateID] = useSelector((state) => (slotId ? [state.scheduling.interviewerTemplateIDs[slotId]] : []));

  // We need to get the schedule without the updates made by the user in calendar view, please do not use useSchedule here.
  const schedule = useSelector((state) => getScheduleById(state, selectedScheduleId || ''));
  const originalEvent = schedule?.events?.find((event) => event.id === content?.event.id);
  const scheduleUpdates = useSelector((state) => (schedule ? state.scheduleUpdate.byId[schedule.id] : undefined));
  const updatedSchedule = useScheduleWithoutBreaks(selectedScheduleId);
  const updatedEvent = updatedSchedule?.events?.find((event) => event.id === content?.event.id);

  const eventOldContent = useSelector(getEventOldContent)?.find(
    (event) => event.googleEventId === originalEvent?.googleEventId
  );

  const allCodingUrls = useSelector((state) => getAllCodingUrls(state, selectedScheduleId || ''));

  const groupByTypeAndInterviewId = scheduleUpdates ? groupBy(['type', 'applicationStageInterviewId']) : null;
  const resultBytypeAndInterviewId =
    groupByTypeAndInterviewId && scheduleUpdates ? groupByTypeAndInterviewId(scheduleUpdates) : [];

  const [defaultTemplateIdMap] = useDefaultTemplateForTypeQuery([TemplateType.InterviewerInvite]);

  useEffect(() => {
    if (templateID || !slotId) return;
    if (defaultTemplateIdMap.INTERVIEWER_INVITE) {
      dispatch(updateInterviewerTemplateID(eventId, slotId, defaultTemplateIdMap.INTERVIEWER_INVITE));
    }
  }, [defaultTemplateIdMap.INTERVIEWER_INVITE, dispatch, eventId, slotId, templateID]);

  let timeChange: SchdeuleUpdateInterviewTime[] | null = null;
  let nameChange: SchdeuleUpdateInterviewName[] | null = null;
  let scorecardChange: ScheduleUpdateScorecard[] | null = null;
  let interviewerChange: ScheduleUpdateInterviewSeat[] | null = null;
  let videoUrlChange: ScheduleUpdateVideoUrl[] | null = null;
  let codingUrlChange: ScheduleUpdateCodingUrl[] | null = null;
  let privateEventChange: ScheduleUpdateEditInterview[] | null = null;
  let meetingRoomsAddedChange: ScheduleUpdateAddInterviewMeetingRoom[] | null = null;
  let meetingRoomsDeletedChange: ScheduleUpdateDeleteInterviewMeetingRoom[] | null = null;
  let attendeeOptionalStatus: ScheduleUpdateInterviewerOptional[] | null = null;
  let interviewHiddenFromCandidate: ScheduleUpdateInterviewHiddenFromCandidate[] | null = null;

  const newInterview = resultBytypeAndInterviewId[
    `ScheduleUpdateNewInterview-${content?.event.id}` // *New*
  ] as ScheduleUpdateNewInterview[];

  // Only show "changed" indicators if this interview existed before
  // the update flow was started (aka wasn't added through Add Interview)
  if (!newInterview) {
    timeChange = resultBytypeAndInterviewId[
      `SchdeuleUpdateInterviewTime-${content?.event.id}`
    ] as SchdeuleUpdateInterviewTime[];
    nameChange = resultBytypeAndInterviewId[
      `SchdeuleUpdateInterviewName-${content?.event.id}`
    ] as SchdeuleUpdateInterviewName[];
    scorecardChange = resultBytypeAndInterviewId[
      `ScheduleUpdateScorecard-${content?.event.id}`
    ] as ScheduleUpdateScorecard[];
    interviewerChange = resultBytypeAndInterviewId[
      `ScheduleUpdateInterviewSeat-${content?.event.id}`
    ] as ScheduleUpdateInterviewSeat[];
    videoUrlChange = resultBytypeAndInterviewId[
      `ScheduleUpdateVideoUrl-${content?.event.id}`
    ] as ScheduleUpdateVideoUrl[];
    codingUrlChange = resultBytypeAndInterviewId[
      `ScheduleUpdateCodingUrl-${content?.event.id}`
    ] as ScheduleUpdateCodingUrl[];
    privateEventChange = resultBytypeAndInterviewId[
      `ScheduleUpdateInterviewPrivacy-${content?.event.id}`
    ] as ScheduleUpdateEditInterview[];
    meetingRoomsAddedChange = resultBytypeAndInterviewId[
      `ScheduleUpdateAddInterviewMeetingRoom-${content?.event.id}`
    ] as ScheduleUpdateAddInterviewMeetingRoom[];
    meetingRoomsDeletedChange = resultBytypeAndInterviewId[
      `ScheduleUpdateDeleteInterviewMeetingRoom-${content?.event.id}`
    ] as ScheduleUpdateDeleteInterviewMeetingRoom[];
    attendeeOptionalStatus = resultBytypeAndInterviewId[
      `ScheduleUpdateInterviewerOptional-${content?.event.id}`
    ] as ScheduleUpdateInterviewerOptional[];
    interviewHiddenFromCandidate = resultBytypeAndInterviewId[
      `ScheduleUpdateInterviewHiddenFromCandidate-${content?.event.id}`
    ] as ScheduleUpdateInterviewHiddenFromCandidate[];
  }

  const changes: string[] = [];

  if (nameChange) changes.push('title');
  if (timeChange) changes.push('time');
  if (scorecardChange) changes.push('scorecard');
  if (interviewerChange) changes.push('interviewer');
  if (videoUrlChange) changes.push('meeting link');
  if (codingUrlChange) changes.push('coding link');
  if (privateEventChange) changes.push('privacy setting');
  if (meetingRoomsAddedChange || meetingRoomsDeletedChange) changes.push('meeting room');
  if (newInterview) changes.push('new interview');
  if (attendeeOptionalStatus) changes.push('attendee optional status');
  if (interviewHiddenFromCandidate?.length) {
    if (interviewHiddenFromCandidate[0].isHiddenFromCandidate) {
      changes.push('interview hidden from candidate');
    } else {
      changes.push('interview shown to candidate');
    }
  }

  const getHighlightClass = (show: boolean) => {
    return show ? classes.changed : undefined;
  };

  const changeHeader = (
    <Stack wrap="nowrap">
      {!newInterview && (
        <Label
          variant="body"
          fontWeight={400}
          color="info"
          noWrap
          style={{
            backgroundColor: theme.palette.background.info,
          }}
        >
          Updated {changes.join(', ')}
        </Label>
      )}
      {newInterview && (
        <Tag color="info" size="medium" variant="filled" icon={<SuccessIcon color="info" />} label="New interview" />
      )}
    </Stack>
  );

  const changed = (
    <Label className={getHighlightClass(true)} variant="captions" fontWeight={600} color="info">
      Changed
    </Label>
  );

  const loading = useInterviewEventTemplateContent(
    { interviewPlan },
    { eventId, templateId: templateID || undefined, slotId }
  );
  const sxProps = useSxProps(loading);

  return (
    <Paper color="alternate" sx={sxProps.root}>
      <Stack direction="column" spacing={1}>
        {updatedEvent?.isHiddenFromCandidate && (
          <ConditionalThemeProvider>
            <MuiStack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                backgroundColor: (muiTheme: MuiTheme) => muiTheme.palette.background.paperHeader,
                borderBottom: (muiTheme: MuiTheme) => `1px solid ${muiTheme.palette.borderState.default}`,
                padding: 1,
                margin: -1.5,
                marginBottom: 0,
              }}
            >
              <EyeCrossedIcon />
              <Typography variant="subtitle2" color="text.secondary">
                Hidden from candidate
              </Typography>
            </MuiStack>
          </ConditionalThemeProvider>
        )}
        {changes.length > 0 && changeHeader}
        <Stack spacing={1}>
          {!!nameChange && changed}
          <Label className={getHighlightClass(!!nameChange)} variant="body" fontWeight={600}>
            {content?.event.name}
          </Label>
        </Stack>
        <Stack wrap="nowrap" spacing={1}>
          {!!timeChange && changed}
          <Label className={getHighlightClass(!!timeChange)} variant="captions" fontWeight={400}>
            {renderTimeRangeWithDayMthHrMinTz(content?.event.startAt, content?.event.endAt, candidateTimezone)}
          </Label>
        </Stack>

        {changes.length === 0 && !isExpanded && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            color="inherit"
            onClick={() => {
              if (content?.event) {
                dispatch(addUpdateFlowInterviewExpanded(content?.event.id));
              }
            }}
          >
            <Label variant="body" fontWeight={400} color="info">
              Edit unchanged interview
            </Label>
          </Link>
        )}
        {(changes.length > 0 || isExpanded) && (
          <Stack direction="column" spacing={1}>
            {content?.event.interviewers.map((i) => {
              const interviewersCount = content?.event.interviewers.length ?? 0;
              const hasInterviewerChanged = originalEvent?.interviewers.find(
                (oi) =>
                  oi.employee.id === i.employeeId || oi.employee.id === (i as unknown as RichInterviewer).employeeId
              );
              const currentInterviewer = schedule?.events
                ?.find((event) => {
                  return event?.id === content?.event.id;
                })
                ?.interviewers.find(
                  (interviewer) =>
                    interviewer?.employeeId === i?.employeeId ||
                    interviewer?.employeeId === (i as unknown as RichInterviewer).employeeId
                );
              return (
                // eslint-disable-next-line react/jsx-key
                <Interviewer
                  updateType={!hasInterviewerChanged && !newInterview ? 'changed' : 'none'}
                  employeeId={i.employeeId || (i as unknown as RichInterviewer).employeeId}
                  fullName={(i as unknown as RichInterviewer)?.employee?.fullName || ''}
                  slackImageUrl={(i as unknown as RichInterviewer)?.employee?.slackImageUrl}
                  startAt={content?.event.startAt ? parseISO(content.event.startAt) : null}
                  endAt={content?.event.endAt ? parseISO(content.event.endAt) : null}
                  timezone={(i as unknown as RichInterviewer)?.employee?.timezone || 'UTC'}
                  timeChange={!!timeChange}
                  scheduleId={selectedScheduleId ?? ''}
                  eventId={content?.event.id}
                  isOptional={Boolean(currentInterviewer?.isOptional)}
                  interviewersCount={interviewersCount}
                />
              );
            })}
            {originalEvent?.interviewers.map((oi) => {
              const interviewersCount = originalEvent?.interviewers.length ?? 0;

              const isInterviewerFoundInEvent = content?.event.interviewers.find(
                (i) =>
                  i.employeeId === oi.employee.id || oi.employee.id === (i as unknown as RichInterviewer).employeeId
              );

              const currentInterviewer = schedule?.events
                ?.find((event) => {
                  return event?.id === originalEvent?.id;
                })
                ?.interviewers.find(
                  (interviewer) =>
                    interviewer?.employeeId === oi?.employeeId ||
                    interviewer?.employeeId === (oi as unknown as RichInterviewer).employeeId
                );

              if (!isInterviewerFoundInEvent)
                return (
                  <Interviewer
                    updateType="removed"
                    employeeId={oi.employee.id}
                    fullName={oi.employee.fullName ?? ''}
                    slackImageUrl={oi.employee.slackImageUrl}
                    startAt={null}
                    endAt={null}
                    timezone={null}
                    timeChange={false}
                    scheduleId={schedule?.id ?? ''}
                    eventId={originalEvent.id}
                    isOptional={Boolean(currentInterviewer?.isOptional)}
                    interviewersCount={interviewersCount}
                  />
                );
              return null;
            })}
            {content?.location && (
              <Stack
                alignItems="center"
                wrap="nowrap"
                spacing={1}
                itemStyles={{ 0: { flexShrink: 0 }, 1: { flexShrink: videoUrlChange ? 0 : undefined } }}
              >
                {!!videoUrlChange && changed}
                <VideoIcon fontSize={20} color={videoUrlChange ? 'info' : 'max-contrast-grey'} />
                <Label
                  className={getHighlightClass(!!videoUrlChange)}
                  variant="captions"
                  fontWeight={400}
                  color="max-contrast-grey"
                  style={{ overflowWrap: 'anywhere', padding: '4px' }}
                >
                  {content?.location}
                </Label>
              </Stack>
            )}
            {allCodingUrls &&
              slotId &&
              Object.keys(allCodingUrls).map((key: CodeLinkType) => {
                const codingUrlsForType = allCodingUrls[key] || {};
                const codingUrl = codingUrlsForType[slotId];

                if (!codingUrl) return null;

                return (
                  <Stack
                    key={slotId}
                    wrap="nowrap"
                    spacing={1}
                    alignItems="center"
                    itemStyles={{ 0: { flexShrink: 0 }, 1: { flexShrink: codingUrlChange ? 0 : undefined } }}
                  >
                    {!!codingUrlChange && changed}
                    {iconMap[key]}
                    <Label
                      className={getHighlightClass(!!codingUrlChange)}
                      variant="captions"
                      fontWeight={400}
                      color="max-contrast-grey"
                      style={{ overflowWrap: 'anywhere', padding: '4px' }}
                    >
                      {codingUrl}
                    </Label>
                  </Stack>
                );
              })}
            <Divider />
            <Label variant="captions" fontWeight={600}>
              Template:
            </Label>

            <TemplateSelect
              minimalUX
              useSelectButton
              onSelect={(value) => {
                if (!slotId) return;
                dispatch(updateInterviewerTemplateID(eventId, slotId, value || ''));
              }}
              selectedTemplateId={templateID || undefined}
              types={[TemplateType.InterviewerInvite]}
            />

            {loading && <LinearProgress sx={{ width: '100%' }} />}

            {content && <Label icon={<MeetingRoomIcon />}>Meeting room</Label>}
            {content && (
              <MeetingRoom
                startAt={assertIsoTimestamp(new Date(content.event.startAt).toISOString())}
                endAt={assertIsoTimestamp(new Date(content.event.endAt).toISOString())}
                interviewEventId={content.event.id}
              />
            )}
            {content && <Divider />}
            <Stack direction="column" spacing={1}>
              <TextField
                fullWidth
                disabled={isWaiting || loading}
                variant="outlined"
                label=""
                required
                id="standard-required"
                value={(content && content.summary) || ''}
                onChange={(event) => {
                  if (selectedScheduleId) {
                    dispatch(updateInterviewerContentSummary(selectedScheduleId, event.target.value, eventId));
                  }
                }}
              />
              <Editor
                readOnly={loading}
                onChange={(value) => {
                  if (selectedScheduleId) {
                    dispatch(updateInterviewerContentDescription(selectedScheduleId, value, eventId));
                  }
                }}
                value={(content && content.description) || ''}
                sx={sxProps.editor}
              />
            </Stack>
          </Stack>
        )}
        {eventOldContent && (
          <OldContent title="" description={eventOldContent.oldDescription} summary={eventOldContent.oldSummary} />
        )}
      </Stack>
    </Paper>
  );
};

InterviewerContentCard.fragments = {
  interviewPlan: gql`
    ${useInterviewEventTemplateContent.fragments.interviewPlan}
    fragment InterviewerContentCard_interviewPlan on JobStage {
      id
      ...useInterviewEventTemplateContent_interviewPlan
    }
  `,
};

export default InterviewerContentCard;
