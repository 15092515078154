import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { CircularProgress } from '@mui/material';

import { CodeLinkType, CodingCard_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import IconButton from 'src/components/IconButton';
import Paper from 'src/components/Paper';
import Stack from 'src/components/Stack';
import Button from 'src/components/button';
import { CodeSignalIcon, CoderPadIcon, CodilityIcon, CrossIcon, HackerRankIcon, TrashIcon } from 'src/components/icons';
import Label from 'src/components/label';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { setScheduleContentStepWaiting } from 'src/slices/scheduling';

import { deleteCodeLinkType, deleteCodingUrl, setCodingUrl } from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { getAddedCodeLinkTypes, getCodingUrl } from 'src/store/selectors/schedule-communications';
import { getCandidateEmail, getCandidateFullName, getInterviewerEventContents } from 'src/store/selectors/scheduling';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import createCodingInterviewUrl from 'src/utils/api/createCodingInterviewUrl';
import { InterviewEvent } from 'src/utils/api/getScheduleOptions';
import { getExternalErrorMessage } from 'src/utils/error';
import getCodingUrlTitle from 'src/utils/getCodingUrlTitle';
import logError from 'src/utils/logError';
import { renderTimeRangeWithDayMthHrMinTz } from 'src/utils/renderTimeRange';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { useDispatch, useSelector } from 'src/store';

import useCreateCodingUrls from './useCreateCodingUrls';

export const iconMap = {
  [CodeLinkType.Hackerrank]: <HackerRankIcon />,
  [CodeLinkType.Codility]: <CodilityIcon />,
  [CodeLinkType.Coderpad]: <CoderPadIcon />,
  [CodeLinkType.Codesignal]: <CodeSignalIcon />,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type CodingCardRowProps = {
  codeLinkType: CodeLinkType;
  interview: InterviewEvent;
  scheduleId: string;
};

const CodingCardRow = ({ codeLinkType, scheduleId, interview }: CodingCardRowProps) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState('');

  const candidateEmail = useSelector(getCandidateEmail);
  const candidateName = useSelector(getCandidateFullName) || '';
  const codeUrl = useSelector((state) => getCodingUrl(state, scheduleId, interview.slotId || '', codeLinkType));

  const scheduleFlowData = useScheduleFlowData();
  const { candidateTimezone } = scheduleFlowData;
  const { slotId } = interview;
  if (!slotId) return null;

  const handleCreateCodingInterviewForSlotId = async () => {
    if (!candidateEmail) {
      setErrorMsg('Candiate email missing');
      return;
    }

    dispatch(setScheduleContentStepWaiting(true));

    try {
      const url = await createCodingInterviewUrl(codeLinkType, {
        candidateEmail,
        candidateName,
      });
      dispatch(setCodingUrl(scheduleId, codeLinkType, slotId, url));
    } catch (error) {
      logError(error);
      setErrorMsg(getExternalErrorMessage(error));
    }

    dispatch(setScheduleContentStepWaiting(false));
  };

  const removeCodingInterviewBySlotId = async () => {
    dispatch(deleteCodingUrl(scheduleId, codeLinkType, slotId));
  };

  return (
    <Stack direction="column" spacing={1}>
      <Label variant="captions" fontWeight={600}>
        {interview.name}
      </Label>
      <Label variant="captions" fontWeight={400} color="max-contrast-grey">
        {renderTimeRangeWithDayMthHrMinTz(interview.startAt, interview.endAt, candidateTimezone)}
      </Label>
      {!codeUrl && !submitting && (
        <Button
          autoFocus={false}
          label={`+ Add ${getCodingUrlTitle(codeLinkType)} link`}
          variant="link"
          size="small"
          endIcon={null}
          onClick={async () => {
            setSubmitting(true);
            if (scheduleId) {
              dispatch(
                addInterviewScheduleUpdate({
                  type: 'ScheduleUpdateCodingUrl',
                  updateType: ScheduleUpdateType.NEW,
                  scheduleId,
                  applicationStageInterviewId: interview.id,
                })
              );
            }
            try {
              await handleCreateCodingInterviewForSlotId();
            } catch (error) {
              logError(error);
            }
            setSubmitting(false);
          }}
        />
      )}
      {errorMsg && (
        <Alert
          alignItems="center"
          status="error"
          title={errorMsg}
          actions={
            <IconButton onClick={() => setErrorMsg('')}>
              <CrossIcon />
            </IconButton>
          }
        />
      )}
      {!codeUrl && submitting && <CircularProgress size={16} color="primary" />}
      {codeUrl && (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Label variant="captions" fontWeight={400}>
            {codeUrl}
          </Label>

          <IconButton
            onClick={() => {
              removeCodingInterviewBySlotId();
              if (scheduleId) {
                dispatch(
                  addInterviewScheduleUpdate({
                    type: 'ScheduleUpdateCodingUrl',
                    updateType: ScheduleUpdateType.DELETE,
                    scheduleId,
                    applicationStageInterviewId: interview.id,
                  })
                );
              }
              setSubmitting(false);
            }}
          >
            <CrossIcon fontSize={16} />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

type Fragments = {
  interviewPlan: CodingCard_InterviewPlanFragment | undefined;
};

type Props = {
  scheduleId: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const CodingCard: FCWithFragments<Fragments, Props> = ({ interviewPlan, scheduleId }) => {
  const dispatch = useDispatch();

  const schedule = useScheduleWithoutBreaks(scheduleId);

  const addedCodeLinkTypes = useSelector((state) => getAddedCodeLinkTypes(state, scheduleId));
  const interviewContents = useSelector(getInterviewerEventContents);

  // Generate the coding links based on coding tools configured in interview plan.
  useCreateCodingUrls({ interviewPlan }, { scheduleId });

  if (addedCodeLinkTypes.length === 0) return null;

  const handleDeleteCodeLinkType = (codeLinkType: CodeLinkType) => {
    dispatch(deleteCodeLinkType(scheduleId, codeLinkType));

    if (!schedule || !schedule.events) return;
    schedule.events.map((interview) => {
      return dispatch(
        addInterviewScheduleUpdate({
          type: 'ScheduleUpdateCodingUrl',
          updateType: ScheduleUpdateType.DELETE,
          scheduleId: schedule.id,
          applicationStageInterviewId: interview.id,
        })
      );
    });
  };

  return (
    <Stack direction="column" spacing={1}>
      {addedCodeLinkTypes.map((codeLinkType) => {
        return (
          <Paper color="alternate" key={codeLinkType}>
            <Stack direction="column" spacing={1}>
              <Stack justifyContent="space-between">
                <Label fontWeight={600}>{getCodingUrlTitle(codeLinkType)}</Label>
                <IconButton onClick={() => handleDeleteCodeLinkType(codeLinkType)}>
                  <TrashIcon />
                </IconButton>
              </Stack>

              {interviewContents?.map((content) => (
                <CodingCardRow
                  key={content.event.id}
                  codeLinkType={codeLinkType}
                  scheduleId={scheduleId}
                  interview={content.event}
                />
              ))}
            </Stack>
          </Paper>
        );
      })}
    </Stack>
  );
};

CodingCard.fragments = {
  interviewPlan: gql`
    ${useCreateCodingUrls.fragments.interviewPlan}
    fragment CodingCard_interviewPlan on JobStage {
      id
      ...useCreateCodingUrls_interviewPlan
    }
  `,
};

export default CodingCard;
