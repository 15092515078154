import React from 'react';

import { ApolloError, gql } from '@apollo/client';
import { FCWithFragments, ZeroState } from '@modernloop/shared/components';
import { Loader, PublicError } from '@modernloop/shared/helper-components';
import {
  Box,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';

import { InterviewModuleMemberSearchOrderByProperty, Members_MembersFragment, Sort } from 'src/generated/mloop-graphql';

import ModuleMemberRow, { ModuleMemberRowType } from 'src/views-new/InterviewModuleDetails/Members/ModuleMemberRow';
import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

import { PAGER_DEFAULT } from 'src/constants';

type Fragments = {
  members: Members_MembersFragment[];
};

type Props = {
  total: number;
  currentPage: number;
  onPagination: (page: number) => void;
  direction: Sort;
  orderBy: InterviewModuleMemberSearchOrderByProperty;
  onSort: (property: InterviewModuleMemberSearchOrderByProperty) => void;
  loading?: boolean;
  error?: ApolloError;
};

const Members: FCWithFragments<Fragments, Props> = ({
  members,
  orderBy,
  direction,
  currentPage,
  total,
  onSort,
  onPagination,
  loading,
  error,
}) => {
  const getSortDirection = (property: InterviewModuleMemberSearchOrderByProperty): 'asc' | 'desc' | undefined => {
    if (property === orderBy) {
      return direction?.toUpperCase() === Sort.Asc ? 'asc' : 'desc';
    }
    return undefined;
  };

  const pageCount = Math.ceil((total || 1) / PAGER_DEFAULT) || 1;

  const getMarkup = () => {
    let jsx;
    if (loading && !members?.length) {
      jsx = <Loader loading={loading} />;
    } else if (error) {
      jsx = <PublicError error={error} />;
    } else if (!members.length) {
      jsx = <ZeroState label="No interviewers found" />;
    }
    if (jsx) {
      return (
        <TableRow>
          <TableCell colSpan={7}>
            <Box justifyContent="center" display="flex">
              {jsx}
            </Box>
          </TableCell>
        </TableRow>
      );
    }
    return members.map((member) => (
      <ModuleMemberRow
        rowType={ModuleMemberRowType.Member}
        onRowClick={() => {
          SidePanelManager.openInterviewModuleSidePanel({
            interviewModuleId: member.interviewModuleId,
            moduleMemberId: member.employeeId,
          });
        }}
        key={`${member.interviewModuleId}-${member.employeeId}`}
        moduleMember={member}
      />
    ));
  };

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="30%">
              <TableSortLabel
                direction={getSortDirection(InterviewModuleMemberSearchOrderByProperty.Name)}
                onClick={() => {
                  onSort(InterviewModuleMemberSearchOrderByProperty.Name);
                }}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                direction={getSortDirection(InterviewModuleMemberSearchOrderByProperty.ProgressPercentage)}
                onClick={() => {
                  onSort(InterviewModuleMemberSearchOrderByProperty.ProgressPercentage);
                }}
              >
                Role
              </TableSortLabel>
            </TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Upcoming</TableCell>
            <TableCell>Shadows</TableCell>
            <TableCell>Reverse</TableCell>
            <TableCell>Trained</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{getMarkup()}</TableBody>
      </Table>
      {pageCount > 1 && (
        <TableFooter sx={{ width: '100%', display: 'flex' }}>
          <Stack justifyContent="center" alignItems="center" width="100%" p={1}>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={(e, page) => {
                onPagination(page);
              }}
            />
          </Stack>
        </TableFooter>
      )}
    </TableContainer>
  );
};

Members.fragments = {
  members: gql`
    ${ModuleMemberRow.fragments.moduleMember}
    fragment Members_members on InterviewModuleMember {
      interviewModuleId
      employeeId
      ...ModuleMemberRow_moduleMember
    }
  `,
};

export default Members;
