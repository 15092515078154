import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { AppThunk } from 'src/store';

const MAX_LAST_USED_TIMEZONES = 10;
const MAX_LAST_USED_ZOOM_ACCOUNTS = 5;

export interface PersistState {
  lastUsedTimezones: string[];
  lastUsedZoomUserIds: string[];
  lastUsedSlackChannelMessageTemplateID: string;
  lastUsedInterviewerEventTemplateID: string;
  lastUsedCandidateEmailTemplateID: string;
  lastUsedCandidateEventTemplateID: string;
}

const initialState: PersistState = {
  lastUsedTimezones: [],
  lastUsedZoomUserIds: [],
  lastUsedSlackChannelMessageTemplateID: '',
  lastUsedInterviewerEventTemplateID: '',
  lastUsedCandidateEmailTemplateID: '',
  lastUsedCandidateEventTemplateID: '',
};

// reducers
const slice = createSlice({
  name: 'persist',
  initialState,
  reducers: {
    addLastUsedTimezone: (state, action: PayloadAction<{ tz: string }>) => {
      // Check if the timezone is already in the list
      const { tz } = action.payload;
      state.lastUsedTimezones = [...state.lastUsedTimezones];
      const index = state.lastUsedTimezones.indexOf(tz);
      if (index !== -1) {
        state.lastUsedTimezones.splice(index, 1);
      }
      state.lastUsedTimezones.unshift(tz);
      // Truncate the list to a max of 10 elements
      state.lastUsedTimezones = state.lastUsedTimezones.slice(0, MAX_LAST_USED_TIMEZONES);
    },
    addLastZoomUserIds: (state, action: PayloadAction<{ userId: string }>) => {
      // Check if the timezone is already in the list
      const { userId } = action.payload;
      state.lastUsedZoomUserIds = [...state.lastUsedZoomUserIds];
      state.lastUsedZoomUserIds.unshift(userId);
      // Truncate the list to a max of 5 elements
      state.lastUsedZoomUserIds = state.lastUsedZoomUserIds.slice(0, MAX_LAST_USED_ZOOM_ACCOUNTS);
    },
    updateLastUsedSlackChannelMessageTemplateID: (state, action: PayloadAction<{ templateID: string }>) => {
      const { templateID } = action.payload;
      state.lastUsedSlackChannelMessageTemplateID = templateID;
    },
    updateLastUsedInterviewerEventTemplateID: (state, action: PayloadAction<{ templateID: string }>) => {
      const { templateID } = action.payload;
      state.lastUsedInterviewerEventTemplateID = templateID;
    },
    updateLastUsedCandidateEmailTemplateID: (state, action: PayloadAction<{ templateID: string }>) => {
      const { templateID } = action.payload;
      state.lastUsedCandidateEmailTemplateID = templateID;
    },
    updateLastUsedCandidateEventTemplateID: (state, action: PayloadAction<{ templateID: string }>) => {
      const { templateID } = action.payload;
      state.lastUsedCandidateEventTemplateID = templateID;
    },
  },
});

export const { reducer } = slice;
export default reducer;

// Add actions here

export const addLastUsedTimezone =
  (tz: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.addLastUsedTimezone({ tz }));
  };

export const addLastUsedZoomUserIds =
  (userId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.addLastZoomUserIds({ userId }));
  };

export const updateLastUsedSlackChannelMessageTemplateID =
  (templateID: string | null): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateLastUsedSlackChannelMessageTemplateID({ templateID: templateID ?? '' }));
  };

export const updateLastUsedInterviewerEventTemplateID =
  (templateID: string | null): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateLastUsedInterviewerEventTemplateID({ templateID: templateID ?? '' }));
  };

export const updateLastUsedCandidateEmailTemplateID =
  (templateID: string | null): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateLastUsedCandidateEmailTemplateID({ templateID: templateID ?? '' }));
  };

export const updateLastUsedCandidateEventTemplateID =
  (templateID: string | null): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateLastUsedCandidateEventTemplateID({ templateID: templateID ?? '' }));
  };
