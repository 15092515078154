import React, { useState } from 'react';
import type { FC } from 'react';

import { useApolloClient } from '@apollo/client';
import { Dialog } from '@modernloop/shared/components';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import AtsName from 'src/entities/Ats/AtsName';
import AtsScorecardName from 'src/entities/Ats/AtsScorecardName';

import useDirectoryInfo from 'src/hooks/useDirectoryInfo';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import cancelDebrief from 'src/utils/api/cancelDebrief';
import cancelInterview from 'src/utils/api/cancelInterview';
import { apolloCacheDeleteById, apolloCacheDeleteByQuery } from 'src/utils/apolloHelpers';
import { getExternalErrorMessage } from 'src/utils/error';
import logError from 'src/utils/logError';

interface Props {
  interviewId: string;
  isOpen: boolean;
  isCompleted?: boolean;
  isDebrief?: boolean;
  onCancel: () => void;
  onClose: () => void;
  selfScheduleOptionsId?: string;
  applicationId?: string;
  jobStageId?: string;
}

const CancelInterviewDialog: FC<Props> = ({
  interviewId,
  isOpen,
  isCompleted,
  isDebrief,
  onCancel,
  onClose,
  selfScheduleOptionsId,
  applicationId,
  jobStageId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const directoryInfo = useDirectoryInfo();

  const client = useApolloClient();

  const [canceling, setCanceling] = useState<boolean>(false);

  const type = isDebrief ? 'debrief' : 'interview';
  const title = isCompleted ? 'Mark as canceled?' : `Cancel ${type}?`;

  const doCancel = async () => {
    setCanceling(true);
    try {
      if (isDebrief) {
        await cancelDebrief({ applicationDebriefId: interviewId });
        apolloCacheDeleteById(client.cache, interviewId, 'ApplicationDebrief');
      } else {
        await cancelInterview({ applicationStageInterviewId: interviewId });
      }
      if (selfScheduleOptionsId) {
        apolloCacheDeleteByQuery(client.cache, 'selfScheduleOptions', { id: selfScheduleOptionsId });
        if (applicationId) {
          apolloCacheDeleteByQuery(client.cache, 'selfScheduleOptionsForApplicationJobStage', {
            input: {
              applicationId,
              jobStageId,
            },
          });
        }
      }
      onCancel();
    } catch (e) {
      logError(e);
      const errorMsg = getExternalErrorMessage(e) || `Failed to cancel ${isDebrief ? 'debrief' : 'interview'}`;
      onClose();
      enqueueSnackbar(errorMsg, {
        variant: 'error',
      });
    }
    setCanceling(false);
  };

  return (
    <ConditionalThemeProvider>
      <Dialog
        title={title}
        subTitle="This cannot be undone."
        open={isOpen}
        onClose={onClose}
        submitOptions={{
          label: 'Cancel event',
          isDisabled: canceling,
          isLoading: canceling,
          isDangerous: true,
          onClick: doCancel,
        }}
        cancelOptions={{
          label: 'Keep event',
          onClick: onClose,
        }}
      >
        <Box>
          {!isDebrief && (
            <Typography>
              All records of this interview in <AtsName /> will be deleted, including any related <AtsScorecardName />{' '}
              that have not been submitted. The interviewer event in {directoryInfo.label} Calendar will also be
              deleted.
            </Typography>
          )}
          {isDebrief && (
            <Typography>
              All records of this debrief will be deleted in ModernLoop. The event in {directoryInfo.label} Calendar
              will also be deleted.
            </Typography>
          )}
          {isDebrief && <br />}
          {isDebrief && (
            <Typography>
              No communication will be sent to the candidate. Candidate calendar events will not be changed.
            </Typography>
          )}
        </Box>
      </Dialog>
    </ConditionalThemeProvider>
  );
};

export default CancelInterviewDialog;
