import React, { useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Divider, Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Stack } from '@mui/material';

import { EmployeeFragment } from 'src/generated/mloop-graphql';

import Button from 'src/components/button';
import Checkbox from 'src/components/checkbox';
import { TriangleDownIcon } from 'src/components/icons';
import Menu, { MenuOption } from 'src/components/menu';

import EmployeePicker from 'src/entities/EmployeePicker/EmployeePickerExtended';

import { updateJobStagePlanConfig } from 'src/store/actions/job-stage-plan-config';
import {
  getExcludeInterviewerIdsByJobStageId,
  getHasBreaksByJobStageId,
  getHasMultiDayByJobStageId,
  getNumberOfDaysByJobStageId,
} from 'src/store/selectors/job-stage-plan-config';

import { useDispatch, useSelector } from 'src/store';
import { Theme } from 'src/theme';

const NUMBER_OF_DAYS_OPTIONS: MenuOption[] = [
  { id: 0, value: 'No limit' },
  { id: 2, value: '2 days' },
  { id: 3, value: '3 days' },
  { id: 4, value: '4 days' },
  { id: 5, value: '5 days' },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    numberOfDays: {
      minWidth: '172px',
      marginLeft: '36px',
      borderRadius: '6px',
      cursor: 'pointer',
    },
    employeePicker: {
      marginLeft: '36px',
    },
    button: {
      borderRadius: '6px',
      fontWeight: theme.typography.fontWeightRegular,
      width: '200px',
      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  })
);

type Props = {
  jobStageId: string;
};

const InterviewConfig = ({ jobStageId }: Props): JSX.Element | null => {
  const orgBreakImprovementsEnabled = useFlag('org_break_improvements');
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showNumberOfDaysMenu, setShowNumberOfDaysMenu] = useState(false);
  const [showExcludedInterviewerIdsSelector, setShowExcludedInterviewerIdsSelector] = useState(false);
  const numberOfDaysRef = useRef<HTMLButtonElement>(null);

  const hasBreaks = useSelector((state) => getHasBreaksByJobStageId(state, jobStageId));
  const hasMultiDay = useSelector((state) => getHasMultiDayByJobStageId(state, jobStageId));
  const numberOfDays = useSelector((state) => getNumberOfDaysByJobStageId(state, jobStageId));
  const excludeInterviewerIds = useSelector((state) => getExcludeInterviewerIdsByJobStageId(state, jobStageId));

  if (orgBreakImprovementsEnabled) return null;

  const handleHasBreakChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateJobStagePlanConfig({ jobStageId, hasBreaks: event.target.checked }));
  };

  const handleHasMultiDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateJobStagePlanConfig({ jobStageId, hasMultiDay: event.target.checked }));
  };

  const handleNumberOfDaysMenuOpen = () => {
    setShowNumberOfDaysMenu(true);
  };

  const handleNumberOfDaysMenuClose = () => {
    setShowNumberOfDaysMenu(false);
  };

  const handleNumberOfDaysChange = (option: MenuOption) => {
    dispatch(updateJobStagePlanConfig({ jobStageId, numberOfDays: option.id as number }));
    handleNumberOfDaysMenuClose();
  };

  const handleShowExculdedInterviewPicker = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowExcludedInterviewerIdsSelector(event.target.checked);
    if (event.target.checked) return;

    dispatch(updateJobStagePlanConfig({ jobStageId, excludeInterviewerIds: [] }));
  };

  const handleExcludedInterviewChange = (ids: string[]) => {
    dispatch(updateJobStagePlanConfig({ jobStageId, excludeInterviewerIds: ids }));
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item>
        <Checkbox label="Allow adding breaks to avoid conflicts" checked={hasBreaks} onChange={handleHasBreakChange} />
      </Grid>
      <Grid item container direction="column">
        <Grid item>
          <Checkbox
            label="Allow scheduling over multiple days"
            checked={hasMultiDay}
            onChange={handleHasMultiDayChange}
          />
        </Grid>
        {hasMultiDay && (
          <Grid item container alignItems="center">
            <Stack className={classes.numberOfDays}>
              <Button
                variant="outlined"
                className={classes.button}
                label={numberOfDays === 0 ? 'No limit' : `Up to ${numberOfDays} Days `}
                ref={numberOfDaysRef}
                endIcon={<TriangleDownIcon />}
                onClick={handleNumberOfDaysMenuOpen}
              />
              <Grid item>
                {/* <IconButton onClick={handleNumberOfDaysMenuOpen}>
                <CaretDownIcon />
              </IconButton> */}
                <Menu
                  id="number-of-days-menu"
                  anchorEl={numberOfDaysRef.current}
                  open={showNumberOfDaysMenu}
                  options={NUMBER_OF_DAYS_OPTIONS}
                  onSelect={handleNumberOfDaysChange}
                  onClose={handleNumberOfDaysMenuClose}
                />
              </Grid>
            </Stack>
          </Grid>
        )}
      </Grid>
      <Grid item container direction="column" wrap="nowrap">
        <Grid item>
          <Checkbox
            label="Exclude specific interviewers"
            checked={
              showExcludedInterviewerIdsSelector || Boolean(excludeInterviewerIds && excludeInterviewerIds.length)
            }
            onChange={handleShowExculdedInterviewPicker}
          />
        </Grid>
        {(showExcludedInterviewerIdsSelector || Boolean(excludeInterviewerIds && excludeInterviewerIds.length)) && (
          <Grid item container xs={8} className={classes.employeePicker}>
            <EmployeePicker
              selectedEmployeeIds={excludeInterviewerIds || []}
              onChange={(emps: EmployeeFragment[]) => handleExcludedInterviewChange(emps.map((emp) => emp.id))}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default InterviewConfig;
