/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';

import { useFlag } from '@modernloop/shared/feature-flag';
import { StarFilledIcon } from '@modernloop/shared/icons';
import type PopperJs from 'popper.js';

import {
  CurrentTrainingSessionStatus,
  InterviewerFlag,
  InterviewerRole,
  TrainingStatus,
} from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import Stack from 'src/components/Stack';
import { InfoIcon, PauseIcon, UserSlashIcon, WarningIcon } from 'src/components/icons';
import Label from 'src/components/label';
import Tooltip from 'src/components/tooltip';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

import ModuleMember from 'src/entities/InterviewModuleMember/Scheduling/ModuleMember';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { InterviewEvent } from 'src/utils/api/getScheduleOptions';

import ScheduleInterviewerDetails from 'src/views-new/ScheduleFlow/Steps/ScheduleOptions/ScheduleInterviewerDetails';

import { InterviewerSuggestion } from './types';
import { getInterviewerAvailabilityStatus, getRichInterviewerFromSuggestion } from './utils';

type Props = {
  interviewEvent: InterviewEvent;
  interviewer: InterviewerSuggestion;
  excluded: boolean;
  preferred: boolean;
};

/**
 * Note currently not able to fragmentise this component as it uses ScheduleInterviewerDetails , getRichInterviewerFromSuggestion & getInterviewerAvailabilityStatus
 * which gets data from GRPC API as well.
 */

const InterviewerSuggestionRow = ({ interviewEvent, interviewer, excluded, preferred }: Props): JSX.Element => {
  const popperRef = useRef<PopperJs | null>(null);
  const isFastTrackTrainingEnabled = useFlag('user_fast_track_interview_module');

  const richInterviewer = getRichInterviewerFromSuggestion(interviewer);
  const eventsStr = excluded ? 'Excluded from interview plan' : getInterviewerAvailabilityStatus(richInterviewer);
  const stats = interviewer?.employee?.interviewModuleMember?.stats;

  let roleStr = 'Interviewer';
  if (stats?.currentTrainingSessionStatus === CurrentTrainingSessionStatus.NeedsApproval) {
    roleStr = 'Needs approval';
  } else if (stats?.currentTrainingSessionStatus === CurrentTrainingSessionStatus.RequiredInterviewsScheduled) {
    if (stats.trainingStatus === TrainingStatus.Shadow) {
      roleStr = 'All required shadows scheduled';
    } else if (stats.trainingStatus === TrainingStatus.ReverseShadow) {
      roleStr = 'All required reverse shadows scheduled';
    }
  } else if (interviewer?.role === InterviewerRole.Shadow) {
    roleStr = 'Shadow';

    if (stats) {
      roleStr += ` ${stats.completedAsShadow + stats.shadowOffset + 1}`;
    }
  } else if (interviewer?.role === InterviewerRole.ReverseShadow) {
    roleStr = 'Reverse shadow';

    if (stats) {
      roleStr += ` ${stats.completedAsReverseShadow + stats.reverseShadowOffset + 1}`;
    }
  }

  const flagIcon = (() => {
    if (excluded) {
      return <UserSlashIcon />;
    }

    if (
      interviewer?.employee?.interviewModuleMember?.stats?.currentTrainingSessionStatus ===
      CurrentTrainingSessionStatus.NeedsApproval
    ) {
      return <InfoIcon color="warning" />;
    }

    const flags = interviewer?.flags;

    if (!flags || flags.length === 0) return null;

    if (flags.includes(InterviewerFlag.Paused) || flags.includes(InterviewerFlag.PausedInModule)) return <PauseIcon />;

    if (
      flags.includes(InterviewerFlag.Conflict) ||
      flags.includes(InterviewerFlag.ExternalConflict) ||
      flags.includes(InterviewerFlag.InterviewConflict) ||
      flags.includes(InterviewerFlag.Ooo) ||
      flags.includes(InterviewerFlag.OutsideWorkHours) ||
      flags.includes(InterviewerFlag.UnschedulableConflict) ||
      flags.includes(InterviewerFlag.CompanyCountryHoliday) ||
      flags.includes(InterviewerFlag.ConflictOfInterestWithCandidate) ||
      flags.includes(InterviewerFlag.PreviousInterviewsForApplication)
    ) {
      return <WarningIcon color="error" />;
    }

    if (
      flags.includes(InterviewerFlag.HoldConflict) ||
      flags.includes(InterviewerFlag.MissingCalendar) ||
      flags.includes(InterviewerFlag.MissingTimezone) ||
      flags.includes(InterviewerFlag.OneOnOneConflict) ||
      flags.includes(InterviewerFlag.OutsideRecruitingBlock) ||
      flags.includes(InterviewerFlag.OverDailyLimit) ||
      flags.includes(InterviewerFlag.OverWeeklyLimit) ||
      flags.includes(InterviewerFlag.SchedulableConflict)
    ) {
      return <WarningIcon color="warning" />;
    }

    return null;
  })();

  /**
   * When user_fast_track_interview_module is disabled or we need to render freeform interviewer suggestion row
   */
  if (!isFastTrackTrainingEnabled || !interviewer.employee?.interviewModuleMember) {
    return (
      <div style={{ width: '100%' }} data-testid="update-interview-seat-interviewer-suggestion-row">
        <Tooltip
          interactive
          placement="right-end"
          tooltip={
            <ScheduleInterviewerDetails event={interviewEvent} interviewer={richInterviewer} popperRef={popperRef} />
          }
          popperRef={popperRef}
        >
          <Stack
            alignItems="center"
            itemStyles={{ 0: { flexShrink: 0 }, 1: { flexGrow: 1 }, 2: { flexShrink: 0 } }}
            spacing={1}
            wrap="nowrap"
          >
            <Avatar
              alt={interviewer?.employee?.fullName || ''}
              src={interviewer?.employee?.slackImageUrl || undefined}
            />

            <Stack direction="column">
              <Stack spacing={1}>
                <MaybeTooltip
                  label={interviewer?.employee?.fullName || ''}
                  labelProps={{ fontWeight: 400 }}
                  tooltip={interviewer?.employee?.fullName || ''}
                />
                <Label color="high-contrast-grey" variant="captions" noWrap>
                  {interviewer?.employee?.title || ''}
                </Label>

                {preferred && <StarFilledIcon color="warning" />}
              </Stack>
              {interviewer?.role !== InterviewerRole.Interviewer && (
                <Label color="high-contrast-grey" variant="captions" noWrap>
                  {roleStr}
                </Label>
              )}
              <MaybeTooltip
                label={eventsStr}
                labelProps={{ color: 'high-contrast-grey', variant: 'captions' }}
                tooltip={eventsStr}
              />
            </Stack>
            {flagIcon}
          </Stack>
        </Tooltip>
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }} data-testid="update-interview-seat-interviewer-suggestion-row">
      <Tooltip
        interactive
        placement="right-end"
        tooltip={
          <ScheduleInterviewerDetails event={interviewEvent} interviewer={richInterviewer} popperRef={popperRef} />
        }
        popperRef={popperRef}
      >
        <ConditionalThemeProvider>
          <ModuleMember
            preferred={preferred}
            flagIcon={flagIcon}
            eventsStr={eventsStr}
            interviewModuleMember={interviewer.employee?.interviewModuleMember}
          />
        </ConditionalThemeProvider>
      </Tooltip>
    </div>
  );
};

export default InterviewerSuggestionRow;
