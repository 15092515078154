import { MutableRefObject } from 'react';

import { ApolloClient } from '@apollo/client';
import { sha256Encode } from '@modernloop/shared/utils';
import { merge, unescape } from 'lodash';
import { v4 as uuid } from 'uuid';

import { InterviewTokensQuery, RenderType } from 'src/generated/mloop-graphql';

import { CodingUrlBySlotId } from 'src/store/slices/schedule-communications';

import { InterviewEvent, InterviewSchedule } from 'src/utils/api/getScheduleOptions';
import logError from 'src/utils/logError';

import InterviewPlaceholderFiller from './InterviewPlaceholderFiller';
import getRequestInputsForInterviewEvent from './getRequestInputsForInterviewEvent';
import getRequestResponseForInterviewEvent from './getRequestResponseForInterviewEvent';
import { MeetingType } from './input';
import { InterviewPlaceholderFillerOptions } from './types';

export type Input = {
  renderType?: RenderType;
  applicationId: string;
  candidateId: string;
  schedule: InterviewSchedule;
  timezone?: string;
  codingUrlByInterviewEvent?: CodingUrlBySlotId;
  isFetch?: boolean;
  skipMeetingLinkWithSchedule?: boolean;
  slotId?: string;
  candidateTimezone?: string; // Expliclity pass for INTERVIEW_SCHEDULE  token to be computed in candidate timezone
} & MeetingType;

/**
 * Generates a unique request ID based on the given input parameters.
 * @param params - The input parameters to generate the request ID from.
 * @returns A Promise that resolves to a string representing the generated request ID.
 */
export const getRequestId = async (params: Input): Promise<string> => {
  try {
    const ascii = btoa(unescape(encodeURIComponent(JSON.stringify(params))));
    // eslint-disable-next-line @typescript-eslint/return-await
    return sha256Encode(ascii);
  } catch (e) {
    logError(e, { params });
    // If failed just return a unique uuid for every request
    return sha256Encode(uuid());
  }
};

/**
 * Checks if the given `id` is included in the `INTERVIEW_SCHEDULE` field of the `data` object.
 * @param data - The `InterviewTokensQuery` object to check.
 * @param id - The ID to search for.
 * @returns `true` if the `id` is included in the `INTERVIEW_SCHEDULE` field, `false` otherwise.
 */
const hasRequestId = (data: InterviewTokensQuery, id: string): boolean => {
  // INTERVIEW_SCHEDULE is a required to come
  return data.INTERVIEW_SCHEDULE?.id?.includes(id) || false;
};

/**
 * Fetches the token data for the interviewer placeholder filler.
 * @param apolloClient - The Apollo client object.
 * @param requestIdRef - The mutable reference object for the request ID.
 * @param params - The input parameters for the interview placeholder filler.
 * @param slotId - Optional. The ID of the slot to fetch the token data for, if it doesnt exist, it will fetch for all the events
 * @returns The interview placeholder filler options or null.
 */
const fetchInterviewerPlaceholderFillerTokenData = async (
  apolloClient: ApolloClient<object>,
  requestIdRef: MutableRefObject<string>,
  params: Input,
  slotId?: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
Promise<InterviewPlaceholderFillerOptions | null> => {
  const { schedule } = params;

  /**
   * Get a unique identifer based on the params passed to the hook
   */
  const requestId = await getRequestId(params);
  if (requestIdRef) {
    requestIdRef.current = requestId;
  }

  const { events } = schedule;
  if (!events || !events.length) return null;

  // Since we have to do this once for a single slot id or for all the events
  // we can encapsulate this logic in a function
  const fetchForInterviewEvent = async (event: InterviewEvent | undefined) => {
    if (!event) {
      return null;
    }

    const input = getRequestInputsForInterviewEvent(requestId, params, event);
    if (!input) {
      return null;
    }

    const response = await getRequestResponseForInterviewEvent(apolloClient, input);

    // if the response does not have the requestId, we should not use it
    // this means that our current request is no longer valid
    if (!hasRequestId(response.data, requestIdRef?.current)) {
      return null;
    }

    const options = InterviewPlaceholderFiller.getOptions(response.data);
    return options;
  };

  // When there is a single slot id, we only need to request for that slot id
  if (slotId) {
    const event = events.find((e) => e.slotId === slotId);
    const data = await fetchForInterviewEvent(event);
    return data;
  }

  // When there is no slot id, we need to request for all the events
  const options = (
    await Promise.all(
      events.map(async (event: InterviewEvent) => {
        if (!event) {
          return null;
        }

        const thatSlotId = event.slotId;
        if (!thatSlotId) {
          return null;
        }

        const data = await fetchForInterviewEvent(event);
        if (!data) {
          return null;
        }

        return {
          // must be keyed by its on slot id
          [thatSlotId]: data,
        };
      })
    )
  ).filter(Boolean); // filter to non-null values;

  if (!options.length) return null;

  return InterviewPlaceholderFiller.getInterviewMergedTokens(merge({}, ...options));
};

export default fetchInterviewerPlaceholderFillerTokenData;
