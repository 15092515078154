import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskCandidateInterviewReminderSent_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';

interface Fragments {
  activityLog: ActivityLogTaskCandidateInterviewReminderSent_ActivityLogFragment;
}

const ActivityLogTaskCandidateInterviewReminderSent: FCWithFragments<Fragments> = ({ activityLog }) => {
  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel="sent a reminder to the candidate for their upcoming interview"
    />
  );
};

ActivityLogTaskCandidateInterviewReminderSent.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskCandidateInterviewReminderSent_activityLog on ActivityLog {
      id

      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskCandidateInterviewReminderSent;
