import React from 'react';

import { Dialog } from '@modernloop/shared/components';
import { Stack, Typography } from '@mui/material';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type MissingSelfScheduleLinkTokenModalProps = {
  onAppendAndContinue: () => void;
  goBack: () => void;
};

const MissingSelfScheduleLinkTokenModal = ({ onAppendAndContinue, goBack }: MissingSelfScheduleLinkTokenModalProps) => {
  return (
    <Dialog
      open
      title="Missing token"
      submitOptions={{
        label: 'Append & send',
        onClick: onAppendAndContinue,
      }}
      cancelOptions={{
        label: 'Cancel',
        onClick: goBack,
      }}
    >
      <Stack spacing={2}>
        <Typography>
          Your email is missing the {'{{SELF_SCHEDULE_LINK}}'} token. This is necessary for the candidate to be able to
          schedule their interview. We’ll append this link to the end of your email similar to the example below:
        </Typography>
        <Typography color="text.secondary">
          Click here to schedule your interview: https://mloop.prod.modernloop.io/s/7LzYHZoccUa
        </Typography>
      </Stack>
    </Dialog>
  );
};

export default MissingSelfScheduleLinkTokenModal;
