import React, { FC, useMemo, useState } from 'react';

import { logError } from '@modernloop/shared/utils';
import { Stack, StackProps } from '@mui/material';

import { ActivityType, ActorType, SourceType } from 'src/generated/mloop-graphql';

import PageTabs, { PageTab } from 'src/components/PageTabs';
import Label from 'src/components/label';

import ApplicationDetailsActivityLogsList from 'src/entities/ActivityLogsList/ApplicationDetailsActivityLogsList';
import CreateNoteTextField from 'src/entities/ActivityLogsList/CreateNoteTextField';
import TaskDetailsActivityLogsList from 'src/entities/ActivityLogsList/TaskDetailsActivityLogsList';
import SubscribersSelect from 'src/entities/SubscribersSelect';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';

interface Props {
  sourceId: string;
  sourceType: SourceType;
  onHasUnsavedNoteChangesChange?: (value: boolean) => void;
  onNoteCreated?: () => void;
}

enum ActivityFeedTabs {
  All = 'all_activity',
  Comments = 'comments_only',
  CandidateAction = 'candidate_actions_only',
}
const ActivityFeedTabLabels = {
  [ActivityFeedTabs.All]: 'All activity',
  [ActivityFeedTabs.Comments]: 'Comments only',
  [ActivityFeedTabs.CandidateAction]: 'Candidate actions only',
};

const TABS = [
  { id: ActivityFeedTabs.All, label: ActivityFeedTabLabels[ActivityFeedTabs.All] },
  { id: ActivityFeedTabs.Comments, label: ActivityFeedTabLabels[ActivityFeedTabs.Comments] },
];

const HAS_NOTE_COMPOSER = {
  [SourceType.SourceTypeTask]: true,
  [SourceType.SourceTypeApplication]: false,
};

const SubscribableSourceTypes = [SourceType.SourceTypeTask];

const ActivityFeed: FC<Props> = ({ sourceId, sourceType, onHasUnsavedNoteChangesChange, onNoteCreated }) => {
  const refetchData = useActivityFeedRefetch(sourceType);

  const [currentTab, setCurrentTab] = useState<ActivityFeedTabs>(ActivityFeedTabs.All);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  const allTabs = useMemo(() => {
    if (sourceType === SourceType.SourceTypeApplication) {
      return [
        ...TABS,
        { id: ActivityFeedTabs.CandidateAction, label: ActivityFeedTabLabels[ActivityFeedTabs.CandidateAction] },
      ];
    }
    return TABS;
  }, [sourceType]);

  const hasNoteComposer = useMemo(() => {
    const ret = HAS_NOTE_COMPOSER[sourceType];

    if (ret === undefined) {
      logError(`${sourceType} needs a composer value`);
      return false;
    }

    return ret;
  }, [sourceType]);

  const getActivityTypes = (): ActivityType[] => {
    if (currentTab === ActivityFeedTabs.Comments) {
      return [ActivityType.Note];
    }
    return [];
  };

  const getActorType = () => {
    if (currentTab === ActivityFeedTabs.CandidateAction) {
      return [ActorType.Candidate];
    }
    return undefined;
  };

  const renderActivityLogsList = () => {
    if (sourceType === SourceType.SourceTypeTask) {
      return <TaskDetailsActivityLogsList taskId={sourceId} activityTypes={getActivityTypes()} />;
    }

    if (sourceType === SourceType.SourceTypeApplication) {
      return (
        <ApplicationDetailsActivityLogsList
          applicationId={sourceId}
          activityTypes={getActivityTypes()}
          actorTypes={getActorType()}
        />
      );
    }

    return null;
  };

  const onCreateNoteSubmit = () => {
    refetchData();
    if (onNoteCreated) onNoteCreated();
  };

  const stackOptions: StackProps = useMemo(() => {
    if (sourceType === SourceType.SourceTypeApplication) {
      return { direction: 'row', justifyContent: 'space-between', alignItems: 'center' };
    }
    return { direction: 'column', spacing: 1 };
  }, [sourceType]);

  return (
    <Stack direction="column" spacing={1} sx={{ height: '100%' }}>
      <Stack {...stackOptions}>
        <Label color="foreground" fontWeight={600}>
          Activity
        </Label>

        <Stack direction="row" justifyContent="space-between">
          <PageTabs
            currentValue={currentTab}
            onChange={(newTab) => {
              setCurrentTab(newTab as ActivityFeedTabs);
            }}
            isNewStyle
          >
            {allTabs.map((tab) => (
              <PageTab key={tab.id} value={tab.id} label={tab.label} />
            ))}
          </PageTabs>

          {SubscribableSourceTypes.includes(sourceType) ? (
            <SubscribersSelect sourceId={sourceId} sourceType={sourceType} showSubscribeButton />
          ) : null}
        </Stack>
      </Stack>

      {hasNoteComposer && (
        <CreateNoteTextField
          sourceId={sourceId}
          sourceType={sourceType}
          onSubmit={onCreateNoteSubmit}
          onChange={(value: string) => {
            if (value && !hasUnsavedChanges) {
              setHasUnsavedChanges(true);
              if (onHasUnsavedNoteChangesChange) onHasUnsavedNoteChangesChange(true);
            } else if (!value && hasUnsavedChanges) {
              setHasUnsavedChanges(false);
              if (onHasUnsavedNoteChangesChange) onHasUnsavedNoteChangesChange(false);
            }
          }}
        />
      )}

      {renderActivityLogsList()}
    </Stack>
  );
};

export default ActivityFeed;
