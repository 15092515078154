import { gql } from '@apollo/client';

import { Permission, useSchedulerPermissionQuery } from 'src/interviewer-portal/generated/graphql';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PERMISSIONS_QUERY = gql`
  query SchedulerPermission {
    actorUserInfo {
      permissions
    }
  }
`;

/**
 * Helper hook to check if the current user has a specific permission
 * @param permission - The permission to check
 * @returns - Whether the user has the permission. Null if loading.
 */
export const useHasPermission = (permission: Permission): { permission: boolean; loading: boolean } => {
  const { data, loading } = useSchedulerPermissionQuery({
    fetchPolicy: 'cache-and-network',
  });
  if (loading) {
    return { permission: false, loading: true };
  }
  return { permission: data?.actorUserInfo?.permissions.includes(permission) || false, loading: false };
};
