import { useIframeDispatch } from './IframeDispatchContext';
import IframeProvider from './IframeProvider';
import { IframeStateActionType } from './types';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { SupportedFonts } from '@modernloop/shared/constants';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StylesProvider, jssPreset } from '@mui/styles';
import { create } from 'jss';
import { startCase } from 'lodash';
import React, { FC, CSSProperties } from 'react';
import ReactDOM from 'react-dom';
import { prefixer } from 'stylis';

// Code derived from:
// https://github.com/mui/material-ui/blob/c3d6309171afc1d0ba7a3a758c3c6268081eece1/docs/src/modules/components/DemoSandbox.js

// eslint-disable-next-line modernloop/restrict-props-name.cjs
type FramedDemoProps = {
  document: Document;
};

// eslint-disable-next-line modernloop/restrict-props-name.cjs
const FramedDemo: FC<FramedDemoProps> = (props) => {
  const { children, document } = props;

  const theme = useTheme();
  React.useEffect(() => {
    document.body.dir = theme.direction;
  }, [document, theme.direction]);

  const { jss, sheetsManager } = React.useMemo(() => {
    return {
      jss: create({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        plugins: [...jssPreset().plugins] as any,
        insertionPoint: document.head,
      }),
      sheetsManager: new Map(),
    };
  }, [document]);

  const cache = React.useMemo(
    () =>
      createCache({
        key: `iframe-demo-${theme.direction}`,
        prepend: true,
        container: document.head,
        stylisPlugins: [prefixer],
      }),
    [document, theme.direction]
  );

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <StylesProvider jss={jss as any} sheetsManager={sheetsManager}>
      <CacheProvider value={cache}>{React.cloneElement(<>{children}</>)}</CacheProvider>
    </StylesProvider>
  );
};

const Iframe = styled('iframe')(() => ({
  height: '100%',
  width: '100%',
  border: 0,
}));

// eslint-disable-next-line modernloop/restrict-props-name.cjs
type IframeContainerProps = {
  name: string;
  width?: CSSProperties['width'];
  font?: SupportedFonts;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/restrict-props-name.cjs
const IframeContainer: FC<IframeContainerProps> = (props) => {
  const { children, name, width, font } = props;
  const frameRef = React.useRef<HTMLIFrameElement>(null);
  const iframeDispatch = useIframeDispatch();

  // If we portal content into the iframe before the load event then that content
  // is dropped in firefox.
  const [iframeLoaded, onLoad] = React.useReducer(() => true, false);

  React.useEffect(() => {
    const iframeDocument = frameRef.current?.contentDocument;
    // When we hydrate the iframe then the load event is already dispatched
    // once the iframe markup is parsed (maybe later but the important part is
    // that it happens before React can attach event listeners).
    // We need to check the readyState of the document once the iframe is mounted
    // and "replay" the missed load event.
    // See https://github.com/facebook/react/pull/13862 for ongoing effort in React
    // (though not with iframes in mind).
    if (iframeDocument != null && iframeDocument.readyState === 'complete' && !iframeLoaded) {
      onLoad();
    }

    if (iframeDocument) {
      if (font) {
        const fontToLoad = startCase(font.toLowerCase()).replace(/\s+/g, '+');
        const fontUrl = `https://fonts.googleapis.com/css2?family=${fontToLoad}:wght@400;500;600&display=swap`;
        const customFontLink = iframeDocument.createElement('link');
        customFontLink.setAttribute('rel', 'stylesheet');
        customFontLink.setAttribute('href', fontUrl);
        iframeDocument.head.appendChild(customFontLink);
      }

      const link = iframeDocument.createElement('link');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
      iframeDocument.head.appendChild(link);

      const link2 = iframeDocument.createElement('link');
      link2.setAttribute('rel', 'stylesheet');
      link2.setAttribute('href', 'https://cdn.quilljs.com/1.3.7/quill.snow.css');
      iframeDocument.head.appendChild(link2);
    }

    iframeDispatch({
      type: IframeStateActionType.SetWindow,
      iframeWindow: frameRef.current?.contentWindow || null,
    });
  }, [font, iframeDispatch, iframeLoaded]);

  const document = frameRef.current?.contentDocument;

  if (document?.body) {
    document.body.style.margin = '0';
  }

  let root: HTMLDivElement | null = null;
  if (document) {
    root = document.createElement('div');
    document.body.appendChild(root);

    const style = document.createElement('style');
    style.innerHTML = `* { box-sizing: border-box; }`;
    document.head.appendChild(style);
  }

  return (
    <>
      {/* eslint-disable-next-line no-restricted-syntax */}
      <Iframe onLoad={onLoad} ref={frameRef} title={`${name} demo`} sx={{ width }} />
      {iframeLoaded !== false && document && root
        ? ReactDOM.createPortal(<FramedDemo document={document}>{children}</FramedDemo>, root)
        : null}
    </>
  );
};

type Props = {
  name: string;
  width?: CSSProperties['width'];
  font?: SupportedFonts;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const IframeContainerWrapper: FC<Props> = ({ name, children, width, font }) => {
  return (
    <IframeProvider>
      <IframeContainer name={name} width={width} font={font}>
        {children}
      </IframeContainer>
    </IframeProvider>
  );
};

export default IframeContainerWrapper;
