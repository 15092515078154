import React from 'react';

import { Box, Stack, useTheme } from '@mui/material';
import { CustomContentProps } from 'notistack';

import Label from '../label';
import { getIconFromEnum } from '../utils/icons';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type ToastProps = CustomContentProps;

const Toast: React.ForwardRefRenderFunction<HTMLDivElement, ToastProps> = (props, ref) => {
  const theme = useTheme();
  const { variant, message } = props;

  const getIcon = () => {
    switch (variant) {
      case 'success':
        return getIconFromEnum('CircleWithCheckIcon', { color: 'success' });
      case 'error':
        return getIconFromEnum('WarningIcon', { color: 'error' });
      case 'warning':
        return getIconFromEnum('WarningIcon', { color: 'warning' });
      case 'info':
        return getIconFromEnum('InfoIcon', { color: 'info' });
      default:
        return null;
    }
  };

  const getHighlight = () => {
    switch (variant) {
      case 'error':
        return (
          <Box height="4px" left="0px" top="0px" bgcolor={theme.palette.error.main} position="absolute" width="100%" />
        );
      case 'success':
        return (
          <Box
            height="4px"
            left="0px"
            top="0px"
            bgcolor={theme.palette.success.main}
            position="absolute"
            width="100%"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      component="div"
      p="12px"
      maxWidth="fit-content"
      borderRadius="6px"
      ref={ref}
      bgcolor={theme.palette.background.default}
      style={{
        boxShadow: theme.shadows[2],
        position: 'relative',
        overflow: 'hidden',
        outline: `1px solid ${theme.grey.alpha.low}`,
        outlineOffset: '-1px',
      }}
    >
      {getHighlight()}
      <Stack direction="row" spacing={0.5} alignItems="center">
        {getIcon()}
        <Label>{message}</Label>
      </Stack>
    </Box>
  );
};

export default React.forwardRef<HTMLDivElement, ToastProps>(Toast);
