import React, { useMemo } from 'react';

import { Alert as MuiAlert, AlertTitle as MuiAlertTitle, SxProps, darken } from '@mui/material';

import { ImageBlockProps } from 'src/components/ImageBlock';
import { BaseProps, createSxProps } from 'src/components/types';

import { Theme } from 'src/themeMui5/type';

import { CheckIcon, ClockIcon, InfoIcon, WarningIcon } from '../icons';

type Severity = 'info' | 'warning' | 'success' | 'error';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface AlertProps extends BaseProps {
  status: Severity | 'pending' | 'neutral';

  /**
   * Required. Title text of the image block
   */
  title: JSX.Element | string;

  /**
   * Optional. Caption text of the image block
   */
  caption?: JSX.Element | string;

  /**
   * Optional. Disable the icon or not
   */
  disableIcon?: boolean;

  /**
   * Optional. sx
   */
  sx?: SxProps<Theme>;

  /**
   * Optional alignItems
   */
  // eslint-disable-next-line react/no-unused-prop-types
  alignItems?: ImageBlockProps['alignItems'];

  /**
   *  Custom icon to show in the alert
   */
  icon?: JSX.Element;

  actions?: JSX.Element;
}

const useSxProps = ({ alignItems, status }: AlertProps) => {
  const sxProps = useMemo(() => {
    return {
      root: {
        borderRadius: '6px',
        padding: '8px 12px',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '18px',
        alignItems: alignItems || 'center',
        '& .MuiAlert-icon': {
          opacity: 1,
          padding: '0px',
          marginRight: '8px',
          marginLeft: '-4px',
        },
        '& .MuiAlert-message .MuiTypography-root': {
          marginTop: '0px',
        },
        color: (theme: Theme) => {
          switch (status) {
            case 'info': {
              return darken(theme.palette.info.main, 0.6);
            }
            case 'warning':
            case 'error':
            case 'success':
            case 'neutral':
            default: {
              return theme.grey.alpha.max;
            }
          }
        },
        backgroundColor: (theme: Theme) => {
          switch (status) {
            case 'info': {
              return theme.palette.background.info;
            }
            case 'warning': {
              return theme.palette.background.warning;
            }
            case 'error': {
              return theme.palette.background.error;
            }
            case 'success': {
              return theme.palette.background.success;
            }
            case 'pending': {
              return theme.palette.background.pending;
            }
            case 'neutral':
            default: {
              return theme.grey.alpha.min;
            }
          }
        },

        '& .MuiAlert-message': {
          overflow: 'unset',
          flexGrow: 1,
        },
      } as SxProps,
      alertTitleRoot: {
        fontSize: '13px',
        fontWeight: 600,
        lineHeight: '18px',
        marginBottom: 'unset',
      } as SxProps,
    };
  }, [alignItems, status]);

  return createSxProps(sxProps);
};

const ICON_MAP: { [key: string]: JSX.Element } = {
  neutral: <InfoIcon color="max-contrast-grey" />,
  info: <InfoIcon color="info" />,
  warning: <InfoIcon color="warning" />,
  error: <WarningIcon color="error" />,
  success: <CheckIcon color="success" />,
  pending: <ClockIcon color="error" />,
};

/**
 * @deprecated - Use `Alert` from `@mui/material` instead
 */
const Alert = (props: AlertProps): JSX.Element => {
  const { status, title, caption, disableIcon, sx, icon, actions, dataTestId } = props;
  const sxProps = useSxProps({ ...props });

  const rootSxProps = useMemo(() => {
    return { ...sxProps.root, ...sx };
  }, [sx, sxProps.root]);

  let alertIcon: JSX.Element | boolean = !disableIcon;
  if (!disableIcon) {
    alertIcon = icon || ICON_MAP[status];
  }

  let severity = status;
  if (status === 'neutral') severity = 'success';
  if (status === 'pending') severity = 'warning';

  return (
    <MuiAlert
      color={status === 'neutral' ? 'success' : status}
      severity={severity as Severity}
      action={actions}
      icon={alertIcon}
      data-testid={dataTestId}
      sx={rootSxProps}
    >
      <MuiAlertTitle sx={sxProps.alertTitleRoot}>{title}</MuiAlertTitle>
      {caption}
    </MuiAlert>
  );
};

export default Alert;
