export enum Categories {
  AllEmployees = 'ALL_EMPLOYEES',
  InterviewPanel = 'INTERVIEW_PANEL',
  SmartOptions = 'SMART_OPTIONS',
}

export enum SmartOptions {
  Interviewer = 'INTERVIEWER',
  Scheduler = 'SCHEDULER',
}

export const SMART_OPTIONS_INTERVIEWERS_ID = 'SMART_OPTIONS_INTERVIEWERS_ID';
export const SMART_OPTIONS_SCHEDULER_ID = 'SMART_OPTIONS_SCHEDULER_ID';
