import React, { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import Box from '@material-ui/core/Box';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Calendar } from '@material-ui/pickers';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports, modernloop/restrict-imports.cjs
import { CalendarProps } from '@material-ui/pickers/views/Calendar/Calendar';
import { guessIsoTimestamp } from '@modernloop/shared/datetime';
import clsx from 'clsx';
import moment from 'moment';

import Label from 'src/components/label';

import IsoTimestamp, { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { isFuture, isPast } from 'src/utils/datetime/Comparision';
import { endOfDay, getTimeInTimezone, startOfDay } from 'src/utils/datetime/Conversions';

import { Theme } from 'src/theme';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type BaseDatePickerProps = {
  /**
   * Default date to initialize the date time picker.
   */
  utcDate: IsoTimestamp;

  minDate?: IsoTimestamp;

  maxDate?: IsoTimestamp;

  timezone: string;

  dataTestId?: string;

  className?: string;

  renderDay?: CalendarProps['renderDay'];

  onChange: (time: IsoTimestamp) => void;

  disablePast?: CalendarProps['disablePast'];

  disableFuture?: CalendarProps['disableFuture'];

  shouldDisableDate?: (day: IsoTimestamp) => boolean;
};

export const useDateButtonStyle = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '6px',
      justifyContent: `stretch`,
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      '& .MuiPickersCalendarHeader-switchHeader': {
        '& .MuiPickersCalendarHeader-transitionContainer': {
          '& p': {
            fontWeight: 600,
          },
        },
      },
    },
    calendarButton: {
      '& button': {
        height: '32px',
        width: '32px',
        margin: '2px 4px',
      },
    },
    calendarButtonBorder: {
      '& button': {
        border: `1px solid ${theme.palette.border}`,
        borderRadius: '50%',
      },
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const BaseDatePicker: FC<BaseDatePickerProps> = ({
  utcDate,
  minDate,
  maxDate,
  timezone,
  className,
  dataTestId,
  renderDay,
  onChange,
  disablePast,
  disableFuture,
  shouldDisableDate,
}: BaseDatePickerProps) => {
  const classes = useStyles();

  const handleSelect = (newDate: moment.Moment) => {
    // const timestamp = startOfDay(assertIsoTimestamp(newDate.toISOString()), timezone);
    const date = newDate.toDate();
    const timestamp = getTimeInTimezone(
      guessIsoTimestamp(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`),
      timezone
    );
    onChange(timestamp);
  };

  const defaultRenderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
    // eslint-disable-next-line max-params
  ) => {
    let showCalendarButtonBorder = true;
    if (disablePast && day) {
      showCalendarButtonBorder = !isPast(endOfDay(assertIsoTimestamp(day.toISOString()), timezone));
    }
    if (disableFuture && day) {
      showCalendarButtonBorder = !isFuture(startOfDay(assertIsoTimestamp(day.toISOString()), timezone));
    }

    const defaultDayJsx = (
      <Label
        dataTestId="multiple-date-picker-default-day"
        className={clsx(classes.calendarButton, { [classes.calendarButtonBorder]: showCalendarButtonBorder })}
      >
        {dayComponent}
      </Label>
    );

    return defaultDayJsx;
  };

  const handleShouldDisableDate = (day: MaterialUiPickersDate): boolean => {
    if (!shouldDisableDate || !day) return false;

    return shouldDisableDate(assertIsoTimestamp(day.toDate().toISOString()));
  };

  const disablePastValue = disablePast ?? true;
  const disableFutureValue = disableFuture ?? false;
  // TODO: Figure out a better behaviour here, maybe not render.
  const nonEmptyUTCTime = utcDate || assertIsoTimestamp(new Date().toISOString());
  return (
    <Box className={clsx(classes.root, className)} data-testid={dataTestId}>
      <Calendar
        allowKeyboardControl
        date={moment(nonEmptyUTCTime)}
        disableFuture={disableFutureValue}
        disablePast={disablePastValue}
        minDate={minDate ? moment(minDate) : undefined}
        maxDate={maxDate ? moment(maxDate) : undefined}
        renderDay={renderDay || defaultRenderDay}
        onChange={handleSelect}
        shouldDisableDate={handleShouldDisableDate}
      />
    </Box>
  );
};

export default BaseDatePicker;
