import React from 'react';

import BaseIcon, { Props } from './Icon';

const RsvpNo = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} tiny>
      <circle cx="7" cy="6.99999" r="6.33333" fill="#FDEBEB" stroke="white" />
      <path d="M4.5 9.5L9.5 4.5" stroke="#BC130C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.5 9.5L4.5 4.5" stroke="#BC130C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </BaseIcon>
  );
};

export default RsvpNo;
