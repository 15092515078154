import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ModernLoopFooterIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 27 27'}>
      <path
        d="M0 6C0 2.68629 2.68629 0 6 0H20.6667C23.9804 0 26.6667 2.68629 26.6667 6V20.6667C26.6667 23.9804 23.9804 26.6667 20.6667 26.6667H6C2.68629 26.6667 0 23.9804 0 20.6667V6Z"
        fill="white"
      />
      <path
        d="M17.9516 14.9161C17.9516 15.599 18.5207 16.1682 19.2036 16.1682C19.9056 16.1682 20.4747 15.599 20.4747 14.9161V7.91582C20.4747 7.34668 20.0763 6.85343 19.5261 6.70167C18.976 6.56887 18.3879 6.8155 18.1223 7.30873L13.171 16.4716L8.35239 7.32756C8.08678 6.81534 7.49869 6.56872 6.94854 6.7015C6.37941 6.85327 6 7.34652 6 7.91565V18.6912C6 19.3742 6.56913 19.9433 7.25207 19.9433C7.954 19.9433 8.52314 19.3742 8.52314 18.6912V12.2601L12.0516 19.2795C12.2793 19.6968 12.6966 19.9434 13.1519 19.9434C13.6072 19.9434 14.0246 19.6968 14.2522 19.2984L17.9516 12.1464V14.9161ZM20.4746 18.6914C20.4746 17.9895 19.9055 17.4203 19.2036 17.4203C18.5206 17.4203 17.9515 17.9895 17.9515 18.6914C17.9515 19.3744 18.5206 19.9435 19.2036 19.9435C19.9055 19.9435 20.4746 19.3743 20.4746 18.6914Z"
        fill="#01458E"
      />
    </SvgIcon>
  );
};
