/* eslint-disable max-lines */
import {
  addDays,
  areIntervalsOverlapping,
  endOfDay,
  endOfToday,
  format,
  isSameDay,
  parseISO,
  setDay,
  startOfDay,
  startOfToday,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { filter, isEmpty, uniq, uniqBy } from 'lodash';

import { DateTimeRangeInput, InterviewerFlag, InterviewerRole, TimeBlockWeek } from 'src/generated/mloop-graphql';

import { CalendarBusinessHours, CalendarEvent, CalendarResource } from 'src/components/calendar';

import { assertIsoTimestamp } from 'src/types/IsoTimestamp';
import { TimeBlock } from 'src/types/preferenes';

import { CalendarFreeBusyStatus, Event, EventTag, RSVP } from 'src/utils/api/getEmployeeCalendarEvents';
import { InterviewSchedule, RichInterviewer } from 'src/utils/api/getScheduleOptions';
import { endOfDay as endOfDayTz, startOfDay as startOfDayTz } from 'src/utils/dateUtils';
import { getTimeInTimezone } from 'src/utils/datetime/Conversions';

import { getTextForFlag, isEventBasedFlag } from '../ScheduleOptions/utils';

import { EmployeeWorkHoursById, InterviewerSuggestion } from './types';

const SEPARATOR = '_';

export enum CalendarEventType {
  CANDIDATE_EVENT = 'candidate_event',
  INTERVIEWER_EVENT = 'interviewer_event',
  INTERVIEWER_CALENDAR_EVENTS = 'interviewer_calendar_events',
}

export type EmployeeCalendarEventsById = { [employeeId: string]: Event[] };

/**
 * Converts work hours from interviewers timezone to candidate timezone
 * assumes non-overlapping times since the UI has checks for it.
 * @param interviewerWorkHoursWeek
 * @param interviewerTimezone Time zone the interviewerWorkHoursWeek are in
 * @param candidateTimezone Time zone the interviewerWorkHoursWeek need to be converted to.
 * @returns Work hours in candidate timezone
 */
export const getFullCalendarWorkHours = (
  interviewerWorkHoursWeek: TimeBlockWeek,
  interviewerTimezone: string,
  candidateTimezone: string,
  skipTZConversionForEmployeesWorkHours = false
  // eslint-disable-next-line max-params
) => {
  // Initializing this explicitly because interviewerWorkHoursWeek has extra properties like __typename
  // which causes exception while iterating
  const sortedWorkHourWeek: TimeBlockWeek = {
    sunday: interviewerWorkHoursWeek.sunday,
    monday: interviewerWorkHoursWeek.monday,
    tuesday: interviewerWorkHoursWeek.tuesday,
    wednesday: interviewerWorkHoursWeek.wednesday,
    thursday: interviewerWorkHoursWeek.thursday,
    friday: interviewerWorkHoursWeek.friday,
    saturday: interviewerWorkHoursWeek.saturday,
  };

  const calendarWorkHours: CalendarBusinessHours = Object.keys(sortedWorkHourWeek).map(
    (day: string, dayIndex: number) => {
      /**
       * The below loop converts the work hours from interviewer timezone to candidate timezone.
       */
      const workHours: ({ daysOfWeek: number[]; startTime: string; endTime: string } | null)[] = sortedWorkHourWeek[day]
        .map((timeBlock: TimeBlock) => {
          const startHourParts = timeBlock.start.split(':');
          const endHourParts = timeBlock.end.split(':');

          // The start and end date are in browser timezone.
          // We just assume that it is in interviewerTimezone
          // and then convert it to candidateTimezone to get correct start and end time.
          const start = setDay(new Date(), dayIndex);
          start.setHours(parseInt(startHourParts[0], 10), parseInt(startHourParts[1], 10));

          const end = setDay(new Date(), dayIndex);
          end.setHours(parseInt(endHourParts[0], 10), parseInt(endHourParts[1], 10));

          const startCandidateTz = skipTZConversionForEmployeesWorkHours
            ? start
            : utcToZonedTime(zonedTimeToUtc(start, interviewerTimezone), candidateTimezone);
          const startTime = format(startCandidateTz, 'HH:mm');

          const endCandidateTz = skipTZConversionForEmployeesWorkHours
            ? end
            : utcToZonedTime(zonedTimeToUtc(end, interviewerTimezone), candidateTimezone);
          const endTime = format(endCandidateTz, 'HH:mm');

          const startDay = startCandidateTz.getDay();
          const endDay = endCandidateTz.getDay();

          if (startDay !== endDay) {
            // This means the work hours span 2 days and we need to split it into 2 work hours range.
            return [
              {
                daysOfWeek: [startDay],
                startTime,
                endTime: '24:00', // To indicate end of day for full calendar.
              },
              {
                daysOfWeek: [endDay],
                startTime: '00:00', // To indicate start of day for full calendar.
                endTime,
              },
            ];
          }

          return [
            {
              daysOfWeek: [startDay],
              startTime,
              // endTime === '00:00' basically implies start of next day.
              // But we want calendar to interpret it as end of current day so setting it to '24:00'
              endTime: endTime === '00:00' ? '24:00' : endTime,
            },
          ];
        })
        .flat();

      return workHours;
    }
  );

  // False indicates no workHours to be shown on calendar
  if (calendarWorkHours.flat().length === 0) return false;

  return calendarWorkHours.flat();
};

const getIntervalForAllDayEvents = (event: Event) => {
  const start = parseISO(event.start);
  const end = parseISO(event.end);
  return { start, end };
};

export const getCalendarResourcesFromSchedule = (
  activeDay: number,
  schedule: InterviewSchedule,
  employeeCalendarEventsById: EmployeeCalendarEventsById,
  employeesWorkHoursById: EmployeeWorkHoursById,
  timezone: string,
  skipTZConversionForEmployeesWorkHours = false // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
CalendarResource[] => {
  const resources: CalendarResource[] = [];
  resources.push({
    id: schedule.id,
    order: 0,
  });

  if (!schedule.events) return resources;

  let interviewers: RichInterviewer[] = [];
  schedule.events.forEach((event) => {
    if (!event.interviewers) return;
    event.interviewers.forEach((interviewer) => interviewers.push(interviewer));
  });

  if (interviewers.length && interviewers[0].employee) {
    interviewers = uniqBy(interviewers, 'employee.id');
  } else {
    interviewers = uniqBy(interviewers, 'employeeID');
  }

  interviewers.forEach((interviewer) => {
    let allDayEventCount = 0;
    if (!isEmpty(employeeCalendarEventsById)) {
      const employeeCalendarEvent = employeeCalendarEventsById[interviewer.employee.id];
      const calendarEventMasterEventIds: string[] = [];
      if (!isEmpty(employeeCalendarEvent) && employeeCalendarEvent) {
        employeeCalendarEvent.forEach((value) => {
          /**
           * Using master_event_id as a uniqueness check for all day recurring events because
           * the `fe/calendar/listEvent` api returns unique events for each recurrence but
           * FullCalendar is showing only once and this is causing the allDayEventCount mismatch.
           * https://linear.app/modernloop/issue/TASK-1480/incorrect-all-day-event-count-in-calendar-view-sometimes
           */
          const eventId = value.master_event_id || value.uid;
          if (!eventId) return;
          if (value.is_all_day && !calendarEventMasterEventIds.includes(eventId)) {
            // We have to check if the all day events actually overlap with the active day.
            const { start, end } = getIntervalForAllDayEvents(value);
            const shouldAdd = areIntervalsOverlapping(
              { start, end },
              { start: startOfDayTz(activeDay, timezone), end: endOfDayTz(activeDay, timezone) }
            );
            if (shouldAdd) {
              allDayEventCount += 1;
              calendarEventMasterEventIds.push(eventId);
            }
          }
        });
      }
    }

    // Calculating business hours for each interviewer
    const interviewerTimezone = interviewer.employee.timezone ?? 'UTC';
    const workHours = employeesWorkHoursById[interviewer.employee.id];

    const businessHours: CalendarBusinessHours = workHours
      ? getFullCalendarWorkHours(workHours, interviewerTimezone, timezone, skipTZConversionForEmployeesWorkHours)
      : [];

    resources.push({
      id: interviewer.employee?.id,
      title: interviewer.employee?.fullName || undefined,
      interviewer,
      allDayEventCount,
      businessHours,
    });
  });

  return resources;
};

export const getOverlappingInterviewEventsFromSchedule = (
  schedule: InterviewSchedule,
  options?: { ignoreHiddenEvents: boolean }
) => {
  const { ignoreHiddenEvents = true } = options || {};
  return schedule.events.filter((event) => {
    if (event.isBreak) return false;
    return schedule.events.some((otherEvent) => {
      // Ignore same event.
      if (event.slotId === otherEvent.slotId) return false;

      // Ignore if any of the event is hidden. We allow hidden events to overlap with each other and non-hidden events.
      if (ignoreHiddenEvents && (event.isHiddenFromCandidate || otherEvent.isHiddenFromCandidate)) return false;

      return areIntervalsOverlapping(
        { start: parseISO(event.startAt), end: parseISO(event.endAt) },
        { start: parseISO(otherEvent.startAt), end: parseISO(otherEvent.endAt) }
      );
    });
  });
};

export const getCalendarEventsFromSchedule = (
  schedule: InterviewSchedule,
  employeeCalendarEventsById: EmployeeCalendarEventsById,
  timezone: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
CalendarEvent[] => {
  const events: CalendarEvent[] = [];

  if (!schedule.events) return events;

  const overlappingInterviewEvents = getOverlappingInterviewEventsFromSchedule(schedule);
  const allOverlappingInterviewEvents = getOverlappingInterviewEventsFromSchedule(schedule, {
    ignoreHiddenEvents: false,
  });

  schedule.events.forEach((event) => {
    const { slotId } = event;
    if (!slotId || event.isBreak) return;

    const isOverlappingWithNonHiddenEvent =
      overlappingInterviewEvents.findIndex((value) => value.slotId === slotId) !== -1;
    const isOverlappingWithHiddenEvent =
      allOverlappingInterviewEvents.findIndex((value) => value.slotId === slotId) !== -1;

    events.push({
      resourceId: schedule.id,
      id: slotId,
      groupId: slotId,
      title: event.name,
      start: event.startAt,
      end: event.endAt,
      timezone,
      event,
      type: CalendarEventType.CANDIDATE_EVENT,
      isConflict: isOverlappingWithNonHiddenEvent,
      isConflictWithHiddenEvent: !isOverlappingWithNonHiddenEvent && isOverlappingWithHiddenEvent,
    });

    if (!event.interviewers) return;

    const eventStartAt = parseISO(event.startAt);
    const eventEndAt = parseISO(event.endAt);

    event.interviewers.forEach((interviewer) => {
      const employeeId = interviewer.employee?.id;
      events.push({
        resourceId: employeeId,
        id: `${slotId}${SEPARATOR}${employeeId}`,
        groupId: slotId,
        title: event.name,
        start: eventStartAt,
        end: eventEndAt,
        timezone: interviewer.employee?.timezone,
        event,
        type: CalendarEventType.INTERVIEWER_EVENT,
      });

      if (isEmpty(employeeCalendarEventsById)) return;
      const employeeCalendarEvent = employeeCalendarEventsById[employeeId];
      if (!employeeCalendarEvent || employeeCalendarEvent.length === 0) return;

      employeeCalendarEvent.forEach((employeeEvent) => {
        if (!employeeEvent.start || !employeeEvent.end) return;

        let employeeEventStart = parseISO(employeeEvent.start);
        let employeeEventEnd = parseISO(employeeEvent.end);

        if (employeeEvent.is_all_day && interviewer.employee?.timezone) {
          // This may be better as interviewer timezone, unclear.
          const { start: allDayStartAt, end: allDayEndAt } = getIntervalForAllDayEvents(employeeEvent);
          employeeEventStart = allDayStartAt;
          employeeEventEnd = allDayEndAt;
        }

        const isConflict =
          areIntervalsOverlapping(
            { start: eventStartAt, end: eventEndAt },
            { start: employeeEventStart, end: employeeEventEnd }
          ) &&
          employeeEvent.rsvp !== RSVP.RSVP_DECLINED &&
          employeeEvent.free_busy !== CalendarFreeBusyStatus.STATUS_FREE &&
          !employeeEvent.event_tag?.includes(EventTag.RECRUITING_BLOCK) &&
          !employeeEvent.event_tag?.includes(EventTag.AVAILABLE_TIME_BLOCK);

        const employeeEventUid =
          employeeEvent.uid ||
          `${employeeEvent.start}-${employeeEvent.end}-${employeeEvent.location || ''}-${employeeEvent.title || ''}`;
        // Check if the event is already been added for a particular interviewer
        const index = events.findIndex((value) => {
          const eventUid =
            value.employeeEvent?.uid ||
            `${value.employeeEvent?.start}-${value.employeeEvent?.end}-${employeeEvent.location || ''}-${
              value.employeeEvent?.title || ''
            }`;
          return eventUid === employeeEventUid && value.resourceId === employeeId;
        });

        if (index === -1) {
          events.push({
            resourceId: employeeId,
            id: `${slotId}${SEPARATOR}${employeeId}${SEPARATOR}${employeeEvent.uid}`,
            title: employeeEvent.is_private ? 'Busy' : employeeEvent.title ?? 'No title',
            start: employeeEventStart.getTime(),
            end: employeeEventEnd.getTime(),
            allDay: employeeEvent.is_all_day,
            timezone: interviewer.employee?.timezone,
            editable: false,
            startEditable: false,
            durationEditable: false,
            // url: employeeEvent.web_link,
            employeeEvent,
            interviewer,
            type: CalendarEventType.INTERVIEWER_CALENDAR_EVENTS,
            isConflict,
          });
        } else {
          events[index].isConflict = events[index].isConflict || isConflict;
        }
      });
    });
  });

  return events;
};

/**
 * Get all the days that events of the schedule START or END on.
 * This is good for UI that needs to list out just the days the events start on.
 * This WILL return a separate day that an event bleeds over into the next day. ex. 11am - 12:30pm
 * Examples of when to use this: Calendar view date selectors.
 */
export const getUniqueEventDaysFromSchedule = (schedule: InterviewSchedule, timezone: string): number[] => {
  let prevDay: number | null = null;
  const days: number[] = [];

  if (!schedule.events) return days;

  const events = schedule.events.slice().sort((a, b) => parseISO(a.startAt).getTime() - parseISO(b.startAt).getTime());

  events.forEach((event) => {
    if (event.isBreak) return;

    // Have to parseISO because `event.startAt` is in milliseconds and `start_at` is in ISO string
    //
    // Have to use utcToZonedTime because we want to get the midnight time for day which is then converted to UTC
    // This conversion ensures that we calculate correct number of days when timezone changes
    // TODO: convert to timezone aware dateUtils startOfDay and isSameDay
    const startAt = utcToZonedTime(parseISO(event.startAt), timezone).getTime();
    if (prevDay === null || !isSameDay(prevDay, startAt)) {
      days.push(zonedTimeToUtc(startOfDay(startAt), timezone).getTime());
      prevDay = startAt;
    }

    // TODO: convert to timezone aware dateUtils startOfDay and isSameDay
    const endAt = utcToZonedTime(parseISO(event.endAt), timezone).getTime();
    if (prevDay === null || !isSameDay(prevDay, endAt)) {
      days.push(zonedTimeToUtc(startOfDay(endAt), timezone).getTime());
      prevDay = endAt;
    }
  });

  return days;
};

/**
 * Get all the days that events of the schedule START on.
 * This is good for UI that needs to list out just the days the events start on.
 * This will NOT return a day that an event bleeds over into the next day. ex. 11am - 12:30pm
 * Examples of when to use this: Booking meeting rooms for the days.
 */
export const getUniqueEventDaysFromScheduleEventStarts = (schedule: InterviewSchedule, timezone: string): number[] => {
  let prevDay: number | null = null;
  const days: number[] = [];

  if (!schedule.events) return days;

  const events = schedule.events.slice().sort((a, b) => parseISO(a.startAt).getTime() - parseISO(b.startAt).getTime());

  events.forEach((event) => {
    if (event.isBreak) return;

    // Have to parseISO because `event.startAt` is in milliseconds and `start_at` is in ISO string
    //
    // Have to use utcToZonedTime because we want to get the midnight time for day which is then converted to UTC
    // This conversion ensures that we calculate correct number of days when timezone changes
    // TODO: convert to timezone aware dateUtils startOfDay and isSameDay
    const startAt = utcToZonedTime(parseISO(event.startAt), timezone).getTime();
    if (prevDay === null || !isSameDay(prevDay, startAt)) {
      days.push(zonedTimeToUtc(startOfDay(startAt), timezone).getTime());
      prevDay = startAt;
    }
  });

  return days;
};

export const getBusinessHoursFromCandidateAvailability = (
  candidateAvailabilities: DateTimeRangeInput[],
  candidateTimezone: string,
  businessDay: number // UTC // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
CalendarBusinessHours => {
  const businessDayZonedTime = utcToZonedTime(businessDay, candidateTimezone);
  const dayOfWeek = businessDayZonedTime.getDay();

  const businessHours = filter(
    candidateAvailabilities.map((availability) => {
      let startTime = utcToZonedTime(parseISO(availability.startAt), candidateTimezone);
      let endTime = utcToZonedTime(parseISO(availability.endAt), candidateTimezone);

      // If no portion of availability overlaps then return null
      if (!isSameDay(businessDayZonedTime, startTime) && !isSameDay(businessDayZonedTime, endTime)) return null;

      // If second half of availability overlaps
      if (!isSameDay(businessDayZonedTime, startTime)) {
        // TODO: convert to timezone aware dateUtils startOfDay and endOfDay
        startTime = startOfDay(businessDayZonedTime);
      }

      // If first half of availability overlaps
      if (!isSameDay(businessDayZonedTime, endTime)) {
        // TODO: convert to timezone aware dateUtils startOfDay and endOfDay
        endTime = endOfDay(businessDayZonedTime);
      }

      const endStr = format(endTime, 'HH:mm');

      /**
       * If the time range is empty then return null.
       * This happens for the case where previous days availability ends on 12am midnight for current day.
       * The maximum end time for any time range is end of day for that timezone and we represent it
       * as start of day for next day in said timezone (00:00:000).
       *
       * Few reasons for using start of next day as end of current day:
       *  - This is to prevent displaying (23:59) in time picker if (23:59:999) was used as end time.
       *  - Also our schedule generation logic would not be able to find good options if we use (23:59:999) as end time.
       *  - Full calendar would show a small gap of 1 minute if work hours end at `23:59`, it needs 24:00 to show availability till end of day.
       *  - Even Google seems to be using 12 AM of next day to represent end of current day.
       *
       * Using start of next day to represent end of current day causes the endTime for workHours to be 00:00
       * So now we end up having work hours as `00:00 - 00:00` which is an empty time range and full calendar does not render it.
       * To prevent this if endTime workHours is 00:00 then we return 24:00.
       * In this particular case, because of above logic, when time range is empty and start & end time are start of day we end up
       * calculating the work hours as 00:00 - 24:00 hence the candidate appear to be available all day.
       *
       * e.g. If we have 2 availabilities [May 1, 11 PM - May 2, 12 AM] &  [May 2, 12 AM - May 2, 1 AM]
       *      in this case [May 1, 11 PM - May 2, 12 AM] availability returns startTime = May 2, 12 AM & endTime = May 2, 12 AM
       *      when calculating work hours for May 2 because the only thing that overlaps is 12 AM.
       */
      if (startTime.getTime() === endTime.getTime()) return null;

      return {
        start: format(startTime, 'HH:mm'),
        // Need this hack to format end of day as `24:00` because FullCalendar adds an extra space at the bottom which causes
        // the time at the left (eg. 11pm) to be misaligned with rest of the table.
        end: endStr === '23:59' ? '24:00' : endStr,
      } as TimeBlock;
    }),
    (value) => !!value
  ) as TimeBlock[];

  if (businessHours.length === 0 || dayOfWeek === -1) return [{ daysOfWeek: [] }];

  return getFullCalendarWorkHours(
    {
      sunday: dayOfWeek === 0 ? businessHours : [],
      monday: dayOfWeek === 1 ? businessHours : [],
      tuesday: dayOfWeek === 2 ? businessHours : [],
      wednesday: dayOfWeek === 3 ? businessHours : [],
      thursday: dayOfWeek === 4 ? businessHours : [],
      friday: dayOfWeek === 5 ? businessHours : [],
      saturday: dayOfWeek === 6 ? businessHours : [],
    },
    candidateTimezone,
    candidateTimezone
  );
};

export const getEventIdFromCalendarEventId = (eventId: string): string => {
  if (eventId.indexOf(SEPARATOR) === -1) return eventId;

  const parts = eventId.split(SEPARATOR);
  if (parts.length >= 1) return parts[0];

  return '';
};

export const getEmployeeIdFromCalendarEventId = (eventId: string): string => {
  if (eventId.indexOf(SEPARATOR) === -1) return eventId;

  const parts = eventId.split(SEPARATOR);
  if (parts.length >= 2) return parts[1];

  return '';
};

export const getUniqueEmployeeIdsFromSchedule = (schedule: InterviewSchedule): string[] => {
  if (!schedule || !schedule.events) return [];

  const employeeIds: string[] = [];

  schedule.events.forEach((event) => {
    if (!event.interviewers) return;
    event.interviewers.forEach((interviewer) => employeeIds.push(interviewer.employee?.id));
  });

  return uniq(employeeIds);
};

export const getStartAndEndTimeFromSchedule = (
  schedule: InterviewSchedule,
  timezone: string
): { startTime: number; endTime: number } => {
  // TODO: convert to timezone aware dateUtils startOfToday endOfToday
  if (!schedule || !schedule.events) return { startTime: startOfToday().getTime(), endTime: endOfToday().getTime() };

  let startTime = 0;
  let endTime = 0;

  schedule.events.forEach((event) => {
    // Have to parseISO because `event.startAt` is in milliseconds and `start_at` is in ISO string
    const startAt = utcToZonedTime(parseISO(event.startAt), timezone).getTime();
    if (startTime === 0 || startAt < startTime) {
      startTime = startAt;
    }

    const endAt = utcToZonedTime(parseISO(event.endAt), timezone).getTime();
    if (endTime === 0 || endAt > endTime) {
      endTime = endAt;
    }
  });

  return {
    // TODO: convert to timezone aware dateUtils startOfDay and endOfDay
    startTime: zonedTimeToUtc(startOfDay(startTime), timezone).getTime(),
    endTime: zonedTimeToUtc(endOfDay(endTime), timezone).getTime(),
  };
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getFirstEventStartTimeForDay = (schedule: InterviewSchedule, day: number, timezone: string): number => {
  let startTime = 0;
  const dayTz = utcToZonedTime(day, timezone);

  if (schedule.events && schedule.events.length > 0) {
    const times = schedule.events
      .map((event) => {
        // Conversion to zoned time is required to get the correct start time for first event for given timezone.
        const startAt = utcToZonedTime(parseISO(event.startAt), timezone);
        if (!isSameDay(dayTz, startAt)) return 0;
        return zonedTimeToUtc(startAt, timezone).getTime();
      })
      .filter((value) => value !== 0)
      .sort();
    [startTime] = times;
  }

  return startTime;
};

export const getHasAllDay = (
  employeeEventsById: EmployeeCalendarEventsById,
  activeDay: number,
  timezone: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
boolean => {
  return (
    Object.keys(employeeEventsById).findIndex((key) => {
      if (!employeeEventsById[key] || !employeeEventsById[key].length) return false;

      return (
        employeeEventsById[key].findIndex((event) => {
          const { start, end } = getIntervalForAllDayEvents(event);
          const isOverlapping = areIntervalsOverlapping(
            { start, end },
            { start: startOfDayTz(activeDay, timezone), end: endOfDayTz(activeDay, timezone) }
          );
          return isOverlapping && event.is_all_day;
        }) !== -1
      );
    }) !== -1
  );
};

export const getInterviewerEmailsFromSchedule = (schedule: InterviewSchedule | null): string[] => {
  const interviewerEmails: string[] = [];
  if (schedule && schedule.events) {
    schedule.events.forEach((interviewEvent) => {
      interviewEvent.interviewers.forEach((interviewer) => {
        if (interviewer.employee.email) {
          interviewerEmails.push(interviewer.employee.email);
        }
      });
    });
  }

  return interviewerEmails;
};

export const getInterviewerIdsFromSchedule = (schedule: InterviewSchedule | null): string[] => {
  const interviewerIds: string[] = [];
  if (schedule && schedule.events) {
    schedule.events.forEach((interviewEvent) => {
      interviewEvent.interviewers.forEach((interviewer) => {
        if (interviewer.employeeId) {
          interviewerIds.push(interviewer.employeeId);
        }
      });
    });
  }

  return uniq(interviewerIds);
};

/**
 * Finds the end time of the last interview on a given day
 * @param schedule
 * @param timezone
 * @param day Unix Epoc milliseconds
 * @returns Unix Epoc milliseconds representing end time of last interview on the passed day or null.
 *   NOTE: The last interview of THIS day must end in THIS day.
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getScheduleEndTimeForDay = (schedule: InterviewSchedule, day: number, timezone: string): number => {
  let maxEndAt = 0;
  const dayTz = utcToZonedTime(day, timezone);

  // If there are no events in the schedules then pick 9AM next day as start time.
  if (!schedule.events || schedule.events.length === 0) {
    let tomorrow = addDays(new Date(), 1);
    tomorrow = parseISO(getTimeInTimezone(assertIsoTimestamp(tomorrow.toISOString()), timezone, 9, 0));
    return tomorrow.getTime();
  }

  const events = schedule.events.slice().sort((a, b) => parseISO(a.startAt).getTime() - parseISO(b.startAt).getTime());

  events.forEach((event) => {
    const endAt = utcToZonedTime(parseISO(event.endAt), timezone).getTime();
    if (maxEndAt === null || (isSameDay(endAt, dayTz) && maxEndAt < endAt)) {
      maxEndAt = endAt;
    }
  });

  // If there are no events in the schedule then pick 9AM from the day as start time.
  if (maxEndAt === 0) {
    return parseISO(getTimeInTimezone(assertIsoTimestamp(dayTz.toISOString()), timezone, 9, 0)).getTime();
  }

  return zonedTimeToUtc(new Date(maxEndAt), timezone).getTime();
};

/**
 * Finds the end time of the last interview on a given day
 * @param schedule
 * @param timezone
 * @param day Unix Epoc milliseconds
 * @returns Unix Epoc milliseconds representing end time of last interview on the passed day or null.
 *   NOTE: The last interview of THIS day may end in the NEXT day.
 *   Ex. for an interview that is 11pm-12:30am this function return 12:30am of the next day.
 */
export const getScheduleEndTimeForDayEventEnds = (
  schedule: InterviewSchedule,
  day: number,
  timezone: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
number => {
  let maxEndAt = 0;
  const dayTz = utcToZonedTime(day, timezone);

  // If there are no events in the schedules then pick 9AM next day as start time.
  if (!schedule.events || schedule.events.length === 0) {
    let tomorrow = addDays(new Date(), 1);
    tomorrow = parseISO(getTimeInTimezone(assertIsoTimestamp(tomorrow.toISOString()), timezone, 9, 0));
    return tomorrow.getTime();
  }

  const events = schedule.events.slice().sort((a, b) => parseISO(a.startAt).getTime() - parseISO(b.startAt).getTime());

  events.forEach((event) => {
    const startAt = utcToZonedTime(parseISO(event.startAt), timezone).getTime();
    const endAt = utcToZonedTime(parseISO(event.endAt), timezone).getTime();
    // We check the start time values to see if the interview starts on this day
    if (maxEndAt === null || (isSameDay(startAt, dayTz) && maxEndAt < endAt)) {
      maxEndAt = endAt;
    }
  });

  return zonedTimeToUtc(new Date(maxEndAt), timezone).getTime();
};

const pullFlagToFront = (arr: InterviewerFlag[], item: InterviewerFlag) => {
  if (!arr.includes(item)) {
    return arr;
  }
  const newArr = arr.filter((a) => a !== item);
  newArr.unshift(item);
  return newArr;
};

export const getInterviewerAvailabilityStatus = (interviewer?: RichInterviewer): string => {
  const freeAtThisTime = 'Free at this time';

  if (!interviewer) return freeAtThisTime;

  const { flags, eventsWithConflicts } = interviewer;
  const events = eventsWithConflicts || [];
  if (!flags || flags.length === 0) return freeAtThisTime;

  const captions: string[] = [];
  const modifiedFlags = pullFlagToFront(pullFlagToFront(flags, InterviewerFlag.Ooo), InterviewerFlag.Paused).filter(
    (flag) => !isEventBasedFlag(flag)
  );

  // Non-event-based flags
  for (const flag of modifiedFlags) {
    const text = getTextForFlag(
      { companyHoliday: interviewer?.companyHolidays?.length ? interviewer?.companyHolidays[0] : null },
      { flag, eventsWithConflict: events }
    );
    if (text) {
      captions.push(text);
    }
  }

  // Event-based flags
  if (events.length) {
    captions.push(`Busy`);

    events.forEach((eventWithConflict) => {
      captions.splice(captions.length, 0, ...(eventWithConflict.events || []));
    });
  }

  return captions.length > 0 ? captions.join(' · ') : freeAtThisTime;
};

export const getRichInterviewerFromSuggestion = (interviewer: InterviewerSuggestion): RichInterviewer => {
  const richInterviewer: RichInterviewer = {
    employeeId: interviewer?.employeeId || '',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    employee: interviewer?.employee as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventsWithConflicts: interviewer?.eventsWithConflicts ? (interviewer?.eventsWithConflicts as any) : [],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    flags: interviewer?.flags ? (interviewer?.flags as any[]) : undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    role: (interviewer?.role as any) || InterviewerRole.Interviewer,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadAndLimit: { employeeId: interviewer?.employeeId, ...interviewer?.loadAndLimit } as any,
    companyHolidays: interviewer?.companyHolidays,
  };

  return richInterviewer;
};
