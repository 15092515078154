import { useCallback } from 'react';

import { gql } from '@apollo/client';

import {
  RecentlyUsedZoomUsersPref,
  useUpdateRecentlyUsedZoomUsersEmployeePrefMutation,
} from 'src/generated/mloop-graphql';

import useEmployeeId from 'src/hooks/useEmployeeId';

import { EmployeePrefName } from 'src/utils/api/employee/constants';

const useRecentlyUsedZoomUsersEmployeePrefMutation = () => {
  const employeeId = useEmployeeId();
  const [updateRecentlyUsedZoomUsersEmployeePref] = useUpdateRecentlyUsedZoomUsersEmployeePrefMutation();

  const updateFilters = useCallback(
    (updates: Pick<RecentlyUsedZoomUsersPref, 'zoomUserIds'>) => {
      updateRecentlyUsedZoomUsersEmployeePref({
        variables: {
          input: {
            employeeId,
            prefName: EmployeePrefName.OBJ_RECENTLY_USED_ZOOM_USERS,
            set: { json: JSON.stringify(updates) },
          },
        },
      });
    },
    [employeeId, updateRecentlyUsedZoomUsersEmployeePref]
  );
  return updateFilters;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RecentlyUsedZoomUsersEmployeePrefMutation = gql`
  mutation updateRecentlyUsedZoomUsersEmployeePref($input: EmployeePrefUpdateInput!) {
    employeePrefUpdate(input: $input) {
      employeePref {
        orgId
        employeeId
        prefName
      }
    }
  }
`;

export default useRecentlyUsedZoomUsersEmployeePrefMutation;
