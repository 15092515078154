import { SvgIcon, SvgIconProps } from './SvgIcon';

export const MultipleUsersIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 2C3.84315 2 2.5 3.34315 2.5 5V6C2.5 7.65685 3.84315 9 5.5 9C7.15685 9 8.5 7.65685 8.5 6V5C8.5 3.34315 7.15685 2 5.5 2ZM9.21737 10.9968C7.17566 10.0779 4.46718 10.0108 2.33435 10.7746C1.63345 11.0257 1.25 11.6951 1.25 12.3577V14.4148C1.25 15.3813 2.0335 16.1648 3 16.1648H6.45398C7.05684 16.1648 7.59128 15.8551 7.91712 15.3955C8.40586 14.7062 8.86438 14.2476 9.50708 13.7C9.92601 13.3431 10.1489 12.823 10.1197 12.3049C10.0894 11.769 9.78521 11.2523 9.21737 10.9968ZM15 5C13.3431 5 12 6.34315 12 8V9C12 10.6569 13.3431 12 15 12C16.6569 12 18 10.6569 18 9V8C18 6.34315 16.6569 5 15 5ZM12.5029 13.5807C14.0523 13.1517 15.882 13.1262 17.5363 13.5768C18.3035 13.7857 18.75 14.4922 18.75 15.2069V16.5C18.75 17.4665 17.9665 18.25 17 18.25L10.5 18.25C10.0219 18.25 9.55818 18.0511 9.24816 17.7009C8.92137 17.3318 8.77136 16.7886 8.99825 16.2386C9.56437 14.8662 10.9799 14.0025 12.5029 13.5807Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
