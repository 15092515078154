/* eslint-disable max-lines */
import React, { useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { ListItemIcon, PopoverOrigin } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import MUIDivider from '@material-ui/core/Divider';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import MuiListSubheader from '@material-ui/core/ListSubheader';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import MUIMenu, { MenuProps as MUIMenuProps } from '@material-ui/core/Menu';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import MUIMenuItem from '@material-ui/core/MenuItem';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Stack from 'src/components/Stack';
import { STD_BUTTON_HEIGHT } from 'src/components/constants/Typography';
import { CaretRightIcon } from 'src/components/icons';
import Label from 'src/components/label';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/theme/type';

export interface MenuOption {
  id?: string | number;
  value?: string;
  isDivider?: boolean;
  isSubheader?: boolean;
  selected?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  color?: 'info' | 'error' | 'warning' | 'success';
  isSticky?: boolean;
  className?: string;
  customOption?: JSX.Element;
  items?: MenuOption[];
  caption?: React.ReactNode;
  hidden?: boolean;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface MenuProps extends BaseProps {
  /**
   * List of options that are used to show in the menu
   */
  options: MenuOption[];

  /**
   * Shows the menu at the bottom of anchor element.
   */
  anchorEl: HTMLElement | null;

  /**
   * Used to connect the anchorEl to menu for accessibility purposes.
   */
  id: string;

  /**
   * Determines if the menu is shown or hidden
   */
  open: boolean;

  /**
   * This is the point on the anchor where the popover's
   * `anchorEl` will attach to. This is not used when the
   * anchorReference is 'anchorPosition'.
   *
   * Options:
   * vertical: [top, center, bottom];
   * horizontal: [left, center, right].
   */
  anchorOrigin?: PopoverOrigin;

  /**
   * This is the point on the popover which
   * will attach to the anchor's origin.
   *
   * Options:
   * vertical: [top, center, bottom, x(px)];
   * horizontal: [left, center, right, x(px)].
   */
  transformOrigin?: PopoverOrigin;

  disableScrollLock?: boolean;

  /**
   * Do not use this field directly, it is supposed to be only used by MenuItem when rendering a nested menu.
   */
  isSubMenu?: boolean;

  /**
   * Called when user selects a MenuItem
   */
  onSelect?: (option: MenuOption, e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;

  /**
   * Handler when menu is closed
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (event: any, reason?: Parameters<Exclude<MUIMenuProps['onClose'], undefined>>[1]) => void;

  menuWidth?: number;

  onClick?: MUIMenuProps['onClick'];
}

const useMenuSyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      pointerEvents: 'none',
    },
    paper: (props: MenuProps) => {
      return {
        minWidth: props.menuWidth ? props.menuWidth + 16 : 0,
        borderRadius: '6px',
        maxHeight: '400px',
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        pointerEvents: 'auto',
        zIndex: 100000001,
      };
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemRoot: {
      padding: 0,
      minHeight: STD_BUTTON_HEIGHT,
      borderRadius: '6px',
    },
    itemLabel: {
      padding: `5px ${theme.spacing(1.5)}px`,
      width: '100%',
    },
    itemLabelWithIcon: {
      paddingLeft: '0',
    },
    itemLabelInfo: {
      '&:hover': {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.info.main,
      },
    },
    itemLabelError: {
      '&:hover': {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.error.main,
      },
    },
    itemLabelWarning: {
      '&:hover': {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.warning.main,
      },
    },
    itemLabelSuccess: {
      '&:hover': {
        color: theme.palette.background.default,
        backgroundColor: theme.palette.success.main,
      },
    },
    subheader: {
      padding: '6px 8px',
      userSelect: 'none',
    },
    stickyMenuItem: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
    dividerRoot: {
      margin: theme.spacing(1),
    },
    iconPadding: {
      padding: `0 ${theme.spacing(1)}px`,
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type MenuItemProps = {
  /** Optional test id for the menu item */
  dataTestId?: string;
  option: MenuOption;
  index: number;
  onSelect: MenuProps['onSelect'];
  onClose: MenuProps['onClose'];
};

const MenuItemBase: React.ForwardRefRenderFunction<
  HTMLDivElement,
  MenuItemProps
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = ({ dataTestId, option, index, onSelect, onClose }, ref) => {
  const classes = useStyles();
  const innerRef = useRef<HTMLElement>(null);
  const [showSubMenu, setShowSubMenu] = useState(false);

  if (option.isDivider) {
    // eslint-disable-next-line react/no-array-index-key
    return (
      <MUIDivider data-testid={dataTestId} key={`${index}_DIVIDER`} variant="middle" className={classes.dividerRoot} />
    );
  }

  if (option.isSubheader) {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <MuiListSubheader data-testid={dataTestId} key={`${index}_SUBHEADER`} disableSticky disableGutters>
        <Label variant="captions" className={clsx(classes.subheader, option.className)} color={option.color}>
          {option.customOption || option.value}
        </Label>
      </MuiListSubheader>
    );
  }

  const classNames = clsx(classes.itemLabel, {
    [classes.itemLabelInfo]: option.color === 'info',
    [classes.itemLabelError]: option.color === 'error',
    [classes.itemLabelWarning]: option.color === 'warning',
    [classes.itemLabelSuccess]: option.color === 'success',
    [classes.itemLabelWithIcon]: !!option.icon,
  });

  let captionContent = option.caption;
  if (typeof captionContent === 'string') {
    captionContent = (
      <Label color={option.disabled ? 'high-contrast-grey' : 'max-contrast-grey'} variant="captions">
        {option.caption}
      </Label>
    );
  }

  return (
    <MUIMenuItem
      data-testid={dataTestId}
      className={clsx(classes.itemRoot, { [classes.stickyMenuItem]: option.isSticky }, option.className)}
      key={option.id}
      innerRef={innerRef}
      value={option.id}
      onClick={(e) => {
        // Don't do anything if this option has a sub menu.
        if (option.items) return;

        if (onSelect) {
          onSelect(option, e);
        }
        if (onClose) {
          onClose(e);
        }
      }}
      autoFocus={option.selected} // Scroll to the selected item.
      selected={option.selected}
      disabled={option.disabled}
      onMouseEnter={() => {
        if (!option.items) return;
        setShowSubMenu(true);
      }}
      onMouseLeave={() => {
        if (!option.items) return;
        setShowSubMenu(false);
      }}
    >
      {option.icon && <ListItemIcon className={classes.iconPadding}>{option.icon}</ListItemIcon>}
      {option.customOption && option.customOption}
      {!option.customOption && !captionContent && (
        <>
          <Label variant="body" className={classNames} color={option.color}>
            {option.value}
          </Label>
          {option.items && <CaretRightIcon />}
        </>
      )}
      {!option.customOption && captionContent && (
        <Stack direction="column" className={classNames}>
          <div>
            <Label variant="body" color={option.color}>
              {option.value}
            </Label>
            {option.items && <CaretRightIcon />}
          </div>
          {captionContent}
        </Stack>
      )}
      {showSubMenu && option.items && (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <Menu
          open
          anchorEl={innerRef.current}
          options={option.items}
          id={`${option.id}`}
          onSelect={(innerOption: MenuOption, e?: React.MouseEvent<HTMLLIElement, MouseEvent> | undefined) => {
            if (onSelect) {
              onSelect(innerOption, e);
            }
            if (onClose) {
              onClose(e);
            }
          }}
          onClose={() => {
            setShowSubMenu(false);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          isSubMenu
        />
      )}
    </MUIMenuItem>
  );
};

const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(MenuItemBase);

/**
 * @deprecated - Use `Menu` from `@mui/material` instead
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const Menu = ({
  dataTestId,
  menuWidth,
  options,
  anchorEl,
  id,
  open,
  anchorOrigin,
  transformOrigin,
  disableScrollLock,
  isSubMenu,
  onSelect,
  onClose,
  onClick,
}: MenuProps): JSX.Element => {
  const menuClasses = useMenuSyles({ menuWidth, options, anchorEl, id, open });
  return (
    <MUIMenu
      onClick={onClick}
      id={id}
      classes={{ paper: menuClasses.paper }}
      data-testid={dataTestId}
      anchorEl={anchorEl}
      open={open}
      keepMounted
      disableScrollLock={disableScrollLock}
      anchorOrigin={
        anchorOrigin ?? {
          vertical: 'bottom',
          horizontal: 'center',
        }
      }
      transformOrigin={
        transformOrigin ?? {
          vertical: 'top',
          horizontal: 'center',
        }
      }
      onClose={onClose}
      MenuListProps={{ autoFocus: true }}
      PopoverClasses={isSubMenu ? { root: menuClasses.root } : undefined}
    >
      {options
        .filter((option) => !option.hidden)
        .map((option, index) => {
          return (
            <MenuItem
              dataTestId={`menu-item-${option.id}`}
              key={option.id}
              option={option}
              index={index}
              onSelect={onSelect}
              onClose={onClose}
            />
          );
        })}
    </MUIMenu>
  );
};

export default Menu;
