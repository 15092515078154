import React from 'react';

import BaseIcon, { Props } from './Icon';

const UserSlashIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fillOpacity="0.43"
        fill="currentColor"
        d="M7.00001 6V7C7.00001 8.05322 7.54275 8.97967 8.3638 9.51494L12.3749 4.16678C11.8263 3.45704 10.9665 3 10 3C8.34315 3 7.00001 4.34315 7.00001 6ZM10.9769 12.0467C10.7166 12.0217 10.462 12.134 10.3051 12.3433L7.15002 16.55C6.97957 16.7773 6.95216 17.0813 7.0792 17.3354C7.20624 17.5895 7.46594 17.75 7.75002 17.75L14.9988 17.75C15.477 17.75 15.9338 17.5535 16.2475 17.2135C16.5716 16.8621 16.7419 16.3508 16.5893 15.808C15.9204 13.4298 13.3841 12.2785 10.9769 12.0467Z"
      />
      <path
        d="M4 18L16 2"
        stroke="currentColor"
        strokeOpacity="0.72"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default UserSlashIcon;
