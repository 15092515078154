import React from 'react';

import BaseIcon, { Props } from './Icon';

const MeetingRoomIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43937 5.03847L10.75 3.96081V5.00023V16.0396L6.25 14.9146V5.281C6.25 5.16629 6.32807 5.06629 6.43937 5.03847ZM4.75 5.281V14.7502H3C2.58579 14.7502 2.25 15.086 2.25 15.5002C2.25 15.9144 2.58579 16.2502 3 16.2502H5.40767L10.6968 17.5725C11.4858 17.7698 12.25 17.1731 12.25 16.3598V5.75023H13.75V15.5002C13.75 15.9144 14.0858 16.2502 14.5 16.2502H17C17.4142 16.2502 17.75 15.9144 17.75 15.5002C17.75 15.086 17.4142 14.7502 17 14.7502H15.25V5.00023C15.25 4.58601 14.9142 4.25023 14.5 4.25023H12.25V3.64062C12.25 2.8274 11.4858 2.2307 10.6968 2.42794L6.07556 3.58325C5.29652 3.77801 4.75 4.47798 4.75 5.281ZM9 11.5002C9.55228 11.5002 10 11.0525 10 10.5002C10 9.94794 9.55228 9.50023 9 9.50023C8.44772 9.50023 8 9.94794 8 10.5002C8 11.0525 8.44772 11.5002 9 11.5002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5852 17.4646L14.4908 16.2502C14.0808 16.2452 13.75 15.9114 13.75 15.5002V5.75023H12.25V16.3598C12.25 16.8474 11.9753 17.2571 11.5852 17.4646Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MeetingRoomIcon;
