import { useEmployeePrefStringArray } from 'src/hooks/api/employee/get';
import { useSetEmployeePrefStringArray } from 'src/hooks/api/employee/set';
import useEmployeeId from 'src/hooks/useEmployeeId';

import { EmployeePrefName } from 'src/utils/api/employee';

import { EMPTY_ARRAY } from 'src/constants';

const MAX_LAST_USED_TIMEZONES = 10;

const useRecentlyUsedTimezonesEmployeePref = (onCompleted?: () => void) => {
  const currentEmployeeId = useEmployeeId();

  const [data, { loading, refetch }] = useEmployeePrefStringArray(
    EmployeePrefName.ARR_RECENTLY_USED_TIMEZONES,
    currentEmployeeId || ''
  );

  const recentlyUsedTimezones = (data || EMPTY_ARRAY) as string[];

  const updatePref = useSetEmployeePrefStringArray(
    EmployeePrefName.ARR_RECENTLY_USED_TIMEZONES,
    currentEmployeeId || '',
    onCompleted
  );

  const addRecentlyUsedTimezone = (timezone: string) => {
    const oldTimezones = [...recentlyUsedTimezones];
    const index = oldTimezones.indexOf(timezone);
    if (index === 0) return;
    if (index !== -1) {
      oldTimezones.splice(index, 1);
    }
    oldTimezones.unshift(timezone);
    const newTimezones = oldTimezones.slice(0, MAX_LAST_USED_TIMEZONES);
    updatePref(newTimezones);
  };

  return {
    recentlyUsedTimezones,
    addRecentlyUsedTimezone,
    loading,
    refetch,
  };
};

export default useRecentlyUsedTimezonesEmployeePref;
