import { ThemeOptions } from '@mui/material';

/**
 * Old conversion
 * huge -> h2
 * title -> h4
 * header -> h5
 * body -> body1 (default)
 * captions -> body2
 *
 * // New stuff
 * subtitle1 - body1 + bold
 * subtitle2 - body2 + bold
 * caption - used for hint or helper texts
 *
 * TODO: Can we use the caption for old caption?
 * - can we have 16px font size default? That's currently the default in app-client
 * - recommendation - font size rem
 */

export const typography: ThemeOptions['typography'] = {
  fontFamily: "'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif",
  fontSize: 15,
  fontWeightBold: 600,
  fontWeightMedium: 500,
  fontWeightRegular: 400,

  // These are removed from the theme
  h1: {
    fontSize: '64px',
    lineHeight: 80 / 64, // '80px',
    fontWeight: 600,
  },

  h6: {},
  overline: {},

  // Huge
  h2: {
    fontSize: '48px',
    lineHeight: 60 / 48, //  '60px',
    fontWeight: 600,
  },
  h3: {
    fontSize: '32px',
    lineHeight: 40 / 32, // '40px',
    fontWeight: 600,
  },
  // Title
  h4: {
    fontSize: '22px',
    lineHeight: 28 / 22, // '28px',
    fontWeight: 600,
  },
  // Header
  h5: {
    fontSize: '18px',
    lineHeight: 24 / 18, // '24px',
    fontWeight: 600,
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: 22 / 15, // '22px',
  },
  subtitle2: {
    fontSize: '13px',
    lineHeight: 18 / 13, //'18px',
    fontWeight: 600,
  },
  body1: {
    fontSize: '15px',
    lineHeight: 22 / 15, // '22px',
  },
  body2: {
    fontSize: '13px',
    lineHeight: 18 / 13, //'18px',
    // color: text.secondary TODO
  },
  caption: {
    fontSize: '12px',
    lineHeight: 18 / 12, // '18px',
  },
  button: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: 22 / 15,
    textTransform: 'none',
  },
};
