/**
 * ActivityLog
 *
 * This component is a factory for the various ActivityLog renderers.
 * To add a new renderer:
 *   1) create a new file in the Renderers directory
 *   2) add the new renderer to the typeToComponentMap
 *   3) add the new renderer's fragment gql to the top of ActivityLog.fragments.activityLog
 *   4) add the new renderer's fragment spread to the bottom of ActivityLog.fragments.activityLog
 *   5) add a new test case in storybook
 */
import React, { FC } from 'react';

import { gql } from '@apollo/client';

import { ActivityLog_ActivityLogFragment, ActivityType } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import logError from 'src/utils/logError';

import { IS_PRODUCTION } from 'src/constants';
import SilentErrorBoundary from 'src/shared/components/base/boundaries/SilentErrorBoundary';

import ActivityLogApplicationCandidatePortalNoteCreated from './Renderers/ActivityLogApplicationCandidatePortalNoteCreated';
import ActivityLogApplicationCandidatePortalNoteDeleted from './Renderers/ActivityLogApplicationCandidatePortalNoteDeleted';
import ActivityLogApplicationCandidatePortalNoteUpdated from './Renderers/ActivityLogApplicationCandidatePortalNoteUpdated';
import ActivityLogCandidatePreferredPhoneNumberChangeDetails from './Renderers/ActivityLogCandidatePreferredPhoneNumberChangeDetails';
import ActivityLogCandidateViewedTabDetails from './Renderers/ActivityLogCandidateViewedTabDetails';
import ActivityLogEmail from './Renderers/ActivityLogEmail';
import ActivityLogNote from './Renderers/ActivityLogNote';
import ActivityLogTaskAssigned from './Renderers/ActivityLogTaskAssigned';
import ActivityLogTaskCalendarChanges from './Renderers/ActivityLogTaskCalendarChanges';
import ActivityLogTaskCandidateAvailabilityDeleted from './Renderers/ActivityLogTaskCandidateAvailabilityDeleted';
import ActivityLogTaskCandidateAvailabilityReminderSent from './Renderers/ActivityLogTaskCandidateAvailabilityReminderSent';
import ActivityLogTaskCandidateInterviewReminderSent from './Renderers/ActivityLogTaskCandidateInterviewReminderSent';
import ActivityLogTaskCandidateRsvp from './Renderers/ActivityLogTaskCandidateRsvp';
import ActivityLogTaskCandidateSelfScheduleCanceled from './Renderers/ActivityLogTaskCandidateSelfScheduleCanceled';
import ActivityLogTaskCandidateSelfScheduleCreated from './Renderers/ActivityLogTaskCandidateSelfScheduleCreated';
import ActivityLogTaskCandidateSelfScheduleReminderSent from './Renderers/ActivityLogTaskCandidateSelfScheduleReminderSent';
import ActivityLogTaskCandidateSelfScheduleRescheduled from './Renderers/ActivityLogTaskCandidateSelfScheduleRescheduled';
import ActivityLogTaskCandidateSubmittedAvailability from './Renderers/ActivityLogTaskCandidateSubmittedAvailability';
import ActivityLogTaskChangeRequested from './Renderers/ActivityLogTaskChangeRequested';
import ActivityLogTaskChangedStatus from './Renderers/ActivityLogTaskChangedStatus';
import ActivityLogTaskClosed from './Renderers/ActivityLogTaskClosed';
import ActivityLogTaskClosedDueToStageChange from './Renderers/ActivityLogTaskClosedDueToStageChange';
import ActivityLogTaskCreated from './Renderers/ActivityLogTaskCreated';
import ActivityLogTaskDebriefCanceled from './Renderers/ActivityLogTaskDebriefCanceled';
import ActivityLogTaskDebriefCreated from './Renderers/ActivityLogTaskDebriefCreated';
import ActivityLogTaskDebriefRescheduled from './Renderers/ActivityLogTaskDebriefRescheduled';
import ActivityLogTaskDeclinedInterviewerAutoReplaced from './Renderers/ActivityLogTaskDeclinedInterviewerAutoReplaced';
import ActivityLogTaskEditedAvailability from './Renderers/ActivityLogTaskEditedAvailability';
import ActivityLogTaskFlagResolvedCandidateDeclined from './Renderers/ActivityLogTaskFlagResolvedCandidateDeclined';
import ActivityLogTaskFlagResolvedChangeRequested from './Renderers/ActivityLogTaskFlagResolvedChangeRequested';
import ActivityLogTaskFlagResolvedDebriefDeclined from './Renderers/ActivityLogTaskFlagResolvedDebriefDeclined';
import ActivityLogTaskFlagResolvedInterviewDeclined from './Renderers/ActivityLogTaskFlagResolvedInterviewDeclined';
import ActivityLogTaskInterviewerRsvp from './Renderers/ActivityLogTaskInterviewerRsvp';
import ActivityLogTaskMarkedUrgent from './Renderers/ActivityLogTaskMarkedUrgent';
import ActivityLogTaskMeetingRoomRsvp from './Renderers/ActivityLogTaskMeetingRoomRsvp';
import ActivityLogTaskMigrated from './Renderers/ActivityLogTaskMigrated';
import ActivityLogTaskQueueSet from './Renderers/ActivityLogTaskQueueSet';
import ActivityLogTaskQueueUnset from './Renderers/ActivityLogTaskQueueUnset';
import ActivityLogTaskRcAdjustInterviewerRsvp from './Renderers/ActivityLogTaskRcAdjustInterviewerRsvp';
import ActivityLogTaskReadyForCandidateComm from './Renderers/ActivityLogTaskReadyForCandidateComm';
import ActivityLogTaskReopened from './Renderers/ActivityLogTaskReopened';
import ActivityLogTaskRequirementApplicationDebriefAdded from './Renderers/ActivityLogTaskRequirementApplicationDebriefAdded';
import ActivityLogTaskRequirementApplicationDebriefRemoved from './Renderers/ActivityLogTaskRequirementApplicationDebriefRemoved';
import ActivityLogTaskRequirementCandidateAvailabilityAdded from './Renderers/ActivityLogTaskRequirementCandidateAvailabilityAdded';
import ActivityLogTaskRequirementCandidateAvailabilityRemoved from './Renderers/ActivityLogTaskRequirementCandidateAvailabilityRemoved';
import ActivityLogTaskRequirementCandidateAvailabilityUpdated from './Renderers/ActivityLogTaskRequirementCandidateAvailabilityUpdated';
import ActivityLogTaskRequirementScheduleAdded from './Renderers/ActivityLogTaskRequirementScheduleAdded';
import ActivityLogTaskRequirementSelfScheduleAdded from './Renderers/ActivityLogTaskRequirementSelfScheduleAdded';
import ActivityLogTaskRequirementSelfScheduleRemoved from './Renderers/ActivityLogTaskRequirementSelfScheduleRemoved';
import ActivityLogTaskRequirementSelfScheduleUpdated from './Renderers/ActivityLogTaskRequirementSelfScheduleUpdated';
import ActivityLogTaskRescheduleLogCreated from './Renderers/ActivityLogTaskRescheduleLogCreated';
import ActivityLogTaskRescheduleLogDeleted from './Renderers/ActivityLogTaskRescheduleLogDeleted';
import ActivityLogTaskRescheduleLogEdited from './Renderers/ActivityLogTaskRescheduleLogEdited';
import ActivityLogTaskScheduleCanceled from './Renderers/ActivityLogTaskScheduleCanceled';
import ActivityLogTaskScheduleCreated from './Renderers/ActivityLogTaskScheduleCreated';
import ActivityLogTaskScheduleRescheduled from './Renderers/ActivityLogTaskScheduleRescheduled';
import ActivityLogTaskScheduleUpdated from './Renderers/ActivityLogTaskScheduleUpdated';
import ActivityLogTaskTagAdded from './Renderers/ActivityLogTaskTagAdded';
import ActivityLogTaskTagRemoved from './Renderers/ActivityLogTaskTagRemoved';
import ActivityLogTaskUnassigned from './Renderers/ActivityLogTaskUnassigned';
import ActivityLogTaskUnmarkedUrgent from './Renderers/ActivityLogTaskUnmarkedUrgent';

interface Fragment {
  activityLog: ActivityLog_ActivityLogFragment;
}

const typeToComponentMap = {
  [ActivityType.ApplicationCandidatePortalNoteCreated]: ActivityLogApplicationCandidatePortalNoteCreated,
  [ActivityType.ApplicationCandidatePortalNoteUpdated]: ActivityLogApplicationCandidatePortalNoteUpdated,
  [ActivityType.ApplicationCandidatePortalNoteDeleted]: ActivityLogApplicationCandidatePortalNoteDeleted,
  [ActivityType.Note]: ActivityLogNote,
  [ActivityType.TaskAssigned]: ActivityLogTaskAssigned,
  [ActivityType.TaskCalendarChanges]: ActivityLogTaskCalendarChanges,
  [ActivityType.TaskCandidateAvailabilityDeleted]: ActivityLogTaskCandidateAvailabilityDeleted,
  [ActivityType.TaskCandidateEditedAvailability]: ActivityLogTaskCandidateSubmittedAvailability,
  [ActivityType.TaskCandidateEmailSent]: ActivityLogEmail,
  [ActivityType.TaskCandidateRsvp]: ActivityLogTaskCandidateRsvp,
  [ActivityType.TaskCandidateSelfScheduleCanceled]: ActivityLogTaskCandidateSelfScheduleCanceled,
  [ActivityType.TaskCandidateSelfScheduleCreated]: ActivityLogTaskCandidateSelfScheduleCreated,
  [ActivityType.TaskCandidateSelfScheduleRescheduled]: ActivityLogTaskCandidateSelfScheduleRescheduled,
  [ActivityType.TaskChangedStatus]: ActivityLogTaskChangedStatus,
  [ActivityType.TaskChangeRequested]: ActivityLogTaskChangeRequested,
  [ActivityType.TaskClosed]: ActivityLogTaskClosed,
  [ActivityType.TaskClosedDueToStageChange]: ActivityLogTaskClosedDueToStageChange,
  [ActivityType.TaskCreated]: ActivityLogTaskCreated,
  [ActivityType.TaskDebriefCanceled]: ActivityLogTaskDebriefCanceled,
  [ActivityType.TaskDebriefCreated]: ActivityLogTaskDebriefCreated,
  [ActivityType.TaskDebriefRescheduled]: ActivityLogTaskDebriefRescheduled,
  [ActivityType.TaskDebriefUpdated]: ActivityLogTaskDebriefRescheduled,
  [ActivityType.TaskEmployeeEditedAvailability]: ActivityLogTaskEditedAvailability,
  [ActivityType.TaskFlagResolvedCandidateDeclined]: ActivityLogTaskFlagResolvedCandidateDeclined,
  [ActivityType.TaskFlagResolvedChangeRequested]: ActivityLogTaskFlagResolvedChangeRequested,
  [ActivityType.TaskFlagResolvedDebriefDeclined]: ActivityLogTaskFlagResolvedDebriefDeclined,
  [ActivityType.TaskFlagResolvedInterviewDeclined]: ActivityLogTaskFlagResolvedInterviewDeclined,
  [ActivityType.TaskInterviewerRsvp]: ActivityLogTaskInterviewerRsvp,
  [ActivityType.TaskMarkedUrgent]: ActivityLogTaskMarkedUrgent,
  [ActivityType.TaskMeetingRoomRsvp]: ActivityLogTaskMeetingRoomRsvp,
  [ActivityType.TaskMigrated]: ActivityLogTaskMigrated,
  [ActivityType.TaskReopened]: ActivityLogTaskReopened,
  [ActivityType.TaskRequirementAppDebriefAdded]: ActivityLogTaskRequirementApplicationDebriefAdded,
  [ActivityType.TaskRequirementAppDebriefRemoved]: ActivityLogTaskRequirementApplicationDebriefRemoved,
  [ActivityType.TaskRequirementCandidateAvailabilityAdded]: ActivityLogTaskRequirementCandidateAvailabilityAdded,
  [ActivityType.TaskRequirementCandidateAvailabilityRemoved]: ActivityLogTaskRequirementCandidateAvailabilityRemoved,
  [ActivityType.TaskRequirementCandidateAvailabilityUpdated]: ActivityLogTaskRequirementCandidateAvailabilityUpdated,
  [ActivityType.TaskRequirementScheduleAdded]: ActivityLogTaskRequirementScheduleAdded,
  [ActivityType.TaskRequirementSelfScheduleAdded]: ActivityLogTaskRequirementSelfScheduleAdded,
  [ActivityType.TaskRequirementSelfScheduleRemoved]: ActivityLogTaskRequirementSelfScheduleRemoved,
  [ActivityType.TaskRequirementSelfScheduleUpdated]: ActivityLogTaskRequirementSelfScheduleUpdated,
  [ActivityType.TaskRescheduleLogAdded]: ActivityLogTaskRescheduleLogCreated,
  [ActivityType.TaskRescheduleLogUpdated]: ActivityLogTaskRescheduleLogEdited,
  [ActivityType.TaskRescheduleLogRemoved]: ActivityLogTaskRescheduleLogDeleted,
  [ActivityType.TaskScheduleAdded]: ActivityLogTaskScheduleCreated,
  [ActivityType.TaskScheduleCanceled]: ActivityLogTaskScheduleCanceled,
  [ActivityType.TaskScheduleRescheduled]: ActivityLogTaskScheduleRescheduled,
  [ActivityType.TaskScheduleUpdated]: ActivityLogTaskScheduleUpdated,
  [ActivityType.TaskTagAdded]: ActivityLogTaskTagAdded,
  [ActivityType.TaskTagRemoved]: ActivityLogTaskTagRemoved,
  [ActivityType.TaskUnassigned]: ActivityLogTaskUnassigned,
  [ActivityType.TaskUnmarkedUrgent]: ActivityLogTaskUnmarkedUrgent,
  [ActivityType.TaskRcAdjustInterviewerRsvp]: ActivityLogTaskRcAdjustInterviewerRsvp,
  [ActivityType.TaskDeclinedInterviewerAutoReplaced]: ActivityLogTaskDeclinedInterviewerAutoReplaced,
  [ActivityType.TaskReadyForCandidateComm]: ActivityLogTaskReadyForCandidateComm,
  [ActivityType.CandidateViewedTab]: ActivityLogCandidateViewedTabDetails,
  [ActivityType.TaskQueueSet]: ActivityLogTaskQueueSet,
  [ActivityType.TaskQueueUnset]: ActivityLogTaskQueueUnset,
  [ActivityType.TaskInterviewCandidateReminderSent]: ActivityLogTaskCandidateInterviewReminderSent,
  [ActivityType.TaskSelfScheduleCandidateReminderSent]: ActivityLogTaskCandidateSelfScheduleReminderSent,
  [ActivityType.TaskAvailabilityCandidateReminderSent]: ActivityLogTaskCandidateAvailabilityReminderSent,
  [ActivityType.CandidatePreferredPhoneNumberChanged]: ActivityLogCandidatePreferredPhoneNumberChangeDetails,
};

/**
 * This is a ActivityLog Factory
 * Wow a factory pattern in React + Typescript + GraphQL Fragments. it's beautiful!
 *
 * Caveats:
 *   If we are not careful we get the following error:
 *   > Expression produces a union type that is too complex
 *   We are getting around that by recasting the component.
 *
 * @param activityLog the activityLog to render
 * @returns the correct ActivityLog component based on the activityType
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const ActivityLog: FCWithFragments<Fragment> = ({ activityLog }) => {
  const { activityType } = activityLog as ActivityLog_ActivityLogFragment;

  const Component: FC<{ activityLog: ActivityLog_ActivityLogFragment }> = typeToComponentMap[activityType];

  // If we don't have the component we should log an error and return null
  // We can show helper text in development but in production we should just return null
  if (!Component) {
    logError(`ActivityLog: No component found for activityType: ${activityType}`);
    if (!IS_PRODUCTION) {
      return <>[NOT-SHOWN-IN-PROD] Missing renderer: {activityType}</>;
    }
    return null;
  }

  // Wrapped in an error boundary so that the individual renderers can safely throw errors
  // and not crash the entire feed or page
  return (
    <SilentErrorBoundary>
      <Component activityLog={activityLog} />
    </SilentErrorBoundary>
  );
};

ActivityLog.fragments = {
  activityLog: gql`
    ${ActivityLogApplicationCandidatePortalNoteCreated.fragments.activityLog}
    ${ActivityLogApplicationCandidatePortalNoteUpdated.fragments.activityLog}
    ${ActivityLogApplicationCandidatePortalNoteDeleted.fragments.activityLog}
    ${ActivityLogEmail.fragments.activityLog}
    ${ActivityLogNote.fragments.activityLog}
    ${ActivityLogTaskAssigned.fragments.activityLog}
    ${ActivityLogTaskCalendarChanges.fragments.activityLog}
    ${ActivityLogTaskCandidateAvailabilityDeleted.fragments.activityLog}
    ${ActivityLogTaskCandidateRsvp.fragments.activityLog}
    ${ActivityLogTaskCandidateSelfScheduleCanceled.fragments.activityLog}
    ${ActivityLogTaskCandidateSelfScheduleCreated.fragments.activityLog}
    ${ActivityLogTaskCandidateSelfScheduleRescheduled.fragments.activityLog}
    ${ActivityLogTaskCandidateSubmittedAvailability.fragments.activityLog}
    ${ActivityLogTaskChangedStatus.fragments.activityLog}
    ${ActivityLogTaskChangeRequested.fragments.activityLog}
    ${ActivityLogTaskClosed.fragments.activityLog}
    ${ActivityLogTaskCreated.fragments.activityLog}
    ${ActivityLogTaskDebriefCanceled.fragments.activityLog}
    ${ActivityLogTaskDebriefCreated.fragments.activityLog}
    ${ActivityLogTaskEditedAvailability.fragments.activityLog}
    ${ActivityLogTaskFlagResolvedCandidateDeclined.fragments.activityLog}
    ${ActivityLogTaskFlagResolvedChangeRequested.fragments.activityLog}
    ${ActivityLogTaskFlagResolvedDebriefDeclined.fragments.activityLog}
    ${ActivityLogTaskFlagResolvedInterviewDeclined.fragments.activityLog}
    ${ActivityLogTaskInterviewerRsvp.fragments.activityLog}
    ${ActivityLogTaskRcAdjustInterviewerRsvp.fragments.activityLog}
    ${ActivityLogTaskMarkedUrgent.fragments.activityLog}
    ${ActivityLogTaskMeetingRoomRsvp.fragments.activityLog}
    ${ActivityLogTaskReopened.fragments.activityLog}
    ${ActivityLogTaskRequirementApplicationDebriefAdded.fragments.activityLog}
    ${ActivityLogTaskRequirementApplicationDebriefRemoved.fragments.activityLog}
    ${ActivityLogTaskRequirementCandidateAvailabilityAdded.fragments.activityLog}
    ${ActivityLogTaskRequirementCandidateAvailabilityRemoved.fragments.activityLog}
    ${ActivityLogTaskRequirementCandidateAvailabilityUpdated.fragments.activityLog}
    ${ActivityLogTaskRequirementScheduleAdded.fragments.activityLog}
    ${ActivityLogTaskRequirementSelfScheduleAdded.fragments.activityLog}
    ${ActivityLogTaskRequirementSelfScheduleRemoved.fragments.activityLog}
    ${ActivityLogTaskRequirementSelfScheduleUpdated.fragments.activityLog}
    ${ActivityLogTaskRescheduleLogCreated.fragments.activityLog}
    ${ActivityLogTaskRescheduleLogEdited.fragments.activityLog}
    ${ActivityLogTaskRescheduleLogDeleted.fragments.activityLog}
    ${ActivityLogTaskScheduleCanceled.fragments.activityLog}
    ${ActivityLogTaskScheduleCreated.fragments.activityLog}
    ${ActivityLogTaskScheduleRescheduled.fragments.activityLog}
    ${ActivityLogTaskScheduleUpdated.fragments.activityLog}
    ${ActivityLogTaskTagAdded.fragments.activityLog}
    ${ActivityLogTaskTagRemoved.fragments.activityLog}
    ${ActivityLogTaskUnassigned.fragments.activityLog}
    ${ActivityLogTaskUnmarkedUrgent.fragments.activityLog}
    ${ActivityLogTaskReadyForCandidateComm.fragments.activityLog}
    ${ActivityLogCandidateViewedTabDetails.fragments.activityLog}
    ${ActivityLogTaskQueueSet.fragments.activityLog}
    ${ActivityLogTaskQueueUnset.fragments.activityLog}
    ${ActivityLogTaskCandidateInterviewReminderSent.fragments.activityLog}
    ${ActivityLogTaskCandidateSelfScheduleReminderSent.fragments.activityLog}
    ${ActivityLogTaskCandidateAvailabilityReminderSent.fragments.activityLog}
    ${ActivityLogTaskDeclinedInterviewerAutoReplaced.fragments.activityLog}
    ${ActivityLogCandidatePreferredPhoneNumberChangeDetails.fragments.activityLog}

    fragment ActivityLog_activityLog on ActivityLog {
      id
      activityType
      ...ActivityLogApplicationCandidatePortalNoteCreated_activityLog
      ...ActivityLogApplicationCandidatePortalNoteUpdated_activityLog
      ...ActivityLogApplicationCandidatePortalNoteDeleted_activityLog
      ...ActivityLogEmail_activityLog
      ...ActivityLogNote_activityLog
      ...ActivityLogTaskAssigned_activityLog
      ...ActivityLogTaskCalendarChanges_activityLog
      ...ActivityLogTaskCandidateAvailabilityDeleted_activityLog
      ...ActivityLogTaskCandidateRsvp_activityLog
      ...ActivityLogTaskCandidateSelfScheduleCanceled_activityLog
      ...ActivityLogTaskCandidateSelfScheduleCreated_activityLog
      ...ActivityLogTaskCandidateSelfScheduleRescheduled_activityLog
      ...ActivityLogTaskCandidateSubmittedAvailability_activityLog
      ...ActivityLogTaskChangedStatus_activityLog
      ...ActivityLogTaskChangeRequested_activityLog
      ...ActivityLogTaskClosed_activityLog
      ...ActivityLogTaskClosed_activityLog
      ...ActivityLogTaskCreated_activityLog
      ...ActivityLogTaskCreated_activityLog
      ...ActivityLogTaskDebriefCanceled_activityLog
      ...ActivityLogTaskDebriefCreated_activityLog
      ...ActivityLogTaskEditedAvailability_activityLog
      ...ActivityLogTaskFlagResolvedCandidateDeclined_activityLog
      ...ActivityLogTaskFlagResolvedChangeRequested_activityLog
      ...ActivityLogTaskFlagResolvedDebriefDeclined_activityLog
      ...ActivityLogTaskFlagResolvedInterviewDeclined_activityLog
      ...ActivityLogTaskInterviewerRsvp_activityLog
      ...ActivityLogTaskRcAdjustInterviewerRsvp_activityLog
      ...ActivityLogTaskMarkedUrgent_activityLog
      ...ActivityLogTaskMeetingRoomRsvp_activityLog
      ...ActivityLogTaskReopened_activityLog
      ...ActivityLogTaskRequirementApplicationDebriefAdded_activityLog
      ...ActivityLogTaskRequirementApplicationDebriefRemoved_activityLog
      ...ActivityLogTaskRequirementCandidateAvailabilityAdded_activityLog
      ...ActivityLogTaskRequirementCandidateAvailabilityRemoved_activityLog
      ...ActivityLogTaskRequirementCandidateAvailabilityUpdated_activityLog
      ...ActivityLogTaskRequirementScheduleAdded_activityLog
      ...ActivityLogTaskRequirementSelfScheduleAdded_activityLog
      ...ActivityLogTaskRequirementSelfScheduleRemoved_activityLog
      ...ActivityLogTaskRequirementSelfScheduleUpdated_activityLog
      ...ActivityLogTaskScheduleCanceled_activityLog
      ...ActivityLogTaskScheduleCreated_activityLog
      ...ActivityLogTaskScheduleRescheduled_activityLog
      ...ActivityLogTaskScheduleUpdated_activityLog
      ...ActivityLogTaskTagAdded_activityLog
      ...ActivityLogTaskTagRemoved_activityLog
      ...ActivityLogTaskUnassigned_activityLog
      ...ActivityLogTaskUnmarkedUrgent_activityLog
      ...ActivityLogTaskReadyForCandidateComm_activityLog
      ...ActivityLogCandidateViewedTabDetails_activityLog
      ...ActivityLogTaskQueueSet_activityLog
      ...ActivityLogTaskQueueUnset_activityLog
      ...ActivityLogTaskCandidateInterviewReminderSent_activityLog
      ...ActivityLogTaskCandidateSelfScheduleReminderSent_activityLog
      ...ActivityLogTaskCandidateAvailabilityReminderSent_activityLog
      ...ActivityLogTaskDeclinedInterviewerAutoReplaced_activityLog
      ...ActivityLogCandidatePreferredPhoneNumberChangeDetails_activityLog
    }
  `,
};

export default ActivityLog;
