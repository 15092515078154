// Re-export all functions from the files
export * from './strings';
export * from './datadog';
export * from './candidateAvailabilityUtils';

// Re-export individual functions from the files
export { logError } from './logError';
export { logMessage } from './logMessage';
export { default as axiosRetry } from './axios/axiosRetry';
export { default as axioxInterceptorMlTraceId } from './axios/axioxInterceptorMlTraceId';
export { default as axiosInterceptorResponseError } from './axios/axiosInterceptorResponseError';
export {
  getErrorStatus,
  getErrorMessageForReason,
  getExternalErrorMessage,
  getInternalErrorMessage,
  isPermissionsError,
} from './axios/error';
export { encode, decode } from './encodeDecode';
export { isValidVideoUrl } from './videoUtils';
export { getPickerIcon } from '../components/IconPicker/Icons/utils';
export { sha256Encode } from './sha256';

export { constructCustomTheme } from './brandingThemes';

export const inIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export {
  mergeTimeRanges,
  getTimeRangesInTimezone,
  getUniqueTimeRanges,
  sortTimeRanges,
  getTimePeriods,
  isTimeBlockWeekNotEmpty,
  DEFAULT_WORK_HOURS,
} from './timeRangeUtils';
export type { TimeBlockWeek } from './timeRangeUtils';
export { plainText, snakeCaseToInitCase, snakeCaseToCapitalize } from './plainText';

export { default as copyRichTextToClipboard } from './copyRichTextToClipboard';

export { fetchImage } from './fetchImage';

export { default as isValidHttpUrl } from './isValidHttpUrl';
