import React, { FC, createContext, useState } from 'react';
import { useLocation } from 'react-router';

import { noop } from 'lodash';

import { Routes } from 'src/constants/routes';

import useHistory from 'src/hooks/useHistory';

export const OnboardingContext = createContext({
  isDirectoryOAuthModalDismissed: false,
  dismissDirectoryOAuthModal: noop,
});

const OnboardingProvider: FC = ({ children }) => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const [initialLoadRoute] = useState(pathname + search);
  const [isDirectoryOAuthModalDismissed, setDirectoryOAuthModalDismissed] = useState(false);
  return (
    <OnboardingContext.Provider
      value={{
        isDirectoryOAuthModalDismissed,
        dismissDirectoryOAuthModal: () => {
          setDirectoryOAuthModalDismissed(true);
          history.replace(
            !initialLoadRoute || initialLoadRoute.includes('/grant-access')
              ? Routes.MySchedulingTasks
              : initialLoadRoute
          );
        },
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingProvider;
