import { gql } from '@apollo/client';

import { useOrgSlugQuery } from 'src/generated/mloop-graphql';

export const OrgSlugQuery = gql`
  query OrgSlug {
    thisOrg {
      id
      slugs {
        id: orgId
        slug
      }
    }
  }
`;

const useOrgSlug = (skip?: boolean) => {
  const { data, ...rest } = useOrgSlugQuery({
    skip,
  });
  return { orgSlug: data?.thisOrg?.slugs?.[0]?.slug, ...rest };
};

export default useOrgSlug;
