import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { difference, minBy } from 'lodash';

import {
  UpdateCommunications_CandidateFragment,
  UpdateCommunications_InterviewPlanFragment,
  UpdateCommunications_JobStageSettingsFragment,
} from 'src/generated/mloop-graphql';

import TwoColumnLayout from 'src/components/Layout/TwoColumnLayout';
import Stack from 'src/components/Stack';
import Button from 'src/components/button';
import { SlackIcon } from 'src/components/icons';
import Label from 'src/components/label';

import { useIntegrations } from 'src/hooks/api/integration';
import { useCodingIntegrationTypes } from 'src/hooks/api/integration/useCodingIntegration';
import { OrgPrefName, useOrgPrefString } from 'src/hooks/api/org-grpc';

import {
  updateCandidateCalendarId,
  updateCandidateCalendarTemplateID,
  updateCandidateTemplateID,
  updateInterviewerCalendarId,
  updateIsPrivateCalendarEvent,
} from 'src/slices/scheduling';

import {
  addCodeLinkType,
  updateCandidateCalendarDescription,
  updateCandidateCalendarSummary,
  updateCandidateContentDescription,
  updateCandidateContentSummary,
  updateCustomVideoMeetingLinksConfig,
  updateSlackChannelEnabled,
  updateSlackChannelName,
  updateVideoMeetingLinkConfig,
  updateZoomMeetingConfig,
} from 'src/store/actions/schedule-communications';
import {
  getAddedCodeLinkTypes,
  getCustomVideoMeetingLink,
  getMeetingLinksConfig,
  getScheduleContent,
} from 'src/store/selectors/schedule-communications';
import {
  getCandidateCalendarTemplateID,
  getCandidateTemplateID,
  getEventOldContent,
  getOriginalCandidateGoogleEventId,
} from 'src/store/selectors/scheduling';
import { getLeftNavExpanded } from 'src/store/selectors/ui-state';
import { MeetingLinksConfig } from 'src/store/slices/schedule-communications';

import getCodingUrlTitle from 'src/utils/getCodingUrlTitle';
import getSlackChannelName, { getSlackChannelFormat } from 'src/utils/slack/getSlackChannelName';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';
import CalendarCard from 'src/views-new/ScheduleFlow/Steps/Communications/CalendarCard';
import CandidateEmailCard from 'src/views-new/ScheduleFlow/Steps/Communications/CandidateEmailCard';
import CandidateEventCard from 'src/views-new/ScheduleFlow/Steps/Communications/CandidateEventCard';
import CodingCard, { iconMap } from 'src/views-new/ScheduleFlow/Steps/Communications/CodingCard';
import LocationCard from 'src/views-new/ScheduleFlow/Steps/Communications/LocationCard';
import SlackChannelCard from 'src/views-new/ScheduleFlow/Steps/Communications/SlackChannelCard';
import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import LoadingContent from 'src/views/booking/BookingCreateView/LoadingContent';

import LoadingScreen from 'src/shared/components/base/screenLoaders/LoadingScreen';
import { useDispatch, useSelector } from 'src/store';

import useInitScheduleCommunicationContent from '../ScheduleFlow/Steps/Communications/TemplateContent/useInitScheduleCommunicationContent';

import CommunicationsPreview from './CommunicationsPreview';
import InterviewerCommunicationCards from './InterviewerCommunicationCards';
import OldContent from './OldContent';

type Fragments = {
  candidate: UpdateCommunications_CandidateFragment | undefined;
  interviewPlan: UpdateCommunications_InterviewPlanFragment | undefined;
  jobStageSettings: UpdateCommunications_JobStageSettingsFragment | undefined;
};

type Props = {
  onComplete: () => void;
};

const UpdateCommunications: FCWithFragments<Fragments, Props> = ({
  candidate,
  interviewPlan,
  onComplete,
}): JSX.Element => {
  const dispatch = useDispatch();
  const scheduleFlowData = useScheduleFlowData();

  const { data: integrations, isLoading } = useIntegrations();
  const codingIntegrationTypes = useCodingIntegrationTypes();

  const candidateTemplateID = useSelector(getCandidateTemplateID);
  const candidateCalendarEventTemplateId = useSelector(getCandidateCalendarTemplateID);
  const { selectedScheduleId, candidateCalendarId, interviewerCalendarId, isPrivateCalendarEvent } = useSelector(
    (state) => state.scheduling
  );
  const meetingLinkConfig = useSelector((state) => getMeetingLinksConfig(state, selectedScheduleId || ''));
  const scheduleContent = useSelector(getScheduleContent);
  const customVideoMeetingLink = useSelector((state) => getCustomVideoMeetingLink(state, selectedScheduleId || ''));

  const originalCandidateGoogleEventId = useSelector(getOriginalCandidateGoogleEventId);
  const eventOldContent = useSelector(getEventOldContent)?.find(
    (event) => event.googleEventId === originalCandidateGoogleEventId
  );
  const leftNavExpanded = useSelector(getLeftNavExpanded);
  const { candidate: storeCandidate } = useSelector((state) => state.scheduling);

  const { slackChannelEnabled, conversationId } = useSelector(
    (state) => state.scheduleCommunications.byId[selectedScheduleId || ''] || {}
  );

  const addedCodeLinkTypes = useSelector((state) => {
    if (!selectedScheduleId) return [];
    return getAddedCodeLinkTypes(state, selectedScheduleId);
  });

  const pendingCodeLinkTypes = difference(codingIntegrationTypes, addedCodeLinkTypes);

  const { candidateTimezone, jobId } = scheduleFlowData;

  const startAt = new Date(
    minBy(scheduleContent?.candidateEventContent?.schedule?.events, 'startAt')?.startAt ?? Date.now()
  );
  const candidateName = storeCandidate ? storeCandidate.fullName : '';
  const defaultChannelName = `${getSlackChannelName(candidateName, startAt, candidateTimezone)}`;
  let [preference] = useOrgPrefString(OrgPrefName.STR_SLACK_CHANNEL_NAME_FORMAT, defaultChannelName);
  if (preference === null) {
    preference = '';
  }
  const newChannelFormat = `${getSlackChannelFormat(candidateName, startAt, candidateTimezone, preference)}`;
  React.useEffect(() => {
    if (slackChannelEnabled && integrations?.slack?.active && !conversationId) {
      dispatch(updateSlackChannelName(selectedScheduleId || '', newChannelFormat));
    }
  }, [dispatch, newChannelFormat, slackChannelEnabled, integrations, selectedScheduleId, conversationId]);

  useInitScheduleCommunicationContent(selectedScheduleId);

  if (!jobId) {
    return <LoadingScreen />;
  }

  if (isLoading || !scheduleContent) {
    return <LoadingContent />;
  }

  const showSlack = integrations?.slack?.active ?? false;
  const showAdd = (showSlack && !slackChannelEnabled) || pendingCodeLinkTypes.length > 0;

  const handleZoomMeetingConfigChange = (config: MeetingLinksConfig) => {
    if (!selectedScheduleId) return;
    dispatch(updateZoomMeetingConfig(selectedScheduleId, config));
  };
  const handleCustomMeetingLinkConfigChange = (config: MeetingLinksConfig) => {
    dispatch(updateCustomVideoMeetingLinksConfig(selectedScheduleId || '', config));
  };
  const handleMeetingLinksConfigChange = (meetingLinksConfig: MeetingLinksConfig) => {
    if (!selectedScheduleId) return;
    dispatch(updateVideoMeetingLinkConfig(selectedScheduleId, meetingLinksConfig));
  };

  const leftPane = (
    <Stack spacing={2} direction="column">
      <LocationCard
        handleZoomMeetingConfigChange={handleZoomMeetingConfigChange}
        scheduleFlow={ScheduleFlowType.UPDATE}
        customVideoMeetingURL={customVideoMeetingLink}
        meetingLinkConfig={meetingLinkConfig}
        interviewMeetingLocationSetting={undefined}
        candidatePreferredPhoneNumber={candidate?.preferredPhoneNumber || undefined}
        handleCustomMeetingLinkConfigChange={handleCustomMeetingLinkConfigChange}
        selectedScheduleId={selectedScheduleId || ''}
        onMeetingLinksConfigChange={handleMeetingLinksConfigChange}
      />
      <CalendarCard
        candidateCalendarId={candidateCalendarId}
        interviewerCalendarId={interviewerCalendarId}
        isPrivateEvent={isPrivateCalendarEvent}
        onCandidateCalendarChange={(id) => dispatch(updateCandidateCalendarId(id))}
        onInterviewerCalendarChange={(id) => dispatch(updateInterviewerCalendarId(id))}
        onPrivacyChange={(val) => dispatch(updateIsPrivateCalendarEvent(val))}
      />

      <Stack direction="column" spacing={1}>
        {selectedScheduleId && <CodingCard scheduleId={selectedScheduleId} interviewPlan={interviewPlan} />}
        {showSlack && slackChannelEnabled && (
          <SlackChannelCard
            scheduleId={selectedScheduleId || ''}
            candidateName={candidateName}
            startAt={startAt}
            candidateTimezone={candidateTimezone}
            interviewPlan={interviewPlan}
          />
        )}
      </Stack>
      <Stack spacing={1}>
        {showAdd && (
          <div style={{ paddingTop: '8px', paddingLeft: '24px' }}>
            <Label variant="body">Add:</Label>
          </div>
        )}
        {showSlack && !slackChannelEnabled && (
          <Button
            fullWidth
            label="Slack"
            variant="outlined"
            size="medium"
            startIcon={<SlackIcon />}
            onClick={() => {
              dispatch(updateSlackChannelEnabled(selectedScheduleId || '', !slackChannelEnabled));
            }}
          />
        )}
        {pendingCodeLinkTypes.map((pendingCodeLinkType) => {
          return (
            <Button
              key={pendingCodeLinkType}
              fullWidth
              label={getCodingUrlTitle(pendingCodeLinkType)}
              variant="outlined"
              size="medium"
              startIcon={iconMap[pendingCodeLinkType]}
              onClick={() => {
                dispatch(addCodeLinkType(selectedScheduleId || '', pendingCodeLinkType));
              }}
            />
          );
        })}
      </Stack>
      <CandidateEmailCard
        interviewPlan={interviewPlan}
        summary={scheduleContent.candidateEventContent.summary}
        onSummaryChange={(value) => {
          if (selectedScheduleId) dispatch(updateCandidateContentSummary(selectedScheduleId, value));
        }}
        description={scheduleContent.candidateEventContent.description}
        onDescriptionChange={(value) => {
          if (selectedScheduleId) dispatch(updateCandidateContentDescription(selectedScheduleId, value));
        }}
        timezone={candidateTimezone}
        onTimezoneChange={(tz) => {
          if (tz) {
            scheduleFlowData.setCandidateTimezone(tz);
          }
        }}
        templateID={candidateTemplateID}
        onTemplateIDChange={(value) => {
          dispatch(updateCandidateTemplateID(value));
        }}
      />
      <CandidateEventCard
        interviewPlan={interviewPlan}
        subject={scheduleContent.candidateCalendarEventContent.subject}
        onSubjectChange={(subject) => {
          if (selectedScheduleId) dispatch(updateCandidateCalendarSummary(selectedScheduleId, subject));
        }}
        body={scheduleContent.candidateCalendarEventContent.body}
        onBodyChange={(body) => {
          if (selectedScheduleId) dispatch(updateCandidateCalendarDescription(selectedScheduleId, body));
        }}
        templateID={candidateCalendarEventTemplateId}
        onTemplateIDChange={(templateID) => {
          dispatch(updateCandidateCalendarTemplateID(templateID));
        }}
        old={
          <OldContent
            title={scheduleContent.candidateCalendarEventContent.subject}
            description={eventOldContent?.oldDescription}
            summary={eventOldContent?.oldSummary}
          />
        }
      />
      <InterviewerCommunicationCards interviewPlan={interviewPlan} />
    </Stack>
  );

  return (
    <TwoColumnLayout
      leftComponent={leftPane}
      rightComponent={<CommunicationsPreview onComplete={onComplete} />}
      mobileBreakpoint={leftNavExpanded ? 'md' : 'sm'}
    />
  );
};

UpdateCommunications.fragments = {
  candidate: gql`
    fragment UpdateCommunications_candidate on Candidate {
      id
      preferredPhoneNumber
    }
  `,
  interviewPlan: gql`
    ${CandidateEmailCard.fragments.interviewPlan}
    ${CandidateEventCard.fragments.interviewPlan}
    ${CodingCard.fragments.interviewPlan}
    ${InterviewerCommunicationCards.fragments.interviewPlan}
    ${SlackChannelCard.fragments.interviewPlan}
    fragment UpdateCommunications_interviewPlan on JobStage {
      id
      ...CandidateEmailCard_interviewPlan
      ...CandidateEventCard_interviewPlan
      ...CodingCard_interviewPlan
      ...InterviewerCommunicationCards_interviewPlan
      ...SlackChannelCard_interviewPlan
    }
  `,
  jobStageSettings: gql`
    ${LocationCard.fragments.interviewMeetingLocationSetting}
    fragment UpdateCommunications_jobStageSettings on JobStageSettingsV2 {
      id
      interviewMeetingLocationSettingWithLevel {
        interviewMeetingLocationSetting {
          ...LocationCard_interviewMeetingLocationSetting
        }
      }
    }
  `,
};

export default UpdateCommunications;
