import React, { FC, useEffect, useMemo } from 'react';

import { LinearProgress } from '@mui/material';
import { first, uniqBy } from 'lodash';

import Autocomplete from 'src/components/Autocomplete';
import Stack from 'src/components/Stack';
import Label from 'src/components/label';
import Tooltip from 'src/components/tooltip';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

import useCalendars from 'src/hooks/useCalendars';
import useDirectoryInfo from 'src/hooks/useDirectoryInfo';

import { Calendar } from 'src/utils/api/getCalendars';

import { PAGE_SIZE } from 'src/constants';

const IS_READ_ONLY = false;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface CalendarSelectProps {
  calendarId?: string | null | undefined;
  onChange?: (remoteCalendarId: string | null) => void;
  error?: boolean;
  fullWidth?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  haveSelectedOption?: boolean;
  disableClearable?: boolean;
  dataTestId?: string;
  useTooltip?: boolean;

  // Whether or not to call onChange(null) after loading all calendars and not finding the passed in `calendarId`
  // in the users access list
  resetUnknownCalendarsOnLoad?: boolean;

  // When using a default calendar, set the label
  defaultCalendarLabel?: string;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const CalendarSelect: FC<CalendarSelectProps> = ({
  calendarId,
  fullWidth,
  disableClearable,
  dataTestId,
  error,
  onChange,
  useTooltip,
  resetUnknownCalendarsOnLoad = true,
  defaultCalendarLabel,
}) => {
  const { isLoading, isError, data } = useCalendars(PAGE_SIZE, IS_READ_ONLY);
  const directoryInfo = useDirectoryInfo();

  const hasSelected = !!calendarId;

  const nullOption: Calendar = useMemo(
    () => ({
      id: null,
      summary: 'Select a calendar',
    }),
    []
  );

  const calendars = useMemo(() => {
    const result: Calendar[] = [];

    if (hasSelected) {
      result.push(nullOption);
    }

    if (!data?.pages?.length) return result;

    return uniqBy(result.concat(data.pages.map((p) => p.calendar).flat()), (c) => c.id);
  }, [data?.pages, hasSelected, nullOption]);

  useEffect(() => {
    if (
      onChange &&
      calendarId &&
      calendars.length &&
      !calendars.find((cal) => cal.id === calendarId) &&
      resetUnknownCalendarsOnLoad
    ) {
      onChange(null);
    }
  }, [calendarId, resetUnknownCalendarsOnLoad, onChange, calendars]);

  if (isLoading || !data) {
    return (
      <LinearProgress
        data-testid="calendar-select-loading"
        style={{ width: '100%', marginTop: 12, marginBottom: 12 }}
      />
    );
  }

  if (isError) {
    return <Label variant="captions">{directoryInfo.label} Calendars failed to load</Label>;
  }

  const handleChange = (_event, option: Calendar) => {
    if (onChange) {
      onChange((option && option.id) || '');
    }
  };

  const getOptionLabel = (option: Calendar) => option.summary;
  const renderOption = (option: Calendar) => (
    <Stack>
      <MaybeTooltip label={option.summary} labelProps={{ variant: 'captions' }} tooltip={option.summary} />
    </Stack>
  );

  const getOptionFromValue = (value: string | null | undefined) => {
    if (!value) {
      return nullOption;
    }

    const option = first((calendars as Calendar[]).filter((calendarOption) => calendarOption.id === value)) || null;

    if (option) {
      if (defaultCalendarLabel && option.summary.indexOf(`${defaultCalendarLabel}`) === -1) {
        option.summary = `${defaultCalendarLabel} (${option.summary})`;
      }
      return option;
    }
    const unknownOption = {
      id: value,
      summary: 'Unknown Calendar',
    };
    calendars.push(unknownOption);
    return unknownOption;
  };

  const option = getOptionFromValue(calendarId);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/no-multi-comp
  const CalendarAutocomplete: React.FC = () => (
    <Autocomplete
      disableClearable={disableClearable}
      error={error}
      dataTestId={dataTestId || 'calendar-select'}
      style={fullWidth ? {} : { width: 260 }}
      id="calendar-select-autocomplete"
      options={calendars}
      value={option}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      renderOption={renderOption}
      ListboxProps={{
        getChildSize: () => {
          return 48;
        },
      }}
    />
  );

  return (
    <>
      {useTooltip && (
        <Tooltip tooltip={option.summary} fullWidth placement="top-start">
          <CalendarAutocomplete />
        </Tooltip>
      )}
      {!useTooltip && <CalendarAutocomplete />}
    </>
  );
};

export default CalendarSelect;
