import { BOOL_FEATURE_FLAGS } from './FeatureFlagBool';
import { JSON_FEATURE_FLAGS } from './FeatureFlagJson';
import { NUMBER_FEATURE_FLAGS } from './FeatureFlagNumber';
import { STRING_FEATURE_FLAGS } from './FeatureFlagString';
import { useFeatureFlagBool } from './useFeatureFlagBool';

/**
 * Feature flag helpers.
 *
 * This functions wrap LaunchDarkly's API for more type-saftey and clean-up accountability.
 *
 * https://docs.launchdarkly.com/home/getting-started/feature-flags
 */

export type { FeatureFlagBool } from './FeatureFlagBool';
export type { FeatureFlagJson } from './FeatureFlagJson';
export type { FeatureFlagNumber } from './FeatureFlagNumber';
export type { FeatureFlagString } from './FeatureFlagString';
export type { LocalStorageFlags, FeatureFlagNames } from './useFeatureFlagLocal';
export { BOOL_FEATURE_FLAGS } from './FeatureFlagBool';
export { JSON_FEATURE_FLAGS } from './FeatureFlagJson';
export { NUMBER_FEATURE_FLAGS } from './FeatureFlagNumber';
export { STRING_FEATURE_FLAGS } from './FeatureFlagString';
export { useFeatureFlagBool } from './useFeatureFlagBool';
export { useFeatureFlagNumber } from './useFeatureFlagNumber';
export { useFeatureFlagString } from './useFeatureFlagString';
export { useFeatureFlagJson } from './useFeatureFlagJson';
export { useFeatureFlagLocal } from './useFeatureFlagLocal';

export const useFlag = useFeatureFlagBool; // shorthand function

export const ALL_FEATURE_FLAGS = [
  ...BOOL_FEATURE_FLAGS,
  ...NUMBER_FEATURE_FLAGS,
  ...STRING_FEATURE_FLAGS,
  ...JSON_FEATURE_FLAGS,
];
