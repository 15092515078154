import { useEffect, useMemo, useRef, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';
import { first, isArray, isEmpty, isNil, merge, uniqBy } from 'lodash';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

import {
  RecentlyUsedZoomUsersEmployeePrefFragment,
  ZoomUserFragmentFragment,
  ZoomUserStatus,
  ZoomUsersListQuery,
  useZoomUserByIdsLazyQuery,
  useZoomUsersByIdsLazyQuery,
  useZoomUsersListLazyQuery,
} from 'src/generated/mloop-graphql';

import { FilterListSelectInterface } from 'src/components/FilterList/FilterSelect';

import { addLastUsedZoomUserIds } from 'src/slices/persist';

import { DEBOUNCE_TIMEOUT, PAGER_DEFAULT } from 'src/constants';
import { useDispatch, useSelector } from 'src/store';

import { Categories, ZoomUserDataWithCategory, ZoomUserSelectProps, ZoomUserSelectValue } from './types';
import useRecentlyUsedZoomUsersEmployeePref from './useRecentlyUsedZoomUserEmployeePref';
import useRecentlyUsedZoomUsersEmployeePrefMutation from './useRecentlyUsedZoomUsersEmployeePrefMutation';

// Added this variable for quick testing of pagination in case anything fails.
const PAGE_LIMIT = PAGER_DEFAULT;
const MAX_LAST_USED_ZOOM_ACCOUNTS = 5;

const useZoomUserSelectData = <
  TData extends ZoomUserDataWithCategory,
  InputValue extends string | string[] | undefined
>({
  value,
  onChange,
  panelInterviewerIds,
  additionalOptions,
  isAdditionalOptionId,
  shouldHidePopoverOnSelect = true,
  shouldInvokeOnChangeOnExistingUsersDataFetch = false,
}: ZoomUserSelectProps<TData, InputValue>) => {
  const dispatch = useDispatch();

  const isInputValueArray = isArray(value);

  const { lastUsedZoomUserIds } = useSelector((state) => state.persist);

  const filtersEmployeePrefEnabled = useFlag('user_migrate_recent_zoom_users_to_employee_pref');

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_TIMEOUT);

  const [
    fetchUsers,
    {
      loading: isZoomUsersListLoading,
      error: isZoomUsersListQueryError,
      data: zoomUsersListData,
      fetchMore: fetchMoreZoomUsers,
      updateQuery: updateZoomUsersQuery,
    },
  ] = useZoomUsersListLazyQuery();

  const [
    fetchRecentlyUsedZoomUsers,
    {
      data: recentlyUsedZoomUsersData,
      loading: isRecentlyUsedZoomUsersQueryLoading,
      error: isRecentlyUsedZoomUsersQueryError,
    },
  ] = useZoomUserByIdsLazyQuery();

  const [
    fetchExistingZoomUsers,
    { data: existingZoomUsersData, loading: isExistingZoomUsersQueryLoading, error: isExistingZoomUsersQueryError },
  ] = useZoomUserByIdsLazyQuery();

  const [
    fetchPanelInterviewersZoomUsers,
    {
      data: panelInterviewersZoomUsersData,
      loading: isPanelInterviewersZoomUsersQueryLoading,
      error: isPanelInterviewersZoomUsersQueryError,
    },
  ] = useZoomUsersByIdsLazyQuery();

  const defaultSelectedUsers = useMemo(() => {
    const user = additionalOptions?.find((option) => option?.zoomUserId === value);
    return user ? [user] : undefined;
  }, [additionalOptions, value]);

  const [selectedUsers, setSelectedUsers] = useState<ZoomUserDataWithCategory[] | undefined>(defaultSelectedUsers);
  const [lastUsedZoomUserPref, setLastUsedZoomUserPref] = useState<ZoomUserFragmentFragment[]>([]);
  const [isExistingZoomUsersDataSet, setIsExistingZoomUsersDataSet] = useState<boolean>(false);
  const [fetchingMore, setFetchingMore] = useState(false);
  const filterListSelectInterfaceRef = useRef<FilterListSelectInterface | null>(null);

  useEffect(() => {
    if (value && !isExistingZoomUsersDataSet) {
      if (
        !isExistingZoomUsersQueryLoading &&
        !isNil(existingZoomUsersData) &&
        !isEmpty(existingZoomUsersData) &&
        !isExistingZoomUsersQueryError &&
        existingZoomUsersData.zoomUsersByIds
      ) {
        const existingUsers: ZoomUserFragmentFragment[] = existingZoomUsersData.zoomUsersByIds;

        setIsExistingZoomUsersDataSet(true);
        setSelectedUsers(existingUsers.length ? existingUsers : defaultSelectedUsers);

        if (shouldInvokeOnChangeOnExistingUsersDataFetch && onChange && existingUsers) {
          onChange(existingUsers as ZoomUserSelectValue<TData, InputValue>);
        }
      } else if (isNil(existingZoomUsersData) && !isExistingZoomUsersQueryLoading && !isExistingZoomUsersQueryError) {
        fetchExistingZoomUsers({ variables: { input: { zoomUserIds: isInputValueArray ? value : [value] } } });
      }
    }
  }, [
    defaultSelectedUsers,
    existingZoomUsersData,
    fetchExistingZoomUsers,
    isExistingZoomUsersDataSet,
    isExistingZoomUsersQueryError,
    isExistingZoomUsersQueryLoading,
    isInputValueArray,
    onChange,
    shouldInvokeOnChangeOnExistingUsersDataFetch,
    value,
  ]);

  const handleScrollToEnd = () => {
    if (
      !zoomUsersListData ||
      !zoomUsersListData.zoomUsers ||
      !zoomUsersListData.zoomUsers.items ||
      isZoomUsersListLoading ||
      !zoomUsersListData.zoomUsers.nextCursor ||
      !fetchMoreZoomUsers
    ) {
      return;
    }

    setFetchingMore(true);
    fetchMoreZoomUsers({
      variables: {
        input: {
          pageInput: {
            cursor: zoomUsersListData.zoomUsers.nextCursor,
            limit: PAGE_LIMIT,
          },
          prefix: '',
        },
      },
    })
      ?.then((result: ApolloQueryResult<ZoomUsersListQuery>) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line promise/always-return
        if (!updateZoomUsersQuery) return;

        updateZoomUsersQuery((prevResult: ZoomUsersListQuery) => {
          const { zoomUsers } = prevResult;
          const newResults = merge({}, prevResult, result.data);

          if (newResults.zoomUsers?.items) {
            newResults.zoomUsers.items = uniqBy(
              [...(zoomUsers?.items || []), ...(result.data?.zoomUsers?.items || [])],
              'zoomUserId'
            );
          }

          return { ...newResults };
        });
      })
      .finally(() => {
        setFetchingMore(false);
      });
  };

  const debouncedHandleScrollToEnd = useDebouncedCallback(handleScrollToEnd, DEBOUNCE_TIMEOUT);

  useEffect(() => {
    fetchUsers({
      variables: {
        input: {
          pageInput: {
            limit: PAGE_LIMIT,
          },
          prefix: debouncedSearch,
        },
      },
    });
  }, [debouncedSearch, fetchUsers]);

  const recentUsersPref = useRecentlyUsedZoomUsersEmployeePref((data) => {
    if (!data.thisEmployee?.employeePref?.jsonPref || !filtersEmployeePrefEnabled) return;
    const pref = data.thisEmployee.employeePref.jsonPref as RecentlyUsedZoomUsersEmployeePrefFragment;
    setLastUsedZoomUserPref(pref.zoomUsers.filter((user) => user.status === ZoomUserStatus.Active));
  });

  const updateFilterPrefs = useRecentlyUsedZoomUsersEmployeePrefMutation();

  //   const onFiltersChange = (updatedFilters: RecentlyUsedZoomUsersEmployeePrefFragment) => {};

  const handleAddAccount = (id: string) => {
    for (const recentId of lastUsedZoomUserIds) {
      if (recentId === id) {
        return;
      }
    }

    dispatch(addLastUsedZoomUserIds(id));
  };

  const handleUpdatePref = (user: ZoomUserFragmentFragment) => {
    // Check if the timezone is already in the list
    for (const recentUser of lastUsedZoomUserPref) {
      if (recentUser.zoomUserId === user.zoomUserId) {
        return;
      }
    }

    const lastUsedZoomUserIdsCopy = [...lastUsedZoomUserPref];
    lastUsedZoomUserIdsCopy.unshift(user);

    // Truncate the list to a max of 5 elements
    const zoomUsers = lastUsedZoomUserIdsCopy.slice(0, MAX_LAST_USED_ZOOM_ACCOUNTS);

    updateFilterPrefs({
      zoomUserIds: zoomUsers.map((zoomUser) => zoomUser.zoomUserId),
    });
    setLastUsedZoomUserPref(zoomUsers);
  };

  const users = useMemo(() => {
    let usersAraay: ZoomUserDataWithCategory[] = (
      zoomUsersListData ? zoomUsersListData.zoomUsers?.items : []
    ) as ZoomUserDataWithCategory[];
    if (filtersEmployeePrefEnabled) {
      usersAraay = [...usersAraay, ...lastUsedZoomUserPref];
    } else if (
      !isNil(recentlyUsedZoomUsersData) &&
      !isEmpty(recentlyUsedZoomUsersData) &&
      !isRecentlyUsedZoomUsersQueryError
    ) {
      const recentlyUsedZoomUsers = (
        recentlyUsedZoomUsersData ? recentlyUsedZoomUsersData.zoomUsersByIds : []
      ) as ZoomUserDataWithCategory[];

      usersAraay = [...usersAraay, ...recentlyUsedZoomUsers];
    }

    if (
      !isNil(panelInterviewersZoomUsersData) &&
      !isEmpty(panelInterviewersZoomUsersData) &&
      !isPanelInterviewersZoomUsersQueryError
    ) {
      const panelInterviewersZoomUsers = (
        panelInterviewersZoomUsersData ? panelInterviewersZoomUsersData.zoomUsersByIds : []
      ) as ZoomUserDataWithCategory[];

      usersAraay = [...usersAraay, ...panelInterviewersZoomUsers];
    }

    return usersAraay
      .map((user) => ({ ...user, category: Categories.AllAccounts }))
      .filter((user) => !!user?.zoomUserId);
  }, [
    filtersEmployeePrefEnabled,
    isPanelInterviewersZoomUsersQueryError,
    isRecentlyUsedZoomUsersQueryError,
    lastUsedZoomUserPref,
    panelInterviewersZoomUsersData,
    recentlyUsedZoomUsersData,
    zoomUsersListData,
  ]);

  // Fetching recent interviewer details if they do not exist in existing users data.
  useEffect(() => {
    if (filtersEmployeePrefEnabled) return;

    if (
      zoomUsersListData &&
      !isNil(lastUsedZoomUserIds) &&
      !isEmpty(lastUsedZoomUserIds) &&
      !isRecentlyUsedZoomUsersQueryLoading &&
      !recentlyUsedZoomUsersData
    ) {
      let recentlyUsedZoomUserIds = lastUsedZoomUserIds ? [...lastUsedZoomUserIds] : [];

      const existingZoomUserIds = users
        .filter((user) => recentlyUsedZoomUserIds.includes(user.zoomUserId))
        .map((user) => user.zoomUserId);

      if (!isEmpty(existingZoomUserIds)) {
        recentlyUsedZoomUserIds = recentlyUsedZoomUserIds.filter((userId) => !existingZoomUserIds.includes(userId));
      }

      if (!isEmpty(recentlyUsedZoomUserIds)) {
        const ids = recentlyUsedZoomUserIds
          .filter((id) => !isAdditionalOptionId || !isAdditionalOptionId(id))
          .filter((id) => !isNil(id));
        if (!ids.length) return;
        fetchRecentlyUsedZoomUsers({ variables: { input: { zoomUserIds: ids } } });
      }
    }
  }, [
    fetchRecentlyUsedZoomUsers,
    isRecentlyUsedZoomUsersQueryLoading,
    isAdditionalOptionId,
    lastUsedZoomUserIds,
    recentlyUsedZoomUsersData,
    users,
    zoomUsersListData,
    filtersEmployeePrefEnabled,
    lastUsedZoomUserPref,
    recentUsersPref,
  ]);

  // Fetching panel interviewer details if they do not exist in existing users data.
  useEffect(() => {
    if (
      zoomUsersListData &&
      !isNil(panelInterviewerIds) &&
      !isEmpty(panelInterviewerIds) &&
      !isPanelInterviewersZoomUsersQueryLoading &&
      !panelInterviewersZoomUsersData
    ) {
      let panelInterviewerIdsArray = panelInterviewerIds ? [...panelInterviewerIds] : [];

      const existingZoomUserIds = users
        .filter((user) => {
          if (user?.employeeId) {
            return panelInterviewerIdsArray.includes(user.employeeId);
          }
          return false;
        })
        .map((user) => user.employeeId);

      if (!isEmpty(existingZoomUserIds)) {
        panelInterviewerIdsArray = panelInterviewerIdsArray.filter(
          (interviewerId) => !existingZoomUserIds.includes(interviewerId)
        );
      }

      if (!isEmpty(panelInterviewerIdsArray)) {
        const ids = panelInterviewerIdsArray
          .filter((id) => !isAdditionalOptionId || !isAdditionalOptionId(id))
          .filter((id) => !isNil(id));
        if (!ids.length) return;
        fetchPanelInterviewersZoomUsers({ variables: { input: { employeeIds: ids } } });
      }
    }
  }, [
    fetchPanelInterviewersZoomUsers,
    isPanelInterviewersZoomUsersQueryLoading,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line max-lines
    isAdditionalOptionId,
    panelInterviewerIds,
    panelInterviewersZoomUsersData,
    users,
    zoomUsersListData,
  ]);

  const handleChange = (_event, allOptions: ZoomUserDataWithCategory[]) => {
    const selectedOption = first(allOptions);

    if (!selectedOption) return;

    if (selectedOption?.zoomUserId) {
      if (onChange) {
        onChange((isInputValueArray ? allOptions : selectedOption) as ZoomUserSelectValue<TData, InputValue>);
      }

      if (filtersEmployeePrefEnabled) {
        handleUpdatePref(selectedOption);
      } else {
        handleAddAccount(selectedOption?.zoomUserId);
      }

      setSelectedUsers([...allOptions]);
    }
    if (shouldHidePopoverOnSelect) {
      filterListSelectInterfaceRef.current?.dismiss();
    }
  };

  const panelZoomUsers = useMemo(
    () =>
      panelInterviewerIds
        .map((interviewerId) => {
          for (let i = 0; i < users.length; i++) {
            if (users[i].employeeId === interviewerId && users[i].zoomUserId) {
              return {
                ...users[i],
                category: Categories.InterviewPanel,
              };
            }
          }
          return null;
        })
        .filter((user) => !!user?.zoomUserId),
    [panelInterviewerIds, users]
  );

  const recentZoomUsers = useMemo(
    () =>
      (!filtersEmployeePrefEnabled ? lastUsedZoomUserIds : lastUsedZoomUserPref.map((user) => user.zoomUserId))
        .map((userId) => {
          for (let i = 0; i < users.length; i++) {
            if (users[i]?.zoomUserId === userId) {
              return {
                ...users[i],
                category: Categories.RecentAccounts,
              };
            }
          }
          return null;
        })
        .filter((user) => !!user?.zoomUserId),
    [filtersEmployeePrefEnabled, lastUsedZoomUserIds, lastUsedZoomUserPref, users]
  );

  const options = uniqBy(
    [...(additionalOptions || ([] as ZoomUserDataWithCategory[])), ...panelZoomUsers, ...recentZoomUsers, ...users],
    'email'
  );

  const onInputChange = (_, inputValue: string) => {
    setSearch(inputValue);
  };

  const selectedValues = useMemo(
    () => (isInputValueArray ? selectedUsers : undefined),
    [isInputValueArray, selectedUsers]
  );

  return {
    options,
    selectedUsers,
    handleChange,
    onInputChange,
    filterListSelectInterfaceRef,
    handleScrollToEnd: debouncedHandleScrollToEnd,
    isFetchingMore: fetchingMore,
    selectedValues,
    isZoomUsersListLoading,
    isZoomUsersListQueryError,
    isSearchEmpty: isEmpty(debouncedSearch),
  };
};

export default useZoomUserSelectData;
