import { createSelector } from '@reduxjs/toolkit';

import { IdToNullableIdMap, OldContent } from 'src/slices/scheduling';

import { InterviewerEventContent } from 'src/store/slices/schedule-communications';

import { State } from 'src/store';

export const getSelectedScheduleId = (state: State): string | null => state.scheduling.selectedScheduleId;

export const getCandidateTemplateID = (state: State): string | null => state.scheduling.candidateTemplateID;

export const getCandidateCalendarTemplateID = (state: State) => state.scheduling.candidateCalendarTemplateID;

export const getInitialInterviewerEventTemplateID = (state: State): string | null =>
  state.scheduling.initialInterviewerEventTemplateID;

export const getInterviewerTemplateIDs = (state: State): IdToNullableIdMap => state.scheduling.interviewerTemplateIDs;

export const getHasSameContent = (state: State): boolean => state.scheduling.hasSameContent;

export const getSlackChannelEnabled = (state: State): boolean => state.scheduling.slackChannelEnabled;

export const getEventOldContent = (state: State): OldContent[] => state.scheduling.eventOldContent;

export const getOriginalCandidateGoogleEventId = (state: State): string | null =>
  state.scheduling.originalCandidateGoogleEventId;

export const getCandidateCommunicationsEnabled = (state: State): boolean =>
  state.scheduling.candidateCommunicationsEnabled;

export const getCandidateEventCommunicationsEnabled = (state: State): boolean =>
  state.scheduling.candidateEventCommunicationsEnabled;

export const getCandidateId = (state: State): string | unknown => state.scheduling.candidate?.rowID;

export const getCandidateEmail = (state: State): string | null => state.scheduling.candidate?.email || null;

export const getCandidateFullName = (state: State): string | null => state.scheduling.candidate?.fullName || null;

export const getCCEmployeeIDs = (state: State): string[] => state.scheduling.ccEmployeeIDs;

export const getBCCEmployeeIDs = (state: State): string[] => state.scheduling.bccEmployeeIDs;

export const getCCExternalAddresses = (state: State): string[] => state.scheduling.ccExternalAddresses;

export const getBCCExternalAddresses = (state: State): string[] => state.scheduling.bccExternalAddresses;

export const getInterviewerEventContents = (state: State): InterviewerEventContent[] | undefined => {
  return state.scheduleCommunications.byId[state.scheduling.selectedScheduleId || '']?.scheduleContent
    ?.interviewerEventContents;
};

export const getInterviewerIdsList = (state: State): string[] => {
  const interviewerEventContents = getInterviewerEventContents(state);
  const interviewerIds =
    interviewerEventContents
      ?.map((content) => {
        return content.event.interviewers.map((interviewer) => {
          return interviewer.employeeId;
        });
      })
      .flat() || [];

  return interviewerIds;
};

export const getStepInterviewPlan = (
  state: State
): {
  loading: boolean;
  submitting: boolean;
  error: string | null;
} => state.scheduling.stepInterviewPlan;

export const getStepScheduleOptions = (
  state: State
): {
  loading: boolean;
  submitting: boolean;
  error: string | null;
  shouldRefreshOptions: boolean;
} => state.scheduling.stepScheduleOptions;

export const getShouldRefreshOptions = (state: State): boolean =>
  state.scheduling.stepScheduleOptions.shouldRefreshOptions;

/**
 * Created using createSelector
 */

export const getSelectedSchedule = createSelector(
  (state: State) => state,
  getSelectedScheduleId,
  (state, scheduleId) => (scheduleId ? state.schedules.byId[scheduleId] : null)
);

export const getWorkspaceVideoLink = (state: State) =>
  state.scheduleCommunications.byId[state.scheduling.selectedScheduleId || '']?.videoMeetingLink;

export const getCandidateCalendarId = (state: State) => state.scheduling.candidateCalendarId;

export const getInterviewerCalendarId = (state: State) => state.scheduling.interviewerCalendarId;

export const getIsPrivateCalendarEvent = (state: State) => state.scheduling.isPrivateCalendarEvent;
