import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date (example format: 2023-03-28) */
  date: any;
  /** Date time */
  datetime: any;
  /** A JSONB scalar */
  jsonb: any;
  /** Local Time (example format: 23:59) */
  localtime: any;
  /** numeric */
  numeric: any;
  /** Timezone */
  timezone: any;
  /** UUID */
  uuid: any;
};






export type ActivityCreateResult = {
  __typename?: 'ActivityCreateResult';
  activityLog?: Maybe<ActivityLog>;
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  id: Scalars['uuid'];
  sourceType: SourceType;
  sourceId: Scalars['uuid'];
  activityType: ActivityType;
  createdAt: Scalars['datetime'];
  activityActorType: ActorType;
  activityActorEmployeeId?: Maybe<Scalars['uuid']>;
  actorEmployee?: Maybe<Employee>;
  details?: Maybe<ActivityLogDetails>;
  source?: Maybe<ActivityLogSource>;
};

export type ActivityLogApplicationStateChangeDetails = {
  __typename?: 'ActivityLogApplicationStateChangeDetails';
  applicationStatus: ApplicationStatus;
};

export type ActivityLogCandidatePortalNoteDetails = {
  __typename?: 'ActivityLogCandidatePortalNoteDetails';
  candidatePortalNoteId: Scalars['uuid'];
  content?: Maybe<Scalars['String']>;
  sendToCandidate?: Maybe<Scalars['Boolean']>;
};

export type ActivityLogCandidatePreferredPhoneNumberChangeDetails = {
  __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails';
  updatedPhoneNumber?: Maybe<Scalars['String']>;
  isUnset?: Maybe<Scalars['Boolean']>;
};

export type ActivityLogCandidateViewedTabDetails = {
  __typename?: 'ActivityLogCandidateViewedTabDetails';
  tabName: Scalars['String'];
  currentJobStageId: Scalars['uuid'];
};

/**
 * Keep this union in sync with `details` messages in `activity_log_db.proto`!
 * --------
 */
export type ActivityLogDetails = ActivityLogTaskDetails | ActivityLogScheduleDetails | ActivityLogNoteDetails | ActivityLogTaskTagDetails | ActivityLogEmailDetails | ActivityLogRsvpDetails | ActivityLogFlagDetails | ActivityLogRescheduleDetails | ActivityLogTaskStatusDetails | ActivityLogTaskAssigneeDetails | ActivityLogTaskInterviewerDetails | ActivityLogTaskMeetingRoomDetails | ActivityLogTaskEventDetails | ActivityLogTaskAvailabilityDetails | ActivityLogTaskReadyForCandidateCommDetails | ActivityLogRescheduleLogDetails | ActivityLogCandidateViewedTabDetails | ActivityLogApplicationStateChangeDetails | ActivityLogTaskQueueDetails | ActivityLogCandidatePortalNoteDetails | ActivityLogCandidatePreferredPhoneNumberChangeDetails;

export type ActivityLogEmailDetails = {
  __typename?: 'ActivityLogEmailDetails';
  toEmailAddress: Scalars['String'];
  fromEmailAddress: Scalars['String'];
  ccAddresses: Array<Scalars['String']>;
  subject: Scalars['String'];
  body: Scalars['String'];
};

export type ActivityLogFlagDetails = {
  __typename?: 'ActivityLogFlagDetails';
  flagContent: Scalars['String'];
};

export type ActivityLogNoteDetails = {
  __typename?: 'ActivityLogNoteDetails';
  note: Scalars['String'];
  isEdited: Scalars['Boolean'];
};

export type ActivityLogRescheduleDetails = {
  __typename?: 'ActivityLogRescheduleDetails';
  reason: Scalars['String'];
};

export type ActivityLogRescheduleLogDetails = {
  __typename?: 'ActivityLogRescheduleLogDetails';
  rescheduleLogId: Scalars['uuid'];
  rescheduleLog: RescheduleLog;
};

export type ActivityLogRsvpDetails = {
  __typename?: 'ActivityLogRsvpDetails';
  interviewName: Scalars['String'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  responseStatus: ResponseStatus;
  comment: Scalars['String'];
  interviewerId?: Maybe<Scalars['uuid']>;
  /**  This is not required for candidate RSVP */
  interviewer?: Maybe<Employee>;
  /**  This is not required for candidate RSVP */
  addedInterviewerId?: Maybe<Scalars['uuid']>;
  addedInterviewer?: Maybe<Employee>;
};

export type ActivityLogScheduleDetails = {
  __typename?: 'ActivityLogScheduleDetails';
  applicationStageId: Scalars['uuid'];
};

export type ActivityLogSource = Task | Application | Candidate;

export type ActivityLogTaskAssigneeDetails = {
  __typename?: 'ActivityLogTaskAssigneeDetails';
  assigneeId: Scalars['uuid'];
  assignee: Employee;
};

export type ActivityLogTaskAvailabilityDetails = {
  __typename?: 'ActivityLogTaskAvailabilityDetails';
  candidateAvailability: Array<DateTimeRangeOutput>;
  note: Scalars['String'];
  sourcingLinkId?: Maybe<Scalars['uuid']>;
};

export type ActivityLogTaskDetails = {
  __typename?: 'ActivityLogTaskDetails';
  taskId: Scalars['uuid'];
  task: Task;
  sourcingLinkId?: Maybe<Scalars['uuid']>;
  atsName?: Maybe<Scalars['String']>;
};

export type ActivityLogTaskEventDetails = {
  __typename?: 'ActivityLogTaskEventDetails';
  name: Scalars['String'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  selfScheduleCandidateNote: Scalars['String'];
  candidateCancellationReason?: Maybe<CandidateCancellationReason>;
  sourcingLinkId?: Maybe<Scalars['uuid']>;
};

export type ActivityLogTaskInterviewerDetails = {
  __typename?: 'ActivityLogTaskInterviewerDetails';
  interviewName: Scalars['String'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  interviewerId: Scalars['String'];
  interviewer: Employee;
};

export type ActivityLogTaskMeetingRoomDetails = {
  __typename?: 'ActivityLogTaskMeetingRoomDetails';
  interviewName: Scalars['String'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  meetingRoomId: Scalars['String'];
  room: MeetingRoom;
  responseStatus: ResponseStatus;
  comment: Scalars['String'];
};

export type ActivityLogTaskQueueDetails = {
  __typename?: 'ActivityLogTaskQueueDetails';
  taskQueueId: Scalars['uuid'];
  taskQueue?: Maybe<TaskQueue>;
};

export type ActivityLogTaskReadyForCandidateCommDetails = {
  __typename?: 'ActivityLogTaskReadyForCandidateCommDetails';
  subscriberIds: Array<Scalars['uuid']>;
};

export type ActivityLogTaskStatusDetails = {
  __typename?: 'ActivityLogTaskStatusDetails';
  taskStatus: TaskStatus;
};

export type ActivityLogTaskTagDetails = {
  __typename?: 'ActivityLogTaskTagDetails';
  tagIds: Array<Scalars['uuid']>;
  taskTags: Array<TaskTag>;
};

export type ActivityLogsForSourceInput = {
  pageInput: PageInput;
  activityTypes?: Maybe<Array<ActivityType>>;
  actorTypes?: Maybe<Array<ActorType>>;
};

export type ActivityLogsInput = {
  pageInput: PageInput;
  sourceType: SourceType;
  sourceId: Scalars['uuid'];
  activityTypes?: Maybe<Array<ActivityType>>;
};

export type ActivityLogsResult = {
  __typename?: 'ActivityLogsResult';
  items: Array<ActivityLog>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type ActivityNoteCreateInput = {
  sourceType: SourceType;
  sourceId: Scalars['uuid'];
  note: Scalars['String'];
};

export type ActivityNoteCreateResult = {
  __typename?: 'ActivityNoteCreateResult';
  activityLog?: Maybe<ActivityLog>;
};

export type ActivityNoteDeleteInput = {
  activityLogId: Scalars['uuid'];
};

export type ActivityNoteDeleteResult = {
  __typename?: 'ActivityNoteDeleteResult';
  result?: Maybe<Result>;
};

export type ActivityNoteEditInput = {
  activityLogId: Scalars['uuid'];
  note: Scalars['String'];
};

export type ActivityNoteEditResult = {
  __typename?: 'ActivityNoteEditResult';
  activityLog?: Maybe<ActivityLog>;
};

export type ActivityRescheduleCreateInput = {
  taskId: Scalars['uuid'];
  rescheduleReason: Scalars['String'];
};

/**
 * --------------------------------------------------------------------------------
 * Keep this enum in sync with `ActivityType` enum in `activity_log_db.proto`!
 */
export enum ActivityType {
  Note = 'NOTE',
  TaskCreated = 'TASK_CREATED',
  TaskClosed = 'TASK_CLOSED',
  TaskChangedStatus = 'TASK_CHANGED_STATUS',
  TaskTagAdded = 'TASK_TAG_ADDED',
  TaskTagRemoved = 'TASK_TAG_REMOVED',
  TaskRequirementCandidateAvailabilityAdded = 'TASK_REQUIREMENT_CANDIDATE_AVAILABILITY_ADDED',
  TaskRequirementCandidateAvailabilityUpdated = 'TASK_REQUIREMENT_CANDIDATE_AVAILABILITY_UPDATED',
  TaskRequirementCandidateAvailabilityRemoved = 'TASK_REQUIREMENT_CANDIDATE_AVAILABILITY_REMOVED',
  TaskRequirementSelfScheduleAdded = 'TASK_REQUIREMENT_SELF_SCHEDULE_ADDED',
  TaskRequirementSelfScheduleUpdated = 'TASK_REQUIREMENT_SELF_SCHEDULE_UPDATED',
  TaskRequirementSelfScheduleRemoved = 'TASK_REQUIREMENT_SELF_SCHEDULE_REMOVED',
  TaskRequirementAppDebriefAdded = 'TASK_REQUIREMENT_APP_DEBRIEF_ADDED',
  TaskRequirementAppDebriefRemoved = 'TASK_REQUIREMENT_APP_DEBRIEF_REMOVED',
  TaskRequirementScheduleAdded = 'TASK_REQUIREMENT_SCHEDULE_ADDED',
  TaskAssigned = 'TASK_ASSIGNED',
  TaskUnassigned = 'TASK_UNASSIGNED',
  TaskMarkedUrgent = 'TASK_MARKED_URGENT',
  TaskUnmarkedUrgent = 'TASK_UNMARKED_URGENT',
  TaskEmployeeEditedAvailability = 'TASK_EMPLOYEE_EDITED_AVAILABILITY',
  TaskCandidateEmailSent = 'TASK_CANDIDATE_EMAIL_SENT',
  TaskRescheduleRecorded = 'TASK_RESCHEDULE_RECORDED',
  TaskScheduleAdded = 'TASK_SCHEDULE_ADDED',
  TaskScheduleUpdated = 'TASK_SCHEDULE_UPDATED',
  TaskScheduleRescheduled = 'TASK_SCHEDULE_RESCHEDULED',
  TaskScheduleCanceled = 'TASK_SCHEDULE_CANCELED',
  TaskDebriefCreated = 'TASK_DEBRIEF_CREATED',
  TaskDebriefCanceled = 'TASK_DEBRIEF_CANCELED',
  TaskCandidateSelfScheduleCreated = 'TASK_CANDIDATE_SELF_SCHEDULE_CREATED',
  TaskCandidateSelfScheduleRescheduled = 'TASK_CANDIDATE_SELF_SCHEDULE_RESCHEDULED',
  TaskCandidateSelfScheduleCanceled = 'TASK_CANDIDATE_SELF_SCHEDULE_CANCELED',
  TaskChangeRequested = 'TASK_CHANGE_REQUESTED',
  TaskFlagAdded = 'TASK_FLAG_ADDED',
  TaskCandidateRsvp = 'TASK_CANDIDATE_RSVP',
  TaskInterviewerRsvp = 'TASK_INTERVIEWER_RSVP',
  TaskMeetingRoomRsvp = 'TASK_MEETING_ROOM_RSVP',
  TaskScheduleInterviewCanceled = 'TASK_SCHEDULE_INTERVIEW_CANCELED',
  TaskInterviewerAdded = 'TASK_INTERVIEWER_ADDED',
  TaskMeetingRoomAdded = 'TASK_MEETING_ROOM_ADDED',
  TaskCandidateEditedAvailability = 'TASK_CANDIDATE_EDITED_AVAILABILITY',
  TaskReopened = 'TASK_REOPENED',
  TaskFlagResolvedCandidateDeclined = 'TASK_FLAG_RESOLVED_CANDIDATE_DECLINED',
  TaskFlagResolvedInterviewDeclined = 'TASK_FLAG_RESOLVED_INTERVIEW_DECLINED',
  TaskFlagResolvedChangeRequested = 'TASK_FLAG_RESOLVED_CHANGE_REQUESTED',
  TaskFlagResolvedDebriefDeclined = 'TASK_FLAG_RESOLVED_DEBRIEF_DECLINED',
  TaskCalendarChanges = 'TASK_CALENDAR_CHANGES',
  TaskDebriefRescheduled = 'TASK_DEBRIEF_RESCHEDULED',
  TaskMigrated = 'TASK_MIGRATED',
  TaskDebriefUpdated = 'TASK_DEBRIEF_UPDATED',
  TaskRcAdjustInterviewerRsvp = 'TASK_RC_ADJUST_INTERVIEWER_RSVP',
  TaskReadyForCandidateComm = 'TASK_READY_FOR_CANDIDATE_COMM',
  TaskRescheduleLogAdded = 'TASK_RESCHEDULE_LOG_ADDED',
  TaskRescheduleLogUpdated = 'TASK_RESCHEDULE_LOG_UPDATED',
  TaskRescheduleLogRemoved = 'TASK_RESCHEDULE_LOG_REMOVED',
  CandidateViewedTab = 'CANDIDATE_VIEWED_TAB',
  TaskApplicationStateChange = 'TASK_APPLICATION_STATE_CHANGE',
  TaskQueueSet = 'TASK_QUEUE_SET',
  TaskQueueUnset = 'TASK_QUEUE_UNSET',
  ApplicationCandidatePortalNoteCreated = 'APPLICATION_CANDIDATE_PORTAL_NOTE_CREATED',
  ApplicationCandidatePortalNoteUpdated = 'APPLICATION_CANDIDATE_PORTAL_NOTE_UPDATED',
  ApplicationCandidatePortalNoteDeleted = 'APPLICATION_CANDIDATE_PORTAL_NOTE_DELETED',
  TaskCandidateAvailabilityDeleted = 'TASK_CANDIDATE_AVAILABILITY_DELETED',
  TaskInterviewCandidateReminderSent = 'TASK_INTERVIEW_CANDIDATE_REMINDER_SENT',
  TaskSelfScheduleCandidateReminderSent = 'TASK_SELF_SCHEDULE_CANDIDATE_REMINDER_SENT',
  TaskAvailabilityCandidateReminderSent = 'TASK_AVAILABILITY_CANDIDATE_REMINDER_SENT',
  TaskDeclinedInterviewerAutoReplaced = 'TASK_DECLINED_INTERVIEWER_AUTO_REPLACED',
  TaskClosedDueToStageChange = 'TASK_CLOSED_DUE_TO_STAGE_CHANGE',
  CandidatePreferredPhoneNumberChanged = 'CANDIDATE_PREFERRED_PHONE_NUMBER_CHANGED'
}

/**
 * Keep this enum in sync with `ActivityType` enum in `activity_log_db.proto`!
 * --------------------------------------------------------------------------------
 */
export enum ActorType {
  Employee = 'EMPLOYEE',
  System = 'SYSTEM',
  Candidate = 'CANDIDATE'
}

export type AddModuleMemberInput = {
  memberId: Scalars['uuid'];
  status: TrainingStatus;
  shadowRequiredCount?: Maybe<Scalars['Int']>;
  reverseShadowRequiredCount?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  weeklyNumInterviewLimit?: Maybe<Scalars['Int']>;
};

export type AddZoomHostAsInternalInviteePref = {
  __typename?: 'AddZoomHostAsInternalInviteePref';
  enabled: Scalars['Boolean'];
  zoomHostUser: Array<ZoomHost>;
};

export type Aggregate = {
  __typename?: 'Aggregate';
  count?: Maybe<Scalars['numeric']>;
};

export enum AmplitudeLoggingHeaderNames {
  XAmpCreateLocation = 'X_AMP_CREATE_LOCATION'
}

export type AppStgCandidateEventsAppStgInput = {
  orderBy?: Maybe<DateOrderByInput>;
};

export type AppStgInterviewsAppStgInput = {
  isCancelled?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<DateOrderByInput>;
};

export type Application = {
  __typename?: 'Application';
  id: Scalars['uuid'];
  candidateId: Scalars['uuid'];
  candidate?: Maybe<Candidate>;
  jobId: Scalars['uuid'];
  jobStageId?: Maybe<Scalars['uuid']>;
  jobStageCount?: Maybe<Scalars['numeric']>;
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  orgId: Scalars['uuid'];
  atsId?: Maybe<Scalars['String']>;
  atsType?: Maybe<AtsType>;
  atsApplication?: Maybe<AtsApplication>;
  status: ApplicationStatus;
  org?: Maybe<Org>;
  job?: Maybe<Job>;
  jobStage?: Maybe<JobStage>;
  applicationStages?: Maybe<Array<ApplicationStage>>;
  upcomingApplicationStage?: Maybe<ApplicationStage>;
  state?: Maybe<ApplicationState>;
  isAtsDisabled: Scalars['Boolean'];
  applicationDebriefs?: Maybe<Array<ApplicationDebrief>>;
  existingConversations?: Maybe<Array<Conversation>>;
  tasks: Array<Task>;
  activityLogs: ActivityLogsResult;
  internalNote?: Maybe<Scalars['String']>;
  hiringManagerId?: Maybe<Scalars['uuid']>;
  hiringManager?: Maybe<Employee>;
  isPortalAccessible: Scalars['Boolean'];
  isTaskEnabled: Scalars['Boolean'];
  candidatePortalNote?: Maybe<CandidatePortalNote>;
  sourcingLink?: Maybe<SourcingLink>;
};


export type ApplicationApplicationStagesArgs = {
  input?: Maybe<ApplicationStageApplicationInput>;
};


export type ApplicationApplicationDebriefsArgs = {
  input?: Maybe<ApplicationDebriefApplicationInput>;
};


export type ApplicationExistingConversationsArgs = {
  input?: Maybe<ConversationsApplicationInput>;
};


export type ApplicationActivityLogsArgs = {
  input: ActivityLogsForSourceInput;
};

export type ApplicationByAtsIdInput = {
  atsId: Scalars['String'];
  atsType: AtsType;
};

export type ApplicationCandidatePortalNoteCreateInput = {
  applicationId: Scalars['uuid'];
  content: Scalars['String'];
  sendToCandidate: Scalars['Boolean'];
};

export type ApplicationCandidatePortalNoteUpdateInput = {
  id: Scalars['uuid'];
  applicationId: Scalars['uuid'];
  content: Scalars['String'];
  sendToCandidate: Scalars['Boolean'];
};

export type ApplicationDebrief = {
  __typename?: 'ApplicationDebrief';
  id: Scalars['uuid'];
  createdAt?: Maybe<Scalars['datetime']>;
  updatedAt?: Maybe<Scalars['datetime']>;
  applicationId?: Maybe<Scalars['uuid']>;
  startAt?: Maybe<Scalars['datetime']>;
  endAt?: Maybe<Scalars['datetime']>;
  name?: Maybe<Scalars['String']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  videoMeetingUrl?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  googleEventId?: Maybe<Scalars['String']>;
  googleEventUrl?: Maybe<Scalars['String']>;
  creatorUserId?: Maybe<Scalars['uuid']>;
  isPrivateCalendarEvent?: Maybe<Scalars['Boolean']>;
  creatorEmployeeId?: Maybe<Scalars['uuid']>;
  creator?: Maybe<Employee>;
  attendees?: Maybe<Array<ApplicationDebriefAttendee>>;
  meetingRooms?: Maybe<Array<ApplicationDebriefRoom>>;
  conversation?: Maybe<Conversation>;
  /**  Task can be null for non Task based debriefs hence optional */
  task?: Maybe<Task>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  conversationTemplateId?: Maybe<Scalars['uuid']>;
  zoomMeetingId?: Maybe<Scalars['numeric']>;
  zoomPassword?: Maybe<Scalars['String']>;
  zoomHostUserId?: Maybe<Scalars['String']>;
  zoomPstnPassword?: Maybe<Scalars['numeric']>;
  zoomDialIns?: Maybe<Scalars['jsonb']>;
};

export type ApplicationDebriefApplicationInput = {
  isCancelled?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<DateOrderByInput>;
};

export type ApplicationDebriefAttendee = {
  __typename?: 'ApplicationDebriefAttendee';
  applicationDebriefId: Scalars['uuid'];
  interviewerId: Scalars['uuid'];
  responseStatus?: Maybe<ResponseStatus>;
  interviewer?: Maybe<Employee>;
  isOptional: Scalars['Boolean'];
};

export type ApplicationDebriefRoom = {
  __typename?: 'ApplicationDebriefRoom';
  applicationDebriefId: Scalars['uuid'];
  meetingRoomId: Scalars['uuid'];
  responseStatus?: Maybe<ResponseStatus>;
  meetingRoom?: Maybe<MeetingRoom>;
};

export type ApplicationEmail = {
  __typename?: 'ApplicationEmail';
  application?: Maybe<Application>;
  /** @deprecated Field no longer supported */
  jobStage?: Maybe<JobStage>;
  email?: Maybe<Email>;
};

export type ApplicationEmailInput = {
  applicationId: Scalars['uuid'];
  jobStageId?: Maybe<Scalars['uuid']>;
};

export type ApplicationEmailOutput = {
  __typename?: 'ApplicationEmailOutput';
  items?: Maybe<Array<ApplicationEmail>>;
};

export type ApplicationResponse = {
  __typename?: 'ApplicationResponse';
  total?: Maybe<Scalars['numeric']>;
  applications?: Maybe<Array<Application>>;
};

export type ApplicationSearchInput = {
  pageInput: PageInput;
  candidateName?: Maybe<Scalars['String']>;
  jobIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  jobStageNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  hiringTeamMemberIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  recruiterIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  coordinatorIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  showDeclines?: Maybe<Scalars['Boolean']>;
  statusFilterGroups?: Maybe<Array<Maybe<StatusFilterGroup>>>;
  applicationStatuses?: Maybe<Array<Maybe<ApplicationStatus>>>;
  orderBy?: Maybe<ApplicationSearchOrderBy>;
};

export type ApplicationSearchOrderBy = {
  property: ApplicationSearchOrderByProperty;
  direction: Sort;
};

export enum ApplicationSearchOrderByProperty {
  LastActivityAt = 'LAST_ACTIVITY_AT',
  Name = 'NAME',
  Stage = 'STAGE',
  TaskCount = 'TASK_COUNT'
}

export type ApplicationSearchOutput = {
  __typename?: 'ApplicationSearchOutput';
  items: Array<Application>;
  total: Scalars['Int'];
  hasNext: Scalars['Boolean'];
};

export type ApplicationStage = {
  __typename?: 'ApplicationStage';
  id: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  startAt?: Maybe<Scalars['datetime']>;
  endAt?: Maybe<Scalars['datetime']>;
  jobStageId?: Maybe<Scalars['uuid']>;
  applicationId?: Maybe<Scalars['uuid']>;
  orgId: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  isCancelled: Scalars['Boolean'];
  atsScheduleId?: Maybe<Scalars['String']>;
  atsType?: Maybe<AtsType>;
  timezone?: Maybe<Scalars['String']>;
  videoMeetingUrl?: Maybe<Scalars['String']>;
  creator?: Maybe<Employee>;
  emailTemplateId?: Maybe<Scalars['String']>;
  initialEmailSubject?: Maybe<Scalars['String']>;
  initialEmailBody?: Maybe<Scalars['String']>;
  initialCalendarEventSubject?: Maybe<Scalars['String']>;
  initialCalendarEventBody?: Maybe<Scalars['String']>;
  isPrivateCalendarEvent: Scalars['Boolean'];
  org?: Maybe<Org>;
  application?: Maybe<Application>;
  jobStage?: Maybe<JobStage>;
  applicationStageCandidateEvents?: Maybe<Array<ApplicationStageCandidateEvent>>;
  applicationStageInterviews?: Maybe<Array<ApplicationStageInterview>>;
  minStartAt: Scalars['datetime'];
  maxEndAt: Scalars['datetime'];
  hasInterviewerDeclined: Scalars['Boolean'];
  hasInterviewerTentative: Scalars['Boolean'];
  hasInterviewerNeedsAction: Scalars['Boolean'];
  hasCandidateDeclined: Scalars['Boolean'];
  hasCandidateTentative: Scalars['Boolean'];
  hasCandidateNeedsAction: Scalars['Boolean'];
  conversation?: Maybe<Conversation>;
  selfScheduleOptions?: Maybe<SelfScheduleOptions>;
  selfScheduleOptionsId?: Maybe<Scalars['uuid']>;
  candidateCancellationReason?: Maybe<CandidateCancellationReason>;
  candidateCancellationNote?: Maybe<Scalars['String']>;
  ccRecipients?: Maybe<NotificationPreference>;
  bccRecipients?: Maybe<NotificationPreference>;
  isHoldForRsvp: Scalars['Boolean'];
  isReadyForCandidateComms: Scalars['Boolean'];
  taskId?: Maybe<Scalars['uuid']>;
  task?: Maybe<Task>;
  internalEvents: Array<ApplicationStageInternalEvent>;
  frozenAt?: Maybe<Scalars['datetime']>;
};


export type ApplicationStageApplicationStageCandidateEventsArgs = {
  input?: Maybe<AppStgCandidateEventsAppStgInput>;
};


export type ApplicationStageApplicationStageInterviewsArgs = {
  input?: Maybe<AppStgInterviewsAppStgInput>;
};


export type ApplicationStageInternalEventsArgs = {
  input?: Maybe<ApplicationStageInternalEventsInput>;
};

export type ApplicationStageApplicationInput = {
  isCancelled?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<DateOrderByInput>;
};

export type ApplicationStageCandidateEvent = {
  __typename?: 'ApplicationStageCandidateEvent';
  id: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  applicationStageId: Scalars['uuid'];
  orgId: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  googleEventId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  googleEventUrl?: Maybe<Scalars['String']>;
  calendarEventTemplateId?: Maybe<Scalars['String']>;
  responseStatus?: Maybe<ResponseStatus>;
  org?: Maybe<Org>;
  applicationStage?: Maybe<ApplicationStage>;
  calendar?: Maybe<Calendar>;
  creator?: Maybe<Employee>;
  comment?: Maybe<Scalars['String']>;
};

export type ApplicationStageFilterInput = {
  schedulers?: Maybe<Array<Scalars['uuid']>>;
  interviewers?: Maybe<Array<Scalars['uuid']>>;
  recruiters?: Maybe<Array<Scalars['uuid']>>;
  candidates?: Maybe<Array<Scalars['uuid']>>;
  rsvp?: Maybe<EventsRsvp>;
};

export type ApplicationStageInput = {
  pageInput: PageInput;
  timeRange?: Maybe<DateTimeRangeInput>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  jobId?: Maybe<Scalars['uuid']>;
  filters?: Maybe<ApplicationStageFilterInput>;
};

export type ApplicationStageInternalEvent = {
  __typename?: 'ApplicationStageInternalEvent';
  id: Scalars['uuid'];
  applicationStageId: Scalars['uuid'];
  orgId: Scalars['uuid'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  remoteCalendarId: Scalars['String'];
  remoteEventId: Scalars['String'];
  remoteEventUrl: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['datetime']>;
  creatorEmployeeId: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  templateId?: Maybe<Scalars['uuid']>;
  attendees: Array<ApplicationStageInternalEventAttendee>;
};

export type ApplicationStageInternalEventAttendee = {
  __typename?: 'ApplicationStageInternalEventAttendee';
  applicationStageInternalEventId: Scalars['uuid'];
  attendeeId: Scalars['uuid'];
  orgId: Scalars['uuid'];
  responseStatus: ResponseStatus;
};

export type ApplicationStageInternalEventsInput = {
  orderBy?: Maybe<DateOrderByInput>;
};

export type ApplicationStageInterview = {
  __typename?: 'ApplicationStageInterview';
  id: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  applicationStageId: Scalars['uuid'];
  orgId: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  googleEventId?: Maybe<Scalars['String']>;
  googleCalendarId?: Maybe<Scalars['String']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  googleEventUrl?: Maybe<Scalars['String']>;
  atsScheduledInterviewId?: Maybe<Scalars['String']>;
  atsInterviewDefinitionId?: Maybe<Scalars['String']>;
  atsType?: Maybe<AtsType>;
  atsInterviewDefinition?: Maybe<AtsInterviewDefinition>;
  videoMeetingUrl?: Maybe<Scalars['String']>;
  creatorEmployeeId?: Maybe<Scalars['uuid']>;
  creator?: Maybe<Employee>;
  emailTemplateId?: Maybe<Scalars['String']>;
  codingInterviewUrl?: Maybe<Scalars['String']>;
  calendarEventTitle?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['numeric']>;
  zoomPassword?: Maybe<Scalars['String']>;
  zoomHostUserId?: Maybe<Scalars['String']>;
  jobStageInterviewId?: Maybe<Scalars['uuid']>;
  zoomPstnPassword?: Maybe<Scalars['numeric']>;
  zoomDialInInfo?: Maybe<Scalars['jsonb']>;
  org?: Maybe<Org>;
  applicationStage?: Maybe<ApplicationStage>;
  applicationStageInterviewInterviewers?: Maybe<Array<ApplicationStageInterviewInterviewer>>;
  applicationStageInterviewRooms?: Maybe<Array<ApplicationStageInterviewRoom>>;
  traineeFeedback?: Maybe<ApplicationStageInterviewFeedback>;
  calendar?: Maybe<Calendar>;
  isHiddenFromCandidate: Scalars['Boolean'];
  videoMeetingHostEmployeeId?: Maybe<Scalars['uuid']>;
  videoMeetingHostEmployee?: Maybe<Employee>;
  meetingLocationId?: Maybe<Scalars['uuid']>;
  meetingLocation?: Maybe<MeetingLocation>;
};


export type ApplicationStageInterviewTraineeFeedbackArgs = {
  reviewerType?: Maybe<GraduationApproverType>;
};

export type ApplicationStageInterviewFeedback = {
  __typename?: 'ApplicationStageInterviewFeedback';
  decisionType?: Maybe<GraduationApproverDecisionType>;
  note?: Maybe<Scalars['String']>;
  reviewedAt?: Maybe<Scalars['datetime']>;
  reviewerId?: Maybe<Scalars['uuid']>;
  traineeId?: Maybe<Scalars['uuid']>;
  reviewer?: Maybe<Employee>;
  trainee?: Maybe<Employee>;
  lastRequestedAt?: Maybe<Scalars['datetime']>;
  nagType?: Maybe<TrainingFeedbackNagType>;
  lastRequestedBy?: Maybe<Employee>;
};

export type ApplicationStageInterviewInterviewer = {
  __typename?: 'ApplicationStageInterviewInterviewer';
  applicationStageInterviewId: Scalars['uuid'];
  interviewerId: Scalars['uuid'];
  role?: Maybe<InterviewerRole>;
  responseStatus?: Maybe<ResponseStatus>;
  interviewModuleId?: Maybe<Scalars['uuid']>;
  jobStageInterviewSeatId?: Maybe<Scalars['uuid']>;
  interviewer?: Maybe<Employee>;
  declines?: Maybe<Array<InterviewInterviewerDeclined>>;
  isOptional: Scalars['Boolean'];
  calendarEventNote?: Maybe<Scalars['String']>;
  shouldCountForModule?: Maybe<Scalars['Boolean']>;
};

export type ApplicationStageInterviewInterviewerResult = {
  __typename?: 'ApplicationStageInterviewInterviewerResult';
  result?: Maybe<Result>;
};

export type ApplicationStageInterviewInterviewerUpdateInput = {
  applicationStageInterviewId: Scalars['uuid'];
  interviewerId: Scalars['uuid'];
  rsvp?: Maybe<InterviewerRsvpInput>;
  shouldCountForModule?: Maybe<Scalars['Boolean']>;
  interviewerRole?: Maybe<InterviewerRole>;
};

export type ApplicationStageInterviewInterviewerUpsertInput = {
  applicationStageInterviewId: Scalars['uuid'];
  interviewerId: Scalars['uuid'];
  rsvp?: Maybe<InterviewerRsvpInput>;
  shouldCountForModule?: Maybe<Scalars['Boolean']>;
  interviewerRole?: Maybe<InterviewerRole>;
};

export type ApplicationStageInterviewRoom = {
  __typename?: 'ApplicationStageInterviewRoom';
  id: Scalars['String'];
  /** This is a computed field. */
  applicationStageInterviewId: Scalars['uuid'];
  meetingRoomId: Scalars['uuid'];
  responseStatus?: Maybe<ResponseStatus>;
  meetingRoom?: Maybe<MeetingRoom>;
};

export type ApplicationStageInterviewsInput = {
  pageInput: PageInput;
  role?: Maybe<InterviewerRole>;
};

export type ApplicationStageInterviewsOutput = {
  __typename?: 'ApplicationStageInterviewsOutput';
  items: Array<ApplicationStageInterview>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type ApplicationStageOutput = {
  __typename?: 'ApplicationStageOutput';
  items?: Maybe<Array<ApplicationStage>>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type ApplicationState = {
  __typename?: 'ApplicationState';
  isSchedulable?: Maybe<Scalars['Boolean']>;
  isNotScheduleable?: Maybe<Scalars['Boolean']>;
  isUnscheduled?: Maybe<Scalars['Boolean']>;
  isAvailabilityRequested?: Maybe<Scalars['Boolean']>;
  isReadyToSchedule?: Maybe<Scalars['Boolean']>;
  isScheduledInAts?: Maybe<Scalars['Boolean']>;
  isCanceled?: Maybe<Scalars['Boolean']>;
  isOngoing?: Maybe<Scalars['Boolean']>;
  isScheduled?: Maybe<Scalars['Boolean']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isCompletedInAts?: Maybe<Scalars['Boolean']>;
  isCandidateDeclined?: Maybe<Scalars['Boolean']>;
  isInterviewerDeclined?: Maybe<Scalars['Boolean']>;
  isSelfScheduleRequested?: Maybe<Scalars['Boolean']>;
  isHoldForRsvp?: Maybe<Scalars['Boolean']>;
  isReadyForCandidateComms?: Maybe<Scalars['Boolean']>;
};

/**  This enum should be in sync with interview/client/src/main/proto/interview-db.proto#Application */
export enum ApplicationStatus {
  Active = 'ACTIVE',
  Rejected = 'REJECTED',
  Hired = 'HIRED',
  Converted = 'CONVERTED',
  Archived = 'ARCHIVED'
}

export type ApplicationUpdateInput = {
  applicationId: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  hiringManagerId?: Maybe<Scalars['uuid']>;
  isTaskEnabled?: Maybe<Scalars['Boolean']>;
  isPortalEnabled?: Maybe<Scalars['Boolean']>;
};

export type AshbyApplication = {
  __typename?: 'AshbyApplication';
  interviewPlanId: Scalars['String'];
};

export type AshbyCandidate = {
  __typename?: 'AshbyCandidate';
  profileUrl: Scalars['String'];
};

export type AshbyIntegration = {
  __typename?: 'AshbyIntegration';
  apiKey: Scalars['String'];
};

export type AshbyIntegrationHealth = {
  __typename?: 'AshbyIntegrationHealth';
  isInstalled: Scalars['Boolean'];
  isHealthy: Scalars['Boolean'];
};

export type AshbyJob = {
  __typename?: 'AshbyJob';
  defaultInterviewPlanId: Scalars['String'];
};

export type AtsApplication = {
  __typename?: 'AtsApplication';
  atsId: Scalars['String'];
  atsType: AtsType;
  atsJobId: Scalars['String'];
  atsJobStageId: Scalars['String'];
  atsCandidateId: Scalars['String'];
  orgId: Scalars['uuid'];
  status: AtsApplicationStatus;
  atsScheduledInterviews?: Maybe<Array<AtsScheduledInterview>>;
  atsFields?: Maybe<AtsFieldsApplication>;
  notSchedulableReason?: Maybe<AtsNotSchedulableReason>;
  atsUrl?: Maybe<Scalars['String']>;
  canMoveJobStage: Scalars['Boolean'];
};

export enum AtsApplicationStatus {
  NotSet = 'NOT_SET',
  Active = 'ACTIVE',
  Rejected = 'REJECTED',
  Hired = 'HIRED',
  Converted = 'CONVERTED',
  Archived = 'ARCHIVED'
}

export type AtsCandidate = {
  __typename?: 'AtsCandidate';
  atsId: Scalars['String'];
  atsType: AtsType;
  orgId: Scalars['uuid'];
  fullName: Scalars['String'];
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  primaryEmail: Scalars['String'];
  emails: Array<Scalars['String']>;
  atsUrl: Scalars['String'];
  atsFields?: Maybe<AtsFieldsCandidate>;
};

export type AtsFieldsApplication = WorkdayApplication | AshbyApplication;

export type AtsFieldsCandidate = GreenhouseCandidate | LeverCandidate | AshbyCandidate | WorkdayCandidate;

export type AtsFieldsInterviewDefinition = GreenhouseInterviewDefinition | LeverInterviewDefinition | GemInterviewDefinition | WorkdayInterviewDefinition;

export type AtsFieldsJob = AshbyJob | WorkdayJob | GreenhouseJob | GemJob;

/** ######################################################################################### */
export type AtsInterviewDefinition = {
  __typename?: 'AtsInterviewDefinition';
  atsId: Scalars['String'];
  atsType: AtsType;
  orgId: Scalars['uuid'];
  name: Scalars['String'];
  atsJobId?: Maybe<Scalars['String']>;
  atsJobStageId?: Maybe<Scalars['String']>;
  atsJobStage?: Maybe<AtsJobStage>;
  schedulable?: Maybe<Scalars['Boolean']>;
  atsFields?: Maybe<AtsFieldsInterviewDefinition>;
};

/** ######################################################################################### */
export type AtsInterviewDefinitionsInput = {
  atsJobId: Scalars['String'];
  schedulable: Scalars['Boolean'];
};

export type AtsInterviewDefinitionsOutput = {
  __typename?: 'AtsInterviewDefinitionsOutput';
  atsInterviewDefinitions?: Maybe<Array<AtsInterviewDefinition>>;
};

export type AtsJob = {
  __typename?: 'AtsJob';
  atsId: Scalars['String'];
  atsType: AtsType;
  name: Scalars['String'];
  isConfidential?: Maybe<Scalars['Boolean']>;
  atsFields?: Maybe<AtsFieldsJob>;
  jobRequisitionId?: Maybe<Scalars['String']>;
};

export type AtsJobStage = {
  __typename?: 'AtsJobStage';
  atsId: Scalars['String'];
  atsType: AtsType;
  name: Scalars['String'];
  atsJobId: Scalars['String'];
  orgId: Scalars['uuid'];
  index: Scalars['Int'];
  isSchedulable: Scalars['Boolean'];
  /**  Not implemented yet! */
  canResetInterviewPlan: Scalars['Boolean'];
};

export enum AtsNotSchedulableReason {
  NotSchedulable = 'NOT_SCHEDULABLE',
  NotSchedulableOnCurrentStep = 'NOT_SCHEDULABLE_ON_CURRENT_STEP'
}

export type AtsScheduledInterview = {
  __typename?: 'AtsScheduledInterview';
  atsId: Scalars['String'];
  atsType: AtsType;
  status: AtsScheduledInterviewStatus;
  orgId: Scalars['uuid'];
  atsApplicationId: Scalars['String'];
  atsCandidateId: Scalars['String'];
  atsScheduledId?: Maybe<Scalars['String']>;
  atsCreatorUserId?: Maybe<Scalars['String']>;
  atsInterviewDefinitionId: Scalars['String'];
  atsInterviewDefinition?: Maybe<AtsInterviewDefinition>;
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  createdAt: Scalars['datetime'];
  interviewers?: Maybe<Array<AtsScheduledInterviewInterviewer>>;
};

export type AtsScheduledInterviewInterviewer = {
  __typename?: 'AtsScheduledInterviewInterviewer';
  atsScheduledInterviewId: Scalars['String'];
  atsUserId: Scalars['String'];
  atsUser?: Maybe<AtsUser>;
  orgId: Scalars['uuid'];
  responseStatus?: Maybe<ResponseStatus>;
};

export enum AtsScheduledInterviewStatus {
  ToBeScheduled = 'TO_BE_SCHEDULED',
  Scheduled = 'SCHEDULED',
  AwaitingFeedback = 'AWAITING_FEEDBACK',
  Complete = 'COMPLETE',
  Cancelled = 'CANCELLED'
}

export enum AtsType {
  Greenhouse = 'GREENHOUSE',
  Lever = 'LEVER',
  Ashby = 'ASHBY',
  Gem = 'GEM',
  Workday = 'WORKDAY'
}

export type AtsUser = {
  __typename?: 'AtsUser';
  id: Scalars['String'];
  atsId: Scalars['String'];
  atsType: AtsType;
  email: Scalars['String'];
  name: Scalars['String'];
  fullName: Scalars['String'];
  isDisabled: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  employeeId?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  employee?: Maybe<Employee>;
};

export type AtsUserEmployeeAssociationUpdateInput = {
  atsUserId: Scalars['String'];
  employeeId: Scalars['uuid'];
  executeUpdate?: Maybe<Scalars['Boolean']>;
};

export type AtsUserEmployeeAssociationUpdateResult = {
  __typename?: 'AtsUserEmployeeAssociationUpdateResult';
  atsUser: AtsUser;
};

export type AtsUserInput = {
  pageInput: PageInput;
  name?: Maybe<Scalars['String']>;
};

export type AtsUserOutput = {
  __typename?: 'AtsUserOutput';
  items?: Maybe<Array<AtsUser>>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['numeric']>;
  timestamp?: Maybe<Scalars['numeric']>;
};

export type AttachmentInput = {
  name: Scalars['String'];
  path: Scalars['String'];
  size?: Maybe<Scalars['numeric']>;
  timestamp?: Maybe<Scalars['numeric']>;
};

export enum AttributeNameType {
  Department = 'DEPARTMENT',
  Team = 'TEAM',
  Level = 'LEVEL',
  Location = 'LOCATION',
  Custom = 'CUSTOM',
  Country = 'COUNTRY'
}

export type AttributeNameValueMapInput = {
  attributeNameId: Scalars['uuid'];
  attributeValueIds: Array<Scalars['uuid']>;
};

export type AttributeValueEmployeesInput = {
  attributeNameId: Scalars['uuid'];
  attributeTagValueId: Scalars['uuid'];
  employeeIds?: Maybe<Array<Scalars['uuid']>>;
};

export enum BlockType {
  Text = 'TEXT',
  Image = 'IMAGE',
  Video = 'VIDEO',
  ContentCard = 'CONTENT_CARD',
  LinkedCard = 'LINKED_CARD',
  Divider = 'DIVIDER'
}

export enum BoolEmployeePref {
  BoolShowTasksOnCandidateDashboard = 'bool_show_tasks_on_candidate_dashboard'
}

export type BoolEmployeePrefValue = EmployeePrefValue & {
  __typename?: 'BoolEmployeePrefValue';
  id: Scalars['String'];
  prefName: Scalars['String'];
  level: PrefLevel;
  value?: Maybe<Scalars['Boolean']>;
};

export enum BoolOrgPref {
  BoolShouldWriteActivityBackToAts = 'bool_should_write_activity_back_to_ats',
  BoolShouldWriteEmailBackToAts = 'bool_should_write_email_back_to_ats',
  BoolNotifySlackInterviewerNoResponse = 'bool_notify_slack_interviewer_no_response',
  BoolNotifySlackInterviewerIncludeRcNoResponse = 'bool_notify_slack_interviewer_include_rc_no_response',
  BoolNotifySlack_24hoursreminder = 'bool_notify_slack_24hoursreminder',
  BoolNotifySlack_24HoursReminderInterviewerDirect = 'bool_notify_slack_24_hours_reminder_interviewer_direct',
  BoolNotifyEmailInterviewerDeclined = 'bool_notify_email_interviewer_declined',
  BoolSyncSlackUserImages = 'bool_sync_slack_user_images',
  BoolSecurityAllowStorageOfEventDescriptions = 'bool_security_allow_storage_of_event_descriptions',
  BoolSecurityAllowStorageOfEventAttendees = 'bool_security_allow_storage_of_event_attendees',
  BoolAutomaticallyReplaceDeclinedInterviewer = 'bool_automatically_replace_declined_interviewer',
  BoolDeleteOrphanedZoomMeetings = 'bool_delete_orphaned_zoom_meetings',
  BoolAllowOverlappingInterviewTimeRanges = 'bool_allow_overlapping_interview_time_ranges',
  BoolAutoCancelTasksOnApplicationClose = 'bool_auto_cancel_tasks_on_application_close',
  BoolEventVisibilityIsPrivate = 'bool_event_visibility_is_private',
  BoolCandidateFacingShowInterviewName = 'bool_candidate_facing_show_interview_name',
  BoolHasVerifiedCandidateWritePermission = 'bool_has_verified_candidate_write_permission',
  BoolArUseRollingWindow = 'bool_ar_use_rolling_window',
  BoolArCanScheduleOverRecruitingKeywords = 'bool_ar_can_schedule_over_recruiting_keywords',
  BoolArCanScheduleOverAvailableKeywords = 'bool_ar_can_schedule_over_available_keywords',
  BoolArCanScheduleOverFreeTime = 'bool_ar_can_schedule_over_free_time',
  BoolSsrUseRollingWindow = 'bool_ssr_use_rolling_window',
  BoolSsrCanScheduleOverRecruitingKeywords = 'bool_ssr_can_schedule_over_recruiting_keywords',
  BoolSsrCanScheduleOverAvailableKeywords = 'bool_ssr_can_schedule_over_available_keywords',
  BoolSsrCanScheduleOverFreeTime = 'bool_ssr_can_schedule_over_free_time'
}

export type BrandingTheme = {
  __typename?: 'BrandingTheme';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  name: Scalars['String'];
  profileImageUrl?: Maybe<Scalars['String']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  isOrgDefault: Scalars['Boolean'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  /**  Refers to number of active applications for the jobs that use that branding theme */
  livePortals: Scalars['numeric'];
  primaryColor?: Maybe<Scalars['String']>;
  themeMode?: Maybe<BrandingThemeMode>;
  faviconImageUrl?: Maybe<Scalars['String']>;
  font?: Maybe<Scalars['String']>;
};

export type BrandingThemeCreateInput = {
  name: Scalars['String'];
  profileImageUrl?: Maybe<Scalars['String']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  isOrgDefault?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  themeMode?: Maybe<BrandingThemeMode>;
  faviconImageUrl?: Maybe<Scalars['String']>;
  font?: Maybe<Scalars['String']>;
};

export enum BrandingThemeMode {
  Light = 'LIGHT',
  Dark = 'DARK'
}

export type BrandingThemeResult = {
  __typename?: 'BrandingThemeResult';
  theme: BrandingTheme;
};

export type BrandingThemeUpdateInput = {
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  coverImageUrl?: Maybe<Scalars['String']>;
  isOrgDefault?: Maybe<Scalars['Boolean']>;
  primaryColor?: Maybe<Scalars['String']>;
  themeMode?: Maybe<BrandingThemeMode>;
  faviconImageUrl?: Maybe<Scalars['String']>;
  font?: Maybe<Scalars['String']>;
};

export type BrandingThemesResult = {
  __typename?: 'BrandingThemesResult';
  items: Array<BrandingTheme>;
};

export type Calendar = {
  __typename?: 'Calendar';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  employeeId?: Maybe<Scalars['String']>;
  remoteId?: Maybe<Scalars['String']>;
  supportsRsvpSync: Scalars['Boolean'];
  /** Is the calendar part of the employee's calendar list? */
  isPartOfCalendarList?: Maybe<Scalars['Boolean']>;
};

export type CalendarEmailBusyTimeRange = {
  __typename?: 'CalendarEmailBusyTimeRange';
  email?: Maybe<Scalars['String']>;
  timeRanges?: Maybe<Array<DateTimeRangeOutput>>;
};

export type CalendarFreeBusyInput = {
  emails: Array<Scalars['String']>;
  timeRange: DateTimeRangeInput;
};

export type CalendarFreeBusyOutput = {
  __typename?: 'CalendarFreeBusyOutput';
  emailBusyTimeRanges?: Maybe<Array<CalendarEmailBusyTimeRange>>;
};

export type CalendarMeetingCreateInput = {
  timeRange: DateTimeRangeInput;
  hostEmployeeId?: Maybe<Scalars['uuid']>;
};

export type CalendarsInput = {
  pageInput: PageInput;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  remoteId?: Maybe<Scalars['String']>;
};

export type CalendarsResult = {
  __typename?: 'CalendarsResult';
  calendars: Array<Calendar>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type CanPoSettings = {
  importIntroText: Scalars['Boolean'];
  importBrandingTheme: Scalars['Boolean'];
  contentTabMappings?: Maybe<Array<ContentTabMappingInput>>;
};

export type CanPoSettingsImportConfig = {
  contentTabs?: Maybe<Array<Scalars['uuid']>>;
};

export type Candidate = {
  __typename?: 'Candidate';
  orgId: Scalars['uuid'];
  coordinatorId?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  lastActivityAt?: Maybe<Scalars['datetime']>;
  atsId?: Maybe<Scalars['String']>;
  atsType?: Maybe<AtsType>;
  atsCandidate?: Maybe<AtsCandidate>;
  linkedinUrl?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<Scalars['String']>>;
  pronoun?: Maybe<Scalars['String']>;
  recruiterId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  externalId: Scalars['String'];
  updatedAt?: Maybe<Scalars['datetime']>;
  timezone?: Maybe<Scalars['String']>;
  recruiter?: Maybe<Employee>;
  coordinator?: Maybe<Employee>;
  sourcer?: Maybe<Employee>;
  org?: Maybe<Org>;
  additionalEmails?: Maybe<Array<Scalars['String']>>;
  applications?: Maybe<Array<Application>>;
  /** The identifier which is exposed on public internet for candidate portal. */
  cryptoHash?: Maybe<Scalars['String']>;
  atsLatestAvailability?: Maybe<CandidateAtsAvailability>;
  preferredEmail?: Maybe<Scalars['String']>;
  conflictOfInterestEmployees: Array<Employee>;
  preferredPhoneNumber?: Maybe<Scalars['String']>;
};

export type CandidateAtsAvailability = {
  __typename?: 'CandidateAtsAvailability';
  intervals?: Maybe<Array<DateTimeRangeOutput>>;
  timezone?: Maybe<Scalars['timezone']>;
  originalText?: Maybe<Scalars['String']>;
  jobName?: Maybe<Scalars['String']>;
  jobStageName?: Maybe<Scalars['String']>;
};

export type CandidateAvailability = {
  __typename?: 'CandidateAvailability';
  id: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  candidateEnteredAvailability?: Maybe<Array<DateTimeRangeOutput>>;
  rcEnteredAvailability?: Maybe<Array<DateTimeRangeOutput>>;
  lastUpdatedByEmployeeId?: Maybe<Scalars['uuid']>;
  lastUpdatedByEmployee?: Maybe<Employee>;
  employeeUpdatedAt?: Maybe<Scalars['datetime']>;
  candidateUpdatedAt?: Maybe<Scalars['datetime']>;
  note?: Maybe<Scalars['String']>;
};

/** ########################################################## */
export type CandidateAvailabilityInput = {
  applicationId: Scalars['uuid'];
  jobStageId: Scalars['uuid'];
  taskId?: Maybe<Scalars['uuid']>;
};

export type CandidateAvailabilityOptions = {
  __typename?: 'CandidateAvailabilityOptions';
  applicationId: Scalars['uuid'];
  availabilityEndDate?: Maybe<Scalars['datetime']>;
  availabilityStartDate?: Maybe<Scalars['datetime']>;
  candidateNote?: Maybe<Scalars['String']>;
  createdAt: Scalars['datetime'];
  externalId: Scalars['String'];
  id: Scalars['uuid'];
  creatorEmployeeId?: Maybe<Scalars['uuid']>;
  creator?: Maybe<Employee>;
  jobStageId: Scalars['uuid'];
  minimumTimeBlockMinutes: Scalars['numeric'];
  minutesPerDays: Scalars['numeric'];
  numberOfDays: Scalars['numeric'];
  timezone: Scalars['String'];
  lastCandidateCommunicationSentAt?: Maybe<Scalars['datetime']>;
  updatedAt: Scalars['datetime'];
  advanceNoticeMinutes?: Maybe<Scalars['numeric']>;
  /** @deprecated Field no longer supported */
  availabilitySubmittedNotificationPrefs?: Maybe<Scalars['jsonb']>;
  /** @deprecated Field no longer supported */
  availabilitySubmittedNotificationPrefsV2?: Maybe<NotificationPreference>;
  shouldRespectLoadLimit: Scalars['Boolean'];
  suggestOverRecruitingKeywords: Scalars['Boolean'];
  suggestOverAvailableKeywords: Scalars['Boolean'];
  suggestOverFreeTime: Scalars['Boolean'];
  rollingDays: Scalars['numeric'];
  inclusionDays: Array<Scalars['String']>;
};

export type CandidateAvailabilityOptionsInput = {
  applicationId: Scalars['uuid'];
  jobStageId: Scalars['uuid'];
};

/** ########################################## */
export type CandidateAvailabilityOptionsSettingInput = {
  numberOfDays?: Maybe<Scalars['numeric']>;
  rollingDays?: Maybe<Scalars['numeric']>;
  minutesPerDays?: Maybe<Scalars['numeric']>;
  minimumTimeBlockMinutes?: Maybe<Scalars['numeric']>;
  advanceNoticeMinutes?: Maybe<Scalars['numeric']>;
  candidateNote?: Maybe<Scalars['String']>;
  ccRecipients?: Maybe<NotificationPreferenceInput>;
  bccRecipients?: Maybe<NotificationPreferenceInput>;
  shouldRespectLoadLimit?: Maybe<Scalars['Boolean']>;
  canScheduleOverRecruitingKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverAvailableKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverFreeTime?: Maybe<Scalars['Boolean']>;
  timeframeNumberOfDays?: Maybe<Scalars['numeric']>;
  useRollingDays?: Maybe<Scalars['Boolean']>;
};

/** @deprecated use JobStageCandidateAvailabilitySettings instead */
export type CandidateAvailabilityOptionsSettings = {
  __typename?: 'CandidateAvailabilityOptionsSettings';
  numberOfDays?: Maybe<Scalars['numeric']>;
  /** @deprecated Field no longer supported */
  rollingDays?: Maybe<Scalars['numeric']>;
  minutesPerDays?: Maybe<Scalars['numeric']>;
  minimumTimeBlockMinutes?: Maybe<Scalars['numeric']>;
  advanceNoticeMinutes?: Maybe<Scalars['numeric']>;
  candidateNote?: Maybe<Scalars['String']>;
  ccRecipients?: Maybe<NotificationPreference>;
  bccRecipients?: Maybe<NotificationPreference>;
  shouldRespectLoadLimit?: Maybe<Scalars['Boolean']>;
  canScheduleOverRecruitingKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverAvailableKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverFreeTime?: Maybe<Scalars['Boolean']>;
  timeframeNumberOfDays?: Maybe<Scalars['numeric']>;
  useRollingDays?: Maybe<Scalars['Boolean']>;
};

export type CandidateAvailabilityOptionsUpsertInput = {
  taskId: Scalars['uuid'];
  numberOfDays: Scalars['numeric'];
  minutesPerDays: Scalars['numeric'];
  minimumTimeBlockMinutes: Scalars['numeric'];
  advanceNoticeMinutes: Scalars['numeric'];
  timezone: Scalars['timezone'];
  candidateNote?: Maybe<Scalars['String']>;
  shouldRespectLoadLimit: Scalars['Boolean'];
  suggestOverRecruitingKeywords: Scalars['Boolean'];
  suggestOverAvailableKeywords: Scalars['Boolean'];
  suggestOverFreeTime: Scalars['Boolean'];
  rollingDays: Scalars['numeric'];
  inclusionDays?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<CandidateEmailContentInput>;
};

export type CandidateAvailabilityOptionsUpsertResult = {
  __typename?: 'CandidateAvailabilityOptionsUpsertResult';
  options?: Maybe<CandidateAvailabilityOptions>;
};

export type CandidateByAtsIdInput = {
  atsId: Scalars['String'];
  atsType: AtsType;
};

export enum CandidateCancellationReason {
  NotSet = 'NOT_SET',
  CantMakeTime = 'CANT_MAKE_TIME',
  NoLongerInterested = 'NO_LONGER_INTERESTED',
  AcceptedAnotherOffer = 'ACCEPTED_ANOTHER_OFFER',
  Other = 'OTHER'
}

export type CandidateCommunicationsSettingPref = {
  __typename?: 'CandidateCommunicationsSettingPref';
  ccRecipients?: Maybe<NotificationPreference>;
  bccRecipients?: Maybe<NotificationPreference>;
};

export type CandidateEmailContentInput = {
  toEmailAddress: Scalars['String'];
  ccEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
  bccEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
  ccExternalAddresses?: Maybe<Array<Scalars['String']>>;
  bccExternalAddresses?: Maybe<Array<Scalars['String']>>;
  summary: Scalars['String'];
  description: Scalars['String'];
  attachments?: Maybe<Array<RemoteAttachmentInput>>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  parentEmailId?: Maybe<Scalars['uuid']>;
};

export type CandidateEventContentInput = {
  toEmailAddress: Scalars['String'];
  summary: Scalars['String'];
  description: Scalars['String'];
  videoMeetingUrl: Scalars['String'];
  calendarEventTemplateId?: Maybe<Scalars['uuid']>;
};

export type CandidateFacingHiringTeamContactInfo = {
  __typename?: 'CandidateFacingHiringTeamContactInfo';
  showCoordinator: Scalars['Boolean'];
  showRecruiter: Scalars['Boolean'];
  canShowJobTitle: Scalars['Boolean'];
  canShowLinkedin: Scalars['Boolean'];
  canShowPhone: Scalars['Boolean'];
  canShowProfilePicture: Scalars['Boolean'];
  canShowPronouns: Scalars['Boolean'];
  canShowBio: Scalars['Boolean'];
};

export type CandidateFacingInterviewerInfo = {
  __typename?: 'CandidateFacingInterviewerInfo';
  canShowInfo: Scalars['Boolean'];
  canShowFullName: Scalars['Boolean'];
  canShowJobTitle: Scalars['Boolean'];
  canShowLinkedin: Scalars['Boolean'];
  canShowEmail: Scalars['Boolean'];
  canShowProfilePicture: Scalars['Boolean'];
  canShowPronouns: Scalars['Boolean'];
  canShowBio: Scalars['Boolean'];
};

export type CandidatePageFiltersPref = {
  __typename?: 'CandidatePageFiltersPref';
  showInactiveApplications: Scalars['Boolean'];
  coordinatorEmployeeIds: Array<Scalars['uuid']>;
  coordinatorEmployees: Array<Employee>;
  recruiterEmployeeIds: Array<Scalars['uuid']>;
  recruiterEmployees: Array<Employee>;
  jobIds: Array<Scalars['uuid']>;
  jobs: Array<Job>;
  jobStageNames: Array<Scalars['String']>;
};

export type CandidatePortalNote = {
  __typename?: 'CandidatePortalNote';
  id: Scalars['uuid'];
  content?: Maybe<Scalars['String']>;
  updatedBy: Employee;
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  lastNotificationSentAt?: Maybe<Scalars['datetime']>;
};

export type CandidateSetInput = {
  recruiterId?: Maybe<Scalars['uuid']>;
  coordinatorId?: Maybe<Scalars['uuid']>;
  sourcerId?: Maybe<Scalars['uuid']>;
  clearPreferredEmail?: Maybe<Scalars['Boolean']>;
  timezone?: Maybe<Scalars['timezone']>;
  conflictOfInterestEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
  preferredPhoneNumber?: Maybe<Scalars['String']>;
};

export type CandidateUpdateInput = {
  id: Scalars['uuid'];
  set: CandidateSetInput;
};

export type CandidateUpdateResult = {
  __typename?: 'CandidateUpdateResult';
  candidate: Candidate;
};

export type ChartCoordinateData = {
  __typename?: 'ChartCoordinateData';
  xpoint?: Maybe<Scalars['String']>;
  ypoint?: Maybe<Scalars['Float']>;
};

/** ######################################### */
export type CheckCreateCandidatePermissionInput = {
  jobId?: Maybe<Scalars['uuid']>;
};

export type CheckCreateCandidatePermissionResult = {
  __typename?: 'CheckCreateCandidatePermissionResult';
  id?: Maybe<Scalars['uuid']>;
  hasPermission: Scalars['Boolean'];
  testCandidateAtsUrl?: Maybe<Scalars['String']>;
};

export type CodeLinkInput = {
  url: Scalars['String'];
  type: CodeLinkType;
};

export enum CodeLinkType {
  None = 'NONE',
  Codility = 'CODILITY',
  Coderpad = 'CODERPAD',
  Codesignal = 'CODESIGNAL',
  Hackerrank = 'HACKERRANK'
}

export type CoderpadIntegration = {
  __typename?: 'CoderpadIntegration';
  apiKey: Scalars['String'];
};

export type CodesignalIntegration = {
  __typename?: 'CodesignalIntegration';
  apiKey: Scalars['String'];
};

export type CodilityIntegration = {
  __typename?: 'CodilityIntegration';
  apiKey: Scalars['String'];
};

export type CompanyHoliday = {
  __typename?: 'CompanyHoliday';
  id: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  name: Scalars['String'];
  startAt: Scalars['date'];
  endAt: Scalars['date'];
  countries?: Maybe<Array<EmployeeAttributeTagValue>>;
};

export type CompanyHolidayCreateInput = {
  name: Scalars['String'];
  startAt: Scalars['date'];
  endAt: Scalars['date'];
  allCountries: Scalars['Boolean'];
  countries?: Maybe<Array<Scalars['uuid']>>;
};

export type CompanyHolidayDeleteInput = {
  id: Scalars['uuid'];
};

export type CompanyHolidayDeleteResult = {
  __typename?: 'CompanyHolidayDeleteResult';
  result?: Maybe<Result>;
};

export type CompanyHolidayOrderBy = {
  property: CompanyHolidayOrderByProperty;
  direction: Sort;
};

export enum CompanyHolidayOrderByProperty {
  Name = 'NAME',
  Date = 'DATE'
}

export type CompanyHolidayResult = {
  __typename?: 'CompanyHolidayResult';
  items: Array<CompanyHoliday>;
  nextCursor: Scalars['String'];
};

export type CompanyHolidayUpdateInput = {
  id: Scalars['uuid'];
  name: Scalars['String'];
  startAt: Scalars['date'];
  endAt: Scalars['date'];
  allCountries: Scalars['Boolean'];
  countries?: Maybe<Array<Scalars['uuid']>>;
};

export type CompanyHolidayUpsertResult = {
  __typename?: 'CompanyHolidayUpsertResult';
  companyHoliday: CompanyHoliday;
};

export type CompanyHolidaysForDays = {
  __typename?: 'CompanyHolidaysForDays';
  date: Scalars['date'];
  companyHolidays: Array<CompanyHoliday>;
};

export type CompanyHolidaysForDaysInput = {
  startAt: Scalars['date'];
  endAt: Scalars['date'];
};

export type CompanyHolidaysForDaysResult = {
  __typename?: 'CompanyHolidaysForDaysResult';
  items: Array<CompanyHolidaysForDays>;
};

/**
 * ################################
 *  Company Holiday
 */
export type CompanyHolidaysInput = {
  name?: Maybe<Scalars['String']>;
  allCountries?: Maybe<Scalars['Boolean']>;
  countries?: Maybe<Array<Scalars['uuid']>>;
  startAt?: Maybe<Scalars['date']>;
  endAt?: Maybe<Scalars['date']>;
  orderBy?: Maybe<CompanyHolidayOrderBy>;
  pageInput: PageInput;
};

export type ConfigOverride = {
  __typename?: 'ConfigOverride';
  multiDay: Scalars['Boolean'];
};

export type ContentCard = {
  __typename?: 'ContentCard';
  header: Scalars['String'];
  body?: Maybe<Scalars['String']>;
};

export type ContentCardInput = {
  header: Scalars['String'];
  body?: Maybe<Scalars['String']>;
};

export type ContentTabMapping = {
  __typename?: 'ContentTabMapping';
  /** @deprecated Use targetAtsId instead. */
  targetContentTabId?: Maybe<Scalars['uuid']>;
  sourceName: Scalars['String'];
  sourceContentTabId: Scalars['uuid'];
  sourceContentTab: PortalJobOptionsTab;
  sourceJobStageId?: Maybe<Scalars['uuid']>;
  sourceJobStage?: Maybe<JobStage>;
  targetJobStageId?: Maybe<Scalars['uuid']>;
  targetJobStage?: Maybe<JobStage>;
};

export type ContentTabMappingInput = {
  sourceContentTabId: Scalars['uuid'];
  visibility: PortalJobOptionsTabVisibilityType;
  targetJobStageId?: Maybe<Scalars['uuid']>;
};

export type Conversation = {
  __typename?: 'Conversation';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  remoteId: Scalars['String'];
  remoteName: Scalars['String'];
  /**  Redact this to "private channel" behind a feature flag */
  type: ConversationType;
  isPrivate: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
};

export enum ConversationType {
  Slack = 'SLACK'
}

export type ConversationsApplicationInput = {
  isCancelled?: Maybe<Scalars['Boolean']>;
  includeArchived?: Maybe<Scalars['Boolean']>;
};

export enum CurrentTrainingSessionStatus {
  None = 'NONE',
  /**  Ideally for trained interviews */
  Scheduled = 'SCHEDULED',
  /**  For in training interviewer and upcoming scheduled via ModernLoop */
  RequiredInterviewsScheduled = 'REQUIRED_INTERVIEWS_SCHEDULED',
  /**  For in training interviewer and all required upcoming scheduled via ModernLoop */
  Unscheduled = 'UNSCHEDULED',
  /**  For in training interviewer for no upcoming scheduled via ModernLoop */
  NeedsApproval = 'NEEDS_APPROVAL'
}

export type CustomInterviewScheduleTokenPref = {
  dateHeaderFormat: Scalars['String'];
  interviewDetailWithTime: Scalars['String'];
  breakDividerFormat: Scalars['String'];
  uniqueMeetingLinkFormat?: Maybe<MeetingLinkFormat>;
  singleMeetingLinkFormat?: Maybe<MeetingLinkFormat>;
  codingLinkFormat?: Maybe<Scalars['String']>;
  candidatePhoneNumberFormat?: Maybe<Scalars['String']>;
};

export type CustomInterviewScheduleTokenPreviewInput = {
  pref: CustomInterviewScheduleTokenPref;
  previewSchedule: TokenInterviewScheduleInput;
  employees?: Maybe<Array<PreviewTokenEmployee>>;
  candidate: PreviewTokenCandidate;
};

export type CustomInterviewScheduleTokenPreviewResult = {
  __typename?: 'CustomInterviewScheduleTokenPreviewResult';
  html?: Maybe<Scalars['String']>;
};

export type CustomJobStageInput = {
  candidateId: Scalars['uuid'];
  jobStageId: Scalars['uuid'];
  customJobStageId: Scalars['uuid'];
};

export type CustomJobStageInterviewPlanResult = {
  __typename?: 'CustomJobStageInterviewPlanResult';
  customJobStageId?: Maybe<Scalars['uuid']>;
};

export type DateBlock = {
  __typename?: 'DateBlock';
  /**  yyyy-MM-dd format */
  start?: Maybe<Scalars['String']>;
  /**  yyyy-MM-dd format */
  end?: Maybe<Scalars['String']>;
};

export type DateBlockInput = {
  /**  yyyy-MM-dd format */
  start?: Maybe<Scalars['String']>;
  /**  yyyy-MM-dd format */
  end?: Maybe<Scalars['String']>;
};

export type DateOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
  startAt?: Maybe<Sort>;
  endAt?: Maybe<Sort>;
};

export type DateTimeRangeInput = {
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
};

export type DateTimeRangeOutput = {
  __typename?: 'DateTimeRangeOutput';
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  /** @deprecated Field no longer supported */
  start: Scalars['datetime'];
  /** @deprecated Field no longer supported */
  end: Scalars['datetime'];
};

export type DebriefOptionsInput = {
  /** One of the following 2 need to be sent. */
  timeRanges?: Maybe<Array<DateTimeRangeInput>>;
  exactTime?: Maybe<Scalars['datetime']>;
  durationMinutes: Scalars['numeric'];
  interviewerIds: Array<Scalars['uuid']>;
  hardConflicts?: Maybe<Array<HardConflictInput>>;
  applicationId?: Maybe<Scalars['uuid']>;
};

export type DebriefRescheduleDetails = {
  __typename?: 'DebriefRescheduleDetails';
  hasScheduleTimeAfterDebriefStartAt: Scalars['Boolean'];
  hasAttendeeAdded: Scalars['Boolean'];
};

/** ################################################################### */
export enum DeclineReason {
  Conflict = 'CONFLICT',
  KnowTheCandidate = 'KNOW_THE_CANDIDATE',
  OutOfOffice = 'OUT_OF_OFFICE',
  OutsideWorkHours = 'OUTSIDE_WORK_HOURS',
  OverLimit = 'OVER_LIMIT',
  Other = 'OTHER',
  Missed = 'MISSED'
}

export type DefaultCustomInterviewScheduleTokenPref = {
  __typename?: 'DefaultCustomInterviewScheduleTokenPref';
  dateHeaderFormat: Scalars['String'];
  interviewDetailWithTime: Scalars['String'];
  breakDividerFormat: Scalars['String'];
  uniqueMeetingLinkFormat?: Maybe<DefaultMeetingLinkFormat>;
  singleMeetingLinkFormat?: Maybe<DefaultMeetingLinkFormat>;
  codingLinkFormat?: Maybe<Scalars['String']>;
  candidatePhoneNumberFormat?: Maybe<Scalars['String']>;
};

export type DefaultCustomInterviewScheduleTokenResult = {
  __typename?: 'DefaultCustomInterviewScheduleTokenResult';
  pref?: Maybe<DefaultCustomInterviewScheduleTokenPref>;
};

export type DefaultMeetingLinkFormat = {
  __typename?: 'DefaultMeetingLinkFormat';
  zoomLinkFormat?: Maybe<Scalars['String']>;
  workspaceVideoLinkFormat?: Maybe<Scalars['String']>;
  customLinkFormat?: Maybe<Scalars['String']>;
};

export enum DeleteType {
  Upcoming = 'UPCOMING',
  Past = 'PAST',
  All = 'ALL'
}

export type DialInfo = {
  __typename?: 'DialInfo';
  type?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type DirectoryPageFiltersPref = {
  __typename?: 'DirectoryPageFiltersPref';
  departmentAttributeIds: Array<Scalars['uuid']>;
  teamAttributeIds: Array<Scalars['uuid']>;
  locationAttributeIds: Array<Scalars['uuid']>;
  levelAttributeIds: Array<Scalars['uuid']>;
  customAttributeIds: Array<Scalars['uuid']>;
  countryAttributeIds: Array<Scalars['uuid']>;
};

export enum DirectoryType {
  Google = 'GOOGLE',
  Outlook = 'OUTLOOK'
}

export type Duration = {
  __typename?: 'Duration';
  seconds: Scalars['numeric'];
  nanos: Scalars['numeric'];
};

export enum DurationEmployeePref {
  DurationFeedbackWritingTime = 'duration_feedback_writing_time'
}

export type DurationEmployeePrefValue = EmployeePrefValue & {
  __typename?: 'DurationEmployeePrefValue';
  id: Scalars['String'];
  prefName: Scalars['String'];
  level: PrefLevel;
  value?: Maybe<Duration>;
};

export type DurationInput = {
  seconds: Scalars['numeric'];
  nanos?: Maybe<Scalars['numeric']>;
};

export enum DurationOrgPref {
  DurationDefaultInterviewDuration = 'duration_default_interview_duration',
  DurationDefaultDebriefDuration = 'duration_default_debrief_duration',
  DurationSelfScheduleRequestStepIncrement = 'duration_self_schedule_request_step_increment',
  DurationAvailabilityRequestStepIncrement = 'duration_availability_request_step_increment',
  DurationDefaultSchedulingWindow = 'duration_default_scheduling_window',
  DurationFeedbackWritingTime = 'duration_feedback_writing_time',
  DurationArTimeframe = 'duration_ar_timeframe',
  DurationArAdvanceNotice = 'duration_ar_advance_notice',
  DurationSsrTimeframe = 'duration_ssr_timeframe',
  DurationSsrAdvanceNotice = 'duration_ssr_advance_notice'
}

export enum DynamicAudience {
  NotSpecified = 'NOT_SPECIFIED',
  JobCoordinators = 'JOB_COORDINATORS',
  JobRecruiters = 'JOB_RECRUITERS',
  JobSourcers = 'JOB_SOURCERS',
  CandidateCoordinator = 'CANDIDATE_COORDINATOR',
  CandidateRecruiter = 'CANDIDATE_RECRUITER',
  CandidateSourcer = 'CANDIDATE_SOURCER',
  TaskAssignee = 'TASK_ASSIGNEE',
  TaskSubscribers = 'TASK_SUBSCRIBERS',
  TaskCreator = 'TASK_CREATOR',
  InterviewersOnSchedule = 'INTERVIEWERS_ON_SCHEDULE',
  PreviousCandidateInterviewers = 'PREVIOUS_CANDIDATE_INTERVIEWERS',
  DebriefAttendees = 'DEBRIEF_ATTENDEES'
}

export type DynamicEmployee = {
  __typename?: 'DynamicEmployee';
  id?: Maybe<Scalars['String']>;
  JOB_COORDINATORS: Array<Employee>;
  JOB_RECRUITERS: Array<Employee>;
  JOB_SOURCERS: Array<Employee>;
  CANDIDATE_COORDINATOR?: Maybe<Employee>;
  CANDIDATE_RECRUITER?: Maybe<Employee>;
  CANDIDATE_SOURCER?: Maybe<Employee>;
  TASK_ASSIGNEE?: Maybe<Employee>;
  TASK_SUBSCRIBERS: Array<Employee>;
  TASK_CREATOR?: Maybe<Employee>;
  INTERVIEWERS_ON_SCHEDULE: Array<Employee>;
  INTERVIEWERS_FOR_THIS_CANDIDATE: Array<Employee>;
  DEBRIEF_ATTENDEES: Array<Employee>;
};


export type DynamicEmployeeJob_CoordinatorsArgs = {
  input: DynamicEmployeeJobInput;
};


export type DynamicEmployeeJob_RecruitersArgs = {
  input: DynamicEmployeeJobInput;
};


export type DynamicEmployeeJob_SourcersArgs = {
  input: DynamicEmployeeJobInput;
};


export type DynamicEmployeeCandidate_CoordinatorArgs = {
  input: DynamicEmployeeCandidateInput;
};


export type DynamicEmployeeCandidate_RecruiterArgs = {
  input: DynamicEmployeeCandidateInput;
};


export type DynamicEmployeeCandidate_SourcerArgs = {
  input: DynamicEmployeeCandidateInput;
};


export type DynamicEmployeeTask_AssigneeArgs = {
  input: DynamicEmployeeTaskInput;
};


export type DynamicEmployeeTask_SubscribersArgs = {
  input: DynamicEmployeeTaskInput;
};


export type DynamicEmployeeTask_CreatorArgs = {
  input: DynamicEmployeeTaskInput;
};


export type DynamicEmployeeInterviewers_On_ScheduleArgs = {
  input: DynamicEmployeeInterviewerInput;
};


export type DynamicEmployeeInterviewers_For_This_CandidateArgs = {
  input: DynamicEmployeeApplicationInput;
};


export type DynamicEmployeeDebrief_AttendeesArgs = {
  input: DynamicEmployeeTaskInput;
};

export type DynamicEmployeeApplicationInput = {
  applicationId: Scalars['uuid'];
};

export type DynamicEmployeeCandidateInput = {
  candidateId: Scalars['uuid'];
};

export type DynamicEmployeeInterviewerInput = {
  employeeIds?: Maybe<Array<Scalars['uuid']>>;
};

export type DynamicEmployeeJobInput = {
  jobId: Scalars['uuid'];
};

export type DynamicEmployeeTaskInput = {
  taskId: Scalars['uuid'];
};

export enum DynamicInterviewMeetingHost {
  NotSet = 'NOT_SET',
  Interviewers = 'INTERVIEWERS',
  Scheduler = 'SCHEDULER',
  CustomZoomHost = 'CUSTOM_ZOOM_HOST',
  CustomMeetingHost = 'CUSTOM_MEETING_HOST'
}

export enum DynamicLinkType {
  Coderpad = 'CODERPAD',
  Hackerrank = 'HACKERRANK',
  Codility = 'CODILITY',
  Codesignal = 'CODESIGNAL'
}

export type Email = {
  __typename?: 'Email';
  id: Scalars['uuid'];
  subject: Scalars['String'];
  sentAt?: Maybe<Scalars['datetime']>;
  recipients: Recipient;
  jobStage: JobStage;
  isSmtpIdAvailable?: Maybe<Scalars['Boolean']>;
};

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  email: Scalars['String'];
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  atsId?: Maybe<Scalars['String']>;
  atsType?: Maybe<AtsType>;
  atsUser?: Maybe<AtsUser>;
  timezone?: Maybe<Scalars['String']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isAtsDisabled?: Maybe<Scalars['Boolean']>;
  hasAtsId?: Maybe<Scalars['Boolean']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  isDirectoryDisabled: Scalars['Boolean'];
  workHours?: Maybe<TimeBlockWeek>;
  interviewPauseDates?: Maybe<Array<DateBlock>>;
  googleUserId?: Maybe<Scalars['String']>;
  directoryUserId?: Maybe<Scalars['String']>;
  slackUserId?: Maybe<Scalars['String']>;
  slackImageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  calendarTimezone?: Maybe<Scalars['String']>;
  useCalendarTimezone: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['String']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
  /**  is the employee currently paused? */
  isPaused: Scalars['Boolean'];
  interviewModuleMember?: Maybe<InterviewModuleMember>;
  loadAndLimit?: Maybe<LoadAndLimit>;
  employeePref?: Maybe<EmployeePref>;
  employeePrefByNames: Array<EmployeePref>;
  employeePrefs?: Maybe<Array<EmployeePref>>;
  employeePrefValues: Array<EmployeePrefValue>;
  attributes?: Maybe<Array<EmployeeAttributeTagValue>>;
  integrations?: Maybe<EmployeeIntegrations>;
  manager?: Maybe<Employee>;
  upcomingInterviewsCount?: Maybe<Scalars['numeric']>;
  modulesTrainedCount?: Maybe<Scalars['numeric']>;
  modulesInTrainingCount?: Maybe<Scalars['numeric']>;
  interviewModuleMembers?: Maybe<EmployeeInterviewModuleMembersOutput>;
  applicationStageInterviews?: Maybe<EmployeeApplicationStageInterviewsOutput>;
  notificationPrefs: Array<EmployeeNotificationPreference>;
  canViewApplication?: Maybe<Scalars['Boolean']>;
  canViewTask?: Maybe<Scalars['Boolean']>;
  plannedAvailabilities: Array<EmployeePlannedAvailability>;
  absoluteWorkHours: Array<DateTimeRangeOutput>;
  /** @deprecated Use externalProfilePicture instead */
  profilePictureUrl?: Maybe<ProfilePicture>;
  externalProfilePicture?: Maybe<ProfilePicture>;
  internalProfilePictureUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
};


export type EmployeeInterviewModuleMemberArgs = {
  interviewModuleId: Scalars['uuid'];
};


export type EmployeeLoadAndLimitArgs = {
  input: LoadAndLimitInput;
};


export type EmployeeEmployeePrefArgs = {
  prefName: Scalars['String'];
};


export type EmployeeEmployeePrefByNamesArgs = {
  prefNames: Array<Scalars['String']>;
};


export type EmployeeInterviewModuleMembersArgs = {
  input: EmployeeInterviewModuleMembersInput;
};


export type EmployeeApplicationStageInterviewsArgs = {
  input: EmployeeApplicationStageInterviewsInput;
};


export type EmployeeCanViewApplicationArgs = {
  input: EmployeeCanViewApplicationInput;
};


export type EmployeeCanViewTaskArgs = {
  input: EmployeeCanViewTaskInput;
};


export type EmployeeAbsoluteWorkHoursArgs = {
  input: EmployeeAbsoluteWorkHoursInput;
};

export type EmployeeAbsoluteWorkHoursInput = {
  range: DateTimeRangeInput;
};

export enum EmployeeApplicationStageInterviewOrderByProperty {
  Date = 'DATE'
}

export type EmployeeApplicationStageInterviewsInput = {
  pageInput: PageInput;
  jobStageNames?: Maybe<Array<Scalars['String']>>;
  jobIds?: Maybe<Array<Scalars['uuid']>>;
  statusFilters?: Maybe<Array<Maybe<EmployeeApplicationStageInterviewsStatusFilter>>>;
  orderBy: EmployeeApplicationStageInterviewsOrderBy;
  includeCancelled?: Maybe<Scalars['Boolean']>;
};

export type EmployeeApplicationStageInterviewsOrderBy = {
  property: EmployeeApplicationStageInterviewOrderByProperty;
  direction: Sort;
};

export type EmployeeApplicationStageInterviewsOutput = {
  __typename?: 'EmployeeApplicationStageInterviewsOutput';
  items: Array<ApplicationStageInterview>;
  nextCursor?: Maybe<Scalars['String']>;
};

export enum EmployeeApplicationStageInterviewsStatusFilter {
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED',
  Ongoing = 'ONGOING'
}

export type EmployeeAttribute = {
  attributeNameId?: Maybe<Scalars['uuid']>;
  attributeValueIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
};

/** ########################################## */
export type EmployeeAttributeBulkUpsertInput = {
  name: Scalars['String'];
  path: Scalars['String'];
};

export type EmployeeAttributeName = {
  __typename?: 'EmployeeAttributeName';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  name: Scalars['String'];
  type: AttributeNameType;
  mappingType: MappingType;
  values?: Maybe<Array<EmployeeAttributeTagValue>>;
  aggregateJobStageInterviewSeatCount?: Maybe<Scalars['Int']>;
};

/**
 * ##########################################################
 *  Employee Attribute
 */
export type EmployeeAttributeNameCreateInput = {
  name: Scalars['String'];
};

export type EmployeeAttributeNameDeleteInput = {
  attributeNameId: Scalars['uuid'];
};

export type EmployeeAttributeNameUpdateInput = {
  attributeNameId: Scalars['uuid'];
  name: Scalars['String'];
};

export type EmployeeAttributeNamesInput = {
  types?: Maybe<Array<Maybe<AttributeNameType>>>;
};

export type EmployeeAttributeNamesResult = {
  __typename?: 'EmployeeAttributeNamesResult';
  items?: Maybe<Array<EmployeeAttributeName>>;
};

export type EmployeeAttributeSet = {
  attributes?: Maybe<Array<Maybe<EmployeeAttribute>>>;
};

export type EmployeeAttributeTagValue = {
  __typename?: 'EmployeeAttributeTagValue';
  id: Scalars['uuid'];
  value: Scalars['String'];
  name?: Maybe<EmployeeAttributeName>;
  employees?: Maybe<Array<Employee>>;
  aggregateJobStageInterviewSeatCount?: Maybe<Scalars['Int']>;
};

export type EmployeeAttributeValueCreateInput = {
  attributeNameId: Scalars['uuid'];
  value: Scalars['String'];
};

export type EmployeeAttributeValueDeleteInput = {
  attributeNameId: Scalars['uuid'];
  attributeTagValueId: Scalars['uuid'];
};

export type EmployeeAttributeValueUpdateInput = {
  attributeNameId: Scalars['uuid'];
  attributeTagValueId: Scalars['uuid'];
  value: Scalars['String'];
};

export type EmployeeAttributesInput = {
  id: Scalars['uuid'];
  set: EmployeeAttributeSet;
};

export enum EmployeeBulkUploadDataType {
  Modules = 'MODULES',
  CustomAttributes = 'CUSTOM_ATTRIBUTES',
  SystemAttributes = 'SYSTEM_ATTRIBUTES'
}

export type EmployeeCanViewApplicationInput = {
  applicationId: Scalars['uuid'];
};

export type EmployeeCanViewTaskInput = {
  taskId: Scalars['uuid'];
};

export type EmployeeDataBulkUpsertInput = {
  name: Scalars['String'];
  path: Scalars['String'];
  dataType: EmployeeBulkUploadDataType;
};

export type EmployeeIntegrations = {
  __typename?: 'EmployeeIntegrations';
  slack?: Maybe<SlackEmployeeIntegration>;
  google?: Maybe<GoogleEmployeeIntegration>;
  microsoft?: Maybe<MicrosoftEmployeeIntegration>;
  microsoftGccHighEmployee?: Maybe<MicrosoftGccHighEmployeeIntegration>;
  /** @deprecated Use microsoftGccHighEmployee instead */
  microsoftEmployeeGccHigh?: Maybe<MicrosoftEmployeeGccHighIntegration>;
};

export type EmployeeInterviewModuleMembersInput = {
  pageInput: PageInput;
  interviewModuleIds?: Maybe<Array<Scalars['uuid']>>;
};

export type EmployeeInterviewModuleMembersOutput = {
  __typename?: 'EmployeeInterviewModuleMembersOutput';
  items?: Maybe<Array<InterviewModuleMember>>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type EmployeeJsonPref = InterviewLimit | EmployeeTaskDashboardLayoutPref | EmployeeTaskDashboardFilterPref | CandidatePageFiltersPref | MetricsPageRecruitingTeamFiltersPref | MetricsPageInterviewerFiltersPref | DirectoryPageFiltersPref | UpcomingEventsPageFiltersPref | RecentlyUsedZoomUsersPref | ProfilePicturePref | InterviewBufferTimePref;

export enum EmployeeNotificationMedium {
  Email = 'EMAIL',
  Chat = 'CHAT'
}

/** ########################################## */
export type EmployeeNotificationPrefUpdateInput = {
  prefName: EmployeeNotificationType;
  medium: EmployeeNotificationMedium;
  value: Scalars['Boolean'];
};

export type EmployeeNotificationPrefUpdateResult = {
  __typename?: 'EmployeeNotificationPrefUpdateResult';
  employeeNotificationPref?: Maybe<EmployeeNotificationPreference>;
};

export type EmployeeNotificationPreference = {
  __typename?: 'EmployeeNotificationPreference';
  id: Scalars['String'];
  /** This is a computed PK */
  orgId: Scalars['uuid'];
  employeeId: Scalars['uuid'];
  type: EmployeeNotificationType;
  medium: EmployeeNotificationMedium;
  value: Scalars['Boolean'];
};

export enum EmployeeNotificationType {
  TaskAssigned = 'TASK_ASSIGNED',
  TaskMention = 'TASK_MENTION',
  TaskNoteAdded = 'TASK_NOTE_ADDED',
  TaskChangeRequested = 'TASK_CHANGE_REQUESTED',
  TaskInterviewerDeclined = 'TASK_INTERVIEWER_DECLINED',
  TaskCandidateDeclined = 'TASK_CANDIDATE_DECLINED',
  TaskAvailabilityRequested = 'TASK_AVAILABILITY_REQUESTED',
  TaskAvailabilitySubmittedByEmployee = 'TASK_AVAILABILITY_SUBMITTED_BY_EMPLOYEE',
  TaskAvailabilitySubmittedByCandidate = 'TASK_AVAILABILITY_SUBMITTED_BY_CANDIDATE',
  TaskSelfScheduleRequested = 'TASK_SELF_SCHEDULE_REQUESTED',
  TaskSelfScheduleBooked = 'TASK_SELF_SCHEDULE_BOOKED',
  TaskSelfScheduleChanged = 'TASK_SELF_SCHEDULE_CHANGED',
  TaskSelfScheduleCanceled = 'TASK_SELF_SCHEDULE_CANCELED',
  TaskMarkedUrgent = 'TASK_MARKED_URGENT',
  TaskRcAdjustInterviewerRsvp = 'TASK_RC_ADJUST_INTERVIEWER_RSVP',
  TaskReadyForCandidateComm = 'TASK_READY_FOR_CANDIDATE_COMM',
  TaskQueueEmployeeMembershipChanged = 'TASK_QUEUE_EMPLOYEE_MEMBERSHIP_CHANGED',
  TaskQueueTaskUnassigned = 'TASK_QUEUE_TASK_UNASSIGNED',
  InterviewTrainingUpdate = 'INTERVIEW_TRAINING_UPDATE',
  TaskDebriefAttendeeDeclined = 'TASK_DEBRIEF_ATTENDEE_DECLINED'
}

/**
 * ################################
 *  Planned availability
 * ################################
 *  Planned availability for an employee
 */
export type EmployeePlannedAvailability = {
  __typename?: 'EmployeePlannedAvailability';
  id: Scalars['uuid'];
  employeeId: Scalars['uuid'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  timezone: Scalars['timezone'];
  workHours: TimeBlockWeek;
};

export type EmployeePlannedAvailabilityCreateInput = {
  employeeId: Scalars['uuid'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  timezone: Scalars['timezone'];
  workHours: TimeBlockWeekInput;
};

export type EmployeePlannedAvailabilityDeleteInput = {
  id: Scalars['uuid'];
};

export type EmployeePlannedAvailabilityResult = {
  __typename?: 'EmployeePlannedAvailabilityResult';
  item: EmployeePlannedAvailability;
};

export type EmployeePlannedAvailabilityUpdateInput = {
  id: Scalars['uuid'];
  startAt?: Maybe<Scalars['datetime']>;
  endAt?: Maybe<Scalars['datetime']>;
  timezone?: Maybe<Scalars['timezone']>;
  workHours?: Maybe<TimeBlockWeekInput>;
};

export type EmployeePref = {
  __typename?: 'EmployeePref';
  id: Scalars['String'];
  /** This is a computed PK */
  orgId: Scalars['uuid'];
  employeeId: Scalars['uuid'];
  prefName: Scalars['String'];
  boolValue?: Maybe<Scalars['Boolean']>;
  stringValue?: Maybe<Scalars['String']>;
  intValue?: Maybe<Scalars['numeric']>;
  stringArray?: Maybe<Array<Scalars['String']>>;
  json?: Maybe<Scalars['jsonb']>;
  jsonArray?: Maybe<Array<Scalars['jsonb']>>;
  timestamp?: Maybe<Scalars['datetime']>;
  jsonPref?: Maybe<EmployeeJsonPref>;
  jsonArrayPref: Array<EmployeeJsonPref>;
  duration?: Maybe<Duration>;
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
};

/** ########################################## */
export type EmployeePrefUpdateInput = {
  employeeId: Scalars['uuid'];
  prefName: Scalars['String'];
  set?: Maybe<PrefSet>;
};

export type EmployeePrefUpdateResult = {
  __typename?: 'EmployeePrefUpdateResult';
  employeePref?: Maybe<EmployeePref>;
};

export type EmployeePrefValue = {
  id: Scalars['String'];
  prefName: Scalars['String'];
  level: PrefLevel;
};

/** ######################################################### */
export type EmployeeSearchInput = {
  pageInput: PageInput;
  isDirectoryDisabled?: Maybe<Scalars['Boolean']>;
  hasAtsId?: Maybe<Scalars['Boolean']>;
  isAtsDisabled?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isManager?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  attributeNameValueMap?: Maybe<Array<AttributeNameValueMapInput>>;
};

export type EmployeeSearchOutput = {
  __typename?: 'EmployeeSearchOutput';
  items: Array<Employee>;
  total?: Maybe<Scalars['Int']>;
};

export type EmployeeSet = {
  timezone?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  useCalendarTimezone?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  managerEmployeeId?: Maybe<Scalars['uuid']>;
  interviewPauseDates?: Maybe<Array<Maybe<DateBlockInput>>>;
  workHours?: Maybe<TimeBlockWeekInput>;
  fullName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
};

export type EmployeeTaskDashboardFilterPref = {
  __typename?: 'EmployeeTaskDashboardFilterPref';
  isUrgent: Scalars['Boolean'];
  assigneeEmployeeIds: Array<Scalars['uuid']>;
  creatorEmployeeIds: Array<Scalars['uuid']>;
  taskStatuses: Array<TaskStatus>;
  jobIds: Array<Scalars['uuid']>;
  jobStageNames: Array<Scalars['String']>;
  flags: Array<TaskFlagType>;
  tagIds: Array<Scalars['uuid']>;
  applicationStatuses: Array<ApplicationStatus>;
  assigneeEmployees: Array<Employee>;
  creatorEmployees: Array<Employee>;
  jobs: Array<Job>;
  tags: Array<TaskTag>;
  showUnassigned: Scalars['Boolean'];
  taskQueueIds: Array<Scalars['uuid']>;
  taskQueues: Array<TaskQueue>;
  showNoQueue: Scalars['Boolean'];
};

export type EmployeeTaskDashboardLayoutPref = {
  __typename?: 'EmployeeTaskDashboardLayoutPref';
  columnType: ScheduleTaskColumnType;
  isVisible: Scalars['Boolean'];
};

export type EmployeeUpdateInput = {
  id: Scalars['uuid'];
  set: EmployeeSet;
};

export type EmployeeUpdateResult = {
  __typename?: 'EmployeeUpdateResult';
  employee?: Maybe<Employee>;
};

/**
 * ##########################################################
 *  Input and output for employees
 */
export type EmployeesInput = {
  pageInput: PageInput;
  isDirectoryDisabled?: Maybe<Scalars['Boolean']>;
  hasAtsId?: Maybe<Scalars['Boolean']>;
  isAtsDisabled?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  /**  Exclude employees which are already added as user of modernloop. */
  excludeUsers?: Maybe<Scalars['Boolean']>;
  requiredPermission?: Maybe<Permission>;
};

export type EmployeesOutput = {
  __typename?: 'EmployeesOutput';
  items: Array<Employee>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type EmptyResult = {
  __typename?: 'EmptyResult';
  result?: Maybe<Result>;
};

export enum ErrorCode {
  ModuleAlreadyExists = 'MODULE_ALREADY_EXISTS',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  Unauthenticated = 'UNAUTHENTICATED',
  OrgPrefPermissionDenied = 'ORG_PREF_PERMISSION_DENIED',
  InterviewModuleNotFound = 'INTERVIEW_MODULE_NOT_FOUND',
  InterviewModuleMemberNotFound = 'INTERVIEW_MODULE_MEMBER_NOT_FOUND',
  GoogleAccessTokenNotFound = 'GOOGLE_ACCESS_TOKEN_NOT_FOUND',
  OrgNotFound = 'ORG_NOT_FOUND',
  EmployeeNotFound = 'EMPLOYEE_NOT_FOUND',
  SlackContentInvalid = 'SLACK_CONTENT_INVALID',
  EmployeeAttributeNotFound = 'EMPLOYEE_ATTRIBUTE_NOT_FOUND',
  InvalidLongUrl = 'INVALID_LONG_URL',
  NotFound = 'NOT_FOUND',
  UserNotFound = 'USER_NOT_FOUND',
  TemplateNameAlreadyExists = 'TEMPLATE_NAME_ALREADY_EXISTS',
  MultiDayPlanNotAllowed = 'MULTI_DAY_PLAN_NOT_ALLOWED',
  AtleastOneInterviewHasNoSeats = 'ATLEAST_ONE_INTERVIEW_HAS_NO_SEATS',
  CandidateLinkInvalid = 'CANDIDATE_LINK_INVALID',
  CandidateLinkExpired = 'CANDIDATE_LINK_EXPIRED',
  InvalidZoomHost = 'INVALID_ZOOM_HOST',
  ZoomUserNotFound = 'ZOOM_USER_NOT_FOUND',
  ZoomUserNotFoundForInterviewPlan = 'ZOOM_USER_NOT_FOUND_FOR_INTERVIEW_PLAN',
  CandidateNotFound = 'CANDIDATE_NOT_FOUND',
  SlackConversationInviteError = 'SLACK_CONVERSATION_INVITE_ERROR',
  EmailTemplateFailedToRender = 'EMAIL_TEMPLATE_FAILED_TO_RENDER',
  ZoomNotConnectedButLocationIsZoom = 'ZOOM_NOT_CONNECTED_BUT_LOCATION_IS_ZOOM',
  ActiveSelfScheduleAlreadyPresent = 'ACTIVE_SELF_SCHEDULE_ALREADY_PRESENT',
  SelfScheduleRequestNotFound = 'SELF_SCHEDULE_REQUEST_NOT_FOUND',
  NoSchedulesFoundWhileBookingSelfSchedule = 'NO_SCHEDULES_FOUND_WHILE_BOOKING_SELF_SCHEDULE',
  SelfScheduleCandidateEmailIsInvalid = 'SELF_SCHEDULE_CANDIDATE_EMAIL_IS_INVALID',
  SelfScheduleAdvanceNoticeNotHonoured = 'SELF_SCHEDULE_ADVANCE_NOTICE_NOT_HONOURED',
  SelfScheduleStartTimeAlreadyElapsed = 'SELF_SCHEDULE_START_TIME_ALREADY_ELAPSED',
  ZoomUserNoPermission = 'ZOOM_USER_NO_PERMISSION',
  NotAllRequiredScopesConsentedByUser = 'NOT_ALL_REQUIRED_SCOPES_CONSENTED_BY_USER',
  AuthCodeGrantFailed = 'AUTH_CODE_GRANT_FAILED',
  RefreshCodeGrantFailed = 'REFRESH_CODE_GRANT_FAILED',
  RefreshAdminCodeGrantFailed = 'REFRESH_ADMIN_CODE_GRANT_FAILED',
  InvalidToken = 'INVALID_TOKEN',
  ZoomIntegrationAlreadyConnectedBySameUser = 'ZOOM_INTEGRATION_ALREADY_CONNECTED_BY_SAME_USER',
  GoogleIntegrationAdminMayNotBelongToYourOrg = 'GOOGLE_INTEGRATION_ADMIN_MAY_NOT_BELONG_TO_YOUR_ORG',
  CanNotConnectGoogleAsOutlookIsConnected = 'CAN_NOT_CONNECT_GOOGLE_AS_OUTLOOK_IS_CONNECTED',
  CanNotConnectOutlookAsGoogleIsConnected = 'CAN_NOT_CONNECT_OUTLOOK_AS_GOOGLE_IS_CONNECTED',
  OneAtsIsAlreadyConnected = 'ONE_ATS_IS_ALREADY_CONNECTED',
  ErrorListingUsersFromAts = 'ERROR_LISTING_USERS_FROM_ATS',
  OrgNotConnectedToAuth0 = 'ORG_NOT_CONNECTED_TO_AUTH0',
  SamlDuplicateName = 'SAML_DUPLICATE_NAME',
  SamlBadRequest = 'SAML_BAD_REQUEST',
  CanNotAddDirectoryDisabledEmployeeToModule = 'CAN_NOT_ADD_DIRECTORY_DISABLED_EMPLOYEE_TO_MODULE',
  CandidateAvailabilityOptionsMissingNotificationRecipients = 'CANDIDATE_AVAILABILITY_OPTIONS_MISSING_NOTIFICATION_RECIPIENTS',
  UserFacingEmployeeNotFound = 'USER_FACING_EMPLOYEE_NOT_FOUND',
  ApplicationNotFound = 'APPLICATION_NOT_FOUND',
  ApplicationStageNotFound = 'APPLICATION_STAGE_NOT_FOUND',
  ApplicationStageInterviewNotFound = 'APPLICATION_STAGE_INTERVIEW_NOT_FOUND',
  InvalidInput = 'INVALID_INPUT',
  OrgIntegrationNotFound = 'ORG_INTEGRATION_NOT_FOUND',
  EmployeeIntegrationNotFound = 'EMPLOYEE_INTEGRATION_NOT_FOUND',
  ConversationNotFound = 'CONVERSATION_NOT_FOUND',
  PrefNotFound = 'PREF_NOT_FOUND',
  CandidateAvailabilityOptionsNotFound = 'CANDIDATE_AVAILABILITY_OPTIONS_NOT_FOUND',
  CandidateAvailabilityNotFound = 'CANDIDATE_AVAILABILITY_NOT_FOUND',
  SelfScheduleOptionsNotFound = 'SELF_SCHEDULE_OPTIONS_NOT_FOUND',
  JobStageNotFound = 'JOB_STAGE_NOT_FOUND',
  JobNotFound = 'JOB_NOT_FOUND',
  ApplicationDebriefNotFound = 'APPLICATION_DEBRIEF_NOT_FOUND',
  RateLimitExceeded = 'RATE_LIMIT_EXCEEDED',
  PermissionDenied = 'PERMISSION_DENIED',
  ScimUserNotFound = 'SCIM_USER_NOT_FOUND',
  ScimUserAlreadyExists = 'SCIM_USER_ALREADY_EXISTS',
  PermissionDeniedForImpersonatedUser = 'PERMISSION_DENIED_FOR_IMPERSONATED_USER',
  CandidateResumeNotFound = 'CANDIDATE_RESUME_NOT_FOUND',
  TaskTagNotFound = 'TASK_TAG_NOT_FOUND',
  ActivityLogNotFound = 'ACTIVITY_LOG_NOT_FOUND',
  InvalidInputForNote = 'INVALID_INPUT_FOR_NOTE',
  TryingDisconnectCurrentAts = 'TRYING_DISCONNECT_CURRENT_ATS',
  CalendarHasTooManyEvents = 'CALENDAR_HAS_TOO_MANY_EVENTS',
  OrgCantForceDeletionOfNonDeletedOrg = 'ORG_CANT_FORCE_DELETION_OF_NON_DELETED_ORG',
  OrgDeletingOrgNameDoesntMatchRequest = 'ORG_DELETING_ORG_NAME_DOESNT_MATCH_REQUEST',
  ScheduleUpdateAlreadyInProgress = 'SCHEDULE_UPDATE_ALREADY_IN_PROGRESS',
  ScheduleCreateAlreadyInProgress = 'SCHEDULE_CREATE_ALREADY_IN_PROGRESS',
  UnusedTokensCandidateEventTitle = 'UNUSED_TOKENS_CANDIDATE_EVENT_TITLE',
  UnusedTokensCandidateEventBody = 'UNUSED_TOKENS_CANDIDATE_EVENT_BODY',
  UnusedTokensInterviewerEventTitle = 'UNUSED_TOKENS_INTERVIEWER_EVENT_TITLE',
  UnusedTokensInterviewerEventBody = 'UNUSED_TOKENS_INTERVIEWER_EVENT_BODY',
  UnusedTokensCandidateEmailSubject = 'UNUSED_TOKENS_CANDIDATE_EMAIL_SUBJECT',
  UnusedTokensCandidateEmailBody = 'UNUSED_TOKENS_CANDIDATE_EMAIL_BODY',
  ActorMissingAtsId = 'ACTOR_MISSING_ATS_ID',
  OrgNotConnectedToAts = 'ORG_NOT_CONNECTED_TO_ATS',
  ConnectedAtsIsDifferentThanScheduleAts = 'CONNECTED_ATS_IS_DIFFERENT_THAN_SCHEDULE_ATS',
  InvalidEmployeeNotificationRequest = 'INVALID_EMPLOYEE_NOTIFICATION_REQUEST',
  ScheduleCreateCalendarPermissionMissing = 'SCHEDULE_CREATE_CALENDAR_PERMISSION_MISSING',
  ScheduleUpdateCalendarPermissionMissing = 'SCHEDULE_UPDATE_CALENDAR_PERMISSION_MISSING',
  ScheduleCancelCalendarPermissionMissing = 'SCHEDULE_CANCEL_CALENDAR_PERMISSION_MISSING',
  SelfScheduleCreateCalendarPermissionMissing = 'SELF_SCHEDULE_CREATE_CALENDAR_PERMISSION_MISSING',
  ScheduleAlreadyExists = 'SCHEDULE_ALREADY_EXISTS',
  MissingCandidateCalendarId = 'MISSING_CANDIDATE_CALENDAR_ID',
  TaskTagLimitExceeded = 'TASK_TAG_LIMIT_EXCEEDED',
  TaskTagAlreadyExists = 'TASK_TAG_ALREADY_EXISTS',
  TaskAlreadyCancelled = 'TASK_ALREADY_CANCELLED',
  ErrorCreatingScheduleOnAts = 'ERROR_CREATING_SCHEDULE_ON_ATS',
  ErrorCreatingInterviewerEvents = 'ERROR_CREATING_INTERVIEWER_EVENTS',
  ErrorCreatingCandidateEvents = 'ERROR_CREATING_CANDIDATE_EVENTS',
  RequestMissingCandidateTimezone = 'REQUEST_MISSING_CANDIDATE_TIMEZONE',
  InvalidSearchArguments = 'INVALID_SEARCH_ARGUMENTS',
  InvalidInputDuplicateInterviewer = 'INVALID_INPUT_DUPLICATE_INTERVIEWER',
  InvalidInputNoInterviewerSlot = 'INVALID_INPUT_NO_INTERVIEWER_SLOT',
  InvalidInputNoCandidateAvailability = 'INVALID_INPUT_NO_CANDIDATE_AVAILABILITY',
  InvalidInputNoCandidateAvailabilityInPast = 'INVALID_INPUT_NO_CANDIDATE_AVAILABILITY_IN_PAST',
  InvalidInputTerminalBreak = 'INVALID_INPUT_TERMINAL_BREAK',
  InvalidInputTerminalDayDivider = 'INVALID_INPUT_TERMINAL_DAY_DIVIDER',
  InvalidInputModuleMissingTrainedInterviewer = 'INVALID_INPUT_MODULE_MISSING_TRAINED_INTERVIEWER',
  InvalidInputManualStartConflict = 'INVALID_INPUT_MANUAL_START_CONFLICT',
  GenericInvalidInput = 'GENERIC_INVALID_INPUT',
  TaskTagNameEmpty = 'TASK_TAG_NAME_EMPTY',
  InvalidInputForNoteCreate = 'INVALID_INPUT_FOR_NOTE_CREATE',
  InvalidInputNoInterviewer = 'INVALID_INPUT_NO_INTERVIEWER',
  TaskNotFound = 'TASK_NOT_FOUND',
  InvalidCreatedBy = 'INVALID_CREATED_BY',
  InvalidCreatedAt = 'INVALID_CREATED_AT',
  InvalidInputTimeRangesOverlap = 'INVALID_INPUT_TIME_RANGES_OVERLAP',
  PortalEmailIsRequired = 'PORTAL_EMAIL_IS_REQUIRED',
  PortalEmailMismatchWithApplication = 'PORTAL_EMAIL_MISMATCH_WITH_APPLICATION',
  PortalBrandingThemeAlreadyExists = 'PORTAL_BRANDING_THEME_ALREADY_EXISTS',
  PortalBrandingThemeLimitReached = 'PORTAL_BRANDING_THEME_LIMIT_REACHED',
  PortalJobStageIsRequired = 'PORTAL_JOB_STAGE_IS_REQUIRED',
  PortalTemplateIsRequired = 'PORTAL_TEMPLATE_IS_REQUIRED',
  PortalTemplateIsNotRequired = 'PORTAL_TEMPLATE_IS_NOT_REQUIRED',
  InvalidInputBulkUploadEmpModuleTrainingInfo = 'INVALID_INPUT_BULK_UPLOAD_EMP_MODULE_TRAINING_INFO',
  InvalidInputBulkUploadEmpCustomAttribute = 'INVALID_INPUT_BULK_UPLOAD_EMP_CUSTOM_ATTRIBUTE',
  MissingPrimaryColumnBulkUploadEmpData = 'MISSING_PRIMARY_COLUMN_BULK_UPLOAD_EMP_DATA',
  PortalTemplateNameAlreadyExists = 'PORTAL_TEMPLATE_NAME_ALREADY_EXISTS',
  OtpExpired = 'OTP_EXPIRED',
  PremiumPortalNotEnabled = 'PREMIUM_PORTAL_NOT_ENABLED',
  CannotUpdateCancelledSchedule = 'CANNOT_UPDATE_CANCELLED_SCHEDULE',
  NameIsRequired = 'NAME_IS_REQUIRED',
  CandidateMissingEmail = 'CANDIDATE_MISSING_EMAIL',
  InvalidCandidateEmailAsEmployeeRequest = 'INVALID_CANDIDATE_EMAIL_AS_EMPLOYEE_REQUEST',
  ErrorCreatingInterviewOnAts = 'ERROR_CREATING_INTERVIEW_ON_ATS',
  ErrorUpdatingInterviewOnAts = 'ERROR_UPDATING_INTERVIEW_ON_ATS',
  TaskEnabledApplicationMissingTaskId = 'TASK_ENABLED_APPLICATION_MISSING_TASK_ID',
  EmployeePlannedAvailabilityNotFound = 'EMPLOYEE_PLANNED_AVAILABILITY_NOT_FOUND',
  EmployeePlannedAvailabilityTimeRangeInPast = 'EMPLOYEE_PLANNED_AVAILABILITY_TIME_RANGE_IN_PAST',
  EmployeePlannedAvailabilityTimeRangeOverlaps = 'EMPLOYEE_PLANNED_AVAILABILITY_TIME_RANGE_OVERLAPS',
  EmployeePlannedAvailabilityTimeRangeInvalid = 'EMPLOYEE_PLANNED_AVAILABILITY_TIME_RANGE_INVALID',
  ForbiddenBrandingThemeName = 'FORBIDDEN_BRANDING_THEME_NAME',
  PortalTemplateBlockRequired = 'PORTAL_TEMPLATE_BLOCK_REQUIRED',
  PortalOrgSlugLimitReached = 'PORTAL_ORG_SLUG_LIMIT_REACHED',
  InvalidPortalOrgSlug = 'INVALID_PORTAL_ORG_SLUG',
  RescheduleLogNotFound = 'RESCHEDULE_LOG_NOT_FOUND',
  RescheduleLogReasonAlreadyExists = 'RESCHEDULE_LOG_REASON_ALREADY_EXISTS',
  RescheduleSystemLogCannotArchive = 'RESCHEDULE_SYSTEM_LOG_CANNOT_ARCHIVE',
  TooManyRescheduleLogs = 'TOO_MANY_RESCHEDULE_LOGS',
  ModuleInterviewLimitTooHigh = 'MODULE_INTERVIEW_LIMIT_TOO_HIGH',
  PortalOrgSlugExists = 'PORTAL_ORG_SLUG_EXISTS',
  PortalOrgSlugTooLong = 'PORTAL_ORG_SLUG_TOO_LONG',
  PortalOrgSlugTooShort = 'PORTAL_ORG_SLUG_TOO_SHORT',
  OrgNameTooLong = 'ORG_NAME_TOO_LONG',
  UnusedTokensInterviewerSlackBody = 'UNUSED_TOKENS_INTERVIEWER_SLACK_BODY',
  CandidateAvailabilityExceeds_100Hours = 'CANDIDATE_AVAILABILITY_EXCEEDS_100_HOURS',
  CandidateInvalidApplicationState = 'CANDIDATE_INVALID_APPLICATION_STATE',
  EmployeeInvalidApplicationState = 'EMPLOYEE_INVALID_APPLICATION_STATE',
  TaskMissingCandidateSchedulingRequest = 'TASK_MISSING_CANDIDATE_SCHEDULING_REQUEST',
  CandidatePortalCannotBeEnabledAsApplicationTooOld = 'CANDIDATE_PORTAL_CANNOT_BE_ENABLED_AS_APPLICATION_TOO_OLD',
  ScheduleAlreadyExistsOnTask = 'SCHEDULE_ALREADY_EXISTS_ON_TASK',
  ApplicationRejected = 'APPLICATION_REJECTED',
  TaskQueueNotFound = 'TASK_QUEUE_NOT_FOUND',
  TaskQueueLimitExceeded = 'TASK_QUEUE_LIMIT_EXCEEDED',
  TaskQueueNameEmpty = 'TASK_QUEUE_NAME_EMPTY',
  TaskQueueAlreadyExists = 'TASK_QUEUE_ALREADY_EXISTS',
  InvalidCompanyHoliday = 'INVALID_COMPANY_HOLIDAY',
  InvalidCompanyHolidayCountry = 'INVALID_COMPANY_HOLIDAY_COUNTRY',
  ZoomUserRateLimited = 'ZOOM_USER_RATE_LIMITED',
  InterviewPlanHasHiddenInterviews = 'INTERVIEW_PLAN_HAS_HIDDEN_INTERVIEWS',
  InvalidCountryValue = 'INVALID_COUNTRY_VALUE',
  TaskQueueNameNoQueue = 'TASK_QUEUE_NAME_NO_QUEUE',
  CandidatePortalNoteExists = 'CANDIDATE_PORTAL_NOTE_EXISTS',
  SlackTeamMissingInOauth = 'SLACK_TEAM_MISSING_IN_OAUTH',
  InterviewDefinitionDoesNotExistOnJob = 'INTERVIEW_DEFINITION_DOES_NOT_EXIST_ON_JOB',
  InterviewModuleMemberManualLogNotFound = 'INTERVIEW_MODULE_MEMBER_MANUAL_LOG_NOT_FOUND',
  InterviewModuleMemberManualLogDateInFuture = 'INTERVIEW_MODULE_MEMBER_MANUAL_LOG_DATE_IN_FUTURE',
  LoginUserNotConnectedToAnyOrg = 'LOGIN_USER_NOT_CONNECTED_TO_ANY_ORG',
  LoginUserAttemptingSamlWithGoogleOrg = 'LOGIN_USER_ATTEMPTING_SAML_WITH_GOOGLE_ORG',
  LoginUserAttemptingGoogleWithSamlOrg = 'LOGIN_USER_ATTEMPTING_GOOGLE_WITH_SAML_ORG',
  LoginUserAttemptingWrongOrg = 'LOGIN_USER_ATTEMPTING_WRONG_ORG',
  LoginUserMissingEmail = 'LOGIN_USER_MISSING_EMAIL',
  MethodNotAllowedInThisEnv = 'METHOD_NOT_ALLOWED_IN_THIS_ENV',
  SourcingLinkAlreadyUsed = 'SOURCING_LINK_ALREADY_USED',
  LoginUsernamePasswordNotAllowed = 'LOGIN_USERNAME_PASSWORD_NOT_ALLOWED',
  InterviewModuleMemberStatsNotLoaded = 'INTERVIEW_MODULE_MEMBER_STATS_NOT_LOADED',
  SourcingLinkApplyInProgress = 'SOURCING_LINK_APPLY_IN_PROGRESS',
  SourcingLinkInterviewPlanIsMandatoryForSelfSchedule = 'SOURCING_LINK_INTERVIEW_PLAN_IS_MANDATORY_FOR_SELF_SCHEDULE',
  AtsRecentlyDisconnected = 'ATS_RECENTLY_DISCONNECTED',
  SourcingLinkJobIsClosed = 'SOURCING_LINK_JOB_IS_CLOSED',
  WorkdayApplicationNotInSchedulableStep = 'WORKDAY_APPLICATION_NOT_IN_SCHEDULABLE_STEP',
  WorkdayException = 'WORKDAY_EXCEPTION',
  WorkdayInvalidNextJobStage = 'WORKDAY_INVALID_NEXT_JOB_STAGE',
  WorkdayCannotMapAtsInterviewMloopInterview = 'WORKDAY_CANNOT_MAP_ATS_INTERVIEW_MLOOP_INTERVIEW',
  WorkdayJobStageNotSchedulable = 'WORKDAY_JOB_STAGE_NOT_SCHEDULABLE',
  SettingsFirstNameCannotBeNone = 'SETTINGS_FIRST_NAME_CANNOT_BE_NONE',
  SettingsLastNameCannotBeNone = 'SETTINGS_LAST_NAME_CANNOT_BE_NONE',
  SettingsEmailCannotBeNone = 'SETTINGS_EMAIL_CANNOT_BE_NONE',
  SettingsEmailCannotBeWrite = 'SETTINGS_EMAIL_CANNOT_BE_WRITE',
  SettingsTimezoneCannotBeNone = 'SETTINGS_TIMEZONE_CANNOT_BE_NONE',
  IntegrationApiKeyInvalid = 'INTEGRATION_API_KEY_INVALID',
  SlackTeamIdsDoNotMatch = 'SLACK_TEAM_IDS_DO_NOT_MATCH',
  TargetCalendarIdSetToSelf = 'TARGET_CALENDAR_ID_SET_TO_SELF',
  WorkdayScheduleFrozen = 'WORKDAY_SCHEDULE_FROZEN',
  UserAttemptingToChangeOwnRole = 'USER_ATTEMPTING_TO_CHANGE_OWN_ROLE',
  CannotSetLoadLimitBelowOrgMinimum = 'CANNOT_SET_LOAD_LIMIT_BELOW_ORG_MINIMUM',
  DuplicateJobStageMapping = 'DUPLICATE_JOB_STAGE_MAPPING',
  InterviewPlanNoInterviewsSpecifiedInGroup = 'INTERVIEW_PLAN_NO_INTERVIEWS_SPECIFIED_IN_GROUP',
  InterviewPlanIncorrectNumberOfInterviews = 'INTERVIEW_PLAN_INCORRECT_NUMBER_OF_INTERVIEWS',
  InterviewPlanIncorrectPositionOfInterviews = 'INTERVIEW_PLAN_INCORRECT_POSITION_OF_INTERVIEWS',
  GreenhouseApiKeyShouldBeDifferentThanSubdomain = 'GREENHOUSE_API_KEY_SHOULD_BE_DIFFERENT_THAN_SUBDOMAIN',
  InvalidMeetingLocationSettings = 'INVALID_MEETING_LOCATION_SETTINGS',
  InterviewPlanBreakTooLarge = 'INTERVIEW_PLAN_BREAK_TOO_LARGE',
  SourcingLinkInterviewTimeAlreadyBooked = 'SOURCING_LINK_INTERVIEW_TIME_ALREADY_BOOKED',
  InvalidCreateMeetingLocationRequest = 'INVALID_CREATE_MEETING_LOCATION_REQUEST',
  SelectedUserDoesNotHaveRequiredPermissions = 'SELECTED_USER_DOES_NOT_HAVE_REQUIRED_PERMISSIONS',
  AttemptingToScheduleUnschedulableInterview = 'ATTEMPTING_TO_SCHEDULE_UNSCHEDULABLE_INTERVIEW',
  CandidateAlreadyAppliedToJob = 'CANDIDATE_ALREADY_APPLIED_TO_JOB',
  PlanWithDynamicBreaksNotSupported = 'PLAN_WITH_DYNAMIC_BREAKS_NOT_SUPPORTED',
  RescheduleScheduleAlreadyStarted = 'RESCHEDULE_SCHEDULE_ALREADY_STARTED',
  CancelScheduleAlreadyStarted = 'CANCEL_SCHEDULE_ALREADY_STARTED',
  ZoomUserDoesNotExist = 'ZOOM_USER_DOES_NOT_EXIST',
  UnsupportedDynamicHostOnSelfSchedule = 'UNSUPPORTED_DYNAMIC_HOST_ON_SELF_SCHEDULE',
  MeetingHostNotSet = 'MEETING_HOST_NOT_SET',
  MeetingLocationSettingsNotSet = 'MEETING_LOCATION_SETTINGS_NOT_SET'
}

export enum ErrorDomain {
  Org = 'ORG',
  Integration = 'INTEGRATION',
  InterviewModule = 'INTERVIEW_MODULE',
  Employee = 'EMPLOYEE',
  UrlShortner = 'URL_SHORTNER',
  SelfSchedule = 'SELF_SCHEDULE',
  TemplateModule = 'TEMPLATE_MODULE',
  CandidatePortal = 'CANDIDATE_PORTAL',
  Zoom = 'ZOOM',
  Interview = 'INTERVIEW',
  Slack = 'SLACK',
  Email = 'EMAIL',
  Job = 'JOB',
  JobStage = 'JOB_STAGE',
  Graphql = 'GRAPHQL',
  Saml = 'SAML',
  Ats = 'ATS',
  CandidateAvailability = 'CANDIDATE_AVAILABILITY',
  Calendar = 'CALENDAR',
  Schedule = 'SCHEDULE',
  Debrief = 'DEBRIEF',
  Scim = 'SCIM',
  Permission = 'PERMISSION',
  Task = 'TASK',
  ActivityLog = 'ACTIVITY_LOG',
  TaskFlag = 'TASK_FLAG',
  Notification = 'NOTIFICATION',
  EmployeeInfoBulkUpload = 'EMPLOYEE_INFO_BULK_UPLOAD',
  InterviewerPortal = 'INTERVIEWER_PORTAL',
  SourcingLink = 'SOURCING_LINK'
}

export type EventInput = {
  timeRange: DateTimeRangeInput;
  name: Scalars['String'];
  isBreak: Scalars['Boolean'];
  interviewerIds?: Maybe<Array<Scalars['uuid']>>;
  codeLink?: Maybe<CodeLinkInput>;
  zoomInfo?: Maybe<ZoomInfoInput>;
  customVideoMeetingLinkUrl?: Maybe<Scalars['String']>;
  workspaceVideoLink?: Maybe<Scalars['String']>;
  workspaceVideoLinkUrl?: Maybe<Scalars['String']>;
  interviewers?: Maybe<Array<InterviewerInput>>;
  isHiddenFromCandidate?: Maybe<Scalars['Boolean']>;
};

export type EventsRsvp = {
  candidateRsvp?: Maybe<Array<Maybe<ResponseStatus>>>;
  interviewerRsvp?: Maybe<Array<Maybe<ResponseStatus>>>;
  allAccepted?: Maybe<Scalars['Boolean']>;
};

export type EventsWithConflicts = {
  __typename?: 'EventsWithConflicts';
  flag?: Maybe<InterviewerFlag>;
  events?: Maybe<Array<Scalars['String']>>;
};

export type FilledInterviewSeat = {
  __typename?: 'FilledInterviewSeat';
  interviewerId?: Maybe<Scalars['uuid']>;
  traineeId?: Maybe<Scalars['uuid']>;
  traineeRole: InterviewerRole;
  seatId: Scalars['uuid'];
};

export type FreeformSeat = {
  __typename?: 'FreeformSeat';
  jobStageInterviewSeatEmployees?: Maybe<Array<JobStageInterviewSeatEmployee>>;
};

export type FreeformSeatInput = {
  jobStageInterviewSeatEmployees: Array<JobStageInterviewSeatEmployeeInput>;
};

export type GemIntegrationHealth = {
  __typename?: 'GemIntegrationHealth';
  isInstalled: Scalars['Boolean'];
  isHealthy: Scalars['Boolean'];
  canReadUsers: Scalars['Boolean'];
  canReadUser: Scalars['Boolean'];
  canReadJobs: Scalars['Boolean'];
  canReadJob: Scalars['Boolean'];
  canReadJobStages: Scalars['Boolean'];
  canReadJobStageForJob: Scalars['Boolean'];
  canReadJobPostings: Scalars['Boolean'];
  canReadUserRoles: Scalars['Boolean'];
  canReadInterviews: Scalars['Boolean'];
  canReadInterview: Scalars['Boolean'];
  canReadCandidates: Scalars['Boolean'];
  canReadCandidate: Scalars['Boolean'];
  canReadActivityFeed: Scalars['Boolean'];
  canReadApplications: Scalars['Boolean'];
  canReadApplication: Scalars['Boolean'];
};

export type GemInterviewDefinition = {
  __typename?: 'GemInterviewDefinition';
  gemInterviewKitId: Scalars['String'];
  index: Scalars['numeric'];
  estimatedMinutes: Scalars['numeric'];
};

export type GemJob = {
  __typename?: 'GemJob';
  jobRequisitionId?: Maybe<Scalars['String']>;
};

export type GoogleEmployeeIntegration = {
  __typename?: 'GoogleEmployeeIntegration';
  active: Scalars['Boolean'];
};

export type GoogleIntegration = {
  __typename?: 'GoogleIntegration';
  adminUserId: Scalars['String'];
};

export type GoogleIntegrationHealth = {
  __typename?: 'GoogleIntegrationHealth';
  isInstalled: Scalars['Boolean'];
  isHealthy: Scalars['Boolean'];
  canReadDirectory: Scalars['Boolean'];
  canReadMeetingRooms: Scalars['Boolean'];
  canReadCalendars: Scalars['Boolean'];
  canSendGmail: Scalars['Boolean'];
};

export type GoogleMeetingResult = {
  __typename?: 'GoogleMeetingResult';
  url: Scalars['String'];
};

export enum GraduationApprovalType {
  Automatic = 'AUTOMATIC',
  Scheduler = 'SCHEDULER',
  Custom = 'CUSTOM'
}

export enum GraduationApproverDecisionType {
  AwaitingFeedback = 'AWAITING_FEEDBACK',
  MoveForward = 'MOVE_FORWARD',
  DoAnother = 'DO_ANOTHER',
  MissedInterview = 'MISSED_INTERVIEW',
  Invalid = 'INVALID',
  Override = 'OVERRIDE'
}

export enum GraduationApproverType {
  Trained = 'TRAINED',
  Trainee = 'TRAINEE',
  Scheduler = 'SCHEDULER'
}

export type GreenhouseCandidate = {
  __typename?: 'GreenhouseCandidate';
  greenhouseRecruiterId?: Maybe<Scalars['numeric']>;
  greenhouseCoordinatorId?: Maybe<Scalars['numeric']>;
};

export type GreenhouseIntegration = {
  __typename?: 'GreenhouseIntegration';
  apiKey: Scalars['String'];
  subdomain: Scalars['String'];
  webhookSecret?: Maybe<Scalars['String']>;
};

export type GreenhouseIntegrationHealth = {
  __typename?: 'GreenhouseIntegrationHealth';
  isInstalled: Scalars['Boolean'];
  isHealthy: Scalars['Boolean'];
  canReadUsers: Scalars['Boolean'];
  canReadUser: Scalars['Boolean'];
  canReadJobs: Scalars['Boolean'];
  canReadJob: Scalars['Boolean'];
  canReadJobStages: Scalars['Boolean'];
  canReadJobStageForJob: Scalars['Boolean'];
  canReadJobPostings: Scalars['Boolean'];
  canReadUserRoles: Scalars['Boolean'];
  canReadEmailTemplates: Scalars['Boolean'];
  canReadInterviews: Scalars['Boolean'];
  canReadInterview: Scalars['Boolean'];
  canReadCandidates: Scalars['Boolean'];
  canReadCandidate: Scalars['Boolean'];
  canReadActivityFeed: Scalars['Boolean'];
  canReadApplications: Scalars['Boolean'];
  canReadApplication: Scalars['Boolean'];
  canReadUserPermission: Scalars['Boolean'];
};

export type GreenhouseInterviewDefinition = {
  __typename?: 'GreenhouseInterviewDefinition';
  greenhouseInterviewKitId: Scalars['numeric'];
  index: Scalars['numeric'];
  estimatedMinutes: Scalars['numeric'];
};

export type GreenhouseJob = {
  __typename?: 'GreenhouseJob';
  jobRequisitionId?: Maybe<Scalars['String']>;
};

export type HackerRankIntegration = {
  __typename?: 'HackerRankIntegration';
  apiKey: Scalars['String'];
};

export type HardConflict = {
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  eventUid: Scalars['String'];
};

export type HardConflictInput = {
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  organizerEmail: Scalars['String'];
  eventUid: Scalars['String'];
};

export type Id = {
  __typename?: 'Id';
  id: Scalars['uuid'];
};

export type IndexOrderBy = {
  index?: Maybe<Sort>;
};

export type InitScheduleFlowInput = {
  jobStageId: Scalars['uuid'];
};

export type InitScheduleFlowResult = {
  __typename?: 'InitScheduleFlowResult';
  /**  Templates */
  candidateConfirmationEmailTemplate?: Maybe<Template>;
  candidateConfirmationInviteTemplate?: Maybe<Template>;
  interviewerConfirmationInviteTemplate?: Maybe<Template>;
  interviewerConversationMessageTemplate?: Maybe<Template>;
  /**  Calendars */
  candidateCalendar?: Maybe<Calendar>;
  interviewerCalendar?: Maybe<Calendar>;
  /**  Other */
  isPrivateCalendarEvents: Scalars['Boolean'];
};

export enum IntEmployeePref {
  IntPref = 'int_pref'
}

export type IntEmployeePrefValue = EmployeePrefValue & {
  __typename?: 'IntEmployeePrefValue';
  id: Scalars['String'];
  prefName: Scalars['String'];
  level: PrefLevel;
  value?: Maybe<Scalars['numeric']>;
};

export enum IntOrgPref {
  IntCompanyDataRetentionDays = 'int_company_data_retention_days',
  IntDaysUntilHiredApplicationCanSeePortal = 'int_days_until_hired_application_can_see_portal',
  IntDaysUntilRejectedApplicationCanSeePortal = 'int_days_until_rejected_application_can_see_portal',
  IntCandidateAvailabilityReminderMinutes = 'int_candidate_availability_reminder_minutes',
  IntSelfScheduleReminderMinutes = 'int_self_schedule_reminder_minutes',
  IntCandidateInterviewReminderMinutes = 'int_candidate_interview_reminder_minutes'
}

export type IntegrationCheckHealthInput = {
  /** If you want to evict the cache before checking health, pass true. */
  force?: Maybe<Scalars['Boolean']>;
};

export type IntegrationCheckHealthResult = {
  __typename?: 'IntegrationCheckHealthResult';
  google?: Maybe<GoogleIntegrationHealth>;
  zoom?: Maybe<ZoomIntegrationHealth>;
  microsoft?: Maybe<MicrosoftIntegrationHealth>;
  microsoftGccHigh?: Maybe<MicrosoftIntegrationHealth>;
  slack?: Maybe<SlackIntegrationHealth>;
  greenhouse?: Maybe<GreenhouseIntegrationHealth>;
  ashby?: Maybe<AshbyIntegrationHealth>;
  lever?: Maybe<LeverIntegrationHealth>;
  gem?: Maybe<GemIntegrationHealth>;
};

export enum IntegrationConnectionStatus {
  NotConnected = 'NOT_CONNECTED',
  Syncing = 'SYNCING',
  Connected = 'CONNECTED'
}

export type IntegrationGetOauthUrlInput = {
  integrationType: IntegrationType;
  redirectUrl?: Maybe<Scalars['String']>;
  loginHint?: Maybe<Scalars['String']>;
};

export type IntegrationGetOauthUrlResult = {
  __typename?: 'IntegrationGetOauthUrlResult';
  url: Scalars['String'];
  redirectUri: Scalars['String'];
};

export enum IntegrationType {
  Lever = 'LEVER',
  Google = 'GOOGLE',
  AshbyApiKey = 'ASHBY_API_KEY',
  GreenhouseApiKey = 'GREENHOUSE_API_KEY',
  GemApiKey = 'GEM_API_KEY',
  CoderpadApiKey = 'CODERPAD_API_KEY',
  CodesignalApiKey = 'CODESIGNAL_API_KEY',
  Zoom = 'ZOOM',
  SlackOrg = 'SLACK_ORG',
  SlackEmployee = 'SLACK_EMPLOYEE',
  HackerrankApiKey = 'HACKERRANK_API_KEY',
  CodilityApiKey = 'CODILITY_API_KEY',
  Microsoft = 'MICROSOFT',
  MicrosoftEmployee = 'MICROSOFT_EMPLOYEE',
  MicrosoftGccHigh = 'MICROSOFT_GCC_HIGH',
  MicrosoftGccHighEmployee = 'MICROSOFT_GCC_HIGH_EMPLOYEE',
  GoogleEmployee = 'GOOGLE_EMPLOYEE',
  Docusign = 'DOCUSIGN',
  Workday = 'WORKDAY'
}

export type InterviewAdjustRsvpInput = {
  applicationStageInterviewId: Scalars['uuid'];
  interviewerId: Scalars['uuid'];
  responseStatus: ResponseStatus;
  reason?: Maybe<DeclineReason>;
  comment?: Maybe<Scalars['String']>;
};

export type InterviewAdjustRsvpResult = {
  __typename?: 'InterviewAdjustRsvpResult';
  result?: Maybe<Result>;
};

export type InterviewBufferTimePref = {
  __typename?: 'InterviewBufferTimePref';
  before?: Maybe<Duration>;
  after?: Maybe<Duration>;
};

export type InterviewEvent = {
  __typename?: 'InterviewEvent';
  id: Scalars['uuid'];
  jobStageInterviewId: Scalars['uuid'];
  name: Scalars['String'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  interviewers: Array<InterviewerSuggestion>;
  isBreak: Scalars['Boolean'];
  seats: Array<FilledInterviewSeat>;
  isHiddenFromCandidate: Scalars['Boolean'];
};

export type InterviewGroupInput = {
  jobStageInterviewGroupId: Scalars['uuid'];
  locked: Scalars['Boolean'];
  interviews: Array<InterviewInput>;
};

export type InterviewInput = {
  jobStageInterviewId: Scalars['uuid'];
  type: InterviewType;
  durationMinutes: Scalars['Int'];
  locked: Scalars['Boolean'];
  name: Scalars['String'];
  forcedStartAt?: Maybe<Scalars['datetime']>;
  seats: Array<ScheduleOptionSeatInput>;
  isHiddenFromCandidate?: Maybe<Scalars['Boolean']>;
};

export type InterviewInterviewerDeclined = {
  __typename?: 'InterviewInterviewerDeclined';
  applicationStageInterviewId: Scalars['uuid'];
  reason?: Maybe<DeclineReason>;
  comment?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type InterviewLimit = {
  __typename?: 'InterviewLimit';
  type?: Maybe<InterviewLimitType>;
  limit?: Maybe<Scalars['Int']>;
};

export enum InterviewLimitType {
  NumberOfInterviews = 'NUMBER_OF_INTERVIEWS',
  NumberOfMinutes = 'NUMBER_OF_MINUTES'
}

export type InterviewLocation = {
  __typename?: 'InterviewLocation';
  /**  This can be null if the type is not yet understood by BE. */
  type?: Maybe<InterviewLocationType>;
  value?: Maybe<Scalars['String']>;
};

export enum InterviewLocationType {
  GoogleMeet = 'GOOGLE_MEET',
  Zoom = 'ZOOM',
  MicrosoftTeams = 'MICROSOFT_TEAMS',
  CustomLink = 'CUSTOM_LINK',
  Phone = 'PHONE'
}

export type InterviewMeetingLocationSettingPref = {
  __typename?: 'InterviewMeetingLocationSettingPref';
  interviewMeetingLocationType: InterviewMeetingLocationType;
  dynamicHost?: Maybe<DynamicInterviewMeetingHost>;
  hostEmployeeId?: Maybe<Scalars['String']>;
  hostEmployee?: Maybe<Employee>;
  remoteVideoMeetingHostUserId?: Maybe<Scalars['String']>;
  /**  zoomUserId goes here */
  remoteVideoMeetingHostUser?: Maybe<RemoteVideoMeetingHostUser>;
  customLocation?: Maybe<Scalars['String']>;
};

export type InterviewMeetingLocationSettingWithLevel = {
  __typename?: 'InterviewMeetingLocationSettingWithLevel';
  level: SettingLevel;
  interviewMeetingLocationSetting?: Maybe<InterviewMeetingLocationSettingPref>;
};

export type InterviewMeetingLocationSettingsInput = {
  interviewMeetingLocationType: InterviewMeetingLocationType;
  hostEmployeeId?: Maybe<Scalars['uuid']>;
  dynamicHost?: Maybe<DynamicInterviewMeetingHost>;
  remoteVideoMeetingHostUserId?: Maybe<Scalars['String']>;
  customLocation?: Maybe<Scalars['String']>;
};

export enum InterviewMeetingLocationType {
  NotSpecified = 'NOT_SPECIFIED',
  Zoom = 'ZOOM',
  GoogleMeet = 'GOOGLE_MEET',
  MicrosoftTeams = 'MICROSOFT_TEAMS',
  CustomLink = 'CUSTOM_LINK',
  CandidatePhone = 'CANDIDATE_PHONE',
  CustomPhone = 'CUSTOM_PHONE',
  CustomText = 'CUSTOM_TEXT',
  None = 'NONE'
}

export type InterviewMetrics = {
  __typename?: 'InterviewMetrics';
  id?: Maybe<Scalars['String']>;
  /**  This can be mloop, lever or gh id hence string as gh is not uuid */
  interviewName?: Maybe<Scalars['String']>;
  calendarEventTitle?: Maybe<Scalars['String']>;
  jobStage?: Maybe<Scalars['String']>;
  scorecard?: Maybe<Scalars['String']>;
  candidate?: Maybe<Scalars['String']>;
  scheduler?: Maybe<Scalars['String']>;
  /**  this should be schedulerName */
  interviewDate?: Maybe<Scalars['datetime']>;
  interviewer?: Maybe<Scalars['String']>;
  /**  this should be interviewerName */
  schedulerId?: Maybe<Scalars['String']>;
  /**  For ashby we can send empty string */
  interviewerId?: Maybe<Scalars['uuid']>;
  atsInterviewId?: Maybe<Scalars['String']>;
  interviewDurationSeconds?: Maybe<Scalars['numeric']>;
  declineReason?: Maybe<Scalars['String']>;
  declineComment?: Maybe<Scalars['String']>;
  declineSource?: Maybe<Scalars['String']>;
  declineLeadTime?: Maybe<Scalars['Float']>;
  job?: Maybe<Scalars['String']>;
  jobId?: Maybe<Scalars['uuid']>;
  /**  This is mloop id */
  atsJobId?: Maybe<Scalars['String']>;
  /**  Need download chart */
  schedulerEmployee?: Maybe<Employee>;
  /**  Need download chart - # this should be scheduler */
  interviewerEmployee?: Maybe<Employee>;
};

export type InterviewModule = {
  __typename?: 'InterviewModule';
  id: Scalars['uuid'];
  name: Scalars['String'];
  orgId: Scalars['uuid'];
  shadowsRequired: Scalars['numeric'];
  reverseShadowsRequired: Scalars['numeric'];
  membersCount: Scalars['numeric'];
  membersTrainedCount: Scalars['numeric'];
  membersInTrainingCount: Scalars['numeric'];
  /**  number shadow + reverse_shadow */
  membersInReverseShadowCount: Scalars['numeric'];
  membersInShadowCount: Scalars['numeric'];
  membersInPendingApprovalCount: Scalars['numeric'];
  interviewModuleMembers?: Maybe<InterviewModuleMembersOutput>;
  interviewModuleMembersByIds: Array<InterviewModuleMember>;
  graduateFromShadowApprovalType: GraduationApprovalType;
  graduateFromShadowCustomApprovers?: Maybe<Array<GraduationApproverType>>;
  graduateFromReverseShadowApprovalType: GraduationApprovalType;
  graduateFromReverseShadowCustomApprovers?: Maybe<Array<GraduationApproverType>>;
  /**  Number of interviews which can be conducted at max. null means no limit. */
  weeklyNumInterviewLimit?: Maybe<Scalars['numeric']>;
  shouldFastTrackTraining: Scalars['Boolean'];
};


export type InterviewModuleInterviewModuleMembersArgs = {
  input: InterviewModuleMembersInput;
};


export type InterviewModuleInterviewModuleMembersByIdsArgs = {
  input: InterviewModuleMembersByIdsInput;
};

export type InterviewModuleAddMembersInput = {
  interviewModuleId: Scalars['uuid'];
  addMembers: Array<AddModuleMemberInput>;
};

export type InterviewModuleCreateInput = {
  name?: Maybe<Scalars['String']>;
  requiredShadowsCount?: Maybe<Scalars['Int']>;
  requiredReverseShadowsCount?: Maybe<Scalars['Int']>;
  weeklyNumInterviewLimit?: Maybe<Scalars['Int']>;
  shouldFastTrackTraining?: Maybe<Scalars['Boolean']>;
};

export type InterviewModuleMember = {
  __typename?: 'InterviewModuleMember';
  id: Scalars['String'];
  /** This is a computed field. */
  interviewModuleId: Scalars['uuid'];
  employeeId: Scalars['uuid'];
  status: TrainingStatus;
  shadowsRequired: Scalars['Int'];
  reverseShadowsRequired: Scalars['Int'];
  isPausedIndefinitely?: Maybe<Scalars['Boolean']>;
  pausedUntil?: Maybe<Scalars['datetime']>;
  isPaused?: Maybe<Scalars['Boolean']>;
  employee?: Maybe<Employee>;
  /** @deprecated Use moduleMemberInterviewLogs instead. */
  applicationStageInterviews?: Maybe<ApplicationStageInterviewsOutput>;
  moduleMemberInterviewLogs?: Maybe<ModuleMemberInterviewLogOutput>;
  pendingApprovalApplicationStageInterview?: Maybe<ApplicationStageInterview>;
  nextStatus: TrainingStatus;
  stats?: Maybe<InterviewModuleMemberStats>;
  interviewModule?: Maybe<InterviewModule>;
  /**  Number of interviews which can be conducted at max. null means no limit. */
  weeklyNumInterviewLimit?: Maybe<Scalars['numeric']>;
  createdAt?: Maybe<Scalars['datetime']>;
  shadowStartAt?: Maybe<Scalars['datetime']>;
  reverseShadowStartAt?: Maybe<Scalars['datetime']>;
  trainedAt?: Maybe<Scalars['datetime']>;
  timeToTrain?: Maybe<Scalars['numeric']>;
};


export type InterviewModuleMemberApplicationStageInterviewsArgs = {
  input?: Maybe<ApplicationStageInterviewsInput>;
};


export type InterviewModuleMemberModuleMemberInterviewLogsArgs = {
  input?: Maybe<ModuleMemberInterviewLogInput>;
};

/** ############################################################################# */
export type InterviewModuleMemberApproveInput = {
  interviewModuleId: Scalars['uuid'];
  interviewModuleMemberId: Scalars['uuid'];
};

export type InterviewModuleMemberLogCreateInput = {
  moduleId: Scalars['uuid'];
  memberId: Scalars['uuid'];
  role: TrainingStatus;
  interviewDate: Scalars['datetime'];
};

export type InterviewModuleMemberLogDeleteInput = {
  id: Scalars['uuid'];
};

export type InterviewModuleMemberLogUpdateInput = {
  id: Scalars['uuid'];
  /**  Can be asi_id or InterviewModuleMemberLog_id */
  role: TrainingStatus;
  interviewDate: Scalars['datetime'];
};

/** ########################################################## */
export type InterviewModuleMemberLookupInput = {
  interviewModuleMemberIds: Array<Scalars['uuid']>;
  interviewModuleIds?: Maybe<Array<Scalars['uuid']>>;
};

export type InterviewModuleMemberLookupOutput = {
  __typename?: 'InterviewModuleMemberLookupOutput';
  items: Array<InterviewModuleMember>;
};

export type InterviewModuleMemberManualLog = {
  __typename?: 'InterviewModuleMemberManualLog';
  id: Scalars['uuid'];
  moduleId: Scalars['uuid'];
  memberId: Scalars['uuid'];
  role: TrainingStatus;
  interviewDate: Scalars['datetime'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  updatedBy: Employee;
};

export type InterviewModuleMemberManualLogResult = {
  __typename?: 'InterviewModuleMemberManualLogResult';
  interviewModuleMemberManualLog?: Maybe<InterviewModuleMemberManualLog>;
};

/** ############################################################################# */
export type InterviewModuleMemberMoveStageInput = {
  interviewModuleId: Scalars['uuid'];
  interviewModuleMemberId: Scalars['uuid'];
  newStatus: TrainingStatus;
  newLevel: Scalars['Int'];
};

export type InterviewModuleMemberResult = {
  __typename?: 'InterviewModuleMemberResult';
  interviewModuleMember?: Maybe<InterviewModuleMember>;
};

/**
 * ##########################################################
 *  Input and output for interviewModuleMemberSearch
 */
export type InterviewModuleMemberSearchInput = {
  pageInput: PageInput;
  interviewModuleId: Scalars['uuid'];
  search?: Maybe<Scalars['String']>;
  filterByTrainingStatus?: Maybe<Array<Maybe<TrainingStatus>>>;
  filterByState?: Maybe<Array<Maybe<InterviewModuleMemberState>>>;
  orderBy?: Maybe<InterviewModuleMemberSearchOrderBy>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isAtsDisabled?: Maybe<Scalars['Boolean']>;
  isDirectoryDisabled?: Maybe<Scalars['Boolean']>;
  hasAtsId?: Maybe<Scalars['Boolean']>;
  useArchivedFilter?: Maybe<Scalars['Boolean']>;
  useAtsDisabledFilter?: Maybe<Scalars['Boolean']>;
  attributeNameValueMap?: Maybe<Array<AttributeNameValueMapInput>>;
};

export type InterviewModuleMemberSearchOrderBy = {
  property: InterviewModuleMemberSearchOrderByProperty;
  direction: Sort;
};

export enum InterviewModuleMemberSearchOrderByProperty {
  Name = 'NAME',
  TrainingStartDate = 'TRAINING_START_DATE',
  ProgressPercentage = 'PROGRESS_PERCENTAGE',
  TrainingEndDate = 'TRAINING_END_DATE'
}

export type InterviewModuleMemberSearchOutput = {
  __typename?: 'InterviewModuleMemberSearchOutput';
  items: Array<InterviewModuleMember>;
  total?: Maybe<Scalars['Int']>;
};

/**  The states by which we can query for interview module members. */
export enum InterviewModuleMemberState {
  Paused = 'PAUSED',
  Scheduled = 'SCHEDULED',
  Unscheduled = 'UNSCHEDULED',
  NeedsApproval = 'NEEDS_APPROVAL'
}

export type InterviewModuleMemberStats = {
  __typename?: 'InterviewModuleMemberStats';
  interviewModule?: Maybe<InterviewModule>;
  interviewModuleMember?: Maybe<InterviewModuleMember>;
  completed?: Maybe<Scalars['numeric']>;
  /**  completed in ModernLoop */
  upcoming?: Maybe<Scalars['numeric']>;
  /**  upcoming scheduled in ModernLoop */
  completedAsShadow?: Maybe<Scalars['numeric']>;
  /**  completed as shadow in ModernLoop */
  completedAsReverseShadow?: Maybe<Scalars['numeric']>;
  /**  completed as reverse shadow in ModernLoop */
  trainingStatus?: Maybe<TrainingStatus>;
  currentTrainingSession?: Maybe<Scalars['String']>;
  /**  Training Status + Level */
  currentTrainingSessionStatus?: Maybe<CurrentTrainingSessionStatus>;
  trainingsToPass?: Maybe<Scalars['numeric']>;
  shadowsRequired?: Maybe<Scalars['numeric']>;
  reverseShadowsRequired?: Maybe<Scalars['numeric']>;
  shadowOffset?: Maybe<Scalars['numeric']>;
  reverseShadowOffset?: Maybe<Scalars['numeric']>;
  completedAsInterviewer: Scalars['numeric'];
  manualCompletedAsInterviewer: Scalars['numeric'];
  manualCompletedAsShadow: Scalars['numeric'];
  manualCompletedAsReverseShadow: Scalars['numeric'];
};

export type InterviewModuleMemberUpdateInput = {
  interviewModuleId: Scalars['uuid'];
  employeeId: Scalars['uuid'];
  status?: Maybe<TrainingStatus>;
  isPausedIndefinitely?: Maybe<Scalars['Boolean']>;
  pausedUntil?: Maybe<Scalars['datetime']>;
  clearPaused?: Maybe<Scalars['Boolean']>;
  shadowRequiredCount?: Maybe<Scalars['Int']>;
  reverseShadowRequiredCount?: Maybe<Scalars['Int']>;
  shadowOrReverseShadowIncrementBy?: Maybe<Scalars['Int']>;
  weeklyNumInterviewLimit?: Maybe<Scalars['Int']>;
  addedToModuleAt?: Maybe<Scalars['datetime']>;
  trainedAt?: Maybe<Scalars['datetime']>;
  clearTrainedAt?: Maybe<Scalars['Boolean']>;
};

export type InterviewModuleMembersByIdsInput = {
  interviewModuleMemberIds: Array<Scalars['uuid']>;
};

/**
 * ##########################################################
 *  Input and output for interviewModuleMembers
 */
export type InterviewModuleMembersInput = {
  pageInput: PageInput;
  interviewModuleId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Array<TrainingStatus>>;
};

export type InterviewModuleMembersOutput = {
  __typename?: 'InterviewModuleMembersOutput';
  items: Array<InterviewModuleMember>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type InterviewModuleRemoveMembersInput = {
  interviewModuleId: Scalars['uuid'];
  employeeIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
};

/** ############################################################################# */
export type InterviewModuleResult = {
  __typename?: 'InterviewModuleResult';
  interviewModule?: Maybe<InterviewModule>;
};

export type InterviewModuleSearchFilter = {
  employeeIds?: Maybe<Array<Scalars['uuid']>>;
};

/**
 * ##########################################################
 *  Input and output for interviewModuleSearch
 */
export type InterviewModuleSearchInput = {
  pageInput: PageInput;
  search?: Maybe<Scalars['String']>;
  orderBy: InterviewModulesOrderBy;
  filters?: Maybe<InterviewModuleSearchFilter>;
};

export type InterviewModuleSearchOutput = {
  __typename?: 'InterviewModuleSearchOutput';
  items: Array<InterviewModule>;
  total?: Maybe<Scalars['Int']>;
};

export type InterviewModuleUpdateInput = {
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  requiredShadowsCount?: Maybe<Scalars['Int']>;
  graduateFromShadowApprovalType?: Maybe<GraduationApprovalType>;
  graduateFromShadowCustomApprovers?: Maybe<Array<Maybe<GraduationApproverType>>>;
  requiredReverseShadowsCount?: Maybe<Scalars['Int']>;
  graduateFromReverseShadowApprovalType?: Maybe<GraduationApprovalType>;
  graduateFromReverseShadowCustomApprovers?: Maybe<Array<Maybe<GraduationApproverType>>>;
  weeklyNumInterviewLimit?: Maybe<Scalars['Int']>;
  shouldFastTrackTraining?: Maybe<Scalars['Boolean']>;
};

/**
 * ##########################################################
 *  Input and output for interviewModules
 */
export type InterviewModulesInput = {
  pageInput: PageInput;
  search?: Maybe<Scalars['String']>;
  orderBy: InterviewModulesOrderBy;
};

/**  How to order by interview modules? */
export type InterviewModulesOrderBy = {
  property: InterviewModulesOrderByProperty;
  direction: Sort;
};

export enum InterviewModulesOrderByProperty {
  Name = 'NAME',
  TrainedCount = 'TRAINED_COUNT',
  InTrainingCount = 'IN_TRAINING_COUNT',
  NeedsApprovalCount = 'NEEDS_APPROVAL_COUNT'
}

export type InterviewModulesOutput = {
  __typename?: 'InterviewModulesOutput';
  items: Array<InterviewModule>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type InterviewNameSetting = {
  __typename?: 'InterviewNameSetting';
  isCustom: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  level: SettingLevel;
  /**  Specific to this setting */
  showInterviewName: Scalars['Boolean'];
};

export type InterviewNameSettingInput = {
  /**
   * Setting "isCustom" to false has the affect of explicitly setting the Job Setting's value to be the org default.
   * Otherwise, if "isCustom" is true, then the "showInterviewName" value must be present and will be used.
   */
  isCustom: Scalars['Boolean'];
  showInterviewName?: Maybe<Scalars['Boolean']>;
};

export type InterviewPlan = {
  __typename?: 'InterviewPlan';
  id: Scalars['uuid'];
  /** This is jobStageId */
  jobStageInterviewGroups: Array<JobStageInterviewGroup>;
  interviewPlanSource: InterviewPlanSource;
};

export type InterviewPlanInput = {
  groups: Array<JobStageInterviewGroupInput>;
  schedulingWindow?: Maybe<DurationInput>;
  excludedEmployeeIds: Array<Scalars['uuid']>;
};

export enum InterviewPlanSource {
  Task = 'TASK',
  Ats = 'ATS',
  Candidate = 'CANDIDATE',
  SourcingLink = 'SOURCING_LINK'
}

export enum InterviewType {
  Interview = 'INTERVIEW',
  Break = 'BREAK',
  /** This means we can schedule on any day after the current day. */
  DayDivider = 'DAY_DIVIDER',
  AnyTimeLater = 'ANY_TIME_LATER',
  /** This strictly means that we should schedule this interview on the very next day, no later. */
  NextDay = 'NEXT_DAY'
}

export enum InterviewTypePrefType {
  AnyTimeLater = 'ANY_TIME_LATER',
  ImmediatelyAfter = 'IMMEDIATELY_AFTER'
}

export type InterviewerAndPreferenceLevel = {
  employeeId: Scalars['uuid'];
  preferenceLevel: Scalars['Int'];
};

export type InterviewerContentInput = {
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  interviewers: Array<InterviewerInput>;
  summary: Scalars['String'];
  description: Scalars['String'];
  videoMeetingUrl?: Maybe<Scalars['String']>;
  codingInterviewUrl: Scalars['String'];
  interviewName: Scalars['String'];
  atsInterviewDefinitionId?: Maybe<Scalars['String']>;
  emailTemplateId?: Maybe<Scalars['uuid']>;
  zoomInfo?: Maybe<ZoomInfoInput>;
  /**  maps to `job_stage_interview_id` in `application_stage_interview` table */
  jobStageInterviewId?: Maybe<Scalars['uuid']>;
  meetingRoomIds?: Maybe<Array<Scalars['uuid']>>;
  isHiddenFromCandidate?: Maybe<Scalars['Boolean']>;
  videoMeetingHostEmployeeId?: Maybe<Scalars['uuid']>;
  /**  maps to `meeting_location_id` in `application_stage_interview` table */
  meetingLocationId?: Maybe<Scalars['uuid']>;
};

export type InterviewerFilters = {
  interviewerIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  managerIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  jobIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  jobStageIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  jobStageNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  interviewModuleIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  attributeValueIds?: Maybe<Array<Scalars['uuid']>>;
  showArchived?: Maybe<Scalars['Boolean']>;
};

export enum InterviewerFlag {
  Conflict = 'CONFLICT',
  MissingTimezone = 'MISSING_TIMEZONE',
  MissingCalendar = 'MISSING_CALENDAR',
  InterviewConflict = 'INTERVIEW_CONFLICT',
  OutsideWorkHours = 'OUTSIDE_WORK_HOURS',
  OutsideRecruitingBlock = 'OUTSIDE_RECRUITING_BLOCK',
  InsideRecruitingBlock = 'INSIDE_RECRUITING_BLOCK',
  OverDailyLimit = 'OVER_DAILY_LIMIT',
  OverWeeklyLimit = 'OVER_WEEKLY_LIMIT',
  Paused = 'PAUSED',
  Repeat = 'REPEAT',
  Weekend = 'WEEKEND',
  ReverseShadow = 'REVERSE_SHADOW',
  Required = 'REQUIRED',
  Shadow = 'SHADOW',
  Linked = 'LINKED',
  Ooo = 'OOO',
  SchedulableConflict = 'SCHEDULABLE_CONFLICT',
  UnschedulableConflict = 'UNSCHEDULABLE_CONFLICT',
  ExternalConflict = 'EXTERNAL_CONFLICT',
  OneOnOneConflict = 'ONE_ON_ONE_CONFLICT',
  HoldConflict = 'HOLD_CONFLICT',
  PausedInModule = 'PAUSED_IN_MODULE',
  MiddleOfTheNight = 'MIDDLE_OF_THE_NIGHT',
  /** The hours between 9PM and 6AM */
  PrivateCalendar = 'PRIVATE_CALENDAR',
  /** The event belongs to a private calendar */
  OutsideWorkHoursBreakTime = 'OUTSIDE_WORK_HOURS_BREAK_TIME',
  /** Within the earliest work hour and latest work hour, but not a work hour */
  OutsideWorkHours_30 = 'OUTSIDE_WORK_HOURS_30',
  /** Starting or ending within 30 minutes of work hours */
  OutsideWorkHours_60 = 'OUTSIDE_WORK_HOURS_60',
  /** Starting or ending within 60 minutes of work hours */
  OutsideWorkHours_90 = 'OUTSIDE_WORK_HOURS_90',
  /** Starting or ending within 90 minutes of work hours */
  OutsideWorkHours_120 = 'OUTSIDE_WORK_HOURS_120',
  /** Starting or ending within 120 minutes of work hours */
  OverWeeklyModuleLimit = 'OVER_WEEKLY_MODULE_LIMIT',
  NotPreferred = 'NOT_PREFERRED',
  Preferred = 'PREFERRED',
  CompanyCountryHoliday = 'COMPANY_COUNTRY_HOLIDAY',
  PreviousInterviewsForApplication = 'PREVIOUS_INTERVIEWS_FOR_APPLICATION',
  ConflictOfInterestWithCandidate = 'CONFLICT_OF_INTEREST_WITH_CANDIDATE',
  BufferConflict = 'BUFFER_CONFLICT'
}

export type InterviewerInfoSetting = {
  __typename?: 'InterviewerInfoSetting';
  isCustom: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  level: SettingLevel;
  /**  Specific to this setting */
  canShowInfo: Scalars['Boolean'];
  canShowFullName: Scalars['Boolean'];
  canShowJobTitle: Scalars['Boolean'];
  canShowLinkedin: Scalars['Boolean'];
  canShowEmail: Scalars['Boolean'];
  canShowProfilePicture: Scalars['Boolean'];
  canShowPronouns: Scalars['Boolean'];
  canShowBio: Scalars['Boolean'];
};

export type InterviewerInfoSettingInput = {
  /**
   * Setting "isCustom" to false has the affect of explicitly setting the Job Setting's value to be the org default.
   * Otherwise, if "isCustom" is true, then the "canShowInfo" value must be present and will be used.
   */
  isCustom: Scalars['Boolean'];
  canShowInfo?: Maybe<Scalars['Boolean']>;
};

export type InterviewerInput = {
  employeeId: Scalars['uuid'];
  role: InterviewerRole;
  interviewModuleId?: Maybe<Scalars['uuid']>;
  jobStageInterviewSeatId?: Maybe<Scalars['uuid']>;
  isOptional?: Maybe<Scalars['Boolean']>;
};

/**
 * #########################
 *  input for interviewer metrics chart and tables
 */
export type InterviewerMetricsChartInput = {
  chartType?: Maybe<InterviewerMetricsChartType>;
  startAt?: Maybe<Scalars['datetime']>;
  endAt?: Maybe<Scalars['datetime']>;
  filters: InterviewerFilters;
  timezone?: Maybe<Scalars['timezone']>;
};

/**
 * #########################
 *  input types for interviewer metrics chart and tables
 */
export type InterviewerMetricsChartResult = {
  __typename?: 'InterviewerMetricsChartResult';
  chartType: InterviewerMetricsChartType;
  data?: Maybe<Array<ChartCoordinateData>>;
};

/**
 * #########################
 *  enum types for interviewer metrics chart and tables
 */
export enum InterviewerMetricsChartType {
  InterviewsCompleted = 'INTERVIEWS_COMPLETED',
  InterviewsDeclines = 'INTERVIEWS_DECLINES',
  InterviewsDeclineReasons = 'INTERVIEWS_DECLINE_REASONS',
  InterviewsDeclineLeadTime = 'INTERVIEWS_DECLINE_LEAD_TIME'
}

export type InterviewerMetricsDeclines = {
  __typename?: 'InterviewerMetricsDeclines';
  interviewer?: Maybe<Employee>;
  leadTime?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  declineComment?: Maybe<Scalars['String']>;
  declineSource?: Maybe<Scalars['String']>;
  declineReason?: Maybe<Scalars['String']>;
};

export type InterviewerMetricsDeclinesResult = {
  __typename?: 'InterviewerMetricsDeclinesResult';
  items?: Maybe<Array<InterviewerMetricsDeclines>>;
};

export type InterviewerMetricsDeclinesWrapperResult = {
  __typename?: 'InterviewerMetricsDeclinesWrapperResult';
  declinesChart?: Maybe<InterviewerMetricsChartResult>;
  declineReasonsChart?: Maybe<InterviewerMetricsChartResult>;
  declineLeadTimeChart?: Maybe<InterviewerMetricsChartResult>;
  declineTable?: Maybe<InterviewerMetricsDeclinesResult>;
};

export type InterviewerMetricsInterviewing = {
  __typename?: 'InterviewerMetricsInterviewing';
  interviewer?: Maybe<Employee>;
  upcoming?: Maybe<Scalars['numeric']>;
  completed?: Maybe<Scalars['numeric']>;
  declines?: Maybe<Scalars['numeric']>;
  totalTime?: Maybe<Scalars['Float']>;
  interviewPerWeek?: Maybe<Scalars['Float']>;
  timePerWeek?: Maybe<Scalars['Float']>;
};

export type InterviewerMetricsInterviewingResult = {
  __typename?: 'InterviewerMetricsInterviewingResult';
  items?: Maybe<Array<InterviewerMetricsInterviewing>>;
};

export type InterviewerMetricsTableInput = {
  startAt?: Maybe<Scalars['datetime']>;
  endAt?: Maybe<Scalars['datetime']>;
  filters: InterviewerFilters;
  pageInput?: Maybe<PageInput>;
};

export type InterviewerMetricsTraining = {
  __typename?: 'InterviewerMetricsTraining';
  interviewer?: Maybe<Employee>;
  trainer?: Maybe<Scalars['numeric']>;
  shadows?: Maybe<Scalars['numeric']>;
  reverseShadows?: Maybe<Scalars['numeric']>;
  modulesAsTrainee?: Maybe<Scalars['String']>;
};

export type InterviewerMetricsTrainingResult = {
  __typename?: 'InterviewerMetricsTrainingResult';
  items?: Maybe<Array<InterviewerMetricsTraining>>;
};

export enum InterviewerPortalFieldPermission {
  None = 'NONE',
  Read = 'READ',
  Write = 'WRITE'
}

export type InterviewerPortalSettings = {
  __typename?: 'InterviewerPortalSettings';
  id: Scalars['uuid'];
  firstName: InterviewerPortalFieldPermission;
  lastName: InterviewerPortalFieldPermission;
  title: InterviewerPortalFieldPermission;
  emailAddress: InterviewerPortalFieldPermission;
  linkedinUrl: InterviewerPortalFieldPermission;
  timezone: InterviewerPortalFieldPermission;
  interviewLoadCapacity: InterviewerPortalFieldPermission;
  interviewAvailability: InterviewerPortalFieldPermission;
  temporaryAvailability: InterviewerPortalFieldPermission;
  pauseFromInterviews: InterviewerPortalFieldPermission;
  interviewerKeywords: InterviewerPortalFieldPermission;
  profilePicture: InterviewerPortalFieldPermission;
  pronouns: InterviewerPortalFieldPermission;
  bio: InterviewerPortalFieldPermission;
  feedbackWritingTime: InterviewerPortalFieldPermission;
  bufferTime: InterviewerPortalFieldPermission;
};

/** ######################################### */
export type InterviewerPortalSettingsUpsertInput = {
  firstName?: Maybe<InterviewerPortalFieldPermission>;
  lastName?: Maybe<InterviewerPortalFieldPermission>;
  title?: Maybe<InterviewerPortalFieldPermission>;
  emailAddress?: Maybe<InterviewerPortalFieldPermission>;
  linkedinUrl?: Maybe<InterviewerPortalFieldPermission>;
  timezone?: Maybe<InterviewerPortalFieldPermission>;
  interviewLoadCapacity?: Maybe<InterviewerPortalFieldPermission>;
  interviewAvailability?: Maybe<InterviewerPortalFieldPermission>;
  temporaryAvailability?: Maybe<InterviewerPortalFieldPermission>;
  pauseFromInterviews?: Maybe<InterviewerPortalFieldPermission>;
  interviewerKeywords?: Maybe<InterviewerPortalFieldPermission>;
  profilePicture?: Maybe<InterviewerPortalFieldPermission>;
  pronouns?: Maybe<InterviewerPortalFieldPermission>;
  bio?: Maybe<InterviewerPortalFieldPermission>;
  feedbackWritingTime?: Maybe<InterviewerPortalFieldPermission>;
  bufferTime?: Maybe<InterviewerPortalFieldPermission>;
};

export type InterviewerResponseStatusInput = {
  interviewerId: Scalars['uuid'];
  responseStatus: ResponseStatus;
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
};

export enum InterviewerRole {
  Interviewer = 'INTERVIEWER',
  ReverseShadow = 'REVERSE_SHADOW',
  Shadow = 'SHADOW'
}

export type InterviewerRsvpInput = {
  responseStatus: ResponseStatus;
  reason?: Maybe<DeclineReason>;
  comment?: Maybe<Scalars['String']>;
};

export type InterviewerSuggestion = {
  __typename?: 'InterviewerSuggestion';
  role?: Maybe<InterviewerRole>;
  loadAndLimit?: Maybe<LoadAndLimit>;
  employeeId: Scalars['String'];
  employee?: Maybe<Employee>;
  flags?: Maybe<Array<InterviewerFlag>>;
  eventsWithConflicts?: Maybe<Array<EventsWithConflicts>>;
  companyHolidays: Array<CompanyHoliday>;
  previousInterviewsForApplication?: Maybe<PreviousInterviewsForApplication>;
};

/**
 * ##########################################################
 * ##########################################################
 *  Input and output for interviewerSuggestions
 */
export type InterviewerSuggestionsInput = {
  prefix?: Maybe<Scalars['String']>;
  interviewModuleId?: Maybe<Scalars['uuid']>;
  freeformEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
  freeformInterviewers?: Maybe<Array<InterviewerAndPreferenceLevel>>;
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  attributeValueIds?: Maybe<Array<Scalars['uuid']>>;
  attributes?: Maybe<Array<EmployeeAttribute>>;
  hardConflicts?: Maybe<Array<HardConflict>>;
  excludedEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
  /**
   * below field is mandatory going forward to avoid break change will add it now.
   * will add mandatory in next build
   */
  applicationId?: Maybe<Scalars['uuid']>;
};

export type InterviewerSuggestionsOutput = {
  __typename?: 'InterviewerSuggestionsOutput';
  employees?: Maybe<Array<InterviewerSuggestion>>;
  others?: Maybe<Array<InterviewerSuggestion>>;
};

export type Job = {
  __typename?: 'Job';
  id: Scalars['uuid'];
  name: Scalars['String'];
  /**  This will be the name of the posting or if there is no posting, the name of the job. */
  externalJobName: Scalars['String'];
  fallbackName?: Maybe<Scalars['String']>;
  orgId: Scalars['uuid'];
  status: JobStatus;
  atsId: Scalars['String'];
  atsType: AtsType;
  atsJob?: Maybe<AtsJob>;
  isConfidential: Scalars['Boolean'];
  location?: Maybe<Array<Scalars['String']>>;
  jobStages?: Maybe<Array<JobStage>>;
  org?: Maybe<Org>;
  jobStagesAggregate?: Maybe<Scalars['numeric']>;
  hiringManagers?: Maybe<Array<Employee>>;
  recruiters?: Maybe<Array<Employee>>;
  coordinators?: Maybe<Array<Employee>>;
  sourcers?: Maybe<Array<Employee>>;
  jobStagesWithInterviewPlansCount: Scalars['numeric'];
  portalJobOptions?: Maybe<PortalJobOptions>;
  portalJobOptionsPreview?: Maybe<PortalJobOptions>;
  jobSettings: JobSettings;
  sourcingLinks: Array<SourcingLink>;
};


export type JobJobStagesArgs = {
  input?: Maybe<JobStagesJobInput>;
};


export type JobPortalJobOptionsPreviewArgs = {
  input: JobPortalJobOptionsPreviewInput;
};

export enum JobHiringTeamRole {
  Coordinator = 'COORDINATOR',
  Sourcer = 'SOURCER',
  Recruiter = 'RECRUITER',
  HiringManager = 'HIRING_MANAGER'
}

export type JobImportValidateResult = {
  __typename?: 'JobImportValidateResult';
  targetJob: Job;
  jobStageMappings: Array<JobStageMapping>;
  scorecardMappings: Array<ScorecardMapping>;
  contentTabMappings: Array<ContentTabMapping>;
};

export type JobPortalJobOptionsPreviewInput = {
  jobStageId: Scalars['uuid'];
};

export type JobSettings = {
  __typename?: 'JobSettings';
  settingLevelId: Scalars['uuid'];
  settingLevel: SettingLevel;
  interviewNameSetting: InterviewNameSetting;
  interviewerInfoSetting: InterviewerInfoSetting;
  recruitingTeamContactInfoSetting: RecruitingTeamContactInfoSetting;
  defaultTaskQueue?: Maybe<TaskQueue>;
};

export type JobSettingsConfigImportInput = {
  targetJobId: Scalars['uuid'];
  jobSetupSettings?: Maybe<JobSetupSettingsInput>;
  jobStageMappings?: Maybe<Array<JobStageMappingInput>>;
  scorecardMappings?: Maybe<Array<ScorecardMappingInput>>;
  canPoSettings?: Maybe<CanPoSettings>;
};

export type JobSettingsImportInput = {
  sourceJobId: Scalars['uuid'];
  targetJobs: Array<JobSettingsConfigImportInput>;
};

export type JobSettingsImportResult = {
  __typename?: 'JobSettingsImportResult';
  sourceJobId: Scalars['uuid'];
  sourceJob: Job;
  targetJobIds: Array<Scalars['uuid']>;
  targetJobs: Array<Job>;
};

export type JobSettingsImportValidateInput = {
  sourceJobId: Scalars['uuid'];
  targetJobIds: Array<Scalars['uuid']>;
  stageImportConfigs: Array<JobStageSettingsImportConfig>;
  canPoImportConfig: CanPoSettingsImportConfig;
};

export type JobSettingsImportValidateResult = {
  __typename?: 'JobSettingsImportValidateResult';
  items: Array<JobImportValidateResult>;
  sourceJob: Job;
};

export type JobSettingsUpsertInput = {
  jobId: Scalars['uuid'];
  interviewNameSetting?: Maybe<InterviewNameSettingInput>;
  interviewerInfoSetting?: Maybe<InterviewerInfoSettingInput>;
  recruitingTeamContactInfoSetting?: Maybe<RecruitingTeamContactInfoSettingInput>;
  defaultTaskQueueId?: Maybe<Scalars['uuid']>;
};

export type JobSettingsUpsertResult = {
  __typename?: 'JobSettingsUpsertResult';
  jobSettings: JobSettings;
};

export type JobSetupSettingsInput = {
  importNewTaskDefault: Scalars['Boolean'];
  importPrivacy: Scalars['Boolean'];
};

export type JobStage = {
  __typename?: 'JobStage';
  id: Scalars['uuid'];
  name: Scalars['String'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  index: Scalars['Int'];
  jobId: Scalars['uuid'];
  atsId?: Maybe<Scalars['String']>;
  atsType?: Maybe<AtsType>;
  atsJobStage?: Maybe<AtsJobStage>;
  orgId: Scalars['uuid'];
  schedulable: Scalars['Boolean'];
  job?: Maybe<Job>;
  jobStageInterviewGroups?: Maybe<Array<JobStageInterviewGroup>>;
  jobStageSettings?: Maybe<JobStageSettings>;
  jobStageSettingsV2?: Maybe<JobStageSettingsV2>;
  schedulingWindow?: Maybe<Duration>;
  excludedEmployees?: Maybe<Array<Employee>>;
};


export type JobStageJobStageInterviewGroupsArgs = {
  orderBy?: Maybe<IndexOrderBy>;
};

/** Maps to JobStageSettingsV2.CandidateAvailabilitySettings */
export type JobStageCandidateAvailabilitySettings = {
  __typename?: 'JobStageCandidateAvailabilitySettings';
  numberOfDays?: Maybe<Scalars['numeric']>;
  minutesPerDays?: Maybe<Scalars['numeric']>;
  minimumTimeBlockMinutes?: Maybe<Scalars['numeric']>;
  advanceNoticeMinutes?: Maybe<Scalars['numeric']>;
  candidateNote?: Maybe<Scalars['String']>;
  ccRecipients?: Maybe<NotificationPreference>;
  bccRecipients?: Maybe<NotificationPreference>;
  shouldRespectLoadLimit?: Maybe<Scalars['Boolean']>;
  canScheduleOverRecruitingKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverAvailableKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverFreeTime?: Maybe<Scalars['Boolean']>;
  timeframeNumberOfDays?: Maybe<Scalars['numeric']>;
  useRollingDays?: Maybe<Scalars['Boolean']>;
};

export type JobStageInterview = {
  __typename?: 'JobStageInterview';
  atsInterviewDefinition?: Maybe<AtsInterviewDefinition>;
  atsInterviewDefinitionId?: Maybe<Scalars['String']>;
  atsType?: Maybe<AtsType>;
  customDuration?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  dynamicLinkTypes?: Maybe<Array<DynamicLinkType>>;
  forcedStartAt?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  index: Scalars['Int'];
  interviewType: InterviewType;
  isHiddenFromCandidate: Scalars['Boolean'];
  isLockedOrder: Scalars['Boolean'];
  jobStage: JobStage;
  jobStageId: Scalars['uuid'];
  jobStageInterviewGroupId?: Maybe<Scalars['uuid']>;
  jobStageInterviewSeats?: Maybe<Array<JobStageInterviewSeat>>;
  name?: Maybe<Scalars['String']>;
  schedulable: Scalars['Boolean'];
  staticLinks?: Maybe<Array<Scalars['String']>>;
  useAtsDuration: Scalars['Boolean'];
  useAtsName: Scalars['Boolean'];
};

export type JobStageInterviewGroup = {
  __typename?: 'JobStageInterviewGroup';
  id: Scalars['uuid'];
  index: Scalars['Int'];
  jobStageId: Scalars['uuid'];
  locked: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  atsId?: Maybe<Scalars['String']>;
  jobStageInterviews?: Maybe<Array<JobStageInterview>>;
};

export type JobStageInterviewGroupInput = {
  id: Scalars['uuid'];
  locked?: Maybe<Scalars['Boolean']>;
  jobStageId?: Maybe<Scalars['uuid']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  atsId?: Maybe<Scalars['String']>;
  jobStageInterviews?: Maybe<Array<Maybe<JobStageInterviewInput>>>;
};

export type JobStageInterviewInput = {
  id: Scalars['uuid'];
  jobStageId?: Maybe<Scalars['uuid']>;
  interviewType?: Maybe<InterviewType>;
  atsInterviewDefinitionId?: Maybe<Scalars['String']>;
  customDuration?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  isLockedOrder?: Maybe<Scalars['Boolean']>;
  useAtsName?: Maybe<Scalars['Boolean']>;
  useAtsDuration?: Maybe<Scalars['Boolean']>;
  staticLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
  dynamicLinkTypes?: Maybe<Array<Maybe<DynamicLinkType>>>;
  jobStageInterviewSeats?: Maybe<Array<Maybe<JobStageInterviewSeatInput>>>;
  jobStageInterviewGroupId?: Maybe<Scalars['uuid']>;
  isHiddenFromCandidate?: Maybe<Scalars['Boolean']>;
};

export type JobStageInterviewPlanResult = {
  __typename?: 'JobStageInterviewPlanResult';
  jobStage?: Maybe<JobStage>;
};

export type JobStageInterviewPlanUpsertInput = {
  customJobStage?: Maybe<CustomJobStageInput>;
  jobStageId?: Maybe<Scalars['uuid']>;
  groups: Array<JobStageInterviewGroupInput>;
  schedulingWindow?: Maybe<DurationInput>;
  excludedEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
};

export type JobStageInterviewSeat = {
  __typename?: 'JobStageInterviewSeat';
  id: Scalars['uuid'];
  type: JobStageInterviewSeatType;
  moduleSeat?: Maybe<ModuleSeat>;
  freeformSeat?: Maybe<FreeformSeat>;
  linkedSeat?: Maybe<LinkedSeat>;
  index?: Maybe<Scalars['Int']>;
};

export type JobStageInterviewSeatAttribute = {
  __typename?: 'JobStageInterviewSeatAttribute';
  id: Scalars['uuid'];
  jobStageInterviewSeatId?: Maybe<Scalars['uuid']>;
  attributeNameId?: Maybe<Scalars['uuid']>;
  attributeTagValueId?: Maybe<Scalars['uuid']>;
};

export type JobStageInterviewSeatAttributeInput = {
  id?: Maybe<Scalars['uuid']>;
  attributeNameId?: Maybe<Scalars['uuid']>;
  attributeTagValueId?: Maybe<Scalars['uuid']>;
  orgId?: Maybe<Scalars['uuid']>;
  jobStageInterviewSeatId?: Maybe<Scalars['uuid']>;
};

export type JobStageInterviewSeatEmployee = {
  __typename?: 'JobStageInterviewSeatEmployee';
  jobStageInterviewSeatId: Scalars['uuid'];
  employeeId: Scalars['uuid'];
  employee?: Maybe<Employee>;
  preferenceLevel?: Maybe<Scalars['Int']>;
};

export type JobStageInterviewSeatEmployeeInput = {
  employeeId: Scalars['uuid'];
  jobStageInterviewSeatId?: Maybe<Scalars['uuid']>;
  preferenceLevel?: Maybe<Scalars['Int']>;
};

export type JobStageInterviewSeatInput = {
  id: Scalars['uuid'];
  index?: Maybe<Scalars['Int']>;
  moduleSeat?: Maybe<ModuleSeatInput>;
  linkedSeat?: Maybe<LinkedSeatInput>;
  freeformSeat?: Maybe<FreeformSeatInput>;
  jobStageInterviewId?: Maybe<Scalars['uuid']>;
};

export enum JobStageInterviewSeatType {
  Freeform = 'FREEFORM',
  Module = 'MODULE',
  Linked = 'LINKED'
}

export type JobStageMapping = {
  __typename?: 'JobStageMapping';
  targetJobStageId?: Maybe<Scalars['uuid']>;
  sourceName: Scalars['String'];
  sourceJobStageId: Scalars['uuid'];
  sourceJobStage: JobStage;
};

export type JobStageMappingInput = {
  targetJobStageId: Scalars['uuid'];
  sourceJobStageId: Scalars['uuid'];
  importInterviewPlan: Scalars['Boolean'];
  importInternalEvents: Scalars['Boolean'];
  importCandidateComms: Scalars['Boolean'];
  importAvailabilitySettings: Scalars['Boolean'];
  importSelfScheduleSettings: Scalars['Boolean'];
  importSlackChannel: Scalars['Boolean'];
};

/** Maps to JobStageSettingsV2.SelfScheduleSettings */
export type JobStageSelfScheduleSettings = {
  __typename?: 'JobStageSelfScheduleSettings';
  advanceNoticeMinutes?: Maybe<Scalars['numeric']>;
  candidateCalendarId?: Maybe<SettingCalendarId>;
  interviewerCalendarId?: Maybe<SettingCalendarId>;
  location?: Maybe<SelfScheduleLocation>;
  zoomHost?: Maybe<SelfScheduleZoomHost>;
  isPrivateCalendarEvent?: Maybe<SettingBoolValue>;
  zoomHostUserId?: Maybe<Scalars['String']>;
  customLocation?: Maybe<Scalars['String']>;
  shouldRespectLoadLimit?: Maybe<Scalars['Boolean']>;
  canScheduleOverRecruitingKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverAvailableKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverFreeTime?: Maybe<Scalars['Boolean']>;
  candidateNote?: Maybe<Scalars['String']>;
  timeframeNumberOfDays?: Maybe<Scalars['numeric']>;
  ccRecipients?: Maybe<NotificationPreference>;
  bccRecipients?: Maybe<NotificationPreference>;
  useRollingDays?: Maybe<Scalars['Boolean']>;
  videoMeetingHostEmployeeId?: Maybe<Scalars['uuid']>;
  meetingHost?: Maybe<SelfScheduleMeetingHost>;
};

export type JobStageSettings = {
  __typename?: 'JobStageSettings';
  id: Scalars['uuid'];
  jobStageId: Scalars['uuid'];
  candidateEmailTemplateId?: Maybe<Scalars['uuid']>;
  candidateEmailTemplate?: Maybe<Template>;
  candidateEventTemplateId?: Maybe<Scalars['uuid']>;
  candidateEventTemplate?: Maybe<Template>;
  slackTemplateId?: Maybe<Scalars['uuid']>;
  slackTemplate?: Maybe<Template>;
  interviewerEventTemplateId?: Maybe<Scalars['uuid']>;
  interviewerEventTemplate?: Maybe<Template>;
  interviewerCalendarId?: Maybe<Scalars['String']>;
  candidateCalendarId?: Maybe<Scalars['String']>;
  privateCalendarEvents?: Maybe<Scalars['Boolean']>;
  toRecipients?: Maybe<NotificationPreference>;
  ccRecipients?: Maybe<NotificationPreference>;
  bccRecipients?: Maybe<NotificationPreference>;
  candidateAvailabilityOptions?: Maybe<CandidateAvailabilityOptionsSettings>;
  selfScheduleOptions?: Maybe<SelfScheduleOptionSettings>;
  selfScheduleRequestEmailTemplateId?: Maybe<Scalars['uuid']>;
  selfScheduleCandidateEmailTemplateId?: Maybe<Scalars['uuid']>;
  selfScheduleInterviewerEventTemplateId?: Maybe<Scalars['uuid']>;
  availabilityRequestEmailTemplateId?: Maybe<Scalars['uuid']>;
  interviewMeetingLocationSetting?: Maybe<InterviewMeetingLocationSettingPref>;
};

export type JobStageSettingsImportConfig = {
  jobStageId: Scalars['uuid'];
  importInterviewPlan: Scalars['Boolean'];
};

export type JobStageSettingsUpsertInput = {
  jobStageId: Scalars['uuid'];
  candidateEmailTemplateId?: Maybe<Scalars['uuid']>;
  /**  this is used for self/schedule request */
  candidateEventTemplateId?: Maybe<Scalars['uuid']>;
  /**  same */
  slackTemplateId?: Maybe<Scalars['uuid']>;
  /**  V2 attributes */
  interviewerEventTemplateId?: Maybe<Scalars['uuid']>;
  interviewerCalendarId?: Maybe<Scalars['String']>;
  automaticallyReplaceDeclinedInterviewer?: Maybe<Scalars['Boolean']>;
  candidateCalendarId?: Maybe<Scalars['String']>;
  privateCalendarEvents?: Maybe<Scalars['Boolean']>;
  toRecipients?: Maybe<NotificationPreferenceInput>;
  ccRecipients?: Maybe<NotificationPreferenceInput>;
  bccRecipients?: Maybe<NotificationPreferenceInput>;
  candidateAvailabilityOptions?: Maybe<CandidateAvailabilityOptionsSettingInput>;
  selfScheduleOptions?: Maybe<SelfScheduleOptionsSettingInput>;
  selfScheduleRequestEmailTemplateId?: Maybe<Scalars['uuid']>;
  selfScheduleCandidateEmailTemplateId?: Maybe<Scalars['uuid']>;
  selfScheduleInterviewerEventTemplateId?: Maybe<Scalars['uuid']>;
  availabilityRequestEmailTemplateId?: Maybe<Scalars['uuid']>;
  interviewMeetingLocationSetting?: Maybe<InterviewMeetingLocationSettingsInput>;
};

export type JobStageSettingsUpsertResult = {
  __typename?: 'JobStageSettingsUpsertResult';
  jobStageSettings?: Maybe<JobStageSettings>;
  jobStageSettingsV2?: Maybe<JobStageSettingsV2>;
};

export type JobStageSettingsV2 = {
  __typename?: 'JobStageSettingsV2';
  id: Scalars['uuid'];
  jobStageId: Scalars['uuid'];
  candidateEmailTemplateId?: Maybe<SettingTemplateId>;
  candidateEventTemplateId?: Maybe<SettingTemplateId>;
  slackTemplateId?: Maybe<SettingTemplateId>;
  interviewerEventTemplateId?: Maybe<SettingTemplateId>;
  selfScheduleRequestEmailTemplateId?: Maybe<SettingTemplateId>;
  selfScheduleCandidateEmailTemplateId?: Maybe<SettingTemplateId>;
  selfScheduleInterviewerEventTemplateId?: Maybe<SettingTemplateId>;
  availabilityRequestEmailTemplateId?: Maybe<SettingTemplateId>;
  interviewerCalendarId?: Maybe<SettingCalendarId>;
  candidateCalendarId?: Maybe<SettingCalendarId>;
  isPrivateCalendarEvent: SettingBoolValue;
  automaticallyReplaceDeclinedInterviewer: SettingBoolValue;
  toRecipients?: Maybe<NotificationPreference>;
  ccRecipients?: Maybe<NotificationPreference>;
  bccRecipients?: Maybe<NotificationPreference>;
  candidateAvailabilitySettings?: Maybe<JobStageCandidateAvailabilitySettings>;
  selfScheduleSettings?: Maybe<JobStageSelfScheduleSettings>;
  interviewMeetingLocationSettingWithLevel?: Maybe<InterviewMeetingLocationSettingWithLevel>;
};

export type JobStagesByNameOutput = {
  __typename?: 'JobStagesByNameOutput';
  items: Array<Maybe<Scalars['String']>>;
  nextCursor?: Maybe<Scalars['String']>;
};

/**
 * ##########################################################
 *  Input and output for job stages
 */
export type JobStagesInput = {
  jobId?: Maybe<Scalars['uuid']>;
  /** optional */
  pageInput: PageInput;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<JobsOrderBy>;
  hasActiveApplications?: Maybe<Scalars['Boolean']>;
  isSchedulable?: Maybe<Scalars['Boolean']>;
};

export type JobStagesJobInput = {
  /**  If not set, will be set to false, meaning only original job stages will be included */
  includeCustom?: Maybe<Scalars['Boolean']>;
};

export type JobStagesOrderBy = {
  property: JobStagesOrderByProperty;
  direction: Sort;
};

export enum JobStagesOrderByProperty {
  Name = 'NAME'
}

export type JobStagesOutput = {
  __typename?: 'JobStagesOutput';
  items: Array<JobStage>;
  nextCursor?: Maybe<Scalars['String']>;
};

export enum JobStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export type JobUpdateInput = {
  jobId: Scalars['uuid'];
  fallbackName?: Maybe<Scalars['String']>;
};

export type JobUpdateResult = {
  __typename?: 'JobUpdateResult';
  job: Job;
};

/**
 * ##########################################################
 * ##########################################################
 *  Input and output for jobs
 */
export type JobsInput = {
  pageInput: PageInput;
  search?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<JobStatus>>>;
  locations?: Maybe<Array<Scalars['String']>>;
  orderBy?: Maybe<JobsOrderBy>;
  hasActiveApplications?: Maybe<Scalars['Boolean']>;
};

/**  How to order by jobs? */
export type JobsOrderBy = {
  property: JobsOrderByProperty;
  direction: Sort;
};

export enum JobsOrderByProperty {
  Name = 'NAME'
}

export type JobsOutput = {
  __typename?: 'JobsOutput';
  items: Array<Job>;
  nextCursor?: Maybe<Scalars['String']>;
};

export enum JsonArrayEmployeePref {
  ArrMyTasksDashboardLayout = 'arr_my_tasks_dashboard_layout',
  ArrAllTasksDashboardLayout = 'arr_all_tasks_dashboard_layout'
}

export type JsonArrayEmployeePrefValue = EmployeePrefValue & {
  __typename?: 'JsonArrayEmployeePrefValue';
  id: Scalars['String'];
  prefName: Scalars['String'];
  level: PrefLevel;
  value?: Maybe<Array<EmployeeJsonPref>>;
};

export enum JsonEmployeePref {
  ObjDailyInterviewLimit = 'obj_daily_interview_limit',
  ObjWeeklyInterviewLimit = 'obj_weekly_interview_limit',
  ObjMyTasksDashboardAssignedToMeFilters = 'obj_my_tasks_dashboard_assigned_to_me_filters',
  ObjMyTasksDashboardCreatedByMeFilters = 'obj_my_tasks_dashboard_created_by_me_filters',
  ObjMyTasksDashboardSubscribedByMeFilters = 'obj_my_tasks_dashboard_subscribed_by_me_filters',
  ObjMyTasksDashboardMyQueuesFilters = 'obj_my_tasks_dashboard_my_queues_filters',
  ObjAllTasksDashboardFilters = 'obj_all_tasks_dashboard_filters',
  ObjCandidatePageFilters = 'obj_candidate_page_filters',
  ObjMetricsPageRecruitingTeamFilters = 'obj_metrics_page_recruiting_team_filters',
  ObjMetricsPageInterviewerFilters = 'obj_metrics_page_interviewer_filters',
  ObjDirectoryPageFilters = 'obj_directory_page_filters',
  ObjUpcomingEventsPageFilters = 'obj_upcoming_events_page_filters',
  ObjRecentlyUsedZoomUsers = 'obj_recently_used_zoom_users',
  ObjInterviewBufferTime = 'obj_interview_buffer_time',
  ObjProfilePicture = 'obj_profile_picture'
}

export type JsonEmployeePrefValue = EmployeePrefValue & {
  __typename?: 'JsonEmployeePrefValue';
  id: Scalars['String'];
  prefName: Scalars['String'];
  level: PrefLevel;
  value?: Maybe<EmployeeJsonPref>;
};

export enum JsonOrgPref {
  ObjAddZoomHostAsInternalInvitee = 'obj_add_zoom_host_as_internal_invitee',
  ObjDailyInterviewLimit = 'obj_daily_interview_limit',
  ObjWeeklyInterviewLimit = 'obj_weekly_interview_limit',
  ObjDefaultWorkHours = 'obj_default_work_hours',
  ObjCustomInterviewScheduleToken = 'obj_custom_interview_schedule_token',
  ObjTrainingModuleGraduationNotification = 'obj_training_module_graduation_notification',
  ObjInterviewMeetingLocationSetting = 'obj_interview_meeting_location_setting',
  ObjDebriefMeetingLocationSetting = 'obj_debrief_meeting_location_setting',
  ObjCandidateFacingInterviewerInfo = 'obj_candidate_facing_interviewer_info',
  ObjCandidateFacingHiringTeamContactInfo = 'obj_candidate_facing_hiring_team_contact_info',
  ObjPremiumCandidatePortal = 'obj_premium_candidate_portal',
  ObjMinimumDailyInterviewerLoadLimit = 'obj_minimum_daily_interviewer_load_limit',
  ObjMinimumWeeklyInterviewerLoadLimit = 'obj_minimum_weekly_interviewer_load_limit',
  ObjInterviewBufferTime = 'obj_interview_buffer_time',
  ObjCandidateCommunicationsSetting = 'obj_candidate_communications_setting',
  ObjArAdditionalRecipients = 'obj_ar_additional_recipients',
  ObjSsrAdditionalRecipients = 'obj_ssr_additional_recipients'
}

export type LeverCandidate = {
  __typename?: 'LeverCandidate';
  origin?: Maybe<Scalars['String']>;
  leverOpportunityUrl: Scalars['String'];
};

export type LeverIntegration = {
  __typename?: 'LeverIntegration';
  active: Scalars['Boolean'];
};

export type LeverIntegrationHealth = {
  __typename?: 'LeverIntegrationHealth';
  isInstalled: Scalars['Boolean'];
  isHealthy: Scalars['Boolean'];
  canReadUsers: Scalars['Boolean'];
  canReadJobs: Scalars['Boolean'];
  canReadCandidates: Scalars['Boolean'];
  canReadApplications: Scalars['Boolean'];
  canReadArchiveReasons: Scalars['Boolean'];
  canReadPanels: Scalars['Boolean'];
  canReadStages: Scalars['Boolean'];
  canReadDeletedCandidates: Scalars['Boolean'];
};

export type LeverInterviewDefinition = {
  __typename?: 'LeverInterviewDefinition';
  groupId?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
};

export type LinkUnfurlInput = {
  url: Scalars['String'];
};

export type LinkUnfurlResult = {
  __typename?: 'LinkUnfurlResult';
  url: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  favicon?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type LinkedCard = {
  __typename?: 'LinkedCard';
  url: Scalars['String'];
  header: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
};

export type LinkedCardInput = {
  url: Scalars['String'];
  header: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  previewImageUrl?: Maybe<Scalars['String']>;
};

export type LinkedSeat = {
  __typename?: 'LinkedSeat';
  linkedJobStageInterviewSeatId: Scalars['uuid'];
};

export type LinkedSeatInput = {
  linkedJobStageInterviewSeatId: Scalars['uuid'];
};

export type LoadAndLimit = {
  __typename?: 'LoadAndLimit';
  dailyLoad?: Maybe<Scalars['Int']>;
  weeklyLoad?: Maybe<Scalars['Int']>;
  lastWeeklyLoad?: Maybe<Scalars['Int']>;
  dailyLoadMinutes?: Maybe<Scalars['Int']>;
  weeklyLoadMinutes?: Maybe<Scalars['Int']>;
  lastWeeklyLoadMinutes?: Maybe<Scalars['Int']>;
  dailyInterviewLimit?: Maybe<InterviewLimit>;
  weeklyInterviewLimit?: Maybe<InterviewLimit>;
};

export type LoadAndLimitInput = {
  date: Scalars['date'];
  timezone: Scalars['String'];
};

export type LocalDateBlock = {
  __typename?: 'LocalDateBlock';
  start: Scalars['date'];
  end: Scalars['date'];
};

export type LocalTimeBlock = {
  __typename?: 'LocalTimeBlock';
  start: Scalars['localtime'];
  end: Scalars['localtime'];
};

export type LocalTimeBlockInput = {
  start: Scalars['localtime'];
  end: Scalars['localtime'];
};

/** ######################################################## */
export type ManagersInput = {
  pageInput: PageInput;
  isDirectoryDisabled?: Maybe<Scalars['Boolean']>;
  hasAtsId?: Maybe<Scalars['Boolean']>;
  isAtsDisabled?: Maybe<Scalars['Boolean']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  /**  Exclude employees which are already added as user of modernloop. */
  excludeUsers?: Maybe<Scalars['Boolean']>;
};

export enum MappingType {
  OneToOne = 'ONE_TO_ONE',
  OneToMany = 'ONE_TO_MANY'
}

export type MeetingLinkFormat = {
  zoomLinkFormat?: Maybe<Scalars['String']>;
  workspaceVideoLinkFormat?: Maybe<Scalars['String']>;
  customLinkFormat?: Maybe<Scalars['String']>;
};

export type MeetingLocation = {
  __typename?: 'MeetingLocation';
  id: Scalars['uuid'];
  type: InterviewMeetingLocationType;
  hostEmployee?: Maybe<Employee>;
  remoteVideoMeetingHostUser?: Maybe<RemoteVideoMeetingHostUser>;
  videoMeetingLink?: Maybe<Scalars['String']>;
  details?: Maybe<MeetingLocationDetails>;
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  canceledAt?: Maybe<Scalars['datetime']>;
};

export type MeetingLocationCreateInput = {
  locationType: InterviewMeetingLocationType;
  /** Either of videoMeeting or customLocation must be set */
  videoMeeting?: Maybe<VideoMeetingCreateInput>;
  customLocation?: Maybe<Scalars['String']>;
};

export type MeetingLocationCreateResult = {
  __typename?: 'MeetingLocationCreateResult';
  meetingLocation?: Maybe<MeetingLocation>;
};

export type MeetingLocationDetails = {
  __typename?: 'MeetingLocationDetails';
  customLocation?: Maybe<Scalars['String']>;
  zoomInfo?: Maybe<ZoomInfo>;
};

export type MeetingRoom = {
  __typename?: 'MeetingRoom';
  id: Scalars['String'];
  orgId: Scalars['String'];
  remoteId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  buildingRemoteId?: Maybe<Scalars['String']>;
  floorName?: Maybe<Scalars['String']>;
  features?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
};

export type MeetingRoomOrderBy = {
  property: MeetingRoomOrderByProperty;
  direction: Sort;
};

export enum MeetingRoomOrderByProperty {
  Description = 'DESCRIPTION'
}

/**
 * ##########################################################
 *  In future we will add calendar events to this type.
 */
export type MeetingRoomSuggestion = {
  __typename?: 'MeetingRoomSuggestion';
  room?: Maybe<MeetingRoom>;
  isBusy?: Maybe<Scalars['Boolean']>;
  busyTimeRanges?: Maybe<Array<DateTimeRangeOutput>>;
};

/**
 * ##########################################################
 *  Input and output for meetingRoomSuggestions
 */
export type MeetingRoomSuggestionsInput = {
  pageInput: PageInput;
  search?: Maybe<Scalars['String']>;
  minCapacity?: Maybe<Scalars['Int']>;
  maxCapacity?: Maybe<Scalars['Int']>;
  timeRange: DateTimeRangeInput;
  orderBy?: Maybe<MeetingRoomOrderBy>;
};

export type MeetingRoomSuggestionsOutput = {
  __typename?: 'MeetingRoomSuggestionsOutput';
  items: Array<MeetingRoomSuggestion>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type MetricsChartDownloadResult = {
  __typename?: 'MetricsChartDownloadResult';
  data?: Maybe<Array<InterviewMetrics>>;
  scheduleStages?: Maybe<Array<ScheduleStageMetrics>>;
};

export type MetricsPageInterviewerFiltersPref = {
  __typename?: 'MetricsPageInterviewerFiltersPref';
  interviewerEmployeeIds: Array<Scalars['uuid']>;
  interviewerEmployees: Array<Employee>;
  managerEmployeeIds: Array<Scalars['uuid']>;
  managerEmployees: Array<Employee>;
  jobIds: Array<Scalars['uuid']>;
  jobs: Array<Job>;
  interviewModuleIds: Array<Scalars['uuid']>;
  interviewModules: Array<InterviewModule>;
  departmentAttributeIds: Array<Scalars['uuid']>;
  teamAttributeIds: Array<Scalars['uuid']>;
  locationAttributeIds: Array<Scalars['uuid']>;
  levelAttributeIds: Array<Scalars['uuid']>;
  customAttributeIds: Array<Scalars['uuid']>;
  countryAttributeIds: Array<Scalars['uuid']>;
  showArchived: Scalars['Boolean'];
};

export type MetricsPageRecruitingTeamFiltersPref = {
  __typename?: 'MetricsPageRecruitingTeamFiltersPref';
  schedulerEmployeeIds: Array<Scalars['uuid']>;
  schedulerEmployees: Array<Employee>;
  jobIds: Array<Scalars['uuid']>;
  jobs: Array<Job>;
  jobStageNames: Array<Scalars['String']>;
  showArchived: Scalars['Boolean'];
};

/**  This type is deprecated and will be removed in the future. Use MicrosoftGccHighEmployeeIntegration instead. */
export type MicrosoftEmployeeGccHighIntegration = {
  __typename?: 'MicrosoftEmployeeGccHighIntegration';
  active: Scalars['Boolean'];
};

export type MicrosoftEmployeeIntegration = {
  __typename?: 'MicrosoftEmployeeIntegration';
  active: Scalars['Boolean'];
};

export type MicrosoftGccHighEmployeeIntegration = {
  __typename?: 'MicrosoftGccHighEmployeeIntegration';
  active: Scalars['Boolean'];
};

export type MicrosoftGccHighIntegration = {
  __typename?: 'MicrosoftGccHighIntegration';
  active: Scalars['Boolean'];
};

export type MicrosoftIntegration = {
  __typename?: 'MicrosoftIntegration';
  active: Scalars['Boolean'];
};

export type MicrosoftIntegrationHealth = {
  __typename?: 'MicrosoftIntegrationHealth';
  isInstalled: Scalars['Boolean'];
  isHealthy: Scalars['Boolean'];
  canReadDirectory: Scalars['Boolean'];
  canReadMeetingRooms: Scalars['Boolean'];
  canReadCalendars: Scalars['Boolean'];
};

export type MinimumInterviewerLoadLimitOrgPref = {
  __typename?: 'MinimumInterviewerLoadLimitOrgPref';
  interviews?: Maybe<Scalars['Int']>;
  minutes?: Maybe<Scalars['Int']>;
};

export type ModuleMemberApplicationStageInterviewLog = ModuleMemberInterview & {
  __typename?: 'ModuleMemberApplicationStageInterviewLog';
  id: Scalars['uuid'];
  /**  This is applicationStageInterviewId */
  interviewDate: Scalars['datetime'];
  trainingRole: TrainingStatus;
  status: ModuleMemberInterviewStatus;
  /**  SCHEDULED, Didn't Count, COMPLETED */
  applicationStageInterview: ApplicationStageInterview;
};

export type ModuleMemberInterview = {
  id: Scalars['uuid'];
  interviewDate: Scalars['datetime'];
  trainingRole: TrainingStatus;
  status: ModuleMemberInterviewStatus;
};

export type ModuleMemberInterviewLogInput = {
  pageInput: PageInput;
  trainingRole?: Maybe<TrainingStatus>;
  orderBy?: Maybe<ModuleMemberInterviewLogOrderBy>;
};

export type ModuleMemberInterviewLogOrderBy = {
  direction: Sort;
};

export type ModuleMemberInterviewLogOutput = {
  __typename?: 'ModuleMemberInterviewLogOutput';
  items: Array<ModuleMemberInterview>;
  nextCursor: Scalars['String'];
  prevCursor: Scalars['String'];
};

export enum ModuleMemberInterviewStatus {
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED',
  DidntCount = 'DIDNT_COUNT',
  Cancelled = 'CANCELLED'
}

/** ## Extend above interface */
export type ModuleMemberManualLog = ModuleMemberInterview & {
  __typename?: 'ModuleMemberManualLog';
  id: Scalars['uuid'];
  /**  This is ModuleMemberManualLogId */
  interviewDate: Scalars['datetime'];
  trainingRole: TrainingStatus;
  status: ModuleMemberInterviewStatus;
};

export type ModuleSeat = {
  __typename?: 'ModuleSeat';
  interviewModuleId: Scalars['uuid'];
  interviewModule?: Maybe<InterviewModule>;
  jobStageInterviewSeatAttributes?: Maybe<Array<JobStageInterviewSeatAttribute>>;
  selectedEmployeeIds: Array<Scalars['uuid']>;
};

export type ModuleSeatInput = {
  interviewModuleId: Scalars['uuid'];
  jobStageInterviewSeatAttributes?: Maybe<Array<JobStageInterviewSeatAttributeInput>>;
  selectedEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** # Admin apis */
  orgUpdate?: Maybe<Org>;
  portalSlugCreate?: Maybe<PortalOrgSlug>;
  userUpdate?: Maybe<UserUpdateResult>;
  atsUserEmployeeAssociationUpdate?: Maybe<AtsUserEmployeeAssociationUpdateResult>;
  employeeAttributeNameCreate?: Maybe<EmployeeAttributeName>;
  employeeAttributeValueCreate?: Maybe<Array<EmployeeAttributeTagValue>>;
  employeeAttributeNameUpdate?: Maybe<EmployeeAttributeName>;
  employeeAttributeValueUpdate?: Maybe<Array<EmployeeAttributeTagValue>>;
  employeeAttributeNameDelete?: Maybe<Id>;
  employeeAttributeValueDelete?: Maybe<Id>;
  employeeAttributeBulkUpsert?: Maybe<EmptyResult>;
  employeeDataBulkUpsert?: Maybe<EmptyResult>;
  /** activity */
  activityNoteCreate?: Maybe<ActivityNoteCreateResult>;
  activityNoteEdit?: Maybe<ActivityNoteEditResult>;
  activityNoteDelete?: Maybe<ActivityNoteDeleteResult>;
  /** Company country holidays */
  companyHolidayCreate?: Maybe<CompanyHolidayUpsertResult>;
  companyHolidayUpdate?: Maybe<CompanyHolidayUpsertResult>;
  companyHolidayDelete?: Maybe<CompanyHolidayDeleteResult>;
  employeeAttributesUpsert?: Maybe<EmployeeUpdateResult>;
  attributeValueEmployeesUpsert?: Maybe<Array<EmployeeUpdateResult>>;
  orgPrefUpdate?: Maybe<OrgPrefUpdateResult>;
  employeePrefUpdate?: Maybe<EmployeePrefUpdateResult>;
  employeeNotificationPrefUpdate?: Maybe<EmployeeNotificationPrefUpdateResult>;
  employeeUpdate?: Maybe<EmployeeUpdateResult>;
  /**  Scheduler apis. */
  candidateAvailabilityOptionsUpsert?: Maybe<CandidateAvailabilityOptionsUpsertResult>;
  candidateAvailabilityOptionsDelete?: Maybe<EmptyResult>;
  /** @deprecated Use applicationStageInterviewInterviewerUpdate instead */
  interviewAdjustRsvp?: Maybe<InterviewAdjustRsvpResult>;
  applicationStageInterviewInterviewerUpsert?: Maybe<ApplicationStageInterviewInterviewerResult>;
  applicationStageInterviewInterviewerUpdate?: Maybe<ApplicationStageInterviewInterviewerResult>;
  interviewModuleCreate?: Maybe<InterviewModuleResult>;
  interviewModuleUpdate?: Maybe<InterviewModuleResult>;
  interviewModuleDelete?: Maybe<InterviewModuleResult>;
  interviewModuleAddMembers?: Maybe<InterviewModuleResult>;
  interviewModuleRemoveMembers?: Maybe<InterviewModuleResult>;
  interviewModuleMemberUpdate?: Maybe<InterviewModuleMemberResult>;
  interviewModuleMemberMoveStage?: Maybe<InterviewModuleMemberResult>;
  interviewModuleMemberApprove?: Maybe<InterviewModuleMemberResult>;
  interviewModuleMemberLogCreate?: Maybe<InterviewModuleMemberManualLogResult>;
  interviewModuleMemberLogUpdate?: Maybe<InterviewModuleMemberManualLogResult>;
  interviewModuleMemberLogDelete?: Maybe<EmptyResult>;
  customJobStageInterviewPlanDelete?: Maybe<CustomJobStageInterviewPlanResult>;
  jobStageInterviewPlanUpsert?: Maybe<JobStageInterviewPlanResult>;
  resetJobStageToAtsInterviewPlan?: Maybe<JobStageInterviewPlanResult>;
  trainingFeedbackReminderNag?: Maybe<InterviewModuleMemberResult>;
  jobUpdate?: Maybe<JobUpdateResult>;
  jobStageSettingsUpsert?: Maybe<JobStageSettingsUpsertResult>;
  jobSettingsUpsert?: Maybe<JobSettingsUpsertResult>;
  jobSettingsImport?: Maybe<JobSettingsImportResult>;
  templateCreate?: Maybe<TemplateResult>;
  templateUpdate?: Maybe<TemplateResult>;
  templateDelete?: Maybe<TemplateResult>;
  calendarMeetingCreate?: Maybe<GoogleMeetingResult>;
  zoomMeetingUpdate?: Maybe<ZoomMeetingResult>;
  zoomMeetingCreate?: Maybe<ZoomMeetingCreateResult>;
  meetingLocationCreate?: Maybe<MeetingLocationCreateResult>;
  shortUrlCreate?: Maybe<ShortUrlCreateResult>;
  selfScheduleOptionsUpsert?: Maybe<SelfScheduleOptions>;
  selfScheduleOptionsDelete?: Maybe<SelfScheduleOptionsDeleteInput>;
  scheduleCancel?: Maybe<EmptyResult>;
  scheduleCreate?: Maybe<ScheduleCreateResult>;
  scheduleReschedule?: Maybe<ScheduleRescheduleResult>;
  /** task queue */
  taskQueueCreate?: Maybe<TaskQueueCreateOutput>;
  taskQueueUpdate?: Maybe<TaskQueueUpdateOutput>;
  taskQueueDelete?: Maybe<TaskQueueDeleteResult>;
  /**  task tag */
  taskTagCreate?: Maybe<TaskTagCreateOutput>;
  taskTagUpdate?: Maybe<TaskTagUpdateOutput>;
  taskTagDelete?: Maybe<TaskTagDeleteOutput>;
  /**  task */
  taskCreate?: Maybe<TaskCreateResult>;
  /** Set the tags on a task. All tags must be sent, this is a full replace. */
  taskSetTags: Task;
  /**
   * Cancels a task.
   * If there is a self-schedule request it is closed.
   * If there is an availability request it is closed.
   * If there is a schedule it is canceled.
   * If there is a debrief it is canceled.
   */
  taskCancel: Task;
  /** Set whether or not a debrief will be required for the task to be considered complete. */
  taskSetDebriefRequired: Task;
  /** Sets the task as urgent or not. */
  taskSetIsUrgent: Task;
  /** Sets or unsets the assignee. */
  taskSetAssignee: Task;
  /** Sets or unsets the task queue. */
  taskSetTaskQueue: Task;
  taskRequestChange: TaskRequestChangeResult;
  /**
   * Updates fields on a task.
   *
   * IMPORTANT: Only one field can be set at a time.
   * @deprecated Use field specific mutations instead
   */
  taskUpdate: Task;
  taskFlagMarkResolved: TaskFlagMarkResolvedResult;
  /**  reschedule log and reasons */
  rescheduleReasonCreate?: Maybe<RescheduleReasonUpsertResult>;
  rescheduleReasonUpdate?: Maybe<RescheduleReasonUpsertResult>;
  rescheduleLogCreate?: Maybe<RescheduleLogResult>;
  rescheduleLogUpdate?: Maybe<RescheduleLogResult>;
  rescheduleLogDelete?: Maybe<EmptyResult>;
  /**  subscription */
  subscriberAdd?: Maybe<SubscriberAddResult>;
  subscriberRemove?: Maybe<SubscriberRemoveResult>;
  /**  candidate */
  candidateUpdate: CandidateUpdateResult;
  /**  application */
  applicationUpdate: Application;
  /** candidate portal note */
  applicationCandidatePortalNoteCreate: CandidatePortalNote;
  applicationCandidatePortalNoteUpdate: CandidatePortalNote;
  applicationCandidatePortalNoteDelete: EmptyResult;
  brandingThemeCreate: BrandingThemeResult;
  brandingThemeUpdate: BrandingThemeResult;
  brandingThemeDelete: EmptyResult;
  portalJobOptionsDelete: EmptyResult;
  portalJobOptionsUpsert: PortalJobOptionsResult;
  portalTemplateCreate: PortalTemplateResult;
  portalTemplateUpdate: PortalTemplateResult;
  portalTemplateDelete: EmptyResult;
  employeePlannedAvailabilityCreate: EmployeePlannedAvailabilityResult;
  employeePlannedAvailabilityUpdate: EmployeePlannedAvailabilityResult;
  employeePlannedAvailabilityDelete: EmptyResult;
  sourcingLinkCreate: SourcingLinkResult;
  sourcingLinkUpdate: SourcingLinkResult;
  sourcingLinkDelete: EmptyResult;
  checkCreateCandidatePermission: CheckCreateCandidatePermissionResult;
  interviewerPortalSettingsUpsert: InterviewerPortalSettings;
  /** Set max concurrent meetings for zoom hosts */
  zoomUserConcurrentMeetingsPut: EmptyResult;
  /** Update max concurrent meetings for zoom hosts */
  zoomUserConcurrentMeetingsUpdate: EmptyResult;
  /** Delete max concurrent meetings entry for zoom hosts */
  zoomUserConcurrentMeetingsDelete: EmptyResult;
};


export type MutationOrgUpdateArgs = {
  input: OrgUpdateInput;
};


export type MutationPortalSlugCreateArgs = {
  input: PortalSlugCreateInput;
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};


export type MutationAtsUserEmployeeAssociationUpdateArgs = {
  input: AtsUserEmployeeAssociationUpdateInput;
};


export type MutationEmployeeAttributeNameCreateArgs = {
  input: EmployeeAttributeNameCreateInput;
};


export type MutationEmployeeAttributeValueCreateArgs = {
  input: EmployeeAttributeValueCreateInput;
};


export type MutationEmployeeAttributeNameUpdateArgs = {
  input: EmployeeAttributeNameUpdateInput;
};


export type MutationEmployeeAttributeValueUpdateArgs = {
  input: EmployeeAttributeValueUpdateInput;
};


export type MutationEmployeeAttributeNameDeleteArgs = {
  input: EmployeeAttributeNameDeleteInput;
};


export type MutationEmployeeAttributeValueDeleteArgs = {
  input: EmployeeAttributeValueDeleteInput;
};


export type MutationEmployeeAttributeBulkUpsertArgs = {
  input: EmployeeAttributeBulkUpsertInput;
};


export type MutationEmployeeDataBulkUpsertArgs = {
  input: EmployeeDataBulkUpsertInput;
};


export type MutationActivityNoteCreateArgs = {
  input: ActivityNoteCreateInput;
};


export type MutationActivityNoteEditArgs = {
  input: ActivityNoteEditInput;
};


export type MutationActivityNoteDeleteArgs = {
  input: ActivityNoteDeleteInput;
};


export type MutationCompanyHolidayCreateArgs = {
  input: CompanyHolidayCreateInput;
};


export type MutationCompanyHolidayUpdateArgs = {
  input: CompanyHolidayUpdateInput;
};


export type MutationCompanyHolidayDeleteArgs = {
  input: CompanyHolidayDeleteInput;
};


export type MutationEmployeeAttributesUpsertArgs = {
  input: EmployeeAttributesInput;
};


export type MutationAttributeValueEmployeesUpsertArgs = {
  input: AttributeValueEmployeesInput;
};


export type MutationOrgPrefUpdateArgs = {
  input: OrgPrefUpdateInput;
};


export type MutationEmployeePrefUpdateArgs = {
  input: EmployeePrefUpdateInput;
};


export type MutationEmployeeNotificationPrefUpdateArgs = {
  input: EmployeeNotificationPrefUpdateInput;
};


export type MutationEmployeeUpdateArgs = {
  input: EmployeeUpdateInput;
};


export type MutationCandidateAvailabilityOptionsUpsertArgs = {
  input: CandidateAvailabilityOptionsUpsertInput;
};


export type MutationCandidateAvailabilityOptionsDeleteArgs = {
  id: Scalars['uuid'];
};


export type MutationInterviewAdjustRsvpArgs = {
  input: InterviewAdjustRsvpInput;
};


export type MutationApplicationStageInterviewInterviewerUpsertArgs = {
  input: ApplicationStageInterviewInterviewerUpsertInput;
};


export type MutationApplicationStageInterviewInterviewerUpdateArgs = {
  input: ApplicationStageInterviewInterviewerUpdateInput;
};


export type MutationInterviewModuleCreateArgs = {
  input: InterviewModuleCreateInput;
};


export type MutationInterviewModuleUpdateArgs = {
  input: InterviewModuleUpdateInput;
};


export type MutationInterviewModuleDeleteArgs = {
  id: Scalars['uuid'];
};


export type MutationInterviewModuleAddMembersArgs = {
  input: InterviewModuleAddMembersInput;
};


export type MutationInterviewModuleRemoveMembersArgs = {
  input: InterviewModuleRemoveMembersInput;
};


export type MutationInterviewModuleMemberUpdateArgs = {
  input: InterviewModuleMemberUpdateInput;
};


export type MutationInterviewModuleMemberMoveStageArgs = {
  input: InterviewModuleMemberMoveStageInput;
};


export type MutationInterviewModuleMemberApproveArgs = {
  input: InterviewModuleMemberApproveInput;
};


export type MutationInterviewModuleMemberLogCreateArgs = {
  input: InterviewModuleMemberLogCreateInput;
};


export type MutationInterviewModuleMemberLogUpdateArgs = {
  input: InterviewModuleMemberLogUpdateInput;
};


export type MutationInterviewModuleMemberLogDeleteArgs = {
  input: InterviewModuleMemberLogDeleteInput;
};


export type MutationCustomJobStageInterviewPlanDeleteArgs = {
  id: Scalars['uuid'];
};


export type MutationJobStageInterviewPlanUpsertArgs = {
  input: JobStageInterviewPlanUpsertInput;
};


export type MutationResetJobStageToAtsInterviewPlanArgs = {
  input: ResetJobStageToAtsInterviewPlanInput;
};


export type MutationTrainingFeedbackReminderNagArgs = {
  input: TrainingFeedbackReminderNagInput;
};


export type MutationJobUpdateArgs = {
  input: JobUpdateInput;
};


export type MutationJobStageSettingsUpsertArgs = {
  input: JobStageSettingsUpsertInput;
};


export type MutationJobSettingsUpsertArgs = {
  input: JobSettingsUpsertInput;
};


export type MutationJobSettingsImportArgs = {
  input: JobSettingsImportInput;
};


export type MutationTemplateCreateArgs = {
  input: TemplateCreateInput;
};


export type MutationTemplateUpdateArgs = {
  input: TemplateUpdateInput;
};


export type MutationTemplateDeleteArgs = {
  id: Scalars['uuid'];
};


export type MutationCalendarMeetingCreateArgs = {
  input: CalendarMeetingCreateInput;
};


export type MutationZoomMeetingUpdateArgs = {
  input: ZoomMeetingUpdateInput;
};


export type MutationZoomMeetingCreateArgs = {
  input: ZoomMeetingCreateInput;
};


export type MutationMeetingLocationCreateArgs = {
  input: MeetingLocationCreateInput;
};


export type MutationShortUrlCreateArgs = {
  input: ShortUrlCreateInput;
};


export type MutationSelfScheduleOptionsUpsertArgs = {
  input: SelfScheduleOptionsUpsertInput;
};


export type MutationSelfScheduleOptionsDeleteArgs = {
  id: Scalars['uuid'];
};


export type MutationScheduleCancelArgs = {
  input: ScheduleCancelInput;
};


export type MutationScheduleCreateArgs = {
  input: ScheduleCreateInput;
};


export type MutationScheduleRescheduleArgs = {
  input: ScheduleRescheduleInput;
};


export type MutationTaskQueueCreateArgs = {
  input: TaskQueueCreateInput;
};


export type MutationTaskQueueUpdateArgs = {
  input: TaskQueueUpdateInput;
};


export type MutationTaskQueueDeleteArgs = {
  input: TaskQueueDeleteInput;
};


export type MutationTaskTagCreateArgs = {
  input: TaskTagCreateInput;
};


export type MutationTaskTagUpdateArgs = {
  input: TaskTagUpdateInput;
};


export type MutationTaskTagDeleteArgs = {
  input: TaskTagDeleteInput;
};


export type MutationTaskCreateArgs = {
  input: TaskCreateInput;
};


export type MutationTaskSetTagsArgs = {
  input: TaskSetTagsInput;
};


export type MutationTaskCancelArgs = {
  input: TaskCancelInput;
};


export type MutationTaskSetDebriefRequiredArgs = {
  input: TaskSetDebriefRequiredInput;
};


export type MutationTaskSetIsUrgentArgs = {
  input: TaskSetIsUrgentInput;
};


export type MutationTaskSetAssigneeArgs = {
  input: TaskSetAssigneeInput;
};


export type MutationTaskSetTaskQueueArgs = {
  input: TaskSetTaskQueueInput;
};


export type MutationTaskRequestChangeArgs = {
  input: TaskRequestChangeInput;
};


export type MutationTaskUpdateArgs = {
  input: TaskUpdateInput;
};


export type MutationTaskFlagMarkResolvedArgs = {
  input: TaskFlagMarkResolvedInput;
};


export type MutationRescheduleReasonCreateArgs = {
  input: RescheduleReasonCreateInput;
};


export type MutationRescheduleReasonUpdateArgs = {
  input: RescheduleReasonUpdateInput;
};


export type MutationRescheduleLogCreateArgs = {
  input: RescheduleLogCreateInput;
};


export type MutationRescheduleLogUpdateArgs = {
  input: RescheduleLogUpdateInput;
};


export type MutationRescheduleLogDeleteArgs = {
  input: RescheduleLogDeleteInput;
};


export type MutationSubscriberAddArgs = {
  input: SubscriberAddInput;
};


export type MutationSubscriberRemoveArgs = {
  input: SubscriberRemoveInput;
};


export type MutationCandidateUpdateArgs = {
  input: CandidateUpdateInput;
};


export type MutationApplicationUpdateArgs = {
  input: ApplicationUpdateInput;
};


export type MutationApplicationCandidatePortalNoteCreateArgs = {
  input: ApplicationCandidatePortalNoteCreateInput;
};


export type MutationApplicationCandidatePortalNoteUpdateArgs = {
  input: ApplicationCandidatePortalNoteUpdateInput;
};


export type MutationApplicationCandidatePortalNoteDeleteArgs = {
  id: Scalars['uuid'];
};


export type MutationBrandingThemeCreateArgs = {
  input: BrandingThemeCreateInput;
};


export type MutationBrandingThemeUpdateArgs = {
  input: BrandingThemeUpdateInput;
};


export type MutationBrandingThemeDeleteArgs = {
  id: Scalars['uuid'];
};


export type MutationPortalJobOptionsDeleteArgs = {
  id: Scalars['uuid'];
};


export type MutationPortalJobOptionsUpsertArgs = {
  input: PortalJobOptionsUpsertInput;
};


export type MutationPortalTemplateCreateArgs = {
  input: PortalTemplateCreateInput;
};


export type MutationPortalTemplateUpdateArgs = {
  input: PortalTemplateUpdateInput;
};


export type MutationPortalTemplateDeleteArgs = {
  id: Scalars['uuid'];
};


export type MutationEmployeePlannedAvailabilityCreateArgs = {
  input: EmployeePlannedAvailabilityCreateInput;
};


export type MutationEmployeePlannedAvailabilityUpdateArgs = {
  input: EmployeePlannedAvailabilityUpdateInput;
};


export type MutationEmployeePlannedAvailabilityDeleteArgs = {
  input: EmployeePlannedAvailabilityDeleteInput;
};


export type MutationSourcingLinkCreateArgs = {
  input: SourcingLinkCreateInput;
};


export type MutationSourcingLinkUpdateArgs = {
  input: SourcingLinkUpdateInput;
};


export type MutationSourcingLinkDeleteArgs = {
  id: Scalars['uuid'];
};


export type MutationCheckCreateCandidatePermissionArgs = {
  input: CheckCreateCandidatePermissionInput;
};


export type MutationInterviewerPortalSettingsUpsertArgs = {
  input: InterviewerPortalSettingsUpsertInput;
};


export type MutationZoomUserConcurrentMeetingsPutArgs = {
  input: ZoomUserConcurrentMeetingsPutInput;
};


export type MutationZoomUserConcurrentMeetingsUpdateArgs = {
  input: ZoomUserConcurrentMeetingsUpdateInput;
};


export type MutationZoomUserConcurrentMeetingsDeleteArgs = {
  input: ZoomUserConcurrentMeetingsDeleteInput;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  employeeIds?: Maybe<Array<Scalars['uuid']>>;
  employees: Array<Employee>;
  externalEmailAddresses?: Maybe<Array<Scalars['String']>>;
  dynamicAudiences?: Maybe<Array<DynamicAudience>>;
};

export type NotificationPreferenceInput = {
  employeeIds?: Maybe<Array<Scalars['uuid']>>;
  externalEmailAddresses?: Maybe<Array<Scalars['String']>>;
  dynamicAudiences?: Maybe<Array<DynamicAudience>>;
};

/** ########################################################## */
export type Options = {
  __typename?: 'Options';
  inclusionDays?: Maybe<Array<Scalars['String']>>;
  advanceNoticeInHours?: Maybe<Scalars['numeric']>;
  rollingDays?: Maybe<Scalars['numeric']>;
  candidateCalendarId?: Maybe<Scalars['String']>;
  interviewerCalendarId?: Maybe<Scalars['String']>;
  location?: Maybe<SelfScheduleLocation>;
  zoomHost?: Maybe<SelfScheduleZoomHost>;
  meetingHost?: Maybe<SelfScheduleMeetingHost>;
  videoMeetingHostEmployeeId?: Maybe<Scalars['uuid']>;
  isPrivateCalendarEvent?: Maybe<Scalars['Boolean']>;
  zoomHostUserId?: Maybe<Scalars['String']>;
  candidateEmail: Scalars['String'];
  notificationPreference?: Maybe<SelfScheduleNotificationPreference>;
  customLocation?: Maybe<Scalars['String']>;
  shouldRespectLoadLimit: Scalars['Boolean'];
  canScheduleOverRecruitingKeywords: Scalars['Boolean'];
  canScheduleOverAvailableKeywords: Scalars['Boolean'];
  canScheduleOverFreeTime: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
};

/** ################################## */
export type OptionsInput = {
  inclusionDays?: Maybe<Array<Maybe<Scalars['String']>>>;
  advanceNoticeInHours?: Maybe<Scalars['numeric']>;
  rollingDays?: Maybe<Scalars['numeric']>;
  candidateCalendarId: Scalars['String'];
  interviewerCalendarId: Scalars['String'];
  location: SelfScheduleLocation;
  zoomHost?: Maybe<SelfScheduleZoomHost>;
  meetingHost?: Maybe<SelfScheduleMeetingHost>;
  videoMeetingHostEmployeeId?: Maybe<Scalars['uuid']>;
  isPrivateCalendarEvent?: Maybe<Scalars['Boolean']>;
  zoomHostUserId?: Maybe<Scalars['String']>;
  notificationPreference?: Maybe<SelfScheduleNotificationPreferenceInput>;
  customLocation?: Maybe<Scalars['String']>;
  /**  default true */
  shouldRespectLoadLimit?: Maybe<Scalars['Boolean']>;
  /**  Default false */
  canScheduleOverRecruitingKeywords?: Maybe<Scalars['Boolean']>;
  /**  Default false */
  canScheduleOverAvailableKeywords?: Maybe<Scalars['Boolean']>;
  /**  Default true */
  canScheduleOverFreeTime?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
};

/** ########################################################## */
export type Org = {
  __typename?: 'Org';
  id: Scalars['uuid'];
  name: Scalars['String'];
  domain: Scalars['String'];
  authOrganizationId?: Maybe<Scalars['String']>;
  orgPref?: Maybe<OrgPref>;
  orgPrefByNames: Array<OrgPref>;
  orgPrefs?: Maybe<Array<OrgPref>>;
  integrations?: Maybe<OrgIntegrations>;
  hasAdmin: Scalars['Boolean'];
  userRoles: Array<UserRole>;
  hasSamlSso: Scalars['Boolean'];
  hasScim: Scalars['Boolean'];
  userInfos: UserInfoResult;
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  slugs: Array<PortalOrgSlug>;
  recruitingSources: Array<RecruitingSource>;
  upcomingInterviewsCount: Scalars['numeric'];
  activeCandidateAvailabilityLinksCount: Scalars['numeric'];
  activeSelfScheduleLinksCount: Scalars['numeric'];
  locations: Array<Scalars['String']>;
  interviewerPortalSettings: InterviewerPortalSettings;
  directoryType?: Maybe<DirectoryType>;
  atsType?: Maybe<AtsType>;
  atsStatus: IntegrationConnectionStatus;
  directoryStatus: IntegrationConnectionStatus;
};


/** ########################################################## */
export type OrgOrgPrefArgs = {
  prefName: Scalars['String'];
};


/** ########################################################## */
export type OrgOrgPrefByNamesArgs = {
  prefNames: Array<Scalars['String']>;
};


/** ########################################################## */
export type OrgUserInfosArgs = {
  input: OrgUserInfoInput;
};

export type OrgIntegrations = {
  __typename?: 'OrgIntegrations';
  ashby?: Maybe<AshbyIntegration>;
  coderpad?: Maybe<CoderpadIntegration>;
  greenhouse?: Maybe<GreenhouseIntegration>;
  lever?: Maybe<LeverIntegration>;
  google?: Maybe<GoogleIntegration>;
  microsoft?: Maybe<MicrosoftIntegration>;
  microsoftGccHigh?: Maybe<MicrosoftGccHighIntegration>;
  codesignal?: Maybe<CodesignalIntegration>;
  zoom?: Maybe<ZoomIntegration>;
  slack?: Maybe<SlackOrgIntegration>;
  hackerrank?: Maybe<HackerRankIntegration>;
  codility?: Maybe<CodilityIntegration>;
};

export type OrgJsonPref = InterviewLimit | TimeBlockWeek | DateBlock | DefaultCustomInterviewScheduleTokenPref | NotificationPreference | AddZoomHostAsInternalInviteePref | CandidateFacingInterviewerInfo | CandidateFacingHiringTeamContactInfo | PaidFeaturePreference | MinimumInterviewerLoadLimitOrgPref | InterviewMeetingLocationSettingPref | InterviewBufferTimePref | CandidateCommunicationsSettingPref;

/**
 * ###############################################################
 *  This file contains the GraphQL schema for the org and employee preferences.
 * ###############################################################
 */
export type OrgPref = {
  __typename?: 'OrgPref';
  id: Scalars['String'];
  /** This is a computed PK */
  orgId: Scalars['uuid'];
  prefName: Scalars['String'];
  boolValue?: Maybe<Scalars['Boolean']>;
  stringValue?: Maybe<Scalars['String']>;
  intValue?: Maybe<Scalars['numeric']>;
  stringArray?: Maybe<Array<Scalars['String']>>;
  json?: Maybe<Scalars['jsonb']>;
  jsonArray?: Maybe<Array<Scalars['jsonb']>>;
  jsonPref?: Maybe<OrgJsonPref>;
  jsonArrayPref: Array<OrgJsonPref>;
  isLocked: Scalars['Boolean'];
  timestamp?: Maybe<Scalars['datetime']>;
  duration?: Maybe<Duration>;
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
};

/** ######################################### */
export type OrgPrefUpdateInput = {
  prefName: Scalars['String'];
  set?: Maybe<PrefSet>;
};

export type OrgPrefUpdateResult = {
  __typename?: 'OrgPrefUpdateResult';
  orgPref?: Maybe<OrgPref>;
};

/** ########################################################## */
export type OrgUpdateInput = {
  name?: Maybe<Scalars['String']>;
};

export type OrgUserInfoInput = {
  pageInput: PageInput;
  query?: Maybe<Scalars['String']>;
  sortBy?: Maybe<OrgUserInfoSortBy>;
  sortOrder?: Maybe<Sort>;
  requiredPermission?: Maybe<Permission>;
};

export enum OrgUserInfoSortBy {
  UserId = 'USER_ID',
  Name = 'NAME',
  Email = 'EMAIL'
}

export type PageInput = {
  offset?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
};

export type PaidFeaturePreference = {
  __typename?: 'PaidFeaturePreference';
  type?: Maybe<PaidFeatureType>;
  expireAt?: Maybe<Scalars['datetime']>;
};

export enum PaidFeatureType {
  Paid = 'PAID',
  Trial = 'TRIAL'
}

export enum Permission {
  CanManageOrgSettings = 'CAN_MANAGE_ORG_SETTINGS',
  CanSchedule = 'CAN_SCHEDULE',
  CanInterview = 'CAN_INTERVIEW'
}

export type PortalJobOptions = {
  __typename?: 'PortalJobOptions';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  jobId: Scalars['uuid'];
  /** @deprecated Field no longer supported */
  jobDisplayName?: Maybe<Scalars['String']>;
  intro?: Maybe<Scalars['String']>;
  brandingThemeId?: Maybe<Scalars['uuid']>;
  useOrgDefaultBrandingTheme?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  tabs: Array<PortalJobOptionsTab>;
  brandingTheme?: Maybe<BrandingTheme>;
};

export type PortalJobOptionsResult = {
  __typename?: 'PortalJobOptionsResult';
  options: PortalJobOptions;
};

export type PortalJobOptionsTab = {
  __typename?: 'PortalJobOptionsTab';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  portalJobOptionsId: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  name: Scalars['String'];
  icon: Scalars['String'];
  visibility: PortalJobOptionsTabVisibilityType;
  index: Scalars['numeric'];
  portalTemplateId: Scalars['uuid'];
  jobStageId?: Maybe<Scalars['uuid']>;
};

export type PortalJobOptionsTabInput = {
  name: Scalars['String'];
  icon: Scalars['String'];
  visibility: PortalJobOptionsTabVisibilityType;
  index: Scalars['numeric'];
  portalTemplateId: Scalars['uuid'];
  jobStageId?: Maybe<Scalars['uuid']>;
};

export enum PortalJobOptionsTabVisibilityType {
  Always = 'ALWAYS',
  Never = 'NEVER',
  JobStage = 'JOB_STAGE'
}

export type PortalJobOptionsUpsertInput = {
  jobId: Scalars['uuid'];
  intro?: Maybe<Scalars['String']>;
  brandingThemeId?: Maybe<Scalars['uuid']>;
  useOrgDefaultBrandingTheme: Scalars['Boolean'];
  tabs: Array<PortalJobOptionsTabInput>;
  contactInfos?: Maybe<Array<JobHiringTeamRole>>;
};

export type PortalOrgSlug = {
  __typename?: 'PortalOrgSlug';
  orgId: Scalars['uuid'];
  slug: Scalars['String'];
  createdAt: Scalars['datetime'];
  lastUsedAt?: Maybe<Scalars['datetime']>;
  isPrimary: Scalars['Boolean'];
};

export type PortalSlugCreateInput = {
  slug: Scalars['String'];
};

export type PortalTemplate = {
  __typename?: 'PortalTemplate';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  name: Scalars['String'];
  livePortals: Scalars['numeric'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  blocks: Array<PortalTemplateBlock>;
};

export type PortalTemplateBlock = {
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  portalTemplateId: Scalars['uuid'];
  type: BlockType;
  index: Scalars['numeric'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
};

export type PortalTemplateBlockContentCard = PortalTemplateBlock & {
  __typename?: 'PortalTemplateBlockContentCard';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  portalTemplateId: Scalars['uuid'];
  type: BlockType;
  index: Scalars['numeric'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  /**  Custom fields: */
  header?: Maybe<Scalars['String']>;
  cards: Array<ContentCard>;
};

export type PortalTemplateBlockDivider = PortalTemplateBlock & {
  __typename?: 'PortalTemplateBlockDivider';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  portalTemplateId: Scalars['uuid'];
  type: BlockType;
  index: Scalars['numeric'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
};

export type PortalTemplateBlockImage = PortalTemplateBlock & {
  __typename?: 'PortalTemplateBlockImage';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  portalTemplateId: Scalars['uuid'];
  type: BlockType;
  index: Scalars['numeric'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  /**  Custom fields: */
  url: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  fillPage: Scalars['Boolean'];
};

export type PortalTemplateBlockInput = {
  type: BlockType;
  index: Scalars['numeric'];
  content?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  fillPage?: Maybe<Scalars['Boolean']>;
  contentCards?: Maybe<Array<ContentCardInput>>;
  linkedCards?: Maybe<Array<LinkedCardInput>>;
};

export type PortalTemplateBlockLinkedCard = PortalTemplateBlock & {
  __typename?: 'PortalTemplateBlockLinkedCard';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  portalTemplateId: Scalars['uuid'];
  type: BlockType;
  index: Scalars['numeric'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  /**  Custom fields: */
  header?: Maybe<Scalars['String']>;
  cards: Array<LinkedCard>;
};

export type PortalTemplateBlockText = PortalTemplateBlock & {
  __typename?: 'PortalTemplateBlockText';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  portalTemplateId: Scalars['uuid'];
  type: BlockType;
  index: Scalars['numeric'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  /**  Custom fields: */
  content: Scalars['String'];
};

export type PortalTemplateBlockVideo = PortalTemplateBlock & {
  __typename?: 'PortalTemplateBlockVideo';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  portalTemplateId: Scalars['uuid'];
  type: BlockType;
  index: Scalars['numeric'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  /**  Custom fields: */
  url: Scalars['String'];
  header?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
};

export type PortalTemplateCreateInput = {
  name: Scalars['String'];
  blocks: Array<PortalTemplateBlockInput>;
};

export type PortalTemplateResult = {
  __typename?: 'PortalTemplateResult';
  template: PortalTemplate;
};

export type PortalTemplateUpdateInput = {
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  blocks: Array<PortalTemplateBlockInput>;
};

export type PortalTemplatesInput = {
  pageInput: PageInput;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<PortalTemplatesOrderBy>;
};

export type PortalTemplatesOrderBy = {
  property: PortalTemplatesOrderByProperty;
  direction: Sort;
};

export enum PortalTemplatesOrderByProperty {
  Id = 'ID',
  Name = 'NAME'
}

export type PortalTemplatesResult = {
  __typename?: 'PortalTemplatesResult';
  items: Array<PortalTemplate>;
  nextCursor?: Maybe<Scalars['String']>;
};

export enum PrefLevel {
  Org = 'ORG',
  Employee = 'EMPLOYEE'
}

export type PrefSet = {
  boolValue?: Maybe<Scalars['Boolean']>;
  stringValue?: Maybe<Scalars['String']>;
  intValue?: Maybe<Scalars['numeric']>;
  stringArray?: Maybe<Array<Maybe<Scalars['String']>>>;
  json?: Maybe<Scalars['jsonb']>;
  jsonArray?: Maybe<Array<Maybe<Scalars['jsonb']>>>;
  timestamp?: Maybe<Scalars['datetime']>;
  duration?: Maybe<DurationInput>;
  isLocked?: Maybe<Scalars['Boolean']>;
};

export type PreviewTokenCandidate = {
  phoneNumber: Scalars['String'];
};

export type PreviewTokenEmployee = {
  id: Scalars['uuid'];
  fullName: Scalars['String'];
  title: Scalars['String'];
  linkedInUrl: Scalars['String'];
};

export type PreviousInterviewsForApplication = {
  __typename?: 'PreviousInterviewsForApplication';
  flag: InterviewerFlag;
  applicationStageInterviews?: Maybe<Array<ApplicationStageInterview>>;
};

export type ProfilePicture = {
  __typename?: 'ProfilePicture';
  imageOriginal?: Maybe<Scalars['String']>;
  image32px?: Maybe<Scalars['String']>;
  image64px?: Maybe<Scalars['String']>;
  image128px?: Maybe<Scalars['String']>;
  image256px?: Maybe<Scalars['String']>;
  image512px?: Maybe<Scalars['String']>;
  image1024px?: Maybe<Scalars['String']>;
};

export type ProfilePicturePref = {
  __typename?: 'ProfilePicturePref';
  originalImageUrl?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  hello?: Maybe<Scalars['String']>;
  /**  utility */
  thisOrg?: Maybe<Org>;
  thisEmployee?: Maybe<Employee>;
  thisUserInfo?: Maybe<UserInfo>;
  userInfoByEmployeeIds?: Maybe<Array<UserInfo>>;
  templateToken?: Maybe<TemplateToken>;
  /**  Custom interview schedule token preview used in org setting */
  customInterviewScheduleTokenPreview?: Maybe<CustomInterviewScheduleTokenPreviewResult>;
  defaultCustomInterviewScheduleToken?: Maybe<DefaultCustomInterviewScheduleTokenPref>;
  /** ATS (Unification) */
  atsUsers?: Maybe<AtsUserOutput>;
  atsInterviewDefinitions?: Maybe<AtsInterviewDefinitionsOutput>;
  /** Application */
  application?: Maybe<Application>;
  applicationByAtsId?: Maybe<Application>;
  applicationByIds?: Maybe<Array<Application>>;
  applicationSearch?: Maybe<ApplicationSearchOutput>;
  /** ApplicationStage */
  applicationStage?: Maybe<ApplicationStage>;
  applicationStages?: Maybe<ApplicationStageOutput>;
  /** Candidate */
  candidate?: Maybe<Candidate>;
  candidateByAtsId?: Maybe<Candidate>;
  candidateByIds?: Maybe<Array<Candidate>>;
  candidateAvailability?: Maybe<CandidateAvailability>;
  candidateAvailabilityOptions?: Maybe<CandidateAvailabilityOptions>;
  calendar?: Maybe<Calendar>;
  calendars?: Maybe<CalendarsResult>;
  /** Company Country holidays */
  companyHolidays?: Maybe<CompanyHolidayResult>;
  companyHolidaysForDays?: Maybe<CompanyHolidaysForDaysResult>;
  /** Job */
  job?: Maybe<Job>;
  jobByIds?: Maybe<Array<Job>>;
  jobs?: Maybe<JobsOutput>;
  jobSettingsImportValidate: JobSettingsImportValidateResult;
  /** Job Stage */
  jobStage?: Maybe<JobStage>;
  jobStageByIds?: Maybe<Array<JobStage>>;
  jobStages?: Maybe<JobStagesOutput>;
  jobStagesByName?: Maybe<JobStagesByNameOutput>;
  /** Employee */
  employee?: Maybe<Employee>;
  employeeByIds?: Maybe<Array<Employee>>;
  employees?: Maybe<EmployeesOutput>;
  employeeSearch?: Maybe<EmployeeSearchOutput>;
  managers?: Maybe<EmployeesOutput>;
  /** EmployeeAttributes */
  employeeAttributeNames?: Maybe<EmployeeAttributeNamesResult>;
  /** MeetingRoom */
  meetingRoom?: Maybe<MeetingRoom>;
  meetingRoomByIds?: Maybe<Array<MeetingRoom>>;
  meetingRoomSuggestions?: Maybe<MeetingRoomSuggestionsOutput>;
  /** Interview Module */
  interviewModule?: Maybe<InterviewModule>;
  interviewModuleByIds?: Maybe<Array<InterviewModule>>;
  interviewModules?: Maybe<InterviewModulesOutput>;
  interviewModuleSearch?: Maybe<InterviewModuleSearchOutput>;
  /** Interview Module Members */
  interviewModuleMember?: Maybe<InterviewModuleMember>;
  interviewModuleMembers?: Maybe<InterviewModuleMembersOutput>;
  interviewModuleMemberSearch?: Maybe<InterviewModuleMemberSearchOutput>;
  interviewModuleMemberLookup?: Maybe<InterviewModuleMemberLookupOutput>;
  interviewModuleMemberStats?: Maybe<Array<InterviewModuleMemberStats>>;
  /** Email */
  applicationEmails?: Maybe<ApplicationEmailOutput>;
  /** Template */
  template?: Maybe<Template>;
  templateByIds?: Maybe<Array<Template>>;
  templates?: Maybe<TemplatesOutput>;
  /** Calendar */
  calendarFreeBusy?: Maybe<CalendarFreeBusyOutput>;
  /**  Non-db rows */
  interviewerSuggestions?: Maybe<InterviewerSuggestionsOutput>;
  /**
   *  self schedule options
   * @deprecated This is no longer used
   */
  selfScheduleOptionsCount?: Maybe<SelfScheduleOptionsCount>;
  selfScheduleOptions?: Maybe<SelfScheduleOptions>;
  /**  suggested availability + self schedule V2 */
  suggestedTimeRangesCount?: Maybe<SuggestedTimeRangesCountOutput>;
  /**  metrics */
  interviewerMetricsChart?: Maybe<InterviewerMetricsChartResult>;
  interviewerMetricsChartDownload?: Maybe<MetricsChartDownloadResult>;
  interviewerMetricsInterviewing?: Maybe<InterviewerMetricsInterviewingResult>;
  interviewerMetricsDeclines?: Maybe<InterviewerMetricsDeclinesResult>;
  interviewerMetricsTraining?: Maybe<InterviewerMetricsTrainingResult>;
  recruiterMetricsChart?: Maybe<RecruiterMetricsChartResult>;
  recruiterMetricsRescheduleLogs?: Maybe<RecruiterRescheduleLogsWrapperResult>;
  recruiterMetricsTable?: Maybe<RecruiterMetricsTableResult>;
  recruiterMetricsChartDownload?: Maybe<MetricsChartDownloadResult>;
  /** temporary until we fetch data from ES */
  interviewerMetricsDeclinesWrapper?: Maybe<InterviewerMetricsDeclinesWrapperResult>;
  /**  make slack call to pull user's private channel, not archived and not read only. */
  slackChannelByName?: Maybe<Array<SlackChannel>>;
  /** zoom */
  zoomUser?: Maybe<ZoomUser>;
  zoomUsers?: Maybe<ZoomUsersResult>;
  zoomUsersByIds?: Maybe<Array<ZoomUser>>;
  integrationGetOauthUrl?: Maybe<IntegrationGetOauthUrlResult>;
  integrationCheckHealth?: Maybe<IntegrationCheckHealthResult>;
  /**  Upcoming Events */
  upcomingEvents?: Maybe<UpcomingEventsResult>;
  /**  Flow initializers (follow naming convention: init<FlowName>) */
  initScheduleFlow?: Maybe<InitScheduleFlowResult>;
  /**  task's tags */
  taskTags?: Maybe<TaskTagsResult>;
  /**  task */
  task?: Maybe<Task>;
  taskSearch?: Maybe<TaskSearchResult>;
  taskTag?: Maybe<TaskTag>;
  /**  task queues */
  taskQueues?: Maybe<TaskQueuesResult>;
  taskQueueSearch?: Maybe<TaskQueueSearchResult>;
  taskQueue?: Maybe<TaskQueue>;
  /**  Reschedule Log */
  rescheduleReasons?: Maybe<RescheduleReasonsResult>;
  /**  Subscribers */
  subscribers?: Maybe<SubscribersResult>;
  /**  Candidate Portal */
  brandingThemes?: Maybe<BrandingThemesResult>;
  linkUnfurl?: Maybe<LinkUnfurlResult>;
  portalTemplate?: Maybe<PortalTemplate>;
  portalTemplates?: Maybe<PortalTemplatesResult>;
  scheduleOptions: ScheduleOptionsResult;
  rescheduleOptions: ScheduleOptionsResult;
  debriefOptions: ScheduleOptionsResult;
  /**  Dynamic Employee */
  dynamicEmployee?: Maybe<DynamicEmployee>;
};


export type QueryUserInfoByEmployeeIdsArgs = {
  ids: Array<Scalars['uuid']>;
};


export type QueryTemplateTokenArgs = {
  input: TemplateTokenInput;
};


export type QueryCustomInterviewScheduleTokenPreviewArgs = {
  input: CustomInterviewScheduleTokenPreviewInput;
};


export type QueryAtsUsersArgs = {
  input: AtsUserInput;
};


export type QueryAtsInterviewDefinitionsArgs = {
  input: AtsInterviewDefinitionsInput;
};


export type QueryApplicationArgs = {
  id: Scalars['uuid'];
};


export type QueryApplicationByAtsIdArgs = {
  input: ApplicationByAtsIdInput;
};


export type QueryApplicationByIdsArgs = {
  ids: Array<Scalars['uuid']>;
};


export type QueryApplicationSearchArgs = {
  input: ApplicationSearchInput;
};


export type QueryApplicationStageArgs = {
  id: Scalars['uuid'];
};


export type QueryApplicationStagesArgs = {
  input: ApplicationStageInput;
};


export type QueryCandidateArgs = {
  id: Scalars['uuid'];
};


export type QueryCandidateByAtsIdArgs = {
  input: CandidateByAtsIdInput;
};


export type QueryCandidateByIdsArgs = {
  ids: Array<Scalars['uuid']>;
};


export type QueryCandidateAvailabilityArgs = {
  input: CandidateAvailabilityInput;
};


export type QueryCandidateAvailabilityOptionsArgs = {
  input: CandidateAvailabilityOptionsInput;
};


export type QueryCalendarArgs = {
  id: Scalars['String'];
};


export type QueryCalendarsArgs = {
  input: CalendarsInput;
};


export type QueryCompanyHolidaysArgs = {
  input: CompanyHolidaysInput;
};


export type QueryCompanyHolidaysForDaysArgs = {
  input: CompanyHolidaysForDaysInput;
};


export type QueryJobArgs = {
  id: Scalars['uuid'];
};


export type QueryJobByIdsArgs = {
  ids: Array<Scalars['uuid']>;
};


export type QueryJobsArgs = {
  input: JobsInput;
};


export type QueryJobSettingsImportValidateArgs = {
  input: JobSettingsImportValidateInput;
};


export type QueryJobStageArgs = {
  id: Scalars['uuid'];
};


export type QueryJobStageByIdsArgs = {
  ids: Array<Scalars['uuid']>;
};


export type QueryJobStagesArgs = {
  input: JobStagesInput;
};


export type QueryJobStagesByNameArgs = {
  input: JobStagesInput;
};


export type QueryEmployeeArgs = {
  id: Scalars['uuid'];
};


export type QueryEmployeeByIdsArgs = {
  ids: Array<Scalars['uuid']>;
};


export type QueryEmployeesArgs = {
  input: EmployeesInput;
};


export type QueryEmployeeSearchArgs = {
  input: EmployeeSearchInput;
};


export type QueryManagersArgs = {
  input: ManagersInput;
};


export type QueryEmployeeAttributeNamesArgs = {
  input: EmployeeAttributeNamesInput;
};


export type QueryMeetingRoomArgs = {
  id: Scalars['uuid'];
};


export type QueryMeetingRoomByIdsArgs = {
  ids: Array<Scalars['uuid']>;
};


export type QueryMeetingRoomSuggestionsArgs = {
  input: MeetingRoomSuggestionsInput;
};


export type QueryInterviewModuleArgs = {
  id: Scalars['uuid'];
};


export type QueryInterviewModuleByIdsArgs = {
  ids: Array<Scalars['uuid']>;
};


export type QueryInterviewModulesArgs = {
  input: InterviewModulesInput;
};


export type QueryInterviewModuleSearchArgs = {
  input: InterviewModuleSearchInput;
};


export type QueryInterviewModuleMemberArgs = {
  interviewModuleId: Scalars['uuid'];
  employeeId: Scalars['uuid'];
};


export type QueryInterviewModuleMembersArgs = {
  input: InterviewModuleMembersInput;
};


export type QueryInterviewModuleMemberSearchArgs = {
  input: InterviewModuleMemberSearchInput;
};


export type QueryInterviewModuleMemberLookupArgs = {
  input: InterviewModuleMemberLookupInput;
};


export type QueryApplicationEmailsArgs = {
  input: ApplicationEmailInput;
};


export type QueryTemplateArgs = {
  id: Scalars['uuid'];
};


export type QueryTemplateByIdsArgs = {
  ids: Array<Scalars['uuid']>;
};


export type QueryTemplatesArgs = {
  input: TemplatesInput;
};


export type QueryCalendarFreeBusyArgs = {
  input: CalendarFreeBusyInput;
};


export type QueryInterviewerSuggestionsArgs = {
  input: InterviewerSuggestionsInput;
};


export type QuerySelfScheduleOptionsCountArgs = {
  input: SelfScheduleOptionsCountInput;
};


export type QuerySelfScheduleOptionsArgs = {
  id: Scalars['uuid'];
};


export type QuerySuggestedTimeRangesCountArgs = {
  input: SuggestedTimeRangesCountInput;
};


export type QueryInterviewerMetricsChartArgs = {
  input: InterviewerMetricsChartInput;
};


export type QueryInterviewerMetricsChartDownloadArgs = {
  input: InterviewerMetricsChartInput;
};


export type QueryInterviewerMetricsInterviewingArgs = {
  input: InterviewerMetricsTableInput;
};


export type QueryInterviewerMetricsDeclinesArgs = {
  input: InterviewerMetricsTableInput;
};


export type QueryInterviewerMetricsTrainingArgs = {
  input: InterviewerMetricsTableInput;
};


export type QueryRecruiterMetricsChartArgs = {
  input: RecruiterMetricsChartInput;
};


export type QueryRecruiterMetricsRescheduleLogsArgs = {
  input: RecruiterMetricsChartInput;
};


export type QueryRecruiterMetricsTableArgs = {
  input: RecruiterMetricsChartInput;
};


export type QueryRecruiterMetricsChartDownloadArgs = {
  input: RecruiterMetricsChartInput;
};


export type QueryInterviewerMetricsDeclinesWrapperArgs = {
  input: InterviewerMetricsChartInput;
};


export type QuerySlackChannelByNameArgs = {
  name: Scalars['String'];
};


export type QueryZoomUserArgs = {
  id: Scalars['String'];
};


export type QueryZoomUsersArgs = {
  input: ZoomUsersInput;
};


export type QueryZoomUsersByIdsArgs = {
  input: ZoomUsersByIdsInput;
};


export type QueryIntegrationGetOauthUrlArgs = {
  input: IntegrationGetOauthUrlInput;
};


export type QueryIntegrationCheckHealthArgs = {
  input: IntegrationCheckHealthInput;
};


export type QueryUpcomingEventsArgs = {
  input: UpcomingEventsInput;
};


export type QueryInitScheduleFlowArgs = {
  input: InitScheduleFlowInput;
};


export type QueryTaskTagsArgs = {
  input: TaskTagsInput;
};


export type QueryTaskArgs = {
  id: Scalars['uuid'];
};


export type QueryTaskSearchArgs = {
  input: TaskSearchInput;
};


export type QueryTaskTagArgs = {
  id: Scalars['uuid'];
};


export type QueryTaskQueuesArgs = {
  input: TaskQueuesInput;
};


export type QueryTaskQueueSearchArgs = {
  input: TaskQueueSearchInput;
};


export type QueryTaskQueueArgs = {
  id: Scalars['uuid'];
};


export type QueryRescheduleReasonsArgs = {
  input: RescheduleReasonsInput;
};


export type QuerySubscribersArgs = {
  input: SubscribersInput;
};


export type QueryLinkUnfurlArgs = {
  input: LinkUnfurlInput;
};


export type QueryPortalTemplateArgs = {
  id: Scalars['uuid'];
};


export type QueryPortalTemplatesArgs = {
  input: PortalTemplatesInput;
};


export type QueryScheduleOptionsArgs = {
  input: ScheduleOptionsInput;
};


export type QueryRescheduleOptionsArgs = {
  input: ReScheduleOptionsInput;
};


export type QueryDebriefOptionsArgs = {
  input: DebriefOptionsInput;
};


export type QueryDynamicEmployeeArgs = {
  id: Scalars['String'];
};

export type ReScheduleOptionsInput = {
  jobStageId: Scalars['uuid'];
  groups: Array<InterviewGroupInput>;
  availabilities: Array<DateTimeRangeInput>;
  candidateTimezone: Scalars['timezone'];
  excludedInterviewIds?: Maybe<Array<Scalars['uuid']>>;
  hasBreaks: Scalars['Boolean'];
  hasMultiDay: Scalars['Boolean'];
  numberOfDays: Scalars['Int'];
  throwOnZero: Scalars['Boolean'];
  hardConflicts?: Maybe<Array<HardConflictInput>>;
  interviewerResponseStatuses?: Maybe<Array<InterviewerResponseStatusInput>>;
  applicationId?: Maybe<Scalars['uuid']>;
};

export type RecentlyUsedZoomUsersPref = {
  __typename?: 'RecentlyUsedZoomUsersPref';
  zoomUserIds: Array<Scalars['String']>;
  zoomUsers: Array<ZoomUser>;
};

export type Recipient = {
  __typename?: 'Recipient';
  to?: Maybe<Array<Scalars['String']>>;
  cc?: Maybe<Array<Scalars['String']>>;
  bcc?: Maybe<Array<Scalars['String']>>;
};

/** ######################### */
export type RecruiterMetricsChartInput = {
  chartType?: Maybe<RecruiterMetricsChartType>;
  startAt?: Maybe<Scalars['datetime']>;
  endAt?: Maybe<Scalars['datetime']>;
  filters: InterviewerFilters;
  timezone?: Maybe<Scalars['timezone']>;
  viewBy?: Maybe<RecruiterMetricsChartViewBy>;
};

/**
 * #########################
 *  input types for recruiter metrics chart and tables
 */
export type RecruiterMetricsChartResult = {
  __typename?: 'RecruiterMetricsChartResult';
  chartType: RecruiterMetricsChartType;
  data?: Maybe<Array<ChartCoordinateData>>;
};

/**
 * #########################
 *  enum types for recruiter metrics chart and tables
 */
export enum RecruiterMetricsChartType {
  RecruiterInterviewsScheduled = 'RECRUITER_INTERVIEWS_SCHEDULED',
  RecruiterInterviewsCompleted = 'RECRUITER_INTERVIEWS_COMPLETED',
  RecruiterInterviewsDeclines = 'RECRUITER_INTERVIEWS_DECLINES',
  RecruiterInterviewsCancelled = 'RECRUITER_INTERVIEWS_CANCELLED',
  RecruiterInterviewsChanged = 'RECRUITER_INTERVIEWS_CHANGED',
  RecruiterScheduleStageScheduled = 'RECRUITER_SCHEDULE_STAGE_SCHEDULED',
  RecruiterScheduleStageFacilitated = 'RECRUITER_SCHEDULE_STAGE_FACILITATED',
  RecruiterScheduleStageChanged = 'RECRUITER_SCHEDULE_STAGE_CHANGED',
  RecruiterScheduleStageCancelled = 'RECRUITER_SCHEDULE_STAGE_CANCELLED',
  RecruiterRescheduleLogs = 'RECRUITER_RESCHEDULE_LOGS'
}

export enum RecruiterMetricsChartViewBy {
  Interviews = 'INTERVIEWS',
  ScheduleStage = 'SCHEDULE_STAGE'
}

export type RecruiterMetricsTable = {
  __typename?: 'RecruiterMetricsTable';
  recruiter?: Maybe<Employee>;
  scheduled?: Maybe<Scalars['numeric']>;
  completed?: Maybe<Scalars['numeric']>;
  declines?: Maybe<Scalars['numeric']>;
  cancelled?: Maybe<Scalars['numeric']>;
  changed?: Maybe<Scalars['numeric']>;
  facilitated?: Maybe<Scalars['numeric']>;
};

export type RecruiterMetricsTableResult = {
  __typename?: 'RecruiterMetricsTableResult';
  items?: Maybe<Array<RecruiterMetricsTable>>;
};

export type RecruiterRescheduleLog = {
  __typename?: 'RecruiterRescheduleLog';
  id?: Maybe<Scalars['uuid']>;
  performedBy?: Maybe<Employee>;
  date?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  rescheduleReasonId?: Maybe<Scalars['uuid']>;
  rescheduleReason?: Maybe<RescheduleReason>;
  taskId?: Maybe<Scalars['uuid']>;
  task?: Maybe<Task>;
};

export type RecruiterRescheduleLogsData = {
  __typename?: 'RecruiterRescheduleLogsData';
  rescheduleReasonId: Scalars['uuid'];
  rescheduleReason: RescheduleReason;
  data: Array<ChartCoordinateData>;
};

export type RecruiterRescheduleLogsWrapperResult = {
  __typename?: 'RecruiterRescheduleLogsWrapperResult';
  rescheduleLogsChart?: Maybe<Array<RecruiterRescheduleLogsData>>;
  items?: Maybe<Array<RecruiterRescheduleLog>>;
};

export type RecruitingSource = {
  __typename?: 'RecruitingSource';
  source: Scalars['String'];
};

export type RecruitingTeamContactInfoSetting = {
  __typename?: 'RecruitingTeamContactInfoSetting';
  isCustom: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  level: SettingLevel;
  /**  Specific to this setting */
  showRecruiter: Scalars['Boolean'];
  showCoordinator: Scalars['Boolean'];
  canShowJobTitle: Scalars['Boolean'];
  canShowLinkedin: Scalars['Boolean'];
  canShowPhone: Scalars['Boolean'];
  canShowProfilePicture: Scalars['Boolean'];
  canShowPronouns: Scalars['Boolean'];
  canShowBio: Scalars['Boolean'];
};

export type RecruitingTeamContactInfoSettingInput = {
  /**
   * Setting "isCustom" to false has the affect of explicitly setting the Job Setting's value to be the org default.
   * Otherwise, if "isCustom" is true, then the "showRecruiter" and "showCoordinator" values must be present and will
   * be used.
   */
  isCustom: Scalars['Boolean'];
  showRecruiter?: Maybe<Scalars['Boolean']>;
  showCoordinator?: Maybe<Scalars['Boolean']>;
};

/**  candidate email content */
export type RemoteAttachmentInput = {
  name: Scalars['String'];
  path: Scalars['String'];
};

export type RemoteVideoMeetingHostUser = ZoomUser;

export enum RenderType {
  Html = 'HTML',
  SlackMarkdown = 'SLACK_MARKDOWN',
  Plaintext = 'PLAINTEXT'
}

export type RescheduleLog = {
  __typename?: 'RescheduleLog';
  id: Scalars['uuid'];
  rescheduleReasonId: Scalars['uuid'];
  rescheduleReason?: Maybe<RescheduleReason>;
  note?: Maybe<Scalars['String']>;
  rescheduledAt: Scalars['datetime'];
  performedByEmployeeId: Scalars['uuid'];
  performedBy?: Maybe<Employee>;
  lastUpdatedByEmployeeId: Scalars['uuid'];
  lastUpdatedBy?: Maybe<Employee>;
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
};

export type RescheduleLogCreateInput = {
  taskId: Scalars['uuid'];
  rescheduleReasonId: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  performedByEmployeeId: Scalars['uuid'];
  rescheduledAt: Scalars['datetime'];
};

export type RescheduleLogDeleteInput = {
  id: Scalars['uuid'];
};

export type RescheduleLogResult = {
  __typename?: 'RescheduleLogResult';
  rescheduleLog?: Maybe<RescheduleLog>;
};

export type RescheduleLogUpdateInput = {
  id: Scalars['uuid'];
  rescheduleReasonId?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  performedByEmployeeId?: Maybe<Scalars['uuid']>;
  rescheduledAt?: Maybe<Scalars['datetime']>;
};

export type RescheduleLogsInput = {
  pageInput: PageInput;
};

export type RescheduleLogsResult = {
  __typename?: 'RescheduleLogsResult';
  items: Array<RescheduleLog>;
  nextCursor: Scalars['String'];
};

export type RescheduleReason = {
  __typename?: 'RescheduleReason';
  id: Scalars['uuid'];
  reason: Scalars['String'];
  type: Scalars['String'];
  creatorEmployeeId: Scalars['uuid'];
  creator?: Maybe<Employee>;
  lastUpdatedByEmployeeId: Scalars['uuid'];
  lastUpdatedBy?: Maybe<Employee>;
  archivedAt?: Maybe<Scalars['datetime']>;
  /**  can be null */
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
};

export type RescheduleReasonCreateInput = {
  reason: Scalars['String'];
};

export type RescheduleReasonUpdateInput = {
  id: Scalars['uuid'];
  reason?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type RescheduleReasonUpsertResult = {
  __typename?: 'RescheduleReasonUpsertResult';
  rescheduleReason?: Maybe<RescheduleReason>;
};

export type RescheduleReasonsInput = {
  prefix?: Maybe<Scalars['String']>;
  pageInput: PageInput;
  /**  This is for pagination */
  includeArchived?: Maybe<Scalars['Boolean']>;
  /**  optional to pull archived reasons */
  orderBy?: Maybe<RescheduleReasonsOrderBy>;
};

export type RescheduleReasonsOrderBy = {
  property: RescheduleReasonsOrderByProperty;
  direction: Sort;
};

export enum RescheduleReasonsOrderByProperty {
  Reason = 'REASON',
  Status = 'STATUS'
}

export type RescheduleReasonsResult = {
  __typename?: 'RescheduleReasonsResult';
  items: Array<RescheduleReason>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type ResetJobStageToAtsInterviewPlanInput = {
  jobStageId: Scalars['uuid'];
};

export enum ResponseStatus {
  NeedsAction = 'NEEDS_ACTION',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Tentative = 'TENTATIVE',
  Missed = 'MISSED'
}

export enum Result {
  Success = 'SUCCESS',
  Failure = 'FAILURE'
}

/** ########################################################## */
export type ScheduleCancelInput = {
  applicationStageId: Scalars['uuid'];
  deleteType?: Maybe<DeleteType>;
  isReschedule?: Maybe<Scalars['Boolean']>;
  candidateCancellationReason?: Maybe<CandidateCancellationReason>;
  candidateCancellationNote?: Maybe<Scalars['String']>;
};

export enum ScheduleCandidateEventSplitType {
  OneEventPerDay = 'ONE_EVENT_PER_DAY',
  OneEventPerBlock = 'ONE_EVENT_PER_BLOCK',
  OneEventPerInterview = 'ONE_EVENT_PER_INTERVIEW'
}

export type ScheduleCreateInput = {
  interviewerCalendarId: Scalars['String'];
  candidateCalendarId?: Maybe<Scalars['String']>;
  /**  can be empty */
  candidateEmailContent?: Maybe<CandidateEmailContentInput>;
  candidateEventContent?: Maybe<CandidateEventContentInput>;
  slackChannelContent?: Maybe<SlackChannelContentInput>;
  candidateTimezone?: Maybe<Scalars['timezone']>;
  /**  can be empty */
  interviewerContents?: Maybe<Array<InterviewerContentInput>>;
  isPrivateCalendarEvent: Scalars['Boolean'];
  applicationStageId: Scalars['uuid'];
  taskId: Scalars['uuid'];
};

/** ########################################################## */
export type ScheduleCreateResult = {
  __typename?: 'ScheduleCreateResult';
  applicationStageId: Scalars['uuid'];
  debriefRescheduleDetails: DebriefRescheduleDetails;
};

export type ScheduleOption = {
  __typename?: 'ScheduleOption';
  id: Scalars['uuid'];
  jobStageId: Scalars['uuid'];
  events: Array<InterviewEvent>;
};

export type ScheduleOptionModuleSeatInput = {
  interviewModuleId: Scalars['uuid'];
  selectedTrainedInterviewerIds?: Maybe<Array<Scalars['uuid']>>;
  selectedReverseShadowInterviewerIds?: Maybe<Array<Scalars['uuid']>>;
  selectedShadowInterviewerIds?: Maybe<Array<Scalars['uuid']>>;
  attributes?: Maybe<Array<ScheduleOptionSeatAttributeInput>>;
};

export type ScheduleOptionSeatAttributeInput = {
  attributeNameId: Scalars['uuid'];
  attributeTagValueId: Scalars['uuid'];
};

export type ScheduleOptionSeatInput = {
  id: Scalars['uuid'];
  moduleSeat?: Maybe<ScheduleOptionModuleSeatInput>;
  linkedSeat?: Maybe<LinkedSeatInput>;
  freeformSeat?: Maybe<FreeformSeatInput>;
};

export type ScheduleOptionsInput = {
  jobStageId: Scalars['uuid'];
  groups: Array<InterviewGroupInput>;
  availabilities: Array<DateTimeRangeInput>;
  candidateTimezone: Scalars['timezone'];
  excludedInterviewIds?: Maybe<Array<Scalars['uuid']>>;
  hasBreaks: Scalars['Boolean'];
  hasMultiDay: Scalars['Boolean'];
  numberOfDays: Scalars['Int'];
  throwOnZero: Scalars['Boolean'];
  hardConflicts?: Maybe<Array<HardConflictInput>>;
  applicationId?: Maybe<Scalars['uuid']>;
};

export type ScheduleOptionsResult = {
  __typename?: 'ScheduleOptionsResult';
  configOverride: ConfigOverride;
  scheduleOptions: Array<ScheduleOption>;
};

export type ScheduleRescheduleInput = {
  interviewerCalendarId: Scalars['String'];
  candidateCalendarId?: Maybe<Scalars['String']>;
  /**  can be empty */
  candidateEmailContent?: Maybe<CandidateEmailContentInput>;
  candidateEventContent?: Maybe<CandidateEventContentInput>;
  slackChannelContent?: Maybe<SlackChannelContentInput>;
  candidateTimezone?: Maybe<Scalars['timezone']>;
  /**  can be empty */
  interviewerContents?: Maybe<Array<InterviewerContentInput>>;
  isPrivateCalendarEvent: Scalars['Boolean'];
  applicationStageId: Scalars['uuid'];
  taskId: Scalars['uuid'];
};

export type ScheduleRescheduleResult = {
  __typename?: 'ScheduleRescheduleResult';
  applicationStageId: Scalars['uuid'];
  debriefRescheduleDetails: DebriefRescheduleDetails;
};

export type ScheduleStageFilters = {
  schedulerIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  jobIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
  jobStageIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
};

export type ScheduleStageMetrics = {
  __typename?: 'ScheduleStageMetrics';
  id?: Maybe<Scalars['String']>;
  /**  This can be mloop, lever or gh id hence string as gh is not uuid */
  job?: Maybe<Scalars['String']>;
  jobStage?: Maybe<Scalars['String']>;
  candidate?: Maybe<Scalars['String']>;
  scheduler?: Maybe<Scalars['String']>;
  scheduleStageDate?: Maybe<Scalars['datetime']>;
  schedulerId?: Maybe<Scalars['uuid']>;
  jobId?: Maybe<Scalars['uuid']>;
  jobStageId?: Maybe<Scalars['uuid']>;
  atsJobId?: Maybe<Scalars['String']>;
};

export enum ScheduleTaskColumnType {
  Candidate = 'CANDIDATE',
  Asignee = 'ASIGNEE',
  Creator = 'CREATOR',
  Job = 'JOB',
  JobStage = 'JOB_STAGE',
  Status = 'STATUS',
  CreatedAt = 'CREATED_AT',
  LastActivityAt = 'LAST_ACTIVITY_AT',
  TaskFlags = 'TASK_FLAGS',
  TaskTags = 'TASK_TAGS',
  ScheduleStartAt = 'SCHEDULE_START_AT',
  Urgent = 'URGENT',
  ApplicationStatus = 'APPLICATION_STATUS',
  Queue = 'QUEUE'
}

export type ScorecardMapping = {
  __typename?: 'ScorecardMapping';
  targetAtsId?: Maybe<Scalars['String']>;
  sourceName: Scalars['String'];
  sourceAtsId: Scalars['String'];
  atsType: AtsType;
  sourceAtsInterviewDefinition: AtsInterviewDefinition;
  jobStageInterviews?: Maybe<Array<JobStageInterview>>;
};

export type ScorecardMappingInput = {
  targetAtsId?: Maybe<Scalars['String']>;
  sourceAtsId: Scalars['String'];
  doNotImportInterviewsWithScorecard?: Maybe<Scalars['Boolean']>;
  importInterviewsWithoutScorecard?: Maybe<Scalars['Boolean']>;
};

export enum SelfScheduleLocation {
  None = 'NONE',
  Zoom = 'ZOOM',
  Google = 'GOOGLE',
  Phone = 'PHONE',
  Custom = 'CUSTOM',
  MicrosoftTeams = 'MICROSOFT_TEAMS'
}

export enum SelfScheduleMeetingHost {
  Interviewer = 'INTERVIEWER',
  Scheduler = 'SCHEDULER',
  CustomMeetingHost = 'CUSTOM_MEETING_HOST'
}

export type SelfScheduleNotificationPreference = {
  __typename?: 'SelfScheduleNotificationPreference';
  employeeIds?: Maybe<Array<Scalars['uuid']>>;
  externalEmailAddresses?: Maybe<Array<Scalars['String']>>;
};

/** ## NOTE: This input is deprecated, do not use! */
export type SelfScheduleNotificationPreferenceInput = {
  employeeIds?: Maybe<Array<Scalars['uuid']>>;
  externalEmailAddresses?: Maybe<Array<Scalars['String']>>;
};

export type SelfScheduleOptionSettings = {
  __typename?: 'SelfScheduleOptionSettings';
  numberOfDays?: Maybe<Scalars['numeric']>;
  advanceNoticeInHours?: Maybe<Scalars['numeric']>;
  rollingDays?: Maybe<Scalars['numeric']>;
  candidateCalendarId?: Maybe<Scalars['String']>;
  interviewerCalendarId?: Maybe<Scalars['String']>;
  location?: Maybe<SelfScheduleLocation>;
  zoomHost?: Maybe<SelfScheduleZoomHost>;
  isPrivateCalendarEvent?: Maybe<Scalars['Boolean']>;
  zoomHostUserId?: Maybe<Scalars['String']>;
  customLocation?: Maybe<Scalars['String']>;
  shouldRespectLoadLimit?: Maybe<Scalars['Boolean']>;
  canScheduleOverRecruitingKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverAvailableKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverFreeTime?: Maybe<Scalars['Boolean']>;
  candidateNote?: Maybe<Scalars['String']>;
  ccRecipients?: Maybe<NotificationPreference>;
  bccRecipients?: Maybe<NotificationPreference>;
  useRollingDays?: Maybe<Scalars['Boolean']>;
  meetingHost?: Maybe<SelfScheduleMeetingHost>;
  videoMeetingHostEmployeeId?: Maybe<Scalars['uuid']>;
};

/**  mimics the db object */
export type SelfScheduleOptions = {
  __typename?: 'SelfScheduleOptions';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  options?: Maybe<Options>;
  jobStageId: Scalars['uuid'];
  applicationId: Scalars['uuid'];
  selfScheduleEmailTemplateId?: Maybe<Scalars['uuid']>;
  creatorEmployeeId?: Maybe<Scalars['uuid']>;
  lastUpdatedByEmployeeId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['datetime']>;
  updatedAt?: Maybe<Scalars['datetime']>;
  deletedAt?: Maybe<Scalars['datetime']>;
  rescheduledAt?: Maybe<Scalars['datetime']>;
  candidateEmailTemplateId?: Maybe<Scalars['uuid']>;
  candidateEventTemplateId?: Maybe<Scalars['uuid']>;
  interviewerEventTemplateId?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
  selfScheduleEmailSentAt?: Maybe<Scalars['datetime']>;
  interviewMeetingLocationSetting?: Maybe<InterviewMeetingLocationSettingPref>;
};

export type SelfScheduleOptionsCount = {
  __typename?: 'SelfScheduleOptionsCount';
  timeRangeCounts: Array<Maybe<TimeRangeCount>>;
  employeeMissingZoomUserId?: Maybe<Array<Scalars['uuid']>>;
};

export type SelfScheduleOptionsCountInput = {
  customJobStageId?: Maybe<Scalars['uuid']>;
  timeRange: DateTimeRangeInput;
  customInterviewPlan?: Maybe<Array<JobStageInterviewGroupInput>>;
  applicationId?: Maybe<Scalars['uuid']>;
  /**  Defaults to 15 minutes */
  stepDurationMinutes?: Maybe<Scalars['numeric']>;
  validateZoomHost?: Maybe<Scalars['Boolean']>;
  includeEmptyRanges?: Maybe<Scalars['Boolean']>;
  /**  How much advance notice do you want to give? */
  advanceNoticeHours?: Maybe<Scalars['numeric']>;
  /**  Default true. Do we want to respect interviewer load limit or not? */
  shouldRespectLoadLimit?: Maybe<Scalars['Boolean']>;
  /**  Default false. Do we want to allow scheduling over recruiting keywords? */
  canScheduleOverRecruitingKeywords?: Maybe<Scalars['Boolean']>;
  /**  Default false. Do we want to allow scheduling over available keywords? */
  canScheduleOverAvailableKeywords?: Maybe<Scalars['Boolean']>;
  /**  Default true. Do we want to allow scheduling over free time? */
  canScheduleOverFreeTime?: Maybe<Scalars['Boolean']>;
};

/**  delete */
export type SelfScheduleOptionsDeleteInput = {
  __typename?: 'SelfScheduleOptionsDeleteInput';
  id: Scalars['uuid'];
};

export type SelfScheduleOptionsInput = {
  id: Scalars['uuid'];
};

export type SelfScheduleOptionsSettingInput = {
  numberOfDays?: Maybe<Scalars['numeric']>;
  advanceNoticeInHours?: Maybe<Scalars['numeric']>;
  rollingDays?: Maybe<Scalars['numeric']>;
  candidateCalendarId?: Maybe<Scalars['String']>;
  interviewerCalendarId?: Maybe<Scalars['String']>;
  location?: Maybe<SelfScheduleLocation>;
  zoomHost?: Maybe<SelfScheduleZoomHost>;
  isPrivateCalendarEvent?: Maybe<Scalars['Boolean']>;
  zoomHostUserId?: Maybe<Scalars['String']>;
  customLocation?: Maybe<Scalars['String']>;
  shouldRespectLoadLimit?: Maybe<Scalars['Boolean']>;
  canScheduleOverRecruitingKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverAvailableKeywords?: Maybe<Scalars['Boolean']>;
  canScheduleOverFreeTime?: Maybe<Scalars['Boolean']>;
  candidateNote?: Maybe<Scalars['String']>;
  ccRecipients?: Maybe<NotificationPreferenceInput>;
  bccRecipients?: Maybe<NotificationPreferenceInput>;
  useRollingDays?: Maybe<Scalars['Boolean']>;
  meetingHost?: Maybe<SelfScheduleMeetingHost>;
  videoMeetingHostEmployeeId?: Maybe<Scalars['uuid']>;
};

/** upsert */
export type SelfScheduleOptionsUpsertInput = {
  options: OptionsInput;
  candidateEmailContent?: Maybe<CandidateEmailContentInput>;
  candidateEmailTemplateId?: Maybe<Scalars['uuid']>;
  candidateEventTemplateId?: Maybe<Scalars['uuid']>;
  interviewerEventTemplateId?: Maybe<Scalars['uuid']>;
  taskId: Scalars['uuid'];
  interviewMeetingLocationSetting?: Maybe<InterviewMeetingLocationSettingsInput>;
};

export enum SelfScheduleZoomHost {
  NotSet = 'NOT_SET',
  Interviewer = 'INTERVIEWER',
  Scheduler = 'SCHEDULER',
  CustomZoomHost = 'CUSTOM_ZOOM_HOST'
}

export type SettingBoolValue = {
  __typename?: 'SettingBoolValue';
  value: Scalars['Boolean'];
  level: SettingLevel;
};

export type SettingCalendarId = {
  __typename?: 'SettingCalendarId';
  remoteCalendarId: Scalars['String'];
  remoteCalendar?: Maybe<Calendar>;
  level: SettingLevel;
};

export enum SettingLevel {
  Org = 'ORG',
  Employee = 'EMPLOYEE',
  Job = 'JOB',
  JobStage = 'JOB_STAGE'
}

export type SettingTemplateId = {
  __typename?: 'SettingTemplateId';
  templateId: Scalars['uuid'];
  template?: Maybe<Template>;
  level: SettingLevel;
};

/** ########################################## */
export type ShortUrlCreateInput = {
  longUrl: Scalars['String'];
};

export type ShortUrlCreateResult = {
  __typename?: 'ShortUrlCreateResult';
  shortUrl: Scalars['String'];
};

export type SlackChannel = {
  __typename?: 'SlackChannel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SlackChannelContentInput = {
  slackChannelInterviewerEmployeeIds: Array<Scalars['uuid']>;
  slackChannelRecruitingTeamEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
  slackChannelHiringManagerEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
  slackChannelName?: Maybe<Scalars['String']>;
  conversationId?: Maybe<Scalars['uuid']>;
  slackChannelId?: Maybe<Scalars['String']>;
  slackChannelMessageContent: Scalars['String'];
};

export type SlackEmployeeIntegration = {
  __typename?: 'SlackEmployeeIntegration';
  active: Scalars['Boolean'];
  slackTeamId: Scalars['String'];
  slackUserId: Scalars['String'];
  scopes: Array<Scalars['String']>;
};

export type SlackIntegrationHealth = {
  __typename?: 'SlackIntegrationHealth';
  isInstalled: Scalars['Boolean'];
  isHealthy: Scalars['Boolean'];
};

export type SlackOrgIntegration = {
  __typename?: 'SlackOrgIntegration';
  active: Scalars['Boolean'];
  slackTeamId: Scalars['String'];
};

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortFilter {
  LastActivityDesc = 'LAST_ACTIVITY_DESC',
  LastActivityAsc = 'LAST_ACTIVITY_ASC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  StageAsc = 'STAGE_ASC',
  StageDesc = 'STAGE_DESC'
}

export enum SourceType {
  SourceTypeTask = 'SOURCE_TYPE_TASK',
  SourceTypeApplication = 'SOURCE_TYPE_APPLICATION',
  SourceTypeTaskQueue = 'SOURCE_TYPE_TASK_QUEUE',
  SourceTypeCandidate = 'SOURCE_TYPE_CANDIDATE'
}

export type SourcingLink = {
  __typename?: 'SourcingLink';
  id: Scalars['uuid'];
  orgId: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  createdBy?: Maybe<Employee>;
  updatedBy?: Maybe<Employee>;
  initialJobStageId: Scalars['uuid'];
  initialJobStage?: Maybe<JobStage>;
  taskAssigneeId: Scalars['uuid'];
  taskAssignee?: Maybe<Employee>;
  taskSubscriberIds: Array<Scalars['uuid']>;
  taskSubscribers: Array<Employee>;
  name: Scalars['String'];
  recruitingSource?: Maybe<Scalars['String']>;
  candidateRecruiterId?: Maybe<Scalars['uuid']>;
  candidateRecruiter?: Maybe<Employee>;
  candidateCoordinatorId?: Maybe<Scalars['uuid']>;
  candidateCoordinator?: Maybe<Employee>;
  candidateSourcerId?: Maybe<Scalars['uuid']>;
  candidateSourcer?: Maybe<Employee>;
  taskAction: SourcingLinkTaskAction;
  deactivatedAt?: Maybe<Scalars['datetime']>;
  interviewPlanJobStageId: Scalars['uuid'];
  interviewPlanJobStage?: Maybe<JobStage>;
};

export type SourcingLinkCreateInput = {
  initialJobStageId: Scalars['uuid'];
  name: Scalars['String'];
  taskAction: SourcingLinkTaskAction;
  candidateRecruiterId?: Maybe<Scalars['uuid']>;
  candidateCoordinatorId?: Maybe<Scalars['uuid']>;
  candidateSourcerId?: Maybe<Scalars['uuid']>;
  taskAssigneeId?: Maybe<Scalars['uuid']>;
  taskSubscriberIds?: Maybe<Array<Scalars['uuid']>>;
  recruitingSource?: Maybe<Scalars['String']>;
  jobStageInterviewGroups?: Maybe<Array<JobStageInterviewGroupInput>>;
  interviewPlan?: Maybe<InterviewPlanInput>;
};

export type SourcingLinkResult = {
  __typename?: 'SourcingLinkResult';
  sourcingLink: SourcingLink;
};

export enum SourcingLinkTaskAction {
  SelfSchedule = 'SELF_SCHEDULE',
  Availability = 'AVAILABILITY'
}

/**  Pass null to remove the value. Don't pass the field to keep the value unchanged. */
export type SourcingLinkUpdateInput = {
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  candidateRecruiterId?: Maybe<Scalars['uuid']>;
  candidateCoordinatorId?: Maybe<Scalars['uuid']>;
  candidateSourcerId?: Maybe<Scalars['uuid']>;
  taskAssigneeId?: Maybe<Scalars['uuid']>;
  taskSubscriberIds?: Maybe<Array<Scalars['uuid']>>;
  initialJobStageId?: Maybe<Scalars['uuid']>;
  recruitingSource?: Maybe<Scalars['String']>;
  taskAction?: Maybe<SourcingLinkTaskAction>;
  jobStageInterviewGroups?: Maybe<Array<JobStageInterviewGroupInput>>;
  isActive?: Maybe<Scalars['Boolean']>;
  interviewPlan?: Maybe<InterviewPlanInput>;
};

export enum StatusFilter {
  Schedulable = 'SCHEDULABLE',
  NotSchedulable = 'NOT_SCHEDULABLE',
  Unscheduled = 'UNSCHEDULED',
  AvailabilityNotRequested = 'AVAILABILITY_NOT_REQUESTED',
  AvailabilityRequested = 'AVAILABILITY_REQUESTED',
  ReadyToSchedule = 'READY_TO_SCHEDULE',
  ScheduledInAts = 'SCHEDULED_IN_ATS',
  Canceled = 'CANCELED',
  Ongoing = 'ONGOING',
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED',
  CompletedInAts = 'COMPLETED_IN_ATS',
  CandidateDeclined = 'CANDIDATE_DECLINED',
  InterviewerDeclined = 'INTERVIEWER_DECLINED',
  SelfScheduleNotRequested = 'SELF_SCHEDULE_NOT_REQUESTED',
  SelfScheduleRequested = 'SELF_SCHEDULE_REQUESTED',
  HoldForRsvp = 'HOLD_FOR_RSVP',
  ReadyForCandidateComms = 'READY_FOR_CANDIDATE_COMMS'
}

export type StatusFilterGroup = {
  statusFilters?: Maybe<Array<Maybe<StatusFilter>>>;
};

export enum StringArrayEmployeePref {
  ArrRecentlyUsedTimezones = 'arr_recently_used_timezones',
  ArrDoNotShowAgainModals = 'arr_do_not_show_again_modals',
  ArrAvailableKeywords = 'arr_available_keywords',
  ArrAvoidKeywords = 'arr_avoid_keywords',
  ArrOutOfOfficeKeywords = 'arr_out_of_office_keywords',
  ArrRecruitingBlockKeywords = 'arr_recruiting_block_keywords'
}

export type StringArrayEmployeePrefValue = EmployeePrefValue & {
  __typename?: 'StringArrayEmployeePrefValue';
  id: Scalars['String'];
  prefName: Scalars['String'];
  level: PrefLevel;
  /**  The array is declared as nullable because if both org and employee pref is not set, array will be null. */
  value?: Maybe<Array<Scalars['String']>>;
};

export enum StringArrayOrgPref {
  ArrOutlookSharedCalendarOwnerIds = 'arr_outlook_shared_calendar_owner_ids',
  ArrSoftConflictPhrases = 'arr_soft_conflict_phrases',
  ArrHardConflictPhrases = 'arr_hard_conflict_phrases',
  ArrRecruitingBlockPhrases = 'arr_recruiting_block_phrases',
  ArrOrgInternalMeetingDomains = 'arr_org_internal_meeting_domains',
  ArrOutOfOfficePhrases = 'arr_out_of_office_phrases'
}

export enum StringEmployeePref {
  StrDefaultCandidateCalendar = 'str_default_candidate_calendar',
  StrDefaultInterviewerCalendar = 'str_default_interviewer_calendar',
  StrInternalInterviewerNote = 'str_internal_interviewer_note',
  StrSignature = 'str_signature',
  StrCandidateEmailConfirmationTemplateId = 'str_candidate_email_confirmation_template_id',
  StrCandidateCalendarInviteTemplateId = 'str_candidate_calendar_invite_template_id',
  StrInterviewerCalendarInviteTemplateId = 'str_interviewer_calendar_invite_template_id',
  StrInterviewerConversationMessageTemplateId = 'str_interviewer_conversation_message_template_id',
  StrPronouns = 'str_pronouns',
  StrBio = 'str_bio'
}

export type StringEmployeePrefValue = EmployeePrefValue & {
  __typename?: 'StringEmployeePrefValue';
  id: Scalars['String'];
  prefName: Scalars['String'];
  level: PrefLevel;
  value?: Maybe<Scalars['String']>;
};

export enum StringOrgPref {
  StrScheduleCandidateEventSplit = 'str_schedule_candidate_event_split',
  StrDefaultTaskComment = 'str_default_task_comment',
  StrSlackChannelNameFormat = 'str_slack_channel_name_format',
  StrDefaultCandidateCalendar = 'str_default_candidate_calendar',
  StrDefaultInterviewerCalendar = 'str_default_interviewer_calendar',
  StrCsmName = 'str_csm_name',
  StrTaskDefaultAssigneeAvailabilityRequests = 'str_task_default_assignee_availability_requests',
  StrTaskDefaultAssigneeRequestToSchedule = 'str_task_default_assignee_request_to_schedule',
  StrTaskDefaultAssigneeSelfScheduleRequests = 'str_task_default_assignee_self_schedule_requests',
  StrDefaultInterviewPlanBreakType = 'str_default_interview_plan_break_type',
  StrArCandidateNote = 'str_ar_candidate_note',
  StrSsrCandidateNote = 'str_ssr_candidate_note'
}

export type SubscriberAddInput = {
  sourceId: Scalars['uuid'];
  sourceType?: Maybe<SourceType>;
  employeeIds: Array<Scalars['uuid']>;
};

export type SubscriberAddResult = {
  __typename?: 'SubscriberAddResult';
  result?: Maybe<Result>;
  /**  SUCCESS | FAILURE */
  subscribers: Array<Employee>;
};

export type SubscriberRemoveInput = {
  sourceId: Scalars['uuid'];
  sourceType?: Maybe<SourceType>;
  employeeIds: Array<Scalars['uuid']>;
};

export type SubscriberRemoveResult = {
  __typename?: 'SubscriberRemoveResult';
  result?: Maybe<Result>;
  /**  SUCCESS | FAILURE */
  subscribers: Array<Employee>;
};

export type SubscribersInput = {
  pageInput: PageInput;
  sourceId: Scalars['uuid'];
  sourceType?: Maybe<SourceType>;
  searchText?: Maybe<Scalars['String']>;
};

export type SubscribersResult = {
  __typename?: 'SubscribersResult';
  cursor: Scalars['String'];
  items: Array<Employee>;
};

export type SuggestedTimeRangesCountInput = {
  /**  exactly one of the following must be provided */
  taskId?: Maybe<Scalars['uuid']>;
  customInterviewPlan?: Maybe<Array<JobStageInterviewGroupInput>>;
  interviewPlan?: Maybe<InterviewPlanInput>;
  applicationId?: Maybe<Scalars['uuid']>;
  timeRange: DateTimeRangeInput;
  advanceNoticeHours: Scalars['numeric'];
  stepDurationMinutes?: Maybe<Scalars['numeric']>;
  validateZoomHost: Scalars['Boolean'];
  includeEmptyRanges: Scalars['Boolean'];
  shouldRespectLoadLimit: Scalars['Boolean'];
  canScheduleOverRecruitingKeywords: Scalars['Boolean'];
  canScheduleOverAvailableKeywords: Scalars['Boolean'];
  canScheduleOverFreeTime: Scalars['Boolean'];
  requestType?: Maybe<SuggestedTimeRangesRequestType>;
};

export type SuggestedTimeRangesCountOutput = {
  __typename?: 'SuggestedTimeRangesCountOutput';
  timeRangeCounts: Array<TimeRangeCount>;
  employeeMissingZoomUserId: Array<Scalars['uuid']>;
};

export enum SuggestedTimeRangesRequestType {
  SelfScheduleRequest = 'SELF_SCHEDULE_REQUEST',
  AvailabilityRequest = 'AVAILABILITY_REQUEST'
}

export type Task = {
  __typename?: 'Task';
  id: Scalars['uuid'];
  /**  number of task for this application + job stage */
  count?: Maybe<Scalars['numeric']>;
  status: TaskStatus;
  assignee?: Maybe<Employee>;
  createdBy: Employee;
  /**  Created time */
  createdAt: Scalars['datetime'];
  /**  Canceled time */
  canceledAt?: Maybe<Scalars['datetime']>;
  /**  Tags */
  tags: Array<TaskTag>;
  /**  Flags */
  flags: Array<TaskFlag>;
  /**  Whether or not the task was marked as urgent */
  isUrgent: Scalars['Boolean'];
  /**  Whether or not the task needs a debrief */
  isDebriefRequired: Scalars['Boolean'];
  /**  Recruiter selected stage when making the task */
  stage: JobStage;
  /** deprecated, use job stage instead */
  jobStage: JobStage;
  /**  Each task should have a custom job stage */
  interviewPlan: JobStage;
  /**
   *  The availability request if it exists
   * @deprecated Field no longer supported
   */
  availabilityRequest?: Maybe<CandidateAvailabilityOptions>;
  /**
   *  The availability provided by recruiting or candidate
   * @deprecated Field no longer supported
   */
  availability?: Maybe<CandidateAvailability>;
  /**
   *  The self schedule request if it exists
   * @deprecated Field no longer supported
   */
  selfScheduleRequest?: Maybe<SelfScheduleOptions>;
  /**  The schedules associated to this task */
  schedule?: Maybe<ApplicationStage>;
  schedules: Array<ApplicationStage>;
  /**
   *  The single debrief associated to this task
   * @deprecated Field no longer supported
   */
  debrief?: Maybe<ApplicationDebrief>;
  application: Application;
  creationSource: TaskCreationSource;
  /**  will be added later */
  activityLogs: ActivityLogsResult;
  /**  activityLog(input): PageableActivityItem! */
  name: Scalars['String'];
  /**  The availability requests */
  availabilityRequests: Array<CandidateAvailabilityOptions>;
  /**  The availabilities */
  availabilities: Array<CandidateAvailability>;
  /**  The self schedule requests */
  selfScheduleRequests: Array<SelfScheduleOptions>;
  /**  Debriefs */
  debriefs: Array<ApplicationDebrief>;
  rescheduleLogs: RescheduleLogsResult;
  lastActivityAt: Scalars['datetime'];
  taskQueue?: Maybe<TaskQueue>;
  sourcingLink?: Maybe<SourcingLink>;
};


export type TaskActivityLogsArgs = {
  input: ActivityLogsForSourceInput;
};


export type TaskRescheduleLogsArgs = {
  input: RescheduleLogsInput;
};

export type TaskAssigneesInput = {
  showUnassigned?: Maybe<Scalars['Boolean']>;
  assigneeIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
};

export type TaskCancelInput = {
  taskId: Scalars['uuid'];
};

export type TaskCreateInput = {
  applicationId: Scalars['uuid'];
  jobStageId: Scalars['uuid'];
  assigneeEmployeeId?: Maybe<Scalars['uuid']>;
  isUrgent: Scalars['Boolean'];
  isDebriefRequired: Scalars['Boolean'];
  creationSource: TaskCreationSource;
  customInterviewPlan?: Maybe<Array<JobStageInterviewGroupInput>>;
  note?: Maybe<Scalars['String']>;
  availability?: Maybe<Array<DateTimeRangeInput>>;
  customJobStageId: Scalars['uuid'];
  candidateTimezone: Scalars['timezone'];
  tagIds?: Maybe<Array<Scalars['uuid']>>;
  subscriberEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
  taskQueueId?: Maybe<Scalars['uuid']>;
  interviewPlan?: Maybe<InterviewPlanInput>;
};

export type TaskCreateResult = {
  __typename?: 'TaskCreateResult';
  task: Task;
};

export enum TaskCreationSource {
  AssignTask = 'ASSIGN_TASK',
  CandidateAvailabilityRequest = 'CANDIDATE_AVAILABILITY_REQUEST',
  SelfScheduleRequest = 'SELF_SCHEDULE_REQUEST',
  ScheduleNow = 'SCHEDULE_NOW',
  Migration = 'MIGRATION'
}

export enum TaskDefaultAssignee {
  Unassigned = 'UNASSIGNED',
  CandidateCoordinator = 'CANDIDATE_COORDINATOR',
  CandidateRecruiter = 'CANDIDATE_RECRUITER',
  TaskCreator = 'TASK_CREATOR'
}

export type TaskFlag = {
  id: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  flagType: TaskFlagType;
  actorType: TaskFlagActorType;
  actorEmployee?: Maybe<Employee>;
  resolvedAt?: Maybe<Scalars['datetime']>;
  resolvedBy?: Maybe<Employee>;
  task: Task;
};

export enum TaskFlagActorType {
  System = 'SYSTEM',
  Candidate = 'CANDIDATE',
  Employee = 'EMPLOYEE'
}

export type TaskFlagCandidateDeclined = TaskFlag & {
  __typename?: 'TaskFlagCandidateDeclined';
  id: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  flagType: TaskFlagType;
  actorType: TaskFlagActorType;
  actorEmployee?: Maybe<Employee>;
  resolvedAt?: Maybe<Scalars['datetime']>;
  resolvedBy?: Maybe<Employee>;
  declinedCandidateEvent: ApplicationStageCandidateEvent;
  task: Task;
};

export type TaskFlagInterviewerDebriefDeclined = TaskFlag & {
  __typename?: 'TaskFlagInterviewerDebriefDeclined';
  id: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  flagType: TaskFlagType;
  actorType: TaskFlagActorType;
  actorEmployee?: Maybe<Employee>;
  resolvedAt?: Maybe<Scalars['datetime']>;
  resolvedBy?: Maybe<Employee>;
  declinedDebrief: ApplicationDebrief;
  task: Task;
};

export type TaskFlagInterviewerDeclined = TaskFlag & {
  __typename?: 'TaskFlagInterviewerDeclined';
  id: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  flagType: TaskFlagType;
  actorType: TaskFlagActorType;
  actorEmployee?: Maybe<Employee>;
  resolvedAt?: Maybe<Scalars['datetime']>;
  resolvedBy?: Maybe<Employee>;
  declinedInterview: ApplicationStageInterview;
  task: Task;
};

export type TaskFlagMarkResolvedInput = {
  taskFlagId: Scalars['uuid'];
};

export type TaskFlagMarkResolvedResult = {
  __typename?: 'TaskFlagMarkResolvedResult';
  taskFlag: TaskFlag;
};

export type TaskFlagRequestedChange = TaskFlag & {
  __typename?: 'TaskFlagRequestedChange';
  id: Scalars['uuid'];
  createdAt: Scalars['datetime'];
  flagType: TaskFlagType;
  actorType: TaskFlagActorType;
  actorEmployee?: Maybe<Employee>;
  resolvedAt?: Maybe<Scalars['datetime']>;
  resolvedBy?: Maybe<Employee>;
  note?: Maybe<Scalars['String']>;
  task: Task;
};

export enum TaskFlagType {
  CandidateDeclined = 'CANDIDATE_DECLINED',
  InterviewDeclined = 'INTERVIEW_DECLINED',
  DebriefDeclined = 'DEBRIEF_DECLINED',
  RequestChange = 'REQUEST_CHANGE'
}

export type TaskFlagsInput = {
  showWithoutFlags?: Maybe<Scalars['Boolean']>;
  taskFlags?: Maybe<Array<TaskFlagType>>;
};

export type TaskQueue = {
  __typename?: 'TaskQueue';
  id: Scalars['uuid'];
  name: Scalars['String'];
  taskCount: Scalars['Int'];
  memberCount: Scalars['Int'];
  createdBy: Employee;
  lastUpdatedBy: Employee;
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
};

export type TaskQueueCreateInput = {
  name: Scalars['String'];
  employeeIds?: Maybe<Array<Scalars['uuid']>>;
};

export type TaskQueueCreateOutput = {
  __typename?: 'TaskQueueCreateOutput';
  taskQueue: TaskQueue;
};

export type TaskQueueDeleteInput = {
  id: Scalars['uuid'];
};

export type TaskQueueDeleteResult = {
  __typename?: 'TaskQueueDeleteResult';
  result: Result;
};

export type TaskQueueSearchInput = {
  pageInput: PageInput;
  orderBy?: Maybe<TaskQueuesOrderBy>;
  search?: Maybe<Scalars['String']>;
  memberEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
};

export type TaskQueueSearchResult = {
  __typename?: 'TaskQueueSearchResult';
  items: Array<TaskQueue>;
  total: Scalars['Int'];
  hasNext: Scalars['Boolean'];
};

export type TaskQueueUpdateInput = {
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  employeeIds?: Maybe<Array<Scalars['uuid']>>;
};

export type TaskQueueUpdateOutput = {
  __typename?: 'TaskQueueUpdateOutput';
  taskQueue: TaskQueue;
};

export type TaskQueuesFilterInput = {
  showUnassigned?: Maybe<Scalars['Boolean']>;
  taskQueueIds?: Maybe<Array<Maybe<Scalars['uuid']>>>;
};

export type TaskQueuesInput = {
  page: PageInput;
  orderBy?: Maybe<TaskQueuesOrderBy>;
  name?: Maybe<Scalars['String']>;
};

export type TaskQueuesOrderBy = {
  property: TaskQueuesOrderByProperty;
  direction: Sort;
};

export enum TaskQueuesOrderByProperty {
  Name = 'NAME',
  MemberCount = 'MEMBER_COUNT',
  TaskCount = 'TASK_COUNT'
}

export type TaskQueuesResult = {
  __typename?: 'TaskQueuesResult';
  items: Array<TaskQueue>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type TaskRequestChangeInput = {
  taskId: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
};

export type TaskRequestChangeResult = {
  __typename?: 'TaskRequestChangeResult';
  task: Task;
  taskFlag: TaskFlag;
};

export type TaskSearchInput = {
  pageInput: PageInput;
  orderBy?: Maybe<TaskSearchOrderBy>;
  candidateName?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<TaskStatus>>;
  showIsUrgent?: Maybe<Scalars['Boolean']>;
  jobIds?: Maybe<Array<Scalars['uuid']>>;
  jobStageNames?: Maybe<Array<Scalars['String']>>;
  taskTagIds?: Maybe<Array<Scalars['uuid']>>;
  assignees?: Maybe<TaskAssigneesInput>;
  subscribedByIds?: Maybe<Array<Scalars['uuid']>>;
  createdByIds?: Maybe<Array<Scalars['uuid']>>;
  flags?: Maybe<TaskFlagsInput>;
  filterCompletedCanceled?: Maybe<Scalars['Boolean']>;
  taskQueues?: Maybe<TaskQueuesFilterInput>;
  taskQueueMemberEmployeeIds?: Maybe<Array<Scalars['uuid']>>;
};

export type TaskSearchOrderBy = {
  property: TaskSearchOrderByProperty;
  order: Sort;
};

export enum TaskSearchOrderByProperty {
  CreatedAt = 'CREATED_AT',
  ApplicationStageMaxEndAt = 'APPLICATION_STAGE_MAX_END_AT'
}

export type TaskSearchResult = {
  __typename?: 'TaskSearchResult';
  items: Array<Task>;
  total: Scalars['Int'];
  hasNext: Scalars['Boolean'];
};

export type TaskSetAssigneeInput = {
  taskId: Scalars['uuid'];
  assigneeEmployeeId?: Maybe<Scalars['uuid']>;
};

export type TaskSetDebriefRequiredInput = {
  taskId: Scalars['uuid'];
  isDebriefRequired: Scalars['Boolean'];
};

export type TaskSetIsUrgentInput = {
  taskId: Scalars['uuid'];
  isUrgent: Scalars['Boolean'];
};

export type TaskSetTagsInput = {
  taskId: Scalars['uuid'];
  tagIds: Array<Scalars['uuid']>;
};

export type TaskSetTaskQueueInput = {
  taskId: Scalars['uuid'];
  taskQueueId?: Maybe<Scalars['uuid']>;
};

/**  Task query */
export enum TaskStatus {
  NeedsAvailability = 'NEEDS_AVAILABILITY',
  WaitingForCandidate = 'WAITING_FOR_CANDIDATE',
  ReadyToSchedule = 'READY_TO_SCHEDULE',
  ReadyToReschedule = 'READY_TO_RESCHEDULE',
  Hold = 'HOLD',
  ReadyToSendToCandidate = 'READY_TO_SEND_TO_CANDIDATE',
  Scheduled = 'SCHEDULED',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  NeedsDebrief = 'NEEDS_DEBRIEF'
}

export type TaskTag = {
  __typename?: 'TaskTag';
  id: Scalars['uuid'];
  name: Scalars['String'];
  taskCount: Scalars['Int'];
};

/**  Mutation */
export type TaskTagCreateInput = {
  name: Scalars['String'];
};

export type TaskTagCreateOutput = {
  __typename?: 'TaskTagCreateOutput';
  taskTag: TaskTag;
};

export type TaskTagDeleteInput = {
  id: Scalars['uuid'];
};

export type TaskTagDeleteOutput = {
  __typename?: 'TaskTagDeleteOutput';
  ok: Scalars['Boolean'];
};

export type TaskTagSetInput = {
  name?: Maybe<Scalars['String']>;
};

export type TaskTagUpdateInput = {
  taskTagId: Scalars['uuid'];
  set: TaskTagSetInput;
};

export type TaskTagUpdateOutput = {
  __typename?: 'TaskTagUpdateOutput';
  taskTag: TaskTag;
};

/**  Query */
export type TaskTagsInput = {
  page: PageInput;
  name?: Maybe<Scalars['String']>;
};

export type TaskTagsResult = {
  __typename?: 'TaskTagsResult';
  items: Array<TaskTag>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type TaskUpdateInput = {
  taskId: Scalars['uuid'];
  tagIds?: Maybe<Array<Scalars['uuid']>>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isDebriefRequired?: Maybe<Scalars['Boolean']>;
  isUrgent?: Maybe<Scalars['Boolean']>;
  assigneeEmployeeId?: Maybe<Scalars['uuid']>;
  /** To unset, explicitly set to null */
  taskQueueId?: Maybe<Scalars['uuid']>;
};

/** ########################################################## */
export type Template = {
  __typename?: 'Template';
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  isOrganizationDefault: Scalars['Boolean'];
  greenhouseEmailTemplateId?: Maybe<Scalars['numeric']>;
  attachments?: Maybe<Array<Attachment>>;
  type: TemplateType;
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  liveSettingsUsages: Scalars['numeric'];
};

/** ########################################################## */
export type TemplateCreateInput = {
  name: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  type: TemplateType;
  isOrganizationDefault?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<Array<AttachmentInput>>;
};

export type TemplateResult = {
  __typename?: 'TemplateResult';
  template?: Maybe<Template>;
};

export type TemplateToken = {
  __typename?: 'TemplateToken';
  id?: Maybe<Scalars['String']>;
  ME?: Maybe<Scalars['String']>;
  MY_FIRST_NAME?: Maybe<Scalars['String']>;
  MY_FULL_NAME?: Maybe<Scalars['String']>;
  MY_PHONE_NUMBER?: Maybe<Scalars['String']>;
  MY_SIGNATURE?: Maybe<Scalars['String']>;
  COMPANY?: Maybe<Scalars['String']>;
  COMPANY_NAME?: Maybe<Scalars['String']>;
  JOB_NAME?: Maybe<Scalars['String']>;
  JOB_LOCATION?: Maybe<Scalars['String']>;
  JOB_STAGE_NAME?: Maybe<Scalars['String']>;
  CANDIDATE_NAME?: Maybe<Scalars['String']>;
  RECRUITER?: Maybe<Scalars['String']>;
  RECRUITER_EMAIL?: Maybe<Scalars['String']>;
  COORDINATOR?: Maybe<Scalars['String']>;
  COORDINATOR_EMAIL?: Maybe<Scalars['String']>;
  SOURCER?: Maybe<Scalars['String']>;
  /** Expects candidate_id */
  CANDIDATE_SOURCER?: Maybe<Scalars['String']>;
  /** Expects candidate_id */
  CANDIDATE_SOURCER_EMAIL?: Maybe<Scalars['String']>;
  CANDIDATE_FIRST_NAME?: Maybe<Scalars['String']>;
  CANDIDATE_LAST_NAME?: Maybe<Scalars['String']>;
  CANDIDATE_LAST_INITIAL?: Maybe<Scalars['String']>;
  CANDIDATE_EMAIL_ADDRESS?: Maybe<Scalars['String']>;
  CANDIDATE_PHONE?: Maybe<Scalars['String']>;
  CANDIDATE_PRONOUNS?: Maybe<Scalars['String']>;
  CANDIDATE_LINKEDIN?: Maybe<Scalars['String']>;
  CANDIDATE_AVAILABILITY?: Maybe<Scalars['String']>;
  CANDIDATE_AVAILABILITY_TIMEZONE?: Maybe<Scalars['String']>;
  CANDIDATE_AVAILABILITY_NOTE?: Maybe<Scalars['String']>;
  CANDIDATE_AVAILABILITY_URL?: Maybe<Scalars['String']>;
  CANDIDATE_LINK?: Maybe<Scalars['String']>;
  INTERVIEW_SCHEDULE?: Maybe<Scalars['String']>;
  INTERNAL_INTERVIEW_SCHEDULE?: Maybe<Scalars['String']>;
  INTERVIEW_SCHEDULE_START_TIME?: Maybe<Scalars['String']>;
  INTERVIEW_SCORECARD_NAME?: Maybe<Scalars['String']>;
  HIRING_MANAGER?: Maybe<Scalars['String']>;
  INTERVIEWER_NAMES?: Maybe<Scalars['String']>;
  INTERVIEW_NAME?: Maybe<Scalars['String']>;
  INTERVIEW_DAYS?: Maybe<Scalars['String']>;
  INTERVIEW_KIT_LINK?: Maybe<Scalars['String']>;
  VIDEO_MEETING_LINK?: Maybe<Scalars['String']>;
  ZOOM_MEETING_DIAL_IN_INFO?: Maybe<Scalars['String']>;
  ZOOM_MEETING_INFO?: Maybe<Scalars['String']>;
  DEBRIEF_SCHEDULE?: Maybe<Scalars['String']>;
  CODE_LINK?: Maybe<Scalars['String']>;
  SELF_SCHEDULE_LINK?: Maybe<Scalars['String']>;
  EXTERNAL_JOB_NAME?: Maybe<Scalars['String']>;
  INTERVIEWER_TRAINING_INFO?: Maybe<Scalars['String']>;
  INTERNAL_APPLICATION_NOTE?: Maybe<Scalars['String']>;
  TASK_CREATOR?: Maybe<Scalars['String']>;
  TASK_ASSIGNEE?: Maybe<Scalars['String']>;
  APPLICATION_CANDIDATE_PORTAL_URL?: Maybe<Scalars['String']>;
  SCHEDULE_LOCATION?: Maybe<Scalars['String']>;
};


export type TemplateTokenJob_NameArgs = {
  input: TokenJobNameInput;
};


export type TemplateTokenJob_LocationArgs = {
  input: TokenJobLocationInput;
};


export type TemplateTokenJob_Stage_NameArgs = {
  input: TokenJobStageNameInput;
};


export type TemplateTokenCandidate_NameArgs = {
  input: TokenCandidateInput;
};


export type TemplateTokenRecruiterArgs = {
  input: TokenCandidateInput;
};


export type TemplateTokenRecruiter_EmailArgs = {
  input: TokenCandidateInput;
};


export type TemplateTokenCoordinatorArgs = {
  input: TokenCandidateInput;
};


export type TemplateTokenCoordinator_EmailArgs = {
  input: TokenCandidateInput;
};


export type TemplateTokenSourcerArgs = {
  input: TokenApplicationInput;
};


export type TemplateTokenCandidate_SourcerArgs = {
  id: Scalars['uuid'];
};


export type TemplateTokenCandidate_Sourcer_EmailArgs = {
  id: Scalars['uuid'];
};


export type TemplateTokenCandidate_First_NameArgs = {
  input: TokenCandidateInput;
};


export type TemplateTokenCandidate_Last_NameArgs = {
  input: TokenCandidateInput;
};


export type TemplateTokenCandidate_Last_InitialArgs = {
  input: TokenCandidateInput;
};


export type TemplateTokenCandidate_Email_AddressArgs = {
  input: TokenCandidateInput;
};


export type TemplateTokenCandidate_PhoneArgs = {
  input: TokenCandidateInput;
};


export type TemplateTokenCandidate_PronounsArgs = {
  input: TokenCandidateInput;
};


export type TemplateTokenCandidate_LinkedinArgs = {
  input: TokenCandidateLinkedinInput;
};


export type TemplateTokenCandidate_AvailabilityArgs = {
  input: TokenCandidateAvailabilityInput;
};


export type TemplateTokenCandidate_Availability_TimezoneArgs = {
  input: TokenCandidateAvailabilityTimeZoneInput;
};


export type TemplateTokenCandidate_Availability_NoteArgs = {
  input: TokenCandidateAvailabilityNoteInput;
};


export type TemplateTokenCandidate_Availability_UrlArgs = {
  input: TokenCandidateAvailabilityUrlInput;
};


export type TemplateTokenCandidate_LinkArgs = {
  input: TokenCandidateLinkInput;
};


export type TemplateTokenInterview_ScheduleArgs = {
  input: TokenInterviewScheduleInput;
};


export type TemplateTokenInternal_Interview_ScheduleArgs = {
  input: TokenInterviewScheduleInput;
};


export type TemplateTokenInterview_Schedule_Start_TimeArgs = {
  input: TokenInterviewScheduleStartTimeInput;
};


export type TemplateTokenInterview_Scorecard_NameArgs = {
  input: TokenInterviewScorecardNameInput;
};


export type TemplateTokenHiring_ManagerArgs = {
  input: TokenHiringManagerInput;
};


export type TemplateTokenInterviewer_NamesArgs = {
  input: TokenInterviewerNamesInput;
};


export type TemplateTokenInterview_NameArgs = {
  input: TokenInterviewNameInput;
};


export type TemplateTokenInterview_DaysArgs = {
  input: TokenInterviewDaysInput;
};


export type TemplateTokenInterview_Kit_LinkArgs = {
  input: TokenInterviewKitLinkInput;
};


export type TemplateTokenVideo_Meeting_LinkArgs = {
  input: TokenVideoMeetingLinkInput;
};


export type TemplateTokenZoom_Meeting_Dial_In_InfoArgs = {
  input: TokenZoomMeetingInput;
};


export type TemplateTokenZoom_Meeting_InfoArgs = {
  input: TokenZoomMeetingInput;
};


export type TemplateTokenDebrief_ScheduleArgs = {
  input: TokenDebriefScheduleInput;
};


export type TemplateTokenCode_LinkArgs = {
  input: CodeLinkInput;
};


export type TemplateTokenSelf_Schedule_LinkArgs = {
  input: TokenSelfScheduleLinkInput;
};


export type TemplateTokenExternal_Job_NameArgs = {
  input: TokenExternalJobNameInput;
};


export type TemplateTokenInterviewer_Training_InfoArgs = {
  input: TokenInterviewerTrainingInfoInput;
};


export type TemplateTokenInternal_Application_NoteArgs = {
  input: TokenApplicationInput;
};


export type TemplateTokenTask_CreatorArgs = {
  input: TokenTaskCreatorInput;
};


export type TemplateTokenTask_AssigneeArgs = {
  input: TokenTaskAssigneeInput;
};


export type TemplateTokenApplication_Candidate_Portal_UrlArgs = {
  input: TokenApplicationInput;
};


export type TemplateTokenSchedule_LocationArgs = {
  input: TokenScheduleLocationInput;
};

export type TemplateTokenInput = {
  renderType: RenderType;
  id?: Maybe<Scalars['String']>;
};

/**  Look at template.proto for naming convention. */
export enum TemplateType {
  CandidateAvailabilityRequest = 'CANDIDATE_AVAILABILITY_REQUEST',
  CandidateConfirmation = 'CANDIDATE_CONFIRMATION',
  CandidateInvite = 'CANDIDATE_INVITE',
  DebriefCalendarInvite = 'DEBRIEF_CALENDAR_INVITE',
  InterviewerInvite = 'INTERVIEWER_INVITE',
  SlackChannelMessage = 'SLACK_CHANNEL_MESSAGE',
  Other = 'OTHER',
  DebriefConfirmationConversationMessage = 'DEBRIEF_CONFIRMATION_CONVERSATION_MESSAGE',
  SelfScheduleRequestCandidateEmail = 'SELF_SCHEDULE_REQUEST_CANDIDATE_EMAIL',
  DebriefUpdateConfirmationConversationMessage = 'DEBRIEF_UPDATE_CONFIRMATION_CONVERSATION_MESSAGE',
  ScheduleCancellationConversationMessage = 'SCHEDULE_CANCELLATION_CONVERSATION_MESSAGE',
  DebriefUpdateConfirmationInterviewerCalendarInvite = 'DEBRIEF_UPDATE_CONFIRMATION_INTERVIEWER_CALENDAR_INVITE',
  ScheduleCancellationInterviewerEmail = 'SCHEDULE_CANCELLATION_INTERVIEWER_EMAIL',
  ScheduleCancellationCandidateEmail = 'SCHEDULE_CANCELLATION_CANDIDATE_EMAIL',
  ScheduleUpdateConfirmationCandidateEmail = 'SCHEDULE_UPDATE_CONFIRMATION_CANDIDATE_EMAIL',
  ScheduleUpdateConfirmationCandidateInvite = 'SCHEDULE_UPDATE_CONFIRMATION_CANDIDATE_INVITE',
  ScheduleUpdateConfirmationInterviewerInvite = 'SCHEDULE_UPDATE_CONFIRMATION_INTERVIEWER_INVITE',
  ScheduleUpdateConfirmationConversationMessage = 'SCHEDULE_UPDATE_CONFIRMATION_CONVERSATION_MESSAGE'
}

/** ########################################################## */
export type TemplateUpdateInput = {
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  type?: Maybe<TemplateType>;
  isOrganizationDefault?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<Array<AttachmentInput>>;
};

/** ########################################################## */
export type TemplatesInput = {
  pageInput: PageInput;
  orderBy?: Maybe<TemplatesOrderBy>;
  text?: Maybe<Scalars['String']>;
  types?: Maybe<Array<TemplateType>>;
  showDefault?: Maybe<Scalars['Boolean']>;
};

export type TemplatesOrderBy = {
  property: TemplatesOrderByProperty;
  direction: Sort;
};

export enum TemplatesOrderByProperty {
  Id = 'ID',
  Name = 'NAME'
}

export type TemplatesOutput = {
  __typename?: 'TemplatesOutput';
  items: Array<Template>;
  nextCursor?: Maybe<Scalars['String']>;
};

export type TimeBlock = {
  __typename?: 'TimeBlock';
  /**  hh:mm format */
  start?: Maybe<Scalars['String']>;
  /**  hh:mm format */
  end?: Maybe<Scalars['String']>;
};

export type TimeBlockInput = {
  /**  hh:mm format */
  start?: Maybe<Scalars['String']>;
  /**  hh:mm format */
  end?: Maybe<Scalars['String']>;
};

export type TimeBlockWeek = {
  __typename?: 'TimeBlockWeek';
  monday?: Maybe<Array<TimeBlock>>;
  tuesday?: Maybe<Array<TimeBlock>>;
  wednesday?: Maybe<Array<TimeBlock>>;
  thursday?: Maybe<Array<TimeBlock>>;
  friday?: Maybe<Array<TimeBlock>>;
  saturday?: Maybe<Array<TimeBlock>>;
  sunday?: Maybe<Array<TimeBlock>>;
};

/** ############################################# */
export type TimeBlockWeekInput = {
  monday?: Maybe<Array<Maybe<TimeBlockInput>>>;
  tuesday?: Maybe<Array<Maybe<TimeBlockInput>>>;
  wednesday?: Maybe<Array<Maybe<TimeBlockInput>>>;
  thursday?: Maybe<Array<Maybe<TimeBlockInput>>>;
  friday?: Maybe<Array<Maybe<TimeBlockInput>>>;
  saturday?: Maybe<Array<Maybe<TimeBlockInput>>>;
  sunday?: Maybe<Array<Maybe<TimeBlockInput>>>;
};

export type TimeRangeCount = {
  __typename?: 'TimeRangeCount';
  range: DateTimeRangeOutput;
  count: Scalars['numeric'];
};

export enum TimestampEmployeePref {
  TsTosAcceptedAt = 'ts_tos_accepted_at'
}

export type TimestampEmployeePrefValue = EmployeePrefValue & {
  __typename?: 'TimestampEmployeePrefValue';
  id: Scalars['String'];
  prefName: Scalars['String'];
  level: PrefLevel;
  value?: Maybe<Scalars['datetime']>;
};

export enum TimestampOrgPref {
  TsAtsDisconnectedAt = 'ts_ats_disconnected_at',
  TsCompanyContractEndDate = 'ts_company_contract_end_date'
}

/**  This will expect applicationId. */
export type TokenApplicationInput = {
  id: Scalars['uuid'];
};

export type TokenCandidateAvailabilityInput = {
  timeRanges: Array<DateTimeRangeInput>;
  timezone: Scalars['timezone'];
};

export type TokenCandidateAvailabilityNoteInput = {
  applicationId?: Maybe<Scalars['uuid']>;
  jobStageId?: Maybe<Scalars['uuid']>;
  taskId?: Maybe<Scalars['uuid']>;
};

export type TokenCandidateAvailabilityTimeZoneInput = {
  timezone: Scalars['timezone'];
};

export type TokenCandidateAvailabilityUrlInput = {
  taskId?: Maybe<Scalars['uuid']>;
};

/**  This will expect candidateId. */
export type TokenCandidateInput = {
  id: Scalars['uuid'];
  jobId: Scalars['uuid'];
};

/**  This will expect candidateId. */
export type TokenCandidateLinkInput = {
  id: Scalars['uuid'];
  label?: Maybe<Scalars['String']>;
};

/**  This will expect candidateId. */
export type TokenCandidateLinkedinInput = {
  id: Scalars['uuid'];
  label?: Maybe<Scalars['String']>;
};

export type TokenDebriefScheduleInput = {
  name: Scalars['String'];
  employeeIds: Array<Scalars['uuid']>;
  timeRange: DateTimeRangeInput;
};

export type TokenExternalJobNameInput = {
  id?: Maybe<Scalars['uuid']>;
  applicationId?: Maybe<Scalars['uuid']>;
};

export type TokenHiringManagerInput = {
  jobId: Scalars['uuid'];
};

export type TokenInterviewDaysInput = {
  events: Array<EventInput>;
  timezone?: Maybe<Scalars['timezone']>;
};

export type TokenInterviewKitLinkInput = {
  applicationId: Scalars['uuid'];
  atsScheduledInterviewId?: Maybe<Scalars['String']>;
  atsInterviewDefinitionId?: Maybe<Scalars['String']>;
  candidateId?: Maybe<Scalars['uuid']>;
};

export type TokenInterviewNameInput = {
  jobStageInterviewId?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

export type TokenInterviewScheduleInput = {
  events: Array<EventInput>;
  candidateId?: Maybe<Scalars['uuid']>;
  applicationId?: Maybe<Scalars['uuid']>;
  timezone?: Maybe<Scalars['timezone']>;
  hideInterviewName?: Maybe<Scalars['Boolean']>;
  linkInterviewerName?: Maybe<Scalars['Boolean']>;
};

export type TokenInterviewScheduleStartTimeInput = {
  startAt: Scalars['datetime'];
  timezone: Scalars['timezone'];
};

export type TokenInterviewScorecardNameInput = {
  /** Job Stage Id */
  id?: Maybe<Scalars['uuid']>;
  atsInterviewDefinitionId?: Maybe<Scalars['String']>;
};

export type TokenInterviewerNamesInput = {
  employeeIds?: Maybe<Array<Scalars['uuid']>>;
  addUrls?: Maybe<Scalars['Boolean']>;
};

export type TokenInterviewerTrainingInfoInput = {
  interviewers: Array<InterviewerInput>;
};

export type TokenJobLocationInput = {
  id: Scalars['uuid'];
};

export type TokenJobNameInput = {
  id: Scalars['uuid'];
};

export type TokenJobStageNameInput = {
  id: Scalars['uuid'];
};

export type TokenScheduleLocationInput = {
  meetingLocationId?: Maybe<Scalars['uuid']>;
};

export type TokenSelfScheduleLinkInput = {
  taskId?: Maybe<Scalars['uuid']>;
};

export type TokenTaskAssigneeInput = {
  taskId?: Maybe<Scalars['uuid']>;
  assigneeId?: Maybe<Scalars['uuid']>;
};

export type TokenTaskCreatorInput = {
  taskId?: Maybe<Scalars['uuid']>;
};

export type TokenVideoMeetingLinkInput = {
  videoMeetingLink?: Maybe<Scalars['String']>;
  customVideoMeetingLinkUrl?: Maybe<Scalars['String']>;
  zoomInfo?: Maybe<ZoomInfoInput>;
};

export type TokenZoomDialInfoInput = {
  number: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type TokenZoomMeetingInput = {
  joinUrl: Scalars['String'];
  meetingId: Scalars['String'];
  password: Scalars['String'];
  pstnPassword?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  dialInfo?: Maybe<Array<TokenZoomDialInfoInput>>;
};

export enum TrainingFeedbackNagType {
  None = 'NONE',
  Adhoc = 'ADHOC',
  Automated = 'AUTOMATED',
  Original = 'ORIGINAL'
}

export type TrainingFeedbackReminderNagInput = {
  interviewModuleId: Scalars['uuid'];
  interviewModuleMemberId: Scalars['uuid'];
  applicationStageInterviewId: Scalars['uuid'];
  reviewerId: Scalars['uuid'];
  trainingFeedbackNagType: TrainingFeedbackNagType;
};

export enum TrainingStatus {
  Trained = 'TRAINED',
  Shadow = 'SHADOW',
  ReverseShadow = 'REVERSE_SHADOW'
}

export type UpcomingEvent = {
  __typename?: 'UpcomingEvent';
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  dayIndex: Scalars['numeric'];
  dayTotal: Scalars['numeric'];
  schedule: ApplicationStage;
  /** @deprecated Use candidateEvents instead. */
  candidateEvent?: Maybe<ApplicationStageCandidateEvent>;
  candidateEvents: Array<ApplicationStageCandidateEvent>;
  interviews?: Maybe<Array<ApplicationStageInterview>>;
};

export type UpcomingEventsInput = {
  timeRange: DateTimeRangeInput;
  filters?: Maybe<ApplicationStageFilterInput>;
};

export type UpcomingEventsPageFiltersPref = {
  __typename?: 'UpcomingEventsPageFiltersPref';
  schedulerEmployeeIds: Array<Scalars['uuid']>;
  schedulerEmployees: Array<Employee>;
  recruiterEmployeeIds: Array<Scalars['uuid']>;
  recruiterEmployees: Array<Employee>;
  interviewerEmployeeIds: Array<Scalars['uuid']>;
  interviewerEmployees: Array<Employee>;
  candidateRsvps: Array<ResponseStatus>;
  interviewerRsvps: Array<ResponseStatus>;
  allAccepted: Scalars['Boolean'];
};

export type UpcomingEventsResult = {
  __typename?: 'UpcomingEventsResult';
  items: Array<UpcomingEvent>;
};

export type UpsertDateByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
};

export type User = {
  __typename?: 'User';
  auth0Id: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  signature: Scalars['String'];
  fullName: Scalars['String'];
  givenName: Scalars['String'];
  familyName: Scalars['String'];
  lastLoginAt?: Maybe<Scalars['datetime']>;
  employee?: Maybe<Employee>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  userId: Scalars['uuid'];
  userName: Scalars['String'];
  userEmail: Scalars['String'];
  employeeId?: Maybe<Scalars['uuid']>;
  employeeName?: Maybe<Scalars['String']>;
  employeeEmail?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['uuid']>;
  orgName?: Maybe<Scalars['String']>;
  role: UserRole;
  permissions: Array<Permission>;
  employee?: Maybe<Employee>;
  user?: Maybe<User>;
};

export type UserInfoResult = {
  __typename?: 'UserInfoResult';
  items: Array<UserInfo>;
  nextCursor?: Maybe<Scalars['String']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Scheduler = 'SCHEDULER',
  Interviewer = 'INTERVIEWER'
}

export type UserRoleSet = {
  role?: Maybe<UserRole>;
};

/** ######################################### */
export type UserUpdateInput = {
  userId: Scalars['uuid'];
  set: UserRoleSet;
};

export type UserUpdateResult = {
  __typename?: 'UserUpdateResult';
  userInfo?: Maybe<UserInfo>;
};

export type VideoMeetingCreateInput = {
  hostEmployeeId?: Maybe<Scalars['uuid']>;
  timeRange: DateTimeRangeInput;
  remoteVideoHostUserId?: Maybe<Scalars['String']>;
  /**  If an ML user exists, use hostEmployeeId */
  title?: Maybe<Scalars['String']>;
};

export type WorkdayApplication = {
  __typename?: 'WorkdayApplication';
  referenceId: Scalars['String'];
  stageReferenceId: Scalars['String'];
  currentStepName?: Maybe<Scalars['String']>;
};

export type WorkdayCandidate = {
  __typename?: 'WorkdayCandidate';
  referenceId: Scalars['String'];
};

export type WorkdayInterviewDefinition = {
  __typename?: 'WorkdayInterviewDefinition';
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type WorkdayJob = {
  __typename?: 'WorkdayJob';
  referenceId: Scalars['String'];
  isEvergreen: Scalars['Boolean'];
};

export type ZoomDialInInfoInput = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ZoomHost = {
  __typename?: 'ZoomHost';
  userId: Scalars['String'];
  userEmail: Scalars['String'];
};

export type ZoomInfo = {
  __typename?: 'ZoomInfo';
  password?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['numeric']>;
  userId?: Maybe<Scalars['String']>;
  pstnPassword?: Maybe<Scalars['String']>;
  dialInfo?: Maybe<Array<DialInfo>>;
};

export type ZoomInfoInput = {
  joinUrl: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  meetingId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  pstnPassword?: Maybe<Scalars['String']>;
  dialInfo?: Maybe<Array<Maybe<ZoomDialInInfoInput>>>;
};

export type ZoomIntegration = {
  __typename?: 'ZoomIntegration';
  active: Scalars['Boolean'];
};

export type ZoomIntegrationHealth = {
  __typename?: 'ZoomIntegrationHealth';
  isInstalled: Scalars['Boolean'];
  isHealthy: Scalars['Boolean'];
  canReadUsers: Scalars['Boolean'];
};

export type ZoomMeeting = {
  __typename?: 'ZoomMeeting';
  meetingId?: Maybe<Scalars['numeric']>;
};

export type ZoomMeetingCreateInput = {
  zoomUserId: Scalars['String'];
  timeRange?: Maybe<DateTimeRangeInput>;
  title?: Maybe<Scalars['String']>;
  previousMeetingId?: Maybe<Scalars['numeric']>;
};

export type ZoomMeetingCreateResult = {
  __typename?: 'ZoomMeetingCreateResult';
  meetingId: Scalars['numeric'];
  hostId?: Maybe<Scalars['String']>;
  joinUrl?: Maybe<Scalars['String']>;
  startUrl?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  pstnPassword?: Maybe<Scalars['String']>;
  dialInfo?: Maybe<Array<DialInfo>>;
};

export type ZoomMeetingResult = {
  __typename?: 'ZoomMeetingResult';
  zoomMeeting?: Maybe<ZoomMeeting>;
};

/**  Input for updating zoom meetings */
export type ZoomMeetingUpdateInput = {
  meetingId: Scalars['numeric'];
  timeRange?: Maybe<DateTimeRangeInput>;
};

export type ZoomUser = {
  __typename?: 'ZoomUser';
  orgId: Scalars['String'];
  zoomUserId: Scalars['String'];
  status?: Maybe<ZoomUserStatus>;
  email: Scalars['String'];
  employeeId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  type?: Maybe<ZoomUserType>;
};

export type ZoomUserConcurrentMeetingsDeleteInput = {
  zoomUserIds: Array<Scalars['String']>;
};

export type ZoomUserConcurrentMeetingsInput = {
  zoomUserId: Scalars['String'];
  maxConcurrentMeetings: Scalars['numeric'];
};

/** input for zoom user concurrent meetings */
export type ZoomUserConcurrentMeetingsPutInput = {
  items: Array<ZoomUserConcurrentMeetingsInput>;
};

export type ZoomUserConcurrentMeetingsUpdateInput = {
  items: Array<ZoomUserConcurrentMeetingsInput>;
};

export enum ZoomUserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING'
}

export enum ZoomUserType {
  Basic = 'BASIC',
  Licensed = 'LICENSED',
  None = 'NONE'
}

/**  Use either zoomUserIds or employeeIds. Accidentally if both are sent then zoomUserIds will take precedence. */
export type ZoomUsersByIdsInput = {
  zoomUserIds?: Maybe<Array<Scalars['String']>>;
  employeeIds?: Maybe<Array<Scalars['uuid']>>;
};

export type ZoomUsersInput = {
  pageInput: PageInput;
  prefix: Scalars['String'];
  orderBy?: Maybe<ZoomUsersOrderBy>;
};

export type ZoomUsersOrderBy = {
  property: ZoomUsersOrderByProperty;
  direction: Sort;
};

export enum ZoomUsersOrderByProperty {
  ZoomUserId = 'ZOOM_USER_ID',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME'
}

export type ZoomUsersResult = {
  __typename?: 'ZoomUsersResult';
  items: Array<ZoomUser>;
  nextCursor?: Maybe<Scalars['String']>;
};








export type ActivityLogAvatar_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id' | 'activityActorType'>
  & { actorEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )> }
);

export type ActivityLogBase_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id' | 'createdAt' | 'sourceType' | 'activityActorType'>
  & { source?: Maybe<(
    { __typename: 'Task' }
    & Pick<Task, 'id'>
    & TaskName_TaskFragment
  ) | { __typename?: 'Application' } | { __typename?: 'Candidate' }>, actorEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )> }
  & ActivityLogAvatar_ActivityLogFragment
);

export type ActivityLogApplicationCandidatePortalNoteCreated_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | (
    { __typename?: 'ActivityLogCandidatePortalNoteDetails' }
    & Pick<ActivityLogCandidatePortalNoteDetails, 'candidatePortalNoteId' | 'content' | 'sendToCandidate'>
  ) | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogApplicationCandidatePortalNoteDeleted_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | (
    { __typename?: 'ActivityLogCandidatePortalNoteDetails' }
    & Pick<ActivityLogCandidatePortalNoteDetails, 'candidatePortalNoteId'>
  ) | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogApplicationCandidatePortalNoteUpdated_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | (
    { __typename?: 'ActivityLogCandidatePortalNoteDetails' }
    & Pick<ActivityLogCandidatePortalNoteDetails, 'candidatePortalNoteId' | 'content' | 'sendToCandidate'>
  ) | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogCandidatePreferredPhoneNumberChangeDetails_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename: 'ActivityLogTaskDetails' } | { __typename: 'ActivityLogScheduleDetails' } | { __typename: 'ActivityLogNoteDetails' } | { __typename: 'ActivityLogTaskTagDetails' } | { __typename: 'ActivityLogEmailDetails' } | { __typename: 'ActivityLogRsvpDetails' } | { __typename: 'ActivityLogFlagDetails' } | { __typename: 'ActivityLogRescheduleDetails' } | { __typename: 'ActivityLogTaskStatusDetails' } | { __typename: 'ActivityLogTaskAssigneeDetails' } | { __typename: 'ActivityLogTaskInterviewerDetails' } | { __typename: 'ActivityLogTaskMeetingRoomDetails' } | { __typename: 'ActivityLogTaskEventDetails' } | { __typename: 'ActivityLogTaskAvailabilityDetails' } | { __typename: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename: 'ActivityLogRescheduleLogDetails' } | { __typename: 'ActivityLogCandidateViewedTabDetails' } | { __typename: 'ActivityLogApplicationStateChangeDetails' } | { __typename: 'ActivityLogTaskQueueDetails' } | { __typename: 'ActivityLogCandidatePortalNoteDetails' } | (
    { __typename: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }
    & Pick<ActivityLogCandidatePreferredPhoneNumberChangeDetails, 'updatedPhoneNumber'>
  )> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogCandidateViewedTabDetails_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | (
    { __typename: 'ActivityLogCandidateViewedTabDetails' }
    & Pick<ActivityLogCandidateViewedTabDetails, 'tabName'>
  ) | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogEmail_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | (
    { __typename: 'ActivityLogEmailDetails' }
    & Pick<ActivityLogEmailDetails, 'fromEmailAddress' | 'toEmailAddress' | 'ccAddresses' | 'subject' | 'body'>
  ) | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogNote_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id' | 'activityActorEmployeeId'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | (
    { __typename?: 'ActivityLogNoteDetails' }
    & Pick<ActivityLogNoteDetails, 'note' | 'isEdited'>
  ) | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogEditNoteMutationVariables = Exact<{
  input: ActivityNoteEditInput;
  showTaskName?: Scalars['Boolean'];
}>;


export type ActivityLogEditNoteMutation = (
  { __typename?: 'Mutation' }
  & { activityNoteEdit?: Maybe<(
    { __typename?: 'ActivityNoteEditResult' }
    & { activityLog?: Maybe<(
      { __typename?: 'ActivityLog' }
      & ActivityLogNote_ActivityLogFragment
    )> }
  )> }
);

export type ActivityLogDeleteNoteMutationVariables = Exact<{
  input: ActivityNoteDeleteInput;
}>;


export type ActivityLogDeleteNoteMutation = (
  { __typename?: 'Mutation' }
  & { activityNoteDelete?: Maybe<(
    { __typename?: 'ActivityNoteDeleteResult' }
    & Pick<ActivityNoteDeleteResult, 'result'>
  )> }
);

export type ActivityLogTaskAssigned_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename: 'ActivityLogTaskDetails' } | { __typename: 'ActivityLogScheduleDetails' } | { __typename: 'ActivityLogNoteDetails' } | { __typename: 'ActivityLogTaskTagDetails' } | { __typename: 'ActivityLogEmailDetails' } | { __typename: 'ActivityLogRsvpDetails' } | { __typename: 'ActivityLogFlagDetails' } | { __typename: 'ActivityLogRescheduleDetails' } | { __typename: 'ActivityLogTaskStatusDetails' } | (
    { __typename: 'ActivityLogTaskAssigneeDetails' }
    & Pick<ActivityLogTaskAssigneeDetails, 'assigneeId'>
    & { assignee: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName'>
    ) }
  ) | { __typename: 'ActivityLogTaskInterviewerDetails' } | { __typename: 'ActivityLogTaskMeetingRoomDetails' } | { __typename: 'ActivityLogTaskEventDetails' } | { __typename: 'ActivityLogTaskAvailabilityDetails' } | { __typename: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename: 'ActivityLogRescheduleLogDetails' } | { __typename: 'ActivityLogCandidateViewedTabDetails' } | { __typename: 'ActivityLogApplicationStateChangeDetails' } | { __typename: 'ActivityLogTaskQueueDetails' } | { __typename: 'ActivityLogCandidatePortalNoteDetails' } | { __typename: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskCalendarChanges_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskCandidateAvailabilityDeleted_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskCandidateAvailabilityReminderSent_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskCandidateInterviewReminderSent_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskCandidateRsvp_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | (
    { __typename?: 'ActivityLogRsvpDetails' }
    & Pick<ActivityLogRsvpDetails, 'startAt' | 'endAt' | 'responseStatus' | 'comment'>
  ) | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskCandidateSelfScheduleCanceled_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | (
    { __typename: 'ActivityLogTaskEventDetails' }
    & Pick<ActivityLogTaskEventDetails, 'selfScheduleCandidateNote'>
  ) | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskCandidateSelfScheduleCreated_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | (
    { __typename: 'ActivityLogTaskEventDetails' }
    & Pick<ActivityLogTaskEventDetails, 'selfScheduleCandidateNote' | 'sourcingLinkId'>
  ) | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskCandidateSelfScheduleReminderSent_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskCandidateSelfScheduleRescheduled_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | (
    { __typename: 'ActivityLogTaskEventDetails' }
    & Pick<ActivityLogTaskEventDetails, 'selfScheduleCandidateNote'>
  ) | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskCandidateSubmittedAvailability_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | (
    { __typename?: 'ActivityLogTaskAvailabilityDetails' }
    & Pick<ActivityLogTaskAvailabilityDetails, 'sourcingLinkId' | 'note'>
  ) | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskChangeRequested_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | (
    { __typename: 'ActivityLogFlagDetails' }
    & Pick<ActivityLogFlagDetails, 'flagContent'>
  ) | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskChangedStatus_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename: 'ActivityLogTaskDetails' } | { __typename: 'ActivityLogScheduleDetails' } | { __typename: 'ActivityLogNoteDetails' } | { __typename: 'ActivityLogTaskTagDetails' } | { __typename: 'ActivityLogEmailDetails' } | { __typename: 'ActivityLogRsvpDetails' } | { __typename: 'ActivityLogFlagDetails' } | { __typename: 'ActivityLogRescheduleDetails' } | (
    { __typename: 'ActivityLogTaskStatusDetails' }
    & Pick<ActivityLogTaskStatusDetails, 'taskStatus'>
  ) | { __typename: 'ActivityLogTaskAssigneeDetails' } | { __typename: 'ActivityLogTaskInterviewerDetails' } | { __typename: 'ActivityLogTaskMeetingRoomDetails' } | { __typename: 'ActivityLogTaskEventDetails' } | { __typename: 'ActivityLogTaskAvailabilityDetails' } | { __typename: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename: 'ActivityLogRescheduleLogDetails' } | { __typename: 'ActivityLogCandidateViewedTabDetails' } | { __typename: 'ActivityLogApplicationStateChangeDetails' } | { __typename: 'ActivityLogTaskQueueDetails' } | { __typename: 'ActivityLogCandidatePortalNoteDetails' } | { __typename: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskClosed_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<(
    { __typename: 'ActivityLogTaskDetails' }
    & Pick<ActivityLogTaskDetails, 'taskId'>
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & TaskName_TaskFragment
    ) }
  ) | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskClosedDueToStageChange_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskCreated_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<(
    { __typename: 'ActivityLogTaskDetails' }
    & Pick<ActivityLogTaskDetails, 'taskId' | 'sourcingLinkId'>
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & TaskName_TaskFragment
    ) }
  ) | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskDebriefCanceled_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskDebriefCreated_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskDebriefRescheduled_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskDeclinedInterviewerAutoReplaced_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename: 'ActivityLogTaskDetails' } | { __typename: 'ActivityLogScheduleDetails' } | { __typename: 'ActivityLogNoteDetails' } | { __typename: 'ActivityLogTaskTagDetails' } | { __typename: 'ActivityLogEmailDetails' } | (
    { __typename: 'ActivityLogRsvpDetails' }
    & Pick<ActivityLogRsvpDetails, 'interviewName'>
    & { previous?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'fullName'>
    )>, current?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'fullName'>
    )> }
  ) | { __typename: 'ActivityLogFlagDetails' } | { __typename: 'ActivityLogRescheduleDetails' } | { __typename: 'ActivityLogTaskStatusDetails' } | { __typename: 'ActivityLogTaskAssigneeDetails' } | { __typename: 'ActivityLogTaskInterviewerDetails' } | { __typename: 'ActivityLogTaskMeetingRoomDetails' } | { __typename: 'ActivityLogTaskEventDetails' } | { __typename: 'ActivityLogTaskAvailabilityDetails' } | { __typename: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename: 'ActivityLogRescheduleLogDetails' } | { __typename: 'ActivityLogCandidateViewedTabDetails' } | { __typename: 'ActivityLogApplicationStateChangeDetails' } | { __typename: 'ActivityLogTaskQueueDetails' } | { __typename: 'ActivityLogCandidatePortalNoteDetails' } | { __typename: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskEditedAvailability_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskFlagAdded_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | (
    { __typename?: 'ActivityLogFlagDetails' }
    & Pick<ActivityLogFlagDetails, 'flagContent'>
  ) | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskFlagResolvedCandidateDeclined_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskFlagResolvedChangeRequested_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | (
    { __typename: 'ActivityLogFlagDetails' }
    & Pick<ActivityLogFlagDetails, 'flagContent'>
  ) | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskFlagResolvedDebriefDeclined_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | (
    { __typename: 'ActivityLogTaskInterviewerDetails' }
    & Pick<ActivityLogTaskInterviewerDetails, 'interviewName'>
    & { interviewer: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName'>
    ) }
  ) | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskFlagResolvedInterviewDeclined_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | (
    { __typename: 'ActivityLogTaskInterviewerDetails' }
    & Pick<ActivityLogTaskInterviewerDetails, 'interviewName'>
    & { interviewer: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName'>
    ) }
  ) | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskInterviewerRsvp_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename: 'ActivityLogTaskDetails' } | { __typename: 'ActivityLogScheduleDetails' } | { __typename: 'ActivityLogNoteDetails' } | { __typename: 'ActivityLogTaskTagDetails' } | { __typename: 'ActivityLogEmailDetails' } | (
    { __typename: 'ActivityLogRsvpDetails' }
    & Pick<ActivityLogRsvpDetails, 'interviewName' | 'responseStatus' | 'comment'>
  ) | { __typename: 'ActivityLogFlagDetails' } | { __typename: 'ActivityLogRescheduleDetails' } | { __typename: 'ActivityLogTaskStatusDetails' } | { __typename: 'ActivityLogTaskAssigneeDetails' } | { __typename: 'ActivityLogTaskInterviewerDetails' } | { __typename: 'ActivityLogTaskMeetingRoomDetails' } | { __typename: 'ActivityLogTaskEventDetails' } | { __typename: 'ActivityLogTaskAvailabilityDetails' } | { __typename: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename: 'ActivityLogRescheduleLogDetails' } | { __typename: 'ActivityLogCandidateViewedTabDetails' } | { __typename: 'ActivityLogApplicationStateChangeDetails' } | { __typename: 'ActivityLogTaskQueueDetails' } | { __typename: 'ActivityLogCandidatePortalNoteDetails' } | { __typename: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskMarkedUrgent_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskMeetingRoomRsvp_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | (
    { __typename: 'ActivityLogTaskMeetingRoomDetails' }
    & Pick<ActivityLogTaskMeetingRoomDetails, 'responseStatus' | 'comment' | 'interviewName'>
    & { room: (
      { __typename?: 'MeetingRoom' }
      & Pick<MeetingRoom, 'id' | 'name'>
    ) }
  ) | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskMigrated_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskQueueSet_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename: 'ActivityLogTaskDetails' } | { __typename: 'ActivityLogScheduleDetails' } | { __typename: 'ActivityLogNoteDetails' } | { __typename: 'ActivityLogTaskTagDetails' } | { __typename: 'ActivityLogEmailDetails' } | { __typename: 'ActivityLogRsvpDetails' } | { __typename: 'ActivityLogFlagDetails' } | { __typename: 'ActivityLogRescheduleDetails' } | { __typename: 'ActivityLogTaskStatusDetails' } | { __typename: 'ActivityLogTaskAssigneeDetails' } | { __typename: 'ActivityLogTaskInterviewerDetails' } | { __typename: 'ActivityLogTaskMeetingRoomDetails' } | { __typename: 'ActivityLogTaskEventDetails' } | { __typename: 'ActivityLogTaskAvailabilityDetails' } | { __typename: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename: 'ActivityLogRescheduleLogDetails' } | { __typename: 'ActivityLogCandidateViewedTabDetails' } | { __typename: 'ActivityLogApplicationStateChangeDetails' } | (
    { __typename: 'ActivityLogTaskQueueDetails' }
    & { taskQueue?: Maybe<(
      { __typename?: 'TaskQueue' }
      & Pick<TaskQueue, 'id' | 'name'>
    )> }
  ) | { __typename: 'ActivityLogCandidatePortalNoteDetails' } | { __typename: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskQueueUnset_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename: 'ActivityLogTaskDetails' } | { __typename: 'ActivityLogScheduleDetails' } | { __typename: 'ActivityLogNoteDetails' } | { __typename: 'ActivityLogTaskTagDetails' } | { __typename: 'ActivityLogEmailDetails' } | { __typename: 'ActivityLogRsvpDetails' } | { __typename: 'ActivityLogFlagDetails' } | { __typename: 'ActivityLogRescheduleDetails' } | { __typename: 'ActivityLogTaskStatusDetails' } | { __typename: 'ActivityLogTaskAssigneeDetails' } | { __typename: 'ActivityLogTaskInterviewerDetails' } | { __typename: 'ActivityLogTaskMeetingRoomDetails' } | { __typename: 'ActivityLogTaskEventDetails' } | { __typename: 'ActivityLogTaskAvailabilityDetails' } | { __typename: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename: 'ActivityLogRescheduleLogDetails' } | { __typename: 'ActivityLogCandidateViewedTabDetails' } | { __typename: 'ActivityLogApplicationStateChangeDetails' } | (
    { __typename: 'ActivityLogTaskQueueDetails' }
    & { taskQueue?: Maybe<(
      { __typename?: 'TaskQueue' }
      & Pick<TaskQueue, 'id' | 'name'>
    )> }
  ) | { __typename: 'ActivityLogCandidatePortalNoteDetails' } | { __typename: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRcAdjustInterviewerRsvp_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename: 'ActivityLogTaskDetails' } | { __typename: 'ActivityLogScheduleDetails' } | { __typename: 'ActivityLogNoteDetails' } | { __typename: 'ActivityLogTaskTagDetails' } | { __typename: 'ActivityLogEmailDetails' } | (
    { __typename: 'ActivityLogRsvpDetails' }
    & Pick<ActivityLogRsvpDetails, 'interviewName' | 'responseStatus' | 'comment'>
    & { rsvp?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'fullName'>
    )> }
  ) | { __typename: 'ActivityLogFlagDetails' } | { __typename: 'ActivityLogRescheduleDetails' } | { __typename: 'ActivityLogTaskStatusDetails' } | { __typename: 'ActivityLogTaskAssigneeDetails' } | { __typename: 'ActivityLogTaskInterviewerDetails' } | { __typename: 'ActivityLogTaskMeetingRoomDetails' } | { __typename: 'ActivityLogTaskEventDetails' } | { __typename: 'ActivityLogTaskAvailabilityDetails' } | { __typename: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename: 'ActivityLogRescheduleLogDetails' } | { __typename: 'ActivityLogCandidateViewedTabDetails' } | { __typename: 'ActivityLogApplicationStateChangeDetails' } | { __typename: 'ActivityLogTaskQueueDetails' } | { __typename: 'ActivityLogCandidatePortalNoteDetails' } | { __typename: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskReadyForCandidateComm_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskReopened_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<(
    { __typename: 'ActivityLogTaskDetails' }
    & Pick<ActivityLogTaskDetails, 'taskId'>
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & TaskName_TaskFragment
    ) }
  ) | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRequirementApplicationDebriefAdded_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRequirementApplicationDebriefRemoved_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRequirementCandidateAvailabilityAdded_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRequirementCandidateAvailabilityRemoved_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRequirementCandidateAvailabilityUpdated_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRequirementScheduleAdded_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRequirementSelfScheduleAdded_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRequirementSelfScheduleRemoved_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRequirementSelfScheduleUpdated_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRescheduleLogCreated_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | (
    { __typename: 'ActivityLogRescheduleLogDetails' }
    & { rescheduleLog: (
      { __typename?: 'RescheduleLog' }
      & Pick<RescheduleLog, 'id' | 'note'>
      & { rescheduleReason?: Maybe<(
        { __typename?: 'RescheduleReason' }
        & Pick<RescheduleReason, 'reason'>
      )> }
    ) }
  ) | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRescheduleLogDeleted_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | (
    { __typename: 'ActivityLogRescheduleLogDetails' }
    & { rescheduleLog: (
      { __typename?: 'RescheduleLog' }
      & Pick<RescheduleLog, 'id' | 'note'>
    ) }
  ) | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskRescheduleLogEdited_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | (
    { __typename: 'ActivityLogRescheduleLogDetails' }
    & { rescheduleLog: (
      { __typename?: 'RescheduleLog' }
      & Pick<RescheduleLog, 'id' | 'note'>
    ) }
  ) | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskScheduleCanceled_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskScheduleCreated_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskScheduleRescheduled_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskScheduleUpdated_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskTagAdded_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename: 'ActivityLogTaskDetails' } | { __typename: 'ActivityLogScheduleDetails' } | { __typename: 'ActivityLogNoteDetails' } | (
    { __typename: 'ActivityLogTaskTagDetails' }
    & { taskTags: Array<(
      { __typename?: 'TaskTag' }
      & Pick<TaskTag, 'id' | 'name'>
    )> }
  ) | { __typename: 'ActivityLogEmailDetails' } | { __typename: 'ActivityLogRsvpDetails' } | { __typename: 'ActivityLogFlagDetails' } | { __typename: 'ActivityLogRescheduleDetails' } | { __typename: 'ActivityLogTaskStatusDetails' } | { __typename: 'ActivityLogTaskAssigneeDetails' } | { __typename: 'ActivityLogTaskInterviewerDetails' } | { __typename: 'ActivityLogTaskMeetingRoomDetails' } | { __typename: 'ActivityLogTaskEventDetails' } | { __typename: 'ActivityLogTaskAvailabilityDetails' } | { __typename: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename: 'ActivityLogRescheduleLogDetails' } | { __typename: 'ActivityLogCandidateViewedTabDetails' } | { __typename: 'ActivityLogApplicationStateChangeDetails' } | { __typename: 'ActivityLogTaskQueueDetails' } | { __typename: 'ActivityLogCandidatePortalNoteDetails' } | { __typename: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskTagRemoved_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & { details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | { __typename?: 'ActivityLogNoteDetails' } | (
    { __typename?: 'ActivityLogTaskTagDetails' }
    & { taskTags: Array<(
      { __typename?: 'TaskTag' }
      & Pick<TaskTag, 'id' | 'name'>
    )> }
  ) | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskUnassigned_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLogTaskUnmarkedUrgent_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogBase_ActivityLogFragment
);

export type ActivityLog_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id' | 'activityType'>
  & ActivityLogApplicationCandidatePortalNoteCreated_ActivityLogFragment
  & ActivityLogApplicationCandidatePortalNoteUpdated_ActivityLogFragment
  & ActivityLogApplicationCandidatePortalNoteDeleted_ActivityLogFragment
  & ActivityLogEmail_ActivityLogFragment
  & ActivityLogNote_ActivityLogFragment
  & ActivityLogTaskAssigned_ActivityLogFragment
  & ActivityLogTaskCalendarChanges_ActivityLogFragment
  & ActivityLogTaskCandidateAvailabilityDeleted_ActivityLogFragment
  & ActivityLogTaskCandidateRsvp_ActivityLogFragment
  & ActivityLogTaskCandidateSelfScheduleCanceled_ActivityLogFragment
  & ActivityLogTaskCandidateSelfScheduleCreated_ActivityLogFragment
  & ActivityLogTaskCandidateSelfScheduleRescheduled_ActivityLogFragment
  & ActivityLogTaskCandidateSubmittedAvailability_ActivityLogFragment
  & ActivityLogTaskChangedStatus_ActivityLogFragment
  & ActivityLogTaskChangeRequested_ActivityLogFragment
  & ActivityLogTaskClosed_ActivityLogFragment
  & ActivityLogTaskClosed_ActivityLogFragment
  & ActivityLogTaskCreated_ActivityLogFragment
  & ActivityLogTaskCreated_ActivityLogFragment
  & ActivityLogTaskDebriefCanceled_ActivityLogFragment
  & ActivityLogTaskDebriefCreated_ActivityLogFragment
  & ActivityLogTaskEditedAvailability_ActivityLogFragment
  & ActivityLogTaskFlagResolvedCandidateDeclined_ActivityLogFragment
  & ActivityLogTaskFlagResolvedChangeRequested_ActivityLogFragment
  & ActivityLogTaskFlagResolvedDebriefDeclined_ActivityLogFragment
  & ActivityLogTaskFlagResolvedInterviewDeclined_ActivityLogFragment
  & ActivityLogTaskInterviewerRsvp_ActivityLogFragment
  & ActivityLogTaskRcAdjustInterviewerRsvp_ActivityLogFragment
  & ActivityLogTaskMarkedUrgent_ActivityLogFragment
  & ActivityLogTaskMeetingRoomRsvp_ActivityLogFragment
  & ActivityLogTaskReopened_ActivityLogFragment
  & ActivityLogTaskRequirementApplicationDebriefAdded_ActivityLogFragment
  & ActivityLogTaskRequirementApplicationDebriefRemoved_ActivityLogFragment
  & ActivityLogTaskRequirementCandidateAvailabilityAdded_ActivityLogFragment
  & ActivityLogTaskRequirementCandidateAvailabilityRemoved_ActivityLogFragment
  & ActivityLogTaskRequirementCandidateAvailabilityUpdated_ActivityLogFragment
  & ActivityLogTaskRequirementScheduleAdded_ActivityLogFragment
  & ActivityLogTaskRequirementSelfScheduleAdded_ActivityLogFragment
  & ActivityLogTaskRequirementSelfScheduleRemoved_ActivityLogFragment
  & ActivityLogTaskRequirementSelfScheduleUpdated_ActivityLogFragment
  & ActivityLogTaskScheduleCanceled_ActivityLogFragment
  & ActivityLogTaskScheduleCreated_ActivityLogFragment
  & ActivityLogTaskScheduleRescheduled_ActivityLogFragment
  & ActivityLogTaskScheduleUpdated_ActivityLogFragment
  & ActivityLogTaskTagAdded_ActivityLogFragment
  & ActivityLogTaskTagRemoved_ActivityLogFragment
  & ActivityLogTaskUnassigned_ActivityLogFragment
  & ActivityLogTaskUnmarkedUrgent_ActivityLogFragment
  & ActivityLogTaskReadyForCandidateComm_ActivityLogFragment
  & ActivityLogCandidateViewedTabDetails_ActivityLogFragment
  & ActivityLogTaskQueueSet_ActivityLogFragment
  & ActivityLogTaskQueueUnset_ActivityLogFragment
  & ActivityLogTaskCandidateInterviewReminderSent_ActivityLogFragment
  & ActivityLogTaskCandidateSelfScheduleReminderSent_ActivityLogFragment
  & ActivityLogTaskCandidateAvailabilityReminderSent_ActivityLogFragment
  & ActivityLogTaskDeclinedInterviewerAutoReplaced_ActivityLogFragment
  & ActivityLogCandidatePreferredPhoneNumberChangeDetails_ActivityLogFragment
);

export type ApplicationDetailsActivityLogsList_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogsList_ActivityLogFragment
);

export type ApplicationDetailsActivityLogsListQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
  input: ActivityLogsForSourceInput;
  showTaskName?: Scalars['Boolean'];
}>;


export type ApplicationDetailsActivityLogsListQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { activityLogs: (
      { __typename?: 'ActivityLogsResult' }
      & Pick<ActivityLogsResult, 'nextCursor'>
      & { items: Array<(
        { __typename?: 'ActivityLog' }
        & ApplicationDetailsActivityLogsList_ActivityLogFragment
      )> }
    ) }
  )> }
);

export type ActivityNoteCreateMutationVariables = Exact<{
  input: ActivityNoteCreateInput;
}>;


export type ActivityNoteCreateMutation = (
  { __typename?: 'Mutation' }
  & { activityNoteCreate?: Maybe<(
    { __typename?: 'ActivityNoteCreateResult' }
    & { activityLog?: Maybe<(
      { __typename?: 'ActivityLog' }
      & Pick<ActivityLog, 'id' | 'createdAt' | 'activityType' | 'activityActorType' | 'activityActorEmployeeId'>
      & { actorEmployee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
      )>, details?: Maybe<{ __typename?: 'ActivityLogTaskDetails' } | { __typename?: 'ActivityLogScheduleDetails' } | (
        { __typename?: 'ActivityLogNoteDetails' }
        & Pick<ActivityLogNoteDetails, 'note'>
      ) | { __typename?: 'ActivityLogTaskTagDetails' } | { __typename?: 'ActivityLogEmailDetails' } | { __typename?: 'ActivityLogRsvpDetails' } | { __typename?: 'ActivityLogFlagDetails' } | { __typename?: 'ActivityLogRescheduleDetails' } | { __typename?: 'ActivityLogTaskStatusDetails' } | { __typename?: 'ActivityLogTaskAssigneeDetails' } | { __typename?: 'ActivityLogTaskInterviewerDetails' } | { __typename?: 'ActivityLogTaskMeetingRoomDetails' } | { __typename?: 'ActivityLogTaskEventDetails' } | { __typename?: 'ActivityLogTaskAvailabilityDetails' } | { __typename?: 'ActivityLogTaskReadyForCandidateCommDetails' } | { __typename?: 'ActivityLogRescheduleLogDetails' } | { __typename?: 'ActivityLogCandidateViewedTabDetails' } | { __typename?: 'ActivityLogApplicationStateChangeDetails' } | { __typename?: 'ActivityLogTaskQueueDetails' } | { __typename?: 'ActivityLogCandidatePortalNoteDetails' } | { __typename?: 'ActivityLogCandidatePreferredPhoneNumberChangeDetails' }> }
    )> }
  )> }
);

export type TaskDetailsActivityLogsList_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLogsList_ActivityLogFragment
);

export type TaskDetailsActivityLogsListQueryVariables = Exact<{
  taskId: Scalars['uuid'];
  input: ActivityLogsForSourceInput;
  showTaskName?: Scalars['Boolean'];
}>;


export type TaskDetailsActivityLogsListQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { activityLogs: (
      { __typename?: 'ActivityLogsResult' }
      & Pick<ActivityLogsResult, 'nextCursor'>
      & { items: Array<(
        { __typename?: 'ActivityLog' }
        & TaskDetailsActivityLogsList_ActivityLogFragment
      )> }
    ) }
  )> }
);

export type ActivityLogsList_ActivityLogFragment = (
  { __typename?: 'ActivityLog' }
  & Pick<ActivityLog, 'id'>
  & ActivityLog_ActivityLogFragment
);

export type ApplicationStatusIcon_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'status' | 'atsType'>
  & { state?: Maybe<(
    { __typename?: 'ApplicationState' }
    & Pick<ApplicationState, 'isOngoing' | 'isCompleted' | 'isScheduled' | 'isUnscheduled' | 'isCandidateDeclined' | 'isInterviewerDeclined' | 'isScheduledInAts' | 'isCompletedInAts'>
  )> }
);

export type TaskCount_TasksFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'canceledAt'>
  & { jobStage: (
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
  ) }
);

export type BrandingThemePlaceholderPreview_BrandingThemeFragment = (
  { __typename?: 'BrandingTheme' }
  & Pick<BrandingTheme, 'id' | 'themeMode' | 'profileImageUrl' | 'coverImageUrl' | 'primaryColor' | 'font'>
);

export type Candidate_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id' | 'fullName'>
  & { atsCandidate?: Maybe<(
    { __typename?: 'AtsCandidate' }
    & Pick<AtsCandidate, 'atsId'>
    & { atsFields?: Maybe<{ __typename?: 'GreenhouseCandidate' } | (
      { __typename?: 'LeverCandidate' }
      & Pick<LeverCandidate, 'origin'>
    ) | { __typename?: 'AshbyCandidate' } | { __typename?: 'WorkdayCandidate' }> }
  )> }
);

export type CompanyHolidayIcon_CompanyHolidaysFragment = (
  { __typename?: 'CompanyHoliday' }
  & Pick<CompanyHoliday, 'id' | 'name' | 'startAt' | 'endAt'>
  & { countries?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
  )>> }
);

export type CompanyHolidaysForDaysQueryVariables = Exact<{
  input: CompanyHolidaysForDaysInput;
}>;


export type CompanyHolidaysForDaysQuery = (
  { __typename?: 'Query' }
  & { companyHolidaysForDays?: Maybe<(
    { __typename?: 'CompanyHolidaysForDaysResult' }
    & { items: Array<(
      { __typename?: 'CompanyHolidaysForDays' }
      & Pick<CompanyHolidaysForDays, 'date'>
      & { companyHolidays: Array<(
        { __typename?: 'CompanyHoliday' }
        & CompanyHolidayIcon_CompanyHolidaysFragment
      )> }
    )> }
  )> }
);

export type CountryAttributeQueryVariables = Exact<{
  input: EmployeeAttributeNamesInput;
}>;


export type CountryAttributeQuery = (
  { __typename?: 'Query' }
  & { employeeAttributeNames?: Maybe<(
    { __typename?: 'EmployeeAttributeNamesResult' }
    & { items?: Maybe<Array<(
      { __typename?: 'EmployeeAttributeName' }
      & Pick<EmployeeAttributeName, 'id'>
      & { values?: Maybe<Array<(
        { __typename?: 'EmployeeAttributeTagValue' }
        & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
      )>> }
    )>> }
  )> }
);

export type DebriefContentActions_DebriefFragment = (
  { __typename?: 'ApplicationDebrief' }
  & Pick<ApplicationDebrief, 'id' | 'applicationId' | 'startAt' | 'endAt'>
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type DebriefContentDetails_DebriefFragment = (
  { __typename?: 'ApplicationDebrief' }
  & Pick<ApplicationDebrief, 'id'>
  & { attendees?: Maybe<Array<(
    { __typename?: 'ApplicationDebriefAttendee' }
    & Pick<ApplicationDebriefAttendee, 'applicationDebriefId'>
    & DebriefContentDetailsInterviewer_DebriefFragment
  )>>, meetingRooms?: Maybe<Array<(
    { __typename?: 'ApplicationDebriefRoom' }
    & Pick<ApplicationDebriefRoom, 'applicationDebriefId' | 'meetingRoomId'>
    & DebriefContentDetailsMeetingRoom_MeetingRoomFragment
  )>> }
);

export type DebriefContentDetailsInterviewer_DebriefFragment = (
  { __typename?: 'ApplicationDebriefAttendee' }
  & Pick<ApplicationDebriefAttendee, 'applicationDebriefId' | 'interviewerId' | 'responseStatus' | 'isOptional'>
  & { interviewer?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )> }
);

export type DebriefContentDetailsMeetingRoom_MeetingRoomFragment = (
  { __typename?: 'ApplicationDebriefRoom' }
  & Pick<ApplicationDebriefRoom, 'applicationDebriefId' | 'meetingRoomId' | 'responseStatus'>
  & { meetingRoom?: Maybe<(
    { __typename?: 'MeetingRoom' }
    & Pick<MeetingRoom, 'id' | 'name' | 'description'>
  )> }
);

export type DebriefContentHeader_DebriefFragment = (
  { __typename?: 'ApplicationDebrief' }
  & Pick<ApplicationDebrief, 'id' | 'name' | 'startAt' | 'endAt' | 'videoMeetingUrl'>
  & DebriefContentMenu_DebriefFragment
);

export type DebriefContentMenu_DebriefFragment = (
  { __typename?: 'ApplicationDebrief' }
  & Pick<ApplicationDebrief, 'id' | 'startAt' | 'googleEventUrl' | 'videoMeetingUrl' | 'applicationId' | 'zoomMeetingId' | 'zoomHostUserId' | 'zoomPassword' | 'zoomPstnPassword' | 'zoomDialIns'>
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type DebriefContentInterviewMenuQueryVariables = Exact<{
  input: TemplateTokenInput;
  zoomMeetingInfoInput: TokenZoomMeetingInput;
}>;


export type DebriefContentInterviewMenuQuery = (
  { __typename?: 'Query' }
  & { templateToken?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'ZOOM_MEETING_INFO' | 'ZOOM_MEETING_DIAL_IN_INFO'>
  )> }
);

export type DebriefContentMeeting_DebriefFragment = (
  { __typename?: 'ApplicationDebrief' }
  & Pick<ApplicationDebrief, 'id'>
  & DebriefContentHeader_DebriefFragment
  & DebriefContentDetails_DebriefFragment
);

export type DebriefContentEntity_DebriefFragment = (
  { __typename?: 'ApplicationDebrief' }
  & Pick<ApplicationDebrief, 'id' | 'startAt' | 'endAt' | 'createdAt'>
  & { creator?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'fullName'>
  )> }
  & DebriefContentActions_DebriefFragment
  & DebriefContentMeeting_DebriefFragment
);

export type EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'fullName' | 'email' | 'id' | 'orgId' | 'slackImageUrl' | 'timezone' | 'atsId' | 'isDirectoryDisabled' | 'isArchived' | 'isAtsDisabled' | 'hasAtsId' | 'title'>
);

export type EmployeeWorkHoursFragment = (
  { __typename?: 'Employee' }
  & { workHours?: Maybe<(
    { __typename?: 'TimeBlockWeek' }
    & { monday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, tuesday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, wednesday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, thursday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, friday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, saturday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, sunday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>> }
  )> }
);

export type EmployeeAttributesFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'isArchived' | 'fullName' | 'email' | 'timezone' | 'linkedinUrl' | 'title' | 'googleUserId' | 'isDirectoryDisabled' | 'isAtsDisabled' | 'hasAtsId' | 'atsId'>
  & { manager?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>, atsUser?: Maybe<(
    { __typename?: 'AtsUser' }
    & Pick<AtsUser, 'atsId' | 'fullName' | 'email' | 'isDisabled'>
  )> }
);

export type EmployeeDetailsFragmentFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'linkedinUrl' | 'googleUserId' | 'givenName' | 'useCalendarTimezone' | 'calendarTimezone'>
  & { interviewPauseDates?: Maybe<Array<(
    { __typename?: 'DateBlock' }
    & Pick<DateBlock, 'start' | 'end'>
  )>> }
  & EmployeeFragment
  & EmployeeAttributesFragment
  & EmployeeWorkHoursFragment
);

export type EmployeeListQueryVariables = Exact<{
  input: EmployeeSearchInput;
}>;


export type EmployeeListQuery = (
  { __typename?: 'Query' }
  & { employeeSearch?: Maybe<(
    { __typename?: 'EmployeeSearchOutput' }
    & Pick<EmployeeSearchOutput, 'total'>
    & { items: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'upcomingInterviewsCount' | 'modulesTrainedCount' | 'modulesInTrainingCount'>
      & EmployeeFragment
    )> }
  )> }
);

export type EmployeesQueryVariables = Exact<{
  input: EmployeesInput;
}>;


export type EmployeesQuery = (
  { __typename?: 'Query' }
  & { employees?: Maybe<(
    { __typename?: 'EmployeesOutput' }
    & Pick<EmployeesOutput, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Employee' }
      & EmployeeFragment
    )> }
  )> }
);

export type EmployeeByIdsQueryVariables = Exact<{
  input: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type EmployeeByIdsQuery = (
  { __typename?: 'Query' }
  & { employeeByIds?: Maybe<Array<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )>> }
);

export type EmployeeDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type EmployeeDetailsQuery = (
  { __typename?: 'Query' }
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeDetailsFragmentFragment
  )> }
);

export type UpdateEmployeeMutationVariables = Exact<{
  input: EmployeeUpdateInput;
}>;


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { employeeUpdate?: Maybe<(
    { __typename?: 'EmployeeUpdateResult' }
    & { employee?: Maybe<(
      { __typename?: 'Employee' }
      & EmployeeFragment
    )> }
  )> }
);

export type EmployeesWorkHoursQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type EmployeesWorkHoursQuery = (
  { __typename?: 'Query' }
  & { employeeByIds?: Maybe<Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'timezone'>
    & EmployeeWorkHoursFragment
  )>> }
);

export type AttributesQueryVariables = Exact<{
  input: EmployeeAttributeNamesInput;
}>;


export type AttributesQuery = (
  { __typename?: 'Query' }
  & { employeeAttributeNames?: Maybe<(
    { __typename?: 'EmployeeAttributeNamesResult' }
    & { items?: Maybe<Array<(
      { __typename?: 'EmployeeAttributeName' }
      & Pick<EmployeeAttributeName, 'id' | 'name'>
      & { values?: Maybe<Array<(
        { __typename?: 'EmployeeAttributeTagValue' }
        & Pick<EmployeeAttributeTagValue, 'id'>
        & { name: EmployeeAttributeTagValue['value'] }
        & { attribute?: Maybe<(
          { __typename?: 'EmployeeAttributeName' }
          & Pick<EmployeeAttributeName, 'name' | 'id' | 'type'>
        )> }
      )>> }
    )>> }
  )> }
);

export type JobStagesByNameQueryVariables = Exact<{
  input: JobStagesInput;
}>;


export type JobStagesByNameQuery = (
  { __typename?: 'Query' }
  & { jobStagesByName?: Maybe<(
    { __typename?: 'JobStagesByNameOutput' }
    & Pick<JobStagesByNameOutput, 'items' | 'nextCursor'>
  )> }
);

export type JobsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type JobsByIdsQuery = (
  { __typename?: 'Query' }
  & { jobByIds?: Maybe<Array<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name'>
  )>> }
);

export type CandidateListJobsQueryVariables = Exact<{
  input: JobsInput;
}>;


export type CandidateListJobsQuery = (
  { __typename?: 'Query' }
  & { jobs?: Maybe<(
    { __typename?: 'JobsOutput' }
    & Pick<JobsOutput, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name'>
    )> }
  )> }
);

export type ManagersQueryVariables = Exact<{
  input: ManagersInput;
}>;


export type ManagersQuery = (
  { __typename?: 'Query' }
  & { managers?: Maybe<(
    { __typename?: 'EmployeesOutput' }
    & Pick<EmployeesOutput, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'slackImageUrl' | 'fullName'>
    )> }
  )> }
);

export type ListSelectedManagersByIdsQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type ListSelectedManagersByIdsQuery = (
  { __typename?: 'Query' }
  & { employeeByIds?: Maybe<Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'slackImageUrl' | 'fullName'>
  )>> }
);

export type InterviewModulesSelectQueryVariables = Exact<{
  input: InterviewModuleSearchInput;
}>;


export type InterviewModulesSelectQuery = (
  { __typename?: 'Query' }
  & { interviewModuleSearch?: Maybe<(
    { __typename?: 'InterviewModuleSearchOutput' }
    & Pick<InterviewModuleSearchOutput, 'total'>
    & { items: Array<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id' | 'name'>
    )> }
  )> }
);

export type ListSelectedModulesByIdQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type ListSelectedModulesByIdQuery = (
  { __typename?: 'Query' }
  & { interviewModuleByIds?: Maybe<Array<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'id' | 'name'>
  )>> }
);

export type TaskTagsBase_TaskTagFragment = (
  { __typename?: 'TaskTag' }
  & Pick<TaskTag, 'id' | 'name'>
);

export type TaskTags_SelectedTaskTagFragment = (
  { __typename?: 'TaskTag' }
  & TaskTagsBase_TaskTagFragment
);

export type TaskTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TaskTagsQuery = (
  { __typename?: 'Query' }
  & { taskTags?: Maybe<(
    { __typename?: 'TaskTagsResult' }
    & { items: Array<(
      { __typename?: 'TaskTag' }
      & TaskTags_SelectedTaskTagFragment
    )> }
  )> }
);

export type InterviewItemFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'name' | 'startAt' | 'endAt' | 'isCancelled' | 'googleEventUrl'>
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id'>
    & { application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'fullName'>
      )>, job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name'>
      )>, jobStage?: Maybe<(
        { __typename?: 'JobStage' }
        & Pick<JobStage, 'id' | 'name'>
      )> }
    )> }
  )>, applicationStageInterviewInterviewers?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterviewInterviewer' }
    & Pick<ApplicationStageInterviewInterviewer, 'interviewerId' | 'role' | 'responseStatus' | 'isOptional'>
    & { interviewer?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
    )>, declines?: Maybe<Array<(
      { __typename?: 'InterviewInterviewerDeclined' }
      & Pick<InterviewInterviewerDeclined, 'reason' | 'comment' | 'source'>
    )>> }
  )>>, applicationStageInterviewRooms?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterviewRoom' }
    & Pick<ApplicationStageInterviewRoom, 'applicationStageInterviewId' | 'meetingRoomId' | 'responseStatus'>
  )>>, traineeFeedback?: Maybe<(
    { __typename?: 'ApplicationStageInterviewFeedback' }
    & TraineeFeedbackFragment
  )>, trainedFeedback?: Maybe<(
    { __typename?: 'ApplicationStageInterviewFeedback' }
    & TraineeFeedbackFragment
  )>, schedulerFeedback?: Maybe<(
    { __typename?: 'ApplicationStageInterviewFeedback' }
    & TraineeFeedbackFragment
  )> }
);

export type VideoMeetingHost_InterviewMeetingLocationSettingPrefFragment = (
  { __typename?: 'InterviewMeetingLocationSettingPref' }
  & Pick<InterviewMeetingLocationSettingPref, 'interviewMeetingLocationType' | 'dynamicHost'>
  & { hostEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'email'>
  )> }
);

export type InterviewMeetingLocationHost_InterviewMeetingLocationSettingPrefFragment = (
  { __typename?: 'InterviewMeetingLocationSettingPref' }
  & Pick<InterviewMeetingLocationSettingPref, 'interviewMeetingLocationType' | 'dynamicHost' | 'hostEmployeeId' | 'remoteVideoMeetingHostUserId' | 'customLocation'>
  & { remoteVideoMeetingHostUser?: Maybe<(
    { __typename?: 'ZoomUser' }
    & Pick<ZoomUser, 'zoomUserId' | 'firstName' | 'lastName' | 'email'>
  )> }
  & VideoMeetingHost_InterviewMeetingLocationSettingPrefFragment
);

export type InterviewMeetingLocationMenuOrgIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type InterviewMeetingLocationMenuOrgIntegrationsQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { integrations?: Maybe<(
      { __typename?: 'OrgIntegrations' }
      & { google?: Maybe<(
        { __typename?: 'GoogleIntegration' }
        & Pick<GoogleIntegration, 'adminUserId'>
      )>, microsoft?: Maybe<(
        { __typename?: 'MicrosoftIntegration' }
        & Pick<MicrosoftIntegration, 'active'>
      )>, microsoftGccHigh?: Maybe<(
        { __typename?: 'MicrosoftGccHighIntegration' }
        & Pick<MicrosoftGccHighIntegration, 'active'>
      )>, zoom?: Maybe<(
        { __typename?: 'ZoomIntegration' }
        & Pick<ZoomIntegration, 'active'>
      )> }
    )> }
  )> }
);

export type InterviewMeetingLocationModal_InterviewMeetingLocationSettingPrefFragment = (
  { __typename?: 'InterviewMeetingLocationSettingPref' }
  & Pick<InterviewMeetingLocationSettingPref, 'interviewMeetingLocationType'>
  & InterviewMeetingLocationHost_InterviewMeetingLocationSettingPrefFragment
);

export type ModuleMember_InterviewModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'title'>
  )>, stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'currentTrainingSessionStatus'>
  )> }
  & IsModuleMembreOrEmployeePaused_ModuleMemberFragment
  & GetModuleMemberPendingTrainingCount_ModuleMemberFragment
);

export type ModuleMemberPausedChip_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'isPausedIndefinitely' | 'isPaused' | 'pausedUntil' | 'id'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'isPaused' | 'timezone'>
    & { interviewPauseDates?: Maybe<Array<(
      { __typename?: 'DateBlock' }
      & Pick<DateBlock, 'start' | 'end'>
    )>> }
  )> }
  & IsModuleMemberEmployeePaused_ModuleMemberFragment
  & IsModuleMembreOrEmployeePaused_ModuleMemberFragment
  & IsModuleMemberPauseIndefinitely_ModuleMemberFragment
  & GetModuleMemberEmployeePausedText_ModuleMemberFragment
  & GetModuleMemberPauseText_ModuleMemberFragment
);

export type ModuleMemberPendingChip_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'currentTrainingSessionStatus'>
  )> }
);

export type ModuleMemberTrainingStatus_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'isPausedIndefinitely' | 'isPaused' | 'pausedUntil'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'currentTrainingSessionStatus'>
  )>, employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'isPaused' | 'timezone' | 'isAtsDisabled' | 'isDirectoryDisabled' | 'hasAtsId'>
    & { interviewPauseDates?: Maybe<Array<(
      { __typename?: 'DateBlock' }
      & Pick<DateBlock, 'start' | 'end'>
    )>> }
  )> }
  & ModuleMemberPendingChip_ModuleMemberFragment
  & ModuleMemberPausedChip_ModuleMemberFragment
);

export type IsModuleMemberEmployeePaused_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'isPaused'>
  )> }
);

export type GetModuleMemberEmployeePausedText_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'isPaused' | 'timezone'>
    & { interviewPauseDates?: Maybe<Array<(
      { __typename?: 'DateBlock' }
      & Pick<DateBlock, 'start' | 'end'>
    )>> }
  )> }
);

export type IsModuleMemberPaused_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'isPaused' | 'pausedUntil'>
);

export type IsModuleMemberPauseIndefinitely_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'isPausedIndefinitely'>
);

export type GetModuleMemberPauseText_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'pausedUntil'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'timezone'>
  )> }
);

export type IsModuleMembreOrEmployeePaused_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'isPaused'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'isPaused'>
  )> }
);

export type GetShadowCompletedCount_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'employeeId' | 'interviewModuleId'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'completedAsShadow' | 'manualCompletedAsShadow'>
  )> }
);

export type GetShadowPendingCount_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'employeeId' | 'interviewModuleId' | 'shadowsRequired'>
  & GetShadowCompletedCount_ModuleMemberFragment
);

export type GetReverseShadowCompletedCount_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'employeeId' | 'interviewModuleId'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'completedAsReverseShadow' | 'manualCompletedAsReverseShadow'>
  )> }
);

export type GetReverseShadowPendingCount_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'employeeId' | 'interviewModuleId' | 'reverseShadowsRequired'>
  & GetReverseShadowCompletedCount_ModuleMemberFragment
);

export type GetModuleMemberPendingTrainingCount_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'status'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'upcoming'>
  )> }
  & GetShadowPendingCount_ModuleMemberFragment
  & GetReverseShadowPendingCount_ModuleMemberFragment
);

export type IsModuleMemberDisabledForScheduling_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'status' | 'shadowsRequired' | 'reverseShadowsRequired'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'currentTrainingSessionStatus'>
  )>, interviewModule?: Maybe<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'id' | 'shouldFastTrackTraining'>
  )> }
  & GetModuleMemberPendingTrainingCount_ModuleMemberFragment
);

export type AdvancedOptions_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { schedulingWindow?: Maybe<(
    { __typename?: 'Duration' }
    & Pick<Duration, 'seconds'>
  )>, excludedEmployees?: Maybe<Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>> }
);

export type Break_BreakSlotFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'interviewType' | 'name' | 'duration' | 'isLockedOrder'>
);

export type BreakSlot_BreakSlotFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'interviewType' | 'name' | 'duration' | 'isLockedOrder'>
  & MoreMenuOptions_JobStageInterviewFragment
);

export type BreakSlot_JobStageInterviewGroupsFragment = (
  { __typename?: 'JobStageInterviewGroup' }
  & Pick<JobStageInterviewGroup, 'id'>
  & MoreMenuOptions_JobStageInterviewGroupsFragment
);

export type CodingLink_JobStageInterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'dynamicLinkTypes'>
);

export type InterviewCardHeader_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'atsId'>
);

export type InterviewCardHeader_JobStageInterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'name' | 'isLockedOrder' | 'useAtsDuration' | 'useAtsName' | 'duration' | 'forcedStartAt'>
  & { atsInterviewDefinition?: Maybe<(
    { __typename?: 'AtsInterviewDefinition' }
    & Pick<AtsInterviewDefinition, 'atsId' | 'name' | 'atsJobId'>
    & { atsFields?: Maybe<(
      { __typename?: 'GreenhouseInterviewDefinition' }
      & Pick<GreenhouseInterviewDefinition, 'estimatedMinutes' | 'index'>
    ) | { __typename?: 'LeverInterviewDefinition' } | { __typename?: 'GemInterviewDefinition' } | { __typename?: 'WorkdayInterviewDefinition' }> }
  )> }
  & CodingLink_JobStageInterviewFragment
  & MoreMenuOptions_JobStageInterviewFragment
);

export type InterviewCardHeader_JobStageInterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'duration' | 'forcedStartAt' | 'useAtsDuration'>
  & { atsInterviewDefinition?: Maybe<(
    { __typename?: 'AtsInterviewDefinition' }
    & Pick<AtsInterviewDefinition, 'atsId'>
    & { atsFields?: Maybe<(
      { __typename?: 'GreenhouseInterviewDefinition' }
      & Pick<GreenhouseInterviewDefinition, 'estimatedMinutes' | 'index'>
    ) | { __typename?: 'LeverInterviewDefinition' } | { __typename?: 'GemInterviewDefinition' } | { __typename?: 'WorkdayInterviewDefinition' }> }
  )> }
  & UseWarningForStartAt_JobStageInterviewsFragment
);

export type InterviewCardHeader_JobStageInterviewGroupsFragment = (
  { __typename?: 'JobStageInterviewGroup' }
  & Pick<JobStageInterviewGroup, 'id'>
  & MoreMenuOptions_JobStageInterviewGroupsFragment
);

export type InterviewGroup_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id'>
  & InterviewGroupOld_JobFragment
  & JobStageInterview_JobFragment
);

export type InterviewGroup_JobStageInterviewGroupFragment = (
  { __typename?: 'JobStageInterviewGroup' }
  & Pick<JobStageInterviewGroup, 'id' | 'locked' | 'name'>
  & { jobStageInterviews?: Maybe<Array<(
    { __typename?: 'JobStageInterview' }
    & Pick<JobStageInterview, 'id'>
    & JobStageInterview_JobStageInterviewFragment
  )>> }
  & InterviewGroupOld_JobStageInterviewGroupFragment
);

export type InterviewGroup_JobStageInterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & InterviewGroupOld_JobStageInterviewsFragment
  & JobStageInterview_JobStageInterviewFragment
  & JobStageInterview_JobStageInterviewsFragment
  & JobStageInterview_LinkableInterviewsFragment
);

export type InterviewGroup_JobStageInterviewGroupsFragment = (
  { __typename?: 'JobStageInterviewGroup' }
  & Pick<JobStageInterviewGroup, 'id'>
  & { jobStageInterviews?: Maybe<Array<(
    { __typename?: 'JobStageInterview' }
    & Pick<JobStageInterview, 'id'>
  )>> }
  & InterviewGroupOld_JobStageInterviewGroupsFragment
  & JobStageInterview_JobStageInterviewGroupsFragment
);

export type InterviewGroupOld_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id'>
  & JobStageInterview_JobFragment
);

export type InterviewGroupOld_JobStageInterviewGroupFragment = (
  { __typename?: 'JobStageInterviewGroup' }
  & Pick<JobStageInterviewGroup, 'id' | 'locked' | 'name'>
  & { jobStageInterviews?: Maybe<Array<(
    { __typename?: 'JobStageInterview' }
    & Pick<JobStageInterview, 'id'>
    & JobStageInterview_JobStageInterviewFragment
  )>> }
);

export type InterviewGroupOld_JobStageInterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & JobStageInterview_JobStageInterviewFragment
  & JobStageInterview_JobStageInterviewsFragment
  & JobStageInterview_LinkableInterviewsFragment
);

export type InterviewGroupOld_JobStageInterviewGroupsFragment = (
  { __typename?: 'JobStageInterviewGroup' }
  & Pick<JobStageInterviewGroup, 'id'>
  & JobStageInterview_JobStageInterviewGroupsFragment
);

export type InterviewModuleMember_InterviewModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
  & ModuleMember_InterviewModuleMemberFragment
);

export type BaseEditInterviewSeatModule_SeatFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id'>
  & BaseReadInterviewSeatModule_SeatFragment
);

export type BaseEditInterviewSeatModule_LinkableInterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'name'>
  & { jobStageInterviewSeats?: Maybe<Array<(
    { __typename?: 'JobStageInterviewSeat' }
    & Pick<JobStageInterviewSeat, 'id' | 'type'>
  )>> }
);

export type BaseEditInterviewSeatModule_LinkedInterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'name'>
  & { jobStageInterviewSeats?: Maybe<Array<(
    { __typename?: 'JobStageInterviewSeat' }
    & Pick<JobStageInterviewSeat, 'id'>
  )>> }
);

export type BaseEditInterviewSeatModuleEmployeeSearchV2Fragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'email' | 'orgId' | 'isDirectoryDisabled' | 'hasAtsId' | 'slackImageUrl' | 'timezone' | 'atsId' | 'isAtsDisabled'>
);

export type BaseEditInterviewSeatModuleInterviewModuleSearchV2Fragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'orgId' | 'membersCount' | 'membersTrainedCount' | 'membersInTrainingCount' | 'membersInReverseShadowCount' | 'membersInShadowCount' | 'membersInPendingApprovalCount' | 'graduateFromShadowApprovalType' | 'graduateFromReverseShadowApprovalType'>
  & { interviewModuleMembers?: Maybe<(
    { __typename?: 'InterviewModuleMembersOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
      & EmployeeFilterFragment
    )> }
  )> }
  & InterviewModuleFragment
);

export type EmployeesAndModulesSearchV2QueryVariables = Exact<{
  employeeSearchInput: EmployeeSearchInput;
  moduleSearchInput: InterviewModuleSearchInput;
}>;


export type EmployeesAndModulesSearchV2Query = (
  { __typename?: 'Query' }
  & { employeeSearch?: Maybe<(
    { __typename?: 'EmployeeSearchOutput' }
    & { items: Array<(
      { __typename?: 'Employee' }
      & BaseEditInterviewSeatModuleEmployeeSearchV2Fragment
    )> }
  )>, interviewModuleSearch?: Maybe<(
    { __typename?: 'InterviewModuleSearchOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModule' }
      & BaseEditInterviewSeatModuleInterviewModuleSearchV2Fragment
    )> }
  )> }
);

export type BaseInterviewSeatModule_SeatFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id' | 'type'>
  & BaseReadInterviewSeatModule_SeatFragment
  & BaseEditInterviewSeatModule_SeatFragment
);

export type BaseInterviewSeatModule_OriginalSeatFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id'>
  & BaseReadInterviewSeatModule_OriginalSeatFragment
);

export type BaseInterviewSeatModule_LinkedInterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & BaseReadInterviewSeatModule_LinkedInterviewFragment
  & BaseEditInterviewSeatModule_LinkedInterviewFragment
);

export type BaseInterviewSeatModule_LinkableInterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & BaseEditInterviewSeatModule_LinkableInterviewsFragment
);

export type BaseReadInterviewSeatModule_SeatFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id' | 'type' | 'index'>
  & FreeformSeat_SeatFragment
  & ModuleSeat_SeatFragment
  & LinkedSeat_SeatFragment
);

export type BaseReadInterviewSeatModule_LinkedInterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & LinkedSeat_LinkedInterviewFragment
);

export type BaseReadInterviewSeatModule_OriginalSeatFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id'>
  & LinkedSeat_OriginalSeatFragment
);

export type FreeformSeat_SeatFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id'>
  & { freeformSeat?: Maybe<(
    { __typename?: 'FreeformSeat' }
    & { jobStageInterviewSeatEmployees?: Maybe<Array<(
      { __typename?: 'JobStageInterviewSeatEmployee' }
      & Pick<JobStageInterviewSeatEmployee, 'jobStageInterviewSeatId' | 'employeeId' | 'preferenceLevel'>
      & { employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
        & EmployeeFragment
      )> }
    )>> }
  )> }
);

export type LinkedSeat_SeatFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id'>
  & { linkedSeat?: Maybe<(
    { __typename?: 'LinkedSeat' }
    & Pick<LinkedSeat, 'linkedJobStageInterviewSeatId'>
  )> }
);

export type LinkedSeat_LinkedInterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'name'>
  & { jobStageInterviewSeats?: Maybe<Array<(
    { __typename?: 'JobStageInterviewSeat' }
    & Pick<JobStageInterviewSeat, 'id'>
  )>> }
);

export type LinkedSeat_OriginalSeatFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id'>
  & { freeformSeat?: Maybe<(
    { __typename?: 'FreeformSeat' }
    & { jobStageInterviewSeatEmployees?: Maybe<Array<(
      { __typename?: 'JobStageInterviewSeatEmployee' }
      & Pick<JobStageInterviewSeatEmployee, 'employeeId'>
      & { employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
      )> }
    )>> }
  )>, moduleSeat?: Maybe<(
    { __typename?: 'ModuleSeat' }
    & Pick<ModuleSeat, 'interviewModuleId'>
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id' | 'name'>
    )> }
  )> }
);

export type ModuleSeat_SeatFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id'>
  & { moduleSeat?: Maybe<(
    { __typename?: 'ModuleSeat' }
    & Pick<ModuleSeat, 'interviewModuleId' | 'selectedEmployeeIds'>
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id' | 'orgId' | 'shadowsRequired' | 'reverseShadowsRequired' | 'membersCount' | 'name' | 'membersTrainedCount' | 'membersInTrainingCount' | 'membersInReverseShadowCount' | 'membersInShadowCount' | 'membersInPendingApprovalCount' | 'graduateFromShadowApprovalType' | 'graduateFromReverseShadowApprovalType'>
      & { interviewModuleMembers?: Maybe<(
        { __typename?: 'InterviewModuleMembersOutput' }
        & { items: Array<(
          { __typename?: 'InterviewModuleMember' }
          & Pick<InterviewModuleMember, 'id' | 'interviewModuleId'>
          & EmployeeFilterFragment
        )> }
      )> }
    )>, jobStageInterviewSeatAttributes?: Maybe<Array<(
      { __typename?: 'JobStageInterviewSeatAttribute' }
      & Pick<JobStageInterviewSeatAttribute, 'id' | 'jobStageInterviewSeatId' | 'attributeNameId' | 'attributeTagValueId'>
    )>> }
  )> }
);

export type EditModuleSeatInterviewModuleV2Fragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId'>
  & EmployeeAndAttributesFilterFragment
);

export type InterviewModuleMemberFilteredByAttributesV2QueryVariables = Exact<{
  input: InterviewModuleMemberSearchInput;
}>;


export type InterviewModuleMemberFilteredByAttributesV2Query = (
  { __typename?: 'Query' }
  & { interviewModuleMemberSearch?: Maybe<(
    { __typename?: 'InterviewModuleMemberSearchOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & EditModuleSeatInterviewModuleV2Fragment
    )> }
  )> }
);

export type EditModuleSeatInterviewStatsV2Fragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id' | 'name' | 'membersCount' | 'membersInReverseShadowCount' | 'membersInShadowCount' | 'membersTrainedCount'>
);

export type ModuleMemberStatsV2QueryVariables = Exact<{ [key: string]: never; }>;


export type ModuleMemberStatsV2Query = (
  { __typename?: 'Query' }
  & { interviewModuleMemberStats?: Maybe<Array<(
    { __typename?: 'InterviewModuleMemberStats' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & EditModuleSeatInterviewStatsV2Fragment
    )> }
  )>> }
);

export type InterviewSeatsModule_SeatsFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id'>
  & BaseInterviewSeatModule_SeatFragment
);

export type InterviewSeatsModule_OriginalSeatsFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id'>
  & BaseInterviewSeatModule_OriginalSeatFragment
);

export type InterviewSeatsModule_LinkableInterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & BaseInterviewSeatModule_LinkableInterviewsFragment
  & BaseInterviewSeatModule_LinkedInterviewFragment
);

export type JobStageInterview_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id'>
  & InterviewCardHeader_JobFragment
);

export type JobStageInterview_JobStageInterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & { jobStageInterviewSeats?: Maybe<Array<(
    { __typename?: 'JobStageInterviewSeat' }
    & Pick<JobStageInterviewSeat, 'id'>
    & InterviewSeatsModule_SeatsFragment
  )>> }
  & InterviewCardHeader_JobStageInterviewFragment
  & BreakSlot_BreakSlotFragment
  & Break_BreakSlotFragment
);

export type JobStageInterview_LinkableInterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & { jobStageInterviewSeats?: Maybe<Array<(
    { __typename?: 'JobStageInterviewSeat' }
    & Pick<JobStageInterviewSeat, 'id'>
    & InterviewSeatsModule_OriginalSeatsFragment
  )>> }
  & InterviewSeatsModule_LinkableInterviewsFragment
);

export type JobStageInterview_JobStageInterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & InterviewCardHeader_JobStageInterviewsFragment
);

export type JobStageInterview_JobStageInterviewGroupsFragment = (
  { __typename?: 'JobStageInterviewGroup' }
  & Pick<JobStageInterviewGroup, 'id'>
  & BreakSlot_JobStageInterviewGroupsFragment
  & InterviewCardHeader_JobStageInterviewGroupsFragment
);

export type MoreMenuOptions_JobStageInterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'interviewType' | 'jobStageInterviewGroupId' | 'isHiddenFromCandidate'>
);

export type MoreMenuOptions_JobStageInterviewGroupsFragment = (
  { __typename?: 'JobStageInterviewGroup' }
  & Pick<JobStageInterviewGroup, 'id'>
  & { jobStageInterviews?: Maybe<Array<(
    { __typename?: 'JobStageInterview' }
    & Pick<JobStageInterview, 'id'>
  )>> }
);

export type CloneInterviewPlan_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
    & InterviewPlan_JobFragment
  )> }
  & InterviewPlan_JobStageFragment
);

export type GetSaveInterviewPlanInput_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & InterviewPlan_JobStageFragment
);

export type InterviewPlan_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'atsId'>
  & InterviewGroup_JobFragment
);

export type InterviewPlan_JobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id'>
      & InterviewGroup_JobStageInterviewsFragment
    )>> }
    & InterviewGroup_JobStageInterviewGroupFragment
    & InterviewGroup_JobStageInterviewGroupsFragment
  )>> }
  & AdvancedOptions_InterviewPlanFragment
);

export type UseInterviewPlanDescription_JobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id' | 'interviewType' | 'duration'>
      & { jobStageInterviewSeats?: Maybe<Array<(
        { __typename?: 'JobStageInterviewSeat' }
        & Pick<JobStageInterviewSeat, 'id' | 'type'>
        & { linkedSeat?: Maybe<(
          { __typename?: 'LinkedSeat' }
          & Pick<LinkedSeat, 'linkedJobStageInterviewSeatId'>
        )> }
      )>> }
    )>> }
  )>> }
);

export type UseInterviewPlanHasInterviewHiddenFromCandidate_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id' | 'isHiddenFromCandidate'>
    )>> }
  )>> }
);

export type UseIsInterviewPlanValid_JobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id' | 'interviewType' | 'isHiddenFromCandidate'>
      & { jobStageInterviewSeats?: Maybe<Array<(
        { __typename?: 'JobStageInterviewSeat' }
        & Pick<JobStageInterviewSeat, 'id'>
        & { freeformSeat?: Maybe<(
          { __typename?: 'FreeformSeat' }
          & { jobStageInterviewSeatEmployees?: Maybe<Array<(
            { __typename?: 'JobStageInterviewSeatEmployee' }
            & Pick<JobStageInterviewSeatEmployee, 'employeeId'>
            & { employee?: Maybe<(
              { __typename?: 'Employee' }
              & Pick<Employee, 'id' | 'fullName' | 'isArchived' | 'isDirectoryDisabled' | 'isAtsDisabled' | 'hasAtsId'>
            )> }
          )>> }
        )>, moduleSeat?: Maybe<(
          { __typename?: 'ModuleSeat' }
          & Pick<ModuleSeat, 'interviewModuleId'>
        )>, linkedSeat?: Maybe<(
          { __typename?: 'LinkedSeat' }
          & Pick<LinkedSeat, 'linkedJobStageInterviewSeatId'>
        )> }
      )>> }
    )>> }
  )>> }
);

export type ResetInterviewPlanToBaseJobStageQueryVariables = Exact<{
  jobStageId: Scalars['uuid'];
}>;


export type ResetInterviewPlanToBaseJobStageQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
    & GetSaveInterviewPlanInput_InterviewPlanFragment
  )> }
);

export type ResetPlanToBaseJobStageMutationVariables = Exact<{
  input: JobStageInterviewPlanUpsertInput;
}>;


export type ResetPlanToBaseJobStageMutation = (
  { __typename?: 'Mutation' }
  & { jobStageInterviewPlanUpsert?: Maybe<(
    { __typename?: 'JobStageInterviewPlanResult' }
    & { jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
      & GetSaveInterviewPlanInput_InterviewPlanFragment
    )> }
  )> }
);

export type UseWarningForStartAt_JobStageInterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'duration' | 'forcedStartAt'>
);

export type MeetingRoomFragmentFragment = (
  { __typename?: 'MeetingRoom' }
  & Pick<MeetingRoom, 'id' | 'name' | 'floorName' | 'description' | 'capacity' | 'features'>
);

export type MeetingRoomSuggestionsQueryVariables = Exact<{
  input: MeetingRoomSuggestionsInput;
}>;


export type MeetingRoomSuggestionsQuery = (
  { __typename?: 'Query' }
  & { meetingRoomSuggestions?: Maybe<(
    { __typename?: 'MeetingRoomSuggestionsOutput' }
    & Pick<MeetingRoomSuggestionsOutput, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'MeetingRoomSuggestion' }
      & Pick<MeetingRoomSuggestion, 'isBusy'>
      & { room?: Maybe<(
        { __typename?: 'MeetingRoom' }
        & Pick<MeetingRoom, 'id' | 'name' | 'floorName' | 'description' | 'capacity' | 'features'>
      )> }
    )> }
  )> }
);

export type MeetingRoomQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type MeetingRoomQuery = (
  { __typename?: 'Query' }
  & { meetingRoom?: Maybe<(
    { __typename?: 'MeetingRoom' }
    & MeetingRoomFragmentFragment
  )> }
);

export type MeetingRoomByIdsQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type MeetingRoomByIdsQuery = (
  { __typename?: 'Query' }
  & { meetingRoomByIds?: Maybe<Array<(
    { __typename?: 'MeetingRoom' }
    & MeetingRoomFragmentFragment
  )>> }
);

export type HoldForCandidateCommunications_ApplicationStageFragment = (
  { __typename?: 'ApplicationStage' }
  & Pick<ApplicationStage, 'id' | 'isHoldForRsvp' | 'isReadyForCandidateComms'>
);

export type CancelScheduleDialog_ApplicationStageFragment = (
  { __typename?: 'ApplicationStage' }
  & Pick<ApplicationStage, 'id' | 'minStartAt' | 'maxEndAt'>
);

export type CancelScheduleDialog_InterviewsFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'startAt'>
);

export type ScheduleContentActions_ApplicationStageFragment = (
  { __typename?: 'ApplicationStage' }
  & Pick<ApplicationStage, 'id' | 'selfScheduleOptionsId' | 'frozenAt'>
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { availabilityRequests: Array<(
      { __typename?: 'CandidateAvailabilityOptions' }
      & Pick<CandidateAvailabilityOptions, 'id'>
    )> }
  )> }
  & CancelScheduleDialog_ApplicationStageFragment
);

export type ScheduleContentActions_InterviewsFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'startAt' | 'endAt'>
  & CancelScheduleDialog_InterviewsFragment
);

export type InterviewAdjustRsvpMutationVariables = Exact<{
  applicationStageInterviewId: Scalars['uuid'];
  interviewerId: Scalars['uuid'];
  reason?: Maybe<DeclineReason>;
  comment?: Maybe<Scalars['String']>;
  responseStatus: ResponseStatus;
}>;


export type InterviewAdjustRsvpMutation = (
  { __typename?: 'Mutation' }
  & { interviewAdjustRsvp?: Maybe<(
    { __typename?: 'InterviewAdjustRsvpResult' }
    & Pick<InterviewAdjustRsvpResult, 'result'>
  )> }
);

export type AdjustInterviewerRsvp_InterviewFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'startAt' | 'endAt' | 'name' | 'isHiddenFromCandidate'>
  & { applicationStageInterviewInterviewers?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterviewInterviewer' }
    & Pick<ApplicationStageInterviewInterviewer, 'interviewerId' | 'role' | 'responseStatus' | 'isOptional'>
    & { declines?: Maybe<Array<(
      { __typename?: 'InterviewInterviewerDeclined' }
      & Pick<InterviewInterviewerDeclined, 'reason' | 'comment'>
    )>>, interviewer?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'slackImageUrl' | 'fullName' | 'givenName'>
    )> }
  )>> }
);

export type ScheduleContentInterviewDetails_InterviewFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id'>
  & { applicationStageInterviewInterviewers?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterviewInterviewer' }
    & Pick<ApplicationStageInterviewInterviewer, 'interviewerId'>
    & ScheduleContentInterviewDetailsInterviewer_InterviewerFragment
  )>>, applicationStageInterviewRooms?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterviewRoom' }
    & Pick<ApplicationStageInterviewRoom, 'applicationStageInterviewId' | 'meetingRoomId'>
    & ScheduleContentInterviewDetailsMeetingRoom_MeetingRoomFragment
  )>> }
  & ScheduleContentInterviewDetailsInterviewer_InterviewFragment
);

export type ScheduleContentInterviewDetails_CandidateEventFragment = (
  { __typename?: 'ApplicationStageCandidateEvent' }
  & Pick<ApplicationStageCandidateEvent, 'id' | 'responseStatus'>
);

export type ScheduleContentInterviewDetailsInterviewer_InterviewFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id'>
  & AdjustInterviewerRsvp_InterviewFragment
);

export type ScheduleContentInterviewDetailsInterviewer_InterviewerFragment = (
  { __typename?: 'ApplicationStageInterviewInterviewer' }
  & Pick<ApplicationStageInterviewInterviewer, 'applicationStageInterviewId' | 'interviewerId' | 'responseStatus' | 'calendarEventNote' | 'isOptional' | 'role'>
  & { interviewer?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )> }
);

export type ScheduleContentInterviewDetailsMeetingRoom_MeetingRoomFragment = (
  { __typename?: 'ApplicationStageInterviewRoom' }
  & Pick<ApplicationStageInterviewRoom, 'applicationStageInterviewId' | 'meetingRoomId' | 'responseStatus'>
  & { meetingRoom?: Maybe<(
    { __typename?: 'MeetingRoom' }
    & Pick<MeetingRoom, 'id' | 'name' | 'description'>
  )> }
);

export type ScheduleContentInterviewHeader_InterviewFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'name' | 'startAt' | 'endAt' | 'googleEventUrl' | 'videoMeetingUrl' | 'zoomDialInInfo' | 'codingInterviewUrl' | 'isHiddenFromCandidate'>
  & ScheduleContentInterviewMenu_InterviewFragment
);

export type ScheduleContentInterviewHeader_CandidateEventFragment = (
  { __typename?: 'ApplicationStageCandidateEvent' }
  & Pick<ApplicationStageCandidateEvent, 'id'>
  & ScheduleContentInterviewMenu_CandidateEventFragment
);

export type ScheduleContentInterviewMenu_InterviewFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'startAt' | 'googleEventUrl' | 'videoMeetingUrl' | 'zoomHostUserId' | 'zoomDialInInfo' | 'zoomMeetingId' | 'zoomPassword' | 'zoomPstnPassword' | 'codingInterviewUrl' | 'isHiddenFromCandidate'>
  & { videoMeetingHostEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName'>
  )> }
);

export type ScheduleContentInterviewMenu_CandidateEventFragment = (
  { __typename?: 'ApplicationStageCandidateEvent' }
  & Pick<ApplicationStageCandidateEvent, 'id' | 'googleEventUrl'>
);

export type ScheduleContentInterviewMenuQueryVariables = Exact<{
  input: TemplateTokenInput;
  zoomMeetingInfoInput: TokenZoomMeetingInput;
  zoomMeetingUserId: Scalars['String'];
}>;


export type ScheduleContentInterviewMenuQuery = (
  { __typename?: 'Query' }
  & { templateToken?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'ZOOM_MEETING_INFO' | 'ZOOM_MEETING_DIAL_IN_INFO'>
  )>, zoomUsersByIds?: Maybe<Array<(
    { __typename?: 'ZoomUser' }
    & Pick<ZoomUser, 'zoomUserId' | 'firstName' | 'lastName' | 'email'>
  )>> }
);

export type ScheduleContentInterview_InterviewFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id'>
  & ScheduleContentInterviewHeader_InterviewFragment
  & ScheduleContentInterviewDetails_InterviewFragment
);

export type ScheduleContentInterview_CandidateEventFragment = (
  { __typename?: 'ApplicationStageCandidateEvent' }
  & Pick<ApplicationStageCandidateEvent, 'id' | 'responseStatus'>
  & ScheduleContentInterviewHeader_CandidateEventFragment
  & ScheduleContentInterviewHeader_CandidateEventFragment
);

export type ScheduleContent_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'status'>
);

export type ScheduleContent_ScheduleFragment = (
  { __typename?: 'ApplicationStage' }
  & Pick<ApplicationStage, 'id' | 'createdAt' | 'frozenAt'>
  & { creator?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName'>
  )>, applicationStageInterviews?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterview' }
    & Pick<ApplicationStageInterview, 'id'>
    & ScheduleContentActions_InterviewsFragment
    & ScheduleContentInterview_InterviewFragment
    & GroupInterviewEventsByDate_InterviewsFragment
    & GetCandidateEventByInterviewId_InterviewsFragment
  )>>, applicationStageCandidateEvents?: Maybe<Array<(
    { __typename?: 'ApplicationStageCandidateEvent' }
    & Pick<ApplicationStageCandidateEvent, 'id'>
    & ScheduleContentInterview_CandidateEventFragment
    & GetCandidateEventByInterviewId_CandidateEventsFragment
  )>> }
  & HoldForCandidateCommunications_ApplicationStageFragment
  & ScheduleContentActions_ApplicationStageFragment
);

export type GetCandidateEventByInterviewId_InterviewsFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'startAt' | 'endAt'>
);

export type GetCandidateEventByInterviewId_CandidateEventsFragment = (
  { __typename?: 'ApplicationStageCandidateEvent' }
  & Pick<ApplicationStageCandidateEvent, 'id' | 'startAt' | 'endAt'>
);

export type GroupInterviewEventsByDate_InterviewsFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'startAt'>
);

export type AtsUserFragment = (
  { __typename?: 'AtsUser' }
  & Pick<AtsUser, 'id' | 'email' | 'name' | 'disabled' | 'employeeId'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )> }
);

export type ListAtsUsersQueryVariables = Exact<{
  input: AtsUserInput;
}>;


export type ListAtsUsersQuery = (
  { __typename?: 'Query' }
  & { atsUsers?: Maybe<(
    { __typename?: 'AtsUserOutput' }
    & { items?: Maybe<Array<(
      { __typename?: 'AtsUser' }
      & AtsUserFragment
    )>> }
  )> }
);

export type BrandingThemeSelectQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandingThemeSelectQuery = (
  { __typename?: 'Query' }
  & { brandingThemes?: Maybe<(
    { __typename?: 'BrandingThemesResult' }
    & { items: Array<(
      { __typename?: 'BrandingTheme' }
      & Row_BrandingOptionsFragment
    )> }
  )> }
);

export type Row_BrandingOptionsFragment = (
  { __typename?: 'BrandingTheme' }
  & Pick<BrandingTheme, 'id' | 'name' | 'profileImageUrl' | 'coverImageUrl' | 'isOrgDefault'>
  & BrandingThemePlaceholderPreview_BrandingThemeFragment
);

export type CandidateTimezoneSelectUpdateMutationVariables = Exact<{
  input: CandidateUpdateInput;
}>;


export type CandidateTimezoneSelectUpdateMutation = (
  { __typename?: 'Mutation' }
  & { candidateUpdate: (
    { __typename?: 'CandidateUpdateResult' }
    & { candidate: (
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id' | 'timezone'>
    ) }
  ) }
);

export type CandidateTimezoneSelect_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id' | 'timezone'>
);

export type CoordinatorSelectCandidateUpdateMutationVariables = Exact<{
  input: CandidateUpdateInput;
}>;


export type CoordinatorSelectCandidateUpdateMutation = (
  { __typename?: 'Mutation' }
  & { candidateUpdate: (
    { __typename?: 'CandidateUpdateResult' }
    & { candidate: (
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id'>
      & { coordinator?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
        & SelectButtonLabel_EmployeeFragment
      )> }
    ) }
  ) }
);

export type CoordinatorSelect_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id'>
  & { coordinator?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & SelectButtonLabel_EmployeeFragment
  )> }
);

export type RecruiterSelectCandidateUpdateMutationVariables = Exact<{
  input: CandidateUpdateInput;
}>;


export type RecruiterSelectCandidateUpdateMutation = (
  { __typename?: 'Mutation' }
  & { candidateUpdate: (
    { __typename?: 'CandidateUpdateResult' }
    & { candidate: (
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id'>
      & { recruiter?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
        & SelectButtonLabel_EmployeeFragment
      )> }
    ) }
  ) }
);

export type RecruiterSelect_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id'>
  & { recruiter?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & SelectButtonLabel_EmployeeFragment
  )> }
);

export type SourcerSelectCandidateUpdateMutationVariables = Exact<{
  input: CandidateUpdateInput;
}>;


export type SourcerSelectCandidateUpdateMutation = (
  { __typename?: 'Mutation' }
  & { candidateUpdate: (
    { __typename?: 'CandidateUpdateResult' }
    & { candidate: (
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id'>
      & { sourcer?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
        & SelectButtonLabel_EmployeeFragment
      )> }
    ) }
  ) }
);

export type SourcerSelect_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id'>
  & { sourcer?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & SelectButtonLabel_EmployeeFragment
  )> }
);

export type HiringTeam_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id'>
  & CoordinatorSelect_CandidateFragment
  & RecruiterSelect_CandidateFragment
  & SourcerSelect_CandidateFragment
);

export type InterviewModuleFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id' | 'name' | 'shadowsRequired' | 'reverseShadowsRequired' | 'weeklyNumInterviewLimit'>
);

export type InterviewModuleSelectQueryVariables = Exact<{
  input: InterviewModuleSearchInput;
}>;


export type InterviewModuleSelectQuery = (
  { __typename?: 'Query' }
  & { interviewModuleSearch?: Maybe<(
    { __typename?: 'InterviewModuleSearchOutput' }
    & Pick<InterviewModuleSearchOutput, 'total'>
    & { items: Array<(
      { __typename?: 'InterviewModule' }
      & InterviewModuleFragment
    )> }
  )> }
);

export type JobBrandingTemplateSelect_PortalTemplateFragment = (
  { __typename?: 'PortalTemplate' }
  & Pick<PortalTemplate, 'id' | 'name'>
);

export type JobBrandingTemplateSelectQueryQueryVariables = Exact<{
  input: PortalTemplatesInput;
}>;


export type JobBrandingTemplateSelectQueryQuery = (
  { __typename?: 'Query' }
  & { portalTemplates?: Maybe<(
    { __typename?: 'PortalTemplatesResult' }
    & { items: Array<(
      { __typename?: 'PortalTemplate' }
      & Pick<PortalTemplate, 'id'>
      & JobBrandingTemplateSelect_PortalTemplateFragment
    )> }
  )> }
);

export type OrgJobLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgJobLocationsQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id' | 'locations'>
  )> }
);

export type MemberSelect_UserInfoFragment = (
  { __typename?: 'UserInfo' }
  & Pick<UserInfo, 'userId' | 'userName'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )> }
);

export type OrgMembersQueryVariables = Exact<{
  input: OrgUserInfoInput;
}>;


export type OrgMembersQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { userInfos: (
      { __typename?: 'UserInfoResult' }
      & Pick<UserInfoResult, 'nextCursor'>
      & { items: Array<(
        { __typename?: 'UserInfo' }
        & MemberSelect_UserInfoFragment
      )> }
    ) }
  )> }
);

export type UserInfoByEmployeeIdsQueryVariables = Exact<{
  input: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type UserInfoByEmployeeIdsQuery = (
  { __typename?: 'Query' }
  & { userInfoByEmployeeIds?: Maybe<Array<(
    { __typename?: 'UserInfo' }
    & MemberSelect_UserInfoFragment
  )>> }
);

export type QueueSelect_SelectedTaskQueueFragment = (
  { __typename?: 'TaskQueue' }
  & Pick<TaskQueue, 'id' | 'name' | 'memberCount'>
);

export type QueueSelect_DefaultTaskQueueFragment = (
  { __typename?: 'TaskQueue' }
  & Pick<TaskQueue, 'id'>
  & QueueSelect_SelectedTaskQueueFragment
);

export type TaskQueuesSelectListQueryVariables = Exact<{
  input: TaskQueueSearchInput;
}>;


export type TaskQueuesSelectListQuery = (
  { __typename?: 'Query' }
  & { taskQueueSearch?: Maybe<(
    { __typename?: 'TaskQueueSearchResult' }
    & { items: Array<(
      { __typename?: 'TaskQueue' }
      & QueueSelect_SelectedTaskQueueFragment
    )> }
  )> }
);

export type OptionRenderer_EmployeesFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'givenName' | 'familyName' | 'slackImageUrl' | 'email' | 'isArchived' | 'isAtsDisabled' | 'hasAtsId' | 'isDisabled' | 'orgId'>
);

export type SmartEmployeeSelectFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'givenName' | 'familyName' | 'slackImageUrl' | 'email' | 'isArchived' | 'isAtsDisabled' | 'hasAtsId' | 'isDisabled' | 'orgId'>
);

export type SmartEmployeeSelectMembersQueryVariables = Exact<{
  input: EmployeesInput;
}>;


export type SmartEmployeeSelectMembersQuery = (
  { __typename?: 'Query' }
  & { employees?: Maybe<(
    { __typename?: 'EmployeesOutput' }
    & Pick<EmployeesOutput, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Employee' }
      & SmartEmployeeSelectFragment
    )> }
  )> }
);

export type SmartEmployeeByIdsQueryVariables = Exact<{
  input: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type SmartEmployeeByIdsQuery = (
  { __typename?: 'Query' }
  & { employeeByIds?: Maybe<Array<(
    { __typename?: 'Employee' }
    & SmartEmployeeSelectFragment
  )>> }
);

export type DynamicEmployeeQueryVariables = Exact<{
  id: Scalars['String'];
  taskId: Scalars['uuid'];
  jobId: Scalars['uuid'];
  candidateId: Scalars['uuid'];
  applicationId: Scalars['uuid'];
  skipTask: Scalars['Boolean'];
  skipJob: Scalars['Boolean'];
  skipCandidate: Scalars['Boolean'];
}>;


export type DynamicEmployeeQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'DynamicEmployee' }
    & { TASK_ASSIGNEE?: Maybe<(
      { __typename?: 'Employee' }
      & UseTaskSmartOptions_TaskAssigneeDataFragment
    )>, TASK_SUBSCRIBERS: Array<(
      { __typename?: 'Employee' }
      & UseTaskSmartOptions_TaskSubscriberDataFragment
    )>, TASK_CREATOR?: Maybe<(
      { __typename?: 'Employee' }
      & UseTaskSmartOptions_TaskCreatorDataFragment
    )>, DEBRIEF_ATTENDEES: Array<(
      { __typename?: 'Employee' }
      & UseTaskSmartOptions_TaskDebriefDataFragment
    )> }
  )>, job?: Maybe<(
    { __typename?: 'DynamicEmployee' }
    & { JOB_COORDINATORS: Array<(
      { __typename?: 'Employee' }
      & UseJobSmartOptions_JobCoordinatorDataFragment
    )>, JOB_RECRUITERS: Array<(
      { __typename?: 'Employee' }
      & UseJobSmartOptions_JobRecruiterDataFragment
    )>, JOB_SOURCERS: Array<(
      { __typename?: 'Employee' }
      & UseJobSmartOptions_JobSourcerDataFragment
    )> }
  )>, candidate?: Maybe<(
    { __typename?: 'DynamicEmployee' }
    & { CANDIDATE_COORDINATOR?: Maybe<(
      { __typename?: 'Employee' }
      & UseCandidateSmartOptions_CandidateCoordinatorDataFragment
    )>, CANDIDATE_RECRUITER?: Maybe<(
      { __typename?: 'Employee' }
      & UseCandidateSmartOptions_CandidateRecruiterDataFragment
    )>, CANDIDATE_SOURCER?: Maybe<(
      { __typename?: 'Employee' }
      & UseCandidateSmartOptions_CandidateSourcerDataFragment
    )>, INTERVIEWERS_FOR_THIS_CANDIDATE: Array<(
      { __typename?: 'Employee' }
      & UseCandidateSmartOptions_PreviousCandidateInterviewersDataFragment
    )> }
  )> }
);

export type CandidateDynamicEmployeeQueryVariables = Exact<{
  id: Scalars['String'];
  candidateId: Scalars['uuid'];
  applicationId: Scalars['uuid'];
}>;


export type CandidateDynamicEmployeeQuery = (
  { __typename?: 'Query' }
  & { dynamicEmployee?: Maybe<(
    { __typename?: 'DynamicEmployee' }
    & { CANDIDATE_COORDINATOR?: Maybe<(
      { __typename?: 'Employee' }
      & OptionRenderer_EmployeesFragment
    )>, CANDIDATE_RECRUITER?: Maybe<(
      { __typename?: 'Employee' }
      & OptionRenderer_EmployeesFragment
    )>, CANDIDATE_SOURCER?: Maybe<(
      { __typename?: 'Employee' }
      & OptionRenderer_EmployeesFragment
    )>, INTERVIEWERS_FOR_THIS_CANDIDATE: Array<(
      { __typename?: 'Employee' }
      & OptionRenderer_EmployeesFragment
    )> }
  )> }
);

export type UseCandidateSmartOptions_CandidateCoordinatorDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type UseCandidateSmartOptions_CandidateRecruiterDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type UseCandidateSmartOptions_CandidateSourcerDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type UseCandidateSmartOptions_PreviousCandidateInterviewersDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type UseJobSmartOptions_JobCoordinatorDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type UseJobSmartOptions_JobRecruiterDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type UseJobSmartOptions_JobSourcerDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type UseTaskSmartOptionsEmployeeByIdsQueryVariables = Exact<{
  taskAssigneeEmployeeIds: Array<Scalars['uuid']> | Scalars['uuid'];
  taskInterviewersOnScheduleEmployeeIds: Array<Scalars['uuid']> | Scalars['uuid'];
  taskCreatorEmployeeIds: Array<Scalars['uuid']> | Scalars['uuid'];
  taskSubscriberEmployeeIds: Array<Scalars['uuid']> | Scalars['uuid'];
  taskDebriefEmployeeIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type UseTaskSmartOptionsEmployeeByIdsQuery = (
  { __typename?: 'Query' }
  & { taskAssigneeData?: Maybe<Array<(
    { __typename?: 'Employee' }
    & OptionRenderer_EmployeesFragment
  )>>, taskSubscriberData?: Maybe<Array<(
    { __typename?: 'Employee' }
    & OptionRenderer_EmployeesFragment
  )>>, taskDebriefData?: Maybe<Array<(
    { __typename?: 'Employee' }
    & OptionRenderer_EmployeesFragment
  )>>, taskInterviewersData?: Maybe<Array<(
    { __typename?: 'Employee' }
    & OptionRenderer_EmployeesFragment
  )>>, taskCreatorData?: Maybe<Array<(
    { __typename?: 'Employee' }
    & OptionRenderer_EmployeesFragment
  )>> }
);

export type UseTaskSmartOptions_TaskAssigneeDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type UseTaskSmartOptions_TaskCreatorDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type UseTaskSmartOptions_TaskDebriefDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type UseTaskSmartOptions_TaskSubscriberDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type UseTaskSmartOptions_TaskInterviewersDataFragment = (
  { __typename?: 'Employee' }
  & OptionRenderer_EmployeesFragment
);

export type ZoomUserFragmentFragment = (
  { __typename?: 'ZoomUser' }
  & Pick<ZoomUser, 'orgId' | 'zoomUserId' | 'status' | 'email' | 'employeeId' | 'firstName' | 'lastName'>
);

export type ZoomUsersListQueryVariables = Exact<{
  input: ZoomUsersInput;
}>;


export type ZoomUsersListQuery = (
  { __typename?: 'Query' }
  & { zoomUsers?: Maybe<(
    { __typename?: 'ZoomUsersResult' }
    & Pick<ZoomUsersResult, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'ZoomUser' }
      & ZoomUserFragmentFragment
    )> }
  )> }
);

export type ZoomUsersByIdsQueryVariables = Exact<{
  input: ZoomUsersByIdsInput;
}>;


export type ZoomUsersByIdsQuery = (
  { __typename?: 'Query' }
  & { zoomUsersByIds?: Maybe<Array<(
    { __typename?: 'ZoomUser' }
    & ZoomUserFragmentFragment
  )>> }
);

export type ZoomUserByIdsQueryVariables = Exact<{
  input: ZoomUsersByIdsInput;
}>;


export type ZoomUserByIdsQuery = (
  { __typename?: 'Query' }
  & { zoomUsersByIds?: Maybe<Array<(
    { __typename?: 'ZoomUser' }
    & ZoomUserFragmentFragment
  )>> }
);

export type RecentlyUsedZoomUsersEmployeePrefFragment = (
  { __typename?: 'RecentlyUsedZoomUsersPref' }
  & { zoomUsers: Array<(
    { __typename?: 'ZoomUser' }
    & ZoomUserFragmentFragment
  )> }
);

export type RecentlyUsedZoomUsersEmployeePrefQueryVariables = Exact<{
  prefName: Scalars['String'];
}>;


export type RecentlyUsedZoomUsersEmployeePrefQuery = (
  { __typename?: 'Query' }
  & { thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
      & { jsonPref?: Maybe<{ __typename?: 'InterviewLimit' } | { __typename?: 'EmployeeTaskDashboardLayoutPref' } | { __typename?: 'EmployeeTaskDashboardFilterPref' } | { __typename?: 'CandidatePageFiltersPref' } | { __typename?: 'MetricsPageRecruitingTeamFiltersPref' } | { __typename?: 'MetricsPageInterviewerFiltersPref' } | { __typename?: 'DirectoryPageFiltersPref' } | { __typename?: 'UpcomingEventsPageFiltersPref' } | (
        { __typename?: 'RecentlyUsedZoomUsersPref' }
        & RecentlyUsedZoomUsersEmployeePrefFragment
      ) | { __typename?: 'ProfilePicturePref' } | { __typename?: 'InterviewBufferTimePref' }> }
    )> }
  )> }
);

export type UpdateRecentlyUsedZoomUsersEmployeePrefMutationVariables = Exact<{
  input: EmployeePrefUpdateInput;
}>;


export type UpdateRecentlyUsedZoomUsersEmployeePrefMutation = (
  { __typename?: 'Mutation' }
  & { employeePrefUpdate?: Maybe<(
    { __typename?: 'EmployeePrefUpdateResult' }
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
    )> }
  )> }
);

export type TraineeFeedbackFragment = (
  { __typename?: 'ApplicationStageInterviewFeedback' }
  & Pick<ApplicationStageInterviewFeedback, 'traineeId' | 'decisionType' | 'reviewerId' | 'note' | 'reviewedAt' | 'lastRequestedAt' | 'nagType'>
  & { trainee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>, reviewer?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>, lastRequestedBy?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName'>
  )> }
);

export type SubscribersSelect_ThisEmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
);

export type CurrentEmployeeDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentEmployeeDetailsQuery = (
  { __typename?: 'Query' }
  & { thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & SubscribersSelect_ThisEmployeeFragment
  )> }
);

export type SubscribersSelect_EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id'>
  & EmployeeFacePile_EmployeeFragment
);

export type SubscribersListQueryVariables = Exact<{
  input: SubscribersInput;
}>;


export type SubscribersListQuery = (
  { __typename?: 'Query' }
  & { subscribers?: Maybe<(
    { __typename?: 'SubscribersResult' }
    & { items: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & SubscribersSelect_EmployeeFragment
    )> }
  )> }
);

export type SubscriberAddMutationVariables = Exact<{
  input: SubscriberAddInput;
}>;


export type SubscriberAddMutation = (
  { __typename?: 'Mutation' }
  & { subscriberAdd?: Maybe<(
    { __typename?: 'SubscriberAddResult' }
    & Pick<SubscriberAddResult, 'result'>
    & { subscribers: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & SubscribersSelect_EmployeeFragment
    )> }
  )> }
);

export type SubscriberRemoveMutationVariables = Exact<{
  input: SubscriberRemoveInput;
}>;


export type SubscriberRemoveMutation = (
  { __typename?: 'Mutation' }
  & { subscriberRemove?: Maybe<(
    { __typename?: 'SubscriberRemoveResult' }
    & Pick<SubscriberRemoveResult, 'result'>
    & { subscribers: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & SubscribersSelect_EmployeeFragment
    )> }
  )> }
);

export type TemplateFragment = (
  { __typename?: 'Template' }
  & Pick<Template, 'id' | 'name' | 'subject' | 'body' | 'isOrganizationDefault' | 'updatedAt' | 'type' | 'createdAt'>
  & { attachments?: Maybe<Array<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'name' | 'path' | 'size'>
  )>> }
);

export type TemplateSelectTemplateByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TemplateSelectTemplateByIdQuery = (
  { __typename?: 'Query' }
  & { template?: Maybe<(
    { __typename?: 'Template' }
    & TemplateFragment
  )> }
);

export type TemplateSelectTemplateByIdsQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type TemplateSelectTemplateByIdsQuery = (
  { __typename?: 'Query' }
  & { templateByIds?: Maybe<Array<(
    { __typename?: 'Template' }
    & TemplateFragment
  )>> }
);

export type TemplateSelectTemplatesSearchQueryVariables = Exact<{
  input: TemplatesInput;
}>;


export type TemplateSelectTemplatesSearchQuery = (
  { __typename?: 'Query' }
  & { templates?: Maybe<(
    { __typename?: 'TemplatesOutput' }
    & { items: Array<(
      { __typename?: 'Template' }
      & TemplateFragment
    )> }
  )> }
);

export type AvatarFacePile_InterviewersFragment = (
  { __typename?: 'ApplicationStageInterviewInterviewer' }
  & Pick<ApplicationStageInterviewInterviewer, 'interviewerId' | 'responseStatus' | 'isOptional'>
  & { interviewer?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )> }
);

export type EmployeeFacePile_EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
);

export type TaskName_TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'name'>
);

export type EmployeePrefDataFragment = (
  { __typename?: 'EmployeePref' }
  & Pick<EmployeePref, 'orgId' | 'prefName' | 'json' | 'boolValue' | 'stringValue' | 'intValue' | 'stringArray' | 'jsonArray' | 'employeeId' | 'timestamp'>
);

export type EmployeePrefsQueryVariables = Exact<{
  id: Scalars['uuid'];
  prefName: Scalars['String'];
}>;


export type EmployeePrefsQuery = (
  { __typename?: 'Query' }
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName' | 'createdAt' | 'updatedAt'>
      & EmployeePrefDataFragment
    )> }
  )> }
);

export type UpdateEmployeePrefMutationVariables = Exact<{
  input: EmployeePrefUpdateInput;
}>;


export type UpdateEmployeePrefMutation = (
  { __typename?: 'Mutation' }
  & { employeePrefUpdate?: Maybe<(
    { __typename?: 'EmployeePrefUpdateResult' }
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName' | 'timestamp'>
    )> }
  )> }
);

export type CandidateAtsLatestAvailabilityFragmentFragment = (
  { __typename?: 'Candidate' }
  & { atsLatestAvailability?: Maybe<(
    { __typename?: 'CandidateAtsAvailability' }
    & Pick<CandidateAtsAvailability, 'jobName' | 'jobStageName' | 'timezone' | 'originalText'>
    & { intervals?: Maybe<Array<(
      { __typename?: 'DateTimeRangeOutput' }
      & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
    )>> }
  )> }
);

export type ApplicationStageInterviewPlanQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ApplicationStageInterviewPlanQuery = (
  { __typename?: 'Query' }
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'emailTemplateId' | 'applicationId' | 'isPrivateCalendarEvent'>
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { availabilities: Array<(
        { __typename?: 'CandidateAvailability' }
        & Pick<CandidateAvailability, 'id' | 'createdAt'>
        & { candidateEnteredAvailability?: Maybe<Array<(
          { __typename?: 'DateTimeRangeOutput' }
          & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
        )>>, rcEnteredAvailability?: Maybe<Array<(
          { __typename?: 'DateTimeRangeOutput' }
          & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
        )>> }
      )>, interviewPlan: (
        { __typename?: 'JobStage' }
        & Pick<JobStage, 'id'>
        & JobStage_InterviewPlanFragment
      ) }
    )>, conversation?: Maybe<(
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id' | 'remoteId' | 'remoteName'>
    )>, ccRecipients?: Maybe<(
      { __typename?: 'NotificationPreference' }
      & Pick<NotificationPreference, 'employeeIds' | 'externalEmailAddresses' | 'dynamicAudiences'>
    )>, bccRecipients?: Maybe<(
      { __typename?: 'NotificationPreference' }
      & Pick<NotificationPreference, 'employeeIds' | 'externalEmailAddresses' | 'dynamicAudiences'>
    )>, applicationStageCandidateEvents?: Maybe<Array<(
      { __typename: 'ApplicationStageCandidateEvent' }
      & Pick<ApplicationStageCandidateEvent, 'id' | 'name' | 'googleEventId' | 'googleCalendarId' | 'calendarEventTemplateId'>
    )>>, jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'atsId' | 'jobId' | 'name' | 'createdAt' | 'updatedAt' | 'schedulable'>
      & { job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'atsId' | 'name'>
        & { hiringManagers?: Maybe<Array<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id'>
        )>> }
      )>, jobStageSettings?: Maybe<(
        { __typename?: 'JobStageSettings' }
        & Pick<JobStageSettings, 'id' | 'jobStageId' | 'candidateEmailTemplateId' | 'candidateEventTemplateId' | 'candidateCalendarId' | 'slackTemplateId'>
        & { ccRecipients?: Maybe<(
          { __typename?: 'NotificationPreference' }
          & Pick<NotificationPreference, 'employeeIds' | 'externalEmailAddresses' | 'dynamicAudiences'>
        )>, bccRecipients?: Maybe<(
          { __typename?: 'NotificationPreference' }
          & Pick<NotificationPreference, 'employeeIds' | 'externalEmailAddresses' | 'dynamicAudiences'>
        )> }
      )> }
    )>, application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'fullName' | 'email' | 'additionalEmails' | 'coordinatorId' | 'recruiterId' | 'timezone'>
        & { atsCandidate?: Maybe<(
          { __typename?: 'AtsCandidate' }
          & Pick<AtsCandidate, 'atsUrl' | 'atsId'>
        )> }
        & CandidateAtsLatestAvailabilityFragmentFragment
      )>, existingConversations?: Maybe<Array<(
        { __typename?: 'Conversation' }
        & Pick<Conversation, 'id' | 'remoteId' | 'remoteName' | 'isArchived'>
      )>> }
    )> }
  )> }
);

export type OrgPrefsDataFragment = (
  { __typename?: 'OrgPref' }
  & Pick<OrgPref, 'orgId' | 'prefName' | 'json' | 'boolValue' | 'stringValue' | 'intValue' | 'stringArray' | 'jsonArray' | 'isLocked' | 'timestamp'>
  & { duration?: Maybe<(
    { __typename?: 'Duration' }
    & Pick<Duration, 'seconds' | 'nanos'>
  )>, jsonPref?: Maybe<(
    { __typename?: 'InterviewLimit' }
    & Pick<InterviewLimit, 'type' | 'limit'>
  ) | { __typename?: 'TimeBlockWeek' } | { __typename?: 'DateBlock' } | { __typename?: 'DefaultCustomInterviewScheduleTokenPref' } | { __typename?: 'NotificationPreference' } | { __typename?: 'AddZoomHostAsInternalInviteePref' } | { __typename?: 'CandidateFacingInterviewerInfo' } | { __typename?: 'CandidateFacingHiringTeamContactInfo' } | { __typename?: 'PaidFeaturePreference' } | (
    { __typename?: 'MinimumInterviewerLoadLimitOrgPref' }
    & Pick<MinimumInterviewerLoadLimitOrgPref, 'interviews' | 'minutes'>
  ) | (
    { __typename?: 'InterviewMeetingLocationSettingPref' }
    & Pick<InterviewMeetingLocationSettingPref, 'interviewMeetingLocationType' | 'dynamicHost' | 'hostEmployeeId' | 'remoteVideoMeetingHostUserId' | 'customLocation'>
  ) | { __typename?: 'InterviewBufferTimePref' } | { __typename?: 'CandidateCommunicationsSettingPref' }> }
);

export type OrgPrefsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgPrefsQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { orgPrefs?: Maybe<Array<(
      { __typename?: 'OrgPref' }
      & OrgPrefsDataFragment
    )>> }
  )> }
);

export type UpdateOrgPrefsMutationVariables = Exact<{
  input: OrgPrefUpdateInput;
}>;


export type UpdateOrgPrefsMutation = (
  { __typename?: 'Mutation' }
  & { orgPrefUpdate?: Maybe<(
    { __typename?: 'OrgPrefUpdateResult' }
    & { orgPref?: Maybe<(
      { __typename?: 'OrgPref' }
      & OrgPrefsDataFragment
    )> }
  )> }
);

export type UserPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type UserPermissionQuery = (
  { __typename?: 'Query' }
  & { thisUserInfo?: Maybe<(
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'permissions'>
  )> }
);

export type GoogleIntegrationHealthCheckQueryVariables = Exact<{ [key: string]: never; }>;


export type GoogleIntegrationHealthCheckQuery = (
  { __typename?: 'Query' }
  & { integrationCheckHealth?: Maybe<(
    { __typename?: 'IntegrationCheckHealthResult' }
    & { google?: Maybe<(
      { __typename?: 'GoogleIntegrationHealth' }
      & Pick<GoogleIntegrationHealth, 'canSendGmail'>
    )> }
  )>, thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { integrations?: Maybe<(
      { __typename?: 'OrgIntegrations' }
      & { google?: Maybe<(
        { __typename?: 'GoogleIntegration' }
        & Pick<GoogleIntegration, 'adminUserId'>
      )> }
    )> }
  )>, thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { integrations?: Maybe<(
      { __typename?: 'EmployeeIntegrations' }
      & { google?: Maybe<(
        { __typename?: 'GoogleEmployeeIntegration' }
        & Pick<GoogleEmployeeIntegration, 'active'>
      )> }
    )> }
  )> }
);

export type MicrosoftGccHighIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type MicrosoftGccHighIntegrationQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { integrations?: Maybe<(
      { __typename?: 'OrgIntegrations' }
      & { microsoftGccHigh?: Maybe<(
        { __typename?: 'MicrosoftGccHighIntegration' }
        & Pick<MicrosoftGccHighIntegration, 'active'>
      )> }
    )> }
  )>, thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { integrations?: Maybe<(
      { __typename?: 'EmployeeIntegrations' }
      & { microsoftGccHighEmployee?: Maybe<(
        { __typename?: 'MicrosoftGccHighEmployeeIntegration' }
        & Pick<MicrosoftGccHighEmployeeIntegration, 'active'>
      )> }
    )> }
  )> }
);

export type MicrosoftIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type MicrosoftIntegrationQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { integrations?: Maybe<(
      { __typename?: 'OrgIntegrations' }
      & { microsoft?: Maybe<(
        { __typename?: 'MicrosoftIntegration' }
        & Pick<MicrosoftIntegration, 'active'>
      )> }
    )> }
  )>, thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { integrations?: Maybe<(
      { __typename?: 'EmployeeIntegrations' }
      & { microsoft?: Maybe<(
        { __typename?: 'MicrosoftEmployeeIntegration' }
        & Pick<MicrosoftEmployeeIntegration, 'active'>
      )> }
    )> }
  )> }
);

export type ImportCandidateAvailabilityApplicationTasksQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type ImportCandidateAvailabilityApplicationTasksQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & { tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'canceledAt' | 'id'>
      & { availabilities: Array<(
        { __typename?: 'CandidateAvailability' }
        & Pick<CandidateAvailability, 'id'>
        & { candidateEnteredAvailability?: Maybe<Array<(
          { __typename?: 'DateTimeRangeOutput' }
          & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
        )>>, rcEnteredAvailability?: Maybe<Array<(
          { __typename?: 'DateTimeRangeOutput' }
          & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
        )>> }
      )> }
    )> }
  )> }
);

export type UseApplicationJobStageSchedulable_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'jobStageId'>
  & { atsApplication?: Maybe<(
    { __typename?: 'AtsApplication' }
    & Pick<AtsApplication, 'atsId' | 'notSchedulableReason' | 'canMoveJobStage'>
  )> }
);

export type CompanyHolidaysFragment = (
  { __typename?: 'CompanyHoliday' }
  & Pick<CompanyHoliday, 'id'>
  & { start: CompanyHoliday['startAt'], end: CompanyHoliday['endAt'] }
  & { countries?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
  )>> }
);

export type AllCompanyHolidaysQueryVariables = Exact<{
  input: CompanyHolidaysInput;
}>;


export type AllCompanyHolidaysQuery = (
  { __typename?: 'Query' }
  & { companyHolidays?: Maybe<(
    { __typename?: 'CompanyHolidayResult' }
    & { items: Array<(
      { __typename?: 'CompanyHoliday' }
      & CompanyHolidaysFragment
    )> }
  )> }
);

export type DirectoryInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type DirectoryInfoQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { integrations?: Maybe<(
      { __typename?: 'OrgIntegrations' }
      & { google?: Maybe<(
        { __typename?: 'GoogleIntegration' }
        & Pick<GoogleIntegration, 'adminUserId'>
      )>, microsoft?: Maybe<(
        { __typename?: 'MicrosoftIntegration' }
        & Pick<MicrosoftIntegration, 'active'>
      )> }
    )> }
  )> }
);

export type OrgHasAtLeastOneAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgHasAtLeastOneAdminQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id' | 'hasAdmin'>
  )> }
);

export type OrgNameQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgNameQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id' | 'name'>
  )> }
);

export type OrgSlugQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgSlugQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { slugs: Array<(
      { __typename?: 'PortalOrgSlug' }
      & Pick<PortalOrgSlug, 'slug'>
      & { id: PortalOrgSlug['orgId'] }
    )> }
  )> }
);

export type OrgHasSamlSsoQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgHasSamlSsoQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id' | 'hasSamlSso' | 'hasScim'>
  )> }
);

export type SelfScheduleOptionsByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SelfScheduleOptionsByIdQuery = (
  { __typename?: 'Query' }
  & { selfScheduleOptions?: Maybe<(
    { __typename?: 'SelfScheduleOptions' }
    & Pick<SelfScheduleOptions, 'id' | 'createdAt' | 'creatorEmployeeId' | 'deletedAt' | 'jobStageId' | 'interviewerEventTemplateId' | 'candidateEventTemplateId' | 'candidateEmailTemplateId' | 'lastUpdatedByEmployeeId' | 'rescheduledAt' | 'selfScheduleEmailTemplateId' | 'selfScheduleEmailSentAt' | 'updatedAt'>
    & { options?: Maybe<(
      { __typename?: 'Options' }
      & Pick<Options, 'inclusionDays' | 'rollingDays' | 'advanceNoticeInHours' | 'candidateCalendarId' | 'interviewerCalendarId' | 'location' | 'zoomHost' | 'zoomHostUserId' | 'candidateEmail'>
    )> }
  )> }
);

export type ShortUrlCreateMutationVariables = Exact<{
  input: ShortUrlCreateInput;
}>;


export type ShortUrlCreateMutation = (
  { __typename?: 'Mutation' }
  & { shortUrlCreate?: Maybe<(
    { __typename?: 'ShortUrlCreateResult' }
    & Pick<ShortUrlCreateResult, 'shortUrl'>
  )> }
);

export type SyncCandidateQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SyncCandidateQuery = (
  { __typename?: 'Query' }
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id'>
    & { applications?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
    )>> }
  )> }
);

export type AshbyHealthCheckBanner_AshbyFragment = (
  { __typename?: 'AshbyIntegrationHealth' }
  & Pick<AshbyIntegrationHealth, 'isHealthy' | 'isInstalled'>
);

export type GemHealthCheckBanner_GemFragment = (
  { __typename?: 'GemIntegrationHealth' }
  & Pick<GemIntegrationHealth, 'isInstalled' | 'isHealthy' | 'canReadUsers' | 'canReadUser' | 'canReadJobs' | 'canReadJob' | 'canReadJobStages' | 'canReadJobStageForJob' | 'canReadJobPostings' | 'canReadUserRoles' | 'canReadInterviews' | 'canReadInterview' | 'canReadCandidates' | 'canReadCandidate' | 'canReadActivityFeed' | 'canReadApplications' | 'canReadApplication'>
);

export type GoogleHealthCheckBanner_GoogleFragment = (
  { __typename?: 'GoogleIntegrationHealth' }
  & Pick<GoogleIntegrationHealth, 'isHealthy' | 'isInstalled' | 'canReadDirectory' | 'canReadCalendars' | 'canReadMeetingRooms'>
);

export type GreenhouseHealthCheckBanner_GreenhouseFragment = (
  { __typename?: 'GreenhouseIntegrationHealth' }
  & Pick<GreenhouseIntegrationHealth, 'isHealthy' | 'isInstalled' | 'canReadUsers' | 'canReadUser' | 'canReadJobs' | 'canReadJob' | 'canReadJobStages' | 'canReadJobStageForJob' | 'canReadJobPostings' | 'canReadUserRoles' | 'canReadEmailTemplates' | 'canReadInterviews' | 'canReadInterview' | 'canReadCandidates' | 'canReadCandidate' | 'canReadActivityFeed' | 'canReadApplications' | 'canReadApplication' | 'canReadUserPermission'>
);

export type LeverHealthCheckBanner_LeverFragment = (
  { __typename?: 'LeverIntegrationHealth' }
  & Pick<LeverIntegrationHealth, 'isHealthy' | 'isInstalled' | 'canReadUsers' | 'canReadJobs' | 'canReadCandidates' | 'canReadApplications' | 'canReadArchiveReasons' | 'canReadPanels' | 'canReadStages' | 'canReadDeletedCandidates'>
);

export type MicrosoftHealthCheckBanner_MicrosoftGccHighFragment = (
  { __typename?: 'MicrosoftIntegrationHealth' }
  & Pick<MicrosoftIntegrationHealth, 'isHealthy' | 'isInstalled' | 'canReadDirectory' | 'canReadCalendars' | 'canReadMeetingRooms'>
);

export type MicrosoftHealthCheckBanner_MicrosoftFragment = (
  { __typename?: 'MicrosoftIntegrationHealth' }
  & Pick<MicrosoftIntegrationHealth, 'isHealthy' | 'isInstalled' | 'canReadDirectory' | 'canReadCalendars' | 'canReadMeetingRooms'>
);

export type ZoomHealthCheckBanner_ZoomFragment = (
  { __typename?: 'ZoomIntegrationHealth' }
  & Pick<ZoomIntegrationHealth, 'isHealthy' | 'isInstalled'>
);

export type IntegrationHealthCheckBannersQueryVariables = Exact<{
  force: Scalars['Boolean'];
  includeGem: Scalars['Boolean'];
}>;


export type IntegrationHealthCheckBannersQuery = (
  { __typename?: 'Query' }
  & { integrationCheckHealth?: Maybe<(
    { __typename?: 'IntegrationCheckHealthResult' }
    & { google?: Maybe<(
      { __typename?: 'GoogleIntegrationHealth' }
      & GoogleHealthCheckBanner_GoogleFragment
    )>, microsoft?: Maybe<(
      { __typename?: 'MicrosoftIntegrationHealth' }
      & MicrosoftHealthCheckBanner_MicrosoftFragment
    )>, microsoftGccHigh?: Maybe<(
      { __typename?: 'MicrosoftIntegrationHealth' }
      & MicrosoftHealthCheckBanner_MicrosoftGccHighFragment
    )>, zoom?: Maybe<(
      { __typename?: 'ZoomIntegrationHealth' }
      & ZoomHealthCheckBanner_ZoomFragment
    )>, greenhouse?: Maybe<(
      { __typename?: 'GreenhouseIntegrationHealth' }
      & GreenhouseHealthCheckBanner_GreenhouseFragment
    )>, ashby?: Maybe<(
      { __typename?: 'AshbyIntegrationHealth' }
      & AshbyHealthCheckBanner_AshbyFragment
    )>, lever?: Maybe<(
      { __typename?: 'LeverIntegrationHealth' }
      & LeverHealthCheckBanner_LeverFragment
    )>, gem?: Maybe<(
      { __typename?: 'GemIntegrationHealth' }
      & GemHealthCheckBanner_GemFragment
    )> }
  )> }
);

export type GetUserPermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserPermissionsQuery = (
  { __typename?: 'Query' }
  & { thisUserInfo?: Maybe<(
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'permissions'>
  )> }
);

export type ApplicationSidebarContent_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'isTaskEnabled'>
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
  )>, job?: Maybe<(
    { __typename?: 'Job' }
    & JobStageSelect_JobFragment
  )>, candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id'>
    & CandidateInfoSidebar_CandidateFragment
  )>, tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { jobStage: (
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
    ) }
  )> }
  & ApplicationSidebarCreateTaskButton_ApplicationFragment
  & TaskList_ApplicationFragment
);

export type ApplicationSidebarCreateTaskActions_ApplicationFragment = (
  { __typename?: 'Application' }
  & CreateTaskActions_ApplicationFragment
  & NonSchedulableJobStageAlert_ApplicationFragment
);

export type ApplicationSidebarCreateTaskButton_ApplicationFragment = (
  { __typename?: 'Application' }
  & CreateTaskActions_ApplicationFragment
  & NonSchedulableJobStageAlert_ApplicationFragment
);

export type JobStageNameByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type JobStageNameByIdQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )> }
);

export type ApplicationSidebarHeader_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'fullName'>
  )>, job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name' | 'location'>
  )> }
);

export type CandidateInfoSidebar_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id'>
  & CandidateTimezoneSelect_CandidateFragment
  & CoordinatorSelect_CandidateFragment
  & RecruiterSelect_CandidateFragment
  & SourcerSelect_CandidateFragment
);

export type CreateTaskActions_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'status'>
  & BasicTaskModal_ApplicationFragment
  & AvailabilityTaskModal_ApplicationFragment
  & SelfScheduleTaskModal_ApplicationFragment
  & UseApplicationJobStageSchedulable_ApplicationFragment
);

export type JobStageSelect_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id'>
  & { jobStages?: Maybe<Array<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )>> }
);

export type NonSchedulableJobStageAlert_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'jobStageId'>
  & { atsApplication?: Maybe<(
    { __typename?: 'AtsApplication' }
    & Pick<AtsApplication, 'atsId'>
    & { atsFields?: Maybe<(
      { __typename: 'WorkdayApplication' }
      & Pick<WorkdayApplication, 'currentStepName'>
    ) | { __typename: 'AshbyApplication' }> }
  )>, jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )> }
  & UseApplicationJobStageSchedulable_ApplicationFragment
);

export type SelectButtonLabel_EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
);

export type ApplicationSidebarQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type ApplicationSidebarQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & ApplicationSidebarHeader_ApplicationFragment
    & ApplicationSidebarContent_ApplicationFragment
  )> }
);

export type GetExtSidebarDataQueryVariables = Exact<{
  candidateId: Scalars['uuid'];
  hasCandidateId: Scalars['Boolean'];
  fetchJob: Scalars['Boolean'];
}>;


export type GetExtSidebarDataQuery = (
  { __typename?: 'Query' }
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id'>
    & { applications?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'atsId'>
      & { job?: Maybe<(
        { __typename?: 'Job' }
        & { atsJob?: Maybe<(
          { __typename?: 'AtsJob' }
          & Pick<AtsJob, 'atsId'>
          & { atsFields?: Maybe<{ __typename?: 'AshbyJob' } | (
            { __typename?: 'WorkdayJob' }
            & Pick<WorkdayJob, 'referenceId'>
          ) | { __typename?: 'GreenhouseJob' } | { __typename?: 'GemJob' }> }
        )> }
      )> }
    )>> }
  )> }
);

export type ApplicationTokensQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  renderType: RenderType;
  input: TokenApplicationInput;
}>;


export type ApplicationTokensQuery = (
  { __typename?: 'Query' }
  & { templateToken?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'INTERNAL_APPLICATION_NOTE' | 'APPLICATION_CANDIDATE_PORTAL_URL'>
  )> }
);

export type CandidateAvailabilityTokensQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  renderType: RenderType;
  candidateAvailabilityInput: TokenCandidateAvailabilityInput;
  timezone: Scalars['timezone'];
  applicationId: Scalars['uuid'];
  jobStageId: Scalars['uuid'];
}>;


export type CandidateAvailabilityTokensQuery = (
  { __typename?: 'Query' }
  & { CANDIDATE_AVAILABILITY?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_AVAILABILITY'>
  )>, CANDIDATE_AVAILABILITY_TIMEZONE?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_AVAILABILITY_TIMEZONE'>
  )>, CANDIDATE_AVAILABILITY_NOTE?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_AVAILABILITY_NOTE'>
  )> }
);

export type CandidateTokensRequestQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  renderType: RenderType;
  candidateId: Scalars['uuid'];
  applicationId: Scalars['uuid'];
  jobId: Scalars['uuid'];
  candidateLinkLabel?: Maybe<Scalars['String']>;
  hasApplicationId: Scalars['Boolean'];
  hasJobId: Scalars['Boolean'];
}>;


export type CandidateTokensRequestQuery = (
  { __typename?: 'Query' }
  & { CANDIDATE_NAME?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_NAME'>
  )>, CANDIDATE_FIRST_NAME?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_FIRST_NAME'>
  )>, CANDIDATE_LAST_NAME?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_LAST_NAME'>
  )>, CANDIDATE_LAST_INITIAL?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_LAST_INITIAL'>
  )>, CANDIDATE_EMAIL_ADDRESS?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_EMAIL_ADDRESS'>
  )>, CANDIDATE_PHONE?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_PHONE'>
  )>, CANDIDATE_PRONOUNS?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_PRONOUNS'>
  )>, CANDIDATE_LINKEDIN?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_LINKEDIN'>
  )>, COORDINATOR_EMAIL?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'COORDINATOR_EMAIL'>
  )>, COORDINATOR?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'COORDINATOR'>
  )>, RECRUITER_EMAIL?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'RECRUITER_EMAIL'>
  )>, RECRUITER?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'RECRUITER'>
  )>, HIRING_MANAGER?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'HIRING_MANAGER'>
  )>, CANDIDATE_LINK?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_LINK'>
  )>, SOURCER?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'SOURCER'>
  )>, CANDIDATE_SOURCER?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CANDIDATE_SOURCER'>
  )> }
);

export type DebriefTokensQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  renderType: RenderType;
  input: TokenDebriefScheduleInput;
}>;


export type DebriefTokensQuery = (
  { __typename?: 'Query' }
  & { DEBRIEF_SCHEDULE?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'DEBRIEF_SCHEDULE'>
  )> }
);

export type InterviewTokensQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  uuid: Scalars['uuid'];
  renderType: RenderType;
  name: TokenInterviewNameInput;
  names: TokenInterviewerNamesInput;
  scheduleNames: TokenInterviewerNamesInput;
  days: TokenInterviewDaysInput;
  schedule: TokenInterviewScheduleInput;
  scheduleStartTime: TokenInterviewScheduleStartTimeInput;
  internalInterviewSchedule: TokenInterviewScheduleInput;
  kitLink: TokenInterviewKitLinkInput;
  codeLink: CodeLinkInput;
  hasScorecard: Scalars['Boolean'];
  videoMeetingLink: TokenVideoMeetingLinkInput;
  zoom: TokenZoomMeetingInput;
  trainingInfo: TokenInterviewerTrainingInfoInput;
  hasName: Scalars['Boolean'];
  hasNames: Scalars['Boolean'];
  hasDays: Scalars['Boolean'];
  hasSchedule: Scalars['Boolean'];
  hasScheduleStartTime: Scalars['Boolean'];
  hasInternalInterviewSchedule: Scalars['Boolean'];
  hasCodeLink: Scalars['Boolean'];
  hasVideoMeetingLink: Scalars['Boolean'];
  hasZoom: Scalars['Boolean'];
  atsInterviewDefinitionId?: Maybe<Scalars['String']>;
}>;


export type InterviewTokensQuery = (
  { __typename?: 'Query' }
  & { INTERVIEW_NAME?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'INTERVIEW_NAME'>
  )>, INTERVIEWER_NAMES?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'INTERVIEWER_NAMES'>
  )>, SCHEDULE_INTERVIEWER_NAMES?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'INTERVIEWER_NAMES'>
  )>, INTERVIEW_DAYS?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'INTERVIEW_DAYS'>
  )>, INTERVIEW_SCHEDULE?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'INTERVIEW_SCHEDULE'>
  )>, INTERVIEW_SCHEDULE_START_TIME?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'INTERVIEW_SCHEDULE_START_TIME'>
  )>, INTERNAL_INTERVIEW_SCHEDULE?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'INTERNAL_INTERVIEW_SCHEDULE'>
  )>, INTERVIEW_KIT_LINK?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'INTERVIEW_KIT_LINK'>
  )>, INTERVIEW_SCORECARD_NAME?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'INTERVIEW_SCORECARD_NAME'>
  )>, CODE_LINK?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'CODE_LINK'>
  )>, VIDEO_MEETING_LINK?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'VIDEO_MEETING_LINK'>
  )>, ZOOM_MEETING_DIAL_IN_INFO?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'ZOOM_MEETING_DIAL_IN_INFO'>
  )>, ZOOM_MEETING_INFO?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'ZOOM_MEETING_INFO'>
  )>, INTERVIEWER_TRAINING_INFO?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'INTERVIEWER_TRAINING_INFO'>
  )> }
);

export type JobTokensQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  renderType: RenderType;
  jobId: Scalars['uuid'];
  jobStageId: Scalars['uuid'];
  applicationId?: Maybe<Scalars['uuid']>;
  externalJobNameJobId?: Maybe<Scalars['uuid']>;
}>;


export type JobTokensQuery = (
  { __typename?: 'Query' }
  & { templateToken?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'JOB_NAME' | 'JOB_LOCATION' | 'JOB_STAGE_NAME' | 'EXTERNAL_JOB_NAME'>
  )> }
);

export type MeetingsTokenQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  renderType: RenderType;
  zoom: TokenZoomMeetingInput;
  videoMeetingLink: TokenVideoMeetingLinkInput;
  hasVideoMeetingLink: Scalars['Boolean'];
  hasZoom: Scalars['Boolean'];
}>;


export type MeetingsTokenQuery = (
  { __typename?: 'Query' }
  & { VIDEO_MEETING_LINK?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'VIDEO_MEETING_LINK'>
  )>, ZOOM_MEETING_DIAL_IN_INFO?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'ZOOM_MEETING_DIAL_IN_INFO'>
  )>, ZOOM_MEETING_INFO?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'ZOOM_MEETING_INFO'>
  )> }
);

export type TaskTokensQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  renderType: RenderType;
  taskId?: Maybe<Scalars['uuid']>;
  assigneeId?: Maybe<Scalars['uuid']>;
}>;


export type TaskTokensQuery = (
  { __typename?: 'Query' }
  & { templateToken?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'TASK_CREATOR' | 'TASK_ASSIGNEE'>
  )> }
);

export type UserTokensQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  renderType: RenderType;
}>;


export type UserTokensQuery = (
  { __typename?: 'Query' }
  & { templateToken?: Maybe<(
    { __typename?: 'TemplateToken' }
    & Pick<TemplateToken, 'id' | 'ME' | 'MY_FULL_NAME' | 'MY_FIRST_NAME' | 'MY_PHONE_NUMBER' | 'MY_SIGNATURE' | 'COMPANY_NAME' | 'COMPANY'>
  )> }
);

export type OrgInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgInfoQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id' | 'createdAt'>
  )> }
);

export type UpcomingScheduleAlert_ApplicationStagesFragment = (
  { __typename?: 'ApplicationStage' }
  & Pick<ApplicationStage, 'id' | 'jobStageId' | 'startAt' | 'endAt' | 'hasInterviewerDeclined'>
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { flags: Array<(
      { __typename?: 'TaskFlagCandidateDeclined' }
      & Pick<TaskFlagCandidateDeclined, 'id' | 'flagType' | 'resolvedAt'>
    ) | (
      { __typename?: 'TaskFlagInterviewerDebriefDeclined' }
      & Pick<TaskFlagInterviewerDebriefDeclined, 'id' | 'flagType' | 'resolvedAt'>
    ) | (
      { __typename?: 'TaskFlagInterviewerDeclined' }
      & Pick<TaskFlagInterviewerDeclined, 'id' | 'flagType' | 'resolvedAt'>
    ) | (
      { __typename?: 'TaskFlagRequestedChange' }
      & Pick<TaskFlagRequestedChange, 'id' | 'flagType' | 'resolvedAt'>
    )> }
  )>, applicationStageInterviews?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterview' }
    & Pick<ApplicationStageInterview, 'id' | 'startAt' | 'endAt'>
    & { applicationStageInterviewInterviewers?: Maybe<Array<(
      { __typename?: 'ApplicationStageInterviewInterviewer' }
      & Pick<ApplicationStageInterviewInterviewer, 'interviewerId'>
      & { declines?: Maybe<Array<(
        { __typename?: 'InterviewInterviewerDeclined' }
        & Pick<InterviewInterviewerDeclined, 'applicationStageInterviewId'>
      )>> }
    )>> }
  )>> }
);

export type ApplicationAlert_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'status'>
  & { applicationStages?: Maybe<Array<(
    { __typename?: 'ApplicationStage' }
    & UpcomingScheduleAlert_ApplicationStagesFragment
  )>> }
);

export type ApplicationConflict_TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id'>
  & { jobStage: (
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name'>
    )> }
  ), application: (
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
      & { job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type ApplicationDebriefs_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'status'>
  & { debriefs?: Maybe<Array<(
    { __typename?: 'ApplicationDebrief' }
    & Pick<ApplicationDebrief, 'id'>
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    )> }
    & DebriefContentEntity_DebriefFragment
  )>> }
  & UseApplicationJobStageSchedulable_ApplicationFragment
);

export type JobStageDetails_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id'>
  & { jobStages?: Maybe<Array<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name' | 'index'>
  )>> }
);

export type MoveJobStageButton_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )>, job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
    & { aliasedJs?: Maybe<Array<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name' | 'index' | 'schedulable' | 'atsId' | 'jobId'>
    )>> }
  )>, candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id'>
  )>, atsApplication?: Maybe<(
    { __typename?: 'AtsApplication' }
    & Pick<AtsApplication, 'atsId' | 'canMoveJobStage'>
  )> }
);

export type MoveToNextStageAlert_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id'>
  )>, job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name'>
    & { MoveToNextStageAlert_jobStages?: Maybe<Array<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name' | 'index'>
    )>> }
  )>, jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name' | 'index'>
  )>, tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'status'>
    & { jobStage: (
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
    ) }
  )>, atsApplication?: Maybe<(
    { __typename?: 'AtsApplication' }
    & Pick<AtsApplication, 'atsId' | 'canMoveJobStage'>
  )> }
);

export type OpenTaskOnOtherJobStageAlert_ApplicationFragment = (
  { __typename?: 'Application' }
  & { tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'status'>
    & { jobStage: (
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
    ) }
  )> }
);

export type TaskCard_TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'count' | 'status' | 'isUrgent'>
  & { application: (
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & ScheduleContent_ApplicationFragment
  ), assignee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>, flags: Array<(
    { __typename?: 'TaskFlagCandidateDeclined' }
    & Pick<TaskFlagCandidateDeclined, 'id' | 'resolvedAt'>
    & ScheduleTaskFlagCompact_TaskFlag_TaskFlagCandidateDeclined_Fragment
  ) | (
    { __typename?: 'TaskFlagInterviewerDebriefDeclined' }
    & Pick<TaskFlagInterviewerDebriefDeclined, 'id' | 'resolvedAt'>
    & ScheduleTaskFlagCompact_TaskFlag_TaskFlagInterviewerDebriefDeclined_Fragment
  ) | (
    { __typename?: 'TaskFlagInterviewerDeclined' }
    & Pick<TaskFlagInterviewerDeclined, 'id' | 'resolvedAt'>
    & ScheduleTaskFlagCompact_TaskFlag_TaskFlagInterviewerDeclined_Fragment
  ) | (
    { __typename?: 'TaskFlagRequestedChange' }
    & Pick<TaskFlagRequestedChange, 'id' | 'resolvedAt'>
    & ScheduleTaskFlagCompact_TaskFlag_TaskFlagRequestedChange_Fragment
  )>, tags: Array<(
    { __typename?: 'TaskTag' }
    & Pick<TaskTag, 'id'>
    & ScheduleTaskTagCompact_TaskTagFragment
  )>, schedules: Array<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'createdAt'>
    & ScheduleContent_ScheduleFragment
  )>, debriefs: Array<(
    { __typename?: 'ApplicationDebrief' }
    & Pick<ApplicationDebrief, 'id' | 'createdAt'>
    & DebriefContentEntity_DebriefFragment
  )>, jobStage: (
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  ), availabilityRequests: Array<(
    { __typename?: 'CandidateAvailabilityOptions' }
    & Pick<CandidateAvailabilityOptions, 'id'>
  )> }
  & TaskName_TaskFragment
);

export type TaskList_TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'count' | 'status'>
  & { flags: Array<(
    { __typename?: 'TaskFlagCandidateDeclined' }
    & Pick<TaskFlagCandidateDeclined, 'id' | 'resolvedAt'>
  ) | (
    { __typename?: 'TaskFlagInterviewerDebriefDeclined' }
    & Pick<TaskFlagInterviewerDebriefDeclined, 'id' | 'resolvedAt'>
  ) | (
    { __typename?: 'TaskFlagInterviewerDeclined' }
    & Pick<TaskFlagInterviewerDeclined, 'id' | 'resolvedAt'>
  ) | (
    { __typename?: 'TaskFlagRequestedChange' }
    & Pick<TaskFlagRequestedChange, 'id' | 'resolvedAt'>
  )> }
  & TaskCard_TaskFragment
);

export type TaskListQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type TaskListQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { tasks: Array<(
      { __typename?: 'Task' }
      & TaskList_TaskFragment
    )> }
    & ApplicationSidebarCreateTaskActions_ApplicationFragment
  )> }
);

export type TaskList_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & ApplicationSidebarCreateTaskActions_ApplicationFragment
);

export type TaskShareDialogQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;


export type TaskShareDialogQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { application: (
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'jobId'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'fullName' | 'coordinatorId' | 'recruiterId'>
      )> }
    ), availabilityRequests: Array<(
      { __typename?: 'CandidateAvailabilityOptions' }
      & Pick<CandidateAvailabilityOptions, 'id' | 'applicationId' | 'availabilityEndDate' | 'availabilityStartDate' | 'candidateNote' | 'externalId' | 'createdAt' | 'jobStageId' | 'minimumTimeBlockMinutes' | 'minutesPerDays' | 'numberOfDays' | 'timezone' | 'updatedAt' | 'lastCandidateCommunicationSentAt' | 'advanceNoticeMinutes'>
    )>, selfScheduleRequests: Array<(
      { __typename?: 'SelfScheduleOptions' }
      & Pick<SelfScheduleOptions, 'id' | 'applicationId' | 'createdAt' | 'creatorEmployeeId' | 'deletedAt' | 'jobStageId' | 'interviewerEventTemplateId' | 'candidateEventTemplateId' | 'candidateEmailTemplateId' | 'lastUpdatedByEmployeeId' | 'rescheduledAt' | 'selfScheduleEmailTemplateId' | 'selfScheduleEmailSentAt' | 'updatedAt'>
      & { options?: Maybe<(
        { __typename?: 'Options' }
        & Pick<Options, 'inclusionDays' | 'rollingDays' | 'advanceNoticeInHours' | 'candidateCalendarId' | 'interviewerCalendarId' | 'location' | 'zoomHost' | 'zoomHostUserId' | 'candidateEmail'>
      )> }
    )> }
  )> }
);

export type ApplicationContent_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'jobStageId'>
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )>, job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name'>
    & JobStageDetails_JobFragment
  )>, tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { jobStage: (
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
    ) }
  )> }
  & ApplicationAlert_ApplicationFragment
  & ApplicationDebriefs_ApplicationFragment
  & MoveJobStageButton_ApplicationFragment
  & TaskList_ApplicationFragment
  & MoveToNextStageAlert_ApplicationFragment
  & OpenTaskOnOtherJobStageAlert_ApplicationFragment
);

export type CandidateHeader_CandidateFullNameFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'fullName'>
);

export type AboutSettings_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id' | 'timezone' | 'updatedAt' | 'preferredEmail' | 'email' | 'linkedinUrl' | 'preferredPhoneNumber' | 'phoneNumbers'>
  & { atsCandidate?: Maybe<(
    { __typename?: 'AtsCandidate' }
    & Pick<AtsCandidate, 'atsUrl'>
  )> }
  & CandidateTimezoneSelect_CandidateFragment
  & EditCandidatePhoneNumberModal_CandidateFragment
);

export type AboutSettings_ApplicationFragment = (
  { __typename?: 'Application' }
  & { atsApplication?: Maybe<(
    { __typename?: 'AtsApplication' }
    & Pick<AtsApplication, 'atsType' | 'atsId' | 'atsUrl'>
  )>, jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )>, conversations?: Maybe<Array<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'remoteId' | 'remoteName' | 'isArchived'>
  )>> }
);

export type EditCandidatePhoneNumberModal_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id' | 'preferredPhoneNumber' | 'phoneNumbers'>
);

export type EditCandidatePhoneNumberMutationVariables = Exact<{
  input: CandidateUpdateInput;
}>;


export type EditCandidatePhoneNumberMutation = (
  { __typename?: 'Mutation' }
  & { candidateUpdate: (
    { __typename?: 'CandidateUpdateResult' }
    & { candidate: (
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id'>
      & EditCandidatePhoneNumberModal_CandidateFragment
    ) }
  ) }
);

export type CandidateInfo_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'atsType'>
  & { existingConversations?: Maybe<Array<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'remoteId' | 'remoteName' | 'isArchived'>
  )>>, atsApplication?: Maybe<(
    { __typename?: 'AtsApplication' }
    & Pick<AtsApplication, 'atsId' | 'atsUrl'>
  )> }
  & CandidatePortalSettings_ApplicationFragment
  & AboutSettings_ApplicationFragment
);

export type CandidateInfo_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id' | 'email' | 'preferredEmail' | 'linkedinUrl' | 'updatedAt' | 'timezone'>
  & { atsCandidate?: Maybe<(
    { __typename?: 'AtsCandidate' }
    & Pick<AtsCandidate, 'atsId' | 'atsUrl' | 'primaryEmail'>
  )> }
  & HiringTeam_CandidateFragment
  & AboutSettings_CandidateFragment
  & CandidateTimezoneSelect_CandidateFragment
  & CoordinatorSelect_CandidateFragment
  & RecruiterSelect_CandidateFragment
  & SourcerSelect_CandidateFragment
  & ConflictInterestSection_CandidateFragment
);

export type CandidatePortalActionsMenu_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'jobId' | 'jobStageId' | 'isPortalAccessible'>
);

export type ToggleCandidatePortalMutationVariables = Exact<{
  input: ApplicationUpdateInput;
}>;


export type ToggleCandidatePortalMutation = (
  { __typename?: 'Mutation' }
  & { applicationUpdate: (
    { __typename?: 'Application' }
    & CandidatePortalActionsMenu_ApplicationFragment
  ) }
);

export type ResetPrimaryEmailMutationVariables = Exact<{
  input: CandidateUpdateInput;
}>;


export type ResetPrimaryEmailMutation = (
  { __typename?: 'Mutation' }
  & { candidateUpdate: (
    { __typename?: 'CandidateUpdateResult' }
    & { candidate: (
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id' | 'email' | 'preferredEmail'>
    ) }
  ) }
);

export type DeleteCandidatePortalNoteMutationVariables = Exact<{
  applicationCandidatePortalNoteDeleteId: Scalars['uuid'];
}>;


export type DeleteCandidatePortalNoteMutation = (
  { __typename?: 'Mutation' }
  & { applicationCandidatePortalNoteDelete: (
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  ) }
);

export type CandidatePortalNoteEditor_CandidatePortalNoteFragment = (
  { __typename?: 'CandidatePortalNote' }
  & Pick<CandidatePortalNote, 'id' | 'content' | 'lastNotificationSentAt'>
);

export type CreateCandidatePortalNoteMutationVariables = Exact<{
  applicationCandidatePortalNoteCreateInput: ApplicationCandidatePortalNoteCreateInput;
}>;


export type CreateCandidatePortalNoteMutation = (
  { __typename?: 'Mutation' }
  & { applicationCandidatePortalNoteCreate: (
    { __typename?: 'CandidatePortalNote' }
    & Pick<CandidatePortalNote, 'id' | 'updatedAt' | 'lastNotificationSentAt'>
    & CandidatePortalNoteEditor_CandidatePortalNoteFragment
  ) }
);

export type UpdateCandidatePortalNoteMutationVariables = Exact<{
  input: ApplicationCandidatePortalNoteUpdateInput;
}>;


export type UpdateCandidatePortalNoteMutation = (
  { __typename?: 'Mutation' }
  & { applicationCandidatePortalNoteUpdate: (
    { __typename?: 'CandidatePortalNote' }
    & Pick<CandidatePortalNote, 'id' | 'updatedAt' | 'lastNotificationSentAt'>
    & CandidatePortalNoteEditor_CandidatePortalNoteFragment
  ) }
);

export type CandidatePortalNote_CandidatePortalNoteFragment = (
  { __typename?: 'CandidatePortalNote' }
  & Pick<CandidatePortalNote, 'id' | 'content' | 'updatedAt'>
  & CandidatePortalNoteEditor_CandidatePortalNoteFragment
);

export type CandidatePortalSettings_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'isPortalAccessible'>
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & { org?: Maybe<(
      { __typename?: 'Org' }
      & { slugs: Array<(
        { __typename?: 'PortalOrgSlug' }
        & Pick<PortalOrgSlug, 'orgId' | 'slug'>
      )> }
    )> }
  )>, candidatePortalNote?: Maybe<(
    { __typename?: 'CandidatePortalNote' }
    & Pick<CandidatePortalNote, 'id'>
    & CandidatePortalNote_CandidatePortalNoteFragment
  )> }
  & CandidatePortalActionsMenu_ApplicationFragment
);

export type ToggleCandidatePortalStatusMutationVariables = Exact<{
  input: ApplicationUpdateInput;
}>;


export type ToggleCandidatePortalStatusMutation = (
  { __typename?: 'Mutation' }
  & { applicationUpdate: (
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'isPortalAccessible'>
  ) }
);

export type ConflictInterestSection_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id'>
  & { conflictOfInterestEmployees: Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & EmployeeFragment
  )> }
);

export type CandidateUpdateCoiMutationVariables = Exact<{
  input: CandidateUpdateInput;
}>;


export type CandidateUpdateCoiMutation = (
  { __typename?: 'Mutation' }
  & { candidateUpdate: (
    { __typename?: 'CandidateUpdateResult' }
    & { candidate: (
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id'>
      & ConflictInterestSection_CandidateFragment
    ) }
  ) }
);

export type ApplicationNoteUpdateMutationMutationVariables = Exact<{
  input: ApplicationUpdateInput;
}>;


export type ApplicationNoteUpdateMutationMutation = (
  { __typename?: 'Mutation' }
  & { applicationUpdate: (
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'internalNote'>
  ) }
);

export type ApplicationNote_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'internalNote'>
  & ApplicationNoteEditor_ApplicationFragment
);

export type ApplicationNoteEditor_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'internalNote'>
);

export type InternalNoteUpdateMutationVariables = Exact<{
  input: ApplicationUpdateInput;
}>;


export type InternalNoteUpdateMutation = (
  { __typename?: 'Mutation' }
  & { applicationUpdate: (
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'internalNote'>
  ) }
);

export type ApplicationList_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'jobStageId' | 'status'>
  & { state?: Maybe<(
    { __typename?: 'ApplicationState' }
    & Pick<ApplicationState, 'isSchedulable' | 'isNotScheduleable' | 'isUnscheduled' | 'isAvailabilityRequested' | 'isReadyToSchedule' | 'isCanceled' | 'isOngoing' | 'isScheduled' | 'isCompleted' | 'isScheduledInAts' | 'isCompletedInAts' | 'isCandidateDeclined' | 'isInterviewerDeclined' | 'isSelfScheduleRequested'>
  )>, job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name' | 'jobStagesAggregate'>
  )>, jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name' | 'index'>
  )> }
);

export type CandidateSecondaryContent_ApplicationFragment = (
  { __typename?: 'Application' }
  & ApplicationNote_ApplicationFragment
  & CandidateInfo_ApplicationFragment
);

export type CandidateSecondaryContent_ApplicationsFragment = (
  { __typename?: 'Application' }
  & ApplicationList_ApplicationFragment
);

export type CandidateSecondaryContent_CandidateFragment = (
  { __typename?: 'Candidate' }
  & CandidateInfo_CandidateFragment
);

export type ApplicationTaskViewApplicationsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ApplicationTaskViewApplicationsQuery = (
  { __typename?: 'Query' }
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id'>
    & { applications?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
    )>> }
  )> }
);

export type ApplicationDetailsTaskViewQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type ApplicationDetailsTaskViewQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & { applications?: Maybe<Array<(
        { __typename?: 'Application' }
        & CandidateSecondaryContent_ApplicationsFragment
      )>> }
      & CandidateHeader_CandidateFullNameFragment
      & CandidateSecondaryContent_CandidateFragment
      & Candidate_CandidateFragment
    )> }
    & ApplicationContent_ApplicationFragment
    & ApplicationSidebarCreateTaskButton_ApplicationFragment
    & CandidateSecondaryContent_ApplicationFragment
  )> }
);

export type CandidateAvailabilityOptionsModalQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type CandidateAvailabilityOptionsModalQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & CreateTaskDialogPage_ApplicationFragment
  )>, thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & EmployeeFragment
  )> }
);

export type CandidateAvailabilityOptionsInterviewPlanQueryVariables = Exact<{
  jobStageId: Scalars['uuid'];
}>;


export type CandidateAvailabilityOptionsInterviewPlanQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
    & CreateTaskDialogPage_InterviewPlanFragment
    & CloneInterviewPlan_InterviewPlanFragment
    & GetSaveInterviewPlanInput_InterviewPlanFragment
    & CandidateAvailabilityOption_InterviewPlanFragment
  )> }
);

export type CandidateAvailabilityOptionsSettings_JobStageSettingsFragment = (
  { __typename?: 'JobStageSettings' }
  & Pick<JobStageSettings, 'availabilityRequestEmailTemplateId'>
  & CandidateAvailabilityOptions_JobStageSettingsFragment
);

export type BaseCandidateAvailabilityOption_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseIsInterviewPlanValid_JobStageFragment
  & UseInterviewPlanHasInterviewHiddenFromCandidate_InterviewPlanFragment
);

export type CandidateAvailabilityOption_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & BaseCandidateAvailabilityOption_InterviewPlanFragment
);

export type TaskInterviewPlanQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TaskInterviewPlanQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & { interviewPlan: (
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
    ) }
  )> }
);

export type CandidateAvailabilityOptionsDeleteMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CandidateAvailabilityOptionsDeleteMutation = (
  { __typename?: 'Mutation' }
  & { candidateAvailabilityOptionsDelete?: Maybe<(
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  )> }
);

export type CandidateAvailabilityTaskCreateMutationVariables = Exact<{
  input: TaskCreateInput;
}>;


export type CandidateAvailabilityTaskCreateMutation = (
  { __typename?: 'Mutation' }
  & { taskCreate?: Maybe<(
    { __typename?: 'TaskCreateResult' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    ) }
  )> }
);

export type CandidateAvailabilityRequestUpsertMutationVariables = Exact<{
  input: CandidateAvailabilityOptionsUpsertInput;
}>;


export type CandidateAvailabilityRequestUpsertMutation = (
  { __typename?: 'Mutation' }
  & { candidateAvailabilityOptionsUpsert?: Maybe<(
    { __typename?: 'CandidateAvailabilityOptionsUpsertResult' }
    & { options?: Maybe<(
      { __typename?: 'CandidateAvailabilityOptions' }
      & Pick<CandidateAvailabilityOptions, 'id'>
    )> }
  )> }
);

export type CandidateApplicationsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CandidateApplicationsQuery = (
  { __typename?: 'Query' }
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id'>
    & { applications?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'atsId'>
    )>> }
  )> }
);

export type ApplicationActionsQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type ApplicationActionsQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'status' | 'jobStageId'>
    & { upcomingApplicationStage?: Maybe<(
      { __typename?: 'ApplicationStage' }
      & Pick<ApplicationStage, 'minStartAt'>
    )>, applicationStages?: Maybe<Array<(
      { __typename?: 'ApplicationStage' }
      & Pick<ApplicationStage, 'id'>
    )>> }
  )> }
);

export type ApplicationStageStatusQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type ApplicationStageStatusQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { upcomingApplicationStage?: Maybe<(
      { __typename?: 'ApplicationStage' }
      & Pick<ApplicationStage, 'minStartAt'>
    )>, applicationStages?: Maybe<Array<(
      { __typename?: 'ApplicationStage' }
      & Pick<ApplicationStage, 'minStartAt' | 'maxEndAt' | 'isCancelled' | 'hasInterviewerDeclined' | 'hasInterviewerTentative' | 'hasCandidateDeclined' | 'hasCandidateTentative'>
      & CancelScheduleApplicationStageFragment
    )>> }
  )> }
);

export type ApplicationStageActionsQueryVariables = Exact<{
  applicationStageId: Scalars['uuid'];
}>;


export type ApplicationStageActionsQuery = (
  { __typename?: 'Query' }
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'isCancelled' | 'minStartAt' | 'maxEndAt' | 'hasInterviewerDeclined' | 'hasCandidateDeclined' | 'applicationId'>
    & { applicationStageInterviews?: Maybe<Array<(
      { __typename?: 'ApplicationStageInterview' }
      & Pick<ApplicationStageInterview, 'id' | 'startAt' | 'endAt'>
    )>> }
  )> }
);

export type CandidateApplicationStagesFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { applicationStages?: Maybe<Array<(
    { __typename?: 'ApplicationStage' }
    & CandidateApplicationStageFragment
  )>> }
  & CandidateApplicationStagesRowApplicationFragment
);

export type CandidateApplicationStagesQueryVariables = Exact<{
  input: Scalars['uuid'];
}>;


export type CandidateApplicationStagesQuery = (
  { __typename?: 'Query' }
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'fullName'>
    & { applications?: Maybe<Array<(
      { __typename?: 'Application' }
      & CandidateApplicationStagesFragment
    )>> }
  )> }
);

export type CandidateEventRowFragment = (
  { __typename?: 'ApplicationStageCandidateEvent' }
  & Pick<ApplicationStageCandidateEvent, 'id' | 'isCancelled' | 'startAt' | 'endAt' | 'responseStatus' | 'name' | 'googleEventId' | 'googleEventUrl' | 'googleCalendarId'>
);

export type CandidateInterviewRowFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'startAt' | 'endAt' | 'isCancelled' | 'name' | 'googleEventUrl'>
  & { applicationStageInterviewInterviewers?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterviewInterviewer' }
    & Pick<ApplicationStageInterviewInterviewer, 'applicationStageInterviewId' | 'interviewerId' | 'responseStatus' | 'role'>
    & { interviewer?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
    )> }
  )>> }
);

export type CandidateApplicationStagesRowApplicationFragment = (
  { __typename?: 'Application' }
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name'>
  )>, upcomingApplicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'minStartAt'>
  )> }
);

export type CandidateApplicationStageFragment = (
  { __typename?: 'ApplicationStage' }
  & Pick<ApplicationStage, 'id' | 'isCancelled' | 'timezone' | 'startAt' | 'endAt' | 'hasCandidateDeclined' | 'hasInterviewerDeclined' | 'hasCandidateTentative' | 'hasInterviewerTentative'>
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )>, applicationStageInterviews?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterview' }
    & CandidateInterviewRowFragment
  )>>, applicationStageCandidateEvents?: Maybe<Array<(
    { __typename?: 'ApplicationStageCandidateEvent' }
    & CandidateEventRowFragment
  )>> }
);

export type CancelScheduleApplicationStageFragment = (
  { __typename?: 'ApplicationStage' }
  & Pick<ApplicationStage, 'id' | 'minStartAt' | 'maxEndAt' | 'jobStageId'>
  & { cancelScheduleApplicationStageInterviews?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterview' }
    & Pick<ApplicationStageInterview, 'id' | 'startAt'>
  )>> }
);

export type CandidateAvailabilityStatus_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'status'>
);

export type CandidateAvailabilityStatus_AvailabilityRequestFragment = (
  { __typename?: 'CandidateAvailabilityOptions' }
  & RecjectetCandidateAvailabilitySectionAlert_AvailabilityRequestFragment
);

export type RecjectetCandidateAvailabilitySectionAlert_AvailabilityRequestFragment = (
  { __typename?: 'CandidateAvailabilityOptions' }
  & Pick<CandidateAvailabilityOptions, 'id' | 'updatedAt' | 'lastCandidateCommunicationSentAt'>
  & { creator?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'fullName'>
  )> }
);

export type BaseCandidateAvailabilitySection_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'status'>
  & CandidateAvailabilityStatus_ApplicationFragment
);

export type BaseCandidateAvailabilitySection_AvailabilityRequestFragment = (
  { __typename?: 'CandidateAvailabilityOptions' }
  & Pick<CandidateAvailabilityOptions, 'id'>
  & CandidateAvailabilityStatus_AvailabilityRequestFragment
);

export type CandidateHeader_AtsCandidateFragment = (
  { __typename?: 'AtsCandidate' }
  & { atsFields?: Maybe<{ __typename?: 'GreenhouseCandidate' } | (
    { __typename: 'LeverCandidate' }
    & Pick<LeverCandidate, 'origin'>
  ) | { __typename?: 'AshbyCandidate' } | { __typename?: 'WorkdayCandidate' }> }
);

export type CandidateAndAtsDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CandidateAndAtsDetailsQuery = (
  { __typename?: 'Query' }
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'fullName'>
    & { atsCandidate?: Maybe<(
      { __typename?: 'AtsCandidate' }
      & CandidateHeader_AtsCandidateFragment
    )> }
  )> }
);

export type ApplicationTaskQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type ApplicationTaskQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'isTaskEnabled'>
  )> }
);

export type JobStageFragmentFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id' | 'name' | 'jobId' | 'schedulable' | 'atsId' | 'index'>
);

export type CandidateDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type CandidateDetailsQuery = (
  { __typename?: 'Query' }
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'fullName' | 'lastActivityAt' | 'email' | 'coordinatorId' | 'recruiterId' | 'familyName' | 'givenName' | 'atsId' | 'atsType' | 'linkedinUrl' | 'phoneNumbers' | 'pronoun' | 'timezone' | 'externalId' | 'updatedAt' | 'additionalEmails'>
    & { atsCandidate?: Maybe<(
      { __typename?: 'AtsCandidate' }
      & Pick<AtsCandidate, 'atsId' | 'atsUrl'>
      & CandidateHeader_AtsCandidateFragment
    )>, recruiter?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'email' | 'isDisabled' | 'timezone' | 'atsId'>
      & { atsUser?: Maybe<(
        { __typename?: 'AtsUser' }
        & Pick<AtsUser, 'id' | 'atsId'>
      )> }
    )>, coordinator?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'email' | 'isDisabled' | 'timezone' | 'atsId'>
      & { atsUser?: Maybe<(
        { __typename?: 'AtsUser' }
        & Pick<AtsUser, 'id' | 'atsId'>
      )> }
    )>, applications?: Maybe<Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'status' | 'jobStageCount'>
      & { existingConversations?: Maybe<Array<(
        { __typename?: 'Conversation' }
        & Pick<Conversation, 'id' | 'remoteId' | 'remoteName' | 'isArchived'>
      )>>, job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name' | 'atsId' | 'atsType' | 'location' | 'jobStagesAggregate'>
        & { sourcers?: Maybe<Array<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
        )>>, hiringManagers?: Maybe<Array<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
        )>> }
      )>, jobStage?: Maybe<(
        { __typename?: 'JobStage' }
        & Pick<JobStage, 'id' | 'name' | 'jobId' | 'schedulable' | 'atsId' | 'index'>
      )>, applicationStages?: Maybe<Array<(
        { __typename?: 'ApplicationStage' }
        & Pick<ApplicationStage, 'id' | 'startAt' | 'endAt' | 'createdAt' | 'updatedAt' | 'name' | 'timezone' | 'selfScheduleOptionsId' | 'jobStageId' | 'isHoldForRsvp' | 'isReadyForCandidateComms' | 'hasCandidateTentative' | 'hasInterviewerTentative' | 'isCancelled' | 'hasCandidateDeclined' | 'hasInterviewerDeclined'>
        & { creator?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'fullName'>
        )>, jobStage?: Maybe<(
          { __typename?: 'JobStage' }
          & Pick<JobStage, 'id' | 'name' | 'index'>
        )>, applicationStageCandidateEvents?: Maybe<Array<(
          { __typename?: 'ApplicationStageCandidateEvent' }
          & Pick<ApplicationStageCandidateEvent, 'id' | 'startAt' | 'endAt' | 'responseStatus' | 'googleEventUrl' | 'isCancelled' | 'name' | 'googleEventId' | 'googleCalendarId'>
        )>>, applicationStageInterviews?: Maybe<Array<(
          { __typename?: 'ApplicationStageInterview' }
          & Pick<ApplicationStageInterview, 'id' | 'startAt' | 'endAt' | 'name' | 'calendarEventTitle' | 'isCancelled' | 'googleEventId' | 'googleEventUrl' | 'googleCalendarId'>
          & { applicationStageInterviewInterviewers?: Maybe<Array<(
            { __typename?: 'ApplicationStageInterviewInterviewer' }
            & Pick<ApplicationStageInterviewInterviewer, 'role' | 'isOptional' | 'interviewerId' | 'responseStatus' | 'applicationStageInterviewId'>
            & { interviewer?: Maybe<(
              { __typename?: 'Employee' }
              & Pick<Employee, 'id' | 'slackImageUrl' | 'fullName'>
            )> }
          )>>, applicationStageInterviewRooms?: Maybe<Array<(
            { __typename?: 'ApplicationStageInterviewRoom' }
            & Pick<ApplicationStageInterviewRoom, 'applicationStageInterviewId' | 'meetingRoomId' | 'responseStatus'>
            & { meetingRoom?: Maybe<(
              { __typename?: 'MeetingRoom' }
              & Pick<MeetingRoom, 'id' | 'name'>
            )> }
          )>> }
        )>> }
      )>>, applicationDebriefs?: Maybe<Array<(
        { __typename?: 'ApplicationDebrief' }
        & Pick<ApplicationDebrief, 'id' | 'startAt' | 'endAt' | 'isCancelled' | 'isPrivateCalendarEvent'>
        & { attendees?: Maybe<Array<(
          { __typename?: 'ApplicationDebriefAttendee' }
          & Pick<ApplicationDebriefAttendee, 'interviewerId' | 'responseStatus' | 'isOptional'>
          & { interviewer?: Maybe<(
            { __typename?: 'Employee' }
            & Pick<Employee, 'id' | 'fullName' | 'email' | 'slackImageUrl'>
          )> }
        )>>, meetingRooms?: Maybe<Array<(
          { __typename?: 'ApplicationDebriefRoom' }
          & Pick<ApplicationDebriefRoom, 'applicationDebriefId' | 'meetingRoomId' | 'responseStatus'>
        )>> }
      )>>, state?: Maybe<(
        { __typename?: 'ApplicationState' }
        & Pick<ApplicationState, 'isSchedulable' | 'isNotScheduleable' | 'isUnscheduled' | 'isAvailabilityRequested' | 'isReadyToSchedule' | 'isCanceled' | 'isOngoing' | 'isScheduled' | 'isCompleted' | 'isScheduledInAts' | 'isCompletedInAts' | 'isCandidateDeclined' | 'isInterviewerDeclined' | 'isSelfScheduleRequested' | 'isHoldForRsvp' | 'isReadyForCandidateComms'>
      )>, upcomingApplicationStage?: Maybe<(
        { __typename?: 'ApplicationStage' }
        & Pick<ApplicationStage, 'id' | 'startAt' | 'endAt' | 'selfScheduleOptionsId' | 'minStartAt'>
        & { applicationStageInterviews?: Maybe<Array<(
          { __typename?: 'ApplicationStageInterview' }
          & Pick<ApplicationStageInterview, 'id' | 'startAt' | 'endAt'>
        )>> }
      )> }
      & ApplicationNote_ApplicationFragment
    )>> }
  )> }
);

export type JobStageDetailsQueryVariables = Exact<{
  input: JobStagesInput;
}>;


export type JobStageDetailsQuery = (
  { __typename?: 'Query' }
  & { jobStages?: Maybe<(
    { __typename?: 'JobStagesOutput' }
    & { items: Array<(
      { __typename?: 'JobStage' }
      & JobStageFragmentFragment
    )> }
  )> }
);

export type JobStageUpdatedAtQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type JobStageUpdatedAtQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'updatedAt'>
  )> }
);

export type CandidateAvailabilityFragmentFragment = (
  { __typename?: 'CandidateAvailability' }
  & Pick<CandidateAvailability, 'id' | 'createdAt' | 'updatedAt' | 'lastUpdatedByEmployeeId' | 'employeeUpdatedAt' | 'candidateUpdatedAt'>
  & { candidateEnteredAvailability?: Maybe<Array<(
    { __typename?: 'DateTimeRangeOutput' }
    & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
  )>>, rcEnteredAvailability?: Maybe<Array<(
    { __typename?: 'DateTimeRangeOutput' }
    & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
  )>>, lastUpdatedByEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName'>
  )> }
);

export type ConfidentialJobByJobStageQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ConfidentialJobByJobStageQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'isConfidential' | 'id'>
    )> }
  )> }
);

export type ConfidentialJobByApplicationQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ConfidentialJobByApplicationQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'isConfidential' | 'id'>
    )> }
  )> }
);

export type ApplicationJobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name'>
);

export type ApplicationJobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id' | 'name' | 'index'>
);

export type ApplicationCandidateEmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
);

export type ApplicationCandidateBaseFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id' | 'fullName' | 'lastActivityAt' | 'email'>
);

export type ApplicationItemBaseFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'atsType' | 'status' | 'jobStageCount'>
  & { state?: Maybe<(
    { __typename?: 'ApplicationState' }
    & Pick<ApplicationState, 'isSchedulable' | 'isNotScheduleable' | 'isUnscheduled' | 'isAvailabilityRequested' | 'isReadyToSchedule' | 'isCanceled' | 'isOngoing' | 'isScheduled' | 'isCompleted' | 'isScheduledInAts' | 'isCompletedInAts' | 'isCandidateDeclined' | 'isInterviewerDeclined' | 'isSelfScheduleRequested' | 'isHoldForRsvp' | 'isReadyForCandidateComms'>
  )>, upcomingApplicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'startAt' | 'endAt' | 'selfScheduleOptionsId' | 'minStartAt'>
    & { applicationStageInterviews?: Maybe<Array<(
      { __typename?: 'ApplicationStageInterview' }
      & Pick<ApplicationStageInterview, 'id' | 'startAt' | 'endAt'>
    )>> }
  )> }
);

export type ApplicationItemFragment = (
  { __typename?: 'Application' }
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & ApplicationJobFragment
  )>, jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & ApplicationJobStageFragment
  )>, candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & { coordinator?: Maybe<(
      { __typename?: 'Employee' }
      & ApplicationCandidateEmployeeFragment
    )>, recruiter?: Maybe<(
      { __typename?: 'Employee' }
      & ApplicationCandidateEmployeeFragment
    )> }
    & ApplicationCandidateBaseFragment
  )>, tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & TaskCount_TasksFragment
  )> }
  & ApplicationItemBaseFragment
);

export type CandidateListQueryVariables = Exact<{
  pageInput: PageInput;
  candidateName?: Maybe<Scalars['String']>;
  jobIds?: Maybe<Array<Maybe<Scalars['uuid']>> | Maybe<Scalars['uuid']>>;
  jobStageNames?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  hiringTeamMemberIds?: Maybe<Array<Maybe<Scalars['uuid']>> | Maybe<Scalars['uuid']>>;
  coordinatorIds?: Maybe<Array<Maybe<Scalars['uuid']>> | Maybe<Scalars['uuid']>>;
  recruiterIds?: Maybe<Array<Maybe<Scalars['uuid']>> | Maybe<Scalars['uuid']>>;
  showDeclines?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<ApplicationSearchOrderBy>;
  statusFilterGroups?: Maybe<Array<Maybe<StatusFilterGroup>> | Maybe<StatusFilterGroup>>;
  applicationStatuses?: Maybe<Array<ApplicationStatus> | ApplicationStatus>;
}>;


export type CandidateListQuery = (
  { __typename?: 'Query' }
  & { applicationSearch?: Maybe<(
    { __typename?: 'ApplicationSearchOutput' }
    & Pick<ApplicationSearchOutput, 'total'>
    & { items: Array<(
      { __typename?: 'Application' }
      & ApplicationItemFragment
    )> }
  )> }
);

export type CandidatePageFiltersEmployeePrefFragment = (
  { __typename?: 'CandidatePageFiltersPref' }
  & Pick<CandidatePageFiltersPref, 'coordinatorEmployeeIds' | 'jobIds' | 'jobStageNames' | 'recruiterEmployeeIds' | 'showInactiveApplications'>
);

export type CandidatePageFiltersEmployeePrefQueryVariables = Exact<{
  prefName: Scalars['String'];
}>;


export type CandidatePageFiltersEmployeePrefQuery = (
  { __typename?: 'Query' }
  & { thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
      & { jsonPref?: Maybe<{ __typename?: 'InterviewLimit' } | { __typename?: 'EmployeeTaskDashboardLayoutPref' } | { __typename?: 'EmployeeTaskDashboardFilterPref' } | (
        { __typename?: 'CandidatePageFiltersPref' }
        & CandidatePageFiltersEmployeePrefFragment
      ) | { __typename?: 'MetricsPageRecruitingTeamFiltersPref' } | { __typename?: 'MetricsPageInterviewerFiltersPref' } | { __typename?: 'DirectoryPageFiltersPref' } | { __typename?: 'UpcomingEventsPageFiltersPref' } | { __typename?: 'RecentlyUsedZoomUsersPref' } | { __typename?: 'ProfilePicturePref' } | { __typename?: 'InterviewBufferTimePref' }> }
    )> }
  )> }
);

export type UpdateCandidatePageFiltersEmployeePrefMutationVariables = Exact<{
  input: EmployeePrefUpdateInput;
}>;


export type UpdateCandidatePageFiltersEmployeePrefMutation = (
  { __typename?: 'Mutation' }
  & { employeePrefUpdate?: Maybe<(
    { __typename?: 'EmployeePrefUpdateResult' }
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
    )> }
  )> }
);

export type EmployeeDataBulkUpsertMutationVariables = Exact<{
  name: Scalars['String'];
  path: Scalars['String'];
  dataType: EmployeeBulkUploadDataType;
}>;


export type EmployeeDataBulkUpsertMutation = (
  { __typename?: 'Mutation' }
  & { employeeDataBulkUpsert?: Maybe<(
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  )> }
);

export type CalendarPermissionsQueryVariables = Exact<{
  applicationStageId: Scalars['uuid'];
}>;


export type CalendarPermissionsQuery = (
  { __typename?: 'Query' }
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id'>
    & { application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'candidateId'>
    )>, creator?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'orgId' | 'email' | 'fullName' | 'slackImageUrl'>
    )>, applicationStageCandidateEvents?: Maybe<Array<(
      { __typename?: 'ApplicationStageCandidateEvent' }
      & Pick<ApplicationStageCandidateEvent, 'googleCalendarId' | 'startAt' | 'endAt' | 'name' | 'googleEventId'>
      & { calendar?: Maybe<(
        { __typename?: 'Calendar' }
        & Pick<Calendar, 'name' | 'isPartOfCalendarList' | 'isReadOnly'>
      )> }
    )>>, applicationStageInterviews?: Maybe<Array<(
      { __typename?: 'ApplicationStageInterview' }
      & Pick<ApplicationStageInterview, 'calendarEventTitle' | 'googleCalendarId' | 'id' | 'name' | 'startAt' | 'endAt'>
      & { calendar?: Maybe<(
        { __typename?: 'Calendar' }
        & Pick<Calendar, 'id' | 'name' | 'remoteId' | 'employeeId' | 'isPartOfCalendarList' | 'isReadOnly'>
      )> }
    )>> }
  )> }
);

export type CandidateWithStatusIcon_ApplicationFragment = (
  { __typename?: 'Application' }
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'fullName'>
    & Candidate_CandidateFragment
  )> }
  & ApplicationStatusIcon_ApplicationFragment
);

export type ApprovalFeedback_FeedbackFragment = (
  { __typename?: 'ApplicationStageInterviewFeedback' }
  & Pick<ApplicationStageInterviewFeedback, 'reviewerId' | 'decisionType' | 'reviewedAt'>
  & { reviewer?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName'>
  )> }
);

export type ApprovalFeedback_TraineeFeedbackFragment = (
  { __typename?: 'ApplicationStageInterviewFeedback' }
  & ApprovalFeedback_FeedbackFragment
);

export type ApprovalFeedback_TrainedFeedbackFragment = (
  { __typename?: 'ApplicationStageInterviewFeedback' }
  & ApprovalFeedback_FeedbackFragment
);

export type ApprovalFeedback_SchedulerFeedbackFragment = (
  { __typename?: 'ApplicationStageInterviewFeedback' }
  & ApprovalFeedback_FeedbackFragment
);

export type SlackFeedbackNagMutationVariables = Exact<{
  input: TrainingFeedbackReminderNagInput;
}>;


export type SlackFeedbackNagMutation = (
  { __typename?: 'Mutation' }
  & { trainingFeedbackReminderNag?: Maybe<(
    { __typename?: 'InterviewModuleMemberResult' }
    & { interviewModuleMember?: Maybe<(
      { __typename?: 'InterviewModuleMember' }
      & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
    )> }
  )> }
);

export type Interviewfeedback_InterviewfeedbackFragment = (
  { __typename?: 'ApplicationStageInterviewFeedback' }
  & Pick<ApplicationStageInterviewFeedback, 'decisionType' | 'note' | 'reviewerId' | 'reviewedAt'>
  & { reviewer?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )> }
);

export type InterviewLogInterview_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'employeeId' | 'interviewModuleId'>
);

export type InterviewLogInterview_InterviewFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'name' | 'startAt' | 'endAt' | 'googleEventUrl' | 'isHiddenFromCandidate'>
  & { applicationStageInterviewInterviewers?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterviewInterviewer' }
    & Pick<ApplicationStageInterviewInterviewer, 'responseStatus' | 'interviewerId'>
  )>>, applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'taskId'>
    & { application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'fullName'>
      )>, job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name'>
      )>, jobStage?: Maybe<(
        { __typename?: 'JobStage' }
        & Pick<JobStage, 'id' | 'name'>
      )> }
    )> }
  )>, traineeFeedback?: Maybe<(
    { __typename?: 'ApplicationStageInterviewFeedback' }
    & Interviewfeedback_InterviewfeedbackFragment
    & ApprovalFeedback_TraineeFeedbackFragment
  )>, trainedFeedback?: Maybe<(
    { __typename?: 'ApplicationStageInterviewFeedback' }
    & Interviewfeedback_InterviewfeedbackFragment
    & ApprovalFeedback_TrainedFeedbackFragment
  )>, schedulerFeedback?: Maybe<(
    { __typename?: 'ApplicationStageInterviewFeedback' }
    & Interviewfeedback_InterviewfeedbackFragment
    & ApprovalFeedback_SchedulerFeedbackFragment
  )> }
);

export type InterviewLog_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'employeeId' | 'interviewModuleId'>
  & InterviewLogInterview_ModuleMemberFragment
);

type InterviewLog_InterviewLog_ModuleMemberApplicationStageInterviewLog_Fragment = (
  { __typename: 'ModuleMemberApplicationStageInterviewLog' }
  & Pick<ModuleMemberApplicationStageInterviewLog, 'id' | 'trainingRole'>
  & { applicationStageInterview: (
    { __typename?: 'ApplicationStageInterview' }
    & InterviewLogInterview_InterviewFragment
  ) }
);

type InterviewLog_InterviewLog_ModuleMemberManualLog_Fragment = (
  { __typename: 'ModuleMemberManualLog' }
  & Pick<ModuleMemberManualLog, 'id' | 'trainingRole'>
);

export type InterviewLog_InterviewLogFragment = InterviewLog_InterviewLog_ModuleMemberApplicationStageInterviewLog_Fragment | InterviewLog_InterviewLog_ModuleMemberManualLog_Fragment;

export type InterviewModuleMemberHeader_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'interviewModuleId' | 'employeeId' | 'status'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>, interviewModule?: Maybe<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'id' | 'name'>
  )> }
  & ModuleMemberPausedChip_ModuleMemberFragment
  & ModuleMemberPendingChip_ModuleMemberFragment
);

export type InterviewModuleMemberKeyDates_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'interviewModuleId' | 'employeeId' | 'shadowStartAt' | 'reverseShadowStartAt' | 'shadowsRequired' | 'reverseShadowsRequired' | 'status' | 'createdAt' | 'trainedAt' | 'timeToTrain'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>, interviewModule?: Maybe<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'id' | 'name'>
  )> }
  & EditKeyDatesModal_ModuleMemberFragment
);

export type AddModuleLog_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'employeeId' | 'interviewModuleId' | 'shadowsRequired' | 'status' | 'reverseShadowsRequired'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'completedAsReverseShadow' | 'completedAsShadow' | 'manualCompletedAsReverseShadow' | 'manualCompletedAsShadow'>
  )> }
  & EditTrainigPlan_ModuleMemberFragment
  & GetReverseShadowPendingCount_ModuleMemberFragment
);

export type InterviewModuleMemberLogCreateMutationVariables = Exact<{
  input: InterviewModuleMemberLogCreateInput;
}>;


export type InterviewModuleMemberLogCreateMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleMemberLogCreate?: Maybe<(
    { __typename?: 'InterviewModuleMemberManualLogResult' }
    & { interviewModuleMemberManualLog?: Maybe<(
      { __typename?: 'InterviewModuleMemberManualLog' }
      & Pick<InterviewModuleMemberManualLog, 'id'>
    )> }
  )> }
);

export type AddToTrainingPlan_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'shadowsRequired' | 'reverseShadowsRequired'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'completedAsShadow' | 'completedAsReverseShadow' | 'manualCompletedAsShadow' | 'manualCompletedAsReverseShadow'>
  )> }
);

type DeleteModuleLogModal_ModuleMemberInterview_ModuleMemberApplicationStageInterviewLog_Fragment = (
  { __typename?: 'ModuleMemberApplicationStageInterviewLog' }
  & Pick<ModuleMemberApplicationStageInterviewLog, 'id' | 'trainingRole' | 'status'>
);

type DeleteModuleLogModal_ModuleMemberInterview_ModuleMemberManualLog_Fragment = (
  { __typename?: 'ModuleMemberManualLog' }
  & Pick<ModuleMemberManualLog, 'id' | 'trainingRole' | 'status'>
);

export type DeleteModuleLogModal_ModuleMemberInterviewFragment = DeleteModuleLogModal_ModuleMemberInterview_ModuleMemberApplicationStageInterviewLog_Fragment | DeleteModuleLogModal_ModuleMemberInterview_ModuleMemberManualLog_Fragment;

export type DeleteModuleLogModal_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'employeeId' | 'interviewModuleId' | 'shadowsRequired' | 'status'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'completedAsShadow' | 'manualCompletedAsShadow'>
  )> }
);

export type InterviewModuleMemberLogDeleteMutationVariables = Exact<{
  input: InterviewModuleMemberLogDeleteInput;
}>;


export type InterviewModuleMemberLogDeleteMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleMemberLogDelete?: Maybe<(
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  )> }
);

export type EditKeyDatesModal_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'interviewModuleId' | 'employeeId' | 'trainedAt' | 'createdAt'>
);

export type EditTrainigPlan_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'employeeId' | 'interviewModuleId' | 'status' | 'shadowsRequired' | 'reverseShadowsRequired'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'completedAsReverseShadow' | 'completedAsShadow' | 'manualCompletedAsShadow' | 'manualCompletedAsReverseShadow'>
  )>, interviewModule?: Maybe<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'id' | 'shadowsRequired' | 'reverseShadowsRequired' | 'shouldFastTrackTraining' | 'graduateFromShadowApprovalType' | 'graduateFromReverseShadowApprovalType'>
  )> }
);

export type PauseMemberModalInterviewModuleMemberUpdateMutationVariables = Exact<{
  input: InterviewModuleMemberUpdateInput;
}>;


export type PauseMemberModalInterviewModuleMemberUpdateMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleMemberUpdate?: Maybe<(
    { __typename?: 'InterviewModuleMemberResult' }
    & { interviewModuleMember?: Maybe<(
      { __typename?: 'InterviewModuleMember' }
      & PauseMemberModal_ModuleMemberFragment
    )> }
  )> }
);

export type PauseMemberModal_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'employeeId' | 'interviewModuleId' | 'isPausedIndefinitely' | 'isPaused' | 'pausedUntil'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'timezone'>
  )> }
);

export type RemoveMemberModal_InterviewModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'employeeId' | 'interviewModuleId'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'upcoming'>
  )> }
);

export type InterviewModuleDeleteMemberMutationVariables = Exact<{
  input: InterviewModuleRemoveMembersInput;
}>;


export type InterviewModuleDeleteMemberMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleRemoveMembers?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id'>
    )> }
  )> }
);

export type SetModuleMemberAsTrained_InterviewModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'employeeId' | 'interviewModuleId'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'upcoming'>
  )> }
);

export type SetModuleMemberAsTrainee_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'employeeId' | 'interviewModuleId'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'upcoming'>
  )> }
  & AddToTrainingPlan_ModuleMemberFragment
);

export type InterviewModuleMemberLogUpdateMutationVariables = Exact<{
  input: InterviewModuleMemberLogUpdateInput;
}>;


export type InterviewModuleMemberLogUpdateMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleMemberLogUpdate?: Maybe<(
    { __typename?: 'InterviewModuleMemberManualLogResult' }
    & { interviewModuleMemberManualLog?: Maybe<(
      { __typename?: 'InterviewModuleMemberManualLog' }
      & Pick<InterviewModuleMemberManualLog, 'id'>
    )> }
  )> }
);

export type ApplicationStageInterviewInterviewerUpsertMutationVariables = Exact<{
  input: ApplicationStageInterviewInterviewerUpsertInput;
}>;


export type ApplicationStageInterviewInterviewerUpsertMutation = (
  { __typename?: 'Mutation' }
  & { applicationStageInterviewInterviewerUpsert?: Maybe<(
    { __typename?: 'ApplicationStageInterviewInterviewerResult' }
    & Pick<ApplicationStageInterviewInterviewerResult, 'result'>
  )> }
);

type UpdateModuleLogModal_ModuleMemberInterview_ModuleMemberApplicationStageInterviewLog_Fragment = (
  { __typename: 'ModuleMemberApplicationStageInterviewLog' }
  & Pick<ModuleMemberApplicationStageInterviewLog, 'id' | 'interviewDate' | 'trainingRole' | 'status'>
  & { applicationStageInterview: (
    { __typename?: 'ApplicationStageInterview' }
    & Pick<ApplicationStageInterview, 'id'>
  ) }
  & DeleteModuleLogModal_ModuleMemberInterview_ModuleMemberApplicationStageInterviewLog_Fragment
);

type UpdateModuleLogModal_ModuleMemberInterview_ModuleMemberManualLog_Fragment = (
  { __typename: 'ModuleMemberManualLog' }
  & Pick<ModuleMemberManualLog, 'id' | 'interviewDate' | 'trainingRole' | 'status'>
  & DeleteModuleLogModal_ModuleMemberInterview_ModuleMemberManualLog_Fragment
);

export type UpdateModuleLogModal_ModuleMemberInterviewFragment = UpdateModuleLogModal_ModuleMemberInterview_ModuleMemberApplicationStageInterviewLog_Fragment | UpdateModuleLogModal_ModuleMemberInterview_ModuleMemberManualLog_Fragment;

export type UpdateModuleLogModal_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'employeeId' | 'interviewModuleId' | 'shadowsRequired' | 'status' | 'reverseShadowsRequired'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'completedAsShadow' | 'completedAsReverseShadow' | 'manualCompletedAsShadow' | 'manualCompletedAsReverseShadow'>
  )> }
  & DeleteModuleLogModal_ModuleMemberFragment
  & GetReverseShadowCompletedCount_ModuleMemberFragment
);

export type ModuleLoadPauseRow_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'interviewModuleId' | 'employeeId' | 'weeklyNumInterviewLimit'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'isPaused' | 'timezone'>
    & { interviewPauseDates?: Maybe<Array<(
      { __typename?: 'DateBlock' }
      & Pick<DateBlock, 'start' | 'end'>
    )>> }
  )> }
  & PauseMemberModal_ModuleMemberFragment
  & IsModuleMemberEmployeePaused_ModuleMemberFragment
  & IsModuleMembreOrEmployeePaused_ModuleMemberFragment
  & IsModuleMemberPauseIndefinitely_ModuleMemberFragment
);

export type ModuleLoadPauseRowInterviewModuleMemberUpdateMutationVariables = Exact<{
  input: InterviewModuleMemberUpdateInput;
}>;


export type ModuleLoadPauseRowInterviewModuleMemberUpdateMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleMemberUpdate?: Maybe<(
    { __typename?: 'InterviewModuleMemberResult' }
    & { interviewModuleMember?: Maybe<(
      { __typename?: 'InterviewModuleMember' }
      & ModuleLoadPauseRow_ModuleMemberFragment
    )> }
  )> }
);

export type ModuleLoad_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'interviewModuleId' | 'employeeId' | 'weeklyNumInterviewLimit'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'isPaused' | 'timezone'>
    & { interviewPauseDates?: Maybe<Array<(
      { __typename?: 'DateBlock' }
      & Pick<DateBlock, 'start' | 'end'>
    )>> }
  )> }
  & PauseMemberModal_ModuleMemberFragment
  & ModuleLoadPauseRow_ModuleMemberFragment
  & IsModuleMemberEmployeePaused_ModuleMemberFragment
  & IsModuleMembreOrEmployeePaused_ModuleMemberFragment
  & IsModuleMemberPauseIndefinitely_ModuleMemberFragment
  & GetModuleMemberEmployeePausedText_ModuleMemberFragment
  & GetModuleMemberPauseText_ModuleMemberFragment
);

export type ModuleLoadInterviewModuleMemberUpdateMutationVariables = Exact<{
  input: InterviewModuleMemberUpdateInput;
}>;


export type ModuleLoadInterviewModuleMemberUpdateMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleMemberUpdate?: Maybe<(
    { __typename?: 'InterviewModuleMemberResult' }
    & { interviewModuleMember?: Maybe<(
      { __typename?: 'InterviewModuleMember' }
      & ModuleLoad_ModuleMemberFragment
    )> }
  )> }
);

export type InterviewModuleMemberModuleLogRow_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'employeeId' | 'status' | 'interviewModuleId'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'currentTrainingSessionStatus'>
  )> }
  & UpdateModuleLogModal_ModuleMemberFragment
  & InterviewLog_ModuleMemberFragment
);

type InterviewModuleMemberModuleLogRow_ModuleMemberInterview_ModuleMemberApplicationStageInterviewLog_Fragment = (
  { __typename: 'ModuleMemberApplicationStageInterviewLog' }
  & Pick<ModuleMemberApplicationStageInterviewLog, 'id' | 'interviewDate' | 'trainingRole' | 'status'>
  & { applicationStageInterview: (
    { __typename?: 'ApplicationStageInterview' }
    & Pick<ApplicationStageInterview, 'startAt' | 'endAt' | 'id'>
  ) }
  & UpdateModuleLogModal_ModuleMemberInterview_ModuleMemberApplicationStageInterviewLog_Fragment
  & InterviewLog_InterviewLog_ModuleMemberApplicationStageInterviewLog_Fragment
);

type InterviewModuleMemberModuleLogRow_ModuleMemberInterview_ModuleMemberManualLog_Fragment = (
  { __typename: 'ModuleMemberManualLog' }
  & Pick<ModuleMemberManualLog, 'id' | 'interviewDate' | 'trainingRole' | 'status'>
  & UpdateModuleLogModal_ModuleMemberInterview_ModuleMemberManualLog_Fragment
  & InterviewLog_InterviewLog_ModuleMemberManualLog_Fragment
);

export type InterviewModuleMemberModuleLogRow_ModuleMemberInterviewFragment = InterviewModuleMemberModuleLogRow_ModuleMemberInterview_ModuleMemberApplicationStageInterviewLog_Fragment | InterviewModuleMemberModuleLogRow_ModuleMemberInterview_ModuleMemberManualLog_Fragment;

export type InterviewModuleMemberModuleLogSidepanelQueryVariables = Exact<{
  interviewModuleId: Scalars['uuid'];
  moduleMemberId: Scalars['uuid'];
  moduleMemberInterviewLogsInput: ModuleMemberInterviewLogInput;
}>;


export type InterviewModuleMemberModuleLogSidepanelQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMember?: Maybe<(
    { __typename?: 'InterviewModuleMember' }
    & Pick<InterviewModuleMember, 'id' | 'interviewModuleId' | 'employeeId'>
    & { moduleMemberInterviewLogs?: Maybe<(
      { __typename?: 'ModuleMemberInterviewLogOutput' }
      & { items: Array<(
        { __typename?: 'ModuleMemberApplicationStageInterviewLog' }
        & InterviewModuleMemberModuleLogRow_ModuleMemberInterview_ModuleMemberApplicationStageInterviewLog_Fragment
      ) | (
        { __typename?: 'ModuleMemberManualLog' }
        & InterviewModuleMemberModuleLogRow_ModuleMemberInterview_ModuleMemberManualLog_Fragment
      )> }
    )> }
  )> }
);

export type InterviewModuleMemberModuleLog_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'interviewModuleId' | 'employeeId'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>, interviewModule?: Maybe<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'id' | 'name'>
  )>, stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'currentTrainingSessionStatus'>
  )> }
  & AddModuleLog_ModuleMemberFragment
  & InterviewModuleMemberModuleLogRow_ModuleMemberFragment
);

export type ActionButtons_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'interviewModuleId' | 'employeeId' | 'status' | 'shadowsRequired' | 'reverseShadowsRequired'>
  & { pendingApprovalApplicationStageInterview?: Maybe<(
    { __typename?: 'ApplicationStageInterview' }
    & Pick<ApplicationStageInterview, 'id'>
  )>, stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'currentTrainingSessionStatus' | 'completedAsShadow' | 'completedAsReverseShadow' | 'manualCompletedAsShadow' | 'manualCompletedAsReverseShadow'>
  )> }
  & GetReverseShadowCompletedCount_ModuleMemberFragment
  & GetShadowCompletedCount_ModuleMemberFragment
  & GetReverseShadowPendingCount_ModuleMemberFragment
);

export type PendingApprovalAlert_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'employeeId' | 'interviewModuleId'>
  & { pendingApprovalApplicationStageInterview?: Maybe<(
    { __typename?: 'ApplicationStageInterview' }
    & Pick<ApplicationStageInterview, 'id'>
    & { traineeFeedback?: Maybe<(
      { __typename?: 'ApplicationStageInterviewFeedback' }
      & Pick<ApplicationStageInterviewFeedback, 'reviewerId' | 'reviewedAt'>
    )>, trainedFeedback?: Maybe<(
      { __typename?: 'ApplicationStageInterviewFeedback' }
      & Pick<ApplicationStageInterviewFeedback, 'reviewerId' | 'reviewedAt'>
    )> }
  )> }
);

export type PendingApproval_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'interviewModuleId' | 'employeeId' | 'status'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'currentTrainingSessionStatus'>
  )>, pendingApprovalApplicationStageInterview?: Maybe<(
    { __typename?: 'ApplicationStageInterview' }
    & Pick<ApplicationStageInterview, 'id' | 'startAt'>
    & InterviewLogInterview_InterviewFragment
  )> }
  & ActionButtons_ModuleMemberFragment
  & InterviewLogInterview_ModuleMemberFragment
  & PendingApprovalAlert_ModuleMemberFragment
);

export type PendingApprovalApplicationStageInterviewInterviewerUpsertMutationVariables = Exact<{
  input: ApplicationStageInterviewInterviewerUpsertInput;
}>;


export type PendingApprovalApplicationStageInterviewInterviewerUpsertMutation = (
  { __typename?: 'Mutation' }
  & { applicationStageInterviewInterviewerUpsert?: Maybe<(
    { __typename?: 'ApplicationStageInterviewInterviewerResult' }
    & Pick<ApplicationStageInterviewInterviewerResult, 'result'>
  )> }
);

export type TrainingPlanSection_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'employeeId' | 'interviewModuleId' | 'status' | 'shadowsRequired' | 'reverseShadowsRequired' | 'trainedAt'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'currentTrainingSessionStatus'>
  )> }
  & GetReverseShadowCompletedCount_ModuleMemberFragment
  & GetReverseShadowPendingCount_ModuleMemberFragment
  & GetShadowCompletedCount_ModuleMemberFragment
  & GetShadowPendingCount_ModuleMemberFragment
  & SetModuleMemberAsTrainee_ModuleMemberFragment
  & EditTrainigPlan_ModuleMemberFragment
);

export type ModuleMemberSidePanelFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'employeeId' | 'interviewModuleId'>
  & { interviewModule?: Maybe<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'id'>
    & MembersList_ModuleFragment
  )> }
  & PendingApproval_ModuleMemberFragment
  & InterviewModuleMemberHeader_ModuleMemberFragment
  & InterviewModuleMemberKeyDates_ModuleMemberFragment
  & ModuleLoad_ModuleMemberFragment
  & TrainingPlanSection_ModuleMemberFragment
);

export type InterviewModuleMemberSidepanelQueryQueryVariables = Exact<{
  interviewModuleId: Scalars['uuid'];
  moduleMemberId: Scalars['uuid'];
}>;


export type InterviewModuleMemberSidepanelQueryQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMember?: Maybe<(
    { __typename?: 'InterviewModuleMember' }
    & ModuleMemberSidePanelFragment
    & InterviewModuleMemberModuleLog_ModuleMemberFragment
  )> }
);

export type ModuleMemberUpdateMutationVariables = Exact<{
  input: InterviewModuleMemberUpdateInput;
}>;


export type ModuleMemberUpdateMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleMemberUpdate?: Maybe<(
    { __typename?: 'InterviewModuleMemberResult' }
    & { interviewModuleMember?: Maybe<(
      { __typename?: 'InterviewModuleMember' }
      & ModuleMemberSidePanelFragment
    )> }
  )> }
);

export type ModuleMemberApproveMutationVariables = Exact<{
  input: InterviewModuleMemberApproveInput;
}>;


export type ModuleMemberApproveMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleMemberApprove?: Maybe<(
    { __typename?: 'InterviewModuleMemberResult' }
    & { interviewModuleMember?: Maybe<(
      { __typename?: 'InterviewModuleMember' }
      & ModuleMemberSidePanelFragment
    )> }
  )> }
);

export type DebriefCommunications_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id' | 'preferredPhoneNumber'>
);

export type DebriefFlowDoneQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type DebriefFlowDoneQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & { candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id'>
    )> }
  )> }
);

export type DebriefFlowHeaderQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DebriefFlowHeaderQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id' | 'fullName'>
    )>, jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
    )>, job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name'>
    )> }
  )> }
);

export type AttendeeAttributesFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'familyName' | 'isArchived' | 'isAtsDisabled' | 'isDirectoryDisabled' | 'hasAtsId'>
);

export type AtsDebriefStageFromApplicationFragment = (
  { __typename?: 'AtsApplication' }
  & Pick<AtsApplication, 'atsId'>
  & { atsScheduledInterviews?: Maybe<Array<(
    { __typename?: 'AtsScheduledInterview' }
    & Pick<AtsScheduledInterview, 'atsId' | 'startAt' | 'endAt'>
    & { atsInterviewDefinition?: Maybe<(
      { __typename?: 'AtsInterviewDefinition' }
      & Pick<AtsInterviewDefinition, 'atsId'>
      & { atsJobStage?: Maybe<(
        { __typename?: 'AtsJobStage' }
        & Pick<AtsJobStage, 'atsId' | 'name'>
      )> }
    )>, interviewers?: Maybe<Array<(
      { __typename?: 'AtsScheduledInterviewInterviewer' }
      & { atsUser?: Maybe<(
        { __typename?: 'AtsUser' }
        & Pick<AtsUser, 'id'>
        & { employee?: Maybe<(
          { __typename?: 'Employee' }
          & AttendeeAttributesFragment
        )> }
      )> }
    )>> }
  )>> }
);

export type ApplicationAttendeesQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ApplicationAttendeesQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { applicationStages?: Maybe<Array<(
      { __typename?: 'ApplicationStage' }
      & Pick<ApplicationStage, 'id' | 'startAt' | 'endAt'>
      & { applicationStageInterviews?: Maybe<Array<(
        { __typename?: 'ApplicationStageInterview' }
        & Pick<ApplicationStageInterview, 'id' | 'atsScheduledInterviewId'>
        & { applicationStageInterviewInterviewers?: Maybe<Array<(
          { __typename?: 'ApplicationStageInterviewInterviewer' }
          & Pick<ApplicationStageInterviewInterviewer, 'interviewerId' | 'responseStatus'>
          & { interviewer?: Maybe<(
            { __typename?: 'Employee' }
            & AttendeeAttributesFragment
          )> }
        )>> }
      )>>, jobStage?: Maybe<(
        { __typename?: 'JobStage' }
        & Pick<JobStage, 'id' | 'name'>
      )> }
    )>>, job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { hiringManagers?: Maybe<Array<(
        { __typename?: 'Employee' }
        & AttendeeAttributesFragment
      )>>, sourcers?: Maybe<Array<(
        { __typename?: 'Employee' }
        & AttendeeAttributesFragment
      )>> }
    )>, candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id'>
      & { recruiter?: Maybe<(
        { __typename?: 'Employee' }
        & AttendeeAttributesFragment
      )>, coordinator?: Maybe<(
        { __typename?: 'Employee' }
        & AttendeeAttributesFragment
      )>, sourcer?: Maybe<(
        { __typename?: 'Employee' }
        & AttendeeAttributesFragment
      )> }
    )>, atsApplication?: Maybe<(
      { __typename?: 'AtsApplication' }
      & AtsDebriefStageFromApplicationFragment
    )> }
  )> }
);

export type TaskDebriefAttendeeQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;


export type TaskDebriefAttendeeQuery = (
  { __typename?: 'Query' }
  & { thisUserInfo?: Maybe<(
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'userName'>
  )>, task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { debriefs: Array<(
      { __typename?: 'ApplicationDebrief' }
      & Pick<ApplicationDebrief, 'id'>
      & { attendees?: Maybe<Array<(
        { __typename?: 'ApplicationDebriefAttendee' }
        & Pick<ApplicationDebriefAttendee, 'interviewerId'>
        & { interviewer?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'isDirectoryDisabled' | 'isAtsDisabled' | 'hasAtsId'>
        )> }
      )>> }
    )> }
  )> }
);

export type DebriefTaskQueryVariables = Exact<{
  taskId: Scalars['uuid'];
}>;


export type DebriefTaskQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name'>
    & { jobStage: (
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
      & { job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id'>
        & ScheduleFlowDataProvider_JobFragment
      )> }
    ), schedule?: Maybe<(
      { __typename?: 'ApplicationStage' }
      & Pick<ApplicationStage, 'id'>
      & { conversation?: Maybe<(
        { __typename?: 'Conversation' }
        & Pick<Conversation, 'id' | 'remoteName'>
      )> }
    )>, debriefs: Array<(
      { __typename?: 'ApplicationDebrief' }
      & Pick<ApplicationDebrief, 'id' | 'conversationTemplateId' | 'emailTemplateId' | 'googleCalendarId' | 'startAt' | 'endAt'>
      & { conversation?: Maybe<(
        { __typename?: 'Conversation' }
        & Pick<Conversation, 'id' | 'remoteName'>
      )> }
    )> }
  )> }
);

export type DebriefApplicationQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type DebriefApplicationQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'jobStageId'>
    & { candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id'>
      & ScheduleFlowDataProvider_CandidateFragment
    )>, job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'atsId'>
      & ScheduleFlowDataProvider_JobFragment
    )>, existingConversations?: Maybe<Array<(
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id' | 'remoteName'>
    )>> }
  )> }
);

export type DevToolsTaskSearchQueryVariables = Exact<{
  input: TaskSearchInput;
}>;


export type DevToolsTaskSearchQuery = (
  { __typename?: 'Query' }
  & { taskSearch?: Maybe<(
    { __typename?: 'TaskSearchResult' }
    & { items: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status'>
    )> }
  )> }
);

export type DevToolsApplicationTasksQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type DevToolsApplicationTasksQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & { tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'status'>
    )> }
  )> }
);

export type DevToolsTaskCancelMutationVariables = Exact<{
  input: TaskCancelInput;
}>;


export type DevToolsTaskCancelMutation = (
  { __typename?: 'Mutation' }
  & { taskCancel: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  ) }
);

export type AttributesFilterFragment = (
  { __typename?: 'EmployeeAttributeName' }
  & Pick<EmployeeAttributeName, 'id' | 'name' | 'type'>
  & { values?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
  )>> }
);

export type EmployeeAttributeNamesQueryVariables = Exact<{
  input: EmployeeAttributeNamesInput;
}>;


export type EmployeeAttributeNamesQuery = (
  { __typename?: 'Query' }
  & { employeeAttributeNames?: Maybe<(
    { __typename?: 'EmployeeAttributeNamesResult' }
    & { items?: Maybe<Array<(
      { __typename?: 'EmployeeAttributeName' }
      & AttributesFilterFragment
    )>> }
  )> }
);

export type EmployeeFilterFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'interviewModuleId' | 'employeeId' | 'status' | 'shadowsRequired' | 'reverseShadowsRequired' | 'pausedUntil'>
  & { stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'completedAsReverseShadow' | 'completedAsShadow' | 'shadowOffset' | 'reverseShadowOffset'>
  )>, employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'email' | 'title' | 'slackImageUrl'>
    & { attributes?: Maybe<Array<(
      { __typename?: 'EmployeeAttributeTagValue' }
      & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
    )>>, interviewPauseDates?: Maybe<Array<(
      { __typename?: 'DateBlock' }
      & Pick<DateBlock, 'start' | 'end'>
    )>> }
  )> }
  & IsModuleMemberDisabledForScheduling_ModuleMemberFragment
  & InterviewModuleMember_InterviewModuleMemberFragment
);

export type EmployeeAndAttributesFilterFragment = (
  { __typename?: 'InterviewModuleMember' }
  & EmployeeFilterFragment
);

export type InterviewModuleMemberSearchQueryVariables = Exact<{
  input: InterviewModuleMemberSearchInput;
}>;


export type InterviewModuleMemberSearchQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMemberSearch?: Maybe<(
    { __typename?: 'InterviewModuleMemberSearchOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & EmployeeFilterFragment
    )> }
  )> }
);

export type CreateInterviewModuleMutationVariables = Exact<{
  input: InterviewModuleCreateInput;
}>;


export type CreateInterviewModuleMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleCreate?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id'>
    )> }
  )> }
);

export type InterviewModuleList_InterviewModuleFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id'>
  & InterviewModuleRow_InterviewModuleFragment
);

export type InterviewModuleRow_InterviewModuleFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id' | 'name' | 'membersTrainedCount' | 'membersInTrainingCount' | 'membersInPendingApprovalCount' | 'shadowsRequired' | 'reverseShadowsRequired'>
  & { trained?: Maybe<(
    { __typename?: 'InterviewModuleMembersOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
      & { employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'slackImageUrl' | 'fullName' | 'id'>
      )> }
    )> }
  )>, untrained?: Maybe<(
    { __typename?: 'InterviewModuleMembersOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
      & { employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'slackImageUrl' | 'fullName' | 'id'>
      )> }
    )> }
  )> }
);

export type InterviewModuleSearchV2QueryVariables = Exact<{
  input: InterviewModuleSearchInput;
  trainedEmployeesInput: InterviewModuleMembersInput;
  untrainedEmployeesInput: InterviewModuleMembersInput;
}>;


export type InterviewModuleSearchV2Query = (
  { __typename?: 'Query' }
  & { interviewModuleSearch?: Maybe<(
    { __typename?: 'InterviewModuleSearchOutput' }
    & Pick<InterviewModuleSearchOutput, 'total'>
    & { items: Array<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id'>
      & InterviewModuleList_InterviewModuleFragment
    )> }
  )> }
);

export type InterviewModuleCreateMutationVariables = Exact<{
  input: InterviewModuleCreateInput;
}>;


export type InterviewModuleCreateMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleCreate?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id'>
    )> }
  )> }
);

export type InterviewModuleSearchQueryVariables = Exact<{
  input: InterviewModuleSearchInput;
}>;


export type InterviewModuleSearchQuery = (
  { __typename?: 'Query' }
  & { interviewModuleSearch?: Maybe<(
    { __typename?: 'InterviewModuleSearchOutput' }
    & Pick<InterviewModuleSearchOutput, 'total'>
    & { items: Array<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id' | 'name' | 'membersTrainedCount' | 'membersInTrainingCount' | 'membersInPendingApprovalCount' | 'shadowsRequired' | 'reverseShadowsRequired'>
    )> }
  )> }
);

export type InterviewModuleMemberStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type InterviewModuleMemberStatsQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMemberStats?: Maybe<Array<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'completed' | 'trainingStatus' | 'trainingsToPass' | 'currentTrainingSession' | 'currentTrainingSessionStatus' | 'shadowsRequired' | 'reverseShadowsRequired'>
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'name'>
    )>, interviewModuleMember?: Maybe<(
      { __typename?: 'InterviewModuleMember' }
      & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
      & { employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'fullName'>
      )> }
    )> }
  )>> }
);

export type UpdateInterviewTrainingScheduleMutationVariables = Exact<{
  input: InterviewModuleUpdateInput;
}>;


export type UpdateInterviewTrainingScheduleMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleUpdate?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id' | 'name' | 'shouldFastTrackTraining'>
    )> }
  )> }
);

export type UpdateModuleName_TrainingScheduleFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id' | 'name' | 'shouldFastTrackTraining'>
);

export type UpdateInterviewModuleNameMutationVariables = Exact<{
  input: InterviewModuleUpdateInput;
}>;


export type UpdateInterviewModuleNameMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleUpdate?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateModuleName_InterviewModuleFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id' | 'name'>
);

export type TraineeRoleConfigSlackStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type TraineeRoleConfigSlackStatusQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { integrations?: Maybe<(
      { __typename?: 'OrgIntegrations' }
      & { slack?: Maybe<(
        { __typename?: 'SlackOrgIntegration' }
        & Pick<SlackOrgIntegration, 'active'>
      )> }
    )> }
  )> }
);

export type UpdateInterviewModuleSetupMutationVariables = Exact<{
  input: InterviewModuleUpdateInput;
}>;


export type UpdateInterviewModuleSetupMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleUpdate?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id'>
      & InterviewModuleSetup_InterviewModuleFragment
    )> }
  )> }
);

export type InterviewModuleSetup_InterviewModuleFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id' | 'name' | 'weeklyNumInterviewLimit' | 'shadowsRequired' | 'reverseShadowsRequired' | 'shouldFastTrackTraining' | 'graduateFromReverseShadowApprovalType' | 'graduateFromReverseShadowCustomApprovers' | 'graduateFromShadowApprovalType' | 'graduateFromShadowCustomApprovers'>
  & UpdateModuleName_TrainingScheduleFragment
  & UpdateModuleName_InterviewModuleFragment
);

export type AddMembersToModuleMutationVariables = Exact<{
  input: InterviewModuleAddMembersInput;
}>;


export type AddMembersToModuleMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleAddMembers?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id'>
    )> }
  )> }
);

export type AddMembersModal_ModuleFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id' | 'shadowsRequired' | 'reverseShadowsRequired'>
);

export type Members_MembersFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
  & ModuleMemberRow_ModuleMemberFragment
);

export type ModuleMemberRow_ModuleMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'status' | 'employeeId' | 'interviewModuleId' | 'shadowsRequired' | 'reverseShadowsRequired'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'title'>
  )>, interviewModule?: Maybe<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'id' | 'name'>
  )>, stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'upcoming' | 'completedAsShadow' | 'completedAsReverseShadow' | 'completed' | 'completedAsInterviewer' | 'manualCompletedAsInterviewer' | 'manualCompletedAsShadow' | 'manualCompletedAsReverseShadow'>
  )> }
  & ModuleMemberTrainingStatus_ModuleMemberFragment
);

export type InterviewModuleMembersListQueryVariables = Exact<{
  id: Scalars['uuid'];
  pageInput: PageInput;
  orderBy: InterviewModuleMemberSearchOrderBy;
  search?: Maybe<Scalars['String']>;
  filterByTrainingStatus?: Maybe<Array<Maybe<TrainingStatus>> | Maybe<TrainingStatus>>;
  filterByState?: Maybe<Array<Maybe<InterviewModuleMemberState>> | Maybe<InterviewModuleMemberState>>;
}>;


export type InterviewModuleMembersListQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMemberSearch?: Maybe<(
    { __typename?: 'InterviewModuleMemberSearchOutput' }
    & Pick<InterviewModuleMemberSearchOutput, 'total'>
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & Members_MembersFragment
    )> }
  )> }
);

export type MembersList_ModuleFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id' | 'membersCount' | 'membersTrainedCount'>
  & AddMembersModal_ModuleFragment
);

export type ModuleMembersViewQueryVariables = Exact<{
  interviewModuleId: Scalars['uuid'];
}>;


export type ModuleMembersViewQuery = (
  { __typename?: 'Query' }
  & { interviewModule?: Maybe<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'id' | 'name'>
    & AddMembersModal_ModuleFragment
    & MembersList_ModuleFragment
    & InterviewModuleSetup_InterviewModuleFragment
  )> }
);

export type InterviewModuleQueryVariables = Exact<{
  interviewModuleId: Scalars['uuid'];
}>;


export type InterviewModuleQuery = (
  { __typename?: 'Query' }
  & { interviewModule?: Maybe<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'membersCount' | 'membersTrainedCount' | 'membersInTrainingCount' | 'membersInShadowCount' | 'membersInReverseShadowCount' | 'graduateFromShadowApprovalType' | 'graduateFromShadowCustomApprovers' | 'graduateFromReverseShadowApprovalType' | 'graduateFromReverseShadowCustomApprovers'>
    & InterviewModuleFragment
  )> }
);

export type InterviewModuleUpdateMutationVariables = Exact<{
  input: InterviewModuleUpdateInput;
}>;


export type InterviewModuleUpdateMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleUpdate?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id'>
    )> }
  )> }
);

export type InterviewModuleDeleteMutationVariables = Exact<{
  interviewModuleId: Scalars['uuid'];
}>;


export type InterviewModuleDeleteMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleDelete?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id'>
    )> }
  )> }
);

export type EmployeesToAddForInterviewModuleQueryVariables = Exact<{
  input: EmployeesInput;
  interviewModuleId: Scalars['uuid'];
}>;


export type EmployeesToAddForInterviewModuleQuery = (
  { __typename?: 'Query' }
  & { employees?: Maybe<(
    { __typename?: 'EmployeesOutput' }
    & Pick<EmployeesOutput, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Employee' }
      & { interviewModuleMember?: Maybe<(
        { __typename?: 'InterviewModuleMember' }
        & Pick<InterviewModuleMember, 'employeeId' | 'status' | 'interviewModuleId'>
      )> }
      & EmployeeFragment
    )> }
  )> }
);

export type InterviewModuleMemberUpdateMutationVariables = Exact<{
  input: InterviewModuleMemberUpdateInput;
}>;


export type InterviewModuleMemberUpdateMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleMemberUpdate?: Maybe<(
    { __typename?: 'InterviewModuleMemberResult' }
    & { interviewModuleMember?: Maybe<(
      { __typename?: 'InterviewModuleMember' }
      & InterviewMemberFragment
    )> }
  )> }
);

export type InterviewModuleAddMembersMutationVariables = Exact<{
  input: InterviewModuleAddMembersInput;
}>;


export type InterviewModuleAddMembersMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleAddMembers?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id'>
    )> }
  )> }
);

export type InterviewModuleRemoveMembersMutationVariables = Exact<{
  input: InterviewModuleRemoveMembersInput;
}>;


export type InterviewModuleRemoveMembersMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleRemoveMembers?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id'>
    )> }
  )> }
);

export type InterviewModuleMemberMoveStageMutationVariables = Exact<{
  input: InterviewModuleMemberMoveStageInput;
}>;


export type InterviewModuleMemberMoveStageMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleMemberMoveStage?: Maybe<(
    { __typename?: 'InterviewModuleMemberResult' }
    & { interviewModuleMember?: Maybe<(
      { __typename?: 'InterviewModuleMember' }
      & InterviewMemberFragment
    )> }
  )> }
);

export type InterviewModuleMemberQueryVariables = Exact<{
  moduleId: Scalars['uuid'];
  employeeId: Scalars['uuid'];
}>;


export type InterviewModuleMemberQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMember?: Maybe<(
    { __typename?: 'InterviewModuleMember' }
    & InterviewMemberFragment
  )> }
);

export type InterviewModuleMemberApproveMutationVariables = Exact<{
  input: InterviewModuleMemberApproveInput;
}>;


export type InterviewModuleMemberApproveMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleMemberApprove?: Maybe<(
    { __typename?: 'InterviewModuleMemberResult' }
    & { interviewModuleMember?: Maybe<(
      { __typename?: 'InterviewModuleMember' }
      & InterviewMemberFragment
    )> }
  )> }
);

export type InterviewModuleMembersQueryVariables = Exact<{
  id: Scalars['uuid'];
  pageInput: PageInput;
  orderBy: InterviewModuleMemberSearchOrderBy;
  search?: Maybe<Scalars['String']>;
  filterByTrainingStatus?: Maybe<Array<Maybe<TrainingStatus>> | Maybe<TrainingStatus>>;
  filterByState?: Maybe<Array<Maybe<InterviewModuleMemberState>> | Maybe<InterviewModuleMemberState>>;
  isArchived?: Maybe<Scalars['Boolean']>;
  isAtsDisabled?: Maybe<Scalars['Boolean']>;
  isDirectoryDisabled?: Maybe<Scalars['Boolean']>;
  hasAtsId?: Maybe<Scalars['Boolean']>;
  useArchivedFilter?: Maybe<Scalars['Boolean']>;
  useAtsDisabledFilter?: Maybe<Scalars['Boolean']>;
}>;


export type InterviewModuleMembersQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMemberSearch?: Maybe<(
    { __typename?: 'InterviewModuleMemberSearchOutput' }
    & Pick<InterviewModuleMemberSearchOutput, 'total'>
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & InterviewMemberFragment
    )> }
  )> }
);

export type InterviewModuleMemberNameQueryVariables = Exact<{
  interviewModuleId: Scalars['uuid'];
  employeeId: Scalars['uuid'];
}>;


export type InterviewModuleMemberNameQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMember?: Maybe<(
    { __typename?: 'InterviewModuleMember' }
    & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
    & { employee?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName'>
    )> }
  )> }
);

export type InterviewMemberFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'reverseShadowsRequired' | 'shadowsRequired' | 'employeeId' | 'status' | 'isPaused' | 'isPausedIndefinitely' | 'pausedUntil' | 'weeklyNumInterviewLimit'>
  & { interviewModule?: Maybe<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'id' | 'name'>
  )>, employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'slackImageUrl' | 'fullName' | 'id' | 'title' | 'isPaused' | 'timezone' | 'isAtsDisabled' | 'isArchived'>
    & { interviewPauseDates?: Maybe<Array<(
      { __typename?: 'DateBlock' }
      & Pick<DateBlock, 'start' | 'end'>
    )>> }
  )>, stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'upcoming' | 'completed' | 'completedAsShadow' | 'completedAsReverseShadow' | 'trainingStatus' | 'trainingsToPass' | 'shadowOffset' | 'reverseShadowOffset' | 'currentTrainingSession' | 'currentTrainingSessionStatus' | 'manualCompletedAsShadow' | 'manualCompletedAsReverseShadow'>
  )>, shadowInterviews?: Maybe<(
    { __typename?: 'ApplicationStageInterviewsOutput' }
    & { items: Array<(
      { __typename?: 'ApplicationStageInterview' }
      & InterviewItemFragment
    )> }
  )>, reverseShadowInterviews?: Maybe<(
    { __typename?: 'ApplicationStageInterviewsOutput' }
    & { items: Array<(
      { __typename?: 'ApplicationStageInterview' }
      & InterviewItemFragment
    )> }
  )> }
);

export type MemberCardInterviewModuleFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id' | 'name'>
);

export type BaseEditInterviewSeatModuleEmployeeSearchFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'email' | 'orgId' | 'isDirectoryDisabled' | 'hasAtsId' | 'slackImageUrl' | 'timezone' | 'atsId' | 'isAtsDisabled'>
);

export type BaseEditInterviewSeatModuleInterviewModuleSearchFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'orgId' | 'membersCount' | 'membersTrainedCount' | 'membersInTrainingCount' | 'membersInReverseShadowCount' | 'membersInShadowCount' | 'membersInPendingApprovalCount' | 'graduateFromShadowApprovalType' | 'graduateFromReverseShadowApprovalType'>
  & { interviewModuleMembers?: Maybe<(
    { __typename?: 'InterviewModuleMembersOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'status' | 'shadowsRequired' | 'reverseShadowsRequired' | 'pausedUntil'>
      & { stats?: Maybe<(
        { __typename?: 'InterviewModuleMemberStats' }
        & Pick<InterviewModuleMemberStats, 'completedAsReverseShadow' | 'completedAsShadow' | 'shadowOffset' | 'reverseShadowOffset'>
      )>, employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'fullName' | 'email' | 'slackImageUrl' | 'timezone'>
        & { attributes?: Maybe<Array<(
          { __typename?: 'EmployeeAttributeTagValue' }
          & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
        )>>, interviewPauseDates?: Maybe<Array<(
          { __typename?: 'DateBlock' }
          & Pick<DateBlock, 'start' | 'end'>
        )>> }
      )> }
    )> }
  )> }
  & InterviewModuleFragment
);

export type EmployeesAndModulesSearchQueryVariables = Exact<{
  employeeSearchInput: EmployeeSearchInput;
  moduleSearchInput: InterviewModuleSearchInput;
}>;


export type EmployeesAndModulesSearchQuery = (
  { __typename?: 'Query' }
  & { employeeSearch?: Maybe<(
    { __typename?: 'EmployeeSearchOutput' }
    & { items: Array<(
      { __typename?: 'Employee' }
      & BaseEditInterviewSeatModuleEmployeeSearchFragment
    )> }
  )>, interviewModuleSearch?: Maybe<(
    { __typename?: 'InterviewModuleSearchOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModule' }
      & BaseEditInterviewSeatModuleInterviewModuleSearchFragment
    )> }
  )> }
);

export type EditModuleSeatInterviewModuleFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId'>
  & EmployeeAndAttributesFilterFragment
);

export type EditModuleSeatInterviewStatsFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id' | 'name' | 'membersCount' | 'membersInReverseShadowCount' | 'membersInShadowCount' | 'membersTrainedCount'>
);

export type InterviewModuleMemberFilteredByAttributesQueryVariables = Exact<{
  input: InterviewModuleMemberSearchInput;
}>;


export type InterviewModuleMemberFilteredByAttributesQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMemberSearch?: Maybe<(
    { __typename?: 'InterviewModuleMemberSearchOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & EditModuleSeatInterviewModuleFragment
    )> }
  )> }
);

export type ModuleMemberStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type ModuleMemberStatsQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMemberStats?: Maybe<Array<(
    { __typename?: 'InterviewModuleMemberStats' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & EditModuleSeatInterviewStatsFragment
    )> }
  )>> }
);

export type ResetJobStageToAtsInterviewPlanMutationVariables = Exact<{
  input: ResetJobStageToAtsInterviewPlanInput;
}>;


export type ResetJobStageToAtsInterviewPlanMutation = (
  { __typename?: 'Mutation' }
  & { resetJobStageToAtsInterviewPlan?: Maybe<(
    { __typename?: 'JobStageInterviewPlanResult' }
    & { jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
    )> }
  )> }
);

export type JobStage_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id' | 'jobId' | 'name' | 'createdAt' | 'updatedAt' | 'schedulable' | 'index' | 'atsId'>
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'atsId'>
  )>, jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'locked' | 'id' | 'jobStageId' | 'index' | 'name' | 'atsId'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id' | 'jobStageId' | 'interviewType' | 'customDuration' | 'name' | 'duration' | 'index' | 'isLockedOrder' | 'useAtsName' | 'useAtsDuration' | 'schedulable' | 'dynamicLinkTypes' | 'staticLinks' | 'jobStageInterviewGroupId' | 'atsInterviewDefinitionId' | 'isHiddenFromCandidate'>
      & { atsInterviewDefinition?: Maybe<(
        { __typename?: 'AtsInterviewDefinition' }
        & AtsInterviewDefinitionFragment
      )>, jobStageInterviewSeats?: Maybe<Array<(
        { __typename?: 'JobStageInterviewSeat' }
        & Pick<JobStageInterviewSeat, 'id' | 'type'>
        & { moduleSeat?: Maybe<(
          { __typename?: 'ModuleSeat' }
          & Pick<ModuleSeat, 'interviewModuleId' | 'selectedEmployeeIds'>
          & { interviewModule?: Maybe<(
            { __typename?: 'InterviewModule' }
            & Pick<InterviewModule, 'id' | 'orgId' | 'shadowsRequired' | 'reverseShadowsRequired' | 'membersCount' | 'name' | 'membersTrainedCount' | 'membersInTrainingCount' | 'membersInReverseShadowCount' | 'membersInShadowCount' | 'membersInPendingApprovalCount' | 'graduateFromShadowApprovalType' | 'graduateFromReverseShadowApprovalType'>
            & { interviewModuleMembers?: Maybe<(
              { __typename?: 'InterviewModuleMembersOutput' }
              & { items: Array<(
                { __typename?: 'InterviewModuleMember' }
                & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'status' | 'shadowsRequired' | 'reverseShadowsRequired' | 'pausedUntil'>
                & { stats?: Maybe<(
                  { __typename?: 'InterviewModuleMemberStats' }
                  & Pick<InterviewModuleMemberStats, 'completedAsReverseShadow' | 'completedAsShadow' | 'shadowOffset' | 'reverseShadowOffset'>
                )>, employee?: Maybe<(
                  { __typename?: 'Employee' }
                  & Pick<Employee, 'id' | 'fullName' | 'email' | 'slackImageUrl'>
                  & { attributes?: Maybe<Array<(
                    { __typename?: 'EmployeeAttributeTagValue' }
                    & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
                  )>>, interviewPauseDates?: Maybe<Array<(
                    { __typename?: 'DateBlock' }
                    & Pick<DateBlock, 'start' | 'end'>
                  )>> }
                )> }
              )> }
            )> }
          )>, jobStageInterviewSeatAttributes?: Maybe<Array<(
            { __typename?: 'JobStageInterviewSeatAttribute' }
            & Pick<JobStageInterviewSeatAttribute, 'id' | 'jobStageInterviewSeatId' | 'attributeNameId' | 'attributeTagValueId'>
          )>> }
        )>, linkedSeat?: Maybe<(
          { __typename?: 'LinkedSeat' }
          & Pick<LinkedSeat, 'linkedJobStageInterviewSeatId'>
        )>, freeformSeat?: Maybe<(
          { __typename?: 'FreeformSeat' }
          & { jobStageInterviewSeatEmployees?: Maybe<Array<(
            { __typename?: 'JobStageInterviewSeatEmployee' }
            & Pick<JobStageInterviewSeatEmployee, 'jobStageInterviewSeatId' | 'employeeId' | 'preferenceLevel'>
            & { employee?: Maybe<(
              { __typename?: 'Employee' }
              & Pick<Employee, 'fullName' | 'email' | 'slackImageUrl' | 'id' | 'orgId' | 'hasAtsId' | 'isArchived' | 'isAtsDisabled' | 'isDirectoryDisabled' | 'useCalendarTimezone' | 'isPaused'>
            )> }
          )>> }
        )> }
      )>> }
    )>> }
  )>> }
);

export type InterviewPlanQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type InterviewPlanQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & JobStage_InterviewPlanFragment
  )> }
);

export type JobStageAtsDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type JobStageAtsDetailsQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'atsId'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'atsId'>
    )> }
  )> }
);

export type SaveJobStageInterviewPlanMutationVariables = Exact<{
  input: JobStageInterviewPlanUpsertInput;
}>;


export type SaveJobStageInterviewPlanMutation = (
  { __typename?: 'Mutation' }
  & { jobStageInterviewPlanUpsert?: Maybe<(
    { __typename?: 'JobStageInterviewPlanResult' }
    & { jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & JobStage_InterviewPlanFragment
    )> }
  )> }
);

export type AddModuleToInterviewerMutationVariables = Exact<{
  input: InterviewModuleAddMembersInput;
}>;


export type AddModuleToInterviewerMutation = (
  { __typename?: 'Mutation' }
  & { interviewModuleAddMembers?: Maybe<(
    { __typename?: 'InterviewModuleResult' }
    & { interviewModule?: Maybe<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id'>
    )> }
  )> }
);

export type Modules_MemberModulesFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
  & ModuleMemberRow_ModuleMemberFragment
);

export type InterviewerInterviewModuleMemberLookupQueryVariables = Exact<{
  memberIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type InterviewerInterviewModuleMemberLookupQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMemberLookup?: Maybe<(
    { __typename?: 'InterviewModuleMemberLookupOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
      & Modules_MemberModulesFragment
    )> }
  )> }
);

export type ModulesList_EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'modulesTrainedCount' | 'modulesInTrainingCount'>
);

export type InterviewerInterviewModulesLookupQueryVariables = Exact<{
  memberIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type InterviewerInterviewModulesLookupQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMemberLookup?: Maybe<(
    { __typename?: 'InterviewModuleMemberLookupOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & { interviewModule?: Maybe<(
        { __typename?: 'InterviewModule' }
        & MemberCardInterviewModuleFragment
      )> }
      & InterviewMemberFragment
    )> }
  )> }
);

export type InterviewModulesTab_EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id'>
  & ModulesList_EmployeeFragment
);

export type AboutSectionEditDialogQueryVariables = Exact<{
  input: EmployeeAttributeNamesInput;
}>;


export type AboutSectionEditDialogQuery = (
  { __typename?: 'Query' }
  & { employeeAttributeNames?: Maybe<(
    { __typename?: 'EmployeeAttributeNamesResult' }
    & { items?: Maybe<Array<(
      { __typename?: 'EmployeeAttributeName' }
      & Pick<EmployeeAttributeName, 'id' | 'name' | 'type'>
      & { values?: Maybe<Array<(
        { __typename?: 'EmployeeAttributeTagValue' }
        & Pick<EmployeeAttributeTagValue, 'id'>
        & { name: EmployeeAttributeTagValue['value'] }
        & { parent?: Maybe<(
          { __typename?: 'EmployeeAttributeName' }
          & Pick<EmployeeAttributeName, 'id'>
        )> }
      )>> }
    )>> }
  )> }
);

export type AboutSectionEditDialog_InterviewerFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'givenName' | 'isArchived' | 'familyName' | 'email' | 'title' | 'linkedinUrl'>
  & { atsUser?: Maybe<(
    { __typename?: 'AtsUser' }
    & Pick<AtsUser, 'atsId' | 'name' | 'email'>
    & { disabled: AtsUser['isDisabled'] }
  )>, manager?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'slackImageUrl' | 'fullName'>
  )>, attributes?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
    & { parent?: Maybe<(
      { __typename?: 'EmployeeAttributeName' }
      & Pick<EmployeeAttributeName, 'id' | 'name' | 'type'>
    )> }
  )>> }
);

export type InterviewerAboutSection_InterviewerFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'givenName' | 'familyName' | 'email' | 'title' | 'linkedinUrl' | 'pronouns' | 'bio'>
  & { profilePictureUrl?: Maybe<(
    { __typename?: 'ProfilePicture' }
    & Pick<ProfilePicture, 'imageOriginal' | 'image128px'>
  )>, manager?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'slackImageUrl' | 'fullName'>
  )>, atsUser?: Maybe<(
    { __typename?: 'AtsUser' }
    & Pick<AtsUser, 'atsId' | 'name' | 'email'>
    & { disabled: AtsUser['isDisabled'] }
  )>, attributes?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
    & { parent?: Maybe<(
      { __typename?: 'EmployeeAttributeName' }
      & Pick<EmployeeAttributeName, 'id' | 'name' | 'type'>
    )> }
  )>> }
  & AboutSectionEditDialog_InterviewerFragment
);

export type InterviewerUpdateMutationVariables = Exact<{
  input: EmployeeUpdateInput;
}>;


export type InterviewerUpdateMutation = (
  { __typename?: 'Mutation' }
  & { employeeUpdate?: Maybe<(
    { __typename?: 'EmployeeUpdateResult' }
    & { employee?: Maybe<(
      { __typename?: 'Employee' }
      & InterviewerAboutSection_InterviewerFragment
    )> }
  )> }
);

export type InterviewerUpdateAttributesMutationVariables = Exact<{
  input: EmployeeAttributesInput;
}>;


export type InterviewerUpdateAttributesMutation = (
  { __typename?: 'Mutation' }
  & { employeeAttributesUpsert?: Maybe<(
    { __typename?: 'EmployeeUpdateResult' }
    & { employee?: Maybe<(
      { __typename?: 'Employee' }
      & InterviewerAboutSection_InterviewerFragment
    )> }
  )> }
);

export type AtsUserEmployeeAssociationUpdateMutationVariables = Exact<{
  input: AtsUserEmployeeAssociationUpdateInput;
}>;


export type AtsUserEmployeeAssociationUpdateMutation = (
  { __typename?: 'Mutation' }
  & { atsUserEmployeeAssociationUpdate?: Maybe<(
    { __typename?: 'AtsUserEmployeeAssociationUpdateResult' }
    & { atsUser: (
      { __typename?: 'AtsUser' }
      & AtsUserFragment
    ) }
  )> }
);

export type AttributeEditRow_AttributeTagValueFragment = (
  { __typename?: 'EmployeeAttributeTagValue' }
  & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
  & { parent?: Maybe<(
    { __typename?: 'EmployeeAttributeName' }
    & Pick<EmployeeAttributeName, 'id' | 'type' | 'name'>
  )> }
);

export type AttributeEditRow_EmployeeAttributeParentFragment = (
  { __typename?: 'EmployeeAttributeName' }
  & Pick<EmployeeAttributeName, 'id' | 'name' | 'type'>
  & { values?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & AttributeEditRow_AttributeTagValueFragment
  )>> }
);

export type AttributeEditRow_InterviewerAttributesFragment = (
  { __typename?: 'EmployeeAttributeTagValue' }
  & AttributeEditRow_AttributeTagValueFragment
);

export type AttributesSectionEditDialogQueryVariables = Exact<{
  input: EmployeeAttributeNamesInput;
}>;


export type AttributesSectionEditDialogQuery = (
  { __typename?: 'Query' }
  & { employeeAttributeNames?: Maybe<(
    { __typename?: 'EmployeeAttributeNamesResult' }
    & { items?: Maybe<Array<(
      { __typename?: 'EmployeeAttributeName' }
      & AttributeEditRow_EmployeeAttributeParentFragment
    )>> }
  )> }
);

export type AttributeSectionEditDialog_AttributeTagValueFragment = (
  { __typename?: 'EmployeeAttributeTagValue' }
  & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
  & { parent?: Maybe<(
    { __typename?: 'EmployeeAttributeName' }
    & Pick<EmployeeAttributeName, 'id' | 'type' | 'name'>
  )> }
);

export type AttributesSectionEditDialog_InterviewerFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'fullName'>
  & { attributes?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
    & { parent?: Maybe<(
      { __typename?: 'EmployeeAttributeName' }
      & Pick<EmployeeAttributeName, 'id' | 'type' | 'name'>
    )> }
    & AttributeEditRow_InterviewerAttributesFragment
  )>> }
);

export type InterviewerAttributesSection_InterviewerFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id'>
  & { attributes?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
    & { parent?: Maybe<(
      { __typename?: 'EmployeeAttributeName' }
      & Pick<EmployeeAttributeName, 'id' | 'type' | 'name'>
    )> }
  )>> }
  & AttributesSectionEditDialog_InterviewerFragment
);

export type AttributeSectionQueryVariables = Exact<{
  input: EmployeeAttributeNamesInput;
}>;


export type AttributeSectionQuery = (
  { __typename?: 'Query' }
  & { employeeAttributeNames?: Maybe<(
    { __typename?: 'EmployeeAttributeNamesResult' }
    & { items?: Maybe<Array<(
      { __typename?: 'EmployeeAttributeName' }
      & Pick<EmployeeAttributeName, 'id' | 'name'>
    )>> }
  )> }
);

export type InterviewerLoadBalancer_EmployeePrefsFragment = (
  { __typename?: 'Employee' }
  & { employeePrefs?: Maybe<Array<(
    { __typename?: 'EmployeePref' }
    & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName' | 'json'>
  )>> }
);

export type InterviewerWorkHours_WorkHoursFragment = (
  { __typename?: 'Employee' }
  & { workHours?: Maybe<(
    { __typename?: 'TimeBlockWeek' }
    & { monday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, tuesday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, wednesday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, thursday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, friday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, saturday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, sunday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>> }
  )> }
);

export type AvailabilitySectionEditDialog_InterviewerFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'useCalendarTimezone' | 'calendarTimezone' | 'timezone'>
  & { employeePrefs?: Maybe<Array<(
    { __typename?: 'EmployeePref' }
    & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName' | 'json'>
  )>> }
  & InterviewerLoadBalancer_EmployeePrefsFragment
  & InterviewerWorkHours_WorkHoursFragment
);

export type AvailabilitySection_InterviewerFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'useCalendarTimezone' | 'calendarTimezone' | 'timezone'>
  & { interviewPauseDates?: Maybe<Array<(
    { __typename?: 'DateBlock' }
    & Pick<DateBlock, 'start' | 'end'>
  )>>, loadAndLimit?: Maybe<(
    { __typename?: 'LoadAndLimit' }
    & { dailyInterviewLimit?: Maybe<(
      { __typename?: 'InterviewLimit' }
      & Pick<InterviewLimit, 'type' | 'limit'>
    )>, weeklyInterviewLimit?: Maybe<(
      { __typename?: 'InterviewLimit' }
      & Pick<InterviewLimit, 'type' | 'limit'>
    )> }
  )>, employeePrefs?: Maybe<Array<(
    { __typename?: 'EmployeePref' }
    & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName' | 'json'>
  )>>, workHours?: Maybe<(
    { __typename?: 'TimeBlockWeek' }
    & { monday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, tuesday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, wednesday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, thursday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, friday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, saturday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>>, sunday?: Maybe<Array<(
      { __typename?: 'TimeBlock' }
      & Pick<TimeBlock, 'start' | 'end'>
    )>> }
  )> }
  & AvailabilitySectionEditDialog_InterviewerFragment
);

export type AvailabilitySectionSaveMutationVariables = Exact<{
  input: EmployeeUpdateInput;
  date: Scalars['date'];
  timezone: Scalars['String'];
}>;


export type AvailabilitySectionSaveMutation = (
  { __typename?: 'Mutation' }
  & { employeeUpdate?: Maybe<(
    { __typename?: 'EmployeeUpdateResult' }
    & { employee?: Maybe<(
      { __typename?: 'Employee' }
      & AvailabilitySection_InterviewerFragment
    )> }
  )> }
);

export type ExternalProfileModalInterviewerFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'givenName' | 'familyName' | 'fullName' | 'title' | 'linkedinUrl' | 'bio' | 'pronouns' | 'email'>
  & { externalProfilePicture?: Maybe<(
    { __typename?: 'ProfilePicture' }
    & Pick<ProfilePicture, 'imageOriginal'>
  )> }
);

export type ExternalProfileUpdateInterviewerMutationVariables = Exact<{
  pronounsInput: EmployeePrefUpdateInput;
  profileImageInput: EmployeePrefUpdateInput;
  bioInput: EmployeePrefUpdateInput;
  input: EmployeeUpdateInput;
}>;


export type ExternalProfileUpdateInterviewerMutation = (
  { __typename?: 'Mutation' }
  & { bioUpdate?: Maybe<(
    { __typename?: 'EmployeePrefUpdateResult' }
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'employeeId' | 'orgId' | 'prefName'>
    )> }
  )>, pronounsUpdate?: Maybe<(
    { __typename?: 'EmployeePrefUpdateResult' }
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'employeeId' | 'orgId' | 'prefName'>
    )> }
  )>, profileImageUpdate?: Maybe<(
    { __typename?: 'EmployeePrefUpdateResult' }
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'employeeId' | 'orgId' | 'prefName'>
    )> }
  )>, employeeUpdate?: Maybe<(
    { __typename?: 'EmployeeUpdateResult' }
    & { employee?: Maybe<(
      { __typename?: 'Employee' }
      & ExternalProfileModalInterviewerFragment
    )> }
  )> }
);

export type ExternalProfileEditModal_InterviewerFragment = (
  { __typename?: 'Employee' }
  & ExternalProfileModalInterviewerFragment
);

export type ExternalProfileSection_InterviewerFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'title' | 'linkedinUrl' | 'bio' | 'pronouns' | 'email'>
  & { externalProfilePicture?: Maybe<(
    { __typename?: 'ProfilePicture' }
    & Pick<ProfilePicture, 'imageOriginal' | 'image128px'>
  )> }
  & ExternalProfileEditModal_InterviewerFragment
);

export type InterviewModulesAccordion_InterviewerModulesFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'id' | 'employeeId' | 'interviewModuleId' | 'status' | 'shadowsRequired' | 'reverseShadowsRequired' | 'isPausedIndefinitely' | 'pausedUntil' | 'weeklyNumInterviewLimit' | 'isPaused'>
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'fullName' | 'timezone' | 'id' | 'isPaused' | 'slackImageUrl' | 'title'>
  )>, interviewModule?: Maybe<(
    { __typename?: 'InterviewModule' }
    & Pick<InterviewModule, 'id' | 'name'>
  )>, stats?: Maybe<(
    { __typename?: 'InterviewModuleMemberStats' }
    & Pick<InterviewModuleMemberStats, 'currentTrainingSessionStatus' | 'upcoming' | 'completed' | 'manualCompletedAsShadow' | 'manualCompletedAsReverseShadow'>
  )> }
  & ModuleMemberRow_ModuleMemberFragment
);

export type InterviewerStatusAccordion_InterviewerFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'isAtsDisabled' | 'isArchived' | 'isDirectoryDisabled' | 'isDisabled' | 'isPaused' | 'hasAtsId'>
  & { interviewPauseDates?: Maybe<Array<(
    { __typename?: 'DateBlock' }
    & Pick<DateBlock, 'start' | 'end'>
  )>>, loadAndLimit?: Maybe<(
    { __typename?: 'LoadAndLimit' }
    & Pick<LoadAndLimit, 'dailyLoad' | 'weeklyLoad' | 'lastWeeklyLoad' | 'dailyLoadMinutes' | 'weeklyLoadMinutes' | 'lastWeeklyLoadMinutes'>
    & { dailyInterviewLimit?: Maybe<(
      { __typename?: 'InterviewLimit' }
      & Pick<InterviewLimit, 'type' | 'limit'>
    )>, weeklyInterviewLimit?: Maybe<(
      { __typename?: 'InterviewLimit' }
      & Pick<InterviewLimit, 'type' | 'limit'>
    )> }
  )>, interviewModuleMembers?: Maybe<(
    { __typename?: 'EmployeeInterviewModuleMembersOutput' }
    & { items?: Maybe<Array<(
      { __typename?: 'InterviewModuleMember' }
      & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'isPaused'>
      & { interviewModule?: Maybe<(
        { __typename?: 'InterviewModule' }
        & Pick<InterviewModule, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type InterviewsAccordion_InterviewFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & InterviewItemFragment
);

export type InterviewsAccordion_EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id'>
);

export type EmployeeKeywordsQueryVariables = Exact<{
  employeeId: Scalars['uuid'];
  orgKeywordNames: Array<Scalars['String']> | Scalars['String'];
  employeeKeywordNames: Array<Scalars['String']> | Scalars['String'];
}>;


export type EmployeeKeywordsQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { orgPrefByNames: Array<(
      { __typename?: 'OrgPref' }
      & Pick<OrgPref, 'orgId' | 'prefName' | 'stringArray'>
    )> }
  )>, employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { employeePrefByNames: Array<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName' | 'stringArray'>
    )> }
  )> }
);

export type DeletePlannedAvailabilityMutationVariables = Exact<{
  input: EmployeePlannedAvailabilityDeleteInput;
}>;


export type DeletePlannedAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { employeePlannedAvailabilityDelete: (
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  ) }
);

export type TemporaryAvailabilityDialog_PlannedAvailabilityFragment = (
  { __typename?: 'EmployeePlannedAvailability' }
  & Pick<EmployeePlannedAvailability, 'id' | 'employeeId' | 'startAt' | 'endAt' | 'timezone'>
  & { workHours: (
    { __typename?: 'TimeBlockWeek' }
    & TemporaryAvailabilityWorkHours_WorkHoursFragment
  ) }
);

export type CreatePlannedAvailabilityMutationVariables = Exact<{
  input: EmployeePlannedAvailabilityCreateInput;
}>;


export type CreatePlannedAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { employeePlannedAvailabilityCreate: (
    { __typename?: 'EmployeePlannedAvailabilityResult' }
    & { item: (
      { __typename?: 'EmployeePlannedAvailability' }
      & TemporaryAvailabilityDialog_PlannedAvailabilityFragment
    ) }
  ) }
);

export type UpdatePlannedAvailabilityMutationVariables = Exact<{
  input: EmployeePlannedAvailabilityUpdateInput;
}>;


export type UpdatePlannedAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { employeePlannedAvailabilityUpdate: (
    { __typename?: 'EmployeePlannedAvailabilityResult' }
    & { item: (
      { __typename?: 'EmployeePlannedAvailability' }
      & TemporaryAvailabilityDialog_PlannedAvailabilityFragment
    ) }
  ) }
);

export type TemporaryAvailabilityWorkHours_WorkHoursFragment = (
  { __typename?: 'TimeBlockWeek' }
  & { monday?: Maybe<Array<(
    { __typename?: 'TimeBlock' }
    & Pick<TimeBlock, 'start' | 'end'>
  )>>, tuesday?: Maybe<Array<(
    { __typename?: 'TimeBlock' }
    & Pick<TimeBlock, 'start' | 'end'>
  )>>, wednesday?: Maybe<Array<(
    { __typename?: 'TimeBlock' }
    & Pick<TimeBlock, 'start' | 'end'>
  )>>, thursday?: Maybe<Array<(
    { __typename?: 'TimeBlock' }
    & Pick<TimeBlock, 'start' | 'end'>
  )>>, friday?: Maybe<Array<(
    { __typename?: 'TimeBlock' }
    & Pick<TimeBlock, 'start' | 'end'>
  )>>, saturday?: Maybe<Array<(
    { __typename?: 'TimeBlock' }
    & Pick<TimeBlock, 'start' | 'end'>
  )>>, sunday?: Maybe<Array<(
    { __typename?: 'TimeBlock' }
    & Pick<TimeBlock, 'start' | 'end'>
  )>> }
);

export type UpdatePauseDatesMutationVariables = Exact<{
  input: EmployeeUpdateInput;
}>;


export type UpdatePauseDatesMutation = (
  { __typename?: 'Mutation' }
  & { employeeUpdate?: Maybe<(
    { __typename?: 'EmployeeUpdateResult' }
    & { employee?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'isPaused'>
      & PlannedAvailabilitySection_InterviewerFragment
    )> }
  )> }
);

export type PlannedAvailabilitySection_InterviewerFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'useCalendarTimezone' | 'calendarTimezone' | 'timezone'>
  & { interviewPauseDates?: Maybe<Array<(
    { __typename?: 'DateBlock' }
    & Pick<DateBlock, 'start' | 'end'>
  )>>, plannedAvailabilities: Array<(
    { __typename?: 'EmployeePlannedAvailability' }
    & Pick<EmployeePlannedAvailability, 'id'>
    & TemporaryAvailabilityDialog_PlannedAvailabilityFragment
  )> }
);

export type InterviewerInfoContainer_EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'email' | 'isPaused' | 'orgId'>
  & { interviewModuleMembers?: Maybe<(
    { __typename?: 'EmployeeInterviewModuleMembersOutput' }
    & { items?: Maybe<Array<(
      { __typename?: 'InterviewModuleMember' }
      & InterviewModulesAccordion_InterviewerModulesFragment
    )>> }
  )>, applicationStageInterviews?: Maybe<(
    { __typename?: 'EmployeeApplicationStageInterviewsOutput' }
    & { items: Array<(
      { __typename?: 'ApplicationStageInterview' }
      & InterviewsAccordion_InterviewFragment
    )> }
  )> }
  & InterviewerStatusAccordion_InterviewerFragment
  & InterviewerAboutSection_InterviewerFragment
  & AvailabilitySection_InterviewerFragment
  & InterviewerAttributesSection_InterviewerFragment
  & PlannedAvailabilitySection_InterviewerFragment
  & ExternalProfileSection_InterviewerFragment
  & InterviewsAccordion_EmployeeFragment
);

export type InterviewsTab_InterviewFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'name' | 'startAt' | 'endAt' | 'isCancelled' | 'googleEventUrl'>
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id'>
    & { application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'fullName'>
      )>, job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name'>
      )>, jobStage?: Maybe<(
        { __typename?: 'JobStage' }
        & Pick<JobStage, 'id' | 'name'>
      )> }
    )> }
  )>, applicationStageInterviewInterviewers?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterviewInterviewer' }
    & Pick<ApplicationStageInterviewInterviewer, 'interviewerId' | 'role' | 'responseStatus' | 'isOptional'>
    & { interviewer?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
    )>, declines?: Maybe<Array<(
      { __typename?: 'InterviewInterviewerDeclined' }
      & Pick<InterviewInterviewerDeclined, 'reason' | 'comment' | 'source'>
    )>> }
  )>>, applicationStageInterviewRooms?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterviewRoom' }
    & Pick<ApplicationStageInterviewRoom, 'applicationStageInterviewId' | 'meetingRoomId' | 'responseStatus'>
  )>> }
);

export type InterviewsTab_InterviewerFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id'>
  & { applicationStageInterviews?: Maybe<(
    { __typename?: 'EmployeeApplicationStageInterviewsOutput' }
    & Pick<EmployeeApplicationStageInterviewsOutput, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'ApplicationStageInterview' }
      & InterviewsTab_InterviewFragment
    )> }
  )> }
);

export type InterviewsTabInterviewerQueryVariables = Exact<{
  id: Scalars['uuid'];
  input: EmployeeApplicationStageInterviewsInput;
}>;


export type InterviewsTabInterviewerQuery = (
  { __typename?: 'Query' }
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & InterviewsTab_InterviewerFragment
  )> }
);

export type EmployeeDetailsViewQueryVariables = Exact<{
  id: Scalars['uuid'];
  date: Scalars['date'];
  timezone: Scalars['String'];
}>;


export type EmployeeDetailsViewQuery = (
  { __typename?: 'Query' }
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & InterviewerInfoContainer_EmployeeFragment
    & InterviewModulesTab_EmployeeFragment
    & EmployeeDetailsFragmentFragment
  )> }
);

export type PortalPreviewByJobStage_BrandingThemeFragment = (
  { __typename?: 'BrandingTheme' }
  & Pick<BrandingTheme, 'id' | 'coverImageUrl' | 'profileImageUrl' | 'font' | 'themeMode' | 'primaryColor'>
);

export type PortalPreviewByJobStage_JobStagesFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id' | 'name' | 'index'>
);

export type PortalPreviewByJobStage_RecruitingTeamContactInfoFragment = (
  { __typename?: 'RecruitingTeamContactInfoSetting' }
  & Pick<RecruitingTeamContactInfoSetting, 'showRecruiter' | 'showCoordinator'>
);

export type CandidatePortalPreviewQueryVariables = Exact<{
  jobId: Scalars['uuid'];
}>;


export type CandidatePortalPreviewQuery = (
  { __typename?: 'Query' }
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name'>
    & { jobStages?: Maybe<Array<(
      { __typename?: 'JobStage' }
      & PortalPreviewByJobStage_JobStagesFragment
    )>>, jobSettings: (
      { __typename?: 'JobSettings' }
      & { recruitingTeamContactInfoSetting: (
        { __typename?: 'RecruitingTeamContactInfoSetting' }
        & PortalPreviewByJobStage_RecruitingTeamContactInfoFragment
      ) }
    ) }
  )>, brandingThemes?: Maybe<(
    { __typename?: 'BrandingThemesResult' }
    & { items: Array<(
      { __typename?: 'BrandingTheme' }
      & Pick<BrandingTheme, 'id' | 'isOrgDefault'>
      & PortalPreviewByJobStage_BrandingThemeFragment
    )> }
  )> }
);

export type CustomTabs_CustomTabsFragment = (
  { __typename?: 'PortalJobOptionsTab' }
  & Pick<PortalJobOptionsTab, 'id'>
  & DraggableTabRows_TabsFragment
);

export type DraggableTabRows_TabsFragment = (
  { __typename?: 'PortalJobOptionsTab' }
  & Pick<PortalJobOptionsTab, 'id'>
  & TabRow_TabFragment
);

export type Settings_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'externalJobName'>
);

export type Settings_PortalJobOptionsFragment = (
  { __typename?: 'PortalJobOptions' }
  & Pick<PortalJobOptions, 'jobDisplayName' | 'intro' | 'brandingThemeId' | 'useOrgDefaultBrandingTheme'>
);

export type TabRow_TabFragment = (
  { __typename?: 'PortalJobOptionsTab' }
  & Pick<PortalJobOptionsTab, 'id' | 'index' | 'name' | 'icon' | 'visibility' | 'portalTemplateId' | 'jobStageId'>
);

export type VisibilitySelectJobStagesQueryVariables = Exact<{
  input: JobStagesInput;
}>;


export type VisibilitySelectJobStagesQuery = (
  { __typename?: 'Query' }
  & { jobStages?: Maybe<(
    { __typename?: 'JobStagesOutput' }
    & { items: Array<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
    )> }
  )> }
);

export type JobPortalOptionsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type JobPortalOptionsQuery = (
  { __typename?: 'Query' }
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name' | 'fallbackName'>
    & { settings?: Maybe<(
      { __typename?: 'PortalJobOptions' }
      & Pick<PortalJobOptions, 'id'>
      & Settings_PortalJobOptionsFragment
    )>, customTabs?: Maybe<(
      { __typename?: 'PortalJobOptions' }
      & Pick<PortalJobOptions, 'id'>
      & { tabs: Array<(
        { __typename?: 'PortalJobOptionsTab' }
        & CustomTabs_CustomTabsFragment
      )> }
    )> }
    & Settings_JobFragment
  )> }
);

export type JobPortalOptionMutationMutationVariables = Exact<{
  input: PortalJobOptionsUpsertInput;
}>;


export type JobPortalOptionMutationMutation = (
  { __typename?: 'Mutation' }
  & { portalJobOptionsUpsert: (
    { __typename?: 'PortalJobOptionsResult' }
    & { options: (
      { __typename?: 'PortalJobOptions' }
      & Pick<PortalJobOptions, 'id'>
      & { tabs: Array<(
        { __typename?: 'PortalJobOptionsTab' }
        & CustomTabs_CustomTabsFragment
      )> }
      & Settings_PortalJobOptionsFragment
    ) }
  ) }
);

export type HiringTeamJobEmployeeFragmentFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'fullName' | 'email' | 'id' | 'orgId' | 'slackImageUrl' | 'timezone' | 'atsId'>
);

export type HiringTeam_JobEmployeeFragment = (
  { __typename?: 'Job' }
  & { hiringManagers?: Maybe<Array<(
    { __typename?: 'Employee' }
    & HiringTeamJobEmployeeFragmentFragment
  )>>, recruiters?: Maybe<Array<(
    { __typename?: 'Employee' }
    & HiringTeamJobEmployeeFragmentFragment
  )>>, coordinators?: Maybe<Array<(
    { __typename?: 'Employee' }
    & HiringTeamJobEmployeeFragmentFragment
  )>>, sourcers?: Maybe<Array<(
    { __typename?: 'Employee' }
    & HiringTeamJobEmployeeFragmentFragment
  )>> }
);

export type InterviewNameRadio_InterviewNameSettingFragment = (
  { __typename?: 'InterviewNameSetting' }
  & Pick<InterviewNameSetting, 'isCustom' | 'isLocked' | 'level' | 'showInterviewName'>
);

export type InterviewerInfoRadio_InterviewerInfoSettingFragment = (
  { __typename?: 'InterviewerInfoSetting' }
  & Pick<InterviewerInfoSetting, 'isCustom' | 'isLocked' | 'level' | 'canShowInfo' | 'canShowFullName' | 'canShowJobTitle' | 'canShowLinkedin'>
);

export type ManageJobDisplayNameModal_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name' | 'fallbackName'>
);

export type JobNameUpdateMutationVariables = Exact<{
  input: JobUpdateInput;
}>;


export type JobNameUpdateMutation = (
  { __typename?: 'Mutation' }
  & { jobUpdate?: Maybe<(
    { __typename?: 'JobUpdateResult' }
    & { job: (
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & ManageJobDisplayNameModal_JobFragment
    ) }
  )> }
);

export type RecruitingTeamRadio_RecruitingTeamContactInfoSettingFragment = (
  { __typename?: 'RecruitingTeamContactInfoSetting' }
  & Pick<RecruitingTeamContactInfoSetting, 'isCustom' | 'isLocked' | 'level' | 'showRecruiter' | 'showCoordinator'>
);

export type JobDetailsPrivacy_JobSettingsFragment = (
  { __typename?: 'JobSettings' }
  & Pick<JobSettings, 'settingLevelId' | 'settingLevel'>
  & { interviewNameSetting: (
    { __typename?: 'InterviewNameSetting' }
    & InterviewNameRadio_InterviewNameSettingFragment
  ), interviewerInfoSetting: (
    { __typename?: 'InterviewerInfoSetting' }
    & InterviewerInfoRadio_InterviewerInfoSettingFragment
  ), defaultTaskQueue?: Maybe<(
    { __typename?: 'TaskQueue' }
    & QueueSelect_SelectedTaskQueueFragment
    & QueueSelect_DefaultTaskQueueFragment
  )>, recruitingTeamContactInfoSetting: (
    { __typename?: 'RecruitingTeamContactInfoSetting' }
    & RecruitingTeamRadio_RecruitingTeamContactInfoSettingFragment
  ) }
);

export type JobDetailsPrivacyQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type JobDetailsPrivacyQuery = (
  { __typename?: 'Query' }
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name' | 'fallbackName'>
    & { jobSettings: (
      { __typename?: 'JobSettings' }
      & JobDetailsPrivacy_JobSettingsFragment
    ) }
  )> }
);

export type UpdateJobSettingsMutationVariables = Exact<{
  input: JobSettingsUpsertInput;
}>;


export type UpdateJobSettingsMutation = (
  { __typename?: 'Mutation' }
  & { jobSettingsUpsert?: Maybe<(
    { __typename?: 'JobSettingsUpsertResult' }
    & { jobSettings: (
      { __typename?: 'JobSettings' }
      & JobDetailsPrivacy_JobSettingsFragment
    ) }
  )> }
);

export type JobNameAndLocationHeader_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'status' | 'name' | 'location'>
);

export type InternalEventsCard_InternalEventsFragment = (
  { __typename?: 'JobStageSettings' }
  & Pick<JobStageSettings, 'interviewerCalendarId'>
  & { interviewerEventTemplate?: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'name' | 'subject' | 'body' | 'isOrganizationDefault' | 'updatedAt' | 'type' | 'createdAt'>
  )> }
);

export type JobStageInterviewPlanQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type JobStageInterviewPlanQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & InterviewPlan_JobFragment
    )>, atsJobStage?: Maybe<(
      { __typename?: 'AtsJobStage' }
      & Pick<AtsJobStage, 'atsId' | 'canResetInterviewPlan'>
    )> }
    & InterviewPlan_JobStageFragment
    & UseInterviewPlanDescription_JobStageFragment
  )> }
);

export type JobStageInterviewPlanUpsertMutationVariables = Exact<{
  input: JobStageInterviewPlanUpsertInput;
}>;


export type JobStageInterviewPlanUpsertMutation = (
  { __typename?: 'Mutation' }
  & { jobStageInterviewPlanUpsert?: Maybe<(
    { __typename?: 'JobStageInterviewPlanResult' }
    & { jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
      & InterviewPlan_JobStageFragment
      & UseInterviewPlanDescription_JobStageFragment
    )> }
  )> }
);

export type CandidateAvailabilityOptions_JobStageSettingsFragment = (
  { __typename?: 'JobStageSettings' }
  & { candidateAvailabilityOptions?: Maybe<(
    { __typename?: 'CandidateAvailabilityOptionsSettings' }
    & Pick<CandidateAvailabilityOptionsSettings, 'numberOfDays' | 'minutesPerDays' | 'minimumTimeBlockMinutes' | 'advanceNoticeMinutes' | 'candidateNote' | 'shouldRespectLoadLimit' | 'canScheduleOverRecruitingKeywords' | 'canScheduleOverAvailableKeywords' | 'canScheduleOverFreeTime' | 'useRollingDays' | 'timeframeNumberOfDays'>
    & { ccRecipients?: Maybe<(
      { __typename?: 'NotificationPreference' }
      & Pick<NotificationPreference, 'employeeIds' | 'dynamicAudiences' | 'externalEmailAddresses'>
    )>, bccRecipients?: Maybe<(
      { __typename?: 'NotificationPreference' }
      & Pick<NotificationPreference, 'employeeIds' | 'dynamicAudiences' | 'externalEmailAddresses'>
    )> }
  )> }
);

export type SelfScheduleOptions_JobStageSettingsFragment = (
  { __typename?: 'JobStageSettings' }
  & { selfScheduleOptions?: Maybe<(
    { __typename?: 'SelfScheduleOptionSettings' }
    & Pick<SelfScheduleOptionSettings, 'numberOfDays' | 'advanceNoticeInHours' | 'rollingDays' | 'candidateCalendarId' | 'interviewerCalendarId' | 'location' | 'zoomHost' | 'isPrivateCalendarEvent' | 'zoomHostUserId' | 'customLocation' | 'shouldRespectLoadLimit' | 'canScheduleOverRecruitingKeywords' | 'canScheduleOverAvailableKeywords' | 'canScheduleOverFreeTime' | 'candidateNote' | 'useRollingDays' | 'meetingHost' | 'videoMeetingHostEmployeeId'>
    & { ccRecipients?: Maybe<(
      { __typename?: 'NotificationPreference' }
      & Pick<NotificationPreference, 'dynamicAudiences' | 'employeeIds' | 'externalEmailAddresses'>
    )>, bccRecipients?: Maybe<(
      { __typename?: 'NotificationPreference' }
      & Pick<NotificationPreference, 'dynamicAudiences' | 'employeeIds' | 'externalEmailAddresses'>
    )> }
  )> }
);

export type JobStageCommunicationSettings_JobStageSettingsFragment = (
  { __typename?: 'JobStageSettings' }
  & Pick<JobStageSettings, 'id' | 'jobStageId' | 'candidateEmailTemplateId' | 'candidateEventTemplateId' | 'candidateCalendarId' | 'slackTemplateId' | 'selfScheduleRequestEmailTemplateId' | 'selfScheduleCandidateEmailTemplateId' | 'selfScheduleInterviewerEventTemplateId' | 'availabilityRequestEmailTemplateId'>
  & { ccRecipients?: Maybe<(
    { __typename?: 'NotificationPreference' }
    & Pick<NotificationPreference, 'employeeIds' | 'dynamicAudiences' | 'externalEmailAddresses'>
  )>, bccRecipients?: Maybe<(
    { __typename?: 'NotificationPreference' }
    & Pick<NotificationPreference, 'employeeIds' | 'dynamicAudiences' | 'externalEmailAddresses'>
  )> }
  & CandidateAvailabilityOptions_JobStageSettingsFragment
  & SelfScheduleOptions_JobStageSettingsFragment
);

export type ScheduleLocation_InterviewMeetingLocationSettingFragment = (
  { __typename?: 'InterviewMeetingLocationSettingWithLevel' }
  & Pick<InterviewMeetingLocationSettingWithLevel, 'level'>
  & { interviewMeetingLocationSetting?: Maybe<(
    { __typename?: 'InterviewMeetingLocationSettingPref' }
    & Pick<InterviewMeetingLocationSettingPref, 'interviewMeetingLocationType' | 'dynamicHost'>
    & { hostEmployee?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
    )>, remoteVideoMeetingHostUser?: Maybe<(
      { __typename?: 'ZoomUser' }
      & Pick<ZoomUser, 'zoomUserId' | 'firstName' | 'lastName' | 'email'>
    )> }
    & InterviewMeetingLocationHost_InterviewMeetingLocationSettingPrefFragment
  )> }
);

export type InterviewMeetingLocationUpsertMutationVariables = Exact<{
  input: JobStageSettingsUpsertInput;
}>;


export type InterviewMeetingLocationUpsertMutation = (
  { __typename?: 'Mutation' }
  & { jobStageSettingsUpsert?: Maybe<(
    { __typename?: 'JobStageSettingsUpsertResult' }
    & { jobStageSettings?: Maybe<(
      { __typename?: 'JobStageSettings' }
      & Pick<JobStageSettings, 'id'>
    )> }
  )> }
);

export type JobStageDetails_JobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id' | 'name'>
);

export type JobStageDetails_JobStageSettingsFragment = (
  { __typename?: 'JobStageSettings' }
  & Pick<JobStageSettings, 'id' | 'jobStageId' | 'privateCalendarEvents' | 'interviewerEventTemplateId'>
  & InternalEventsCard_InternalEventsFragment
  & JobStageCommunicationSettings_JobStageSettingsFragment
);

export type JobStageDetails_JobStageSettingsV2Fragment = (
  { __typename?: 'JobStageSettingsV2' }
  & Pick<JobStageSettingsV2, 'id'>
  & { interviewMeetingLocationSettingWithLevel?: Maybe<(
    { __typename?: 'InterviewMeetingLocationSettingWithLevel' }
    & ScheduleLocation_InterviewMeetingLocationSettingFragment
  )> }
);

export type JobStageCommsSettingsUpsertMutationVariables = Exact<{
  input: JobStageSettingsUpsertInput;
}>;


export type JobStageCommsSettingsUpsertMutation = (
  { __typename?: 'Mutation' }
  & { jobStageSettingsUpsert?: Maybe<(
    { __typename?: 'JobStageSettingsUpsertResult' }
    & { jobStageSettings?: Maybe<(
      { __typename?: 'JobStageSettings' }
      & Pick<JobStageSettings, 'id'>
      & JobStageDetails_JobStageSettingsFragment
    )> }
  )> }
);

export type CheckCreateCandidatePermissionMutationVariables = Exact<{
  input: CheckCreateCandidatePermissionInput;
}>;


export type CheckCreateCandidatePermissionMutation = (
  { __typename?: 'Mutation' }
  & { checkCreateCandidatePermission: (
    { __typename?: 'CheckCreateCandidatePermissionResult' }
    & Pick<CheckCreateCandidatePermissionResult, 'hasPermission' | 'testCandidateAtsUrl'>
  ) }
);

export type SourceSelect_RecruitingSourceFragment = (
  { __typename?: 'RecruitingSource' }
  & Pick<RecruitingSource, 'source'>
);

export type SummaryAlert_JobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id' | 'name' | 'jobId'>
  & { jobStageSettingsV2?: Maybe<(
    { __typename?: 'JobStageSettingsV2' }
    & Pick<JobStageSettingsV2, 'id'>
    & { candidateAvailabilitySettings?: Maybe<(
      { __typename?: 'JobStageCandidateAvailabilitySettings' }
      & Pick<JobStageCandidateAvailabilitySettings, 'advanceNoticeMinutes' | 'minutesPerDays' | 'numberOfDays' | 'minimumTimeBlockMinutes' | 'candidateNote'>
    )>, selfScheduleSettings?: Maybe<(
      { __typename?: 'JobStageSelfScheduleSettings' }
      & Pick<JobStageSelfScheduleSettings, 'location' | 'advanceNoticeMinutes' | 'candidateNote'>
    )> }
  )> }
);

export type SummaryAlert_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id' | 'duration' | 'interviewType' | 'isHiddenFromCandidate'>
    )>> }
  )>> }
);

export type JobStageInterviewPlanQueryQueryVariables = Exact<{
  jobStageId: Scalars['uuid'];
}>;


export type JobStageInterviewPlanQueryQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & InterviewPlan_JobFragment
    )> }
    & InterviewPlan_JobStageFragment
  )> }
);

export type CreateSourcingLinkMutationVariables = Exact<{
  input: SourcingLinkCreateInput;
}>;


export type CreateSourcingLinkMutation = (
  { __typename?: 'Mutation' }
  & { sourcingLinkCreate: (
    { __typename?: 'SourcingLinkResult' }
    & { sourcingLink: (
      { __typename?: 'SourcingLink' }
      & Pick<SourcingLink, 'id'>
    ) }
  ) }
);

export type UpdateSourcingLinkMutationVariables = Exact<{
  input: SourcingLinkUpdateInput;
}>;


export type UpdateSourcingLinkMutation = (
  { __typename?: 'Mutation' }
  & { sourcingLinkUpdate: (
    { __typename?: 'SourcingLinkResult' }
    & { sourcingLink: (
      { __typename?: 'SourcingLink' }
      & Pick<SourcingLink, 'id'>
    ) }
  ) }
);

export type SourcingLinkForm_CurrentEmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
);

export type SourcingLinkForm_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name'>
  & { jobStages?: Maybe<Array<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name' | 'atsId' | 'index'>
    & { jobStageSettingsV2?: Maybe<(
      { __typename?: 'JobStageSettingsV2' }
      & Pick<JobStageSettingsV2, 'id'>
      & { candidateAvailabilitySettings?: Maybe<(
        { __typename?: 'JobStageCandidateAvailabilitySettings' }
        & Pick<JobStageCandidateAvailabilitySettings, 'canScheduleOverAvailableKeywords' | 'canScheduleOverRecruitingKeywords' | 'shouldRespectLoadLimit' | 'canScheduleOverFreeTime'>
      )> }
    )> }
    & SummaryAlert_JobStageFragment
  )>>, org?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { recruitingSources: Array<(
      { __typename?: 'RecruitingSource' }
      & SourceSelect_RecruitingSourceFragment
    )> }
  )> }
  & InterviewPlan_JobFragment
);

export type SourcingLinkForm_SourcingLinkFragment = (
  { __typename?: 'SourcingLink' }
  & Pick<SourcingLink, 'id' | 'deactivatedAt' | 'initialJobStageId' | 'name' | 'candidateSourcerId' | 'candidateRecruiterId' | 'candidateCoordinatorId' | 'recruitingSource' | 'taskAction' | 'taskAssigneeId' | 'taskSubscriberIds'>
  & { interviewPlanJobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & InterviewPlan_JobStageFragment
  )>, candidateRecruiter?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>, candidateSourcer?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>, candidateCoordinator?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>, taskAssignee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )> }
);

export type DuplicateSourcingLinkMutationVariables = Exact<{
  input: SourcingLinkCreateInput;
}>;


export type DuplicateSourcingLinkMutation = (
  { __typename?: 'Mutation' }
  & { sourcingLinkCreate: (
    { __typename?: 'SourcingLinkResult' }
    & { sourcingLink: (
      { __typename?: 'SourcingLink' }
      & Pick<SourcingLink, 'id'>
    ) }
  ) }
);

export type DuplicateMenuItem_SourcingLinkFragment = (
  { __typename?: 'SourcingLink' }
  & Pick<SourcingLink, 'id' | 'initialJobStageId' | 'name' | 'candidateSourcerId' | 'candidateRecruiterId' | 'candidateCoordinatorId' | 'recruitingSource' | 'taskAction' | 'taskAssigneeId' | 'taskSubscriberIds'>
  & { interviewPlanJobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & GetSaveInterviewPlanInput_InterviewPlanFragment
  )> }
);

export type DeleteSourcingLinkMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteSourcingLinkMutation = (
  { __typename?: 'Mutation' }
  & { sourcingLinkDelete: (
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  ) }
);

export type UpdateSourcingLinkStatusMutationVariables = Exact<{
  input: SourcingLinkUpdateInput;
}>;


export type UpdateSourcingLinkStatusMutation = (
  { __typename?: 'Mutation' }
  & { sourcingLinkUpdate: (
    { __typename?: 'SourcingLinkResult' }
    & { sourcingLink: (
      { __typename?: 'SourcingLink' }
      & Pick<SourcingLink, 'id' | 'deactivatedAt'>
    ) }
  ) }
);

export type SourcingLinksTable_SourcingLinksFragment = (
  { __typename?: 'SourcingLink' }
  & Pick<SourcingLink, 'id' | 'name' | 'deactivatedAt' | 'taskAction'>
  & { initialJobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )> }
  & DuplicateMenuItem_SourcingLinkFragment
);

export type SourcingLinksQueryVariables = Exact<{
  jobId: Scalars['uuid'];
}>;


export type SourcingLinksQuery = (
  { __typename?: 'Query' }
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'status'>
    & { sourcingLinks: Array<(
      { __typename?: 'SourcingLink' }
      & Pick<SourcingLink, 'id'>
      & SourcingLinkForm_SourcingLinkFragment
      & SourcingLinksTable_SourcingLinksFragment
    )> }
    & SourcingLinkForm_JobFragment
  )>, thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & SourcingLinkForm_CurrentEmployeeFragment
  )> }
);

export type JobDetailsPage_JobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id' | 'name' | 'index' | 'atsId'>
);

export type JobByIdQueryVariables = Exact<{
  jobId: Scalars['uuid'];
}>;


export type JobByIdQuery = (
  { __typename?: 'Query' }
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'atsType'>
    & { jobStages?: Maybe<Array<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
      & { jobStageSettings?: Maybe<(
        { __typename?: 'JobStageSettings' }
        & Pick<JobStageSettings, 'id'>
        & JobStageDetails_JobStageSettingsFragment
      )>, jobStageSettingsV2?: Maybe<(
        { __typename?: 'JobStageSettingsV2' }
        & Pick<JobStageSettingsV2, 'id'>
        & JobStageDetails_JobStageSettingsV2Fragment
      )> }
      & JobDetailsPage_JobStageFragment
      & JobStageDetails_JobStageFragment
    )>> }
    & JobNameAndLocationHeader_JobFragment
    & HiringTeam_JobEmployeeFragment
  )> }
);

export type ExitConfirmationModal_JobsFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name'>
);

export type FixContentTabMapping_TargetJobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id'>
  & JobStageSelectFixIssues_JobFragment
);

export type FixContentTabMapping_ContentTabMappingFragment = (
  { __typename?: 'ContentTabMapping' }
  & Pick<ContentTabMapping, 'sourceName' | 'sourceJobStageId'>
  & { sourceContentTab: (
    { __typename?: 'PortalJobOptionsTab' }
    & Pick<PortalJobOptionsTab, 'id' | 'name' | 'icon' | 'visibility'>
  ) }
);

export type FixContentTabMapping_SourceJobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name'>
  & { jobStages?: Maybe<Array<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )>> }
);

export type FixJobIssues_IssuesFragment = (
  { __typename?: 'JobImportValidateResult' }
  & { targetJob: (
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name' | 'location'>
    & FixJobStageMapping_TargetJobFragment
    & FixContentTabMapping_TargetJobFragment
    & FixScorecardMapping_TargetJobFragment
  ), jobStageMappings: Array<(
    { __typename?: 'JobStageMapping' }
    & Pick<JobStageMapping, 'targetJobStageId'>
    & FixJobStageMapping_JobStageMappingFragment
  )>, contentTabMappings: Array<(
    { __typename?: 'ContentTabMapping' }
    & Pick<ContentTabMapping, 'targetJobStageId' | 'sourceJobStageId'>
    & FixContentTabMapping_ContentTabMappingFragment
  )>, scorecardMappings: Array<(
    { __typename?: 'ScorecardMapping' }
    & Pick<ScorecardMapping, 'targetAtsId' | 'sourceAtsId'>
    & FixScorecardMapping_ScorecardMappingFragment
  )> }
);

export type SourceJobFixIssues_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name'>
  & FixContentTabMapping_SourceJobFragment
  & FixJobStageMapping_SourceJobFragment
);

export type FixAndImportJobSettingsMutationVariables = Exact<{
  input: JobSettingsImportInput;
}>;


export type FixAndImportJobSettingsMutation = (
  { __typename?: 'Mutation' }
  & { jobSettingsImport?: Maybe<(
    { __typename?: 'JobSettingsImportResult' }
    & { sourceJob: (
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
    ) }
  )> }
);

export type FixJobStageMapping_TargetJobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name'>
  & JobStageSelectFixIssues_JobFragment
);

export type FixJobStageMapping_SourceJobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name'>
  & { jobStages?: Maybe<Array<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'index' | 'name'>
  )>> }
);

export type FixJobStageMapping_JobStageMappingFragment = (
  { __typename?: 'JobStageMapping' }
  & Pick<JobStageMapping, 'sourceName' | 'sourceJobStageId'>
  & { sourceJobStage: (
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'index'>
  ) }
);

export type FixScorecardMapping_TargetJobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name'>
  & { atsJob?: Maybe<(
    { __typename?: 'AtsJob' }
    & Pick<AtsJob, 'atsId'>
  )> }
);

export type FixScorecardMapping_ScorecardMappingFragment = (
  { __typename?: 'ScorecardMapping' }
  & Pick<ScorecardMapping, 'sourceAtsId'>
  & { sourceAtsInterviewDefinition: (
    { __typename?: 'AtsInterviewDefinition' }
    & Pick<AtsInterviewDefinition, 'atsId' | 'name' | 'atsJobId'>
  ), jobStageInterviews?: Maybe<Array<(
    { __typename?: 'JobStageInterview' }
    & Pick<JobStageInterview, 'id' | 'name'>
  )>> }
);

export type JobStageSelectFixIssues_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id'>
  & { jobStages?: Maybe<Array<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )>> }
);

export type VisibilitySelectFixIssuesQueryVariables = Exact<{
  input: JobStagesInput;
}>;


export type VisibilitySelectFixIssuesQuery = (
  { __typename?: 'Query' }
  & { jobStages?: Maybe<(
    { __typename?: 'JobStagesOutput' }
    & { items: Array<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
    )> }
  )> }
);

export type FixIssues_IssuesFragment = (
  { __typename?: 'JobSettingsImportValidateResult' }
  & { items: Array<(
    { __typename?: 'JobImportValidateResult' }
    & FixJobIssues_IssuesFragment
  )>, sourceJob: (
    { __typename?: 'Job' }
    & SourceJobFixIssues_JobFragment
  ) }
);

export type ReviewJobRow_JobImportValidateResultFragment = (
  { __typename?: 'JobImportValidateResult' }
  & { targetJob: (
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name' | 'location'>
  ), jobStageMappings: Array<(
    { __typename?: 'JobStageMapping' }
    & Pick<JobStageMapping, 'targetJobStageId' | 'sourceName' | 'sourceJobStageId'>
    & UnmappedJobStages_JobStageMappingFragment
  )>, scorecardMappings: Array<(
    { __typename?: 'ScorecardMapping' }
    & Pick<ScorecardMapping, 'targetAtsId' | 'sourceName' | 'sourceAtsId'>
    & UnmappedScorecards_ScorecardMappingFragment
  )>, contentTabMappings: Array<(
    { __typename?: 'ContentTabMapping' }
    & Pick<ContentTabMapping, 'sourceName' | 'sourceJobStageId' | 'targetJobStageId' | 'sourceContentTabId'>
    & UnmappedContentTabs_ContentTabMappingFragment
  )> }
);

export type ReviewJobsTable_JobImportValidateResultsFragment = (
  { __typename?: 'JobImportValidateResult' }
  & { targetJob: (
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
  ) }
  & ReviewJobRow_JobImportValidateResultFragment
);

export type Review_JobImportValidateResultsFragment = (
  { __typename?: 'JobImportValidateResult' }
  & { targetJob: (
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
  ), jobStageMappings: Array<(
    { __typename?: 'JobStageMapping' }
    & Pick<JobStageMapping, 'targetJobStageId'>
  )>, scorecardMappings: Array<(
    { __typename?: 'ScorecardMapping' }
    & Pick<ScorecardMapping, 'targetAtsId'>
  )> }
  & ReviewJobsTable_JobImportValidateResultsFragment
  & GetJobsWithIssues_JobImportValidateResultsFragment
  & GetJobsWithoutIssues_JobImportValidateResultsFragment
);

export type CandidatePortalImportOptions_TabsFragment = (
  { __typename?: 'PortalJobOptionsTab' }
  & Pick<PortalJobOptionsTab, 'id' | 'name'>
);

export type SelectImportOptions_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name' | 'location'>
  & { jobStages?: Maybe<Array<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )>>, portalJobOptions?: Maybe<(
    { __typename?: 'PortalJobOptions' }
    & Pick<PortalJobOptions, 'id'>
    & { tabs: Array<(
      { __typename?: 'PortalJobOptionsTab' }
      & CandidatePortalImportOptions_TabsFragment
    )> }
  )> }
);

export type SourceJobListItem_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name' | 'orgId' | 'status' | 'location'>
);

export type SourceJobTable_JobFragment = (
  { __typename?: 'Job' }
  & SourceJobListItem_JobFragment
);

export type SourceJobsListQueryVariables = Exact<{
  input: JobsInput;
}>;


export type SourceJobsListQuery = (
  { __typename?: 'Query' }
  & { jobs?: Maybe<(
    { __typename?: 'JobsOutput' }
    & Pick<JobsOutput, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Job' }
      & SourceJobTable_JobFragment
    )> }
  )> }
);

export type GetSourceJobQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetSourceJobQuery = (
  { __typename?: 'Query' }
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & SelectImportOptions_JobFragment
  )> }
);

export type GetTargetJobsQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type GetTargetJobsQuery = (
  { __typename?: 'Query' }
  & { jobByIds?: Maybe<Array<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
    & ExitConfirmationModal_JobsFragment
  )>> }
);

export type JobSettingsImportValidateQueryVariables = Exact<{
  input: JobSettingsImportValidateInput;
}>;


export type JobSettingsImportValidateQuery = (
  { __typename?: 'Query' }
  & { jobSettingsImportValidate: (
    { __typename?: 'JobSettingsImportValidateResult' }
    & { items: Array<(
      { __typename?: 'JobImportValidateResult' }
      & { jobStageMappings: Array<(
        { __typename?: 'JobStageMapping' }
        & GetJobStageMappings_JobStageMappingFragment
      )>, scorecardMappings: Array<(
        { __typename?: 'ScorecardMapping' }
        & GetScorecardMappings_ScorecardMappingFragment
      )>, contentTabMappings: Array<(
        { __typename?: 'ContentTabMapping' }
        & Pick<ContentTabMapping, 'targetJobStageId'>
        & { sourceContentTab: (
          { __typename?: 'PortalJobOptionsTab' }
          & Pick<PortalJobOptionsTab, 'id' | 'visibility'>
        ) }
      )> }
      & Review_JobImportValidateResultsFragment
      & GetJobsWithIssues_JobImportValidateResultsFragment
      & GetJobsWithoutIssues_JobImportValidateResultsFragment
    )> }
    & FixIssues_IssuesFragment
  ) }
);

export type ImportJobSettingsMutationVariables = Exact<{
  input: JobSettingsImportInput;
}>;


export type ImportJobSettingsMutation = (
  { __typename?: 'Mutation' }
  & { jobSettingsImport?: Maybe<(
    { __typename?: 'JobSettingsImportResult' }
    & { sourceJob: (
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
    ) }
  )> }
);

export type GetJobStageMappings_JobStageMappingFragment = (
  { __typename?: 'JobStageMapping' }
  & Pick<JobStageMapping, 'targetJobStageId' | 'sourceJobStageId'>
);

export type GetJobsWithIssues_JobImportValidateResultsFragment = (
  { __typename?: 'JobImportValidateResult' }
  & { targetJob: (
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
  ), jobStageMappings: Array<(
    { __typename?: 'JobStageMapping' }
    & UnmappedJobStages_JobStageMappingFragment
  )>, scorecardMappings: Array<(
    { __typename?: 'ScorecardMapping' }
    & UnmappedScorecards_ScorecardMappingFragment
  )>, contentTabMappings: Array<(
    { __typename?: 'ContentTabMapping' }
    & UnmappedContentTabs_ContentTabMappingFragment
  )> }
);

export type GetJobsWithoutIssues_JobImportValidateResultsFragment = (
  { __typename?: 'JobImportValidateResult' }
  & { targetJob: (
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
  ), jobStageMappings: Array<(
    { __typename?: 'JobStageMapping' }
    & UnmappedJobStages_JobStageMappingFragment
  )>, scorecardMappings: Array<(
    { __typename?: 'ScorecardMapping' }
    & UnmappedScorecards_ScorecardMappingFragment
  )>, contentTabMappings: Array<(
    { __typename?: 'ContentTabMapping' }
    & UnmappedContentTabs_ContentTabMappingFragment
  )> }
);

export type GetScorecardMappings_ScorecardMappingFragment = (
  { __typename?: 'ScorecardMapping' }
  & Pick<ScorecardMapping, 'targetAtsId' | 'sourceAtsId'>
);

export type UnmappedContentTabs_ContentTabMappingFragment = (
  { __typename?: 'ContentTabMapping' }
  & Pick<ContentTabMapping, 'sourceJobStageId' | 'targetJobStageId'>
);

export type UnmappedJobStages_JobStageMappingFragment = (
  { __typename?: 'JobStageMapping' }
  & Pick<JobStageMapping, 'targetJobStageId'>
);

export type UnmappedScorecards_ScorecardMappingFragment = (
  { __typename?: 'ScorecardMapping' }
  & Pick<ScorecardMapping, 'targetAtsId'>
);

export type JobListItem_EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'fullName' | 'email' | 'id' | 'orgId' | 'slackImageUrl' | 'timezone' | 'atsId'>
);

export type JobListItem_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name' | 'orgId' | 'status' | 'location' | 'jobStagesWithInterviewPlansCount'>
  & { hiringManagers?: Maybe<Array<(
    { __typename?: 'Employee' }
    & JobListItem_EmployeeFragment
  )>>, recruiters?: Maybe<Array<(
    { __typename?: 'Employee' }
    & JobListItem_EmployeeFragment
  )>>, coordinators?: Maybe<Array<(
    { __typename?: 'Employee' }
    & JobListItem_EmployeeFragment
  )>>, sourcers?: Maybe<Array<(
    { __typename?: 'Employee' }
    & JobListItem_EmployeeFragment
  )>>, atsJob?: Maybe<(
    { __typename?: 'AtsJob' }
    & Pick<AtsJob, 'atsId' | 'jobRequisitionId'>
  )> }
);

export type JobListItemV2_EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'fullName' | 'email' | 'id' | 'orgId' | 'slackImageUrl' | 'timezone' | 'atsId'>
);

export type JobListItemV2_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name' | 'orgId' | 'status' | 'location' | 'jobStagesWithInterviewPlansCount'>
  & { hiringManagers?: Maybe<Array<(
    { __typename?: 'Employee' }
    & JobListItemV2_EmployeeFragment
  )>>, recruiters?: Maybe<Array<(
    { __typename?: 'Employee' }
    & JobListItemV2_EmployeeFragment
  )>>, coordinators?: Maybe<Array<(
    { __typename?: 'Employee' }
    & JobListItemV2_EmployeeFragment
  )>>, sourcers?: Maybe<Array<(
    { __typename?: 'Employee' }
    & JobListItemV2_EmployeeFragment
  )>>, atsJob?: Maybe<(
    { __typename?: 'AtsJob' }
    & Pick<AtsJob, 'atsId' | 'jobRequisitionId'>
  )> }
);

export type JobListTable_JobFragment = (
  { __typename?: 'Job' }
  & JobListItemV2_JobFragment
);

export type JobsListQueryVariables = Exact<{
  input: JobsInput;
}>;


export type JobsListQuery = (
  { __typename?: 'Query' }
  & { jobs?: Maybe<(
    { __typename?: 'JobsOutput' }
    & Pick<JobsOutput, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Job' }
      & JobListItem_JobFragment
    )> }
  )> }
);

export type DeclineMetricesDownloadQueryVariables = Exact<{
  input: InterviewerMetricsChartInput;
}>;


export type DeclineMetricesDownloadQuery = (
  { __typename?: 'Query' }
  & { interviewerMetricsChartDownload?: Maybe<(
    { __typename?: 'MetricsChartDownloadResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'InterviewMetrics' }
      & Pick<InterviewMetrics, 'interviewDate' | 'candidate' | 'interviewName' | 'calendarEventTitle' | 'jobStage' | 'scorecard' | 'scheduler' | 'interviewer' | 'declineLeadTime' | 'job'>
      & { interviewerEmployee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
        & { manager?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'email'>
        )>, attributes?: Maybe<Array<(
          { __typename?: 'EmployeeAttributeTagValue' }
          & Pick<EmployeeAttributeTagValue, 'value'>
          & { name?: Maybe<(
            { __typename?: 'EmployeeAttributeName' }
            & Pick<EmployeeAttributeName, 'name'>
          )> }
        )>> }
      )> }
    )>> }
  )> }
);

export type InterviewsCompletedChartQueryVariables = Exact<{
  input: InterviewerMetricsChartInput;
}>;


export type InterviewsCompletedChartQuery = (
  { __typename?: 'Query' }
  & { interviewerMetricsChart?: Maybe<(
    { __typename?: 'InterviewerMetricsChartResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'ChartCoordinateData' }
      & { x: ChartCoordinateData['xpoint'], y: ChartCoordinateData['ypoint'] }
    )>> }
  )> }
);

export type InterviewsCompletedDownloadChartQueryVariables = Exact<{
  input: InterviewerMetricsChartInput;
}>;


export type InterviewsCompletedDownloadChartQuery = (
  { __typename?: 'Query' }
  & { interviewerMetricsChartDownload?: Maybe<(
    { __typename?: 'MetricsChartDownloadResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'InterviewMetrics' }
      & Pick<InterviewMetrics, 'interviewDate' | 'candidate' | 'interviewName' | 'calendarEventTitle' | 'jobStage' | 'scorecard' | 'scheduler' | 'interviewer' | 'job'>
      & { interviewerEmployee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
        & { manager?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'email'>
        )>, attributes?: Maybe<Array<(
          { __typename?: 'EmployeeAttributeTagValue' }
          & Pick<EmployeeAttributeTagValue, 'value'>
          & { name?: Maybe<(
            { __typename?: 'EmployeeAttributeName' }
            & Pick<EmployeeAttributeName, 'name'>
          )> }
        )>> }
      )> }
    )>> }
  )> }
);

export type InterviewsDeclinedDownloadChartQueryVariables = Exact<{
  input: InterviewerMetricsChartInput;
}>;


export type InterviewsDeclinedDownloadChartQuery = (
  { __typename?: 'Query' }
  & { interviewerMetricsChartDownload?: Maybe<(
    { __typename?: 'MetricsChartDownloadResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'InterviewMetrics' }
      & Pick<InterviewMetrics, 'interviewDate' | 'candidate' | 'interviewName' | 'calendarEventTitle' | 'jobStage' | 'scorecard' | 'scheduler' | 'interviewer' | 'job'>
      & { interviewerEmployee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
        & { manager?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'email'>
        )>, attributes?: Maybe<Array<(
          { __typename?: 'EmployeeAttributeTagValue' }
          & Pick<EmployeeAttributeTagValue, 'value'>
          & { name?: Maybe<(
            { __typename?: 'EmployeeAttributeName' }
            & Pick<EmployeeAttributeName, 'name'>
          )> }
        )>> }
      )> }
    )>> }
  )> }
);

export type InterviewerMetricsInterviewingQueryVariables = Exact<{
  input: InterviewerMetricsTableInput;
}>;


export type InterviewerMetricsInterviewingQuery = (
  { __typename?: 'Query' }
  & { interviewerMetricsInterviewing?: Maybe<(
    { __typename?: 'InterviewerMetricsInterviewingResult' }
    & { items?: Maybe<Array<(
      { __typename?: 'InterviewerMetricsInterviewing' }
      & Pick<InterviewerMetricsInterviewing, 'upcoming' | 'completed' | 'declines' | 'totalTime' | 'interviewPerWeek' | 'timePerWeek'>
      & { interviewer?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'email'>
        & { manager?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'email'>
        )>, attributes?: Maybe<Array<(
          { __typename?: 'EmployeeAttributeTagValue' }
          & Pick<EmployeeAttributeTagValue, 'value'>
          & { name?: Maybe<(
            { __typename?: 'EmployeeAttributeName' }
            & Pick<EmployeeAttributeName, 'name'>
          )> }
        )>> }
      )> }
    )>> }
  )> }
);

export type InterviewerMetricsTrainingQueryVariables = Exact<{
  input: InterviewerMetricsTableInput;
}>;


export type InterviewerMetricsTrainingQuery = (
  { __typename?: 'Query' }
  & { interviewerMetricsTraining?: Maybe<(
    { __typename?: 'InterviewerMetricsTrainingResult' }
    & { items?: Maybe<Array<(
      { __typename?: 'InterviewerMetricsTraining' }
      & Pick<InterviewerMetricsTraining, 'trainer' | 'shadows' | 'reverseShadows' | 'modulesAsTrainee'>
      & { interviewer?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'slackImageUrl' | 'fullName' | 'email'>
        & { manager?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'email'>
        )>, attributes?: Maybe<Array<(
          { __typename?: 'EmployeeAttributeTagValue' }
          & Pick<EmployeeAttributeTagValue, 'value'>
          & { name?: Maybe<(
            { __typename?: 'EmployeeAttributeName' }
            & Pick<EmployeeAttributeName, 'name'>
          )> }
        )>> }
      )> }
    )>> }
  )> }
);

export type DeclineMetricesQueryVariables = Exact<{
  input: InterviewerMetricsChartInput;
}>;


export type DeclineMetricesQuery = (
  { __typename?: 'Query' }
  & { interviewerMetricsDeclinesWrapper?: Maybe<(
    { __typename?: 'InterviewerMetricsDeclinesWrapperResult' }
    & { declineReasonsChart?: Maybe<(
      { __typename?: 'InterviewerMetricsChartResult' }
      & { data?: Maybe<Array<(
        { __typename?: 'ChartCoordinateData' }
        & { x: ChartCoordinateData['xpoint'], y: ChartCoordinateData['ypoint'] }
      )>> }
    )>, declineLeadTimeChart?: Maybe<(
      { __typename?: 'InterviewerMetricsChartResult' }
      & { data?: Maybe<Array<(
        { __typename?: 'ChartCoordinateData' }
        & { x: ChartCoordinateData['xpoint'], y: ChartCoordinateData['ypoint'] }
      )>> }
    )>, declineTable?: Maybe<(
      { __typename?: 'InterviewerMetricsDeclinesResult' }
      & { items?: Maybe<Array<(
        { __typename?: 'InterviewerMetricsDeclines' }
        & Pick<InterviewerMetricsDeclines, 'date' | 'leadTime' | 'declineSource' | 'declineComment' | 'declineReason'>
        & { interviewer?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'email'>
          & { manager?: Maybe<(
            { __typename?: 'Employee' }
            & Pick<Employee, 'email'>
          )>, attributes?: Maybe<Array<(
            { __typename?: 'EmployeeAttributeTagValue' }
            & Pick<EmployeeAttributeTagValue, 'value'>
            & { name?: Maybe<(
              { __typename?: 'EmployeeAttributeName' }
              & Pick<EmployeeAttributeName, 'name'>
            )> }
          )>> }
        )> }
      )>> }
    )>, declinesChart?: Maybe<(
      { __typename?: 'InterviewerMetricsChartResult' }
      & { data?: Maybe<Array<(
        { __typename?: 'ChartCoordinateData' }
        & { x: ChartCoordinateData['xpoint'], y: ChartCoordinateData['ypoint'] }
      )>> }
    )> }
  )> }
);

export type RescheduleLogReasonsChart_RescheduleLogReasonsDataFragment = (
  { __typename?: 'RecruiterRescheduleLogsWrapperResult' }
  & { rescheduleLogsChart?: Maybe<Array<(
    { __typename?: 'RecruiterRescheduleLogsData' }
    & { rescheduleReason: (
      { __typename?: 'RescheduleReason' }
      & Pick<RescheduleReason, 'id' | 'reason'>
    ), data: Array<(
      { __typename?: 'ChartCoordinateData' }
      & { x: ChartCoordinateData['xpoint'], y: ChartCoordinateData['ypoint'] }
    )> }
  )>>, items?: Maybe<Array<(
    { __typename?: 'RecruiterRescheduleLog' }
    & Pick<RecruiterRescheduleLog, 'date' | 'note'>
    & { rescheduleReason?: Maybe<(
      { __typename?: 'RescheduleReason' }
      & Pick<RescheduleReason, 'id' | 'reason'>
    )>, performedBy?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'fullName' | 'slackImageUrl'>
    )>, task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { createdBy: (
        { __typename?: 'Employee' }
        & Pick<Employee, 'fullName'>
      ), jobStage: (
        { __typename?: 'JobStage' }
        & Pick<JobStage, 'name'>
      ), application: (
        { __typename?: 'Application' }
        & Pick<Application, 'id'>
        & { job?: Maybe<(
          { __typename?: 'Job' }
          & Pick<Job, 'name'>
        )>, candidate?: Maybe<(
          { __typename?: 'Candidate' }
          & Pick<Candidate, 'id' | 'fullName'>
        )> }
      ) }
    )> }
  )>> }
);

export type RecruiterChartQueryVariables = Exact<{
  input: RecruiterMetricsChartInput;
}>;


export type RecruiterChartQuery = (
  { __typename?: 'Query' }
  & { recruiterMetricsChart?: Maybe<(
    { __typename?: 'RecruiterMetricsChartResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'ChartCoordinateData' }
      & { x: ChartCoordinateData['xpoint'], y: ChartCoordinateData['ypoint'] }
    )>> }
  )> }
);

export type RecruiterChartDownloadQueryVariables = Exact<{
  input: RecruiterMetricsChartInput;
}>;


export type RecruiterChartDownloadQuery = (
  { __typename?: 'Query' }
  & { recruiterMetricsChartDownload?: Maybe<(
    { __typename?: 'MetricsChartDownloadResult' }
    & { data?: Maybe<Array<(
      { __typename?: 'InterviewMetrics' }
      & Pick<InterviewMetrics, 'interviewDate' | 'id' | 'interviewName' | 'calendarEventTitle' | 'scorecard' | 'jobStage' | 'scheduler' | 'candidate' | 'job'>
    )>>, scheduleStages?: Maybe<Array<(
      { __typename?: 'ScheduleStageMetrics' }
      & Pick<ScheduleStageMetrics, 'id' | 'jobStage' | 'candidate' | 'scheduler' | 'scheduleStageDate' | 'job'>
    )>> }
  )> }
);

export type RescheduleLogNotes_RescheduleLogItemsFragment = (
  { __typename?: 'RecruiterRescheduleLog' }
  & Pick<RecruiterRescheduleLog, 'id' | 'date' | 'note'>
  & { rescheduleReason?: Maybe<(
    { __typename?: 'RescheduleReason' }
    & Pick<RescheduleReason, 'id' | 'reason'>
  )>, performedBy?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'fullName' | 'slackImageUrl'>
  )>, task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { createdBy: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'fullName'>
    ), jobStage: (
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'name'>
    ), application: (
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'name'>
      )>, candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'fullName'>
      )> }
    ) }
  )> }
);

export type RecruitersMetricsTableQueryVariables = Exact<{
  input: RecruiterMetricsChartInput;
}>;


export type RecruitersMetricsTableQuery = (
  { __typename?: 'Query' }
  & { recruiterMetricsTable?: Maybe<(
    { __typename?: 'RecruiterMetricsTableResult' }
    & { items?: Maybe<Array<(
      { __typename?: 'RecruiterMetricsTable' }
      & Pick<RecruiterMetricsTable, 'scheduled' | 'completed' | 'declines' | 'cancelled' | 'changed' | 'facilitated'>
      & { recruiter?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'email'>
      )> }
    )>> }
  )> }
);

export type RecruiterMetricsRescheduleLogsQueryVariables = Exact<{
  input: RecruiterMetricsChartInput;
}>;


export type RecruiterMetricsRescheduleLogsQuery = (
  { __typename?: 'Query' }
  & { recruiterMetricsRescheduleLogs?: Maybe<(
    { __typename?: 'RecruiterRescheduleLogsWrapperResult' }
    & { items?: Maybe<Array<(
      { __typename?: 'RecruiterRescheduleLog' }
      & RescheduleLogNotes_RescheduleLogItemsFragment
    )>> }
    & RescheduleLogReasonsChart_RescheduleLogReasonsDataFragment
  )> }
);

export type MetricsPageInterviewerFiltersEmployeePrefFragment = (
  { __typename?: 'MetricsPageInterviewerFiltersPref' }
  & Pick<MetricsPageInterviewerFiltersPref, 'interviewerEmployeeIds' | 'managerEmployeeIds' | 'jobIds' | 'interviewModuleIds' | 'departmentAttributeIds' | 'teamAttributeIds' | 'locationAttributeIds' | 'levelAttributeIds' | 'customAttributeIds' | 'showArchived'>
);

export type MetricsPageInterviewerFiltersEmployeePrefQueryVariables = Exact<{
  prefName: Scalars['String'];
}>;


export type MetricsPageInterviewerFiltersEmployeePrefQuery = (
  { __typename?: 'Query' }
  & { thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
      & { jsonPref?: Maybe<{ __typename?: 'InterviewLimit' } | { __typename?: 'EmployeeTaskDashboardLayoutPref' } | { __typename?: 'EmployeeTaskDashboardFilterPref' } | { __typename?: 'CandidatePageFiltersPref' } | { __typename?: 'MetricsPageRecruitingTeamFiltersPref' } | (
        { __typename?: 'MetricsPageInterviewerFiltersPref' }
        & MetricsPageInterviewerFiltersEmployeePrefFragment
      ) | { __typename?: 'DirectoryPageFiltersPref' } | { __typename?: 'UpcomingEventsPageFiltersPref' } | { __typename?: 'RecentlyUsedZoomUsersPref' } | { __typename?: 'ProfilePicturePref' } | { __typename?: 'InterviewBufferTimePref' }> }
    )> }
  )> }
);

export type UpdateMetricsPageInterviewerFiltersEmployeePrefMutationVariables = Exact<{
  input: EmployeePrefUpdateInput;
}>;


export type UpdateMetricsPageInterviewerFiltersEmployeePrefMutation = (
  { __typename?: 'Mutation' }
  & { employeePrefUpdate?: Maybe<(
    { __typename?: 'EmployeePrefUpdateResult' }
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
    )> }
  )> }
);

export type MetricsPageRecruitingTeamFiltersEmployeePrefFragment = (
  { __typename?: 'MetricsPageRecruitingTeamFiltersPref' }
  & Pick<MetricsPageRecruitingTeamFiltersPref, 'schedulerEmployeeIds' | 'jobIds' | 'jobStageNames' | 'showArchived'>
);

export type MetricsPageRecruitingTeamFiltersEmployeePrefQueryVariables = Exact<{
  prefName: Scalars['String'];
}>;


export type MetricsPageRecruitingTeamFiltersEmployeePrefQuery = (
  { __typename?: 'Query' }
  & { thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
      & { jsonPref?: Maybe<{ __typename?: 'InterviewLimit' } | { __typename?: 'EmployeeTaskDashboardLayoutPref' } | { __typename?: 'EmployeeTaskDashboardFilterPref' } | { __typename?: 'CandidatePageFiltersPref' } | (
        { __typename?: 'MetricsPageRecruitingTeamFiltersPref' }
        & MetricsPageRecruitingTeamFiltersEmployeePrefFragment
      ) | { __typename?: 'MetricsPageInterviewerFiltersPref' } | { __typename?: 'DirectoryPageFiltersPref' } | { __typename?: 'UpcomingEventsPageFiltersPref' } | { __typename?: 'RecentlyUsedZoomUsersPref' } | { __typename?: 'ProfilePicturePref' } | { __typename?: 'InterviewBufferTimePref' }> }
    )> }
  )> }
);

export type UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutationVariables = Exact<{
  input: EmployeePrefUpdateInput;
}>;


export type UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation = (
  { __typename?: 'Mutation' }
  & { employeePrefUpdate?: Maybe<(
    { __typename?: 'EmployeePrefUpdateResult' }
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
    )> }
  )> }
);

export type OmniSearchOptionApplication_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'fullName'>
  )>, job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id' | 'name'>
  )>, jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )> }
);

export type OmniSearchApplicationsQueryVariables = Exact<{
  input: ApplicationSearchInput;
}>;


export type OmniSearchApplicationsQuery = (
  { __typename?: 'Query' }
  & { applicationSearch?: Maybe<(
    { __typename?: 'ApplicationSearchOutput' }
    & Pick<ApplicationSearchOutput, 'total'>
    & { items: Array<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'fullName'>
      )> }
      & OmniSearchOptionApplication_ApplicationFragment
    )> }
  )> }
);

export type OmniSearchOptionEmployee_EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'isArchived' | 'isAtsDisabled' | 'hasAtsId' | 'atsType'>
);

export type OmniSearchEmployeesQueryVariables = Exact<{
  input: EmployeeSearchInput;
}>;


export type OmniSearchEmployeesQuery = (
  { __typename?: 'Query' }
  & { employeeSearch?: Maybe<(
    { __typename?: 'EmployeeSearchOutput' }
    & Pick<EmployeeSearchOutput, 'total'>
    & { items: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName'>
      & OmniSearchOptionEmployee_EmployeeFragment
    )> }
  )> }
);

export type OmniSearchOptionInterviewModule_InterviewModuleFragment = (
  { __typename?: 'InterviewModule' }
  & Pick<InterviewModule, 'id' | 'name'>
);

export type OmniSearchInterviewModulesQueryVariables = Exact<{
  input: InterviewModuleSearchInput;
}>;


export type OmniSearchInterviewModulesQuery = (
  { __typename?: 'Query' }
  & { interviewModuleSearch?: Maybe<(
    { __typename?: 'InterviewModuleSearchOutput' }
    & Pick<InterviewModuleSearchOutput, 'total'>
    & { items: Array<(
      { __typename?: 'InterviewModule' }
      & Pick<InterviewModule, 'id' | 'name'>
      & OmniSearchOptionInterviewModule_InterviewModuleFragment
    )> }
  )> }
);

export type OmniSearchOptionJob_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'name'>
);

export type OmniSearchJobsQueryVariables = Exact<{
  input: JobsInput;
}>;


export type OmniSearchJobsQuery = (
  { __typename?: 'Query' }
  & { jobs?: Maybe<(
    { __typename?: 'JobsOutput' }
    & Pick<JobsOutput, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name'>
      & OmniSearchOptionJob_JobFragment
    )> }
  )> }
);

export type RescheduleFlowQueryVariables = Exact<{
  id: Scalars['uuid'];
  fetchInterviewPlan: Scalars['Boolean'];
}>;


export type RescheduleFlowQuery = (
  { __typename?: 'Query' }
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id'>
    & { application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'timezone'>
        & { atsLatestAvailability?: Maybe<(
          { __typename?: 'CandidateAtsAvailability' }
          & Pick<CandidateAtsAvailability, 'timezone' | 'originalText'>
          & UseCandidateAvailability_AtsLatestAvailabilityFragment
        )> }
        & ScheduleFlowDataProvider_CandidateFragment
      )> }
    )>, jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
      & { job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name' | 'atsId'>
        & ScheduleFlowDataProvider_JobFragment
      )>, jobStageSettingsV2?: Maybe<(
        { __typename?: 'JobStageSettingsV2' }
        & Pick<JobStageSettingsV2, 'id'>
        & ScheduleFlowDataProvider_JobStageSettingsFragment
      )> }
    )>, task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { availabilities: Array<(
        { __typename?: 'CandidateAvailability' }
        & Pick<CandidateAvailability, 'id'>
        & UseCandidateAvailability_AvailabilitiesFragment
      )>, interviewPlan: (
        { __typename?: 'JobStage' }
        & Pick<JobStage, 'id'>
        & ScheduleFlowDataProvider_InterviewPlanFragment
      ) }
    )> }
  )> }
);

export type JobStageSettingsFragmentFragment = (
  { __typename?: 'JobStageSettings' }
  & Pick<JobStageSettings, 'id' | 'jobStageId' | 'candidateEmailTemplateId' | 'candidateEventTemplateId' | 'slackTemplateId' | 'interviewerEventTemplateId' | 'interviewerCalendarId' | 'candidateCalendarId' | 'privateCalendarEvents'>
  & { toRecipients?: Maybe<(
    { __typename?: 'NotificationPreference' }
    & Pick<NotificationPreference, 'employeeIds' | 'externalEmailAddresses' | 'dynamicAudiences'>
  )>, ccRecipients?: Maybe<(
    { __typename?: 'NotificationPreference' }
    & Pick<NotificationPreference, 'employeeIds' | 'externalEmailAddresses' | 'dynamicAudiences'>
  )>, bccRecipients?: Maybe<(
    { __typename?: 'NotificationPreference' }
    & Pick<NotificationPreference, 'employeeIds' | 'externalEmailAddresses' | 'dynamicAudiences'>
  )> }
);

export type JobStageSettingsQueryVariables = Exact<{
  jobStageId: Scalars['uuid'];
}>;


export type JobStageSettingsQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
    & { jobStageSettings?: Maybe<(
      { __typename?: 'JobStageSettings' }
      & JobStageSettingsFragmentFragment
    )> }
  )> }
);

export type ScheduleFlowDataProvider_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id'>
  & Communications_CandidateFragment
  & UpdateCommunications_CandidateFragment
);

export type ScheduleFlowDataProvider_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id'>
  & ScheduleInterviewPlan_JobFragment
);

export type ScheduleFlowDataProvider_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & ScheduleInterviewPlan_JobStageFragment
  & Communications_InterviewPlanFragment
  & CalendarView_InterviewPlanFragment
  & UpdateCalendarView_InterviewPlanFragment
  & UpdateCommunications_InterviewPlanFragment
);

export type ScheduleFlowDataProvider_JobStageSettingsFragment = (
  { __typename?: 'JobStageSettingsV2' }
  & Pick<JobStageSettingsV2, 'id'>
  & Communications_JobStageSettingsFragment
);

export type TaskDebriefRequirementQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
  taskId: Scalars['uuid'];
}>;


export type TaskDebriefRequirementQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { applicationStages?: Maybe<Array<(
      { __typename?: 'ApplicationStage' }
      & Pick<ApplicationStage, 'id' | 'isHoldForRsvp' | 'isReadyForCandidateComms'>
    )>>, candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id'>
    )> }
  )>, task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'isDebriefRequired'>
    & { debriefs: Array<(
      { __typename?: 'ApplicationDebrief' }
      & Pick<ApplicationDebrief, 'id'>
    )> }
  )> }
);

export type ScheduleFlowHeaderQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ScheduleFlowHeaderQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id' | 'fullName' | 'timezone' | 'email' | 'additionalEmails' | 'recruiterId'>
      & { atsCandidate?: Maybe<(
        { __typename?: 'AtsCandidate' }
        & Pick<AtsCandidate, 'atsId' | 'atsUrl'>
      )> }
    )>, jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
    )>, job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name' | 'isConfidential'>
    )>, existingConversations?: Maybe<Array<(
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id' | 'remoteId' | 'remoteName' | 'isArchived'>
    )>> }
  )> }
);

export type JobStageNameQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type JobStageNameQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )> }
);

export type CandidateAvailabilityApplicationTasksQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type CandidateAvailabilityApplicationTasksQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & { tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { availabilities: Array<(
        { __typename?: 'CandidateAvailability' }
        & Pick<CandidateAvailability, 'id'>
        & { candidateEnteredAvailability?: Maybe<Array<(
          { __typename?: 'DateTimeRangeOutput' }
          & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
        )>>, rcEnteredAvailability?: Maybe<Array<(
          { __typename?: 'DateTimeRangeOutput' }
          & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
        )>> }
      )> }
    )> }
  )> }
);

export type ScheduleIssuesDialogApplicationStageInterviewFragment = (
  { __typename?: 'ApplicationStageInterview' }
  & Pick<ApplicationStageInterview, 'id' | 'startAt' | 'endAt'>
  & { atsInterviewDefinition?: Maybe<(
    { __typename?: 'AtsInterviewDefinition' }
    & Pick<AtsInterviewDefinition, 'atsId' | 'name'>
  )> }
);

export type ScheduleIssuesDialogApplicationStageInterviewInterviewersFragment = (
  { __typename?: 'ApplicationStageInterviewInterviewer' }
  & Pick<ApplicationStageInterviewInterviewer, 'applicationStageInterviewId' | 'interviewerId'>
  & { interviewer?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )>, declines?: Maybe<Array<(
    { __typename?: 'InterviewInterviewerDeclined' }
    & Pick<InterviewInterviewerDeclined, 'source' | 'reason' | 'comment'>
  )>> }
);

export type InterviewInterviewerDeclinedQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type InterviewInterviewerDeclinedQuery = (
  { __typename?: 'Query' }
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id'>
    & { applicationStageInterviews?: Maybe<Array<(
      { __typename?: 'ApplicationStageInterview' }
      & { applicationStageInterviewInterviewers?: Maybe<Array<(
        { __typename?: 'ApplicationStageInterviewInterviewer' }
        & ScheduleIssuesDialogApplicationStageInterviewInterviewersFragment
      )>> }
      & ScheduleIssuesDialogApplicationStageInterviewFragment
    )>> }
  )> }
);

export type CandidateEmailCard_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseCandidateEventTemplateContent_InterviewPlanFragment
);

export type UseCandidateEventTemplateContent_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseScheduleContent_InterviewPlanFragment
);

export type CandidateEventCard_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseCandidateCalendarEventTemplateContent_InterviewPlanFragment
);

export type UseCandidateCalendarEventTemplateContent_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseScheduleContent_InterviewPlanFragment
);

export type CodingCard_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseCreateCodingUrls_InterviewPlanFragment
);

export type UseCreateCodingUrls_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id' | 'dynamicLinkTypes'>
    )>> }
  )>> }
);

export type GetWarningModalCalendarQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetWarningModalCalendarQuery = (
  { __typename?: 'Query' }
  & { calendar?: Maybe<(
    { __typename?: 'Calendar' }
    & Pick<Calendar, 'id' | 'name'>
  )> }
);

export type HasApplicationStageInterviewQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type HasApplicationStageInterviewQuery = (
  { __typename?: 'Query' }
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id'>
    & { applicationStageInterviews?: Maybe<Array<(
      { __typename?: 'ApplicationStageInterview' }
      & Pick<ApplicationStageInterview, 'id'>
    )>> }
  )> }
);

export type GetApplicationTaskQueueQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type GetApplicationTaskQueueQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { jobSettings: (
        { __typename?: 'JobSettings' }
        & { defaultTaskQueue?: Maybe<(
          { __typename?: 'TaskQueue' }
          & Pick<TaskQueue, 'id'>
        )> }
      ) }
    )> }
  )> }
);

export type CommunicationsPreview_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseSubmitCreateSchedule_InterviewPlanFragment
);

export type UseGetInterviewers_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id'>
      & { jobStageInterviewSeats?: Maybe<Array<(
        { __typename?: 'JobStageInterviewSeat' }
        & Pick<JobStageInterviewSeat, 'id'>
        & { freeformSeat?: Maybe<(
          { __typename?: 'FreeformSeat' }
          & { jobStageInterviewSeatEmployees?: Maybe<Array<(
            { __typename?: 'JobStageInterviewSeatEmployee' }
            & Pick<JobStageInterviewSeatEmployee, 'jobStageInterviewSeatId' | 'employeeId' | 'preferenceLevel'>
          )>> }
        )>, moduleSeat?: Maybe<(
          { __typename?: 'ModuleSeat' }
          & Pick<ModuleSeat, 'interviewModuleId'>
        )>, linkedSeat?: Maybe<(
          { __typename?: 'LinkedSeat' }
          & Pick<LinkedSeat, 'linkedJobStageInterviewSeatId'>
        )> }
      )>> }
    )>> }
  )>> }
);

export type UsePopulateEventData_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id' | 'interviewType'>
      & { atsInterviewDefinition?: Maybe<(
        { __typename?: 'AtsInterviewDefinition' }
        & Pick<AtsInterviewDefinition, 'atsId'>
      )> }
    )>> }
  )>> }
);

export type ScheduleNowTaskCreateMutationVariables = Exact<{
  input: TaskCreateInput;
}>;


export type ScheduleNowTaskCreateMutation = (
  { __typename?: 'Mutation' }
  & { taskCreate?: Maybe<(
    { __typename?: 'TaskCreateResult' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    ) }
  )> }
);

export type CreateScheduleMutationVariables = Exact<{
  input: ScheduleCreateInput;
}>;


export type CreateScheduleMutation = (
  { __typename?: 'Mutation' }
  & { scheduleCreate?: Maybe<(
    { __typename?: 'ScheduleCreateResult' }
    & Pick<ScheduleCreateResult, 'applicationStageId'>
    & { debriefRescheduleDetails: (
      { __typename?: 'DebriefRescheduleDetails' }
      & Pick<DebriefRescheduleDetails, 'hasScheduleTimeAfterDebriefStartAt' | 'hasAttendeeAdded'>
    ) }
  )> }
);

export type RescheduleMutationVariables = Exact<{
  input: ScheduleRescheduleInput;
}>;


export type RescheduleMutation = (
  { __typename?: 'Mutation' }
  & { scheduleReschedule?: Maybe<(
    { __typename?: 'ScheduleRescheduleResult' }
    & Pick<ScheduleRescheduleResult, 'applicationStageId'>
    & { debriefRescheduleDetails: (
      { __typename?: 'DebriefRescheduleDetails' }
      & Pick<DebriefRescheduleDetails, 'hasScheduleTimeAfterDebriefStartAt' | 'hasAttendeeAdded'>
    ) }
  )> }
);

export type UseSubmitCreateSchedule_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseGetInterviewers_InterviewPlanFragment
  & GetSaveInterviewPlanInput_InterviewPlanFragment
  & UsePopulateEventData_InterviewPlanFragment
  & UseSubmitCreateScheduleRest_InterviewPlanFragment
);

export type CreateScheduleSaveInterviewPlanMutationVariables = Exact<{
  input: JobStageInterviewPlanUpsertInput;
}>;


export type CreateScheduleSaveInterviewPlanMutation = (
  { __typename?: 'Mutation' }
  & { jobStageInterviewPlanUpsert?: Maybe<(
    { __typename?: 'JobStageInterviewPlanResult' }
    & { jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
    )> }
  )> }
);

export type UseSubmitCreateScheduleRest_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseGetInterviewers_InterviewPlanFragment
  & GetSaveInterviewPlanInput_InterviewPlanFragment
  & UsePopulateEventData_InterviewPlanFragment
);

export type InterviewerContentCard_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseInterviewEventTemplateContent_InterviewPlanFragment
);

export type UseInterviewEventTemplateContent_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseScheduleContent_InterviewPlanFragment
);

export type InterviewerCommunicationCards_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & InterviewerContentCard_InterviewPlanFragment
);

export type CustomInterviewMeetingLocationCreateMutationVariables = Exact<{
  input: MeetingLocationCreateInput;
}>;


export type CustomInterviewMeetingLocationCreateMutation = (
  { __typename?: 'Mutation' }
  & { meetingLocationCreate?: Maybe<(
    { __typename?: 'MeetingLocationCreateResult' }
    & { meetingLocation?: Maybe<(
      { __typename?: 'MeetingLocation' }
      & Pick<MeetingLocation, 'id' | 'type' | 'videoMeetingLink'>
      & { details?: Maybe<(
        { __typename?: 'MeetingLocationDetails' }
        & Pick<MeetingLocationDetails, 'customLocation'>
      )> }
    )> }
  )> }
);

export type CalendarMeetingCreateMutationVariables = Exact<{
  input: CalendarMeetingCreateInput;
}>;


export type CalendarMeetingCreateMutation = (
  { __typename?: 'Mutation' }
  & { calendarMeetingCreate?: Maybe<(
    { __typename?: 'GoogleMeetingResult' }
    & Pick<GoogleMeetingResult, 'url'>
  )> }
);

export type VideoInterviewMeetingLocationCreateMutationVariables = Exact<{
  input: MeetingLocationCreateInput;
}>;


export type VideoInterviewMeetingLocationCreateMutation = (
  { __typename?: 'Mutation' }
  & { meetingLocationCreate?: Maybe<(
    { __typename?: 'MeetingLocationCreateResult' }
    & { meetingLocation?: Maybe<(
      { __typename?: 'MeetingLocation' }
      & Pick<MeetingLocation, 'id' | 'type' | 'videoMeetingLink'>
    )> }
  )> }
);

export type ZoomMeetingCreateMutationVariables = Exact<{
  input: ZoomMeetingCreateInput;
}>;


export type ZoomMeetingCreateMutation = (
  { __typename?: 'Mutation' }
  & { zoomMeetingCreate?: Maybe<(
    { __typename?: 'ZoomMeetingCreateResult' }
    & Pick<ZoomMeetingCreateResult, 'joinUrl' | 'meetingId' | 'password' | 'pstnPassword'>
    & { dialInfo?: Maybe<Array<(
      { __typename?: 'DialInfo' }
      & Pick<DialInfo, 'country' | 'countryName' | 'city' | 'number' | 'type'>
    )>> }
  )> }
);

export type ZoomInterviewMeetingLocationCreateMutationVariables = Exact<{
  input: MeetingLocationCreateInput;
}>;


export type ZoomInterviewMeetingLocationCreateMutation = (
  { __typename?: 'Mutation' }
  & { meetingLocationCreate?: Maybe<(
    { __typename?: 'MeetingLocationCreateResult' }
    & { meetingLocation?: Maybe<(
      { __typename?: 'MeetingLocation' }
      & Pick<MeetingLocation, 'id' | 'type' | 'videoMeetingLink'>
      & { details?: Maybe<(
        { __typename?: 'MeetingLocationDetails' }
        & { zoomInfo?: Maybe<(
          { __typename?: 'ZoomInfo' }
          & Pick<ZoomInfo, 'password' | 'meetingId' | 'userId' | 'pstnPassword'>
          & { dialInfo?: Maybe<Array<(
            { __typename?: 'DialInfo' }
            & Pick<DialInfo, 'type' | 'number' | 'city' | 'countryName' | 'country'>
          )>> }
        )> }
      )> }
    )> }
  )> }
);

export type LocationCard_InterviewMeetingLocationSettingFragment = (
  { __typename?: 'InterviewMeetingLocationSettingPref' }
  & UsePopulateInterviewMeetingLocation_InterviewMeetingLocationSettingFragment
);

export type UsePopulateInterviewMeetingLocation_InterviewMeetingLocationSettingFragment = (
  { __typename?: 'InterviewMeetingLocationSettingPref' }
  & Pick<InterviewMeetingLocationSettingPref, 'interviewMeetingLocationType' | 'dynamicHost' | 'hostEmployeeId' | 'remoteVideoMeetingHostUserId' | 'customLocation'>
);

export type InterviewMeetingLocationZoomUsersByEmployeeIdsQueryVariables = Exact<{
  employeeIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type InterviewMeetingLocationZoomUsersByEmployeeIdsQuery = (
  { __typename?: 'Query' }
  & { zoomUsersByIds?: Maybe<Array<(
    { __typename?: 'ZoomUser' }
    & Pick<ZoomUser, 'employeeId' | 'zoomUserId'>
  )>> }
);

export type SlackChannelByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type SlackChannelByNameQuery = (
  { __typename?: 'Query' }
  & { slackChannelByName?: Maybe<Array<(
    { __typename?: 'SlackChannel' }
    & Pick<SlackChannel, 'id' | 'name'>
  )>> }
);

export type ExistingSlackChannelsQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type ExistingSlackChannelsQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & { existingConversations?: Maybe<Array<(
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id' | 'remoteId' | 'remoteName' | 'isArchived'>
    )>> }
  )> }
);

export type BaseSlackChannelCard_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseSlackTemplateContent_InterviewPlanFragment
);

export type SlackChannelCard_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & BaseSlackChannelCard_InterviewPlanFragment
);

export type UseSlackTemplateContent_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseScheduleContent_InterviewPlanFragment
);

export type UseScheduleContent_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UsePopulateEventData_InterviewPlanFragment
);

export type Communications_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id' | 'preferredPhoneNumber'>
);

export type Communications_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & CandidateEmailCard_InterviewPlanFragment
  & CandidateEventCard_InterviewPlanFragment
  & CodingCard_InterviewPlanFragment
  & CommunicationsPreview_InterviewPlanFragment
  & InterviewerCommunicationCards_InterviewPlanFragment
  & SlackChannelCard_InterviewPlanFragment
);

export type Communications_JobStageSettingsFragment = (
  { __typename?: 'JobStageSettingsV2' }
  & Pick<JobStageSettingsV2, 'id'>
  & { interviewMeetingLocationSettingWithLevel?: Maybe<(
    { __typename?: 'InterviewMeetingLocationSettingWithLevel' }
    & { interviewMeetingLocationSetting?: Maybe<(
      { __typename?: 'InterviewMeetingLocationSettingPref' }
      & LocationCard_InterviewMeetingLocationSettingFragment
    )> }
  )> }
);

export type EventContent_InterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & InterviewDetails_InterviewFragment
);

export type EventContent_OriginalInterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & InterviewDetails_OriginalInterviewsFragment
);

export type InterviewDetails_InterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & { atsInterviewDefinition?: Maybe<(
    { __typename?: 'AtsInterviewDefinition' }
    & Pick<AtsInterviewDefinition, 'atsId'>
  )>, jobStageInterviewSeats?: Maybe<Array<(
    { __typename?: 'JobStageInterviewSeat' }
    & Pick<JobStageInterviewSeat, 'id'>
    & UpdateInterviewSeat_InterviewSeatFragment
  )>> }
  & UpdateInterviewSeat_InterviewFragment
);

export type InterviewDetails_OriginalInterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id'>
  & { jobStageInterviewSeats?: Maybe<Array<(
    { __typename?: 'JobStageInterviewSeat' }
    & Pick<JobStageInterviewSeat, 'id'>
    & UpdateInterviewSeat_OriginalInterviewSeatFragment
  )>> }
  & UpdateInterviewSeat_OriginalInterviewFragment
);

export type UpdateInterviewSeat_InterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'name'>
);

export type UpdateInterviewSeat_OriginalInterviewFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'name'>
);

export type UpdateInterviewSeat_InterviewSeatFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id' | 'type' | 'index'>
  & { freeformSeat?: Maybe<(
    { __typename?: 'FreeformSeat' }
    & { jobStageInterviewSeatEmployees?: Maybe<Array<(
      { __typename?: 'JobStageInterviewSeatEmployee' }
      & Pick<JobStageInterviewSeatEmployee, 'employeeId' | 'preferenceLevel'>
    )>> }
  )>, moduleSeat?: Maybe<(
    { __typename?: 'ModuleSeat' }
    & Pick<ModuleSeat, 'interviewModuleId' | 'selectedEmployeeIds'>
    & { jobStageInterviewSeatAttributes?: Maybe<Array<(
      { __typename?: 'JobStageInterviewSeatAttribute' }
      & Pick<JobStageInterviewSeatAttribute, 'id' | 'attributeNameId' | 'attributeTagValueId'>
    )>> }
  )>, linkedSeat?: Maybe<(
    { __typename?: 'LinkedSeat' }
    & Pick<LinkedSeat, 'linkedJobStageInterviewSeatId'>
  )> }
);

export type UpdateInterviewSeat_OriginalInterviewSeatFragment = (
  { __typename?: 'JobStageInterviewSeat' }
  & Pick<JobStageInterviewSeat, 'id' | 'type' | 'index'>
  & { freeformSeat?: Maybe<(
    { __typename?: 'FreeformSeat' }
    & { jobStageInterviewSeatEmployees?: Maybe<Array<(
      { __typename?: 'JobStageInterviewSeatEmployee' }
      & Pick<JobStageInterviewSeatEmployee, 'employeeId' | 'preferenceLevel'>
    )>> }
  )>, moduleSeat?: Maybe<(
    { __typename?: 'ModuleSeat' }
    & Pick<ModuleSeat, 'interviewModuleId' | 'selectedEmployeeIds'>
    & { jobStageInterviewSeatAttributes?: Maybe<Array<(
      { __typename?: 'JobStageInterviewSeatAttribute' }
      & Pick<JobStageInterviewSeatAttribute, 'id' | 'attributeNameId' | 'attributeTagValueId'>
    )>> }
  )>, linkedSeat?: Maybe<(
    { __typename?: 'LinkedSeat' }
    & Pick<LinkedSeat, 'linkedJobStageInterviewSeatId'>
  )> }
);

export type InterviewerSuggestionsQueryVariables = Exact<{
  input: InterviewerSuggestionsInput;
  interviewModuleId: Scalars['uuid'];
}>;


export type InterviewerSuggestionsQuery = (
  { __typename?: 'Query' }
  & { interviewerSuggestions?: Maybe<(
    { __typename?: 'InterviewerSuggestionsOutput' }
    & { employees?: Maybe<Array<(
      { __typename?: 'InterviewerSuggestion' }
      & Pick<InterviewerSuggestion, 'role' | 'employeeId' | 'flags'>
      & { companyHolidays: Array<(
        { __typename?: 'CompanyHoliday' }
        & Pick<CompanyHoliday, 'id'>
        & GetTextForFlag_CompanyHolidayFragment
      )>, loadAndLimit?: Maybe<(
        { __typename?: 'LoadAndLimit' }
        & Pick<LoadAndLimit, 'dailyLoad' | 'weeklyLoad' | 'lastWeeklyLoad' | 'dailyLoadMinutes' | 'weeklyLoadMinutes' | 'lastWeeklyLoadMinutes'>
        & { dailyInterviewLimit?: Maybe<(
          { __typename?: 'InterviewLimit' }
          & Pick<InterviewLimit, 'type' | 'limit'>
        )>, weeklyInterviewLimit?: Maybe<(
          { __typename?: 'InterviewLimit' }
          & Pick<InterviewLimit, 'type' | 'limit'>
        )> }
      )>, employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'fullName' | 'givenName' | 'linkedinUrl' | 'slackImageUrl' | 'timezone' | 'email' | 'title'>
        & { interviewModuleMember?: Maybe<(
          { __typename?: 'InterviewModuleMember' }
          & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId'>
          & { stats?: Maybe<(
            { __typename?: 'InterviewModuleMemberStats' }
            & Pick<InterviewModuleMemberStats, 'trainingStatus' | 'currentTrainingSessionStatus' | 'completedAsShadow' | 'completedAsReverseShadow' | 'shadowOffset' | 'reverseShadowOffset'>
          )> }
          & IsModuleMemberDisabledForScheduling_ModuleMemberFragment
          & ModuleMember_InterviewModuleMemberFragment
        )> }
      )>, eventsWithConflicts?: Maybe<Array<(
        { __typename?: 'EventsWithConflicts' }
        & Pick<EventsWithConflicts, 'flag' | 'events'>
      )>> }
    )>>, others?: Maybe<Array<(
      { __typename?: 'InterviewerSuggestion' }
      & Pick<InterviewerSuggestion, 'role' | 'employeeId' | 'flags'>
      & { companyHolidays: Array<(
        { __typename?: 'CompanyHoliday' }
        & Pick<CompanyHoliday, 'id'>
        & GetTextForFlag_CompanyHolidayFragment
      )>, loadAndLimit?: Maybe<(
        { __typename?: 'LoadAndLimit' }
        & Pick<LoadAndLimit, 'dailyLoad' | 'weeklyLoad' | 'lastWeeklyLoad' | 'dailyLoadMinutes' | 'weeklyLoadMinutes' | 'lastWeeklyLoadMinutes'>
        & { dailyInterviewLimit?: Maybe<(
          { __typename?: 'InterviewLimit' }
          & Pick<InterviewLimit, 'type' | 'limit'>
        )>, weeklyInterviewLimit?: Maybe<(
          { __typename?: 'InterviewLimit' }
          & Pick<InterviewLimit, 'type' | 'limit'>
        )> }
      )>, employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'fullName' | 'givenName' | 'linkedinUrl' | 'slackImageUrl' | 'timezone' | 'email' | 'title'>
      )>, eventsWithConflicts?: Maybe<Array<(
        { __typename?: 'EventsWithConflicts' }
        & Pick<EventsWithConflicts, 'flag' | 'events'>
      )>> }
    )>> }
  )> }
);

export type InterviewModuleMemberLookupQueryVariables = Exact<{
  memberIds: Array<Scalars['uuid']> | Scalars['uuid'];
  interviewModuleIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type InterviewModuleMemberLookupQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMemberLookup?: Maybe<(
    { __typename?: 'InterviewModuleMemberLookupOutput' }
    & { items: Array<(
      { __typename?: 'InterviewModuleMember' }
      & Pick<InterviewModuleMember, 'interviewModuleId' | 'employeeId' | 'status'>
      & { interviewModule?: Maybe<(
        { __typename?: 'InterviewModule' }
        & Pick<InterviewModule, 'id' | 'name' | 'shadowsRequired' | 'reverseShadowsRequired'>
      )>, employee?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'fullName'>
      )>, stats?: Maybe<(
        { __typename?: 'InterviewModuleMemberStats' }
        & Pick<InterviewModuleMemberStats, 'completed' | 'upcoming' | 'shadowOffset' | 'shadowsRequired' | 'reverseShadowOffset' | 'reverseShadowsRequired' | 'completedAsShadow' | 'completedAsReverseShadow' | 'currentTrainingSessionStatus'>
      )> }
    )> }
  )> }
);

export type BaseCalendarView_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id'>
      & EventContent_InterviewFragment
      & EventContent_OriginalInterviewsFragment
      & InterviewDetails_InterviewFragment
      & InterviewDetails_OriginalInterviewsFragment
    )>> }
  )>> }
);

export type CalendarView_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & BaseCalendarView_InterviewPlanFragment
);

export type AbsoluteEmployeeWorkHoursQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
}>;


export type AbsoluteEmployeeWorkHoursQuery = (
  { __typename?: 'Query' }
  & { employeeByIds?: Maybe<Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName'>
    & { absoluteWorkHours: Array<(
      { __typename?: 'DateTimeRangeOutput' }
      & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
    )> }
  )>> }
);

export type BaseInterviewPlanPreview_JobStageInterviewGroupsFragment = (
  { __typename?: 'JobStageInterviewGroup' }
  & Pick<JobStageInterviewGroup, 'id'>
  & { jobStageInterviews?: Maybe<Array<(
    { __typename?: 'JobStageInterview' }
    & Pick<JobStageInterview, 'id' | 'name' | 'interviewType' | 'duration' | 'isLockedOrder'>
  )>> }
);

export type InterviewPlanPreview_JobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & BaseInterviewPlanPreview_JobStageInterviewGroupsFragment
  )>> }
  & GetSaveInterviewPlanInput_InterviewPlanFragment
  & UseIsInterviewPlanValid_JobStageFragment
  & UseSubmitInterviewPlan_InterviewPlanFragment
);

export type SaveInterviewPlanToJobStageMutationVariables = Exact<{
  input: JobStageInterviewPlanUpsertInput;
}>;


export type SaveInterviewPlanToJobStageMutation = (
  { __typename?: 'Mutation' }
  & { jobStageInterviewPlanUpsert?: Maybe<(
    { __typename?: 'JobStageInterviewPlanResult' }
    & { jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
    )> }
  )> }
);

export type ScheduleInterviewPlan_JobFragment = (
  { __typename?: 'Job' }
  & Pick<Job, 'id' | 'atsId'>
);

export type ScheduleInterviewPlan_JobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & InterviewPlanPreview_JobStageFragment
  & InterviewPlan_JobStageFragment
);

export type UseScheduleOptionsRequestBody_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id' | 'locked'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id' | 'interviewType' | 'customDuration' | 'duration' | 'forcedStartAt' | 'name' | 'useAtsName' | 'isLockedOrder' | 'isHiddenFromCandidate'>
      & { atsInterviewDefinition?: Maybe<(
        { __typename?: 'AtsInterviewDefinition' }
        & Pick<AtsInterviewDefinition, 'atsId' | 'name'>
      )>, jobStageInterviewSeats?: Maybe<Array<(
        { __typename?: 'JobStageInterviewSeat' }
        & Pick<JobStageInterviewSeat, 'id'>
        & { freeformSeat?: Maybe<(
          { __typename?: 'FreeformSeat' }
          & { jobStageInterviewSeatEmployees?: Maybe<Array<(
            { __typename?: 'JobStageInterviewSeatEmployee' }
            & Pick<JobStageInterviewSeatEmployee, 'jobStageInterviewSeatId' | 'employeeId' | 'preferenceLevel'>
          )>> }
        )>, moduleSeat?: Maybe<(
          { __typename?: 'ModuleSeat' }
          & Pick<ModuleSeat, 'interviewModuleId' | 'selectedEmployeeIds'>
          & { jobStageInterviewSeatAttributes?: Maybe<Array<(
            { __typename?: 'JobStageInterviewSeatAttribute' }
            & Pick<JobStageInterviewSeatAttribute, 'id' | 'attributeNameId' | 'attributeTagValueId'>
          )>>, interviewModule?: Maybe<(
            { __typename?: 'InterviewModule' }
            & Pick<InterviewModule, 'id'>
            & { interviewModuleMembers?: Maybe<(
              { __typename?: 'InterviewModuleMembersOutput' }
              & Pick<InterviewModuleMembersOutput, 'nextCursor'>
              & { items: Array<(
                { __typename?: 'InterviewModuleMember' }
                & Pick<InterviewModuleMember, 'id' | 'employeeId' | 'status'>
              )> }
            )> }
          )> }
        )>, linkedSeat?: Maybe<(
          { __typename?: 'LinkedSeat' }
          & Pick<LinkedSeat, 'linkedJobStageInterviewSeatId'>
        )> }
      )>> }
    )>> }
  )>>, schedulingWindow?: Maybe<(
    { __typename?: 'Duration' }
    & Pick<Duration, 'seconds'>
  )>, excludedEmployees?: Maybe<Array<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )>> }
);

export type UseSubmitInterviewPlan_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseScheduleOptionsRequestBody_InterviewPlanFragment
);

export type GetTextForFlag_CompanyHolidayFragment = (
  { __typename?: 'CompanyHoliday' }
  & Pick<CompanyHoliday, 'id' | 'name' | 'startAt'>
);

export type ScheduleFlowApplicationQueryVariables = Exact<{
  id: Scalars['uuid'];
  fetchInterviewPlan: Scalars['Boolean'];
}>;


export type ScheduleFlowApplicationQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name' | 'atsId'>
      & ScheduleFlowDataProvider_JobFragment
    )>, jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
      & { jobStageSettingsV2?: Maybe<(
        { __typename?: 'JobStageSettingsV2' }
        & Pick<JobStageSettingsV2, 'id'>
        & ScheduleFlowDataProvider_JobStageSettingsFragment
      )> }
      & ScheduleFlowDataProvider_InterviewPlanFragment
    )>, candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id' | 'timezone'>
      & { atsLatestAvailability?: Maybe<(
        { __typename?: 'CandidateAtsAvailability' }
        & Pick<CandidateAtsAvailability, 'timezone'>
        & UseCandidateAvailability_AtsLatestAvailabilityFragment
      )> }
      & ScheduleFlowDataProvider_CandidateFragment
    )> }
  )> }
);

export type ScheduleFlowJobStageQueryVariables = Exact<{
  jobStageId: Scalars['uuid'];
  fetchInterviewPlan: Scalars['Boolean'];
}>;


export type ScheduleFlowJobStageQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name' | 'atsId'>
      & ScheduleFlowDataProvider_JobFragment
    )>, jobStageSettingsV2?: Maybe<(
      { __typename?: 'JobStageSettingsV2' }
      & Pick<JobStageSettingsV2, 'id'>
      & ScheduleFlowDataProvider_JobStageSettingsFragment
    )> }
    & ScheduleFlowDataProvider_InterviewPlanFragment
  )> }
);

export type ScheduleFlowTaskQueryVariables = Exact<{
  id: Scalars['uuid'];
  fetchInterviewPlan: Scalars['Boolean'];
}>;


export type ScheduleFlowTaskQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { application: (
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'timezone'>
        & { atsLatestAvailability?: Maybe<(
          { __typename?: 'CandidateAtsAvailability' }
          & Pick<CandidateAtsAvailability, 'timezone'>
          & UseCandidateAvailability_AtsLatestAvailabilityFragment
        )> }
        & ScheduleFlowDataProvider_CandidateFragment
      )> }
    ), interviewPlan: (
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
      & ScheduleFlowDataProvider_InterviewPlanFragment
    ), jobStage: (
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
      & { job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name' | 'atsId'>
        & ScheduleFlowDataProvider_JobFragment
      )>, jobStageSettingsV2?: Maybe<(
        { __typename?: 'JobStageSettingsV2' }
        & Pick<JobStageSettingsV2, 'id'>
        & ScheduleFlowDataProvider_JobStageSettingsFragment
      )> }
    ), availabilities: Array<(
      { __typename?: 'CandidateAvailability' }
      & Pick<CandidateAvailability, 'id'>
      & UseCandidateAvailability_AvailabilitiesFragment
    )> }
  )> }
);

export type CandidateAtsLatestAvailabilityQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type CandidateAtsLatestAvailabilityQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & { candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'recruiterId' | 'coordinatorId'>
      & CandidateAtsLatestAvailabilityFragmentFragment
    )>, job?: Maybe<(
      { __typename?: 'Job' }
      & { hiringManagers?: Maybe<Array<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id'>
      )>> }
    )> }
  )> }
);

export type InitScheduleFlowData_TemplateFragment = (
  { __typename?: 'Template' }
  & Pick<Template, 'id' | 'name' | 'type'>
);

export type InitScheduleFlowDataQueryVariables = Exact<{
  input: InitScheduleFlowInput;
}>;


export type InitScheduleFlowDataQuery = (
  { __typename?: 'Query' }
  & { initScheduleFlow?: Maybe<(
    { __typename?: 'InitScheduleFlowResult' }
    & Pick<InitScheduleFlowResult, 'isPrivateCalendarEvents'>
    & { candidateConfirmationEmailTemplate?: Maybe<(
      { __typename?: 'Template' }
      & InitScheduleFlowData_TemplateFragment
    )>, candidateConfirmationInviteTemplate?: Maybe<(
      { __typename?: 'Template' }
      & InitScheduleFlowData_TemplateFragment
    )>, interviewerConfirmationInviteTemplate?: Maybe<(
      { __typename?: 'Template' }
      & InitScheduleFlowData_TemplateFragment
    )>, interviewerConversationMessageTemplate?: Maybe<(
      { __typename?: 'Template' }
      & InitScheduleFlowData_TemplateFragment
    )>, candidateCalendar?: Maybe<(
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'remoteId'>
    )>, interviewerCalendar?: Maybe<(
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'remoteId'>
    )> }
  )> }
);

export type InterviewPlanFromTaskQueryVariables = Exact<{
  id: Scalars['uuid'];
  skipFetchingInterviwPlan: Scalars['Boolean'];
}>;


export type InterviewPlanFromTaskQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & { application: (
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'coordinatorId' | 'recruiterId' | 'fullName' | 'timezone' | 'email' | 'additionalEmails'>
        & { atsCandidate?: Maybe<(
          { __typename?: 'AtsCandidate' }
          & Pick<AtsCandidate, 'atsId' | 'atsUrl'>
        )>, atsLatestAvailability?: Maybe<(
          { __typename?: 'CandidateAtsAvailability' }
          & Pick<CandidateAtsAvailability, 'jobName' | 'jobStageName' | 'timezone' | 'originalText'>
          & { intervals?: Maybe<Array<(
            { __typename?: 'DateTimeRangeOutput' }
            & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
          )>> }
        )> }
      )>, existingConversations?: Maybe<Array<(
        { __typename?: 'Conversation' }
        & Pick<Conversation, 'id' | 'remoteId' | 'remoteName'>
      )>> }
    ), availabilities: Array<(
      { __typename?: 'CandidateAvailability' }
      & Pick<CandidateAvailability, 'id' | 'createdAt'>
      & CandidateAvailabilityFragmentFragment
    )>, interviewPlan?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
      & JobStage_InterviewPlanFragment
    )>, jobStage: (
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
      & { job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name' | 'isConfidential'>
        & { hiringManagers?: Maybe<Array<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id'>
        )>> }
      )> }
    ) }
  )> }
);

export type UseCandidateAvailability_AtsLatestAvailabilityFragment = (
  { __typename?: 'CandidateAtsAvailability' }
  & Pick<CandidateAtsAvailability, 'jobName' | 'jobStageName' | 'originalText'>
  & { intervals?: Maybe<Array<(
    { __typename?: 'DateTimeRangeOutput' }
    & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
  )>> }
);

export type UseCandidateAvailability_AvailabilitiesFragment = (
  { __typename?: 'CandidateAvailability' }
  & Pick<CandidateAvailability, 'id'>
  & { candidateEnteredAvailability?: Maybe<Array<(
    { __typename?: 'DateTimeRangeOutput' }
    & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
  )>>, rcEnteredAvailability?: Maybe<Array<(
    { __typename?: 'DateTimeRangeOutput' }
    & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
  )>> }
);

export type AvailabilityTaskModal_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'timezone'>
    & { coordinator?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
    )> }
  )>, jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
    )> }
  )> }
);

export type AvailabilityOptionsSettingsQueryVariables = Exact<{
  jobStageId: Scalars['uuid'];
}>;


export type AvailabilityOptionsSettingsQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & { jobStageSettings?: Maybe<(
      { __typename?: 'JobStageSettings' }
      & CandidateAvailabilityOptionsSettings_JobStageSettingsFragment
    )> }
  )> }
);

export type BasicTaskModal_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
  )>, candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'timezone'>
  )> }
  & CreateTaskStep_ApplicationFragment
);

export type BasicTaskModalMutationVariables = Exact<{
  input: TaskCreateInput;
}>;


export type BasicTaskModalMutation = (
  { __typename?: 'Mutation' }
  & { taskCreate?: Maybe<(
    { __typename?: 'TaskCreateResult' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    ) }
  )> }
);

export type CreateBasicTaskInterviewPlanQueryVariables = Exact<{
  jobStageId: Scalars['uuid'];
}>;


export type CreateBasicTaskInterviewPlanQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
    & CreateTaskStep_JobStageFragment
    & CloneInterviewPlan_InterviewPlanFragment
    & GetSaveInterviewPlanInput_InterviewPlanFragment
  )> }
);

export type SelfScheduleTaskModal_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'timezone'>
    & { coordinator?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
    )> }
  )>, jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
  )>, job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
  )> }
);

export type SelfScheduleOptionsSettingsQueryVariables = Exact<{
  jobStageId: Scalars['uuid'];
}>;


export type SelfScheduleOptionsSettingsQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & { jobStageSettings?: Maybe<(
      { __typename?: 'JobStageSettings' }
      & SelfScheduleOptionSettings_JobStageSettingsFragment
    )> }
  )> }
);

export type CreateTaskDialogPage_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & JobStageSelect_JobFragment
  )>, jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
  )>, candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id' | 'timezone'>
    & { coordinator?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName'>
    )> }
  )> }
  & CreateTaskStep_ApplicationFragment
);

export type CreateTaskDialogPage_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & CreateTaskStep_JobStageFragment
  & CloneInterviewPlan_InterviewPlanFragment
  & UseIsInterviewPlanValid_JobStageFragment
  & UseInterviewPlanHasInterviewHiddenFromCandidate_InterviewPlanFragment
);

export type CreateTaskStep_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )>, job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
    & { jobSettings: (
      { __typename?: 'JobSettings' }
      & { defaultTaskQueue?: Maybe<(
        { __typename?: 'TaskQueue' }
        & QueueSelect_DefaultTaskQueueFragment
      )> }
    ) }
    & InterviewPlan_JobFragment
    & JobStageSelect_JobFragment
  )>, candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'id'>
    & { coordinator?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName'>
    )>, recruiter?: Maybe<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'fullName'>
    )> }
  )>, atsApplication?: Maybe<(
    { __typename?: 'AtsApplication' }
    & Pick<AtsApplication, 'atsId' | 'canMoveJobStage'>
  )> }
);

export type CreateTaskStep_JobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & InterviewPlan_JobStageFragment
  & UseInterviewPlanHasInterviewHiddenFromCandidate_InterviewPlanFragment
);

export type DefaultTaskAssigneeQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
  prefName: Scalars['String'];
}>;


export type DefaultTaskAssigneeQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & { candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & { coordinator?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
      )>, recruiter?: Maybe<(
        { __typename?: 'Employee' }
        & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
      )> }
    )> }
  )>, thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { orgPref?: Maybe<(
      { __typename?: 'OrgPref' }
      & Pick<OrgPref, 'id' | 'orgId' | 'prefName' | 'stringValue'>
    )> }
  )>, thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )> }
);

export type CoordinatorRecruiterUserInfosQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type CoordinatorRecruiterUserInfosQuery = (
  { __typename?: 'Query' }
  & { userInfoByEmployeeIds?: Maybe<Array<(
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'userId' | 'employeeId' | 'permissions'>
  )>> }
);

export type MigrateApplicationMutationVariables = Exact<{
  input: ApplicationUpdateInput;
}>;


export type MigrateApplicationMutation = (
  { __typename?: 'Mutation' }
  & { applicationUpdate: (
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'isTaskEnabled'>
  ) }
);

export type TaskSetAssigneeMutationVariables = Exact<{
  input: TaskSetAssigneeInput;
}>;


export type TaskSetAssigneeMutation = (
  { __typename?: 'Mutation' }
  & { taskSetAssignee: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { assignee?: Maybe<(
      { __typename?: 'Employee' }
      & EmployeeFragment
    )> }
  ) }
);

export type ScheduleTaskAssignee_TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id'>
  & { assignee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
  )> }
);

type ScheduleTaskFlagCompact_TaskFlag_TaskFlagCandidateDeclined_Fragment = (
  { __typename?: 'TaskFlagCandidateDeclined' }
  & Pick<TaskFlagCandidateDeclined, 'id' | 'flagType'>
);

type ScheduleTaskFlagCompact_TaskFlag_TaskFlagInterviewerDebriefDeclined_Fragment = (
  { __typename?: 'TaskFlagInterviewerDebriefDeclined' }
  & Pick<TaskFlagInterviewerDebriefDeclined, 'id' | 'flagType'>
);

type ScheduleTaskFlagCompact_TaskFlag_TaskFlagInterviewerDeclined_Fragment = (
  { __typename?: 'TaskFlagInterviewerDeclined' }
  & Pick<TaskFlagInterviewerDeclined, 'id' | 'flagType'>
);

type ScheduleTaskFlagCompact_TaskFlag_TaskFlagRequestedChange_Fragment = (
  { __typename?: 'TaskFlagRequestedChange' }
  & Pick<TaskFlagRequestedChange, 'id' | 'flagType'>
);

export type ScheduleTaskFlagCompact_TaskFlagFragment = ScheduleTaskFlagCompact_TaskFlag_TaskFlagCandidateDeclined_Fragment | ScheduleTaskFlagCompact_TaskFlag_TaskFlagInterviewerDebriefDeclined_Fragment | ScheduleTaskFlagCompact_TaskFlag_TaskFlagInterviewerDeclined_Fragment | ScheduleTaskFlagCompact_TaskFlag_TaskFlagRequestedChange_Fragment;

export type ScheduleTaskTagCompact_TaskTagFragment = (
  { __typename?: 'TaskTag' }
  & Pick<TaskTag, 'id' | 'name'>
);

type ScheduleTaskFlags_TaskFlag_TaskFlagCandidateDeclined_Fragment = (
  { __typename?: 'TaskFlagCandidateDeclined' }
  & Pick<TaskFlagCandidateDeclined, 'id' | 'createdAt' | 'flagType' | 'actorType' | 'resolvedAt'>
  & { declinedCandidateEvent: (
    { __typename?: 'ApplicationStageCandidateEvent' }
    & Pick<ApplicationStageCandidateEvent, 'id' | 'name'>
  ), actorEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )>, resolvedBy?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )> }
);

type ScheduleTaskFlags_TaskFlag_TaskFlagInterviewerDebriefDeclined_Fragment = (
  { __typename?: 'TaskFlagInterviewerDebriefDeclined' }
  & Pick<TaskFlagInterviewerDebriefDeclined, 'id' | 'createdAt' | 'flagType' | 'actorType' | 'resolvedAt'>
  & { declinedDebrief: (
    { __typename?: 'ApplicationDebrief' }
    & Pick<ApplicationDebrief, 'id' | 'name'>
  ), actorEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )>, resolvedBy?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )> }
);

type ScheduleTaskFlags_TaskFlag_TaskFlagInterviewerDeclined_Fragment = (
  { __typename?: 'TaskFlagInterviewerDeclined' }
  & Pick<TaskFlagInterviewerDeclined, 'id' | 'createdAt' | 'flagType' | 'actorType' | 'resolvedAt'>
  & { declinedInterview: (
    { __typename?: 'ApplicationStageInterview' }
    & Pick<ApplicationStageInterview, 'id' | 'name'>
  ), actorEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )>, resolvedBy?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )> }
);

type ScheduleTaskFlags_TaskFlag_TaskFlagRequestedChange_Fragment = (
  { __typename?: 'TaskFlagRequestedChange' }
  & Pick<TaskFlagRequestedChange, 'note' | 'id' | 'createdAt' | 'flagType' | 'actorType' | 'resolvedAt'>
  & { actorEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )>, resolvedBy?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )> }
);

export type ScheduleTaskFlags_TaskFlagFragment = ScheduleTaskFlags_TaskFlag_TaskFlagCandidateDeclined_Fragment | ScheduleTaskFlags_TaskFlag_TaskFlagInterviewerDebriefDeclined_Fragment | ScheduleTaskFlags_TaskFlag_TaskFlagInterviewerDeclined_Fragment | ScheduleTaskFlags_TaskFlag_TaskFlagRequestedChange_Fragment;

export type TaskFlagMarkResolvedMutationVariables = Exact<{
  input: TaskFlagMarkResolvedInput;
}>;


export type TaskFlagMarkResolvedMutation = (
  { __typename?: 'Mutation' }
  & { taskFlagMarkResolved: (
    { __typename?: 'TaskFlagMarkResolvedResult' }
    & { taskFlag: (
      { __typename?: 'TaskFlagCandidateDeclined' }
      & ScheduleTaskFlags_TaskFlag_TaskFlagCandidateDeclined_Fragment
    ) | (
      { __typename?: 'TaskFlagInterviewerDebriefDeclined' }
      & ScheduleTaskFlags_TaskFlag_TaskFlagInterviewerDebriefDeclined_Fragment
    ) | (
      { __typename?: 'TaskFlagInterviewerDeclined' }
      & ScheduleTaskFlags_TaskFlag_TaskFlagInterviewerDeclined_Fragment
    ) | (
      { __typename?: 'TaskFlagRequestedChange' }
      & ScheduleTaskFlags_TaskFlag_TaskFlagRequestedChange_Fragment
    ) }
  ) }
);

export type ScheduleTaskStatusSubText_TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'status'>
  & { availabilityRequests: Array<(
    { __typename?: 'CandidateAvailabilityOptions' }
    & Pick<CandidateAvailabilityOptions, 'id' | 'createdAt'>
  )>, selfScheduleRequests: Array<(
    { __typename?: 'SelfScheduleOptions' }
    & Pick<SelfScheduleOptions, 'id' | 'createdAt' | 'creatorEmployeeId'>
  )> }
);

export type ScheduleTaskDetails_TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'isUrgent' | 'name' | 'status' | 'createdAt'>
  & { application: (
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'status'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
      & { jobSettings: (
        { __typename?: 'JobSettings' }
        & { defaultTaskQueue?: Maybe<(
          { __typename?: 'TaskQueue' }
          & QueueSelect_DefaultTaskQueueFragment
        )> }
      ) }
    )>, candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id' | 'fullName'>
      & Candidate_CandidateFragment
    )> }
  ), taskQueue?: Maybe<(
    { __typename?: 'TaskQueue' }
    & QueueSelect_SelectedTaskQueueFragment
  )>, createdBy: (
    { __typename?: 'Employee' }
    & EmployeeFragment
  ), jobStage: (
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name' | 'location'>
    )> }
  ), tags: Array<(
    { __typename?: 'TaskTag' }
    & TaskTags_SelectedTaskTagFragment
  )>, flags: Array<(
    { __typename?: 'TaskFlagCandidateDeclined' }
    & ScheduleTaskFlags_TaskFlag_TaskFlagCandidateDeclined_Fragment
  ) | (
    { __typename?: 'TaskFlagInterviewerDebriefDeclined' }
    & ScheduleTaskFlags_TaskFlag_TaskFlagInterviewerDebriefDeclined_Fragment
  ) | (
    { __typename?: 'TaskFlagInterviewerDeclined' }
    & ScheduleTaskFlags_TaskFlag_TaskFlagInterviewerDeclined_Fragment
  ) | (
    { __typename?: 'TaskFlagRequestedChange' }
    & ScheduleTaskFlags_TaskFlag_TaskFlagRequestedChange_Fragment
  )> }
  & TaskName_TaskFragment
  & ScheduleTaskAssignee_TaskFragment
  & ScheduleTaskStatusSubText_TaskFragment
  & ApplicationConflict_TaskFragment
);

export type TaskSetTagsMutationVariables = Exact<{
  input: TaskSetTagsInput;
}>;


export type TaskSetTagsMutation = (
  { __typename?: 'Mutation' }
  & { taskSetTags: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { tags: Array<(
      { __typename?: 'TaskTag' }
      & TaskTags_SelectedTaskTagFragment
    )> }
  ) }
);

export type TaskUpdateQueueMutationVariables = Exact<{
  input: TaskUpdateInput;
}>;


export type TaskUpdateQueueMutation = (
  { __typename?: 'Mutation' }
  & { taskUpdate: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { taskQueue?: Maybe<(
      { __typename?: 'TaskQueue' }
      & QueueSelect_SelectedTaskQueueFragment
    )> }
  ) }
);

export type Availability_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id'>
    )> }
  )> }
  & BaseCandidateAvailabilitySection_ApplicationFragment
);

export type Availability_AvailabilityFragment = (
  { __typename?: 'CandidateAvailability' }
  & CandidateAvailabilityFragmentFragment
);

export type Availability_AvailabilityRequestFragment = (
  { __typename?: 'CandidateAvailabilityOptions' }
  & Pick<CandidateAvailabilityOptions, 'id' | 'applicationId' | 'availabilityEndDate' | 'availabilityStartDate' | 'candidateNote' | 'externalId' | 'createdAt' | 'jobStageId' | 'minimumTimeBlockMinutes' | 'minutesPerDays' | 'numberOfDays' | 'timezone' | 'updatedAt' | 'lastCandidateCommunicationSentAt' | 'advanceNoticeMinutes' | 'shouldRespectLoadLimit' | 'suggestOverRecruitingKeywords' | 'suggestOverAvailableKeywords' | 'suggestOverFreeTime' | 'rollingDays' | 'inclusionDays'>
  & { creator?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'fullName'>
  )> }
  & BaseCandidateAvailabilitySection_AvailabilityRequestFragment
);

export type Availability_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id' | 'coordinatorId' | 'recruiterId' | 'timezone' | 'atsType'>
);

export type Availability_JobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id' | 'name'>
  & { jobStageSettings?: Maybe<(
    { __typename?: 'JobStageSettings' }
    & CandidateAvailabilityOptionsSettings_JobStageSettingsFragment
  )> }
);

export type Availability_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & JobStage_InterviewPlanFragment
);

export type Availability_ScheduleFragment = (
  { __typename?: 'ApplicationStage' }
  & Pick<ApplicationStage, 'id' | 'createdAt'>
);

export type TaskRequestChangeMutationVariables = Exact<{
  input: TaskRequestChangeInput;
}>;


export type TaskRequestChangeMutation = (
  { __typename?: 'Mutation' }
  & { taskRequestChange: (
    { __typename?: 'TaskRequestChangeResult' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { flags: Array<(
        { __typename?: 'TaskFlagCandidateDeclined' }
        & ScheduleTaskFlags_TaskFlag_TaskFlagCandidateDeclined_Fragment
      ) | (
        { __typename?: 'TaskFlagInterviewerDebriefDeclined' }
        & ScheduleTaskFlags_TaskFlag_TaskFlagInterviewerDebriefDeclined_Fragment
      ) | (
        { __typename?: 'TaskFlagInterviewerDeclined' }
        & ScheduleTaskFlags_TaskFlag_TaskFlagInterviewerDeclined_Fragment
      ) | (
        { __typename?: 'TaskFlagRequestedChange' }
        & ScheduleTaskFlags_TaskFlag_TaskFlagRequestedChange_Fragment
      )> }
    ) }
  ) }
);

export type TaskSetDebriefRequiredMutationVariables = Exact<{
  input: TaskSetDebriefRequiredInput;
}>;


export type TaskSetDebriefRequiredMutation = (
  { __typename?: 'Mutation' }
  & { taskSetDebriefRequired: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'isDebriefRequired'>
  ) }
);

export type Debrief_DebriefRequirementsFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'isDebriefRequired'>
  & { application: (
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
  ), debriefs: Array<(
    { __typename?: 'ApplicationDebrief' }
    & Pick<ApplicationDebrief, 'id' | 'createdAt'>
    & DebriefContentEntity_DebriefFragment
  )> }
);

export type RescheduleLogModal_RescheduleLogFragment = (
  { __typename?: 'RescheduleLog' }
  & Pick<RescheduleLog, 'id' | 'note' | 'rescheduledAt' | 'createdAt' | 'updatedAt' | 'rescheduleReasonId'>
  & { lastUpdatedBy?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName'>
  )>, rescheduleReason?: Maybe<(
    { __typename?: 'RescheduleReason' }
    & Pick<RescheduleReason, 'reason'>
  )>, performedBy?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'fullName'>
  )> }
);

export type RescheduleLogCreateMutationVariables = Exact<{
  input: RescheduleLogCreateInput;
}>;


export type RescheduleLogCreateMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleLogCreate?: Maybe<(
    { __typename?: 'RescheduleLogResult' }
    & { rescheduleLog?: Maybe<(
      { __typename?: 'RescheduleLog' }
      & RescheduleLogModal_RescheduleLogFragment
    )> }
  )> }
);

export type RescheduleLogUpdateMutationVariables = Exact<{
  input: RescheduleLogUpdateInput;
}>;


export type RescheduleLogUpdateMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleLogUpdate?: Maybe<(
    { __typename?: 'RescheduleLogResult' }
    & { rescheduleLog?: Maybe<(
      { __typename?: 'RescheduleLog' }
      & RescheduleLogModal_RescheduleLogFragment
    )> }
  )> }
);

export type RescheduleLogsList_RescheduleLogsFragment = (
  { __typename?: 'RescheduleLog' }
  & Pick<RescheduleLog, 'id'>
  & RescheduleLogModal_RescheduleLogFragment
);

export type RescheduleLogDeleteMutationVariables = Exact<{
  input: RescheduleLogDeleteInput;
}>;


export type RescheduleLogDeleteMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleLogDelete?: Maybe<(
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  )> }
);

export type Schedule_ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, 'id' | 'status' | 'atsType'>
  & { atsApplication?: Maybe<(
    { __typename?: 'AtsApplication' }
    & Pick<AtsApplication, 'atsId'>
    & { atsFields?: Maybe<(
      { __typename: 'WorkdayApplication' }
      & Pick<WorkdayApplication, 'currentStepName'>
    ) | { __typename: 'AshbyApplication' }> }
  )>, job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
  )> }
  & ScheduleContent_ApplicationFragment
  & UseApplicationJobStageSchedulable_ApplicationFragment
);

export type Schedule_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id'>
);

export type Schedule_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { job?: Maybe<(
    { __typename?: 'Job' }
    & Pick<Job, 'id'>
    & InterviewPlan_JobFragment
  )>, jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id'>
      & UseGetInterviewNames_InterviewsFragment
    )>> }
  )>> }
  & InterviewPlan_JobStageFragment
  & GetSaveInterviewPlanInput_InterviewPlanFragment
  & UseIsInterviewPlanValid_JobStageFragment
);

export type Schedule_JobStageFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageSettings?: Maybe<(
    { __typename?: 'JobStageSettings' }
    & SelfScheduleOptionSettings_JobStageSettingsFragment
  )> }
);

export type Schedule_SelfScheduleRequestFragment = (
  { __typename?: 'SelfScheduleOptions' }
  & SelfScheduleOptionsModal_SelfScheduleFragment
);

export type Schedule_ScheduleFragment = (
  { __typename?: 'ApplicationStage' }
  & CancelScheduleApplicationStageFragment
  & ScheduleContent_ScheduleFragment
);

export type ScheduleTaskInterviewPlanUpsertMutationVariables = Exact<{
  input: JobStageInterviewPlanUpsertInput;
}>;


export type ScheduleTaskInterviewPlanUpsertMutation = (
  { __typename?: 'Mutation' }
  & { jobStageInterviewPlanUpsert?: Maybe<(
    { __typename?: 'JobStageInterviewPlanResult' }
    & { jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
      & InterviewPlan_JobStageFragment
      & GetSaveInterviewPlanInput_InterviewPlanFragment
    )> }
  )> }
);

export type UseGetInterviewNames_InterviewsFragment = (
  { __typename?: 'JobStageInterview' }
  & Pick<JobStageInterview, 'id' | 'name' | 'interviewType'>
);

export type ScheduleTaskRequirementsQueryVariables = Exact<{
  scheduleTaskId: Scalars['uuid'];
}>;


export type ScheduleTaskRequirementsQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'status'>
    & { rescheduleLogs: (
      { __typename?: 'RescheduleLogsResult' }
      & { items: Array<(
        { __typename?: 'RescheduleLog' }
        & RescheduleLogsList_RescheduleLogsFragment
      )> }
    ), application: (
      { __typename?: 'Application' }
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Schedule_CandidateFragment
        & Availability_CandidateFragment
      )> }
      & Availability_ApplicationFragment
      & Schedule_ApplicationFragment
    ), availabilities: Array<(
      { __typename?: 'CandidateAvailability' }
      & Pick<CandidateAvailability, 'id' | 'createdAt'>
      & Availability_AvailabilityFragment
    )>, availabilityRequests: Array<(
      { __typename?: 'CandidateAvailabilityOptions' }
      & Pick<CandidateAvailabilityOptions, 'id' | 'createdAt'>
      & Availability_AvailabilityRequestFragment
    )>, interviewPlan: (
      { __typename?: 'JobStage' }
      & Availability_InterviewPlanFragment
      & Schedule_InterviewPlanFragment
    ), jobStage: (
      { __typename?: 'JobStage' }
      & Availability_JobStageFragment
      & Schedule_JobStageFragment
    ), selfScheduleRequests: Array<(
      { __typename?: 'SelfScheduleOptions' }
      & Pick<SelfScheduleOptions, 'id' | 'createdAt'>
      & Schedule_SelfScheduleRequestFragment
    )>, schedules: Array<(
      { __typename?: 'ApplicationStage' }
      & Pick<ApplicationStage, 'id' | 'createdAt' | 'updatedAt'>
      & Availability_ScheduleFragment
      & Schedule_ScheduleFragment
    )> }
    & Debrief_DebriefRequirementsFragment
  )> }
);

export type ScheduleTask_TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'status'>
  & ScheduleTaskDetails_TaskFragment
);

export type TaskDashboardTable_TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id'>
  & TaskDashboardTableRowItem_TaskFragment
);

export type ScheduleDateCell_SchedulesFragment = (
  { __typename?: 'ApplicationStage' }
  & Pick<ApplicationStage, 'id' | 'createdAt' | 'startAt' | 'endAt'>
);

type ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagCandidateDeclined_Fragment = (
  { __typename?: 'TaskFlagCandidateDeclined' }
  & Pick<TaskFlagCandidateDeclined, 'id' | 'resolvedAt'>
  & ScheduleTaskFlagCompact_TaskFlag_TaskFlagCandidateDeclined_Fragment
);

type ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagInterviewerDebriefDeclined_Fragment = (
  { __typename?: 'TaskFlagInterviewerDebriefDeclined' }
  & Pick<TaskFlagInterviewerDebriefDeclined, 'id' | 'resolvedAt'>
  & ScheduleTaskFlagCompact_TaskFlag_TaskFlagInterviewerDebriefDeclined_Fragment
);

type ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagInterviewerDeclined_Fragment = (
  { __typename?: 'TaskFlagInterviewerDeclined' }
  & Pick<TaskFlagInterviewerDeclined, 'id' | 'resolvedAt'>
  & ScheduleTaskFlagCompact_TaskFlag_TaskFlagInterviewerDeclined_Fragment
);

type ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagRequestedChange_Fragment = (
  { __typename?: 'TaskFlagRequestedChange' }
  & Pick<TaskFlagRequestedChange, 'id' | 'resolvedAt'>
  & ScheduleTaskFlagCompact_TaskFlag_TaskFlagRequestedChange_Fragment
);

export type ScheduleTaskDashboardTaskFlags_TaskFlagsFragment = ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagCandidateDeclined_Fragment | ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagInterviewerDebriefDeclined_Fragment | ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagInterviewerDeclined_Fragment | ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagRequestedChange_Fragment;

export type ScheduleTaskDashboardTaskTags_TaskTagsFragment = (
  { __typename?: 'TaskTag' }
  & Pick<TaskTag, 'id'>
  & ScheduleTaskTagCompact_TaskTagFragment
);

export type TaskDashboardTableRowItem_TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'status' | 'isUrgent' | 'createdAt' | 'lastActivityAt'>
  & { application: (
    { __typename?: 'Application' }
    & Pick<Application, 'id' | 'status'>
    & { candidate?: Maybe<(
      { __typename?: 'Candidate' }
      & Pick<Candidate, 'id' | 'fullName'>
      & Candidate_CandidateFragment
    )> }
  ), jobStage: (
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
    & { job?: Maybe<(
      { __typename?: 'Job' }
      & Pick<Job, 'id' | 'name'>
    )> }
  ), taskQueue?: Maybe<(
    { __typename?: 'TaskQueue' }
    & Pick<TaskQueue, 'id' | 'name'>
  )>, createdBy: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'slackImageUrl' | 'fullName'>
  ), schedules: Array<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id'>
    & ScheduleDateCell_SchedulesFragment
  )>, flags: Array<(
    { __typename?: 'TaskFlagCandidateDeclined' }
    & Pick<TaskFlagCandidateDeclined, 'id'>
    & ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagCandidateDeclined_Fragment
  ) | (
    { __typename?: 'TaskFlagInterviewerDebriefDeclined' }
    & Pick<TaskFlagInterviewerDebriefDeclined, 'id'>
    & ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagInterviewerDebriefDeclined_Fragment
  ) | (
    { __typename?: 'TaskFlagInterviewerDeclined' }
    & Pick<TaskFlagInterviewerDeclined, 'id'>
    & ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagInterviewerDeclined_Fragment
  ) | (
    { __typename?: 'TaskFlagRequestedChange' }
    & Pick<TaskFlagRequestedChange, 'id'>
    & ScheduleTaskDashboardTaskFlags_TaskFlags_TaskFlagRequestedChange_Fragment
  )>, tags: Array<(
    { __typename?: 'TaskTag' }
    & Pick<TaskTag, 'id'>
    & ScheduleTaskDashboardTaskTags_TaskTagsFragment
  )> }
  & ScheduleTaskAssignee_TaskFragment
);

export type QueueFilter_SelectedOptionsFragment = (
  { __typename?: 'TaskQueue' }
  & Pick<TaskQueue, 'id' | 'name' | 'memberCount'>
);

export type QueueFilterTaskQueueSearchQueryVariables = Exact<{
  input: TaskQueueSearchInput;
}>;


export type QueueFilterTaskQueueSearchQuery = (
  { __typename?: 'Query' }
  & { taskQueueSearch?: Maybe<(
    { __typename?: 'TaskQueueSearchResult' }
    & { items: Array<(
      { __typename?: 'TaskQueue' }
      & QueueFilter_SelectedOptionsFragment
    )> }
  )> }
);

export type ScheduleTaskDashboardTaskSearchQueryVariables = Exact<{
  input: TaskSearchInput;
}>;


export type ScheduleTaskDashboardTaskSearchQuery = (
  { __typename?: 'Query' }
  & { taskSearch?: Maybe<(
    { __typename?: 'TaskSearchResult' }
    & Pick<TaskSearchResult, 'total' | 'hasNext'>
    & { items: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & TaskDashboardTable_TaskFragment
    )> }
  )> }
);

export type MySchedulingTasksQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type MySchedulingTasksQuery = (
  { __typename?: 'Query' }
  & { assignedToMe?: Maybe<(
    { __typename?: 'TaskSearchResult' }
    & Pick<TaskSearchResult, 'total'>
  )>, created?: Maybe<(
    { __typename?: 'TaskSearchResult' }
    & Pick<TaskSearchResult, 'total'>
  )>, subscribed?: Maybe<(
    { __typename?: 'TaskSearchResult' }
    & Pick<TaskSearchResult, 'total'>
  )>, myQueues?: Maybe<(
    { __typename?: 'TaskSearchResult' }
    & Pick<TaskSearchResult, 'total'>
  )> }
);

export type ScheduleTaskDashboardFiltersEmployeePrefFragment = (
  { __typename?: 'EmployeeTaskDashboardFilterPref' }
  & Pick<EmployeeTaskDashboardFilterPref, 'isUrgent' | 'assigneeEmployeeIds' | 'showUnassigned' | 'creatorEmployeeIds' | 'applicationStatuses' | 'taskStatuses' | 'jobIds' | 'jobStageNames' | 'flags' | 'taskQueueIds' | 'showNoQueue'>
  & { tags: Array<(
    { __typename?: 'TaskTag' }
    & Pick<TaskTag, 'id' | 'name'>
  )> }
);

export type ScheduleTaskDashboardFiltersEmployeePrefQueryVariables = Exact<{
  prefName: Scalars['String'];
}>;


export type ScheduleTaskDashboardFiltersEmployeePrefQuery = (
  { __typename?: 'Query' }
  & { thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
      & { jsonPref?: Maybe<{ __typename?: 'InterviewLimit' } | { __typename?: 'EmployeeTaskDashboardLayoutPref' } | (
        { __typename?: 'EmployeeTaskDashboardFilterPref' }
        & ScheduleTaskDashboardFiltersEmployeePrefFragment
      ) | { __typename?: 'CandidatePageFiltersPref' } | { __typename?: 'MetricsPageRecruitingTeamFiltersPref' } | { __typename?: 'MetricsPageInterviewerFiltersPref' } | { __typename?: 'DirectoryPageFiltersPref' } | { __typename?: 'UpcomingEventsPageFiltersPref' } | { __typename?: 'RecentlyUsedZoomUsersPref' } | { __typename?: 'ProfilePicturePref' } | { __typename?: 'InterviewBufferTimePref' }> }
    )> }
  )> }
);

export type UpdateScheduleTaskDashboardFiltersEmployeePrefMutationVariables = Exact<{
  input: EmployeePrefUpdateInput;
}>;


export type UpdateScheduleTaskDashboardFiltersEmployeePrefMutation = (
  { __typename?: 'Mutation' }
  & { employeePrefUpdate?: Maybe<(
    { __typename?: 'EmployeePrefUpdateResult' }
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
    )> }
  )> }
);

export type ScheduleTaskDashboardColumnsQueryVariables = Exact<{
  prefName: Scalars['String'];
}>;


export type ScheduleTaskDashboardColumnsQuery = (
  { __typename?: 'Query' }
  & { thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
      & { jsonArrayPref: Array<{ __typename: 'InterviewLimit' } | (
        { __typename: 'EmployeeTaskDashboardLayoutPref' }
        & Pick<EmployeeTaskDashboardLayoutPref, 'columnType' | 'isVisible'>
      ) | { __typename: 'EmployeeTaskDashboardFilterPref' } | { __typename: 'CandidatePageFiltersPref' } | { __typename: 'MetricsPageRecruitingTeamFiltersPref' } | { __typename: 'MetricsPageInterviewerFiltersPref' } | { __typename: 'DirectoryPageFiltersPref' } | { __typename: 'UpcomingEventsPageFiltersPref' } | { __typename: 'RecentlyUsedZoomUsersPref' } | { __typename: 'ProfilePicturePref' } | { __typename: 'InterviewBufferTimePref' }> }
    )> }
  )> }
);

export type AtsInterviewDefinitionFragment = (
  { __typename?: 'AtsInterviewDefinition' }
  & Pick<AtsInterviewDefinition, 'name' | 'atsId' | 'atsJobStageId' | 'atsJobId'>
  & { atsJobStage?: Maybe<(
    { __typename?: 'AtsJobStage' }
    & Pick<AtsJobStage, 'atsId' | 'name' | 'index'>
  )>, atsFields?: Maybe<(
    { __typename?: 'GreenhouseInterviewDefinition' }
    & Pick<GreenhouseInterviewDefinition, 'estimatedMinutes' | 'index'>
  ) | (
    { __typename?: 'LeverInterviewDefinition' }
    & Pick<LeverInterviewDefinition, 'groupId' | 'groupName'>
  ) | { __typename?: 'GemInterviewDefinition' } | { __typename?: 'WorkdayInterviewDefinition' }> }
);

export type AtsInterviewDefinitionsQueryVariables = Exact<{
  input: AtsInterviewDefinitionsInput;
}>;


export type AtsInterviewDefinitionsQuery = (
  { __typename?: 'Query' }
  & { atsInterviewDefinitions?: Maybe<(
    { __typename?: 'AtsInterviewDefinitionsOutput' }
    & { atsInterviewDefinitions?: Maybe<Array<(
      { __typename?: 'AtsInterviewDefinition' }
      & AtsInterviewDefinitionFragment
    )>> }
  )> }
);

export type JobStageQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type JobStageQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id' | 'name'>
  )> }
);

export type SelfScheduleOptions_OptionsFragment = (
  { __typename?: 'Options' }
  & Pick<Options, 'inclusionDays' | 'rollingDays' | 'advanceNoticeInHours' | 'candidateCalendarId' | 'interviewerCalendarId' | 'location' | 'zoomHost' | 'zoomHostUserId' | 'candidateEmail' | 'shouldRespectLoadLimit' | 'canScheduleOverRecruitingKeywords' | 'canScheduleOverAvailableKeywords' | 'canScheduleOverFreeTime' | 'meetingHost' | 'videoMeetingHostEmployeeId'>
);

export type SelfScheduleOptions_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & UseInterviewPlanDescription_JobStageFragment
);

export type CandidateCoordinatorAndRecruiterQueryVariables = Exact<{
  candidateId: Scalars['uuid'];
}>;


export type CandidateCoordinatorAndRecruiterQuery = (
  { __typename?: 'Query' }
  & { candidate?: Maybe<(
    { __typename?: 'Candidate' }
    & Pick<Candidate, 'coordinatorId' | 'recruiterId' | 'email'>
  )> }
);

export type SelfScheduleOptionsModalQueryVariables = Exact<{
  applicationId: Scalars['uuid'];
}>;


export type SelfScheduleOptionsModalQuery = (
  { __typename?: 'Query' }
  & { application?: Maybe<(
    { __typename?: 'Application' }
    & Pick<Application, 'id'>
    & CreateTaskDialogPage_ApplicationFragment
  )>, thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & EmployeeFragment
  )> }
);

export type SelfScheduleCalendarsQueryVariables = Exact<{
  input: InitScheduleFlowInput;
}>;


export type SelfScheduleCalendarsQuery = (
  { __typename?: 'Query' }
  & { initScheduleFlow?: Maybe<(
    { __typename?: 'InitScheduleFlowResult' }
    & { candidateCalendar?: Maybe<(
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'remoteId'>
    )>, interviewerCalendar?: Maybe<(
      { __typename?: 'Calendar' }
      & Pick<Calendar, 'remoteId'>
    )> }
  )> }
);

export type SelfScheduleOptionsInterviewPlanQueryVariables = Exact<{
  jobStageId: Scalars['uuid'];
}>;


export type SelfScheduleOptionsInterviewPlanQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & Pick<JobStage, 'id'>
    & CreateTaskDialogPage_InterviewPlanFragment
    & CloneInterviewPlan_InterviewPlanFragment
    & GetSaveInterviewPlanInput_InterviewPlanFragment
    & SelfScheduleOptions_InterviewPlanFragment
  )> }
);

export type SelfScheduleOptionsModal_SelfScheduleFragment = (
  { __typename?: 'SelfScheduleOptions' }
  & Pick<SelfScheduleOptions, 'id' | 'taskId' | 'createdAt' | 'creatorEmployeeId' | 'deletedAt' | 'jobStageId' | 'candidateEmailTemplateId' | 'candidateEventTemplateId' | 'interviewerEventTemplateId' | 'lastUpdatedByEmployeeId' | 'rescheduledAt' | 'selfScheduleEmailTemplateId' | 'selfScheduleEmailSentAt' | 'updatedAt'>
  & { options?: Maybe<(
    { __typename?: 'Options' }
    & SelfScheduleOptions_OptionsFragment
  )> }
);

export type SelfScheduleOptionSettings_JobStageSettingsFragment = (
  { __typename?: 'JobStageSettings' }
  & Pick<JobStageSettings, 'selfScheduleRequestEmailTemplateId' | 'selfScheduleCandidateEmailTemplateId' | 'selfScheduleInterviewerEventTemplateId'>
  & SelfScheduleOptions_JobStageSettingsFragment
);

export type SelfScheduleOptionsUpsertMutationVariables = Exact<{
  input: SelfScheduleOptionsUpsertInput;
}>;


export type SelfScheduleOptionsUpsertMutation = (
  { __typename?: 'Mutation' }
  & { selfScheduleOptionsUpsert?: Maybe<(
    { __typename?: 'SelfScheduleOptions' }
    & SelfScheduleOptionsModal_SelfScheduleFragment
  )> }
);

export type SuggestedTimeRangesCountQueryVariables = Exact<{
  input: SuggestedTimeRangesCountInput;
}>;


export type SuggestedTimeRangesCountQuery = (
  { __typename?: 'Query' }
  & { suggestedTimeRangesCount?: Maybe<(
    { __typename?: 'SuggestedTimeRangesCountOutput' }
    & Pick<SuggestedTimeRangesCountOutput, 'employeeMissingZoomUserId'>
    & { timeRangeCounts: Array<(
      { __typename?: 'TimeRangeCount' }
      & Pick<TimeRangeCount, 'count'>
      & { range: (
        { __typename?: 'DateTimeRangeOutput' }
        & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
      ) }
    )> }
  )> }
);

export type SelfScheduleOptionsCountQueryVariables = Exact<{
  input: SelfScheduleOptionsCountInput;
}>;


export type SelfScheduleOptionsCountQuery = (
  { __typename?: 'Query' }
  & { selfScheduleOptionsCount?: Maybe<(
    { __typename?: 'SelfScheduleOptionsCount' }
    & Pick<SelfScheduleOptionsCount, 'employeeMissingZoomUserId'>
    & { timeRangeCounts: Array<Maybe<(
      { __typename?: 'TimeRangeCount' }
      & Pick<TimeRangeCount, 'count'>
      & { range: (
        { __typename?: 'DateTimeRangeOutput' }
        & Pick<DateTimeRangeOutput, 'startAt' | 'endAt'>
      ) }
    )>> }
  )> }
);

export type SelfScheduleTemplatesQueryVariables = Exact<{
  jobStageId: Scalars['uuid'];
}>;


export type SelfScheduleTemplatesQuery = (
  { __typename?: 'Query' }
  & { jobStage?: Maybe<(
    { __typename?: 'JobStage' }
    & { jobStageSettings?: Maybe<(
      { __typename?: 'JobStageSettings' }
      & Pick<JobStageSettings, 'interviewerEventTemplateId' | 'candidateEventTemplateId' | 'candidateEmailTemplateId'>
    )> }
  )> }
);

export type SelfScheduleOptionsDeleteMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type SelfScheduleOptionsDeleteMutation = (
  { __typename?: 'Mutation' }
  & { selfScheduleOptionsDelete?: Maybe<(
    { __typename?: 'SelfScheduleOptionsDeleteInput' }
    & Pick<SelfScheduleOptionsDeleteInput, 'id'>
  )> }
);

export type SelfScheduleTaskCreateMutationVariables = Exact<{
  input: TaskCreateInput;
}>;


export type SelfScheduleTaskCreateMutation = (
  { __typename?: 'Mutation' }
  & { taskCreate?: Maybe<(
    { __typename?: 'TaskCreateResult' }
    & { task: (
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    ) }
  )> }
);

export type BrandingThemeBuilderComponent_BrandingThemeFragment = (
  { __typename?: 'BrandingTheme' }
  & Pick<BrandingTheme, 'id' | 'name' | 'isOrgDefault' | 'livePortals'>
  & BrandingThemeInputsPanel_BrandingThemeFragment
  & BrandingThemePreviewWrapper_BrandingThemeFragment
);

export type BrandingThemeBuilderCreateMutationVariables = Exact<{
  input: BrandingThemeCreateInput;
}>;


export type BrandingThemeBuilderCreateMutation = (
  { __typename?: 'Mutation' }
  & { brandingThemeCreate: (
    { __typename?: 'BrandingThemeResult' }
    & { theme: (
      { __typename?: 'BrandingTheme' }
      & BrandingThemeBuilderComponent_BrandingThemeFragment
    ) }
  ) }
);

export type BrandingThemeBuilderUpdateMutationVariables = Exact<{
  input: BrandingThemeUpdateInput;
}>;


export type BrandingThemeBuilderUpdateMutation = (
  { __typename?: 'Mutation' }
  & { brandingThemeUpdate: (
    { __typename?: 'BrandingThemeResult' }
    & { theme: (
      { __typename?: 'BrandingTheme' }
      & BrandingThemeBuilderComponent_BrandingThemeFragment
    ) }
  ) }
);

export type BrandingThemePreviewContent_BrandingThemeFragment = (
  { __typename?: 'BrandingTheme' }
  & Pick<BrandingTheme, 'coverImageUrl' | 'profileImageUrl'>
);

export type BrandingThemePreviewWrapper_BrandingThemeFragment = (
  { __typename?: 'BrandingTheme' }
  & Pick<BrandingTheme, 'id' | 'themeMode' | 'font' | 'primaryColor'>
  & BrandingThemePreviewContent_BrandingThemeFragment
);

export type BrandingThemeInputsPanel_BrandingThemeFragment = (
  { __typename?: 'BrandingTheme' }
  & Pick<BrandingTheme, 'id' | 'profileImageUrl' | 'coverImageUrl' | 'faviconImageUrl' | 'font' | 'themeMode' | 'primaryColor'>
);

export type BrandingThemeBuilder_BrandingThemeFragment = (
  { __typename?: 'BrandingTheme' }
  & BrandingThemeBuilderComponent_BrandingThemeFragment
);

export type BrandingThemeDeleteMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type BrandingThemeDeleteMutation = (
  { __typename?: 'Mutation' }
  & { brandingThemeDelete: (
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  ) }
);

export type BrandingThemesItem_BrandingThemeFragment = (
  { __typename?: 'BrandingTheme' }
  & Pick<BrandingTheme, 'id' | 'orgId' | 'name' | 'profileImageUrl' | 'coverImageUrl' | 'isOrgDefault' | 'createdAt' | 'updatedAt' | 'livePortals'>
  & BrandingThemePlaceholderPreview_BrandingThemeFragment
);

export type BrandingThemeItemDescription_BrandingThemeFragment = (
  { __typename?: 'BrandingTheme' }
  & Pick<BrandingTheme, 'id' | 'name' | 'isOrgDefault' | 'livePortals'>
);

export type BrandingThemesQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandingThemesQuery = (
  { __typename?: 'Query' }
  & { brandingThemes?: Maybe<(
    { __typename?: 'BrandingThemesResult' }
    & { items: Array<(
      { __typename?: 'BrandingTheme' }
      & BrandingThemeBuilder_BrandingThemeFragment
      & BrandingThemesItem_BrandingThemeFragment
    )> }
  )> }
);

export type DuplicateBrandingThemeMutationVariables = Exact<{
  input: BrandingThemeCreateInput;
}>;


export type DuplicateBrandingThemeMutation = (
  { __typename?: 'Mutation' }
  & { brandingThemeCreate: (
    { __typename?: 'BrandingThemeResult' }
    & { theme: (
      { __typename?: 'BrandingTheme' }
      & BrandingThemeBuilder_BrandingThemeFragment
      & BrandingThemesItem_BrandingThemeFragment
    ) }
  ) }
);

export type PortalTemplatesQueryVariables = Exact<{
  input: PortalTemplatesInput;
}>;


export type PortalTemplatesQuery = (
  { __typename?: 'Query' }
  & { portalTemplates?: Maybe<(
    { __typename?: 'PortalTemplatesResult' }
    & Pick<PortalTemplatesResult, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'PortalTemplate' }
      & Pick<PortalTemplate, 'id'>
      & PageTemplateRow_TemplateFragment
    )> }
  )> }
);

export type PortalTemplatePreviewQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PortalTemplatePreviewQuery = (
  { __typename?: 'Query' }
  & { portalTemplate?: Maybe<(
    { __typename?: 'PortalTemplate' }
    & Pick<PortalTemplate, 'id'>
    & TemplatePreview_PortalTemplateFragment
  )> }
);

export type PageTemplateRow_TemplateFragment = (
  { __typename?: 'PortalTemplate' }
  & Pick<PortalTemplate, 'id' | 'name' | 'orgId' | 'livePortals' | 'updatedAt'>
);

export type PortalTemplateDeleteMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PortalTemplateDeleteMutation = (
  { __typename?: 'Mutation' }
  & { portalTemplateDelete: (
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  ) }
);

type BlockPreview_Block_PortalTemplateBlockContentCard_Fragment = (
  { __typename?: 'PortalTemplateBlockContentCard' }
  & Pick<PortalTemplateBlockContentCard, 'id' | 'type' | 'index'>
  & ContentCardBlock_BlockFragment
);

type BlockPreview_Block_PortalTemplateBlockDivider_Fragment = (
  { __typename?: 'PortalTemplateBlockDivider' }
  & Pick<PortalTemplateBlockDivider, 'id' | 'type' | 'index'>
  & DividerBlock_BlockFragment
);

type BlockPreview_Block_PortalTemplateBlockImage_Fragment = (
  { __typename?: 'PortalTemplateBlockImage' }
  & Pick<PortalTemplateBlockImage, 'id' | 'type' | 'index'>
  & ImageBlock_BlockFragment
);

type BlockPreview_Block_PortalTemplateBlockLinkedCard_Fragment = (
  { __typename?: 'PortalTemplateBlockLinkedCard' }
  & Pick<PortalTemplateBlockLinkedCard, 'id' | 'type' | 'index'>
  & LinkedCardBlock_BlockFragment
);

type BlockPreview_Block_PortalTemplateBlockText_Fragment = (
  { __typename?: 'PortalTemplateBlockText' }
  & Pick<PortalTemplateBlockText, 'id' | 'type' | 'index'>
  & TextBlock_BlockFragment
);

type BlockPreview_Block_PortalTemplateBlockVideo_Fragment = (
  { __typename?: 'PortalTemplateBlockVideo' }
  & Pick<PortalTemplateBlockVideo, 'id' | 'type' | 'index'>
  & VideoBlock_BlockFragment
);

export type BlockPreview_BlockFragment = BlockPreview_Block_PortalTemplateBlockContentCard_Fragment | BlockPreview_Block_PortalTemplateBlockDivider_Fragment | BlockPreview_Block_PortalTemplateBlockImage_Fragment | BlockPreview_Block_PortalTemplateBlockLinkedCard_Fragment | BlockPreview_Block_PortalTemplateBlockText_Fragment | BlockPreview_Block_PortalTemplateBlockVideo_Fragment;

export type ContentCardBlock_BlockFragment = (
  { __typename?: 'PortalTemplateBlockContentCard' }
  & Pick<PortalTemplateBlockContentCard, 'id' | 'type' | 'index' | 'header'>
  & { cards: Array<(
    { __typename?: 'ContentCard' }
    & Pick<ContentCard, 'header' | 'body'>
  )> }
);

export type DividerBlock_BlockFragment = (
  { __typename?: 'PortalTemplateBlockDivider' }
  & Pick<PortalTemplateBlockDivider, 'id' | 'index' | 'type'>
);

export type ImageBlock_BlockFragment = (
  { __typename?: 'PortalTemplateBlockImage' }
  & Pick<PortalTemplateBlockImage, 'id' | 'type' | 'index' | 'url' | 'header' | 'caption' | 'fillPage'>
);

export type LinkedCardBlock_BlockFragment = (
  { __typename?: 'PortalTemplateBlockLinkedCard' }
  & Pick<PortalTemplateBlockLinkedCard, 'id' | 'type' | 'index' | 'header'>
  & { cards: Array<(
    { __typename?: 'LinkedCard' }
    & Pick<LinkedCard, 'url' | 'header' | 'body' | 'previewImageUrl'>
  )> }
);

export type TextBlock_BlockFragment = (
  { __typename?: 'PortalTemplateBlockText' }
  & Pick<PortalTemplateBlockText, 'id' | 'index' | 'type' | 'content'>
);

export type VideoBlock_BlockFragment = (
  { __typename?: 'PortalTemplateBlockVideo' }
  & Pick<PortalTemplateBlockVideo, 'id' | 'type' | 'url' | 'index' | 'caption' | 'header'>
);

export type TemplatePreview_PortalTemplateFragment = (
  { __typename?: 'PortalTemplate' }
  & Pick<PortalTemplate, 'id' | 'name' | 'orgId' | 'livePortals' | 'updatedAt'>
  & { blocks: Array<(
    { __typename?: 'PortalTemplateBlockContentCard' }
    & Pick<PortalTemplateBlockContentCard, 'id' | 'index' | 'type'>
    & BlockPreview_Block_PortalTemplateBlockContentCard_Fragment
  ) | (
    { __typename?: 'PortalTemplateBlockDivider' }
    & Pick<PortalTemplateBlockDivider, 'id' | 'index' | 'type'>
    & BlockPreview_Block_PortalTemplateBlockDivider_Fragment
  ) | (
    { __typename?: 'PortalTemplateBlockImage' }
    & Pick<PortalTemplateBlockImage, 'id' | 'index' | 'type'>
    & BlockPreview_Block_PortalTemplateBlockImage_Fragment
  ) | (
    { __typename?: 'PortalTemplateBlockLinkedCard' }
    & Pick<PortalTemplateBlockLinkedCard, 'id' | 'index' | 'type'>
    & BlockPreview_Block_PortalTemplateBlockLinkedCard_Fragment
  ) | (
    { __typename?: 'PortalTemplateBlockText' }
    & Pick<PortalTemplateBlockText, 'id' | 'index' | 'type'>
    & BlockPreview_Block_PortalTemplateBlockText_Fragment
  ) | (
    { __typename?: 'PortalTemplateBlockVideo' }
    & Pick<PortalTemplateBlockVideo, 'id' | 'index' | 'type'>
    & BlockPreview_Block_PortalTemplateBlockVideo_Fragment
  )> }
);

export type LinkUnfurlQueryVariables = Exact<{
  input: LinkUnfurlInput;
}>;


export type LinkUnfurlQuery = (
  { __typename?: 'Query' }
  & { linkUnfurl?: Maybe<(
    { __typename?: 'LinkUnfurlResult' }
    & Pick<LinkUnfurlResult, 'url' | 'title' | 'description' | 'thumbnail'>
  )> }
);

export type PortalTemplateQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type PortalTemplateQuery = (
  { __typename?: 'Query' }
  & { portalTemplate?: Maybe<(
    { __typename?: 'PortalTemplate' }
    & TemplatePreview_PortalTemplateFragment
  )> }
);

export type PortalTemplateUpdateMutationVariables = Exact<{
  input: PortalTemplateUpdateInput;
}>;


export type PortalTemplateUpdateMutation = (
  { __typename?: 'Mutation' }
  & { portalTemplateUpdate: (
    { __typename?: 'PortalTemplateResult' }
    & { template: (
      { __typename?: 'PortalTemplate' }
      & Pick<PortalTemplate, 'id'>
      & TemplatePreview_PortalTemplateFragment
    ) }
  ) }
);

export type PortalTemplateCreateMutationVariables = Exact<{
  input: PortalTemplateCreateInput;
}>;


export type PortalTemplateCreateMutation = (
  { __typename?: 'Mutation' }
  & { portalTemplateCreate: (
    { __typename?: 'PortalTemplateResult' }
    & { template: (
      { __typename?: 'PortalTemplate' }
      & Pick<PortalTemplate, 'id'>
      & TemplatePreview_PortalTemplateFragment
    ) }
  ) }
);

export type MicrosoftIntegrationGetOAuthUrlQueryVariables = Exact<{
  input: IntegrationGetOauthUrlInput;
}>;


export type MicrosoftIntegrationGetOAuthUrlQuery = (
  { __typename?: 'Query' }
  & { integrationGetOauthUrl?: Maybe<(
    { __typename?: 'IntegrationGetOauthUrlResult' }
    & Pick<IntegrationGetOauthUrlResult, 'url'>
  )> }
);

export type MicrosoftGccHighIntegrationGetOAuthUrlQueryVariables = Exact<{
  input: IntegrationGetOauthUrlInput;
}>;


export type MicrosoftGccHighIntegrationGetOAuthUrlQuery = (
  { __typename?: 'Query' }
  & { integrationGetOauthUrl?: Maybe<(
    { __typename?: 'IntegrationGetOauthUrlResult' }
    & Pick<IntegrationGetOauthUrlResult, 'url'>
  )> }
);

export type DisconnectAtsDialogQueryVariables = Exact<{ [key: string]: never; }>;


export type DisconnectAtsDialogQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'upcomingInterviewsCount' | 'activeCandidateAvailabilityLinksCount' | 'activeSelfScheduleLinksCount'>
  )> }
);

export type InterviewerPortalSettingsOldFragment = (
  { __typename?: 'InterviewerPortalSettings' }
  & Pick<InterviewerPortalSettings, 'id' | 'firstName' | 'lastName' | 'title' | 'emailAddress' | 'linkedinUrl' | 'timezone' | 'interviewLoadCapacity' | 'interviewAvailability' | 'temporaryAvailability' | 'pauseFromInterviews' | 'interviewerKeywords'>
);

export type InterviewerPortalTabOldQueryVariables = Exact<{ [key: string]: never; }>;


export type InterviewerPortalTabOldQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { interviewerPortalSettings: (
      { __typename?: 'InterviewerPortalSettings' }
      & InterviewerPortalSettingsOldFragment
    ) }
  )> }
);

export type UpdateInterviewPortalSettingOldMutationVariables = Exact<{
  input: InterviewerPortalSettingsUpsertInput;
}>;


export type UpdateInterviewPortalSettingOldMutation = (
  { __typename?: 'Mutation' }
  & { interviewerPortalSettingsUpsert: (
    { __typename?: 'InterviewerPortalSettings' }
    & InterviewerPortalSettingsOldFragment
  ) }
);

export type InterviewerPortalSettingsFragment = (
  { __typename?: 'InterviewerPortalSettings' }
  & Pick<InterviewerPortalSettings, 'id' | 'firstName' | 'lastName' | 'title' | 'emailAddress' | 'linkedinUrl' | 'timezone' | 'interviewLoadCapacity' | 'interviewAvailability' | 'temporaryAvailability' | 'pauseFromInterviews' | 'interviewerKeywords' | 'profilePicture' | 'pronouns' | 'bio'>
);

export type InterviewerPortalTabQueryVariables = Exact<{ [key: string]: never; }>;


export type InterviewerPortalTabQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { interviewerPortalSettings: (
      { __typename?: 'InterviewerPortalSettings' }
      & InterviewerPortalSettingsFragment
    ) }
  )> }
);

export type UpdateInterviewPortalSettingMutationVariables = Exact<{
  input: InterviewerPortalSettingsUpsertInput;
}>;


export type UpdateInterviewPortalSettingMutation = (
  { __typename?: 'Mutation' }
  & { interviewerPortalSettingsUpsert: (
    { __typename?: 'InterviewerPortalSettings' }
    & InterviewerPortalSettingsFragment
  ) }
);

export type EmployeeIdFragmentFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl'>
);

export type ValueFragmentFragment = (
  { __typename?: 'EmployeeAttributeTagValue' }
  & Pick<EmployeeAttributeTagValue, 'id' | 'value' | 'aggregateJobStageInterviewSeatCount'>
  & { employees?: Maybe<Array<(
    { __typename?: 'Employee' }
    & EmployeeIdFragmentFragment
  )>> }
);

export type AttributeFragmentValuesFragment = (
  { __typename?: 'EmployeeAttributeName' }
  & Pick<EmployeeAttributeName, 'id' | 'type' | 'name' | 'aggregateJobStageInterviewSeatCount'>
);

export type AttributeFragmentFragment = (
  { __typename?: 'EmployeeAttributeName' }
  & { values?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & ValueFragmentFragment
  )>> }
  & AttributeFragmentValuesFragment
);

export type OrganizationAttributesQueryVariables = Exact<{
  input: EmployeeAttributeNamesInput;
}>;


export type OrganizationAttributesQuery = (
  { __typename?: 'Query' }
  & { employeeAttributeNames?: Maybe<(
    { __typename?: 'EmployeeAttributeNamesResult' }
    & { items?: Maybe<Array<(
      { __typename?: 'EmployeeAttributeName' }
      & AttributeFragmentFragment
    )>> }
  )> }
);

export type EmployeeAttributeNameCreateMutationVariables = Exact<{
  input: EmployeeAttributeNameCreateInput;
}>;


export type EmployeeAttributeNameCreateMutation = (
  { __typename?: 'Mutation' }
  & { employeeAttributeNameCreate?: Maybe<(
    { __typename?: 'EmployeeAttributeName' }
    & AttributeFragmentFragment
  )> }
);

export type EmployeeAttributeNameUpdateMutationVariables = Exact<{
  input: EmployeeAttributeNameUpdateInput;
}>;


export type EmployeeAttributeNameUpdateMutation = (
  { __typename?: 'Mutation' }
  & { employeeAttributeNameUpdate?: Maybe<(
    { __typename?: 'EmployeeAttributeName' }
    & AttributeFragmentFragment
  )> }
);

export type EmployeeAttributeNameDeleteMutationVariables = Exact<{
  input: EmployeeAttributeNameDeleteInput;
}>;


export type EmployeeAttributeNameDeleteMutation = (
  { __typename?: 'Mutation' }
  & { employeeAttributeNameDelete?: Maybe<(
    { __typename?: 'Id' }
    & Pick<Id, 'id'>
  )> }
);

export type EmployeeAttributeValueCreateMutationVariables = Exact<{
  input: EmployeeAttributeValueCreateInput;
}>;


export type EmployeeAttributeValueCreateMutation = (
  { __typename?: 'Mutation' }
  & { employeeAttributeValueCreate?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & ValueFragmentFragment
  )>> }
);

export type EmployeeAttributeValueUpdateMutationVariables = Exact<{
  input: EmployeeAttributeValueUpdateInput;
}>;


export type EmployeeAttributeValueUpdateMutation = (
  { __typename?: 'Mutation' }
  & { employeeAttributeValueUpdate?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & { name?: Maybe<(
      { __typename?: 'EmployeeAttributeName' }
      & AttributeFragmentValuesFragment
    )> }
    & ValueFragmentFragment
  )>> }
);

export type AttributeValueEmployeesUpsertMutationVariables = Exact<{
  input: AttributeValueEmployeesInput;
}>;


export type AttributeValueEmployeesUpsertMutation = (
  { __typename?: 'Mutation' }
  & { attributeValueEmployeesUpsert?: Maybe<Array<(
    { __typename?: 'EmployeeUpdateResult' }
    & { employee?: Maybe<(
      { __typename?: 'Employee' }
      & EmployeeIdFragmentFragment
    )> }
  )>> }
);

export type EmployeeAttributeValueDeleteMutationVariables = Exact<{
  input: EmployeeAttributeValueDeleteInput;
}>;


export type EmployeeAttributeValueDeleteMutation = (
  { __typename?: 'Mutation' }
  & { employeeAttributeValueDelete?: Maybe<(
    { __typename?: 'Id' }
    & Pick<Id, 'id'>
  )> }
);

export type MemberRoleSelectUpdateRoleMutationVariables = Exact<{
  userId: Scalars['uuid'];
  role: UserRole;
}>;


export type MemberRoleSelectUpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & { userUpdate?: Maybe<(
    { __typename?: 'UserUpdateResult' }
    & { userInfo?: Maybe<(
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'userId' | 'role'>
    )> }
  )> }
);

export type CompanyHoliday_CompanyHolidayFragment = (
  { __typename?: 'CompanyHoliday' }
  & Pick<CompanyHoliday, 'id' | 'name' | 'startAt' | 'endAt'>
  & { countries?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
  )>> }
  & CompanyHolidayModal_CompanyHolidayFragment
  & DeleteCompanyHolidayModal_CompanyHolidayFragment
);

export type CompanyHolidayModal_CompanyHolidayFragment = (
  { __typename?: 'CompanyHoliday' }
  & Pick<CompanyHoliday, 'id' | 'name' | 'startAt' | 'endAt'>
  & { countries?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
  )>> }
);

export type CompanyHolidayCreateMutationVariables = Exact<{
  input: CompanyHolidayCreateInput;
}>;


export type CompanyHolidayCreateMutation = (
  { __typename?: 'Mutation' }
  & { companyHolidayCreate?: Maybe<(
    { __typename?: 'CompanyHolidayUpsertResult' }
    & { companyHoliday: (
      { __typename?: 'CompanyHoliday' }
      & CompanyHolidayModal_CompanyHolidayFragment
    ) }
  )> }
);

export type CompanyHolidayUpdateMutationVariables = Exact<{
  input: CompanyHolidayUpdateInput;
}>;


export type CompanyHolidayUpdateMutation = (
  { __typename?: 'Mutation' }
  & { companyHolidayUpdate?: Maybe<(
    { __typename?: 'CompanyHolidayUpsertResult' }
    & { companyHoliday: (
      { __typename?: 'CompanyHoliday' }
      & CompanyHolidayModal_CompanyHolidayFragment
    ) }
  )> }
);

export type CompanyHolidaysQueryVariables = Exact<{
  input: CompanyHolidaysInput;
}>;


export type CompanyHolidaysQuery = (
  { __typename?: 'Query' }
  & { companyHolidays?: Maybe<(
    { __typename?: 'CompanyHolidayResult' }
    & { items: Array<(
      { __typename?: 'CompanyHoliday' }
      & CompanyHoliday_CompanyHolidayFragment
    )> }
  )> }
);

export type DeleteCompanyHolidayModal_CompanyHolidayFragment = (
  { __typename?: 'CompanyHoliday' }
  & Pick<CompanyHoliday, 'id' | 'name' | 'startAt' | 'endAt'>
  & { countries?: Maybe<Array<(
    { __typename?: 'EmployeeAttributeTagValue' }
    & Pick<EmployeeAttributeTagValue, 'id' | 'value'>
  )>> }
);

export type CompanyHolidayDeleteMutationVariables = Exact<{
  input: CompanyHolidayDeleteInput;
}>;


export type CompanyHolidayDeleteMutation = (
  { __typename?: 'Mutation' }
  & { companyHolidayDelete?: Maybe<(
    { __typename?: 'CompanyHolidayDeleteResult' }
    & Pick<CompanyHolidayDeleteResult, 'result'>
  )> }
);

export type InterviewMeetingLocationSettingOrgPrefQueryVariables = Exact<{
  prefName: Scalars['String'];
}>;


export type InterviewMeetingLocationSettingOrgPrefQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { orgPref?: Maybe<(
      { __typename?: 'OrgPref' }
      & Pick<OrgPref, 'id' | 'orgId' | 'prefName'>
      & { jsonPref?: Maybe<{ __typename?: 'InterviewLimit' } | { __typename?: 'TimeBlockWeek' } | { __typename?: 'DateBlock' } | { __typename?: 'DefaultCustomInterviewScheduleTokenPref' } | { __typename?: 'NotificationPreference' } | { __typename?: 'AddZoomHostAsInternalInviteePref' } | { __typename?: 'CandidateFacingInterviewerInfo' } | { __typename?: 'CandidateFacingHiringTeamContactInfo' } | { __typename?: 'PaidFeaturePreference' } | { __typename?: 'MinimumInterviewerLoadLimitOrgPref' } | (
        { __typename?: 'InterviewMeetingLocationSettingPref' }
        & { hostEmployee?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'email'>
        )> }
        & InterviewMeetingLocationModal_InterviewMeetingLocationSettingPrefFragment
      ) | { __typename?: 'InterviewBufferTimePref' } | { __typename?: 'CandidateCommunicationsSettingPref' }> }
    )> }
  )> }
);

export type UpdateInterviewMeetingLocationSettingOrgPrefMutationVariables = Exact<{
  prefName: Scalars['String'];
  json?: Maybe<Scalars['jsonb']>;
}>;


export type UpdateInterviewMeetingLocationSettingOrgPrefMutation = (
  { __typename?: 'Mutation' }
  & { orgPrefUpdate?: Maybe<(
    { __typename?: 'OrgPrefUpdateResult' }
    & { orgPref?: Maybe<(
      { __typename?: 'OrgPref' }
      & Pick<OrgPref, 'id' | 'orgId' | 'prefName'>
      & { jsonPref?: Maybe<{ __typename?: 'InterviewLimit' } | { __typename?: 'TimeBlockWeek' } | { __typename?: 'DateBlock' } | { __typename?: 'DefaultCustomInterviewScheduleTokenPref' } | { __typename?: 'NotificationPreference' } | { __typename?: 'AddZoomHostAsInternalInviteePref' } | { __typename?: 'CandidateFacingInterviewerInfo' } | { __typename?: 'CandidateFacingHiringTeamContactInfo' } | { __typename?: 'PaidFeaturePreference' } | { __typename?: 'MinimumInterviewerLoadLimitOrgPref' } | (
        { __typename?: 'InterviewMeetingLocationSettingPref' }
        & { hostEmployee?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'fullName' | 'slackImageUrl' | 'email'>
        )> }
        & InterviewMeetingLocationModal_InterviewMeetingLocationSettingPrefFragment
      ) | { __typename?: 'InterviewBufferTimePref' } | { __typename?: 'CandidateCommunicationsSettingPref' }> }
    )> }
  )> }
);

export type RescheduleReasonCreateMutationVariables = Exact<{
  input: RescheduleReasonCreateInput;
}>;


export type RescheduleReasonCreateMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleReasonCreate?: Maybe<(
    { __typename?: 'RescheduleReasonUpsertResult' }
    & { rescheduleReason?: Maybe<(
      { __typename?: 'RescheduleReason' }
      & Pick<RescheduleReason, 'id' | 'reason'>
    )> }
  )> }
);

export type EditRescheduleReasonModal_RescheduleReasonFragment = (
  { __typename?: 'RescheduleReason' }
  & Pick<RescheduleReason, 'id' | 'reason'>
);

export type RescheduleReasonUpdateMutationVariables = Exact<{
  input: RescheduleReasonUpdateInput;
}>;


export type RescheduleReasonUpdateMutation = (
  { __typename?: 'Mutation' }
  & { rescheduleReasonUpdate?: Maybe<(
    { __typename?: 'RescheduleReasonUpsertResult' }
    & { rescheduleReason?: Maybe<(
      { __typename?: 'RescheduleReason' }
      & EditRescheduleReasonModal_RescheduleReasonFragment
    )> }
  )> }
);

export type RescheduleReasonItem_RescheduleReasonFragment = (
  { __typename?: 'RescheduleReason' }
  & Pick<RescheduleReason, 'id' | 'reason' | 'type' | 'archivedAt'>
);

export type RescheduleReasonsList_RescheduleReasonFragment = (
  { __typename?: 'RescheduleReason' }
  & Pick<RescheduleReason, 'id' | 'reason'>
  & RescheduleReasonItem_RescheduleReasonFragment
  & EditRescheduleReasonModal_RescheduleReasonFragment
);

export type RescheduleReasonsListQueryVariables = Exact<{
  input: RescheduleReasonsInput;
}>;


export type RescheduleReasonsListQuery = (
  { __typename?: 'Query' }
  & { rescheduleReasons?: Maybe<(
    { __typename?: 'RescheduleReasonsResult' }
    & Pick<RescheduleReasonsResult, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'RescheduleReason' }
      & RescheduleReasonsList_RescheduleReasonFragment
    )> }
  )> }
);

export type EditOrgNameDialog_OrgFragment = (
  { __typename?: 'Org' }
  & Pick<Org, 'id' | 'name'>
);

export type UpdateOrgNameMutationVariables = Exact<{
  input: OrgUpdateInput;
}>;


export type UpdateOrgNameMutation = (
  { __typename?: 'Mutation' }
  & { orgUpdate?: Maybe<(
    { __typename?: 'Org' }
    & EditOrgNameDialog_OrgFragment
  )> }
);

export type PortalCreateOrgSlugMutationVariables = Exact<{
  input: PortalSlugCreateInput;
}>;


export type PortalCreateOrgSlugMutation = (
  { __typename?: 'Mutation' }
  & { portalSlugCreate?: Maybe<(
    { __typename?: 'PortalOrgSlug' }
    & Pick<PortalOrgSlug, 'orgId'>
  )> }
);

export type OrgNameAndSlugQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgNameAndSlugQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id' | 'name'>
    & { slugs: Array<(
      { __typename?: 'PortalOrgSlug' }
      & Pick<PortalOrgSlug, 'slug' | 'isPrimary'>
    )> }
    & EditOrgNameDialog_OrgFragment
  )> }
);

export type TaskTagCreateMutationVariables = Exact<{
  input: TaskTagCreateInput;
}>;


export type TaskTagCreateMutation = (
  { __typename?: 'Mutation' }
  & { taskTagCreate?: Maybe<(
    { __typename?: 'TaskTagCreateOutput' }
    & { taskTag: (
      { __typename?: 'TaskTag' }
      & Pick<TaskTag, 'id' | 'name'>
    ) }
  )> }
);

export type DeleteTagModal_TaskTagFragment = (
  { __typename?: 'TaskTag' }
  & Pick<TaskTag, 'id' | 'taskCount'>
);

export type TaskTagDeleteMutationVariables = Exact<{
  input: TaskTagDeleteInput;
}>;


export type TaskTagDeleteMutation = (
  { __typename?: 'Mutation' }
  & { taskTagDelete?: Maybe<(
    { __typename?: 'TaskTagDeleteOutput' }
    & Pick<TaskTagDeleteOutput, 'ok'>
  )> }
);

export type EditTagModal_TaskTagFragment = (
  { __typename?: 'TaskTag' }
  & Pick<TaskTag, 'id' | 'name'>
);

export type TaskTagUpdateMutationVariables = Exact<{
  input: TaskTagUpdateInput;
}>;


export type TaskTagUpdateMutation = (
  { __typename?: 'Mutation' }
  & { taskTagUpdate?: Maybe<(
    { __typename?: 'TaskTagUpdateOutput' }
    & { taskTag: (
      { __typename?: 'TaskTag' }
      & EditTagModal_TaskTagFragment
    ) }
  )> }
);

export type TaskTagItem_TaskTagFragment = (
  { __typename?: 'TaskTag' }
  & Pick<TaskTag, 'id' | 'name' | 'taskCount'>
);

export type TaskTagList_TaskTagFragment = (
  { __typename?: 'TaskTag' }
  & Pick<TaskTag, 'id' | 'name'>
  & TaskTagItem_TaskTagFragment
  & EditTagModal_TaskTagFragment
);

export type TaskTagListQueryVariables = Exact<{
  input: TaskTagsInput;
}>;


export type TaskTagListQuery = (
  { __typename?: 'Query' }
  & { taskTags?: Maybe<(
    { __typename?: 'TaskTagsResult' }
    & Pick<TaskTagsResult, 'nextCursor'>
    & { items: Array<(
      { __typename?: 'TaskTag' }
      & TaskTagList_TaskTagFragment
    )> }
  )> }
);

export type AddMemberModal_SelectedEmployeesFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id'>
  & EmployeeFragment
);

export type DeleteQueueModal_TaskQueueFragment = (
  { __typename?: 'TaskQueue' }
  & Pick<TaskQueue, 'id' | 'name'>
);

export type DeleteTaskQueueMutationVariables = Exact<{
  input: TaskQueueDeleteInput;
}>;


export type DeleteTaskQueueMutation = (
  { __typename?: 'Mutation' }
  & { taskQueueDelete?: Maybe<(
    { __typename?: 'TaskQueueDeleteResult' }
    & Pick<TaskQueueDeleteResult, 'result'>
  )> }
);

export type QueueDialogContent_QueueMembersFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id'>
  & AddMemberModal_SelectedEmployeesFragment
);

export type CreateTaskQueueMutationVariables = Exact<{
  input: TaskQueueCreateInput;
}>;


export type CreateTaskQueueMutation = (
  { __typename?: 'Mutation' }
  & { taskQueueCreate?: Maybe<(
    { __typename?: 'TaskQueueCreateOutput' }
    & { taskQueue: (
      { __typename?: 'TaskQueue' }
      & Pick<TaskQueue, 'id'>
      & QueueRow_TaskQueueFragment
    ) }
  )> }
);

export type EditTaskQueueMutationVariables = Exact<{
  input: TaskQueueUpdateInput;
}>;


export type EditTaskQueueMutation = (
  { __typename?: 'Mutation' }
  & { taskQueueUpdate?: Maybe<(
    { __typename?: 'TaskQueueUpdateOutput' }
    & { taskQueue: (
      { __typename?: 'TaskQueue' }
      & Pick<TaskQueue, 'id'>
      & QueueRow_TaskQueueFragment
    ) }
  )> }
);

export type SubscribersListQueueModalQueryVariables = Exact<{
  input: SubscribersInput;
}>;


export type SubscribersListQueueModalQuery = (
  { __typename?: 'Query' }
  & { subscribers?: Maybe<(
    { __typename?: 'SubscribersResult' }
    & { items: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id'>
      & QueueDialogContent_QueueMembersFragment
    )> }
  )> }
);

export type QueueModal_TaskQueueFragment = (
  { __typename?: 'TaskQueue' }
  & Pick<TaskQueue, 'id' | 'name' | 'memberCount' | 'taskCount'>
);

export type QueueRow_TaskQueueFragment = (
  { __typename?: 'TaskQueue' }
  & Pick<TaskQueue, 'id' | 'memberCount' | 'taskCount' | 'name'>
  & QueueModal_TaskQueueFragment
  & DeleteQueueModal_TaskQueueFragment
);

export type QueueList_TaskQueueFragment = (
  { __typename?: 'TaskQueue' }
  & Pick<TaskQueue, 'id'>
  & QueueRow_TaskQueueFragment
);

export type TaskQueuesQueryVariables = Exact<{
  input: TaskQueueSearchInput;
}>;


export type TaskQueuesQuery = (
  { __typename?: 'Query' }
  & { taskQueueSearch?: Maybe<(
    { __typename?: 'TaskQueueSearchResult' }
    & { items: Array<(
      { __typename?: 'TaskQueue' }
      & QueueList_TaskQueueFragment
    )> }
  )> }
);

export type AttachmentsFragment = (
  { __typename?: 'Template' }
  & { attachments?: Maybe<Array<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'name' | 'path' | 'size' | 'timestamp'>
  )>> }
);

export type TemplateFragFragment = (
  { __typename?: 'Template' }
  & Pick<Template, 'id' | 'type' | 'name' | 'updatedAt' | 'subject' | 'body' | 'isOrganizationDefault' | 'liveSettingsUsages'>
);

export type DefaultTemplateForTypesQueryVariables = Exact<{
  input: TemplatesInput;
}>;


export type DefaultTemplateForTypesQuery = (
  { __typename?: 'Query' }
  & { templates?: Maybe<(
    { __typename?: 'TemplatesOutput' }
    & { items: Array<(
      { __typename?: 'Template' }
      & TemplateFragFragment
    )> }
  )> }
);

export type OrganizationTemplatesQueryVariables = Exact<{
  input: TemplatesInput;
}>;


export type OrganizationTemplatesQuery = (
  { __typename?: 'Query' }
  & { templates?: Maybe<(
    { __typename?: 'TemplatesOutput' }
    & { items: Array<(
      { __typename?: 'Template' }
      & TemplateFragFragment
    )> }
  )> }
);

export type OrganizationTemplateCreateMutationVariables = Exact<{
  input: TemplateCreateInput;
}>;


export type OrganizationTemplateCreateMutation = (
  { __typename?: 'Mutation' }
  & { templateCreate?: Maybe<(
    { __typename?: 'TemplateResult' }
    & { template?: Maybe<(
      { __typename?: 'Template' }
      & TemplateFragFragment
      & AttachmentsFragment
    )> }
  )> }
);

export type OrganizationTemplateUpdateMutationVariables = Exact<{
  input: TemplateUpdateInput;
}>;


export type OrganizationTemplateUpdateMutation = (
  { __typename?: 'Mutation' }
  & { templateUpdate?: Maybe<(
    { __typename?: 'TemplateResult' }
    & { template?: Maybe<(
      { __typename?: 'Template' }
      & TemplateFragFragment
      & AttachmentsFragment
    )> }
  )> }
);

export type OrganizationTemplateDeleteMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type OrganizationTemplateDeleteMutation = (
  { __typename?: 'Mutation' }
  & { templateDelete?: Maybe<(
    { __typename?: 'TemplateResult' }
    & { template?: Maybe<(
      { __typename?: 'Template' }
      & Pick<Template, 'id'>
    )> }
  )> }
);

export type TemplateAttachementsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type TemplateAttachementsQuery = (
  { __typename?: 'Query' }
  & { template?: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id'>
    & AttachmentsFragment
  )> }
);

export type CustomInterviewScheduleTokenPreviewQueryVariables = Exact<{
  input: CustomInterviewScheduleTokenPreviewInput;
}>;


export type CustomInterviewScheduleTokenPreviewQuery = (
  { __typename?: 'Query' }
  & { customInterviewScheduleTokenPreview?: Maybe<(
    { __typename?: 'CustomInterviewScheduleTokenPreviewResult' }
    & Pick<CustomInterviewScheduleTokenPreviewResult, 'html'>
  )> }
);

export type DefaultCustomInterviewScheduleTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type DefaultCustomInterviewScheduleTokenQuery = (
  { __typename?: 'Query' }
  & { defaultCustomInterviewScheduleToken?: Maybe<(
    { __typename?: 'DefaultCustomInterviewScheduleTokenPref' }
    & Pick<DefaultCustomInterviewScheduleTokenPref, 'dateHeaderFormat' | 'breakDividerFormat' | 'interviewDetailWithTime' | 'codingLinkFormat' | 'candidatePhoneNumberFormat'>
    & { uniqueMeetingLinkFormat?: Maybe<(
      { __typename?: 'DefaultMeetingLinkFormat' }
      & Pick<DefaultMeetingLinkFormat, 'zoomLinkFormat' | 'workspaceVideoLinkFormat' | 'customLinkFormat'>
    )>, singleMeetingLinkFormat?: Maybe<(
      { __typename?: 'DefaultMeetingLinkFormat' }
      & Pick<DefaultMeetingLinkFormat, 'zoomLinkFormat' | 'workspaceVideoLinkFormat' | 'customLinkFormat'>
    )> }
  )> }
);

export type AgendaTab_UpcomingEventsFragment = (
  { __typename?: 'UpcomingEvent' }
  & SchedulesList_UpcomingEventsFragment
);

export type FullCalendarViews_UpcomingEventsFragment = (
  { __typename?: 'UpcomingEvent' }
  & Pick<UpcomingEvent, 'startAt' | 'endAt' | 'dayIndex' | 'dayTotal'>
  & { interviews?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterview' }
    & Pick<ApplicationStageInterview, 'id'>
  )>>, candidateEvent?: Maybe<(
    { __typename?: 'ApplicationStageCandidateEvent' }
    & Pick<ApplicationStageCandidateEvent, 'id'>
  )>, schedule: (
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'taskId'>
    & { jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
    )>, application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'fullName'>
      )> }
    )> }
  ) }
);

export type ScheduleListItem_UpcomingEventFragment = (
  { __typename?: 'UpcomingEvent' }
  & Pick<UpcomingEvent, 'startAt' | 'endAt' | 'dayIndex' | 'dayTotal'>
  & { schedule: (
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'isHoldForRsvp' | 'isReadyForCandidateComms'>
    & { jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
    )>, application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name'>
      )>, candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id'>
        & Candidate_CandidateFragment
      )> }
    )> }
  ), candidateEvent?: Maybe<(
    { __typename?: 'ApplicationStageCandidateEvent' }
    & Pick<ApplicationStageCandidateEvent, 'id' | 'responseStatus'>
  )>, interviews?: Maybe<Array<(
    { __typename?: 'ApplicationStageInterview' }
    & Pick<ApplicationStageInterview, 'id'>
    & { applicationStageInterviewInterviewers?: Maybe<Array<(
      { __typename?: 'ApplicationStageInterviewInterviewer' }
      & Pick<ApplicationStageInterviewInterviewer, 'interviewerId' | 'responseStatus'>
      & AvatarFacePile_InterviewersFragment
    )>> }
  )>> }
);

export type SchedulesList_UpcomingEventsFragment = (
  { __typename?: 'UpcomingEvent' }
  & { schedule: (
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'taskId'>
  ) }
  & ScheduleListItem_UpcomingEventFragment
);

export type UpcomingEventsQueryVariables = Exact<{
  input: UpcomingEventsInput;
}>;


export type UpcomingEventsQuery = (
  { __typename?: 'Query' }
  & { upcomingEvents?: Maybe<(
    { __typename?: 'UpcomingEventsResult' }
    & { items: Array<(
      { __typename?: 'UpcomingEvent' }
      & Views_UpcomingEventsFragment
    )> }
  )> }
);

export type Views_UpcomingEventsFragment = (
  { __typename?: 'UpcomingEvent' }
  & Pick<UpcomingEvent, 'startAt' | 'endAt'>
  & AgendaTab_UpcomingEventsFragment
);

export type UpcomingEventsPageFiltersEmployeePrefFragment = (
  { __typename?: 'UpcomingEventsPageFiltersPref' }
  & Pick<UpcomingEventsPageFiltersPref, 'schedulerEmployeeIds' | 'recruiterEmployeeIds' | 'interviewerEmployeeIds' | 'candidateRsvps' | 'interviewerRsvps' | 'allAccepted'>
);

export type UpcomingEventsPageFiltersEmployeePrefQueryVariables = Exact<{
  prefName: Scalars['String'];
}>;


export type UpcomingEventsPageFiltersEmployeePrefQuery = (
  { __typename?: 'Query' }
  & { thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
      & { jsonPref?: Maybe<{ __typename?: 'InterviewLimit' } | { __typename?: 'EmployeeTaskDashboardLayoutPref' } | { __typename?: 'EmployeeTaskDashboardFilterPref' } | { __typename?: 'CandidatePageFiltersPref' } | { __typename?: 'MetricsPageRecruitingTeamFiltersPref' } | { __typename?: 'MetricsPageInterviewerFiltersPref' } | { __typename?: 'DirectoryPageFiltersPref' } | (
        { __typename?: 'UpcomingEventsPageFiltersPref' }
        & UpcomingEventsPageFiltersEmployeePrefFragment
      ) | { __typename?: 'RecentlyUsedZoomUsersPref' } | { __typename?: 'ProfilePicturePref' } | { __typename?: 'InterviewBufferTimePref' }> }
    )> }
  )> }
);

export type UpdateUpcomingEventsPageFiltersEmployeePrefMutationVariables = Exact<{
  input: EmployeePrefUpdateInput;
}>;


export type UpdateUpcomingEventsPageFiltersEmployeePrefMutation = (
  { __typename?: 'Mutation' }
  & { employeePrefUpdate?: Maybe<(
    { __typename?: 'EmployeePrefUpdateResult' }
    & { employeePref?: Maybe<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName'>
    )> }
  )> }
);

export type UpdateCommunications_CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, 'id' | 'preferredPhoneNumber'>
);

export type UpdateCommunications_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & CandidateEmailCard_InterviewPlanFragment
  & CandidateEventCard_InterviewPlanFragment
  & CodingCard_InterviewPlanFragment
  & InterviewerCommunicationCards_InterviewPlanFragment
  & SlackChannelCard_InterviewPlanFragment
);

export type UpdateCommunications_JobStageSettingsFragment = (
  { __typename?: 'JobStageSettingsV2' }
  & Pick<JobStageSettingsV2, 'id'>
  & { interviewMeetingLocationSettingWithLevel?: Maybe<(
    { __typename?: 'InterviewMeetingLocationSettingWithLevel' }
    & { interviewMeetingLocationSetting?: Maybe<(
      { __typename?: 'InterviewMeetingLocationSettingPref' }
      & LocationCard_InterviewMeetingLocationSettingFragment
    )> }
  )> }
);

export type UpdateFlowDoneQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UpdateFlowDoneQuery = (
  { __typename?: 'Query' }
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'isHoldForRsvp' | 'isReadyForCandidateComms'>
    & { application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id' | 'candidateId'>
    )>, task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'isDebriefRequired'>
      & { debriefs: Array<(
        { __typename?: 'ApplicationDebrief' }
        & Pick<ApplicationDebrief, 'id'>
      )> }
    )> }
  )> }
);

export type UpdateScheduleFlowHeaderQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UpdateScheduleFlowHeaderQuery = (
  { __typename?: 'Query' }
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'taskId' | 'name'>
    & { jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
    )>, application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'fullName'>
      )>, job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type UpdateFlowQueryVariables = Exact<{
  id: Scalars['uuid'];
  fetchInterviewPlan: Scalars['Boolean'];
}>;


export type UpdateFlowQuery = (
  { __typename?: 'Query' }
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id'>
    & { application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'timezone'>
        & { atsLatestAvailability?: Maybe<(
          { __typename?: 'CandidateAtsAvailability' }
          & Pick<CandidateAtsAvailability, 'timezone' | 'originalText'>
          & UseCandidateAvailability_AtsLatestAvailabilityFragment
        )> }
        & ScheduleFlowDataProvider_CandidateFragment
      )> }
    )>, jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id' | 'name'>
      & { job?: Maybe<(
        { __typename?: 'Job' }
        & Pick<Job, 'id' | 'name' | 'atsId'>
        & ScheduleInterviewPlan_JobFragment
      )>, jobStageSettingsV2?: Maybe<(
        { __typename?: 'JobStageSettingsV2' }
        & Pick<JobStageSettingsV2, 'id'>
        & ScheduleFlowDataProvider_JobStageSettingsFragment
      )> }
    )>, task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { availabilities: Array<(
        { __typename?: 'CandidateAvailability' }
        & Pick<CandidateAvailability, 'id'>
        & UseCandidateAvailability_AvailabilitiesFragment
      )>, interviewPlan: (
        { __typename?: 'JobStage' }
        & Pick<JobStage, 'id'>
        & ScheduleFlowDataProvider_InterviewPlanFragment
      ) }
    )> }
  )> }
);

export type ApplicationStageInterviewQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ApplicationStageInterviewQuery = (
  { __typename?: 'Query' }
  & { applicationStage?: Maybe<(
    { __typename?: 'ApplicationStage' }
    & Pick<ApplicationStage, 'id' | 'isCancelled' | 'applicationId' | 'jobStageId' | 'emailTemplateId' | 'timezone' | 'videoMeetingUrl' | 'initialEmailSubject' | 'initialEmailBody' | 'isPrivateCalendarEvent'>
    & { task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
      & { interviewPlan: (
        { __typename?: 'JobStage' }
        & Pick<JobStage, 'id'>
        & JobStage_InterviewPlanFragment
      ) }
    )>, conversation?: Maybe<(
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id' | 'remoteName'>
    )>, applicationStageCandidateEvents?: Maybe<Array<(
      { __typename?: 'ApplicationStageCandidateEvent' }
      & Pick<ApplicationStageCandidateEvent, 'id' | 'name' | 'googleEventId' | 'googleCalendarId' | 'calendarEventTemplateId'>
    )>>, application?: Maybe<(
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { jobStage?: Maybe<(
        { __typename?: 'JobStage' }
        & Pick<JobStage, 'id' | 'atsId'>
        & { job?: Maybe<(
          { __typename?: 'Job' }
          & Pick<Job, 'id' | 'atsId'>
        )> }
      )>, candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id' | 'fullName' | 'email' | 'additionalEmails' | 'recruiterId' | 'coordinatorId'>
        & { atsCandidate?: Maybe<(
          { __typename?: 'AtsCandidate' }
          & Pick<AtsCandidate, 'atsId' | 'atsUrl'>
        )> }
      )> }
    )>, applicationStageInterviews?: Maybe<Array<(
      { __typename?: 'ApplicationStageInterview' }
      & Pick<ApplicationStageInterview, 'id' | 'name' | 'endAt' | 'startAt' | 'googleEventId' | 'googleCalendarId' | 'jobStageInterviewId' | 'videoMeetingUrl' | 'zoomHostUserId' | 'zoomMeetingId' | 'zoomPassword' | 'zoomPstnPassword' | 'zoomDialInInfo' | 'codingInterviewUrl' | 'emailTemplateId' | 'isHiddenFromCandidate' | 'videoMeetingHostEmployeeId' | 'atsScheduledInterviewId' | 'atsInterviewDefinitionId'>
      & { applicationStageInterviewInterviewers?: Maybe<Array<(
        { __typename?: 'ApplicationStageInterviewInterviewer' }
        & Pick<ApplicationStageInterviewInterviewer, 'interviewModuleId' | 'isOptional' | 'jobStageInterviewSeatId' | 'role' | 'responseStatus' | 'interviewerId'>
        & { interviewer?: Maybe<(
          { __typename?: 'Employee' }
          & Pick<Employee, 'id' | 'atsId' | 'fullName' | 'slackImageUrl' | 'email' | 'givenName' | 'timezone' | 'title' | 'linkedinUrl' | 'familyName' | 'orgId' | 'isDirectoryDisabled' | 'useCalendarTimezone' | 'isPaused'>
        )> }
      )>>, applicationStageInterviewRooms?: Maybe<Array<(
        { __typename?: 'ApplicationStageInterviewRoom' }
        & Pick<ApplicationStageInterviewRoom, 'applicationStageInterviewId' | 'meetingRoomId' | 'responseStatus'>
      )>>, atsInterviewDefinition?: Maybe<(
        { __typename?: 'AtsInterviewDefinition' }
        & AtsInterviewDefinitionFragment
      )> }
    )>>, jobStage?: Maybe<(
      { __typename?: 'JobStage' }
      & Pick<JobStage, 'id'>
      & { jobStageSettings?: Maybe<(
        { __typename?: 'JobStageSettings' }
        & Pick<JobStageSettings, 'id' | 'candidateEmailTemplateId' | 'candidateEventTemplateId' | 'candidateCalendarId' | 'slackTemplateId'>
      )> }
    )> }
  )>, thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { employeePrefs?: Maybe<Array<(
      { __typename?: 'EmployeePref' }
      & Pick<EmployeePref, 'orgId' | 'employeeId' | 'prefName' | 'stringValue'>
    )>> }
  )>, orgCandidateCalendarId?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { orgPref?: Maybe<(
      { __typename?: 'OrgPref' }
      & Pick<OrgPref, 'orgId' | 'prefName' | 'stringValue'>
    )> }
  )>, orgInterviewerCalendarId?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { orgPref?: Maybe<(
      { __typename?: 'OrgPref' }
      & Pick<OrgPref, 'orgId' | 'prefName' | 'stringValue'>
    )> }
  )> }
);

export type BaseUpdateCalendarView_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & { jobStageInterviewGroups?: Maybe<Array<(
    { __typename?: 'JobStageInterviewGroup' }
    & Pick<JobStageInterviewGroup, 'id'>
    & { jobStageInterviews?: Maybe<Array<(
      { __typename?: 'JobStageInterview' }
      & Pick<JobStageInterview, 'id'>
      & EventContent_InterviewFragment
      & EventContent_OriginalInterviewsFragment
      & InterviewDetails_InterviewFragment
      & InterviewDetails_OriginalInterviewsFragment
    )>> }
  )>> }
);

export type UpdateCalendarView_InterviewPlanFragment = (
  { __typename?: 'JobStage' }
  & Pick<JobStage, 'id'>
  & BaseUpdateCalendarView_InterviewPlanFragment
);

export type AccountPhoneNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountPhoneNumberQuery = (
  { __typename?: 'Query' }
  & { thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id' | 'phoneNumber' | 'email'>
  )> }
);

export type Notification_EmployeeNotificationPrefFragment = (
  { __typename?: 'EmployeeNotificationPreference' }
  & Pick<EmployeeNotificationPreference, 'employeeId' | 'type' | 'medium' | 'value'>
);

export type EmployeeNotificationPrefsQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployeeNotificationPrefsQuery = (
  { __typename?: 'Query' }
  & { thisEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { notificationPrefs: Array<(
      { __typename?: 'EmployeeNotificationPreference' }
      & Notification_EmployeeNotificationPrefFragment
    )> }
  )> }
);

export type EmployeeNotificationPrefMutationVariables = Exact<{
  input: EmployeeNotificationPrefUpdateInput;
}>;


export type EmployeeNotificationPrefMutation = (
  { __typename?: 'Mutation' }
  & { employeeNotificationPrefUpdate?: Maybe<(
    { __typename?: 'EmployeeNotificationPrefUpdateResult' }
    & { employeeNotificationPref?: Maybe<(
      { __typename?: 'EmployeeNotificationPreference' }
      & Notification_EmployeeNotificationPrefFragment
    )> }
  )> }
);

export type InterviewModuleMemberMenuOptionsQueryVariables = Exact<{
  interviewModuleId: Scalars['uuid'];
  employeeId: Scalars['uuid'];
}>;


export type InterviewModuleMemberMenuOptionsQuery = (
  { __typename?: 'Query' }
  & { interviewModuleMember?: Maybe<(
    { __typename?: 'InterviewModuleMember' }
    & Pick<InterviewModuleMember, 'employeeId' | 'interviewModuleId'>
    & InterviewModuleMemberMenuOptionsFragment
    & RemoveMemberModal_InterviewModuleMemberFragment
    & SetModuleMemberAsTrained_InterviewModuleMemberFragment
    & PauseMemberModal_ModuleMemberFragment
    & SetModuleMemberAsTrainee_ModuleMemberFragment
  )> }
);

export type InterviewModuleMemberMenuOptionsFragment = (
  { __typename?: 'InterviewModuleMember' }
  & Pick<InterviewModuleMember, 'employeeId' | 'interviewModuleId' | 'status'>
);

export type TaskSetIsUrgentMutationVariables = Exact<{
  input: TaskSetIsUrgentInput;
}>;


export type TaskSetIsUrgentMutation = (
  { __typename?: 'Mutation' }
  & { taskSetIsUrgent: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'isUrgent'>
  ) }
);

export type TaskCancelMutationVariables = Exact<{
  input: TaskCancelInput;
}>;


export type TaskCancelMutation = (
  { __typename?: 'Mutation' }
  & { taskCancel: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  ) }
);

export type ScheduleTaskQueryVariables = Exact<{
  scheduleTaskId: Scalars['uuid'];
}>;


export type ScheduleTaskQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & { application: (
      { __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { candidate?: Maybe<(
        { __typename?: 'Candidate' }
        & Pick<Candidate, 'id'>
      )> }
    ) }
    & ScheduleTask_TaskFragment
    & UseSchduleTaskMenuOptions_TaskFragment
  )> }
);

export type UseSchduleTaskMenuOptions_TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'isUrgent' | 'status'>
);

export const TaskName_TaskFragmentDoc = gql`
    fragment TaskName_task on Task {
  id
  name
}
    `;
export const ActivityLogAvatar_ActivityLogFragmentDoc = gql`
    fragment ActivityLogAvatar_activityLog on ActivityLog {
  id
  activityActorType
  actorEmployee {
    id
    fullName
    slackImageUrl
  }
}
    `;
export const ActivityLogBase_ActivityLogFragmentDoc = gql`
    fragment ActivityLogBase_activityLog on ActivityLog {
  id
  source {
    ... on Task {
      id
      __typename
    }
    ... on Task @include(if: $showTaskName) {
      ...TaskName_task
    }
  }
  createdAt
  sourceType
  activityActorType
  actorEmployee {
    id
    fullName
    slackImageUrl
  }
  ...ActivityLogAvatar_activityLog
}
    ${TaskName_TaskFragmentDoc}
${ActivityLogAvatar_ActivityLogFragmentDoc}`;
export const ActivityLogTaskClosedDueToStageChange_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskClosedDueToStageChange_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
  details {
    ... on ActivityLogTaskDetails {
      __typename
    }
  }
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskDebriefRescheduled_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskDebriefRescheduled_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskFlagAdded_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskFlagAdded_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogFlagDetails {
      flagContent
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskMigrated_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskMigrated_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRescheduleLogCreated_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRescheduleLogCreated_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogRescheduleLogDetails {
      __typename
      rescheduleLog {
        id
        rescheduleReason {
          reason
        }
        note
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRescheduleLogDeleted_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRescheduleLogDeleted_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogRescheduleLogDetails {
      __typename
      rescheduleLog {
        id
        note
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRescheduleLogEdited_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRescheduleLogEdited_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogRescheduleLogDetails {
      __typename
      rescheduleLog {
        id
        note
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogApplicationCandidatePortalNoteCreated_ActivityLogFragmentDoc = gql`
    fragment ActivityLogApplicationCandidatePortalNoteCreated_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogCandidatePortalNoteDetails {
      candidatePortalNoteId
      content
      sendToCandidate
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogApplicationCandidatePortalNoteUpdated_ActivityLogFragmentDoc = gql`
    fragment ActivityLogApplicationCandidatePortalNoteUpdated_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogCandidatePortalNoteDetails {
      candidatePortalNoteId
      content
      sendToCandidate
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogApplicationCandidatePortalNoteDeleted_ActivityLogFragmentDoc = gql`
    fragment ActivityLogApplicationCandidatePortalNoteDeleted_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogCandidatePortalNoteDetails {
      candidatePortalNoteId
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogEmail_ActivityLogFragmentDoc = gql`
    fragment ActivityLogEmail_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogEmailDetails {
      __typename
      fromEmailAddress
      toEmailAddress
      ccAddresses
      subject
      body
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogNote_ActivityLogFragmentDoc = gql`
    fragment ActivityLogNote_activityLog on ActivityLog {
  id
  activityActorEmployeeId
  details {
    ... on ActivityLogNoteDetails {
      note
      isEdited
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskAssigned_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskAssigned_activityLog on ActivityLog {
  id
  details {
    __typename
    ... on ActivityLogTaskAssigneeDetails {
      assigneeId
      assignee {
        id
        fullName
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskCalendarChanges_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskCalendarChanges_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskCandidateAvailabilityDeleted_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskCandidateAvailabilityDeleted_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskCandidateRsvp_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskCandidateRsvp_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogRsvpDetails {
      startAt
      endAt
      responseStatus
      comment
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskCandidateSelfScheduleCanceled_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskCandidateSelfScheduleCanceled_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogTaskEventDetails {
      __typename
      selfScheduleCandidateNote
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskCandidateSelfScheduleCreated_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskCandidateSelfScheduleCreated_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogTaskEventDetails {
      __typename
      selfScheduleCandidateNote
      sourcingLinkId
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskCandidateSelfScheduleRescheduled_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskCandidateSelfScheduleRescheduled_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogTaskEventDetails {
      __typename
      selfScheduleCandidateNote
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskCandidateSubmittedAvailability_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskCandidateSubmittedAvailability_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogTaskAvailabilityDetails {
      sourcingLinkId
      note
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskChangedStatus_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskChangedStatus_activityLog on ActivityLog {
  id
  details {
    __typename
    ... on ActivityLogTaskStatusDetails {
      taskStatus
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskChangeRequested_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskChangeRequested_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogFlagDetails {
      flagContent
      __typename
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskClosed_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskClosed_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogTaskDetails {
      __typename
      taskId
      task {
        id
        ...TaskName_task
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${TaskName_TaskFragmentDoc}
${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskCreated_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskCreated_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogTaskDetails {
      __typename
      taskId
      sourcingLinkId
      task {
        id
        ...TaskName_task
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${TaskName_TaskFragmentDoc}
${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskDebriefCanceled_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskDebriefCanceled_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskDebriefCreated_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskDebriefCreated_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskEditedAvailability_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskEditedAvailability_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskFlagResolvedCandidateDeclined_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskFlagResolvedCandidateDeclined_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskFlagResolvedChangeRequested_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskFlagResolvedChangeRequested_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogFlagDetails {
      flagContent
      __typename
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskFlagResolvedDebriefDeclined_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskFlagResolvedDebriefDeclined_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogTaskInterviewerDetails {
      __typename
      interviewName
      interviewer {
        id
        fullName
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskFlagResolvedInterviewDeclined_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskFlagResolvedInterviewDeclined_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogTaskInterviewerDetails {
      __typename
      interviewName
      interviewer {
        id
        fullName
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskInterviewerRsvp_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskInterviewerRsvp_activityLog on ActivityLog {
  id
  details {
    __typename
    ... on ActivityLogRsvpDetails {
      interviewName
      responseStatus
      comment
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRcAdjustInterviewerRsvp_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRcAdjustInterviewerRsvp_activityLog on ActivityLog {
  id
  details {
    __typename
    ... on ActivityLogRsvpDetails {
      interviewName
      responseStatus
      comment
      rsvp: interviewer {
        fullName
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskMarkedUrgent_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskMarkedUrgent_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskMeetingRoomRsvp_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskMeetingRoomRsvp_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogTaskMeetingRoomDetails {
      __typename
      responseStatus
      comment
      interviewName
      room {
        id
        name
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskReopened_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskReopened_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogTaskDetails {
      __typename
      taskId
      task {
        id
        ...TaskName_task
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${TaskName_TaskFragmentDoc}
${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRequirementApplicationDebriefAdded_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRequirementApplicationDebriefAdded_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRequirementApplicationDebriefRemoved_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRequirementApplicationDebriefRemoved_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRequirementCandidateAvailabilityAdded_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRequirementCandidateAvailabilityAdded_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRequirementCandidateAvailabilityRemoved_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRequirementCandidateAvailabilityRemoved_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRequirementCandidateAvailabilityUpdated_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRequirementCandidateAvailabilityUpdated_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRequirementScheduleAdded_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRequirementScheduleAdded_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRequirementSelfScheduleAdded_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRequirementSelfScheduleAdded_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRequirementSelfScheduleRemoved_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRequirementSelfScheduleRemoved_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskRequirementSelfScheduleUpdated_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskRequirementSelfScheduleUpdated_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskScheduleCanceled_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskScheduleCanceled_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskScheduleCreated_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskScheduleCreated_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskScheduleRescheduled_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskScheduleRescheduled_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskScheduleUpdated_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskScheduleUpdated_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskTagAdded_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskTagAdded_activityLog on ActivityLog {
  id
  details {
    __typename
    ... on ActivityLogTaskTagDetails {
      taskTags {
        id
        name
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskTagRemoved_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskTagRemoved_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogTaskTagDetails {
      taskTags {
        id
        name
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskUnassigned_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskUnassigned_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskUnmarkedUrgent_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskUnmarkedUrgent_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskReadyForCandidateComm_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskReadyForCandidateComm_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogCandidateViewedTabDetails_ActivityLogFragmentDoc = gql`
    fragment ActivityLogCandidateViewedTabDetails_activityLog on ActivityLog {
  id
  details {
    ... on ActivityLogCandidateViewedTabDetails {
      __typename
      tabName
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskQueueSet_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskQueueSet_activityLog on ActivityLog {
  id
  details {
    __typename
    ... on ActivityLogTaskQueueDetails {
      taskQueue {
        id
        name
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskQueueUnset_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskQueueUnset_activityLog on ActivityLog {
  id
  details {
    __typename
    ... on ActivityLogTaskQueueDetails {
      taskQueue {
        id
        name
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskCandidateInterviewReminderSent_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskCandidateInterviewReminderSent_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskCandidateSelfScheduleReminderSent_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskCandidateSelfScheduleReminderSent_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskCandidateAvailabilityReminderSent_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskCandidateAvailabilityReminderSent_activityLog on ActivityLog {
  id
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogTaskDeclinedInterviewerAutoReplaced_ActivityLogFragmentDoc = gql`
    fragment ActivityLogTaskDeclinedInterviewerAutoReplaced_activityLog on ActivityLog {
  id
  details {
    __typename
    ... on ActivityLogRsvpDetails {
      interviewName
      previous: interviewer {
        fullName
      }
      current: addedInterviewer {
        fullName
      }
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLogCandidatePreferredPhoneNumberChangeDetails_ActivityLogFragmentDoc = gql`
    fragment ActivityLogCandidatePreferredPhoneNumberChangeDetails_activityLog on ActivityLog {
  id
  details {
    __typename
    ... on ActivityLogCandidatePreferredPhoneNumberChangeDetails {
      updatedPhoneNumber
    }
  }
  ...ActivityLogBase_activityLog
}
    ${ActivityLogBase_ActivityLogFragmentDoc}`;
export const ActivityLog_ActivityLogFragmentDoc = gql`
    fragment ActivityLog_activityLog on ActivityLog {
  id
  activityType
  ...ActivityLogApplicationCandidatePortalNoteCreated_activityLog
  ...ActivityLogApplicationCandidatePortalNoteUpdated_activityLog
  ...ActivityLogApplicationCandidatePortalNoteDeleted_activityLog
  ...ActivityLogEmail_activityLog
  ...ActivityLogNote_activityLog
  ...ActivityLogTaskAssigned_activityLog
  ...ActivityLogTaskCalendarChanges_activityLog
  ...ActivityLogTaskCandidateAvailabilityDeleted_activityLog
  ...ActivityLogTaskCandidateRsvp_activityLog
  ...ActivityLogTaskCandidateSelfScheduleCanceled_activityLog
  ...ActivityLogTaskCandidateSelfScheduleCreated_activityLog
  ...ActivityLogTaskCandidateSelfScheduleRescheduled_activityLog
  ...ActivityLogTaskCandidateSubmittedAvailability_activityLog
  ...ActivityLogTaskChangedStatus_activityLog
  ...ActivityLogTaskChangeRequested_activityLog
  ...ActivityLogTaskClosed_activityLog
  ...ActivityLogTaskClosed_activityLog
  ...ActivityLogTaskCreated_activityLog
  ...ActivityLogTaskCreated_activityLog
  ...ActivityLogTaskDebriefCanceled_activityLog
  ...ActivityLogTaskDebriefCreated_activityLog
  ...ActivityLogTaskEditedAvailability_activityLog
  ...ActivityLogTaskFlagResolvedCandidateDeclined_activityLog
  ...ActivityLogTaskFlagResolvedChangeRequested_activityLog
  ...ActivityLogTaskFlagResolvedDebriefDeclined_activityLog
  ...ActivityLogTaskFlagResolvedInterviewDeclined_activityLog
  ...ActivityLogTaskInterviewerRsvp_activityLog
  ...ActivityLogTaskRcAdjustInterviewerRsvp_activityLog
  ...ActivityLogTaskMarkedUrgent_activityLog
  ...ActivityLogTaskMeetingRoomRsvp_activityLog
  ...ActivityLogTaskReopened_activityLog
  ...ActivityLogTaskRequirementApplicationDebriefAdded_activityLog
  ...ActivityLogTaskRequirementApplicationDebriefRemoved_activityLog
  ...ActivityLogTaskRequirementCandidateAvailabilityAdded_activityLog
  ...ActivityLogTaskRequirementCandidateAvailabilityRemoved_activityLog
  ...ActivityLogTaskRequirementCandidateAvailabilityUpdated_activityLog
  ...ActivityLogTaskRequirementScheduleAdded_activityLog
  ...ActivityLogTaskRequirementSelfScheduleAdded_activityLog
  ...ActivityLogTaskRequirementSelfScheduleRemoved_activityLog
  ...ActivityLogTaskRequirementSelfScheduleUpdated_activityLog
  ...ActivityLogTaskScheduleCanceled_activityLog
  ...ActivityLogTaskScheduleCreated_activityLog
  ...ActivityLogTaskScheduleRescheduled_activityLog
  ...ActivityLogTaskScheduleUpdated_activityLog
  ...ActivityLogTaskTagAdded_activityLog
  ...ActivityLogTaskTagRemoved_activityLog
  ...ActivityLogTaskUnassigned_activityLog
  ...ActivityLogTaskUnmarkedUrgent_activityLog
  ...ActivityLogTaskReadyForCandidateComm_activityLog
  ...ActivityLogCandidateViewedTabDetails_activityLog
  ...ActivityLogTaskQueueSet_activityLog
  ...ActivityLogTaskQueueUnset_activityLog
  ...ActivityLogTaskCandidateInterviewReminderSent_activityLog
  ...ActivityLogTaskCandidateSelfScheduleReminderSent_activityLog
  ...ActivityLogTaskCandidateAvailabilityReminderSent_activityLog
  ...ActivityLogTaskDeclinedInterviewerAutoReplaced_activityLog
  ...ActivityLogCandidatePreferredPhoneNumberChangeDetails_activityLog
}
    ${ActivityLogApplicationCandidatePortalNoteCreated_ActivityLogFragmentDoc}
${ActivityLogApplicationCandidatePortalNoteUpdated_ActivityLogFragmentDoc}
${ActivityLogApplicationCandidatePortalNoteDeleted_ActivityLogFragmentDoc}
${ActivityLogEmail_ActivityLogFragmentDoc}
${ActivityLogNote_ActivityLogFragmentDoc}
${ActivityLogTaskAssigned_ActivityLogFragmentDoc}
${ActivityLogTaskCalendarChanges_ActivityLogFragmentDoc}
${ActivityLogTaskCandidateAvailabilityDeleted_ActivityLogFragmentDoc}
${ActivityLogTaskCandidateRsvp_ActivityLogFragmentDoc}
${ActivityLogTaskCandidateSelfScheduleCanceled_ActivityLogFragmentDoc}
${ActivityLogTaskCandidateSelfScheduleCreated_ActivityLogFragmentDoc}
${ActivityLogTaskCandidateSelfScheduleRescheduled_ActivityLogFragmentDoc}
${ActivityLogTaskCandidateSubmittedAvailability_ActivityLogFragmentDoc}
${ActivityLogTaskChangedStatus_ActivityLogFragmentDoc}
${ActivityLogTaskChangeRequested_ActivityLogFragmentDoc}
${ActivityLogTaskClosed_ActivityLogFragmentDoc}
${ActivityLogTaskCreated_ActivityLogFragmentDoc}
${ActivityLogTaskDebriefCanceled_ActivityLogFragmentDoc}
${ActivityLogTaskDebriefCreated_ActivityLogFragmentDoc}
${ActivityLogTaskEditedAvailability_ActivityLogFragmentDoc}
${ActivityLogTaskFlagResolvedCandidateDeclined_ActivityLogFragmentDoc}
${ActivityLogTaskFlagResolvedChangeRequested_ActivityLogFragmentDoc}
${ActivityLogTaskFlagResolvedDebriefDeclined_ActivityLogFragmentDoc}
${ActivityLogTaskFlagResolvedInterviewDeclined_ActivityLogFragmentDoc}
${ActivityLogTaskInterviewerRsvp_ActivityLogFragmentDoc}
${ActivityLogTaskRcAdjustInterviewerRsvp_ActivityLogFragmentDoc}
${ActivityLogTaskMarkedUrgent_ActivityLogFragmentDoc}
${ActivityLogTaskMeetingRoomRsvp_ActivityLogFragmentDoc}
${ActivityLogTaskReopened_ActivityLogFragmentDoc}
${ActivityLogTaskRequirementApplicationDebriefAdded_ActivityLogFragmentDoc}
${ActivityLogTaskRequirementApplicationDebriefRemoved_ActivityLogFragmentDoc}
${ActivityLogTaskRequirementCandidateAvailabilityAdded_ActivityLogFragmentDoc}
${ActivityLogTaskRequirementCandidateAvailabilityRemoved_ActivityLogFragmentDoc}
${ActivityLogTaskRequirementCandidateAvailabilityUpdated_ActivityLogFragmentDoc}
${ActivityLogTaskRequirementScheduleAdded_ActivityLogFragmentDoc}
${ActivityLogTaskRequirementSelfScheduleAdded_ActivityLogFragmentDoc}
${ActivityLogTaskRequirementSelfScheduleRemoved_ActivityLogFragmentDoc}
${ActivityLogTaskRequirementSelfScheduleUpdated_ActivityLogFragmentDoc}
${ActivityLogTaskScheduleCanceled_ActivityLogFragmentDoc}
${ActivityLogTaskScheduleCreated_ActivityLogFragmentDoc}
${ActivityLogTaskScheduleRescheduled_ActivityLogFragmentDoc}
${ActivityLogTaskScheduleUpdated_ActivityLogFragmentDoc}
${ActivityLogTaskTagAdded_ActivityLogFragmentDoc}
${ActivityLogTaskTagRemoved_ActivityLogFragmentDoc}
${ActivityLogTaskUnassigned_ActivityLogFragmentDoc}
${ActivityLogTaskUnmarkedUrgent_ActivityLogFragmentDoc}
${ActivityLogTaskReadyForCandidateComm_ActivityLogFragmentDoc}
${ActivityLogCandidateViewedTabDetails_ActivityLogFragmentDoc}
${ActivityLogTaskQueueSet_ActivityLogFragmentDoc}
${ActivityLogTaskQueueUnset_ActivityLogFragmentDoc}
${ActivityLogTaskCandidateInterviewReminderSent_ActivityLogFragmentDoc}
${ActivityLogTaskCandidateSelfScheduleReminderSent_ActivityLogFragmentDoc}
${ActivityLogTaskCandidateAvailabilityReminderSent_ActivityLogFragmentDoc}
${ActivityLogTaskDeclinedInterviewerAutoReplaced_ActivityLogFragmentDoc}
${ActivityLogCandidatePreferredPhoneNumberChangeDetails_ActivityLogFragmentDoc}`;
export const ActivityLogsList_ActivityLogFragmentDoc = gql`
    fragment ActivityLogsList_activityLog on ActivityLog {
  id
  ...ActivityLog_activityLog
}
    ${ActivityLog_ActivityLogFragmentDoc}`;
export const ApplicationDetailsActivityLogsList_ActivityLogFragmentDoc = gql`
    fragment ApplicationDetailsActivityLogsList_activityLog on ActivityLog {
  id
  ...ActivityLogsList_activityLog
}
    ${ActivityLogsList_ActivityLogFragmentDoc}`;
export const TaskDetailsActivityLogsList_ActivityLogFragmentDoc = gql`
    fragment TaskDetailsActivityLogsList_activityLog on ActivityLog {
  id
  ...ActivityLogsList_activityLog
}
    ${ActivityLogsList_ActivityLogFragmentDoc}`;
export const CompanyHolidayIcon_CompanyHolidaysFragmentDoc = gql`
    fragment CompanyHolidayIcon_companyHolidays on CompanyHoliday {
  countries {
    id
    value
  }
  id
  name
  startAt
  endAt
}
    `;
export const EmployeeFragmentDoc = gql`
    fragment Employee on Employee {
  fullName
  email
  id
  orgId
  slackImageUrl
  timezone
  atsId
  isDirectoryDisabled
  isArchived
  isAtsDisabled
  hasAtsId
  title
}
    `;
export const EmployeeAttributesFragmentDoc = gql`
    fragment EmployeeAttributes on Employee {
  id
  isArchived
  fullName
  email
  timezone
  linkedinUrl
  title
  googleUserId
  isDirectoryDisabled
  isAtsDisabled
  hasAtsId
  atsId
  manager {
    id
    fullName
    slackImageUrl
  }
  atsUser {
    atsId
    fullName
    email
    isDisabled
  }
}
    `;
export const EmployeeWorkHoursFragmentDoc = gql`
    fragment EmployeeWorkHours on Employee {
  workHours {
    monday {
      start
      end
    }
    tuesday {
      start
      end
    }
    wednesday {
      start
      end
    }
    thursday {
      start
      end
    }
    friday {
      start
      end
    }
    saturday {
      start
      end
    }
    sunday {
      start
      end
    }
  }
}
    `;
export const EmployeeDetailsFragmentFragmentDoc = gql`
    fragment EmployeeDetailsFragment on Employee {
  ...Employee
  linkedinUrl
  googleUserId
  givenName
  useCalendarTimezone
  calendarTimezone
  interviewPauseDates {
    start
    end
  }
  ...EmployeeAttributes
  ...EmployeeWorkHours
}
    ${EmployeeFragmentDoc}
${EmployeeAttributesFragmentDoc}
${EmployeeWorkHoursFragmentDoc}`;
export const VideoMeetingHost_InterviewMeetingLocationSettingPrefFragmentDoc = gql`
    fragment VideoMeetingHost_interviewMeetingLocationSettingPref on InterviewMeetingLocationSettingPref {
  interviewMeetingLocationType
  dynamicHost
  hostEmployee {
    id
    fullName
    slackImageUrl
    email
  }
}
    `;
export const InterviewMeetingLocationHost_InterviewMeetingLocationSettingPrefFragmentDoc = gql`
    fragment InterviewMeetingLocationHost_interviewMeetingLocationSettingPref on InterviewMeetingLocationSettingPref {
  interviewMeetingLocationType
  dynamicHost
  hostEmployeeId
  remoteVideoMeetingHostUserId
  remoteVideoMeetingHostUser {
    ... on ZoomUser {
      zoomUserId
      firstName
      lastName
      email
    }
  }
  customLocation
  ...VideoMeetingHost_interviewMeetingLocationSettingPref
}
    ${VideoMeetingHost_InterviewMeetingLocationSettingPrefFragmentDoc}`;
export const InterviewMeetingLocationModal_InterviewMeetingLocationSettingPrefFragmentDoc = gql`
    fragment InterviewMeetingLocationModal_interviewMeetingLocationSettingPref on InterviewMeetingLocationSettingPref {
  interviewMeetingLocationType
  ...InterviewMeetingLocationHost_interviewMeetingLocationSettingPref
}
    ${InterviewMeetingLocationHost_InterviewMeetingLocationSettingPrefFragmentDoc}`;
export const IsModuleMemberPaused_ModuleMemberFragmentDoc = gql`
    fragment isModuleMemberPaused_ModuleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  isPaused
  pausedUntil
}
    `;
export const BaseEditInterviewSeatModuleEmployeeSearchV2FragmentDoc = gql`
    fragment BaseEditInterviewSeatModuleEmployeeSearchV2 on Employee {
  id
  fullName
  email
  orgId
  isDirectoryDisabled
  hasAtsId
  slackImageUrl
  timezone
  atsId
  isAtsDisabled
}
    `;
export const InterviewModuleFragmentDoc = gql`
    fragment InterviewModule on InterviewModule {
  id
  name
  shadowsRequired
  reverseShadowsRequired
  weeklyNumInterviewLimit
}
    `;
export const GetShadowCompletedCount_ModuleMemberFragmentDoc = gql`
    fragment getShadowCompletedCount_moduleMember on InterviewModuleMember {
  employeeId
  interviewModuleId
  stats {
    completedAsShadow
    manualCompletedAsShadow
  }
}
    `;
export const GetShadowPendingCount_ModuleMemberFragmentDoc = gql`
    fragment getShadowPendingCount_moduleMember on InterviewModuleMember {
  employeeId
  interviewModuleId
  shadowsRequired
  ...getShadowCompletedCount_moduleMember
}
    ${GetShadowCompletedCount_ModuleMemberFragmentDoc}`;
export const GetReverseShadowCompletedCount_ModuleMemberFragmentDoc = gql`
    fragment getReverseShadowCompletedCount_moduleMember on InterviewModuleMember {
  employeeId
  interviewModuleId
  stats {
    completedAsReverseShadow
    manualCompletedAsReverseShadow
  }
}
    `;
export const GetReverseShadowPendingCount_ModuleMemberFragmentDoc = gql`
    fragment getReverseShadowPendingCount_moduleMember on InterviewModuleMember {
  employeeId
  interviewModuleId
  reverseShadowsRequired
  ...getReverseShadowCompletedCount_moduleMember
}
    ${GetReverseShadowCompletedCount_ModuleMemberFragmentDoc}`;
export const GetModuleMemberPendingTrainingCount_ModuleMemberFragmentDoc = gql`
    fragment getModuleMemberPendingTrainingCount_ModuleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  status
  stats {
    upcoming
  }
  ...getShadowPendingCount_moduleMember
  ...getReverseShadowPendingCount_moduleMember
}
    ${GetShadowPendingCount_ModuleMemberFragmentDoc}
${GetReverseShadowPendingCount_ModuleMemberFragmentDoc}`;
export const IsModuleMemberDisabledForScheduling_ModuleMemberFragmentDoc = gql`
    fragment isModuleMemberDisabledForScheduling_ModuleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  status
  shadowsRequired
  reverseShadowsRequired
  stats {
    currentTrainingSessionStatus
  }
  interviewModule {
    id
    shouldFastTrackTraining
  }
  ...getModuleMemberPendingTrainingCount_ModuleMember
}
    ${GetModuleMemberPendingTrainingCount_ModuleMemberFragmentDoc}`;
export const IsModuleMembreOrEmployeePaused_ModuleMemberFragmentDoc = gql`
    fragment isModuleMembreOrEmployeePaused_ModuleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  employee {
    id
    isPaused
  }
  isPaused
}
    `;
export const ModuleMember_InterviewModuleMemberFragmentDoc = gql`
    fragment ModuleMember_interviewModuleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  employee {
    id
    fullName
    slackImageUrl
    title
  }
  stats {
    currentTrainingSessionStatus
  }
  ...isModuleMembreOrEmployeePaused_ModuleMember
  ...getModuleMemberPendingTrainingCount_ModuleMember
}
    ${IsModuleMembreOrEmployeePaused_ModuleMemberFragmentDoc}
${GetModuleMemberPendingTrainingCount_ModuleMemberFragmentDoc}`;
export const InterviewModuleMember_InterviewModuleMemberFragmentDoc = gql`
    fragment InterviewModuleMember_interviewModuleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  ...ModuleMember_interviewModuleMember
}
    ${ModuleMember_InterviewModuleMemberFragmentDoc}`;
export const EmployeeFilterFragmentDoc = gql`
    fragment EmployeeFilter on InterviewModuleMember {
  id
  ...isModuleMemberDisabledForScheduling_ModuleMember
  ...InterviewModuleMember_interviewModuleMember
  interviewModuleId
  employeeId
  status
  shadowsRequired
  reverseShadowsRequired
  pausedUntil
  stats {
    completedAsReverseShadow
    completedAsShadow
    shadowOffset
    reverseShadowOffset
  }
  employee {
    id
    fullName
    email
    title
    slackImageUrl
    attributes {
      id
      value
    }
    interviewPauseDates {
      start
      end
    }
  }
}
    ${IsModuleMemberDisabledForScheduling_ModuleMemberFragmentDoc}
${InterviewModuleMember_InterviewModuleMemberFragmentDoc}`;
export const BaseEditInterviewSeatModuleInterviewModuleSearchV2FragmentDoc = gql`
    fragment BaseEditInterviewSeatModuleInterviewModuleSearchV2 on InterviewModule {
  ...InterviewModule
  orgId
  membersCount
  membersTrainedCount
  membersInTrainingCount
  membersInReverseShadowCount
  membersInShadowCount
  membersInPendingApprovalCount
  graduateFromShadowApprovalType
  graduateFromReverseShadowApprovalType
  interviewModuleMembers(input: {pageInput: {limit: 500, offset: 0}}) {
    items {
      interviewModuleId
      employeeId
      ...EmployeeFilter
    }
  }
}
    ${InterviewModuleFragmentDoc}
${EmployeeFilterFragmentDoc}`;
export const EmployeeAndAttributesFilterFragmentDoc = gql`
    fragment EmployeeAndAttributesFilter on InterviewModuleMember {
  ...EmployeeFilter
}
    ${EmployeeFilterFragmentDoc}`;
export const EditModuleSeatInterviewModuleV2FragmentDoc = gql`
    fragment EditModuleSeatInterviewModuleV2 on InterviewModuleMember {
  interviewModuleId
  ...EmployeeAndAttributesFilter
}
    ${EmployeeAndAttributesFilterFragmentDoc}`;
export const EditModuleSeatInterviewStatsV2FragmentDoc = gql`
    fragment EditModuleSeatInterviewStatsV2 on InterviewModule {
  id
  name
  membersCount
  membersInReverseShadowCount
  membersInShadowCount
  membersTrainedCount
}
    `;
export const MeetingRoomFragmentFragmentDoc = gql`
    fragment MeetingRoomFragment on MeetingRoom {
  id
  name
  floorName
  description
  capacity
  features
}
    `;
export const ScheduleContentInterviewDetails_CandidateEventFragmentDoc = gql`
    fragment ScheduleContentInterviewDetails_candidateEvent on ApplicationStageCandidateEvent {
  id
  responseStatus
}
    `;
export const AtsUserFragmentDoc = gql`
    fragment AtsUser on AtsUser {
  id
  email
  name
  disabled
  employeeId
  employee {
    id
    fullName
    slackImageUrl
  }
}
    `;
export const BrandingThemePlaceholderPreview_BrandingThemeFragmentDoc = gql`
    fragment BrandingThemePlaceholderPreview_brandingTheme on BrandingTheme {
  id
  themeMode
  profileImageUrl
  coverImageUrl
  primaryColor
  font
}
    `;
export const Row_BrandingOptionsFragmentDoc = gql`
    fragment Row_BrandingOptions on BrandingTheme {
  id
  name
  profileImageUrl
  coverImageUrl
  isOrgDefault
  ...BrandingThemePlaceholderPreview_brandingTheme
}
    ${BrandingThemePlaceholderPreview_BrandingThemeFragmentDoc}`;
export const JobBrandingTemplateSelect_PortalTemplateFragmentDoc = gql`
    fragment JobBrandingTemplateSelect_portalTemplate on PortalTemplate {
  id
  name
}
    `;
export const MemberSelect_UserInfoFragmentDoc = gql`
    fragment MemberSelect_userInfo on UserInfo {
  userId
  userName
  employee {
    id
    fullName
    slackImageUrl
  }
}
    `;
export const SmartEmployeeSelectFragmentDoc = gql`
    fragment SmartEmployeeSelect on Employee {
  id
  fullName
  givenName
  familyName
  slackImageUrl
  email
  isArchived
  isAtsDisabled
  hasAtsId
  isDisabled
  orgId
}
    `;
export const OptionRenderer_EmployeesFragmentDoc = gql`
    fragment OptionRenderer_employees on Employee {
  id
  fullName
  givenName
  familyName
  slackImageUrl
  email
  isArchived
  isAtsDisabled
  hasAtsId
  isDisabled
  orgId
}
    `;
export const UseCandidateSmartOptions_CandidateCoordinatorDataFragmentDoc = gql`
    fragment useCandidateSmartOptions_candidateCoordinatorData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const UseCandidateSmartOptions_CandidateRecruiterDataFragmentDoc = gql`
    fragment useCandidateSmartOptions_candidateRecruiterData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const UseCandidateSmartOptions_CandidateSourcerDataFragmentDoc = gql`
    fragment useCandidateSmartOptions_candidateSourcerData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const UseCandidateSmartOptions_PreviousCandidateInterviewersDataFragmentDoc = gql`
    fragment useCandidateSmartOptions_previousCandidateInterviewersData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const UseJobSmartOptions_JobCoordinatorDataFragmentDoc = gql`
    fragment useJobSmartOptions_jobCoordinatorData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const UseJobSmartOptions_JobRecruiterDataFragmentDoc = gql`
    fragment useJobSmartOptions_jobRecruiterData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const UseJobSmartOptions_JobSourcerDataFragmentDoc = gql`
    fragment useJobSmartOptions_jobSourcerData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const UseTaskSmartOptions_TaskAssigneeDataFragmentDoc = gql`
    fragment useTaskSmartOptions_taskAssigneeData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const UseTaskSmartOptions_TaskCreatorDataFragmentDoc = gql`
    fragment useTaskSmartOptions_taskCreatorData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const UseTaskSmartOptions_TaskDebriefDataFragmentDoc = gql`
    fragment useTaskSmartOptions_taskDebriefData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const UseTaskSmartOptions_TaskSubscriberDataFragmentDoc = gql`
    fragment useTaskSmartOptions_taskSubscriberData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const UseTaskSmartOptions_TaskInterviewersDataFragmentDoc = gql`
    fragment useTaskSmartOptions_taskInterviewersData on Employee {
  ...OptionRenderer_employees
}
    ${OptionRenderer_EmployeesFragmentDoc}`;
export const ZoomUserFragmentFragmentDoc = gql`
    fragment ZoomUserFragment on ZoomUser {
  orgId
  zoomUserId
  status
  email
  employeeId
  firstName
  lastName
}
    `;
export const RecentlyUsedZoomUsersEmployeePrefFragmentDoc = gql`
    fragment RecentlyUsedZoomUsersEmployeePref on RecentlyUsedZoomUsersPref {
  zoomUsers {
    ...ZoomUserFragment
  }
}
    ${ZoomUserFragmentFragmentDoc}`;
export const SubscribersSelect_ThisEmployeeFragmentDoc = gql`
    fragment SubscribersSelect_thisEmployee on Employee {
  id
  fullName
  slackImageUrl
}
    `;
export const EmployeeFacePile_EmployeeFragmentDoc = gql`
    fragment EmployeeFacePile_employee on Employee {
  id
  fullName
  slackImageUrl
}
    `;
export const SubscribersSelect_EmployeeFragmentDoc = gql`
    fragment SubscribersSelect_employee on Employee {
  id
  ...EmployeeFacePile_employee
}
    ${EmployeeFacePile_EmployeeFragmentDoc}`;
export const TemplateFragmentDoc = gql`
    fragment Template on Template {
  id
  name
  subject
  body
  isOrganizationDefault
  updatedAt
  attachments {
    name
    path
    size
  }
  type
  createdAt
}
    `;
export const EmployeePrefDataFragmentDoc = gql`
    fragment EmployeePrefData on EmployeePref {
  orgId
  prefName
  json
  boolValue
  stringValue
  intValue
  stringArray
  jsonArray
  employeeId
  timestamp
}
    `;
export const CandidateAtsLatestAvailabilityFragmentFragmentDoc = gql`
    fragment CandidateAtsLatestAvailabilityFragment on Candidate {
  atsLatestAvailability {
    intervals {
      startAt
      endAt
    }
    jobName
    jobStageName
    timezone
    originalText
  }
}
    `;
export const OrgPrefsDataFragmentDoc = gql`
    fragment OrgPrefsData on OrgPref {
  orgId
  prefName
  json
  boolValue
  stringValue
  intValue
  stringArray
  jsonArray
  isLocked
  timestamp
  duration {
    seconds
    nanos
  }
  jsonPref {
    ... on InterviewLimit {
      type
      limit
    }
    ... on MinimumInterviewerLoadLimitOrgPref {
      interviews
      minutes
    }
    ... on InterviewMeetingLocationSettingPref {
      interviewMeetingLocationType
      dynamicHost
      hostEmployeeId
      remoteVideoMeetingHostUserId
      customLocation
    }
  }
}
    `;
export const CompanyHolidaysFragmentDoc = gql`
    fragment CompanyHolidays on CompanyHoliday {
  id
  start: startAt
  end: endAt
  countries {
    id
    value
  }
}
    `;
export const AshbyHealthCheckBanner_AshbyFragmentDoc = gql`
    fragment AshbyHealthCheckBanner_ashby on AshbyIntegrationHealth {
  isHealthy
  isInstalled
}
    `;
export const GemHealthCheckBanner_GemFragmentDoc = gql`
    fragment GemHealthCheckBanner_gem on GemIntegrationHealth {
  isInstalled
  isHealthy
  canReadUsers
  canReadUser
  canReadJobs
  canReadJob
  canReadJobStages
  canReadJobStageForJob
  canReadJobPostings
  canReadUserRoles
  canReadInterviews
  canReadInterview
  canReadCandidates
  canReadCandidate
  canReadActivityFeed
  canReadApplications
  canReadApplication
}
    `;
export const GoogleHealthCheckBanner_GoogleFragmentDoc = gql`
    fragment GoogleHealthCheckBanner_google on GoogleIntegrationHealth {
  isHealthy
  isInstalled
  canReadDirectory
  canReadCalendars
  canReadMeetingRooms
}
    `;
export const GreenhouseHealthCheckBanner_GreenhouseFragmentDoc = gql`
    fragment GreenhouseHealthCheckBanner_greenhouse on GreenhouseIntegrationHealth {
  isHealthy
  isInstalled
  canReadUsers
  canReadUser
  canReadJobs
  canReadJob
  canReadJobStages
  canReadJobStageForJob
  canReadJobPostings
  canReadUserRoles
  canReadEmailTemplates
  canReadInterviews
  canReadInterview
  canReadCandidates
  canReadCandidate
  canReadActivityFeed
  canReadApplications
  canReadApplication
  canReadUserPermission
}
    `;
export const LeverHealthCheckBanner_LeverFragmentDoc = gql`
    fragment LeverHealthCheckBanner_lever on LeverIntegrationHealth {
  isHealthy
  isInstalled
  canReadUsers
  canReadJobs
  canReadCandidates
  canReadApplications
  canReadArchiveReasons
  canReadPanels
  canReadStages
  canReadDeletedCandidates
}
    `;
export const MicrosoftHealthCheckBanner_MicrosoftGccHighFragmentDoc = gql`
    fragment MicrosoftHealthCheckBanner_microsoftGccHigh on MicrosoftIntegrationHealth {
  isHealthy
  isInstalled
  canReadDirectory
  canReadCalendars
  canReadMeetingRooms
}
    `;
export const MicrosoftHealthCheckBanner_MicrosoftFragmentDoc = gql`
    fragment MicrosoftHealthCheckBanner_microsoft on MicrosoftIntegrationHealth {
  isHealthy
  isInstalled
  canReadDirectory
  canReadCalendars
  canReadMeetingRooms
}
    `;
export const ZoomHealthCheckBanner_ZoomFragmentDoc = gql`
    fragment ZoomHealthCheckBanner_zoom on ZoomIntegrationHealth {
  isHealthy
  isInstalled
}
    `;
export const InterviewCardHeader_JobFragmentDoc = gql`
    fragment InterviewCardHeader_job on Job {
  id
  atsId
}
    `;
export const JobStageInterview_JobFragmentDoc = gql`
    fragment JobStageInterview_job on Job {
  id
  ...InterviewCardHeader_job
}
    ${InterviewCardHeader_JobFragmentDoc}`;
export const InterviewGroupOld_JobFragmentDoc = gql`
    fragment InterviewGroupOld_job on Job {
  id
  ...JobStageInterview_job
}
    ${JobStageInterview_JobFragmentDoc}`;
export const InterviewGroup_JobFragmentDoc = gql`
    fragment InterviewGroup_job on Job {
  id
  ...InterviewGroupOld_job
  ...JobStageInterview_job
}
    ${InterviewGroupOld_JobFragmentDoc}
${JobStageInterview_JobFragmentDoc}`;
export const InterviewPlan_JobFragmentDoc = gql`
    fragment InterviewPlan_job on Job {
  id
  atsId
  ...InterviewGroup_job
}
    ${InterviewGroup_JobFragmentDoc}`;
export const JobStageSelect_JobFragmentDoc = gql`
    fragment JobStageSelect_job on Job {
  id
  jobStages(input: {includeCustom: false}) {
    id
    name
  }
}
    `;
export const QueueSelect_SelectedTaskQueueFragmentDoc = gql`
    fragment QueueSelect_selectedTaskQueue on TaskQueue {
  id
  name
  memberCount
}
    `;
export const QueueSelect_DefaultTaskQueueFragmentDoc = gql`
    fragment QueueSelect_defaultTaskQueue on TaskQueue {
  id
  ...QueueSelect_selectedTaskQueue
}
    ${QueueSelect_SelectedTaskQueueFragmentDoc}`;
export const CreateTaskStep_ApplicationFragmentDoc = gql`
    fragment CreateTaskStep_application on Application {
  id
  jobStage {
    id
    name
  }
  job {
    id
    ...InterviewPlan_job
    ...JobStageSelect_job
    jobSettings {
      defaultTaskQueue {
        ...QueueSelect_defaultTaskQueue
      }
    }
  }
  candidate {
    id
    coordinator {
      id
      fullName
    }
    recruiter {
      id
      fullName
    }
  }
  atsApplication {
    atsId
    canMoveJobStage
  }
}
    ${InterviewPlan_JobFragmentDoc}
${JobStageSelect_JobFragmentDoc}
${QueueSelect_DefaultTaskQueueFragmentDoc}`;
export const BasicTaskModal_ApplicationFragmentDoc = gql`
    fragment BasicTaskModal_application on Application {
  id
  ...CreateTaskStep_application
  jobStage {
    id
  }
  candidate {
    id
    timezone
  }
}
    ${CreateTaskStep_ApplicationFragmentDoc}`;
export const AvailabilityTaskModal_ApplicationFragmentDoc = gql`
    fragment AvailabilityTaskModal_application on Application {
  id
  candidate {
    id
    timezone
    coordinator {
      id
    }
  }
  jobStage {
    id
    job {
      id
    }
  }
}
    `;
export const SelfScheduleTaskModal_ApplicationFragmentDoc = gql`
    fragment SelfScheduleTaskModal_application on Application {
  id
  candidate {
    id
    timezone
    coordinator {
      id
    }
  }
  jobStage {
    id
  }
  job {
    id
  }
}
    `;
export const UseApplicationJobStageSchedulable_ApplicationFragmentDoc = gql`
    fragment useApplicationJobStageSchedulable_application on Application {
  id
  jobStageId
  atsApplication {
    atsId
    notSchedulableReason
    canMoveJobStage
  }
}
    `;
export const CreateTaskActions_ApplicationFragmentDoc = gql`
    fragment CreateTaskActions_application on Application {
  id
  status
  ...BasicTaskModal_application
  ...AvailabilityTaskModal_application
  ...SelfScheduleTaskModal_application
  ...useApplicationJobStageSchedulable_application
}
    ${BasicTaskModal_ApplicationFragmentDoc}
${AvailabilityTaskModal_ApplicationFragmentDoc}
${SelfScheduleTaskModal_ApplicationFragmentDoc}
${UseApplicationJobStageSchedulable_ApplicationFragmentDoc}`;
export const NonSchedulableJobStageAlert_ApplicationFragmentDoc = gql`
    fragment NonSchedulableJobStageAlert_application on Application {
  id
  jobStageId
  atsApplication {
    atsId
    atsFields {
      ... on WorkdayApplication {
        currentStepName
        __typename
      }
      __typename
    }
  }
  jobStage {
    id
    name
  }
  ...useApplicationJobStageSchedulable_application
}
    ${UseApplicationJobStageSchedulable_ApplicationFragmentDoc}`;
export const ApplicationSidebarCreateTaskButton_ApplicationFragmentDoc = gql`
    fragment ApplicationSidebarCreateTaskButton_application on Application {
  ...CreateTaskActions_application
  ...NonSchedulableJobStageAlert_application
}
    ${CreateTaskActions_ApplicationFragmentDoc}
${NonSchedulableJobStageAlert_ApplicationFragmentDoc}`;
export const ApplicationSidebarCreateTaskActions_ApplicationFragmentDoc = gql`
    fragment ApplicationSidebarCreateTaskActions_application on Application {
  ...CreateTaskActions_application
  ...NonSchedulableJobStageAlert_application
}
    ${CreateTaskActions_ApplicationFragmentDoc}
${NonSchedulableJobStageAlert_ApplicationFragmentDoc}`;
export const TaskList_ApplicationFragmentDoc = gql`
    fragment TaskList_application on Application {
  id
  ...ApplicationSidebarCreateTaskActions_application
}
    ${ApplicationSidebarCreateTaskActions_ApplicationFragmentDoc}`;
export const CandidateTimezoneSelect_CandidateFragmentDoc = gql`
    fragment CandidateTimezoneSelect_candidate on Candidate {
  id
  timezone
}
    `;
export const SelectButtonLabel_EmployeeFragmentDoc = gql`
    fragment SelectButtonLabel_employee on Employee {
  id
  fullName
  slackImageUrl
}
    `;
export const CoordinatorSelect_CandidateFragmentDoc = gql`
    fragment CoordinatorSelect_candidate on Candidate {
  id
  coordinator {
    id
    ...SelectButtonLabel_employee
  }
}
    ${SelectButtonLabel_EmployeeFragmentDoc}`;
export const RecruiterSelect_CandidateFragmentDoc = gql`
    fragment RecruiterSelect_candidate on Candidate {
  id
  recruiter {
    id
    ...SelectButtonLabel_employee
  }
}
    ${SelectButtonLabel_EmployeeFragmentDoc}`;
export const SourcerSelect_CandidateFragmentDoc = gql`
    fragment SourcerSelect_candidate on Candidate {
  id
  sourcer {
    id
    ...SelectButtonLabel_employee
  }
}
    ${SelectButtonLabel_EmployeeFragmentDoc}`;
export const CandidateInfoSidebar_CandidateFragmentDoc = gql`
    fragment CandidateInfoSidebar_candidate on Candidate {
  id
  ...CandidateTimezoneSelect_candidate
  ...CoordinatorSelect_candidate
  ...RecruiterSelect_candidate
  ...SourcerSelect_candidate
}
    ${CandidateTimezoneSelect_CandidateFragmentDoc}
${CoordinatorSelect_CandidateFragmentDoc}
${RecruiterSelect_CandidateFragmentDoc}
${SourcerSelect_CandidateFragmentDoc}`;
export const ApplicationSidebarContent_ApplicationFragmentDoc = gql`
    fragment ApplicationSidebarContent_application on Application {
  ...ApplicationSidebarCreateTaskButton_application
  ...TaskList_application
  id
  isTaskEnabled
  jobStage {
    id
  }
  job {
    ...JobStageSelect_job
  }
  candidate {
    id
    ...CandidateInfoSidebar_candidate
  }
  tasks {
    id
    jobStage {
      id
    }
  }
}
    ${ApplicationSidebarCreateTaskButton_ApplicationFragmentDoc}
${TaskList_ApplicationFragmentDoc}
${JobStageSelect_JobFragmentDoc}
${CandidateInfoSidebar_CandidateFragmentDoc}`;
export const ApplicationSidebarHeader_ApplicationFragmentDoc = gql`
    fragment ApplicationSidebarHeader_application on Application {
  id
  candidate {
    id
    fullName
  }
  job {
    id
    name
    location
  }
}
    `;
export const ScheduleContent_ApplicationFragmentDoc = gql`
    fragment ScheduleContent_application on Application {
  id
  status
}
    `;
export const ScheduleTaskFlagCompact_TaskFlagFragmentDoc = gql`
    fragment ScheduleTaskFlagCompact_taskFlag on TaskFlag {
  id
  flagType
}
    `;
export const ScheduleTaskTagCompact_TaskTagFragmentDoc = gql`
    fragment ScheduleTaskTagCompact_taskTag on TaskTag {
  id
  name
}
    `;
export const CancelScheduleDialog_InterviewsFragmentDoc = gql`
    fragment CancelScheduleDialog_interviews on ApplicationStageInterview {
  id
  startAt
}
    `;
export const ScheduleContentActions_InterviewsFragmentDoc = gql`
    fragment ScheduleContentActions_interviews on ApplicationStageInterview {
  id
  startAt
  endAt
  ...CancelScheduleDialog_interviews
}
    ${CancelScheduleDialog_InterviewsFragmentDoc}`;
export const ScheduleContentInterviewMenu_InterviewFragmentDoc = gql`
    fragment ScheduleContentInterviewMenu_interview on ApplicationStageInterview {
  id
  startAt
  googleEventUrl
  videoMeetingUrl
  zoomHostUserId
  zoomDialInInfo
  zoomMeetingId
  zoomPassword
  zoomPstnPassword
  codingInterviewUrl
  isHiddenFromCandidate
  videoMeetingHostEmployee {
    id
    fullName
  }
}
    `;
export const ScheduleContentInterviewHeader_InterviewFragmentDoc = gql`
    fragment ScheduleContentInterviewHeader_interview on ApplicationStageInterview {
  id
  name
  startAt
  endAt
  googleEventUrl
  videoMeetingUrl
  zoomDialInInfo
  codingInterviewUrl
  isHiddenFromCandidate
  ...ScheduleContentInterviewMenu_interview
}
    ${ScheduleContentInterviewMenu_InterviewFragmentDoc}`;
export const AdjustInterviewerRsvp_InterviewFragmentDoc = gql`
    fragment AdjustInterviewerRsvp_interview on ApplicationStageInterview {
  id
  startAt
  endAt
  name
  isHiddenFromCandidate
  applicationStageInterviewInterviewers {
    interviewerId
    role
    responseStatus
    isOptional
    declines {
      reason
      comment
    }
    interviewer {
      id
      slackImageUrl
      fullName
      givenName
    }
  }
}
    `;
export const ScheduleContentInterviewDetailsInterviewer_InterviewFragmentDoc = gql`
    fragment ScheduleContentInterviewDetailsInterviewer_interview on ApplicationStageInterview {
  id
  ...AdjustInterviewerRsvp_interview
}
    ${AdjustInterviewerRsvp_InterviewFragmentDoc}`;
export const ScheduleContentInterviewDetailsInterviewer_InterviewerFragmentDoc = gql`
    fragment ScheduleContentInterviewDetailsInterviewer_interviewer on ApplicationStageInterviewInterviewer {
  applicationStageInterviewId
  interviewerId
  interviewer {
    id
    fullName
    slackImageUrl
  }
  responseStatus
  calendarEventNote
  isOptional
  role
}
    `;
export const ScheduleContentInterviewDetailsMeetingRoom_MeetingRoomFragmentDoc = gql`
    fragment ScheduleContentInterviewDetailsMeetingRoom_meetingRoom on ApplicationStageInterviewRoom {
  applicationStageInterviewId
  meetingRoomId
  responseStatus
  meetingRoom {
    id
    name
    description
  }
}
    `;
export const ScheduleContentInterviewDetails_InterviewFragmentDoc = gql`
    fragment ScheduleContentInterviewDetails_interview on ApplicationStageInterview {
  id
  ...ScheduleContentInterviewDetailsInterviewer_interview
  applicationStageInterviewInterviewers {
    interviewerId
    ...ScheduleContentInterviewDetailsInterviewer_interviewer
  }
  applicationStageInterviewRooms {
    applicationStageInterviewId
    meetingRoomId
    ...ScheduleContentInterviewDetailsMeetingRoom_meetingRoom
  }
}
    ${ScheduleContentInterviewDetailsInterviewer_InterviewFragmentDoc}
${ScheduleContentInterviewDetailsInterviewer_InterviewerFragmentDoc}
${ScheduleContentInterviewDetailsMeetingRoom_MeetingRoomFragmentDoc}`;
export const ScheduleContentInterview_InterviewFragmentDoc = gql`
    fragment ScheduleContentInterview_interview on ApplicationStageInterview {
  id
  ...ScheduleContentInterviewHeader_interview
  ...ScheduleContentInterviewDetails_interview
}
    ${ScheduleContentInterviewHeader_InterviewFragmentDoc}
${ScheduleContentInterviewDetails_InterviewFragmentDoc}`;
export const GroupInterviewEventsByDate_InterviewsFragmentDoc = gql`
    fragment groupInterviewEventsByDate_interviews on ApplicationStageInterview {
  id
  startAt
}
    `;
export const GetCandidateEventByInterviewId_InterviewsFragmentDoc = gql`
    fragment getCandidateEventByInterviewId_interviews on ApplicationStageInterview {
  id
  startAt
  endAt
}
    `;
export const ScheduleContentInterviewMenu_CandidateEventFragmentDoc = gql`
    fragment ScheduleContentInterviewMenu_candidateEvent on ApplicationStageCandidateEvent {
  id
  googleEventUrl
}
    `;
export const ScheduleContentInterviewHeader_CandidateEventFragmentDoc = gql`
    fragment ScheduleContentInterviewHeader_candidateEvent on ApplicationStageCandidateEvent {
  id
  ...ScheduleContentInterviewMenu_candidateEvent
}
    ${ScheduleContentInterviewMenu_CandidateEventFragmentDoc}`;
export const ScheduleContentInterview_CandidateEventFragmentDoc = gql`
    fragment ScheduleContentInterview_candidateEvent on ApplicationStageCandidateEvent {
  id
  responseStatus
  ...ScheduleContentInterviewHeader_candidateEvent
  ...ScheduleContentInterviewHeader_candidateEvent
}
    ${ScheduleContentInterviewHeader_CandidateEventFragmentDoc}`;
export const GetCandidateEventByInterviewId_CandidateEventsFragmentDoc = gql`
    fragment getCandidateEventByInterviewId_candidateEvents on ApplicationStageCandidateEvent {
  id
  startAt
  endAt
}
    `;
export const HoldForCandidateCommunications_ApplicationStageFragmentDoc = gql`
    fragment HoldForCandidateCommunications_applicationStage on ApplicationStage {
  id
  isHoldForRsvp
  isReadyForCandidateComms
}
    `;
export const CancelScheduleDialog_ApplicationStageFragmentDoc = gql`
    fragment CancelScheduleDialog_applicationStage on ApplicationStage {
  id
  minStartAt
  maxEndAt
}
    `;
export const ScheduleContentActions_ApplicationStageFragmentDoc = gql`
    fragment ScheduleContentActions_applicationStage on ApplicationStage {
  id
  selfScheduleOptionsId
  frozenAt
  task {
    id
    availabilityRequests {
      id
    }
  }
  ...CancelScheduleDialog_applicationStage
}
    ${CancelScheduleDialog_ApplicationStageFragmentDoc}`;
export const ScheduleContent_ScheduleFragmentDoc = gql`
    fragment ScheduleContent_schedule on ApplicationStage {
  id
  createdAt
  frozenAt
  creator {
    id
    fullName
  }
  applicationStageInterviews(input: {orderBy: {startAt: ASC}, isCancelled: false}) {
    id
    ...ScheduleContentActions_interviews
    ...ScheduleContentInterview_interview
    ...groupInterviewEventsByDate_interviews
    ...getCandidateEventByInterviewId_interviews
  }
  applicationStageCandidateEvents(input: {orderBy: {createdAt: DESC}}) {
    id
    ...ScheduleContentInterview_candidateEvent
    ...getCandidateEventByInterviewId_candidateEvents
  }
  ...HoldForCandidateCommunications_applicationStage
  ...ScheduleContentActions_applicationStage
}
    ${ScheduleContentActions_InterviewsFragmentDoc}
${ScheduleContentInterview_InterviewFragmentDoc}
${GroupInterviewEventsByDate_InterviewsFragmentDoc}
${GetCandidateEventByInterviewId_InterviewsFragmentDoc}
${ScheduleContentInterview_CandidateEventFragmentDoc}
${GetCandidateEventByInterviewId_CandidateEventsFragmentDoc}
${HoldForCandidateCommunications_ApplicationStageFragmentDoc}
${ScheduleContentActions_ApplicationStageFragmentDoc}`;
export const DebriefContentActions_DebriefFragmentDoc = gql`
    fragment DebriefContentActions_debrief on ApplicationDebrief {
  id
  applicationId
  startAt
  endAt
  task {
    id
  }
}
    `;
export const DebriefContentMenu_DebriefFragmentDoc = gql`
    fragment DebriefContentMenu_debrief on ApplicationDebrief {
  id
  startAt
  googleEventUrl
  videoMeetingUrl
  applicationId
  task {
    id
  }
  zoomMeetingId
  zoomHostUserId
  zoomPassword
  zoomPstnPassword
  zoomDialIns
}
    `;
export const DebriefContentHeader_DebriefFragmentDoc = gql`
    fragment DebriefContentHeader_debrief on ApplicationDebrief {
  id
  name
  startAt
  endAt
  videoMeetingUrl
  ...DebriefContentMenu_debrief
}
    ${DebriefContentMenu_DebriefFragmentDoc}`;
export const DebriefContentDetailsInterviewer_DebriefFragmentDoc = gql`
    fragment DebriefContentDetailsInterviewer_debrief on ApplicationDebriefAttendee {
  applicationDebriefId
  interviewerId
  interviewer {
    id
    fullName
    slackImageUrl
  }
  responseStatus
  isOptional
}
    `;
export const DebriefContentDetailsMeetingRoom_MeetingRoomFragmentDoc = gql`
    fragment DebriefContentDetailsMeetingRoom_meetingRoom on ApplicationDebriefRoom {
  applicationDebriefId
  meetingRoomId
  responseStatus
  meetingRoom {
    id
    name
    description
  }
}
    `;
export const DebriefContentDetails_DebriefFragmentDoc = gql`
    fragment DebriefContentDetails_debrief on ApplicationDebrief {
  id
  attendees {
    applicationDebriefId
    ...DebriefContentDetailsInterviewer_debrief
  }
  meetingRooms {
    applicationDebriefId
    meetingRoomId
    ...DebriefContentDetailsMeetingRoom_meetingRoom
  }
}
    ${DebriefContentDetailsInterviewer_DebriefFragmentDoc}
${DebriefContentDetailsMeetingRoom_MeetingRoomFragmentDoc}`;
export const DebriefContentMeeting_DebriefFragmentDoc = gql`
    fragment DebriefContentMeeting_debrief on ApplicationDebrief {
  id
  ...DebriefContentHeader_debrief
  ...DebriefContentDetails_debrief
}
    ${DebriefContentHeader_DebriefFragmentDoc}
${DebriefContentDetails_DebriefFragmentDoc}`;
export const DebriefContentEntity_DebriefFragmentDoc = gql`
    fragment DebriefContentEntity_debrief on ApplicationDebrief {
  id
  ...DebriefContentActions_debrief
  ...DebriefContentMeeting_debrief
  startAt
  endAt
  creator {
    fullName
  }
  createdAt
}
    ${DebriefContentActions_DebriefFragmentDoc}
${DebriefContentMeeting_DebriefFragmentDoc}`;
export const TaskCard_TaskFragmentDoc = gql`
    fragment TaskCard_task on Task {
  id
  count
  status
  application {
    id
    ...ScheduleContent_application
  }
  assignee {
    id
    fullName
    slackImageUrl
  }
  flags {
    id
    resolvedAt
    ...ScheduleTaskFlagCompact_taskFlag
  }
  tags {
    id
    ...ScheduleTaskTagCompact_taskTag
  }
  isUrgent
  schedules {
    id
    createdAt
    ...ScheduleContent_schedule
  }
  debriefs {
    id
    createdAt
    ...DebriefContentEntity_debrief
  }
  jobStage {
    id
    name
  }
  availabilityRequests {
    id
  }
  ...TaskName_task
}
    ${ScheduleContent_ApplicationFragmentDoc}
${ScheduleTaskFlagCompact_TaskFlagFragmentDoc}
${ScheduleTaskTagCompact_TaskTagFragmentDoc}
${ScheduleContent_ScheduleFragmentDoc}
${DebriefContentEntity_DebriefFragmentDoc}
${TaskName_TaskFragmentDoc}`;
export const TaskList_TaskFragmentDoc = gql`
    fragment TaskList_task on Task {
  id
  count
  flags {
    id
    resolvedAt
  }
  status
  ...TaskCard_task
}
    ${TaskCard_TaskFragmentDoc}`;
export const JobStageDetails_JobFragmentDoc = gql`
    fragment JobStageDetails_job on Job {
  id
  jobStages(input: {includeCustom: false}) {
    id
    name
    index
  }
}
    `;
export const UpcomingScheduleAlert_ApplicationStagesFragmentDoc = gql`
    fragment UpcomingScheduleAlert_applicationStages on ApplicationStage {
  id
  jobStageId
  startAt
  endAt
  hasInterviewerDeclined
  task {
    id
    flags {
      id
      flagType
      resolvedAt
    }
  }
  applicationStageInterviews {
    id
    startAt
    endAt
    applicationStageInterviewInterviewers {
      interviewerId
      declines {
        applicationStageInterviewId
      }
    }
  }
}
    `;
export const ApplicationAlert_ApplicationFragmentDoc = gql`
    fragment ApplicationAlert_application on Application {
  id
  status
  applicationStages(input: {isCancelled: false}) {
    ...UpcomingScheduleAlert_applicationStages
  }
}
    ${UpcomingScheduleAlert_ApplicationStagesFragmentDoc}`;
export const ApplicationDebriefs_ApplicationFragmentDoc = gql`
    fragment ApplicationDebriefs_application on Application {
  id
  status
  debriefs: applicationDebriefs(
    input: {orderBy: {createdAt: DESC}, isCancelled: false}
  ) {
    id
    task {
      id
    }
    ...DebriefContentEntity_debrief
  }
  ...useApplicationJobStageSchedulable_application
}
    ${DebriefContentEntity_DebriefFragmentDoc}
${UseApplicationJobStageSchedulable_ApplicationFragmentDoc}`;
export const MoveJobStageButton_ApplicationFragmentDoc = gql`
    fragment MoveJobStageButton_application on Application {
  id
  jobStage {
    id
    name
  }
  job {
    id
    aliasedJs: jobStages {
      id
      name
      index
      schedulable
      atsId
      jobId
    }
  }
  candidate {
    id
  }
  atsApplication {
    atsId
    canMoveJobStage
  }
}
    `;
export const MoveToNextStageAlert_ApplicationFragmentDoc = gql`
    fragment MoveToNextStageAlert_application on Application {
  id
  candidate {
    id
  }
  job {
    id
    name
    MoveToNextStageAlert_jobStages: jobStages {
      id
      name
      index
    }
  }
  jobStage {
    id
    name
    index
  }
  tasks {
    id
    status
    jobStage {
      id
    }
  }
  atsApplication {
    atsId
    canMoveJobStage
  }
}
    `;
export const OpenTaskOnOtherJobStageAlert_ApplicationFragmentDoc = gql`
    fragment OpenTaskOnOtherJobStageAlert_application on Application {
  tasks {
    id
    status
    jobStage {
      id
      name
    }
  }
}
    `;
export const ApplicationContent_ApplicationFragmentDoc = gql`
    fragment ApplicationContent_application on Application {
  id
  jobStageId
  jobStage {
    id
    name
  }
  job {
    id
    name
    ...JobStageDetails_job
  }
  tasks {
    id
    jobStage {
      id
    }
  }
  ...ApplicationAlert_application
  ...ApplicationDebriefs_application
  ...MoveJobStageButton_application
  ...TaskList_application
  ...MoveToNextStageAlert_application
  ...OpenTaskOnOtherJobStageAlert_application
}
    ${JobStageDetails_JobFragmentDoc}
${ApplicationAlert_ApplicationFragmentDoc}
${ApplicationDebriefs_ApplicationFragmentDoc}
${MoveJobStageButton_ApplicationFragmentDoc}
${TaskList_ApplicationFragmentDoc}
${MoveToNextStageAlert_ApplicationFragmentDoc}
${OpenTaskOnOtherJobStageAlert_ApplicationFragmentDoc}`;
export const CandidateHeader_CandidateFullNameFragmentDoc = gql`
    fragment CandidateHeader_candidateFullName on Candidate {
  fullName
}
    `;
export const ApplicationNoteEditor_ApplicationFragmentDoc = gql`
    fragment ApplicationNoteEditor_application on Application {
  id
  internalNote
}
    `;
export const ApplicationNote_ApplicationFragmentDoc = gql`
    fragment ApplicationNote_application on Application {
  id
  internalNote
  ...ApplicationNoteEditor_application
}
    ${ApplicationNoteEditor_ApplicationFragmentDoc}`;
export const CandidatePortalActionsMenu_ApplicationFragmentDoc = gql`
    fragment CandidatePortalActionsMenu_application on Application {
  id
  jobId
  jobStageId
  isPortalAccessible
}
    `;
export const CandidatePortalNoteEditor_CandidatePortalNoteFragmentDoc = gql`
    fragment CandidatePortalNoteEditor_candidatePortalNote on CandidatePortalNote {
  id
  content
  lastNotificationSentAt
}
    `;
export const CandidatePortalNote_CandidatePortalNoteFragmentDoc = gql`
    fragment CandidatePortalNote_candidatePortalNote on CandidatePortalNote {
  id
  content
  updatedAt
  ...CandidatePortalNoteEditor_candidatePortalNote
}
    ${CandidatePortalNoteEditor_CandidatePortalNoteFragmentDoc}`;
export const CandidatePortalSettings_ApplicationFragmentDoc = gql`
    fragment CandidatePortalSettings_application on Application {
  ...CandidatePortalActionsMenu_application
  id
  isPortalAccessible
  job {
    org {
      slugs {
        orgId
        slug
      }
    }
  }
  candidatePortalNote {
    id
    ...CandidatePortalNote_candidatePortalNote
  }
}
    ${CandidatePortalActionsMenu_ApplicationFragmentDoc}
${CandidatePortalNote_CandidatePortalNoteFragmentDoc}`;
export const AboutSettings_ApplicationFragmentDoc = gql`
    fragment AboutSettings_application on Application {
  atsApplication {
    atsType
    atsId
    atsUrl
  }
  jobStage {
    id
    name
  }
  conversations: existingConversations {
    id
    remoteId
    remoteName
    isArchived
  }
}
    `;
export const CandidateInfo_ApplicationFragmentDoc = gql`
    fragment CandidateInfo_application on Application {
  existingConversations(input: {isCancelled: false}) {
    id
    remoteId
    remoteName
    isArchived
  }
  ...CandidatePortalSettings_application
  atsApplication {
    atsId
    atsUrl
  }
  ...AboutSettings_application
  atsType
}
    ${CandidatePortalSettings_ApplicationFragmentDoc}
${AboutSettings_ApplicationFragmentDoc}`;
export const CandidateSecondaryContent_ApplicationFragmentDoc = gql`
    fragment CandidateSecondaryContent_application on Application {
  ...ApplicationNote_application
  ...CandidateInfo_application
}
    ${ApplicationNote_ApplicationFragmentDoc}
${CandidateInfo_ApplicationFragmentDoc}`;
export const ApplicationList_ApplicationFragmentDoc = gql`
    fragment ApplicationList_application on Application {
  id
  jobStageId
  status
  state {
    isSchedulable
    isNotScheduleable
    isUnscheduled
    isAvailabilityRequested
    isReadyToSchedule
    isCanceled
    isOngoing
    isScheduled
    isCompleted
    isScheduledInAts
    isCompletedInAts
    isCandidateDeclined
    isInterviewerDeclined
    isSelfScheduleRequested
  }
  job {
    id
    name
    jobStagesAggregate
  }
  jobStage {
    id
    name
    index
  }
}
    `;
export const CandidateSecondaryContent_ApplicationsFragmentDoc = gql`
    fragment CandidateSecondaryContent_applications on Application {
  ...ApplicationList_application
}
    ${ApplicationList_ApplicationFragmentDoc}`;
export const HiringTeam_CandidateFragmentDoc = gql`
    fragment HiringTeam_candidate on Candidate {
  id
  ...CoordinatorSelect_candidate
  ...RecruiterSelect_candidate
  ...SourcerSelect_candidate
}
    ${CoordinatorSelect_CandidateFragmentDoc}
${RecruiterSelect_CandidateFragmentDoc}
${SourcerSelect_CandidateFragmentDoc}`;
export const EditCandidatePhoneNumberModal_CandidateFragmentDoc = gql`
    fragment EditCandidatePhoneNumberModal_candidate on Candidate {
  id
  preferredPhoneNumber
  phoneNumbers
}
    `;
export const AboutSettings_CandidateFragmentDoc = gql`
    fragment AboutSettings_candidate on Candidate {
  id
  timezone
  updatedAt
  preferredEmail
  email
  linkedinUrl
  preferredPhoneNumber
  phoneNumbers
  atsCandidate {
    atsUrl
  }
  ...CandidateTimezoneSelect_candidate
  ...EditCandidatePhoneNumberModal_candidate
}
    ${CandidateTimezoneSelect_CandidateFragmentDoc}
${EditCandidatePhoneNumberModal_CandidateFragmentDoc}`;
export const ConflictInterestSection_CandidateFragmentDoc = gql`
    fragment ConflictInterestSection_candidate on Candidate {
  id
  conflictOfInterestEmployees {
    id
    ...Employee
  }
}
    ${EmployeeFragmentDoc}`;
export const CandidateInfo_CandidateFragmentDoc = gql`
    fragment CandidateInfo_candidate on Candidate {
  id
  email
  preferredEmail
  atsCandidate {
    atsId
    atsUrl
    primaryEmail
  }
  linkedinUrl
  updatedAt
  timezone
  ...HiringTeam_candidate
  ...AboutSettings_candidate
  ...CandidateTimezoneSelect_candidate
  ...CoordinatorSelect_candidate
  ...RecruiterSelect_candidate
  ...SourcerSelect_candidate
  ...ConflictInterestSection_candidate
}
    ${HiringTeam_CandidateFragmentDoc}
${AboutSettings_CandidateFragmentDoc}
${CandidateTimezoneSelect_CandidateFragmentDoc}
${CoordinatorSelect_CandidateFragmentDoc}
${RecruiterSelect_CandidateFragmentDoc}
${SourcerSelect_CandidateFragmentDoc}
${ConflictInterestSection_CandidateFragmentDoc}`;
export const CandidateSecondaryContent_CandidateFragmentDoc = gql`
    fragment CandidateSecondaryContent_candidate on Candidate {
  ...CandidateInfo_candidate
}
    ${CandidateInfo_CandidateFragmentDoc}`;
export const UseIsInterviewPlanValid_JobStageFragmentDoc = gql`
    fragment useIsInterviewPlanValid_jobStage on JobStage {
  id
  jobStageInterviewGroups {
    id
    jobStageInterviews {
      id
      interviewType
      isHiddenFromCandidate
      jobStageInterviewSeats {
        id
        freeformSeat {
          jobStageInterviewSeatEmployees {
            employeeId
            employee {
              id
              fullName
              isArchived
              isDirectoryDisabled
              isAtsDisabled
              hasAtsId
            }
          }
        }
        moduleSeat {
          interviewModuleId
        }
        linkedSeat {
          linkedJobStageInterviewSeatId
        }
      }
    }
  }
}
    `;
export const UseInterviewPlanHasInterviewHiddenFromCandidate_InterviewPlanFragmentDoc = gql`
    fragment useInterviewPlanHasInterviewHiddenFromCandidate_interviewPlan on JobStage {
  id
  jobStageInterviewGroups {
    id
    jobStageInterviews {
      id
      isHiddenFromCandidate
    }
  }
}
    `;
export const BaseCandidateAvailabilityOption_InterviewPlanFragmentDoc = gql`
    fragment BaseCandidateAvailabilityOption_interviewPlan on JobStage {
  id
  ...useIsInterviewPlanValid_jobStage
  ...useInterviewPlanHasInterviewHiddenFromCandidate_interviewPlan
}
    ${UseIsInterviewPlanValid_JobStageFragmentDoc}
${UseInterviewPlanHasInterviewHiddenFromCandidate_InterviewPlanFragmentDoc}`;
export const CandidateAvailabilityOption_InterviewPlanFragmentDoc = gql`
    fragment CandidateAvailabilityOption_interviewPlan on JobStage {
  id
  ...BaseCandidateAvailabilityOption_interviewPlan
}
    ${BaseCandidateAvailabilityOption_InterviewPlanFragmentDoc}`;
export const CandidateInterviewRowFragmentDoc = gql`
    fragment CandidateInterviewRow on ApplicationStageInterview {
  id
  startAt
  endAt
  isCancelled
  name
  applicationStageInterviewInterviewers {
    applicationStageInterviewId
    interviewerId
    responseStatus
    role
    interviewer {
      id
      fullName
      slackImageUrl
    }
  }
  googleEventUrl
}
    `;
export const CandidateEventRowFragmentDoc = gql`
    fragment CandidateEventRow on ApplicationStageCandidateEvent {
  id
  isCancelled
  startAt
  endAt
  responseStatus
  name
  googleEventId
  googleEventUrl
  googleCalendarId
}
    `;
export const CandidateApplicationStageFragmentDoc = gql`
    fragment CandidateApplicationStage on ApplicationStage {
  id
  isCancelled
  timezone
  startAt
  endAt
  hasCandidateDeclined
  hasInterviewerDeclined
  hasCandidateTentative
  hasInterviewerTentative
  jobStage {
    id
    name
  }
  applicationStageInterviews(input: {orderBy: {startAt: ASC}}) {
    ...CandidateInterviewRow
  }
  applicationStageCandidateEvents(input: {orderBy: {createdAt: DESC}}) {
    ...CandidateEventRow
  }
}
    ${CandidateInterviewRowFragmentDoc}
${CandidateEventRowFragmentDoc}`;
export const CandidateApplicationStagesRowApplicationFragmentDoc = gql`
    fragment CandidateApplicationStagesRowApplication on Application {
  job {
    id
    name
  }
  upcomingApplicationStage {
    id
    minStartAt
  }
}
    `;
export const CandidateApplicationStagesFragmentDoc = gql`
    fragment CandidateApplicationStages on Application {
  id
  applicationStages(input: {orderBy: {createdAt: DESC}}) {
    ...CandidateApplicationStage
  }
  ...CandidateApplicationStagesRowApplication
}
    ${CandidateApplicationStageFragmentDoc}
${CandidateApplicationStagesRowApplicationFragmentDoc}`;
export const CandidateHeader_AtsCandidateFragmentDoc = gql`
    fragment CandidateHeader_atsCandidate on AtsCandidate {
  atsFields {
    ... on LeverCandidate {
      __typename
      origin
    }
  }
}
    `;
export const JobStageFragmentFragmentDoc = gql`
    fragment JobStageFragment on JobStage {
  id
  name
  jobId
  schedulable
  atsId
  index
}
    `;
export const ApplicationItemBaseFragmentDoc = gql`
    fragment ApplicationItemBase on Application {
  id
  atsType
  status
  jobStageCount
  state {
    isSchedulable
    isNotScheduleable
    isUnscheduled
    isAvailabilityRequested
    isReadyToSchedule
    isCanceled
    isOngoing
    isScheduled
    isCompleted
    isScheduledInAts
    isCompletedInAts
    isCandidateDeclined
    isInterviewerDeclined
    isSelfScheduleRequested
    isHoldForRsvp
    isReadyForCandidateComms
  }
  upcomingApplicationStage {
    id
    startAt
    endAt
    selfScheduleOptionsId
    minStartAt
    applicationStageInterviews(input: {orderBy: {startAt: ASC}, isCancelled: false}) {
      id
      startAt
      endAt
    }
  }
}
    `;
export const ApplicationJobFragmentDoc = gql`
    fragment ApplicationJob on Job {
  id
  name
}
    `;
export const ApplicationJobStageFragmentDoc = gql`
    fragment ApplicationJobStage on JobStage {
  id
  name
  index
}
    `;
export const ApplicationCandidateBaseFragmentDoc = gql`
    fragment ApplicationCandidateBase on Candidate {
  id
  fullName
  lastActivityAt
  email
}
    `;
export const ApplicationCandidateEmployeeFragmentDoc = gql`
    fragment ApplicationCandidateEmployee on Employee {
  id
  fullName
  slackImageUrl
}
    `;
export const TaskCount_TasksFragmentDoc = gql`
    fragment TaskCount_tasks on Task {
  id
  canceledAt
  jobStage {
    id
  }
}
    `;
export const ApplicationItemFragmentDoc = gql`
    fragment ApplicationItem on Application {
  ...ApplicationItemBase
  job {
    ...ApplicationJob
  }
  jobStage {
    ...ApplicationJobStage
  }
  candidate {
    ...ApplicationCandidateBase
    coordinator {
      ...ApplicationCandidateEmployee
    }
    recruiter {
      ...ApplicationCandidateEmployee
    }
  }
  tasks {
    id
    ...TaskCount_tasks
  }
}
    ${ApplicationItemBaseFragmentDoc}
${ApplicationJobFragmentDoc}
${ApplicationJobStageFragmentDoc}
${ApplicationCandidateBaseFragmentDoc}
${ApplicationCandidateEmployeeFragmentDoc}
${TaskCount_TasksFragmentDoc}`;
export const CandidatePageFiltersEmployeePrefFragmentDoc = gql`
    fragment CandidatePageFiltersEmployeePref on CandidatePageFiltersPref {
  coordinatorEmployeeIds
  jobIds
  jobStageNames
  recruiterEmployeeIds
  showInactiveApplications
}
    `;
export const ApplicationStatusIcon_ApplicationFragmentDoc = gql`
    fragment ApplicationStatusIcon_application on Application {
  id
  status
  state {
    isOngoing
    isCompleted
    isScheduled
    isUnscheduled
    isCandidateDeclined
    isInterviewerDeclined
    isScheduledInAts
    isCompletedInAts
  }
  atsType
}
    `;
export const Candidate_CandidateFragmentDoc = gql`
    fragment Candidate_candidate on Candidate {
  id
  fullName
  atsCandidate {
    atsId
    atsFields {
      ... on LeverCandidate {
        origin
      }
    }
  }
}
    `;
export const CandidateWithStatusIcon_ApplicationFragmentDoc = gql`
    fragment CandidateWithStatusIcon_application on Application {
  ...ApplicationStatusIcon_application
  candidate {
    id
    fullName
    ...Candidate_candidate
  }
}
    ${ApplicationStatusIcon_ApplicationFragmentDoc}
${Candidate_CandidateFragmentDoc}`;
export const RemoveMemberModal_InterviewModuleMemberFragmentDoc = gql`
    fragment RemoveMemberModal_interviewModuleMember on InterviewModuleMember {
  employeeId
  interviewModuleId
  stats {
    upcoming
  }
}
    `;
export const SetModuleMemberAsTrained_InterviewModuleMemberFragmentDoc = gql`
    fragment SetModuleMemberAsTrained_interviewModuleMember on InterviewModuleMember {
  employeeId
  interviewModuleId
  stats {
    upcoming
  }
}
    `;
export const DeleteModuleLogModal_ModuleMemberInterviewFragmentDoc = gql`
    fragment deleteModuleLogModal_moduleMemberInterview on ModuleMemberInterview {
  id
  trainingRole
  status
}
    `;
export const UpdateModuleLogModal_ModuleMemberInterviewFragmentDoc = gql`
    fragment updateModuleLogModal_moduleMemberInterview on ModuleMemberInterview {
  id
  interviewDate
  trainingRole
  status
  ... on ModuleMemberApplicationStageInterviewLog {
    applicationStageInterview {
      id
    }
    __typename
  }
  __typename
  ...deleteModuleLogModal_moduleMemberInterview
}
    ${DeleteModuleLogModal_ModuleMemberInterviewFragmentDoc}`;
export const Interviewfeedback_InterviewfeedbackFragmentDoc = gql`
    fragment Interviewfeedback_interviewfeedback on ApplicationStageInterviewFeedback {
  reviewer {
    id
    fullName
    slackImageUrl
  }
  decisionType
  note
  reviewerId
  reviewedAt
}
    `;
export const ApprovalFeedback_FeedbackFragmentDoc = gql`
    fragment ApprovalFeedback_feedback on ApplicationStageInterviewFeedback {
  reviewerId
  decisionType
  reviewedAt
  reviewer {
    id
    fullName
  }
}
    `;
export const ApprovalFeedback_TraineeFeedbackFragmentDoc = gql`
    fragment ApprovalFeedback_traineeFeedback on ApplicationStageInterviewFeedback {
  ...ApprovalFeedback_feedback
}
    ${ApprovalFeedback_FeedbackFragmentDoc}`;
export const ApprovalFeedback_TrainedFeedbackFragmentDoc = gql`
    fragment ApprovalFeedback_trainedFeedback on ApplicationStageInterviewFeedback {
  ...ApprovalFeedback_feedback
}
    ${ApprovalFeedback_FeedbackFragmentDoc}`;
export const ApprovalFeedback_SchedulerFeedbackFragmentDoc = gql`
    fragment ApprovalFeedback_schedulerFeedback on ApplicationStageInterviewFeedback {
  ...ApprovalFeedback_feedback
}
    ${ApprovalFeedback_FeedbackFragmentDoc}`;
export const InterviewLogInterview_InterviewFragmentDoc = gql`
    fragment InterviewLogInterview_interview on ApplicationStageInterview {
  id
  name
  startAt
  endAt
  googleEventUrl
  applicationStageInterviewInterviewers {
    responseStatus
    interviewerId
  }
  applicationStage {
    id
    taskId
    application {
      id
      candidate {
        id
        fullName
      }
      job {
        id
        name
      }
      jobStage {
        id
        name
      }
    }
  }
  traineeFeedback: traineeFeedback(reviewerType: TRAINEE) {
    ...Interviewfeedback_interviewfeedback
    ...ApprovalFeedback_traineeFeedback
  }
  trainedFeedback: traineeFeedback(reviewerType: TRAINED) {
    ...Interviewfeedback_interviewfeedback
    ...ApprovalFeedback_trainedFeedback
  }
  schedulerFeedback: traineeFeedback(reviewerType: SCHEDULER) {
    ...Interviewfeedback_interviewfeedback
    ...ApprovalFeedback_schedulerFeedback
  }
  isHiddenFromCandidate
}
    ${Interviewfeedback_InterviewfeedbackFragmentDoc}
${ApprovalFeedback_TraineeFeedbackFragmentDoc}
${ApprovalFeedback_TrainedFeedbackFragmentDoc}
${ApprovalFeedback_SchedulerFeedbackFragmentDoc}`;
export const InterviewLog_InterviewLogFragmentDoc = gql`
    fragment InterviewLog_interviewLog on ModuleMemberInterview {
  id
  trainingRole
  ... on ModuleMemberApplicationStageInterviewLog {
    applicationStageInterview {
      ...InterviewLogInterview_interview
    }
    __typename
  }
  ... on ModuleMemberManualLog {
    id
    __typename
  }
  __typename
}
    ${InterviewLogInterview_InterviewFragmentDoc}`;
export const InterviewModuleMemberModuleLogRow_ModuleMemberInterviewFragmentDoc = gql`
    fragment interviewModuleMemberModuleLogRow_moduleMemberInterview on ModuleMemberInterview {
  id
  interviewDate
  trainingRole
  status
  ... on ModuleMemberApplicationStageInterviewLog {
    applicationStageInterview {
      startAt
      endAt
      id
    }
    __typename
  }
  ...updateModuleLogModal_moduleMemberInterview
  ...InterviewLog_interviewLog
  __typename
}
    ${UpdateModuleLogModal_ModuleMemberInterviewFragmentDoc}
${InterviewLog_InterviewLogFragmentDoc}`;
export const EditTrainigPlan_ModuleMemberFragmentDoc = gql`
    fragment EditTrainigPlan_moduleMember on InterviewModuleMember {
  employeeId
  interviewModuleId
  status
  shadowsRequired
  reverseShadowsRequired
  stats {
    completedAsReverseShadow
    completedAsShadow
    manualCompletedAsShadow
    manualCompletedAsReverseShadow
  }
  interviewModule {
    id
    shadowsRequired
    reverseShadowsRequired
    shouldFastTrackTraining
    graduateFromShadowApprovalType
    graduateFromReverseShadowApprovalType
  }
}
    `;
export const AddModuleLog_ModuleMemberFragmentDoc = gql`
    fragment AddModuleLog_moduleMember on InterviewModuleMember {
  id
  employeeId
  interviewModuleId
  shadowsRequired
  status
  reverseShadowsRequired
  stats {
    completedAsReverseShadow
    completedAsShadow
    manualCompletedAsReverseShadow
    manualCompletedAsShadow
  }
  ...EditTrainigPlan_moduleMember
  ...getReverseShadowPendingCount_moduleMember
}
    ${EditTrainigPlan_ModuleMemberFragmentDoc}
${GetReverseShadowPendingCount_ModuleMemberFragmentDoc}`;
export const DeleteModuleLogModal_ModuleMemberFragmentDoc = gql`
    fragment deleteModuleLogModal_moduleMember on InterviewModuleMember {
  employeeId
  interviewModuleId
  shadowsRequired
  status
  stats {
    completedAsShadow
    manualCompletedAsShadow
  }
}
    `;
export const UpdateModuleLogModal_ModuleMemberFragmentDoc = gql`
    fragment updateModuleLogModal_moduleMember on InterviewModuleMember {
  id
  employeeId
  interviewModuleId
  shadowsRequired
  status
  reverseShadowsRequired
  stats {
    completedAsShadow
    completedAsReverseShadow
    manualCompletedAsShadow
    manualCompletedAsReverseShadow
  }
  ...deleteModuleLogModal_moduleMember
  ...getReverseShadowCompletedCount_moduleMember
}
    ${DeleteModuleLogModal_ModuleMemberFragmentDoc}
${GetReverseShadowCompletedCount_ModuleMemberFragmentDoc}`;
export const InterviewLogInterview_ModuleMemberFragmentDoc = gql`
    fragment InterviewLogInterview_moduleMember on InterviewModuleMember {
  id
  employeeId
  interviewModuleId
}
    `;
export const InterviewLog_ModuleMemberFragmentDoc = gql`
    fragment InterviewLog_moduleMember on InterviewModuleMember {
  id
  employeeId
  interviewModuleId
  ...InterviewLogInterview_moduleMember
}
    ${InterviewLogInterview_ModuleMemberFragmentDoc}`;
export const InterviewModuleMemberModuleLogRow_ModuleMemberFragmentDoc = gql`
    fragment interviewModuleMemberModuleLogRow_moduleMember on InterviewModuleMember {
  id
  employeeId
  status
  interviewModuleId
  stats {
    currentTrainingSessionStatus
  }
  ...updateModuleLogModal_moduleMember
  ...InterviewLog_moduleMember
}
    ${UpdateModuleLogModal_ModuleMemberFragmentDoc}
${InterviewLog_ModuleMemberFragmentDoc}`;
export const InterviewModuleMemberModuleLog_ModuleMemberFragmentDoc = gql`
    fragment InterviewModuleMemberModuleLog_moduleMember on InterviewModuleMember {
  id
  interviewModuleId
  employeeId
  employee {
    id
    fullName
    slackImageUrl
  }
  interviewModule {
    id
    name
  }
  stats {
    currentTrainingSessionStatus
  }
  ...AddModuleLog_moduleMember
  ...interviewModuleMemberModuleLogRow_moduleMember
}
    ${AddModuleLog_ModuleMemberFragmentDoc}
${InterviewModuleMemberModuleLogRow_ModuleMemberFragmentDoc}`;
export const ActionButtons_ModuleMemberFragmentDoc = gql`
    fragment ActionButtons_moduleMember on InterviewModuleMember {
  id
  interviewModuleId
  employeeId
  status
  shadowsRequired
  reverseShadowsRequired
  pendingApprovalApplicationStageInterview {
    id
  }
  stats {
    currentTrainingSessionStatus
    completedAsShadow
    completedAsReverseShadow
    manualCompletedAsShadow
    manualCompletedAsReverseShadow
  }
  ...getReverseShadowCompletedCount_moduleMember
  ...getShadowCompletedCount_moduleMember
  ...getReverseShadowPendingCount_moduleMember
}
    ${GetReverseShadowCompletedCount_ModuleMemberFragmentDoc}
${GetShadowCompletedCount_ModuleMemberFragmentDoc}
${GetReverseShadowPendingCount_ModuleMemberFragmentDoc}`;
export const PendingApprovalAlert_ModuleMemberFragmentDoc = gql`
    fragment PendingApprovalAlert_moduleMember on InterviewModuleMember {
  id
  employeeId
  interviewModuleId
  pendingApprovalApplicationStageInterview {
    id
    traineeFeedback: traineeFeedback(reviewerType: TRAINEE) {
      reviewerId
      reviewedAt
    }
    trainedFeedback: traineeFeedback(reviewerType: TRAINED) {
      reviewerId
      reviewedAt
    }
  }
}
    `;
export const PendingApproval_ModuleMemberFragmentDoc = gql`
    fragment PendingApproval_moduleMember on InterviewModuleMember {
  id
  interviewModuleId
  employeeId
  status
  stats {
    currentTrainingSessionStatus
  }
  pendingApprovalApplicationStageInterview {
    id
    startAt
    ...InterviewLogInterview_interview
  }
  ...ActionButtons_moduleMember
  ...InterviewLogInterview_moduleMember
  ...PendingApprovalAlert_moduleMember
}
    ${InterviewLogInterview_InterviewFragmentDoc}
${ActionButtons_ModuleMemberFragmentDoc}
${InterviewLogInterview_ModuleMemberFragmentDoc}
${PendingApprovalAlert_ModuleMemberFragmentDoc}`;
export const IsModuleMemberEmployeePaused_ModuleMemberFragmentDoc = gql`
    fragment isModuleMemberEmployeePaused_ModuleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  employee {
    id
    isPaused
  }
}
    `;
export const IsModuleMemberPauseIndefinitely_ModuleMemberFragmentDoc = gql`
    fragment isModuleMemberPauseIndefinitely_ModuleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  isPausedIndefinitely
}
    `;
export const GetModuleMemberEmployeePausedText_ModuleMemberFragmentDoc = gql`
    fragment getModuleMemberEmployeePausedText_ModuleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  employee {
    id
    isPaused
    timezone
    interviewPauseDates {
      start
      end
    }
  }
}
    `;
export const GetModuleMemberPauseText_ModuleMemberFragmentDoc = gql`
    fragment getModuleMemberPauseText_ModuleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  pausedUntil
  employee {
    id
    timezone
  }
}
    `;
export const ModuleMemberPausedChip_ModuleMemberFragmentDoc = gql`
    fragment ModuleMemberPausedChip_moduleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  isPausedIndefinitely
  isPaused
  pausedUntil
  id
  employee {
    id
    isPaused
    timezone
    interviewPauseDates {
      start
      end
    }
  }
  ...isModuleMemberEmployeePaused_ModuleMember
  ...isModuleMembreOrEmployeePaused_ModuleMember
  ...isModuleMemberPauseIndefinitely_ModuleMember
  ...getModuleMemberEmployeePausedText_ModuleMember
  ...getModuleMemberPauseText_ModuleMember
}
    ${IsModuleMemberEmployeePaused_ModuleMemberFragmentDoc}
${IsModuleMembreOrEmployeePaused_ModuleMemberFragmentDoc}
${IsModuleMemberPauseIndefinitely_ModuleMemberFragmentDoc}
${GetModuleMemberEmployeePausedText_ModuleMemberFragmentDoc}
${GetModuleMemberPauseText_ModuleMemberFragmentDoc}`;
export const ModuleMemberPendingChip_ModuleMemberFragmentDoc = gql`
    fragment ModuleMemberPendingChip_moduleMember on InterviewModuleMember {
  stats {
    currentTrainingSessionStatus
  }
  id
}
    `;
export const InterviewModuleMemberHeader_ModuleMemberFragmentDoc = gql`
    fragment InterviewModuleMemberHeader_moduleMember on InterviewModuleMember {
  id
  interviewModuleId
  employeeId
  employee {
    id
    fullName
    slackImageUrl
  }
  interviewModule {
    id
    name
  }
  status
  ...ModuleMemberPausedChip_moduleMember
  ...ModuleMemberPendingChip_moduleMember
}
    ${ModuleMemberPausedChip_ModuleMemberFragmentDoc}
${ModuleMemberPendingChip_ModuleMemberFragmentDoc}`;
export const EditKeyDatesModal_ModuleMemberFragmentDoc = gql`
    fragment EditKeyDatesModal_moduleMember on InterviewModuleMember {
  id
  interviewModuleId
  employeeId
  trainedAt
  createdAt
}
    `;
export const InterviewModuleMemberKeyDates_ModuleMemberFragmentDoc = gql`
    fragment InterviewModuleMemberKeyDates_moduleMember on InterviewModuleMember {
  id
  interviewModuleId
  employeeId
  shadowStartAt
  reverseShadowStartAt
  employee {
    id
    fullName
    slackImageUrl
  }
  shadowsRequired
  reverseShadowsRequired
  interviewModule {
    id
    name
  }
  status
  createdAt
  trainedAt
  timeToTrain
  ...EditKeyDatesModal_moduleMember
}
    ${EditKeyDatesModal_ModuleMemberFragmentDoc}`;
export const PauseMemberModal_ModuleMemberFragmentDoc = gql`
    fragment pauseMemberModal_moduleMember on InterviewModuleMember {
  id
  employeeId
  interviewModuleId
  isPausedIndefinitely
  isPaused
  pausedUntil
  employee {
    id
    timezone
  }
}
    `;
export const ModuleLoadPauseRow_ModuleMemberFragmentDoc = gql`
    fragment moduleLoadPauseRow_moduleMember on InterviewModuleMember {
  id
  interviewModuleId
  employeeId
  weeklyNumInterviewLimit
  ...pauseMemberModal_moduleMember
  employee {
    id
    isPaused
    timezone
    interviewPauseDates {
      start
      end
    }
  }
  ...isModuleMemberEmployeePaused_ModuleMember
  ...isModuleMembreOrEmployeePaused_ModuleMember
  ...isModuleMemberPauseIndefinitely_ModuleMember
}
    ${PauseMemberModal_ModuleMemberFragmentDoc}
${IsModuleMemberEmployeePaused_ModuleMemberFragmentDoc}
${IsModuleMembreOrEmployeePaused_ModuleMemberFragmentDoc}
${IsModuleMemberPauseIndefinitely_ModuleMemberFragmentDoc}`;
export const ModuleLoad_ModuleMemberFragmentDoc = gql`
    fragment moduleLoad_moduleMember on InterviewModuleMember {
  id
  interviewModuleId
  employeeId
  weeklyNumInterviewLimit
  employee {
    id
    isPaused
    timezone
    interviewPauseDates {
      start
      end
    }
  }
  ...pauseMemberModal_moduleMember
  ...moduleLoadPauseRow_moduleMember
  ...isModuleMemberEmployeePaused_ModuleMember
  ...isModuleMembreOrEmployeePaused_ModuleMember
  ...isModuleMemberPauseIndefinitely_ModuleMember
  ...getModuleMemberEmployeePausedText_ModuleMember
  ...getModuleMemberPauseText_ModuleMember
}
    ${PauseMemberModal_ModuleMemberFragmentDoc}
${ModuleLoadPauseRow_ModuleMemberFragmentDoc}
${IsModuleMemberEmployeePaused_ModuleMemberFragmentDoc}
${IsModuleMembreOrEmployeePaused_ModuleMemberFragmentDoc}
${IsModuleMemberPauseIndefinitely_ModuleMemberFragmentDoc}
${GetModuleMemberEmployeePausedText_ModuleMemberFragmentDoc}
${GetModuleMemberPauseText_ModuleMemberFragmentDoc}`;
export const AddToTrainingPlan_ModuleMemberFragmentDoc = gql`
    fragment AddToTrainingPlan_moduleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  shadowsRequired
  reverseShadowsRequired
  stats {
    completedAsShadow
    completedAsReverseShadow
    manualCompletedAsShadow
    manualCompletedAsReverseShadow
  }
}
    `;
export const SetModuleMemberAsTrainee_ModuleMemberFragmentDoc = gql`
    fragment SetModuleMemberAsTrainee_moduleMember on InterviewModuleMember {
  id
  employeeId
  interviewModuleId
  stats {
    upcoming
  }
  ...AddToTrainingPlan_moduleMember
}
    ${AddToTrainingPlan_ModuleMemberFragmentDoc}`;
export const TrainingPlanSection_ModuleMemberFragmentDoc = gql`
    fragment TrainingPlanSection_moduleMember on InterviewModuleMember {
  employeeId
  interviewModuleId
  status
  shadowsRequired
  reverseShadowsRequired
  trainedAt
  stats {
    currentTrainingSessionStatus
  }
  ...getReverseShadowCompletedCount_moduleMember
  ...getReverseShadowPendingCount_moduleMember
  ...getShadowCompletedCount_moduleMember
  ...getShadowPendingCount_moduleMember
  ...SetModuleMemberAsTrainee_moduleMember
  ...EditTrainigPlan_moduleMember
}
    ${GetReverseShadowCompletedCount_ModuleMemberFragmentDoc}
${GetReverseShadowPendingCount_ModuleMemberFragmentDoc}
${GetShadowCompletedCount_ModuleMemberFragmentDoc}
${GetShadowPendingCount_ModuleMemberFragmentDoc}
${SetModuleMemberAsTrainee_ModuleMemberFragmentDoc}
${EditTrainigPlan_ModuleMemberFragmentDoc}`;
export const AddMembersModal_ModuleFragmentDoc = gql`
    fragment AddMembersModal_module on InterviewModule {
  id
  shadowsRequired
  reverseShadowsRequired
}
    `;
export const MembersList_ModuleFragmentDoc = gql`
    fragment MembersList_module on InterviewModule {
  id
  membersCount
  membersTrainedCount
  ...AddMembersModal_module
}
    ${AddMembersModal_ModuleFragmentDoc}`;
export const ModuleMemberSidePanelFragmentDoc = gql`
    fragment ModuleMemberSidePanel on InterviewModuleMember {
  id
  employeeId
  interviewModuleId
  ...PendingApproval_moduleMember
  ...InterviewModuleMemberHeader_moduleMember
  ...InterviewModuleMemberKeyDates_moduleMember
  ...moduleLoad_moduleMember
  ...TrainingPlanSection_moduleMember
  interviewModule {
    id
    ...MembersList_module
  }
}
    ${PendingApproval_ModuleMemberFragmentDoc}
${InterviewModuleMemberHeader_ModuleMemberFragmentDoc}
${InterviewModuleMemberKeyDates_ModuleMemberFragmentDoc}
${ModuleLoad_ModuleMemberFragmentDoc}
${TrainingPlanSection_ModuleMemberFragmentDoc}
${MembersList_ModuleFragmentDoc}`;
export const DebriefCommunications_CandidateFragmentDoc = gql`
    fragment DebriefCommunications_candidate on Candidate {
  id
  preferredPhoneNumber
}
    `;
export const AttendeeAttributesFragmentDoc = gql`
    fragment AttendeeAttributes on Employee {
  id
  fullName
  slackImageUrl
  familyName
  isArchived
  isAtsDisabled
  isDirectoryDisabled
  hasAtsId
}
    `;
export const AtsDebriefStageFromApplicationFragmentDoc = gql`
    fragment AtsDebriefStageFromApplication on AtsApplication {
  atsId
  atsScheduledInterviews {
    atsId
    startAt
    endAt
    atsInterviewDefinition {
      atsId
      atsJobStage {
        atsId
        name
      }
    }
    interviewers {
      atsUser {
        id
        employee {
          ...AttendeeAttributes
        }
      }
    }
  }
}
    ${AttendeeAttributesFragmentDoc}`;
export const AttributesFilterFragmentDoc = gql`
    fragment AttributesFilter on EmployeeAttributeName {
  id
  name
  type
  values {
    id
    value
  }
}
    `;
export const InterviewModuleRow_InterviewModuleFragmentDoc = gql`
    fragment InterviewModuleRow_InterviewModule on InterviewModule {
  id
  name
  membersTrainedCount
  membersInTrainingCount
  membersInPendingApprovalCount
  shadowsRequired
  reverseShadowsRequired
  trained: interviewModuleMembers(input: $trainedEmployeesInput) {
    items {
      interviewModuleId
      employeeId
      employee {
        slackImageUrl
        fullName
        id
      }
    }
  }
  untrained: interviewModuleMembers(input: $untrainedEmployeesInput) {
    items {
      interviewModuleId
      employeeId
      employee {
        slackImageUrl
        fullName
        id
      }
    }
  }
}
    `;
export const InterviewModuleList_InterviewModuleFragmentDoc = gql`
    fragment InterviewModuleList_InterviewModule on InterviewModule {
  id
  ...InterviewModuleRow_InterviewModule
}
    ${InterviewModuleRow_InterviewModuleFragmentDoc}`;
export const UpdateModuleName_TrainingScheduleFragmentDoc = gql`
    fragment UpdateModuleName_trainingSchedule on InterviewModule {
  id
  name
  shouldFastTrackTraining
}
    `;
export const UpdateModuleName_InterviewModuleFragmentDoc = gql`
    fragment UpdateModuleName_interviewModule on InterviewModule {
  id
  name
}
    `;
export const InterviewModuleSetup_InterviewModuleFragmentDoc = gql`
    fragment InterviewModuleSetup_interviewModule on InterviewModule {
  id
  name
  weeklyNumInterviewLimit
  shadowsRequired
  reverseShadowsRequired
  shouldFastTrackTraining
  graduateFromReverseShadowApprovalType
  graduateFromReverseShadowCustomApprovers
  graduateFromShadowApprovalType
  graduateFromShadowCustomApprovers
  ...UpdateModuleName_trainingSchedule
  ...UpdateModuleName_interviewModule
}
    ${UpdateModuleName_TrainingScheduleFragmentDoc}
${UpdateModuleName_InterviewModuleFragmentDoc}`;
export const ModuleMemberTrainingStatus_ModuleMemberFragmentDoc = gql`
    fragment ModuleMemberTrainingStatus_moduleMember on InterviewModuleMember {
  interviewModuleId
  employeeId
  isPausedIndefinitely
  isPaused
  pausedUntil
  stats {
    currentTrainingSessionStatus
  }
  employee {
    id
    isPaused
    timezone
    isAtsDisabled
    isDirectoryDisabled
    hasAtsId
    interviewPauseDates {
      start
      end
    }
  }
  ...ModuleMemberPendingChip_moduleMember
  ...ModuleMemberPausedChip_moduleMember
}
    ${ModuleMemberPendingChip_ModuleMemberFragmentDoc}
${ModuleMemberPausedChip_ModuleMemberFragmentDoc}`;
export const ModuleMemberRow_ModuleMemberFragmentDoc = gql`
    fragment ModuleMemberRow_moduleMember on InterviewModuleMember {
  id
  ...ModuleMemberTrainingStatus_moduleMember
  status
  employeeId
  interviewModuleId
  shadowsRequired
  reverseShadowsRequired
  employee {
    id
    fullName
    slackImageUrl
    title
  }
  interviewModule {
    id
    name
  }
  stats {
    upcoming
    completedAsShadow
    completedAsReverseShadow
    completed
    completedAsInterviewer
    manualCompletedAsInterviewer
    manualCompletedAsShadow
    manualCompletedAsReverseShadow
  }
}
    ${ModuleMemberTrainingStatus_ModuleMemberFragmentDoc}`;
export const Members_MembersFragmentDoc = gql`
    fragment Members_members on InterviewModuleMember {
  interviewModuleId
  employeeId
  ...ModuleMemberRow_moduleMember
}
    ${ModuleMemberRow_ModuleMemberFragmentDoc}`;
export const TraineeFeedbackFragmentDoc = gql`
    fragment TraineeFeedback on ApplicationStageInterviewFeedback {
  trainee {
    id
    fullName
    slackImageUrl
  }
  reviewer {
    id
    fullName
    slackImageUrl
  }
  traineeId
  decisionType
  reviewerId
  note
  reviewedAt
  lastRequestedAt
  nagType
  lastRequestedBy {
    id
    fullName
  }
}
    `;
export const InterviewItemFragmentDoc = gql`
    fragment InterviewItem on ApplicationStageInterview {
  id
  name
  startAt
  endAt
  isCancelled
  googleEventUrl
  applicationStage {
    id
    application {
      id
      candidate {
        id
        fullName
      }
      job {
        id
        name
      }
      jobStage {
        id
        name
      }
    }
  }
  applicationStageInterviewInterviewers {
    interviewerId
    role
    responseStatus
    isOptional
    interviewer {
      id
      fullName
      slackImageUrl
    }
    declines {
      reason
      comment
      source
    }
  }
  applicationStageInterviewRooms {
    applicationStageInterviewId
    meetingRoomId
    responseStatus
  }
  traineeFeedback: traineeFeedback(reviewerType: TRAINEE) {
    ...TraineeFeedback
  }
  trainedFeedback: traineeFeedback(reviewerType: TRAINED) {
    ...TraineeFeedback
  }
  schedulerFeedback: traineeFeedback(reviewerType: SCHEDULER) {
    ...TraineeFeedback
  }
}
    ${TraineeFeedbackFragmentDoc}`;
export const InterviewMemberFragmentDoc = gql`
    fragment InterviewMember on InterviewModuleMember {
  interviewModuleId
  interviewModule {
    id
    name
  }
  reverseShadowsRequired
  shadowsRequired
  employeeId
  employee {
    slackImageUrl
    fullName
    id
    title
    isPaused
    timezone
    isAtsDisabled
    isArchived
    interviewPauseDates {
      start
      end
    }
  }
  status
  isPaused
  isPausedIndefinitely
  pausedUntil
  weeklyNumInterviewLimit
  stats {
    upcoming
    completed
    completedAsShadow
    completedAsReverseShadow
    trainingStatus
    trainingsToPass
    shadowOffset
    reverseShadowOffset
    currentTrainingSession
    currentTrainingSessionStatus
    manualCompletedAsShadow
    manualCompletedAsReverseShadow
  }
  shadowInterviews: applicationStageInterviews(
    input: {role: SHADOW, pageInput: {limit: 100, cursor: ""}}
  ) {
    items {
      ...InterviewItem
    }
  }
  reverseShadowInterviews: applicationStageInterviews(
    input: {role: REVERSE_SHADOW, pageInput: {limit: 100, cursor: ""}}
  ) {
    items {
      ...InterviewItem
    }
  }
}
    ${InterviewItemFragmentDoc}`;
export const MemberCardInterviewModuleFragmentDoc = gql`
    fragment MemberCardInterviewModule on InterviewModule {
  id
  name
}
    `;
export const BaseEditInterviewSeatModuleEmployeeSearchFragmentDoc = gql`
    fragment BaseEditInterviewSeatModuleEmployeeSearch on Employee {
  id
  fullName
  email
  orgId
  isDirectoryDisabled
  hasAtsId
  slackImageUrl
  timezone
  atsId
  isAtsDisabled
}
    `;
export const BaseEditInterviewSeatModuleInterviewModuleSearchFragmentDoc = gql`
    fragment BaseEditInterviewSeatModuleInterviewModuleSearch on InterviewModule {
  ...InterviewModule
  orgId
  membersCount
  membersTrainedCount
  membersInTrainingCount
  membersInReverseShadowCount
  membersInShadowCount
  membersInPendingApprovalCount
  graduateFromShadowApprovalType
  graduateFromReverseShadowApprovalType
  interviewModuleMembers(input: {pageInput: {limit: 500, offset: 0}}) {
    items {
      interviewModuleId
      employeeId
      status
      shadowsRequired
      reverseShadowsRequired
      pausedUntil
      stats {
        completedAsReverseShadow
        completedAsShadow
        shadowOffset
        reverseShadowOffset
      }
      employee {
        id
        fullName
        email
        slackImageUrl
        timezone
        attributes {
          id
          value
        }
        interviewPauseDates {
          start
          end
        }
      }
    }
  }
}
    ${InterviewModuleFragmentDoc}`;
export const EditModuleSeatInterviewModuleFragmentDoc = gql`
    fragment EditModuleSeatInterviewModule on InterviewModuleMember {
  interviewModuleId
  ...EmployeeAndAttributesFilter
}
    ${EmployeeAndAttributesFilterFragmentDoc}`;
export const EditModuleSeatInterviewStatsFragmentDoc = gql`
    fragment EditModuleSeatInterviewStats on InterviewModule {
  id
  name
  membersCount
  membersInReverseShadowCount
  membersInShadowCount
  membersTrainedCount
}
    `;
export const Modules_MemberModulesFragmentDoc = gql`
    fragment Modules_memberModules on InterviewModuleMember {
  interviewModuleId
  employeeId
  ...ModuleMemberRow_moduleMember
}
    ${ModuleMemberRow_ModuleMemberFragmentDoc}`;
export const ModulesList_EmployeeFragmentDoc = gql`
    fragment ModulesList_employee on Employee {
  id
  modulesTrainedCount
  modulesInTrainingCount
}
    `;
export const InterviewModulesTab_EmployeeFragmentDoc = gql`
    fragment InterviewModulesTab_employee on Employee {
  id
  ...ModulesList_employee
}
    ${ModulesList_EmployeeFragmentDoc}`;
export const AttributeEditRow_AttributeTagValueFragmentDoc = gql`
    fragment AttributeEditRow_attributeTagValue on EmployeeAttributeTagValue {
  id
  value
  parent: name {
    id
    type
    name
  }
}
    `;
export const AttributeEditRow_EmployeeAttributeParentFragmentDoc = gql`
    fragment AttributeEditRow_employeeAttributeParent on EmployeeAttributeName {
  id
  name
  type
  values {
    ...AttributeEditRow_attributeTagValue
  }
}
    ${AttributeEditRow_AttributeTagValueFragmentDoc}`;
export const AttributeSectionEditDialog_AttributeTagValueFragmentDoc = gql`
    fragment AttributeSectionEditDialog_attributeTagValue on EmployeeAttributeTagValue {
  id
  value
  parent: name {
    id
    type
    name
  }
}
    `;
export const InterviewerStatusAccordion_InterviewerFragmentDoc = gql`
    fragment InterviewerStatusAccordion_interviewer on Employee {
  id
  isAtsDisabled
  isArchived
  isDirectoryDisabled
  isDisabled
  isPaused
  hasAtsId
  interviewPauseDates {
    start
    end
  }
  loadAndLimit(input: {date: $date, timezone: $timezone}) {
    dailyLoad
    weeklyLoad
    lastWeeklyLoad
    dailyLoadMinutes
    weeklyLoadMinutes
    lastWeeklyLoadMinutes
    dailyInterviewLimit {
      type
      limit
    }
    weeklyInterviewLimit {
      type
      limit
    }
  }
  interviewModuleMembers(input: {pageInput: {limit: 500, offset: 0}}) {
    items {
      interviewModuleId
      employeeId
      isPaused
      interviewModule {
        id
        name
      }
    }
  }
}
    `;
export const AboutSectionEditDialog_InterviewerFragmentDoc = gql`
    fragment AboutSectionEditDialog_interviewer on Employee {
  id
  fullName
  givenName
  isArchived
  familyName
  email
  title
  linkedinUrl
  atsUser {
    atsId
    name
    email
    disabled: isDisabled
  }
  manager {
    id
    slackImageUrl
    fullName
  }
  attributes {
    id
    value
    parent: name {
      id
      name
      type
    }
  }
}
    `;
export const InterviewerAboutSection_InterviewerFragmentDoc = gql`
    fragment InterviewerAboutSection_interviewer on Employee {
  ...AboutSectionEditDialog_interviewer
  id
  fullName
  givenName
  familyName
  email
  title
  linkedinUrl
  profilePictureUrl {
    imageOriginal
    image128px
  }
  pronouns
  bio
  manager {
    id
    slackImageUrl
    fullName
  }
  atsUser {
    atsId
    name
    email
    disabled: isDisabled
  }
  attributes {
    id
    parent: name {
      id
      name
      type
    }
    value
  }
}
    ${AboutSectionEditDialog_InterviewerFragmentDoc}`;
export const InterviewerLoadBalancer_EmployeePrefsFragmentDoc = gql`
    fragment InterviewerLoadBalancer_employeePrefs on Employee {
  employeePrefs {
    orgId
    employeeId
    prefName
    json
  }
}
    `;
export const InterviewerWorkHours_WorkHoursFragmentDoc = gql`
    fragment InterviewerWorkHours_workHours on Employee {
  workHours {
    monday {
      start
      end
    }
    tuesday {
      start
      end
    }
    wednesday {
      start
      end
    }
    thursday {
      start
      end
    }
    friday {
      start
      end
    }
    saturday {
      start
      end
    }
    sunday {
      start
      end
    }
  }
}
    `;
export const AvailabilitySectionEditDialog_InterviewerFragmentDoc = gql`
    fragment AvailabilitySectionEditDialog_interviewer on Employee {
  id
  fullName
  useCalendarTimezone
  calendarTimezone
  timezone
  employeePrefs {
    orgId
    employeeId
    prefName
    json
  }
  ...InterviewerLoadBalancer_employeePrefs
  ...InterviewerWorkHours_workHours
}
    ${InterviewerLoadBalancer_EmployeePrefsFragmentDoc}
${InterviewerWorkHours_WorkHoursFragmentDoc}`;
export const AvailabilitySection_InterviewerFragmentDoc = gql`
    fragment AvailabilitySection_interviewer on Employee {
  id
  useCalendarTimezone
  calendarTimezone
  timezone
  interviewPauseDates {
    start
    end
  }
  loadAndLimit(input: {date: $date, timezone: $timezone}) {
    dailyInterviewLimit {
      type
      limit
    }
    weeklyInterviewLimit {
      type
      limit
    }
  }
  employeePrefs {
    orgId
    employeeId
    prefName
    json
  }
  workHours {
    monday {
      start
      end
    }
    tuesday {
      start
      end
    }
    wednesday {
      start
      end
    }
    thursday {
      start
      end
    }
    friday {
      start
      end
    }
    saturday {
      start
      end
    }
    sunday {
      start
      end
    }
  }
  ...AvailabilitySectionEditDialog_interviewer
}
    ${AvailabilitySectionEditDialog_InterviewerFragmentDoc}`;
export const AttributeEditRow_InterviewerAttributesFragmentDoc = gql`
    fragment AttributeEditRow_interviewerAttributes on EmployeeAttributeTagValue {
  ...AttributeEditRow_attributeTagValue
}
    ${AttributeEditRow_AttributeTagValueFragmentDoc}`;
export const AttributesSectionEditDialog_InterviewerFragmentDoc = gql`
    fragment AttributesSectionEditDialog_interviewer on Employee {
  fullName
  attributes {
    id
    value
    parent: name {
      id
      type
      name
    }
    ...AttributeEditRow_interviewerAttributes
  }
}
    ${AttributeEditRow_InterviewerAttributesFragmentDoc}`;
export const InterviewerAttributesSection_InterviewerFragmentDoc = gql`
    fragment InterviewerAttributesSection_interviewer on Employee {
  id
  attributes {
    id
    value
    parent: name {
      id
      type
      name
    }
  }
  ...AttributesSectionEditDialog_interviewer
}
    ${AttributesSectionEditDialog_InterviewerFragmentDoc}`;
export const TemporaryAvailabilityWorkHours_WorkHoursFragmentDoc = gql`
    fragment TemporaryAvailabilityWorkHours_workHours on TimeBlockWeek {
  monday {
    start
    end
  }
  tuesday {
    start
    end
  }
  wednesday {
    start
    end
  }
  thursday {
    start
    end
  }
  friday {
    start
    end
  }
  saturday {
    start
    end
  }
  sunday {
    start
    end
  }
}
    `;
export const TemporaryAvailabilityDialog_PlannedAvailabilityFragmentDoc = gql`
    fragment TemporaryAvailabilityDialog_plannedAvailability on EmployeePlannedAvailability {
  id
  employeeId
  startAt
  endAt
  timezone
  workHours {
    ...TemporaryAvailabilityWorkHours_workHours
  }
}
    ${TemporaryAvailabilityWorkHours_WorkHoursFragmentDoc}`;
export const PlannedAvailabilitySection_InterviewerFragmentDoc = gql`
    fragment PlannedAvailabilitySection_interviewer on Employee {
  id
  interviewPauseDates {
    start
    end
  }
  useCalendarTimezone
  calendarTimezone
  timezone
  plannedAvailabilities {
    id
    ...TemporaryAvailabilityDialog_plannedAvailability
  }
}
    ${TemporaryAvailabilityDialog_PlannedAvailabilityFragmentDoc}`;
export const ExternalProfileModalInterviewerFragmentDoc = gql`
    fragment ExternalProfileModalInterviewer on Employee {
  id
  givenName
  familyName
  fullName
  title
  linkedinUrl
  bio
  pronouns
  email
  externalProfilePicture {
    imageOriginal
  }
}
    `;
export const ExternalProfileEditModal_InterviewerFragmentDoc = gql`
    fragment ExternalProfileEditModal_interviewer on Employee {
  ...ExternalProfileModalInterviewer
}
    ${ExternalProfileModalInterviewerFragmentDoc}`;
export const ExternalProfileSection_InterviewerFragmentDoc = gql`
    fragment ExternalProfileSection_interviewer on Employee {
  ...ExternalProfileEditModal_interviewer
  id
  fullName
  title
  linkedinUrl
  bio
  pronouns
  email
  externalProfilePicture {
    imageOriginal
    image128px
  }
}
    ${ExternalProfileEditModal_InterviewerFragmentDoc}`;
export const InterviewModulesAccordion_InterviewerModulesFragmentDoc = gql`
    fragment InterviewModulesAccordion_interviewerModules on InterviewModuleMember {
  id
  employeeId
  interviewModuleId
  status
  shadowsRequired
  reverseShadowsRequired
  isPausedIndefinitely
  pausedUntil
  weeklyNumInterviewLimit
  isPaused
  employee {
    fullName
    timezone
    id
    isPaused
    slackImageUrl
    title
  }
  interviewModule {
    id
    name
  }
  stats {
    currentTrainingSessionStatus
    upcoming
    completed
    manualCompletedAsShadow
    manualCompletedAsReverseShadow
  }
  ...ModuleMemberRow_moduleMember
}
    ${ModuleMemberRow_ModuleMemberFragmentDoc}`;
export const InterviewsAccordion_EmployeeFragmentDoc = gql`
    fragment InterviewsAccordion_employee on Employee {
  id
}
    `;
export const InterviewsAccordion_InterviewFragmentDoc = gql`
    fragment InterviewsAccordion_interview on ApplicationStageInterview {
  ...InterviewItem
}
    ${InterviewItemFragmentDoc}`;
export const InterviewerInfoContainer_EmployeeFragmentDoc = gql`
    fragment InterviewerInfoContainer_employee on Employee {
  id
  fullName
  email
  isPaused
  orgId
  ...InterviewerStatusAccordion_interviewer
  ...InterviewerAboutSection_interviewer
  ...AvailabilitySection_interviewer
  ...InterviewerAttributesSection_interviewer
  ...PlannedAvailabilitySection_interviewer
  ...ExternalProfileSection_interviewer
  interviewModuleMembers(input: {pageInput: {limit: 500, offset: 0}}) {
    items {
      ...InterviewModulesAccordion_interviewerModules
    }
  }
  ...InterviewsAccordion_employee
  applicationStageInterviews(
    input: {pageInput: {limit: 3}, orderBy: {property: DATE, direction: ASC}, statusFilters: [SCHEDULED, ONGOING]}
  ) {
    items {
      ...InterviewsAccordion_interview
    }
  }
}
    ${InterviewerStatusAccordion_InterviewerFragmentDoc}
${InterviewerAboutSection_InterviewerFragmentDoc}
${AvailabilitySection_InterviewerFragmentDoc}
${InterviewerAttributesSection_InterviewerFragmentDoc}
${PlannedAvailabilitySection_InterviewerFragmentDoc}
${ExternalProfileSection_InterviewerFragmentDoc}
${InterviewModulesAccordion_InterviewerModulesFragmentDoc}
${InterviewsAccordion_EmployeeFragmentDoc}
${InterviewsAccordion_InterviewFragmentDoc}`;
export const InterviewsTab_InterviewFragmentDoc = gql`
    fragment InterviewsTab_interview on ApplicationStageInterview {
  id
  name
  startAt
  endAt
  isCancelled
  googleEventUrl
  applicationStage {
    id
    application {
      id
      candidate {
        id
        fullName
      }
      job {
        id
        name
      }
      jobStage {
        id
        name
      }
    }
  }
  applicationStageInterviewInterviewers {
    interviewerId
    role
    responseStatus
    isOptional
    interviewer {
      id
      fullName
      slackImageUrl
    }
    declines {
      reason
      comment
      source
    }
  }
  applicationStageInterviewRooms {
    applicationStageInterviewId
    meetingRoomId
    responseStatus
  }
}
    `;
export const InterviewsTab_InterviewerFragmentDoc = gql`
    fragment InterviewsTab_interviewer on Employee {
  id
  applicationStageInterviews(input: $input) {
    items {
      ...InterviewsTab_interview
    }
    nextCursor
  }
}
    ${InterviewsTab_InterviewFragmentDoc}`;
export const PortalPreviewByJobStage_BrandingThemeFragmentDoc = gql`
    fragment PortalPreviewByJobStage_brandingTheme on BrandingTheme {
  id
  coverImageUrl
  profileImageUrl
  font
  themeMode
  primaryColor
}
    `;
export const PortalPreviewByJobStage_JobStagesFragmentDoc = gql`
    fragment PortalPreviewByJobStage_jobStages on JobStage {
  id
  name
  index
}
    `;
export const PortalPreviewByJobStage_RecruitingTeamContactInfoFragmentDoc = gql`
    fragment PortalPreviewByJobStage_recruitingTeamContactInfo on RecruitingTeamContactInfoSetting {
  showRecruiter
  showCoordinator
}
    `;
export const TabRow_TabFragmentDoc = gql`
    fragment TabRow_tab on PortalJobOptionsTab {
  id
  index
  name
  icon
  visibility
  portalTemplateId
  jobStageId
}
    `;
export const DraggableTabRows_TabsFragmentDoc = gql`
    fragment DraggableTabRows_tabs on PortalJobOptionsTab {
  id
  ...TabRow_tab
}
    ${TabRow_TabFragmentDoc}`;
export const CustomTabs_CustomTabsFragmentDoc = gql`
    fragment CustomTabs_customTabs on PortalJobOptionsTab {
  id
  ...DraggableTabRows_tabs
}
    ${DraggableTabRows_TabsFragmentDoc}`;
export const Settings_JobFragmentDoc = gql`
    fragment Settings_job on Job {
  id
  externalJobName
}
    `;
export const Settings_PortalJobOptionsFragmentDoc = gql`
    fragment Settings_portalJobOptions on PortalJobOptions {
  jobDisplayName
  intro
  brandingThemeId
  useOrgDefaultBrandingTheme
}
    `;
export const HiringTeamJobEmployeeFragmentFragmentDoc = gql`
    fragment HiringTeamJobEmployeeFragment on Employee {
  fullName
  email
  id
  orgId
  slackImageUrl
  timezone
  atsId
}
    `;
export const HiringTeam_JobEmployeeFragmentDoc = gql`
    fragment HiringTeam_jobEmployee on Job {
  hiringManagers {
    ...HiringTeamJobEmployeeFragment
  }
  recruiters {
    ...HiringTeamJobEmployeeFragment
  }
  coordinators {
    ...HiringTeamJobEmployeeFragment
  }
  sourcers {
    ...HiringTeamJobEmployeeFragment
  }
}
    ${HiringTeamJobEmployeeFragmentFragmentDoc}`;
export const ManageJobDisplayNameModal_JobFragmentDoc = gql`
    fragment ManageJobDisplayNameModal_job on Job {
  id
  name
  fallbackName
}
    `;
export const InterviewNameRadio_InterviewNameSettingFragmentDoc = gql`
    fragment InterviewNameRadio_interviewNameSetting on InterviewNameSetting {
  isCustom
  isLocked
  level
  showInterviewName
}
    `;
export const InterviewerInfoRadio_InterviewerInfoSettingFragmentDoc = gql`
    fragment InterviewerInfoRadio_interviewerInfoSetting on InterviewerInfoSetting {
  isCustom
  isLocked
  level
  canShowInfo
  canShowFullName
  canShowJobTitle
  canShowLinkedin
}
    `;
export const RecruitingTeamRadio_RecruitingTeamContactInfoSettingFragmentDoc = gql`
    fragment RecruitingTeamRadio_recruitingTeamContactInfoSetting on RecruitingTeamContactInfoSetting {
  isCustom
  isLocked
  level
  showRecruiter
  showCoordinator
}
    `;
export const JobDetailsPrivacy_JobSettingsFragmentDoc = gql`
    fragment JobDetailsPrivacy_jobSettings on JobSettings {
  settingLevelId
  settingLevel
  interviewNameSetting {
    ...InterviewNameRadio_interviewNameSetting
  }
  interviewerInfoSetting {
    ...InterviewerInfoRadio_interviewerInfoSetting
  }
  defaultTaskQueue {
    ...QueueSelect_selectedTaskQueue
    ...QueueSelect_defaultTaskQueue
  }
  recruitingTeamContactInfoSetting {
    ...RecruitingTeamRadio_recruitingTeamContactInfoSetting
  }
}
    ${InterviewNameRadio_InterviewNameSettingFragmentDoc}
${InterviewerInfoRadio_InterviewerInfoSettingFragmentDoc}
${QueueSelect_SelectedTaskQueueFragmentDoc}
${QueueSelect_DefaultTaskQueueFragmentDoc}
${RecruitingTeamRadio_RecruitingTeamContactInfoSettingFragmentDoc}`;
export const JobNameAndLocationHeader_JobFragmentDoc = gql`
    fragment JobNameAndLocationHeader_job on Job {
  id
  status
  name
  location
}
    `;
export const JobStageDetails_JobStageFragmentDoc = gql`
    fragment JobStageDetails_jobStage on JobStage {
  id
  name
}
    `;
export const InternalEventsCard_InternalEventsFragmentDoc = gql`
    fragment InternalEventsCard_internalEvents on JobStageSettings {
  interviewerCalendarId
  interviewerEventTemplate {
    id
    name
    subject
    body
    isOrganizationDefault
    updatedAt
    type
    createdAt
  }
}
    `;
export const CandidateAvailabilityOptions_JobStageSettingsFragmentDoc = gql`
    fragment CandidateAvailabilityOptions_jobStageSettings on JobStageSettings {
  candidateAvailabilityOptions {
    numberOfDays
    minutesPerDays
    minimumTimeBlockMinutes
    advanceNoticeMinutes
    candidateNote
    ccRecipients {
      employeeIds
      dynamicAudiences
      externalEmailAddresses
    }
    bccRecipients {
      employeeIds
      dynamicAudiences
      externalEmailAddresses
    }
    shouldRespectLoadLimit
    canScheduleOverRecruitingKeywords
    canScheduleOverAvailableKeywords
    canScheduleOverFreeTime
    useRollingDays
    timeframeNumberOfDays
  }
}
    `;
export const SelfScheduleOptions_JobStageSettingsFragmentDoc = gql`
    fragment SelfScheduleOptions_jobStageSettings on JobStageSettings {
  selfScheduleOptions {
    numberOfDays
    advanceNoticeInHours
    rollingDays
    candidateCalendarId
    interviewerCalendarId
    location
    zoomHost
    isPrivateCalendarEvent
    zoomHostUserId
    customLocation
    shouldRespectLoadLimit
    canScheduleOverRecruitingKeywords
    canScheduleOverAvailableKeywords
    canScheduleOverFreeTime
    candidateNote
    ccRecipients {
      dynamicAudiences
      employeeIds
      externalEmailAddresses
    }
    bccRecipients {
      dynamicAudiences
      employeeIds
      externalEmailAddresses
    }
    useRollingDays
    meetingHost
    videoMeetingHostEmployeeId
  }
}
    `;
export const JobStageCommunicationSettings_JobStageSettingsFragmentDoc = gql`
    fragment JobStageCommunicationSettings_jobStageSettings on JobStageSettings {
  id
  jobStageId
  candidateEmailTemplateId
  candidateEventTemplateId
  candidateCalendarId
  slackTemplateId
  ccRecipients {
    employeeIds
    dynamicAudiences
    externalEmailAddresses
  }
  bccRecipients {
    employeeIds
    dynamicAudiences
    externalEmailAddresses
  }
  ...CandidateAvailabilityOptions_jobStageSettings
  ...SelfScheduleOptions_jobStageSettings
  selfScheduleRequestEmailTemplateId
  selfScheduleCandidateEmailTemplateId
  selfScheduleInterviewerEventTemplateId
  availabilityRequestEmailTemplateId
}
    ${CandidateAvailabilityOptions_JobStageSettingsFragmentDoc}
${SelfScheduleOptions_JobStageSettingsFragmentDoc}`;
export const JobStageDetails_JobStageSettingsFragmentDoc = gql`
    fragment JobStageDetails_jobStageSettings on JobStageSettings {
  id
  jobStageId
  privateCalendarEvents
  interviewerEventTemplateId
  ...InternalEventsCard_internalEvents
  ...JobStageCommunicationSettings_jobStageSettings
}
    ${InternalEventsCard_InternalEventsFragmentDoc}
${JobStageCommunicationSettings_JobStageSettingsFragmentDoc}`;
export const ScheduleLocation_InterviewMeetingLocationSettingFragmentDoc = gql`
    fragment ScheduleLocation_interviewMeetingLocationSetting on InterviewMeetingLocationSettingWithLevel {
  level
  interviewMeetingLocationSetting {
    interviewMeetingLocationType
    dynamicHost
    hostEmployee {
      id
      fullName
      slackImageUrl
    }
    remoteVideoMeetingHostUser {
      ... on ZoomUser {
        zoomUserId
        firstName
        lastName
        email
      }
    }
    ...InterviewMeetingLocationHost_interviewMeetingLocationSettingPref
  }
}
    ${InterviewMeetingLocationHost_InterviewMeetingLocationSettingPrefFragmentDoc}`;
export const JobStageDetails_JobStageSettingsV2FragmentDoc = gql`
    fragment JobStageDetails_jobStageSettingsV2 on JobStageSettingsV2 {
  id
  interviewMeetingLocationSettingWithLevel {
    ...ScheduleLocation_interviewMeetingLocationSetting
  }
}
    ${ScheduleLocation_InterviewMeetingLocationSettingFragmentDoc}`;
export const SummaryAlert_InterviewPlanFragmentDoc = gql`
    fragment SummaryAlert_interviewPlan on JobStage {
  id
  jobStageInterviewGroups {
    id
    jobStageInterviews {
      id
      duration
      interviewType
      isHiddenFromCandidate
    }
  }
}
    `;
export const SourcingLinkForm_CurrentEmployeeFragmentDoc = gql`
    fragment SourcingLinkForm_currentEmployee on Employee {
  id
  fullName
  slackImageUrl
}
    `;
export const SummaryAlert_JobStageFragmentDoc = gql`
    fragment SummaryAlert_jobStage on JobStage {
  id
  name
  jobId
  jobStageSettingsV2 {
    id
    candidateAvailabilitySettings {
      advanceNoticeMinutes
      minutesPerDays
      numberOfDays
      minimumTimeBlockMinutes
      candidateNote
    }
    selfScheduleSettings {
      location
      advanceNoticeMinutes
      candidateNote
    }
  }
}
    `;
export const SourceSelect_RecruitingSourceFragmentDoc = gql`
    fragment SourceSelect_recruitingSource on RecruitingSource {
  source
}
    `;
export const SourcingLinkForm_JobFragmentDoc = gql`
    fragment SourcingLinkForm_job on Job {
  id
  name
  jobStages {
    id
    name
    atsId
    index
    jobStageSettingsV2 {
      id
      candidateAvailabilitySettings {
        canScheduleOverAvailableKeywords
        canScheduleOverRecruitingKeywords
        shouldRespectLoadLimit
        canScheduleOverFreeTime
      }
    }
    ...SummaryAlert_jobStage
  }
  org {
    id
    recruitingSources {
      ...SourceSelect_recruitingSource
    }
  }
  ...InterviewPlan_job
}
    ${SummaryAlert_JobStageFragmentDoc}
${SourceSelect_RecruitingSourceFragmentDoc}
${InterviewPlan_JobFragmentDoc}`;
export const CodingLink_JobStageInterviewFragmentDoc = gql`
    fragment CodingLink_jobStageInterview on JobStageInterview {
  id
  dynamicLinkTypes
}
    `;
export const MoreMenuOptions_JobStageInterviewFragmentDoc = gql`
    fragment MoreMenuOptions_jobStageInterview on JobStageInterview {
  id
  interviewType
  jobStageInterviewGroupId
  isHiddenFromCandidate
}
    `;
export const InterviewCardHeader_JobStageInterviewFragmentDoc = gql`
    fragment InterviewCardHeader_jobStageInterview on JobStageInterview {
  id
  name
  isLockedOrder
  useAtsDuration
  useAtsName
  duration
  forcedStartAt @client
  atsInterviewDefinition {
    atsId
    name
    atsJobId
    atsFields {
      ... on GreenhouseInterviewDefinition {
        estimatedMinutes
        index
      }
    }
  }
  ...CodingLink_jobStageInterview
  ...MoreMenuOptions_jobStageInterview
}
    ${CodingLink_JobStageInterviewFragmentDoc}
${MoreMenuOptions_JobStageInterviewFragmentDoc}`;
export const BreakSlot_BreakSlotFragmentDoc = gql`
    fragment BreakSlot_breakSlot on JobStageInterview {
  id
  interviewType
  name
  duration
  isLockedOrder
  ...MoreMenuOptions_jobStageInterview
}
    ${MoreMenuOptions_JobStageInterviewFragmentDoc}`;
export const Break_BreakSlotFragmentDoc = gql`
    fragment Break_breakSlot on JobStageInterview {
  id
  interviewType
  name
  duration
  isLockedOrder
}
    `;
export const FreeformSeat_SeatFragmentDoc = gql`
    fragment FreeformSeat_seat on JobStageInterviewSeat {
  id
  freeformSeat {
    jobStageInterviewSeatEmployees {
      jobStageInterviewSeatId
      employeeId
      employee {
        id
        ...Employee
      }
      preferenceLevel
    }
  }
}
    ${EmployeeFragmentDoc}`;
export const ModuleSeat_SeatFragmentDoc = gql`
    fragment ModuleSeat_seat on JobStageInterviewSeat {
  id
  moduleSeat {
    interviewModuleId
    interviewModule {
      id
      orgId
      shadowsRequired
      reverseShadowsRequired
      membersCount
      name
      membersTrainedCount
      membersInTrainingCount
      membersInReverseShadowCount
      membersInShadowCount
      membersInPendingApprovalCount
      graduateFromShadowApprovalType
      graduateFromReverseShadowApprovalType
      interviewModuleMembers(input: {pageInput: {limit: 500, offset: 0}}) {
        items {
          id
          interviewModuleId
          ...EmployeeFilter
        }
      }
    }
    selectedEmployeeIds
    jobStageInterviewSeatAttributes {
      id
      jobStageInterviewSeatId
      attributeNameId
      attributeTagValueId
    }
  }
}
    ${EmployeeFilterFragmentDoc}`;
export const LinkedSeat_SeatFragmentDoc = gql`
    fragment LinkedSeat_seat on JobStageInterviewSeat {
  id
  linkedSeat {
    linkedJobStageInterviewSeatId
  }
}
    `;
export const BaseReadInterviewSeatModule_SeatFragmentDoc = gql`
    fragment BaseReadInterviewSeatModule_seat on JobStageInterviewSeat {
  id
  type
  index
  ...FreeformSeat_seat
  ...ModuleSeat_seat
  ...LinkedSeat_seat
}
    ${FreeformSeat_SeatFragmentDoc}
${ModuleSeat_SeatFragmentDoc}
${LinkedSeat_SeatFragmentDoc}`;
export const BaseEditInterviewSeatModule_SeatFragmentDoc = gql`
    fragment BaseEditInterviewSeatModule_seat on JobStageInterviewSeat {
  id
  ...BaseReadInterviewSeatModule_seat
}
    ${BaseReadInterviewSeatModule_SeatFragmentDoc}`;
export const BaseInterviewSeatModule_SeatFragmentDoc = gql`
    fragment BaseInterviewSeatModule_seat on JobStageInterviewSeat {
  id
  type
  ...BaseReadInterviewSeatModule_seat
  ...BaseEditInterviewSeatModule_seat
}
    ${BaseReadInterviewSeatModule_SeatFragmentDoc}
${BaseEditInterviewSeatModule_SeatFragmentDoc}`;
export const InterviewSeatsModule_SeatsFragmentDoc = gql`
    fragment InterviewSeatsModule_seats on JobStageInterviewSeat {
  id
  ...BaseInterviewSeatModule_seat
}
    ${BaseInterviewSeatModule_SeatFragmentDoc}`;
export const JobStageInterview_JobStageInterviewFragmentDoc = gql`
    fragment JobStageInterview_jobStageInterview on JobStageInterview {
  id
  ...InterviewCardHeader_jobStageInterview
  ...BreakSlot_breakSlot
  ...Break_breakSlot
  jobStageInterviewSeats {
    id
    ...InterviewSeatsModule_seats
  }
}
    ${InterviewCardHeader_JobStageInterviewFragmentDoc}
${BreakSlot_BreakSlotFragmentDoc}
${Break_BreakSlotFragmentDoc}
${InterviewSeatsModule_SeatsFragmentDoc}`;
export const InterviewGroupOld_JobStageInterviewGroupFragmentDoc = gql`
    fragment InterviewGroupOld_jobStageInterviewGroup on JobStageInterviewGroup {
  id
  locked
  name
  jobStageInterviews {
    id
    ...JobStageInterview_jobStageInterview
  }
}
    ${JobStageInterview_JobStageInterviewFragmentDoc}`;
export const InterviewGroup_JobStageInterviewGroupFragmentDoc = gql`
    fragment InterviewGroup_jobStageInterviewGroup on JobStageInterviewGroup {
  id
  locked
  name
  jobStageInterviews {
    id
    ...JobStageInterview_jobStageInterview
  }
  ...InterviewGroupOld_jobStageInterviewGroup
}
    ${JobStageInterview_JobStageInterviewFragmentDoc}
${InterviewGroupOld_JobStageInterviewGroupFragmentDoc}`;
export const MoreMenuOptions_JobStageInterviewGroupsFragmentDoc = gql`
    fragment MoreMenuOptions_jobStageInterviewGroups on JobStageInterviewGroup {
  id
  jobStageInterviews {
    id
  }
}
    `;
export const BreakSlot_JobStageInterviewGroupsFragmentDoc = gql`
    fragment BreakSlot_jobStageInterviewGroups on JobStageInterviewGroup {
  id
  ...MoreMenuOptions_jobStageInterviewGroups
}
    ${MoreMenuOptions_JobStageInterviewGroupsFragmentDoc}`;
export const InterviewCardHeader_JobStageInterviewGroupsFragmentDoc = gql`
    fragment InterviewCardHeader_jobStageInterviewGroups on JobStageInterviewGroup {
  id
  ...MoreMenuOptions_jobStageInterviewGroups
}
    ${MoreMenuOptions_JobStageInterviewGroupsFragmentDoc}`;
export const JobStageInterview_JobStageInterviewGroupsFragmentDoc = gql`
    fragment JobStageInterview_jobStageInterviewGroups on JobStageInterviewGroup {
  id
  ...BreakSlot_jobStageInterviewGroups
  ...InterviewCardHeader_jobStageInterviewGroups
}
    ${BreakSlot_JobStageInterviewGroupsFragmentDoc}
${InterviewCardHeader_JobStageInterviewGroupsFragmentDoc}`;
export const InterviewGroupOld_JobStageInterviewGroupsFragmentDoc = gql`
    fragment InterviewGroupOld_jobStageInterviewGroups on JobStageInterviewGroup {
  id
  ...JobStageInterview_jobStageInterviewGroups
}
    ${JobStageInterview_JobStageInterviewGroupsFragmentDoc}`;
export const InterviewGroup_JobStageInterviewGroupsFragmentDoc = gql`
    fragment InterviewGroup_jobStageInterviewGroups on JobStageInterviewGroup {
  id
  jobStageInterviews {
    id
  }
  ...InterviewGroupOld_jobStageInterviewGroups
  ...JobStageInterview_jobStageInterviewGroups
}
    ${InterviewGroupOld_JobStageInterviewGroupsFragmentDoc}
${JobStageInterview_JobStageInterviewGroupsFragmentDoc}`;
export const UseWarningForStartAt_JobStageInterviewsFragmentDoc = gql`
    fragment useWarningForStartAt_jobStageInterviews on JobStageInterview {
  id
  duration
  forcedStartAt @client
}
    `;
export const InterviewCardHeader_JobStageInterviewsFragmentDoc = gql`
    fragment InterviewCardHeader_jobStageInterviews on JobStageInterview {
  id
  duration
  forcedStartAt @client
  useAtsDuration
  atsInterviewDefinition {
    atsId
    atsFields {
      ... on GreenhouseInterviewDefinition {
        estimatedMinutes
        index
      }
    }
  }
  ...useWarningForStartAt_jobStageInterviews
}
    ${UseWarningForStartAt_JobStageInterviewsFragmentDoc}`;
export const JobStageInterview_JobStageInterviewsFragmentDoc = gql`
    fragment JobStageInterview_jobStageInterviews on JobStageInterview {
  id
  ...InterviewCardHeader_jobStageInterviews
}
    ${InterviewCardHeader_JobStageInterviewsFragmentDoc}`;
export const BaseEditInterviewSeatModule_LinkableInterviewsFragmentDoc = gql`
    fragment BaseEditInterviewSeatModule_linkableInterviews on JobStageInterview {
  id
  name
  jobStageInterviewSeats {
    id
    type
  }
}
    `;
export const BaseInterviewSeatModule_LinkableInterviewsFragmentDoc = gql`
    fragment BaseInterviewSeatModule_linkableInterviews on JobStageInterview {
  id
  ...BaseEditInterviewSeatModule_linkableInterviews
}
    ${BaseEditInterviewSeatModule_LinkableInterviewsFragmentDoc}`;
export const LinkedSeat_LinkedInterviewFragmentDoc = gql`
    fragment LinkedSeat_linkedInterview on JobStageInterview {
  id
  name
  jobStageInterviewSeats {
    id
  }
}
    `;
export const BaseReadInterviewSeatModule_LinkedInterviewFragmentDoc = gql`
    fragment BaseReadInterviewSeatModule_linkedInterview on JobStageInterview {
  id
  ...LinkedSeat_linkedInterview
}
    ${LinkedSeat_LinkedInterviewFragmentDoc}`;
export const BaseEditInterviewSeatModule_LinkedInterviewFragmentDoc = gql`
    fragment BaseEditInterviewSeatModule_linkedInterview on JobStageInterview {
  id
  name
  jobStageInterviewSeats {
    id
  }
}
    `;
export const BaseInterviewSeatModule_LinkedInterviewFragmentDoc = gql`
    fragment BaseInterviewSeatModule_linkedInterview on JobStageInterview {
  id
  ...BaseReadInterviewSeatModule_linkedInterview
  ...BaseEditInterviewSeatModule_linkedInterview
}
    ${BaseReadInterviewSeatModule_LinkedInterviewFragmentDoc}
${BaseEditInterviewSeatModule_LinkedInterviewFragmentDoc}`;
export const InterviewSeatsModule_LinkableInterviewsFragmentDoc = gql`
    fragment InterviewSeatsModule_linkableInterviews on JobStageInterview {
  id
  ...BaseInterviewSeatModule_linkableInterviews
  ...BaseInterviewSeatModule_linkedInterview
}
    ${BaseInterviewSeatModule_LinkableInterviewsFragmentDoc}
${BaseInterviewSeatModule_LinkedInterviewFragmentDoc}`;
export const LinkedSeat_OriginalSeatFragmentDoc = gql`
    fragment LinkedSeat_originalSeat on JobStageInterviewSeat {
  id
  freeformSeat {
    jobStageInterviewSeatEmployees {
      employeeId
      employee {
        id
        fullName
        slackImageUrl
      }
    }
  }
  moduleSeat {
    interviewModuleId
    interviewModule {
      id
      name
    }
  }
}
    `;
export const BaseReadInterviewSeatModule_OriginalSeatFragmentDoc = gql`
    fragment BaseReadInterviewSeatModule_originalSeat on JobStageInterviewSeat {
  id
  ...LinkedSeat_originalSeat
}
    ${LinkedSeat_OriginalSeatFragmentDoc}`;
export const BaseInterviewSeatModule_OriginalSeatFragmentDoc = gql`
    fragment BaseInterviewSeatModule_originalSeat on JobStageInterviewSeat {
  id
  ...BaseReadInterviewSeatModule_originalSeat
}
    ${BaseReadInterviewSeatModule_OriginalSeatFragmentDoc}`;
export const InterviewSeatsModule_OriginalSeatsFragmentDoc = gql`
    fragment InterviewSeatsModule_originalSeats on JobStageInterviewSeat {
  id
  ...BaseInterviewSeatModule_originalSeat
}
    ${BaseInterviewSeatModule_OriginalSeatFragmentDoc}`;
export const JobStageInterview_LinkableInterviewsFragmentDoc = gql`
    fragment JobStageInterview_linkableInterviews on JobStageInterview {
  id
  ...InterviewSeatsModule_linkableInterviews
  jobStageInterviewSeats {
    id
    ...InterviewSeatsModule_originalSeats
  }
}
    ${InterviewSeatsModule_LinkableInterviewsFragmentDoc}
${InterviewSeatsModule_OriginalSeatsFragmentDoc}`;
export const InterviewGroupOld_JobStageInterviewsFragmentDoc = gql`
    fragment InterviewGroupOld_jobStageInterviews on JobStageInterview {
  id
  ...JobStageInterview_jobStageInterview
  ...JobStageInterview_jobStageInterviews
  ...JobStageInterview_linkableInterviews
}
    ${JobStageInterview_JobStageInterviewFragmentDoc}
${JobStageInterview_JobStageInterviewsFragmentDoc}
${JobStageInterview_LinkableInterviewsFragmentDoc}`;
export const InterviewGroup_JobStageInterviewsFragmentDoc = gql`
    fragment InterviewGroup_jobStageInterviews on JobStageInterview {
  id
  ...InterviewGroupOld_jobStageInterviews
  ...JobStageInterview_jobStageInterview
  ...JobStageInterview_jobStageInterviews
  ...JobStageInterview_linkableInterviews
}
    ${InterviewGroupOld_JobStageInterviewsFragmentDoc}
${JobStageInterview_JobStageInterviewFragmentDoc}
${JobStageInterview_JobStageInterviewsFragmentDoc}
${JobStageInterview_LinkableInterviewsFragmentDoc}`;
export const AdvancedOptions_InterviewPlanFragmentDoc = gql`
    fragment AdvancedOptions_interviewPlan on JobStage {
  id
  schedulingWindow {
    seconds
  }
  excludedEmployees {
    id
    fullName
    slackImageUrl
  }
}
    `;
export const InterviewPlan_JobStageFragmentDoc = gql`
    fragment InterviewPlan_jobStage on JobStage {
  id
  jobStageInterviewGroups {
    id
    ...InterviewGroup_jobStageInterviewGroup
    ...InterviewGroup_jobStageInterviewGroups
    jobStageInterviews {
      id
      ...InterviewGroup_jobStageInterviews
    }
  }
  ...AdvancedOptions_interviewPlan
}
    ${InterviewGroup_JobStageInterviewGroupFragmentDoc}
${InterviewGroup_JobStageInterviewGroupsFragmentDoc}
${InterviewGroup_JobStageInterviewsFragmentDoc}
${AdvancedOptions_InterviewPlanFragmentDoc}`;
export const SourcingLinkForm_SourcingLinkFragmentDoc = gql`
    fragment SourcingLinkForm_sourcingLink on SourcingLink {
  id
  deactivatedAt
  initialJobStageId
  interviewPlanJobStage {
    ...InterviewPlan_jobStage
  }
  name
  candidateSourcerId
  candidateRecruiterId
  candidateCoordinatorId
  candidateRecruiter {
    id
    fullName
    slackImageUrl
  }
  candidateSourcer {
    id
    fullName
    slackImageUrl
  }
  candidateCoordinator {
    id
    fullName
    slackImageUrl
  }
  recruitingSource
  taskAction
  taskAssigneeId
  taskAssignee {
    id
    fullName
    slackImageUrl
  }
  taskSubscriberIds
}
    ${InterviewPlan_JobStageFragmentDoc}`;
export const GetSaveInterviewPlanInput_InterviewPlanFragmentDoc = gql`
    fragment getSaveInterviewPlanInput_interviewPlan on JobStage {
  id
  ...InterviewPlan_jobStage
}
    ${InterviewPlan_JobStageFragmentDoc}`;
export const DuplicateMenuItem_SourcingLinkFragmentDoc = gql`
    fragment DuplicateMenuItem_sourcingLink on SourcingLink {
  id
  initialJobStageId
  interviewPlanJobStage {
    ...getSaveInterviewPlanInput_interviewPlan
  }
  name
  candidateSourcerId
  candidateRecruiterId
  candidateCoordinatorId
  recruitingSource
  taskAction
  taskAssigneeId
  taskSubscriberIds
}
    ${GetSaveInterviewPlanInput_InterviewPlanFragmentDoc}`;
export const SourcingLinksTable_SourcingLinksFragmentDoc = gql`
    fragment SourcingLinksTable_sourcingLinks on SourcingLink {
  id
  name
  deactivatedAt
  taskAction
  initialJobStage {
    id
    name
  }
  ...DuplicateMenuItem_sourcingLink
}
    ${DuplicateMenuItem_SourcingLinkFragmentDoc}`;
export const JobDetailsPage_JobStageFragmentDoc = gql`
    fragment JobDetailsPage_jobStage on JobStage {
  id
  name
  index
  atsId
}
    `;
export const ExitConfirmationModal_JobsFragmentDoc = gql`
    fragment ExitConfirmationModal_jobs on Job {
  id
  name
}
    `;
export const JobStageSelectFixIssues_JobFragmentDoc = gql`
    fragment JobStageSelectFixIssues_job on Job {
  id
  jobStages(input: {includeCustom: false}) {
    id
    name
  }
}
    `;
export const FixJobStageMapping_TargetJobFragmentDoc = gql`
    fragment FixJobStageMapping_targetJob on Job {
  id
  name
  ...JobStageSelectFixIssues_job
}
    ${JobStageSelectFixIssues_JobFragmentDoc}`;
export const FixContentTabMapping_TargetJobFragmentDoc = gql`
    fragment FixContentTabMapping_targetJob on Job {
  id
  ...JobStageSelectFixIssues_job
}
    ${JobStageSelectFixIssues_JobFragmentDoc}`;
export const FixScorecardMapping_TargetJobFragmentDoc = gql`
    fragment FixScorecardMapping_targetJob on Job {
  id
  name
  atsJob {
    atsId
  }
}
    `;
export const FixJobStageMapping_JobStageMappingFragmentDoc = gql`
    fragment FixJobStageMapping_jobStageMapping on JobStageMapping {
  sourceName
  sourceJobStageId
  sourceJobStage {
    id
    index
  }
}
    `;
export const FixContentTabMapping_ContentTabMappingFragmentDoc = gql`
    fragment FixContentTabMapping_ContentTabMapping on ContentTabMapping {
  sourceName
  sourceContentTab {
    id
    name
    icon
    visibility
  }
  sourceJobStageId
}
    `;
export const FixScorecardMapping_ScorecardMappingFragmentDoc = gql`
    fragment FixScorecardMapping_scorecardMapping on ScorecardMapping {
  sourceAtsId
  sourceAtsInterviewDefinition {
    atsId
    name
    atsJobId
  }
  jobStageInterviews {
    id
    name
  }
}
    `;
export const FixJobIssues_IssuesFragmentDoc = gql`
    fragment FixJobIssues_issues on JobImportValidateResult {
  targetJob {
    id
    name
    location
    ...FixJobStageMapping_targetJob
    ...FixContentTabMapping_targetJob
    ...FixScorecardMapping_targetJob
  }
  jobStageMappings {
    targetJobStageId
    ...FixJobStageMapping_jobStageMapping
  }
  contentTabMappings {
    targetJobStageId
    sourceJobStageId
    ...FixContentTabMapping_ContentTabMapping
  }
  scorecardMappings {
    targetAtsId
    sourceAtsId
    ...FixScorecardMapping_scorecardMapping
  }
}
    ${FixJobStageMapping_TargetJobFragmentDoc}
${FixContentTabMapping_TargetJobFragmentDoc}
${FixScorecardMapping_TargetJobFragmentDoc}
${FixJobStageMapping_JobStageMappingFragmentDoc}
${FixContentTabMapping_ContentTabMappingFragmentDoc}
${FixScorecardMapping_ScorecardMappingFragmentDoc}`;
export const FixContentTabMapping_SourceJobFragmentDoc = gql`
    fragment FixContentTabMapping_sourceJob on Job {
  id
  name
  jobStages(input: {includeCustom: false}) {
    id
    name
  }
}
    `;
export const FixJobStageMapping_SourceJobFragmentDoc = gql`
    fragment FixJobStageMapping_sourceJob on Job {
  id
  name
  jobStages(input: {includeCustom: false}) {
    id
    index
    name
  }
}
    `;
export const SourceJobFixIssues_JobFragmentDoc = gql`
    fragment SourceJobFixIssues_job on Job {
  id
  name
  ...FixContentTabMapping_sourceJob
  ...FixJobStageMapping_sourceJob
}
    ${FixContentTabMapping_SourceJobFragmentDoc}
${FixJobStageMapping_SourceJobFragmentDoc}`;
export const FixIssues_IssuesFragmentDoc = gql`
    fragment FixIssues_issues on JobSettingsImportValidateResult {
  items {
    ...FixJobIssues_issues
  }
  sourceJob {
    ...SourceJobFixIssues_job
  }
}
    ${FixJobIssues_IssuesFragmentDoc}
${SourceJobFixIssues_JobFragmentDoc}`;
export const UnmappedJobStages_JobStageMappingFragmentDoc = gql`
    fragment unmappedJobStages_jobStageMapping on JobStageMapping {
  targetJobStageId
}
    `;
export const UnmappedScorecards_ScorecardMappingFragmentDoc = gql`
    fragment unmappedScorecards_scorecardMapping on ScorecardMapping {
  targetAtsId
}
    `;
export const UnmappedContentTabs_ContentTabMappingFragmentDoc = gql`
    fragment unmappedContentTabs_contentTabMapping on ContentTabMapping {
  sourceJobStageId
  targetJobStageId
}
    `;
export const ReviewJobRow_JobImportValidateResultFragmentDoc = gql`
    fragment ReviewJobRow_jobImportValidateResult on JobImportValidateResult {
  targetJob {
    id
    name
    location
  }
  jobStageMappings {
    targetJobStageId
    sourceName
    sourceJobStageId
    ...unmappedJobStages_jobStageMapping
  }
  scorecardMappings {
    targetAtsId
    sourceName
    sourceAtsId
    ...unmappedScorecards_scorecardMapping
  }
  contentTabMappings {
    sourceName
    sourceJobStageId
    targetJobStageId
    sourceContentTabId
    ...unmappedContentTabs_contentTabMapping
  }
}
    ${UnmappedJobStages_JobStageMappingFragmentDoc}
${UnmappedScorecards_ScorecardMappingFragmentDoc}
${UnmappedContentTabs_ContentTabMappingFragmentDoc}`;
export const ReviewJobsTable_JobImportValidateResultsFragmentDoc = gql`
    fragment ReviewJobsTable_jobImportValidateResults on JobImportValidateResult {
  targetJob {
    id
  }
  ...ReviewJobRow_jobImportValidateResult
}
    ${ReviewJobRow_JobImportValidateResultFragmentDoc}`;
export const GetJobsWithIssues_JobImportValidateResultsFragmentDoc = gql`
    fragment getJobsWithIssues_jobImportValidateResults on JobImportValidateResult {
  targetJob {
    id
  }
  jobStageMappings {
    ...unmappedJobStages_jobStageMapping
  }
  scorecardMappings {
    ...unmappedScorecards_scorecardMapping
  }
  contentTabMappings {
    ...unmappedContentTabs_contentTabMapping
  }
}
    ${UnmappedJobStages_JobStageMappingFragmentDoc}
${UnmappedScorecards_ScorecardMappingFragmentDoc}
${UnmappedContentTabs_ContentTabMappingFragmentDoc}`;
export const GetJobsWithoutIssues_JobImportValidateResultsFragmentDoc = gql`
    fragment getJobsWithoutIssues_jobImportValidateResults on JobImportValidateResult {
  targetJob {
    id
  }
  jobStageMappings {
    ...unmappedJobStages_jobStageMapping
  }
  scorecardMappings {
    ...unmappedScorecards_scorecardMapping
  }
  contentTabMappings {
    ...unmappedContentTabs_contentTabMapping
  }
}
    ${UnmappedJobStages_JobStageMappingFragmentDoc}
${UnmappedScorecards_ScorecardMappingFragmentDoc}
${UnmappedContentTabs_ContentTabMappingFragmentDoc}`;
export const Review_JobImportValidateResultsFragmentDoc = gql`
    fragment Review_jobImportValidateResults on JobImportValidateResult {
  targetJob {
    id
  }
  jobStageMappings {
    targetJobStageId
  }
  scorecardMappings {
    targetAtsId
  }
  ...ReviewJobsTable_jobImportValidateResults
  ...getJobsWithIssues_jobImportValidateResults
  ...getJobsWithoutIssues_jobImportValidateResults
}
    ${ReviewJobsTable_JobImportValidateResultsFragmentDoc}
${GetJobsWithIssues_JobImportValidateResultsFragmentDoc}
${GetJobsWithoutIssues_JobImportValidateResultsFragmentDoc}`;
export const CandidatePortalImportOptions_TabsFragmentDoc = gql`
    fragment CandidatePortalImportOptions_tabs on PortalJobOptionsTab {
  id
  name
}
    `;
export const SelectImportOptions_JobFragmentDoc = gql`
    fragment SelectImportOptions_job on Job {
  id
  name
  location
  jobStages {
    id
    name
  }
  portalJobOptions {
    id
    tabs {
      ...CandidatePortalImportOptions_tabs
    }
  }
}
    ${CandidatePortalImportOptions_TabsFragmentDoc}`;
export const SourceJobListItem_JobFragmentDoc = gql`
    fragment SourceJobListItem_job on Job {
  id
  name
  orgId
  status
  location
}
    `;
export const SourceJobTable_JobFragmentDoc = gql`
    fragment SourceJobTable_job on Job {
  ...SourceJobListItem_job
}
    ${SourceJobListItem_JobFragmentDoc}`;
export const GetJobStageMappings_JobStageMappingFragmentDoc = gql`
    fragment getJobStageMappings_jobStageMapping on JobStageMapping {
  targetJobStageId
  sourceJobStageId
}
    `;
export const GetScorecardMappings_ScorecardMappingFragmentDoc = gql`
    fragment getScorecardMappings_scorecardMapping on ScorecardMapping {
  targetAtsId
  sourceAtsId
}
    `;
export const JobListItem_EmployeeFragmentDoc = gql`
    fragment JobListItem_employee on Employee {
  fullName
  email
  id
  orgId
  slackImageUrl
  timezone
  atsId
}
    `;
export const JobListItem_JobFragmentDoc = gql`
    fragment JobListItem_job on Job {
  id
  name
  orgId
  status
  hiringManagers {
    ...JobListItem_employee
  }
  recruiters {
    ...JobListItem_employee
  }
  coordinators {
    ...JobListItem_employee
  }
  sourcers {
    ...JobListItem_employee
  }
  atsJob {
    atsId
    jobRequisitionId
  }
  location
  jobStagesWithInterviewPlansCount
}
    ${JobListItem_EmployeeFragmentDoc}`;
export const JobListItemV2_EmployeeFragmentDoc = gql`
    fragment JobListItemV2_employee on Employee {
  fullName
  email
  id
  orgId
  slackImageUrl
  timezone
  atsId
}
    `;
export const JobListItemV2_JobFragmentDoc = gql`
    fragment JobListItemV2_job on Job {
  id
  name
  orgId
  status
  hiringManagers {
    ...JobListItemV2_employee
  }
  recruiters {
    ...JobListItemV2_employee
  }
  coordinators {
    ...JobListItemV2_employee
  }
  sourcers {
    ...JobListItemV2_employee
  }
  atsJob {
    atsId
    jobRequisitionId
  }
  location
  jobStagesWithInterviewPlansCount
}
    ${JobListItemV2_EmployeeFragmentDoc}`;
export const JobListTable_JobFragmentDoc = gql`
    fragment JobListTable_job on Job {
  ...JobListItemV2_job
}
    ${JobListItemV2_JobFragmentDoc}`;
export const RescheduleLogReasonsChart_RescheduleLogReasonsDataFragmentDoc = gql`
    fragment RescheduleLogReasonsChart_rescheduleLogReasonsData on RecruiterRescheduleLogsWrapperResult {
  rescheduleLogsChart {
    rescheduleReason {
      id
      reason
    }
    data {
      x: xpoint
      y: ypoint
    }
  }
  items {
    date
    note
    rescheduleReason {
      id
      reason
    }
    performedBy {
      fullName
      slackImageUrl
    }
    task {
      id
      createdBy {
        fullName
      }
      jobStage {
        name
      }
      application {
        id
        job {
          name
        }
        candidate {
          id
          fullName
        }
      }
    }
  }
}
    `;
export const RescheduleLogNotes_RescheduleLogItemsFragmentDoc = gql`
    fragment RescheduleLogNotes_rescheduleLogItems on RecruiterRescheduleLog {
  id
  date
  note
  rescheduleReason {
    id
    reason
  }
  performedBy {
    fullName
    slackImageUrl
  }
  task {
    id
    createdBy {
      fullName
    }
    jobStage {
      name
    }
    application {
      id
      job {
        name
      }
      candidate {
        id
        fullName
      }
    }
  }
}
    `;
export const MetricsPageInterviewerFiltersEmployeePrefFragmentDoc = gql`
    fragment MetricsPageInterviewerFiltersEmployeePref on MetricsPageInterviewerFiltersPref {
  interviewerEmployeeIds
  managerEmployeeIds
  jobIds
  interviewModuleIds
  departmentAttributeIds
  teamAttributeIds
  locationAttributeIds
  levelAttributeIds
  customAttributeIds
  showArchived
}
    `;
export const MetricsPageRecruitingTeamFiltersEmployeePrefFragmentDoc = gql`
    fragment MetricsPageRecruitingTeamFiltersEmployeePref on MetricsPageRecruitingTeamFiltersPref {
  schedulerEmployeeIds
  jobIds
  jobStageNames
  showArchived
}
    `;
export const OmniSearchOptionApplication_ApplicationFragmentDoc = gql`
    fragment OmniSearchOptionApplication_application on Application {
  id
  candidate {
    id
    fullName
  }
  job {
    id
    name
  }
  jobStage {
    id
    name
  }
}
    `;
export const OmniSearchOptionEmployee_EmployeeFragmentDoc = gql`
    fragment OmniSearchOptionEmployee_employee on Employee {
  id
  fullName
  slackImageUrl
  isArchived
  isAtsDisabled
  hasAtsId
  atsType
}
    `;
export const OmniSearchOptionInterviewModule_InterviewModuleFragmentDoc = gql`
    fragment OmniSearchOptionInterviewModule_interviewModule on InterviewModule {
  id
  name
}
    `;
export const OmniSearchOptionJob_JobFragmentDoc = gql`
    fragment OmniSearchOptionJob_job on Job {
  id
  name
}
    `;
export const JobStageSettingsFragmentFragmentDoc = gql`
    fragment JobStageSettingsFragment on JobStageSettings {
  id
  jobStageId
  candidateEmailTemplateId
  candidateEventTemplateId
  candidateEmailTemplateId
  slackTemplateId
  interviewerEventTemplateId
  interviewerCalendarId
  candidateCalendarId
  privateCalendarEvents
  toRecipients {
    employeeIds
    externalEmailAddresses
    dynamicAudiences
  }
  ccRecipients {
    employeeIds
    externalEmailAddresses
    dynamicAudiences
  }
  bccRecipients {
    employeeIds
    externalEmailAddresses
    dynamicAudiences
  }
}
    `;
export const Communications_CandidateFragmentDoc = gql`
    fragment Communications_candidate on Candidate {
  id
  preferredPhoneNumber
}
    `;
export const UpdateCommunications_CandidateFragmentDoc = gql`
    fragment UpdateCommunications_candidate on Candidate {
  id
  preferredPhoneNumber
}
    `;
export const ScheduleFlowDataProvider_CandidateFragmentDoc = gql`
    fragment ScheduleFlowDataProvider_candidate on Candidate {
  id
  ...Communications_candidate
  ...UpdateCommunications_candidate
}
    ${Communications_CandidateFragmentDoc}
${UpdateCommunications_CandidateFragmentDoc}`;
export const ScheduleInterviewPlan_JobFragmentDoc = gql`
    fragment ScheduleInterviewPlan_job on Job {
  id
  atsId
}
    `;
export const ScheduleFlowDataProvider_JobFragmentDoc = gql`
    fragment ScheduleFlowDataProvider_job on Job {
  id
  ...ScheduleInterviewPlan_job
}
    ${ScheduleInterviewPlan_JobFragmentDoc}`;
export const BaseInterviewPlanPreview_JobStageInterviewGroupsFragmentDoc = gql`
    fragment BaseInterviewPlanPreview_jobStageInterviewGroups on JobStageInterviewGroup {
  id
  jobStageInterviews {
    id
    name
    interviewType
    duration
    isLockedOrder
  }
}
    `;
export const UseScheduleOptionsRequestBody_InterviewPlanFragmentDoc = gql`
    fragment useScheduleOptionsRequestBody_interviewPlan on JobStage {
  id
  jobStageInterviewGroups {
    id
    locked
    jobStageInterviews {
      id
      interviewType
      customDuration
      duration
      forcedStartAt @client
      name
      useAtsName
      atsInterviewDefinition {
        atsId
        name
      }
      isLockedOrder
      isHiddenFromCandidate
      jobStageInterviewSeats {
        id
        freeformSeat {
          jobStageInterviewSeatEmployees {
            jobStageInterviewSeatId
            employeeId
            preferenceLevel
          }
        }
        moduleSeat {
          interviewModuleId
          jobStageInterviewSeatAttributes {
            id
            attributeNameId
            attributeTagValueId
          }
          selectedEmployeeIds
          interviewModule {
            id
            interviewModuleMembers(input: {pageInput: {limit: 500, offset: 0}}) {
              items {
                id
                employeeId
                status
              }
              nextCursor
            }
          }
        }
        linkedSeat {
          linkedJobStageInterviewSeatId
        }
      }
    }
  }
  schedulingWindow {
    seconds
  }
  excludedEmployees {
    id
  }
}
    `;
export const UseSubmitInterviewPlan_InterviewPlanFragmentDoc = gql`
    fragment useSubmitInterviewPlan_interviewPlan on JobStage {
  id
  ...useScheduleOptionsRequestBody_interviewPlan
}
    ${UseScheduleOptionsRequestBody_InterviewPlanFragmentDoc}`;
export const InterviewPlanPreview_JobStageFragmentDoc = gql`
    fragment InterviewPlanPreview_jobStage on JobStage {
  id
  jobStageInterviewGroups {
    id
    ...BaseInterviewPlanPreview_jobStageInterviewGroups
  }
  ...getSaveInterviewPlanInput_interviewPlan
  ...useIsInterviewPlanValid_jobStage
  ...useSubmitInterviewPlan_interviewPlan
}
    ${BaseInterviewPlanPreview_JobStageInterviewGroupsFragmentDoc}
${GetSaveInterviewPlanInput_InterviewPlanFragmentDoc}
${UseIsInterviewPlanValid_JobStageFragmentDoc}
${UseSubmitInterviewPlan_InterviewPlanFragmentDoc}`;
export const ScheduleInterviewPlan_JobStageFragmentDoc = gql`
    fragment ScheduleInterviewPlan_jobStage on JobStage {
  id
  ...InterviewPlanPreview_jobStage
  ...InterviewPlan_jobStage
}
    ${InterviewPlanPreview_JobStageFragmentDoc}
${InterviewPlan_JobStageFragmentDoc}`;
export const UsePopulateEventData_InterviewPlanFragmentDoc = gql`
    fragment usePopulateEventData_interviewPlan on JobStage {
  id
  jobStageInterviewGroups {
    id
    jobStageInterviews {
      id
      interviewType
      atsInterviewDefinition {
        atsId
      }
    }
  }
}
    `;
export const UseScheduleContent_InterviewPlanFragmentDoc = gql`
    fragment useScheduleContent_interviewPlan on JobStage {
  id
  ...usePopulateEventData_interviewPlan
}
    ${UsePopulateEventData_InterviewPlanFragmentDoc}`;
export const UseCandidateEventTemplateContent_InterviewPlanFragmentDoc = gql`
    fragment useCandidateEventTemplateContent_interviewPlan on JobStage {
  id
  ...useScheduleContent_interviewPlan
}
    ${UseScheduleContent_InterviewPlanFragmentDoc}`;
export const CandidateEmailCard_InterviewPlanFragmentDoc = gql`
    fragment CandidateEmailCard_interviewPlan on JobStage {
  id
  ...useCandidateEventTemplateContent_interviewPlan
}
    ${UseCandidateEventTemplateContent_InterviewPlanFragmentDoc}`;
export const UseCandidateCalendarEventTemplateContent_InterviewPlanFragmentDoc = gql`
    fragment useCandidateCalendarEventTemplateContent_interviewPlan on JobStage {
  id
  ...useScheduleContent_interviewPlan
}
    ${UseScheduleContent_InterviewPlanFragmentDoc}`;
export const CandidateEventCard_InterviewPlanFragmentDoc = gql`
    fragment CandidateEventCard_interviewPlan on JobStage {
  id
  ...useCandidateCalendarEventTemplateContent_interviewPlan
}
    ${UseCandidateCalendarEventTemplateContent_InterviewPlanFragmentDoc}`;
export const UseCreateCodingUrls_InterviewPlanFragmentDoc = gql`
    fragment useCreateCodingUrls_interviewPlan on JobStage {
  id
  jobStageInterviewGroups {
    id
    jobStageInterviews {
      id
      dynamicLinkTypes
    }
  }
}
    `;
export const CodingCard_InterviewPlanFragmentDoc = gql`
    fragment CodingCard_interviewPlan on JobStage {
  id
  ...useCreateCodingUrls_interviewPlan
}
    ${UseCreateCodingUrls_InterviewPlanFragmentDoc}`;
export const UseGetInterviewers_InterviewPlanFragmentDoc = gql`
    fragment useGetInterviewers_interviewPlan on JobStage {
  id
  jobStageInterviewGroups {
    id
    jobStageInterviews {
      id
      jobStageInterviewSeats {
        id
        freeformSeat {
          jobStageInterviewSeatEmployees {
            jobStageInterviewSeatId
            employeeId
            preferenceLevel
          }
        }
        moduleSeat {
          interviewModuleId
        }
        linkedSeat {
          linkedJobStageInterviewSeatId
        }
      }
    }
  }
}
    `;
export const UseSubmitCreateScheduleRest_InterviewPlanFragmentDoc = gql`
    fragment useSubmitCreateScheduleRest_interviewPlan on JobStage {
  id
  ...useGetInterviewers_interviewPlan
  ...getSaveInterviewPlanInput_interviewPlan
  ...usePopulateEventData_interviewPlan
}
    ${UseGetInterviewers_InterviewPlanFragmentDoc}
${GetSaveInterviewPlanInput_InterviewPlanFragmentDoc}
${UsePopulateEventData_InterviewPlanFragmentDoc}`;
export const UseSubmitCreateSchedule_InterviewPlanFragmentDoc = gql`
    fragment useSubmitCreateSchedule_interviewPlan on JobStage {
  id
  ...useGetInterviewers_interviewPlan
  ...getSaveInterviewPlanInput_interviewPlan
  ...usePopulateEventData_interviewPlan
  ...useSubmitCreateScheduleRest_interviewPlan
}
    ${UseGetInterviewers_InterviewPlanFragmentDoc}
${GetSaveInterviewPlanInput_InterviewPlanFragmentDoc}
${UsePopulateEventData_InterviewPlanFragmentDoc}
${UseSubmitCreateScheduleRest_InterviewPlanFragmentDoc}`;
export const CommunicationsPreview_InterviewPlanFragmentDoc = gql`
    fragment CommunicationsPreview_interviewPlan on JobStage {
  id
  ...useSubmitCreateSchedule_interviewPlan
}
    ${UseSubmitCreateSchedule_InterviewPlanFragmentDoc}`;
export const UseInterviewEventTemplateContent_InterviewPlanFragmentDoc = gql`
    fragment useInterviewEventTemplateContent_interviewPlan on JobStage {
  id
  ...useScheduleContent_interviewPlan
}
    ${UseScheduleContent_InterviewPlanFragmentDoc}`;
export const InterviewerContentCard_InterviewPlanFragmentDoc = gql`
    fragment InterviewerContentCard_interviewPlan on JobStage {
  id
  ...useInterviewEventTemplateContent_interviewPlan
}
    ${UseInterviewEventTemplateContent_InterviewPlanFragmentDoc}`;
export const InterviewerCommunicationCards_InterviewPlanFragmentDoc = gql`
    fragment InterviewerCommunicationCards_interviewPlan on JobStage {
  id
  ...InterviewerContentCard_interviewPlan
}
    ${InterviewerContentCard_InterviewPlanFragmentDoc}`;
export const UseSlackTemplateContent_InterviewPlanFragmentDoc = gql`
    fragment useSlackTemplateContent_interviewPlan on JobStage {
  id
  ...useScheduleContent_interviewPlan
}
    ${UseScheduleContent_InterviewPlanFragmentDoc}`;
export const BaseSlackChannelCard_InterviewPlanFragmentDoc = gql`
    fragment BaseSlackChannelCard_interviewPlan on JobStage {
  id
  ...useSlackTemplateContent_interviewPlan
}
    ${UseSlackTemplateContent_InterviewPlanFragmentDoc}`;
export const SlackChannelCard_InterviewPlanFragmentDoc = gql`
    fragment SlackChannelCard_interviewPlan on JobStage {
  id
  ...BaseSlackChannelCard_interviewPlan
}
    ${BaseSlackChannelCard_InterviewPlanFragmentDoc}`;
export const Communications_InterviewPlanFragmentDoc = gql`
    fragment Communications_interviewPlan on JobStage {
  id
  ...CandidateEmailCard_interviewPlan
  ...CandidateEventCard_interviewPlan
  ...CodingCard_interviewPlan
  ...CommunicationsPreview_interviewPlan
  ...InterviewerCommunicationCards_interviewPlan
  ...SlackChannelCard_interviewPlan
}
    ${CandidateEmailCard_InterviewPlanFragmentDoc}
${CandidateEventCard_InterviewPlanFragmentDoc}
${CodingCard_InterviewPlanFragmentDoc}
${CommunicationsPreview_InterviewPlanFragmentDoc}
${InterviewerCommunicationCards_InterviewPlanFragmentDoc}
${SlackChannelCard_InterviewPlanFragmentDoc}`;
export const UpdateInterviewSeat_InterviewSeatFragmentDoc = gql`
    fragment UpdateInterviewSeat_interviewSeat on JobStageInterviewSeat {
  id
  type
  index
  freeformSeat {
    jobStageInterviewSeatEmployees {
      employeeId
      preferenceLevel
    }
  }
  moduleSeat {
    interviewModuleId
    jobStageInterviewSeatAttributes {
      id
      attributeNameId
      attributeTagValueId
    }
    selectedEmployeeIds
  }
  linkedSeat {
    linkedJobStageInterviewSeatId
  }
}
    `;
export const UpdateInterviewSeat_InterviewFragmentDoc = gql`
    fragment UpdateInterviewSeat_interview on JobStageInterview {
  id
  name
}
    `;
export const InterviewDetails_InterviewFragmentDoc = gql`
    fragment InterviewDetails_interview on JobStageInterview {
  id
  atsInterviewDefinition {
    atsId
  }
  jobStageInterviewSeats {
    id
    ...UpdateInterviewSeat_interviewSeat
  }
  ...UpdateInterviewSeat_interview
}
    ${UpdateInterviewSeat_InterviewSeatFragmentDoc}
${UpdateInterviewSeat_InterviewFragmentDoc}`;
export const EventContent_InterviewFragmentDoc = gql`
    fragment EventContent_interview on JobStageInterview {
  id
  ...InterviewDetails_interview
}
    ${InterviewDetails_InterviewFragmentDoc}`;
export const UpdateInterviewSeat_OriginalInterviewSeatFragmentDoc = gql`
    fragment UpdateInterviewSeat_originalInterviewSeat on JobStageInterviewSeat {
  id
  type
  index
  freeformSeat {
    jobStageInterviewSeatEmployees {
      employeeId
      preferenceLevel
    }
  }
  moduleSeat {
    interviewModuleId
    jobStageInterviewSeatAttributes {
      id
      attributeNameId
      attributeTagValueId
    }
    selectedEmployeeIds
  }
  linkedSeat {
    linkedJobStageInterviewSeatId
  }
}
    `;
export const UpdateInterviewSeat_OriginalInterviewFragmentDoc = gql`
    fragment UpdateInterviewSeat_originalInterview on JobStageInterview {
  id
  name
}
    `;
export const InterviewDetails_OriginalInterviewsFragmentDoc = gql`
    fragment InterviewDetails_originalInterviews on JobStageInterview {
  id
  jobStageInterviewSeats {
    id
    ...UpdateInterviewSeat_originalInterviewSeat
  }
  ...UpdateInterviewSeat_originalInterview
}
    ${UpdateInterviewSeat_OriginalInterviewSeatFragmentDoc}
${UpdateInterviewSeat_OriginalInterviewFragmentDoc}`;
export const EventContent_OriginalInterviewsFragmentDoc = gql`
    fragment EventContent_originalInterviews on JobStageInterview {
  id
  ...InterviewDetails_originalInterviews
}
    ${InterviewDetails_OriginalInterviewsFragmentDoc}`;
export const BaseCalendarView_InterviewPlanFragmentDoc = gql`
    fragment BaseCalendarView_interviewPlan on JobStage {
  id
  jobStageInterviewGroups {
    id
    jobStageInterviews {
      id
      ...EventContent_interview
      ...EventContent_originalInterviews
      ...InterviewDetails_interview
      ...InterviewDetails_originalInterviews
    }
  }
}
    ${EventContent_InterviewFragmentDoc}
${EventContent_OriginalInterviewsFragmentDoc}
${InterviewDetails_InterviewFragmentDoc}
${InterviewDetails_OriginalInterviewsFragmentDoc}`;
export const CalendarView_InterviewPlanFragmentDoc = gql`
    fragment CalendarView_interviewPlan on JobStage {
  id
  ...BaseCalendarView_interviewPlan
}
    ${BaseCalendarView_InterviewPlanFragmentDoc}`;
export const BaseUpdateCalendarView_InterviewPlanFragmentDoc = gql`
    fragment BaseUpdateCalendarView_interviewPlan on JobStage {
  id
  jobStageInterviewGroups {
    id
    jobStageInterviews {
      id
      ...EventContent_interview
      ...EventContent_originalInterviews
      ...InterviewDetails_interview
      ...InterviewDetails_originalInterviews
    }
  }
}
    ${EventContent_InterviewFragmentDoc}
${EventContent_OriginalInterviewsFragmentDoc}
${InterviewDetails_InterviewFragmentDoc}
${InterviewDetails_OriginalInterviewsFragmentDoc}`;
export const UpdateCalendarView_InterviewPlanFragmentDoc = gql`
    fragment UpdateCalendarView_interviewPlan on JobStage {
  id
  ...BaseUpdateCalendarView_interviewPlan
}
    ${BaseUpdateCalendarView_InterviewPlanFragmentDoc}`;
export const UpdateCommunications_InterviewPlanFragmentDoc = gql`
    fragment UpdateCommunications_interviewPlan on JobStage {
  id
  ...CandidateEmailCard_interviewPlan
  ...CandidateEventCard_interviewPlan
  ...CodingCard_interviewPlan
  ...InterviewerCommunicationCards_interviewPlan
  ...SlackChannelCard_interviewPlan
}
    ${CandidateEmailCard_InterviewPlanFragmentDoc}
${CandidateEventCard_InterviewPlanFragmentDoc}
${CodingCard_InterviewPlanFragmentDoc}
${InterviewerCommunicationCards_InterviewPlanFragmentDoc}
${SlackChannelCard_InterviewPlanFragmentDoc}`;
export const ScheduleFlowDataProvider_InterviewPlanFragmentDoc = gql`
    fragment ScheduleFlowDataProvider_interviewPlan on JobStage {
  id
  ...ScheduleInterviewPlan_jobStage
  ...Communications_interviewPlan
  ...CalendarView_interviewPlan
  ...UpdateCalendarView_interviewPlan
  ...UpdateCommunications_interviewPlan
}
    ${ScheduleInterviewPlan_JobStageFragmentDoc}
${Communications_InterviewPlanFragmentDoc}
${CalendarView_InterviewPlanFragmentDoc}
${UpdateCalendarView_InterviewPlanFragmentDoc}
${UpdateCommunications_InterviewPlanFragmentDoc}`;
export const UsePopulateInterviewMeetingLocation_InterviewMeetingLocationSettingFragmentDoc = gql`
    fragment usePopulateInterviewMeetingLocation_interviewMeetingLocationSetting on InterviewMeetingLocationSettingPref {
  interviewMeetingLocationType
  dynamicHost
  hostEmployeeId
  remoteVideoMeetingHostUserId
  customLocation
}
    `;
export const LocationCard_InterviewMeetingLocationSettingFragmentDoc = gql`
    fragment LocationCard_interviewMeetingLocationSetting on InterviewMeetingLocationSettingPref {
  ...usePopulateInterviewMeetingLocation_interviewMeetingLocationSetting
}
    ${UsePopulateInterviewMeetingLocation_InterviewMeetingLocationSettingFragmentDoc}`;
export const Communications_JobStageSettingsFragmentDoc = gql`
    fragment Communications_jobStageSettings on JobStageSettingsV2 {
  id
  interviewMeetingLocationSettingWithLevel {
    interviewMeetingLocationSetting {
      ...LocationCard_interviewMeetingLocationSetting
    }
  }
}
    ${LocationCard_InterviewMeetingLocationSettingFragmentDoc}`;
export const ScheduleFlowDataProvider_JobStageSettingsFragmentDoc = gql`
    fragment ScheduleFlowDataProvider_jobStageSettings on JobStageSettingsV2 {
  id
  ...Communications_jobStageSettings
}
    ${Communications_JobStageSettingsFragmentDoc}`;
export const ScheduleIssuesDialogApplicationStageInterviewFragmentDoc = gql`
    fragment ScheduleIssuesDialogApplicationStageInterview on ApplicationStageInterview {
  id
  atsInterviewDefinition {
    atsId
    name
  }
  startAt
  endAt
}
    `;
export const ScheduleIssuesDialogApplicationStageInterviewInterviewersFragmentDoc = gql`
    fragment ScheduleIssuesDialogApplicationStageInterviewInterviewers on ApplicationStageInterviewInterviewer {
  applicationStageInterviewId
  interviewerId
  interviewer {
    id
    fullName
    slackImageUrl
  }
  declines {
    source
    reason
    comment
  }
}
    `;
export const GetTextForFlag_CompanyHolidayFragmentDoc = gql`
    fragment getTextForFlag_companyHoliday on CompanyHoliday {
  id
  name
  startAt
}
    `;
export const InitScheduleFlowData_TemplateFragmentDoc = gql`
    fragment InitScheduleFlowData_template on Template {
  id
  name
  type
}
    `;
export const UseCandidateAvailability_AtsLatestAvailabilityFragmentDoc = gql`
    fragment useCandidateAvailability_atsLatestAvailability on CandidateAtsAvailability {
  intervals {
    startAt
    endAt
  }
  jobName
  jobStageName
  originalText
}
    `;
export const UseCandidateAvailability_AvailabilitiesFragmentDoc = gql`
    fragment useCandidateAvailability_availabilities on CandidateAvailability {
  id
  candidateEnteredAvailability {
    startAt
    endAt
  }
  rcEnteredAvailability {
    startAt
    endAt
  }
}
    `;
export const CreateTaskDialogPage_ApplicationFragmentDoc = gql`
    fragment CreateTaskDialogPage_application on Application {
  id
  ...CreateTaskStep_application
  job {
    ...JobStageSelect_job
  }
  jobStage {
    id
  }
  candidate {
    id
    coordinator {
      id
      fullName
    }
    timezone
  }
}
    ${CreateTaskStep_ApplicationFragmentDoc}
${JobStageSelect_JobFragmentDoc}`;
export const CreateTaskStep_JobStageFragmentDoc = gql`
    fragment CreateTaskStep_jobStage on JobStage {
  id
  ...InterviewPlan_jobStage
  ...useInterviewPlanHasInterviewHiddenFromCandidate_interviewPlan
}
    ${InterviewPlan_JobStageFragmentDoc}
${UseInterviewPlanHasInterviewHiddenFromCandidate_InterviewPlanFragmentDoc}`;
export const CloneInterviewPlan_InterviewPlanFragmentDoc = gql`
    fragment cloneInterviewPlan_interviewPlan on JobStage {
  id
  job {
    id
    ...InterviewPlan_job
  }
  ...InterviewPlan_jobStage
}
    ${InterviewPlan_JobFragmentDoc}
${InterviewPlan_JobStageFragmentDoc}`;
export const CreateTaskDialogPage_InterviewPlanFragmentDoc = gql`
    fragment CreateTaskDialogPage_interviewPlan on JobStage {
  id
  ...CreateTaskStep_jobStage
  ...cloneInterviewPlan_interviewPlan
  ...useIsInterviewPlanValid_jobStage
  ...useInterviewPlanHasInterviewHiddenFromCandidate_interviewPlan
}
    ${CreateTaskStep_JobStageFragmentDoc}
${CloneInterviewPlan_InterviewPlanFragmentDoc}
${UseIsInterviewPlanValid_JobStageFragmentDoc}
${UseInterviewPlanHasInterviewHiddenFromCandidate_InterviewPlanFragmentDoc}`;
export const CandidateAvailabilityStatus_ApplicationFragmentDoc = gql`
    fragment CandidateAvailabilityStatus_application on Application {
  id
  status
}
    `;
export const BaseCandidateAvailabilitySection_ApplicationFragmentDoc = gql`
    fragment BaseCandidateAvailabilitySection_application on Application {
  id
  status
  ...CandidateAvailabilityStatus_application
}
    ${CandidateAvailabilityStatus_ApplicationFragmentDoc}`;
export const Availability_ApplicationFragmentDoc = gql`
    fragment Availability_application on Application {
  id
  jobStage {
    id
    job {
      id
    }
  }
  ...BaseCandidateAvailabilitySection_application
}
    ${BaseCandidateAvailabilitySection_ApplicationFragmentDoc}`;
export const CandidateAvailabilityFragmentFragmentDoc = gql`
    fragment CandidateAvailabilityFragment on CandidateAvailability {
  id
  createdAt
  updatedAt
  candidateEnteredAvailability {
    startAt
    endAt
  }
  rcEnteredAvailability {
    startAt
    endAt
  }
  lastUpdatedByEmployeeId
  lastUpdatedByEmployee {
    id
    fullName
  }
  employeeUpdatedAt
  candidateUpdatedAt
}
    `;
export const Availability_AvailabilityFragmentDoc = gql`
    fragment Availability_availability on CandidateAvailability {
  ...CandidateAvailabilityFragment
}
    ${CandidateAvailabilityFragmentFragmentDoc}`;
export const RecjectetCandidateAvailabilitySectionAlert_AvailabilityRequestFragmentDoc = gql`
    fragment RecjectetCandidateAvailabilitySectionAlert_availabilityRequest on CandidateAvailabilityOptions {
  id
  updatedAt
  lastCandidateCommunicationSentAt
  creator {
    fullName
  }
}
    `;
export const CandidateAvailabilityStatus_AvailabilityRequestFragmentDoc = gql`
    fragment CandidateAvailabilityStatus_availabilityRequest on CandidateAvailabilityOptions {
  ...RecjectetCandidateAvailabilitySectionAlert_availabilityRequest
}
    ${RecjectetCandidateAvailabilitySectionAlert_AvailabilityRequestFragmentDoc}`;
export const BaseCandidateAvailabilitySection_AvailabilityRequestFragmentDoc = gql`
    fragment BaseCandidateAvailabilitySection_availabilityRequest on CandidateAvailabilityOptions {
  id
  ...CandidateAvailabilityStatus_availabilityRequest
}
    ${CandidateAvailabilityStatus_AvailabilityRequestFragmentDoc}`;
export const Availability_AvailabilityRequestFragmentDoc = gql`
    fragment Availability_availabilityRequest on CandidateAvailabilityOptions {
  id
  applicationId
  availabilityEndDate
  availabilityStartDate
  candidateNote
  externalId
  createdAt
  jobStageId
  minimumTimeBlockMinutes
  minutesPerDays
  numberOfDays
  timezone
  updatedAt
  lastCandidateCommunicationSentAt
  advanceNoticeMinutes
  creator {
    fullName
  }
  shouldRespectLoadLimit
  suggestOverRecruitingKeywords
  suggestOverAvailableKeywords
  suggestOverFreeTime
  rollingDays
  inclusionDays
  ...BaseCandidateAvailabilitySection_availabilityRequest
}
    ${BaseCandidateAvailabilitySection_AvailabilityRequestFragmentDoc}`;
export const Availability_CandidateFragmentDoc = gql`
    fragment Availability_candidate on Candidate {
  id
  coordinatorId
  recruiterId
  timezone
  atsType
}
    `;
export const CandidateAvailabilityOptionsSettings_JobStageSettingsFragmentDoc = gql`
    fragment CandidateAvailabilityOptionsSettings_JobStageSettings on JobStageSettings {
  ...CandidateAvailabilityOptions_jobStageSettings
  availabilityRequestEmailTemplateId
}
    ${CandidateAvailabilityOptions_JobStageSettingsFragmentDoc}`;
export const Availability_JobStageFragmentDoc = gql`
    fragment Availability_jobStage on JobStage {
  id
  name
  jobStageSettings {
    ...CandidateAvailabilityOptionsSettings_JobStageSettings
  }
}
    ${CandidateAvailabilityOptionsSettings_JobStageSettingsFragmentDoc}`;
export const AtsInterviewDefinitionFragmentDoc = gql`
    fragment AtsInterviewDefinition on AtsInterviewDefinition {
  name
  atsId
  atsJobStageId
  atsJobId
  atsJobStage {
    atsId
    name
    index
  }
  atsFields {
    ... on GreenhouseInterviewDefinition {
      estimatedMinutes
      index
    }
    ... on LeverInterviewDefinition {
      groupId
      groupName
    }
  }
}
    `;
export const JobStage_InterviewPlanFragmentDoc = gql`
    fragment JobStage_InterviewPlan on JobStage {
  id
  jobId
  name
  createdAt
  updatedAt
  schedulable
  index
  atsId
  job {
    id
    atsId
  }
  jobStageInterviewGroups {
    locked
    id
    jobStageId
    index
    name
    atsId
    jobStageInterviews {
      id
      jobStageId
      interviewType
      customDuration
      name
      duration
      index
      isLockedOrder
      useAtsName
      useAtsDuration
      schedulable
      dynamicLinkTypes
      staticLinks
      jobStageInterviewGroupId
      atsInterviewDefinitionId
      isHiddenFromCandidate
      atsInterviewDefinition {
        ...AtsInterviewDefinition
      }
      jobStageInterviewSeats {
        id
        type
        moduleSeat {
          interviewModuleId
          interviewModule {
            id
            orgId
            shadowsRequired
            reverseShadowsRequired
            membersCount
            name
            membersTrainedCount
            membersInTrainingCount
            membersInReverseShadowCount
            membersInShadowCount
            membersInPendingApprovalCount
            graduateFromShadowApprovalType
            graduateFromReverseShadowApprovalType
            interviewModuleMembers(input: {pageInput: {limit: 500, offset: 0}}) {
              items {
                interviewModuleId
                employeeId
                status
                shadowsRequired
                reverseShadowsRequired
                pausedUntil
                stats {
                  completedAsReverseShadow
                  completedAsShadow
                  shadowOffset
                  reverseShadowOffset
                }
                employee {
                  id
                  fullName
                  email
                  slackImageUrl
                  attributes {
                    id
                    value
                  }
                  interviewPauseDates {
                    start
                    end
                  }
                }
              }
            }
          }
          selectedEmployeeIds
          jobStageInterviewSeatAttributes {
            id
            jobStageInterviewSeatId
            attributeNameId
            attributeTagValueId
          }
        }
        linkedSeat {
          linkedJobStageInterviewSeatId
        }
        freeformSeat {
          jobStageInterviewSeatEmployees {
            jobStageInterviewSeatId
            employeeId
            employee {
              fullName
              email
              slackImageUrl
              id
              orgId
              hasAtsId
              isArchived
              isAtsDisabled
              isDirectoryDisabled
              useCalendarTimezone
              isPaused
            }
            preferenceLevel
          }
        }
      }
    }
  }
}
    ${AtsInterviewDefinitionFragmentDoc}`;
export const Availability_InterviewPlanFragmentDoc = gql`
    fragment Availability_interviewPlan on JobStage {
  ...JobStage_InterviewPlan
}
    ${JobStage_InterviewPlanFragmentDoc}`;
export const Availability_ScheduleFragmentDoc = gql`
    fragment Availability_schedule on ApplicationStage {
  id
  createdAt
}
    `;
export const Debrief_DebriefRequirementsFragmentDoc = gql`
    fragment Debrief_debriefRequirements on Task {
  id
  isDebriefRequired
  application {
    id
  }
  debriefs {
    id
    createdAt
    ...DebriefContentEntity_debrief
  }
}
    ${DebriefContentEntity_DebriefFragmentDoc}`;
export const RescheduleLogModal_RescheduleLogFragmentDoc = gql`
    fragment RescheduleLogModal_rescheduleLog on RescheduleLog {
  id
  note
  rescheduledAt
  createdAt
  updatedAt
  lastUpdatedBy {
    id
    fullName
  }
  rescheduleReasonId
  rescheduleReason {
    reason
  }
  performedBy {
    id
    fullName
  }
}
    `;
export const RescheduleLogsList_RescheduleLogsFragmentDoc = gql`
    fragment RescheduleLogsList_rescheduleLogs on RescheduleLog {
  id
  ...RescheduleLogModal_rescheduleLog
}
    ${RescheduleLogModal_RescheduleLogFragmentDoc}`;
export const Schedule_ApplicationFragmentDoc = gql`
    fragment Schedule_application on Application {
  id
  status
  atsType
  atsApplication {
    atsId
    atsFields {
      ... on WorkdayApplication {
        currentStepName
        __typename
      }
      __typename
    }
  }
  job {
    id
  }
  ...ScheduleContent_application
  ...useApplicationJobStageSchedulable_application
}
    ${ScheduleContent_ApplicationFragmentDoc}
${UseApplicationJobStageSchedulable_ApplicationFragmentDoc}`;
export const Schedule_CandidateFragmentDoc = gql`
    fragment Schedule_candidate on Candidate {
  id
}
    `;
export const UseGetInterviewNames_InterviewsFragmentDoc = gql`
    fragment useGetInterviewNames_interviews on JobStageInterview {
  id
  name
  interviewType
}
    `;
export const Schedule_InterviewPlanFragmentDoc = gql`
    fragment Schedule_interviewPlan on JobStage {
  id
  ...InterviewPlan_jobStage
  ...getSaveInterviewPlanInput_interviewPlan
  ...useIsInterviewPlanValid_jobStage
  job {
    id
    ...InterviewPlan_job
  }
  jobStageInterviewGroups {
    id
    jobStageInterviews {
      id
      ...useGetInterviewNames_interviews
    }
  }
}
    ${InterviewPlan_JobStageFragmentDoc}
${GetSaveInterviewPlanInput_InterviewPlanFragmentDoc}
${UseIsInterviewPlanValid_JobStageFragmentDoc}
${InterviewPlan_JobFragmentDoc}
${UseGetInterviewNames_InterviewsFragmentDoc}`;
export const SelfScheduleOptionSettings_JobStageSettingsFragmentDoc = gql`
    fragment SelfScheduleOptionSettings_JobStageSettings on JobStageSettings {
  ...SelfScheduleOptions_jobStageSettings
  selfScheduleRequestEmailTemplateId
  selfScheduleCandidateEmailTemplateId
  selfScheduleInterviewerEventTemplateId
}
    ${SelfScheduleOptions_JobStageSettingsFragmentDoc}`;
export const Schedule_JobStageFragmentDoc = gql`
    fragment Schedule_jobStage on JobStage {
  id
  jobStageSettings {
    ...SelfScheduleOptionSettings_JobStageSettings
  }
}
    ${SelfScheduleOptionSettings_JobStageSettingsFragmentDoc}`;
export const SelfScheduleOptions_OptionsFragmentDoc = gql`
    fragment SelfScheduleOptions_options on Options {
  inclusionDays
  rollingDays
  advanceNoticeInHours
  candidateCalendarId
  interviewerCalendarId
  location
  zoomHost
  zoomHostUserId
  candidateEmail
  shouldRespectLoadLimit
  canScheduleOverRecruitingKeywords
  canScheduleOverAvailableKeywords
  canScheduleOverFreeTime
  meetingHost
  videoMeetingHostEmployeeId
}
    `;
export const SelfScheduleOptionsModal_SelfScheduleFragmentDoc = gql`
    fragment SelfScheduleOptionsModal_selfSchedule on SelfScheduleOptions {
  id
  taskId
  createdAt
  creatorEmployeeId
  deletedAt
  jobStageId
  candidateEmailTemplateId
  candidateEventTemplateId
  interviewerEventTemplateId
  lastUpdatedByEmployeeId
  options {
    ...SelfScheduleOptions_options
  }
  rescheduledAt
  selfScheduleEmailTemplateId
  selfScheduleEmailSentAt
  updatedAt
}
    ${SelfScheduleOptions_OptionsFragmentDoc}`;
export const Schedule_SelfScheduleRequestFragmentDoc = gql`
    fragment Schedule_selfScheduleRequest on SelfScheduleOptions {
  ...SelfScheduleOptionsModal_selfSchedule
}
    ${SelfScheduleOptionsModal_SelfScheduleFragmentDoc}`;
export const CancelScheduleApplicationStageFragmentDoc = gql`
    fragment CancelScheduleApplicationStage on ApplicationStage {
  id
  minStartAt
  maxEndAt
  jobStageId
  cancelScheduleApplicationStageInterviews: applicationStageInterviews {
    id
    startAt
  }
}
    `;
export const Schedule_ScheduleFragmentDoc = gql`
    fragment Schedule_schedule on ApplicationStage {
  ...CancelScheduleApplicationStage
  ...ScheduleContent_schedule
}
    ${CancelScheduleApplicationStageFragmentDoc}
${ScheduleContent_ScheduleFragmentDoc}`;
export const TaskTagsBase_TaskTagFragmentDoc = gql`
    fragment TaskTagsBase_taskTag on TaskTag {
  id
  name
}
    `;
export const TaskTags_SelectedTaskTagFragmentDoc = gql`
    fragment TaskTags_selectedTaskTag on TaskTag {
  ...TaskTagsBase_taskTag
}
    ${TaskTagsBase_TaskTagFragmentDoc}`;
export const ScheduleTaskFlags_TaskFlagFragmentDoc = gql`
    fragment ScheduleTaskFlags_taskFlag on TaskFlag {
  id
  createdAt
  flagType
  actorType
  actorEmployee {
    ...Employee
  }
  resolvedAt
  resolvedBy {
    ...Employee
  }
  ... on TaskFlagCandidateDeclined {
    declinedCandidateEvent {
      id
      name
    }
  }
  ... on TaskFlagInterviewerDeclined {
    declinedInterview {
      id
      name
    }
  }
  ... on TaskFlagRequestedChange {
    note
  }
  ... on TaskFlagInterviewerDebriefDeclined {
    declinedDebrief {
      id
      name
    }
  }
}
    ${EmployeeFragmentDoc}`;
export const ScheduleTaskAssignee_TaskFragmentDoc = gql`
    fragment ScheduleTaskAssignee_task on Task {
  id
  assignee {
    id
    fullName
    slackImageUrl
  }
}
    `;
export const ScheduleTaskStatusSubText_TaskFragmentDoc = gql`
    fragment ScheduleTaskStatusSubText_task on Task {
  id
  status
  availabilityRequests {
    id
    createdAt
  }
  selfScheduleRequests {
    id
    createdAt
    creatorEmployeeId
  }
}
    `;
export const ApplicationConflict_TaskFragmentDoc = gql`
    fragment ApplicationConflict_task on Task {
  id
  jobStage {
    id
    job {
      id
      name
    }
  }
  application {
    id
    jobStage {
      id
      job {
        id
        name
      }
    }
  }
}
    `;
export const ScheduleTaskDetails_TaskFragmentDoc = gql`
    fragment ScheduleTaskDetails_task on Task {
  id
  isUrgent
  name
  status
  application {
    id
    job {
      id
      jobSettings {
        defaultTaskQueue {
          ...QueueSelect_defaultTaskQueue
        }
      }
    }
    status
    candidate {
      id
      fullName
      ...Candidate_candidate
    }
  }
  taskQueue {
    ...QueueSelect_selectedTaskQueue
  }
  createdBy {
    ...Employee
  }
  createdAt
  jobStage {
    id
    name
    job {
      id
      name
      location
    }
  }
  tags {
    ...TaskTags_selectedTaskTag
  }
  flags {
    ...ScheduleTaskFlags_taskFlag
  }
  ...TaskName_task
  ...ScheduleTaskAssignee_task
  ...ScheduleTaskStatusSubText_task
  ...ApplicationConflict_task
}
    ${QueueSelect_DefaultTaskQueueFragmentDoc}
${Candidate_CandidateFragmentDoc}
${QueueSelect_SelectedTaskQueueFragmentDoc}
${EmployeeFragmentDoc}
${TaskTags_SelectedTaskTagFragmentDoc}
${ScheduleTaskFlags_TaskFlagFragmentDoc}
${TaskName_TaskFragmentDoc}
${ScheduleTaskAssignee_TaskFragmentDoc}
${ScheduleTaskStatusSubText_TaskFragmentDoc}
${ApplicationConflict_TaskFragmentDoc}`;
export const ScheduleTask_TaskFragmentDoc = gql`
    fragment ScheduleTask_task on Task {
  status
  ...ScheduleTaskDetails_task
}
    ${ScheduleTaskDetails_TaskFragmentDoc}`;
export const ScheduleDateCell_SchedulesFragmentDoc = gql`
    fragment ScheduleDateCell_schedules on ApplicationStage {
  id
  createdAt
  startAt
  endAt
}
    `;
export const ScheduleTaskDashboardTaskFlags_TaskFlagsFragmentDoc = gql`
    fragment ScheduleTaskDashboardTaskFlags_taskFlags on TaskFlag {
  id
  resolvedAt
  ...ScheduleTaskFlagCompact_taskFlag
}
    ${ScheduleTaskFlagCompact_TaskFlagFragmentDoc}`;
export const ScheduleTaskDashboardTaskTags_TaskTagsFragmentDoc = gql`
    fragment ScheduleTaskDashboardTaskTags_taskTags on TaskTag {
  id
  ...ScheduleTaskTagCompact_taskTag
}
    ${ScheduleTaskTagCompact_TaskTagFragmentDoc}`;
export const TaskDashboardTableRowItem_TaskFragmentDoc = gql`
    fragment TaskDashboardTableRowItem_task on Task {
  id
  status
  isUrgent
  createdAt
  lastActivityAt
  application {
    id
    status
    candidate {
      id
      fullName
      ...Candidate_candidate
    }
  }
  jobStage {
    id
    name
    job {
      id
      name
    }
  }
  taskQueue {
    id
    name
  }
  createdBy {
    id
    slackImageUrl
    fullName
  }
  schedules {
    id
    ...ScheduleDateCell_schedules
  }
  flags {
    id
    ...ScheduleTaskDashboardTaskFlags_taskFlags
  }
  tags {
    id
    ...ScheduleTaskDashboardTaskTags_taskTags
  }
  ...ScheduleTaskAssignee_task
}
    ${Candidate_CandidateFragmentDoc}
${ScheduleDateCell_SchedulesFragmentDoc}
${ScheduleTaskDashboardTaskFlags_TaskFlagsFragmentDoc}
${ScheduleTaskDashboardTaskTags_TaskTagsFragmentDoc}
${ScheduleTaskAssignee_TaskFragmentDoc}`;
export const TaskDashboardTable_TaskFragmentDoc = gql`
    fragment TaskDashboardTable_task on Task {
  id
  ...TaskDashboardTableRowItem_task
}
    ${TaskDashboardTableRowItem_TaskFragmentDoc}`;
export const QueueFilter_SelectedOptionsFragmentDoc = gql`
    fragment QueueFilter_selectedOptions on TaskQueue {
  id
  name
  memberCount
}
    `;
export const ScheduleTaskDashboardFiltersEmployeePrefFragmentDoc = gql`
    fragment ScheduleTaskDashboardFiltersEmployeePref on EmployeeTaskDashboardFilterPref {
  isUrgent
  assigneeEmployeeIds
  showUnassigned
  creatorEmployeeIds
  applicationStatuses
  taskStatuses
  jobIds
  jobStageNames
  flags
  taskQueueIds
  showNoQueue
  tags {
    id
    name
  }
}
    `;
export const UseInterviewPlanDescription_JobStageFragmentDoc = gql`
    fragment useInterviewPlanDescription_jobStage on JobStage {
  id
  jobStageInterviewGroups {
    id
    jobStageInterviews {
      id
      interviewType
      duration
      jobStageInterviewSeats {
        id
        type
        linkedSeat {
          linkedJobStageInterviewSeatId
        }
      }
    }
  }
}
    `;
export const SelfScheduleOptions_InterviewPlanFragmentDoc = gql`
    fragment SelfScheduleOptions_interviewPlan on JobStage {
  id
  ...useInterviewPlanDescription_jobStage
}
    ${UseInterviewPlanDescription_JobStageFragmentDoc}`;
export const BrandingThemeInputsPanel_BrandingThemeFragmentDoc = gql`
    fragment BrandingThemeInputsPanel_brandingTheme on BrandingTheme {
  id
  profileImageUrl
  coverImageUrl
  faviconImageUrl
  font
  themeMode
  primaryColor
}
    `;
export const BrandingThemePreviewContent_BrandingThemeFragmentDoc = gql`
    fragment BrandingThemePreviewContent_brandingTheme on BrandingTheme {
  coverImageUrl
  profileImageUrl
}
    `;
export const BrandingThemePreviewWrapper_BrandingThemeFragmentDoc = gql`
    fragment BrandingThemePreviewWrapper_brandingTheme on BrandingTheme {
  id
  themeMode
  font
  primaryColor
  ...BrandingThemePreviewContent_brandingTheme
}
    ${BrandingThemePreviewContent_BrandingThemeFragmentDoc}`;
export const BrandingThemeBuilderComponent_BrandingThemeFragmentDoc = gql`
    fragment BrandingThemeBuilderComponent_brandingTheme on BrandingTheme {
  id
  name
  isOrgDefault
  livePortals
  ...BrandingThemeInputsPanel_brandingTheme
  ...BrandingThemePreviewWrapper_brandingTheme
}
    ${BrandingThemeInputsPanel_BrandingThemeFragmentDoc}
${BrandingThemePreviewWrapper_BrandingThemeFragmentDoc}`;
export const BrandingThemeBuilder_BrandingThemeFragmentDoc = gql`
    fragment BrandingThemeBuilder_brandingTheme on BrandingTheme {
  ...BrandingThemeBuilderComponent_brandingTheme
}
    ${BrandingThemeBuilderComponent_BrandingThemeFragmentDoc}`;
export const BrandingThemesItem_BrandingThemeFragmentDoc = gql`
    fragment BrandingThemesItem_brandingTheme on BrandingTheme {
  ...BrandingThemePlaceholderPreview_brandingTheme
  id
  orgId
  name
  profileImageUrl
  coverImageUrl
  isOrgDefault
  createdAt
  updatedAt
  livePortals
}
    ${BrandingThemePlaceholderPreview_BrandingThemeFragmentDoc}`;
export const BrandingThemeItemDescription_BrandingThemeFragmentDoc = gql`
    fragment BrandingThemeItemDescription_brandingTheme on BrandingTheme {
  id
  name
  isOrgDefault
  livePortals
}
    `;
export const PageTemplateRow_TemplateFragmentDoc = gql`
    fragment PageTemplateRow_template on PortalTemplate {
  id
  name
  orgId
  livePortals
  updatedAt
}
    `;
export const TextBlock_BlockFragmentDoc = gql`
    fragment TextBlock_block on PortalTemplateBlockText {
  id
  index
  type
  content
}
    `;
export const ImageBlock_BlockFragmentDoc = gql`
    fragment ImageBlock_block on PortalTemplateBlockImage {
  id
  type
  index
  url
  header
  caption
  fillPage
}
    `;
export const VideoBlock_BlockFragmentDoc = gql`
    fragment VideoBlock_block on PortalTemplateBlockVideo {
  id
  type
  url
  index
  caption
  header
}
    `;
export const ContentCardBlock_BlockFragmentDoc = gql`
    fragment ContentCardBlock_block on PortalTemplateBlockContentCard {
  id
  type
  index
  header
  cards {
    header
    body
  }
}
    `;
export const LinkedCardBlock_BlockFragmentDoc = gql`
    fragment LinkedCardBlock_block on PortalTemplateBlockLinkedCard {
  id
  type
  index
  header
  cards {
    url
    header
    body
    previewImageUrl
  }
}
    `;
export const DividerBlock_BlockFragmentDoc = gql`
    fragment DividerBlock_block on PortalTemplateBlockDivider {
  id
  index
  type
}
    `;
export const BlockPreview_BlockFragmentDoc = gql`
    fragment BlockPreview_block on PortalTemplateBlock {
  id
  type
  index
  ... on PortalTemplateBlockText {
    id
    ...TextBlock_block
  }
  ... on PortalTemplateBlockImage {
    id
    ...ImageBlock_block
  }
  ... on PortalTemplateBlockVideo {
    id
    ...VideoBlock_block
  }
  ... on PortalTemplateBlockContentCard {
    id
    ...ContentCardBlock_block
  }
  ... on PortalTemplateBlockLinkedCard {
    id
    ...LinkedCardBlock_block
  }
  ... on PortalTemplateBlockDivider {
    id
    ...DividerBlock_block
  }
}
    ${TextBlock_BlockFragmentDoc}
${ImageBlock_BlockFragmentDoc}
${VideoBlock_BlockFragmentDoc}
${ContentCardBlock_BlockFragmentDoc}
${LinkedCardBlock_BlockFragmentDoc}
${DividerBlock_BlockFragmentDoc}`;
export const TemplatePreview_PortalTemplateFragmentDoc = gql`
    fragment TemplatePreview_portalTemplate on PortalTemplate {
  id
  name
  orgId
  livePortals
  updatedAt
  blocks {
    id
    index
    type
    ...BlockPreview_block
  }
}
    ${BlockPreview_BlockFragmentDoc}`;
export const InterviewerPortalSettingsOldFragmentDoc = gql`
    fragment InterviewerPortalSettingsOld on InterviewerPortalSettings {
  id
  firstName
  lastName
  title
  emailAddress
  linkedinUrl
  timezone
  interviewLoadCapacity
  interviewAvailability
  temporaryAvailability
  pauseFromInterviews
  interviewerKeywords
}
    `;
export const InterviewerPortalSettingsFragmentDoc = gql`
    fragment InterviewerPortalSettings on InterviewerPortalSettings {
  id
  firstName
  lastName
  title
  emailAddress
  linkedinUrl
  timezone
  interviewLoadCapacity
  interviewAvailability
  temporaryAvailability
  pauseFromInterviews
  interviewerKeywords
  profilePicture
  pronouns
  bio
}
    `;
export const AttributeFragmentValuesFragmentDoc = gql`
    fragment AttributeFragmentValues on EmployeeAttributeName {
  id
  type
  name
  aggregateJobStageInterviewSeatCount
}
    `;
export const EmployeeIdFragmentFragmentDoc = gql`
    fragment EmployeeIdFragment on Employee {
  id
  fullName
  slackImageUrl
}
    `;
export const ValueFragmentFragmentDoc = gql`
    fragment ValueFragment on EmployeeAttributeTagValue {
  id
  value
  aggregateJobStageInterviewSeatCount
  employees {
    ...EmployeeIdFragment
  }
}
    ${EmployeeIdFragmentFragmentDoc}`;
export const AttributeFragmentFragmentDoc = gql`
    fragment AttributeFragment on EmployeeAttributeName {
  ...AttributeFragmentValues
  values {
    ...ValueFragment
  }
}
    ${AttributeFragmentValuesFragmentDoc}
${ValueFragmentFragmentDoc}`;
export const CompanyHolidayModal_CompanyHolidayFragmentDoc = gql`
    fragment CompanyHolidayModal_companyHoliday on CompanyHoliday {
  id
  name
  startAt
  endAt
  countries {
    id
    value
  }
}
    `;
export const DeleteCompanyHolidayModal_CompanyHolidayFragmentDoc = gql`
    fragment DeleteCompanyHolidayModal_companyHoliday on CompanyHoliday {
  id
  name
  startAt
  endAt
  countries {
    id
    value
  }
}
    `;
export const CompanyHoliday_CompanyHolidayFragmentDoc = gql`
    fragment CompanyHoliday_companyHoliday on CompanyHoliday {
  id
  name
  startAt
  endAt
  countries {
    id
    value
  }
  ...CompanyHolidayModal_companyHoliday
  ...DeleteCompanyHolidayModal_companyHoliday
}
    ${CompanyHolidayModal_CompanyHolidayFragmentDoc}
${DeleteCompanyHolidayModal_CompanyHolidayFragmentDoc}`;
export const RescheduleReasonItem_RescheduleReasonFragmentDoc = gql`
    fragment RescheduleReasonItem_rescheduleReason on RescheduleReason {
  id
  reason
  type
  archivedAt
}
    `;
export const EditRescheduleReasonModal_RescheduleReasonFragmentDoc = gql`
    fragment EditRescheduleReasonModal_rescheduleReason on RescheduleReason {
  id
  reason
}
    `;
export const RescheduleReasonsList_RescheduleReasonFragmentDoc = gql`
    fragment RescheduleReasonsList_rescheduleReason on RescheduleReason {
  id
  reason
  ...RescheduleReasonItem_rescheduleReason
  ...EditRescheduleReasonModal_rescheduleReason
}
    ${RescheduleReasonItem_RescheduleReasonFragmentDoc}
${EditRescheduleReasonModal_RescheduleReasonFragmentDoc}`;
export const EditOrgNameDialog_OrgFragmentDoc = gql`
    fragment EditOrgNameDialog_org on Org {
  id
  name
}
    `;
export const DeleteTagModal_TaskTagFragmentDoc = gql`
    fragment DeleteTagModal_taskTag on TaskTag {
  id
  taskCount
}
    `;
export const TaskTagItem_TaskTagFragmentDoc = gql`
    fragment TaskTagItem_taskTag on TaskTag {
  id
  name
  taskCount
}
    `;
export const EditTagModal_TaskTagFragmentDoc = gql`
    fragment EditTagModal_taskTag on TaskTag {
  id
  name
}
    `;
export const TaskTagList_TaskTagFragmentDoc = gql`
    fragment TaskTagList_taskTag on TaskTag {
  id
  name
  ...TaskTagItem_taskTag
  ...EditTagModal_taskTag
}
    ${TaskTagItem_TaskTagFragmentDoc}
${EditTagModal_TaskTagFragmentDoc}`;
export const AddMemberModal_SelectedEmployeesFragmentDoc = gql`
    fragment AddMemberModal_selectedEmployees on Employee {
  id
  ...Employee
}
    ${EmployeeFragmentDoc}`;
export const QueueDialogContent_QueueMembersFragmentDoc = gql`
    fragment QueueDialogContent_queueMembers on Employee {
  id
  ...AddMemberModal_selectedEmployees
}
    ${AddMemberModal_SelectedEmployeesFragmentDoc}`;
export const QueueModal_TaskQueueFragmentDoc = gql`
    fragment QueueModal_taskQueue on TaskQueue {
  id
  name
  memberCount
  taskCount
}
    `;
export const DeleteQueueModal_TaskQueueFragmentDoc = gql`
    fragment DeleteQueueModal_taskQueue on TaskQueue {
  id
  name
}
    `;
export const QueueRow_TaskQueueFragmentDoc = gql`
    fragment QueueRow_taskQueue on TaskQueue {
  id
  memberCount
  taskCount
  name
  ...QueueModal_taskQueue
  ...DeleteQueueModal_taskQueue
}
    ${QueueModal_TaskQueueFragmentDoc}
${DeleteQueueModal_TaskQueueFragmentDoc}`;
export const QueueList_TaskQueueFragmentDoc = gql`
    fragment QueueList_taskQueue on TaskQueue {
  id
  ...QueueRow_taskQueue
}
    ${QueueRow_TaskQueueFragmentDoc}`;
export const AttachmentsFragmentDoc = gql`
    fragment Attachments on Template {
  attachments {
    name
    path
    size
    timestamp
  }
}
    `;
export const TemplateFragFragmentDoc = gql`
    fragment TemplateFrag on Template {
  id
  type
  name
  updatedAt
  subject
  body
  isOrganizationDefault
  liveSettingsUsages
}
    `;
export const FullCalendarViews_UpcomingEventsFragmentDoc = gql`
    fragment FullCalendarViews_upcomingEvents on UpcomingEvent {
  startAt
  endAt
  dayIndex
  dayTotal
  interviews {
    id
  }
  candidateEvent {
    id
  }
  schedule {
    id
    taskId
    jobStage {
      id
      name
    }
    application {
      id
      candidate {
        id
        fullName
      }
    }
  }
}
    `;
export const AvatarFacePile_InterviewersFragmentDoc = gql`
    fragment AvatarFacePile_interviewers on ApplicationStageInterviewInterviewer {
  interviewerId
  responseStatus
  isOptional
  interviewer {
    id
    fullName
    slackImageUrl
  }
}
    `;
export const ScheduleListItem_UpcomingEventFragmentDoc = gql`
    fragment ScheduleListItem_upcomingEvent on UpcomingEvent {
  startAt
  endAt
  dayIndex
  dayTotal
  schedule {
    id
    jobStage {
      id
      name
    }
    application {
      id
      job {
        id
        name
      }
      candidate {
        id
        ...Candidate_candidate
      }
    }
    isHoldForRsvp
    isReadyForCandidateComms
  }
  candidateEvent {
    id
    responseStatus
  }
  interviews {
    id
    applicationStageInterviewInterviewers {
      interviewerId
      responseStatus
      ...AvatarFacePile_interviewers
    }
  }
}
    ${Candidate_CandidateFragmentDoc}
${AvatarFacePile_InterviewersFragmentDoc}`;
export const SchedulesList_UpcomingEventsFragmentDoc = gql`
    fragment SchedulesList_upcomingEvents on UpcomingEvent {
  ...ScheduleListItem_upcomingEvent
  schedule {
    id
    taskId
  }
}
    ${ScheduleListItem_UpcomingEventFragmentDoc}`;
export const AgendaTab_UpcomingEventsFragmentDoc = gql`
    fragment AgendaTab_upcomingEvents on UpcomingEvent {
  ...SchedulesList_upcomingEvents
}
    ${SchedulesList_UpcomingEventsFragmentDoc}`;
export const Views_UpcomingEventsFragmentDoc = gql`
    fragment Views_upcomingEvents on UpcomingEvent {
  startAt
  endAt
  ...AgendaTab_upcomingEvents
}
    ${AgendaTab_UpcomingEventsFragmentDoc}`;
export const UpcomingEventsPageFiltersEmployeePrefFragmentDoc = gql`
    fragment UpcomingEventsPageFiltersEmployeePref on UpcomingEventsPageFiltersPref {
  schedulerEmployeeIds
  recruiterEmployeeIds
  interviewerEmployeeIds
  candidateRsvps
  interviewerRsvps
  allAccepted
}
    `;
export const UpdateCommunications_JobStageSettingsFragmentDoc = gql`
    fragment UpdateCommunications_jobStageSettings on JobStageSettingsV2 {
  id
  interviewMeetingLocationSettingWithLevel {
    interviewMeetingLocationSetting {
      ...LocationCard_interviewMeetingLocationSetting
    }
  }
}
    ${LocationCard_InterviewMeetingLocationSettingFragmentDoc}`;
export const Notification_EmployeeNotificationPrefFragmentDoc = gql`
    fragment Notification_EmployeeNotificationPref on EmployeeNotificationPreference {
  employeeId
  type
  medium
  value
}
    `;
export const InterviewModuleMemberMenuOptionsFragmentDoc = gql`
    fragment InterviewModuleMemberMenuOptions on InterviewModuleMember {
  employeeId
  interviewModuleId
  status
}
    `;
export const UseSchduleTaskMenuOptions_TaskFragmentDoc = gql`
    fragment UseSchduleTaskMenuOptions_task on Task {
  id
  isUrgent
  status
}
    `;
export const ActivityLogEditNoteDocument = gql`
    mutation ActivityLogEditNote($input: ActivityNoteEditInput!, $showTaskName: Boolean! = false) {
  activityNoteEdit(input: $input) {
    activityLog {
      ...ActivityLogNote_activityLog
    }
  }
}
    ${ActivityLogNote_ActivityLogFragmentDoc}`;
export type ActivityLogEditNoteMutationFn = Apollo.MutationFunction<ActivityLogEditNoteMutation, ActivityLogEditNoteMutationVariables>;

/**
 * __useActivityLogEditNoteMutation__
 *
 * To run a mutation, you first call `useActivityLogEditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityLogEditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityLogEditNoteMutation, { data, loading, error }] = useActivityLogEditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *      showTaskName: // value for 'showTaskName'
 *   },
 * });
 */
export function useActivityLogEditNoteMutation(baseOptions?: Apollo.MutationHookOptions<ActivityLogEditNoteMutation, ActivityLogEditNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivityLogEditNoteMutation, ActivityLogEditNoteMutationVariables>(ActivityLogEditNoteDocument, options);
      }
export type ActivityLogEditNoteMutationHookResult = ReturnType<typeof useActivityLogEditNoteMutation>;
export type ActivityLogEditNoteMutationResult = Apollo.MutationResult<ActivityLogEditNoteMutation>;
export type ActivityLogEditNoteMutationOptions = Apollo.BaseMutationOptions<ActivityLogEditNoteMutation, ActivityLogEditNoteMutationVariables>;
export const ActivityLogDeleteNoteDocument = gql`
    mutation ActivityLogDeleteNote($input: ActivityNoteDeleteInput!) {
  activityNoteDelete(input: $input) {
    result
  }
}
    `;
export type ActivityLogDeleteNoteMutationFn = Apollo.MutationFunction<ActivityLogDeleteNoteMutation, ActivityLogDeleteNoteMutationVariables>;

/**
 * __useActivityLogDeleteNoteMutation__
 *
 * To run a mutation, you first call `useActivityLogDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityLogDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityLogDeleteNoteMutation, { data, loading, error }] = useActivityLogDeleteNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivityLogDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<ActivityLogDeleteNoteMutation, ActivityLogDeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivityLogDeleteNoteMutation, ActivityLogDeleteNoteMutationVariables>(ActivityLogDeleteNoteDocument, options);
      }
export type ActivityLogDeleteNoteMutationHookResult = ReturnType<typeof useActivityLogDeleteNoteMutation>;
export type ActivityLogDeleteNoteMutationResult = Apollo.MutationResult<ActivityLogDeleteNoteMutation>;
export type ActivityLogDeleteNoteMutationOptions = Apollo.BaseMutationOptions<ActivityLogDeleteNoteMutation, ActivityLogDeleteNoteMutationVariables>;
export const ApplicationDetailsActivityLogsListDocument = gql`
    query ApplicationDetailsActivityLogsList($applicationId: uuid!, $input: ActivityLogsForSourceInput!, $showTaskName: Boolean! = true) {
  application(id: $applicationId) {
    id
    activityLogs(input: $input) {
      items {
        ...ApplicationDetailsActivityLogsList_activityLog
      }
      nextCursor
    }
  }
}
    ${ApplicationDetailsActivityLogsList_ActivityLogFragmentDoc}`;

/**
 * __useApplicationDetailsActivityLogsListQuery__
 *
 * To run a query within a React component, call `useApplicationDetailsActivityLogsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationDetailsActivityLogsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationDetailsActivityLogsListQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      input: // value for 'input'
 *      showTaskName: // value for 'showTaskName'
 *   },
 * });
 */
export function useApplicationDetailsActivityLogsListQuery(baseOptions: Apollo.QueryHookOptions<ApplicationDetailsActivityLogsListQuery, ApplicationDetailsActivityLogsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationDetailsActivityLogsListQuery, ApplicationDetailsActivityLogsListQueryVariables>(ApplicationDetailsActivityLogsListDocument, options);
      }
export function useApplicationDetailsActivityLogsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationDetailsActivityLogsListQuery, ApplicationDetailsActivityLogsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationDetailsActivityLogsListQuery, ApplicationDetailsActivityLogsListQueryVariables>(ApplicationDetailsActivityLogsListDocument, options);
        }
export type ApplicationDetailsActivityLogsListQueryHookResult = ReturnType<typeof useApplicationDetailsActivityLogsListQuery>;
export type ApplicationDetailsActivityLogsListLazyQueryHookResult = ReturnType<typeof useApplicationDetailsActivityLogsListLazyQuery>;
export type ApplicationDetailsActivityLogsListQueryResult = Apollo.QueryResult<ApplicationDetailsActivityLogsListQuery, ApplicationDetailsActivityLogsListQueryVariables>;
export const ActivityNoteCreateDocument = gql`
    mutation ActivityNoteCreate($input: ActivityNoteCreateInput!) {
  activityNoteCreate(input: $input) {
    activityLog {
      id
      createdAt
      activityType
      activityActorType
      activityActorEmployeeId
      actorEmployee {
        id
        fullName
        slackImageUrl
      }
      details {
        ... on ActivityLogNoteDetails {
          note
        }
      }
    }
  }
}
    `;
export type ActivityNoteCreateMutationFn = Apollo.MutationFunction<ActivityNoteCreateMutation, ActivityNoteCreateMutationVariables>;

/**
 * __useActivityNoteCreateMutation__
 *
 * To run a mutation, you first call `useActivityNoteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityNoteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityNoteCreateMutation, { data, loading, error }] = useActivityNoteCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivityNoteCreateMutation(baseOptions?: Apollo.MutationHookOptions<ActivityNoteCreateMutation, ActivityNoteCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivityNoteCreateMutation, ActivityNoteCreateMutationVariables>(ActivityNoteCreateDocument, options);
      }
export type ActivityNoteCreateMutationHookResult = ReturnType<typeof useActivityNoteCreateMutation>;
export type ActivityNoteCreateMutationResult = Apollo.MutationResult<ActivityNoteCreateMutation>;
export type ActivityNoteCreateMutationOptions = Apollo.BaseMutationOptions<ActivityNoteCreateMutation, ActivityNoteCreateMutationVariables>;
export const TaskDetailsActivityLogsListDocument = gql`
    query TaskDetailsActivityLogsList($taskId: uuid!, $input: ActivityLogsForSourceInput!, $showTaskName: Boolean! = false) {
  task(id: $taskId) {
    id
    activityLogs(input: $input) {
      items {
        ...TaskDetailsActivityLogsList_activityLog
      }
      nextCursor
    }
  }
}
    ${TaskDetailsActivityLogsList_ActivityLogFragmentDoc}`;

/**
 * __useTaskDetailsActivityLogsListQuery__
 *
 * To run a query within a React component, call `useTaskDetailsActivityLogsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDetailsActivityLogsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDetailsActivityLogsListQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *      showTaskName: // value for 'showTaskName'
 *   },
 * });
 */
export function useTaskDetailsActivityLogsListQuery(baseOptions: Apollo.QueryHookOptions<TaskDetailsActivityLogsListQuery, TaskDetailsActivityLogsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskDetailsActivityLogsListQuery, TaskDetailsActivityLogsListQueryVariables>(TaskDetailsActivityLogsListDocument, options);
      }
export function useTaskDetailsActivityLogsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskDetailsActivityLogsListQuery, TaskDetailsActivityLogsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskDetailsActivityLogsListQuery, TaskDetailsActivityLogsListQueryVariables>(TaskDetailsActivityLogsListDocument, options);
        }
export type TaskDetailsActivityLogsListQueryHookResult = ReturnType<typeof useTaskDetailsActivityLogsListQuery>;
export type TaskDetailsActivityLogsListLazyQueryHookResult = ReturnType<typeof useTaskDetailsActivityLogsListLazyQuery>;
export type TaskDetailsActivityLogsListQueryResult = Apollo.QueryResult<TaskDetailsActivityLogsListQuery, TaskDetailsActivityLogsListQueryVariables>;
export const CompanyHolidaysForDaysDocument = gql`
    query CompanyHolidaysForDays($input: CompanyHolidaysForDaysInput!) {
  companyHolidaysForDays(input: $input) {
    items {
      date
      companyHolidays {
        ...CompanyHolidayIcon_companyHolidays
      }
    }
  }
}
    ${CompanyHolidayIcon_CompanyHolidaysFragmentDoc}`;

/**
 * __useCompanyHolidaysForDaysQuery__
 *
 * To run a query within a React component, call `useCompanyHolidaysForDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyHolidaysForDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyHolidaysForDaysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyHolidaysForDaysQuery(baseOptions: Apollo.QueryHookOptions<CompanyHolidaysForDaysQuery, CompanyHolidaysForDaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyHolidaysForDaysQuery, CompanyHolidaysForDaysQueryVariables>(CompanyHolidaysForDaysDocument, options);
      }
export function useCompanyHolidaysForDaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyHolidaysForDaysQuery, CompanyHolidaysForDaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyHolidaysForDaysQuery, CompanyHolidaysForDaysQueryVariables>(CompanyHolidaysForDaysDocument, options);
        }
export type CompanyHolidaysForDaysQueryHookResult = ReturnType<typeof useCompanyHolidaysForDaysQuery>;
export type CompanyHolidaysForDaysLazyQueryHookResult = ReturnType<typeof useCompanyHolidaysForDaysLazyQuery>;
export type CompanyHolidaysForDaysQueryResult = Apollo.QueryResult<CompanyHolidaysForDaysQuery, CompanyHolidaysForDaysQueryVariables>;
export const CountryAttributeDocument = gql`
    query CountryAttribute($input: EmployeeAttributeNamesInput!) {
  employeeAttributeNames(input: $input) {
    items {
      id
      values {
        id
        value
      }
    }
  }
}
    `;

/**
 * __useCountryAttributeQuery__
 *
 * To run a query within a React component, call `useCountryAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryAttributeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountryAttributeQuery(baseOptions: Apollo.QueryHookOptions<CountryAttributeQuery, CountryAttributeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountryAttributeQuery, CountryAttributeQueryVariables>(CountryAttributeDocument, options);
      }
export function useCountryAttributeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountryAttributeQuery, CountryAttributeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountryAttributeQuery, CountryAttributeQueryVariables>(CountryAttributeDocument, options);
        }
export type CountryAttributeQueryHookResult = ReturnType<typeof useCountryAttributeQuery>;
export type CountryAttributeLazyQueryHookResult = ReturnType<typeof useCountryAttributeLazyQuery>;
export type CountryAttributeQueryResult = Apollo.QueryResult<CountryAttributeQuery, CountryAttributeQueryVariables>;
export const DebriefContentInterviewMenuDocument = gql`
    query DebriefContentInterviewMenu($input: TemplateTokenInput!, $zoomMeetingInfoInput: TokenZoomMeetingInput!) {
  templateToken(input: $input) {
    ZOOM_MEETING_INFO(input: $zoomMeetingInfoInput)
    ZOOM_MEETING_DIAL_IN_INFO(input: $zoomMeetingInfoInput)
  }
}
    `;

/**
 * __useDebriefContentInterviewMenuQuery__
 *
 * To run a query within a React component, call `useDebriefContentInterviewMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebriefContentInterviewMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebriefContentInterviewMenuQuery({
 *   variables: {
 *      input: // value for 'input'
 *      zoomMeetingInfoInput: // value for 'zoomMeetingInfoInput'
 *   },
 * });
 */
export function useDebriefContentInterviewMenuQuery(baseOptions: Apollo.QueryHookOptions<DebriefContentInterviewMenuQuery, DebriefContentInterviewMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DebriefContentInterviewMenuQuery, DebriefContentInterviewMenuQueryVariables>(DebriefContentInterviewMenuDocument, options);
      }
export function useDebriefContentInterviewMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DebriefContentInterviewMenuQuery, DebriefContentInterviewMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DebriefContentInterviewMenuQuery, DebriefContentInterviewMenuQueryVariables>(DebriefContentInterviewMenuDocument, options);
        }
export type DebriefContentInterviewMenuQueryHookResult = ReturnType<typeof useDebriefContentInterviewMenuQuery>;
export type DebriefContentInterviewMenuLazyQueryHookResult = ReturnType<typeof useDebriefContentInterviewMenuLazyQuery>;
export type DebriefContentInterviewMenuQueryResult = Apollo.QueryResult<DebriefContentInterviewMenuQuery, DebriefContentInterviewMenuQueryVariables>;
export const EmployeeListDocument = gql`
    query EmployeeList($input: EmployeeSearchInput!) {
  employeeSearch(input: $input) {
    total
    items {
      ...Employee
      upcomingInterviewsCount
      modulesTrainedCount
      modulesInTrainingCount
    }
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useEmployeeListQuery__
 *
 * To run a query within a React component, call `useEmployeeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeListQuery(baseOptions: Apollo.QueryHookOptions<EmployeeListQuery, EmployeeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeListQuery, EmployeeListQueryVariables>(EmployeeListDocument, options);
      }
export function useEmployeeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeListQuery, EmployeeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeListQuery, EmployeeListQueryVariables>(EmployeeListDocument, options);
        }
export type EmployeeListQueryHookResult = ReturnType<typeof useEmployeeListQuery>;
export type EmployeeListLazyQueryHookResult = ReturnType<typeof useEmployeeListLazyQuery>;
export type EmployeeListQueryResult = Apollo.QueryResult<EmployeeListQuery, EmployeeListQueryVariables>;
export const EmployeesDocument = gql`
    query Employees($input: EmployeesInput!) {
  employees(input: $input) {
    nextCursor
    items {
      ...Employee
    }
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeesQuery(baseOptions: Apollo.QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
      }
export function useEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
        }
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>;
export type EmployeesLazyQueryHookResult = ReturnType<typeof useEmployeesLazyQuery>;
export type EmployeesQueryResult = Apollo.QueryResult<EmployeesQuery, EmployeesQueryVariables>;
export const EmployeeByIdsDocument = gql`
    query EmployeeByIds($input: [uuid!]!) {
  employeeByIds(ids: $input) {
    ...Employee
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useEmployeeByIdsQuery__
 *
 * To run a query within a React component, call `useEmployeeByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeByIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeByIdsQuery(baseOptions: Apollo.QueryHookOptions<EmployeeByIdsQuery, EmployeeByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeByIdsQuery, EmployeeByIdsQueryVariables>(EmployeeByIdsDocument, options);
      }
export function useEmployeeByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeByIdsQuery, EmployeeByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeByIdsQuery, EmployeeByIdsQueryVariables>(EmployeeByIdsDocument, options);
        }
export type EmployeeByIdsQueryHookResult = ReturnType<typeof useEmployeeByIdsQuery>;
export type EmployeeByIdsLazyQueryHookResult = ReturnType<typeof useEmployeeByIdsLazyQuery>;
export type EmployeeByIdsQueryResult = Apollo.QueryResult<EmployeeByIdsQuery, EmployeeByIdsQueryVariables>;
export const EmployeeDetailsDocument = gql`
    query EmployeeDetails($id: uuid!) {
  employee(id: $id) {
    ...EmployeeDetailsFragment
  }
}
    ${EmployeeDetailsFragmentFragmentDoc}`;

/**
 * __useEmployeeDetailsQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeDetailsQuery(baseOptions: Apollo.QueryHookOptions<EmployeeDetailsQuery, EmployeeDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeDetailsQuery, EmployeeDetailsQueryVariables>(EmployeeDetailsDocument, options);
      }
export function useEmployeeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeDetailsQuery, EmployeeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeDetailsQuery, EmployeeDetailsQueryVariables>(EmployeeDetailsDocument, options);
        }
export type EmployeeDetailsQueryHookResult = ReturnType<typeof useEmployeeDetailsQuery>;
export type EmployeeDetailsLazyQueryHookResult = ReturnType<typeof useEmployeeDetailsLazyQuery>;
export type EmployeeDetailsQueryResult = Apollo.QueryResult<EmployeeDetailsQuery, EmployeeDetailsQueryVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($input: EmployeeUpdateInput!) {
  employeeUpdate(input: $input) {
    employee {
      ...Employee
    }
  }
}
    ${EmployeeFragmentDoc}`;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const EmployeesWorkHoursDocument = gql`
    query EmployeesWorkHours($ids: [uuid!]!) {
  employeeByIds(ids: $ids) {
    id
    timezone
    ...EmployeeWorkHours
  }
}
    ${EmployeeWorkHoursFragmentDoc}`;

/**
 * __useEmployeesWorkHoursQuery__
 *
 * To run a query within a React component, call `useEmployeesWorkHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesWorkHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesWorkHoursQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useEmployeesWorkHoursQuery(baseOptions: Apollo.QueryHookOptions<EmployeesWorkHoursQuery, EmployeesWorkHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesWorkHoursQuery, EmployeesWorkHoursQueryVariables>(EmployeesWorkHoursDocument, options);
      }
export function useEmployeesWorkHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesWorkHoursQuery, EmployeesWorkHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesWorkHoursQuery, EmployeesWorkHoursQueryVariables>(EmployeesWorkHoursDocument, options);
        }
export type EmployeesWorkHoursQueryHookResult = ReturnType<typeof useEmployeesWorkHoursQuery>;
export type EmployeesWorkHoursLazyQueryHookResult = ReturnType<typeof useEmployeesWorkHoursLazyQuery>;
export type EmployeesWorkHoursQueryResult = Apollo.QueryResult<EmployeesWorkHoursQuery, EmployeesWorkHoursQueryVariables>;
export const AttributesDocument = gql`
    query Attributes($input: EmployeeAttributeNamesInput!) {
  employeeAttributeNames(input: $input) {
    items {
      id
      name
      values {
        id
        name: value
        attribute: name {
          name
          id
          type
        }
      }
    }
  }
}
    `;

/**
 * __useAttributesQuery__
 *
 * To run a query within a React component, call `useAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttributesQuery(baseOptions: Apollo.QueryHookOptions<AttributesQuery, AttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttributesQuery, AttributesQueryVariables>(AttributesDocument, options);
      }
export function useAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttributesQuery, AttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttributesQuery, AttributesQueryVariables>(AttributesDocument, options);
        }
export type AttributesQueryHookResult = ReturnType<typeof useAttributesQuery>;
export type AttributesLazyQueryHookResult = ReturnType<typeof useAttributesLazyQuery>;
export type AttributesQueryResult = Apollo.QueryResult<AttributesQuery, AttributesQueryVariables>;
export const JobStagesByNameDocument = gql`
    query JobStagesByName($input: JobStagesInput!) {
  jobStagesByName(input: $input) {
    items
    nextCursor
  }
}
    `;

/**
 * __useJobStagesByNameQuery__
 *
 * To run a query within a React component, call `useJobStagesByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobStagesByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobStagesByNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobStagesByNameQuery(baseOptions: Apollo.QueryHookOptions<JobStagesByNameQuery, JobStagesByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobStagesByNameQuery, JobStagesByNameQueryVariables>(JobStagesByNameDocument, options);
      }
export function useJobStagesByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobStagesByNameQuery, JobStagesByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobStagesByNameQuery, JobStagesByNameQueryVariables>(JobStagesByNameDocument, options);
        }
export type JobStagesByNameQueryHookResult = ReturnType<typeof useJobStagesByNameQuery>;
export type JobStagesByNameLazyQueryHookResult = ReturnType<typeof useJobStagesByNameLazyQuery>;
export type JobStagesByNameQueryResult = Apollo.QueryResult<JobStagesByNameQuery, JobStagesByNameQueryVariables>;
export const JobsByIdsDocument = gql`
    query JobsByIds($ids: [uuid!]!) {
  jobByIds(ids: $ids) {
    id
    name
  }
}
    `;

/**
 * __useJobsByIdsQuery__
 *
 * To run a query within a React component, call `useJobsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useJobsByIdsQuery(baseOptions: Apollo.QueryHookOptions<JobsByIdsQuery, JobsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsByIdsQuery, JobsByIdsQueryVariables>(JobsByIdsDocument, options);
      }
export function useJobsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsByIdsQuery, JobsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsByIdsQuery, JobsByIdsQueryVariables>(JobsByIdsDocument, options);
        }
export type JobsByIdsQueryHookResult = ReturnType<typeof useJobsByIdsQuery>;
export type JobsByIdsLazyQueryHookResult = ReturnType<typeof useJobsByIdsLazyQuery>;
export type JobsByIdsQueryResult = Apollo.QueryResult<JobsByIdsQuery, JobsByIdsQueryVariables>;
export const CandidateListJobsDocument = gql`
    query CandidateListJobs($input: JobsInput!) {
  jobs(input: $input) {
    items {
      id
      name
    }
    nextCursor
  }
}
    `;

/**
 * __useCandidateListJobsQuery__
 *
 * To run a query within a React component, call `useCandidateListJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateListJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateListJobsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCandidateListJobsQuery(baseOptions: Apollo.QueryHookOptions<CandidateListJobsQuery, CandidateListJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateListJobsQuery, CandidateListJobsQueryVariables>(CandidateListJobsDocument, options);
      }
export function useCandidateListJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateListJobsQuery, CandidateListJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateListJobsQuery, CandidateListJobsQueryVariables>(CandidateListJobsDocument, options);
        }
export type CandidateListJobsQueryHookResult = ReturnType<typeof useCandidateListJobsQuery>;
export type CandidateListJobsLazyQueryHookResult = ReturnType<typeof useCandidateListJobsLazyQuery>;
export type CandidateListJobsQueryResult = Apollo.QueryResult<CandidateListJobsQuery, CandidateListJobsQueryVariables>;
export const ManagersDocument = gql`
    query Managers($input: ManagersInput!) {
  managers(input: $input) {
    items {
      id
      slackImageUrl
      fullName
    }
    nextCursor
  }
}
    `;

/**
 * __useManagersQuery__
 *
 * To run a query within a React component, call `useManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManagersQuery(baseOptions: Apollo.QueryHookOptions<ManagersQuery, ManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManagersQuery, ManagersQueryVariables>(ManagersDocument, options);
      }
export function useManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagersQuery, ManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManagersQuery, ManagersQueryVariables>(ManagersDocument, options);
        }
export type ManagersQueryHookResult = ReturnType<typeof useManagersQuery>;
export type ManagersLazyQueryHookResult = ReturnType<typeof useManagersLazyQuery>;
export type ManagersQueryResult = Apollo.QueryResult<ManagersQuery, ManagersQueryVariables>;
export const ListSelectedManagersByIdsDocument = gql`
    query ListSelectedManagersByIds($ids: [uuid!]!) {
  employeeByIds(ids: $ids) {
    id
    slackImageUrl
    fullName
  }
}
    `;

/**
 * __useListSelectedManagersByIdsQuery__
 *
 * To run a query within a React component, call `useListSelectedManagersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSelectedManagersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSelectedManagersByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useListSelectedManagersByIdsQuery(baseOptions: Apollo.QueryHookOptions<ListSelectedManagersByIdsQuery, ListSelectedManagersByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSelectedManagersByIdsQuery, ListSelectedManagersByIdsQueryVariables>(ListSelectedManagersByIdsDocument, options);
      }
export function useListSelectedManagersByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSelectedManagersByIdsQuery, ListSelectedManagersByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSelectedManagersByIdsQuery, ListSelectedManagersByIdsQueryVariables>(ListSelectedManagersByIdsDocument, options);
        }
export type ListSelectedManagersByIdsQueryHookResult = ReturnType<typeof useListSelectedManagersByIdsQuery>;
export type ListSelectedManagersByIdsLazyQueryHookResult = ReturnType<typeof useListSelectedManagersByIdsLazyQuery>;
export type ListSelectedManagersByIdsQueryResult = Apollo.QueryResult<ListSelectedManagersByIdsQuery, ListSelectedManagersByIdsQueryVariables>;
export const InterviewModulesSelectDocument = gql`
    query InterviewModulesSelect($input: InterviewModuleSearchInput!) {
  interviewModuleSearch(input: $input) {
    total
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useInterviewModulesSelectQuery__
 *
 * To run a query within a React component, call `useInterviewModulesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModulesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModulesSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModulesSelectQuery(baseOptions: Apollo.QueryHookOptions<InterviewModulesSelectQuery, InterviewModulesSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModulesSelectQuery, InterviewModulesSelectQueryVariables>(InterviewModulesSelectDocument, options);
      }
export function useInterviewModulesSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModulesSelectQuery, InterviewModulesSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModulesSelectQuery, InterviewModulesSelectQueryVariables>(InterviewModulesSelectDocument, options);
        }
export type InterviewModulesSelectQueryHookResult = ReturnType<typeof useInterviewModulesSelectQuery>;
export type InterviewModulesSelectLazyQueryHookResult = ReturnType<typeof useInterviewModulesSelectLazyQuery>;
export type InterviewModulesSelectQueryResult = Apollo.QueryResult<InterviewModulesSelectQuery, InterviewModulesSelectQueryVariables>;
export const ListSelectedModulesByIdDocument = gql`
    query ListSelectedModulesById($ids: [uuid!]!) {
  interviewModuleByIds(ids: $ids) {
    id
    name
  }
}
    `;

/**
 * __useListSelectedModulesByIdQuery__
 *
 * To run a query within a React component, call `useListSelectedModulesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSelectedModulesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSelectedModulesByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useListSelectedModulesByIdQuery(baseOptions: Apollo.QueryHookOptions<ListSelectedModulesByIdQuery, ListSelectedModulesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSelectedModulesByIdQuery, ListSelectedModulesByIdQueryVariables>(ListSelectedModulesByIdDocument, options);
      }
export function useListSelectedModulesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSelectedModulesByIdQuery, ListSelectedModulesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSelectedModulesByIdQuery, ListSelectedModulesByIdQueryVariables>(ListSelectedModulesByIdDocument, options);
        }
export type ListSelectedModulesByIdQueryHookResult = ReturnType<typeof useListSelectedModulesByIdQuery>;
export type ListSelectedModulesByIdLazyQueryHookResult = ReturnType<typeof useListSelectedModulesByIdLazyQuery>;
export type ListSelectedModulesByIdQueryResult = Apollo.QueryResult<ListSelectedModulesByIdQuery, ListSelectedModulesByIdQueryVariables>;
export const TaskTagsDocument = gql`
    query TaskTags {
  taskTags(input: {page: {offset: 0, limit: 100}}) {
    items {
      ...TaskTags_selectedTaskTag
    }
  }
}
    ${TaskTags_SelectedTaskTagFragmentDoc}`;

/**
 * __useTaskTagsQuery__
 *
 * To run a query within a React component, call `useTaskTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskTagsQuery(baseOptions?: Apollo.QueryHookOptions<TaskTagsQuery, TaskTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskTagsQuery, TaskTagsQueryVariables>(TaskTagsDocument, options);
      }
export function useTaskTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskTagsQuery, TaskTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskTagsQuery, TaskTagsQueryVariables>(TaskTagsDocument, options);
        }
export type TaskTagsQueryHookResult = ReturnType<typeof useTaskTagsQuery>;
export type TaskTagsLazyQueryHookResult = ReturnType<typeof useTaskTagsLazyQuery>;
export type TaskTagsQueryResult = Apollo.QueryResult<TaskTagsQuery, TaskTagsQueryVariables>;
export const InterviewMeetingLocationMenuOrgIntegrationsDocument = gql`
    query InterviewMeetingLocationMenuOrgIntegrations {
  thisOrg {
    id
    integrations {
      google {
        adminUserId
      }
      microsoft {
        active
      }
      microsoftGccHigh {
        active
      }
      zoom {
        active
      }
    }
  }
}
    `;

/**
 * __useInterviewMeetingLocationMenuOrgIntegrationsQuery__
 *
 * To run a query within a React component, call `useInterviewMeetingLocationMenuOrgIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewMeetingLocationMenuOrgIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewMeetingLocationMenuOrgIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterviewMeetingLocationMenuOrgIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<InterviewMeetingLocationMenuOrgIntegrationsQuery, InterviewMeetingLocationMenuOrgIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewMeetingLocationMenuOrgIntegrationsQuery, InterviewMeetingLocationMenuOrgIntegrationsQueryVariables>(InterviewMeetingLocationMenuOrgIntegrationsDocument, options);
      }
export function useInterviewMeetingLocationMenuOrgIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewMeetingLocationMenuOrgIntegrationsQuery, InterviewMeetingLocationMenuOrgIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewMeetingLocationMenuOrgIntegrationsQuery, InterviewMeetingLocationMenuOrgIntegrationsQueryVariables>(InterviewMeetingLocationMenuOrgIntegrationsDocument, options);
        }
export type InterviewMeetingLocationMenuOrgIntegrationsQueryHookResult = ReturnType<typeof useInterviewMeetingLocationMenuOrgIntegrationsQuery>;
export type InterviewMeetingLocationMenuOrgIntegrationsLazyQueryHookResult = ReturnType<typeof useInterviewMeetingLocationMenuOrgIntegrationsLazyQuery>;
export type InterviewMeetingLocationMenuOrgIntegrationsQueryResult = Apollo.QueryResult<InterviewMeetingLocationMenuOrgIntegrationsQuery, InterviewMeetingLocationMenuOrgIntegrationsQueryVariables>;
export const EmployeesAndModulesSearchV2Document = gql`
    query EmployeesAndModulesSearchV2($employeeSearchInput: EmployeeSearchInput!, $moduleSearchInput: InterviewModuleSearchInput!) {
  employeeSearch(input: $employeeSearchInput) {
    items {
      ...BaseEditInterviewSeatModuleEmployeeSearchV2
    }
  }
  interviewModuleSearch(input: $moduleSearchInput) {
    items {
      ...BaseEditInterviewSeatModuleInterviewModuleSearchV2
    }
  }
}
    ${BaseEditInterviewSeatModuleEmployeeSearchV2FragmentDoc}
${BaseEditInterviewSeatModuleInterviewModuleSearchV2FragmentDoc}`;

/**
 * __useEmployeesAndModulesSearchV2Query__
 *
 * To run a query within a React component, call `useEmployeesAndModulesSearchV2Query` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesAndModulesSearchV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesAndModulesSearchV2Query({
 *   variables: {
 *      employeeSearchInput: // value for 'employeeSearchInput'
 *      moduleSearchInput: // value for 'moduleSearchInput'
 *   },
 * });
 */
export function useEmployeesAndModulesSearchV2Query(baseOptions: Apollo.QueryHookOptions<EmployeesAndModulesSearchV2Query, EmployeesAndModulesSearchV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesAndModulesSearchV2Query, EmployeesAndModulesSearchV2QueryVariables>(EmployeesAndModulesSearchV2Document, options);
      }
export function useEmployeesAndModulesSearchV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesAndModulesSearchV2Query, EmployeesAndModulesSearchV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesAndModulesSearchV2Query, EmployeesAndModulesSearchV2QueryVariables>(EmployeesAndModulesSearchV2Document, options);
        }
export type EmployeesAndModulesSearchV2QueryHookResult = ReturnType<typeof useEmployeesAndModulesSearchV2Query>;
export type EmployeesAndModulesSearchV2LazyQueryHookResult = ReturnType<typeof useEmployeesAndModulesSearchV2LazyQuery>;
export type EmployeesAndModulesSearchV2QueryResult = Apollo.QueryResult<EmployeesAndModulesSearchV2Query, EmployeesAndModulesSearchV2QueryVariables>;
export const InterviewModuleMemberFilteredByAttributesV2Document = gql`
    query InterviewModuleMemberFilteredByAttributesV2($input: InterviewModuleMemberSearchInput!) {
  interviewModuleMemberSearch(input: $input) {
    items {
      ...EditModuleSeatInterviewModuleV2
    }
  }
}
    ${EditModuleSeatInterviewModuleV2FragmentDoc}`;

/**
 * __useInterviewModuleMemberFilteredByAttributesV2Query__
 *
 * To run a query within a React component, call `useInterviewModuleMemberFilteredByAttributesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberFilteredByAttributesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMemberFilteredByAttributesV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleMemberFilteredByAttributesV2Query(baseOptions: Apollo.QueryHookOptions<InterviewModuleMemberFilteredByAttributesV2Query, InterviewModuleMemberFilteredByAttributesV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMemberFilteredByAttributesV2Query, InterviewModuleMemberFilteredByAttributesV2QueryVariables>(InterviewModuleMemberFilteredByAttributesV2Document, options);
      }
export function useInterviewModuleMemberFilteredByAttributesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMemberFilteredByAttributesV2Query, InterviewModuleMemberFilteredByAttributesV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMemberFilteredByAttributesV2Query, InterviewModuleMemberFilteredByAttributesV2QueryVariables>(InterviewModuleMemberFilteredByAttributesV2Document, options);
        }
export type InterviewModuleMemberFilteredByAttributesV2QueryHookResult = ReturnType<typeof useInterviewModuleMemberFilteredByAttributesV2Query>;
export type InterviewModuleMemberFilteredByAttributesV2LazyQueryHookResult = ReturnType<typeof useInterviewModuleMemberFilteredByAttributesV2LazyQuery>;
export type InterviewModuleMemberFilteredByAttributesV2QueryResult = Apollo.QueryResult<InterviewModuleMemberFilteredByAttributesV2Query, InterviewModuleMemberFilteredByAttributesV2QueryVariables>;
export const ModuleMemberStatsV2Document = gql`
    query ModuleMemberStatsV2 {
  interviewModuleMemberStats {
    interviewModule {
      ...EditModuleSeatInterviewStatsV2
    }
  }
}
    ${EditModuleSeatInterviewStatsV2FragmentDoc}`;

/**
 * __useModuleMemberStatsV2Query__
 *
 * To run a query within a React component, call `useModuleMemberStatsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useModuleMemberStatsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleMemberStatsV2Query({
 *   variables: {
 *   },
 * });
 */
export function useModuleMemberStatsV2Query(baseOptions?: Apollo.QueryHookOptions<ModuleMemberStatsV2Query, ModuleMemberStatsV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModuleMemberStatsV2Query, ModuleMemberStatsV2QueryVariables>(ModuleMemberStatsV2Document, options);
      }
export function useModuleMemberStatsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleMemberStatsV2Query, ModuleMemberStatsV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModuleMemberStatsV2Query, ModuleMemberStatsV2QueryVariables>(ModuleMemberStatsV2Document, options);
        }
export type ModuleMemberStatsV2QueryHookResult = ReturnType<typeof useModuleMemberStatsV2Query>;
export type ModuleMemberStatsV2LazyQueryHookResult = ReturnType<typeof useModuleMemberStatsV2LazyQuery>;
export type ModuleMemberStatsV2QueryResult = Apollo.QueryResult<ModuleMemberStatsV2Query, ModuleMemberStatsV2QueryVariables>;
export const ResetInterviewPlanToBaseJobStageDocument = gql`
    query ResetInterviewPlanToBaseJobStage($jobStageId: uuid!) {
  jobStage(id: $jobStageId) {
    id
    ...getSaveInterviewPlanInput_interviewPlan
  }
}
    ${GetSaveInterviewPlanInput_InterviewPlanFragmentDoc}`;

/**
 * __useResetInterviewPlanToBaseJobStageQuery__
 *
 * To run a query within a React component, call `useResetInterviewPlanToBaseJobStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useResetInterviewPlanToBaseJobStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResetInterviewPlanToBaseJobStageQuery({
 *   variables: {
 *      jobStageId: // value for 'jobStageId'
 *   },
 * });
 */
export function useResetInterviewPlanToBaseJobStageQuery(baseOptions: Apollo.QueryHookOptions<ResetInterviewPlanToBaseJobStageQuery, ResetInterviewPlanToBaseJobStageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResetInterviewPlanToBaseJobStageQuery, ResetInterviewPlanToBaseJobStageQueryVariables>(ResetInterviewPlanToBaseJobStageDocument, options);
      }
export function useResetInterviewPlanToBaseJobStageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResetInterviewPlanToBaseJobStageQuery, ResetInterviewPlanToBaseJobStageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResetInterviewPlanToBaseJobStageQuery, ResetInterviewPlanToBaseJobStageQueryVariables>(ResetInterviewPlanToBaseJobStageDocument, options);
        }
export type ResetInterviewPlanToBaseJobStageQueryHookResult = ReturnType<typeof useResetInterviewPlanToBaseJobStageQuery>;
export type ResetInterviewPlanToBaseJobStageLazyQueryHookResult = ReturnType<typeof useResetInterviewPlanToBaseJobStageLazyQuery>;
export type ResetInterviewPlanToBaseJobStageQueryResult = Apollo.QueryResult<ResetInterviewPlanToBaseJobStageQuery, ResetInterviewPlanToBaseJobStageQueryVariables>;
export const ResetPlanToBaseJobStageDocument = gql`
    mutation ResetPlanToBaseJobStage($input: JobStageInterviewPlanUpsertInput!) {
  jobStageInterviewPlanUpsert(input: $input) {
    jobStage {
      id
      ...getSaveInterviewPlanInput_interviewPlan
    }
  }
}
    ${GetSaveInterviewPlanInput_InterviewPlanFragmentDoc}`;
export type ResetPlanToBaseJobStageMutationFn = Apollo.MutationFunction<ResetPlanToBaseJobStageMutation, ResetPlanToBaseJobStageMutationVariables>;

/**
 * __useResetPlanToBaseJobStageMutation__
 *
 * To run a mutation, you first call `useResetPlanToBaseJobStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPlanToBaseJobStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPlanToBaseJobStageMutation, { data, loading, error }] = useResetPlanToBaseJobStageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPlanToBaseJobStageMutation(baseOptions?: Apollo.MutationHookOptions<ResetPlanToBaseJobStageMutation, ResetPlanToBaseJobStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPlanToBaseJobStageMutation, ResetPlanToBaseJobStageMutationVariables>(ResetPlanToBaseJobStageDocument, options);
      }
export type ResetPlanToBaseJobStageMutationHookResult = ReturnType<typeof useResetPlanToBaseJobStageMutation>;
export type ResetPlanToBaseJobStageMutationResult = Apollo.MutationResult<ResetPlanToBaseJobStageMutation>;
export type ResetPlanToBaseJobStageMutationOptions = Apollo.BaseMutationOptions<ResetPlanToBaseJobStageMutation, ResetPlanToBaseJobStageMutationVariables>;
export const MeetingRoomSuggestionsDocument = gql`
    query MeetingRoomSuggestions($input: MeetingRoomSuggestionsInput!) {
  meetingRoomSuggestions(input: $input) {
    items {
      room {
        id
        name
        floorName
        description
        capacity
        features
      }
      isBusy
    }
    nextCursor
  }
}
    `;

/**
 * __useMeetingRoomSuggestionsQuery__
 *
 * To run a query within a React component, call `useMeetingRoomSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingRoomSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingRoomSuggestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMeetingRoomSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<MeetingRoomSuggestionsQuery, MeetingRoomSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeetingRoomSuggestionsQuery, MeetingRoomSuggestionsQueryVariables>(MeetingRoomSuggestionsDocument, options);
      }
export function useMeetingRoomSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeetingRoomSuggestionsQuery, MeetingRoomSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeetingRoomSuggestionsQuery, MeetingRoomSuggestionsQueryVariables>(MeetingRoomSuggestionsDocument, options);
        }
export type MeetingRoomSuggestionsQueryHookResult = ReturnType<typeof useMeetingRoomSuggestionsQuery>;
export type MeetingRoomSuggestionsLazyQueryHookResult = ReturnType<typeof useMeetingRoomSuggestionsLazyQuery>;
export type MeetingRoomSuggestionsQueryResult = Apollo.QueryResult<MeetingRoomSuggestionsQuery, MeetingRoomSuggestionsQueryVariables>;
export const MeetingRoomDocument = gql`
    query MeetingRoom($id: uuid!) {
  meetingRoom(id: $id) {
    ...MeetingRoomFragment
  }
}
    ${MeetingRoomFragmentFragmentDoc}`;

/**
 * __useMeetingRoomQuery__
 *
 * To run a query within a React component, call `useMeetingRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMeetingRoomQuery(baseOptions: Apollo.QueryHookOptions<MeetingRoomQuery, MeetingRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeetingRoomQuery, MeetingRoomQueryVariables>(MeetingRoomDocument, options);
      }
export function useMeetingRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeetingRoomQuery, MeetingRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeetingRoomQuery, MeetingRoomQueryVariables>(MeetingRoomDocument, options);
        }
export type MeetingRoomQueryHookResult = ReturnType<typeof useMeetingRoomQuery>;
export type MeetingRoomLazyQueryHookResult = ReturnType<typeof useMeetingRoomLazyQuery>;
export type MeetingRoomQueryResult = Apollo.QueryResult<MeetingRoomQuery, MeetingRoomQueryVariables>;
export const MeetingRoomByIdsDocument = gql`
    query MeetingRoomByIds($ids: [uuid!]!) {
  meetingRoomByIds(ids: $ids) {
    ...MeetingRoomFragment
  }
}
    ${MeetingRoomFragmentFragmentDoc}`;

/**
 * __useMeetingRoomByIdsQuery__
 *
 * To run a query within a React component, call `useMeetingRoomByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingRoomByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingRoomByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMeetingRoomByIdsQuery(baseOptions: Apollo.QueryHookOptions<MeetingRoomByIdsQuery, MeetingRoomByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeetingRoomByIdsQuery, MeetingRoomByIdsQueryVariables>(MeetingRoomByIdsDocument, options);
      }
export function useMeetingRoomByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeetingRoomByIdsQuery, MeetingRoomByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeetingRoomByIdsQuery, MeetingRoomByIdsQueryVariables>(MeetingRoomByIdsDocument, options);
        }
export type MeetingRoomByIdsQueryHookResult = ReturnType<typeof useMeetingRoomByIdsQuery>;
export type MeetingRoomByIdsLazyQueryHookResult = ReturnType<typeof useMeetingRoomByIdsLazyQuery>;
export type MeetingRoomByIdsQueryResult = Apollo.QueryResult<MeetingRoomByIdsQuery, MeetingRoomByIdsQueryVariables>;
export const InterviewAdjustRsvpDocument = gql`
    mutation interviewAdjustRsvp($applicationStageInterviewId: uuid!, $interviewerId: uuid!, $reason: DeclineReason, $comment: String, $responseStatus: ResponseStatus!) {
  interviewAdjustRsvp(
    input: {applicationStageInterviewId: $applicationStageInterviewId, interviewerId: $interviewerId, reason: $reason, comment: $comment, responseStatus: $responseStatus}
  ) {
    result
  }
}
    `;
export type InterviewAdjustRsvpMutationFn = Apollo.MutationFunction<InterviewAdjustRsvpMutation, InterviewAdjustRsvpMutationVariables>;

/**
 * __useInterviewAdjustRsvpMutation__
 *
 * To run a mutation, you first call `useInterviewAdjustRsvpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewAdjustRsvpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewAdjustRsvpMutation, { data, loading, error }] = useInterviewAdjustRsvpMutation({
 *   variables: {
 *      applicationStageInterviewId: // value for 'applicationStageInterviewId'
 *      interviewerId: // value for 'interviewerId'
 *      reason: // value for 'reason'
 *      comment: // value for 'comment'
 *      responseStatus: // value for 'responseStatus'
 *   },
 * });
 */
export function useInterviewAdjustRsvpMutation(baseOptions?: Apollo.MutationHookOptions<InterviewAdjustRsvpMutation, InterviewAdjustRsvpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewAdjustRsvpMutation, InterviewAdjustRsvpMutationVariables>(InterviewAdjustRsvpDocument, options);
      }
export type InterviewAdjustRsvpMutationHookResult = ReturnType<typeof useInterviewAdjustRsvpMutation>;
export type InterviewAdjustRsvpMutationResult = Apollo.MutationResult<InterviewAdjustRsvpMutation>;
export type InterviewAdjustRsvpMutationOptions = Apollo.BaseMutationOptions<InterviewAdjustRsvpMutation, InterviewAdjustRsvpMutationVariables>;
export const ScheduleContentInterviewMenuDocument = gql`
    query ScheduleContentInterviewMenu($input: TemplateTokenInput!, $zoomMeetingInfoInput: TokenZoomMeetingInput!, $zoomMeetingUserId: String!) {
  templateToken(input: $input) {
    ZOOM_MEETING_INFO(input: $zoomMeetingInfoInput)
    ZOOM_MEETING_DIAL_IN_INFO(input: $zoomMeetingInfoInput)
  }
  zoomUsersByIds(input: {zoomUserIds: [$zoomMeetingUserId]}) {
    zoomUserId
    firstName
    lastName
    email
  }
}
    `;

/**
 * __useScheduleContentInterviewMenuQuery__
 *
 * To run a query within a React component, call `useScheduleContentInterviewMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleContentInterviewMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleContentInterviewMenuQuery({
 *   variables: {
 *      input: // value for 'input'
 *      zoomMeetingInfoInput: // value for 'zoomMeetingInfoInput'
 *      zoomMeetingUserId: // value for 'zoomMeetingUserId'
 *   },
 * });
 */
export function useScheduleContentInterviewMenuQuery(baseOptions: Apollo.QueryHookOptions<ScheduleContentInterviewMenuQuery, ScheduleContentInterviewMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleContentInterviewMenuQuery, ScheduleContentInterviewMenuQueryVariables>(ScheduleContentInterviewMenuDocument, options);
      }
export function useScheduleContentInterviewMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleContentInterviewMenuQuery, ScheduleContentInterviewMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleContentInterviewMenuQuery, ScheduleContentInterviewMenuQueryVariables>(ScheduleContentInterviewMenuDocument, options);
        }
export type ScheduleContentInterviewMenuQueryHookResult = ReturnType<typeof useScheduleContentInterviewMenuQuery>;
export type ScheduleContentInterviewMenuLazyQueryHookResult = ReturnType<typeof useScheduleContentInterviewMenuLazyQuery>;
export type ScheduleContentInterviewMenuQueryResult = Apollo.QueryResult<ScheduleContentInterviewMenuQuery, ScheduleContentInterviewMenuQueryVariables>;
export const ListAtsUsersDocument = gql`
    query ListAtsUsers($input: AtsUserInput!) {
  atsUsers(input: $input) {
    items {
      ...AtsUser
    }
  }
}
    ${AtsUserFragmentDoc}`;

/**
 * __useListAtsUsersQuery__
 *
 * To run a query within a React component, call `useListAtsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAtsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAtsUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListAtsUsersQuery(baseOptions: Apollo.QueryHookOptions<ListAtsUsersQuery, ListAtsUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAtsUsersQuery, ListAtsUsersQueryVariables>(ListAtsUsersDocument, options);
      }
export function useListAtsUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAtsUsersQuery, ListAtsUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAtsUsersQuery, ListAtsUsersQueryVariables>(ListAtsUsersDocument, options);
        }
export type ListAtsUsersQueryHookResult = ReturnType<typeof useListAtsUsersQuery>;
export type ListAtsUsersLazyQueryHookResult = ReturnType<typeof useListAtsUsersLazyQuery>;
export type ListAtsUsersQueryResult = Apollo.QueryResult<ListAtsUsersQuery, ListAtsUsersQueryVariables>;
export const BrandingThemeSelectDocument = gql`
    query BrandingThemeSelect {
  brandingThemes {
    items {
      ...Row_BrandingOptions
    }
  }
}
    ${Row_BrandingOptionsFragmentDoc}`;

/**
 * __useBrandingThemeSelectQuery__
 *
 * To run a query within a React component, call `useBrandingThemeSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandingThemeSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandingThemeSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandingThemeSelectQuery(baseOptions?: Apollo.QueryHookOptions<BrandingThemeSelectQuery, BrandingThemeSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandingThemeSelectQuery, BrandingThemeSelectQueryVariables>(BrandingThemeSelectDocument, options);
      }
export function useBrandingThemeSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandingThemeSelectQuery, BrandingThemeSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandingThemeSelectQuery, BrandingThemeSelectQueryVariables>(BrandingThemeSelectDocument, options);
        }
export type BrandingThemeSelectQueryHookResult = ReturnType<typeof useBrandingThemeSelectQuery>;
export type BrandingThemeSelectLazyQueryHookResult = ReturnType<typeof useBrandingThemeSelectLazyQuery>;
export type BrandingThemeSelectQueryResult = Apollo.QueryResult<BrandingThemeSelectQuery, BrandingThemeSelectQueryVariables>;
export const CandidateTimezoneSelectUpdateDocument = gql`
    mutation CandidateTimezoneSelectUpdate($input: CandidateUpdateInput!) {
  candidateUpdate(input: $input) {
    candidate {
      id
      timezone
    }
  }
}
    `;
export type CandidateTimezoneSelectUpdateMutationFn = Apollo.MutationFunction<CandidateTimezoneSelectUpdateMutation, CandidateTimezoneSelectUpdateMutationVariables>;

/**
 * __useCandidateTimezoneSelectUpdateMutation__
 *
 * To run a mutation, you first call `useCandidateTimezoneSelectUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateTimezoneSelectUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateTimezoneSelectUpdateMutation, { data, loading, error }] = useCandidateTimezoneSelectUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCandidateTimezoneSelectUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CandidateTimezoneSelectUpdateMutation, CandidateTimezoneSelectUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateTimezoneSelectUpdateMutation, CandidateTimezoneSelectUpdateMutationVariables>(CandidateTimezoneSelectUpdateDocument, options);
      }
export type CandidateTimezoneSelectUpdateMutationHookResult = ReturnType<typeof useCandidateTimezoneSelectUpdateMutation>;
export type CandidateTimezoneSelectUpdateMutationResult = Apollo.MutationResult<CandidateTimezoneSelectUpdateMutation>;
export type CandidateTimezoneSelectUpdateMutationOptions = Apollo.BaseMutationOptions<CandidateTimezoneSelectUpdateMutation, CandidateTimezoneSelectUpdateMutationVariables>;
export const CoordinatorSelectCandidateUpdateDocument = gql`
    mutation CoordinatorSelectCandidateUpdate($input: CandidateUpdateInput!) {
  candidateUpdate(input: $input) {
    candidate {
      id
      coordinator {
        id
        ...SelectButtonLabel_employee
      }
    }
  }
}
    ${SelectButtonLabel_EmployeeFragmentDoc}`;
export type CoordinatorSelectCandidateUpdateMutationFn = Apollo.MutationFunction<CoordinatorSelectCandidateUpdateMutation, CoordinatorSelectCandidateUpdateMutationVariables>;

/**
 * __useCoordinatorSelectCandidateUpdateMutation__
 *
 * To run a mutation, you first call `useCoordinatorSelectCandidateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoordinatorSelectCandidateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coordinatorSelectCandidateUpdateMutation, { data, loading, error }] = useCoordinatorSelectCandidateUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoordinatorSelectCandidateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CoordinatorSelectCandidateUpdateMutation, CoordinatorSelectCandidateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoordinatorSelectCandidateUpdateMutation, CoordinatorSelectCandidateUpdateMutationVariables>(CoordinatorSelectCandidateUpdateDocument, options);
      }
export type CoordinatorSelectCandidateUpdateMutationHookResult = ReturnType<typeof useCoordinatorSelectCandidateUpdateMutation>;
export type CoordinatorSelectCandidateUpdateMutationResult = Apollo.MutationResult<CoordinatorSelectCandidateUpdateMutation>;
export type CoordinatorSelectCandidateUpdateMutationOptions = Apollo.BaseMutationOptions<CoordinatorSelectCandidateUpdateMutation, CoordinatorSelectCandidateUpdateMutationVariables>;
export const RecruiterSelectCandidateUpdateDocument = gql`
    mutation RecruiterSelectCandidateUpdate($input: CandidateUpdateInput!) {
  candidateUpdate(input: $input) {
    candidate {
      id
      recruiter {
        id
        ...SelectButtonLabel_employee
      }
    }
  }
}
    ${SelectButtonLabel_EmployeeFragmentDoc}`;
export type RecruiterSelectCandidateUpdateMutationFn = Apollo.MutationFunction<RecruiterSelectCandidateUpdateMutation, RecruiterSelectCandidateUpdateMutationVariables>;

/**
 * __useRecruiterSelectCandidateUpdateMutation__
 *
 * To run a mutation, you first call `useRecruiterSelectCandidateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecruiterSelectCandidateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recruiterSelectCandidateUpdateMutation, { data, loading, error }] = useRecruiterSelectCandidateUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecruiterSelectCandidateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<RecruiterSelectCandidateUpdateMutation, RecruiterSelectCandidateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecruiterSelectCandidateUpdateMutation, RecruiterSelectCandidateUpdateMutationVariables>(RecruiterSelectCandidateUpdateDocument, options);
      }
export type RecruiterSelectCandidateUpdateMutationHookResult = ReturnType<typeof useRecruiterSelectCandidateUpdateMutation>;
export type RecruiterSelectCandidateUpdateMutationResult = Apollo.MutationResult<RecruiterSelectCandidateUpdateMutation>;
export type RecruiterSelectCandidateUpdateMutationOptions = Apollo.BaseMutationOptions<RecruiterSelectCandidateUpdateMutation, RecruiterSelectCandidateUpdateMutationVariables>;
export const SourcerSelectCandidateUpdateDocument = gql`
    mutation SourcerSelectCandidateUpdate($input: CandidateUpdateInput!) {
  candidateUpdate(input: $input) {
    candidate {
      id
      sourcer {
        id
        ...SelectButtonLabel_employee
      }
    }
  }
}
    ${SelectButtonLabel_EmployeeFragmentDoc}`;
export type SourcerSelectCandidateUpdateMutationFn = Apollo.MutationFunction<SourcerSelectCandidateUpdateMutation, SourcerSelectCandidateUpdateMutationVariables>;

/**
 * __useSourcerSelectCandidateUpdateMutation__
 *
 * To run a mutation, you first call `useSourcerSelectCandidateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSourcerSelectCandidateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sourcerSelectCandidateUpdateMutation, { data, loading, error }] = useSourcerSelectCandidateUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSourcerSelectCandidateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SourcerSelectCandidateUpdateMutation, SourcerSelectCandidateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SourcerSelectCandidateUpdateMutation, SourcerSelectCandidateUpdateMutationVariables>(SourcerSelectCandidateUpdateDocument, options);
      }
export type SourcerSelectCandidateUpdateMutationHookResult = ReturnType<typeof useSourcerSelectCandidateUpdateMutation>;
export type SourcerSelectCandidateUpdateMutationResult = Apollo.MutationResult<SourcerSelectCandidateUpdateMutation>;
export type SourcerSelectCandidateUpdateMutationOptions = Apollo.BaseMutationOptions<SourcerSelectCandidateUpdateMutation, SourcerSelectCandidateUpdateMutationVariables>;
export const InterviewModuleSelectDocument = gql`
    query InterviewModuleSelect($input: InterviewModuleSearchInput!) {
  interviewModuleSearch(input: $input) {
    total
    items {
      ...InterviewModule
    }
  }
}
    ${InterviewModuleFragmentDoc}`;

/**
 * __useInterviewModuleSelectQuery__
 *
 * To run a query within a React component, call `useInterviewModuleSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleSelectQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleSelectQuery, InterviewModuleSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleSelectQuery, InterviewModuleSelectQueryVariables>(InterviewModuleSelectDocument, options);
      }
export function useInterviewModuleSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleSelectQuery, InterviewModuleSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleSelectQuery, InterviewModuleSelectQueryVariables>(InterviewModuleSelectDocument, options);
        }
export type InterviewModuleSelectQueryHookResult = ReturnType<typeof useInterviewModuleSelectQuery>;
export type InterviewModuleSelectLazyQueryHookResult = ReturnType<typeof useInterviewModuleSelectLazyQuery>;
export type InterviewModuleSelectQueryResult = Apollo.QueryResult<InterviewModuleSelectQuery, InterviewModuleSelectQueryVariables>;
export const JobBrandingTemplateSelectQueryDocument = gql`
    query JobBrandingTemplateSelectQuery($input: PortalTemplatesInput!) {
  portalTemplates(input: $input) {
    items {
      id
      ...JobBrandingTemplateSelect_portalTemplate
    }
  }
}
    ${JobBrandingTemplateSelect_PortalTemplateFragmentDoc}`;

/**
 * __useJobBrandingTemplateSelectQueryQuery__
 *
 * To run a query within a React component, call `useJobBrandingTemplateSelectQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobBrandingTemplateSelectQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobBrandingTemplateSelectQueryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobBrandingTemplateSelectQueryQuery(baseOptions: Apollo.QueryHookOptions<JobBrandingTemplateSelectQueryQuery, JobBrandingTemplateSelectQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobBrandingTemplateSelectQueryQuery, JobBrandingTemplateSelectQueryQueryVariables>(JobBrandingTemplateSelectQueryDocument, options);
      }
export function useJobBrandingTemplateSelectQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobBrandingTemplateSelectQueryQuery, JobBrandingTemplateSelectQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobBrandingTemplateSelectQueryQuery, JobBrandingTemplateSelectQueryQueryVariables>(JobBrandingTemplateSelectQueryDocument, options);
        }
export type JobBrandingTemplateSelectQueryQueryHookResult = ReturnType<typeof useJobBrandingTemplateSelectQueryQuery>;
export type JobBrandingTemplateSelectQueryLazyQueryHookResult = ReturnType<typeof useJobBrandingTemplateSelectQueryLazyQuery>;
export type JobBrandingTemplateSelectQueryQueryResult = Apollo.QueryResult<JobBrandingTemplateSelectQueryQuery, JobBrandingTemplateSelectQueryQueryVariables>;
export const OrgJobLocationsDocument = gql`
    query OrgJobLocations {
  thisOrg {
    id
    locations
  }
}
    `;

/**
 * __useOrgJobLocationsQuery__
 *
 * To run a query within a React component, call `useOrgJobLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgJobLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgJobLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgJobLocationsQuery(baseOptions?: Apollo.QueryHookOptions<OrgJobLocationsQuery, OrgJobLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgJobLocationsQuery, OrgJobLocationsQueryVariables>(OrgJobLocationsDocument, options);
      }
export function useOrgJobLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgJobLocationsQuery, OrgJobLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgJobLocationsQuery, OrgJobLocationsQueryVariables>(OrgJobLocationsDocument, options);
        }
export type OrgJobLocationsQueryHookResult = ReturnType<typeof useOrgJobLocationsQuery>;
export type OrgJobLocationsLazyQueryHookResult = ReturnType<typeof useOrgJobLocationsLazyQuery>;
export type OrgJobLocationsQueryResult = Apollo.QueryResult<OrgJobLocationsQuery, OrgJobLocationsQueryVariables>;
export const OrgMembersDocument = gql`
    query OrgMembers($input: OrgUserInfoInput!) {
  thisOrg {
    id
    userInfos(input: $input) {
      items {
        ...MemberSelect_userInfo
      }
      nextCursor
    }
  }
}
    ${MemberSelect_UserInfoFragmentDoc}`;

/**
 * __useOrgMembersQuery__
 *
 * To run a query within a React component, call `useOrgMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrgMembersQuery(baseOptions: Apollo.QueryHookOptions<OrgMembersQuery, OrgMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgMembersQuery, OrgMembersQueryVariables>(OrgMembersDocument, options);
      }
export function useOrgMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgMembersQuery, OrgMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgMembersQuery, OrgMembersQueryVariables>(OrgMembersDocument, options);
        }
export type OrgMembersQueryHookResult = ReturnType<typeof useOrgMembersQuery>;
export type OrgMembersLazyQueryHookResult = ReturnType<typeof useOrgMembersLazyQuery>;
export type OrgMembersQueryResult = Apollo.QueryResult<OrgMembersQuery, OrgMembersQueryVariables>;
export const UserInfoByEmployeeIdsDocument = gql`
    query UserInfoByEmployeeIds($input: [uuid!]!) {
  userInfoByEmployeeIds(ids: $input) {
    ...MemberSelect_userInfo
  }
}
    ${MemberSelect_UserInfoFragmentDoc}`;

/**
 * __useUserInfoByEmployeeIdsQuery__
 *
 * To run a query within a React component, call `useUserInfoByEmployeeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoByEmployeeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoByEmployeeIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserInfoByEmployeeIdsQuery(baseOptions: Apollo.QueryHookOptions<UserInfoByEmployeeIdsQuery, UserInfoByEmployeeIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInfoByEmployeeIdsQuery, UserInfoByEmployeeIdsQueryVariables>(UserInfoByEmployeeIdsDocument, options);
      }
export function useUserInfoByEmployeeIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoByEmployeeIdsQuery, UserInfoByEmployeeIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInfoByEmployeeIdsQuery, UserInfoByEmployeeIdsQueryVariables>(UserInfoByEmployeeIdsDocument, options);
        }
export type UserInfoByEmployeeIdsQueryHookResult = ReturnType<typeof useUserInfoByEmployeeIdsQuery>;
export type UserInfoByEmployeeIdsLazyQueryHookResult = ReturnType<typeof useUserInfoByEmployeeIdsLazyQuery>;
export type UserInfoByEmployeeIdsQueryResult = Apollo.QueryResult<UserInfoByEmployeeIdsQuery, UserInfoByEmployeeIdsQueryVariables>;
export const TaskQueuesSelectListDocument = gql`
    query TaskQueuesSelectList($input: TaskQueueSearchInput!) {
  taskQueueSearch(input: $input) {
    items {
      ...QueueSelect_selectedTaskQueue
    }
  }
}
    ${QueueSelect_SelectedTaskQueueFragmentDoc}`;

/**
 * __useTaskQueuesSelectListQuery__
 *
 * To run a query within a React component, call `useTaskQueuesSelectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQueuesSelectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQueuesSelectListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskQueuesSelectListQuery(baseOptions: Apollo.QueryHookOptions<TaskQueuesSelectListQuery, TaskQueuesSelectListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQueuesSelectListQuery, TaskQueuesSelectListQueryVariables>(TaskQueuesSelectListDocument, options);
      }
export function useTaskQueuesSelectListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQueuesSelectListQuery, TaskQueuesSelectListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQueuesSelectListQuery, TaskQueuesSelectListQueryVariables>(TaskQueuesSelectListDocument, options);
        }
export type TaskQueuesSelectListQueryHookResult = ReturnType<typeof useTaskQueuesSelectListQuery>;
export type TaskQueuesSelectListLazyQueryHookResult = ReturnType<typeof useTaskQueuesSelectListLazyQuery>;
export type TaskQueuesSelectListQueryResult = Apollo.QueryResult<TaskQueuesSelectListQuery, TaskQueuesSelectListQueryVariables>;
export const SmartEmployeeSelectMembersDocument = gql`
    query SmartEmployeeSelectMembers($input: EmployeesInput!) {
  employees(input: $input) {
    items {
      ...SmartEmployeeSelect
    }
    nextCursor
  }
}
    ${SmartEmployeeSelectFragmentDoc}`;

/**
 * __useSmartEmployeeSelectMembersQuery__
 *
 * To run a query within a React component, call `useSmartEmployeeSelectMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartEmployeeSelectMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartEmployeeSelectMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSmartEmployeeSelectMembersQuery(baseOptions: Apollo.QueryHookOptions<SmartEmployeeSelectMembersQuery, SmartEmployeeSelectMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SmartEmployeeSelectMembersQuery, SmartEmployeeSelectMembersQueryVariables>(SmartEmployeeSelectMembersDocument, options);
      }
export function useSmartEmployeeSelectMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmartEmployeeSelectMembersQuery, SmartEmployeeSelectMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SmartEmployeeSelectMembersQuery, SmartEmployeeSelectMembersQueryVariables>(SmartEmployeeSelectMembersDocument, options);
        }
export type SmartEmployeeSelectMembersQueryHookResult = ReturnType<typeof useSmartEmployeeSelectMembersQuery>;
export type SmartEmployeeSelectMembersLazyQueryHookResult = ReturnType<typeof useSmartEmployeeSelectMembersLazyQuery>;
export type SmartEmployeeSelectMembersQueryResult = Apollo.QueryResult<SmartEmployeeSelectMembersQuery, SmartEmployeeSelectMembersQueryVariables>;
export const SmartEmployeeByIdsDocument = gql`
    query SmartEmployeeByIds($input: [uuid!]!) {
  employeeByIds(ids: $input) {
    ...SmartEmployeeSelect
  }
}
    ${SmartEmployeeSelectFragmentDoc}`;

/**
 * __useSmartEmployeeByIdsQuery__
 *
 * To run a query within a React component, call `useSmartEmployeeByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSmartEmployeeByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSmartEmployeeByIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSmartEmployeeByIdsQuery(baseOptions: Apollo.QueryHookOptions<SmartEmployeeByIdsQuery, SmartEmployeeByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SmartEmployeeByIdsQuery, SmartEmployeeByIdsQueryVariables>(SmartEmployeeByIdsDocument, options);
      }
export function useSmartEmployeeByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SmartEmployeeByIdsQuery, SmartEmployeeByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SmartEmployeeByIdsQuery, SmartEmployeeByIdsQueryVariables>(SmartEmployeeByIdsDocument, options);
        }
export type SmartEmployeeByIdsQueryHookResult = ReturnType<typeof useSmartEmployeeByIdsQuery>;
export type SmartEmployeeByIdsLazyQueryHookResult = ReturnType<typeof useSmartEmployeeByIdsLazyQuery>;
export type SmartEmployeeByIdsQueryResult = Apollo.QueryResult<SmartEmployeeByIdsQuery, SmartEmployeeByIdsQueryVariables>;
export const DynamicEmployeeDocument = gql`
    query DynamicEmployee($id: String!, $taskId: uuid!, $jobId: uuid!, $candidateId: uuid!, $applicationId: uuid!, $skipTask: Boolean!, $skipJob: Boolean!, $skipCandidate: Boolean!) {
  task: dynamicEmployee(id: $id) @skip(if: $skipTask) {
    TASK_ASSIGNEE(input: {taskId: $taskId}) {
      ...useTaskSmartOptions_taskAssigneeData
    }
    TASK_SUBSCRIBERS(input: {taskId: $taskId}) {
      ...useTaskSmartOptions_taskSubscriberData
    }
    TASK_CREATOR(input: {taskId: $taskId}) {
      ...useTaskSmartOptions_taskCreatorData
    }
    DEBRIEF_ATTENDEES(input: {taskId: $taskId}) {
      ...useTaskSmartOptions_taskDebriefData
    }
  }
  job: dynamicEmployee(id: $id) @skip(if: $skipJob) {
    JOB_COORDINATORS(input: {jobId: $jobId}) {
      ...useJobSmartOptions_jobCoordinatorData
    }
    JOB_RECRUITERS(input: {jobId: $jobId}) {
      ...useJobSmartOptions_jobRecruiterData
    }
    JOB_SOURCERS(input: {jobId: $jobId}) {
      ...useJobSmartOptions_jobSourcerData
    }
  }
  candidate: dynamicEmployee(id: $id) @skip(if: $skipCandidate) {
    CANDIDATE_COORDINATOR(input: {candidateId: $candidateId}) {
      ...useCandidateSmartOptions_candidateCoordinatorData
    }
    CANDIDATE_RECRUITER(input: {candidateId: $candidateId}) {
      ...useCandidateSmartOptions_candidateRecruiterData
    }
    CANDIDATE_SOURCER(input: {candidateId: $candidateId}) {
      ...useCandidateSmartOptions_candidateSourcerData
    }
    INTERVIEWERS_FOR_THIS_CANDIDATE(input: {applicationId: $applicationId}) {
      ...useCandidateSmartOptions_previousCandidateInterviewersData
    }
  }
}
    ${UseTaskSmartOptions_TaskAssigneeDataFragmentDoc}
${UseTaskSmartOptions_TaskSubscriberDataFragmentDoc}
${UseTaskSmartOptions_TaskCreatorDataFragmentDoc}
${UseTaskSmartOptions_TaskDebriefDataFragmentDoc}
${UseJobSmartOptions_JobCoordinatorDataFragmentDoc}
${UseJobSmartOptions_JobRecruiterDataFragmentDoc}
${UseJobSmartOptions_JobSourcerDataFragmentDoc}
${UseCandidateSmartOptions_CandidateCoordinatorDataFragmentDoc}
${UseCandidateSmartOptions_CandidateRecruiterDataFragmentDoc}
${UseCandidateSmartOptions_CandidateSourcerDataFragmentDoc}
${UseCandidateSmartOptions_PreviousCandidateInterviewersDataFragmentDoc}`;

/**
 * __useDynamicEmployeeQuery__
 *
 * To run a query within a React component, call `useDynamicEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDynamicEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDynamicEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      taskId: // value for 'taskId'
 *      jobId: // value for 'jobId'
 *      candidateId: // value for 'candidateId'
 *      applicationId: // value for 'applicationId'
 *      skipTask: // value for 'skipTask'
 *      skipJob: // value for 'skipJob'
 *      skipCandidate: // value for 'skipCandidate'
 *   },
 * });
 */
export function useDynamicEmployeeQuery(baseOptions: Apollo.QueryHookOptions<DynamicEmployeeQuery, DynamicEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DynamicEmployeeQuery, DynamicEmployeeQueryVariables>(DynamicEmployeeDocument, options);
      }
export function useDynamicEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DynamicEmployeeQuery, DynamicEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DynamicEmployeeQuery, DynamicEmployeeQueryVariables>(DynamicEmployeeDocument, options);
        }
export type DynamicEmployeeQueryHookResult = ReturnType<typeof useDynamicEmployeeQuery>;
export type DynamicEmployeeLazyQueryHookResult = ReturnType<typeof useDynamicEmployeeLazyQuery>;
export type DynamicEmployeeQueryResult = Apollo.QueryResult<DynamicEmployeeQuery, DynamicEmployeeQueryVariables>;
export const CandidateDynamicEmployeeDocument = gql`
    query CandidateDynamicEmployee($id: String!, $candidateId: uuid!, $applicationId: uuid!) {
  dynamicEmployee(id: $id) {
    CANDIDATE_COORDINATOR(input: {candidateId: $candidateId}) {
      ...OptionRenderer_employees
    }
    CANDIDATE_RECRUITER(input: {candidateId: $candidateId}) {
      ...OptionRenderer_employees
    }
    CANDIDATE_SOURCER(input: {candidateId: $candidateId}) {
      ...OptionRenderer_employees
    }
    INTERVIEWERS_FOR_THIS_CANDIDATE(input: {applicationId: $applicationId}) {
      ...OptionRenderer_employees
    }
  }
}
    ${OptionRenderer_EmployeesFragmentDoc}`;

/**
 * __useCandidateDynamicEmployeeQuery__
 *
 * To run a query within a React component, call `useCandidateDynamicEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateDynamicEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateDynamicEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      candidateId: // value for 'candidateId'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useCandidateDynamicEmployeeQuery(baseOptions: Apollo.QueryHookOptions<CandidateDynamicEmployeeQuery, CandidateDynamicEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateDynamicEmployeeQuery, CandidateDynamicEmployeeQueryVariables>(CandidateDynamicEmployeeDocument, options);
      }
export function useCandidateDynamicEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateDynamicEmployeeQuery, CandidateDynamicEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateDynamicEmployeeQuery, CandidateDynamicEmployeeQueryVariables>(CandidateDynamicEmployeeDocument, options);
        }
export type CandidateDynamicEmployeeQueryHookResult = ReturnType<typeof useCandidateDynamicEmployeeQuery>;
export type CandidateDynamicEmployeeLazyQueryHookResult = ReturnType<typeof useCandidateDynamicEmployeeLazyQuery>;
export type CandidateDynamicEmployeeQueryResult = Apollo.QueryResult<CandidateDynamicEmployeeQuery, CandidateDynamicEmployeeQueryVariables>;
export const UseTaskSmartOptionsEmployeeByIdsDocument = gql`
    query UseTaskSmartOptionsEmployeeByIds($taskAssigneeEmployeeIds: [uuid!]!, $taskInterviewersOnScheduleEmployeeIds: [uuid!]!, $taskCreatorEmployeeIds: [uuid!]!, $taskSubscriberEmployeeIds: [uuid!]!, $taskDebriefEmployeeIds: [uuid!]!) {
  taskAssigneeData: employeeByIds(ids: $taskAssigneeEmployeeIds) {
    ...OptionRenderer_employees
  }
  taskSubscriberData: employeeByIds(ids: $taskSubscriberEmployeeIds) {
    ...OptionRenderer_employees
  }
  taskDebriefData: employeeByIds(ids: $taskDebriefEmployeeIds) {
    ...OptionRenderer_employees
  }
  taskInterviewersData: employeeByIds(ids: $taskInterviewersOnScheduleEmployeeIds) {
    ...OptionRenderer_employees
  }
  taskCreatorData: employeeByIds(ids: $taskCreatorEmployeeIds) {
    ...OptionRenderer_employees
  }
}
    ${OptionRenderer_EmployeesFragmentDoc}`;

/**
 * __useUseTaskSmartOptionsEmployeeByIdsQuery__
 *
 * To run a query within a React component, call `useUseTaskSmartOptionsEmployeeByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseTaskSmartOptionsEmployeeByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseTaskSmartOptionsEmployeeByIdsQuery({
 *   variables: {
 *      taskAssigneeEmployeeIds: // value for 'taskAssigneeEmployeeIds'
 *      taskInterviewersOnScheduleEmployeeIds: // value for 'taskInterviewersOnScheduleEmployeeIds'
 *      taskCreatorEmployeeIds: // value for 'taskCreatorEmployeeIds'
 *      taskSubscriberEmployeeIds: // value for 'taskSubscriberEmployeeIds'
 *      taskDebriefEmployeeIds: // value for 'taskDebriefEmployeeIds'
 *   },
 * });
 */
export function useUseTaskSmartOptionsEmployeeByIdsQuery(baseOptions: Apollo.QueryHookOptions<UseTaskSmartOptionsEmployeeByIdsQuery, UseTaskSmartOptionsEmployeeByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseTaskSmartOptionsEmployeeByIdsQuery, UseTaskSmartOptionsEmployeeByIdsQueryVariables>(UseTaskSmartOptionsEmployeeByIdsDocument, options);
      }
export function useUseTaskSmartOptionsEmployeeByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseTaskSmartOptionsEmployeeByIdsQuery, UseTaskSmartOptionsEmployeeByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseTaskSmartOptionsEmployeeByIdsQuery, UseTaskSmartOptionsEmployeeByIdsQueryVariables>(UseTaskSmartOptionsEmployeeByIdsDocument, options);
        }
export type UseTaskSmartOptionsEmployeeByIdsQueryHookResult = ReturnType<typeof useUseTaskSmartOptionsEmployeeByIdsQuery>;
export type UseTaskSmartOptionsEmployeeByIdsLazyQueryHookResult = ReturnType<typeof useUseTaskSmartOptionsEmployeeByIdsLazyQuery>;
export type UseTaskSmartOptionsEmployeeByIdsQueryResult = Apollo.QueryResult<UseTaskSmartOptionsEmployeeByIdsQuery, UseTaskSmartOptionsEmployeeByIdsQueryVariables>;
export const ZoomUsersListDocument = gql`
    query ZoomUsersList($input: ZoomUsersInput!) {
  zoomUsers(input: $input) {
    items {
      ...ZoomUserFragment
    }
    nextCursor
  }
}
    ${ZoomUserFragmentFragmentDoc}`;

/**
 * __useZoomUsersListQuery__
 *
 * To run a query within a React component, call `useZoomUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomUsersListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useZoomUsersListQuery(baseOptions: Apollo.QueryHookOptions<ZoomUsersListQuery, ZoomUsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZoomUsersListQuery, ZoomUsersListQueryVariables>(ZoomUsersListDocument, options);
      }
export function useZoomUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoomUsersListQuery, ZoomUsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZoomUsersListQuery, ZoomUsersListQueryVariables>(ZoomUsersListDocument, options);
        }
export type ZoomUsersListQueryHookResult = ReturnType<typeof useZoomUsersListQuery>;
export type ZoomUsersListLazyQueryHookResult = ReturnType<typeof useZoomUsersListLazyQuery>;
export type ZoomUsersListQueryResult = Apollo.QueryResult<ZoomUsersListQuery, ZoomUsersListQueryVariables>;
export const ZoomUsersByIdsDocument = gql`
    query ZoomUsersByIds($input: ZoomUsersByIdsInput!) {
  zoomUsersByIds(input: $input) {
    ...ZoomUserFragment
  }
}
    ${ZoomUserFragmentFragmentDoc}`;

/**
 * __useZoomUsersByIdsQuery__
 *
 * To run a query within a React component, call `useZoomUsersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomUsersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomUsersByIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useZoomUsersByIdsQuery(baseOptions: Apollo.QueryHookOptions<ZoomUsersByIdsQuery, ZoomUsersByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZoomUsersByIdsQuery, ZoomUsersByIdsQueryVariables>(ZoomUsersByIdsDocument, options);
      }
export function useZoomUsersByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoomUsersByIdsQuery, ZoomUsersByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZoomUsersByIdsQuery, ZoomUsersByIdsQueryVariables>(ZoomUsersByIdsDocument, options);
        }
export type ZoomUsersByIdsQueryHookResult = ReturnType<typeof useZoomUsersByIdsQuery>;
export type ZoomUsersByIdsLazyQueryHookResult = ReturnType<typeof useZoomUsersByIdsLazyQuery>;
export type ZoomUsersByIdsQueryResult = Apollo.QueryResult<ZoomUsersByIdsQuery, ZoomUsersByIdsQueryVariables>;
export const ZoomUserByIdsDocument = gql`
    query ZoomUserByIds($input: ZoomUsersByIdsInput!) {
  zoomUsersByIds(input: $input) {
    ...ZoomUserFragment
  }
}
    ${ZoomUserFragmentFragmentDoc}`;

/**
 * __useZoomUserByIdsQuery__
 *
 * To run a query within a React component, call `useZoomUserByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomUserByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomUserByIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useZoomUserByIdsQuery(baseOptions: Apollo.QueryHookOptions<ZoomUserByIdsQuery, ZoomUserByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZoomUserByIdsQuery, ZoomUserByIdsQueryVariables>(ZoomUserByIdsDocument, options);
      }
export function useZoomUserByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoomUserByIdsQuery, ZoomUserByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZoomUserByIdsQuery, ZoomUserByIdsQueryVariables>(ZoomUserByIdsDocument, options);
        }
export type ZoomUserByIdsQueryHookResult = ReturnType<typeof useZoomUserByIdsQuery>;
export type ZoomUserByIdsLazyQueryHookResult = ReturnType<typeof useZoomUserByIdsLazyQuery>;
export type ZoomUserByIdsQueryResult = Apollo.QueryResult<ZoomUserByIdsQuery, ZoomUserByIdsQueryVariables>;
export const RecentlyUsedZoomUsersEmployeePrefDocument = gql`
    query RecentlyUsedZoomUsersEmployeePref($prefName: String!) {
  thisEmployee {
    id
    employeePref(prefName: $prefName) {
      orgId
      employeeId
      prefName
      jsonPref {
        ... on RecentlyUsedZoomUsersPref {
          ...RecentlyUsedZoomUsersEmployeePref
        }
      }
    }
  }
}
    ${RecentlyUsedZoomUsersEmployeePrefFragmentDoc}`;

/**
 * __useRecentlyUsedZoomUsersEmployeePrefQuery__
 *
 * To run a query within a React component, call `useRecentlyUsedZoomUsersEmployeePrefQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentlyUsedZoomUsersEmployeePrefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentlyUsedZoomUsersEmployeePrefQuery({
 *   variables: {
 *      prefName: // value for 'prefName'
 *   },
 * });
 */
export function useRecentlyUsedZoomUsersEmployeePrefQuery(baseOptions: Apollo.QueryHookOptions<RecentlyUsedZoomUsersEmployeePrefQuery, RecentlyUsedZoomUsersEmployeePrefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentlyUsedZoomUsersEmployeePrefQuery, RecentlyUsedZoomUsersEmployeePrefQueryVariables>(RecentlyUsedZoomUsersEmployeePrefDocument, options);
      }
export function useRecentlyUsedZoomUsersEmployeePrefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentlyUsedZoomUsersEmployeePrefQuery, RecentlyUsedZoomUsersEmployeePrefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentlyUsedZoomUsersEmployeePrefQuery, RecentlyUsedZoomUsersEmployeePrefQueryVariables>(RecentlyUsedZoomUsersEmployeePrefDocument, options);
        }
export type RecentlyUsedZoomUsersEmployeePrefQueryHookResult = ReturnType<typeof useRecentlyUsedZoomUsersEmployeePrefQuery>;
export type RecentlyUsedZoomUsersEmployeePrefLazyQueryHookResult = ReturnType<typeof useRecentlyUsedZoomUsersEmployeePrefLazyQuery>;
export type RecentlyUsedZoomUsersEmployeePrefQueryResult = Apollo.QueryResult<RecentlyUsedZoomUsersEmployeePrefQuery, RecentlyUsedZoomUsersEmployeePrefQueryVariables>;
export const UpdateRecentlyUsedZoomUsersEmployeePrefDocument = gql`
    mutation updateRecentlyUsedZoomUsersEmployeePref($input: EmployeePrefUpdateInput!) {
  employeePrefUpdate(input: $input) {
    employeePref {
      orgId
      employeeId
      prefName
    }
  }
}
    `;
export type UpdateRecentlyUsedZoomUsersEmployeePrefMutationFn = Apollo.MutationFunction<UpdateRecentlyUsedZoomUsersEmployeePrefMutation, UpdateRecentlyUsedZoomUsersEmployeePrefMutationVariables>;

/**
 * __useUpdateRecentlyUsedZoomUsersEmployeePrefMutation__
 *
 * To run a mutation, you first call `useUpdateRecentlyUsedZoomUsersEmployeePrefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecentlyUsedZoomUsersEmployeePrefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecentlyUsedZoomUsersEmployeePrefMutation, { data, loading, error }] = useUpdateRecentlyUsedZoomUsersEmployeePrefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecentlyUsedZoomUsersEmployeePrefMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecentlyUsedZoomUsersEmployeePrefMutation, UpdateRecentlyUsedZoomUsersEmployeePrefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecentlyUsedZoomUsersEmployeePrefMutation, UpdateRecentlyUsedZoomUsersEmployeePrefMutationVariables>(UpdateRecentlyUsedZoomUsersEmployeePrefDocument, options);
      }
export type UpdateRecentlyUsedZoomUsersEmployeePrefMutationHookResult = ReturnType<typeof useUpdateRecentlyUsedZoomUsersEmployeePrefMutation>;
export type UpdateRecentlyUsedZoomUsersEmployeePrefMutationResult = Apollo.MutationResult<UpdateRecentlyUsedZoomUsersEmployeePrefMutation>;
export type UpdateRecentlyUsedZoomUsersEmployeePrefMutationOptions = Apollo.BaseMutationOptions<UpdateRecentlyUsedZoomUsersEmployeePrefMutation, UpdateRecentlyUsedZoomUsersEmployeePrefMutationVariables>;
export const CurrentEmployeeDetailsDocument = gql`
    query CurrentEmployeeDetails {
  thisEmployee {
    id
    ...SubscribersSelect_thisEmployee
  }
}
    ${SubscribersSelect_ThisEmployeeFragmentDoc}`;

/**
 * __useCurrentEmployeeDetailsQuery__
 *
 * To run a query within a React component, call `useCurrentEmployeeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentEmployeeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentEmployeeDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentEmployeeDetailsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentEmployeeDetailsQuery, CurrentEmployeeDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentEmployeeDetailsQuery, CurrentEmployeeDetailsQueryVariables>(CurrentEmployeeDetailsDocument, options);
      }
export function useCurrentEmployeeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentEmployeeDetailsQuery, CurrentEmployeeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentEmployeeDetailsQuery, CurrentEmployeeDetailsQueryVariables>(CurrentEmployeeDetailsDocument, options);
        }
export type CurrentEmployeeDetailsQueryHookResult = ReturnType<typeof useCurrentEmployeeDetailsQuery>;
export type CurrentEmployeeDetailsLazyQueryHookResult = ReturnType<typeof useCurrentEmployeeDetailsLazyQuery>;
export type CurrentEmployeeDetailsQueryResult = Apollo.QueryResult<CurrentEmployeeDetailsQuery, CurrentEmployeeDetailsQueryVariables>;
export const SubscribersListDocument = gql`
    query SubscribersList($input: SubscribersInput!) {
  subscribers(input: $input) {
    items {
      id
      ...SubscribersSelect_employee
    }
  }
}
    ${SubscribersSelect_EmployeeFragmentDoc}`;

/**
 * __useSubscribersListQuery__
 *
 * To run a query within a React component, call `useSubscribersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribersListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribersListQuery(baseOptions: Apollo.QueryHookOptions<SubscribersListQuery, SubscribersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscribersListQuery, SubscribersListQueryVariables>(SubscribersListDocument, options);
      }
export function useSubscribersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscribersListQuery, SubscribersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscribersListQuery, SubscribersListQueryVariables>(SubscribersListDocument, options);
        }
export type SubscribersListQueryHookResult = ReturnType<typeof useSubscribersListQuery>;
export type SubscribersListLazyQueryHookResult = ReturnType<typeof useSubscribersListLazyQuery>;
export type SubscribersListQueryResult = Apollo.QueryResult<SubscribersListQuery, SubscribersListQueryVariables>;
export const SubscriberAddDocument = gql`
    mutation SubscriberAdd($input: SubscriberAddInput!) {
  subscriberAdd(input: $input) {
    result
    subscribers {
      id
      ...SubscribersSelect_employee
    }
  }
}
    ${SubscribersSelect_EmployeeFragmentDoc}`;
export type SubscriberAddMutationFn = Apollo.MutationFunction<SubscriberAddMutation, SubscriberAddMutationVariables>;

/**
 * __useSubscriberAddMutation__
 *
 * To run a mutation, you first call `useSubscriberAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriberAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriberAddMutation, { data, loading, error }] = useSubscriberAddMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscriberAddMutation(baseOptions?: Apollo.MutationHookOptions<SubscriberAddMutation, SubscriberAddMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriberAddMutation, SubscriberAddMutationVariables>(SubscriberAddDocument, options);
      }
export type SubscriberAddMutationHookResult = ReturnType<typeof useSubscriberAddMutation>;
export type SubscriberAddMutationResult = Apollo.MutationResult<SubscriberAddMutation>;
export type SubscriberAddMutationOptions = Apollo.BaseMutationOptions<SubscriberAddMutation, SubscriberAddMutationVariables>;
export const SubscriberRemoveDocument = gql`
    mutation SubscriberRemove($input: SubscriberRemoveInput!) {
  subscriberRemove(input: $input) {
    result
    subscribers {
      id
      ...SubscribersSelect_employee
    }
  }
}
    ${SubscribersSelect_EmployeeFragmentDoc}`;
export type SubscriberRemoveMutationFn = Apollo.MutationFunction<SubscriberRemoveMutation, SubscriberRemoveMutationVariables>;

/**
 * __useSubscriberRemoveMutation__
 *
 * To run a mutation, you first call `useSubscriberRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscriberRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscriberRemoveMutation, { data, loading, error }] = useSubscriberRemoveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscriberRemoveMutation(baseOptions?: Apollo.MutationHookOptions<SubscriberRemoveMutation, SubscriberRemoveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscriberRemoveMutation, SubscriberRemoveMutationVariables>(SubscriberRemoveDocument, options);
      }
export type SubscriberRemoveMutationHookResult = ReturnType<typeof useSubscriberRemoveMutation>;
export type SubscriberRemoveMutationResult = Apollo.MutationResult<SubscriberRemoveMutation>;
export type SubscriberRemoveMutationOptions = Apollo.BaseMutationOptions<SubscriberRemoveMutation, SubscriberRemoveMutationVariables>;
export const TemplateSelectTemplateByIdDocument = gql`
    query TemplateSelectTemplateById($id: uuid!) {
  template(id: $id) {
    ...Template
  }
}
    ${TemplateFragmentDoc}`;

/**
 * __useTemplateSelectTemplateByIdQuery__
 *
 * To run a query within a React component, call `useTemplateSelectTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateSelectTemplateByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateSelectTemplateByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateSelectTemplateByIdQuery(baseOptions: Apollo.QueryHookOptions<TemplateSelectTemplateByIdQuery, TemplateSelectTemplateByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateSelectTemplateByIdQuery, TemplateSelectTemplateByIdQueryVariables>(TemplateSelectTemplateByIdDocument, options);
      }
export function useTemplateSelectTemplateByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateSelectTemplateByIdQuery, TemplateSelectTemplateByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateSelectTemplateByIdQuery, TemplateSelectTemplateByIdQueryVariables>(TemplateSelectTemplateByIdDocument, options);
        }
export type TemplateSelectTemplateByIdQueryHookResult = ReturnType<typeof useTemplateSelectTemplateByIdQuery>;
export type TemplateSelectTemplateByIdLazyQueryHookResult = ReturnType<typeof useTemplateSelectTemplateByIdLazyQuery>;
export type TemplateSelectTemplateByIdQueryResult = Apollo.QueryResult<TemplateSelectTemplateByIdQuery, TemplateSelectTemplateByIdQueryVariables>;
export const TemplateSelectTemplateByIdsDocument = gql`
    query TemplateSelectTemplateByIds($ids: [uuid!]!) {
  templateByIds(ids: $ids) {
    ...Template
  }
}
    ${TemplateFragmentDoc}`;

/**
 * __useTemplateSelectTemplateByIdsQuery__
 *
 * To run a query within a React component, call `useTemplateSelectTemplateByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateSelectTemplateByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateSelectTemplateByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTemplateSelectTemplateByIdsQuery(baseOptions: Apollo.QueryHookOptions<TemplateSelectTemplateByIdsQuery, TemplateSelectTemplateByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateSelectTemplateByIdsQuery, TemplateSelectTemplateByIdsQueryVariables>(TemplateSelectTemplateByIdsDocument, options);
      }
export function useTemplateSelectTemplateByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateSelectTemplateByIdsQuery, TemplateSelectTemplateByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateSelectTemplateByIdsQuery, TemplateSelectTemplateByIdsQueryVariables>(TemplateSelectTemplateByIdsDocument, options);
        }
export type TemplateSelectTemplateByIdsQueryHookResult = ReturnType<typeof useTemplateSelectTemplateByIdsQuery>;
export type TemplateSelectTemplateByIdsLazyQueryHookResult = ReturnType<typeof useTemplateSelectTemplateByIdsLazyQuery>;
export type TemplateSelectTemplateByIdsQueryResult = Apollo.QueryResult<TemplateSelectTemplateByIdsQuery, TemplateSelectTemplateByIdsQueryVariables>;
export const TemplateSelectTemplatesSearchDocument = gql`
    query TemplateSelectTemplatesSearch($input: TemplatesInput!) {
  templates(input: $input) {
    items {
      ...Template
    }
  }
}
    ${TemplateFragmentDoc}`;

/**
 * __useTemplateSelectTemplatesSearchQuery__
 *
 * To run a query within a React component, call `useTemplateSelectTemplatesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateSelectTemplatesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateSelectTemplatesSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTemplateSelectTemplatesSearchQuery(baseOptions: Apollo.QueryHookOptions<TemplateSelectTemplatesSearchQuery, TemplateSelectTemplatesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateSelectTemplatesSearchQuery, TemplateSelectTemplatesSearchQueryVariables>(TemplateSelectTemplatesSearchDocument, options);
      }
export function useTemplateSelectTemplatesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateSelectTemplatesSearchQuery, TemplateSelectTemplatesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateSelectTemplatesSearchQuery, TemplateSelectTemplatesSearchQueryVariables>(TemplateSelectTemplatesSearchDocument, options);
        }
export type TemplateSelectTemplatesSearchQueryHookResult = ReturnType<typeof useTemplateSelectTemplatesSearchQuery>;
export type TemplateSelectTemplatesSearchLazyQueryHookResult = ReturnType<typeof useTemplateSelectTemplatesSearchLazyQuery>;
export type TemplateSelectTemplatesSearchQueryResult = Apollo.QueryResult<TemplateSelectTemplatesSearchQuery, TemplateSelectTemplatesSearchQueryVariables>;
export const EmployeePrefsDocument = gql`
    query EmployeePrefs($id: uuid!, $prefName: String!) {
  employee(id: $id) {
    id
    employeePref(prefName: $prefName) {
      orgId
      employeeId
      prefName
      createdAt
      updatedAt
      ...EmployeePrefData
    }
  }
}
    ${EmployeePrefDataFragmentDoc}`;

/**
 * __useEmployeePrefsQuery__
 *
 * To run a query within a React component, call `useEmployeePrefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeePrefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeePrefsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      prefName: // value for 'prefName'
 *   },
 * });
 */
export function useEmployeePrefsQuery(baseOptions: Apollo.QueryHookOptions<EmployeePrefsQuery, EmployeePrefsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeePrefsQuery, EmployeePrefsQueryVariables>(EmployeePrefsDocument, options);
      }
export function useEmployeePrefsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeePrefsQuery, EmployeePrefsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeePrefsQuery, EmployeePrefsQueryVariables>(EmployeePrefsDocument, options);
        }
export type EmployeePrefsQueryHookResult = ReturnType<typeof useEmployeePrefsQuery>;
export type EmployeePrefsLazyQueryHookResult = ReturnType<typeof useEmployeePrefsLazyQuery>;
export type EmployeePrefsQueryResult = Apollo.QueryResult<EmployeePrefsQuery, EmployeePrefsQueryVariables>;
export const UpdateEmployeePrefDocument = gql`
    mutation UpdateEmployeePref($input: EmployeePrefUpdateInput!) {
  employeePrefUpdate(input: $input) {
    employeePref {
      orgId
      employeeId
      prefName
      timestamp
    }
  }
}
    `;
export type UpdateEmployeePrefMutationFn = Apollo.MutationFunction<UpdateEmployeePrefMutation, UpdateEmployeePrefMutationVariables>;

/**
 * __useUpdateEmployeePrefMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeePrefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeePrefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeePrefMutation, { data, loading, error }] = useUpdateEmployeePrefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeePrefMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeePrefMutation, UpdateEmployeePrefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeePrefMutation, UpdateEmployeePrefMutationVariables>(UpdateEmployeePrefDocument, options);
      }
export type UpdateEmployeePrefMutationHookResult = ReturnType<typeof useUpdateEmployeePrefMutation>;
export type UpdateEmployeePrefMutationResult = Apollo.MutationResult<UpdateEmployeePrefMutation>;
export type UpdateEmployeePrefMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeePrefMutation, UpdateEmployeePrefMutationVariables>;
export const ApplicationStageInterviewPlanDocument = gql`
    query applicationStageInterviewPlan($id: uuid!) {
  applicationStage(id: $id) {
    id
    task {
      id
      availabilities {
        id
        createdAt
        candidateEnteredAvailability {
          startAt
          endAt
        }
        rcEnteredAvailability {
          startAt
          endAt
        }
      }
      interviewPlan {
        id
        ...JobStage_InterviewPlan
      }
    }
    emailTemplateId
    applicationId
    conversation {
      id
      remoteId
      remoteName
    }
    isPrivateCalendarEvent
    ccRecipients {
      employeeIds
      externalEmailAddresses
      dynamicAudiences
    }
    bccRecipients {
      employeeIds
      externalEmailAddresses
      dynamicAudiences
    }
    applicationStageCandidateEvents {
      id
      name
      googleEventId
      googleCalendarId
      calendarEventTemplateId
      __typename
    }
    jobStage {
      id
      atsId
      jobId
      name
      createdAt
      updatedAt
      schedulable
      job {
        id
        atsId
        name
        hiringManagers {
          id
        }
      }
      jobStageSettings {
        id
        jobStageId
        candidateEmailTemplateId
        candidateEventTemplateId
        candidateCalendarId
        slackTemplateId
        ccRecipients {
          employeeIds
          externalEmailAddresses
          dynamicAudiences
        }
        bccRecipients {
          employeeIds
          externalEmailAddresses
          dynamicAudiences
        }
      }
    }
    application {
      id
      candidate {
        id
        fullName
        email
        additionalEmails
        coordinatorId
        recruiterId
        timezone
        atsCandidate {
          atsUrl
          atsId
        }
        ...CandidateAtsLatestAvailabilityFragment
      }
      existingConversations {
        id
        remoteId
        remoteName
        isArchived
      }
    }
  }
}
    ${JobStage_InterviewPlanFragmentDoc}
${CandidateAtsLatestAvailabilityFragmentFragmentDoc}`;

/**
 * __useApplicationStageInterviewPlanQuery__
 *
 * To run a query within a React component, call `useApplicationStageInterviewPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationStageInterviewPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationStageInterviewPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicationStageInterviewPlanQuery(baseOptions: Apollo.QueryHookOptions<ApplicationStageInterviewPlanQuery, ApplicationStageInterviewPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationStageInterviewPlanQuery, ApplicationStageInterviewPlanQueryVariables>(ApplicationStageInterviewPlanDocument, options);
      }
export function useApplicationStageInterviewPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationStageInterviewPlanQuery, ApplicationStageInterviewPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationStageInterviewPlanQuery, ApplicationStageInterviewPlanQueryVariables>(ApplicationStageInterviewPlanDocument, options);
        }
export type ApplicationStageInterviewPlanQueryHookResult = ReturnType<typeof useApplicationStageInterviewPlanQuery>;
export type ApplicationStageInterviewPlanLazyQueryHookResult = ReturnType<typeof useApplicationStageInterviewPlanLazyQuery>;
export type ApplicationStageInterviewPlanQueryResult = Apollo.QueryResult<ApplicationStageInterviewPlanQuery, ApplicationStageInterviewPlanQueryVariables>;
export const OrgPrefsDocument = gql`
    query OrgPrefs {
  thisOrg {
    id
    orgPrefs {
      ...OrgPrefsData
    }
  }
}
    ${OrgPrefsDataFragmentDoc}`;

/**
 * __useOrgPrefsQuery__
 *
 * To run a query within a React component, call `useOrgPrefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgPrefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgPrefsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgPrefsQuery(baseOptions?: Apollo.QueryHookOptions<OrgPrefsQuery, OrgPrefsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgPrefsQuery, OrgPrefsQueryVariables>(OrgPrefsDocument, options);
      }
export function useOrgPrefsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgPrefsQuery, OrgPrefsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgPrefsQuery, OrgPrefsQueryVariables>(OrgPrefsDocument, options);
        }
export type OrgPrefsQueryHookResult = ReturnType<typeof useOrgPrefsQuery>;
export type OrgPrefsLazyQueryHookResult = ReturnType<typeof useOrgPrefsLazyQuery>;
export type OrgPrefsQueryResult = Apollo.QueryResult<OrgPrefsQuery, OrgPrefsQueryVariables>;
export const UpdateOrgPrefsDocument = gql`
    mutation UpdateOrgPrefs($input: OrgPrefUpdateInput!) {
  orgPrefUpdate(input: $input) {
    orgPref {
      ...OrgPrefsData
    }
  }
}
    ${OrgPrefsDataFragmentDoc}`;
export type UpdateOrgPrefsMutationFn = Apollo.MutationFunction<UpdateOrgPrefsMutation, UpdateOrgPrefsMutationVariables>;

/**
 * __useUpdateOrgPrefsMutation__
 *
 * To run a mutation, you first call `useUpdateOrgPrefsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgPrefsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgPrefsMutation, { data, loading, error }] = useUpdateOrgPrefsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgPrefsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgPrefsMutation, UpdateOrgPrefsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgPrefsMutation, UpdateOrgPrefsMutationVariables>(UpdateOrgPrefsDocument, options);
      }
export type UpdateOrgPrefsMutationHookResult = ReturnType<typeof useUpdateOrgPrefsMutation>;
export type UpdateOrgPrefsMutationResult = Apollo.MutationResult<UpdateOrgPrefsMutation>;
export type UpdateOrgPrefsMutationOptions = Apollo.BaseMutationOptions<UpdateOrgPrefsMutation, UpdateOrgPrefsMutationVariables>;
export const UserPermissionDocument = gql`
    query UserPermission {
  thisUserInfo {
    permissions
  }
}
    `;

/**
 * __useUserPermissionQuery__
 *
 * To run a query within a React component, call `useUserPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPermissionQuery(baseOptions?: Apollo.QueryHookOptions<UserPermissionQuery, UserPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPermissionQuery, UserPermissionQueryVariables>(UserPermissionDocument, options);
      }
export function useUserPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPermissionQuery, UserPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPermissionQuery, UserPermissionQueryVariables>(UserPermissionDocument, options);
        }
export type UserPermissionQueryHookResult = ReturnType<typeof useUserPermissionQuery>;
export type UserPermissionLazyQueryHookResult = ReturnType<typeof useUserPermissionLazyQuery>;
export type UserPermissionQueryResult = Apollo.QueryResult<UserPermissionQuery, UserPermissionQueryVariables>;
export const GoogleIntegrationHealthCheckDocument = gql`
    query GoogleIntegrationHealthCheck {
  integrationCheckHealth(input: {force: false}) {
    google {
      canSendGmail
    }
  }
  thisOrg {
    id
    integrations {
      google {
        adminUserId
      }
    }
  }
  thisEmployee {
    id
    integrations {
      google {
        active
      }
    }
  }
}
    `;

/**
 * __useGoogleIntegrationHealthCheckQuery__
 *
 * To run a query within a React component, call `useGoogleIntegrationHealthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleIntegrationHealthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleIntegrationHealthCheckQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoogleIntegrationHealthCheckQuery(baseOptions?: Apollo.QueryHookOptions<GoogleIntegrationHealthCheckQuery, GoogleIntegrationHealthCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoogleIntegrationHealthCheckQuery, GoogleIntegrationHealthCheckQueryVariables>(GoogleIntegrationHealthCheckDocument, options);
      }
export function useGoogleIntegrationHealthCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoogleIntegrationHealthCheckQuery, GoogleIntegrationHealthCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoogleIntegrationHealthCheckQuery, GoogleIntegrationHealthCheckQueryVariables>(GoogleIntegrationHealthCheckDocument, options);
        }
export type GoogleIntegrationHealthCheckQueryHookResult = ReturnType<typeof useGoogleIntegrationHealthCheckQuery>;
export type GoogleIntegrationHealthCheckLazyQueryHookResult = ReturnType<typeof useGoogleIntegrationHealthCheckLazyQuery>;
export type GoogleIntegrationHealthCheckQueryResult = Apollo.QueryResult<GoogleIntegrationHealthCheckQuery, GoogleIntegrationHealthCheckQueryVariables>;
export const MicrosoftGccHighIntegrationDocument = gql`
    query MicrosoftGccHighIntegration {
  thisOrg {
    id
    integrations {
      microsoftGccHigh {
        active
      }
    }
  }
  thisEmployee {
    id
    integrations {
      microsoftGccHighEmployee {
        active
      }
    }
  }
}
    `;

/**
 * __useMicrosoftGccHighIntegrationQuery__
 *
 * To run a query within a React component, call `useMicrosoftGccHighIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMicrosoftGccHighIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMicrosoftGccHighIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useMicrosoftGccHighIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<MicrosoftGccHighIntegrationQuery, MicrosoftGccHighIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MicrosoftGccHighIntegrationQuery, MicrosoftGccHighIntegrationQueryVariables>(MicrosoftGccHighIntegrationDocument, options);
      }
export function useMicrosoftGccHighIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MicrosoftGccHighIntegrationQuery, MicrosoftGccHighIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MicrosoftGccHighIntegrationQuery, MicrosoftGccHighIntegrationQueryVariables>(MicrosoftGccHighIntegrationDocument, options);
        }
export type MicrosoftGccHighIntegrationQueryHookResult = ReturnType<typeof useMicrosoftGccHighIntegrationQuery>;
export type MicrosoftGccHighIntegrationLazyQueryHookResult = ReturnType<typeof useMicrosoftGccHighIntegrationLazyQuery>;
export type MicrosoftGccHighIntegrationQueryResult = Apollo.QueryResult<MicrosoftGccHighIntegrationQuery, MicrosoftGccHighIntegrationQueryVariables>;
export const MicrosoftIntegrationDocument = gql`
    query MicrosoftIntegration {
  thisOrg {
    id
    integrations {
      microsoft {
        active
      }
    }
  }
  thisEmployee {
    id
    integrations {
      microsoft {
        active
      }
    }
  }
}
    `;

/**
 * __useMicrosoftIntegrationQuery__
 *
 * To run a query within a React component, call `useMicrosoftIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMicrosoftIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMicrosoftIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useMicrosoftIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<MicrosoftIntegrationQuery, MicrosoftIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MicrosoftIntegrationQuery, MicrosoftIntegrationQueryVariables>(MicrosoftIntegrationDocument, options);
      }
export function useMicrosoftIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MicrosoftIntegrationQuery, MicrosoftIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MicrosoftIntegrationQuery, MicrosoftIntegrationQueryVariables>(MicrosoftIntegrationDocument, options);
        }
export type MicrosoftIntegrationQueryHookResult = ReturnType<typeof useMicrosoftIntegrationQuery>;
export type MicrosoftIntegrationLazyQueryHookResult = ReturnType<typeof useMicrosoftIntegrationLazyQuery>;
export type MicrosoftIntegrationQueryResult = Apollo.QueryResult<MicrosoftIntegrationQuery, MicrosoftIntegrationQueryVariables>;
export const ImportCandidateAvailabilityApplicationTasksDocument = gql`
    query ImportCandidateAvailabilityApplicationTasks($applicationId: uuid!) {
  application(id: $applicationId) {
    tasks {
      canceledAt
      id
      availabilities {
        id
        candidateEnteredAvailability {
          startAt
          endAt
        }
        rcEnteredAvailability {
          startAt
          endAt
        }
      }
    }
  }
}
    `;

/**
 * __useImportCandidateAvailabilityApplicationTasksQuery__
 *
 * To run a query within a React component, call `useImportCandidateAvailabilityApplicationTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportCandidateAvailabilityApplicationTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportCandidateAvailabilityApplicationTasksQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useImportCandidateAvailabilityApplicationTasksQuery(baseOptions: Apollo.QueryHookOptions<ImportCandidateAvailabilityApplicationTasksQuery, ImportCandidateAvailabilityApplicationTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImportCandidateAvailabilityApplicationTasksQuery, ImportCandidateAvailabilityApplicationTasksQueryVariables>(ImportCandidateAvailabilityApplicationTasksDocument, options);
      }
export function useImportCandidateAvailabilityApplicationTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImportCandidateAvailabilityApplicationTasksQuery, ImportCandidateAvailabilityApplicationTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImportCandidateAvailabilityApplicationTasksQuery, ImportCandidateAvailabilityApplicationTasksQueryVariables>(ImportCandidateAvailabilityApplicationTasksDocument, options);
        }
export type ImportCandidateAvailabilityApplicationTasksQueryHookResult = ReturnType<typeof useImportCandidateAvailabilityApplicationTasksQuery>;
export type ImportCandidateAvailabilityApplicationTasksLazyQueryHookResult = ReturnType<typeof useImportCandidateAvailabilityApplicationTasksLazyQuery>;
export type ImportCandidateAvailabilityApplicationTasksQueryResult = Apollo.QueryResult<ImportCandidateAvailabilityApplicationTasksQuery, ImportCandidateAvailabilityApplicationTasksQueryVariables>;
export const AllCompanyHolidaysDocument = gql`
    query AllCompanyHolidays($input: CompanyHolidaysInput!) {
  companyHolidays(input: $input) {
    items {
      ...CompanyHolidays
    }
  }
}
    ${CompanyHolidaysFragmentDoc}`;

/**
 * __useAllCompanyHolidaysQuery__
 *
 * To run a query within a React component, call `useAllCompanyHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompanyHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompanyHolidaysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllCompanyHolidaysQuery(baseOptions: Apollo.QueryHookOptions<AllCompanyHolidaysQuery, AllCompanyHolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCompanyHolidaysQuery, AllCompanyHolidaysQueryVariables>(AllCompanyHolidaysDocument, options);
      }
export function useAllCompanyHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCompanyHolidaysQuery, AllCompanyHolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCompanyHolidaysQuery, AllCompanyHolidaysQueryVariables>(AllCompanyHolidaysDocument, options);
        }
export type AllCompanyHolidaysQueryHookResult = ReturnType<typeof useAllCompanyHolidaysQuery>;
export type AllCompanyHolidaysLazyQueryHookResult = ReturnType<typeof useAllCompanyHolidaysLazyQuery>;
export type AllCompanyHolidaysQueryResult = Apollo.QueryResult<AllCompanyHolidaysQuery, AllCompanyHolidaysQueryVariables>;
export const DirectoryInfoDocument = gql`
    query DirectoryInfo {
  thisOrg {
    id
    integrations {
      google {
        adminUserId
      }
      microsoft {
        active
      }
    }
  }
}
    `;

/**
 * __useDirectoryInfoQuery__
 *
 * To run a query within a React component, call `useDirectoryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectoryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectoryInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useDirectoryInfoQuery(baseOptions?: Apollo.QueryHookOptions<DirectoryInfoQuery, DirectoryInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DirectoryInfoQuery, DirectoryInfoQueryVariables>(DirectoryInfoDocument, options);
      }
export function useDirectoryInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DirectoryInfoQuery, DirectoryInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DirectoryInfoQuery, DirectoryInfoQueryVariables>(DirectoryInfoDocument, options);
        }
export type DirectoryInfoQueryHookResult = ReturnType<typeof useDirectoryInfoQuery>;
export type DirectoryInfoLazyQueryHookResult = ReturnType<typeof useDirectoryInfoLazyQuery>;
export type DirectoryInfoQueryResult = Apollo.QueryResult<DirectoryInfoQuery, DirectoryInfoQueryVariables>;
export const OrgHasAtLeastOneAdminDocument = gql`
    query OrgHasAtLeastOneAdmin {
  thisOrg {
    id
    hasAdmin
  }
}
    `;

/**
 * __useOrgHasAtLeastOneAdminQuery__
 *
 * To run a query within a React component, call `useOrgHasAtLeastOneAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgHasAtLeastOneAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgHasAtLeastOneAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgHasAtLeastOneAdminQuery(baseOptions?: Apollo.QueryHookOptions<OrgHasAtLeastOneAdminQuery, OrgHasAtLeastOneAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgHasAtLeastOneAdminQuery, OrgHasAtLeastOneAdminQueryVariables>(OrgHasAtLeastOneAdminDocument, options);
      }
export function useOrgHasAtLeastOneAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgHasAtLeastOneAdminQuery, OrgHasAtLeastOneAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgHasAtLeastOneAdminQuery, OrgHasAtLeastOneAdminQueryVariables>(OrgHasAtLeastOneAdminDocument, options);
        }
export type OrgHasAtLeastOneAdminQueryHookResult = ReturnType<typeof useOrgHasAtLeastOneAdminQuery>;
export type OrgHasAtLeastOneAdminLazyQueryHookResult = ReturnType<typeof useOrgHasAtLeastOneAdminLazyQuery>;
export type OrgHasAtLeastOneAdminQueryResult = Apollo.QueryResult<OrgHasAtLeastOneAdminQuery, OrgHasAtLeastOneAdminQueryVariables>;
export const OrgNameDocument = gql`
    query OrgName {
  thisOrg {
    id
    name
  }
}
    `;

/**
 * __useOrgNameQuery__
 *
 * To run a query within a React component, call `useOrgNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgNameQuery(baseOptions?: Apollo.QueryHookOptions<OrgNameQuery, OrgNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgNameQuery, OrgNameQueryVariables>(OrgNameDocument, options);
      }
export function useOrgNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgNameQuery, OrgNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgNameQuery, OrgNameQueryVariables>(OrgNameDocument, options);
        }
export type OrgNameQueryHookResult = ReturnType<typeof useOrgNameQuery>;
export type OrgNameLazyQueryHookResult = ReturnType<typeof useOrgNameLazyQuery>;
export type OrgNameQueryResult = Apollo.QueryResult<OrgNameQuery, OrgNameQueryVariables>;
export const OrgSlugDocument = gql`
    query OrgSlug {
  thisOrg {
    id
    slugs {
      id: orgId
      slug
    }
  }
}
    `;

/**
 * __useOrgSlugQuery__
 *
 * To run a query within a React component, call `useOrgSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgSlugQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgSlugQuery(baseOptions?: Apollo.QueryHookOptions<OrgSlugQuery, OrgSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgSlugQuery, OrgSlugQueryVariables>(OrgSlugDocument, options);
      }
export function useOrgSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgSlugQuery, OrgSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgSlugQuery, OrgSlugQueryVariables>(OrgSlugDocument, options);
        }
export type OrgSlugQueryHookResult = ReturnType<typeof useOrgSlugQuery>;
export type OrgSlugLazyQueryHookResult = ReturnType<typeof useOrgSlugLazyQuery>;
export type OrgSlugQueryResult = Apollo.QueryResult<OrgSlugQuery, OrgSlugQueryVariables>;
export const OrgHasSamlSsoDocument = gql`
    query OrgHasSamlSso {
  thisOrg {
    id
    hasSamlSso
    hasScim
  }
}
    `;

/**
 * __useOrgHasSamlSsoQuery__
 *
 * To run a query within a React component, call `useOrgHasSamlSsoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgHasSamlSsoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgHasSamlSsoQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgHasSamlSsoQuery(baseOptions?: Apollo.QueryHookOptions<OrgHasSamlSsoQuery, OrgHasSamlSsoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgHasSamlSsoQuery, OrgHasSamlSsoQueryVariables>(OrgHasSamlSsoDocument, options);
      }
export function useOrgHasSamlSsoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgHasSamlSsoQuery, OrgHasSamlSsoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgHasSamlSsoQuery, OrgHasSamlSsoQueryVariables>(OrgHasSamlSsoDocument, options);
        }
export type OrgHasSamlSsoQueryHookResult = ReturnType<typeof useOrgHasSamlSsoQuery>;
export type OrgHasSamlSsoLazyQueryHookResult = ReturnType<typeof useOrgHasSamlSsoLazyQuery>;
export type OrgHasSamlSsoQueryResult = Apollo.QueryResult<OrgHasSamlSsoQuery, OrgHasSamlSsoQueryVariables>;
export const SelfScheduleOptionsByIdDocument = gql`
    query SelfScheduleOptionsById($id: uuid!) {
  selfScheduleOptions(id: $id) {
    id
    createdAt
    creatorEmployeeId
    deletedAt
    jobStageId
    interviewerEventTemplateId
    candidateEventTemplateId
    candidateEmailTemplateId
    lastUpdatedByEmployeeId
    options {
      inclusionDays
      rollingDays
      advanceNoticeInHours
      candidateCalendarId
      interviewerCalendarId
      location
      zoomHost
      zoomHostUserId
      candidateEmail
    }
    rescheduledAt
    selfScheduleEmailTemplateId
    selfScheduleEmailSentAt
    updatedAt
  }
}
    `;

/**
 * __useSelfScheduleOptionsByIdQuery__
 *
 * To run a query within a React component, call `useSelfScheduleOptionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfScheduleOptionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfScheduleOptionsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelfScheduleOptionsByIdQuery(baseOptions: Apollo.QueryHookOptions<SelfScheduleOptionsByIdQuery, SelfScheduleOptionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfScheduleOptionsByIdQuery, SelfScheduleOptionsByIdQueryVariables>(SelfScheduleOptionsByIdDocument, options);
      }
export function useSelfScheduleOptionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfScheduleOptionsByIdQuery, SelfScheduleOptionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfScheduleOptionsByIdQuery, SelfScheduleOptionsByIdQueryVariables>(SelfScheduleOptionsByIdDocument, options);
        }
export type SelfScheduleOptionsByIdQueryHookResult = ReturnType<typeof useSelfScheduleOptionsByIdQuery>;
export type SelfScheduleOptionsByIdLazyQueryHookResult = ReturnType<typeof useSelfScheduleOptionsByIdLazyQuery>;
export type SelfScheduleOptionsByIdQueryResult = Apollo.QueryResult<SelfScheduleOptionsByIdQuery, SelfScheduleOptionsByIdQueryVariables>;
export const ShortUrlCreateDocument = gql`
    mutation ShortUrlCreate($input: ShortUrlCreateInput!) {
  shortUrlCreate(input: $input) {
    shortUrl
  }
}
    `;
export type ShortUrlCreateMutationFn = Apollo.MutationFunction<ShortUrlCreateMutation, ShortUrlCreateMutationVariables>;

/**
 * __useShortUrlCreateMutation__
 *
 * To run a mutation, you first call `useShortUrlCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShortUrlCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shortUrlCreateMutation, { data, loading, error }] = useShortUrlCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShortUrlCreateMutation(baseOptions?: Apollo.MutationHookOptions<ShortUrlCreateMutation, ShortUrlCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShortUrlCreateMutation, ShortUrlCreateMutationVariables>(ShortUrlCreateDocument, options);
      }
export type ShortUrlCreateMutationHookResult = ReturnType<typeof useShortUrlCreateMutation>;
export type ShortUrlCreateMutationResult = Apollo.MutationResult<ShortUrlCreateMutation>;
export type ShortUrlCreateMutationOptions = Apollo.BaseMutationOptions<ShortUrlCreateMutation, ShortUrlCreateMutationVariables>;
export const SyncCandidateDocument = gql`
    query SyncCandidate($id: uuid!) {
  candidate(id: $id) {
    id
    applications {
      id
    }
  }
}
    `;

/**
 * __useSyncCandidateQuery__
 *
 * To run a query within a React component, call `useSyncCandidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncCandidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncCandidateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyncCandidateQuery(baseOptions: Apollo.QueryHookOptions<SyncCandidateQuery, SyncCandidateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SyncCandidateQuery, SyncCandidateQueryVariables>(SyncCandidateDocument, options);
      }
export function useSyncCandidateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SyncCandidateQuery, SyncCandidateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SyncCandidateQuery, SyncCandidateQueryVariables>(SyncCandidateDocument, options);
        }
export type SyncCandidateQueryHookResult = ReturnType<typeof useSyncCandidateQuery>;
export type SyncCandidateLazyQueryHookResult = ReturnType<typeof useSyncCandidateLazyQuery>;
export type SyncCandidateQueryResult = Apollo.QueryResult<SyncCandidateQuery, SyncCandidateQueryVariables>;
export const IntegrationHealthCheckBannersDocument = gql`
    query IntegrationHealthCheckBanners($force: Boolean!, $includeGem: Boolean!) {
  integrationCheckHealth(input: {force: $force}) {
    google {
      ...GoogleHealthCheckBanner_google
    }
    microsoft {
      ...MicrosoftHealthCheckBanner_microsoft
    }
    microsoftGccHigh {
      ...MicrosoftHealthCheckBanner_microsoftGccHigh
    }
    zoom {
      ...ZoomHealthCheckBanner_zoom
    }
    greenhouse {
      ...GreenhouseHealthCheckBanner_greenhouse
    }
    ashby {
      ...AshbyHealthCheckBanner_ashby
    }
    lever {
      ...LeverHealthCheckBanner_lever
    }
    gem @include(if: $includeGem) {
      ...GemHealthCheckBanner_gem
    }
  }
}
    ${GoogleHealthCheckBanner_GoogleFragmentDoc}
${MicrosoftHealthCheckBanner_MicrosoftFragmentDoc}
${MicrosoftHealthCheckBanner_MicrosoftGccHighFragmentDoc}
${ZoomHealthCheckBanner_ZoomFragmentDoc}
${GreenhouseHealthCheckBanner_GreenhouseFragmentDoc}
${AshbyHealthCheckBanner_AshbyFragmentDoc}
${LeverHealthCheckBanner_LeverFragmentDoc}
${GemHealthCheckBanner_GemFragmentDoc}`;

/**
 * __useIntegrationHealthCheckBannersQuery__
 *
 * To run a query within a React component, call `useIntegrationHealthCheckBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationHealthCheckBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationHealthCheckBannersQuery({
 *   variables: {
 *      force: // value for 'force'
 *      includeGem: // value for 'includeGem'
 *   },
 * });
 */
export function useIntegrationHealthCheckBannersQuery(baseOptions: Apollo.QueryHookOptions<IntegrationHealthCheckBannersQuery, IntegrationHealthCheckBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationHealthCheckBannersQuery, IntegrationHealthCheckBannersQueryVariables>(IntegrationHealthCheckBannersDocument, options);
      }
export function useIntegrationHealthCheckBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationHealthCheckBannersQuery, IntegrationHealthCheckBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationHealthCheckBannersQuery, IntegrationHealthCheckBannersQueryVariables>(IntegrationHealthCheckBannersDocument, options);
        }
export type IntegrationHealthCheckBannersQueryHookResult = ReturnType<typeof useIntegrationHealthCheckBannersQuery>;
export type IntegrationHealthCheckBannersLazyQueryHookResult = ReturnType<typeof useIntegrationHealthCheckBannersLazyQuery>;
export type IntegrationHealthCheckBannersQueryResult = Apollo.QueryResult<IntegrationHealthCheckBannersQuery, IntegrationHealthCheckBannersQueryVariables>;
export const GetUserPermissionsDocument = gql`
    query GetUserPermissions {
  thisUserInfo {
    permissions
  }
}
    `;

/**
 * __useGetUserPermissionsQuery__
 *
 * To run a query within a React component, call `useGetUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>(GetUserPermissionsDocument, options);
      }
export function useGetUserPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>(GetUserPermissionsDocument, options);
        }
export type GetUserPermissionsQueryHookResult = ReturnType<typeof useGetUserPermissionsQuery>;
export type GetUserPermissionsLazyQueryHookResult = ReturnType<typeof useGetUserPermissionsLazyQuery>;
export type GetUserPermissionsQueryResult = Apollo.QueryResult<GetUserPermissionsQuery, GetUserPermissionsQueryVariables>;
export const JobStageNameByIdDocument = gql`
    query JobStageNameById($id: uuid!) {
  jobStage(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useJobStageNameByIdQuery__
 *
 * To run a query within a React component, call `useJobStageNameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobStageNameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobStageNameByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobStageNameByIdQuery(baseOptions: Apollo.QueryHookOptions<JobStageNameByIdQuery, JobStageNameByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobStageNameByIdQuery, JobStageNameByIdQueryVariables>(JobStageNameByIdDocument, options);
      }
export function useJobStageNameByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobStageNameByIdQuery, JobStageNameByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobStageNameByIdQuery, JobStageNameByIdQueryVariables>(JobStageNameByIdDocument, options);
        }
export type JobStageNameByIdQueryHookResult = ReturnType<typeof useJobStageNameByIdQuery>;
export type JobStageNameByIdLazyQueryHookResult = ReturnType<typeof useJobStageNameByIdLazyQuery>;
export type JobStageNameByIdQueryResult = Apollo.QueryResult<JobStageNameByIdQuery, JobStageNameByIdQueryVariables>;
export const ApplicationSidebarDocument = gql`
    query ApplicationSidebar($applicationId: uuid!) {
  application(id: $applicationId) {
    id
    ...ApplicationSidebarHeader_application
    ...ApplicationSidebarContent_application
  }
}
    ${ApplicationSidebarHeader_ApplicationFragmentDoc}
${ApplicationSidebarContent_ApplicationFragmentDoc}`;

/**
 * __useApplicationSidebarQuery__
 *
 * To run a query within a React component, call `useApplicationSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationSidebarQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useApplicationSidebarQuery(baseOptions: Apollo.QueryHookOptions<ApplicationSidebarQuery, ApplicationSidebarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationSidebarQuery, ApplicationSidebarQueryVariables>(ApplicationSidebarDocument, options);
      }
export function useApplicationSidebarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationSidebarQuery, ApplicationSidebarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationSidebarQuery, ApplicationSidebarQueryVariables>(ApplicationSidebarDocument, options);
        }
export type ApplicationSidebarQueryHookResult = ReturnType<typeof useApplicationSidebarQuery>;
export type ApplicationSidebarLazyQueryHookResult = ReturnType<typeof useApplicationSidebarLazyQuery>;
export type ApplicationSidebarQueryResult = Apollo.QueryResult<ApplicationSidebarQuery, ApplicationSidebarQueryVariables>;
export const GetExtSidebarDataDocument = gql`
    query GetExtSidebarData($candidateId: uuid!, $hasCandidateId: Boolean!, $fetchJob: Boolean!) {
  candidate(id: $candidateId) @include(if: $hasCandidateId) {
    id
    applications {
      id
      atsId
      job @include(if: $fetchJob) {
        atsJob {
          atsId
          atsFields {
            ... on WorkdayJob {
              referenceId
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetExtSidebarDataQuery__
 *
 * To run a query within a React component, call `useGetExtSidebarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtSidebarDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtSidebarDataQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      hasCandidateId: // value for 'hasCandidateId'
 *      fetchJob: // value for 'fetchJob'
 *   },
 * });
 */
export function useGetExtSidebarDataQuery(baseOptions: Apollo.QueryHookOptions<GetExtSidebarDataQuery, GetExtSidebarDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExtSidebarDataQuery, GetExtSidebarDataQueryVariables>(GetExtSidebarDataDocument, options);
      }
export function useGetExtSidebarDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtSidebarDataQuery, GetExtSidebarDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExtSidebarDataQuery, GetExtSidebarDataQueryVariables>(GetExtSidebarDataDocument, options);
        }
export type GetExtSidebarDataQueryHookResult = ReturnType<typeof useGetExtSidebarDataQuery>;
export type GetExtSidebarDataLazyQueryHookResult = ReturnType<typeof useGetExtSidebarDataLazyQuery>;
export type GetExtSidebarDataQueryResult = Apollo.QueryResult<GetExtSidebarDataQuery, GetExtSidebarDataQueryVariables>;
export const ApplicationTokensDocument = gql`
    query ApplicationTokens($id: String, $renderType: RenderType!, $input: TokenApplicationInput!) {
  templateToken(input: {id: $id, renderType: $renderType}) {
    id
    INTERNAL_APPLICATION_NOTE(input: $input)
    APPLICATION_CANDIDATE_PORTAL_URL(input: $input)
  }
}
    `;

/**
 * __useApplicationTokensQuery__
 *
 * To run a query within a React component, call `useApplicationTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationTokensQuery({
 *   variables: {
 *      id: // value for 'id'
 *      renderType: // value for 'renderType'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplicationTokensQuery(baseOptions: Apollo.QueryHookOptions<ApplicationTokensQuery, ApplicationTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationTokensQuery, ApplicationTokensQueryVariables>(ApplicationTokensDocument, options);
      }
export function useApplicationTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationTokensQuery, ApplicationTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationTokensQuery, ApplicationTokensQueryVariables>(ApplicationTokensDocument, options);
        }
export type ApplicationTokensQueryHookResult = ReturnType<typeof useApplicationTokensQuery>;
export type ApplicationTokensLazyQueryHookResult = ReturnType<typeof useApplicationTokensLazyQuery>;
export type ApplicationTokensQueryResult = Apollo.QueryResult<ApplicationTokensQuery, ApplicationTokensQueryVariables>;
export const CandidateAvailabilityTokensDocument = gql`
    query CandidateAvailabilityTokens($id: String, $renderType: RenderType!, $candidateAvailabilityInput: TokenCandidateAvailabilityInput!, $timezone: timezone!, $applicationId: uuid!, $jobStageId: uuid!) {
  CANDIDATE_AVAILABILITY: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    CANDIDATE_AVAILABILITY(input: $candidateAvailabilityInput)
  }
  CANDIDATE_AVAILABILITY_TIMEZONE: templateToken(
    input: {id: $id, renderType: $renderType}
  ) {
    id
    CANDIDATE_AVAILABILITY_TIMEZONE(input: {timezone: $timezone})
  }
  CANDIDATE_AVAILABILITY_NOTE: templateToken(
    input: {id: $id, renderType: $renderType}
  ) {
    id
    CANDIDATE_AVAILABILITY_NOTE(
      input: {applicationId: $applicationId, jobStageId: $jobStageId}
    )
  }
}
    `;

/**
 * __useCandidateAvailabilityTokensQuery__
 *
 * To run a query within a React component, call `useCandidateAvailabilityTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateAvailabilityTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateAvailabilityTokensQuery({
 *   variables: {
 *      id: // value for 'id'
 *      renderType: // value for 'renderType'
 *      candidateAvailabilityInput: // value for 'candidateAvailabilityInput'
 *      timezone: // value for 'timezone'
 *      applicationId: // value for 'applicationId'
 *      jobStageId: // value for 'jobStageId'
 *   },
 * });
 */
export function useCandidateAvailabilityTokensQuery(baseOptions: Apollo.QueryHookOptions<CandidateAvailabilityTokensQuery, CandidateAvailabilityTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateAvailabilityTokensQuery, CandidateAvailabilityTokensQueryVariables>(CandidateAvailabilityTokensDocument, options);
      }
export function useCandidateAvailabilityTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateAvailabilityTokensQuery, CandidateAvailabilityTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateAvailabilityTokensQuery, CandidateAvailabilityTokensQueryVariables>(CandidateAvailabilityTokensDocument, options);
        }
export type CandidateAvailabilityTokensQueryHookResult = ReturnType<typeof useCandidateAvailabilityTokensQuery>;
export type CandidateAvailabilityTokensLazyQueryHookResult = ReturnType<typeof useCandidateAvailabilityTokensLazyQuery>;
export type CandidateAvailabilityTokensQueryResult = Apollo.QueryResult<CandidateAvailabilityTokensQuery, CandidateAvailabilityTokensQueryVariables>;
export const CandidateTokensRequestDocument = gql`
    query CandidateTokensRequest($id: String, $renderType: RenderType!, $candidateId: uuid!, $applicationId: uuid!, $jobId: uuid!, $candidateLinkLabel: String, $hasApplicationId: Boolean!, $hasJobId: Boolean!) {
  CANDIDATE_NAME: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    CANDIDATE_NAME(input: {id: $candidateId, jobId: $jobId})
  }
  CANDIDATE_FIRST_NAME: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    CANDIDATE_FIRST_NAME(input: {id: $candidateId, jobId: $jobId})
  }
  CANDIDATE_LAST_NAME: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    CANDIDATE_LAST_NAME(input: {id: $candidateId, jobId: $jobId})
  }
  CANDIDATE_LAST_INITIAL: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    CANDIDATE_LAST_INITIAL(input: {id: $candidateId, jobId: $jobId})
  }
  CANDIDATE_EMAIL_ADDRESS: templateToken(
    input: {id: $id, renderType: $renderType}
  ) {
    id
    CANDIDATE_EMAIL_ADDRESS(input: {id: $candidateId, jobId: $jobId})
  }
  CANDIDATE_PHONE: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    CANDIDATE_PHONE(input: {id: $candidateId, jobId: $jobId})
  }
  CANDIDATE_PRONOUNS: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    CANDIDATE_PRONOUNS(input: {id: $candidateId, jobId: $jobId})
  }
  CANDIDATE_LINKEDIN: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    CANDIDATE_LINKEDIN(input: {id: $candidateId})
  }
  COORDINATOR_EMAIL: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    COORDINATOR_EMAIL(input: {id: $candidateId, jobId: $jobId})
  }
  COORDINATOR: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    COORDINATOR(input: {id: $candidateId, jobId: $jobId})
  }
  RECRUITER_EMAIL: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    RECRUITER_EMAIL(input: {id: $candidateId, jobId: $jobId})
  }
  RECRUITER: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    RECRUITER(input: {id: $candidateId, jobId: $jobId})
  }
  HIRING_MANAGER: templateToken(input: {id: $id, renderType: $renderType}) @include(if: $hasJobId) {
    id
    HIRING_MANAGER(input: {jobId: $jobId})
  }
  CANDIDATE_LINK: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    CANDIDATE_LINK(input: {id: $candidateId, label: $candidateLinkLabel})
  }
  SOURCER: templateToken(input: {id: $id, renderType: $renderType}) @include(if: $hasApplicationId) {
    id
    SOURCER(input: {id: $applicationId})
  }
  CANDIDATE_SOURCER: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    CANDIDATE_SOURCER(id: $candidateId)
  }
}
    `;

/**
 * __useCandidateTokensRequestQuery__
 *
 * To run a query within a React component, call `useCandidateTokensRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateTokensRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateTokensRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *      renderType: // value for 'renderType'
 *      candidateId: // value for 'candidateId'
 *      applicationId: // value for 'applicationId'
 *      jobId: // value for 'jobId'
 *      candidateLinkLabel: // value for 'candidateLinkLabel'
 *      hasApplicationId: // value for 'hasApplicationId'
 *      hasJobId: // value for 'hasJobId'
 *   },
 * });
 */
export function useCandidateTokensRequestQuery(baseOptions: Apollo.QueryHookOptions<CandidateTokensRequestQuery, CandidateTokensRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateTokensRequestQuery, CandidateTokensRequestQueryVariables>(CandidateTokensRequestDocument, options);
      }
export function useCandidateTokensRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateTokensRequestQuery, CandidateTokensRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateTokensRequestQuery, CandidateTokensRequestQueryVariables>(CandidateTokensRequestDocument, options);
        }
export type CandidateTokensRequestQueryHookResult = ReturnType<typeof useCandidateTokensRequestQuery>;
export type CandidateTokensRequestLazyQueryHookResult = ReturnType<typeof useCandidateTokensRequestLazyQuery>;
export type CandidateTokensRequestQueryResult = Apollo.QueryResult<CandidateTokensRequestQuery, CandidateTokensRequestQueryVariables>;
export const DebriefTokensDocument = gql`
    query DebriefTokens($id: String, $renderType: RenderType!, $input: TokenDebriefScheduleInput!) {
  DEBRIEF_SCHEDULE: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    DEBRIEF_SCHEDULE(input: $input)
  }
}
    `;

/**
 * __useDebriefTokensQuery__
 *
 * To run a query within a React component, call `useDebriefTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebriefTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebriefTokensQuery({
 *   variables: {
 *      id: // value for 'id'
 *      renderType: // value for 'renderType'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDebriefTokensQuery(baseOptions: Apollo.QueryHookOptions<DebriefTokensQuery, DebriefTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DebriefTokensQuery, DebriefTokensQueryVariables>(DebriefTokensDocument, options);
      }
export function useDebriefTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DebriefTokensQuery, DebriefTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DebriefTokensQuery, DebriefTokensQueryVariables>(DebriefTokensDocument, options);
        }
export type DebriefTokensQueryHookResult = ReturnType<typeof useDebriefTokensQuery>;
export type DebriefTokensLazyQueryHookResult = ReturnType<typeof useDebriefTokensLazyQuery>;
export type DebriefTokensQueryResult = Apollo.QueryResult<DebriefTokensQuery, DebriefTokensQueryVariables>;
export const InterviewTokensDocument = gql`
    query InterviewTokens($id: String, $uuid: uuid!, $renderType: RenderType!, $name: TokenInterviewNameInput!, $names: TokenInterviewerNamesInput!, $scheduleNames: TokenInterviewerNamesInput!, $days: TokenInterviewDaysInput!, $schedule: TokenInterviewScheduleInput!, $scheduleStartTime: TokenInterviewScheduleStartTimeInput!, $internalInterviewSchedule: TokenInterviewScheduleInput!, $kitLink: TokenInterviewKitLinkInput!, $codeLink: CodeLinkInput!, $hasScorecard: Boolean!, $videoMeetingLink: TokenVideoMeetingLinkInput!, $zoom: TokenZoomMeetingInput!, $trainingInfo: TokenInterviewerTrainingInfoInput!, $hasName: Boolean!, $hasNames: Boolean!, $hasDays: Boolean!, $hasSchedule: Boolean!, $hasScheduleStartTime: Boolean!, $hasInternalInterviewSchedule: Boolean!, $hasCodeLink: Boolean!, $hasVideoMeetingLink: Boolean!, $hasZoom: Boolean!, $atsInterviewDefinitionId: String) {
  INTERVIEW_NAME: templateToken(input: {id: $id, renderType: $renderType}) @include(if: $hasName) {
    id
    INTERVIEW_NAME(input: $name)
  }
  INTERVIEWER_NAMES: templateToken(input: {id: $id, renderType: $renderType}) @include(if: $hasNames) {
    id
    INTERVIEWER_NAMES(input: $names)
  }
  SCHEDULE_INTERVIEWER_NAMES: templateToken(
    input: {id: $id, renderType: $renderType}
  ) @include(if: $hasNames) {
    id
    INTERVIEWER_NAMES(input: $scheduleNames)
  }
  INTERVIEW_DAYS: templateToken(input: {id: $id, renderType: $renderType}) @include(if: $hasDays) {
    id
    INTERVIEW_DAYS(input: $days)
  }
  INTERVIEW_SCHEDULE: templateToken(input: {id: $id, renderType: $renderType}) @include(if: $hasSchedule) {
    id
    INTERVIEW_SCHEDULE(input: $schedule)
  }
  INTERVIEW_SCHEDULE_START_TIME: templateToken(
    input: {id: $id, renderType: $renderType}
  ) @include(if: $hasScheduleStartTime) {
    id
    INTERVIEW_SCHEDULE_START_TIME(input: $scheduleStartTime)
  }
  INTERNAL_INTERVIEW_SCHEDULE: templateToken(
    input: {id: $id, renderType: $renderType}
  ) @include(if: $hasInternalInterviewSchedule) {
    id
    INTERNAL_INTERVIEW_SCHEDULE(input: $internalInterviewSchedule)
  }
  INTERVIEW_KIT_LINK: templateToken(input: {id: $id, renderType: $renderType}) {
    id
    INTERVIEW_KIT_LINK(input: $kitLink)
  }
  INTERVIEW_SCORECARD_NAME: templateToken(
    input: {id: $id, renderType: $renderType}
  ) @include(if: $hasScorecard) {
    id
    INTERVIEW_SCORECARD_NAME(
      input: {id: $uuid, atsInterviewDefinitionId: $atsInterviewDefinitionId}
    )
  }
  CODE_LINK: templateToken(input: {id: $id, renderType: $renderType}) @include(if: $hasCodeLink) {
    id
    CODE_LINK(input: $codeLink)
  }
  VIDEO_MEETING_LINK: templateToken(input: {id: $id, renderType: $renderType}) @include(if: $hasVideoMeetingLink) {
    id
    VIDEO_MEETING_LINK(input: $videoMeetingLink)
  }
  ZOOM_MEETING_DIAL_IN_INFO: templateToken(
    input: {id: $id, renderType: $renderType}
  ) @include(if: $hasZoom) {
    id
    ZOOM_MEETING_DIAL_IN_INFO(input: $zoom)
  }
  ZOOM_MEETING_INFO: templateToken(input: {id: $id, renderType: $renderType}) @include(if: $hasZoom) {
    id
    ZOOM_MEETING_INFO(input: $zoom)
  }
  INTERVIEWER_TRAINING_INFO: templateToken(
    input: {id: $id, renderType: $renderType}
  ) {
    id
    INTERVIEWER_TRAINING_INFO(input: $trainingInfo)
  }
}
    `;

/**
 * __useInterviewTokensQuery__
 *
 * To run a query within a React component, call `useInterviewTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewTokensQuery({
 *   variables: {
 *      id: // value for 'id'
 *      uuid: // value for 'uuid'
 *      renderType: // value for 'renderType'
 *      name: // value for 'name'
 *      names: // value for 'names'
 *      scheduleNames: // value for 'scheduleNames'
 *      days: // value for 'days'
 *      schedule: // value for 'schedule'
 *      scheduleStartTime: // value for 'scheduleStartTime'
 *      internalInterviewSchedule: // value for 'internalInterviewSchedule'
 *      kitLink: // value for 'kitLink'
 *      codeLink: // value for 'codeLink'
 *      hasScorecard: // value for 'hasScorecard'
 *      videoMeetingLink: // value for 'videoMeetingLink'
 *      zoom: // value for 'zoom'
 *      trainingInfo: // value for 'trainingInfo'
 *      hasName: // value for 'hasName'
 *      hasNames: // value for 'hasNames'
 *      hasDays: // value for 'hasDays'
 *      hasSchedule: // value for 'hasSchedule'
 *      hasScheduleStartTime: // value for 'hasScheduleStartTime'
 *      hasInternalInterviewSchedule: // value for 'hasInternalInterviewSchedule'
 *      hasCodeLink: // value for 'hasCodeLink'
 *      hasVideoMeetingLink: // value for 'hasVideoMeetingLink'
 *      hasZoom: // value for 'hasZoom'
 *      atsInterviewDefinitionId: // value for 'atsInterviewDefinitionId'
 *   },
 * });
 */
export function useInterviewTokensQuery(baseOptions: Apollo.QueryHookOptions<InterviewTokensQuery, InterviewTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewTokensQuery, InterviewTokensQueryVariables>(InterviewTokensDocument, options);
      }
export function useInterviewTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewTokensQuery, InterviewTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewTokensQuery, InterviewTokensQueryVariables>(InterviewTokensDocument, options);
        }
export type InterviewTokensQueryHookResult = ReturnType<typeof useInterviewTokensQuery>;
export type InterviewTokensLazyQueryHookResult = ReturnType<typeof useInterviewTokensLazyQuery>;
export type InterviewTokensQueryResult = Apollo.QueryResult<InterviewTokensQuery, InterviewTokensQueryVariables>;
export const JobTokensDocument = gql`
    query JobTokens($id: String, $renderType: RenderType!, $jobId: uuid!, $jobStageId: uuid!, $applicationId: uuid, $externalJobNameJobId: uuid) {
  templateToken(input: {id: $id, renderType: $renderType}) {
    id
    JOB_NAME(input: {id: $jobId})
    JOB_LOCATION(input: {id: $jobId})
    JOB_STAGE_NAME(input: {id: $jobStageId})
    EXTERNAL_JOB_NAME(
      input: {id: $externalJobNameJobId, applicationId: $applicationId}
    )
  }
}
    `;

/**
 * __useJobTokensQuery__
 *
 * To run a query within a React component, call `useJobTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobTokensQuery({
 *   variables: {
 *      id: // value for 'id'
 *      renderType: // value for 'renderType'
 *      jobId: // value for 'jobId'
 *      jobStageId: // value for 'jobStageId'
 *      applicationId: // value for 'applicationId'
 *      externalJobNameJobId: // value for 'externalJobNameJobId'
 *   },
 * });
 */
export function useJobTokensQuery(baseOptions: Apollo.QueryHookOptions<JobTokensQuery, JobTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobTokensQuery, JobTokensQueryVariables>(JobTokensDocument, options);
      }
export function useJobTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobTokensQuery, JobTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobTokensQuery, JobTokensQueryVariables>(JobTokensDocument, options);
        }
export type JobTokensQueryHookResult = ReturnType<typeof useJobTokensQuery>;
export type JobTokensLazyQueryHookResult = ReturnType<typeof useJobTokensLazyQuery>;
export type JobTokensQueryResult = Apollo.QueryResult<JobTokensQuery, JobTokensQueryVariables>;
export const MeetingsTokenDocument = gql`
    query MeetingsToken($id: String, $renderType: RenderType!, $zoom: TokenZoomMeetingInput!, $videoMeetingLink: TokenVideoMeetingLinkInput!, $hasVideoMeetingLink: Boolean!, $hasZoom: Boolean!) {
  VIDEO_MEETING_LINK: templateToken(input: {id: $id, renderType: $renderType}) @include(if: $hasVideoMeetingLink) {
    id
    VIDEO_MEETING_LINK(input: $videoMeetingLink)
  }
  ZOOM_MEETING_DIAL_IN_INFO: templateToken(
    input: {id: $id, renderType: $renderType}
  ) @include(if: $hasZoom) {
    id
    ZOOM_MEETING_DIAL_IN_INFO(input: $zoom)
  }
  ZOOM_MEETING_INFO: templateToken(input: {id: $id, renderType: $renderType}) @include(if: $hasZoom) {
    id
    ZOOM_MEETING_INFO(input: $zoom)
  }
}
    `;

/**
 * __useMeetingsTokenQuery__
 *
 * To run a query within a React component, call `useMeetingsTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingsTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingsTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *      renderType: // value for 'renderType'
 *      zoom: // value for 'zoom'
 *      videoMeetingLink: // value for 'videoMeetingLink'
 *      hasVideoMeetingLink: // value for 'hasVideoMeetingLink'
 *      hasZoom: // value for 'hasZoom'
 *   },
 * });
 */
export function useMeetingsTokenQuery(baseOptions: Apollo.QueryHookOptions<MeetingsTokenQuery, MeetingsTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeetingsTokenQuery, MeetingsTokenQueryVariables>(MeetingsTokenDocument, options);
      }
export function useMeetingsTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeetingsTokenQuery, MeetingsTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeetingsTokenQuery, MeetingsTokenQueryVariables>(MeetingsTokenDocument, options);
        }
export type MeetingsTokenQueryHookResult = ReturnType<typeof useMeetingsTokenQuery>;
export type MeetingsTokenLazyQueryHookResult = ReturnType<typeof useMeetingsTokenLazyQuery>;
export type MeetingsTokenQueryResult = Apollo.QueryResult<MeetingsTokenQuery, MeetingsTokenQueryVariables>;
export const TaskTokensDocument = gql`
    query TaskTokens($id: String, $renderType: RenderType!, $taskId: uuid, $assigneeId: uuid) {
  templateToken(input: {id: $id, renderType: $renderType}) {
    id
    TASK_CREATOR(input: {taskId: $taskId})
    TASK_ASSIGNEE(input: {taskId: $taskId, assigneeId: $assigneeId})
  }
}
    `;

/**
 * __useTaskTokensQuery__
 *
 * To run a query within a React component, call `useTaskTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTokensQuery({
 *   variables: {
 *      id: // value for 'id'
 *      renderType: // value for 'renderType'
 *      taskId: // value for 'taskId'
 *      assigneeId: // value for 'assigneeId'
 *   },
 * });
 */
export function useTaskTokensQuery(baseOptions: Apollo.QueryHookOptions<TaskTokensQuery, TaskTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskTokensQuery, TaskTokensQueryVariables>(TaskTokensDocument, options);
      }
export function useTaskTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskTokensQuery, TaskTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskTokensQuery, TaskTokensQueryVariables>(TaskTokensDocument, options);
        }
export type TaskTokensQueryHookResult = ReturnType<typeof useTaskTokensQuery>;
export type TaskTokensLazyQueryHookResult = ReturnType<typeof useTaskTokensLazyQuery>;
export type TaskTokensQueryResult = Apollo.QueryResult<TaskTokensQuery, TaskTokensQueryVariables>;
export const UserTokensDocument = gql`
    query UserTokens($id: String, $renderType: RenderType!) {
  templateToken(input: {id: $id, renderType: $renderType}) {
    id
    ME
    MY_FULL_NAME
    MY_FIRST_NAME
    MY_PHONE_NUMBER
    MY_SIGNATURE
    COMPANY_NAME
    COMPANY
  }
}
    `;

/**
 * __useUserTokensQuery__
 *
 * To run a query within a React component, call `useUserTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTokensQuery({
 *   variables: {
 *      id: // value for 'id'
 *      renderType: // value for 'renderType'
 *   },
 * });
 */
export function useUserTokensQuery(baseOptions: Apollo.QueryHookOptions<UserTokensQuery, UserTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTokensQuery, UserTokensQueryVariables>(UserTokensDocument, options);
      }
export function useUserTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTokensQuery, UserTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTokensQuery, UserTokensQueryVariables>(UserTokensDocument, options);
        }
export type UserTokensQueryHookResult = ReturnType<typeof useUserTokensQuery>;
export type UserTokensLazyQueryHookResult = ReturnType<typeof useUserTokensLazyQuery>;
export type UserTokensQueryResult = Apollo.QueryResult<UserTokensQuery, UserTokensQueryVariables>;
export const OrgInfoDocument = gql`
    query OrgInfo {
  thisOrg {
    id
    createdAt
  }
}
    `;

/**
 * __useOrgInfoQuery__
 *
 * To run a query within a React component, call `useOrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgInfoQuery(baseOptions?: Apollo.QueryHookOptions<OrgInfoQuery, OrgInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgInfoQuery, OrgInfoQueryVariables>(OrgInfoDocument, options);
      }
export function useOrgInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgInfoQuery, OrgInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgInfoQuery, OrgInfoQueryVariables>(OrgInfoDocument, options);
        }
export type OrgInfoQueryHookResult = ReturnType<typeof useOrgInfoQuery>;
export type OrgInfoLazyQueryHookResult = ReturnType<typeof useOrgInfoLazyQuery>;
export type OrgInfoQueryResult = Apollo.QueryResult<OrgInfoQuery, OrgInfoQueryVariables>;
export const TaskListDocument = gql`
    query TaskList($applicationId: uuid!) {
  application(id: $applicationId) {
    id
    tasks {
      ...TaskList_task
    }
    ...ApplicationSidebarCreateTaskActions_application
  }
}
    ${TaskList_TaskFragmentDoc}
${ApplicationSidebarCreateTaskActions_ApplicationFragmentDoc}`;

/**
 * __useTaskListQuery__
 *
 * To run a query within a React component, call `useTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskListQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useTaskListQuery(baseOptions: Apollo.QueryHookOptions<TaskListQuery, TaskListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskListQuery, TaskListQueryVariables>(TaskListDocument, options);
      }
export function useTaskListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskListQuery, TaskListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskListQuery, TaskListQueryVariables>(TaskListDocument, options);
        }
export type TaskListQueryHookResult = ReturnType<typeof useTaskListQuery>;
export type TaskListLazyQueryHookResult = ReturnType<typeof useTaskListLazyQuery>;
export type TaskListQueryResult = Apollo.QueryResult<TaskListQuery, TaskListQueryVariables>;
export const TaskShareDialogDocument = gql`
    query TaskShareDialog($taskId: uuid!) {
  task(id: $taskId) {
    id
    application {
      id
      jobId
      candidate {
        id
        fullName
        coordinatorId
        recruiterId
      }
    }
    availabilityRequests {
      id
      applicationId
      availabilityEndDate
      availabilityStartDate
      candidateNote
      externalId
      createdAt
      jobStageId
      minimumTimeBlockMinutes
      minutesPerDays
      numberOfDays
      timezone
      updatedAt
      lastCandidateCommunicationSentAt
      advanceNoticeMinutes
    }
    selfScheduleRequests {
      id
      applicationId
      createdAt
      creatorEmployeeId
      deletedAt
      jobStageId
      interviewerEventTemplateId
      candidateEventTemplateId
      candidateEmailTemplateId
      lastUpdatedByEmployeeId
      options {
        inclusionDays
        rollingDays
        advanceNoticeInHours
        candidateCalendarId
        interviewerCalendarId
        location
        zoomHost
        zoomHostUserId
        candidateEmail
      }
      rescheduledAt
      selfScheduleEmailTemplateId
      selfScheduleEmailSentAt
      updatedAt
    }
  }
}
    `;

/**
 * __useTaskShareDialogQuery__
 *
 * To run a query within a React component, call `useTaskShareDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskShareDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskShareDialogQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskShareDialogQuery(baseOptions: Apollo.QueryHookOptions<TaskShareDialogQuery, TaskShareDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskShareDialogQuery, TaskShareDialogQueryVariables>(TaskShareDialogDocument, options);
      }
export function useTaskShareDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskShareDialogQuery, TaskShareDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskShareDialogQuery, TaskShareDialogQueryVariables>(TaskShareDialogDocument, options);
        }
export type TaskShareDialogQueryHookResult = ReturnType<typeof useTaskShareDialogQuery>;
export type TaskShareDialogLazyQueryHookResult = ReturnType<typeof useTaskShareDialogLazyQuery>;
export type TaskShareDialogQueryResult = Apollo.QueryResult<TaskShareDialogQuery, TaskShareDialogQueryVariables>;
export const EditCandidatePhoneNumberDocument = gql`
    mutation EditCandidatePhoneNumber($input: CandidateUpdateInput!) {
  candidateUpdate(input: $input) {
    candidate {
      id
      ...EditCandidatePhoneNumberModal_candidate
    }
  }
}
    ${EditCandidatePhoneNumberModal_CandidateFragmentDoc}`;
export type EditCandidatePhoneNumberMutationFn = Apollo.MutationFunction<EditCandidatePhoneNumberMutation, EditCandidatePhoneNumberMutationVariables>;

/**
 * __useEditCandidatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useEditCandidatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCandidatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCandidatePhoneNumberMutation, { data, loading, error }] = useEditCandidatePhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCandidatePhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<EditCandidatePhoneNumberMutation, EditCandidatePhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCandidatePhoneNumberMutation, EditCandidatePhoneNumberMutationVariables>(EditCandidatePhoneNumberDocument, options);
      }
export type EditCandidatePhoneNumberMutationHookResult = ReturnType<typeof useEditCandidatePhoneNumberMutation>;
export type EditCandidatePhoneNumberMutationResult = Apollo.MutationResult<EditCandidatePhoneNumberMutation>;
export type EditCandidatePhoneNumberMutationOptions = Apollo.BaseMutationOptions<EditCandidatePhoneNumberMutation, EditCandidatePhoneNumberMutationVariables>;
export const ToggleCandidatePortalDocument = gql`
    mutation toggleCandidatePortal($input: ApplicationUpdateInput!) {
  applicationUpdate(input: $input) {
    ...CandidatePortalActionsMenu_application
  }
}
    ${CandidatePortalActionsMenu_ApplicationFragmentDoc}`;
export type ToggleCandidatePortalMutationFn = Apollo.MutationFunction<ToggleCandidatePortalMutation, ToggleCandidatePortalMutationVariables>;

/**
 * __useToggleCandidatePortalMutation__
 *
 * To run a mutation, you first call `useToggleCandidatePortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCandidatePortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCandidatePortalMutation, { data, loading, error }] = useToggleCandidatePortalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleCandidatePortalMutation(baseOptions?: Apollo.MutationHookOptions<ToggleCandidatePortalMutation, ToggleCandidatePortalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleCandidatePortalMutation, ToggleCandidatePortalMutationVariables>(ToggleCandidatePortalDocument, options);
      }
export type ToggleCandidatePortalMutationHookResult = ReturnType<typeof useToggleCandidatePortalMutation>;
export type ToggleCandidatePortalMutationResult = Apollo.MutationResult<ToggleCandidatePortalMutation>;
export type ToggleCandidatePortalMutationOptions = Apollo.BaseMutationOptions<ToggleCandidatePortalMutation, ToggleCandidatePortalMutationVariables>;
export const ResetPrimaryEmailDocument = gql`
    mutation resetPrimaryEmail($input: CandidateUpdateInput!) {
  candidateUpdate(input: $input) {
    candidate {
      id
      email
      preferredEmail
    }
  }
}
    `;
export type ResetPrimaryEmailMutationFn = Apollo.MutationFunction<ResetPrimaryEmailMutation, ResetPrimaryEmailMutationVariables>;

/**
 * __useResetPrimaryEmailMutation__
 *
 * To run a mutation, you first call `useResetPrimaryEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPrimaryEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPrimaryEmailMutation, { data, loading, error }] = useResetPrimaryEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPrimaryEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResetPrimaryEmailMutation, ResetPrimaryEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPrimaryEmailMutation, ResetPrimaryEmailMutationVariables>(ResetPrimaryEmailDocument, options);
      }
export type ResetPrimaryEmailMutationHookResult = ReturnType<typeof useResetPrimaryEmailMutation>;
export type ResetPrimaryEmailMutationResult = Apollo.MutationResult<ResetPrimaryEmailMutation>;
export type ResetPrimaryEmailMutationOptions = Apollo.BaseMutationOptions<ResetPrimaryEmailMutation, ResetPrimaryEmailMutationVariables>;
export const DeleteCandidatePortalNoteDocument = gql`
    mutation deleteCandidatePortalNote($applicationCandidatePortalNoteDeleteId: uuid!) {
  applicationCandidatePortalNoteDelete(
    id: $applicationCandidatePortalNoteDeleteId
  ) {
    result
  }
}
    `;
export type DeleteCandidatePortalNoteMutationFn = Apollo.MutationFunction<DeleteCandidatePortalNoteMutation, DeleteCandidatePortalNoteMutationVariables>;

/**
 * __useDeleteCandidatePortalNoteMutation__
 *
 * To run a mutation, you first call `useDeleteCandidatePortalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCandidatePortalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCandidatePortalNoteMutation, { data, loading, error }] = useDeleteCandidatePortalNoteMutation({
 *   variables: {
 *      applicationCandidatePortalNoteDeleteId: // value for 'applicationCandidatePortalNoteDeleteId'
 *   },
 * });
 */
export function useDeleteCandidatePortalNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCandidatePortalNoteMutation, DeleteCandidatePortalNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCandidatePortalNoteMutation, DeleteCandidatePortalNoteMutationVariables>(DeleteCandidatePortalNoteDocument, options);
      }
export type DeleteCandidatePortalNoteMutationHookResult = ReturnType<typeof useDeleteCandidatePortalNoteMutation>;
export type DeleteCandidatePortalNoteMutationResult = Apollo.MutationResult<DeleteCandidatePortalNoteMutation>;
export type DeleteCandidatePortalNoteMutationOptions = Apollo.BaseMutationOptions<DeleteCandidatePortalNoteMutation, DeleteCandidatePortalNoteMutationVariables>;
export const CreateCandidatePortalNoteDocument = gql`
    mutation createCandidatePortalNote($applicationCandidatePortalNoteCreateInput: ApplicationCandidatePortalNoteCreateInput!) {
  applicationCandidatePortalNoteCreate(
    input: $applicationCandidatePortalNoteCreateInput
  ) {
    id
    updatedAt
    lastNotificationSentAt
    ...CandidatePortalNoteEditor_candidatePortalNote
  }
}
    ${CandidatePortalNoteEditor_CandidatePortalNoteFragmentDoc}`;
export type CreateCandidatePortalNoteMutationFn = Apollo.MutationFunction<CreateCandidatePortalNoteMutation, CreateCandidatePortalNoteMutationVariables>;

/**
 * __useCreateCandidatePortalNoteMutation__
 *
 * To run a mutation, you first call `useCreateCandidatePortalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCandidatePortalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCandidatePortalNoteMutation, { data, loading, error }] = useCreateCandidatePortalNoteMutation({
 *   variables: {
 *      applicationCandidatePortalNoteCreateInput: // value for 'applicationCandidatePortalNoteCreateInput'
 *   },
 * });
 */
export function useCreateCandidatePortalNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateCandidatePortalNoteMutation, CreateCandidatePortalNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCandidatePortalNoteMutation, CreateCandidatePortalNoteMutationVariables>(CreateCandidatePortalNoteDocument, options);
      }
export type CreateCandidatePortalNoteMutationHookResult = ReturnType<typeof useCreateCandidatePortalNoteMutation>;
export type CreateCandidatePortalNoteMutationResult = Apollo.MutationResult<CreateCandidatePortalNoteMutation>;
export type CreateCandidatePortalNoteMutationOptions = Apollo.BaseMutationOptions<CreateCandidatePortalNoteMutation, CreateCandidatePortalNoteMutationVariables>;
export const UpdateCandidatePortalNoteDocument = gql`
    mutation updateCandidatePortalNote($input: ApplicationCandidatePortalNoteUpdateInput!) {
  applicationCandidatePortalNoteUpdate(input: $input) {
    id
    updatedAt
    lastNotificationSentAt
    ...CandidatePortalNoteEditor_candidatePortalNote
  }
}
    ${CandidatePortalNoteEditor_CandidatePortalNoteFragmentDoc}`;
export type UpdateCandidatePortalNoteMutationFn = Apollo.MutationFunction<UpdateCandidatePortalNoteMutation, UpdateCandidatePortalNoteMutationVariables>;

/**
 * __useUpdateCandidatePortalNoteMutation__
 *
 * To run a mutation, you first call `useUpdateCandidatePortalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidatePortalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidatePortalNoteMutation, { data, loading, error }] = useUpdateCandidatePortalNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCandidatePortalNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCandidatePortalNoteMutation, UpdateCandidatePortalNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCandidatePortalNoteMutation, UpdateCandidatePortalNoteMutationVariables>(UpdateCandidatePortalNoteDocument, options);
      }
export type UpdateCandidatePortalNoteMutationHookResult = ReturnType<typeof useUpdateCandidatePortalNoteMutation>;
export type UpdateCandidatePortalNoteMutationResult = Apollo.MutationResult<UpdateCandidatePortalNoteMutation>;
export type UpdateCandidatePortalNoteMutationOptions = Apollo.BaseMutationOptions<UpdateCandidatePortalNoteMutation, UpdateCandidatePortalNoteMutationVariables>;
export const ToggleCandidatePortalStatusDocument = gql`
    mutation toggleCandidatePortalStatus($input: ApplicationUpdateInput!) {
  applicationUpdate(input: $input) {
    id
    isPortalAccessible
  }
}
    `;
export type ToggleCandidatePortalStatusMutationFn = Apollo.MutationFunction<ToggleCandidatePortalStatusMutation, ToggleCandidatePortalStatusMutationVariables>;

/**
 * __useToggleCandidatePortalStatusMutation__
 *
 * To run a mutation, you first call `useToggleCandidatePortalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCandidatePortalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCandidatePortalStatusMutation, { data, loading, error }] = useToggleCandidatePortalStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleCandidatePortalStatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleCandidatePortalStatusMutation, ToggleCandidatePortalStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleCandidatePortalStatusMutation, ToggleCandidatePortalStatusMutationVariables>(ToggleCandidatePortalStatusDocument, options);
      }
export type ToggleCandidatePortalStatusMutationHookResult = ReturnType<typeof useToggleCandidatePortalStatusMutation>;
export type ToggleCandidatePortalStatusMutationResult = Apollo.MutationResult<ToggleCandidatePortalStatusMutation>;
export type ToggleCandidatePortalStatusMutationOptions = Apollo.BaseMutationOptions<ToggleCandidatePortalStatusMutation, ToggleCandidatePortalStatusMutationVariables>;
export const CandidateUpdateCoiDocument = gql`
    mutation CandidateUpdateCOI($input: CandidateUpdateInput!) {
  candidateUpdate(input: $input) {
    candidate {
      id
      ...ConflictInterestSection_candidate
    }
  }
}
    ${ConflictInterestSection_CandidateFragmentDoc}`;
export type CandidateUpdateCoiMutationFn = Apollo.MutationFunction<CandidateUpdateCoiMutation, CandidateUpdateCoiMutationVariables>;

/**
 * __useCandidateUpdateCoiMutation__
 *
 * To run a mutation, you first call `useCandidateUpdateCoiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateUpdateCoiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateUpdateCoiMutation, { data, loading, error }] = useCandidateUpdateCoiMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCandidateUpdateCoiMutation(baseOptions?: Apollo.MutationHookOptions<CandidateUpdateCoiMutation, CandidateUpdateCoiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateUpdateCoiMutation, CandidateUpdateCoiMutationVariables>(CandidateUpdateCoiDocument, options);
      }
export type CandidateUpdateCoiMutationHookResult = ReturnType<typeof useCandidateUpdateCoiMutation>;
export type CandidateUpdateCoiMutationResult = Apollo.MutationResult<CandidateUpdateCoiMutation>;
export type CandidateUpdateCoiMutationOptions = Apollo.BaseMutationOptions<CandidateUpdateCoiMutation, CandidateUpdateCoiMutationVariables>;
export const ApplicationNoteUpdateMutationDocument = gql`
    mutation applicationNoteUpdateMutation($input: ApplicationUpdateInput!) {
  applicationUpdate(input: $input) {
    id
    internalNote
  }
}
    `;
export type ApplicationNoteUpdateMutationMutationFn = Apollo.MutationFunction<ApplicationNoteUpdateMutationMutation, ApplicationNoteUpdateMutationMutationVariables>;

/**
 * __useApplicationNoteUpdateMutationMutation__
 *
 * To run a mutation, you first call `useApplicationNoteUpdateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplicationNoteUpdateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applicationNoteUpdateMutationMutation, { data, loading, error }] = useApplicationNoteUpdateMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplicationNoteUpdateMutationMutation(baseOptions?: Apollo.MutationHookOptions<ApplicationNoteUpdateMutationMutation, ApplicationNoteUpdateMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplicationNoteUpdateMutationMutation, ApplicationNoteUpdateMutationMutationVariables>(ApplicationNoteUpdateMutationDocument, options);
      }
export type ApplicationNoteUpdateMutationMutationHookResult = ReturnType<typeof useApplicationNoteUpdateMutationMutation>;
export type ApplicationNoteUpdateMutationMutationResult = Apollo.MutationResult<ApplicationNoteUpdateMutationMutation>;
export type ApplicationNoteUpdateMutationMutationOptions = Apollo.BaseMutationOptions<ApplicationNoteUpdateMutationMutation, ApplicationNoteUpdateMutationMutationVariables>;
export const InternalNoteUpdateDocument = gql`
    mutation InternalNoteUpdate($input: ApplicationUpdateInput!) {
  applicationUpdate(input: $input) {
    id
    internalNote
  }
}
    `;
export type InternalNoteUpdateMutationFn = Apollo.MutationFunction<InternalNoteUpdateMutation, InternalNoteUpdateMutationVariables>;

/**
 * __useInternalNoteUpdateMutation__
 *
 * To run a mutation, you first call `useInternalNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInternalNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [internalNoteUpdateMutation, { data, loading, error }] = useInternalNoteUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInternalNoteUpdateMutation(baseOptions?: Apollo.MutationHookOptions<InternalNoteUpdateMutation, InternalNoteUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InternalNoteUpdateMutation, InternalNoteUpdateMutationVariables>(InternalNoteUpdateDocument, options);
      }
export type InternalNoteUpdateMutationHookResult = ReturnType<typeof useInternalNoteUpdateMutation>;
export type InternalNoteUpdateMutationResult = Apollo.MutationResult<InternalNoteUpdateMutation>;
export type InternalNoteUpdateMutationOptions = Apollo.BaseMutationOptions<InternalNoteUpdateMutation, InternalNoteUpdateMutationVariables>;
export const ApplicationTaskViewApplicationsDocument = gql`
    query ApplicationTaskViewApplications($id: uuid!) {
  candidate(id: $id) {
    id
    applications {
      id
    }
  }
}
    `;

/**
 * __useApplicationTaskViewApplicationsQuery__
 *
 * To run a query within a React component, call `useApplicationTaskViewApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationTaskViewApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationTaskViewApplicationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicationTaskViewApplicationsQuery(baseOptions: Apollo.QueryHookOptions<ApplicationTaskViewApplicationsQuery, ApplicationTaskViewApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationTaskViewApplicationsQuery, ApplicationTaskViewApplicationsQueryVariables>(ApplicationTaskViewApplicationsDocument, options);
      }
export function useApplicationTaskViewApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationTaskViewApplicationsQuery, ApplicationTaskViewApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationTaskViewApplicationsQuery, ApplicationTaskViewApplicationsQueryVariables>(ApplicationTaskViewApplicationsDocument, options);
        }
export type ApplicationTaskViewApplicationsQueryHookResult = ReturnType<typeof useApplicationTaskViewApplicationsQuery>;
export type ApplicationTaskViewApplicationsLazyQueryHookResult = ReturnType<typeof useApplicationTaskViewApplicationsLazyQuery>;
export type ApplicationTaskViewApplicationsQueryResult = Apollo.QueryResult<ApplicationTaskViewApplicationsQuery, ApplicationTaskViewApplicationsQueryVariables>;
export const ApplicationDetailsTaskViewDocument = gql`
    query ApplicationDetailsTaskView($applicationId: uuid!) {
  application(id: $applicationId) {
    id
    ...ApplicationContent_application
    ...ApplicationSidebarCreateTaskButton_application
    ...CandidateSecondaryContent_application
    candidate {
      ...CandidateHeader_candidateFullName
      ...CandidateSecondaryContent_candidate
      ...Candidate_candidate
      applications {
        ...CandidateSecondaryContent_applications
      }
    }
  }
}
    ${ApplicationContent_ApplicationFragmentDoc}
${ApplicationSidebarCreateTaskButton_ApplicationFragmentDoc}
${CandidateSecondaryContent_ApplicationFragmentDoc}
${CandidateHeader_CandidateFullNameFragmentDoc}
${CandidateSecondaryContent_CandidateFragmentDoc}
${Candidate_CandidateFragmentDoc}
${CandidateSecondaryContent_ApplicationsFragmentDoc}`;

/**
 * __useApplicationDetailsTaskViewQuery__
 *
 * To run a query within a React component, call `useApplicationDetailsTaskViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationDetailsTaskViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationDetailsTaskViewQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useApplicationDetailsTaskViewQuery(baseOptions: Apollo.QueryHookOptions<ApplicationDetailsTaskViewQuery, ApplicationDetailsTaskViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationDetailsTaskViewQuery, ApplicationDetailsTaskViewQueryVariables>(ApplicationDetailsTaskViewDocument, options);
      }
export function useApplicationDetailsTaskViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationDetailsTaskViewQuery, ApplicationDetailsTaskViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationDetailsTaskViewQuery, ApplicationDetailsTaskViewQueryVariables>(ApplicationDetailsTaskViewDocument, options);
        }
export type ApplicationDetailsTaskViewQueryHookResult = ReturnType<typeof useApplicationDetailsTaskViewQuery>;
export type ApplicationDetailsTaskViewLazyQueryHookResult = ReturnType<typeof useApplicationDetailsTaskViewLazyQuery>;
export type ApplicationDetailsTaskViewQueryResult = Apollo.QueryResult<ApplicationDetailsTaskViewQuery, ApplicationDetailsTaskViewQueryVariables>;
export const CandidateAvailabilityOptionsModalDocument = gql`
    query CandidateAvailabilityOptionsModal($applicationId: uuid!) {
  application(id: $applicationId) {
    id
    ...CreateTaskDialogPage_application
  }
  thisEmployee {
    id
    ...Employee
  }
}
    ${CreateTaskDialogPage_ApplicationFragmentDoc}
${EmployeeFragmentDoc}`;

/**
 * __useCandidateAvailabilityOptionsModalQuery__
 *
 * To run a query within a React component, call `useCandidateAvailabilityOptionsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateAvailabilityOptionsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateAvailabilityOptionsModalQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useCandidateAvailabilityOptionsModalQuery(baseOptions: Apollo.QueryHookOptions<CandidateAvailabilityOptionsModalQuery, CandidateAvailabilityOptionsModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateAvailabilityOptionsModalQuery, CandidateAvailabilityOptionsModalQueryVariables>(CandidateAvailabilityOptionsModalDocument, options);
      }
export function useCandidateAvailabilityOptionsModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateAvailabilityOptionsModalQuery, CandidateAvailabilityOptionsModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateAvailabilityOptionsModalQuery, CandidateAvailabilityOptionsModalQueryVariables>(CandidateAvailabilityOptionsModalDocument, options);
        }
export type CandidateAvailabilityOptionsModalQueryHookResult = ReturnType<typeof useCandidateAvailabilityOptionsModalQuery>;
export type CandidateAvailabilityOptionsModalLazyQueryHookResult = ReturnType<typeof useCandidateAvailabilityOptionsModalLazyQuery>;
export type CandidateAvailabilityOptionsModalQueryResult = Apollo.QueryResult<CandidateAvailabilityOptionsModalQuery, CandidateAvailabilityOptionsModalQueryVariables>;
export const CandidateAvailabilityOptionsInterviewPlanDocument = gql`
    query CandidateAvailabilityOptionsInterviewPlan($jobStageId: uuid!) {
  jobStage(id: $jobStageId) {
    id
    ...CreateTaskDialogPage_interviewPlan
    ...cloneInterviewPlan_interviewPlan
    ...getSaveInterviewPlanInput_interviewPlan
    ...CandidateAvailabilityOption_interviewPlan
  }
}
    ${CreateTaskDialogPage_InterviewPlanFragmentDoc}
${CloneInterviewPlan_InterviewPlanFragmentDoc}
${GetSaveInterviewPlanInput_InterviewPlanFragmentDoc}
${CandidateAvailabilityOption_InterviewPlanFragmentDoc}`;

/**
 * __useCandidateAvailabilityOptionsInterviewPlanQuery__
 *
 * To run a query within a React component, call `useCandidateAvailabilityOptionsInterviewPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateAvailabilityOptionsInterviewPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateAvailabilityOptionsInterviewPlanQuery({
 *   variables: {
 *      jobStageId: // value for 'jobStageId'
 *   },
 * });
 */
export function useCandidateAvailabilityOptionsInterviewPlanQuery(baseOptions: Apollo.QueryHookOptions<CandidateAvailabilityOptionsInterviewPlanQuery, CandidateAvailabilityOptionsInterviewPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateAvailabilityOptionsInterviewPlanQuery, CandidateAvailabilityOptionsInterviewPlanQueryVariables>(CandidateAvailabilityOptionsInterviewPlanDocument, options);
      }
export function useCandidateAvailabilityOptionsInterviewPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateAvailabilityOptionsInterviewPlanQuery, CandidateAvailabilityOptionsInterviewPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateAvailabilityOptionsInterviewPlanQuery, CandidateAvailabilityOptionsInterviewPlanQueryVariables>(CandidateAvailabilityOptionsInterviewPlanDocument, options);
        }
export type CandidateAvailabilityOptionsInterviewPlanQueryHookResult = ReturnType<typeof useCandidateAvailabilityOptionsInterviewPlanQuery>;
export type CandidateAvailabilityOptionsInterviewPlanLazyQueryHookResult = ReturnType<typeof useCandidateAvailabilityOptionsInterviewPlanLazyQuery>;
export type CandidateAvailabilityOptionsInterviewPlanQueryResult = Apollo.QueryResult<CandidateAvailabilityOptionsInterviewPlanQuery, CandidateAvailabilityOptionsInterviewPlanQueryVariables>;
export const TaskInterviewPlanDocument = gql`
    query TaskInterviewPlan($id: uuid!) {
  task(id: $id) {
    interviewPlan {
      id
    }
  }
}
    `;

/**
 * __useTaskInterviewPlanQuery__
 *
 * To run a query within a React component, call `useTaskInterviewPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskInterviewPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskInterviewPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskInterviewPlanQuery(baseOptions: Apollo.QueryHookOptions<TaskInterviewPlanQuery, TaskInterviewPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskInterviewPlanQuery, TaskInterviewPlanQueryVariables>(TaskInterviewPlanDocument, options);
      }
export function useTaskInterviewPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskInterviewPlanQuery, TaskInterviewPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskInterviewPlanQuery, TaskInterviewPlanQueryVariables>(TaskInterviewPlanDocument, options);
        }
export type TaskInterviewPlanQueryHookResult = ReturnType<typeof useTaskInterviewPlanQuery>;
export type TaskInterviewPlanLazyQueryHookResult = ReturnType<typeof useTaskInterviewPlanLazyQuery>;
export type TaskInterviewPlanQueryResult = Apollo.QueryResult<TaskInterviewPlanQuery, TaskInterviewPlanQueryVariables>;
export const CandidateAvailabilityOptionsDeleteDocument = gql`
    mutation CandidateAvailabilityOptionsDelete($id: uuid!) {
  candidateAvailabilityOptionsDelete(id: $id) {
    result
  }
}
    `;
export type CandidateAvailabilityOptionsDeleteMutationFn = Apollo.MutationFunction<CandidateAvailabilityOptionsDeleteMutation, CandidateAvailabilityOptionsDeleteMutationVariables>;

/**
 * __useCandidateAvailabilityOptionsDeleteMutation__
 *
 * To run a mutation, you first call `useCandidateAvailabilityOptionsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateAvailabilityOptionsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateAvailabilityOptionsDeleteMutation, { data, loading, error }] = useCandidateAvailabilityOptionsDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateAvailabilityOptionsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CandidateAvailabilityOptionsDeleteMutation, CandidateAvailabilityOptionsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateAvailabilityOptionsDeleteMutation, CandidateAvailabilityOptionsDeleteMutationVariables>(CandidateAvailabilityOptionsDeleteDocument, options);
      }
export type CandidateAvailabilityOptionsDeleteMutationHookResult = ReturnType<typeof useCandidateAvailabilityOptionsDeleteMutation>;
export type CandidateAvailabilityOptionsDeleteMutationResult = Apollo.MutationResult<CandidateAvailabilityOptionsDeleteMutation>;
export type CandidateAvailabilityOptionsDeleteMutationOptions = Apollo.BaseMutationOptions<CandidateAvailabilityOptionsDeleteMutation, CandidateAvailabilityOptionsDeleteMutationVariables>;
export const CandidateAvailabilityTaskCreateDocument = gql`
    mutation CandidateAvailabilityTaskCreate($input: TaskCreateInput!) {
  taskCreate(input: $input) {
    task {
      id
    }
  }
}
    `;
export type CandidateAvailabilityTaskCreateMutationFn = Apollo.MutationFunction<CandidateAvailabilityTaskCreateMutation, CandidateAvailabilityTaskCreateMutationVariables>;

/**
 * __useCandidateAvailabilityTaskCreateMutation__
 *
 * To run a mutation, you first call `useCandidateAvailabilityTaskCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateAvailabilityTaskCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateAvailabilityTaskCreateMutation, { data, loading, error }] = useCandidateAvailabilityTaskCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCandidateAvailabilityTaskCreateMutation(baseOptions?: Apollo.MutationHookOptions<CandidateAvailabilityTaskCreateMutation, CandidateAvailabilityTaskCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateAvailabilityTaskCreateMutation, CandidateAvailabilityTaskCreateMutationVariables>(CandidateAvailabilityTaskCreateDocument, options);
      }
export type CandidateAvailabilityTaskCreateMutationHookResult = ReturnType<typeof useCandidateAvailabilityTaskCreateMutation>;
export type CandidateAvailabilityTaskCreateMutationResult = Apollo.MutationResult<CandidateAvailabilityTaskCreateMutation>;
export type CandidateAvailabilityTaskCreateMutationOptions = Apollo.BaseMutationOptions<CandidateAvailabilityTaskCreateMutation, CandidateAvailabilityTaskCreateMutationVariables>;
export const CandidateAvailabilityRequestUpsertDocument = gql`
    mutation CandidateAvailabilityRequestUpsert($input: CandidateAvailabilityOptionsUpsertInput!) {
  candidateAvailabilityOptionsUpsert(input: $input) {
    options {
      id
    }
  }
}
    `;
export type CandidateAvailabilityRequestUpsertMutationFn = Apollo.MutationFunction<CandidateAvailabilityRequestUpsertMutation, CandidateAvailabilityRequestUpsertMutationVariables>;

/**
 * __useCandidateAvailabilityRequestUpsertMutation__
 *
 * To run a mutation, you first call `useCandidateAvailabilityRequestUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateAvailabilityRequestUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateAvailabilityRequestUpsertMutation, { data, loading, error }] = useCandidateAvailabilityRequestUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCandidateAvailabilityRequestUpsertMutation(baseOptions?: Apollo.MutationHookOptions<CandidateAvailabilityRequestUpsertMutation, CandidateAvailabilityRequestUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CandidateAvailabilityRequestUpsertMutation, CandidateAvailabilityRequestUpsertMutationVariables>(CandidateAvailabilityRequestUpsertDocument, options);
      }
export type CandidateAvailabilityRequestUpsertMutationHookResult = ReturnType<typeof useCandidateAvailabilityRequestUpsertMutation>;
export type CandidateAvailabilityRequestUpsertMutationResult = Apollo.MutationResult<CandidateAvailabilityRequestUpsertMutation>;
export type CandidateAvailabilityRequestUpsertMutationOptions = Apollo.BaseMutationOptions<CandidateAvailabilityRequestUpsertMutation, CandidateAvailabilityRequestUpsertMutationVariables>;
export const CandidateApplicationsDocument = gql`
    query CandidateApplications($id: uuid!) {
  candidate(id: $id) {
    id
    applications {
      id
      atsId
    }
  }
}
    `;

/**
 * __useCandidateApplicationsQuery__
 *
 * To run a query within a React component, call `useCandidateApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateApplicationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateApplicationsQuery(baseOptions: Apollo.QueryHookOptions<CandidateApplicationsQuery, CandidateApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateApplicationsQuery, CandidateApplicationsQueryVariables>(CandidateApplicationsDocument, options);
      }
export function useCandidateApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateApplicationsQuery, CandidateApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateApplicationsQuery, CandidateApplicationsQueryVariables>(CandidateApplicationsDocument, options);
        }
export type CandidateApplicationsQueryHookResult = ReturnType<typeof useCandidateApplicationsQuery>;
export type CandidateApplicationsLazyQueryHookResult = ReturnType<typeof useCandidateApplicationsLazyQuery>;
export type CandidateApplicationsQueryResult = Apollo.QueryResult<CandidateApplicationsQuery, CandidateApplicationsQueryVariables>;
export const ApplicationActionsDocument = gql`
    query ApplicationActions($applicationId: uuid!) {
  application(id: $applicationId) {
    id
    status
    upcomingApplicationStage {
      minStartAt
    }
    jobStageId
    applicationStages {
      id
    }
  }
}
    `;

/**
 * __useApplicationActionsQuery__
 *
 * To run a query within a React component, call `useApplicationActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationActionsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useApplicationActionsQuery(baseOptions: Apollo.QueryHookOptions<ApplicationActionsQuery, ApplicationActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationActionsQuery, ApplicationActionsQueryVariables>(ApplicationActionsDocument, options);
      }
export function useApplicationActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationActionsQuery, ApplicationActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationActionsQuery, ApplicationActionsQueryVariables>(ApplicationActionsDocument, options);
        }
export type ApplicationActionsQueryHookResult = ReturnType<typeof useApplicationActionsQuery>;
export type ApplicationActionsLazyQueryHookResult = ReturnType<typeof useApplicationActionsLazyQuery>;
export type ApplicationActionsQueryResult = Apollo.QueryResult<ApplicationActionsQuery, ApplicationActionsQueryVariables>;
export const ApplicationStageStatusDocument = gql`
    query ApplicationStageStatus($applicationId: uuid!) {
  application(id: $applicationId) {
    id
    upcomingApplicationStage {
      minStartAt
    }
    applicationStages {
      ...CancelScheduleApplicationStage
      minStartAt
      maxEndAt
      isCancelled
      hasInterviewerDeclined
      hasInterviewerTentative
      hasCandidateDeclined
      hasCandidateTentative
    }
  }
}
    ${CancelScheduleApplicationStageFragmentDoc}`;

/**
 * __useApplicationStageStatusQuery__
 *
 * To run a query within a React component, call `useApplicationStageStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationStageStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationStageStatusQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useApplicationStageStatusQuery(baseOptions: Apollo.QueryHookOptions<ApplicationStageStatusQuery, ApplicationStageStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationStageStatusQuery, ApplicationStageStatusQueryVariables>(ApplicationStageStatusDocument, options);
      }
export function useApplicationStageStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationStageStatusQuery, ApplicationStageStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationStageStatusQuery, ApplicationStageStatusQueryVariables>(ApplicationStageStatusDocument, options);
        }
export type ApplicationStageStatusQueryHookResult = ReturnType<typeof useApplicationStageStatusQuery>;
export type ApplicationStageStatusLazyQueryHookResult = ReturnType<typeof useApplicationStageStatusLazyQuery>;
export type ApplicationStageStatusQueryResult = Apollo.QueryResult<ApplicationStageStatusQuery, ApplicationStageStatusQueryVariables>;
export const ApplicationStageActionsDocument = gql`
    query ApplicationStageActions($applicationStageId: uuid!) {
  applicationStage(id: $applicationStageId) {
    id
    isCancelled
    minStartAt
    maxEndAt
    hasInterviewerDeclined
    hasCandidateDeclined
    applicationId
    applicationStageInterviews {
      id
      startAt
      endAt
    }
  }
}
    `;

/**
 * __useApplicationStageActionsQuery__
 *
 * To run a query within a React component, call `useApplicationStageActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationStageActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationStageActionsQuery({
 *   variables: {
 *      applicationStageId: // value for 'applicationStageId'
 *   },
 * });
 */
export function useApplicationStageActionsQuery(baseOptions: Apollo.QueryHookOptions<ApplicationStageActionsQuery, ApplicationStageActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationStageActionsQuery, ApplicationStageActionsQueryVariables>(ApplicationStageActionsDocument, options);
      }
export function useApplicationStageActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationStageActionsQuery, ApplicationStageActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationStageActionsQuery, ApplicationStageActionsQueryVariables>(ApplicationStageActionsDocument, options);
        }
export type ApplicationStageActionsQueryHookResult = ReturnType<typeof useApplicationStageActionsQuery>;
export type ApplicationStageActionsLazyQueryHookResult = ReturnType<typeof useApplicationStageActionsLazyQuery>;
export type ApplicationStageActionsQueryResult = Apollo.QueryResult<ApplicationStageActionsQuery, ApplicationStageActionsQueryVariables>;
export const CandidateApplicationStagesDocument = gql`
    query CandidateApplicationStages($input: uuid!) {
  candidate(id: $input) {
    id
    fullName
    applications {
      ...CandidateApplicationStages
    }
  }
}
    ${CandidateApplicationStagesFragmentDoc}`;

/**
 * __useCandidateApplicationStagesQuery__
 *
 * To run a query within a React component, call `useCandidateApplicationStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateApplicationStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateApplicationStagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCandidateApplicationStagesQuery(baseOptions: Apollo.QueryHookOptions<CandidateApplicationStagesQuery, CandidateApplicationStagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateApplicationStagesQuery, CandidateApplicationStagesQueryVariables>(CandidateApplicationStagesDocument, options);
      }
export function useCandidateApplicationStagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateApplicationStagesQuery, CandidateApplicationStagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateApplicationStagesQuery, CandidateApplicationStagesQueryVariables>(CandidateApplicationStagesDocument, options);
        }
export type CandidateApplicationStagesQueryHookResult = ReturnType<typeof useCandidateApplicationStagesQuery>;
export type CandidateApplicationStagesLazyQueryHookResult = ReturnType<typeof useCandidateApplicationStagesLazyQuery>;
export type CandidateApplicationStagesQueryResult = Apollo.QueryResult<CandidateApplicationStagesQuery, CandidateApplicationStagesQueryVariables>;
export const CandidateAndAtsDetailsDocument = gql`
    query CandidateAndAtsDetails($id: uuid!) {
  candidate(id: $id) {
    id
    fullName
    atsCandidate {
      ...CandidateHeader_atsCandidate
    }
  }
}
    ${CandidateHeader_AtsCandidateFragmentDoc}`;

/**
 * __useCandidateAndAtsDetailsQuery__
 *
 * To run a query within a React component, call `useCandidateAndAtsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateAndAtsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateAndAtsDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateAndAtsDetailsQuery(baseOptions: Apollo.QueryHookOptions<CandidateAndAtsDetailsQuery, CandidateAndAtsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateAndAtsDetailsQuery, CandidateAndAtsDetailsQueryVariables>(CandidateAndAtsDetailsDocument, options);
      }
export function useCandidateAndAtsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateAndAtsDetailsQuery, CandidateAndAtsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateAndAtsDetailsQuery, CandidateAndAtsDetailsQueryVariables>(CandidateAndAtsDetailsDocument, options);
        }
export type CandidateAndAtsDetailsQueryHookResult = ReturnType<typeof useCandidateAndAtsDetailsQuery>;
export type CandidateAndAtsDetailsLazyQueryHookResult = ReturnType<typeof useCandidateAndAtsDetailsLazyQuery>;
export type CandidateAndAtsDetailsQueryResult = Apollo.QueryResult<CandidateAndAtsDetailsQuery, CandidateAndAtsDetailsQueryVariables>;
export const ApplicationTaskDocument = gql`
    query ApplicationTask($applicationId: uuid!) {
  application(id: $applicationId) {
    isTaskEnabled
  }
}
    `;

/**
 * __useApplicationTaskQuery__
 *
 * To run a query within a React component, call `useApplicationTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationTaskQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useApplicationTaskQuery(baseOptions: Apollo.QueryHookOptions<ApplicationTaskQuery, ApplicationTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationTaskQuery, ApplicationTaskQueryVariables>(ApplicationTaskDocument, options);
      }
export function useApplicationTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationTaskQuery, ApplicationTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationTaskQuery, ApplicationTaskQueryVariables>(ApplicationTaskDocument, options);
        }
export type ApplicationTaskQueryHookResult = ReturnType<typeof useApplicationTaskQuery>;
export type ApplicationTaskLazyQueryHookResult = ReturnType<typeof useApplicationTaskLazyQuery>;
export type ApplicationTaskQueryResult = Apollo.QueryResult<ApplicationTaskQuery, ApplicationTaskQueryVariables>;
export const CandidateDetailsDocument = gql`
    query CandidateDetails($id: uuid!) {
  candidate(id: $id) {
    id
    fullName
    lastActivityAt
    email
    coordinatorId
    recruiterId
    familyName
    givenName
    atsId
    atsType
    atsCandidate {
      atsId
      atsUrl
      ...CandidateHeader_atsCandidate
    }
    linkedinUrl
    phoneNumbers
    pronoun
    recruiterId
    timezone
    externalId
    updatedAt
    additionalEmails
    recruiter {
      id
      fullName
      slackImageUrl
      email
      isDisabled
      timezone
      atsId
      atsUser {
        id
        atsId
      }
    }
    coordinator {
      id
      fullName
      slackImageUrl
      email
      isDisabled
      timezone
      atsId
      atsUser {
        id
        atsId
      }
    }
    applications {
      id
      existingConversations(input: {isCancelled: false}) {
        id
        remoteId
        remoteName
        isArchived
      }
      job {
        id
        name
        atsId
        atsType
        location
        jobStagesAggregate
        sourcers {
          id
          fullName
          slackImageUrl
        }
        hiringManagers {
          id
          fullName
          slackImageUrl
        }
      }
      jobStage {
        id
        name
        jobId
        schedulable
        atsId
        index
      }
      applicationStages(input: {orderBy: {createdAt: DESC}, isCancelled: false}) {
        id
        startAt
        endAt
        createdAt
        updatedAt
        name
        timezone
        selfScheduleOptionsId
        jobStageId
        creator {
          id
          fullName
        }
        jobStage {
          id
          name
          index
        }
        isHoldForRsvp
        isReadyForCandidateComms
        hasCandidateTentative
        hasInterviewerTentative
        isCancelled
        hasCandidateDeclined
        hasInterviewerDeclined
        applicationStageCandidateEvents(input: {orderBy: {createdAt: DESC}}) {
          id
          startAt
          endAt
          responseStatus
          googleEventUrl
          isCancelled
          name
          googleEventId
          googleCalendarId
        }
        applicationStageInterviews(input: {orderBy: {startAt: ASC}, isCancelled: false}) {
          id
          startAt
          endAt
          name
          calendarEventTitle
          isCancelled
          googleEventId
          googleEventUrl
          googleCalendarId
          applicationStageInterviewInterviewers {
            role
            isOptional
            interviewerId
            responseStatus
            applicationStageInterviewId
            interviewer {
              id
              slackImageUrl
              fullName
            }
          }
          applicationStageInterviewRooms {
            applicationStageInterviewId
            meetingRoomId
            responseStatus
            meetingRoom {
              id
              name
            }
          }
        }
        selfScheduleOptionsId
      }
      applicationDebriefs(input: {orderBy: {createdAt: DESC}, isCancelled: false}) {
        id
        startAt
        endAt
        isCancelled
        isPrivateCalendarEvent
        attendees {
          interviewerId
          responseStatus
          interviewer {
            id
            fullName
            email
            slackImageUrl
          }
          isOptional
        }
        meetingRooms {
          applicationDebriefId
          meetingRoomId
          responseStatus
        }
      }
      status
      jobStageCount
      state {
        isSchedulable
        isNotScheduleable
        isUnscheduled
        isAvailabilityRequested
        isReadyToSchedule
        isCanceled
        isOngoing
        isScheduled
        isCompleted
        isScheduledInAts
        isCompletedInAts
        isCandidateDeclined
        isInterviewerDeclined
        isSelfScheduleRequested
        isHoldForRsvp
        isReadyForCandidateComms
      }
      upcomingApplicationStage {
        id
        startAt
        endAt
        selfScheduleOptionsId
        minStartAt
        applicationStageInterviews(input: {orderBy: {startAt: ASC}, isCancelled: false}) {
          id
          startAt
          endAt
        }
      }
      ...ApplicationNote_application
    }
  }
}
    ${CandidateHeader_AtsCandidateFragmentDoc}
${ApplicationNote_ApplicationFragmentDoc}`;

/**
 * __useCandidateDetailsQuery__
 *
 * To run a query within a React component, call `useCandidateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateDetailsQuery(baseOptions: Apollo.QueryHookOptions<CandidateDetailsQuery, CandidateDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateDetailsQuery, CandidateDetailsQueryVariables>(CandidateDetailsDocument, options);
      }
export function useCandidateDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateDetailsQuery, CandidateDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateDetailsQuery, CandidateDetailsQueryVariables>(CandidateDetailsDocument, options);
        }
export type CandidateDetailsQueryHookResult = ReturnType<typeof useCandidateDetailsQuery>;
export type CandidateDetailsLazyQueryHookResult = ReturnType<typeof useCandidateDetailsLazyQuery>;
export type CandidateDetailsQueryResult = Apollo.QueryResult<CandidateDetailsQuery, CandidateDetailsQueryVariables>;
export const JobStageDetailsDocument = gql`
    query JobStageDetails($input: JobStagesInput!) {
  jobStages(input: $input) {
    items {
      ...JobStageFragment
    }
  }
}
    ${JobStageFragmentFragmentDoc}`;

/**
 * __useJobStageDetailsQuery__
 *
 * To run a query within a React component, call `useJobStageDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobStageDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobStageDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobStageDetailsQuery(baseOptions: Apollo.QueryHookOptions<JobStageDetailsQuery, JobStageDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobStageDetailsQuery, JobStageDetailsQueryVariables>(JobStageDetailsDocument, options);
      }
export function useJobStageDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobStageDetailsQuery, JobStageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobStageDetailsQuery, JobStageDetailsQueryVariables>(JobStageDetailsDocument, options);
        }
export type JobStageDetailsQueryHookResult = ReturnType<typeof useJobStageDetailsQuery>;
export type JobStageDetailsLazyQueryHookResult = ReturnType<typeof useJobStageDetailsLazyQuery>;
export type JobStageDetailsQueryResult = Apollo.QueryResult<JobStageDetailsQuery, JobStageDetailsQueryVariables>;
export const JobStageUpdatedAtDocument = gql`
    query JobStageUpdatedAt($id: uuid!) {
  jobStage(id: $id) {
    id
    updatedAt
  }
}
    `;

/**
 * __useJobStageUpdatedAtQuery__
 *
 * To run a query within a React component, call `useJobStageUpdatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobStageUpdatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobStageUpdatedAtQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobStageUpdatedAtQuery(baseOptions: Apollo.QueryHookOptions<JobStageUpdatedAtQuery, JobStageUpdatedAtQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobStageUpdatedAtQuery, JobStageUpdatedAtQueryVariables>(JobStageUpdatedAtDocument, options);
      }
export function useJobStageUpdatedAtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobStageUpdatedAtQuery, JobStageUpdatedAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobStageUpdatedAtQuery, JobStageUpdatedAtQueryVariables>(JobStageUpdatedAtDocument, options);
        }
export type JobStageUpdatedAtQueryHookResult = ReturnType<typeof useJobStageUpdatedAtQuery>;
export type JobStageUpdatedAtLazyQueryHookResult = ReturnType<typeof useJobStageUpdatedAtLazyQuery>;
export type JobStageUpdatedAtQueryResult = Apollo.QueryResult<JobStageUpdatedAtQuery, JobStageUpdatedAtQueryVariables>;
export const ConfidentialJobByJobStageDocument = gql`
    query ConfidentialJobByJobStage($id: uuid!) {
  jobStage(id: $id) {
    job {
      isConfidential
      id
    }
  }
}
    `;

/**
 * __useConfidentialJobByJobStageQuery__
 *
 * To run a query within a React component, call `useConfidentialJobByJobStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfidentialJobByJobStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfidentialJobByJobStageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfidentialJobByJobStageQuery(baseOptions: Apollo.QueryHookOptions<ConfidentialJobByJobStageQuery, ConfidentialJobByJobStageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfidentialJobByJobStageQuery, ConfidentialJobByJobStageQueryVariables>(ConfidentialJobByJobStageDocument, options);
      }
export function useConfidentialJobByJobStageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfidentialJobByJobStageQuery, ConfidentialJobByJobStageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfidentialJobByJobStageQuery, ConfidentialJobByJobStageQueryVariables>(ConfidentialJobByJobStageDocument, options);
        }
export type ConfidentialJobByJobStageQueryHookResult = ReturnType<typeof useConfidentialJobByJobStageQuery>;
export type ConfidentialJobByJobStageLazyQueryHookResult = ReturnType<typeof useConfidentialJobByJobStageLazyQuery>;
export type ConfidentialJobByJobStageQueryResult = Apollo.QueryResult<ConfidentialJobByJobStageQuery, ConfidentialJobByJobStageQueryVariables>;
export const ConfidentialJobByApplicationDocument = gql`
    query ConfidentialJobByApplication($id: uuid!) {
  application(id: $id) {
    job {
      isConfidential
      id
    }
  }
}
    `;

/**
 * __useConfidentialJobByApplicationQuery__
 *
 * To run a query within a React component, call `useConfidentialJobByApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfidentialJobByApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfidentialJobByApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfidentialJobByApplicationQuery(baseOptions: Apollo.QueryHookOptions<ConfidentialJobByApplicationQuery, ConfidentialJobByApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfidentialJobByApplicationQuery, ConfidentialJobByApplicationQueryVariables>(ConfidentialJobByApplicationDocument, options);
      }
export function useConfidentialJobByApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfidentialJobByApplicationQuery, ConfidentialJobByApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfidentialJobByApplicationQuery, ConfidentialJobByApplicationQueryVariables>(ConfidentialJobByApplicationDocument, options);
        }
export type ConfidentialJobByApplicationQueryHookResult = ReturnType<typeof useConfidentialJobByApplicationQuery>;
export type ConfidentialJobByApplicationLazyQueryHookResult = ReturnType<typeof useConfidentialJobByApplicationLazyQuery>;
export type ConfidentialJobByApplicationQueryResult = Apollo.QueryResult<ConfidentialJobByApplicationQuery, ConfidentialJobByApplicationQueryVariables>;
export const CandidateListDocument = gql`
    query CandidateList($pageInput: PageInput!, $candidateName: String, $jobIds: [uuid], $jobStageNames: [String], $hiringTeamMemberIds: [uuid], $coordinatorIds: [uuid], $recruiterIds: [uuid], $showDeclines: Boolean, $orderBy: ApplicationSearchOrderBy, $statusFilterGroups: [StatusFilterGroup], $applicationStatuses: [ApplicationStatus!]) {
  applicationSearch(
    input: {pageInput: $pageInput, candidateName: $candidateName, jobIds: $jobIds, jobStageNames: $jobStageNames, hiringTeamMemberIds: $hiringTeamMemberIds, coordinatorIds: $coordinatorIds, recruiterIds: $recruiterIds, showDeclines: $showDeclines, orderBy: $orderBy, statusFilterGroups: $statusFilterGroups, applicationStatuses: $applicationStatuses}
  ) {
    total
    items {
      ...ApplicationItem
    }
  }
}
    ${ApplicationItemFragmentDoc}`;

/**
 * __useCandidateListQuery__
 *
 * To run a query within a React component, call `useCandidateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateListQuery({
 *   variables: {
 *      pageInput: // value for 'pageInput'
 *      candidateName: // value for 'candidateName'
 *      jobIds: // value for 'jobIds'
 *      jobStageNames: // value for 'jobStageNames'
 *      hiringTeamMemberIds: // value for 'hiringTeamMemberIds'
 *      coordinatorIds: // value for 'coordinatorIds'
 *      recruiterIds: // value for 'recruiterIds'
 *      showDeclines: // value for 'showDeclines'
 *      orderBy: // value for 'orderBy'
 *      statusFilterGroups: // value for 'statusFilterGroups'
 *      applicationStatuses: // value for 'applicationStatuses'
 *   },
 * });
 */
export function useCandidateListQuery(baseOptions: Apollo.QueryHookOptions<CandidateListQuery, CandidateListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateListQuery, CandidateListQueryVariables>(CandidateListDocument, options);
      }
export function useCandidateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateListQuery, CandidateListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateListQuery, CandidateListQueryVariables>(CandidateListDocument, options);
        }
export type CandidateListQueryHookResult = ReturnType<typeof useCandidateListQuery>;
export type CandidateListLazyQueryHookResult = ReturnType<typeof useCandidateListLazyQuery>;
export type CandidateListQueryResult = Apollo.QueryResult<CandidateListQuery, CandidateListQueryVariables>;
export const CandidatePageFiltersEmployeePrefDocument = gql`
    query CandidatePageFiltersEmployeePref($prefName: String!) {
  thisEmployee {
    id
    employeePref(prefName: $prefName) {
      orgId
      employeeId
      prefName
      jsonPref {
        ... on CandidatePageFiltersPref {
          ...CandidatePageFiltersEmployeePref
        }
      }
    }
  }
}
    ${CandidatePageFiltersEmployeePrefFragmentDoc}`;

/**
 * __useCandidatePageFiltersEmployeePrefQuery__
 *
 * To run a query within a React component, call `useCandidatePageFiltersEmployeePrefQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatePageFiltersEmployeePrefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatePageFiltersEmployeePrefQuery({
 *   variables: {
 *      prefName: // value for 'prefName'
 *   },
 * });
 */
export function useCandidatePageFiltersEmployeePrefQuery(baseOptions: Apollo.QueryHookOptions<CandidatePageFiltersEmployeePrefQuery, CandidatePageFiltersEmployeePrefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidatePageFiltersEmployeePrefQuery, CandidatePageFiltersEmployeePrefQueryVariables>(CandidatePageFiltersEmployeePrefDocument, options);
      }
export function useCandidatePageFiltersEmployeePrefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidatePageFiltersEmployeePrefQuery, CandidatePageFiltersEmployeePrefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidatePageFiltersEmployeePrefQuery, CandidatePageFiltersEmployeePrefQueryVariables>(CandidatePageFiltersEmployeePrefDocument, options);
        }
export type CandidatePageFiltersEmployeePrefQueryHookResult = ReturnType<typeof useCandidatePageFiltersEmployeePrefQuery>;
export type CandidatePageFiltersEmployeePrefLazyQueryHookResult = ReturnType<typeof useCandidatePageFiltersEmployeePrefLazyQuery>;
export type CandidatePageFiltersEmployeePrefQueryResult = Apollo.QueryResult<CandidatePageFiltersEmployeePrefQuery, CandidatePageFiltersEmployeePrefQueryVariables>;
export const UpdateCandidatePageFiltersEmployeePrefDocument = gql`
    mutation updateCandidatePageFiltersEmployeePref($input: EmployeePrefUpdateInput!) {
  employeePrefUpdate(input: $input) {
    employeePref {
      orgId
      employeeId
      prefName
    }
  }
}
    `;
export type UpdateCandidatePageFiltersEmployeePrefMutationFn = Apollo.MutationFunction<UpdateCandidatePageFiltersEmployeePrefMutation, UpdateCandidatePageFiltersEmployeePrefMutationVariables>;

/**
 * __useUpdateCandidatePageFiltersEmployeePrefMutation__
 *
 * To run a mutation, you first call `useUpdateCandidatePageFiltersEmployeePrefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidatePageFiltersEmployeePrefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidatePageFiltersEmployeePrefMutation, { data, loading, error }] = useUpdateCandidatePageFiltersEmployeePrefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCandidatePageFiltersEmployeePrefMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCandidatePageFiltersEmployeePrefMutation, UpdateCandidatePageFiltersEmployeePrefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCandidatePageFiltersEmployeePrefMutation, UpdateCandidatePageFiltersEmployeePrefMutationVariables>(UpdateCandidatePageFiltersEmployeePrefDocument, options);
      }
export type UpdateCandidatePageFiltersEmployeePrefMutationHookResult = ReturnType<typeof useUpdateCandidatePageFiltersEmployeePrefMutation>;
export type UpdateCandidatePageFiltersEmployeePrefMutationResult = Apollo.MutationResult<UpdateCandidatePageFiltersEmployeePrefMutation>;
export type UpdateCandidatePageFiltersEmployeePrefMutationOptions = Apollo.BaseMutationOptions<UpdateCandidatePageFiltersEmployeePrefMutation, UpdateCandidatePageFiltersEmployeePrefMutationVariables>;
export const EmployeeDataBulkUpsertDocument = gql`
    mutation employeeDataBulkUpsert($name: String!, $path: String!, $dataType: EmployeeBulkUploadDataType!) {
  employeeDataBulkUpsert(input: {name: $name, path: $path, dataType: $dataType}) {
    result
  }
}
    `;
export type EmployeeDataBulkUpsertMutationFn = Apollo.MutationFunction<EmployeeDataBulkUpsertMutation, EmployeeDataBulkUpsertMutationVariables>;

/**
 * __useEmployeeDataBulkUpsertMutation__
 *
 * To run a mutation, you first call `useEmployeeDataBulkUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDataBulkUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeDataBulkUpsertMutation, { data, loading, error }] = useEmployeeDataBulkUpsertMutation({
 *   variables: {
 *      name: // value for 'name'
 *      path: // value for 'path'
 *      dataType: // value for 'dataType'
 *   },
 * });
 */
export function useEmployeeDataBulkUpsertMutation(baseOptions?: Apollo.MutationHookOptions<EmployeeDataBulkUpsertMutation, EmployeeDataBulkUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmployeeDataBulkUpsertMutation, EmployeeDataBulkUpsertMutationVariables>(EmployeeDataBulkUpsertDocument, options);
      }
export type EmployeeDataBulkUpsertMutationHookResult = ReturnType<typeof useEmployeeDataBulkUpsertMutation>;
export type EmployeeDataBulkUpsertMutationResult = Apollo.MutationResult<EmployeeDataBulkUpsertMutation>;
export type EmployeeDataBulkUpsertMutationOptions = Apollo.BaseMutationOptions<EmployeeDataBulkUpsertMutation, EmployeeDataBulkUpsertMutationVariables>;
export const CalendarPermissionsDocument = gql`
    query CalendarPermissions($applicationStageId: uuid!) {
  applicationStage(id: $applicationStageId) {
    id
    application {
      id
      candidateId
    }
    creator {
      id
      orgId
      email
      fullName
      slackImageUrl
    }
    applicationStageCandidateEvents {
      googleCalendarId
      startAt
      endAt
      name
      googleEventId
      calendar {
        name
        isPartOfCalendarList
        isReadOnly
      }
    }
    applicationStageInterviews {
      calendarEventTitle
      googleCalendarId
      id
      name
      startAt
      endAt
      calendar {
        id
        name
        remoteId
        employeeId
        isPartOfCalendarList
        isReadOnly
      }
    }
  }
}
    `;

/**
 * __useCalendarPermissionsQuery__
 *
 * To run a query within a React component, call `useCalendarPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarPermissionsQuery({
 *   variables: {
 *      applicationStageId: // value for 'applicationStageId'
 *   },
 * });
 */
export function useCalendarPermissionsQuery(baseOptions: Apollo.QueryHookOptions<CalendarPermissionsQuery, CalendarPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarPermissionsQuery, CalendarPermissionsQueryVariables>(CalendarPermissionsDocument, options);
      }
export function useCalendarPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarPermissionsQuery, CalendarPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarPermissionsQuery, CalendarPermissionsQueryVariables>(CalendarPermissionsDocument, options);
        }
export type CalendarPermissionsQueryHookResult = ReturnType<typeof useCalendarPermissionsQuery>;
export type CalendarPermissionsLazyQueryHookResult = ReturnType<typeof useCalendarPermissionsLazyQuery>;
export type CalendarPermissionsQueryResult = Apollo.QueryResult<CalendarPermissionsQuery, CalendarPermissionsQueryVariables>;
export const SlackFeedbackNagDocument = gql`
    mutation SlackFeedbackNag($input: TrainingFeedbackReminderNagInput!) {
  trainingFeedbackReminderNag(input: $input) {
    interviewModuleMember {
      interviewModuleId
      employeeId
    }
  }
}
    `;
export type SlackFeedbackNagMutationFn = Apollo.MutationFunction<SlackFeedbackNagMutation, SlackFeedbackNagMutationVariables>;

/**
 * __useSlackFeedbackNagMutation__
 *
 * To run a mutation, you first call `useSlackFeedbackNagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSlackFeedbackNagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [slackFeedbackNagMutation, { data, loading, error }] = useSlackFeedbackNagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSlackFeedbackNagMutation(baseOptions?: Apollo.MutationHookOptions<SlackFeedbackNagMutation, SlackFeedbackNagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SlackFeedbackNagMutation, SlackFeedbackNagMutationVariables>(SlackFeedbackNagDocument, options);
      }
export type SlackFeedbackNagMutationHookResult = ReturnType<typeof useSlackFeedbackNagMutation>;
export type SlackFeedbackNagMutationResult = Apollo.MutationResult<SlackFeedbackNagMutation>;
export type SlackFeedbackNagMutationOptions = Apollo.BaseMutationOptions<SlackFeedbackNagMutation, SlackFeedbackNagMutationVariables>;
export const InterviewModuleMemberLogCreateDocument = gql`
    mutation InterviewModuleMemberLogCreate($input: InterviewModuleMemberLogCreateInput!) {
  interviewModuleMemberLogCreate(input: $input) {
    interviewModuleMemberManualLog {
      id
    }
  }
}
    `;
export type InterviewModuleMemberLogCreateMutationFn = Apollo.MutationFunction<InterviewModuleMemberLogCreateMutation, InterviewModuleMemberLogCreateMutationVariables>;

/**
 * __useInterviewModuleMemberLogCreateMutation__
 *
 * To run a mutation, you first call `useInterviewModuleMemberLogCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberLogCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleMemberLogCreateMutation, { data, loading, error }] = useInterviewModuleMemberLogCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleMemberLogCreateMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleMemberLogCreateMutation, InterviewModuleMemberLogCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleMemberLogCreateMutation, InterviewModuleMemberLogCreateMutationVariables>(InterviewModuleMemberLogCreateDocument, options);
      }
export type InterviewModuleMemberLogCreateMutationHookResult = ReturnType<typeof useInterviewModuleMemberLogCreateMutation>;
export type InterviewModuleMemberLogCreateMutationResult = Apollo.MutationResult<InterviewModuleMemberLogCreateMutation>;
export type InterviewModuleMemberLogCreateMutationOptions = Apollo.BaseMutationOptions<InterviewModuleMemberLogCreateMutation, InterviewModuleMemberLogCreateMutationVariables>;
export const InterviewModuleMemberLogDeleteDocument = gql`
    mutation InterviewModuleMemberLogDelete($input: InterviewModuleMemberLogDeleteInput!) {
  interviewModuleMemberLogDelete(input: $input) {
    result
  }
}
    `;
export type InterviewModuleMemberLogDeleteMutationFn = Apollo.MutationFunction<InterviewModuleMemberLogDeleteMutation, InterviewModuleMemberLogDeleteMutationVariables>;

/**
 * __useInterviewModuleMemberLogDeleteMutation__
 *
 * To run a mutation, you first call `useInterviewModuleMemberLogDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberLogDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleMemberLogDeleteMutation, { data, loading, error }] = useInterviewModuleMemberLogDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleMemberLogDeleteMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleMemberLogDeleteMutation, InterviewModuleMemberLogDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleMemberLogDeleteMutation, InterviewModuleMemberLogDeleteMutationVariables>(InterviewModuleMemberLogDeleteDocument, options);
      }
export type InterviewModuleMemberLogDeleteMutationHookResult = ReturnType<typeof useInterviewModuleMemberLogDeleteMutation>;
export type InterviewModuleMemberLogDeleteMutationResult = Apollo.MutationResult<InterviewModuleMemberLogDeleteMutation>;
export type InterviewModuleMemberLogDeleteMutationOptions = Apollo.BaseMutationOptions<InterviewModuleMemberLogDeleteMutation, InterviewModuleMemberLogDeleteMutationVariables>;
export const PauseMemberModalInterviewModuleMemberUpdateDocument = gql`
    mutation PauseMemberModalInterviewModuleMemberUpdate($input: InterviewModuleMemberUpdateInput!) {
  interviewModuleMemberUpdate(input: $input) {
    interviewModuleMember {
      ...pauseMemberModal_moduleMember
    }
  }
}
    ${PauseMemberModal_ModuleMemberFragmentDoc}`;
export type PauseMemberModalInterviewModuleMemberUpdateMutationFn = Apollo.MutationFunction<PauseMemberModalInterviewModuleMemberUpdateMutation, PauseMemberModalInterviewModuleMemberUpdateMutationVariables>;

/**
 * __usePauseMemberModalInterviewModuleMemberUpdateMutation__
 *
 * To run a mutation, you first call `usePauseMemberModalInterviewModuleMemberUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseMemberModalInterviewModuleMemberUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseMemberModalInterviewModuleMemberUpdateMutation, { data, loading, error }] = usePauseMemberModalInterviewModuleMemberUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePauseMemberModalInterviewModuleMemberUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PauseMemberModalInterviewModuleMemberUpdateMutation, PauseMemberModalInterviewModuleMemberUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PauseMemberModalInterviewModuleMemberUpdateMutation, PauseMemberModalInterviewModuleMemberUpdateMutationVariables>(PauseMemberModalInterviewModuleMemberUpdateDocument, options);
      }
export type PauseMemberModalInterviewModuleMemberUpdateMutationHookResult = ReturnType<typeof usePauseMemberModalInterviewModuleMemberUpdateMutation>;
export type PauseMemberModalInterviewModuleMemberUpdateMutationResult = Apollo.MutationResult<PauseMemberModalInterviewModuleMemberUpdateMutation>;
export type PauseMemberModalInterviewModuleMemberUpdateMutationOptions = Apollo.BaseMutationOptions<PauseMemberModalInterviewModuleMemberUpdateMutation, PauseMemberModalInterviewModuleMemberUpdateMutationVariables>;
export const InterviewModuleDeleteMemberDocument = gql`
    mutation InterviewModuleDeleteMember($input: InterviewModuleRemoveMembersInput!) {
  interviewModuleRemoveMembers(input: $input) {
    interviewModule {
      id
    }
  }
}
    `;
export type InterviewModuleDeleteMemberMutationFn = Apollo.MutationFunction<InterviewModuleDeleteMemberMutation, InterviewModuleDeleteMemberMutationVariables>;

/**
 * __useInterviewModuleDeleteMemberMutation__
 *
 * To run a mutation, you first call `useInterviewModuleDeleteMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleDeleteMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleDeleteMemberMutation, { data, loading, error }] = useInterviewModuleDeleteMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleDeleteMemberMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleDeleteMemberMutation, InterviewModuleDeleteMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleDeleteMemberMutation, InterviewModuleDeleteMemberMutationVariables>(InterviewModuleDeleteMemberDocument, options);
      }
export type InterviewModuleDeleteMemberMutationHookResult = ReturnType<typeof useInterviewModuleDeleteMemberMutation>;
export type InterviewModuleDeleteMemberMutationResult = Apollo.MutationResult<InterviewModuleDeleteMemberMutation>;
export type InterviewModuleDeleteMemberMutationOptions = Apollo.BaseMutationOptions<InterviewModuleDeleteMemberMutation, InterviewModuleDeleteMemberMutationVariables>;
export const InterviewModuleMemberLogUpdateDocument = gql`
    mutation InterviewModuleMemberLogUpdate($input: InterviewModuleMemberLogUpdateInput!) {
  interviewModuleMemberLogUpdate(input: $input) {
    interviewModuleMemberManualLog {
      id
    }
  }
}
    `;
export type InterviewModuleMemberLogUpdateMutationFn = Apollo.MutationFunction<InterviewModuleMemberLogUpdateMutation, InterviewModuleMemberLogUpdateMutationVariables>;

/**
 * __useInterviewModuleMemberLogUpdateMutation__
 *
 * To run a mutation, you first call `useInterviewModuleMemberLogUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberLogUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleMemberLogUpdateMutation, { data, loading, error }] = useInterviewModuleMemberLogUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleMemberLogUpdateMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleMemberLogUpdateMutation, InterviewModuleMemberLogUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleMemberLogUpdateMutation, InterviewModuleMemberLogUpdateMutationVariables>(InterviewModuleMemberLogUpdateDocument, options);
      }
export type InterviewModuleMemberLogUpdateMutationHookResult = ReturnType<typeof useInterviewModuleMemberLogUpdateMutation>;
export type InterviewModuleMemberLogUpdateMutationResult = Apollo.MutationResult<InterviewModuleMemberLogUpdateMutation>;
export type InterviewModuleMemberLogUpdateMutationOptions = Apollo.BaseMutationOptions<InterviewModuleMemberLogUpdateMutation, InterviewModuleMemberLogUpdateMutationVariables>;
export const ApplicationStageInterviewInterviewerUpsertDocument = gql`
    mutation ApplicationStageInterviewInterviewerUpsert($input: ApplicationStageInterviewInterviewerUpsertInput!) {
  applicationStageInterviewInterviewerUpsert(input: $input) {
    result
  }
}
    `;
export type ApplicationStageInterviewInterviewerUpsertMutationFn = Apollo.MutationFunction<ApplicationStageInterviewInterviewerUpsertMutation, ApplicationStageInterviewInterviewerUpsertMutationVariables>;

/**
 * __useApplicationStageInterviewInterviewerUpsertMutation__
 *
 * To run a mutation, you first call `useApplicationStageInterviewInterviewerUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplicationStageInterviewInterviewerUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applicationStageInterviewInterviewerUpsertMutation, { data, loading, error }] = useApplicationStageInterviewInterviewerUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplicationStageInterviewInterviewerUpsertMutation(baseOptions?: Apollo.MutationHookOptions<ApplicationStageInterviewInterviewerUpsertMutation, ApplicationStageInterviewInterviewerUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplicationStageInterviewInterviewerUpsertMutation, ApplicationStageInterviewInterviewerUpsertMutationVariables>(ApplicationStageInterviewInterviewerUpsertDocument, options);
      }
export type ApplicationStageInterviewInterviewerUpsertMutationHookResult = ReturnType<typeof useApplicationStageInterviewInterviewerUpsertMutation>;
export type ApplicationStageInterviewInterviewerUpsertMutationResult = Apollo.MutationResult<ApplicationStageInterviewInterviewerUpsertMutation>;
export type ApplicationStageInterviewInterviewerUpsertMutationOptions = Apollo.BaseMutationOptions<ApplicationStageInterviewInterviewerUpsertMutation, ApplicationStageInterviewInterviewerUpsertMutationVariables>;
export const ModuleLoadPauseRowInterviewModuleMemberUpdateDocument = gql`
    mutation ModuleLoadPauseRowInterviewModuleMemberUpdate($input: InterviewModuleMemberUpdateInput!) {
  interviewModuleMemberUpdate(input: $input) {
    interviewModuleMember {
      ...moduleLoadPauseRow_moduleMember
    }
  }
}
    ${ModuleLoadPauseRow_ModuleMemberFragmentDoc}`;
export type ModuleLoadPauseRowInterviewModuleMemberUpdateMutationFn = Apollo.MutationFunction<ModuleLoadPauseRowInterviewModuleMemberUpdateMutation, ModuleLoadPauseRowInterviewModuleMemberUpdateMutationVariables>;

/**
 * __useModuleLoadPauseRowInterviewModuleMemberUpdateMutation__
 *
 * To run a mutation, you first call `useModuleLoadPauseRowInterviewModuleMemberUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModuleLoadPauseRowInterviewModuleMemberUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moduleLoadPauseRowInterviewModuleMemberUpdateMutation, { data, loading, error }] = useModuleLoadPauseRowInterviewModuleMemberUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModuleLoadPauseRowInterviewModuleMemberUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ModuleLoadPauseRowInterviewModuleMemberUpdateMutation, ModuleLoadPauseRowInterviewModuleMemberUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModuleLoadPauseRowInterviewModuleMemberUpdateMutation, ModuleLoadPauseRowInterviewModuleMemberUpdateMutationVariables>(ModuleLoadPauseRowInterviewModuleMemberUpdateDocument, options);
      }
export type ModuleLoadPauseRowInterviewModuleMemberUpdateMutationHookResult = ReturnType<typeof useModuleLoadPauseRowInterviewModuleMemberUpdateMutation>;
export type ModuleLoadPauseRowInterviewModuleMemberUpdateMutationResult = Apollo.MutationResult<ModuleLoadPauseRowInterviewModuleMemberUpdateMutation>;
export type ModuleLoadPauseRowInterviewModuleMemberUpdateMutationOptions = Apollo.BaseMutationOptions<ModuleLoadPauseRowInterviewModuleMemberUpdateMutation, ModuleLoadPauseRowInterviewModuleMemberUpdateMutationVariables>;
export const ModuleLoadInterviewModuleMemberUpdateDocument = gql`
    mutation ModuleLoadInterviewModuleMemberUpdate($input: InterviewModuleMemberUpdateInput!) {
  interviewModuleMemberUpdate(input: $input) {
    interviewModuleMember {
      ...moduleLoad_moduleMember
    }
  }
}
    ${ModuleLoad_ModuleMemberFragmentDoc}`;
export type ModuleLoadInterviewModuleMemberUpdateMutationFn = Apollo.MutationFunction<ModuleLoadInterviewModuleMemberUpdateMutation, ModuleLoadInterviewModuleMemberUpdateMutationVariables>;

/**
 * __useModuleLoadInterviewModuleMemberUpdateMutation__
 *
 * To run a mutation, you first call `useModuleLoadInterviewModuleMemberUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModuleLoadInterviewModuleMemberUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moduleLoadInterviewModuleMemberUpdateMutation, { data, loading, error }] = useModuleLoadInterviewModuleMemberUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModuleLoadInterviewModuleMemberUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ModuleLoadInterviewModuleMemberUpdateMutation, ModuleLoadInterviewModuleMemberUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModuleLoadInterviewModuleMemberUpdateMutation, ModuleLoadInterviewModuleMemberUpdateMutationVariables>(ModuleLoadInterviewModuleMemberUpdateDocument, options);
      }
export type ModuleLoadInterviewModuleMemberUpdateMutationHookResult = ReturnType<typeof useModuleLoadInterviewModuleMemberUpdateMutation>;
export type ModuleLoadInterviewModuleMemberUpdateMutationResult = Apollo.MutationResult<ModuleLoadInterviewModuleMemberUpdateMutation>;
export type ModuleLoadInterviewModuleMemberUpdateMutationOptions = Apollo.BaseMutationOptions<ModuleLoadInterviewModuleMemberUpdateMutation, ModuleLoadInterviewModuleMemberUpdateMutationVariables>;
export const InterviewModuleMemberModuleLogSidepanelDocument = gql`
    query InterviewModuleMemberModuleLogSidepanel($interviewModuleId: uuid!, $moduleMemberId: uuid!, $moduleMemberInterviewLogsInput: ModuleMemberInterviewLogInput!) {
  interviewModuleMember(
    interviewModuleId: $interviewModuleId
    employeeId: $moduleMemberId
  ) {
    id
    interviewModuleId
    employeeId
    moduleMemberInterviewLogs(input: $moduleMemberInterviewLogsInput) {
      items {
        ...interviewModuleMemberModuleLogRow_moduleMemberInterview
      }
    }
  }
}
    ${InterviewModuleMemberModuleLogRow_ModuleMemberInterviewFragmentDoc}`;

/**
 * __useInterviewModuleMemberModuleLogSidepanelQuery__
 *
 * To run a query within a React component, call `useInterviewModuleMemberModuleLogSidepanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberModuleLogSidepanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMemberModuleLogSidepanelQuery({
 *   variables: {
 *      interviewModuleId: // value for 'interviewModuleId'
 *      moduleMemberId: // value for 'moduleMemberId'
 *      moduleMemberInterviewLogsInput: // value for 'moduleMemberInterviewLogsInput'
 *   },
 * });
 */
export function useInterviewModuleMemberModuleLogSidepanelQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleMemberModuleLogSidepanelQuery, InterviewModuleMemberModuleLogSidepanelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMemberModuleLogSidepanelQuery, InterviewModuleMemberModuleLogSidepanelQueryVariables>(InterviewModuleMemberModuleLogSidepanelDocument, options);
      }
export function useInterviewModuleMemberModuleLogSidepanelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMemberModuleLogSidepanelQuery, InterviewModuleMemberModuleLogSidepanelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMemberModuleLogSidepanelQuery, InterviewModuleMemberModuleLogSidepanelQueryVariables>(InterviewModuleMemberModuleLogSidepanelDocument, options);
        }
export type InterviewModuleMemberModuleLogSidepanelQueryHookResult = ReturnType<typeof useInterviewModuleMemberModuleLogSidepanelQuery>;
export type InterviewModuleMemberModuleLogSidepanelLazyQueryHookResult = ReturnType<typeof useInterviewModuleMemberModuleLogSidepanelLazyQuery>;
export type InterviewModuleMemberModuleLogSidepanelQueryResult = Apollo.QueryResult<InterviewModuleMemberModuleLogSidepanelQuery, InterviewModuleMemberModuleLogSidepanelQueryVariables>;
export const PendingApprovalApplicationStageInterviewInterviewerUpsertDocument = gql`
    mutation PendingApprovalApplicationStageInterviewInterviewerUpsert($input: ApplicationStageInterviewInterviewerUpsertInput!) {
  applicationStageInterviewInterviewerUpsert(input: $input) {
    result
  }
}
    `;
export type PendingApprovalApplicationStageInterviewInterviewerUpsertMutationFn = Apollo.MutationFunction<PendingApprovalApplicationStageInterviewInterviewerUpsertMutation, PendingApprovalApplicationStageInterviewInterviewerUpsertMutationVariables>;

/**
 * __usePendingApprovalApplicationStageInterviewInterviewerUpsertMutation__
 *
 * To run a mutation, you first call `usePendingApprovalApplicationStageInterviewInterviewerUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePendingApprovalApplicationStageInterviewInterviewerUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pendingApprovalApplicationStageInterviewInterviewerUpsertMutation, { data, loading, error }] = usePendingApprovalApplicationStageInterviewInterviewerUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePendingApprovalApplicationStageInterviewInterviewerUpsertMutation(baseOptions?: Apollo.MutationHookOptions<PendingApprovalApplicationStageInterviewInterviewerUpsertMutation, PendingApprovalApplicationStageInterviewInterviewerUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PendingApprovalApplicationStageInterviewInterviewerUpsertMutation, PendingApprovalApplicationStageInterviewInterviewerUpsertMutationVariables>(PendingApprovalApplicationStageInterviewInterviewerUpsertDocument, options);
      }
export type PendingApprovalApplicationStageInterviewInterviewerUpsertMutationHookResult = ReturnType<typeof usePendingApprovalApplicationStageInterviewInterviewerUpsertMutation>;
export type PendingApprovalApplicationStageInterviewInterviewerUpsertMutationResult = Apollo.MutationResult<PendingApprovalApplicationStageInterviewInterviewerUpsertMutation>;
export type PendingApprovalApplicationStageInterviewInterviewerUpsertMutationOptions = Apollo.BaseMutationOptions<PendingApprovalApplicationStageInterviewInterviewerUpsertMutation, PendingApprovalApplicationStageInterviewInterviewerUpsertMutationVariables>;
export const InterviewModuleMemberSidepanelQueryDocument = gql`
    query InterviewModuleMemberSidepanelQuery($interviewModuleId: uuid!, $moduleMemberId: uuid!) {
  interviewModuleMember(
    interviewModuleId: $interviewModuleId
    employeeId: $moduleMemberId
  ) {
    ...ModuleMemberSidePanel
    ...InterviewModuleMemberModuleLog_moduleMember
  }
}
    ${ModuleMemberSidePanelFragmentDoc}
${InterviewModuleMemberModuleLog_ModuleMemberFragmentDoc}`;

/**
 * __useInterviewModuleMemberSidepanelQueryQuery__
 *
 * To run a query within a React component, call `useInterviewModuleMemberSidepanelQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberSidepanelQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMemberSidepanelQueryQuery({
 *   variables: {
 *      interviewModuleId: // value for 'interviewModuleId'
 *      moduleMemberId: // value for 'moduleMemberId'
 *   },
 * });
 */
export function useInterviewModuleMemberSidepanelQueryQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleMemberSidepanelQueryQuery, InterviewModuleMemberSidepanelQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMemberSidepanelQueryQuery, InterviewModuleMemberSidepanelQueryQueryVariables>(InterviewModuleMemberSidepanelQueryDocument, options);
      }
export function useInterviewModuleMemberSidepanelQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMemberSidepanelQueryQuery, InterviewModuleMemberSidepanelQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMemberSidepanelQueryQuery, InterviewModuleMemberSidepanelQueryQueryVariables>(InterviewModuleMemberSidepanelQueryDocument, options);
        }
export type InterviewModuleMemberSidepanelQueryQueryHookResult = ReturnType<typeof useInterviewModuleMemberSidepanelQueryQuery>;
export type InterviewModuleMemberSidepanelQueryLazyQueryHookResult = ReturnType<typeof useInterviewModuleMemberSidepanelQueryLazyQuery>;
export type InterviewModuleMemberSidepanelQueryQueryResult = Apollo.QueryResult<InterviewModuleMemberSidepanelQueryQuery, InterviewModuleMemberSidepanelQueryQueryVariables>;
export const ModuleMemberUpdateDocument = gql`
    mutation ModuleMemberUpdate($input: InterviewModuleMemberUpdateInput!) {
  interviewModuleMemberUpdate(input: $input) {
    interviewModuleMember {
      ...ModuleMemberSidePanel
    }
  }
}
    ${ModuleMemberSidePanelFragmentDoc}`;
export type ModuleMemberUpdateMutationFn = Apollo.MutationFunction<ModuleMemberUpdateMutation, ModuleMemberUpdateMutationVariables>;

/**
 * __useModuleMemberUpdateMutation__
 *
 * To run a mutation, you first call `useModuleMemberUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModuleMemberUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moduleMemberUpdateMutation, { data, loading, error }] = useModuleMemberUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModuleMemberUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ModuleMemberUpdateMutation, ModuleMemberUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModuleMemberUpdateMutation, ModuleMemberUpdateMutationVariables>(ModuleMemberUpdateDocument, options);
      }
export type ModuleMemberUpdateMutationHookResult = ReturnType<typeof useModuleMemberUpdateMutation>;
export type ModuleMemberUpdateMutationResult = Apollo.MutationResult<ModuleMemberUpdateMutation>;
export type ModuleMemberUpdateMutationOptions = Apollo.BaseMutationOptions<ModuleMemberUpdateMutation, ModuleMemberUpdateMutationVariables>;
export const ModuleMemberApproveDocument = gql`
    mutation ModuleMemberApprove($input: InterviewModuleMemberApproveInput!) {
  interviewModuleMemberApprove(input: $input) {
    interviewModuleMember {
      ...ModuleMemberSidePanel
    }
  }
}
    ${ModuleMemberSidePanelFragmentDoc}`;
export type ModuleMemberApproveMutationFn = Apollo.MutationFunction<ModuleMemberApproveMutation, ModuleMemberApproveMutationVariables>;

/**
 * __useModuleMemberApproveMutation__
 *
 * To run a mutation, you first call `useModuleMemberApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModuleMemberApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moduleMemberApproveMutation, { data, loading, error }] = useModuleMemberApproveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModuleMemberApproveMutation(baseOptions?: Apollo.MutationHookOptions<ModuleMemberApproveMutation, ModuleMemberApproveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModuleMemberApproveMutation, ModuleMemberApproveMutationVariables>(ModuleMemberApproveDocument, options);
      }
export type ModuleMemberApproveMutationHookResult = ReturnType<typeof useModuleMemberApproveMutation>;
export type ModuleMemberApproveMutationResult = Apollo.MutationResult<ModuleMemberApproveMutation>;
export type ModuleMemberApproveMutationOptions = Apollo.BaseMutationOptions<ModuleMemberApproveMutation, ModuleMemberApproveMutationVariables>;
export const DebriefFlowDoneDocument = gql`
    query DebriefFlowDone($applicationId: uuid!) {
  application(id: $applicationId) {
    candidate {
      id
    }
  }
}
    `;

/**
 * __useDebriefFlowDoneQuery__
 *
 * To run a query within a React component, call `useDebriefFlowDoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebriefFlowDoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebriefFlowDoneQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useDebriefFlowDoneQuery(baseOptions: Apollo.QueryHookOptions<DebriefFlowDoneQuery, DebriefFlowDoneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DebriefFlowDoneQuery, DebriefFlowDoneQueryVariables>(DebriefFlowDoneDocument, options);
      }
export function useDebriefFlowDoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DebriefFlowDoneQuery, DebriefFlowDoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DebriefFlowDoneQuery, DebriefFlowDoneQueryVariables>(DebriefFlowDoneDocument, options);
        }
export type DebriefFlowDoneQueryHookResult = ReturnType<typeof useDebriefFlowDoneQuery>;
export type DebriefFlowDoneLazyQueryHookResult = ReturnType<typeof useDebriefFlowDoneLazyQuery>;
export type DebriefFlowDoneQueryResult = Apollo.QueryResult<DebriefFlowDoneQuery, DebriefFlowDoneQueryVariables>;
export const DebriefFlowHeaderDocument = gql`
    query debriefFlowHeader($id: uuid!) {
  application(id: $id) {
    id
    candidate {
      id
      fullName
    }
    jobStage {
      id
      name
    }
    job {
      id
      name
    }
  }
}
    `;

/**
 * __useDebriefFlowHeaderQuery__
 *
 * To run a query within a React component, call `useDebriefFlowHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebriefFlowHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebriefFlowHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDebriefFlowHeaderQuery(baseOptions: Apollo.QueryHookOptions<DebriefFlowHeaderQuery, DebriefFlowHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DebriefFlowHeaderQuery, DebriefFlowHeaderQueryVariables>(DebriefFlowHeaderDocument, options);
      }
export function useDebriefFlowHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DebriefFlowHeaderQuery, DebriefFlowHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DebriefFlowHeaderQuery, DebriefFlowHeaderQueryVariables>(DebriefFlowHeaderDocument, options);
        }
export type DebriefFlowHeaderQueryHookResult = ReturnType<typeof useDebriefFlowHeaderQuery>;
export type DebriefFlowHeaderLazyQueryHookResult = ReturnType<typeof useDebriefFlowHeaderLazyQuery>;
export type DebriefFlowHeaderQueryResult = Apollo.QueryResult<DebriefFlowHeaderQuery, DebriefFlowHeaderQueryVariables>;
export const ApplicationAttendeesDocument = gql`
    query applicationAttendees($id: uuid!) {
  application(id: $id) {
    id
    applicationStages(input: {isCancelled: false}) {
      id
      startAt
      endAt
      applicationStageInterviews(input: {isCancelled: false}) {
        id
        atsScheduledInterviewId
        applicationStageInterviewInterviewers {
          interviewerId
          responseStatus
          interviewer {
            ...AttendeeAttributes
          }
        }
      }
      jobStage {
        id
        name
      }
    }
    job {
      id
      hiringManagers {
        ...AttendeeAttributes
      }
      sourcers {
        ...AttendeeAttributes
      }
    }
    candidate {
      id
      recruiter {
        ...AttendeeAttributes
      }
      coordinator {
        ...AttendeeAttributes
      }
      sourcer {
        ...AttendeeAttributes
      }
    }
    atsApplication {
      ...AtsDebriefStageFromApplication
    }
  }
}
    ${AttendeeAttributesFragmentDoc}
${AtsDebriefStageFromApplicationFragmentDoc}`;

/**
 * __useApplicationAttendeesQuery__
 *
 * To run a query within a React component, call `useApplicationAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationAttendeesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicationAttendeesQuery(baseOptions: Apollo.QueryHookOptions<ApplicationAttendeesQuery, ApplicationAttendeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationAttendeesQuery, ApplicationAttendeesQueryVariables>(ApplicationAttendeesDocument, options);
      }
export function useApplicationAttendeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationAttendeesQuery, ApplicationAttendeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationAttendeesQuery, ApplicationAttendeesQueryVariables>(ApplicationAttendeesDocument, options);
        }
export type ApplicationAttendeesQueryHookResult = ReturnType<typeof useApplicationAttendeesQuery>;
export type ApplicationAttendeesLazyQueryHookResult = ReturnType<typeof useApplicationAttendeesLazyQuery>;
export type ApplicationAttendeesQueryResult = Apollo.QueryResult<ApplicationAttendeesQuery, ApplicationAttendeesQueryVariables>;
export const TaskDebriefAttendeeDocument = gql`
    query TaskDebriefAttendee($taskId: uuid!) {
  thisUserInfo {
    userName
  }
  task(id: $taskId) {
    id
    debriefs {
      id
      attendees {
        interviewerId
        interviewer {
          id
          fullName
          slackImageUrl
          isDirectoryDisabled
          isAtsDisabled
          hasAtsId
        }
      }
    }
  }
}
    `;

/**
 * __useTaskDebriefAttendeeQuery__
 *
 * To run a query within a React component, call `useTaskDebriefAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDebriefAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDebriefAttendeeQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskDebriefAttendeeQuery(baseOptions: Apollo.QueryHookOptions<TaskDebriefAttendeeQuery, TaskDebriefAttendeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskDebriefAttendeeQuery, TaskDebriefAttendeeQueryVariables>(TaskDebriefAttendeeDocument, options);
      }
export function useTaskDebriefAttendeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskDebriefAttendeeQuery, TaskDebriefAttendeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskDebriefAttendeeQuery, TaskDebriefAttendeeQueryVariables>(TaskDebriefAttendeeDocument, options);
        }
export type TaskDebriefAttendeeQueryHookResult = ReturnType<typeof useTaskDebriefAttendeeQuery>;
export type TaskDebriefAttendeeLazyQueryHookResult = ReturnType<typeof useTaskDebriefAttendeeLazyQuery>;
export type TaskDebriefAttendeeQueryResult = Apollo.QueryResult<TaskDebriefAttendeeQuery, TaskDebriefAttendeeQueryVariables>;
export const DebriefTaskDocument = gql`
    query DebriefTask($taskId: uuid!) {
  task(id: $taskId) {
    id
    name
    jobStage {
      id
      job {
        id
        ...ScheduleFlowDataProvider_job
      }
    }
    schedule {
      id
      conversation {
        id
        remoteName
      }
    }
    debriefs {
      id
      conversationTemplateId
      emailTemplateId
      googleCalendarId
      conversation {
        id
        remoteName
      }
      startAt
      endAt
    }
  }
}
    ${ScheduleFlowDataProvider_JobFragmentDoc}`;

/**
 * __useDebriefTaskQuery__
 *
 * To run a query within a React component, call `useDebriefTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebriefTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebriefTaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDebriefTaskQuery(baseOptions: Apollo.QueryHookOptions<DebriefTaskQuery, DebriefTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DebriefTaskQuery, DebriefTaskQueryVariables>(DebriefTaskDocument, options);
      }
export function useDebriefTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DebriefTaskQuery, DebriefTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DebriefTaskQuery, DebriefTaskQueryVariables>(DebriefTaskDocument, options);
        }
export type DebriefTaskQueryHookResult = ReturnType<typeof useDebriefTaskQuery>;
export type DebriefTaskLazyQueryHookResult = ReturnType<typeof useDebriefTaskLazyQuery>;
export type DebriefTaskQueryResult = Apollo.QueryResult<DebriefTaskQuery, DebriefTaskQueryVariables>;
export const DebriefApplicationDocument = gql`
    query DebriefApplication($applicationId: uuid!) {
  application(id: $applicationId) {
    id
    candidate {
      id
      ...ScheduleFlowDataProvider_candidate
    }
    job {
      id
      atsId
      ...ScheduleFlowDataProvider_job
    }
    jobStageId
    existingConversations {
      id
      remoteName
    }
  }
}
    ${ScheduleFlowDataProvider_CandidateFragmentDoc}
${ScheduleFlowDataProvider_JobFragmentDoc}`;

/**
 * __useDebriefApplicationQuery__
 *
 * To run a query within a React component, call `useDebriefApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebriefApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebriefApplicationQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useDebriefApplicationQuery(baseOptions: Apollo.QueryHookOptions<DebriefApplicationQuery, DebriefApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DebriefApplicationQuery, DebriefApplicationQueryVariables>(DebriefApplicationDocument, options);
      }
export function useDebriefApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DebriefApplicationQuery, DebriefApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DebriefApplicationQuery, DebriefApplicationQueryVariables>(DebriefApplicationDocument, options);
        }
export type DebriefApplicationQueryHookResult = ReturnType<typeof useDebriefApplicationQuery>;
export type DebriefApplicationLazyQueryHookResult = ReturnType<typeof useDebriefApplicationLazyQuery>;
export type DebriefApplicationQueryResult = Apollo.QueryResult<DebriefApplicationQuery, DebriefApplicationQueryVariables>;
export const DevToolsTaskSearchDocument = gql`
    query DevToolsTaskSearch($input: TaskSearchInput!) {
  taskSearch(input: $input) {
    items {
      id
      status
    }
  }
}
    `;

/**
 * __useDevToolsTaskSearchQuery__
 *
 * To run a query within a React component, call `useDevToolsTaskSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevToolsTaskSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevToolsTaskSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDevToolsTaskSearchQuery(baseOptions: Apollo.QueryHookOptions<DevToolsTaskSearchQuery, DevToolsTaskSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevToolsTaskSearchQuery, DevToolsTaskSearchQueryVariables>(DevToolsTaskSearchDocument, options);
      }
export function useDevToolsTaskSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevToolsTaskSearchQuery, DevToolsTaskSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevToolsTaskSearchQuery, DevToolsTaskSearchQueryVariables>(DevToolsTaskSearchDocument, options);
        }
export type DevToolsTaskSearchQueryHookResult = ReturnType<typeof useDevToolsTaskSearchQuery>;
export type DevToolsTaskSearchLazyQueryHookResult = ReturnType<typeof useDevToolsTaskSearchLazyQuery>;
export type DevToolsTaskSearchQueryResult = Apollo.QueryResult<DevToolsTaskSearchQuery, DevToolsTaskSearchQueryVariables>;
export const DevToolsApplicationTasksDocument = gql`
    query DevToolsApplicationTasks($applicationId: uuid!) {
  application(id: $applicationId) {
    tasks {
      id
      status
    }
  }
}
    `;

/**
 * __useDevToolsApplicationTasksQuery__
 *
 * To run a query within a React component, call `useDevToolsApplicationTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevToolsApplicationTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevToolsApplicationTasksQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useDevToolsApplicationTasksQuery(baseOptions: Apollo.QueryHookOptions<DevToolsApplicationTasksQuery, DevToolsApplicationTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevToolsApplicationTasksQuery, DevToolsApplicationTasksQueryVariables>(DevToolsApplicationTasksDocument, options);
      }
export function useDevToolsApplicationTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevToolsApplicationTasksQuery, DevToolsApplicationTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevToolsApplicationTasksQuery, DevToolsApplicationTasksQueryVariables>(DevToolsApplicationTasksDocument, options);
        }
export type DevToolsApplicationTasksQueryHookResult = ReturnType<typeof useDevToolsApplicationTasksQuery>;
export type DevToolsApplicationTasksLazyQueryHookResult = ReturnType<typeof useDevToolsApplicationTasksLazyQuery>;
export type DevToolsApplicationTasksQueryResult = Apollo.QueryResult<DevToolsApplicationTasksQuery, DevToolsApplicationTasksQueryVariables>;
export const DevToolsTaskCancelDocument = gql`
    mutation DevToolsTaskCancel($input: TaskCancelInput!) {
  taskCancel(input: $input) {
    id
  }
}
    `;
export type DevToolsTaskCancelMutationFn = Apollo.MutationFunction<DevToolsTaskCancelMutation, DevToolsTaskCancelMutationVariables>;

/**
 * __useDevToolsTaskCancelMutation__
 *
 * To run a mutation, you first call `useDevToolsTaskCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDevToolsTaskCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [devToolsTaskCancelMutation, { data, loading, error }] = useDevToolsTaskCancelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDevToolsTaskCancelMutation(baseOptions?: Apollo.MutationHookOptions<DevToolsTaskCancelMutation, DevToolsTaskCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DevToolsTaskCancelMutation, DevToolsTaskCancelMutationVariables>(DevToolsTaskCancelDocument, options);
      }
export type DevToolsTaskCancelMutationHookResult = ReturnType<typeof useDevToolsTaskCancelMutation>;
export type DevToolsTaskCancelMutationResult = Apollo.MutationResult<DevToolsTaskCancelMutation>;
export type DevToolsTaskCancelMutationOptions = Apollo.BaseMutationOptions<DevToolsTaskCancelMutation, DevToolsTaskCancelMutationVariables>;
export const EmployeeAttributeNamesDocument = gql`
    query employeeAttributeNames($input: EmployeeAttributeNamesInput!) {
  employeeAttributeNames(input: $input) {
    items {
      ...AttributesFilter
    }
  }
}
    ${AttributesFilterFragmentDoc}`;

/**
 * __useEmployeeAttributeNamesQuery__
 *
 * To run a query within a React component, call `useEmployeeAttributeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAttributeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeAttributeNamesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeAttributeNamesQuery(baseOptions: Apollo.QueryHookOptions<EmployeeAttributeNamesQuery, EmployeeAttributeNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeAttributeNamesQuery, EmployeeAttributeNamesQueryVariables>(EmployeeAttributeNamesDocument, options);
      }
export function useEmployeeAttributeNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeAttributeNamesQuery, EmployeeAttributeNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeAttributeNamesQuery, EmployeeAttributeNamesQueryVariables>(EmployeeAttributeNamesDocument, options);
        }
export type EmployeeAttributeNamesQueryHookResult = ReturnType<typeof useEmployeeAttributeNamesQuery>;
export type EmployeeAttributeNamesLazyQueryHookResult = ReturnType<typeof useEmployeeAttributeNamesLazyQuery>;
export type EmployeeAttributeNamesQueryResult = Apollo.QueryResult<EmployeeAttributeNamesQuery, EmployeeAttributeNamesQueryVariables>;
export const InterviewModuleMemberSearchDocument = gql`
    query InterviewModuleMemberSearch($input: InterviewModuleMemberSearchInput!) {
  interviewModuleMemberSearch(input: $input) {
    items {
      ...EmployeeFilter
    }
  }
}
    ${EmployeeFilterFragmentDoc}`;

/**
 * __useInterviewModuleMemberSearchQuery__
 *
 * To run a query within a React component, call `useInterviewModuleMemberSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMemberSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleMemberSearchQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleMemberSearchQuery, InterviewModuleMemberSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMemberSearchQuery, InterviewModuleMemberSearchQueryVariables>(InterviewModuleMemberSearchDocument, options);
      }
export function useInterviewModuleMemberSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMemberSearchQuery, InterviewModuleMemberSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMemberSearchQuery, InterviewModuleMemberSearchQueryVariables>(InterviewModuleMemberSearchDocument, options);
        }
export type InterviewModuleMemberSearchQueryHookResult = ReturnType<typeof useInterviewModuleMemberSearchQuery>;
export type InterviewModuleMemberSearchLazyQueryHookResult = ReturnType<typeof useInterviewModuleMemberSearchLazyQuery>;
export type InterviewModuleMemberSearchQueryResult = Apollo.QueryResult<InterviewModuleMemberSearchQuery, InterviewModuleMemberSearchQueryVariables>;
export const CreateInterviewModuleDocument = gql`
    mutation CreateInterviewModule($input: InterviewModuleCreateInput!) {
  interviewModuleCreate(input: $input) {
    interviewModule {
      id
    }
  }
}
    `;
export type CreateInterviewModuleMutationFn = Apollo.MutationFunction<CreateInterviewModuleMutation, CreateInterviewModuleMutationVariables>;

/**
 * __useCreateInterviewModuleMutation__
 *
 * To run a mutation, you first call `useCreateInterviewModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInterviewModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInterviewModuleMutation, { data, loading, error }] = useCreateInterviewModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInterviewModuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateInterviewModuleMutation, CreateInterviewModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInterviewModuleMutation, CreateInterviewModuleMutationVariables>(CreateInterviewModuleDocument, options);
      }
export type CreateInterviewModuleMutationHookResult = ReturnType<typeof useCreateInterviewModuleMutation>;
export type CreateInterviewModuleMutationResult = Apollo.MutationResult<CreateInterviewModuleMutation>;
export type CreateInterviewModuleMutationOptions = Apollo.BaseMutationOptions<CreateInterviewModuleMutation, CreateInterviewModuleMutationVariables>;
export const InterviewModuleSearchV2Document = gql`
    query InterviewModuleSearchV2($input: InterviewModuleSearchInput!, $trainedEmployeesInput: InterviewModuleMembersInput!, $untrainedEmployeesInput: InterviewModuleMembersInput!) {
  interviewModuleSearch(input: $input) {
    total
    items {
      id
      ...InterviewModuleList_InterviewModule
    }
  }
}
    ${InterviewModuleList_InterviewModuleFragmentDoc}`;

/**
 * __useInterviewModuleSearchV2Query__
 *
 * To run a query within a React component, call `useInterviewModuleSearchV2Query` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleSearchV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleSearchV2Query({
 *   variables: {
 *      input: // value for 'input'
 *      trainedEmployeesInput: // value for 'trainedEmployeesInput'
 *      untrainedEmployeesInput: // value for 'untrainedEmployeesInput'
 *   },
 * });
 */
export function useInterviewModuleSearchV2Query(baseOptions: Apollo.QueryHookOptions<InterviewModuleSearchV2Query, InterviewModuleSearchV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleSearchV2Query, InterviewModuleSearchV2QueryVariables>(InterviewModuleSearchV2Document, options);
      }
export function useInterviewModuleSearchV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleSearchV2Query, InterviewModuleSearchV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleSearchV2Query, InterviewModuleSearchV2QueryVariables>(InterviewModuleSearchV2Document, options);
        }
export type InterviewModuleSearchV2QueryHookResult = ReturnType<typeof useInterviewModuleSearchV2Query>;
export type InterviewModuleSearchV2LazyQueryHookResult = ReturnType<typeof useInterviewModuleSearchV2LazyQuery>;
export type InterviewModuleSearchV2QueryResult = Apollo.QueryResult<InterviewModuleSearchV2Query, InterviewModuleSearchV2QueryVariables>;
export const InterviewModuleCreateDocument = gql`
    mutation InterviewModuleCreate($input: InterviewModuleCreateInput!) {
  interviewModuleCreate(input: $input) {
    interviewModule {
      id
    }
  }
}
    `;
export type InterviewModuleCreateMutationFn = Apollo.MutationFunction<InterviewModuleCreateMutation, InterviewModuleCreateMutationVariables>;

/**
 * __useInterviewModuleCreateMutation__
 *
 * To run a mutation, you first call `useInterviewModuleCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleCreateMutation, { data, loading, error }] = useInterviewModuleCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleCreateMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleCreateMutation, InterviewModuleCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleCreateMutation, InterviewModuleCreateMutationVariables>(InterviewModuleCreateDocument, options);
      }
export type InterviewModuleCreateMutationHookResult = ReturnType<typeof useInterviewModuleCreateMutation>;
export type InterviewModuleCreateMutationResult = Apollo.MutationResult<InterviewModuleCreateMutation>;
export type InterviewModuleCreateMutationOptions = Apollo.BaseMutationOptions<InterviewModuleCreateMutation, InterviewModuleCreateMutationVariables>;
export const InterviewModuleSearchDocument = gql`
    query InterviewModuleSearch($input: InterviewModuleSearchInput!) {
  interviewModuleSearch(input: $input) {
    total
    items {
      id
      name
      membersTrainedCount
      membersInTrainingCount
      membersInPendingApprovalCount
      shadowsRequired
      reverseShadowsRequired
    }
  }
}
    `;

/**
 * __useInterviewModuleSearchQuery__
 *
 * To run a query within a React component, call `useInterviewModuleSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleSearchQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleSearchQuery, InterviewModuleSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleSearchQuery, InterviewModuleSearchQueryVariables>(InterviewModuleSearchDocument, options);
      }
export function useInterviewModuleSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleSearchQuery, InterviewModuleSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleSearchQuery, InterviewModuleSearchQueryVariables>(InterviewModuleSearchDocument, options);
        }
export type InterviewModuleSearchQueryHookResult = ReturnType<typeof useInterviewModuleSearchQuery>;
export type InterviewModuleSearchLazyQueryHookResult = ReturnType<typeof useInterviewModuleSearchLazyQuery>;
export type InterviewModuleSearchQueryResult = Apollo.QueryResult<InterviewModuleSearchQuery, InterviewModuleSearchQueryVariables>;
export const InterviewModuleMemberStatsDocument = gql`
    query InterviewModuleMemberStats {
  interviewModuleMemberStats {
    interviewModule {
      name
    }
    interviewModuleMember {
      interviewModuleId
      employeeId
      employee {
        fullName
      }
    }
    completed
    trainingStatus
    trainingsToPass
    currentTrainingSession
    currentTrainingSessionStatus
    shadowsRequired
    reverseShadowsRequired
  }
}
    `;

/**
 * __useInterviewModuleMemberStatsQuery__
 *
 * To run a query within a React component, call `useInterviewModuleMemberStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMemberStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterviewModuleMemberStatsQuery(baseOptions?: Apollo.QueryHookOptions<InterviewModuleMemberStatsQuery, InterviewModuleMemberStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMemberStatsQuery, InterviewModuleMemberStatsQueryVariables>(InterviewModuleMemberStatsDocument, options);
      }
export function useInterviewModuleMemberStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMemberStatsQuery, InterviewModuleMemberStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMemberStatsQuery, InterviewModuleMemberStatsQueryVariables>(InterviewModuleMemberStatsDocument, options);
        }
export type InterviewModuleMemberStatsQueryHookResult = ReturnType<typeof useInterviewModuleMemberStatsQuery>;
export type InterviewModuleMemberStatsLazyQueryHookResult = ReturnType<typeof useInterviewModuleMemberStatsLazyQuery>;
export type InterviewModuleMemberStatsQueryResult = Apollo.QueryResult<InterviewModuleMemberStatsQuery, InterviewModuleMemberStatsQueryVariables>;
export const UpdateInterviewTrainingScheduleDocument = gql`
    mutation UpdateInterviewTrainingSchedule($input: InterviewModuleUpdateInput!) {
  interviewModuleUpdate(input: $input) {
    interviewModule {
      id
      name
      shouldFastTrackTraining
    }
  }
}
    `;
export type UpdateInterviewTrainingScheduleMutationFn = Apollo.MutationFunction<UpdateInterviewTrainingScheduleMutation, UpdateInterviewTrainingScheduleMutationVariables>;

/**
 * __useUpdateInterviewTrainingScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewTrainingScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewTrainingScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewTrainingScheduleMutation, { data, loading, error }] = useUpdateInterviewTrainingScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewTrainingScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewTrainingScheduleMutation, UpdateInterviewTrainingScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewTrainingScheduleMutation, UpdateInterviewTrainingScheduleMutationVariables>(UpdateInterviewTrainingScheduleDocument, options);
      }
export type UpdateInterviewTrainingScheduleMutationHookResult = ReturnType<typeof useUpdateInterviewTrainingScheduleMutation>;
export type UpdateInterviewTrainingScheduleMutationResult = Apollo.MutationResult<UpdateInterviewTrainingScheduleMutation>;
export type UpdateInterviewTrainingScheduleMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewTrainingScheduleMutation, UpdateInterviewTrainingScheduleMutationVariables>;
export const UpdateInterviewModuleNameDocument = gql`
    mutation UpdateInterviewModuleName($input: InterviewModuleUpdateInput!) {
  interviewModuleUpdate(input: $input) {
    interviewModule {
      id
      name
    }
  }
}
    `;
export type UpdateInterviewModuleNameMutationFn = Apollo.MutationFunction<UpdateInterviewModuleNameMutation, UpdateInterviewModuleNameMutationVariables>;

/**
 * __useUpdateInterviewModuleNameMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewModuleNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewModuleNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewModuleNameMutation, { data, loading, error }] = useUpdateInterviewModuleNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewModuleNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewModuleNameMutation, UpdateInterviewModuleNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewModuleNameMutation, UpdateInterviewModuleNameMutationVariables>(UpdateInterviewModuleNameDocument, options);
      }
export type UpdateInterviewModuleNameMutationHookResult = ReturnType<typeof useUpdateInterviewModuleNameMutation>;
export type UpdateInterviewModuleNameMutationResult = Apollo.MutationResult<UpdateInterviewModuleNameMutation>;
export type UpdateInterviewModuleNameMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewModuleNameMutation, UpdateInterviewModuleNameMutationVariables>;
export const TraineeRoleConfigSlackStatusDocument = gql`
    query TraineeRoleConfigSlackStatus {
  thisOrg {
    id
    integrations {
      slack {
        active
      }
    }
  }
}
    `;

/**
 * __useTraineeRoleConfigSlackStatusQuery__
 *
 * To run a query within a React component, call `useTraineeRoleConfigSlackStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraineeRoleConfigSlackStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraineeRoleConfigSlackStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useTraineeRoleConfigSlackStatusQuery(baseOptions?: Apollo.QueryHookOptions<TraineeRoleConfigSlackStatusQuery, TraineeRoleConfigSlackStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TraineeRoleConfigSlackStatusQuery, TraineeRoleConfigSlackStatusQueryVariables>(TraineeRoleConfigSlackStatusDocument, options);
      }
export function useTraineeRoleConfigSlackStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TraineeRoleConfigSlackStatusQuery, TraineeRoleConfigSlackStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TraineeRoleConfigSlackStatusQuery, TraineeRoleConfigSlackStatusQueryVariables>(TraineeRoleConfigSlackStatusDocument, options);
        }
export type TraineeRoleConfigSlackStatusQueryHookResult = ReturnType<typeof useTraineeRoleConfigSlackStatusQuery>;
export type TraineeRoleConfigSlackStatusLazyQueryHookResult = ReturnType<typeof useTraineeRoleConfigSlackStatusLazyQuery>;
export type TraineeRoleConfigSlackStatusQueryResult = Apollo.QueryResult<TraineeRoleConfigSlackStatusQuery, TraineeRoleConfigSlackStatusQueryVariables>;
export const UpdateInterviewModuleSetupDocument = gql`
    mutation UpdateInterviewModuleSetup($input: InterviewModuleUpdateInput!) {
  interviewModuleUpdate(input: $input) {
    interviewModule {
      id
      ...InterviewModuleSetup_interviewModule
    }
  }
}
    ${InterviewModuleSetup_InterviewModuleFragmentDoc}`;
export type UpdateInterviewModuleSetupMutationFn = Apollo.MutationFunction<UpdateInterviewModuleSetupMutation, UpdateInterviewModuleSetupMutationVariables>;

/**
 * __useUpdateInterviewModuleSetupMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewModuleSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewModuleSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewModuleSetupMutation, { data, loading, error }] = useUpdateInterviewModuleSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewModuleSetupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewModuleSetupMutation, UpdateInterviewModuleSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewModuleSetupMutation, UpdateInterviewModuleSetupMutationVariables>(UpdateInterviewModuleSetupDocument, options);
      }
export type UpdateInterviewModuleSetupMutationHookResult = ReturnType<typeof useUpdateInterviewModuleSetupMutation>;
export type UpdateInterviewModuleSetupMutationResult = Apollo.MutationResult<UpdateInterviewModuleSetupMutation>;
export type UpdateInterviewModuleSetupMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewModuleSetupMutation, UpdateInterviewModuleSetupMutationVariables>;
export const AddMembersToModuleDocument = gql`
    mutation AddMembersToModule($input: InterviewModuleAddMembersInput!) {
  interviewModuleAddMembers(input: $input) {
    interviewModule {
      id
    }
  }
}
    `;
export type AddMembersToModuleMutationFn = Apollo.MutationFunction<AddMembersToModuleMutation, AddMembersToModuleMutationVariables>;

/**
 * __useAddMembersToModuleMutation__
 *
 * To run a mutation, you first call `useAddMembersToModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembersToModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembersToModuleMutation, { data, loading, error }] = useAddMembersToModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMembersToModuleMutation(baseOptions?: Apollo.MutationHookOptions<AddMembersToModuleMutation, AddMembersToModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMembersToModuleMutation, AddMembersToModuleMutationVariables>(AddMembersToModuleDocument, options);
      }
export type AddMembersToModuleMutationHookResult = ReturnType<typeof useAddMembersToModuleMutation>;
export type AddMembersToModuleMutationResult = Apollo.MutationResult<AddMembersToModuleMutation>;
export type AddMembersToModuleMutationOptions = Apollo.BaseMutationOptions<AddMembersToModuleMutation, AddMembersToModuleMutationVariables>;
export const InterviewModuleMembersListDocument = gql`
    query InterviewModuleMembersList($id: uuid!, $pageInput: PageInput!, $orderBy: InterviewModuleMemberSearchOrderBy!, $search: String, $filterByTrainingStatus: [TrainingStatus], $filterByState: [InterviewModuleMemberState]) {
  interviewModuleMemberSearch(
    input: {interviewModuleId: $id, pageInput: $pageInput, orderBy: $orderBy, search: $search, filterByTrainingStatus: $filterByTrainingStatus, filterByState: $filterByState}
  ) {
    total
    items {
      ...Members_members
    }
  }
}
    ${Members_MembersFragmentDoc}`;

/**
 * __useInterviewModuleMembersListQuery__
 *
 * To run a query within a React component, call `useInterviewModuleMembersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMembersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMembersListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pageInput: // value for 'pageInput'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      filterByTrainingStatus: // value for 'filterByTrainingStatus'
 *      filterByState: // value for 'filterByState'
 *   },
 * });
 */
export function useInterviewModuleMembersListQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleMembersListQuery, InterviewModuleMembersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMembersListQuery, InterviewModuleMembersListQueryVariables>(InterviewModuleMembersListDocument, options);
      }
export function useInterviewModuleMembersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMembersListQuery, InterviewModuleMembersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMembersListQuery, InterviewModuleMembersListQueryVariables>(InterviewModuleMembersListDocument, options);
        }
export type InterviewModuleMembersListQueryHookResult = ReturnType<typeof useInterviewModuleMembersListQuery>;
export type InterviewModuleMembersListLazyQueryHookResult = ReturnType<typeof useInterviewModuleMembersListLazyQuery>;
export type InterviewModuleMembersListQueryResult = Apollo.QueryResult<InterviewModuleMembersListQuery, InterviewModuleMembersListQueryVariables>;
export const ModuleMembersViewDocument = gql`
    query ModuleMembersView($interviewModuleId: uuid!) {
  interviewModule(id: $interviewModuleId) {
    id
    name
    ...AddMembersModal_module
    ...MembersList_module
    ...InterviewModuleSetup_interviewModule
  }
}
    ${AddMembersModal_ModuleFragmentDoc}
${MembersList_ModuleFragmentDoc}
${InterviewModuleSetup_InterviewModuleFragmentDoc}`;

/**
 * __useModuleMembersViewQuery__
 *
 * To run a query within a React component, call `useModuleMembersViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleMembersViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleMembersViewQuery({
 *   variables: {
 *      interviewModuleId: // value for 'interviewModuleId'
 *   },
 * });
 */
export function useModuleMembersViewQuery(baseOptions: Apollo.QueryHookOptions<ModuleMembersViewQuery, ModuleMembersViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModuleMembersViewQuery, ModuleMembersViewQueryVariables>(ModuleMembersViewDocument, options);
      }
export function useModuleMembersViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleMembersViewQuery, ModuleMembersViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModuleMembersViewQuery, ModuleMembersViewQueryVariables>(ModuleMembersViewDocument, options);
        }
export type ModuleMembersViewQueryHookResult = ReturnType<typeof useModuleMembersViewQuery>;
export type ModuleMembersViewLazyQueryHookResult = ReturnType<typeof useModuleMembersViewLazyQuery>;
export type ModuleMembersViewQueryResult = Apollo.QueryResult<ModuleMembersViewQuery, ModuleMembersViewQueryVariables>;
export const InterviewModuleDocument = gql`
    query InterviewModule($interviewModuleId: uuid!) {
  interviewModule(id: $interviewModuleId) {
    ...InterviewModule
    membersCount
    membersTrainedCount
    membersInTrainingCount
    membersInShadowCount
    membersInReverseShadowCount
    graduateFromShadowApprovalType
    graduateFromShadowCustomApprovers
    graduateFromReverseShadowApprovalType
    graduateFromReverseShadowCustomApprovers
  }
}
    ${InterviewModuleFragmentDoc}`;

/**
 * __useInterviewModuleQuery__
 *
 * To run a query within a React component, call `useInterviewModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleQuery({
 *   variables: {
 *      interviewModuleId: // value for 'interviewModuleId'
 *   },
 * });
 */
export function useInterviewModuleQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleQuery, InterviewModuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleQuery, InterviewModuleQueryVariables>(InterviewModuleDocument, options);
      }
export function useInterviewModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleQuery, InterviewModuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleQuery, InterviewModuleQueryVariables>(InterviewModuleDocument, options);
        }
export type InterviewModuleQueryHookResult = ReturnType<typeof useInterviewModuleQuery>;
export type InterviewModuleLazyQueryHookResult = ReturnType<typeof useInterviewModuleLazyQuery>;
export type InterviewModuleQueryResult = Apollo.QueryResult<InterviewModuleQuery, InterviewModuleQueryVariables>;
export const InterviewModuleUpdateDocument = gql`
    mutation InterviewModuleUpdate($input: InterviewModuleUpdateInput!) {
  interviewModuleUpdate(input: $input) {
    interviewModule {
      id
    }
  }
}
    `;
export type InterviewModuleUpdateMutationFn = Apollo.MutationFunction<InterviewModuleUpdateMutation, InterviewModuleUpdateMutationVariables>;

/**
 * __useInterviewModuleUpdateMutation__
 *
 * To run a mutation, you first call `useInterviewModuleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleUpdateMutation, { data, loading, error }] = useInterviewModuleUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleUpdateMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleUpdateMutation, InterviewModuleUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleUpdateMutation, InterviewModuleUpdateMutationVariables>(InterviewModuleUpdateDocument, options);
      }
export type InterviewModuleUpdateMutationHookResult = ReturnType<typeof useInterviewModuleUpdateMutation>;
export type InterviewModuleUpdateMutationResult = Apollo.MutationResult<InterviewModuleUpdateMutation>;
export type InterviewModuleUpdateMutationOptions = Apollo.BaseMutationOptions<InterviewModuleUpdateMutation, InterviewModuleUpdateMutationVariables>;
export const InterviewModuleDeleteDocument = gql`
    mutation InterviewModuleDelete($interviewModuleId: uuid!) {
  interviewModuleDelete(id: $interviewModuleId) {
    interviewModule {
      id
    }
  }
}
    `;
export type InterviewModuleDeleteMutationFn = Apollo.MutationFunction<InterviewModuleDeleteMutation, InterviewModuleDeleteMutationVariables>;

/**
 * __useInterviewModuleDeleteMutation__
 *
 * To run a mutation, you first call `useInterviewModuleDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleDeleteMutation, { data, loading, error }] = useInterviewModuleDeleteMutation({
 *   variables: {
 *      interviewModuleId: // value for 'interviewModuleId'
 *   },
 * });
 */
export function useInterviewModuleDeleteMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleDeleteMutation, InterviewModuleDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleDeleteMutation, InterviewModuleDeleteMutationVariables>(InterviewModuleDeleteDocument, options);
      }
export type InterviewModuleDeleteMutationHookResult = ReturnType<typeof useInterviewModuleDeleteMutation>;
export type InterviewModuleDeleteMutationResult = Apollo.MutationResult<InterviewModuleDeleteMutation>;
export type InterviewModuleDeleteMutationOptions = Apollo.BaseMutationOptions<InterviewModuleDeleteMutation, InterviewModuleDeleteMutationVariables>;
export const EmployeesToAddForInterviewModuleDocument = gql`
    query EmployeesToAddForInterviewModule($input: EmployeesInput!, $interviewModuleId: uuid!) {
  employees(input: $input) {
    nextCursor
    items {
      ...Employee
      interviewModuleMember(interviewModuleId: $interviewModuleId) {
        employeeId
        status
        interviewModuleId
      }
    }
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useEmployeesToAddForInterviewModuleQuery__
 *
 * To run a query within a React component, call `useEmployeesToAddForInterviewModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesToAddForInterviewModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesToAddForInterviewModuleQuery({
 *   variables: {
 *      input: // value for 'input'
 *      interviewModuleId: // value for 'interviewModuleId'
 *   },
 * });
 */
export function useEmployeesToAddForInterviewModuleQuery(baseOptions: Apollo.QueryHookOptions<EmployeesToAddForInterviewModuleQuery, EmployeesToAddForInterviewModuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesToAddForInterviewModuleQuery, EmployeesToAddForInterviewModuleQueryVariables>(EmployeesToAddForInterviewModuleDocument, options);
      }
export function useEmployeesToAddForInterviewModuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesToAddForInterviewModuleQuery, EmployeesToAddForInterviewModuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesToAddForInterviewModuleQuery, EmployeesToAddForInterviewModuleQueryVariables>(EmployeesToAddForInterviewModuleDocument, options);
        }
export type EmployeesToAddForInterviewModuleQueryHookResult = ReturnType<typeof useEmployeesToAddForInterviewModuleQuery>;
export type EmployeesToAddForInterviewModuleLazyQueryHookResult = ReturnType<typeof useEmployeesToAddForInterviewModuleLazyQuery>;
export type EmployeesToAddForInterviewModuleQueryResult = Apollo.QueryResult<EmployeesToAddForInterviewModuleQuery, EmployeesToAddForInterviewModuleQueryVariables>;
export const InterviewModuleMemberUpdateDocument = gql`
    mutation InterviewModuleMemberUpdate($input: InterviewModuleMemberUpdateInput!) {
  interviewModuleMemberUpdate(input: $input) {
    interviewModuleMember {
      ...InterviewMember
    }
  }
}
    ${InterviewMemberFragmentDoc}`;
export type InterviewModuleMemberUpdateMutationFn = Apollo.MutationFunction<InterviewModuleMemberUpdateMutation, InterviewModuleMemberUpdateMutationVariables>;

/**
 * __useInterviewModuleMemberUpdateMutation__
 *
 * To run a mutation, you first call `useInterviewModuleMemberUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleMemberUpdateMutation, { data, loading, error }] = useInterviewModuleMemberUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleMemberUpdateMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleMemberUpdateMutation, InterviewModuleMemberUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleMemberUpdateMutation, InterviewModuleMemberUpdateMutationVariables>(InterviewModuleMemberUpdateDocument, options);
      }
export type InterviewModuleMemberUpdateMutationHookResult = ReturnType<typeof useInterviewModuleMemberUpdateMutation>;
export type InterviewModuleMemberUpdateMutationResult = Apollo.MutationResult<InterviewModuleMemberUpdateMutation>;
export type InterviewModuleMemberUpdateMutationOptions = Apollo.BaseMutationOptions<InterviewModuleMemberUpdateMutation, InterviewModuleMemberUpdateMutationVariables>;
export const InterviewModuleAddMembersDocument = gql`
    mutation InterviewModuleAddMembers($input: InterviewModuleAddMembersInput!) {
  interviewModuleAddMembers(input: $input) {
    interviewModule {
      id
    }
  }
}
    `;
export type InterviewModuleAddMembersMutationFn = Apollo.MutationFunction<InterviewModuleAddMembersMutation, InterviewModuleAddMembersMutationVariables>;

/**
 * __useInterviewModuleAddMembersMutation__
 *
 * To run a mutation, you first call `useInterviewModuleAddMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleAddMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleAddMembersMutation, { data, loading, error }] = useInterviewModuleAddMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleAddMembersMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleAddMembersMutation, InterviewModuleAddMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleAddMembersMutation, InterviewModuleAddMembersMutationVariables>(InterviewModuleAddMembersDocument, options);
      }
export type InterviewModuleAddMembersMutationHookResult = ReturnType<typeof useInterviewModuleAddMembersMutation>;
export type InterviewModuleAddMembersMutationResult = Apollo.MutationResult<InterviewModuleAddMembersMutation>;
export type InterviewModuleAddMembersMutationOptions = Apollo.BaseMutationOptions<InterviewModuleAddMembersMutation, InterviewModuleAddMembersMutationVariables>;
export const InterviewModuleRemoveMembersDocument = gql`
    mutation InterviewModuleRemoveMembers($input: InterviewModuleRemoveMembersInput!) {
  interviewModuleRemoveMembers(input: $input) {
    interviewModule {
      id
    }
  }
}
    `;
export type InterviewModuleRemoveMembersMutationFn = Apollo.MutationFunction<InterviewModuleRemoveMembersMutation, InterviewModuleRemoveMembersMutationVariables>;

/**
 * __useInterviewModuleRemoveMembersMutation__
 *
 * To run a mutation, you first call `useInterviewModuleRemoveMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleRemoveMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleRemoveMembersMutation, { data, loading, error }] = useInterviewModuleRemoveMembersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleRemoveMembersMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleRemoveMembersMutation, InterviewModuleRemoveMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleRemoveMembersMutation, InterviewModuleRemoveMembersMutationVariables>(InterviewModuleRemoveMembersDocument, options);
      }
export type InterviewModuleRemoveMembersMutationHookResult = ReturnType<typeof useInterviewModuleRemoveMembersMutation>;
export type InterviewModuleRemoveMembersMutationResult = Apollo.MutationResult<InterviewModuleRemoveMembersMutation>;
export type InterviewModuleRemoveMembersMutationOptions = Apollo.BaseMutationOptions<InterviewModuleRemoveMembersMutation, InterviewModuleRemoveMembersMutationVariables>;
export const InterviewModuleMemberMoveStageDocument = gql`
    mutation InterviewModuleMemberMoveStage($input: InterviewModuleMemberMoveStageInput!) {
  interviewModuleMemberMoveStage(input: $input) {
    interviewModuleMember {
      ...InterviewMember
    }
  }
}
    ${InterviewMemberFragmentDoc}`;
export type InterviewModuleMemberMoveStageMutationFn = Apollo.MutationFunction<InterviewModuleMemberMoveStageMutation, InterviewModuleMemberMoveStageMutationVariables>;

/**
 * __useInterviewModuleMemberMoveStageMutation__
 *
 * To run a mutation, you first call `useInterviewModuleMemberMoveStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberMoveStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleMemberMoveStageMutation, { data, loading, error }] = useInterviewModuleMemberMoveStageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleMemberMoveStageMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleMemberMoveStageMutation, InterviewModuleMemberMoveStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleMemberMoveStageMutation, InterviewModuleMemberMoveStageMutationVariables>(InterviewModuleMemberMoveStageDocument, options);
      }
export type InterviewModuleMemberMoveStageMutationHookResult = ReturnType<typeof useInterviewModuleMemberMoveStageMutation>;
export type InterviewModuleMemberMoveStageMutationResult = Apollo.MutationResult<InterviewModuleMemberMoveStageMutation>;
export type InterviewModuleMemberMoveStageMutationOptions = Apollo.BaseMutationOptions<InterviewModuleMemberMoveStageMutation, InterviewModuleMemberMoveStageMutationVariables>;
export const InterviewModuleMemberDocument = gql`
    query InterviewModuleMember($moduleId: uuid!, $employeeId: uuid!) {
  interviewModuleMember(interviewModuleId: $moduleId, employeeId: $employeeId) {
    ...InterviewMember
  }
}
    ${InterviewMemberFragmentDoc}`;

/**
 * __useInterviewModuleMemberQuery__
 *
 * To run a query within a React component, call `useInterviewModuleMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMemberQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useInterviewModuleMemberQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleMemberQuery, InterviewModuleMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMemberQuery, InterviewModuleMemberQueryVariables>(InterviewModuleMemberDocument, options);
      }
export function useInterviewModuleMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMemberQuery, InterviewModuleMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMemberQuery, InterviewModuleMemberQueryVariables>(InterviewModuleMemberDocument, options);
        }
export type InterviewModuleMemberQueryHookResult = ReturnType<typeof useInterviewModuleMemberQuery>;
export type InterviewModuleMemberLazyQueryHookResult = ReturnType<typeof useInterviewModuleMemberLazyQuery>;
export type InterviewModuleMemberQueryResult = Apollo.QueryResult<InterviewModuleMemberQuery, InterviewModuleMemberQueryVariables>;
export const InterviewModuleMemberApproveDocument = gql`
    mutation InterviewModuleMemberApprove($input: InterviewModuleMemberApproveInput!) {
  interviewModuleMemberApprove(input: $input) {
    interviewModuleMember {
      ...InterviewMember
    }
  }
}
    ${InterviewMemberFragmentDoc}`;
export type InterviewModuleMemberApproveMutationFn = Apollo.MutationFunction<InterviewModuleMemberApproveMutation, InterviewModuleMemberApproveMutationVariables>;

/**
 * __useInterviewModuleMemberApproveMutation__
 *
 * To run a mutation, you first call `useInterviewModuleMemberApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewModuleMemberApproveMutation, { data, loading, error }] = useInterviewModuleMemberApproveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleMemberApproveMutation(baseOptions?: Apollo.MutationHookOptions<InterviewModuleMemberApproveMutation, InterviewModuleMemberApproveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewModuleMemberApproveMutation, InterviewModuleMemberApproveMutationVariables>(InterviewModuleMemberApproveDocument, options);
      }
export type InterviewModuleMemberApproveMutationHookResult = ReturnType<typeof useInterviewModuleMemberApproveMutation>;
export type InterviewModuleMemberApproveMutationResult = Apollo.MutationResult<InterviewModuleMemberApproveMutation>;
export type InterviewModuleMemberApproveMutationOptions = Apollo.BaseMutationOptions<InterviewModuleMemberApproveMutation, InterviewModuleMemberApproveMutationVariables>;
export const InterviewModuleMembersDocument = gql`
    query InterviewModuleMembers($id: uuid!, $pageInput: PageInput!, $orderBy: InterviewModuleMemberSearchOrderBy!, $search: String, $filterByTrainingStatus: [TrainingStatus], $filterByState: [InterviewModuleMemberState], $isArchived: Boolean, $isAtsDisabled: Boolean, $isDirectoryDisabled: Boolean, $hasAtsId: Boolean, $useArchivedFilter: Boolean, $useAtsDisabledFilter: Boolean) {
  interviewModuleMemberSearch(
    input: {interviewModuleId: $id, pageInput: $pageInput, orderBy: $orderBy, search: $search, filterByTrainingStatus: $filterByTrainingStatus, filterByState: $filterByState, isArchived: $isArchived, isAtsDisabled: $isAtsDisabled, isDirectoryDisabled: $isDirectoryDisabled, hasAtsId: $hasAtsId, useArchivedFilter: $useArchivedFilter, useAtsDisabledFilter: $useAtsDisabledFilter}
  ) {
    total
    items {
      ...InterviewMember
    }
  }
}
    ${InterviewMemberFragmentDoc}`;

/**
 * __useInterviewModuleMembersQuery__
 *
 * To run a query within a React component, call `useInterviewModuleMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pageInput: // value for 'pageInput'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *      filterByTrainingStatus: // value for 'filterByTrainingStatus'
 *      filterByState: // value for 'filterByState'
 *      isArchived: // value for 'isArchived'
 *      isAtsDisabled: // value for 'isAtsDisabled'
 *      isDirectoryDisabled: // value for 'isDirectoryDisabled'
 *      hasAtsId: // value for 'hasAtsId'
 *      useArchivedFilter: // value for 'useArchivedFilter'
 *      useAtsDisabledFilter: // value for 'useAtsDisabledFilter'
 *   },
 * });
 */
export function useInterviewModuleMembersQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleMembersQuery, InterviewModuleMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMembersQuery, InterviewModuleMembersQueryVariables>(InterviewModuleMembersDocument, options);
      }
export function useInterviewModuleMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMembersQuery, InterviewModuleMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMembersQuery, InterviewModuleMembersQueryVariables>(InterviewModuleMembersDocument, options);
        }
export type InterviewModuleMembersQueryHookResult = ReturnType<typeof useInterviewModuleMembersQuery>;
export type InterviewModuleMembersLazyQueryHookResult = ReturnType<typeof useInterviewModuleMembersLazyQuery>;
export type InterviewModuleMembersQueryResult = Apollo.QueryResult<InterviewModuleMembersQuery, InterviewModuleMembersQueryVariables>;
export const InterviewModuleMemberNameDocument = gql`
    query InterviewModuleMemberName($interviewModuleId: uuid!, $employeeId: uuid!) {
  interviewModuleMember(
    interviewModuleId: $interviewModuleId
    employeeId: $employeeId
  ) {
    interviewModuleId
    employeeId
    employee {
      id
      fullName
    }
  }
}
    `;

/**
 * __useInterviewModuleMemberNameQuery__
 *
 * To run a query within a React component, call `useInterviewModuleMemberNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMemberNameQuery({
 *   variables: {
 *      interviewModuleId: // value for 'interviewModuleId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useInterviewModuleMemberNameQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleMemberNameQuery, InterviewModuleMemberNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMemberNameQuery, InterviewModuleMemberNameQueryVariables>(InterviewModuleMemberNameDocument, options);
      }
export function useInterviewModuleMemberNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMemberNameQuery, InterviewModuleMemberNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMemberNameQuery, InterviewModuleMemberNameQueryVariables>(InterviewModuleMemberNameDocument, options);
        }
export type InterviewModuleMemberNameQueryHookResult = ReturnType<typeof useInterviewModuleMemberNameQuery>;
export type InterviewModuleMemberNameLazyQueryHookResult = ReturnType<typeof useInterviewModuleMemberNameLazyQuery>;
export type InterviewModuleMemberNameQueryResult = Apollo.QueryResult<InterviewModuleMemberNameQuery, InterviewModuleMemberNameQueryVariables>;
export const EmployeesAndModulesSearchDocument = gql`
    query EmployeesAndModulesSearch($employeeSearchInput: EmployeeSearchInput!, $moduleSearchInput: InterviewModuleSearchInput!) {
  employeeSearch(input: $employeeSearchInput) {
    items {
      ...BaseEditInterviewSeatModuleEmployeeSearch
    }
  }
  interviewModuleSearch(input: $moduleSearchInput) {
    items {
      ...BaseEditInterviewSeatModuleInterviewModuleSearch
    }
  }
}
    ${BaseEditInterviewSeatModuleEmployeeSearchFragmentDoc}
${BaseEditInterviewSeatModuleInterviewModuleSearchFragmentDoc}`;

/**
 * __useEmployeesAndModulesSearchQuery__
 *
 * To run a query within a React component, call `useEmployeesAndModulesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesAndModulesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesAndModulesSearchQuery({
 *   variables: {
 *      employeeSearchInput: // value for 'employeeSearchInput'
 *      moduleSearchInput: // value for 'moduleSearchInput'
 *   },
 * });
 */
export function useEmployeesAndModulesSearchQuery(baseOptions: Apollo.QueryHookOptions<EmployeesAndModulesSearchQuery, EmployeesAndModulesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesAndModulesSearchQuery, EmployeesAndModulesSearchQueryVariables>(EmployeesAndModulesSearchDocument, options);
      }
export function useEmployeesAndModulesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesAndModulesSearchQuery, EmployeesAndModulesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesAndModulesSearchQuery, EmployeesAndModulesSearchQueryVariables>(EmployeesAndModulesSearchDocument, options);
        }
export type EmployeesAndModulesSearchQueryHookResult = ReturnType<typeof useEmployeesAndModulesSearchQuery>;
export type EmployeesAndModulesSearchLazyQueryHookResult = ReturnType<typeof useEmployeesAndModulesSearchLazyQuery>;
export type EmployeesAndModulesSearchQueryResult = Apollo.QueryResult<EmployeesAndModulesSearchQuery, EmployeesAndModulesSearchQueryVariables>;
export const InterviewModuleMemberFilteredByAttributesDocument = gql`
    query InterviewModuleMemberFilteredByAttributes($input: InterviewModuleMemberSearchInput!) {
  interviewModuleMemberSearch(input: $input) {
    items {
      ...EditModuleSeatInterviewModule
    }
  }
}
    ${EditModuleSeatInterviewModuleFragmentDoc}`;

/**
 * __useInterviewModuleMemberFilteredByAttributesQuery__
 *
 * To run a query within a React component, call `useInterviewModuleMemberFilteredByAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberFilteredByAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMemberFilteredByAttributesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewModuleMemberFilteredByAttributesQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleMemberFilteredByAttributesQuery, InterviewModuleMemberFilteredByAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMemberFilteredByAttributesQuery, InterviewModuleMemberFilteredByAttributesQueryVariables>(InterviewModuleMemberFilteredByAttributesDocument, options);
      }
export function useInterviewModuleMemberFilteredByAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMemberFilteredByAttributesQuery, InterviewModuleMemberFilteredByAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMemberFilteredByAttributesQuery, InterviewModuleMemberFilteredByAttributesQueryVariables>(InterviewModuleMemberFilteredByAttributesDocument, options);
        }
export type InterviewModuleMemberFilteredByAttributesQueryHookResult = ReturnType<typeof useInterviewModuleMemberFilteredByAttributesQuery>;
export type InterviewModuleMemberFilteredByAttributesLazyQueryHookResult = ReturnType<typeof useInterviewModuleMemberFilteredByAttributesLazyQuery>;
export type InterviewModuleMemberFilteredByAttributesQueryResult = Apollo.QueryResult<InterviewModuleMemberFilteredByAttributesQuery, InterviewModuleMemberFilteredByAttributesQueryVariables>;
export const ModuleMemberStatsDocument = gql`
    query ModuleMemberStats {
  interviewModuleMemberStats {
    interviewModule {
      ...EditModuleSeatInterviewStats
    }
  }
}
    ${EditModuleSeatInterviewStatsFragmentDoc}`;

/**
 * __useModuleMemberStatsQuery__
 *
 * To run a query within a React component, call `useModuleMemberStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModuleMemberStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModuleMemberStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useModuleMemberStatsQuery(baseOptions?: Apollo.QueryHookOptions<ModuleMemberStatsQuery, ModuleMemberStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModuleMemberStatsQuery, ModuleMemberStatsQueryVariables>(ModuleMemberStatsDocument, options);
      }
export function useModuleMemberStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModuleMemberStatsQuery, ModuleMemberStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModuleMemberStatsQuery, ModuleMemberStatsQueryVariables>(ModuleMemberStatsDocument, options);
        }
export type ModuleMemberStatsQueryHookResult = ReturnType<typeof useModuleMemberStatsQuery>;
export type ModuleMemberStatsLazyQueryHookResult = ReturnType<typeof useModuleMemberStatsLazyQuery>;
export type ModuleMemberStatsQueryResult = Apollo.QueryResult<ModuleMemberStatsQuery, ModuleMemberStatsQueryVariables>;
export const ResetJobStageToAtsInterviewPlanDocument = gql`
    mutation ResetJobStageToAtsInterviewPlan($input: ResetJobStageToAtsInterviewPlanInput!) {
  resetJobStageToAtsInterviewPlan(input: $input) {
    jobStage {
      id
    }
  }
}
    `;
export type ResetJobStageToAtsInterviewPlanMutationFn = Apollo.MutationFunction<ResetJobStageToAtsInterviewPlanMutation, ResetJobStageToAtsInterviewPlanMutationVariables>;

/**
 * __useResetJobStageToAtsInterviewPlanMutation__
 *
 * To run a mutation, you first call `useResetJobStageToAtsInterviewPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetJobStageToAtsInterviewPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetJobStageToAtsInterviewPlanMutation, { data, loading, error }] = useResetJobStageToAtsInterviewPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetJobStageToAtsInterviewPlanMutation(baseOptions?: Apollo.MutationHookOptions<ResetJobStageToAtsInterviewPlanMutation, ResetJobStageToAtsInterviewPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetJobStageToAtsInterviewPlanMutation, ResetJobStageToAtsInterviewPlanMutationVariables>(ResetJobStageToAtsInterviewPlanDocument, options);
      }
export type ResetJobStageToAtsInterviewPlanMutationHookResult = ReturnType<typeof useResetJobStageToAtsInterviewPlanMutation>;
export type ResetJobStageToAtsInterviewPlanMutationResult = Apollo.MutationResult<ResetJobStageToAtsInterviewPlanMutation>;
export type ResetJobStageToAtsInterviewPlanMutationOptions = Apollo.BaseMutationOptions<ResetJobStageToAtsInterviewPlanMutation, ResetJobStageToAtsInterviewPlanMutationVariables>;
export const InterviewPlanDocument = gql`
    query InterviewPlan($id: uuid!) {
  jobStage(id: $id) {
    ...JobStage_InterviewPlan
  }
}
    ${JobStage_InterviewPlanFragmentDoc}`;

/**
 * __useInterviewPlanQuery__
 *
 * To run a query within a React component, call `useInterviewPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInterviewPlanQuery(baseOptions: Apollo.QueryHookOptions<InterviewPlanQuery, InterviewPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewPlanQuery, InterviewPlanQueryVariables>(InterviewPlanDocument, options);
      }
export function useInterviewPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewPlanQuery, InterviewPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewPlanQuery, InterviewPlanQueryVariables>(InterviewPlanDocument, options);
        }
export type InterviewPlanQueryHookResult = ReturnType<typeof useInterviewPlanQuery>;
export type InterviewPlanLazyQueryHookResult = ReturnType<typeof useInterviewPlanLazyQuery>;
export type InterviewPlanQueryResult = Apollo.QueryResult<InterviewPlanQuery, InterviewPlanQueryVariables>;
export const JobStageAtsDetailsDocument = gql`
    query JobStageATSDetails($id: uuid!) {
  jobStage(id: $id) {
    id
    atsId
    job {
      id
      atsId
    }
  }
}
    `;

/**
 * __useJobStageAtsDetailsQuery__
 *
 * To run a query within a React component, call `useJobStageAtsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobStageAtsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobStageAtsDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobStageAtsDetailsQuery(baseOptions: Apollo.QueryHookOptions<JobStageAtsDetailsQuery, JobStageAtsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobStageAtsDetailsQuery, JobStageAtsDetailsQueryVariables>(JobStageAtsDetailsDocument, options);
      }
export function useJobStageAtsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobStageAtsDetailsQuery, JobStageAtsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobStageAtsDetailsQuery, JobStageAtsDetailsQueryVariables>(JobStageAtsDetailsDocument, options);
        }
export type JobStageAtsDetailsQueryHookResult = ReturnType<typeof useJobStageAtsDetailsQuery>;
export type JobStageAtsDetailsLazyQueryHookResult = ReturnType<typeof useJobStageAtsDetailsLazyQuery>;
export type JobStageAtsDetailsQueryResult = Apollo.QueryResult<JobStageAtsDetailsQuery, JobStageAtsDetailsQueryVariables>;
export const SaveJobStageInterviewPlanDocument = gql`
    mutation SaveJobStageInterviewPlan($input: JobStageInterviewPlanUpsertInput!) {
  jobStageInterviewPlanUpsert(input: $input) {
    jobStage {
      ...JobStage_InterviewPlan
    }
  }
}
    ${JobStage_InterviewPlanFragmentDoc}`;
export type SaveJobStageInterviewPlanMutationFn = Apollo.MutationFunction<SaveJobStageInterviewPlanMutation, SaveJobStageInterviewPlanMutationVariables>;

/**
 * __useSaveJobStageInterviewPlanMutation__
 *
 * To run a mutation, you first call `useSaveJobStageInterviewPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveJobStageInterviewPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveJobStageInterviewPlanMutation, { data, loading, error }] = useSaveJobStageInterviewPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveJobStageInterviewPlanMutation(baseOptions?: Apollo.MutationHookOptions<SaveJobStageInterviewPlanMutation, SaveJobStageInterviewPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveJobStageInterviewPlanMutation, SaveJobStageInterviewPlanMutationVariables>(SaveJobStageInterviewPlanDocument, options);
      }
export type SaveJobStageInterviewPlanMutationHookResult = ReturnType<typeof useSaveJobStageInterviewPlanMutation>;
export type SaveJobStageInterviewPlanMutationResult = Apollo.MutationResult<SaveJobStageInterviewPlanMutation>;
export type SaveJobStageInterviewPlanMutationOptions = Apollo.BaseMutationOptions<SaveJobStageInterviewPlanMutation, SaveJobStageInterviewPlanMutationVariables>;
export const AddModuleToInterviewerDocument = gql`
    mutation AddModuleToInterviewer($input: InterviewModuleAddMembersInput!) {
  interviewModuleAddMembers(input: $input) {
    interviewModule {
      id
    }
  }
}
    `;
export type AddModuleToInterviewerMutationFn = Apollo.MutationFunction<AddModuleToInterviewerMutation, AddModuleToInterviewerMutationVariables>;

/**
 * __useAddModuleToInterviewerMutation__
 *
 * To run a mutation, you first call `useAddModuleToInterviewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddModuleToInterviewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addModuleToInterviewerMutation, { data, loading, error }] = useAddModuleToInterviewerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddModuleToInterviewerMutation(baseOptions?: Apollo.MutationHookOptions<AddModuleToInterviewerMutation, AddModuleToInterviewerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddModuleToInterviewerMutation, AddModuleToInterviewerMutationVariables>(AddModuleToInterviewerDocument, options);
      }
export type AddModuleToInterviewerMutationHookResult = ReturnType<typeof useAddModuleToInterviewerMutation>;
export type AddModuleToInterviewerMutationResult = Apollo.MutationResult<AddModuleToInterviewerMutation>;
export type AddModuleToInterviewerMutationOptions = Apollo.BaseMutationOptions<AddModuleToInterviewerMutation, AddModuleToInterviewerMutationVariables>;
export const InterviewerInterviewModuleMemberLookupDocument = gql`
    query InterviewerInterviewModuleMemberLookup($memberIds: [uuid!]!) {
  interviewModuleMemberLookup(input: {interviewModuleMemberIds: $memberIds}) {
    items {
      interviewModuleId
      employeeId
      ...Modules_memberModules
    }
  }
}
    ${Modules_MemberModulesFragmentDoc}`;

/**
 * __useInterviewerInterviewModuleMemberLookupQuery__
 *
 * To run a query within a React component, call `useInterviewerInterviewModuleMemberLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewerInterviewModuleMemberLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewerInterviewModuleMemberLookupQuery({
 *   variables: {
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useInterviewerInterviewModuleMemberLookupQuery(baseOptions: Apollo.QueryHookOptions<InterviewerInterviewModuleMemberLookupQuery, InterviewerInterviewModuleMemberLookupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewerInterviewModuleMemberLookupQuery, InterviewerInterviewModuleMemberLookupQueryVariables>(InterviewerInterviewModuleMemberLookupDocument, options);
      }
export function useInterviewerInterviewModuleMemberLookupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewerInterviewModuleMemberLookupQuery, InterviewerInterviewModuleMemberLookupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewerInterviewModuleMemberLookupQuery, InterviewerInterviewModuleMemberLookupQueryVariables>(InterviewerInterviewModuleMemberLookupDocument, options);
        }
export type InterviewerInterviewModuleMemberLookupQueryHookResult = ReturnType<typeof useInterviewerInterviewModuleMemberLookupQuery>;
export type InterviewerInterviewModuleMemberLookupLazyQueryHookResult = ReturnType<typeof useInterviewerInterviewModuleMemberLookupLazyQuery>;
export type InterviewerInterviewModuleMemberLookupQueryResult = Apollo.QueryResult<InterviewerInterviewModuleMemberLookupQuery, InterviewerInterviewModuleMemberLookupQueryVariables>;
export const InterviewerInterviewModulesLookupDocument = gql`
    query InterviewerInterviewModulesLookup($memberIds: [uuid!]!) {
  interviewModuleMemberLookup(input: {interviewModuleMemberIds: $memberIds}) {
    items {
      ...InterviewMember
      interviewModule {
        ...MemberCardInterviewModule
      }
    }
  }
}
    ${InterviewMemberFragmentDoc}
${MemberCardInterviewModuleFragmentDoc}`;

/**
 * __useInterviewerInterviewModulesLookupQuery__
 *
 * To run a query within a React component, call `useInterviewerInterviewModulesLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewerInterviewModulesLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewerInterviewModulesLookupQuery({
 *   variables: {
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useInterviewerInterviewModulesLookupQuery(baseOptions: Apollo.QueryHookOptions<InterviewerInterviewModulesLookupQuery, InterviewerInterviewModulesLookupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewerInterviewModulesLookupQuery, InterviewerInterviewModulesLookupQueryVariables>(InterviewerInterviewModulesLookupDocument, options);
      }
export function useInterviewerInterviewModulesLookupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewerInterviewModulesLookupQuery, InterviewerInterviewModulesLookupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewerInterviewModulesLookupQuery, InterviewerInterviewModulesLookupQueryVariables>(InterviewerInterviewModulesLookupDocument, options);
        }
export type InterviewerInterviewModulesLookupQueryHookResult = ReturnType<typeof useInterviewerInterviewModulesLookupQuery>;
export type InterviewerInterviewModulesLookupLazyQueryHookResult = ReturnType<typeof useInterviewerInterviewModulesLookupLazyQuery>;
export type InterviewerInterviewModulesLookupQueryResult = Apollo.QueryResult<InterviewerInterviewModulesLookupQuery, InterviewerInterviewModulesLookupQueryVariables>;
export const AboutSectionEditDialogDocument = gql`
    query AboutSectionEditDialog($input: EmployeeAttributeNamesInput!) {
  employeeAttributeNames(input: $input) {
    items {
      id
      name
      type
      values {
        id
        name: value
        parent: name {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAboutSectionEditDialogQuery__
 *
 * To run a query within a React component, call `useAboutSectionEditDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutSectionEditDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutSectionEditDialogQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAboutSectionEditDialogQuery(baseOptions: Apollo.QueryHookOptions<AboutSectionEditDialogQuery, AboutSectionEditDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AboutSectionEditDialogQuery, AboutSectionEditDialogQueryVariables>(AboutSectionEditDialogDocument, options);
      }
export function useAboutSectionEditDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AboutSectionEditDialogQuery, AboutSectionEditDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AboutSectionEditDialogQuery, AboutSectionEditDialogQueryVariables>(AboutSectionEditDialogDocument, options);
        }
export type AboutSectionEditDialogQueryHookResult = ReturnType<typeof useAboutSectionEditDialogQuery>;
export type AboutSectionEditDialogLazyQueryHookResult = ReturnType<typeof useAboutSectionEditDialogLazyQuery>;
export type AboutSectionEditDialogQueryResult = Apollo.QueryResult<AboutSectionEditDialogQuery, AboutSectionEditDialogQueryVariables>;
export const InterviewerUpdateDocument = gql`
    mutation InterviewerUpdate($input: EmployeeUpdateInput!) {
  employeeUpdate(input: $input) {
    employee {
      ...InterviewerAboutSection_interviewer
    }
  }
}
    ${InterviewerAboutSection_InterviewerFragmentDoc}`;
export type InterviewerUpdateMutationFn = Apollo.MutationFunction<InterviewerUpdateMutation, InterviewerUpdateMutationVariables>;

/**
 * __useInterviewerUpdateMutation__
 *
 * To run a mutation, you first call `useInterviewerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewerUpdateMutation, { data, loading, error }] = useInterviewerUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewerUpdateMutation(baseOptions?: Apollo.MutationHookOptions<InterviewerUpdateMutation, InterviewerUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewerUpdateMutation, InterviewerUpdateMutationVariables>(InterviewerUpdateDocument, options);
      }
export type InterviewerUpdateMutationHookResult = ReturnType<typeof useInterviewerUpdateMutation>;
export type InterviewerUpdateMutationResult = Apollo.MutationResult<InterviewerUpdateMutation>;
export type InterviewerUpdateMutationOptions = Apollo.BaseMutationOptions<InterviewerUpdateMutation, InterviewerUpdateMutationVariables>;
export const InterviewerUpdateAttributesDocument = gql`
    mutation InterviewerUpdateAttributes($input: EmployeeAttributesInput!) {
  employeeAttributesUpsert(input: $input) {
    employee {
      ...InterviewerAboutSection_interviewer
    }
  }
}
    ${InterviewerAboutSection_InterviewerFragmentDoc}`;
export type InterviewerUpdateAttributesMutationFn = Apollo.MutationFunction<InterviewerUpdateAttributesMutation, InterviewerUpdateAttributesMutationVariables>;

/**
 * __useInterviewerUpdateAttributesMutation__
 *
 * To run a mutation, you first call `useInterviewerUpdateAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewerUpdateAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewerUpdateAttributesMutation, { data, loading, error }] = useInterviewerUpdateAttributesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewerUpdateAttributesMutation(baseOptions?: Apollo.MutationHookOptions<InterviewerUpdateAttributesMutation, InterviewerUpdateAttributesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewerUpdateAttributesMutation, InterviewerUpdateAttributesMutationVariables>(InterviewerUpdateAttributesDocument, options);
      }
export type InterviewerUpdateAttributesMutationHookResult = ReturnType<typeof useInterviewerUpdateAttributesMutation>;
export type InterviewerUpdateAttributesMutationResult = Apollo.MutationResult<InterviewerUpdateAttributesMutation>;
export type InterviewerUpdateAttributesMutationOptions = Apollo.BaseMutationOptions<InterviewerUpdateAttributesMutation, InterviewerUpdateAttributesMutationVariables>;
export const AtsUserEmployeeAssociationUpdateDocument = gql`
    mutation AtsUserEmployeeAssociationUpdate($input: AtsUserEmployeeAssociationUpdateInput!) {
  atsUserEmployeeAssociationUpdate(input: $input) {
    atsUser {
      ...AtsUser
    }
  }
}
    ${AtsUserFragmentDoc}`;
export type AtsUserEmployeeAssociationUpdateMutationFn = Apollo.MutationFunction<AtsUserEmployeeAssociationUpdateMutation, AtsUserEmployeeAssociationUpdateMutationVariables>;

/**
 * __useAtsUserEmployeeAssociationUpdateMutation__
 *
 * To run a mutation, you first call `useAtsUserEmployeeAssociationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAtsUserEmployeeAssociationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [atsUserEmployeeAssociationUpdateMutation, { data, loading, error }] = useAtsUserEmployeeAssociationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAtsUserEmployeeAssociationUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AtsUserEmployeeAssociationUpdateMutation, AtsUserEmployeeAssociationUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AtsUserEmployeeAssociationUpdateMutation, AtsUserEmployeeAssociationUpdateMutationVariables>(AtsUserEmployeeAssociationUpdateDocument, options);
      }
export type AtsUserEmployeeAssociationUpdateMutationHookResult = ReturnType<typeof useAtsUserEmployeeAssociationUpdateMutation>;
export type AtsUserEmployeeAssociationUpdateMutationResult = Apollo.MutationResult<AtsUserEmployeeAssociationUpdateMutation>;
export type AtsUserEmployeeAssociationUpdateMutationOptions = Apollo.BaseMutationOptions<AtsUserEmployeeAssociationUpdateMutation, AtsUserEmployeeAssociationUpdateMutationVariables>;
export const AttributesSectionEditDialogDocument = gql`
    query AttributesSectionEditDialog($input: EmployeeAttributeNamesInput!) {
  employeeAttributeNames(input: $input) {
    items {
      ...AttributeEditRow_employeeAttributeParent
    }
  }
}
    ${AttributeEditRow_EmployeeAttributeParentFragmentDoc}`;

/**
 * __useAttributesSectionEditDialogQuery__
 *
 * To run a query within a React component, call `useAttributesSectionEditDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributesSectionEditDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributesSectionEditDialogQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttributesSectionEditDialogQuery(baseOptions: Apollo.QueryHookOptions<AttributesSectionEditDialogQuery, AttributesSectionEditDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttributesSectionEditDialogQuery, AttributesSectionEditDialogQueryVariables>(AttributesSectionEditDialogDocument, options);
      }
export function useAttributesSectionEditDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttributesSectionEditDialogQuery, AttributesSectionEditDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttributesSectionEditDialogQuery, AttributesSectionEditDialogQueryVariables>(AttributesSectionEditDialogDocument, options);
        }
export type AttributesSectionEditDialogQueryHookResult = ReturnType<typeof useAttributesSectionEditDialogQuery>;
export type AttributesSectionEditDialogLazyQueryHookResult = ReturnType<typeof useAttributesSectionEditDialogLazyQuery>;
export type AttributesSectionEditDialogQueryResult = Apollo.QueryResult<AttributesSectionEditDialogQuery, AttributesSectionEditDialogQueryVariables>;
export const AttributeSectionDocument = gql`
    query AttributeSection($input: EmployeeAttributeNamesInput!) {
  employeeAttributeNames(input: $input) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useAttributeSectionQuery__
 *
 * To run a query within a React component, call `useAttributeSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributeSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributeSectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttributeSectionQuery(baseOptions: Apollo.QueryHookOptions<AttributeSectionQuery, AttributeSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttributeSectionQuery, AttributeSectionQueryVariables>(AttributeSectionDocument, options);
      }
export function useAttributeSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttributeSectionQuery, AttributeSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttributeSectionQuery, AttributeSectionQueryVariables>(AttributeSectionDocument, options);
        }
export type AttributeSectionQueryHookResult = ReturnType<typeof useAttributeSectionQuery>;
export type AttributeSectionLazyQueryHookResult = ReturnType<typeof useAttributeSectionLazyQuery>;
export type AttributeSectionQueryResult = Apollo.QueryResult<AttributeSectionQuery, AttributeSectionQueryVariables>;
export const AvailabilitySectionSaveDocument = gql`
    mutation AvailabilitySectionSave($input: EmployeeUpdateInput!, $date: date!, $timezone: String!) {
  employeeUpdate(input: $input) {
    employee {
      ...AvailabilitySection_interviewer
    }
  }
}
    ${AvailabilitySection_InterviewerFragmentDoc}`;
export type AvailabilitySectionSaveMutationFn = Apollo.MutationFunction<AvailabilitySectionSaveMutation, AvailabilitySectionSaveMutationVariables>;

/**
 * __useAvailabilitySectionSaveMutation__
 *
 * To run a mutation, you first call `useAvailabilitySectionSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilitySectionSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilitySectionSaveMutation, { data, loading, error }] = useAvailabilitySectionSaveMutation({
 *   variables: {
 *      input: // value for 'input'
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useAvailabilitySectionSaveMutation(baseOptions?: Apollo.MutationHookOptions<AvailabilitySectionSaveMutation, AvailabilitySectionSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AvailabilitySectionSaveMutation, AvailabilitySectionSaveMutationVariables>(AvailabilitySectionSaveDocument, options);
      }
export type AvailabilitySectionSaveMutationHookResult = ReturnType<typeof useAvailabilitySectionSaveMutation>;
export type AvailabilitySectionSaveMutationResult = Apollo.MutationResult<AvailabilitySectionSaveMutation>;
export type AvailabilitySectionSaveMutationOptions = Apollo.BaseMutationOptions<AvailabilitySectionSaveMutation, AvailabilitySectionSaveMutationVariables>;
export const ExternalProfileUpdateInterviewerDocument = gql`
    mutation ExternalProfileUpdateInterviewer($pronounsInput: EmployeePrefUpdateInput!, $profileImageInput: EmployeePrefUpdateInput!, $bioInput: EmployeePrefUpdateInput!, $input: EmployeeUpdateInput!) {
  bioUpdate: employeePrefUpdate(input: $bioInput) {
    employeePref {
      employeeId
      orgId
      prefName
    }
  }
  pronounsUpdate: employeePrefUpdate(input: $pronounsInput) {
    employeePref {
      employeeId
      orgId
      prefName
    }
  }
  profileImageUpdate: employeePrefUpdate(input: $profileImageInput) {
    employeePref {
      employeeId
      orgId
      prefName
    }
  }
  employeeUpdate(input: $input) {
    employee {
      ...ExternalProfileModalInterviewer
    }
  }
}
    ${ExternalProfileModalInterviewerFragmentDoc}`;
export type ExternalProfileUpdateInterviewerMutationFn = Apollo.MutationFunction<ExternalProfileUpdateInterviewerMutation, ExternalProfileUpdateInterviewerMutationVariables>;

/**
 * __useExternalProfileUpdateInterviewerMutation__
 *
 * To run a mutation, you first call `useExternalProfileUpdateInterviewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalProfileUpdateInterviewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalProfileUpdateInterviewerMutation, { data, loading, error }] = useExternalProfileUpdateInterviewerMutation({
 *   variables: {
 *      pronounsInput: // value for 'pronounsInput'
 *      profileImageInput: // value for 'profileImageInput'
 *      bioInput: // value for 'bioInput'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExternalProfileUpdateInterviewerMutation(baseOptions?: Apollo.MutationHookOptions<ExternalProfileUpdateInterviewerMutation, ExternalProfileUpdateInterviewerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExternalProfileUpdateInterviewerMutation, ExternalProfileUpdateInterviewerMutationVariables>(ExternalProfileUpdateInterviewerDocument, options);
      }
export type ExternalProfileUpdateInterviewerMutationHookResult = ReturnType<typeof useExternalProfileUpdateInterviewerMutation>;
export type ExternalProfileUpdateInterviewerMutationResult = Apollo.MutationResult<ExternalProfileUpdateInterviewerMutation>;
export type ExternalProfileUpdateInterviewerMutationOptions = Apollo.BaseMutationOptions<ExternalProfileUpdateInterviewerMutation, ExternalProfileUpdateInterviewerMutationVariables>;
export const EmployeeKeywordsDocument = gql`
    query EmployeeKeywords($employeeId: uuid!, $orgKeywordNames: [String!]!, $employeeKeywordNames: [String!]!) {
  thisOrg {
    id
    orgPrefByNames(prefNames: $orgKeywordNames) {
      orgId
      prefName
      stringArray
    }
  }
  employee(id: $employeeId) {
    id
    employeePrefByNames(prefNames: $employeeKeywordNames) {
      orgId
      employeeId
      prefName
      stringArray
    }
  }
}
    `;

/**
 * __useEmployeeKeywordsQuery__
 *
 * To run a query within a React component, call `useEmployeeKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeKeywordsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      orgKeywordNames: // value for 'orgKeywordNames'
 *      employeeKeywordNames: // value for 'employeeKeywordNames'
 *   },
 * });
 */
export function useEmployeeKeywordsQuery(baseOptions: Apollo.QueryHookOptions<EmployeeKeywordsQuery, EmployeeKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeKeywordsQuery, EmployeeKeywordsQueryVariables>(EmployeeKeywordsDocument, options);
      }
export function useEmployeeKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeKeywordsQuery, EmployeeKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeKeywordsQuery, EmployeeKeywordsQueryVariables>(EmployeeKeywordsDocument, options);
        }
export type EmployeeKeywordsQueryHookResult = ReturnType<typeof useEmployeeKeywordsQuery>;
export type EmployeeKeywordsLazyQueryHookResult = ReturnType<typeof useEmployeeKeywordsLazyQuery>;
export type EmployeeKeywordsQueryResult = Apollo.QueryResult<EmployeeKeywordsQuery, EmployeeKeywordsQueryVariables>;
export const DeletePlannedAvailabilityDocument = gql`
    mutation DeletePlannedAvailability($input: EmployeePlannedAvailabilityDeleteInput!) {
  employeePlannedAvailabilityDelete(input: $input) {
    result
  }
}
    `;
export type DeletePlannedAvailabilityMutationFn = Apollo.MutationFunction<DeletePlannedAvailabilityMutation, DeletePlannedAvailabilityMutationVariables>;

/**
 * __useDeletePlannedAvailabilityMutation__
 *
 * To run a mutation, you first call `useDeletePlannedAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlannedAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlannedAvailabilityMutation, { data, loading, error }] = useDeletePlannedAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlannedAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlannedAvailabilityMutation, DeletePlannedAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlannedAvailabilityMutation, DeletePlannedAvailabilityMutationVariables>(DeletePlannedAvailabilityDocument, options);
      }
export type DeletePlannedAvailabilityMutationHookResult = ReturnType<typeof useDeletePlannedAvailabilityMutation>;
export type DeletePlannedAvailabilityMutationResult = Apollo.MutationResult<DeletePlannedAvailabilityMutation>;
export type DeletePlannedAvailabilityMutationOptions = Apollo.BaseMutationOptions<DeletePlannedAvailabilityMutation, DeletePlannedAvailabilityMutationVariables>;
export const CreatePlannedAvailabilityDocument = gql`
    mutation CreatePlannedAvailability($input: EmployeePlannedAvailabilityCreateInput!) {
  employeePlannedAvailabilityCreate(input: $input) {
    item {
      ...TemporaryAvailabilityDialog_plannedAvailability
    }
  }
}
    ${TemporaryAvailabilityDialog_PlannedAvailabilityFragmentDoc}`;
export type CreatePlannedAvailabilityMutationFn = Apollo.MutationFunction<CreatePlannedAvailabilityMutation, CreatePlannedAvailabilityMutationVariables>;

/**
 * __useCreatePlannedAvailabilityMutation__
 *
 * To run a mutation, you first call `useCreatePlannedAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlannedAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlannedAvailabilityMutation, { data, loading, error }] = useCreatePlannedAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlannedAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlannedAvailabilityMutation, CreatePlannedAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlannedAvailabilityMutation, CreatePlannedAvailabilityMutationVariables>(CreatePlannedAvailabilityDocument, options);
      }
export type CreatePlannedAvailabilityMutationHookResult = ReturnType<typeof useCreatePlannedAvailabilityMutation>;
export type CreatePlannedAvailabilityMutationResult = Apollo.MutationResult<CreatePlannedAvailabilityMutation>;
export type CreatePlannedAvailabilityMutationOptions = Apollo.BaseMutationOptions<CreatePlannedAvailabilityMutation, CreatePlannedAvailabilityMutationVariables>;
export const UpdatePlannedAvailabilityDocument = gql`
    mutation UpdatePlannedAvailability($input: EmployeePlannedAvailabilityUpdateInput!) {
  employeePlannedAvailabilityUpdate(input: $input) {
    item {
      ...TemporaryAvailabilityDialog_plannedAvailability
    }
  }
}
    ${TemporaryAvailabilityDialog_PlannedAvailabilityFragmentDoc}`;
export type UpdatePlannedAvailabilityMutationFn = Apollo.MutationFunction<UpdatePlannedAvailabilityMutation, UpdatePlannedAvailabilityMutationVariables>;

/**
 * __useUpdatePlannedAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdatePlannedAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlannedAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlannedAvailabilityMutation, { data, loading, error }] = useUpdatePlannedAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlannedAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlannedAvailabilityMutation, UpdatePlannedAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlannedAvailabilityMutation, UpdatePlannedAvailabilityMutationVariables>(UpdatePlannedAvailabilityDocument, options);
      }
export type UpdatePlannedAvailabilityMutationHookResult = ReturnType<typeof useUpdatePlannedAvailabilityMutation>;
export type UpdatePlannedAvailabilityMutationResult = Apollo.MutationResult<UpdatePlannedAvailabilityMutation>;
export type UpdatePlannedAvailabilityMutationOptions = Apollo.BaseMutationOptions<UpdatePlannedAvailabilityMutation, UpdatePlannedAvailabilityMutationVariables>;
export const UpdatePauseDatesDocument = gql`
    mutation UpdatePauseDates($input: EmployeeUpdateInput!) {
  employeeUpdate(input: $input) {
    employee {
      ...PlannedAvailabilitySection_interviewer
      isPaused
    }
  }
}
    ${PlannedAvailabilitySection_InterviewerFragmentDoc}`;
export type UpdatePauseDatesMutationFn = Apollo.MutationFunction<UpdatePauseDatesMutation, UpdatePauseDatesMutationVariables>;

/**
 * __useUpdatePauseDatesMutation__
 *
 * To run a mutation, you first call `useUpdatePauseDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePauseDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePauseDatesMutation, { data, loading, error }] = useUpdatePauseDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePauseDatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePauseDatesMutation, UpdatePauseDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePauseDatesMutation, UpdatePauseDatesMutationVariables>(UpdatePauseDatesDocument, options);
      }
export type UpdatePauseDatesMutationHookResult = ReturnType<typeof useUpdatePauseDatesMutation>;
export type UpdatePauseDatesMutationResult = Apollo.MutationResult<UpdatePauseDatesMutation>;
export type UpdatePauseDatesMutationOptions = Apollo.BaseMutationOptions<UpdatePauseDatesMutation, UpdatePauseDatesMutationVariables>;
export const InterviewsTabInterviewerDocument = gql`
    query InterviewsTabInterviewer($id: uuid!, $input: EmployeeApplicationStageInterviewsInput!) {
  employee(id: $id) {
    id
    ...InterviewsTab_interviewer
  }
}
    ${InterviewsTab_InterviewerFragmentDoc}`;

/**
 * __useInterviewsTabInterviewerQuery__
 *
 * To run a query within a React component, call `useInterviewsTabInterviewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewsTabInterviewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewsTabInterviewerQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewsTabInterviewerQuery(baseOptions: Apollo.QueryHookOptions<InterviewsTabInterviewerQuery, InterviewsTabInterviewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewsTabInterviewerQuery, InterviewsTabInterviewerQueryVariables>(InterviewsTabInterviewerDocument, options);
      }
export function useInterviewsTabInterviewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewsTabInterviewerQuery, InterviewsTabInterviewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewsTabInterviewerQuery, InterviewsTabInterviewerQueryVariables>(InterviewsTabInterviewerDocument, options);
        }
export type InterviewsTabInterviewerQueryHookResult = ReturnType<typeof useInterviewsTabInterviewerQuery>;
export type InterviewsTabInterviewerLazyQueryHookResult = ReturnType<typeof useInterviewsTabInterviewerLazyQuery>;
export type InterviewsTabInterviewerQueryResult = Apollo.QueryResult<InterviewsTabInterviewerQuery, InterviewsTabInterviewerQueryVariables>;
export const EmployeeDetailsViewDocument = gql`
    query EmployeeDetailsView($id: uuid!, $date: date!, $timezone: String!) {
  employee(id: $id) {
    ...InterviewerInfoContainer_employee
    ...InterviewModulesTab_employee
    ...EmployeeDetailsFragment
  }
}
    ${InterviewerInfoContainer_EmployeeFragmentDoc}
${InterviewModulesTab_EmployeeFragmentDoc}
${EmployeeDetailsFragmentFragmentDoc}`;

/**
 * __useEmployeeDetailsViewQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailsViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useEmployeeDetailsViewQuery(baseOptions: Apollo.QueryHookOptions<EmployeeDetailsViewQuery, EmployeeDetailsViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeDetailsViewQuery, EmployeeDetailsViewQueryVariables>(EmployeeDetailsViewDocument, options);
      }
export function useEmployeeDetailsViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeDetailsViewQuery, EmployeeDetailsViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeDetailsViewQuery, EmployeeDetailsViewQueryVariables>(EmployeeDetailsViewDocument, options);
        }
export type EmployeeDetailsViewQueryHookResult = ReturnType<typeof useEmployeeDetailsViewQuery>;
export type EmployeeDetailsViewLazyQueryHookResult = ReturnType<typeof useEmployeeDetailsViewLazyQuery>;
export type EmployeeDetailsViewQueryResult = Apollo.QueryResult<EmployeeDetailsViewQuery, EmployeeDetailsViewQueryVariables>;
export const CandidatePortalPreviewDocument = gql`
    query CandidatePortalPreview($jobId: uuid!) {
  job(id: $jobId) {
    id
    name
    jobStages {
      ...PortalPreviewByJobStage_jobStages
    }
    jobSettings {
      recruitingTeamContactInfoSetting {
        ...PortalPreviewByJobStage_recruitingTeamContactInfo
      }
    }
  }
  brandingThemes {
    items {
      id
      isOrgDefault
      ...PortalPreviewByJobStage_brandingTheme
    }
  }
}
    ${PortalPreviewByJobStage_JobStagesFragmentDoc}
${PortalPreviewByJobStage_RecruitingTeamContactInfoFragmentDoc}
${PortalPreviewByJobStage_BrandingThemeFragmentDoc}`;

/**
 * __useCandidatePortalPreviewQuery__
 *
 * To run a query within a React component, call `useCandidatePortalPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidatePortalPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidatePortalPreviewQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useCandidatePortalPreviewQuery(baseOptions: Apollo.QueryHookOptions<CandidatePortalPreviewQuery, CandidatePortalPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidatePortalPreviewQuery, CandidatePortalPreviewQueryVariables>(CandidatePortalPreviewDocument, options);
      }
export function useCandidatePortalPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidatePortalPreviewQuery, CandidatePortalPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidatePortalPreviewQuery, CandidatePortalPreviewQueryVariables>(CandidatePortalPreviewDocument, options);
        }
export type CandidatePortalPreviewQueryHookResult = ReturnType<typeof useCandidatePortalPreviewQuery>;
export type CandidatePortalPreviewLazyQueryHookResult = ReturnType<typeof useCandidatePortalPreviewLazyQuery>;
export type CandidatePortalPreviewQueryResult = Apollo.QueryResult<CandidatePortalPreviewQuery, CandidatePortalPreviewQueryVariables>;
export const VisibilitySelectJobStagesDocument = gql`
    query VisibilitySelectJobStages($input: JobStagesInput!) {
  jobStages(input: $input) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useVisibilitySelectJobStagesQuery__
 *
 * To run a query within a React component, call `useVisibilitySelectJobStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisibilitySelectJobStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisibilitySelectJobStagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVisibilitySelectJobStagesQuery(baseOptions: Apollo.QueryHookOptions<VisibilitySelectJobStagesQuery, VisibilitySelectJobStagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisibilitySelectJobStagesQuery, VisibilitySelectJobStagesQueryVariables>(VisibilitySelectJobStagesDocument, options);
      }
export function useVisibilitySelectJobStagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisibilitySelectJobStagesQuery, VisibilitySelectJobStagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisibilitySelectJobStagesQuery, VisibilitySelectJobStagesQueryVariables>(VisibilitySelectJobStagesDocument, options);
        }
export type VisibilitySelectJobStagesQueryHookResult = ReturnType<typeof useVisibilitySelectJobStagesQuery>;
export type VisibilitySelectJobStagesLazyQueryHookResult = ReturnType<typeof useVisibilitySelectJobStagesLazyQuery>;
export type VisibilitySelectJobStagesQueryResult = Apollo.QueryResult<VisibilitySelectJobStagesQuery, VisibilitySelectJobStagesQueryVariables>;
export const JobPortalOptionsDocument = gql`
    query JobPortalOptions($id: uuid!) {
  job(id: $id) {
    id
    name
    fallbackName
    ...Settings_job
    settings: portalJobOptions {
      id
      ...Settings_portalJobOptions
    }
    customTabs: portalJobOptions {
      id
      tabs {
        ...CustomTabs_customTabs
      }
    }
  }
}
    ${Settings_JobFragmentDoc}
${Settings_PortalJobOptionsFragmentDoc}
${CustomTabs_CustomTabsFragmentDoc}`;

/**
 * __useJobPortalOptionsQuery__
 *
 * To run a query within a React component, call `useJobPortalOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPortalOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPortalOptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobPortalOptionsQuery(baseOptions: Apollo.QueryHookOptions<JobPortalOptionsQuery, JobPortalOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobPortalOptionsQuery, JobPortalOptionsQueryVariables>(JobPortalOptionsDocument, options);
      }
export function useJobPortalOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobPortalOptionsQuery, JobPortalOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobPortalOptionsQuery, JobPortalOptionsQueryVariables>(JobPortalOptionsDocument, options);
        }
export type JobPortalOptionsQueryHookResult = ReturnType<typeof useJobPortalOptionsQuery>;
export type JobPortalOptionsLazyQueryHookResult = ReturnType<typeof useJobPortalOptionsLazyQuery>;
export type JobPortalOptionsQueryResult = Apollo.QueryResult<JobPortalOptionsQuery, JobPortalOptionsQueryVariables>;
export const JobPortalOptionMutationDocument = gql`
    mutation JobPortalOptionMutation($input: PortalJobOptionsUpsertInput!) {
  portalJobOptionsUpsert(input: $input) {
    options {
      id
      ...Settings_portalJobOptions
      tabs {
        ...CustomTabs_customTabs
      }
    }
  }
}
    ${Settings_PortalJobOptionsFragmentDoc}
${CustomTabs_CustomTabsFragmentDoc}`;
export type JobPortalOptionMutationMutationFn = Apollo.MutationFunction<JobPortalOptionMutationMutation, JobPortalOptionMutationMutationVariables>;

/**
 * __useJobPortalOptionMutationMutation__
 *
 * To run a mutation, you first call `useJobPortalOptionMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobPortalOptionMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobPortalOptionMutationMutation, { data, loading, error }] = useJobPortalOptionMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobPortalOptionMutationMutation(baseOptions?: Apollo.MutationHookOptions<JobPortalOptionMutationMutation, JobPortalOptionMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobPortalOptionMutationMutation, JobPortalOptionMutationMutationVariables>(JobPortalOptionMutationDocument, options);
      }
export type JobPortalOptionMutationMutationHookResult = ReturnType<typeof useJobPortalOptionMutationMutation>;
export type JobPortalOptionMutationMutationResult = Apollo.MutationResult<JobPortalOptionMutationMutation>;
export type JobPortalOptionMutationMutationOptions = Apollo.BaseMutationOptions<JobPortalOptionMutationMutation, JobPortalOptionMutationMutationVariables>;
export const JobNameUpdateDocument = gql`
    mutation jobNameUpdate($input: JobUpdateInput!) {
  jobUpdate(input: $input) {
    job {
      id
      ...ManageJobDisplayNameModal_job
    }
  }
}
    ${ManageJobDisplayNameModal_JobFragmentDoc}`;
export type JobNameUpdateMutationFn = Apollo.MutationFunction<JobNameUpdateMutation, JobNameUpdateMutationVariables>;

/**
 * __useJobNameUpdateMutation__
 *
 * To run a mutation, you first call `useJobNameUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobNameUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobNameUpdateMutation, { data, loading, error }] = useJobNameUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobNameUpdateMutation(baseOptions?: Apollo.MutationHookOptions<JobNameUpdateMutation, JobNameUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobNameUpdateMutation, JobNameUpdateMutationVariables>(JobNameUpdateDocument, options);
      }
export type JobNameUpdateMutationHookResult = ReturnType<typeof useJobNameUpdateMutation>;
export type JobNameUpdateMutationResult = Apollo.MutationResult<JobNameUpdateMutation>;
export type JobNameUpdateMutationOptions = Apollo.BaseMutationOptions<JobNameUpdateMutation, JobNameUpdateMutationVariables>;
export const JobDetailsPrivacyDocument = gql`
    query JobDetailsPrivacy($id: uuid!) {
  job(id: $id) {
    id
    jobSettings {
      ...JobDetailsPrivacy_jobSettings
    }
    name
    fallbackName
  }
}
    ${JobDetailsPrivacy_JobSettingsFragmentDoc}`;

/**
 * __useJobDetailsPrivacyQuery__
 *
 * To run a query within a React component, call `useJobDetailsPrivacyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobDetailsPrivacyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobDetailsPrivacyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobDetailsPrivacyQuery(baseOptions: Apollo.QueryHookOptions<JobDetailsPrivacyQuery, JobDetailsPrivacyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobDetailsPrivacyQuery, JobDetailsPrivacyQueryVariables>(JobDetailsPrivacyDocument, options);
      }
export function useJobDetailsPrivacyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobDetailsPrivacyQuery, JobDetailsPrivacyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobDetailsPrivacyQuery, JobDetailsPrivacyQueryVariables>(JobDetailsPrivacyDocument, options);
        }
export type JobDetailsPrivacyQueryHookResult = ReturnType<typeof useJobDetailsPrivacyQuery>;
export type JobDetailsPrivacyLazyQueryHookResult = ReturnType<typeof useJobDetailsPrivacyLazyQuery>;
export type JobDetailsPrivacyQueryResult = Apollo.QueryResult<JobDetailsPrivacyQuery, JobDetailsPrivacyQueryVariables>;
export const UpdateJobSettingsDocument = gql`
    mutation UpdateJobSettings($input: JobSettingsUpsertInput!) {
  jobSettingsUpsert(input: $input) {
    jobSettings {
      ...JobDetailsPrivacy_jobSettings
    }
  }
}
    ${JobDetailsPrivacy_JobSettingsFragmentDoc}`;
export type UpdateJobSettingsMutationFn = Apollo.MutationFunction<UpdateJobSettingsMutation, UpdateJobSettingsMutationVariables>;

/**
 * __useUpdateJobSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateJobSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobSettingsMutation, { data, loading, error }] = useUpdateJobSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJobSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobSettingsMutation, UpdateJobSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobSettingsMutation, UpdateJobSettingsMutationVariables>(UpdateJobSettingsDocument, options);
      }
export type UpdateJobSettingsMutationHookResult = ReturnType<typeof useUpdateJobSettingsMutation>;
export type UpdateJobSettingsMutationResult = Apollo.MutationResult<UpdateJobSettingsMutation>;
export type UpdateJobSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateJobSettingsMutation, UpdateJobSettingsMutationVariables>;
export const JobStageInterviewPlanDocument = gql`
    query JobStageInterviewPlan($id: uuid!) {
  jobStage(id: $id) {
    id
    ...InterviewPlan_jobStage
    ...useInterviewPlanDescription_jobStage
    job {
      id
      ...InterviewPlan_job
    }
    atsJobStage {
      atsId
      canResetInterviewPlan
    }
  }
}
    ${InterviewPlan_JobStageFragmentDoc}
${UseInterviewPlanDescription_JobStageFragmentDoc}
${InterviewPlan_JobFragmentDoc}`;

/**
 * __useJobStageInterviewPlanQuery__
 *
 * To run a query within a React component, call `useJobStageInterviewPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobStageInterviewPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobStageInterviewPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobStageInterviewPlanQuery(baseOptions: Apollo.QueryHookOptions<JobStageInterviewPlanQuery, JobStageInterviewPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobStageInterviewPlanQuery, JobStageInterviewPlanQueryVariables>(JobStageInterviewPlanDocument, options);
      }
export function useJobStageInterviewPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobStageInterviewPlanQuery, JobStageInterviewPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobStageInterviewPlanQuery, JobStageInterviewPlanQueryVariables>(JobStageInterviewPlanDocument, options);
        }
export type JobStageInterviewPlanQueryHookResult = ReturnType<typeof useJobStageInterviewPlanQuery>;
export type JobStageInterviewPlanLazyQueryHookResult = ReturnType<typeof useJobStageInterviewPlanLazyQuery>;
export type JobStageInterviewPlanQueryResult = Apollo.QueryResult<JobStageInterviewPlanQuery, JobStageInterviewPlanQueryVariables>;
export const JobStageInterviewPlanUpsertDocument = gql`
    mutation JobStageInterviewPlanUpsert($input: JobStageInterviewPlanUpsertInput!) {
  jobStageInterviewPlanUpsert(input: $input) {
    jobStage {
      id
      ...InterviewPlan_jobStage
      ...useInterviewPlanDescription_jobStage
    }
  }
}
    ${InterviewPlan_JobStageFragmentDoc}
${UseInterviewPlanDescription_JobStageFragmentDoc}`;
export type JobStageInterviewPlanUpsertMutationFn = Apollo.MutationFunction<JobStageInterviewPlanUpsertMutation, JobStageInterviewPlanUpsertMutationVariables>;

/**
 * __useJobStageInterviewPlanUpsertMutation__
 *
 * To run a mutation, you first call `useJobStageInterviewPlanUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobStageInterviewPlanUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobStageInterviewPlanUpsertMutation, { data, loading, error }] = useJobStageInterviewPlanUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobStageInterviewPlanUpsertMutation(baseOptions?: Apollo.MutationHookOptions<JobStageInterviewPlanUpsertMutation, JobStageInterviewPlanUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobStageInterviewPlanUpsertMutation, JobStageInterviewPlanUpsertMutationVariables>(JobStageInterviewPlanUpsertDocument, options);
      }
export type JobStageInterviewPlanUpsertMutationHookResult = ReturnType<typeof useJobStageInterviewPlanUpsertMutation>;
export type JobStageInterviewPlanUpsertMutationResult = Apollo.MutationResult<JobStageInterviewPlanUpsertMutation>;
export type JobStageInterviewPlanUpsertMutationOptions = Apollo.BaseMutationOptions<JobStageInterviewPlanUpsertMutation, JobStageInterviewPlanUpsertMutationVariables>;
export const InterviewMeetingLocationUpsertDocument = gql`
    mutation InterviewMeetingLocationUpsert($input: JobStageSettingsUpsertInput!) {
  jobStageSettingsUpsert(input: $input) {
    jobStageSettings {
      id
    }
  }
}
    `;
export type InterviewMeetingLocationUpsertMutationFn = Apollo.MutationFunction<InterviewMeetingLocationUpsertMutation, InterviewMeetingLocationUpsertMutationVariables>;

/**
 * __useInterviewMeetingLocationUpsertMutation__
 *
 * To run a mutation, you first call `useInterviewMeetingLocationUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInterviewMeetingLocationUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [interviewMeetingLocationUpsertMutation, { data, loading, error }] = useInterviewMeetingLocationUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewMeetingLocationUpsertMutation(baseOptions?: Apollo.MutationHookOptions<InterviewMeetingLocationUpsertMutation, InterviewMeetingLocationUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InterviewMeetingLocationUpsertMutation, InterviewMeetingLocationUpsertMutationVariables>(InterviewMeetingLocationUpsertDocument, options);
      }
export type InterviewMeetingLocationUpsertMutationHookResult = ReturnType<typeof useInterviewMeetingLocationUpsertMutation>;
export type InterviewMeetingLocationUpsertMutationResult = Apollo.MutationResult<InterviewMeetingLocationUpsertMutation>;
export type InterviewMeetingLocationUpsertMutationOptions = Apollo.BaseMutationOptions<InterviewMeetingLocationUpsertMutation, InterviewMeetingLocationUpsertMutationVariables>;
export const JobStageCommsSettingsUpsertDocument = gql`
    mutation JobStageCommsSettingsUpsert($input: JobStageSettingsUpsertInput!) {
  jobStageSettingsUpsert(input: $input) {
    jobStageSettings {
      id
      ...JobStageDetails_jobStageSettings
    }
  }
}
    ${JobStageDetails_JobStageSettingsFragmentDoc}`;
export type JobStageCommsSettingsUpsertMutationFn = Apollo.MutationFunction<JobStageCommsSettingsUpsertMutation, JobStageCommsSettingsUpsertMutationVariables>;

/**
 * __useJobStageCommsSettingsUpsertMutation__
 *
 * To run a mutation, you first call `useJobStageCommsSettingsUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobStageCommsSettingsUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobStageCommsSettingsUpsertMutation, { data, loading, error }] = useJobStageCommsSettingsUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobStageCommsSettingsUpsertMutation(baseOptions?: Apollo.MutationHookOptions<JobStageCommsSettingsUpsertMutation, JobStageCommsSettingsUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobStageCommsSettingsUpsertMutation, JobStageCommsSettingsUpsertMutationVariables>(JobStageCommsSettingsUpsertDocument, options);
      }
export type JobStageCommsSettingsUpsertMutationHookResult = ReturnType<typeof useJobStageCommsSettingsUpsertMutation>;
export type JobStageCommsSettingsUpsertMutationResult = Apollo.MutationResult<JobStageCommsSettingsUpsertMutation>;
export type JobStageCommsSettingsUpsertMutationOptions = Apollo.BaseMutationOptions<JobStageCommsSettingsUpsertMutation, JobStageCommsSettingsUpsertMutationVariables>;
export const CheckCreateCandidatePermissionDocument = gql`
    mutation CheckCreateCandidatePermission($input: CheckCreateCandidatePermissionInput!) {
  checkCreateCandidatePermission(input: $input) {
    hasPermission
    testCandidateAtsUrl
  }
}
    `;
export type CheckCreateCandidatePermissionMutationFn = Apollo.MutationFunction<CheckCreateCandidatePermissionMutation, CheckCreateCandidatePermissionMutationVariables>;

/**
 * __useCheckCreateCandidatePermissionMutation__
 *
 * To run a mutation, you first call `useCheckCreateCandidatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckCreateCandidatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkCreateCandidatePermissionMutation, { data, loading, error }] = useCheckCreateCandidatePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckCreateCandidatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<CheckCreateCandidatePermissionMutation, CheckCreateCandidatePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckCreateCandidatePermissionMutation, CheckCreateCandidatePermissionMutationVariables>(CheckCreateCandidatePermissionDocument, options);
      }
export type CheckCreateCandidatePermissionMutationHookResult = ReturnType<typeof useCheckCreateCandidatePermissionMutation>;
export type CheckCreateCandidatePermissionMutationResult = Apollo.MutationResult<CheckCreateCandidatePermissionMutation>;
export type CheckCreateCandidatePermissionMutationOptions = Apollo.BaseMutationOptions<CheckCreateCandidatePermissionMutation, CheckCreateCandidatePermissionMutationVariables>;
export const JobStageInterviewPlanQueryDocument = gql`
    query JobStageInterviewPlanQuery($jobStageId: uuid!) {
  jobStage(id: $jobStageId) {
    id
    ...InterviewPlan_jobStage
    job {
      ...InterviewPlan_job
    }
  }
}
    ${InterviewPlan_JobStageFragmentDoc}
${InterviewPlan_JobFragmentDoc}`;

/**
 * __useJobStageInterviewPlanQueryQuery__
 *
 * To run a query within a React component, call `useJobStageInterviewPlanQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobStageInterviewPlanQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobStageInterviewPlanQueryQuery({
 *   variables: {
 *      jobStageId: // value for 'jobStageId'
 *   },
 * });
 */
export function useJobStageInterviewPlanQueryQuery(baseOptions: Apollo.QueryHookOptions<JobStageInterviewPlanQueryQuery, JobStageInterviewPlanQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobStageInterviewPlanQueryQuery, JobStageInterviewPlanQueryQueryVariables>(JobStageInterviewPlanQueryDocument, options);
      }
export function useJobStageInterviewPlanQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobStageInterviewPlanQueryQuery, JobStageInterviewPlanQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobStageInterviewPlanQueryQuery, JobStageInterviewPlanQueryQueryVariables>(JobStageInterviewPlanQueryDocument, options);
        }
export type JobStageInterviewPlanQueryQueryHookResult = ReturnType<typeof useJobStageInterviewPlanQueryQuery>;
export type JobStageInterviewPlanQueryLazyQueryHookResult = ReturnType<typeof useJobStageInterviewPlanQueryLazyQuery>;
export type JobStageInterviewPlanQueryQueryResult = Apollo.QueryResult<JobStageInterviewPlanQueryQuery, JobStageInterviewPlanQueryQueryVariables>;
export const CreateSourcingLinkDocument = gql`
    mutation CreateSourcingLink($input: SourcingLinkCreateInput!) {
  sourcingLinkCreate(input: $input) {
    sourcingLink {
      id
    }
  }
}
    `;
export type CreateSourcingLinkMutationFn = Apollo.MutationFunction<CreateSourcingLinkMutation, CreateSourcingLinkMutationVariables>;

/**
 * __useCreateSourcingLinkMutation__
 *
 * To run a mutation, you first call `useCreateSourcingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSourcingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSourcingLinkMutation, { data, loading, error }] = useCreateSourcingLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSourcingLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateSourcingLinkMutation, CreateSourcingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSourcingLinkMutation, CreateSourcingLinkMutationVariables>(CreateSourcingLinkDocument, options);
      }
export type CreateSourcingLinkMutationHookResult = ReturnType<typeof useCreateSourcingLinkMutation>;
export type CreateSourcingLinkMutationResult = Apollo.MutationResult<CreateSourcingLinkMutation>;
export type CreateSourcingLinkMutationOptions = Apollo.BaseMutationOptions<CreateSourcingLinkMutation, CreateSourcingLinkMutationVariables>;
export const UpdateSourcingLinkDocument = gql`
    mutation UpdateSourcingLink($input: SourcingLinkUpdateInput!) {
  sourcingLinkUpdate(input: $input) {
    sourcingLink {
      id
    }
  }
}
    `;
export type UpdateSourcingLinkMutationFn = Apollo.MutationFunction<UpdateSourcingLinkMutation, UpdateSourcingLinkMutationVariables>;

/**
 * __useUpdateSourcingLinkMutation__
 *
 * To run a mutation, you first call `useUpdateSourcingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSourcingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSourcingLinkMutation, { data, loading, error }] = useUpdateSourcingLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSourcingLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSourcingLinkMutation, UpdateSourcingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSourcingLinkMutation, UpdateSourcingLinkMutationVariables>(UpdateSourcingLinkDocument, options);
      }
export type UpdateSourcingLinkMutationHookResult = ReturnType<typeof useUpdateSourcingLinkMutation>;
export type UpdateSourcingLinkMutationResult = Apollo.MutationResult<UpdateSourcingLinkMutation>;
export type UpdateSourcingLinkMutationOptions = Apollo.BaseMutationOptions<UpdateSourcingLinkMutation, UpdateSourcingLinkMutationVariables>;
export const DuplicateSourcingLinkDocument = gql`
    mutation DuplicateSourcingLink($input: SourcingLinkCreateInput!) {
  sourcingLinkCreate(input: $input) {
    sourcingLink {
      id
    }
  }
}
    `;
export type DuplicateSourcingLinkMutationFn = Apollo.MutationFunction<DuplicateSourcingLinkMutation, DuplicateSourcingLinkMutationVariables>;

/**
 * __useDuplicateSourcingLinkMutation__
 *
 * To run a mutation, you first call `useDuplicateSourcingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateSourcingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateSourcingLinkMutation, { data, loading, error }] = useDuplicateSourcingLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateSourcingLinkMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateSourcingLinkMutation, DuplicateSourcingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateSourcingLinkMutation, DuplicateSourcingLinkMutationVariables>(DuplicateSourcingLinkDocument, options);
      }
export type DuplicateSourcingLinkMutationHookResult = ReturnType<typeof useDuplicateSourcingLinkMutation>;
export type DuplicateSourcingLinkMutationResult = Apollo.MutationResult<DuplicateSourcingLinkMutation>;
export type DuplicateSourcingLinkMutationOptions = Apollo.BaseMutationOptions<DuplicateSourcingLinkMutation, DuplicateSourcingLinkMutationVariables>;
export const DeleteSourcingLinkDocument = gql`
    mutation DeleteSourcingLink($id: uuid!) {
  sourcingLinkDelete(id: $id) {
    result
  }
}
    `;
export type DeleteSourcingLinkMutationFn = Apollo.MutationFunction<DeleteSourcingLinkMutation, DeleteSourcingLinkMutationVariables>;

/**
 * __useDeleteSourcingLinkMutation__
 *
 * To run a mutation, you first call `useDeleteSourcingLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSourcingLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSourcingLinkMutation, { data, loading, error }] = useDeleteSourcingLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSourcingLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSourcingLinkMutation, DeleteSourcingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSourcingLinkMutation, DeleteSourcingLinkMutationVariables>(DeleteSourcingLinkDocument, options);
      }
export type DeleteSourcingLinkMutationHookResult = ReturnType<typeof useDeleteSourcingLinkMutation>;
export type DeleteSourcingLinkMutationResult = Apollo.MutationResult<DeleteSourcingLinkMutation>;
export type DeleteSourcingLinkMutationOptions = Apollo.BaseMutationOptions<DeleteSourcingLinkMutation, DeleteSourcingLinkMutationVariables>;
export const UpdateSourcingLinkStatusDocument = gql`
    mutation UpdateSourcingLinkStatus($input: SourcingLinkUpdateInput!) {
  sourcingLinkUpdate(input: $input) {
    sourcingLink {
      id
      deactivatedAt
    }
  }
}
    `;
export type UpdateSourcingLinkStatusMutationFn = Apollo.MutationFunction<UpdateSourcingLinkStatusMutation, UpdateSourcingLinkStatusMutationVariables>;

/**
 * __useUpdateSourcingLinkStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSourcingLinkStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSourcingLinkStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSourcingLinkStatusMutation, { data, loading, error }] = useUpdateSourcingLinkStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSourcingLinkStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSourcingLinkStatusMutation, UpdateSourcingLinkStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSourcingLinkStatusMutation, UpdateSourcingLinkStatusMutationVariables>(UpdateSourcingLinkStatusDocument, options);
      }
export type UpdateSourcingLinkStatusMutationHookResult = ReturnType<typeof useUpdateSourcingLinkStatusMutation>;
export type UpdateSourcingLinkStatusMutationResult = Apollo.MutationResult<UpdateSourcingLinkStatusMutation>;
export type UpdateSourcingLinkStatusMutationOptions = Apollo.BaseMutationOptions<UpdateSourcingLinkStatusMutation, UpdateSourcingLinkStatusMutationVariables>;
export const SourcingLinksDocument = gql`
    query SourcingLinks($jobId: uuid!) {
  job(id: $jobId) {
    id
    status
    sourcingLinks {
      id
      ...SourcingLinkForm_sourcingLink
      ...SourcingLinksTable_sourcingLinks
    }
    ...SourcingLinkForm_job
  }
  thisEmployee {
    id
    ...SourcingLinkForm_currentEmployee
  }
}
    ${SourcingLinkForm_SourcingLinkFragmentDoc}
${SourcingLinksTable_SourcingLinksFragmentDoc}
${SourcingLinkForm_JobFragmentDoc}
${SourcingLinkForm_CurrentEmployeeFragmentDoc}`;

/**
 * __useSourcingLinksQuery__
 *
 * To run a query within a React component, call `useSourcingLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcingLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcingLinksQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useSourcingLinksQuery(baseOptions: Apollo.QueryHookOptions<SourcingLinksQuery, SourcingLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SourcingLinksQuery, SourcingLinksQueryVariables>(SourcingLinksDocument, options);
      }
export function useSourcingLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SourcingLinksQuery, SourcingLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SourcingLinksQuery, SourcingLinksQueryVariables>(SourcingLinksDocument, options);
        }
export type SourcingLinksQueryHookResult = ReturnType<typeof useSourcingLinksQuery>;
export type SourcingLinksLazyQueryHookResult = ReturnType<typeof useSourcingLinksLazyQuery>;
export type SourcingLinksQueryResult = Apollo.QueryResult<SourcingLinksQuery, SourcingLinksQueryVariables>;
export const JobByIdDocument = gql`
    query JobById($jobId: uuid!) {
  job(id: $jobId) {
    id
    atsType
    ...JobNameAndLocationHeader_job
    jobStages {
      id
      ...JobDetailsPage_jobStage
      ...JobStageDetails_jobStage
      jobStageSettings {
        id
        ...JobStageDetails_jobStageSettings
      }
      jobStageSettingsV2 {
        id
        ...JobStageDetails_jobStageSettingsV2
      }
    }
    ...HiringTeam_jobEmployee
  }
}
    ${JobNameAndLocationHeader_JobFragmentDoc}
${JobDetailsPage_JobStageFragmentDoc}
${JobStageDetails_JobStageFragmentDoc}
${JobStageDetails_JobStageSettingsFragmentDoc}
${JobStageDetails_JobStageSettingsV2FragmentDoc}
${HiringTeam_JobEmployeeFragmentDoc}`;

/**
 * __useJobByIdQuery__
 *
 * To run a query within a React component, call `useJobByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobByIdQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useJobByIdQuery(baseOptions: Apollo.QueryHookOptions<JobByIdQuery, JobByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobByIdQuery, JobByIdQueryVariables>(JobByIdDocument, options);
      }
export function useJobByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobByIdQuery, JobByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobByIdQuery, JobByIdQueryVariables>(JobByIdDocument, options);
        }
export type JobByIdQueryHookResult = ReturnType<typeof useJobByIdQuery>;
export type JobByIdLazyQueryHookResult = ReturnType<typeof useJobByIdLazyQuery>;
export type JobByIdQueryResult = Apollo.QueryResult<JobByIdQuery, JobByIdQueryVariables>;
export const FixAndImportJobSettingsDocument = gql`
    mutation FixAndImportJobSettings($input: JobSettingsImportInput!) {
  jobSettingsImport(input: $input) {
    sourceJob {
      id
    }
  }
}
    `;
export type FixAndImportJobSettingsMutationFn = Apollo.MutationFunction<FixAndImportJobSettingsMutation, FixAndImportJobSettingsMutationVariables>;

/**
 * __useFixAndImportJobSettingsMutation__
 *
 * To run a mutation, you first call `useFixAndImportJobSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFixAndImportJobSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fixAndImportJobSettingsMutation, { data, loading, error }] = useFixAndImportJobSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFixAndImportJobSettingsMutation(baseOptions?: Apollo.MutationHookOptions<FixAndImportJobSettingsMutation, FixAndImportJobSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FixAndImportJobSettingsMutation, FixAndImportJobSettingsMutationVariables>(FixAndImportJobSettingsDocument, options);
      }
export type FixAndImportJobSettingsMutationHookResult = ReturnType<typeof useFixAndImportJobSettingsMutation>;
export type FixAndImportJobSettingsMutationResult = Apollo.MutationResult<FixAndImportJobSettingsMutation>;
export type FixAndImportJobSettingsMutationOptions = Apollo.BaseMutationOptions<FixAndImportJobSettingsMutation, FixAndImportJobSettingsMutationVariables>;
export const VisibilitySelectFixIssuesDocument = gql`
    query VisibilitySelectFixIssues($input: JobStagesInput!) {
  jobStages(input: $input) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useVisibilitySelectFixIssuesQuery__
 *
 * To run a query within a React component, call `useVisibilitySelectFixIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisibilitySelectFixIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisibilitySelectFixIssuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVisibilitySelectFixIssuesQuery(baseOptions: Apollo.QueryHookOptions<VisibilitySelectFixIssuesQuery, VisibilitySelectFixIssuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisibilitySelectFixIssuesQuery, VisibilitySelectFixIssuesQueryVariables>(VisibilitySelectFixIssuesDocument, options);
      }
export function useVisibilitySelectFixIssuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisibilitySelectFixIssuesQuery, VisibilitySelectFixIssuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisibilitySelectFixIssuesQuery, VisibilitySelectFixIssuesQueryVariables>(VisibilitySelectFixIssuesDocument, options);
        }
export type VisibilitySelectFixIssuesQueryHookResult = ReturnType<typeof useVisibilitySelectFixIssuesQuery>;
export type VisibilitySelectFixIssuesLazyQueryHookResult = ReturnType<typeof useVisibilitySelectFixIssuesLazyQuery>;
export type VisibilitySelectFixIssuesQueryResult = Apollo.QueryResult<VisibilitySelectFixIssuesQuery, VisibilitySelectFixIssuesQueryVariables>;
export const SourceJobsListDocument = gql`
    query SourceJobsList($input: JobsInput!) {
  jobs(input: $input) {
    items {
      ...SourceJobTable_job
    }
    nextCursor
  }
}
    ${SourceJobTable_JobFragmentDoc}`;

/**
 * __useSourceJobsListQuery__
 *
 * To run a query within a React component, call `useSourceJobsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceJobsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceJobsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSourceJobsListQuery(baseOptions: Apollo.QueryHookOptions<SourceJobsListQuery, SourceJobsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SourceJobsListQuery, SourceJobsListQueryVariables>(SourceJobsListDocument, options);
      }
export function useSourceJobsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SourceJobsListQuery, SourceJobsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SourceJobsListQuery, SourceJobsListQueryVariables>(SourceJobsListDocument, options);
        }
export type SourceJobsListQueryHookResult = ReturnType<typeof useSourceJobsListQuery>;
export type SourceJobsListLazyQueryHookResult = ReturnType<typeof useSourceJobsListLazyQuery>;
export type SourceJobsListQueryResult = Apollo.QueryResult<SourceJobsListQuery, SourceJobsListQueryVariables>;
export const GetSourceJobDocument = gql`
    query GetSourceJob($id: uuid!) {
  job(id: $id) {
    ...SelectImportOptions_job
  }
}
    ${SelectImportOptions_JobFragmentDoc}`;

/**
 * __useGetSourceJobQuery__
 *
 * To run a query within a React component, call `useGetSourceJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSourceJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSourceJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSourceJobQuery(baseOptions: Apollo.QueryHookOptions<GetSourceJobQuery, GetSourceJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSourceJobQuery, GetSourceJobQueryVariables>(GetSourceJobDocument, options);
      }
export function useGetSourceJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSourceJobQuery, GetSourceJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSourceJobQuery, GetSourceJobQueryVariables>(GetSourceJobDocument, options);
        }
export type GetSourceJobQueryHookResult = ReturnType<typeof useGetSourceJobQuery>;
export type GetSourceJobLazyQueryHookResult = ReturnType<typeof useGetSourceJobLazyQuery>;
export type GetSourceJobQueryResult = Apollo.QueryResult<GetSourceJobQuery, GetSourceJobQueryVariables>;
export const GetTargetJobsDocument = gql`
    query GetTargetJobs($ids: [uuid!]!) {
  jobByIds(ids: $ids) {
    id
    ...ExitConfirmationModal_jobs
  }
}
    ${ExitConfirmationModal_JobsFragmentDoc}`;

/**
 * __useGetTargetJobsQuery__
 *
 * To run a query within a React component, call `useGetTargetJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetJobsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetTargetJobsQuery(baseOptions: Apollo.QueryHookOptions<GetTargetJobsQuery, GetTargetJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTargetJobsQuery, GetTargetJobsQueryVariables>(GetTargetJobsDocument, options);
      }
export function useGetTargetJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTargetJobsQuery, GetTargetJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTargetJobsQuery, GetTargetJobsQueryVariables>(GetTargetJobsDocument, options);
        }
export type GetTargetJobsQueryHookResult = ReturnType<typeof useGetTargetJobsQuery>;
export type GetTargetJobsLazyQueryHookResult = ReturnType<typeof useGetTargetJobsLazyQuery>;
export type GetTargetJobsQueryResult = Apollo.QueryResult<GetTargetJobsQuery, GetTargetJobsQueryVariables>;
export const JobSettingsImportValidateDocument = gql`
    query JobSettingsImportValidate($input: JobSettingsImportValidateInput!) {
  jobSettingsImportValidate(input: $input) {
    ...FixIssues_issues
    items {
      ...Review_jobImportValidateResults
      ...getJobsWithIssues_jobImportValidateResults
      ...getJobsWithoutIssues_jobImportValidateResults
      jobStageMappings {
        ...getJobStageMappings_jobStageMapping
      }
      scorecardMappings {
        ...getScorecardMappings_scorecardMapping
      }
      contentTabMappings {
        targetJobStageId
        sourceContentTab {
          id
          visibility
        }
      }
    }
  }
}
    ${FixIssues_IssuesFragmentDoc}
${Review_JobImportValidateResultsFragmentDoc}
${GetJobsWithIssues_JobImportValidateResultsFragmentDoc}
${GetJobsWithoutIssues_JobImportValidateResultsFragmentDoc}
${GetJobStageMappings_JobStageMappingFragmentDoc}
${GetScorecardMappings_ScorecardMappingFragmentDoc}`;

/**
 * __useJobSettingsImportValidateQuery__
 *
 * To run a query within a React component, call `useJobSettingsImportValidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobSettingsImportValidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobSettingsImportValidateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobSettingsImportValidateQuery(baseOptions: Apollo.QueryHookOptions<JobSettingsImportValidateQuery, JobSettingsImportValidateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobSettingsImportValidateQuery, JobSettingsImportValidateQueryVariables>(JobSettingsImportValidateDocument, options);
      }
export function useJobSettingsImportValidateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobSettingsImportValidateQuery, JobSettingsImportValidateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobSettingsImportValidateQuery, JobSettingsImportValidateQueryVariables>(JobSettingsImportValidateDocument, options);
        }
export type JobSettingsImportValidateQueryHookResult = ReturnType<typeof useJobSettingsImportValidateQuery>;
export type JobSettingsImportValidateLazyQueryHookResult = ReturnType<typeof useJobSettingsImportValidateLazyQuery>;
export type JobSettingsImportValidateQueryResult = Apollo.QueryResult<JobSettingsImportValidateQuery, JobSettingsImportValidateQueryVariables>;
export const ImportJobSettingsDocument = gql`
    mutation ImportJobSettings($input: JobSettingsImportInput!) {
  jobSettingsImport(input: $input) {
    sourceJob {
      id
    }
  }
}
    `;
export type ImportJobSettingsMutationFn = Apollo.MutationFunction<ImportJobSettingsMutation, ImportJobSettingsMutationVariables>;

/**
 * __useImportJobSettingsMutation__
 *
 * To run a mutation, you first call `useImportJobSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportJobSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importJobSettingsMutation, { data, loading, error }] = useImportJobSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportJobSettingsMutation(baseOptions?: Apollo.MutationHookOptions<ImportJobSettingsMutation, ImportJobSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportJobSettingsMutation, ImportJobSettingsMutationVariables>(ImportJobSettingsDocument, options);
      }
export type ImportJobSettingsMutationHookResult = ReturnType<typeof useImportJobSettingsMutation>;
export type ImportJobSettingsMutationResult = Apollo.MutationResult<ImportJobSettingsMutation>;
export type ImportJobSettingsMutationOptions = Apollo.BaseMutationOptions<ImportJobSettingsMutation, ImportJobSettingsMutationVariables>;
export const JobsListDocument = gql`
    query JobsList($input: JobsInput!) {
  jobs(input: $input) {
    items {
      ...JobListItem_job
    }
    nextCursor
  }
}
    ${JobListItem_JobFragmentDoc}`;

/**
 * __useJobsListQuery__
 *
 * To run a query within a React component, call `useJobsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobsListQuery(baseOptions: Apollo.QueryHookOptions<JobsListQuery, JobsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsListQuery, JobsListQueryVariables>(JobsListDocument, options);
      }
export function useJobsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsListQuery, JobsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsListQuery, JobsListQueryVariables>(JobsListDocument, options);
        }
export type JobsListQueryHookResult = ReturnType<typeof useJobsListQuery>;
export type JobsListLazyQueryHookResult = ReturnType<typeof useJobsListLazyQuery>;
export type JobsListQueryResult = Apollo.QueryResult<JobsListQuery, JobsListQueryVariables>;
export const DeclineMetricesDownloadDocument = gql`
    query DeclineMetricesDownload($input: InterviewerMetricsChartInput!) {
  interviewerMetricsChartDownload(input: $input) {
    data {
      interviewDate
      candidate
      interviewName
      calendarEventTitle
      jobStage
      scorecard
      scheduler
      interviewer
      declineLeadTime
      job
      interviewerEmployee {
        id
        manager {
          email
        }
        attributes {
          name {
            name
          }
          value
        }
      }
    }
  }
}
    `;

/**
 * __useDeclineMetricesDownloadQuery__
 *
 * To run a query within a React component, call `useDeclineMetricesDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeclineMetricesDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeclineMetricesDownloadQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineMetricesDownloadQuery(baseOptions: Apollo.QueryHookOptions<DeclineMetricesDownloadQuery, DeclineMetricesDownloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeclineMetricesDownloadQuery, DeclineMetricesDownloadQueryVariables>(DeclineMetricesDownloadDocument, options);
      }
export function useDeclineMetricesDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeclineMetricesDownloadQuery, DeclineMetricesDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeclineMetricesDownloadQuery, DeclineMetricesDownloadQueryVariables>(DeclineMetricesDownloadDocument, options);
        }
export type DeclineMetricesDownloadQueryHookResult = ReturnType<typeof useDeclineMetricesDownloadQuery>;
export type DeclineMetricesDownloadLazyQueryHookResult = ReturnType<typeof useDeclineMetricesDownloadLazyQuery>;
export type DeclineMetricesDownloadQueryResult = Apollo.QueryResult<DeclineMetricesDownloadQuery, DeclineMetricesDownloadQueryVariables>;
export const InterviewsCompletedChartDocument = gql`
    query InterviewsCompletedChart($input: InterviewerMetricsChartInput!) {
  interviewerMetricsChart(input: $input) {
    data {
      x: xpoint
      y: ypoint
    }
  }
}
    `;

/**
 * __useInterviewsCompletedChartQuery__
 *
 * To run a query within a React component, call `useInterviewsCompletedChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewsCompletedChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewsCompletedChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewsCompletedChartQuery(baseOptions: Apollo.QueryHookOptions<InterviewsCompletedChartQuery, InterviewsCompletedChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewsCompletedChartQuery, InterviewsCompletedChartQueryVariables>(InterviewsCompletedChartDocument, options);
      }
export function useInterviewsCompletedChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewsCompletedChartQuery, InterviewsCompletedChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewsCompletedChartQuery, InterviewsCompletedChartQueryVariables>(InterviewsCompletedChartDocument, options);
        }
export type InterviewsCompletedChartQueryHookResult = ReturnType<typeof useInterviewsCompletedChartQuery>;
export type InterviewsCompletedChartLazyQueryHookResult = ReturnType<typeof useInterviewsCompletedChartLazyQuery>;
export type InterviewsCompletedChartQueryResult = Apollo.QueryResult<InterviewsCompletedChartQuery, InterviewsCompletedChartQueryVariables>;
export const InterviewsCompletedDownloadChartDocument = gql`
    query InterviewsCompletedDownloadChart($input: InterviewerMetricsChartInput!) {
  interviewerMetricsChartDownload(input: $input) {
    data {
      interviewDate
      candidate
      interviewName
      calendarEventTitle
      jobStage
      scorecard
      scheduler
      interviewer
      job
      interviewerEmployee {
        id
        manager {
          email
        }
        attributes {
          name {
            name
          }
          value
        }
      }
    }
  }
}
    `;

/**
 * __useInterviewsCompletedDownloadChartQuery__
 *
 * To run a query within a React component, call `useInterviewsCompletedDownloadChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewsCompletedDownloadChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewsCompletedDownloadChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewsCompletedDownloadChartQuery(baseOptions: Apollo.QueryHookOptions<InterviewsCompletedDownloadChartQuery, InterviewsCompletedDownloadChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewsCompletedDownloadChartQuery, InterviewsCompletedDownloadChartQueryVariables>(InterviewsCompletedDownloadChartDocument, options);
      }
export function useInterviewsCompletedDownloadChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewsCompletedDownloadChartQuery, InterviewsCompletedDownloadChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewsCompletedDownloadChartQuery, InterviewsCompletedDownloadChartQueryVariables>(InterviewsCompletedDownloadChartDocument, options);
        }
export type InterviewsCompletedDownloadChartQueryHookResult = ReturnType<typeof useInterviewsCompletedDownloadChartQuery>;
export type InterviewsCompletedDownloadChartLazyQueryHookResult = ReturnType<typeof useInterviewsCompletedDownloadChartLazyQuery>;
export type InterviewsCompletedDownloadChartQueryResult = Apollo.QueryResult<InterviewsCompletedDownloadChartQuery, InterviewsCompletedDownloadChartQueryVariables>;
export const InterviewsDeclinedDownloadChartDocument = gql`
    query InterviewsDeclinedDownloadChart($input: InterviewerMetricsChartInput!) {
  interviewerMetricsChartDownload(input: $input) {
    data {
      interviewDate
      candidate
      interviewName
      calendarEventTitle
      jobStage
      scorecard
      scheduler
      interviewer
      job
      interviewerEmployee {
        id
        manager {
          email
        }
        attributes {
          name {
            name
          }
          value
        }
      }
    }
  }
}
    `;

/**
 * __useInterviewsDeclinedDownloadChartQuery__
 *
 * To run a query within a React component, call `useInterviewsDeclinedDownloadChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewsDeclinedDownloadChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewsDeclinedDownloadChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewsDeclinedDownloadChartQuery(baseOptions: Apollo.QueryHookOptions<InterviewsDeclinedDownloadChartQuery, InterviewsDeclinedDownloadChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewsDeclinedDownloadChartQuery, InterviewsDeclinedDownloadChartQueryVariables>(InterviewsDeclinedDownloadChartDocument, options);
      }
export function useInterviewsDeclinedDownloadChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewsDeclinedDownloadChartQuery, InterviewsDeclinedDownloadChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewsDeclinedDownloadChartQuery, InterviewsDeclinedDownloadChartQueryVariables>(InterviewsDeclinedDownloadChartDocument, options);
        }
export type InterviewsDeclinedDownloadChartQueryHookResult = ReturnType<typeof useInterviewsDeclinedDownloadChartQuery>;
export type InterviewsDeclinedDownloadChartLazyQueryHookResult = ReturnType<typeof useInterviewsDeclinedDownloadChartLazyQuery>;
export type InterviewsDeclinedDownloadChartQueryResult = Apollo.QueryResult<InterviewsDeclinedDownloadChartQuery, InterviewsDeclinedDownloadChartQueryVariables>;
export const InterviewerMetricsInterviewingDocument = gql`
    query interviewerMetricsInterviewing($input: InterviewerMetricsTableInput!) {
  interviewerMetricsInterviewing(input: $input) {
    items {
      interviewer {
        id
        fullName
        slackImageUrl
        email
        manager {
          email
        }
        attributes {
          name {
            name
          }
          value
        }
      }
      upcoming
      completed
      declines
      totalTime
      interviewPerWeek
      timePerWeek
    }
  }
}
    `;

/**
 * __useInterviewerMetricsInterviewingQuery__
 *
 * To run a query within a React component, call `useInterviewerMetricsInterviewingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewerMetricsInterviewingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewerMetricsInterviewingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewerMetricsInterviewingQuery(baseOptions: Apollo.QueryHookOptions<InterviewerMetricsInterviewingQuery, InterviewerMetricsInterviewingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewerMetricsInterviewingQuery, InterviewerMetricsInterviewingQueryVariables>(InterviewerMetricsInterviewingDocument, options);
      }
export function useInterviewerMetricsInterviewingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewerMetricsInterviewingQuery, InterviewerMetricsInterviewingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewerMetricsInterviewingQuery, InterviewerMetricsInterviewingQueryVariables>(InterviewerMetricsInterviewingDocument, options);
        }
export type InterviewerMetricsInterviewingQueryHookResult = ReturnType<typeof useInterviewerMetricsInterviewingQuery>;
export type InterviewerMetricsInterviewingLazyQueryHookResult = ReturnType<typeof useInterviewerMetricsInterviewingLazyQuery>;
export type InterviewerMetricsInterviewingQueryResult = Apollo.QueryResult<InterviewerMetricsInterviewingQuery, InterviewerMetricsInterviewingQueryVariables>;
export const InterviewerMetricsTrainingDocument = gql`
    query InterviewerMetricsTraining($input: InterviewerMetricsTableInput!) {
  interviewerMetricsTraining(input: $input) {
    items {
      interviewer {
        id
        slackImageUrl
        fullName
        email
        manager {
          email
        }
        attributes {
          name {
            name
          }
          value
        }
      }
      trainer
      shadows
      reverseShadows
      modulesAsTrainee
    }
  }
}
    `;

/**
 * __useInterviewerMetricsTrainingQuery__
 *
 * To run a query within a React component, call `useInterviewerMetricsTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewerMetricsTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewerMetricsTrainingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInterviewerMetricsTrainingQuery(baseOptions: Apollo.QueryHookOptions<InterviewerMetricsTrainingQuery, InterviewerMetricsTrainingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewerMetricsTrainingQuery, InterviewerMetricsTrainingQueryVariables>(InterviewerMetricsTrainingDocument, options);
      }
export function useInterviewerMetricsTrainingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewerMetricsTrainingQuery, InterviewerMetricsTrainingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewerMetricsTrainingQuery, InterviewerMetricsTrainingQueryVariables>(InterviewerMetricsTrainingDocument, options);
        }
export type InterviewerMetricsTrainingQueryHookResult = ReturnType<typeof useInterviewerMetricsTrainingQuery>;
export type InterviewerMetricsTrainingLazyQueryHookResult = ReturnType<typeof useInterviewerMetricsTrainingLazyQuery>;
export type InterviewerMetricsTrainingQueryResult = Apollo.QueryResult<InterviewerMetricsTrainingQuery, InterviewerMetricsTrainingQueryVariables>;
export const DeclineMetricesDocument = gql`
    query DeclineMetrices($input: InterviewerMetricsChartInput!) {
  interviewerMetricsDeclinesWrapper(input: $input) {
    declineReasonsChart {
      data {
        x: xpoint
        y: ypoint
      }
    }
    declineLeadTimeChart {
      data {
        x: xpoint
        y: ypoint
      }
    }
    declineTable {
      items {
        date
        leadTime
        interviewer {
          id
          fullName
          slackImageUrl
          email
          manager {
            email
          }
          attributes {
            name {
              name
            }
            value
          }
        }
        declineSource
        declineComment
        declineReason
      }
    }
    declinesChart {
      data {
        x: xpoint
        y: ypoint
      }
    }
  }
}
    `;

/**
 * __useDeclineMetricesQuery__
 *
 * To run a query within a React component, call `useDeclineMetricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeclineMetricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeclineMetricesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineMetricesQuery(baseOptions: Apollo.QueryHookOptions<DeclineMetricesQuery, DeclineMetricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeclineMetricesQuery, DeclineMetricesQueryVariables>(DeclineMetricesDocument, options);
      }
export function useDeclineMetricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeclineMetricesQuery, DeclineMetricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeclineMetricesQuery, DeclineMetricesQueryVariables>(DeclineMetricesDocument, options);
        }
export type DeclineMetricesQueryHookResult = ReturnType<typeof useDeclineMetricesQuery>;
export type DeclineMetricesLazyQueryHookResult = ReturnType<typeof useDeclineMetricesLazyQuery>;
export type DeclineMetricesQueryResult = Apollo.QueryResult<DeclineMetricesQuery, DeclineMetricesQueryVariables>;
export const RecruiterChartDocument = gql`
    query RecruiterChart($input: RecruiterMetricsChartInput!) {
  recruiterMetricsChart(input: $input) {
    data {
      x: xpoint
      y: ypoint
    }
  }
}
    `;

/**
 * __useRecruiterChartQuery__
 *
 * To run a query within a React component, call `useRecruiterChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecruiterChartQuery(baseOptions: Apollo.QueryHookOptions<RecruiterChartQuery, RecruiterChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecruiterChartQuery, RecruiterChartQueryVariables>(RecruiterChartDocument, options);
      }
export function useRecruiterChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecruiterChartQuery, RecruiterChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecruiterChartQuery, RecruiterChartQueryVariables>(RecruiterChartDocument, options);
        }
export type RecruiterChartQueryHookResult = ReturnType<typeof useRecruiterChartQuery>;
export type RecruiterChartLazyQueryHookResult = ReturnType<typeof useRecruiterChartLazyQuery>;
export type RecruiterChartQueryResult = Apollo.QueryResult<RecruiterChartQuery, RecruiterChartQueryVariables>;
export const RecruiterChartDownloadDocument = gql`
    query RecruiterChartDownload($input: RecruiterMetricsChartInput!) {
  recruiterMetricsChartDownload(input: $input) {
    data {
      interviewDate
      id
      interviewName
      calendarEventTitle
      scorecard
      jobStage
      scheduler
      candidate
      job
    }
    scheduleStages {
      id
      jobStage
      candidate
      scheduler
      scheduleStageDate
      job
    }
  }
}
    `;

/**
 * __useRecruiterChartDownloadQuery__
 *
 * To run a query within a React component, call `useRecruiterChartDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterChartDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterChartDownloadQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecruiterChartDownloadQuery(baseOptions: Apollo.QueryHookOptions<RecruiterChartDownloadQuery, RecruiterChartDownloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecruiterChartDownloadQuery, RecruiterChartDownloadQueryVariables>(RecruiterChartDownloadDocument, options);
      }
export function useRecruiterChartDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecruiterChartDownloadQuery, RecruiterChartDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecruiterChartDownloadQuery, RecruiterChartDownloadQueryVariables>(RecruiterChartDownloadDocument, options);
        }
export type RecruiterChartDownloadQueryHookResult = ReturnType<typeof useRecruiterChartDownloadQuery>;
export type RecruiterChartDownloadLazyQueryHookResult = ReturnType<typeof useRecruiterChartDownloadLazyQuery>;
export type RecruiterChartDownloadQueryResult = Apollo.QueryResult<RecruiterChartDownloadQuery, RecruiterChartDownloadQueryVariables>;
export const RecruitersMetricsTableDocument = gql`
    query RecruitersMetricsTable($input: RecruiterMetricsChartInput!) {
  recruiterMetricsTable(input: $input) {
    items {
      recruiter {
        id
        fullName
        slackImageUrl
        email
      }
      scheduled
      completed
      declines
      cancelled
      changed
      facilitated
    }
  }
}
    `;

/**
 * __useRecruitersMetricsTableQuery__
 *
 * To run a query within a React component, call `useRecruitersMetricsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruitersMetricsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruitersMetricsTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecruitersMetricsTableQuery(baseOptions: Apollo.QueryHookOptions<RecruitersMetricsTableQuery, RecruitersMetricsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecruitersMetricsTableQuery, RecruitersMetricsTableQueryVariables>(RecruitersMetricsTableDocument, options);
      }
export function useRecruitersMetricsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecruitersMetricsTableQuery, RecruitersMetricsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecruitersMetricsTableQuery, RecruitersMetricsTableQueryVariables>(RecruitersMetricsTableDocument, options);
        }
export type RecruitersMetricsTableQueryHookResult = ReturnType<typeof useRecruitersMetricsTableQuery>;
export type RecruitersMetricsTableLazyQueryHookResult = ReturnType<typeof useRecruitersMetricsTableLazyQuery>;
export type RecruitersMetricsTableQueryResult = Apollo.QueryResult<RecruitersMetricsTableQuery, RecruitersMetricsTableQueryVariables>;
export const RecruiterMetricsRescheduleLogsDocument = gql`
    query recruiterMetricsRescheduleLogs($input: RecruiterMetricsChartInput!) {
  recruiterMetricsRescheduleLogs(input: $input) {
    ...RescheduleLogReasonsChart_rescheduleLogReasonsData
    items {
      ...RescheduleLogNotes_rescheduleLogItems
    }
  }
}
    ${RescheduleLogReasonsChart_RescheduleLogReasonsDataFragmentDoc}
${RescheduleLogNotes_RescheduleLogItemsFragmentDoc}`;

/**
 * __useRecruiterMetricsRescheduleLogsQuery__
 *
 * To run a query within a React component, call `useRecruiterMetricsRescheduleLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterMetricsRescheduleLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecruiterMetricsRescheduleLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecruiterMetricsRescheduleLogsQuery(baseOptions: Apollo.QueryHookOptions<RecruiterMetricsRescheduleLogsQuery, RecruiterMetricsRescheduleLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecruiterMetricsRescheduleLogsQuery, RecruiterMetricsRescheduleLogsQueryVariables>(RecruiterMetricsRescheduleLogsDocument, options);
      }
export function useRecruiterMetricsRescheduleLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecruiterMetricsRescheduleLogsQuery, RecruiterMetricsRescheduleLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecruiterMetricsRescheduleLogsQuery, RecruiterMetricsRescheduleLogsQueryVariables>(RecruiterMetricsRescheduleLogsDocument, options);
        }
export type RecruiterMetricsRescheduleLogsQueryHookResult = ReturnType<typeof useRecruiterMetricsRescheduleLogsQuery>;
export type RecruiterMetricsRescheduleLogsLazyQueryHookResult = ReturnType<typeof useRecruiterMetricsRescheduleLogsLazyQuery>;
export type RecruiterMetricsRescheduleLogsQueryResult = Apollo.QueryResult<RecruiterMetricsRescheduleLogsQuery, RecruiterMetricsRescheduleLogsQueryVariables>;
export const MetricsPageInterviewerFiltersEmployeePrefDocument = gql`
    query MetricsPageInterviewerFiltersEmployeePref($prefName: String!) {
  thisEmployee {
    id
    employeePref(prefName: $prefName) {
      orgId
      employeeId
      prefName
      jsonPref {
        ... on MetricsPageInterviewerFiltersPref {
          ...MetricsPageInterviewerFiltersEmployeePref
        }
      }
    }
  }
}
    ${MetricsPageInterviewerFiltersEmployeePrefFragmentDoc}`;

/**
 * __useMetricsPageInterviewerFiltersEmployeePrefQuery__
 *
 * To run a query within a React component, call `useMetricsPageInterviewerFiltersEmployeePrefQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsPageInterviewerFiltersEmployeePrefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsPageInterviewerFiltersEmployeePrefQuery({
 *   variables: {
 *      prefName: // value for 'prefName'
 *   },
 * });
 */
export function useMetricsPageInterviewerFiltersEmployeePrefQuery(baseOptions: Apollo.QueryHookOptions<MetricsPageInterviewerFiltersEmployeePrefQuery, MetricsPageInterviewerFiltersEmployeePrefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricsPageInterviewerFiltersEmployeePrefQuery, MetricsPageInterviewerFiltersEmployeePrefQueryVariables>(MetricsPageInterviewerFiltersEmployeePrefDocument, options);
      }
export function useMetricsPageInterviewerFiltersEmployeePrefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricsPageInterviewerFiltersEmployeePrefQuery, MetricsPageInterviewerFiltersEmployeePrefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricsPageInterviewerFiltersEmployeePrefQuery, MetricsPageInterviewerFiltersEmployeePrefQueryVariables>(MetricsPageInterviewerFiltersEmployeePrefDocument, options);
        }
export type MetricsPageInterviewerFiltersEmployeePrefQueryHookResult = ReturnType<typeof useMetricsPageInterviewerFiltersEmployeePrefQuery>;
export type MetricsPageInterviewerFiltersEmployeePrefLazyQueryHookResult = ReturnType<typeof useMetricsPageInterviewerFiltersEmployeePrefLazyQuery>;
export type MetricsPageInterviewerFiltersEmployeePrefQueryResult = Apollo.QueryResult<MetricsPageInterviewerFiltersEmployeePrefQuery, MetricsPageInterviewerFiltersEmployeePrefQueryVariables>;
export const UpdateMetricsPageInterviewerFiltersEmployeePrefDocument = gql`
    mutation updateMetricsPageInterviewerFiltersEmployeePref($input: EmployeePrefUpdateInput!) {
  employeePrefUpdate(input: $input) {
    employeePref {
      orgId
      employeeId
      prefName
    }
  }
}
    `;
export type UpdateMetricsPageInterviewerFiltersEmployeePrefMutationFn = Apollo.MutationFunction<UpdateMetricsPageInterviewerFiltersEmployeePrefMutation, UpdateMetricsPageInterviewerFiltersEmployeePrefMutationVariables>;

/**
 * __useUpdateMetricsPageInterviewerFiltersEmployeePrefMutation__
 *
 * To run a mutation, you first call `useUpdateMetricsPageInterviewerFiltersEmployeePrefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMetricsPageInterviewerFiltersEmployeePrefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMetricsPageInterviewerFiltersEmployeePrefMutation, { data, loading, error }] = useUpdateMetricsPageInterviewerFiltersEmployeePrefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMetricsPageInterviewerFiltersEmployeePrefMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMetricsPageInterviewerFiltersEmployeePrefMutation, UpdateMetricsPageInterviewerFiltersEmployeePrefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMetricsPageInterviewerFiltersEmployeePrefMutation, UpdateMetricsPageInterviewerFiltersEmployeePrefMutationVariables>(UpdateMetricsPageInterviewerFiltersEmployeePrefDocument, options);
      }
export type UpdateMetricsPageInterviewerFiltersEmployeePrefMutationHookResult = ReturnType<typeof useUpdateMetricsPageInterviewerFiltersEmployeePrefMutation>;
export type UpdateMetricsPageInterviewerFiltersEmployeePrefMutationResult = Apollo.MutationResult<UpdateMetricsPageInterviewerFiltersEmployeePrefMutation>;
export type UpdateMetricsPageInterviewerFiltersEmployeePrefMutationOptions = Apollo.BaseMutationOptions<UpdateMetricsPageInterviewerFiltersEmployeePrefMutation, UpdateMetricsPageInterviewerFiltersEmployeePrefMutationVariables>;
export const MetricsPageRecruitingTeamFiltersEmployeePrefDocument = gql`
    query MetricsPageRecruitingTeamFiltersEmployeePref($prefName: String!) {
  thisEmployee {
    id
    employeePref(prefName: $prefName) {
      orgId
      employeeId
      prefName
      jsonPref {
        ... on MetricsPageRecruitingTeamFiltersPref {
          ...MetricsPageRecruitingTeamFiltersEmployeePref
        }
      }
    }
  }
}
    ${MetricsPageRecruitingTeamFiltersEmployeePrefFragmentDoc}`;

/**
 * __useMetricsPageRecruitingTeamFiltersEmployeePrefQuery__
 *
 * To run a query within a React component, call `useMetricsPageRecruitingTeamFiltersEmployeePrefQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetricsPageRecruitingTeamFiltersEmployeePrefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetricsPageRecruitingTeamFiltersEmployeePrefQuery({
 *   variables: {
 *      prefName: // value for 'prefName'
 *   },
 * });
 */
export function useMetricsPageRecruitingTeamFiltersEmployeePrefQuery(baseOptions: Apollo.QueryHookOptions<MetricsPageRecruitingTeamFiltersEmployeePrefQuery, MetricsPageRecruitingTeamFiltersEmployeePrefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetricsPageRecruitingTeamFiltersEmployeePrefQuery, MetricsPageRecruitingTeamFiltersEmployeePrefQueryVariables>(MetricsPageRecruitingTeamFiltersEmployeePrefDocument, options);
      }
export function useMetricsPageRecruitingTeamFiltersEmployeePrefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetricsPageRecruitingTeamFiltersEmployeePrefQuery, MetricsPageRecruitingTeamFiltersEmployeePrefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetricsPageRecruitingTeamFiltersEmployeePrefQuery, MetricsPageRecruitingTeamFiltersEmployeePrefQueryVariables>(MetricsPageRecruitingTeamFiltersEmployeePrefDocument, options);
        }
export type MetricsPageRecruitingTeamFiltersEmployeePrefQueryHookResult = ReturnType<typeof useMetricsPageRecruitingTeamFiltersEmployeePrefQuery>;
export type MetricsPageRecruitingTeamFiltersEmployeePrefLazyQueryHookResult = ReturnType<typeof useMetricsPageRecruitingTeamFiltersEmployeePrefLazyQuery>;
export type MetricsPageRecruitingTeamFiltersEmployeePrefQueryResult = Apollo.QueryResult<MetricsPageRecruitingTeamFiltersEmployeePrefQuery, MetricsPageRecruitingTeamFiltersEmployeePrefQueryVariables>;
export const UpdateMetricsPageRecruitingTeamFiltersEmployeePrefDocument = gql`
    mutation updateMetricsPageRecruitingTeamFiltersEmployeePref($input: EmployeePrefUpdateInput!) {
  employeePrefUpdate(input: $input) {
    employeePref {
      orgId
      employeeId
      prefName
    }
  }
}
    `;
export type UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutationFn = Apollo.MutationFunction<UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation, UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutationVariables>;

/**
 * __useUpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation__
 *
 * To run a mutation, you first call `useUpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMetricsPageRecruitingTeamFiltersEmployeePrefMutation, { data, loading, error }] = useUpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation, UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation, UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutationVariables>(UpdateMetricsPageRecruitingTeamFiltersEmployeePrefDocument, options);
      }
export type UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutationHookResult = ReturnType<typeof useUpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation>;
export type UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutationResult = Apollo.MutationResult<UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation>;
export type UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutationOptions = Apollo.BaseMutationOptions<UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutation, UpdateMetricsPageRecruitingTeamFiltersEmployeePrefMutationVariables>;
export const OmniSearchApplicationsDocument = gql`
    query OmniSearchApplications($input: ApplicationSearchInput!) {
  applicationSearch(input: $input) {
    total
    items {
      id
      candidate {
        fullName
      }
      ...OmniSearchOptionApplication_application
    }
  }
}
    ${OmniSearchOptionApplication_ApplicationFragmentDoc}`;

/**
 * __useOmniSearchApplicationsQuery__
 *
 * To run a query within a React component, call `useOmniSearchApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOmniSearchApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOmniSearchApplicationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOmniSearchApplicationsQuery(baseOptions: Apollo.QueryHookOptions<OmniSearchApplicationsQuery, OmniSearchApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OmniSearchApplicationsQuery, OmniSearchApplicationsQueryVariables>(OmniSearchApplicationsDocument, options);
      }
export function useOmniSearchApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OmniSearchApplicationsQuery, OmniSearchApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OmniSearchApplicationsQuery, OmniSearchApplicationsQueryVariables>(OmniSearchApplicationsDocument, options);
        }
export type OmniSearchApplicationsQueryHookResult = ReturnType<typeof useOmniSearchApplicationsQuery>;
export type OmniSearchApplicationsLazyQueryHookResult = ReturnType<typeof useOmniSearchApplicationsLazyQuery>;
export type OmniSearchApplicationsQueryResult = Apollo.QueryResult<OmniSearchApplicationsQuery, OmniSearchApplicationsQueryVariables>;
export const OmniSearchEmployeesDocument = gql`
    query OmniSearchEmployees($input: EmployeeSearchInput!) {
  employeeSearch(input: $input) {
    total
    items {
      id
      fullName
      ...OmniSearchOptionEmployee_employee
    }
  }
}
    ${OmniSearchOptionEmployee_EmployeeFragmentDoc}`;

/**
 * __useOmniSearchEmployeesQuery__
 *
 * To run a query within a React component, call `useOmniSearchEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOmniSearchEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOmniSearchEmployeesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOmniSearchEmployeesQuery(baseOptions: Apollo.QueryHookOptions<OmniSearchEmployeesQuery, OmniSearchEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OmniSearchEmployeesQuery, OmniSearchEmployeesQueryVariables>(OmniSearchEmployeesDocument, options);
      }
export function useOmniSearchEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OmniSearchEmployeesQuery, OmniSearchEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OmniSearchEmployeesQuery, OmniSearchEmployeesQueryVariables>(OmniSearchEmployeesDocument, options);
        }
export type OmniSearchEmployeesQueryHookResult = ReturnType<typeof useOmniSearchEmployeesQuery>;
export type OmniSearchEmployeesLazyQueryHookResult = ReturnType<typeof useOmniSearchEmployeesLazyQuery>;
export type OmniSearchEmployeesQueryResult = Apollo.QueryResult<OmniSearchEmployeesQuery, OmniSearchEmployeesQueryVariables>;
export const OmniSearchInterviewModulesDocument = gql`
    query OmniSearchInterviewModules($input: InterviewModuleSearchInput!) {
  interviewModuleSearch(input: $input) {
    total
    items {
      id
      name
      ...OmniSearchOptionInterviewModule_interviewModule
    }
  }
}
    ${OmniSearchOptionInterviewModule_InterviewModuleFragmentDoc}`;

/**
 * __useOmniSearchInterviewModulesQuery__
 *
 * To run a query within a React component, call `useOmniSearchInterviewModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOmniSearchInterviewModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOmniSearchInterviewModulesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOmniSearchInterviewModulesQuery(baseOptions: Apollo.QueryHookOptions<OmniSearchInterviewModulesQuery, OmniSearchInterviewModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OmniSearchInterviewModulesQuery, OmniSearchInterviewModulesQueryVariables>(OmniSearchInterviewModulesDocument, options);
      }
export function useOmniSearchInterviewModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OmniSearchInterviewModulesQuery, OmniSearchInterviewModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OmniSearchInterviewModulesQuery, OmniSearchInterviewModulesQueryVariables>(OmniSearchInterviewModulesDocument, options);
        }
export type OmniSearchInterviewModulesQueryHookResult = ReturnType<typeof useOmniSearchInterviewModulesQuery>;
export type OmniSearchInterviewModulesLazyQueryHookResult = ReturnType<typeof useOmniSearchInterviewModulesLazyQuery>;
export type OmniSearchInterviewModulesQueryResult = Apollo.QueryResult<OmniSearchInterviewModulesQuery, OmniSearchInterviewModulesQueryVariables>;
export const OmniSearchJobsDocument = gql`
    query OmniSearchJobs($input: JobsInput!) {
  jobs(input: $input) {
    items {
      id
      name
      ...OmniSearchOptionJob_job
    }
    nextCursor
  }
}
    ${OmniSearchOptionJob_JobFragmentDoc}`;

/**
 * __useOmniSearchJobsQuery__
 *
 * To run a query within a React component, call `useOmniSearchJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOmniSearchJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOmniSearchJobsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOmniSearchJobsQuery(baseOptions: Apollo.QueryHookOptions<OmniSearchJobsQuery, OmniSearchJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OmniSearchJobsQuery, OmniSearchJobsQueryVariables>(OmniSearchJobsDocument, options);
      }
export function useOmniSearchJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OmniSearchJobsQuery, OmniSearchJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OmniSearchJobsQuery, OmniSearchJobsQueryVariables>(OmniSearchJobsDocument, options);
        }
export type OmniSearchJobsQueryHookResult = ReturnType<typeof useOmniSearchJobsQuery>;
export type OmniSearchJobsLazyQueryHookResult = ReturnType<typeof useOmniSearchJobsLazyQuery>;
export type OmniSearchJobsQueryResult = Apollo.QueryResult<OmniSearchJobsQuery, OmniSearchJobsQueryVariables>;
export const RescheduleFlowDocument = gql`
    query RescheduleFlow($id: uuid!, $fetchInterviewPlan: Boolean!) {
  applicationStage(id: $id) {
    id
    application {
      id
      candidate {
        id
        timezone
        atsLatestAvailability {
          ...useCandidateAvailability_atsLatestAvailability
          timezone
          originalText
        }
        ...ScheduleFlowDataProvider_candidate
      }
    }
    jobStage {
      id
      name
      job {
        id
        name
        atsId
        ...ScheduleFlowDataProvider_job
      }
      jobStageSettingsV2 {
        id
        ...ScheduleFlowDataProvider_jobStageSettings
      }
    }
    task {
      id
      availabilities {
        id
        ...useCandidateAvailability_availabilities
      }
      interviewPlan {
        id
        ...ScheduleFlowDataProvider_interviewPlan @include(if: $fetchInterviewPlan)
      }
    }
  }
}
    ${UseCandidateAvailability_AtsLatestAvailabilityFragmentDoc}
${ScheduleFlowDataProvider_CandidateFragmentDoc}
${ScheduleFlowDataProvider_JobFragmentDoc}
${ScheduleFlowDataProvider_JobStageSettingsFragmentDoc}
${UseCandidateAvailability_AvailabilitiesFragmentDoc}
${ScheduleFlowDataProvider_InterviewPlanFragmentDoc}`;

/**
 * __useRescheduleFlowQuery__
 *
 * To run a query within a React component, call `useRescheduleFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useRescheduleFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRescheduleFlowQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fetchInterviewPlan: // value for 'fetchInterviewPlan'
 *   },
 * });
 */
export function useRescheduleFlowQuery(baseOptions: Apollo.QueryHookOptions<RescheduleFlowQuery, RescheduleFlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RescheduleFlowQuery, RescheduleFlowQueryVariables>(RescheduleFlowDocument, options);
      }
export function useRescheduleFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RescheduleFlowQuery, RescheduleFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RescheduleFlowQuery, RescheduleFlowQueryVariables>(RescheduleFlowDocument, options);
        }
export type RescheduleFlowQueryHookResult = ReturnType<typeof useRescheduleFlowQuery>;
export type RescheduleFlowLazyQueryHookResult = ReturnType<typeof useRescheduleFlowLazyQuery>;
export type RescheduleFlowQueryResult = Apollo.QueryResult<RescheduleFlowQuery, RescheduleFlowQueryVariables>;
export const JobStageSettingsDocument = gql`
    query JobStageSettings($jobStageId: uuid!) {
  jobStage(id: $jobStageId) {
    id
    jobStageSettings {
      ...JobStageSettingsFragment
    }
  }
}
    ${JobStageSettingsFragmentFragmentDoc}`;

/**
 * __useJobStageSettingsQuery__
 *
 * To run a query within a React component, call `useJobStageSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobStageSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobStageSettingsQuery({
 *   variables: {
 *      jobStageId: // value for 'jobStageId'
 *   },
 * });
 */
export function useJobStageSettingsQuery(baseOptions: Apollo.QueryHookOptions<JobStageSettingsQuery, JobStageSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobStageSettingsQuery, JobStageSettingsQueryVariables>(JobStageSettingsDocument, options);
      }
export function useJobStageSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobStageSettingsQuery, JobStageSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobStageSettingsQuery, JobStageSettingsQueryVariables>(JobStageSettingsDocument, options);
        }
export type JobStageSettingsQueryHookResult = ReturnType<typeof useJobStageSettingsQuery>;
export type JobStageSettingsLazyQueryHookResult = ReturnType<typeof useJobStageSettingsLazyQuery>;
export type JobStageSettingsQueryResult = Apollo.QueryResult<JobStageSettingsQuery, JobStageSettingsQueryVariables>;
export const TaskDebriefRequirementDocument = gql`
    query TaskDebriefRequirement($applicationId: uuid!, $taskId: uuid!) {
  application(id: $applicationId) {
    id
    applicationStages(input: {orderBy: {createdAt: DESC}}) {
      id
      isHoldForRsvp
      isReadyForCandidateComms
    }
    candidate {
      id
    }
  }
  task(id: $taskId) {
    isDebriefRequired
    debriefs {
      id
    }
  }
}
    `;

/**
 * __useTaskDebriefRequirementQuery__
 *
 * To run a query within a React component, call `useTaskDebriefRequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDebriefRequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDebriefRequirementQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskDebriefRequirementQuery(baseOptions: Apollo.QueryHookOptions<TaskDebriefRequirementQuery, TaskDebriefRequirementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskDebriefRequirementQuery, TaskDebriefRequirementQueryVariables>(TaskDebriefRequirementDocument, options);
      }
export function useTaskDebriefRequirementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskDebriefRequirementQuery, TaskDebriefRequirementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskDebriefRequirementQuery, TaskDebriefRequirementQueryVariables>(TaskDebriefRequirementDocument, options);
        }
export type TaskDebriefRequirementQueryHookResult = ReturnType<typeof useTaskDebriefRequirementQuery>;
export type TaskDebriefRequirementLazyQueryHookResult = ReturnType<typeof useTaskDebriefRequirementLazyQuery>;
export type TaskDebriefRequirementQueryResult = Apollo.QueryResult<TaskDebriefRequirementQuery, TaskDebriefRequirementQueryVariables>;
export const ScheduleFlowHeaderDocument = gql`
    query scheduleFlowHeader($id: uuid!) {
  application(id: $id) {
    id
    candidate {
      id
      fullName
      timezone
      email
      additionalEmails
      recruiterId
      atsCandidate {
        atsId
        atsUrl
      }
    }
    jobStage {
      id
      name
    }
    job {
      id
      name
      isConfidential
    }
    existingConversations {
      id
      remoteId
      remoteName
      isArchived
    }
  }
}
    `;

/**
 * __useScheduleFlowHeaderQuery__
 *
 * To run a query within a React component, call `useScheduleFlowHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleFlowHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleFlowHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScheduleFlowHeaderQuery(baseOptions: Apollo.QueryHookOptions<ScheduleFlowHeaderQuery, ScheduleFlowHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleFlowHeaderQuery, ScheduleFlowHeaderQueryVariables>(ScheduleFlowHeaderDocument, options);
      }
export function useScheduleFlowHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleFlowHeaderQuery, ScheduleFlowHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleFlowHeaderQuery, ScheduleFlowHeaderQueryVariables>(ScheduleFlowHeaderDocument, options);
        }
export type ScheduleFlowHeaderQueryHookResult = ReturnType<typeof useScheduleFlowHeaderQuery>;
export type ScheduleFlowHeaderLazyQueryHookResult = ReturnType<typeof useScheduleFlowHeaderLazyQuery>;
export type ScheduleFlowHeaderQueryResult = Apollo.QueryResult<ScheduleFlowHeaderQuery, ScheduleFlowHeaderQueryVariables>;
export const JobStageNameDocument = gql`
    query jobStageName($id: uuid!) {
  jobStage(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useJobStageNameQuery__
 *
 * To run a query within a React component, call `useJobStageNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobStageNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobStageNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobStageNameQuery(baseOptions: Apollo.QueryHookOptions<JobStageNameQuery, JobStageNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobStageNameQuery, JobStageNameQueryVariables>(JobStageNameDocument, options);
      }
export function useJobStageNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobStageNameQuery, JobStageNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobStageNameQuery, JobStageNameQueryVariables>(JobStageNameDocument, options);
        }
export type JobStageNameQueryHookResult = ReturnType<typeof useJobStageNameQuery>;
export type JobStageNameLazyQueryHookResult = ReturnType<typeof useJobStageNameLazyQuery>;
export type JobStageNameQueryResult = Apollo.QueryResult<JobStageNameQuery, JobStageNameQueryVariables>;
export const CandidateAvailabilityApplicationTasksDocument = gql`
    query CandidateAvailabilityApplicationTasks($applicationId: uuid!) {
  application(id: $applicationId) {
    tasks {
      id
      availabilities {
        id
        candidateEnteredAvailability {
          startAt
          endAt
        }
        rcEnteredAvailability {
          startAt
          endAt
        }
      }
    }
  }
}
    `;

/**
 * __useCandidateAvailabilityApplicationTasksQuery__
 *
 * To run a query within a React component, call `useCandidateAvailabilityApplicationTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateAvailabilityApplicationTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateAvailabilityApplicationTasksQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useCandidateAvailabilityApplicationTasksQuery(baseOptions: Apollo.QueryHookOptions<CandidateAvailabilityApplicationTasksQuery, CandidateAvailabilityApplicationTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateAvailabilityApplicationTasksQuery, CandidateAvailabilityApplicationTasksQueryVariables>(CandidateAvailabilityApplicationTasksDocument, options);
      }
export function useCandidateAvailabilityApplicationTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateAvailabilityApplicationTasksQuery, CandidateAvailabilityApplicationTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateAvailabilityApplicationTasksQuery, CandidateAvailabilityApplicationTasksQueryVariables>(CandidateAvailabilityApplicationTasksDocument, options);
        }
export type CandidateAvailabilityApplicationTasksQueryHookResult = ReturnType<typeof useCandidateAvailabilityApplicationTasksQuery>;
export type CandidateAvailabilityApplicationTasksLazyQueryHookResult = ReturnType<typeof useCandidateAvailabilityApplicationTasksLazyQuery>;
export type CandidateAvailabilityApplicationTasksQueryResult = Apollo.QueryResult<CandidateAvailabilityApplicationTasksQuery, CandidateAvailabilityApplicationTasksQueryVariables>;
export const InterviewInterviewerDeclinedDocument = gql`
    query InterviewInterviewerDeclined($id: uuid!) {
  applicationStage(id: $id) {
    id
    applicationStageInterviews(input: {isCancelled: false}) {
      ...ScheduleIssuesDialogApplicationStageInterview
      applicationStageInterviewInterviewers {
        ...ScheduleIssuesDialogApplicationStageInterviewInterviewers
      }
    }
  }
}
    ${ScheduleIssuesDialogApplicationStageInterviewFragmentDoc}
${ScheduleIssuesDialogApplicationStageInterviewInterviewersFragmentDoc}`;

/**
 * __useInterviewInterviewerDeclinedQuery__
 *
 * To run a query within a React component, call `useInterviewInterviewerDeclinedQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewInterviewerDeclinedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewInterviewerDeclinedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInterviewInterviewerDeclinedQuery(baseOptions: Apollo.QueryHookOptions<InterviewInterviewerDeclinedQuery, InterviewInterviewerDeclinedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewInterviewerDeclinedQuery, InterviewInterviewerDeclinedQueryVariables>(InterviewInterviewerDeclinedDocument, options);
      }
export function useInterviewInterviewerDeclinedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewInterviewerDeclinedQuery, InterviewInterviewerDeclinedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewInterviewerDeclinedQuery, InterviewInterviewerDeclinedQueryVariables>(InterviewInterviewerDeclinedDocument, options);
        }
export type InterviewInterviewerDeclinedQueryHookResult = ReturnType<typeof useInterviewInterviewerDeclinedQuery>;
export type InterviewInterviewerDeclinedLazyQueryHookResult = ReturnType<typeof useInterviewInterviewerDeclinedLazyQuery>;
export type InterviewInterviewerDeclinedQueryResult = Apollo.QueryResult<InterviewInterviewerDeclinedQuery, InterviewInterviewerDeclinedQueryVariables>;
export const GetWarningModalCalendarDocument = gql`
    query GetWarningModalCalendar($id: String!) {
  calendar(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetWarningModalCalendarQuery__
 *
 * To run a query within a React component, call `useGetWarningModalCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarningModalCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarningModalCalendarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWarningModalCalendarQuery(baseOptions: Apollo.QueryHookOptions<GetWarningModalCalendarQuery, GetWarningModalCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarningModalCalendarQuery, GetWarningModalCalendarQueryVariables>(GetWarningModalCalendarDocument, options);
      }
export function useGetWarningModalCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarningModalCalendarQuery, GetWarningModalCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarningModalCalendarQuery, GetWarningModalCalendarQueryVariables>(GetWarningModalCalendarDocument, options);
        }
export type GetWarningModalCalendarQueryHookResult = ReturnType<typeof useGetWarningModalCalendarQuery>;
export type GetWarningModalCalendarLazyQueryHookResult = ReturnType<typeof useGetWarningModalCalendarLazyQuery>;
export type GetWarningModalCalendarQueryResult = Apollo.QueryResult<GetWarningModalCalendarQuery, GetWarningModalCalendarQueryVariables>;
export const HasApplicationStageInterviewDocument = gql`
    query HasApplicationStageInterview($id: uuid!) {
  applicationStage(id: $id) {
    id
    applicationStageInterviews(input: {isCancelled: false}) {
      id
    }
  }
}
    `;

/**
 * __useHasApplicationStageInterviewQuery__
 *
 * To run a query within a React component, call `useHasApplicationStageInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasApplicationStageInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasApplicationStageInterviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHasApplicationStageInterviewQuery(baseOptions: Apollo.QueryHookOptions<HasApplicationStageInterviewQuery, HasApplicationStageInterviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasApplicationStageInterviewQuery, HasApplicationStageInterviewQueryVariables>(HasApplicationStageInterviewDocument, options);
      }
export function useHasApplicationStageInterviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasApplicationStageInterviewQuery, HasApplicationStageInterviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasApplicationStageInterviewQuery, HasApplicationStageInterviewQueryVariables>(HasApplicationStageInterviewDocument, options);
        }
export type HasApplicationStageInterviewQueryHookResult = ReturnType<typeof useHasApplicationStageInterviewQuery>;
export type HasApplicationStageInterviewLazyQueryHookResult = ReturnType<typeof useHasApplicationStageInterviewLazyQuery>;
export type HasApplicationStageInterviewQueryResult = Apollo.QueryResult<HasApplicationStageInterviewQuery, HasApplicationStageInterviewQueryVariables>;
export const GetApplicationTaskQueueDocument = gql`
    query GetApplicationTaskQueue($applicationId: uuid!) {
  application(id: $applicationId) {
    id
    job {
      id
      jobSettings {
        defaultTaskQueue {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetApplicationTaskQueueQuery__
 *
 * To run a query within a React component, call `useGetApplicationTaskQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationTaskQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationTaskQueueQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGetApplicationTaskQueueQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationTaskQueueQuery, GetApplicationTaskQueueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationTaskQueueQuery, GetApplicationTaskQueueQueryVariables>(GetApplicationTaskQueueDocument, options);
      }
export function useGetApplicationTaskQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationTaskQueueQuery, GetApplicationTaskQueueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationTaskQueueQuery, GetApplicationTaskQueueQueryVariables>(GetApplicationTaskQueueDocument, options);
        }
export type GetApplicationTaskQueueQueryHookResult = ReturnType<typeof useGetApplicationTaskQueueQuery>;
export type GetApplicationTaskQueueLazyQueryHookResult = ReturnType<typeof useGetApplicationTaskQueueLazyQuery>;
export type GetApplicationTaskQueueQueryResult = Apollo.QueryResult<GetApplicationTaskQueueQuery, GetApplicationTaskQueueQueryVariables>;
export const ScheduleNowTaskCreateDocument = gql`
    mutation ScheduleNowTaskCreate($input: TaskCreateInput!) {
  taskCreate(input: $input) {
    task {
      id
    }
  }
}
    `;
export type ScheduleNowTaskCreateMutationFn = Apollo.MutationFunction<ScheduleNowTaskCreateMutation, ScheduleNowTaskCreateMutationVariables>;

/**
 * __useScheduleNowTaskCreateMutation__
 *
 * To run a mutation, you first call `useScheduleNowTaskCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleNowTaskCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleNowTaskCreateMutation, { data, loading, error }] = useScheduleNowTaskCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleNowTaskCreateMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleNowTaskCreateMutation, ScheduleNowTaskCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleNowTaskCreateMutation, ScheduleNowTaskCreateMutationVariables>(ScheduleNowTaskCreateDocument, options);
      }
export type ScheduleNowTaskCreateMutationHookResult = ReturnType<typeof useScheduleNowTaskCreateMutation>;
export type ScheduleNowTaskCreateMutationResult = Apollo.MutationResult<ScheduleNowTaskCreateMutation>;
export type ScheduleNowTaskCreateMutationOptions = Apollo.BaseMutationOptions<ScheduleNowTaskCreateMutation, ScheduleNowTaskCreateMutationVariables>;
export const CreateScheduleDocument = gql`
    mutation CreateSchedule($input: ScheduleCreateInput!) {
  scheduleCreate(input: $input) {
    applicationStageId
    debriefRescheduleDetails {
      hasScheduleTimeAfterDebriefStartAt
      hasAttendeeAdded
    }
  }
}
    `;
export type CreateScheduleMutationFn = Apollo.MutationFunction<CreateScheduleMutation, CreateScheduleMutationVariables>;

/**
 * __useCreateScheduleMutation__
 *
 * To run a mutation, you first call `useCreateScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleMutation, { data, loading, error }] = useCreateScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduleMutation(baseOptions?: Apollo.MutationHookOptions<CreateScheduleMutation, CreateScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScheduleMutation, CreateScheduleMutationVariables>(CreateScheduleDocument, options);
      }
export type CreateScheduleMutationHookResult = ReturnType<typeof useCreateScheduleMutation>;
export type CreateScheduleMutationResult = Apollo.MutationResult<CreateScheduleMutation>;
export type CreateScheduleMutationOptions = Apollo.BaseMutationOptions<CreateScheduleMutation, CreateScheduleMutationVariables>;
export const RescheduleDocument = gql`
    mutation Reschedule($input: ScheduleRescheduleInput!) {
  scheduleReschedule(input: $input) {
    applicationStageId
    debriefRescheduleDetails {
      hasScheduleTimeAfterDebriefStartAt
      hasAttendeeAdded
    }
  }
}
    `;
export type RescheduleMutationFn = Apollo.MutationFunction<RescheduleMutation, RescheduleMutationVariables>;

/**
 * __useRescheduleMutation__
 *
 * To run a mutation, you first call `useRescheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleMutation, { data, loading, error }] = useRescheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleMutation, RescheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleMutation, RescheduleMutationVariables>(RescheduleDocument, options);
      }
export type RescheduleMutationHookResult = ReturnType<typeof useRescheduleMutation>;
export type RescheduleMutationResult = Apollo.MutationResult<RescheduleMutation>;
export type RescheduleMutationOptions = Apollo.BaseMutationOptions<RescheduleMutation, RescheduleMutationVariables>;
export const CreateScheduleSaveInterviewPlanDocument = gql`
    mutation CreateScheduleSaveInterviewPlan($input: JobStageInterviewPlanUpsertInput!) {
  jobStageInterviewPlanUpsert(input: $input) {
    jobStage {
      id
    }
  }
}
    `;
export type CreateScheduleSaveInterviewPlanMutationFn = Apollo.MutationFunction<CreateScheduleSaveInterviewPlanMutation, CreateScheduleSaveInterviewPlanMutationVariables>;

/**
 * __useCreateScheduleSaveInterviewPlanMutation__
 *
 * To run a mutation, you first call `useCreateScheduleSaveInterviewPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleSaveInterviewPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleSaveInterviewPlanMutation, { data, loading, error }] = useCreateScheduleSaveInterviewPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduleSaveInterviewPlanMutation(baseOptions?: Apollo.MutationHookOptions<CreateScheduleSaveInterviewPlanMutation, CreateScheduleSaveInterviewPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScheduleSaveInterviewPlanMutation, CreateScheduleSaveInterviewPlanMutationVariables>(CreateScheduleSaveInterviewPlanDocument, options);
      }
export type CreateScheduleSaveInterviewPlanMutationHookResult = ReturnType<typeof useCreateScheduleSaveInterviewPlanMutation>;
export type CreateScheduleSaveInterviewPlanMutationResult = Apollo.MutationResult<CreateScheduleSaveInterviewPlanMutation>;
export type CreateScheduleSaveInterviewPlanMutationOptions = Apollo.BaseMutationOptions<CreateScheduleSaveInterviewPlanMutation, CreateScheduleSaveInterviewPlanMutationVariables>;
export const CustomInterviewMeetingLocationCreateDocument = gql`
    mutation CustomInterviewMeetingLocationCreate($input: MeetingLocationCreateInput!) {
  meetingLocationCreate(input: $input) {
    meetingLocation {
      id
      type
      videoMeetingLink
      details {
        customLocation
      }
    }
  }
}
    `;
export type CustomInterviewMeetingLocationCreateMutationFn = Apollo.MutationFunction<CustomInterviewMeetingLocationCreateMutation, CustomInterviewMeetingLocationCreateMutationVariables>;

/**
 * __useCustomInterviewMeetingLocationCreateMutation__
 *
 * To run a mutation, you first call `useCustomInterviewMeetingLocationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomInterviewMeetingLocationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customInterviewMeetingLocationCreateMutation, { data, loading, error }] = useCustomInterviewMeetingLocationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomInterviewMeetingLocationCreateMutation(baseOptions?: Apollo.MutationHookOptions<CustomInterviewMeetingLocationCreateMutation, CustomInterviewMeetingLocationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomInterviewMeetingLocationCreateMutation, CustomInterviewMeetingLocationCreateMutationVariables>(CustomInterviewMeetingLocationCreateDocument, options);
      }
export type CustomInterviewMeetingLocationCreateMutationHookResult = ReturnType<typeof useCustomInterviewMeetingLocationCreateMutation>;
export type CustomInterviewMeetingLocationCreateMutationResult = Apollo.MutationResult<CustomInterviewMeetingLocationCreateMutation>;
export type CustomInterviewMeetingLocationCreateMutationOptions = Apollo.BaseMutationOptions<CustomInterviewMeetingLocationCreateMutation, CustomInterviewMeetingLocationCreateMutationVariables>;
export const CalendarMeetingCreateDocument = gql`
    mutation CalendarMeetingCreate($input: CalendarMeetingCreateInput!) {
  calendarMeetingCreate(input: $input) {
    url
  }
}
    `;
export type CalendarMeetingCreateMutationFn = Apollo.MutationFunction<CalendarMeetingCreateMutation, CalendarMeetingCreateMutationVariables>;

/**
 * __useCalendarMeetingCreateMutation__
 *
 * To run a mutation, you first call `useCalendarMeetingCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalendarMeetingCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calendarMeetingCreateMutation, { data, loading, error }] = useCalendarMeetingCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalendarMeetingCreateMutation(baseOptions?: Apollo.MutationHookOptions<CalendarMeetingCreateMutation, CalendarMeetingCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CalendarMeetingCreateMutation, CalendarMeetingCreateMutationVariables>(CalendarMeetingCreateDocument, options);
      }
export type CalendarMeetingCreateMutationHookResult = ReturnType<typeof useCalendarMeetingCreateMutation>;
export type CalendarMeetingCreateMutationResult = Apollo.MutationResult<CalendarMeetingCreateMutation>;
export type CalendarMeetingCreateMutationOptions = Apollo.BaseMutationOptions<CalendarMeetingCreateMutation, CalendarMeetingCreateMutationVariables>;
export const VideoInterviewMeetingLocationCreateDocument = gql`
    mutation VideoInterviewMeetingLocationCreate($input: MeetingLocationCreateInput!) {
  meetingLocationCreate(input: $input) {
    meetingLocation {
      id
      type
      videoMeetingLink
    }
  }
}
    `;
export type VideoInterviewMeetingLocationCreateMutationFn = Apollo.MutationFunction<VideoInterviewMeetingLocationCreateMutation, VideoInterviewMeetingLocationCreateMutationVariables>;

/**
 * __useVideoInterviewMeetingLocationCreateMutation__
 *
 * To run a mutation, you first call `useVideoInterviewMeetingLocationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoInterviewMeetingLocationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoInterviewMeetingLocationCreateMutation, { data, loading, error }] = useVideoInterviewMeetingLocationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVideoInterviewMeetingLocationCreateMutation(baseOptions?: Apollo.MutationHookOptions<VideoInterviewMeetingLocationCreateMutation, VideoInterviewMeetingLocationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoInterviewMeetingLocationCreateMutation, VideoInterviewMeetingLocationCreateMutationVariables>(VideoInterviewMeetingLocationCreateDocument, options);
      }
export type VideoInterviewMeetingLocationCreateMutationHookResult = ReturnType<typeof useVideoInterviewMeetingLocationCreateMutation>;
export type VideoInterviewMeetingLocationCreateMutationResult = Apollo.MutationResult<VideoInterviewMeetingLocationCreateMutation>;
export type VideoInterviewMeetingLocationCreateMutationOptions = Apollo.BaseMutationOptions<VideoInterviewMeetingLocationCreateMutation, VideoInterviewMeetingLocationCreateMutationVariables>;
export const ZoomMeetingCreateDocument = gql`
    mutation ZoomMeetingCreate($input: ZoomMeetingCreateInput!) {
  zoomMeetingCreate(input: $input) {
    joinUrl
    meetingId
    password
    pstnPassword
    dialInfo {
      country
      countryName
      city
      number
      type
    }
  }
}
    `;
export type ZoomMeetingCreateMutationFn = Apollo.MutationFunction<ZoomMeetingCreateMutation, ZoomMeetingCreateMutationVariables>;

/**
 * __useZoomMeetingCreateMutation__
 *
 * To run a mutation, you first call `useZoomMeetingCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZoomMeetingCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zoomMeetingCreateMutation, { data, loading, error }] = useZoomMeetingCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useZoomMeetingCreateMutation(baseOptions?: Apollo.MutationHookOptions<ZoomMeetingCreateMutation, ZoomMeetingCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ZoomMeetingCreateMutation, ZoomMeetingCreateMutationVariables>(ZoomMeetingCreateDocument, options);
      }
export type ZoomMeetingCreateMutationHookResult = ReturnType<typeof useZoomMeetingCreateMutation>;
export type ZoomMeetingCreateMutationResult = Apollo.MutationResult<ZoomMeetingCreateMutation>;
export type ZoomMeetingCreateMutationOptions = Apollo.BaseMutationOptions<ZoomMeetingCreateMutation, ZoomMeetingCreateMutationVariables>;
export const ZoomInterviewMeetingLocationCreateDocument = gql`
    mutation ZoomInterviewMeetingLocationCreate($input: MeetingLocationCreateInput!) {
  meetingLocationCreate(input: $input) {
    meetingLocation {
      id
      type
      videoMeetingLink
      details {
        zoomInfo {
          password
          meetingId
          userId
          pstnPassword
          dialInfo {
            type
            number
            city
            countryName
            country
          }
        }
      }
    }
  }
}
    `;
export type ZoomInterviewMeetingLocationCreateMutationFn = Apollo.MutationFunction<ZoomInterviewMeetingLocationCreateMutation, ZoomInterviewMeetingLocationCreateMutationVariables>;

/**
 * __useZoomInterviewMeetingLocationCreateMutation__
 *
 * To run a mutation, you first call `useZoomInterviewMeetingLocationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZoomInterviewMeetingLocationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zoomInterviewMeetingLocationCreateMutation, { data, loading, error }] = useZoomInterviewMeetingLocationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useZoomInterviewMeetingLocationCreateMutation(baseOptions?: Apollo.MutationHookOptions<ZoomInterviewMeetingLocationCreateMutation, ZoomInterviewMeetingLocationCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ZoomInterviewMeetingLocationCreateMutation, ZoomInterviewMeetingLocationCreateMutationVariables>(ZoomInterviewMeetingLocationCreateDocument, options);
      }
export type ZoomInterviewMeetingLocationCreateMutationHookResult = ReturnType<typeof useZoomInterviewMeetingLocationCreateMutation>;
export type ZoomInterviewMeetingLocationCreateMutationResult = Apollo.MutationResult<ZoomInterviewMeetingLocationCreateMutation>;
export type ZoomInterviewMeetingLocationCreateMutationOptions = Apollo.BaseMutationOptions<ZoomInterviewMeetingLocationCreateMutation, ZoomInterviewMeetingLocationCreateMutationVariables>;
export const InterviewMeetingLocationZoomUsersByEmployeeIdsDocument = gql`
    query InterviewMeetingLocationZoomUsersByEmployeeIds($employeeIds: [uuid!]) {
  zoomUsersByIds(input: {employeeIds: $employeeIds}) {
    employeeId
    zoomUserId
  }
}
    `;

/**
 * __useInterviewMeetingLocationZoomUsersByEmployeeIdsQuery__
 *
 * To run a query within a React component, call `useInterviewMeetingLocationZoomUsersByEmployeeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewMeetingLocationZoomUsersByEmployeeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewMeetingLocationZoomUsersByEmployeeIdsQuery({
 *   variables: {
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useInterviewMeetingLocationZoomUsersByEmployeeIdsQuery(baseOptions?: Apollo.QueryHookOptions<InterviewMeetingLocationZoomUsersByEmployeeIdsQuery, InterviewMeetingLocationZoomUsersByEmployeeIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewMeetingLocationZoomUsersByEmployeeIdsQuery, InterviewMeetingLocationZoomUsersByEmployeeIdsQueryVariables>(InterviewMeetingLocationZoomUsersByEmployeeIdsDocument, options);
      }
export function useInterviewMeetingLocationZoomUsersByEmployeeIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewMeetingLocationZoomUsersByEmployeeIdsQuery, InterviewMeetingLocationZoomUsersByEmployeeIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewMeetingLocationZoomUsersByEmployeeIdsQuery, InterviewMeetingLocationZoomUsersByEmployeeIdsQueryVariables>(InterviewMeetingLocationZoomUsersByEmployeeIdsDocument, options);
        }
export type InterviewMeetingLocationZoomUsersByEmployeeIdsQueryHookResult = ReturnType<typeof useInterviewMeetingLocationZoomUsersByEmployeeIdsQuery>;
export type InterviewMeetingLocationZoomUsersByEmployeeIdsLazyQueryHookResult = ReturnType<typeof useInterviewMeetingLocationZoomUsersByEmployeeIdsLazyQuery>;
export type InterviewMeetingLocationZoomUsersByEmployeeIdsQueryResult = Apollo.QueryResult<InterviewMeetingLocationZoomUsersByEmployeeIdsQuery, InterviewMeetingLocationZoomUsersByEmployeeIdsQueryVariables>;
export const SlackChannelByNameDocument = gql`
    query SlackChannelByName($name: String!) {
  slackChannelByName(name: $name) {
    id
    name
  }
}
    `;

/**
 * __useSlackChannelByNameQuery__
 *
 * To run a query within a React component, call `useSlackChannelByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlackChannelByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlackChannelByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSlackChannelByNameQuery(baseOptions: Apollo.QueryHookOptions<SlackChannelByNameQuery, SlackChannelByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlackChannelByNameQuery, SlackChannelByNameQueryVariables>(SlackChannelByNameDocument, options);
      }
export function useSlackChannelByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlackChannelByNameQuery, SlackChannelByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlackChannelByNameQuery, SlackChannelByNameQueryVariables>(SlackChannelByNameDocument, options);
        }
export type SlackChannelByNameQueryHookResult = ReturnType<typeof useSlackChannelByNameQuery>;
export type SlackChannelByNameLazyQueryHookResult = ReturnType<typeof useSlackChannelByNameLazyQuery>;
export type SlackChannelByNameQueryResult = Apollo.QueryResult<SlackChannelByNameQuery, SlackChannelByNameQueryVariables>;
export const ExistingSlackChannelsDocument = gql`
    query ExistingSlackChannels($applicationId: uuid!) {
  application(id: $applicationId) {
    existingConversations {
      id
      remoteId
      remoteName
      isArchived
    }
  }
}
    `;

/**
 * __useExistingSlackChannelsQuery__
 *
 * To run a query within a React component, call `useExistingSlackChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistingSlackChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistingSlackChannelsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useExistingSlackChannelsQuery(baseOptions: Apollo.QueryHookOptions<ExistingSlackChannelsQuery, ExistingSlackChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExistingSlackChannelsQuery, ExistingSlackChannelsQueryVariables>(ExistingSlackChannelsDocument, options);
      }
export function useExistingSlackChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExistingSlackChannelsQuery, ExistingSlackChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExistingSlackChannelsQuery, ExistingSlackChannelsQueryVariables>(ExistingSlackChannelsDocument, options);
        }
export type ExistingSlackChannelsQueryHookResult = ReturnType<typeof useExistingSlackChannelsQuery>;
export type ExistingSlackChannelsLazyQueryHookResult = ReturnType<typeof useExistingSlackChannelsLazyQuery>;
export type ExistingSlackChannelsQueryResult = Apollo.QueryResult<ExistingSlackChannelsQuery, ExistingSlackChannelsQueryVariables>;
export const InterviewerSuggestionsDocument = gql`
    query InterviewerSuggestions($input: InterviewerSuggestionsInput!, $interviewModuleId: uuid!) {
  interviewerSuggestions(input: $input) {
    employees {
      companyHolidays {
        id
        ...getTextForFlag_companyHoliday
      }
      role
      loadAndLimit {
        dailyLoad
        weeklyLoad
        lastWeeklyLoad
        dailyLoadMinutes
        weeklyLoadMinutes
        lastWeeklyLoadMinutes
        dailyInterviewLimit {
          type
          limit
        }
        weeklyInterviewLimit {
          type
          limit
        }
      }
      employeeId
      employee {
        id
        fullName
        givenName
        linkedinUrl
        slackImageUrl
        timezone
        email
        title
        interviewModuleMember(interviewModuleId: $interviewModuleId) {
          interviewModuleId
          employeeId
          stats {
            trainingStatus
            currentTrainingSessionStatus
            completedAsShadow
            completedAsReverseShadow
            shadowOffset
            reverseShadowOffset
          }
          ...isModuleMemberDisabledForScheduling_ModuleMember
          ...ModuleMember_interviewModuleMember
        }
      }
      flags
      eventsWithConflicts {
        flag
        events
      }
    }
    others {
      companyHolidays {
        id
        ...getTextForFlag_companyHoliday
      }
      role
      loadAndLimit {
        dailyLoad
        weeklyLoad
        lastWeeklyLoad
        dailyLoadMinutes
        weeklyLoadMinutes
        lastWeeklyLoadMinutes
        dailyInterviewLimit {
          type
          limit
        }
        weeklyInterviewLimit {
          type
          limit
        }
      }
      employeeId
      employee {
        id
        fullName
        givenName
        linkedinUrl
        slackImageUrl
        timezone
        email
        title
      }
      flags
      eventsWithConflicts {
        flag
        events
      }
    }
  }
}
    ${GetTextForFlag_CompanyHolidayFragmentDoc}
${IsModuleMemberDisabledForScheduling_ModuleMemberFragmentDoc}
${ModuleMember_InterviewModuleMemberFragmentDoc}`;

/**
 * __useInterviewerSuggestionsQuery__
 *
 * To run a query within a React component, call `useInterviewerSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewerSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewerSuggestionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      interviewModuleId: // value for 'interviewModuleId'
 *   },
 * });
 */
export function useInterviewerSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<InterviewerSuggestionsQuery, InterviewerSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewerSuggestionsQuery, InterviewerSuggestionsQueryVariables>(InterviewerSuggestionsDocument, options);
      }
export function useInterviewerSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewerSuggestionsQuery, InterviewerSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewerSuggestionsQuery, InterviewerSuggestionsQueryVariables>(InterviewerSuggestionsDocument, options);
        }
export type InterviewerSuggestionsQueryHookResult = ReturnType<typeof useInterviewerSuggestionsQuery>;
export type InterviewerSuggestionsLazyQueryHookResult = ReturnType<typeof useInterviewerSuggestionsLazyQuery>;
export type InterviewerSuggestionsQueryResult = Apollo.QueryResult<InterviewerSuggestionsQuery, InterviewerSuggestionsQueryVariables>;
export const InterviewModuleMemberLookupDocument = gql`
    query InterviewModuleMemberLookup($memberIds: [uuid!]!, $interviewModuleIds: [uuid!]) {
  interviewModuleMemberLookup(
    input: {interviewModuleMemberIds: $memberIds, interviewModuleIds: $interviewModuleIds}
  ) {
    items {
      interviewModuleId
      employeeId
      status
      interviewModule {
        id
        name
        shadowsRequired
        reverseShadowsRequired
      }
      employee {
        id
        fullName
      }
      stats {
        completed
        upcoming
        shadowOffset
        shadowsRequired
        reverseShadowOffset
        reverseShadowsRequired
        completedAsShadow
        completedAsReverseShadow
        currentTrainingSessionStatus
      }
    }
  }
}
    `;

/**
 * __useInterviewModuleMemberLookupQuery__
 *
 * To run a query within a React component, call `useInterviewModuleMemberLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMemberLookupQuery({
 *   variables: {
 *      memberIds: // value for 'memberIds'
 *      interviewModuleIds: // value for 'interviewModuleIds'
 *   },
 * });
 */
export function useInterviewModuleMemberLookupQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleMemberLookupQuery, InterviewModuleMemberLookupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMemberLookupQuery, InterviewModuleMemberLookupQueryVariables>(InterviewModuleMemberLookupDocument, options);
      }
export function useInterviewModuleMemberLookupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMemberLookupQuery, InterviewModuleMemberLookupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMemberLookupQuery, InterviewModuleMemberLookupQueryVariables>(InterviewModuleMemberLookupDocument, options);
        }
export type InterviewModuleMemberLookupQueryHookResult = ReturnType<typeof useInterviewModuleMemberLookupQuery>;
export type InterviewModuleMemberLookupLazyQueryHookResult = ReturnType<typeof useInterviewModuleMemberLookupLazyQuery>;
export type InterviewModuleMemberLookupQueryResult = Apollo.QueryResult<InterviewModuleMemberLookupQuery, InterviewModuleMemberLookupQueryVariables>;
export const AbsoluteEmployeeWorkHoursDocument = gql`
    query AbsoluteEmployeeWorkHours($ids: [uuid!]!, $startAt: datetime!, $endAt: datetime!) {
  employeeByIds(ids: $ids) {
    id
    fullName
    absoluteWorkHours(input: {range: {startAt: $startAt, endAt: $endAt}}) {
      startAt
      endAt
    }
  }
}
    `;

/**
 * __useAbsoluteEmployeeWorkHoursQuery__
 *
 * To run a query within a React component, call `useAbsoluteEmployeeWorkHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useAbsoluteEmployeeWorkHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAbsoluteEmployeeWorkHoursQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      startAt: // value for 'startAt'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useAbsoluteEmployeeWorkHoursQuery(baseOptions: Apollo.QueryHookOptions<AbsoluteEmployeeWorkHoursQuery, AbsoluteEmployeeWorkHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AbsoluteEmployeeWorkHoursQuery, AbsoluteEmployeeWorkHoursQueryVariables>(AbsoluteEmployeeWorkHoursDocument, options);
      }
export function useAbsoluteEmployeeWorkHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AbsoluteEmployeeWorkHoursQuery, AbsoluteEmployeeWorkHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AbsoluteEmployeeWorkHoursQuery, AbsoluteEmployeeWorkHoursQueryVariables>(AbsoluteEmployeeWorkHoursDocument, options);
        }
export type AbsoluteEmployeeWorkHoursQueryHookResult = ReturnType<typeof useAbsoluteEmployeeWorkHoursQuery>;
export type AbsoluteEmployeeWorkHoursLazyQueryHookResult = ReturnType<typeof useAbsoluteEmployeeWorkHoursLazyQuery>;
export type AbsoluteEmployeeWorkHoursQueryResult = Apollo.QueryResult<AbsoluteEmployeeWorkHoursQuery, AbsoluteEmployeeWorkHoursQueryVariables>;
export const SaveInterviewPlanToJobStageDocument = gql`
    mutation SaveInterviewPlanToJobStage($input: JobStageInterviewPlanUpsertInput!) {
  jobStageInterviewPlanUpsert(input: $input) {
    jobStage {
      id
    }
  }
}
    `;
export type SaveInterviewPlanToJobStageMutationFn = Apollo.MutationFunction<SaveInterviewPlanToJobStageMutation, SaveInterviewPlanToJobStageMutationVariables>;

/**
 * __useSaveInterviewPlanToJobStageMutation__
 *
 * To run a mutation, you first call `useSaveInterviewPlanToJobStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInterviewPlanToJobStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInterviewPlanToJobStageMutation, { data, loading, error }] = useSaveInterviewPlanToJobStageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveInterviewPlanToJobStageMutation(baseOptions?: Apollo.MutationHookOptions<SaveInterviewPlanToJobStageMutation, SaveInterviewPlanToJobStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveInterviewPlanToJobStageMutation, SaveInterviewPlanToJobStageMutationVariables>(SaveInterviewPlanToJobStageDocument, options);
      }
export type SaveInterviewPlanToJobStageMutationHookResult = ReturnType<typeof useSaveInterviewPlanToJobStageMutation>;
export type SaveInterviewPlanToJobStageMutationResult = Apollo.MutationResult<SaveInterviewPlanToJobStageMutation>;
export type SaveInterviewPlanToJobStageMutationOptions = Apollo.BaseMutationOptions<SaveInterviewPlanToJobStageMutation, SaveInterviewPlanToJobStageMutationVariables>;
export const ScheduleFlowApplicationDocument = gql`
    query ScheduleFlowApplication($id: uuid!, $fetchInterviewPlan: Boolean!) {
  application(id: $id) {
    id
    job {
      id
      name
      atsId
      ...ScheduleFlowDataProvider_job
    }
    jobStage {
      id
      name
      ...ScheduleFlowDataProvider_interviewPlan @include(if: $fetchInterviewPlan)
      jobStageSettingsV2 {
        id
        ...ScheduleFlowDataProvider_jobStageSettings
      }
    }
    candidate {
      id
      timezone
      atsLatestAvailability {
        ...useCandidateAvailability_atsLatestAvailability
        timezone
      }
      ...ScheduleFlowDataProvider_candidate
    }
  }
}
    ${ScheduleFlowDataProvider_JobFragmentDoc}
${ScheduleFlowDataProvider_InterviewPlanFragmentDoc}
${ScheduleFlowDataProvider_JobStageSettingsFragmentDoc}
${UseCandidateAvailability_AtsLatestAvailabilityFragmentDoc}
${ScheduleFlowDataProvider_CandidateFragmentDoc}`;

/**
 * __useScheduleFlowApplicationQuery__
 *
 * To run a query within a React component, call `useScheduleFlowApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleFlowApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleFlowApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fetchInterviewPlan: // value for 'fetchInterviewPlan'
 *   },
 * });
 */
export function useScheduleFlowApplicationQuery(baseOptions: Apollo.QueryHookOptions<ScheduleFlowApplicationQuery, ScheduleFlowApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleFlowApplicationQuery, ScheduleFlowApplicationQueryVariables>(ScheduleFlowApplicationDocument, options);
      }
export function useScheduleFlowApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleFlowApplicationQuery, ScheduleFlowApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleFlowApplicationQuery, ScheduleFlowApplicationQueryVariables>(ScheduleFlowApplicationDocument, options);
        }
export type ScheduleFlowApplicationQueryHookResult = ReturnType<typeof useScheduleFlowApplicationQuery>;
export type ScheduleFlowApplicationLazyQueryHookResult = ReturnType<typeof useScheduleFlowApplicationLazyQuery>;
export type ScheduleFlowApplicationQueryResult = Apollo.QueryResult<ScheduleFlowApplicationQuery, ScheduleFlowApplicationQueryVariables>;
export const ScheduleFlowJobStageDocument = gql`
    query scheduleFlowJobStage($jobStageId: uuid!, $fetchInterviewPlan: Boolean!) {
  jobStage(id: $jobStageId) {
    id
    name
    job {
      id
      name
      atsId
      ...ScheduleFlowDataProvider_job
    }
    ...ScheduleFlowDataProvider_interviewPlan @include(if: $fetchInterviewPlan)
    jobStageSettingsV2 {
      id
      ...ScheduleFlowDataProvider_jobStageSettings
    }
  }
}
    ${ScheduleFlowDataProvider_JobFragmentDoc}
${ScheduleFlowDataProvider_InterviewPlanFragmentDoc}
${ScheduleFlowDataProvider_JobStageSettingsFragmentDoc}`;

/**
 * __useScheduleFlowJobStageQuery__
 *
 * To run a query within a React component, call `useScheduleFlowJobStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleFlowJobStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleFlowJobStageQuery({
 *   variables: {
 *      jobStageId: // value for 'jobStageId'
 *      fetchInterviewPlan: // value for 'fetchInterviewPlan'
 *   },
 * });
 */
export function useScheduleFlowJobStageQuery(baseOptions: Apollo.QueryHookOptions<ScheduleFlowJobStageQuery, ScheduleFlowJobStageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleFlowJobStageQuery, ScheduleFlowJobStageQueryVariables>(ScheduleFlowJobStageDocument, options);
      }
export function useScheduleFlowJobStageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleFlowJobStageQuery, ScheduleFlowJobStageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleFlowJobStageQuery, ScheduleFlowJobStageQueryVariables>(ScheduleFlowJobStageDocument, options);
        }
export type ScheduleFlowJobStageQueryHookResult = ReturnType<typeof useScheduleFlowJobStageQuery>;
export type ScheduleFlowJobStageLazyQueryHookResult = ReturnType<typeof useScheduleFlowJobStageLazyQuery>;
export type ScheduleFlowJobStageQueryResult = Apollo.QueryResult<ScheduleFlowJobStageQuery, ScheduleFlowJobStageQueryVariables>;
export const ScheduleFlowTaskDocument = gql`
    query ScheduleFlowTask($id: uuid!, $fetchInterviewPlan: Boolean!) {
  task(id: $id) {
    id
    application {
      id
      candidate {
        id
        timezone
        atsLatestAvailability {
          ...useCandidateAvailability_atsLatestAvailability
          timezone
        }
        ...ScheduleFlowDataProvider_candidate
      }
    }
    interviewPlan {
      id
      ...ScheduleFlowDataProvider_interviewPlan @include(if: $fetchInterviewPlan)
    }
    jobStage {
      id
      job {
        id
        name
        atsId
        ...ScheduleFlowDataProvider_job
      }
      name
      jobStageSettingsV2 {
        id
        ...ScheduleFlowDataProvider_jobStageSettings
      }
    }
    availabilities {
      id
      ...useCandidateAvailability_availabilities
    }
  }
}
    ${UseCandidateAvailability_AtsLatestAvailabilityFragmentDoc}
${ScheduleFlowDataProvider_CandidateFragmentDoc}
${ScheduleFlowDataProvider_InterviewPlanFragmentDoc}
${ScheduleFlowDataProvider_JobFragmentDoc}
${ScheduleFlowDataProvider_JobStageSettingsFragmentDoc}
${UseCandidateAvailability_AvailabilitiesFragmentDoc}`;

/**
 * __useScheduleFlowTaskQuery__
 *
 * To run a query within a React component, call `useScheduleFlowTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleFlowTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleFlowTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fetchInterviewPlan: // value for 'fetchInterviewPlan'
 *   },
 * });
 */
export function useScheduleFlowTaskQuery(baseOptions: Apollo.QueryHookOptions<ScheduleFlowTaskQuery, ScheduleFlowTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleFlowTaskQuery, ScheduleFlowTaskQueryVariables>(ScheduleFlowTaskDocument, options);
      }
export function useScheduleFlowTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleFlowTaskQuery, ScheduleFlowTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleFlowTaskQuery, ScheduleFlowTaskQueryVariables>(ScheduleFlowTaskDocument, options);
        }
export type ScheduleFlowTaskQueryHookResult = ReturnType<typeof useScheduleFlowTaskQuery>;
export type ScheduleFlowTaskLazyQueryHookResult = ReturnType<typeof useScheduleFlowTaskLazyQuery>;
export type ScheduleFlowTaskQueryResult = Apollo.QueryResult<ScheduleFlowTaskQuery, ScheduleFlowTaskQueryVariables>;
export const CandidateAtsLatestAvailabilityDocument = gql`
    query CandidateAtsLatestAvailability($applicationId: uuid!) {
  application(id: $applicationId) {
    candidate {
      recruiterId
      coordinatorId
      ...CandidateAtsLatestAvailabilityFragment
    }
    job {
      hiringManagers {
        id
      }
    }
  }
}
    ${CandidateAtsLatestAvailabilityFragmentFragmentDoc}`;

/**
 * __useCandidateAtsLatestAvailabilityQuery__
 *
 * To run a query within a React component, call `useCandidateAtsLatestAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateAtsLatestAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateAtsLatestAvailabilityQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useCandidateAtsLatestAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<CandidateAtsLatestAvailabilityQuery, CandidateAtsLatestAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateAtsLatestAvailabilityQuery, CandidateAtsLatestAvailabilityQueryVariables>(CandidateAtsLatestAvailabilityDocument, options);
      }
export function useCandidateAtsLatestAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateAtsLatestAvailabilityQuery, CandidateAtsLatestAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateAtsLatestAvailabilityQuery, CandidateAtsLatestAvailabilityQueryVariables>(CandidateAtsLatestAvailabilityDocument, options);
        }
export type CandidateAtsLatestAvailabilityQueryHookResult = ReturnType<typeof useCandidateAtsLatestAvailabilityQuery>;
export type CandidateAtsLatestAvailabilityLazyQueryHookResult = ReturnType<typeof useCandidateAtsLatestAvailabilityLazyQuery>;
export type CandidateAtsLatestAvailabilityQueryResult = Apollo.QueryResult<CandidateAtsLatestAvailabilityQuery, CandidateAtsLatestAvailabilityQueryVariables>;
export const InitScheduleFlowDataDocument = gql`
    query InitScheduleFlowData($input: InitScheduleFlowInput!) {
  initScheduleFlow(input: $input) {
    candidateConfirmationEmailTemplate {
      ...InitScheduleFlowData_template
    }
    candidateConfirmationInviteTemplate {
      ...InitScheduleFlowData_template
    }
    interviewerConfirmationInviteTemplate {
      ...InitScheduleFlowData_template
    }
    interviewerConversationMessageTemplate {
      ...InitScheduleFlowData_template
    }
    candidateCalendar {
      remoteId
    }
    interviewerCalendar {
      remoteId
    }
    isPrivateCalendarEvents
  }
}
    ${InitScheduleFlowData_TemplateFragmentDoc}`;

/**
 * __useInitScheduleFlowDataQuery__
 *
 * To run a query within a React component, call `useInitScheduleFlowDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitScheduleFlowDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitScheduleFlowDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInitScheduleFlowDataQuery(baseOptions: Apollo.QueryHookOptions<InitScheduleFlowDataQuery, InitScheduleFlowDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitScheduleFlowDataQuery, InitScheduleFlowDataQueryVariables>(InitScheduleFlowDataDocument, options);
      }
export function useInitScheduleFlowDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitScheduleFlowDataQuery, InitScheduleFlowDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitScheduleFlowDataQuery, InitScheduleFlowDataQueryVariables>(InitScheduleFlowDataDocument, options);
        }
export type InitScheduleFlowDataQueryHookResult = ReturnType<typeof useInitScheduleFlowDataQuery>;
export type InitScheduleFlowDataLazyQueryHookResult = ReturnType<typeof useInitScheduleFlowDataLazyQuery>;
export type InitScheduleFlowDataQueryResult = Apollo.QueryResult<InitScheduleFlowDataQuery, InitScheduleFlowDataQueryVariables>;
export const InterviewPlanFromTaskDocument = gql`
    query InterviewPlanFromTask($id: uuid!, $skipFetchingInterviwPlan: Boolean!) {
  task(id: $id) {
    application {
      id
      candidate {
        id
        coordinatorId
        recruiterId
        fullName
        timezone
        email
        additionalEmails
        atsCandidate {
          atsId
          atsUrl
        }
        atsLatestAvailability {
          intervals {
            startAt
            endAt
          }
          jobName
          jobStageName
          timezone
          originalText
        }
      }
      existingConversations {
        id
        remoteId
        remoteName
      }
    }
    availabilities {
      id
      createdAt
      ...CandidateAvailabilityFragment
    }
    interviewPlan @skip(if: $skipFetchingInterviwPlan) {
      id
      ...JobStage_InterviewPlan
    }
    jobStage {
      id
      name
      job {
        id
        name
        isConfidential
        hiringManagers {
          id
        }
      }
    }
  }
}
    ${CandidateAvailabilityFragmentFragmentDoc}
${JobStage_InterviewPlanFragmentDoc}`;

/**
 * __useInterviewPlanFromTaskQuery__
 *
 * To run a query within a React component, call `useInterviewPlanFromTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewPlanFromTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewPlanFromTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skipFetchingInterviwPlan: // value for 'skipFetchingInterviwPlan'
 *   },
 * });
 */
export function useInterviewPlanFromTaskQuery(baseOptions: Apollo.QueryHookOptions<InterviewPlanFromTaskQuery, InterviewPlanFromTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewPlanFromTaskQuery, InterviewPlanFromTaskQueryVariables>(InterviewPlanFromTaskDocument, options);
      }
export function useInterviewPlanFromTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewPlanFromTaskQuery, InterviewPlanFromTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewPlanFromTaskQuery, InterviewPlanFromTaskQueryVariables>(InterviewPlanFromTaskDocument, options);
        }
export type InterviewPlanFromTaskQueryHookResult = ReturnType<typeof useInterviewPlanFromTaskQuery>;
export type InterviewPlanFromTaskLazyQueryHookResult = ReturnType<typeof useInterviewPlanFromTaskLazyQuery>;
export type InterviewPlanFromTaskQueryResult = Apollo.QueryResult<InterviewPlanFromTaskQuery, InterviewPlanFromTaskQueryVariables>;
export const AvailabilityOptionsSettingsDocument = gql`
    query AvailabilityOptionsSettings($jobStageId: uuid!) {
  jobStage(id: $jobStageId) {
    jobStageSettings {
      ...CandidateAvailabilityOptionsSettings_JobStageSettings
    }
  }
}
    ${CandidateAvailabilityOptionsSettings_JobStageSettingsFragmentDoc}`;

/**
 * __useAvailabilityOptionsSettingsQuery__
 *
 * To run a query within a React component, call `useAvailabilityOptionsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityOptionsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityOptionsSettingsQuery({
 *   variables: {
 *      jobStageId: // value for 'jobStageId'
 *   },
 * });
 */
export function useAvailabilityOptionsSettingsQuery(baseOptions: Apollo.QueryHookOptions<AvailabilityOptionsSettingsQuery, AvailabilityOptionsSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailabilityOptionsSettingsQuery, AvailabilityOptionsSettingsQueryVariables>(AvailabilityOptionsSettingsDocument, options);
      }
export function useAvailabilityOptionsSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailabilityOptionsSettingsQuery, AvailabilityOptionsSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailabilityOptionsSettingsQuery, AvailabilityOptionsSettingsQueryVariables>(AvailabilityOptionsSettingsDocument, options);
        }
export type AvailabilityOptionsSettingsQueryHookResult = ReturnType<typeof useAvailabilityOptionsSettingsQuery>;
export type AvailabilityOptionsSettingsLazyQueryHookResult = ReturnType<typeof useAvailabilityOptionsSettingsLazyQuery>;
export type AvailabilityOptionsSettingsQueryResult = Apollo.QueryResult<AvailabilityOptionsSettingsQuery, AvailabilityOptionsSettingsQueryVariables>;
export const BasicTaskModalDocument = gql`
    mutation BasicTaskModal($input: TaskCreateInput!) {
  taskCreate(input: $input) {
    task {
      id
    }
  }
}
    `;
export type BasicTaskModalMutationFn = Apollo.MutationFunction<BasicTaskModalMutation, BasicTaskModalMutationVariables>;

/**
 * __useBasicTaskModalMutation__
 *
 * To run a mutation, you first call `useBasicTaskModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBasicTaskModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [basicTaskModalMutation, { data, loading, error }] = useBasicTaskModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBasicTaskModalMutation(baseOptions?: Apollo.MutationHookOptions<BasicTaskModalMutation, BasicTaskModalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BasicTaskModalMutation, BasicTaskModalMutationVariables>(BasicTaskModalDocument, options);
      }
export type BasicTaskModalMutationHookResult = ReturnType<typeof useBasicTaskModalMutation>;
export type BasicTaskModalMutationResult = Apollo.MutationResult<BasicTaskModalMutation>;
export type BasicTaskModalMutationOptions = Apollo.BaseMutationOptions<BasicTaskModalMutation, BasicTaskModalMutationVariables>;
export const CreateBasicTaskInterviewPlanDocument = gql`
    query CreateBasicTaskInterviewPlan($jobStageId: uuid!) {
  jobStage(id: $jobStageId) {
    id
    ...CreateTaskStep_jobStage
    ...cloneInterviewPlan_interviewPlan
    ...getSaveInterviewPlanInput_interviewPlan
  }
}
    ${CreateTaskStep_JobStageFragmentDoc}
${CloneInterviewPlan_InterviewPlanFragmentDoc}
${GetSaveInterviewPlanInput_InterviewPlanFragmentDoc}`;

/**
 * __useCreateBasicTaskInterviewPlanQuery__
 *
 * To run a query within a React component, call `useCreateBasicTaskInterviewPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateBasicTaskInterviewPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateBasicTaskInterviewPlanQuery({
 *   variables: {
 *      jobStageId: // value for 'jobStageId'
 *   },
 * });
 */
export function useCreateBasicTaskInterviewPlanQuery(baseOptions: Apollo.QueryHookOptions<CreateBasicTaskInterviewPlanQuery, CreateBasicTaskInterviewPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateBasicTaskInterviewPlanQuery, CreateBasicTaskInterviewPlanQueryVariables>(CreateBasicTaskInterviewPlanDocument, options);
      }
export function useCreateBasicTaskInterviewPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateBasicTaskInterviewPlanQuery, CreateBasicTaskInterviewPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateBasicTaskInterviewPlanQuery, CreateBasicTaskInterviewPlanQueryVariables>(CreateBasicTaskInterviewPlanDocument, options);
        }
export type CreateBasicTaskInterviewPlanQueryHookResult = ReturnType<typeof useCreateBasicTaskInterviewPlanQuery>;
export type CreateBasicTaskInterviewPlanLazyQueryHookResult = ReturnType<typeof useCreateBasicTaskInterviewPlanLazyQuery>;
export type CreateBasicTaskInterviewPlanQueryResult = Apollo.QueryResult<CreateBasicTaskInterviewPlanQuery, CreateBasicTaskInterviewPlanQueryVariables>;
export const SelfScheduleOptionsSettingsDocument = gql`
    query SelfScheduleOptionsSettings($jobStageId: uuid!) {
  jobStage(id: $jobStageId) {
    jobStageSettings {
      ...SelfScheduleOptionSettings_JobStageSettings
    }
  }
}
    ${SelfScheduleOptionSettings_JobStageSettingsFragmentDoc}`;

/**
 * __useSelfScheduleOptionsSettingsQuery__
 *
 * To run a query within a React component, call `useSelfScheduleOptionsSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfScheduleOptionsSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfScheduleOptionsSettingsQuery({
 *   variables: {
 *      jobStageId: // value for 'jobStageId'
 *   },
 * });
 */
export function useSelfScheduleOptionsSettingsQuery(baseOptions: Apollo.QueryHookOptions<SelfScheduleOptionsSettingsQuery, SelfScheduleOptionsSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfScheduleOptionsSettingsQuery, SelfScheduleOptionsSettingsQueryVariables>(SelfScheduleOptionsSettingsDocument, options);
      }
export function useSelfScheduleOptionsSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfScheduleOptionsSettingsQuery, SelfScheduleOptionsSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfScheduleOptionsSettingsQuery, SelfScheduleOptionsSettingsQueryVariables>(SelfScheduleOptionsSettingsDocument, options);
        }
export type SelfScheduleOptionsSettingsQueryHookResult = ReturnType<typeof useSelfScheduleOptionsSettingsQuery>;
export type SelfScheduleOptionsSettingsLazyQueryHookResult = ReturnType<typeof useSelfScheduleOptionsSettingsLazyQuery>;
export type SelfScheduleOptionsSettingsQueryResult = Apollo.QueryResult<SelfScheduleOptionsSettingsQuery, SelfScheduleOptionsSettingsQueryVariables>;
export const DefaultTaskAssigneeDocument = gql`
    query DefaultTaskAssignee($applicationId: uuid!, $prefName: String!) {
  application(id: $applicationId) {
    candidate {
      coordinator {
        id
        fullName
        slackImageUrl
      }
      recruiter {
        id
        fullName
        slackImageUrl
      }
    }
  }
  thisOrg {
    id
    orgPref(prefName: $prefName) {
      id
      orgId
      prefName
      stringValue
    }
  }
  thisEmployee {
    id
    fullName
    slackImageUrl
  }
}
    `;

/**
 * __useDefaultTaskAssigneeQuery__
 *
 * To run a query within a React component, call `useDefaultTaskAssigneeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultTaskAssigneeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultTaskAssigneeQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      prefName: // value for 'prefName'
 *   },
 * });
 */
export function useDefaultTaskAssigneeQuery(baseOptions: Apollo.QueryHookOptions<DefaultTaskAssigneeQuery, DefaultTaskAssigneeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultTaskAssigneeQuery, DefaultTaskAssigneeQueryVariables>(DefaultTaskAssigneeDocument, options);
      }
export function useDefaultTaskAssigneeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultTaskAssigneeQuery, DefaultTaskAssigneeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultTaskAssigneeQuery, DefaultTaskAssigneeQueryVariables>(DefaultTaskAssigneeDocument, options);
        }
export type DefaultTaskAssigneeQueryHookResult = ReturnType<typeof useDefaultTaskAssigneeQuery>;
export type DefaultTaskAssigneeLazyQueryHookResult = ReturnType<typeof useDefaultTaskAssigneeLazyQuery>;
export type DefaultTaskAssigneeQueryResult = Apollo.QueryResult<DefaultTaskAssigneeQuery, DefaultTaskAssigneeQueryVariables>;
export const CoordinatorRecruiterUserInfosDocument = gql`
    query CoordinatorRecruiterUserInfos($ids: [uuid!]!) {
  userInfoByEmployeeIds(ids: $ids) {
    userId
    employeeId
    permissions
  }
}
    `;

/**
 * __useCoordinatorRecruiterUserInfosQuery__
 *
 * To run a query within a React component, call `useCoordinatorRecruiterUserInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoordinatorRecruiterUserInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoordinatorRecruiterUserInfosQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCoordinatorRecruiterUserInfosQuery(baseOptions: Apollo.QueryHookOptions<CoordinatorRecruiterUserInfosQuery, CoordinatorRecruiterUserInfosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoordinatorRecruiterUserInfosQuery, CoordinatorRecruiterUserInfosQueryVariables>(CoordinatorRecruiterUserInfosDocument, options);
      }
export function useCoordinatorRecruiterUserInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoordinatorRecruiterUserInfosQuery, CoordinatorRecruiterUserInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoordinatorRecruiterUserInfosQuery, CoordinatorRecruiterUserInfosQueryVariables>(CoordinatorRecruiterUserInfosDocument, options);
        }
export type CoordinatorRecruiterUserInfosQueryHookResult = ReturnType<typeof useCoordinatorRecruiterUserInfosQuery>;
export type CoordinatorRecruiterUserInfosLazyQueryHookResult = ReturnType<typeof useCoordinatorRecruiterUserInfosLazyQuery>;
export type CoordinatorRecruiterUserInfosQueryResult = Apollo.QueryResult<CoordinatorRecruiterUserInfosQuery, CoordinatorRecruiterUserInfosQueryVariables>;
export const MigrateApplicationDocument = gql`
    mutation MigrateApplication($input: ApplicationUpdateInput!) {
  applicationUpdate(input: $input) {
    id
    isTaskEnabled
  }
}
    `;
export type MigrateApplicationMutationFn = Apollo.MutationFunction<MigrateApplicationMutation, MigrateApplicationMutationVariables>;

/**
 * __useMigrateApplicationMutation__
 *
 * To run a mutation, you first call `useMigrateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateApplicationMutation, { data, loading, error }] = useMigrateApplicationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMigrateApplicationMutation(baseOptions?: Apollo.MutationHookOptions<MigrateApplicationMutation, MigrateApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MigrateApplicationMutation, MigrateApplicationMutationVariables>(MigrateApplicationDocument, options);
      }
export type MigrateApplicationMutationHookResult = ReturnType<typeof useMigrateApplicationMutation>;
export type MigrateApplicationMutationResult = Apollo.MutationResult<MigrateApplicationMutation>;
export type MigrateApplicationMutationOptions = Apollo.BaseMutationOptions<MigrateApplicationMutation, MigrateApplicationMutationVariables>;
export const TaskSetAssigneeDocument = gql`
    mutation TaskSetAssignee($input: TaskSetAssigneeInput!) {
  taskSetAssignee(input: $input) {
    id
    assignee {
      ...Employee
    }
  }
}
    ${EmployeeFragmentDoc}`;
export type TaskSetAssigneeMutationFn = Apollo.MutationFunction<TaskSetAssigneeMutation, TaskSetAssigneeMutationVariables>;

/**
 * __useTaskSetAssigneeMutation__
 *
 * To run a mutation, you first call `useTaskSetAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskSetAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskSetAssigneeMutation, { data, loading, error }] = useTaskSetAssigneeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskSetAssigneeMutation(baseOptions?: Apollo.MutationHookOptions<TaskSetAssigneeMutation, TaskSetAssigneeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskSetAssigneeMutation, TaskSetAssigneeMutationVariables>(TaskSetAssigneeDocument, options);
      }
export type TaskSetAssigneeMutationHookResult = ReturnType<typeof useTaskSetAssigneeMutation>;
export type TaskSetAssigneeMutationResult = Apollo.MutationResult<TaskSetAssigneeMutation>;
export type TaskSetAssigneeMutationOptions = Apollo.BaseMutationOptions<TaskSetAssigneeMutation, TaskSetAssigneeMutationVariables>;
export const TaskFlagMarkResolvedDocument = gql`
    mutation TaskFlagMarkResolved($input: TaskFlagMarkResolvedInput!) {
  taskFlagMarkResolved(input: $input) {
    taskFlag {
      ...ScheduleTaskFlags_taskFlag
    }
  }
}
    ${ScheduleTaskFlags_TaskFlagFragmentDoc}`;
export type TaskFlagMarkResolvedMutationFn = Apollo.MutationFunction<TaskFlagMarkResolvedMutation, TaskFlagMarkResolvedMutationVariables>;

/**
 * __useTaskFlagMarkResolvedMutation__
 *
 * To run a mutation, you first call `useTaskFlagMarkResolvedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskFlagMarkResolvedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskFlagMarkResolvedMutation, { data, loading, error }] = useTaskFlagMarkResolvedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskFlagMarkResolvedMutation(baseOptions?: Apollo.MutationHookOptions<TaskFlagMarkResolvedMutation, TaskFlagMarkResolvedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskFlagMarkResolvedMutation, TaskFlagMarkResolvedMutationVariables>(TaskFlagMarkResolvedDocument, options);
      }
export type TaskFlagMarkResolvedMutationHookResult = ReturnType<typeof useTaskFlagMarkResolvedMutation>;
export type TaskFlagMarkResolvedMutationResult = Apollo.MutationResult<TaskFlagMarkResolvedMutation>;
export type TaskFlagMarkResolvedMutationOptions = Apollo.BaseMutationOptions<TaskFlagMarkResolvedMutation, TaskFlagMarkResolvedMutationVariables>;
export const TaskSetTagsDocument = gql`
    mutation TaskSetTags($input: TaskSetTagsInput!) {
  taskSetTags(input: $input) {
    id
    tags {
      ...TaskTags_selectedTaskTag
    }
  }
}
    ${TaskTags_SelectedTaskTagFragmentDoc}`;
export type TaskSetTagsMutationFn = Apollo.MutationFunction<TaskSetTagsMutation, TaskSetTagsMutationVariables>;

/**
 * __useTaskSetTagsMutation__
 *
 * To run a mutation, you first call `useTaskSetTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskSetTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskSetTagsMutation, { data, loading, error }] = useTaskSetTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskSetTagsMutation(baseOptions?: Apollo.MutationHookOptions<TaskSetTagsMutation, TaskSetTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskSetTagsMutation, TaskSetTagsMutationVariables>(TaskSetTagsDocument, options);
      }
export type TaskSetTagsMutationHookResult = ReturnType<typeof useTaskSetTagsMutation>;
export type TaskSetTagsMutationResult = Apollo.MutationResult<TaskSetTagsMutation>;
export type TaskSetTagsMutationOptions = Apollo.BaseMutationOptions<TaskSetTagsMutation, TaskSetTagsMutationVariables>;
export const TaskUpdateQueueDocument = gql`
    mutation TaskUpdateQueue($input: TaskUpdateInput!) {
  taskUpdate(input: $input) {
    id
    taskQueue {
      ...QueueSelect_selectedTaskQueue
    }
  }
}
    ${QueueSelect_SelectedTaskQueueFragmentDoc}`;
export type TaskUpdateQueueMutationFn = Apollo.MutationFunction<TaskUpdateQueueMutation, TaskUpdateQueueMutationVariables>;

/**
 * __useTaskUpdateQueueMutation__
 *
 * To run a mutation, you first call `useTaskUpdateQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskUpdateQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskUpdateQueueMutation, { data, loading, error }] = useTaskUpdateQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskUpdateQueueMutation(baseOptions?: Apollo.MutationHookOptions<TaskUpdateQueueMutation, TaskUpdateQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskUpdateQueueMutation, TaskUpdateQueueMutationVariables>(TaskUpdateQueueDocument, options);
      }
export type TaskUpdateQueueMutationHookResult = ReturnType<typeof useTaskUpdateQueueMutation>;
export type TaskUpdateQueueMutationResult = Apollo.MutationResult<TaskUpdateQueueMutation>;
export type TaskUpdateQueueMutationOptions = Apollo.BaseMutationOptions<TaskUpdateQueueMutation, TaskUpdateQueueMutationVariables>;
export const TaskRequestChangeDocument = gql`
    mutation TaskRequestChange($input: TaskRequestChangeInput!) {
  taskRequestChange(input: $input) {
    task {
      id
      flags {
        ...ScheduleTaskFlags_taskFlag
      }
    }
  }
}
    ${ScheduleTaskFlags_TaskFlagFragmentDoc}`;
export type TaskRequestChangeMutationFn = Apollo.MutationFunction<TaskRequestChangeMutation, TaskRequestChangeMutationVariables>;

/**
 * __useTaskRequestChangeMutation__
 *
 * To run a mutation, you first call `useTaskRequestChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskRequestChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskRequestChangeMutation, { data, loading, error }] = useTaskRequestChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskRequestChangeMutation(baseOptions?: Apollo.MutationHookOptions<TaskRequestChangeMutation, TaskRequestChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskRequestChangeMutation, TaskRequestChangeMutationVariables>(TaskRequestChangeDocument, options);
      }
export type TaskRequestChangeMutationHookResult = ReturnType<typeof useTaskRequestChangeMutation>;
export type TaskRequestChangeMutationResult = Apollo.MutationResult<TaskRequestChangeMutation>;
export type TaskRequestChangeMutationOptions = Apollo.BaseMutationOptions<TaskRequestChangeMutation, TaskRequestChangeMutationVariables>;
export const TaskSetDebriefRequiredDocument = gql`
    mutation TaskSetDebriefRequired($input: TaskSetDebriefRequiredInput!) {
  taskSetDebriefRequired(input: $input) {
    id
    isDebriefRequired
  }
}
    `;
export type TaskSetDebriefRequiredMutationFn = Apollo.MutationFunction<TaskSetDebriefRequiredMutation, TaskSetDebriefRequiredMutationVariables>;

/**
 * __useTaskSetDebriefRequiredMutation__
 *
 * To run a mutation, you first call `useTaskSetDebriefRequiredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskSetDebriefRequiredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskSetDebriefRequiredMutation, { data, loading, error }] = useTaskSetDebriefRequiredMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskSetDebriefRequiredMutation(baseOptions?: Apollo.MutationHookOptions<TaskSetDebriefRequiredMutation, TaskSetDebriefRequiredMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskSetDebriefRequiredMutation, TaskSetDebriefRequiredMutationVariables>(TaskSetDebriefRequiredDocument, options);
      }
export type TaskSetDebriefRequiredMutationHookResult = ReturnType<typeof useTaskSetDebriefRequiredMutation>;
export type TaskSetDebriefRequiredMutationResult = Apollo.MutationResult<TaskSetDebriefRequiredMutation>;
export type TaskSetDebriefRequiredMutationOptions = Apollo.BaseMutationOptions<TaskSetDebriefRequiredMutation, TaskSetDebriefRequiredMutationVariables>;
export const RescheduleLogCreateDocument = gql`
    mutation RescheduleLogCreate($input: RescheduleLogCreateInput!) {
  rescheduleLogCreate(input: $input) {
    rescheduleLog {
      ...RescheduleLogModal_rescheduleLog
    }
  }
}
    ${RescheduleLogModal_RescheduleLogFragmentDoc}`;
export type RescheduleLogCreateMutationFn = Apollo.MutationFunction<RescheduleLogCreateMutation, RescheduleLogCreateMutationVariables>;

/**
 * __useRescheduleLogCreateMutation__
 *
 * To run a mutation, you first call `useRescheduleLogCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleLogCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleLogCreateMutation, { data, loading, error }] = useRescheduleLogCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleLogCreateMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleLogCreateMutation, RescheduleLogCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleLogCreateMutation, RescheduleLogCreateMutationVariables>(RescheduleLogCreateDocument, options);
      }
export type RescheduleLogCreateMutationHookResult = ReturnType<typeof useRescheduleLogCreateMutation>;
export type RescheduleLogCreateMutationResult = Apollo.MutationResult<RescheduleLogCreateMutation>;
export type RescheduleLogCreateMutationOptions = Apollo.BaseMutationOptions<RescheduleLogCreateMutation, RescheduleLogCreateMutationVariables>;
export const RescheduleLogUpdateDocument = gql`
    mutation RescheduleLogUpdate($input: RescheduleLogUpdateInput!) {
  rescheduleLogUpdate(input: $input) {
    rescheduleLog {
      ...RescheduleLogModal_rescheduleLog
    }
  }
}
    ${RescheduleLogModal_RescheduleLogFragmentDoc}`;
export type RescheduleLogUpdateMutationFn = Apollo.MutationFunction<RescheduleLogUpdateMutation, RescheduleLogUpdateMutationVariables>;

/**
 * __useRescheduleLogUpdateMutation__
 *
 * To run a mutation, you first call `useRescheduleLogUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleLogUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleLogUpdateMutation, { data, loading, error }] = useRescheduleLogUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleLogUpdateMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleLogUpdateMutation, RescheduleLogUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleLogUpdateMutation, RescheduleLogUpdateMutationVariables>(RescheduleLogUpdateDocument, options);
      }
export type RescheduleLogUpdateMutationHookResult = ReturnType<typeof useRescheduleLogUpdateMutation>;
export type RescheduleLogUpdateMutationResult = Apollo.MutationResult<RescheduleLogUpdateMutation>;
export type RescheduleLogUpdateMutationOptions = Apollo.BaseMutationOptions<RescheduleLogUpdateMutation, RescheduleLogUpdateMutationVariables>;
export const RescheduleLogDeleteDocument = gql`
    mutation RescheduleLogDelete($input: RescheduleLogDeleteInput!) {
  rescheduleLogDelete(input: $input) {
    result
  }
}
    `;
export type RescheduleLogDeleteMutationFn = Apollo.MutationFunction<RescheduleLogDeleteMutation, RescheduleLogDeleteMutationVariables>;

/**
 * __useRescheduleLogDeleteMutation__
 *
 * To run a mutation, you first call `useRescheduleLogDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleLogDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleLogDeleteMutation, { data, loading, error }] = useRescheduleLogDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleLogDeleteMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleLogDeleteMutation, RescheduleLogDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleLogDeleteMutation, RescheduleLogDeleteMutationVariables>(RescheduleLogDeleteDocument, options);
      }
export type RescheduleLogDeleteMutationHookResult = ReturnType<typeof useRescheduleLogDeleteMutation>;
export type RescheduleLogDeleteMutationResult = Apollo.MutationResult<RescheduleLogDeleteMutation>;
export type RescheduleLogDeleteMutationOptions = Apollo.BaseMutationOptions<RescheduleLogDeleteMutation, RescheduleLogDeleteMutationVariables>;
export const ScheduleTaskInterviewPlanUpsertDocument = gql`
    mutation ScheduleTaskInterviewPlanUpsert($input: JobStageInterviewPlanUpsertInput!) {
  jobStageInterviewPlanUpsert(input: $input) {
    jobStage {
      id
      ...InterviewPlan_jobStage
      ...getSaveInterviewPlanInput_interviewPlan
    }
  }
}
    ${InterviewPlan_JobStageFragmentDoc}
${GetSaveInterviewPlanInput_InterviewPlanFragmentDoc}`;
export type ScheduleTaskInterviewPlanUpsertMutationFn = Apollo.MutationFunction<ScheduleTaskInterviewPlanUpsertMutation, ScheduleTaskInterviewPlanUpsertMutationVariables>;

/**
 * __useScheduleTaskInterviewPlanUpsertMutation__
 *
 * To run a mutation, you first call `useScheduleTaskInterviewPlanUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleTaskInterviewPlanUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleTaskInterviewPlanUpsertMutation, { data, loading, error }] = useScheduleTaskInterviewPlanUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleTaskInterviewPlanUpsertMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleTaskInterviewPlanUpsertMutation, ScheduleTaskInterviewPlanUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleTaskInterviewPlanUpsertMutation, ScheduleTaskInterviewPlanUpsertMutationVariables>(ScheduleTaskInterviewPlanUpsertDocument, options);
      }
export type ScheduleTaskInterviewPlanUpsertMutationHookResult = ReturnType<typeof useScheduleTaskInterviewPlanUpsertMutation>;
export type ScheduleTaskInterviewPlanUpsertMutationResult = Apollo.MutationResult<ScheduleTaskInterviewPlanUpsertMutation>;
export type ScheduleTaskInterviewPlanUpsertMutationOptions = Apollo.BaseMutationOptions<ScheduleTaskInterviewPlanUpsertMutation, ScheduleTaskInterviewPlanUpsertMutationVariables>;
export const ScheduleTaskRequirementsDocument = gql`
    query ScheduleTaskRequirements($scheduleTaskId: uuid!) {
  task(id: $scheduleTaskId) {
    id
    status
    rescheduleLogs(input: {pageInput: {offset: 0, cursor: "", limit: 100}}) {
      items {
        ...RescheduleLogsList_rescheduleLogs
      }
    }
    application {
      ...Availability_application
      ...Schedule_application
      candidate {
        ...Schedule_candidate
        ...Availability_candidate
      }
    }
    availabilities {
      id
      createdAt
      ...Availability_availability
    }
    availabilityRequests {
      id
      createdAt
      ...Availability_availabilityRequest
    }
    interviewPlan {
      ...Availability_interviewPlan
      ...Schedule_interviewPlan
    }
    jobStage {
      ...Availability_jobStage
      ...Schedule_jobStage
    }
    selfScheduleRequests {
      id
      createdAt
      ...Schedule_selfScheduleRequest
    }
    schedules {
      id
      createdAt
      updatedAt
      ...Availability_schedule
      ...Schedule_schedule
    }
    ...Debrief_debriefRequirements
  }
}
    ${RescheduleLogsList_RescheduleLogsFragmentDoc}
${Availability_ApplicationFragmentDoc}
${Schedule_ApplicationFragmentDoc}
${Schedule_CandidateFragmentDoc}
${Availability_CandidateFragmentDoc}
${Availability_AvailabilityFragmentDoc}
${Availability_AvailabilityRequestFragmentDoc}
${Availability_InterviewPlanFragmentDoc}
${Schedule_InterviewPlanFragmentDoc}
${Availability_JobStageFragmentDoc}
${Schedule_JobStageFragmentDoc}
${Schedule_SelfScheduleRequestFragmentDoc}
${Availability_ScheduleFragmentDoc}
${Schedule_ScheduleFragmentDoc}
${Debrief_DebriefRequirementsFragmentDoc}`;

/**
 * __useScheduleTaskRequirementsQuery__
 *
 * To run a query within a React component, call `useScheduleTaskRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleTaskRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleTaskRequirementsQuery({
 *   variables: {
 *      scheduleTaskId: // value for 'scheduleTaskId'
 *   },
 * });
 */
export function useScheduleTaskRequirementsQuery(baseOptions: Apollo.QueryHookOptions<ScheduleTaskRequirementsQuery, ScheduleTaskRequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleTaskRequirementsQuery, ScheduleTaskRequirementsQueryVariables>(ScheduleTaskRequirementsDocument, options);
      }
export function useScheduleTaskRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleTaskRequirementsQuery, ScheduleTaskRequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleTaskRequirementsQuery, ScheduleTaskRequirementsQueryVariables>(ScheduleTaskRequirementsDocument, options);
        }
export type ScheduleTaskRequirementsQueryHookResult = ReturnType<typeof useScheduleTaskRequirementsQuery>;
export type ScheduleTaskRequirementsLazyQueryHookResult = ReturnType<typeof useScheduleTaskRequirementsLazyQuery>;
export type ScheduleTaskRequirementsQueryResult = Apollo.QueryResult<ScheduleTaskRequirementsQuery, ScheduleTaskRequirementsQueryVariables>;
export const QueueFilterTaskQueueSearchDocument = gql`
    query QueueFilterTaskQueueSearch($input: TaskQueueSearchInput!) {
  taskQueueSearch(input: $input) {
    items {
      ...QueueFilter_selectedOptions
    }
  }
}
    ${QueueFilter_SelectedOptionsFragmentDoc}`;

/**
 * __useQueueFilterTaskQueueSearchQuery__
 *
 * To run a query within a React component, call `useQueueFilterTaskQueueSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueueFilterTaskQueueSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueueFilterTaskQueueSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQueueFilterTaskQueueSearchQuery(baseOptions: Apollo.QueryHookOptions<QueueFilterTaskQueueSearchQuery, QueueFilterTaskQueueSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueueFilterTaskQueueSearchQuery, QueueFilterTaskQueueSearchQueryVariables>(QueueFilterTaskQueueSearchDocument, options);
      }
export function useQueueFilterTaskQueueSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueueFilterTaskQueueSearchQuery, QueueFilterTaskQueueSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueueFilterTaskQueueSearchQuery, QueueFilterTaskQueueSearchQueryVariables>(QueueFilterTaskQueueSearchDocument, options);
        }
export type QueueFilterTaskQueueSearchQueryHookResult = ReturnType<typeof useQueueFilterTaskQueueSearchQuery>;
export type QueueFilterTaskQueueSearchLazyQueryHookResult = ReturnType<typeof useQueueFilterTaskQueueSearchLazyQuery>;
export type QueueFilterTaskQueueSearchQueryResult = Apollo.QueryResult<QueueFilterTaskQueueSearchQuery, QueueFilterTaskQueueSearchQueryVariables>;
export const ScheduleTaskDashboardTaskSearchDocument = gql`
    query ScheduleTaskDashboardTaskSearch($input: TaskSearchInput!) {
  taskSearch(input: $input) {
    items {
      id
      ...TaskDashboardTable_task
    }
    total
    hasNext
  }
}
    ${TaskDashboardTable_TaskFragmentDoc}`;

/**
 * __useScheduleTaskDashboardTaskSearchQuery__
 *
 * To run a query within a React component, call `useScheduleTaskDashboardTaskSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleTaskDashboardTaskSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleTaskDashboardTaskSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScheduleTaskDashboardTaskSearchQuery(baseOptions: Apollo.QueryHookOptions<ScheduleTaskDashboardTaskSearchQuery, ScheduleTaskDashboardTaskSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleTaskDashboardTaskSearchQuery, ScheduleTaskDashboardTaskSearchQueryVariables>(ScheduleTaskDashboardTaskSearchDocument, options);
      }
export function useScheduleTaskDashboardTaskSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleTaskDashboardTaskSearchQuery, ScheduleTaskDashboardTaskSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleTaskDashboardTaskSearchQuery, ScheduleTaskDashboardTaskSearchQueryVariables>(ScheduleTaskDashboardTaskSearchDocument, options);
        }
export type ScheduleTaskDashboardTaskSearchQueryHookResult = ReturnType<typeof useScheduleTaskDashboardTaskSearchQuery>;
export type ScheduleTaskDashboardTaskSearchLazyQueryHookResult = ReturnType<typeof useScheduleTaskDashboardTaskSearchLazyQuery>;
export type ScheduleTaskDashboardTaskSearchQueryResult = Apollo.QueryResult<ScheduleTaskDashboardTaskSearchQuery, ScheduleTaskDashboardTaskSearchQueryVariables>;
export const MySchedulingTasksDocument = gql`
    query MySchedulingTasks($id: uuid!) {
  assignedToMe: taskSearch(
    input: {pageInput: {limit: 10}, assignees: {showUnassigned: false, assigneeIds: [$id]}, filterCompletedCanceled: true}
  ) {
    total
  }
  created: taskSearch(
    input: {pageInput: {limit: 10}, createdByIds: [$id], filterCompletedCanceled: true}
  ) {
    total
  }
  subscribed: taskSearch(
    input: {pageInput: {limit: 10}, subscribedByIds: [$id], filterCompletedCanceled: true}
  ) {
    total
  }
  myQueues: taskSearch(
    input: {pageInput: {limit: 10}, taskQueueMemberEmployeeIds: [$id], filterCompletedCanceled: true}
  ) {
    total
  }
}
    `;

/**
 * __useMySchedulingTasksQuery__
 *
 * To run a query within a React component, call `useMySchedulingTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySchedulingTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySchedulingTasksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMySchedulingTasksQuery(baseOptions: Apollo.QueryHookOptions<MySchedulingTasksQuery, MySchedulingTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MySchedulingTasksQuery, MySchedulingTasksQueryVariables>(MySchedulingTasksDocument, options);
      }
export function useMySchedulingTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MySchedulingTasksQuery, MySchedulingTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MySchedulingTasksQuery, MySchedulingTasksQueryVariables>(MySchedulingTasksDocument, options);
        }
export type MySchedulingTasksQueryHookResult = ReturnType<typeof useMySchedulingTasksQuery>;
export type MySchedulingTasksLazyQueryHookResult = ReturnType<typeof useMySchedulingTasksLazyQuery>;
export type MySchedulingTasksQueryResult = Apollo.QueryResult<MySchedulingTasksQuery, MySchedulingTasksQueryVariables>;
export const ScheduleTaskDashboardFiltersEmployeePrefDocument = gql`
    query ScheduleTaskDashboardFiltersEmployeePref($prefName: String!) {
  thisEmployee {
    id
    employeePref(prefName: $prefName) {
      orgId
      employeeId
      prefName
      jsonPref {
        ... on EmployeeTaskDashboardFilterPref {
          ...ScheduleTaskDashboardFiltersEmployeePref
        }
      }
    }
  }
}
    ${ScheduleTaskDashboardFiltersEmployeePrefFragmentDoc}`;

/**
 * __useScheduleTaskDashboardFiltersEmployeePrefQuery__
 *
 * To run a query within a React component, call `useScheduleTaskDashboardFiltersEmployeePrefQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleTaskDashboardFiltersEmployeePrefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleTaskDashboardFiltersEmployeePrefQuery({
 *   variables: {
 *      prefName: // value for 'prefName'
 *   },
 * });
 */
export function useScheduleTaskDashboardFiltersEmployeePrefQuery(baseOptions: Apollo.QueryHookOptions<ScheduleTaskDashboardFiltersEmployeePrefQuery, ScheduleTaskDashboardFiltersEmployeePrefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleTaskDashboardFiltersEmployeePrefQuery, ScheduleTaskDashboardFiltersEmployeePrefQueryVariables>(ScheduleTaskDashboardFiltersEmployeePrefDocument, options);
      }
export function useScheduleTaskDashboardFiltersEmployeePrefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleTaskDashboardFiltersEmployeePrefQuery, ScheduleTaskDashboardFiltersEmployeePrefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleTaskDashboardFiltersEmployeePrefQuery, ScheduleTaskDashboardFiltersEmployeePrefQueryVariables>(ScheduleTaskDashboardFiltersEmployeePrefDocument, options);
        }
export type ScheduleTaskDashboardFiltersEmployeePrefQueryHookResult = ReturnType<typeof useScheduleTaskDashboardFiltersEmployeePrefQuery>;
export type ScheduleTaskDashboardFiltersEmployeePrefLazyQueryHookResult = ReturnType<typeof useScheduleTaskDashboardFiltersEmployeePrefLazyQuery>;
export type ScheduleTaskDashboardFiltersEmployeePrefQueryResult = Apollo.QueryResult<ScheduleTaskDashboardFiltersEmployeePrefQuery, ScheduleTaskDashboardFiltersEmployeePrefQueryVariables>;
export const UpdateScheduleTaskDashboardFiltersEmployeePrefDocument = gql`
    mutation updateScheduleTaskDashboardFiltersEmployeePref($input: EmployeePrefUpdateInput!) {
  employeePrefUpdate(input: $input) {
    employeePref {
      orgId
      employeeId
      prefName
    }
  }
}
    `;
export type UpdateScheduleTaskDashboardFiltersEmployeePrefMutationFn = Apollo.MutationFunction<UpdateScheduleTaskDashboardFiltersEmployeePrefMutation, UpdateScheduleTaskDashboardFiltersEmployeePrefMutationVariables>;

/**
 * __useUpdateScheduleTaskDashboardFiltersEmployeePrefMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleTaskDashboardFiltersEmployeePrefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleTaskDashboardFiltersEmployeePrefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleTaskDashboardFiltersEmployeePrefMutation, { data, loading, error }] = useUpdateScheduleTaskDashboardFiltersEmployeePrefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateScheduleTaskDashboardFiltersEmployeePrefMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduleTaskDashboardFiltersEmployeePrefMutation, UpdateScheduleTaskDashboardFiltersEmployeePrefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduleTaskDashboardFiltersEmployeePrefMutation, UpdateScheduleTaskDashboardFiltersEmployeePrefMutationVariables>(UpdateScheduleTaskDashboardFiltersEmployeePrefDocument, options);
      }
export type UpdateScheduleTaskDashboardFiltersEmployeePrefMutationHookResult = ReturnType<typeof useUpdateScheduleTaskDashboardFiltersEmployeePrefMutation>;
export type UpdateScheduleTaskDashboardFiltersEmployeePrefMutationResult = Apollo.MutationResult<UpdateScheduleTaskDashboardFiltersEmployeePrefMutation>;
export type UpdateScheduleTaskDashboardFiltersEmployeePrefMutationOptions = Apollo.BaseMutationOptions<UpdateScheduleTaskDashboardFiltersEmployeePrefMutation, UpdateScheduleTaskDashboardFiltersEmployeePrefMutationVariables>;
export const ScheduleTaskDashboardColumnsDocument = gql`
    query ScheduleTaskDashboardColumns($prefName: String!) {
  thisEmployee {
    id
    employeePref(prefName: $prefName) {
      orgId
      employeeId
      prefName
      jsonArrayPref {
        ... on EmployeeTaskDashboardLayoutPref {
          columnType
          isVisible
          __typename
        }
        __typename
      }
    }
  }
}
    `;

/**
 * __useScheduleTaskDashboardColumnsQuery__
 *
 * To run a query within a React component, call `useScheduleTaskDashboardColumnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleTaskDashboardColumnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleTaskDashboardColumnsQuery({
 *   variables: {
 *      prefName: // value for 'prefName'
 *   },
 * });
 */
export function useScheduleTaskDashboardColumnsQuery(baseOptions: Apollo.QueryHookOptions<ScheduleTaskDashboardColumnsQuery, ScheduleTaskDashboardColumnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleTaskDashboardColumnsQuery, ScheduleTaskDashboardColumnsQueryVariables>(ScheduleTaskDashboardColumnsDocument, options);
      }
export function useScheduleTaskDashboardColumnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleTaskDashboardColumnsQuery, ScheduleTaskDashboardColumnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleTaskDashboardColumnsQuery, ScheduleTaskDashboardColumnsQueryVariables>(ScheduleTaskDashboardColumnsDocument, options);
        }
export type ScheduleTaskDashboardColumnsQueryHookResult = ReturnType<typeof useScheduleTaskDashboardColumnsQuery>;
export type ScheduleTaskDashboardColumnsLazyQueryHookResult = ReturnType<typeof useScheduleTaskDashboardColumnsLazyQuery>;
export type ScheduleTaskDashboardColumnsQueryResult = Apollo.QueryResult<ScheduleTaskDashboardColumnsQuery, ScheduleTaskDashboardColumnsQueryVariables>;
export const AtsInterviewDefinitionsDocument = gql`
    query AtsInterviewDefinitions($input: AtsInterviewDefinitionsInput!) {
  atsInterviewDefinitions(input: $input) {
    atsInterviewDefinitions {
      ...AtsInterviewDefinition
    }
  }
}
    ${AtsInterviewDefinitionFragmentDoc}`;

/**
 * __useAtsInterviewDefinitionsQuery__
 *
 * To run a query within a React component, call `useAtsInterviewDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAtsInterviewDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAtsInterviewDefinitionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAtsInterviewDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<AtsInterviewDefinitionsQuery, AtsInterviewDefinitionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AtsInterviewDefinitionsQuery, AtsInterviewDefinitionsQueryVariables>(AtsInterviewDefinitionsDocument, options);
      }
export function useAtsInterviewDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AtsInterviewDefinitionsQuery, AtsInterviewDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AtsInterviewDefinitionsQuery, AtsInterviewDefinitionsQueryVariables>(AtsInterviewDefinitionsDocument, options);
        }
export type AtsInterviewDefinitionsQueryHookResult = ReturnType<typeof useAtsInterviewDefinitionsQuery>;
export type AtsInterviewDefinitionsLazyQueryHookResult = ReturnType<typeof useAtsInterviewDefinitionsLazyQuery>;
export type AtsInterviewDefinitionsQueryResult = Apollo.QueryResult<AtsInterviewDefinitionsQuery, AtsInterviewDefinitionsQueryVariables>;
export const JobStageDocument = gql`
    query JobStage($id: uuid!) {
  jobStage(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useJobStageQuery__
 *
 * To run a query within a React component, call `useJobStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobStageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobStageQuery(baseOptions: Apollo.QueryHookOptions<JobStageQuery, JobStageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobStageQuery, JobStageQueryVariables>(JobStageDocument, options);
      }
export function useJobStageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobStageQuery, JobStageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobStageQuery, JobStageQueryVariables>(JobStageDocument, options);
        }
export type JobStageQueryHookResult = ReturnType<typeof useJobStageQuery>;
export type JobStageLazyQueryHookResult = ReturnType<typeof useJobStageLazyQuery>;
export type JobStageQueryResult = Apollo.QueryResult<JobStageQuery, JobStageQueryVariables>;
export const CandidateCoordinatorAndRecruiterDocument = gql`
    query CandidateCoordinatorAndRecruiter($candidateId: uuid!) {
  candidate(id: $candidateId) {
    coordinatorId
    recruiterId
    email
  }
}
    `;

/**
 * __useCandidateCoordinatorAndRecruiterQuery__
 *
 * To run a query within a React component, call `useCandidateCoordinatorAndRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateCoordinatorAndRecruiterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateCoordinatorAndRecruiterQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useCandidateCoordinatorAndRecruiterQuery(baseOptions: Apollo.QueryHookOptions<CandidateCoordinatorAndRecruiterQuery, CandidateCoordinatorAndRecruiterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CandidateCoordinatorAndRecruiterQuery, CandidateCoordinatorAndRecruiterQueryVariables>(CandidateCoordinatorAndRecruiterDocument, options);
      }
export function useCandidateCoordinatorAndRecruiterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateCoordinatorAndRecruiterQuery, CandidateCoordinatorAndRecruiterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CandidateCoordinatorAndRecruiterQuery, CandidateCoordinatorAndRecruiterQueryVariables>(CandidateCoordinatorAndRecruiterDocument, options);
        }
export type CandidateCoordinatorAndRecruiterQueryHookResult = ReturnType<typeof useCandidateCoordinatorAndRecruiterQuery>;
export type CandidateCoordinatorAndRecruiterLazyQueryHookResult = ReturnType<typeof useCandidateCoordinatorAndRecruiterLazyQuery>;
export type CandidateCoordinatorAndRecruiterQueryResult = Apollo.QueryResult<CandidateCoordinatorAndRecruiterQuery, CandidateCoordinatorAndRecruiterQueryVariables>;
export const SelfScheduleOptionsModalDocument = gql`
    query SelfScheduleOptionsModal($applicationId: uuid!) {
  application(id: $applicationId) {
    id
    ...CreateTaskDialogPage_application
  }
  thisEmployee {
    id
    ...Employee
  }
}
    ${CreateTaskDialogPage_ApplicationFragmentDoc}
${EmployeeFragmentDoc}`;

/**
 * __useSelfScheduleOptionsModalQuery__
 *
 * To run a query within a React component, call `useSelfScheduleOptionsModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfScheduleOptionsModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfScheduleOptionsModalQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useSelfScheduleOptionsModalQuery(baseOptions: Apollo.QueryHookOptions<SelfScheduleOptionsModalQuery, SelfScheduleOptionsModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfScheduleOptionsModalQuery, SelfScheduleOptionsModalQueryVariables>(SelfScheduleOptionsModalDocument, options);
      }
export function useSelfScheduleOptionsModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfScheduleOptionsModalQuery, SelfScheduleOptionsModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfScheduleOptionsModalQuery, SelfScheduleOptionsModalQueryVariables>(SelfScheduleOptionsModalDocument, options);
        }
export type SelfScheduleOptionsModalQueryHookResult = ReturnType<typeof useSelfScheduleOptionsModalQuery>;
export type SelfScheduleOptionsModalLazyQueryHookResult = ReturnType<typeof useSelfScheduleOptionsModalLazyQuery>;
export type SelfScheduleOptionsModalQueryResult = Apollo.QueryResult<SelfScheduleOptionsModalQuery, SelfScheduleOptionsModalQueryVariables>;
export const SelfScheduleCalendarsDocument = gql`
    query SelfScheduleCalendars($input: InitScheduleFlowInput!) {
  initScheduleFlow(input: $input) {
    candidateCalendar {
      remoteId
    }
    interviewerCalendar {
      remoteId
    }
  }
}
    `;

/**
 * __useSelfScheduleCalendarsQuery__
 *
 * To run a query within a React component, call `useSelfScheduleCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfScheduleCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfScheduleCalendarsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfScheduleCalendarsQuery(baseOptions: Apollo.QueryHookOptions<SelfScheduleCalendarsQuery, SelfScheduleCalendarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfScheduleCalendarsQuery, SelfScheduleCalendarsQueryVariables>(SelfScheduleCalendarsDocument, options);
      }
export function useSelfScheduleCalendarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfScheduleCalendarsQuery, SelfScheduleCalendarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfScheduleCalendarsQuery, SelfScheduleCalendarsQueryVariables>(SelfScheduleCalendarsDocument, options);
        }
export type SelfScheduleCalendarsQueryHookResult = ReturnType<typeof useSelfScheduleCalendarsQuery>;
export type SelfScheduleCalendarsLazyQueryHookResult = ReturnType<typeof useSelfScheduleCalendarsLazyQuery>;
export type SelfScheduleCalendarsQueryResult = Apollo.QueryResult<SelfScheduleCalendarsQuery, SelfScheduleCalendarsQueryVariables>;
export const SelfScheduleOptionsInterviewPlanDocument = gql`
    query SelfScheduleOptionsInterviewPlan($jobStageId: uuid!) {
  jobStage(id: $jobStageId) {
    id
    ...CreateTaskDialogPage_interviewPlan
    ...cloneInterviewPlan_interviewPlan
    ...getSaveInterviewPlanInput_interviewPlan
    ...SelfScheduleOptions_interviewPlan
  }
}
    ${CreateTaskDialogPage_InterviewPlanFragmentDoc}
${CloneInterviewPlan_InterviewPlanFragmentDoc}
${GetSaveInterviewPlanInput_InterviewPlanFragmentDoc}
${SelfScheduleOptions_InterviewPlanFragmentDoc}`;

/**
 * __useSelfScheduleOptionsInterviewPlanQuery__
 *
 * To run a query within a React component, call `useSelfScheduleOptionsInterviewPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfScheduleOptionsInterviewPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfScheduleOptionsInterviewPlanQuery({
 *   variables: {
 *      jobStageId: // value for 'jobStageId'
 *   },
 * });
 */
export function useSelfScheduleOptionsInterviewPlanQuery(baseOptions: Apollo.QueryHookOptions<SelfScheduleOptionsInterviewPlanQuery, SelfScheduleOptionsInterviewPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfScheduleOptionsInterviewPlanQuery, SelfScheduleOptionsInterviewPlanQueryVariables>(SelfScheduleOptionsInterviewPlanDocument, options);
      }
export function useSelfScheduleOptionsInterviewPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfScheduleOptionsInterviewPlanQuery, SelfScheduleOptionsInterviewPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfScheduleOptionsInterviewPlanQuery, SelfScheduleOptionsInterviewPlanQueryVariables>(SelfScheduleOptionsInterviewPlanDocument, options);
        }
export type SelfScheduleOptionsInterviewPlanQueryHookResult = ReturnType<typeof useSelfScheduleOptionsInterviewPlanQuery>;
export type SelfScheduleOptionsInterviewPlanLazyQueryHookResult = ReturnType<typeof useSelfScheduleOptionsInterviewPlanLazyQuery>;
export type SelfScheduleOptionsInterviewPlanQueryResult = Apollo.QueryResult<SelfScheduleOptionsInterviewPlanQuery, SelfScheduleOptionsInterviewPlanQueryVariables>;
export const SelfScheduleOptionsUpsertDocument = gql`
    mutation SelfScheduleOptionsUpsert($input: SelfScheduleOptionsUpsertInput!) {
  selfScheduleOptionsUpsert(input: $input) {
    ...SelfScheduleOptionsModal_selfSchedule
  }
}
    ${SelfScheduleOptionsModal_SelfScheduleFragmentDoc}`;
export type SelfScheduleOptionsUpsertMutationFn = Apollo.MutationFunction<SelfScheduleOptionsUpsertMutation, SelfScheduleOptionsUpsertMutationVariables>;

/**
 * __useSelfScheduleOptionsUpsertMutation__
 *
 * To run a mutation, you first call `useSelfScheduleOptionsUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfScheduleOptionsUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfScheduleOptionsUpsertMutation, { data, loading, error }] = useSelfScheduleOptionsUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfScheduleOptionsUpsertMutation(baseOptions?: Apollo.MutationHookOptions<SelfScheduleOptionsUpsertMutation, SelfScheduleOptionsUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelfScheduleOptionsUpsertMutation, SelfScheduleOptionsUpsertMutationVariables>(SelfScheduleOptionsUpsertDocument, options);
      }
export type SelfScheduleOptionsUpsertMutationHookResult = ReturnType<typeof useSelfScheduleOptionsUpsertMutation>;
export type SelfScheduleOptionsUpsertMutationResult = Apollo.MutationResult<SelfScheduleOptionsUpsertMutation>;
export type SelfScheduleOptionsUpsertMutationOptions = Apollo.BaseMutationOptions<SelfScheduleOptionsUpsertMutation, SelfScheduleOptionsUpsertMutationVariables>;
export const SuggestedTimeRangesCountDocument = gql`
    query SuggestedTimeRangesCount($input: SuggestedTimeRangesCountInput!) {
  suggestedTimeRangesCount(input: $input) {
    timeRangeCounts {
      range {
        startAt
        endAt
      }
      count
    }
    employeeMissingZoomUserId
  }
}
    `;

/**
 * __useSuggestedTimeRangesCountQuery__
 *
 * To run a query within a React component, call `useSuggestedTimeRangesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedTimeRangesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedTimeRangesCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuggestedTimeRangesCountQuery(baseOptions: Apollo.QueryHookOptions<SuggestedTimeRangesCountQuery, SuggestedTimeRangesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedTimeRangesCountQuery, SuggestedTimeRangesCountQueryVariables>(SuggestedTimeRangesCountDocument, options);
      }
export function useSuggestedTimeRangesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedTimeRangesCountQuery, SuggestedTimeRangesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedTimeRangesCountQuery, SuggestedTimeRangesCountQueryVariables>(SuggestedTimeRangesCountDocument, options);
        }
export type SuggestedTimeRangesCountQueryHookResult = ReturnType<typeof useSuggestedTimeRangesCountQuery>;
export type SuggestedTimeRangesCountLazyQueryHookResult = ReturnType<typeof useSuggestedTimeRangesCountLazyQuery>;
export type SuggestedTimeRangesCountQueryResult = Apollo.QueryResult<SuggestedTimeRangesCountQuery, SuggestedTimeRangesCountQueryVariables>;
export const SelfScheduleOptionsCountDocument = gql`
    query SelfScheduleOptionsCount($input: SelfScheduleOptionsCountInput!) {
  selfScheduleOptionsCount(input: $input) {
    timeRangeCounts {
      range {
        startAt
        endAt
      }
      count
    }
    employeeMissingZoomUserId
  }
}
    `;

/**
 * __useSelfScheduleOptionsCountQuery__
 *
 * To run a query within a React component, call `useSelfScheduleOptionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfScheduleOptionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfScheduleOptionsCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfScheduleOptionsCountQuery(baseOptions: Apollo.QueryHookOptions<SelfScheduleOptionsCountQuery, SelfScheduleOptionsCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfScheduleOptionsCountQuery, SelfScheduleOptionsCountQueryVariables>(SelfScheduleOptionsCountDocument, options);
      }
export function useSelfScheduleOptionsCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfScheduleOptionsCountQuery, SelfScheduleOptionsCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfScheduleOptionsCountQuery, SelfScheduleOptionsCountQueryVariables>(SelfScheduleOptionsCountDocument, options);
        }
export type SelfScheduleOptionsCountQueryHookResult = ReturnType<typeof useSelfScheduleOptionsCountQuery>;
export type SelfScheduleOptionsCountLazyQueryHookResult = ReturnType<typeof useSelfScheduleOptionsCountLazyQuery>;
export type SelfScheduleOptionsCountQueryResult = Apollo.QueryResult<SelfScheduleOptionsCountQuery, SelfScheduleOptionsCountQueryVariables>;
export const SelfScheduleTemplatesDocument = gql`
    query SelfScheduleTemplates($jobStageId: uuid!) {
  jobStage(id: $jobStageId) {
    jobStageSettings {
      interviewerEventTemplateId
      candidateEventTemplateId
      candidateEmailTemplateId
    }
  }
}
    `;

/**
 * __useSelfScheduleTemplatesQuery__
 *
 * To run a query within a React component, call `useSelfScheduleTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfScheduleTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfScheduleTemplatesQuery({
 *   variables: {
 *      jobStageId: // value for 'jobStageId'
 *   },
 * });
 */
export function useSelfScheduleTemplatesQuery(baseOptions: Apollo.QueryHookOptions<SelfScheduleTemplatesQuery, SelfScheduleTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfScheduleTemplatesQuery, SelfScheduleTemplatesQueryVariables>(SelfScheduleTemplatesDocument, options);
      }
export function useSelfScheduleTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfScheduleTemplatesQuery, SelfScheduleTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfScheduleTemplatesQuery, SelfScheduleTemplatesQueryVariables>(SelfScheduleTemplatesDocument, options);
        }
export type SelfScheduleTemplatesQueryHookResult = ReturnType<typeof useSelfScheduleTemplatesQuery>;
export type SelfScheduleTemplatesLazyQueryHookResult = ReturnType<typeof useSelfScheduleTemplatesLazyQuery>;
export type SelfScheduleTemplatesQueryResult = Apollo.QueryResult<SelfScheduleTemplatesQuery, SelfScheduleTemplatesQueryVariables>;
export const SelfScheduleOptionsDeleteDocument = gql`
    mutation SelfScheduleOptionsDelete($id: uuid!) {
  selfScheduleOptionsDelete(id: $id) {
    id
  }
}
    `;
export type SelfScheduleOptionsDeleteMutationFn = Apollo.MutationFunction<SelfScheduleOptionsDeleteMutation, SelfScheduleOptionsDeleteMutationVariables>;

/**
 * __useSelfScheduleOptionsDeleteMutation__
 *
 * To run a mutation, you first call `useSelfScheduleOptionsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfScheduleOptionsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfScheduleOptionsDeleteMutation, { data, loading, error }] = useSelfScheduleOptionsDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSelfScheduleOptionsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<SelfScheduleOptionsDeleteMutation, SelfScheduleOptionsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelfScheduleOptionsDeleteMutation, SelfScheduleOptionsDeleteMutationVariables>(SelfScheduleOptionsDeleteDocument, options);
      }
export type SelfScheduleOptionsDeleteMutationHookResult = ReturnType<typeof useSelfScheduleOptionsDeleteMutation>;
export type SelfScheduleOptionsDeleteMutationResult = Apollo.MutationResult<SelfScheduleOptionsDeleteMutation>;
export type SelfScheduleOptionsDeleteMutationOptions = Apollo.BaseMutationOptions<SelfScheduleOptionsDeleteMutation, SelfScheduleOptionsDeleteMutationVariables>;
export const SelfScheduleTaskCreateDocument = gql`
    mutation SelfScheduleTaskCreate($input: TaskCreateInput!) {
  taskCreate(input: $input) {
    task {
      id
    }
  }
}
    `;
export type SelfScheduleTaskCreateMutationFn = Apollo.MutationFunction<SelfScheduleTaskCreateMutation, SelfScheduleTaskCreateMutationVariables>;

/**
 * __useSelfScheduleTaskCreateMutation__
 *
 * To run a mutation, you first call `useSelfScheduleTaskCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfScheduleTaskCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfScheduleTaskCreateMutation, { data, loading, error }] = useSelfScheduleTaskCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfScheduleTaskCreateMutation(baseOptions?: Apollo.MutationHookOptions<SelfScheduleTaskCreateMutation, SelfScheduleTaskCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelfScheduleTaskCreateMutation, SelfScheduleTaskCreateMutationVariables>(SelfScheduleTaskCreateDocument, options);
      }
export type SelfScheduleTaskCreateMutationHookResult = ReturnType<typeof useSelfScheduleTaskCreateMutation>;
export type SelfScheduleTaskCreateMutationResult = Apollo.MutationResult<SelfScheduleTaskCreateMutation>;
export type SelfScheduleTaskCreateMutationOptions = Apollo.BaseMutationOptions<SelfScheduleTaskCreateMutation, SelfScheduleTaskCreateMutationVariables>;
export const BrandingThemeBuilderCreateDocument = gql`
    mutation BrandingThemeBuilderCreate($input: BrandingThemeCreateInput!) {
  brandingThemeCreate(input: $input) {
    theme {
      ...BrandingThemeBuilderComponent_brandingTheme
    }
  }
}
    ${BrandingThemeBuilderComponent_BrandingThemeFragmentDoc}`;
export type BrandingThemeBuilderCreateMutationFn = Apollo.MutationFunction<BrandingThemeBuilderCreateMutation, BrandingThemeBuilderCreateMutationVariables>;

/**
 * __useBrandingThemeBuilderCreateMutation__
 *
 * To run a mutation, you first call `useBrandingThemeBuilderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandingThemeBuilderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandingThemeBuilderCreateMutation, { data, loading, error }] = useBrandingThemeBuilderCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandingThemeBuilderCreateMutation(baseOptions?: Apollo.MutationHookOptions<BrandingThemeBuilderCreateMutation, BrandingThemeBuilderCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BrandingThemeBuilderCreateMutation, BrandingThemeBuilderCreateMutationVariables>(BrandingThemeBuilderCreateDocument, options);
      }
export type BrandingThemeBuilderCreateMutationHookResult = ReturnType<typeof useBrandingThemeBuilderCreateMutation>;
export type BrandingThemeBuilderCreateMutationResult = Apollo.MutationResult<BrandingThemeBuilderCreateMutation>;
export type BrandingThemeBuilderCreateMutationOptions = Apollo.BaseMutationOptions<BrandingThemeBuilderCreateMutation, BrandingThemeBuilderCreateMutationVariables>;
export const BrandingThemeBuilderUpdateDocument = gql`
    mutation BrandingThemeBuilderUpdate($input: BrandingThemeUpdateInput!) {
  brandingThemeUpdate(input: $input) {
    theme {
      ...BrandingThemeBuilderComponent_brandingTheme
    }
  }
}
    ${BrandingThemeBuilderComponent_BrandingThemeFragmentDoc}`;
export type BrandingThemeBuilderUpdateMutationFn = Apollo.MutationFunction<BrandingThemeBuilderUpdateMutation, BrandingThemeBuilderUpdateMutationVariables>;

/**
 * __useBrandingThemeBuilderUpdateMutation__
 *
 * To run a mutation, you first call `useBrandingThemeBuilderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandingThemeBuilderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandingThemeBuilderUpdateMutation, { data, loading, error }] = useBrandingThemeBuilderUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandingThemeBuilderUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BrandingThemeBuilderUpdateMutation, BrandingThemeBuilderUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BrandingThemeBuilderUpdateMutation, BrandingThemeBuilderUpdateMutationVariables>(BrandingThemeBuilderUpdateDocument, options);
      }
export type BrandingThemeBuilderUpdateMutationHookResult = ReturnType<typeof useBrandingThemeBuilderUpdateMutation>;
export type BrandingThemeBuilderUpdateMutationResult = Apollo.MutationResult<BrandingThemeBuilderUpdateMutation>;
export type BrandingThemeBuilderUpdateMutationOptions = Apollo.BaseMutationOptions<BrandingThemeBuilderUpdateMutation, BrandingThemeBuilderUpdateMutationVariables>;
export const BrandingThemeDeleteDocument = gql`
    mutation BrandingThemeDelete($id: uuid!) {
  brandingThemeDelete(id: $id) {
    result
  }
}
    `;
export type BrandingThemeDeleteMutationFn = Apollo.MutationFunction<BrandingThemeDeleteMutation, BrandingThemeDeleteMutationVariables>;

/**
 * __useBrandingThemeDeleteMutation__
 *
 * To run a mutation, you first call `useBrandingThemeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandingThemeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandingThemeDeleteMutation, { data, loading, error }] = useBrandingThemeDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBrandingThemeDeleteMutation(baseOptions?: Apollo.MutationHookOptions<BrandingThemeDeleteMutation, BrandingThemeDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BrandingThemeDeleteMutation, BrandingThemeDeleteMutationVariables>(BrandingThemeDeleteDocument, options);
      }
export type BrandingThemeDeleteMutationHookResult = ReturnType<typeof useBrandingThemeDeleteMutation>;
export type BrandingThemeDeleteMutationResult = Apollo.MutationResult<BrandingThemeDeleteMutation>;
export type BrandingThemeDeleteMutationOptions = Apollo.BaseMutationOptions<BrandingThemeDeleteMutation, BrandingThemeDeleteMutationVariables>;
export const BrandingThemesDocument = gql`
    query BrandingThemes {
  brandingThemes {
    items {
      ...BrandingThemeBuilder_brandingTheme
      ...BrandingThemesItem_brandingTheme
    }
  }
}
    ${BrandingThemeBuilder_BrandingThemeFragmentDoc}
${BrandingThemesItem_BrandingThemeFragmentDoc}`;

/**
 * __useBrandingThemesQuery__
 *
 * To run a query within a React component, call `useBrandingThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandingThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandingThemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandingThemesQuery(baseOptions?: Apollo.QueryHookOptions<BrandingThemesQuery, BrandingThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandingThemesQuery, BrandingThemesQueryVariables>(BrandingThemesDocument, options);
      }
export function useBrandingThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandingThemesQuery, BrandingThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandingThemesQuery, BrandingThemesQueryVariables>(BrandingThemesDocument, options);
        }
export type BrandingThemesQueryHookResult = ReturnType<typeof useBrandingThemesQuery>;
export type BrandingThemesLazyQueryHookResult = ReturnType<typeof useBrandingThemesLazyQuery>;
export type BrandingThemesQueryResult = Apollo.QueryResult<BrandingThemesQuery, BrandingThemesQueryVariables>;
export const DuplicateBrandingThemeDocument = gql`
    mutation DuplicateBrandingTheme($input: BrandingThemeCreateInput!) {
  brandingThemeCreate(input: $input) {
    theme {
      ...BrandingThemeBuilder_brandingTheme
      ...BrandingThemesItem_brandingTheme
    }
  }
}
    ${BrandingThemeBuilder_BrandingThemeFragmentDoc}
${BrandingThemesItem_BrandingThemeFragmentDoc}`;
export type DuplicateBrandingThemeMutationFn = Apollo.MutationFunction<DuplicateBrandingThemeMutation, DuplicateBrandingThemeMutationVariables>;

/**
 * __useDuplicateBrandingThemeMutation__
 *
 * To run a mutation, you first call `useDuplicateBrandingThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateBrandingThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateBrandingThemeMutation, { data, loading, error }] = useDuplicateBrandingThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateBrandingThemeMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateBrandingThemeMutation, DuplicateBrandingThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateBrandingThemeMutation, DuplicateBrandingThemeMutationVariables>(DuplicateBrandingThemeDocument, options);
      }
export type DuplicateBrandingThemeMutationHookResult = ReturnType<typeof useDuplicateBrandingThemeMutation>;
export type DuplicateBrandingThemeMutationResult = Apollo.MutationResult<DuplicateBrandingThemeMutation>;
export type DuplicateBrandingThemeMutationOptions = Apollo.BaseMutationOptions<DuplicateBrandingThemeMutation, DuplicateBrandingThemeMutationVariables>;
export const PortalTemplatesDocument = gql`
    query PortalTemplates($input: PortalTemplatesInput!) {
  portalTemplates(input: $input) {
    nextCursor
    items {
      id
      ...PageTemplateRow_template
    }
  }
}
    ${PageTemplateRow_TemplateFragmentDoc}`;

/**
 * __usePortalTemplatesQuery__
 *
 * To run a query within a React component, call `usePortalTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortalTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortalTemplatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortalTemplatesQuery(baseOptions: Apollo.QueryHookOptions<PortalTemplatesQuery, PortalTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PortalTemplatesQuery, PortalTemplatesQueryVariables>(PortalTemplatesDocument, options);
      }
export function usePortalTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortalTemplatesQuery, PortalTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PortalTemplatesQuery, PortalTemplatesQueryVariables>(PortalTemplatesDocument, options);
        }
export type PortalTemplatesQueryHookResult = ReturnType<typeof usePortalTemplatesQuery>;
export type PortalTemplatesLazyQueryHookResult = ReturnType<typeof usePortalTemplatesLazyQuery>;
export type PortalTemplatesQueryResult = Apollo.QueryResult<PortalTemplatesQuery, PortalTemplatesQueryVariables>;
export const PortalTemplatePreviewDocument = gql`
    query PortalTemplatePreview($id: uuid!) {
  portalTemplate(id: $id) {
    id
    ...TemplatePreview_portalTemplate
  }
}
    ${TemplatePreview_PortalTemplateFragmentDoc}`;

/**
 * __usePortalTemplatePreviewQuery__
 *
 * To run a query within a React component, call `usePortalTemplatePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortalTemplatePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortalTemplatePreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePortalTemplatePreviewQuery(baseOptions: Apollo.QueryHookOptions<PortalTemplatePreviewQuery, PortalTemplatePreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PortalTemplatePreviewQuery, PortalTemplatePreviewQueryVariables>(PortalTemplatePreviewDocument, options);
      }
export function usePortalTemplatePreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortalTemplatePreviewQuery, PortalTemplatePreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PortalTemplatePreviewQuery, PortalTemplatePreviewQueryVariables>(PortalTemplatePreviewDocument, options);
        }
export type PortalTemplatePreviewQueryHookResult = ReturnType<typeof usePortalTemplatePreviewQuery>;
export type PortalTemplatePreviewLazyQueryHookResult = ReturnType<typeof usePortalTemplatePreviewLazyQuery>;
export type PortalTemplatePreviewQueryResult = Apollo.QueryResult<PortalTemplatePreviewQuery, PortalTemplatePreviewQueryVariables>;
export const PortalTemplateDeleteDocument = gql`
    mutation portalTemplateDelete($id: uuid!) {
  portalTemplateDelete(id: $id) {
    result
  }
}
    `;
export type PortalTemplateDeleteMutationFn = Apollo.MutationFunction<PortalTemplateDeleteMutation, PortalTemplateDeleteMutationVariables>;

/**
 * __usePortalTemplateDeleteMutation__
 *
 * To run a mutation, you first call `usePortalTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePortalTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [portalTemplateDeleteMutation, { data, loading, error }] = usePortalTemplateDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePortalTemplateDeleteMutation(baseOptions?: Apollo.MutationHookOptions<PortalTemplateDeleteMutation, PortalTemplateDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PortalTemplateDeleteMutation, PortalTemplateDeleteMutationVariables>(PortalTemplateDeleteDocument, options);
      }
export type PortalTemplateDeleteMutationHookResult = ReturnType<typeof usePortalTemplateDeleteMutation>;
export type PortalTemplateDeleteMutationResult = Apollo.MutationResult<PortalTemplateDeleteMutation>;
export type PortalTemplateDeleteMutationOptions = Apollo.BaseMutationOptions<PortalTemplateDeleteMutation, PortalTemplateDeleteMutationVariables>;
export const LinkUnfurlDocument = gql`
    query LinkUnfurl($input: LinkUnfurlInput!) {
  linkUnfurl(input: $input) {
    url
    title
    description
    thumbnail
  }
}
    `;

/**
 * __useLinkUnfurlQuery__
 *
 * To run a query within a React component, call `useLinkUnfurlQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkUnfurlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkUnfurlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkUnfurlQuery(baseOptions: Apollo.QueryHookOptions<LinkUnfurlQuery, LinkUnfurlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkUnfurlQuery, LinkUnfurlQueryVariables>(LinkUnfurlDocument, options);
      }
export function useLinkUnfurlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkUnfurlQuery, LinkUnfurlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkUnfurlQuery, LinkUnfurlQueryVariables>(LinkUnfurlDocument, options);
        }
export type LinkUnfurlQueryHookResult = ReturnType<typeof useLinkUnfurlQuery>;
export type LinkUnfurlLazyQueryHookResult = ReturnType<typeof useLinkUnfurlLazyQuery>;
export type LinkUnfurlQueryResult = Apollo.QueryResult<LinkUnfurlQuery, LinkUnfurlQueryVariables>;
export const PortalTemplateDocument = gql`
    query PortalTemplate($id: uuid!) {
  portalTemplate(id: $id) {
    ...TemplatePreview_portalTemplate
  }
}
    ${TemplatePreview_PortalTemplateFragmentDoc}`;

/**
 * __usePortalTemplateQuery__
 *
 * To run a query within a React component, call `usePortalTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortalTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortalTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePortalTemplateQuery(baseOptions: Apollo.QueryHookOptions<PortalTemplateQuery, PortalTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PortalTemplateQuery, PortalTemplateQueryVariables>(PortalTemplateDocument, options);
      }
export function usePortalTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PortalTemplateQuery, PortalTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PortalTemplateQuery, PortalTemplateQueryVariables>(PortalTemplateDocument, options);
        }
export type PortalTemplateQueryHookResult = ReturnType<typeof usePortalTemplateQuery>;
export type PortalTemplateLazyQueryHookResult = ReturnType<typeof usePortalTemplateLazyQuery>;
export type PortalTemplateQueryResult = Apollo.QueryResult<PortalTemplateQuery, PortalTemplateQueryVariables>;
export const PortalTemplateUpdateDocument = gql`
    mutation portalTemplateUpdate($input: PortalTemplateUpdateInput!) {
  portalTemplateUpdate(input: $input) {
    template {
      id
      ...TemplatePreview_portalTemplate
    }
  }
}
    ${TemplatePreview_PortalTemplateFragmentDoc}`;
export type PortalTemplateUpdateMutationFn = Apollo.MutationFunction<PortalTemplateUpdateMutation, PortalTemplateUpdateMutationVariables>;

/**
 * __usePortalTemplateUpdateMutation__
 *
 * To run a mutation, you first call `usePortalTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePortalTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [portalTemplateUpdateMutation, { data, loading, error }] = usePortalTemplateUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortalTemplateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PortalTemplateUpdateMutation, PortalTemplateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PortalTemplateUpdateMutation, PortalTemplateUpdateMutationVariables>(PortalTemplateUpdateDocument, options);
      }
export type PortalTemplateUpdateMutationHookResult = ReturnType<typeof usePortalTemplateUpdateMutation>;
export type PortalTemplateUpdateMutationResult = Apollo.MutationResult<PortalTemplateUpdateMutation>;
export type PortalTemplateUpdateMutationOptions = Apollo.BaseMutationOptions<PortalTemplateUpdateMutation, PortalTemplateUpdateMutationVariables>;
export const PortalTemplateCreateDocument = gql`
    mutation portalTemplateCreate($input: PortalTemplateCreateInput!) {
  portalTemplateCreate(input: $input) {
    template {
      id
      ...TemplatePreview_portalTemplate
    }
  }
}
    ${TemplatePreview_PortalTemplateFragmentDoc}`;
export type PortalTemplateCreateMutationFn = Apollo.MutationFunction<PortalTemplateCreateMutation, PortalTemplateCreateMutationVariables>;

/**
 * __usePortalTemplateCreateMutation__
 *
 * To run a mutation, you first call `usePortalTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePortalTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [portalTemplateCreateMutation, { data, loading, error }] = usePortalTemplateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortalTemplateCreateMutation(baseOptions?: Apollo.MutationHookOptions<PortalTemplateCreateMutation, PortalTemplateCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PortalTemplateCreateMutation, PortalTemplateCreateMutationVariables>(PortalTemplateCreateDocument, options);
      }
export type PortalTemplateCreateMutationHookResult = ReturnType<typeof usePortalTemplateCreateMutation>;
export type PortalTemplateCreateMutationResult = Apollo.MutationResult<PortalTemplateCreateMutation>;
export type PortalTemplateCreateMutationOptions = Apollo.BaseMutationOptions<PortalTemplateCreateMutation, PortalTemplateCreateMutationVariables>;
export const MicrosoftIntegrationGetOAuthUrlDocument = gql`
    query MicrosoftIntegrationGetOAuthUrl($input: IntegrationGetOauthUrlInput!) {
  integrationGetOauthUrl(input: $input) {
    url
  }
}
    `;

/**
 * __useMicrosoftIntegrationGetOAuthUrlQuery__
 *
 * To run a query within a React component, call `useMicrosoftIntegrationGetOAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useMicrosoftIntegrationGetOAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMicrosoftIntegrationGetOAuthUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMicrosoftIntegrationGetOAuthUrlQuery(baseOptions: Apollo.QueryHookOptions<MicrosoftIntegrationGetOAuthUrlQuery, MicrosoftIntegrationGetOAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MicrosoftIntegrationGetOAuthUrlQuery, MicrosoftIntegrationGetOAuthUrlQueryVariables>(MicrosoftIntegrationGetOAuthUrlDocument, options);
      }
export function useMicrosoftIntegrationGetOAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MicrosoftIntegrationGetOAuthUrlQuery, MicrosoftIntegrationGetOAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MicrosoftIntegrationGetOAuthUrlQuery, MicrosoftIntegrationGetOAuthUrlQueryVariables>(MicrosoftIntegrationGetOAuthUrlDocument, options);
        }
export type MicrosoftIntegrationGetOAuthUrlQueryHookResult = ReturnType<typeof useMicrosoftIntegrationGetOAuthUrlQuery>;
export type MicrosoftIntegrationGetOAuthUrlLazyQueryHookResult = ReturnType<typeof useMicrosoftIntegrationGetOAuthUrlLazyQuery>;
export type MicrosoftIntegrationGetOAuthUrlQueryResult = Apollo.QueryResult<MicrosoftIntegrationGetOAuthUrlQuery, MicrosoftIntegrationGetOAuthUrlQueryVariables>;
export const MicrosoftGccHighIntegrationGetOAuthUrlDocument = gql`
    query MicrosoftGccHighIntegrationGetOAuthUrl($input: IntegrationGetOauthUrlInput!) {
  integrationGetOauthUrl(input: $input) {
    url
  }
}
    `;

/**
 * __useMicrosoftGccHighIntegrationGetOAuthUrlQuery__
 *
 * To run a query within a React component, call `useMicrosoftGccHighIntegrationGetOAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useMicrosoftGccHighIntegrationGetOAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMicrosoftGccHighIntegrationGetOAuthUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMicrosoftGccHighIntegrationGetOAuthUrlQuery(baseOptions: Apollo.QueryHookOptions<MicrosoftGccHighIntegrationGetOAuthUrlQuery, MicrosoftGccHighIntegrationGetOAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MicrosoftGccHighIntegrationGetOAuthUrlQuery, MicrosoftGccHighIntegrationGetOAuthUrlQueryVariables>(MicrosoftGccHighIntegrationGetOAuthUrlDocument, options);
      }
export function useMicrosoftGccHighIntegrationGetOAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MicrosoftGccHighIntegrationGetOAuthUrlQuery, MicrosoftGccHighIntegrationGetOAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MicrosoftGccHighIntegrationGetOAuthUrlQuery, MicrosoftGccHighIntegrationGetOAuthUrlQueryVariables>(MicrosoftGccHighIntegrationGetOAuthUrlDocument, options);
        }
export type MicrosoftGccHighIntegrationGetOAuthUrlQueryHookResult = ReturnType<typeof useMicrosoftGccHighIntegrationGetOAuthUrlQuery>;
export type MicrosoftGccHighIntegrationGetOAuthUrlLazyQueryHookResult = ReturnType<typeof useMicrosoftGccHighIntegrationGetOAuthUrlLazyQuery>;
export type MicrosoftGccHighIntegrationGetOAuthUrlQueryResult = Apollo.QueryResult<MicrosoftGccHighIntegrationGetOAuthUrlQuery, MicrosoftGccHighIntegrationGetOAuthUrlQueryVariables>;
export const DisconnectAtsDialogDocument = gql`
    query DisconnectAtsDialog {
  thisOrg {
    upcomingInterviewsCount
    activeCandidateAvailabilityLinksCount
    activeSelfScheduleLinksCount
  }
}
    `;

/**
 * __useDisconnectAtsDialogQuery__
 *
 * To run a query within a React component, call `useDisconnectAtsDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisconnectAtsDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisconnectAtsDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectAtsDialogQuery(baseOptions?: Apollo.QueryHookOptions<DisconnectAtsDialogQuery, DisconnectAtsDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisconnectAtsDialogQuery, DisconnectAtsDialogQueryVariables>(DisconnectAtsDialogDocument, options);
      }
export function useDisconnectAtsDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisconnectAtsDialogQuery, DisconnectAtsDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisconnectAtsDialogQuery, DisconnectAtsDialogQueryVariables>(DisconnectAtsDialogDocument, options);
        }
export type DisconnectAtsDialogQueryHookResult = ReturnType<typeof useDisconnectAtsDialogQuery>;
export type DisconnectAtsDialogLazyQueryHookResult = ReturnType<typeof useDisconnectAtsDialogLazyQuery>;
export type DisconnectAtsDialogQueryResult = Apollo.QueryResult<DisconnectAtsDialogQuery, DisconnectAtsDialogQueryVariables>;
export const InterviewerPortalTabOldDocument = gql`
    query InterviewerPortalTabOld {
  thisOrg {
    id
    interviewerPortalSettings {
      ...InterviewerPortalSettingsOld
    }
  }
}
    ${InterviewerPortalSettingsOldFragmentDoc}`;

/**
 * __useInterviewerPortalTabOldQuery__
 *
 * To run a query within a React component, call `useInterviewerPortalTabOldQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewerPortalTabOldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewerPortalTabOldQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterviewerPortalTabOldQuery(baseOptions?: Apollo.QueryHookOptions<InterviewerPortalTabOldQuery, InterviewerPortalTabOldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewerPortalTabOldQuery, InterviewerPortalTabOldQueryVariables>(InterviewerPortalTabOldDocument, options);
      }
export function useInterviewerPortalTabOldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewerPortalTabOldQuery, InterviewerPortalTabOldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewerPortalTabOldQuery, InterviewerPortalTabOldQueryVariables>(InterviewerPortalTabOldDocument, options);
        }
export type InterviewerPortalTabOldQueryHookResult = ReturnType<typeof useInterviewerPortalTabOldQuery>;
export type InterviewerPortalTabOldLazyQueryHookResult = ReturnType<typeof useInterviewerPortalTabOldLazyQuery>;
export type InterviewerPortalTabOldQueryResult = Apollo.QueryResult<InterviewerPortalTabOldQuery, InterviewerPortalTabOldQueryVariables>;
export const UpdateInterviewPortalSettingOldDocument = gql`
    mutation UpdateInterviewPortalSettingOld($input: InterviewerPortalSettingsUpsertInput!) {
  interviewerPortalSettingsUpsert(input: $input) {
    ...InterviewerPortalSettingsOld
  }
}
    ${InterviewerPortalSettingsOldFragmentDoc}`;
export type UpdateInterviewPortalSettingOldMutationFn = Apollo.MutationFunction<UpdateInterviewPortalSettingOldMutation, UpdateInterviewPortalSettingOldMutationVariables>;

/**
 * __useUpdateInterviewPortalSettingOldMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewPortalSettingOldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewPortalSettingOldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewPortalSettingOldMutation, { data, loading, error }] = useUpdateInterviewPortalSettingOldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewPortalSettingOldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewPortalSettingOldMutation, UpdateInterviewPortalSettingOldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewPortalSettingOldMutation, UpdateInterviewPortalSettingOldMutationVariables>(UpdateInterviewPortalSettingOldDocument, options);
      }
export type UpdateInterviewPortalSettingOldMutationHookResult = ReturnType<typeof useUpdateInterviewPortalSettingOldMutation>;
export type UpdateInterviewPortalSettingOldMutationResult = Apollo.MutationResult<UpdateInterviewPortalSettingOldMutation>;
export type UpdateInterviewPortalSettingOldMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewPortalSettingOldMutation, UpdateInterviewPortalSettingOldMutationVariables>;
export const InterviewerPortalTabDocument = gql`
    query InterviewerPortalTab {
  thisOrg {
    id
    interviewerPortalSettings {
      ...InterviewerPortalSettings
    }
  }
}
    ${InterviewerPortalSettingsFragmentDoc}`;

/**
 * __useInterviewerPortalTabQuery__
 *
 * To run a query within a React component, call `useInterviewerPortalTabQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewerPortalTabQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewerPortalTabQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterviewerPortalTabQuery(baseOptions?: Apollo.QueryHookOptions<InterviewerPortalTabQuery, InterviewerPortalTabQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewerPortalTabQuery, InterviewerPortalTabQueryVariables>(InterviewerPortalTabDocument, options);
      }
export function useInterviewerPortalTabLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewerPortalTabQuery, InterviewerPortalTabQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewerPortalTabQuery, InterviewerPortalTabQueryVariables>(InterviewerPortalTabDocument, options);
        }
export type InterviewerPortalTabQueryHookResult = ReturnType<typeof useInterviewerPortalTabQuery>;
export type InterviewerPortalTabLazyQueryHookResult = ReturnType<typeof useInterviewerPortalTabLazyQuery>;
export type InterviewerPortalTabQueryResult = Apollo.QueryResult<InterviewerPortalTabQuery, InterviewerPortalTabQueryVariables>;
export const UpdateInterviewPortalSettingDocument = gql`
    mutation UpdateInterviewPortalSetting($input: InterviewerPortalSettingsUpsertInput!) {
  interviewerPortalSettingsUpsert(input: $input) {
    ...InterviewerPortalSettings
  }
}
    ${InterviewerPortalSettingsFragmentDoc}`;
export type UpdateInterviewPortalSettingMutationFn = Apollo.MutationFunction<UpdateInterviewPortalSettingMutation, UpdateInterviewPortalSettingMutationVariables>;

/**
 * __useUpdateInterviewPortalSettingMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewPortalSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewPortalSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewPortalSettingMutation, { data, loading, error }] = useUpdateInterviewPortalSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewPortalSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewPortalSettingMutation, UpdateInterviewPortalSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewPortalSettingMutation, UpdateInterviewPortalSettingMutationVariables>(UpdateInterviewPortalSettingDocument, options);
      }
export type UpdateInterviewPortalSettingMutationHookResult = ReturnType<typeof useUpdateInterviewPortalSettingMutation>;
export type UpdateInterviewPortalSettingMutationResult = Apollo.MutationResult<UpdateInterviewPortalSettingMutation>;
export type UpdateInterviewPortalSettingMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewPortalSettingMutation, UpdateInterviewPortalSettingMutationVariables>;
export const OrganizationAttributesDocument = gql`
    query OrganizationAttributes($input: EmployeeAttributeNamesInput!) {
  employeeAttributeNames(input: $input) {
    items {
      ...AttributeFragment
    }
  }
}
    ${AttributeFragmentFragmentDoc}`;

/**
 * __useOrganizationAttributesQuery__
 *
 * To run a query within a React component, call `useOrganizationAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAttributesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationAttributesQuery(baseOptions: Apollo.QueryHookOptions<OrganizationAttributesQuery, OrganizationAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationAttributesQuery, OrganizationAttributesQueryVariables>(OrganizationAttributesDocument, options);
      }
export function useOrganizationAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationAttributesQuery, OrganizationAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationAttributesQuery, OrganizationAttributesQueryVariables>(OrganizationAttributesDocument, options);
        }
export type OrganizationAttributesQueryHookResult = ReturnType<typeof useOrganizationAttributesQuery>;
export type OrganizationAttributesLazyQueryHookResult = ReturnType<typeof useOrganizationAttributesLazyQuery>;
export type OrganizationAttributesQueryResult = Apollo.QueryResult<OrganizationAttributesQuery, OrganizationAttributesQueryVariables>;
export const EmployeeAttributeNameCreateDocument = gql`
    mutation EmployeeAttributeNameCreate($input: EmployeeAttributeNameCreateInput!) {
  employeeAttributeNameCreate(input: $input) {
    ...AttributeFragment
  }
}
    ${AttributeFragmentFragmentDoc}`;
export type EmployeeAttributeNameCreateMutationFn = Apollo.MutationFunction<EmployeeAttributeNameCreateMutation, EmployeeAttributeNameCreateMutationVariables>;

/**
 * __useEmployeeAttributeNameCreateMutation__
 *
 * To run a mutation, you first call `useEmployeeAttributeNameCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAttributeNameCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAttributeNameCreateMutation, { data, loading, error }] = useEmployeeAttributeNameCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeAttributeNameCreateMutation(baseOptions?: Apollo.MutationHookOptions<EmployeeAttributeNameCreateMutation, EmployeeAttributeNameCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmployeeAttributeNameCreateMutation, EmployeeAttributeNameCreateMutationVariables>(EmployeeAttributeNameCreateDocument, options);
      }
export type EmployeeAttributeNameCreateMutationHookResult = ReturnType<typeof useEmployeeAttributeNameCreateMutation>;
export type EmployeeAttributeNameCreateMutationResult = Apollo.MutationResult<EmployeeAttributeNameCreateMutation>;
export type EmployeeAttributeNameCreateMutationOptions = Apollo.BaseMutationOptions<EmployeeAttributeNameCreateMutation, EmployeeAttributeNameCreateMutationVariables>;
export const EmployeeAttributeNameUpdateDocument = gql`
    mutation EmployeeAttributeNameUpdate($input: EmployeeAttributeNameUpdateInput!) {
  employeeAttributeNameUpdate(input: $input) {
    ...AttributeFragment
  }
}
    ${AttributeFragmentFragmentDoc}`;
export type EmployeeAttributeNameUpdateMutationFn = Apollo.MutationFunction<EmployeeAttributeNameUpdateMutation, EmployeeAttributeNameUpdateMutationVariables>;

/**
 * __useEmployeeAttributeNameUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeAttributeNameUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAttributeNameUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAttributeNameUpdateMutation, { data, loading, error }] = useEmployeeAttributeNameUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeAttributeNameUpdateMutation(baseOptions?: Apollo.MutationHookOptions<EmployeeAttributeNameUpdateMutation, EmployeeAttributeNameUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmployeeAttributeNameUpdateMutation, EmployeeAttributeNameUpdateMutationVariables>(EmployeeAttributeNameUpdateDocument, options);
      }
export type EmployeeAttributeNameUpdateMutationHookResult = ReturnType<typeof useEmployeeAttributeNameUpdateMutation>;
export type EmployeeAttributeNameUpdateMutationResult = Apollo.MutationResult<EmployeeAttributeNameUpdateMutation>;
export type EmployeeAttributeNameUpdateMutationOptions = Apollo.BaseMutationOptions<EmployeeAttributeNameUpdateMutation, EmployeeAttributeNameUpdateMutationVariables>;
export const EmployeeAttributeNameDeleteDocument = gql`
    mutation EmployeeAttributeNameDelete($input: EmployeeAttributeNameDeleteInput!) {
  employeeAttributeNameDelete(input: $input) {
    id
  }
}
    `;
export type EmployeeAttributeNameDeleteMutationFn = Apollo.MutationFunction<EmployeeAttributeNameDeleteMutation, EmployeeAttributeNameDeleteMutationVariables>;

/**
 * __useEmployeeAttributeNameDeleteMutation__
 *
 * To run a mutation, you first call `useEmployeeAttributeNameDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAttributeNameDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAttributeNameDeleteMutation, { data, loading, error }] = useEmployeeAttributeNameDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeAttributeNameDeleteMutation(baseOptions?: Apollo.MutationHookOptions<EmployeeAttributeNameDeleteMutation, EmployeeAttributeNameDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmployeeAttributeNameDeleteMutation, EmployeeAttributeNameDeleteMutationVariables>(EmployeeAttributeNameDeleteDocument, options);
      }
export type EmployeeAttributeNameDeleteMutationHookResult = ReturnType<typeof useEmployeeAttributeNameDeleteMutation>;
export type EmployeeAttributeNameDeleteMutationResult = Apollo.MutationResult<EmployeeAttributeNameDeleteMutation>;
export type EmployeeAttributeNameDeleteMutationOptions = Apollo.BaseMutationOptions<EmployeeAttributeNameDeleteMutation, EmployeeAttributeNameDeleteMutationVariables>;
export const EmployeeAttributeValueCreateDocument = gql`
    mutation EmployeeAttributeValueCreate($input: EmployeeAttributeValueCreateInput!) {
  employeeAttributeValueCreate(input: $input) {
    ...ValueFragment
  }
}
    ${ValueFragmentFragmentDoc}`;
export type EmployeeAttributeValueCreateMutationFn = Apollo.MutationFunction<EmployeeAttributeValueCreateMutation, EmployeeAttributeValueCreateMutationVariables>;

/**
 * __useEmployeeAttributeValueCreateMutation__
 *
 * To run a mutation, you first call `useEmployeeAttributeValueCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAttributeValueCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAttributeValueCreateMutation, { data, loading, error }] = useEmployeeAttributeValueCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeAttributeValueCreateMutation(baseOptions?: Apollo.MutationHookOptions<EmployeeAttributeValueCreateMutation, EmployeeAttributeValueCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmployeeAttributeValueCreateMutation, EmployeeAttributeValueCreateMutationVariables>(EmployeeAttributeValueCreateDocument, options);
      }
export type EmployeeAttributeValueCreateMutationHookResult = ReturnType<typeof useEmployeeAttributeValueCreateMutation>;
export type EmployeeAttributeValueCreateMutationResult = Apollo.MutationResult<EmployeeAttributeValueCreateMutation>;
export type EmployeeAttributeValueCreateMutationOptions = Apollo.BaseMutationOptions<EmployeeAttributeValueCreateMutation, EmployeeAttributeValueCreateMutationVariables>;
export const EmployeeAttributeValueUpdateDocument = gql`
    mutation EmployeeAttributeValueUpdate($input: EmployeeAttributeValueUpdateInput!) {
  employeeAttributeValueUpdate(input: $input) {
    ...ValueFragment
    name {
      ...AttributeFragmentValues
    }
  }
}
    ${ValueFragmentFragmentDoc}
${AttributeFragmentValuesFragmentDoc}`;
export type EmployeeAttributeValueUpdateMutationFn = Apollo.MutationFunction<EmployeeAttributeValueUpdateMutation, EmployeeAttributeValueUpdateMutationVariables>;

/**
 * __useEmployeeAttributeValueUpdateMutation__
 *
 * To run a mutation, you first call `useEmployeeAttributeValueUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAttributeValueUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAttributeValueUpdateMutation, { data, loading, error }] = useEmployeeAttributeValueUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeAttributeValueUpdateMutation(baseOptions?: Apollo.MutationHookOptions<EmployeeAttributeValueUpdateMutation, EmployeeAttributeValueUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmployeeAttributeValueUpdateMutation, EmployeeAttributeValueUpdateMutationVariables>(EmployeeAttributeValueUpdateDocument, options);
      }
export type EmployeeAttributeValueUpdateMutationHookResult = ReturnType<typeof useEmployeeAttributeValueUpdateMutation>;
export type EmployeeAttributeValueUpdateMutationResult = Apollo.MutationResult<EmployeeAttributeValueUpdateMutation>;
export type EmployeeAttributeValueUpdateMutationOptions = Apollo.BaseMutationOptions<EmployeeAttributeValueUpdateMutation, EmployeeAttributeValueUpdateMutationVariables>;
export const AttributeValueEmployeesUpsertDocument = gql`
    mutation AttributeValueEmployeesUpsert($input: AttributeValueEmployeesInput!) {
  attributeValueEmployeesUpsert(input: $input) {
    employee {
      ...EmployeeIdFragment
    }
  }
}
    ${EmployeeIdFragmentFragmentDoc}`;
export type AttributeValueEmployeesUpsertMutationFn = Apollo.MutationFunction<AttributeValueEmployeesUpsertMutation, AttributeValueEmployeesUpsertMutationVariables>;

/**
 * __useAttributeValueEmployeesUpsertMutation__
 *
 * To run a mutation, you first call `useAttributeValueEmployeesUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttributeValueEmployeesUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attributeValueEmployeesUpsertMutation, { data, loading, error }] = useAttributeValueEmployeesUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttributeValueEmployeesUpsertMutation(baseOptions?: Apollo.MutationHookOptions<AttributeValueEmployeesUpsertMutation, AttributeValueEmployeesUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AttributeValueEmployeesUpsertMutation, AttributeValueEmployeesUpsertMutationVariables>(AttributeValueEmployeesUpsertDocument, options);
      }
export type AttributeValueEmployeesUpsertMutationHookResult = ReturnType<typeof useAttributeValueEmployeesUpsertMutation>;
export type AttributeValueEmployeesUpsertMutationResult = Apollo.MutationResult<AttributeValueEmployeesUpsertMutation>;
export type AttributeValueEmployeesUpsertMutationOptions = Apollo.BaseMutationOptions<AttributeValueEmployeesUpsertMutation, AttributeValueEmployeesUpsertMutationVariables>;
export const EmployeeAttributeValueDeleteDocument = gql`
    mutation EmployeeAttributeValueDelete($input: EmployeeAttributeValueDeleteInput!) {
  employeeAttributeValueDelete(input: $input) {
    id
  }
}
    `;
export type EmployeeAttributeValueDeleteMutationFn = Apollo.MutationFunction<EmployeeAttributeValueDeleteMutation, EmployeeAttributeValueDeleteMutationVariables>;

/**
 * __useEmployeeAttributeValueDeleteMutation__
 *
 * To run a mutation, you first call `useEmployeeAttributeValueDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeAttributeValueDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeAttributeValueDeleteMutation, { data, loading, error }] = useEmployeeAttributeValueDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeAttributeValueDeleteMutation(baseOptions?: Apollo.MutationHookOptions<EmployeeAttributeValueDeleteMutation, EmployeeAttributeValueDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmployeeAttributeValueDeleteMutation, EmployeeAttributeValueDeleteMutationVariables>(EmployeeAttributeValueDeleteDocument, options);
      }
export type EmployeeAttributeValueDeleteMutationHookResult = ReturnType<typeof useEmployeeAttributeValueDeleteMutation>;
export type EmployeeAttributeValueDeleteMutationResult = Apollo.MutationResult<EmployeeAttributeValueDeleteMutation>;
export type EmployeeAttributeValueDeleteMutationOptions = Apollo.BaseMutationOptions<EmployeeAttributeValueDeleteMutation, EmployeeAttributeValueDeleteMutationVariables>;
export const MemberRoleSelectUpdateRoleDocument = gql`
    mutation MemberRoleSelectUpdateRole($userId: uuid!, $role: UserRole!) {
  userUpdate(input: {userId: $userId, set: {role: $role}}) {
    userInfo {
      userId
      role
    }
  }
}
    `;
export type MemberRoleSelectUpdateRoleMutationFn = Apollo.MutationFunction<MemberRoleSelectUpdateRoleMutation, MemberRoleSelectUpdateRoleMutationVariables>;

/**
 * __useMemberRoleSelectUpdateRoleMutation__
 *
 * To run a mutation, you first call `useMemberRoleSelectUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMemberRoleSelectUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [memberRoleSelectUpdateRoleMutation, { data, loading, error }] = useMemberRoleSelectUpdateRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useMemberRoleSelectUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<MemberRoleSelectUpdateRoleMutation, MemberRoleSelectUpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MemberRoleSelectUpdateRoleMutation, MemberRoleSelectUpdateRoleMutationVariables>(MemberRoleSelectUpdateRoleDocument, options);
      }
export type MemberRoleSelectUpdateRoleMutationHookResult = ReturnType<typeof useMemberRoleSelectUpdateRoleMutation>;
export type MemberRoleSelectUpdateRoleMutationResult = Apollo.MutationResult<MemberRoleSelectUpdateRoleMutation>;
export type MemberRoleSelectUpdateRoleMutationOptions = Apollo.BaseMutationOptions<MemberRoleSelectUpdateRoleMutation, MemberRoleSelectUpdateRoleMutationVariables>;
export const CompanyHolidayCreateDocument = gql`
    mutation CompanyHolidayCreate($input: CompanyHolidayCreateInput!) {
  companyHolidayCreate(input: $input) {
    companyHoliday {
      ...CompanyHolidayModal_companyHoliday
    }
  }
}
    ${CompanyHolidayModal_CompanyHolidayFragmentDoc}`;
export type CompanyHolidayCreateMutationFn = Apollo.MutationFunction<CompanyHolidayCreateMutation, CompanyHolidayCreateMutationVariables>;

/**
 * __useCompanyHolidayCreateMutation__
 *
 * To run a mutation, you first call `useCompanyHolidayCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyHolidayCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyHolidayCreateMutation, { data, loading, error }] = useCompanyHolidayCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyHolidayCreateMutation(baseOptions?: Apollo.MutationHookOptions<CompanyHolidayCreateMutation, CompanyHolidayCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyHolidayCreateMutation, CompanyHolidayCreateMutationVariables>(CompanyHolidayCreateDocument, options);
      }
export type CompanyHolidayCreateMutationHookResult = ReturnType<typeof useCompanyHolidayCreateMutation>;
export type CompanyHolidayCreateMutationResult = Apollo.MutationResult<CompanyHolidayCreateMutation>;
export type CompanyHolidayCreateMutationOptions = Apollo.BaseMutationOptions<CompanyHolidayCreateMutation, CompanyHolidayCreateMutationVariables>;
export const CompanyHolidayUpdateDocument = gql`
    mutation CompanyHolidayUpdate($input: CompanyHolidayUpdateInput!) {
  companyHolidayUpdate(input: $input) {
    companyHoliday {
      ...CompanyHolidayModal_companyHoliday
    }
  }
}
    ${CompanyHolidayModal_CompanyHolidayFragmentDoc}`;
export type CompanyHolidayUpdateMutationFn = Apollo.MutationFunction<CompanyHolidayUpdateMutation, CompanyHolidayUpdateMutationVariables>;

/**
 * __useCompanyHolidayUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyHolidayUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyHolidayUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyHolidayUpdateMutation, { data, loading, error }] = useCompanyHolidayUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyHolidayUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CompanyHolidayUpdateMutation, CompanyHolidayUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyHolidayUpdateMutation, CompanyHolidayUpdateMutationVariables>(CompanyHolidayUpdateDocument, options);
      }
export type CompanyHolidayUpdateMutationHookResult = ReturnType<typeof useCompanyHolidayUpdateMutation>;
export type CompanyHolidayUpdateMutationResult = Apollo.MutationResult<CompanyHolidayUpdateMutation>;
export type CompanyHolidayUpdateMutationOptions = Apollo.BaseMutationOptions<CompanyHolidayUpdateMutation, CompanyHolidayUpdateMutationVariables>;
export const CompanyHolidaysDocument = gql`
    query CompanyHolidays($input: CompanyHolidaysInput!) {
  companyHolidays(input: $input) {
    items {
      ...CompanyHoliday_companyHoliday
    }
  }
}
    ${CompanyHoliday_CompanyHolidayFragmentDoc}`;

/**
 * __useCompanyHolidaysQuery__
 *
 * To run a query within a React component, call `useCompanyHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyHolidaysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyHolidaysQuery(baseOptions: Apollo.QueryHookOptions<CompanyHolidaysQuery, CompanyHolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyHolidaysQuery, CompanyHolidaysQueryVariables>(CompanyHolidaysDocument, options);
      }
export function useCompanyHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyHolidaysQuery, CompanyHolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyHolidaysQuery, CompanyHolidaysQueryVariables>(CompanyHolidaysDocument, options);
        }
export type CompanyHolidaysQueryHookResult = ReturnType<typeof useCompanyHolidaysQuery>;
export type CompanyHolidaysLazyQueryHookResult = ReturnType<typeof useCompanyHolidaysLazyQuery>;
export type CompanyHolidaysQueryResult = Apollo.QueryResult<CompanyHolidaysQuery, CompanyHolidaysQueryVariables>;
export const CompanyHolidayDeleteDocument = gql`
    mutation CompanyHolidayDelete($input: CompanyHolidayDeleteInput!) {
  companyHolidayDelete(input: $input) {
    result
  }
}
    `;
export type CompanyHolidayDeleteMutationFn = Apollo.MutationFunction<CompanyHolidayDeleteMutation, CompanyHolidayDeleteMutationVariables>;

/**
 * __useCompanyHolidayDeleteMutation__
 *
 * To run a mutation, you first call `useCompanyHolidayDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyHolidayDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyHolidayDeleteMutation, { data, loading, error }] = useCompanyHolidayDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyHolidayDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CompanyHolidayDeleteMutation, CompanyHolidayDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyHolidayDeleteMutation, CompanyHolidayDeleteMutationVariables>(CompanyHolidayDeleteDocument, options);
      }
export type CompanyHolidayDeleteMutationHookResult = ReturnType<typeof useCompanyHolidayDeleteMutation>;
export type CompanyHolidayDeleteMutationResult = Apollo.MutationResult<CompanyHolidayDeleteMutation>;
export type CompanyHolidayDeleteMutationOptions = Apollo.BaseMutationOptions<CompanyHolidayDeleteMutation, CompanyHolidayDeleteMutationVariables>;
export const InterviewMeetingLocationSettingOrgPrefDocument = gql`
    query InterviewMeetingLocationSettingOrgPref($prefName: String!) {
  thisOrg {
    id
    orgPref(prefName: $prefName) {
      id
      orgId
      prefName
      jsonPref {
        ... on InterviewMeetingLocationSettingPref {
          ...InterviewMeetingLocationModal_interviewMeetingLocationSettingPref
          hostEmployee {
            id
            fullName
            slackImageUrl
            email
          }
        }
      }
    }
  }
}
    ${InterviewMeetingLocationModal_InterviewMeetingLocationSettingPrefFragmentDoc}`;

/**
 * __useInterviewMeetingLocationSettingOrgPrefQuery__
 *
 * To run a query within a React component, call `useInterviewMeetingLocationSettingOrgPrefQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewMeetingLocationSettingOrgPrefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewMeetingLocationSettingOrgPrefQuery({
 *   variables: {
 *      prefName: // value for 'prefName'
 *   },
 * });
 */
export function useInterviewMeetingLocationSettingOrgPrefQuery(baseOptions: Apollo.QueryHookOptions<InterviewMeetingLocationSettingOrgPrefQuery, InterviewMeetingLocationSettingOrgPrefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewMeetingLocationSettingOrgPrefQuery, InterviewMeetingLocationSettingOrgPrefQueryVariables>(InterviewMeetingLocationSettingOrgPrefDocument, options);
      }
export function useInterviewMeetingLocationSettingOrgPrefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewMeetingLocationSettingOrgPrefQuery, InterviewMeetingLocationSettingOrgPrefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewMeetingLocationSettingOrgPrefQuery, InterviewMeetingLocationSettingOrgPrefQueryVariables>(InterviewMeetingLocationSettingOrgPrefDocument, options);
        }
export type InterviewMeetingLocationSettingOrgPrefQueryHookResult = ReturnType<typeof useInterviewMeetingLocationSettingOrgPrefQuery>;
export type InterviewMeetingLocationSettingOrgPrefLazyQueryHookResult = ReturnType<typeof useInterviewMeetingLocationSettingOrgPrefLazyQuery>;
export type InterviewMeetingLocationSettingOrgPrefQueryResult = Apollo.QueryResult<InterviewMeetingLocationSettingOrgPrefQuery, InterviewMeetingLocationSettingOrgPrefQueryVariables>;
export const UpdateInterviewMeetingLocationSettingOrgPrefDocument = gql`
    mutation updateInterviewMeetingLocationSettingOrgPref($prefName: String!, $json: jsonb) {
  orgPrefUpdate(input: {prefName: $prefName, set: {json: $json}}) {
    orgPref {
      id
      orgId
      prefName
      jsonPref {
        ... on InterviewMeetingLocationSettingPref {
          ...InterviewMeetingLocationModal_interviewMeetingLocationSettingPref
          hostEmployee {
            id
            fullName
            slackImageUrl
            email
          }
        }
      }
    }
  }
}
    ${InterviewMeetingLocationModal_InterviewMeetingLocationSettingPrefFragmentDoc}`;
export type UpdateInterviewMeetingLocationSettingOrgPrefMutationFn = Apollo.MutationFunction<UpdateInterviewMeetingLocationSettingOrgPrefMutation, UpdateInterviewMeetingLocationSettingOrgPrefMutationVariables>;

/**
 * __useUpdateInterviewMeetingLocationSettingOrgPrefMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewMeetingLocationSettingOrgPrefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewMeetingLocationSettingOrgPrefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewMeetingLocationSettingOrgPrefMutation, { data, loading, error }] = useUpdateInterviewMeetingLocationSettingOrgPrefMutation({
 *   variables: {
 *      prefName: // value for 'prefName'
 *      json: // value for 'json'
 *   },
 * });
 */
export function useUpdateInterviewMeetingLocationSettingOrgPrefMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewMeetingLocationSettingOrgPrefMutation, UpdateInterviewMeetingLocationSettingOrgPrefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewMeetingLocationSettingOrgPrefMutation, UpdateInterviewMeetingLocationSettingOrgPrefMutationVariables>(UpdateInterviewMeetingLocationSettingOrgPrefDocument, options);
      }
export type UpdateInterviewMeetingLocationSettingOrgPrefMutationHookResult = ReturnType<typeof useUpdateInterviewMeetingLocationSettingOrgPrefMutation>;
export type UpdateInterviewMeetingLocationSettingOrgPrefMutationResult = Apollo.MutationResult<UpdateInterviewMeetingLocationSettingOrgPrefMutation>;
export type UpdateInterviewMeetingLocationSettingOrgPrefMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewMeetingLocationSettingOrgPrefMutation, UpdateInterviewMeetingLocationSettingOrgPrefMutationVariables>;
export const RescheduleReasonCreateDocument = gql`
    mutation RescheduleReasonCreate($input: RescheduleReasonCreateInput!) {
  rescheduleReasonCreate(input: $input) {
    rescheduleReason {
      id
      reason
    }
  }
}
    `;
export type RescheduleReasonCreateMutationFn = Apollo.MutationFunction<RescheduleReasonCreateMutation, RescheduleReasonCreateMutationVariables>;

/**
 * __useRescheduleReasonCreateMutation__
 *
 * To run a mutation, you first call `useRescheduleReasonCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleReasonCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleReasonCreateMutation, { data, loading, error }] = useRescheduleReasonCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleReasonCreateMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleReasonCreateMutation, RescheduleReasonCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleReasonCreateMutation, RescheduleReasonCreateMutationVariables>(RescheduleReasonCreateDocument, options);
      }
export type RescheduleReasonCreateMutationHookResult = ReturnType<typeof useRescheduleReasonCreateMutation>;
export type RescheduleReasonCreateMutationResult = Apollo.MutationResult<RescheduleReasonCreateMutation>;
export type RescheduleReasonCreateMutationOptions = Apollo.BaseMutationOptions<RescheduleReasonCreateMutation, RescheduleReasonCreateMutationVariables>;
export const RescheduleReasonUpdateDocument = gql`
    mutation RescheduleReasonUpdate($input: RescheduleReasonUpdateInput!) {
  rescheduleReasonUpdate(input: $input) {
    rescheduleReason {
      ...EditRescheduleReasonModal_rescheduleReason
    }
  }
}
    ${EditRescheduleReasonModal_RescheduleReasonFragmentDoc}`;
export type RescheduleReasonUpdateMutationFn = Apollo.MutationFunction<RescheduleReasonUpdateMutation, RescheduleReasonUpdateMutationVariables>;

/**
 * __useRescheduleReasonUpdateMutation__
 *
 * To run a mutation, you first call `useRescheduleReasonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleReasonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleReasonUpdateMutation, { data, loading, error }] = useRescheduleReasonUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleReasonUpdateMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleReasonUpdateMutation, RescheduleReasonUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleReasonUpdateMutation, RescheduleReasonUpdateMutationVariables>(RescheduleReasonUpdateDocument, options);
      }
export type RescheduleReasonUpdateMutationHookResult = ReturnType<typeof useRescheduleReasonUpdateMutation>;
export type RescheduleReasonUpdateMutationResult = Apollo.MutationResult<RescheduleReasonUpdateMutation>;
export type RescheduleReasonUpdateMutationOptions = Apollo.BaseMutationOptions<RescheduleReasonUpdateMutation, RescheduleReasonUpdateMutationVariables>;
export const RescheduleReasonsListDocument = gql`
    query RescheduleReasonsList($input: RescheduleReasonsInput!) {
  rescheduleReasons(input: $input) {
    items {
      ...RescheduleReasonsList_rescheduleReason
    }
    nextCursor
  }
}
    ${RescheduleReasonsList_RescheduleReasonFragmentDoc}`;

/**
 * __useRescheduleReasonsListQuery__
 *
 * To run a query within a React component, call `useRescheduleReasonsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRescheduleReasonsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRescheduleReasonsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleReasonsListQuery(baseOptions: Apollo.QueryHookOptions<RescheduleReasonsListQuery, RescheduleReasonsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RescheduleReasonsListQuery, RescheduleReasonsListQueryVariables>(RescheduleReasonsListDocument, options);
      }
export function useRescheduleReasonsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RescheduleReasonsListQuery, RescheduleReasonsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RescheduleReasonsListQuery, RescheduleReasonsListQueryVariables>(RescheduleReasonsListDocument, options);
        }
export type RescheduleReasonsListQueryHookResult = ReturnType<typeof useRescheduleReasonsListQuery>;
export type RescheduleReasonsListLazyQueryHookResult = ReturnType<typeof useRescheduleReasonsListLazyQuery>;
export type RescheduleReasonsListQueryResult = Apollo.QueryResult<RescheduleReasonsListQuery, RescheduleReasonsListQueryVariables>;
export const UpdateOrgNameDocument = gql`
    mutation UpdateOrgName($input: OrgUpdateInput!) {
  orgUpdate(input: $input) {
    ...EditOrgNameDialog_org
  }
}
    ${EditOrgNameDialog_OrgFragmentDoc}`;
export type UpdateOrgNameMutationFn = Apollo.MutationFunction<UpdateOrgNameMutation, UpdateOrgNameMutationVariables>;

/**
 * __useUpdateOrgNameMutation__
 *
 * To run a mutation, you first call `useUpdateOrgNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgNameMutation, { data, loading, error }] = useUpdateOrgNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgNameMutation, UpdateOrgNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgNameMutation, UpdateOrgNameMutationVariables>(UpdateOrgNameDocument, options);
      }
export type UpdateOrgNameMutationHookResult = ReturnType<typeof useUpdateOrgNameMutation>;
export type UpdateOrgNameMutationResult = Apollo.MutationResult<UpdateOrgNameMutation>;
export type UpdateOrgNameMutationOptions = Apollo.BaseMutationOptions<UpdateOrgNameMutation, UpdateOrgNameMutationVariables>;
export const PortalCreateOrgSlugDocument = gql`
    mutation PortalCreateOrgSlug($input: PortalSlugCreateInput!) {
  portalSlugCreate(input: $input) {
    orgId
  }
}
    `;
export type PortalCreateOrgSlugMutationFn = Apollo.MutationFunction<PortalCreateOrgSlugMutation, PortalCreateOrgSlugMutationVariables>;

/**
 * __usePortalCreateOrgSlugMutation__
 *
 * To run a mutation, you first call `usePortalCreateOrgSlugMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePortalCreateOrgSlugMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [portalCreateOrgSlugMutation, { data, loading, error }] = usePortalCreateOrgSlugMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePortalCreateOrgSlugMutation(baseOptions?: Apollo.MutationHookOptions<PortalCreateOrgSlugMutation, PortalCreateOrgSlugMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PortalCreateOrgSlugMutation, PortalCreateOrgSlugMutationVariables>(PortalCreateOrgSlugDocument, options);
      }
export type PortalCreateOrgSlugMutationHookResult = ReturnType<typeof usePortalCreateOrgSlugMutation>;
export type PortalCreateOrgSlugMutationResult = Apollo.MutationResult<PortalCreateOrgSlugMutation>;
export type PortalCreateOrgSlugMutationOptions = Apollo.BaseMutationOptions<PortalCreateOrgSlugMutation, PortalCreateOrgSlugMutationVariables>;
export const OrgNameAndSlugDocument = gql`
    query OrgNameAndSlug {
  thisOrg {
    id
    name
    ...EditOrgNameDialog_org
    slugs {
      slug
      isPrimary
    }
  }
}
    ${EditOrgNameDialog_OrgFragmentDoc}`;

/**
 * __useOrgNameAndSlugQuery__
 *
 * To run a query within a React component, call `useOrgNameAndSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgNameAndSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgNameAndSlugQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgNameAndSlugQuery(baseOptions?: Apollo.QueryHookOptions<OrgNameAndSlugQuery, OrgNameAndSlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgNameAndSlugQuery, OrgNameAndSlugQueryVariables>(OrgNameAndSlugDocument, options);
      }
export function useOrgNameAndSlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgNameAndSlugQuery, OrgNameAndSlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgNameAndSlugQuery, OrgNameAndSlugQueryVariables>(OrgNameAndSlugDocument, options);
        }
export type OrgNameAndSlugQueryHookResult = ReturnType<typeof useOrgNameAndSlugQuery>;
export type OrgNameAndSlugLazyQueryHookResult = ReturnType<typeof useOrgNameAndSlugLazyQuery>;
export type OrgNameAndSlugQueryResult = Apollo.QueryResult<OrgNameAndSlugQuery, OrgNameAndSlugQueryVariables>;
export const TaskTagCreateDocument = gql`
    mutation TaskTagCreate($input: TaskTagCreateInput!) {
  taskTagCreate(input: $input) {
    taskTag {
      id
      name
    }
  }
}
    `;
export type TaskTagCreateMutationFn = Apollo.MutationFunction<TaskTagCreateMutation, TaskTagCreateMutationVariables>;

/**
 * __useTaskTagCreateMutation__
 *
 * To run a mutation, you first call `useTaskTagCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskTagCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskTagCreateMutation, { data, loading, error }] = useTaskTagCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskTagCreateMutation(baseOptions?: Apollo.MutationHookOptions<TaskTagCreateMutation, TaskTagCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskTagCreateMutation, TaskTagCreateMutationVariables>(TaskTagCreateDocument, options);
      }
export type TaskTagCreateMutationHookResult = ReturnType<typeof useTaskTagCreateMutation>;
export type TaskTagCreateMutationResult = Apollo.MutationResult<TaskTagCreateMutation>;
export type TaskTagCreateMutationOptions = Apollo.BaseMutationOptions<TaskTagCreateMutation, TaskTagCreateMutationVariables>;
export const TaskTagDeleteDocument = gql`
    mutation TaskTagDelete($input: TaskTagDeleteInput!) {
  taskTagDelete(input: $input) {
    ok
  }
}
    `;
export type TaskTagDeleteMutationFn = Apollo.MutationFunction<TaskTagDeleteMutation, TaskTagDeleteMutationVariables>;

/**
 * __useTaskTagDeleteMutation__
 *
 * To run a mutation, you first call `useTaskTagDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskTagDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskTagDeleteMutation, { data, loading, error }] = useTaskTagDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskTagDeleteMutation(baseOptions?: Apollo.MutationHookOptions<TaskTagDeleteMutation, TaskTagDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskTagDeleteMutation, TaskTagDeleteMutationVariables>(TaskTagDeleteDocument, options);
      }
export type TaskTagDeleteMutationHookResult = ReturnType<typeof useTaskTagDeleteMutation>;
export type TaskTagDeleteMutationResult = Apollo.MutationResult<TaskTagDeleteMutation>;
export type TaskTagDeleteMutationOptions = Apollo.BaseMutationOptions<TaskTagDeleteMutation, TaskTagDeleteMutationVariables>;
export const TaskTagUpdateDocument = gql`
    mutation TaskTagUpdate($input: TaskTagUpdateInput!) {
  taskTagUpdate(input: $input) {
    taskTag {
      ...EditTagModal_taskTag
    }
  }
}
    ${EditTagModal_TaskTagFragmentDoc}`;
export type TaskTagUpdateMutationFn = Apollo.MutationFunction<TaskTagUpdateMutation, TaskTagUpdateMutationVariables>;

/**
 * __useTaskTagUpdateMutation__
 *
 * To run a mutation, you first call `useTaskTagUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskTagUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskTagUpdateMutation, { data, loading, error }] = useTaskTagUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskTagUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TaskTagUpdateMutation, TaskTagUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskTagUpdateMutation, TaskTagUpdateMutationVariables>(TaskTagUpdateDocument, options);
      }
export type TaskTagUpdateMutationHookResult = ReturnType<typeof useTaskTagUpdateMutation>;
export type TaskTagUpdateMutationResult = Apollo.MutationResult<TaskTagUpdateMutation>;
export type TaskTagUpdateMutationOptions = Apollo.BaseMutationOptions<TaskTagUpdateMutation, TaskTagUpdateMutationVariables>;
export const TaskTagListDocument = gql`
    query TaskTagList($input: TaskTagsInput!) {
  taskTags(input: $input) {
    items {
      ...TaskTagList_taskTag
    }
    nextCursor
  }
}
    ${TaskTagList_TaskTagFragmentDoc}`;

/**
 * __useTaskTagListQuery__
 *
 * To run a query within a React component, call `useTaskTagListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTagListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTagListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskTagListQuery(baseOptions: Apollo.QueryHookOptions<TaskTagListQuery, TaskTagListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskTagListQuery, TaskTagListQueryVariables>(TaskTagListDocument, options);
      }
export function useTaskTagListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskTagListQuery, TaskTagListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskTagListQuery, TaskTagListQueryVariables>(TaskTagListDocument, options);
        }
export type TaskTagListQueryHookResult = ReturnType<typeof useTaskTagListQuery>;
export type TaskTagListLazyQueryHookResult = ReturnType<typeof useTaskTagListLazyQuery>;
export type TaskTagListQueryResult = Apollo.QueryResult<TaskTagListQuery, TaskTagListQueryVariables>;
export const DeleteTaskQueueDocument = gql`
    mutation deleteTaskQueue($input: TaskQueueDeleteInput!) {
  taskQueueDelete(input: $input) {
    result
  }
}
    `;
export type DeleteTaskQueueMutationFn = Apollo.MutationFunction<DeleteTaskQueueMutation, DeleteTaskQueueMutationVariables>;

/**
 * __useDeleteTaskQueueMutation__
 *
 * To run a mutation, you first call `useDeleteTaskQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskQueueMutation, { data, loading, error }] = useDeleteTaskQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaskQueueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskQueueMutation, DeleteTaskQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskQueueMutation, DeleteTaskQueueMutationVariables>(DeleteTaskQueueDocument, options);
      }
export type DeleteTaskQueueMutationHookResult = ReturnType<typeof useDeleteTaskQueueMutation>;
export type DeleteTaskQueueMutationResult = Apollo.MutationResult<DeleteTaskQueueMutation>;
export type DeleteTaskQueueMutationOptions = Apollo.BaseMutationOptions<DeleteTaskQueueMutation, DeleteTaskQueueMutationVariables>;
export const CreateTaskQueueDocument = gql`
    mutation CreateTaskQueue($input: TaskQueueCreateInput!) {
  taskQueueCreate(input: $input) {
    taskQueue {
      id
      ...QueueRow_taskQueue
    }
  }
}
    ${QueueRow_TaskQueueFragmentDoc}`;
export type CreateTaskQueueMutationFn = Apollo.MutationFunction<CreateTaskQueueMutation, CreateTaskQueueMutationVariables>;

/**
 * __useCreateTaskQueueMutation__
 *
 * To run a mutation, you first call `useCreateTaskQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskQueueMutation, { data, loading, error }] = useCreateTaskQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskQueueMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskQueueMutation, CreateTaskQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskQueueMutation, CreateTaskQueueMutationVariables>(CreateTaskQueueDocument, options);
      }
export type CreateTaskQueueMutationHookResult = ReturnType<typeof useCreateTaskQueueMutation>;
export type CreateTaskQueueMutationResult = Apollo.MutationResult<CreateTaskQueueMutation>;
export type CreateTaskQueueMutationOptions = Apollo.BaseMutationOptions<CreateTaskQueueMutation, CreateTaskQueueMutationVariables>;
export const EditTaskQueueDocument = gql`
    mutation EditTaskQueue($input: TaskQueueUpdateInput!) {
  taskQueueUpdate(input: $input) {
    taskQueue {
      id
      ...QueueRow_taskQueue
    }
  }
}
    ${QueueRow_TaskQueueFragmentDoc}`;
export type EditTaskQueueMutationFn = Apollo.MutationFunction<EditTaskQueueMutation, EditTaskQueueMutationVariables>;

/**
 * __useEditTaskQueueMutation__
 *
 * To run a mutation, you first call `useEditTaskQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskQueueMutation, { data, loading, error }] = useEditTaskQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTaskQueueMutation(baseOptions?: Apollo.MutationHookOptions<EditTaskQueueMutation, EditTaskQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTaskQueueMutation, EditTaskQueueMutationVariables>(EditTaskQueueDocument, options);
      }
export type EditTaskQueueMutationHookResult = ReturnType<typeof useEditTaskQueueMutation>;
export type EditTaskQueueMutationResult = Apollo.MutationResult<EditTaskQueueMutation>;
export type EditTaskQueueMutationOptions = Apollo.BaseMutationOptions<EditTaskQueueMutation, EditTaskQueueMutationVariables>;
export const SubscribersListQueueModalDocument = gql`
    query SubscribersListQueueModal($input: SubscribersInput!) {
  subscribers(input: $input) {
    items {
      id
      ...QueueDialogContent_queueMembers
    }
  }
}
    ${QueueDialogContent_QueueMembersFragmentDoc}`;

/**
 * __useSubscribersListQueueModalQuery__
 *
 * To run a query within a React component, call `useSubscribersListQueueModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribersListQueueModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribersListQueueModalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribersListQueueModalQuery(baseOptions: Apollo.QueryHookOptions<SubscribersListQueueModalQuery, SubscribersListQueueModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscribersListQueueModalQuery, SubscribersListQueueModalQueryVariables>(SubscribersListQueueModalDocument, options);
      }
export function useSubscribersListQueueModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscribersListQueueModalQuery, SubscribersListQueueModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscribersListQueueModalQuery, SubscribersListQueueModalQueryVariables>(SubscribersListQueueModalDocument, options);
        }
export type SubscribersListQueueModalQueryHookResult = ReturnType<typeof useSubscribersListQueueModalQuery>;
export type SubscribersListQueueModalLazyQueryHookResult = ReturnType<typeof useSubscribersListQueueModalLazyQuery>;
export type SubscribersListQueueModalQueryResult = Apollo.QueryResult<SubscribersListQueueModalQuery, SubscribersListQueueModalQueryVariables>;
export const TaskQueuesDocument = gql`
    query TaskQueues($input: TaskQueueSearchInput!) {
  taskQueueSearch(input: $input) {
    items {
      ...QueueList_taskQueue
    }
  }
}
    ${QueueList_TaskQueueFragmentDoc}`;

/**
 * __useTaskQueuesQuery__
 *
 * To run a query within a React component, call `useTaskQueuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQueuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQueuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskQueuesQuery(baseOptions: Apollo.QueryHookOptions<TaskQueuesQuery, TaskQueuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQueuesQuery, TaskQueuesQueryVariables>(TaskQueuesDocument, options);
      }
export function useTaskQueuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQueuesQuery, TaskQueuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQueuesQuery, TaskQueuesQueryVariables>(TaskQueuesDocument, options);
        }
export type TaskQueuesQueryHookResult = ReturnType<typeof useTaskQueuesQuery>;
export type TaskQueuesLazyQueryHookResult = ReturnType<typeof useTaskQueuesLazyQuery>;
export type TaskQueuesQueryResult = Apollo.QueryResult<TaskQueuesQuery, TaskQueuesQueryVariables>;
export const DefaultTemplateForTypesDocument = gql`
    query DefaultTemplateForTypes($input: TemplatesInput!) {
  templates(input: $input) {
    items {
      ...TemplateFrag
    }
  }
}
    ${TemplateFragFragmentDoc}`;

/**
 * __useDefaultTemplateForTypesQuery__
 *
 * To run a query within a React component, call `useDefaultTemplateForTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultTemplateForTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultTemplateForTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDefaultTemplateForTypesQuery(baseOptions: Apollo.QueryHookOptions<DefaultTemplateForTypesQuery, DefaultTemplateForTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultTemplateForTypesQuery, DefaultTemplateForTypesQueryVariables>(DefaultTemplateForTypesDocument, options);
      }
export function useDefaultTemplateForTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultTemplateForTypesQuery, DefaultTemplateForTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultTemplateForTypesQuery, DefaultTemplateForTypesQueryVariables>(DefaultTemplateForTypesDocument, options);
        }
export type DefaultTemplateForTypesQueryHookResult = ReturnType<typeof useDefaultTemplateForTypesQuery>;
export type DefaultTemplateForTypesLazyQueryHookResult = ReturnType<typeof useDefaultTemplateForTypesLazyQuery>;
export type DefaultTemplateForTypesQueryResult = Apollo.QueryResult<DefaultTemplateForTypesQuery, DefaultTemplateForTypesQueryVariables>;
export const OrganizationTemplatesDocument = gql`
    query OrganizationTemplates($input: TemplatesInput!) {
  templates(input: $input) {
    items {
      ...TemplateFrag
    }
  }
}
    ${TemplateFragFragmentDoc}`;

/**
 * __useOrganizationTemplatesQuery__
 *
 * To run a query within a React component, call `useOrganizationTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTemplatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationTemplatesQuery(baseOptions: Apollo.QueryHookOptions<OrganizationTemplatesQuery, OrganizationTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationTemplatesQuery, OrganizationTemplatesQueryVariables>(OrganizationTemplatesDocument, options);
      }
export function useOrganizationTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationTemplatesQuery, OrganizationTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationTemplatesQuery, OrganizationTemplatesQueryVariables>(OrganizationTemplatesDocument, options);
        }
export type OrganizationTemplatesQueryHookResult = ReturnType<typeof useOrganizationTemplatesQuery>;
export type OrganizationTemplatesLazyQueryHookResult = ReturnType<typeof useOrganizationTemplatesLazyQuery>;
export type OrganizationTemplatesQueryResult = Apollo.QueryResult<OrganizationTemplatesQuery, OrganizationTemplatesQueryVariables>;
export const OrganizationTemplateCreateDocument = gql`
    mutation OrganizationTemplateCreate($input: TemplateCreateInput!) {
  templateCreate(input: $input) {
    template {
      ...TemplateFrag
      ...Attachments
    }
  }
}
    ${TemplateFragFragmentDoc}
${AttachmentsFragmentDoc}`;
export type OrganizationTemplateCreateMutationFn = Apollo.MutationFunction<OrganizationTemplateCreateMutation, OrganizationTemplateCreateMutationVariables>;

/**
 * __useOrganizationTemplateCreateMutation__
 *
 * To run a mutation, you first call `useOrganizationTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationTemplateCreateMutation, { data, loading, error }] = useOrganizationTemplateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationTemplateCreateMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationTemplateCreateMutation, OrganizationTemplateCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationTemplateCreateMutation, OrganizationTemplateCreateMutationVariables>(OrganizationTemplateCreateDocument, options);
      }
export type OrganizationTemplateCreateMutationHookResult = ReturnType<typeof useOrganizationTemplateCreateMutation>;
export type OrganizationTemplateCreateMutationResult = Apollo.MutationResult<OrganizationTemplateCreateMutation>;
export type OrganizationTemplateCreateMutationOptions = Apollo.BaseMutationOptions<OrganizationTemplateCreateMutation, OrganizationTemplateCreateMutationVariables>;
export const OrganizationTemplateUpdateDocument = gql`
    mutation OrganizationTemplateUpdate($input: TemplateUpdateInput!) {
  templateUpdate(input: $input) {
    template {
      ...TemplateFrag
      ...Attachments
    }
  }
}
    ${TemplateFragFragmentDoc}
${AttachmentsFragmentDoc}`;
export type OrganizationTemplateUpdateMutationFn = Apollo.MutationFunction<OrganizationTemplateUpdateMutation, OrganizationTemplateUpdateMutationVariables>;

/**
 * __useOrganizationTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useOrganizationTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationTemplateUpdateMutation, { data, loading, error }] = useOrganizationTemplateUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationTemplateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationTemplateUpdateMutation, OrganizationTemplateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationTemplateUpdateMutation, OrganizationTemplateUpdateMutationVariables>(OrganizationTemplateUpdateDocument, options);
      }
export type OrganizationTemplateUpdateMutationHookResult = ReturnType<typeof useOrganizationTemplateUpdateMutation>;
export type OrganizationTemplateUpdateMutationResult = Apollo.MutationResult<OrganizationTemplateUpdateMutation>;
export type OrganizationTemplateUpdateMutationOptions = Apollo.BaseMutationOptions<OrganizationTemplateUpdateMutation, OrganizationTemplateUpdateMutationVariables>;
export const OrganizationTemplateDeleteDocument = gql`
    mutation OrganizationTemplateDelete($id: uuid!) {
  templateDelete(id: $id) {
    template {
      id
    }
  }
}
    `;
export type OrganizationTemplateDeleteMutationFn = Apollo.MutationFunction<OrganizationTemplateDeleteMutation, OrganizationTemplateDeleteMutationVariables>;

/**
 * __useOrganizationTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useOrganizationTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationTemplateDeleteMutation, { data, loading, error }] = useOrganizationTemplateDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationTemplateDeleteMutation(baseOptions?: Apollo.MutationHookOptions<OrganizationTemplateDeleteMutation, OrganizationTemplateDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrganizationTemplateDeleteMutation, OrganizationTemplateDeleteMutationVariables>(OrganizationTemplateDeleteDocument, options);
      }
export type OrganizationTemplateDeleteMutationHookResult = ReturnType<typeof useOrganizationTemplateDeleteMutation>;
export type OrganizationTemplateDeleteMutationResult = Apollo.MutationResult<OrganizationTemplateDeleteMutation>;
export type OrganizationTemplateDeleteMutationOptions = Apollo.BaseMutationOptions<OrganizationTemplateDeleteMutation, OrganizationTemplateDeleteMutationVariables>;
export const TemplateAttachementsDocument = gql`
    query TemplateAttachements($id: uuid!) {
  template(id: $id) {
    id
    ...Attachments
  }
}
    ${AttachmentsFragmentDoc}`;

/**
 * __useTemplateAttachementsQuery__
 *
 * To run a query within a React component, call `useTemplateAttachementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateAttachementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateAttachementsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTemplateAttachementsQuery(baseOptions: Apollo.QueryHookOptions<TemplateAttachementsQuery, TemplateAttachementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateAttachementsQuery, TemplateAttachementsQueryVariables>(TemplateAttachementsDocument, options);
      }
export function useTemplateAttachementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateAttachementsQuery, TemplateAttachementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateAttachementsQuery, TemplateAttachementsQueryVariables>(TemplateAttachementsDocument, options);
        }
export type TemplateAttachementsQueryHookResult = ReturnType<typeof useTemplateAttachementsQuery>;
export type TemplateAttachementsLazyQueryHookResult = ReturnType<typeof useTemplateAttachementsLazyQuery>;
export type TemplateAttachementsQueryResult = Apollo.QueryResult<TemplateAttachementsQuery, TemplateAttachementsQueryVariables>;
export const CustomInterviewScheduleTokenPreviewDocument = gql`
    query CustomInterviewScheduleTokenPreview($input: CustomInterviewScheduleTokenPreviewInput!) {
  customInterviewScheduleTokenPreview(input: $input) {
    html
  }
}
    `;

/**
 * __useCustomInterviewScheduleTokenPreviewQuery__
 *
 * To run a query within a React component, call `useCustomInterviewScheduleTokenPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomInterviewScheduleTokenPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomInterviewScheduleTokenPreviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomInterviewScheduleTokenPreviewQuery(baseOptions: Apollo.QueryHookOptions<CustomInterviewScheduleTokenPreviewQuery, CustomInterviewScheduleTokenPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomInterviewScheduleTokenPreviewQuery, CustomInterviewScheduleTokenPreviewQueryVariables>(CustomInterviewScheduleTokenPreviewDocument, options);
      }
export function useCustomInterviewScheduleTokenPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomInterviewScheduleTokenPreviewQuery, CustomInterviewScheduleTokenPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomInterviewScheduleTokenPreviewQuery, CustomInterviewScheduleTokenPreviewQueryVariables>(CustomInterviewScheduleTokenPreviewDocument, options);
        }
export type CustomInterviewScheduleTokenPreviewQueryHookResult = ReturnType<typeof useCustomInterviewScheduleTokenPreviewQuery>;
export type CustomInterviewScheduleTokenPreviewLazyQueryHookResult = ReturnType<typeof useCustomInterviewScheduleTokenPreviewLazyQuery>;
export type CustomInterviewScheduleTokenPreviewQueryResult = Apollo.QueryResult<CustomInterviewScheduleTokenPreviewQuery, CustomInterviewScheduleTokenPreviewQueryVariables>;
export const DefaultCustomInterviewScheduleTokenDocument = gql`
    query DefaultCustomInterviewScheduleToken {
  defaultCustomInterviewScheduleToken {
    dateHeaderFormat
    breakDividerFormat
    interviewDetailWithTime
    codingLinkFormat
    candidatePhoneNumberFormat
    uniqueMeetingLinkFormat {
      zoomLinkFormat
      workspaceVideoLinkFormat
      customLinkFormat
    }
    singleMeetingLinkFormat {
      zoomLinkFormat
      workspaceVideoLinkFormat
      customLinkFormat
    }
  }
}
    `;

/**
 * __useDefaultCustomInterviewScheduleTokenQuery__
 *
 * To run a query within a React component, call `useDefaultCustomInterviewScheduleTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultCustomInterviewScheduleTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultCustomInterviewScheduleTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultCustomInterviewScheduleTokenQuery(baseOptions?: Apollo.QueryHookOptions<DefaultCustomInterviewScheduleTokenQuery, DefaultCustomInterviewScheduleTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultCustomInterviewScheduleTokenQuery, DefaultCustomInterviewScheduleTokenQueryVariables>(DefaultCustomInterviewScheduleTokenDocument, options);
      }
export function useDefaultCustomInterviewScheduleTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultCustomInterviewScheduleTokenQuery, DefaultCustomInterviewScheduleTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultCustomInterviewScheduleTokenQuery, DefaultCustomInterviewScheduleTokenQueryVariables>(DefaultCustomInterviewScheduleTokenDocument, options);
        }
export type DefaultCustomInterviewScheduleTokenQueryHookResult = ReturnType<typeof useDefaultCustomInterviewScheduleTokenQuery>;
export type DefaultCustomInterviewScheduleTokenLazyQueryHookResult = ReturnType<typeof useDefaultCustomInterviewScheduleTokenLazyQuery>;
export type DefaultCustomInterviewScheduleTokenQueryResult = Apollo.QueryResult<DefaultCustomInterviewScheduleTokenQuery, DefaultCustomInterviewScheduleTokenQueryVariables>;
export const UpcomingEventsDocument = gql`
    query UpcomingEvents($input: UpcomingEventsInput!) {
  upcomingEvents(input: $input) {
    items {
      ...Views_upcomingEvents
    }
  }
}
    ${Views_UpcomingEventsFragmentDoc}`;

/**
 * __useUpcomingEventsQuery__
 *
 * To run a query within a React component, call `useUpcomingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpcomingEventsQuery(baseOptions: Apollo.QueryHookOptions<UpcomingEventsQuery, UpcomingEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingEventsQuery, UpcomingEventsQueryVariables>(UpcomingEventsDocument, options);
      }
export function useUpcomingEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingEventsQuery, UpcomingEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingEventsQuery, UpcomingEventsQueryVariables>(UpcomingEventsDocument, options);
        }
export type UpcomingEventsQueryHookResult = ReturnType<typeof useUpcomingEventsQuery>;
export type UpcomingEventsLazyQueryHookResult = ReturnType<typeof useUpcomingEventsLazyQuery>;
export type UpcomingEventsQueryResult = Apollo.QueryResult<UpcomingEventsQuery, UpcomingEventsQueryVariables>;
export const UpcomingEventsPageFiltersEmployeePrefDocument = gql`
    query UpcomingEventsPageFiltersEmployeePref($prefName: String!) {
  thisEmployee {
    id
    employeePref(prefName: $prefName) {
      orgId
      employeeId
      prefName
      jsonPref {
        ... on UpcomingEventsPageFiltersPref {
          ...UpcomingEventsPageFiltersEmployeePref
        }
      }
    }
  }
}
    ${UpcomingEventsPageFiltersEmployeePrefFragmentDoc}`;

/**
 * __useUpcomingEventsPageFiltersEmployeePrefQuery__
 *
 * To run a query within a React component, call `useUpcomingEventsPageFiltersEmployeePrefQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingEventsPageFiltersEmployeePrefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingEventsPageFiltersEmployeePrefQuery({
 *   variables: {
 *      prefName: // value for 'prefName'
 *   },
 * });
 */
export function useUpcomingEventsPageFiltersEmployeePrefQuery(baseOptions: Apollo.QueryHookOptions<UpcomingEventsPageFiltersEmployeePrefQuery, UpcomingEventsPageFiltersEmployeePrefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingEventsPageFiltersEmployeePrefQuery, UpcomingEventsPageFiltersEmployeePrefQueryVariables>(UpcomingEventsPageFiltersEmployeePrefDocument, options);
      }
export function useUpcomingEventsPageFiltersEmployeePrefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingEventsPageFiltersEmployeePrefQuery, UpcomingEventsPageFiltersEmployeePrefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingEventsPageFiltersEmployeePrefQuery, UpcomingEventsPageFiltersEmployeePrefQueryVariables>(UpcomingEventsPageFiltersEmployeePrefDocument, options);
        }
export type UpcomingEventsPageFiltersEmployeePrefQueryHookResult = ReturnType<typeof useUpcomingEventsPageFiltersEmployeePrefQuery>;
export type UpcomingEventsPageFiltersEmployeePrefLazyQueryHookResult = ReturnType<typeof useUpcomingEventsPageFiltersEmployeePrefLazyQuery>;
export type UpcomingEventsPageFiltersEmployeePrefQueryResult = Apollo.QueryResult<UpcomingEventsPageFiltersEmployeePrefQuery, UpcomingEventsPageFiltersEmployeePrefQueryVariables>;
export const UpdateUpcomingEventsPageFiltersEmployeePrefDocument = gql`
    mutation updateUpcomingEventsPageFiltersEmployeePref($input: EmployeePrefUpdateInput!) {
  employeePrefUpdate(input: $input) {
    employeePref {
      orgId
      employeeId
      prefName
    }
  }
}
    `;
export type UpdateUpcomingEventsPageFiltersEmployeePrefMutationFn = Apollo.MutationFunction<UpdateUpcomingEventsPageFiltersEmployeePrefMutation, UpdateUpcomingEventsPageFiltersEmployeePrefMutationVariables>;

/**
 * __useUpdateUpcomingEventsPageFiltersEmployeePrefMutation__
 *
 * To run a mutation, you first call `useUpdateUpcomingEventsPageFiltersEmployeePrefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUpcomingEventsPageFiltersEmployeePrefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUpcomingEventsPageFiltersEmployeePrefMutation, { data, loading, error }] = useUpdateUpcomingEventsPageFiltersEmployeePrefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUpcomingEventsPageFiltersEmployeePrefMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUpcomingEventsPageFiltersEmployeePrefMutation, UpdateUpcomingEventsPageFiltersEmployeePrefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUpcomingEventsPageFiltersEmployeePrefMutation, UpdateUpcomingEventsPageFiltersEmployeePrefMutationVariables>(UpdateUpcomingEventsPageFiltersEmployeePrefDocument, options);
      }
export type UpdateUpcomingEventsPageFiltersEmployeePrefMutationHookResult = ReturnType<typeof useUpdateUpcomingEventsPageFiltersEmployeePrefMutation>;
export type UpdateUpcomingEventsPageFiltersEmployeePrefMutationResult = Apollo.MutationResult<UpdateUpcomingEventsPageFiltersEmployeePrefMutation>;
export type UpdateUpcomingEventsPageFiltersEmployeePrefMutationOptions = Apollo.BaseMutationOptions<UpdateUpcomingEventsPageFiltersEmployeePrefMutation, UpdateUpcomingEventsPageFiltersEmployeePrefMutationVariables>;
export const UpdateFlowDoneDocument = gql`
    query UpdateFlowDone($id: uuid!) {
  applicationStage(id: $id) {
    id
    isHoldForRsvp
    isReadyForCandidateComms
    application {
      id
      candidateId
    }
    task {
      id
      isDebriefRequired
      debriefs {
        id
      }
    }
  }
}
    `;

/**
 * __useUpdateFlowDoneQuery__
 *
 * To run a query within a React component, call `useUpdateFlowDoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlowDoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateFlowDoneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateFlowDoneQuery(baseOptions: Apollo.QueryHookOptions<UpdateFlowDoneQuery, UpdateFlowDoneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateFlowDoneQuery, UpdateFlowDoneQueryVariables>(UpdateFlowDoneDocument, options);
      }
export function useUpdateFlowDoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateFlowDoneQuery, UpdateFlowDoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateFlowDoneQuery, UpdateFlowDoneQueryVariables>(UpdateFlowDoneDocument, options);
        }
export type UpdateFlowDoneQueryHookResult = ReturnType<typeof useUpdateFlowDoneQuery>;
export type UpdateFlowDoneLazyQueryHookResult = ReturnType<typeof useUpdateFlowDoneLazyQuery>;
export type UpdateFlowDoneQueryResult = Apollo.QueryResult<UpdateFlowDoneQuery, UpdateFlowDoneQueryVariables>;
export const UpdateScheduleFlowHeaderDocument = gql`
    query updateScheduleFlowHeader($id: uuid!) {
  applicationStage(id: $id) {
    id
    taskId
    name
    jobStage {
      id
      name
    }
    application {
      id
      candidate {
        id
        fullName
      }
      job {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUpdateScheduleFlowHeaderQuery__
 *
 * To run a query within a React component, call `useUpdateScheduleFlowHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleFlowHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateScheduleFlowHeaderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateScheduleFlowHeaderQuery(baseOptions: Apollo.QueryHookOptions<UpdateScheduleFlowHeaderQuery, UpdateScheduleFlowHeaderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateScheduleFlowHeaderQuery, UpdateScheduleFlowHeaderQueryVariables>(UpdateScheduleFlowHeaderDocument, options);
      }
export function useUpdateScheduleFlowHeaderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateScheduleFlowHeaderQuery, UpdateScheduleFlowHeaderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateScheduleFlowHeaderQuery, UpdateScheduleFlowHeaderQueryVariables>(UpdateScheduleFlowHeaderDocument, options);
        }
export type UpdateScheduleFlowHeaderQueryHookResult = ReturnType<typeof useUpdateScheduleFlowHeaderQuery>;
export type UpdateScheduleFlowHeaderLazyQueryHookResult = ReturnType<typeof useUpdateScheduleFlowHeaderLazyQuery>;
export type UpdateScheduleFlowHeaderQueryResult = Apollo.QueryResult<UpdateScheduleFlowHeaderQuery, UpdateScheduleFlowHeaderQueryVariables>;
export const UpdateFlowDocument = gql`
    query UpdateFlow($id: uuid!, $fetchInterviewPlan: Boolean!) {
  applicationStage(id: $id) {
    id
    application {
      id
      candidate {
        id
        timezone
        atsLatestAvailability {
          ...useCandidateAvailability_atsLatestAvailability
          timezone
          originalText
        }
        ...ScheduleFlowDataProvider_candidate
      }
    }
    jobStage {
      id
      name
      job {
        id
        name
        atsId
        ...ScheduleInterviewPlan_job
      }
      jobStageSettingsV2 {
        id
        ...ScheduleFlowDataProvider_jobStageSettings
      }
    }
    task {
      id
      availabilities {
        id
        ...useCandidateAvailability_availabilities
      }
      interviewPlan {
        id
        ...ScheduleFlowDataProvider_interviewPlan @include(if: $fetchInterviewPlan)
      }
    }
  }
}
    ${UseCandidateAvailability_AtsLatestAvailabilityFragmentDoc}
${ScheduleFlowDataProvider_CandidateFragmentDoc}
${ScheduleInterviewPlan_JobFragmentDoc}
${ScheduleFlowDataProvider_JobStageSettingsFragmentDoc}
${UseCandidateAvailability_AvailabilitiesFragmentDoc}
${ScheduleFlowDataProvider_InterviewPlanFragmentDoc}`;

/**
 * __useUpdateFlowQuery__
 *
 * To run a query within a React component, call `useUpdateFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateFlowQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fetchInterviewPlan: // value for 'fetchInterviewPlan'
 *   },
 * });
 */
export function useUpdateFlowQuery(baseOptions: Apollo.QueryHookOptions<UpdateFlowQuery, UpdateFlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateFlowQuery, UpdateFlowQueryVariables>(UpdateFlowDocument, options);
      }
export function useUpdateFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateFlowQuery, UpdateFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateFlowQuery, UpdateFlowQueryVariables>(UpdateFlowDocument, options);
        }
export type UpdateFlowQueryHookResult = ReturnType<typeof useUpdateFlowQuery>;
export type UpdateFlowLazyQueryHookResult = ReturnType<typeof useUpdateFlowLazyQuery>;
export type UpdateFlowQueryResult = Apollo.QueryResult<UpdateFlowQuery, UpdateFlowQueryVariables>;
export const ApplicationStageInterviewDocument = gql`
    query applicationStageInterview($id: uuid!) {
  applicationStage(id: $id) {
    id
    isCancelled
    applicationId
    jobStageId
    emailTemplateId
    task {
      id
      interviewPlan {
        id
        ...JobStage_InterviewPlan
      }
    }
    conversation {
      id
      remoteName
    }
    timezone
    videoMeetingUrl
    initialEmailSubject
    initialEmailBody
    isPrivateCalendarEvent
    applicationStageCandidateEvents {
      id
      name
      googleEventId
      googleCalendarId
      calendarEventTemplateId
    }
    application {
      id
      jobStage {
        id
        atsId
        job {
          id
          atsId
        }
      }
      candidate {
        id
        fullName
        email
        additionalEmails
        recruiterId
        coordinatorId
        atsCandidate {
          atsId
          atsUrl
        }
      }
    }
    applicationStageInterviews(input: {isCancelled: false, orderBy: {startAt: ASC}}) {
      id
      name
      endAt
      startAt
      googleEventId
      googleCalendarId
      jobStageInterviewId
      videoMeetingUrl
      zoomHostUserId
      zoomMeetingId
      zoomPassword
      zoomPstnPassword
      zoomDialInInfo
      codingInterviewUrl
      emailTemplateId
      isHiddenFromCandidate
      videoMeetingHostEmployeeId
      applicationStageInterviewInterviewers {
        interviewModuleId
        interviewer {
          id
          atsId
          fullName
          slackImageUrl
          email
          givenName
          timezone
          title
          linkedinUrl
          familyName
          orgId
          isDirectoryDisabled
          useCalendarTimezone
          isPaused
        }
        isOptional
        jobStageInterviewSeatId
        role
        responseStatus
        interviewerId
        interviewerId
      }
      applicationStageInterviewRooms {
        applicationStageInterviewId
        meetingRoomId
        responseStatus
      }
      atsInterviewDefinition {
        ...AtsInterviewDefinition
      }
      atsScheduledInterviewId
      atsInterviewDefinitionId
    }
    jobStage {
      id
      jobStageSettings {
        id
        candidateEmailTemplateId
        candidateEventTemplateId
        candidateCalendarId
        slackTemplateId
      }
    }
  }
  thisEmployee {
    id
    employeePrefs {
      orgId
      employeeId
      prefName
      stringValue
    }
  }
  orgCandidateCalendarId: thisOrg {
    id
    orgPref(prefName: "str_default_candidate_calendar") {
      orgId
      prefName
      stringValue
    }
  }
  orgInterviewerCalendarId: thisOrg {
    id
    orgPref(prefName: "str_default_interviewer_calendar") {
      orgId
      prefName
      stringValue
    }
  }
}
    ${JobStage_InterviewPlanFragmentDoc}
${AtsInterviewDefinitionFragmentDoc}`;

/**
 * __useApplicationStageInterviewQuery__
 *
 * To run a query within a React component, call `useApplicationStageInterviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationStageInterviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationStageInterviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicationStageInterviewQuery(baseOptions: Apollo.QueryHookOptions<ApplicationStageInterviewQuery, ApplicationStageInterviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationStageInterviewQuery, ApplicationStageInterviewQueryVariables>(ApplicationStageInterviewDocument, options);
      }
export function useApplicationStageInterviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationStageInterviewQuery, ApplicationStageInterviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationStageInterviewQuery, ApplicationStageInterviewQueryVariables>(ApplicationStageInterviewDocument, options);
        }
export type ApplicationStageInterviewQueryHookResult = ReturnType<typeof useApplicationStageInterviewQuery>;
export type ApplicationStageInterviewLazyQueryHookResult = ReturnType<typeof useApplicationStageInterviewLazyQuery>;
export type ApplicationStageInterviewQueryResult = Apollo.QueryResult<ApplicationStageInterviewQuery, ApplicationStageInterviewQueryVariables>;
export const AccountPhoneNumberDocument = gql`
    query AccountPhoneNumber {
  thisEmployee {
    id
    phoneNumber
    email
  }
}
    `;

/**
 * __useAccountPhoneNumberQuery__
 *
 * To run a query within a React component, call `useAccountPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPhoneNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountPhoneNumberQuery(baseOptions?: Apollo.QueryHookOptions<AccountPhoneNumberQuery, AccountPhoneNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountPhoneNumberQuery, AccountPhoneNumberQueryVariables>(AccountPhoneNumberDocument, options);
      }
export function useAccountPhoneNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountPhoneNumberQuery, AccountPhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountPhoneNumberQuery, AccountPhoneNumberQueryVariables>(AccountPhoneNumberDocument, options);
        }
export type AccountPhoneNumberQueryHookResult = ReturnType<typeof useAccountPhoneNumberQuery>;
export type AccountPhoneNumberLazyQueryHookResult = ReturnType<typeof useAccountPhoneNumberLazyQuery>;
export type AccountPhoneNumberQueryResult = Apollo.QueryResult<AccountPhoneNumberQuery, AccountPhoneNumberQueryVariables>;
export const EmployeeNotificationPrefsDocument = gql`
    query EmployeeNotificationPrefs {
  thisEmployee {
    id
    notificationPrefs {
      ...Notification_EmployeeNotificationPref
    }
  }
}
    ${Notification_EmployeeNotificationPrefFragmentDoc}`;

/**
 * __useEmployeeNotificationPrefsQuery__
 *
 * To run a query within a React component, call `useEmployeeNotificationPrefsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeNotificationPrefsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeNotificationPrefsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeeNotificationPrefsQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeNotificationPrefsQuery, EmployeeNotificationPrefsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeNotificationPrefsQuery, EmployeeNotificationPrefsQueryVariables>(EmployeeNotificationPrefsDocument, options);
      }
export function useEmployeeNotificationPrefsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeNotificationPrefsQuery, EmployeeNotificationPrefsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeNotificationPrefsQuery, EmployeeNotificationPrefsQueryVariables>(EmployeeNotificationPrefsDocument, options);
        }
export type EmployeeNotificationPrefsQueryHookResult = ReturnType<typeof useEmployeeNotificationPrefsQuery>;
export type EmployeeNotificationPrefsLazyQueryHookResult = ReturnType<typeof useEmployeeNotificationPrefsLazyQuery>;
export type EmployeeNotificationPrefsQueryResult = Apollo.QueryResult<EmployeeNotificationPrefsQuery, EmployeeNotificationPrefsQueryVariables>;
export const EmployeeNotificationPrefDocument = gql`
    mutation EmployeeNotificationPref($input: EmployeeNotificationPrefUpdateInput!) {
  employeeNotificationPrefUpdate(input: $input) {
    employeeNotificationPref {
      ...Notification_EmployeeNotificationPref
    }
  }
}
    ${Notification_EmployeeNotificationPrefFragmentDoc}`;
export type EmployeeNotificationPrefMutationFn = Apollo.MutationFunction<EmployeeNotificationPrefMutation, EmployeeNotificationPrefMutationVariables>;

/**
 * __useEmployeeNotificationPrefMutation__
 *
 * To run a mutation, you first call `useEmployeeNotificationPrefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmployeeNotificationPrefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [employeeNotificationPrefMutation, { data, loading, error }] = useEmployeeNotificationPrefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmployeeNotificationPrefMutation(baseOptions?: Apollo.MutationHookOptions<EmployeeNotificationPrefMutation, EmployeeNotificationPrefMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmployeeNotificationPrefMutation, EmployeeNotificationPrefMutationVariables>(EmployeeNotificationPrefDocument, options);
      }
export type EmployeeNotificationPrefMutationHookResult = ReturnType<typeof useEmployeeNotificationPrefMutation>;
export type EmployeeNotificationPrefMutationResult = Apollo.MutationResult<EmployeeNotificationPrefMutation>;
export type EmployeeNotificationPrefMutationOptions = Apollo.BaseMutationOptions<EmployeeNotificationPrefMutation, EmployeeNotificationPrefMutationVariables>;
export const InterviewModuleMemberMenuOptionsDocument = gql`
    query InterviewModuleMemberMenuOptions($interviewModuleId: uuid!, $employeeId: uuid!) {
  interviewModuleMember(
    interviewModuleId: $interviewModuleId
    employeeId: $employeeId
  ) {
    employeeId
    interviewModuleId
    ...InterviewModuleMemberMenuOptions
    ...RemoveMemberModal_interviewModuleMember
    ...SetModuleMemberAsTrained_interviewModuleMember
    ...pauseMemberModal_moduleMember
    ...SetModuleMemberAsTrainee_moduleMember
  }
}
    ${InterviewModuleMemberMenuOptionsFragmentDoc}
${RemoveMemberModal_InterviewModuleMemberFragmentDoc}
${SetModuleMemberAsTrained_InterviewModuleMemberFragmentDoc}
${PauseMemberModal_ModuleMemberFragmentDoc}
${SetModuleMemberAsTrainee_ModuleMemberFragmentDoc}`;

/**
 * __useInterviewModuleMemberMenuOptionsQuery__
 *
 * To run a query within a React component, call `useInterviewModuleMemberMenuOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewModuleMemberMenuOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewModuleMemberMenuOptionsQuery({
 *   variables: {
 *      interviewModuleId: // value for 'interviewModuleId'
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useInterviewModuleMemberMenuOptionsQuery(baseOptions: Apollo.QueryHookOptions<InterviewModuleMemberMenuOptionsQuery, InterviewModuleMemberMenuOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewModuleMemberMenuOptionsQuery, InterviewModuleMemberMenuOptionsQueryVariables>(InterviewModuleMemberMenuOptionsDocument, options);
      }
export function useInterviewModuleMemberMenuOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewModuleMemberMenuOptionsQuery, InterviewModuleMemberMenuOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewModuleMemberMenuOptionsQuery, InterviewModuleMemberMenuOptionsQueryVariables>(InterviewModuleMemberMenuOptionsDocument, options);
        }
export type InterviewModuleMemberMenuOptionsQueryHookResult = ReturnType<typeof useInterviewModuleMemberMenuOptionsQuery>;
export type InterviewModuleMemberMenuOptionsLazyQueryHookResult = ReturnType<typeof useInterviewModuleMemberMenuOptionsLazyQuery>;
export type InterviewModuleMemberMenuOptionsQueryResult = Apollo.QueryResult<InterviewModuleMemberMenuOptionsQuery, InterviewModuleMemberMenuOptionsQueryVariables>;
export const TaskSetIsUrgentDocument = gql`
    mutation TaskSetIsUrgent($input: TaskSetIsUrgentInput!) {
  taskSetIsUrgent(input: $input) {
    id
    isUrgent
  }
}
    `;
export type TaskSetIsUrgentMutationFn = Apollo.MutationFunction<TaskSetIsUrgentMutation, TaskSetIsUrgentMutationVariables>;

/**
 * __useTaskSetIsUrgentMutation__
 *
 * To run a mutation, you first call `useTaskSetIsUrgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskSetIsUrgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskSetIsUrgentMutation, { data, loading, error }] = useTaskSetIsUrgentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskSetIsUrgentMutation(baseOptions?: Apollo.MutationHookOptions<TaskSetIsUrgentMutation, TaskSetIsUrgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskSetIsUrgentMutation, TaskSetIsUrgentMutationVariables>(TaskSetIsUrgentDocument, options);
      }
export type TaskSetIsUrgentMutationHookResult = ReturnType<typeof useTaskSetIsUrgentMutation>;
export type TaskSetIsUrgentMutationResult = Apollo.MutationResult<TaskSetIsUrgentMutation>;
export type TaskSetIsUrgentMutationOptions = Apollo.BaseMutationOptions<TaskSetIsUrgentMutation, TaskSetIsUrgentMutationVariables>;
export const TaskCancelDocument = gql`
    mutation TaskCancel($input: TaskCancelInput!) {
  taskCancel(input: $input) {
    id
  }
}
    `;
export type TaskCancelMutationFn = Apollo.MutationFunction<TaskCancelMutation, TaskCancelMutationVariables>;

/**
 * __useTaskCancelMutation__
 *
 * To run a mutation, you first call `useTaskCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskCancelMutation, { data, loading, error }] = useTaskCancelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskCancelMutation(baseOptions?: Apollo.MutationHookOptions<TaskCancelMutation, TaskCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskCancelMutation, TaskCancelMutationVariables>(TaskCancelDocument, options);
      }
export type TaskCancelMutationHookResult = ReturnType<typeof useTaskCancelMutation>;
export type TaskCancelMutationResult = Apollo.MutationResult<TaskCancelMutation>;
export type TaskCancelMutationOptions = Apollo.BaseMutationOptions<TaskCancelMutation, TaskCancelMutationVariables>;
export const ScheduleTaskDocument = gql`
    query ScheduleTask($scheduleTaskId: uuid!) {
  task(id: $scheduleTaskId) {
    application {
      id
      candidate {
        id
      }
    }
    ...ScheduleTask_task
    ...UseSchduleTaskMenuOptions_task
  }
}
    ${ScheduleTask_TaskFragmentDoc}
${UseSchduleTaskMenuOptions_TaskFragmentDoc}`;

/**
 * __useScheduleTaskQuery__
 *
 * To run a query within a React component, call `useScheduleTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleTaskQuery({
 *   variables: {
 *      scheduleTaskId: // value for 'scheduleTaskId'
 *   },
 * });
 */
export function useScheduleTaskQuery(baseOptions: Apollo.QueryHookOptions<ScheduleTaskQuery, ScheduleTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduleTaskQuery, ScheduleTaskQueryVariables>(ScheduleTaskDocument, options);
      }
export function useScheduleTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduleTaskQuery, ScheduleTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduleTaskQuery, ScheduleTaskQueryVariables>(ScheduleTaskDocument, options);
        }
export type ScheduleTaskQueryHookResult = ReturnType<typeof useScheduleTaskQuery>;
export type ScheduleTaskLazyQueryHookResult = ReturnType<typeof useScheduleTaskLazyQuery>;
export type ScheduleTaskQueryResult = Apollo.QueryResult<ScheduleTaskQuery, ScheduleTaskQueryVariables>;