import { generatePath } from 'react-router-dom';

import { Routes } from 'src/constants/routes';

import { IntegrationGrantType, ReturnType } from './types';

/**
 * If redirect is true & needed redirect params use this function
 * pathname    : Is the route to which you want to redirect
 *               If type is updated please update array in isIgnoredRoutes function as well
 * redirectUrl : Is the current url where you need to navigate back like a callback url
 */
export const getRedirectParams = (pathname: string, redirectUrl: string): ReturnType => {
  return {
    isOnboardingRequired: true,
    onboardingRedirectParams: {
      pathname,
      state: {
        redirectUrl,
      },
    },
  };
};

/** If already on any of the redirected routes do nothing  */
export const isIgnoredRoutes = (currentRoute: string): boolean => {
  return [
    Routes.TermsAndPrivacy,
    generatePath(Routes.GrantAccess, {
      integrationType: IntegrationGrantType.Outlook,
    }),
    generatePath(Routes.GrantAccess, {
      integrationType: IntegrationGrantType.Gmail,
    }),
    generatePath(Routes.GrantAccess, {
      integrationType: IntegrationGrantType.MicrosoftGccHigh,
    }),
  ].some((route) => currentRoute.includes(route));
};
