import React from 'react';

import BaseIcon, { Props } from './Icon';

const ScheduleCompleteWithBg = ({ dataTestId, color, fontSize = 32, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon
      dataTestId={dataTestId}
      color={color}
      fontSize={fontSize}
      tooltip={tooltip}
      viewBox={`0 0 ${fontSize} ${fontSize}`}
    >
      <rect width={fontSize} height={fontSize} rx="6" fill="#161727" fillOpacity="0.04" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 8C13.75 7.58579 13.4142 7.25 13 7.25C12.5858 7.25 12.25 7.58579 12.25 8V9.25H11C9.48122 9.25 8.25 10.4812 8.25 12L8.25001 21C8.25002 22.5188 9.48123 23.75 11 23.75H21C22.5188 23.75 23.75 22.5188 23.75 21L23.75 12C23.75 10.4812 22.5188 9.25 21 9.25H19.75V8C19.75 7.58579 19.4142 7.25 19 7.25C18.5858 7.25 18.25 7.58579 18.25 8V9.25H13.75V8ZM12.25 10.75V11C12.25 11.4142 12.5858 11.75 13 11.75C13.4142 11.75 13.75 11.4142 13.75 11V10.75H18.25V11C18.25 11.4142 18.5858 11.75 19 11.75C19.4142 11.75 19.75 11.4142 19.75 11V10.75H21C21.6903 10.75 22.25 11.3096 22.25 12L22.25 21C22.25 21.6904 21.6904 22.25 21 22.25H11C10.3097 22.25 9.75001 21.6904 9.75001 21L9.75 12C9.75 11.3096 10.3096 10.75 11 10.75H12.25ZM13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15ZM12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20ZM14.25 15C14.25 14.5858 14.5858 14.25 15 14.25H20C20.4142 14.25 20.75 14.5858 20.75 15C20.75 15.4142 20.4142 15.75 20 15.75H15C14.5858 15.75 14.25 15.4142 14.25 15ZM15 18.25C14.5858 18.25 14.25 18.5858 14.25 19C14.25 19.4142 14.5858 19.75 15 19.75H20C20.4142 19.75 20.75 19.4142 20.75 19C20.75 18.5858 20.4142 18.25 20 18.25H15Z"
        fill="#787882"
      />
      <circle cx="9" cy="23" r="7" fill="#F6F6F7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17C5.68629 17 3 19.6863 3 23C3 26.3137 5.68629 29 9 29C12.3137 29 15 26.3137 15 23C15 19.6863 12.3137 17 9 17ZM12.8106 21.4983C13.0857 21.1887 13.0579 20.7146 12.7483 20.4394C12.4387 20.1643 11.9646 20.1921 11.6894 20.5017L8.21789 24.4072L6.28033 22.4697C5.98744 22.1768 5.51256 22.1768 5.21967 22.4697C4.92678 22.7626 4.92678 23.2374 5.21967 23.5303L7.71967 26.0303C7.86573 26.1764 8.06556 26.2557 8.27203 26.2497C8.4785 26.2436 8.67333 26.1527 8.81056 25.9983L12.8106 21.4983Z"
        fill="#00A39E"
      />
    </BaseIcon>
  );
};

export default ScheduleCompleteWithBg;
