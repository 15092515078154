import { useCallback } from 'react';

import { ScheduleTaskDashboardTaskSearchDocument } from 'src/generated/mloop-graphql';

import { useApolloRefetchQuery } from 'src/hooks/useApolloQuery';

const useScheduleTaskDashboardTaskSearchRefetch = () => {
  const apolloRefetchQuery = useApolloRefetchQuery();

  return useCallback(async () => {
    await apolloRefetchQuery([ScheduleTaskDashboardTaskSearchDocument]);
  }, [apolloRefetchQuery]);
};

export default useScheduleTaskDashboardTaskSearchRefetch;
