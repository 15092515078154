import { useCallback } from 'react';

import { RefetchQueriesInclude, RefetchQueriesResult, useApolloClient } from '@apollo/client';

export function useApolloRefetchQuery(): (include: RefetchQueriesInclude) => RefetchQueriesResult<unknown> {
  const apolloClient = useApolloClient();
  return useCallback(
    (include) => {
      return apolloClient.refetchQueries({
        include,
      });
    },
    [apolloClient]
  );
}
