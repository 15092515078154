/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { assertIsoTimestamp } from '@modernloop/shared/datetime';
import { useFlag } from '@modernloop/shared/feature-flag';
import { InfoIcon, SparkleIcon } from '@modernloop/shared/icons';
import { Alert, AlertTitle, Container, FormControlLabel, Paper, Stack, Switch, Typography } from '@mui/material';
import { addDays, startOfDay as startOfDayDateFns } from 'date-fns';
import { noop } from 'lodash';

import {
  BaseCandidateAvailabilityOption_InterviewPlanFragment,
  CandidateAvailabilityOption_InterviewPlanFragment,
  SuggestedTimeRangesRequestType,
  useTaskInterviewPlanQuery,
} from 'src/generated/mloop-graphql';

import DialogPage, { DialogActions, DialogContent, DialogTitle } from 'src/components/Dialog/DialogPage';
import useFullScreen from 'src/components/Dialog/useFullScreen';
import useEqualSizeSxProps from 'src/components/utils/useEqualSizeSxProps';

import useInterviewPlanEntityHasInterviewHiddenFromCandidate from 'src/entities/InterviewPlan/useInterviewPlanHasInterviewHiddenFromCandidate';
import useIsInterviewPlanEntityValid from 'src/entities/InterviewPlan/useIsInterviewPlanValid';

import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';
import useUserId from 'src/hooks/useUserId';

import {
  CandidateAvailabilityOptions,
  DEFAULT_MINUTES_PER_DAY,
  DEFAULT_NUMBER_OF_DAYS,
} from 'src/store/slices/candidate-availability-options';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import IsoTimestamp from 'src/types/IsoTimestamp';

import OptionsCountAlert from 'src/views-new/CandidateRequest/OptionsCountAlert';
import useInterviewPlanHasInterviewHiddenFromCandidate from 'src/views-new/InterviewPlan/useInterviewPlanHasInterviewHiddenFromCandidate';
import useIsInterviewPlanValid from 'src/views-new/InterviewPlan/useIsInterviewPlanValid';
import { TaskCreateInputWrapper } from 'src/views-new/ScheduleTask/CreateModals/shared/types';
import OptionsCalendar from 'src/views-new/SelfSchedule/OptionsCalendar';
import TimeFrame, { NumberOfDays } from 'src/views-new/SelfSchedule/TimeframePicker';

import { CandidateAvailabilityOptionsPreferences } from './CandidateAvailabilityOptionsPreferences';
import { DayCount } from './DayCountPicker';
import { AdvanceNoticeHours } from './RequiresAdvanceNoticeOptions';

type Fragments = {
  interviewPlan: BaseCandidateAvailabilityOption_InterviewPlanFragment;
};

type Props = {
  applicationId: string;
  taskId?: string;
  taskInput?: TaskCreateInputWrapper;
  timezone: string;
  options: CandidateAvailabilityOptions;
  onOptionsChanged: (options: CandidateAvailabilityOptions) => void;
  onBack?: () => void;
  onClose: () => void;
  onSave: (config: CandidateAvailabilityOptions) => Promise<void>;
};

export function useRoundedMinsPerDay(options: CandidateAvailabilityOptions) {
  return useMemo(() => {
    if (!options) return DEFAULT_MINUTES_PER_DAY;

    return options.minutesPerDays >= options.minimumTimeBlockMinutes
      ? options.minutesPerDays
      : options.minimumTimeBlockMinutes;
  }, [options]);
}

const BaseCandidateAvailabilityOption: FCWithFragments<Fragments, Props> = ({
  applicationId,
  taskId,
  taskInput,
  timezone,
  interviewPlan,
  options: optionsProp,
  onOptionsChanged,
  onBack,
  onClose,
  onSave,
}): JSX.Element | null => {
  const equalSizeSxProps = useEqualSizeSxProps();
  const isFullScreen = useFullScreen();
  const ignoreRollingDaysSetting = useFlag('user_ignore_rolling_window_setting');
  const interviewPlanEntityInTaskEnabled = useFlag('interview_plan_entity_in_tasks');

  const { data: taskInterviewPlan } = useTaskInterviewPlanQuery({
    variables: { id: taskId },
    skip: !taskId,
  });

  const [saving, setSaving] = useState(false);
  const [haveTogglesChanged, setHaveTogglesChanged] = useState(false);

  const interviewPlanId = taskInput?.customJobStageId || taskInterviewPlan?.task?.interviewPlan?.id;
  const isInterviewPlanStoreValid = useIsInterviewPlanValid(interviewPlanId, {
    isDayBreakAllowed: false,
    allowAllInterviewsHiddenFromCandiate: true,
  });

  const isInterviewPlanEntityValid = useIsInterviewPlanEntityValid(
    {
      jobStage: interviewPlan || { id: interviewPlanId, jobStageInterviewGroups: [] },
    },
    { isDayBreakAllowed: false, allowAllInterviewsHiddenFromCandiate: true, allowDynamicBreaks: false }
  );

  const {
    isValid: isValidInterviewPlan,
    title: interviewPlanTitle,
    error: interviewPlanError,
  } = interviewPlanEntityInTaskEnabled ? isInterviewPlanEntityValid : isInterviewPlanStoreValid;

  const hasInterviewHiddenFromCandidateStore = useInterviewPlanHasInterviewHiddenFromCandidate(interviewPlanId);
  const hasInterviewHiddenFromCandidateEntity = useInterviewPlanEntityHasInterviewHiddenFromCandidate({
    interviewPlan,
  });
  const hasInterviewHiddenFromCandidate = interviewPlanEntityInTaskEnabled
    ? hasInterviewHiddenFromCandidateEntity
    : hasInterviewHiddenFromCandidateStore;

  const options = useMemo(() => {
    // When there are interviews hidden from candidate we want to disable the suggested availability toggles.
    return {
      ...optionsProp,
      canScheduleOverAvailableKeywords: hasInterviewHiddenFromCandidate
        ? false
        : optionsProp.canScheduleOverAvailableKeywords,
      canScheduleOverRecruitingKeywords: hasInterviewHiddenFromCandidate
        ? false
        : optionsProp.canScheduleOverRecruitingKeywords,
      canScheduleOverFreeTime: hasInterviewHiddenFromCandidate ? false : optionsProp.canScheduleOverFreeTime,
      shouldRespectLoadLimit: hasInterviewHiddenFromCandidate ? false : optionsProp.shouldRespectLoadLimit,
    };
  }, [hasInterviewHiddenFromCandidate, optionsProp]);

  const roundedMinsPerDay = useRoundedMinsPerDay(options);

  const [loadingOptions, setLoadingOptions] = useState(false);
  const [selectedDatesOptionsCount, setSelectedDatesOptionsCount] = useState(0);
  const [selfScheduleCountError, setSelfScheduleCountError] = useState<string>();

  const getInclusionDays = useCallback((value: NumberOfDays) => {
    let date = startOfDayDateFns(new Date());
    const inclusionDays: IsoTimestamp[] = [];

    for (let i = 0; i < value; i++) {
      inclusionDays.push(assertIsoTimestamp(date.toISOString()));
      date = addDays(date, 1);
    }

    return inclusionDays;
  }, []);

  const handleSelectDays = (value: DayCount) => {
    onOptionsChanged({ ...options, numberOfDays: value });
  };

  const handleSelectMinsPerDay = (mins: number) => {
    onOptionsChanged({
      ...options,
      minutesPerDays: mins,
      minimumTimeBlockMinutes: options.minimumTimeBlockMinutes > mins ? mins : options.minimumTimeBlockMinutes,
    });
  };

  const handleSelectMinTimeBlock = (mins: number) => {
    onOptionsChanged({ ...options, minimumTimeBlockMinutes: mins });
  };

  const handleSelectAdvanceNoticeHours = (value: AdvanceNoticeHours) => {
    onOptionsChanged({ ...options, advanceNoticeMinutes: value * 60 });
  };

  const handleNotesBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onOptionsChanged({ ...options, candidateNote: event.target.value });
  };

  const handleSaveAndContinueClick = () => {
    setSaving(true);
    const finalOptions = { ...options };
    finalOptions.minutesPerDays = roundedMinsPerDay;
    finalOptions.isValidInterviewPlan = isValidInterviewPlan;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    onSave(finalOptions).finally(() => {
      setSaving(false);
    });
  };

  const handleTimeframeDaysChange = (value: NumberOfDays) => {
    if (options.useRollingDays) {
      onOptionsChanged({ ...options, timeframeNumberOfDays: value, inclusionDays: undefined });
    } else {
      onOptionsChanged({ ...options, timeframeNumberOfDays: value, inclusionDays: getInclusionDays(value) });
    }
  };

  const handleSelectedDatesChanged = useCallback(
    (dates: IsoTimestamp[]) => {
      const timeframeOptions = ignoreRollingDaysSetting
        ? {
            timeframeNumberOfDays: NumberOfDays.Custom,
          }
        : {};
      onOptionsChanged({
        ...options,
        ...timeframeOptions,
        inclusionDays: dates,
        useRollingDays: false,
      });
    },
    [onOptionsChanged, options, ignoreRollingDaysSetting]
  );

  const inclusionDays: IsoTimestamp[] | undefined = useMemo(() => {
    if (!options) return undefined;

    if (options.useRollingDays) {
      return undefined;
    }

    if (options.inclusionDays?.length) {
      return options.inclusionDays as IsoTimestamp[];
    }

    if (options.timeframeNumberOfDays) {
      return getInclusionDays(options.timeframeNumberOfDays);
    }

    return undefined;
  }, [options, getInclusionDays]);

  if (!options) return null;

  const suggestedOptionsOn =
    options.canScheduleOverAvailableKeywords ||
    options.canScheduleOverRecruitingKeywords ||
    options.canScheduleOverFreeTime;

  return (
    <DialogPage onClose={onClose}>
      <DialogContent>
        <DialogTitle
          title="Set up availability request"
          subTitle={!onBack ? 'Step 1 of 2: Add timeframe' : 'Step 2 out of 3: Add timeframe'}
        />
        {((taskId && taskInterviewPlan) || !!taskInput) && (
          <Stack direction="column" spacing={1} useFlexGap mb={1}>
            <ConditionalThemeProvider>
              <Typography fontWeight={600}>What days can the candidate submit availability for?</Typography>

              <Stack
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="space-between"
                spacing={1}
                sx={equalSizeSxProps.equalSizeChild}
              >
                <Typography>Timeframe</Typography>
                <TimeFrame
                  timeFrame={options.timeframeNumberOfDays || NumberOfDays.TwoWeeks}
                  onChange={handleTimeframeDaysChange}
                />
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1} ml={-1.5}>
                <FormControlLabel
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                  labelPlacement="start"
                  label="Use rolling window"
                  control={
                    <Switch
                      checked={options.useRollingDays}
                      onChange={(event) => {
                        if (!event.target.checked) {
                          const timeframeOptions = ignoreRollingDaysSetting
                            ? {
                                timeframeNumberOfDays: NumberOfDays.Custom,
                              }
                            : {};
                          onOptionsChanged({
                            ...options,
                            ...timeframeOptions,
                            useRollingDays: false,
                            inclusionDays: options.timeframeNumberOfDays
                              ? getInclusionDays(options.timeframeNumberOfDays)
                              : options.inclusionDays,
                          });
                        } else {
                          onOptionsChanged({
                            ...options,
                            useRollingDays: true,
                            timeframeNumberOfDays: options.timeframeNumberOfDays || NumberOfDays.TwoWeeks,
                            inclusionDays: undefined,
                          });
                        }
                      }}
                    />
                  }
                />
                <InfoIcon tooltip="Based on the selection above. Ensures the last selectable date is always that number of days out from when the candidate is viewing their options. Not available when selecting custom dates." />{' '}
              </Stack>
            </ConditionalThemeProvider>
            <Container
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Paper
                elevation={0}
                variant={isFullScreen ? undefined : 'outlined'}
                sx={{
                  width: '380px',
                  pl: '10px',
                }}
              >
                <OptionsCalendar
                  applicationId={applicationId}
                  taskId={taskId}
                  requestType={SuggestedTimeRangesRequestType.AvailabilityRequest}
                  jobStageId={options.jobStageId}
                  customJobStageId={taskInterviewPlan?.task?.interviewPlan?.id || taskInput?.customJobStageId}
                  customInterviewPlan={taskInput?.customInterviewPlan || taskInput?.interviewPlan?.groups || undefined}
                  rollingDays={
                    options?.useRollingDays ? options.timeframeNumberOfDays || NumberOfDays.TwoWeeks : undefined
                  }
                  inclusionDays={inclusionDays}
                  advanceNoticeInHours={options.advanceNoticeMinutes ? options.advanceNoticeMinutes / 60 : 0}
                  datePickerNoMargin
                  onLoading={setLoadingOptions}
                  onSelectedDatesOptionsCountChanged={setSelectedDatesOptionsCount}
                  onSelectedDatesChanged={handleSelectedDatesChanged}
                  onEmployeeMissingZoomUserId={noop}
                  onSelfScheduleCountError={setSelfScheduleCountError}
                  shouldRespectLoadLimit={options.shouldRespectLoadLimit ?? true}
                  canScheduleOverAvailableKeywords={options.canScheduleOverAvailableKeywords ?? false}
                  canScheduleOverRecruitingKeywords={options.canScheduleOverRecruitingKeywords ?? false}
                  canScheduleOverFreeTime={options.canScheduleOverFreeTime ?? false}
                  skipFetchingOptions={
                    (!suggestedOptionsOn && !haveTogglesChanged) ||
                    hasInterviewHiddenFromCandidate ||
                    !isValidInterviewPlan ||
                    (interviewPlanEntityInTaskEnabled && !interviewPlan.id) // This means interview plan is not yet available
                  }
                />
              </Paper>
            </Container>
            {!isValidInterviewPlan && (
              <ConditionalThemeProvider>
                <Alert severity="info" icon={<SparkleIcon color="warning" />}>
                  <AlertTitle>Suggest times to your candidate</AlertTitle>
                  {interviewPlanTitle !== 'Multi-day schedule found' && interviewPlanError}
                  {interviewPlanTitle === 'Multi-day schedule found' &&
                    'The interview plan for this candidate has a day break. Please remove the day break to turn on suggestions.'}
                </Alert>
              </ConditionalThemeProvider>
            )}
          </Stack>
        )}

        {hasInterviewHiddenFromCandidate && (
          <ConditionalThemeProvider>
            <Alert severity="info">
              <AlertTitle>Suggested times are not available</AlertTitle>
              Suggested times aren’t available for interview plans with hidden events.
            </Alert>
          </ConditionalThemeProvider>
        )}

        <CandidateAvailabilityOptionsPreferences
          options={options}
          disableToggles={!isValidInterviewPlan || hasInterviewHiddenFromCandidate}
          candidateTimezone={timezone}
          roundedMinsPerDay={roundedMinsPerDay}
          onNumberOfDaysChange={handleSelectDays}
          onMinutesPerDayChange={handleSelectMinsPerDay}
          onMinTimeBlockMinutesChange={handleSelectMinTimeBlock}
          onAdvanceNoticeHoursChange={handleSelectAdvanceNoticeHours}
          onCandidateNoteBlur={handleNotesBlur}
          onScheduleOverAvailableKeywordsChange={(canScheduleOverAvailableKeywords) => {
            setHaveTogglesChanged(true);
            onOptionsChanged({ ...options, canScheduleOverAvailableKeywords });
          }}
          onScheduleOverRecruitingKeywordsChange={(canScheduleOverRecruitingKeywords) => {
            setHaveTogglesChanged(true);
            onOptionsChanged({ ...options, canScheduleOverRecruitingKeywords });
          }}
          onScheduleOverFreeTimeChange={(canScheduleOverFreeTime) => {
            setHaveTogglesChanged(true);
            onOptionsChanged({ ...options, canScheduleOverFreeTime });
          }}
          onRespectLoadLimitChange={(shouldRespectLoadLimit) => {
            onOptionsChanged({ ...options, shouldRespectLoadLimit });
          }}
          alertJsx={
            isValidInterviewPlan && (
              <OptionsCountAlert
                enabled={suggestedOptionsOn ?? false}
                loading={loadingOptions}
                count={selectedDatesOptionsCount}
                error={suggestedOptionsOn ? selfScheduleCountError : undefined}
              />
            )
          }
        />
      </DialogContent>

      <DialogActions
        submitOptions={{
          label: 'Continue',
          isDisabled: Boolean(saving),
          isLoading: saving,
          onClick: handleSaveAndContinueClick,
        }}
        cancelOptions={
          !onBack
            ? {
                label: 'Cancel',
                onClick: () => onClose(),
              }
            : {
                label: 'Previous step',
                onClick: onBack,
                startIcon: 'CaretLeftIcon',
              }
        }
      />
    </DialogPage>
  );
};

BaseCandidateAvailabilityOption.fragments = {
  interviewPlan: gql`
    ${useIsInterviewPlanEntityValid.fragments.jobStage}
    ${useInterviewPlanEntityHasInterviewHiddenFromCandidate.fragments.interviewPlan}
    fragment BaseCandidateAvailabilityOption_interviewPlan on JobStage {
      id
      ...useIsInterviewPlanValid_jobStage
      ...useInterviewPlanHasInterviewHiddenFromCandidate_interviewPlan
    }
  `,
};

type CandidateAvailabilityOptionFragments = {
  interviewPlan: CandidateAvailabilityOption_InterviewPlanFragment;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type CandidateAvailabilityOptionProps = {
  applicationId: string;
  taskId?: string;
  taskInput?: TaskCreateInputWrapper;
  timezone: string;
  options: CandidateAvailabilityOptions;
  onOptionsChanged: (options: CandidateAvailabilityOptions) => void;
  onContinue: () => void;
  onClose: () => void;
  onBack?: () => void;
};

const CandidateAvailabilityOption: FCWithFragments<
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/restric-fragments-name.cjs
  CandidateAvailabilityOptionFragments,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/restric-fragments-name.cjs
  CandidateAvailabilityOptionProps
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/no-multi-comp
> = ({
  applicationId,
  timezone,
  options,
  taskId,
  taskInput,
  interviewPlan,
  onOptionsChanged,
  onBack,
  onContinue,
  onClose,
}): JSX.Element => {
  const { logEvent } = useLogEvent();
  const userId = useUserId();

  const handleSaveAndContinue = async (newOptions: CandidateAvailabilityOptions) => {
    const finalOptions: CandidateAvailabilityOptions = { ...newOptions };

    onOptionsChanged(newOptions);

    logEvent(LoggerEvent.CLIENT_CANDIDATE_AVAILABILITY_OPTIONS, {
      isDefaultNumberOfDays: finalOptions.numberOfDays !== DEFAULT_NUMBER_OF_DAYS,
      userId,
    });

    onContinue();
  };

  return (
    <BaseCandidateAvailabilityOption
      applicationId={applicationId}
      timezone={timezone}
      options={options}
      taskInput={taskInput}
      taskId={taskId}
      interviewPlan={interviewPlan}
      onOptionsChanged={onOptionsChanged}
      onClose={onClose}
      onBack={onBack}
      onSave={handleSaveAndContinue}
    />
  );
};

CandidateAvailabilityOption.fragments = {
  interviewPlan: gql`
    ${BaseCandidateAvailabilityOption.fragments.interviewPlan}
    fragment CandidateAvailabilityOption_interviewPlan on JobStage {
      id
      ...BaseCandidateAvailabilityOption_interviewPlan
    }
  `,
};

export default CandidateAvailabilityOption;

export const GQL = gql`
  query TaskInterviewPlan($id: uuid!) {
    task(id: $id) {
      interviewPlan {
        id
      }
    }
  }
`;
