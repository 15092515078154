import { AxiosRequestConfig } from 'axios';
import LogRocket from 'logrocket';
import { v4 as uuid } from 'uuid';

/**
 * This axios interceptor adds a unique guid to the header.
 * It is used by backend to trace the front end api request.
 *
 * @param config AxiousRequestConfig to add auth header too
 * @returns AxiosRequestConfig that has `ml_trace_id` as
 */
const axioxInterceptorMlTraceId = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  // The check is to mainly make the compiler happy, headers should never be null

  if (config.headers) {
    config.headers.ml_trace_id = uuid();
    LogRocket.track('ml_trace_id', { ml_trace_id: config.headers.ml_trace_id });
    LogRocket.debug(`ml_trace_id: ${config.headers.ml_trace_id}`);
  }
  return config;
};

export default axioxInterceptorMlTraceId;
