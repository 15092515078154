import React, { FC, useMemo } from 'react';

import { Box, SxProps } from '@mui/material';
import { isEmpty } from 'lodash';

import Label from 'src/components/label';

import { Theme } from 'src/themeMui5/type';

const useSxProps = () => {
  return useMemo(() => {
    return {
      root: {
        backgroundColor: (theme: Theme) => theme.palette.background.default,
        border: (theme: Theme) => `1px solid ${theme.grey.solid.low}`,
        borderRadius: '12px',
        padding: '8px 12px',
        marginLeft: '28px',
      },
    };
  }, []);
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface ActivityLogContentProps {
  sx?: SxProps<Theme>;
  dataTestId?: string;
  content: string | JSX.Element;
  fullWidth?: boolean;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const ActivityLogContent: FC<ActivityLogContentProps> = ({ dataTestId, content, fullWidth, sx }) => {
  const sxProps = useSxProps();

  if (!content) return null;

  if (typeof content === 'string' && isEmpty(content)) return null;

  return (
    <Box sx={{ ...sxProps.root, ...sx }} width={fullWidth ? '100%' : 'fit-content'}>
      {typeof content === 'string' ? (
        <Label dataTestId={dataTestId} variant="body" color="max-contrast-grey">
          {content}
        </Label>
      ) : (
        content
      )}
    </Box>
  );
};

export default ActivityLogContent;
