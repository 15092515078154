import React from 'react';

import BaseIcon, { Props } from './Icon';

const RwvpMaybeIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} tiny>
      <circle cx="7" cy="6.99999" r="6.33333" fill="white" stroke="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.78083 5.39514C6.01163 5.02285 6.46041 4.74997 7 4.74997C7.7379 4.74997 8.25001 5.3646 8.25001 5.99996C8.25001 6.63533 7.73789 7.24997 7 7.24997C6.58579 7.24997 6.25 7.58576 6.25 7.99997C6.25 8.41418 6.58579 8.74997 7 8.74997C8.66212 8.74997 9.75 7.3646 9.75 5.99996C9.75 4.63533 8.66211 3.24997 7 3.24997C5.9529 3.24997 5.01634 3.78149 4.50594 4.60479C4.28769 4.95684 4.39616 5.41916 4.74821 5.63741C5.10026 5.85566 5.56258 5.74719 5.78083 5.39514ZM7 11.5C7.55229 11.5 8 11.0523 8 10.5C8 9.94771 7.55229 9.5 7 9.5C6.44772 9.5 6 9.94771 6 10.5C6 11.0523 6.44772 11.5 7 11.5Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default RwvpMaybeIcon;
