import { useMemo } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';

import { InterviewType, UseIsInterviewPlanValid_JobStageFragment } from 'src/generated/mloop-graphql';

import { useOrgAtsService } from 'src/hooks/atsService';

import { isBreak } from 'src/utils/interview';

const INCOMPLETE_INTERVIEW_PLAN = 'Incomplete interview plan';
export const INTERVIEW_PLAN_WITH_HIDDEN_EVENTS = 'Interview plan with hidden events';
export const INTERVIEW_PLAN_WITH_MULTI_DAY_SCHEDULE = 'Multi-day schedule found';
export const INTERVIEW_PLAN_WITH_DYNAMIC_BREAKS = 'Interview plan has dynamic breaks';

type Props = {
  isDayBreakAllowed?: boolean;
  allowEmptyPlan?: boolean;
  allowEmptyInterviews?: boolean;
  allowAllInterviewsHiddenFromCandiate?: boolean;
  allowSomeInterviewsHiddenFromCandiate?: boolean;
  allowDynamicBreaks?: boolean;
};

type Fragments = {
  jobStage: UseIsInterviewPlanValid_JobStageFragment;
};

export type IsInterviewPlanValidResult = {
  isValid: boolean;
  title: string;
  error: string;
};

const useIsInterviewPlanValid: FunctionWithFragments<Fragments, Props, IsInterviewPlanValidResult> = (
  { jobStage },
  {
    isDayBreakAllowed = true,
    allowEmptyPlan = false,
    allowEmptyInterviews = false,
    allowAllInterviewsHiddenFromCandiate = false,
    allowSomeInterviewsHiddenFromCandiate = true,
    allowDynamicBreaks = true,
  }
) => {
  const atsService = useOrgAtsService();
  const orgBreakImprovementsEnabled = useFlag('org_break_improvements');

  const jobStageInterviewGroups = jobStage.jobStageInterviewGroups || [];
  const jobStageInterviews = jobStageInterviewGroups
    .map((group) => group.jobStageInterviews || [])
    .flat()
    // eslint-disable-next-line max-params
    .filter((interview, index, array) => {
      // Filtering out the last break when org_break_improvements is enabled since we want to treat is as a no-op
      return !(orgBreakImprovementsEnabled && index === array.length - 1 && isBreak(interview.interviewType));
    });
  const jobStageInterviewSeats = jobStageInterviews.map((interview) => interview.jobStageInterviewSeats || []).flat();
  const employees = useMemo(() => {
    return jobStageInterviewSeats
      .map((seat) => {
        if (!seat.freeformSeat) return [];
        return seat.freeformSeat.jobStageInterviewSeatEmployees?.map((employee) => employee.employee).flat();
      })
      .flat();
  }, [jobStageInterviewSeats]);

  /**
   * TODO: (hemant) - Check the following
   * - If interview module has no trained interviewer.
   * - If the attributes applied to module makes the trained interviewer count 0.
   *   https://www.notion.so/modernloop/Interview-modules-in-interview-plan-with-more-than-500-members-2c543bb8f158478ea061b18eb1d81fcc?pvs=4
   */

  /**
   * Ensure that freeform employees in plan are not disabled or archived.
   */

  const isEmployeeDisabled = useMemo(() => {
    return employees.reduce((isDisabled, employee) => {
      return isDisabled || employee?.isArchived || employee?.isDirectoryDisabled || employee?.isAtsDisabled;
    }, false);
  }, [employees]);

  /**
   * Check if the freeform employees are connected to ATS.
   */

  const isEmployeeNotConnectedToAts = useMemo(() => {
    if (!employees || employees.length === 0) {
      return false;
    }

    return employees.reduce((hasAtsId, employee) => {
      return hasAtsId || !employee?.hasAtsId;
    }, false);
  }, [employees]);

  if (isEmployeeDisabled) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error: `Some of the interviewers in interview plan are disabled: ${employees
        ?.map((employee) => {
          if (employee?.isArchived || employee?.isAtsDisabled || employee?.isDirectoryDisabled)
            return employee.fullName;
          return '';
        })
        .filter((value) => Boolean(value))
        .join(', ')}`,
    };
  }

  if (isEmployeeNotConnectedToAts) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error: `Some of the interviewers in interview plan are disconnected from ${atsService?.name}: ${employees
        .map((employee) => {
          if (!employee?.hasAtsId) return employee?.fullName;
          return '';
        })
        .filter((value) => Boolean(value))
        .join(', ')}`,
    };
  }

  /**
   * Check if there are interviews in the plan.
   */

  if (!jobStageInterviews.length && !allowEmptyPlan) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error:
        'The interview plan for this candidate is incomplete. There are no interviews added to your interview plan',
    };
  }

  if (!jobStageInterviews.length && allowEmptyPlan) {
    return {
      isValid: true,
      title: '',
      error: 'Empty plan is valid',
    };
  }

  /**
   * Ensure that there is no day break
   */
  if (!isDayBreakAllowed) {
    const hasDayBreak = jobStageInterviews.reduce((hasDayDivider, interview) => {
      return (
        hasDayDivider ||
        interview.interviewType === InterviewType.DayDivider ||
        interview.interviewType === InterviewType.NextDay
      );
    }, false);

    if (hasDayBreak) {
      return {
        isValid: false,
        title: INTERVIEW_PLAN_WITH_MULTI_DAY_SCHEDULE,
        error:
          'ModernLoop does not support self-scheduling of multi-day schedules yet. ' +
          'Please remove any day breaks and try again. We’re working to bring you this feature in the future!',
      };
    }
  }

  /**
   * Ensure that break is not first in plan
   */
  if (jobStageInterviews.length && isBreak(jobStageInterviews[0].interviewType)) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error: 'Break cannot be at the start of interview plan',
    };
  }

  /**
   * Ensure that break is not last in plan
   */
  if (
    !orgBreakImprovementsEnabled &&
    jobStageInterviews.length &&
    isBreak(jobStageInterviews[jobStageInterviews.length - 1].interviewType)
  ) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error: 'Break cannot be at the end of interview plan',
    };
  }

  /**
   * Ensure that there are interviewers added (either freeform or module)
   */

  const hasInterviewSeats =
    Boolean(jobStageInterviews.length) &&
    jobStageInterviews.reduce((isValid: boolean, interview) => {
      return (
        isValid &&
        (Boolean(interview.jobStageInterviewSeats?.length) ||
          interview.interviewType === InterviewType.Break ||
          interview.interviewType === InterviewType.DayDivider ||
          interview.interviewType === InterviewType.NextDay ||
          interview.interviewType === InterviewType.AnyTimeLater)
      );
    }, true);

  const hasInterviewer =
    Boolean(jobStageInterviewSeats.length) &&
    jobStageInterviewSeats.reduce((isValid: boolean, seat) => {
      if (seat.linkedSeat) return isValid;

      if (seat.freeformSeat) {
        return isValid && Boolean(seat.freeformSeat?.jobStageInterviewSeatEmployees?.length);
      }

      if (seat.moduleSeat) {
        return isValid && Boolean(seat.moduleSeat?.interviewModuleId);
      }

      return false;
    }, true);

  if ((!hasInterviewSeats || !hasInterviewer) && !allowEmptyInterviews) {
    return {
      isValid: false,
      title: INCOMPLETE_INTERVIEW_PLAN,
      error:
        'The interview plan for this candidate is incomplete. Please check that each interview has at least one interviewer assigned.',
    };
  }

  /**
   * Ensure that not all interviews are hidden from candidate
   */

  const hasOnlyInterviewsHiddenFromCandidate = jobStageInterviews.reduce((hasHidden, interview) => {
    return hasHidden && Boolean(interview.isHiddenFromCandidate);
  }, true);

  if (!allowAllInterviewsHiddenFromCandiate && hasOnlyInterviewsHiddenFromCandidate) {
    return {
      isValid: false,
      title: INTERVIEW_PLAN_WITH_HIDDEN_EVENTS,
      error: 'All interviews are hidden from candidate',
    };
  }

  /**
   * Ensure that there are atleast some interviews visible to candidate
   */

  const hasInterviewsHiddenFromCandidate = jobStageInterviews.reduce((hasHidden, interview) => {
    return hasHidden || Boolean(interview.isHiddenFromCandidate);
  }, false);

  if (!allowSomeInterviewsHiddenFromCandiate && hasInterviewsHiddenFromCandidate) {
    return {
      isValid: false,
      title: INTERVIEW_PLAN_WITH_HIDDEN_EVENTS,
      error: 'Some interviews are hidden from candidate',
    };
  }

  const hasDynamicBreaks = jobStageInterviews.reduce((hasDynamicBreak, interview) => {
    return hasDynamicBreak || interview.interviewType === InterviewType.AnyTimeLater;
  }, false);

  if (!allowDynamicBreaks && hasDynamicBreaks) {
    return {
      isValid: false,
      title: INTERVIEW_PLAN_WITH_DYNAMIC_BREAKS,
      error: 'Dynamic breaks are not allowed in interview plan',
    };
  }

  return {
    isValid: true,
    title: '',
    error: 'Is valid',
  };
};

useIsInterviewPlanValid.fragments = {
  jobStage: gql`
    fragment useIsInterviewPlanValid_jobStage on JobStage {
      id
      jobStageInterviewGroups {
        id
        jobStageInterviews {
          id
          interviewType
          isHiddenFromCandidate
          jobStageInterviewSeats {
            id
            freeformSeat {
              jobStageInterviewSeatEmployees {
                employeeId
                employee {
                  id
                  fullName
                  isArchived
                  isDirectoryDisabled
                  isAtsDisabled
                  hasAtsId
                }
              }
            }
            moduleSeat {
              interviewModuleId
            }
            linkedSeat {
              linkedJobStageInterviewSeatId
            }
          }
        }
      }
    }
  `,
};

export default useIsInterviewPlanValid;
