import { SvgIcon, SvgIconProps } from './SvgIcon';

export const LockIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox={props.viewBox ? props.viewBox : '0 0 12 16'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1.75C5.0335 1.75 4.25 2.5335 4.25 3.5V4.26758L7.75 4.25846V3.5C7.75 2.5335 6.9665 1.75 6 1.75ZM2.75 3.5V4.27148L2.49414 4.27215C1.25379 4.27538 0.25 5.28179 0.25 6.52214V12.4961C0.25 13.741 1.26094 14.7493 2.50586 14.7461L9.50586 14.7278C10.7462 14.7246 11.75 13.7182 11.75 12.4779L11.75 6.50391C11.75 5.25898 10.7391 4.25068 9.49414 4.25392L9.25 4.25456V3.5C9.25 1.70507 7.79493 0.25 6 0.25C4.20507 0.25 2.75 1.70507 2.75 3.5ZM6 11C6.82843 11 7.5 10.3284 7.5 9.5C7.5 8.67157 6.82843 8 6 8C5.17157 8 4.5 8.67157 4.5 9.5C4.5 10.3284 5.17157 11 6 11Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
