import React, { useEffect, useMemo } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { EyeCrossedIcon } from '@modernloop/shared/icons';
import { LinearProgress, Stack as MuiStack, Theme as MuiTheme, Typography } from '@mui/material';
import { parseISO } from 'date-fns';

import { InterviewerContentCard_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import Divider from 'src/components/Divider';
import Editor from 'src/components/Editor';
import Paper from 'src/components/Paper';
import Stack from 'src/components/Stack';
import TextField from 'src/components/TextField';
import { MeetingRoomIcon, VideoIcon } from 'src/components/icons';
import Label from 'src/components/label';

import TemplateType from 'src/constants/TemplateType';

import TemplateSelect from 'src/entities/Template/TemplateSelect';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { updateLastUsedInterviewerEventTemplateID } from 'src/slices/persist';
import { updateInterviewerTemplateID } from 'src/slices/scheduling';

import {
  updateInterviewerContentDescription,
  updateInterviewerContentSummary,
} from 'src/store/actions/schedule-communications';
import { getInitialInterviewerEventTemplateID, getInterviewerEventContents } from 'src/store/selectors/scheduling';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { EmployeePrefName } from 'src/utils/api/employee';
import { RichInterviewer } from 'src/utils/api/getScheduleOptions';
import { renderTimeRangeWithDayMthHrMinTz } from 'src/utils/renderTimeRange';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';
import MeetingRoom from 'src/views-new/ScheduleFlow/Steps/Communications/InterviewerCommunicationCards/MeetingRoom';
import { useCommunicationTemplatesPref } from 'src/views-new/ScheduleFlow/Steps/Communications/useCommunicationTemplatesPref';

import { useDispatch, useSelector } from 'src/store';

import InterviewerRow from './InterviewerRow';
import useInterviewEventTemplateContent from './useInterviewEventTemplateContent';

type Fragments = {
  interviewPlan: InterviewerContentCard_InterviewPlanFragment | undefined;
};

type Props = {
  scheduleId: string;
  eventId: string;
};

const useSxProps = (loading) => {
  return useMemo(() => {
    return {
      root: { marginBottom: '8px' },
      editor: {
        '& .ql-editor': {
          height: 500,
        },
        opacity: loading ? 0.5 : 1,
      },
    };
  }, [loading]);
};

const InterviewerContentCard: FCWithFragments<Fragments, Props> = ({ interviewPlan, scheduleId, eventId }) => {
  const dispatch = useDispatch();
  const schedule = useScheduleWithoutBreaks(scheduleId);
  const event = schedule?.events.find((e) => e.id === eventId);

  const scheduleFlowData = useScheduleFlowData();

  const showEmployeePrefData = useFlag('user_last_used_template_ids_employee_pref');

  const {
    stepScheduleContent: { isWaiting },
  } = useSelector((state) => state.scheduling);

  const { candidateTimezone } = scheduleFlowData;

  const [content] = useSelector((state) => {
    const interviewerEventContents = getInterviewerEventContents(state);
    if (!interviewerEventContents) return [undefined];
    return [interviewerEventContents.find((c) => c.event.id === eventId)];
  });
  const slotId = content?.event.slotId;
  const [templateID] = useSelector((state) => (slotId ? [state.scheduling.interviewerTemplateIDs[slotId]] : []));
  const initialTemplateId = useSelector(getInitialInterviewerEventTemplateID) || null;
  const jobStageSettings = useSelector((state) => state.scheduling.jobStageSettings);

  /** * START ** */ /*
   * I think this is not the right place to default to a perticular templateId, Decision making should be avoided here.
   * getScheduleContent will be called few extra times due to this
   * Need to discuss this with Cam before we decided to refactor a bit
   * -- Just noting I've updated this logic a bit to account for the removal of the per-interview template selector, and the new
   * field for invite template exposed in job setup v2
   */

  useEffect(() => {
    const templateId = jobStageSettings?.interviewerEventTemplateId || initialTemplateId;
    if (!slotId || !templateId) return;
    dispatch(updateInterviewerTemplateID(eventId, slotId, templateId));
  }, [dispatch, eventId, initialTemplateId, jobStageSettings?.interviewerEventTemplateId, slotId]);
  /** * END ** */

  const handleUpdateLastUsedInterviewerEventTemplateId = useCommunicationTemplatesPref(
    EmployeePrefName.STR_INTERVIEWER_CALENDAR_INVITE_TEMPLATE_ID
  );

  const loading = useInterviewEventTemplateContent(
    { interviewPlan },
    { eventId, templateId: templateID || undefined, slotId }
  );

  const sxProps = useSxProps(loading);

  return (
    <Paper color="alternate" sx={sxProps.root}>
      <Stack direction="column" spacing={1}>
        {event?.isHiddenFromCandidate && (
          <ConditionalThemeProvider>
            <MuiStack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{
                backgroundColor: (muiTheme: MuiTheme) => muiTheme.palette.background.paperHeader,
                borderBottom: (muiTheme: MuiTheme) => `1px solid ${muiTheme.palette.borderState.default}`,
                padding: 1,
                margin: -1.5,
                marginBottom: 0,
              }}
            >
              <EyeCrossedIcon />
              <Typography variant="subtitle2" color="text.secondary">
                Hidden from candidate
              </Typography>
            </MuiStack>
          </ConditionalThemeProvider>
        )}
        <Label variant="body" fontWeight={600}>
          {content?.event.name}
        </Label>
        {content && content.event.startAt && content.event.endAt && (
          <Label variant="captions" fontWeight={400}>
            {renderTimeRangeWithDayMthHrMinTz(content?.event.startAt, content?.event.endAt, candidateTimezone)}
          </Label>
        )}
        {content?.event.interviewers.map((i) => {
          return (
            <InterviewerRow
              // Remove typecast after useAtsUnification is released and change interviewers to RichInterviewer[]
              key={i.employeeId || (i as unknown as RichInterviewer)?.employeeId}
              employeeId={i.employeeId || (i as unknown as RichInterviewer)?.employeeId}
              fullName={(i as unknown as RichInterviewer)?.employee?.fullName || ''}
              slackImageUrl={(i as unknown as RichInterviewer)?.employee?.slackImageUrl}
              timezone={(i as unknown as RichInterviewer)?.employee?.timezone ?? 'UTC'}
              scheduleId={scheduleId}
              interviewEventId={content.event.id}
              eventStartAt={content?.event.startAt ? parseISO(content?.event.startAt) : null}
              eventEndAt={content?.event.endAt ? parseISO(content?.event.endAt) : null}
              interviewersCount={content?.event.interviewers.length}
            />
          );
        })}
        {content?.location && (
          <Stack wrap="nowrap" spacing={1}>
            <VideoIcon fontSize={20} />
            <Label variant="captions" fontWeight={400}>
              {content?.location}
            </Label>
          </Stack>
        )}
        <Divider />
        <Label icon={<MeetingRoomIcon />}>Meeting room</Label>
        {content && (
          <MeetingRoom
            scheduleId={scheduleId}
            startAt={assertIsoTimestamp(new Date(content.event.startAt).toISOString())}
            endAt={assertIsoTimestamp(new Date(content.event.endAt).toISOString())}
            interviewEventId={content.event.id}
          />
        )}
        <Divider />
        <Label variant="captions" fontWeight={600}>
          Template:
        </Label>

        <TemplateSelect
          minimalUX
          useSelectButton
          onSelect={(value) => {
            if (!slotId) return;
            dispatch(updateInterviewerTemplateID(eventId, slotId, value || ''));

            if (value && showEmployeePrefData) {
              handleUpdateLastUsedInterviewerEventTemplateId(value);
            } else {
              dispatch(updateLastUsedInterviewerEventTemplateID(value || null));
            }
          }}
          selectedTemplateId={templateID || undefined}
          types={[TemplateType.InterviewerInvite]}
        />

        {loading && <LinearProgress sx={{ width: '100%' }} />}

        <Stack direction="column" spacing={1}>
          <TextField
            fullWidth
            disabled={isWaiting || loading}
            variant="outlined"
            label=""
            required
            id="standard-required"
            value={(content && content.summary) || ''}
            onChange={(inputEvent) => {
              dispatch(updateInterviewerContentSummary(scheduleId, inputEvent.target.value, eventId));
            }}
          />
          <Editor
            readOnly={loading}
            onChange={(value) => {
              dispatch(updateInterviewerContentDescription(scheduleId, value, eventId));
            }}
            value={(content && content.description) || ''}
            sx={sxProps.editor}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

InterviewerContentCard.fragments = {
  interviewPlan: gql`
    ${useInterviewEventTemplateContent.fragments.interviewPlan}
    fragment InterviewerContentCard_interviewPlan on JobStage {
      id
      ...useInterviewEventTemplateContent_interviewPlan
    }
  `,
};

export default InterviewerContentCard;
