import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, DropResult, Droppable, DroppableProvided } from 'react-beautiful-dnd';

import { Box, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';

import { DateTimeRangeInput, InterviewType } from 'src/generated/mloop-graphql';

import { Loader } from 'src/components/HelperComponents';
import TwoColumnLayout from 'src/components/Layout/TwoColumnLayout';
import Paper from 'src/components/Paper';
import Stack from 'src/components/Stack';
import Button from 'src/components/button';
import { CaretLeftIcon } from 'src/components/icons';
import Label from 'src/components/label';

import { useFeatureFlagNumber } from 'src/hooks/feature-flag';
import { useFlag } from 'src/hooks/feature-flag/';

import { moveJobStageInterviewGroupId } from 'src/store/actions/job-stage';
import { createJobStageInterview } from 'src/store/actions/job-stage-interview';
import { createJobStagePlanConfig } from 'src/store/actions/job-stage-plan-config';
import { getJobStageById, getJobStageErrorById, getJobStageLoadingById } from 'src/store/selectors/job-stage';
import { getLeftNavExpanded } from 'src/store/selectors/ui-state';

import { Theme } from 'src/themeMui5/type';

import ScheduleIssuesButton from 'src/views-new/ScheduleFlow/Steps/Common/ScheduleIssues/ScheduleIssuesButton';
import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useDispatch, useSelector } from 'src/store';

import AtsScorecardModal from '../Scorecards/AtsScorecardModal';

import InterviewConfig from './InterviewConfig';
import InterviewGroup from './InterviewGroup';
import InterviewPlanPreview from './InterviewPlanPreview';
import useSaveInterviewPlan from './useSaveInterviewPlan';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type BaseInterviewPlanProps = {
  jobStageId?: string;
  interviewPlanJobStageId: string;
  schedulable: boolean;
  scheduleFlowType: ScheduleFlowType;
  autoSave?: boolean;
  readonly?: boolean;
  candidateAvailabilities?: DateTimeRangeInput[];
  footer?: React.ReactNode;
  onSave?: () => Promise<void>;
  onAutoSaveComplete?: (complete: boolean) => void;
  gotoNextStep?: () => void;
  gotoPrevStep?: () => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type InterviewPlanProps = Omit<BaseInterviewPlanProps, 'scheduleFlowType'>;

const useSxProps = () => {
  return useMemo(() => {
    return {
      noInterviewPlanWrapper: {
        display: 'flex',
        height: '150px',
        backgroundColor: (theme: Theme) => theme.grey.alpha.min,
        alignItems: 'center',
        justifyContent: 'center',
        margin: (theme: Theme) => `0 ${theme.spacing(3)}`,
      },
    };
  }, []);
};

export const BaseInterviewPlan = ({
  jobStageId,
  interviewPlanJobStageId,
  schedulable,
  scheduleFlowType,
  autoSave,
  readonly,
  candidateAvailabilities,
  footer,
  onSave,
  onAutoSaveComplete,
  gotoPrevStep,
  gotoNextStep,
}: BaseInterviewPlanProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const orgShuffleAbleBreak = useFlag('org-shuffle-able-break');
  const sxProps = useSxProps();

  const [showScorecardModal, setShowScorecardModal] = useState(false);

  const jobStage = useSelector((state) => getJobStageById(state, interviewPlanJobStageId));
  const jobStageLoading = useSelector((state) => getJobStageLoadingById(state, interviewPlanJobStageId));
  const jobStageError = useSelector((state) => getJobStageErrorById(state, interviewPlanJobStageId));
  const leftNavExpanded = useSelector(getLeftNavExpanded);

  const [saveInterviewPlanMutation] = useSaveInterviewPlan({ jobStageId: interviewPlanJobStageId });
  const interviewPlanAutoSaveDebounceTimeout = useFeatureFlagNumber('user_interview_plan_auto_save_debounce_timeout');

  const handleAddInterview = () => {
    setShowScorecardModal(true);
  };

  const handleUpdated = useDebouncedCallback(
    () => {
      if (onSave) {
        onSave();
        return;
      }

      if (!autoSave) return;

      if (onAutoSaveComplete) onAutoSaveComplete(false);

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/catch-or-return
      saveInterviewPlanMutation() // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line promise/always-return
        .then(() => {
          enqueueSnackbar('Saved interview plan.', {
            variant: 'success',
            preventDuplicate: true,
          });
        })
        .finally(() => {
          if (onAutoSaveComplete) onAutoSaveComplete(true);
        });
    },
    onSave ? 100 : interviewPlanAutoSaveDebounceTimeout
  );

  const handleAddBreak = () => {
    dispatch(createJobStageInterview(interviewPlanJobStageId, InterviewType.Break, undefined, orgShuffleAbleBreak));
    handleUpdated();
  };

  const handleScorecardSelectClose = () => {
    setShowScorecardModal(false);
    handleUpdated();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    dispatch(moveJobStageInterviewGroupId(interviewPlanJobStageId, result.draggableId, result.destination.index));
    handleUpdated();
  };

  if (jobStageLoading)
    return (
      <Box mt={4}>
        <Loader loading={jobStageLoading} size={20} />
      </Box>
    );
  if (jobStageError) return <Label>Error: {jobStageError}</Label>;
  if (!jobStage) return null;

  const left = (
    <Stack direction="column" spacing={2} wrap="nowrap">
      {schedulable && (
        <Stack direction="column" spacing={2}>
          <Stack justifyContent="space-between">
            <Button startIcon={<CaretLeftIcon />} variant="outlined" label="Availability" onClick={gotoPrevStep} />
            <ScheduleIssuesButton />
          </Stack>
        </Stack>
      )}

      {!jobStage.jobStageInterviewGroupIds.length && (
        <Paper sx={sxProps.noInterviewPlanWrapper}>
          <Label color="mid-contrast-grey" variant="small-button">
            Add an interview to start building your candidate&apos;s custom interview plan.
          </Label>
        </Paper>
      )}

      {jobStage.jobStageInterviewGroupIds.length && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical" isDropDisabled={readonly}>
            {(droppableProvided: DroppableProvided) => (
              <Grid
                container
                direction="column"
                spacing={2}
                wrap="nowrap"
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {jobStage.jobStageInterviewGroupIds.map((groupId, i) => {
                  return (
                    <InterviewGroup
                      jobStageId={interviewPlanJobStageId}
                      id={groupId}
                      count={jobStage.jobStageInterviewGroupIds.length}
                      key={groupId}
                      index={i}
                      schedulable={schedulable}
                      readonly={readonly}
                      candidateAvailabilities={candidateAvailabilities}
                      onUpdated={handleUpdated}
                    />
                  );
                })}
                {droppableProvided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {!readonly && (
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item container xs={10} spacing={1}>
            <Grid item>
              <Button label="Add interview" variant="outlined" size="medium" onClick={() => handleAddInterview()} />
            </Grid>
            <Grid item>
              <Button label="Add break" variant="outlined" size="medium" onClick={handleAddBreak} />
            </Grid>
          </Grid>
        </Grid>
      )}

      {footer}

      {schedulable && <InterviewConfig jobStageId={interviewPlanJobStageId} />}
    </Stack>
  );

  const right = (
    <InterviewPlanPreview
      jobStageId={jobStageId}
      interviewPlanJobStageId={interviewPlanJobStageId}
      schedulable={schedulable}
      scheduleFlowType={scheduleFlowType}
      gotoNextStep={gotoNextStep}
    />
  );
  return (
    <>
      {!schedulable && left}
      {schedulable && (
        <TwoColumnLayout leftComponent={left} rightComponent={right} mobileBreakpoint={leftNavExpanded ? 'md' : 'sm'} />
      )}
      {showScorecardModal ? (
        <AtsScorecardModal jobStageId={interviewPlanJobStageId} onClose={handleScorecardSelectClose} />
      ) : null}
    </>
  );
};

// We need a separate component just to invoke useFetchInterviewPlan.
// Because useFetchInterviewPlan() updates redux store which causes component re-render.
// And if we call useFetchInterviewPlan() and useSelector() in same component then it ends up
// in a infinite loop of fetching and re-rendering.
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const InterviewPlan = ({
  jobStageId,
  interviewPlanJobStageId,
  schedulable,
  autoSave,
  readonly,
  footer,
  candidateAvailabilities,
  onSave,
  gotoPrevStep,
  gotoNextStep,
}: InterviewPlanProps): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createJobStagePlanConfig(interviewPlanJobStageId));
  }, [dispatch, interviewPlanJobStageId]);

  return (
    <BaseInterviewPlan
      jobStageId={jobStageId}
      interviewPlanJobStageId={interviewPlanJobStageId}
      schedulable={schedulable}
      scheduleFlowType={ScheduleFlowType.SCHEDULE}
      autoSave={autoSave}
      onSave={onSave}
      gotoNextStep={gotoNextStep}
      gotoPrevStep={gotoPrevStep}
      readonly={readonly}
      footer={footer}
      candidateAvailabilities={candidateAvailabilities}
    />
  );
};

export default InterviewPlan;
