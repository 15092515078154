/**
 * Check if open browser is chrome
 */
export function isChrome(): boolean {
  return window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
}

export enum OS {
  MacOS = 'Mac OS',
  iOS = 'iOS',
  Windows = 'Windows',
  Android = 'Android',
  Linux = 'Linux',
}

export function getOS() {
  const { userAgent } = window.navigator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const platform = (window.navigator as any)?.userAgentData?.platform || window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os: OS = OS.MacOS;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OS.MacOS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OS.iOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OS.Windows;
  } else if (/Android/.test(userAgent)) {
    os = OS.Android;
  } else if (/Linux/.test(platform)) {
    os = OS.Linux;
  }

  return os;
}
