import { alpha } from '@mui/material';

export const whitescale = {
  0: '#FFFFFF',
  10: alpha('#FFFFFF', 0.95),
  20: alpha('#FFFFFF', 0.91),
  25: alpha('#FFFFFF', 0.87),
  30: alpha('#FFFFFF', 0.82),
  35: alpha('#FFFFFF', 0.76),
  40: alpha('#FFFFFF', 0.71),
  50: alpha('#FFFFFF', 0.6),
  60: alpha('#FFFFFF', 0.49),
  70: alpha('#FFFFFF', 0.38),
  80: alpha('#FFFFFF', 0.26),
  90: alpha('#FFFFFF', 0.13),
  95: alpha('#FFFFFF', 0.08),
  98: alpha('#FFFFFF', 0.04),
  99: alpha('#FFFFFF', 0.02),
  100: alpha('#FFFFFF', 1),
};
