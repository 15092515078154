import { SvgIcon, SvgIconProps } from './SvgIcon';

export const NoneIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 10C4.75 7.10051 7.10051 4.75 10 4.75C10.8119 4.75 11.5787 4.93376 12.2631 5.26127C12.6367 5.44007 13.0846 5.28213 13.2634 4.9085C13.4422 4.53486 13.2842 4.08702 12.9106 3.90822C12.0284 3.48604 11.0407 3.25 10 3.25C6.27208 3.25 3.25 6.27208 3.25 10C3.25 11.0407 3.48604 12.0284 3.90822 12.9106C4.08702 13.2842 4.53486 13.4422 4.9085 13.2634C5.28213 13.0845 5.44007 12.6367 5.26127 12.2631C4.93376 11.5787 4.75 10.8119 4.75 10ZM16.0918 7.08942C15.913 6.71578 15.4651 6.55784 15.0915 6.73665C14.7179 6.91545 14.5599 7.36329 14.7387 7.73692C15.0662 8.42128 15.25 9.18813 15.25 10C15.25 12.8995 12.8995 15.25 10 15.25C9.18813 15.25 8.42128 15.0662 7.73692 14.7387C7.36329 14.5599 6.91545 14.7179 6.73665 15.0915C6.55784 15.4651 6.71578 15.913 7.08942 16.0918C7.9716 16.514 8.95929 16.75 10 16.75C13.7279 16.75 16.75 13.7279 16.75 10C16.75 8.95929 16.514 7.9716 16.0918 7.08942ZM16.5303 4.53033C16.8232 4.23744 16.8232 3.76256 16.5303 3.46967C16.2374 3.17678 15.7626 3.17678 15.4697 3.46967L3.46967 15.4697C3.17678 15.7626 3.17678 16.2374 3.46967 16.5303C3.76256 16.8232 4.23744 16.8232 4.53033 16.5303L16.5303 4.53033Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
