import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { ApolloError } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { CircularProgress } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { getEventTimeRange } from '@modernloop/shared/datetime';

import Stack from 'src/components/Stack';
import Label from 'src/components/label';

import IsoTimestamp from 'src/types/IsoTimestamp';

import { AxiosError } from 'src/utils/axios';

import Alert from './Alert';

const useStyles = makeStyles(() =>
  createStyles({
    disabled: {
      pointerEvents: 'none',
      opacity: '0.5',
    },
  })
);

export const Error = ({ error }: { error?: ApolloError | AxiosError | string }): JSX.Element | null => {
  if (error) {
    let errorMessage = 'Something went wrong please try again later.';
    if (error instanceof ApolloError) {
      errorMessage = error.message;
    }
    return (
      <Stack justifyContent="center">
        <Alert status="error" alignItems="center" title={typeof error === 'string' ? error : errorMessage} />
      </Stack>
    );
  }
  return null;
};

/**
 * @deprecated
 * Use directly from mloop-shared
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
export const Loader = ({ loading, size = 30 }: { loading: boolean; size?: number }): JSX.Element | null => {
  if (loading) {
    return (
      <Stack justifyContent="center">
        <CircularProgress size={size} />
      </Stack>
    );
  }
  return null;
};

/**
 * @deprecated
 * Use directly from mloop-shared
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/validate-component-definition.cjs
export const CanDisable = ({ children, disabled }: { children: ReactNode; disabled: boolean }): JSX.Element => {
  const classes = useStyles();
  return <div className={disabled ? classes.disabled : ''}>{children}</div>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, react/no-multi-comp, modernloop/validate-component-definition.cjs
export const ReduxContextProviderWrap = ({ children, store }) => <Provider store={store}>{children}</Provider>;

/* 
  Error component for use in Candidate Portal
*/
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
export const PublicError = ({ error }: { error?: ApolloError | AxiosError | string }): JSX.Element | null => {
  if (error) {
    return (
      <Stack style={{ padding: '0px', marginTop: '2px' }}>
        <Label variant="captions" color="error">
          {typeof error === 'string' ? error : 'Something went wrong please try again later.'}
        </Label>
      </Stack>
    );
  }
  return null;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/validate-component-definition.cjs
export const TimeRange = ({
  startAt,
  endAt,
  timezone,
}: {
  startAt: IsoTimestamp;
  endAt: IsoTimestamp;
  timezone: string;
}): JSX.Element | null => {
  const timeRange = getEventTimeRange(startAt, endAt, timezone);
  return <>{timeRange}</>;
};
