import React, { FC } from 'react';

import { useFlag } from '@modernloop/shared/feature-flag';
import { Stack } from '@mui/material';

import Alert from 'src/components/Alert';
import Link from 'src/components/Link';

import { Routes } from 'src/constants/routes';

import { useOrgPrefStringArray } from 'src/hooks/api/org';

import { OrganizationSettingsTab, getOrganizationSettingUrls } from 'src/urls/getOrganizationSettingUrls';

import { CalendarFreeBusyStatus, Event, EventTag } from 'src/utils/api/getEmployeeCalendarEvents';
import { OrgPrefName } from 'src/utils/api/org';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface EventInfoAlertProps {
  event: Event;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const EventInfoAlert: FC<EventInfoAlertProps> = ({ event }) => {
  const [oooConflictPhrasesArray] = useOrgPrefStringArray(OrgPrefName.ARR_OUT_OF_OFFICE_PHRASES);

  const [softConflictPhrasesArray] = useOrgPrefStringArray(OrgPrefName.ARR_SOFT_CONFLICT_PHRASES);

  const newOrgSettingsSidebar = useFlag('user_new_org_settings_sidebar');

  const [hardConflictPhrasesArray] = useOrgPrefStringArray(OrgPrefName.ARR_HARD_CONFLICT_PHRASES);

  const [recruitingBlockPhrasesArray] = useOrgPrefStringArray(OrgPrefName.ARR_RECRUITING_BLOCK_PHRASES);

  const getKeyWord = (keywords?: string[]): string => {
    if (keywords?.length) {
      for (const word of keywords) {
        if (event?.title?.toLowerCase()?.includes(word.toLowerCase())) {
          return word;
        }
      }
    }
    return '';
  };

  const getAlertMessages = (): JSX.Element[] => {
    const schedulingUrl = newOrgSettingsSidebar
      ? getOrganizationSettingUrls({
          tab: OrganizationSettingsTab.SCHEDULING,
        })
      : Routes.SettingsScheduling;

    const alerts: JSX.Element[] = [];
    if (event.is_all_day) return alerts;

    let keyWord = '';

    if (event.event_tag?.includes(EventTag.OOO)) {
      keyWord = getKeyWord(oooConflictPhrasesArray as string[]);
      if (keyWord) {
        alerts.push(
          <Alert
            disableIcon
            status="error"
            title={`Out of office keyword match: "${keyWord}"`}
            caption={
              <Stack direction="column">
                <>This event’s title matches a keyword for events to avoid scheduling over.</>
                <Link variant="captions" color="info" target="_blank" href={schedulingUrl}>
                  Edit keywords
                </Link>
              </Stack>
            }
          />
        );
      }
    }

    if (event.event_tag?.includes(EventTag.UNAVAILABLE_TIME_BLOCK)) {
      keyWord = getKeyWord(hardConflictPhrasesArray as string[]);
      if (keyWord) {
        alerts.push(
          <Alert
            disableIcon
            status="error"
            title={`Avoid keyword match: "${keyWord}"`}
            caption={
              <Stack direction="column">
                <>This event’s title matches a keyword for events to avoid scheduling over.</>
                <Link variant="captions" color="info" target="_blank" href={schedulingUrl}>
                  Edit keywords
                </Link>
              </Stack>
            }
          />
        );
      }
    }

    if (event.event_tag?.includes(EventTag.RECRUITING_BLOCK)) {
      keyWord = getKeyWord(recruitingBlockPhrasesArray as string[]);
      if (keyWord) {
        alerts.push(
          <Alert
            disableIcon
            status="neutral"
            title={`Recruiting block keyword match: "${keyWord}"`}
            caption={
              <Stack direction="column">
                <>This event’s title matches a keyword for events preferred to be scheduled over.</>
                <Link variant="captions" color="info" target="_blank" href={schedulingUrl}>
                  Edit keywords
                </Link>
              </Stack>
            }
          />
        );
      }
    }

    if (event.event_tag?.includes(EventTag.AVAILABLE_TIME_BLOCK)) {
      keyWord = getKeyWord(softConflictPhrasesArray as string[]);
      if (keyWord) {
        alerts.push(
          <Alert
            disableIcon
            status="neutral"
            title={`Available keyword match: "${keyWord}"`}
            caption={
              <Stack direction="column">
                <>This event’s title matches a keyword for events available to be scheduled over.</>
                <Link variant="captions" color="info" target="_blank" href={schedulingUrl}>
                  Edit keywords
                </Link>
              </Stack>
            }
          />
        );
      }
    }

    if (event.free_busy === CalendarFreeBusyStatus.STATUS_FREE && !alerts.length) {
      alerts.push(
        <Alert
          status="success"
          title="Free time"
          caption="This event’s availability is marked as free. It’s likely this event is okay to schedule over."
        />
      );
    }

    return alerts;
  };

  const alerts = getAlertMessages();

  if (!alerts.length) return null;

  return (
    <Stack direction="column" spacing={2}>
      {alerts.map((alert) => alert)}
    </Stack>
  );
};

export default EventInfoAlert;
