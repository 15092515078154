/* eslint-disable max-lines */
import React, { CSSProperties, MutableRefObject, useEffect, useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { CircularProgress, ListItem } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { alpha, createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  UseAutocompleteProps,
  useAutocomplete,
} from '@material-ui/lab';
import { Search } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { AutocompleteInputChangeReason } from '@mui/material';

import Alert from 'src/components/Alert';
import IconButton from 'src/components/IconButton';
import Paper from 'src/components/Paper';
import Stack from 'src/components/Stack';
import VirtualList, { VirtualListOnScrollParams } from 'src/components/VirtualList';
import { CrossIcon } from 'src/components/icons';
import Label from 'src/components/label';
import Tabs, { TabProps } from 'src/components/tabs';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/theme/type';

import { Theme as ThemeV5 } from 'src/themeMui5/type';

// The type of groupedOptions returned by useAutocomplete hook is wrong
// https://github.com/mui-org/material-ui/issues/23846
type AutocompleteGroupedOption<T> = {
  key: number;
  index: number;
  group: string;
  options: T[];
};

const DEFAULT_ITEM_HEIGHT = 48;

export type FilterListInterface = {
  clearSelected: () => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type FilterListProps<TData, TabType extends string | number = string> = BaseProps & {
  autoFocus?: boolean;
  activeTabId?: TabType;
  placeholderText?: string;
  tabs?: TabProps<TabType>[];
  loading?: boolean;
  wrapInPaper?: boolean;
  errorMsg?: string;
  backButton?: React.ReactNode;
  actions?: React.ReactNode;
  subActions?: React.ReactNode;
  header?: React.ReactNode;
  optionWrapperClassName?: string;
  onTabChange?: (option: TabProps<TabType>) => void;
  getListHeight?: (options: TData[]) => number;
  filterListInterfaceRef?: MutableRefObject<FilterListInterface | null>;
  searchBarWidth?: string;

  // Purpose of this function is determine if user has scroll to end
  // and fetch more data if required.
  onScrollToEnd?: () => void;

  // UseAutocompleteProps re-exposed
  options: TData[];
  values?: TData[];
  getOptionLabel: (option: TData) => string;
  groupBy?: (option: TData) => string;
  filterOptions?: (options: TData[], state: FilterOptionsState<TData>) => TData[];
  getOptionDisabled?: UseAutocompleteProps<TData, true, undefined, undefined>['getOptionDisabled'];
  renderOption?: (options: TData, state: AutocompleteRenderOptionState, index?: number) => React.ReactNode;
  renderGroup?: (params: AutocompleteRenderGroupParams, groupedOptions: TData[]) => React.ReactNodeArray;
  getChildSize?: (child: React.ReactNode, index: number) => number;
  onChange?: UseAutocompleteProps<TData, true, undefined, undefined>['onChange'];
  onInputChange?: UseAutocompleteProps<TData, true, undefined, undefined>['onInputChange'];
  getOptionSelected?: UseAutocompleteProps<TData, true, undefined, undefined>['getOptionSelected'];
  onAutocompleteClose?: UseAutocompleteProps<TData, true, undefined, undefined>['onClose'];
  className?: string;
  zeroState?: JSX.Element;
  hideSearch?: boolean;
  filterSelectedOptions?: UseAutocompleteProps<TData, true, undefined, undefined>['filterSelectedOptions'];
  disableHover?: boolean;
};

const useOptionStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: (props: { disableHover?: boolean }) => ({
      borderRadius: '6px',
      paddingLeft: props.disableHover ? 0 : theme.spacing(1.5),
      paddingRight: props.disableHover ? 0 : theme.spacing(1.5),
      '&[aria-disabled="true"]': {
        opacity: theme.palette.action.disabledOpacity,
        pointerEvents: 'none',
      },
      '&[aria-selected="true"]': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
      '&[data-focus="true"]': {
        backgroundColor: props.disableHover ? 'none' : theme.palette.action.hover,
        cursor: props.disableHover ? 'unset' : 'pointer',
      },
    }),
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stack: {
      height: '100%',
    },
    startAdornment: {
      padding: `0 ${theme.spacing(1)}px`,
    },
    optionsContainer: {
      minHeight: DEFAULT_ITEM_HEIGHT,
      height: '100%',
      '& ul': {
        padding: 0,
        margin: 0,
      },
    },
  })
);

const FilterList = <TData, TabType extends string | number = string>({
  dataTestId,
  autoFocus,
  activeTabId,
  placeholderText,
  tabs,
  loading,
  wrapInPaper = true,
  errorMsg,
  backButton,
  actions,
  subActions,
  header,
  optionWrapperClassName,
  onTabChange,
  getListHeight,
  onScrollToEnd,
  filterListInterfaceRef,
  searchBarWidth = '100%',

  // UseAutocompleteProps re-exposed
  options,
  values,
  getOptionLabel,
  groupBy,
  filterOptions,
  getOptionDisabled,
  renderOption,
  renderGroup,
  getChildSize,
  onChange,
  onInputChange,
  getOptionSelected,
  onAutocompleteClose,
  className,
  zeroState,
  hideSearch = false,
  filterSelectedOptions,
  disableHover,
}: FilterListProps<TData, TabType>): JSX.Element => {
  const provideInputValue = useFlag('user_provide_input_value_to_filterselect');

  const classes = useStyles();
  const optionClasses = useOptionStyles({ disableHover });

  const virtualListInnerRef = useRef<HTMLElement>(null);
  const [listHeight, setListHeight] = useState<number>(DEFAULT_ITEM_HEIGHT);
  const [currentInputValue, setCurrentInputValue] = useState<string>('');

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  const onInputChangeWrapper = (event: React.ChangeEvent, value: string, reason: AutocompleteInputChangeReason) => {
    // 'reset' is the reason when user selects an option from the list,
    //  we don't want to reset the input value in this case
    if (reason !== 'reset') setCurrentInputValue(value);

    if (onInputChange) onInputChange(event, value, reason);
  };

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions, inputValue, getClearProps } =
    useAutocomplete({
      autoHighlight: false,
      disableListWrap: true,
      filterOptions,
      getOptionDisabled,
      getOptionLabel,
      inputValue: provideInputValue ? currentInputValue : undefined,
      groupBy,
      multiple: true,
      onChange,
      open: true,
      options,
      value: values,
      getOptionSelected,
      onInputChange: provideInputValue ? onInputChangeWrapper : onInputChange,
      clearOnBlur: false,
      onClose: onAutocompleteClose,
      filterSelectedOptions,
    });

  useEffect(() => {
    if (!filterListInterfaceRef) return;
    const { onClick } = getClearProps() as unknown as { onClick: () => void };
    const filterListInterface: FilterListInterface = { clearSelected: onClick };
    filterListInterfaceRef.current = filterListInterface;
  }, [filterListInterfaceRef, getClearProps]);

  // Sometimes it is possible that user has a big screen and the number of records passed
  // on initial render are taking less vertical space than that available,
  // in this case we notify the enclosing component that there is still more
  // space that can be used to show records by invoking `onScrollToEnd()`
  // Also will run when the options passed to the list are updated
  useEffect(() => {
    if (!virtualListInnerRef.current) return;
    if (listHeight >= virtualListInnerRef.current.clientHeight && onScrollToEnd) onScrollToEnd();
  }, [listHeight, onScrollToEnd, options]);

  const handleClearSearch = (event: React.MouseEvent) => {
    if (provideInputValue) {
      setCurrentInputValue('');
      if (onInputChange) onInputChange(event, '', 'clear');
      return;
    }
    const { onClick } = getClearProps() as unknown as { onClick: () => void };
    if (!onClick) return;
    onClick();
  };

  const renderListOption = (option: TData, index: number) => {
    const optionProps = getOptionProps({ option, index });

    if (!renderOption) {
      return (
        <ListItem {...optionProps} className={optionWrapperClassName || optionClasses.option} disableGutters>
          <Label>{getOptionLabel(option)}</Label>
        </ListItem>
      );
    }

    return (
      <ListItem {...optionProps} className={optionWrapperClassName || optionClasses.option}>
        {renderOption(
          option,
          {
            selected: optionProps['aria-selected'],
            inputValue,
          },
          index
        )}
      </ListItem>
    );
  };

  const handleScroll = (params: VirtualListOnScrollParams) => {
    if (params.scrollDirection !== 'forward') return;
    if (!virtualListInnerRef.current) return;
    if (!onScrollToEnd) return;

    // Here 10 is a random error offset.
    if (Math.abs(virtualListInnerRef.current.clientHeight - (params.scrollOffset + listHeight)) > 10) return;

    onScrollToEnd();
  };

  const renderOptions = () => {
    if (groupedOptions.length === 0) return null;

    const itemData = groupedOptions.map((option, index) => {
      if (groupBy && renderGroup) {
        const groupOption = option as unknown as AutocompleteGroupedOption<TData>;
        return renderGroup(
          {
            key: `${groupOption.key}`,
            group: groupOption.group,
            children: groupOption.options.map((option2, index2) =>
              renderListOption(option2, groupOption.index + index2)
            ),
          },
          groupOption.options
        );
      }
      return renderListOption(option as TData, index);
    });

    let itemDataResult: React.ReactNode[] = [];
    itemData.forEach((item) => {
      if (Array.isArray(item)) {
        itemDataResult = itemDataResult.concat(...item);
      } else {
        itemDataResult.push(item);
      }
    });

    if (loading) {
      itemData.push(
        <Stack justifyContent="center">
          <CircularProgress size={24} />
        </Stack>
      );
    }

    return (
      <VirtualList
        getChildSize={
          getChildSize
            ? (_, index: number) => {
                if (getChildSize) return getChildSize(itemDataResult[index], index);
                return DEFAULT_ITEM_HEIGHT;
              }
            : undefined
        }
        onScroll={handleScroll}
        innerRef={virtualListInnerRef}
        height={getListHeight ? getListHeight(options) : listHeight}
        {...getListboxProps()}
      >
        {itemData}
      </VirtualList>
    );
  };

  let itemStyles: { [key: number]: CSSProperties } = {
    0: { flexBasis: 'unset' },
    1: { flexBasis: 'unset' },
    2: { flexBasis: 'unset', flexGrow: 1 },
  };

  if (!subActions) {
    itemStyles = { 0: { flexBasis: 'unset' }, 1: { flexGrow: 1 } };
  }

  if (header) {
    if (!subActions) {
      itemStyles[1] = { flexGrow: 1, flexBasis: 'unset' };
    } else {
      itemStyles[2] = { flexGrow: 'unset', flexBasis: 'unset' };
      itemStyles[3] = { flexBasis: 'unset', flexGrow: 1 };
    }
  }

  const jsx = (
    <div style={{ height: '100%' }} className={className} data-testid={dataTestId}>
      <Stack direction="column" spacing={1} wrap="nowrap" className={classes.stack} itemStyles={itemStyles}>
        <>
          <Stack alignItems="center" spacing={1} itemStyles={{ [backButton ? 1 : 0]: { flexGrow: 1 } }}>
            {backButton}
            {/* To hide Search display none works, but then it will break the keyboad navigation so adding height 0 & overflow hidden */}
            <div
              {...getRootProps()}
              style={hideSearch ? { height: '0px', overflow: 'hidden' } : { width: searchBarWidth }}
            >
              <Search
                autoFocus={autoFocus}
                placeholder={placeholderText}
                dataTestId="filter-list-search-input"
                InputProps={{
                  endAdornment: inputValue ? (
                    <IconButton color="min-contrast-grey" onClick={handleClearSearch}>
                      <CrossIcon />
                    </IconButton>
                  ) : undefined,
                  ...getInputProps(),
                }}
              />
            </div>
            {actions}
          </Stack>

          {errorMsg && (
            <Alert
              alignItems="center"
              sx={{ marginTop: (theme: ThemeV5) => theme.spacing(1) }}
              status="error"
              title={errorMsg}
            />
          )}
        </>

        {activeTabId && tabs && tabs.length && <Tabs activeTabId={activeTabId} tabs={tabs} onChange={onTabChange} />}
        {subActions}

        {header}

        {groupedOptions.length && (
          <div
            className={classes.optionsContainer}
            ref={(ref) => {
              if (!ref || !ref.clientHeight || ref.clientHeight === listHeight) return;
              setListHeight(ref.clientHeight);
            }}
          >
            {listHeight > 0 && renderOptions()}
          </div>
        )}
        {loading && groupedOptions.length === 0 && (
          <Stack justifyContent="center">
            <CircularProgress size={24} />
          </Stack>
        )}

        {!loading && !groupedOptions.length && zeroState}
      </Stack>
    </div>
  );

  if (!wrapInPaper) return jsx;

  return (
    <Paper color="default" sx={{ height: '100%' }}>
      {jsx}
    </Paper>
  );
};

export default FilterList;
