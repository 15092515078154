import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { CanDisable } from '@modernloop/shared/helper-components';
import { LoadWeekIcon } from '@modernloop/shared/icons';
import { getExternalErrorMessage, pluralize } from '@modernloop/shared/utils';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  ModuleLoad_ModuleMemberFragment,
  useModuleLoadInterviewModuleMemberUpdateMutation,
} from 'src/generated/mloop-graphql';

import {
  getModuleMemberEmployeePausedText,
  getModuleMemberPauseText,
  isModuleMemberEmployeePaused,
  isModuleMemberPauseIndefinitely,
  isModuleMemberPaused,
} from 'src/entities/InterviewModuleMember/utils';

import LoadLimitSelect from 'src/views-new/InterviewModuleDetails/InterviewModuleSetup/LoadLimitSelect';

import { MAX_LOAD_LIMIT } from 'src/constants';

import PauseMemberModal from '../Modals/PauseMemberModal';

import ModuleLoadPauseRow from './ModuleLoadPauseRow';

type Fragments = {
  moduleMember: ModuleLoad_ModuleMemberFragment;
};

type Props = {
  disabled: boolean;
};

const ModuleLoad: FCWithFragments<Fragments, Props> = ({ moduleMember, disabled }) => {
  const [expanded, setExpanded] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [updateWeeklyLoadLimit, { loading: moduleLoadLimitLoading }] =
    useModuleLoadInterviewModuleMemberUpdateMutation();

  const handleUpdateLoadLimit = async (value: number) => {
    try {
      await updateWeeklyLoadLimit({
        variables: {
          input: {
            employeeId: moduleMember.employeeId,
            interviewModuleId: moduleMember.interviewModuleId,
            weeklyNumInterviewLimit: value,
          },
        },
      });
      enqueueSnackbar('Load limit updated', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(getExternalErrorMessage(error), {
        variant: 'error',
      });
    }
  };

  let pausedTooltip = '';
  let loadLimitTooltip = '';
  if (moduleMember.employee && isModuleMemberEmployeePaused({ moduleMember }, null)) {
    pausedTooltip = getModuleMemberEmployeePausedText({ moduleMember }, null);
  }

  if (isModuleMemberPauseIndefinitely({ moduleMember }, null)) {
    pausedTooltip = 'Paused from module indefinitely';
  }

  if (isModuleMemberPaused({ moduleMember }, null)) {
    pausedTooltip = getModuleMemberPauseText({ moduleMember }, null);
  }

  if (!pausedTooltip) {
    loadLimitTooltip =
      moduleMember.weeklyNumInterviewLimit === MAX_LOAD_LIMIT
        ? 'Unlimited'
        : `${moduleMember.weeklyNumInterviewLimit} ${pluralize(
            'interview',
            moduleMember.weeklyNumInterviewLimit
          )} per week`;
  }

  return (
    <>
      <Accordion
        variant="outlined"
        disableGutters
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        defaultExpanded
      >
        <AccordionSummary>
          <Stack>
            <Typography variant="subtitle2">Module load</Typography>
            {!expanded && (
              <Typography variant="body2" color="text.secondary">
                {pausedTooltip || loadLimitTooltip}
              </Typography>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {expanded && !!moduleMember.employee && isModuleMemberEmployeePaused({ moduleMember }, null) && (
            <Alert severity="warning" sx={{ marginBottom: 2 }}>
              <Typography variant="body2">{getModuleMemberEmployeePausedText({ moduleMember }, null)}</Typography>
            </Alert>
          )}

          <CanDisable disabled={disabled}>
            <Stack direction="column">
              <Stack
                direction="row"
                alignItems="center"
                paddingTop={1.5}
                paddingBottom={1.5}
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" columnGap={1}>
                  <LoadWeekIcon />
                  <Typography variant="body2">Weekly module limit</Typography>
                </Stack>
                <Box width={200}>
                  <LoadLimitSelect
                    disabled={moduleLoadLimitLoading || disabled}
                    selectedValue={moduleMember.weeklyNumInterviewLimit}
                    onChange={(value) => {
                      handleUpdateLoadLimit(value);
                    }}
                  />
                </Box>
              </Stack>
              <ModuleLoadPauseRow moduleMember={moduleMember} />
            </Stack>
          </CanDisable>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

ModuleLoad.fragments = {
  moduleMember: gql`
    ${PauseMemberModal.fragments.moduleMember}
    ${ModuleLoadPauseRow.fragments.moduleMember}

    ${isModuleMemberEmployeePaused.fragments.moduleMember}
    ${isModuleMemberPaused.fragments.moduleMember}
    ${isModuleMemberPauseIndefinitely.fragments.moduleMember}
    ${getModuleMemberEmployeePausedText.fragments.moduleMember}
    ${getModuleMemberPauseText.fragments.moduleMember}
    fragment moduleLoad_moduleMember on InterviewModuleMember {
      id
      interviewModuleId
      employeeId
      weeklyNumInterviewLimit
      employee {
        id
        isPaused
        timezone
        interviewPauseDates {
          start
          end
        }
      }
      ...pauseMemberModal_moduleMember
      ...moduleLoadPauseRow_moduleMember
      ...isModuleMemberEmployeePaused_ModuleMember
      ...isModuleMembreOrEmployeePaused_ModuleMember
      ...isModuleMemberPauseIndefinitely_ModuleMember
      ...getModuleMemberEmployeePausedText_ModuleMember
      ...getModuleMemberPauseText_ModuleMember
    }
  `,
};

export const ModuleLoadInterviewModuleMemberUpdateMutation = gql`
  ${ModuleLoad.fragments.moduleMember}
  mutation ModuleLoadInterviewModuleMemberUpdate($input: InterviewModuleMemberUpdateInput!) {
    interviewModuleMemberUpdate(input: $input) {
      interviewModuleMember {
        ...moduleLoad_moduleMember
      }
    }
  }
`;

export default ModuleLoad;
