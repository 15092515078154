import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { UserOptionalIcon } from '@modernloop/shared/icons';
import { Avatar, Stack, Typography } from '@mui/material';

import { DebriefContentDetailsInterviewer_DebriefFragment, ResponseStatus } from 'src/generated/mloop-graphql';

import getResponseStatusNode from 'src/entities/common/getResponseStatusNode';

type Fragments = {
  attendee: DebriefContentDetailsInterviewer_DebriefFragment;
};

const DebriefContentDetailsInterviewer: FCWithFragments<Fragments> = ({ attendee }) => {
  return (
    <Stack direction="row" justifyContent="space-between" key={attendee.interviewerId}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar src={attendee.interviewer?.slackImageUrl || ''} alt={attendee.interviewer?.fullName || ''}>
              {attendee.interviewer?.fullName?.charAt(0)}
            </Avatar>
            <Typography variant="body2">{attendee.interviewer?.fullName}</Typography>
          </Stack>
        </Stack>

        {attendee.isOptional && <UserOptionalIcon tooltip="Optional" />}
      </Stack>

      <Stack direction="row" alignItems="center">
        {getResponseStatusNode(attendee.responseStatus || ResponseStatus.NeedsAction)}
      </Stack>
    </Stack>
  );
};

DebriefContentDetailsInterviewer.fragments = {
  attendee: gql`
    fragment DebriefContentDetailsInterviewer_debrief on ApplicationDebriefAttendee {
      applicationDebriefId
      interviewerId
      interviewer {
        id
        fullName
        slackImageUrl
      }
      responseStatus
      isOptional
    }
  `,
};

export default DebriefContentDetailsInterviewer;
