import React from 'react';

import BaseIcon, { Props } from './Icon';

const RejectIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 10C3.75 6.54822 6.54822 3.75 10 3.75C11.4556 3.75 12.795 4.24761 13.8573 5.082L5.082 13.8573C4.24761 12.795 3.75 11.4556 3.75 10ZM6.14266 14.918C7.20499 15.7524 8.54439 16.25 10 16.25C13.4518 16.25 16.25 13.4518 16.25 10C16.25 8.54439 15.7524 7.20499 14.918 6.14266L6.14266 14.918ZM10 2.25C5.71979 2.25 2.25 5.71979 2.25 10C2.25 14.2802 5.71979 17.75 10 17.75C14.2802 17.75 17.75 14.2802 17.75 10C17.75 5.71979 14.2802 2.25 10 2.25Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default RejectIcon;
