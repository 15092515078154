import React, { FC } from 'react';

import { Badge, BadgeProps } from '@mui/material';

import Avatar, { AvatarProps } from 'src/components/Avatar';

type AvatarPropsModified = Omit<AvatarProps, 'alt'>;

interface Props {
  badgeProps?: BadgeProps;
  avatarProps?: AvatarPropsModified;
  icon?: JSX.Element | null;
  src: string | undefined;
  alt: string;
  dataTestId?: string;
  children?: JSX.Element | string;
  size?: AvatarProps['size'];
}

const AvatarWithBadge: FC<Props> = ({
  dataTestId,
  badgeProps,
  icon,
  avatarProps,
  src,
  alt,
  children,
  size = '24px',
}) => {
  return (
    <Badge
      badgeContent={
        icon ? <div style={{ width: 14, height: 14, transform: 'translate (-4px, 4px) ' }}>{icon}</div> : null
      }
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...badgeProps}
    >
      <Avatar dataTestId={dataTestId} src={src} alt={alt} size={size} {...avatarProps}>
        {children}
      </Avatar>
    </Badge>
  );
};

export default AvatarWithBadge;
