import { useCallback } from 'react';

import { SourceType } from 'src/generated/mloop-graphql';

import { useApolloRefetchQuery } from 'src/hooks/useApolloQuery';

export function useActivityFeedRefetch(sourceType?: SourceType) {
  const refetchQuery = useApolloRefetchQuery();

  const refetch = useCallback(() => {
    if (sourceType) {
      if (sourceType === SourceType.SourceTypeTask) {
        return refetchQuery(['TaskDetailsActivityLogsList']);
      }

      if (sourceType === SourceType.SourceTypeApplication) {
        return refetchQuery(['ApplicationDetailsActivityLogsList']);
      }
    } else {
      return refetchQuery(['TaskDetailsActivityLogsList', 'ApplicationDetailsActivityLogsList']);
    }

    return undefined;
  }, [refetchQuery, sourceType]);

  return refetch;
}
