import useEmployeeId from './useEmployeeId';

/**
 * Hook to help us show restricted onboarding experience (for new orgs) or not. If true,
 * users only see the Integrations page and nothing else
 * @returns {boolean} Whether we should show the onboarding experience or not.
 */
export default function useIsNewOrgExperience() {
  // If an employeeId exists for the current actor,
  // then the directory was connected at some point.
  const employeeId = useEmployeeId();
  return !employeeId;
}
