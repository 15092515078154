import React from 'react';

import BaseIcon, { Props } from './Icon';

const NotificationIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 6.5C15.6046 6.5 16.5 5.60457 16.5 4.5C16.5 3.39543 15.6046 2.5 14.5 2.5C13.3954 2.5 12.5 3.39543 12.5 4.5C12.5 5.60457 13.3954 6.5 14.5 6.5ZM10 4.75C8.20507 4.75 6.75 6.20507 6.75 8V10.25C6.75 11.273 6.26837 12.2362 5.45 12.85L5.08333 13.125L4.91667 13.25H15.0833L14.9167 13.125L14.55 12.85C13.7316 12.2362 13.25 11.273 13.25 10.25V8.5C13.25 8.08579 13.5858 7.75 14 7.75C14.4142 7.75 14.75 8.08579 14.75 8.5V10.25C14.75 10.8008 15.0093 11.3195 15.45 11.65L15.8167 11.925L16.1833 12.2C16.5401 12.4675 16.75 12.8874 16.75 13.3333C16.75 14.1157 16.1157 14.75 15.3333 14.75H4.66667C3.88426 14.75 3.25 14.1157 3.25 13.3333C3.25 12.8874 3.45994 12.4675 3.81667 12.2L4.18333 11.925L4.62571 12.5148L4.18333 11.925L4.55 11.65C4.99066 11.3195 5.25 10.8008 5.25 10.25V8C5.25 5.37665 7.37665 3.25 10 3.25C10.2004 3.25 10.3983 3.26246 10.5928 3.28671C11.0038 3.33797 11.2955 3.71272 11.2442 4.12375C11.193 4.53478 10.8182 4.82644 10.4072 4.77518C10.2741 4.75859 10.1382 4.75 10 4.75ZM12 16C12 17.1046 11.1046 18 10 18C8.89543 18 8 17.1046 8 16H12Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default NotificationIcon;
