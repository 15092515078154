import ApiVerb from './ApiVerb';
import { apiPost } from './index';

export interface RequestBody {
  applicationDebriefId: string;
}

interface ResponseBody {
  error?: string;
}

const cancelDebrief = async (data: RequestBody): Promise<ResponseBody> => {
  const response = await apiPost(ApiVerb.CANCEL_DEBRIEF, data);
  return response.data;
};

export default cancelDebrief;
