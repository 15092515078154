export enum LoggerEvent {
  /** CLIENT EVENTS */
  /** Please keep entries in this section alphabetically sorted */
  CLIENT_ADVANCED_SCHEDULING_MODAL_INTERACTION = 'client_advanced_scheduling_modal_interaction',
  CLIENT_ATS_CANDIDATE_REDIRECT = 'client_ats_candidate_redirect',
  CLIENT_BROWSER_EXTENSION_CLOSED = 'client_browser_extension_closed',
  CLIENT_BROWSER_EXTENSION_OPENED = 'client_browser_extension_opened',
  CLIENT_BROWSER_EXTENSION_REDIRECT = 'client_browser_extension_redirect',
  CLIENT_CANDIDATE_AVAILABILITY_COPY_LINK = 'client_candidate_availability_copy_link',
  CLIENT_CANDIDATE_AVAILABILITY_EMAIL_SENT = 'client_candidate_availability_email_sent',
  CLIENT_CANDIDATE_AVAILABILITY_IMPORTED = 'client_candidate_availability_imported',
  CLIENT_CANDIDATE_AVAILABILITY_OPTIONS = 'client_candidate_availability_options',
  CLIENT_CANDIDATE_AVAILABILITY_PORTAL_OPENED = 'client_candidate_availability_portal_opened',
  CLIENT_CANDIDATE_DETAILS_DEPRECATED_VIEW_OPENED = 'client_candidate_details_deprecated_view_opened',
  CLIENT_CANDIDATE_LIST_ACTION_CLICKED = 'client_candidate_list_action_clicked',
  CLIENT_CANDIDATE_LIST_DEPRECATED_VIEW_SELECTED = 'client_candidate_list_deprecated_view_selected',
  CLIENT_CANDIDATE_LIST_MENU_OPENED = 'client_candidate_list_menu_opened',
  CLIENT_CANDIDATE_PORTAL_OPENED = 'client_candidate_portal_opened',
  CLIENT_CHANGE_COORDINATOR = 'client_change_coordinator',
  CLIENT_CHANGE_JOB_STAGE = 'client_change_job_stage',
  CLIENT_CHANGE_RECRUITER = 'client_change_recruiter',
  CLIENT_CHANGE_SOURCER = 'client_change_sourcer',
  CLIENT_CHANGE_TIMEZONE = 'client_change_timezone',
  CLIENT_CONTRACT_END_BANNER_VIEWED = 'client_contract_end_banner_viewed',
  CLIENT_CREATE_CUSTOM_JOB_STAGE_PLAN = 'client_create_custom_job_stage_plan',
  CLIENT_CUSTOM_INTERVIEW_SCHEDULE_EDIT = 'client_custom_interview_schedule_edit',
  CLIENT_CUSTOM_INTERVIEW_SCHEDULE_RESET = 'client_custom_interview_schedule_reset',
  CLIENT_DEBRIEF_FLOW_BEGIN = 'client_debrief_flow_begin',
  CLIENT_DEBRIEF_FLOW_COMPLETE = 'client_debrief_flow_complete',
  CLIENT_FLOW_NAV_AWAY_PROMPT_TRIGGERED = 'client_flow_nav_away_prompt_triggered',
  CLIENT_INTERVIEWER_PAGE_VIEW = 'client_interviewer_page_view',
  CLIENT_JOB_IMPORT_COMPLETED = 'client_job_import_completed',
  CLIENT_JOB_IMPORT_SKIPPED_IN_FIX_ISSUES_STEP = 'client_job_import_skipped_in_fix_issues_step',
  CLIENT_NO_CALENDAR_ACCESS_DIALOG_SHOWN = 'client_no_calendar_access_dialog_shown',
  CLIENT_OLD_PORTAL_NAVIGATED = 'client_old_portal_navigated',
  CLIENT_OMNI_SEARCH_FILTER = 'client_omni_search_filter',
  CLIENT_OMNI_SEARCH_LAUNCHED = 'client_omni_search_launched',
  CLIENT_PAGE_VIEW = 'client_page_view',
  CLIENT_RESCHEDULE_FLOW_BEGIN = 'client_reschedule_flow_begin',
  CLIENT_RESCHEDULE_FLOW_COMPLETE = 'client_reschedule_flow_complete',
  CLIENT_RESCHEDULE_FLOW_COMPLETE_TIMING = 'client_reschedule_flow_complete_timing',
  CLIENT_RESCHEDULE_FLOW_PREVIOUS_ISSUES_OPENED = 'client_reschedule_flow_previous_issues_opened',
  CLIENT_SAVE_BACK_TO_JOB_STAGE_SETTINGS = 'client_save_back_to_job_stage_settings',
  CLIENT_SCHEDULE_FLOW_ADD_INTERVIEW = 'client_schedule_flow_add_interview',
  CLIENT_SCHEDULE_FLOW_BEGIN = 'client_schedule_flow_begin',
  CLIENT_SCHEDULE_FLOW_COMPLETE = 'client_schedule_flow_complete',
  CLIENT_SCHEDULE_FLOW_COMPLETE_TIMING = 'client_schedule_flow_complete_timing',
  CLIENT_SCHEDULE_FLOW_DELETE_INTERVIEW = 'client_schedule_flow_delete_interview',
  CLIENT_SCHEDULE_FLOW_SELECTED_SCHEDULE_OPTION = 'client_schedule_flow_selected_schedule_option',
  CLIENT_SCHEDULE_FLOW_SEND_INTERNAL_ONLY_CLICKED = 'client_schedule_flow_send_internal_only_clicked',
  CLIENT_SCHEDULE_OBJECT_INTERVIEW_MENU_OPTION_SELECTED = 'client_schedule_object_interview_menu_option_selected',
  CLIENT_SCHEDULE_OBJECT_INTERVIEW_RESPONSE_EXPANDED = 'client_schedule_object_interview_response_expanded',
  CLIENT_SCHEDULE_OPTIONS_DATE_FILTER_APPLIED = 'client_schedule_options_date_filter_applied',
  CLIENT_SCHEDULE_OPTIONS_DATE_FILTER_BUTTON_CLICKED = 'client_schedule_options_date_filter_button_clicked',
  CLIENT_SCHEDULE_OPTIONS_LOADED_TIMING = 'client_schedule_options_loaded_timing',
  CLIENT_SETTINGS_EMAIL_TEMPLATE_ADD_ATTACHEMENTS = 'client_settings_email_template_add_attachements',
  CLIENT_SETTINGS_EMAIL_TEMPLATE_COMPANY_DEFAULT_SET = 'client_settings_email_template_company_default_set',
  CLIENT_SETTINGS_EMAIL_TEMPLATE_COMPANY_DEFAULT_UNSET = 'client_settings_email_template_company_default_unset',
  CLIENT_SETTINGS_EMAIL_TEMPLATE_DELETE = 'client_settings_email_template_delete',
  CLIENT_SETTINGS_EMAIL_TEMPLATE_DUPLICATE = 'client_settings_email_template_duplicate',
  CLIENT_SETTINGS_EMAIL_TEMPLATE_EDIT = 'client_settings_email_template_edit',
  CLIENT_SETTINGS_EMAIL_TEMPLATE_NEW = 'client_settings_email_template_new',
  CLIENT_SHARE_DIALOG_COPY_LINK = 'client_share_dialog_copy_link',
  CLIENT_SHARE_DIALOG_SEND_EMAIL = 'client_share_dialog_send_email',
  CLIENT_SHARE_PORTAL_LINK = 'client_share_portal_link',
  CLIENT_SOURCING_LINK_ACTIVE_TOGGLED = 'client_sourcing_link_active_toggled',
  CLIENT_UPCOMING_EVENTS_CHANGE_DATE = 'client_upcoming_events_change_date',
  CLIENT_UPCOMING_EVENTS_CHANGE_FILTER = 'client_upcoming_events_change_filter',
  CLIENT_UPCOMING_EVENTS_CHANGE_VIEW = 'client_upcoming_events_change_view',
  CLIENT_UPCOMING_EVENTS_CLICK_EVENT = 'client_upcoming_events_click_event',
  CLIENT_UPDATE_FLOW_BEGIN = 'client_update_flow_begin',
  CLIENT_UPDATE_FLOW_COMPLETE = 'client_update_flow_complete',
}
