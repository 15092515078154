import { useCallback, useEffect } from 'react';

import { ApolloQueryResult, gql, useApolloClient } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';
import { addDays, isBefore, parseISO } from 'date-fns';
import { cloneDeep } from 'lodash';

import {
  DateTimeRangeOutput,
  JobStageInterviewGroupInput,
  SelfScheduleZoomHost,
  SuggestedTimeRangesCountDocument,
  SuggestedTimeRangesCountInput,
  SuggestedTimeRangesCountQuery,
  SuggestedTimeRangesCountQueryVariables,
  SuggestedTimeRangesRequestType,
} from 'src/generated/mloop-graphql';

import IsoTimestamp, { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { startOfDay } from 'src/utils/datetime/Conversions';

import useLoadSelfScheduleOptionsByTimeRangeOld from './useLoadSelfScheduleOptionsByTimerangeOld';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SuggestedTimeRangesCount = gql`
  query SuggestedTimeRangesCount($input: SuggestedTimeRangesCountInput!) {
    suggestedTimeRangesCount(input: $input) {
      timeRangeCounts {
        range {
          startAt
          endAt
        }
        count
      }
      employeeMissingZoomUserId
    }
  }
`;

type Input = {
  applicationId: string;
  onEmployeeMissingZoomUserId: (employeeIds: string[]) => void;
  onError: (errorMessage: string) => void;
  requestType: SuggestedTimeRangesRequestType;
  selfScheduleZoomHost?: SelfScheduleZoomHost;
  taskId?: string;
};

const useLoadSelfScheduleOptionsByTimeRangeNew = (params: Input) => {
  const { applicationId, onEmployeeMissingZoomUserId, onError, requestType, selfScheduleZoomHost, taskId } = params;

  const apolloClient = useApolloClient();

  /**
   * Every time the hook mounts clear selfScheduleOptionsCount
   * This is needed to handle the below cases:
   *   - User updated the interview plan.
   *   - The modal was opened again after some time and by then the options count may have changed.
   */
  useEffect(() => {
    apolloClient.cache.evict({ fieldName: 'suggestedTimeRangesCount' });
  }, [apolloClient]);

  const loadSelfScheduleOptionsByTimeRange = useCallback(
    async (
      timeRanges: DateTimeRangeOutput[],
      timezone: string,
      advanceNoticeHours: number | undefined,
      countByDayMap: { [day: IsoTimestamp]: number },
      // onCountByDayMapChanged helps to immediately update the data as soon as we have it and not wait for all weeks count to load
      onCountByDayMapChanged: (newCountByDayMap: { [day: IsoTimestamp]: number }) => void,
      jobStageId: string,
      shouldRespectLoadLimit: boolean,
      canScheduleOverAvailableKeywords: boolean,
      canScheduleOverRecruitingKeywords: boolean,
      canScheduleOverFreeTime: boolean,
      customJobStageId?: string,
      fetchPolicy?: 'network-only',
      customInterviewPlan?: JobStageInterviewGroupInput[] | undefined,
      shouldSkip?: boolean
      // eslint-disable-next-line max-params
    ) => {
      const promises: Promise<void>[] = [];
      const countByDayMapClone = cloneDeep(countByDayMap);

      if (shouldSkip) {
        return;
      }

      timeRanges.forEach((timeRange) => {
        let start = parseISO(startOfDay(timeRange.startAt, timezone));
        const end = parseISO(timeRange.endAt);

        // Clear dates for which we are fetching counts.
        while (start.getTime() < end.getTime()) {
          const key = start.toISOString();
          delete countByDayMapClone[key];
          start = addDays(start, 1);
        }

        const input: SuggestedTimeRangesCountInput = {
          taskId,
          applicationId,
          timeRange,
          advanceNoticeHours,
          validateZoomHost: selfScheduleZoomHost === SelfScheduleZoomHost.Interviewer,
          includeEmptyRanges: false,
          shouldRespectLoadLimit,
          canScheduleOverRecruitingKeywords,
          canScheduleOverAvailableKeywords,
          canScheduleOverFreeTime,
          requestType,
        };

        // If customInterviewPlan is provided, use it. Otherwise, use customJobStageId or jobStageId.
        // customPlan of [] is not a valid input.
        if (customInterviewPlan && customInterviewPlan.length > 0) {
          input.customInterviewPlan = customInterviewPlan;
        }

        const variables: SuggestedTimeRangesCountQueryVariables = {
          input,
        };

        const promise = apolloClient
          .query({
            query: SuggestedTimeRangesCountDocument,
            variables,
            fetchPolicy,
            context: {
              batch: false,
            },
          })
          .then((result: ApolloQueryResult<SuggestedTimeRangesCountQuery>) => {
            if (result.data.suggestedTimeRangesCount?.employeeMissingZoomUserId) {
              onEmployeeMissingZoomUserId(result.data.suggestedTimeRangesCount?.employeeMissingZoomUserId);
            }

            if (!result.data.suggestedTimeRangesCount?.timeRangeCounts) return;

            result.data.suggestedTimeRangesCount.timeRangeCounts.forEach((timeRangeCount) => {
              if (!timeRangeCount?.range?.startAt) return;

              const day = startOfDay(timeRangeCount.range.startAt, timezone);
              countByDayMapClone[day] = (countByDayMapClone[day] || 0) + (timeRangeCount.count || 0);
            });

            // Add days from timerange that do not have any count and set them to 0.
            let startAt = parseISO(timeRange.startAt);
            const endAt = parseISO(timeRange.endAt);
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line promise/always-return
            while (isBefore(startAt, endAt)) {
              const dayStart = startOfDay(assertIsoTimestamp(startAt.toISOString()), timezone);
              if (!countByDayMapClone[dayStart]) {
                countByDayMapClone[dayStart] = 0;
              }
              startAt = addDays(parseISO(dayStart), 1);
            }

            onCountByDayMapChanged(cloneDeep(countByDayMapClone));
          })
          .catch((reason) => {
            onError(reason.message);
          });

        promises.push(promise);
      });

      await Promise.all(promises);
    },
    [taskId, applicationId, selfScheduleZoomHost, requestType, apolloClient, onEmployeeMissingZoomUserId, onError]
  );

  return loadSelfScheduleOptionsByTimeRange;
};

const useLoadSelfScheduleOptionsByTimeRange = (params: Input) => {
  const showCustomStartTimes = useFlag('org_setting_for_start_times');
  let hook;
  if (showCustomStartTimes) {
    hook = useLoadSelfScheduleOptionsByTimeRangeNew;
  } else {
    hook = useLoadSelfScheduleOptionsByTimeRangeOld;
  }
  return hook(params);
};

export default useLoadSelfScheduleOptionsByTimeRange;
