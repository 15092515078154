import React from 'react';

import BaseIcon, { Props } from './Icon';

const CaretUpTinyIcon = ({ color, fontSize, tooltip, viewBox }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} viewBox={viewBox}>
      <path
        d="M8.625 6.75L6 4.5L3.375 6.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default CaretUpTinyIcon;
