/* eslint-disable max-lines */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box, Grid, ListSubheader, TextField } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import AutoComplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteGetTagProps,
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete';
import { Stack } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import {
  BaseEditInterviewSeatModuleEmployeeSearchFragment,
  InterviewModulesOrderByProperty,
  Sort,
  useEmployeesAndModulesSearchLazyQuery,
} from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import IconButton from 'src/components/IconButton';
import VirtualList, { VirtualListboxProps } from 'src/components/VirtualList';
import Chip from 'src/components/chip';
import CheckIcon from 'src/components/icons/Check';
import CrossIcon from 'src/components/icons/Cross';
import LinkIcon from 'src/components/icons/Link';
import ModulesIcon from 'src/components/icons/Modules';
import Label from 'src/components/label';
import Tooltip from 'src/components/tooltip';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

import { InterviewModuleFragment } from 'src/entities/Select/InterviewModulesSelect';

import {
  getJobStageInterviewSeatById,
  getLinkableSeatsByInterviewIds,
} from 'src/store/selectors/job-stage-interview-seat';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import { Theme } from 'src/theme/type';

import { DEBOUNCE_TIMEOUT, PAGE_SIZE } from 'src/constants';
import { useSelector } from 'src/store';

import { AutoCompleteOption, AutoCompleteOptionType, BaseEditInterviewSeatModuleProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '6px',
      padding: '2px 8px',
      width: '100%',
    },
    gridAutoCompleteContainer: { flexGrow: 1, width: 0 },
    subHeader: {
      padding: '8px 16px',
    },
  })
);

const useAutoCompleteStyles = makeStyles((/* theme: Theme */) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '&:hover .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '&:hover .MuiInput-underline:after': {
        border: 'none',
      },
    },
  }));

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface OptionComponentProps {
  selected: boolean;
  hasInterviewers: boolean;
  name: string;
  type: AutoCompleteOptionType;
  photoUrl?: string | undefined;
  email?: string | undefined;
}

const OptionComponent = ({ selected, hasInterviewers, name, type, photoUrl, email }: OptionComponentProps) => {
  const [optionHovered, setOptionHovered] = useState(false);

  let label = selected ? <CheckIcon /> : undefined;
  if (optionHovered) {
    if (type === AutoCompleteOptionType.INTERVIEW || type === AutoCompleteOptionType.SEAT) {
      if (selected) {
        label = <>Remove</>;
      } else if (hasInterviewers) {
        label = <>Replace all</>;
      } else {
        label = <>Add</>;
      }
    } else {
      label = selected ? <>Remove</> : <>Add</>;
    }
  }

  let icon: JSX.Element | null = null;
  if (type === AutoCompleteOptionType.INTERVIEW) {
    icon = <ModulesIcon />;
  } else if (type === AutoCompleteOptionType.SEAT) {
    icon = <LinkIcon />;
  } else {
    icon = <Avatar size="36px" alt={name} src={photoUrl} />;
  }

  return (
    <Box
      width="100%"
      onMouseOver={() => {
        setOptionHovered(true);
      }}
      onMouseOut={() => {
        setOptionHovered(false);
      }}
      onFocus={() => {
        setOptionHovered(true);
      }}
      onBlur={() => {
        setOptionHovered(false);
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          {icon}
          <Stack direction="column">
            <Label>{name}</Label>
            {email ? (
              <Label variant="captions" color="high-contrast-grey">
                {email}
              </Label>
            ) : null}
          </Stack>
        </Stack>
        {label}
      </Stack>
    </Box>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const BaseEditInterviewSeatModule = ({
  jobStageInterviewSeat,
  linkableInterviews,
  onEditComplete,
  onDelete,
}: BaseEditInterviewSeatModuleProps): JSX.Element | null => {
  const classes = useStyles();

  const autoCompleteClasses = useAutoCompleteStyles();
  const [searchTerm, setSearchTerm] = useState('');

  const [hasInterviewers, setHasInterviewers] = useState(false);

  const [selectedSeatOrModules, setSelectedSeatOrModules] = useState<AutoCompleteOption[]>([]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<AutoCompleteOption[]>([]);
  const linkedInterviewOptionsRef = useRef<AutoCompleteOption[]>([]);

  const [searchEmployeesAndModules, { data: employeesAndModulesData, loading: loadingEmployeesAndModulesData }] =
    useEmployeesAndModulesSearchLazyQuery({
      fetchPolicy: 'network-only', // force this to fetch from the backend each time in case modules/members/attributes are added while in the scheduling flow
    });

  useEffect(() => {
    // run the search once using an empty string when the component mounts
    searchEmployeesAndModules({
      variables: {
        employeeSearchInput: {
          search: '',
          isArchived: false,
          isAtsDisabled: false,
          isDirectoryDisabled: false,
          pageInput: {
            limit: 25,
            offset: 0,
          },
        },
        moduleSearchInput: {
          search: '',
          pageInput: {
            limit: 25,
            offset: 0,
          },
          orderBy: {
            property: InterviewModulesOrderByProperty.Name,
            direction: Sort.Asc,
          },
        },
      },
    });
  }, [searchEmployeesAndModules]);

  const linkableInterviewIds = linkableInterviews.map((interview) => interview.id);
  const linkableSeats: JobStageInterviewSeat[] = useSelector((state) =>
    getLinkableSeatsByInterviewIds(state, jobStageInterviewSeat.jobStageInterviewId, linkableInterviewIds)
  );

  const originalSeat = useSelector((state) => {
    if (!jobStageInterviewSeat.interviewSeatId) return undefined;
    return getJobStageInterviewSeatById(state, jobStageInterviewSeat.interviewSeatId);
  });
  const originalInterview = linkableInterviews.find((interview) => interview.id === originalSeat?.jobStageInterviewId);

  useEffect(() => {
    // populate the selectedSeatOrModules from the store
    const options: AutoCompleteOption[] = [];
    if (!jobStageInterviewSeat) {
      return;
    }
    if (jobStageInterviewSeat.freeformSeat) {
      if (jobStageInterviewSeat.freeformSeat?.jobStageInterviewSeatEmployees) {
        jobStageInterviewSeat.freeformSeat?.jobStageInterviewSeatEmployees.forEach((interviewSeatEmployee) => {
          if (interviewSeatEmployee?.employee) {
            options.push({ type: AutoCompleteOptionType.EMPLOYEE, employee: interviewSeatEmployee.employee });
          }
        });
      }
      setSelectedSeatOrModules(options);
      if (options.length) {
        setHasInterviewers(true);
      }
    }

    if (jobStageInterviewSeat.moduleSeat && jobStageInterviewSeat.moduleSeat.interviewModule) {
      setSelectedSeatOrModules([
        {
          type: AutoCompleteOptionType.INTERVIEW,
          interview: jobStageInterviewSeat.moduleSeat.interviewModule,
        },
      ]);

      setHasInterviewers(false);
    }
    if (jobStageInterviewSeat.linkedSeat) {
      setSelectedSeatOrModules([
        { type: AutoCompleteOptionType.SEAT, seat: originalSeat, linkableInterview: originalInterview },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let options: AutoCompleteOption[] = [];

    if (employeesAndModulesData && employeesAndModulesData?.employeeSearch?.items) {
      options = employeesAndModulesData?.employeeSearch?.items.map((emp) => ({
        type: AutoCompleteOptionType.EMPLOYEE,
        employee: emp,
      }));
    }

    if (employeesAndModulesData && employeesAndModulesData?.interviewModuleSearch?.items) {
      options = options.concat(
        employeesAndModulesData?.interviewModuleSearch?.items.map((inter) => ({
          type: AutoCompleteOptionType.INTERVIEW,
          interview: inter,
        }))
      );
    }
    setAutoCompleteOptions(options || []);
  }, [employeesAndModulesData]);

  useEffect(() => {
    const options: AutoCompleteOption[] = [];

    linkableSeats.forEach((linkableSeat) => {
      options.push({
        type: AutoCompleteOptionType.SEAT,
        seat: linkableSeat,
        linkableInterview: linkableInterviews.find((interview) => interview.id === linkableSeat.jobStageInterviewId),
      });
    });

    linkedInterviewOptionsRef.current = options;
  }, [linkableInterviews, linkableSeats]);

  const getOptionLabel = (option: AutoCompleteOption) => {
    if (option.type === 'employee') return option.employee?.fullName ?? '';
    if (option.type === AutoCompleteOptionType.INTERVIEW) return option.interview?.name ?? '';
    return `${option.linkableInterview?.name ?? ''}`;
  };

  const renderOption = (option: AutoCompleteOption, state: AutocompleteRenderOptionState) => {
    if (option.type === AutoCompleteOptionType.INTERVIEW) {
      if (!option.interview?.name) return null;

      return (
        <OptionComponent
          selected={state.selected}
          hasInterviewers={hasInterviewers}
          name={option.interview?.name}
          type={option.type}
        />
      );
    }

    if (option.type === AutoCompleteOptionType.EMPLOYEE) {
      if (!option.employee?.fullName && !option.employee?.email) return null;

      return (
        <OptionComponent
          email={option.employee.email}
          selected={state.selected}
          hasInterviewers={hasInterviewers}
          name={option.employee.fullName || option.employee.email}
          type={option.type}
          photoUrl={option.employee.slackImageUrl ?? undefined}
        />
      );
    }

    // Handling for linked seat
    let name = option.linkableInterview?.name;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line max-lines
    if (
      option.linkableInterview &&
      option.linkableInterview.seatIds &&
      option.linkableInterview.seatIds.length > 1 &&
      option &&
      option.seat
    ) {
      const seatIndex = option.linkableInterview.seatIds.indexOf(option.seat.id);
      name = `${name} (Seat ${seatIndex + 1})`;
    }

    if (!name) return null;

    return (
      <OptionComponent selected={state.selected} hasInterviewers={hasInterviewers} type={option.type} name={name} />
    );
  };

  const handleChange = useCallback(
    (
      event: React.ChangeEvent,
      values: AutoCompleteOption[],
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<AutoCompleteOption>
      // eslint-disable-next-line max-params
    ) => {
      if (reason === 'remove-option' || reason === 'clear') {
        setSelectedSeatOrModules(values);
        setSearchTerm('');
        return;
      }
      const index = values.findIndex(
        (value) => value.type === AutoCompleteOptionType.INTERVIEW || value.type === AutoCompleteOptionType.SEAT
      );

      if (
        details &&
        (details.option.type === AutoCompleteOptionType.INTERVIEW ||
          details.option.type === AutoCompleteOptionType.SEAT ||
          index !== -1)
      ) {
        setSelectedSeatOrModules([details.option]);
        setHasInterviewers(details.option.type === AutoCompleteOptionType.EMPLOYEE);

        if (
          details.option.type === AutoCompleteOptionType.INTERVIEW ||
          details.option.type === AutoCompleteOptionType.SEAT
        ) {
          onEditComplete([details.option]);
        }
        setSearchTerm('');
        return;
      }

      setSelectedSeatOrModules(values);
      setHasInterviewers(true);
      setSearchTerm('');
    },
    [onEditComplete, setSelectedSeatOrModules, setHasInterviewers]
  );

  const debouncedHandleInputChange = useDebouncedCallback((search: string) => {
    searchEmployeesAndModules({
      variables: {
        employeeSearchInput: {
          search,
          isArchived: false,
          isAtsDisabled: false,
          isDirectoryDisabled: false,
          pageInput: {
            limit: PAGE_SIZE,
            offset: 0,
          },
        },
        moduleSearchInput: {
          search,
          pageInput: {
            limit: PAGE_SIZE,
            offset: 0,
          },
          orderBy: {
            property: InterviewModulesOrderByProperty.Name,
            direction: Sort.Asc,
          },
        },
      },
    });
  }, DEBOUNCE_TIMEOUT);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event?.target?.value !== undefined && typeof event?.target?.value === 'string') {
      setSearchTerm(event.target.value);
      debouncedHandleInputChange(event.target.value);
    }
  };

  const handleTextInputBlur = useCallback(() => {
    if (selectedSeatOrModules.length === 0) onDelete();
    onEditComplete(selectedSeatOrModules);
  }, [selectedSeatOrModules, onDelete, onEditComplete]);

  const getOptionSelected = (option: AutoCompleteOption, value: AutoCompleteOption) => {
    if (option.type !== value.type) return false;
    if (option.type === AutoCompleteOptionType.EMPLOYEE) {
      return option.employee?.id === value.employee?.id;
    }
    if (option.type === AutoCompleteOptionType.SEAT) {
      return option.seat?.id === value.seat?.id && option.linkableInterview?.id === value.linkableInterview?.id;
    }
    return option.interview?.id === value.interview?.id;
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  const renderTags = (options: AutoCompleteOption[], getTagProps: AutocompleteGetTagProps, isFocused?: boolean) => {
    return (
      <>
        {options.map((option, index) => {
          let avatar = <ModulesIcon />;
          let key = option.interview?.id;
          let label = option.interview?.name;

          if (option.type === AutoCompleteOptionType.EMPLOYEE) {
            avatar = <Avatar alt={option.employee?.fullName ?? ''} src={option.employee?.slackImageUrl ?? undefined} />;
            key = option.employee?.id;
            label = option.employee?.fullName ?? undefined;
          } else if (option.type === AutoCompleteOptionType.SEAT) {
            // This is just a fallback logic because when user selects a linked seat we immediately save it.
            avatar = <LinkIcon />;
            key = option.seat?.id;
            const seatIndex = option.linkableInterview?.seatIds.indexOf(option.seat?.id ?? '') ?? -1;
            label = `${option.linkableInterview?.name} (Seat ${seatIndex !== -1 ? seatIndex + 1 : ''})`;
          }

          if (!label) return null;

          return (
            <Tooltip
              key={key}
              tooltip={
                option.type === AutoCompleteOptionType.EMPLOYEE ? (
                  <Stack direction="column">
                    <Label>{option.employee?.fullName}</Label>
                    <Label>{option.employee?.email}</Label>
                  </Stack>
                ) : (
                  ''
                )
              }
            >
              <Chip
                label={<MaybeTooltip tooltip={label} label={label} />}
                variant={isFocused ? 'default' : 'outlined'}
                avatar={avatar}
                tagProps={getTagProps({ index })}
              />
            </Tooltip>
          );
        })}
      </>
    );
  };

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader disableSticky key={params.key} component="div" className={classes.subHeader}>
      <Label variant="captions" color="info" fontWeight={600}>
        {params.group === AutoCompleteOptionType.EMPLOYEE && 'Individuals'}
        {params.group === AutoCompleteOptionType.INTERVIEW && 'Modules'}
        {params.group === AutoCompleteOptionType.SEAT && 'Link from another interview'}
      </Label>
    </ListSubheader>,
    params.children,
  ];

  const filterOptions = (options: AutoCompleteOption[], state: FilterOptionsState<AutoCompleteOption>) => {
    // the interview modules and employees are already filtered by the search term, but we still need to filter on the linked interview names
    const { inputValue } = state;

    return options
      .filter((option) => {
        switch (option.type) {
          case AutoCompleteOptionType.EMPLOYEE:
          case AutoCompleteOptionType.INTERVIEW:
            // these types are already filtered by the search query
            return true;
          case AutoCompleteOptionType.SEAT:
            // this type isn't filtered yet so go ahead and filter based on the inputValue
            return Boolean(option.linkableInterview?.name?.toLowerCase().includes(inputValue.toLowerCase()));
          default:
        }
        return false;
      })
      .filter((option) => {
        if (option.type === AutoCompleteOptionType.INTERVIEW || option.type === AutoCompleteOptionType.SEAT) {
          return true;
        }
        // eslint-disable-next-line prefer-destructuring
        const employee: BaseEditInterviewSeatModuleEmployeeSearchFragment | undefined = option.employee;
        return !employee?.isAtsDisabled && employee?.atsId;
      });
  };

  // combine the linkedInterview seats with the searched-for results
  const options = [...linkedInterviewOptionsRef.current, ...autoCompleteOptions];
  const listboxProps: VirtualListboxProps = {
    getChildSize: (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 32;
      }

      if (
        child &&
        React.isValidElement(child) &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React.Children.only((child.props as any)?.children)?.props?.type &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React.Children.only((child.props as any)?.children)?.props?.type === AutoCompleteOptionType.EMPLOYEE
      ) {
        return 52;
      }

      return 32;
    },
  };

  const getOptionDisabled = (option: AutoCompleteOption) =>
    Boolean(option.type === AutoCompleteOptionType.EMPLOYEE && option.employee?.fullName && !option.employee?.timezone);

  return (
    <Grid item container alignItems="center" spacing={1} wrap="nowrap">
      <Grid item className={classes.gridAutoCompleteContainer}>
        <Box className={classes.box}>
          <AutoComplete
            autoHighlight
            fullWidth
            multiple
            disableClearable
            clearOnBlur
            disableListWrap
            loading={loadingEmployeesAndModulesData}
            classes={autoCompleteClasses}
            ListboxComponent={VirtualList as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            ListboxProps={listboxProps}
            options={options}
            value={selectedSeatOrModules}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder={selectedSeatOrModules.length === 0 ? 'Search modules or individuals' : ''}
                autoFocus
                onBlur={handleTextInputBlur}
              />
            )}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            renderTags={renderTags}
            onChange={handleChange}
            getOptionSelected={getOptionSelected}
            groupBy={(option: AutoCompleteOption) => option.type}
            renderGroup={renderGroup}
            onInputChange={handleInputChange}
            inputValue={searchTerm}
            filterOptions={filterOptions}
            getOptionDisabled={getOptionDisabled}
          />
        </Box>
      </Grid>
      <Grid item>
        <Grid container justifyContent="flex-end">
          {selectedSeatOrModules.length > 0 && (
            <IconButton color="primary" onClick={() => onEditComplete(selectedSeatOrModules)}>
              <CheckIcon fontSize={24} color="background" />
            </IconButton>
          )}
          {selectedSeatOrModules.length === 0 && (
            <IconButton onClick={onDelete}>
              <CrossIcon fontSize={24} />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

BaseEditInterviewSeatModule.fragment = {
  employeeSearch: gql`
    fragment BaseEditInterviewSeatModuleEmployeeSearch on Employee {
      id
      fullName
      email
      orgId
      isDirectoryDisabled
      hasAtsId
      slackImageUrl
      timezone
      atsId
      isAtsDisabled
    }
  `,
  moduleSearch: gql`
    ${InterviewModuleFragment}
    fragment BaseEditInterviewSeatModuleInterviewModuleSearch on InterviewModule {
      ...InterviewModule
      orgId
      membersCount
      membersTrainedCount
      membersInTrainingCount
      membersInReverseShadowCount
      membersInShadowCount
      membersInPendingApprovalCount
      graduateFromShadowApprovalType
      graduateFromReverseShadowApprovalType
      interviewModuleMembers(input: { pageInput: { limit: 500, offset: 0 } }) {
        items {
          interviewModuleId
          employeeId
          status
          shadowsRequired
          reverseShadowsRequired
          pausedUntil
          stats {
            completedAsReverseShadow
            completedAsShadow
            shadowOffset
            reverseShadowOffset
          }
          employee {
            id
            fullName
            email
            slackImageUrl
            timezone
            attributes {
              id
              value
            }
            interviewPauseDates {
              start
              end
            }
          }
        }
      }
    }
  `,
};

export const BaseEditInterviewSeatModuleQuery = gql`
  ${BaseEditInterviewSeatModule.fragment?.employeeSearch}
  ${BaseEditInterviewSeatModule.fragment?.moduleSearch}
  query EmployeesAndModulesSearch(
    $employeeSearchInput: EmployeeSearchInput!
    $moduleSearchInput: InterviewModuleSearchInput!
  ) {
    employeeSearch(input: $employeeSearchInput) {
      items {
        ...BaseEditInterviewSeatModuleEmployeeSearch
      }
    }
    interviewModuleSearch(input: $moduleSearchInput) {
      items {
        ...BaseEditInterviewSeatModuleInterviewModuleSearch
      }
    }
  }
`;

export default BaseEditInterviewSeatModule;
