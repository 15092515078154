import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskReadyForCandidateComm_ActivityLogFragment, TaskStatus } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { getDisplayStringForTaskStatus } from 'src/views-new/ScheduleTaskDashboard/utils';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';

interface Fragments {
  activityLog: ActivityLogTaskReadyForCandidateComm_ActivityLogFragment;
}

const ActivityLogTaskReadyForCandidateComm: FCWithFragments<Fragments> = ({ activityLog }) => {
  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={`set the status to ${getDisplayStringForTaskStatus(TaskStatus.ReadyToSendToCandidate)}`}
    />
  );
};

ActivityLogTaskReadyForCandidateComm.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskReadyForCandidateComm_activityLog on ActivityLog {
      id

      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskReadyForCandidateComm;
