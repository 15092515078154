import React, { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Theme, createStyles, makeStyles } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { BugReport } from '@material-ui/icons';

import IconButton from 'src/components/IconButton';
import Button from 'src/components/button';

import { useIsMLoopInternalOnly } from 'src/hooks/feature-flag/useIsMLoopInternalOnly';

import useCopyDebugInfo from './useCopyDebugInfo';

type Props = {
  expanded: boolean;
  size?: 'small' | 'medium';
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {},
    button: {
      display: 'flex',
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: theme.spacing(1.5),
      borderRadius: '6px',
    },
  })
);

const CopyDebugInfoNavButton: FC<Props> = ({ expanded, size = 'medium' }) => {
  const classes = useStyles();

  const handleCopyDebugInfo = useCopyDebugInfo();
  const isMLOnly = useIsMLoopInternalOnly();
  if (!isMLOnly) {
    return null;
  }

  return (
    <>
      {!expanded && (
        <IconButton
          tooltip="ModernLoop Internal Only - Copy Debug Info"
          className={classes.iconButton}
          onClick={handleCopyDebugInfo}
        >
          <BugReport color="action" />
        </IconButton>
      )}
      {expanded && (
        <Button
          tooltip="ModernLoop Internal Only - Copy Debug Info"
          size={size}
          className={classes.button}
          fullWidth
          onClick={handleCopyDebugInfo}
          startIcon={<BugReport />}
          label="Debug Info"
          variant="unstyled"
        />
      )}
    </>
  );
};

export default CopyDebugInfoNavButton;
