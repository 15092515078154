import React from 'react';

import BaseIcon, { Props } from './Icon';

const JobIconFilled = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49983 3.75C8.36176 3.75 8.24983 3.86193 8.24983 4V5.25H11.7498V4C11.7498 3.86193 11.6379 3.75 11.4998 3.75H8.49983ZM6.74983 4V5.25H3.99983C2.48105 5.25 1.24983 6.48122 1.24983 8V15C1.24983 16.5188 2.48105 17.75 3.99983 17.75H15.9998C17.5186 17.75 18.7498 16.5188 18.7498 15V8C18.7498 6.48122 17.5186 5.25 15.9998 5.25H13.2498V4C13.2498 3.0335 12.4663 2.25 11.4998 2.25H8.49983C7.53334 2.25 6.74983 3.0335 6.74983 4ZM7.80523 10.0016C8.03201 8.99879 8.92848 8.25 9.99983 8.25C11.0712 8.25 11.9677 8.99879 12.1944 10.0016L16.2793 8.7447C16.6752 8.62289 17.0949 8.84508 17.2167 9.24097C17.3385 9.63687 17.1163 10.0566 16.7204 10.1784L12.2492 11.5541C12.2204 12.7718 11.2244 13.75 9.99983 13.75C8.77529 13.75 7.77922 12.7718 7.75047 11.5541L3.27927 10.1784C2.88337 10.0566 2.66119 9.63687 2.783 9.24097C2.90481 8.84508 3.3245 8.62289 3.7204 8.7447L7.80523 10.0016ZM9.24983 11.017C9.2501 11.0055 9.2501 10.994 9.24983 10.9825V10.5C9.24983 10.0858 9.58562 9.75 9.99983 9.75C10.414 9.75 10.7498 10.0858 10.7498 10.5V10.9825C10.7496 10.994 10.7496 11.0055 10.7498 11.017V11.5C10.7498 11.9142 10.414 12.25 9.99983 12.25C9.58562 12.25 9.24983 11.9142 9.24983 11.5V11.017Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default JobIconFilled;
