import React, { useCallback, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { Box, Button, Stack, Typography } from '@mui/material';
import { last, sortBy } from 'lodash';

import { Debrief_DebriefRequirementsFragment } from 'src/generated/mloop-graphql';

import AccordionCard from 'src/components/AccordionCard';
import { Props as IconProps } from 'src/components/icons/Icon';
import { FCWithFragments } from 'src/components/types';
import { IconType } from 'src/components/utils/icons';

import DebriefContentEntity from 'src/entities/DebriefContent';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useHistory from 'src/hooks/useHistory';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import getDebriefFlowUrl from 'src/urls/getDebriefFlowUrl';

import CancelInterviewDialog from 'src/views-new/CandidateDetails/CancelInterviewDialog';
import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

import RequirementContainer from '../Common/RequirementContainer';

import RemoveDebriefRequirementConfirmation from './RemoveDebriefRequirementConfirmation';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type DebriefFragmentProps = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/component-with-fragments.cjs
  debriefRequirements: Debrief_DebriefRequirementsFragment;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type DebriefProps = {
  isCancelled?: boolean;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const Debrief: FCWithFragments<DebriefFragmentProps, DebriefProps> = ({ debriefRequirements, isCancelled }) => {
  const activityFeedRefetch = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();

  const history = useHistory();

  const [showDeleteDebriefConfirmationModal, setShowDeleteDebriefConfirmationModal] = useState(false);
  const [showRemoveDebriefRequirementConfirmation, setShowRemoveDebriefRequirementConfirmation] = useState(false);

  const debriefFlowUrl = useMemo(() => {
    return getDebriefFlowUrl(debriefRequirements.application.id, { taskId: debriefRequirements.id });
  }, [debriefRequirements.application.id, debriefRequirements.id]);

  const handleScheduleDebrief = useCallback(() => {
    refetchScheduleTaskQuery();
    activityFeedRefetch();
    SidePanelManager.closeSidePanel();
    history.push(debriefFlowUrl);
  }, [activityFeedRefetch, debriefFlowUrl, history, refetchScheduleTaskQuery]);

  /**
   * This is a temporary work around to get the latest data.
   * BE is going to add a comparator and send the sorted array soon.
   */
  const taskDebrief = debriefRequirements?.debriefs
    ? last(
        sortBy(debriefRequirements.debriefs, (debrief) => {
          return new Date(debrief.createdAt);
        })
      )
    : null;

  const iconInfo = useMemo((): { type: IconType; props: IconProps } => {
    if (taskDebrief?.id) {
      return { type: 'CircleWithCheckIcon', props: { color: 'success' } };
    }
    return { type: 'RadioIcon', props: {} };
  }, [taskDebrief?.id]);

  const isDebriefCompleted = useMemo(() => {
    return new Date(taskDebrief?.endAt as string).getTime() < new Date().getTime();
  }, [taskDebrief?.endAt]);

  return (
    <RequirementContainer
      title="Schedule debrief"
      iconType={iconInfo.type}
      iconProps={iconInfo.props}
      moreMenuOptions={!isCancelled ? [{ value: 'Remove requirement' }] : undefined}
      onSelect={() => setShowRemoveDebriefRequirementConfirmation(true)}
    >
      <AccordionCard title={<Typography>Debrief</Typography>} accordionDetailsPadding={0}>
        <Stack spacing={2}>
          {!taskDebrief?.id && (
            <Box py={1.5} px={2.5}>
              <Button size="small" color="primary" onClick={handleScheduleDebrief}>
                Schedule debrief
              </Button>
            </Box>
          )}

          {taskDebrief && <DebriefContentEntity debrief={taskDebrief} />}
        </Stack>
      </AccordionCard>

      <CancelInterviewDialog
        isCompleted={isDebriefCompleted}
        isDebrief
        interviewId={taskDebrief?.id}
        isOpen={showDeleteDebriefConfirmationModal}
        onCancel={() => {
          activityFeedRefetch();

          setShowDeleteDebriefConfirmationModal(false);
        }}
        onClose={() => setShowDeleteDebriefConfirmationModal(false)}
      />

      <RemoveDebriefRequirementConfirmation
        taskId={debriefRequirements.id}
        open={showRemoveDebriefRequirementConfirmation}
        onRemove={() => {
          activityFeedRefetch();
        }}
        onClose={() => {
          setShowRemoveDebriefRequirementConfirmation(false);
        }}
      />
    </RequirementContainer>
  );
};

Debrief.fragments = {
  debriefRequirements: gql`
    ${DebriefContentEntity.fragments.debrief}
    fragment Debrief_debriefRequirements on Task {
      id
      isDebriefRequired
      application {
        id
      }
      debriefs {
        id
        createdAt
        ...DebriefContentEntity_debrief
      }
    }
  `,
};

export default Debrief;
