import { ThemeOptions } from '@mui/material';

/**
 * View AvatarGroup props and classes at https://mui.com/material-ui/api/avatargroup/#css
 */
export const avatarGroup: ThemeOptions['components'] = {
  MuiAvatarGroup: {
    defaultProps: {
      max: 3,
      variant: 'rounded',
      renderSurplus: (surplus: number) => surplus,
    },
    styleOverrides: {
      avatar: ({ theme, ownerState }) => ({
        ...(ownerState.spacing === 'medium' && {
          marginLeft: '-4px',
        }),
        border: 'none',
        outline: `2px solid ${theme.palette.background.default}`,
      }),
    },
  },
};
