import React, { useEffect } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box } from '@material-ui/core';

import { TrainingStatus, useInterviewModuleMemberLookupLazyQuery } from 'src/generated/mloop-graphql';

import Label from 'src/components/label';
import { MenuOption } from 'src/components/menu';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
const useTrainedModuleOptions = (prefix: string, interviewerId?: string, interviewerName?: string): MenuOption[] => {
  const [fetchMemberModules, { data, loading, error }] = useInterviewModuleMemberLookupLazyQuery({
    variables: { memberIds: [interviewerId] },
  });

  useEffect(() => {
    if (!interviewerId) return;
    fetchMemberModules();
  }, [fetchMemberModules, interviewerId]);

  if (loading) {
    return [
      {
        id: 'freeform-interviewer-options-loading',
        value: 'Loading…',
        disabled: true,
      },
    ];
  }

  if (error) {
    return [
      {
        id: 'freeform-interviewer-options-loading',
        value: `Error: ${error.message}`,
        disabled: true,
      },
    ];
  }

  if (
    !data ||
    !data.interviewModuleMemberLookup ||
    !data.interviewModuleMemberLookup.items ||
    data.interviewModuleMemberLookup.items.length === 0 ||
    data.interviewModuleMemberLookup.items.filter((i) => i.status === TrainingStatus.Trained).length === 0
  ) {
    const customOption = (
      <Box width="260px">
        <Label color="high-contrast-grey" style={{ whiteSpace: 'pre-wrap' }}>
          {interviewerName || 'Interviewer'} isn&apos;t trained for any modules
        </Label>
      </Box>
    );
    return [
      {
        id: 'freeform-interviewer-options-loading',
        customOption,
        disabled: true,
      },
    ];
  }

  const { items } = data.interviewModuleMemberLookup;

  const options: MenuOption[] = items
    .filter((i) => i.status === TrainingStatus.Trained)
    .map((i) => ({
      id: `${prefix}-${i.interviewModule?.id}`,
      value: i.interviewModule?.name || '',
    }));

  options.unshift({
    customOption: (
      <Label color="info" fontWeight={600} variant="captions">
        {interviewerName || ''}&apos;s fully trained modules
      </Label>
    ),
    isSubheader: true,
  });
  return options;
};

export default useTrainedModuleOptions;
