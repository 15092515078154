// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { useTheme as useMuiTheme } from '@material-ui/core/styles';

import type { Theme } from './type';

/**
 * @deprecated
 * use from  '@mui/material'
 */
const useTheme = (): Theme => useMuiTheme<Theme>();

export default useTheme;
