import { useEffect, useMemo, useRef, useState } from 'react';

import { QueryResult, useApolloClient } from '@apollo/client';
import { forOwn, includes, isArray, isObject, unset } from 'lodash';

import logError from 'src/utils/logError';

import { EMPTY_UUID } from 'src/constants';

import fetchInterviewerPlaceholderFillerTokenData from './fetchInterviewerPlaceholderFillerTokenData';
import { EMPTY_MERGED_OPTIONS, Input, InterviewPlaceholderFillerOptions } from './types';

const IGNORED_PARAM_KEYS = ['isOptional'];
const getSanitisedParams = (params: Input): Input => {
  const newParams = { ...params };
  function deepDelete(obj) {
    if (isArray(obj)) {
      obj.forEach((item) => deepDelete(item));
    } else if (isObject(obj)) {
      forOwn(obj, (value, key) => {
        if (includes(IGNORED_PARAM_KEYS, key)) {
          unset(obj, key);
        } else {
          deepDelete(value);
        }
      });
    }
  }
  deepDelete(newParams);
  return newParams;
};

/**
 * A hook that fetches interview placeholder filler token data and returns the options and loading state.
 * @param params - The input parameters for the hook.
 * @returns A tuple containing the interview placeholder filler options and loading state
 */
const useInterviewPlaceholderFiller = (
  input: Input
): [InterviewPlaceholderFillerOptions, Pick<QueryResult, 'loading'>] => {
  const { applicationId, candidateId, schedule, isFetch = true, slotId } = input;
  const requestIdRef = useRef<string>(EMPTY_UUID);
  const [ret, setRet] = useState<InterviewPlaceholderFillerOptions>({ ...EMPTY_MERGED_OPTIONS });
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();

  /**
   * Ignore keys which are not required for token replacement
   */
  const params = useMemo(() => {
    return getSanitisedParams(input);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(input)]);

  useEffect(() => {
    if (!isFetch || !applicationId || !candidateId || !schedule || !schedule?.events?.length) {
      return;
    }

    const fetch = async () => {
      setLoading(true);
      try {
        const data = await fetchInterviewerPlaceholderFillerTokenData(apolloClient, requestIdRef, params, slotId);
        if (data) {
          setRet(data);
          setLoading(false);
        }
      } catch (e) {
        logError(e);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!apolloClient, JSON.stringify(params)]);

  return [ret, { loading }];
};

export default useInterviewPlaceholderFiller;
