import { ThemeOptions } from '@mui/material';

const MIN_DIALOG_WIDTH = 444;

export const dialog: ThemeOptions['components'] = {
  MuiDialog: {
    defaultProps: {
      maxWidth: 'sm',
      PaperProps: {
        elevation: 5,
      },
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(1.5),
        outlineOffset: '-1px',
      }),
      paper: ({ theme, ownerState }) => {
        return {
          borderRadius: theme.spacing(1.5),
          // Manually set the width otherwise our dialogs shrink/expand to content width. 444 is the fallback since 'xs' breakpoint is 0px.
          width: theme.breakpoints.values[ownerState.maxWidth || 'sm'] || MIN_DIALOG_WIDTH,
          backgroundImage: 'none',
        };
      },
      paperFullScreen: {
        borderRadius: '0',
        margin: '0',
        maxHeight: '100%',
        height: '100%',
        width: '100%',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        flexGrow: 0,
      },
    },
  },
};
