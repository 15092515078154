export const yellow = {
  0: '#000000',
  10: '#221C04',
  20: '#3A2F09',
  25: '#473B09',
  30: '#57470A',
  35: '#66550F',
  40: '#7D6505',
  50: '#A17F03',
  60: '#C49A02',
  70: '#E3B409',
  80: '#FCCC1C',
  90: '#FCDF74',
  95: '#FFF2C9',
  98: '#FEFAEF',
  99: '#FFFEFA',
  100: '#FFFFFF',
};
