import { SvgIcon, SvgIconProps } from './SvgIcon';

export const PhoneIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        d="M14.2297 15.7703L15.0355 14.9645C15.4261 14.5739 15.4261 13.9408 15.0355 13.5503L13.6213 12.136C13.2308 11.7455 12.5976 11.7455 12.2071 12.136L11.5249 12.8183C11.2885 13.0547 10.9462 13.165 10.6326 13.0491C9.16323 12.5056 7.59486 10.9373 7.05144 9.46787C6.93546 9.15427 7.04581 8.81205 7.28224 8.57563L7.96446 7.8934C8.35499 7.50288 8.35499 6.86971 7.96446 6.47919L6.55025 5.06497C6.15973 4.67445 5.52656 4.67445 5.13604 5.06497L4.33021 5.8708C3.93264 6.26837 3.63181 6.76927 3.6088 7.33106C3.43683 11.5294 8.5711 16.6637 12.7694 16.4917C13.3312 16.4687 13.8321 16.1679 14.2297 15.7703Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M16.7764 7.66992C16.3859 6.72237 15.8058 5.8346 15.0359 5.0647C14.266 4.29481 13.3782 3.71464 12.4306 3.3242"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M11.4837 6.15364C12 6.41157 12.4841 6.75579 12.9146 7.1863C13.3451 7.61682 13.6893 8.10094 13.9473 8.61719"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
