import React from 'react';

import BaseIcon, { Props } from './Icon';

const CalendarIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 4C8.25 3.86193 8.36193 3.75 8.5 3.75H11.5C11.6381 3.75 11.75 3.86193 11.75 4V5.25H8.25V4ZM6.75 5.25V4C6.75 3.0335 7.5335 2.25 8.5 2.25H11.5C12.4665 2.25 13.25 3.0335 13.25 4V5.25H16C17.5188 5.25 18.75 6.48122 18.75 8V8.98302C18.7503 8.99453 18.7503 9.00602 18.75 9.01747V15C18.75 16.5188 17.5188 17.75 16 17.75H4C2.48122 17.75 1.25 16.5188 1.25 15V9.01747C1.24973 9.00602 1.24973 8.99454 1.25 8.98303V8C1.25 6.48122 2.48122 5.25 4 5.25H6.75ZM2.75 10.0155V15C2.75 15.6904 3.30964 16.25 4 16.25H16C16.6904 16.25 17.25 15.6904 17.25 15V10.0155L12.2494 11.5541C12.2206 12.7718 11.2245 13.75 10 13.75C8.77545 13.75 7.77938 12.7718 7.75064 11.5541L2.75 10.0155ZM7.8054 10.0016L2.75 8.44607V8C2.75 7.30964 3.30964 6.75 4 6.75H16C16.6904 6.75 17.25 7.30964 17.25 8V8.44607L12.1946 10.0016C11.9678 8.99879 11.0714 8.25 10 8.25C8.92864 8.25 8.03218 8.99879 7.8054 10.0016ZM9.25 11.017C9.25027 11.0055 9.25026 10.994 9.25 10.9825V10.5C9.25 10.0858 9.58578 9.75 10 9.75C10.4142 9.75 10.75 10.0858 10.75 10.5V10.9825C10.7497 10.994 10.7497 11.0055 10.75 11.017V11.5C10.75 11.9142 10.4142 12.25 10 12.25C9.58578 12.25 9.25 11.9142 9.25 11.5V11.017Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CalendarIcon;
