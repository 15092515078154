export interface ZoomInfo {
  userId: string;
  password: string;
  meetingId: number;
  pstnPassword?: number;
  dialInfo?: ZoomDialInInfo[];
}

export interface ZoomDialInInfo {
  country: string;
  countryName: string;
  city: string;
  number: string;
  type: string;
}

export const MLoopBackendUrlNamespace = `mLoopBackendUrl`;
