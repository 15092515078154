import React, { FC } from 'react';

import { ModernLoopLoader } from '@modernloop/shared/helper-components';
import { Backdrop } from '@mui/material';

interface Props {
  open: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const LoadingBackdrop: FC<Props> = (props) => {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        color: '#fff',
      }}
      {...props}
    >
      <ModernLoopLoader color="white" />
    </Backdrop>
  );
};

export default LoadingBackdrop;
