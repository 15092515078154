import { OrgSettingsSource, Routes } from 'src/constants/routes';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { SettingsTabs } from 'src/views-new/Settings/types';

import getBrandingThemeUrl from './getBrandingThemeUrl';

/**
 * @deprecated use {@link useSyncedOrgSettingUrl} instead
 */
export const getOrgSettingsUrls = (source: OrgSettingsSource) => {
  switch (source) {
    case OrgSettingsSource.ORG_PRIVACY:
      return SettingsTabs.PRIVACY;
    case OrgSettingsSource.TEMPLATES:
      return Routes.SettingsTemplates;
    case OrgSettingsSource.BRANDING_THEME:
      return getBrandingThemeUrl();
    case OrgSettingsSource.SCHEDULING:
      return Routes.SettingsScheduling;
    default:
      return '';
  }
};
