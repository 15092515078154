import { Conflict } from 'src/store/slices/conflicts';

import { TimeBlock } from 'src/types/preferenes';

import { apiPost } from 'src/utils/api';
import { InterviewSchedule, inlineEmployees } from 'src/utils/api/getScheduleOptions';

import ApiVerb from './ApiVerb';

type DebriefOptionsRequest = {
  time: { ranges: TimeBlock[] };
  duration: number;
  interviewerIds: string[];
  hardConflicts: Omit<Conflict, 'isHardConflict' | 'eventUid' | 'summary'>[];
  applicationId: string;
};

export interface ResponseBody {
  schedules?: InterviewSchedule[];
  count: number;
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  employees?: { [id: string]: any };
}

const getDebriefOptions = async (data: DebriefOptionsRequest): Promise<ResponseBody> => {
  const response = await apiPost(ApiVerb.GET_DEBRIEF_OPTIONS, data);
  return inlineEmployees(response.data);
};

export default getDebriefOptions;
