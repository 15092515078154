import { DocumentNode } from '@apollo/client';
import { SxProps } from '@mui/material';
import { FC } from 'react';

export type BaseProps = {
  /**
   * To use for testing
   */
  dataTestId?: string;
};

type GeneratedFragmentTypes<T> = {
  [K in keyof T]: T[K];
};

export interface FCWithFragments<TFragments extends GeneratedFragmentTypes<TFragments>, TProps = object>
  extends FC<TProps & TFragments> {
  fragments: Record<keyof TFragments, DocumentNode>;
}

/**
 * This function doesn't really "do anything" at runtime, it's just the identity
 * function. Its only purpose is to defeat TypeScript's type widening.
 * This is a replacement for createStyles imported from `@material-ui/styles` since
 * we are not installing `@mui/styles` package for MUIv5
 *
 * @param styles a set of style mappings
 * @returns the same styles that were passed in
 */
export const createSxProps = <ClassKey extends string, Props extends SxProps = object>(
  styles: Record<ClassKey, Props>
): Record<ClassKey, Props> => {
  return styles;
};

export interface FunctionWithFragments<TFragments extends GeneratedFragmentTypes<TFragments>, TArgs, R>
  // eslint-disable-next-line @typescript-eslint/ban-types
  extends Function {
  (fragments: TFragments, args: TArgs): R;
  fragments: Record<keyof TFragments, DocumentNode>;
}
