import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Stack } from '@mui/material';

import { DebriefContentDetails_DebriefFragment } from 'src/generated/mloop-graphql';

import DebriefContentDetailsInterviewer from './DebriefContentDetailsInterviewer';
import DebriefContentDetailsMeetingRoom from './DebriefContentDetailsMeetingRoom';

type Fragments = {
  debrief: DebriefContentDetails_DebriefFragment;
};

const DebriefContentDetails: FCWithFragments<Fragments> = ({ debrief }) => {
  return (
    <Stack spacing={2} sx={{ pl: 2.5, pr: 1.5, py: 1 }}>
      {debrief.attendees?.map((interviewer) => (
        <DebriefContentDetailsInterviewer
          key={`${interviewer.interviewerId}-${interviewer.interviewerId}`}
          attendee={interviewer}
        />
      ))}

      {debrief.meetingRooms?.map((room) => (
        <DebriefContentDetailsMeetingRoom key={room.applicationDebriefId} meetingRoom={room} />
      ))}
    </Stack>
  );
};

DebriefContentDetails.fragments = {
  debrief: gql`
    ${DebriefContentDetailsInterviewer.fragments.attendee}
    ${DebriefContentDetailsMeetingRoom.fragments.meetingRoom}
    fragment DebriefContentDetails_debrief on ApplicationDebrief {
      id
      attendees {
        applicationDebriefId
        ...DebriefContentDetailsInterviewer_debrief
      }
      meetingRooms {
        applicationDebriefId
        meetingRoomId
        ...DebriefContentDetailsMeetingRoom_meetingRoom
      }
    }
  `,
};

export default DebriefContentDetails;
