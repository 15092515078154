import { SvgIcon, SvgIconProps } from './SvgIcon';

export const TemplateIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64582 2.17472C5.79566 1.88927 6.20433 1.88927 6.35416 2.17472L7.14361 3.67869C7.21731 3.81909 7.35322 3.9162 7.50992 3.94043L9.20748 4.20292C9.53358 4.25334 9.66181 4.65467 9.42537 4.88484L8.22644 6.05198C8.10958 6.16574 8.05554 6.32934 8.08166 6.49032L8.34767 8.13002C8.39977 8.45115 8.06711 8.69684 7.7755 8.55262L6.22166 7.7841C6.08196 7.715 5.91803 7.715 5.77833 7.7841L4.22448 8.55262C3.93287 8.69684 3.60021 8.45115 3.65231 8.13002L3.91832 6.49032C3.94444 6.32934 3.89041 6.16574 3.77355 6.05198L2.57461 4.88484C2.33817 4.65467 2.46641 4.25334 2.7925 4.20292L4.49006 3.94043C4.64676 3.9162 4.78268 3.81909 4.85637 3.67869L5.64582 2.17472ZM6.25 10.5C6.25 10.0858 6.58579 9.75 7 9.75H13C13.4142 9.75 13.75 10.0858 13.75 10.5C13.75 10.9142 13.4142 11.25 13 11.25H7C6.58579 11.25 6.25 10.9142 6.25 10.5ZM7 12.75C6.58579 12.75 6.25 13.0858 6.25 13.5C6.25 13.9142 6.58579 14.25 7 14.25H13C13.4142 14.25 13.75 13.9142 13.75 13.5C13.75 13.0858 13.4142 12.75 13 12.75H7ZM9.75 7.5C9.75 7.08579 10.0858 6.75 10.5 6.75H13C13.4142 6.75 13.75 7.08579 13.75 7.5C13.75 7.91421 13.4142 8.25 13 8.25H10.5C10.0858 8.25 9.75 7.91421 9.75 7.5ZM10.5 2.25C10.0858 2.25 9.75 2.58579 9.75 3C9.75 3.41421 10.0858 3.75 10.5 3.75H14C14.6903 3.75 15.25 4.30964 15.25 5L15.25 15C15.25 15.6904 14.6904 16.25 14 16.25H6.00001C5.30966 16.25 4.75001 15.6904 4.75001 15L4.75 10.5C4.75 10.0858 4.41421 9.75 4 9.75C3.58578 9.75 3.25 10.0858 3.25 10.5L3.25001 15C3.25001 16.5188 4.48123 17.75 6.00001 17.75H14C15.5188 17.75 16.75 16.5188 16.75 15L16.75 5C16.75 3.48122 15.5188 2.25 14 2.25H10.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default TemplateIcon;
