import React, { useRef, useState } from 'react';

import { gql } from '@apollo/client';
import {
  LinkIcon,
  NoneIcon,
  PhoneWithArrowPointingInwardsIcon,
  PhoneWithArrowPointingOutwardsIcon,
  TextIcon,
  TriangleDownIcon,
} from '@modernloop/shared/icons';
import {
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';

import {
  InterviewMeetingLocationType,
  useInterviewMeetingLocationMenuOrgIntegrationsQuery,
} from 'src/generated/mloop-graphql';

import { GoogleMeetIcon, MicrosoftTeamsIcon, ZoomIcon } from 'src/components/icons';

import { getInterviewMeetingLocationIcon, getInterviewMeetingLocationLabel } from './utils';

type Props = {
  disabled: boolean;
  interviewMeetingLocationType: InterviewMeetingLocationType;
  onChange: (interviewMeetingLocationType: InterviewMeetingLocationType) => void;
};

const InterviewMeetingLocationMenu = ({ disabled, interviewMeetingLocationType, onChange }: Props) => {
  const { data } = useInterviewMeetingLocationMenuOrgIntegrationsQuery();
  const hasZoom = Boolean(data?.thisOrg?.integrations?.zoom?.active);
  const hasMicrosoft = Boolean(
    data?.thisOrg?.integrations?.microsoft?.active || data?.thisOrg?.integrations?.microsoftGccHigh?.active
  );
  const hasGoogle = Boolean(data?.thisOrg?.integrations?.google?.adminUserId);

  const [open, setOpen] = useState(false);

  const locationMenuRef = useRef<HTMLButtonElement | null>(null);

  const handleMenuItemClick = (type: InterviewMeetingLocationType) => {
    onChange(type);
    setOpen(false);
  };

  const renderLabel = () => {
    const label = getInterviewMeetingLocationLabel(interviewMeetingLocationType);
    const icon = getInterviewMeetingLocationIcon(interviewMeetingLocationType);

    return (
      <Stack direction="row" spacing={1}>
        {icon}
        <Typography>{label}</Typography>
      </Stack>
    );
  };

  return (
    <>
      <Button
        data-testid={`interview-meeting-location-menu-button-${interviewMeetingLocationType}`}
        fullWidth
        disabled={disabled}
        ref={locationMenuRef}
        endIcon={<TriangleDownIcon />}
        sx={{ borderRadius: '6px', justifyContent: 'space-between' }}
        onClick={() => setOpen(true)}
      >
        {renderLabel()}
      </Button>
      <Menu
        open={open}
        anchorEl={locationMenuRef.current}
        slotProps={{ paper: { sx: { width: '400px' } } }}
        onClose={() => setOpen(false)}
      >
        <ListSubheader>Video conferencing</ListSubheader>

        {hasZoom && (
          <MenuItem
            data-testid={`interview-meeting-location-menu-item-${InterviewMeetingLocationType.Zoom}`}
            value={InterviewMeetingLocationType.Zoom}
            onClick={() => handleMenuItemClick(InterviewMeetingLocationType.Zoom)}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <ListItemIcon>
                <ZoomIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography>Zoom</Typography>
                <Typography variant="caption" color="text.secondary" sx={{ textWrap: 'wrap' }}>
                  Zoom video conferencing link.
                </Typography>
              </ListItemText>
            </Stack>
          </MenuItem>
        )}

        {hasGoogle && (
          <MenuItem
            data-testid={`interview-meeting-location-menu-item-${InterviewMeetingLocationType.GoogleMeet}`}
            value={InterviewMeetingLocationType.GoogleMeet}
            onClick={() => handleMenuItemClick(InterviewMeetingLocationType.GoogleMeet)}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <ListItemIcon>
                <GoogleMeetIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography>Google Meet</Typography>
                <Typography variant="caption" color="text.secondary" sx={{ textWrap: 'wrap' }}>
                  Google Meet video conferencing link.
                </Typography>
              </ListItemText>
            </Stack>
          </MenuItem>
        )}

        {hasMicrosoft && (
          <MenuItem
            data-testid={`interview-meeting-location-menu-item-${InterviewMeetingLocationType.MicrosoftTeams}`}
            value={InterviewMeetingLocationType.MicrosoftTeams}
            onClick={() => handleMenuItemClick(InterviewMeetingLocationType.MicrosoftTeams)}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <ListItemIcon>
                <MicrosoftTeamsIcon />
              </ListItemIcon>
              <ListItemText>
                <Typography>Microsoft Teams</Typography>
                <Typography variant="caption" color="text.secondary" noWrap={false}>
                  Microsoft Teams video conferencing link.
                </Typography>
              </ListItemText>
            </Stack>
          </MenuItem>
        )}

        <MenuItem
          data-testid={`interview-meeting-location-menu-item-${InterviewMeetingLocationType.CustomLink}`}
          value={InterviewMeetingLocationType.CustomLink}
          onClick={() => handleMenuItemClick(InterviewMeetingLocationType.CustomLink)}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>Custom link</Typography>
              <Typography variant="caption" color="text.secondary" sx={{ textWrap: 'wrap' }}>
                Set any link as location.
              </Typography>
            </ListItemText>
          </Stack>
        </MenuItem>

        <Divider />

        <ListSubheader>Phone number</ListSubheader>

        <MenuItem
          data-testid={`interview-meeting-location-menu-item-${InterviewMeetingLocationType.CandidatePhone}`}
          value={InterviewMeetingLocationType.CandidatePhone}
          onClick={() => handleMenuItemClick(InterviewMeetingLocationType.CandidatePhone)}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <ListItemIcon>
              <PhoneWithArrowPointingOutwardsIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>Candidate phone number</Typography>
              <Typography variant="caption" color="text.secondary" sx={{ textWrap: 'wrap' }}>
                Interviewer(s) will call the candidate phone number that is set in ModernLoop.
              </Typography>
            </ListItemText>
          </Stack>
        </MenuItem>

        <MenuItem
          data-testid={`interview-meeting-location-menu-item-${InterviewMeetingLocationType.CustomPhone}`}
          value={InterviewMeetingLocationType.CustomPhone}
          onClick={() => handleMenuItemClick(InterviewMeetingLocationType.CustomPhone)}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <ListItemIcon>
              <PhoneWithArrowPointingInwardsIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>Custom phone number</Typography>
              <Typography variant="caption" color="text.secondary" sx={{ textWrap: 'wrap' }}>
                Set a custom phone number for the candidate to call.
              </Typography>
            </ListItemText>
          </Stack>
        </MenuItem>

        <Divider />

        <ListSubheader>Other</ListSubheader>

        <MenuItem
          data-testid={`interview-meeting-location-menu-item-${InterviewMeetingLocationType.CustomText}`}
          value={InterviewMeetingLocationType.CustomText}
          onClick={() => handleMenuItemClick(InterviewMeetingLocationType.CustomText)}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <ListItemIcon>
              <TextIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>Custom text</Typography>
              <Typography variant="caption" color="text.secondary" sx={{ textWrap: 'wrap' }}>
                Share an address or specific details about the location.
              </Typography>
            </ListItemText>
          </Stack>
        </MenuItem>

        <MenuItem
          data-testid={`interview-meeting-location-menu-item-${InterviewMeetingLocationType.NotSpecified}`}
          value={InterviewMeetingLocationType.NotSpecified}
          onClick={() => handleMenuItemClick(InterviewMeetingLocationType.NotSpecified)}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <ListItemIcon>
              <NoneIcon />
            </ListItemIcon>
            <ListItemText>None</ListItemText>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InterviewMeetingLocationMenuOrgIntegrations = gql`
  query InterviewMeetingLocationMenuOrgIntegrations {
    thisOrg {
      id
      integrations {
        google {
          adminUserId
        }
        microsoft {
          active
        }
        microsoftGccHigh {
          active
        }
        zoom {
          active
        }
      }
    }
  }
`;

export default InterviewMeetingLocationMenu;
