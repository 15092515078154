/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { PlusIcon } from '@modernloop/shared/icons';
import { CircularProgress, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { isAfter } from 'date-fns';
import { last, sortBy } from 'lodash';

import {
  RescheduleLogModal_RescheduleLogFragment,
  SourceType,
  TaskStatus,
  useScheduleTaskRequirementsQuery,
  useTaskSetDebriefRequiredMutation,
} from 'src/generated/mloop-graphql';

import ZeroState from 'src/components/ZeroState';
import Button from 'src/components/button';
import { CrossIcon } from 'src/components/icons';
import Label from 'src/components/label';

import { useFeatureFlagNumber } from 'src/hooks/feature-flag';
import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useIsApplicationRejected from 'src/hooks/useIsApplicationRejected';

import Availability from './Availability';
import TaskRequestChangeModal from './Common/TaskRequestChangeModal';
import Debrief from './Debrief';
import RescheduleLogsList from './RescheduleLogsList';
import RescheduleLogModal from './RescheduleLogsList/RescheduleLogModal';
import Schedule from './Schedule';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleTaskRequirementsProps = {
  taskId: string;
  isCancelled: boolean;
};

const ScheduleTaskRequirements = ({ taskId, isCancelled }: ScheduleTaskRequirementsProps) => {
  const [showRequestChangeModal, setShowRequestChangeModal] = useState(false);
  const [showRescheduleLogModal, setShowRescheduleLogModal] = useState(false);
  const [rescheduleLog, setRescheduleLog] = useState<RescheduleLogModal_RescheduleLogFragment>();
  const taskPolling = useFeatureFlagNumber('user_task_polling_seconds');

  const refetchActivityFeed = useActivityFeedRefetch(SourceType.SourceTypeTask);
  const [taskSetDebriefRequiredMutation] = useTaskSetDebriefRequiredMutation();

  const { data, error, loading } = useScheduleTaskRequirementsQuery({
    variables: { scheduleTaskId: taskId },
    fetchPolicy: 'cache-and-network',
    pollInterval: taskPolling ? taskPolling * 1000 : undefined,
  });

  const { application, interviewPlan, isDebriefRequired, jobStage, status } = data?.task || {};
  const { candidate } = application || {};

  const isApplicationRejected = useIsApplicationRejected(application?.status);

  /**
   * This is a temporary work around to get the latest data.
   * BE is going to add a comparator and send the sorted array soon.
   */
  const taskSelfScheduleRequest = data?.task?.selfScheduleRequests
    ? last(
        sortBy(data?.task?.selfScheduleRequests, (selfScheduleRequest) => {
          return new Date(selfScheduleRequest.createdAt);
        })
      )
    : null;

  /**
   * This is a temporary work around to get the latest data.
   * BE is going to add a comparator and send the sorted array soon.
   */
  const taskSchedule = data?.task?.schedules
    ? last(
        sortBy(data?.task?.schedules, (schedule) => {
          return new Date(schedule.createdAt);
        })
      )
    : null;

  /**
   * This is a temporary work around to get the latest data.
   * BE is going to add a comparator and send the sorted array soon.
   */
  const taskAvailabilityRequest = data?.task?.availabilityRequests
    ? last(
        sortBy(data?.task?.availabilityRequests, (availabilityRequest) => {
          return new Date(availabilityRequest.createdAt);
        })
      )
    : null;

  /**
   * This is a temporary work around to get the latest data.
   * BE is going to add a comparator and send the sorted array soon.
   */
  const taskAvailability = data?.task?.availabilities
    ? last(
        sortBy(data?.task?.availabilities, (availability) => {
          return new Date(availability.createdAt);
        })
      )
    : null;

  const handleAddDebriefClick = useCallback(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    taskSetDebriefRequiredMutation({
      variables: {
        input: {
          taskId,
          isDebriefRequired: true,
        },
      },
      optimisticResponse: {
        taskSetDebriefRequired: {
          id: taskId,
          isDebriefRequired: true,
        },
      },
    }).then(() => refetchActivityFeed());
  }, [refetchActivityFeed, taskId, taskSetDebriefRequiredMutation]);

  const hideDebriefIfCancelled = useMemo(
    () => isCancelled && !data?.task?.debriefs[0]?.id,
    [data?.task?.debriefs, isCancelled]
  );

  const hideScheduleIfCancelled = useMemo(() => isCancelled && !taskSchedule?.id, [isCancelled, taskSchedule?.id]);
  const availabilityRequestedAfterSchedule =
    taskSchedule?.id && isAfter(new Date(taskAvailabilityRequest?.updatedAt), new Date(taskSchedule?.updatedAt));
  const availabilityReceivedAfterSchedule =
    taskSchedule?.id &&
    taskAvailabilityRequest &&
    isAfter(new Date(taskAvailability?.updatedAt), new Date(taskSchedule?.updatedAt)) &&
    status !== TaskStatus.WaitingForCandidate;

  // checks if the tasks is cancelled and none of the sub-sections are completed
  if (hideDebriefIfCancelled && hideScheduleIfCancelled) {
    return null;
  }

  if (loading && !data?.task) return <CircularProgress />;

  if (error) {
    return (
      <ZeroState
        icon={<CrossIcon color="error" fontSize={32} />}
        label={
          <Stack justifyItems="center">
            <Label>Error fetching requirements for task</Label>
            <Label variant="captions" color="high-contrast-grey">
              {error.message}
            </Label>
          </Stack>
        }
      />
    );
  }

  if (!application || !candidate || !jobStage || !interviewPlan) return null;

  return (
    <Stack spacing={2}>
      <Divider />
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Label fontWeight={600}>Requirements</Label>
          {!isCancelled && (
            <Stack direction="row" spacing={1}>
              <Tooltip title={isApplicationRejected ? 'Application rejected' : ''}>
                <span>
                  <Button
                    variant="outlined"
                    size="small"
                    label="Request a change"
                    disabled={isApplicationRejected}
                    onClick={() => setShowRequestChangeModal(true)}
                  />
                </span>
              </Tooltip>
              {!isDebriefRequired && (
                <Button variant="outlined" size="small" label="Add debrief" onClick={handleAddDebriefClick} />
              )}
            </Stack>
          )}
        </Stack>
        {!isCancelled && (
          <Availability
            taskId={taskId}
            application={application}
            availability={taskAvailability}
            candidate={candidate}
            jobStage={jobStage}
            availabilityRequest={taskAvailabilityRequest}
            interviewPlan={interviewPlan}
            selfScheduleRequestId={taskSelfScheduleRequest?.id}
            schedule={taskSchedule}
            status={status}
            availabilityRequestedAfterSchedule={availabilityRequestedAfterSchedule}
            availabilityReceivedAfterSchedule={availabilityReceivedAfterSchedule}
          />
        )}

        {!hideScheduleIfCancelled && (
          <Schedule
            application={application}
            availabilityRequestId={taskAvailabilityRequest?.id}
            candidate={candidate}
            jobStage={jobStage}
            taskId={taskId}
            jobStageId={jobStage.id}
            customJobStageId={interviewPlan.id}
            interviewPlan={interviewPlan}
            selfScheduleRequest={taskSelfScheduleRequest || undefined}
            schedule={taskSchedule || undefined}
            availabilityRequestedAfterSchedule={availabilityRequestedAfterSchedule}
            availabilityReceivedAfterSchedule={availabilityReceivedAfterSchedule}
          />
        )}
        {data?.task && isDebriefRequired && !hideDebriefIfCancelled && (
          <Debrief debriefRequirements={data.task} isCancelled={isCancelled} />
        )}
        {showRequestChangeModal && (
          <TaskRequestChangeModal taskId={taskId} onClose={() => setShowRequestChangeModal(false)} />
        )}

        <>
          <Divider />
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle2">Reschedule log</Typography>
            <IconButton
              onClick={() => {
                setRescheduleLog(undefined);
                setShowRescheduleLogModal(true);
              }}
            >
              <PlusIcon />
            </IconButton>
            {showRescheduleLogModal && (
              <RescheduleLogModal
                rescheduleLog={rescheduleLog}
                taskId={taskId}
                onClose={() => setShowRescheduleLogModal(false)}
              />
            )}
          </Stack>
          <RescheduleLogsList
            rescheduleLogs={data?.task?.rescheduleLogs.items}
            onModalClose={setShowRescheduleLogModal}
            onRescheduleLogSelect={setRescheduleLog}
          />
        </>
      </Stack>
    </Stack>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ScheduleTaskRequirementsQuery = gql`
  ${Availability.fragments.application}
  ${Availability.fragments.availability}
  ${Availability.fragments.availabilityRequest}
  ${Availability.fragments.candidate}
  ${Availability.fragments.interviewPlan}
  ${Availability.fragments.jobStage}
  ${Availability.fragments.schedule}
  ${Schedule.fragments.application}
  ${Schedule.fragments.jobStage}
  ${Schedule.fragments.candidate}
  ${Schedule.fragments.selfScheduleRequest}
  ${Schedule.fragments.schedule}
  ${Debrief.fragments.debriefRequirements}
  ${RescheduleLogModal.fragments.rescheduleLog}
  ${RescheduleLogsList.fragments.rescheduleLogs}
  ${Schedule.fragments.interviewPlan}

  query ScheduleTaskRequirements($scheduleTaskId: uuid!) {
    task(id: $scheduleTaskId) {
      id
      status
      rescheduleLogs(input: { pageInput: { offset: 0, cursor: "", limit: 100 } }) {
        items {
          ...RescheduleLogsList_rescheduleLogs
        }
      }
      application {
        ...Availability_application
        ...Schedule_application
        candidate {
          ...Schedule_candidate
          ...Availability_candidate
        }
      }
      availabilities {
        id
        createdAt

        ...Availability_availability
      }
      availabilityRequests {
        id
        createdAt

        ...Availability_availabilityRequest
      }
      interviewPlan {
        ...Availability_interviewPlan
        ...Schedule_interviewPlan
      }
      jobStage {
        ...Availability_jobStage
        ...Schedule_jobStage
      }
      selfScheduleRequests {
        id
        createdAt

        ...Schedule_selfScheduleRequest
      }
      schedules {
        id
        createdAt
        updatedAt
        ...Availability_schedule
        ...Schedule_schedule
      }
      ...Debrief_debriefRequirements
    }
  }
`;

export default ScheduleTaskRequirements;
