import React, { useEffect } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';

import {
  DateTimeRangeInput,
  ScheduleInterviewPlan_JobFragment,
  ScheduleInterviewPlan_JobStageFragment,
} from 'src/generated/mloop-graphql';

import TwoColumnLayout from 'src/components/Layout/TwoColumnLayout';
import Stack from 'src/components/Stack';
import Button from 'src/components/button';
import { CaretLeftIcon } from 'src/components/icons';

import InterviewPlan from 'src/entities/InterviewPlan';
import { useResetInterviewPlanToBaseJobStage } from 'src/entities/InterviewPlan/useResetInterviewPlanToBaseJobStage';

import { createJobStagePlanConfig } from 'src/store/actions/job-stage-plan-config';
import { getLeftNavExpanded } from 'src/store/selectors/ui-state';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useDispatch, useSelector } from 'src/store';

import ScheduleIssuesButton from '../Common/ScheduleIssues/ScheduleIssuesButton';

import InterviewConfig from './InterviewConfig';
import InterviewPlanPreview from './InterviewPlanPreview';

type Props = {
  jobStageId: string;
  jobStageLoading?: boolean;
  schedulable: boolean;
  scheduleFlowType: ScheduleFlowType;
  readonly?: boolean;
  candidateAvailabilities?: DateTimeRangeInput[];
  footer?: React.ReactNode;
  onUpdated: (jobStage: ScheduleInterviewPlan_JobStageFragment) => void;
  gotoNextStep?: () => void;
  gotoPrevStep?: () => void;
};

type Fragments = {
  job: ScheduleInterviewPlan_JobFragment | undefined;
  jobStage: ScheduleInterviewPlan_JobStageFragment | undefined;
};

export const ScheduleInterviewPlan: FCWithFragments<Fragments, Props> = ({
  job,
  jobStage,
  jobStageId,
  jobStageLoading: jobStageFragmentLoading,
  schedulable,
  scheduleFlowType,
  readonly,
  candidateAvailabilities,
  footer,
  onUpdated,
  gotoPrevStep,
  gotoNextStep,
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const leftNavExpanded = useSelector(getLeftNavExpanded);

  const { resetPlan, isLoading } = useResetInterviewPlanToBaseJobStage({
    jobStageId,
    customJobStageId: jobStage?.id,
    doNotPersist: true,
  });

  const handleResetToJobStageDefault = async () => {
    if (isLoading) return;

    const baseInterviewPlan = await resetPlan();

    if (!baseInterviewPlan) return;
    onUpdated({ ...baseInterviewPlan });
  };

  useEffect(() => {
    if (!jobStage) return;
    dispatch(createJobStagePlanConfig(jobStage.id));
  }, [dispatch, jobStage]);

  const interviewPlanJobStageId = jobStage?.id || '';

  const left = (
    <Stack direction="column" spacing={2} wrap="nowrap">
      {schedulable && (
        <Stack direction="column" spacing={2}>
          <Stack justifyContent="space-between">
            <Button startIcon={<CaretLeftIcon />} variant="outlined" label="Availability" onClick={gotoPrevStep} />
            <ScheduleIssuesButton />
          </Stack>
        </Stack>
      )}
      <InterviewPlan
        job={job}
        jobStage={jobStage}
        jobStageLoading={jobStageFragmentLoading}
        schedulable={schedulable}
        readonly={readonly}
        candidateAvailabilities={candidateAvailabilities}
        footer={footer}
        onUpdated={onUpdated}
        onResetToJobStageDefault={handleResetToJobStageDefault}
      />
      {footer}
      {schedulable && <InterviewConfig jobStageId={interviewPlanJobStageId} />}
    </Stack>
  );

  const right = jobStage ? (
    <InterviewPlanPreview
      jobStage={jobStage}
      jobStageId={interviewPlanJobStageId}
      schedulable={schedulable}
      scheduleFlowType={scheduleFlowType}
      gotoNextStep={gotoNextStep}
    />
  ) : null;

  return (
    <TwoColumnLayout leftComponent={left} rightComponent={right} mobileBreakpoint={leftNavExpanded ? 'md' : 'sm'} />
  );
};

ScheduleInterviewPlan.fragments = {
  job: gql`
    fragment ScheduleInterviewPlan_job on Job {
      id
      atsId
    }
  `,
  jobStage: gql`
    ${InterviewPlan.fragments.jobStage}
    ${InterviewPlanPreview.fragments.jobStage}
    fragment ScheduleInterviewPlan_jobStage on JobStage {
      id
      ...InterviewPlanPreview_jobStage
      ...InterviewPlan_jobStage
    }
  `,
};

export default ScheduleInterviewPlan;
