import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { CrossIcon, EditIcon, LinkIcon } from '@modernloop/shared/icons';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import {
  LinkedSeat_LinkedInterviewFragment,
  LinkedSeat_OriginalSeatFragment,
  LinkedSeat_SeatFragment,
} from 'src/generated/mloop-graphql';

import Chip from 'src/components/chip';
import ModulesIcon from 'src/components/icons/Modules';
import Tooltip from 'src/components/tooltip';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

import { useAvatarSxProps, useGridSxProps, useSxProps } from './sxProps';

interface Props {
  handleMouseOut: () => void;
  handleMouseOver: () => void;
  seatIndex: number;
  onEdit: () => void;
  showOptions: boolean;
  onDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface Fragments {
  seat: LinkedSeat_SeatFragment;
  originalSeat: LinkedSeat_OriginalSeatFragment;
  linkedInterview: LinkedSeat_LinkedInterviewFragment;
}

const LinkedSeat: FCWithFragments<Fragments, Props> = ({
  seat,
  originalSeat,
  linkedInterview,
  handleMouseOut,
  handleMouseOver,
  onEdit,
  seatIndex,
  showOptions,
  onDelete,
}) => {
  const sxProps = useSxProps();
  const gridSxProps = useGridSxProps();
  const avatarSxProps = useAvatarSxProps();

  const originalSeatIndex = linkedInterview?.jobStageInterviewSeats?.findIndex((s) => s.id === originalSeat?.id) ?? 0;

  let label;
  if (originalSeat?.freeformSeat) {
    if (!originalSeat.freeformSeat.jobStageInterviewSeatEmployees) {
      label = '';
    } else if (originalSeat.freeformSeat.jobStageInterviewSeatEmployees.length > 1) {
      const text = `(1 of ${originalSeat.freeformSeat.jobStageInterviewSeatEmployees.length} people)`;
      label = (
        <Typography data-testid="linked-seat-multiple-employee-seat-label" sx={sxProps.linkedInterviewLabel}>
          {text}
        </Typography>
      );
    } else if (originalSeat.freeformSeat.jobStageInterviewSeatEmployees.length === 1) {
      const employee = originalSeat?.freeformSeat?.jobStageInterviewSeatEmployees?.[0]?.employee;
      label = (
        <Typography sx={sxProps.linkedInterviewLabel} data-testid="linked-seat-single-employee-seat-label">
          (
          <Chip
            key={employee?.id}
            label={<MaybeTooltip tooltip={employee?.fullName ?? ''} label={employee?.fullName ?? ''} />}
            sx={sxProps.linkedSeatChip}
            avatar={<Avatar src={employee?.slackImageUrl ?? undefined} />}
            variant={showOptions ? 'outlined' : 'default'}
          />
          )
        </Typography>
      );
    }
  } else if (originalSeat?.moduleSeat) {
    const { interviewModule } = originalSeat.moduleSeat;

    if (!interviewModule) return null;

    label = (
      <Typography data-testid="linked-seat-module-seat-label" sx={sxProps.linkedInterviewLabel}>
        (
        <Chip
          key={interviewModule.id}
          label={<MaybeTooltip tooltip={interviewModule.name} label={interviewModule.name} />}
          sx={sxProps.linkedSeatChip}
          avatar={<ModulesIcon />}
          variant={showOptions ? 'outlined' : 'default'}
        />
        &nbsp;)
      </Typography>
    );
  }

  const linkedInterviewLabel =
    (linkedInterview.name ?? 'Interview name') +
    (linkedInterview.jobStageInterviewSeats && linkedInterview.jobStageInterviewSeats.length > 1
      ? ` (Seat ${originalSeatIndex + 1})`
      : '');

  return (
    <Grid>
      <Box
        data-testid="linked-seat-box-container"
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        sx={sxProps.box}
        onClick={onEdit}
      >
        <Grid container justifyContent="space-between" sx={gridSxProps.root} spacing={1} tabIndex={0} wrap="nowrap">
          <Grid xs={10}>
            <Grid container alignItems="center">
              <Grid sx={sxProps.one}>
                <Avatar sx={avatarSxProps.root}>{seatIndex + 1}</Avatar>
              </Grid>
              <Grid sx={sxProps.one}>
                <Typography>Same as:</Typography>
              </Grid>
              <Grid style={{ overflow: 'hidden', maxWidth: '100%' }}>
                <Chip
                  key={seat.id}
                  dataTestId="linked-seat-chip"
                  sx={sxProps.linkedInterviewChip}
                  label={
                    <MaybeTooltip
                      tooltip={linkedInterviewLabel}
                      label={linkedInterviewLabel}
                      labelProps={{ color: 'info' }}
                    />
                  }
                  avatar={<LinkIcon />}
                  variant={showOptions ? 'outlined' : 'default'}
                />
              </Grid>
              <Grid sx={[sxProps.one, { overflow: 'hidden', maxWidth: '100%' }]}>{label}</Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" alignItems="center" xs sx={sxProps.options} wrap="nowrap">
            <Grid>
              <Tooltip tooltip="Edit">
                <IconButton
                  sx={sxProps.actionButton}
                  onClick={onEdit}
                  size="small"
                  data-testid="linked-seat-edit-icon-button"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid>
              <Tooltip tooltip="Delete">
                <IconButton
                  sx={sxProps.actionButton}
                  onClick={onDelete}
                  size="small"
                  data-testid="linked-seat-delete-icon-button"
                >
                  <CrossIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

LinkedSeat.fragments = {
  seat: gql`
    fragment LinkedSeat_seat on JobStageInterviewSeat {
      id
      linkedSeat {
        linkedJobStageInterviewSeatId
      }
    }
  `,
  linkedInterview: gql`
    fragment LinkedSeat_linkedInterview on JobStageInterview {
      id
      name
      jobStageInterviewSeats {
        id
      }
    }
  `,
  originalSeat: gql`
    fragment LinkedSeat_originalSeat on JobStageInterviewSeat {
      id
      freeformSeat {
        jobStageInterviewSeatEmployees {
          employeeId
          employee {
            id
            fullName
            slackImageUrl
          }
        }
      }
      moduleSeat {
        interviewModuleId
        interviewModule {
          id
          name
        }
      }
    }
  `,
};

export default LinkedSeat;
