import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { getExternalErrorMessage, logDatadogError } from '@modernloop/shared/utils';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  DeleteModuleLogModal_ModuleMemberFragment,
  DeleteModuleLogModal_ModuleMemberInterviewFragment,
  ModuleMemberInterviewStatus,
  TrainingStatus,
  useInterviewModuleMemberLogDeleteMutation,
} from 'src/generated/mloop-graphql';

import { resetModuleMemberListCache } from 'src/entities/InterviewModuleMember/utils';

import { apolloCacheDeleteById } from 'src/utils/apolloHelpers';

import RoleChangeAlert from '../RoleChangeAlert';

type Props = {
  onClose: () => void;
  onDelete: () => void;
};
type Fragments = {
  moduleMember: DeleteModuleLogModal_ModuleMemberFragment;
  moduleMemberInterview: DeleteModuleLogModal_ModuleMemberInterviewFragment;
};

const DeleteModuleLogModal: FCWithFragments<Fragments, Props> = ({
  onClose,
  onDelete,
  moduleMemberInterview,
  moduleMember,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModuleLog, { loading }] = useInterviewModuleMemberLogDeleteMutation();

  const roleImpacted: TrainingStatus | null = useMemo(() => {
    if (
      moduleMemberInterview.status !== ModuleMemberInterviewStatus.DidntCount &&
      moduleMember.status === TrainingStatus.ReverseShadow &&
      moduleMemberInterview.trainingRole === TrainingStatus.Shadow &&
      moduleMember.stats?.manualCompletedAsShadow + moduleMember.stats?.completedAsShadow - 1 <
        moduleMember.shadowsRequired
    ) {
      return TrainingStatus.Shadow;
    }

    return null;
  }, [
    moduleMember.stats?.manualCompletedAsShadow,
    moduleMember.shadowsRequired,
    moduleMember.stats?.completedAsShadow,
    moduleMember.status,
    moduleMemberInterview.status,
    moduleMemberInterview.trainingRole,
  ]);

  const deleteLog = async () => {
    try {
      await deleteModuleLog({
        variables: {
          input: {
            id: moduleMemberInterview.id,
          },
        },
        refetchQueries: ['InterviewModuleMemberSidepanelQuery'],
        awaitRefetchQueries: true,
        update: (cache) => {
          apolloCacheDeleteById(cache, moduleMemberInterview.id, 'ModuleMemberManualLog');
          if (roleImpacted) {
            resetModuleMemberListCache({
              cache,
              moduleId: moduleMember.interviewModuleId,
              employeeIds: [moduleMember.employeeId],
            });
          }
        },
      });
      enqueueSnackbar('Module log deleted', { variant: 'success' });
      onDelete();
    } catch (error) {
      logDatadogError(error);
      enqueueSnackbar(getExternalErrorMessage(error), { variant: 'error' });
    }
  };

  return (
    <Dialog
      cancelOptions={{ label: 'Cancel', onClick: onClose }}
      decoration={
        <img src="/static/images/setup/trash_modal.svg" alt="Edit training plan" height="64px" width="64px" />
      }
      submitOptions={{
        label: 'Delete log',
        onClick: deleteLog,
        isDisabled: loading,
        isDangerous: true,
        endIcon: loading ? <CircularProgress color="inherit" size="16px" /> : undefined,
      }}
      title="Delete log?"
      open
      maxWidth="xs"
      onClose={onClose}
    >
      <Stack spacing={1}>
        <Typography>This cannot be undone</Typography>
        <RoleChangeAlert roleImpacted={roleImpacted} />
      </Stack>
    </Dialog>
  );
};

DeleteModuleLogModal.fragments = {
  moduleMemberInterview: gql`
    fragment deleteModuleLogModal_moduleMemberInterview on ModuleMemberInterview {
      id
      trainingRole
      status
    }
  `,
  moduleMember: gql`
    fragment deleteModuleLogModal_moduleMember on InterviewModuleMember {
      employeeId
      interviewModuleId
      shadowsRequired
      status
      stats {
        completedAsShadow
        manualCompletedAsShadow
      }
    }
  `,
};

export const DeleteManualLogMutation = gql`
  mutation InterviewModuleMemberLogDelete($input: InterviewModuleMemberLogDeleteInput!) {
    interviewModuleMemberLogDelete(input: $input) {
      result
    }
  }
`;

export default DeleteModuleLogModal;
