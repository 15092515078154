import { getLocalTimezone } from '@modernloop/shared/datetime';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/**
 * Using `unknown` here because we don't want to leak the complex types to ui-state.
 */
export type CandidateListFilters = {
  orderBy?: unknown;
};

export type UIState = {
  displayTimezone: string;
  leftNavExpanded: boolean;
  candidateListFilters: CandidateListFilters;
};

const initialState: UIState = {
  displayTimezone: getLocalTimezone(),
  leftNavExpanded: false,
  candidateListFilters: {},
};

const uiStateSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    setDisplayTimezone(state: UIState, action: PayloadAction<string>) {
      const { payload } = action;
      state.displayTimezone = payload;
    },

    setLeftNavExpanded(state: UIState, action: PayloadAction<boolean>) {
      const { payload } = action;
      state.leftNavExpanded = payload;
    },

    setCandidateListFilters(state: UIState, action: PayloadAction<CandidateListFilters>) {
      const { payload } = action;
      state.candidateListFilters = payload;
    },
  },
});

export const { reducer } = uiStateSlice;
export default uiStateSlice;
