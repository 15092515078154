/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { Stack } from '@mui/material';

import {
  ActivityLogBase_ActivityLogFragment,
  ActorType,
  ResponseStatus,
  SourceType,
} from 'src/generated/mloop-graphql';

import DurationLabel from 'src/components/DurationLabel';
import Link from 'src/components/Link';
import MoreMenuButton, { MoreMenuButtonProps } from 'src/components/MoreMenuButton';
import Tooltip from 'src/components/tooltip';
import { FCWithFragments } from 'src/components/types';

import TaskName from 'src/entities/common/TaskName';

import { formatToTimeZone } from 'src/utils/datetime/Format';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

import ActivityLogAvatar from '../ActivityLogAvatar';
import ActivityLogText from '../ActivityLogText';

interface Fragment {
  activityLog: ActivityLogBase_ActivityLogFragment;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface ActivityLogBaseProps {
  dataTestId?: string;
  actionLabel?: string | JSX.Element;
  actionLabelTooltip?: string;
  responseStatus?: ResponseStatus | null | undefined;
  moreMenuProps?: MoreMenuButtonProps;
  avatar?: JSX.Element;
  actorName?: string;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const ActivityLogBase: FCWithFragments<Fragment, ActivityLogBaseProps> = ({
  dataTestId,
  activityLog,
  actionLabel,
  actionLabelTooltip,
  responseStatus,
  moreMenuProps,
  children,
  avatar,
  actorName: actorNameProp,
}) => {
  const { activityActorType, actorEmployee, source, sourceType, createdAt: timestamp } = activityLog;

  const actorName = useMemo(() => {
    if (actorNameProp) {
      return actorNameProp;
    }

    switch (activityActorType) {
      case ActorType.Employee:
        return actorEmployee?.fullName ?? '';
      case ActorType.System:
        return 'ModernLoop';
      case ActorType.Candidate:
        return 'Candidate';
      default:
        return '';
    }
  }, [activityActorType, actorEmployee?.fullName, actorNameProp]);

  return (
    <Stack direction="row" spacing={1}>
      {avatar ?? <ActivityLogAvatar actorName={actorName} activityLog={activityLog} responseStatus={responseStatus} />}

      <Stack direction="column" spacing={0.5} sx={{ paddingTop: '1px' }}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <ActivityLogText dataTestId={dataTestId} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <ActivityLogText fontWeight={600} color="foreground" noWrap>
              {actorName}&nbsp;
            </ActivityLogText>
            <ActivityLogText tooltip={actionLabelTooltip}>{actionLabel}</ActivityLogText>
            <ActivityLogText>&nbsp;·&nbsp;</ActivityLogText>

            <Tooltip tooltip={formatToTimeZone(timestamp, 'MMMM d, yyyy · hh:mm a', getLocalTimezone())} displayUnset>
              <DurationLabel
                timestamp={timestamp}
                labelProps={{
                  variant: 'captions',
                  fontWeight: 400,
                  color: 'high-contrast-grey',
                  noWrap: true,
                  component: 'span',
                }}
              />
            </Tooltip>

            {/**
             * source?.count !== undefined is a workaround for checking if the fragment was returned by BE.
             * We can look for a better check in future.
             */}

            {sourceType === SourceType.SourceTypeTask && source && source.__typename === 'Task' && source.name ? (
              <ActivityLogText>
                &nbsp;
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  component="button"
                  style={{
                    textDecorationThickness: '1.5px',
                  }}
                  href=""
                  onClick={() => {
                    SidePanelManager.openScheduleTaskSidePanel(source.id);
                  }}
                >
                  <ActivityLogText color="info">
                    (<TaskName task={source} />)
                  </ActivityLogText>
                </Link>
              </ActivityLogText>
            ) : null}
          </ActivityLogText>
          {moreMenuProps ? <MoreMenuButton size="small" {...moreMenuProps} /> : null}
        </Stack>

        {children}
      </Stack>
    </Stack>
  );
};

ActivityLogBase.fragments = {
  activityLog: gql`
    ${ActivityLogAvatar.fragments.activityLog}
    ${TaskName.fragments.task}
    fragment ActivityLogBase_activityLog on ActivityLog {
      id
      source {
        ... on Task {
          id
          __typename
        }
        ... on Task @include(if: $showTaskName) {
          ...TaskName_task
        }
      }
      createdAt
      sourceType
      activityActorType
      actorEmployee {
        id
        fullName
        slackImageUrl
      }

      ...ActivityLogAvatar_activityLog
    }
  `,
};

export default ActivityLogBase;
