import React from 'react';

import BaseIcon, { Props } from './Icon';

const DurationIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.75C9.92142 3.75 9.84322 3.75145 9.76542 3.75431C9.35149 3.76955 9.00357 3.44635 8.98833 3.03242C8.97309 2.61848 9.29629 2.27057 9.71023 2.25533C9.80641 2.25179 9.90302 2.25 10 2.25C14.2802 2.25 17.75 5.71979 17.75 10C17.75 14.2802 14.2802 17.75 10 17.75C5.71979 17.75 2.25 14.2802 2.25 10C2.25 9.90302 2.25179 9.80641 2.25533 9.71023C2.27057 9.29629 2.61848 8.97309 3.03242 8.98833C3.44635 9.00357 3.76955 9.35149 3.75431 9.76542C3.75145 9.84322 3.75 9.92142 3.75 10C3.75 13.4518 6.54822 16.25 10 16.25C13.4518 16.25 16.25 13.4518 16.25 10C16.25 6.54822 13.4518 3.75 10 3.75ZM7.39128 3.45892C7.58514 3.82497 7.44556 4.27886 7.07951 4.47272C6.94154 4.54579 6.80661 4.6239 6.67496 4.70679C6.32444 4.92749 5.86138 4.82226 5.64067 4.47174C5.41997 4.12122 5.5252 3.65816 5.87572 3.43745C6.03894 3.33468 6.20629 3.23781 6.37748 3.14715C6.74353 2.95329 7.19742 3.09287 7.39128 3.45892ZM4.47174 5.64067C4.82226 5.86137 4.92749 6.32444 4.70679 6.67496C4.6239 6.80661 4.54579 6.94154 4.47273 7.07951C4.27886 7.44556 3.82497 7.58514 3.45892 7.39128C3.09287 7.19742 2.95329 6.74352 3.14715 6.37748C3.23781 6.20629 3.33468 6.03894 3.43745 5.87572C3.65816 5.5252 4.12122 5.41997 4.47174 5.64067ZM10.75 6C10.75 5.58579 10.4142 5.25 10 5.25C9.58579 5.25 9.25 5.58579 9.25 6V10C9.25 10.1989 9.32902 10.3897 9.46967 10.5303L11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L10.75 9.68934V6Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DurationIcon;
