import React, { FC, useMemo } from 'react';

import { DialogContent, SxProps } from '@mui/material';

import { BaseProps } from 'src/components/types';

import { Theme } from 'src/themeMui5/type';

const useSxProps = () => {
  return useMemo(() => {
    return {
      container: {
        overflow: 'auto',
        // Had to add !important because of this issue
        // https://github.com/mui/material-ui/issues/27851
        padding: '20px !important',
        width: '100%',
      },
    };
  }, []);
};

// eslint-disable-next-line react/no-unused-prop-types, modernloop/restrict-props-name.cjs
type DialogContainerContentProps = BaseProps & { sx?: SxProps<Theme> };

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const DialogContainerContent: FC<DialogContainerContentProps> = ({ children, dataTestId, sx }): JSX.Element => {
  const sxProps = useSxProps();
  const sxContainerProps = useMemo(() => ({ ...sxProps.container, ...(sx || {}) }), [sx, sxProps.container]);
  return (
    <DialogContent sx={sxContainerProps} data-testid={dataTestId}>
      {children}
    </DialogContent>
  );
};

export default DialogContainerContent;
