import { getLocalTimezone } from '@modernloop/shared/datetime';

import { getDisplayTimezone } from 'src/store/selectors/ui-state';

import { useSelector } from 'src/store';

const useDisplayTimezone = (): string => {
  const displayTimezone = useSelector(getDisplayTimezone);
  if (displayTimezone) {
    // may be empty string so checking for falsey...
    return displayTimezone;
  }
  return getLocalTimezone();
};

export default useDisplayTimezone;
