import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { CandidateFilledIcon } from '@modernloop/shared/icons';
import { Box, Stack, Typography } from '@mui/material';

import {
  ResponseStatus,
  ScheduleContentInterviewDetails_CandidateEventFragment,
  ScheduleContentInterviewDetails_InterviewFragment,
} from 'src/generated/mloop-graphql';

import getResponseStatusNode from 'src/entities/common/getResponseStatusNode';

import ScheduleContentInterviewDetailsInterviewer from './ScheduleContentInterviewDetailsInterviewer';
import ScheduleContentInterviewDetailsMeetingRoom from './ScheduleContentInterviewDetailsMeetingRoom';

type Fragments = {
  interview: ScheduleContentInterviewDetails_InterviewFragment;
  candidateEvent: ScheduleContentInterviewDetails_CandidateEventFragment | undefined;
};

const ScheduleContentInterviewDetails: FCWithFragments<Fragments> = ({ interview, candidateEvent }) => {
  return (
    <Stack spacing={1} data-testid="schedule-content-interview-details-" sx={{ pl: 2.5, pr: 1.5, py: 1 }}>
      {candidateEvent && !interview.isHiddenFromCandidate && (
        <Stack
          direction="row"
          justifyContent="space-between"
          data-testid="schedule-content-interview-details-candidate-rsvp"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <CandidateFilledIcon color="action" />
            <Typography variant="body2">Candidate</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {getResponseStatusNode(candidateEvent.responseStatus || ResponseStatus.NeedsAction)}
            <Box width={32} height={32} />
          </Stack>
        </Stack>
      )}

      {interview.applicationStageInterviewInterviewers?.map((interviewer) => (
        <ScheduleContentInterviewDetailsInterviewer
          key={`${interview.id}-${interviewer.interviewerId}`}
          interview={interview}
          interviewer={interviewer}
        />
      ))}

      {interview.applicationStageInterviewRooms?.map((room) => (
        <ScheduleContentInterviewDetailsMeetingRoom key={room.applicationStageInterviewId} meetingRoom={room} />
      ))}
    </Stack>
  );
};

ScheduleContentInterviewDetails.fragments = {
  interview: gql`
    ${ScheduleContentInterviewDetailsInterviewer.fragments.interview}
    ${ScheduleContentInterviewDetailsInterviewer.fragments.interviewer}
    ${ScheduleContentInterviewDetailsMeetingRoom.fragments.meetingRoom}
    fragment ScheduleContentInterviewDetails_interview on ApplicationStageInterview {
      id
      ...ScheduleContentInterviewDetailsInterviewer_interview
      applicationStageInterviewInterviewers {
        interviewerId
        ...ScheduleContentInterviewDetailsInterviewer_interviewer
      }
      applicationStageInterviewRooms {
        applicationStageInterviewId
        meetingRoomId
        ...ScheduleContentInterviewDetailsMeetingRoom_meetingRoom
      }
    }
  `,
  candidateEvent: gql`
    fragment ScheduleContentInterviewDetails_candidateEvent on ApplicationStageCandidateEvent {
      id
      responseStatus
    }
  `,
};

export default ScheduleContentInterviewDetails;
