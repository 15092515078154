import { parseISO } from 'date-fns';

import scheduleUpdateStateSlice, { ScheduleUpdate } from 'src/store/slices/schedule-update';

import { applyUpdatesToSchedule } from 'src/views-new/ScheduleFlow/Steps/Schedule/applyUpdatesToSchedule';

import { AppThunk } from 'src/store';

export const clearUpdates = (): AppThunk => async (dispatch) => {
  dispatch(scheduleUpdateStateSlice.actions.clear());
};

export const addInterviewScheduleUpdate =
  (scheduleUpdate: ScheduleUpdate): AppThunk =>
  async (dispatch, getState) => {
    dispatch(scheduleUpdateStateSlice.actions.addScheduleUpdate(scheduleUpdate));

    /**
     * The below logic checks if the user has made multiple changes to time, name, scorecard, etc.
     * and if the changes revert back to the original value in interview then delete all the changes of that type for that interview
     */

    const state = getState();

    const selectedSchedule = state.schedules.byId[scheduleUpdate.scheduleId];
    const scheduleUpdates = state.scheduleUpdate.byId[scheduleUpdate.scheduleId];
    const updatedSchedule = applyUpdatesToSchedule(selectedSchedule, scheduleUpdates);
    let typeToFilter: string | undefined;

    const interview = selectedSchedule.events?.find((intw) => intw.id === scheduleUpdate.applicationStageInterviewId);
    const updatedInterview = updatedSchedule.events?.find(
      (intw) => intw.id === scheduleUpdate.applicationStageInterviewId
    );

    switch (scheduleUpdate.type) {
      case 'SchdeuleUpdateInterviewName': {
        if (interview && updatedInterview && interview.name === updatedInterview.name) {
          typeToFilter = scheduleUpdate.type;
        }
        break;
      }
      case 'SchdeuleUpdateInterviewTime': {
        if (
          interview &&
          updatedInterview &&
          parseISO(interview.startAt).getTime() === parseISO(updatedInterview.startAt).getTime() &&
          parseISO(interview.endAt).getTime() === parseISO(updatedInterview.endAt).getTime()
        ) {
          typeToFilter = scheduleUpdate.type;
        }
        break;
      }
      case 'ScheduleUpdateScorecard': {
        if (interview && updatedInterview) {
          if (interview?.atsInterviewDefinitionId === updatedInterview.atsInterviewDefinitionId) {
            typeToFilter = scheduleUpdate.type;
          }
        }
        break;
      }
      case 'ScheduleUpdateAddInterviewMeetingRoom': {
        const index = scheduleUpdates.findIndex(
          (update) =>
            update.type === 'ScheduleUpdateDeleteInterviewMeetingRoom' &&
            update.applicationStageInterviewId === scheduleUpdate.applicationStageInterviewId &&
            update.roomSuggestion.room?.id === scheduleUpdate.roomSuggestion.room?.id
        );

        if (index !== -1) {
          typeToFilter = 'ScheduleUpdateDeleteInterviewMeetingRoom';
        }
        break;
      }
      case 'ScheduleUpdateDeleteInterviewMeetingRoom': {
        const index = scheduleUpdates.findIndex(
          (update) =>
            update.type === 'ScheduleUpdateAddInterviewMeetingRoom' &&
            update.applicationStageInterviewId === scheduleUpdate.applicationStageInterviewId &&
            update.roomSuggestion.room?.id === scheduleUpdate.roomSuggestion.room?.id
        );

        if (index !== -1) {
          typeToFilter = 'ScheduleUpdateAddInterviewMeetingRoom';
        }
        break;
      }
      case 'ScheduleUpdateInterviewerOptional': {
        const index = scheduleUpdates.findIndex(
          (update) =>
            update.type === 'ScheduleUpdateInterviewerOptional' &&
            update.applicationStageInterviewId === scheduleUpdate.applicationStageInterviewId &&
            update.applicationStageInterviewerId === scheduleUpdate.applicationStageInterviewerId &&
            update.isOptional !== scheduleUpdate.isOptional
        );

        if (index !== -1) {
          typeToFilter = 'ScheduleUpdateInterviewerOptional';
        }

        break;
      }
      case 'ScheduleUpdateInterviewHiddenFromCandidate': {
        if (
          interview &&
          updatedInterview &&
          interview.isHiddenFromCandidate === updatedInterview.isHiddenFromCandidate
        ) {
          typeToFilter = scheduleUpdate.type;
        }

        break;
      }
      default:
    }

    if (
      typeToFilter === 'ScheduleUpdateAddInterviewMeetingRoom' ||
      typeToFilter === 'ScheduleUpdateDeleteInterviewMeetingRoom'
    ) {
      const meetingRoomId =
        scheduleUpdate.type === 'ScheduleUpdateDeleteInterviewMeetingRoom' ||
        scheduleUpdate.type === 'ScheduleUpdateAddInterviewMeetingRoom'
          ? scheduleUpdate.roomSuggestion.room?.id
          : undefined;

      // if a room is added then deleted or vice versa then we remove all updates for that room for that interview
      const updates = scheduleUpdates.filter(
        (update) =>
          !(
            (update.type === 'ScheduleUpdateAddInterviewMeetingRoom' ||
              update.type === 'ScheduleUpdateDeleteInterviewMeetingRoom') &&
            update.roomSuggestion.room?.id === meetingRoomId &&
            update.applicationStageInterviewId === scheduleUpdate.applicationStageInterviewId
          )
      );

      dispatch(
        scheduleUpdateStateSlice.actions.setScheduleUpdates({
          scheduleId: scheduleUpdate.scheduleId,
          updates,
        })
      );
    } else if (typeToFilter === 'ScheduleUpdateInterviewerOptional') {
      const updates = scheduleUpdates.filter(
        (update) =>
          !(
            update.type === 'ScheduleUpdateInterviewerOptional' &&
            scheduleUpdate.type === 'ScheduleUpdateInterviewerOptional' &&
            update.applicationStageInterviewId === scheduleUpdate.applicationStageInterviewId &&
            update.applicationStageInterviewerId === scheduleUpdate.applicationStageInterviewerId
          )
      );

      dispatch(
        scheduleUpdateStateSlice.actions.setScheduleUpdates({
          scheduleId: scheduleUpdate.scheduleId,
          updates,
        })
      );
    } else if (typeToFilter) {
      const updates = scheduleUpdates.filter(
        (update) =>
          update.type !== typeToFilter ||
          update.applicationStageInterviewId !== scheduleUpdate.applicationStageInterviewId
      );

      dispatch(
        scheduleUpdateStateSlice.actions.setScheduleUpdates({
          scheduleId: scheduleUpdate.scheduleId,
          updates,
        })
      );
    } else if (scheduleUpdate.type === 'ScheduleUpdateDeleteInterview') {
      const newInterviewUpdate = scheduleUpdates.find((update) => {
        return (
          update.type === 'ScheduleUpdateNewInterview' &&
          update.applicationStageInterviewId === scheduleUpdate.applicationStageInterviewId
        );
      });

      // If the interview was not newly added then return early.
      if (!newInterviewUpdate) return;

      // Remove all the updates related to a newly added interview if the interview is deleted
      const updates = scheduleUpdates.filter((update) => {
        return update.applicationStageInterviewId !== scheduleUpdate.applicationStageInterviewId;
      });

      dispatch(
        scheduleUpdateStateSlice.actions.setScheduleUpdates({
          scheduleId: scheduleUpdate.scheduleId,
          updates,
        })
      );
    }
  };
