export enum Routes {
  /**
   * @deprecated use {@link getAccountSettingUrls}
   */
  Account = '/account',
  AccountSettings = '/settings/account/:tab',
  OrganizationSettings = '/settings/organization/:tab',
  CandidateDetails = '/candidates/:candidateId',
  Candidates = '/candidates',
  AllSchedulingTasks = '/all-tasks',
  MySchedulingTasks = '/my-tasks',
  Employees = '/employees',
  InterviewModuleDetails = '/interviews/:interviewModuleId',
  InterviewerDetails = '/interviewers/:interviewerId',
  Interviews = '/interviews',
  JobDetails = '/jobs/:jobId/:tab',
  JobDetailsWithJobStage = '/jobs/:jobId/:tab/:jobStageId',
  Jobs = '/jobs',
  JobSettingsImport = '/job-settings-import',
  Metrics = '/metrics',
  /**
   * @deprecated for Organization setting urls use {@link getOrganizationSettingUrls}
   */
  Organization = '/organization',
  SettingsIntegrations = '/organization/integrations',
  SettingsInterviewers = '/organization/interviewers',
  SettingsMembers = '/organization/members',
  SettingsNotifications = '/organization/notifications',
  SettingsScheduling = '/organization/scheduling',
  SettingsTemplates = '/organization/templates',
  SettingsCandidatePortal = '/organization/candidate-portal',
  SettingsTasks = '/organization/tasks',
  SettingsPrivacy = '/organization/privacy',
  SettingsSetup = '/organization/setup',
  SettingsSingleSignOn = '/organization/single-sign-on',
  SettingsRescheduleReasons = '/organization/reschedule-reasons',
  UpcomingEvents = '/upcomingevents',
  TermsAndPrivacy = '/terms-and-privacy',
  GrantAccess = '/grant-access/:integrationType',
}

// TODO: move these tabs to their respective url getters
export enum MetricsPageTabs {
  RECRUITERS = 'recruiters',
  INTERVIEWERS = 'interviewers',
}

// TODO: move these tabs to their respective url getters
export enum OrgSettingsSource {
  JOB_SETTINGS = 'job_settings',
  TEMPLATES = 'templates',
  ORG_PRIVACY = 'org_privacy',
  BRANDING_THEME = 'branding_theme',
  SCHEDULING = 'scheduling',
}
