/**
 * Converts an array of strings to a grammatically correct sentence.
 * @param arr - The array of strings to convert.
 * @param conjunction - The conjunction to use before the last item in the sentence. Defaults to 'and'.
 * @returns A grammatically correct sentence.
 */
const stringArrayToSentence = (arr: string[], conjunction = 'and') => {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return `${firsts.join(', ')} ${conjunction} ${last}`;
};

export default stringArrayToSentence;
