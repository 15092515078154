import React from 'react';

import BaseIcon, { Props } from './Icon';

const ScheduleFilledWithBg = ({ dataTestId, color, fontSize = 32, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon
      dataTestId={dataTestId}
      color={color}
      fontSize={fontSize}
      tooltip={tooltip}
      viewBox={`0 0 ${fontSize} ${fontSize}`}
    >
      <rect width={fontSize} height={fontSize} rx="6" fill="#026FD4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7.25C13.4142 7.25 13.75 7.58579 13.75 8V9.25H18.25V8C18.25 7.58579 18.5858 7.25 19 7.25C19.4142 7.25 19.75 7.58579 19.75 8V9.25H21C22.5188 9.25 23.75 10.4812 23.75 12L23.75 21C23.75 22.5188 22.5188 23.75 21 23.75H11C9.48123 23.75 8.25002 22.5188 8.25001 21L8.25 12C8.25 10.4812 9.48122 9.25 11 9.25H12.25V8C12.25 7.58579 12.5858 7.25 13 7.25ZM12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16ZM13 19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19C11 18.4477 11.4477 18 12 18C12.5523 18 13 18.4477 13 19ZM15 14.25C14.5858 14.25 14.25 14.5858 14.25 15C14.25 15.4142 14.5858 15.75 15 15.75H20C20.4142 15.75 20.75 15.4142 20.75 15C20.75 14.5858 20.4142 14.25 20 14.25H15ZM14.25 19C14.25 18.5858 14.5858 18.25 15 18.25H20C20.4142 18.25 20.75 18.5858 20.75 19C20.75 19.4142 20.4142 19.75 20 19.75H15C14.5858 19.75 14.25 19.4142 14.25 19Z"
        fill="white"
      />
    </BaseIcon>
  );
};

export default ScheduleFilledWithBg;
