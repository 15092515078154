import React from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/styles';
import { Stack } from '@mui/material';

import { AtsFieldsCandidate, CandidateHeader_AtsCandidateFragment } from 'src/generated/mloop-graphql';

import Tag from 'src/components/Tag';
import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';

import { getCandidateAtsField } from 'src/hooks/atsService/util';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface CandidateHeaderProps {
  candidateName: string;
}

interface CandidateHeaderFragment {
  atsCandidate: CandidateHeader_AtsCandidateFragment;
}

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      marginLeft: 0,
      paddingLeft: '20px',
    },
    headerWrapper: {
      alignItems: 'baseline',
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const CandidateHeader: FCWithFragments<CandidateHeaderFragment, CandidateHeaderProps> = ({
  candidateName,
  atsCandidate,
}) => {
  const classes = useStyles();
  const isInternalCandidate =
    getCandidateAtsField(atsCandidate?.atsFields as AtsFieldsCandidate, 'origin') === 'internal';

  return (
    <Stack direction="row" className={classes.headerWrapper}>
      <Label variant="title" style={{ marginRight: '12px' }}>
        {candidateName}
      </Label>
      {isInternalCandidate && (
        <Tag label="Internal candidate" size="medium" variant="filled" color="max-contrast-grey" />
      )}
    </Stack>
  );
};

CandidateHeader.fragments = {
  atsCandidate: gql`
    fragment CandidateHeader_atsCandidate on AtsCandidate {
      atsFields {
        ... on LeverCandidate {
          __typename
          origin
        }
      }
    }
  `,
};

export default CandidateHeader;
