import React from 'react';
import type { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box } from '@material-ui/core';

import LoadingScreen from 'src/shared/components/base/screenLoaders/LoadingScreen';

const LoadingContent: FC = () => (
  <Box p={10}>
    <LoadingScreen />
  </Box>
);

export default LoadingContent;
