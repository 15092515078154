import React from 'react';

import useHasChromeExtension from 'src/hooks/useHasChromeExtension';

import ContractEndDateBanner from './ContractEndBanner';
import ImpersonatingUserBanner from './ImpersonatingUserBanner';
import IntegrationHealthCheckBanners from './IntegrationHealthCheckBanners';
import LaunchDarklyBanner from './LaunchDarklyBanner';
import MissingChromeExtensionBanner from './MissingChromeExtensionBanner';
import MissingOrgAdminUserBanner from './MissingOrgAdminUserBanner';

const Banners: React.FC = (): JSX.Element => {
  const chrome = useHasChromeExtension();

  return (
    <>
      <ContractEndDateBanner />
      <LaunchDarklyBanner />
      <IntegrationHealthCheckBanners />
      <ImpersonatingUserBanner />
      <MissingOrgAdminUserBanner />
      {!chrome.hasExtension && (
        <MissingChromeExtensionBanner text={chrome.extensionBannerText} bannerUrlText={chrome.extensionBannerUrlText} />
      )}
    </>
  );
};

export default Banners;
