import { ScheduleUpdate } from 'src/store/slices/schedule-update';

import { applyUpdatesToSchedule } from 'src/views-new/ScheduleFlow/Steps/Schedule/applyUpdatesToSchedule';

import { State } from 'src/store';

import { getScheduleById } from './schedules';

const EMPTY_ARRAY = [];

export const getScheduleUpdates = (state: State, scheduleId: string): ScheduleUpdate[] =>
  state.scheduleUpdate.byId[scheduleId] || EMPTY_ARRAY;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getOptionalAttendeeIdsList = (state: State, scheduleId: string, interviewEventId: string) => {
  const optionalAttendeeIdsList: string[] = [];

  const schedule = getScheduleById(state, scheduleId);
  const scheduleUpdates = getScheduleUpdates(state, scheduleId);
  const updatedSchedule = applyUpdatesToSchedule(schedule, scheduleUpdates);
  const scheduleEvents = updatedSchedule?.events;

  if (scheduleEvents) {
    const interviewEvent = scheduleEvents.find((event) => {
      return event.id === interviewEventId;
    });
    if (interviewEvent) {
      interviewEvent.interviewers.forEach((interviewer) => {
        if (interviewer?.isOptional) {
          optionalAttendeeIdsList.push(interviewer.employeeId);
        }
      });
    }
  }

  return optionalAttendeeIdsList;
};
