import React from 'react';

import BaseIcon, { Props } from './Icon';

const CodeIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7155 4.28162C12.1123 4.40064 12.3374 4.81875 12.2184 5.2155L9.21837 15.2155C9.09935 15.6122 8.68123 15.8374 8.28449 15.7184C7.88774 15.5993 7.66261 15.1812 7.78163 14.7845L10.7816 4.78447C10.9007 4.38773 11.3188 4.16259 11.7155 4.28162ZM6.53033 6.46967C6.82322 6.76256 6.82322 7.23743 6.53033 7.53033L4.06066 10L6.53033 12.4697C6.82322 12.7626 6.82322 13.2374 6.53033 13.5303C6.23744 13.8232 5.76256 13.8232 5.46967 13.5303L2.46967 10.5303C2.17678 10.2374 2.17678 9.76256 2.46967 9.46967L5.46967 6.46967C5.76256 6.17677 6.23744 6.17677 6.53033 6.46967ZM14.5303 6.46967C14.2374 6.17678 13.7626 6.17678 13.4697 6.46967C13.1768 6.76256 13.1768 7.23744 13.4697 7.53033L15.9393 10L13.4697 12.4697C13.1768 12.7626 13.1768 13.2374 13.4697 13.5303C13.7626 13.8232 14.2374 13.8232 14.5303 13.5303L17.5303 10.5303C17.8232 10.2374 17.8232 9.76256 17.5303 9.46967L14.5303 6.46967Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CodeIcon;
