import React from 'react';

import BaseIcon, { Props } from './Icon';

const LockIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4.75C9.30964 4.75 8.75 5.30964 8.75 6V7.26329L11.25 7.25696V6C11.25 5.30964 10.6904 4.75 10 4.75ZM7.25 6V7.26709L6.99557 7.26773C6.0308 7.27018 5.25 8.05296 5.25 9.01773V13.9975C5.25 14.9657 6.0362 15.7499 7.00443 15.7475L13.0044 15.7323C13.9692 15.7298 14.75 14.947 14.75 13.9823V9.00254C14.75 8.03431 13.9638 7.25009 12.9956 7.25254L12.75 7.25316V6C12.75 4.48122 11.5188 3.25 10 3.25C8.48122 3.25 7.25 4.48122 7.25 6ZM10 13C10.8284 13 11.5 12.3284 11.5 11.5C11.5 10.6716 10.8284 10 10 10C9.17157 10 8.5 10.6716 8.5 11.5C8.5 12.3284 9.17157 13 10 13Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default LockIcon;
