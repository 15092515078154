import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Loader } from '@modernloop/shared/helper-components';
import { Avatar, Box, Button, TextField, Typography } from '@mui/material';

import { QueueDialogContent_QueueMembersFragment } from 'src/generated/mloop-graphql';

import FilterList from 'src/components/FilterList';

import AddMemberModal from './AddMemberModal';

type Props = {
  queueName: string;
  onNameChange: (name: string) => void;
  onMembersChange: (members: QueueDialogContent_QueueMembersFragment[]) => void;
  loading?: boolean;
};

type Fragments = {
  queueMembers: QueueDialogContent_QueueMembersFragment[];
};

const QueueDialogContent: FCWithFragments<Fragments, Props> = ({
  queueMembers,
  queueName,
  onMembersChange,
  onNameChange,
  loading,
}) => {
  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);

  const handleAddMember = (selectedEmployees: QueueDialogContent_QueueMembersFragment[]) => {
    onMembersChange(selectedEmployees ?? []);
  };

  const handleRemoveMember = (employeeId: string) => {
    const filteredEmployees = queueMembers.filter((employee) => employee.id !== employeeId);
    onMembersChange(filteredEmployees);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" rowGap="20px">
        <TextField value={queueName} onChange={(e) => onNameChange(e.target.value)} placeholder="Name" fullWidth />
        <Box display="flex" flexDirection="column" rowGap="8px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">{queueMembers.length} members</Typography>
            <Button size="small" variant="contained" color="primary" onClick={() => setOpenAddMemberDialog(true)}>
              Add members
            </Button>
          </Box>

          {loading && <Loader loading />}

          {!!queueMembers.length && !loading && (
            <Box mx="-10px">
              <FilterList
                getOptionLabel={(option) => option.fullName || ''}
                options={queueMembers}
                getChildSize={() => 45}
                placeholderText="Search members"
                getListHeight={() => Math.min(queueMembers.length * 45, 220)}
                renderOption={(option) => (
                  <Box key={option.id} display="flex" width="100%" alignItems="center" columnGap="8px">
                    <Avatar
                      sx={{ width: 28, height: 28 }}
                      alt="Avatar"
                      src={option.slackImageUrl ?? ''}
                      variant="rounded"
                    />
                    <Typography variant="body1">{option.fullName}</Typography>
                    <Box marginLeft="auto">
                      <Button variant="text" color="inherit" size="small" onClick={() => handleRemoveMember(option.id)}>
                        Remove
                      </Button>
                    </Box>
                  </Box>
                )}
                zeroState={<Typography variant="body1">No members found</Typography>}
              />
            </Box>
          )}
        </Box>
      </Box>
      {openAddMemberDialog && (
        <AddMemberModal
          open={openAddMemberDialog}
          onClose={() => {
            setOpenAddMemberDialog(false);
          }}
          onSave={(selectedEmployees) => {
            handleAddMember(selectedEmployees);
          }}
          selectedEmployees={queueMembers}
        />
      )}
    </>
  );
};

QueueDialogContent.fragments = {
  queueMembers: gql`
    ${AddMemberModal.fragments.selectedEmployees}
    fragment QueueDialogContent_queueMembers on Employee {
      id
      ...AddMemberModal_selectedEmployees
    }
  `,
};

export default QueueDialogContent;
