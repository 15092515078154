import React, { useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { IsoTimestamp, assertIsoTimestamp, formatTZ, getLocalTimezone } from '@modernloop/shared/datetime';
import { CrossIcon, DateIcon } from '@modernloop/shared/icons';
import { IconButton, Stack, TextField, Typography } from '@mui/material';

import { EditKeyDatesModal_ModuleMemberFragment, useModuleMemberUpdateMutation } from 'src/generated/mloop-graphql';

import DatePicker from 'src/components/date-time-picker/DatePickerPopoverV2';

type Fragments = {
  moduleMember: EditKeyDatesModal_ModuleMemberFragment;
};

type Props = {
  onClose: () => void;
};

const EditKeyDatesModal: FCWithFragments<Fragments, Props> = ({ moduleMember, onClose }) => {
  const timezone = getLocalTimezone();
  const [showAddedToModuleDatePicker, setShowAddedToModuleDatePicker] = useState(false);
  const [showFullyTrainedDatePicker, setShowFullyTrainedDatePicker] = useState(false);

  const [addedToModuleDate, setAddedToModuleDate] = useState<IsoTimestamp | undefined>(moduleMember.createdAt);
  const [fullyTrainedDate, setFullyTrainedDate] = useState<IsoTimestamp | undefined>(moduleMember.trainedAt);

  const addedToModuleDateAnchorEl = useRef<HTMLInputElement>(null);
  const fullyTrainedDateAnchorEl = useRef<HTMLInputElement>(null);

  const [editKeyDatesMutation, { loading }] = useModuleMemberUpdateMutation();

  const handleSave = async () => {
    try {
      await editKeyDatesMutation({
        variables: {
          input: {
            interviewModuleId: moduleMember.interviewModuleId,
            employeeId: moduleMember.employeeId,
            trainedAt: fullyTrainedDate,
            addedToModuleAt: addedToModuleDate,
            clearTrainedAt: !fullyTrainedDate,
          },
        },
      });

      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      open
      maxWidth="xs"
      title="Edit key dates"
      decoration={
        <img src="/static/images/setup/decoration_edit.svg" alt="Edit training plan" height="64px" width="64px" />
      }
      onClose={onClose}
      cancelOptions={{ label: 'Cancel', onClick: onClose }}
      submitOptions={{ label: 'Save', onClick: handleSave, isLoading: loading, isDisabled: loading }}
    >
      <Stack gap={2.5}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography>Added to module</Typography>
            <TextField
              ref={addedToModuleDateAnchorEl}
              variant="outlined"
              value={addedToModuleDate ? formatTZ(addedToModuleDate, timezone, 'MMM d, yyyy') : ''}
              onClick={() => setShowAddedToModuleDatePicker(true)}
              InputProps={{
                startAdornment: <DateIcon />,
              }}
              sx={{ width: '50%' }}
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Typography>Fully trained</Typography>
            <TextField
              ref={fullyTrainedDateAnchorEl}
              variant="outlined"
              value={fullyTrainedDate ? formatTZ(fullyTrainedDate, timezone, 'MMM d, yyyy') : ''}
              onClick={() => setShowFullyTrainedDatePicker(true)}
              InputProps={{
                startAdornment: <DateIcon />,
                endAdornment: (
                  <IconButton
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setFullyTrainedDate(undefined);
                    }}
                  >
                    <CrossIcon />
                  </IconButton>
                ),
              }}
              sx={{ width: '50%' }}
            />
          </Stack>
        </Stack>

        <Typography color="text.secondary">Additional key dates are determined based on module log history.</Typography>
      </Stack>
      {showAddedToModuleDatePicker && (
        <DatePicker
          open
          disableFuture
          disablePast={false}
          timezone={timezone}
          dateAnchorEl={addedToModuleDateAnchorEl}
          utcDate={addedToModuleDate || assertIsoTimestamp(new Date().toISOString())}
          onChange={setAddedToModuleDate}
          onClose={() => setShowAddedToModuleDatePicker(false)}
        />
      )}
      {showFullyTrainedDatePicker && (
        <DatePicker
          open
          disableFuture
          disablePast={false}
          timezone={timezone}
          dateAnchorEl={fullyTrainedDateAnchorEl}
          utcDate={fullyTrainedDate || assertIsoTimestamp(new Date().toISOString())}
          onChange={setFullyTrainedDate}
          onClose={() => setShowFullyTrainedDatePicker(false)}
        />
      )}
    </Dialog>
  );
};

EditKeyDatesModal.fragments = {
  moduleMember: gql`
    fragment EditKeyDatesModal_moduleMember on InterviewModuleMember {
      id
      interviewModuleId
      employeeId
      trainedAt
      createdAt
    }
  `,
};

export default EditKeyDatesModal;
