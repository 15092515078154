import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import BaseIcon, { Props } from '../../Icon';

const TotalRsvpWarning = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM8.93774 5.50358C9.08011 5.34231 9.28488 5.24993 9.5 5.24993H10.5C10.7151 5.24993 10.9199 5.34231 11.0623 5.50358C11.2046 5.66486 11.2709 5.8795 11.2442 6.09296L10.7442 10.093C10.6973 10.4683 10.3782 10.7499 10 10.7499C9.62176 10.7499 9.30271 10.4683 9.25579 10.093L8.75579 6.09296C8.72911 5.8795 8.79537 5.66486 8.93774 5.50358ZM11 13.4999C11 14.0522 10.5523 14.4999 10 14.4999C9.44771 14.4999 9 14.0522 9 13.4999C9 12.9476 9.44771 12.4999 10 12.4999C10.5523 12.4999 11 12.9476 11 13.4999Z"
        fill="#D7241D"
      />
    </BaseIcon>
  );
};

export default TotalRsvpWarning;
