import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { LeverHealthCheckBanner_LeverFragment } from 'src/generated/mloop-graphql';

import AppWideBanner, { AppWideBannerAction } from '../AppWideBanner';

import { integrationUnhealthyMessage } from './constants';

interface Fragments {
  lever: LeverHealthCheckBanner_LeverFragment;
}

interface Props {
  retry: AppWideBannerAction;
}

const LeverHealthCheckBanner: FCWithFragments<Fragments, Props> = ({ lever, retry }) => {
  // hide if its not installed or is healthy
  if (!lever || !lever?.isInstalled || lever?.isHealthy) {
    return null;
  }

  const endpoints = [
    !lever.canReadUsers && 'users',
    !lever.canReadJobs && 'jobs',
    !lever.canReadCandidates && 'candidates',
    !lever.canReadApplications && 'applications',
    !lever.canReadArchiveReasons && 'archive reasons',
    !lever.canReadPanels && 'panels',
    !lever.canReadStages && 'stages',
    !lever.canReadDeletedCandidates && 'deleted candidates',
  ].filter(Boolean) as string[];

  return (
    <AppWideBanner
      color="foreground"
      backgroundColor="error"
      text={integrationUnhealthyMessage('Lever', endpoints)}
      action={retry}
    />
  );
};

LeverHealthCheckBanner.fragments = {
  lever: gql`
    fragment LeverHealthCheckBanner_lever on LeverIntegrationHealth {
      isHealthy
      isInstalled
      canReadUsers
      canReadJobs
      canReadCandidates
      canReadApplications
      canReadArchiveReasons
      canReadPanels
      canReadStages
      canReadDeletedCandidates
    }
  `,
};

export default LeverHealthCheckBanner;
