import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { assertIsoTimestamp } from '@modernloop/shared/datetime';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Alert, AlertTitle, LinearProgress, Stack, Typography } from '@mui/material';
import { endOfToday, startOfToday } from 'date-fns';
import { maxBy, minBy } from 'lodash';

import { InterviewMeetingLocationType, useCalendarMeetingCreateMutation } from 'src/generated/mloop-graphql';

import useEmployeeId from 'src/hooks/useEmployeeId';
import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import {
  updateVideoMeetingLink,
  updateVideoMeetingLinkHostEmployeeId,
} from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { getVideoMeetingLink, getVideoMeetingLinkHostEmployeeId } from 'src/store/selectors/schedule-communications';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import { useDispatch, useSelector } from 'src/store';

import VideoMeetingLinkWithHost from './VideoMeetingLinkWithHost';
import usePopulateVideoInterviewMeetingLocation from './usePopulateVideoInterviewMeetingLocation';

type Props = {
  scheduleId: string;
  location: InterviewMeetingLocationType;
};

const VideoMeetingLinkSingle = ({ scheduleId, location }: Props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();
  const [fetchingURLForCurrentEmployee, setFetchingURLForCurrentEmployee] = useState(false);
  const schedule = useScheduleWithoutBreaks(scheduleId);
  const employeeId = useEmployeeId();
  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const [calendarMeetingCreate] = useCalendarMeetingCreateMutation();
  const populateVideoInterviewMeetingLocation = usePopulateVideoInterviewMeetingLocation({
    scheduleId,
    interviewMeetingLocationType: location,
  });

  const videoMeetingLink = useSelector((state) => getVideoMeetingLink(state, scheduleId));
  const hostEmployeeId = useSelector((state) => getVideoMeetingLinkHostEmployeeId(state, scheduleId));

  const startAt = useMemo(() => {
    return assertIsoTimestamp(
      minBy(schedule?.events || [], (event) => new Date(event.startAt))?.startAt || startOfToday().toISOString()
    );
  }, [schedule]);

  const endAt = useMemo(() => {
    return assertIsoTimestamp(
      maxBy(schedule?.events || [], (event) => new Date(event.endAt))?.endAt || endOfToday().toISOString()
    );
  }, [schedule]);

  const interviewerIds = useMemo(() => {
    return schedule?.events?.map((interview) => interview.interviewers.map((i) => i.employeeId)).flat() || [];
  }, [schedule?.events]);

  const handleHostEmployeeChanged = useCallback(
    async (newHostEmployeeId: string) => {
      try {
        if (scheduleLocationEnabled) {
          populateVideoInterviewMeetingLocation({ hostEmployeeId: newHostEmployeeId });
        } else {
          const { data } = await calendarMeetingCreate({
            variables: { input: { timeRange: { startAt, endAt }, hostEmployeeId: newHostEmployeeId } },
          });

          const url = data?.calendarMeetingCreate?.url;
          if (url) {
            dispatch(updateVideoMeetingLinkHostEmployeeId(scheduleId, newHostEmployeeId));
            dispatch(updateVideoMeetingLink(scheduleId, url));

            schedule?.events?.forEach((interview) => {
              dispatch(
                addInterviewScheduleUpdate({
                  type: 'ScheduleUpdateVideoUrl',
                  updateType: ScheduleUpdateType.EDIT,
                  scheduleId,
                  applicationStageInterviewId: interview.id,
                })
              );
            });
          }
        }
      } catch (e) {
        setError(e.message);
      }
    },
    [
      calendarMeetingCreate,
      dispatch,
      endAt,
      populateVideoInterviewMeetingLocation,
      schedule?.events,
      scheduleId,
      scheduleLocationEnabled,
      startAt,
    ]
  );

  useEffect(() => {
    (async () => {
      if (!employeeId || hostEmployeeId || videoMeetingLink) return;

      setFetchingURLForCurrentEmployee(true);
      try {
        await handleHostEmployeeChanged(employeeId);
      } finally {
        setFetchingURLForCurrentEmployee(false);
      }
    })();
  }, [employeeId, handleHostEmployeeChanged, hostEmployeeId, videoMeetingLink]);

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">Host</Typography>
      <VideoMeetingLinkWithHost
        interviewerIds={interviewerIds}
        hostEmployeeId={hostEmployeeId}
        url={videoMeetingLink}
        onHostChanged={handleHostEmployeeChanged}
      />
      {fetchingURLForCurrentEmployee && <LinearProgress />}
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
    </Stack>
  );
};

export default VideoMeetingLinkSingle;
