import urlcat from 'urlcat';

import { SearchParamKnownKeys, SidePanelType } from 'src/hooks/useUrlSearchParams';

/**
 * Schedule Flow Url
 * @param applicationId
 * @param candidateId
 * @returns string
 */
const getTaskUrl = (
  candidateId: string,
  taskId: string,
  options?: {
    applicationId?: string;
    useRelativeUrl?: boolean;
  } // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
string => {
  const { applicationId, useRelativeUrl = true } = options || {};

  const currentUrl = new URL(window.location.href);
  const baseUrl = useRelativeUrl ? '' : currentUrl.origin;

  return urlcat(baseUrl, '/candidates/:candidateId', {
    applicationId,
    candidateId,
    [SearchParamKnownKeys.panelType]: SidePanelType.ScheduleTask,
    [SearchParamKnownKeys.panelObjectId]: taskId,
  });
};

export default getTaskUrl;
