import React from 'react';
import type { FC } from 'react';

import { ApolloError } from '@apollo/client';
import { Alert, Box, LinearProgress, Stack } from '@mui/material';

import { useZoomMeetingCreateMutation } from 'src/generated/mloop-graphql';

import CopyTextField from 'src/components/copy-text-field';

import ZoomUserSelect from 'src/entities/Select/ZoomUserSelect';
import { ZoomUserDataWithCategory } from 'src/entities/Select/ZoomUserSelect/types';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { resetZoomUserInfo, setScheduleContentStepWaiting } from 'src/store/actions/debrief';
import { updateZoomInfo } from 'src/store/actions/schedule-communications';
import { getCandidateName, getInterviewerEventContent, getSelectedScheduleId } from 'src/store/selectors/debrief';
import { getZoomInfo, getZoomUserId } from 'src/store/selectors/schedule-communications';
import { ZoomDialInInfo } from 'src/store/slices/schedule-communications';

import logError from 'src/utils/logError';

import { getInterviewerIdsFromSchedule } from 'src/views-new/ScheduleFlow/Steps/Schedule/utils';

import { useDispatch, useSelector } from 'src/store';

interface Props {
  applicationId: string;
}

const ZoomSingle: FC<Props> = ({ applicationId }) => {
  const dispatch = useDispatch();
  const selectedScheduleId = useSelector((state) => getSelectedScheduleId(state, applicationId));
  const zoomUserId = useSelector((state) => getZoomUserId(state, selectedScheduleId || ''));
  const zoomInfo = useSelector((state) => getZoomInfo(state, selectedScheduleId || ''));
  const interviewerEventContent = useSelector((state) => getInterviewerEventContent(state, applicationId));
  const candidateName = useSelector((state) => getCandidateName(state, applicationId));

  const [zoomMeetingCreateMutation, { loading, error }] = useZoomMeetingCreateMutation();

  const schedule = useScheduleWithoutBreaks(selectedScheduleId);
  const interviewerIds = getInterviewerIdsFromSchedule(schedule);

  if (!selectedScheduleId) return null;

  const handleUserSelect = async (value: ZoomUserDataWithCategory) => {
    const newZoomUserID = value.zoomUserId;
    if (selectedScheduleId) {
      dispatch(resetZoomUserInfo(applicationId, selectedScheduleId, newZoomUserID));
    }
    dispatch(setScheduleContentStepWaiting(applicationId, true));

    if (!interviewerEventContent) {
      dispatch(setScheduleContentStepWaiting(applicationId, false));
      return;
    }

    if (newZoomUserID) {
      zoomMeetingCreateMutation({
        variables: {
          input: {
            zoomUserId: newZoomUserID,
            timeRange: {
              startAt: new Date(interviewerEventContent.event.startAt).toISOString(),
              endAt: new Date(interviewerEventContent.event.endAt).toISOString(),
            },
            title: `${candidateName} Debrief`,
          },
        },
      })
        .then(async (result) => {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line promise/always-return
          try {
            const { data } = result;
            if (!data || !data.zoomMeetingCreate) return;
            const { zoomMeetingCreate: zoomData } = data;

            if (selectedScheduleId) {
              dispatch(
                updateZoomInfo(selectedScheduleId, {
                  joinURL: zoomData.joinUrl || '',
                  meetingID: zoomData.meetingId,
                  password: zoomData.password || '',
                  pstnPassword: zoomData.pstnPassword as unknown as number,
                  dialInfo: zoomData.dialInfo as ZoomDialInInfo[],
                })
              );
            }
          } catch (err) {
            logError(err);
            dispatch(setScheduleContentStepWaiting(applicationId, false));
          }
        })
        .catch((reason: ApolloError) => {
          dispatch(setScheduleContentStepWaiting(applicationId, false));
          logError(reason);
        });
    }
  };

  return (
    <Stack spacing={1}>
      <ZoomUserSelect
        onChange={handleUserSelect}
        value={zoomUserId ?? undefined}
        panelInterviewerIds={interviewerIds}
      />
      {loading && <LinearProgress sx={{ width: '100%', marginTop: 12, marginBottom: 12 }} />}
      {zoomInfo?.joinURL && (
        <Box mt={2}>
          <CopyTextField value={zoomInfo.joinURL} />
        </Box>
      )}
      {error && (
        <Alert severity="error">
          {error.name} - {error.message}
        </Alert>
      )}
    </Stack>
  );
};

export default ZoomSingle;
