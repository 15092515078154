import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskChangeRequested_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import logError from 'src/utils/logError';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogContent from '../../Helpers/ActivityLogContent';

interface Fragments {
  activityLog: ActivityLogTaskChangeRequested_ActivityLogFragment;
}

const ActivityLogTaskChangeRequested: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (details?.__typename !== 'ActivityLogFlagDetails') {
    logError('ActivityLogTaskChangeRequested: details is not ActivityLogFlagDetails');
    return null;
  }

  const { flagContent } = details;

  return (
    <ActivityLogBase activityLog={activityLog} actionLabel="requested a change">
      <ActivityLogContent content={flagContent} />
    </ActivityLogBase>
  );
};

ActivityLogTaskChangeRequested.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskChangeRequested_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogFlagDetails {
          flagContent
          __typename
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskChangeRequested;
