import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import SvgIcon from '@material-ui/core/SvgIcon';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { CreateCSSProperties } from '@material-ui/styles';

import Tooltip from 'src/components/tooltip';
import { BaseProps } from 'src/components/types';
import {
  SupportedColor,
  SupportedTextColor,
  getColorFromTheme,
  getTextColorFromTheme,
  isSupportedColor,
  isSupportedTextColor,
} from 'src/components/utils/color';

import { Theme } from 'src/theme/type';

import { HexColor } from 'src/types/color';

export interface Props extends BaseProps {
  /**
   * Color of the icon drawn
   */
  color?: SupportedColor | SupportedTextColor | HexColor;

  /**
   * Size of the icon
   */
  // eslint-disable-next-line react/no-unused-prop-types
  fontSize?: number;

  /**
   * tooltip to be shown on hover.
   */
  tooltip?: JSX.Element | string;

  /**
   * Fill opacity of the underlying icon
   * It is used by only a few icons defined in `src/components/Icons`
   */
  // eslint-disable-next-line react/no-unused-prop-types
  fillOpacity?: number;

  tiny?: boolean;

  viewBox?: string;

  className?: string;

  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: Props): CreateCSSProperties<Props> => {
      const styles: CreateCSSProperties<Props> = {
        fill: 'none',
        stroke: 'none',
        fontSize: props.fontSize || 20,
        verticalAlign: 'middle',
      };

      if (isSupportedColor(props.color)) {
        styles.color = getColorFromTheme(props.color, theme);
      }

      if (isSupportedTextColor(props.color)) {
        styles.color = getTextColorFromTheme(props.color, theme);
      }

      if (!styles.color && props.color) {
        styles.color = props.color;
      }

      return styles;
    },
  })
);

const BaseIcon: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { children, color, dataTestId, tooltip, tiny, viewBox, className, style } = props;
  const classes = useStyles({ ...props, color: color || 'max-contrast-grey' });

  let icon = (
    <SvgIcon
      style={style}
      className={className}
      classes={classes}
      viewBox={viewBox ?? '0 0 20 20'}
      data-testid={dataTestId}
    >
      {children}
    </SvgIcon>
  );

  if (tiny) {
    icon = (
      <SvgIcon
        style={style}
        className={className}
        classes={classes}
        viewBox={viewBox ?? '0 0 14 14'}
        data-testid={dataTestId}
      >
        {children}
      </SvgIcon>
    );
  }

  if (tooltip) {
    return <Tooltip tooltip={tooltip}>{icon}</Tooltip>;
  }

  return icon;
};

export default BaseIcon;
