import React from 'react';

import BaseIcon, { Props } from './Icon';

const CaretDownIcon = ({ color, fontSize, tooltip, viewBox }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} viewBox={viewBox}>
      <path
        d="M5 8.5L10 12.5L15 8.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default CaretDownIcon;
