import React from 'react';

import { Box, Stack } from '@mui/material';

import Select, { MenuOption } from 'src/components/Select';
import Label from 'src/components/label';
import useEqualSizeSxProps from 'src/components/utils/useEqualSizeSxProps';

export const ADVANCE_NOTICE_OPTIONS: MenuOption[] = [
  { id: 0, value: 'None' },
  { id: 24, value: '24 hours' },
  { id: 48, value: '48 hours' },
];

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type RequiredAdvanceNoticeProps = {
  value?: number;
  onChange: (value: number) => void;
};

const RequiredAdvanceNotice = ({ value, onChange }: RequiredAdvanceNoticeProps) => {
  const sxProps = useEqualSizeSxProps();

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      sx={sxProps.equalSizeChild}
      justifyContent="space-between"
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      spacing={2}
    >
      <Label>Advanced notice</Label>
      <Box>
        <Select
          fullWidth
          dataTestId="self-schedule-options-advance-notice-select"
          options={ADVANCE_NOTICE_OPTIONS}
          onSelect={(val) => onChange(val.id as number)}
          selectedOption={ADVANCE_NOTICE_OPTIONS.find((val) => val.id === value) || ADVANCE_NOTICE_OPTIONS[1]}
        />
      </Box>
    </Stack>
  );
};

export default RequiredAdvanceNotice;
