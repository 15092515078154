import { SvgIcon, SvgIconProps } from './SvgIcon';

export const UserSparkleIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1187 3.04307C11.4693 3.33 12.7967 2.46776 13.0837 1.11719C12.7967 2.46776 13.659 3.79521 15.0095 4.08215C13.659 3.79521 12.3315 4.65746 12.0446 6.00803C12.3315 4.65746 11.4693 3.33 10.1187 3.04307ZM7 3.75016C5.20507 3.75016 3.75 5.20524 3.75 7.00016V8.00016C3.75 9.79509 5.20507 11.2502 7 11.2502C8.79493 11.2502 10.25 9.79509 10.25 8.00016V7.00016C10.25 5.20524 8.79493 3.75016 7 3.75016ZM5.25 7.00016C5.25 6.03366 6.0335 5.25016 7 5.25016C7.9665 5.25016 8.75 6.03366 8.75 7.00016V8.00016C8.75 8.96666 7.9665 9.75016 7 9.75016C6.0335 9.75016 5.25 8.96666 5.25 8.00016V7.00016ZM3.30147 14.6806C4.66189 13.8371 6.71497 13.5476 8.55743 13.8919C10.4161 14.2392 11.7745 15.1632 12.1429 16.4559C12.1214 16.4772 12.0715 16.5062 12.0016 16.5067L3.50164 16.5624C3.36293 16.5633 3.25 16.4512 3.25 16.3124V14.8169C3.25 14.7232 3.2908 14.6872 3.30147 14.6806ZM13.5912 16.065C13.0018 13.956 10.9348 12.8102 8.83296 12.4174C6.70878 12.0205 4.25676 12.3233 2.51104 13.4057C1.99237 13.7273 1.75 14.2879 1.75 14.8169V16.3124C1.75 17.2834 2.54051 18.0688 3.51148 18.0624L12.0115 18.0067C12.4879 18.0035 12.9417 17.8053 13.2528 17.4651C13.574 17.1138 13.742 16.6047 13.5912 16.065ZM13.8769 5.64256C14.4972 8.04919 13.049 10.503 10.6424 11.1233C13.049 10.503 15.5028 11.9511 16.1231 14.3578C15.5028 11.9511 16.9509 9.49732 19.3576 8.87705C16.9509 9.49732 14.4972 8.04919 13.8769 5.64256Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
