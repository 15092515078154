import React, { useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Alert, AlertTitle } from '@material-ui/lab';

import Link from 'src/components/Link';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface OldContentProps {
  title: string;
  summary: string | undefined;
  description: string | undefined;
}

const OldContent = ({ title, summary, description }: OldContentProps): JSX.Element => {
  const [showOld, setShowOld] = useState<boolean>(false);

  const displayOld = () => {
    setShowOld(true);
  };

  if (!showOld) {
    return (
      <Alert severity="info">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={displayOld}>
          <div>View previous event details</div>
        </Link>
      </Alert>
    );
  }

  return (
    <Alert severity="info">
      <AlertTitle>{title}</AlertTitle>
      <div>{summary}</div>
      <div style={{ whiteSpace: 'pre-wrap' }}>{description}</div>
    </Alert>
  );
};

export default OldContent;
