import { Quill } from 'react-quill';

const Link = Quill.import('formats/link');
// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'radar', 'rdar', 'smb', 'sms'];

/**
 * This is necessary because Quill will convert a relative URL into an
 * absolute URL using the current origin. For example, a user could enter
 * excalidraw.com on the scheduling flow and it would convert to app.modernloop.io/schedule/1234/excalidraw.com.
 *
 * This will make sure the link is prepended with http:// in order to prevent that.
 *
 * https://github.com/quilljs/quill/issues/2677
 */
class CustomLinkSanitizer extends Link {
  static sanitize(url: string) {
    const value = super.sanitize(url);
    if (value) {
      for (let i = 0; i < this.PROTOCOL_WHITELIST.length; i++) {
        if (value.startsWith(this.PROTOCOL_WHITELIST[i])) {
          return value;
        }
      }

      return `http://${value}`;
    }
    return value;
  }
}

export default CustomLinkSanitizer;
