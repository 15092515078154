import { Box } from '@mui/material';
import { ReactNode, useMemo } from 'react';

export const CanDisable = ({ children, disabled }: { children: ReactNode; disabled: boolean }): JSX.Element => {
  const sxProps = useMemo(() => {
    if (disabled) {
      return {
        disabledStyle: {
          pointerEvents: 'none',
          opacity: '0.5',
        },
      };
    }
    return {};
  }, [disabled]);

  return (
    <Box
      // eslint-disable-next-line no-restricted-syntax
      sx={sxProps.disabledStyle}
      width="100%"
    >
      {children}
    </Box>
  );
};
