import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type JobStageInterviewGroup = {
  id: string;
  jobStageId: string;
  locked: boolean;
  // Not using index in code, only using it while storing it.
  // index: number;
  jobStageInterviewIds: string[];
  name?: string;
  atsId?: string;
};

export type UpdateJobStageInterviewGroupPayload = Pick<JobStageInterviewGroup, 'id'> &
  Partial<Omit<JobStageInterviewGroup, 'id'>>;

type JobStageInterviewGroupState = {
  byId: { [key: string]: JobStageInterviewGroup };
};

const getInitialState = (): JobStageInterviewGroupState => {
  return {
    byId: {},
  };
};

const jobStageInterviewGroupSlice = createSlice({
  name: 'jobStageInterviewGroup',
  initialState: getInitialState(),
  reducers: {
    addJobStageInterviewGroup: (state: JobStageInterviewGroupState, action: PayloadAction<JobStageInterviewGroup>) => {
      const { payload } = action;
      state.byId[payload.id] = payload;
    },

    updateJobStageInterviewGroup: (
      state: JobStageInterviewGroupState,
      action: PayloadAction<UpdateJobStageInterviewGroupPayload>
    ) => {
      const { payload } = action;

      state.byId[payload.id] = {
        ...state.byId[payload.id],
        ...payload,
      };
    },

    deleteJobStageInterviewGroup: (state: JobStageInterviewGroupState, action: PayloadAction<string>) => {
      const { payload: jobStageInterviewGroupId } = action;
      delete state.byId[jobStageInterviewGroupId];
    },

    lockJobStageInterviewGroup: (
      state: JobStageInterviewGroupState,
      action: PayloadAction<{ jobStageInterviewGroupId: string; locked: boolean }>
    ) => {
      const { jobStageInterviewGroupId, locked } = action.payload;
      state.byId[jobStageInterviewGroupId].locked = locked;
    },

    moveJobStageInterviewInGroup: (
      state: JobStageInterviewGroupState,
      action: PayloadAction<{ jobStageInterviewGroupId: string; jobStageInterviewId: string; position: number }>
    ) => {
      const { jobStageInterviewGroupId, jobStageInterviewId, position } = action.payload;
      const interviewIndex = state.byId[jobStageInterviewGroupId].jobStageInterviewIds.indexOf(jobStageInterviewId);

      if (interviewIndex === -1) return;
      const interviewIds = state.byId[jobStageInterviewGroupId].jobStageInterviewIds.splice(interviewIndex, 1);
      state.byId[jobStageInterviewGroupId].jobStageInterviewIds.splice(position, 0, interviewIds[0]);
    },
  },
});

export const { reducer } = jobStageInterviewGroupSlice;
export default jobStageInterviewGroupSlice;
