import { IframeStateAction, IframeStateActionType, IframeState } from './types';

const reducer = (state: IframeState, action: IframeStateAction): IframeState => {
  switch (action.type) {
    case IframeStateActionType.SetWindow: {
      return { ...state, iframeWindow: action.iframeWindow };
    }
    default:
      return state;
  }
};

export default reducer;
