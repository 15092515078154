import { useOneTrustLoader } from './useOnetrustLoader';
import React, { createContext } from 'react';

export interface Consents {
  consents: {
    preferences: boolean;
    marketing: boolean;
    statistics: boolean;
  } | null;
  cookieConsentEnabled: boolean;
}

export const CookieConsentContext = createContext<Consents>({ consents: null, cookieConsentEnabled: false });
interface Props {
  children: React.ReactNode;
}

export const CookieConsentProvider: React.FC<Props> = ({ children }) => {
  const { consents, oneTrustEnabled: cookieConsentEnabled } = useOneTrustLoader();

  return (
    <CookieConsentContext.Provider
      value={{
        consents,
        cookieConsentEnabled,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};
