import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { v4 as uuid } from 'uuid';

import {
  InterviewSeatsModule_LinkableInterviewsFragment,
  InterviewSeatsModule_OriginalSeatsFragment,
  InterviewSeatsModule_SeatsFragment,
  JobStageInterviewSeatType,
} from 'src/generated/mloop-graphql';

import { UserIcon } from 'src/components/icons';

import BaseInterviewSeatModule from './InterviewSeat/BaseInterviewSeatModule';

interface Props {
  jobStageInterviewId: string;
  readonly?: boolean;
  onUpdated: (seats: InterviewSeatsModule_SeatsFragment[]) => void;
  onDelete: (seatId: string) => void;
}

type Fragments = {
  seats: InterviewSeatsModule_SeatsFragment[];
  originalSeats: InterviewSeatsModule_OriginalSeatsFragment[];
  linkableInterviews: InterviewSeatsModule_LinkableInterviewsFragment[];
};

const InterviewSeatsModule: FCWithFragments<Fragments, Props> = ({
  seats,
  originalSeats,
  linkableInterviews,
  jobStageInterviewId,
  readonly,
  onUpdated,
  onDelete,
}): JSX.Element | null => {
  const handleAddInterviewer = () => {
    onUpdated([
      ...seats,
      {
        id: uuid(),
        index: seats.length,
        type: JobStageInterviewSeatType.Freeform,
        freeformSeat: {
          jobStageInterviewSeatEmployees: [],
        },
      },
    ]);
  };

  const handleUpdateSeat = (updatedSeat: InterviewSeatsModule_SeatsFragment) => {
    const updatedSeats = seats.map((seat) => {
      if (seat.id === updatedSeat.id) {
        return updatedSeat;
      }
      return seat;
    });
    onUpdated(updatedSeats);
  };

  return (
    <Grid container direction="column" spacing={0.5} wrap="nowrap">
      {seats.map((seat, index) => {
        const linkedInterview = linkableInterviews.find((interview) => {
          const linkedInterviewIndex = interview.jobStageInterviewSeats?.findIndex((s) => {
            return s.id === seat.linkedSeat?.linkedJobStageInterviewSeatId;
          });

          return linkedInterviewIndex !== undefined && linkedInterviewIndex !== -1;
        });

        const originalSeat = originalSeats.find((s) => s.id === seat.linkedSeat?.linkedJobStageInterviewSeatId);

        return (
          <Grid key={seat.id}>
            <BaseInterviewSeatModule
              seat={seat}
              originalSeat={originalSeat}
              jobStageInterviewId={jobStageInterviewId}
              linkedInterview={linkedInterview}
              linkableInterviews={linkableInterviews}
              seatIndex={index}
              onUpdated={handleUpdateSeat}
              onDelete={onDelete}
            />
          </Grid>
        );
      })}
      {!readonly && (
        <Grid container p={1}>
          <Button
            variant="text"
            color="primary"
            startIcon={<UserIcon color="primary" />}
            onClick={handleAddInterviewer}
          >
            Add interviewer
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

InterviewSeatsModule.fragments = {
  seats: gql`
    ${BaseInterviewSeatModule.fragments.seat}
    fragment InterviewSeatsModule_seats on JobStageInterviewSeat {
      id
      ...BaseInterviewSeatModule_seat
    }
  `,
  originalSeats: gql`
    ${BaseInterviewSeatModule.fragments.originalSeat}
    fragment InterviewSeatsModule_originalSeats on JobStageInterviewSeat {
      id
      ...BaseInterviewSeatModule_originalSeat
    }
  `,
  linkableInterviews: gql`
    ${BaseInterviewSeatModule.fragments.linkableInterviews}
    ${BaseInterviewSeatModule.fragments.linkedInterview}
    fragment InterviewSeatsModule_linkableInterviews on JobStageInterview {
      id
      ...BaseInterviewSeatModule_linkableInterviews
      ...BaseInterviewSeatModule_linkedInterview
    }
  `,
};

export default InterviewSeatsModule;
