import React from 'react';

import { Stack } from '@mui/material';

import { InterviewMeetingLocationType } from 'src/generated/mloop-graphql';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import VideoMeetingLinkMultipleRow from './VideoMeetingLinkMultipleRow';

type Props = {
  scheduleId: string;
  location: InterviewMeetingLocationType;
};

const VideoMeetingLinkMultiple = ({ scheduleId, location }: Props) => {
  const schedule = useScheduleWithoutBreaks(scheduleId);

  if (!schedule) return null;

  return (
    <Stack spacing={1.5}>
      {schedule.events.map((event) => {
        return (
          <VideoMeetingLinkMultipleRow key={event.id} scheduleId={scheduleId} location={location} interview={event} />
        );
      })}
    </Stack>
  );
};

export default VideoMeetingLinkMultiple;
