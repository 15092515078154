import { InterviewModuleSearchQuery, InterviewerSuggestionsQuery, TimeBlockWeek } from 'src/generated/mloop-graphql';

import { ArrayElement, ElementType } from 'src/types/utils';

export enum ScheduleFlowType {
  DEBRIEF = 'debrief',
  RESCHEDULE = 'reschedule',
  SCHEDULE = 'schedule',
  UPDATE = 'update',
  CREATE_TASK = 'create-task',
}

export type EmployeeWorkHoursById = { [employeeId: string]: TimeBlockWeek };

export type InterviewerSuggestion = ArrayElement<
  Exclude<ElementType<InterviewerSuggestionsQuery['interviewerSuggestions']>['employees'], null | undefined>
>;

export type InterviewModuleSuggestion = ArrayElement<
  Exclude<ElementType<InterviewModuleSearchQuery['interviewModuleSearch']>['items'], null | undefined>
>;

export type InterviewerSuggestionsEmployee = Exclude<
  Exclude<
    ArrayElement<
      Exclude<
        Exclude<
          Exclude<InterviewerSuggestionsQuery['interviewerSuggestions'], null | undefined>['employees'],
          null | undefined
        >,
        null | undefined
      >
    >,
    null | undefined
  >['employee'],
  null | undefined
>;
