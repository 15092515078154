import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { IsoTimestamp, formatTimeRange } from '@modernloop/shared/datetime';
import { EyeCrossedIcon, LinkIcon } from '@modernloop/shared/icons';
import { Avatar, Stack, Tooltip, Typography } from '@mui/material';

import {
  ApplicationStatus,
  ScheduleContentInterviewHeader_CandidateEventFragment,
  ScheduleContentInterviewHeader_InterviewFragment,
} from 'src/generated/mloop-graphql';

import { ZoomIcon } from 'src/components/icons';

import ScheduleContentInterviewMenu from './ScheduleContentInterviewMenu';

type Fragments = {
  interview: ScheduleContentInterviewHeader_InterviewFragment;
  candidateEvent: ScheduleContentInterviewHeader_CandidateEventFragment | undefined;
};

type Props = {
  applicationStageId: string;
  applicationStatus: ApplicationStatus;
  timezone: string;
  frozenAt?: IsoTimestamp;
};

const ScheduleContentInterviewHeader: FCWithFragments<Fragments, Props> = ({
  applicationStageId,
  applicationStatus,
  interview,
  candidateEvent,
  timezone,
  frozenAt,
}) => {
  const locationIcon = useMemo(() => {
    if (interview.videoMeetingUrl?.toLowerCase().indexOf('zoom') !== -1) {
      return <ZoomIcon dataTestId="schedule-content-interview-meeting-zoom-icon" tooltip="Zoom" fontSize={16} />;
    }

    if (interview.videoMeetingUrl?.toLowerCase().indexOf('meet.google.com') !== -1) {
      return (
        <Tooltip title="Google Meets">
          <Avatar
            data-testid="schedule-content-interview-meeting-google-meets-icon"
            src="/static/images/integrations/google-meets.png"
            sx={{ width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(2) }}
          />
        </Tooltip>
      );
    }

    if (interview.videoMeetingUrl?.toLowerCase().indexOf('teams.microsoft.com') !== -1) {
      return (
        <Tooltip title="Microsoft Teams">
          <Avatar
            data-testid="schedule-content-interview-meeting-microsoft-teams-icon"
            src="/static/images/integrations/microsoft-teams.png"
            sx={{ width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(2) }}
          />
        </Tooltip>
      );
    }

    if (interview.videoMeetingUrl?.length) {
      return (
        <LinkIcon
          data-testid="schedule-content-interview-meeting-custom-link-icon"
          tooltip="Includes a custom meeting link"
          fontSize="small"
        />
      );
    }

    return null;
  }, [interview.videoMeetingUrl]);

  const codingIntegrationIcon = useMemo(() => {
    const codingUrl = interview.codingInterviewUrl;

    if (!codingUrl) return null;

    let tooltip = '';
    let iconUrl = '';
    if (codingUrl && codingUrl.indexOf('coderpad') !== -1) {
      tooltip = 'CoderPad';
      iconUrl = '/static/images/integrations/CoderPad.png';
    }

    if (codingUrl && codingUrl.indexOf('codesignal') !== -1) {
      tooltip = 'CodeSignal';
      iconUrl = '/static/images/integrations/codesignal.png';
    }

    // Example Codility link - https://app.codility.com/test/XYZ123/,
    if (codingUrl && codingUrl.indexOf('codility') !== -1) {
      tooltip = 'Codility';
      iconUrl = '/static/images/integrations/codility.png';
    }

    // Example HackerRank link - https://hr.gs/123456
    if (codingUrl && codingUrl.indexOf('hr.gs') !== -1) {
      tooltip = 'HackerRank';
      iconUrl = '/static/images/integrations/hackerrank.png';
    }

    return (
      <Tooltip title={tooltip}>
        <Avatar
          data-testid="schedule-content-interview-coding-link-icon"
          src={iconUrl}
          sx={{ width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(2) }}
        />
      </Tooltip>
    );
  }, [interview.codingInterviewUrl]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" pl={2.5} pr={1.5} py={0.5}>
      <Stack>
        <Stack direction="row" spacing={0.5} alignItems="center">
          {interview.isHiddenFromCandidate && (
            <EyeCrossedIcon
              data-testid="schedule-content-interview-header-hidden-interview-icon"
              tooltip="Hidden from candidate"
            />
          )}
          <Typography variant="body1" data-testid="schedule-content-interview-header-interview-name">
            {interview.name}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Typography variant="body2" color="text.secondary">
            {formatTimeRange(interview.startAt, interview.endAt, timezone, true /* showTzAbbr */)}
          </Typography>

          {locationIcon}

          {codingIntegrationIcon}
        </Stack>
      </Stack>

      <ScheduleContentInterviewMenu
        applicationStatus={applicationStatus}
        applicationStageId={applicationStageId}
        interview={interview}
        candidateEvent={candidateEvent}
        frozenAt={frozenAt}
      />
    </Stack>
  );
};

ScheduleContentInterviewHeader.fragments = {
  interview: gql`
    ${ScheduleContentInterviewMenu.fragments.interview}
    fragment ScheduleContentInterviewHeader_interview on ApplicationStageInterview {
      id
      name
      startAt
      endAt
      googleEventUrl
      videoMeetingUrl
      zoomDialInInfo
      codingInterviewUrl
      isHiddenFromCandidate
      ...ScheduleContentInterviewMenu_interview
    }
  `,
  candidateEvent: gql`
    ${ScheduleContentInterviewMenu.fragments.candidateEvent}
    fragment ScheduleContentInterviewHeader_candidateEvent on ApplicationStageCandidateEvent {
      id
      ...ScheduleContentInterviewMenu_candidateEvent
    }
  `,
};

export default ScheduleContentInterviewHeader;
