import React, { useState } from 'react';

import { Dialog } from '@modernloop/shared/components';
import { getExternalErrorMessage, logDatadogError } from '@modernloop/shared/utils';
import { useSnackbar } from 'notistack';

import AtsName from 'src/entities/Ats/AtsName';
import AtsScorecardName from 'src/entities/Ats/AtsScorecardName';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useDirectoryInfo from 'src/hooks/useDirectoryInfo';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import cancelInterview from 'src/utils/api/cancelInterview';

type Props = {
  applicationStageInterviewId: string;
  onClose: () => void;
};

const ScheduleContentCancelInterviewDialog = ({ applicationStageInterviewId, onClose }: Props) => {
  const directoryInfo = useDirectoryInfo();

  const [canceling, setCanceling] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const refetchActivityFeed = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();

  const handleCancelEvent = async () => {
    setCanceling(true);
    // eslint-disable-next-line promise/catch-or-return
    cancelInterview({ applicationStageInterviewId })
      .then(() => {
        refetchScheduleTaskQuery();
        refetchActivityFeed();
        onClose();
        return undefined; // Returning to prevent eslint error
      })
      .catch((error) => {
        logDatadogError(error);
        enqueueSnackbar(getExternalErrorMessage(error), { variant: 'error' });
      })
      .finally(() => setCanceling(false));
  };

  return (
    <Dialog
      open
      title="Mark as canceled?"
      subTitle="This cannot be undone."
      onClose={onClose}
      submitOptions={{
        label: 'Cancel event',
        onClick: handleCancelEvent,
        isDangerous: true,
        isDisabled: canceling,
        isLoading: canceling,
      }}
      cancelOptions={{ label: 'Keep event', onClick: onClose, isDisabled: canceling }}
    >
      All records of this interview in <AtsName /> will be deleted, including any related <AtsScorecardName /> that have
      been submitted. The interviewer event in {directoryInfo.label} Calendar will also be deleted.
    </Dialog>
  );
};

export default ScheduleContentCancelInterviewDialog;
