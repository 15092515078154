import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/styles';
import { AvatarGroup, Stack } from '@mui/material';

import { AvatarFacePile_InterviewersFragment, ResponseStatus } from 'src/generated/mloop-graphql';

import Label from 'src/components/label';
import Tooltip from 'src/components/tooltip';
import { FCWithFragments } from 'src/components/types';

import AvatarWithRsvp from './AvatarWithRsvp';

const responseStatusTextMap = {
  [ResponseStatus.Accepted]: 'accepted',
  [ResponseStatus.Declined]: 'declined',
  [ResponseStatus.Tentative]: 'maybe',
  [ResponseStatus.NeedsAction]: 'awaiting',
};

const responseStatusOrder = {
  [ResponseStatus.Declined]: 0,
  [ResponseStatus.Tentative]: 1,
  [ResponseStatus.NeedsAction]: 2,
  [ResponseStatus.Accepted]: 3,
};

const useStyles = makeStyles(() =>
  createStyles({
    avatarWrapper: {
      alignItems: 'flex-start',
    },
    avatarItems: (props: { size: 'sm' | 'md' }) => ({
      '&>div': {
        width: props.size === 'sm' ? '20px' : '24px',
        height: props.size === 'sm' ? '20px' : '24px',
        borderRadius: '6px',
        fontSize: '12px',
      },
    }),
  })
);

interface Props {
  max?: number;
  tooltip?: string;
  dataTestId?: string;
  size?: 'sm' | 'md';
}

type Fragments = {
  interviewers: AvatarFacePile_InterviewersFragment[];
};

const AvatarFacePile: FCWithFragments<Fragments, Props> = ({
  dataTestId,
  interviewers,
  max = 3,
  tooltip,
  size = 'md',
}): JSX.Element | null => {
  const classes = useStyles({ size });

  const sortedInterviewers = useMemo(() => {
    const interviewersCopy = [...interviewers];

    interviewersCopy.sort((a, b) => {
      const aStatus = a.responseStatus ?? ResponseStatus.NeedsAction;
      const bStatus = b.responseStatus ?? ResponseStatus.NeedsAction;

      if (responseStatusOrder[aStatus] > responseStatusOrder[bStatus]) {
        return 1;
      }

      if (responseStatusOrder[aStatus] < responseStatusOrder[bStatus]) {
        return -1;
      }

      return 0;
    });

    return interviewersCopy ?? [];
  }, [interviewers]);

  if (!sortedInterviewers?.length) return null;

  const tooltipDataList: string[] = sortedInterviewers.map((interviewerData) => {
    return `${interviewerData.interviewer?.fullName} ${interviewerData.isOptional ? '(optional)' : ''} (${
      responseStatusTextMap[interviewerData.responseStatus ?? ResponseStatus.NeedsAction]
    })`;
  });

  return (
    <Stack className={classes.avatarWrapper}>
      <Tooltip
        dataTestId={dataTestId}
        tooltip={
          tooltip ?? (
            <Stack>
              {tooltipDataList.map((tooltipText) => (
                <Label key={tooltipText}>{tooltipText}</Label>
              ))}
            </Stack>
          )
        }
      >
        <AvatarGroup max={max} className={classes.avatarItems}>
          {sortedInterviewers.map((interviewer, index) => (
            <AvatarWithRsvp
              size={size === 'sm' ? '20px' : '24px'}
              // eslint-disable-next-line react/no-array-index-key
              key={`${interviewer?.interviewerId}-${interviewer?.responseStatus}-${index}`}
              responseStatus={interviewer?.responseStatus}
              src={interviewer?.interviewer?.slackImageUrl || interviewer?.interviewer?.fullName || undefined}
              alt={interviewer?.interviewer?.fullName ?? ''}
              outlineOffset="-3px"
            />
          ))}
        </AvatarGroup>
      </Tooltip>
    </Stack>
  );
};

AvatarFacePile.fragments = {
  interviewers: gql`
    fragment AvatarFacePile_interviewers on ApplicationStageInterviewInterviewer {
      interviewerId
      responseStatus
      isOptional
      interviewer {
        id
        fullName
        slackImageUrl
      }
    }
  `,
};

export default AvatarFacePile;
