import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ContentCardIcon = (props: SvgIconProps): JSX.Element => {
  const { sx, fill, ...rest } = props;
  return (
    // TODO: Fix this the next time the file is edited
    // eslint-disable-next-line no-restricted-syntax
    <SvgIcon {...rest} sx={{ ...sx, fill: fill || 'none' }}>
      <rect
        x="16.75"
        y="2.75"
        width="14.5"
        height="13.5"
        rx="2.25"
        transform="rotate(90 16.75 2.75)"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <line x1="7.75" y1="6.25" x2="12.25" y2="6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <line x1="6.75" y1="10.25" x2="13.25" y2="10.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <line x1="6.75" y1="13.25" x2="13.25" y2="13.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </SvgIcon>
  );
};
