/**
 * TODO ENG-9217 @Ashutosh use it directly from mloop-shared once candidate portal works fine as candidate portal is using shared version
 * Not doing it right now as unit test is faling for some reason, will debug once candidate portal is working fine
 */
import { AxiosRequestConfig } from 'axios';

import { getJSONWebToken } from 'src/contexts/auth0/utils';

import logError from 'src/utils/logError';

/**
 * This axios interceptor adds the users JWT to the `Authorization` header as a `Bearer` token
 *
 * @param config AxiousRequestConfig to add auth header too
 * @returns AxiosRequestConfig that has the new JWT
 */
const axiosInterceptorRequestAddJWT = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  try {
    // The check is to mainly make the compiler happy, headers should never be null
    if (config.headers) {
      const token = await getJSONWebToken();
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    logError(error);
  }
  return config;
};

export default axiosInterceptorRequestAddJWT;
