import React from 'react';

import { useFlag } from '@modernloop/shared/feature-flag';

import BaseTimezoneSelect, { BaseTimezoneSelectProps } from 'src/components/date-time-picker/BaseTimezoneSelect';

import useDisplayTimezone from 'src/hooks/useDisplayTimezone';

import { addLastUsedTimezone } from 'src/slices/persist';

import { setDisplayTimezone } from 'src/store/actions/ui-state';

import { useDispatch, useSelector } from 'src/store';

import useRecentlyUsedTimezonesEmployeePref from './useRecentlyUsedTimezonesEmployeePref';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type TimezoneSelectProps = Omit<BaseTimezoneSelectProps, 'lastUsedTimezones'> & {
  shouldUpdateDisplayTimezone?: boolean;
};

/**
 * Use this component when you want to show timezone picker that is common across ModernLoop app
 * Updating timezone will update all the display times according to selected timezone.
 */
const TimezoneSelect = (props: TimezoneSelectProps): JSX.Element => {
  const showLastUsedTimezones = useFlag('user_move_recently_used_timezones_to_employee_pref');
  const { onChange, value, shouldUpdateDisplayTimezone, ...rest } = props;
  const { lastUsedTimezones: lastUsedTimezonesPersist } = useSelector((state) => state.persist);
  const displayTimezone = useDisplayTimezone();

  const dispatch = useDispatch();

  const { recentlyUsedTimezones, addRecentlyUsedTimezone } = useRecentlyUsedTimezonesEmployeePref();

  const lastUsedTimezones = showLastUsedTimezones ? recentlyUsedTimezones : lastUsedTimezonesPersist;

  const handleChange = (timezone: string) => {
    if (onChange) {
      onChange(timezone);
    }

    dispatch(addLastUsedTimezone(timezone));

    if (showLastUsedTimezones) {
      addRecentlyUsedTimezone(timezone);
    }

    if (shouldUpdateDisplayTimezone) {
      dispatch(setDisplayTimezone(timezone));
    }
  };

  const timezone = value ?? displayTimezone ?? 'UTC';

  return (
    <BaseTimezoneSelect {...rest} lastUsedTimezones={lastUsedTimezones} onChange={handleChange} value={timezone} />
  );
};

export default TimezoneSelect;
