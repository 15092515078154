import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { TraineeIcon } from '@modernloop/shared/icons';
import { Chip } from '@mui/material';

import {
  CurrentTrainingSessionStatus,
  ModuleMemberPendingChip_ModuleMemberFragment,
} from 'src/generated/mloop-graphql';

type Fragments = {
  moduleMember: ModuleMemberPendingChip_ModuleMemberFragment;
};

const ModuleMemberPendingChip: FCWithFragments<Fragments> = ({ moduleMember }) => {
  // TODO: Replace this logic with the util function once this PR is merged #4114
  if (moduleMember.stats?.currentTrainingSessionStatus === CurrentTrainingSessionStatus.NeedsApproval) {
    return <Chip icon={<TraineeIcon />} label="Pending" color="warning" />;
  }
  return null;
};

ModuleMemberPendingChip.fragments = {
  moduleMember: gql`
    fragment ModuleMemberPendingChip_moduleMember on InterviewModuleMember {
      stats {
        currentTrainingSessionStatus
      }
      id
    }
  `,
};

export default ModuleMemberPendingChip;
