import { useMemo } from 'react';

import { formatDuration } from 'date-fns';

import { JobStageInterviewSeatType } from 'src/generated/mloop-graphql';

import { getAllJobStageInterviewByJobStageId } from 'src/store/selectors/job-stage-interview';
import { getAllJobStageInterviewSeatByJobStageId } from 'src/store/selectors/job-stage-interview-seat';

import { isBreak, isDayBreak } from 'src/utils/interview';

import { useSelector } from 'src/store';

const useInterviewPlanDescription = (jobStageId: string) => {
  const jobStageInterviews = useSelector((state) => getAllJobStageInterviewByJobStageId(state, jobStageId));
  const jobStageInterviewSeats = useSelector((state) => getAllJobStageInterviewSeatByJobStageId(state, jobStageId));

  const interviewPlanMetaStr = useMemo(() => {
    if (
      !jobStageInterviews ||
      !jobStageInterviewSeats ||
      !jobStageInterviews.length ||
      !jobStageInterviewSeats.length
    ) {
      return '';
    }

    let durationInMins = 0;
    let interviews = 0;
    let minInterviewers = 0;
    let maxInterviewers = 0;

    jobStageInterviews.forEach((jobStageInterview) => {
      if (!isBreak(jobStageInterview.type) && !isDayBreak(jobStageInterview.type)) {
        interviews++;
      }
      durationInMins += isDayBreak(jobStageInterview.type) ? 1440 : jobStageInterview.duration;
    });

    jobStageInterviewSeats.forEach((jobStageInterviewSeat) => {
      const originalSeat =
        jobStageInterviewSeat.type === JobStageInterviewSeatType.Linked
          ? jobStageInterviewSeats.find((seat) => seat.id === jobStageInterviewSeat.interviewSeatId)
          : jobStageInterviewSeat;

      if (originalSeat?.type === JobStageInterviewSeatType.Freeform) {
        minInterviewers++;
        maxInterviewers++;
      } else if (originalSeat?.type === JobStageInterviewSeatType.Module) {
        minInterviewers++;
        maxInterviewers += 2;
      }
    });

    const durationStr = formatDuration({
      days: Math.floor(durationInMins / 1440),
      hours: Math.floor((durationInMins / 60) % 24),
      minutes: durationInMins % 60,
    });

    return `${interviews} ${interviews === 1 ? 'interview' : 'interviews'}, ${durationStr}, ${
      minInterviewers === maxInterviewers ? minInterviewers : `${minInterviewers} - ${maxInterviewers}`
    } ${minInterviewers === maxInterviewers && minInterviewers === 1 ? 'interviewer' : 'interviewers'}`;
  }, [jobStageInterviews, jobStageInterviewSeats]);

  return interviewPlanMetaStr;
};

export default useInterviewPlanDescription;
