import { CaretLeftIcon, CaretRightIcon } from '@modernloop/shared/icons';
import { ThemeOptions } from '@mui/material';

/**
 * View Pagination props and classes at https://mui.com/material-ui/api/pagination/#css
 */
export const pagination: ThemeOptions['components'] = {
  MuiPagination: {
    defaultProps: {
      shape: 'rounded',
      color: 'primary',
    },
  },
  MuiPaginationItem: {
    defaultProps: {
      slots: {
        previous: CaretLeftIcon,
        next: CaretRightIcon,
      },
    },
  },
};
