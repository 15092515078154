/* eslint-disable max-lines */
import React, { useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box, DialogContent, Divider } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import clsx from 'clsx';
import { addMinutes, differenceInMinutes, format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { v4 as uuid } from 'uuid';

import {
  AtsInterviewDefinitionFragment,
  InterviewDetails_InterviewFragment,
  InterviewDetails_OriginalInterviewsFragment,
  InterviewerRole,
} from 'src/generated/mloop-graphql';

import DialogContainer, { DialogContainerHeader } from 'src/components/DialogContainer';
import Stack from 'src/components/Stack';
import ZeroState from 'src/components/ZeroState';
import Button from 'src/components/button';
import DatePicker, { useDateButtonStyle } from 'src/components/date-time-picker/DatePickerPopover';
import TimePicker from 'src/components/date-time-picker/TimePicker';
import {
  CalendarClock,
  CheckIcon,
  DurationIcon,
  MonthIcon,
  ScorecardIcon,
  TrashIcon,
  TriangleDownIcon,
  UserAddIcon,
} from 'src/components/icons';
import Label from 'src/components/label';
import DurationMenu, { DURATION_OPTIONS } from 'src/components/menu/DurationMenu';

import { PREFERENCE_LEVEL_NON_PREFERRED } from 'src/constants/InterviewPlan';

import { useOrgAtsService } from 'src/hooks/atsService';
import useDisplayTimezone from 'src/hooks/useDisplayTimezone';
import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';
import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { updateJobStageInterview } from 'src/store/actions/job-stage-interview';
import { clearAnyLinkedJobStageInterviewSeats } from 'src/store/actions/job-stage-interview-seat';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';
import { ScheduleUpdate, ScheduleUpdateScorecard, ScheduleUpdateType } from 'src/store/slices/schedule-update';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import {
  AttributeMap,
  InterviewEvent,
  RichInterviewer,
  UIFilledInterviewSeat,
  UIInterviewSeatFreeform,
  UIInterviewSeatModule,
} from 'src/utils/api/getScheduleOptions';
import { guessIsoTimestamp } from 'src/utils/datetime/IsoTimestamp';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';
import AtsScorecard from 'src/views-new/Scorecards/AtsScorecard';

import { useDispatch, useSelector } from 'src/store';
import { Theme } from 'src/theme';

import UpdateInterviewSeat from './UpdateInterviewSeat';
import { applyUpdatesToSchedule } from './applyUpdatesToSchedule';
import { InterviewerSuggestion } from './types';
import { getRichInterviewerFromSuggestion } from './utils';

type Fragments = {
  interview: InterviewDetails_InterviewFragment | undefined;
  originalInterviews: InterviewDetails_OriginalInterviewsFragment[];
};

type Props = {
  atsJobId: string;
  interviewPlanId: string;
  organizationId: string | null;
  scheduleId: string;
  interviewEvent: InterviewEvent;
  scheduleFlowType: ScheduleFlowType;
  open: boolean;
  isNewInterview?: boolean;
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentRoot: {
      padding: 0,
    },
    interviewConfigSection: {
      backgroundColor: theme.palette.background.contrast,
      padding: theme.spacing(1.5),
    },
    button: {
      borderRadius: '6px',
    },
    timePickerContainer: {
      width: '120px',
      '& div': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    interviewerConfigSection: {
      padding: theme.spacing(1.5),
    },
    interviewerContainer: {
      paddingBottom: theme.spacing(2),
    },
  })
);

const InterviewDetails: FCWithFragments<Fragments, Props> = ({
  atsJobId,
  interviewPlanId,
  organizationId,
  scheduleId,
  interviewEvent: interviewEventProp,
  scheduleFlowType,
  open,
  isNewInterview,
  interview,
  originalInterviews,
  onClose,
}): JSX.Element | null => {
  const atsService = useOrgAtsService();
  const classes = useStyles();
  const dispatch = useDispatch();

  const displayTimezone = useDisplayTimezone();
  const { logEvent } = useLogEvent();

  const scheduleUpdates = useRef<ScheduleUpdate[]>([]);
  const seatIdsToUnlink = useRef<string[]>([]);

  const [renderCount, setRenderCount] = useState(0);
  const [addingNewSeat, setAddingNewSeat] = useState(false);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [durationMenuOpen, setDurationMenuOpen] = useState(false);

  const datePickerAnchorEl = useRef<HTMLButtonElement>(null);
  const durationAnchorEl = useRef<HTMLButtonElement>(null);

  const dateButtonStyle = useDateButtonStyle();

  const interviewPlanEntityInScheduleFlowEnabled = useFlag('interview_plan_entity_in_schedule_flows');

  // To get atsInterviewDefinitionId for schedule flow.
  const jobStageInterview = useSelector((state) => {
    if (!interviewEventProp.slotId) return null;
    return getJobStageInterviewById(state, interviewEventProp.slotId);
  });

  const schedule = useScheduleWithoutBreaks(scheduleId);

  const updatedSchedule = applyUpdatesToSchedule(
    {
      id: scheduleId,
      jobStageId: interviewPlanId,
      events: [interviewEventProp],
    },
    scheduleUpdates.current
  );

  const interviewEvent =
    updatedSchedule.events && updatedSchedule.events.length ? updatedSchedule.events[0] : interviewEventProp;

  const [startAt, setStartAt] = useState(interviewEvent.startAt);
  const [endAt, setEndAt] = useState(interviewEvent.endAt);

  const [atsInterviewDefinitionId, setAtsInterviewDefinitionId] = useState<string | undefined>(
    interviewEvent.atsInterviewDefinitionId ||
      (interviewPlanEntityInScheduleFlowEnabled
        ? interview?.atsInterviewDefinition?.atsId
        : jobStageInterview?.atsInterviewDefinition?.atsId)
  );

  const [isHiddenFromCandidate, setIsHiddenFromCandidate] = useState(interviewEvent.isHiddenFromCandidate);

  const duration = differenceInMinutes(parseISO(endAt), parseISO(startAt));

  const interviewerCount = useMemo(() => {
    if (!updatedSchedule.events || updatedSchedule.events.length === 0) return 0;

    let count = 0;
    updatedSchedule.events.forEach((event) => {
      if (!event.interviewers || event.interviewers.length === 0) return;

      event.interviewers.forEach((i) => {
        if (i.role === InterviewerRole.Interviewer) {
          count++;
        }
      });
    });
    return count;
  }, [updatedSchedule]);

  const excludeInterviewerIds = useMemo(() => {
    if (!updatedSchedule.events || updatedSchedule.events.length === 0) return [];

    return updatedSchedule.events
      .map((event) => {
        if (!event.filledInterviewSeats) return [] as string[];
        return event.filledInterviewSeats
          .map((filledInterviewSeat) => {
            if (filledInterviewSeat.traineeId) {
              return [filledInterviewSeat.interviewerId, filledInterviewSeat.traineeId];
            }

            return [filledInterviewSeat.interviewerId];
          })
          .flat();
      })
      .flat();
  }, [updatedSchedule]);

  const handleNameBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!event || !event.target) return;

    // Deleting the old value.
    scheduleUpdates.current = scheduleUpdates.current.filter((update) => update.type !== 'SchdeuleUpdateInterviewName');

    scheduleUpdates.current.push({
      type: 'SchdeuleUpdateInterviewName',
      scheduleId,
      applicationStageInterviewId: interviewEvent.id,
      name: event.target.value?.trim(),
    });
  };

  const openDatePicker = () => {
    setDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setDatePickerOpen(false);
  };

  const handleDateTimeChange = (dateTime: Date | undefined) => {
    if (dateTime) {
      const newStartAt = dateTime;
      const newEndAt = addMinutes(newStartAt, duration);

      setStartAt(newStartAt.toISOString());
      setEndAt(newEndAt.toISOString());

      // Deleting the old value.
      scheduleUpdates.current = scheduleUpdates.current.filter(
        (update) => update.type !== 'SchdeuleUpdateInterviewTime'
      );

      scheduleUpdates.current.push({
        type: 'SchdeuleUpdateInterviewTime',
        scheduleId,
        applicationStageInterviewId: interviewEvent.id,
        startAt: newStartAt.toISOString(),
        endAt: newEndAt.toISOString(),
        duration,
      });
    }
  };

  const openDurationMenu = () => {
    setDurationMenuOpen(true);
  };

  const handleDurationMenuClose = () => {
    setDurationMenuOpen(false);
  };

  const handleDurationSelect = (newDuration: number) => {
    handleDurationMenuClose();

    const newStartAt = parseISO(startAt);
    const newEndAt = addMinutes(newStartAt, newDuration);

    setEndAt(newEndAt.toISOString());

    // Deleting the old value.
    scheduleUpdates.current = scheduleUpdates.current.filter((update) => update.type !== 'SchdeuleUpdateInterviewTime');

    scheduleUpdates.current.push({
      type: 'SchdeuleUpdateInterviewTime',
      scheduleId,
      applicationStageInterviewId: interviewEvent.id,
      startAt: newStartAt.toISOString(),
      endAt: newEndAt.toISOString(),
      duration: newDuration,
    });
  };

  const handleAtsScorecardSelect = (atsInterviewDefinition: AtsInterviewDefinitionFragment) => {
    // Deleting the old value.
    scheduleUpdates.current = scheduleUpdates.current.filter((update) => update.type !== 'ScheduleUpdateScorecard');

    setAtsInterviewDefinitionId(atsInterviewDefinition.atsId);

    scheduleUpdates.current.push({
      type: 'ScheduleUpdateScorecard',
      scheduleId,
      applicationStageInterviewId: interviewEvent.id,
      atsInterviewDefinitionId: atsInterviewDefinition.atsId,
    });
  };

  const handleMarkAsHiddenFromCandidate = () => {
    // Deleting old value
    scheduleUpdates.current = scheduleUpdates.current.filter(
      (update) => update.type !== 'ScheduleUpdateInterviewHiddenFromCandidate'
    );

    setIsHiddenFromCandidate(!isHiddenFromCandidate);

    scheduleUpdates.current.push({
      type: 'ScheduleUpdateInterviewHiddenFromCandidate',
      scheduleId,
      applicationStageInterviewId: interviewEvent.id,
      isHiddenFromCandidate: !isHiddenFromCandidate,
    });
  };

  const handleClose = (event: React.MouseEvent) => {
    // Below 2 lines are required because without them the click event is propogated to <EventContent />
    // and it sets the selected event again causing <InterviewDetails /> to never dismiss.
    event.preventDefault();
    event.stopPropagation();

    onClose();
  };

  const getFreeformFilledSeat = (interviewerId: string): UIFilledInterviewSeat => {
    return {
      interviewerId,
      seat: {
        freeformSeat: {
          id: uuid(),
          interviewers: [{ id: interviewerId, preferenceLevel: PREFERENCE_LEVEL_NON_PREFERRED }],
        },
      },
    };
  };

  const getInterviewSeat = (filledInterviewSeat: UIFilledInterviewSeat) => {
    return filledInterviewSeat.seat;
  };

  const addSeatIdToUnlink = (filledInterviewSeat: UIFilledInterviewSeat) => {
    if (filledInterviewSeat.seat.moduleSeat) {
      seatIdsToUnlink.current.push(filledInterviewSeat.seat.moduleSeat.id);
    } else if (filledInterviewSeat.seat.freeformSeat) {
      seatIdsToUnlink.current.push(filledInterviewSeat.seat.freeformSeat.id);
    }
  };

  const addInterviewer = (interviewer: RichInterviewer, filledInterviewSeat: UIFilledInterviewSeat) => {
    const seat = getInterviewSeat(filledInterviewSeat);

    if (!seat) return;

    const updateNew: ScheduleUpdate = {
      type: 'ScheduleUpdateInterviewSeat',
      updateType: ScheduleUpdateType.NEW,
      scheduleId,
      applicationStageInterviewId: interviewEvent.id,
      applicationStageInterviewInterviewerId: interviewer.employeeId,
      seat,
      interviewer,
      prevInterviewerId: '',
    };

    scheduleUpdates.current.push(updateNew);
  };

  const removeInterviewer = (interviewer: RichInterviewer, filledInterviewSeat: UIFilledInterviewSeat) => {
    const seat = getInterviewSeat(filledInterviewSeat);

    if (!seat) return;

    const update: ScheduleUpdate = {
      type: 'ScheduleUpdateInterviewSeat',
      updateType: ScheduleUpdateType.DELETE,
      scheduleId,
      applicationStageInterviewId: interviewEvent.id,
      applicationStageInterviewInterviewerId: interviewer.employeeId,
      seat,
      interviewer,
      prevInterviewerId: interviewer.employeeId,
    };

    scheduleUpdates.current.push(update);
  };

  const handleInterviewerRemoved = (
    filledInterviewSeat: UIFilledInterviewSeat,
    trained: RichInterviewer,
    trainee?: RichInterviewer
    // eslint-disable-next-line max-params
  ) => {
    addSeatIdToUnlink(filledInterviewSeat);

    removeInterviewer(trained, filledInterviewSeat);

    if (trainee) {
      removeInterviewer(trainee, filledInterviewSeat);
      addInterviewer({ ...trainee, role: InterviewerRole.Interviewer }, getFreeformFilledSeat(trainee.employeeId));
    }
    setRenderCount(renderCount + 1);
  };

  const handleInterviewerModuleClear = (
    filledInterviewSeat: UIFilledInterviewSeat,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line max-lines
    trained: RichInterviewer,
    trainee?: RichInterviewer
    // eslint-disable-next-line max-params
  ) => {
    addSeatIdToUnlink(filledInterviewSeat);

    removeInterviewer(trained, filledInterviewSeat);
    addInterviewer({ ...trained, role: InterviewerRole.Interviewer }, getFreeformFilledSeat(trained.employeeId));

    if (trainee) {
      removeInterviewer(trainee, filledInterviewSeat);
      addInterviewer({ ...trainee, role: InterviewerRole.Interviewer }, getFreeformFilledSeat(trainee.employeeId));
    }

    setRenderCount(renderCount + 1);
  };

  const handleTraineeRemoved = (filledInterviewSeat: UIFilledInterviewSeat, trainee: RichInterviewer) => {
    addSeatIdToUnlink(filledInterviewSeat);

    removeInterviewer(trainee, filledInterviewSeat);
    setRenderCount(renderCount + 1);
  };

  const handleTraineeModuleClear = (filledInterviewSeat: UIFilledInterviewSeat, trainee: RichInterviewer) => {
    addSeatIdToUnlink(filledInterviewSeat);

    removeInterviewer(trainee, filledInterviewSeat);
    addInterviewer({ ...trainee, role: InterviewerRole.Interviewer }, getFreeformFilledSeat(trainee.employeeId));
    setRenderCount(renderCount + 1);
  };

  const handleInterviewSeatAdded = (
    filledInterviewSeat: UIFilledInterviewSeat,
    newInterviewer: InterviewerSuggestion
  ) => {
    addInterviewer(getRichInterviewerFromSuggestion(newInterviewer), filledInterviewSeat);
    setAddingNewSeat(false);
    setRenderCount(renderCount + 1);
  };

  const handleInterviewSeatUpdate = (
    filledInterviewSeat: UIFilledInterviewSeat,
    newInterviewer: InterviewerSuggestion,
    originalJobStageInterviewSeat: JobStageInterviewSeat
    // eslint-disable-next-line max-params
  ) => {
    addSeatIdToUnlink(filledInterviewSeat);

    let seat = getInterviewSeat(filledInterviewSeat);

    if (!seat || !newInterviewer) return;

    if (filledInterviewSeat.seat.linkedSeat) {
      if (originalJobStageInterviewSeat.interviewId) {
        let attributeMap: AttributeMap | undefined;

        if (originalJobStageInterviewSeat.attributeMap) {
          attributeMap = {};
          Object.keys(originalJobStageInterviewSeat.attributeMap).forEach((key) => {
            if (!attributeMap || !originalJobStageInterviewSeat.attributeMap) return;
            attributeMap[key] = { attributeValues: originalJobStageInterviewSeat.attributeMap[key] };
          });
        }

        const moduleSeat: UIInterviewSeatModule = {
          id: seat.moduleSeat?.id || uuid(),
          interviewId: originalJobStageInterviewSeat.interviewId,
          attributeMap,
          selectedReverseShadowInterviewerIds: originalJobStageInterviewSeat.selectedReverseShadowInterviewerIds,
          selectedShadowInterviewerIds: originalJobStageInterviewSeat.selectedShadowInterviewerIds,
          selectedTrainedInterviewerIds: originalJobStageInterviewSeat.selectedTrainedInterviewerIds,
        };
        seat = { moduleSeat };
      } else {
        const freeformSeat: UIInterviewSeatFreeform = {
          id: seat.freeformSeat?.id || uuid(),
          interviewers:
            originalJobStageInterviewSeat.employeeIds?.map((employeeId) => ({
              id: employeeId,
              preferenceLevel: PREFERENCE_LEVEL_NON_PREFERRED,
            })) || [],
        };
        seat = { freeformSeat };
      }
    }

    const update: ScheduleUpdate = {
      type: 'ScheduleUpdateInterviewSeat',
      scheduleId,
      applicationStageInterviewId: interviewEvent.id,
      applicationStageInterviewInterviewerId: newInterviewer.employeeId,
      updateType: ScheduleUpdateType.EDIT,
      seat,
      interviewer: getRichInterviewerFromSuggestion(newInterviewer),
      prevInterviewerId:
        newInterviewer?.role === InterviewerRole.Interviewer
          ? filledInterviewSeat.interviewerId
          : (filledInterviewSeat.traineeId as string),
    };

    scheduleUpdates.current.push(update);
    setRenderCount(renderCount + 1);
  };

  const handleUseInterviewModule = (
    filledInterviewSeat: UIFilledInterviewSeat,
    trainedInterviewer: RichInterviewer
  ) => {
    addSeatIdToUnlink(filledInterviewSeat);

    const seat = getInterviewSeat(filledInterviewSeat) || null;
    const update: ScheduleUpdate = {
      type: 'ScheduleUpdateInterviewSeat',
      scheduleId,
      applicationStageInterviewId: interviewEvent.id,
      applicationStageInterviewInterviewerId: filledInterviewSeat.interviewerId,
      updateType: ScheduleUpdateType.EDIT,
      seat,
      interviewer: trainedInterviewer,
      prevInterviewerId: filledInterviewSeat.interviewerId,
    };

    scheduleUpdates.current.push(update);
    setRenderCount(renderCount + 1);
  };

  const handleInterviewUpdate = (event: React.MouseEvent) => {
    scheduleUpdates.current.forEach((update) => {
      dispatch(addInterviewScheduleUpdate(update));
      const seatId =
        update.type === 'ScheduleUpdateInterviewSeat'
          ? update.seat?.moduleSeat?.id || update.seat?.freeformSeat?.id || update.seat?.linkedSeat?.id
          : undefined;

      if (
        update.type === 'ScheduleUpdateInterviewSeat' &&
        update.seat &&
        seatId &&
        seatIdsToUnlink.current.includes(seatId)
      ) {
        dispatch(clearAnyLinkedJobStageInterviewSeats(seatId));
      }
    });

    if (isNewInterview) {
      logEvent(LoggerEvent.CLIENT_SCHEDULE_FLOW_ADD_INTERVIEW, {
        scheduleFlowType,
      });
    }

    const scorecardUpdates = scheduleUpdates.current.filter((update) => update.type === 'ScheduleUpdateScorecard');
    const interviewPresent = Boolean(interviewPlanEntityInScheduleFlowEnabled ? interview : jobStageInterview);
    if (interviewPresent && scorecardUpdates && scorecardUpdates.length && !interviewPlanEntityInScheduleFlowEnabled) {
      const scorecardUpdate = scorecardUpdates[0] as ScheduleUpdateScorecard;
      dispatch(
        updateJobStageInterview({
          id: interviewPlanEntityInScheduleFlowEnabled ? interview?.id : jobStageInterview?.id,

          atsInterviewDefinition: {
            atsId: scorecardUpdate.atsInterviewDefinitionId || '',
            atsJobStageId: scorecardUpdate.atsJobStageId,
          },
        })
      );
    }

    handleClose(event);
  };

  const handleDeleteInterview = (event: React.MouseEvent) => {
    scheduleUpdates.current = [
      {
        type: 'ScheduleUpdateDeleteInterview',
        scheduleId,
        applicationStageInterviewId: interviewEvent.id,
      },
    ];
    handleInterviewUpdate(event);

    if (!isNewInterview) {
      logEvent(LoggerEvent.CLIENT_SCHEDULE_FLOW_DELETE_INTERVIEW, {
        scheduleFlowType,
      });
    }
  };

  const handleCancel = (event: React.MouseEvent) => {
    if (isNewInterview) {
      handleDeleteInterview(event);
    }
    handleClose(event);
  };

  const isLastInterview = Boolean(schedule?.events && schedule.events.length <= 1);
  const lastInterviewDeleteDisableTooltip = useMemo(() => {
    if (!isLastInterview) return undefined;
    if (scheduleFlowType === ScheduleFlowType.UPDATE) {
      return `The last interview of a schedule may not be deleted, please use the "Cancel" flow on the Candidate Details page.`;
    }
    return `The last interview may not be deleted.`;
  }, [isLastInterview, scheduleFlowType]);

  if (!atsService || !organizationId) return null;

  return (
    <DialogContainer open={open} onClose={handleCancel}>
      <DialogContainerHeader
        title="Edit interview"
        headerActions={
          <Stack spacing={1}>
            <Button
              dataTestId="schedule-interview-event-details-cancel"
              variant="outlined"
              size="small"
              label="Cancel"
              onClick={handleCancel}
            />
            <Button
              dataTestId="schedule-interview-event-details-update"
              color="primary"
              variant="contained"
              size="small"
              disabled={interviewerCount === 0}
              label="Update"
              startIcon={<CheckIcon color={interviewerCount === 0 ? undefined : 'background'} />}
              onClick={handleInterviewUpdate}
            />
          </Stack>
        }
      />
      <DialogContent className={classes.contentRoot}>
        <Box
          className={classes.interviewConfigSection}
          data-testid="schedule-interview-event-details" /* Placeholder for testing */
        >
          <Stack direction="column" spacing={2}>
            <TextField
              data-testid="schedule-interview-event-details-name"
              label="Interview name"
              fullWidth
              disabled={scheduleFlowType === ScheduleFlowType.DEBRIEF}
              placeholder="Enter interview name"
              defaultValue={interviewEvent.name}
              autoFocus
              onBlur={handleNameBlur}
            />
            <Stack alignItems="center" itemStyles={{ 0: { width: '116px' }, 1: { flexGrow: 1 } }}>
              <Box display="flex" alignItems="center">
                <CalendarClock />
                &nbsp;
                <Label>Start</Label>
              </Box>

              <Stack alignItems="center" spacing={1}>
                <>
                  <Button
                    dataTestId="schedule-interview-event-details-date-button"
                    ref={datePickerAnchorEl}
                    variant="outlined"
                    startIcon={<MonthIcon />}
                    label={format(utcToZonedTime(startAt, displayTimezone), 'LLLL d, yyyy')}
                    className={clsx(dateButtonStyle.button, classes.button)}
                    onClick={openDatePicker}
                  />
                  <DatePicker
                    dataTestId="schedule-interview-event-details-date-picker"
                    utcDate={new Date(startAt)}
                    timezone={displayTimezone}
                    open={datePickerOpen}
                    dateAnchorEl={datePickerAnchorEl}
                    onChange={handleDateTimeChange}
                    onClose={handleDatePickerClose}
                  />
                </>
                <Box className={classes.timePickerContainer}>
                  <TimePicker
                    dataTestId="schedule-interview-event-details-time-picker"
                    utcTime={new Date(startAt)}
                    timezone={displayTimezone}
                    onChange={handleDateTimeChange}
                  />
                </Box>
              </Stack>
            </Stack>

            <Stack alignItems="center" itemStyles={{ 0: { width: '116px' }, 1: { flexGrow: 1 } }}>
              <Box display="flex" alignItems="center">
                <DurationIcon />
                &nbsp;
                <Label>Duration</Label>
              </Box>

              <>
                <Button
                  label={
                    DURATION_OPTIONS.find((option) => option.id === duration)?.value ??
                    `${duration} ${duration === 1 ? 'min' : 'mins'}`
                  }
                  endIcon={<TriangleDownIcon />}
                  ref={durationAnchorEl}
                  variant="outlined"
                  size="medium"
                  className={classes.button}
                  onClick={openDurationMenu}
                />
                <DurationMenu
                  id="interview-slot-duration-menu"
                  open={durationMenuOpen}
                  anchorEl={durationAnchorEl}
                  maxDuration={720}
                  onSelect={handleDurationSelect}
                  onClose={handleDurationMenuClose}
                />
              </>
            </Stack>

            {scheduleFlowType !== ScheduleFlowType.DEBRIEF && (
              <Stack alignItems="center" itemStyles={{ 0: { width: '116px' }, 1: { flexGrow: 1 } }}>
                <Box display="flex" alignItems="center">
                  <ScorecardIcon />
                  &nbsp;
                  <Label>Scorecard</Label>
                </Box>

                {atsJobId && (
                  <AtsScorecard
                    atsInterviewDefinitionId={atsInterviewDefinitionId}
                    atsJobId={atsJobId}
                    selectScorecard={handleAtsScorecardSelect}
                  />
                )}
              </Stack>
            )}

            {scheduleFlowType !== ScheduleFlowType.DEBRIEF && (
              <ConditionalThemeProvider>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Hide from candidate"
                  checked={isHiddenFromCandidate}
                  onChange={handleMarkAsHiddenFromCandidate}
                />
              </ConditionalThemeProvider>
            )}
          </Stack>
        </Box>

        <Divider />

        {interviewerCount === 0 && !addingNewSeat && (
          <Box px="12px">
            <ZeroState
              dataTestId="schedule-interview-event-details-zero-state"
              icon={<UserAddIcon color="mid-contrast-grey" fontSize={80} />}
              label="Add an interviewer to save this interview"
              actions={
                <Button
                  dataTestId="schedule-interview-event-details-zero-state-add-interviewer-button"
                  label="Add interviewer"
                  variant="link"
                  onClick={() => {
                    setAddingNewSeat(true);
                  }}
                />
              }
            />
          </Box>
        )}

        <Box className={classes.interviewerConfigSection}>
          <Stack direction="column" spacing={2} className={classes.interviewerContainer}>
            {updatedSchedule?.events &&
              updatedSchedule.events[0].filledInterviewSeats?.map((filledInterviewSeat) => {
                if (!updatedSchedule.events) return null;

                const seatId =
                  filledInterviewSeat.seat.moduleSeat?.id ||
                  filledInterviewSeat.seat.freeformSeat?.id ||
                  filledInterviewSeat.seat.linkedSeat?.id;
                const interviewSeat = interview?.jobStageInterviewSeats?.find((i) => i.id === seatId);

                const originalInterviewSeat = interviewSeat?.linkedSeat
                  ? originalInterviews
                      .map((i) => i.jobStageInterviewSeats || [])
                      .flat()
                      .find((s) => s.id === interviewSeat?.linkedSeat?.linkedJobStageInterviewSeatId)
                  : interviewSeat;

                const originalInterview = interviewSeat?.linkedSeat
                  ? originalInterviews.find((i) =>
                      i.jobStageInterviewSeats?.some(
                        (s) => s.id === interviewSeat?.linkedSeat?.linkedJobStageInterviewSeatId
                      )
                    )
                  : interview;

                return (
                  <UpdateInterviewSeat
                    key={
                      filledInterviewSeat.seat.moduleSeat?.id ||
                      filledInterviewSeat.seat.freeformSeat?.id ||
                      filledInterviewSeat.seat.linkedSeat?.id
                    }
                    interviewPlanId={interviewPlanId}
                    interviewEvent={interviewEvent}
                    filledInterviewSeat={filledInterviewSeat}
                    startAt={guessIsoTimestamp(interviewEvent.startAt)}
                    endAt={guessIsoTimestamp(interviewEvent.endAt)}
                    excludeInterviewerIds={excludeInterviewerIds}
                    scheduleFlowType={scheduleFlowType}
                    interview={interview}
                    interviewSeat={interviewSeat}
                    originalInterview={originalInterview}
                    originalInterviewSeat={originalInterviewSeat}
                    interviewers={updatedSchedule.events[0].interviewers || ([] as RichInterviewer[])}
                    onInterviewerRemoved={handleInterviewerRemoved}
                    onInterviewerModuleClear={handleInterviewerModuleClear}
                    onTraineeRemoved={handleTraineeRemoved}
                    onTraineeModuleClear={handleTraineeModuleClear}
                    onInterviewSeatAdded={handleInterviewSeatAdded}
                    onInterviewSeatUpdate={handleInterviewSeatUpdate}
                    onUseInterviewModule={handleUseInterviewModule}
                    onNewInterviewerDismiss={() => setAddingNewSeat(false)}
                  />
                );
              })}

            {addingNewSeat && (
              <UpdateInterviewSeat
                interviewPlanId={interviewPlanId}
                interviewEvent={interviewEvent}
                filledInterviewSeat={{
                  interviewerId: '',
                  seat: { freeformSeat: { id: '', interviewers: [] } },
                }}
                startAt={guessIsoTimestamp(interviewEvent.startAt)}
                endAt={guessIsoTimestamp(interviewEvent.endAt)}
                excludeInterviewerIds={excludeInterviewerIds}
                scheduleFlowType={scheduleFlowType}
                interview={undefined}
                interviewSeat={undefined}
                originalInterview={undefined}
                originalInterviewSeat={undefined}
                interviewers={[] as RichInterviewer[]}
                onInterviewerRemoved={handleInterviewerRemoved}
                onInterviewerModuleClear={handleInterviewerModuleClear}
                onTraineeRemoved={handleTraineeRemoved}
                onTraineeModuleClear={handleTraineeModuleClear}
                onInterviewSeatAdded={handleInterviewSeatAdded}
                onInterviewSeatUpdate={handleInterviewSeatUpdate}
                onUseInterviewModule={handleUseInterviewModule}
                onNewInterviewerDismiss={() => setAddingNewSeat(false)}
              />
            )}
          </Stack>
          <Stack alignItems="center" justifyContent="space-between">
            {!interviewerCount && <Box />}
            {Boolean(interviewerCount) && (
              <Button
                dataTestId="schedule-interview-event-details-add-interviewer"
                variant="outlined"
                size="small"
                label="Add interviewer"
                disabled={addingNewSeat}
                startIcon={<UserAddIcon />}
                onClick={() => {
                  setAddingNewSeat(true);
                }}
              />
            )}
            <Button
              dataTestId="schedule-interview-event-details-delete-interview"
              variant="outlined"
              size="small"
              color="error"
              disabled={isLastInterview}
              label="Delete interview"
              startIcon={<TrashIcon color={!isLastInterview ? 'error' : 'mid-contrast-grey'} />}
              onClick={handleDeleteInterview}
              tooltip={lastInterviewDeleteDisableTooltip}
            />
          </Stack>
        </Box>
      </DialogContent>
    </DialogContainer>
  );
};

InterviewDetails.fragments = {
  interview: gql`
    ${UpdateInterviewSeat.fragments.interview}
    ${UpdateInterviewSeat.fragments.interviewSeat}
    fragment InterviewDetails_interview on JobStageInterview {
      id
      atsInterviewDefinition {
        atsId
      }
      jobStageInterviewSeats {
        id
        ...UpdateInterviewSeat_interviewSeat
      }
      ...UpdateInterviewSeat_interview
    }
  `,
  originalInterviews: gql`
    ${UpdateInterviewSeat.fragments.originalInterview}
    fragment InterviewDetails_originalInterviews on JobStageInterview {
      id
      jobStageInterviewSeats {
        id
        ...UpdateInterviewSeat_originalInterviewSeat
      }
      ...UpdateInterviewSeat_originalInterview
    }
  `,
};

export default InterviewDetails;
