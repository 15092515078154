import { SvgIcon, SvgIconProps } from './SvgIcon';

export const RsvpGoingLeftIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62469 18.7002C2.96993 19.2241 2 18.7579 2 17.9194V5C2 3.34315 3.34315 2 5 2H15C16.6569 2 18 3.34315 18 5V13C18 14.6569 16.6569 16 15 16H8.05234C7.37113 16 6.7102 16.2318 6.17826 16.6574L3.62469 18.7002ZM13.8106 7.49827C14.0857 7.18869 14.0579 6.71463 13.7483 6.43944C13.4387 6.16425 12.9646 6.19214 12.6894 6.50173L9.21789 10.4072L7.28033 8.46967C6.98744 8.17678 6.51256 8.17678 6.21967 8.46967C5.92678 8.76256 5.92678 9.23744 6.21967 9.53033L8.71967 12.0303C8.86573 12.1764 9.06556 12.2557 9.27203 12.2497C9.4785 12.2436 9.67333 12.1527 9.81056 11.9983L13.8106 7.49827Z"
        fill="#00A39E"
      />
    </SvgIcon>
  );
};
