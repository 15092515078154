import React, { FC } from 'react';

import { DialogContent as MuiDialogContent } from '@mui/material';

import Label from 'src/components/label';

const DialogContent: FC = ({ children }) => {
  return (
    <MuiDialogContent sx={{ padding: '20px 20px', flexGrow: 0 }}>
      {typeof children === 'string' && <Label variant="body">{children}</Label>}
      {typeof children !== 'string' && children}
    </MuiDialogContent>
  );
};

export default DialogContent;
