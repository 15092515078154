import React from 'react';

import { gql } from '@apollo/client';

import { InterviewLog_InterviewLogFragment, InterviewLog_ModuleMemberFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import InterviewLogInterview from './InterviewLogInterview';
import InterviewLogManual from './InterviewLogManual';

type Fragments = {
  interviewLog: InterviewLog_InterviewLogFragment | undefined;
  moduleMember: InterviewLog_ModuleMemberFragment;
};

type Props = {
  showNagButton?: boolean;
};

const InterviewLog: FCWithFragments<Fragments, Props> = ({ interviewLog, moduleMember, showNagButton }) => {
  if (!interviewLog) {
    return null;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (interviewLog.__typename === 'ModuleMemberManualLog' || !interviewLog.applicationStageInterview) {
    return <InterviewLogManual />;
  }

  return (
    <InterviewLogInterview
      interview={interviewLog.applicationStageInterview}
      role={interviewLog.trainingRole}
      showNagButton={showNagButton}
      moduleMember={moduleMember}
    />
  );
};

InterviewLog.fragments = {
  moduleMember: gql`
    ${InterviewLogInterview.fragments.moduleMember}
    fragment InterviewLog_moduleMember on InterviewModuleMember {
      id
      employeeId
      interviewModuleId
      ...InterviewLogInterview_moduleMember
    }
  `,
  interviewLog: gql`
    ${InterviewLogInterview.fragments.interview}
    fragment InterviewLog_interviewLog on ModuleMemberInterview {
      id
      trainingRole
      ... on ModuleMemberApplicationStageInterviewLog {
        applicationStageInterview {
          ...InterviewLogInterview_interview
        }
        __typename
      }
      ... on ModuleMemberManualLog {
        id
        __typename
      }
      __typename
    }
  `,
};

export default InterviewLog;
