import React from 'react';

import BaseIcon, { Props } from './Icon';

const DayIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.25C7.41422 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48121 17.75 5.99999L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H6.25V2C6.25 1.58579 6.58579 1.25 7 1.25ZM6.25 5V4.75H5C4.30964 4.75 3.75 5.30964 3.75 6L3.75001 15C3.75001 15.6904 4.30966 16.25 5.00001 16.25H15C15.6904 16.25 16.25 15.6904 16.25 15L16.25 6C16.25 5.30964 15.6903 4.75 15 4.75H13.75V5C13.75 5.41421 13.4142 5.75 13 5.75C12.5858 5.75 12.25 5.41421 12.25 5V4.75H7.75V5C7.75 5.41421 7.41422 5.75 7 5.75C6.58579 5.75 6.25 5.41421 6.25 5ZM8 7.75C7.58579 7.75 7.25 8.08579 7.25 8.5V13.5C7.25 13.6989 7.32902 13.8897 7.46967 14.0303C7.61032 14.171 7.80109 14.25 8 14.25H9.5C10.6606 14.25 11.6035 13.8841 12.263 13.2659C12.9186 12.6513 13.25 11.8262 13.25 11C13.25 10.1738 12.9186 9.34874 12.263 8.7341C11.6035 8.11587 10.6606 7.75 9.5 7.75H8ZM8.75 12.75V9.25H9.5C10.3394 9.25 10.8965 9.50913 11.237 9.8284C11.5814 10.1513 11.75 10.5762 11.75 11C11.75 11.4238 11.5814 11.8487 11.237 12.1716C10.8965 12.4909 10.3394 12.75 9.5 12.75H8.75Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DayIcon;
