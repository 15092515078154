import React from 'react';

import BaseIcon, { Props } from './Icon';

const AverageIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.65867 9.96822C7.1036 9.94687 6.42096 10.1397 5.53033 11.0303C5.23744 11.3232 4.76256 11.3232 4.46967 11.0303C4.17678 10.7374 4.17678 10.2626 4.46967 9.96968C5.57904 8.86032 6.6464 8.42817 7.71633 8.46932C8.6923 8.50686 9.56809 8.94518 10.2775 9.30022C10.2969 9.30995 10.3162 9.31961 10.3354 9.3292C11.116 9.71948 11.719 10.0079 12.3413 10.0318C12.8964 10.0532 13.579 9.86031 14.4697 8.96967C14.7626 8.67678 15.2374 8.67677 15.5303 8.96967C15.8232 9.26256 15.8232 9.73743 15.5303 10.0303C14.421 11.1397 13.3536 11.5719 12.2837 11.5307C11.3077 11.4932 10.4319 11.0549 9.72249 10.6998C9.70307 10.6901 9.68377 10.6804 9.66459 10.6708C8.88401 10.2806 8.28097 9.99215 7.65867 9.96822Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default AverageIcon;
