import ApiVerb from './ApiVerb';
import { apiPost } from './index';
import { ZoomInfo } from './types';

interface CandidateEventContent {
  toEmailAddress: string;
  summary: string;
  description: string;
  videoMeetingUrl?: string;
  calendarEventTemplateId?: string;
}

interface CandidateEmailContent {
  toEmailAddress: string;
  ccEmployeeIds?: string[];
  bccEmployeeIds?: string[];
  ccExternalAddresses?: string[];
  bccExternalAddresses?: string[];
  description: string;
  summary: string;
  attachments?: { name: string; path: string }[];
  emailTemplateId?: string;
}

export interface SlackChannelContent {
  slackChannelInterviewerEmployeeIds?: string[];
  slackChannelRecruitingTeamEmployeeIds?: string[];
  slackChannelHiringManagerEmployeeIds?: string[];
  slackChannelMessageContent: string;

  // Only one of these can be set

  // New slack channel name
  slackChannelName?: string;
  // Id of a slack channel in our DB
  conversationId?: string;
  // Id of a slack channel in slack
  slackChannelId?: string;
}

export interface Interviewer {
  interviewerId: string;
  role: string;
  interviewId: string;
  jobStageInterviewSeatId: string;
  isOptional: boolean;
}

export interface InterviewerContent {
  interviewer: Interviewer[];
  videoMeetingUrl: string;
  startAt: string;
  endAt: string;
  summary: string;
  description: string;
  codingInterviewUrl?: string;
  interviewName: string;
  atsInterviewDefinitionId?: string;
  emailTemplateId?: string;
  zoomInfo?: ZoomInfo;
  jobStageInterviewId?: string;
  meetingRoomIds?: string[];
  isHiddenFromCandidate?: boolean;
  videoMeetingHostEmployeeId?: string;
}

export interface RequestBody {
  applicationId: string;
  interviewerCalendarId?: string;
  candidateCalendarId?: string;
  candidateTimezone: string;
  candidateEmailContent?: CandidateEmailContent;
  candidateEventContent?: CandidateEventContent;
  slackChannelContent?: SlackChannelContent;
  interviewerContents: InterviewerContent[];
  isPrivateCalendarEvent: boolean;
  isReschedule?: boolean;
  applicationStageId?: string;
  taskId?: string;
}

export type DebriefRescheduleDetails = {
  has_schedule_time_after_debrief_start_at: boolean;
  has_attendee_added: boolean;
};

interface ResponseBody {
  application_stage_id: string;
  debrief_reschedule_details?: DebriefRescheduleDetails;
}

const createSchedule = async (data: RequestBody): Promise<ResponseBody> => {
  const response = await apiPost(ApiVerb.CREATE_SCHEDULE, data, {
    'axios-retry': {
      retries: 0,
    },
  });
  return response.data;
};

export default createSchedule;
