import React, { FC } from 'react';

import * as Sentry from '@sentry/react';

import logMessage from 'src/utils/logMessage';

const SilentErrorBoundary: FC = ({ children }) => (
  <Sentry.ErrorBoundary
    onError={(error) => {
      logMessage('Silent error boundary triggered', 'fatal', { error });
    }}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default SilentErrorBoundary;
