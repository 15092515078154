import { ApolloError } from '@apollo/client';

import { JobStageSettingsUpsertInput, useJobStageCommsSettingsUpsertMutation } from 'src/generated/mloop-graphql';

import useGenericOnError from 'src/hooks/useGenericOnError';
import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';

import logError from 'src/utils/logError';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { useSelector } from 'src/store';

/**
 * Grab candidate and slack communication templates and save them to
 * job stage settings
 */
export default function useSaveSettingsToJobStageFromStore() {
  const genericOnError = useGenericOnError();
  const { logEvent } = useLogEvent();
  const {
    candidateCommunicationsEnabled,
    candidateEventCommunicationsEnabled,
    candidateTemplateID,
    candidateCalendarTemplateID,
    selectedScheduleId,
  } = useSelector((state) => state.scheduling);

  const scheduleFlowData = useScheduleFlowData();

  const { jobStageId } = scheduleFlowData;

  const { slackChannelEnabled, slackChannelMessageTemplateID } = useSelector(
    (state) => state.scheduleCommunications.byId[selectedScheduleId || ''] || {}
  );

  let input: JobStageSettingsUpsertInput = { jobStageId };

  // Add candidate email template
  if (candidateCommunicationsEnabled) {
    input = {
      ...input,
      candidateEmailTemplateId: candidateTemplateID,
    };
  }

  // Add candidate event template
  if (candidateEventCommunicationsEnabled) {
    input = {
      ...input,
      candidateEventTemplateId: candidateCalendarTemplateID,
    };
  }

  // Grab slack template if slack is enabled
  if (slackChannelEnabled && slackChannelMessageTemplateID) {
    input = {
      ...input,
      slackTemplateId: slackChannelMessageTemplateID,
    };
  }

  return useJobStageCommsSettingsUpsertMutation({
    variables: {
      input,
    },
    onCompleted: () => {
      logEvent(LoggerEvent.CLIENT_SAVE_BACK_TO_JOB_STAGE_SETTINGS, { jobStageId });
    },
    onError: (error: ApolloError) => {
      logError(error);
      genericOnError(error);
    },
  });
}
