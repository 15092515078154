import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import { endOfDay, isPast } from 'date-fns';

import { Theme } from 'src/theme/type';

import Label from '../label';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type CalendarDateDisplayProps = {
  day: MaterialUiPickersDate;
  selectedDate: MaterialUiPickersDate;
  dayComponent: JSX.Element;
  utcAvailability?: number[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendarButton: {
      '& button': {
        height: '32px',
        width: '32px',
        margin: '2px 4px',
      },
    },
    calendarButtonBorder: {
      '& button': {
        border: `1px solid ${theme.palette.border}`,
        borderRadius: '50%',
      },
    },
    calendarAvailabilityDay: {
      '& button': {
        border: `1px solid ${theme.palette.info.main}`,
        color: 'inherit',
      },
    },
  })
);

const CalendarDateDisplay = ({ day, dayComponent, selectedDate, utcAvailability }: CalendarDateDisplayProps) => {
  /**
   * Display component used in the MUI <Calendar/> component to render custom display of days on the calendar
   *
   * @remarks
   * This is used for the `renderDay` prop of the MUI <Calendar/> component.
   *
   * @returns JSX element
   *
   */

  const classes = useStyles();
  const showCalendarButtonBorder = day && !isPast(endOfDay(day.toDate()));
  const dayJsx = (
    <Label className={clsx(classes.calendarButton, { [classes.calendarButtonBorder]: showCalendarButtonBorder })}>
      {dayComponent}
    </Label>
  );
  if (!day || !selectedDate || !utcAvailability) return dayJsx;

  const dayMs = day.toDate().getTime();
  if (utcAvailability.includes(dayMs) && selectedDate.toDate().getTime() !== dayMs) {
    return (
      <Label
        color="info"
        className={clsx(classes.calendarButton, classes.calendarAvailabilityDay, {
          [classes.calendarButtonBorder]: showCalendarButtonBorder,
        })}
      >
        {dayComponent}
      </Label>
    );
  }

  return dayJsx;
};

export default CalendarDateDisplay;
