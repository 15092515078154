/**
 * TODO ENG-9217 @Ashutosh use it directly from mloop-shared once candidate portal works fine as candidate portal is using shared version
 * Not doing it right now as unit test is faling for some reason, will debug once candidate portal is working fine
 */
import { AxiosInstance } from 'axios';
import axiosRetryLib, { IAxiosRetryConfig } from 'axios-retry';

import logMessage from 'src/utils/logMessage';

declare module 'axios' {
  export interface AxiosRequestConfig {
    'axios-retry'?: IAxiosRetryConfig;
  }
}

/**
 * Common code that allows
 * @param error AxiosError that was recieved after the axios request was made
 * @returns boolean if its a Network Error
 */
const axiosRetry = (axiosInstance: AxiosInstance, numRetries: number): void =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  axiosRetryLib(axiosInstance, {
    retries: numRetries,
    retryDelay: (retryCount, error): number => {
      const msg = `retry attempt: ${retryCount} for ${(error?.request as XMLHttpRequest)?.responseURL}`;
      logMessage(msg, numRetries === retryCount ? 'fatal' : 'warning', {
        error,
      });
      return retryCount * 1000; // backoff
    },
    retryCondition: (error): boolean => {
      if (!error?.response?.status) {
        return true;
      }
      return error.response.status >= 500;
    },
  });

export default axiosRetry;
