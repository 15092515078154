import { gql } from '@apollo/client';
import { sortBy } from 'lodash';

import { AtsInterviewDefinitionFragment, useAtsInterviewDefinitionsQuery } from 'src/generated/mloop-graphql';

import { useOrgAtsService } from 'src/hooks/atsService';
import { getAtsInterviewDefinitionFields } from 'src/hooks/atsService/util';

export const AtsInterviewDefinitionsFragment = gql`
  fragment AtsInterviewDefinition on AtsInterviewDefinition {
    name
    atsId
    atsJobStageId
    atsJobId
    atsJobStage {
      atsId
      name
      index
    }
    atsFields {
      ... on GreenhouseInterviewDefinition {
        estimatedMinutes
        index
      }
      ... on LeverInterviewDefinition {
        groupId
        groupName
      }
    }
  }
`;

export const AtsInterviewDefinitionsQuery = gql`
  ${AtsInterviewDefinitionsFragment}
  query AtsInterviewDefinitions($input: AtsInterviewDefinitionsInput!) {
    atsInterviewDefinitions(input: $input) {
      atsInterviewDefinitions {
        ...AtsInterviewDefinition
      }
    }
  }
`;

const useAtsScorecardOptions = (atsJobId?: string) => {
  const atsService = useOrgAtsService();

  const { data, loading, error } = useAtsInterviewDefinitionsQuery({
    skip: !atsJobId,
    variables: {
      input: {
        atsJobId: atsJobId || '',
        schedulable: true,
      },
    },
  });

  let scorecards: AtsInterviewDefinitionFragment[] = [];

  const noScorecard = {
    atsId: '',
    name: 'No scorecard',
  } as AtsInterviewDefinitionFragment;

  const sortByBy = atsService?.scorecardSortKey;

  if (sortByBy) {
    scorecards = sortBy(data?.atsInterviewDefinitions?.atsInterviewDefinitions || [], (value) =>
      sortByBy !== 'index'
        ? getAtsInterviewDefinitionFields(value.atsFields, sortByBy) || null
        : `${value.atsJobStage?.index || 0}-${getAtsInterviewDefinitionFields(value.atsFields, sortByBy) || 0}` || null
    );
  } else {
    scorecards = sortBy(
      data?.atsInterviewDefinitions?.atsInterviewDefinitions || [],
      (value) => value.name || null
    ).filter((template) => template.name);
  }

  return { loading, error, options: [noScorecard, ...scorecards] };
};

export default useAtsScorecardOptions;
