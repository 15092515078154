import { accordion } from './accordion';
import { alert } from './alert';
import { autocomplete } from './autocomplete';
import { avatar } from './avatar';
import { avatarGroup } from './avatarGroup';
import { bottomNavigation } from './bottomNavigation';
import { button } from './button';
import { card } from './card';
import { checkbox } from './checkbox';
import { chip } from './chip';
import { container } from './container';
import { dialog, dialogActions, dialogContent } from './dialog';
import { formControl } from './formcontrol';
import { grid } from './grid';
import { icon } from './icon';
import { iconButton } from './iconButton';
import { input } from './input';
import { link } from './link';
import { list } from './list';
import { menu } from './menu';
import { pagination } from './pagination';
import { paper } from './paper';
import { radio } from './radio';
import { select } from './select';
import { Switch } from './switch';
import { table } from './table';
import { tabs } from './tabs';
import { textfield } from './textfield';
import { tooltip } from './tooltip';
import { typography } from './typography';
import { ThemeOptions } from '@mui/material';

export const components: ThemeOptions['components'] = {
  ...accordion,
  ...alert,
  ...autocomplete,
  ...avatar,
  ...avatarGroup,
  ...bottomNavigation,
  ...button,
  ...card,
  ...checkbox,
  ...chip,
  ...container,
  ...container,
  ...dialog,
  ...dialogActions,
  ...dialogContent,
  ...formControl,
  ...grid,
  ...icon,
  ...iconButton,
  ...input,
  ...link,
  ...list,
  ...menu,
  ...pagination,
  ...paper,
  ...radio,
  ...select,
  ...Switch,
  ...table,
  ...tabs,
  ...textfield,
  ...tooltip,
  ...typography,
};
