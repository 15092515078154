import { ThemeOptions } from '@mui/material';

/**
 * View Menu props and classes at https://mui.com/material-ui/api/menu/#css
 */
export const menu: ThemeOptions['components'] = {
  MuiMenu: {
    defaultProps: {
      PaperProps: {
        elevation: 2,
      },
    },
    styleOverrides: {
      root: () => ({}),
    },
  },
  MuiList: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1),
      }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: 'unset',
        borderRadius: theme.spacing(0.75),
        padding: theme.spacing(0.875, 1.5),
        gap: '8px',
        '& .MuiListItemIcon-root': {
          minWidth: '20px',
        },
      }),
    },
  },
};
