import React, { useMemo } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import { AutocompleteGetTagProps as MuiAutocompleteGetTagProps, Chip as MuiChip, SxProps } from '@mui/material';
import clsx from 'clsx';

import CrossIcon from 'src/components/icons/Cross';
import { BaseProps, createSxProps } from 'src/components/types';

import { Theme } from 'src/themeMui5/type';

import { BODY_LINE_HEIGHT, BODY_SIZE, BODY_WEIGHT } from '../constants/Typography';
import MaybeTooltip from '../tooltip/MaybeTooltip';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ChipProps = BaseProps & {
  label: string | React.ReactNode;
  variant: 'default' | 'outlined';
  avatar?: React.ReactElement;
  className?: string;
  sx?: SxProps<Theme>;
  tagProps?: ReturnType<AutocompleteGetTagProps>;
};

const useSxProps = () => {
  const sxProps = useMemo(() => {
    return {
      root: {
        padding: '1px 2px 1px 4px',
        borderRadius: '6px',
        cursor: 'unset',
        margin: '4px',
        height: '24px',
        maxWidth: '100%',

        '& .MuiChip-avatar': {
          width: '20px',
          height: '20px',
          borderRadius: '6px',
          marginLeft: '0',
          marginRight: '0',
        },

        '&:focus': {
          backgroundColor: (theme: Theme) => theme.palette.background.info,
          border: (theme: Theme) => `1px solid ${theme.palette.action.focus}`,
          boxShadow: (theme: Theme) => `0 0 0 1px ${theme.palette.action.focus}`,
          '& div': {
            backgroundColor: (theme: Theme) => `${theme.palette.primary.dark}`,
            '& svg': {
              color: (theme: Theme) => theme.palette.background.info,
            },
          },
        },

        '& .MuiChip-label': {
          padding: '0 4px',
          fontSize: BODY_SIZE,
          lineHeight: BODY_LINE_HEIGHT,
          fontWeight: BODY_WEIGHT,
          maxWidth: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },

        '&.MuiChip-filled': {
          backgroundColor: (theme: Theme) => theme.palette.action.hover,
          border: (theme: Theme) => `1px solid ${theme.palette.common.transparent}`,
        },

        '&.MuiChip-outlined': {
          backgroundColor: (theme: Theme) => theme.palette.background.default,
          border: (theme: Theme) => `1px solid ${theme.palette.border}`,
        },

        '& .MuiChip-deleteIcon': {
          display: 'flex',
          borderRadius: '4px',
          margin: '0',
          padding: '0',
          width: '20px',
          height: '20px',
        },
      } as SxProps,
    };
  }, []);

  return createSxProps(sxProps);
};

/**
 * @deprecated - Use `Chip` from `@mui/material` instead
 */
const Chip: React.FC<ChipProps> = ({ className, sx, label, avatar, variant, tagProps, dataTestId }) => {
  const sxProps = useSxProps();

  // Adding className because it is not in the types but is part of tagProps
  const { onDelete, className: tagPropsClassName } = (tagProps as ReturnType<MuiAutocompleteGetTagProps> & {
    className?: string;
  }) ?? {
    onDelete: () => {},
  };

  const deleteIcon = onDelete ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={onDelete} data-testid="delete-icon">
      <CrossIcon fontSize={20} />
    </div>
  ) : undefined;

  const sxRootProps = useMemo(() => ({ ...sxProps.root, ...(sx || {}) }), [sx, sxProps.root]);

  return (
    <MuiChip
      sx={sxRootProps}
      color="default"
      label={typeof label === 'string' ? <MaybeTooltip label={label} tooltip={label} /> : label}
      variant={variant === 'default' ? 'filled' : 'outlined'}
      avatar={avatar}
      deleteIcon={deleteIcon}
      {...tagProps}
      className={clsx(className, tagPropsClassName)}
      data-testid={dataTestId}
    />
  );
};

export default Chip;
