import React, { useCallback } from 'react';

import { gql } from '@apollo/client';
import { Box, List, ListItem, useTheme } from '@mui/material';

import { ActivityLogsList_ActivityLogFragment, ActivityType } from 'src/generated/mloop-graphql';

import Link from 'src/components/Link';
import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';

import ActivityLog from 'src/entities/ActivityLog';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface ActivityLogsListProps {
  onViewMoreClick: () => void;
  activityTypes?: ActivityType[];
  showViewMoreButton?: boolean;
}
interface Fragment {
  activityLogs: ActivityLogsList_ActivityLogFragment[];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const ActivityLogsList: FCWithFragments<Fragment, ActivityLogsListProps> = ({
  activityLogs,
  onViewMoreClick,
  showViewMoreButton,
}) => {
  const theme = useTheme();

  const renderItem = useCallback(
    (option, index: number) => {
      return (
        <ListItem key={option?.id ?? index} disableGutters disablePadding={false}>
          <ActivityLog activityLog={option} />

          {/* This box renders the vertical line between activity logs */}
          {index < activityLogs.length - 1 ? (
            <Box
              height="calc(100% - 28px)"
              border={`1px solid ${theme.grey.solid.low}`}
              position="absolute"
              marginLeft="9px"
              marginTop="35px"
              borderRadius="1px"
            />
          ) : null}
        </ListItem>
      );
    },
    [activityLogs.length, theme.grey.solid.low]
  );

  return activityLogs.length ? (
    <List>
      {activityLogs.map((activityLog, index) => renderItem(activityLog, index))}
      {showViewMoreButton ? (
        <ListItem disablePadding disableGutters>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link component="button" href="" onClick={onViewMoreClick} color="info">
            Show more
          </Link>
        </ListItem>
      ) : null}
    </List>
  ) : (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Label color="high-contrast-grey">There is no activity yet</Label>
    </Box>
  );
};

ActivityLogsList.fragments = {
  activityLogs: gql`
    ${ActivityLog.fragments.activityLog}
    fragment ActivityLogsList_activityLog on ActivityLog {
      id
      ...ActivityLog_activityLog
    }
  `,
};

export default ActivityLogsList;
