import { SvgIcon, SvgIconProps } from './SvgIcon';

export const UsersMultipleIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5V6C7 6.82843 6.32843 7.5 5.5 7.5C4.67157 7.5 4 6.82843 4 6V5C4 4.17157 4.67157 3.5 5.5 3.5C6.32843 3.5 7 4.17157 7 5ZM2.5 5C2.5 3.34315 3.84315 2 5.5 2C7.15685 2 8.5 3.34315 8.5 5V6C8.5 7.65685 7.15685 9 5.5 9C3.84315 9 2.5 7.65685 2.5 6V5ZM2.84011 12.1868C4.6266 11.547 6.93211 11.6132 8.60176 12.3646C8.61175 12.3691 8.61715 12.3727 8.6197 12.3748C8.62045 12.377 8.6216 12.3817 8.62204 12.3894C8.62401 12.4244 8.6076 12.4958 8.53431 12.5582C7.82884 13.1592 7.27491 13.7079 6.69348 14.5279C6.62566 14.6236 6.53172 14.6648 6.45398 14.6648H3C2.86193 14.6648 2.75 14.5528 2.75 14.4148V12.3577C2.75 12.3058 2.76512 12.2628 2.78407 12.2338C2.80172 12.2069 2.82099 12.1937 2.84011 12.1868ZM8.62162 12.3766L8.62061 12.3755C8.62137 12.3762 8.62166 12.3766 8.62162 12.3766ZM9.21737 10.9968C7.17566 10.0779 4.46718 10.0108 2.33435 10.7746C1.63345 11.0257 1.25 11.6951 1.25 12.3577V14.4148C1.25 15.3813 2.0335 16.1648 3 16.1648H6.45398C7.05684 16.1648 7.59128 15.8551 7.91712 15.3955C8.40586 14.7062 8.86438 14.2476 9.50708 13.7C9.92601 13.3431 10.1489 12.823 10.1197 12.3049C10.0894 11.769 9.78521 11.2523 9.21737 10.9968ZM16.5 9V8C16.5 7.17157 15.8284 6.5 15 6.5C14.1716 6.5 13.5 7.17157 13.5 8V9C13.5 9.82843 14.1716 10.5 15 10.5C15.8284 10.5 16.5 9.82843 16.5 9ZM15 5C13.3431 5 12 6.34315 12 8V9C12 10.6569 13.3431 12 15 12C16.6569 12 18 10.6569 18 9V8C18 6.34315 16.6569 5 15 5ZM10.5 16.75C10.4698 16.75 10.4417 16.7441 10.4182 16.7349C10.7565 16.0108 11.627 15.3797 12.9032 15.0263C14.1971 14.6681 15.7523 14.6455 17.1421 15.024C17.1664 15.0307 17.1896 15.0452 17.2104 15.0743C17.2327 15.1055 17.25 15.1517 17.25 15.2069V16.5C17.25 16.6381 17.1381 16.75 17 16.75L10.5 16.75ZM17.5363 13.5768C15.882 13.1262 14.0523 13.1517 12.5029 13.5807C10.9799 14.0025 9.56437 14.8662 8.99825 16.2386C8.77136 16.7886 8.92137 17.3318 9.24816 17.7009C9.55818 18.0511 10.0219 18.25 10.5 18.25L17 18.25C17.9665 18.25 18.75 17.4665 18.75 16.5V15.2069C18.75 14.4922 18.3035 13.7857 17.5363 13.5768Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
