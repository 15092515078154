import { gql } from '@apollo/client';

import { useOrgNameQuery } from 'src/generated/mloop-graphql';

export const OrgNameQuery = gql`
  query OrgName {
    thisOrg {
      id
      name
    }
  }
`;

const useOrgName = (skip?: boolean) => {
  const { data, ...rest } = useOrgNameQuery({
    skip,
  });
  return { orgName: data?.thisOrg?.name, ...rest };
};

export default useOrgName;
