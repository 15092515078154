import { SvgIcon, SvgIconProps } from './SvgIcon';

export const Move = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5303 2.46967C10.3897 2.32902 10.1989 2.25 10 2.25C9.80109 2.25 9.61032 2.32902 9.46967 2.46967L6.96967 4.96967C6.67678 5.26256 6.67678 5.73744 6.96967 6.03033C7.26256 6.32322 7.73744 6.32322 8.03033 6.03033L9.25 4.81066V7.5C9.25 7.91421 9.58578 8.25 10 8.25C10.4142 8.25 10.75 7.91421 10.75 7.5V4.81066L11.9697 6.03033C12.2626 6.32322 12.7374 6.32322 13.0303 6.03033C13.3232 5.73744 13.3232 5.26256 13.0303 4.96967L10.5303 2.46967ZM17.5303 10.5303C17.671 10.3897 17.75 10.1989 17.75 10C17.75 9.80109 17.671 9.61032 17.5303 9.46967L15.0303 6.96967C14.7374 6.67678 14.2626 6.67678 13.9697 6.96967C13.6768 7.26256 13.6768 7.73744 13.9697 8.03033L15.1893 9.25H12.5C12.0858 9.25 11.75 9.58578 11.75 10C11.75 10.4142 12.0858 10.75 12.5 10.75H15.1893L13.9697 11.9697C13.6768 12.2626 13.6768 12.7374 13.9697 13.0303C14.2626 13.3232 14.7374 13.3232 15.0303 13.0303L17.5303 10.5303ZM2.25 10C2.25 9.80109 2.32902 9.61032 2.46967 9.46967L4.96967 6.96967C5.26256 6.67678 5.73744 6.67678 6.03033 6.96967C6.32322 7.26256 6.32322 7.73744 6.03033 8.03033L4.81066 9.25H7.5C7.91421 9.25 8.25 9.58579 8.25 10C8.25 10.4142 7.91421 10.75 7.5 10.75H4.81066L6.03033 11.9697C6.32322 12.2626 6.32322 12.7374 6.03033 13.0303C5.73744 13.3232 5.26256 13.3232 4.96967 13.0303L2.46967 10.5303C2.32902 10.3897 2.25 10.1989 2.25 10ZM9.46967 17.5303C9.61032 17.671 9.80109 17.75 10 17.75C10.1989 17.75 10.3897 17.671 10.5303 17.5303L13.0303 15.0303C13.3232 14.7374 13.3232 14.2626 13.0303 13.9697C12.7374 13.6768 12.2626 13.6768 11.9697 13.9697L10.75 15.1893V12.5C10.75 12.0858 10.4142 11.75 10 11.75C9.58579 11.75 9.25 12.0858 9.25 12.5L9.25 15.1893L8.03033 13.9697C7.73744 13.6768 7.26256 13.6768 6.96967 13.9697C6.67678 14.2626 6.67678 14.7374 6.96967 15.0303L9.46967 17.5303Z"
        fill="currentColor"
        fillOpacity="0.71"
      />
    </SvgIcon>
  );
};
