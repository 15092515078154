import React from 'react';

import BaseIcon, { Props } from './Icon';

const MoveIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.75C3.58579 4.75 3.25 4.41421 3.25 4C3.25 3.58579 3.58579 3.25 4 3.25H16C16.4142 3.25 16.75 3.58579 16.75 4C16.75 4.41421 16.4142 4.75 16 4.75H4ZM4 11.25C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H7C7.41421 12.75 7.75 12.4142 7.75 12C7.75 11.5858 7.41421 11.25 7 11.25H4ZM3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H10.5C10.9142 8.75 11.25 8.41421 11.25 8C11.25 7.58579 10.9142 7.25 10.5 7.25H4C3.58579 7.25 3.25 7.58579 3.25 8ZM14.5303 8.46967C14.2374 8.17678 13.7626 8.17678 13.4697 8.46967C13.1768 8.76256 13.1768 9.23744 13.4697 9.53033L15.1893 11.25H12.7361C11.0816 11.25 9.56909 12.1848 8.82918 13.6646C8.34336 14.6362 7.35026 15.25 6.26393 15.25H4C3.58579 15.25 3.25 15.5858 3.25 16C3.25 16.4142 3.58579 16.75 4 16.75H6.26393C7.91842 16.75 9.43091 15.8152 10.1708 14.3354C10.6566 13.3638 11.6497 12.75 12.7361 12.75H15.1893L13.4697 14.4697C13.1768 14.7626 13.1768 15.2374 13.4697 15.5303C13.7626 15.8232 14.2374 15.8232 14.5303 15.5303L17.5303 12.5303C17.8232 12.2374 17.8232 11.7626 17.5303 11.4697L14.5303 8.46967Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MoveIcon;
