import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CaretDownIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.41438 8.03151C4.67313 7.70806 5.1451 7.65562 5.46855 7.91438L10 11.5396L14.5315 7.91438C14.855 7.65562 15.3269 7.70806 15.5857 8.03151C15.8444 8.35495 15.792 8.82692 15.4685 9.08568L10.4685 13.0857C10.1946 13.3048 9.80542 13.3048 9.53151 13.0857L4.53151 9.08568C4.20806 8.82692 4.15562 8.35495 4.41438 8.03151Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
