import React from 'react';

import BaseIcon, { Props } from './Icon';

const ScorecardFilledIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 4.5C8.25 3.5335 9.0335 2.75 10 2.75C10.9665 2.75 11.75 3.5335 11.75 4.5H11.75C11.75 4.91421 12.0858 5.25 12.5 5.25H14C14.6904 5.25 15.25 5.80964 15.25 6.5V7H4.75003V6.5C4.75003 5.80964 5.30967 5.25 6.00003 5.25H7.5C7.91421 5.25 8.25 4.91421 8.25 4.5ZM4 16.8874C3.53506 16.3949 3.25003 15.7308 3.25003 15V6.5C3.25003 4.98122 4.48125 3.75 6.00003 3.75H6.83697C7.17555 2.31665 8.46321 1.25 10 1.25C11.5368 1.25 12.8245 2.31665 13.163 3.75H14C15.5188 3.75 16.75 4.98122 16.75 6.5V15C16.75 15.7308 16.465 16.395 16 16.8875V17H15.8875C15.395 17.465 14.7308 17.75 14 17.75H6.00003C5.26926 17.75 4.60507 17.465 4.11257 17H4V16.8874ZM11 4.5C11 5.05229 10.5523 5.5 10 5.5C9.44772 5.5 9 5.05229 9 4.5C9 3.94772 9.44772 3.5 10 3.5C10.5523 3.5 11 3.94772 11 4.5ZM6.25 10C6.25 9.58579 6.58579 9.25 7 9.25H13C13.4142 9.25 13.75 9.58579 13.75 10C13.75 10.4142 13.4142 10.75 13 10.75H7C6.58579 10.75 6.25 10.4142 6.25 10ZM7 12.25C6.58579 12.25 6.25 12.5858 6.25 13C6.25 13.4142 6.58579 13.75 7 13.75H9C9.41421 13.75 9.75 13.4142 9.75 13C9.75 12.5858 9.41421 12.25 9 12.25H7Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ScorecardFilledIcon;
