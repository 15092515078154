import React from 'react';

import { MenuItem, Select } from '@mui/material';

import { MeetingLinksConfig } from 'src/store/slices/schedule-communications';

type Props = {
  meetingLinkConfig: MeetingLinksConfig;
  onChange: (meetingLinkConfig: MeetingLinksConfig) => void;
};

const LocationConfigMenu = ({ meetingLinkConfig, onChange }: Props) => {
  return (
    <Select
      fullWidth
      value={meetingLinkConfig}
      onChange={(event) => onChange(event.target.value as MeetingLinksConfig)}
    >
      <MenuItem value={MeetingLinksConfig.One}>Same for all interviews</MenuItem>
      <MenuItem value={MeetingLinksConfig.Many}>Unique for each interview</MenuItem>
    </Select>
  );
};

export default LocationConfigMenu;
