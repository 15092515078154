import { SvgIcon, SvgIconProps } from './SvgIcon';

export const MinusIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 10C4.25 9.58581 4.58579 9.25002 5 9.25002L15 9.25002C15.4142 9.25002 15.75 9.58581 15.75 10C15.75 10.4142 15.4142 10.75 15 10.75L5 10.75C4.58579 10.75 4.25 10.4142 4.25 10Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
