import React, { useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Label, { LabelProps } from 'src/components/label';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/theme/type';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type TextFieldProps = BaseProps & MuiTextFieldProps & { isMobile?: boolean };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: TextFieldProps) => {
      let multiLineHeight = 0;
      if (props.multiline) {
        const rows: number = parseInt(props.rows?.toString() || '0', 10) || 2;
        multiLineHeight = rows * 22;
      }

      let currentHeight = props.label ? 64 : 32;
      if (multiLineHeight && props.label) {
        currentHeight = multiLineHeight + 20 + 32;
      } else if (multiLineHeight) {
        currentHeight = multiLineHeight + 20;
      }

      let border = props.disabled
        ? `1px solid ${theme.palette.common.transparent}`
        : `1px solid ${theme.grey.alpha.low}`;

      border = props.error ? `1px solid ${theme.palette.error.light}` : border;

      return {
        width: '100%',
        minHeight: currentHeight,
        paddingLeft: '12px',
        paddingRight: '8px',
        background: props.disabled ? theme.grey.alpha.min : theme.palette.background.default,
        border,
        borderRadius: '6px',
        '&:focus-within': {
          borderColor: theme.palette.action.focus,
          boxShadow: `0 0 0 1px ${theme.palette.action.focus}`,
        },
      };
    },
    startIconDisabled: {
      '& svg': {
        color: theme.grey.solid.mid,
      },
    },
    helperText: {
      padding: '0px 12px',
    },
    input: (props) => {
      return {
        // iOS zooms on inputs with fontSize < 16px. Allow consumers to turn this behavior off by overriding fontSize.
        fontSize: props.isMobile ? '16px' : '15px',
        lineHeight: '22px',
        height: 'unset',
        padding: '4px 0 4px',
      };
    },
    inputWrapper: {
      marginTop: `0 !important`,
    },
  })
);

const useInputLabelStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'inherit',
      paddingTop: '8px',
      '& p': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    formControl: {
      position: 'relative',
      transform: 'none',
    },
    asterisk: {
      fontSize: '13px',
      flexShrink: 0,
    },
  })
);

/**
 * @deprecated - Use `TextField` from `@mui/material` instead
 */
const TextField = (props: TextFieldProps): JSX.Element => {
  const classes = useStyles(props);
  const inputLabelClasses = useInputLabelStyles();
  const [isFocused, setIsFocused] = useState(false);
  const { className, InputProps, onBlur, onFocus, dataTestId, label, disabled, helperText, error, ...restProps } =
    props;

  let labelJSX = label;
  if (label && typeof label === 'string') {
    let color: LabelProps['color'] = 'high-contrast-grey';
    if (error) {
      color = 'error';
    } else if (isFocused) {
      color = 'info';
    } else if (disabled) {
      color = 'mid-contrast-grey';
    }
    labelJSX = (
      <Label variant="captions" color={color} fontWeight={600}>
        {label}
      </Label>
    );
  }

  const textField = (
    <MuiTextField
      {...restProps}
      disabled={disabled}
      className={clsx(classes.root, className)}
      variant="standard"
      onBlur={(event) => {
        setIsFocused(false);
        if (!onBlur) return;
        onBlur(event);
      }}
      onFocus={(event) => {
        setIsFocused(true);
        if (!onFocus) return;
        onFocus(event);
      }}
      label={labelJSX}
      InputLabelProps={{ ...restProps.InputLabelProps, shrink: true, classes: labelJSX ? inputLabelClasses : {} }}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        className: clsx(InputProps?.className, classes.inputWrapper),
        inputProps: {
          ...restProps.inputProps,
          className: clsx('mousetrap', restProps.inputProps?.className || '', classes.input),
        },
      }}
      data-testid={dataTestId}
      error={error}
    />
  );

  if (helperText) {
    return (
      <div>
        {textField}
        {React.isValidElement(helperText) && helperText}
        {!React.isValidElement(helperText) && (
          <Label
            className={classes.helperText}
            variant="captions"
            color={disabled ? 'mid-contrast-grey' : 'high-contrast-grey'}
          >
            {helperText}
          </Label>
        )}
      </div>
    );
  }

  return textField;
};

export default TextField;
