import React from 'react';

import { Dialog } from '@modernloop/shared/components';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

type Props = {
  onClose: () => void;
};

const AllInterviewsHiddenModal = ({ onClose }: Props) => {
  return (
    <ConditionalThemeProvider>
      <Dialog
        open
        decoration={
          <img
            style={{ width: '64px', height: '64px' }}
            src="/static/images/scheduling/warning_64.png"
            alt="Changing calender warning"
          />
        }
        title="All interviews are hidden from candidate"
        subTitle="Please address this issue to continue"
        onClose={onClose}
        submitOptions={{
          label: 'Got it',
          onClick: onClose,
        }}
      >
        Mark at least one interview as visible to the candidate to continue.
      </Dialog>
    </ConditionalThemeProvider>
  );
};

export default AllInterviewsHiddenModal;
