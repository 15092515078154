/* eslint-disable react/no-multi-comp */
import React from 'react';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import InterviewerApp from './interviewer-portal/App';
import RecruitingApp from './recruiting-portal/App';
import BaseApp from './shared/app/BaseApp';

const INTERVIEWER_PORTAL_BASE_URL = '/ip';

/**
 * Helper inner App that handles rendering apps based on routes.
 * This is necessary because we need to know the current location to determine which app to render
 * and need to use hooks to get the location.
 */
const LocationAwareApp: FC = () => {
  const { pathname } = useLocation();
  const isInterviewerPortal = pathname.startsWith(INTERVIEWER_PORTAL_BASE_URL);

  if (isInterviewerPortal) {
    return <InterviewerApp />;
  }

  return <RecruitingApp />;
};

/**
 * The main entry point for the app.
 */
const App: FC = () => {
  return (
    <BaseApp>
      <LocationAwareApp />
    </BaseApp>
  );
};

export default App;
