import React, { FC } from 'react';

import { JumpIcon } from '@modernloop/shared/icons';
import { IconButton, ListItemButton, ListItemIcon, Tooltip, Typography } from '@mui/material';

import { useIsMLoopInternalOnly } from 'src/hooks/feature-flag/useIsMLoopInternalOnly';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { enviromentConfig } from 'src/config';

type Props = {
  expanded: boolean;
};

const DevToolsLinkButton: FC<Props> = ({ expanded }) => {
  const { environment } = enviromentConfig;

  const isStaging = environment?.toLowerCase() === 'staging';
  const isDeployPreview = window.location.hostname.includes('deploy-preview');

  const isMLOnly = useIsMLoopInternalOnly();
  if (!isMLOnly) {
    return null;
  }

  const handleLinkToolClick = () => {
    // Open current window location in localhost or staging
    // If deploy preview, open in staging
    const path = window.location.pathname;
    const searchString = window.location.search;
    if (isStaging && !isDeployPreview) {
      window.open(`http://localhost:8888${path}${searchString}`);
    } else {
      window.open(`https://appstg.modernloop.io${path}${searchString}`);
    }
  };

  return (
    <ConditionalThemeProvider>
      {!expanded && (
        <Tooltip title={`Open in ${isStaging ? 'localhost' : 'staging'}`}>
          <IconButton onClick={handleLinkToolClick}>
            <JumpIcon />
          </IconButton>
        </Tooltip>
      )}
      {expanded && (
        <ListItemButton onClick={handleLinkToolClick}>
          <ListItemIcon>
            <JumpIcon />
          </ListItemIcon>
          <Typography color="text.secondary" fontWeight="500" variant="body2">
            Open in {isStaging && !isDeployPreview ? 'localhost' : 'staging'}
          </Typography>
        </ListItemButton>
      )}
    </ConditionalThemeProvider>
  );
};

export default DevToolsLinkButton;
