import ApiVerb from './ApiVerb';
import { apiPost } from './index';

export enum DeleteType {
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
  ALL = 'ALL',
}
export interface RequestBody {
  applicationStageId: string;
  isReschedule?: boolean;
  shouldDeletePastInterviews?: boolean;
  deleteType?: DeleteType;
}

const cancelSchedule = async (data: RequestBody): Promise<void> => {
  const response = await apiPost(ApiVerb.CANCEL_SCHEDULE, data);
  return response.data;
};

export default cancelSchedule;
