import React, { useEffect, useMemo, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box } from '@material-ui/core';
import { ListItem, ListItemButton, Stack } from '@mui/material';
import { useDebounce } from 'use-debounce';

import { useMeetingRoomSuggestionsLazyQuery } from 'src/generated/mloop-graphql';

import FilterSelect from 'src/components/FilterList/FilterSelect';
import IconButton from 'src/components/IconButton';
import { CrossIcon, MeetingRoomIcon, PlusIcon, ResetIcon } from 'src/components/icons';
import Label from 'src/components/label';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';
import { SupportedBackgroundColor } from 'src/components/utils/color';

import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { getMeetingRoomUpdates } from 'src/store/selectors/schedule-communications';
import { getSelectedScheduleId } from 'src/store/selectors/scheduling';
import { MeetingRoomSuggestionInterface } from 'src/store/slices/schedule-communications';

import IsoTimestamp from 'src/types/IsoTimestamp';

import fetchMoreMeetingRooms from 'src/views-new/ScheduleFlow/Steps/Communications/InterviewerCommunicationCards/MeetingRoom/fetchMoreMeetingRooms';

import { DEBOUNCE_TIMEOUT, PAGER_DEFAULT } from 'src/constants';
import { useDispatch, useSelector } from 'src/store';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type MeetingRoomProps = {
  startAt: IsoTimestamp;
  endAt: IsoTimestamp;
  interviewEventId: string;
};

const ROOM_OPTION_HEIGHT = 50;

const MeetingRoom = ({ startAt, endAt, interviewEventId }: MeetingRoomProps) => {
  const dispatch = useDispatch();
  const [fetchingMore, setFetchingMore] = useState(false);

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_TIMEOUT);

  const selectedScheduleId = useSelector(getSelectedScheduleId);
  const { addedRooms, deletedRooms, unchangedRooms } = useSelector((state) => {
    return getMeetingRoomUpdates(state, selectedScheduleId || '', interviewEventId);
  });

  const [fetchMeetingRoomSuggestions, { data, loading, error, fetchMore, updateQuery }] =
    useMeetingRoomSuggestionsLazyQuery();

  useEffect(() => {
    fetchMeetingRoomSuggestions({
      variables: {
        input: {
          pageInput: { cursor: '', limit: PAGER_DEFAULT },
          search: debouncedSearch,
          timeRange: { startAt, endAt },
        },
      },
      context: {
        batch: false,
      },
    });
  }, [fetchMeetingRoomSuggestions, debouncedSearch, startAt, endAt]);

  const options = useMemo(() => {
    if (!data?.meetingRoomSuggestions) return [] as MeetingRoomSuggestionInterface[];
    const selectedRoomIds = [...addedRooms, ...unchangedRooms].map((roomSuggestion) => roomSuggestion.room?.id || '');
    const result = data.meetingRoomSuggestions.items
      .map((roomSuggestion) => roomSuggestion)
      .filter((roomSuggestion) => roomSuggestion.room?.id && !selectedRoomIds.includes(roomSuggestion.room.id));

    return result;
  }, [addedRooms, data, unchangedRooms]);

  if (error) {
    return (
      <Label backgroundColor="error" color="error" variant="captions">
        {error.message}
      </Label>
    );
  }

  if (!selectedScheduleId || !interviewEventId) return null;

  const handleFetchMore = () => {
    if (!loading && !fetchingMore && data?.meetingRoomSuggestions?.nextCursor) {
      setFetchingMore(true);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/catch-or-return
      fetchMoreMeetingRooms(
        fetchMore,
        updateQuery,
        data?.meetingRoomSuggestions,
        debouncedSearch,
        startAt,
        endAt
      ).finally(() => {
        setFetchingMore(false);
      });
    }
  };

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: MeetingRoomSuggestionInterface) => {
    return (
      <ListItem {...props}>
        <ListItemButton>
          <Stack direction="column">
            <Stack direction="row" spacing={1} flexWrap="nowrap">
              <MaybeTooltip
                label={option.room?.description || ''}
                labelProps={{ style: { display: 'flex', maxWidth: '100%' } }}
                tooltip={option.room?.description || ''}
              />
              <Label>·</Label>
              {option.room?.floorName && (
                <Label color="high-contrast-grey" noWrap>
                  Floor: {option.room.floorName}
                </Label>
              )}
            </Stack>
            <Stack direction="row" spacing={1} flexWrap="nowrap">
              <Label color="high-contrast-grey" noWrap>{`${option.room?.capacity} people`}</Label>
              <Label color="high-contrast-grey">·</Label>
              <Label color={option.isBusy ? 'error' : 'success'} noWrap>{`${
                option.isBusy ? 'Busy at this time' : 'Free at this time'
              }`}</Label>
            </Stack>
          </Stack>
        </ListItemButton>
      </ListItem>
    );
  };

  const handleInputChange = (event: React.ChangeEvent, value: string) => {
    setSearch(value);
  };

  const handleChange = (event: React.ChangeEvent, values: MeetingRoomSuggestionInterface[]) => {
    if (!values.length) return;

    dispatch(
      addInterviewScheduleUpdate({
        type: 'ScheduleUpdateAddInterviewMeetingRoom',
        scheduleId: selectedScheduleId,
        applicationStageInterviewId: interviewEventId,
        roomSuggestion: values[values.length - 1],
      })
    );
  };

  const renderRooms = (
    roomSuggestion: MeetingRoomSuggestionInterface,
    operation: 'unchanged' | 'added' | 'deleted'
  ) => {
    let bgColor: SupportedBackgroundColor = 'contrast';
    if (operation === 'added') {
      bgColor = 'success';
    } else if (operation === 'deleted') {
      bgColor = 'error';
    }

    return (
      <Label
        icon={<MeetingRoomIcon />}
        color={operation === 'added' ? 'success' : undefined}
        backgroundColor={bgColor}
        style={{ textDecoration: operation === 'deleted' ? 'line-through' : undefined }}
      >
        <Stack direction="row" alignItems="center">
          {roomSuggestion.room?.description}
          <IconButton
            onClick={() => {
              if (operation === 'deleted') {
                dispatch(
                  addInterviewScheduleUpdate({
                    type: 'ScheduleUpdateAddInterviewMeetingRoom',
                    scheduleId: selectedScheduleId,
                    applicationStageInterviewId: interviewEventId,
                    roomSuggestion,
                  })
                );
                return;
              }
              dispatch(
                addInterviewScheduleUpdate({
                  type: 'ScheduleUpdateDeleteInterviewMeetingRoom',
                  scheduleId: selectedScheduleId,
                  applicationStageInterviewId: interviewEventId,
                  roomSuggestion,
                })
              );
            }}
          >
            {operation !== 'deleted' && <CrossIcon />}
            {operation === 'deleted' && <ResetIcon />}
          </IconButton>
        </Stack>
      </Label>
    );
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={1} gap={1} flexWrap="wrap">
        {unchangedRooms.map((roomSuggestion) => renderRooms(roomSuggestion, 'unchanged'))}
        {addedRooms.map((roomSuggestion) => renderRooms(roomSuggestion, 'added'))}
        {deletedRooms.map((roomSuggestion) => renderRooms(roomSuggestion, 'deleted'))}
        <FilterSelect
          options={options}
          loading={loading || fetchingMore}
          placeholderText="Search meeting rooms…"
          PaperProps={{ style: { width: 480 } }}
          getLabel={() =>
            unchangedRooms.length + addedRooms.length + deletedRooms.length ? (
              <IconButton>
                <PlusIcon />
              </IconButton>
            ) : (
              'Add meeting room'
            )
          }
          getOptionLabel={(option) => option.room?.description || ''}
          renderOption={renderOption}
          onChange={handleChange}
          onInputChange={handleInputChange}
          getChildSize={() => ROOM_OPTION_HEIGHT}
          onPopoverClose={() => setSearch('')}
          filterOptions={(opts) => opts}
          onScrollToEnd={handleFetchMore}
        />
      </Stack>
    </Box>
  );
};

export default MeetingRoom;
