import React from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/styles';
import { Box, Stack } from '@mui/material';

import { ActivityLogEmail_ActivityLogFragment } from 'src/generated/mloop-graphql';

import Link from 'src/components/Link';
import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';

import logError from 'src/utils/logError';
import { replaceLinksWithAnchorTags } from 'src/utils/strings';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogContent from '../../Helpers/ActivityLogContent';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      overflow: 'hidden',
      display: '-webkit-box',
      maxWidth: '558px',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 1, // Limit to 1 line
    },
    labelExpanded: { overflowX: 'scroll', maxWidth: '558px' },
    container: {
      '& ul': {
        marginLeft: '28px',
      },
      '& ol': {
        marginLeft: '28px',
      },
    },
  })
);

interface Fragments {
  activityLog: ActivityLogEmail_ActivityLogFragment;
}

const ActivityLogEmail: FCWithFragments<Fragments> = ({ activityLog }) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (details?.__typename !== 'ActivityLogEmailDetails') {
    logError('ActivityLogEmail: details is not ActivityLogEmailDetails');
    return null;
  }

  const emailDetails = details;

  const { subject, body, fromEmailAddress, toEmailAddress, ccAddresses } = emailDetails;

  const cc = ccAddresses.join(', ');

  const getEmailRecipientsText = (property: string, value: string) => (
    <Stack direction="row" alignItems="baseline" spacing="4px">
      <Label color="high-contrast-grey" fontWeight={600}>
        {property}:
      </Label>
      <Label color="high-contrast-grey" fontWeight={400}>
        {value}
      </Label>
    </Stack>
  );

  const getSubjectRow = (subjectText: string) => {
    return (
      <Stack direction="row" alignItems="baseline" spacing="4px">
        <Label color="max-contrast-grey" fontWeight={600}>
          Subject:
        </Label>
        <Label color="max-contrast-grey" fontWeight={400}>
          {subjectText}
        </Label>
      </Stack>
    );
  };

  const getLinkButton = (label: string, onClick: () => void) => {
    return (
      <Box>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          dataTestId="activity-log-email-show-more-button"
          component="button"
          href=""
          onClick={onClick}
          color="info"
        >
          {label}
        </Link>
      </Box>
    );
  };

  const emailContentExpanded = (
    <Stack direction="column" spacing={1}>
      {fromEmailAddress ? getEmailRecipientsText('From', fromEmailAddress) : null}
      {toEmailAddress ? getEmailRecipientsText('To', toEmailAddress) : null}
      {cc ? getEmailRecipientsText('CC', cc) : null}

      {getSubjectRow(subject)}

      <Label className={classes.labelExpanded}>
        <span
          className={classes.container}
          style={{
            whiteSpace: 'pre-line',
          }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: replaceLinksWithAnchorTags(body).text }}
        />
      </Label>

      {getLinkButton('Show less', () => {
        setIsExpanded(false);
      })}
    </Stack>
  );

  const emailContentCollapsed = (
    <Stack direction="column" spacing={1}>
      {getSubjectRow(subject)}

      <Label className={classes.label}>
        {/* eslint-disable-next-line react/no-danger */}
        <span style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: body }} />
      </Label>

      {getLinkButton('Show more', () => {
        setIsExpanded(true);
      })}
    </Stack>
  );

  return (
    <ActivityLogBase activityLog={activityLog} actionLabel="emailed the candidate">
      <ActivityLogContent fullWidth content={isExpanded ? emailContentExpanded : emailContentCollapsed} />
    </ActivityLogBase>
  );
};

ActivityLogEmail.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogEmail_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogEmailDetails {
          __typename
          fromEmailAddress
          toEmailAddress
          ccAddresses
          subject
          body
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogEmail;
