/**
 * TODO ENG-9217 @Ashutosh use it directly from mloop-shared once candidate portal works fine as candidate portal is using shared version
 * Not doing it right now as unit test is faling for some reason, will debug once candidate portal is working fine
 */
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

import { getAuth0Client } from 'src/contexts/auth0/utils';

import { getErrorStatus, getExternalErrorMessage, getInternalErrorMessage } from 'src/utils/error';
import logError from 'src/utils/logError';

import logMessage from '../logMessage';

/**
 * Sometimes the error recieved is a `Network Error` this is our best guess at understanding when this is
 * @param error AxiosError that was recieved after the axios request was made
 * @returns boolean if its a Network Error
 */
function isNetworkError(err: AxiosError) {
  return !!err.isAxiosError && !err.response;
}

/**
 * This axios interceptor logs any error response to Sentry with the appropriate tags and extra data
 *
 * @param error AxiosError that was recieved after the axios request was made
 * @returns void
 * @throws rethrows the error receieved
 */
const axiosInterceptorResponseError =
  (category: string) =>
  (error: AxiosError): void => {
    const internalError = getInternalErrorMessage(error);
    const externalError = getExternalErrorMessage(error);
    const endpoint = (error?.request as XMLHttpRequest)?.responseURL;
    Sentry.withScope(() => {
      Sentry.setTag('category', category);
      Sentry.setTag('endpoint', endpoint);
      Sentry.setTag('external_error', externalError);
      Sentry.setTag('internal_error', internalError);
      logError(error, {
        endpoint,
        internalError,
        externalError,
        isNetworkError: isNetworkError(error),
        isAxiosError: error?.isAxiosError,
        request: error?.request,
        response: error?.response,
      });

      if (getErrorStatus(error) >= 500) {
        logMessage(`Customer received 5XX error at ${endpoint}`, 'fatal', { error });
      }

      if (getErrorStatus(error) === 401) {
        // eslint-disable-next-line promise/no-promise-in-callback
        getAuth0Client() // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line promise/always-return
          .then((auth0Client) => {
            setTimeout(() => {
              auth0Client.loginWithRedirect();
            }, 300);
          })
          .catch((err) => {
            logError(err);
          });
      }
    });
    throw error;
  };

export default axiosInterceptorResponseError;
