import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CaretRightIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.03151 4.41435C8.35495 4.15559 8.82692 4.20803 9.08568 4.53148L13.0857 9.53148C13.3048 9.80539 13.3048 10.1946 13.0857 10.4685L9.08568 15.4685C8.82692 15.792 8.35495 15.8444 8.03151 15.5856C7.70806 15.3269 7.65562 14.8549 7.91438 14.5315L11.5396 10L7.91438 5.46852C7.65562 5.14507 7.70806 4.6731 8.03151 4.41435Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
