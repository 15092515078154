/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Stack } from '@mui/material';
import { addDays, startOfDay as startOfDayDateFns } from 'date-fns';
import { isEmpty } from 'lodash';

import {
  JobStageInterviewGroupInput,
  OptionsInput,
  SelfScheduleLocation,
  SelfScheduleOptions_InterviewPlanFragment,
  SelfScheduleZoomHost,
  SuggestedTimeRangesRequestType,
  useEmployeeByIdsLazyQuery,
  useJobStageQuery,
} from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import DialogPage, { DialogActions, DialogContent, DialogTitle } from 'src/components/Dialog/DialogPage';
import useFullScreen from 'src/components/Dialog/useFullScreen';
import { MenuOption } from 'src/components/Select';
import Label from 'src/components/label';

import useInterviewPlanEntityDescription from 'src/entities/InterviewPlan/useInterviewPlanDescription';
import { ZoomUserDataWithCategory } from 'src/entities/Select/ZoomUserSelect/types';
import { SmartOptions } from 'src/entities/Select/ZoomUserSelectWithSmartOptions';

import { useIntegrations } from 'src/hooks/api/integration';
import { useHasCalendarWriteAccess } from 'src/hooks/useHasCalendarAccess';

import IsoTimestamp, { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import useInterviewPlanDescription from 'src/views-new/InterviewPlan/useInterviewPlanDescription';

import OptionsCountAlert from '../CandidateRequest/OptionsCountAlert';

import { ConfigurableSelfScheduleOptions } from './ConfigurableSelfScheduleOptions';
import OptionsCalendar from './OptionsCalendar';
import { SelfScheduleSelectedTemplates } from './SelfSchedulePreferences';
import { NumberOfDays } from './TimeframePicker';

export const JobStageQuery = gql`
  query JobStage($id: uuid!) {
    jobStage(id: $id) {
      id
      name
    }
  }
`;

type Fragments = {
  options: OptionsInput;
  interviewPlan: SelfScheduleOptions_InterviewPlanFragment;
};

type Props = {
  applicationId: string;
  taskId?: string;
  jobStageId: string;
  customJobStageId: string | undefined;
  customInterviewPlan?: JobStageInterviewGroupInput[];
  templates: SelfScheduleSelectedTemplates;
  defaultTemplates: SelfScheduleSelectedTemplates;
  onBack?: () => void;
  onClose: () => void;
  onContinue: () => void;
  onOptionsChanged: (value: OptionsInput) => void;
  onTemplatesChanged: (value: SelfScheduleSelectedTemplates) => void;
  numberOfDays?: NumberOfDays;
};

const LOCATION_OPTIONS: MenuOption[] = [
  { id: SelfScheduleLocation.Zoom, value: 'Zoom' },
  { id: SelfScheduleLocation.Google, value: 'Google Meet' },
  { id: SelfScheduleLocation.MicrosoftTeams, value: 'Microsoft Teams' },
  { id: SelfScheduleLocation.Phone, value: 'Phone call' },
  { id: SelfScheduleLocation.Custom, value: 'Custom link' },
  { id: SelfScheduleLocation.None, value: 'None' },
];

export function useLocationOptions() {
  const { data: integrations, isLoading: integrationsLoading } = useIntegrations();
  return useMemo(() => {
    return LOCATION_OPTIONS.filter((option) => {
      if (integrationsLoading) return false;

      if (option.id === SelfScheduleLocation.Google) return Boolean(integrations?.google?.admin_user_id);
      if (option.id === SelfScheduleLocation.MicrosoftTeams) return Boolean(integrations?.microsoft?.active);
      if (option.id === SelfScheduleLocation.Zoom) return Boolean(integrations?.zoom?.active);

      return true;
    });
  }, [integrations, integrationsLoading]);
}

const SelfScheduleOptions: FCWithFragments<Fragments, Props> = ({
  applicationId,
  taskId,
  jobStageId,
  customJobStageId,
  customInterviewPlan,
  interviewPlan,
  options,
  templates,
  defaultTemplates,
  onBack,
  onClose,
  onContinue,
  onOptionsChanged,
  onTemplatesChanged,
  numberOfDays: prefNumberOfDays,
}) => {
  const blockUnknownCalendars = useFlag('user_should_block_unknown_calendars');
  const isFullScreen = useFullScreen();
  const [numberOfDaysState, setNumberOfDays] = useState<NumberOfDays | undefined>();
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [selectedDatesOptionsCount, setSelectedDatesOptionsCount] = useState(0);
  const [employeeMissingZoomUserIds, setEmployeeMissingZoomUserIds] = useState<string[]>();
  const [selfScheduleCountError, setSelfScheduleCountError] = useState<string>();
  const [customLink, setCustomLink] = useState<string>(options.customLocation || '');
  const [shouldSendPersonalizedEmail, setShouldSendPersonalizedEmail] = React.useState<boolean>(
    Boolean(templates.candidateEmailTemplateId)
  );

  const { hasAccess: hasCandidateCalendarWriteAccess } = useHasCalendarWriteAccess(options.candidateCalendarId);
  const { hasAccess: hasInterviewerCalendarWriteAccess } = useHasCalendarWriteAccess(options.interviewerCalendarId);

  const ignoreRollingDaysSetting = useFlag('user_ignore_rolling_window_setting');
  const interviewPlanEntityInTasksEnabled = useFlag('interview_plan_entity_in_tasks');

  const numberOfDays = numberOfDaysState || options.rollingDays || prefNumberOfDays;

  const suggestedOptionsOn =
    options.canScheduleOverAvailableKeywords ||
    options.canScheduleOverRecruitingKeywords ||
    options.canScheduleOverFreeTime;

  const [fetchEmployeeByIds, { data: missingEmployeesData }] = useEmployeeByIdsLazyQuery();

  const { data: jobStageData } = useJobStageQuery({ variables: { id: jobStageId } });
  const interviewPlanDescription = useInterviewPlanDescription(customJobStageId || jobStageId);
  const interviewPlanEntityDescription = useInterviewPlanEntityDescription({ jobStage: interviewPlan }, undefined);

  useEffect(() => {
    if (!employeeMissingZoomUserIds?.length) return;
    fetchEmployeeByIds({ variables: { input: employeeMissingZoomUserIds } });
  }, [employeeMissingZoomUserIds, fetchEmployeeByIds]);

  const getInclusionDays = useCallback((value: NumberOfDays) => {
    let date = startOfDayDateFns(new Date());
    const inclusionDays: IsoTimestamp[] = [];

    for (let i = 0; i < value; i++) {
      inclusionDays.push(assertIsoTimestamp(date.toISOString()));
      date = addDays(date, 1);
    }

    return inclusionDays;
  }, []);

  const handleSetNumberOfDays = (value: NumberOfDays) => {
    setNumberOfDays(value);
    if (options.rollingDays) {
      onOptionsChanged({ ...options, rollingDays: value, inclusionDays: undefined });
    } else {
      onOptionsChanged({ ...options, inclusionDays: getInclusionDays(value) });
    }
  };

  const handleSelectedDatesChanged = (dates: IsoTimestamp[]) => {
    if (ignoreRollingDaysSetting) {
      setNumberOfDays(NumberOfDays.Custom);
    } else {
      setNumberOfDays(undefined);
    }
    onOptionsChanged({ ...options, inclusionDays: dates, rollingDays: null });
  };

  const handleZoomUserChanged = (option: ZoomUserDataWithCategory) => {
    const value: string | null | SmartOptions = option.zoomUserId;
    if (value === SmartOptions.Interviewer || value === SmartOptions.Scheduler) {
      onOptionsChanged({
        ...options,
        zoomHost:
          value === SmartOptions.Interviewer ? SelfScheduleZoomHost.Interviewer : SelfScheduleZoomHost.Scheduler,
      });
    } else if (value) {
      onOptionsChanged({
        ...options,
        zoomHost: SelfScheduleZoomHost.CustomZoomHost,
        zoomHostUserId: value,
      });
    }
  };

  const handleCustomLinksInput = (event: React.FocusEvent<HTMLInputElement>) => {
    setCustomLink(event?.target?.value);
  };

  const handleCustomLinksInputComplete = () => {
    onOptionsChanged({
      ...options,
      customLocation: customLink,
    });
  };

  /**
   * Returns the first user-facing reason for disabling the continue step.
   * Returns null if the step is enabled and can be continued.
   */
  const getDisabledReason = (): string | undefined => {
    if (!options.candidateCalendarId) {
      return 'Candidate calendar is required';
    }

    if (!hasCandidateCalendarWriteAccess && blockUnknownCalendars) {
      return 'Candidate calendar write access is required';
    }

    if (!options.interviewerCalendarId) {
      return 'Interviewer calendar is required';
    }

    if (!hasInterviewerCalendarWriteAccess && blockUnknownCalendars) {
      return 'Interviewer calendar write access is required';
    }

    if (!selectedDatesOptionsCount) {
      return 'There are no options available to schedule';
    }

    if (options.location === SelfScheduleLocation.Custom && isEmpty(customLink)) {
      return 'Custom link is required';
    }

    if (!templates?.interviewerEventTemplateId) {
      return 'Interviewer event template is required';
    }

    if (shouldSendPersonalizedEmail && !templates?.candidateEmailTemplateId) {
      return 'Candidate email template is required when sending personalized emails';
    }

    return undefined;
  };

  const disabledReason = getDisabledReason();

  const optionsCalendarJsx = (
    <OptionsCalendar
      applicationId={applicationId}
      taskId={taskId}
      requestType={SuggestedTimeRangesRequestType.SelfScheduleRequest}
      jobStageId={jobStageId}
      customJobStageId={customJobStageId}
      customInterviewPlan={customInterviewPlan}
      rollingDays={options.rollingDays}
      inclusionDays={options.inclusionDays as IsoTimestamp[]}
      selfScheduleZoomHost={options.zoomHost || undefined}
      advanceNoticeInHours={options.advanceNoticeInHours}
      datePickerNoMargin={isFullScreen}
      onLoading={setLoadingOptions}
      onSelectedDatesOptionsCountChanged={setSelectedDatesOptionsCount}
      onSelectedDatesChanged={handleSelectedDatesChanged}
      onEmployeeMissingZoomUserId={setEmployeeMissingZoomUserIds}
      onSelfScheduleCountError={setSelfScheduleCountError}
      shouldRespectLoadLimit={Boolean(options.shouldRespectLoadLimit)}
      canScheduleOverAvailableKeywords={Boolean(options.canScheduleOverAvailableKeywords)}
      canScheduleOverRecruitingKeywords={Boolean(options.canScheduleOverRecruitingKeywords)}
      canScheduleOverFreeTime={Boolean(options.canScheduleOverFreeTime)}
    />
  );

  return (
    <DialogPage onClose={onClose}>
      <DialogContent>
        <DialogTitle
          title="Add self-schedule timeframe"
          subTitle={!onBack ? 'Step 1 of 2: Add timeframe' : 'Step 2 out of 3: Add timeframe'}
        />

        <Stack direction="column" spacing={2}>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              width: '100%',
            }}
          >
            {selfScheduleCountError && (
              <Alert
                dataTestId="self-schedule-options-error-alert"
                status="error"
                alignItems="center"
                title={selfScheduleCountError}
                caption={selfScheduleCountError}
              />
            )}

            {jobStageData?.jobStage?.name && (
              <Stack data-testid="self-schedule-options-interview-plan-description">
                <Label fontWeight={600}>Scheduling for {jobStageData.jobStage.name}</Label>
                <Label variant="captions" fontWeight={400} color="high-contrast-grey">
                  {interviewPlanEntityInTasksEnabled ? interviewPlanEntityDescription : interviewPlanDescription}
                </Label>
              </Stack>
            )}
          </Stack>

          <ConfigurableSelfScheduleOptions
            alertJsx={
              <OptionsCountAlert
                text="schedule"
                enabled={suggestedOptionsOn ?? false}
                loading={loadingOptions}
                count={selectedDatesOptionsCount}
                error={selfScheduleCountError}
              />
            }
            title="Preferences"
            options={options}
            numberOfDays={numberOfDays}
            selectedDatesOptionsCount={selectedDatesOptionsCount}
            optionsCalendarJsx={optionsCalendarJsx}
            templates={templates}
            employeeMissingZoomUserIds={employeeMissingZoomUserIds}
            defaultTemplates={defaultTemplates}
            missingEmployeesData={missingEmployeesData}
            onRollingDaysChange={handleSetNumberOfDays}
            onRollingDaysToggle={(value: boolean) => {
              if (!value) {
                onOptionsChanged({
                  ...options,
                  rollingDays: null,
                  inclusionDays: numberOfDays ? getInclusionDays(numberOfDays) : options.inclusionDays,
                });
                if (ignoreRollingDaysSetting) {
                  setNumberOfDays(NumberOfDays.Custom);
                }
              } else if (!options.rollingDays) {
                onOptionsChanged({
                  ...options,
                  rollingDays: numberOfDays || NumberOfDays.TwoWeeks,
                  inclusionDays: undefined,
                });
              }
            }}
            onNoteChanged={(value: string) => {
              onOptionsChanged({ ...options, note: value });
            }}
            onOptionsChanged={onOptionsChanged}
            onTemplatesChanged={onTemplatesChanged}
            handleCustomLinksInput={handleCustomLinksInput}
            handleCustomLinksInputComplete={handleCustomLinksInputComplete}
            handleZoomUserChanged={handleZoomUserChanged}
            hideRequestTemplateSelector
            showCandidateEventTemplateSelector
            shouldSendPersonalizedEmail={shouldSendPersonalizedEmail}
            setShouldSendPersonalizedEmail={setShouldSendPersonalizedEmail}
            showScheduleLocation
          />
        </Stack>
      </DialogContent>
      <DialogActions
        submitOptions={{
          isDisabled: !!disabledReason,
          tooltip: disabledReason,
          label: 'Continue',
          onClick: onContinue,
        }}
        cancelOptions={
          !onBack
            ? {
                label: 'Cancel',
                onClick: () => onClose(),
              }
            : {
                label: 'Previous step',
                onClick: onBack,
                startIcon: 'CaretLeftIcon',
              }
        }
      />
    </DialogPage>
  );
};

SelfScheduleOptions.fragments = {
  options: gql`
    fragment SelfScheduleOptions_options on Options {
      inclusionDays
      rollingDays
      advanceNoticeInHours
      candidateCalendarId
      interviewerCalendarId
      location
      zoomHost
      zoomHostUserId
      candidateEmail
      shouldRespectLoadLimit
      canScheduleOverRecruitingKeywords
      canScheduleOverAvailableKeywords
      canScheduleOverFreeTime
      meetingHost
      videoMeetingHostEmployeeId
    }
  `,
  interviewPlan: gql`
    ${useInterviewPlanEntityDescription.fragments.jobStage}
    fragment SelfScheduleOptions_interviewPlan on JobStage {
      id
      ...useInterviewPlanDescription_jobStage
    }
  `,
};

export default SelfScheduleOptions;
