import { Routes } from 'src/constants/routes';

import { EmployeePrefName } from 'src/utils/api/employee';

import { useEmployeePrefTimestamp } from '../api/employee/get';
import useCurrentRoute from '../useCurrentRoute';
import useEmployeeId from '../useEmployeeId';

import { DefaultReturnType } from './constants';
import { ReturnType } from './types';
import { getRedirectParams, isIgnoredRoutes } from './utils';

/**
 * Hook to check if terms & privacy requirements are met, if yes return redirect params to naviagte to terms & privacy page with current url
 * If needed to be export, lets discuss once
 */
const useTermsAndPrivacyRequirements = (): ReturnType => {
  /** Getting the current location */
  const currentRoute = useCurrentRoute();

  const employeeId = useEmployeeId();
  const [employeePref, { loading, error }] = useEmployeePrefTimestamp(
    EmployeePrefName.TS_TOS_ACCEPTED_AT,
    employeeId || '',
    null
  );

  if (!employeeId || loading || error || isIgnoredRoutes(currentRoute)) {
    return DefaultReturnType;
  }

  /** If employee pref has not been set to accept the terms & condition redirect params to TOS page */
  if (!employeePref) {
    return getRedirectParams(Routes.TermsAndPrivacy, currentRoute);
  }

  return DefaultReturnType;
};

export default useTermsAndPrivacyRequirements;
