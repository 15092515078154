import React, { FC, createContext, useContext, useEffect, useReducer } from 'react';

import { noop } from 'lodash';

import {
  HistoryOperation,
  SearchParamKnownKeys,
  SidePanelType,
  useAddUrlSearchParams,
  useDeleteUrlSearchParams,
  useUrlSearchParams,
} from 'src/hooks/useUrlSearchParams';

import { SidePanelData, UnsavedChangesConfig } from 'src/views-new/sidepanel/utils/types';

type SidePanelState = {
  data?: SidePanelData;
  showUnsavedChangesDialog?: boolean;
  unsavedChangesConfig: UnsavedChangesConfig;
};

type SidePanelAction = {
  data?: SidePanelData;
  showUnsavedChangesDialog?: boolean;
  unsavedChangesConfig?: Partial<UnsavedChangesConfig>;
};

const reducer = (state: SidePanelState, action: SidePanelAction): SidePanelState => {
  // When dismissing side panel check if there is unsaved data and show dialog to confirm
  if (
    state.data &&
    action.data === undefined &&
    action.unsavedChangesConfig === undefined &&
    action.showUnsavedChangesDialog === undefined
  ) {
    const hasUnsavedChanges = Object.values(state.unsavedChangesConfig).some((value) => value);

    if (hasUnsavedChanges) {
      return {
        ...state,
        showUnsavedChangesDialog: hasUnsavedChanges,
      };
    }
  }

  return {
    ...state,
    ...action,
    unsavedChangesConfig: { ...state.unsavedChangesConfig, ...action.unsavedChangesConfig },
  };
};

const DEFAULT_UNSAVED_CHANGES_CONFIG: UnsavedChangesConfig = {
  notes: false,
};

const SidePanelStateContext = createContext<SidePanelState>({
  data: undefined,
  unsavedChangesConfig: DEFAULT_UNSAVED_CHANGES_CONFIG,
});

const SidePanelDispatchContext = createContext<React.Dispatch<SidePanelAction>>(noop);

export const useSidePanelState = () => {
  const state = useContext(SidePanelStateContext);
  return state;
};

/**
 * SidePanelManager is responsible for opening and closing side panel.
 * It lets users easily open side panel from anywhere in the app.
 *
 * Additonal details:
 * - For adding support for additional side panel types add function in SidePanelManager class.
 *   E.g. for activity log on settings page, openOrgSettingsActivityLogSidePanel(type: SidePanlType.ActivityLog, orgId: string)
 */
export class SidePanelManager {
  private static sidePanelManager: SidePanelManager | null = null;

  private urlSearchParams: URLSearchParams;

  private addUrlSearchParams: ReturnType<typeof useAddUrlSearchParams>;

  private deleteUrlSearchParams: ReturnType<typeof useDeleteUrlSearchParams>;

  private dispatch: React.Dispatch<SidePanelAction>;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  public static initialize(
    dispatch: React.Dispatch<SidePanelAction>,
    urlSearchParams: URLSearchParams,
    addUrlSearchParams: ReturnType<typeof useAddUrlSearchParams>,
    deleteUrlSearchParams: ReturnType<typeof useDeleteUrlSearchParams>
  ) {
    if (!this.sidePanelManager) {
      this.sidePanelManager = new SidePanelManager();
    }
    this.sidePanelManager.dispatch = dispatch;
    this.sidePanelManager.urlSearchParams = urlSearchParams;
    this.sidePanelManager.addUrlSearchParams = addUrlSearchParams;
    this.sidePanelManager.deleteUrlSearchParams = deleteUrlSearchParams;
  }

  private clear() {
    // Reset handle unsaved changes when opening side panel
    this.dispatch({ showUnsavedChangesDialog: false, unsavedChangesConfig: DEFAULT_UNSAVED_CHANGES_CONFIG });
  }

  public static openScheduleTaskSidePanel(
    scheduleTaskId: string,
    options?: { showRequirements?: boolean; addToUrl?: boolean }
  ) {
    if (!SidePanelManager.sidePanelManager) return;

    const { addToUrl = true, showRequirements = true } = options || {};

    SidePanelManager.sidePanelManager.clear();

    if (addToUrl) {
      ((sidePanelManager: SidePanelManager) => {
        setTimeout(() => {
          sidePanelManager.addUrlSearchParams(
            {
              [SearchParamKnownKeys.panelType]: SidePanelType.ScheduleTask,
              [SearchParamKnownKeys.panelObjectId]: scheduleTaskId,
            },
            'replace'
          );
        }, 0);
      })(SidePanelManager.sidePanelManager);
    }
    SidePanelManager.sidePanelManager.dispatch({
      data: { type: SidePanelType.ScheduleTask, scheduleTaskId, showRequirements },
    });
  }

  public static openInterviewModuleSidePanel(
    {
      interviewModuleId,
      moduleMemberId,
    }: {
      interviewModuleId: string;
      moduleMemberId: string;
    },
    options?: { addToUrl?: boolean }
  ) {
    if (!SidePanelManager.sidePanelManager) return;

    const { addToUrl = true } = options || {};

    SidePanelManager.sidePanelManager.clear();

    if (addToUrl) {
      ((sidePanelManager: SidePanelManager) => {
        setTimeout(() => {
          sidePanelManager.addUrlSearchParams(
            {
              [SearchParamKnownKeys.panelType]: SidePanelType.InterviewModuleMember,
              [SearchParamKnownKeys.interviewModuleId]: interviewModuleId,
              [SearchParamKnownKeys.moduleMemberId]: moduleMemberId,
            },
            'replace'
          );
        }, 0);
      })(SidePanelManager.sidePanelManager);
    }
    SidePanelManager.sidePanelManager.dispatch({
      data: { type: SidePanelType.InterviewModuleMember, interviewModuleId, moduleMemberId },
    });
  }

  public static closeSidePanel() {
    if (!SidePanelManager.sidePanelManager) return;

    SidePanelManager.sidePanelManager.dispatch({ data: undefined });

    // This handles the case when we call closeSidePanel and immediately navigate to a different URL.
    // If the URL does not have side panel info in it then skip.
    ((sidePanelManager: SidePanelManager) => {
      setTimeout(() => {
        if (
          sidePanelManager.urlSearchParams.get(SearchParamKnownKeys.panelType) ||
          sidePanelManager.urlSearchParams.get(SearchParamKnownKeys.panelObjectId) ||
          sidePanelManager.urlSearchParams.get(SearchParamKnownKeys.interviewModuleId) ||
          sidePanelManager.urlSearchParams.get(SearchParamKnownKeys.moduleMemberId)
        ) {
          sidePanelManager.deleteUrlSearchParams(
            [
              SearchParamKnownKeys.panelType,
              SearchParamKnownKeys.panelObjectId,
              SearchParamKnownKeys.moduleMemberId,
              SearchParamKnownKeys.interviewModuleId,
            ],
            HistoryOperation.REPLACE
          );
        }
      }, 0);
    })(SidePanelManager.sidePanelManager);
  }

  public static setShowUnsavedChangesDialog(show: boolean) {
    if (!SidePanelManager.sidePanelManager) return;
    SidePanelManager.sidePanelManager.dispatch({ showUnsavedChangesDialog: show });
  }

  public static setHasUnsavedNoteChanges(hasUnsavedChanges: boolean) {
    if (!SidePanelManager.sidePanelManager) return;
    SidePanelManager.sidePanelManager.dispatch({ unsavedChangesConfig: { notes: hasUnsavedChanges } });
  }
}

/**
 * Wrapper class that initializes SidePanelManager object.
 */
export const SidePanelProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    showUnsavedChangesDialog: false,
    unsavedChangesConfig: DEFAULT_UNSAVED_CHANGES_CONFIG,
  });
  const urlSearchParams = useUrlSearchParams();
  const addUrlSearchParams = useAddUrlSearchParams();
  const deleteUrlSearchParams = useDeleteUrlSearchParams();

  useEffect(() => {
    SidePanelManager.initialize(dispatch, urlSearchParams, addUrlSearchParams, deleteUrlSearchParams);
  }, [addUrlSearchParams, deleteUrlSearchParams, urlSearchParams]);

  return (
    <SidePanelStateContext.Provider value={state}>
      <SidePanelDispatchContext.Provider value={dispatch}>{children}</SidePanelDispatchContext.Provider>
    </SidePanelStateContext.Provider>
  );
};
