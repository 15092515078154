import React from 'react';

import BaseIcon, { Props } from './Icon';

const LiveIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.58058 5.58058C5.87348 5.28769 5.87348 4.81282 5.58058 4.51992C5.28769 4.22703 4.81282 4.22703 4.51992 4.51992C1.49336 7.54649 1.49336 12.4535 4.51992 15.4801C4.81282 15.773 5.28769 15.773 5.58058 15.4801C5.87348 15.1872 5.87348 14.7123 5.58058 14.4194C3.13981 11.9786 3.13981 8.02136 5.58058 5.58058ZM15.4801 4.51992C15.1872 4.22703 14.7123 4.22703 14.4194 4.51992C14.1265 4.81282 14.1265 5.28769 14.4194 5.58058C16.8602 8.02136 16.8602 11.9786 14.4194 14.4194C14.1265 14.7123 14.1265 15.1872 14.4194 15.4801C14.7123 15.773 15.1872 15.773 15.4801 15.4801C18.5066 12.4535 18.5066 7.54649 15.4801 4.51992ZM7.7019 6.64124C7.9948 6.93414 7.9948 7.40901 7.7019 7.7019C6.4327 8.97111 6.4327 11.0289 7.7019 12.2981C7.9948 12.591 7.9948 13.0659 7.7019 13.3588C7.40901 13.6516 6.93414 13.6516 6.64124 13.3588C4.78625 11.5038 4.78625 8.49623 6.64124 6.64124C6.93414 6.34835 7.40901 6.34835 7.7019 6.64124ZM12.2981 6.64124C12.591 6.34835 13.0659 6.34835 13.3588 6.64124C15.2137 8.49623 15.2137 11.5038 13.3588 13.3588C13.0659 13.6516 12.591 13.6516 12.2981 13.3588C12.0052 13.0659 12.0052 12.591 12.2981 12.2981C13.5673 11.0289 13.5673 8.97111 12.2981 7.7019C12.0052 7.40901 12.0052 6.93414 12.2981 6.64124ZM11.5 10C11.5 10.8284 10.8284 11.5 10 11.5C9.17157 11.5 8.5 10.8284 8.5 10C8.5 9.17157 9.17157 8.5 10 8.5C10.8284 8.5 11.5 9.17157 11.5 10Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default LiveIcon;
