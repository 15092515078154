import { UseQueryResult, useQuery } from 'react-query';

import { ListCalendarEventResponse, getEmployeesCalendarEventsForDay } from 'src/utils/api/getEmployeeCalendarEvents';

export const useGetEmployeesCalendarEventsForDay = (
  orgId: string | null | undefined,
  employeeIds: string[],
  startTime: number,
  endTime: number,
  timezone: string | null // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
UseQueryResult<ListCalendarEventResponse, Error> => {
  return useQuery(['calendar_list_event', orgId, employeeIds, startTime, endTime, timezone], async () => {
    if (!orgId || !startTime || !employeeIds || employeeIds.length === 0) {
      return {
        org_id: orgId,
        time_max: new Date(startTime).toISOString(),
        time_min: new Date(endTime).toISOString(),
        calendars: [],
      };
    }
    const response = await getEmployeesCalendarEventsForDay(orgId, employeeIds, startTime, endTime, timezone);
    return response.data;
  });
};
