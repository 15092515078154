import React, { FC, useLayoutEffect, useMemo, useState } from 'react';

import { Box, Dialog, DialogProps, Slide } from '@mui/material';

import { BaseProps } from 'src/components/types';

import usePrevious from 'src/hooks/usePrevious';

export { default as DialogContainerHeader } from './DialogContainerHeader';
export type { DialogContainerHeaderProps } from './DialogContainerHeader';
export { default as DialogContainerContent } from './DialogContainerContent';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type DialogContainerProps = BaseProps & {
  open: boolean;
  height?: number;
  width?: number;
  currentPage?: number;
  maxWidth?: DialogProps['maxWidth'];
  onClose?: (event: React.MouseEvent) => void;
  disableBackdropClick?: boolean;

  // Prop to avoid focus race condition in case a number text field is used inside a popover in a modal.
  disableEnforceFocus?: boolean;
};

const TIMEOUT = 250;

const useSxProps = () => {
  return useMemo(() => {
    return {
      paper: {
        width: '600px',
        maxHeight: '90vh',
        overflow: 'auto',
        border: 'none',
      },
    };
  }, []);
};

/**
 * @deprecated - use `Dialog` from `components/Dialog` instead
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const DialogContainer: FC<DialogContainerProps> = ({
  open,
  height,
  width,
  currentPage,
  children,
  onClose,
  maxWidth,
  disableBackdropClick,
  dataTestId,
  disableEnforceFocus,
}): JSX.Element => {
  const sxProps = useSxProps();
  const [animate, setAnimate] = useState(false);
  const [direction, setDirection] = useState<'left' | 'right' | null>(null);
  const previousPage = usePrevious(currentPage);

  useLayoutEffect(() => {
    if (
      currentPage === undefined ||
      previousPage === null ||
      previousPage === undefined ||
      previousPage === currentPage
    )
      return;
    setAnimate(true);

    if (previousPage < currentPage) {
      setDirection('left');
    } else {
      setDirection('right');
    }
  }, [currentPage, previousPage]);

  const handleClose = (event: React.MouseEvent) => {
    setDirection(null);
    if (disableBackdropClick) return;
    if (onClose) onClose(event);
  };

  const handleTransitionComplete = () => {
    setAnimate(false);
  };

  const PaperProps: DialogProps['PaperProps'] = {
    sx: sxProps.paper,
    style: { overflow: 'hidden', width, height },
  };

  if (maxWidth && PaperProps.style) {
    PaperProps.style.maxWidth = maxWidth;
  } else if (width && PaperProps.style) {
    PaperProps.style.maxWidth = width;
  }

  // `currentPage === undefined` means we are not using navigation
  if (currentPage === undefined) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        disableScrollLock
        maxWidth={maxWidth}
        PaperProps={PaperProps}
        data-testid={dataTestId}
        disableEnforceFocus={disableEnforceFocus}
      >
        {children}
      </Dialog>
    );
  }

  return (
    <Dialog
      disableEnforceFocus={disableEnforceFocus}
      open={open}
      onClose={handleClose}
      disableScrollLock
      maxWidth={maxWidth}
      PaperProps={PaperProps}
      data-testid={dataTestId}
    >
      {animate && direction && previousPage !== null && (
        <Box style={{ overflow: 'hidden', width, height }}>
          <Slide
            in
            direction={direction}
            timeout={TIMEOUT}
            mountOnEnter
            unmountOnExit
            onEntered={handleTransitionComplete}
          >
            <Box>{children}</Box>
          </Slide>
        </Box>
      )}
      {(!animate || !direction) && children}
    </Dialog>
  );
};

export default DialogContainer;
