import { ModernLoopLoader } from './ModernLoopLoader';
import { Box, Theme } from '@mui/material';
import type { FC } from 'react';
import { useMemo } from 'react';

const useSxProps = () => {
  return useMemo(() => {
    return {
      root: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        minHeight: '100%',
        padding: (theme: Theme) => theme.spacing(3),
      },
    };
  }, []);
};

/**
 * @deprecated Use `UnbrandedLoadingScreen` instead.
 */

export const LoadingScreen: FC = () => {
  const sxStyle = useSxProps();
  return (
    // TODO: Fix this the next time the file is edited
    // eslint-disable-next-line no-restricted-syntax
    <Box sx={sxStyle.root}>
      <ModernLoopLoader />
    </Box>
  );
};
