import React, { FC } from 'react';

import exportFromJSON from 'export-from-json';

import { DeclineMetricesQuery } from 'src/generated/mloop-graphql';

import { DataPoint, DataPoints } from 'src/components/Charts';
import PieChart, { DataKey } from 'src/components/Charts/Pie';
import { Loader } from 'src/components/HelperComponents';
import Stack from 'src/components/Stack';
import Label from 'src/components/label';

import { ElementType } from 'src/types/utils';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import SectionWrap from '../../SectionWrap';

export enum DeclineReasons {
  conflict = 'Conflict',
  know_the_candidate = 'I know the candidate',
  out_of_office = 'Out of office',
  outside_work_hours = 'Outside work hours',
  over_limit = 'Too many interviews',
  other = 'Other',
  missed = 'Missed',
}

export const DeclineReasonsKeys: DataKey[] = [
  {
    key: 'missed',
    label: DeclineReasons.missed,
  },
  {
    key: 'outside_work_hours',
    label: DeclineReasons.outside_work_hours,
  },
  {
    key: 'out_of_office',
    label: DeclineReasons.out_of_office,
  },
  {
    key: 'conflict',
    label: DeclineReasons.conflict,
  },
  {
    key: 'over_limit',
    label: DeclineReasons.over_limit,
  },
  {
    key: 'know_the_candidate',
    label: DeclineReasons.know_the_candidate,
  },
  {
    key: 'other',
    label: DeclineReasons.other,
  },
];

interface Props {
  loading?: boolean;
  data: ElementType<
    ElementType<ElementType<DeclineMetricesQuery['interviewerMetricsDeclinesWrapper']>['declineReasonsChart']>['data']
  >;
}

const DeclineReasonsChart: FC<Props> = ({ loading, data }) => {
  const series: DataPoints =
    (data.length &&
      data.map((val) => {
        return {
          x: val?.x,
          y: val?.y,
        } as DataPoint;
      })) ||
    [];

  const onDownload = () => {
    if (!series.length) return;
    const total = series.reduce((partialSum, val) => {
      return partialSum + val.y;
    }, 0);
    const formattedData = series.map((item) => {
      return {
        Reason: DeclineReasons[item.x],
        Percentage: total ? ((item.y / total) * 100).toFixed(2) : 0,
        Value: item.y.toFixed(2),
      };
    });
    exportFromJSON({
      data: formattedData,
      fileName: 'Decline reasons',
      exportType: 'csv',
    });
  };

  const headerContent = (): JSX.Element | null => {
    if (!series.length) {
      return null;
    }

    const nonZeroValue = series.filter((a) => {
      return !!a.y;
    });

    const maxValDataPoint = nonZeroValue.reduce((a, b) => {
      return a.y > b.y ? a : b;
    });

    const maxValueDataKey = DeclineReasonsKeys.find((dataKey) => {
      return dataKey.key === maxValDataPoint?.x;
    });

    if (maxValDataPoint?.y) {
      return (
        <Stack alignItems="baseline" spacing={1}>
          <Label variant="huge" color="info">
            {maxValDataPoint.y}
          </Label>
          <Label variant="header" color="info">
            {maxValueDataKey && maxValueDataKey.label}
            {nonZeroValue.length ? `, +${nonZeroValue.length - 1} more reasons` : ''}
          </Label>
        </Stack>
      );
    }

    return null;
  };

  const getMarkup = (): JSX.Element => {
    if (loading) {
      return <Loader loading={loading} />;
    }

    return (
      <>
        {headerContent()}
        <PieChart
          series={series}
          dataKeys={DeclineReasonsKeys}
          showCustomLabels
          options={{
            plotOptions: {
              pie: {
                offsetX: -40,
                offsetY: 5,
              },
            },
          }}
          width={300}
          zeroState={{
            label: 'No decline reason for the selected date range',
            height: 240,
          }}
        />
      </>
    );
  };

  return (
    <SectionWrap header="Decline reasons" subHeader="Reasons given for declines received" onDownload={onDownload}>
      {getMarkup()}
    </SectionWrap>
  );
};

export default DeclineReasonsChart;
