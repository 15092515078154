import React, { FC } from 'react';

import { Editor } from '@modernloop/shared/components';
import { InterviewerNoteIcon } from '@modernloop/shared/icons';
import { Stack, Typography } from '@mui/material';

import { removeEscapedQuotes } from './utils';

type Props = {
  note: string;
};

const InternalInterviewerNote: FC<Props> = ({ note }) => {
  return (
    <Stack
      direction="row"
      borderRadius={1.5}
      mt={1}
      bgcolor={(theme) => theme.palette.background.info}
      alignItems="start"
      spacing={1}
      color={(theme) => theme.palette.info.contrastText}
      p={1.5}
    >
      <InterviewerNoteIcon />
      <Typography variant="body2">
        <Editor
          value={removeEscapedQuotes(note)}
          readOnly
          onChange={() => {}}
          modules={{ toolbar: false }}
          sx={{
            border: 'none',
            boxShadow: 'none',
            '& .ql-editor': {
              padding: '0',
            },
          }}
        />
      </Typography>
    </Stack>
  );
};

export default InternalInterviewerNote;
