import React, { useCallback } from 'react';

import { gql } from '@apollo/client';
import { getExternalErrorMessage } from '@modernloop/shared/utils';
import { Box, Stack, SxProps } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  EmployeeFragment,
  EmployeeFragmentDoc,
  ScheduleTaskAssignee_TaskFragment,
  SourceType,
  useTaskSetAssigneeMutation,
} from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import SelectButton from 'src/components/SelectButton';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';
import { FCWithFragments } from 'src/components/types';

import { useSubscribersSelectRefetch } from 'src/entities/SubscribersSelect/useSubscribersSelectRefetch';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';

import { Theme } from 'src/themeMui5/type';

import AssigneeSelect from 'src/views-new/ScheduleTask/ScheduleTaskDetails/Common/ScheduleTaskAssignee/AssigneeSelect';
import useMySchedulingTasksRefetch from 'src/views-new/ScheduleTaskDashboard/ScheduleTaskDashboardTables/useMySchedulingTasksRefetch';
import useScheduleTaskDashboardTaskSearchRefetch from 'src/views-new/ScheduleTaskDashboard/ScheduleTaskDashboardTables/useScheduleTaskDashboardTaskSearchRefetch';

export const TaskSetAsigneeMutation = gql`
  ${EmployeeFragmentDoc}
  mutation TaskSetAssignee($input: TaskSetAssigneeInput!) {
    taskSetAssignee(input: $input) {
      id
      assignee {
        ...Employee
      }
    }
  }
`;

interface Fragments {
  task: ScheduleTaskAssignee_TaskFragment;
}

interface Props {
  sx?: SxProps<Theme>;
  onClick?: (e) => void;
  shouldRefetchSubscribersList?: boolean;
  shouldRefecthTaskSearch?: boolean;
  shouldRefetchMySchedulingTasks?: boolean;
}

const ScheduleTaskAssignee: FCWithFragments<Fragments, Props> = ({
  sx,
  task,
  onClick,
  shouldRefetchSubscribersList,
  shouldRefecthTaskSearch,
  shouldRefetchMySchedulingTasks,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const refetchActivityFeed = useActivityFeedRefetch(SourceType.SourceTypeTask);
  const refetchSubscriberSelect = useSubscribersSelectRefetch();
  const refetchScheduleTaskDashboardTaskSearch = useScheduleTaskDashboardTaskSearchRefetch();
  const refetchMySchedulingTasks = useMySchedulingTasksRefetch();
  const [taskSetAssigneeMutation] = useTaskSetAssigneeMutation();

  const handleAssigneeChange = useCallback(
    (assignee: EmployeeFragment) => {
      taskSetAssigneeMutation({
        variables: {
          input: {
            taskId: task.id,
            assigneeEmployeeId: assignee.id,
          },
        },
        optimisticResponse: {
          taskSetAssignee: {
            __typename: 'Task',
            id: task.id,
            assignee: assignee.id === null ? null : assignee,
          },
        },
      })
        .then(() => {
          if (shouldRefetchSubscribersList) {
            refetchSubscriberSelect();
          }
          if (shouldRefetchMySchedulingTasks) {
            refetchMySchedulingTasks();
          }
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line promise/always-return
          if (shouldRefecthTaskSearch) {
            refetchScheduleTaskDashboardTaskSearch();
          }
          refetchActivityFeed();
        })
        .catch((e) => {
          enqueueSnackbar(getExternalErrorMessage(e), { variant: 'error' });
        });
    },
    [
      enqueueSnackbar,
      refetchActivityFeed,
      refetchMySchedulingTasks,
      refetchScheduleTaskDashboardTaskSearch,
      refetchSubscriberSelect,
      shouldRefecthTaskSearch,
      shouldRefetchMySchedulingTasks,
      shouldRefetchSubscribersList,
      task.id,
      taskSetAssigneeMutation,
    ]
  );

  return (
    <Box sx={{ ...(sx ?? {}), width: 'max-content' }} onClick={onClick}>
      <AssigneeSelect
        onChange={(newMember) => handleAssigneeChange(newMember?.employee as EmployeeFragment)}
        getLabel={() => {
          let label;
          let startIcon;

          if (!task.assignee) {
            label = 'Unassigned';
            startIcon = 'UnknownUserIcon';
          } else {
            label = (
              <Stack direction="row" spacing={1}>
                <Box
                  sx={{
                    marginLeft: '-4px',
                  }}
                >
                  <Avatar alt={task.assignee.fullName ?? ''} src={task.assignee.slackImageUrl ?? undefined} />
                </Box>

                <Box
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <MaybeTooltip label={task.assignee.fullName ?? ''} tooltip={task.assignee.fullName ?? ''} />
                </Box>
              </Stack>
            );
          }

          return <SelectButton variant="unstyled" startIcon={startIcon} label={label} size="medium" />;
        }}
      />
    </Box>
  );
};

ScheduleTaskAssignee.fragments = {
  task: gql`
    fragment ScheduleTaskAssignee_task on Task {
      id
      assignee {
        id
        fullName
        slackImageUrl
      }
    }
  `,
};

export default ScheduleTaskAssignee;
