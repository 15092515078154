import React, { useCallback, useState } from 'react';

import { Box, CircularProgress, Stack } from '@mui/material';

import { LinkIcon, LockIcon } from 'src/components/icons';
import Label from 'src/components/label';

import { useCreateLocation } from 'src/hooks/amplitude/useCreateLocationHeader';
import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';
import useShortUrlCreate from 'src/hooks/useShortUrlCreate';

import Dialog from '../Dialog';

export enum CreateType {
  CANDIDATE_AVAILABILITY = 'CANDIDATE_AVAILABILITY',
  SELF_SCHEDULE = 'SELF_SCHEDULE',
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ShareDialogProps = {
  longUrl: string;
  content: string;
  onClose: () => void;
  onSendEmail: () => void;
  type?: CreateType;
};

const ShareDialog = ({ longUrl, content, onClose, onSendEmail, type }: ShareDialogProps) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const createLocation = useCreateLocation();
  const { logEvent } = useLogEvent();

  const { data, loading: shortUrlLoading } = useShortUrlCreate(longUrl);
  const loading = shortUrlLoading;

  const handleCopyLinkClick = useCallback(() => {
    navigator.clipboard.writeText(data?.shortUrlCreate?.shortUrl || longUrl);
    logEvent(LoggerEvent.CLIENT_SHARE_DIALOG_COPY_LINK, {
      type,
      location: createLocation,
    });
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 5000);
  }, [data, longUrl, logEvent, type, createLocation]);

  return (
    <Dialog
      dataTestId="share-dialog"
      decoration={
        <img style={{ width: '64px', height: '64px' }} src="/static/images/scheduling/share_64.png" alt="Share link" />
      }
      title="Share link"
      subTitle={content}
      isOpen
      onClose={onClose}
      submitOptions={{
        onClick: () => {
          onSendEmail();
        },
        label: 'Share via email',
      }}
      secondaryOptions={{
        onClick: () => {
          handleCopyLinkClick();
        },
        label: copySuccess ? 'Copied' : 'Copy link',
        startIcon: copySuccess ? 'SuccessIcon' : 'CopyIcon',
      }}
    >
      <Stack spacing={2}>
        <Label icon={<LockIcon />} fontWeight={400} variant="body">
          Anyone with this link can make changes.
        </Label>
        {!loading && (
          <Label icon={<LinkIcon />} style={{ flexGrow: 1 }} fontWeight={400} variant="body">
            {data?.shortUrlCreate?.shortUrl || longUrl}
          </Label>
        )}
        {loading && (
          <Box sx={{ flexGrow: 1 }}>
            <CircularProgress size={16} />
          </Box>
        )}
      </Stack>
    </Dialog>
  );
};

export default ShareDialog;
