import React, { FC } from 'react';

import { Box, Theme, Tooltip } from '@mui/material';

type Props = {
  title: React.ReactNode;
};

export const HoverPopover: FC<Props> = ({ children, title }) => {
  return (
    <Tooltip
      title={title}
      componentsProps={
        React.isValidElement(title)
          ? {
              tooltip: {
                sx: {
                  backgroundColor: (theme: Theme) => theme.palette.background.contrast,
                  boxShadow: (theme: Theme) => theme.shadows[10],
                },
              },
            }
          : undefined
      }
    >
      {React.isValidElement(children) ? children : <Box>{children}</Box>}
    </Tooltip>
  );
};
