import { TaskFlagType } from 'src/generated/mloop-graphql';

export const getTaskFlagLabel = (flagType: TaskFlagType) => {
  switch (flagType) {
    case TaskFlagType.CandidateDeclined:
      return 'Candidate declined';
    case TaskFlagType.InterviewDeclined:
      return 'Interviewer declined';
    case TaskFlagType.RequestChange:
      return 'Change requested';
    case TaskFlagType.DebriefDeclined:
      return 'Debrief declined';
    default:
      return '';
  }
};
