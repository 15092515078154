import { useIframeState } from '../Iframe/IframeStateContext';
import DialogActions, { Props as DialogActionsProps } from './DialogActions';
import DialogMoreMenu, { Props as DialogMoreMenuProps } from './DialogMoreMenu';
import DialogTitle, { Props as DialogTitleProps } from './DialogTitle';
import { BaseProps } from '@modernloop/shared/components';
import { useIsMobile } from '@modernloop/shared/hooks';
import { DialogContent, Dialog as MUIDialog, DialogProps as MUIDialogProps } from '@mui/material';
import React, { FC } from 'react';

export type Props = BaseProps &
  DialogActionsProps &
  DialogMoreMenuProps &
  DialogTitleProps & {
    /**
     * Whether the dialog is open or not
     */
    open: boolean;

    /**
     * Callback when the dialog is closed from the top right X icon
     */
    onClose?: () => void;

    /**
     * The content of the dialog
     * Can be a string or a JSX element
     * If it's a string, it will be rendered as a Label
     */
    children: React.ReactNode | React.ReactNode[];

    maxWidth?: MUIDialogProps['maxWidth'];
    enableEscapeClose?: boolean;
    enableBackdropClickClose?: boolean;
  };

/**
 * ModernLoop dialog compoenent that renders main content
 * with sticky top right header actions and a sticky bottom actions
 *
 * This dialog will automatically go full screen on mobile and screen sizes less than 484px
 *
 * This component is meant to be more rigid than the MUI Dialog component so
 * it's easier to quickly build dialogs with the same look and feel
 */

// eslint-disable-next-line modernloop/validate-component-definition.cjs
export const Dialog: FC<Props> = ({
  open,
  onClose,
  submitOptions,
  secondaryOptions,
  cancelOptions,
  actionButtons,
  decoration,
  title,
  subTitle,
  maxWidth,
  children,
  dataTestId,
  enableEscapeClose,
  enableBackdropClickClose,
}): JSX.Element => {
  const isMobile = useIsMobile();
  const { iframeWindow } = useIframeState();
  const container = iframeWindow?.document.body;
  /**
   * By default, only the cross icon can close the modal.
   */
  const handleClose = (_e: unknown, reason: string) => {
    if (!enableEscapeClose && reason === 'escapeKeyDown') {
      return;
    }
    if (!enableBackdropClickClose && reason === 'backdropClick') {
      return;
    }
    onClose && onClose();
  };

  return (
    <MUIDialog
      container={container}
      data-testid={dataTestId}
      fullScreen={isMobile}
      disableScrollLock
      scroll="paper"
      open={open}
      maxWidth={maxWidth}
      onClose={handleClose}
    >
      <DialogMoreMenu actionButtons={actionButtons} onClose={onClose} />
      <DialogContent
        // eslint-disable-next-line no-restricted-syntax
        sx={{
          flexGrow: 0,
        }}
      >
        <DialogTitle title={title} subTitle={subTitle} decoration={decoration} />
        {children}
      </DialogContent>
      <DialogActions cancelOptions={cancelOptions} secondaryOptions={secondaryOptions} submitOptions={submitOptions} />
    </MUIDialog>
  );
};

Dialog.defaultProps = {
  enableEscapeClose: false,
  enableBackdropClickClose: false,
};
