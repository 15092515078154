import { gql } from '@apollo/client';

import {
  RecentlyUsedZoomUsersEmployeePrefFragment,
  RecentlyUsedZoomUsersEmployeePrefQuery,
  ZoomUserFragmentFragmentDoc,
  useRecentlyUsedZoomUsersEmployeePrefQuery,
} from 'src/generated/mloop-graphql';

import { EmployeePrefName } from 'src/utils/api/employee/constants';

const useRecentlyUsedZoomUsersEmployeePref = (
  onCompleted: (data: RecentlyUsedZoomUsersEmployeePrefQuery) => void
): RecentlyUsedZoomUsersEmployeePrefFragment | undefined => {
  const { data } = useRecentlyUsedZoomUsersEmployeePrefQuery({
    variables: { prefName: EmployeePrefName.OBJ_RECENTLY_USED_ZOOM_USERS },
    onCompleted,
  });

  if (!data?.thisEmployee) return undefined;

  const { thisEmployee } = data;

  const filterPref = thisEmployee.employeePref?.jsonPref as RecentlyUsedZoomUsersEmployeePrefFragment;

  if (!filterPref) return undefined;

  return filterPref;
};

export default useRecentlyUsedZoomUsersEmployeePref;

const RecentlyUsedZoomUsersEmployeePrefFragmentGQL = gql`
  ${ZoomUserFragmentFragmentDoc}
  fragment RecentlyUsedZoomUsersEmployeePref on RecentlyUsedZoomUsersPref {
    zoomUsers {
      ...ZoomUserFragment
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RecentlyUsedZoomUsersPrefsQuery = gql`
  ${RecentlyUsedZoomUsersEmployeePrefFragmentGQL}
  query RecentlyUsedZoomUsersEmployeePref($prefName: String!) {
    thisEmployee {
      id
      employeePref(prefName: $prefName) {
        orgId
        employeeId
        prefName
        jsonPref {
          ... on RecentlyUsedZoomUsersPref {
            ...RecentlyUsedZoomUsersEmployeePref
          }
        }
      }
    }
  }
`;
