import { useCallback } from 'react';

import { gql } from '@apollo/client';

import {
  useResetInterviewPlanToBaseJobStageLazyQuery,
  useResetPlanToBaseJobStageMutation,
} from 'src/generated/mloop-graphql';

import cloneInterviewPlan from './cloneInterviewPlan';
import getSaveInterviewPlanInput from './getSaveInterviewPlanInput';

type Props = {
  jobStageId: string;
  customJobStageId: string;
  doNotPersist?: boolean;
};

export const useResetInterviewPlanToBaseJobStage = ({ jobStageId, customJobStageId, doNotPersist }: Props) => {
  const [fetchInterviewPlan, { loading }] = useResetInterviewPlanToBaseJobStageLazyQuery({
    variables: {
      jobStageId,
    },
  });

  const [resetPlan, { loading: ld }] = useResetPlanToBaseJobStageMutation();

  const resetPlanToBaseJobStage = useCallback(async () => {
    const { data } = await fetchInterviewPlan();
    if (!data) return null;

    const { jobStage } = data;

    if (!jobStage) return null;

    // If doNotPersist is true, return the base job stage interview plan data
    if (doNotPersist) {
      return cloneInterviewPlan({ interviewPlan: jobStage }, { customJobStageId });
    }

    const input = getSaveInterviewPlanInput(
      { interviewPlan: jobStage },
      { finalJobStageId: customJobStageId, generateNewIds: true }
    );
    const result = await resetPlan({ variables: { input } });

    return result.data?.jobStageInterviewPlanUpsert?.jobStage;
  }, [fetchInterviewPlan, doNotPersist, customJobStageId, resetPlan]);

  return { resetPlan: resetPlanToBaseJobStage, isLoading: loading || ld };
};

export const ResetInterviewPlanToBaseJobStageQuery = gql`
  ${getSaveInterviewPlanInput.fragments.interviewPlan}
  query ResetInterviewPlanToBaseJobStage($jobStageId: uuid!) {
    jobStage(id: $jobStageId) {
      id
      ...getSaveInterviewPlanInput_interviewPlan
    }
  }
`;

export const ResetInterviewPlanToBaseJobStageMutation = gql`
  ${getSaveInterviewPlanInput.fragments.interviewPlan}
  mutation ResetPlanToBaseJobStage($input: JobStageInterviewPlanUpsertInput!) {
    jobStageInterviewPlanUpsert(input: $input) {
      jobStage {
        id
        ...getSaveInterviewPlanInput_interviewPlan
      }
    }
  }
`;
