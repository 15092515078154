import React from 'react';
import type { FC } from 'react';
import { Redirect } from 'react-router';

import { Routes } from 'src/constants/routes';

import useOrgId from 'src/hooks/useOrgId';

/**
 * Guard that only allows users without an org to access the children components
 * Note: Guards should check only one thing. Don't add any other checks to this Guard.
 */
const UserHasNoOrgGuard: FC = ({ children }) => {
  const orgId = useOrgId();

  if (orgId) {
    return <Redirect to={Routes.MySchedulingTasks} />;
  }

  return <>{children}</>;
};

export default UserHasNoOrgGuard;
