import { EyeDropperIcon } from '@modernloop/shared/icons';
import { Alert, Box, IconButton, InputAdornment, Popover, Stack, TextField, decomposeColor } from '@mui/material';
import { styled } from '@mui/material/styles';
import { get } from 'lodash';
import { FC, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

const DEFAULT_COLOR = ['#1160D4', '#B146BE', '#0E8E8A', '#E27121'];

type Props = {
  color: string;
  onChange: (color: string) => void;
  customErrorMessage?: string;
};

const Picker = styled(HexColorPicker)`
  .react-colorful__hue-pointer,
  .react-colorful__pointer {
    width: 14px;
    height: 14px;
    border-radius: '50%';
  }
  .react-colorful__hue {
    height: 12px;
    border-radius: 8px;
    margin: 10px 0px;
  }
  .react-colorful__saturation {
    border-radius: 8px;
  }
`;

const ColorPicker: FC<Props> = ({ color, onChange, customErrorMessage }) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef<HTMLInputElement | null>(null);
  const [errorMessage, setErrorMessage] = useState(customErrorMessage);

  const handleChange = (color: string) => {
    validateColor(color);
    onChange(color);
  };
  const EyeDropper = get(window, 'EyeDropper');

  const handleEyeDropperClick = () => {
    if (!EyeDropper) {
      return;
    }

    const eyeDropper = new EyeDropper();

    eyeDropper
      .open()
      // eslint-disable-next-line promise/always-return
      .then((result: { sRGBHex: string }) => {
        onChange(result.sRGBHex);
      })
      .catch(() => {
        console.log('EyeDropper is not supported');
      });
  };

  const validateColor = (color: string) => {
    setErrorMessage('');
    try {
      decomposeColor(color);
      if (!CSS.supports('color', color)) {
        throw new Error('Invalid color');
      }
    } catch (e) {
      setErrorMessage('Please enter a valid color value or use the selector to choose a color');
    }
  };

  return (
    <Stack spacing={1}>
      <TextField
        type="text"
        variant="outlined"
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        size="medium"
        placeholder="Select color"
        value={color}
        ref={anchorEl}
        onClick={() => setOpen(!open)}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box width={20} height={20} bgcolor={color} borderRadius="4px" />
            </InputAdornment>
          ),
        }}
        error={Boolean(errorMessage)}
      />
      {errorMessage && (
        <Alert
          severity="error"
          // eslint-disable-next-line no-restricted-syntax
          sx={{ whiteSpace: 'pre-line' }}
        >
          {errorMessage}
        </Alert>
      )}

      <Popover
        anchorEl={anchorEl.current}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box display="flex" justifyContent="center" padding={2}>
          <Stack spacing={0.5}>
            <Picker color={color} onChange={handleChange} />
            <Stack direction="row" justifyContent="space-between">
              {DEFAULT_COLOR.map((color) => (
                <Box
                  key={color}
                  width={30}
                  height={30}
                  borderRadius={15}
                  bgcolor={color}
                  onClick={() => {
                    handleChange(color);
                    setOpen(false);
                  }}
                />
              ))}
              {EyeDropper && (
                <IconButton onClick={handleEyeDropperClick}>
                  <EyeDropperIcon />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </Stack>
  );
};

export default ColorPicker;
