import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskScheduleCreated_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';

interface Fragments {
  activityLog: ActivityLogTaskScheduleCreated_ActivityLogFragment;
}

const ActivityLogTaskScheduleCreated: FCWithFragments<Fragments> = ({ activityLog }) => {
  return <ActivityLogBase activityLog={activityLog} actionLabel="created the schedule" />;
};

ActivityLogTaskScheduleCreated.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskScheduleCreated_activityLog on ActivityLog {
      id

      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskScheduleCreated;
