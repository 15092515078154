import React from 'react';

import { gql } from '@apollo/client';

import { IntegrationType, useDirectoryInfoQuery } from 'src/generated/mloop-graphql';

import { GoogleIcon, MicrosoftIcon } from 'src/components/icons';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DirectoryInfoQuery = gql`
  query DirectoryInfo {
    thisOrg {
      id
      integrations {
        google {
          adminUserId
        }
        microsoft {
          active
        }
      }
    }
  }
`;

// Do not export this instead use IntegrationType.
// Using this only for type safety so that we don't assign unsupported IntegrationType's as type.
type DirectoryInfoTypeDONOTEXPORT = IntegrationType.Google | IntegrationType.Microsoft;

type DirectoryInfo = {
  type?: DirectoryInfoTypeDONOTEXPORT;
  label?: string;
  loading: boolean;
  icon?: JSX.Element;
  calendarName?: string;
};

const useDirectoryInfo = (): DirectoryInfo => {
  const { data, loading } = useDirectoryInfoQuery();

  let type: DirectoryInfoTypeDONOTEXPORT | undefined;
  let label: string | undefined;
  let calendarName: string | undefined;
  let icon: JSX.Element | undefined;
  if (data?.thisOrg?.integrations?.google?.adminUserId) {
    type = IntegrationType.Google;
    label = 'Google';
    calendarName = 'Google Calendar';
    icon = <GoogleIcon />;
  } else if (data?.thisOrg?.integrations?.microsoft?.active) {
    type = IntegrationType.Microsoft;
    label = 'Microsoft';
    calendarName = 'Outlook Calendar';
    icon = <MicrosoftIcon />;
  }

  return {
    type,
    label,
    loading,
    icon,
    calendarName,
  };
};

export const getDirectoryIcon = (type?: IntegrationType) =>
  type === IntegrationType.Microsoft ? <MicrosoftIcon /> : <GoogleIcon />;

export default useDirectoryInfo;
