import React from 'react';

import BaseIcon, { Props } from './Icon';

const CheckIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        d="M4.5 10C6.19438 11.3378 7.07682 12.2023 8.5 14C11.3692 10.4943 13.011 8.8486 16 6.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default CheckIcon;
