const SLACK_CHANNEL_MAX_LENGTH = 75;

const getCleanSlackChannelName = (channelName: string): string => {
  return channelName
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/\./g, '-')
    .replace(/[,/#@#?+|"'!$%^&*;:{}=`~()]/g, '')
    .slice(0, SLACK_CHANNEL_MAX_LENGTH);
};

export const getCleanSlackChannelFormat = (channelName: string): string => {
  return channelName
    .replace(/\s/g, '-')
    .replace(/\./g, '-')
    .replace(/[,/#@#?+|"'!$%^&*;:=`~()]/g, '');
};

export default getCleanSlackChannelName;
