import React, { FC } from 'react';
import { Redirect } from 'react-router';

import { gql } from '@apollo/client';
import { LoadingScreen } from '@modernloop/shared/helper-components';

import { useUserTermsOfServiceQuery } from 'src/interviewer-portal/generated/graphql';

/**
 * Guard that checks if the user has completed the terms of service agreement.
 * Note: Guards should check only one thing. Don't add any other checks to this Guard.
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TOS_QUERY = gql`
  query UserTermsOfService {
    thisInterviewer {
      id
      tosAcceptedAt
    }
  }
`;

const IPUserHasAcceptedTOSGuard: FC = ({ children }) => {
  const { data, loading } = useUserTermsOfServiceQuery({
    fetchPolicy: 'cache-and-network',
  });

  const hasAcceptedTOS = !!data?.thisInterviewer?.tosAcceptedAt;

  if (loading) {
    return <LoadingScreen />;
  }

  if (!hasAcceptedTOS) {
    return <Redirect to="/ip/terms-and-privacy" />;
  }

  // Don't add components here, please add components either on the app or on the appropriate Layout component
  return <>{children}</>;
};

export default IPUserHasAcceptedTOSGuard;
