import { SvgIcon, SvgIconProps } from './SvgIcon';

export const TraineeIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.07234 4.54044L9.60609 2.16884C9.85764 2.06103 10.1424 2.06103 10.3939 2.16884L15.9277 4.54044C16.3317 4.71361 16.3317 5.28642 15.9277 5.45959L10.197 7.9156C10.0712 7.96951 9.92882 7.96951 9.80305 7.9156L4.07234 5.45959C3.66829 5.28642 3.66829 4.71361 4.07234 4.54044ZM7.00001 8.00001V7.80227L9.21217 8.75034C9.71527 8.96596 10.2847 8.96596 10.7878 8.75034L13 7.80227V8.00001C13 9.65686 11.6569 11 10 11C8.34315 11 7.00001 9.65686 7.00001 8.00001ZM10 12.25C12.3208 12.25 14.9331 13.33 15.5984 15.8155C15.7432 16.3563 15.5717 16.8642 15.2482 17.214C14.9345 17.5532 14.4785 17.75 13.9998 17.75L6.0002 17.75C5.52155 17.75 5.06555 17.5532 4.75184 17.214C4.42835 16.8641 4.25682 16.3563 4.40159 15.8155C5.06687 13.33 7.67918 12.25 10 12.25ZM15.25 8.00003C15.25 7.58582 14.9142 7.25003 14.5 7.25003C14.0858 7.25003 13.75 7.58582 13.75 8.00003V10C13.75 10.4142 14.0858 10.75 14.5 10.75C14.9142 10.75 15.25 10.4142 15.25 10V8.00003Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
