/* eslint-disable max-lines */
import React, { useState } from 'react';
import { useParams } from 'react-router';

import { useFlag } from '@modernloop/shared/feature-flag';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import {
  DynamicAudience,
  EmployeeFragment,
  NotificationPreferenceInput,
  TemplateType,
} from 'src/generated/mloop-graphql';

import { MaxDuration } from 'src/components/menu/DurationMenu';
import useEqualSizeSxProps from 'src/components/utils/useEqualSizeSxProps';

import EmployeePickerExtended from 'src/entities/EmployeePicker/EmployeePickerExtended';
import SmartEmployeeSelect from 'src/entities/Select/SmartEmployeeSelect';
import TemplateSelect from 'src/entities/Template/TemplateSelect';

import { CandidateAvailabilityOptions } from 'src/store/slices/candidate-availability-options';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import {
  DAY_CALC_FROM_INTERVIEW_PLAN,
  DURATION_CALC_FROM_INTERVIEW_PLAN,
} from 'src/views-new/JobDetailsPage/OrgSelfScheduleOptions';
import { KeywordTooltip } from 'src/views-new/SelfSchedule/KeywordTooltip';
import RequiredAdvanceNotice from 'src/views-new/SelfSchedule/RequiredAdvanceNotice';

import CandidateAvailabilityOptionsLabel, { getFormattedDuration } from './CandidateAvailabilityOptionsLabel';
import DayCountPicker, { DayCount } from './DayCountPicker';
import DurationPicker from './DurationPicker';
import { AdvanceNoticeHours } from './RequiresAdvanceNoticeOptions';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface CandidateAvailabilityOptionsPreferencesProps {
  options: CandidateAvailabilityOptions;
  candidateTimezone?: string;
  roundedMinsPerDay: number;
  disableToggles?: boolean;
  onNumberOfDaysChange: (value: DayCount) => void;
  onMinutesPerDayChange: (mins: number) => void;
  onMinTimeBlockMinutesChange: (mins: number) => void;
  onAdvanceNoticeHoursChange: (value: AdvanceNoticeHours) => void;
  onCandidateNoteBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onScheduleOverFreeTimeChange: (value: boolean) => void;
  onScheduleOverRecruitingKeywordsChange: (value: boolean) => void;
  onScheduleOverAvailableKeywordsChange: (value: boolean) => void;
  onRespectLoadLimitChange: (value: boolean) => void;
  alertJsx?: JSX.Element | null | boolean;
  availabilityTemplateId?: string;
  onAvailabilityRequestTemplateChange?: (templateId?: string) => void;
  allowUnsettingOptions?: boolean;
  ccRecipients?: NotificationPreferenceInput | null;
  bccRecipients?: NotificationPreferenceInput | null;
  onCcRecipientsChange?: (ccs: NotificationPreferenceInput) => void;
  onBccRecipientsChange?: (bccs: NotificationPreferenceInput) => void;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
export function CandidateAvailabilityOptionsPreferences({
  options,
  candidateTimezone,
  disableToggles,
  roundedMinsPerDay,
  onNumberOfDaysChange,
  onMinutesPerDayChange,
  onMinTimeBlockMinutesChange,
  onAdvanceNoticeHoursChange,
  onCandidateNoteBlur,
  onRespectLoadLimitChange,
  onScheduleOverFreeTimeChange,
  onScheduleOverRecruitingKeywordsChange,
  onScheduleOverAvailableKeywordsChange,
  availabilityTemplateId,
  onAvailabilityRequestTemplateChange,
  allowUnsettingOptions,
  alertJsx,
  ccRecipients,
  bccRecipients,
  onCcRecipientsChange,
  onBccRecipientsChange,
}: CandidateAvailabilityOptionsPreferencesProps) {
  const equalSizeSxProps = useEqualSizeSxProps();
  const smartOptionsEnabled = useFlag('user_employee_smart_options');

  const [showBccBtn, setShowBccButton] = useState(false);
  const { jobID } = useParams<{ jobID: string }>();

  const suggestedOptionsOn =
    options.canScheduleOverAvailableKeywords ||
    options.canScheduleOverRecruitingKeywords ||
    options.canScheduleOverFreeTime;

  return (
    <ConditionalThemeProvider>
      <Stack direction="column" useFlexGap spacing={1} sx={{ overflowY: 'auto', overflowX: 'hidden', padding: '1px' }}>
        <>
          <Typography variant="subtitle1">Preferences</Typography>

          <KeywordTooltip
            title="Free times"
            description="Empty space in an interviewer’s calendar or events where the status is set to free (not busy)."
          >
            <FormControlLabel
              sx={{
                justifyContent: 'space-between',
              }}
              disabled={disableToggles}
              control={
                <Switch
                  checked={!!options.canScheduleOverFreeTime}
                  onChange={() => onScheduleOverFreeTimeChange(!options.canScheduleOverFreeTime)}
                />
              }
              labelPlacement="start"
              label="Suggest times over free time"
            />
          </KeywordTooltip>

          <KeywordTooltip
            title="Recruiting blocks"
            description="Priority interviewing time! Interviewers are expected to move other events if needed. We’ll schedule over any other non-interview event during this time that isn’t marked with an Avoid keyword or Out Of Office."
          >
            <FormControlLabel
              sx={{
                justifyContent: 'space-between',
              }}
              disabled={disableToggles}
              control={
                <Switch
                  checked={!!options.canScheduleOverRecruitingKeywords}
                  onChange={() => onScheduleOverRecruitingKeywordsChange(!options.canScheduleOverRecruitingKeywords)}
                />
              }
              labelPlacement="start"
              label="Suggest times over recruiting block keywords"
            />
          </KeywordTooltip>

          <KeywordTooltip
            title="Available"
            description="Events with these keywords are treated as safe to schedule over."
          >
            <FormControlLabel
              sx={{
                justifyContent: 'space-between',
              }}
              disabled={disableToggles}
              control={
                <Switch
                  checked={!!options.canScheduleOverAvailableKeywords}
                  onChange={() => onScheduleOverAvailableKeywordsChange(!options.canScheduleOverAvailableKeywords)}
                />
              }
              labelPlacement="start"
              label="Suggest times over available keywords"
            />
          </KeywordTooltip>

          {suggestedOptionsOn && (
            <KeywordTooltip
              title="Load limits"
              description="The number of events or hours an interviewer can dedicate to interviewing in a day or week."
            >
              <FormControlLabel
                sx={{
                  justifyContent: 'space-between',
                }}
                disabled={disableToggles}
                control={
                  <Switch
                    checked={!options.shouldRespectLoadLimit}
                    onChange={() => onRespectLoadLimitChange(!options.shouldRespectLoadLimit)}
                  />
                }
                labelPlacement="start"
                label="Ignore interviewer load limits"
              />
            </KeywordTooltip>
          )}

          <RequiredAdvanceNotice
            value={
              (options.advanceNoticeMinutes !== undefined
                ? options.advanceNoticeMinutes / 60
                : 24) as AdvanceNoticeHours
            }
            onChange={onAdvanceNoticeHoursChange}
          />
        </>

        {alertJsx}

        <Stack direction="column" spacing={2}>
          <Typography variant="subtitle1">How much availability should be provided?</Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={equalSizeSxProps.equalSizeChild}
          >
            <Box>
              <Typography>Days</Typography>
              <Typography color="text.secondary" variant="body2">
                How many days of options the candidate should provide (excluding weekends)
              </Typography>
            </Box>
            <DayCountPicker
              additionalOptions={
                allowUnsettingOptions
                  ? [{ id: DAY_CALC_FROM_INTERVIEW_PLAN, value: 'Calculate from interview plan' }]
                  : []
              }
              days={options.numberOfDays as DayCount}
              onChange={onNumberOfDaysChange}
            />
          </Stack>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={equalSizeSxProps.equalSizeChild}
          >
            <Box>
              <Typography>Availability per day</Typography>
              <Typography color="text.secondary" variant="body2">
                Total hours needed each day
              </Typography>
            </Box>
            <DurationPicker
              dataTestId="availability-per-day-picker"
              additionalOptions={
                allowUnsettingOptions
                  ? [{ id: DURATION_CALC_FROM_INTERVIEW_PLAN, value: 'Calculate from interview plan' }]
                  : []
              }
              duration={options.minutesPerDays}
              onChange={onMinutesPerDayChange}
            />
          </Stack>

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            sx={equalSizeSxProps.equalSizeChild}
          >
            <Box>
              <Typography>Time blocks</Typography>
              <Typography color="text.secondary" variant="body2">
                The minimum block of time a candidate should submit
              </Typography>
            </Box>
            <DurationPicker
              dataTestId="time-block-picker"
              additionalOptions={
                allowUnsettingOptions
                  ? [{ id: DURATION_CALC_FROM_INTERVIEW_PLAN, value: 'Calculate from interview plan' }]
                  : []
              }
              duration={options.minimumTimeBlockMinutes}
              maxDuration={options.minutesPerDays as MaxDuration}
              onChange={onMinTimeBlockMinutesChange}
            />
          </Stack>
        </Stack>

        <Paper
          sx={{
            backgroundColor: (theme) => theme.palette.background.contrast,
          }}
        >
          <Box p={1}>
            <Typography variant="subtitle1">Request summary</Typography>
            <CandidateAvailabilityOptionsLabel
              options={options}
              candidateTimezone={candidateTimezone}
              roundedMinsPerDay={roundedMinsPerDay}
            />
          </Box>
        </Paper>

        {roundedMinsPerDay !== options.minutesPerDays && (
          <Stack spacing={2}>
            <Alert icon={false} severity="warning">
              {`*Availability per day is being rounded up to ${getFormattedDuration(
                roundedMinsPerDay
              )} to fit within the minimum time blocks.`}
            </Alert>
          </Stack>
        )}

        <Divider />

        <Stack spacing={1}>
          <Typography>
            <strong style={{ fontWeight: 600 }}>Note to the candidate</strong> (optional)
          </Typography>
          <TextField
            fullWidth
            multiline
            maxRows={4}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line max-lines
            minRows={4}
            defaultValue={options.candidateNote}
            placeholder="Add additional instructions for the candidate"
            onBlur={onCandidateNoteBlur}
          />
        </Stack>

        {onAvailabilityRequestTemplateChange && (
          <Stack direction="column" spacing={1}>
            <Typography variant="subtitle1">Request email</Typography>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              sx={equalSizeSxProps.equalSizeChild}
            >
              <Typography>Template</Typography>
              <TemplateSelect
                hideTitle
                showOrgDefaultOption
                useSelectButton
                selectedTemplateId={availabilityTemplateId}
                onSelect={(value) => onAvailabilityRequestTemplateChange(value)}
                types={[TemplateType.CandidateAvailabilityRequest]}
              />
            </Stack>
          </Stack>
        )}
        {onCcRecipientsChange && onBccRecipientsChange && (
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            marginTop={2}
            justifyContent="space-between"
            spacing={2}
            sx={equalSizeSxProps.equalSizeChild}
          >
            <Typography>Additional recipients</Typography>
            <Stack direction="column" alignItems="start" spacing={1}>
              <Stack width="100%">
                {smartOptionsEnabled && (
                  <SmartEmployeeSelect
                    label="CC"
                    placeholder="Add names or emails"
                    context={{
                      task: true,
                      application: true,
                      job: jobID,
                      enableOptionsWithMissingData: true,
                    }}
                    filterDynamicAudiences={[DynamicAudience.DebriefAttendees]}
                    onChange={({ employees, freeformEmails, dynamicAudiences }) => {
                      const ids = employees.map((e) => e.id);
                      onCcRecipientsChange({
                        employeeIds: ids,
                        externalEmailAddresses: freeformEmails,
                        dynamicAudiences,
                      });
                    }}
                    selectedFreeformEmails={ccRecipients?.externalEmailAddresses || []}
                    selectedEmployeeIds={ccRecipients?.employeeIds || []}
                    selectedDynamicAudiences={ccRecipients?.dynamicAudiences || []}
                  />
                )}
              </Stack>
              {!smartOptionsEnabled && (
                <EmployeePickerExtended
                  label="CC"
                  placeholder="Add names or emails"
                  dynamicAudiencesEnabled={false}
                  freeformEnabled
                  freeformEmails={ccRecipients?.externalEmailAddresses || []}
                  selectedEmployeeIds={ccRecipients?.employeeIds || []}
                  onChange={(selectedEmployees: EmployeeFragment[], freeformEmails: string[]) => {
                    const ids = selectedEmployees.map((e) => e.id);
                    onCcRecipientsChange({
                      employeeIds: ids,
                      externalEmailAddresses: freeformEmails,
                    });
                  }}
                />
              )}
              {!showBccBtn && !bccRecipients && (
                <Button variant="text" color="info" onClick={() => setShowBccButton(true)}>
                  Add BCC
                </Button>
              )}
              <Stack width="100%">
                {smartOptionsEnabled && (showBccBtn || bccRecipients) && (
                  <SmartEmployeeSelect
                    label="BCC"
                    placeholder="Add names or emails"
                    context={{
                      task: true,
                      application: true,
                      job: jobID,
                      enableOptionsWithMissingData: true,
                    }}
                    filterDynamicAudiences={[DynamicAudience.DebriefAttendees]}
                    onChange={({ employees, freeformEmails, dynamicAudiences }) => {
                      const ids = employees.map((e) => e.id);
                      onBccRecipientsChange({
                        employeeIds: ids,
                        externalEmailAddresses: freeformEmails,
                        dynamicAudiences,
                      });
                    }}
                    selectedFreeformEmails={bccRecipients?.externalEmailAddresses || []}
                    selectedEmployeeIds={bccRecipients?.employeeIds || []}
                    selectedDynamicAudiences={bccRecipients?.dynamicAudiences || []}
                  />
                )}
              </Stack>
              {!smartOptionsEnabled && (showBccBtn || bccRecipients) && (
                <EmployeePickerExtended
                  label="BCC"
                  dynamicAudiencesEnabled={false}
                  placeholder="Add names or emails"
                  freeformEnabled
                  freeformEmails={bccRecipients?.externalEmailAddresses || []}
                  selectedEmployeeIds={bccRecipients?.employeeIds || []}
                  onChange={(selectedEmployees: EmployeeFragment[], freeformEmails: string[]) => {
                    const ids = selectedEmployees.map((e) => e.id);
                    onBccRecipientsChange({
                      employeeIds: ids,
                      externalEmailAddresses: freeformEmails,
                    });
                  }}
                />
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    </ConditionalThemeProvider>
  );
}
