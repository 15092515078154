/* eslint-disable max-lines */
import { useMemo } from 'react';

import { isEmpty } from 'lodash';

import {
  RenderType,
  TemplateFragment,
  TemplateToken,
  TemplateType,
  useTemplateSelectTemplateByIdQuery,
} from 'src/generated/mloop-graphql';

import {
  CodingUrlBySlotId,
  CustomVideoMeetingLinkUrlBySlotId,
  VideoMeetingLinkBySlotId,
  ZoomInfo,
  ZoomInfoBySlotId,
} from 'src/store/slices/schedule-communications';

/* GENRIC token filler  */
import ApplicationPlaceholderFiller, {
  ApplicationPlaceholderFillerOptions,
  useApplicationPlaceholderFillerOptions,
} from 'src/utils/PlaceholderFiller/ApplicationPlaceholderFiller';
import CandidatePlaceholderFiller, {
  CandidatePlaceholderFillerOptions,
  useCandidatePlaceholderFillerOptions,
} from 'src/utils/PlaceholderFiller/CandidatePlaceholderFiller';
import InterviewPlaceholderFiller, {
  InterviewPlaceholderFillerOptions,
  useInterviewPlaceholderFiller,
} from 'src/utils/PlaceholderFiller/InterviewPlaceholderFiller';
import JobPlaceholderFiller, {
  JobPlaceholderFillerOptions,
  useJobPlaceholderFiller,
} from 'src/utils/PlaceholderFiller/JobPlaceholderFiller';
import MeetingPlaceholderFiller, {
  MeetingPlaceholderFillerOptions,
  useMeetingPlaceholderFillerOptions,
} from 'src/utils/PlaceholderFiller/MeetingPlaceholderFiller';
import UserPlaceholderFiller, {
  UserPlaceholderFillerOptions,
  useUserPlaceholderFillerOptions,
} from 'src/utils/PlaceholderFiller/UserPlaceholderFiller';
import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';

import { removeHtmlTags } from '../strings';

import TaskPlaceholderFiller, { TaskPlaceholderFillerOptions, useTaskPlaceholderFiller } from './TaskPlaceholderFiller';

export type PlaceholderFillerOptionsMap = {
  [key in keyof (ApplicationPlaceholderFillerOptions &
    CandidatePlaceholderFillerOptions &
    InterviewPlaceholderFillerOptions &
    JobPlaceholderFillerOptions &
    MeetingPlaceholderFillerOptions &
    UserPlaceholderFillerOptions &
    TaskPlaceholderFillerOptions)]?: TemplateToken['id'];
};

export type Output = {
  filledTemplate?: TemplateFragment;
  loading: boolean;
  placeholderFillerOptions: PlaceholderFillerOptionsMap;
};

/**
 * Input of this hook is used to fetch all filler data & return filled templates
 */
export type Input = {
  renderType: RenderType;
  applicationId: string;
  candidateId: string;
  jobId: string;
  jobStageId: string;
  schedule: InterviewSchedule | null;
  timezone?: string;
  templateId?: string;
  zoomInfo?: ZoomInfo;
  zoomInfos?: ZoomInfoBySlotId;
  customVideoMeetingLinkUrl?: string | null;
  customVideoMeetingLinkUrls?: CustomVideoMeetingLinkUrlBySlotId;
  customVideoMeetingLinkUrlBySlotId?: CustomVideoMeetingLinkUrlBySlotId;
  videoMeetingLinkUrls?: VideoMeetingLinkBySlotId;
  workspaceVideoLink?: string | null;
  codingUrlByInterviewEvent?: CodingUrlBySlotId;
  slotId?: string;
  isFetch?: boolean;
  skipMeetingLinkWithSchedule?: boolean;
  candidateTimezone?: string;
  taskId?: string;
  assigneeId?: string;
};

/**
 * This hook is used to fill template content with placeholder data for schedule flow
 * Schedule , Reschedule , Update & Defrief flow.
 */
const useScheduleTemplateContent = ({
  renderType,
  applicationId,
  candidateId,
  jobId,
  jobStageId,
  timezone,
  zoomInfo,
  zoomInfos,
  customVideoMeetingLinkUrl,
  customVideoMeetingLinkUrls,
  videoMeetingLinkUrls,
  workspaceVideoLink,
  templateId,
  codingUrlByInterviewEvent,
  schedule,
  slotId,
  isFetch = true,
  skipMeetingLinkWithSchedule = false,
  candidateTimezone,
  taskId,
  assigneeId,
}: Input): Output => {
  isFetch = !!(isFetch && schedule?.id);

  const { data: templatesData, loading: templatesLoading } = useTemplateSelectTemplateByIdQuery({
    skip: !templateId || !isFetch,
    variables: {
      id: templateId,
    },
  });

  const [applicationPlaceholderFillerOptions, { loading: applicationPlaceHoldersLoading }] =
    useApplicationPlaceholderFillerOptions({
      applicationId,
      renderType,
      isFetch,
    });

  const [taskPlaceholderFillerOptions, { loading: taskPlaceholderLoading }] = useTaskPlaceholderFiller({
    renderType,
    taskId,
    assigneeId,
    isFetch,
  });

  const [candidatePlaceholderFillerOptions, { loading: candidatePlaceHoldersLoading }] =
    useCandidatePlaceholderFillerOptions({
      renderType,
      applicationId,
      candidateId,
      jobId,
      isFetch,
    });

  const [jobPlaceholderFiller, { loading: jobPlaceholderLoading }] = useJobPlaceholderFiller({
    renderType,
    jobId,
    jobStageId,
    applicationId,
    isFetch,
  });

  // The filtered version of zoomInfo, zoomInfos, customVideoMeetingLinkUrl & customVideoMeetingLinkUrls
  // is needed to handle the case where the schedule has used unique meeting links while creation flow
  // but when the schedule is updated and there is only one interview that is in the future, then the
  // VIDEO_MEETING_LINK token should be filled with the unique meeting link of that interview.
  let filteredZoomInfo = zoomInfo;
  let filteredZoomInfos: ZoomInfoBySlotId | undefined;
  if (zoomInfos) {
    const slotIds = Object.keys(zoomInfos).filter((key) => schedule?.events?.find((e) => e.slotId === key));

    if (slotIds.length === 1) {
      filteredZoomInfo = zoomInfos[slotIds[0]];
    } else if (slotIds.length > 1) {
      filteredZoomInfos = {};
      slotIds.forEach((sId) => {
        if (!filteredZoomInfos) return;
        filteredZoomInfos[sId] = zoomInfos[sId];
      });
    }
  }

  let filteredCustomVideoMeetingLinkUrl = customVideoMeetingLinkUrl;
  let filteredCustomVideoMeetingLinkUrls: CustomVideoMeetingLinkUrlBySlotId | undefined;
  if (customVideoMeetingLinkUrls) {
    const slotIds = Object.keys(customVideoMeetingLinkUrls).filter((key) =>
      schedule?.events?.find((e) => e.slotId === key)
    );

    if (slotIds.length === 1) {
      filteredCustomVideoMeetingLinkUrl = customVideoMeetingLinkUrls[slotIds[0]];
    } else if (slotIds.length > 1) {
      filteredCustomVideoMeetingLinkUrls = {};
      slotIds.forEach((sId) => {
        if (!filteredCustomVideoMeetingLinkUrls) return;
        filteredCustomVideoMeetingLinkUrls[sId] = customVideoMeetingLinkUrls[sId];
      });
    }
  }

  let filteredVideoMeetingLinkUrl = workspaceVideoLink;
  let filteredVideoMeetingLinkUrls: VideoMeetingLinkBySlotId | undefined;
  if (videoMeetingLinkUrls) {
    const slotIds = Object.keys(videoMeetingLinkUrls).filter((key) => schedule?.events?.find((e) => e.slotId === key));
    if (slotIds.length === 1) {
      filteredVideoMeetingLinkUrl = videoMeetingLinkUrls[slotIds[0]];
    } else if (slotIds.length > 1) {
      filteredVideoMeetingLinkUrls = {};
      slotIds.forEach((sId) => {
        if (!filteredVideoMeetingLinkUrls) return;
        filteredVideoMeetingLinkUrls[sId] = videoMeetingLinkUrls[sId];
      });
    }
  }

  const [meetingPlaceholderFillerOptions, { loading: videoMeetingLinkPlaceholderLoading }] =
    useMeetingPlaceholderFillerOptions({
      schedule,
      slotId,
      renderType,
      zoomInfo: filteredZoomInfo,
      zoomInfos: filteredZoomInfos,
      customVideoMeetingLinkUrl: filteredCustomVideoMeetingLinkUrl || undefined,
      customVideoMeetingLinkUrls: filteredCustomVideoMeetingLinkUrls,
      videoMeetingLinkUrls: filteredVideoMeetingLinkUrls,
      workspaceVideoLink: filteredVideoMeetingLinkUrl || undefined,
      isFetch,
    });

  const [userPlaceholderFillerOptions, { loading: userPlaceholderLoading }] = useUserPlaceholderFillerOptions({
    renderType,
    isFetch,
  });

  const [interviewPlaceholderFiller, { loading: interviewPlaceholderLoading }] = useInterviewPlaceholderFiller({
    renderType,
    applicationId,
    candidateId,
    schedule: schedule as InterviewSchedule,
    timezone,
    zoomInfo: filteredZoomInfo,
    zoomInfos: filteredZoomInfos,
    customVideoMeetingLinkUrl: filteredCustomVideoMeetingLinkUrl || undefined,
    customVideoMeetingLinkUrls: filteredCustomVideoMeetingLinkUrls,
    videoMeetingLinkUrls,
    workspaceVideoLink: filteredVideoMeetingLinkUrl || undefined,
    codingUrlByInterviewEvent,
    isFetch,
    skipMeetingLinkWithSchedule,
    slotId,
    candidateTimezone,
  });

  const loading =
    templatesLoading ||
    applicationPlaceHoldersLoading ||
    candidatePlaceHoldersLoading ||
    interviewPlaceholderLoading ||
    jobPlaceholderLoading ||
    videoMeetingLinkPlaceholderLoading ||
    userPlaceholderLoading ||
    taskPlaceholderLoading;

  return useMemo((): Output => {
    const templateToFill = { ...templatesData?.template };

    if (!templateToFill?.body || loading) {
      return { loading, placeholderFillerOptions: {} };
    }

    if (templateToFill.subject) {
      if (!isEmpty(candidatePlaceholderFillerOptions)) {
        templateToFill.subject = CandidatePlaceholderFiller.getFilledText(
          templateToFill.subject,
          candidatePlaceholderFillerOptions
        );
      }

      if (!isEmpty(jobPlaceholderFiller)) {
        templateToFill.subject = JobPlaceholderFiller.getFilledText(templateToFill.subject, jobPlaceholderFiller);
      }

      if (!isEmpty(meetingPlaceholderFillerOptions)) {
        templateToFill.subject = MeetingPlaceholderFiller.getFilledText(
          templateToFill.subject,
          meetingPlaceholderFillerOptions
        );
      }

      if (!isEmpty(userPlaceholderFillerOptions)) {
        templateToFill.subject = UserPlaceholderFiller.getFilledText(
          templateToFill.subject,
          userPlaceholderFillerOptions
        );
      }

      if (!isEmpty(taskPlaceholderFillerOptions)) {
        templateToFill.subject = TaskPlaceholderFiller.getFilledText(
          templateToFill.subject,
          taskPlaceholderFillerOptions
        );
      }
    }

    if (!isEmpty(applicationPlaceholderFillerOptions)) {
      templateToFill.body = ApplicationPlaceholderFiller.getFilledText(
        templateToFill.body,
        applicationPlaceholderFillerOptions
      );
    }

    if (!isEmpty(candidatePlaceholderFillerOptions)) {
      templateToFill.body = CandidatePlaceholderFiller.getFilledText(
        templateToFill.body,
        candidatePlaceholderFillerOptions
      );
    }

    if (!isEmpty(jobPlaceholderFiller)) {
      templateToFill.body = JobPlaceholderFiller.getFilledText(templateToFill.body, jobPlaceholderFiller);
    }

    if (!isEmpty(meetingPlaceholderFillerOptions)) {
      templateToFill.body = MeetingPlaceholderFiller.getFilledText(
        templateToFill.body,
        meetingPlaceholderFillerOptions
      );
    }

    if (!isEmpty(userPlaceholderFillerOptions)) {
      templateToFill.body = UserPlaceholderFiller.getFilledText(templateToFill.body, userPlaceholderFillerOptions);
    }

    if (!isEmpty(taskPlaceholderFillerOptions)) {
      templateToFill.body = TaskPlaceholderFiller.getFilledText(templateToFill.body, taskPlaceholderFillerOptions);
    }

    /**
     * Reverse filling other templates with interview specific data
     */
    if (templateToFill.type !== TemplateType.InterviewerInvite && !isEmpty(interviewPlaceholderFiller)) {
      if (templateToFill.subject) {
        templateToFill.subject = InterviewPlaceholderFiller.getFilledText(
          templateToFill.subject,
          interviewPlaceholderFiller
        );
      }

      templateToFill.body = InterviewPlaceholderFiller.getFilledText(templateToFill.body, interviewPlaceholderFiller);
    }

    /**
     * Filling interview template with interview specific data
     */
    if (slotId && !isEmpty(interviewPlaceholderFiller) && templateToFill.type === TemplateType.InterviewerInvite) {
      if (templateToFill.subject) {
        templateToFill.subject = InterviewPlaceholderFiller.getFilledText(
          templateToFill.subject,
          interviewPlaceholderFiller
        );
      }

      if (templateToFill.body) {
        templateToFill.body = InterviewPlaceholderFiller.getFilledText(templateToFill.body, interviewPlaceholderFiller);
      }

      /** Custom code in case on interview template. */
      if (interviewPlaceholderFiller.CODE_LINK) {
        templateToFill.body = `${interviewPlaceholderFiller.CODE_LINK} <br/> <br/> ${templateToFill.body}`;
      }

      /** Custom code in case on interview template. */
      if (interviewPlaceholderFiller.INTERVIEWER_TRAINING_INFO) {
        templateToFill.body = `${interviewPlaceholderFiller.INTERVIEWER_TRAINING_INFO} <br/> <br/> ${templateToFill.body}`;
      }
    }

    /** Don't include html tags in subject */
    if (templateToFill.subject) {
      templateToFill.subject = removeHtmlTags(templateToFill.subject);
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line max-lines
    return {
      filledTemplate: templateToFill as TemplateFragment,
      loading,
      placeholderFillerOptions: {
        ...candidatePlaceholderFillerOptions,
        ...interviewPlaceholderFiller,
        ...jobPlaceholderFiller,
        ...meetingPlaceholderFillerOptions,
        ...userPlaceholderFillerOptions,
        ...taskPlaceholderFillerOptions,
      },
    };
  }, [
    templatesData,
    loading,
    applicationPlaceholderFillerOptions,
    candidatePlaceholderFillerOptions,
    jobPlaceholderFiller,
    meetingPlaceholderFillerOptions,
    userPlaceholderFillerOptions,
    interviewPlaceholderFiller,
    taskPlaceholderFillerOptions,
    slotId,
  ]);
};

export default useScheduleTemplateContent;
