import React, { useCallback, useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog } from '@modernloop/shared/components';
import { Stack, TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { useTaskRequestChangeMutation } from 'src/generated/mloop-graphql';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';

import ScheduleTaskFlags from 'src/views-new/ScheduleTask/ScheduleTaskDetails/ScheduleTaskFlags';

import { DEBOUNCE_TIMEOUT } from 'src/constants';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type TaskRequestChangeModalProps = {
  taskId: string;
  onClose: () => void;
};

const TaskRequestChangeModal = ({ taskId, onClose }: TaskRequestChangeModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [note, setNote] = useState('');
  const [taskRequestChangeMutation] = useTaskRequestChangeMutation();
  const refetchActivityFeed = useActivityFeedRefetch();

  const debouncedSetNote = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event?.target?.value?.trim() || '');
  }, DEBOUNCE_TIMEOUT);

  const handleRequestChange = useCallback(() => {
    setIsSubmitting(true);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    taskRequestChangeMutation({
      variables: {
        input: {
          taskId,
          note,
        },
      },
    }) // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      .then(() => {
        refetchActivityFeed();
      })
      .finally(() => {
        setIsSubmitting(false);
        onClose();
      });
  }, [note, onClose, refetchActivityFeed, taskId, taskRequestChangeMutation]);

  return (
    <Dialog
      open
      onClose={onClose}
      title="Request a change"
      subTitle="Describe the change needed"
      cancelOptions={{ label: 'Cancel', onClick: onClose }}
      submitOptions={{
        label: 'Submit change request',
        onClick: handleRequestChange,
        isDisabled: !note || isSubmitting,
        isLoading: isSubmitting,
      }}
    >
      <Stack spacing={1}>
        <TextField
          multiline
          rows={4}
          autoFocus
          fullWidth
          placeholder="e.g. Need to add an interviewer, update meeting descriptions, etc."
          onChange={debouncedSetNote}
        />
      </Stack>
    </Dialog>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TaskRequestChangeMutation = gql`
  ${ScheduleTaskFlags.fragments.taskFlags}
  mutation TaskRequestChange($input: TaskRequestChangeInput!) {
    taskRequestChange(input: $input) {
      task {
        id
        flags {
          ...ScheduleTaskFlags_taskFlag
        }
      }
    }
  }
`;

export default TaskRequestChangeModal;
