import React, { FC, useMemo } from 'react';

import { Stack, SxProps } from '@mui/material';

import Label from 'src/components/label';
import { BaseProps, createSxProps } from 'src/components/types';

import { Theme } from 'src/themeMui5/type';

import {
  SupportedBackgroundColor,
  SupportedGrey,
  getBackgroundColorFromTheme,
  getGreyFromTheme,
  isSupportedGrey,
} from '../utils/color';

/**
 * @deprecated - Use ZeroState from `@modernloop/shared` instead
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface ZeroStateProps extends BaseProps {
  /**
   * Optional. String or JSX component that will be the Label of the component
   */
  label?: string | React.ReactNode;
  /**
   * Optional. Icon that will be the main center piece of the ZeroState component
   */
  icon?: React.ReactNode;
  /**
   * Optional. Typically button actions at the bottom of the component
   */
  actions?: React.ReactNode;
  /**
   * Optional. Background color that will be applied to the root
   */
  backgroundColor?: SupportedGrey | SupportedBackgroundColor;
}

const useSxProps = (props: Partial<ZeroStateProps>) => {
  const { backgroundColor } = props;
  const sxProps = useMemo(() => {
    return {
      root: {
        borderRadius: '12px',
        padding: '12px',
        backgroundColor: (theme: Theme) => {
          if (!backgroundColor) return 'unset';

          if (isSupportedGrey(backgroundColor)) return getGreyFromTheme(backgroundColor, theme);

          return getBackgroundColorFromTheme(backgroundColor, theme);
        },
      } as SxProps,
    };
  }, [backgroundColor]);

  return createSxProps(sxProps);
};

/**
 * @deprecated
 * use from  mloop-shared folder
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const ZeroState: FC<ZeroStateProps> = ({ dataTestId, label, icon, actions, backgroundColor }) => {
  const sxProps = useSxProps({ backgroundColor });

  if (!label && !icon && !actions) return null;

  return (
    <div data-testid={dataTestId}>
      <Stack sx={sxProps.root} direction="column" alignItems="center" justifyContent="center" spacing={3}>
        {icon}
        {typeof label === 'string' && (
          <Label style={{ textAlign: 'center' }} variant="body" fontWeight={400} color="high-contrast-grey">
            {label}
          </Label>
        )}
        {typeof label !== 'string' && label}
        {actions}
      </Stack>
    </div>
  );
};

export default ZeroState;
