import { useEffect, useState } from 'react';

import { getLocalTimezone } from '@modernloop/shared/datetime';
import { formatDistance, intervalToDuration, parseISO } from 'date-fns';
import { noop } from 'lodash';

import IsoTimestamp, { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { formatToTimeZone } from 'src/utils/datetime/Format';

const useDurationLabel = (timestamp: IsoTimestamp | undefined) => {
  const [durationLabel, setDurationLabel] = useState(
    timestamp ? `${formatDistance(Date.now(), parseISO(timestamp))} ago` : ''
  );

  useEffect(() => {
    if (!timestamp) return noop;

    setDurationLabel(`${formatDistance(Date.now(), parseISO(timestamp))} ago`);

    const duration = intervalToDuration({ start: parseISO(timestamp), end: Date.now() });
    let timeoutMs = 60 * 1000;

    const { years = 0, months = 0, weeks = 0, days = 0 } = duration;

    const showDurationLabel = years === 0 && months === 0 && weeks === 0 && days <= 7;

    if (!showDurationLabel) {
      setDurationLabel(
        formatToTimeZone(assertIsoTimestamp(timestamp), years > 0 ? 'MMM d, yyyy' : 'MMM d', getLocalTimezone())
      );
      return noop;
    }

    if (duration.days && duration.days > 0) {
      timeoutMs *= 60 * 24; // 60 minutes * 24 hours
    } else if (duration.hours) {
      timeoutMs *= 60; // 60 minutes
    }

    const intervalHandle = setInterval(() => {
      setDurationLabel(`${formatDistance(Date.now(), parseISO(timestamp))} ago`);
    }, timeoutMs);

    return () => {
      clearInterval(intervalHandle);
    };
  }, [timestamp]);

  return durationLabel
    .replace('about ', '')
    .replace('minutes', 'min')
    .replace('minute', 'min')
    .replace('less than a min ago', 'just now');
};

export default useDurationLabel;
