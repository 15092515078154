import urlcat from 'urlcat';

export enum CandidateDetailsTab {
  Application = 'application',
  Activity = 'activity',
  Interviews = 'interviews',
}

/**
 * Candidate Details URL
 * @param candidateId
 * @param applicationId
 * @returns string
 */
const getCandidateDetailsUrl = (
  candidateId: string,
  options?: { applicationId?: string; tab?: CandidateDetailsTab; useRelativeUrl?: boolean }
): string => {
  const { applicationId, useRelativeUrl = true, tab } = options || {};
  const currentUrl = new URL(window.location.href);
  const baseUrl = useRelativeUrl ? '' : currentUrl.origin;

  if (tab) {
    return urlcat(baseUrl, '/candidates/:candidateId/:tab', { candidateId, applicationId, tab });
  }

  return urlcat(baseUrl, '/candidates/:candidateId', { candidateId, applicationId });
};

export default getCandidateDetailsUrl;
