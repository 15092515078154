import { useMemo } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';

import { UseInterviewPlanHasInterviewHiddenFromCandidate_InterviewPlanFragment } from 'src/generated/mloop-graphql';

type Fragments = {
  interviewPlan: UseInterviewPlanHasInterviewHiddenFromCandidate_InterviewPlanFragment | undefined;
};

const useInterviewPlanHasInterviewHiddenFromCandidate: FunctionWithFragments<Fragments, void, boolean> = ({
  interviewPlan,
}) => {
  return useMemo(() => {
    if (!interviewPlan) return false;

    const interviews = interviewPlan.jobStageInterviewGroups?.flatMap((group) => group.jobStageInterviews || []) || [];
    return interviews.some((interview) => interview.isHiddenFromCandidate);
  }, [interviewPlan]);
};

useInterviewPlanHasInterviewHiddenFromCandidate.fragments = {
  interviewPlan: gql`
    fragment useInterviewPlanHasInterviewHiddenFromCandidate_interviewPlan on JobStage {
      id
      jobStageInterviewGroups {
        id
        jobStageInterviews {
          id
          isHiddenFromCandidate
        }
      }
    }
  `,
};

export default useInterviewPlanHasInterviewHiddenFromCandidate;
