import { ArrowDownIcon } from '@modernloop/shared/icons';
import { Paper, ThemeOptions } from '@mui/material';

/**
 * View Table props and classes at https://mui.com/material-ui/api/Table/#css
 */
export const table: ThemeOptions['components'] = {
  MuiTableContainer: {
    defaultProps: {
      component: Paper,
    },
  },
  MuiTableCell: {
    defaultProps: {},
    styleOverrides: {
      root: ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.borderState.default}`,
      }),
      paddingCheckbox: ({ theme }) => ({
        padding: theme.spacing(0.75),
        paddingRight: 0,
        ':first-child': {
          paddingLeft: theme.spacing(1.75),
        },
      }),
      head: ({ theme, ownerState }) => ({
        ...theme.typography.subtitle2,
        padding: theme.spacing(1.5),
        ':first-child': {
          paddingLeft: theme.spacing(2.5),
        },
        ':not(:last-child)': {
          boxShadow: ownerState.padding === 'checkbox' ? undefined : `inset -1px 0 0 0 ${theme.palette.divider}`,
        },
      }),
      body: ({ theme }) => ({
        padding: theme.spacing(1.5),
        ':first-child': {
          paddingLeft: theme.spacing(2.5),
        },
      }),
    },
  },
  MuiTableSortLabel: {
    defaultProps: {
      IconComponent: ArrowDownIcon,
    },
    styleOverrides: {
      icon: {
        width: '16px',
        height: '16px',
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: () => ({
        borderBottom: 'none',
      }),
      toolbar: ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
          minHeight: 'unset',
          padding: theme.spacing(0.5, 2.5),
        },
      }),
    },
  },
};
