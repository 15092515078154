import stringArrayToSentence from './stringArrayToSentence';

export const HEALTH_CHECK_CONTACT_US = 'If this issue persists, please contact your Customer Success Manager.';
export const BUTTON_LABEL_TRY_AGAIN = 'Try Again';

export const integrationUnhealthyMessage = (integrationName: string, endpoints: string[] = []) => {
  return endpoints.length > 0
    ? `ModernLoop recently encountered errors when trying to access your company’s ${integrationName} account. ModernLoop could not access the following endpoints: ${stringArrayToSentence(
        endpoints
      )}. If this issue persists, please contact your Customer Success Manager.`
    : `ModernLoop recently encountered errors when trying to access your company’s ${integrationName} account. If this issue persists, please contact your Customer Success Manager.`;
};
