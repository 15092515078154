import { ThemeOptions } from '@mui/material';

import { typography } from 'src/theme/light/typography';

import { grey, palette } from './colors';
import shadows from './shadows';

const options: ThemeOptions = {
  typography,
  shadows,
  grey,
  palette,
};

export default options;
