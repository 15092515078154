import React from 'react';

import { Chip } from '@mui/material';

import { ResponseStatus } from 'src/generated/mloop-graphql';

const getResponseStatusNode = (responseStatus: ResponseStatus) => {
  switch (responseStatus) {
    case ResponseStatus.Accepted:
      return <Chip data-testid="response-status-node-rsvp-accepted" color="success" label="Going" />;
    case ResponseStatus.Declined:
      return <Chip data-testid="response-status-node-rsvp-declined" color="error" label="Declined" />;
    case ResponseStatus.Tentative:
      return <Chip data-testid="response-status-node-rsvp-maybe" label="Maybe" />;
    default:
      return <Chip data-testid="response-status-node-rsvp-no-response" label="No response" />;
  }
};

export default getResponseStatusNode;
