import React from 'react';

import BaseIcon, { Props } from './Icon';

const ModulesIconFilled = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2.68752L5 2.68753C3.4467 2.68753 2.1875 3.94672 2.1875 5.50002V14.8685C2.1875 16.0085 3.54079 16.6065 4.38366 15.839L7.16517 13.3063L7.16517 15.5536C7.16517 17.1192 8.43437 18.3884 10 18.3884H16C17.5656 18.3884 18.8349 17.1192 18.8349 15.5536L18.8348 10C18.8348 8.71674 17.9822 7.63262 16.8125 7.28331V5.50002C16.8125 3.94672 15.5533 2.68752 14 2.68752ZM16 8.83485L10 8.83485C9.35652 8.83485 8.83487 9.35651 8.83487 10L8.83486 15.5536C8.83486 16.1971 9.35652 16.7187 10 16.7187H16C16.6435 16.7187 17.1652 16.1971 17.1652 15.5536L17.1651 10C17.1651 9.35651 16.6435 8.83485 16 8.83485ZM11 10.75C10.5858 10.75 10.25 11.0858 10.25 11.5C10.25 11.9142 10.5858 12.25 11 12.25H15C15.4142 12.25 15.75 11.9142 15.75 11.5C15.75 11.0858 15.4142 10.75 15 10.75H11ZM11 13.75C10.5858 13.75 10.25 14.0858 10.25 14.5C10.25 14.9142 10.5858 15.25 11 15.25H15C15.4142 15.25 15.75 14.9142 15.75 14.5C15.75 14.0858 15.4142 13.75 15 13.75H11Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ModulesIconFilled;
