import { useMemo } from 'react';

import { QueryResult, gql } from '@apollo/client';

import {
  ApplicationTokensQuery,
  RenderType,
  TemplateToken,
  useApplicationTokensQuery,
} from 'src/generated/mloop-graphql';

import placeholders from './utils/placeholders';

export const ApplicationTemplateTokenQuery = gql`
  query ApplicationTokens($id: String, $renderType: RenderType!, $input: TokenApplicationInput!) {
    templateToken(input: { id: $id, renderType: $renderType }) {
      id
      INTERNAL_APPLICATION_NOTE(input: $input)
      APPLICATION_CANDIDATE_PORTAL_URL(input: $input)
    }
  }
`;

/**
 * TOKENS TO DISPLAY
 * Note Only add if eligible for display
 * */
export enum Tokens {
  APPLICATION_CANDIDATE_PORTAL_URL = 'APPLICATION_CANDIDATE_PORTAL_URL',
  INTERNAL_APPLICATION_NOTE = 'INTERNAL_APPLICATION_NOTE',
}

/**
 * TOKENS FETCHED
 */
export type ApplicationPlaceholderFillerOptions = Pick<TemplateToken, keyof typeof Tokens>;

export default class ApplicationPlaceholderFiller {
  static readonly EMPTY_OBJECT: ApplicationPlaceholderFillerOptions = {
    INTERNAL_APPLICATION_NOTE: '',
    APPLICATION_CANDIDATE_PORTAL_URL: '',
  };

  static getFilledText = (input: string, options: ApplicationPlaceholderFillerOptions): string => {
    return placeholders(input, options);
  };

  static getOptions = (data?: ApplicationTokensQuery): ApplicationPlaceholderFillerOptions => {
    if (!data || !Object.keys(data).length) return this.EMPTY_OBJECT;
    return {
      INTERNAL_APPLICATION_NOTE: data.templateToken?.INTERNAL_APPLICATION_NOTE || '',
      APPLICATION_CANDIDATE_PORTAL_URL: data.templateToken?.APPLICATION_CANDIDATE_PORTAL_URL || '',
    };
  };
}

export type Input = {
  applicationId: string;
  renderType?: RenderType;
  isFetch?: boolean;
};

export const useApplicationPlaceholderFillerOptions = ({
  applicationId,
  renderType = RenderType.Html,
  isFetch = true,
}: Input): [ApplicationPlaceholderFillerOptions, Pick<QueryResult, 'data' | 'error' | 'loading'>] => {
  const { data, loading, error } = useApplicationTokensQuery({
    variables: {
      id: applicationId,
      input: { id: applicationId },
      renderType,
    },
    skip: !isFetch,
    errorPolicy: 'all',
  });

  const applicationPlaceholderFillerOptions: ApplicationPlaceholderFillerOptions = useMemo(
    () => ApplicationPlaceholderFiller.getOptions(data),
    [data]
  );

  return useMemo(() => {
    return [applicationPlaceholderFillerOptions, { data, error, loading }];
  }, [applicationPlaceholderFillerOptions, data, error, loading]);
};
