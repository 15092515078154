import { gql } from '@apollo/client';
import { assertIsoTimestamp } from '@modernloop/shared/datetime';
import { logError } from '@modernloop/shared/utils';

import {
  DurationInput,
  DurationOrgPref,
  JsonOrgPref,
  OrgJsonPref,
  PrefSet,
  StringOrgPref,
  useUpdateOrgPrefsMutation,
} from 'src/generated/mloop-graphql';

import { OrgPrefName } from 'src/utils/api/org';
import { removeTypename } from 'src/utils/removeTypename';

import { OrgPrefsFragment } from './get';
import { AllOrgPrefName } from './types';

export const GQL_ORG_PREFS_UPDATE_MUTATION = gql`
  ${OrgPrefsFragment}

  mutation UpdateOrgPrefs($input: OrgPrefUpdateInput!) {
    orgPrefUpdate(input: $input) {
      orgPref {
        ...OrgPrefsData
      }
    }
  }
`;

function useSetOrgPref(onCompleted?: () => void) {
  const [update] = useUpdateOrgPrefsMutation({ onCompleted });

  const updateOrgPrefs = (prefName: AllOrgPrefName | OrgPrefName, prefValue: PrefSet) => {
    return update({
      variables: {
        input: {
          prefName,
          set: prefValue,
        },
      },
      onCompleted,
    });
  };

  return { updateOrgPrefs };
}

export function useSetOrgPrefBool(prefName: OrgPrefName, onCompleted?: () => void) {
  const { updateOrgPrefs } = useSetOrgPref(onCompleted);
  return (value: boolean, isLocked?: boolean) => {
    const input: PrefSet = { boolValue: value };
    if (isLocked !== undefined) {
      input.isLocked = isLocked;
    }
    return updateOrgPrefs(prefName, input);
  };
}

export function useSetOrgPrefInt(prefName: OrgPrefName, onCompleted?: () => void) {
  const { updateOrgPrefs } = useSetOrgPref(onCompleted);
  return (value: number, isLocked?: boolean) => {
    const input: PrefSet = { intValue: value };
    if (isLocked !== undefined) {
      input.isLocked = isLocked;
    }
    return updateOrgPrefs(prefName, input);
  };
}

export function useSetOrgPrefString(prefName: OrgPrefName | StringOrgPref, onCompleted?: () => void) {
  const { updateOrgPrefs } = useSetOrgPref(onCompleted);
  return (value: string, isLocked?: boolean) => {
    const input: PrefSet = { stringValue: value };
    if (isLocked !== undefined) {
      input.isLocked = isLocked;
    }
    return updateOrgPrefs(prefName, input);
  };
}

export function useSetOrgPrefStringArray(prefName: OrgPrefName, onCompleted?: () => void) {
  const { updateOrgPrefs } = useSetOrgPref(onCompleted);
  return (value: string[] | null, isLocked?: boolean) => {
    const input: PrefSet = {
      stringArray: value || [],
    };
    if (isLocked !== undefined) {
      input.isLocked = isLocked;
    }
    return updateOrgPrefs(prefName, input);
  };
}

export function useSetOrgPrefJson(prefName: OrgPrefName, onCompleted?: () => void) {
  const { updateOrgPrefs } = useSetOrgPref(onCompleted);
  return (value: unknown, isLocked?: boolean) => {
    const input: PrefSet = { json: JSON.stringify(value) };
    if (isLocked !== undefined) {
      input.isLocked = isLocked;
    }
    return updateOrgPrefs(prefName, input);
  };
}

/**
 * @param prefName
 * @returns mutationHook
 */
export function useSetOrgPrefJsonArray(prefName: OrgPrefName, onCompleted?: () => void) {
  const { updateOrgPrefs } = useSetOrgPref(onCompleted);
  return (jsonArray: unknown[] | null, isLocked?: boolean) => {
    const input: PrefSet = { jsonArray: jsonArray ? jsonArray.map((json) => JSON.stringify(json)) : [] };
    if (isLocked !== undefined) {
      input.isLocked = isLocked;
    }
    return updateOrgPrefs(prefName, input);
  };
}

/**
 * @param prefName
 * @returns mutationHook
 */
export function useSetOrgPrefTimestamp(prefName: OrgPrefName, onCompleted?: () => void) {
  const { updateOrgPrefs } = useSetOrgPref(onCompleted);
  return (timestamp: string) => {
    let isoTimestamp = timestamp;
    try {
      isoTimestamp = assertIsoTimestamp(timestamp);
    } catch (e) {
      logError(e);
      return Promise.resolve();
    }
    const input: PrefSet = { timestamp: isoTimestamp };
    return updateOrgPrefs(prefName, input);
  };
}

/**
 * @param prefName
 * @returns mutationHook
 */
export function useSetOrgPrefDuration(prefName: DurationOrgPref, onCompleted?: () => void) {
  const { updateOrgPrefs } = useSetOrgPref(onCompleted);
  return (duration: DurationInput) => {
    const input: PrefSet = { duration };
    return updateOrgPrefs(prefName, input);
  };
}

export function useSetOrgPrefJsonTyped<T extends OrgJsonPref>(prefName: JsonOrgPref, onCompleted?: () => void) {
  const { updateOrgPrefs } = useSetOrgPref(onCompleted);
  return (value: T, isLocked?: boolean) => {
    const input: PrefSet = { json: JSON.stringify(removeTypename(value)) };
    if (isLocked !== undefined) {
      input.isLocked = isLocked;
    }
    return updateOrgPrefs(prefName, input);
  };
}
