// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { colors, createTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import _ from 'lodash';

import lightTheme from './light';
import { Theme, ThemeOptions } from './type';

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  shape: {
    borderRadius: 12,
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600],
        },
      },
    },
  },
};

const createTheme = (): Theme => {
  let theme = createMuiTheme(_.merge({}, baseOptions, lightTheme));
  theme = responsiveFontSizes(theme);
  return theme as Theme;
};

export default createTheme;

/**
 * This export is a hack.
 * There are about 190 files that import Theme from here.
 * Its easy to change the import but majority of those files have TSLint errors.
 * And fixing all of them in one go is a bit too much because it might lead to unintended behaviour.
 */
export type { Theme } from './type';
