import { PublicError } from '@modernloop/shared/helper-components';
import { PhoneNumberUtil } from 'google-libphonenumber';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useEffect, useState } from 'react';

export interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
}
interface Props {
  shouldValidate?: boolean;
  setPhoneNumber: (phoneNumber: string) => void;
  setIsPhoneNumberValid?: (isValid: boolean) => void;
  value?: string;
}

const phoneUtil = PhoneNumberUtil.getInstance();

const PhoneNumberInput: React.FC<Props> = ({ setPhoneNumber, setIsPhoneNumberValid, value, shouldValidate = true }) => {
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);
  const [internalPhoneNumberValid, setInternalPhoneNumberValid] = useState(!shouldValidate);
  const [internalPhoneNumber, setInternalPhoneNumber] = useState('');

  const handlePhoneNumberChange = (phoneValue: string, countryDataValue: CountryData) => {
    setPhoneNumber(phoneValue);
    setInternalPhoneNumber(phoneValue);

    // If we don't need to validate, we can skip the validation
    if (!shouldValidate) {
      return;
    }

    try {
      const parsedPhoneNumber = phoneUtil.parse(phoneValue, countryDataValue?.countryCode);
      setInternalPhoneNumberValid(phoneUtil.isValidNumberForRegion(parsedPhoneNumber, countryDataValue?.countryCode));
      if (setIsPhoneNumberValid) {
        setIsPhoneNumberValid(phoneUtil.isValidNumberForRegion(parsedPhoneNumber, countryDataValue?.countryCode));
      }
    } catch (err) {
      setInternalPhoneNumberValid(false);
      if (setIsPhoneNumberValid) {
        setIsPhoneNumberValid(false);
      }
    }
  };

  const onBlur = () => {
    if (internalPhoneNumber) {
      setPhoneNumberTouched(true);
    }
  };

  useEffect(() => {
    if (value && setIsPhoneNumberValid && shouldValidate) {
      try {
        setIsPhoneNumberValid(phoneUtil.isValidNumber(phoneUtil.parse(value)));
      } catch (err) {
        setInternalPhoneNumberValid(false);
        if (setIsPhoneNumberValid) {
          setIsPhoneNumberValid(false);
        }
      }
    }
  }, [value, setIsPhoneNumberValid, shouldValidate]);

  return (
    <>
      <MuiPhoneNumber
        disableAreaCodes
        defaultCountry="us"
        onBlur={onBlur}
        value={value || internalPhoneNumber}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // both value and country are passed to onChange, but the library itself doesn't have the onChange interface overriden
        onChange={handlePhoneNumberChange}
        // eslint-disable-next-line no-restricted-syntax
        sx={{
          backgroundColor: (theme) => theme.palette.secondary.main,
          width: '100%',
          'button::after': {
            content: '"\u25BE"',
            fontSize: '16px',
            marginLeft: '4px',
            color: 'rgba(4, 7, 27, 0.57)', // use theme light/high contrast grey
          },
          '.MuiPhoneNumber-flagButton': {
            width: '46px',
            height: '24px',
            marginLeft: '4px',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#EAF5FF', // use theme
            },
          },
          svg: {
            width: '16px',
            height: '16px',
          },
          borderRadius: '4px',
          border: () => {
            return !internalPhoneNumberValid && phoneNumberTouched
              ? '2px solid #BC130C!important'
              : '1px solid rgba(5, 7, 26, 0.15)!important';
          }, // use theme
          '&:focus-within': {
            boxShadow: 'rgb(49 147 237 / 45%) 0px 0px 0px 1px', // use theme
            border: '1px solid rgba(49, 147, 237, 0.45)!important', // use theme
          },
          '& .MuiInput-underline:after, & .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before':
            {
              borderBottom: '0px',
            },
        }}
      />
      {!internalPhoneNumberValid && phoneNumberTouched && <PublicError error="Enter a valid phone number" />}
    </>
  );
};

export default PhoneNumberInput;
