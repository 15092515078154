import { logError } from '../utils/logError';
import { logMessage } from '../utils/logMessage';
import { UnbrandedLoadingScreen } from './UnbrandedLoadingScreen';
import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

// Webpack errors - leaving for now but can likely be removed
const REGEX_JS_CHUNK = /Loading chunk [\d]+ failed/;
const REGEX_CSS_CHUNK = /Loading CSS chunk [\d]+ failed/;

// Vite errors
const REGEX_JS_DYNAMIC_MODULE = /Failed to fetch dynamically imported module/;
const REGEX_JS_MODULE = /Failed to load module script/;
const REGEX_JS_TYPE_ERROR = /'text\/html' is not a valid JavaScript MIME type./;

const isChunkLoadFailure = (error: Error) => {
  return (
    REGEX_JS_CHUNK.test(error.message) ||
    REGEX_CSS_CHUNK.test(error.message) ||
    REGEX_JS_MODULE.test(error.message) ||
    REGEX_JS_DYNAMIC_MODULE.test(error.message) ||
    REGEX_JS_TYPE_ERROR.test(error.message)
  );
};

// eslint-disable-next-line react-refresh/only-export-components
const SomethingWentWrongMessage: FC = () => (
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line no-restricted-syntax
  <Stack direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
    <Typography gutterBottom align="center" variant="h4">
      <span aria-label="sad" role="img">
        😢
      </span>
    </Typography>
    <Typography gutterBottom align="center" variant="h4">
      Something went wrong.
    </Typography>
    <Typography gutterBottom align="center" variant="h5">
      We&apos;ve already been notified and will reach out if we have any questions.
    </Typography>
  </Stack>
);

interface Props {
  children: React.ReactNode;
}
interface State {
  hasError: boolean;
  showError: boolean;
}
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/validate-component-definition.cjs
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, showError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, showError: !isChunkLoadFailure(error) };
  }

  componentDidCatch(error: Error): void {
    if (isChunkLoadFailure(error)) {
      logMessage('Chunk load error. App reloaded.', 'info', { error });
      if (REGEX_JS_TYPE_ERROR.test(error.message)) {
        logMessage('JS returned HTML error. App reloaded.', 'info', { error });
      }
      window.location.reload();
    } else {
      logError(error);
      logMessage('"Something went wrong" toast displayed', 'fatal', { error });
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return this.state.showError ? <SomethingWentWrongMessage /> : <UnbrandedLoadingScreen />;
    }

    return this.props.children;
  }
}
