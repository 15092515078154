import React, { useRef } from 'react';

import { ListItem, ListItemButton } from '@mui/material';

import { AtsInterviewDefinitionFragment } from 'src/generated/mloop-graphql';

import FilterSelect, {
  FilterListSelectInterface,
  Props as FilterSelectProps,
} from 'src/components/FilterList/FilterSelect';

import { useGetChildSize, useGroupBy, useRenderGroup, useRenderOption } from './AtsScorecard';
import useAtsScorecardOptions from './useAtsScorecardOptions';

type Props = {
  atsJobId: string;
  getLabel: FilterSelectProps<AtsInterviewDefinitionFragment>['getLabel'];
  selectScorecard?: (interviewDefinition: AtsInterviewDefinitionFragment) => void;
};

const AtsScorecardSelect = ({ atsJobId, getLabel, selectScorecard }: Props) => {
  const filterListSelectInterfaceRef = useRef<FilterListSelectInterface | null>(null);
  const { loading, error, options } = useAtsScorecardOptions(atsJobId);

  const handleChange = (event: React.MouseEvent, values: AtsInterviewDefinitionFragment[]) => {
    if (!values.length || !selectScorecard) return;
    selectScorecard(values[0]);
    filterListSelectInterfaceRef.current?.dismiss();
  };

  const renderOption = useRenderOption();
  const renderGroup = useRenderGroup();
  const getChildSize = useGetChildSize();
  const groupBy = useGroupBy();

  return (
    <FilterSelect
      dataTestId="ats-scorecard-select"
      placeholderText="Search scorecard…"
      filterListSelectInterfaceRef={filterListSelectInterfaceRef}
      isOptionEqualToValue={(option, value) => option.atsId === value.atsId}
      options={options}
      loading={loading}
      errorMsg={error?.message}
      onChange={handleChange}
      getLabel={getLabel}
      getOptionLabel={(option) => option.name ?? 'No Scorecard'}
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemButton>{renderOption(option)}</ListItemButton>
          </ListItem>
        );
      }}
      groupBy={groupBy}
      renderGroup={renderGroup}
      getChildSize={getChildSize}
    />
  );
};

export default AtsScorecardSelect;
