import React from 'react';

import BaseIcon, { Props } from './Icon';

const TrashIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 10C3.75 6.54822 6.54822 3.75 10 3.75C13.4518 3.75 16.25 6.54822 16.25 10C16.25 13.4518 13.4518 16.25 10 16.25C6.54822 16.25 3.75 13.4518 3.75 10ZM10 2.25C5.71979 2.25 2.25 5.71979 2.25 10C2.25 14.2802 5.71979 17.75 10 17.75C14.2802 17.75 17.75 14.2802 17.75 10C17.75 5.71979 14.2802 2.25 10 2.25ZM10.75 6C10.75 5.58579 10.4142 5.25 10 5.25C9.58579 5.25 9.25 5.58579 9.25 6V10C9.25 10.1989 9.32902 10.3897 9.46967 10.5303L11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L10.75 9.68934V6Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TrashIcon;
