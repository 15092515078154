/* eslint-disable max-lines */
import { ApolloCache, gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import {
  IsoTimestamp,
  formatToTimeZone,
  getLocalTimezone,
  guessIsoTimestamp,
  isEventOngoing,
} from '@modernloop/shared/datetime';

import {
  CurrentTrainingSessionStatus,
  DateBlock,
  GetModuleMemberEmployeePausedText_ModuleMemberFragment,
  GetModuleMemberPauseText_ModuleMemberFragment,
  GetModuleMemberPendingTrainingCount_ModuleMemberFragment,
  GetReverseShadowCompletedCount_ModuleMemberFragment,
  GetReverseShadowPendingCount_ModuleMemberFragment,
  GetShadowCompletedCount_ModuleMemberFragment,
  GetShadowPendingCount_ModuleMemberFragment,
  InterviewerRole,
  IsModuleMemberDisabledForScheduling_ModuleMemberFragment,
  IsModuleMemberEmployeePaused_ModuleMemberFragment,
  IsModuleMemberPauseIndefinitely_ModuleMemberFragment,
  IsModuleMemberPaused_ModuleMemberFragment,
  IsModuleMembreOrEmployeePaused_ModuleMemberFragment,
  TrainingStatus,
} from 'src/generated/mloop-graphql';

import { apolloCacheDeleteByQuery } from 'src/utils/apolloHelpers';

const DEFAULT_COUNT = 0;

/**
 * Status of a module member for a interview module
 */
export const isModuleMemberShadow = (status?: TrainingStatus) => {
  return status === TrainingStatus.Shadow;
};

/**
 * Status of a module member for a interview module
 */
export const isModuleMemberReverseShadow = (status?: TrainingStatus) => {
  return status === TrainingStatus.ReverseShadow;
};

/**
 * Status of a module member for a interview module
 */
export const isModuleMemberTrained = (status?: TrainingStatus) => {
  return status === TrainingStatus.Trained;
};

export const getEmployeePauseEndDate = (pauseDates: DateBlock[]): IsoTimestamp | null => {
  if (!pauseDates || !pauseDates.length) return null;
  const currentPauseDate = pauseDates?.find(
    (pauseDate) =>
      pauseDate.start &&
      pauseDate.end &&
      isEventOngoing(guessIsoTimestamp(pauseDate.start), guessIsoTimestamp(pauseDate.end))
  );
  if (currentPauseDate) {
    return currentPauseDate.end as IsoTimestamp;
  }
  return null;
};

export const getEmployeePauseTime = (interviewPauseDates: DateBlock[]): string | null => {
  const maxEndDate = getEmployeePauseEndDate(interviewPauseDates);
  if (maxEndDate) {
    return maxEndDate;
  }
  return null;
};

/**
 * Status of a module member for a scheduled interview
 */
export const isModuleMemberInterviewRoleShadow = (status?: InterviewerRole) => {
  return status === InterviewerRole.Shadow;
};

/**
 * Status of a module member for a scheduled interview
 */
export const isModuleMemberInterviewRoleReverseShadow = (status?: InterviewerRole) => {
  return status === InterviewerRole.ReverseShadow;
};

/**
 * Status of a module member for a scheduled interview
 */
export const isModuleMemberInterviewRoleInterviewer = (status?: InterviewerRole) => {
  return status === InterviewerRole.Interviewer;
};

/**
 * If module member is not trained and current training session status is NeedsApproval
 */
export const doesModuleMemberNeedsApproval = (
  status?: TrainingStatus,
  currentTrainingSessionStatus?: CurrentTrainingSessionStatus
): boolean => {
  if (!status) return false;
  if (!isModuleMemberTrained(status)) {
    return currentTrainingSessionStatus === CurrentTrainingSessionStatus.NeedsApproval;
  }
  return false;
};

export const isModuleMemberAllRequiredInterviewsScheduled = (
  currentTrainingSessionStatus?: CurrentTrainingSessionStatus
): boolean => {
  return currentTrainingSessionStatus === CurrentTrainingSessionStatus.RequiredInterviewsScheduled;
};

/**
 * Check if employee is paused
 */
export const isModuleMemberEmployeePaused: FunctionWithFragments<
  { moduleMember: IsModuleMemberEmployeePaused_ModuleMemberFragment },
  null,
  boolean
> = ({ moduleMember }) => {
  return !!moduleMember.employee?.isPaused;
};
isModuleMemberEmployeePaused.fragments = {
  moduleMember: gql`
    fragment isModuleMemberEmployeePaused_ModuleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      employee {
        id
        isPaused
      }
    }
  `,
};

/**
 * Return text with formatted date for when employee is paused
 */
export const getModuleMemberEmployeePausedText: FunctionWithFragments<
  { moduleMember: GetModuleMemberEmployeePausedText_ModuleMemberFragment },
  null,
  string
> = ({ moduleMember }) => {
  let pausedTooltip = '';

  if (moduleMember.employee && isModuleMemberEmployeePaused({ moduleMember }, null)) {
    const pausedTill = getEmployeePauseTime(moduleMember.employee.interviewPauseDates ?? []);
    if (pausedTill) {
      pausedTooltip = `Paused from all interviews. Resumes ${formatToTimeZone(
        guessIsoTimestamp(getEmployeePauseTime(moduleMember.employee.interviewPauseDates ?? []) as string),
        'LLLL d, yyyy',
        moduleMember.employee?.timezone || getLocalTimezone()
      )}`;
    } else {
      pausedTooltip = 'Paused from all interviews';
    }
  }
  return pausedTooltip;
};
getModuleMemberEmployeePausedText.fragments = {
  moduleMember: gql`
    fragment getModuleMemberEmployeePausedText_ModuleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      employee {
        id
        isPaused
        timezone
        interviewPauseDates {
          start
          end
        }
      }
    }
  `,
};

/**
 * Check if module member is paused
 */
export const isModuleMemberPaused: FunctionWithFragments<
  { moduleMember: IsModuleMemberPaused_ModuleMemberFragment },
  null,
  boolean
> = ({ moduleMember }) => {
  return !!moduleMember?.isPaused && !!moduleMember?.pausedUntil;
};
isModuleMemberPaused.fragments = {
  moduleMember: gql`
    fragment isModuleMemberPaused_ModuleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      isPaused
      pausedUntil
    }
  `,
};

/**
 * Check if module member is paused
 */
export const isModuleMemberPauseIndefinitely: FunctionWithFragments<
  { moduleMember: IsModuleMemberPauseIndefinitely_ModuleMemberFragment },
  null,
  boolean
> = ({ moduleMember }) => {
  return !!moduleMember?.isPausedIndefinitely;
};
isModuleMemberPauseIndefinitely.fragments = {
  moduleMember: gql`
    fragment isModuleMemberPauseIndefinitely_ModuleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      isPausedIndefinitely
    }
  `,
};

/**
 * Return text with formatted date for when module is paused
 */
export const getModuleMemberPauseText: FunctionWithFragments<
  { moduleMember: GetModuleMemberPauseText_ModuleMemberFragment },
  null,
  string
> = ({ moduleMember }) => {
  let pausedTooltip = '';

  if (isModuleMemberPaused({ moduleMember }, null)) {
    if (moduleMember.pausedUntil) {
      pausedTooltip = `Paused from module. Resumes ${formatToTimeZone(
        moduleMember.pausedUntil,
        'LLLL d, yyyy',
        moduleMember.employee?.timezone || getLocalTimezone()
      )}`;
    } else {
      pausedTooltip = 'Paused from module';
    }
  }
  return pausedTooltip;
};
getModuleMemberPauseText.fragments = {
  moduleMember: gql`
    fragment getModuleMemberPauseText_ModuleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      pausedUntil
      employee {
        id
        timezone
      }
    }
  `,
};

/**
 * Check for employee is paused or module member is paused
 */
export const isModuleMembreOrEmployeePaused: FunctionWithFragments<
  { moduleMember: IsModuleMembreOrEmployeePaused_ModuleMemberFragment },
  null,
  boolean
> = ({ moduleMember }) => {
  return !!(moduleMember.employee?.isPaused || moduleMember.isPaused);
};
isModuleMembreOrEmployeePaused.fragments = {
  moduleMember: gql`
    fragment isModuleMembreOrEmployeePaused_ModuleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      employee {
        id
        isPaused
      }
      isPaused
    }
  `,
};

/**
 * Gets shadow completed count for the module member interview + manually completed shadows
 */
export const getShadowCompletedCount: FunctionWithFragments<
  { moduleMember: GetShadowCompletedCount_ModuleMemberFragment },
  undefined,
  number
> = ({ moduleMember }) => {
  return (
    (moduleMember.stats?.completedAsShadow || DEFAULT_COUNT) +
    (moduleMember.stats?.manualCompletedAsShadow || DEFAULT_COUNT)
  );
};
getShadowCompletedCount.fragments = {
  moduleMember: gql`
    fragment getShadowCompletedCount_moduleMember on InterviewModuleMember {
      employeeId
      interviewModuleId
      stats {
        completedAsShadow
        manualCompletedAsShadow
      }
    }
  `,
};

/**
 * Provides shadow pending count for the module member irrespective of the current Training role
 */
export const getShadowPendingCount: FunctionWithFragments<
  {
    moduleMember: GetShadowPendingCount_ModuleMemberFragment;
  },
  undefined,
  number
> = ({ moduleMember }) => {
  const count = moduleMember.shadowsRequired - getShadowCompletedCount({ moduleMember }, undefined);
  return count >= DEFAULT_COUNT ? count : DEFAULT_COUNT;
};
getShadowPendingCount.fragments = {
  moduleMember: gql`
    ${getShadowCompletedCount.fragments.moduleMember}
    fragment getShadowPendingCount_moduleMember on InterviewModuleMember {
      employeeId
      interviewModuleId
      shadowsRequired
      ...getShadowCompletedCount_moduleMember
    }
  `,
};

export const getReverseShadowCompletedCount: FunctionWithFragments<
  { moduleMember: GetReverseShadowCompletedCount_ModuleMemberFragment },
  undefined,
  number
> = ({ moduleMember }) => {
  return (
    (moduleMember.stats?.completedAsReverseShadow || DEFAULT_COUNT) +
    (moduleMember.stats?.manualCompletedAsReverseShadow || DEFAULT_COUNT)
  );
};
getReverseShadowCompletedCount.fragments = {
  moduleMember: gql`
    fragment getReverseShadowCompletedCount_moduleMember on InterviewModuleMember {
      employeeId
      interviewModuleId
      stats {
        completedAsReverseShadow
        manualCompletedAsReverseShadow
      }
    }
  `,
};

/**
 * Provides reverse shadow pending count for the module member irrespective of the current Training role
 */
export const getReverseShadowPendingCount: FunctionWithFragments<
  {
    moduleMember: GetReverseShadowPendingCount_ModuleMemberFragment;
  },
  undefined,
  number
> = ({ moduleMember }) => {
  const count = moduleMember.reverseShadowsRequired - getReverseShadowCompletedCount({ moduleMember }, undefined);
  return count >= DEFAULT_COUNT ? count : DEFAULT_COUNT;
};
getReverseShadowPendingCount.fragments = {
  moduleMember: gql`
    ${getReverseShadowCompletedCount.fragments.moduleMember}
    fragment getReverseShadowPendingCount_moduleMember on InterviewModuleMember {
      employeeId
      interviewModuleId
      reverseShadowsRequired
      ...getReverseShadowCompletedCount_moduleMember
    }
  `,
};

/**
 * Get total pending training count for the module member to be schedule in upcoming - (total pending )
 */
export const getModuleMemberPendingTrainingCount: FunctionWithFragments<
  {
    moduleMember: GetModuleMemberPendingTrainingCount_ModuleMemberFragment;
  },
  null,
  number
> = ({ moduleMember }) => {
  if (isModuleMemberTrained(moduleMember.status)) return DEFAULT_COUNT;
  const shadowPending = getShadowPendingCount({ moduleMember }, undefined);
  const reverseShadowPending = getReverseShadowPendingCount({ moduleMember }, undefined);
  const count = shadowPending + reverseShadowPending - moduleMember.stats?.upcoming || DEFAULT_COUNT;
  return count >= DEFAULT_COUNT ? count : DEFAULT_COUNT;
};
getModuleMemberPendingTrainingCount.fragments = {
  moduleMember: gql`
    ${getShadowPendingCount.fragments.moduleMember}
    ${getReverseShadowPendingCount.fragments.moduleMember}
    fragment getModuleMemberPendingTrainingCount_ModuleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      status
      stats {
        upcoming
      }
      ...getShadowPendingCount_moduleMember
      ...getReverseShadowPendingCount_moduleMember
    }
  `,
};

/**
 * Check if module member is disabled for scheduling, if all required interviews are scheduled or if module member needs approval
 * Needs approval to be checked for every role if module member is not fast tracked
 * If module member is fast tracked, then check for approval only for reverse shadow only
 * or need approval shadow with 0 reverse shadows required
 */
export const isModuleMemberDisabledForScheduling: FunctionWithFragments<
  { moduleMember: IsModuleMemberDisabledForScheduling_ModuleMemberFragment | undefined },
  null,
  boolean
> = ({ moduleMember }) => {
  if (!moduleMember) return true;

  if (moduleMember.status === TrainingStatus.Trained) return false;

  if (isModuleMemberAllRequiredInterviewsScheduled(moduleMember.stats?.currentTrainingSessionStatus || undefined)) {
    return true;
  }

  if (!moduleMember.interviewModule?.shouldFastTrackTraining) {
    return doesModuleMemberNeedsApproval(
      moduleMember.status,
      moduleMember.stats?.currentTrainingSessionStatus || undefined
    );
  }

  return getModuleMemberPendingTrainingCount({ moduleMember }, null) <= 0;
};
isModuleMemberDisabledForScheduling.fragments = {
  moduleMember: gql`
    ${getModuleMemberPendingTrainingCount.fragments.moduleMember}
    fragment isModuleMemberDisabledForScheduling_ModuleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      status
      shadowsRequired
      reverseShadowsRequired
      stats {
        currentTrainingSessionStatus
      }
      interviewModule {
        id
        shouldFastTrackTraining
      }
      ...getModuleMemberPendingTrainingCount_ModuleMember
    }
  `,
};

export const resetModuleMemberListCache = ({
  cache,
  employeeIds,
}: {
  cache: ApolloCache<unknown>;
  moduleId: string;
  employeeIds: string[];
}) => {
  apolloCacheDeleteByQuery(cache, 'interviewModuleMemberSearch');
  apolloCacheDeleteByQuery(cache, 'interviewModuleMemberLookup', {
    memberIds: employeeIds,
  });
};

export const getRoleToBeAdded = (shadowsRequired = 0, reverseShadowsRequired = 0) => {
  if (!shadowsRequired && !reverseShadowsRequired) {
    return TrainingStatus.Trained;
  }

  if (!shadowsRequired && reverseShadowsRequired) {
    return TrainingStatus.ReverseShadow;
  }
  return TrainingStatus.Shadow;
};
