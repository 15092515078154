import { logError } from '../utils/logError';
import { FeatureFlagNumber } from './FeatureFlagNumber';
import { useFeatureFlagLocal } from './useFeatureFlagLocal';
// eslint-disable-next-line no-restricted-imports
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';

/**
 * Number based feature flag checker.
 * It complains if it can't find the specified flag in the LaunchDarkly payload.
 *
 * https://docs.launchdarkly.com/home/getting-started/feature-flags
 */
export function useFeatureFlagNumber(key: FeatureFlagNumber): number {
  const flags = useFlags();
  const { localStorageFeatureFlags } = useFeatureFlagLocal();

  if (!(key in flags)) {
    if (!isEmpty(flags)) {
      logError(`Feature Flag "${key}" is not being sent to client`);
    }
    return 0;
  }

  if (flags['MLOnly'] === true) {
    const localFeatureFlagValue = localStorageFeatureFlags[key];
    if (localFeatureFlagValue !== undefined && typeof localFeatureFlagValue === 'number') {
      return localFeatureFlagValue;
    }
  }

  return flags[key];
}
