import { SvgIcon, SvgIconProps } from '@mui/material';

export const AppsIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;

  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.21742 2.55279C9.71009 2.30646 10.29 2.30646 10.7827 2.55279L16.441 5.38197C17.3623 5.84263 17.3623 7.15739 16.441 7.61804L10.7827 10.4472C10.29 10.6936 9.71009 10.6936 9.21742 10.4472L3.55906 7.61804C2.63775 7.15739 2.63775 5.84263 3.55906 5.38197L9.21742 2.55279ZM10.1118 3.89444C10.0415 3.85924 9.95862 3.85924 9.88824 3.89444L4.67709 6.50001L9.88824 9.10558C9.95862 9.14077 10.0415 9.14077 10.1118 9.10558L15.323 6.50001L10.1118 3.89444ZM2.82922 9.91461C3.01446 9.54412 3.46497 9.39395 3.83545 9.5792L9.88824 12.6056C9.95862 12.6408 10.0415 12.6408 10.1118 12.6056L16.1646 9.5792C16.5351 9.39395 16.9856 9.54412 17.1709 9.91461C17.3561 10.2851 17.2059 10.7356 16.8355 10.9208L10.7827 13.9472C10.29 14.1936 9.71009 14.1936 9.21742 13.9472L3.16463 10.9208C2.79415 10.7356 2.64398 10.2851 2.82922 9.91461ZM3.83545 13.0792C3.46497 12.894 3.01446 13.0441 2.82922 13.4146C2.64398 13.7851 2.79415 14.2356 3.16463 14.4208L9.21742 17.4472C9.71009 17.6936 10.29 17.6936 10.7827 17.4472L16.8355 14.4208C17.2059 14.2356 17.3561 13.7851 17.1709 13.4146C16.9856 13.0441 16.5351 12.894 16.1646 13.0792L10.1118 16.1056C10.0415 16.1408 9.95862 16.1408 9.88824 16.1056L3.83545 13.0792Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
