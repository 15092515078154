import React from 'react';

import BaseIcon, { Props } from './Icon';

const DeclinedInterviewIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.25C7.41422 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48121 17.75 5.99999L17.75 9C17.75 9.41421 17.4142 9.75 17 9.75C16.5858 9.75 16.25 9.41422 16.25 9L16.25 6C16.25 5.30964 15.6903 4.75 15 4.75H13.75V5C13.75 5.41421 13.4142 5.75 13 5.75C12.5858 5.75 12.25 5.41421 12.25 5V4.75H7.75V5C7.75 5.41421 7.41422 5.75 7 5.75C6.58579 5.75 6.25 5.41421 6.25 5V4.75H5C4.30965 4.75 3.75 5.30965 3.75 6L3.75002 15C3.75002 15.6904 4.30966 16.25 5.00002 16.25H9C9.41421 16.25 9.75 16.5858 9.75 17C9.75 17.4142 9.41421 17.75 9 17.75H5.00002C3.48124 17.75 2.25002 16.5188 2.25002 15L2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H6.25V2C6.25 1.58579 6.58579 1.25 7 1.25ZM15.5607 14.5L17.5303 12.5303C17.8232 12.2374 17.8232 11.7626 17.5303 11.4697C17.2374 11.1768 16.7626 11.1768 16.4697 11.4697L14.5 13.4393L12.5303 11.4697C12.2374 11.1768 11.7626 11.1768 11.4697 11.4697C11.1768 11.7626 11.1768 12.2374 11.4697 12.5303L13.4393 14.5L11.4697 16.4697C11.1768 16.7626 11.1768 17.2374 11.4697 17.5303C11.7626 17.8232 12.2374 17.8232 12.5303 17.5303L14.5 15.5607L16.4697 17.5303C16.7626 17.8232 17.2374 17.8232 17.5303 17.5303C17.8232 17.2374 17.8232 16.7626 17.5303 16.4697L15.5607 14.5ZM7 7.25C6.0335 7.25 5.25 8.0335 5.25 9V11C5.25 11.9665 6.0335 12.75 7 12.75H9C9.41421 12.75 9.75 12.4142 9.75 12C9.75 11.5858 9.41421 11.25 9 11.25H7C6.86193 11.25 6.75 11.1381 6.75 11V9C6.75 8.86193 6.86193 8.75 7 8.75H13C13.1381 8.75 13.25 8.86193 13.25 9V10C13.25 10.4142 13.5858 10.75 14 10.75C14.4142 10.75 14.75 10.4142 14.75 10V9C14.75 8.0335 13.9665 7.25 13 7.25H7Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DeclinedInterviewIcon;
