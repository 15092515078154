/**
 * Component to show a banner when the contract end date is near or has passed
 * The banner will show 7 days before the contract end date
 */
import React, { FC } from 'react';

import { LoggerEvent } from 'src/constants/logger';

import { OrgPrefName, useOrgPrefString, useOrgPrefTimestamp } from 'src/hooks/api/org';
import { useLogEventOnce } from 'src/hooks/useLogEvent';

import AppWideBanner from './AppWideBanner';

const DAYS_BEFORE_CONTRACT_END_DATE = 7;

const getCsmEmail = (csm: string | null): string => {
  switch (csm) {
    case 'JD':
      return '(jd@modernloop.io) ';
    case 'KIM_CASEY':
      return '(kim@modernloop.io) ';
    default:
      return '';
  }
};

type Props = {
  csm: string | null;
  contractEndDate: Date;
};

/**
 * Inner component that always shows the banner and logs the view event
 */
const ContractEndDateBannerInner: FC<Props> = ({ csm, contractEndDate }) => {
  useLogEventOnce(LoggerEvent.CLIENT_CONTRACT_END_BANNER_VIEWED);

  // now
  const now = new Date();

  // 1 day after contract end date
  const oneDayAfterContractEndDate = new Date(contractEndDate.getTime());
  oneDayAfterContractEndDate.setDate(contractEndDate.getDate() + 1);

  // if the contract end date is in the past
  const isPast = oneDayAfterContractEndDate.getTime() < now.getTime();

  const text = `Your ModernLoop contract ${
    isPast ? 'ended' : 'will be ending'
  } on ${contractEndDate.toLocaleDateString()}, please connect with your Customer Success Manager ${getCsmEmail(
    csm
  )}for more information.`;

  return <AppWideBanner color="foreground" backgroundColor="error" text={text} />;
};

// eslint-disable-next-line react/no-multi-comp -- This is a wrapper component
const ContractEndDateBanner: FC = () => {
  const [csm, { loading: isCsmLoading }] = useOrgPrefString(OrgPrefName.STR_CSM_NAME);
  const [contractEndDateIso, { loading: isContractEndDateLoading }] = useOrgPrefTimestamp(
    OrgPrefName.TS_COMPANY_CONTRACT_END_DATE
  );

  if (isCsmLoading || isContractEndDateLoading || !csm || !contractEndDateIso) {
    return null;
  }

  // Contract end date
  const contractEndDate = new Date(contractEndDateIso);

  // 7 days before contract end date
  const sevenDaysBeforeContractEndDate = new Date(contractEndDate.getTime());
  sevenDaysBeforeContractEndDate.setDate(contractEndDate.getDate() - DAYS_BEFORE_CONTRACT_END_DATE);

  // now
  const now = new Date();

  // if 7 day before date is in the future, don't show the banner
  if (sevenDaysBeforeContractEndDate.getTime() > now.getTime()) {
    return null;
  }

  return <ContractEndDateBannerInner csm={csm} contractEndDate={contractEndDate} />;
};

export default ContractEndDateBanner;
