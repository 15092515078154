import { CrossIcon } from '@modernloop/shared/icons';
import { ThemeOptions } from '@mui/material';

/**
 * View Chip props and classes at https://mui.com/material-ui/api/chip/#css
 */
export const chip: ThemeOptions['components'] = {
  MuiChip: {
    defaultProps: {
      deleteIcon: <CrossIcon />,
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        border: ownerState.color === 'secondary' ? `1px solid ${theme.palette.secondary.dark}` : undefined,
        borderRadius: '6px',
        padding: '0 4px',

        cursor: 'unset',
        maxWidth: '100%',
      }),
      outlined: ({ theme, ownerState }) => {
        const color = ownerState.color ?? 'default';
        if (color === 'secondary' || color === 'info') {
          return {
            border: `1px solid ${theme.palette[color].contrastText}`,
            color: theme.palette[color].contrastText,
          };
        }
      },
      label: ({ theme }) => ({
        ...theme.typography.body2,
        padding: '0 4px',
      }),
      icon: {
        marginLeft: '-2px',
        marginRight: 0,
      },
      iconMedium: {
        width: '20px',
        height: '20px',
      },
      iconSmall: {
        width: '16px',
        height: '16px',
      },
      avatar: {
        marginLeft: '-2px',
        marginRight: 0,
      },
      avatarMedium: {
        width: '20px',
        height: '20px',
      },
      avatarSmall: {
        width: '16px',
        height: '16px',
      },
      deleteIcon: {
        marginLeft: 0,
        marginRight: '-2px',
      },
      sizeSmall: {
        height: '20px',
      },
      sizeMedium: {
        height: '24px',
      },
    },
  },
};
