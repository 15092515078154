import React, { FC } from 'react';

import { DiamondFilled, ReverseShadowIcon, ShadowIcon } from '@modernloop/shared/icons';
import { Chip } from '@mui/material';

import { TrainingStatus } from 'src/generated/mloop-graphql';

interface Props {
  status: TrainingStatus;
}

const ModuleMemberTrainingRole: FC<Props> = ({ status }) => {
  switch (status) {
    case TrainingStatus.Shadow:
      return <Chip icon={<ShadowIcon color="inherit" />} label="Shadow" color="info" />;
    case TrainingStatus.ReverseShadow:
      return <Chip icon={<ReverseShadowIcon color="inherit" />} label="Reverse" color="primary" />;

    default:
      return <Chip icon={<DiamondFilled color="inherit" tooltip="Trained" />} label="Trained" color="success" />;
  }
};

export default ModuleMemberTrainingRole;
