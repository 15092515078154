import React from 'react';

import { CaretLeftIcon } from '@modernloop/shared/icons';
import {
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  Theme,
  Typography,
} from '@mui/material';

import useGoBackOrHome from 'src/hooks/useGoBackOrGoHome';
import useHistory from 'src/hooks/useHistory';

import { useGetOrgSettingsNavItems } from './useGetOrgSettingsNavItems';

export const SettingsNav = () => {
  const navItems = useGetOrgSettingsNavItems();
  const goBackOrGoHome = useGoBackOrHome();
  const history = useHistory();

  return (
    <Stack
      minWidth="240px"
      height="100%"
      sx={{
        backgroundColor: (theme: Theme) => theme.palette.background.contrast,
        flexShrink: 0,
        flexWrap: 'nowrap',
        height: '100%',
        overflowX: 'hidden',
      }}
    >
      <Stack
        direction="row"
        gap={0.5}
        alignItems="center"
        padding={(theme: Theme) => {
          return `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2)}`;
        }}
      >
        <IconButton onClick={goBackOrGoHome}>
          <CaretLeftIcon />
        </IconButton>
        <Typography variant="subtitle1">Settings</Typography>
      </Stack>
      {navItems.map((navItem) => {
        return (
          <Stack key={navItem.id}>
            <List>
              <Stack px={0.5}>
                <ListSubheader>{navItem.label}</ListSubheader>
                {navItem.navs.map((nav) => {
                  if (!nav.isShown) {
                    return null;
                  }
                  return (
                    <ListItemButton
                      onClick={() => {
                        history.replace(nav.to);
                      }}
                      key={nav.id}
                      dense
                      selected={nav.isActive}
                      sx={
                        nav.isActive
                          ? {
                              backgroundColor: (theme: Theme) => `${theme.palette.primary.main} !important`,
                            }
                          : {}
                      }
                    >
                      <ListItemIcon
                        sx={
                          nav.isActive
                            ? {
                                color: (theme: Theme) => theme.palette.common.white,
                              }
                            : { color: (theme: Theme) => theme.palette.text.primary }
                        }
                      >
                        {nav.icon}
                      </ListItemIcon>
                      <ListItemText
                        sx={
                          nav.isActive
                            ? {
                                color: (theme: Theme) => theme.palette.common.white,
                              }
                            : {}
                        }
                      >
                        {nav.label}
                      </ListItemText>
                    </ListItemButton>
                  );
                })}
              </Stack>
            </List>
            {navItem.showDivider && (
              <Divider
                sx={{
                  my: 1.5,
                }}
              />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
