import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ErrorIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18.0001C14.4183 18.0001 18 14.4183 18 10.0001C18 5.58178 14.4183 2.00006 10 2.00006C5.58172 2.00006 2 5.58178 2 10.0001C2 14.4183 5.58172 18.0001 10 18.0001ZM8.93774 5.50364C9.08011 5.34237 9.28488 5.25 9.5 5.25H10.5C10.7151 5.25 10.9199 5.34237 11.0623 5.50364C11.2046 5.66492 11.2709 5.87956 11.2442 6.09302L10.7442 10.093C10.6973 10.4683 10.3782 10.75 10 10.75C9.62176 10.75 9.30271 10.4683 9.25579 10.093L8.75579 6.09302C8.72911 5.87956 8.79537 5.66492 8.93774 5.50364ZM11 13.5C11 14.0523 10.5523 14.5 10 14.5C9.44771 14.5 9 14.0523 9 13.5C9 12.9477 9.44771 12.5 10 12.5C10.5523 12.5 11 12.9477 11 13.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
