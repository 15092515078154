import React, { useCallback, useMemo, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Box, Chip, Link, Stack, Tooltip, Typography } from '@mui/material';

import { Candidate_CandidateFragment } from 'src/generated/mloop-graphql';

import { useShowTooltip } from 'src/components/tooltip/MaybeTooltip';

import { getCandidateAtsField } from 'src/hooks/atsService/util';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import getCandidateDetailsUrl from 'src/urls/getCandidateDetailsUrl';

type Fragments = {
  candidate: Candidate_CandidateFragment;
};

type Props = {
  applicationId?: string;
  color?: 'inherit' | 'primary';
  variant?: 'subtitle2' | 'body1' | 'h4';
  onClick?: () => void;
};

const Candidate: FCWithFragments<Fragments, Props> = ({
  applicationId,
  candidate,
  color = 'inherit',
  variant = 'body1',
  onClick,
}) => {
  const labelRef = useRef<HTMLSpanElement>(null);
  const showLabelTooltip = useShowTooltip(labelRef, candidate?.fullName || '');

  const internalCandidateChipRef = useRef<HTMLDivElement>(null);
  const showInternalCandidateChipTooltip = useShowTooltip(internalCandidateChipRef, 'Internal candidate');

  const isInternalCandidate = getCandidateAtsField(candidate?.atsCandidate?.atsFields, 'origin') === 'internal';

  const candidateDetailsUrl = useMemo(() => {
    if (!candidate) return undefined;
    return getCandidateDetailsUrl(candidate.id, { applicationId });
  }, [applicationId, candidate]);

  const handleClick = useCallback(() => {
    if (!onClick) return;
    onClick();
  }, [onClick]);

  const candidateNameJsx = (
    <Tooltip title={showLabelTooltip ? candidate?.fullName : undefined}>
      <Typography
        fontWeight={variant === 'body1' ? 600 : undefined}
        variant={variant}
        noWrap
        sx={{ minWidth: 0 }}
        ref={labelRef}
      >
        {candidate?.fullName}
      </Typography>
    </Tooltip>
  );

  // Creating a separate JSX element for the internal candidate chip so that
  // tooltip shows only when the entire text is not visible.
  const internalCandidateLabel = (
    <Tooltip title={showInternalCandidateChipTooltip ? 'Internal candidate' : undefined}>
      <Typography noWrap ref={internalCandidateChipRef}>
        Internal candidate
      </Typography>
    </Tooltip>
  );

  return (
    <ConditionalThemeProvider>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box maxWidth={isInternalCandidate ? '60%' : '100%'}>
          <Link
            color={color}
            onClick={handleClick}
            underline="hover"
            {...{ component: RouterLink, to: candidateDetailsUrl }}
          >
            {candidateNameJsx}
          </Link>
        </Box>
        {isInternalCandidate && (
          <Chip
            variant="outlined"
            size={variant === 'h4' ? 'medium' : 'small'}
            label={internalCandidateLabel}
            sx={{ backgroundColor: (theme) => theme.palette.background.default, minWidth: 0, maxWidth: '100%' }}
          />
        )}
      </Stack>
    </ConditionalThemeProvider>
  );
};

Candidate.fragments = {
  candidate: gql`
    fragment Candidate_candidate on Candidate {
      id
      fullName
      atsCandidate {
        atsId
        atsFields {
          ... on LeverCandidate {
            origin
          }
        }
      }
    }
  `,
};

export default Candidate;
