import React, { useCallback, useState } from 'react';

import ResetPng from 'src/assets/images/reset.png';

import Dialog from 'src/components/Dialog';
import Label from 'src/components/label';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import updateCandidateAvailability from 'src/utils/api/candidate/updateCandidateAvailability';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ResetAvailabilityModalProps = {
  applicationId: string;
  jobStageId: string;
  candidateTimezone: string;
  taskId?: string;
  onClose: () => void;
  onConfirm: () => void;
};

const ResetAvailabilityModal = ({
  applicationId,
  candidateTimezone,
  jobStageId,
  taskId,
  onClose,
  onConfirm,
}: ResetAvailabilityModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const refetchActivityFeed = useActivityFeedRefetch();
  const refetchScheduleTask = useRefetchScheduleTaskQuery();

  const handleResetAndContinue = useCallback(() => {
    setIsSubmitting(true);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    updateCandidateAvailability({
      applicationId,
      jobStageId,
      candidateTimezone,
      taskId,
      candidateAvailability: [],
    }) // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      .then(async () => {
        await Promise.all([refetchActivityFeed(), refetchScheduleTask()]);
        onConfirm();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [applicationId, candidateTimezone, jobStageId, onConfirm, refetchActivityFeed, refetchScheduleTask, taskId]);

  return (
    <Dialog
      isOpen
      decoration={<img src={ResetPng} alt="Reset availability" height="64px" width="64px" />}
      title="Current availability will be reset"
      onClose={onClose}
      cancelOptions={{ label: 'Cancel', isDisabled: isSubmitting, onClick: onClose }}
      submitOptions={{
        label: 'Reset & continue',
        isDisabled: isSubmitting,
        isLoading: isSubmitting,
        onClick: handleResetAndContinue,
      }}
    >
      <Label>Creating a new request will remove all current availability.</Label>
    </Dialog>
  );
};

export default ResetAvailabilityModal;
