import React from 'react';

import BaseIcon, { Props } from './Icon';

const Preferences = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path d="M4 5H12" stroke="#787882" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M16 10H8" stroke="#787882" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M12 15H4" stroke="#787882" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="14" cy="5" r="2" stroke="#787882" strokeWidth="1.5" />
      <circle cx="14" cy="15" r="2" stroke="#787882" strokeWidth="1.5" />
      <circle cx="6" cy="10" r="2" stroke="#787882" strokeWidth="1.5" />
    </BaseIcon>
  );
};

export default Preferences;
