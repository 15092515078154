import { SvgIcon, SvgIconProps } from './SvgIcon';

export const SearchIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 9C5.25 6.92893 6.92893 5.25 9 5.25C11.0711 5.25 12.75 6.92893 12.75 9C12.75 11.0711 11.0711 12.75 9 12.75C6.92893 12.75 5.25 11.0711 5.25 9ZM9 3.75C6.1005 3.75 3.75 6.10051 3.75 9C3.75 11.8995 6.1005 14.25 9 14.25C10.1792 14.25 11.2677 13.8612 12.1441 13.2048L14.9697 16.0303C15.2626 16.3232 15.7374 16.3232 16.0303 16.0303C16.3232 15.7374 16.3232 15.2626 16.0303 14.9697L13.2048 12.1441C13.8612 11.2677 14.25 10.1793 14.25 9C14.25 6.10051 11.8995 3.75 9 3.75Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
