import { SvgIcon, SvgIconProps } from '@mui/material';

export const DateIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;

  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.25C7.41422 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48121 17.75 5.99999L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H6.25V2C6.25 1.58579 6.58579 1.25 7 1.25ZM7 4.75L6.99829 4.75H5C4.30964 4.75 3.75 5.30964 3.75 6L3.75001 15C3.75001 15.6904 4.30966 16.25 5.00001 16.25H15C15.6904 16.25 16.25 15.6904 16.25 15L16.25 6C16.25 5.30964 15.6903 4.75 15 4.75H13.0017L13 4.75L12.9983 4.75H7.00171L7 4.75ZM7 8C6.44772 8 6 8.44772 6 9V11C6 11.5523 6.44772 12 7 12H9C9.55228 12 10 11.5523 10 11V9C10 8.44772 9.55228 8 9 8H7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
