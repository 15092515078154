import React, { FC } from 'react';
import { Redirect } from 'react-router';

import { inIframe } from '@modernloop/shared/utils';

/**
 * Guard that checks that the user is not in an iframe.
 * Note: Guards should check only one thing. Don't add any other checks to this Guard.
 */
const IPUserNotInIframeGuard: FC = ({ children }) => {
  if (inIframe()) {
    return <Redirect to="/ip/extension" />;
  }

  // Don't add components here, please add components either on the app or on the appropriate Layout component
  return <>{children}</>;
};

export default IPUserNotInIframeGuard;
