import { logMessage } from '../utils/logMessage';
import { IsoDate, isIsoDate } from './IsoDate';

export type IsoTimestamp = string & {
  __type: 'Time represented in ISO 8601, YYYY-MM-DDTHH:mm:ss.sssZ | YYYY-MM-DDTHH:mm:ssZ format';
};

export default IsoTimestamp;

export type IsoDateTimeRange = {
  startAt: IsoTimestamp;
  endAt: IsoTimestamp;
};

export const isIsoTimestamp = (input: string): input is IsoTimestamp => {
  const dateMs = Date.parse(input);

  if (Number.isNaN(dateMs)) {
    return false;
  }
  return new Date(input).toISOString() === input || new Date(input).toISOString().replace(/.\d\d\dZ$/g, 'Z') === input;
};

export const assertIsoTimestamp = (input: string, environment = 'development'): IsoTimestamp => {
  if (!isIsoTimestamp(input)) {
    if (environment.toLowerCase() !== 'production') {
      throw Error(`'${input}' is not a valid ISO date string`);
    } else {
      logMessage(`'${input}' is not a valid ISO date string`, 'error');
      return input as IsoTimestamp;
    }
  }

  return input;
};

export const assertIsoDateTimeRange = (input: { startAt: string; endAt: string }): IsoDateTimeRange => {
  return {
    startAt: assertIsoTimestamp(input.startAt),
    endAt: assertIsoTimestamp(input.endAt),
  };
};

export const convertToIsoTimestamp = (dateString: IsoDate): IsoTimestamp => {
  // Manually parse the date string into year, month, and day
  const [year, month, day] = dateString.split('-').map(Number);

  // Create a Date object using the local time zone
  const date = new Date(year, month - 1, day);

  return assertIsoTimestamp(date.toISOString());
};

export const guessIsoTimestamp = (input: string | Date): IsoTimestamp => {
  if (input instanceof Date) {
    const ret = input.toISOString();
    return assertIsoTimestamp(ret);
  }

  if (isIsoTimestamp(input)) {
    return input;
  }

  if (isIsoDate(input)) {
    return convertToIsoTimestamp(input);
  }

  const dateMs = Date.parse(input);
  if (Number.isNaN(dateMs)) {
    return assertIsoTimestamp(input); // force error
  }

  return assertIsoTimestamp(new Date(input).toISOString());
};
