import React from 'react';

import BaseIcon, { Props } from './Icon';

const ReorderIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} viewBox="0 0 21 20">
      <circle cx="12.2999" cy="6" r="1" fill="currentColor" />
      <circle cx="8.29987" cy="6" r="1" fill="currentColor" />
      <circle cx="12.2999" cy="10" r="1" fill="currentColor" />
      <circle cx="8.29987" cy="10" r="1" fill="currentColor" />
      <circle cx="12.2999" cy="14" r="1" fill="currentColor" />
      <circle cx="8.29987" cy="14" r="1" fill="currentColor" />
    </BaseIcon>
  );
};

export default ReorderIcon;
