import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { IsoTimestamp, assertIsoDate, formatDate, getLocalTimezone } from '@modernloop/shared/datetime';
import { BeverageCoffeeIcon, DateIcon, InfoIcon } from '@modernloop/shared/icons';
import { Timeline, timelineItemClasses } from '@mui/lab';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { formatDuration, intervalToDuration, parseISO } from 'date-fns';

import { ScheduleContent_ApplicationFragment, ScheduleContent_ScheduleFragment } from 'src/generated/mloop-graphql';

import useWorkdayEnabled from 'src/hooks/atsService/useWorkdayEnabled';

import TimelineItemWrapper from '../common/TimelineItemWrapper';

import HoldForCandidateCommunications from './HoldForCandidateCommunications';
import ScheduleContentActions from './ScheduleContentActions';
import ScheduleContentInterview from './ScheduleContentInterview';
import getCandidateEventByInterviewId from './utils/getCandidateEventByInterviewId';
import groupInterviewEventsByDate from './utils/groupInterviewEventsByDate';

type Props = {
  hideActions?: boolean;
};

type Fragments = {
  application: ScheduleContent_ApplicationFragment;
  schedule: ScheduleContent_ScheduleFragment;
};

const ScheduleContent: FCWithFragments<Fragments, Props> = ({ application, schedule, hideActions = false }) => {
  const timezone = getLocalTimezone();
  const isWorkdayEnabled = useWorkdayEnabled();

  const interviewsGroupedByDate = groupInterviewEventsByDate(
    { interviews: schedule.applicationStageInterviews || [] },
    { timezone }
  );

  const interviewsByCandidateEventId = getCandidateEventByInterviewId({
    interviews: schedule.applicationStageInterviews || [],
    candidateEvents: schedule.applicationStageCandidateEvents || [],
  });

  return (
    <Box>
      {schedule.frozenAt && isWorkdayEnabled && !hideActions && (
        <Box mx={2} mt={1}>
          <Alert variant="filled" color="info" icon={<InfoIcon color="inherit" />}>
            <Stack>
              <Typography fontWeight={600}>Stage changed after scheduling</Typography>
              <Typography variant="body2">Schedules may not be changed after a stage has been changed.</Typography>
            </Stack>
          </Alert>
        </Box>
      )}

      {!hideActions && (
        <ScheduleContentActions
          applicationStage={schedule}
          applicationStatus={application.status}
          interviews={schedule.applicationStageInterviews || []}
        />
      )}

      <Timeline
        position="right"
        sx={{
          backgroundColor: (theme) => theme.palette.background.contrast,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <HoldForCandidateCommunications applicationStage={schedule} />

        {Object.keys(interviewsGroupedByDate).map((date) => {
          const interviews = interviewsGroupedByDate[date];
          const jsx: JSX.Element[] = [];
          let prevInterviewEndAt: IsoTimestamp | undefined;

          jsx.push(
            <TimelineItemWrapper icon={<DateIcon color="action" />} key={date}>
              <Typography variant="subtitle2">{formatDate(assertIsoDate(date), timezone, 'MMMM d, yyyy')}</Typography>
            </TimelineItemWrapper>
          );

          interviews.forEach((interview) => {
            const candidateEvent = schedule.applicationStageCandidateEvents?.find(
              (event) => event.id === interviewsByCandidateEventId[interview.id]
            );

            if (prevInterviewEndAt && prevInterviewEndAt !== interview.startAt) {
              const breakDuration = formatDuration(
                intervalToDuration({ start: parseISO(prevInterviewEndAt), end: parseISO(interview.startAt) })
              );

              jsx.push(
                <TimelineItemWrapper
                  icon={<BeverageCoffeeIcon color="action" />}
                  key={`break-${interview.id}`}
                  sx={{ marginBottom: '8px' }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {breakDuration} break
                  </Typography>
                </TimelineItemWrapper>
              );
            }

            jsx.push(
              <TimelineItemWrapper icon={undefined} key={interview.id}>
                <ScheduleContentInterview
                  applicationStageId={schedule.id}
                  applicationStatus={application.status}
                  interview={interview}
                  candidateEvent={candidateEvent}
                  timezone={timezone}
                  frozenAt={schedule.frozenAt}
                />
              </TimelineItemWrapper>
            );

            prevInterviewEndAt = interview.endAt;
          });

          return jsx;
        })}

        <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 1 }}>
          Created {formatDate(schedule.createdAt, timezone, 'MMM d, yyyy, h:mm aaa')} by {schedule.creator?.fullName}
        </Typography>
      </Timeline>
    </Box>
  );
};

ScheduleContent.fragments = {
  application: gql`
    fragment ScheduleContent_application on Application {
      id
      status
    }
  `,
  schedule: gql`
    ${HoldForCandidateCommunications.fragments.applicationStage}
    ${ScheduleContentActions.fragments.interviews}
    ${ScheduleContentActions.fragments.applicationStage}
    ${ScheduleContentInterview.fragments.interview}
    ${ScheduleContentInterview.fragments.candidateEvent}
    ${groupInterviewEventsByDate.fragments.interviews}
    ${getCandidateEventByInterviewId.fragments.interviews}
    ${getCandidateEventByInterviewId.fragments.candidateEvents}

    fragment ScheduleContent_schedule on ApplicationStage {
      id
      createdAt
      frozenAt
      creator {
        id
        fullName
      }

      applicationStageInterviews(input: { orderBy: { startAt: ASC }, isCancelled: false }) {
        id
        ...ScheduleContentActions_interviews
        ...ScheduleContentInterview_interview
        ...groupInterviewEventsByDate_interviews
        ...getCandidateEventByInterviewId_interviews
      }

      applicationStageCandidateEvents(input: { orderBy: { createdAt: DESC } }) {
        id
        ...ScheduleContentInterview_candidateEvent
        ...getCandidateEventByInterviewId_candidateEvents
      }

      ...HoldForCandidateCommunications_applicationStage
      ...ScheduleContentActions_applicationStage
    }
  `,
};

export default ScheduleContent;
