import React, { useMemo, useRef, useState } from 'react';

import { DynamicLinkType } from 'src/generated/mloop-graphql';

import IconButton from 'src/components/IconButton';
import Button from 'src/components/button';
import { CodeIcon, CodeSignalIcon, CoderPadIcon, CodilityIcon, CrossIcon, HackerRankIcon } from 'src/components/icons';
import Label from 'src/components/label';
import Menu, { MenuOption } from 'src/components/menu';

import { useIntegrations } from 'src/hooks/api/integration';

import { updateJobStageInterview } from 'src/store/actions/job-stage-interview';
import { getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';

import { useDispatch, useSelector } from 'src/store';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type CodingLinkProps = {
  jobStageInterviewId: string;
  onUpdated: () => void;
};

const CodingLink = ({ jobStageInterviewId, onUpdated }: CodingLinkProps) => {
  const dispatch = useDispatch();
  const jobStageInterview = useSelector((state) => getJobStageInterviewById(state, jobStageInterviewId));
  const { data: integrations } = useIntegrations();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const selectedDynamicLinkType = useMemo(() => {
    return jobStageInterview?.dynamicLinkTypes && jobStageInterview?.dynamicLinkTypes.length
      ? jobStageInterview.dynamicLinkTypes[0]
      : undefined;
  }, [jobStageInterview]);

  const menuOptions = useMemo(() => {
    const options: MenuOption[] = [];

    if (integrations?.codility) {
      options.push({
        id: DynamicLinkType.Codility,
        icon: <CodilityIcon />,
        value: 'Codility',
        selected: selectedDynamicLinkType === DynamicLinkType.Codility,
      });
    }

    if (integrations?.coderpad) {
      options.push({
        id: DynamicLinkType.Coderpad,
        icon: <CoderPadIcon />,
        value: 'Coderpad',
        selected: selectedDynamicLinkType === DynamicLinkType.Coderpad,
      });
    }

    if (integrations?.codesignal) {
      options.push({
        id: DynamicLinkType.Codesignal,
        icon: <CodeSignalIcon />,
        value: 'CodeSignal',
        selected: selectedDynamicLinkType === DynamicLinkType.Codesignal,
      });
    }

    if (integrations?.hackerrank) {
      options.push({
        id: DynamicLinkType.Hackerrank,
        icon: <HackerRankIcon />,
        value: 'HackerRank',
        selected: selectedDynamicLinkType === DynamicLinkType.Hackerrank,
      });
    }

    return options;
  }, [integrations, selectedDynamicLinkType]);

  const codingLinkIcon = useMemo(() => {
    if (!selectedDynamicLinkType) return null;
    if (selectedDynamicLinkType === DynamicLinkType.Coderpad) return <CoderPadIcon />;
    if (selectedDynamicLinkType === DynamicLinkType.Codility) return <CodilityIcon />;
    if (selectedDynamicLinkType === DynamicLinkType.Codesignal) return <CodeSignalIcon />;
    if (selectedDynamicLinkType === DynamicLinkType.Hackerrank) return <HackerRankIcon />;

    return null;
  }, [selectedDynamicLinkType]);

  const codingLinkLabel = useMemo(() => {
    if (selectedDynamicLinkType === DynamicLinkType.Coderpad) return 'Coderpad';
    if (selectedDynamicLinkType === DynamicLinkType.Codesignal) return 'CodeSignal';
    if (selectedDynamicLinkType === DynamicLinkType.Codility) return 'Codility';
    if (selectedDynamicLinkType === DynamicLinkType.Hackerrank) return 'HackerRank';

    return '';
  }, [selectedDynamicLinkType]);

  const hasCodingIntegration = useMemo(() => {
    return Boolean(
      integrations?.coderpad || integrations?.codility || integrations?.codesignal || integrations?.hackerrank
    );
  }, [integrations]);

  const handleUpdateCodingLink = (option: MenuOption) => {
    if (!option.id) return;
    dispatch(updateJobStageInterview({ id: jobStageInterviewId, dynamicLinkTypes: [option.id as DynamicLinkType] }));
    onUpdated();
  };

  const handleShowCodingLinkMenu = () => {
    if (menuOptions.length === 1) {
      if (selectedDynamicLinkType) return;
      handleUpdateCodingLink(menuOptions[0]);
      return;
    }

    setShowMenu(true);
  };

  const handleRemoveCodingLink = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    dispatch(updateJobStageInterview({ id: jobStageInterviewId, dynamicLinkTypes: undefined }));
    onUpdated();

    // To not show the menu
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      {(!jobStageInterview.dynamicLinkTypes || jobStageInterview.dynamicLinkTypes.length === 0) && (
        <IconButton
          disabled={!hasCodingIntegration}
          ref={buttonRef}
          color={showMenu ? 'min-contrast-grey' : undefined}
          tooltip={hasCodingIntegration ? 'Select coding tool' : 'No coding tool is integrated'}
          onClick={handleShowCodingLinkMenu}
        >
          <CodeIcon />
        </IconButton>
      )}
      {jobStageInterview.dynamicLinkTypes && Boolean(jobStageInterview.dynamicLinkTypes.length) && (
        <Button
          size="small"
          ref={buttonRef}
          label={
            <Label variant="captions" fontWeight={500} color="max-contrast-grey" noWrap>
              {codingLinkLabel}
            </Label>
          }
          startIcon={codingLinkIcon}
          variant="contained"
          color="default"
          onClick={handleShowCodingLinkMenu}
          endIcon={
            <IconButton onClick={handleRemoveCodingLink}>
              <CrossIcon />
            </IconButton>
          }
          tooltip={codingLinkLabel || undefined}
        />
      )}

      {showMenu && (
        <Menu
          open
          disableScrollLock
          id={`job-stage-interview-coding-link-${jobStageInterviewId}`}
          anchorEl={buttonRef.current}
          options={menuOptions}
          onSelect={handleUpdateCodingLink}
          onClose={() => setShowMenu(false)}
        />
      )}
    </>
  );
};

export default CodingLink;
