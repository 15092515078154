/* eslint-disable max-lines */
import React, { useMemo, useState } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { InputAdornment, SvgIcon } from '@material-ui/core';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Box, Divider, LinearProgress, Stack } from '@mui/material';

import {
  BaseSlackChannelCard_InterviewPlanFragment,
  SlackChannelCard_InterviewPlanFragment,
  TemplateType,
} from 'src/generated/mloop-graphql';

import Editor from 'src/components/Editor';
import IconButton from 'src/components/IconButton';
import Paper from 'src/components/Paper';
import TextField from 'src/components/TextField';
import { LockIcon, TrashIcon } from 'src/components/icons';
import Label from 'src/components/label';

import EmployeePickerExtended from 'src/entities/EmployeePicker/EmployeePickerExtended';
import TemplateSelect from 'src/entities/Template/TemplateSelect';

import { OrgPrefName, useOrgPrefString } from 'src/hooks/api/org-grpc';

import { updateLastUsedSlackChannelMessageTemplateID } from 'src/slices/persist';

import {
  updateConversationId,
  updateRemoteSlackChannelId,
  updateSlackChannelEnabled,
  updateSlackChannelHiringManagerEmployeeIDs,
  updateSlackChannelInterviewerEmployeeIDs,
  updateSlackChannelMessage,
  updateSlackChannelMessageTemplateID,
  updateSlackChannelName,
  updateSlackChannelRecruitingTeamEmployeeIDs,
} from 'src/store/actions/schedule-communications';

import { Theme as ThemeV5 } from 'src/themeMui5/type';

import { EmployeePrefName } from 'src/utils/api/employee';
import getCleanSlackChannelName from 'src/utils/slack/getCleanSlackChannelName';
import getSlackChannelName, { getSlackChannelFormat } from 'src/utils/slack/getSlackChannelName';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { useDispatch, useSelector } from 'src/store';

import { useCommunicationTemplatesPref } from '../useCommunicationTemplatesPref';

import ExistingChannelModal from './ExistingChannelModal';
import ExistingSlackChannelsMenu, { ExistingChannel, SlackMenuIds } from './ExistingSlackChannelsMenu';
import useSlackTemplateContent from './useSlackTemplateContent';

const useSxProps = (loading) => {
  return useMemo(() => {
    return {
      root: {
        marginBottom: (theme: ThemeV5) => theme.spacing(4),
      },
      editor: {
        '& .ql-editor': {
          height: 400,
        },
        opacity: loading ? 0.5 : 1,
      },
      paper: {
        backgroundColor: (theme: ThemeV5) => theme.palette.background.alternate,
        border: (theme: ThemeV5) => `1px solid ${theme.palette.border}`,
        borderRadius: '6px',
      },
    };
  }, [loading]);
};

enum SlackChannelIdType {
  CLEAR,
  CONVERSATION,
  SLACK_CHANNEL,
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type BaseSlackChannelCardFragments = {
  interviewPlan: BaseSlackChannelCard_InterviewPlanFragment | undefined;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type BaseSlackChannelCardProps = {
  applicationId: string;
  isEnabled: boolean;
  defaultChannelName: string;
  slackChannelId: string;
  slackChannelName: string;
  slackMessageContent: string;
  templateId: string;
  interviewerEmployeeIDs: string[];
  recruitingTeamEmployeeIDs: string[];
  hiringTeamEmployeeIDs: string[];
  templateTypes?: TemplateType[];
  onSlackChannelIdChange: (id, type: SlackChannelIdType) => void;
  onSlackChannelToggle: (isEnabled) => void;
  onChannelNameChange: (name) => void;
  onSlackMessageChange: (content) => void;
  onTemplateChange: (templateId) => void;
  onHiringManagersChange: (employeeIDs) => void;
  onRecruitersChange: (employeeIDs) => void;
  onInterviewersChange: (employeeIDs) => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs, modernloop/restric-fragments-name.cjs
const BaseSlackChannelCard: FCWithFragments<BaseSlackChannelCardFragments, BaseSlackChannelCardProps> = ({
  applicationId,
  isEnabled,
  defaultChannelName,
  slackChannelId,
  slackChannelName,
  slackMessageContent,
  templateId,
  interviewerEmployeeIDs,
  recruitingTeamEmployeeIDs,
  hiringTeamEmployeeIDs,
  templateTypes,
  interviewPlan,
  onSlackChannelIdChange,
  onSlackChannelToggle,
  onChannelNameChange,
  onSlackMessageChange,
  onTemplateChange,
  onHiringManagersChange,
  onRecruitersChange,
  onInterviewersChange,
}) => {
  const [showExistingChannelModal, setShowExstingChannelModal] = useState<boolean>(false);
  const [existingChannels, setExistingChannels] = useState<ExistingChannel[]>([]);

  const loading = useSlackTemplateContent({ interviewPlan }, { templateId });
  const sxProps = useSxProps(loading);

  if (!isEnabled) return null;

  return (
    <Paper sx={sxProps.paper}>
      <Stack spacing={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Label variant="body" fontWeight={600}>
            Slack
          </Label>
          <IconButton onClick={() => onSlackChannelToggle(!isEnabled)}>
            <TrashIcon />
          </IconButton>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Label>Send to</Label>
          <div style={{ flexGrow: 1 }}>
            <ExistingSlackChannelsMenu
              applicationId={applicationId}
              selectedConversationId={slackChannelId}
              existingSlackChannels={existingChannels}
              onSelect={(option) => {
                if (option.id === SlackMenuIds.NEW_CHANNEL_ID) {
                  onSlackChannelIdChange('', SlackChannelIdType.CLEAR);
                  onChannelNameChange(defaultChannelName);
                } else if (option.id === SlackMenuIds.EXISTING_CHANNEL_ID) {
                  setShowExstingChannelModal(true);
                } else {
                  if (existingChannels?.map((obj) => obj.id)?.includes(option.id)) {
                    onSlackChannelIdChange(option.id, SlackChannelIdType.SLACK_CHANNEL);
                  } else {
                    onSlackChannelIdChange(option.id, SlackChannelIdType.CONVERSATION);
                  }
                  onChannelNameChange(option.value);
                }
              }}
            />
          </div>
        </Stack>
        {!slackChannelId && (
          <TextField
            fullWidth
            variant="outlined"
            label="Private Channel Name"
            placeholder={defaultChannelName}
            value={slackChannelName}
            onChange={(event) => onChannelNameChange(getCleanSlackChannelName(event.target.value))}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <LockIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              maxLength: 76,
              spellCheck: false,
            }}
          />
        )}
        <EmployeePickerExtended
          dynamicAudiencesEnabled={false}
          freeformEnabled={false}
          label="Interviewers"
          selectedEmployeeIds={interviewerEmployeeIDs}
          onChange={(employeeResult) => {
            onInterviewersChange(
              employeeResult.map((value) => {
                if (typeof value !== 'string') return value.id;

                return value;
              })
            );
          }}
        />
        <EmployeePickerExtended
          dynamicAudiencesEnabled={false}
          freeformEnabled={false}
          label="Recruiting Team"
          selectedEmployeeIds={recruitingTeamEmployeeIDs}
          onChange={(employeeResult) => {
            onRecruitersChange(
              employeeResult.map((value) => {
                if (typeof value !== 'string') return value.id;

                return value;
              })
            );
          }}
        />
        <EmployeePickerExtended
          dynamicAudiencesEnabled={false}
          freeformEnabled={false}
          label="Hiring Managers"
          selectedEmployeeIds={hiringTeamEmployeeIDs}
          onChange={(employeeResult) => {
            onHiringManagersChange(
              employeeResult.map((value) => {
                if (typeof value !== 'string') return value.id;

                return value;
              })
            );
          }}
        />
        <Divider />
      </Stack>
      <Stack mt={1} direction="column" spacing={1}>
        <Box display="flex">
          <TemplateSelect
            minimalUX
            useSelectButton
            selectedTemplateId={templateId}
            onSelect={(newTemplateId) => {
              onTemplateChange(newTemplateId);
            }}
            types={templateTypes}
          />
        </Box>
        {loading && <LinearProgress sx={{ width: '100%' }} />}
        <Editor
          readOnly={loading}
          value={slackMessageContent}
          onChange={(message) => onSlackMessageChange(message)}
          sx={sxProps.editor}
        />
        <ExistingChannelModal
          isOpen={showExistingChannelModal}
          onConfirm={(id, name) => {
            setExistingChannels([
              ...existingChannels,
              {
                id,
                remoteName: name,
                isArchived: false,
              },
            ]);
            onSlackChannelIdChange(id, SlackChannelIdType.SLACK_CHANNEL);
            onChannelNameChange(name);
            setShowExstingChannelModal(false);
          }}
          onClose={() => {
            setShowExstingChannelModal(false);
          }}
        />
      </Stack>
    </Paper>
  );
};

BaseSlackChannelCard.fragments = {
  interviewPlan: gql`
    ${useSlackTemplateContent.fragments.interviewPlan}
    fragment BaseSlackChannelCard_interviewPlan on JobStage {
      id
      ...useSlackTemplateContent_interviewPlan
    }
  `,
};

type Fragments = {
  interviewPlan: SlackChannelCard_InterviewPlanFragment | undefined;
};

type Props = {
  scheduleId: string;
  candidateName: string;
  startAt: Date;
  candidateTimezone: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const SlackChannelCard: FCWithFragments<Fragments, Props> = ({
  interviewPlan,
  scheduleId,
  candidateName,
  startAt,
  candidateTimezone,
}) => {
  const dispatch = useDispatch();
  const showEmployeePrefData = useFlag('user_last_used_template_ids_employee_pref');

  const scheduleFlowData = useScheduleFlowData();

  const {
    slackChannelEnabled,
    slackChannelRemoteId,
    conversationId,
    slackChannelName,
    slackChannelContent,
    slackChannelMessageTemplateID,
    slackChannelInterviewerEmployeeIDs,
    slackChannelRecruitingTeamEmployeeIDs,
    slackChannelHiringManagerEmployeeIDs,
    isDebriefFlow,
  } = useSelector((state) => state.scheduleCommunications.byId[scheduleId] || {});

  const defaultChannelName = `${getSlackChannelName(candidateName, startAt, candidateTimezone)}`;
  const [orgValue] = useOrgPrefString(OrgPrefName.STR_SLACK_CHANNEL_NAME_FORMAT, defaultChannelName);
  const newChannelFormat = `${getSlackChannelFormat(candidateName, startAt, candidateTimezone, orgValue || '')}`;
  React.useEffect(() => {
    const isUserModified =
      slackChannelName && slackChannelName !== defaultChannelName && slackChannelName !== newChannelFormat;
    if (!(conversationId || slackChannelRemoteId) && !isUserModified) {
      dispatch(updateSlackChannelName(scheduleId, newChannelFormat));
    }
  }, [
    dispatch,
    scheduleId,
    newChannelFormat,
    conversationId,
    slackChannelName,
    defaultChannelName,
    slackChannelRemoteId,
  ]);

  const handleUpdateLastUsedSlackTemplateId = useCommunicationTemplatesPref(
    EmployeePrefName.STR_INTERVIEWER_CONVERSATION_MESSAGE_TEMPLATE_ID
  );

  return (
    <BaseSlackChannelCard
      applicationId={scheduleFlowData.applicationId}
      isEnabled={slackChannelEnabled}
      templateId={slackChannelMessageTemplateID || ''}
      defaultChannelName={defaultChannelName}
      slackChannelId={slackChannelRemoteId || conversationId}
      slackChannelName={slackChannelName || ''}
      slackMessageContent={slackChannelContent || ''}
      interviewerEmployeeIDs={slackChannelInterviewerEmployeeIDs}
      recruitingTeamEmployeeIDs={slackChannelRecruitingTeamEmployeeIDs}
      hiringTeamEmployeeIDs={slackChannelHiringManagerEmployeeIDs}
      templateTypes={
        isDebriefFlow ? [TemplateType.DebriefConfirmationConversationMessage] : [TemplateType.SlackChannelMessage]
      }
      interviewPlan={interviewPlan}
      onSlackChannelIdChange={(value, type) => {
        if (type === SlackChannelIdType.CLEAR) {
          dispatch(updateRemoteSlackChannelId(scheduleId, value));
          dispatch(updateConversationId(scheduleId, value));
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line max-lines
        } else if (type === SlackChannelIdType.CONVERSATION) {
          dispatch(updateRemoteSlackChannelId(scheduleId, ''));
          dispatch(updateConversationId(scheduleId, value));
        } else if (type === SlackChannelIdType.SLACK_CHANNEL) {
          dispatch(updateRemoteSlackChannelId(scheduleId, value));
          dispatch(updateConversationId(scheduleId, ''));
        }
      }}
      onChannelNameChange={(value) => dispatch(updateSlackChannelName(scheduleId, value))}
      onHiringManagersChange={(value) => dispatch(updateSlackChannelHiringManagerEmployeeIDs(scheduleId, value))}
      onInterviewersChange={(value) => dispatch(updateSlackChannelInterviewerEmployeeIDs(scheduleId, value))}
      onRecruitersChange={(value) => dispatch(updateSlackChannelRecruitingTeamEmployeeIDs(scheduleId, value))}
      onSlackChannelToggle={(value) => dispatch(updateSlackChannelEnabled(scheduleId, value))}
      onSlackMessageChange={(value) => dispatch(updateSlackChannelMessage(scheduleId, value))}
      onTemplateChange={(value) => {
        dispatch(updateSlackChannelMessageTemplateID(scheduleId, value));

        if (value && showEmployeePrefData) handleUpdateLastUsedSlackTemplateId(value);
        else {
          dispatch(updateLastUsedSlackChannelMessageTemplateID(value));
        }
      }}
    />
  );
};

SlackChannelCard.fragments = {
  interviewPlan: gql`
    ${BaseSlackChannelCard.fragments.interviewPlan}
    fragment SlackChannelCard_interviewPlan on JobStage {
      id
      ...BaseSlackChannelCard_interviewPlan
    }
  `,
};

export default SlackChannelCard;
