import { SvgIcon, SvgIconProps } from './SvgIcon';

export const InfoIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <circle cx="10" cy="10" r="7.5" fill="currentColor" fillOpacity="0.31" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8ZM10.75 10C10.75 9.58579 10.4142 9.25 10 9.25C9.58579 9.25 9.25 9.58579 9.25 10V13C9.25 13.4142 9.58579 13.75 10 13.75C10.4142 13.75 10.75 13.4142 10.75 13V10Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
