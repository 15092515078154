import React from 'react';

import { ResourceLabelContentArg } from '@fullcalendar/resource-common';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Divider, Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { assertIsoTimestamp } from '@modernloop/shared/datetime';
import { useFlag } from '@modernloop/shared/feature-flag';
import { round } from 'lodash';

import { InterviewLimitType } from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import IconButton from 'src/components/IconButton';
import Chip from 'src/components/chip';
import {
  CaretDownIcon,
  CaretUpIcon,
  DayIcon,
  LastWeekIcon,
  ReverseShadowIcon,
  ShadowIcon,
  TimezoneIcon,
  TraineeIcon,
  UserIcon,
  WeekIcon,
} from 'src/components/icons';
import Label from 'src/components/label';
import Tooltip from 'src/components/tooltip';

import CompanyHolidayIcon from 'src/entities/CompanyHolidayIcon';
import useGetCompanyHolidaysForDay from 'src/entities/CompanyHolidayIcon/useGetCompanyHolidaysForDate';
import {
  isModuleMemberInterviewRoleInterviewer,
  isModuleMemberInterviewRoleReverseShadow,
  isModuleMemberInterviewRoleShadow,
} from 'src/entities/InterviewModuleMember/utils';

import { Theme } from 'src/theme/type';

import { InterviewerLoadAndLimit, RichInterviewer } from 'src/utils/api/getScheduleOptions';
import { formatToTimeZone } from 'src/utils/dateUtils';

import ScheduleInterviewerDetails from 'src/views-new/ScheduleFlow/Steps/ScheduleOptions/ScheduleInterviewerDetails';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ResourceLabelContentProps = {
  args: ResourceLabelContentArg;
  activeDay: number;
  scheduleId: string;
  showAllDay: boolean;
  timezone: string;
  loadAndLimits: InterviewerLoadAndLimit[];
  setShowAllDay: (show: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    columnHeader: {
      height: '100%',
      width: '100%',
      paddingBottom: '4px',
    },
    headerDivider: {
      height: '70%',
      alignSelf: 'center',
    },
    headerDaysContainer: {
      overflow: 'auto',
    },
    headerDaysButton: {
      borderRadius: '50%',
      height: '28px',
      width: '28px',
      padding: '3px',

      color: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    headerDaysButtonActive: {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.contrastText,
    },
    interviewerRoleChip: {
      alignSelf: 'flex-start',
    },
    headerInterviewerChip: {
      overflow: 'hidden',
    },
  })
);

const ResourceLabelContent = ({
  args,
  activeDay,
  scheduleId,
  showAllDay,
  timezone,
  loadAndLimits,
  setShowAllDay,
}: ResourceLabelContentProps): JSX.Element => {
  const classes = useStyles();

  const companyHolidaysForDay = useGetCompanyHolidaysForDay(assertIsoTimestamp(new Date(activeDay).toISOString()));
  const isFastTrackTrainingEnabled = useFlag('user_fast_track_interview_module');

  if (args.resource.id === scheduleId) {
    const dateStr = formatToTimeZone(activeDay, 'EEEE, MMMM d', timezone);
    return (
      <Grid
        container
        direction="column"
        alignItems="flex-end"
        justifyContent="flex-end"
        className={classes.columnHeader}
        data-testid="schedule-resource-label-content-candidate"
      >
        {/* Making below Grid container to ensure that label shows up to the left */}
        <Grid item container>
          <Label variant="captions">Interview schedule</Label>
        </Grid>
        <Grid item container>
          <Label variant="body" fontWeight={600}>
            {dateStr}
          </Label>
          {/* Passing directly the first element of companyHolidaysForDay as only one company holiday is expected */}
          &nbsp;
          {companyHolidaysForDay && (
            <CompanyHolidayIcon companyHolidays={companyHolidaysForDay[Object.keys(companyHolidaysForDay)[0]]} />
          )}
        </Grid>
      </Grid>
    );
  }

  const { resource } = args;
  const interviewer = (resource.extendedProps?.interviewer ?? { employee: {} }) as RichInterviewer;
  const allDayEventCount = resource.extendedProps?.allDayEventCount as number;
  const { employee } = interviewer;
  const loadAndLimit = loadAndLimits.find((value) => value.employeeId === employee.id);

  if (!employee) return <Label>{resource.title}</Label>;

  const { fullName, slackImageUrl, timezone: interviewerTimezone } = employee;

  const getDailyLoad = () => {
    if (loadAndLimit) {
      if (loadAndLimit.dailyInterviewLimit?.type === InterviewLimitType.NumberOfMinutes) {
        return `${loadAndLimit.dailyLoadMinutes ? round(loadAndLimit.dailyLoadMinutes / 60, 2) : 0}`;
      }
    }
    return `${loadAndLimit?.dailyLoad ?? 0}`;
  };

  const getWeeklyLoad = () => {
    if (loadAndLimit) {
      if (loadAndLimit.weeklyInterviewLimit?.type === InterviewLimitType.NumberOfMinutes) {
        return `${loadAndLimit.weeklyLoadMinutes ? round(loadAndLimit.weeklyLoadMinutes / 60, 2) : 0}`;
      }
    }
    return `${loadAndLimit?.weeklyLoad ?? 0}`;
  };

  const getLastWeeklyLoad = () => {
    if (loadAndLimit) {
      if (loadAndLimit.weeklyInterviewLimit?.type === InterviewLimitType.NumberOfMinutes) {
        return `${loadAndLimit.lastWeeklyLoadMinutes ? round(loadAndLimit.lastWeeklyLoadMinutes / 60, 2) : 0}`;
      }
    }
    return `${loadAndLimit?.lastWeeklyLoad ?? 0}`;
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      wrap="nowrap"
      className={classes.columnHeader}
      data-testid="schedule-resource-label-content-interviewer"
    >
      <Grid item className={classes.interviewerRoleChip}>
        {isModuleMemberInterviewRoleInterviewer(interviewer.role) && (
          <Chip avatar={<UserIcon />} label="Interviewer" variant="default" />
        )}
        {isModuleMemberInterviewRoleShadow(interviewer.role) && (
          <Chip
            avatar={isFastTrackTrainingEnabled ? <TraineeIcon /> : <ShadowIcon />}
            label={isFastTrackTrainingEnabled ? 'Trainee' : 'Shadow'}
            variant="default"
          />
        )}
        {isModuleMemberInterviewRoleReverseShadow(interviewer.role) && (
          <Chip
            avatar={isFastTrackTrainingEnabled ? <TraineeIcon /> : <ReverseShadowIcon />}
            label={isFastTrackTrainingEnabled ? 'Trainee' : 'Reverse shadow'}
            variant="default"
          />
        )}
      </Grid>
      <Grid item container alignItems="center" wrap="nowrap" justifyContent="space-between" spacing={1}>
        <Grid item className={classes.headerInterviewerChip}>
          <Tooltip tooltip={<Label>{fullName}</Label>}>
            <Chip
              variant="default"
              label={fullName ?? ''}
              avatar={slackImageUrl ? <Avatar alt={fullName || ''} src={slackImageUrl} /> : undefined}
            />
          </Tooltip>
        </Grid>
        {interviewerTimezone !== timezone && (
          <Grid item>
            <Tooltip tooltip={<Label>{interviewerTimezone}</Label>}>
              <TimezoneIcon />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid item container>
        <Tooltip
          interactive
          tooltip={<ScheduleInterviewerDetails interviewer={interviewer} loadAndLimit={loadAndLimit} />}
          placement="bottom-start"
        >
          <Grid container wrap="nowrap">
            <Chip variant="default" label={getDailyLoad()} avatar={<DayIcon />} />
            <Divider orientation="vertical" flexItem className={classes.headerDivider} />
            <Chip variant="default" label={getWeeklyLoad()} avatar={<WeekIcon />} />
            <Divider orientation="vertical" flexItem className={classes.headerDivider} />
            <Chip variant="default" label={getLastWeeklyLoad()} avatar={<LastWeekIcon />} />
          </Grid>
        </Tooltip>
      </Grid>
      {allDayEventCount > 0 && (
        <Grid item container alignItems="center" justifyContent="space-between">
          <Label>{`${allDayEventCount} all day events`}</Label>
          <IconButton onClick={() => setShowAllDay(!showAllDay)}>
            {!showAllDay && <CaretDownIcon fontSize={24} />}
            {showAllDay && <CaretUpIcon fontSize={24} />}
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default ResourceLabelContent;
