import { SvgIcon, SvgIconProps } from './SvgIcon';

export const EditIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props} display="flex">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2197 4.21965C12.4791 2.96023 14.521 2.96023 15.7804 4.21963C17.0398 5.47901 17.0398 7.52088 15.7804 8.78027L15.0303 9.53035L14.0303 10.5304L14.0303 10.5304L9.26581 15.2948C8.35807 16.2026 7.2103 16.8326 5.95714 17.1111L4.69673 17.3912C3.44636 17.6691 2.33092 16.5536 2.60878 15.3033L2.88886 14.0429C3.16735 12.7897 3.79742 11.6419 4.70517 10.7342L9.46966 5.96971L9.46967 5.9697L10.4697 4.96969L11 5.50001L10.4697 4.96969L11.2197 4.21965ZM10 7.56069L12.4393 10L8.20516 14.2342C7.49914 14.9402 6.60643 15.4302 5.63175 15.6468L4.37134 15.9269C4.19271 15.9666 4.03336 15.8073 4.07306 15.6286L4.35314 14.3683C4.56974 13.3936 5.0598 12.5009 5.76583 11.7948L10 7.56069ZM14.5 7.93936L14.7197 7.71962C15.3933 7.04601 15.3933 5.95389 14.7197 5.28029C14.0461 4.60668 12.954 4.60668 12.2804 5.2803L12.0606 5.50001L14.5 7.93936Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
