import React from 'react';

import BaseIcon, { Props } from './Icon';

const HistoryIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.03093 10.7494C3.18605 10.7431 3.32903 10.6897 3.44594 10.6031L5.73168 8.95101C6.06739 8.70837 6.14283 8.23952 5.9002 7.90382C5.65756 7.56811 5.18871 7.49267 4.85301 7.73531L3.96222 8.37914C4.67613 5.71313 7.10881 3.75 10 3.75C13.4518 3.75 16.25 6.54822 16.25 10C16.25 13.4518 13.4518 16.25 10 16.25C7.50792 16.25 5.355 14.7915 4.35108 12.6782C4.17334 12.304 3.72595 12.1448 3.35181 12.3226C2.97767 12.5003 2.81845 12.9477 2.99618 13.3218C4.23919 15.9384 6.90729 17.75 10 17.75C14.2802 17.75 17.75 14.2802 17.75 10C17.75 5.71979 14.2802 2.25 10 2.25C6.40013 2.25 3.37352 4.70442 2.50231 8.0311L1.951 7.26833C1.70836 6.93262 1.23952 6.85718 0.90381 7.09982C0.568104 7.34246 0.492659 7.8113 0.735298 8.147L2.3869 10.4321C2.40165 10.453 2.41743 10.4731 2.43419 10.4923C2.45718 10.5187 2.48191 10.5434 2.50814 10.5662C2.63273 10.6745 2.7934 10.7424 2.96973 10.7494C2.99017 10.7502 3.01059 10.7502 3.03093 10.7494ZM10 5.25C10.4142 5.25 10.75 5.58579 10.75 6V9.68934L12.5303 11.4697C12.8232 11.7626 12.8232 12.2374 12.5303 12.5303C12.2374 12.8232 11.7626 12.8232 11.4697 12.5303L9.46967 10.5303C9.32902 10.3897 9.25 10.1989 9.25 10V6C9.25 5.58579 9.58579 5.25 10 5.25Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default HistoryIcon;
