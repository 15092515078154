/* eslint-disable max-lines */
interface TimezoneData {
  label: string;
  value: string;
  keywords?: string;
}

const data: TimezoneData[] = [
  {
    label: 'Niue Time',
    value: 'Pacific/Niue',
  },
  {
    label: 'Samoa Standard Time',
    value: 'Pacific/Pago_Pago',
  },
  {
    label: 'Cook Islands Standard Time',
    value: 'Pacific/Rarotonga',
  },
  {
    label: 'Hawaii-Aleutian Standard Time',
    value: 'Pacific/Honolulu',
  },
  {
    label: 'Tahiti Time',
    value: 'Pacific/Tahiti',
  },
  {
    label: 'Marquesas Time',
    value: 'Pacific/Marquesas',
  },
  {
    label: 'Gambier Time',
    value: 'Pacific/Gambier',
  },
  {
    label: 'Hawaii-Aleutian Time (Adak)',
    value: 'America/Adak',
  },
  {
    label: 'Alaska Time - Anchorage',
    value: 'America/Anchorage',
  },
  {
    label: 'Alaska Time - Juneau',
    value: 'America/Juneau',
  },
  {
    label: 'Alaska Time - Metlakatla',
    value: 'America/Metlakatla',
  },
  {
    label: 'Alaska Time - Nome',
    value: 'America/Nome',
  },
  {
    label: 'Alaska Time - Sitka',
    value: 'America/Sitka',
  },
  {
    label: 'Alaska Time - Yakutat',
    value: 'America/Yakutat',
  },
  {
    label: 'Pitcairn Time',
    value: 'Pacific/Pitcairn',
  },
  {
    label: 'Mexican Pacific Standard Time',
    value: 'America/Hermosillo',
  },
  {
    label: 'Mountain Standard Time - Creston / Phoenix',
    value: 'America/Phoenix',
    keywords: 'Arizona,MST,MT',
  },
  {
    label: 'Mountain Standard Time - Dawson Creek',
    value: 'America/Dawson_Creek',
    keywords: 'MST,MDT,MT',
  },
  {
    label: 'Mountain Standard Time - Fort Nelson',
    value: 'America/Fort_Nelson',
  },
  {
    label: 'Mountain Time (Dawson)',
    value: 'America/Dawson',
  },
  {
    label: 'Mountain Time (Whitehorse)',
    value: 'America/Whitehorse',
  },
  {
    label: 'Pacific Time - Los Angeles',
    value: 'America/Los_Angeles',
    keywords: 'PST,PDT,PT,San Francisco,San Mateo,Palo Alto,Seattle,San Diego,Portland',
  },
  {
    label: 'Pacific Time - Tijuana',
    value: 'America/Tijuana',
  },
  {
    label: 'Pacific Time - Vancouver',
    value: 'America/Vancouver',
  },
  {
    label: 'Central Standard Time - Belize',
    value: 'America/Belize',
  },
  {
    label: 'Central Standard Time - Costa Rica',
    value: 'America/Costa_Rica',
  },
  {
    label: 'Central Standard Time - El Salvador',
    value: 'America/El_Salvador',
  },
  {
    label: 'Central Standard Time - Guatemala',
    value: 'America/Guatemala',
  },
  {
    label: 'Central Standard Time - Managua',
    value: 'America/Managua',
  },
  {
    label: 'Central Standard Time - Regina',
    value: 'America/Regina',
  },
  {
    label: 'Central Standard Time - Swift Current',
    value: 'America/Swift_Current',
  },
  {
    label: 'Central Standard Time - Tegucigalpa',
    value: 'America/Tegucigalpa',
  },
  {
    label: 'Easter Island Time',
    value: 'Pacific/Easter',
  },
  {
    label: 'Galapagos Time',
    value: 'Pacific/Galapagos',
  },
  {
    label: 'Mexican Pacific Time - Chihuahua',
    value: 'America/Chihuahua',
  },
  {
    label: 'Mexican Pacific Time - Mazatlan',
    value: 'America/Mazatlan',
  },
  {
    label: 'Mountain Time - Boise',
    value: 'America/Boise',
  },
  {
    label: 'Mountain Time - Cambridge Bay',
    value: 'America/Cambridge_Bay',
  },
  {
    label: 'Mountain Time - Denver',
    value: 'America/Denver',
    keywords: 'MST,MDT,MT,Colorado',
  },
  {
    label: 'Mountain Time - Edmonton / Yellowknife',
    value: 'America/Edmonton',
  },
  {
    label: 'Mountain Time - Inuvik',
    value: 'America/Inuvik',
  },
  {
    label: 'Mountain Time - Ojinaga',
    value: 'America/Ojinaga',
  },
  // {
  //   label: 'Mountain Time - Yellowknife',
  //   value: 'America/Edmonton',
  // },
  {
    label: 'Acre Standard Time - Eirunepe',
    value: 'America/Eirunepe',
  },
  {
    label: 'Acre Standard Time - Rio Branco',
    value: 'America/Rio_Branco',
  },
  {
    label: 'Central Time - Bahia Banderas',
    value: 'America/Bahia_Banderas',
  },
  {
    label: 'Central Time - Beulah, North Dakota',
    value: 'America/North_Dakota/Beulah',
  },
  {
    label: 'Central Time - Center, North Dakota',
    value: 'America/North_Dakota/Center',
  },
  {
    label: 'Central Time - Chicago',
    value: 'America/Chicago',
    keywords: 'CST,CDT,CT',
  },
  {
    label: 'Central Time - Knox, Indiana',
    value: 'America/Indiana/Knox',
  },
  {
    label: 'Central Time - Matamoros',
    value: 'America/Matamoros',
  },
  {
    label: 'Central Time - Menominee',
    value: 'America/Menominee',
  },
  {
    label: 'Central Time - Merida',
    value: 'America/Merida',
  },
  {
    label: 'Central Time - Mexico City',
    value: 'America/Mexico_City',
  },
  {
    label: 'Central Time - Monterrey',
    value: 'America/Monterrey',
  },
  {
    label: 'Central Time - New Salem, North Dakota',
    value: 'America/North_Dakota/New_Salem',
  },
  {
    label: 'Central Time - Rainy River / Winnipeg',
    value: 'America/Winnipeg',
  },
  {
    label: 'Central Time - Rankin Inlet',
    value: 'America/Rankin_Inlet',
  },
  {
    label: 'Central Time - Resolute',
    value: 'America/Resolute',
  },
  {
    label: 'Central Time - Tell City, Indiana',
    value: 'America/Indiana/Tell_City',
  },
  {
    label: 'Colombia Standard Time',
    value: 'America/Bogota',
  },
  {
    label: 'Eastern Standard Time - Atikokan / Panama',
    value: 'America/Panama',
  },
  {
    label: 'Eastern Standard Time - Cancun',
    value: 'America/Cancun',
  },
  {
    label: 'Eastern Standard Time - Jamaica',
    value: 'America/Jamaica',
  },
  {
    label: 'Ecuador Time',
    value: 'America/Guayaquil',
  },
  {
    label: 'Peru Standard Time',
    value: 'America/Lima',
  },
  {
    label: 'Amazon Standard Time - Boa Vista',
    value: 'America/Boa_Vista',
  },
  {
    label: 'Amazon Standard Time - Campo Grande',
    value: 'America/Campo_Grande',
  },
  {
    label: 'Amazon Standard Time - Cuiaba',
    value: 'America/Cuiaba',
  },
  {
    label: 'Amazon Standard Time - Manaus',
    value: 'America/Manaus',
  },
  {
    label: 'Amazon Standard Time - Porto Velho',
    value: 'America/Porto_Velho',
  },
  {
    label: 'Atlantic Standard Time - Barbados',
    value: 'America/Barbados',
  },
  {
    label: 'Atlantic Standard Time - Blanc-Sablon / Curaçao / Port of Spain / Puerto Rico',
    value: 'America/Puerto_Rico',
  },
  {
    label: 'Atlantic Standard Time - Martinique',
    value: 'America/Martinique',
  },
  {
    label: 'Atlantic Standard Time - Santo Domingo',
    value: 'America/Santo_Domingo',
  },
  {
    label: 'Bolivia Time',
    value: 'America/La_Paz',
  },
  {
    label: 'Chile Time',
    value: 'America/Santiago',
  },
  {
    label: 'Cuba Time',
    value: 'America/Havana',
  },
  {
    label: 'Eastern Time - Detroit',
    value: 'America/Detroit',
  },
  {
    label: 'Eastern Time - Grand Turk',
    value: 'America/Grand_Turk',
  },
  {
    label: 'Eastern Time - Indianapolis',
    value: 'America/Indiana/Indianapolis',
  },
  {
    label: 'Eastern Time - Iqaluit / Pangnirtung',
    value: 'America/Iqaluit',
  },
  {
    label: 'Eastern Time - Louisville',
    value: 'America/Kentucky/Louisville',
  },
  {
    label: 'Eastern Time - Marengo, Indiana',
    value: 'America/Indiana/Marengo',
  },
  {
    label: 'Eastern Time - Monticello, Kentucky',
    value: 'America/Kentucky/Monticello',
  },
  {
    label: 'Eastern Time - Nassau / Nipigon / Thunder Bay / Toronto',
    value: 'America/Toronto',
  },
  {
    label: 'Eastern Time - New York',
    value: 'America/New_York',
    keywords: 'EST,EDT,ET,New Jersey,Floriday, USA',
  },
  {
    label: 'Eastern Time - Petersburg, Indiana',
    value: 'America/Indiana/Petersburg',
  },
  {
    label: 'Eastern Time - Port-au-Prince',
    value: 'America/Port-au-Prince',
  },
  {
    label: 'Eastern Time - Vevay, Indiana',
    value: 'America/Indiana/Vevay',
  },
  {
    label: 'Eastern Time - Vincennes, Indiana',
    value: 'America/Indiana/Vincennes',
  },
  {
    label: 'Eastern Time - Winamac, Indiana',
    value: 'America/Indiana/Winamac',
  },
  {
    label: 'Guyana Time',
    value: 'America/Guyana',
  },
  {
    label: 'Paraguay Time',
    value: 'America/Asuncion',
  },
  {
    label: 'Venezuela Time',
    value: 'America/Caracas',
  },
  {
    label: 'Argentina Standard Time - Buenos Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    label: 'Argentina Standard Time - Catamarca',
    value: 'America/Argentina/Catamarca',
  },
  {
    label: 'Argentina Standard Time - Cordoba',
    value: 'America/Argentina/Cordoba',
  },
  {
    label: 'Argentina Standard Time - Jujuy',
    value: 'America/Argentina/Jujuy',
  },
  {
    label: 'Argentina Standard Time - La Rioja',
    value: 'America/Argentina/La_Rioja',
  },
  {
    label: 'Argentina Standard Time - Mendoza',
    value: 'America/Argentina/Mendoza',
  },
  {
    label: 'Argentina Standard Time - Rio Gallegos',
    value: 'America/Argentina/Rio_Gallegos',
  },
  {
    label: 'Argentina Standard Time - Salta',
    value: 'America/Argentina/Salta',
  },
  {
    label: 'Argentina Standard Time - San Juan',
    value: 'America/Argentina/San_Juan',
  },
  {
    label: 'Argentina Standard Time - San Luis',
    value: 'America/Argentina/San_Luis',
  },
  {
    label: 'Argentina Standard Time - Tucuman',
    value: 'America/Argentina/Tucuman',
  },
  {
    label: 'Argentina Standard Time - Ushuaia',
    value: 'America/Argentina/Ushuaia',
  },
  {
    label: 'Atlantic Time - Bermuda',
    value: 'Atlantic/Bermuda',
  },
  {
    label: 'Atlantic Time - Glace Bay',
    value: 'America/Glace_Bay',
  },
  {
    label: 'Atlantic Time - Goose Bay',
    value: 'America/Goose_Bay',
  },
  {
    label: 'Atlantic Time - Halifax',
    value: 'America/Halifax',
  },
  {
    label: 'Atlantic Time - Moncton',
    value: 'America/Moncton',
  },
  {
    label: 'Atlantic Time - Thule',
    value: 'America/Thule',
  },
  {
    label: 'Brasilia Standard Time - Araguaina',
    value: 'America/Araguaina',
    keywords: 'Brazil',
  },
  {
    label: 'Brasilia Standard Time - Bahia',
    value: 'America/Bahia',
    keywords: 'Brazil',
  },
  {
    label: 'Brasilia Standard Time - Belem',
    value: 'America/Belem',
    keywords: 'Brazil',
  },
  {
    label: 'Brasilia Standard Time - Fortaleza',
    value: 'America/Fortaleza',
    keywords: 'Brazil',
  },
  {
    label: 'Brasilia Standard Time - Maceio',
    value: 'America/Maceio',
    keywords: 'Brazil',
  },
  {
    label: 'Brasilia Standard Time - Recife',
    value: 'America/Recife',
    keywords: 'Brazil',
  },
  {
    label: 'Brasilia Standard Time - Santarem',
    value: 'America/Santarem',
    keywords: 'Brazil',
  },
  {
    label: 'Brasilia Standard Time - Sao Paulo',
    value: 'America/Sao_Paulo',
    keywords: 'Brazil',
  },
  {
    label: 'Falkland Islands Standard Time',
    value: 'Atlantic/Stanley',
  },
  {
    label: 'French Guiana Time',
    value: 'America/Cayenne',
  },
  {
    label: 'Palmer Time',
    value: 'Antarctica/Palmer',
  },
  {
    label: 'Punta Arenas Time',
    value: 'America/Punta_Arenas',
  },
  {
    label: 'Rothera Time',
    value: 'Antarctica/Rothera',
  },
  {
    label: 'Suriname Time',
    value: 'America/Paramaribo',
  },
  {
    label: 'Uruguay Standard Time',
    value: 'America/Montevideo',
  },
  {
    label: 'Newfoundland Time',
    value: 'America/St_Johns',
  },
  {
    label: 'Fernando de Noronha Standard Time',
    value: 'America/Noronha',
  },
  {
    label: 'South Georgia Time',
    value: 'Atlantic/South_Georgia',
  },
  {
    label: 'St. Pierre & Miquelon Time',
    value: 'America/Miquelon',
  },
  {
    label: 'Cape Verde Standard Time',
    value: 'Atlantic/Cape_Verde',
  },
  {
    label: 'Azores Time',
    value: 'Atlantic/Azores',
  },
  {
    label: 'Coordinated Universal Time',
    value: 'UTC',
  },
  {
    label: 'East Greenland Time',
    value: 'America/Scoresbysund',
  },
  {
    label: 'Greenwich Mean Time',
    value: 'Etc/GMT',
  },
  {
    label: 'Greenwich Mean Time - Abidjan / Accra / Reykjavik',
    value: 'Africa/Abidjan',
  },
  {
    label: 'Greenwich Mean Time - Bissau',
    value: 'Africa/Bissau',
  },
  {
    label: 'Greenwich Mean Time - Danmarkshavn',
    value: 'America/Danmarkshavn',
  },
  {
    label: 'Greenwich Mean Time - Monrovia',
    value: 'Africa/Monrovia',
  },
  {
    label: 'Greenwich Mean Time - São Tomé',
    value: 'Africa/Sao_Tome',
  },
  {
    label: 'Morocco Time',
    value: 'Africa/Casablanca',
  },
  {
    label: 'Western Sahara Time',
    value: 'Africa/El_Aaiun',
  },
  {
    label: 'Central European Standard Time - Algiers',
    value: 'Africa/Algiers',
  },
  {
    label: 'Central European Standard Time - Tunis',
    value: 'Africa/Tunis',
  },
  {
    label: 'Ireland Time',
    value: 'Europe/Dublin',
    keywords: 'Irish,IST',
  },
  {
    label: 'United Kingdom Time',
    value: 'Europe/London',
    keywords: 'London,Edinburgh',
  },
  {
    label: 'West Africa Standard Time - Lagos',
    value: 'Africa/Lagos',
  },
  {
    label: 'West Africa Standard Time - Ndjamena',
    value: 'Africa/Ndjamena',
  },
  {
    label: 'Western European Time - Canary',
    value: 'Atlantic/Canary',
  },
  {
    label: 'Western European Time - Faroe',
    value: 'Atlantic/Faroe',
  },
  {
    label: 'Western European Time - Lisbon',
    value: 'Europe/Lisbon',
  },
  {
    label: 'Western European Time - Madeira',
    value: 'Atlantic/Madeira',
  },
  {
    label: 'Central Africa Time - Juba',
    value: 'Africa/Juba',
  },
  {
    label: 'Central Africa Time - Khartoum',
    value: 'Africa/Khartoum',
  },
  {
    label: 'Central Africa Time - Maputo',
    value: 'Africa/Maputo',
  },
  {
    label: 'Central Africa Time - Windhoek',
    value: 'Africa/Windhoek',
  },
  {
    label: 'Central European Time - Amsterdam / Brussels / Luxembourg',
    value: 'Europe/Brussels',
  },
  {
    label: 'Central European Time - Andorra',
    value: 'Europe/Andorra',
  },
  {
    label: 'Central European Time - Belgrade',
    value: 'Europe/Belgrade',
  },
  {
    label: 'Central European Time - Berlin / Copenhagen / Oslo / Stockholm',
    value: 'Europe/Berlin',
    keywords: 'Munich',
  },
  {
    label: 'Central European Time - Budapest',
    value: 'Europe/Budapest',
  },
  {
    label: 'Central European Time - Ceuta',
    value: 'Africa/Ceuta',
  },
  {
    label: 'Central European Time - Gibraltar',
    value: 'Europe/Gibraltar',
  },
  {
    label: 'Central European Time - Madrid',
    value: 'Europe/Madrid',
  },
  {
    label: 'Central European Time - Malta',
    value: 'Europe/Malta',
  },
  {
    label: 'Central European Time - Monaco / Paris',
    value: 'Europe/Paris',
  },
  {
    label: 'Central European Time - Prague',
    value: 'Europe/Prague',
  },
  {
    label: 'Central European Time - Rome',
    value: 'Europe/Rome',
  },
  {
    label: 'Central European Time - Tirane',
    value: 'Europe/Tirane',
  },
  {
    label: 'Central European Time - Vienna',
    value: 'Europe/Vienna',
  },
  {
    label: 'Central European Time - Warsaw',
    value: 'Europe/Warsaw',
  },
  {
    label: 'Central European Time - Zurich',
    value: 'Europe/Zurich',
  },
  {
    label: 'Eastern European Standard Time - Cairo',
    value: 'Africa/Cairo',
    keywords: 'Eastern European Time,Eastern European Summer Time,EEST,EET',
  },
  {
    label: 'Eastern European Standard Time - Kaliningrad',
    value: 'Europe/Kaliningrad',
  },
  {
    label: 'Eastern European Standard Time - Tripoli',
    value: 'Africa/Tripoli',
  },
  {
    label: 'South Africa Standard Time',
    value: 'Africa/Johannesburg',
  },
  {
    label: 'Troll Time',
    value: 'Antarctica/Troll',
  },
  {
    label: 'Arabian Standard Time - Baghdad',
    value: 'Asia/Baghdad',
  },
  {
    label: 'Arabian Standard Time - Qatar',
    value: 'Asia/Qatar',
  },
  {
    label: 'Arabian Standard Time - Riyadh / Syowa Time',
    value: 'Asia/Riyadh',
  },
  {
    label: 'East Africa Time',
    value: 'Africa/Nairobi',
  },
  {
    label: 'Eastern European Time - Amman',
    value: 'Asia/Amman',
  },
  {
    label: 'Eastern European Time - Athens',
    value: 'Europe/Athens',
  },
  {
    label: 'Eastern European Time - Beirut',
    value: 'Asia/Beirut',
  },
  {
    label: 'Eastern European Time - Bucharest',
    value: 'Europe/Bucharest',
  },
  {
    label: 'Eastern European Time - Chisinau',
    value: 'Europe/Chisinau',
  },
  {
    label: 'Eastern European Time - Damascus',
    value: 'Asia/Damascus',
  },
  {
    label: 'Eastern European Time - Gaza',
    value: 'Asia/Gaza',
  },
  {
    label: 'Eastern European Time - Hebron',
    value: 'Asia/Hebron',
  },
  {
    label: 'Eastern European Time - Helsinki',
    value: 'Europe/Helsinki',
  },
  {
    label: 'Eastern European Time - Kiev / Uzhhorod / Zaporozhye',
    value: 'Europe/Kyiv',
  },
  {
    label: 'Eastern European Time - Nicosia',
    value: 'Asia/Nicosia',
  },
  {
    label: 'Eastern European Time - Riga',
    value: 'Europe/Riga',
  },
  {
    label: 'Eastern European Time - Sofia',
    value: 'Europe/Sofia',
  },
  {
    label: 'Eastern European Time - Tallinn',
    value: 'Europe/Tallinn',
  },
  {
    label: 'Eastern European Time - Vilnius',
    value: 'Europe/Vilnius',
  },
  {
    label: 'Famagusta Time',
    value: 'Asia/Famagusta',
  },
  {
    label: 'Israel Time',
    value: 'Asia/Jerusalem',
  },
  {
    label: 'Kirov Time',
    value: 'Europe/Kirov',
  },
  {
    label: 'Moscow Standard Time - Minsk',
    value: 'Europe/Minsk',
  },
  {
    label: 'Moscow Standard Time - Moscow',
    value: 'Europe/Moscow',
  },
  {
    label: 'Moscow Standard Time - Simferopol',
    value: 'Europe/Simferopol',
  },
  {
    label: 'Turkey Time',
    value: 'Europe/Istanbul',
  },
  {
    label: 'Volgograd Standard Time',
    value: 'Europe/Volgograd',
  },
  {
    label: 'Armenia Standard Time',
    value: 'Asia/Yerevan',
  },
  {
    label: 'Astrakhan Time',
    value: 'Europe/Astrakhan',
  },
  {
    label: 'Azerbaijan Standard Time',
    value: 'Asia/Baku',
  },
  {
    label: 'Georgia Standard Time',
    value: 'Asia/Tbilisi',
  },
  {
    label: 'Gulf Standard Time / Réunion Time / Seychelles Time',
    value: 'Asia/Dubai',
  },
  {
    label: 'Mauritius Standard Time',
    value: 'Indian/Mauritius',
  },
  {
    label: 'Samara Standard Time',
    value: 'Europe/Samara',
  },
  {
    label: 'Saratov Time',
    value: 'Europe/Saratov',
  },
  {
    label: 'Ulyanovsk Time',
    value: 'Europe/Ulyanovsk',
  },
  {
    label: 'Afghanistan Time',
    value: 'Asia/Kabul',
  },
  {
    label: 'Iran Time',
    value: 'Asia/Tehran',
  },
  {
    label: 'French Southern & Antarctic Time / Maldives Time',
    value: 'Indian/Maldives',
  },
  {
    label: 'Mawson Time',
    value: 'Antarctica/Mawson',
  },
  {
    label: 'Pakistan Standard Time',
    value: 'Asia/Karachi',
  },
  {
    label: 'Tajikistan Time',
    value: 'Asia/Dushanbe',
  },
  {
    label: 'Turkmenistan Standard Time',
    value: 'Asia/Ashgabat',
  },
  {
    label: 'Uzbekistan Standard Time - Samarkand',
    value: 'Asia/Samarkand',
  },
  {
    label: 'Uzbekistan Standard Time - Tashkent',
    value: 'Asia/Tashkent',
  },
  {
    label: 'West Kazakhstan Time - Aqtau',
    value: 'Asia/Aqtau',
  },
  {
    label: 'West Kazakhstan Time - Aqtobe',
    value: 'Asia/Aqtobe',
  },
  {
    label: 'West Kazakhstan Time - Atyrau',
    value: 'Asia/Atyrau',
  },
  {
    label: 'West Kazakhstan Time - Oral',
    value: 'Asia/Oral',
  },
  {
    label: 'West Kazakhstan Time - Qyzylorda',
    value: 'Asia/Qyzylorda',
  },
  {
    label: 'Yekaterinburg Standard Time',
    value: 'Asia/Yekaterinburg',
  },
  {
    label: 'India Standard Time - Kolkata/Calcutta',
    value: 'Asia/Kolkata',
    keywords: 'Pune,New Delhi,Bangalore,Mumbai,Kolkata,Calcutta,IST',
  },
  {
    label: 'Nepal Time',
    value: 'Asia/Kathmandu',
  },
  {
    label: 'Bangladesh Standard Time',
    value: 'Asia/Dhaka',
  },
  {
    label: 'Bhutan Time',
    value: 'Asia/Thimphu',
  },
  {
    label: 'East Kazakhstan Time - Almaty',
    value: 'Asia/Almaty',
  },
  {
    label: 'East Kazakhstan Time - Kostanay',
    value: 'Asia/Qostanay',
  },
  {
    label: 'Indian Ocean Time',
    value: 'Indian/Chagos',
  },
  {
    label: 'Kyrgyzstan Time',
    value: 'Asia/Bishkek',
  },
  {
    label: 'Omsk Standard Time',
    value: 'Asia/Omsk',
  },
  {
    label: 'Urumqi Time',
    value: 'Asia/Urumqi',
  },
  {
    label: 'Vostok Time',
    value: 'Antarctica/Vostok',
  },
  {
    label: 'Cocos Islands Time / Myanmar Time',
    value: 'Asia/Yangon',
  },
  {
    label: 'Barnaul Time',
    value: 'Asia/Barnaul',
  },
  {
    label: 'Christmas Island Time / Indochina Time - Bangkok',
    value: 'Asia/Bangkok',
    keywords: 'Thailand',
  },
  {
    label: 'Davis Time',
    value: 'Antarctica/Davis',
  },
  {
    label: 'Hovd Standard Time',
    value: 'Asia/Hovd',
  },
  {
    label: 'Indochina Time - Ho Chi Minh City',
    value: 'Asia/Ho_Chi_Minh',
    keywords: 'Vietnam',
  },
  {
    label: 'Krasnoyarsk Standard Time - Krasnoyarsk',
    value: 'Asia/Krasnoyarsk',
  },
  {
    label: 'Krasnoyarsk Standard Time - Novokuznetsk',
    value: 'Asia/Novokuznetsk',
  },
  {
    label: 'Novosibirsk Standard Time',
    value: 'Asia/Novosibirsk',
  },
  {
    label: 'Tomsk Time',
    value: 'Asia/Tomsk',
  },
  {
    label: 'Western Indonesia Time - Jakarta',
    value: 'Asia/Jakarta',
  },
  {
    label: 'Western Indonesia Time - Pontianak',
    value: 'Asia/Pontianak',
  },
  {
    label: 'Australian Western Standard Time',
    value: 'Australia/Perth',
  },
  {
    label: 'Brunei Darussalam Time / Malaysia Time - Kuching',
    value: 'Asia/Kuching',
  },
  {
    label: 'Central Indonesia Time',
    value: 'Asia/Makassar',
    keywords: 'Bali',
  },
  {
    label: 'China Standard Time - Macao',
    value: 'Asia/Macau',
  },
  {
    label: 'China Standard Time - Shanghai',
    value: 'Asia/Shanghai',
    keywords: 'Beijing,Tianjin,Shenzhen,Guangzhou,Chengdu,Chongqing,Dongguan,Wuhan,CST',
  },
  {
    label: 'Hong Kong Standard Time',
    value: 'Asia/Hong_Kong',
  },
  {
    label: 'Irkutsk Standard Time',
    value: 'Asia/Irkutsk',
  },
  {
    label: 'Malaysia Time - Kuala Lumpur / Singapore Standard Time',
    value: 'Asia/Singapore',
    keywords: 'SGT',
  },
  {
    label: 'Philippine Standard Time',
    value: 'Asia/Manila',
  },
  {
    label: 'Taipei Standard Time',
    value: 'Asia/Taipei',
  },
  {
    label: 'Ulaanbaatar Standard Time - Choibalsan',
    value: 'Asia/Choibalsan',
  },
  {
    label: 'Ulaanbaatar Standard Time - Ulaanbaatar',
    value: 'Asia/Ulaanbaatar',
  },
  {
    label: 'Australian Central Western Standard Time',
    value: 'Australia/Eucla',
  },
  {
    label: 'East Timor Time',
    value: 'Asia/Dili',
  },
  {
    label: 'Eastern Indonesia Time',
    value: 'Asia/Jayapura',
  },
  {
    label: 'Japan Standard Time',
    value: 'Asia/Tokyo',
    keywords: 'Osaka,Kobe,Kyoto,Hiroshima,Sapporo,Nagoya,Yokohama',
  },
  {
    label: 'Korean Standard Time - Pyongyang',
    value: 'Asia/Pyongyang',
  },
  {
    label: 'Korean Standard Time - Seoul',
    value: 'Asia/Seoul',
  },
  {
    label: 'Palau Time',
    value: 'Pacific/Palau',
  },
  {
    label: 'Yakutsk Standard Time - Chita',
    value: 'Asia/Chita',
  },
  {
    label: 'Yakutsk Standard Time - Khandyga',
    value: 'Asia/Khandyga',
  },
  {
    label: 'Yakutsk Standard Time - Yakutsk',
    value: 'Asia/Yakutsk',
  },
  {
    label: 'Australian Central Standard Time',
    value: 'Australia/Darwin',
  },
  {
    label: 'Central Australia Time - Adelaide',
    value: 'Australia/Adelaide',
  },
  {
    label: 'Central Australia Time - Broken Hill',
    value: 'Australia/Broken_Hill',
  },
  {
    label: 'Australian Eastern Standard Time - Brisbane',
    value: 'Australia/Brisbane',
  },
  {
    label: 'Australian Eastern Standard Time - Lindeman',
    value: 'Australia/Lindeman',
  },
  {
    label: 'Chamorro Standard Time',
    value: 'Pacific/Guam',
  },
  {
    label: 'Chuuk Time / Dumont-d’Urville Time / Papua New Guinea Time',
    value: 'Pacific/Port_Moresby',
  },
  {
    label: 'Eastern Australia Time - Hobart',
    value: 'Australia/Hobart',
  },
  {
    label: 'Eastern Australia Time - Macquarie',
    value: 'Antarctica/Macquarie',
  },
  {
    label: 'Eastern Australia Time - Melbourne',
    value: 'Australia/Melbourne',
  },
  {
    label: 'Eastern Australia Time - Sydney',
    value: 'Australia/Sydney',
  },
  {
    label: 'Vladivostok Standard Time - Ust-Nera',
    value: 'Asia/Ust-Nera',
  },
  {
    label: 'Vladivostok Standard Time - Vladivostok',
    value: 'Asia/Vladivostok',
  },
  {
    label: 'Lord Howe Time',
    value: 'Australia/Lord_Howe',
  },
  {
    label: 'Bougainville Time',
    value: 'Pacific/Bougainville',
  },
  {
    label: 'Casey Time',
    value: 'Antarctica/Casey',
  },
  {
    label: 'Kosrae Time',
    value: 'Pacific/Kosrae',
  },
  {
    label: 'Magadan Standard Time',
    value: 'Asia/Magadan',
  },
  {
    label: 'New Caledonia Standard Time',
    value: 'Pacific/Noumea',
  },
  {
    label: 'Norfolk Island Time',
    value: 'Pacific/Norfolk',
  },
  {
    label: 'Ponape Time / Solomon Islands Time',
    value: 'Pacific/Guadalcanal',
  },
  {
    label: 'Sakhalin Standard Time',
    value: 'Asia/Sakhalin',
  },
  {
    label: 'Srednekolymsk Time',
    value: 'Asia/Srednekolymsk',
  },
  {
    label: 'Vanuatu Standard Time',
    value: 'Pacific/Efate',
  },
  {
    label: 'Anadyr Standard Time',
    value: 'Asia/Anadyr',
  },
  {
    label: 'Fiji Time',
    value: 'Pacific/Fiji',
  },
  {
    label:
      'Gilbert Islands Time / Marshall Islands Time - Majuro / Tuvalu Time - Wake Island Time / Wallis & Futuna Time',
    value: 'Pacific/Tarawa',
  },
  {
    label: 'Marshall Islands Time - Kwajalein',
    value: 'Pacific/Kwajalein',
  },
  {
    label: 'Nauru Time',
    value: 'Pacific/Nauru',
  },
  {
    label: 'New Zealand Time',
    value: 'Pacific/Auckland',
  },
  {
    label: 'Petropavlovsk-Kamchatski Standard Time',
    value: 'Asia/Kamchatka',
  },
  {
    label: 'Chatham Time',
    value: 'Pacific/Chatham',
  },
  {
    label: 'Apia Time',
    value: 'Pacific/Apia',
  },
  {
    label: 'Phoenix Islands Time',
    value: 'Pacific/Kanton',
  },
  {
    label: 'Tokelau Time',
    value: 'Pacific/Fakaofo',
  },
  {
    label: 'Tonga Standard Time',
    value: 'Pacific/Tongatapu',
  },
  {
    label: 'Line Islands Time',
    value: 'Pacific/Kiritimati',
  },
];

export default data;

export const RecognizedTimezones = data.map((timezoneData) => timezoneData.value);
