import conflictsSlice, { Conflict } from 'src/store/slices/conflicts';

import { AppThunk } from 'src/store';

export const upsertConflict =
  (conflict: Conflict): AppThunk =>
  async (dispatch) => {
    dispatch(conflictsSlice.actions.upsertConflict({ conflict }));
  };

export const removeConflict =
  (eventUid: string): AppThunk =>
  async (dispatch) => {
    dispatch(conflictsSlice.actions.removeConflict({ eventUid }));
  };
