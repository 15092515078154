import { gql } from '@apollo/client';

import { useOrgHasAtLeastOneAdminQuery } from 'src/generated/mloop-graphql';

export const GQL_GET_ORG_HAS_AT_LEAST_ONE_ADMIN = gql`
  query OrgHasAtLeastOneAdmin {
    thisOrg {
      id
      hasAdmin
    }
  }
`;

export default function useHasOrgAdminUser() {
  const { data, loading, error } = useOrgHasAtLeastOneAdminQuery();
  if (loading || error) {
    // Assuming true while the data is loading or error is safer
    return {
      loading,
      hasAdmin: true,
    };
  }

  const hasAdmin = !!data?.thisOrg?.hasAdmin;
  return {
    loading,
    hasAdmin,
  };
}
