import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type JobStagePlanConfig = {
  jobStageId: string;
  hasBreaks: boolean;
  hasMultiDay: boolean;
  numberOfDays: number;
  shouldRemember: boolean;
  excludeInterviewerIds: string[];
};

export type UpdateJobStagePlanConfigPayload = Pick<JobStagePlanConfig, 'jobStageId'> &
  Partial<Omit<JobStagePlanConfig, 'jobStageId'>>;

type JobStagePlanConfigState = {
  byId: { [key: string]: JobStagePlanConfig };
};

const getInitialState = (): JobStagePlanConfigState => {
  return {
    byId: {},
  };
};

const jobStagePlanConfigSlice = createSlice({
  name: 'jobStagePlanConfig',
  initialState: getInitialState(),
  reducers: {
    createJobStagePlanConfig: (state: JobStagePlanConfigState, action: PayloadAction<string>) => {
      const jobStageId = action.payload;

      // Plan config already exists return early
      if (state.byId[jobStageId]) return;

      state.byId[jobStageId] = {
        jobStageId,
        hasBreaks: false,
        hasMultiDay: false,
        numberOfDays: 0,
        shouldRemember: false,
        excludeInterviewerIds: [],
      };
    },

    updateJobStagePlanConfig: (
      state: JobStagePlanConfigState,
      action: PayloadAction<UpdateJobStagePlanConfigPayload>
    ) => {
      const { payload } = action;
      state.byId[payload.jobStageId] = { ...state.byId[payload.jobStageId], ...payload };
    },

    clearJobStagePlanConfig(state: JobStagePlanConfigState) {
      state.byId = {};
    },
  },
});

export const { reducer } = jobStagePlanConfigSlice;
export default jobStagePlanConfigSlice;
