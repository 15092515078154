import { SvgIcon as SvgIconMui, SvgIconProps as SvgIconPropsMuiProps, Tooltip } from '@mui/material';
import { FC } from 'react';

export type SvgIconProps = SvgIconPropsMuiProps & { tooltip?: string };

export const SvgIcon: FC<SvgIconProps> = (props): JSX.Element => {
  const { children, viewBox, tooltip, ...rest } = props;

  const icon = (
    <SvgIconMui {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      {children}
    </SvgIconMui>
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{icon}</Tooltip>;
  }

  return icon;
};
