import React from 'react';

import BaseIcon, { Props } from './Icon';

const DateIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 1C5.75 0.585786 5.41422 0.25 5 0.25C4.58579 0.25 4.25 0.585786 4.25 1V2.25H3C1.48122 2.25 0.249998 3.48122 0.25 5L0.250014 14C0.250016 15.5188 1.48123 16.75 3.00001 16.75H13C14.5188 16.75 15.75 15.5188 15.75 14L15.75 4.99999C15.75 3.48121 14.5188 2.25 13 2.25H11.75V1C11.75 0.585786 11.4142 0.25 11 0.25C10.5858 0.25 10.25 0.585786 10.25 1V2.25H5.75V1ZM5 7C4.44772 7 4 7.44772 4 8V10C4 10.5523 4.44772 11 5 11H7C7.55228 11 8 10.5523 8 10V8C8 7.44772 7.55228 7 7 7H5Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DateIcon;
