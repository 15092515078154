import React, { FC } from 'react';

import { ResponseStatus } from 'src/generated/mloop-graphql';

import IconWithBadge from './IconWithBadge';
import InterviewerRsvpIcon from './InterviewerRsvpIcon';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface IconWithRsvpProps {
  responseStatus: ResponseStatus | undefined | null;
  icon: JSX.Element | null;
  interviewerRsvpIconDataTestId?: string;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const IconWithRsvp: FC<IconWithRsvpProps> = ({ icon, responseStatus, interviewerRsvpIconDataTestId }) => {
  const rsvpIcon = responseStatus ? (
    <InterviewerRsvpIcon dataTestId={interviewerRsvpIconDataTestId} responseStatus={responseStatus} />
  ) : null;

  return <IconWithBadge badge={rsvpIcon} icon={icon} />;
};

export default IconWithRsvp;
