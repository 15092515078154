import React from 'react';

import BaseIcon, { Props } from './Icon';

const VideoIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 7C2.25 5.48122 3.48122 4.25 5 4.25H11C12.5188 4.25 13.75 5.48121 13.75 7L13.75 7.5L15.75 5.99999C16.574 5.38195 17.75 5.96993 17.75 6.99999V13C17.75 14.03 16.574 14.618 15.75 14L13.75 12.5L13.75 13C13.75 14.5188 12.5188 15.75 11 15.75H5C3.48122 15.75 2.25 14.5188 2.25 13V7ZM5 5.75C4.30964 5.75 3.75 6.30964 3.75 7V13C3.75 13.6904 4.30964 14.25 5 14.25H11C11.6904 14.25 12.25 13.6904 12.25 13L12.25 7C12.25 6.30964 11.6903 5.75 11 5.75H5Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default VideoIcon;
