import { SvgIcon, SvgIconProps } from './SvgIcon';

export const TraineePlusIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M5.34162 4.32933L9.44097 2.27966C9.79288 2.1037 10.2071 2.1037 10.559 2.27966L14.6583 4.32933C15.2111 4.60572 15.2111 5.39457 14.6583 5.67097L10.559 7.72064C10.2071 7.89659 9.79288 7.89659 9.44097 7.72064L5.34163 5.67097C4.78884 5.39457 4.78884 4.60572 5.34162 4.32933ZM7 7.99991V7.61794L9.10558 8.67073C9.66863 8.95226 10.3314 8.95226 10.8944 8.67073L13 7.61795V7.99991C13 9.65676 11.6569 10.9999 10 10.9999C8.34314 10.9999 7 9.65676 7 7.99991ZM9.99999 12.2499C10.6386 12.2499 11.2994 12.3317 11.9355 12.502C11.4133 12.5353 11 12.9694 11 13.5V17C11 17.2986 11.1309 17.5667 11.3384 17.7499L6.0002 17.7499C5.52154 17.7499 5.06554 17.5531 4.75183 17.2139C4.42834 16.8641 4.25681 16.3562 4.40158 15.8154C5.06686 13.3299 7.67918 12.2499 9.99999 12.2499ZM15.25 7.99994C15.25 7.58572 14.9142 7.24994 14.5 7.24994C14.0858 7.24994 13.75 7.58572 13.75 7.99994V8.99994C13.75 9.41415 14.0858 9.74994 14.5 9.74994C14.9142 9.74994 15.25 9.41415 15.25 8.99994V7.99994ZM15.5 12.25C15.9142 12.25 16.25 12.5858 16.25 13L16.25 14.75H18C18.4142 14.75 18.75 15.0858 18.75 15.5C18.75 15.9142 18.4142 16.25 18 16.25H16.25L16.25 18C16.25 18.4142 15.9142 18.75 15.5 18.75C15.0858 18.75 14.75 18.4142 14.75 18L14.75 16.25H13C12.5858 16.25 12.25 15.9142 12.25 15.5C12.25 15.0858 12.5858 14.75 13 14.75H14.75L14.75 13C14.75 12.5858 15.0858 12.25 15.5 12.25Z"
      />
    </SvgIcon>
  );
};
