import React from 'react';

import BaseIcon, { Props } from './Icon';

const LastWeek = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.25C7.41422 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48121 17.75 5.99999L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H6.25V2C6.25 1.58579 6.58579 1.25 7 1.25ZM6.25 5V4.75H5C4.30964 4.75 3.75 5.30964 3.75 6L3.75001 15C3.75001 15.6904 4.30966 16.25 5.00001 16.25H15C15.6904 16.25 16.25 15.6904 16.25 15L16.25 6C16.25 5.30964 15.6903 4.75 15 4.75H13.75V5C13.75 5.41421 13.4142 5.75 13 5.75C12.5858 5.75 12.25 5.41421 12.25 5V4.75H7.75V5C7.75 5.41421 7.41422 5.75 7 5.75C6.58579 5.75 6.25 5.41421 6.25 5ZM9.53033 9.03033C9.82322 8.73743 9.82322 8.26256 9.53033 7.96967C9.23744 7.67677 8.76256 7.67677 8.46967 7.96967L5.96967 10.4697C5.82902 10.6103 5.75 10.8011 5.75 11C5.75 11.1989 5.82902 11.3897 5.96967 11.5303L8.46967 14.0303C8.76256 14.3232 9.23744 14.3232 9.53033 14.0303C9.82322 13.7374 9.82322 13.2626 9.53033 12.9697L8.31066 11.75L13.5 11.75C13.9142 11.75 14.25 11.4142 14.25 11C14.25 10.5858 13.9142 10.25 13.5 10.25L8.31066 10.25L9.53033 9.03033Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default LastWeek;
