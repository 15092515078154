/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { IsoDateFormat, getLocalTimezone } from '@modernloop/shared/datetime';
import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import {
  addDays,
  addHours,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  isBefore,
  isPast,
  isSameMonth,
  startOfDay as startOfDayDateFns,
  startOfToday,
} from 'date-fns';
import { difference } from 'lodash';

import {
  DateTimeRangeOutput,
  JobStageInterviewGroupInput,
  SelfScheduleZoomHost,
  SuggestedTimeRangesRequestType,
} from 'src/generated/mloop-graphql';

import MultipleDatePicker, { MultipleDatePickerProps } from 'src/components/date-time-picker/MultipleDatePicker';
import Label from 'src/components/label';

import CompanyHolidayIcon from 'src/entities/CompanyHolidayIcon';
import useGetCompanyHolidaysForDate, {
  CompanyHolidaysForDays,
} from 'src/entities/CompanyHolidayIcon/useGetCompanyHolidaysForDate';

import usePrevious from 'src/hooks/usePrevious';

import IsoTimestamp, { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { endOfDay } from 'src/utils/dateUtils';

import OptionDay from './OptionDay';
import useLoadSelfScheduleOptionsByTimeRange from './useLoadSelfScheduleOptionsByTimeRange';

type Props = {
  applicationId: string;
  taskId?: string;
  requestType: SuggestedTimeRangesRequestType;
  jobStageId: string;
  customInterviewPlan?: JobStageInterviewGroupInput[];
  customJobStageId: string | undefined; // This can be either original or custom job stage id
  rollingDays?: number;
  inclusionDays?: IsoTimestamp[];
  selfScheduleZoomHost?: SelfScheduleZoomHost;
  advanceNoticeInHours?: number;
  datePickerNoMargin?: boolean;
  onLoading: (loading: boolean) => void;
  onSelectedDatesOptionsCountChanged: (newCount: number) => void;
  onSelectedDatesChanged: (dates: IsoTimestamp[]) => void;
  onEmployeeMissingZoomUserId: (employeeIds: string[]) => void;
  onSelfScheduleCountError: (errorMessage: string) => void;
  shouldRespectLoadLimit: boolean;
  canScheduleOverAvailableKeywords: boolean;
  canScheduleOverRecruitingKeywords: boolean;
  canScheduleOverFreeTime: boolean;
  skipFetchingOptions?: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 380,
      width: 370,
      '& .MuiPickersCalendarHeader-daysHeader': {
        gap: '8px',
      },
      '& .MuiPickersCalendarHeader-dayLabel': {
        width: '44px',
        margin: 0,
      },
      '& .MuiPickersCalendar-week': {
        gap: '8px',
        marginBottom: '4px',
      },
    },
    rootNoMargin: {
      '& > *': {
        margin: 'unset !important',
      },
    },
  })
);

const HolidayIconWrapperProps: BoxProps = {
  position: 'absolute',
  top: '-8px',
  right: '-8px',
  zIndex: 9,
};

const OptionsCalendar = ({
  applicationId,
  taskId,
  requestType,
  jobStageId,
  customJobStageId,
  customInterviewPlan,
  rollingDays,
  inclusionDays,
  selfScheduleZoomHost,
  advanceNoticeInHours,
  datePickerNoMargin,
  onLoading,
  onSelectedDatesOptionsCountChanged,
  onSelectedDatesChanged,
  onEmployeeMissingZoomUserId,
  onSelfScheduleCountError,
  shouldRespectLoadLimit,
  canScheduleOverAvailableKeywords,
  canScheduleOverRecruitingKeywords,
  canScheduleOverFreeTime,
  skipFetchingOptions,
}: Props) => {
  const classes = useStyles();
  const timezone = getLocalTimezone();
  const [selectedDates, setSelectedDates] = useState<IsoTimestamp[]>([]);
  const [countByDayMap, setCountByDayMap] = useState<{ [day: IsoTimestamp]: number }>({});
  const [loadingOptions, setLoadingOptions] = useState(true);
  const [loadPendingDays, setLoadPendingDays] = useState(false);

  const timeRangesLoadedRef = useRef<DateTimeRangeOutput[]>([]);

  const prevSelfScheduleZoomHost = usePrevious(selfScheduleZoomHost);
  const prevAdvanceNoticeInHours = usePrevious(advanceNoticeInHours);
  const shouldSkipFetchingOptions = skipFetchingOptions;

  const loadSelfScheduleOptionsByTimeRange = useLoadSelfScheduleOptionsByTimeRange({
    applicationId,
    onEmployeeMissingZoomUserId,
    onError: onSelfScheduleCountError,
    requestType,
    selfScheduleZoomHost,
    taskId,
  });

  /** Start and end date for calendar option range */
  const startDateRangeIsoTime: IsoTimestamp = assertIsoTimestamp(
    startOfDayDateFns(addHours(new Date(), advanceNoticeInHours ?? 0)).toISOString()
  );
  const endDateRangeIsoTime: IsoTimestamp = assertIsoTimestamp(endOfMonth(addMonths(new Date(), 2)).toISOString());

  const dateToCompanyHolidays: CompanyHolidaysForDays = useGetCompanyHolidaysForDate(
    startDateRangeIsoTime,
    endDateRangeIsoTime
  );

  const advanceNoticeDays = useMemo(() => {
    const result: IsoTimestamp[] = [];
    if (!advanceNoticeInHours) return result;

    let date = startOfDayDateFns(new Date());

    for (let i = 0; i < advanceNoticeInHours / 24; i++) {
      result.push(assertIsoTimestamp(date.toISOString()));
      date = addDays(date, 1);
    }

    return result;
  }, [advanceNoticeInHours]);

  const updateTimeRangesLoaded = useCallback((timeRanges: DateTimeRangeOutput[]) => {
    timeRanges.forEach((range) => {
      const index = timeRangesLoadedRef.current.findIndex(
        (dateRange) => dateRange.startAt === range.startAt && dateRange.endAt === range.endAt
      );

      if (index !== -1) return;
      timeRangesLoadedRef.current.push(range);
    });
  }, []);

  // This calculates the new selected dates when `rollingNumberOfWeeks` changes.
  useEffect(() => {
    setLoadPendingDays(true);
    if (inclusionDays) {
      setSelectedDates(inclusionDays);
      return;
    }

    if (!rollingDays) return;
    let date = startOfDayDateFns(new Date());
    const dates: IsoTimestamp[] = [];

    for (let i = 0; i < rollingDays; i++) {
      if (!advanceNoticeDays.includes(assertIsoTimestamp(date.toISOString()))) {
        dates.push(assertIsoTimestamp(date.toISOString()));
      }
      date = addDays(date, 1);
    }

    setSelectedDates(dates);
  }, [advanceNoticeDays, inclusionDays, rollingDays]);

  // Reset countByDayMap when Zoom host changes
  useEffect(() => {
    if (prevSelfScheduleZoomHost === selfScheduleZoomHost) return;
    setCountByDayMap({});
  }, [prevSelfScheduleZoomHost, selfScheduleZoomHost]);

  // Calculates if there is a change in number of selected dates and updates the candidate options count.
  useEffect(() => {
    let count = 0;

    Object.keys(countByDayMap).forEach((key: IsoTimestamp) => {
      if (!selectedDates.includes(key) || advanceNoticeDays.includes(key)) return;
      count += countByDayMap[key];
    });

    onSelectedDatesOptionsCountChanged(count);
  }, [advanceNoticeDays, countByDayMap, onSelectedDatesOptionsCountChanged, selectedDates]);

  const handleSelectedDatesChanged = (dates: IsoTimestamp[]) => {
    setSelectedDates(dates);
    if (onSelectedDatesChanged) {
      onSelectedDatesChanged(dates);
    }
  };

  const getTimeRangeByWeek = useCallback(
    (firstDay: Date, lastDay: Date): DateTimeRangeOutput[] => {
      const timeRanges: DateTimeRangeOutput[] = [];
      let temp = firstDay;

      while (temp <= lastDay) {
        let endAt = endOfWeek(temp);
        endAt = isSameMonth(firstDay, endAt) ? endAt : lastDay;

        if (advanceNoticeInHours && isBefore(temp, addHours(Date.now(), advanceNoticeInHours))) {
          temp = addHours(Date.now(), advanceNoticeInHours);
        }

        // The check `isBefore(temp, endAt)` ensures that we don't add invalid time range.
        // This happens when temp = Saturday and there is non-zero advance notice which causes temp to be set to (temp + advanceNoticeInHours)
        // Because of the above change to temp it is possible that temp becomes Sunday and endAt is still Saturday (endOfWeek).
        // This particular issue only happens when the someone is trying to get SelfScheduleOptionsCount on Saturday.

        if (isBefore(temp, endAt)) {
          timeRanges.push({
            startAt: temp.toISOString(),
            endAt: endAt.toISOString(),
            // TODO: Remove below 2 fields once GQL makes the change
            start: undefined,
            end: undefined,
          });
        }

        temp = startOfDayDateFns(addDays(endAt, 1));
      }

      return timeRanges;
    },
    [advanceNoticeInHours]
  );

  const handleMonthChange = useCallback(
    (newMonth: IsoTimestamp) => {
      const firstDay = isPast(new Date(newMonth)) ? startOfToday() : new Date(newMonth);
      const lastDay = endOfMonth(firstDay);
      const timeRanges = getTimeRangeByWeek(firstDay, lastDay);

      onLoading(true);
      setLoadingOptions(true);

      updateTimeRangesLoaded(timeRanges);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/catch-or-return
      loadSelfScheduleOptionsByTimeRange(
        timeRanges,
        timezone,
        advanceNoticeInHours,
        countByDayMap,
        setCountByDayMap,
        jobStageId,
        shouldRespectLoadLimit,
        canScheduleOverAvailableKeywords,
        canScheduleOverRecruitingKeywords,
        canScheduleOverFreeTime,
        customJobStageId,
        'network-only',
        customInterviewPlan,
        shouldSkipFetchingOptions
      ).finally(() => {
        onLoading(false);
        setLoadingOptions(false);
      });
    },
    [
      getTimeRangeByWeek,
      onLoading,
      updateTimeRangesLoaded,
      loadSelfScheduleOptionsByTimeRange,
      timezone,
      advanceNoticeInHours,
      countByDayMap,
      jobStageId,
      shouldRespectLoadLimit,
      canScheduleOverAvailableKeywords,
      canScheduleOverRecruitingKeywords,
      canScheduleOverFreeTime,
      customJobStageId,
      customInterviewPlan,
      shouldSkipFetchingOptions,
    ]
  );

  // Fetch options count when the component mounts
  useEffect(() => {
    handleMonthChange(assertIsoTimestamp(startOfDayDateFns(new Date()).toISOString()));
    // disabling dependency check because we want to fire this effect only on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch options count for days that are part of the selection but are not yet fetched.
  // This function loads in time ranges of a week from Sun → Sat
  // E.g. When use current date is 20 Jan and user has selected 3 weeks
  //      in this case in addition to loading options count for month of Jan we also need to fetch
  //      count for Feb until 9th.
  useEffect(() => {
    if (loadingOptions || !loadPendingDays) return;

    setLoadPendingDays(false);

    const datesToLoad = difference(selectedDates, Object.keys(countByDayMap));
    datesToLoad.sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });

    if (!datesToLoad || !datesToLoad.length) return;

    const timeRanges = getTimeRangeByWeek(new Date(datesToLoad[0]), new Date(datesToLoad[datesToLoad.length - 1]));

    onLoading(true);
    setLoadingOptions(true);

    updateTimeRangesLoaded(timeRanges);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    loadSelfScheduleOptionsByTimeRange(
      timeRanges,
      timezone,
      advanceNoticeInHours,
      countByDayMap,
      setCountByDayMap,
      jobStageId,
      shouldRespectLoadLimit,
      canScheduleOverAvailableKeywords,
      canScheduleOverRecruitingKeywords,
      canScheduleOverFreeTime,
      customJobStageId,
      'network-only',
      customInterviewPlan,
      shouldSkipFetchingOptions
    ).finally(() => {
      onLoading(false);
      setLoadingOptions(false);
    });
  }, [
    countByDayMap,
    customJobStageId,
    customInterviewPlan,
    getTimeRangeByWeek,
    jobStageId,
    shouldRespectLoadLimit,
    canScheduleOverAvailableKeywords,
    canScheduleOverRecruitingKeywords,
    canScheduleOverFreeTime,
    loadingOptions,
    loadSelfScheduleOptionsByTimeRange,
    onLoading,
    selectedDates,
    timezone,
    updateTimeRangesLoaded,
    loadPendingDays,
    advanceNoticeInHours,
    shouldSkipFetchingOptions,
  ]);

  /**
   * Used to refetch options count for all the date ranges fetched so far
   * when there is any change to SelfScheduleZoomHost
   */
  useEffect(() => {
    if (prevSelfScheduleZoomHost === selfScheduleZoomHost) return;

    onLoading(true);
    setLoadingOptions(true);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    loadSelfScheduleOptionsByTimeRange(
      timeRangesLoadedRef.current,
      timezone,
      advanceNoticeInHours,
      {},
      setCountByDayMap,
      jobStageId,
      shouldRespectLoadLimit,
      canScheduleOverAvailableKeywords,
      canScheduleOverRecruitingKeywords,
      canScheduleOverFreeTime,
      customJobStageId,
      'network-only',
      customInterviewPlan,
      shouldSkipFetchingOptions
    ).finally(() => {
      onLoading(false);
      setLoadingOptions(false);
    });
  }, [
    customJobStageId,
    customInterviewPlan,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line max-lines
    getTimeRangeByWeek,
    jobStageId,
    shouldRespectLoadLimit,
    canScheduleOverAvailableKeywords,
    canScheduleOverRecruitingKeywords,
    canScheduleOverFreeTime,
    loadSelfScheduleOptionsByTimeRange,
    onLoading,
    prevSelfScheduleZoomHost,
    selectedDates,
    selfScheduleZoomHost,
    timezone,
    advanceNoticeInHours,
    shouldSkipFetchingOptions,
  ]);

  /**
   * Used to refetch options count for all the date ranges fetched so far
   * when there is any change to advanceNoticeInHours
   */
  useEffect(() => {
    if (
      prevAdvanceNoticeInHours === null ||
      prevAdvanceNoticeInHours === undefined ||
      prevAdvanceNoticeInHours !== advanceNoticeInHours
    ) {
      return;
    }

    onLoading(true);
    setLoadingOptions(true);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    loadSelfScheduleOptionsByTimeRange(
      timeRangesLoadedRef.current,
      timezone,
      advanceNoticeInHours,
      {},
      setCountByDayMap,
      jobStageId,
      shouldRespectLoadLimit,
      canScheduleOverAvailableKeywords,
      canScheduleOverRecruitingKeywords,
      canScheduleOverFreeTime,
      customJobStageId,
      'network-only',
      customInterviewPlan,
      shouldSkipFetchingOptions
    ).finally(() => {
      onLoading(false);
      setLoadingOptions(false);
    });
  }, [
    advanceNoticeInHours,
    customInterviewPlan,
    customJobStageId,
    shouldRespectLoadLimit,
    canScheduleOverAvailableKeywords,
    canScheduleOverRecruitingKeywords,
    canScheduleOverFreeTime,
    jobStageId,
    loadSelfScheduleOptionsByTimeRange,
    onLoading,
    prevAdvanceNoticeInHours,
    timezone,
    shouldSkipFetchingOptions,
  ]);

  const renderDay: MultipleDatePickerProps['renderDay'] = (
    day,
    selectedDay,
    dayInCurrentMonth,
    dayComponent: JSX.Element
    // eslint-disable-next-line max-params
  ) => {
    if (!day) return dayComponent;

    const companyHolidays = dateToCompanyHolidays[format(day.toDate(), IsoDateFormat)] || null;

    if (
      !dayInCurrentMonth ||
      (day &&
        (isPast(endOfDay(day.toDate(), timezone)) || advanceNoticeDays.includes(assertIsoTimestamp(day.toISOString()))))
    ) {
      return (
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          borderRadius="6px"
          width="44px"
          height="44px"
        >
          <Label variant="captions" fontWeight={500} style={{ textAlign: 'center' }} color="mid-contrast-grey">
            {day?.format('D')}
          </Label>
          {dayInCurrentMonth && (
            <CompanyHolidayIcon companyHolidays={companyHolidays} iconWrapperProps={HolidayIconWrapperProps} />
          )}
        </Box>
      );
    }

    const dayIsoTimeStamp = assertIsoTimestamp(startOfDayDateFns(day.toDate()).toISOString());
    const options = countByDayMap[dayIsoTimeStamp];

    return (
      <Box position="relative">
        <OptionDay
          options={options}
          day={day}
          selected={Boolean(day && selectedDates.includes(assertIsoTimestamp(day.toISOString())))}
        />
        {dayInCurrentMonth && (
          <CompanyHolidayIcon companyHolidays={companyHolidays} iconWrapperProps={HolidayIconWrapperProps} />
        )}
      </Box>
    );
  };

  return (
    <MultipleDatePicker
      minDate={startDateRangeIsoTime}
      maxDate={endDateRangeIsoTime}
      preferredDatesUTC={[]}
      selectedDatesUTC={selectedDates}
      timezone={timezone}
      className={clsx(classes.root, { [classes.rootNoMargin]: datePickerNoMargin })}
      onSelectedDatesChanged={handleSelectedDatesChanged}
      renderDay={renderDay}
      onMonthChange={handleMonthChange}
      shouldDisableDate={(day: IsoTimestamp) => advanceNoticeDays.includes(day)}
    />
  );
};

export default OptionsCalendar;
