import { useFlag } from '@modernloop/shared/feature-flag';

import { useIntegrations } from '../api/integration';

const useGemEnabled = (): boolean => {
  const isGemEnabled = useFlag('user_gem_integration');
  const { data } = useIntegrations();
  return isGemEnabled || !!data?.gem;
};

export default useGemEnabled;
