import React from 'react';

import BaseIcon, { Props } from './Icon';

const UserOptionalIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 2.75C8.20508 2.75 6.75 4.20507 6.75 6V7C6.75 8.79493 8.20508 10.25 10 10.25C11.7949 10.25 13.25 8.79493 13.25 7V6C13.25 4.20507 11.7949 2.75 10 2.75ZM8.25 6C8.25 5.0335 9.0335 4.25 10 4.25C10.9665 4.25 11.75 5.0335 11.75 6V7C11.75 7.9665 10.9665 8.75 10 8.75C9.0335 8.75 8.25 7.9665 8.25 7V6ZM5.17319 14.7537C5.96952 13.5016 7.88034 12.75 10 12.75C12.1197 12.75 14.0305 13.5016 14.8268 14.7537C14.8906 14.8541 14.8952 14.9218 14.8909 14.9643C14.8858 15.0138 14.8617 15.0846 14.7919 15.1675C14.6438 15.3437 14.347 15.5 13.9994 15.5L6.00053 15.5C5.65293 15.5 5.35617 15.3437 5.20806 15.1675C5.13834 15.0846 5.11424 15.0138 5.10915 14.9643C5.10477 14.9218 5.10935 14.8541 5.17319 14.7537ZM16.0925 13.9487C14.895 12.0658 12.3431 11.25 10 11.25C7.65692 11.25 5.10501 12.0658 3.90749 13.9487C3.41188 14.728 3.57776 15.5592 4.0598 16.1327C4.51385 16.6729 5.24357 17 6.00053 17L13.9994 17C14.7564 17 15.4861 16.6729 15.9402 16.1327C16.4222 15.5592 16.5881 14.728 16.0925 13.9487Z"
          fill="currentColor"
        />
      </svg>
    </BaseIcon>
  );
};

export default UserOptionalIcon;
