import React, { useRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { ListItem, ListItemIcon, Menu, MenuItem, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import Avatar from 'src/components/Avatar';
import Divider from 'src/components/Divider';
import IconButton from 'src/components/IconButton';
import ImageBlock from 'src/components/ImageBlock';
import Button from 'src/components/button';
import { ArrowRightIcon, LogoutIcon, UserEditIcon } from 'src/components/icons';
import CookieIcon from 'src/components/icons/Cookie';
import Label from 'src/components/label';

import { Routes } from 'src/constants/routes';

import useAuth from 'src/hooks/useAuth';
import useHistory from 'src/hooks/useHistory';
import useIsNewOrgExperience from 'src/hooks/useIsNewOrgExperience';
import useOrgName from 'src/hooks/useOrgName';

import { AccountSettingsTab, getAccountSettingUrls } from 'src/urls/getAccountSettingUrls';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type AccountMenuProps = {
  expanded: boolean;
  size?: 'small' | 'medium';
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '6px',
      '&:hover': {
        backgroundColor: theme.palette.background.contrast,
        boxShadow: 'none',
        '& svg': {
          color: theme.palette.info.main,
        },
      },
    },
    button: {
      height: '36px',
      display: 'flex',
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: theme.spacing(1.5),
      borderRadius: '6px',
      '&:hover': {
        color: theme.palette.info.main,
        '& svg': {
          color: theme.palette.info.main,
        },
      },
    },
  })
);

const AccountMenu = ({ expanded, size = 'medium' }: AccountMenuProps): JSX.Element => {
  const { userInfo, logout } = useAuth();
  const { orgName } = useOrgName();
  const classes = useStyles();
  const [showMenu, setShowMenu] = useState(false);
  const avatarRef = useRef<HTMLButtonElement>(null);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const isNewOrgExperience = useIsNewOrgExperience();

  const interviewerPortalEnabled = useFlag('org_interviewer_portal');
  const newOrgSettingsSidebar = useFlag('user_new_org_settings_sidebar');

  const handleLogout = async (): Promise<void> => {
    try {
      setShowMenu(false);
      await logout();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  const accountSettingUrl = newOrgSettingsSidebar
    ? getAccountSettingUrls({
        tab: AccountSettingsTab.GENERAL,
      })
    : Routes.Account;

  // TODO: Get user slack pic
  return (
    <>
      {!expanded && (
        <IconButton className={classes.iconButton} onClick={() => setShowMenu(true)} ref={avatarRef}>
          <Avatar alt={userInfo?.employeeName || ''} src={userInfo?.employeeImageUrl} size="20px" />
        </IconButton>
      )}
      {expanded && (
        <Button
          size={size}
          className={classes.button}
          fullWidth
          ref={avatarRef}
          onClick={() => setShowMenu(true)}
          startIcon={<Avatar alt={userInfo?.employeeName || ''} src={userInfo?.employeeImageUrl} size="20px" />}
          label="Your account"
          variant="unstyled"
        />
      )}
      {showMenu && (
        <Menu
          anchorEl={avatarRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open
          onClose={() => setShowMenu(false)}
        >
          {!isNewOrgExperience && (
            <>
              <ListItem aria-disabled="true">
                <ImageBlock
                  image={<Avatar alt={userInfo?.employeeName || ''} src={userInfo?.employeeImageUrl} size="36px" />}
                  title={userInfo?.employeeName ?? ''}
                  caption={
                    <Stack spacing={1}>
                      <Label>{userInfo?.employeeEmail}</Label>
                      <Label color="high-contrast-grey">{orgName}</Label>
                    </Stack>
                  }
                />
              </ListItem>

              <Divider style={{ margin: '8px 0' }} />
            </>
          )}
          {!isNewOrgExperience && (
            <MenuItem
              onClick={() => {
                history.replace(accountSettingUrl);
                setShowMenu(false);
              }}
            >
              <RouterLink
                to={accountSettingUrl}
                style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
              >
                <ListItemIcon>
                  <UserEditIcon />
                </ListItemIcon>
                <Label>Personal preferences</Label>
              </RouterLink>
            </MenuItem>
          )}

          {interviewerPortalEnabled && (
            <MenuItem
              onClick={() => {
                history.push('/ip');
                setShowMenu(false);
              }}
            >
              <RouterLink to="/ip" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
                <Label>Go to Interviewer Portal</Label>
              </RouterLink>
            </MenuItem>
          )}
          <MenuItem onClick={() => window?.OneTrust?.ToggleInfoDisplay()}>
            <ListItemIcon>
              <CookieIcon />
            </ListItemIcon>
            <Label>Cookie settings</Label>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <Label>Logout</Label>
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default AccountMenu;
