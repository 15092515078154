/* eslint-disable max-lines */
import React, { useState } from 'react';

import { TimezoneSelect } from '@modernloop/shared/components';
import { CircleWithCheckIcon, CrossIcon } from '@modernloop/shared/icons';
import {
  DEFAULT_WORK_HOURS,
  TimeBlockWeek,
  getTimePeriods,
  getUniqueTimeRanges,
  isTimeBlockWeekNotEmpty,
  mergeTimeRanges,
  sortTimeRanges,
} from '@modernloop/shared/utils';
import { Alert, Box, Button, CircularProgress, Stack, Tooltip, Typography } from '@mui/material';
import { max } from 'lodash';

import { DateTimeRangeInput } from 'src/generated/mloop-graphql';

import ImportIllustration from 'src/assets/images/import_availability_300_150.png';

import useFullScreen from 'src/components/Dialog/useFullScreen';

import { CreateLocations } from 'src/hooks/amplitude/useCreateLocationHeader';
import { OrgPrefName, useOrgPrefJson_DEPRECATED } from 'src/hooks/api/org';
import { useImportCandidateAvailabilities } from 'src/hooks/scheduling/useImportCandidateAvailabilities';
import useCompanyHolidays from 'src/hooks/useCompanyHolidays';
import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';

import { startOfNextDay } from 'src/utils/dateUtils';

import { AvailabilityRow } from 'src/views-new/ScheduleFlow/Steps/CandidateAvailability/AvailabilityRow';

import {
  getErrorText,
  getWarningText,
  hasWarningOrError,
} from 'src/views/booking/BookingCreateView/CandidateAvailabilityStep/candidateAvailabilityUtils';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface Props {
  candidateTimezone: string;
  value: DateTimeRangeInput[];
  onChange: (timezone: string, value: DateTimeRangeInput[]) => void;
  applicationId: string;
  setIsImportingAvailability: (isImporting: boolean) => void;
}

const AvailabilityInput: React.FC<Props> = ({
  value,
  candidateTimezone,
  onChange,
  applicationId,
  setIsImportingAvailability,
}) => {
  const isFullScreen = useFullScreen();

  const [orgWorkHours] = useOrgPrefJson_DEPRECATED<TimeBlockWeek>(OrgPrefName.OBJ_DEFAULT_WORK_HOURS);

  const holidays = useCompanyHolidays();

  const [importWarningLabel, setImportWarningLabel] = useState<string | null>(null);
  const [importSuccessLabel, setImportSuccessLabel] = useState<string | null>(null);
  const clearWarnings = () => {
    setImportSuccessLabel('');
    setImportWarningLabel('');
  };
  const [importComplete, setImportComplete] = useState<boolean>(false);

  const { logEvent } = useLogEvent();

  const rcEnteredAvailabilities: DateTimeRangeInput[] = value.map((v) => ({
    startAt: v.startAt,
    endAt: v.endAt,
  }));

  const handleChange = (newValues: DateTimeRangeInput[]) => {
    onChange(
      candidateTimezone,
      newValues.map((v) => ({ startAt: v.startAt, endAt: v.endAt }))
    );
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  const handleUpdateRCEnteredAvailability = (startAt: string, endAt: string, index: number) => {
    const newAvailabilities = [...rcEnteredAvailabilities];
    if (index >= newAvailabilities.length) return;

    newAvailabilities[index] = { startAt, endAt };
    handleChange(newAvailabilities);
  };

  const { importAvailabilities, importingAvailability } = useImportCandidateAvailabilities({
    applicationId,
    candidateTimezone,
    setImportWarningLabel,
    setImportSuccessLabel,
    setIsImportingAvailability,
    // eslint-disable-next-line max-params
    onCompleted: (candidateEnteredImports, rcEnteredImports, totalMinutes) => {
      handleChange(
        getUniqueTimeRanges(
          sortTimeRanges(mergeTimeRanges([...rcEnteredAvailabilities, ...rcEnteredImports, ...candidateEnteredImports]))
        )
      );
      logEvent(LoggerEvent.CLIENT_CANDIDATE_AVAILABILITY_IMPORTED, {
        importedMinutes: totalMinutes,
        location: CreateLocations.EXTENSION,
      });
      setIsImportingAvailability(false);
      setImportComplete(true);
    },
  });

  const handleTimezoneChange = (newTimezone: string) => {
    onChange(newTimezone, value);
  };

  const handleAddRCEnteredAvailability = () => {
    const endTime = startOfNextDay(
      max([...rcEnteredAvailabilities].map((time) => new Date(time.endAt).getTime())) ?? Date.now(),
      candidateTimezone
    );

    const modifiedOrgWorkHours = orgWorkHours ? { ...orgWorkHours } : null;
    if (modifiedOrgWorkHours && orgWorkHours) {
      Object.keys(orgWorkHours).forEach((day) => {
        if (orgWorkHours[day].length) {
          modifiedOrgWorkHours[day] = { startAt: orgWorkHours[day][0].start, endAt: orgWorkHours[day][0].end };
        }
      });
    }
    // If no work hours are set, default to 9am-5pm so we don't block adding availabilities
    const computedWorkHours = isTimeBlockWeekNotEmpty(modifiedOrgWorkHours) ? modifiedOrgWorkHours : DEFAULT_WORK_HOURS;
    const timePeriods = getTimePeriods(endTime.toISOString(), computedWorkHours, candidateTimezone);
    if (timePeriods.length === 0) return;
    handleChange([...rcEnteredAvailabilities, timePeriods[0]]);
  };

  const handleDeleteRCEnteredAvailability = (index: number) => {
    handleChange(rcEnteredAvailabilities.filter((_, i) => index !== i));
  };

  const renderAvailability = (availability: DateTimeRangeInput, index: number) => {
    const warningOrError = hasWarningOrError(
      { id: '', day: availability.startAt, startAt: availability.startAt, endAt: availability.endAt },
      candidateTimezone,
      holidays || []
    );

    const errorText = warningOrError
      ? getErrorText({
          id: '',
          day: availability.startAt,
          startAt: availability.startAt,
          endAt: availability.endAt,
        })
      : '';

    const warningText = warningOrError
      ? getWarningText(
          { id: '', day: availability.startAt, startAt: availability.startAt, endAt: availability.endAt },
          candidateTimezone,
          holidays || []
        )
      : '';

    if (!warningOrError) {
      return (
        <Box key={`${availability.startAt}-${availability.endAt}-${index}`}>
          <AvailabilityRow
            availability={availability}
            timezone={candidateTimezone}
            onChange={(start, end) => handleUpdateRCEnteredAvailability(start, end, index)}
            onDelete={() => handleDeleteRCEnteredAvailability(index)}
          />
        </Box>
      );
    }

    return (
      <Box key={`${availability.startAt}-${availability.endAt}-${index}`}>
        <Alert
          icon={false}
          severity={errorText ? 'error' : 'warning'}
          sx={{
            marginLeft: '-12px',
            marginRight: '-12px',
          }}
        >
          <AvailabilityRow
            availability={availability}
            timezone={candidateTimezone}
            onChange={(start, end) => handleUpdateRCEnteredAvailability(start, end, index)}
            onDelete={() => handleDeleteRCEnteredAvailability(index)}
          />

          <Box pt={1}>
            <Typography variant="caption">{errorText || warningText}</Typography>
          </Box>
        </Alert>
      </Box>
    );
  };

  const importButtonDecoration = () => {
    if (importingAvailability) {
      return <CircularProgress size={20} />;
    }
    if (importComplete) {
      return <CircleWithCheckIcon color="success" />;
    }
    return null;
  };

  return (
    <>
      <Typography variant="subtitle1">Candidate availability</Typography>
      <TimezoneSelect value={candidateTimezone} onTimezoneChange={handleTimezoneChange} disableClearable />
      {importSuccessLabel && (
        <Alert
          severity="success"
          action={
            <CrossIcon
              onClick={() => {
                clearWarnings();
              }}
              sx={{ cursor: 'pointer' }}
            />
          }
        >
          <Stack direction="row" alignItems="center" display="flex" flex="1">
            <Box flex="1" justifyContent="center">
              <Typography variant="body2">{importSuccessLabel}</Typography>
            </Box>
          </Stack>
        </Alert>
      )}
      {importWarningLabel && (
        <Alert
          severity="warning"
          action={
            <CrossIcon
              onClick={() => {
                clearWarnings();
              }}
              sx={{ cursor: 'pointer' }}
            />
          }
        >
          <Stack direction="row" alignItems="center" display="flex" flex="1">
            <Box flex="1" justifyContent="center">
              <Typography variant="body2">{importWarningLabel}</Typography>
            </Box>
          </Stack>
        </Alert>
      )}

      {rcEnteredAvailabilities && rcEnteredAvailabilities.length > 0 && (
        <Box>
          <Stack spacing={isFullScreen ? 2.5 : 1} sx={{ width: '100%' }}>
            {rcEnteredAvailabilities.map((availability, index) => {
              // eslint-disable-next-line max-lines
              return renderAvailability(availability, index);
            })}
          </Stack>
        </Box>
      )}

      <Stack gap={1} direction="row">
        <Button onClick={handleAddRCEnteredAvailability} disabled={!candidateTimezone || importingAvailability}>
          Add new
        </Button>

        <Tooltip
          sx={{ display: 'flex' }}
          title={
            <Stack justifyContent="center" alignContent="center" textAlign="center">
              <img src={ImportIllustration} alt="Import illustration" />
              <Typography>Import upcoming availability from all other tasks on this application</Typography>
            </Stack>
          }
        >
          <Button
            startIcon={importButtonDecoration()}
            onClick={() => {
              setIsImportingAvailability(true);
              clearWarnings();
              importAvailabilities();
            }}
            disabled={!candidateTimezone || importingAvailability}
          >
            Import availability
          </Button>
        </Tooltip>
      </Stack>
    </>
  );
};

export default AvailabilityInput;
