import { DeclineReason } from 'src/generated/mloop-graphql';

export const InterviewDeclineReasonDescription = [
  {
    value: DeclineReason.Missed,
    label: 'Missed',
  },
  {
    value: DeclineReason.OutsideWorkHours,
    label: 'Outside work hours',
  },
  {
    value: DeclineReason.OutOfOffice,
    label: 'Out of office',
  },
  {
    value: DeclineReason.Conflict,
    label: 'Conflict',
  },
  {
    value: DeclineReason.OverLimit,
    label: 'Too many interviews',
  },
  {
    value: DeclineReason.KnowTheCandidate,
    label: 'I know the candidate',
  },
  {
    value: DeclineReason.Other,
    label: 'Other',
  },
];
