import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { TextField as MuiTextField } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Autocomplete as MuiAutocomplete, AutocompleteProps as MuiAutocompleteProps } from '@material-ui/lab';

import VirtualList from 'src/components/VirtualList';
import { BODY_LINE_HEIGHT, BODY_SIZE } from 'src/components/constants/Typography';
import { WarningIcon } from 'src/components/icons';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/theme/type';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Optional<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  error?: boolean;
} & BaseProps;

export const useAutocompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ error }: { error?: boolean }) => ({
      border: `1px solid ${error ? theme.palette.error.light : theme.palette.border}`,
      backgroundColor: theme.palette.background.default,
      borderRadius: '6px',
      padding: '2px 8px',
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '&:hover .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '&:hover .MuiInput-underline:after': {
        border: 'none',
      },
    }),
    endAdornment: {
      top: 'auto',
    },
    input: {
      fontSize: BODY_SIZE,
      lineHeight: BODY_LINE_HEIGHT,
    },
    popupIndicatorOpen: {
      transform: 'rotate(0deg)',
    },
    paper: {
      borderRadius: '6px',
    },
    listbox: {
      '& ul': {
        margin: 0,
      },
    },
  })
);

/**
 * @deprecated - Use `Autocomplete` from `@mui/material` instead
 */
const Autocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
): JSX.Element => {
  let { renderInput } = props;
  const { error, dataTestId, ...restProps } = props;
  const classes = useAutocompleteStyles({ error });
  renderInput =
    renderInput ||
    ((params) => (
      <MuiTextField
        {...params}
        InputProps={{
          ...params.InputProps,
          startAdornment: error ? <WarningIcon color="error" /> : params?.InputProps?.startAdornment ?? undefined,
        }}
      />
    ));

  return (
    <MuiAutocomplete
      classes={classes}
      data-testid={dataTestId}
      disableListWrap
      ListboxComponent={VirtualList as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      renderInput={renderInput}
      {...restProps}
    />
  );
};

export default Autocomplete;
