import React from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';

import {
  DynamicAudience,
  UseCandidateSmartOptions_CandidateCoordinatorDataFragment,
  UseCandidateSmartOptions_CandidateRecruiterDataFragment,
  UseCandidateSmartOptions_CandidateSourcerDataFragment,
  UseCandidateSmartOptions_PreviousCandidateInterviewersDataFragment,
} from 'src/generated/mloop-graphql';

import { OptionRenderer } from './OptionRenderer';

export const DynamicEmployee = gql`
  ${OptionRenderer.fragments.employees}
  query CandidateDynamicEmployee($id: String!, $candidateId: uuid!, $applicationId: uuid!) {
    dynamicEmployee(id: $id) {
      CANDIDATE_COORDINATOR(input: { candidateId: $candidateId }) {
        ...OptionRenderer_employees
      }
      CANDIDATE_RECRUITER(input: { candidateId: $candidateId }) {
        ...OptionRenderer_employees
      }
      CANDIDATE_SOURCER(input: { candidateId: $candidateId }) {
        ...OptionRenderer_employees
      }
      INTERVIEWERS_FOR_THIS_CANDIDATE(input: { applicationId: $applicationId }) {
        ...OptionRenderer_employees
      }
    }
  }
`;

type Fragments = {
  candidateCoordinatorData: UseCandidateSmartOptions_CandidateCoordinatorDataFragment[];
  candidateRecruiterData: UseCandidateSmartOptions_CandidateRecruiterDataFragment[];
  candidateSourcerData: UseCandidateSmartOptions_CandidateSourcerDataFragment[];
  previousCandidateInterviewersData: UseCandidateSmartOptions_PreviousCandidateInterviewersDataFragment[];
};

type HookReturn = {
  options: DynamicAudience[];
  renderer: (props: { optionType: DynamicAudience; isDisabled: boolean; isChecked: boolean }) => JSX.Element | null;
  getData: (
    optionType: DynamicAudience
  ) =>
    | UseCandidateSmartOptions_CandidateCoordinatorDataFragment[]
    | UseCandidateSmartOptions_CandidateRecruiterDataFragment[]
    | UseCandidateSmartOptions_CandidateSourcerDataFragment[]
    | UseCandidateSmartOptions_PreviousCandidateInterviewersDataFragment[]
    | undefined
    | null;
};

export const useCandidateSmartOptions: FunctionWithFragments<Fragments, null, HookReturn> = ({
  candidateCoordinatorData,
  candidateRecruiterData,
  candidateSourcerData,
  previousCandidateInterviewersData,
}) => {
  const returnOptionData = (optionType: DynamicAudience) => {
    switch (optionType) {
      case DynamicAudience.CandidateCoordinator:
        return candidateCoordinatorData;
      case DynamicAudience.CandidateRecruiter:
        return candidateRecruiterData;
      case DynamicAudience.CandidateSourcer:
        return candidateSourcerData;
      case DynamicAudience.PreviousCandidateInterviewers:
        return previousCandidateInterviewersData;
      default:
        return null;
    }
  };

  return {
    options: [
      DynamicAudience.CandidateCoordinator,
      DynamicAudience.CandidateRecruiter,
      DynamicAudience.CandidateSourcer,
      DynamicAudience.PreviousCandidateInterviewers,
    ],
    renderer: ({
      optionType,
      isDisabled,
      isChecked,
    }: {
      optionType: DynamicAudience;
      isDisabled: boolean;
      isChecked: boolean;
    }) => {
      const disabledForContext = isDisabled;

      switch (optionType) {
        case DynamicAudience.CandidateCoordinator:
          return (
            <OptionRenderer
              type={DynamicAudience.CandidateCoordinator}
              employees={returnOptionData(DynamicAudience.CandidateCoordinator)}
              isDisabled={disabledForContext}
              isChecked={isChecked}
            />
          );
        case DynamicAudience.CandidateRecruiter:
          return (
            <OptionRenderer
              type={DynamicAudience.CandidateRecruiter}
              employees={returnOptionData(DynamicAudience.CandidateRecruiter)}
              isDisabled={disabledForContext}
              isChecked={isChecked}
            />
          );
        case DynamicAudience.CandidateSourcer:
          return (
            <OptionRenderer
              type={DynamicAudience.CandidateSourcer}
              employees={returnOptionData(DynamicAudience.CandidateSourcer)}
              isDisabled={disabledForContext}
              isChecked={isChecked}
            />
          );
        case DynamicAudience.PreviousCandidateInterviewers:
          return (
            <OptionRenderer
              type={DynamicAudience.PreviousCandidateInterviewers}
              employees={returnOptionData(DynamicAudience.PreviousCandidateInterviewers)}
              isDisabled={disabledForContext}
              isChecked={isChecked}
            />
          );
        default:
          return null;
      }
    },
    getData: (optionType: DynamicAudience) => {
      switch (optionType) {
        case DynamicAudience.CandidateCoordinator:
          return returnOptionData(DynamicAudience.CandidateCoordinator);
        case DynamicAudience.CandidateRecruiter:
          return returnOptionData(DynamicAudience.CandidateRecruiter);
        case DynamicAudience.CandidateSourcer:
          return returnOptionData(DynamicAudience.CandidateSourcer);
        case DynamicAudience.PreviousCandidateInterviewers:
          return returnOptionData(DynamicAudience.PreviousCandidateInterviewers);
        default:
          return [];
      }
    },
  };
};

useCandidateSmartOptions.fragments = {
  candidateCoordinatorData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useCandidateSmartOptions_candidateCoordinatorData on Employee {
      ...OptionRenderer_employees
    }
  `,
  candidateRecruiterData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useCandidateSmartOptions_candidateRecruiterData on Employee {
      ...OptionRenderer_employees
    }
  `,
  candidateSourcerData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useCandidateSmartOptions_candidateSourcerData on Employee {
      ...OptionRenderer_employees
    }
  `,
  previousCandidateInterviewersData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useCandidateSmartOptions_previousCandidateInterviewersData on Employee {
      ...OptionRenderer_employees
    }
  `,
};
