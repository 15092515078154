import urlcat from 'urlcat';

import { SearchParamKnownKeys } from 'src/hooks/useUrlSearchParams';

/**
 * Update Flow Url
 * @param applicationStageId
 * @returns string
 */
const getUpdateFlowUrl = (
  applicationStageId: string,
  options?: { useRelativeUrl?: boolean; stageInterviewId?: string; candidateComms?: boolean; addNewInterview?: boolean }
): string => {
  const { useRelativeUrl = true, stageInterviewId } = options || {};
  const currentUrl = new URL(window.location.href);
  const baseUrl = useRelativeUrl ? '' : currentUrl.origin;
  return urlcat(baseUrl, '/update/:applicationStageId', {
    applicationStageId,
    [SearchParamKnownKeys.stageInterviewId]: stageInterviewId,
    [SearchParamKnownKeys.candidateComms]: options?.candidateComms !== undefined ? options.candidateComms : undefined,
    [SearchParamKnownKeys.addNewInterview]:
      options?.addNewInterview !== undefined ? `${options.addNewInterview}` : undefined,
  });
};

export default getUpdateFlowUrl;
