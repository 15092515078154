/* eslint-disable max-lines */
import React, { useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { PublicError } from '@modernloop/shared/helper-components';
import { getExternalErrorMessage } from '@modernloop/shared/utils';
import {
  Box,
  Button,
  CircularProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { format as formatTz } from 'date-fns-tz';
import { useSnackbar } from 'notistack';

import {
  EmployeeFragment,
  RescheduleLogModal_RescheduleLogFragment,
  useEmployeeByIdsQuery,
  useRescheduleLogCreateMutation,
  useRescheduleLogUpdateMutation,
  useRescheduleReasonsListQuery,
} from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import SelectButton from 'src/components/SelectButton';
import DatePicker from 'src/components/date-time-picker/DatePickerPopover';
import { DateIcon } from 'src/components/icons';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

import SingleEmployeeSelect from 'src/entities/Select/EmployeeSelect/SingleEmployeeSelect';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useEmployeeId from 'src/hooks/useEmployeeId';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { PAGE_SIZE } from 'src/constants';

interface Props {
  taskId: string;
  onClose: () => void;
}

interface Fragments {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/component-with-fragments.cjs
  rescheduleLog?: RescheduleLogModal_RescheduleLogFragment;
}

const DEFAULT_REASON = 'Add a reason';

const RescheduleLogModal: FCWithFragments<Fragments, Props> = ({ rescheduleLog, taskId, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRescheduleReasonId, setSelectedRescheduleReasonId] = useState(
    rescheduleLog?.rescheduleReasonId || DEFAULT_REASON
  );
  const [showDatePicker, setShowDatePicker] = useState(false);
  const dateButtonRef = useRef<HTMLButtonElement>(null);
  const { data, loading } = useEmployeeByIdsQuery({ variables: { input: [useEmployeeId()] || [] } });
  const [performedBy, setPerformedBy] = useState<EmployeeFragment | undefined>(
    rescheduleLog?.performedBy as EmployeeFragment
  );
  const [rescheduledAt, setRescheduledAt] = useState(
    rescheduleLog ? new Date(rescheduleLog.rescheduledAt) : new Date()
  );
  const [note, setNote] = useState(rescheduleLog?.note || '');
  const [noteError, setNoteError] = useState('');
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const [rescheduleLogCreateMutation, { loading: creating }] = useRescheduleLogCreateMutation();
  const [rescheduleLogUpdateMutation, { loading: updating }] = useRescheduleLogUpdateMutation();
  const refetchActivityFeed = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();
  const { data: rescheduleReasonsData, error: rescheduleReasonsDataError } = useRescheduleReasonsListQuery({
    variables: {
      input: {
        pageInput: {
          limit: PAGE_SIZE,
        },
        prefix: '',
        includeArchived: false,
      },
    },
  });

  if (rescheduleReasonsDataError) {
    enqueueSnackbar(getExternalErrorMessage(rescheduleReasonsDataError), { variant: 'error' });
  }

  const validate = () => {
    const isValid = selectedRescheduleReasonId && selectedRescheduleReasonId !== DEFAULT_REASON && performedBy?.id;
    setShowValidationErrors(!isValid);
    return isValid;
  };

  const createRescheduleLog = async () => {
    await rescheduleLogCreateMutation({
      variables: {
        input: {
          taskId,
          rescheduleReasonId: selectedRescheduleReasonId,
          note,
          performedByEmployeeId: performedBy?.id,
          rescheduledAt,
        },
      },
    }) // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      .then(() => {
        refetchScheduleTaskQuery();
        refetchActivityFeed();
        enqueueSnackbar(`Reschedule log created successfully`, { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(getExternalErrorMessage(error), { variant: 'error' });
      });
  };

  const updateRescheduleLog = async () => {
    await rescheduleLogUpdateMutation({
      variables: {
        input: {
          id: rescheduleLog?.id,
          rescheduleReasonId: selectedRescheduleReasonId,
          note,
          performedByEmployeeId: performedBy?.id,
          rescheduledAt,
        },
      },
    }) // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      .then(() => {
        refetchScheduleTaskQuery();
        refetchActivityFeed();
        enqueueSnackbar(`Reschedule log updated successfully`, { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(getExternalErrorMessage(error), { variant: 'error' });
      });
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/validate-component-definition.cjs
  function getEmployeeLabel() {
    let user = performedBy;
    if (!performedBy) {
      user = data?.employeeByIds?.[0];
      setPerformedBy(user as EmployeeFragment);
    }

    const label = (
      <Stack direction="row" spacing={1}>
        <Box ml={-0.5}>
          <Avatar alt={user?.fullName ?? ''} src={user?.slackImageUrl ?? undefined} />
        </Box>

        <Box
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <MaybeTooltip label={user?.fullName ?? ''} tooltip={user?.fullName ?? ''} />
        </Box>
      </Stack>
    );

    return (
      <Box width="100%">
        <SelectButton variant="outlined" label={label} size="medium" />
      </Box>
    );
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <ConditionalThemeProvider>
      <Dialog
        open
        onClose={onClose}
        title="Log a reschedule"
        subTitle=""
        cancelOptions={{
          onClick: onClose,
          label: 'Cancel',
        }}
        submitOptions={{
          onClick: async () => {
            if (!validate()) return;
            if (!rescheduleLog) {
              await createRescheduleLog();
            } else {
              await updateRescheduleLog();
            }
            onClose();
          },
          isLoading: creating || updating,
          isDisabled: creating || updating,
          label: 'Save',
        }}
      >
        <Stack direction="column" spacing={2}>
          <Stack direction="column" spacing={1}>
            <Typography variant="subtitle2">Reschedule date</Typography>
            <Button
              sx={{
                alignItems: 'left',
                justifyContent: 'left',
                fontWeight: '200',
              }}
              color="secondary"
              startIcon={<DateIcon />}
              ref={dateButtonRef}
              onClick={() => setShowDatePicker(true)}
            >
              {formatTz(new Date(rescheduledAt), 'MMM d, yyyy', { timeZone: getLocalTimezone() })}
            </Button>
            <DatePicker
              open={showDatePicker}
              timezone={getLocalTimezone()}
              dataTestId="start-date-calendar"
              dateAnchorEl={dateButtonRef}
              utcDate={rescheduledAt}
              onClose={() => setShowDatePicker(!showDatePicker)}
              onChange={(val) => setRescheduledAt(val)}
              disablePast={false}
              disableFuture
            />
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography variant="subtitle2">Performed by</Typography>
            <SingleEmployeeSelect
              onEmployeeSelect={(newMember) => setPerformedBy(newMember)}
              getLabel={() => {
                return getEmployeeLabel();
              }}
            />
            {showValidationErrors && !performedBy && (
              <Typography color="error.main" variant="body2">
                Select an employee
              </Typography>
            )}
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography variant="subtitle2">Reschedule reason</Typography>
            <Select
              fullWidth
              error={showValidationErrors && selectedRescheduleReasonId === DEFAULT_REASON}
              value={selectedRescheduleReasonId}
              onChange={(val) => {
                setSelectedRescheduleReasonId(val.target.value);
              }}
              renderValue={(value) => {
                const rescheduleReason = rescheduleReasonsData?.rescheduleReasons?.items?.find(
                  (item) => item.id === value
                );
                if (rescheduleReason) {
                  return <Typography variant="body1">{rescheduleReason.reason}</Typography>;
                }
                return (
                  <Typography variant="body1" color="text.secondary">
                    Add a reason
                  </Typography>
                );
              }}
            >
              {rescheduleReasonsData?.rescheduleReasons?.items?.map((rescheduleReason) => {
                return (
                  <MenuItem key={rescheduleReason.id} value={rescheduleReason.id}>
                    <ListItemText primary={rescheduleReason.reason} />
                  </MenuItem>
                );
              })}
            </Select>
            {showValidationErrors && selectedRescheduleReasonId === DEFAULT_REASON && (
              <Typography color="error.main" variant="body2">
                Select a reschedule reason
              </Typography>
            )}
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography variant="subtitle2">Note</Typography>
            <TextField
              placeholder="Note"
              onChange={(val) => {
                setNote(val.target.value);
                setNoteError('');
              }}
              value={note}
              fullWidth
              multiline
              rows={3}
              error={noteError !== ''}
            />
            {noteError !== '' && <PublicError error={noteError} />}
          </Stack>
        </Stack>
      </Dialog>
    </ConditionalThemeProvider>
  );
};

RescheduleLogModal.fragments = {
  rescheduleLog: gql`
    fragment RescheduleLogModal_rescheduleLog on RescheduleLog {
      id
      note
      rescheduledAt
      createdAt
      updatedAt
      lastUpdatedBy {
        id
        fullName
      }
      rescheduleReasonId
      rescheduleReason {
        reason
      }
      performedBy {
        id
        fullName
      }
    }
  `,
};

export const RescheduleLogCreateMutation = gql`
  mutation RescheduleLogCreate($input: RescheduleLogCreateInput!) {
    rescheduleLogCreate(input: $input) {
      rescheduleLog {
        ...RescheduleLogModal_rescheduleLog
      }
    }
  }
`;

export const RescheduleLogUpdateMutation = gql`
  mutation RescheduleLogUpdate($input: RescheduleLogUpdateInput!) {
    rescheduleLogUpdate(input: $input) {
      rescheduleLog {
        ...RescheduleLogModal_rescheduleLog
      }
    }
  }
`;

export default RescheduleLogModal;
