import React from 'react';

import { MenuItem, Select } from '@mui/material';

import { SECONDS_IN_A_DAY } from 'src/constants';

const UNLIMITED_DURATION_WINDOW_KEY = 'unlimited';

export const DURATION_WINDOW_OPTIONS = [
  { value: 1 * SECONDS_IN_A_DAY, label: '1 day' },
  { value: 2 * SECONDS_IN_A_DAY, label: '2 days' },
  { value: 3 * SECONDS_IN_A_DAY, label: '3 days' },
  { value: 4 * SECONDS_IN_A_DAY, label: '4 days' },
  { value: 5 * SECONDS_IN_A_DAY, label: '5 days' },
  { value: 6 * SECONDS_IN_A_DAY, label: '6 days' },
  { value: 7 * SECONDS_IN_A_DAY, label: '7 days' },
  { value: 8 * SECONDS_IN_A_DAY, label: '8 days' },
  { value: 9 * SECONDS_IN_A_DAY, label: '9 days' },
  { value: 10 * SECONDS_IN_A_DAY, label: '10 days' },
  { value: 11 * SECONDS_IN_A_DAY, label: '11 days' },
  { value: 12 * SECONDS_IN_A_DAY, label: '12 days' },
  { value: 13 * SECONDS_IN_A_DAY, label: '13 days' },
  { value: 14 * SECONDS_IN_A_DAY, label: '14 days' },
  { value: UNLIMITED_DURATION_WINDOW_KEY, label: 'Unlimited' },
];

type Props = {
  disabled?: boolean;
  durationSec: number | null;
  onChange: (durationSec: number | null) => void;
};

const DurationWindowPicker = ({ disabled, durationSec, onChange }: Props) => {
  const handleDefaultSchedulingWindowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.isInteger(event.target.value) ? parseInt(event.target.value, 10) : null;
    onChange(value);
  };

  return (
    <Select
      onChange={handleDefaultSchedulingWindowChange}
      value={`${durationSec || UNLIMITED_DURATION_WINDOW_KEY}`}
      fullWidth
      disabled={disabled}
    >
      {DURATION_WINDOW_OPTIONS.map((option) => (
        <MenuItem key={option.value} value={option.value || UNLIMITED_DURATION_WINDOW_KEY}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DurationWindowPicker;
