import React, { forwardRef } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import MuiIconButton from '@material-ui/core/IconButton';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { CreateCSSProperties } from '@material-ui/styles';

import { BaseProps } from 'src/components/types';
import {
  SupportedColor,
  SupportedGrey,
  getGreyFromTheme,
  isSupportedColor,
  isSupportedGrey,
} from 'src/components/utils/color';

import { Theme } from 'src/theme/type';

import Tooltip, { TooltipProps } from '../tooltip';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface IconButtonProps extends BaseProps {
  ariaLabel?: string;

  // eslint-disable-next-line react/no-unused-prop-types
  color?: 'default' | SupportedColor | SupportedGrey;

  className?: string;

  children?: React.ReactNode;

  disabled?: boolean;

  tooltip?: TooltipProps['tooltip'];

  // eslint-disable-next-line react/no-unused-prop-types
  hideDisabledBackgroundColor?: boolean;

  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;

  edge?: false | 'end' | 'start' | undefined;

  // Used in useStyles
  // eslint-disable-next-line react/no-unused-prop-types
  variant?: 'outlined' | 'contained';

  /**
   * Pass in direct styles. Will allow easier conversion to sx props.
   */
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: IconButtonProps) => {
      const disabledProps = props.hideDisabledBackgroundColor
        ? {}
        : {
            '&:disabled': {
              backgroundColor: theme.palette.action.disabled,
            },
          };

      if (isSupportedColor(props.color)) {
        if (props.variant === 'outlined') {
          return {
            padding: '4px',
            color: theme.palette[props.color].main,
            border: `1px solid ${theme.palette[props.color].main}`,
            borderColor: theme.palette[props.color].main,
            backgroundColor: theme.palette.common.transparent,
            '&:hover': {
              boxShadow: theme.shadows[2],
              backgroundColor: theme.palette[props.color].light,
            } as CreateCSSProperties,
            ...disabledProps,
          };
        }

        return {
          padding: '4px',
          color: theme.palette[props.color].contrastText,
          backgroundColor: theme.palette[props.color].main,
          '&:hover': {
            backgroundColor: theme.palette[props.color].dark,
            boxShadow: theme.shadows[2],
          } as CreateCSSProperties,
          ...disabledProps,
        };
      }

      if (isSupportedGrey(props.color)) {
        if (props.variant === 'outlined') {
          return {
            padding: '4px',
            color: getGreyFromTheme(props.color, theme),
            border: `1px solid ${theme.palette.border}`,
            '&:hover': {
              boxShadow: theme.shadows[2],
            },
            ...disabledProps,
          };
        }

        return {
          padding: '4px',
          backgroundColor: getGreyFromTheme(props.color, theme),
          '&:hover': {
            boxShadow: theme.shadows[1],
          },
          ...disabledProps,
        };
      }

      if (props.variant === 'outlined') {
        return {
          padding: '4px',
          color: theme.palette.text.primary,
          border: `1px solid ${theme.palette.border}`,
          backgroundColor: theme.palette.common.transparent,
          '&:hover': {
            boxShadow: theme.shadows[2],
            backgroundColor: theme.palette.background.contrast,
          } as CreateCSSProperties,
          ...disabledProps,
        };
      }

      return {
        padding: '4px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.common.transparent,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        } as CreateCSSProperties,
        ...disabledProps,
      };
    },
  })
);

/**
 * @deprecated - Use `IconButton` from `@mui/material` instead
 */
const IconButton: React.ForwardRefRenderFunction<HTMLButtonElement, IconButtonProps> = (props, ref): JSX.Element => {
  const { ariaLabel, className, children, disabled, dataTestId, onClick, tooltip, edge, style } = props;
  const classes = useStyles(props);

  const button = (
    <MuiIconButton
      style={style}
      aria-label={ariaLabel}
      onClick={onClick}
      classes={classes}
      className={className}
      ref={ref}
      disabled={disabled}
      data-testid={dataTestId}
      edge={edge}
    >
      {children}
    </MuiIconButton>
  );

  if (tooltip) {
    return <Tooltip tooltip={tooltip}>{button}</Tooltip>;
  }

  return button;
};

export default forwardRef<HTMLButtonElement, IconButtonProps>(IconButton);
