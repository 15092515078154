/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';
import { CrossIcon, EditIcon } from '@modernloop/shared/icons';
import { Avatar, Box, CircularProgress, IconButton, Popover, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { unset } from 'lodash';

import {
  AttributeNameValueMapInput,
  InterviewModuleMemberSearchOrderByProperty,
  Sort,
  TrainingStatus,
  useInterviewModuleMemberFilteredByAttributesQuery,
  useModuleMemberStatsQuery,
} from 'src/generated/mloop-graphql';

import Chip from 'src/components/chip';
import DirectoryIcon from 'src/components/icons/Directory';
import FilterIcon from 'src/components/icons/Filter';
import ModulesIcon from 'src/components/icons/Modules';
import TraineeIcon from 'src/components/icons/Trainee';
import Tooltip from 'src/components/tooltip';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';
import { FCWithFragment } from 'src/components/types';

import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import EmployeeAndAttributesFilter from 'src/views-new/EmployeeAndAttributesFilter';

import { PAGE_SIZE } from 'src/constants';

import { useAvatarSxProps, useGridSxProps, useSxProps } from './sxProps';

interface Props {
  jobStageInterviewSeat: JobStageInterviewSeat;
  rowRef: React.RefObject<HTMLDivElement>;
  handleMouseOut: () => void;
  handleMouseOver: () => void;
  seatIndex: number;
  onEdit: () => void;
  showOptions: boolean;
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onUpdated: () => void;
}

const ModuleSeat: FCWithFragment<Props> = ({
  jobStageInterviewSeat,
  rowRef,
  handleMouseOut,
  handleMouseOver,
  seatIndex,
  onEdit,
  showOptions,
  handleDelete,
  onUpdated,
}) => {
  const sxProps = useSxProps();
  const gridSxProps = useGridSxProps();
  const avatarSxProps = useAvatarSxProps();
  const interviewModule = jobStageInterviewSeat?.moduleSeat?.interviewModule;

  const [attributeMap, setAttributeMap] = useState(jobStageInterviewSeat.attributeMap ?? {});

  useEffect(() => {
    setAttributeMap(jobStageInterviewSeat.attributeMap ?? {});
  }, [jobStageInterviewSeat]);

  const [showAttributeFilter, setShowAttributeFilter] = useState(false);

  // load all the interview module stats
  // this is used to show the # of trained when no filters are applied
  const { data: statsData, loading: statsLoading, refetch: statsRefetch } = useModuleMemberStatsQuery();

  const isFastTrackEnabled = useFlag('user_fast_track_interview_module');
  const moduleMemberQueryVariables = useMemo(() => {
    const variables = {
      input: {
        interviewModuleId: interviewModule?.id,
        pageInput: {
          limit: PAGE_SIZE,
          offset: 0,
        },
        isArchived: false,
        isAtsDisabled: false,
        isDirectoryDisabled: false,
        orderBy: {
          property: InterviewModuleMemberSearchOrderByProperty.ProgressPercentage,
          direction: Sort.Desc,
        },
      },
    };

    if (!isFastTrackEnabled) {
      unset(variables, 'input.orderBy');
    }

    return variables;
  }, [interviewModule?.id, isFastTrackEnabled]);

  // fetch module members that have the attributes selected
  const {
    data: searchModuleMembersData,
    loading: moduleMembersLoading,
    refetch: searchModuleMembers,
  } = useInterviewModuleMemberFilteredByAttributesQuery({
    variables: moduleMemberQueryVariables,
    fetchPolicy: 'network-only', // force this to fetch from the backend each time in case modules/members/attributes are added while in the scheduling flow
  });

  const handleShowAttributeFilter = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowAttributeFilter(true);

    // This is to prevent onEdit from trigging which is attached to the parent <Grid />
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const handleHideAttributeFilter = () => {
    setShowAttributeFilter(false);
  };

  useEffect(() => {
    // rerun the filter query every time the user changes their attributes selection
    if (!interviewModule || !showAttributeFilter) {
      return;
    }

    const attributesInput: AttributeNameValueMapInput[] = [];
    for (const [key, value] of Object.entries(attributeMap)) {
      attributesInput.push({ attributeNameId: key, attributeValueIds: value });
    }

    searchModuleMembers({ input: { ...moduleMemberQueryVariables.input, attributeNameValueMap: attributesInput } });
    statsRefetch();
  }, [
    attributeMap,
    interviewModule,
    moduleMemberQueryVariables,
    searchModuleMembers,
    showAttributeFilter,
    statsRefetch,
  ]);

  // Pre calculating a flat list of all the tagId's applied to module
  let flatAppliedAttributes: string[] = [];
  Object.keys(attributeMap ?? {}).forEach((nameId) => {
    if (!attributeMap || !attributeMap[nameId]) return;
    flatAppliedAttributes = flatAppliedAttributes.concat(attributeMap[nameId]);
  });

  if (!jobStageInterviewSeat) return null;
  if (!jobStageInterviewSeat.interviewId) return <Typography variant="caption">None Selected</Typography>;

  if (!interviewModule) return null;

  const interviewModuleMembers = searchModuleMembersData?.interviewModuleMemberSearch?.items;

  // when we have the search results, filter into trained, shadow, reverse shadow
  let filteredTrained =
    interviewModuleMembers?.filter((value) => {
      return value.status === TrainingStatus.Trained;
    }) || [];

  const hasManuallySelectedTrainedInterviewers =
    jobStageInterviewSeat.selectedTrainedInterviewerIds &&
    jobStageInterviewSeat.selectedTrainedInterviewerIds.length > 0;

  const hasManuallySelectedInTrainingInterviewers =
    (jobStageInterviewSeat.selectedShadowInterviewerIds &&
      jobStageInterviewSeat.selectedShadowInterviewerIds.length > 0) ||
    (jobStageInterviewSeat.selectedReverseShadowInterviewerIds &&
      jobStageInterviewSeat.selectedReverseShadowInterviewerIds.length > 0);

  filteredTrained = filteredTrained.filter((value) =>
    hasManuallySelectedTrainedInterviewers && jobStageInterviewSeat.selectedTrainedInterviewerIds
      ? jobStageInterviewSeat.selectedTrainedInterviewerIds.indexOf(value.employeeId) !== -1
      : true
  );

  const interviewStats = statsData?.interviewModuleMemberStats?.filter(
    (stats) => stats?.interviewModule?.id === interviewModule?.id
  )?.[0];

  let trainingLabel = '';

  let totalShadow = [];
  let totalReverseShadow = [];

  if (interviewStats) {
    totalShadow = interviewStats.interviewModule?.membersInShadowCount;
    totalReverseShadow = interviewStats.interviewModule?.membersInReverseShadowCount;
  }

  if (isFastTrackEnabled) {
    const totalInTrainig = Number(totalShadow || 0) + Number(totalReverseShadow || 0);
    trainingLabel = `(${totalInTrainig} in training)`;
  } else if (totalShadow && totalReverseShadow) {
    trainingLabel = `(${totalShadow} shadow, ${totalReverseShadow} reverse shadow)`;
  } else if (totalShadow) {
    trainingLabel = `(${totalShadow} shadow)`;
  } else if (totalReverseShadow) {
    trainingLabel = `(${totalReverseShadow} reverse shadow)`;
  } else {
    trainingLabel = `(0 in training)`;
  }

  const trained = filteredTrained?.length || 0;

  return (
    <>
      <Grid ref={rowRef}>
        <Box onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} sx={sxProps.box} onClick={onEdit}>
          <Grid container justifyContent="space-between" sx={gridSxProps.root} spacing={1} tabIndex={0} wrap="nowrap">
            <Grid style={{ flexGrow: 1 }}>
              <Grid container direction="column">
                <Grid container alignItems="center" spacing={1} style={{ width: '100%' }}>
                  <Grid>
                    <Avatar sx={[avatarSxProps.root, trained === 0 && !moduleMembersLoading && sxProps.errorChip]}>
                      {seatIndex + 1}
                    </Avatar>
                  </Grid>
                  <Grid>
                    <Typography>One from:</Typography>
                  </Grid>
                  <Grid style={{ overflow: 'hidden', maxWidth: '100%' }}>
                    <Chip
                      key={jobStageInterviewSeat.interviewId}
                      sx={trained === 0 && !moduleMembersLoading ? sxProps.errorChip : undefined}
                      label={<MaybeTooltip tooltip={interviewModule.name} label={interviewModule.name} />}
                      avatar={<ModulesIcon />}
                      variant={showOptions ? 'outlined' : 'default'}
                    />
                  </Grid>
                  {flatAppliedAttributes.length > 0 && (
                    <Grid>
                      <Chip
                        sx={trained === 0 && !moduleMembersLoading ? sxProps.errorChip : undefined}
                        label={`${flatAppliedAttributes.length}`}
                        avatar={<FilterIcon />}
                        variant={showOptions ? 'outlined' : 'default'}
                      />
                    </Grid>
                  )}
                  {(hasManuallySelectedTrainedInterviewers || hasManuallySelectedInTrainingInterviewers) && (
                    <Grid>
                      <Chip
                        label={`${
                          (jobStageInterviewSeat?.selectedTrainedInterviewerIds?.length || 0) +
                          (jobStageInterviewSeat?.selectedShadowInterviewerIds?.length || 0) +
                          (jobStageInterviewSeat?.selectedReverseShadowInterviewerIds?.length || 0)
                        }`}
                        avatar={<DirectoryIcon />}
                        variant={showOptions ? 'outlined' : 'default'}
                      />
                    </Grid>
                  )}
                  <Grid>
                    <Typography
                      sx={trained === 0 && !moduleMembersLoading ? sxProps.errorLabel : undefined}
                    >{`(${trained} trained)`}</Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={1} sx={sxProps.shadow}>
                  <Grid>
                    <TraineeIcon />
                  </Grid>
                  <Grid>
                    <Typography>Training:</Typography>
                  </Grid>
                  <Grid style={{ overflow: 'hidden', maxWidth: '100%' }}>
                    <Chip
                      key={jobStageInterviewSeat.interviewId}
                      sx={trained === 0 && !moduleMembersLoading ? sxProps.errorChip : undefined}
                      label={<MaybeTooltip tooltip={interviewModule.name} label={interviewModule.name} />}
                      avatar={<ModulesIcon />}
                      variant={showOptions ? 'outlined' : 'default'}
                    />
                  </Grid>
                  <Grid>
                    <>
                      {statsLoading && <CircularProgress size={24} />}
                      {statsData && <Typography>{trainingLabel}</Typography>}
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" alignItems="center" xs wrap="nowrap" sx={sxProps.options}>
              <Grid>
                <Tooltip tooltip="Apply attributes">
                  <IconButton sx={sxProps.actionButton} onClick={handleShowAttributeFilter} size="small">
                    <FilterIcon fontSize={20} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid>
                <Tooltip tooltip="Edit">
                  <IconButton sx={sxProps.actionButton} onClick={onEdit} size="small">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid>
                <Tooltip tooltip="Delete">
                  <IconButton sx={sxProps.actionButton} onClick={handleDelete} size="small">
                    <CrossIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {showAttributeFilter && interviewModule.name && (
        <Popover
          id={jobStageInterviewSeat.interviewId}
          open={showAttributeFilter}
          anchorEl={rowRef.current}
          onClose={handleHideAttributeFilter}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {moduleMembersLoading && <CircularProgress size={24} />}
          {!moduleMembersLoading && (
            <EmployeeAndAttributesFilter
              interviewModuleMembers={interviewModuleMembers || interviewModule?.interviewModuleMembers?.items || []}
              interviewModuleId={interviewModule?.id}
              seat={jobStageInterviewSeat}
              onUpdated={onUpdated}
            />
          )}
        </Popover>
      )}
    </>
  );
};

ModuleSeat.fragment = {
  interviewModule: gql`
    ${EmployeeAndAttributesFilter.fragment.employeeAndAttributesFilter}
    fragment EditModuleSeatInterviewModule on InterviewModuleMember {
      interviewModuleId
      ...EmployeeAndAttributesFilter
    }
  `,
  interviewModuleStats: gql`
    fragment EditModuleSeatInterviewStats on InterviewModule {
      id
      name
      membersCount
      membersInReverseShadowCount
      membersInShadowCount
      membersTrainedCount
    }
  `,
};

export const InterviewModuleFilteredByAttributesQuery = gql`
  ${ModuleSeat.fragment.interviewModule}
  query InterviewModuleMemberFilteredByAttributes($input: InterviewModuleMemberSearchInput!) {
    interviewModuleMemberSearch(input: $input) {
      items {
        ...EditModuleSeatInterviewModule
      }
    }
  }
`;

export const InterviewModuleStats = gql`
  ${ModuleSeat.fragment.interviewModuleStats}
  query ModuleMemberStats {
    interviewModuleMemberStats {
      interviewModule {
        ...EditModuleSeatInterviewStats
      }
    }
  }
`;

export default ModuleSeat;
