import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';

import { InterviewType, UseGetInterviewNames_InterviewsFragment } from 'src/generated/mloop-graphql';

type Fragments = {
  interviews: UseGetInterviewNames_InterviewsFragment[];
};

const useGetInterviewNames: FunctionWithFragments<Fragments, void, string> = ({ interviews }) => {
  const names = interviews
    .filter((interview) => ![InterviewType.Break, InterviewType.DayDivider].includes(interview.interviewType))
    .map((interview) => interview.name)
    .filter(Boolean);
  return names.join(', ');
};

useGetInterviewNames.fragments = {
  interviews: gql`
    fragment useGetInterviewNames_interviews on JobStageInterview {
      id
      name
      interviewType
    }
  `,
};

export default useGetInterviewNames;
