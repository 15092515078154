import React from 'react';

import BaseIcon, { Props } from './Icon';

const GoogleMeetIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path d="M5 13.332V6.668h6.668v6.664zm0 0" fill="#fff" />
      <path d="M1.25 7.082v5.836l2.082.414 2.086-.414V7.082l-2.086-.414zm0 0" fill="#1e88e5" />
      <path
        d="M15.418 10v5.832c0 .691-.563 1.25-1.25 1.25h-8.75L5 15l.418-2.082h5.832V10l2.082-.418zm0 0"
        fill="#4caf50"
      />
      <path d="M15.418 4.168V10H11.25V7.082H5.418L5 5l.418-2.082h8.75c.687 0 1.25.559 1.25 1.25zm0 0" fill="#fbc02d" />
      <path d="M5.418 12.918v4.164H2.5c-.691 0-1.25-.559-1.25-1.25v-2.914zm0 0" fill="#1565c0" />
      <path d="M5.418 2.918v4.164H1.25zm0 0" fill="#e53935" />
      <path d="M15.832 10l-.414 3.52L11.25 10l4.168-3.52zm0 0" fill="#2e7d32" />
      <path
        d="M19.168 4.21v11.58c0 .347-.41.542-.68.323l-3.07-2.593V6.48l3.07-2.593a.419.419 0 01.68.324zm0 0"
        fill="#4caf50"
      />
    </BaseIcon>
  );
};

export default GoogleMeetIcon;
