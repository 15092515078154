import { SvgIcon, SvgIconProps } from './SvgIcon';

export const UserOptionalIcon = ({ viewBox, ...rest }: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...rest} viewBox={viewBox || '0 0 16 16'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 2.2002C6.56408 2.2002 5.40002 3.36425 5.40002 4.80019V5.6002C5.40002 7.03614 6.56408 8.2002 8.00002 8.2002C9.43596 8.2002 10.6 7.03614 10.6 5.6002V4.8002C10.6 3.36426 9.43596 2.2002 8.00002 2.2002ZM6.60002 4.80019C6.60002 4.027 7.22682 3.4002 8.00002 3.4002C8.77322 3.4002 9.40002 4.027 9.40002 4.8002V5.6002C9.40002 6.37339 8.77322 7.0002 8.00002 7.0002C7.22682 7.0002 6.60002 6.37339 6.60002 5.6002V4.80019ZM3.92624 12.9852C3.90726 12.977 3.89444 12.9675 3.88699 12.9603C4.07652 12.3004 4.57899 11.7666 5.32156 11.3865C6.07067 11.0031 7.02444 10.8002 8.00002 10.8002C8.97561 10.8002 9.92937 11.0031 10.6785 11.3865C11.4211 11.7666 11.9235 12.3004 12.1131 12.9603C12.1056 12.9674 12.0928 12.977 12.0738 12.9852C12.0525 12.9944 12.0269 13.0002 11.9991 13.0002L4.00095 13.0002C3.97314 13.0002 3.94759 12.9944 3.92624 12.9852ZM13.2714 12.6466C12.9693 11.5724 12.1775 10.8057 11.2252 10.3183C10.2753 9.83209 9.12675 9.60018 8.00002 9.60019C6.87329 9.60019 5.72474 9.8321 4.77482 10.3183C3.82256 10.8057 3.03076 11.5724 2.72862 12.6466C2.6065 13.0808 2.74272 13.4899 3.00202 13.771C3.25294 14.0429 3.61837 14.2002 4.00095 14.2002L11.9991 14.2002C12.3817 14.2002 12.7471 14.0429 12.998 13.7709C13.2573 13.4898 13.3936 13.0808 13.2714 12.6466Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
