import { SvgIcon, SvgIconProps } from './SvgIcon';

export const JumpIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.25006C7.58579 5.25006 7.25 5.58585 7.25 6.00006C7.25 6.41427 7.58579 6.75006 8 6.75006H12.1894L6.96967 11.9697C6.67678 12.2626 6.67678 12.7375 6.96967 13.0304C7.26256 13.3233 7.73743 13.3233 8.03033 13.0304L13.25 7.81071V12C13.25 12.4142 13.5858 12.75 14 12.75C14.4142 12.75 14.75 12.4142 14.75 12V6.00006C14.75 5.58585 14.4142 5.25006 14 5.25006H8ZM5 14C4.44772 14 4 14.4477 4 15C4 15.5523 4.44772 16 5 16C5.55228 16 6 15.5523 6 15C6 14.4477 5.55228 14 5 14Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
