/* eslint-disable import/prefer-default-export */
import { v4 as uuid } from 'uuid';

import { AtsInterviewDefinition, InterviewType } from 'src/generated/mloop-graphql';

import { getAtsInterviewDefinitionFields } from 'src/hooks/atsService/util';

import { updateJobStage } from 'src/store/actions/job-stage';
import {
  addJobStageInterviewGroup,
  deleteJobStageInterviewGroup,
  updateJobStageInterviewGroup,
} from 'src/store/actions/job-stage-interview-group';
import { deleteJobStageInterviewSeat } from 'src/store/actions/job-stage-interview-seat';
import { getJobStageById } from 'src/store/selectors/job-stage';
import { getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';
import { getJobStageInterviewGroupById } from 'src/store/selectors/job-stage-interview-group';
import jobStageInterviewSlice, {
  JobStageInterview,
  UpdateJobStageInterviewPayload,
} from 'src/store/slices/job-stage-interview';
import { JobStageInterviewGroup } from 'src/store/slices/job-stage-interview-group';

import { isBreak, isDayBreak } from 'src/utils/interview';

import { AppThunk } from 'src/store';
import { DEFAULT_INTERVIEW_NAME } from 'src/strings';

export const createJobStageInterview =
  (
    jobStageId: string,
    type: InterviewType,
    id?: string,
    isBreakshuffle?: boolean
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const newGroupId = uuid();
    const jobStageInterview: JobStageInterview = {
      id: id || uuid(),
      type,
      name: isBreak(type) ? 'Break' : DEFAULT_INTERVIEW_NAME,
      index: 0,
      schedulable: true,
      jobStageId,
      jobStageGroupId: newGroupId,
      duration: 45,
      isLockedOrder: isBreakshuffle ? isDayBreak(type) : isBreak(type), // Day divider are not suffelable
      seatIds: [],
      forcedStartAt: null,
    };
    const jobStage = getJobStageById(state, jobStageInterview.jobStageId);
    dispatch(jobStageInterviewSlice.actions.addJobStageInterview(jobStageInterview));

    // Add the interview to a new group.
    const jobStageinterviewGroup: JobStageInterviewGroup = {
      id: jobStageInterview.jobStageGroupId,
      jobStageId: jobStageInterview.jobStageId,
      locked: isBreakshuffle ? isDayBreak(type) : isBreak(type), // Day divider are not suffelable
      jobStageInterviewIds: [jobStageInterview.id],
    };

    dispatch(addJobStageInterviewGroup(jobStageinterviewGroup));

    // Add the group to job stage.
    dispatch(
      updateJobStage({
        id: jobStageInterview.jobStageId,
        jobStageInterviewGroupIds: [...jobStage.jobStageInterviewGroupIds, jobStageinterviewGroup.id],
      })
    );
  };

export const addJobStageInterview =
  (jobStageInterview: JobStageInterview): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageInterviewSlice.actions.addJobStageInterview(jobStageInterview));
  };

export const updateJobStageInterview =
  (jobStageInterview: UpdateJobStageInterviewPayload): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageInterviewSlice.actions.updateJobStageInterview(jobStageInterview));
  };

export const deleteJobStageInterview =
  (jobStageInterviewId: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const interview = getJobStageInterviewById(state, jobStageInterviewId);
    const group = getJobStageInterviewGroupById(state, interview.jobStageGroupId);

    // Delete all the seats to take care of linked seats.
    interview.seatIds.forEach((seatId) => dispatch(deleteJobStageInterviewSeat(interview.id, seatId)));

    // Remove interview from group or delete group if it is the only interview in that group
    const jobStageInterviewIds = group.jobStageInterviewIds.filter((id) => id !== jobStageInterviewId);
    if (jobStageInterviewIds.length === 0) {
      dispatch(deleteJobStageInterviewGroup(group.id));
    } else {
      dispatch(updateJobStageInterviewGroup({ id: group.id, jobStageInterviewIds }));
    }

    dispatch(jobStageInterviewSlice.actions.deleteJobStageInterview(jobStageInterviewId));
  };

export const addJobStageInterviewError =
  (jobStageInterviewId: string, error: string): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageInterviewSlice.actions.addJobStageInterviewError({ jobStageInterviewId, error }));
  };

export const deleteJobStageInterviewError =
  (jobStageInterviewId: string): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageInterviewSlice.actions.deleteJobStageInterviewError(jobStageInterviewId));
  };

export const addJobStageInterviewLoading =
  (jobStageInterviewId: string, loading: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageInterviewSlice.actions.addJobStageInterviewLoading({ jobStageInterviewId, loading }));
  };

export const deleteJobStageInterviewLoading =
  (jobStageInterviewId: string): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageInterviewSlice.actions.deleteJobStageInterviewLoading(jobStageInterviewId));
  };

export const createJobStageInterviewFromAts =
  (jobStageId: string, atsInterviewDefinition: AtsInterviewDefinition): AppThunk =>
  async (dispatch) => {
    const id = uuid();

    // The await here ensures the the interview card header is expanded in UI 🤷‍♂️
    await dispatch(createJobStageInterview(jobStageId, InterviewType.Interview, id));

    const { atsFields } = atsInterviewDefinition;

    dispatch(
      updateJobStageInterview({
        id,
        atsInterviewDefinition,
        name: atsInterviewDefinition.name || DEFAULT_INTERVIEW_NAME,
        duration: getAtsInterviewDefinitionFields(atsFields || undefined, 'estimatedMinutes') || 45,
      })
    );
  };

export const hideJobStageInterviewFromCandidate =
  (jobStageInterviewId: string): AppThunk =>
  async (dispatch) => {
    dispatch(updateJobStageInterview({ id: jobStageInterviewId, isHiddenFromCandidate: true }));
  };

export const showJobStageInterviewToCandidate =
  (jobStageInterviewId: string): AppThunk =>
  async (dispatch) => {
    dispatch(updateJobStageInterview({ id: jobStageInterviewId, isHiddenFromCandidate: false }));
  };
