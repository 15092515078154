export const QUERY_KEY = 'integration';

export interface CoderPadIntegration {
  api_key: string;
}

export interface CodeSignalIntegration {
  api_key: string;
}

export interface GoogleIntegration {
  admin_user_id: string;
}

export interface MicrosoftIntegration {
  active: boolean;
}

export interface MicrosoftGccHighIntegration {
  active: boolean;
}

export interface AshbyIntegration {
  api_key?: string;
  webhook_request_url?: string;
  webhook_secret?: string;
}

export interface GreenhouseIntegration {
  api_key?: string;
  subdomain?: string;
  webhook_secret?: string;
}

export interface LeverIntegration {
  active: boolean;
}

export interface GemIntegration {
  api_key?: string;
}

export interface WorkdayIntegration {
  web_service_url: string;
  ui_url: string;
  domain: string;
  tenant: string;
  password: string;
  username: string;
  interview_raas_url: string;
  job_application_raas_url: string;
}

export interface ZoomIntegration {
  active: boolean;
}

export interface SlackOrgIntegration {
  active: boolean;
  slackTeamId: string;
}

export interface HackerRankIntegration {
  api_key: string;
}

export interface CodilityIntegration {
  api_key: string;
}
