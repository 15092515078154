import React from 'react';

import { gql } from '@apollo/client';

import { JobStageSelect_JobFragment } from 'src/generated/mloop-graphql';

import SelectMenu from 'src/components/SelectMenu';
import { FCWithFragments } from 'src/components/types';

type Fragments = {
  job: JobStageSelect_JobFragment;
};

type Props = {
  selectedJobStageId: string;
  applicationJobStageId: string;
  onChange: (selectedJobStageId: string) => void;
};

const JobStageSelect: FCWithFragments<Fragments, Props> = ({
  job,
  selectedJobStageId,
  applicationJobStageId,
  onChange,
}) => {
  const jobStageOptions = (job?.jobStages || []).map((jobStage) => ({
    id: jobStage.id,
    value: jobStage.name,
    caption: jobStage.id === applicationJobStageId ? 'Current stage' : undefined,
  }));

  const handleSelect = (option) => {
    if (!option) return;
    onChange(option.id);
  };

  const selectedOption = jobStageOptions.find((option) => option.id === selectedJobStageId);
  return (
    <SelectMenu
      size="medium"
      selectedOption={selectedOption}
      onSelect={handleSelect}
      options={jobStageOptions}
      placeholder="Select stage"
    />
  );
};

JobStageSelect.fragments = {
  job: gql`
    fragment JobStageSelect_job on Job {
      id
      jobStages(input: { includeCustom: false }) {
        id
        name
      }
    }
  `,
};

export default JobStageSelect;
