import {
  EmployeesQuery,
  BaseEditInterviewSeatModuleInterviewModuleSearchFragment as InterviewModuleAutoCompleteOption,
} from 'src/generated/mloop-graphql';

import { JobStageInterview } from 'src/store/slices/job-stage-interview';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import { ArrayElement, ElementType } from 'src/types/utils';

export enum AutoCompleteOptionType {
  EMPLOYEE = 'employee',
  INTERVIEW = 'interview',
  SEAT = 'seat',
}

export type AutoCompleteOption = {
  type: AutoCompleteOptionType;
  employee?: ArrayElement<ElementType<EmployeesQuery['employees']>['items']>;
  interview?: InterviewModuleAutoCompleteOption;

  seat?: JobStageInterviewSeat;
  // `linkableInterview` will be added for a seat for ease of access while displaying UI.
  linkableInterview?: JobStageInterview;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type BaseInterviewSeatModuleProps = {
  jobStageInterviewId: string;
  jobStageInterviewSeatId: string;
  seatIndex: number;
  onUpdated: () => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type BaseReadInterviewSeatModuleProps = Omit<BaseInterviewSeatModuleProps, 'jobStageInterviewSeatId'> & {
  jobStageInterviewSeat: JobStageInterviewSeat;
  linkableInterviews: JobStageInterview[];
  onEdit: () => void;
  onDelete: () => void;
  onInterviewerPreferenceChanged: (employeeId: string, preferenceLevel: number) => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type BaseEditInterviewSeatModuleProps = Omit<BaseInterviewSeatModuleProps, 'jobStageInterviewSeatId'> & {
  jobStageInterviewSeat: JobStageInterviewSeat;
  linkableInterviews: JobStageInterview[];
  onEditComplete: (seats: AutoCompleteOption[]) => void;
  onDelete: () => void;
};
