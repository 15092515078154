/* eslint-disable react/no-multi-comp */
import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router';

import { useFlag } from '@modernloop/shared/feature-flag';

import { Routes } from 'src/constants/routes';

import useIsNewOrgExperience from 'src/hooks/useIsNewOrgExperience';

import { OrganizationSettingsTab, getOrganizationSettingUrls } from 'src/urls/getOrganizationSettingUrls';

interface Props {
  children?: ReactNode;
}

/**
 * Guard that checks if the org has been onboarded. Aka - org has connected the directory.
 * Note: Guards should check only one thing. Don't add any other checks to this Guard.
 */
const RPOrgHasConnectedDirectoryGuard: FC<Props> = ({ children }) => {
  const isNewOrgExperience = useIsNewOrgExperience();

  const newOrgSettingsSidebar = useFlag('user_new_org_settings_sidebar');

  const integrationsUrl = newOrgSettingsSidebar
    ? getOrganizationSettingUrls({ tab: OrganizationSettingsTab.INTEGRATIONS })
    : Routes.SettingsIntegrations;

  if (isNewOrgExperience) {
    return <Redirect to={integrationsUrl} />;
  }

  return <>{children}</>;
};

export default RPOrgHasConnectedDirectoryGuard;
