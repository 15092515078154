import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskClosedDueToStageChange_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import ActivityLogText from 'src/entities/ActivityLog/Helpers/ActivityLogText';
import AtsName from 'src/entities/Ats/AtsName';

interface Fragments {
  activityLog: ActivityLogTaskClosedDueToStageChange_ActivityLogFragment;
}

const ActivityLogTaskClosedDueToStageChange: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (!details || details.__typename !== 'ActivityLogTaskDetails') {
    throw new Error('ActivityLogTaskCreated: details is not ActivityLogTaskDetails');
  }

  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={
        <ActivityLogText>
          canceled this unscheduled task because a stage was changed in <AtsName />
        </ActivityLogText>
      }
    />
  );
};

ActivityLogTaskClosedDueToStageChange.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskClosedDueToStageChange_activityLog on ActivityLog {
      id
      ...ActivityLogBase_activityLog
      details {
        ... on ActivityLogTaskDetails {
          __typename
        }
      }
    }
  `,
};

export default ActivityLogTaskClosedDueToStageChange;
