import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CircleWithCheckIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.25C5.71979 2.25 2.25 5.71981 2.25001 10C2.25001 14.2802 5.7198 17.75 10 17.75C14.2802 17.75 17.75 14.2802 17.75 9.99999C17.75 5.71979 14.2802 2.25 10 2.25Z"
        fill="currentColor"
      />
      <path
        d="M5.5 10.0667C6.75237 11.0477 7.40461 11.6817 8.45652 13C10.5772 10.4292 11.7907 9.22231 14 7.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
