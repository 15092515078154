import React, { FC } from 'react';

import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps } from '@mui/material';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type DrawProps = {
  anchor?: MuiDrawerProps['anchor'];
  open?: MuiDrawerProps['open'];
  hideBackdrop?: MuiDrawerProps['hideBackdrop'];
  variant?: MuiDrawerProps['variant'];
  onClose?: MuiDrawerProps['onClose'];

  width?: number;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const Drawer: FC<DrawProps> = ({ children, anchor, open, hideBackdrop, variant, width, onClose }) => {
  return (
    <MuiDrawer
      anchor={anchor || 'right'}
      open={open}
      hideBackdrop={hideBackdrop}
      variant={variant}
      PaperProps={{
        sx: {
          width: width || 644,
          top: (theme) => theme.spacing(1.5),
          right: (theme) => theme.spacing(1.5),
          borderRadius: '12px',
          height: 'calc(100% - 24px)',
        },
      }}
      onClose={onClose}
    >
      {children}
    </MuiDrawer>
  );
};

export default Drawer;
