import React, { useMemo, useRef } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useDebouncedCallback } from 'use-debounce';

import { DateTimeRangeInput } from 'src/generated/mloop-graphql';

import { CanDisable } from 'src/components/HelperComponents';
import IconButton from 'src/components/IconButton';
import Paper from 'src/components/Paper';
import TextField from 'src/components/TextField';
import OrderLockIcon from 'src/components/icons/OrderLock';
import OrderShuffleIcon from 'src/components/icons/OrderShuffle';

import { updateJobStageInterviewGroup } from 'src/store/actions/job-stage-interview-group';
import { getJobStageInterviewGroupById } from 'src/store/selectors/job-stage-interview-group';

import { Theme } from 'src/theme/type';

import { DEBOUNCE_TIMEOUT } from 'src/constants';
import { useDispatch, useSelector } from 'src/store';

import InterviewGroupMenu from './InterviewGroupMenu';
import JobStageInterview from './JobStageInterview';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type InterviewGroupProps = {
  jobStageId: string;
  id: string;
  count: number;
  schedulable: boolean;
  readonly?: boolean;
  candidateAvailabilities?: DateTimeRangeInput[];
  onUpdated: () => void;

  // For Draggable
  index: number;
};

const useStyle = makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      paddingLeft: '18px',
      paddingRight: '8px',
    },
    textField: {
      borderColor: theme.palette.common.transparent,

      '&:hover': {
        borderColor: theme.grey.alpha.low,
      },

      '&:focus-within': {
        borderColor: theme.palette.action.focus,
      },
    },
    groupedSlotDivider: {
      height: '8px',
      width: '100%',
    },
  });
});

const useSxProps = () => {
  return useMemo(() => {
    return {
      paper: {
        padding: '8px',
      },
    };
  }, []);
};

const InterviewGroup = ({
  jobStageId,
  id: groupId,
  count,
  index,
  schedulable,
  readonly,
  candidateAvailabilities,
  onUpdated,
}: InterviewGroupProps): JSX.Element => {
  const classes = useStyle();
  const sxProps = useSxProps();
  const { locked, jobStageInterviewIds, name } = useSelector((state) => getJobStageInterviewGroupById(state, groupId));
  const dispatch = useDispatch();
  const interviewGroupNameRef = useRef<HTMLInputElement>(null);

  const debouncedOnUpdated = useDebouncedCallback(onUpdated, DEBOUNCE_TIMEOUT);

  const handleNameInputOnBlur = () => {
    if (!name) {
      dispatch(updateJobStageInterviewGroup({ id: groupId, name: 'Group' }));
      onUpdated();
    }
  };

  const handleInterviewGroupNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(updateJobStageInterviewGroup({ id: groupId, name: event.target.value }));
    debouncedOnUpdated();
  };

  const handlePlanOrderClick = () => {
    dispatch(updateJobStageInterviewGroup({ id: groupId, locked: !locked }));
    onUpdated();
  };

  return (
    <Draggable key={groupId} draggableId={groupId} index={index} isDragDisabled={readonly}>
      {(draggableProvided: DraggableProvided) => {
        const header =
          jobStageInterviewIds.length > 1 ? (
            <CanDisable disabled={Boolean(readonly)}>
              <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
                <Grid item style={{ flexGrow: 1, marginRight: '8px' }}>
                  <Grid container spacing={2} alignItems="center">
                    <IconButton onClick={handlePlanOrderClick}>
                      {locked && <OrderLockIcon tooltip="Locked position" />}
                      {!locked && <OrderShuffleIcon tooltip="Shuffle position" />}
                    </IconButton>
                    <Grid item style={{ flexGrow: 1 }}>
                      <TextField
                        className={classes.textField}
                        defaultValue={name}
                        fullWidth
                        InputProps={{ style: { fontWeight: 600, width: '100%', flex: 1 } }}
                        onChange={handleInterviewGroupNameChange}
                        placeholder="Group"
                        inputRef={interviewGroupNameRef}
                        onBlur={handleNameInputOnBlur}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <InterviewGroupMenu
                    jobStageId={jobStageId}
                    id={groupId}
                    count={count}
                    index={index}
                    onUpdated={onUpdated}
                    groupNameInputRef={interviewGroupNameRef}
                  />
                </Grid>
              </Grid>
            </CanDisable>
          ) : null;

        const slots = (
          <>
            {jobStageInterviewIds.map((slotId, slotIndex) => {
              return (
                <React.Fragment key={slotId}>
                  {jobStageInterviewIds.length > 1 && <div className={classes.groupedSlotDivider} />}
                  <JobStageInterview
                    key={slotId}
                    jobStageInterviewId={slotId}
                    groupId={groupId}
                    index={slotIndex}
                    schedulable={schedulable}
                    readonly={readonly}
                    candidateAvailabilities={candidateAvailabilities}
                    onUpdated={onUpdated}
                  />
                </React.Fragment>
              );
            })}
          </>
        );

        return (
          <Grid
            item
            container
            direction="column"
            wrap="nowrap"
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            {jobStageInterviewIds.length > 1 && (
              <Paper sx={sxProps.paper}>
                {header}
                {slots}
              </Paper>
            )}
            {jobStageInterviewIds.length === 1 && slots}
          </Grid>
        );
      }}
    </Draggable>
  );
};

export default InterviewGroup;
