import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ScheduleFilledIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.25C7.41422 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48121 17.75 5.99999L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H6.25V2C6.25 1.58579 6.58579 1.25 7 1.25ZM6 10C6.55228 10 7 9.55228 7 9C7 8.44772 6.55228 8 6 8C5.44772 8 5 8.44772 5 9C5 9.55228 5.44772 10 6 10ZM7 13C7 13.5523 6.55228 14 6 14C5.44772 14 5 13.5523 5 13C5 12.4477 5.44772 12 6 12C6.55228 12 7 12.4477 7 13ZM9 8.25C8.58579 8.25 8.25 8.58579 8.25 9C8.25 9.41421 8.58579 9.75 9 9.75H14C14.4142 9.75 14.75 9.41421 14.75 9C14.75 8.58579 14.4142 8.25 14 8.25H9ZM8.25 13C8.25 12.5858 8.58579 12.25 9 12.25H14C14.4142 12.25 14.75 12.5858 14.75 13C14.75 13.4142 14.4142 13.75 14 13.75H9C8.58579 13.75 8.25 13.4142 8.25 13Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
