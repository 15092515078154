import React, { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments, ZeroState } from '@modernloop/shared/components';
import { Alert, AlertTitle, Box, Button, Stack } from '@mui/material';

import {
  InterviewModuleMemberSearchOrderBy,
  InterviewModuleMemberSearchOrderByProperty,
  InterviewModuleMemberState,
  MembersList_ModuleFragment,
  Sort,
  TrainingStatus,
  useInterviewModuleMembersListQuery,
} from 'src/generated/mloop-graphql';

import Filters from 'src/views-new/Common/InterviewModuleMember/Filters';

import { PAGER_DEFAULT } from 'src/constants';

import AddMembersModal from './AddMembersModal';
import Members from './Members';

export const InterviewModuleMembersList = gql`
  ${Members.fragments.members}
  query InterviewModuleMembersList(
    $id: uuid!
    $pageInput: PageInput!
    $orderBy: InterviewModuleMemberSearchOrderBy!
    $search: String
    $filterByTrainingStatus: [TrainingStatus]
    $filterByState: [InterviewModuleMemberState]
  ) {
    interviewModuleMemberSearch(
      input: {
        interviewModuleId: $id
        pageInput: $pageInput
        orderBy: $orderBy
        search: $search
        filterByTrainingStatus: $filterByTrainingStatus
        filterByState: $filterByState
      }
    ) {
      total
      items {
        ...Members_members
      }
    }
  }
`;

type Fragments = {
  module: MembersList_ModuleFragment;
};

const INITIAL_PAGE = 1;

const MembersList: FCWithFragments<Fragments> = ({ module }) => {
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
  const [showAddMemberModal, setShowAddMemberModal] = useState<boolean>(false);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const [search, setSearch] = useState<string>('');
  const handleSearch = (value: string) => {
    setCurrentPage(INITIAL_PAGE);
    setSearch(value.toLowerCase());
  };

  const initialValueOfOrderBy = {
    property: InterviewModuleMemberSearchOrderByProperty.Name,
    direction: Sort.Asc,
  };

  const initialRoleFilterValue = [];
  const [rolesFilter, setRolesFilter] = useState<TrainingStatus[]>(initialRoleFilterValue);
  const handelRolesFilter = (roles: TrainingStatus[]) => {
    setCurrentPage(INITIAL_PAGE);
    setRolesFilter(roles);
  };

  const [statusFilter, setStatusFilter] = useState<InterviewModuleMemberState[]>([]);
  const handelStatusFilter = (status: InterviewModuleMemberState[]) => {
    setCurrentPage(INITIAL_PAGE);
    setStatusFilter(status);
  };

  const [orderBy, setOrderBy] = useState<InterviewModuleMemberSearchOrderBy>(initialValueOfOrderBy);

  const handleOderBy = (property: InterviewModuleMemberSearchOrderByProperty) => {
    setOrderBy({
      property,
      // eslint-disable-next-line no-nested-ternary
      direction: property === orderBy.property ? (Sort.Desc === orderBy.direction ? Sort.Asc : Sort.Desc) : Sort.Asc,
    });
  };

  const { data, loading, error, refetch } = useInterviewModuleMembersListQuery({
    variables: {
      id: module.id,
      pageInput: {
        limit: PAGER_DEFAULT,
        offset: PAGER_DEFAULT * (currentPage - 1),
      },
      orderBy,
      search,
      filterByTrainingStatus: rolesFilter,
      filterByState: statusFilter,
    },
  });

  /** Refetch on every mount */
  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch]);

  const getMarkup = () => {
    if (!module.membersCount) {
      return (
        <Box pt={4} alignItems="center" display="flex" flexDirection="column">
          <ZeroState
            icon={<img src="/static/images/interviewModule/zero_state_member_list.svg" alt="Checklist" />}
            label="It’s a bit lonely in here… There aren’t any interviewers in this module yet!"
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setShowAddMemberModal(true);
            }}
            title="Add interviewer"
          >
            Add interviewers
          </Button>
        </Box>
      );
    }

    return (
      <Stack direction="column" spacing={2.5}>
        {!module.membersTrainedCount && (
          <Alert severity="error" variant="filled" color="error">
            <AlertTitle>No trained interviewers</AlertTitle>
          </Alert>
        )}
        <Filters onRolesFilter={handelRolesFilter} onStatusFilter={handelStatusFilter} onSearch={handleSearch} />
        <Members
          members={data?.interviewModuleMemberSearch?.items || []}
          total={data?.interviewModuleMemberSearch?.total || 0}
          currentPage={currentPage}
          onPagination={handlePageChange}
          direction={orderBy.direction}
          orderBy={orderBy.property}
          onSort={handleOderBy}
          loading={loading}
          error={error}
        />
      </Stack>
    );
  };

  return (
    <Stack direction="column" spacing={2}>
      {getMarkup()}
      {showAddMemberModal && (
        <AddMembersModal
          open={showAddMemberModal}
          onClose={() => {
            setShowAddMemberModal(false);
          }}
          module={module}
        />
      )}
    </Stack>
  );
};

MembersList.fragments = {
  module: gql`
    ${AddMembersModal.fragments.module}
    fragment MembersList_module on InterviewModule {
      id
      membersCount
      membersTrainedCount
      ...AddMembersModal_module
    }
  `,
};

export default MembersList;
