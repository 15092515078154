import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowLeftIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;

  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49827 4.93945C8.80786 5.21464 8.83575 5.68869 8.56056 5.99828L5.67013 9.25001L16.5 9.25001C16.9142 9.25001 17.25 9.58579 17.25 10C17.25 10.4142 16.9142 10.75 16.5 10.75L5.67013 10.75L8.56056 14.0017C8.83575 14.3113 8.80786 14.7854 8.49827 15.0606C8.18869 15.3358 7.71463 15.3079 7.43944 14.9983L3.43944 10.4983C3.18685 10.2141 3.18685 9.7859 3.43944 9.50174L7.43944 5.00174C7.71463 4.69215 8.18869 4.66426 8.49827 4.93945Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
