// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { ThemeOptions } from '@material-ui/core';

import { grey, palette } from './colors';
import { shadows } from './shadows';
import { typography } from './typography';

const options: ThemeOptions = {
  typography,
  shadows,
  grey,
  palette,
};

export default options;
