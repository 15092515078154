import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';

import AtsSevice from './AtsService';

/* *
 * Workday ATS service
 * Imp: Never have atsType set in ATS service
 * */
class WorkdayAtsService implements AtsSevice {
  readonly name = 'Workday';

  readonly logoUrl = `/static/images/integrations/workday.png`;

  readonly helpCenterUrl = ZenDeskHelpCenterUrl.WORKDAY_INTEGRATION_WEB_SERVICES;

  readonly scorecardLabelPlural = 'questionnaires';

  readonly isSourcingLinkEnabled = true;

  readonly isAtsUserSelectEnabled = false;

  readonly isAtsImportAvailabilityEnabled = false;

  readonly skipInterviewKitLinkTokenValidation = false;

  readonly scorecardGroupByKey = null;

  readonly scorecardSortKey = null;

  readonly showEstimatedMinutes = false;

  readonly createCandidatePermissionDialog = null;

  readonly showRequisitionId = false;
}

export default new WorkdayAtsService();
