import {
  BaseEditInterviewSeatModule_LinkedInterviewFragment,
  BaseReadInterviewSeatModule_SeatFragment,
  EmployeesQuery,
  BaseEditInterviewSeatModuleInterviewModuleSearchV2Fragment as InterviewModuleAutoCompleteOption,
} from 'src/generated/mloop-graphql';

import { ArrayElement, ElementType } from 'src/types/utils';

export enum AutoCompleteOptionType {
  EMPLOYEE = 'employee',
  INTERVIEW = 'interview',
  SEAT = 'seat',
}

export type AutoCompleteOption = {
  type: AutoCompleteOptionType;
  employee?: ArrayElement<ElementType<EmployeesQuery['employees']>['items']>;
  interview?: InterviewModuleAutoCompleteOption;

  originalSeatId?: string;
  // `linkableInterview` will be added for a seat for ease of access while displaying UI.
  linkableInterview?: BaseEditInterviewSeatModule_LinkedInterviewFragment;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type BaseInterviewSeatModuleProps = {
  jobStageInterviewId: string;
  seatIndex: number;
  onUpdated: (seat: BaseReadInterviewSeatModule_SeatFragment) => void;
  onDelete: (seatId: string) => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type BaseReadInterviewSeatModuleProps = Omit<BaseInterviewSeatModuleProps, 'jobStageInterviewSeatId'> & {
  onEdit: () => void;
  onDelete: () => void;
  onInterviewerPreferenceChanged: (employeeId: string, preferenceLevel: number) => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type BaseEditInterviewSeatModuleProps = Omit<BaseInterviewSeatModuleProps, 'jobStageInterviewSeatId'> & {
  originalSeatId?: string;
  onEditComplete: (seats: AutoCompleteOption[]) => void;
  onDelete: () => void;
};
