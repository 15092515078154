import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';

import { AddMemberModal_SelectedEmployeesFragment } from 'src/generated/mloop-graphql';

import EmployeePicker from 'src/entities/EmployeePicker';
import { EmployeeFragment } from 'src/entities/EmployeePicker/EmployeeGraphQL';

type Props = {
  open: boolean;
  onClose: (open: boolean) => void;
  onSave: (selectedEmployees: AddMemberModal_SelectedEmployeesFragment[]) => void;
};

type Fragments = {
  selectedEmployees: AddMemberModal_SelectedEmployeesFragment[];
};

const AddMemberModal: FCWithFragments<Fragments, Props> = ({ onSave, open, onClose, selectedEmployees }) => {
  const [employees, setEmployees] = useState<AddMemberModal_SelectedEmployeesFragment[]>(selectedEmployees || []);

  const onChnage = (values: AddMemberModal_SelectedEmployeesFragment[]) => {
    setEmployees(values);
  };

  const handleSave = () => {
    onSave(employees);
    onClose(false);
  };
  return (
    <Dialog
      title="Add members to the queue"
      open={open}
      onClose={() => onClose(false)}
      submitOptions={{
        label: 'Add members',
        onClick: handleSave,
      }}
      cancelOptions={{
        label: 'Cancel',
        onClick: () => onClose(false),
      }}
    >
      <EmployeePicker
        selectedEmployees={employees}
        onChange={(values) => {
          onChnage(values);
        }}
        placeholder="Search members"
      />
    </Dialog>
  );
};

AddMemberModal.fragments = {
  selectedEmployees: gql`
    ${EmployeeFragment}
    fragment AddMemberModal_selectedEmployees on Employee {
      id
      ...Employee
    }
  `,
};

export default AddMemberModal;
