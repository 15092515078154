import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskCandidateAvailabilityDeleted_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';

interface Fragments {
  activityLog: ActivityLogTaskCandidateAvailabilityDeleted_ActivityLogFragment;
}

const ActivityLogTaskCandidateAvailabilityDeleted: FCWithFragments<Fragments> = ({ activityLog }) => {
  return <ActivityLogBase activityLog={activityLog} actionLabel="deleted availability" />;
};

ActivityLogTaskCandidateAvailabilityDeleted.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskCandidateAvailabilityDeleted_activityLog on ActivityLog {
      id
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskCandidateAvailabilityDeleted;
