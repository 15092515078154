import React, { useState } from 'react';

import { gql, useApolloClient } from '@apollo/client';
import { Dialog } from '@modernloop/shared/components';
import { Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { CancelScheduleApplicationStageFragment } from 'src/generated/mloop-graphql';

import { FCWithFragment } from 'src/components/types';

import AtsName from 'src/entities/Ats/AtsName';

import useDirectoryInfo from 'src/hooks/useDirectoryInfo';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import cancelSchedule, { DeleteType } from 'src/utils/api/cancelSchedule';
import { apolloCacheDeleteByQuery } from 'src/utils/apolloHelpers';
import { getExternalErrorMessage } from 'src/utils/error';

import { isCompletedEvent, isEventInProgress, isUpcomingEvent } from 'src/views-new/CandidateDetails/utils';

interface Props {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/component-with-fragments.cjs
  applicationStage: CancelScheduleApplicationStageFragment;
  open: boolean;
  onSuccess: () => void;
  onError: () => void;
  onClose: () => void;
  selfScheduleOptionsId?: string;
  applicationId?: string;
}

const CancelSchedule: FCWithFragment<Props> = ({
  applicationStage,
  open,
  onSuccess,
  onError,
  onClose,
  selfScheduleOptionsId,
  applicationId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const directoryInfo = useDirectoryInfo();

  const client = useApolloClient();

  const getInitialDeleteType = () => {
    if (isUpcomingEvent(applicationStage?.minStartAt)) {
      return DeleteType.UPCOMING;
    }
    if (isCompletedEvent(applicationStage?.maxEndAt)) {
      return DeleteType.PAST;
    }
    const isUpcomingInterview = applicationStage?.cancelScheduleApplicationStageInterviews?.find(
      (interview) => !!isUpcomingEvent(interview?.startAt)
    );
    if (!isUpcomingInterview) {
      return DeleteType.ALL;
    }
    return DeleteType.UPCOMING;
  };

  const [deleteType, setDeleteType] = useState<DeleteType>(getInitialDeleteType());
  const [canceling, setCanceling] = useState<boolean>(false);

  const handleDeleteTypeChange = (event: React.SyntheticEvent, value: DeleteType) => {
    setDeleteType(value);
  };

  const getBodyCommonContent = (): JSX.Element => {
    return (
      <Stack direction="column" spacing={2}>
        <Typography>
          All records of selected interviews in <AtsName /> will be deleted, including any related scorecards that have
          been submitted.
        </Typography>
        <Typography>
          Events in {directoryInfo.label} Calendar for your candidate and interviewers will be deleted.
        </Typography>
      </Stack>
    );
  };

  const getBody = (): JSX.Element => {
    if (isEventInProgress(applicationStage?.minStartAt, applicationStage?.maxEndAt)) {
      const isUpcomingInterview = applicationStage?.cancelScheduleApplicationStageInterviews?.find(
        (interview) => !!isUpcomingEvent(interview?.startAt)
      );

      return (
        <Stack direction="column" spacing={2}>
          {isUpcomingInterview && (
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="deleteType"
                name="deleteType"
                value={deleteType}
                onChange={handleDeleteTypeChange}
              >
                <FormControlLabel
                  value={DeleteType.ALL}
                  control={<Radio color="primary" />}
                  label="All interviews in this schedule"
                />
                <FormControlLabel
                  value={DeleteType.UPCOMING}
                  control={<Radio color="primary" />}
                  label="Upcoming interviews only"
                />
              </RadioGroup>
            </FormControl>
          )}

          {isUpcomingInterview && <Divider />}

          {getBodyCommonContent()}
        </Stack>
      );
    }

    return getBodyCommonContent();
  };

  const doCancel = async () => {
    setCanceling(true);
    cancelSchedule({ applicationStageId: applicationStage?.id, deleteType })
      .then(() => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line promise/always-return
        if (selfScheduleOptionsId) {
          apolloCacheDeleteByQuery(client.cache, 'selfScheduleOptions', {
            id: selfScheduleOptionsId,
          });
          if (applicationId) {
            apolloCacheDeleteByQuery(client.cache, 'selfScheduleOptionsForApplicationJobStage', {
              input: {
                applicationId,
                jobStageId: applicationStage?.jobStageId,
              },
            });
          }
        }
        onSuccess();
        enqueueSnackbar(`Schedule marked as canceled`, {
          variant: 'success',
        });
      })
      .catch((error) => {
        onError();
        const errorMsg = getExternalErrorMessage(error) || `Failed to mark schedule as canceled`;
        enqueueSnackbar(errorMsg, {
          variant: 'error',
        });
      });
  };

  return (
    <ConditionalThemeProvider>
      <Dialog
        title="Mark as canceled?"
        subTitle="This cannot be undone."
        open={open}
        onClose={onClose}
        submitOptions={{
          label: 'Mark as canceled',
          isDisabled: canceling,
          isLoading: canceling,
          isDangerous: true,
          onClick: doCancel,
        }}
        cancelOptions={{
          label: 'Keep schedule',
          onClick: onClose,
        }}
      >
        {getBody()}
      </Dialog>
    </ConditionalThemeProvider>
  );
};

CancelSchedule.fragment = {
  applicationStage: gql`
    fragment CancelScheduleApplicationStage on ApplicationStage {
      id
      minStartAt
      maxEndAt
      jobStageId
      cancelScheduleApplicationStageInterviews: applicationStageInterviews {
        id
        startAt
      }
    }
  `,
};

export default CancelSchedule;
