import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { GreenhouseHealthCheckBanner_GreenhouseFragment } from 'src/generated/mloop-graphql';

import AppWideBanner, { AppWideBannerAction } from '../AppWideBanner';

import { integrationUnhealthyMessage } from './constants';

interface Fragments {
  greenhouse: GreenhouseHealthCheckBanner_GreenhouseFragment;
}

interface Props {
  retry: AppWideBannerAction;
}

const GreenhouseHealthCheckBanner: FCWithFragments<Fragments, Props> = ({ greenhouse, retry }) => {
  // hide if its not installed or is healthy
  if (!greenhouse || !greenhouse?.isInstalled || greenhouse?.isHealthy) {
    return null;
  }

  const endpoints = [
    !greenhouse.canReadUsers && 'list users',
    !greenhouse.canReadUser && 'retrieve user',
    !greenhouse.canReadJobs && 'list jobs',
    !greenhouse.canReadJob && 'retrieve job',
    !greenhouse.canReadJobStages && 'list job stages',
    !greenhouse.canReadJobStageForJob && 'list job stage for job',
    !greenhouse.canReadJobPostings && 'list job postings',
    !greenhouse.canReadUserRoles && 'list user roles',
    !greenhouse.canReadEmailTemplates && 'list email templates',
    !greenhouse.canReadInterviews && 'list interviews',
    !greenhouse.canReadInterview && 'retrieve interview',
    !greenhouse.canReadCandidates && 'list candidates',
    !greenhouse.canReadCandidate && 'retrieve candidate',
    !greenhouse.canReadActivityFeed && 'retreive activity feed',
    !greenhouse.canReadApplications && 'list applications',
    !greenhouse.canReadApplication && 'retrieve application',
    !greenhouse.canReadUserPermission && 'list user permission',
  ].filter(Boolean) as string[];

  return (
    <AppWideBanner
      color="foreground"
      backgroundColor="error"
      text={integrationUnhealthyMessage('Greenhouse', endpoints)}
      action={retry}
    />
  );
};

GreenhouseHealthCheckBanner.fragments = {
  greenhouse: gql`
    fragment GreenhouseHealthCheckBanner_greenhouse on GreenhouseIntegrationHealth {
      isHealthy
      isInstalled
      canReadUsers
      canReadUser
      canReadJobs
      canReadJob
      canReadJobStages
      canReadJobStageForJob
      canReadJobPostings
      canReadUserRoles
      canReadEmailTemplates
      canReadInterviews
      canReadInterview
      canReadCandidates
      canReadCandidate
      canReadActivityFeed
      canReadApplications
      canReadApplication
      canReadUserPermission
    }
  `,
};

export default GreenhouseHealthCheckBanner;
