import React from 'react';

import Dialog from 'src/components/Dialog';
import Label from 'src/components/label';

type Props = {
  onClose: () => void;
};

const SelfScheduleRequestedAlert = ({ onClose }: Props) => {
  return (
    <Dialog isOpen onClose={onClose} title="Availability request pending">
      <Label fontWeight={400}>
        There is a self-schedule request already out to this candidate. To request availability, please close your
        self-schedule.
      </Label>
    </Dialog>
  );
};

export default SelfScheduleRequestedAlert;
