import useAuth from '../useAuth';

import { DefaultReturnType } from './constants';
import { ReturnType } from './types';
import useGmailGrantAccess from './useGmailGrantAccess';
import useMicrosoftGccHighGrantAccess from './useMicrosoftGccHighGrantAccess';
import useOutlookGrantAccess from './useOutlookGrantAccess';
import useTermsAndPrivacyRequirements from './useTermsAndPrivacyRequirements';

/**
 * Hook to check if terms and service & outlook requirements are met, if yes return redirect params to naviagte to respective page
 * Exported by default and only hook to be consumed
 */
const useOnboardingRequirements = (): ReturnType => {
  const {
    isOnboardingRequired: isTermAndPrivacyOnboardingRedirect,
    onboardingRedirectParams: termAndPrivacyonboardingRedirectParams,
  } = useTermsAndPrivacyRequirements();

  const { isOnboardingRequired: isOutlookGrantAccessRedirect, onboardingRedirectParams: outlookGrantAccessParams } =
    useOutlookGrantAccess();

  const { isOnboardingRequired: isGmailOnboardingRequired, onboardingRedirectParams: gmailGrantAccessParams } =
    useGmailGrantAccess();

  const {
    isOnboardingRequired: isMicrosoftGccHighOnboardingRequired,
    onboardingRedirectParams: microsoftGccHighGrantAccessParams,
  } = useMicrosoftGccHighGrantAccess();

  const { userInfo } = useAuth();

  if (userInfo?.impersonatingUserInfo) {
    return DefaultReturnType;
  }

  if (isTermAndPrivacyOnboardingRedirect) {
    return {
      isOnboardingRequired: isTermAndPrivacyOnboardingRedirect,
      onboardingRedirectParams: termAndPrivacyonboardingRedirectParams,
    };
  }

  if (isOutlookGrantAccessRedirect) {
    return {
      isOnboardingRequired: isOutlookGrantAccessRedirect,
      onboardingRedirectParams: outlookGrantAccessParams,
    };
  }

  if (isGmailOnboardingRequired) {
    return {
      isOnboardingRequired: isGmailOnboardingRequired,
      onboardingRedirectParams: gmailGrantAccessParams,
    };
  }

  if (isMicrosoftGccHighOnboardingRequired) {
    return {
      isOnboardingRequired: isMicrosoftGccHighOnboardingRequired,
      onboardingRedirectParams: microsoftGccHighGrantAccessParams,
    };
  }

  return DefaultReturnType;
};

export default useOnboardingRequirements;
