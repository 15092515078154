import { ApolloClient, ApolloQueryResult, gql } from '@apollo/client';

import { InterviewTokensQuery, InterviewTokensQueryVariables } from 'src/generated/mloop-graphql';

/** *
 * Interview placeholder filler is responsible for fetching all the tokens related to interview
 * NOTE: VIDEO_MEETING_LINK , ZOOM_MEETING_DIAL_IN_INFO  & ZOOM_MEETING_INFO are only fetched when multiple link
 */

export const InterviewPlaceholderFillerQuery = gql`
  query InterviewTokens(
    $id: String
    $uuid: uuid!
    $renderType: RenderType!
    $name: TokenInterviewNameInput!
    $names: TokenInterviewerNamesInput!
    $scheduleNames: TokenInterviewerNamesInput!
    $days: TokenInterviewDaysInput!
    $schedule: TokenInterviewScheduleInput!
    $scheduleStartTime: TokenInterviewScheduleStartTimeInput!
    $internalInterviewSchedule: TokenInterviewScheduleInput!
    $kitLink: TokenInterviewKitLinkInput!
    $codeLink: CodeLinkInput!
    $hasScorecard: Boolean!
    $videoMeetingLink: TokenVideoMeetingLinkInput!
    $zoom: TokenZoomMeetingInput!
    $trainingInfo: TokenInterviewerTrainingInfoInput!
    $hasName: Boolean!
    $hasNames: Boolean!
    $hasDays: Boolean!
    $hasSchedule: Boolean!
    $hasScheduleStartTime: Boolean!
    $hasInternalInterviewSchedule: Boolean!
    $hasCodeLink: Boolean!
    $hasVideoMeetingLink: Boolean!
    $hasZoom: Boolean!
    $atsInterviewDefinitionId: String
  ) {
    INTERVIEW_NAME: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasName) {
      id
      INTERVIEW_NAME(input: $name)
    }
    INTERVIEWER_NAMES: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasNames) {
      id
      INTERVIEWER_NAMES(input: $names)
    }
    SCHEDULE_INTERVIEWER_NAMES: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasNames) {
      id
      INTERVIEWER_NAMES(input: $scheduleNames)
    }
    INTERVIEW_DAYS: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasDays) {
      id
      INTERVIEW_DAYS(input: $days)
    }
    INTERVIEW_SCHEDULE: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasSchedule) {
      id
      INTERVIEW_SCHEDULE(input: $schedule)
    }
    INTERVIEW_SCHEDULE_START_TIME: templateToken(input: { id: $id, renderType: $renderType })
      @include(if: $hasScheduleStartTime) {
      id
      INTERVIEW_SCHEDULE_START_TIME(input: $scheduleStartTime)
    }
    INTERNAL_INTERVIEW_SCHEDULE: templateToken(input: { id: $id, renderType: $renderType })
      @include(if: $hasInternalInterviewSchedule) {
      id
      INTERNAL_INTERVIEW_SCHEDULE(input: $internalInterviewSchedule)
    }
    INTERVIEW_KIT_LINK: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      INTERVIEW_KIT_LINK(input: $kitLink)
    }
    INTERVIEW_SCORECARD_NAME: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasScorecard) {
      id
      INTERVIEW_SCORECARD_NAME(input: { id: $uuid, atsInterviewDefinitionId: $atsInterviewDefinitionId })
    }
    CODE_LINK: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasCodeLink) {
      id
      CODE_LINK(input: $codeLink)
    }
    VIDEO_MEETING_LINK: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasVideoMeetingLink) {
      id
      VIDEO_MEETING_LINK(input: $videoMeetingLink)
    }
    ZOOM_MEETING_DIAL_IN_INFO: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasZoom) {
      id
      ZOOM_MEETING_DIAL_IN_INFO(input: $zoom)
    }
    ZOOM_MEETING_INFO: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasZoom) {
      id
      ZOOM_MEETING_INFO(input: $zoom)
    }
    INTERVIEWER_TRAINING_INFO: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      INTERVIEWER_TRAINING_INFO(input: $trainingInfo)
    }
  }
`;

/**
 * Retrieves the interview tokens for a given interview event.
 * @param client The Apollo client instance to use for the query.
 * @param variables The variables to pass to the query.
 * @returns A Promise that resolves to the result of the query.
 */
const getRequestResponseForInterviewEvent = async (
  client: ApolloClient<object>,
  variables: InterviewTokensQueryVariables
): Promise<ApolloQueryResult<InterviewTokensQuery>> => {
  return client.query<InterviewTokensQuery>({
    query: InterviewPlaceholderFillerQuery,
    variables,
    errorPolicy: 'all',
  });
};

export default getRequestResponseForInterviewEvent;
