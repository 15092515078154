import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskAssigned_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import ActivityLogBold from 'src/entities/ActivityLog/Helpers/ActivityLogBold';
import ActivityLogText from 'src/entities/ActivityLog/Helpers/ActivityLogText';

import logError from 'src/utils/logError';

interface Fragments {
  activityLog: ActivityLogTaskAssigned_ActivityLogFragment;
}

const ActivityLogTaskAssigned: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;
  let name = 'Unknown';

  // eslint-disable-next-line no-underscore-dangle
  if (details?.__typename === 'ActivityLogTaskAssigneeDetails') {
    name = details.assignee?.fullName || name;
  } else {
    logError('ActivityLogTaskAssigned: details is not ActivityLogTaskAssigneeDetails');
  }

  const label = (
    <ActivityLogText>
      assigned to&nbsp;<ActivityLogBold>{name}</ActivityLogBold>
    </ActivityLogText>
  );

  return <ActivityLogBase activityLog={activityLog} actionLabel={label} />;
};

ActivityLogTaskAssigned.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskAssigned_activityLog on ActivityLog {
      id
      details {
        __typename
        ... on ActivityLogTaskAssigneeDetails {
          assigneeId
          assignee {
            id
            fullName
          }
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskAssigned;
