import { useSetEmployeePrefString } from 'src/hooks/api/employee/set';
import useEmployeeId from 'src/hooks/useEmployeeId';

import { EmployeePrefName } from 'src/utils/api/employee';

export const useCommunicationTemplatesPref = (pref: EmployeePrefName) => {
  const employeeId = useEmployeeId();

  return useSetEmployeePrefString(pref, employeeId || '');
};
