import React from 'react';

import { gql } from '@apollo/client';
import { Loader, PublicError } from '@modernloop/shared/helper-components';
import { DiamondFilled, ReverseShadowIcon, ShadowIcon, TraineePlusIcon } from '@modernloop/shared/icons';
import { logDatadogError } from '@modernloop/shared/utils';
import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  ActionButtons_ModuleMemberFragment,
  TrainingStatus,
  useModuleMemberApproveMutation,
  useModuleMemberUpdateMutation,
} from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import {
  getReverseShadowCompletedCount,
  getReverseShadowPendingCount,
  getShadowCompletedCount,
  isModuleMemberTrained,
  resetModuleMemberListCache,
} from 'src/entities/InterviewModuleMember/utils';

type Props = {
  disableActions: boolean;
};

type Fragments = {
  moduleMember: ActionButtons_ModuleMemberFragment;
};

const ActionButtons: FCWithFragments<Fragments, Props> = ({ moduleMember, disableActions }) => {
  const [updateModuleMember, { loading, error }] = useModuleMemberUpdateMutation();
  const [approveModuleMember, { loading: approveLoading, error: approveError }] = useModuleMemberApproveMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['InterviewModuleMemberModuleLogSidepanel'],
  });
  const { enqueueSnackbar } = useSnackbar();

  const nextRole: TrainingStatus = getReverseShadowPendingCount({ moduleMember }, undefined)
    ? TrainingStatus.ReverseShadow
    : TrainingStatus.Trained;

  const handleAddRequirements = async (roleToadd: TrainingStatus) => {
    try {
      await updateModuleMember({
        variables: {
          input: {
            employeeId: moduleMember.employeeId,
            interviewModuleId: moduleMember.interviewModuleId,
            shadowRequiredCount:
              roleToadd === TrainingStatus.Shadow
                ? moduleMember?.stats?.completedAsShadow + moduleMember?.stats?.manualCompletedAsShadow + 1
                : moduleMember.shadowsRequired,
            reverseShadowRequiredCount:
              roleToadd === TrainingStatus.ReverseShadow
                ? moduleMember?.stats?.completedAsReverseShadow +
                  moduleMember?.stats?.manualCompletedAsReverseShadow +
                  +1
                : moduleMember.reverseShadowsRequired,
          },
        },
        update: (cache) => {
          resetModuleMemberListCache({
            cache,
            employeeIds: [moduleMember.employeeId],
            moduleId: moduleMember.interviewModuleId,
          });
        },
      });
      enqueueSnackbar('Requirements updated', {
        variant: 'success',
      });
    } catch (e) {
      logDatadogError(e);
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const handleApprove = async () => {
    try {
      await approveModuleMember({
        variables: {
          input: {
            interviewModuleMemberId: moduleMember.employeeId,
            interviewModuleId: moduleMember.interviewModuleId,
          },
        },
        update: (cache) => {
          resetModuleMemberListCache({
            cache,
            employeeIds: [moduleMember.employeeId],
            moduleId: moduleMember.interviewModuleId,
          });
        },
      });
      enqueueSnackbar(`Approved for ${moduleMember.status.toLowerCase()} `, {
        variant: 'success',
      });
    } catch (e) {
      logDatadogError(e);
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const isDisabled = loading || approveLoading || disableActions;

  if (moduleMember.status === TrainingStatus.Trained) return null;

  const Icon = nextRole === TrainingStatus.Trained ? DiamondFilled : ReverseShadowIcon;

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" p={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <ShadowIcon />
          <Typography variant="body2">
            {isModuleMemberTrained(moduleMember.status)
              ? `${getShadowCompletedCount({ moduleMember }, undefined)}`
              : `${getShadowCompletedCount({ moduleMember }, undefined)} / ${moduleMember.shadowsRequired}`}{' '}
            shadows
          </Typography>
        </Stack>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => {
            handleAddRequirements(TrainingStatus.Shadow);
          }}
          startIcon={loading ? <Loader loading={loading} size={20} /> : <TraineePlusIcon />}
          disabled={isDisabled}
        >
          Needs another
        </Button>
      </Stack>
      {nextRole === TrainingStatus.Trained && (
        <Stack direction="row" justifyContent="space-between" alignItems="center" px={1} pb={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <ReverseShadowIcon />
            <Typography variant="body2">
              {isModuleMemberTrained(moduleMember.status)
                ? `${getReverseShadowCompletedCount({ moduleMember }, undefined)}`
                : `${getReverseShadowCompletedCount({ moduleMember }, undefined)} / ${
                    moduleMember.reverseShadowsRequired
                  }`}{' '}
              reverse shadows
            </Typography>
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              handleAddRequirements(TrainingStatus.ReverseShadow);
            }}
            startIcon={loading ? <Loader loading={loading} size={20} /> : <TraineePlusIcon />}
            disabled={isDisabled}
          >
            Needs another
          </Button>
        </Stack>
      )}

      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="medium"
        onClick={handleApprove}
        disabled={isDisabled}
        startIcon={approveLoading ? <Loader loading={approveLoading} size={20} /> : <Icon />}
      >
        Approve {nextRole === TrainingStatus.Trained ? 'to be fully trained' : 'for reverse shadow'}
      </Button>
      <PublicError error={error || approveError} />
    </Stack>
  );
};

ActionButtons.fragments = {
  moduleMember: gql`
    ${getShadowCompletedCount.fragments.moduleMember}
    ${getReverseShadowPendingCount.fragments.moduleMember}
    ${getReverseShadowCompletedCount.fragments.moduleMember}
    fragment ActionButtons_moduleMember on InterviewModuleMember {
      id
      interviewModuleId
      employeeId
      status
      shadowsRequired
      reverseShadowsRequired
      pendingApprovalApplicationStageInterview {
        id
      }
      stats {
        currentTrainingSessionStatus
        completedAsShadow
        completedAsReverseShadow
        manualCompletedAsShadow
        manualCompletedAsReverseShadow
      }
      ...getReverseShadowCompletedCount_moduleMember
      ...getShadowCompletedCount_moduleMember
      ...getReverseShadowPendingCount_moduleMember
    }
  `,
};

export default ActionButtons;
