import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddTraineeIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;

  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.34161 4.32934L9.44095 2.27967C9.79286 2.10372 10.2071 2.10372 10.559 2.27967L14.6583 4.32934C15.2111 4.60574 15.2111 5.39459 14.6583 5.67098L10.559 7.72065C10.2071 7.89661 9.79286 7.89661 9.44095 7.72065L5.34161 5.67098C4.78882 5.39459 4.78882 4.60574 5.34161 4.32934ZM6.99998 7.99993V7.61796L9.10556 8.67075C9.66862 8.95227 10.3314 8.95227 10.8944 8.67075L13 7.61796V7.99993C13 9.65678 11.6568 10.9999 9.99998 10.9999C8.34313 10.9999 6.99998 9.65678 6.99998 7.99993ZM8.99997 15C8.99997 16.2298 9.73994 17.2867 10.7989 17.7499L6.00018 17.7499C5.52152 17.7499 5.06552 17.5531 4.75182 17.2139C4.42833 16.8641 4.2568 16.3562 4.40157 15.8154C5.02447 13.4883 7.35432 12.3932 9.55359 12.2631C9.20497 12.7533 8.99997 13.3527 8.99997 14V15ZM15.25 7.99995C15.25 7.58574 14.9142 7.24995 14.5 7.24995C14.0858 7.24995 13.75 7.58574 13.75 7.99995V9.49995C13.75 9.91416 14.0858 10.25 14.5 10.25C14.9142 10.25 15.25 9.91416 15.25 9.49995V7.99995ZM14.0304 12.5303C14.3232 12.2374 14.3232 11.7626 14.0304 11.4697C13.7375 11.1768 13.2626 11.1768 12.9697 11.4697L10.9697 13.4697C10.6768 13.7626 10.6768 14.2374 10.9697 14.5303L12.9697 16.5303C13.2626 16.8232 13.7375 16.8232 14.0304 16.5303C14.3232 16.2374 14.3232 15.7626 14.0304 15.4697L13.3107 14.75H16C16.6904 14.75 17.25 15.3097 17.25 16C17.25 16.6904 16.6904 17.25 16 17.25C15.5858 17.25 15.25 17.5858 15.25 18C15.25 18.4142 15.5858 18.75 16 18.75C17.5188 18.75 18.75 17.5188 18.75 16C18.75 14.4812 17.5188 13.25 16 13.25H13.3107L14.0304 12.5303Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
