import React from 'react';

import BaseIcon, { Props } from './Icon';

const ArchivedIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.25C10.4142 1.25 10.75 1.58579 10.75 2V7.18934L11.9697 5.96967C12.2626 5.67678 12.7374 5.67678 13.0303 5.96967C13.3232 6.26256 13.3232 6.73744 13.0303 7.03033L10.5303 9.53033C10.2374 9.82322 9.76256 9.82322 9.46967 9.53033L6.96967 7.03033C6.67678 6.73744 6.67678 6.26256 6.96967 5.96967C7.26256 5.67678 7.73744 5.67678 8.03033 5.96967L9.25 7.18934V2C9.25 1.58579 9.58579 1.25 10 1.25ZM6.56619 4.75C6.47838 4.75 6.397 4.79607 6.35182 4.87138L3.86959 9.00843C3.91228 9.00287 3.9558 9 4 9H6.58579C6.851 9 7.10536 9.10536 7.29289 9.29289L8.58579 10.5858C9.36683 11.3668 10.6332 11.3668 11.4142 10.5858L12.7071 9.29289C12.8946 9.10536 13.149 9 13.4142 9H16C16.0442 9 16.0877 9.00287 16.1304 9.00843L13.6482 4.87138C13.603 4.79607 13.5216 4.75 13.4338 4.75H13C12.5858 4.75 12.25 4.41421 12.25 4C12.25 3.58579 12.5858 3.25 13 3.25H13.4338C14.0485 3.25 14.6182 3.57252 14.9344 4.09963L17.5006 8.37662C17.6638 8.64859 17.75 8.95981 17.75 9.27698V15C17.75 15.9665 16.9665 16.75 16 16.75H4C3.0335 16.75 2.25 15.9665 2.25 15L2.25001 9.27698C2.25001 8.95981 2.33621 8.64859 2.49939 8.37662L5.06558 4.09963C5.38184 3.57252 5.95148 3.25 6.56619 3.25H7C7.41421 3.25 7.75 3.58579 7.75 4C7.75 4.41421 7.41421 4.75 7 4.75H6.56619ZM7.25 13C7.25 12.5858 7.58579 12.25 8 12.25H12C12.4142 12.25 12.75 12.5858 12.75 13V14C12.75 14.4142 12.4142 14.75 12 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14V13Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ArchivedIcon;
