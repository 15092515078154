import { SvgIcon, SvgIconProps } from './SvgIcon';

export const HomeFilledIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2.5C5.44772 2.5 5 2.94772 5 3.5V6.5L7 5.00012V3.5C7 2.94772 6.55229 2.5 6 2.5ZM4.5 16.5V8L10 3.5L15.5 8V16.5H12.75V12.5C12.75 10.9812 11.5188 9.75 10 9.75C8.48122 9.75 7.25 10.9812 7.25 12.5V16.5H4.5ZM11.25 12.5V16.5H8.75V12.5C8.75 11.8096 9.30964 11.25 10 11.25C10.6904 11.25 11.25 11.8096 11.25 12.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.84558 4.5749C9.93623 4.50368 10.0638 4.50368 10.1545 4.5749L14.75 8.18569V15.7497H12.75V17.2497H15.5C15.9142 17.2497 16.25 16.9139 16.25 16.4997V9.36426L16.5367 9.58947C16.8624 9.84538 17.3339 9.7888 17.5898 9.4631C17.8457 9.1374 17.7891 8.66591 17.4634 8.41L11.0812 3.39543C10.4467 2.89684 9.55341 2.89684 8.91884 3.39543L2.53667 8.41C2.21096 8.66591 2.15438 9.1374 2.41029 9.4631C2.6662 9.7888 3.13769 9.84538 3.4634 9.58947L3.75003 9.36426V16.4997C3.75003 16.9139 4.08582 17.2497 4.50003 17.2497H7.25003V15.7497H5.25003V8.18569L9.84558 4.5749ZM8.75003 15.7497V17.2497H11.25V15.7497H8.75003Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
