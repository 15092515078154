import React from 'react';

import Stack from 'src/components/Stack';
import { CaretRightIcon, ModulesIcon } from 'src/components/icons';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

import { InterviewModuleSuggestion } from './types';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ModuleSuggestionRowProps = {
  interviewModule: InterviewModuleSuggestion;
};

const ModuleSuggestionRow = ({ interviewModule }: ModuleSuggestionRowProps): JSX.Element => {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      wrap="nowrap"
      itemStyles={{ 0: { flexShrink: 0 }, 1: { flexGrow: 1 }, 2: { flexShrink: 0 } }}
      dataTestId="update-interview-seat-module-suggestion-row"
    >
      <ModulesIcon />
      <MaybeTooltip label={interviewModule.name ?? ''} tooltip={interviewModule.name ?? ''} />
      <CaretRightIcon />
    </Stack>
  );
};

export default ModuleSuggestionRow;
