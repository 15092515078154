import React from 'react';

import BaseIcon, { Props } from './Icon';

const TimezoneIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.39261 4.31819C5.41134 5.22889 3.98817 7.14541 3.77714 9.4137C4.6603 9.61858 5.47666 9.78549 6.25021 9.91482C6.25988 7.93798 6.60601 6.11587 7.18932 4.75481C7.2535 4.60506 7.32125 4.45925 7.39261 4.31819ZM10 3.75C9.83941 3.75 9.6237 3.82276 9.35909 4.08391C9.09126 4.34823 8.81566 4.7679 8.56804 5.34569C8.07393 6.49861 7.75 8.14364 7.75 10C7.75 10.0414 7.75016 10.0827 7.75048 10.1238C8.51591 10.2073 9.25164 10.2494 9.98565 10.2506C10.7276 10.2518 11.4726 10.2112 12.2495 10.1274C12.2498 10.0851 12.25 10.0426 12.25 10C12.25 8.14364 11.9261 6.49861 11.432 5.34569C11.1843 4.7679 10.9087 4.34823 10.6409 4.08391C10.3763 3.82276 10.1606 3.75 10 3.75ZM13.7498 9.91943C13.7407 7.94082 13.3944 6.11692 12.8107 4.75481C12.7465 4.60506 12.6787 4.45925 12.6074 4.31819C14.5892 5.22912 16.0125 7.14637 16.223 9.41541C15.3395 9.62264 14.5229 9.79026 13.7498 9.91943ZM12.1626 11.6442C11.4242 11.7162 10.7044 11.7518 9.98321 11.7506C9.27176 11.7494 8.56276 11.7125 7.83704 11.6408C7.96479 12.8195 8.22553 13.8551 8.56804 14.6543C8.81566 15.2321 9.09126 15.6518 9.35909 15.9161C9.6237 16.1772 9.83941 16.25 10 16.25C10.1606 16.25 10.3763 16.1772 10.6409 15.9161C10.9087 15.6518 11.1843 15.2321 11.432 14.6543C11.7742 13.8559 12.0347 12.8214 12.1626 11.6442ZM12.6074 15.6818C12.6787 15.5407 12.7465 15.3949 12.8107 15.2452C13.2569 14.2039 13.5644 12.8927 13.6885 11.4489C14.4771 11.3254 15.2991 11.1642 16.1759 10.9656C15.85 13.067 14.4772 14.8223 12.6074 15.6818ZM10 17.75C14.2802 17.75 17.75 14.2802 17.75 10C17.75 5.71979 14.2802 2.25 10 2.25C5.71979 2.25 2.25 5.71979 2.25 10C2.25 14.2802 5.71979 17.75 10 17.75ZM7.39261 15.6818C7.32125 15.5407 7.2535 15.3949 7.18932 15.2452C6.74258 14.2028 6.43496 12.8899 6.31106 11.4443C5.52161 11.3204 4.69972 11.1595 3.82372 10.9629C4.14887 13.0655 5.52199 14.822 7.39261 15.6818Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TimezoneIcon;
