import React, { useEffect } from 'react';
import type { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useEmployeesToAddForInterviewModuleLazyQuery } from 'src/generated/mloop-graphql';

import Label from 'src/components/label';

import { BaseEmployeePicker, useSearch } from 'src/entities/EmployeePicker';

import { PAGE_SIZE } from 'src/constants';

import { EmployeeResult } from '../type';

const useStyles = makeStyles(() =>
  createStyles({
    employeePicker: {},
  })
);

interface Props {
  interviewModuleId: string;
  selectedEmployees: EmployeeResult[];
  placeholder: string;
  onChange: (employeeResult: EmployeeResult[]) => void;
}

const InterviewModuleEmployeePicker: FC<Props> = ({ interviewModuleId, selectedEmployees, onChange, placeholder }) => {
  const classes = useStyles();

  // Note: we dont do pagination here , so having a fetch policy is ok, but when we decide to please remove fetchPolicy or pagination wont work
  const [fetchEmployee, { loading, data, error }] = useEmployeesToAddForInterviewModuleLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [searchStr, setSearchStr] = useSearch('', !!data?.employees?.nextCursor);

  useEffect(() => {
    fetchEmployee({
      variables: {
        input: {
          pageInput: {
            limit: PAGE_SIZE,
          },
          isArchived: false,
          isAtsDisabled: false,
          isDirectoryDisabled: false,
          hasAtsId: true,
          search: searchStr,
        },
        interviewModuleId,
      },
    });
  }, [fetchEmployee, interviewModuleId, searchStr]);

  if (error) return <Label>Error</Label>;

  return (
    <BaseEmployeePicker
      autoFocus
      className={classes.employeePicker}
      onInputChange={setSearchStr}
      employees={data?.employees?.items || []}
      loading={loading}
      selectedEmployees={selectedEmployees}
      onChange={onChange}
      placeholder={placeholder}
      filterOptions={(members) => {
        return members.filter((member: EmployeeResult) => {
          return !member?.interviewModuleMember?.employeeId;
        });
      }}
    />
  );
};

export default InterviewModuleEmployeePicker;
