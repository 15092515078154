import React from 'react';

import BaseGreenhouseAshbyPermissionDialog from './BaseGreenhouseAshbyPermissionDialog';

type Props = {
  jobId: string;
  onHasPermission: () => void;
  onClose: () => void;
};

const AshbyCreateCandidatePermissionDialog = ({ jobId, onHasPermission, onClose }: Props) => {
  return (
    <BaseGreenhouseAshbyPermissionDialog
      jobId={jobId}
      onHasPermission={onHasPermission}
      onClose={onClose}
      notAnAdminHelpText="Not an Ashby admin? Please ask your admin to enable the required permissions"
      atsPermissionGrantUrl="https://app.ashbyhq.com/admin/api/keys"
      permissionsListText="Write"
    />
  );
};

export default AshbyCreateCandidatePermissionDialog;
