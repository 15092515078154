/* eslint-disable max-lines */
import type { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch, createSlice } from '@reduxjs/toolkit';
import _, { find, first, isEmpty, uniq } from 'lodash';

import { InterviewerRole, JobStageInterviewSeatType } from 'src/generated/mloop-graphql';

import { PREFERENCE_LEVEL_NON_PREFERRED } from 'src/constants/InterviewPlan';

import AtsSevice from 'src/hooks/atsService/AtsService';

import { updateDebriefRescheduleDetails } from 'src/store/actions/schedule-communications';
import { getHardConflicts } from 'src/store/selectors/conflicts';
import { getAllJobStageInterviewByJobStageId } from 'src/store/selectors/job-stage-interview';
import { getAllJobStageGroupByJobStageId } from 'src/store/selectors/job-stage-interview-group';
import {
  getAllJobStageInterviewSeatByJobStageId,
  getNormalizedJobStageInterviewSeatsById,
} from 'src/store/selectors/job-stage-interview-seat';
import {
  getMeetingRoomUpdates,
  getScheduleContent,
  getVideoMeetingLinkHostEmployeeId,
  getVideoMeetingLinkHostEmployeeIdBySlotId,
} from 'src/store/selectors/schedule-communications';
import { getScheduleUpdates } from 'src/store/selectors/schedule-update';
import { getScheduleById } from 'src/store/selectors/schedules';
import { getSelectedScheduleId } from 'src/store/selectors/scheduling';
import { JobStageInterview } from 'src/store/slices/job-stage-interview';
import { JobStageInterviewGroup } from 'src/store/slices/job-stage-interview-group';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';
import scheduleCommunicationsSlice from 'src/store/slices/schedule-communications';
import {
  ScheduleUpdate,
  ScheduleUpdateInterviewerOptional,
  ScheduleUpdateScorecard,
} from 'src/store/slices/schedule-update';

import getPlaceholders from 'src/utils/PlaceholderFiller/getPlaceholders';
import { InterviewerContent } from 'src/utils/api/createSchedule';
import type { RequestBody as GetEventContentRequestBody } from 'src/utils/api/getEventContent';
import getEventContent from 'src/utils/api/getEventContent';
import {
  AttributeMap,
  InterviewEvent,
  InterviewSchedule,
  RequestBody as ScheduleOptionsRequestBody,
  UIInterviewSeat,
  UIInterviewSeatFreeform,
  UIInterviewSeatLinked,
  UIInterviewSeatModule,
  UIInterviewSlot,
} from 'src/utils/api/getScheduleOptions';
import updateSchedule, { UpdateInterviewEvent } from 'src/utils/api/updateSchedule';
import type { RequestBody as UpdateScheduleRequestBody } from 'src/utils/api/updateSchedule';
import { getExternalErrorMessage } from 'src/utils/error';
import htmlToMrkdwn from 'src/utils/htmlToMrkdwn';
import isQuillTextEmpty from 'src/utils/isQuillTextEmpty';
import logError from 'src/utils/logError';
import { plainText } from 'src/utils/plainText';

import { applyUpdatesToSchedule } from 'src/views-new/ScheduleFlow/Steps/Schedule/applyUpdatesToSchedule';
import { DefaultTemplateIdMap } from 'src/views-new/Settings/TemplateComposer/useTemplateQueries';

import type { AppThunk } from 'src/store';
import { State } from 'src/store';

import { FileStatus, FileUploadFlow, UIFile } from './files';

export type IdToNullableIdMap = { [key: string]: string | null };

export interface CalendarTemplate {
  templateID: string | null;
  subject: string;
  body: string;
}

type Candidate = {
  rowID: string;
  fullName: string;
  email: string | null;
  emailAddresses: string[] | null;
  recruiterID: unknown | null;
  coordinatorID: unknown | null;
  atsId: string;
  atsUrl: string;
};

export interface CandidateAvailability {
  id: string; // can be removed
  day: string; // deprecated
  startAt: string;
  endAt: string;
}

export interface Update {
  selectedToEmailAddress: string;
  candidateTemplateID: string;
  candidateEventTemplateID: string;
  interviewerTemplateIDs: IdToNullableIdMap; // jobStageInterviewId/slotId key
  hasSameContent: boolean;
  candidateCalendarId: string;
  interviewerCalendarId: string;
  slackChannelEnabled: boolean;
  slackChannelId: string;
  slackChannelName: string;
  selectedScheduleId: string | null;
  toEmailAddresses: string[];
  candidate: Candidate;
  initialEmailSubject: string | null;
  initialEmailBody: string | null;
  candidateGoogleEventId: string | null;
  isPrivateCalendarEvent: boolean;
  oldContentRequest: {
    remoteCalendarId: string;
    remoteEventId: string;
  }[];
}

interface SchedulingState {
  stepCandidateAvailability: {
    loading: boolean;
    submitting: boolean;
    error: string | null;
  };
  stepInterviewPlan: {
    loading: boolean;
    submitting: boolean;
    error: string | null;
  };
  stepScheduleOptions: {
    loading: boolean;
    submitting: boolean;
    error: string | null;
    shouldRefreshOptions: boolean;
  };
  stepScheduleContent: {
    loading: boolean;
    isWaiting: boolean;
    isSubmitting: boolean;
    error: string | null;
  };
  selectedScheduleId: string | null;
  isPrivateCalendarEvent: boolean;
  originalCalendarPrivacy: boolean;
  selectedToEmailAddress: string | null;
  toEmailAddresses: string[];
  ccEmployeeIDs: string[];
  bccEmployeeIDs: string[];
  ccExternalAddresses: string[];
  bccExternalAddresses: string[];
  candidateTemplateID: string | null;
  candidateCalendarTemplateID: string | null;
  interviewerTemplateIDs: IdToNullableIdMap; // jobStageInterviewId/slotId key
  initialInterviewerEventTemplateID: string | null;
  hasSameContent: boolean;
  candidate: Candidate | null;
  candidateCommunicationsEnabled: boolean;
  candidateEventCommunicationsEnabled: boolean;
  candidateCalendarId: string | null;
  interviewerCalendarId: string | null;
  // Slack Channel & First Message
  slackChannelEnabled: boolean;
  slackChannelId: string;
  slackChannelName: string;
  prevSlackChannelConversationId: string | undefined;
  prevSlackChannelName: string | undefined;
  slackChannelMessageTemplateID: string | null;
  slackChannelRecruitingTeamEmployeeIDs: string[];
  slackChannelHiringManagerEmployeeIDs: string[];
  originalCandidateCalendarId: string;
  originalInterviewerCalendarId: string;
  candidateEmailOldContent: OldContent | null;
  originalCandidateGoogleEventId: string | null;
  eventOldContent: OldContent[];
  updateFlowInterviewExpanded: string[];
  // jobStageSettings added here instead of in scheduling-comms store b/c we don't have a scheduleId to use for adding items to that store
  jobStageSettings: {
    interviewerCalendarId: string | null;
    candidateCalendarId: string | null;
    interviewerEventTemplateId: string | null;
  };
}

export interface OldContent {
  googleEventId: string | null;
  oldSummary: string;
  oldDescription: string;
}

interface InitializeLastUsedTemplatesPayload {
  slackTemplateId: string | null;
  candidateEmailTemplateId: string | null;
  candidateEventTemplateId: string | null;
  interviewerEventTemplateId: string | null;
}

const getInitialState = (): SchedulingState => {
  return {
    stepCandidateAvailability: {
      loading: true,
      submitting: false,
      error: '',
    },
    stepInterviewPlan: {
      loading: true,
      submitting: false,
      error: '',
    },
    stepScheduleOptions: {
      loading: true,
      submitting: false,
      error: '',
      shouldRefreshOptions: false,
    },
    stepScheduleContent: {
      loading: true,
      isWaiting: false, // Whether or not we are waiting on an API call
      isSubmitting: false, // Whether or not we are submitting the final API call to create/update
      error: '',
    },
    selectedScheduleId: null,
    isPrivateCalendarEvent: false,
    originalCalendarPrivacy: false,
    selectedToEmailAddress: null,
    toEmailAddresses: [],
    ccEmployeeIDs: [],
    bccEmployeeIDs: [],
    ccExternalAddresses: [],
    bccExternalAddresses: [],
    candidateTemplateID: null, // email template id
    candidateCalendarTemplateID: null,
    interviewerTemplateIDs: {},
    initialInterviewerEventTemplateID: '',
    hasSameContent: true,
    candidate: null,
    candidateCommunicationsEnabled: true,
    candidateEventCommunicationsEnabled: true,
    candidateCalendarId: '',
    interviewerCalendarId: '',
    // Slack Channel & First Message
    slackChannelEnabled: false,
    slackChannelId: '',
    slackChannelName: '',
    prevSlackChannelConversationId: undefined,
    prevSlackChannelName: '',
    slackChannelMessageTemplateID: null,
    slackChannelRecruitingTeamEmployeeIDs: [],
    slackChannelHiringManagerEmployeeIDs: [],
    originalCandidateCalendarId: '',
    originalInterviewerCalendarId: '',
    candidateEmailOldContent: null,
    originalCandidateGoogleEventId: null,
    eventOldContent: [],
    updateFlowInterviewExpanded: [],
    jobStageSettings: {
      candidateCalendarId: null,
      interviewerCalendarId: null,
      interviewerEventTemplateId: null,
    },
  };
};

function clearErrors(state: SchedulingState) {
  state.stepCandidateAvailability.error = '';
  state.stepInterviewPlan.error = '';
  state.stepScheduleOptions.error = '';
  state.stepScheduleContent.error = '';
}

const slice = createSlice({
  name: 'scheduling',
  initialState: getInitialState(),
  reducers: {
    /// /////////////////
    // Initialization //
    /// /////////////////

    clear(/* state: SchedulingState, action: PayloadAction<{}> */) {
      return getInitialState();
    },

    clearAnyErrors(state: SchedulingState /* , action: PayloadAction<{}> */) {
      clearErrors(state);
    },

    /// //////////////////////////////
    // Candidate Availability Step //
    /// //////////////////////////////

    setCandidateAvailbilityStepError(state: SchedulingState, action: PayloadAction<{ error: string | null }>) {
      const { error } = action.payload;
      state.stepCandidateAvailability.error = error;
    },

    setCandidateAvailbilityStepSubmitting(state: SchedulingState, action: PayloadAction<{ submitting: boolean }>) {
      const { submitting } = action.payload;
      state.stepCandidateAvailability.submitting = submitting;
      state.stepInterviewPlan.loading = submitting;
    },

    /// //////////////////////
    // Interview Plan Step //
    /// //////////////////////

    setInterviewPlanStepError(state: SchedulingState, action: PayloadAction<{ error: string | null }>) {
      const { error } = action.payload;
      state.stepInterviewPlan.error = error;
    },

    clearInterviewPlanStepError(state: SchedulingState) {
      state.stepInterviewPlan.error = '';
    },

    setInterviewPlanStepSubmitting(state: SchedulingState, action: PayloadAction<{ submitting: boolean }>) {
      const { submitting } = action.payload;
      state.stepInterviewPlan.submitting = submitting;
      state.stepScheduleOptions.loading = submitting;
    },

    /// ////////////////////////
    // Schedule Options Step //
    /// ////////////////////////

    setScheduleOptionsStepError(state: SchedulingState, action: PayloadAction<{ error: string | null }>) {
      const { error } = action.payload;
      state.stepScheduleOptions.error = error;
    },

    setScheduleOptionsStepSubmitting(state: SchedulingState, action: PayloadAction<{ submitting: boolean }>) {
      const { submitting } = action.payload;
      state.stepScheduleOptions.submitting = submitting;
      state.stepScheduleContent.loading = submitting;
    },

    setScheduleOptionsSetupRefresh(state: SchedulingState, action: PayloadAction<{ refresh: boolean }>) {
      const { refresh } = action.payload;
      state.stepScheduleOptions.shouldRefreshOptions = refresh;
    },

    clearSelectedScheduleId(state: SchedulingState) {
      state.selectedScheduleId = null;
    },

    setSelectedScheduleId(state: SchedulingState, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.selectedScheduleId = id;
    },

    /// ////////////
    // Send Step //
    /// ////////////

    setScheduleContentStepError(state: SchedulingState, action: PayloadAction<{ error: string | null }>) {
      const { error } = action.payload;
      state.stepScheduleContent.error = error;
    },

    setScheduleContentStepSubmitting(state: SchedulingState) {
      state.stepScheduleContent.isSubmitting = true;
      state.stepScheduleContent.isWaiting = true;
    },

    clearScheduleContentStepSubmitting(state: SchedulingState) {
      state.stepScheduleContent.isSubmitting = false;
      state.stepScheduleContent.isWaiting = false;
    },

    setScheduleContentStepWaiting(state: SchedulingState, action: PayloadAction<{ isWaiting: boolean }>) {
      const { isWaiting } = action.payload;
      state.stepScheduleContent.isWaiting = isWaiting;
    },

    updateCandidate(state: SchedulingState, action: PayloadAction<{ candidate: Candidate }>) {
      const { candidate } = action.payload;
      state.candidate = candidate;
    },

    updateInterviewerTemplateIDs(
      state: SchedulingState,
      action: PayloadAction<{ interviewerTemplateIDs: IdToNullableIdMap }>
    ) {
      const { interviewerTemplateIDs } = action.payload;
      state.interviewerTemplateIDs = interviewerTemplateIDs;
    },

    updateCandidateCalendarId(state: SchedulingState, action: PayloadAction<{ candidateCalendarId: string }>) {
      const { candidateCalendarId } = action.payload;
      state.candidateCalendarId = candidateCalendarId;
    },

    updateJobStageSettingsCandidateCalendarId(
      state: SchedulingState,
      action: PayloadAction<{ candidateCalendarId: string }>
    ) {
      const { candidateCalendarId } = action.payload;
      state.jobStageSettings.candidateCalendarId = candidateCalendarId;
    },

    updateInterviewerCalendarId(state: SchedulingState, action: PayloadAction<{ interviewerCalendarId: string }>) {
      const { interviewerCalendarId } = action.payload;
      state.interviewerCalendarId = interviewerCalendarId;
    },

    updateJobStageSettingsInterviewerCalendarId(
      state: SchedulingState,
      action: PayloadAction<{ interviewerCalendarId: string }>
    ) {
      const { interviewerCalendarId } = action.payload;
      state.jobStageSettings.interviewerCalendarId = interviewerCalendarId;
    },

    updateJobStageSettingsInterviewerEventTemplateId(
      state: SchedulingState,
      action: PayloadAction<{ interviewerEventTemplateId: string }>
    ) {
      const { interviewerEventTemplateId } = action.payload;
      state.jobStageSettings.interviewerEventTemplateId = interviewerEventTemplateId;
    },

    updateOriginalCalendarPrivacy(state: SchedulingState, action: PayloadAction<{ isPrivate: boolean }>) {
      const { isPrivate } = action.payload;
      state.originalCalendarPrivacy = isPrivate;
    },

    updateOriginalCandidateCalendarId(state: SchedulingState, action: PayloadAction<{ candidateCalendarId: string }>) {
      const { candidateCalendarId } = action.payload;
      state.originalCandidateCalendarId = candidateCalendarId;
    },

    updateOriginalInterviewerCalendarId(
      state: SchedulingState,
      action: PayloadAction<{ interviewerCalendarId: string }>
    ) {
      const { interviewerCalendarId } = action.payload;
      state.originalInterviewerCalendarId = interviewerCalendarId;
    },

    updateHasSameContent(state: SchedulingState, action: PayloadAction<{ hasSameContent: boolean }>) {
      const { hasSameContent } = action.payload;
      state.hasSameContent = hasSameContent;
    },

    updateSlackChannelName(state: SchedulingState, action: PayloadAction<{ value: string }>) {
      const { value } = action.payload;
      state.slackChannelName = value;
    },

    updatePrevSlackChannelInfo(
      state: SchedulingState,
      action: PayloadAction<{ conversationId: string; value: string }>
    ) {
      const { conversationId, value } = action.payload;

      state.prevSlackChannelConversationId = conversationId;
      state.prevSlackChannelName = value;
    },

    initCommunicationTemplateIds(state: SchedulingState, action: PayloadAction<InitializeLastUsedTemplatesPayload>) {
      const { slackTemplateId, candidateEmailTemplateId, candidateEventTemplateId, interviewerEventTemplateId } =
        action.payload;
      state.candidateTemplateID = candidateEmailTemplateId || null;
      state.candidateCalendarTemplateID = candidateEventTemplateId || null;
      state.slackChannelMessageTemplateID = slackTemplateId || null;
      // This is a giant hack, but its one we are already using. We can fix this
      // and key interviewer communications by jobStageId when we move it to its own slice
      state.initialInterviewerEventTemplateID = interviewerEventTemplateId || null;
    },

    initInterviewerTemplateIds(state: SchedulingState, action: PayloadAction<{ schedule: InterviewSchedule }>) {
      const { schedule } = action.payload;
      schedule.events?.forEach((event) => {
        const { slotId } = event;
        if (slotId && !state.interviewerTemplateIDs[slotId]) {
          state.interviewerTemplateIDs[slotId] = state.initialInterviewerEventTemplateID;
        }
      });
    },

    updateCandidateTemplateID(state: SchedulingState, action: PayloadAction<{ templateID: string }>) {
      const { templateID } = action.payload;
      state.candidateTemplateID = templateID;
    },

    updateInterviewerTemplateID(state: SchedulingState, action: PayloadAction<{ slotId: string; templateID: string }>) {
      const { slotId, templateID } = action.payload;
      state.interviewerTemplateIDs[slotId] = templateID;
    },

    updateCandidateCalendarTemplateID: (state, action: PayloadAction<{ templateID: string }>) => {
      const { templateID } = action.payload;
      state.candidateCalendarTemplateID = templateID;
    },

    addUpdateFlowInterviewExpanded: (state, action: PayloadAction<{ interviewId: string }>) => {
      const { interviewId } = action.payload;
      if (!state.updateFlowInterviewExpanded.includes(interviewId)) {
        state.updateFlowInterviewExpanded.push(interviewId);
      }
    },

    updateCCEmployeeIDs(state: SchedulingState, action: PayloadAction<{ employeeIDs: string[] }>) {
      const { employeeIDs } = action.payload;
      state.ccEmployeeIDs = employeeIDs || [];
    },

    updateBCCEmployeeIDs(state: SchedulingState, action: PayloadAction<{ employeeIDs: string[] }>) {
      const { employeeIDs } = action.payload;
      state.bccEmployeeIDs = employeeIDs || [];
    },

    updateCCExternalAddresses(state: SchedulingState, action: PayloadAction<{ ccs: string[] }>) {
      const { ccs } = action.payload;
      state.ccExternalAddresses = ccs || [];
    },

    updateBCCExternalAddresses(state: SchedulingState, action: PayloadAction<{ bccs: string[] }>) {
      const { bccs } = action.payload;
      state.bccExternalAddresses = bccs || [];
    },

    updateCandidateCommunicationsEnabled(state: SchedulingState, action: PayloadAction<{ enabled: boolean }>) {
      const { enabled } = action.payload;
      state.candidateCommunicationsEnabled = enabled;
    },

    updateCandidateEventCommunicationsEnabled(state: SchedulingState, action: PayloadAction<{ enabled: boolean }>) {
      const { enabled } = action.payload;
      state.candidateEventCommunicationsEnabled = enabled;
    },

    updateSlackChannelEnabled(state: SchedulingState, action: PayloadAction<{ enabled: boolean }>) {
      const { enabled } = action.payload;
      state.slackChannelEnabled = enabled;
    },

    updateSlackChannelId(state: SchedulingState, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.slackChannelId = id;
    },

    updateIsPrivateCalendarEvent(state: SchedulingState, action: PayloadAction<{ isPrivate: boolean }>) {
      const { isPrivate: enabled } = action.payload;
      state.isPrivateCalendarEvent = enabled;
    },

    updateCandidateEmailOldContent(
      state: SchedulingState,
      action: PayloadAction<{ initialEmailSubject: string; initialEmailBody: string }>
    ) {
      const { initialEmailSubject, initialEmailBody } = action.payload;
      state.candidateEmailOldContent = {
        googleEventId: null,
        oldSummary: initialEmailSubject,
        oldDescription: initialEmailBody,
      };
    },

    updateOriginalCandidateGoogleEventId(
      state: SchedulingState,
      action: PayloadAction<{ originalCandidateGoogleEventId: string | null }>
    ) {
      const { originalCandidateGoogleEventId } = action.payload;
      state.originalCandidateGoogleEventId = originalCandidateGoogleEventId;
    },

    updateEventOldContent(state: SchedulingState, action: PayloadAction<{ oldContent: OldContent[] }>) {
      const { oldContent } = action.payload;
      state.eventOldContent = oldContent;
    },

    updateSlackChannelRecruitingTeamEmployeeIDs(
      state: SchedulingState,
      action: PayloadAction<{ employeeIDs: string[] }>
    ) {
      const { employeeIDs } = action.payload;
      state.slackChannelRecruitingTeamEmployeeIDs = employeeIDs || [];
    },

    updateSlackChannelHiringManagerEmployeeIDs(
      state: SchedulingState,
      action: PayloadAction<{ employeeIDs: string[] }>
    ) {
      const { employeeIDs } = action.payload;
      state.slackChannelHiringManagerEmployeeIDs = employeeIDs || [];
    },

    updateToEmailAddress(state: SchedulingState, action: PayloadAction<{ toEmailAddress: string }>) {
      const { toEmailAddress } = action.payload;
      state.selectedToEmailAddress = toEmailAddress;
    },

    updateToEmailAddresses(state: SchedulingState, action: PayloadAction<{ toEmailAddresses: string[] }>) {
      const { toEmailAddresses } = action.payload;
      state.toEmailAddresses = toEmailAddresses;
    },

    setStepCandidateAvailabilityLoading(state: SchedulingState, action: PayloadAction<{ loading: boolean }>) {
      const { loading } = action.payload;
      state.stepCandidateAvailability.loading = loading;
    },
  },
});

/// /////////////////
// Update //
/// /////////////////

const getOldEventContent = async (oldContentRequest: GetEventContentRequestBody): Promise<OldContent[]> => {
  try {
    const response = await getEventContent(oldContentRequest);
    if (!response.events) {
      return [];
    }
    return response.events.map((event) => {
      return {
        googleEventId: event.remote_id,
        oldSummary: event.title,
        oldDescription: plainText(event.description),
      };
    });
  } catch (error) {
    logError(error);
    console.log(error?.response);
  }
  return [];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const populateEventData = (state: State, jobStageId: string, schedule: InterviewSchedule): InterviewSchedule => {
  const jobStageInterviews: JobStageInterview[] = getAllJobStageInterviewByJobStageId(state, jobStageId);

  const events = schedule.events.map((event): InterviewEvent => {
    const interviewSlot = find(jobStageInterviews, (slot) => slot.id === event.slotId);

    return {
      ...event,
      atsInterviewDefinitionId:
        event.atsInterviewDefinitionId || interviewSlot?.atsInterviewDefinition?.atsId || undefined,
    };
  });
  return {
    ...schedule,
    events,
  };
};

export const updateIsPrivateCalendarEvent =
  (enabled: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateIsPrivateCalendarEvent({ isPrivate: enabled }));
  };

export const setupUpdate =
  (update: Update): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateInterviewerTemplateIDs({ interviewerTemplateIDs: update.interviewerTemplateIDs }));
    dispatch(slice.actions.updateCandidateTemplateID({ templateID: update.candidateTemplateID }));
    dispatch(slice.actions.updateCandidateCalendarTemplateID({ templateID: update.candidateEventTemplateID }));
    dispatch(slice.actions.updateCandidateCalendarId({ candidateCalendarId: update.candidateCalendarId }));
    dispatch(slice.actions.updateInterviewerCalendarId({ interviewerCalendarId: update.interviewerCalendarId }));
    dispatch(slice.actions.updateOriginalCandidateCalendarId({ candidateCalendarId: update.candidateCalendarId }));
    dispatch(slice.actions.updateIsPrivateCalendarEvent({ isPrivate: update.isPrivateCalendarEvent }));
    dispatch(slice.actions.updateOriginalCalendarPrivacy({ isPrivate: update.isPrivateCalendarEvent }));
    dispatch(
      slice.actions.updateOriginalCandidateGoogleEventId({
        originalCandidateGoogleEventId: update.candidateGoogleEventId,
      })
    );
    dispatch(
      slice.actions.updateOriginalInterviewerCalendarId({ interviewerCalendarId: update.interviewerCalendarId })
    );

    dispatch(slice.actions.updateSlackChannelId({ id: update.slackChannelId }));
    dispatch(slice.actions.updateSlackChannelEnabled({ enabled: update.slackChannelEnabled }));
    dispatch(slice.actions.updateSlackChannelName({ value: update.slackChannelName }));
    dispatch(slice.actions.updateToEmailAddress({ toEmailAddress: update.selectedToEmailAddress }));
    dispatch(slice.actions.updateToEmailAddresses({ toEmailAddresses: update.toEmailAddresses }));
    dispatch(slice.actions.updateCandidate({ candidate: update.candidate }));

    // Set old content
    const oldContent = await getOldEventContent({ calendarEventIds: update.oldContentRequest });
    dispatch(slice.actions.updateEventOldContent({ oldContent }));
  };

/// /////////////////
// Initialization //
/// /////////////////

export const { reducer } = slice;

export const clear = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clear());
};

export const clearAnyErrors = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearAnyErrors());
};

/// //////////////////////////////
// Candidate Availability Step //
/// //////////////////////////////

export const initCommunicationTemplateIds =
  (defaultTemplateIdMap: DefaultTemplateIdMap): AppThunk =>
  async (dispatch, getState) => {
    const { persist } = getState();
    dispatch(
      slice.actions.initCommunicationTemplateIds({
        candidateEmailTemplateId:
          defaultTemplateIdMap.CANDIDATE_CONFIRMATION || persist.lastUsedCandidateEmailTemplateID,
        candidateEventTemplateId: defaultTemplateIdMap.CANDIDATE_INVITE || persist.lastUsedCandidateEventTemplateID,
        interviewerEventTemplateId:
          defaultTemplateIdMap.INTERVIEWER_INVITE || persist.lastUsedInterviewerEventTemplateID,
        slackTemplateId: defaultTemplateIdMap.SLACK_CHANNEL_MESSAGE || persist.lastUsedSlackChannelMessageTemplateID,
      })
    );
  };

/// //////////////////////
// Interview Plan Step //
/// //////////////////////

export const getScheduleOptionsRequestBody = (
  state: State,
  jobStageId: string,
  increment: boolean,
  candidateTimezone: string
  // eslint-disable-next-line max-params
): ScheduleOptionsRequestBody => {
  const jobStageInterviewGroups: JobStageInterviewGroup[] = getAllJobStageGroupByJobStageId(state, jobStageId);
  const jobStageInterviews: JobStageInterview[] = getAllJobStageInterviewByJobStageId(state, jobStageId);
  const jobStageInterviewSeats: JobStageInterviewSeat[] = getAllJobStageInterviewSeatByJobStageId(state, jobStageId);

  const interviewGroups = jobStageInterviewGroups.map((group) => ({
    id: group.id,
    locked: group.locked,
    interviewSlotIds: group.jobStageInterviewIds,
  }));

  const interviewSlots: UIInterviewSlot[] = jobStageInterviews.map((interview) => {
    const seats: UIInterviewSeat[] = [];
    interview.seatIds.forEach((seatId) => {
      const seat = jobStageInterviewSeats.find((value) => value.id === seatId);

      if (!seat) return null;

      if (seat.type === JobStageInterviewSeatType.Module) {
        let attributeMap: AttributeMap | undefined;
        if (seat.attributeMap) {
          attributeMap = {};
          Object.keys(seat.attributeMap).forEach((key) => {
            if (!attributeMap || !seat.attributeMap) return;
            attributeMap[key] = { attributeValues: seat.attributeMap[key] };
          });
        }

        const moduleSeat: UIInterviewSeatModule = {
          id: seat.id,
          interviewId: seat.interviewId || '',
          attributeMap,
          selectedTrainedInterviewerIds: seat.selectedTrainedInterviewerIds,
          selectedReverseShadowInterviewerIds: seat.selectedReverseShadowInterviewerIds,
          selectedShadowInterviewerIds: seat.selectedShadowInterviewerIds,
        };

        return seats.push({ moduleSeat });
      }

      if (seat.type === JobStageInterviewSeatType.Linked) {
        const linkedSeat: UIInterviewSeatLinked = {
          id: seat.id,
          interviewSeatId: seat.interviewSeatId || '',
        };

        return seats.push({ linkedSeat });
      }

      const freeformSeat: UIInterviewSeatFreeform = {
        id: seat.id,
        interviewers:
          seat.freeformSeat?.jobStageInterviewSeatEmployees?.map((value) => ({
            id: value.employeeId,
            preferenceLevel: value.preferenceLevel || PREFERENCE_LEVEL_NON_PREFERRED,
          })) || [],
      };

      return seats.push({ freeformSeat });
    });

    const interviewSlot: UIInterviewSlot = {
      id: interview.id,
      interviewType: interview.type,
      customDuration: interview.duration,
      seats,
      name: interview.name || '',
      locked: interview.isLockedOrder,
      forcedStartAt: interview.forcedStartAt || undefined,
      isHiddenFromCandidate: interview.isHiddenFromCandidate,
    };

    return interviewSlot;
  });

  const params: ScheduleOptionsRequestBody = {
    jobStageId,
    interviewSlots,
    interviewGroups,
    candidateTimezone,
    excludeInterviewerIds: state.jobStagePlanConfig?.byId[jobStageId]?.excludeInterviewerIds,
    hasBreaks: state.jobStagePlanConfig?.byId[jobStageId]?.hasBreaks,
    hasMultiDay: state.jobStagePlanConfig?.byId[jobStageId]?.hasMultiDay,
    hardConflicts: getHardConflicts(state).map((value) => ({
      startAt: value.startAt,
      endAt: value.endAt,
      organizerEmail: value.organizerEmail,
      attendeesEmails: value.attendeesEmails,
      eventUid: value.eventUid,
    })),
    throwOnZero: increment && getHardConflicts(state).length === 0,
    numberOfDays: state.jobStagePlanConfig?.byId[jobStageId]?.numberOfDays,
  };

  return params;
};

export const clearInterviewPlanStepError = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.clearInterviewPlanStepError());

/// ////////////////////////
// Schedule Options Step //
/// ////////////////////////
export const clearSelectedScheduleId = (): AppThunk => async (dispatch) =>
  dispatch(slice.actions.clearSelectedScheduleId());

export const setSelectedScheduleId =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSelectedScheduleId({ id }));
  };

export const submitScheduleOptionsStep =
  (
    applicationId: string,
    selectedScheduleId: string,
    incrementStp = true,
    gotoNextStep?: () => void
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(slice.actions.setScheduleOptionsStepSubmitting({ submitting: true }));

    try {
      const { scheduling, schedules } = getState();

      const scheduleId = selectedScheduleId ?? scheduling.selectedScheduleId;

      if (!scheduleId) {
        dispatch(slice.actions.setScheduleOptionsStepError({ error: 'No schedule selected' }));
        return;
      }
      // Initialize interviewer template ids before content regeneration
      dispatch(slice.actions.initInterviewerTemplateIds({ schedule: schedules.byId[scheduleId] }));

      // Set or update interviewer ids for slack channel
      const schedule = getScheduleById(getState(), scheduleId);
      const scheduleUpdates = getScheduleUpdates(getState(), schedule.id);
      const updatedSchedule = applyUpdatesToSchedule(schedule, scheduleUpdates);
      let interviewerIDs: string[] = [];
      if (updatedSchedule && updatedSchedule.events) {
        interviewerIDs = _.uniq(
          updatedSchedule.events.map((e) => e.interviewers?.map((i) => i.employee.id) || []).flat()
        );
      }
      dispatch(
        scheduleCommunicationsSlice.actions.updateSlackChannelInterviewerEmployeeIDs({
          scheduleId,
          employeeIDs: interviewerIDs,
        })
      );

      if (scheduling.slackChannelHiringManagerEmployeeIDs) {
        dispatch(
          scheduleCommunicationsSlice.actions.updateSlackChannelHiringManagerEmployeeIDs({
            scheduleId,
            employeeIDs: scheduling.slackChannelHiringManagerEmployeeIDs,
          })
        );
      }

      if (scheduling.slackChannelRecruitingTeamEmployeeIDs) {
        dispatch(
          scheduleCommunicationsSlice.actions.updateSlackChannelRecruitingTeamEmployeeIDs({
            scheduleId,
            employeeIDs: scheduling.slackChannelRecruitingTeamEmployeeIDs,
          })
        );
      }

      dispatch(
        scheduleCommunicationsSlice.actions.setFlow({
          scheduleId,
          applicationId,
          isDebrief: false,
        })
      );

      dispatch(
        scheduleCommunicationsSlice.actions.updatePrevSlackChannelName({
          scheduleId: scheduling.selectedScheduleId || '',
          name: scheduling.prevSlackChannelName || '',
        })
      );
      dispatch(
        scheduleCommunicationsSlice.actions.updateConversationId({
          scheduleId,
          conversationId: scheduling.prevSlackChannelConversationId || '',
        })
      );
      dispatch(
        scheduleCommunicationsSlice.actions.updateSlackChannelEnabled({
          scheduleId: scheduling.selectedScheduleId || '',
          isEnabled: scheduling.slackChannelEnabled,
        })
      );

      dispatch(
        scheduleCommunicationsSlice.actions.updateSlackChannelMessageTemplateID({
          scheduleId: scheduling.selectedScheduleId || '',
          templateId: scheduling.slackChannelMessageTemplateID || '',
        })
      );

      if (incrementStp) {
        if (gotoNextStep) {
          gotoNextStep();
        }
      }
      dispatch(slice.actions.setScheduleOptionsStepError({ error: null }));
    } catch (error) {
      logError(error);
      console.log(error?.response);
      dispatch(slice.actions.setScheduleOptionsStepError({ error: getExternalErrorMessage(error) }));
    }
    dispatch(slice.actions.setScheduleOptionsStepSubmitting({ submitting: false }));
  };

/// //////////////////////
// Send / Content Step //
/// //////////////////////
export const updatePrevSlackChannelInfo =
  (conversationId: string, value: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updatePrevSlackChannelInfo({ conversationId, value }));
  };

export const updateCCEmployeeIDs =
  (employeeIDs: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateCCEmployeeIDs({ employeeIDs }));
  };

export const updateBCCEmployeeIDs =
  (employeeIDs: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateBCCEmployeeIDs({ employeeIDs }));
  };

export const updateCCExternalAddresses =
  (ccs: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateCCExternalAddresses({ ccs }));
  };

export const updateBCCExternalAddresses =
  (bccs: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateBCCExternalAddresses({ bccs }));
  };

export const updateCandidateCommunicationsEnabled =
  (enabled: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateCandidateCommunicationsEnabled({ enabled }));
  };

export const updateCandidateEventCommunicationsEnabled =
  (enabled: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateCandidateEventCommunicationsEnabled({ enabled }));
  };

export const updateSlackChannelEnabled =
  (enabled: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateSlackChannelEnabled({ enabled }));
  };

export const updateSlackChannelRecruitingTeamEmployeeIDs =
  (employeeIDs: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateSlackChannelRecruitingTeamEmployeeIDs({ employeeIDs }));
  };

export const updateSlackChannelHiringManagerEmployeeIDs =
  (employeeIDs: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateSlackChannelHiringManagerEmployeeIDs({ employeeIDs }));
  };

export const updateHasSameContent =
  (hasSameContent: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateHasSameContent({ hasSameContent }));
  };

export const updateToEmailAddress =
  (toEmailAddress: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateToEmailAddress({ toEmailAddress }));
  };

export const updateToEmailAddresses =
  (toEmailAddresses: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateToEmailAddresses({ toEmailAddresses }));
  };

export const addUpdateFlowInterviewExpanded =
  (interviewId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.addUpdateFlowInterviewExpanded({ interviewId }));
  };

export const updateCandidate =
  (candidate: Candidate): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateCandidate({ candidate }));
  };

export const updateCandidateCalendarId =
  (candidateCalendarId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateCandidateCalendarId({ candidateCalendarId }));
  };

export const updateJobStageSettingsCandidateCalendarId =
  (candidateCalendarId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateJobStageSettingsCandidateCalendarId({ candidateCalendarId }));
  };

export const updateCandidateCalendarTemplateID =
  (templateID: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateCandidateCalendarTemplateID({ templateID }));
  };

export const updateInterviewerCalendarId =
  (interviewerCalendarId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateInterviewerCalendarId({ interviewerCalendarId }));
  };

export const updateJobStageSettingsInterviewerCalendarId =
  (interviewerCalendarId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateJobStageSettingsInterviewerCalendarId({ interviewerCalendarId }));
  };

export const updateCandidateTemplateID =
  (templateID: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateCandidateTemplateID({ templateID }));
  };

export const updateJobStageSettingsInterviewerEventTemplateId =
  (interviewerEventTemplateId: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateJobStageSettingsInterviewerEventTemplateId({ interviewerEventTemplateId }));
  };

export const updateAllInterviewerTemplateID =
  (templateID: string): AppThunk =>
  async (dispatch, getState) => {
    const { interviewerTemplateIDs, selectedScheduleId } = getState().scheduling;
    const { scheduleUpdate } = getState();

    Object.keys(interviewerTemplateIDs).forEach((slotId) => {
      dispatch(slice.actions.updateInterviewerTemplateID({ slotId, templateID }));
    });

    if (selectedScheduleId && scheduleUpdate.byId[selectedScheduleId]) {
      scheduleUpdate.byId[selectedScheduleId].forEach((update) => {
        if (update.type !== 'ScheduleUpdateNewInterview') return;

        dispatch(slice.actions.updateInterviewerTemplateID({ slotId: update.applicationStageInterviewId, templateID }));
      });
    }
  };

export const updateInterviewerTemplateID =
  (
    eventId: string,
    slotId: string,
    templateID: string
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.updateInterviewerTemplateID({ slotId, templateID }));
  };

export const setScheduleOptionsSetupRefresh =
  (refresh: boolean) =>
  (dispatch: Dispatch): void => {
    dispatch(slice.actions.setScheduleOptionsSetupRefresh({ refresh }));
  };

/// ////////////////////////
// Schedule Content Step //
/// ////////////////////////
export const getSubmitErrorMessage = (state: State, internalOnly = false): string | null => {
  const { scheduling, scheduleCommunications } = state;
  const { selectedScheduleId } = scheduling;
  const scheduleContent = getScheduleContent(state);

  // Slack channel option needs message content
  if (selectedScheduleId) {
    const scheduleCommsData = scheduleCommunications.byId[selectedScheduleId];
    if (scheduleCommsData.slackChannelEnabled && !htmlToMrkdwn(scheduleCommsData?.slackChannelContent || '')) {
      return 'Missing Slack channel message content';
    }
  }

  if (!internalOnly) {
    if (scheduling.candidateCommunicationsEnabled) {
      const isEmailEventSubjectEmpty = isQuillTextEmpty(scheduleContent?.candidateEventContent.summary || '');
      const isEmailEventBodyEmpty = isQuillTextEmpty(scheduleContent?.candidateEventContent.description || '');
      if (isEmailEventSubjectEmpty && isEmailEventBodyEmpty) {
        return 'Missing candidate email subject line and body text';
      }
      if (isEmailEventBodyEmpty) {
        return 'Missing candidate email body text';
      }
      if (isEmailEventSubjectEmpty) {
        return 'Missing candidate email subject line';
      }
    }

    if (!scheduling.hasSameContent && scheduling.candidateEventCommunicationsEnabled) {
      const isCalendarEventSubjectEmpty = isQuillTextEmpty(
        scheduleContent?.candidateCalendarEventContent.subject || ''
      );
      const isCalendarEventBodyEmpty = isQuillTextEmpty(scheduleContent?.candidateCalendarEventContent.body || '');
      if (isCalendarEventSubjectEmpty && isCalendarEventBodyEmpty) {
        return 'Missing candidate event subject line and body text';
      }
      if (isCalendarEventBodyEmpty) {
        return 'Missing candidate event body text';
      }
      if (isCalendarEventSubjectEmpty) {
        return 'Missing candidate event subject line';
      }
    }

    if (!scheduling.candidateCalendarId && !scheduling.interviewerCalendarId) {
      return 'Please confirm your event calendars from the calendar section.';
    }

    if (!scheduling.candidateCalendarId) {
      return 'Please confirm your candidate event calendar from the calendar section.';
    }
  }

  // interviewerCalendarId check is always needed
  if (!scheduling.interviewerCalendarId) {
    return 'Please confirm your interviewer event calendar from the calendar section.';
  }

  return null;
};

const validateUpdateRequest = (data: UpdateScheduleRequestBody, candidateCommsEnabled: boolean) => {
  const interviewsMissingInterviewers = data.interviewerContents
    .filter((content) => content.interviewer.length === 0)
    .map((content) => {
      return content.interviewName;
    });
  if (interviewsMissingInterviewers.length !== 0) {
    throw new Error(`Following interviews are missing interviewers: ${interviewsMissingInterviewers.join(' ')}`);
  }
  if (candidateCommsEnabled && !data.candidateCalendarId) {
    throw new Error(`Missing Candidate Calendar id`);
  }
};

export const validateCandidateComms = (
  toEmailAddress: string,
  candidateCommsEnabled: boolean,
  candidateEventCommsEnabled: boolean
  // eslint-disable-next-line max-params
) => {
  if (candidateCommsEnabled && toEmailAddress === '') {
    throw new Error(`Missing Candidate email address`);
  }
  if (candidateEventCommsEnabled && toEmailAddress === '') {
    throw new Error(`Missing Candidate email address`);
  }
};

export const validSlackChannelName = (slackChannelName: string | undefined) => {
  const regexExp =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
  if (slackChannelName && regexExp.test(slackChannelName)) {
    throw new Error(`Invlaid emoji char in Slack channel name`);
  }
};

const validateNoTokens = (
  content: UpdateScheduleRequestBody,
  candidateCommsEnabled: boolean,
  atsService: AtsSevice
  // eslint-disable-next-line max-params
) => {
  let matches;

  if (candidateCommsEnabled) {
    matches = getPlaceholders(content.candidateEventContent?.summary || '');
    if (matches.length > 0) {
      throw new Error(`Found unused tokens in candidate email title: ${matches.join(' ')}`);
    }

    matches = getPlaceholders(content.candidateEventContent?.description || '');
    if (matches.length > 0) {
      throw new Error(`Found unused tokens in candidate email body: ${matches.join(' ')}`);
    }
  }

  for (const interviewerContent of content.interviewerContents) {
    matches = getPlaceholders(interviewerContent.summary);
    if (matches.length > 0) {
      throw new Error(`Found unused tokens in interviewer email title: ${matches.join(' ')}`);
    }

    matches = getPlaceholders(interviewerContent.description) as string[];
    const skipInterviewKitValidation =
      atsService.skipInterviewKitLinkTokenValidation &&
      _.uniq(matches).length === 1 &&
      _.first(matches) === '{{INTERVIEW_KIT_LINK}}';
    if (matches.length > 0 && !skipInterviewKitValidation) {
      throw new Error(`Found unused tokens in interviewer email body: ${matches.join(' ')}`);
    }
  }
};

export function getInterviewers({
  event,
  getState,
  interviewPlanJobStageId,
}: {
  event: InterviewEvent;
  getState: () => State;
  interviewPlanJobStageId: string;
}) {
  if (!event.filledInterviewSeats) return [];

  const scheduleId = getSelectedScheduleId(getState());

  let optionalInterviewerUpdates: ScheduleUpdateInterviewerOptional[] = [];

  const allJobStageInterviewSeatIds = getAllJobStageInterviewSeatByJobStageId(getState(), interviewPlanJobStageId).map(
    (s) => s.id
  );

  if (scheduleId) {
    optionalInterviewerUpdates = getScheduleUpdates(getState(), scheduleId)?.filter?.(
      (update) => update?.type === 'ScheduleUpdateInterviewerOptional'
    ) as ScheduleUpdateInterviewerOptional[];
  }

  function findInterviewerOptionalStatus(interviewerId: string, interviewId: string) {
    const currentOptionalInterviewerUpdate = optionalInterviewerUpdates?.find(
      (val) => val?.applicationStageInterviewId === interviewId && val?.applicationStageInterviewerId === interviewerId
    );

    return Boolean(currentOptionalInterviewerUpdate?.isOptional);
  }

  return event.filledInterviewSeats.flatMap((filledSeat) => {
    interface InterviewerData {
      interviewer_id: string;
      interview_id: string | null;
      role?: string;
      job_stage_interview_seat_id: string | null;
      is_optional?: boolean;
    }

    let trainee: InterviewerData | null = null;
    let interviewer: InterviewerData | null = null;

    let interviewId: string | null = null;

    if (!isEmpty(filledSeat.seat.moduleSeat) || !isEmpty(filledSeat.seat.linkedSeat)) {
      if (filledSeat.seat.moduleSeat) {
        interviewId = filledSeat.seat.moduleSeat?.interviewId || null;
      } else {
        const originalSeats = getNormalizedJobStageInterviewSeatsById(getState(), [
          filledSeat.seat.linkedSeat?.id || '',
        ]);
        if (originalSeats && originalSeats.length > 0) {
          interviewId = first(originalSeats)?.interviewId || null;
        }
      }

      if (filledSeat.traineeId) {
        let seatId = filledSeat.seat.moduleSeat?.id || filledSeat.seat.linkedSeat?.id || null;
        seatId = seatId && allJobStageInterviewSeatIds.includes(seatId) ? seatId : null;

        trainee = {
          interviewer_id: filledSeat.traineeId,
          interview_id: interviewId,
          role: filledSeat.traineeRole,
          job_stage_interview_seat_id: seatId,
        };

        const traineeOptionalStatus = findInterviewerOptionalStatus(filledSeat?.traineeId, event?.id);

        if (traineeOptionalStatus) {
          trainee.is_optional = traineeOptionalStatus;
        }
      }
    }
    if (filledSeat.interviewerId) {
      let seatId =
        filledSeat.seat.moduleSeat?.id || filledSeat.seat.linkedSeat?.id || filledSeat.seat.freeformSeat?.id || null;
      seatId = seatId && allJobStageInterviewSeatIds.includes(seatId) ? seatId : null;

      interviewer = {
        interviewer_id: filledSeat.interviewerId,
        interview_id: interviewId,
        role: InterviewerRole.Interviewer,
        job_stage_interview_seat_id: seatId,
      };

      const interviewerOptionalStatus = findInterviewerOptionalStatus(filledSeat?.interviewerId, event?.id);

      if (interviewerOptionalStatus) {
        interviewer.is_optional = interviewerOptionalStatus;
      }
    }

    return [trainee, interviewer].filter((a) => a) as {
      interviewer_id: string;
      interview_id: string | null;
      role?: string;
      job_stage_interview_seat_id: string | null;
      is_optional: boolean;
    }[]; // remove nulls
  });
}

function genDeletedInterviews(originalSchedule: InterviewSchedule, updates: ScheduleUpdate[]) {
  const deletedInterviewIds: string[] = [];

  if (!originalSchedule.events) return deletedInterviewIds;

  originalSchedule.events.forEach((originalEvent) => {
    const deletes = (updates || []).filter(
      (update) =>
        update.type === 'ScheduleUpdateDeleteInterview' && update.applicationStageInterviewId === originalEvent.id
    );
    if (deletes.length > 0) {
      deletedInterviewIds.push(originalEvent.id);
    }
  });
  return deletedInterviewIds;
}

export const confirmUpdateSchedule =
  (
    atsService: AtsSevice,
    logUpdateEvent: () => void,
    {
      applicationStageId,
      candidateTimezone,
      jobStageId,
    }: {
      applicationStageId: string;
      candidateTimezone: string;
      jobStageId: string;
    }
  ): // eslint-disable-next-line max-params
  AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(slice.actions.setScheduleContentStepSubmitting());
      const { scheduling, fileUpload, scheduleUpdate, scheduleCommunications } = getState();

      const { selectedScheduleId } = scheduling;
      const originalSchedule = getScheduleById(getState(), selectedScheduleId || '');
      const scheduleContent = getScheduleContent(getState());
      const videoMeetingHostEmployeeId = getVideoMeetingLinkHostEmployeeId(getState(), selectedScheduleId || '');

      const schedule = populateEventData(
        getState(),
        jobStageId,
        applyUpdatesToSchedule(
          originalSchedule as InterviewSchedule,
          scheduleUpdate.byId[scheduling.selectedScheduleId || '']
        )
      );

      const updates: ScheduleUpdate[] = scheduleUpdate.byId[originalSchedule?.id || ''] || [];
      const addedInterviewIds = updates
        ?.map((update) => {
          return update.type === 'ScheduleUpdateNewInterview' ? update.applicationStageInterviewId : '';
        })
        ?.filter((id) => Boolean(id));

      const updatedInterviewEventIds = uniq(updates.map((update) => update.applicationStageInterviewId));

      const updateInterviewEvent = scheduleContent
        ? (scheduleContent.interviewerEventContents
            .map((item) => {
              const event: InterviewEvent = schedule.events?.find((e) => e.id === item.event.id) as InterviewEvent;

              const updatesForEvent = updates.filter((update) => update.applicationStageInterviewId === event.id);
              if (
                updatesForEvent.length === 0 &&
                !updatedInterviewEventIds.includes(event.id) &&
                !scheduling.updateFlowInterviewExpanded.includes(event.id)
              )
                return null;

              // filter out any newly added interviews.
              if (addedInterviewIds.includes(event.id)) return null;

              const { addedRooms, unchangedRooms } = getMeetingRoomUpdates(
                getState(),
                scheduling.selectedScheduleId || '',
                event.id
              );

              const interview: UpdateInterviewEvent = {
                applicationStageInterviewId: event.id,
                interviewer: getInterviewers({
                  event,
                  getState,
                  interviewPlanJobStageId: jobStageId,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                }) as any,
                videoMeetingUrl: item.location || '',
                startAt: new Date(event.startAt).toISOString(),
                endAt: new Date(event.endAt).toISOString(),
                summary: item.summary,
                description: item.description,
                codingInterviewUrl: item.codingInterviewURL || '',
                interviewName: event.name || '',
                meetingRoomIds: [...unchangedRooms, ...addedRooms]
                  .map((roomSuggestion) => roomSuggestion.room?.id || '')
                  .filter(Boolean),
                isHiddenFromCandidate: event.isHiddenFromCandidate,
                videoMeetingHostEmployeeId:
                  (selectedScheduleId && event.slotId
                    ? getVideoMeetingLinkHostEmployeeIdBySlotId(getState(), {
                        scheduleId: selectedScheduleId,
                        slotId: event.slotId,
                      })
                    : undefined) || videoMeetingHostEmployeeId,
              };

              if (item.zoomInfo && item.zoomUserId) {
                interview.zoomInfo = {
                  userId: item.zoomUserId,
                  password: item.zoomInfo.password,
                  pstnPassword: item.zoomInfo.pstnPassword as unknown as number,
                  dialInfo: item.zoomInfo.dialInfo,
                  meetingId: item.zoomInfo.meetingID,
                };
              }

              // apply scorecard if changed
              const scorecardUpdatesForEvent = updatesForEvent.filter(
                (update) => update.type === 'ScheduleUpdateScorecard'
              ) as ScheduleUpdateScorecard[];
              const hasScorecardChange = scorecardUpdatesForEvent.length > 0;

              if (hasScorecardChange) {
                const lastScorecardUpdate = scorecardUpdatesForEvent[scorecardUpdatesForEvent.length - 1];

                if (!lastScorecardUpdate.atsInterviewDefinitionId) {
                  interview.removeAtsScorecard = true;
                } else {
                  interview.atsInterviewDefinitionId = lastScorecardUpdate.atsInterviewDefinitionId;
                }
              }

              return interview;
            })
            .filter((interview) => interview !== null) as UpdateInterviewEvent[])
        : [];

      const addedInterviewerContents: InterviewerContent[] = [];
      if (scheduleContent) {
        scheduleContent.interviewerEventContents.forEach((interviewerContent) => {
          const interviewerContentEvent = schedule.events?.find(
            (e) => e.id === interviewerContent.event.id
          ) as InterviewEvent;

          if (!addedInterviewIds.includes(interviewerContentEvent.id)) return;
          const { addedRooms, unchangedRooms } = getMeetingRoomUpdates(
            getState(),
            scheduling.selectedScheduleId || '',
            interviewerContentEvent.id
          );
          addedInterviewerContents.push({
            interviewer: getInterviewers({
              event: interviewerContentEvent,
              getState,
              interviewPlanJobStageId: jobStageId,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }) as any,
            videoMeetingHostEmployeeId:
              (selectedScheduleId && interviewerContent.slotId
                ? getVideoMeetingLinkHostEmployeeIdBySlotId(getState(), {
                    scheduleId: selectedScheduleId,
                    slotId: interviewerContent.slotId,
                  })
                : undefined) || videoMeetingHostEmployeeId,
            videoMeetingUrl: interviewerContent.location || '',
            startAt: new Date(interviewerContentEvent.startAt).toISOString(),
            endAt: new Date(interviewerContentEvent.endAt).toISOString(),
            summary: interviewerContent.summary,
            description: interviewerContent.description,
            codingInterviewUrl: interviewerContent.codingInterviewURL || '',
            interviewName: interviewerContentEvent.name || '',
            atsInterviewDefinitionId: interviewerContentEvent.atsInterviewDefinitionId,
            emailTemplateId: interviewerContent.emailTemplateID || '',
            isHiddenFromCandidate: interviewerContentEvent.isHiddenFromCandidate,
            zoomInfo:
              interviewerContent.zoomInfo && interviewerContent.zoomUserId
                ? {
                    userId: interviewerContent.zoomUserId,
                    password: interviewerContent.zoomInfo?.password,
                    pstnPassword: interviewerContent.zoomInfo?.pstnPassword as unknown as number,
                    dialInfo: interviewerContent.zoomInfo?.dialInfo,
                    meetingId: interviewerContent.zoomInfo?.meetingID,
                  }
                : undefined,
            meetingRoomIds: [...unchangedRooms, ...addedRooms]
              .map((roomSuggestion) => roomSuggestion.room?.id || '')
              .filter(Boolean),
          });
        });
      }

      const data: UpdateScheduleRequestBody = {
        interviewerContents: updateInterviewEvent,
        applicationStageId,
        deletedApplicationStageInterviewIds: originalSchedule ? genDeletedInterviews(originalSchedule, updates) : [],
        isPrivateCalendarEvent: scheduling.isPrivateCalendarEvent,
        candidateTimezone,
        addedInterviewerContents,
      };

      data.candidateCalendarId = scheduling.candidateCalendarId || '';
      data.interviewerCalendarId = scheduling.interviewerCalendarId || '';

      const scheduleCommsData = scheduleCommunications.byId[scheduling.selectedScheduleId || ''] || {};
      if (scheduleCommsData.slackChannelEnabled && scheduleCommsData.slackChannelContent) {
        if (htmlToMrkdwn(scheduleCommsData.slackChannelContent || '') === '') {
          throw new Error('Missing Slack channel message content');
        }

        data.slackChannelContent = {
          slackChannelInterviewerEmployeeIds: scheduleCommsData.slackChannelInterviewerEmployeeIDs,
          slackChannelRecruitingTeamEmployeeIds: scheduleCommsData.slackChannelRecruitingTeamEmployeeIDs,
          slackChannelHiringManagerEmployeeIds: scheduleCommsData.slackChannelHiringManagerEmployeeIDs,
          slackChannelMessageContent: htmlToMrkdwn(scheduleCommsData.slackChannelContent || ''),
        };

        /*
          Logic -
          - if there's a remote id, user has selected an existing channel
          - if there's a conversation id, user has selected a previously used channel
          - if there's only a name, they are creating a new slack channel
        */
        if (scheduleCommsData.slackChannelRemoteId) {
          data.slackChannelContent.slackChannelId = scheduleCommsData.slackChannelRemoteId;
        } else if (scheduleCommsData.conversationId) {
          data.slackChannelContent.conversationId = scheduleCommsData.conversationId;
        } else {
          data.slackChannelContent.slackChannelName = scheduleCommsData.slackChannelName;
        }
      }

      const toEmailAddress = scheduling.selectedToEmailAddress || '';
      if (scheduling.candidateCommunicationsEnabled) {
        const attachments = _.toArray(fileUpload[FileUploadFlow.SCHEDULING])
          .filter((file: UIFile) => file.status === FileStatus.UPLOADED)
          .map((file: UIFile) => {
            return { name: file.name, path: file.path || '' };
          });

        data.candidateEmailContent = {
          toEmailAddress,
          ccEmployeeIds: scheduling.ccEmployeeIDs,
          bccEmployeeIds: scheduling.bccEmployeeIDs,
          ccExternalAddresses: scheduling.ccExternalAddresses,
          bccExternalAddresses: scheduling.bccExternalAddresses,
          description: scheduleContent?.candidateEventContent.description || '',
          summary: scheduleContent?.candidateEventContent.summary || '',
          attachments,
          emailTemplateId: scheduleContent?.candidateEventContent?.emailTemplateID || '',
        };
      }
      if (scheduling.candidateEventCommunicationsEnabled) {
        data.candidateEventContent = {
          toEmailAddress,
          summary: scheduling.hasSameContent
            ? scheduleContent?.candidateEventContent.summary || ''
            : scheduleContent?.candidateCalendarEventContent.subject || '',
          description: scheduling.hasSameContent
            ? scheduleContent?.candidateEventContent.description || ''
            : scheduleContent?.candidateCalendarEventContent.body || '',
          videoMeetingUrl: scheduling.hasSameContent
            ? scheduleContent?.candidateEventContent.location || ''
            : scheduleContent?.candidateCalendarEventContent.location || '',
          calendarEventTemplateId: scheduling.hasSameContent
            ? scheduleContent?.candidateEventContent?.emailTemplateID || ''
            : scheduleContent?.candidateCalendarEventContent?.templateID || '',
        };
      }
      validateNoTokens(data, scheduling.candidateCommunicationsEnabled, atsService);
      validateUpdateRequest(data, scheduling.candidateCommunicationsEnabled);
      validateCandidateComms(
        toEmailAddress,
        scheduling.candidateCommunicationsEnabled,
        scheduling.candidateEventCommunicationsEnabled
      );

      const response = await updateSchedule(data);
      const hasAttendeeAdded = response.debrief_reschedule_details?.has_attendee_added;
      const hasScheduleTimeAfterDebriefStartAt =
        response.debrief_reschedule_details?.has_schedule_time_after_debrief_start_at;

      dispatch(
        updateDebriefRescheduleDetails(
          scheduling.selectedScheduleId || '',
          hasAttendeeAdded,
          hasScheduleTimeAfterDebriefStartAt
        )
      );
      logUpdateEvent();
    } catch (error) {
      logError(error);
      dispatch(slice.actions.setScheduleContentStepError({ error: getExternalErrorMessage(error) }));
    }
    dispatch(slice.actions.clearScheduleContentStepSubmitting());
  };

export const setScheduleContentStepWaiting =
  (isWaiting: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setScheduleContentStepWaiting({ isWaiting }));
  };

export default slice;
