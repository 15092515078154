import { ApolloCache } from '@apollo/client';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const apolloCacheDeleteById = (cache: ApolloCache<unknown>, entityId?: string, entityTypeName?: string) => {
  if (!entityId || !entityTypeName) return;
  const id = cache.identify({
    id: entityId,
    __typename: entityTypeName,
  });
  if (!id) return;
  cache.evict({ id });
  cache.gc();
};

export const apolloCacheDeleteByQuery = (
  cache: ApolloCache<unknown>,
  fieldName?: string,
  args?: Record<string, unknown>
  // eslint-disable-next-line max-params
) => {
  if (!fieldName) return;
  cache.evict({ id: 'ROOT_QUERY', fieldName, args });
  cache.gc();
};
