import { gql } from '@apollo/client';

import { useCandidateAvailabilityOptionsDeleteMutation } from 'src/generated/mloop-graphql';

import useGenericOnError from 'src/hooks/useGenericOnError';

export const CandidateAvailabilityOptionsDelete = gql`
  mutation CandidateAvailabilityOptionsDelete($id: uuid!) {
    candidateAvailabilityOptionsDelete(id: $id) {
      result
    }
  }
`;

type DeleteMutation = ReturnType<typeof useCandidateAvailabilityOptionsDeleteMutation>;

const useDeleteCandidateAvailabilityOptions = (): {
  deleteCandidateAvailabilityOptionsMutation: DeleteMutation['0'];
  deleting: DeleteMutation['1']['loading'];
  error: DeleteMutation['1']['error'];
} => {
  const genericOnError = useGenericOnError();

  const [deleteCandidateAvailabilityOptionsMutation, { loading: deleting, error }] =
    useCandidateAvailabilityOptionsDeleteMutation({
      onError: genericOnError,
    });

  return { deleteCandidateAvailabilityOptionsMutation, deleting, error };
};

export default useDeleteCandidateAvailabilityOptions;
