import React from 'react';

import { CrossIcon, EditIcon, StarFilledIcon, StarIcon } from '@modernloop/shared/icons';
import { Box, IconButton, Avatar as MuiAvatar, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { EmployeeFragment } from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import Chip from 'src/components/chip';
import Tooltip from 'src/components/tooltip';

import { PREFERENCE_LEVEL_NON_PREFERRED, PREFERENCE_LEVEL_PREFERRED } from 'src/constants/InterviewPlan';

import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import { useAvatarSxProps, useGridSxProps, useSxProps } from './sxProps';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface FreeformSeatProps {
  jobStageInterviewSeat: JobStageInterviewSeat;
  handleMouseOut: () => void;
  handleMouseOver: () => void;
  seatIndex: number;
  onEdit: () => void;
  showOptions: boolean;
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onInterviewerPreferenceChanged: (employeeId: string, preferenceLevel: number) => void;
}

const FreeformSeat: React.FC<FreeformSeatProps> = ({
  jobStageInterviewSeat,
  handleMouseOut,
  handleMouseOver,
  onEdit,
  seatIndex,
  showOptions,
  handleDelete,
  onInterviewerPreferenceChanged,
}) => {
  const sxProps = useSxProps();
  const gridSxProps = useGridSxProps();
  const avatarSxProps = useAvatarSxProps();

  if (!jobStageInterviewSeat.freeformSeat) return null;
  if (!jobStageInterviewSeat.employeeIds) return null;
  const employeeIdMap: { [key: string]: EmployeeFragment } = {};
  jobStageInterviewSeat.freeformSeat?.jobStageInterviewSeatEmployees?.forEach((emp) => {
    if (emp?.employee) {
      employeeIdMap[emp.employeeId] = emp.employee;
    }
  });

  const employeeDisabled = jobStageInterviewSeat.employeeIds.reduce((disabled, empId) => {
    return (
      disabled ||
      !employeeIdMap[empId]?.hasAtsId ||
      !employeeIdMap[empId] ||
      employeeIdMap[empId].isArchived ||
      employeeIdMap[empId].isAtsDisabled ||
      employeeIdMap[empId].isDirectoryDisabled
    );
  }, false);

  return (
    <Grid>
      <Box onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} sx={sxProps.box} onClick={onEdit}>
        <Grid container justifyContent="space-between" sx={gridSxProps.root} spacing={1} tabIndex={0} wrap="nowrap">
          <Grid xs={10}>
            <Grid
              container
              wrap="nowrap"
              alignItems={jobStageInterviewSeat.employeeIds.length > 1 ? 'baseline' : 'center'}
            >
              <Grid sx={sxProps.one}>
                <MuiAvatar sx={[avatarSxProps.root, employeeDisabled && sxProps.errorChip]}>{seatIndex + 1}</MuiAvatar>
              </Grid>

              <Grid>
                <Grid container direction="column">
                  {jobStageInterviewSeat.employeeIds && jobStageInterviewSeat?.employeeIds.length > 1 && (
                    <Grid sx={sxProps.one}>
                      <Typography>One of:</Typography>
                    </Grid>
                  )}
                  {jobStageInterviewSeat.employeeIds &&
                    jobStageInterviewSeat.employeeIds.map((id) => {
                      const preferenceLevel =
                        jobStageInterviewSeat.freeformSeat?.jobStageInterviewSeatEmployees?.find(
                          (e) => e.employeeId === id
                        )?.preferenceLevel || PREFERENCE_LEVEL_NON_PREFERRED;
                      return (
                        <Grid container key={id}>
                          <Tooltip
                            tooltip={
                              <Stack direction="column">
                                <Typography color="text.primary">{employeeIdMap[id].fullName}</Typography>
                                <Typography color="text.primary">{employeeIdMap[id].email}</Typography>
                              </Stack>
                            }
                          >
                            <Chip
                              sx={
                                !employeeIdMap[id]?.hasAtsId ||
                                employeeIdMap[id]?.isDirectoryDisabled ||
                                employeeIdMap[id]?.isAtsDisabled ||
                                employeeIdMap[id]?.isArchived
                                  ? sxProps.errorChip
                                  : undefined
                              }
                              label={employeeIdMap[id]?.fullName}
                              avatar={
                                <Avatar
                                  src={employeeIdMap[id]?.slackImageUrl || undefined}
                                  alt={employeeIdMap[id]?.fullName || ''}
                                />
                              }
                              variant={showOptions ? 'outlined' : 'default'}
                            />
                          </Tooltip>
                          <Tooltip
                            tooltip={
                              preferenceLevel === PREFERENCE_LEVEL_NON_PREFERRED
                                ? 'Mark as preferred'
                                : 'Mark as not preferred'
                            }
                            placement="right"
                          >
                            <IconButton
                              size="small"
                              onClick={(e: React.MouseEvent) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onInterviewerPreferenceChanged(
                                  id,
                                  preferenceLevel === PREFERENCE_LEVEL_NON_PREFERRED
                                    ? PREFERENCE_LEVEL_PREFERRED
                                    : PREFERENCE_LEVEL_NON_PREFERRED
                                );
                              }}
                            >
                              {preferenceLevel === PREFERENCE_LEVEL_NON_PREFERRED ? (
                                <StarIcon />
                              ) : (
                                <StarFilledIcon color="warning" />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" alignItems="center" xs sx={sxProps.options} wrap="nowrap">
            <Grid>
              <Tooltip tooltip="Edit">
                <IconButton sx={sxProps.actionButton} onClick={onEdit} size="small">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid>
              <Tooltip tooltip="Delete">
                <IconButton sx={sxProps.actionButton} onClick={handleDelete} size="small">
                  <CrossIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default FreeformSeat;
