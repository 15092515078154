import { differenceInMinutes, isAfter, isBefore, parseISO } from 'date-fns';
import { fromPairs, sortBy, toPairs } from 'lodash';

import { CandidateAvailabilityOptions } from 'src/store/slices/candidate-availability-options';

import { endOfDay as endOfDayTz, formatToTimeZone, startOfDay as startOfDayTz } from 'src/utils/dateUtils';
import { TimeRange } from 'src/utils/getTimePeriods';
import { mergeTimeRanges } from 'src/utils/timeRangeUtils';

export type CandidateAvailabilityByDate = { [key: string]: TimeRange[] };

export const getCandidateAvailabilityByDate = (
  rcEnteredAvailability: TimeRange[],
  candidateEnteredAvailability: TimeRange[],
  timezone: string,
  skipMerge = false // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
CandidateAvailabilityByDate => {
  const result: CandidateAvailabilityByDate = {};

  const mergeTimeRangesFn = skipMerge ? (value: TimeRange[]) => value : mergeTimeRanges;

  mergeTimeRangesFn(rcEnteredAvailability.concat(candidateEnteredAvailability)).forEach((availability) => {
    const key = formatToTimeZone(availability.start, 'yyyy-MM-dd', timezone);

    if (!result[key]) {
      result[key] = [];
    }

    result[key].push(availability);
  });

  // sort the result by date
  return fromPairs(sortBy(toPairs(result), 0));
};

export const isAvailabilitySufficient = (
  options: CandidateAvailabilityOptions,
  availabilities: TimeRange[],
  candidateTimezone: string,
  submittedAt?: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
boolean => {
  const availabilityByDate = getCandidateAvailabilityByDate(availabilities, [], candidateTimezone);
  if (options.numberOfDays > Object.keys(availabilityByDate).length) return false;

  /**
   * We want to make sure that if `options.numberOfDays` have availability with minutesPerDays and minimumTimeBlockMinutes
   * then we treat the availability as sufficient.
   */
  let numberOfDaysHaveMinutesPerDay = 0;
  let numberOfDaysHaveMinimumTimeBlockMinutes = 0;

  let hasAdvanceNoticeMinutes = true;
  let isAfterEndDate = false;
  let isBeforeStartDate = false;
  let eachDayHasMininumNumberOfMinutesPerDay = true;

  Object.keys(availabilityByDate).forEach((key) => {
    let minutesPerDays = 0;

    availabilityByDate[key].forEach((timeRange) => {
      const start = parseISO(timeRange.start);
      const end = parseISO(timeRange.end);
      const duration = differenceInMinutes(end, start);
      minutesPerDays += duration;

      if (duration >= options.minimumTimeBlockMinutes) {
        numberOfDaysHaveMinimumTimeBlockMinutes++;
      }

      const advanceDuration = differenceInMinutes(start, submittedAt ? parseISO(submittedAt) : Date.now());
      if (options.advanceNoticeMinutes && advanceDuration < options.advanceNoticeMinutes) {
        hasAdvanceNoticeMinutes = false;
      }

      if (
        options.availabilityStartDate &&
        isBefore(parseISO(timeRange.start), startOfDayTz(parseISO(options.availabilityStartDate), options.timezone))
      ) {
        isBeforeStartDate = true;
      }

      if (
        options.availabilityEndDate &&
        isAfter(parseISO(timeRange.end), endOfDayTz(parseISO(options.availabilityEndDate), options.timezone))
      ) {
        isAfterEndDate = true;
      }
    });

    if (minutesPerDays >= options.minutesPerDays) {
      numberOfDaysHaveMinutesPerDay++;
    } else {
      eachDayHasMininumNumberOfMinutesPerDay = false;
    }
  });

  return (
    ((options.numberOfDays !== -1 && numberOfDaysHaveMinutesPerDay >= options.numberOfDays) ||
      (options.numberOfDays === -1 && eachDayHasMininumNumberOfMinutesPerDay)) &&
    numberOfDaysHaveMinimumTimeBlockMinutes >= options.numberOfDays &&
    hasAdvanceNoticeMinutes &&
    !isBeforeStartDate &&
    !isAfterEndDate
  );
};
