import { SvgIcon, SvgIconProps } from './SvgIcon';

export const DocumentIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75003 4C4.75003 3.86193 4.86196 3.75 5.00003 3.75H11.5315C11.5109 3.82991 11.5 3.91368 11.5 4V6C11.5 7.10457 12.3954 8 13.5 8H15C15.0863 8 15.1701 7.98906 15.25 7.96849V16C15.25 16.1381 15.1381 16.25 15 16.25H5.00003C4.86196 16.25 4.75003 16.1381 4.75003 16V4ZM5.00003 2.25C4.03353 2.25 3.25003 3.0335 3.25003 4V16C3.25003 16.9665 4.03353 17.75 5.00003 17.75H15C15.9665 17.75 16.75 16.9665 16.75 16V6.85633C16.75 6.45303 16.6107 6.0621 16.3557 5.74967L14.024 2.89334C13.6916 2.48621 13.1939 2.25 12.6683 2.25H5.00003ZM6.25 11C6.25 10.5858 6.58579 10.25 7 10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H7C6.58579 11.75 6.25 11.4142 6.25 11ZM7 13.25C6.58579 13.25 6.25 13.5858 6.25 14C6.25 14.4142 6.58579 14.75 7 14.75H13C13.4142 14.75 13.75 14.4142 13.75 14C13.75 13.5858 13.4142 13.25 13 13.25H7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
