import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { MeetingRoomIcon } from '@modernloop/shared/icons';
import { Box, Stack, Typography } from '@mui/material';

import {
  ResponseStatus,
  ScheduleContentInterviewDetailsMeetingRoom_MeetingRoomFragment,
} from 'src/generated/mloop-graphql';

import getResponseStatusNode from 'src/entities/common/getResponseStatusNode';

type Fragments = {
  meetingRoom: ScheduleContentInterviewDetailsMeetingRoom_MeetingRoomFragment;
};

const ScheduleContentInterviewDetailsMeetingRoom: FCWithFragments<Fragments> = ({ meetingRoom }) => {
  return (
    <Stack direction="row" justifyContent="space-between" spacing={1} data-testid="schedule-content-meeting-room">
      <Stack direction="row" alignItems="center" spacing={1}>
        <MeetingRoomIcon color="action" />
        <Typography data-testid="schedule-content-meeting-room-description" variant="body2">
          {meetingRoom.meetingRoom?.description}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {getResponseStatusNode(meetingRoom.responseStatus || ResponseStatus.NeedsAction)}
        <Box width={32} height={32} />
      </Stack>
    </Stack>
  );
};

ScheduleContentInterviewDetailsMeetingRoom.fragments = {
  meetingRoom: gql`
    fragment ScheduleContentInterviewDetailsMeetingRoom_meetingRoom on ApplicationStageInterviewRoom {
      applicationStageInterviewId
      meetingRoomId
      responseStatus
      meetingRoom {
        id
        name
        description
      }
    }
  `,
};

export default ScheduleContentInterviewDetailsMeetingRoom;
