/**
 * Color constants
 * FIGMA → https://www.figma.com/file/Yq6Wgf9c3VvcNAAu25KYAZ/Styles?node-id=54%3A42&viewport=-2100%2C289%2C1.5428613424301147
 */
// eslint-disable-next-line no-restricted-imports, modernloop/restrict-imports.cjs
import { blue, whitescale, blackscale, red, green, neutral, orange } from '../../colors';
import { ThemeOptions, alpha } from '@mui/material';

export const palette: ThemeOptions['palette'] = {
  mode: 'light',
  borderState: {
    focus: blue[60],
    focusShadow: alpha(blue[80], 0.8),
    // This always matches theme.palette.error.main
    error: red[40],
    default: blackscale[90], // should be the same as divider
    subtle: neutral[95],
  },
  common: {
    white: whitescale[0],
    black: blackscale[0],
  },
  divider: blackscale[90],
  text: {
    primary: blackscale[10],
    secondary: blackscale[50],
    disabled: blackscale[70],
  },
  // These seems to map to "default"
  action: {
    active: blackscale[50],
    focus: blackscale[90],
    selected: blackscale[95],
    hover: blackscale[98],
    disabled: blackscale[70],
    disabledBackground: blackscale[98],
  },
  background: {
    default: neutral[100],
    paper: neutral[100],
    contrast: neutral[95],
    navigation: alpha(neutral[95], 0.7), // should be the same as background.contrast but lightened to 70%
    paperHeader: alpha(neutral[95], 0.5), // should be the same as background.contrast but lightened to 50%
    paperFooter: alpha(neutral[95], 0.3), // should be the same as background.contrast but lightened to 30%
    elevation: {
      one: neutral[98], // Reserved for hover states.
      two: neutral[99], // Drawers, menus, rich tooltips, floating cards.
      three: neutral[100], // snackbars, floating actionbars (FAB)
      four: neutral[100], // Maybe hover action on FAB's
      five: neutral[100], // Dialogs.
    },
  },
  primary: {
    tint: alpha(blue[80], 0.2),
    light: blue[50],
    main: blue[40],
    dark: blue[30],
    contrastText: blue[100],
  },
  secondary: {
    tint: alpha(neutral[80], 0.2),
    light: neutral[99],
    main: neutral[97],
    dark: neutral[90],
    contrastText: neutral[20],
  },
  success: {
    tint: alpha(green[80], 0.2),
    light: green[50],
    main: green[40],
    dark: green[30],
    contrastText: green[100],
  },
  info: {
    tint: alpha(blue[80], 0.2),
    light: blue[95],
    main: blue[90],
    dark: blue[70],
    contrastText: blue[10],
  },
  warning: {
    tint: alpha(orange[80], 0.2),
    light: orange[70],
    main: orange[60],
    dark: orange[50],
    contrastText: orange[100],
  },
  error: {
    tint: alpha(red[80], 0.2),
    light: red[50],
    main: red[40],
    dark: red[30],
    contrastText: red[100],
  },
};
