/* eslint-disable max-lines */
import React, { useMemo, useState } from 'react';
import { DragDropContext, DropResult, Droppable, DroppableProvided } from 'react-beautiful-dnd';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { PlusIcon } from '@modernloop/shared/icons';
import { Box, Button, CircularProgress, Paper, Stack, Theme, Typography } from '@mui/material';
import { clone, difference } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  AtsInterviewDefinitionFragment,
  DateTimeRangeInput,
  DurationInput,
  InterviewGroup_JobStageInterviewGroupFragment,
  InterviewGroup_JobStageInterviewsFragment,
  InterviewPlan_JobFragment,
  InterviewPlan_JobStageFragment,
  InterviewType,
  InterviewTypePrefType,
  JobStageInterview_JobStageInterviewFragment,
} from 'src/generated/mloop-graphql';

import { OrgPrefName, useOrgPrefString } from 'src/hooks/api/org';
import { getAtsInterviewDefinitionFields } from 'src/hooks/atsService/util';

// eslint-disable-next-line modernloop/restrict-imports.cjs
import AtsScorecardModal from 'src/views-new/Scorecards/AtsScorecardModal';

import { DEFAULT_INTERVIEW_NAME } from 'src/strings';

import AdvancedOptions from './AdvancedOptions';
import InterviewGroup from './InterviewGroup';

type Props = {
  jobStageLoading?: boolean;
  schedulable: boolean;
  readonly?: boolean;
  candidateAvailabilities?: DateTimeRangeInput[];
  footer?: React.ReactNode;
  onUpdated: (jobStage: InterviewPlan_JobStageFragment) => void;
  onResetToJobStageDefault?: () => void;
};

type Fragments = {
  job: InterviewPlan_JobFragment | undefined;
  jobStage: InterviewPlan_JobStageFragment | undefined;
};

const useSxProps = () => {
  return useMemo(() => {
    return {
      noInterviewPlanWrapper: {
        display: 'flex',
        height: '150px',
        backgroundColor: (theme: Theme) => theme.grey.alpha.min,
        alignItems: 'center',
        justifyContent: 'center',
        margin: (theme: Theme) => `0 ${theme.spacing(3)}`,
      },
    };
  }, []);
};

export const InterviewPlan: FCWithFragments<Fragments, Props> = ({
  job,
  jobStage,
  jobStageLoading,
  schedulable,
  readonly,
  candidateAvailabilities,
  footer,
  onUpdated,
  onResetToJobStageDefault,
}): JSX.Element | null => {
  const sxProps = useSxProps();

  const resetStagePlanEnabled = useFlag('user_reset_to_stage_plan');
  const orgBreakImprovementsEnabled = useFlag('org_break_improvements');
  const [showScorecardModal, setShowScorecardModal] = useState(false);

  const jobStageId = jobStage?.id || '';

  const [defaultInterviewPlanBreakType, { loading: loadingDefaultInterviewPlanBreakType }] = useOrgPrefString(
    OrgPrefName.STR_DEFAULT_INTERVIEW_PLAN_BREAK_TYPE
  );

  const interviewPlanBreakType = [InterviewTypePrefType.AnyTimeLater, InterviewTypePrefType.ImmediatelyAfter].includes(
    defaultInterviewPlanBreakType as InterviewTypePrefType
  )
    ? (defaultInterviewPlanBreakType as InterviewTypePrefType)
    : InterviewTypePrefType.AnyTimeLater;

  const handleAddInterview = () => {
    setShowScorecardModal(true);
  };

  const handleSelectScorecard = (scorecard: AtsInterviewDefinitionFragment) => {
    if (!jobStage) return;

    const interview: JobStageInterview_JobStageInterviewFragment = {
      id: uuid(),
      name: scorecard.name || DEFAULT_INTERVIEW_NAME,
      useAtsName: true,
      interviewType: InterviewType.Interview,
      isLockedOrder: false,
      isHiddenFromCandidate: false,
      atsInterviewDefinition: {
        atsId: scorecard.atsId,
        atsJobId: scorecard.atsJobId,
        name: scorecard.name || DEFAULT_INTERVIEW_NAME,
      },
      useAtsDuration: Boolean(getAtsInterviewDefinitionFields(scorecard.atsFields || undefined, 'estimatedMinutes')),
      duration: getAtsInterviewDefinitionFields(scorecard.atsFields || undefined, 'estimatedMinutes') || 45,
    };

    const interviewGroup: InterviewGroup_JobStageInterviewGroupFragment = {
      id: uuid(),
      locked: false,
      jobStageInterviews: [interview],
    };

    if (orgBreakImprovementsEnabled) {
      const breakSlot: JobStageInterview_JobStageInterviewFragment = {
        id: uuid(),
        isLockedOrder: true,
        name: 'Break',
        useAtsName: false,
        interviewType:
          interviewPlanBreakType === InterviewTypePrefType.AnyTimeLater
            ? InterviewType.AnyTimeLater
            : InterviewType.Break,
        duration: 0,
        useAtsDuration: false,
        isHiddenFromCandidate: false,
      };

      interviewGroup.jobStageInterviews?.push(breakSlot);
    }

    onUpdated({
      ...jobStage,
      jobStageInterviewGroups: [...(jobStage?.jobStageInterviewGroups || []), interviewGroup],
    });
  };

  const handleAddBreak = () => {
    if (!jobStage) return;

    const breakInterview: JobStageInterview_JobStageInterviewFragment = {
      id: uuid(),
      name: 'Break',
      useAtsName: false,
      interviewType: InterviewType.Break,
      isLockedOrder: false,
      isHiddenFromCandidate: false,
      useAtsDuration: false,
      duration: 45,
    };

    const breakGroup: InterviewGroup_JobStageInterviewGroupFragment = {
      id: uuid(),
      locked: true, // Break groups are always locked while adding
      jobStageInterviews: [breakInterview],
    };

    onUpdated({
      ...jobStage,
      jobStageInterviewGroups: [...(jobStage?.jobStageInterviewGroups || []), breakGroup],
    });
  };

  const handleScorecardSelectClose = () => {
    setShowScorecardModal(false);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !jobStage?.jobStageInterviewGroups) return;

    const groupIndex = jobStage.jobStageInterviewGroups.findIndex((group) => group.id === result.draggableId);

    if (!groupIndex || groupIndex === -1) return;
    const newJobStageInterviewGroups = Array.from(jobStage.jobStageInterviewGroups || []);

    const group = newJobStageInterviewGroups.splice(groupIndex, 1) || [];
    newJobStageInterviewGroups.splice(result.destination.index, 0, ...group);

    onUpdated({
      ...jobStage,
      jobStageInterviewGroups: newJobStageInterviewGroups,
    });
  };

  const handleMoveJobStageInterviewGroup = (groupId: string, position: number) => {
    if (!jobStage?.jobStageInterviewGroups) return;

    const groupIndex = jobStage.jobStageInterviewGroups.findIndex((group) => group.id === groupId);
    if (groupIndex === undefined || groupIndex === -1) return;

    const newJobStageInterviewGroups = Array.from(jobStage.jobStageInterviewGroups || []);

    const group = newJobStageInterviewGroups.splice(groupIndex, 1) || [];
    newJobStageInterviewGroups.splice(position, 0, ...group);

    onUpdated({
      ...jobStage,
      jobStageInterviewGroups: newJobStageInterviewGroups,
    });
  };

  const handleGroupJobStageInterviewGroup = (sourceGroupIndex: number, destGroupIndex: number) => {
    if (!jobStage?.jobStageInterviewGroups) return;

    // We want to keep visual order while grouping interviews so smaller index is always destination and delete the source.
    // Other way around will work too 🙂.
    let sourceIndex = sourceGroupIndex;
    let destIndex = destGroupIndex;

    if (sourceGroupIndex < destGroupIndex) {
      sourceIndex = destGroupIndex;
      destIndex = sourceGroupIndex;
    }

    const sourceGroup = jobStage.jobStageInterviewGroups[sourceIndex];
    const destGroup = jobStage.jobStageInterviewGroups[destIndex];

    if (!sourceGroup || !destGroup) return;

    const destGroupInterviews = [...(destGroup.jobStageInterviews || [])];
    sourceGroup.jobStageInterviews?.forEach((interview) => {
      destGroupInterviews.push(interview);
    });

    onUpdated({
      ...jobStage,
      jobStageInterviewGroups: [...jobStage.jobStageInterviewGroups?.filter((g) => g.id !== sourceGroup.id)].map(
        (g) => {
          if (g.id === destGroup.id) {
            return { ...destGroup, jobStageInterviews: destGroupInterviews };
          }
          return g;
        }
      ),
    });
  };

  const handleUngroupJobStageInterviewGroup = (groupId: string) => {
    if (!jobStage || !jobStage.jobStageInterviewGroups) return;

    const groupIndex = jobStage.jobStageInterviewGroups.findIndex((group) => group.id === groupId);
    if (groupIndex === undefined || groupIndex === -1) return;

    const newGroups: InterviewGroup_JobStageInterviewGroupFragment[] = [];

    const group = jobStage.jobStageInterviewGroups[groupIndex];

    if (!orgBreakImprovementsEnabled) {
      group.jobStageInterviews?.forEach((interview) => {
        if (!interview) return;

        newGroups.push({
          id: uuid(),
          locked: false,
          jobStageInterviews: [interview],
        });
      });
    } else {
      const interviews = [...(group.jobStageInterviews || [])];
      while (interviews.length > 0) {
        const interviewWithBreak = interviews.splice(0, 2);
        if (interviewWithBreak.length !== 2) return;

        newGroups.push({
          id: uuid(),
          // Match the locked order of the interviewer to the group.
          locked: interviewWithBreak[0].isLockedOrder,
          jobStageInterviews: interviewWithBreak,
        });
      }
    }

    const resultGroups = [...jobStage.jobStageInterviewGroups];
    resultGroups.splice(groupIndex, 1, ...newGroups);
    onUpdated({
      ...jobStage,
      jobStageInterviewGroups: resultGroups,
    });
  };

  if (jobStageLoading) {
    return (
      <Box mt={4}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  const jobStageInterviewGroups = jobStage?.jobStageInterviewGroups?.flat() || [];

  const jobStageInterviews =
    jobStage?.jobStageInterviewGroups
      ?.map((group) => group.jobStageInterviews as InterviewGroup_JobStageInterviewsFragment[])
      .flat() || [];

  const addInterviewAndRestToJobStageDefault = (
    <Box m="0 auto" position="relative">
      <Button
        variant="contained"
        size="medium"
        disabled={loadingDefaultInterviewPlanBreakType}
        startIcon={<PlusIcon />}
        onClick={handleAddInterview}
      >
        Add interview
      </Button>
    </Box>
  );

  const left = (
    <Stack spacing={2} flexWrap="nowrap">
      {jobStageInterviewGroups.length === 0 && (
        <Paper variant="outlined" sx={sxProps.noInterviewPlanWrapper}>
          <Stack alignItems="center" gap={1}>
            <Typography color="theme.secondary" variant="body2" fontWeight={500}>
              Add an interview to start building your candidate&apos;s custom interview plan.
            </Typography>
            {orgBreakImprovementsEnabled && addInterviewAndRestToJobStageDefault}
          </Stack>
        </Paper>
      )}

      {jobStageInterviewGroups.length > 0 && job && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical" isDropDisabled={readonly}>
            {(droppableProvided: DroppableProvided) => (
              <Stack
                spacing={orgBreakImprovementsEnabled ? 0 : 2}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {jobStage?.jobStageInterviewGroups?.map((group, i) => {
                  return (
                    <InterviewGroup
                      job={job}
                      jobStageInterviewGroup={group}
                      jobStageInterviewGroups={jobStageInterviewGroups}
                      jobStageInterviews={jobStageInterviews}
                      count={jobStageInterviewGroups.length}
                      key={group.id}
                      index={i}
                      schedulable={schedulable}
                      readonly={readonly}
                      candidateAvailabilities={candidateAvailabilities}
                      onUpdated={(updatedGroup) => {
                        const newSeatIds =
                          updatedGroup.jobStageInterviews
                            ?.map((interview) => interview.jobStageInterviewSeats?.map((seat) => seat.id).flat() || [])
                            .flat() || [];

                        const oldSeatIds =
                          group.jobStageInterviews
                            ?.map((interview) => interview.jobStageInterviewSeats?.map((seat) => seat.id).flat() || [])
                            .flat() || [];

                        const deletedSeatIds = difference(oldSeatIds, newSeatIds);

                        const updatedJobStage: InterviewPlan_JobStageFragment = {
                          ...jobStage,
                          jobStageInterviewGroups: jobStageInterviewGroups.map((g) => {
                            if (!deletedSeatIds.length) {
                              if (g.id === updatedGroup.id) return updatedGroup;
                              return g;
                            }

                            const groupClone = g.id === updatedGroup.id ? clone(updatedGroup) : clone(g);
                            groupClone.jobStageInterviews = groupClone.jobStageInterviews?.map((interview) => {
                              interview.jobStageInterviewSeats = interview.jobStageInterviewSeats?.filter((seat) => {
                                if (!seat.linkedSeat) return true;
                                return !deletedSeatIds.includes(seat.linkedSeat.linkedJobStageInterviewSeatId);
                              });
                              return interview;
                            });
                            return groupClone;
                          }),
                        };
                        onUpdated(updatedJobStage);
                      }}
                      onDelete={(jobStageInterviewGroupId) => {
                        const deletedSeatIds =
                          jobStageInterviewGroups
                            .find((g) => g.id === jobStageInterviewGroupId)
                            ?.jobStageInterviews?.map((interview) =>
                              interview.jobStageInterviewSeats?.map((seat) => seat.id).flat()
                            )
                            .flat() || [];

                        onUpdated({
                          ...jobStage,
                          jobStageInterviewGroups: jobStageInterviewGroups
                            .filter((g) => g.id !== jobStageInterviewGroupId)
                            .map((g) => {
                              const groupClone = clone(g);
                              groupClone.jobStageInterviews = groupClone.jobStageInterviews?.map((interview) => {
                                interview.jobStageInterviewSeats = interview.jobStageInterviewSeats?.filter((seat) => {
                                  if (!seat.linkedSeat) return true;
                                  return !deletedSeatIds.includes(seat.linkedSeat.linkedJobStageInterviewSeatId);
                                });
                                return interview;
                              });
                              return groupClone;
                            }),
                        });
                      }}
                      onMoveJobStageInterviewGroup={handleMoveJobStageInterviewGroup}
                      onGroupJobStageInterviewGroup={handleGroupJobStageInterviewGroup}
                      onUngroupJobStageInterviewGroup={handleUngroupJobStageInterviewGroup}
                    />
                  );
                })}
                {!readonly && orgBreakImprovementsEnabled && addInterviewAndRestToJobStageDefault}
                {droppableProvided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      )}

      {!readonly && !orgBreakImprovementsEnabled && (
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack direction="row" spacing={2}>
              <Button variant="contained" size="medium" onClick={() => handleAddInterview()}>
                Add interview
              </Button>
              <Button size="medium" onClick={handleAddBreak}>
                Add break
              </Button>
            </Stack>
            {onResetToJobStageDefault && resetStagePlanEnabled && (
              <Box>
                <Button
                  variant="text"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    onResetToJobStageDefault();
                  }}
                >
                  Reset to job stage default
                </Button>
              </Box>
            )}
          </Stack>
        </Stack>
      )}

      <AdvancedOptions
        readonly={readonly}
        interviewPlan={jobStage}
        onSchedulingWindowChange={(duration: DurationInput) => {
          if (!jobStage) return;
          onUpdated({ ...jobStage, schedulingWindow: { seconds: duration.seconds } });
        }}
        onExcludedInterviewerChange={(excludedEmployees) => {
          if (!jobStage) return;
          onUpdated({ ...jobStage, excludedEmployees });
        }}
        onResetToJobStageDefault={onResetToJobStageDefault}
      />

      {footer}
    </Stack>
  );

  return (
    <>
      {left}
      {showScorecardModal ? (
        <AtsScorecardModal
          atsJobId={job?.atsId}
          jobStageId={jobStageId}
          onClose={handleScorecardSelectClose}
          onSelectScorecard={handleSelectScorecard}
        />
      ) : null}
    </>
  );
};

InterviewPlan.fragments = {
  job: gql`
    ${InterviewGroup.fragments.job}
    fragment InterviewPlan_job on Job {
      id
      atsId
      ...InterviewGroup_job
    }
  `,
  jobStage: gql`
    ${AdvancedOptions.fragments.interviewPlan}
    ${InterviewGroup.fragments.jobStageInterviewGroup}
    ${InterviewGroup.fragments.jobStageInterviews}
    ${InterviewGroup.fragments.jobStageInterviewGroups}

    fragment InterviewPlan_jobStage on JobStage {
      id
      jobStageInterviewGroups {
        id
        ...InterviewGroup_jobStageInterviewGroup
        ...InterviewGroup_jobStageInterviewGroups
        jobStageInterviews {
          id
          ...InterviewGroup_jobStageInterviews
        }
      }
      ...AdvancedOptions_interviewPlan
    }
  `,
};

export default InterviewPlan;
