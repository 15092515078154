import React from 'react';

import { gql } from '@apollo/client';
import { TraineePendingApprovalIcon } from '@modernloop/shared/icons';
import { Alert, AlertTitle } from '@mui/material';

import { PendingApprovalAlert_ModuleMemberFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

type Fragments = {
  moduleMember: PendingApprovalAlert_ModuleMemberFragment;
};

const PendingApprovalAlert: FCWithFragments<Fragments> = ({ moduleMember }) => {
  let totalFeedbackExpected = 0;
  let totalFeedbackRecieved = 0;

  if (moduleMember.pendingApprovalApplicationStageInterview?.traineeFeedback?.reviewerId) {
    totalFeedbackExpected++;
  }
  if (moduleMember.pendingApprovalApplicationStageInterview?.traineeFeedback?.reviewedAt) {
    totalFeedbackRecieved++;
  }
  if (moduleMember.pendingApprovalApplicationStageInterview?.trainedFeedback?.reviewerId) {
    totalFeedbackExpected++;
  }
  if (moduleMember.pendingApprovalApplicationStageInterview?.trainedFeedback?.reviewedAt) {
    totalFeedbackRecieved++;
  }

  const getApprovalText = () => {
    if (!totalFeedbackExpected) {
      return 'Manual approval required';
    }
    const pendingFeedbackCount = totalFeedbackExpected - totalFeedbackRecieved;
    return `Awaiting feedback from ${pendingFeedbackCount} ${pendingFeedbackCount <= 1 ? 'person' : 'people'}`;
  };

  return (
    <Alert severity="pending" color="warning" variant="filled" icon={<TraineePendingApprovalIcon color="inherit" />}>
      <AlertTitle>Pending approval</AlertTitle>
      {getApprovalText()}
    </Alert>
  );
};

PendingApprovalAlert.fragments = {
  moduleMember: gql`
    fragment PendingApprovalAlert_moduleMember on InterviewModuleMember {
      id
      employeeId
      interviewModuleId
      pendingApprovalApplicationStageInterview {
        id
        traineeFeedback: traineeFeedback(reviewerType: TRAINEE) {
          reviewerId
          reviewedAt
        }
        trainedFeedback: traineeFeedback(reviewerType: TRAINED) {
          reviewerId
          reviewedAt
        }
      }
    }
  `,
};

export default PendingApprovalAlert;
