import { defaultGetOptionSelected } from './defaultRenderers';
import FilterList, { Props as FilterListProps } from './index';
import { BaseProps } from '@modernloop/shared/components';
import { Select, AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';

const DEFAULT_ITEM_HEIGHT = 32;
const NUMBER_OF_ITEMS = 10;
// This includes the height of search bar (40px) and 8px padding top and bottom
const SEARCH_BAR_HEIGHT_WITH_PADDING = 56;

export type Props<TData> = BaseProps &
  FilterListProps<TData> & {
    disabled?: boolean;
    single?: boolean; // If true, only one item can be selected and we will close the menu onChange
    // Function to return innner content of the select.
    getLabel?: () => string | React.ReactNode;
    onClose?: () => void;
    error?: boolean;
  };

const FilterSelect = <TData,>({
  single = false,
  getLabel,
  onClose,
  error,
  ...filterListProps
}: PropsWithChildren<Props<TData>>): JSX.Element => {
  const [filterOpen, setFilterOpen] = useState(false);
  const { options, onChange, isOptionEqualToValue, ...rest } = filterListProps;

  const handleChange = (
    event: React.SyntheticEvent,
    values: TData[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<TData> | undefined
    // eslint-disable-next-line max-params
  ) => {
    if (single) {
      setFilterOpen(false);
    }
    onChange?.(event, values, reason, details);
  };

  const getListHeight = () => {
    if (options.length > NUMBER_OF_ITEMS) {
      return NUMBER_OF_ITEMS * DEFAULT_ITEM_HEIGHT;
    }
    return options.length * DEFAULT_ITEM_HEIGHT + SEARCH_BAR_HEIGHT_WITH_PADDING;
  };

  return (
    <Select
      disabled={rest.disabled}
      open={filterOpen}
      value=""
      error={error}
      onOpen={() => {
        setFilterOpen(true);
      }}
      onClose={() => {
        onClose?.();
        setFilterOpen(false);
      }}
      displayEmpty
      fullWidth
      renderValue={(value) => {
        if (getLabel) {
          return getLabel();
        }
        return value;
      }}
    >
      <FilterList
        options={options}
        autoFocus
        getListHeight={getListHeight}
        onChange={handleChange}
        isOptionEqualToValue={isOptionEqualToValue || defaultGetOptionSelected}
        {...rest}
      />
    </Select>
  );
};

export default FilterSelect;
