import React, { useCallback } from 'react';

import Drawer from 'src/components/Drawer';

import { SidePanelType } from 'src/hooks/useUrlSearchParams';

import DiscardNoteConfirmationDialog from 'src/views-new/sidepanel/common/DiscardNoteConfirmationDialog';

import InterviewModuleSidepanel from './InterviewModuleMember';
import ScheduleTaskSidePanel from './ScheduleTask';
import { SidePanelManager, SidePanelProvider, useSidePanelState } from './common/SidePanelManager';
import { useSidePanelUrlData } from './common/useSidePanelUrlData';

/**
 * SidePanel is responsible for showing details for Schedule task.
 * This component can be extended to add support for more items which need to be opened in side panel.
 * For e.g. we can show activity log for a task or candidate in side panel.
 *
 * Note: Do not use this component to show details in side panel when the data shown is tightly coupled with the current page.
 * E.g. When showing activity log for settings page, we should not use this component.
 *      Instead show the Drawer component in the settings page itself.
 */
const SidePanelBase = () => {
  const sidePanelState = useSidePanelState();
  const sidePanelUrlData = useSidePanelUrlData();

  const sidePanelData = sidePanelUrlData || sidePanelState;

  const hideSidePanelClose = useCallback(() => {
    SidePanelManager.closeSidePanel();
  }, []);

  let jsx = <></>;

  if (sidePanelData?.data?.type === SidePanelType.ScheduleTask) {
    jsx = (
      <ScheduleTaskSidePanel
        scheduleTaskId={sidePanelData.data.scheduleTaskId}
        showRequirements={sidePanelData.data.showRequirements}
      />
    );
  } else if (sidePanelData?.data?.type === SidePanelType.InterviewModuleMember) {
    jsx = (
      <InterviewModuleSidepanel
        moduleMemberId={sidePanelData.data.moduleMemberId}
        interviewModuleId={sidePanelData?.data.interviewModuleId}
      />
    );
  }

  return (
    <Drawer open={Boolean(sidePanelData.data)} onClose={hideSidePanelClose}>
      {jsx}
      {sidePanelState.showUnsavedChangesDialog && sidePanelState.unsavedChangesConfig.notes && (
        <DiscardNoteConfirmationDialog
          onClose={() => {
            SidePanelManager.setShowUnsavedChangesDialog(false);
          }}
          onDiscard={() => {
            SidePanelManager.setHasUnsavedNoteChanges(false);
            SidePanelManager.setShowUnsavedChangesDialog(false);
            SidePanelManager.closeSidePanel();
          }}
        />
      )}
    </Drawer>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const SidePanel = () => {
  return (
    <SidePanelProvider>
      <SidePanelBase />
    </SidePanelProvider>
  );
};

export default SidePanel;
