import { useCallback } from 'react';

import { gql } from '@apollo/client';
import { max, min } from 'lodash';

import {
  InterviewMeetingLocationType,
  MeetingLocationCreateInput,
  useCustomInterviewMeetingLocationCreateMutation,
} from 'src/generated/mloop-graphql';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import {
  updateCustomVideoMeetingLink,
  updateCustomVideoMeetingLinkUrlBySlotId,
  updateInterviewMeetingLocationId,
  updateinterviewMeetingLocationIdBySlotId,
} from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import { useDispatch } from 'src/store';

type Props = {
  scheduleId: string;
  interviewMeetingLocationType?: InterviewMeetingLocationType;
};

const usePopulateCustomInterviewMeetingLocation = ({ scheduleId, interviewMeetingLocationType }: Props) => {
  const dispatch = useDispatch();
  const schedule = useScheduleWithoutBreaks(scheduleId);

  const [interviewMeetingLocationCreateMutation] = useCustomInterviewMeetingLocationCreateMutation();

  const callback = useCallback(
    async ({ slotId, customLocation }: { customLocation: string; slotId?: string }) => {
      if (!interviewMeetingLocationType) return;
      // If slotId is provided, we are creating a meeting for a specific interview
      if (slotId) {
        const interviewEvent = schedule?.events.find((event) => event.slotId === slotId);
        if (!interviewEvent) return;

        const meetingLocationCreateInput: MeetingLocationCreateInput = {
          locationType: interviewMeetingLocationType,
          videoMeeting: {
            timeRange: { startAt: interviewEvent.startAt, endAt: interviewEvent.endAt },
            title: interviewEvent.name,
          },
          customLocation,
        };

        const { data } = await interviewMeetingLocationCreateMutation({
          variables: {
            input: meetingLocationCreateInput,
          },
        });

        const meetingLocation = data?.meetingLocationCreate?.meetingLocation;
        if (meetingLocation?.videoMeetingLink || meetingLocation?.details?.customLocation) {
          dispatch(
            updateinterviewMeetingLocationIdBySlotId({ scheduleId, slotId, meetingLocationId: meetingLocation.id })
          );

          dispatch(
            updateCustomVideoMeetingLinkUrlBySlotId(
              scheduleId,
              meetingLocation?.videoMeetingLink || meetingLocation?.details?.customLocation || '',
              slotId
            )
          );

          dispatch(
            addInterviewScheduleUpdate({
              type: 'ScheduleUpdateVideoUrl',
              updateType: ScheduleUpdateType.EDIT,
              scheduleId,
              applicationStageInterviewId: interviewEvent.id,
            })
          );
        }
      } else if (schedule) {
        // If slotId is not provided, we are creating a meeting for the whole schedule
        const startAt = min(schedule.events.map((event) => event.startAt));
        const endAt = max(schedule.events.map((event) => event.endAt));

        const meetingLocationCreateInput: MeetingLocationCreateInput = {
          locationType: interviewMeetingLocationType,
          videoMeeting: { timeRange: { startAt, endAt } },
          customLocation,
        };

        const { data } = await interviewMeetingLocationCreateMutation({
          variables: {
            input: meetingLocationCreateInput,
          },
        });

        const meetingLocation = data?.meetingLocationCreate?.meetingLocation;
        if (meetingLocation?.videoMeetingLink || meetingLocation?.details?.customLocation) {
          dispatch(updateInterviewMeetingLocationId(scheduleId, meetingLocation.id));
          dispatch(
            updateCustomVideoMeetingLink(
              scheduleId,
              meetingLocation?.videoMeetingLink || meetingLocation.details?.customLocation || ''
            )
          );

          schedule.events.forEach((interview) => {
            dispatch(
              addInterviewScheduleUpdate({
                type: 'ScheduleUpdateVideoUrl',
                updateType: ScheduleUpdateType.EDIT,
                scheduleId,
                applicationStageInterviewId: interview.id,
              })
            );
          });
        }
      }
    },
    [dispatch, interviewMeetingLocationCreateMutation, interviewMeetingLocationType, schedule, scheduleId]
  );

  return callback;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InterviewMeetingLocationCreateMutation = gql`
  mutation CustomInterviewMeetingLocationCreate($input: MeetingLocationCreateInput!) {
    meetingLocationCreate(input: $input) {
      meetingLocation {
        id
        type
        videoMeetingLink
        details {
          customLocation
        }
      }
    }
  }
`;

export default usePopulateCustomInterviewMeetingLocation;
