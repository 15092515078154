import { alpha } from '@mui/material';

export const blackscale = {
  0: '#000000',
  10: '#181C22',
  20: alpha('#181C22', 0.91),
  25: alpha('#181C22', 0.87),
  30: alpha('#181C22', 0.82),
  35: alpha('#181C22', 0.76),
  40: alpha('#181C22', 0.71),
  50: alpha('#181C22', 0.6),
  60: alpha('#181C22', 0.49),
  70: alpha('#181C22', 0.38),
  80: alpha('#181C22', 0.26),
  90: alpha('#181C22', 0.13),
  95: alpha('#181C22', 0.08),
  98: alpha('#181C22', 0.04),
  99: alpha('#181C22', 0.02),
  100: alpha('#181C22', 0),
};
