import { IS_PRODUCTION } from 'src/constants';

import { useFlag } from '.';

/**
 * Returns true if the user is a member of ModernLoop the company
 * A separate version of this hook is required for each app because of the dependency on env variables for IS_PRODUCTION
 */
export const useIsMLoopInternalOnly = () => {
  const hasMLOnlyFlag = useFlag('MLOnly');
  return !IS_PRODUCTION || hasMLOnlyFlag;
};
