import { useCallback } from 'react';

import { gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';
import { cloneDeep } from 'lodash';

import {
  RemoteAttachmentInput,
  SelfScheduleOptionsUpsertInput,
  useSelfScheduleOptionsUpsertMutation,
  useSelfScheduleTaskCreateMutation,
} from 'src/generated/mloop-graphql';

import { useCreateLocationHeader } from 'src/hooks/amplitude/useCreateLocationHeader';
import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useAuth from 'src/hooks/useAuth';
import useGenericOnError from 'src/hooks/useGenericOnError';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import { FileUploadFlow } from 'src/slices/files';

import { getFilesByUploadFlow } from 'src/store/selectors/files';

import { TemplateInterface } from 'src/views-new/Settings/TemplateComposer/TemplateInterface';

import { useSelector } from 'src/store';

import useRefetchTaskListQuery from '../ApplicationDetailsTaskView/ApplicationContent/useRefetchTaskList';
import { TaskCreateInputWrapper, getTaskCreateInput } from '../ScheduleTask/CreateModals/shared/types';

export const CREATE_TASK_MUTATION = gql`
  mutation SelfScheduleTaskCreate($input: TaskCreateInput!) {
    taskCreate(input: $input) {
      task {
        id
      }
    }
  }
`;

// TODO: Remove unused values
const useSelfScheduleOptionsUpsert = (
  selfScheduleOptions: SelfScheduleOptionsUpsertInput,
  taskId?: string,
  taskInput?: TaskCreateInputWrapper
  // eslint-disable-next-line max-params
) => {
  const auth = useAuth();
  const genericOnError = useGenericOnError();
  const useCustomCandidateEventInvite = useFlag('user_custom_self_schedule_event_template');

  const activityFeedRefetch = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();
  const refetchTaskList = useRefetchTaskListQuery();
  const files = useSelector((state) => getFilesByUploadFlow(state, FileUploadFlow.SELF_SCHEDULE_LINK));

  const [selfScheduleOptionsUpsertMutation] = useSelfScheduleOptionsUpsertMutation({
    onError: genericOnError,
  });

  const createLocationHeader = useCreateLocationHeader();
  const [createTask] = useSelfScheduleTaskCreateMutation({
    context: {
      headers: {
        ...createLocationHeader,
      },
    },
  });

  const selfScheduleOptionsUpsert = useCallback(
    async (
      selectedToFreeformEmail: string,
      ccFreeform: string[],
      bccFreeform: string[],
      ccEmployeeIds: string[],
      bccEmployeeIds: string[],
      selectedTemplate: TemplateInterface,
      subject: string,
      bodyHtml: string,
      saveWithoutSending: boolean // TODO: Fix this the next time the file is edited.
    ): // eslint-disable-next-line max-params
    Promise<string | null> => {
      if (!auth.userInfo?.employeeEmail) return null;

      const selfScheduleOptionsClone = cloneDeep(selfScheduleOptions);

      if (!selfScheduleOptionsClone.options) return null;

      const { candidateCalendarId, interviewerCalendarId, location } = selfScheduleOptionsClone.options;
      if (!candidateCalendarId || !interviewerCalendarId || !location) {
        return null;
      }

      const attachments: RemoteAttachmentInput[] = [];

      if (files) {
        files.forEach((file) => {
          if (!file.path) return;
          attachments.push({ name: file.name, path: file.path });
        });
      }

      let finalTaskId = taskId;
      if (!taskId && taskInput) {
        const saveInput = getTaskCreateInput(taskInput);
        const createResult = await createTask({
          variables: {
            input: saveInput,
          },
          onError: genericOnError,
        });
        finalTaskId = createResult.data?.taskCreate?.task?.id;
      }

      await selfScheduleOptionsUpsertMutation({
        variables: {
          input: {
            taskId: finalTaskId,
            candidateEmailTemplateId: selfScheduleOptions.candidateEmailTemplateId,
            candidateEventTemplateId: useCustomCandidateEventInvite
              ? selfScheduleOptions.candidateEventTemplateId
              : undefined,
            interviewerEventTemplateId: selfScheduleOptions.interviewerEventTemplateId,
            options: {
              ...selfScheduleOptionsClone.options,
              candidateCalendarId,
              interviewerCalendarId,
              location,
            },
            candidateEmailContent: saveWithoutSending
              ? undefined
              : {
                  emailTemplateId: selectedTemplate?.id,
                  description: bodyHtml,
                  summary: subject,
                  toEmailAddress: selectedToFreeformEmail,
                  ccEmployeeIds,
                  bccEmployeeIds,
                  ccExternalAddresses: ccFreeform,
                  bccExternalAddresses: bccFreeform,
                  attachments,
                },
          },
        },
      });

      if (finalTaskId) {
        refetchScheduleTaskQuery();
        activityFeedRefetch();
        refetchTaskList();
        return finalTaskId;
      }

      return null;
    },
    [
      auth.userInfo?.employeeEmail,
      selfScheduleOptions,
      files,
      taskId,
      taskInput,
      selfScheduleOptionsUpsertMutation,
      useCustomCandidateEventInvite,
      createTask,
      genericOnError,
      refetchScheduleTaskQuery,
      activityFeedRefetch,
      refetchTaskList,
    ]
  );

  return selfScheduleOptionsUpsert;
};

export default useSelfScheduleOptionsUpsert;
