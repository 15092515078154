import { getTZAbbr } from '@modernloop/shared/datetime';
import {
  add,
  addDays,
  addHours,
  addMilliseconds,
  areIntervalsOverlapping,
  differenceInCalendarDays as dateFnsDifferenceInCalendarDays,
  endOfDay as dateFnsEndOfDay,
  isSameDay as dateFnsIsSameDay,
  isWeekend as dateFnsIsWeekend,
  startOfDay as dateFnsStartOfDay,
  format,
  isAfter,
  isBefore,
  isEqual,
  isWithinInterval,
  parseISO,
  subMilliseconds,
} from 'date-fns';
import { format as formatTZ, getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { CompanyHolidaysType } from 'src/hooks/useCompanyHolidays';

type DateInput = string | number | Date;

/**
 * Check if {date} is same or before {toCompare}
 * @param date
 * @param toCompare
 */
export function sameOrBefore(date: Date, toCompare: Date): boolean {
  return isEqual(date, toCompare) || isBefore(date, toCompare);
}

/**
 * Check if {date} is same or after {toCompare}
 * @param date
 * @param toCompare
 */
export function sameOrAfter(date: Date, toCompare: Date): boolean {
  return isEqual(date, toCompare) || isAfter(date, toCompare);
}

/**
 * Check if start {date} is between {toCompareStart} and {toCompareEnd}
 * @param date
 * @param toCompareStart
 * @param toCompareEnd
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
function isBetweenDate(date: Date, toCompareStart: Date, toCompareEnd: Date): boolean {
  const isStartDateOverlapping = isAfter(date, toCompareStart);
  const isEndDateOverlapping = isBefore(date, toCompareEnd);
  return isStartDateOverlapping && isEndDateOverlapping;
}

/**
 * Check if start {date} is between {toCompareStart} and {toCompareEnd} including start & end date
 * @param date
 * @param toCompareStart
 * @param toCompareEnd
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function isBetweenDateInclusive(date: Date, toCompareStart: Date, toCompareEnd: Date): boolean {
  const isStartDateOverlapping = isAfter(date, toCompareStart) || isEqual(date, toCompareStart);
  const isEndDateOverlapping = isBefore(date, toCompareEnd) || isEqual(date, toCompareEnd);
  return isStartDateOverlapping && isEndDateOverlapping;
}

/**
 * Check if date blocks are overlapping on calendar
 * exclusive of start time next block.
 * @param dateStart
 * @param dateEnd
 * @param toCompareStart
 * @param toCompareEnd
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function isOverlappingOnCalendar(
  dateStart: Date,
  dateEnd: Date,
  toCompareStart: Date,
  toCompareEnd: Date
): boolean {
  const adjustedEndDate = subMilliseconds(dateEnd, 1);
  return (
    isBetweenDate(dateStart, toCompareStart, toCompareEnd) ||
    isBetweenDate(adjustedEndDate, toCompareStart, toCompareEnd)
  );
}

/**
 * Utility to quickly check validity of date object.
 * @param date
 */
function isValidDate(date) {
  try {
    date.toISOString();
    return true;
  } catch (er) {
    return false;
  }
}

/**
 * checks if start and end dates overlap company holidays
 * @param startAt, start date
 * @param endAt, end date
 * @param holidays - company holidays
 * @returns { isHoliday: boolean, holiday?: CompanyHolidaysType }
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function isHoliday(
  startAt: Date,
  endAt: Date,
  holidays: CompanyHolidaysType
): { isHoliday: boolean; holidays: CompanyHolidaysType } {
  let isOff = false;
  const offs: CompanyHolidaysType = [] as CompanyHolidaysType;
  if (!holidays) {
    return { isHoliday: isOff, holidays: offs };
  }

  for (const holiday of holidays) {
    const parsedStart = parseISO(holiday.start);
    const parsedEnd = addDays(parseISO(holiday.end), 1);

    if (isOverlappingOnCalendar(startAt, endAt, parsedStart, parsedEnd)) {
      isOff = true;
      offs.push(holiday);
    }
  }
  return { isHoliday: isOff, holidays: offs };
}

/**
 * Convert  zoned LocalDate to UTC
 * @param localDate
 * @param startHour
 * @param endHour
 * @param localTimeZone
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function convertLocalDateTimeToUTC(
  localDate: Date,
  startHour: string,
  endHour: string,
  localTimeZone: string
): { utcDay: string; utcStartDateTime: string; utcEndDateTime: string } | null {
  // not a valid date, nothing to do
  if (!isValidDate(localDate)) return null;
  // make sure date is always at the start of the day
  localDate.setHours(0, 0, 0, 0);
  // calculate startDateTime, endDateTime
  const [startHr, startMin] = startHour.split(':').map((s) => parseInt(s, 10));
  const [endHr, endMin] = endHour.split(':').map((s) => parseInt(s, 10));
  // localStartDateTime
  const localStartDateTime = new Date(localDate.getTime());
  localStartDateTime.setHours(startHr, startMin, 0, 0);
  // localEndDateTime
  const localEndDateTime = new Date(localDate.getTime());
  localEndDateTime.setHours(endHr, endMin, 0, 0);
  return {
    utcDay: zonedTimeToUtc(localDate, localTimeZone).toISOString(),
    utcStartDateTime: zonedTimeToUtc(localStartDateTime, localTimeZone).toISOString(),
    utcEndDateTime: zonedTimeToUtc(localEndDateTime, localTimeZone).toISOString(),
  };
}

const now = (): Date => {
  return new Date();
};

export const isBeforeNow = (date: Date): boolean => {
  // https://date-fns.org/v2.22.1/docs/isBefore
  return isBefore(date, now());
};

export const isWithinXHoursFromNow = (date: Date, hours: number): boolean => {
  // https://date-fns.org/v2.22.1/docs/isWithinInterval
  return isWithinInterval(date, { start: now(), end: addHours(now(), hours) });
};

export const isValidInterval = (interval: { start: Date; end: Date }): boolean => {
  return interval.start.getTime() <= interval.end.getTime();
};

export const isIntervalOverlappingXHoursFromNow = (interval: { start: Date; end: Date }, hours: number): boolean => {
  if (!isValidInterval(interval)) {
    return false;
  }
  // https://date-fns.org/v2.22.1/docs/areIntervalsOverlapping
  return areIntervalsOverlapping(interval, { start: now(), end: addHours(now(), hours) });
};

export const isIntervalOverlappingTwelveHoursFromNow = (interval: { start: Date; end: Date }): boolean => {
  return isIntervalOverlappingXHoursFromNow(interval, 12);
};

export const formatRange = (
  start: DateInput,
  end: DateInput,
  timezone: string,
  dateFormat = 'EEEE, MMMM d, yyyy',
  timeFormat = 'h:mm aaa',
  multidayDateFormat = dateFormat,
  multidayTimeFormat = timeFormat // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
string => {
  const startTime = utcToZonedTime(start, timezone);
  const endTime = utcToZonedTime(end, timezone);

  let result = '';
  if (dateFnsIsSameDay(startTime, endTime)) {
    result = `${format(startTime, `${dateFormat}${dateFormat ? ',' : ''} ${timeFormat}`)} - ${format(
      endTime,
      timeFormat
    )}`;
  } else {
    result = `${format(
      startTime,
      `${multidayDateFormat}${multidayDateFormat ? ',' : ''} ${multidayTimeFormat}`
    )} - ${format(endTime, `${multidayDateFormat}${multidayDateFormat ? ',' : ''} ${multidayTimeFormat}`)}`;
  }

  return result;
};

/**
 * Formats a date using the specified timezone
 * @param date - Date to format
 * @param formatString - https://date-fns.org/v2.24.0/docs/format
 * @param tz - the timezone
 * @deprecated
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const formatToTimeZone = (date: DateInput, formatString: string, tz: string): string => {
  const zonedDate = utcToZonedTime(date, tz);
  return formatTZ(zonedDate, formatString, { timeZone: tz });
};

/**
 * Checks if same day using timezone information
 * @param date - Date to format
 * @param formatString - https://date-fns.org/v2.24.0/docs/format
 * @param tz - the timezone
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const isSameDay = (dateLeft: DateInput, dateRight: DateInput, tz: string): boolean => {
  const startTime = utcToZonedTime(dateLeft, tz);
  const endTime = utcToZonedTime(dateRight, tz);
  return dateFnsIsSameDay(startTime, endTime);
};

export const isWeekend = (dateInput: DateInput, tz: string): boolean => {
  const date = utcToZonedTime(dateInput, tz);
  return dateFnsIsWeekend(date);
};

/** @deprecated */
export const startOfDay = (dateInput: DateInput, tz: string): Date => {
  const date = utcToZonedTime(dateInput, tz);
  const start = dateFnsStartOfDay(date);
  return zonedTimeToUtc(start, tz);
};

export const endOfDay = (dateInput: DateInput, tz: string): Date => {
  const date = utcToZonedTime(dateInput, tz);
  const end = dateFnsEndOfDay(date);
  return zonedTimeToUtc(end, tz);
};

export const startOfNextDay = (dateInput: DateInput, tz: string): Date => {
  const date = startOfDay(dateInput, tz);
  return add(date, { days: 1 });
};

/**
 * Returns the formatted timezone offset string
 */

export const getTimezoneOffsetStr = (timezone: string): string => {
  if (!timezone) return '';
  return getTZAbbr(timezone);
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const differenceInCalendarDays = (dateLeft: DateInput, dateRight: DateInput, tz: string): number => {
  const dateLeftTz = utcToZonedTime(dateLeft, tz);
  const dateRightTz = utcToZonedTime(dateRight, tz);

  return dateFnsDifferenceInCalendarDays(dateLeftTz, dateRightTz);
};

/**
 * If the local time object passed has 14:30 then it will return time which translates to 14:30 in the timezone.
 * E.g. time ('2021-12-12T09:00:00.000Z') represents 2:30 PM in 'Asia/Kolkata' (local time) and timezone is 'America/Los_Angeles'
 *      then it will return ('2021-12-12T22:30:00.000Z') which is 2:30 PM in 'America/Los_Angeles'
 * @param time in local timezone
 * @param timezone to which the time needs to be returned in.
 * @param hours the hour value of the time returned.
 * @param minutes the minute value of the time returned.
 * @returns Returns the time in the timezone represented by hours and minutes.
 * @deprecated
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getTimeInTimezone = (time: Date, timezone: string, hours?: number, minutes?: number): Date => {
  const result = new Date(Date.UTC(time.getFullYear(), time.getMonth(), time.getDate()));
  result.setUTCHours(hours ?? time.getHours(), minutes ?? time.getMinutes(), 0, 0);
  return addMilliseconds(result, -getTimezoneOffset(timezone, result));
};

/**
 * Simply using the date constructor like `new Date('yyyy-mm-dd')` returns the date in UTC
 * but sometimes we want the date to be in local time zone, which is then converted to correct timezone
 * `new Date('yyyy-mm-dd')` works fine for timezone to the east of UTC
 * and depending on time of day either works or not for timezone to west of UTC.
 * The issue happens specifically when it is new day in UTC but still old day in the timezone.
 * @param date the date string in `yyyy-mm-dd` format.
 * @returns the start of day represented by passed date string.
 */
export const getDateFromYyyyMmDdStr = (date: string): Date => {
  const dateParts = date.split('-');
  const result = new Date();
  result.setFullYear(parseInt(dateParts[0], 10));

  /**
   * Reason for calling setMonth twice, say today is the 31st of January.
   * When you date.setMonth(1); you are trying to set the date to the 31st of February.
   * Since this date doesn't exist, it falls over to the 3rd of March.
   * This is the simplest solution.
   */
  const month = parseInt(dateParts[1], 10) - 1;
  result.setMonth(month);
  result.setMonth(month);

  result.setDate(parseInt(dateParts[2], 10));
  result.setHours(0, 0, 0, 0);
  return result;
};

export default {};
