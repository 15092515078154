import { useMemo } from 'react';

import { uniq } from 'lodash';

/**
 * Helper hook that returns an array of employee ids for the coordinator and recruiter
 * Filters out null and undefined values as well as de-dupes the array
 */
export const useDefaultCcEmployeeIds = (
  coordinatorId: string | undefined | null,
  recruiterId: string | undefined | null
): string[] => {
  return useMemo(() => {
    const ccEmployeeIds: string[] = [];
    if (coordinatorId) ccEmployeeIds.push(coordinatorId);
    if (recruiterId) ccEmployeeIds.push(recruiterId);
    return uniq(ccEmployeeIds);
  }, [coordinatorId, recruiterId]);
};
