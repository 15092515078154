import React, { useCallback, useMemo } from 'react';

import { AutocompleteRenderGroupParams, ListSubheader, TextField } from '@mui/material';
import { find } from 'lodash';

import { AtsInterviewDefinitionFragment } from 'src/generated/mloop-graphql';

import Autocomplete from 'src/components/Autocomplete';
import ImageBlock from 'src/components/ImageBlock';
import VirtualList from 'src/components/VirtualList';
import Label from 'src/components/label';

import { useOrgAtsService } from 'src/hooks/atsService';
import { getAtsInterviewDefinitionFields } from 'src/hooks/atsService/util';

import useAtsScorecardOptions from './useAtsScorecardOptions';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface BaseAtsScorecardProps {
  result: ReturnType<typeof useAtsScorecardOptions>;
  atsInterviewDefinitionId?: string;
  selectScorecard: (scorecard: AtsInterviewDefinitionFragment) => void;
}

const useSxProps = () => {
  return useMemo(() => {
    return {
      subHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: (theme) => `${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(0.5)}`,
      },
    };
  }, []);
};

export const useGroupBy = () => {
  const atsService = useOrgAtsService();
  const groupBy = useCallback(
    (option: AtsInterviewDefinitionFragment) => {
      const groupByBy = atsService?.scorecardGroupByKey;
      if (!option.atsId) return '';
      if (groupByBy) {
        return getAtsInterviewDefinitionFields(option.atsFields, groupByBy) || '(No group)';
      }
      return option.atsJobStage?.name || '';
    },
    [atsService]
  );
  return groupBy;
};

export const useRenderOption = () => {
  const renderOption = useCallback((option: AtsInterviewDefinitionFragment) => {
    const estimatedMinutes = getAtsInterviewDefinitionFields(option.atsFields, 'estimatedMinutes');
    return (
      <ImageBlock
        noWrap
        dataTestId={option.atsId || 'ats-scorecard-render-option-no-scorecard'}
        title={option.name ?? 'No Scorecard'}
        caption={estimatedMinutes ? `${estimatedMinutes} mins` : undefined}
      />
    );
  }, []);

  return renderOption;
};

export const useGetChildSize = () => {
  const atsService = useOrgAtsService();
  const getChildSize = useCallback(
    (child: React.ReactNode) => {
      if (!atsService?.showEstimatedMinutes || (React.isValidElement(child) && child.type === ListSubheader)) return 32;
      return 48;
    },
    [atsService]
  );
  return getChildSize;
};

export const useRenderGroup = () => {
  // const classes = useStyles();
  const sxProps = useSxProps();

  const renderGroup = useCallback(
    (params: AutocompleteRenderGroupParams) => {
      const group: React.ReactNode[] = [];
      if (params.group) {
        group.push(
          <ListSubheader disableSticky key={params.key} component="div" sx={sxProps.subHeader}>
            <Label variant="captions" color="info" fontWeight={600}>
              {params.group}
            </Label>
          </ListSubheader>
        );
      }
      group.push(params.children);
      return group;
    },
    [sxProps.subHeader]
  );

  return renderGroup;
};

const BaseAtsScorecard = ({
  result,
  atsInterviewDefinitionId,
  selectScorecard,
}: BaseAtsScorecardProps): JSX.Element | null => {
  const { loading, error, options } = result;
  const renderGroup = useRenderGroup();
  const groupBy = useGroupBy();

  if (loading)
    return (
      <Label variant="captions" color="mid-contrast-grey">
        Loading…
      </Label>
    );
  if (error)
    return (
      <Label variant="captions" color="mid-contrast-grey">
        Error: {error.message}
      </Label>
    );

  const selectedScorecard: AtsInterviewDefinitionFragment | undefined =
    (atsInterviewDefinitionId && find(options, (scorecard) => scorecard.atsId === atsInterviewDefinitionId)) ||
    undefined;

  const handleScorecardSelect = (event: React.ChangeEvent, option: AtsInterviewDefinitionFragment) => {
    selectScorecard(option);
  };

  return (
    <Label>
      <Autocomplete
        autoHighlight
        fullWidth
        disableClearable
        ListboxComponent={VirtualList as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
        value={selectedScorecard}
        options={options}
        onChange={handleScorecardSelect}
        groupBy={groupBy}
        getOptionLabel={(option) => option.name ?? 'No Scorecard'}
        renderOption={(option) => <Label>{option.name ?? 'No Scorecard'}</Label>}
        renderInput={(params) => (
          <TextField {...params} variant="standard" placeholder="Search for scorecard" autoFocus />
        )}
        renderGroup={renderGroup}
      />
    </Label>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type AtsScorecardProps = Omit<BaseAtsScorecardProps, 'result'> & { atsJobId: string | null };

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const AtsScorecard = ({ atsJobId, ...rest }: AtsScorecardProps): JSX.Element => {
  const result = useAtsScorecardOptions(atsJobId || undefined);
  return <BaseAtsScorecard result={result} {...rest} />;
};

export default AtsScorecard;
