import React, { FC, useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog } from '@modernloop/shared/components';
import { Alert, AlertTitle, Box, Stack, TextField, Typography } from '@mui/material';

import { useSlackChannelByNameLazyQuery } from 'src/generated/mloop-graphql';

import { LockIcon } from 'src/components/icons';

import useGenericOnError from 'src/hooks/useGenericOnError';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import logError from 'src/utils/logError';

export const ExistingSlackChannelGQL = gql`
  query SlackChannelByName($name: String!) {
    slackChannelByName(name: $name) {
      id
      name
    }
  }
`;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface ExistingChannelModalProps {
  isOpen: boolean;
  onConfirm: (id: string, name: string) => void;
  onClose: () => void;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const ExistingChannelModal: FC<ExistingChannelModalProps> = ({ isOpen, onConfirm, onClose }) => {
  const [slackName, setSlackName] = useState<string>('');
  const [foundSlack, setFoundSlack] = useState<boolean>(true);
  const genericOnError = useGenericOnError();

  const [fetchSlackChannelByName, { loading }] = useSlackChannelByNameLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (slackData) => {
      let found = false;
      slackData.slackChannelByName?.forEach((slackChannelObj) => {
        if (slackChannelObj?.name === slackName) {
          found = true;
          onConfirm(slackChannelObj.id, slackChannelObj.name);
          setSlackName('');
        }
      });
      setFoundSlack(found);
    },
    onError: (e) => {
      logError(e);
      genericOnError(e);
    },
  });

  return (
    <ConditionalThemeProvider>
      <Dialog
        open={isOpen}
        title="Use existing channel"
        submitOptions={{
          label: 'Submit',
          isDisabled: !slackName || loading,
          onClick: () => {
            let cleanSlackName = slackName.trim();
            if (cleanSlackName.length > 0 && cleanSlackName.charAt(0) === '#') {
              cleanSlackName = cleanSlackName.replace('#', '');
            }
            fetchSlackChannelByName({
              variables: {
                name: cleanSlackName,
              },
            });
          },
        }}
        cancelOptions={{
          label: 'Cancel',
          onClick: () => {
            setSlackName('');
            setFoundSlack(true);
            onClose();
          },
        }}
      >
        <Stack direction="column" spacing={1}>
          <TextField
            disabled={loading}
            onChange={(event) => {
              const val = event.target.value;
              setSlackName(val);
            }}
            label="Private channel name"
            placeholder="Private channel name, e.g. #sales-hiring"
            InputProps={{
              startAdornment: (
                <Box mr="4px">
                  <LockIcon fontSize={20} />
                </Box>
              ),
            }}
          />
          {!foundSlack && (
            <Alert severity="warning" title="Private channel not found">
              <AlertTitle>Private channel not found</AlertTitle>
              Double check the spelling of your channel and make sure the ModernLoop Bot is a member of the channel. You
              can add the bot to a channel by typing &quot;/invite @ModernLoop Interview Bot&quot;
            </Alert>
          )}
          <Typography color="text.secondary" variant="body2">
            Enter the name of a private, non-archived channel that uses the ModernLoop Interview Bot. Any user with
            access to this candidate on ModernLoop will be able to see the name of the channel, add members, and post
            into the channel when scheduling or managing this candidate.
          </Typography>
          <Typography color="text.secondary" variant="body2">
            To add the ModernLoop Bot to a channel visit the channel and type “/invite @ModernLoop Interview Bot”
          </Typography>
        </Stack>
      </Dialog>
    </ConditionalThemeProvider>
  );
};

export default ExistingChannelModal;
