import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

export const logMessage = (message: string, level: Sentry.SeverityLevel, extra: unknown = null) => {
  Sentry.withScope((scope) => {
    if (LogRocket.sessionURL) {
      scope.setExtra('session_url', LogRocket.sessionURL);
      scope.setTag('session_url', LogRocket.sessionURL);
    }

    if (extra) {
      scope.setExtra('extra', extra);
    }

    console.log(level, message, extra);
    Sentry.captureMessage(message, level);
  });

  LogRocket.captureMessage(message, {
    tags: {
      severity: level.toString(),
    },
  });
};
