import { useMemo } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { formatDuration } from 'date-fns';

import { JobStageInterviewSeatType, UseInterviewPlanDescription_JobStageFragment } from 'src/generated/mloop-graphql';

import { isBreak, isDayBreak } from 'src/utils/interview';

type Fragments = {
  jobStage: UseInterviewPlanDescription_JobStageFragment | undefined;
};

const useInterviewPlanDescription: FunctionWithFragments<Fragments, undefined, string> = ({ jobStage }) => {
  const jobStageInterviews = useMemo(() => {
    return jobStage?.jobStageInterviewGroups?.map((group) => group.jobStageInterviews || []).flat() || [];
  }, [jobStage]);

  const jobStageInterviewSeats = useMemo(() => {
    return jobStageInterviews.map((interview) => interview.jobStageInterviewSeats || []).flat();
  }, [jobStageInterviews]);

  const interviewPlanMetaStr = useMemo(() => {
    if (
      !jobStageInterviews ||
      !jobStageInterviewSeats ||
      !jobStageInterviews.length ||
      !jobStageInterviewSeats.length
    ) {
      return '';
    }

    let durationInMins = 0;
    let interviews = 0;
    let minInterviewers = 0;
    let maxInterviewers = 0;

    jobStageInterviews.forEach((jobStageInterview) => {
      if (!jobStageInterview?.interviewType) return;

      if (!isBreak(jobStageInterview.interviewType) && !isDayBreak(jobStageInterview.interviewType)) {
        interviews++;
      }

      durationInMins += isDayBreak(jobStageInterview.interviewType) ? 1440 : jobStageInterview.duration ?? 0;
    });

    jobStageInterviewSeats.forEach((jobStageInterviewSeat) => {
      const originalSeat =
        jobStageInterviewSeat.type === JobStageInterviewSeatType.Linked
          ? jobStageInterviewSeats.find(
              (seat) => seat.id === jobStageInterviewSeat?.linkedSeat?.linkedJobStageInterviewSeatId
            )
          : jobStageInterviewSeat;

      if (originalSeat?.type === JobStageInterviewSeatType.Freeform) {
        minInterviewers++;
        maxInterviewers++;
      } else if (originalSeat?.type === JobStageInterviewSeatType.Module) {
        minInterviewers++;
        maxInterviewers += 2;
      }
    });

    const durationStr = formatDuration({
      days: Math.floor(durationInMins / 1440),
      hours: Math.floor((durationInMins / 60) % 24),
      minutes: durationInMins % 60,
    });

    return `${interviews} ${interviews === 1 ? 'interview' : 'interviews'}, ${durationStr}, ${
      minInterviewers === maxInterviewers ? minInterviewers : `${minInterviewers} - ${maxInterviewers}`
    } ${minInterviewers === maxInterviewers && minInterviewers === 1 ? 'interviewer' : 'interviewers'}`;
  }, [jobStageInterviews, jobStageInterviewSeats]);

  return interviewPlanMetaStr;
};

useInterviewPlanDescription.fragments = {
  jobStage: gql`
    fragment useInterviewPlanDescription_jobStage on JobStage {
      id
      jobStageInterviewGroups {
        id
        jobStageInterviews {
          id
          interviewType
          duration
          jobStageInterviewSeats {
            id
            type
            linkedSeat {
              linkedJobStageInterviewSeatId
            }
          }
        }
      }
    }
  `,
};

export default useInterviewPlanDescription;
