import { ThemeOptions } from '../type';

/**
 * Color constants
 * FIGMA → https://www.figma.com/file/Yq6Wgf9c3VvcNAAu25KYAZ/Styles?node-id=54%3A42&viewport=-2100%2C289%2C1.5428613424301147
 */

// eslint-disable-next-line @typescript-eslint/naming-convention
enum Color_DO_NOT_EXPORT {
  // Common

  WHITE = 'rgba(255, 255, 255, 1)',
  BLACK = 'rgba(0, 0, 0, 1)',
  TRANSPARENT = 'rgba(0, 0, 0, 0)',

  // Alpha Greys

  GRAY_CONTRAST_MAX = 'rgba(5, 7, 26, 0.72)',
  GRAY_CONTRAST_HIGH = 'rgba(5, 7, 26, 0.57)',
  GRAY_CONTRAST_MID = 'rgba(5, 7, 26, 0.3)',
  GRAY_CONTRAST_LOW = 'rgba(5, 7, 26, 0.15)',
  GRAY_CONTRAST_MIN = 'rgba(5, 7, 26, 0.03)',

  // Solid Greys

  SOLID_GRAY_CONTRAST_MAX = '#4B4C5A',
  SOLID_GRAY_CONTRAST_HIGH = '#71727D',
  SOLID_GRAY_CONTRAST_MID = '#B4B5BA',
  SOLID_GRAY_CONTRAST_LOW = '#DADADD',
  SOLID_GRAY_CONTRAST_MIN = '#F8F8F8',

  // Foreground

  FOREGROUND_PRIMARY = '#05071A',

  // Background

  BACKGROUND_PRIMARY = '#FFFFFF',
  BACKGROUND_ALTERNATE = '#FEFDFB',
  BACKGROUND_CONTRAST = '#F8F8F8',
  BACKGROUND_INFO = '#EAF5FF',
  BACKGROUND_SUCCESS = '#E7F9F7',
  BACKGROUND_WARNING = '#FFF8DA',
  BACKGROUND_ERROR = '#FDEBEB',
  BACKGROUND_HEADER = 'rgba(246, 246, 247, 0.5)',

  // Utility

  ACCENT = 'rgba(49, 147, 237, 0.1)',
  DISABLED = 'rgba(5, 7, 26, 0.3)',
  FOCUS = 'rgba(49, 147, 237, 0.45)',

  // Colors

  TEAL_LIGHT = '#0DC2AD',
  TEAL = '#08918D',
  TEAL_DARK = '#056667',

  RED_LIGHT = '#E73E36',
  RED = '#BC130C',
  RED_DARK = '#830303',

  YELLOW_LIGHT = '#FEE977',
  YELLOW = '#FDD73F',
  YELLOW_DARK = '#FABB00',

  BLUE_LIGHT = '#3193ED',
  BLUE = '#0E69BD',
  BLUE_DARK = '#034D91',

  PURPLE_LIGHT = '#F2ECFE',
  PURPLE = '#6D32EA',
  PURPLE_DARK = '#481BB6',
  PURPLE_BACKGROUND = '#F2ECFE',

  ORANGE_LIGHT = '#FFAA5B',
  ORANGE = '#EB600A',
  ORANGE_DARK = '#AD4200',
  ORANGE_BACKGROUND = '#FFEDDB',
}

export const grey: ThemeOptions['grey'] = {
  alpha: {
    max: Color_DO_NOT_EXPORT.GRAY_CONTRAST_MAX,
    high: Color_DO_NOT_EXPORT.GRAY_CONTRAST_HIGH,
    mid: Color_DO_NOT_EXPORT.GRAY_CONTRAST_MID,
    low: Color_DO_NOT_EXPORT.GRAY_CONTRAST_LOW,
    min: Color_DO_NOT_EXPORT.GRAY_CONTRAST_MIN,
  },
  solid: {
    max: Color_DO_NOT_EXPORT.SOLID_GRAY_CONTRAST_MAX,
    high: Color_DO_NOT_EXPORT.SOLID_GRAY_CONTRAST_HIGH,
    mid: Color_DO_NOT_EXPORT.SOLID_GRAY_CONTRAST_MID,
    low: Color_DO_NOT_EXPORT.SOLID_GRAY_CONTRAST_LOW,
    min: Color_DO_NOT_EXPORT.SOLID_GRAY_CONTRAST_MIN,
  },
};

export const palette: ThemeOptions['palette'] = {
  type: 'light',
  common: {
    white: Color_DO_NOT_EXPORT.WHITE,
    black: Color_DO_NOT_EXPORT.BLACK,
    transparent: Color_DO_NOT_EXPORT.TRANSPARENT,
  },
  divider: Color_DO_NOT_EXPORT.GRAY_CONTRAST_LOW,
  border: Color_DO_NOT_EXPORT.GRAY_CONTRAST_LOW,
  link: Color_DO_NOT_EXPORT.BLUE,
  text: {
    primary: Color_DO_NOT_EXPORT.FOREGROUND_PRIMARY,
    secondary: Color_DO_NOT_EXPORT.GRAY_CONTRAST_MAX,
    disabled: Color_DO_NOT_EXPORT.DISABLED,
  },
  action: {
    focus: Color_DO_NOT_EXPORT.FOCUS,
    selected: Color_DO_NOT_EXPORT.ACCENT,
    hover: Color_DO_NOT_EXPORT.GRAY_CONTRAST_MIN,
    disabled: Color_DO_NOT_EXPORT.GRAY_CONTRAST_LOW,
  },
  background: {
    default: Color_DO_NOT_EXPORT.BACKGROUND_PRIMARY,
    paper: Color_DO_NOT_EXPORT.BACKGROUND_PRIMARY,
    alternate: Color_DO_NOT_EXPORT.BACKGROUND_ALTERNATE,
    contrast: Color_DO_NOT_EXPORT.BACKGROUND_CONTRAST,
    header: Color_DO_NOT_EXPORT.BACKGROUND_HEADER,
    info: Color_DO_NOT_EXPORT.BACKGROUND_INFO,
    success: Color_DO_NOT_EXPORT.BACKGROUND_SUCCESS,
    warning: Color_DO_NOT_EXPORT.BACKGROUND_WARNING,
    error: Color_DO_NOT_EXPORT.BACKGROUND_ERROR,
    primary: Color_DO_NOT_EXPORT.BACKGROUND_INFO,
    secondary: Color_DO_NOT_EXPORT.BACKGROUND_SUCCESS,
    tertiary: Color_DO_NOT_EXPORT.PURPLE_BACKGROUND,
    pending: Color_DO_NOT_EXPORT.ORANGE_BACKGROUND,
  },
  primary: {
    // primary and info are the same blue
    light: Color_DO_NOT_EXPORT.BLUE_LIGHT,
    main: Color_DO_NOT_EXPORT.BLUE,
    dark: Color_DO_NOT_EXPORT.BLUE_DARK,
    contrastText: Color_DO_NOT_EXPORT.WHITE,
  },
  secondary: {
    // secondary and success are the same teal
    light: Color_DO_NOT_EXPORT.TEAL_LIGHT,
    main: Color_DO_NOT_EXPORT.TEAL,
    dark: Color_DO_NOT_EXPORT.TEAL_DARK,
    contrastText: Color_DO_NOT_EXPORT.WHITE,
  },
  tertiary: {
    light: Color_DO_NOT_EXPORT.PURPLE_LIGHT,
    main: Color_DO_NOT_EXPORT.PURPLE,
    dark: Color_DO_NOT_EXPORT.PURPLE_DARK,
    contrastText: Color_DO_NOT_EXPORT.WHITE,
  },
  success: {
    // secondary and success are the same teal
    light: Color_DO_NOT_EXPORT.TEAL_LIGHT,
    main: Color_DO_NOT_EXPORT.TEAL,
    dark: Color_DO_NOT_EXPORT.TEAL_DARK,
    contrastText: Color_DO_NOT_EXPORT.WHITE,
  },
  info: {
    // primary and info are the same blue
    light: Color_DO_NOT_EXPORT.BLUE_LIGHT,
    main: Color_DO_NOT_EXPORT.BLUE,
    dark: Color_DO_NOT_EXPORT.BLUE_DARK,
    contrastText: Color_DO_NOT_EXPORT.WHITE,
  },
  warning: {
    light: Color_DO_NOT_EXPORT.YELLOW_LIGHT,
    main: Color_DO_NOT_EXPORT.YELLOW,
    dark: Color_DO_NOT_EXPORT.YELLOW_DARK,
    contrastText: Color_DO_NOT_EXPORT.WHITE,
  },
  error: {
    light: Color_DO_NOT_EXPORT.RED_LIGHT,
    main: Color_DO_NOT_EXPORT.RED,
    dark: Color_DO_NOT_EXPORT.RED_DARK,
    contrastText: Color_DO_NOT_EXPORT.WHITE,
  },
  pending: {
    light: Color_DO_NOT_EXPORT.ORANGE_LIGHT,
    main: Color_DO_NOT_EXPORT.ORANGE,
    dark: Color_DO_NOT_EXPORT.ORANGE_DARK,
    contrastText: Color_DO_NOT_EXPORT.WHITE,
  },
};
