import { Timezone, assertIsoTimestamp, formatTZ } from '@modernloop/shared/datetime';

export const MAX_SLACK_CHANNEL_NAME_LENGTH = 75; // actually 80
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
const getSlackChannelName = (candidateName: string, startDate: Date, tz: Timezone) => {
  const channelName = `intw-${candidateName
    .replace(/\s+/g, '-')
    // https://javascript.info/regexp-unicode
    // Matches anything that's not a unicode letter, number, dash, or underscore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .replace(/[^-_\p{L}\p{N}]/giu, '')
    .toLowerCase()}-${formatTZ(assertIsoTimestamp(startDate.toISOString()), tz, 'MMM-dd').toLowerCase()}`;
  return channelName.slice(0, MAX_SLACK_CHANNEL_NAME_LENGTH);
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getSlackChannelFormat = (candidateName: string, startDate: Date, tz: Timezone, format: string) => {
  const firstName = candidateName.split(' ').slice(0, -1).join(' ');
  const lastName = candidateName.split(' ').slice(-1).join(' ');
  const channelName = format
    .replace('{{CANDIDATE_NAME}}', candidateName)
    .replace('{{CANDIDATE_FIRST_NAME}}', firstName)
    .replace('{{CANDIDATE_LAST_NAME}}', lastName)
    .replace('{{MONTH_NUMBER}}', formatTZ(assertIsoTimestamp(startDate.toISOString()), tz, 'MM'))
    .replace('{{MONTH_TEXT}}', formatTZ(assertIsoTimestamp(startDate.toISOString()), tz, 'MMMM'))
    .replace('{{MONTH_TEXT_ABBREVIATED}}', formatTZ(assertIsoTimestamp(startDate.toISOString()), tz, 'MMM'))
    .replace('{{DAY_NUMBER}}', formatTZ(assertIsoTimestamp(startDate.toISOString()), tz, 'd'))
    .replace(/\s+/g, '-')
    // https://javascript.info/regexp-unicode
    // Matches anything that's not a unicode letter, number, dash, or underscore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .replace(/[^-_\p{L}\p{N}]/giu, '')
    .toLowerCase();
  return channelName.slice(0, MAX_SLACK_CHANNEL_NAME_LENGTH);
};

export default getSlackChannelName;
