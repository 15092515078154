import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowDownIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;

  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        d="M10 16L5.5 12M10 16L14.5 12M10 16V3.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
