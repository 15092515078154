import React from 'react';

import { gql } from '@apollo/client';
import { formatDate, getLocalTimezone } from '@modernloop/shared/datetime';
import { Alert, Typography } from '@mui/material';

import {
  ApprovalFeedback_SchedulerFeedbackFragment,
  ApprovalFeedback_TrainedFeedbackFragment,
  ApprovalFeedback_TraineeFeedbackFragment,
  GraduationApproverDecisionType,
  TrainingStatus,
} from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

type Props = {
  role: TrainingStatus;
};

type Fragments = {
  traineeFeedback: ApprovalFeedback_TraineeFeedbackFragment | undefined;
  trainedFeedback: ApprovalFeedback_TrainedFeedbackFragment | undefined;
  schedulerFeedback: ApprovalFeedback_SchedulerFeedbackFragment | undefined;
};

const ApprovalFeedback: FCWithFragments<Fragments, Props> = ({
  traineeFeedback,
  trainedFeedback,
  schedulerFeedback,
  role: status,
}) => {
  const getStatusFor = () => {
    switch (status) {
      case TrainingStatus.Shadow:
        return 'reverse shadow';
      case TrainingStatus.ReverseShadow:
        return 'trained interviewer';
      default:
        return null;
    }
  };

  if (schedulerFeedback?.reviewerId && schedulerFeedback?.decisionType === GraduationApproverDecisionType.MoveForward) {
    return (
      <Alert severity="success" variant="standard">
        <Typography variant="body2" display="inline-flex">
          Approved by
          <Typography variant="subtitle2" fontWeight={600}>
            &nbsp;{schedulerFeedback.reviewer?.fullName}&nbsp;
          </Typography>
          on&nbsp;
          {formatDate(schedulerFeedback.reviewedAt, getLocalTimezone(), 'MMMM d, yyyy')} as {getStatusFor()}
        </Typography>
      </Alert>
    );
  }

  if (
    traineeFeedback?.reviewerId &&
    trainedFeedback?.reviewerId &&
    traineeFeedback?.decisionType === GraduationApproverDecisionType.MoveForward &&
    trainedFeedback?.decisionType === GraduationApproverDecisionType.MoveForward
  ) {
    return (
      <Alert severity="success" variant="standard">
        <Typography variant="body2" display="inline-flex">
          Approved by
          <Typography variant="subtitle2" fontWeight={600}>
            &nbsp;{traineeFeedback.reviewer?.fullName}&nbsp;
          </Typography>
          on&nbsp;
          {formatDate(trainedFeedback.reviewedAt, getLocalTimezone(), 'MMMM d, yyyy')} and&nbsp;
          {formatDate(trainedFeedback.reviewedAt, getLocalTimezone(), 'MMMM d, yyyy')} as&nbsp;{getStatusFor()}
        </Typography>
      </Alert>
    );
  }

  if (traineeFeedback?.decisionType === GraduationApproverDecisionType.MoveForward && !trainedFeedback?.reviewerId) {
    return (
      <Alert severity="success" variant="standard">
        <Typography variant="body2" display="inline-flex">
          Approved by
          <Typography variant="subtitle2" fontWeight={600}>
            &nbsp;{traineeFeedback.reviewer?.fullName}&nbsp;
          </Typography>
          on&nbsp;
          {formatDate(traineeFeedback.reviewedAt, getLocalTimezone(), 'MMMM d, yyyy')} as {getStatusFor()}
        </Typography>
      </Alert>
    );
  }

  if (trainedFeedback?.decisionType === GraduationApproverDecisionType.MoveForward && !traineeFeedback?.reviewerId) {
    return (
      <Alert severity="success" variant="standard">
        <Typography variant="body2" display="inline-flex">
          Approved by
          <Typography variant="subtitle2" fontWeight={600}>
            &nbsp;{trainedFeedback.reviewer?.fullName}&nbsp;
          </Typography>
          on&nbsp;
          {formatDate(trainedFeedback.reviewedAt, getLocalTimezone(), 'MMMM d, yyyy')} as {getStatusFor()}
        </Typography>
      </Alert>
    );
  }

  return null;
};

export const FeedbackFragment = gql`
  fragment ApprovalFeedback_feedback on ApplicationStageInterviewFeedback {
    reviewerId
    decisionType
    reviewedAt
    reviewer {
      id
      fullName
    }
  }
`;

ApprovalFeedback.fragments = {
  traineeFeedback: gql`
    ${FeedbackFragment}
    fragment ApprovalFeedback_traineeFeedback on ApplicationStageInterviewFeedback {
      ...ApprovalFeedback_feedback
    }
  `,
  trainedFeedback: gql`
    ${FeedbackFragment}
    fragment ApprovalFeedback_trainedFeedback on ApplicationStageInterviewFeedback {
      ...ApprovalFeedback_feedback
    }
  `,
  schedulerFeedback: gql`
    ${FeedbackFragment}
    fragment ApprovalFeedback_schedulerFeedback on ApplicationStageInterviewFeedback {
      ...ApprovalFeedback_feedback
    }
  `,
};

export default ApprovalFeedback;
