import { AxiosResponse } from 'axios';

import { apiPost } from 'src/utils/api';
import ApiVerb from 'src/utils/api/ApiVerb';
import { InterviewerLoadAndLimit } from 'src/utils/api/getScheduleOptions';

export type ListLoadLimitRequest = {
  date: {
    year: number;
    month: number;
    day: number;
  };
  timezone: string;
  employeeIds: string[];
};

export type ListLoadLimitResponse = {
  loadAndLimit: InterviewerLoadAndLimit[];
};

export const getLoadAndLimit = (
  date: Date,
  timezone: string,
  employeeIds: string[] // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
Promise<AxiosResponse<ListLoadLimitResponse>> => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const request: ListLoadLimitRequest = {
    date: {
      year,
      month,
      day,
    },
    timezone,
    employeeIds,
  };

  return apiPost(ApiVerb.LIST_LOAD_LIMIT, request);
};
