import React from 'react';

import BaseIcon, { Props } from './Icon';

const UserEditIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <g clipPath="url(#clip0_24_10)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.49999 2.75C7.70507 2.75 6.24999 4.20507 6.24999 6V7C6.24999 8.79493 7.70507 10.25 9.49999 10.25C11.2949 10.25 12.75 8.79493 12.75 7V6C12.75 4.20507 11.2949 2.75 9.49999 2.75ZM7.74999 6C7.74999 5.0335 8.5335 4.25 9.49999 4.25C10.4665 4.25 11.25 5.0335 11.25 6V7C11.25 7.9665 10.4665 8.75 9.49999 8.75C8.5335 8.75 7.74999 7.9665 7.74999 7V6ZM4.95846 14.1939C6.10031 13.0836 7.82899 12.5855 9.92505 12.796C10.3372 12.8374 10.7048 12.5369 10.7462 12.1247C10.7876 11.7126 10.4871 11.3449 10.0749 11.3035C7.67888 11.0629 5.46104 11.613 3.91278 13.1185C3.55377 13.4676 3.34038 13.8921 3.29389 14.3477C3.24805 14.7969 3.36966 15.2228 3.58705 15.5771C4.01595 16.2761 4.84321 16.7498 5.77491 16.7497C6.26067 16.7497 6.75814 16.7498 7.49974 16.75C7.91396 16.7501 8.24985 16.4145 8.24999 16.0002C8.25012 15.586 7.91444 15.2501 7.50023 15.25C6.75844 15.2498 6.26078 15.2497 5.77481 15.2497C5.3414 15.2498 5.00967 15.0275 4.86556 14.7926C4.79644 14.68 4.77803 14.5795 4.78614 14.5C4.79359 14.427 4.82811 14.3207 4.95846 14.1939Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7811 10.2211C16.7979 9.23786 15.2038 9.23774 14.2205 10.2208L9.61697 14.823C9.38249 15.0574 9.25075 15.3754 9.25073 15.707L9.25063 18.0013C9.25062 18.2002 9.32964 18.3909 9.47029 18.5316C9.61094 18.6723 9.80171 18.7513 10.0006 18.7513L12.2936 18.7513C12.6251 18.7513 12.9431 18.6196 13.1775 18.3852L17.781 13.7819C18.7643 12.7986 18.7644 11.2044 17.7811 10.2211ZM15.281 11.2816C15.6785 10.8842 16.3229 10.8842 16.7204 11.2817C17.1179 11.6792 17.1179 12.3237 16.7204 12.7212L16.2508 13.1908L14.8112 11.7512L15.281 11.2816ZM13.7504 12.8117L10.7507 15.8106L10.7507 17.2513L12.1901 17.2513L15.1901 14.2514L13.7504 12.8117Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_24_10">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};

export default UserEditIcon;
