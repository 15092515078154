import { snakeCaseToCapitalize } from '../utils/plainText';

/**
 * Note : IMPORTANT
 * Values to be added not deleted or edited
 */
export enum SupportedFonts {
  COMFORTAA = 'COMFORTAA',
  EXO_2 = 'EXO_2',
  INCONSOLATA = 'INCONSOLATA',
  INTER = 'INTER',
  JOSEFIN_SANS = 'JOSEFIN_SANS',
  JOSEFIN_SLAB = 'JOSEFIN_SLAB',
  LORA = 'LORA',
  MONTSERRAT = 'MONTSERRAT',
  OPEN_SANS = 'OPEN_SANS',
  SPACE_GROTESK = 'SPACE_GROTESK',
}

export const SupportedFontsArray = Object.keys(SupportedFonts).map((key) => ({
  value: SupportedFonts[key as keyof typeof SupportedFonts],
  label: snakeCaseToCapitalize(SupportedFonts[key as keyof typeof SupportedFonts]),
}));
