import { useMutation, useQueryClient } from 'react-query';

import { IntegrationType } from 'src/generated/mloop-graphql';

import { putIntegration } from './integrationPut';
import { QUERY_KEY, WorkdayIntegration } from './types';

const INTEGRATION_TYPE: IntegrationType = IntegrationType.Workday;

function putWorkdayIntegration(workday: WorkdayIntegration) {
  return putIntegration({
    integration_type: INTEGRATION_TYPE,
    workday,
  });
}

export default function useWorkdayIntegrationPut() {
  const queryClient = useQueryClient();
  return useMutation((data: { workday: WorkdayIntegration }) => putWorkdayIntegration(data.workday), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.invalidateQueries([QUERY_KEY, INTEGRATION_TYPE]);
    },
  });
}
