import React, { CSSProperties, FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid, GridProps } from '@material-ui/core';

import { BaseProps } from 'src/components/types';

export type StackItemStyles = { [index: number]: CSSProperties };

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type StackProps = BaseProps & {
  divider?: React.ReactNode;
  spacing?: GridProps['spacing'];
  direction?: GridProps['direction'];
  justifyContent?: GridProps['justifyContent'];
  alignItems?: GridProps['alignItems'];
  alignContent?: GridProps['alignContent'];
  wrap?: GridProps['wrap'];

  /**
   * Override styles with a custom class
   */
  className?: string;

  /**
   * Override styles with a custom styles
   */
  style?: React.CSSProperties;

  /**
   * Apply styles to <Grid item /> at particular index
   */
  itemStyles?: StackItemStyles;
};

/**
 * Return an array with the separator React element interspersed between
 * each React node of the input children.
 *
 * > joinChildren([1,2,3], 0)
 * [1,0,2,0,3]
 */
function joinChildren(childrenArray, separator) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  return childrenArray.reduce((output, child, index) => {
    output.push(child);

    if (index < childrenArray.length - 1) {
      // eslint-disable-next-line react/no-array-index-key
      output.push(React.cloneElement(separator, { key: `separator-${index}` }));
    }

    return output;
  }, []);
}

/**
 * @deprecated - Use Stack from `@mui/material` instead
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const Stack: FC<StackProps> = ({
  dataTestId,
  divider,
  direction,
  spacing,
  wrap,
  justifyContent,
  alignItems,
  alignContent,
  children,
  className,
  style,
  itemStyles,
}): JSX.Element => {
  const childrenArray = React.Children.toArray(children).filter(Boolean);
  const childrenWithDivider: (React.ReactChild | React.ReactFragment | React.ReactPortal)[] = divider
    ? joinChildren(childrenArray, divider)
    : childrenArray;
  const isColumn = direction === 'column' || direction === 'column-reverse';
  return (
    <Grid
      container
      direction={direction}
      spacing={spacing}
      justifyContent={justifyContent}
      alignItems={alignItems}
      alignContent={alignContent}
      wrap={wrap}
      className={className}
      style={style}
      data-testid={dataTestId}
    >
      {childrenWithDivider.map &&
        childrenWithDivider.map((child, index) => (
          <Grid
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            item
            xs={isColumn ? 12 : undefined}
            zeroMinWidth
            style={itemStyles ? itemStyles[index] : undefined}
          >
            {child}
          </Grid>
        ))}
    </Grid>
  );
};

/**
 * @deprecated - Use MUI5 Stack instead
 */
export default Stack;
