import React, { useEffect } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { SetIsInExtensionAction, useOmniSearchDispatch } from 'src/views-new/OmniSearch/OmniSearchProvider';

const IsInExtensionContext = React.createContext<boolean>(false);

export const IsInExtensionProvider: React.FC = ({ children }) => {
  const dispatch = useOmniSearchDispatch();

  useEffect(() => {
    // Tell the OmniSearchProvider that we are in the extension and that we don't want to show the OmniSearch
    dispatch(SetIsInExtensionAction);
  }, [dispatch]);

  return <IsInExtensionContext.Provider value>{children}</IsInExtensionContext.Provider>;
};

/**
 * Helper hook to determine if the component is rendered in the extension sidebar
 * @returns {boolean} true if the component is rendered in the extension sidebar
 */
const useIsInExtension = (): boolean => {
  return React.useContext(IsInExtensionContext);
};

export default useIsInExtension;
