import React, { useCallback, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { Stack, useTheme } from '@mui/material';

import {
  ActorType,
  ResponseStatus,
  ScheduleTaskFlags_TaskFlagFragment,
  SourceType,
  TaskFlagActorType, // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TaskFlagCandidateDeclined, // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TaskFlagInterviewerDebriefDeclined, // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TaskFlagInterviewerDeclined, // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TaskFlagRequestedChange,
  TaskFlagType,
  useTaskFlagMarkResolvedMutation,
} from 'src/generated/mloop-graphql';

import Link from 'src/components/Link';
import { FlagIcon } from 'src/components/icons';
import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import { EmployeeFragment } from 'src/entities/EmployeePicker/EmployeeGraphQL';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import { getTaskFlagLabel } from './Common/ScheduleTaskFlagCompact/getTaskFlagLabel';

const useSxProps = () => {
  const theme = useTheme();

  return useMemo(() => {
    return {
      requestedChangeNote: {
        borderRadius: '12px',
        border: `1px solid ${theme.palette.border}`,
        padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
        width: 'fit-content',
      },
    };
  }, [theme]);
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleTaskFlagProp = {
  taskFlag: ScheduleTaskFlags_TaskFlagFragment;
};

export const getScheduleTaskFlagFields = (
  flag: ScheduleTaskFlags_TaskFlagFragment,
  key?: keyof (TaskFlagCandidateDeclined &
    TaskFlagInterviewerDebriefDeclined &
    TaskFlagInterviewerDeclined &
    TaskFlagRequestedChange)
) => {
  if (flag && key) return flag[key];
  return undefined;
};

const ScheduleTaskFlag = ({ taskFlag }: ScheduleTaskFlagProp) => {
  const sxProps = useSxProps();
  const [resolving, setResolving] = useState(false);

  const activityFeedRefetch = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();

  const [taskFlagMarkResolvedMutation] = useTaskFlagMarkResolvedMutation();

  const actionLabel = useMemo(() => {
    if (taskFlag.flagType === TaskFlagType.CandidateDeclined) {
      return `declined ${getScheduleTaskFlagFields(taskFlag, 'declinedCandidateEvent')?.name}`;
    }
    if (taskFlag.flagType === TaskFlagType.InterviewDeclined) {
      return `declined ${getScheduleTaskFlagFields(taskFlag, 'declinedInterview')?.name}`;
    }
    if (taskFlag.flagType === TaskFlagType.RequestChange) {
      return 'requested a change';
    }
    if (taskFlag.flagType === TaskFlagType.DebriefDeclined) {
      return 'declined Debrief';
    }
    return '';
  }, [taskFlag]);

  const actorType = useMemo(() => {
    if (taskFlag.actorType === TaskFlagActorType.Employee) return ActorType.Employee;
    if (taskFlag.actorType === TaskFlagActorType.Candidate) return ActorType.Candidate;
    return ActorType.System;
  }, [taskFlag.actorType]);

  const handleMarkAsResolved = useCallback(() => {
    setResolving(true);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    taskFlagMarkResolvedMutation({
      variables: {
        input: {
          taskFlagId: taskFlag.id,
        },
      },
      awaitRefetchQueries: true,
    }) // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      .then(() => {
        refetchScheduleTaskQuery();
        activityFeedRefetch();
      })
      .finally(() => {
        setResolving(false);
      });
  }, [activityFeedRefetch, refetchScheduleTaskQuery, taskFlag.id, taskFlagMarkResolvedMutation]);

  return (
    <ActivityLogBase
      dataTestId="schedule-task-flag"
      activityLog={{
        __typename: 'ActivityLog',
        createdAt: taskFlag.createdAt,
        id: taskFlag.id,
        activityActorType: actorType,
        actorEmployee: taskFlag.actorEmployee,
        sourceType: SourceType.SourceTypeTask,
      }}
      responseStatus={
        [TaskFlagType.CandidateDeclined, TaskFlagType.InterviewDeclined].includes(taskFlag.flagType)
          ? ResponseStatus.Declined
          : undefined
      }
      actionLabel={actionLabel}
    >
      <Stack
        spacing={
          taskFlag.flagType === TaskFlagType.RequestChange && getScheduleTaskFlagFields(taskFlag, 'note')
            ? 1
            : undefined
        }
      >
        {taskFlag.flagType === TaskFlagType.RequestChange && getScheduleTaskFlagFields(taskFlag, 'note') && (
          <Label
            dataTestId="schedule-task-flags-request-change-note"
            color="max-contrast-grey"
            style={sxProps.requestedChangeNote}
          >
            {getScheduleTaskFlagFields(taskFlag, 'note')}
          </Label>
        )}
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Label
            dataTestId="schedule-task-flag-label"
            variant="captions"
            color="error"
            icon={<FlagIcon color="error" fontSize={14} />}
          >
            {getTaskFlagLabel(taskFlag.flagType)}
          </Label>
          <Label variant="captions" color="mid-contrast-grey">
            ·
          </Label>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            color={resolving ? 'mid-contrast-grey' : 'info'}
            variant="captions"
            style={{ pointerEvents: resolving ? 'none' : undefined, textDecoration: 'none' }}
            dataTestId="schedule-task-flags-mark-as-resolved"
            onClick={handleMarkAsResolved}
          >
            Mark as resolved
          </Link>
        </Stack>
      </Stack>
    </ActivityLogBase>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleTaskFlagsFragmentProps = {
  taskFlags: ScheduleTaskFlags_TaskFlagFragment[];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/restric-fragments-name.cjs
const ScheduleTaskFlags: FCWithFragments<ScheduleTaskFlagsFragmentProps> = ({ taskFlags }) => {
  const flags = useMemo(() => {
    return taskFlags.filter((flag) => !flag.resolvedAt);
  }, [taskFlags]);

  if (!flags.length) {
    return (
      <Label dataTestId="schedule-task-flags-none" color="mid-contrast-grey" variant="captions">
        None
      </Label>
    );
  }

  return (
    <Stack spacing={2}>
      {flags.map((taskFlag) => {
        return <ScheduleTaskFlag key={taskFlag.id} taskFlag={taskFlag} />;
      })}
    </Stack>
  );
};

ScheduleTaskFlags.fragments = {
  taskFlags: gql`
    ${EmployeeFragment}
    fragment ScheduleTaskFlags_taskFlag on TaskFlag {
      id
      createdAt
      flagType
      actorType
      actorEmployee {
        ...Employee
      }
      resolvedAt
      resolvedBy {
        ...Employee
      }

      ... on TaskFlagCandidateDeclined {
        declinedCandidateEvent {
          id
          name
        }
      }

      ... on TaskFlagInterviewerDeclined {
        declinedInterview {
          id
          name
        }
      }

      ... on TaskFlagRequestedChange {
        note
      }

      ... on TaskFlagInterviewerDebriefDeclined {
        declinedDebrief {
          id
          name
        }
      }
    }
  `,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TaskFlagMarkResolvedMutation = gql`
  ${EmployeeFragment}
  ${ScheduleTaskFlags.fragments.taskFlags}
  mutation TaskFlagMarkResolved($input: TaskFlagMarkResolvedInput!) {
    taskFlagMarkResolved(input: $input) {
      taskFlag {
        ...ScheduleTaskFlags_taskFlag
      }
    }
  }
`;

export default ScheduleTaskFlags;
