import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskCandidateSelfScheduleRescheduled_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import logError from 'src/utils/logError';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogContent from '../../Helpers/ActivityLogContent';

interface Fragments {
  activityLog: ActivityLogTaskCandidateSelfScheduleRescheduled_ActivityLogFragment;
}

const ActivityLogTaskCandidateSelfScheduleRescheduled: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (details?.__typename !== 'ActivityLogTaskEventDetails') {
    logError('ActivityLogTaskCandidateSelfScheduleRescheduled: details is not ActivityLogTaskEventDetails');
    return null;
  }

  return (
    <ActivityLogBase activityLog={activityLog} actionLabel="rescheduled this task via self-schedule">
      {details?.selfScheduleCandidateNote ? <ActivityLogContent content={details.selfScheduleCandidateNote} /> : null}
    </ActivityLogBase>
  );
};

ActivityLogTaskCandidateSelfScheduleRescheduled.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskCandidateSelfScheduleRescheduled_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogTaskEventDetails {
          __typename
          selfScheduleCandidateNote
        }
      }

      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskCandidateSelfScheduleRescheduled;
