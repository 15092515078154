import React, { useCallback, useEffect, useRef, useState } from 'react';

import Label, { LabelProps } from 'src/components/label';

import Tooltip, { TooltipProps } from './index';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type MaybeTooltipProps = TooltipProps & {
  label: string | JSX.Element;
  labelProps?: LabelProps;
};

export const useShowTooltip = (ref: React.RefObject<HTMLElement>, label: string | JSX.Element): boolean => {
  const [showTooltip, setShowTooltip] = useState(false);

  const compareSize = useCallback(() => {
    if (!ref.current) return;
    const compare = ref.current.scrollWidth > ref.current.clientWidth;
    setShowTooltip(compare);
  }, [ref]);

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, [compareSize]);

  // If the label changes, we need to re-run the compareSize effect
  useEffect(() => {
    if (!label) return;
    compareSize();
  }, [label, compareSize]);

  return showTooltip;
};

const MaybeTooltip = (props: MaybeTooltipProps): JSX.Element => {
  const { label, labelProps, ...tooltipProps } = props;

  const textElementRef = useRef<HTMLElement>(null);
  const showTooltip = useShowTooltip(textElementRef, label);

  return (
    <Tooltip {...tooltipProps} disableHoverListener={!showTooltip}>
      <Label {...labelProps} noWrap innerRef={textElementRef}>
        {label}
      </Label>
    </Tooltip>
  );
};

export default MaybeTooltip;
