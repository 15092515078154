import React from 'react';

import BaseIcon, { Props } from './Icon';

const ArrowDownIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        d="M16 10L12 14.5M16 10L12 5.5M16 10L3.5 10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default ArrowDownIcon;
