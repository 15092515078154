/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FCWithFragments } from '@modernloop/shared/components';
import { getTZAbbr } from '@modernloop/shared/datetime';
import { CircularProgress, Stack } from '@mui/material';
import _, { find, toArray } from 'lodash';

import {
  CommunicationsPreview_InterviewPlanFragment,
  useGetApplicationTaskQueueQuery,
  useHasApplicationStageInterviewLazyQuery,
} from 'src/generated/mloop-graphql';

import DoNotShowAgainDialog from 'src/components/Dialog/DoNotShowAgainDialog';
import ImageBlock from 'src/components/ImageBlock';
import Paper from 'src/components/Paper';
import Button from 'src/components/button';
import Checkbox from 'src/components/checkbox';
import { ScheduleIcon, SlackIcon } from 'src/components/icons';
import Label from 'src/components/label';

import { LoggerEvent } from 'src/constants/logger';

import useDisplayTimezone from 'src/hooks/useDisplayTimezone';
import { useLogEvent } from 'src/hooks/useLogEvent';
import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { FileStatus, FileUploadFlow } from 'src/slices/files';
import { updateCandidateCommunicationsEnabled, updateCandidateEventCommunicationsEnabled } from 'src/slices/scheduling';

import { updateShouldSaveJobTemplates } from 'src/store/actions/schedule-communications';
import { getApplicationStageIdByScheduleId, getScheduleContent } from 'src/store/selectors/schedule-communications';
import { getOriginalCandidateGoogleEventId } from 'src/store/selectors/scheduling';
import { ScheduleContent } from 'src/store/slices/schedule-communications';

import { DoNotShowAgainDialogTypes } from 'src/utils/api/employee/constants';
import { formatTz } from 'src/utils/renderTimeRange';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';
import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useDispatch, useSelector } from 'src/store';

import CandidateCommunicationsSection from './CandidateCommunicationsSection';
import useSaveSettingsToJobStageFromStore from './useSaveSettingsToJobStage';
import useSubmitCreateSchedule from './useSubmitCreateSchedule';

export const HasApplicationStageInterview = gql`
  query HasApplicationStageInterview($id: uuid!) {
    applicationStage(id: $id) {
      id
      applicationStageInterviews(input: { isCancelled: false }) {
        id
      }
    }
  }
`;

export const APPLICATION_TASK_QUEUE = gql`
  query GetApplicationTaskQueue($applicationId: uuid!) {
    application(id: $applicationId) {
      id
      job {
        id
        jobSettings {
          defaultTaskQueue {
            id
          }
        }
      }
    }
  }
`;

const useStyle = makeStyles(() =>
  createStyles({
    circularProgress: {
      marginLeft: '4px',
    },
  })
);

// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface BaseCommunicationsPreviewProps {
  applicationId: string;
  timezone: string;
  error?: string | null | undefined;
  submitting?: boolean;
  disabled?: boolean;
  scheduleContent: ScheduleContent;
  slackChannelEnabled: boolean;
  slackChannelName: string;
  prevSlackChannelName?: string;
  candidateCommunicationsEnabled: boolean;
  candidateEventCommunicationsEnabled: boolean;
  hasSameContent: boolean;
  onSubmit: (internalOnly?: boolean) => void;
  onEnableCandidateCommuncations: () => void;
  scheduleFlowType: ScheduleFlowType;
  updateWarning?: string;
  learnMore?: string;
  privateEvents: boolean;
  candidatePrivacyChanged?: boolean;
  scheduleCreated?: boolean;
  shouldSaveTemplates?: boolean;
  onShouldSaveTemplates?: (save: boolean) => void;
}

// eslint-disable-next-line modernloop/restrict-props-name.cjs
export const BaseCommunicationsPreview: FC<BaseCommunicationsPreviewProps> = ({
  applicationId,
  timezone,
  submitting,
  error,
  disabled,
  scheduleContent,
  slackChannelEnabled,
  slackChannelName,
  prevSlackChannelName,
  candidatePrivacyChanged,
  candidateCommunicationsEnabled,
  candidateEventCommunicationsEnabled,
  hasSameContent,
  onSubmit,
  onEnableCandidateCommuncations,
  scheduleFlowType,
  updateWarning,
  learnMore,
  privateEvents,
  scheduleCreated,
  shouldSaveTemplates,
  onShouldSaveTemplates,
}) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const { logEvent } = useLogEvent();
  const warn = !candidateEventCommunicationsEnabled;
  const showCandidateInRightpane = candidateEventCommunicationsEnabled || candidateCommunicationsEnabled;
  const minByStartAt = _.minBy(scheduleContent.candidateEventContent?.schedule?.events, 'startAt');
  const startAt = minByStartAt ? new Date(minByStartAt.startAt) : new Date();
  const maxByStartAt = _.maxBy(scheduleContent.candidateEventContent?.schedule?.events, 'endAt');
  const endAt = maxByStartAt ? new Date(maxByStartAt.endAt) : new Date();
  const eventTitle = hasSameContent
    ? scheduleContent.candidateEventContent?.summary
    : scheduleContent.candidateCalendarEventContent?.subject;

  const [submittingInternalOnly, setSubmittingInternalOnly] = useState(false);

  const renderHours = `${formatTz(startAt, timezone, 'EEEE')}, ${formatTz(startAt, timezone, 'MMM')} ${formatTz(
    startAt,
    timezone,
    'd'
  )}, ${formatTz(startAt, timezone, 'h:mm a')} - ${formatTz(endAt, timezone, 'h:mm a')} ${getTZAbbr(timezone)}`;

  const [overrideModal, setOverrideModal] = useState(false);

  const [isHoldConfirmationModalOpen, setIsHoldConfirmationModalOpen] = useState(false);

  const handleSendInternalOnly = async () => {
    // disable the candidate email and invite since the user has decided not to use these
    setSubmittingInternalOnly(true);
    await dispatch(updateCandidateCommunicationsEnabled(false));
    await dispatch(updateCandidateEventCommunicationsEnabled(false));

    if (!overrideModal) {
      setIsHoldConfirmationModalOpen(true);
    } else {
      await onSubmit(true);
      setSubmittingInternalOnly(false);
    }
  };

  const hasPreviousCandidateEvent = !!useSelector(getOriginalCandidateGoogleEventId);

  return (
    <Stack spacing={2} direction="column">
      <Paper color="alternate">
        <Stack spacing={2} direction="column">
          <Label variant="title" fontWeight={600}>
            Review
          </Label>

          <CandidateCommunicationsSection
            warn={warn}
            disabled={disabled}
            submitting={submitting}
            scheduleCreated={scheduleCreated}
            onSubmit={onSubmit}
            scheduleFlowType={scheduleFlowType}
            prevSlackChannelName={prevSlackChannelName}
            updateWarning={updateWarning}
            learnMore={learnMore}
            error={error}
            showCandidateInRightpane={showCandidateInRightpane}
            onEnableCandidateCommuncations={onEnableCandidateCommuncations}
            candidatePrivacyChanged={candidatePrivacyChanged}
            candidateCommunicationsEnabled={candidateCommunicationsEnabled}
            scheduleContent={scheduleContent}
            candidateEventCommunicationsEnabled={candidateEventCommunicationsEnabled}
            privateEvents={privateEvents}
            eventTitle={eventTitle}
            renderHours={renderHours}
          />

          <Label variant="captions" fontWeight={600}>
            Internal communications
          </Label>
          <ImageBlock
            image={<ScheduleIcon />}
            title={`${scheduleContent.interviewerEventContents.length} ${privateEvents ? 'private ' : ''} ${
              scheduleContent.interviewerEventContents.length > 1 ? 'events' : 'event'
            }`}
            caption={renderHours}
            noWrap
          />
          {slackChannelEnabled && (
            <ImageBlock image={<SlackIcon />} title={slackChannelName} caption=" Channel name" noWrap />
          )}
          {!hasPreviousCandidateEvent && (
            <Button
              disabled={disabled || submitting || scheduleCreated}
              fullWidth
              label="Send internal only"
              variant={
                !candidateEventCommunicationsEnabled && !candidateCommunicationsEnabled ? 'contained' : 'outlined'
              }
              color="default"
              size="medium"
              onClick={() => {
                logEvent(LoggerEvent.CLIENT_SCHEDULE_FLOW_SEND_INTERNAL_ONLY_CLICKED, {
                  applicationID: applicationId,
                  modal: false,
                });
                handleSendInternalOnly();
              }}
              endIcon={
                submitting && submittingInternalOnly ? (
                  <CircularProgress size={20} className={classes.circularProgress} />
                ) : undefined
              }
            />
          )}
        </Stack>
      </Paper>
      {onShouldSaveTemplates && (
        <Checkbox
          label={
            <Label variant="captions">Save templates used for candidate and Slack communications to job setup</Label>
          }
          checked={shouldSaveTemplates}
          onChange={() => onShouldSaveTemplates(!shouldSaveTemplates)}
        />
      )}

      {isHoldConfirmationModalOpen && (
        <DoNotShowAgainDialog
          doNotShowAgainKey={DoNotShowAgainDialogTypes.SEND_INTERNAL_ONLY}
          isOpen
          title="Skip candidate email and calendar invite?"
          onClose={() => setIsHoldConfirmationModalOpen(false)}
          cancelOptions={{ label: 'Cancel', onClick: () => setIsHoldConfirmationModalOpen(false) }}
          submitOptions={{
            label: 'Skip & send internal only',
            onClick: (shouldOverrideModal) => {
              if (shouldOverrideModal) {
                setOverrideModal(true);
              }
              onSubmit(true);
              setIsHoldConfirmationModalOpen(false);
            },
          }}
        >
          <Stack mb={2}>
            <Label>{`Your candidate won't receive any emails or invites to their interview at this time. You'll need to manually send this later by updating the schedule or clicking the "Send to candidate" button shown on their schedule`}</Label>
          </Stack>
        </DoNotShowAgainDialog>
      )}
    </Stack>
  );
};

const useHasApplicationStageInterviews = (
  applicationStageId: string | undefined,
  scheduleFlowType: ScheduleFlowType,
  scheduleInterviewCount: number | undefined,
  error: string | null // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
boolean => {
  const [fetchApplicationStageInterview, { data }] = useHasApplicationStageInterviewLazyQuery({
    variables: {
      id: applicationStageId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (
      (scheduleFlowType === ScheduleFlowType.SCHEDULE || scheduleFlowType === ScheduleFlowType.RESCHEDULE) &&
      error &&
      applicationStageId
    ) {
      fetchApplicationStageInterview();
    }
  }, [applicationStageId, error, fetchApplicationStageInterview, scheduleFlowType]);

  if (data?.applicationStage?.applicationStageInterviews?.length) {
    if (scheduleInterviewCount && scheduleInterviewCount === data.applicationStage.applicationStageInterviews.length) {
      return true;
    }
  }

  return false;
};

type Fragments = {
  interviewPlan: CommunicationsPreview_InterviewPlanFragment | undefined;
};

type Props = {
  scheduleFlowType: ScheduleFlowType;
  onComplete: () => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const CommunicationsPreview: FCWithFragments<Fragments, Props> = ({
  interviewPlan,
  scheduleFlowType,
  onComplete,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { logEvent } = useLogEvent();
  const timezone = useDisplayTimezone();

  const [saveJobSettingsMutation] = useSaveSettingsToJobStageFromStore();

  const scheduleFlowData = useScheduleFlowData();

  const {
    stepScheduleContent: { error, isWaiting, isSubmitting },
    candidateCommunicationsEnabled,
    candidateEventCommunicationsEnabled,
    hasSameContent,
    isPrivateCalendarEvent,
    selectedScheduleId,
  } = useSelector((state) => state.scheduling);

  const { applicationId } = scheduleFlowData;
  const { data: taskQueueData, loading: taskQueueDataLoading } = useGetApplicationTaskQueueQuery({
    variables: {
      applicationId,
    },
  });
  const submitCreateSchedule = useSubmitCreateSchedule(
    { interviewPlan },
    {
      isReschedule: scheduleFlowType === ScheduleFlowType.RESCHEDULE /* isReschedule */,
      options: { taskQueueId: taskQueueData?.application?.job?.jobSettings.defaultTaskQueue?.id },
    }
  );
  const scheduleContent = useSelector(getScheduleContent);

  const { shouldSaveJobTemplates, slackChannelEnabled, slackChannelName, prevSlackChannelName } = useSelector(
    (state) => state.scheduleCommunications.byId[selectedScheduleId || ''] || {}
  );

  const applicationStageId = useSelector((state) => {
    if (!selectedScheduleId) return undefined;
    return getApplicationStageIdByScheduleId(state, selectedScheduleId);
  });

  const scheduleInterviewCount = useScheduleWithoutBreaks(selectedScheduleId)?.events.length;

  const scheduleCreated = useHasApplicationStageInterviews(
    applicationStageId,
    scheduleFlowType,
    scheduleInterviewCount,
    error
  );

  const handleSubmit = async (internalOnly?: boolean): Promise<void> => {
    await submitCreateSchedule(() => {
      logEvent(
        scheduleFlowType === ScheduleFlowType.SCHEDULE
          ? LoggerEvent.CLIENT_SCHEDULE_FLOW_COMPLETE
          : LoggerEvent.CLIENT_RESCHEDULE_FLOW_COMPLETE,
        {
          applicationID: applicationId,
          internalOnly,
        }
      );
      onComplete();
    }, internalOnly);
    // If checkbox for saving job stage templates is on, save them
    if (shouldSaveJobTemplates) {
      saveJobSettingsMutation();
    }
  };

  const handleShouldRemember = (save: boolean) => {
    if (!selectedScheduleId) return;

    dispatch(updateShouldSaveJobTemplates(selectedScheduleId, save));
  };

  const handleEnableCandidateCommunications = async (): Promise<void> => {
    dispatch(updateCandidateEventCommunicationsEnabled(true));
  };

  const dictFiles = useSelector((state) => state.fileUpload[FileUploadFlow.SCHEDULING]);
  const filesArr = toArray(dictFiles);
  const isAttachmentUploading = !!find(filesArr, { status: FileStatus.UPLOADING });
  const isAttachmentErrored = !!find(filesArr, { status: FileStatus.ERRORED });

  if (!scheduleContent) return <></>;

  return (
    <BaseCommunicationsPreview
      applicationId={applicationId}
      timezone={timezone}
      error={error}
      submitting={isSubmitting}
      disabled={isAttachmentUploading || isAttachmentErrored || isWaiting || taskQueueDataLoading}
      scheduleContent={scheduleContent}
      slackChannelEnabled={slackChannelEnabled}
      slackChannelName={slackChannelName || ''}
      prevSlackChannelName={prevSlackChannelName}
      candidateCommunicationsEnabled={candidateCommunicationsEnabled}
      candidateEventCommunicationsEnabled={candidateEventCommunicationsEnabled}
      hasSameContent={hasSameContent}
      onSubmit={handleSubmit}
      onEnableCandidateCommuncations={handleEnableCandidateCommunications}
      scheduleFlowType={scheduleFlowType}
      privateEvents={isPrivateCalendarEvent}
      scheduleCreated={scheduleCreated}
      shouldSaveTemplates={shouldSaveJobTemplates}
      onShouldSaveTemplates={handleShouldRemember}
    />
  );
};

CommunicationsPreview.fragments = {
  interviewPlan: gql`
    ${useSubmitCreateSchedule.fragments.interviewPlan}
    fragment CommunicationsPreview_interviewPlan on JobStage {
      id
      ...useSubmitCreateSchedule_interviewPlan
    }
  `,
};

export default CommunicationsPreview;
