import { useEffect } from 'react';
import { QueryFunction, UseInfiniteQueryResult, useInfiniteQuery } from 'react-query';

import getCalendars, { ListCalendarResponse } from 'src/utils/api/getCalendars';

const useCalendars = (
  limit: number,
  isReadOnly: boolean,
  remoteId?: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
UseInfiniteQueryResult<ListCalendarResponse, Error> => {
  const fetchNextPage: QueryFunction<ListCalendarResponse> = ({ pageParam }) => {
    const filters = remoteId
      ? {
          remoteId,
          isReadOnly,
        }
      : { isReadOnly };
    return getCalendars({
      limit,
      cursor: pageParam,
      filter: filters,
    });
  };

  const result = useInfiniteQuery<ListCalendarResponse, Error>(
    ['calendars', limit, isReadOnly, remoteId],
    fetchNextPage,
    {
      staleTime: 0,
      getNextPageParam: (lastPage: ListCalendarResponse) => {
        return lastPage.nextCursor || undefined;
      },
    }
  );

  // Below useEffect is to fetch next page if hasNextPage is true and isFetchingNextPage is false
  useEffect(() => {
    if (result.hasNextPage && !result.isFetchingNextPage) {
      result.fetchNextPage();
    }
  }, [result]);

  return result;
};

export default useCalendars;
