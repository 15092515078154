import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type JobStage = {
  id: string;
  jobId: string;
  atsId: string;
  atsJobId: string;
  name: string;
  schedulable: boolean;
  createdAt: string;
  updatedAt: string;
  jobStageInterviewGroupIds: string[];
};

export type UpdateJobStagePayload = Pick<JobStage, 'id'> & Partial<Omit<JobStage, 'id'>>;

type JobStageState = {
  byId: { [key: string]: JobStage };
  loadingById: { [key: string]: boolean };
  errorById: { [key: string]: string };
};

const getInitialState = (): JobStageState => {
  return {
    byId: {},
    loadingById: {},
    errorById: {},
  };
};

const jobStageSlice = createSlice({
  name: 'jobStage',
  initialState: getInitialState(),
  reducers: {
    addJobStage(state: JobStageState, action: PayloadAction<JobStage>) {
      const { payload } = action;
      state.byId[payload.id] = payload;
    },

    updateJobStage(state: JobStageState, action: PayloadAction<UpdateJobStagePayload>) {
      const { payload } = action;
      state.byId[payload.id] = {
        ...state.byId[payload.id],
        ...payload,
      };
    },

    deleteJobStage(state: JobStageState, action: PayloadAction<string>) {
      const { payload: jobStageId } = action;
      delete state.byId[jobStageId];
    },

    addJobStageError(state: JobStageState, action: PayloadAction<{ jobStageId: string; error: string }>) {
      const { jobStageId, error } = action.payload;
      state.errorById[jobStageId] = error;
    },

    deleteJobStageError(state: JobStageState, action: PayloadAction<string>) {
      const { payload: jobStageId } = action;
      delete state.errorById[jobStageId];
    },

    addJobStageLoading(state: JobStageState, action: PayloadAction<{ jobStageId: string; loading: boolean }>) {
      const { jobStageId, loading } = action.payload;
      state.loadingById[jobStageId] = loading;
    },

    deleteJobStageLoading(state: JobStageState, action: PayloadAction<string>) {
      const { payload: jobStageId } = action;
      delete state.loadingById[jobStageId];
    },

    moveJobStageInterviewGroupId(
      state: JobStageState,
      action: PayloadAction<{ jobStageId: string; groupId: string; position: number }>
    ) {
      const { jobStageId, groupId, position } = action.payload;
      const groupIndex = state.byId[jobStageId].jobStageInterviewGroupIds.indexOf(groupId);

      if (groupIndex === -1) return;
      const groupIds = state.byId[jobStageId].jobStageInterviewGroupIds.splice(groupIndex, 1);
      state.byId[jobStageId].jobStageInterviewGroupIds.splice(position, 0, groupIds[0]);
    },
  },
});

export const { reducer } = jobStageSlice;
export default jobStageSlice;
