import React from 'react';

import BaseIcon, { Props } from './Icon';

const UnknownUserIcon = ({ color, fontSize, tooltip, dataTestId }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} dataTestId={dataTestId}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.75C6.20507 2.75 4.75 4.20507 4.75 6V7C4.75 8.79493 6.20507 10.25 8 10.25C9.79493 10.25 11.25 8.79493 11.25 7V6C11.25 4.20507 9.79493 2.75 8 2.75ZM6.25 6C6.25 5.0335 7.0335 4.25 8 4.25C8.9665 4.25 9.75 5.0335 9.75 6V7C9.75 7.9665 8.9665 8.75 8 8.75C7.0335 8.75 6.25 7.9665 6.25 7V6ZM3.78147 14.0598C4.99702 13.0118 7.14404 12.5515 9.16868 12.8289C11.21 13.1085 12.748 14.0704 13.1429 15.4557C13.1214 15.477 13.0715 15.5061 13.0016 15.5065L4.00164 15.5656C3.86293 15.5665 3.75 15.4543 3.75 15.3156V14.17C3.75 14.0911 3.7789 14.062 3.78147 14.0598ZM14.5911 15.0647C13.9603 12.8075 11.6432 11.6539 9.37227 11.3428C7.07844 11.0285 4.44685 11.5056 2.80199 12.9238C2.41389 13.2584 2.25 13.7331 2.25 14.17V15.3156C2.25 16.2865 3.04051 17.0719 4.01148 17.0655L13.0115 17.0065C13.488 17.0034 13.9418 16.805 14.2528 16.4648C14.574 16.1135 14.742 15.6045 14.5911 15.0647ZM16 10.5C16 11.0523 15.5523 11.5 15 11.5C14.4477 11.5 14 11.0523 14 10.5C14 9.94772 14.4477 9.5 15 9.5C15.5523 9.5 16 9.94772 16 10.5ZM13.9168 5.37554C14.134 4.99996 14.5383 4.75 15 4.75C15.6904 4.75 16.25 5.30964 16.25 6C16.25 6.69036 15.6904 7.25 15 7.25C14.5858 7.25 14.25 7.58579 14.25 8C14.25 8.41421 14.5858 8.75 15 8.75C16.5188 8.75 17.75 7.51878 17.75 6C17.75 4.48122 16.5188 3.25 15 3.25C13.9812 3.25 13.0927 3.80444 12.6184 4.62446C12.411 4.983 12.5335 5.4418 12.892 5.6492C13.2506 5.85661 13.7094 5.73409 13.9168 5.37554Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default UnknownUserIcon;
