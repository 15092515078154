import { SvgIcon, SvgIconProps } from './SvgIcon';

export const PhoneWithArrowPointingOutwardsIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7275 5.11475C16.7275 4.91583 16.6485 4.72507 16.5078 4.58442C16.3672 4.44376 16.1764 4.36475 15.9775 4.36475L12.442 4.36475C12.0277 4.36475 11.692 4.70053 11.692 5.11475C11.692 5.52896 12.0277 5.86475 12.442 5.86475L14.1668 5.86475L10.4974 9.53416C10.2045 9.82706 10.2045 10.3019 10.4974 10.5948C10.7903 10.8877 11.2652 10.8877 11.5581 10.5948L15.2275 6.92541L15.2275 8.65028C15.2275 9.0645 15.5633 9.40028 15.9775 9.40028C16.3917 9.40028 16.7275 9.06449 16.7275 8.65028L16.7275 5.11475ZM13.2484 16.3128C11.4402 16.3868 9.29863 15.2987 7.59027 13.5904C5.88192 11.882 4.79379 9.74047 4.86786 7.93219C4.88046 7.62448 5.05032 7.29148 5.37023 6.97157L6.17606 6.16574C6.27369 6.06811 6.43198 6.06811 6.52961 6.16575L7.94383 7.57996C8.04146 7.67759 8.04146 7.83588 7.94383 7.93351L7.2616 8.61574C6.86535 9.01198 6.61882 9.65254 6.85769 10.2985C7.17613 11.1595 7.7752 12.0075 8.47415 12.7065C9.17311 13.4054 10.0211 14.0045 10.8822 14.3229C11.5281 14.5618 12.1687 14.3153 12.5649 13.919L13.2471 13.2368C13.3448 13.1392 13.503 13.1392 13.6007 13.2368L15.0149 14.651C15.1125 14.7487 15.1125 14.9069 15.0149 15.0046L14.2091 15.8104C13.8892 16.1303 13.5562 16.3002 13.2484 16.3128ZM3.36912 7.8708C3.27121 10.2609 4.66424 12.7856 6.52961 14.651C8.39499 16.5164 10.9198 17.9094 13.3098 17.8115C14.1257 17.7781 14.7945 17.3463 15.2697 16.8711L16.0756 16.0652C16.759 15.3818 16.759 14.2738 16.0756 13.5904L14.6613 12.1762C13.9779 11.4927 12.8699 11.4927 12.1865 12.1762L11.5042 12.8584C11.4709 12.8917 11.438 12.9077 11.4176 12.9134C11.4082 12.9159 11.4032 12.916 11.4021 12.9159L11.4021 12.9159C10.7938 12.6909 10.1231 12.2341 9.53482 11.6458C8.9465 11.0575 8.48975 10.3868 8.26471 9.77856V9.77854C8.26468 9.77748 8.26469 9.7724 8.26728 9.76303C8.27291 9.74266 8.28892 9.70974 8.32226 9.6764L9.00449 8.99417C9.6879 8.31075 9.6879 7.20272 9.00449 6.5193L7.59027 5.10509C6.90685 4.42167 5.79882 4.42167 5.1154 5.10508L4.30957 5.91091C3.83433 6.38615 3.40254 7.05494 3.36912 7.8708Z"
      />
    </SvgIcon>
  );
};
