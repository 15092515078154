import { TriangleDownIcon } from '@modernloop/shared/icons';
import { ThemeOptions } from '@mui/material';

/**
 * View Select props and classes at https://mui.com/material-ui/api/select/#css
 */
export const select: ThemeOptions['components'] = {
  MuiSelect: {
    defaultProps: {
      IconComponent: TriangleDownIcon,
      MenuProps: {
        PaperProps: {
          elevation: 2,
        },
      },
    },
    styleOverrides: {
      icon: {
        transitionProperty: 'all',
        top: 'unset',
      },
    },
  },
};
