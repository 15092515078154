import { useEffect } from 'react';

import { gql } from '@apollo/client';
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';

// import { useOrgInfoQuery } from 'src/generated/mloop-graphql';
import type { UserInfo } from 'src/contexts/auth0/Auth0Context';

import { IS_PRODUCTION } from 'src/constants';

type LDClient = ReturnType<typeof useLDClient>;

export const CANDIDATE_PORTAL_LD_USER_ID_PREFIX = 'candidate-portal';

export function buildLaunchDarklyUser(userInfo: UserInfo | null) {
  if (!userInfo || !userInfo.userId) {
    return {
      // Use the same key across all anonymous users
      key: 'anonymous-user-id',
      anonymous: true,
    };
  }

  const employeeName = userInfo?.employeeName || '';
  const ldName = employeeName ? `${employeeName} | ${userInfo.orgName}` : userInfo.orgName;
  const email = userInfo?.userEmail || undefined;

  const custom: { [key: string]: string | number } = {};
  if (userInfo.orgId) custom.organizationID = userInfo.orgId;
  if (userInfo.orgName) custom.organizationName = userInfo.orgName;
  if (userInfo.orgCreatedAt) custom.organizationCreatedAt = userInfo.orgCreatedAt;

  return {
    key: userInfo.userId,
    name: IS_PRODUCTION ? undefined : ldName,
    email: IS_PRODUCTION ? undefined : email,
    custom,
  };
}

/**
 * Identifies the user in LaunchDarkly. Sends name, user, email and organization,
 * Our dashboard: https://app.launchdarkly.com/production/test/users
 * LaunchDarkly API Docs: https://docs.launchdarkly.com/sdk/features/identify#javascript
 *
 * @param ldClient from useLDCLient();
 * @param userInfo from useAuth();
 * @returns void
 */
export function identifyLaunchDarklyUser(ldClient: LDClient | null, userInfo: UserInfo | null): void {
  if (!ldClient) {
    return;
  }

  const userContext = buildLaunchDarklyUser(userInfo);
  ldClient.identify(userContext);
}

export const useIdentifyLaunchDarklyUser = (userInfo: UserInfo | null) => {
  const ldClient = useLDClient();

  useEffect(() => {
    identifyLaunchDarklyUser(ldClient, userInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldClient, JSON.stringify(userInfo)]);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OrgInfoQuery = gql`
  query OrgInfo {
    thisOrg {
      id
      createdAt
    }
  }
`;
