import React from 'react';

import { Stack } from '@mui/material';

import DialogContainer, { DialogContainerContent, DialogContainerHeader } from 'src/components/DialogContainer';
import Button from 'src/components/button';
import Label from 'src/components/label';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type MissingCandidateAvailabilityTokenAlertProps = {
  onAppendAndContinue: () => void;
  goBack: () => void;
};

const MissingCandidateAvailabilityTokenAlert = ({
  onAppendAndContinue,
  goBack,
}: MissingCandidateAvailabilityTokenAlertProps) => {
  return (
    <DialogContainer open width={460}>
      <DialogContainerHeader
        title="Missing token"
        headerActions={
          <Stack direction="row" spacing={1}>
            <Button variant="outlined" label="Cancel" size="small" onClick={goBack} />
            <Button
              variant="contained"
              color="primary"
              label="Append & send"
              size="small"
              onClick={onAppendAndContinue}
            />
          </Stack>
        }
      />
      <DialogContainerContent>
        <Stack spacing={2}>
          <Label fontWeight={400}>
            Your email is missing the {'{{CANDIDATE_AVAILABILITY_URL}}'} token. This is necessary for the candidate to
            be able to submit their availability.
          </Label>
          <Label fontWeight={600}>We’ll append this link to the end of your email similar to the example below:</Label>
          <Label variant="captions" color="high-contrast-grey" fontWeight={400}>
            Click here to submit your availability: https://mloop.prod.modernloop.io/s/4vv2VAUO1QB
          </Label>
        </Stack>
      </DialogContainerContent>
    </DialogContainer>
  );
};

export default MissingCandidateAvailabilityTokenAlert;
