import { useEffect, useMemo } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { cloneDeep } from 'lodash';

import { RenderType, TemplateFragment, UseScheduleContent_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import useEmployeeId from 'src/hooks/useEmployeeId';
import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { populateEventData, setScheduleContentStepWaiting } from 'src/slices/scheduling';

import {
  getAllCodingUrls,
  getCustomVideoMeetingLink,
  getCustomVideoMeetingLinkUrls,
  getVideoMeetingLinkUrls,
  getZoomInfo,
  getZoomInfos,
} from 'src/store/selectors/schedule-communications';
import { getCandidateId, getSelectedScheduleId, getWorkspaceVideoLink } from 'src/store/selectors/scheduling';
import {
  CodingUrlBySlotId,
  CustomVideoMeetingLinkUrlBySlotId,
  VideoMeetingLinkBySlotId,
  ZoomInfo,
  ZoomInfoBySlotId,
} from 'src/store/slices/schedule-communications';

import useScheduleTemplateContent, {
  PlaceholderFillerOptionsMap,
} from 'src/utils/PlaceholderFiller/useScheduleTemplateContent';
import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { useDispatch, useSelector } from 'src/store';

import usePopulateEventData from '../CommunicationsPreview/usePopulateEventData';

type Fragments = {
  interviewPlan: UseScheduleContent_InterviewPlanFragment | undefined;
};

type Props = {
  templateId?: string;
  timezone?: string;
  slotId?: string;
};

type ReturnType = {
  filledTemplate: TemplateFragment | undefined;
  selectedScheduleId: string | null;
  schedule: InterviewSchedule | null;
  zoomInfo: ZoomInfo | undefined;
  zoomInfos: ZoomInfoBySlotId;
  customVideoMeetingLinkUrl: string | undefined;
  customVideoMeetingLinkUrls: CustomVideoMeetingLinkUrlBySlotId;
  videoMeetingLinkUrls: VideoMeetingLinkBySlotId;
  workspaceVideoLink: string | undefined;
  codingUrlByInterviewEvent: CodingUrlBySlotId | undefined;
  loading: boolean;
  placeholderFillerOptions: PlaceholderFillerOptionsMap;
};

const useScheduleContent: FunctionWithFragments<Fragments, Props, ReturnType> = (
  { interviewPlan },
  { templateId, timezone, slotId }
) => {
  const dispatch = useDispatch();

  const candidateId = useSelector(getCandidateId) as string;
  const selectedScheduleId = useSelector(getSelectedScheduleId);
  const zoomInfo = useSelector((state) => getZoomInfo(state, selectedScheduleId || ''));
  const zoomInfos = useSelector((state) => getZoomInfos(state, selectedScheduleId || ''));
  const codingUrlByInterviewEvent = useSelector((state) => getAllCodingUrls(state, selectedScheduleId || ''));
  const customVideoMeetingLinkUrl = useSelector((state) => getCustomVideoMeetingLink(state, selectedScheduleId || ''));
  const workspaceVideoLink = useSelector(getWorkspaceVideoLink);
  const customVideoMeetingLinkUrls = useSelector((state) => getCustomVideoMeetingLinkUrls(state, selectedScheduleId));
  const videoMeetingLinkUrls = useSelector((state) => getVideoMeetingLinkUrls(state, selectedScheduleId));
  const assigneeId = useEmployeeId(); // Only necessary if no task id, should be employee id

  const scheduleFlowData = useScheduleFlowData();
  const { applicationId, interviewPlanJobStageId, jobId, jobStageId, taskId, candidateTimezone } = scheduleFlowData;

  const interviewPlanEntityInScheduleFlowsEnabled = useFlag('interview_plan_entity_in_schedule_flows');

  let storeSchedule = useScheduleWithoutBreaks(selectedScheduleId);
  storeSchedule = useSelector((state) => {
    if (!storeSchedule) return storeSchedule;
    return populateEventData(state, interviewPlanJobStageId, cloneDeep(storeSchedule) as InterviewSchedule);
  });

  const fragmentSchedule = usePopulateEventData({ interviewPlan }, { scheduleId: selectedScheduleId });

  const schedule = interviewPlanEntityInScheduleFlowsEnabled ? fragmentSchedule : storeSchedule;

  const { filledTemplate, loading, placeholderFillerOptions } = useScheduleTemplateContent({
    renderType: RenderType.Html,
    applicationId,
    candidateId,
    jobId,
    jobStageId,
    templateId,
    schedule,
    timezone,
    zoomInfo,
    zoomInfos,
    customVideoMeetingLinkUrl,
    customVideoMeetingLinkUrls,
    videoMeetingLinkUrls,
    workspaceVideoLink,
    codingUrlByInterviewEvent,
    slotId,
    isFetch: true,
    candidateTimezone,
    taskId,
    assigneeId,
  });

  useEffect(() => {
    dispatch(setScheduleContentStepWaiting(loading));
  }, [dispatch, loading]);

  return useMemo(() => {
    return {
      filledTemplate,
      selectedScheduleId,
      schedule,
      zoomInfo,
      zoomInfos,
      customVideoMeetingLinkUrl,
      customVideoMeetingLinkUrls,
      videoMeetingLinkUrls,
      workspaceVideoLink,
      codingUrlByInterviewEvent,
      loading,
      placeholderFillerOptions,
    };
  }, [
    filledTemplate,
    selectedScheduleId,
    schedule,
    zoomInfo,
    zoomInfos,
    customVideoMeetingLinkUrl,
    customVideoMeetingLinkUrls,
    videoMeetingLinkUrls,
    workspaceVideoLink,
    codingUrlByInterviewEvent,
    loading,
    placeholderFillerOptions,
  ]);
};

useScheduleContent.fragments = {
  interviewPlan: gql`
    ${usePopulateEventData.fragments.interviewPlan}
    fragment useScheduleContent_interviewPlan on JobStage {
      id
      ...usePopulateEventData_interviewPlan
    }
  `,
};

export default useScheduleContent;
