import React, { useCallback, useState } from 'react';
import type { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { TextField } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { TimezoneOption, timezoneStrings } from '@modernloop/shared/datetime';
import { find } from 'lodash';

import Button from 'src/components/button';
import { TimezoneIcon, TriangleDownIcon } from 'src/components/icons';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/theme/type';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type BaseTimezoneSelectProps = BaseProps & {
  className?: string;
  alwaysEditMode?: boolean;
  label?: string | React.ReactNode;
  value?: string; // tz

  /**
   * If true, the input will take up the full width of its container.
   */
  fullWidth?: boolean;

  /**
   * If true, the select button will take up the full width of its container.
   */
  buttonFullWidth?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
  openOnFocus?: boolean;
  lastUsedTimezones: string[];
  additionalTimezones?: { [key: string]: string[] };
  getTimezoneLabel?: (timezone) => string;
  onChange?: (tz: string) => void;
  overrideCandidateTimezoneReadClass?: string;
};

const useAutoCompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textOverflow: 'ellipsis',
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '6px',
      padding: '0 8px',
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '&:hover .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '&:hover .MuiInput-underline:after': {
        border: 'none',
      },
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    candidateTimezoneRead: {
      maxWidth: '100%',
      padding: '0 8px',
      borderRadius: '6px',
      fontWeight: theme.typography.fontWeightRegular,
    },
    timezoneLabel: {
      padding: '4px',
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const BaseTimezoneSelect: FC<BaseTimezoneSelectProps> = ({
  className,
  dataTestId,
  alwaysEditMode,
  label,
  value,
  fullWidth,
  buttonFullWidth,
  disabled,
  disableClearable,
  openOnFocus,
  lastUsedTimezones,
  additionalTimezones,
  getTimezoneLabel,
  onChange,
  overrideCandidateTimezoneReadClass,
}) => {
  const classes = useStyles();
  const autoCompleteClasses = useAutoCompleteStyles();
  const [editMode, setEditMode] = useState(false);

  const autcompleteProps = {
    className,
    fullWidth,
    disableClearable,
    disabled,
  };

  const getTimezoneOption = (timezone: string, category: string): TimezoneOption | null => {
    const tzOption = timezoneStrings.find((option) => option.value === timezone);
    if (!tzOption) return null;
    const result: TimezoneOption = { ...tzOption, category };
    return result;
  };

  // Create the time options for all the last used timezones
  const recentTzs: TimezoneOption[] = [];

  if (additionalTimezones) {
    Object.keys(additionalTimezones).forEach((key) => {
      const timezones = additionalTimezones[key];
      if (timezones && timezones.length) {
        timezones.forEach((timezone) => {
          const tzOption = getTimezoneOption(timezone, key);
          if (!tzOption) return;
          recentTzs.push(tzOption);
        });
      }
    });
  }

  lastUsedTimezones.forEach((lastUsedTimezone) => {
    const tzOption = getTimezoneOption(lastUsedTimezone, 'Recent Timezones');
    if (!tzOption) return;
    recentTzs.push(tzOption);
  });

  const options = recentTzs.concat(timezoneStrings);

  const getOptionLabel = (option: TimezoneOption) => option.label;

  const renderOption = (option: TimezoneOption) => option.label;

  const getOptionFromValue = (val: string | undefined) => {
    return find(options, { value: val });
  };

  const handleChange = (event, option: TimezoneOption) => {
    if (onChange && option && option.value) {
      onChange(option.value);
      setEditMode(false);
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (tz: TimezoneOption) => `${tz.label},${tz.value},${tz.keywords}`,
  });

  const handleBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const timeOption = options.find((option) => option.value === event.target.value);
      const currentValue = find(options, { value })?.value;
      const newValue = timeOption ? timeOption.value : currentValue;
      setEditMode(false);

      if (onChange && newValue && newValue !== currentValue) {
        onChange(newValue);
      }
    },
    [onChange, options, value]
  );

  if (!alwaysEditMode && !editMode) {
    return (
      <Button
        fullWidth={buttonFullWidth}
        variant="outlined"
        disabled={disabled}
        className={overrideCandidateTimezoneReadClass || classes.candidateTimezoneRead}
        dataTestId={dataTestId}
        startIcon={<TimezoneIcon color={disabled ? 'min-contrast-grey' : 'max-contrast-grey'} />}
        endIcon={<TriangleDownIcon color={disabled ? 'min-contrast-grey' : 'max-contrast-grey'} />}
        label={getTimezoneLabel ? getTimezoneLabel(value) : getOptionFromValue(value)?.label ?? value}
        onClick={() => setEditMode(true)}
      />
    );
  }

  return (
    <Autocomplete
      {...autcompleteProps}
      openOnFocus
      selectOnFocus
      open={openOnFocus}
      classes={autoCompleteClasses}
      style={fullWidth ? undefined : { width: 300 }}
      options={options}
      groupBy={(option) => option.category}
      value={getOptionFromValue(value)}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      renderOption={renderOption}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus={!alwaysEditMode}
          data-testid={dataTestId}
          onBlur={handleBlur}
          label={label}
          fullWidth
          variant="standard"
        />
      )}
    />
  );
};

export default BaseTimezoneSelect;
