import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ModernloopIconProps = {
  height?: number;
  width?: number;
};

// The props for `ModernloopIcon` varies from other icons a bit because its color and font size needs to be same.
// But we should allow showing it in different sizes.
const ModernloopIcon = ({ height, width }: ModernloopIconProps): JSX.Element => {
  return (
    <svg
      width={width || '178'}
      height={height || '36'}
      viewBox="0 0 178 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.4798 27.5966C39.4576 27.5966 40.4354 27.3908 41.336 27.0305C42.2108 26.6446 43.0085 26.1299 43.6775 25.4609C44.3465 24.7662 44.8611 23.9943 45.2471 23.1194C45.633 22.2188 45.8132 21.241 45.8132 20.2633C45.8132 19.2598 45.633 18.3077 45.2471 17.4071C44.8611 16.5323 44.3465 15.7346 43.6775 15.0656C42.9827 14.3966 42.2108 13.882 41.336 13.496C40.4354 13.11 39.4576 12.9299 38.4798 12.9299C37.502 12.9299 36.5243 13.11 35.6237 13.496C34.7488 13.882 33.9769 14.3966 33.2822 15.0656C32.6131 15.7603 32.0985 16.5323 31.7126 17.4071C31.3523 18.3077 31.1465 19.2598 31.1465 20.2633C31.1465 21.241 31.3523 22.2188 31.7126 23.1194C32.0985 23.9943 32.6131 24.7662 33.2822 25.4609C33.9769 26.1299 34.7488 26.6446 35.6237 27.0305C36.5243 27.3908 37.502 27.5966 38.4798 27.5966ZM38.4798 16.7896C40.3839 16.7896 41.9535 18.3592 41.9535 20.2633C41.9535 22.1674 40.3839 23.737 38.4798 23.737C36.5757 23.737 35.0061 22.1674 35.0061 20.2633C35.0061 18.3592 36.5757 16.7896 38.4798 16.7896Z"
        fill="#095296"
      />
      <path
        d="M48.3683 23.1222C48.7543 23.9971 49.2689 24.7948 49.9379 25.4638C50.6327 26.1328 51.4046 26.6731 52.2794 27.0333C53.18 27.4193 54.1578 27.5994 55.1356 27.5994C56.1134 27.5994 57.0912 27.4193 57.9917 27.0333C58.8666 26.6731 59.6385 26.1328 60.3332 25.4638C61.0023 24.7948 61.5169 23.9971 61.9028 23.1222C62.2888 22.2216 62.4689 21.2696 62.4689 20.2661V7.78655L58.6093 6.5V13.8076C58.4034 13.7047 58.1976 13.6018 57.9917 13.5246C57.0912 13.1386 56.1391 12.9585 55.1356 12.9585C54.1578 12.9585 53.18 13.1386 52.2794 13.5246C51.4046 13.8848 50.6327 14.4252 49.9379 15.0942C49.2689 15.7632 48.7543 16.5608 48.3683 17.4357C48.0081 18.3363 47.8022 19.2883 47.8022 20.2661C47.8022 21.2696 48.0081 22.2216 48.3683 23.1222ZM55.1356 16.7924C57.0397 16.7924 58.6093 18.362 58.6093 20.2661C58.6093 22.1959 57.0397 23.7398 55.1356 23.7398C53.2315 23.7398 51.6619 22.1959 51.6619 20.2661C51.6619 18.362 53.2315 16.7924 55.1356 16.7924Z"
        fill="#095296"
      />
      <path
        d="M71.9334 27.5217C72.9369 27.5217 73.9147 27.3158 74.841 26.9041C75.7416 26.5182 76.5392 25.9521 77.2082 25.2574L74.4808 22.6843C73.8118 23.4047 72.9112 23.7907 71.9334 23.7907C70.6726 23.7907 69.5661 23.1217 68.9486 22.1439H79.1638V20.2656C79.1638 18.3357 78.4176 16.5088 77.0539 15.1451C75.6901 13.7813 73.8632 13.0094 71.9334 13.0094C69.9778 13.0094 68.1767 13.7813 66.7872 15.1451C65.4235 16.5088 64.6772 18.3357 64.6772 20.2656C64.6772 22.1954 65.4235 24.0223 66.7872 25.386C68.1767 26.7498 69.9778 27.5217 71.9334 27.5217ZM68.9486 18.4129C69.5661 17.4094 70.6726 16.7404 71.9334 16.7404C73.1685 16.7404 74.2749 17.4094 74.9182 18.4129H68.9486Z"
        fill="#095296"
      />
      <path
        d="M85.3455 27.418V21.0882C85.3455 18.618 87.3525 16.5852 89.8484 16.5852V12.7256C88.7163 12.7256 87.6098 12.9572 86.5806 13.3946C85.6028 13.8063 84.7022 14.4238 83.9303 15.1958C83.1584 15.942 82.5665 16.8425 82.1291 17.846C81.6917 18.8753 81.4858 19.956 81.4858 21.0882V27.418H85.3455Z"
        fill="#095296"
      />
      <path
        d="M103.079 15.2207C101.844 14.0114 100.223 13.3424 98.4989 13.3424C97.5726 13.3424 96.6977 13.5225 95.9 13.8827V12.9307H92.0404V19.2348C92.0147 19.4406 92.0146 19.6207 92.0146 19.8266H92.0404V27.4172H95.9V19.4406C96.0802 18.1798 97.1609 17.202 98.4989 17.202C99.9398 17.202 101.123 18.3856 101.123 19.8266V27.4172H104.983V19.823C104.983 18.0733 104.288 16.4522 103.079 15.2172V15.2207Z"
        fill="#095296"
      />
      <path d="M108.579 27.418H121.65V23.5833H112.615V7.34778L108.576 8.63432L108.579 27.418Z" fill="#095296" />
      <path
        d="M130.582 27.5966C131.56 27.5966 132.538 27.3908 133.439 27.0305C134.313 26.6446 135.111 26.1299 135.78 25.4609C136.449 24.7662 136.964 23.9943 137.35 23.1194C137.736 22.2188 137.916 21.241 137.916 20.2633C137.916 19.2598 137.736 18.3077 137.35 17.4071C136.964 16.5323 136.449 15.7346 135.78 15.0656C135.085 14.3966 134.313 13.882 133.439 13.496C132.538 13.11 131.56 12.9299 130.582 12.9299C129.605 12.9299 128.627 13.11 127.726 13.496C126.851 13.882 126.079 14.3966 125.385 15.0656C124.716 15.7603 124.201 16.5323 123.815 17.4071C123.455 18.3077 123.249 19.2598 123.249 20.2633C123.249 21.241 123.455 22.2188 123.815 23.1194C124.201 23.9943 124.716 24.7662 125.385 25.4609C126.079 26.1299 126.851 26.6446 127.726 27.0305C128.627 27.3908 129.605 27.5966 130.582 27.5966ZM130.582 16.7896C132.486 16.7896 134.056 18.3592 134.056 20.2633C134.056 22.1674 132.486 23.737 130.582 23.737C128.678 23.737 127.109 22.1674 127.109 20.2633C127.109 18.3592 128.678 16.7896 130.582 16.7896Z"
        fill="#095296"
      />
      <path
        d="M147.29 27.5966C148.268 27.5966 149.245 27.3908 150.146 27.0305C151.021 26.6446 151.819 26.1299 152.488 25.4609C153.157 24.7662 153.671 23.9943 154.057 23.1194C154.443 22.2188 154.623 21.241 154.623 20.2633C154.623 19.2598 154.443 18.3077 154.057 17.4071C153.671 16.5323 153.157 15.7346 152.488 15.0656C151.793 14.3966 151.021 13.882 150.146 13.496C149.245 13.11 148.268 12.9299 147.29 12.9299C146.312 12.9299 145.334 13.11 144.434 13.496C143.559 13.882 142.787 14.3966 142.092 15.0656C141.423 15.7603 140.909 16.5323 140.523 17.4071C140.162 18.3077 139.957 19.2598 139.957 20.2633C139.957 21.241 140.162 22.2188 140.523 23.1194C140.909 23.9943 141.423 24.7662 142.092 25.4609C142.787 26.1299 143.559 26.6446 144.434 27.0305C145.334 27.3908 146.312 27.5966 147.29 27.5966ZM147.29 16.7896C149.194 16.7896 150.764 18.3592 150.764 20.2633C150.764 22.1674 149.194 23.737 147.29 23.737C145.386 23.737 143.816 22.1674 143.816 20.2633C143.816 18.3592 145.386 16.7896 147.29 16.7896Z"
        fill="#095296"
      />
      <path
        d="M171.434 17.4329C171.048 16.558 170.533 15.7861 169.864 15.0914C169.17 14.4224 168.398 13.9077 167.523 13.5218C166.622 13.1358 165.67 12.9557 164.667 12.9557C163.689 12.9557 162.711 13.1358 161.811 13.5218C160.936 13.9077 160.164 14.4224 159.469 15.0914C158.8 15.7604 158.286 16.558 157.9 17.4329C157.539 18.3335 157.333 19.2855 157.333 20.289V31.2247L161.193 32.5113V26.7475C161.399 26.8504 161.605 26.9534 161.811 27.0306C162.711 27.4165 163.689 27.6224 164.667 27.6224C165.645 27.6224 166.622 27.4165 167.523 27.0306C168.398 26.6703 169.17 26.1557 169.864 25.461C170.533 24.792 171.048 24.02 171.434 23.1452C171.82 22.2446 172 21.2668 172 20.289C172 19.2855 171.82 18.3335 171.434 17.4329ZM164.667 23.7627C162.763 23.7627 161.193 22.1931 161.193 20.289C161.193 18.3849 162.763 16.8153 164.667 16.8153C166.571 16.8153 168.141 18.3849 168.141 20.289C168.141 22.1931 166.571 23.7627 164.667 23.7627Z"
        fill="#095296"
      />
      <path
        d="M23.9273 19.8559C23.9273 20.8804 24.781 21.734 25.8054 21.734C26.8583 21.734 27.712 20.8804 27.712 19.8559V9.35553C27.712 8.50184 27.1144 7.76196 26.2892 7.53431C25.464 7.33512 24.5818 7.70506 24.1835 8.44491L16.7565 22.1892L9.52858 8.47314C9.13018 7.70482 8.24803 7.3349 7.42282 7.53406C6.56912 7.76172 6 8.50159 6 9.35528V25.5186C6 26.5431 6.85369 27.3968 7.87811 27.3968C8.93101 27.3968 9.78471 26.5431 9.78471 25.5186V15.872L15.0774 26.401C15.4189 27.0271 16.045 27.397 16.7279 27.397C17.4108 27.397 18.0369 27.0271 18.3784 26.4295L23.9273 15.7014V19.8559ZM27.7119 25.5189C27.7119 24.466 26.8582 23.6123 25.8053 23.6123C24.7809 23.6123 23.9272 24.466 23.9272 25.5189C23.9272 26.5434 24.7809 27.397 25.8053 27.397C26.8582 27.397 27.7119 26.5433 27.7119 25.5189Z"
        fill="#095296"
      />
    </svg>
  );
};

export default ModernloopIcon;
