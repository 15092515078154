import React, { FC, useCallback, useMemo } from 'react';

import { ListItem, ListItemButton, Stack } from '@mui/material';

import { MemberSelect_UserInfoFragment } from 'src/generated/mloop-graphql';

import { UnknownUserIcon } from 'src/components/icons';
import Label from 'src/components/label';

import SingleMemberSelect from 'src/entities/Select/MemberSelect/SingleMemberSelect';
import MemberOption from 'src/entities/Select/MemberSelect/SingleMemberSelect/MemberOption';

import useEmployeeId from 'src/hooks/useEmployeeId';

import { EMPTY_UUID } from 'src/constants';

export const ScheduleTaskAssingeeFilterUnassignedItemId = EMPTY_UUID;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface AssigneeSelectProps {
  getLabel: () => React.ReactNode;
  onChange: (newMember: MemberSelect_UserInfoFragment | null) => void;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const AssigneeSelect: FC<AssigneeSelectProps> = ({ getLabel, onChange }) => {
  const currentEmployeeId = useEmployeeId();
  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: MemberSelect_UserInfoFragment) => {
      if (option.userId === ScheduleTaskAssingeeFilterUnassignedItemId) {
        return (
          <ListItem {...props}>
            <ListItemButton>
              <Stack direction="row" alignItems="center" spacing={1}>
                <UnknownUserIcon />
                <Label>Unassigned</Label>
              </Stack>
            </ListItemButton>
          </ListItem>
        );
      }

      if (!currentEmployeeId) return null;

      return (
        <ListItem {...props}>
          <ListItemButton>
            <MemberOption currentEmployeeId={currentEmployeeId} member={option} />
          </ListItemButton>
        </ListItem>
      );
    },
    [currentEmployeeId]
  );

  const additionalOptions = useMemo(() => {
    return [
      {
        userId: ScheduleTaskAssingeeFilterUnassignedItemId,
        userName: 'Unassigned',
        employee: {
          id: ScheduleTaskAssingeeFilterUnassignedItemId,
          slackImageUrl: null,
          fullName: 'Unassigned',
        },
      },
    ];
  }, []);

  return (
    <SingleMemberSelect
      additionalOptions={additionalOptions}
      renderOption={renderOption}
      onMemberSelect={(newUser) => {
        const selectedUser = newUser;
        if (selectedUser?.employee?.id === ScheduleTaskAssingeeFilterUnassignedItemId) {
          selectedUser.employee.id = null;
        }
        onChange(selectedUser);
      }}
      getLabel={getLabel}
    />
  );
};

export default AssigneeSelect;
