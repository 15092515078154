import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskCandidateSelfScheduleCreated_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import logError from 'src/utils/logError';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogContent from '../../Helpers/ActivityLogContent';

interface Fragments {
  activityLog: ActivityLogTaskCandidateSelfScheduleCreated_ActivityLogFragment;
}

const ActivityLogTaskCandidateSelfScheduleCreated: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;
  // eslint-disable-next-line no-underscore-dangle
  if (details?.__typename !== 'ActivityLogTaskEventDetails') {
    logError('ActivityLogTaskCandidateSelfScheduleCreated: details is not ActivityLogTaskEventDetails');
    return null;
  }

  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={`self-scheduled this task${details?.sourcingLinkId ? ' via sourcing link' : ''}`}
    >
      {details?.selfScheduleCandidateNote ? <ActivityLogContent content={details.selfScheduleCandidateNote} /> : null}
    </ActivityLogBase>
  );
};

ActivityLogTaskCandidateSelfScheduleCreated.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskCandidateSelfScheduleCreated_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogTaskEventDetails {
          __typename
          selfScheduleCandidateNote
          sourcingLinkId
        }
      }

      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskCandidateSelfScheduleCreated;
