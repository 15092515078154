import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TimeBlock, TimeBlockWeek } from 'src/types/preferenes';

import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';
import { keysToCamel_TYPE_UNSAFE_DO_NOT_USE } from 'src/utils/snakeToCamelCaseUtil';

export type EmployeeEmailAlias = {
  orgId: string;
  employeeId: string;
  email: string;
  isPrimary: boolean;
};

export type Employee = {
  id: string;
  orgId?: string;
  email?: string;
  givenName?: string;
  familyName?: string;
  slackImageUrl?: string;
  photoUrl?: string;
  googleUserId?: string;
  atsId?: string;
  fullName?: string;
  title?: string;
  timezone?: string;
  isArchived?: boolean;
  isDisabled?: boolean;

  isAtsDisabled?: boolean;
  linkedinUrl?: string;
  recruitingBlocks?: TimeBlockWeek; // jsonb column
  isGoogleDisabled?: boolean;
  workHours?: TimeBlockWeek; // jsonb column
  slackUserId?: string;
  interviewPauseDates?: TimeBlock[]; // jsonb column
  emailAlias?: EmployeeEmailAlias[];
  dateCreate?: string;
  dateUpdate?: string;
  useCalendarTimezone?: boolean;
  calendarTimezone?: string;
  phoneNumber?: string;
};

export type EmployeeLoadLimit = {
  employeeId: string;
  dailyInterviewLimit: number;
  weeklyInterviewLimit: number;
};

export type UserInterviewLoad = {
  date: string; // yyyy-MM-dd format
  dailyInterviewLoad: number;
  weeklyInterviewLoad: number;
};

export enum InterviewLimitType {
  NUMBER_OF_INTERVIEWS = 'NUMBER_OF_INTERVIEWS',
  NUMBER_OF_MINUTES = 'NUMBER_OF_MINUTES',
}

export type ScheduleOverride = {
  multiDay: boolean;
};

export type Schedule = {
  count: number;
  scrollToIndex: number;
  config: {
    scheduleOverride: ScheduleOverride;
  };
  byId: { [key: string]: InterviewSchedule };
};

type SchedulesState = Schedule;

const defaultScheduleOverride: ScheduleOverride = {
  multiDay: false,
};

const getInitialState = (): SchedulesState => {
  return {
    count: 0,
    scrollToIndex: -1,
    config: {
      scheduleOverride: defaultScheduleOverride,
    },
    byId: {},
  };
};

const schedulesSlice = createSlice({
  name: 'scheduleOptions',
  initialState: getInitialState(),
  reducers: {
    clear() {
      return getInitialState();
    },
    saveSchedules: (
      state: SchedulesState,
      action: PayloadAction<{ count: number; schedules: InterviewSchedule[] }>
    ) => {
      const { count, schedules } = action.payload;
      state.count = count;
      state.byId = {};

      if (!schedules) return;

      schedules.forEach((schedule) => {
        if (!schedule.id) return;
        state.byId[schedule.id] = keysToCamel_TYPE_UNSAFE_DO_NOT_USE(schedule);
      });

      state.scrollToIndex = 0;
    },

    updateScheduleScrollToIndex: (state: SchedulesState, action: PayloadAction<{ scrollToIndex: number }>) => {
      const { scrollToIndex } = action.payload;
      state.scrollToIndex = scrollToIndex;
    },

    setScheduleOverrideConfig: (state: SchedulesState, action: PayloadAction<ScheduleOverride>) => {
      state.config.scheduleOverride = action.payload;
    },
  },
});

export const { reducer } = schedulesSlice;
export default schedulesSlice;
