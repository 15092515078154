import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@mui/material';

import QueueModal from './QueueModal';

export const AddQueueModalOpenUrlParam = 'defaultAddQueueModalOpen';

const getUrlParam = (urlSearch: string, param: string) => {
  const params = new URLSearchParams(urlSearch);
  return params.get(param);
};

type Props = {
  disabled: boolean;
};

export const AddQueue: FC<Props> = ({ disabled }) => {
  const [open, setOpen] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    const openFromurl = getUrlParam(search, AddQueueModalOpenUrlParam);
    if (openFromurl && !disabled) {
      setOpen(true);
    }
  }, [search, disabled]);

  return (
    <>
      <Button variant="contained" disabled={disabled} color="primary" onClick={() => setOpen(true)}>
        New queue
      </Button>
      {open && (
        <QueueModal
          open={open}
          taskQueue={null}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
    </>
  );
};
