/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { useMediaQuery } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';

import Divider from 'src/components/Divider';
import IconButton from 'src/components/IconButton';
import Button from 'src/components/button';
import { MenuIcon, SearchIcon } from 'src/components/icons';
import Label from 'src/components/label';
import Tooltip from 'src/components/tooltip';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';
import { IconType, getIconFromEnum } from 'src/components/utils/icons';

import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';
import { Routes } from 'src/constants/routes';

import { useIsMLoopInternalOnly } from 'src/hooks/feature-flag/useIsMLoopInternalOnly';
import useAuth from 'src/hooks/useAuth';
import useIsNewOrgExperience from 'src/hooks/useIsNewOrgExperience';

import { setLeftNavExpanded } from 'src/store/actions/ui-state';

import { Theme } from 'src/theme/type';

import { OrganizationSettingsTab, getOrganizationSettingUrls } from 'src/urls/getOrganizationSettingUrls';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import DevToolsLinkButton from 'src/views-new/DevTools/DevToolsLinkButton';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import DevToolsNavButton from 'src/views-new/DevTools/DevToolsNavButton';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { ShowOmniSearchAction, useOmniSearchDispatch } from 'src/views-new/OmniSearch/OmniSearchProvider';

import { useDispatch } from 'src/store';

import AccountMenu from './AccountMenu';
import CopyDebugInfoNavButton from './CopyDebugInfoNavButton';

type LinkItem = {
  to: string;
  tooltip: string;
  text: string;
  iconType: IconType;
  filledIconType?: IconType;
  newTab?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
    },
    menuIconExpanded: {
      marginLeft: '6px',
    },
    nav: {
      height: '100%',
      transition: 'width 0.1s ease-in-out',
      backgroundColor: 'rgba(246, 246, 247, 0.5)',
    },
    navExpanded: {
      transition: 'width 0.1s ease-in-out',
      width: '200px',
      '& > div': {
        width: '100%',
      },
    },
    routerLink: {
      textDecoration: 'none',
    },
    iconButton: {
      height: '28px',
      width: '28px',
      padding: theme.spacing(1),
      borderRadius: '6px',
      border: 'none',
      '&:hover': {
        backgroundColor: theme.grey.alpha.min,
        boxShadow: 'none',
      },
    },
    iconButtonActive: {
      border: 'none',
      backgroundColor: `${theme.palette.info.main} !important`,
      '&:hover': {
        boxShadow: theme.shadows[1],
      },
    },
    button: {
      border: 'none',
      display: 'flex',
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderRadius: '6px',
      '&:hover': {
        color: theme.palette.info.main,
      },
    },
    buttonActive: {
      border: 'none',
      color: theme.palette.info.main,
      backgroundColor: `${theme.palette.info.main} !important`,
      '&:hover': {
        boxShadow: theme.shadows[1],
      },
    },
  })
);

export const LINKS: LinkItem[] = [
  {
    to: Routes.MySchedulingTasks,
    tooltip: 'My tasks',
    text: 'My tasks',
    iconType: 'MyTasksIcon',
    filledIconType: 'MyTasksIcon',
  },
  {
    to: Routes.AllSchedulingTasks,
    tooltip: 'All tasks',
    text: 'All tasks',
    iconType: 'AllSchedulingTasksIcon',
    filledIconType: 'AllSchedulingTasksIcon',
  },
  {
    to: Routes.Candidates,
    tooltip: 'Candidates',
    text: 'Candidates',
    iconType: 'CandidateIcon',
    filledIconType: 'CandidateIconFilled',
  },
  {
    to: Routes.UpcomingEvents,
    tooltip: 'Upcoming events',
    text: 'Upcoming events',
    iconType: 'ScheduleIcon',
    filledIconType: 'ScheduleIconFilled',
  },
  {
    to: Routes.Jobs,
    tooltip: 'Jobs',
    text: 'Jobs',
    iconType: 'JobIcon',
    filledIconType: 'JobIconFilled',
  },
  {
    to: Routes.Interviews,
    tooltip: 'Modules',
    text: 'Modules',
    iconType: 'MentorIcon',
    filledIconType: 'MentorIcon',
  },
  {
    to: Routes.Employees,
    tooltip: 'Directory',
    text: 'Directory',
    iconType: 'DirectoryIcon',
    filledIconType: 'DirectoryIconFilled',
  },
  {
    to: Routes.Metrics,
    tooltip: 'Metrics',
    text: 'Metrics',
    iconType: 'MetricsIcon',
    filledIconType: 'MetricsIconFilled',
  },
];

const AppNav: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useAuth();
  const omniSearchDispatch = useOmniSearchDispatch();
  const theme = useTheme() as Theme;
  const matches = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
  const [expanded, setExpanded] = useState(false);
  const [userExpanded, setUserExpanded] = useState(false);
  const { pathname } = useLocation();
  const helpCenterUrl = ZenDeskHelpCenterUrl.HOME;
  const isNewOrgExperience = useIsNewOrgExperience();
  const isExpanded = expanded || (!userExpanded && matches);
  const isMLOnly = useIsMLoopInternalOnly();
  const newOrgSettingsSidebar = useFlag('user_new_org_settings_sidebar');

  useEffect(() => {
    dispatch(setLeftNavExpanded(isExpanded));
  }, [dispatch, isExpanded]);

  const BOTTOM_LINKS: LinkItem[] = [
    {
      to: newOrgSettingsSidebar
        ? getOrganizationSettingUrls({
            tab: OrganizationSettingsTab.SCHEDULING,
          })
        : Routes.Organization,
      tooltip: 'Organization settings',
      text: 'Organization settings',
      iconType: 'SettingsIcon',
      filledIconType: 'SettingsIconFilled',
    },
    {
      to: helpCenterUrl,
      tooltip: 'Help & feedback',
      text: 'Help & feedback',
      iconType: 'HelpIcon',
      newTab: true,
    },
  ];

  const handleShowOmniSearch = () => {
    omniSearchDispatch(ShowOmniSearchAction);
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  function getIcon(iconType: IconType, filledIconType: IconType | undefined, isActive: boolean) {
    if (isActive && filledIconType) {
      return getIconFromEnum(filledIconType, { fontSize: 20, color: 'background' });
    }

    return getIconFromEnum(iconType, { fontSize: 20 });
  }

  const searchButton = isExpanded ? (
    <Button
      style={{
        justifyContent: 'flex-start',
      }}
      variant="outlined"
      size="small"
      fullWidth
      startIcon={<SearchIcon />}
      label="Search…"
      onClick={handleShowOmniSearch}
    />
  ) : (
    <Tooltip tooltip="Search…" placement="right">
      <IconButton className={classes.iconButton} onClick={handleShowOmniSearch}>
        <SearchIcon />
      </IconButton>
    </Tooltip>
  );

  const renderLink = (link: LinkItem) => {
    const isActive = pathname.startsWith(link.to);
    if (isExpanded) {
      const button = (
        <Button
          size="small"
          fullWidth
          variant="unstyled"
          className={clsx(classes.button, { [classes.buttonActive]: isActive })}
          color={isActive ? 'info' : undefined}
          startIcon={getIcon(link.iconType, link.filledIconType, isActive)}
          label={
            <Label color={isActive ? 'background' : 'max-contrast-grey'} variant="captions" fontWeight={500}>
              {link.text}
            </Label>
          }
        />
      );

      if (link.newTab) {
        return (
          <a className={classes.routerLink} href={link.to} rel="noopener noreferrer" target="_blank" tabIndex={-1}>
            {button}
          </a>
        );
      }

      return (
        <RouterLink key={link.to} to={link.to} className={classes.routerLink} tabIndex={-1}>
          {button}
        </RouterLink>
      );
    }

    const button = (
      <IconButton
        variant={isActive ? 'outlined' : undefined}
        className={clsx(classes.iconButton, { [classes.iconButtonActive]: isActive })}
      >
        {getIcon(link.iconType, link.filledIconType, isActive)}
      </IconButton>
    );

    if (link.newTab) {
      return (
        <Tooltip tooltip={link.tooltip} placement="right" key={link.to}>
          <a className={classes.routerLink} href={link.to} rel="noopener noreferrer" target="_blank" tabIndex={-1}>
            {button}
          </a>
        </Tooltip>
      );
    }

    return (
      <Tooltip tooltip={link.tooltip} placement="right" key={link.to}>
        <RouterLink to={link.to} className={classes.routerLink} tabIndex={-1}>
          {button}
        </RouterLink>
      </Tooltip>
    );
  };
  return (
    <Stack paddingTop={isExpanded ? 0 : '9px'} className={clsx(classes.nav, { [classes.navExpanded]: isExpanded })}>
      {isExpanded && (
        <Stack height="46px" padding="0px 12px 0px 16px" alignItems="center" direction="row" gap={0.5}>
          <Tooltip tooltip="Collapse" placement="right">
            <IconButton
              onClick={() => {
                setExpanded(false);
                setUserExpanded(true);
              }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Box
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <MaybeTooltip
              label={auth?.userInfo?.orgName ?? ''}
              tooltip={auth?.userInfo?.orgName ?? ''}
              labelProps={{ fontWeight: 600 }}
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line max-lines
              placement="right"
            />
          </Box>
        </Stack>
      )}

      {!isNewOrgExperience && isExpanded ? <Stack padding="4px 12px">{searchButton}</Stack> : null}

      {!(isExpanded && isNewOrgExperience) && (
        <Stack padding={isExpanded ? '8px 12px' : '0px 12px 8px'} gap="4px">
          {!isExpanded && (
            <Tooltip tooltip="Expand" placement="right">
              <IconButton
                className={clsx(classes.iconButton, { [classes.menuIconExpanded]: isExpanded })}
                onClick={() => {
                  setExpanded(true);
                  setUserExpanded(true);
                }}
              >
                <MenuIcon fontSize={20} />
              </IconButton>
            </Tooltip>
          )}
          {/* Only show links if the directory is connected. Otherwise, there is no employee id. */}
          {!isNewOrgExperience && !isExpanded ? searchButton : null}
          {!isNewOrgExperience ? LINKS.map((link) => renderLink(link)) : null}
        </Stack>
      )}
      {!isNewOrgExperience && <Divider />}

      <Stack gap="4px" padding="8px 12px">
        {BOTTOM_LINKS.map((link) => renderLink(link))}
        {/** Only show links if the directory is connected. Otherwise, there is no employee id. */}
        <AccountMenu expanded={isExpanded} size="small" />
        {isMLOnly && <CopyDebugInfoNavButton expanded={isExpanded} size="small" />}
        {isMLOnly && <DevToolsNavButton expanded={isExpanded} />}
        {isMLOnly && <DevToolsLinkButton expanded={isExpanded} />}
      </Stack>
    </Stack>
  );
};

export default AppNav;
