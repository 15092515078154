import React from 'react';

import BaseIcon, { Props } from './Icon';

const ShareIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.25 3.25C12.4216 3.25 11.75 3.92157 11.75 4.75C11.75 5.57843 12.4216 6.25 13.25 6.25C14.0784 6.25 14.75 5.57843 14.75 4.75C14.75 3.92157 14.0784 3.25 13.25 3.25ZM10.25 4.75C10.25 3.09315 11.5931 1.75 13.25 1.75C14.9069 1.75 16.25 3.09315 16.25 4.75C16.25 6.40685 14.9069 7.75 13.25 7.75C12.499 7.75 11.8125 7.47406 11.2862 7.01803L8.50384 8.75703C8.66246 9.13994 8.75 9.55975 8.75 10C8.75 10.4403 8.66246 10.8601 8.50384 11.243L11.2863 12.982C11.8125 12.5259 12.499 12.25 13.25 12.25C14.9069 12.25 16.25 13.5931 16.25 15.25C16.25 16.9069 14.9069 18.25 13.25 18.25C11.5931 18.25 10.25 16.9069 10.25 15.25C10.25 14.8871 10.3144 14.5392 10.4325 14.2172L7.62268 12.4611C7.05322 12.9527 6.31133 13.25 5.5 13.25C3.70507 13.25 2.25 11.7949 2.25 10C2.25 8.20507 3.70507 6.75 5.5 6.75C6.31133 6.75 7.05322 7.04729 7.62268 7.53889L10.4325 5.78275C10.3144 5.46076 10.25 5.11291 10.25 4.75ZM5.5 8.25C4.5335 8.25 3.75 9.0335 3.75 10C3.75 10.9665 4.5335 11.75 5.5 11.75C6.4665 11.75 7.25 10.9665 7.25 10C7.25 9.0335 6.4665 8.25 5.5 8.25ZM11.75 15.25C11.75 14.4216 12.4216 13.75 13.25 13.75C14.0784 13.75 14.75 14.4216 14.75 15.25C14.75 16.0784 14.0784 16.75 13.25 16.75C12.4216 16.75 11.75 16.0784 11.75 15.25Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ShareIcon;
