/**
 * List of all our help center articles. Use these when linking to ZenDesk from within the app.
 */
enum ZenDeskHelpCenterUrl {
  HOME = 'https://modernloop.zendesk.com/hc/en-us',

  // Keep this alphabetical for easier maintenance
  ADDING_MEMBERS = 'https://modernloop.zendesk.com/hc/en-us/articles/5285021011860-Members-Overview#h_01FZPBBYBBWFG458JGC6W918A6',
  ASHBY_INTEGRATION = 'https://modernloop.zendesk.com/hc/en-us/articles/15922721006868',
  SOURCING_LINK_ATS_INTEGRATION = 'https://modernloop.zendesk.com/hc/en-us/articles/24398097505172',
  BEST_PRACTICES_OVERVIEW = 'https://modernloop.zendesk.com/hc/en-us/articles/5644313882132-Best-Practices-Overview',
  CANDIDATE_AVAILABILITY = 'https://modernloop.zendesk.com/hc/en-us/articles/5914389634708-Candidate-Availability',
  CHROME_EXTENSION = 'https://chrome.google.com/webstore/detail/modernloop-extension/bdljmpplpbibfkejhafjkngnhkenpnoi',
  COMMUNICATION_TOKENS = 'https://modernloop.zendesk.com/hc/en-us/articles/5262478729876-Communication-Tokens',
  GOOGLE_CONNECT = 'https://modernloop.zendesk.com/hc/en-us/articles/4498236844692-Google-Integration',
  GOOGLE_WORKSPACE_APP = 'https://modernloop.zendesk.com/hc/en-us/articles/26232189138708--BETA-Google-Integration-Workspace-Install',
  GREENHOUSE_INTEGRATION = 'https://modernloop.zendesk.com/hc/en-us/articles/4498239432212-Greenhouse-Integration',
  GEM_INTEGRATION = 'https://modernloop.zendesk.com/hc/en-us/articles/26630258112276-Gem-Integration',
  INTERVIEWER_PORTAL_SETUP = 'https://modernloop.zendesk.com/hc/en-us/articles/27496705536404',
  INTERVIEWER_PORTAL_USAGE = 'https://modernloop.zendesk.com/hc/en-us/articles/27873870073364',
  LEVER_INTEGRATION = 'https://modernloop.zendesk.com/hc/en-us/articles/4498268778644-Lever-Integration',
  MISSING_CALENDAR = 'https://modernloop.zendesk.com/hc/en-us/articles/6322043165460-Calendar-FAQs',
  MISSING_CANDIDATE_AVAILABILITY = 'https://modernloop.zendesk.com/hc/en-us/articles/13595265361044',
  NO_ORG = 'https://modernloop.zendesk.com/hc/en-us/articles/12251408671892-General-FAQs#h_01GPE8S3ZPTPNG4FFB10J56QQM',
  RESCHEDULING_AND_UPDATING_INTERVIEWS = 'https://modernloop.zendesk.com/hc/en-us/articles/5284465882644-Rescheduling-and-Updating-Interviews',
  SCHEDULE_TASK = 'https://modernloop.zendesk.com/hc/en-us/articles/16984648838932',
  SOURCING_LINKS = 'https://modernloop.zendesk.com/hc/en-us/articles/25346337785748-Sourcing-Links-Overview',
  ZOOM_CONNECT = 'https://modernloop.zendesk.com/hc/en-us/articles/4498283224980-Zoom-Integration',
  WORKDAY_INTEGRATION_ISU = ' https://modernloop.zendesk.com/hc/en-us/articles/27933552209940-Workday-Integration#h_01J1QH1ESG9HFXHANBAV6RPKQX',
  WORKDAY_INTEGRATION_WEB_SERVICES = 'https://modernloop.zendesk.com/hc/en-us/articles/27933552209940-Workday-Integration#h_01J1QH1M4J5779TGH1YYBZRZH8',
  WORKDAY_INTEGRATION_RAAS_URLS = 'https://modernloop.zendesk.com/hc/en-us/articles/27933552209940-Workday-Integration#h_01J1QH1RZVRTHVMYA9EG32RSNP',
  CANDIDATE_PORTAL_FAQS = 'https://modernloop.zendesk.com/hc/en-us/articles/20702952474516-Candidate-Portal-How-to-FAQs',
}

export default ZenDeskHelpCenterUrl;
