import React, { FC, useState } from 'react';

import { BaseProps } from '@modernloop/shared/components';
import { CopyIcon } from '@modernloop/shared/icons';
import { Box, Checkbox, Grid, SxProps, Theme, Tooltip, Typography } from '@mui/material';

export interface Props extends BaseProps {
  value?: string;

  sx?: SxProps<Theme>;
}

const CopyTextField: FC<Props> = ({ value, sx, dataTestId }) => {
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const copyToClipboard = () => {
    if (typeof value !== 'string') {
      return;
    }
    navigator.clipboard.writeText(value as string);
    setCopySuccess(true);
  };

  let sxProps: SxProps = {
    overflowWrap: 'break-word',
    textAlign: 'left',
  };
  if (sx) {
    sxProps = {
      ...sxProps,
      ...sx,
    };
  }

  const hasCopy = !!navigator?.clipboard?.writeText;

  return (
    <Grid container wrap="nowrap" direction="row" alignItems="center" data-testid={dataTestId}>
      <Grid xs={10} item>
        <Typography sx={sxProps} fontWeight={400}>
          {value}
        </Typography>
      </Grid>
      {hasCopy ? (
        <Grid item>
          <Tooltip title={copySuccess ? 'Copied!' : 'Click to Copy'}>
            <Box
              role="button"
              onClick={copyToClipboard}
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ cursor: 'pointer' }}
            >
              <Checkbox
                checked={copySuccess}
                icon={<CopyIcon />}
                checkedIcon={<CopyIcon color="primary" />}
                name="copy"
              />
              <Typography variant="body2">{copySuccess ? 'Copied' : 'Copy'}</Typography>
            </Box>
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CopyTextField;
