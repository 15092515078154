import React, { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { FetchResult, gql, useApolloClient } from '@apollo/client';
import { Button, CircularProgress, Stack, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import {
  ApplicationDetailsTaskViewDocument,
  DevToolsTaskCancelDocument,
  DevToolsTaskSearchDocument,
  TaskStatus,
  useDevToolsApplicationTasksQuery,
} from 'src/generated/mloop-graphql';

import { useApolloRefetchQuery } from 'src/hooks/useApolloQuery';
import useEmployeeId from 'src/hooks/useEmployeeId';
import { useUrlSearchParams } from 'src/hooks/useUrlSearchParams';

import { PAGE_SIZE } from 'src/constants';

export const DEV_TOOLS_TASK_SEARCH_GQL = gql`
  query DevToolsTaskSearch($input: TaskSearchInput!) {
    taskSearch(input: $input) {
      items {
        id
        status
      }
    }
  }
`;

export const DEV_TOOLS_APPLICATION_TASKS = gql`
  query DevToolsApplicationTasks($applicationId: uuid!) {
    application(id: $applicationId) {
      tasks {
        id
        status
      }
    }
  }
`;

export const DEV_TOOLS_TASK_CANCEL_GQL = gql`
  mutation DevToolsTaskCancel($input: TaskCancelInput!) {
    taskCancel(input: $input) {
      id
    }
  }
`;

const TasksTab: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const employeeId = useEmployeeId();
  const urlSearchParam = useUrlSearchParams();
  const refetchQueries = useApolloRefetchQuery();

  const [deletingApplicationTasks, setDeletingApplicationTasks] = useState(false);

  const { pathname } = useLocation();
  const applicationId = urlSearchParam.get('applicationId');
  const showCancelAllTasksForApplication = applicationId && pathname.includes('/candidates');

  const { data: applicationTasksData } = useDevToolsApplicationTasksQuery({
    variables: { applicationId },
    skip: !showCancelAllTasksForApplication,
  });

  const cancelTasks = async (input: {
    assignees?: {
      assigneeIds: string[];
    };
    createdByIds?: string[];
  }) => {
    const tasks = await apolloClient.query({
      query: DevToolsTaskSearchDocument,
      variables: {
        input: {
          ...input,
          filterCompletedCanceled: true,
          pageInput: {
            limit: PAGE_SIZE,
          },
        },
      },
    });

    const taskIds = tasks.data.taskSearch.items.map((task: { id: string }) => task.id);

    if (taskIds.length > 0) {
      // Cancel up to 10 tasks then wait for 2 seconds then continue cancelling in chunks of 10
      const taskChunks = _.chunk(taskIds, 10);

      for (let i = 0; i < taskChunks.length; i++) {
        const cancelPromises = taskChunks[i].map((taskId) => {
          return apolloClient.mutate({
            mutation: DevToolsTaskCancelDocument,
            variables: {
              input: {
                taskId,
              },
            },
          });
        });

        // eslint-disable-next-line no-await-in-loop
        await Promise.all(cancelPromises);
        // Add a second delay between each chunk of 10 tasks
        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    } else {
      enqueueSnackbar('No tasks to cancel', { variant: 'info' });
    }
  };
  const handleCancelMyAssignedTasks = () => {
    if (!employeeId) return;

    cancelTasks({
      assignees: {
        assigneeIds: [employeeId],
      },
    });
  };

  const handleCancelMyCreatedTasks = () => {
    if (!employeeId) return;

    cancelTasks({
      createdByIds: [employeeId],
    });
  };

  const handleCancelTasksForApplication = () => {
    if (!applicationTasksData?.application?.tasks.length) return;

    setDeletingApplicationTasks(true);

    const taskIds = applicationTasksData.application.tasks
      .filter((task) => task.status !== TaskStatus.Canceled)
      .map((task) => task.id);
    const taskChunks = _.chunk(taskIds, 10);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let promises: Promise<FetchResult<any, Record<string, any>, Record<string, any>>>[] = [];
    taskChunks.forEach(async (chunk) => {
      const chunkPromises = chunk.map((taskId) => {
        return apolloClient.mutate({
          mutation: DevToolsTaskCancelDocument,
          variables: {
            input: {
              taskId,
            },
          },
        });
      });
      promises = promises.concat(chunkPromises);
    });

    // eslint-disable-next-line promise/catch-or-return
    Promise.all(promises)
      .then((result) => {
        enqueueSnackbar('Cancelled tasks for application', { variant: 'info' });
        return result;
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to cancel tasks for application ${error.message}`, { variant: 'error' });
      })
      .finally(() => {
        setDeletingApplicationTasks(false);
        refetchQueries([ApplicationDetailsTaskViewDocument]);
      });
  };

  return (
    <Stack spacing={1}>
      {/* TODO: Add a textfield where we can copy in an application id and open the extension URL for it */}
      <Tooltip title="Clicking this will cancel the tasks without confirmation">
        <Button
          color="error"
          onClick={() => {
            handleCancelMyAssignedTasks();
          }}
        >
          Cancel my assigned tasks
        </Button>
      </Tooltip>
      <Tooltip title="Clicking this will cancel the tasks without confirmation">
        <Button
          color="error"
          onClick={() => {
            handleCancelMyCreatedTasks();
          }}
        >
          Cancel my created tasks
        </Button>
      </Tooltip>
      {showCancelAllTasksForApplication && (
        <Tooltip title="Clicking this will cancel the tasks without confirmation for the current application">
          <Button
            color="error"
            onClick={handleCancelTasksForApplication}
            disabled={deletingApplicationTasks}
            endIcon={deletingApplicationTasks ? <CircularProgress size={24} /> : undefined}
          >
            Cancel tasks for application
          </Button>
        </Tooltip>
      )}
      {/* TODO: Add a tool that gets an extension URL for a given application - open in new tab or copy */}
    </Stack>
  );
};

export default TasksTab;
