import { SvgIcon, SvgIconProps } from './SvgIcon';

export const TextBubbleFilledIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0.25C4.16751 0.25 0.25 4.16751 0.25 9C0.25 13.8325 4.16751 17.75 9 17.75C10.18 17.75 11.3071 17.516 12.3361 17.0913L15.574 17.5539C16.7289 17.7189 17.7189 16.7289 17.5539 15.574L17.0913 12.3361C17.516 11.3071 17.75 10.18 17.75 9C17.75 4.16751 13.8325 0.25 9 0.25ZM6 6.25C5.58579 6.25 5.25 6.58579 5.25 7C5.25 7.41421 5.58579 7.75 6 7.75H12C12.4142 7.75 12.75 7.41421 12.75 7C12.75 6.58579 12.4142 6.25 12 6.25H6ZM5.25 11C5.25 10.5858 5.58579 10.25 6 10.25H9C9.41421 10.25 9.75 10.5858 9.75 11C9.75 11.4142 9.41421 11.75 9 11.75H6C5.58579 11.75 5.25 11.4142 5.25 11Z"
      />
    </SvgIcon>
  );
};
