import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import DialogPage, { DialogActions, DialogContent, DialogTitle } from 'src/components/Dialog/DialogPage';

import useDeleteCandidateAvailabilityOptions from './useDeleteCandidateAvailabilityOptions';

type Props = {
  candidateAvailabilityOptionsId: string;
  onClose: () => void;
  onDelete?: () => void;
};

const DeleteCandidateAvailabilityConfirmationDialog = ({
  candidateAvailabilityOptionsId,
  onClose,
  onDelete,
}: Props): JSX.Element => {
  const [deleting, setDeleting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { deleteCandidateAvailabilityOptionsMutation } = useDeleteCandidateAvailabilityOptions();

  const handleDelete = async () => {
    const optionsId = candidateAvailabilityOptionsId;
    if (!optionsId) return;

    setDeleting(true);

    try {
      await deleteCandidateAvailabilityOptionsMutation({
        variables: { id: optionsId },
      });
      onDelete?.();
      onClose();
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
    setDeleting(false);
  };
  return (
    <DialogPage onClose={onClose}>
      <DialogContent>
        <DialogTitle title="Cancel request?" />
        Canceling this request will make it impossible for the candidate to edit their availability from the current
        availability open request link. You can always create a new request link for the candidate to gather more
        availability for this stage.
      </DialogContent>
      <DialogActions
        submitOptions={{
          label: 'Cancel request',
          onClick: handleDelete,
          isDangerous: true,
          isDisabled: deleting,
        }}
        cancelOptions={{
          label: "Don't cancel",
          onClick: onClose,
        }}
      />
    </DialogPage>
  );
};

export default DeleteCandidateAvailabilityConfirmationDialog;
