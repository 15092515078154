/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Calendar, useUtils } from '@material-ui/pickers';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports, modernloop/restrict-imports.cjs
import { CalendarProps } from '@material-ui/pickers/views/Calendar/Calendar';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import clsx from 'clsx';
import { addDays, addMonths, differenceInMinutes, endOfDay, isPast, parseISO, startOfDay, subMonths } from 'date-fns';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';
import { uniq } from 'lodash';
import moment from 'moment';
import { bind, unbind } from 'mousetrap';

import Stack from 'src/components/Stack';
import Label from 'src/components/label';

import IsoTimestamp, { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { getDateFromYyyyMmDdStr, getTimeInTimezone } from 'src/utils/dateUtils';

import { Theme } from 'src/theme';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type MultipleDatePickerProps = {
  /**
   * These dates are showed with a gray border indicating that these are preferred dates.
   * Dates in UTC that represent the start of day in `timezone`
   * The dates passed are converted to midnight time for the timezone if not already.
   * E.g. `2022-02-23T18:30:00.000Z` is midnight `2022-02-24T00:00:00.000Z` in Asia/Kolkatta
   */
  preferredDatesUTC: IsoTimestamp[];

  /**
   * These dates are the ones that are selected.
   * Dates in UTC that represent the start of day in `timezone`
   * The dates passed are converted to midnight time for the timezone if not already.
   * E.g. `2022-02-23T18:30:00.000Z` is midnight `2022-02-24T00:00:00.000Z` in Asia/Kolkatta
   */
  selectedDatesUTC: IsoTimestamp[];

  /**
   * Timezone is used to display dates formatted in the correct timezone.
   */
  timezone: string;

  /**
   * Dates before this are disabled
   */
  minDate?: IsoTimestamp;

  /**
   * Dates after this are disabled
   */
  maxDate?: IsoTimestamp;

  /**
   * Number of months to show in view at a time
   * Supported values (1, 2)
   */
  numberOfMonths?: 1 | 2;

  disablePast?: boolean;

  className?: string;

  shouldDisableDate?: (day: IsoTimestamp) => boolean;

  /**
   * The dates are start of day for the time represented by `timezone`
   * E.g.
   *   - `2022-02-23T18:30:00.000Z` is midnight in Asia/Kolkatta
   *   - `2022-02-23T08:00:00.000Z` is midnight in America/Los_Angeles
   */
  onSelectedDatesChanged: (newDates: IsoTimestamp[]) => void;

  onMonthChange?: (newMonth: IsoTimestamp) => void;

  renderDay?: CalendarProps['renderDay'];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: Partial<MultipleDatePickerProps>) => ({
      margin: '0 auto',
      overflow: 'hidden',
      width: `${(props.numberOfMonths ?? 1) * 300 + ((props.numberOfMonths ?? 1) - 1) * 16}px`,
      userSelect: 'none',

      '& .MuiPickersCalendarHeader-switchHeader': {
        '& .MuiPickersCalendarHeader-transitionContainer': {
          '& p': {
            fontWeight: 600,
          },
        },
      },
    }),
    calendarButton: {
      '& button': {
        height: '32px',
        width: '32px',
        margin: '2px 4px',
      },
    },
    calendarButtonBorder: {
      '& button': {
        border: `1px solid ${theme.palette.border}`,
        borderRadius: '50%',
      },
    },
    preferredDate: {
      '& button': {
        background: theme.palette.common.transparent,
        border: `1px solid ${theme.palette.info.main}`,
        color: 'inherit',
        '&:hover': {
          background: theme.palette.action.hover,
          boxShadow: theme.shadows[1],
        },
      },
    },
    selectedDate: {
      '& button': {
        border: `1px solid ${theme.palette.info.main}`,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: theme.shadows[1],
        },
      },
    },
    normalizeSelectedDate: {
      '& button': {
        background: theme.palette.common.transparent,
        border: `1px solid ${theme.palette.border}`,
        color: theme.palette.text.primary,
        '&:hover': {
          background: theme.palette.action.hover,
          boxShadow: theme.shadows[1],
        },
      },
    },
    normalizeSelectedDisabledDate: {
      '& button': {
        background: theme.palette.common.transparent,
      },
    },
  })
);

const MultipleDatePicker = ({
  preferredDatesUTC,
  selectedDatesUTC,
  timezone,
  minDate,
  maxDate,
  numberOfMonths = 1,
  shouldDisableDate,
  className,
  onSelectedDatesChanged,
  disablePast = true,
  onMonthChange,
  renderDay,
}: MultipleDatePickerProps): JSX.Element => {
  const classes = useStyles({ numberOfMonths });
  const localTimezone = getLocalTimezone();
  const timezoneOffset = getTimezoneOffset(timezone);
  const localTimezoneOffset = getTimezoneOffset(localTimezone);

  const utils = useUtils();
  /**
   * The MUI `<Calendar />` component we use has logic in its componentDidMount to check if shouldDisableDay callback is passed
   * and use it to check if the `date` prop passed in the disabled. If disabled then it calls `onChange` callback with a date that is not disabled.
   * This causes the selected dates to be reset.
   *
   * This logic in `<Calendar />` is an issue because we use it to display multiple selected dates,
   * but it is designed to support single selected date only.
   *
   * We are using `isInitialRender` to skip passing `shouldDisableDate` in initial renders
   */
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [shiftPressed, setShiftPressed] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsInitialRender(false);
    }, 50);
  }, []);

  useEffect(() => {
    // Listen for shift key press so that we can select multiple dates in a single go.
    bind(['shift'], () => setShiftPressed(true), 'keydown');
    bind(['shift'], () => setShiftPressed(false), 'keyup');

    return () => {
      unbind(['shift'], 'keydown');
      unbind(['shift'], 'keyup');
    };
  }, []);

  /**
   * In both the useMemo callback functions below, timezone conversion is skipped when
   * the local timezone is to the east of passed timezone.
   * I don't know why this is required 😅 but with this change this component shows dates correctly.
   *
   * While debugging I noticed that when the passed `timezone` is to the west of local timezone then dates are working fine
   * but not when passed `timezone` is to the east of local timezone, hence this change.
   */
  const initialSelectedDatesMs = useMemo(() => {
    return selectedDatesUTC.map((selectedDate) => {
      const time =
        timezoneOffset - localTimezoneOffset > 0 ? utcToZonedTime(selectedDate, timezone) : parseISO(selectedDate);
      const result = getTimeInTimezone(time, localTimezone, 0, 0).getTime();
      return result;
    });
  }, [selectedDatesUTC, localTimezone, localTimezoneOffset, timezone, timezoneOffset]);

  const preferredDatesMs = useMemo(() => {
    return preferredDatesUTC.map((preferredDate) => {
      const time =
        timezoneOffset - localTimezoneOffset > 0 ? utcToZonedTime(preferredDate, timezone) : parseISO(preferredDate);
      return getTimeInTimezone(time, localTimezone, 0, 0).getTime();
    });
  }, [localTimezone, localTimezoneOffset, preferredDatesUTC, timezone, timezoneOffset]);

  const [selectedDatesUTCMs, setSelectedDatesUTCMs] = useState(initialSelectedDatesMs);

  useEffect(() => {
    setSelectedDatesUTCMs(initialSelectedDatesMs);
  }, [initialSelectedDatesMs]);

  const nonEmptyUTCTime = useMemo(() => {
    if (initialSelectedDatesMs.length) {
      return new Date(initialSelectedDatesMs[0]);
    }

    if (preferredDatesMs.length) {
      return new Date(preferredDatesMs[0]);
    }

    return startOfDay(minDate ? parseISO(minDate) : new Date());
  }, [initialSelectedDatesMs, minDate, preferredDatesMs]);

  const [currentUTCDate, setCurrentUTCDate] = useState(nonEmptyUTCTime);

  const defaultRenderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
    // eslint-disable-next-line max-params
  ) => {
    const isDayDisabled =
      Boolean(shouldDisableDate && day && shouldDisableDate(assertIsoTimestamp(day.toDate().toISOString()))) ||
      Boolean(day && minDate && utils.isBeforeDay(day, moment(startOfDay(utcToZonedTime(minDate, timezone))))) ||
      Boolean(day && maxDate && utils.isAfterDay(day, moment(endOfDay(utcToZonedTime(maxDate, timezone)))));

    const defaultDayJsx = (
      <Label
        dataTestId="multiple-date-picker-default-day"
        className={clsx(classes.calendarButton, { [classes.calendarButtonBorder]: !isDayDisabled })}
      >
        {dayComponent}
      </Label>
    );
    if (!day || !selectedDate) return defaultDayJsx;
    if (disablePast && isPast(endOfDay(day.toDate()))) {
      return (
        <Label
          dataTestId="multiple-date-picker-default-day"
          className={clsx(classes.calendarButton, classes.normalizeSelectedDisabledDate)}
        >
          {defaultDayJsx}
        </Label>
      );
    }

    const dayMs = day.toDate().getTime();

    if (initialSelectedDatesMs.includes(dayMs)) {
      return (
        <Label
          dataTestId="multiple-date-picker-selected-day"
          className={clsx(classes.calendarButton, classes.selectedDate)}
        >
          {dayComponent}
        </Label>
      );
    }

    if (preferredDatesMs.includes(dayMs)) {
      return (
        <Label
          dataTestId="multiple-date-picker-preferred-day"
          className={clsx(classes.calendarButton, classes.preferredDate)}
        >
          {dayComponent}
        </Label>
      );
    }

    if (day.toDate().getDate() === currentUTCDate.getDate()) {
      return (
        <Label
          dataTestId="multiple-date-picker-default-day"
          className={clsx(classes.calendarButton, {
            [classes.normalizeSelectedDate]: !isDayDisabled,
            [classes.normalizeSelectedDisabledDate]: isDayDisabled,
          })}
        >
          {dayComponent}
        </Label>
      );
    }

    return defaultDayJsx;
  };

  const handleShouldDisableDate = (day: MaterialUiPickersDate): boolean => {
    if (!shouldDisableDate || !day) return false;

    return shouldDisableDate(assertIsoTimestamp(day.toDate().toISOString()));
  };

  const handleSelect = (date: MaterialUiPickersDate) => {
    if (!date) return;

    const dateMs = getDateFromYyyyMmDdStr(
      `${date.toDate().getFullYear()}-${date.toDate().getMonth() + 1}-${date.toDate().getDate()}`
    ).getTime();

    let newSelectedDatesUTCMs: number[] = [];
    if (shiftPressed && selectedDatesUTCMs.length > 0) {
      const sortedSelectedDatesUTCMs = [...selectedDatesUTCMs].sort();
      const dateMsIndex = sortedSelectedDatesUTCMs.indexOf(dateMs);
      if (dateMsIndex === -1) {
        // if date is not present then select all dates between the last selected date and this date
        const dateToPrefillFrom = [...sortedSelectedDatesUTCMs].reverse().find((selectedMs) => {
          return dateMs > selectedMs;
        });
        const datesToFill: number[] = [];
        if (dateToPrefillFrom) {
          let nextDate = dateToPrefillFrom;
          while (nextDate < dateMs) {
            nextDate = addDays(nextDate, 1).getTime();
            datesToFill.push(nextDate);
          }
        }
        newSelectedDatesUTCMs = [...sortedSelectedDatesUTCMs, ...datesToFill];
      } else {
        // if date is present then remove all dates between the last selected date and this date
        const subArray = sortedSelectedDatesUTCMs.slice(0, dateMsIndex);
        let dateMsCopy = dateMs;

        while (subArray.length) {
          const lastDate = subArray.pop();
          if (lastDate) {
            const diff = differenceInMinutes(dateMsCopy, lastDate);

            // The 1380 and 1500 are the minutes difference between the dates when the DST shift happens.
            if (diff === 1440 || diff === 1380 || diff === 1500) {
              dateMsCopy = lastDate;
            }
          } else {
            break;
          }
        }

        const dateToDeselectFromIndex = sortedSelectedDatesUTCMs.indexOf(dateMsCopy);
        newSelectedDatesUTCMs = [
          ...sortedSelectedDatesUTCMs.slice(0, dateToDeselectFromIndex),
          ...sortedSelectedDatesUTCMs.slice(dateMsIndex + 1),
        ];
      }
    } else if (selectedDatesUTCMs.includes(dateMs)) {
      newSelectedDatesUTCMs = selectedDatesUTCMs.filter((selectedMs) => selectedMs !== dateMs);
    } else {
      newSelectedDatesUTCMs = [...selectedDatesUTCMs, dateMs];
    }

    newSelectedDatesUTCMs = uniq(newSelectedDatesUTCMs);
    setSelectedDatesUTCMs(newSelectedDatesUTCMs);
    onSelectedDatesChanged(
      newSelectedDatesUTCMs.sort().map((newDateMs) => {
        const result = getTimeInTimezone(new Date(newDateMs), timezone, 0, 0);
        return assertIsoTimestamp(result.toISOString());
      })
    );
  };

  const handleFirstMonthChange = (newMonth: MaterialUiPickersDate) => {
    if (!newMonth) return;
    if (onMonthChange) onMonthChange(assertIsoTimestamp(newMonth.toISOString()));
    setCurrentUTCDate(newMonth.toDate());
  };

  const handleSecondMonthChange = (newMonthDate: MaterialUiPickersDate) => {
    if (!newMonthDate) return;
    setCurrentUTCDate(subMonths(newMonthDate.toDate(), 1));
  };

  return (
    <Stack
      justifyContent="space-between"
      wrap="nowrap"
      className={clsx(classes.root, className)}
      itemStyles={numberOfMonths === 1 ? { 0: { margin: '0 auto' } } : undefined}
    >
      <Calendar
        allowKeyboardControl={false}
        date={moment(startOfDay(currentUTCDate))}
        disablePast={disablePast}
        renderDay={renderDay || defaultRenderDay}
        minDate={minDate ? moment(startOfDay(utcToZonedTime(minDate, timezone))) : undefined}
        maxDate={maxDate ? moment(endOfDay(utcToZonedTime(maxDate, timezone))) : undefined}
        onChange={handleSelect}
        onMonthChange={handleFirstMonthChange}
        shouldDisableDate={isInitialRender ? undefined : handleShouldDisableDate}
        rightArrowButtonProps={numberOfMonths === 2 ? { style: { visibility: 'hidden' } } : undefined}
      />
      {numberOfMonths === 2 && (
        <Calendar
          allowKeyboardControl={false}
          date={moment(addMonths(startOfDay(currentUTCDate), 1))}
          disablePast={disablePast}
          renderDay={renderDay || defaultRenderDay}
          minDate={minDate ? moment(startOfDay(utcToZonedTime(minDate, timezone))) : undefined}
          maxDate={maxDate ? moment(endOfDay(utcToZonedTime(maxDate, timezone))) : undefined}
          onChange={handleSelect}
          onMonthChange={handleSecondMonthChange}
          shouldDisableDate={isInitialRender ? undefined : handleShouldDisableDate}
          leftArrowButtonProps={{ style: { visibility: 'hidden' } }}
        />
      )}
    </Stack>
  );
};

export default MultipleDatePicker;
