import { useCallback } from 'react';

import { ScheduleTaskDocument, ScheduleTaskRequirementsDocument } from 'src/generated/mloop-graphql';

import { useApolloRefetchQuery } from 'src/hooks/useApolloQuery';

const useRefetchScheduleTaskQuery = () => {
  const apolloRefetchQuery = useApolloRefetchQuery();

  return useCallback(async () => {
    await apolloRefetchQuery([ScheduleTaskDocument, ScheduleTaskRequirementsDocument]);
  }, [apolloRefetchQuery]);
};

export default useRefetchScheduleTaskQuery;
