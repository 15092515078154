import React, { FC, useState } from 'react';

import { Dialog, DialogProps } from '@modernloop/shared/components';
import { TextField } from '@mui/material';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type EditNoteModalProps = Pick<DialogProps, 'open' | 'onClose'> & {
  note: string;
  onSubmit: (newNote: string) => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const EditNoteModal: FC<EditNoteModalProps> = ({ note, onClose, onSubmit, open }) => {
  const [noteValue, setNoteValue] = useState<string>(note.trim());

  return (
    <ConditionalThemeProvider>
      <Dialog
        open={open}
        onClose={onClose}
        title="Edit comment"
        cancelOptions={{ label: 'Cancel', onClick: () => onClose?.() }}
        submitOptions={{
          onClick: () => {
            onSubmit(noteValue);
          },
          label: 'Save',
          isDisabled: noteValue === note,
        }}
      >
        <TextField
          autoFocus
          multiline
          minRows={3}
          fullWidth
          defaultValue={noteValue}
          onChange={(e) => {
            setNoteValue(e.target.value.trim());
          }}
          style={{ minHeight: 'unset', whiteSpace: 'pre-line' }}
        />
      </Dialog>
    </ConditionalThemeProvider>
  );
};

export default EditNoteModal;
