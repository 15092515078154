import type { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

// import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60, // 1 minute in ms
    },
  },
});

/**
 * Provides the react-query envoriment and dev tools.
 */
export const ReactQueryClientProvider: FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-left" /> */}
    </QueryClientProvider>
  );
};
