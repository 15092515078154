import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import {
  AppsIcon,
  CandidatePortalIcon,
  IntegrationsIcon,
  LockIcon,
  MultipleUsersIcon,
  NotificationIcon,
  PreferencesIcon,
  SchedulingIcon,
  TemplateIcon,
  WrenchSetupIcon,
} from '@modernloop/shared/icons';

import { AllSchedulingTasksIcon } from 'src/components/icons';
import Directory from 'src/components/icons/Directory';

import useIsNewOrgExperience from 'src/hooks/useIsNewOrgExperience';
import { useIsPremiumCandidatePortalEnabled } from 'src/hooks/useIsPremiumCandidatePortalEnabled';

import { AccountSettingsTab, AccountSettingsUrlType, getAccountSettingUrls } from 'src/urls/getAccountSettingUrls';
import {
  OrganizationSettingsTab,
  OrganizationSettingsUrlType,
  getOrganizationSettingUrls,
} from 'src/urls/getOrganizationSettingUrls';

// Nothing is optional
type NavType = {
  id: string;
  label: string;
  isShown: boolean;
  to: OrganizationSettingsUrlType | AccountSettingsUrlType;
  icon: ReactNode;
  isActive: boolean;
  selectedIcon: ReactNode;

  /* Future scope if needed */
  // isDisabled : isNewOrgExperience
  // tooltip: string | undefined,
  // caption: string | undefined,
};

// Nothing is optional
type SettingsNavItem = {
  id: string;
  label: string;
  isShown: boolean;
  navs: NavType[];
  showDivider: boolean;

  /* Future scope if needed */
  // caption: string | undefined
  // isDisabled : isNewOrgExperience
  // tooltip: string | undefined,
  // infoIcon: string | undefined
};

export const useGetOrgSettingsNavItems = (): SettingsNavItem[] => {
  const isNewOrgExperience = useIsNewOrgExperience();

  const [isPremiumCandidatePortalEnabled] = useIsPremiumCandidatePortalEnabled();

  const { pathname } = useLocation();

  return [
    {
      id: 'My account',
      label: 'My account',
      isShown: true,
      showDivider: true,
      navs: [
        {
          id: 'General',
          label: 'General',
          selectedIcon: undefined,
          to: getAccountSettingUrls({ tab: AccountSettingsTab.GENERAL }),
          icon: <PreferencesIcon color="inherit" />,
          isShown: !isNewOrgExperience,
          isActive: pathname === getAccountSettingUrls({ tab: AccountSettingsTab.GENERAL }),
        },
        {
          id: 'My notifications',
          label: 'My notifications',
          selectedIcon: undefined,
          to: getAccountSettingUrls({ tab: AccountSettingsTab.NOTIFICATIONS }),
          icon: <NotificationIcon color="inherit" />,
          isShown: !isNewOrgExperience,
          isActive: pathname === getAccountSettingUrls({ tab: AccountSettingsTab.NOTIFICATIONS }),
        },
        {
          id: 'Apps',
          label: 'Apps',
          selectedIcon: undefined,
          to: getAccountSettingUrls({ tab: AccountSettingsTab.APPS }),
          icon: <AppsIcon color="inherit" />,
          isShown: !isNewOrgExperience,
          isActive: pathname === getAccountSettingUrls({ tab: AccountSettingsTab.APPS }),
        },
      ],
    },
    {
      id: 'Organization',
      label: 'Organization',
      isShown: true,
      showDivider: false,
      navs: [
        {
          id: 'Scheduling',
          label: 'Scheduling',
          selectedIcon: undefined,
          to: getOrganizationSettingUrls({ tab: OrganizationSettingsTab.SCHEDULING }),
          icon: <SchedulingIcon color="inherit" />,
          isShown: !isNewOrgExperience,
          isActive: pathname === getOrganizationSettingUrls({ tab: OrganizationSettingsTab.SCHEDULING }),
        },
        {
          id: 'Interviewers',
          label: 'Interviewers',
          selectedIcon: undefined,
          to: getOrganizationSettingUrls({ tab: OrganizationSettingsTab.INTERVIEWERS }),
          icon: <Directory color="inherit" />,
          isShown: !isNewOrgExperience,
          isActive: pathname === getOrganizationSettingUrls({ tab: OrganizationSettingsTab.INTERVIEWERS }),
        },
        {
          id: 'Templates',
          label: 'Templates',
          selectedIcon: undefined,
          to: getOrganizationSettingUrls({ tab: OrganizationSettingsTab.TEMPLATES }),
          icon: <TemplateIcon color="inherit" />,
          isShown: !isNewOrgExperience,
          isActive: pathname === getOrganizationSettingUrls({ tab: OrganizationSettingsTab.TEMPLATES }),
        },
        {
          id: 'Candidate Portal',
          label: 'Candidate Portal',
          selectedIcon: undefined,
          to: getOrganizationSettingUrls({ tab: OrganizationSettingsTab.CANDIDATE_PORTAL }),
          icon: <CandidatePortalIcon color="inherit" />,
          isShown: !!(!isNewOrgExperience && isPremiumCandidatePortalEnabled),
          isActive: pathname === getOrganizationSettingUrls({ tab: OrganizationSettingsTab.CANDIDATE_PORTAL }),
        },
        {
          id: 'Notifications for others',
          label: 'Notifications for others',
          selectedIcon: undefined,
          to: getOrganizationSettingUrls({ tab: OrganizationSettingsTab.NOTIFICATIONS }),
          icon: <NotificationIcon color="inherit" />,
          isShown: !isNewOrgExperience,
          isActive: pathname === getOrganizationSettingUrls({ tab: OrganizationSettingsTab.NOTIFICATIONS }),
        },
        {
          id: 'Tasks',
          label: 'Tasks',
          selectedIcon: undefined,
          to: getOrganizationSettingUrls({ tab: OrganizationSettingsTab.TASK }),
          icon: <AllSchedulingTasksIcon color="inherit" />,
          isShown: !isNewOrgExperience,
          isActive: pathname === getOrganizationSettingUrls({ tab: OrganizationSettingsTab.TASK }),
        },
        {
          id: 'Privacy',
          label: 'Privacy',
          selectedIcon: undefined,
          to: getOrganizationSettingUrls({ tab: OrganizationSettingsTab.PRIVACY }),
          icon: <LockIcon color="inherit" />,
          isShown: !isNewOrgExperience,
          isActive: pathname === getOrganizationSettingUrls({ tab: OrganizationSettingsTab.PRIVACY }),
        },
        {
          id: 'Integrations',
          label: 'Integrations',
          selectedIcon: undefined,
          to: getOrganizationSettingUrls({ tab: OrganizationSettingsTab.INTEGRATIONS }),
          icon: <IntegrationsIcon color="inherit" />,
          isShown: true,
          isActive: pathname === getOrganizationSettingUrls({ tab: OrganizationSettingsTab.INTEGRATIONS }),
        },
        {
          id: 'Members',
          label: 'Members',
          selectedIcon: undefined,
          to: getOrganizationSettingUrls({ tab: OrganizationSettingsTab.MEMBERS }),
          icon: <MultipleUsersIcon color="inherit" />,
          isShown: true,
          isActive: pathname === getOrganizationSettingUrls({ tab: OrganizationSettingsTab.MEMBERS }),
        },
        {
          id: 'Setup',
          label: 'Setup',
          selectedIcon: undefined,
          to: getOrganizationSettingUrls({ tab: OrganizationSettingsTab.SETUP }),
          icon: <WrenchSetupIcon color="inherit" />,
          isShown: !isNewOrgExperience,
          isActive: pathname === getOrganizationSettingUrls({ tab: OrganizationSettingsTab.SETUP }),
        },
      ],
    },
  ];
};
