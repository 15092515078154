import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { ZoomHealthCheckBanner_ZoomFragment } from 'src/generated/mloop-graphql';

import AppWideBanner, { AppWideBannerAction } from '../AppWideBanner';

import { integrationUnhealthyMessage } from './constants';

interface Fragments {
  zoom: ZoomHealthCheckBanner_ZoomFragment;
}

interface Props {
  retry: AppWideBannerAction;
}

const ZoomHealthCheckBanner: FCWithFragments<Fragments, Props> = ({ zoom, retry }) => {
  // hide if its not installed or is healthy
  if (!zoom || !zoom?.isInstalled || zoom?.isHealthy) {
    return null;
  }

  return (
    <AppWideBanner
      color="foreground"
      backgroundColor="error"
      text={integrationUnhealthyMessage('Zoom')}
      action={retry}
    />
  );
};

ZoomHealthCheckBanner.fragments = {
  zoom: gql`
    fragment ZoomHealthCheckBanner_zoom on ZoomIntegrationHealth {
      isHealthy
      isInstalled
    }
  `,
};

export default ZoomHealthCheckBanner;
