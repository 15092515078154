/* eslint-disable modernloop/restrict-imports.cjs */
import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';

import AshbyCreateCandidatePermissionDialog from 'src/views-new/JobDetailsPage/SourcingLinks/AtsCreateCandidatePermissionDialogs/AshbyCreateCandidatePermissionDialog';

import AtsSevice from './AtsService';

/* *
 * Ashby ATS service
 * Imp: Never have atsType set in ATS service
 * */
class AshbyAtsService implements AtsSevice {
  readonly name = 'Ashby';

  readonly logoUrl = `/static/images/integrations/ashby.png`;

  readonly helpCenterUrl = ZenDeskHelpCenterUrl.ASHBY_INTEGRATION;

  readonly scorecardLabelPlural = 'feedback forms';

  readonly isSourcingLinkEnabled = true;

  readonly isAtsUserSelectEnabled = false;

  readonly isAtsImportAvailabilityEnabled = false;

  readonly skipInterviewKitLinkTokenValidation = true;

  readonly scorecardGroupByKey = null;

  readonly scorecardSortKey = null;

  readonly showEstimatedMinutes = false;

  readonly createCandidatePermissionDialog = AshbyCreateCandidatePermissionDialog;

  readonly showRequisitionId = false;
}

export default new AshbyAtsService();
