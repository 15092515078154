import React from 'react';
import type { FC } from 'react';

import { ModernLoopLoader } from '@modernloop/shared/helper-components';
import { Box } from '@mui/material';

const LoadingScreen: FC = () => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        minHeight: '100%',
        padding: (theme) => theme.spacing(3),
      }}
    >
      <ModernLoopLoader />
    </Box>
  );
};

export default LoadingScreen;
