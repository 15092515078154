import React from 'react';

import { SxProps } from '@mui/material';

import QuillEditor, { QuillEditorProps } from 'src/components/QuillEditor';
import TinyMceEditor from 'src/components/TinyMceEditor';
import { BaseProps } from 'src/components/types';

import { useFlag } from 'src/hooks/feature-flag';

import { Theme } from 'src/themeMui5/type';

import { ReactQuillProps } from '../QuillEditor/useQuillEditorModules';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type EditorProps = BaseProps & {
  sx?: SxProps<Theme>;
  height?: number;
  readOnly?: boolean;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;

  // Quill editor specific props
  quillRef?: QuillEditorProps['quillRef'];
  modules?: ReactQuillProps['modules'];
};

const Editor = ({
  dataTestId,
  sx,
  height,
  readOnly,
  quillRef,
  value,
  defaultValue,
  modules,
  placeholder,
  onChange,
  onBlur,
}: EditorProps) => {
  const tinyMceEditorEnabled = useFlag('tiny_mce_editor');

  if (tinyMceEditorEnabled) {
    return (
      <TinyMceEditor
        data-testid={dataTestId}
        value={value}
        initialValue={defaultValue}
        init={{ readonly: readOnly, height, placeholder }}
        onEditorChange={onChange}
      />
    );
  }

  return (
    <QuillEditor
      dataTestId={dataTestId}
      defaultValue={defaultValue}
      sx={sx}
      editorHeight={height}
      readOnly={readOnly}
      quillRef={quillRef}
      value={value}
      onChange={onChange}
      modules={modules}
      placeholder={placeholder}
      onBlur={() => {
        if (onBlur) {
          onBlur();
        }
      }}
    />
  );
};

export default Editor;
