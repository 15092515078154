export const blue = {
  0: '#000000',
  10: '#001B3F',
  20: '#002F65',
  25: '#003A7A',
  30: '#01458E',
  35: '#1150A4',
  40: '#1160D4',
  50: '#2779FC',
  60: '#4D8FFF',
  70: '#63A9FF',
  80: '#A1D1FF',
  90: '#CFE7FF',
  95: '#EBF2FF',
  98: '#F9FBFF',
  99: '#FBFCFF',
  100: '#FFFFFF',
};
