import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';

import * as serviceWorker from 'src/serviceWorker';

import App from './App';
import { enviromentConfig } from './config';

// This is so it's easier to see which environment you're in
const setAlternateFavicon = () => {
  try {
    let faviconName;
    if (enviromentConfig.environment === 'development') {
      faviconName = 'favicon.dev.ico';
    }
    if (enviromentConfig.environment === 'staging') {
      faviconName = 'favicon.stg.ico';
    }
    if (!faviconName) {
      return;
    }
    const faviconLink = document.querySelector("link[rel='shortcut icon']") as HTMLLinkElement;
    if (faviconLink) {
      faviconLink.href = `/${faviconName}`;
    }
  } catch (e) {
    // dont want to fail right at the top of the app
    console.error(e);
  }
};

setAlternateFavicon();
const render = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

render();
serviceWorker.unregister();
