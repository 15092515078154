import React, { useEffect } from 'react';

import { Dialog } from '@modernloop/shared/components';
import { WarningIcon } from '@modernloop/shared/icons';
import { Alert, AlertTitle, Avatar, Stack, Table, TableCell, TableRow, Typography } from '@mui/material';

import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';

type Props = {
  fullName: string;
  imageUrl: string;
  email: string;
  candidateCalendarLabel: string;
  candidateId: string;
  candidateEventCalendarId: string;
  interviewerEventCalendarId: string;
  candidateCalendarFound: boolean;
  interviewerCalendarLabel: string;
  interviewerCalendarFound: boolean;
  onClose: () => void;
};

const CalendarPermissionDialog = ({
  fullName,
  imageUrl,
  email,
  candidateCalendarLabel,
  candidateId,
  candidateEventCalendarId,
  interviewerEventCalendarId,
  candidateCalendarFound,
  interviewerCalendarLabel,
  interviewerCalendarFound,
  onClose,
}: Props) => {
  const { logEvent } = useLogEvent();
  useEffect(() => {
    logEvent(LoggerEvent.CLIENT_NO_CALENDAR_ACCESS_DIALOG_SHOWN, {
      candidateId,
      candidateEventAccess: candidateCalendarFound,
      interviewerEventAccess: interviewerCalendarFound,
      candidateEventCalendarId,
      interviewerEventCalendarId,
    });
  }, [
    candidateCalendarFound,
    candidateEventCalendarId,
    candidateId,
    interviewerCalendarFound,
    interviewerEventCalendarId,
    logEvent,
  ]);

  return (
    <Dialog
      open
      dataTestId="schedule-object-calendar-permission-dialog"
      title="Calendar access denied"
      onClose={onClose}
      submitOptions={{
        dataTestId: 'schedule-object-calendar-permission-dialog-okay-button',
        label: 'Okay',
        onClick: onClose,
      }}
    >
      <Stack>
        <Typography>
          You do not have permission to edit events on one or both of the calendars used for this schedule. Contact the
          scheduler to request permission to make changes to that calendar and then accept the invite to add it to your
          calendar list.
        </Typography>

        <Table>
          <TableRow>
            <TableCell sx={{ borderBottom: 'none', p: (theme) => theme.spacing(1, 0) }}>
              <Typography variant="subtitle2">Scheduler</Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none', p: (theme) => theme.spacing(1, 0) }}>
              <Alert icon={<Avatar src={imageUrl} />} sx={{ backgroundColor: 'transparent', p: 0 }}>
                <AlertTitle>{fullName}</AlertTitle>
                {email}
              </Alert>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ borderBottom: 'none', p: (theme) => theme.spacing(1, 0) }}>
              <Typography variant="subtitle2">Candidate events</Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none', p: (theme) => theme.spacing(1, 0) }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <WarningIcon color="warning" />
                <Typography variant="subtitle2">{candidateCalendarLabel}</Typography>
              </Stack>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ borderBottom: 'none', p: (theme) => theme.spacing(1, 0) }}>
              <Typography variant="subtitle2">Interviewer events</Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none', p: (theme) => theme.spacing(1, 0) }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <WarningIcon color="warning" />
                <Typography variant="subtitle2">{interviewerCalendarLabel}</Typography>
              </Stack>
            </TableCell>
          </TableRow>
        </Table>
      </Stack>
    </Dialog>
  );
};

export default CalendarPermissionDialog;
