import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';

import Button from 'src/components/button';
import UserIcon from 'src/components/icons/User';
import Label from 'src/components/label';

import { createJobStageInterviewSeat } from 'src/store/actions/job-stage-interview-seat';
import { getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';

import { useDispatch, useSelector } from 'src/store';

import BaseInterviewSeatModule from './InterviewSeat/BaseInterviewSeatModule';

interface Props {
  jobStageInterviewId: string;
  readonly?: boolean;
  onUpdated: () => void;
}

const InterviewSeatsModule = ({
  jobStageInterviewId,
  readonly,

  onUpdated,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const jobStageInterview = useSelector((state) => getJobStageInterviewById(state, jobStageInterviewId));
  const handleAddInterviewer = () => {
    dispatch(createJobStageInterviewSeat(jobStageInterviewId));
  };

  return (
    <Grid container direction="column" spacing={1} wrap="nowrap">
      {jobStageInterview.seatIds.map((seatId, index) => {
        return (
          <Grid item key={seatId}>
            <BaseInterviewSeatModule
              jobStageInterviewId={jobStageInterviewId}
              jobStageInterviewSeatId={seatId}
              seatIndex={index}
              onUpdated={onUpdated}
            />
          </Grid>
        );
      })}
      {!readonly && (
        <Grid item container>
          <Label variant="body">
            <Button
              startIcon={<UserIcon color="primary" />}
              label="Add interviewer"
              variant="link"
              size="medium"
              onClick={handleAddInterviewer}
            />
          </Label>
        </Grid>
      )}
    </Grid>
  );
};

export default InterviewSeatsModule;
