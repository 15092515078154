/* eslint-disable max-lines */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { ListSubheader } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { StarFilledIcon } from '@modernloop/shared/icons';
import { uniq, uniqBy } from 'lodash';
import { useDebounce } from 'use-debounce';
import { v4 as uuid } from 'uuid';

import {
  CurrentTrainingSessionStatus,
  EmployeeAttribute,
  InterviewModulesOrderByProperty,
  InterviewerRole,
  JobStageInterviewSeatType,
  Sort,
  UpdateInterviewSeat_InterviewFragment,
  UpdateInterviewSeat_InterviewSeatFragment,
  UpdateInterviewSeat_OriginalInterviewFragment,
  UpdateInterviewSeat_OriginalInterviewSeatFragment,
  useInterviewModuleLazyQuery,
  useInterviewModuleSearchLazyQuery,
  useInterviewerSuggestionsLazyQuery,
} from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import Avatar from 'src/components/Avatar';
import { FilterListInterface } from 'src/components/FilterList';
import FilterListPopover from 'src/components/FilterList/FilterListPopover';
import IconButton from 'src/components/IconButton';
import ImageBlock from 'src/components/ImageBlock';
import MoreMenuButton from 'src/components/MoreMenuButton';
import Stack, { StackItemStyles } from 'src/components/Stack';
import ZeroState from 'src/components/ZeroState';
import Button from 'src/components/button';
import Chip from 'src/components/chip';
import {
  CaretLeftIcon,
  ReverseShadowIcon,
  SearchIcon,
  ShadowIcon,
  TraineeIcon,
  TriangleDownIcon,
} from 'src/components/icons';
import Label, { LabelProps } from 'src/components/label';
import { MenuOption } from 'src/components/menu';
import Tooltip from 'src/components/tooltip';

import { PREFERENCE_LEVEL_NON_PREFERRED, PREFERENCE_LEVEL_PREFERRED } from 'src/constants/InterviewPlan';

import {
  doesModuleMemberNeedsApproval,
  isModuleMemberDisabledForScheduling,
  isModuleMemberInterviewRoleReverseShadow,
  isModuleMemberInterviewRoleShadow,
} from 'src/entities/InterviewModuleMember/utils';

import { getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';
import {
  getJobStageInterviewSeatById,
  getNormalizedJobStageInterviewSeatsById,
} from 'src/store/selectors/job-stage-interview-seat';
import { getExcludeInterviewerIdsByJobStageId } from 'src/store/selectors/job-stage-plan-config';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import IsoTimestamp from 'src/types/IsoTimestamp';

import { InterviewEvent, RichInterviewer, UIFilledInterviewSeat } from 'src/utils/api/getScheduleOptions';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';
import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { DEBOUNCE_TIMEOUT, EMPTY_UUID, PAGER_DEFAULT } from 'src/constants';
import { useSelector } from 'src/store';
import { Theme } from 'src/theme';

import InterviewerSuggestionRow from './InterviewerSuggestionRow';
import ModuleSuggestionRow from './ModuleSuggestionRow';
import { InterviewModuleSuggestion, InterviewerSuggestion, InterviewerSuggestionsEmployee } from './types';
import useTrainedModuleOptions from './useTrainedModuleOptions';
import useTraineeModuleOptions from './useTraineeModuleOptions';

type Fragments = {
  interview: UpdateInterviewSeat_InterviewFragment | undefined;
  originalInterview: UpdateInterviewSeat_OriginalInterviewFragment | undefined;
  interviewSeat: UpdateInterviewSeat_InterviewSeatFragment | undefined;
  originalInterviewSeat: UpdateInterviewSeat_OriginalInterviewSeatFragment | undefined;
};

type Props = {
  interviewPlanId: string;
  interviewEvent: InterviewEvent;
  filledInterviewSeat: UIFilledInterviewSeat;
  interviewers: RichInterviewer[];
  startAt: IsoTimestamp;
  endAt: IsoTimestamp;
  excludeInterviewerIds: string[];
  scheduleFlowType: ScheduleFlowType;
  onInterviewerRemoved: (
    filledInterviewSeat: UIFilledInterviewSeat,
    trained: RichInterviewer,
    trainee?: RichInterviewer
  ) => void;
  onInterviewerModuleClear: (
    filledInterviewSeat: UIFilledInterviewSeat,
    trained: RichInterviewer,
    trainee?: RichInterviewer
  ) => void;
  onTraineeRemoved: (filledInterviewSeat: UIFilledInterviewSeat, trainee: RichInterviewer) => void;
  onTraineeModuleClear: (filledInterviewSeat: UIFilledInterviewSeat, trainee: RichInterviewer) => void;
  onInterviewSeatAdded: (filledInterviewSeat: UIFilledInterviewSeat, newInterviewer: InterviewerSuggestion) => void;
  onInterviewSeatUpdate: (
    filledInterviewSeat: UIFilledInterviewSeat,
    newInterviewer: InterviewerSuggestion,
    originalJobStageInterview: JobStageInterviewSeat | null
  ) => void;
  onUseInterviewModule: (filledInterviewSeat: UIFilledInterviewSeat, trainedInterviewer: RichInterviewer) => void;
  onNewInterviewerDismiss: () => void;
};

const DEFAULT_ITEM_HEIGHT = 66;
const DEFAULT_MODULE_ITEM_HEIGHT = 34;
const DEFAULT_HEIGHT_BUFFER = 8 + DEFAULT_MODULE_ITEM_HEIGHT;

enum InterviewerOptions {
  CLEAR_MODULE = 'clear_module',
  REMOVE_INTERVIEWER = 'remove_interviewer',
  REMOVE_TRAINEE = 'remove_trainee',
  SET_AS_TRAINEE_FOR = 'set_as_trainee_for',
  USE_MODULE = 'use_module',
}

const INTERVIEWER_OPTIONS: MenuOption[] = [
  {
    id: InterviewerOptions.CLEAR_MODULE,
    value: "Don't use module",
  },
  {
    id: InterviewerOptions.REMOVE_INTERVIEWER,
    value: 'Remove interviewer',
  },
];

const TRAINEE_OPTIONS: MenuOption[] = [
  {
    id: InterviewerOptions.CLEAR_MODULE,
    value: "Don't use module",
  },
  {
    id: InterviewerOptions.REMOVE_TRAINEE,
    value: 'Remove trainee',
  },
];

const DEBRIEF_INTERVIEWER_OPTIONS: MenuOption[] = [
  {
    id: InterviewerOptions.REMOVE_INTERVIEWER,
    value: 'Remove interviewer',
  },
];

enum InterviewerSuggestionGroupBy {
  AlreadyInvited = 'ALREADY_INVITED',
  Module = 'MODULE',
  ModuleSuggestionAlreadyInvited = 'MODULE_SUGGESTION_ALREADY_INVITED',
  FreeformInterviewer = 'FREEFORM_INTERVIEWER',
  Interviewer = 'INTERVIEWER',
  Shadow = 'REVERSE_SHADOW',
  ReverseShadow = 'SHADOW',
  Ineligible = 'INELIGIBLE',
  Trainee = 'Trainee',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    interviewerTag: {
      borderRadius: '6px',
    },
    headerLabel: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
    },
    addTraineeButton: {
      marginLeft: theme.spacing(3),
    },
  })
);

const UpdateInterviewSeat: FCWithFragments<Fragments, Props> = ({
  interviewPlanId,
  interviewEvent,
  filledInterviewSeat,
  interviewers,
  startAt,
  endAt,
  excludeInterviewerIds,
  scheduleFlowType,
  interview,
  originalInterview,
  interviewSeat,
  originalInterviewSeat,
  onInterviewerRemoved,
  onInterviewerModuleClear,
  onTraineeRemoved,
  onTraineeModuleClear,
  onInterviewSeatAdded,
  onInterviewSeatUpdate,
  onUseInterviewModule,
  onNewInterviewerDismiss,
}): JSX.Element | null => {
  const classes = useStyles();

  const isFastTrackTrainingEnabled = useFlag('user_fast_track_interview_module');
  const interviewPlanEntityInScheduleFlowsEnabled = useFlag('interview_plan_entity_in_schedule_flows');
  const { applicationId } = useScheduleFlowData();

  const itemStyles: StackItemStyles = { 0: { flexGrow: 1 } };
  const traineeItemStyles: StackItemStyles = { 1: { flexGrow: 1 } };

  const filterListInterfaceRef = useRef<FilterListInterface>(null);

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_TIMEOUT);
  const [addingTrainee, setAddingTrainee] = useState(false);

  const [selectedInterviewModuleSuggestion, setSelectedInterviewModuleSuggestion] = useState<
    InterviewModuleSuggestion | undefined
  >(undefined);

  const trainedInterviewer = interviewers.find((i) => i.employeeId === filledInterviewSeat.interviewerId);
  const traineeInterviewer = interviewers.find((i) => i.employeeId === filledInterviewSeat.traineeId);

  const [showTrainedInterviewerPicker, setShowTrainedInterviewerPicker] = useState(!filledInterviewSeat.interviewerId);
  const [showTraineeInterviewerPicker, setShowTraineeInterviewerPicker] = useState(false);

  const seatId =
    filledInterviewSeat.seat.freeformSeat?.id ||
    filledInterviewSeat.seat.moduleSeat?.id ||
    filledInterviewSeat.seat.linkedSeat?.id;

  const jobStageInterviewSeat = useSelector((state) => {
    if (!seatId) return null;
    return getJobStageInterviewSeatById(state, seatId);
  });

  const jobStageInterview = useSelector((state) => {
    if (!jobStageInterviewSeat || !jobStageInterviewSeat.jobStageInterviewId) return null;
    return getJobStageInterviewById(state, jobStageInterviewSeat.jobStageInterviewId);
  });

  const originalJobStageInterviewSeat = useSelector((state) => {
    if (!seatId) return null;
    const seats = getNormalizedJobStageInterviewSeatsById(state, [seatId]);

    if (seats.length === 0) return null;
    return seats[0];
  });

  const originalJobStageInterview = useSelector((state) => {
    if (!originalJobStageInterviewSeat) return null;
    return getJobStageInterviewById(state, originalJobStageInterviewSeat.jobStageInterviewId);
  });

  const interviewPlanConfigExcludeInterviewerIds = useSelector((state): string[] => {
    return getExcludeInterviewerIdsByJobStageId(state, interviewPlanId) || [];
  });

  const interviewModuleId =
    (interviewPlanEntityInScheduleFlowsEnabled
      ? originalInterviewSeat?.moduleSeat?.interviewModuleId
      : originalJobStageInterviewSeat?.interviewId) || filledInterviewSeat.seat.moduleSeat?.interviewId;

  let freeformEmployeeIds =
    (interviewPlanEntityInScheduleFlowsEnabled
      ? originalInterviewSeat?.freeformSeat?.jobStageInterviewSeatEmployees?.map((e) => e.employeeId)
      : originalJobStageInterviewSeat?.employeeIds) ||
    filledInterviewSeat.seat.freeformSeat?.interviewers.map((i) => i.id);

  if (freeformEmployeeIds && filledInterviewSeat.interviewerId) {
    freeformEmployeeIds = uniq([...freeformEmployeeIds, filledInterviewSeat.interviewerId]);
  }

  const [fetchInterviewModule, { data, loading: loadingModule }] = useInterviewModuleLazyQuery();

  useEffect(() => {
    if (!interviewModuleId) return;

    fetchInterviewModule({
      variables: {
        interviewModuleId,
      },
    });
  }, [fetchInterviewModule, interviewModuleId]);

  const attributes: EmployeeAttribute[] | undefined = useMemo(() => {
    if (
      interviewPlanEntityInScheduleFlowsEnabled &&
      originalInterviewSeat?.moduleSeat?.jobStageInterviewSeatAttributes?.length
    ) {
      const result: EmployeeAttribute[] = [];

      originalInterviewSeat.moduleSeat.jobStageInterviewSeatAttributes.forEach((attribute) => {
        const employeeAttribute = result.find((a) => a.attributeNameId === attribute.attributeNameId);
        if (employeeAttribute) {
          if (!employeeAttribute.attributeValueIds) {
            employeeAttribute.attributeValueIds = [attribute.attributeTagValueId];
          } else {
            employeeAttribute.attributeValueIds.push(attribute.attributeTagValueId);
          }
        } else {
          result.push({
            attributeNameId: attribute.attributeNameId,
            attributeValueIds: [attribute.attributeTagValueId],
          });
        }
      });

      return result;
    }

    return originalJobStageInterviewSeat && originalJobStageInterviewSeat.attributeMap
      ? Object.keys(originalJobStageInterviewSeat.attributeMap).map((attributeNameId) => {
          // Below line is just to make compiler happy should never execute
          if (!originalJobStageInterviewSeat.attributeMap) return {};

          return {
            attributeNameId,
            attributeValueIds: originalJobStageInterviewSeat.attributeMap[attributeNameId],
          };
        })
      : undefined;

    return undefined;
  }, [
    interviewPlanEntityInScheduleFlowsEnabled,
    originalInterviewSeat?.moduleSeat?.jobStageInterviewSeatAttributes,
    originalJobStageInterviewSeat,
  ]);

  const [
    fetchInterviewerSuggestions,
    { data: interviewerSuggestions, loading: loadingInterviewerSuggestions, error: interviewerSuggestionsError },
  ] = useInterviewerSuggestionsLazyQuery({
    variables: {
      // We pass EMPTY_UUID for the case when we are getting suggestions for freeform interviewer and we don't have interview module id
      interviewModuleId: selectedInterviewModuleSuggestion?.id || interviewModuleId || EMPTY_UUID,
      input: {
        interviewModuleId: selectedInterviewModuleSuggestion?.id || interviewModuleId,
        freeformEmployeeIds:
          selectedInterviewModuleSuggestion?.id || interviewModuleId ? undefined : freeformEmployeeIds,
        startAt,
        endAt,
        prefix: debouncedSearch,
        attributes,
        applicationId,
      },
    },
  });

  useEffect(() => {
    fetchInterviewerSuggestions();
  }, [fetchInterviewerSuggestions]);

  const [
    fetchInterviewModuleSearch,
    { data: interviewModulesData, loading: loadingInterviewerModules, error: interviewModulesError },
  ] = useInterviewModuleSearchLazyQuery();

  useEffect(() => {
    if (selectedInterviewModuleSuggestion || interviewModuleId || scheduleFlowType === ScheduleFlowType.DEBRIEF) return;

    fetchInterviewModuleSearch({
      variables: {
        input: {
          pageInput: { limit: PAGER_DEFAULT, offset: 0 },
          orderBy: {
            direction: Sort.Asc,
            property: InterviewModulesOrderByProperty.Name,
          },
          search: debouncedSearch,
        },
      },
    });
  }, [
    debouncedSearch,
    fetchInterviewModuleSearch,
    interviewModuleId,
    scheduleFlowType,
    selectedInterviewModuleSuggestion,
  ]);

  // Setting showTraineeInterviewerPicker in a useEffect so that
  // popover for trainee suggestion shows up at correct location.
  useEffect(() => {
    if (!addingTrainee) return;
    setShowTraineeInterviewerPicker(true);
  }, [addingTrainee]);

  let interviewerIdForTrainedModuleOptions: string | undefined;

  if (
    (interviewPlanEntityInScheduleFlowsEnabled &&
      (!originalInterviewSeat || originalInterviewSeat.type === JobStageInterviewSeatType.Freeform)) ||
    (!interviewPlanEntityInScheduleFlowsEnabled &&
      (!originalJobStageInterviewSeat || originalJobStageInterviewSeat?.type === JobStageInterviewSeatType.Freeform))
  ) {
    interviewerIdForTrainedModuleOptions = filledInterviewSeat.interviewerId;
  }

  const interviewerTrainedModuleOptions = useTrainedModuleOptions(
    InterviewerOptions.USE_MODULE,
    interviewerIdForTrainedModuleOptions,
    trainedInterviewer?.employee.givenName || undefined
  );

  const interviewerTraineeModuleOptions = useTraineeModuleOptions(
    InterviewerOptions.SET_AS_TRAINEE_FOR,
    filledInterviewSeat.interviewerId,
    trainedInterviewer?.employee.givenName || '',
    interviewEvent.filledInterviewSeats
      ?.filter(
        (filledSeat) => filledSeat.seat.moduleSeat && filledSeat.interviewerId !== filledInterviewSeat.interviewerId
      )
      .map((filledSeat) => {
        const interviewer = interviewEvent.interviewers.find((i) => i.employeeId === filledSeat.interviewerId);
        const trainee = interviewEvent.interviewers.find((i) => i.employeeId === filledSeat.traineeId);
        return {
          interviewerId: filledSeat.interviewerId,
          interviewModuleId: filledSeat.seat.moduleSeat?.interviewId || '',
          interviewerName: interviewer?.employee.givenName || '',
          interviewerSlackImageUrl: interviewer?.employee.slackImageUrl || '',
          traineeId: filledSeat.traineeId,
          traineeName: trainee?.employee.givenName || undefined,
          traineeRole: filledInterviewSeat.traineeRole,
        };
      }) || []
  );

  // FilterList takes a param `values`, using which it determines the selected items.
  // For our use case in this component we don't ever want a selected item.
  // Simply passing an empty array `[]` causes search to break because
  // the empty array is always a new object and it results in search input to be cleared.
  // Hence using `useMemo` to ensure that we always pass the same object.
  const emptySelectedTrainedInterviewersOptions = useMemo(() => [], []);

  const interviewModuleSuggestions = useMemo(() => {
    if (loadingInterviewerModules || interviewModulesError || !interviewModulesData?.interviewModuleSearch) {
      return [] as InterviewModuleSuggestion[];
    }

    return interviewModulesData.interviewModuleSearch.items;
  }, [interviewModulesData, interviewModulesError, loadingInterviewerModules]);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-lines
  const trainedInterviewerSuggestions = useMemo(() => {
    if (loadingInterviewerSuggestions || interviewerSuggestionsError) {
      return [] as InterviewerSuggestion[];
    }

    // the sort is required to ensure that all items in a group are together
    const sorted = uniqBy(
      [
        ...(interviewerSuggestions?.interviewerSuggestions?.employees || []),
        ...(filledInterviewSeat.seat.freeformSeat ? interviewerSuggestions?.interviewerSuggestions?.others || [] : []),
      ]?.filter((e) => e?.role === InterviewerRole.Interviewer) || [],
      'employeeId'
    ).sort((a, b) => {
      if (!a || !b) return 0;

      if (
        interviewPlanConfigExcludeInterviewerIds.includes(a.employeeId) &&
        interviewPlanConfigExcludeInterviewerIds.includes(a.employeeId)
      ) {
        return 0;
      }

      if (interviewPlanConfigExcludeInterviewerIds.includes(a.employeeId)) return 1;
      if (interviewPlanConfigExcludeInterviewerIds.includes(b.employeeId)) return -1;

      if (a.employeeId === filledInterviewSeat.interviewerId) return 1;
      if (b.employeeId === filledInterviewSeat.interviewerId) return -1;

      return 0;
    }) as InterviewerSuggestion[];

    // Pushing disabled interviewers to the end.
    const included = sorted.filter((s) => s && !excludeInterviewerIds.includes(s.employeeId));
    const excluded = sorted.filter((s) => s && excludeInterviewerIds.includes(s.employeeId));
    return [...included, ...excluded];
  }, [
    excludeInterviewerIds,
    filledInterviewSeat,
    interviewPlanConfigExcludeInterviewerIds,
    interviewerSuggestions,
    interviewerSuggestionsError,
    loadingInterviewerSuggestions,
  ]);

  const traineeInterviewerSuggestions = useMemo(() => {
    if (loadingInterviewerSuggestions || interviewerSuggestionsError) {
      return [] as InterviewerSuggestion[];
    }

    // the sort is required to ensure that all items (interviewers, shadow & reverse shadow) are grouped together
    const sorted = (
      interviewerSuggestions?.interviewerSuggestions?.employees?.filter(
        (e) => e?.role !== InterviewerRole.Interviewer
      ) || []
    ).sort((a, b) => {
      if (!a || !b) return 0;
      const aIsDisabled =
        (a.employee as InterviewerSuggestionsEmployee)?.interviewModuleMember?.stats?.currentTrainingSessionStatus ===
          CurrentTrainingSessionStatus.NeedsApproval ||
        (a.employee as InterviewerSuggestionsEmployee)?.interviewModuleMember?.stats?.currentTrainingSessionStatus ===
          CurrentTrainingSessionStatus.RequiredInterviewsScheduled;

      const bIsDisabled =
        (b.employee as InterviewerSuggestionsEmployee)?.interviewModuleMember?.stats?.currentTrainingSessionStatus ===
          CurrentTrainingSessionStatus.NeedsApproval ||
        (b.employee as InterviewerSuggestionsEmployee)?.interviewModuleMember?.stats?.currentTrainingSessionStatus ===
          CurrentTrainingSessionStatus.RequiredInterviewsScheduled;

      if (aIsDisabled && bIsDisabled) return 0;
      if (a.employeeId === filledInterviewSeat.traineeId || aIsDisabled) return 1;
      if (b.employeeId === filledInterviewSeat.traineeId || bIsDisabled) return -1;

      const aExcludedFromInterviewPlan = interviewPlanConfigExcludeInterviewerIds.includes(a.employeeId);
      const bExcludedFromInterviewPlan = interviewPlanConfigExcludeInterviewerIds.includes(b.employeeId);

      if (a.role === b.role) {
        if (aExcludedFromInterviewPlan && bExcludedFromInterviewPlan) return 0;
        if (aExcludedFromInterviewPlan) return 1;
        if (bExcludedFromInterviewPlan) return -1;
      }

      return (a?.role || '')?.localeCompare(b?.role || '');
    });

    // Pushing disabled interviewers to the end.
    const included = sorted.filter((s) => s && !excludeInterviewerIds.includes(s.employeeId));
    const excluded = sorted.filter((s) => s && excludeInterviewerIds.includes(s.employeeId));
    return [...included, ...excluded];
  }, [
    excludeInterviewerIds,
    filledInterviewSeat,
    interviewPlanConfigExcludeInterviewerIds,
    interviewerSuggestions,
    interviewerSuggestionsError,
    loadingInterviewerSuggestions,
  ]);

  const freeformInterviewerOptions = useMemo(() => {
    return [
      {
        id: InterviewerOptions.SET_AS_TRAINEE_FOR,
        value: 'Set as trainee for',
        items: interviewerTraineeModuleOptions,
      },
      {
        id: InterviewerOptions.USE_MODULE,
        value: 'Use module',
        items: interviewerTrainedModuleOptions,
      },
      {
        id: InterviewerOptions.REMOVE_INTERVIEWER,
        value: 'Remove interviewer',
      },
    ];
  }, [interviewerTrainedModuleOptions, interviewerTraineeModuleOptions]);

  const toggleTrainedInterviewerPicker = () => {
    setSearch('');
    setShowTrainedInterviewerPicker(!showTrainedInterviewerPicker);

    if (!filledInterviewSeat.interviewerId) {
      onNewInterviewerDismiss();
    }

    // Unset selectedInterviewModuleSuggestion when we are closing filter list popover.
    if (showTrainedInterviewerPicker) {
      setSelectedInterviewModuleSuggestion(undefined);
    }
  };

  const toggleTraineeInterviewerPicker = () => {
    setSearch('');
    setAddingTrainee(false);
    setShowTraineeInterviewerPicker(!showTraineeInterviewerPicker);
  };

  const renderInterviewer = (interviewer?: RichInterviewer) => {
    const preferred = interviewPlanEntityInScheduleFlowsEnabled
      ? originalInterviewSeat?.freeformSeat?.jobStageInterviewSeatEmployees?.find(
          (emp) => emp.employeeId === interviewer?.employeeId
        )?.preferenceLevel === PREFERENCE_LEVEL_PREFERRED
      : originalJobStageInterviewSeat?.freeformSeat?.jobStageInterviewSeatEmployees?.find(
          (emp) => emp.employeeId === interviewer?.employeeId
        )?.preferenceLevel === PREFERENCE_LEVEL_PREFERRED;

    return (
      <Stack
        alignItems="center"
        style={{
          columnGap: '8px',
        }}
        wrap="nowrap"
      >
        {!interviewer && (
          <Label color="max-contrast-grey" noWrap>
            New interviewer…
          </Label>
        )}
        {interviewer && (
          <Stack
            style={{
              columnGap: '8px',
            }}
            alignItems="center"
          >
            <ImageBlock
              title={
                <Label noWrap>
                  {interviewer.employee.fullName || interviewer.employee.givenName || interviewer.employee.familyName}
                </Label>
              }
              image={
                <Avatar alt={interviewer.employee?.fullName || ''} src={interviewer.employee.slackImageUrl || ''} />
              }
            />
            {preferred && (
              <Tooltip tooltip="Preferred interviewer">
                <StarFilledIcon fontSize="small" color="warning" />
              </Tooltip>
            )}
          </Stack>
        )}
        {interviewModuleId && interviewer && (
          <Chip label={loadingModule ? 'Loading…' : data?.interviewModule?.name || ''} variant="default" />
        )}
      </Stack>
    );
  };

  const handleMenuOptionSelect = (option: MenuOption, isInterviewer: boolean) => {
    if (isInterviewer) {
      if (!trainedInterviewer) return;

      if (option.id?.toString().startsWith(InterviewerOptions.USE_MODULE)) {
        const moduleId = option.id.toString().substring(InterviewerOptions.USE_MODULE.length + 1);
        onUseInterviewModule(
          {
            interviewerId: filledInterviewSeat.interviewerId,
            seat: { moduleSeat: { id: uuid(), interviewId: moduleId } },
          },
          trainedInterviewer
        );
        return;
      }

      if (option.id?.toString().startsWith(InterviewerOptions.SET_AS_TRAINEE_FOR)) {
        const idParts = option.id
          .toString()
          .substring(InterviewerOptions.SET_AS_TRAINEE_FOR.length + 1)
          .split('_');

        if (idParts.length !== 2) return;

        const traineeModuleId = idParts[0];
        const interviewerId = idParts[1];

        const filledSeat = interviewEvent.filledInterviewSeats?.find(
          (fSeat) => fSeat.seat.moduleSeat?.interviewId === traineeModuleId && fSeat.interviewerId === interviewerId
        );

        if (!filledSeat || !trainedInterviewer) return;

        const suggestedInterviewer = interviewerSuggestions?.interviewerSuggestions?.employees?.find(
          (s) => s?.employeeId === trainedInterviewer.employeeId
        );

        if (!suggestedInterviewer) return;

        setAddingTrainee(false);
        onInterviewerRemoved(filledInterviewSeat, trainedInterviewer);
        onInterviewSeatAdded(filledSeat, { ...suggestedInterviewer, role: option.value as InterviewerRole });
        return;
      }

      switch (option.id) {
        case InterviewerOptions.CLEAR_MODULE: {
          onInterviewerModuleClear(filledInterviewSeat, trainedInterviewer, traineeInterviewer);
          break;
        }
        case InterviewerOptions.REMOVE_INTERVIEWER: {
          onInterviewerRemoved(filledInterviewSeat, trainedInterviewer, traineeInterviewer);
          break;
        }
        default:
      }
    } else {
      if (!traineeInterviewer) {
        setAddingTrainee(false);
        return;
      }
      switch (option.id) {
        case InterviewerOptions.CLEAR_MODULE: {
          onTraineeModuleClear(filledInterviewSeat, traineeInterviewer);
          break;
        }
        case InterviewerOptions.REMOVE_TRAINEE: {
          onTraineeRemoved(filledInterviewSeat, traineeInterviewer);
          break;
        }
        default:
      }
    }
  };

  const handleRemoveModuleClick = () => {
    if (!trainedInterviewer) return;
    onInterviewerModuleClear(filledInterviewSeat, trainedInterviewer, traineeInterviewer);
  };

  const renderSubActions = (isTrained: boolean) => {
    if (isTrained && filledInterviewSeat.seat.moduleSeat) {
      return (
        <Button
          color="info"
          label={filledInterviewSeat.traineeId ? 'Remove module and disconnect trainee' : 'Remove module'}
          size="small"
          variant="unstyled"
          onClick={handleRemoveModuleClick}
        />
      );
    }

    if (
      filledInterviewSeat.seat.linkedSeat &&
      // The below condition is because there can be a scenario where the original seat was changed and seat link is broken.
      // But since the filledInterviewSeat is populated at page load time from saved schedule it does not change.
      interviewPlanEntityInScheduleFlowsEnabled
        ? interviewSeat?.linkedSeat
        : jobStageInterviewSeat?.interviewSeatId
    ) {
      return (
        <Alert
          status="warning"
          title={
            <Label variant="captions">
              This person is part of a linked interview seat between{' '}
              <strong>{interviewPlanEntityInScheduleFlowsEnabled ? interview?.name : jobStageInterview?.name}</strong>{' '}
              and{' '}
              <strong>
                {interviewPlanEntityInScheduleFlowsEnabled ? originalInterview?.name : originalJobStageInterview?.name}
              </strong>
              . Changing them here will not change them in other interviews.
            </Label>
          }
        />
      );
    }

    return null;
  };

  const getListHeight = (interviewerOptions) => {
    const count = interviewerOptions.length;
    if (count < 5) return count * DEFAULT_ITEM_HEIGHT + DEFAULT_HEIGHT_BUFFER;
    return 5 * DEFAULT_ITEM_HEIGHT + DEFAULT_HEIGHT_BUFFER;
  };

  const getChildSize = (child: React.ReactNode) => {
    let height = DEFAULT_ITEM_HEIGHT;
    if (React.isValidElement(child)) {
      if (child.type === ListSubheader || child.props.children.type === ModuleSuggestionRow) {
        height = DEFAULT_MODULE_ITEM_HEIGHT;
      }

      if (child.props.children.type === InterviewerSuggestionRow) {
        if (child.props.children.props.interviewer.employee.title) {
          height += 18;
        }
        if (
          doesModuleMemberNeedsApproval(
            child.props.children.props.interviewer.employee.interviewModuleMember,
            child.props.children.props.interviewer.employee.interviewModuleMember?.stats?.currentTrainingSessionStatus
          )
        ) {
          height = DEFAULT_ITEM_HEIGHT + 18; // Needs approval a extra row is added
        }
      }
    }

    return height;
  };

  const groupBy = (option: InterviewerSuggestion | InterviewModuleSuggestion) => {
    const interviewerSuggestion = option as InterviewerSuggestion;
    if (interviewerSuggestion?.employeeId) {
      if (
        interviewerSuggestion.employeeId === filledInterviewSeat.traineeId ||
        (interviewerSuggestion.employeeId === filledInterviewSeat.interviewerId &&
          !filledInterviewSeat.seat.freeformSeat) ||
        excludeInterviewerIds.includes(interviewerSuggestion.employeeId)
      ) {
        if (selectedInterviewModuleSuggestion) {
          return InterviewerSuggestionGroupBy.ModuleSuggestionAlreadyInvited;
        }
        return InterviewerSuggestionGroupBy.AlreadyInvited;
      }

      const { role } = interviewerSuggestion;
      if (role === InterviewerRole.Interviewer && filledInterviewSeat.seat.freeformSeat) {
        return InterviewerSuggestionGroupBy.FreeformInterviewer;
      }

      if (
        interviewerSuggestion.employee?.interviewModuleMember?.stats?.currentTrainingSessionStatus ===
          CurrentTrainingSessionStatus.NeedsApproval ||
        interviewerSuggestion.employee?.interviewModuleMember?.stats?.currentTrainingSessionStatus ===
          CurrentTrainingSessionStatus.RequiredInterviewsScheduled
      ) {
        return InterviewerSuggestionGroupBy.Ineligible;
      }

      if (role === InterviewerRole.Shadow) {
        return isFastTrackTrainingEnabled ? InterviewerSuggestionGroupBy.Trainee : InterviewerSuggestionGroupBy.Shadow;
      }
      if (role === InterviewerRole.ReverseShadow) {
        return isFastTrackTrainingEnabled
          ? InterviewerSuggestionGroupBy.Trainee
          : InterviewerSuggestionGroupBy.ReverseShadow;
      }
      if (role === InterviewerRole.Interviewer) {
        return InterviewerSuggestionGroupBy.Interviewer;
      }
      return InterviewerSuggestionGroupBy.Ineligible;
    }

    return InterviewerSuggestionGroupBy.Module;
  };

  const renderGroup = (params: AutocompleteRenderGroupParams) => {
    let groupLabel = 'In training, but ineligible';
    let color: LabelProps['color'] = 'high-contrast-grey';

    if (
      selectedInterviewModuleSuggestion &&
      params.group !== InterviewerSuggestionGroupBy.ModuleSuggestionAlreadyInvited
    ) {
      groupLabel = selectedInterviewModuleSuggestion.name || 'Trained members';
      color = 'info';
    } else if (params.group === InterviewerSuggestionGroupBy.Module) {
      groupLabel = 'Modules';
      color = 'info';
    } else if (params.group === InterviewerSuggestionGroupBy.Shadow) {
      groupLabel = 'Shadows';
      color = 'info';
    } else if (params.group === InterviewerSuggestionGroupBy.ReverseShadow) {
      groupLabel = 'Reverse shadows';
      color = 'info';
    } else if (params.group === InterviewerSuggestionGroupBy.Trainee) {
      groupLabel = 'Trainees';
      color = 'high-contrast-grey';
    } else if (params.group === InterviewerSuggestionGroupBy.Interviewer) {
      groupLabel = 'Trained for this module';
      color = 'info';
    } else if (params.group === InterviewerSuggestionGroupBy.FreeformInterviewer) {
      groupLabel = 'Suggested interviewers';
      color = 'info';
    } else if (
      params.group === InterviewerSuggestionGroupBy.AlreadyInvited ||
      params.group === InterviewerSuggestionGroupBy.ModuleSuggestionAlreadyInvited
    ) {
      groupLabel = 'Already invited';
      color = 'high-contrast-grey';
    }

    return [
      <ListSubheader key={params.key} component="div" disableGutters>
        <Label color={color} fontWeight={600} variant="captions" className={classes.headerLabel}>
          {groupLabel}
        </Label>
      </ListSubheader>,
      params.children,
    ];
  };

  const tempOriginalJobStageInterviewSeat: JobStageInterviewSeat | null = interviewPlanEntityInScheduleFlowsEnabled
    ? {
        id: originalInterviewSeat?.id,
        jobStageInterviewId: interview?.id,
        index: originalInterviewSeat?.index || 0,
        type: JobStageInterviewSeatType.Freeform,
        interviewId: originalInterviewSeat?.moduleSeat?.interviewModuleId,
        interviewSeatId: originalInterviewSeat?.linkedSeat?.linkedJobStageInterviewSeatId,
        employeeIds: originalInterviewSeat?.freeformSeat?.jobStageInterviewSeatEmployees?.map((e) => e.employeeId),

        freeformSeat: {
          jobStageInterviewSeatEmployees: originalInterviewSeat?.freeformSeat?.jobStageInterviewSeatEmployees?.map(
            (emp) => ({
              employeeId: emp.employeeId,
              preferenceLevel: emp.preferenceLevel,
              jobStageInterviewSeatId: originalInterviewSeat?.id,
            })
          ),
        },
        moduleSeat: {
          interviewModuleId: originalInterviewSeat?.moduleSeat?.interviewModuleId,
          selectedEmployeeIds: originalInterviewSeat?.moduleSeat?.selectedEmployeeIds || [],
          jobStageInterviewSeatAttributes: originalInterviewSeat?.moduleSeat?.jobStageInterviewSeatAttributes,
          // interviewModule: originalInterviewSeat?.moduleSeat.interviewModule,
        },
        linkedSeat: {
          linkedJobStageInterviewSeatId: originalInterviewSeat?.linkedSeat?.linkedJobStageInterviewSeatId,
        },
      }
    : originalJobStageInterviewSeat;

  const handleTrainedInterviewerSuggestionSelected = (
    event: React.ChangeEvent,
    values: (InterviewerSuggestion | InterviewModuleSuggestion)[]
  ) => {
    if (!values || values.length === 0) return;

    // It is possible to have 2 values selected when user selects an interview module and then selects interviewer.
    // In this case first value is the InterviewModuleSuggestion object and next is InterviewerSuggestion
    const interviewerSuggestionValue = (values.length === 2 ? values[1] : values[0]) as InterviewerSuggestion;

    if (interviewerSuggestionValue && interviewerSuggestionValue.employeeId) {
      if (selectedInterviewModuleSuggestion) {
        const filledSeat = { ...filledInterviewSeat };
        filledSeat.seat = {
          freeformSeat: undefined,
          moduleSeat: {
            id: filledSeat.seat.freeformSeat?.id || uuid(),
            interviewId: selectedInterviewModuleSuggestion.id as string,
          },
          linkedSeat: undefined,
        };

        if (filledInterviewSeat.interviewerId) {
          onInterviewSeatUpdate(filledSeat, interviewerSuggestionValue, tempOriginalJobStageInterviewSeat);
        } else {
          onInterviewSeatAdded(filledSeat, interviewerSuggestionValue);
        }
      } else if (filledInterviewSeat.interviewerId) {
        onInterviewSeatUpdate(filledInterviewSeat, interviewerSuggestionValue, tempOriginalJobStageInterviewSeat);
      } else {
        const filledSeat = { ...filledInterviewSeat };
        filledSeat.seat.freeformSeat = {
          id: uuid(),
          interviewers: [
            { id: interviewerSuggestionValue.employeeId, preferenceLevel: PREFERENCE_LEVEL_NON_PREFERRED },
          ],
        };
        onInterviewSeatAdded(filledSeat, interviewerSuggestionValue);
      }
      toggleTrainedInterviewerPicker();
      return;
    }

    const interviewModuleSuggestionValues = values as InterviewModuleSuggestion[];
    setSelectedInterviewModuleSuggestion(interviewModuleSuggestionValues[0]);

    // Clear the text input when a module is selected and we are showing module options.
    filterListInterfaceRef.current?.clearSelected();
  };

  const handleTraineeInterviewerSuggestionSelected = (event: React.ChangeEvent, values: InterviewerSuggestion[]) => {
    if (!values || values.length === 0) return;

    if (filledInterviewSeat.traineeId) {
      onInterviewSeatUpdate(filledInterviewSeat, values[0], tempOriginalJobStageInterviewSeat);
    } else {
      onInterviewSeatAdded(filledInterviewSeat, values[0]);
    }
    toggleTraineeInterviewerPicker();
    setAddingTrainee(false);
  };

  const trainedOptions = (() => {
    if (interviewModuleId || selectedInterviewModuleSuggestion) return trainedInterviewerSuggestions;
    return [...trainedInterviewerSuggestions, ...interviewModuleSuggestions];
  })();

  let placeholderText = 'Search for modules or interviewer name';
  if (interviewModuleId) {
    placeholderText = `Search ${data?.interviewModule?.name}`;
  } else if (scheduleFlowType === ScheduleFlowType.DEBRIEF) {
    placeholderText = `Search for interviewer name`;
  }

  return (
    <Stack direction="column" spacing={2}>
      <Stack
        alignItems="center"
        direction="row"
        wrap="nowrap"
        justifyContent="space-between"
        style={{
          columnGap: '8px',
        }}
        itemStyles={itemStyles}
      >
        <FilterListPopover
          // Popover props
          open={showTrainedInterviewerPicker}
          onPopoverClose={toggleTrainedInterviewerPicker}
          autoFocus
          filterListInterfaceRef={filterListInterfaceRef}
          placeholderText={placeholderText}
          subActions={renderSubActions(true /* isTrained */)}
          backButton={
            selectedInterviewModuleSuggestion && (
              <IconButton variant="contained" onClick={() => setSelectedInterviewModuleSuggestion(undefined)}>
                <CaretLeftIcon />
              </IconButton>
            )
          }
          options={trainedOptions}
          values={emptySelectedTrainedInterviewersOptions}
          zeroState={
            <ZeroState
              dataTestId="update-interview-seat-trained-filter-list-zero-state"
              label="No interviewers found"
              icon={<SearchIcon color="mid-contrast-grey" fontSize={48} />}
            />
          }
          getOptionDisabled={(option) => {
            const employeeId = (option as InterviewerSuggestion)?.employeeId;

            if (!employeeId) return false;

            // If the current seat is freeform then we should allow the seat
            // to be converted to a module seat, so none of the options is disabled
            if (
              selectedInterviewModuleSuggestion &&
              filledInterviewSeat.seat.freeformSeat &&
              !excludeInterviewerIds.includes(employeeId)
            ) {
              return false;
            }

            return Boolean(
              employeeId === filledInterviewSeat.interviewerId || excludeInterviewerIds.includes(employeeId)
            );
          }}
          renderOption={(option) => {
            if ((option as InterviewerSuggestion)?.employeeId) {
              const interviewerSuggestion = option as Exclude<InterviewerSuggestion, null | undefined>;
              return (
                <InterviewerSuggestionRow
                  interviewEvent={interviewEvent}
                  interviewer={interviewerSuggestion}
                  excluded={interviewPlanConfigExcludeInterviewerIds.includes(interviewerSuggestion.employeeId)}
                  preferred={
                    interviewPlanEntityInScheduleFlowsEnabled
                      ? originalInterviewSeat?.freeformSeat?.jobStageInterviewSeatEmployees?.find(
                          (emp) => emp.employeeId === interviewerSuggestion.employeeId
                        )?.preferenceLevel === PREFERENCE_LEVEL_PREFERRED
                      : originalJobStageInterviewSeat?.freeformSeat?.jobStageInterviewSeatEmployees?.find(
                          (emp) => emp.employeeId === interviewerSuggestion.employeeId
                        )?.preferenceLevel === PREFERENCE_LEVEL_PREFERRED
                  }
                />
              );
            }
            return <ModuleSuggestionRow interviewModule={option as InterviewModuleSuggestion} />;
          }}
          getOptionLabel={(option) =>
            (option as InterviewerSuggestion)?.employee?.fullName || (option as InterviewModuleSuggestion).name || ''
          }
          groupBy={groupBy}
          renderGroup={renderGroup}
          wrapInPaper={false}
          getChildSize={getChildSize}
          getListHeight={getListHeight}
          loading={loadingInterviewerSuggestions}
          onChange={handleTrainedInterviewerSuggestionSelected}
          onInputChange={(event: React.ChangeEvent, value: string) => {
            setSearch(value);
          }}
          dataTestId="update-interview-seat-trained-interviewer-filter-list"
        >
          <Button
            variant="outlined"
            label={renderInterviewer(trainedInterviewer)}
            fullWidth
            className={classes.interviewerTag}
            endIcon={<TriangleDownIcon />}
            onClick={toggleTrainedInterviewerPicker}
            dataTestId="update-interview-seat-trained-interviewer-button"
          />
        </FilterListPopover>
        <MoreMenuButton
          options={(() => {
            if (scheduleFlowType === ScheduleFlowType.DEBRIEF) return DEBRIEF_INTERVIEWER_OPTIONS;
            return interviewModuleId ? INTERVIEWER_OPTIONS : freeformInterviewerOptions;
          })()}
          onSelect={(option) => handleMenuOptionSelect(option, true)}
          dataTestId="update-interview-seat-trained-interviewer-menu-button"
          menuDataTestId="update-interview-seat-trained-interviewer-menu"
        />
      </Stack>

      {trainedInterviewer && !traineeInterviewer && filledInterviewSeat.seat.moduleSeat && !addingTrainee && (
        <Button
          dataTestId="update-interview-seat-add-trainee-to-seat"
          color="info"
          label={`Add trainee to ${trainedInterviewer.employee.givenName}`}
          size="small"
          variant="unstyled"
          className={classes.addTraineeButton}
          onClick={() => setAddingTrainee(true)}
        />
      )}

      {(traineeInterviewer || addingTrainee) && (
        <Stack
          alignItems="center"
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
          style={{
            columnGap: '8px',
          }}
          itemStyles={traineeItemStyles}
        >
          {isModuleMemberInterviewRoleShadow(traineeInterviewer?.role) &&
            (isFastTrackTrainingEnabled ? (
              <TraineeIcon tooltip="Trainee" fontSize={24} />
            ) : (
              <ShadowIcon tooltip="Shadow" fontSize={24} color="info" />
            ))}
          {isModuleMemberInterviewRoleReverseShadow(traineeInterviewer?.role) &&
            (isFastTrackTrainingEnabled ? (
              <TraineeIcon tooltip="Trainee" fontSize={24} />
            ) : (
              <ReverseShadowIcon tooltip="Reverse shadow" fontSize={24} color="info" />
            ))}

          {addingTrainee && <div style={{ width: '24px' }} />}
          <FilterListPopover
            // Popover props
            open={showTraineeInterviewerPicker}
            // FilterList props
            onPopoverClose={toggleTraineeInterviewerPicker}
            autoFocus
            placeholderText={`Search ${data?.interviewModule?.name}`}
            subActions={renderSubActions(false /* isTrained */)}
            options={traineeInterviewerSuggestions}
            zeroState={
              <ZeroState
                dataTestId="update-interview-seat-trainee-filter-list-zero-state"
                label="No trainees found"
                icon={<SearchIcon color="mid-contrast-grey" fontSize={48} />}
              />
            }
            getOptionDisabled={(option: InterviewerSuggestion) => {
              const employeeId = option?.employeeId;

              if (isFastTrackTrainingEnabled) {
                return (
                  employeeId === filledInterviewSeat.traineeId ||
                  Boolean(employeeId && excludeInterviewerIds.includes(employeeId)) ||
                  isModuleMemberDisabledForScheduling(
                    { moduleMember: option.employee?.interviewModuleMember || undefined },
                    null
                  )
                );
              }

              return (
                employeeId === filledInterviewSeat.traineeId ||
                Boolean(employeeId && excludeInterviewerIds.includes(employeeId)) ||
                option?.employee?.interviewModuleMember?.stats?.currentTrainingSessionStatus ===
                  CurrentTrainingSessionStatus.NeedsApproval ||
                option?.employee?.interviewModuleMember?.stats?.currentTrainingSessionStatus ===
                  CurrentTrainingSessionStatus.RequiredInterviewsScheduled
              );
            }}
            renderOption={(option: InterviewerSuggestion) => {
              const interviewerSuggestion = option as Exclude<InterviewerSuggestion, null | undefined>;
              return (
                <InterviewerSuggestionRow
                  interviewEvent={interviewEvent}
                  interviewer={interviewerSuggestion}
                  excluded={interviewPlanConfigExcludeInterviewerIds.includes(interviewerSuggestion.employeeId)}
                  preferred={
                    interviewPlanEntityInScheduleFlowsEnabled
                      ? originalInterviewSeat?.freeformSeat?.jobStageInterviewSeatEmployees?.find(
                          (emp) => emp.employeeId === interviewerSuggestion.employeeId
                        )?.preferenceLevel === PREFERENCE_LEVEL_PREFERRED
                      : originalJobStageInterviewSeat?.freeformSeat?.jobStageInterviewSeatEmployees?.find(
                          (emp) => emp.employeeId === interviewerSuggestion.employeeId
                        )?.preferenceLevel === PREFERENCE_LEVEL_PREFERRED
                  }
                />
              );
            }}
            getOptionLabel={(option: InterviewerSuggestion) => option?.employee?.fullName || ''}
            groupBy={groupBy}
            renderGroup={renderGroup}
            wrapInPaper={false}
            getChildSize={getChildSize}
            getListHeight={getListHeight}
            loading={loadingInterviewerSuggestions}
            onChange={handleTraineeInterviewerSuggestionSelected}
            onInputChange={(event: React.ChangeEvent, value: string) => {
              setSearch(value);
            }}
            dataTestId="update-interview-seat-trainee-interviewer-filter-list"
          >
            <Button
              variant="outlined"
              label={renderInterviewer(traineeInterviewer)}
              fullWidth
              className={classes.interviewerTag}
              endIcon={<TriangleDownIcon />}
              onClick={toggleTraineeInterviewerPicker}
              dataTestId="update-interview-seat-trainee-interviewer-button"
            />
          </FilterListPopover>

          <MoreMenuButton
            dataTestId="update-interview-seat-trainee-interviewer-menu-button"
            menuDataTestId="update-interview-seat-trainee-interviewer-menu"
            options={TRAINEE_OPTIONS}
            onSelect={(option) => handleMenuOptionSelect(option, false)}
          />
        </Stack>
      )}
    </Stack>
  );
};

UpdateInterviewSeat.fragments = {
  interview: gql`
    fragment UpdateInterviewSeat_interview on JobStageInterview {
      id
      name
    }
  `,
  originalInterview: gql`
    fragment UpdateInterviewSeat_originalInterview on JobStageInterview {
      id
      name
    }
  `,
  interviewSeat: gql`
    fragment UpdateInterviewSeat_interviewSeat on JobStageInterviewSeat {
      id
      type
      index
      freeformSeat {
        jobStageInterviewSeatEmployees {
          employeeId
          preferenceLevel
        }
      }
      moduleSeat {
        interviewModuleId
        jobStageInterviewSeatAttributes {
          id
          attributeNameId
          attributeTagValueId
        }
        selectedEmployeeIds
      }
      linkedSeat {
        linkedJobStageInterviewSeatId
      }
    }
  `,
  originalInterviewSeat: gql`
    fragment UpdateInterviewSeat_originalInterviewSeat on JobStageInterviewSeat {
      id
      type
      index
      freeformSeat {
        jobStageInterviewSeatEmployees {
          employeeId
          preferenceLevel
        }
      }
      moduleSeat {
        interviewModuleId
        jobStageInterviewSeatAttributes {
          id
          attributeNameId
          attributeTagValueId
        }
        selectedEmployeeIds
      }
      linkedSeat {
        linkedJobStageInterviewSeatId
      }
    }
  `,
};

export default UpdateInterviewSeat;
