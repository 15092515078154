import React, { useMemo, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { AutocompleteGetTagProps } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

import { EmployeeFragment, useEmployeeByIdsQuery } from 'src/generated/mloop-graphql';

import Chip from 'src/components/chip';
import { MagicIcon } from 'src/components/icons';

import { Theme } from 'src/theme/type';

import { DynamicAudienceType } from 'src/types/preferenes';

import { DEBOUNCE_TIMEOUT, EMAIL_REGEX, EMPTY_UUID } from 'src/constants';

import EmployeePicker, { EmployeePickerProps, Value, Values, renderTagDefault } from '.';

// UUID for freeform emails to bw shown as options in autocomplete.
const FREEFORM_EMAIL_UUID = '1623b0ca-97dd-11ed-a8fc-0242ac120002';

const DYMANIC_AUDIENCE_TYPES = [
  DynamicAudienceType.CANDIDATE_COORDINATOR,
  DynamicAudienceType.CANDIDATE_RECRUITER,
  DynamicAudienceType.JOB_COORDINATOR,
  DynamicAudienceType.JOB_RECRUITER,
];

// We could have treated Dynamic audiences as freeform employees.
// However when we have DymanicAuciences disabled & freeform enabled
// and someone types in the exact string as DynamicAudienceType's
// it makes it difficult to tell if user wanted a freeform email or a dynamic audience
const DYMANIC_AUDIENCE_EMPLOYEES: EmployeeFragment[] = [
  {
    email: DynamicAudienceType.JOB_COORDINATOR,
    fullName: 'Job coordinator',
    id: DynamicAudienceType.JOB_COORDINATOR,
    orgId: EMPTY_UUID,
    hasAtsId: true,
    isArchived: false,
    isAtsDisabled: false,
    isDirectoryDisabled: false,
  },
  {
    email: DynamicAudienceType.JOB_RECRUITER,
    fullName: 'Job recruiter',
    id: DynamicAudienceType.JOB_RECRUITER,
    orgId: EMPTY_UUID,
    hasAtsId: true,
    isArchived: false,
    isAtsDisabled: false,
    isDirectoryDisabled: false,
  },
  {
    email: DynamicAudienceType.CANDIDATE_COORDINATOR,
    fullName: 'Candidate coordinator',
    id: DynamicAudienceType.CANDIDATE_COORDINATOR,
    orgId: EMPTY_UUID,
    hasAtsId: true,
    isArchived: false,
    isAtsDisabled: false,
    isDirectoryDisabled: false,
  },
  {
    email: DynamicAudienceType.CANDIDATE_RECRUITER,
    fullName: 'Candidate recruiter',
    id: DynamicAudienceType.CANDIDATE_RECRUITER,
    orgId: EMPTY_UUID,
    hasAtsId: true,
    isArchived: false,
    isAtsDisabled: false,
    isDirectoryDisabled: false,
  },
];

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type EmployeePickerExtendedProps<TData extends EmployeeFragment, Freeform extends boolean | undefined> = Omit<
  EmployeePickerProps<TData, Freeform>,
  'additionalEmployeeOptions' | 'selectedEmployees' | 'defaultSelectedEmployees' | 'onChange'
> & {
  dynamicAudiencesEnabled?: boolean;
  freeformEnabled?: Freeform;
  dynamicAudiences?: DynamicAudienceType[];
  defaultDynamicAudiences?: DynamicAudienceType[];
  freeformEmails?: string[];
  defaultFreeformEmails?: string[];
  selectedEmployeeIds?: string[];
  defaultSelectedEmployeeIds?: string[];
  onChange: (
    employeeResult: Values<TData, Freeform>,
    freeformEmails: string[],
    dynamicAudiences: DynamicAudienceType[]
  ) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invalidEmail: {
      border: `1px solid ${theme.palette.error.main} !important`,
      backgroundColor: `${theme.palette.background.error} !important`,
    },
  })
);

const EmployeePickerExtended = <TData extends EmployeeFragment, Freeform extends boolean | undefined>({
  dynamicAudiencesEnabled = false,
  freeformEnabled = true,
  dynamicAudiences,
  defaultDynamicAudiences,
  freeformEmails,
  defaultFreeformEmails,
  selectedEmployeeIds,
  defaultSelectedEmployeeIds,
  autoFocus,
  onChange,
  ...employeePickerProps
}: EmployeePickerExtendedProps<TData, Freeform>) => {
  const classes = useStyles();

  const [inputEmailListOption, setInputEmailListOption] = useState<EmployeeFragment>();

  const { data, previousData } = useEmployeeByIdsQuery({ variables: { input: selectedEmployeeIds || [] } });
  const { data: defaultSelectedEmployeesData, previousData: previousDefaultSelectedEmployeesData } =
    useEmployeeByIdsQuery({
      variables: { input: defaultSelectedEmployeeIds || [] },
    });

  const selectedEmployees = useMemo(() => {
    if (!selectedEmployeeIds) return undefined;

    return (data?.employeeByIds || previousData?.employeeByIds || []) as EmployeeFragment[];
  }, [data, previousData, selectedEmployeeIds]);

  const defaultSelectedEmployees = (defaultSelectedEmployeesData?.employeeByIds ||
    previousDefaultSelectedEmployeesData?.employeeByIds ||
    []) as EmployeeFragment[];

  const selectedDynamicAudiences = useMemo(() => {
    if (!dynamicAudiences || !dynamicAudiencesEnabled) return undefined;
    return DYMANIC_AUDIENCE_EMPLOYEES.filter((emp) => dynamicAudiences.includes(emp.id));
  }, [dynamicAudiences, dynamicAudiencesEnabled]);

  const defaultSelectedDynamicAudiences = useMemo(() => {
    if (!defaultDynamicAudiences || !dynamicAudiencesEnabled) return [];
    return DYMANIC_AUDIENCE_EMPLOYEES.filter((emp) => defaultDynamicAudiences.includes(emp.id));
  }, [defaultDynamicAudiences, dynamicAudiencesEnabled]);

  const selectedFreeformEmails = useMemo(() => {
    if (!freeformEmails || !freeformEnabled) return undefined;
    return freeformEmails;
  }, [freeformEmails, freeformEnabled]);

  const defaultSelectedFreeformEmails = useMemo(() => {
    if (!defaultFreeformEmails || !freeformEnabled) return [];
    return defaultFreeformEmails;
  }, [defaultFreeformEmails, freeformEnabled]);

  const renderTags = (options: Values<TData, Freeform>, getTagProps: AutocompleteGetTagProps) => {
    return options.map((option, index) => {
      if (typeof option !== 'string' && DYMANIC_AUDIENCE_TYPES.includes(option.id)) {
        return (
          <Chip
            key={option.id}
            label={option.fullName ?? ''}
            variant="default"
            avatar={<MagicIcon />}
            tagProps={getTagProps({ index })}
          />
        );
      }

      // For rendering freeform email Ids.
      if (typeof option !== 'string' && option.id === FREEFORM_EMAIL_UUID) {
        return renderTagDefault(option.email, index, getTagProps, classes.invalidEmail);
      }

      return renderTagDefault(option, index, getTagProps, classes.invalidEmail);
    });
  };

  const handleChange = (values: Values<TData, Freeform>) => {
    const newDynamicAudiences = values
      .filter((value) => typeof value !== 'string' && DYMANIC_AUDIENCE_TYPES.includes(value.id))
      .map((value) => (value as TData).id as DynamicAudienceType);

    const newFreeformEmails = values
      .filter((value) => typeof value === 'string' || value.id === FREEFORM_EMAIL_UUID)
      .map((value) => (typeof value === 'string' ? value.trim() : value.email)) as string[];

    const newEmployees = values.filter(
      (value) =>
        typeof value !== 'string' && !DYMANIC_AUDIENCE_TYPES.includes(value.id) && value.id !== FREEFORM_EMAIL_UUID
    );
    onChange(newEmployees, newFreeformEmails, newDynamicAudiences);
  };

  const allSelectedEmployees = useMemo(() => {
    return selectedEmployees || selectedFreeformEmails || selectedDynamicAudiences
      ? [...(selectedEmployees || []), ...(selectedFreeformEmails || []), ...(selectedDynamicAudiences || [])]
      : undefined;
  }, [selectedDynamicAudiences, selectedEmployees, selectedFreeformEmails]);

  const allDefaultSelectedEmployees = [
    ...defaultSelectedEmployees,
    ...defaultSelectedFreeformEmails,
    ...defaultSelectedDynamicAudiences,
  ];

  const handleInputChange = (event: React.ChangeEvent, value: string) => {
    if (freeformEnabled && !isEmpty(value) && value.match(EMAIL_REGEX)) {
      const emailInput = value.trim();

      const inputEmailListOptionObj = {
        fullName: emailInput,
        email: emailInput,
        id: FREEFORM_EMAIL_UUID,
        isDirectoryDisabled: false,
        orgId: EMPTY_UUID,
        isAtsDisabled: false,
        hasAtsId: true,
        isArchived: false,
      };

      setInputEmailListOption({ ...inputEmailListOptionObj });
    }
  };

  const handleInputChangeDebounced = useDebouncedCallback(handleInputChange, DEBOUNCE_TIMEOUT);

  const getOptionSelected = (option: Value<TData, Freeform>, value: Value<TData, Freeform>) => {
    const optionId = typeof option === 'string' ? option : option.id;
    const valueId = typeof value === 'string' ? value : value.id;

    // Returning false for showing an option for freeform email ids.
    if (optionId === FREEFORM_EMAIL_UUID) {
      return false;
    }

    return optionId === valueId;
  };

  const additionalEmployeeOptions = useMemo(() => {
    const returnArray: EmployeeFragment[] = [];

    if (inputEmailListOption) {
      returnArray.push(inputEmailListOption);
    }

    if (dynamicAudiencesEnabled) {
      returnArray.push(...DYMANIC_AUDIENCE_EMPLOYEES);
    }

    return returnArray;
  }, [dynamicAudiencesEnabled, inputEmailListOption]);

  return (
    <EmployeePicker
      key={allSelectedEmployees?.length ?? allDefaultSelectedEmployees.length}
      {...employeePickerProps}
      selectedEmployees={allSelectedEmployees}
      defaultSelectedEmployees={allDefaultSelectedEmployees}
      additionalEmployeeOptions={!isEmpty(additionalEmployeeOptions) ? additionalEmployeeOptions : undefined}
      renderTags={renderTags}
      onChange={handleChange}
      freeformEnabled={freeformEnabled}
      autoFocus={autoFocus}
      onInputChange={handleInputChangeDebounced}
      getOptionSelected={getOptionSelected}
    />
  );
};

export default EmployeePickerExtended;
