import React from 'react';

import BaseIcon, { Props } from './Icon';

const MicrosoftIcon = ({ fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon fontSize={fontSize} tooltip={tooltip}>
      <rect width="20" height="20" fill="white" />
      <path d="M9.60566 2.50745H2.50745V9.60566H9.60566V2.50745Z" fill="#F25022" />
      <path d="M9.60566 10.3943H2.50745V17.4926H9.60566V10.3943Z" fill="#00A4EF" />
      <path d="M17.4926 2.50745H10.3944V9.60566H17.4926V2.50745Z" fill="#7FBA00" />
      <path d="M17.4926 10.3943H10.3944V17.4926H17.4926V10.3943Z" fill="#FFB900" />
    </BaseIcon>
  );
};

export default MicrosoftIcon;
