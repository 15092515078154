import React, { useState } from 'react';

import { useFlag } from '@modernloop/shared/feature-flag';

import {
  SelfScheduleOptionSettings,
  SelfScheduleOptionsSettingInput,
  SelfScheduleZoomHost,
} from 'src/generated/mloop-graphql';

import { ZoomUserDataWithCategory } from 'src/entities/Select/ZoomUserSelect/types';
import { SmartOptions } from 'src/entities/Select/ZoomUserSelectWithSmartOptions';

import { ConfigurableSelfScheduleOptions } from '../SelfSchedule/ConfigurableSelfScheduleOptions';
import { SelfScheduleSelectedTemplates } from '../SelfSchedule/SelfSchedulePreferences';
import { NumberOfDays } from '../SelfSchedule/TimeframePicker';
import { useSelfScheduleJobStageTemplates } from '../SelfSchedule/useSelfScheduleJobStageTemplates';

export const defaultScheduleOptionsState = {
  numberOfDays: NumberOfDays.TwoWeeks,
  advanceNoticeInHours: 24,
  rollingDays: NumberOfDays.TwoWeeks,
  candidateCalendarId: '',
  interviewerCalendarId: '',
  zoomHost: SelfScheduleZoomHost.NotSet,
  isPrivateCalendarEvent: false,
  zoomHostUserId: '',
  customLocation: '',
  shouldRespectLoadLimit: true,
  canScheduleOverRecruitingKeywords: false,
  canScheduleOverAvailableKeywords: false,
  canScheduleOverFreeTime: true,
  candidateNote: '',
};

export const DURATION_CALC_FROM_INTERVIEW_PLAN = -1;
export const DAY_CALC_FROM_INTERVIEW_PLAN = -2;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
export function OrgSelfScheduleOptions({
  jobStageId,
  selfScheduleOptions,
  templates,
  onOptionsChanged,
  onTemplatesChanged,
}: {
  jobStageId: string;
  selfScheduleOptions: SelfScheduleOptionSettings;
  templates: SelfScheduleSelectedTemplates;
  onOptionsChanged: (options: SelfScheduleOptionsSettingInput) => void;
  onTemplatesChanged: (selectedTemplates: SelfScheduleSelectedTemplates) => void;
}) {
  const ignoreRollingDaysSetting = useFlag('user_ignore_rolling_window_setting');
  const scheduleLocationEnabled = useFlag('org_schedule_location');
  const [customLink, setCustomLink] = useState<string>();
  const { templates: defaultSelfScheduleTemplates } = useSelfScheduleJobStageTemplates(jobStageId);
  return (
    <ConfigurableSelfScheduleOptions
      shouldSendPersonalizedEmail={Boolean(templates?.candidateEmailTemplateId)}
      showScheduleLocation={!scheduleLocationEnabled}
      options={{
        ...selfScheduleOptions,
      }}
      numberOfDays={
        selfScheduleOptions.rollingDays ? selfScheduleOptions.rollingDays : selfScheduleOptions.numberOfDays
      }
      templates={templates}
      defaultTemplates={{
        ...defaultSelfScheduleTemplates,
      }}
      onRollingDaysChange={(value: NumberOfDays) => {
        const options = { ...selfScheduleOptions };
        if (selfScheduleOptions.rollingDays) {
          options.rollingDays = value;
        } else {
          options.numberOfDays = value;
        }
        onOptionsChanged(options);
      }}
      onRollingDaysToggle={
        !ignoreRollingDaysSetting
          ? (value: boolean) => {
              if (!value) {
                onOptionsChanged({
                  ...selfScheduleOptions,
                  rollingDays: null,
                  numberOfDays: selfScheduleOptions.rollingDays || NumberOfDays.TwoWeeks,
                });
              } else {
                onOptionsChanged({
                  ...selfScheduleOptions,
                  rollingDays: selfScheduleOptions.numberOfDays || NumberOfDays.TwoWeeks,
                });
              }
            }
          : undefined
      }
      onOptionsChanged={(settingInput) => onOptionsChanged(settingInput as SelfScheduleOptionsSettingInput)}
      onTemplatesChanged={(selectedTemplates) => onTemplatesChanged(selectedTemplates as SelfScheduleSelectedTemplates)}
      handleCustomLinksInput={(event: React.FocusEvent<HTMLInputElement>) => {
        setCustomLink(event?.target?.value);
      }}
      handleCustomLinksInputComplete={() => {
        onOptionsChanged({
          ...selfScheduleOptions,
          customLocation: customLink,
        });
      }}
      handleZoomUserChanged={(option: ZoomUserDataWithCategory) => {
        const value: string | null | SmartOptions = option.zoomUserId;
        if (value === SmartOptions.Interviewer || value === SmartOptions.Scheduler) {
          onOptionsChanged({
            ...selfScheduleOptions,
            zoomHost:
              value === SmartOptions.Interviewer ? SelfScheduleZoomHost.Interviewer : SelfScheduleZoomHost.Scheduler,
            zoomHostUserId: '',
          });
        } else if (value) {
          onOptionsChanged({
            ...selfScheduleOptions,
            zoomHost: SelfScheduleZoomHost.CustomZoomHost,
            zoomHostUserId: value,
          });
        }
      }}
      ccRecipients={selfScheduleOptions.ccRecipients}
      bccRecipients={selfScheduleOptions.bccRecipients}
      handleBccRecipients={(bccRecipients) => onOptionsChanged({ ...selfScheduleOptions, bccRecipients })}
      handleCcRecipients={(ccRecipients) => onOptionsChanged({ ...selfScheduleOptions, ccRecipients })}
      onNoteChanged={(candidateNote) => onOptionsChanged({ ...selfScheduleOptions, candidateNote })}
    />
  );
}
