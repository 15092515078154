export enum SettingsTabs {
  SCHEDULING = '/organization/scheduling',
  INTERVIEWERS = '/organization/interviewers',
  TEMPLATES = '/organization/templates',
  CANDIDATE_PORTAL = '/organization/candidate-portal',
  NOTIFICATIONS = '/organization/notifications',
  INTEGRATIONS = '/organization/integrations',
  MEMBERS = '/organization/members',
  TASK = '/organization/tasks',
  YOUR_ACCOUNT = '/organization/your_account',
  PRIVACY = '/organization/privacy',
  RESCHEDULE_REASONS = '/organization/reschedule-reasons',
  Setup = '/organization/setup',
  SINGLE_SIGN_ON = '/organization/single-sign-on',
}

export enum CandidatePortalSettingsTabs {
  PAGE_TEMPLATES = 'page-templates',
  BRANDING_THEMES = 'branding-themes',
  SETUP = 'setup',
}

export enum TaskSettingsTabs {
  GENERAL = 'general',
  TASK_TAGS = 'task-tags',
  TASK_QUEUE = 'task-queue',
  TASK_RESCHEDULE_REASONS = 'task-reschedule-reasons',
}

export enum ScheduleSettingsTabs {
  GENERAL = 'general',
  COMPANY_DAYS_OFF = 'company_days_off',
}

export enum InterviewersSettingsTabs {
  HOURS_AND_AVAILABILITY = 'hours-and-availability',
  WORK_INFO = 'work-info',
  CUSTOM_ATTRIBUTES = 'custom-attributes',
  INTERVIEWER_PORTAL = 'interviewer-portal',
}
