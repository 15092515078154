import React from 'react';

import { gql } from '@apollo/client';
import { Loader } from '@modernloop/shared/helper-components';
import { ChatBubbleWithResetArrowIcon } from '@modernloop/shared/icons';
import { Avatar, Box, Chip, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  GraduationApproverDecisionType,
  GraduationApproverType,
  Interviewfeedback_InterviewfeedbackFragment,
  TrainingFeedbackNagType,
  useSlackFeedbackNagMutation,
} from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

const DEFAULT_RECEIVED_DATE = '1970-01-01T00:00:00.000Z';

export const SlackNagMutation = gql`
  mutation SlackFeedbackNag($input: TrainingFeedbackReminderNagInput!) {
    trainingFeedbackReminderNag(input: $input) {
      interviewModuleMember {
        interviewModuleId
        employeeId
      }
    }
  }
`;

type Props = {
  feedbackType: GraduationApproverType;
  moduleId: string;
  moduleMemberId: string;
  applicationStageInterviewId: string;
  showNagButton?: boolean;
};

type Fragments = {
  interviewfeedback: Interviewfeedback_InterviewfeedbackFragment;
};

const Interviewfeedback: FCWithFragments<Fragments, Props> = ({
  interviewfeedback,
  feedbackType,
  moduleId: interviewModuleId,
  moduleMemberId: interviewModuleMemberId,
  applicationStageInterviewId,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [nagMutation, { loading }] = useSlackFeedbackNagMutation({
    onCompleted: () => {
      enqueueSnackbar('Reminder sent.', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Reminder failed to send.', {
        variant: 'error',
      });
    },
  });

  if (!interviewfeedback) return null;

  const getIcon = () => {
    if (loading) return <Loader loading={loading} size={16} />;

    return <ChatBubbleWithResetArrowIcon />;
  };

  const handleNag = () => {
    nagMutation({
      variables: {
        input: {
          reviewerId: interviewfeedback.reviewerId,
          interviewModuleId,
          interviewModuleMemberId,
          applicationStageInterviewId,
          trainingFeedbackNagType: TrainingFeedbackNagType.Adhoc,
        },
      },
    });
  };

  const getInterviewerFeedbackType = () => {
    switch (feedbackType) {
      case GraduationApproverType.Trained:
        return 'interviewer';
      case GraduationApproverType.Trainee:
        return 'trainee';
      default:
        return '';
    }
  };

  const getFeedbackLabelTextTooltip = () => {
    switch (interviewfeedback.decisionType) {
      case GraduationApproverDecisionType.Invalid:
        return 'No longer applicable. Interview was canceled';
      case GraduationApproverDecisionType.Override:
        return 'No longer applicable. Interviewer was manually approved';
      default:
        return '';
    }
  };

  const getFeedbackLabel = () => {
    const tooltip = getFeedbackLabelTextTooltip();
    let labelJSX;
    switch (interviewfeedback.decisionType) {
      case GraduationApproverDecisionType.MoveForward:
        labelJSX = <Chip size="small" label="Pass" variant="filled" color="success" />;
        break;
      case GraduationApproverDecisionType.AwaitingFeedback:
        labelJSX = <Chip size="small" label="Awaiting feedback" variant="filled" color="default" />;
        break;
      case GraduationApproverDecisionType.DoAnother:
        labelJSX = <Chip size="small" label="Do another" variant="filled" color="warning" />;
        break;
      case GraduationApproverDecisionType.Invalid:
        labelJSX = <Chip size="small" label="Invalid" variant="filled" color="error" />;
        break;
      case GraduationApproverDecisionType.MissedInterview:
        labelJSX = <Chip size="small" label="Interview missed" variant="filled" color="error" />;
        break;
      case GraduationApproverDecisionType.Override:
        labelJSX = <Chip size="small" label="Override" variant="filled" color="warning" />;
        break;
      default:
        labelJSX = <Chip size="small" label="Pass" variant="filled" color="success" />;
        break;
    }
    return <Tooltip title={tooltip}>{labelJSX}</Tooltip>;
  };

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" width="80%" spacing={1} alignItems={interviewfeedback.note ? 'start' : 'center'}>
          <Avatar
            variant="rounded"
            sx={{ width: 28, height: 28 }}
            alt={interviewfeedback.reviewer?.fullName || ''}
            src={interviewfeedback.reviewer?.slackImageUrl || ''}
          />
          <Stack direction="row" spacing={1}>
            <Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle2">{interviewfeedback.reviewer?.fullName}</Typography>{' '}
                <Typography color="text.secondary" variant="body2">
                  ({getInterviewerFeedbackType()})
                </Typography>
              </Stack>
              {interviewfeedback.note && (
                <Stack width="100%" ml={-1.5} mt={0.5} zIndex={1}>
                  <Paper
                    variant="outlined"
                    style={{
                      borderTopLeftRadius: 5,
                      width: '100%',
                    }}
                  >
                    <Box p={1} width="100%">
                      <Typography>{interviewfeedback.note}</Typography>
                    </Box>
                  </Paper>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={1} alignItems={interviewfeedback.note ? 'start' : 'center'}>
          {getFeedbackLabel()}
          {interviewfeedback.reviewedAt === DEFAULT_RECEIVED_DATE && (
            <Tooltip title="Send reminder for slack feedback">
              <IconButton onClick={handleNag}>{getIcon()}</IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

Interviewfeedback.fragments = {
  interviewfeedback: gql`
    fragment Interviewfeedback_interviewfeedback on ApplicationStageInterviewFeedback {
      reviewer {
        id
        fullName
        slackImageUrl
      }
      decisionType
      note
      reviewerId
      reviewedAt
    }
  `,
};

export default Interviewfeedback;
