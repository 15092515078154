import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { assertIsoTimestamp, formatToTimeZone, getLocalTimezone } from '@modernloop/shared/datetime';
import {
  DateIcon,
  DiamondFilled,
  EditIcon,
  ReverseShadowIcon,
  ShadowIcon,
  TimeToTrainIcon,
} from '@modernloop/shared/icons';
import { pluralize } from '@modernloop/shared/utils';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { getYear, isFuture, isPast } from 'date-fns';

import { InterviewModuleMemberKeyDates_ModuleMemberFragment, TrainingStatus } from 'src/generated/mloop-graphql';

import EditKeyDatesModal from './Modals/EditKeyDatesModal';

type Fragments = {
  moduleMember: InterviewModuleMemberKeyDates_ModuleMemberFragment;
};

enum KeyDates {
  AddedToModule = 'ADDED_TO_MODULE',
  FirstShadow = 'FIRST_SHADOW',
  FirstReverseShadow = 'FIRST_REVERSE_SHADOW',
  FullyTrained = 'FULLY_TRAINED',
  TimeToTrain = 'TIME_TO_TRAIN',
}

const ListItemData = [
  {
    title: 'Added to module',
    key: KeyDates.AddedToModule,
    icon: <DateIcon />,
  },
  {
    title: 'First shadow',
    key: KeyDates.FirstShadow,
    icon: <ShadowIcon />,
  },
  {
    title: 'First reverse shadow',
    key: KeyDates.FirstReverseShadow,
    icon: <ReverseShadowIcon />,
  },
  {
    title: 'Fully trained',
    key: KeyDates.FullyTrained,
    icon: <DiamondFilled />,
  },
  {
    title: 'Time to train',
    key: KeyDates.TimeToTrain,
    icon: <TimeToTrainIcon />,
    tooltip: (
      <>
        Time to train is the number of days it takes to go from starting a module to becoming eligible to conduct an
        interview solo.
        <b>
          {' '}
          The start date for a module is determined by the earliest of the following dates: Added to module, First
          shadow, or First reverse shadow.
        </b>{' '}
        You can edit dates via the module log or the edit button of the Key dates section.
      </>
    ),
  },
];

const InterviewModuleMemberKeyDates: FCWithFragments<Fragments> = ({ moduleMember }) => {
  const [showEditKeyDatesModal, setShowEditKeyDatesModal] = useState(false);

  const getData = (type: KeyDates) => {
    const utilGetFormattedDate = ({
      date = '',
      requiredCount = 0,
      timeToTrain,
    }: {
      date?: string;
      requiredCount?: number;
      timeToTrain?: number;
    }) => {
      if (date && getYear(new Date(date)) === 1970) {
        return 'Unknown';
      }

      if (type === KeyDates.AddedToModule) {
        return date ? formatToTimeZone(assertIsoTimestamp(date), 'MMM dd, yyyy', getLocalTimezone()) : 'Unknown';
      }

      // Show formatted date for first shadow ,fully trained and first reverse shadow
      if (date && isPast(new Date(date)) && type !== KeyDates.TimeToTrain) {
        return formatToTimeZone(assertIsoTimestamp(date), 'MMM dd, yyyy', getLocalTimezone());
      }
      if (type === KeyDates.FullyTrained) {
        if (moduleMember.status === TrainingStatus.Trained && !date) {
          return 'Unknown';
        }
      }

      // Show current status for first shadow and first reverse shadow
      if (type !== KeyDates.TimeToTrain && type !== KeyDates.FullyTrained) {
        if (isFuture(new Date(date))) return 'Scheduled';
        if (!date && requiredCount <= 0) {
          return 'Not required';
        }
      }

      if (type === KeyDates.TimeToTrain) {
        if (moduleMember.status === TrainingStatus.Trained && !date) {
          return 'Unknown';
        }
        if (date && timeToTrain === 0) {
          return 'Unknown';
        }
        return date ? `${timeToTrain} ${pluralize('day', timeToTrain || 1)}` : '';
      }

      return '';
    };

    switch (type) {
      case KeyDates.AddedToModule:
        return utilGetFormattedDate({
          date: moduleMember.createdAt,
        });
      case KeyDates.FirstReverseShadow:
        return utilGetFormattedDate({
          requiredCount: moduleMember.reverseShadowsRequired,
          date: moduleMember.reverseShadowStartAt,
        });
      case KeyDates.FirstShadow:
        return utilGetFormattedDate({
          requiredCount: moduleMember.shadowsRequired,
          date: moduleMember.shadowStartAt,
        });
      case KeyDates.FullyTrained:
        return utilGetFormattedDate({
          date: moduleMember.trainedAt,
        });
      case KeyDates.TimeToTrain:
        return utilGetFormattedDate({
          timeToTrain: moduleMember.timeToTrain,
          date: moduleMember.trainedAt,
        });
      default:
        break;
    }

    return '';
  };

  return (
    <Accordion variant="outlined" disableGutters defaultExpanded>
      <AccordionSummary>
        <Typography variant="subtitle2">Key dates</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column">
          {ListItemData.map((item) => {
            const value = getData(item.key);
            return (
              <Stack
                sx={
                  !value
                    ? {
                        opacity: '0.5',
                      }
                    : {}
                }
                key={item.key}
                direction="row"
                alignItems="center"
                py={1}
                justifyContent="space-between"
              >
                <Tooltip title={item.tooltip} sx={item.tooltip ? { cursor: 'pointer' } : undefined}>
                  <Stack direction="row" alignItems="center" columnGap={1}>
                    {item.icon}
                    <Typography variant="body2">{item.title}</Typography>
                  </Stack>
                </Tooltip>
                <Box textAlign="right">
                  <Typography variant="body2">{value}</Typography>
                </Box>
              </Stack>
            );
          })}

          <Box>
            <Button
              size="small"
              startIcon={<EditIcon />}
              onClick={() => setShowEditKeyDatesModal(true)}
              sx={{ float: 'right' }}
            >
              Edit
            </Button>
          </Box>
        </Stack>
      </AccordionDetails>

      {showEditKeyDatesModal && (
        <EditKeyDatesModal moduleMember={moduleMember} onClose={() => setShowEditKeyDatesModal(false)} />
      )}
    </Accordion>
  );
};

InterviewModuleMemberKeyDates.fragments = {
  moduleMember: gql`
    ${EditKeyDatesModal.fragments.moduleMember}
    fragment InterviewModuleMemberKeyDates_moduleMember on InterviewModuleMember {
      id
      interviewModuleId
      employeeId
      shadowStartAt
      reverseShadowStartAt
      employee {
        id
        fullName
        slackImageUrl
      }
      shadowsRequired
      reverseShadowsRequired
      interviewModule {
        id
        name
      }
      status
      createdAt
      trainedAt
      timeToTrain

      ...EditKeyDatesModal_moduleMember
    }
  `,
};

export default InterviewModuleMemberKeyDates;
