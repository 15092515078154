import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ProfileIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 12.177 17.1305 14.1508 15.7197 15.5933C15.2068 16.1177 14.6223 16.5719 13.9821 16.9401C13.9469 16.9603 13.9115 16.9803 13.876 17C13.876 17 13.876 17 13.876 17C13.876 17 13.876 17 13.876 17C12.7278 17.6372 11.4063 18 10 18C8.59375 18 7.27224 17.6372 6.12399 17C5.46841 16.6362 4.8693 16.183 4.34315 15.6569C4.32206 15.6358 4.3011 15.6146 4.28025 15.5933C2.86954 14.1508 2 12.177 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM14.0427 13.7617C14.5101 14.0444 14.5403 14.6832 14.1178 15.0296C12.9966 15.9485 11.5627 16.5 10 16.5C8.43726 16.5 7.00334 15.9485 5.88222 15.0296C5.45972 14.6832 5.48985 14.0444 5.95731 13.7617C8.18786 12.4128 11.8121 12.4128 14.0427 13.7617ZM10 11C11.3807 11 12.5 9.88071 12.5 8.5C12.5 7.11929 11.3807 6 10 6C8.61929 6 7.5 7.11929 7.5 8.5C7.5 9.88071 8.61929 11 10 11Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
