import { useMemo } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';

import {
  DurationOrgPref,
  TrainingStatus,
  UseScheduleOptionsRequestBody_InterviewPlanFragment,
} from 'src/generated/mloop-graphql';

import { PREFERENCE_LEVEL_NON_PREFERRED } from 'src/constants/InterviewPlan';

import { useOrgPrefDuration } from 'src/hooks/api/org';

import { getHardConflicts } from 'src/store/selectors/conflicts';
import {
  getExcludeInterviewerIdsByJobStageId,
  getHasBreaksByJobStageId,
  getHasMultiDayByJobStageId,
  getNumberOfDaysByJobStageId,
} from 'src/store/selectors/job-stage-plan-config';

import {
  AttributeMap,
  RequestBody,
  UIInterviewGroup,
  UIInterviewSeat,
  UIInterviewSeatFreeform,
  UIInterviewSeatLinked,
  UIInterviewSeatModule,
  UIInterviewSlot,
} from 'src/utils/api/getScheduleOptions';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { SECONDS_IN_A_DAY } from 'src/constants';
import { useSelector } from 'src/store';

type Fragments = {
  interviewPlan: UseScheduleOptionsRequestBody_InterviewPlanFragment;
};

type Props = {
  increment?: boolean;
};

const useScheduleOptionsRequestBody: FunctionWithFragments<Fragments, Props, RequestBody> = (
  { interviewPlan },
  { increment }
) => {
  const orgBreakImprovementsEnabled = useFlag('org_break_improvements');
  const { candidateTimezone, interviewPlanJobStageId, applicationId } = useScheduleFlowData();

  const storeExcludedInterviewerIds = useSelector((state) =>
    getExcludeInterviewerIdsByJobStageId(state, interviewPlanJobStageId)
  );
  const hasBreaks = useSelector((state) => getHasBreaksByJobStageId(state, interviewPlanJobStageId));
  const hasMultiDay = useSelector((state) => getHasMultiDayByJobStageId(state, interviewPlanJobStageId));
  const storeNumberOfDays = useSelector((state) => getNumberOfDaysByJobStageId(state, interviewPlanJobStageId));

  const hardConflicts = useSelector((state) => getHardConflicts(state));

  const [defaultSchedulingWindow] = useOrgPrefDuration(DurationOrgPref.DurationDefaultSchedulingWindow);

  const numberOfDays = useMemo(() => {
    const schedulingWindow = interviewPlan?.schedulingWindow;
    if (orgBreakImprovementsEnabled && (schedulingWindow === null || schedulingWindow === undefined)) {
      return defaultSchedulingWindow?.seconds / SECONDS_IN_A_DAY || undefined;
    }

    return orgBreakImprovementsEnabled
      ? (interviewPlan.schedulingWindow?.seconds || 0) / SECONDS_IN_A_DAY
      : storeNumberOfDays;
  }, [
    defaultSchedulingWindow?.seconds,
    interviewPlan.schedulingWindow,
    orgBreakImprovementsEnabled,
    storeNumberOfDays,
  ]);

  const fragmentExcludedInterviewerIds = interviewPlan.excludedEmployees?.map((employee) => employee.id) || [];
  const excludeInterviewerIds = orgBreakImprovementsEnabled
    ? fragmentExcludedInterviewerIds
    : storeExcludedInterviewerIds;

  const interviewGroups: UIInterviewGroup[] = useMemo(() => {
    if (!interviewPlan || !interviewPlan.jobStageInterviewGroups?.length) {
      return [];
    }

    return interviewPlan.jobStageInterviewGroups.map((group) => ({
      id: group.id,
      locked: group.locked,
      interviewSlotIds: group.jobStageInterviews?.map((interview) => interview.id) || [],
    }));
  }, [interviewPlan]);

  const interviewSlots: UIInterviewSlot[] = useMemo(() => {
    if (!interviewPlan || !interviewPlan.jobStageInterviewGroups?.length) {
      return [];
    }

    const interviews = interviewPlan.jobStageInterviewGroups.map((group) => group.jobStageInterviews).flat() || [];

    const slots = interviews.map((interview) => {
      const seats: UIInterviewSeat[] = [];

      if (interview?.jobStageInterviewSeats?.length) {
        interview.jobStageInterviewSeats.forEach((seat) => {
          if (seat.freeformSeat) {
            const freeformSeat: UIInterviewSeatFreeform = {
              id: seat.id,
              interviewers:
                seat.freeformSeat.jobStageInterviewSeatEmployees?.map((employee) => ({
                  id: employee.employeeId,
                  preferenceLevel: employee.preferenceLevel || PREFERENCE_LEVEL_NON_PREFERRED,
                })) || [],
            };

            seats.push({ freeformSeat });
          } else if (seat.linkedSeat) {
            const linkedSeat: UIInterviewSeatLinked = {
              id: seat.id,
              interviewSeatId: seat.linkedSeat.linkedJobStageInterviewSeatId,
            };

            seats.push({ linkedSeat });
          } else if (seat.moduleSeat) {
            const attributeMap: AttributeMap = {};

            seat.moduleSeat.jobStageInterviewSeatAttributes?.forEach((attribute) => {
              if (!attributeMap[attribute.attributeNameId]) {
                attributeMap[attribute.attributeNameId] = { attributeValues: [] };
              }
              attributeMap[attribute.attributeNameId].attributeValues.push(attribute.attributeTagValueId);
            });

            const map: { trained: string[]; shadow: string[]; reverseShadow: string[] } = {
              trained: [],
              shadow: [],
              reverseShadow: [],
            };
            seat.moduleSeat.interviewModule?.interviewModuleMembers?.items.forEach((item) => {
              if (!item) return;
              if (item?.status === TrainingStatus.Trained) {
                map.trained.push(item.employeeId);
              }
              if (item?.status === TrainingStatus.Shadow) {
                map.shadow.push(item.employeeId);
              }
              if (item?.status === TrainingStatus.ReverseShadow) {
                map.reverseShadow.push(item.employeeId);
              }
            });
            const selectedTrainedInterviewerIds = seat.moduleSeat.selectedEmployeeIds.filter((id) =>
              map.trained.includes(id)
            );
            const selectedShadowInterviewerIds = seat.moduleSeat.selectedEmployeeIds.filter((id) =>
              map.shadow.includes(id)
            );
            const selectedReverseShadowInterviewerIds = seat.moduleSeat.selectedEmployeeIds.filter((id) =>
              map.reverseShadow.includes(id)
            );

            const moduleSeat: UIInterviewSeatModule = {
              id: seat.id,
              interviewId: seat.moduleSeat.interviewModuleId,

              attributeMap,
              selectedTrainedInterviewerIds,
              selectedShadowInterviewerIds,
              selectedReverseShadowInterviewerIds,
            };

            seats.push({ moduleSeat });
          }
        });
      }

      let name = interview?.name || '';
      if (interview?.useAtsName && interview?.atsInterviewDefinition?.name) {
        name = interview?.atsInterviewDefinition?.name;
      }
      const slot: UIInterviewSlot = {
        id: interview?.id,
        name,
        customDuration: interview?.customDuration || interview?.duration || 0,
        forcedStartAt: interview?.forcedStartAt || undefined,
        interviewType: interview?.interviewType,
        isHiddenFromCandidate: interview?.isHiddenFromCandidate,
        locked: interview?.isLockedOrder,
        seats,
      };

      return slot;
    });

    return slots;
  }, [interviewPlan]);

  return {
    jobStageId: interviewPlanJobStageId,
    interviewGroups,
    interviewSlots,
    candidateTimezone,
    excludeInterviewerIds,
    hasBreaks,
    hasMultiDay: orgBreakImprovementsEnabled ? numberOfDays === undefined || numberOfDays > 1 : hasMultiDay,
    hardConflicts: hardConflicts.map((value) => ({
      startAt: value.startAt,
      endAt: value.endAt,
      organizerEmail: value.organizerEmail,
      attendeesEmails: value.attendeesEmails,
      eventUid: value.eventUid,
    })),
    throwOnZero: increment && hardConflicts.length === 0,
    numberOfDays,
    applicationId,
  };
};

useScheduleOptionsRequestBody.fragments = {
  interviewPlan: gql`
    fragment useScheduleOptionsRequestBody_interviewPlan on JobStage {
      id
      jobStageInterviewGroups {
        id
        locked
        jobStageInterviews {
          id
          interviewType
          customDuration
          duration
          forcedStartAt @client
          name
          useAtsName
          atsInterviewDefinition {
            atsId
            name
          }
          isLockedOrder
          isHiddenFromCandidate
          jobStageInterviewSeats {
            id
            freeformSeat {
              jobStageInterviewSeatEmployees {
                jobStageInterviewSeatId
                employeeId
                preferenceLevel
              }
            }
            moduleSeat {
              interviewModuleId
              jobStageInterviewSeatAttributes {
                id
                attributeNameId
                attributeTagValueId
              }
              selectedEmployeeIds
              interviewModule {
                id
                interviewModuleMembers(input: { pageInput: { limit: 500, offset: 0 } }) {
                  items {
                    id
                    employeeId
                    status
                  }
                  nextCursor
                }
              }
            }
            linkedSeat {
              linkedJobStageInterviewSeatId
            }
          }
        }
      }
      schedulingWindow {
        seconds
      }
      excludedEmployees {
        id
      }
    }
  `,
};

export default useScheduleOptionsRequestBody;
