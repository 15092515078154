import { CodeLinkType, InterviewMeetingLocationType } from 'src/generated/mloop-graphql';

import {
  CodingUrlBySlotId,
  CustomVideoMeetingLinkUrlBySlotId,
  InterviewMeetingLocationIdBySlotId,
  MeetingLinksConfig,
  MeetingRoomSuggestionInterface,
  ScheduleContent,
  VideoMeetingLinkBySlotId,
  VideoMeetingUserIdBySlotId,
  ZoomInfo,
} from 'src/store/slices/schedule-communications';

import { EMPTY_OBJECT } from 'src/constants';
import { State } from 'src/store';

// TODO: Add all of the slack based stuff here too

export const getCommonMeetingRoomSuggestionsByEventId = (
  state: State,
  scheduleId: string,
  eventId: string
  // eslint-disable-next-line max-params
): MeetingRoomSuggestionInterface[] | undefined =>
  state.scheduleCommunications.byId[scheduleId]?.commonMeetingRoomSuggestionByEvent[eventId];

export const getMeetingRoomSuggestionsByInterviewEventId = (
  state: State,
  scheduleId: string,
  interviewEventId: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
MeetingRoomSuggestionInterface[] | undefined =>
  state.scheduleCommunications.byId[scheduleId]?.meetingRoomSuggestionsPerInterviewEvent[interviewEventId];

export const getMeetingRoomUpdates = (
  state: State,
  scheduleId: string,
  interviewEventId: string
): {
  addedRooms: MeetingRoomSuggestionInterface[];
  deletedRooms: MeetingRoomSuggestionInterface[];
  unchangedRooms: MeetingRoomSuggestionInterface[];
  // eslint-disable-next-line max-params
} => {
  const added: MeetingRoomSuggestionInterface[] = [];
  const deleted: MeetingRoomSuggestionInterface[] = [];
  const unchanged: MeetingRoomSuggestionInterface[] = [];

  const { selectedScheduleId } = state.scheduling;
  const scheduleUpdates = selectedScheduleId ? state.scheduleUpdate.byId[selectedScheduleId] : null;

  if (scheduleUpdates) {
    scheduleUpdates.forEach((update) => {
      if (update.applicationStageInterviewId !== interviewEventId) return;

      if (update.type === 'ScheduleUpdateAddInterviewMeetingRoom') {
        added.push(update.roomSuggestion);
      } else if (update.type === 'ScheduleUpdateDeleteInterviewMeetingRoom') {
        deleted.push(update.roomSuggestion);
      }
    });
  }

  const meetingRoomSuggestions =
    state.scheduleCommunications.byId[scheduleId]?.meetingRoomSuggestionsPerInterviewEvent[interviewEventId];

  if (meetingRoomSuggestions) {
    meetingRoomSuggestions.forEach((roomSuggestion) => {
      if (
        added.findIndex((suggestion) => suggestion.room?.id === roomSuggestion.room?.id) === -1 &&
        deleted.findIndex((suggestion) => suggestion.room?.id === roomSuggestion.room?.id) === -1
      ) {
        unchanged.push(roomSuggestion);
      }
    });
  }

  return { addedRooms: added, deletedRooms: deleted, unchangedRooms: unchanged };
};

export const getApplicationStageIdByScheduleId = (state: State, scheduleId: string): string | undefined =>
  state.scheduleCommunications.byId[scheduleId]?.applicationStageId;

export const getAddedCodeLinkTypes = (state: State, scheduleId: string): CodeLinkType[] => {
  const codingUrlByInterviewEvents = state.scheduleCommunications.byId[scheduleId]?.codingUrlBySlotId;
  const codeLinkTypes: CodeLinkType[] = [];

  if (!codingUrlByInterviewEvents) return codeLinkTypes;

  Object.keys(codingUrlByInterviewEvents).forEach((codeLinkType: CodeLinkType) => codeLinkTypes.push(codeLinkType));

  return codeLinkTypes;
};

export const getAllCodingUrls = (state: State, scheduleId: string): CodingUrlBySlotId | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.codingUrlBySlotId;
};

export const getCodingUrl = (
  state: State,
  scheduleId: string,
  interviewEventId: string,
  codeLinkType: CodeLinkType // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
string | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.codingUrlBySlotId[codeLinkType]?.[interviewEventId];
};

export const getCustomVideoMeetingLinkUrlForSlotId = (
  state: State,
  scheduleId: string,
  slotId: string // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
string | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.customVideoMeetingLinkUrls?.[slotId];
};
export const getCustomVideoMeetingLinkUrls = (
  state: State,
  scheduleId: string | null
): CustomVideoMeetingLinkUrlBySlotId => {
  if (!scheduleId) {
    return {};
  }
  return state.scheduleCommunications.byId[scheduleId]?.customVideoMeetingLinkUrls;
};

export const getMeetingLinksConfig = (state: State, scheduleId: string): MeetingLinksConfig | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.meetingLinkConfig;
};

export const getInterviewMeetingLocationId = (state: State, scheduleId: string): string | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.interviewMeetingLocationId;
};

export const getInterviewMeetingLocationIds = (
  state: State,
  scheduleId: string
): InterviewMeetingLocationIdBySlotId | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.interviewMeetingLocationIdBySlotId;
};

export const getCustomVideoMeetingLink = (state: State, scheduleId: string): string | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.customVideoMeetingLink;
};

export const getVideoMeetingLinkHostEmployeeId = (state: State, scheduleId: string | null): string | undefined => {
  if (!scheduleId) return undefined;
  return state.scheduleCommunications.byId[scheduleId]?.videoMeetingLinkHostEmployeeId;
};

export const getVideoMeetingLink = (state: State, scheduleId: string): string | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.videoMeetingLink;
};

export const getVideoMeetingLinkUrls = (state: State, scheduleId: string | null): VideoMeetingLinkBySlotId => {
  if (!scheduleId) return {};
  return state.scheduleCommunications.byId[scheduleId]?.videoMeetingLinkBySlotId;
};

export const getVideoMeetingLinkHostEmployees = (
  state: State,
  scheduleId: string | null
): VideoMeetingUserIdBySlotId => {
  if (!scheduleId) return EMPTY_OBJECT;
  return state.scheduleCommunications.byId[scheduleId]?.videoMeetingHostEmployeeIdBySlotId;
};

export const getVideoMeetingLinkBySlotId = (
  state: State,
  { scheduleId, slotId }: { scheduleId: string; slotId: string }
): string | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.videoMeetingLinkBySlotId[slotId];
};

export const getVideoMeetingLinkHostEmployeeIdBySlotId = (
  state: State,
  { scheduleId, slotId }: { scheduleId: string; slotId: string }
): string | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.videoMeetingHostEmployeeIdBySlotId[slotId];
};

export const getZoomUserId = (state: State, scheduleId: string): string | undefined =>
  state.scheduleCommunications.byId[scheduleId]?.zoomUserId;

export const getZoomInfo = (state: State, scheduleId: string): ZoomInfo | undefined =>
  state.scheduleCommunications.byId[scheduleId]?.zoomInfo;

export const getZoomInfos = (state: State, scheduleId: string) =>
  state.scheduleCommunications.byId[scheduleId]?.zoomInfos;

export const getZoomUserIds = (state: State, scheduleId: string) =>
  state.scheduleCommunications.byId[scheduleId]?.zoomUserIds;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getZoomUserIdBySlotId = (state: State, scheduleId: string, slotId: string): string | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.zoomUserIds[slotId];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getZoomInfoBySlotId = (state: State, scheduleId: string, slotId: string): ZoomInfo | undefined => {
  return state.scheduleCommunications.byId[scheduleId]?.zoomInfos[slotId];
};

export const getSlackChannelEnabled = (state: State, scheduleId: string | null): boolean => {
  if (!scheduleId) {
    return false;
  }
  // Wrapping in boolean
  return Boolean(state.scheduleCommunications.byId[scheduleId]?.slackChannelEnabled);
};

export const getSlackChannelTemplateId = (state: State, scheduleId: string | null): string | undefined => {
  if (!scheduleId) {
    return undefined;
  }
  return state.scheduleCommunications.byId[scheduleId]?.slackChannelMessageTemplateID;
};

export const getLocation = (state: State, scheduleId: string): InterviewMeetingLocationType => {
  return state.scheduleCommunications.byId[scheduleId]?.location || InterviewMeetingLocationType.NotSpecified;
};

export const getSlackChannelFilledBodyTokens = (state: State, scheduleId: string) => {
  return state.scheduleCommunications.byId[scheduleId]?.slackChannelFilledBodyTokens;
};

export const getDebriefRescheduleDetailHasAttendeeAdded = (state: State, scheduleId: string) => {
  return state.scheduleCommunications.byId[scheduleId]?.hasAttendeeAdded;
};

export const getDebriefRescheduleDetailHasScheduleTimeAfterDebriefStartAt = (state: State, scheduleId: string) => {
  return state.scheduleCommunications.byId[scheduleId]?.hasScheduleTimeAfterDebriefStartAt;
};

export const getScheduleContent = (state: State): ScheduleContent | null | undefined => {
  return state.scheduleCommunications.byId[state.scheduling.selectedScheduleId || '']?.scheduleContent;
};
