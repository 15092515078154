/* eslint-disable max-lines */
import { CodeLinkType, InterviewMeetingLocationType } from 'src/generated/mloop-graphql';

import { setScheduleContentStepWaiting } from 'src/slices/scheduling';

import scheduleCommunicationsSlice, {
  CandidateCalendarEventContent,
  CandidateEventContent,
  InterviewerEventContent,
  MeetingLinksConfig,
  MeetingRoomSuggestionInterface,
  ScheduleContent,
  ZoomInfo,
} from 'src/store/slices/schedule-communications';

import { AppThunk } from 'src/store';

export const clearScheduleCommunications =
  (scheduleId: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.clearScheduleCommunications({ scheduleId }));
  };

export const setFlow =
  (
    scheduleId: string,
    applicationId: string,
    isDebrief: boolean
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.setFlow({ scheduleId, applicationId, isDebrief }));
  };

export const updateShouldSaveJobTemplates =
  (scheduleId: string, shouldSaveJobTemplates: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateShouldSaveJobTemplates({ scheduleId, shouldSaveJobTemplates }));
  };

export const setCommonMeetingRoomSuggestionsByEventId =
  (
    scheduleId: string,
    eventIds: string[],
    roomSuggestions: MeetingRoomSuggestionInterface[]
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      scheduleCommunicationsSlice.actions.setCommonMeetingRoomSuggestionsByEventId({
        scheduleId,
        eventIds,
        roomSuggestions,
      })
    );
  };

export const setMeetingRoomSuggestionsForInterviewEventId =
  (
    scheduleId: string,
    interviewEventId: string,
    roomSuggestions: MeetingRoomSuggestionInterface[]
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      scheduleCommunicationsSlice.actions.setMeetingRoomSuggestionsForInterviewEventId({
        scheduleId,
        interviewEventId,
        roomSuggestions,
      })
    );
  };

export const setApplicationStageIdByScheduleId =
  (scheduleId: string, applicationStageId: string) => async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.setApplicationStageIdByScheduleId({ scheduleId, applicationStageId }));
  };

export const addCodeLinkType = (scheduleId: string, codeLinkType: CodeLinkType) => async (dispatch) => {
  dispatch(scheduleCommunicationsSlice.actions.addCodeLinkType({ scheduleId, codeLinkType }));
};

export const deleteCodeLinkType = (scheduleId: string, codeLinkType: CodeLinkType) => async (dispatch) => {
  dispatch(scheduleCommunicationsSlice.actions.deleteCodeLinkType({ scheduleId, codeLinkType }));
};

export const resetUnusedCodeLinkTypes = (scheduleId: string, codeLinkTypeToKeep: CodeLinkType) => async (dispatch) => {
  dispatch(
    scheduleCommunicationsSlice.actions.resetUnusedCodeLinkTypes({
      scheduleId,
      codeLinkTypeToKeep,
    })
  );
};

export const setCodingUrl =
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  (scheduleId: string, codeLinkType: CodeLinkType, slotId: string, url: string) => async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.setCodingUrl({ scheduleId, codeLinkType, slotId, url }));
  };

export const deleteCodingUrl =
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  (scheduleId: string, codeLinkType: CodeLinkType, interviewEventId: string) => async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.deleteCodingUrl({ scheduleId, codeLinkType, interviewEventId }));
  };

export const updateSlackChannelMessage =
  (scheduleId: string, message: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateSlackChannelMessage({ scheduleId, message }));
  };
export const updateRemoteSlackChannelId =
  (scheduleId: string, remoteChannelId: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateRemoteSlackChannelId({ scheduleId, remoteId: remoteChannelId }));
  };

export const updateConversationId =
  (scheduleId: string, conversationId: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateConversationId({ scheduleId, conversationId }));
  };

export const updateSlackChannelName =
  (scheduleId: string, name: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateSlackChannelName({ scheduleId, name }));
  };

export const updatePrevSlackChannelName =
  (scheduleId: string, name: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updatePrevSlackChannelName({ scheduleId, name }));
  };

export const updateSlackChannelEnabled =
  (scheduleId: string, isEnabled: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateSlackChannelEnabled({ scheduleId, isEnabled }));
  };

export const updateSlackChannelInterviewerEmployeeIDs =
  (scheduleId: string, employeeIDs: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateSlackChannelInterviewerEmployeeIDs({ scheduleId, employeeIDs }));
  };

export const updateSlackChannelRecruitingTeamEmployeeIDs =
  (scheduleId: string, employeeIDs: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(
      scheduleCommunicationsSlice.actions.updateSlackChannelRecruitingTeamEmployeeIDs({ scheduleId, employeeIDs })
    );
  };

export const updateSlackChannelHiringManagerEmployeeIDs =
  (scheduleId: string, employeeIDs: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(
      scheduleCommunicationsSlice.actions.updateSlackChannelHiringManagerEmployeeIDs({ scheduleId, employeeIDs })
    );
  };

export const updateSlackChannelMessageTemplateID =
  (scheduleId: string, templateID: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      scheduleCommunicationsSlice.actions.updateSlackChannelMessageTemplateID({ scheduleId, templateId: templateID })
    );
  };

export const updateCustomVideoMeetingLinkUrlBySlotId =
  (
    scheduleId: string,
    videoMeetingUrl: string,
    slotId: string
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      scheduleCommunicationsSlice.actions.updateCustomVideoMeetingLinkUrlBySlotId({
        scheduleId,
        videoMeetingUrl,
        slotId,
      })
    );
  };

export const updateCustomVideoMeetingLinksConfig =
  (scheduleId: string, config: MeetingLinksConfig): AppThunk =>
  async (dispatch) => {
    dispatch(
      scheduleCommunicationsSlice.actions.updateCustomVideoMeetingLinksConfig({
        scheduleId,
        config,
      })
    );
  };

export const updateInterviewMeetingLocationId =
  (scheduleId: string, meetingLocationId?: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateInterviewMeetingLocationId({ scheduleId, meetingLocationId }));
  };

export const updateinterviewMeetingLocationIdBySlotId =
  ({
    scheduleId,
    slotId,
    meetingLocationId,
  }: {
    scheduleId: string;
    slotId: string;
    meetingLocationId?: string;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(
      scheduleCommunicationsSlice.actions.updateinterviewMeetingLocationIdBySlotId({
        scheduleId,
        slotId,
        meetingLocationId,
      })
    );
  };

export const clearCustomVideoMeetingLinkUrlBySlotId =
  (scheduleId: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      scheduleCommunicationsSlice.actions.clearCustomVideoMeetingLinkUrlBySlotId({
        scheduleId,
      })
    );
  };

export const updateLocation =
  (scheduleId: string, location: InterviewMeetingLocationType): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateLocation({ scheduleId, location }));
  };

export const updateVideoMeetingLinkConfig =
  (scheduleId: string, config: MeetingLinksConfig): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateVideoMeetingLinkConfig({ scheduleId, config }));
  };

export const updateVideoMeetingLinkHostEmployeeId =
  (scheduleId: string, hostEmployeeId: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateVideoMeetingLinkHostEmployeeId({ scheduleId, hostEmployeeId }));
  };

export const updateVideoMeetingLink =
  (scheduleId: string, videoMeetingUrl: string | undefined): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateVideoMeetingLink({ scheduleId, videoMeetingUrl }));
  };

export const updateVideoMeetingLinkForSlotId =
  (args: { scheduleId: string; videoMeetingUrl: string; slotId: string }): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateVideoMeetingLinkForSlotId(args));
  };

export const updateVideoMeetingHostEmployeeIdForSlotId =
  (args: { scheduleId: string; videoMeetingHostEmployeeId: string; slotId: string }): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateVideoMeetingHostEmployeeIdForSlotId(args));
  };

export const updateCustomVideoMeetingLink =
  (scheduleId: string, customVideoMeetingLink: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateCustomVideoMeetingLink({ scheduleId, customVideoMeetingLink }));
  };

export const clearCustomVideoMeetingLink =
  (scheduleId: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.clearCustomVideoMeetingLinkUrl({ scheduleId }));
  };

export const applyCustomVideoMeetingURL = (): AppThunk => async (dispatch, getState) => {
  dispatch(setScheduleContentStepWaiting(false));
  const { scheduling } = getState();
  if (scheduling.selectedScheduleId) {
    dispatch(clearCustomVideoMeetingLinkUrlBySlotId(scheduling.selectedScheduleId));
  }
};

export const updateZoomMeetingConfig =
  (scheduleId: string, config: MeetingLinksConfig): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateZoomMeetingConfig({ scheduleId, config }));
  };

export const updateZoomUserId =
  (scheduleId: string, zoomUserId?: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateZoomInfo({ scheduleId, zoomInfo: undefined }));
    dispatch(scheduleCommunicationsSlice.actions.updateZoomUserId({ scheduleId, zoomUserId }));
  };

export const updateZoomInfo =
  (scheduleId: string, zoomInfo?: ZoomInfo): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateZoomInfo({ scheduleId, zoomInfo }));
  };

export const clearZoomUserInfo =
  (scheduleId: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateZoomUserId({ scheduleId, zoomUserId: undefined }));
    dispatch(scheduleCommunicationsSlice.actions.updateZoomInfo({ scheduleId }));
  };

export const updateZoomUserIdForSlotId =
  (
    scheduleId: string,
    slotId: string,
    zoomUserId?: string
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateZoomUserIdForSlotId({ scheduleId, slotId, zoomUserId }));
    dispatch(scheduleCommunicationsSlice.actions.updateZoomInfoForSlotId({ scheduleId, slotId, zoomInfo: undefined }));
  };

export const updateZoomInfoForSlotId =
  (
    scheduleId: string,
    slotId: string,
    zoomInfo: ZoomInfo
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateZoomInfoForSlotId({ scheduleId, slotId, zoomInfo }));
  };

export const clearZoomUserInfoForSlotId =
  (scheduleId: string, slotId: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      scheduleCommunicationsSlice.actions.updateZoomUserIdForSlotId({ scheduleId, slotId, zoomUserId: undefined })
    );
    dispatch(scheduleCommunicationsSlice.actions.updateZoomInfoForSlotId({ scheduleId, slotId, zoomInfo: undefined }));
  };

export const updateScheduleContent =
  (scheduleId: string, scheduleContent: ScheduleContent): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateScheduleContent({ scheduleId, scheduleContent }));
  };

export const updateCandidateEventContent =
  (scheduleId: string, content: CandidateEventContent): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateCandidateEventContent({ scheduleId, content }));
  };

export const updateCandidateCalendarEventContent =
  (scheduleId: string, content: CandidateCalendarEventContent): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateCandidateCalendarEventContent({ scheduleId, content }));
  };

export const updateInterviewerContentViaIndex =
  (
    scheduleId: string,
    content: InterviewerEventContent,
    eventId: string
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateInterviewerContentViaIndex({ scheduleId, content, eventId }));
  };

export const updateCandidateContentSummary =
  (scheduleId: string, value: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateCandidateContentSummary({ scheduleId, value }));
  };

export const updateCandidateContentDescription =
  (scheduleId: string, value: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateCandidateContentDescription({ scheduleId, value }));
  };

export const updateInterviewerContentSummary =
  (
    scheduleId: string,
    value: string,
    eventId: string
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateInterviewerContentSummary({ scheduleId, value, eventId }));
  };

export const updateInterviewerContentDescription =
  (
    scheduleId: string,
    value: string,
    eventId: string
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateInterviewerContentDescription({ scheduleId, value, eventId }));
  };

export const updateCandidateCalendarSummary =
  (scheduleId: string, subject: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateCandidateCalendarSummary({ scheduleId, subject }));
  };

export const updateCandidateCalendarDescription =
  (scheduleId: string, body: string): AppThunk =>
  async (dispatch) => {
    dispatch(scheduleCommunicationsSlice.actions.updateCandidateCalendarDescription({ scheduleId, body }));
  };

export const updateDebriefRescheduleDetails =
  (
    scheduleId: string,
    hasAttendeeAdded?: boolean,
    hasScheduleTimeAfterDebriefStartAt?: boolean
    // eslint-disable-next-line max-params
  ) =>
  async (dispatch) => {
    dispatch(
      scheduleCommunicationsSlice.actions.updateDebriefRescheduleDetails({
        scheduleId,
        hasAttendeeAdded,
        hasScheduleTimeAfterDebriefStartAt,
      })
    );
  };
