import { SxProps, useTheme } from '@mui/material';
import { useMemo } from 'react';

type QuillEditorStylesOptions = {
  height?: number;
};

export const useQuillEditorSxProps = (options: QuillEditorStylesOptions) => {
  const theme = useTheme();

  return useMemo(() => {
    return {
      root: ((): SxProps => ({
        '& .ql-toolbar': {
          borderLeft: 'none',
          borderTop: 'none',
          borderRight: 'none',
          borderBottom: `1px solid ${theme.palette.divider}`,
          '& .ql-picker-label:hover': {
            color: theme.palette.primary.main,
          },
          '& .ql-picker-label.ql-active': {
            color: theme.palette.primary.main,
          },
          '& .ql-picker-item:hover': {
            color: theme.palette.primary.main,
          },
          '& .ql-picker-item.ql-selected': {
            color: theme.palette.primary.main,
          },
          '& button:hover': {
            color: theme.palette.primary.main,
            '& .ql-stroke': {
              stroke: theme.palette.primary.main,
            },
          },
          '& button:focus': {
            color: theme.palette.primary.main,
            '& .ql-stroke': {
              stroke: theme.palette.primary.main,
            },
          },
          '& button.ql-active': {
            '& .ql-stroke': {
              stroke: theme.palette.primary.main,
            },
          },
          '& .ql-stroke': {
            stroke: theme.palette.text.primary,
          },
          '& .ql-picker': {
            color: theme.palette.text.primary,
          },
          '& .ql-picker-options': {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
            border: 'none',
            boxShadow: theme.shadows[10],
            borderRadius: theme.shape.borderRadius,
          },
        },
        '& .ql-container': {
          border: 'none',
          '&.ql-snow': {
            border: 'none',
          },
          '& .ql-editor': {
            height: options.height ? `${options.height}px` : undefined,
            fontFamily: theme.typography.fontFamily,
            fontSize: 16,
            color: theme.palette.text.primary,
            '&.ql-blank::before': {
              color: theme.palette.text.secondary,
            },
            '& p': {
              lineHeight: '1.6em',
              marginBottom: '1rem',
              textRendering: 'optimizeLegibility',
            },
          },
        },
      }))(),
    };
  }, [options.height, theme]);
};
