import React from 'react';

import BaseIcon, { Props } from './Icon';

const TriangleDownIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        d="M9.22723 13.0909C8.12782 11.8184 7.20142 10.8479 6.07138 9.80758C5.38436 9.17514 5.81532 8 6.74912 8H13.2509C14.1847 8 14.6156 9.17514 13.9286 9.80758C12.7986 10.8479 11.8722 11.8184 10.7728 13.0909C10.366 13.5616 9.63398 13.5616 9.22723 13.0909Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TriangleDownIcon;
