import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskCreated_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import ActivityLogText from 'src/entities/ActivityLog/Helpers/ActivityLogText';
import TaskName from 'src/entities/common/TaskName';

interface Fragments {
  activityLog: ActivityLogTaskCreated_ActivityLogFragment;
}

const ActivityLogTaskCreated: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (!details || details.__typename !== 'ActivityLogTaskDetails') {
    throw new Error('ActivityLogTaskCreated: details is not ActivityLogTaskDetails');
  }

  const { task, sourcingLinkId } = details;
  const createdViaSourcingLink = !!sourcingLinkId;
  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={
        <ActivityLogText>
          created&nbsp;
          <TaskName task={task} />
          {createdViaSourcingLink && ' via sourcing link'}
        </ActivityLogText>
      }
    />
  );
};

ActivityLogTaskCreated.fragments = {
  activityLog: gql`
    ${TaskName.fragments.task}
    ${ActivityLogBase.fragments.activityLog}

    fragment ActivityLogTaskCreated_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogTaskDetails {
          __typename
          taskId
          sourcingLinkId
          task {
            id
            ...TaskName_task
          }
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskCreated;
