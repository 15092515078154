import { SvgIcon, SvgIconProps } from './SvgIcon';

export const SidepanelIconText = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.25 3.5C17.6642 3.5 18 3.16421 18 2.75C18 2.33579 17.6642 2 17.25 2H2.75C2.33579 2 2 2.33579 2 2.75C2 3.16421 2.33579 3.5 2.75 3.5H17.25ZM2.75 6.5C2.33579 6.5 2 6.83579 2 7.25C2 7.66421 2.33579 8 2.75 8L17.25 8C17.6642 8 18 7.66422 18 7.25C18 6.83579 17.6642 6.5 17.25 6.5L2.75 6.5ZM2.75 11.5C2.33579 11.5 2 11.8358 2 12.25C2 12.6642 2.33579 13 2.75 13L17.25 13C17.6642 13 18 12.6642 18 12.25C18 11.8358 17.6642 11.5 17.25 11.5L2.75 11.5ZM2 17.25C2 16.8358 2.33579 16.5 2.75 16.5L9.25 16.5C9.66421 16.5 10 16.8358 10 17.25C10 17.6642 9.66421 18 9.25 18H2.75C2.33579 18 2 17.6642 2 17.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
