import React, { useMemo, useState } from 'react';

import { assertIsoTimestamp, formatToTimeZone, getLocalTimezone, getTZAbbr } from '@modernloop/shared/datetime';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Alert, AlertTitle, Stack, Typography } from '@mui/material';

import { InterviewMeetingLocationType, useCalendarMeetingCreateMutation } from 'src/generated/mloop-graphql';

import EmployeeChip from 'src/components/chip/EmployeeChip';

import {
  updateVideoMeetingHostEmployeeIdForSlotId,
  updateVideoMeetingLinkForSlotId,
} from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import {
  getVideoMeetingLinkBySlotId,
  getVideoMeetingLinkHostEmployeeIdBySlotId,
} from 'src/store/selectors/schedule-communications';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import { InterviewEvent } from 'src/utils/api/getScheduleOptions';

import { useDispatch, useSelector } from 'src/store';

import VideoMeetingLinkWithHost from './VideoMeetingLinkWithHost';
import usePopulateVideoInterviewMeetingLocation from './usePopulateVideoInterviewMeetingLocation';

type Props = {
  scheduleId: string;
  interview: InterviewEvent;
  location: InterviewMeetingLocationType;
};

const VideoMeetingLinkMultipleRow = ({ scheduleId, interview, location }: Props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();

  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const { id, slotId, startAt, endAt } = interview;
  const meetingLinkByInterviewId = useSelector((state) =>
    getVideoMeetingLinkBySlotId(state, { scheduleId, slotId: slotId || '' })
  );
  const hostEmployeeId = useSelector((state) =>
    getVideoMeetingLinkHostEmployeeIdBySlotId(state, {
      scheduleId,
      slotId: slotId || '',
    })
  );

  const [calendarMeetingCreate] = useCalendarMeetingCreateMutation();
  const populateVideoInterviewMeetingLocation = usePopulateVideoInterviewMeetingLocation({
    scheduleId,
    interviewMeetingLocationType: location,
  });

  const interviewerIds = useMemo(() => {
    return interview.interviewers.map((i) => i.employeeId);
  }, [interview.interviewers]);

  const handleHostChanged = async (newHostEmployeeId: string) => {
    try {
      if (scheduleLocationEnabled) {
        populateVideoInterviewMeetingLocation({ hostEmployeeId: newHostEmployeeId, slotId });
      } else {
        const { data } = await calendarMeetingCreate({
          variables: { input: { timeRange: { startAt, endAt }, hostEmployeeId } },
        });

        const url = data?.calendarMeetingCreate?.url;
        if (url) {
          dispatch(updateVideoMeetingLinkForSlotId({ scheduleId, slotId: slotId || '', videoMeetingUrl: url }));
          dispatch(
            updateVideoMeetingHostEmployeeIdForSlotId({
              scheduleId,
              slotId: slotId || '',
              videoMeetingHostEmployeeId: newHostEmployeeId,
            })
          );

          dispatch(
            addInterviewScheduleUpdate({
              type: 'ScheduleUpdateVideoUrl',
              updateType: ScheduleUpdateType.EDIT,
              scheduleId,
              applicationStageInterviewId: id,
            })
          );
        }
      }
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <Stack spacing={0.5}>
      <Typography variant="subtitle2">{interview.name}</Typography>
      <Stack direction="row" alignItems="center">
        <Typography>
          {formatToTimeZone(assertIsoTimestamp(startAt), 'hh:mm a', getLocalTimezone())} -{' '}
          {formatToTimeZone(assertIsoTimestamp(endAt), 'hh:mm a', getLocalTimezone())} {getTZAbbr(getLocalTimezone())}
        </Typography>
        {interview.interviewers.map((i) => (
          <EmployeeChip
            key={i.employeeId}
            dataTestId="zoom-multi-employee-chip"
            name={i?.employee?.fullName || ''}
            photoSrc={i?.employee?.slackImageUrl || undefined}
            variant="default"
          />
        ))}
      </Stack>

      <VideoMeetingLinkWithHost
        interviewerIds={interviewerIds}
        hostEmployeeId={hostEmployeeId}
        url={meetingLinkByInterviewId}
        onHostChanged={handleHostChanged}
      />

      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
    </Stack>
  );
};

export default VideoMeetingLinkMultipleRow;
