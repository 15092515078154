import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Chip, Theme } from '@mui/material';

import {
  CurrentTrainingSessionStatus,
  ModuleMemberTrainingStatus_ModuleMemberFragment,
} from 'src/generated/mloop-graphql';

import ModuleMemberPausedChip from './ModuleMemberPausedChip';
import ModuleMemberPendingChip from './ModuleMemberPendingChip';

interface Fragments {
  moduleMember: ModuleMemberTrainingStatus_ModuleMemberFragment;
}

const ModuleMemberTrainingStatus: FCWithFragments<Fragments> = ({ moduleMember }) => {
  if (
    moduleMember.employee?.isDirectoryDisabled ||
    moduleMember.employee?.isAtsDisabled ||
    !moduleMember.employee?.hasAtsId
  ) {
    return (
      <Chip
        label="Inactive"
        variant="filled"
        size="medium"
        color="secondary"
        sx={{
          border: (theme: Theme) => `1px solid ${theme.palette.secondary.dark}`,
        }}
      />
    );
  }

  // TODO: Replace this logic with the util function once this PR is merged #4114
  if (moduleMember.stats?.currentTrainingSessionStatus === CurrentTrainingSessionStatus.NeedsApproval) {
    return <ModuleMemberPendingChip moduleMember={moduleMember} />;
  }

  // TODO: Replace this logic with the util function once this PR is merged #4114
  if (moduleMember.employee?.isPaused || moduleMember.isPausedIndefinitely || moduleMember.isPaused) {
    return <ModuleMemberPausedChip moduleMember={moduleMember} />;
  }

  return (
    <Chip
      label="Active"
      variant="filled"
      size="medium"
      color="secondary"
      sx={{
        border: (theme: Theme) => `1px solid ${theme.palette.secondary.dark}`,
      }}
    />
  );
};

ModuleMemberTrainingStatus.fragments = {
  moduleMember: gql`
    ${ModuleMemberPausedChip.fragments.moduleMember}
    ${ModuleMemberPendingChip.fragments.moduleMember}
    fragment ModuleMemberTrainingStatus_moduleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      isPausedIndefinitely
      isPaused
      pausedUntil
      stats {
        currentTrainingSessionStatus
      }
      employee {
        id
        isPaused
        timezone
        isAtsDisabled
        isDirectoryDisabled
        hasAtsId
        interviewPauseDates {
          start
          end
        }
      }
      ...ModuleMemberPendingChip_moduleMember
      ...ModuleMemberPausedChip_moduleMember
    }
  `,
};

export default ModuleMemberTrainingStatus;
