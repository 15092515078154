import { SvgIcon, SvgIconProps } from './SvgIcon';

export const OpenInNewTabIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.25C7.58579 5.25 7.25 5.58579 7.25 6C7.25 6.41421 7.58579 6.75 8 6.75H12.1894L6.96967 11.9696C6.67678 12.2625 6.67678 12.7374 6.96967 13.0303C7.26256 13.3232 7.73743 13.3232 8.03033 13.0303L13.25 7.81065V12C13.25 12.4142 13.5858 12.75 14 12.75C14.4142 12.75 14.75 12.4142 14.75 12V6C14.75 5.58579 14.4142 5.25 14 5.25H8ZM5 14C4.44772 14 4 14.4477 4 15C4 15.5523 4.44772 16 5 16C5.55228 16 6 15.5523 6 15C6 14.4477 5.55228 14 5 14Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
