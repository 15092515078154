import { useQueryStringParam } from './useQueryStringParam';

// NOTE: Do not rename this param. Instead use a V2 pattern.
// This param is used by the Chrome extension to allow loginWithPopup
// and is needed for auth flow to work from the extension.
export const loginWithPopupParam = 'loginWithPopup';

export function useLoginWithPopup(): boolean {
  return useQueryStringParam(loginWithPopupParam) !== null;
}
