import { ThemeOptions, alpha, outlinedInputClasses } from '@mui/material';

/**
 * View Input props and classes at https://mui.com/material-ui/api/input/#css
 *
 * View OutlinedInput props and classes at https://mui.com/material-ui/api/outlined-input/#css
 */
export const input: ThemeOptions['components'] = {
  MuiInputBase: {
    styleOverrides: {
      adornedStart: ({ theme }) => ({
        paddingLeft: theme.spacing(1.5),
      }),
      adornedEnd: ({ theme }) => ({
        paddingRight: theme.spacing(1.5),
      }),
      input: ({ theme }) => ({
        ...theme.typography.body1,
      }),
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      notched: false,
      size: 'small',
    },
    styleOverrides: {
      notchedOutline: {
        top: '0',
        '& legend': {
          display: 'none',
        },
      },
      multiline: ({ theme }) => ({
        padding: theme.spacing(0.625, 1.5),
        textarea: {
          padding: theme.spacing(0.625, 0, 0),
        },
      }),
      input: ({ theme, ownerState }) => ({
        height: 'unset',
        padding: theme.spacing(0.625, 1.5),
        ...(ownerState.startAdornment !== undefined && {
          paddingLeft: theme.spacing(0.5),
        }),
        ...(ownerState.endAdornment !== undefined && {
          paddingRight: theme.spacing(0.5),
        }),
      }),
      root: ({ theme, ownerState }) => ({
        borderRadius: '6px',
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.dark,
        ...(ownerState.error !== true && {
          '& svg': {
            color: theme.palette.text.secondary,
          },
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: alpha(theme.palette.borderState.default, 0.5),
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: theme.palette.borderState.focus,
            boxShadow: `0 0 0 4px ${theme.palette.borderState.focusShadow}`,
          },
          '&.Mui-focused svg': {
            color: theme.palette.primary.main,
          },
        }),
        ...(ownerState.error === true && {
          '& svg': {
            color: theme.palette.error.main,
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            boxShadow: `0 0 0 1px ${theme.palette.borderState.error}`,
          },
        }),
      }),
    },
  },
};
