/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { CircularProgress } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Skeleton } from '@material-ui/lab';
import { ListItem, ListItemButton, Popover, Stack } from '@mui/material';
import clsx from 'clsx';
import { first, uniqBy } from 'lodash';
import { useDebounce } from 'use-debounce';

import {
  TemplateFragment,
  TemplateType,
  useTemplateSelectTemplateByIdsQuery,
  useTemplateSelectTemplatesSearchQuery,
} from 'src/generated/mloop-graphql';

import FilterSelect, { FilterListSelectInterface } from 'src/components/FilterList/FilterSelect';
import Paper from 'src/components/Paper';
import SelectButton from 'src/components/SelectButton';
import Button from 'src/components/button';
import { DescriptionOnIcon } from 'src/components/icons';
import Label from 'src/components/label';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';
import { BaseProps, FCWithFragment } from 'src/components/types';
import { SupportedTextColor } from 'src/components/utils/color';

import { assertHexColor } from 'src/types/color';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { useDefaultTemplateForTypeQuery } from 'src/views-new/Settings/TemplateComposer/useTemplateQueries';

import { DEBOUNCE_TIMEOUT, PAGER_DEFAULT } from 'src/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    minimalSelect: {
      border: 'none',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: theme.grey.alpha.min,
        boxShadow: 'none',
      },
    },
    error: {
      border: `1px solid ${theme.palette.error.light}`,
    },
    // Need a custom margin on UL elements otherwise they display outside of popover
    templatePreview: {
      '& ul': {
        marginLeft: '20px',
      },
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
const TemplateOption = ({ template }: { template: TemplateFragment }) => {
  const [anchorOriginPlacement, setAnchorOriginPlacement] = useState<'left' | 'right'>('right');
  const classes = useStyles();
  const [showPreview, setShowPreview] = useState(false);
  const open = showPreview;

  const timer = useRef<number | null>(null);
  const anchorEl = useRef<HTMLDivElement | null>(null);

  // We want to check if there is enough room to place the popover to the right of the anchor element
  // This checks if the distance from the right edge of the window to the right edge of the anchor element is greater than 350px
  const compareSize = useCallback(() => {
    if (!anchorEl.current) return;

    const isWideEnough = window.innerWidth - anchorEl.current.getBoundingClientRect().right > 350;
    if (isWideEnough) {
      setAnchorOriginPlacement('right');
    } else {
      setAnchorOriginPlacement('left');
    }
  }, [anchorEl]);

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, [compareSize]);

  return (
    <div
      data-testid={template.id}
      aria-owns={open ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={() => {
        timer.current = window.setTimeout(() => {
          setShowPreview(true);
        }, 350);
      }}
      onMouseLeave={() => {
        if (timer.current) {
          window.clearTimeout(timer.current);
        }
        setShowPreview(false);
      }}
      style={{ width: '100%' }}
      ref={anchorEl}
    >
      <Label noWrap>{template.name || ''}</Label>

      <Popover
        disableEnforceFocus
        data-testid={`template-preview-popover-${template.id}`}
        sx={{
          pointerEvents: 'none',
          marginLeft: anchorOriginPlacement === 'left' ? '-20px' : '20px',
        }}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: anchorOriginPlacement,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: anchorOriginPlacement === 'right' ? 'left' : 'right',
        }}
        open={open}
      >
        <Paper
          style={{
            width: '400px',
          }}
        >
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <Label variant="captions" color="max-contrast-grey" fontWeight={600}>
              Template preview
            </Label>
            <Paper style={{ width: '100%' }} color="contrast">
              <Stack>
                <Label variant="captions" color="max-contrast-grey" fontWeight={600}>
                  Subject
                </Label>
                <Label fontWeight={500}>{template.subject}</Label>
                <Label variant="captions" color="max-contrast-grey" fontWeight={600}>
                  Body
                </Label>
                <Label>
                  {/* eslint-disable-next-line react/no-danger */}
                  <span className={classes.templatePreview} dangerouslySetInnerHTML={{ __html: template.body || '' }} />
                </Label>
              </Stack>
            </Paper>
          </Stack>
        </Paper>
      </Popover>
    </div>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface TemplateSelectProps extends BaseProps {
  minimalUX?: boolean;
  // When true, adds a `Use org default` option to the menu
  showOrgDefaultOption?: boolean;

  // if we only have templateId, this component will fetch it;  if we already have the template, pass it in to avoid refetch
  selectedTemplateId?: string;
  showErrorOnEmpty?: boolean;

  types?: TemplateType[];
  /** @deprecated */
  onSelect?: (value: string | undefined) => void;

  onTemplateSelect?: (template: TemplateFragment) => void;

  // If true, the template preview will be rendered
  showPreviews?: boolean;

  // If true the title will not be rendered
  hideTitle?: boolean;

  // When true, renders using the SelectButton component.
  // When false, renders using a Button or IconButton
  useSelectButton?: boolean;

  labelColor?: SupportedTextColor;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const TemplateSelect: FCWithFragment<TemplateSelectProps> = ({
  minimalUX,
  dataTestId,
  selectedTemplateId,
  types,
  onSelect,
  onTemplateSelect,
  showErrorOnEmpty,
  showPreviews = true,
  hideTitle,
  useSelectButton,
  showOrgDefaultOption,
  labelColor,
}: TemplateSelectProps) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, DEBOUNCE_TIMEOUT);

  const filterListSelectInterfaceRef = useRef<FilterListSelectInterface | null>(null);

  const { data, loading, error } = useTemplateSelectTemplatesSearchQuery({
    variables: {
      input: {
        pageInput: {
          limit: PAGER_DEFAULT,
        },
        text: debouncedSearch,
        types,
      },
    },
  });

  const [defaultTemplates] = useDefaultTemplateForTypeQuery(types);
  const defaultTemplateId = types?.length && defaultTemplates[types[0]];

  const { data: extraTemplates, loading: extraTemplatesLoading } = useTemplateSelectTemplateByIdsQuery({
    variables: {
      ids: [selectedTemplateId, defaultTemplateId].filter(Boolean),
    },
    /**
     * Skip the query till the templates are loaded & update in apollo cache
     */
    skip: (!data?.templates && !error) || loading || (!selectedTemplateId && !defaultTemplateId),
  });

  const defaultTemplate = useMemo(() => {
    return extraTemplates?.templateByIds?.find((t) => t.id === defaultTemplateId);
  }, [defaultTemplateId, extraTemplates?.templateByIds]);

  const selectedTemplate = useMemo(() => {
    return extraTemplates?.templateByIds?.find((t) => t.id === selectedTemplateId);
  }, [selectedTemplateId, extraTemplates?.templateByIds]);

  const handleChange = (event: React.ChangeEvent, values: TemplateFragment[]) => {
    if (!values.length) return;
    if (onSelect) {
      onSelect(values[0].id);
    }
    if (onTemplateSelect) {
      onTemplateSelect(values[0]);
    }
    filterListSelectInterfaceRef.current?.dismiss();
  };
  const getLabel = () => {
    let label = ``;

    if (showOrgDefaultOption && defaultTemplate?.name) {
      label = `Org default (${defaultTemplate?.name})`;
    }

    if (selectedTemplateId) {
      label = selectedTemplate?.name || '';
    }

    if (selectedTemplate) {
      label = selectedTemplate?.name || '';
    }

    if (!label) {
      label = 'Select template';
    }

    if (useSelectButton) {
      // Render a skeleton on the very first load of all templates
      if (loading && !search) {
        return (
          <Skeleton width="100%" height={hideTitle ? '32px' : '64px'} variant="rect" style={{ borderRadius: '6px' }} />
        );
      }

      return (
        <SelectButton
          shrink={minimalUX}
          title={minimalUX || hideTitle ? '' : 'Template'}
          startIcon={showErrorOnEmpty && !selectedTemplateId ? 'WarningIcon' : 'TemplateIcon'}
          startIconProps={showErrorOnEmpty && !selectedTemplateId ? { color: 'error' } : {}}
          size="medium"
          onClick={() => {}}
          tooltip={label}
          label={
            <Label noWrap color={labelColor ?? 'max-contrast-grey'} style={{ width: '100%' }}>
              {label}
            </Label>
          }
          className={clsx(
            minimalUX ? classes.minimalSelect : '',
            showErrorOnEmpty && !selectedTemplateId ? classes.error : ''
          )}
        />
      );
    }

    return (
      <Button
        dataTestId={dataTestId}
        variant="contained"
        color="default"
        size="small"
        startIcon={<DescriptionOnIcon color={assertHexColor('#C90741')} />}
        label={
          label && !extraTemplatesLoading ? (
            <Label variant="captions" fontWeight={500} color="max-contrast-grey" noWrap>
              {label}
            </Label>
          ) : (
            <CircularProgress size={16} />
          )
        }
        tooltip={label}
      />
    );
  };

  const options = useMemo(() => {
    const items = data?.templates?.items?.length ? [...data?.templates?.items] : [];

    if (selectedTemplate) {
      items.unshift(selectedTemplate);
    }

    if (selectedTemplate) {
      items.unshift(selectedTemplate);
    }

    if (showOrgDefaultOption && defaultTemplateId) {
      items.unshift({
        id: null,
        name: 'Use org default',
        __typename: 'Template',
        subject: defaultTemplate?.subject || '',
        body: defaultTemplate?.body || '',
        isOrganizationDefault: false,
        updatedAt: new Date().toISOString(),
        type: first(types) || TemplateType.Other,
        createdAt: new Date().toISOString(),
      });
    }

    return uniqBy(items, (item) => item.id);
  }, [
    data?.templates?.items,
    defaultTemplate?.body,
    defaultTemplate?.subject,
    defaultTemplateId,
    selectedTemplate,
    showOrgDefaultOption,
    types,
  ]);

  return (
    <FilterSelect
      filterListSelectInterfaceRef={filterListSelectInterfaceRef}
      options={options}
      loading={loading}
      errorMsg={error?.message}
      placeholderText="Search event templates"
      getLabel={getLabel}
      getOptionLabel={(option: TemplateFragment): string => {
        return option.name || '';
      }}
      renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: TemplateFragment) => {
        let jsx: JSX.Element;
        if (showPreviews) {
          jsx = <TemplateOption template={option} />;
        } else {
          jsx = (
            <div data-testid={option.id} style={{ width: '100%' }}>
              <MaybeTooltip label={option.name || ''} tooltip={option.name || ''} />
            </div>
          );
        }

        return (
          <ListItem {...props}>
            <ListItemButton>{jsx}</ListItemButton>
          </ListItem>
        );
      }}
      onChange={handleChange}
      onInputChange={(event, value: string) => setSearch(value)}
    />
  );
};

TemplateSelect.fragment = {
  template: gql`
    fragment Template on Template {
      id
      name
      subject
      body
      isOrganizationDefault
      updatedAt
      attachments {
        name
        path
        size
      }
      type
      createdAt
    }
  `,
};

export const TemplateById = gql`
  ${TemplateSelect.fragment.template}
  query TemplateSelectTemplateById($id: uuid!) {
    template(id: $id) {
      ...Template
    }
  }
`;

export const TemplateByIds = gql`
  ${TemplateSelect.fragment.template}
  query TemplateSelectTemplateByIds($ids: [uuid!]!) {
    templateByIds(ids: $ids) {
      ...Template
    }
  }
`;

export const TemplatesSearchQuery = gql`
  ${TemplateSelect.fragment.template}
  query TemplateSelectTemplatesSearch($input: TemplatesInput!) {
    templates(input: $input) {
      items {
        ...Template
      }
    }
  }
`;

export default TemplateSelect;
