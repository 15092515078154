import useAuth from './useAuth';

export default function useEmployeeId() {
  const { userInfo } = useAuth();
  return userInfo?.employeeId;
}

export function useEmployeeEmail() {
  const { userInfo } = useAuth();
  return userInfo?.employeeEmail;
}

export function useUserInfo() {
  const { userInfo } = useAuth();
  return userInfo;
}
