import { ThemeOptions } from '@mui/material';

/**
 * View Link props and classes at https://mui.com/material-ui/api/link
 */
export const link: ThemeOptions['components'] = {
  MuiLink: {
    defaultProps: {
      underline: 'none',
    },
    styleOverrides: {
      root: {
        '&:hover': { cursor: 'pointer' },
      },
    },
  },
};
