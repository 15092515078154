import React, { FC } from 'react';
import { Redirect } from 'react-router';

import useOnboardingRequirements from 'src/hooks/onboarding/useOnboardingRequirements';

/**
 * Guard that checks if the user has completed the onboarding process.
 * Note: Guards should check only one thing. Don't add any other checks to this Guard.
 *
 * TODO: (Cam) - ENG-14997 - This guard should split into multiple distinct onboarding requirements guards
 */
const RPUserHasFullyOnboardGuard: FC = ({ children }) => {
  const { isOnboardingRequired: isOnboardingRedirect, onboardingRedirectParams } = useOnboardingRequirements();

  if (isOnboardingRedirect) {
    return <Redirect to={onboardingRedirectParams} />;
  }

  // Don't add components here, please add components either on the app or on the appropriate Layout component
  return <>{children}</>;
};

export default RPUserHasFullyOnboardGuard;
