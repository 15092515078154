import React, { FC } from 'react';

import { gql } from '@apollo/client';
import { ScheduleIcon } from '@modernloop/shared/icons';
import { Alert, Divider, Stack, Typography } from '@mui/material';

import { useGetWarningModalCalendarQuery } from 'src/generated/mloop-graphql';

import DoNotShowAgainDialog from 'src/components/Dialog/DoNotShowAgainDialog';

import AtsName from 'src/entities/Ats/AtsName';

import useDirectoryInfo from 'src/hooks/useDirectoryInfo';

import { getCandidateCalendarId, getInterviewerCalendarId } from 'src/store/selectors/scheduling';

import { DoNotShowAgainDialogTypes } from 'src/utils/api/employee/constants';

import { useSelector } from 'src/store';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
};

const ChangingCalenderWarningDialog: FC<Props> = ({ onClose, onSubmit }) => {
  const candidateCalendarId = useSelector(getCandidateCalendarId);

  const { calendarName } = useDirectoryInfo();

  const interviewerCalendarId = useSelector(getInterviewerCalendarId);

  const { data: interviewerCalenderResponse } = useGetWarningModalCalendarQuery({
    variables: {
      id: interviewerCalendarId || '',
    },
  });

  const { data: candidateCalenderResponse } = useGetWarningModalCalendarQuery({
    variables: {
      id: candidateCalendarId || '',
    },
  });

  return (
    <DoNotShowAgainDialog
      doNotShowAgainKey={DoNotShowAgainDialogTypes.EDITING_CALENDER_WARNING}
      decoration={
        <img
          style={{ width: '64px', height: '64px' }}
          src="/static/images/scheduling/warning_64.png"
          alt="Changing calender warning"
        />
      }
      submitOptions={{
        label: 'Confirm',
        onClick: onSubmit,
      }}
      cancelOptions={{
        label: 'Go back',
        onClick: onClose,
      }}
      title="Confirm your calendars"
      isOpen
      onClose={onClose}
    >
      <Stack pb={2.5} spacing={2.5}>
        <Alert severity="warning" variant="filled">
          <Typography>
            Using {calendarName} to change the calendar on which an event is booked will result in the event being
            automatically removed from ModernLoop and <AtsName />.
          </Typography>
        </Alert>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack alignItems="center" direction="row">
            <ScheduleIcon />
            <Typography ml={1}>Candidate Events</Typography>
          </Stack>
          <Typography>{candidateCalenderResponse?.calendar?.name || 'Unknown'}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack alignItems="center" direction="row">
            <ScheduleIcon />
            <Typography ml={1}>Interviewer Events</Typography>
          </Stack>
          <Typography>{interviewerCalenderResponse?.calendar?.name || 'Unknown'}</Typography>
        </Stack>
        <Divider />
      </Stack>
    </DoNotShowAgainDialog>
  );
};

export default ChangingCalenderWarningDialog;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetWarningModalCalendar($id: String!) {
    calendar(id: $id) {
      id
      name
    }
  }
`;
