import React from 'react';

import { AtsType } from 'src/generated/mloop-graphql';

import { getAtsService, useOrgAtsService } from 'src/hooks/atsService';

type Props = {
  atsType?: AtsType;
};

const AtsScorecardName = ({ atsType }: Props) => {
  const currentAtsService = useOrgAtsService();

  // If an ATS Type is provided, use that
  if (atsType) {
    return <>{getAtsService(atsType).scorecardLabelPlural}</>;
  }
  // If org is connected to an ATS, use that
  if (currentAtsService) {
    return <>{currentAtsService.scorecardLabelPlural}</>;
  }
  return <>scorecards</>;
};

export default AtsScorecardName;
