import { ApolloQueryResult } from '@apollo/client';
import { uniqBy } from 'lodash';

import {
  MeetingRoomSuggestionsInput,
  MeetingRoomSuggestionsQuery,
  useMeetingRoomSuggestionsLazyQuery,
} from 'src/generated/mloop-graphql';

import IsoTimestamp from 'src/types/IsoTimestamp';

import { PAGER_DEFAULT } from 'src/constants';

type MeetingRoomSuggestionsLazyQuery = ReturnType<typeof useMeetingRoomSuggestionsLazyQuery>;
type FetchMoreType = MeetingRoomSuggestionsLazyQuery['1']['fetchMore'];
type UpdateQueryType = MeetingRoomSuggestionsLazyQuery['1']['updateQuery'];
type MeetingRoomSuggestionsType = MeetingRoomSuggestionsQuery['meetingRoomSuggestions'];

const fetchMoreMeetingRooms = async (
  fetchMore: FetchMoreType,
  updateQuery: UpdateQueryType,
  meetingRoomSuggestions: MeetingRoomSuggestionsType,
  search: string,
  startAt: IsoTimestamp,
  endAt: IsoTimestamp
  // eslint-disable-next-line max-params
) => {
  if (!fetchMore || !updateQuery || !meetingRoomSuggestions?.nextCursor) {
    return;
  }

  await fetchMore({
    variables: {
      input: {
        pageInput: {
          cursor: meetingRoomSuggestions.nextCursor,
          limit: PAGER_DEFAULT,
        },
        search,
        timeRange: { startAt, endAt },
      } as MeetingRoomSuggestionsInput,
    },
    context: {
      batch: false,
    },
  }).then((result: ApolloQueryResult<MeetingRoomSuggestionsQuery>) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/always-return
    if (!updateQuery) return;

    updateQuery((prevResult: MeetingRoomSuggestionsQuery) => {
      const { meetingRoomSuggestions: prevMeetingRoomSuggestions } = prevResult;

      return {
        meetingRoomSuggestions: {
          ...meetingRoomSuggestions,
          items: uniqBy(
            [...(prevMeetingRoomSuggestions?.items || []), ...(result.data.meetingRoomSuggestions?.items || [])],
            'room.id'
          ),
          nextCursor: result.data.meetingRoomSuggestions?.nextCursor,
        },
      };
    });
  });
};

export default fetchMoreMeetingRooms;
