import React from 'react';

import { gql } from '@apollo/client';
import { ExpandableTextView, FCWithFragments } from '@modernloop/shared/components';

import { ActivityLogApplicationCandidatePortalNoteCreated_ActivityLogFragment } from 'src/generated/mloop-graphql';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import ActivityLogContent from 'src/entities/ActivityLog/Helpers/ActivityLogContent';

type Fragments = {
  activityLog: ActivityLogApplicationCandidatePortalNoteCreated_ActivityLogFragment;
};

const ActivityLogApplicationCandidatePortalNoteCreated: FCWithFragments<Fragments> = ({ activityLog }) => {
  // eslint-disable-next-line no-underscore-dangle
  if (!activityLog.details || activityLog.details.__typename !== 'ActivityLogCandidatePortalNoteDetails') {
    throw new Error('ActivityLogCandidatePortalNoteDetails: details is not ActivityLogCandidatePortalNoteDetails');
  }

  return (
    <ActivityLogBase activityLog={activityLog} actionLabel="created a candidate portal note">
      <ActivityLogContent
        sx={{ borderRadius: '4px 12px 12px 12px' }}
        content={<ExpandableTextView content={activityLog.details.content || ''} hideGradient />}
      />
    </ActivityLogBase>
  );
};

ActivityLogApplicationCandidatePortalNoteCreated.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogApplicationCandidatePortalNoteCreated_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogCandidatePortalNoteDetails {
          candidatePortalNoteId
          content
          sendToCandidate
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogApplicationCandidatePortalNoteCreated;
