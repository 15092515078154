import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import BaseIcon, { Props } from '../../Icon';

const CandidateRsvpMaybe = ({ color, fontSize, tooltip, tiny }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} tiny={tiny}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2226 12.4818C12.5549 12.7033 13 12.4651 13 12.0657V3C13 1.89543 12.1046 1 11 1H3C1.89543 1 1 1.89543 1 3V9C1 10.1046 1.89543 11 3 11H9.09167C9.68395 11 10.263 11.1753 10.7558 11.5038L12.2226 12.4818ZM6.93999 3.75C6.6899 3.75 6.49978 3.87023 6.38746 4.05142C6.16921 4.40347 5.70689 4.51194 5.35484 4.29369C5.00278 4.07544 4.89432 3.61312 5.11257 3.26107C5.48665 2.65765 6.14881 2.25 6.93999 2.25C8.18959 2.25 9.00248 3.29161 9.00248 4.31249C9.00248 4.77829 8.88125 5.15993 8.65352 5.47242C8.45223 5.74861 8.19461 5.92668 8.03916 6.03413L8.02316 6.04519C7.8384 6.17311 7.77976 6.2198 7.7415 6.26998L7.74095 6.27071C7.72678 6.28926 7.68999 6.33744 7.68999 6.5C7.68999 6.91421 7.3542 7.25 6.93999 7.25C6.52577 7.25 6.18999 6.91421 6.18999 6.5C6.18999 6.0402 6.31626 5.66535 6.54863 5.36056C6.74337 5.10512 6.99223 4.93384 7.14076 4.83162L7.16931 4.81193C7.34415 4.69088 7.40355 4.64074 7.44129 4.58896C7.45964 4.56378 7.50248 4.50098 7.50248 4.31249C7.50248 4.02088 7.26537 3.75 6.93999 3.75ZM7.75001 8.75C7.75001 9.16421 7.41423 9.5 7.00001 9.5C6.5858 9.5 6.25001 9.16421 6.25001 8.75C6.25001 8.33579 6.5858 8 7.00001 8C7.41423 8 7.75001 8.33579 7.75001 8.75Z"
        fill="#787882"
      />
    </BaseIcon>
  );
};

export default CandidateRsvpMaybe;
