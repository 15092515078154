/* eslint-disable react/no-multi-comp */

/* eslint-disable react/destructuring-assignment */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import logError from 'src/utils/logError';
import logMessage from 'src/utils/logMessage';

import LoadingScreen from '../screenLoaders/LoadingScreen';

const REGEX_JS_CHUNK = /Loading chunk [\d]+ failed/;
const REGEX_CSS_CHUNK = /Loading CSS chunk [\d]+ failed/;
const REGEX_JS_DYNAMIC_MODULE = /Failed to fetch dynamically imported module/;
const REGEX_JS_MODULE = /Failed to load module script/;
const REGEX_JS_TYPE_ERROR = /'text\/html' is not a valid JavaScript MIME type./;

const isChunkLoadFailure = (error: Error) => {
  return (
    REGEX_JS_CHUNK.test(error.message) ||
    REGEX_CSS_CHUNK.test(error.message) ||
    REGEX_JS_MODULE.test(error.message) ||
    REGEX_JS_DYNAMIC_MODULE.test(error.message) ||
    REGEX_JS_TYPE_ERROR.test(error.message)
  );
};

const SomethingWentWrongMessage: FC = () => (
  <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
    <Grid item>
      <Typography gutterBottom align="center" variant="h1">
        <span aria-label="sad" role="img">
          😢
        </span>
      </Typography>
      <Typography gutterBottom align="center" variant="h3">
        Something went wrong.
      </Typography>
      <Typography gutterBottom align="center" variant="h5">
        We&apos;ve already been notified and will reach out if we have any questions.
      </Typography>
    </Grid>
  </Grid>
);

interface Props {
  children: React.ReactNode;
}
interface State {
  hasError: boolean;
  showError: boolean;
}
// TODO: Fix this the next time the file is edited.
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, showError: false };
  }

  /* eslint-disable react/destructuring-assignment */
  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, showError: !isChunkLoadFailure(error) };
  }

  componentDidCatch(error): void {
    if (isChunkLoadFailure(error)) {
      logMessage('Chunk load error. App reloaded.', 'info', { error });
      if (REGEX_JS_TYPE_ERROR.test(error.message)) {
        logMessage('JS returned HTML error. App reloaded.', 'info', { error });
      }
      window.location.reload();
    } else {
      logError(error);
      logMessage('"Something went wrong" toast displayed', 'fatal', { error });
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return this.state.showError ? <SomethingWentWrongMessage /> : <LoadingScreen />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
