import { cloneDeep } from 'lodash';

import { InterviewerRole } from 'src/generated/mloop-graphql';

import { ScheduleUpdate, ScheduleUpdateType } from 'src/store/slices/schedule-update';

import { InterviewSchedule, RichInterviewer, UIFilledInterviewSeat } from 'src/utils/api/getScheduleOptions';

export const applyUpdatesToSchedule = (schedule: InterviewSchedule, updates: ScheduleUpdate[]): InterviewSchedule => {
  const result: InterviewSchedule = cloneDeep(schedule);

  if (!result) return result;

  const { events } = result;
  if (!events || !updates || updates.length === 0) return result;

  updates.forEach((update) => {
    const eventIndex = events.findIndex((event) => event.id === update.applicationStageInterviewId);

    if (
      eventIndex === -1 &&
      // Below condition handles the case when a new interview is added
      update.type !== 'ScheduleUpdateNewInterview'
    ) {
      return;
    }

    switch (update.type) {
      case 'SchdeuleUpdateInterviewName': {
        events[eventIndex].name = update.name;
        break;
      }
      case 'SchdeuleUpdateInterviewTime': {
        events[eventIndex].startAt = update.startAt;
        events[eventIndex].endAt = update.endAt;
        events[eventIndex].duration = update.duration;
        break;
      }
      case 'ScheduleUpdateScorecard': {
        events[eventIndex].atsInterviewDefinitionId = update.atsInterviewDefinitionId;
        events[eventIndex].atsJobId = update.atsJobId;
        events[eventIndex].atsJobStageId = update.atsJobStageId;
        break;
      }
      case 'ScheduleUpdateInterviewSeat': {
        const newInterviewers: RichInterviewer[] = [];
        const newFilledInterviewSeats: UIFilledInterviewSeat[] = [];

        // Go through every interview seat (primary + trainee)
        const { filledInterviewSeats } = events[eventIndex];
        if (filledInterviewSeats) {
          filledInterviewSeats.forEach((prevFilledInterviewSeat) => {
            const prevInterviewer = events[eventIndex].interviewers.find((i) => {
              return i.employee.id === prevFilledInterviewSeat.interviewerId;
            });
            const prevTrainee = events[eventIndex].interviewers.find(
              (i) => i.employee.id === prevFilledInterviewSeat.traineeId
            );

            // Delete interviewer and associated seat
            if (
              update.updateType === ScheduleUpdateType.DELETE &&
              update.interviewer.employee.id === prevFilledInterviewSeat.interviewerId
            ) {
              // don't add interviewer, trainee, or seat.
              // trainee can't go without a primary interviewer so its ok to remove them too
              return;
            }

            // Delete trainee and update associated seat
            if (
              update.updateType === ScheduleUpdateType.DELETE &&
              update.interviewer.employee.id === prevFilledInterviewSeat.traineeId
            ) {
              // Don't add the trainee to `newInterviewers` and reset trainee id and role on seat
              if (prevInterviewer) {
                newInterviewers.push(prevInterviewer);
              }
              newFilledInterviewSeats.push({
                ...prevFilledInterviewSeat,
                traineeId: undefined,
                traineeRole: undefined,
              });
              return;
            }

            // edit the previous interviewer
            if (
              update.updateType === ScheduleUpdateType.EDIT &&
              update.prevInterviewerId === prevFilledInterviewSeat.interviewerId
            ) {
              newInterviewers.push(update.interviewer);
              if (prevTrainee) {
                newInterviewers.push(prevTrainee);
              }
              newFilledInterviewSeats.push({
                ...prevFilledInterviewSeat,
                interviewerId: update.interviewer.employee.id,
                seat: {
                  freeformSeat: update.seat?.freeformSeat || undefined,
                  moduleSeat: update.seat?.moduleSeat || undefined,
                  linkedSeat: update.seat?.linkedSeat || undefined,
                },
              });
              return;
            }

            // edit the previous trainee
            if (
              update.updateType === ScheduleUpdateType.EDIT &&
              update.prevInterviewerId === prevFilledInterviewSeat.traineeId
            ) {
              if (prevInterviewer) {
                newInterviewers.push(prevInterviewer);
              }
              newInterviewers.push(update.interviewer); // trainee
              newFilledInterviewSeats.push({
                ...prevFilledInterviewSeat,
                seat: {
                  freeformSeat: update.seat?.freeformSeat || undefined,
                  moduleSeat: update.seat?.moduleSeat || undefined,
                  linkedSeat: update.seat?.linkedSeat || undefined,
                },
                traineeId: update.interviewer.employee.id,
                traineeRole: update.interviewer.role,
              });
              return;
            }

            // add new trainee
            if (
              update.updateType === ScheduleUpdateType.NEW &&
              update.interviewer.role?.toUpperCase() !== InterviewerRole.Interviewer &&
              !update.prevInterviewerId &&
              update.seat?.moduleSeat &&
              update.seat?.moduleSeat.id === prevFilledInterviewSeat.seat.moduleSeat?.id &&
              update.seat.moduleSeat.interviewId === prevFilledInterviewSeat.seat.moduleSeat?.interviewId &&
              prevInterviewer?.employeeId === prevFilledInterviewSeat.interviewerId
            ) {
              if (prevInterviewer) {
                newInterviewers.push(prevInterviewer); // trained interviewer
              }
              newInterviewers.push(update.interviewer); // trainee

              newFilledInterviewSeats.push({
                ...prevFilledInterviewSeat,
                traineeId: update.interviewer.employeeId,
                traineeRole: update.interviewer.role,
              });
              return;
            }

            // add the unchanged interviewer, trainee, & filled seat
            if (prevTrainee) {
              newInterviewers.push(prevTrainee);
            }
            if (prevInterviewer) {
              newInterviewers.push(prevInterviewer);
            }
            newFilledInterviewSeats.push(prevFilledInterviewSeat);
          });
        }
        // add brand new interviewer, make new freeform seat for them to sit in.
        if (
          update.updateType === ScheduleUpdateType.NEW &&
          update.interviewer.role?.toUpperCase() === InterviewerRole.Interviewer
        ) {
          newInterviewers.push(update.interviewer);
          newFilledInterviewSeats.push({
            interviewerId: update.interviewer.employee.id,
            seat: {
              freeformSeat: update.seat?.freeformSeat || undefined,
              moduleSeat: update.seat?.moduleSeat || undefined,
              linkedSeat: update.seat?.linkedSeat || undefined,
            },
          });
        }

        // We are cloning the `newInterviewers` object because we were getting an error while adding the `isOptional` property to this object.
        events[eventIndex].interviewers = cloneDeep(newInterviewers);
        events[eventIndex].filledInterviewSeats = newFilledInterviewSeats.map((filledSeat): UIFilledInterviewSeat => {
          if (filledSeat.seat.freeformSeat) {
            return { ...filledSeat, seat: { freeformSeat: { ...filledSeat.seat.freeformSeat } } };
          }
          if (filledSeat.seat.linkedSeat) {
            return { ...filledSeat, seat: { linkedSeat: { ...filledSeat.seat.linkedSeat } } };
          }
          if (filledSeat.seat.moduleSeat) {
            return { ...filledSeat, seat: { moduleSeat: { ...filledSeat.seat.moduleSeat } } };
          }
          throw Error('Filled seat should have atleaset one of freeform, linked or module seat');
        });

        break;
      }
      case 'ScheduleUpdateNewInterview': {
        events.push({
          id: update.applicationStageInterviewId,
          slotId: update.applicationStageInterviewId,
          name: update.name,
          startAt: update.startAt,
          endAt: update.endAt,
          duration: update.duration,
          interviewers: [],
          filledInterviewSeats: [],

          atsInterviewDefinitionId: update.atsInterviewDefinitionId,
          atsJobId: update.atsJobId,
          atsJobStageId: update.atsJobStageId,
        });
        break;
      }
      case 'ScheduleUpdateDeleteInterview': {
        events.splice(eventIndex, 1);
        break;
      }
      case 'ScheduleUpdateInterviewerOptional': {
        const applicationStageInterviewEvent = events.find((event) => {
          return event.id === update.applicationStageInterviewId;
        });

        if (applicationStageInterviewEvent) {
          const interviewer = applicationStageInterviewEvent.interviewers.find(
            (i) => i.employeeId === update.applicationStageInterviewerId
          );
          if (interviewer) {
            interviewer.isOptional = update.isOptional;
          }
        }

        break;
      }
      case 'ScheduleUpdateInterviewHiddenFromCandidate': {
        const applicationStageInterviewEvent = events.find((event) => {
          return event.id === update.applicationStageInterviewId;
        });

        if (applicationStageInterviewEvent) {
          applicationStageInterviewEvent.isHiddenFromCandidate = update.isHiddenFromCandidate;
        }

        break;
      }

      default:
    }
  });

  return result;
};
