import React from 'react';

import { useGreenhouseIntegration } from 'src/hooks/api/integration';

import BaseGreenhouseAshbyPermissionDialog from './BaseGreenhouseAshbyPermissionDialog';

type Props = {
  jobId: string;
  onHasPermission: () => void;
  onClose: () => void;
};

const GreenhouseCreateCandidatePermissionDialog = ({ jobId, onHasPermission, onClose }: Props) => {
  const { data, isLoading } = useGreenhouseIntegration();

  return (
    <BaseGreenhouseAshbyPermissionDialog
      jobId={jobId}
      onHasPermission={onHasPermission}
      onClose={onClose}
      notAnAdminHelpText="Not a Greenhouse admin? Please ask your admin who can manage all API credentials to enable the required permissions"
      atsPermissionGrantUrl={`https://${data?.subdomain}.greenhouse.io/configure/dev_center/credentials`}
      permissionsListText="Post: Add Candidate"
      isLoading={isLoading}
    />
  );
};

export default GreenhouseCreateCandidatePermissionDialog;
