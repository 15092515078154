/**
 * Api Request handler for backend service.
 */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import LogRocket from 'logrocket';

import axiosInterceptorRequestAddJWT from 'src/utils/axios/axiosInterceptorRequestAddJWT';
import axiosInterceptorResponseError from 'src/utils/axios/axiosInterceptorResponseError';
import axiosRetry from 'src/utils/axios/axiosRetry';
import axioxInterceptorMlTraceId from 'src/utils/axios/axioxInterceptorMlTraceId';

import { backendConfig, enviromentConfig } from 'src/config';

import ApiVerb from './ApiVerb';
import { MLoopBackendUrlNamespace } from './types';

export { default as ApiVerb } from './ApiVerb';

const getLocalStorageBackendUrl = () => {
  return localStorage.getItem(MLoopBackendUrlNamespace);
};

// Try to get it from local store (set from Dev Tools), fallback to query param
const getBackendUrl = () => {
  if (enviromentConfig.environment === 'production') return undefined;
  return getLocalStorageBackendUrl() ?? new URLSearchParams(window.location.search).get('backendUrl');
};

export const API_URL = getBackendUrl() ?? backendConfig.url;

const api: AxiosInstance = axios.create({});
LogRocket.getSessionURL((sessionUrl) => {
  api.defaults.headers.common['x-user-session-url'] = sessionUrl;
});
axiosRetry(api, 3);
api.interceptors.request.use(axiosInterceptorRequestAddJWT);
api.interceptors.request.use(axioxInterceptorMlTraceId);
api.interceptors.response.use((successRes) => successRes, axiosInterceptorResponseError('client_axios_java_error'));

function genApiPath(verb: ApiVerb, host?: string): string {
  if (host) {
    return `${host}${verb}`;
  }

  return `${API_URL}${verb}`;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function apiGet(verb: ApiVerb, config?: AxiosRequestConfig, host?: string): Promise<AxiosResponse> {
  return api.get(genApiPath(verb, host), config);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, max-params
export function apiPost<TRequest = any, TResponse = any>(
  verb: ApiVerb,
  data?: TRequest,
  config?: AxiosRequestConfig,
  host?: string
): Promise<AxiosResponse<TResponse>> {
  return api.post<TResponse>(genApiPath(verb, host), data, config);
}
