/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FCWithFragments } from '@modernloop/shared/components';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { ErrorIcon, WarningIcon } from '@modernloop/shared/icons';
import { Stack, StackProps } from '@mui/material';
import { isBefore, parseISO } from 'date-fns';

import {
  CandidateAvailabilityStatus_ApplicationFragment,
  CandidateAvailabilityStatus_AvailabilityRequestFragment,
  Maybe,
  useEmployeeByIdsLazyQuery,
} from 'src/generated/mloop-graphql';

import RequestAvailabilityPng from 'src/assets/images/request_availability_64.png';
import RequestSelfSchedulePng from 'src/assets/images/request_self_schedule.png';

import useDurationLabel from 'src/components/DurationLabel/useDurationLabel';
import { CanDisable } from 'src/components/HelperComponents';
import Link from 'src/components/Link';
import MoreMenuButton from 'src/components/MoreMenuButton';
import Paper, { PaperProps } from 'src/components/Paper';
import ZeroState from 'src/components/ZeroState';
import Button from 'src/components/button';
import { AvailabilityIcon, LockIcon, SendIcon, SuccessIcon } from 'src/components/icons';
import Label from 'src/components/label';

import useIsApplicationRejected from 'src/hooks/useIsApplicationRejected';

import { CandidateAvailability } from 'src/store/slices/candidate-availability';
import { CandidateAvailabilityOptions } from 'src/store/slices/candidate-availability-options';

import { Theme } from 'src/theme/type';

import { Theme as ThemeV5 } from 'src/themeMui5/type';

import IsoTimestamp, { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import SelfScheduleDisableAlert from 'src/views-new/SelfSchedule/SelfScheduleDisableAlert';

import RecjectetCandidateAvailabilitySectionAlert from './RecjectetCandidateAvailabilityAlert';
import { isAvailabilitySufficient } from './utils';

enum CandidateAvailabilityStatusActions {
  CancelAvailabilityRequest = 'cancel-availability-request',
  EditAvailabilityRequest = 'request-availability-request',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
    },
    action: {
      alignSelf: 'center',
      flexShrink: 0,
    },
    interviewScheduled: {
      paddingBottom: theme.spacing(1),
    },
  })
);

const useSxProps = () => {
  return useMemo(() => {
    return {
      root: {
        borderRadius: (theme: ThemeV5) => theme.spacing(1.5),
        padding: (theme: ThemeV5) =>
          `${theme.spacing(2.5)} ${theme.spacing(1.5)} ${theme.spacing(2.5)} ${theme.spacing(2)}`,
      },
    };
  }, []);
};

type Fragments = {
  application: CandidateAvailabilityStatus_ApplicationFragment;
  availabilityRequest?: Maybe<CandidateAvailabilityStatus_AvailabilityRequestFragment>;
};

type Props = {
  taskId?: string;
  jobStageName: string;
  options?: CandidateAvailabilityOptions;
  availabilities?: CandidateAvailability;
  selfScheduleOptionsId?: string;
  lastApplicationStageCreatedAt?: IsoTimestamp;
  onRequestAvailability: () => void;
  onReShareAvailabilityRequest: () => void;
  onDeleteAvailabilityRequest: () => void;
  availabilityRequestActionsJSX: JSX.Element | null;
};

const CandidateAvailabilityStatus: FCWithFragments<Fragments, Props> = ({
  taskId,
  jobStageName,
  options,
  availabilities,
  selfScheduleOptionsId,
  lastApplicationStageCreatedAt,
  onRequestAvailability,
  onReShareAvailabilityRequest,
  onDeleteAvailabilityRequest,
  availabilityRequestActionsJSX,
  application,
  availabilityRequest,
}) => {
  const classes = useStyles();
  const sxProps = useSxProps();
  const [showSelfScheduleDeleteAlert, setShowSelfScheduleDeleteAlert] = useState(false);
  const [fetchEmployeeById, { data }] = useEmployeeByIdsLazyQuery();
  const durationLabel = useDurationLabel(
    availabilities?.updatedAt ? assertIsoTimestamp(availabilities.updatedAt) : undefined
  );
  const isApplicationRejected = useIsApplicationRejected(application.status);

  useEffect(() => {
    if (!options?.employeeId) return;
    fetchEmployeeById({ variables: { input: [options.employeeId] } });
  }, [options, fetchEmployeeById]);

  let paperColor: PaperProps['color'];
  let icon = <></>;
  let content = <></>;
  let action = <></>;
  let alignItems: StackProps['alignItems'];
  let selfScheduleMessage = <></>;
  let insufficientAvailabilityMessage = <></>;

  if (selfScheduleOptionsId) {
    if (
      taskId &&
      !availabilities?.rcEnteredAvailability.length &&
      !availabilities?.candidateEnteredAvailability.length
    ) {
      return (
        <ZeroState
          icon={<img src={RequestSelfSchedulePng} alt="Self-schedule is on" width="64px" height="64px" />}
          label={
            <Stack alignItems="center">
              <Label fontWeight={600}>Self-schedule is on</Label>
              <Label color="high-contrast-grey">
                To add or request availability, please close the self-schedule request.
              </Label>
            </Stack>
          }
        />
      );
    }

    selfScheduleMessage = (
      <Stack direction="row" alignItems="center" spacing={1} sx={{ paddingBottom: (theme) => theme.spacing(1) }}>
        <LockIcon />
        <Label color="max-contrast-grey" variant="captions">
          To enable availability requests, please close your self-schedule request{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link color="info" onClick={() => setShowSelfScheduleDeleteAlert(true)} variant="captions">
            Close request
          </Link>
        </Label>
      </Stack>
    );
  }

  if (
    taskId &&
    !options?.externalId &&
    !availabilities?.rcEnteredAvailability.length &&
    !availabilities?.candidateEnteredAvailability.length
  ) {
    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <img src={RequestAvailabilityPng} alt="Request availability" width="64px" height="64px" />
        <Stack spacing="0.5">
          <Label fontWeight={600}>Request availability for this schedule</Label>
          <Label color="high-contrast-grey">Let the candidate add their own availability.</Label>
        </Stack>
      </Stack>
    );
  }

  const hasCandidateSubmittedAvailabilityAfterRequestCreated =
    availabilities?.candidateUpdatedAt &&
    options?.createdAt &&
    parseISO(availabilities.candidateUpdatedAt).getTime() > parseISO(options.createdAt).getTime();

  const isInterviewScheduledAfterRequestCreated =
    lastApplicationStageCreatedAt &&
    options?.createdAt &&
    parseISO(lastApplicationStageCreatedAt).getTime() > parseISO(options.createdAt).getTime();

  if (
    !options?.externalId &&
    (availabilities?.rcEnteredAvailability.length || isInterviewScheduledAfterRequestCreated || selfScheduleOptionsId)
  ) {
    paperColor = 'contrast';
    icon = <AvailabilityIcon />;
    content = (
      <Label color="max-contrast-grey" className={classes.content} fontWeight={600}>
        Request more availability for {jobStageName}
      </Label>
    );
    action = (
      <Button
        variant="outlined"
        dataTestId="candidate-details-candidate-availability-request"
        label="Request"
        className={classes.action}
        onClick={onRequestAvailability}
      />
    );
    alignItems = 'center';
  } else if (options?.externalId && hasCandidateSubmittedAvailabilityAfterRequestCreated) {
    paperColor = 'contrast';
    icon = <SuccessIcon color="success" />;
    content = (
      <Stack className={classes.content}>
        <Label color="success" fontWeight={600}>
          Availability received
        </Label>

        <Label color="high-contrast-grey" variant="captions">
          This request is still open. The candidate may update or remove their availability at any time.
        </Label>
        {availabilityRequestActionsJSX}
      </Stack>
    );

    action = (
      <Stack direction="row" alignItems="center" className={classes.action} spacing={1}>
        <Button variant="outlined" color="info" label="Re-share link" onClick={onReShareAvailabilityRequest} />
        <MoreMenuButton
          options={[
            {
              id: CandidateAvailabilityStatusActions.EditAvailabilityRequest,
              value: 'Edit request',
            },
            {
              id: CandidateAvailabilityStatusActions.CancelAvailabilityRequest,
              value: 'Close request',
              color: 'error',
            },
          ]}
          onSelect={(option) => {
            switch (option.id) {
              case CandidateAvailabilityStatusActions.CancelAvailabilityRequest: {
                onDeleteAvailabilityRequest();
                break;
              }
              case CandidateAvailabilityStatusActions.EditAvailabilityRequest: {
                onRequestAvailability();
                break;
              }
              default:
            }
          }}
        />
      </Stack>
    );

    const hasRCUpdatedAvailabilityAfterCandidate =
      availabilities?.candidateUpdatedAt &&
      availabilities.employeeUpdatedAt &&
      parseISO(availabilities.candidateUpdatedAt).getTime() < parseISO(availabilities.employeeUpdatedAt).getTime();

    const sufficient = isAvailabilitySufficient(
      options,
      availabilities.candidateEnteredAvailability,
      availabilities.candidateTimezone || getLocalTimezone(),
      availabilities.updatedAt
    );

    if (!hasRCUpdatedAvailabilityAfterCandidate && !sufficient) {
      paperColor = 'warning';

      insufficientAvailabilityMessage = (
        <Stack direction="row" spacing={1} sx={{ paddingBottom: (theme) => theme.spacing(1) }}>
          <WarningIcon color="warning" />
          <Label color="max-contrast-grey" variant="captions">
            Availability received is less than requested. Edit or re-share link if more availability for {jobStageName}{' '}
            is needed.
          </Label>
        </Stack>
      );
    }
  } else if (options?.externalId) {
    if (isApplicationRejected) {
      paperColor = 'error';
      icon = <ErrorIcon color="error" />;
      content = (
        <RecjectetCandidateAvailabilitySectionAlert
          heading="Request cannot be fulfilled: application is rejected"
          availabilityRequest={availabilityRequest}
          actionsJSX={availabilityRequestActionsJSX}
        />
      );
      action = <></>;
    } else {
      const sentBy = data?.employeeByIds?.length ? `by ${data?.employeeByIds[0].fullName}` : '';
      paperColor = taskId ? 'pending' : 'contrast';
      icon = <SendIcon color={taskId ? 'pending' : undefined} />;
      content = (
        <Stack className={classes.content}>
          <Label color="max-contrast-grey" fontWeight={600}>
            Waiting for the candidate to submit availability…
          </Label>

          {options.lastCandidateCommunicationSentAt && (
            <Label color="high-contrast-grey" variant="captions">
              A link was sent to the candidate {sentBy} {durationLabel}.
            </Label>
          )}
          {availabilityRequestActionsJSX}
        </Stack>
      );
      action = (
        <Stack direction="row" alignItems="center" className={classes.action} spacing={1}>
          <Button variant="outlined" color="info" label="Re-share link" onClick={onReShareAvailabilityRequest} />
          <MoreMenuButton
            options={[
              {
                id: CandidateAvailabilityStatusActions.EditAvailabilityRequest,
                value: 'Edit request',
              },
              {
                id: CandidateAvailabilityStatusActions.CancelAvailabilityRequest,
                value: 'Close request',
                color: 'error',
              },
            ]}
            onSelect={(option) => {
              switch (option.id) {
                case CandidateAvailabilityStatusActions.CancelAvailabilityRequest: {
                  onDeleteAvailabilityRequest();
                  break;
                }
                case CandidateAvailabilityStatusActions.EditAvailabilityRequest: {
                  onRequestAvailability();
                  break;
                }
                default:
              }
            }}
          />
        </Stack>
      );
    }
  } else {
    paperColor = 'info';
    icon = <AvailabilityIcon color="info" />;
    content = (
      <Stack className={classes.content} spacing={0.25}>
        <Label color="info" fontWeight={600}>
          Request availability from candidate for {jobStageName}
        </Label>
        <Label color="high-contrast-grey" variant="captions">
          Let the candidate tell you when they’re available. Set up requirements including minimum duration, options on
          multiple days, available dates, advanced notice, and more.
        </Label>
      </Stack>
    );
    action = (
      <Button
        variant="outlined"
        label="Request"
        dataTestId="candidate-details-candidate-availability-request"
        className={classes.action}
        onClick={onRequestAvailability}
      />
    );
  }

  return (
    <>
      <Paper color={paperColor} disableBorder sx={sxProps.root}>
        {isInterviewScheduledAfterRequestCreated && Boolean(options?.externalId) && !taskId && (
          <Label color="high-contrast-grey" variant="captions" className={classes.interviewScheduled}>
            This candidate has been scheduled. If you don’t need additional availability you can{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link color="info" variant="captions" onClick={onDeleteAvailabilityRequest}>
              close this request
            </Link>
          </Label>
        )}
        {!isInterviewScheduledAfterRequestCreated &&
          hasCandidateSubmittedAvailabilityAfterRequestCreated &&
          options?.externalId &&
          availabilities?.candidateUpdatedAt &&
          availabilities.employeeUpdatedAt &&
          isBefore(parseISO(availabilities.candidateUpdatedAt), parseISO(availabilities.employeeUpdatedAt)) && (
            <Label color="high-contrast-grey" variant="captions" className={classes.interviewScheduled}>
              This availability was overwritten by {availabilities.lastUpdatedByEmployee?.fullName}. If you don’t need
              additional availability you can {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link color="info" variant="captions" onClick={onDeleteAvailabilityRequest}>
                close this request
              </Link>
            </Label>
          )}
        {selfScheduleMessage}
        {insufficientAvailabilityMessage}
        <CanDisable disabled={Boolean(selfScheduleOptionsId)}>
          <Stack direction="row" alignItems={alignItems} spacing={1}>
            {icon}
            {content}
            {!taskId && action}
          </Stack>
        </CanDisable>
      </Paper>
      {showSelfScheduleDeleteAlert && selfScheduleOptionsId && (
        <SelfScheduleDisableAlert
          selfScheduleOptionsId={selfScheduleOptionsId}
          onClose={() => setShowSelfScheduleDeleteAlert(false)}
          onDelete={() => setShowSelfScheduleDeleteAlert(false)}
        />
      )}
    </>
  );
};

CandidateAvailabilityStatus.fragments = {
  application: gql`
    fragment CandidateAvailabilityStatus_application on Application {
      id
      status
    }
  `,
  availabilityRequest: gql`
    ${RecjectetCandidateAvailabilitySectionAlert.fragments.availabilityRequest}
    fragment CandidateAvailabilityStatus_availabilityRequest on CandidateAvailabilityOptions {
      ...RecjectetCandidateAvailabilitySectionAlert_availabilityRequest
    }
  `,
};

export default CandidateAvailabilityStatus;
