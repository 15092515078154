import React, { FC } from 'react';

import { useUserInfo } from 'src/hooks/useEmployeeId';

import AppWideBanner from './AppWideBanner';

const ImpersonatingUserBanner: FC = () => {
  const userInfo = useUserInfo();
  if (userInfo?.impersonatingUserInfo) {
    return (
      <AppWideBanner
        backgroundColor="error"
        color="error"
        text={`You are impersonating as ${userInfo.employeeName} for ${userInfo.orgName}, please be careful.`}
      />
    );
  }
  return null;
};

export default ImpersonatingUserBanner;
