import React, { useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';

import {
  BaseReadInterviewSeatModule_LinkedInterviewFragment,
  BaseReadInterviewSeatModule_OriginalSeatFragment,
  BaseReadInterviewSeatModule_SeatFragment,
} from 'src/generated/mloop-graphql';

import FreeformSeat from './FreeformSeat';
import LinkedSeat from './LinkedSeat';
import ModuleSeat from './ModuleSeat';
import { BaseReadInterviewSeatModuleProps as Props } from './types';

type Fragments = {
  seat: BaseReadInterviewSeatModule_SeatFragment;
  originalSeat: BaseReadInterviewSeatModule_OriginalSeatFragment | undefined;
  linkedInterview: BaseReadInterviewSeatModule_LinkedInterviewFragment | undefined;
};

const BaseReadInterviewSeatModule: FCWithFragments<Fragments, Props> = ({
  seat,
  originalSeat,
  linkedInterview,
  seatIndex,
  onEdit,
  onDelete,
  onUpdated,
  onInterviewerPreferenceChanged,
}): JSX.Element | null => {
  const rowRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);

  const handleMouseOver = () => {
    setShowOptions(true);
  };

  const handleMouseOut = () => {
    setShowOptions(false);
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    onDelete();

    // This is to prevent onEdit from trigging which is attached to the parent <Grid />
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  if (seat.moduleSeat) {
    return (
      <ModuleSeat
        seat={seat}
        rowRef={rowRef}
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
        seatIndex={seatIndex}
        onEdit={onEdit}
        showOptions={showOptions}
        onDelete={handleDelete}
        onUpdated={onUpdated}
      />
    );
  }

  if (seat.freeformSeat) {
    if (!seat.freeformSeat?.jobStageInterviewSeatEmployees) return null;

    return (
      <FreeformSeat
        seat={seat}
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
        seatIndex={seatIndex}
        onEdit={onEdit}
        showOptions={showOptions}
        onDelete={handleDelete}
        onInterviewerPreferenceChanged={onInterviewerPreferenceChanged}
      />
    );
  }

  if (seat.linkedSeat) {
    if (!originalSeat || !linkedInterview) return null;

    return (
      <LinkedSeat
        seat={seat}
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
        seatIndex={seatIndex}
        onEdit={onEdit}
        showOptions={showOptions}
        onDelete={handleDelete}
        linkedInterview={linkedInterview}
        originalSeat={originalSeat}
      />
    );
  }

  return null;
};

BaseReadInterviewSeatModule.fragments = {
  seat: gql`
    ${FreeformSeat.fragments.seat}
    ${ModuleSeat.fragments.seat}
    ${LinkedSeat.fragments.seat}
    fragment BaseReadInterviewSeatModule_seat on JobStageInterviewSeat {
      id
      type
      index

      ...FreeformSeat_seat
      ...ModuleSeat_seat
      ...LinkedSeat_seat
    }
  `,
  linkedInterview: gql`
    ${LinkedSeat.fragments.linkedInterview}
    fragment BaseReadInterviewSeatModule_linkedInterview on JobStageInterview {
      id
      ...LinkedSeat_linkedInterview
    }
  `,
  originalSeat: gql`
    ${LinkedSeat.fragments.originalSeat}
    fragment BaseReadInterviewSeatModule_originalSeat on JobStageInterviewSeat {
      id
      ...LinkedSeat_originalSeat
    }
  `,
};

export default BaseReadInterviewSeatModule;
