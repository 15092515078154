import React, { useState } from 'react';

import { Dialog } from '@modernloop/shared/components';
import { logDatadogError } from '@modernloop/shared/utils';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';
import { Routes } from 'src/constants/routes';

import useCheckCreateCandidatePermission from './useCheckCreateCandidatePermission';

enum PermissionCheckStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
}

type Props = {
  jobId: string;
  onHasPermission: () => void;
  onClose: () => void;
};

const LeverCreateCandidatePermissionDialog = ({ jobId, onHasPermission, onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const [permissionCheckStatus, setPermissionCheckStatus] = useState(PermissionCheckStatus.Pending);
  const [testCandidateAtsUrl, setTestCandidateAtsUrl] = useState<string | undefined>();

  const { enqueueSnackbar } = useSnackbar();
  const checkCreateCandidatePermission = useCheckCreateCandidatePermission(jobId);

  const handleCheckPermission = async () => {
    try {
      setLoading(true);
      const { hasPermission: permission } = await checkCreateCandidatePermission();
      setPermissionCheckStatus(permission ? PermissionCheckStatus.Success : PermissionCheckStatus.Failed);
      setTestCandidateAtsUrl(testCandidateAtsUrl);
    } catch (error) {
      enqueueSnackbar('Failed to check permissions', { variant: 'error' });
      logDatadogError(error, { jobId });
    } finally {
      setLoading(false);
    }
  };

  const permissionsJsx = (
    <List sx={{ listStyleType: 'disc', margin: (theme) => theme.spacing(0, 2), padding: 0 }}>
      <ListItem sx={{ display: 'list-item' }}>contact:write:admin</ListItem>
      <ListItem sx={{ display: 'list-item' }}>opportunities:write:admin</ListItem>
      <ListItem sx={{ display: 'list-item' }}>sources:read:admin</ListItem>
    </List>
  );

  return (
    <Dialog
      open
      maxWidth="xs"
      onClose={onClose}
      title="We need additional permissions in Lever to continue"
      decoration={
        <img
          style={{ width: '64px', height: '64px' }}
          src="/static/images/scheduling/warning_64.png"
          alt="Changing calender warning"
        />
      }
      cancelOptions={{
        label: 'Cancel',
        onClick: onClose,
      }}
      submitOptions={{
        label: 'Continue',
        onClick: onHasPermission,
        isDisabled: permissionCheckStatus !== PermissionCheckStatus.Success,
      }}
    >
      <Stack spacing={1}>
        <Typography variant="subtitle1">Step 1</Typography>
        <Typography variant="body1">
          Please re-authenticate your Lever integration in order to grant the following permission and use sourcing
          links:
        </Typography>

        <Alert severity="warning">
          <List sx={{ listStyleType: 'disc', margin: (theme) => theme.spacing(0, 2), padding: 0 }}>
            <ListItem sx={{ display: 'list-item' }}>opportunities:write:admin</ListItem>
            <ListItem sx={{ display: 'list-item' }}>sources:read:admin</ListItem>
          </List>
        </Alert>

        <Box>
          <Button variant="contained" color="primary" href={Routes.SettingsIntegrations} target="_blank">
            Go to &quot;Integrations&quot;
          </Button>
        </Box>

        <Typography variant="body2">
          Not a Lever admin? Please ask your admin to enable the required permissions
        </Typography>

        <Typography variant="body2">
          Need help?{' '}
          <Link href={ZenDeskHelpCenterUrl.SOURCING_LINK_ATS_INTEGRATION} target="_blank">
            Learn more
          </Link>{' '}
          about permissions
        </Typography>

        <Divider flexItem />

        <Typography variant="subtitle1">Step 2</Typography>
        <Typography variant="body1">Check if permissions are setup correctly</Typography>

        {permissionCheckStatus !== PermissionCheckStatus.Success && (
          <Box>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={24} /> : undefined}
              onClick={handleCheckPermission}
            >
              Check permissions
            </Button>
          </Box>
        )}

        {permissionCheckStatus === PermissionCheckStatus.Success && (
          <Alert>
            <AlertTitle>Success!</AlertTitle>
            Your permissions are setup correctly
          </Alert>
        )}

        {permissionCheckStatus === PermissionCheckStatus.Failed && (
          <Alert severity="error">We could not find the following permissions: {permissionsJsx}</Alert>
        )}
      </Stack>
    </Dialog>
  );
};

export default LeverCreateCandidatePermissionDialog;
