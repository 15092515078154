import React, { FC } from 'react';

import { CanDisable } from '@modernloop/shared/helper-components';
import { Box, Tooltip } from '@mui/material';

import { CurrentTrainingSessionStatus } from 'src/generated/mloop-graphql';

type Props = {
  children: React.ReactNode;
  currentTrainingSessionStatus?: CurrentTrainingSessionStatus;
};

const PendingApprovalDisableWrapper: FC<Props> = ({ children, currentTrainingSessionStatus }) => {
  if (currentTrainingSessionStatus === CurrentTrainingSessionStatus.NeedsApproval) {
    return (
      <Tooltip title="Disabled while an approval is pending">
        <Box>
          <CanDisable disabled>{children}</CanDisable>
        </Box>
      </Tooltip>
    );
  }

  return <>{children}</>;
};

export default PendingApprovalDisableWrapper;
