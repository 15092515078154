import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { MicrosoftHealthCheckBanner_MicrosoftGccHighFragment } from 'src/generated/mloop-graphql';

import AppWideBanner, { AppWideBannerAction } from '../AppWideBanner';

import { integrationUnhealthyMessage } from './constants';

interface Fragments {
  microsoftGccHigh: MicrosoftHealthCheckBanner_MicrosoftGccHighFragment;
}

interface Props {
  retry: AppWideBannerAction;
}

const MicrosoftGccHighHealthCheckBanner: FCWithFragments<Fragments, Props> = ({ microsoftGccHigh, retry }) => {
  const microsoftGccHighEnabled = useFlag('org_microsoft_gcc_high');
  // hide if its not installed or is healthy
  if (!microsoftGccHighEnabled || !microsoftGccHigh || !microsoftGccHigh?.isInstalled || microsoftGccHigh?.isHealthy) {
    return null;
  }

  const issues = [
    !microsoftGccHigh.canReadDirectory && 'employee directory',
    !microsoftGccHigh.canReadCalendars && 'calendars',
    !microsoftGccHigh.canReadMeetingRooms && 'meeting rooms',
  ].filter(Boolean) as string[];

  return (
    <AppWideBanner
      color="foreground"
      backgroundColor="error"
      text={integrationUnhealthyMessage('Microsoft GCC High', issues)}
      action={retry}
    />
  );
};

MicrosoftGccHighHealthCheckBanner.fragments = {
  microsoftGccHigh: gql`
    fragment MicrosoftHealthCheckBanner_microsoftGccHigh on MicrosoftIntegrationHealth {
      isHealthy
      isInstalled
      canReadDirectory
      canReadCalendars
      canReadMeetingRooms
    }
  `,
};

export default MicrosoftGccHighHealthCheckBanner;
