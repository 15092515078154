import { useCallback } from 'react';

import { assertIsoTimestamp } from '@modernloop/shared/datetime';
import { getExternalErrorMessage, logError } from '@modernloop/shared/utils';

import { InterviewType } from 'src/generated/mloop-graphql';

import slice, { getScheduleOptionsRequestBody, setScheduleOptionsSetupRefresh } from 'src/slices/scheduling';

import { saveSchedules, setScheduleOverrideConfig } from 'src/store/actions/schedules';
import {
  getExcludeInterviewerIdsByJobStageId,
  getHasBreaksByJobStageId,
  getHasMultiDayByJobStageId,
  getNumberOfDaysByJobStageId,
} from 'src/store/selectors/job-stage-plan-config';
import { ScheduleDatesFilter, ScheduleFilters } from 'src/store/slices/schedule-filters';

import getRescheduleOptions from 'src/utils/api/getRescheduleOptions';
import getScheduleOptions, {
  InterviewSchedule,
  InterviewerResponseStatus,
  RequestBody,
  ResponseBody,
  UICandidateAvailability,
} from 'src/utils/api/getScheduleOptions';
import { isSameDay } from 'src/utils/datetime/Conversions';
import { isBreak } from 'src/utils/interview';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { useDispatch, useSelector } from 'src/store';

const getCandidateAvailabilities = (
  candidateAvailabilities: UICandidateAvailability[],
  tz: string,
  dates?: ScheduleDatesFilter // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
UICandidateAvailability[] => {
  if (dates?.length) {
    const newCandidateAvailabilities = candidateAvailabilities.filter((availablity) => {
      return !!dates.find((date) => {
        return isSameDay(assertIsoTimestamp(availablity.startAt), date, tz);
      });
    });
    if (newCandidateAvailabilities.length) {
      return newCandidateAvailabilities;
    }
  }
  return candidateAvailabilities;
};

const useSubmitInterviewPlan = (jobStageId: string, increment: boolean) => {
  const scheduleFlowData = useScheduleFlowData();

  const dispatch = useDispatch();

  const { candidateAvailabilities, candidateTimezone, interviewPlanJobStageId } = scheduleFlowData;
  const params = useSelector((state) => getScheduleOptionsRequestBody(state, jobStageId, increment, candidateTimezone));

  const excludeInterviewerIds = useSelector((state) => getExcludeInterviewerIdsByJobStageId(state, jobStageId));
  const hasBreaks = useSelector((state) => getHasBreaksByJobStageId(state, jobStageId));
  const hasMultiDay = useSelector((state) => getHasMultiDayByJobStageId(state, jobStageId));
  const numberOfDays = useSelector((state) => getNumberOfDaysByJobStageId(state, jobStageId));

  const submitInterviewPlanStep = useCallback(
    async ({
      filters,
      interviewersResponseStatuses,
    }: {
      filters?: ScheduleFilters;
      interviewersResponseStatuses?: InterviewerResponseStatus[];
    }) => {
      dispatch(setScheduleOptionsSetupRefresh(false));
      dispatch(slice.actions.setInterviewPlanStepSubmitting({ submitting: true }));
      dispatch(slice.actions.clearAnyErrors());

      let hasNoInterviewSeat = false;
      Object.values(params.interviewSlots || []).forEach((interview) => {
        if (isBreak(interview?.interviewType || InterviewType.Interview)) return;

        if (!interview.seats || interview.seats.length === 0) {
          hasNoInterviewSeat = true;
        }
      });

      if (hasNoInterviewSeat) {
        throw new Error(
          'Some of your interviews do not have an interviewer added. Please add one or more interviewers.'
        );
      }

      const scheduleOptionsRequestBody: RequestBody = {
        jobStageId: interviewPlanJobStageId,
        interviewGroups: params.interviewGroups,
        interviewSlots: params.interviewSlots || [],
        candidateAvailabilities: getCandidateAvailabilities(
          candidateAvailabilities,
          scheduleFlowData.candidateTimezone,
          filters?.dates
        ),
        candidateTimezone,
        excludeInterviewerIds: params.excludeInterviewerIds || excludeInterviewerIds,
        hasBreaks: params.hasBreaks || !!hasBreaks,
        hasMultiDay: params.hasMultiDay || !!hasMultiDay,
        hardConflicts: params.hardConflicts,
        throwOnZero: params.throwOnZero,
        numberOfDays: params.numberOfDays || numberOfDays,
      };

      let data: ResponseBody;

      try {
        if (interviewersResponseStatuses) {
          data = await getRescheduleOptions({
            ...scheduleOptionsRequestBody,
            interviewersResponseStatus: interviewersResponseStatuses,
          });
        } else {
          data = await getScheduleOptions(scheduleOptionsRequestBody);
        }
        if (data.error) {
          throw new Error(data.error);
        }
        const newSchedules = data.schedules as InterviewSchedule[];

        dispatch(slice.actions.setStepCandidateAvailabilityLoading({ loading: false }));
        dispatch(saveSchedules(data.count, newSchedules));
        dispatch(setScheduleOverrideConfig({ multiDay: !!data.config_override?.multi_day }));
        dispatch(slice.actions.clearSelectedScheduleId());
      } catch (error) {
        logError(error);
        dispatch(slice.actions.clearSelectedScheduleId());
        dispatch(slice.actions.setStepCandidateAvailabilityLoading({ loading: false }));

        throw new Error(getExternalErrorMessage(error));
      }

      dispatch(slice.actions.setInterviewPlanStepSubmitting({ submitting: false }));
    },
    [
      candidateAvailabilities,
      candidateTimezone,
      dispatch,
      excludeInterviewerIds,
      hasBreaks,
      hasMultiDay,
      interviewPlanJobStageId,
      numberOfDays,
      params.excludeInterviewerIds,
      params.hardConflicts,
      params.hasBreaks,
      params.hasMultiDay,
      params.interviewGroups,
      params.interviewSlots,
      params.numberOfDays,
      params.throwOnZero,
      scheduleFlowData.candidateTimezone,
    ]
  );

  return submitInterviewPlanStep;
};

export default useSubmitInterviewPlan;
