import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@material-ui/core/styles';
import { FCWithFragments } from '@modernloop/shared/components';

import { InterviewerCommunicationCards_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import Paper from 'src/components/Paper';
import Stack from 'src/components/Stack';
import Label from 'src/components/label';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { getScheduleContent } from 'src/store/selectors/schedule-communications';
import { getScheduleById } from 'src/store/selectors/schedules';
import { getSelectedScheduleId } from 'src/store/selectors/scheduling';

import { Theme } from 'src/theme/type';

import { InterviewEvent } from 'src/utils/api/getScheduleOptions';
import { renderTimeRangeWithDayMthHrMinTz } from 'src/utils/renderTimeRange';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';
import InterviewerCommonTemplate from 'src/views-new/ScheduleFlow/Steps/Communications/InterviewerCommunicationCards/InterviewerCommonTemplate';
import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useSelector } from 'src/store';

import InterviewerContentCard from './InterviewerContentCard';

const useSxProps = () => {
  return useMemo(() => {
    return {
      root: { marginBottom: '8px' },
      label: {
        padding: '24px 0px 0px 8px',
      },
    };
  }, []);
};

type Fragments = {
  interviewPlan: InterviewerCommunicationCards_InterviewPlanFragment | undefined;
};

const InterviewerCommunicationCards: FCWithFragments<Fragments> = ({ interviewPlan }): JSX.Element => {
  const sxProps = useSxProps();
  const theme = useTheme() as Theme;
  const scheduleContent = useSelector(getScheduleContent);
  const selectedScheduleId = useSelector((state) => getSelectedScheduleId(state));
  const schedule = useScheduleWithoutBreaks(selectedScheduleId);
  const originalSchedule = useSelector((state) => getScheduleById(state, selectedScheduleId || ''));
  const [scheduleUpdates] = useSelector((state) => (schedule ? [state.scheduleUpdate.byId[schedule.id]] : []));

  const scheduleFlowData = useScheduleFlowData();
  const { candidateTimezone } = scheduleFlowData;

  const deletedInterviews: InterviewEvent[] = [];
  if (originalSchedule && originalSchedule.events) {
    originalSchedule.events.forEach((originalEvent) => {
      const deletes = (scheduleUpdates || []).filter(
        (update) =>
          update.type === 'ScheduleUpdateDeleteInterview' && update.applicationStageInterviewId === originalEvent.id
      );
      if (deletes.length > 0) {
        deletedInterviews.push(originalEvent);
      }
    });
  }

  return (
    <Stack spacing={2} direction="column">
      <Label style={sxProps.label} variant="title">
        Internal
      </Label>
      {scheduleContent && scheduleContent.interviewerEventContents.length > 1 && schedule && (
        <InterviewerCommonTemplate scheduleId={schedule.id} scheduleFlowType={ScheduleFlowType.UPDATE} />
      )}
      {scheduleContent?.interviewerEventContents.map((content) => (
        <InterviewerContentCard key={content.event.id} eventId={content.event.id} interviewPlan={interviewPlan} />
      ))}
      {deletedInterviews.map((deletedEvent, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Paper key={index} color="alternate" sx={sxProps.root}>
          <Stack direction="column" spacing={1}>
            <Stack wrap="nowrap">
              <Label
                variant="body"
                fontWeight={600}
                color="error"
                style={{
                  backgroundColor: theme.palette.background.error,
                }}
              >
                Deleted Interview
              </Label>
            </Stack>
            <Label variant="body" fontWeight={600} style={{ textDecoration: 'line-through' }}>
              {deletedEvent.name}
            </Label>
            <Label variant="captions" fontWeight={400} style={{ textDecoration: 'line-through' }}>
              {renderTimeRangeWithDayMthHrMinTz(deletedEvent.startAt, deletedEvent.endAt, candidateTimezone)}
            </Label>
          </Stack>
        </Paper>
      ))}
    </Stack>
  );
};

InterviewerCommunicationCards.fragments = {
  interviewPlan: gql`
    ${InterviewerContentCard.fragments.interviewPlan}
    fragment InterviewerCommunicationCards_interviewPlan on JobStage {
      id
      ...InterviewerContentCard_interviewPlan
    }
  `,
};

export default InterviewerCommunicationCards;
