import React from 'react';

import BaseIcon, { Props } from './Icon';

const CalendarIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00002 1.25C3.48123 1.25 2.25002 2.48122 2.25002 4V16C2.25002 17.5188 3.48123 18.75 5.00002 18.75H11.6716C12.4009 18.75 13.1004 18.4603 13.6161 17.9445L16.9445 14.6161C17.4603 14.1004 17.75 13.4009 17.75 12.6716V4C17.75 2.48122 16.5188 1.25 15 1.25H5.00002ZM3.75002 4C3.75002 3.30964 4.30966 2.75 5.00002 2.75H15C15.6903 2.75 16.25 3.30964 16.25 4V12.0851L15.7354 11.9822C15.175 11.8701 14.6235 11.8887 14.1095 12.0146C14.0871 11.9951 14.0633 11.9768 14.0382 11.9597C12.6365 11.0087 10.6778 10.7033 8.96497 10.9646C7.27303 11.2228 5.55357 12.087 4.95718 13.7665C4.76205 14.316 4.92315 14.8458 5.24895 15.2066C5.56087 15.552 6.02179 15.75 6.5 15.75H11.25V17.25H5.00002C4.30966 17.25 3.75002 16.6904 3.75002 16V4ZM11.5227 14.25C11.7454 13.7004 12.0942 13.219 12.5298 12.8379C11.5615 12.4167 10.3349 12.273 9.19123 12.4475C7.7685 12.6646 6.73454 13.322 6.38819 14.2212C6.39402 14.2246 6.40081 14.2281 6.40865 14.2315C6.43469 14.2428 6.46613 14.25 6.5 14.25H11.5227ZM12.75 16.6893L15.8839 13.5555L15.8953 13.5439L15.4413 13.4531C14.049 13.1746 12.75 14.2396 12.75 15.6594V16.6893ZM7.25 7.5C7.25 5.98122 8.48121 4.75 10 4.75C11.5188 4.75 12.75 5.98122 12.75 7.5C12.75 9.01878 11.5188 10.25 10 10.25C8.48121 10.25 7.25 9.01878 7.25 7.5ZM10 6.25C9.30964 6.25 8.75 6.80964 8.75 7.5C8.75 8.19036 9.30964 8.75 10 8.75C10.6904 8.75 11.25 8.19036 11.25 7.5C11.25 6.80964 10.6904 6.25 10 6.25Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CalendarIcon;
