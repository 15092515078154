import { Box, LinearProgress } from '@mui/material';

interface Props {
  width?: number | string;
}
export const UnbrandedLoader: React.FC<Props> = ({ width = '120px' }) => {
  return (
    <Box width={width}>
      <LinearProgress variant="indeterminate" />
    </Box>
  );
};
