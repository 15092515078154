import React, { useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { CounterNumberInput, Dialog } from '@modernloop/shared/components';
import { PublicError } from '@modernloop/shared/helper-components';
import { ResetIcon, ReverseShadowIcon, ShadowIcon } from '@modernloop/shared/icons';
import { Button, Stack, Typography } from '@mui/material';

import {
  EditTrainigPlan_ModuleMemberFragment,
  TrainingStatus,
  useInterviewModuleMemberUpdateMutation,
} from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import { resetModuleMemberListCache } from 'src/entities/InterviewModuleMember/utils';

import { MAX_COUNT_OF_TRAINING_INTERVIEWS, MIN_COUNT_OF_TRAINING_INTERVIEWS } from 'src/constants';

import RoleChangeAlert from '../RoleChangeAlert';

type Props = {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
};

type Fragments = {
  moduleMember: EditTrainigPlan_ModuleMemberFragment;
};

const EditTrainigPlanModal: FCWithFragments<Fragments, Props> = ({ moduleMember, open, onClose, onUpdate }) => {
  const [updateMember, { loading, error }] = useInterviewModuleMemberUpdateMutation();
  const [shadows, setShadows] = useState<number>(moduleMember.shadowsRequired || MIN_COUNT_OF_TRAINING_INTERVIEWS);
  const [reverseShadows, setReverseShadows] = useState<number>(
    moduleMember.reverseShadowsRequired || MIN_COUNT_OF_TRAINING_INTERVIEWS
  );

  const handleShadowRequiredChange = (newValue: number) => {
    setShadows(newValue);
  };

  const handleReverseShadowRequiredChange = (newValue: number) => {
    setReverseShadows(newValue);
  };

  const handleReset = () => {
    setShadows(moduleMember?.interviewModule?.shadowsRequired || MIN_COUNT_OF_TRAINING_INTERVIEWS);
    setReverseShadows(moduleMember?.interviewModule?.reverseShadowsRequired || MIN_COUNT_OF_TRAINING_INTERVIEWS);
  };

  const roleImpacted: TrainingStatus | null = useMemo(() => {
    /** If trained it wont impact any status on shadow & reverse shadow required count change */

    if (moduleMember.status === TrainingStatus.Trained) {
      return null;
    }

    // This means changed
    if (shadows !== moduleMember.shadowsRequired) {
      // Check backward first
      if (
        moduleMember.stats?.manualCompletedAsShadow + moduleMember.stats?.completedAsShadow < shadows &&
        moduleMember.status !== TrainingStatus.Shadow
      ) {
        return TrainingStatus.Shadow;
      }
      if (moduleMember.stats?.manualCompletedAsShadow + moduleMember.stats?.completedAsShadow >= shadows) {
        if (
          moduleMember.stats?.manualCompletedAsReverseShadow + moduleMember.stats?.completedAsReverseShadow >=
          reverseShadows
        ) {
          return TrainingStatus.Trained;
        }
        if (moduleMember.status !== TrainingStatus.ReverseShadow) {
          return TrainingStatus.ReverseShadow;
        }
      }
    }

    // This means changed
    if (reverseShadows !== moduleMember.reverseShadowsRequired) {
      // Check backward first
      if (
        moduleMember.stats?.manualCompletedAsShadow + moduleMember.stats?.completedAsShadow < shadows &&
        moduleMember.status !== TrainingStatus.Shadow
      ) {
        return TrainingStatus.Shadow;
      }
      if (
        moduleMember.stats?.manualCompletedAsReverseShadow + moduleMember.stats?.completedAsReverseShadow >=
          reverseShadows &&
        moduleMember.stats?.manualCompletedAsShadow + moduleMember.stats?.completedAsShadow >= shadows
      ) {
        return TrainingStatus.Trained;
      }
    }

    return null;
  }, [moduleMember, reverseShadows, shadows]);

  const handleUpdate = async () => {
    try {
      await updateMember({
        variables: {
          input: {
            employeeId: moduleMember.employeeId,
            interviewModuleId: moduleMember.interviewModuleId,
            shadowRequiredCount: shadows,
            reverseShadowRequiredCount: reverseShadows,
          },
        },
        refetchQueries: ['InterviewModuleMemberSidepanelQuery'],
        awaitRefetchQueries: true,
        update: (cache) => {
          if (roleImpacted) {
            resetModuleMemberListCache({
              cache,
              employeeIds: [moduleMember.employeeId],
              moduleId: moduleMember.interviewModuleId,
            });
          }
        },
      });

      onUpdate();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog
      cancelOptions={{ label: 'Cancel', onClick: onClose }}
      submitOptions={{
        label: 'Save',
        onClick: handleUpdate,
        isDisabled: loading,
        isLoading: loading,
      }}
      decoration={
        <img src="/static/images/setup/decoration_edit.svg" alt="Edit training plan" height="64px" width="64px" />
      }
      maxWidth="xs"
      title="Edit training plan"
      open={open}
      onClose={onClose}
    >
      <Stack direction="column" spacing={2}>
        <Stack rowGap={1} direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography>
            <Stack display="flex" direction="row" alignItems="center" columnGap={1}>
              <ShadowIcon />
              Shadows
            </Stack>
          </Typography>

          <CounterNumberInput
            min={MIN_COUNT_OF_TRAINING_INTERVIEWS}
            max={MAX_COUNT_OF_TRAINING_INTERVIEWS}
            onChange={(value) => {
              handleShadowRequiredChange(value);
            }}
            value={shadows}
          />
        </Stack>

        <Stack rowGap={1} direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography>
            <Stack display="flex" direction="row" alignItems="center" columnGap={1}>
              <ReverseShadowIcon />
              Reverse shadows
            </Stack>
          </Typography>
          <CounterNumberInput
            min={MIN_COUNT_OF_TRAINING_INTERVIEWS}
            max={MAX_COUNT_OF_TRAINING_INTERVIEWS}
            onChange={(value) => {
              handleReverseShadowRequiredChange(value);
            }}
            value={reverseShadows}
          />
        </Stack>

        <Button
          sx={{ width: '240px' }}
          variant="contained"
          color="secondary"
          startIcon={<ResetIcon />}
          onClick={handleReset}
        >
          Reset to module default
        </Button>

        {roleImpacted && <RoleChangeAlert roleImpacted={roleImpacted} />}
      </Stack>

      <PublicError error={error} />
    </Dialog>
  );
};

EditTrainigPlanModal.fragments = {
  moduleMember: gql`
    fragment EditTrainigPlan_moduleMember on InterviewModuleMember {
      employeeId
      interviewModuleId
      status
      shadowsRequired
      reverseShadowsRequired
      stats {
        completedAsReverseShadow
        completedAsShadow
        manualCompletedAsShadow
        manualCompletedAsReverseShadow
      }
      interviewModule {
        id
        shadowsRequired
        reverseShadowsRequired
        shouldFastTrackTraining
        graduateFromShadowApprovalType
        graduateFromReverseShadowApprovalType
      }
    }
  `,
};

export default EditTrainigPlanModal;
