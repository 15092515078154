import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
import { AvatarGroup, Stack, SxProps } from '@mui/material';

import { EmployeeFacePile_EmployeeFragment } from 'src/generated/mloop-graphql';

import Avatar, { AvatarProps } from 'src/components/Avatar';
import { FCWithFragments, createSxProps } from 'src/components/types';

import { Theme } from 'src/themeMui5/type';

const useSxProps = () => {
  const sxProps = useMemo(
    () => ({
      avatarWrapper: {
        alignItems: 'flex-start',
      },
      avatarItems: {
        '&>div': {
          width: '24px',
          height: '24px',
          borderRadius: '6px',
          fontSize: '12px',
        },
      },
    }),
    []
  );

  return createSxProps(sxProps);
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface EmployeeFacePileProps {
  max?: number;
  avatarSize?: AvatarProps['size'];
  sx?: SxProps<Theme>;
  dataTestId?: string;
}

type Fragments = {
  employees: EmployeeFacePile_EmployeeFragment[];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const EmployeeFacePile: FCWithFragments<Fragments, EmployeeFacePileProps> = ({
  sx,
  employees,
  dataTestId,
  max = 3,
  avatarSize = '24px',
}) => {
  const sxProps = useSxProps();
  return (
    <Stack sx={{ ...sxProps.avatarWrapper, ...sx }}>
      <AvatarGroup max={max} sx={sxProps.avatarItems}>
        {employees.map((employee) => (
          <Avatar
            key={employee.id}
            dataTestId={`${dataTestId}-${employee.id}`}
            src={employee?.slackImageUrl ?? ''}
            alt={employee?.fullName ?? ''}
            size={avatarSize}
          />
        ))}
      </AvatarGroup>
    </Stack>
  );
};

EmployeeFacePile.fragments = {
  employees: gql`
    fragment EmployeeFacePile_employee on Employee {
      id
      fullName
      slackImageUrl
    }
  `,
};

export default EmployeeFacePile;
