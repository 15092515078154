import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import Popover from '@material-ui/core/Popover';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';

import IsoTimestamp from 'src/types/IsoTimestamp';

import { Theme } from 'src/theme';

import BaseDatePicker, { BaseDatePickerProps } from './BaseDatePickerV2';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type DatePickerProps = {
  open: boolean;

  dateAnchorEl: React.RefObject<HTMLElement>;

  onClose: () => void;
} & BaseDatePickerProps;

export const useDateButtonStyle = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '6px',
      justifyContent: `stretch`,
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
  })
);

const DateTimePickerPopover = (props: DatePickerProps): JSX.Element => {
  const { open, dateAnchorEl, onClose, onChange, ...restProps } = props;

  const handleSelect = (time: IsoTimestamp) => {
    onChange(time);
    onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={dateAnchorEl.current}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <BaseDatePicker {...restProps} onChange={handleSelect} />
    </Popover>
  );
};

export default DateTimePickerPopover;
