import React from 'react';

import { Stack } from '@mui/material';

import { MemberSelect_UserInfoFragment } from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
const MemberOption = ({
  currentEmployeeId,
  member,
}: {
  currentEmployeeId: string;
  member: MemberSelect_UserInfoFragment;
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center" flexWrap="nowrap">
      <Avatar alt={member.userName || ''} src={member?.employee?.slackImageUrl || undefined} />
      <MaybeTooltip
        label={`${member.userName}${member.employee?.id === currentEmployeeId ? ' (you)' : ''}`}
        tooltip={member.userName || ''}
      />
    </Stack>
  );
};

export default MemberOption;
