import React from 'react';

import { Divider as MuiDivider, DividerProps as MuiDividerProps, SxProps } from '@mui/material';

import { BaseProps } from 'src/components/types';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface DividerProps extends BaseProps {
  /**
   * Passed styles
   */
  style?: React.CSSProperties;

  sx?: SxProps;

  /**
   * The divider orientation.
   */
  orientation?: MuiDividerProps['orientation'];

  /**
   * The variant to use.
   */
  variant?: MuiDividerProps['variant'];
}

/**
 * @deprecated - Use `Divider` from `@mui/material` instead
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
function Divider({ dataTestId, style, sx, orientation, variant }: DividerProps): JSX.Element {
  return <MuiDivider data-testid={dataTestId} style={style} orientation={orientation} variant={variant} sx={sx} />;
}

export default Divider;
