/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { useFeatureFlagNumber, useFlag } from '@modernloop/shared/feature-flag';
import { logDatadogError } from '@modernloop/shared/utils';
import { Alert, Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';

import {
  AtsNotSchedulableReason,
  InterviewPlan_JobStageFragment,
  InterviewType,
  Schedule_ApplicationFragment,
  Schedule_CandidateFragment,
  Schedule_InterviewPlanFragment,
  Schedule_JobStageFragment,
  Schedule_ScheduleFragment,
  Schedule_SelfScheduleRequestFragment,
  UseGetInterviewNames_InterviewsFragment,
  useScheduleTaskInterviewPlanUpsertMutation,
} from 'src/generated/mloop-graphql';

import AccordionCard from 'src/components/AccordionCard';
import { Props as IconProps } from 'src/components/icons/Icon';
import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';
import { IconType } from 'src/components/utils/icons';

import AtsName from 'src/entities/Ats/AtsName';
import InterviewPlan from 'src/entities/InterviewPlan';
import getSaveInterviewPlanInput from 'src/entities/InterviewPlan/getSaveInterviewPlanInput';
import useIsInterviewPlanEntityValid, {
  INTERVIEW_PLAN_WITH_DYNAMIC_BREAKS,
} from 'src/entities/InterviewPlan/useIsInterviewPlanValid';
import { useResetInterviewPlanToBaseJobStage } from 'src/entities/InterviewPlan/useResetInterviewPlanToBaseJobStage';
import ScheduleContentEntity from 'src/entities/ScheduleContent';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useApplicationJobStageSchedulable from 'src/hooks/useApplicationJobStageSchedulable';
import useHistory from 'src/hooks/useHistory';
import useIsApplicationRejected from 'src/hooks/useIsApplicationRejected';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';
import { SearchParamKnownKeys } from 'src/hooks/useUrlSearchParams';

import { getAllJobStageInterviewByJobStageId } from 'src/store/selectors/job-stage-interview';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import CancelSchedule from 'src/views-new/CandidateDetails/CancelSchedule';
import useIsInterviewPlanValid, {
  INCOMPLETE_INTERVIEW_PLAN,
  INTERVIEW_PLAN_WITH_MULTI_DAY_SCHEDULE,
} from 'src/views-new/InterviewPlan/useIsInterviewPlanValid';
import { selfScheduleOptionsFragment } from 'src/views-new/JobDetailsPage/JobStageDetails/JobStageCommunicationSettings';
import IncompleteInterviewPlanAlert from 'src/views-new/SelfSchedule/IncompleteInterviewPlanAlert';
import SelfSchedule from 'src/views-new/SelfSchedule/SelfSchedule';
import SelfScheduleOpenAlert from 'src/views-new/SelfSchedule/SelfScheduleOpenAlert';
import { SelfScheduleOptionsModalPage } from 'src/views-new/SelfSchedule/SelfScheduleOptionsModal';
import SelfScheduleOptionsModal from 'src/views-new/SelfSchedule/SelfScheduleOptionsModalWrapper';
import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

import { useSelector } from 'src/store';

import RequirementContainer from '../Common/RequirementContainer';

import InterviewPlanWrapper from './InterviewPlanWrapper';
import useGetInterviewNamesFromFragment from './utils/useGetInterviewNames';

// Not relying on the interview plan from Task because we are not refreshing task details when interview plan is updated locally.
const useGetInterviewNames = (jobStageId: string) => {
  const allInterviews = useSelector((state) => getAllJobStageInterviewByJobStageId(state, jobStageId));

  if (!allInterviews?.length) return 'Interview plan';

  return allInterviews
    .filter((interview) => interview.type === InterviewType.Interview)
    .map((interview) => interview.name)
    .join(', ');
};

const useGetScheduleInterviewNames = (schedule?: Schedule_ScheduleFragment) => {
  const result = useMemo(() => {
    return schedule?.applicationStageInterviews?.map((interview) => interview.name).join(', ');
  }, [schedule]);

  return result || 'Interviews';
};

type Fragments = {
  application: Schedule_ApplicationFragment;
  candidate: Schedule_CandidateFragment;
  interviewPlan: Schedule_InterviewPlanFragment;
  jobStage: Schedule_JobStageFragment;
  selfScheduleRequest?: Schedule_SelfScheduleRequestFragment;
  schedule?: Schedule_ScheduleFragment;
};

type Props = {
  taskId: string;
  jobStageId: string;
  customJobStageId: string;
  availabilityRequestId?: string;
  availabilityRequestedAfterSchedule: boolean;
  availabilityReceivedAfterSchedule: boolean;
};

const Schedule: FCWithFragments<Fragments, Props> = ({
  availabilityRequestId,
  application,
  jobStage,
  candidate,
  taskId,
  jobStageId,
  customJobStageId,
  interviewPlan: interviewPlanProps,
  selfScheduleRequest,
  schedule,
  availabilityRequestedAfterSchedule,
  availabilityReceivedAfterSchedule,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isSchedulable, reason } = useApplicationJobStageSchedulable(
    {
      application,
    },
    { jobStageId }
  );

  const [savingInterviewPlan, setSavingInterviewPlan] = useState(false);
  const [interviewPlan, setInterviewPlan] = useState<InterviewPlan_JobStageFragment>(interviewPlanProps);

  const [showSelfScheduleOptionsModal, setShowSelfScheduleOptionsModal] = useState(false);
  const [showIncompleteInterviewPlanAlert, setShowIncompleteInterviewPlanAlert] = useState(false);
  const [showSelfScheduleDialogs, setShowSelfScheduleDialogs] = useState(false);
  const [showSelfScheduleOpenAlert, setShowSelfScheduleOpenAlert] = useState(false);
  const [showCancelScheduleDialog, setShowCancelScheduleDialog] = useState(false);
  const isApplicationRejected = useIsApplicationRejected(application.status);

  const refetchactivityFeed = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();

  const { resetPlan } = useResetInterviewPlanToBaseJobStage({
    jobStageId: jobStage?.id,
    customJobStageId,
  });

  const interviewPlanAutoSaveDebounceTimeout = useFeatureFlagNumber('user_interview_plan_auto_save_debounce_timeout');
  const interviewPlanEntityInTasksEnabled = useFlag('interview_plan_entity_in_tasks');
  const orgBreakImprovementsEnabled = useFlag('org_break_improvements');

  const [scheduleTaskInterviewPlanUpsertMutation] = useScheduleTaskInterviewPlanUpsertMutation();

  const saveInterviewPlan = useDebouncedCallback(async (newInterviewPlan) => {
    const interviewPlanInput = getSaveInterviewPlanInput(
      { interviewPlan: newInterviewPlan },
      { finalJobStageId: interviewPlan.id }
    );
    try {
      await scheduleTaskInterviewPlanUpsertMutation({
        variables: {
          input: orgBreakImprovementsEnabled
            ? interviewPlanInput
            : { jobStageId: interviewPlanInput.jobStageId, groups: interviewPlanInput.groups },
        },
      });
      enqueueSnackbar('Interview plan saved', { variant: 'success', preventDuplicate: true });
    } catch (e) {
      enqueueSnackbar('Failed to save interview plan', { variant: 'error', preventDuplicate: true });
    }
  }, interviewPlanAutoSaveDebounceTimeout);

  const interviewNamesFromStore = useGetInterviewNames(customJobStageId);
  const interviewNamesFromInterviewPlanFragment = useGetInterviewNamesFromFragment({
    interviews:
      interviewPlan.jobStageInterviewGroups
        ?.map((group) => (group.jobStageInterviews as UseGetInterviewNames_InterviewsFragment[]) || [])
        .flat() || [],
  });

  const interviewNames = interviewPlanEntityInTasksEnabled
    ? interviewNamesFromInterviewPlanFragment
    : interviewNamesFromStore;

  const scheduledInterviewNames = useGetScheduleInterviewNames(schedule);
  let alertText = availabilityRequestedAfterSchedule
    ? 'New availability was requested after this schedule was last updated.'
    : undefined;

  const history = useHistory();

  const iconInfo = useMemo((): { type: IconType; props: IconProps } => {
    if (availabilityRequestedAfterSchedule || availabilityReceivedAfterSchedule) {
      return { type: 'WarningIcon', props: { color: 'pending' } };
    }
    if (selfScheduleRequest?.id && !schedule?.id) {
      return { type: 'SendIcon', props: { color: 'pending' } };
    }
    if (schedule?.id) {
      return { type: 'CircleWithCheckIcon', props: { color: 'success' } };
    }
    return { type: 'RadioIcon', props: {} };
  }, [schedule?.id, selfScheduleRequest?.id, availabilityRequestedAfterSchedule, availabilityReceivedAfterSchedule]);

  const handleScheduleNow = useCallback(() => {
    refetchScheduleTaskQuery();
    refetchactivityFeed();
    SidePanelManager.closeSidePanel();
    history.push(`/schedule/${application.id}?${SearchParamKnownKeys.taskId}=${taskId}`);
  }, [refetchactivityFeed, application.id, history, refetchScheduleTaskQuery, taskId]);

  const isInterviewPlanValidResultFromStore = useIsInterviewPlanValid(customJobStageId, {
    isDayBreakAllowed: false,
    allowSomeInterviewsHiddenFromCandiate: false,
  });

  const isInterviewPlanValidResultFromFragments = useIsInterviewPlanEntityValid(
    { jobStage: interviewPlan },
    {
      isDayBreakAllowed: false,
      allowSomeInterviewsHiddenFromCandiate: false,
      allowDynamicBreaks: false,
    }
  );

  const isInterviewPlanValidResult = interviewPlanEntityInTasksEnabled
    ? isInterviewPlanValidResultFromFragments
    : isInterviewPlanValidResultFromStore;

  const handleShowSelfScheduleOptionsModal = useCallback(async () => {
    if (!availabilityRequestId && !isInterviewPlanValidResult.isValid) {
      setShowIncompleteInterviewPlanAlert(true);
      return;
    }
    await refetchScheduleTaskQuery();
    setShowSelfScheduleOptionsModal(true);
  }, [availabilityRequestId, isInterviewPlanValidResult.isValid, refetchScheduleTaskQuery]);

  if (availabilityReceivedAfterSchedule) {
    alertText = 'New availability was received after this schedule was last updated.';
  }

  const resetInterviewPlan = async () => {
    try {
      const newInterviewPlan = await resetPlan();
      if (newInterviewPlan) {
        setInterviewPlan(newInterviewPlan);
        enqueueSnackbar('Interview plan saved', { variant: 'success', preventDuplicate: true });
      }
    } catch (e) {
      logDatadogError(e);
      enqueueSnackbar('Failed to reset interview plan', { variant: 'error', preventDuplicate: true });
    }
  };
  const getScheduleDiabledButtonTooltip = (): string | JSX.Element => {
    let toolTip: string | JSX.Element = '';
    if (isApplicationRejected) {
      toolTip = 'Application rejected';
    } else if (!isSchedulable) {
      if (reason === AtsNotSchedulableReason.NotSchedulable) {
        if (application.jobStageId !== jobStageId) {
          toolTip = 'Tasks can only be created for the candidate’s current stage.';
        } else {
          toolTip = (
            <>
              This is an unschedulable stage in <AtsName />
            </>
          );
        }
      } else if (reason === AtsNotSchedulableReason.NotSchedulableOnCurrentStep) {
        const step =
          // eslint-disable-next-line no-underscore-dangle
          application.atsApplication?.atsFields?.__typename === 'WorkdayApplication'
            ? application.atsApplication?.atsFields?.currentStepName
            : '';
        if (step) {
          toolTip = `Candidate is currently on the ${step} step and cannot be scheduled right now.`;
        }
      }
    }
    return toolTip;
  };

  return (
    <>
      <RequirementContainer title="Schedule interviews" iconType={iconInfo.type} iconProps={iconInfo.props}>
        <Stack spacing={1}>
          {alertText && (
            <ConditionalThemeProvider>
              <Alert severity="warning">
                <Typography variant="body1">{alertText}</Typography>
              </Alert>
            </ConditionalThemeProvider>
          )}
          <AccordionCard
            title={<Label>{schedule ? scheduledInterviewNames : interviewNames}</Label>}
            accordionDetailsPadding={schedule ? 0 : undefined}
          >
            <Stack spacing={1}>
              {selfScheduleRequest?.id && !schedule?.id && (
                <SelfSchedule
                  application={application}
                  applicationId={application.id}
                  candidateId={candidate.id}
                  jobStageId={jobStageId}
                  customJobStageId={customJobStageId}
                  taskSelfSchedule={selfScheduleRequest}
                  showSelfScheduleDialogs={showSelfScheduleDialogs}
                  onClose={(emailSent) => {
                    if (emailSent === undefined) {
                      setShowSelfScheduleDialogs(false);
                    } else if (typeof emailSent === 'boolean') {
                      setShowSelfScheduleDialogs(!emailSent);
                    }
                    setShowSelfScheduleDialogs(false);
                  }}
                  settings={jobStage.jobStageSettings}
                />
              )}

              {!schedule?.id && (
                <ConditionalThemeProvider>
                  <Stack direction="row" spacing={1}>
                    <Tooltip title={getScheduleDiabledButtonTooltip()}>
                      <span>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          disabled={isApplicationRejected || !isSchedulable}
                          onClick={() => {
                            if (selfScheduleRequest?.id) {
                              setShowSelfScheduleOpenAlert(true);
                              return;
                            }
                            handleScheduleNow();
                          }}
                        >
                          Schedule now
                        </Button>
                      </span>
                    </Tooltip>

                    {!selfScheduleRequest?.id && (
                      <Tooltip title={getScheduleDiabledButtonTooltip()}>
                        <span>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            disabled={savingInterviewPlan || isApplicationRejected || !isSchedulable}
                            onClick={handleShowSelfScheduleOptionsModal}
                          >
                            Send self-schedule link
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </Stack>
                </ConditionalThemeProvider>
              )}

              {!schedule?.id && (
                <Box ml="-8px">
                  {interviewPlanEntityInTasksEnabled ? (
                    <InterviewPlan
                      onResetToJobStageDefault={resetInterviewPlan}
                      schedulable={false}
                      job={interviewPlanProps.job || undefined}
                      jobStage={interviewPlan}
                      readonly={Boolean(selfScheduleRequest?.id) || isApplicationRejected}
                      onUpdated={(newInterviewPlan: InterviewPlan_JobStageFragment) => {
                        setInterviewPlan(newInterviewPlan);
                        saveInterviewPlan(newInterviewPlan);
                      }}
                    />
                  ) : (
                    <InterviewPlanWrapper
                      key={jobStageId}
                      jobStageId={jobStageId}
                      customJobStageId={customJobStageId}
                      autoSave
                      readonly={Boolean(selfScheduleRequest?.id) || isApplicationRejected}
                      onAutoSaveComplete={(complete) => setSavingInterviewPlan(!complete)}
                    />
                  )}
                </Box>
              )}

              {schedule?.id && (
                <Box overflow="hidden">
                  <ScheduleContentEntity application={application} schedule={schedule} />
                </Box>
              )}
            </Stack>
          </AccordionCard>
        </Stack>
      </RequirementContainer>

      {showSelfScheduleOptionsModal && (
        <SelfScheduleOptionsModal
          isAvailabilityRequested={Boolean(availabilityRequestId)}
          applicationId={application.id}
          jobId={application.job?.id}
          candidateId={candidate.id}
          jobStageId={jobStageId}
          customJobStageId={customJobStageId}
          taskId={taskId}
          selfSchedule={selfScheduleRequest}
          selfScheduleOptionsModalPage={SelfScheduleOptionsModalPage.OPTIONS}
          onClose={(emailSent) => {
            setShowSelfScheduleOptionsModal(false);
            if (emailSent === undefined) {
              setShowSelfScheduleDialogs(false);
            } else {
              setShowSelfScheduleDialogs(!emailSent);
            }
          }}
          settings={jobStage.jobStageSettings}
        />
      )}

      {!availabilityRequestId && !isInterviewPlanValidResult.isValid && showIncompleteInterviewPlanAlert && (
        <IncompleteInterviewPlanAlert
          title={isInterviewPlanValidResult.title}
          message={
            isInterviewPlanValidResult.title === INCOMPLETE_INTERVIEW_PLAN ||
            isInterviewPlanValidResult.title === INTERVIEW_PLAN_WITH_MULTI_DAY_SCHEDULE ||
            isInterviewPlanValidResult.title === INTERVIEW_PLAN_WITH_DYNAMIC_BREAKS
              ? isInterviewPlanValidResult.error
              : 'Self-schedule is unavailable for interview plans with hidden events'
          }
          onClose={() => setShowIncompleteInterviewPlanAlert(false)}
        />
      )}

      {showSelfScheduleOpenAlert && selfScheduleRequest?.id && (
        <SelfScheduleOpenAlert
          selfScheduleOptionId={selfScheduleRequest.id}
          onClose={() => setShowSelfScheduleOpenAlert(false)}
          onContinue={handleScheduleNow}
        />
      )}

      {showCancelScheduleDialog && schedule && (
        <CancelSchedule
          applicationStage={schedule}
          open
          onClose={() => setShowCancelScheduleDialog(false)}
          onSuccess={() => {
            refetchScheduleTaskQuery();
            refetchactivityFeed();
          }}
          onError={() => setShowCancelScheduleDialog(false)}
        />
      )}
    </>
  );
};

Schedule.fragments = {
  application: gql`
    ${ScheduleContentEntity.fragments.application}
    ${selfScheduleOptionsFragment}
    ${useApplicationJobStageSchedulable.fragments.application}
    fragment Schedule_application on Application {
      id
      status
      atsType
      atsApplication {
        atsId
        atsFields {
          ... on WorkdayApplication {
            currentStepName
            __typename
          }
          __typename
        }
      }
      job {
        id
      }
      ...ScheduleContent_application
      ...useApplicationJobStageSchedulable_application
    }
  `,
  candidate: gql`
    fragment Schedule_candidate on Candidate {
      id
    }
  `,
  interviewPlan: gql`
    ${InterviewPlan.fragments.job}
    ${InterviewPlan.fragments.jobStage}
    ${useGetInterviewNamesFromFragment.fragments.interviews}
    ${getSaveInterviewPlanInput.fragments.interviewPlan}
    ${useIsInterviewPlanEntityValid.fragments.jobStage}
    fragment Schedule_interviewPlan on JobStage {
      id
      ...InterviewPlan_jobStage
      ...getSaveInterviewPlanInput_interviewPlan
      ...useIsInterviewPlanValid_jobStage
      job {
        id
        ...InterviewPlan_job
      }
      jobStageInterviewGroups {
        id
        jobStageInterviews {
          id
          ...useGetInterviewNames_interviews
        }
      }
    }
  `,
  jobStage: gql`
    ${SelfScheduleOptionsModal.fragments.settings}
    fragment Schedule_jobStage on JobStage {
      id
      jobStageSettings {
        ...SelfScheduleOptionSettings_JobStageSettings
      }
    }
  `,
  selfScheduleRequest: gql`
    ${SelfScheduleOptionsModal.fragments.selfSchedule}
    fragment Schedule_selfScheduleRequest on SelfScheduleOptions {
      ...SelfScheduleOptionsModal_selfSchedule
    }
  `,
  schedule: gql`
    ${CancelSchedule.fragment?.applicationStage}
    ${ScheduleContentEntity.fragments.schedule}
    fragment Schedule_schedule on ApplicationStage {
      ...CancelScheduleApplicationStage
      ...ScheduleContent_schedule
    }
  `,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ScheduleTaskInterviewPlanUpsertMutation = gql`
  ${InterviewPlan.fragments.jobStage}
  ${getSaveInterviewPlanInput.fragments.interviewPlan}
  mutation ScheduleTaskInterviewPlanUpsert($input: JobStageInterviewPlanUpsertInput!) {
    jobStageInterviewPlanUpsert(input: $input) {
      jobStage {
        id
        ...InterviewPlan_jobStage
        ...getSaveInterviewPlanInput_interviewPlan
      }
    }
  }
`;

export default Schedule;
