import urlcat from 'urlcat';

import { Routes } from 'src/constants/routes';

export enum EmployeeDetailsViewTabRoutes {
  INFO = '',
  MODULES = 'modules',
  INTERVIEWS = 'interviews',
}

const getInterviewerDetailsPageUrl = (
  interviewerId: string,
  tab: EmployeeDetailsViewTabRoutes = EmployeeDetailsViewTabRoutes.INFO
) => {
  if (tab) {
    return urlcat('', Routes.InterviewerDetails, { interviewerId, tab });
  }
  return urlcat('', Routes.InterviewerDetails, { interviewerId });
};

export default getInterviewerDetailsPageUrl;
