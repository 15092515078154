import React, { FC } from 'react';

import { snakeCaseToInitCase } from '@modernloop/shared/utils';
import { Alert, AlertTitle, Typography } from '@mui/material';

import { TrainingStatus } from 'src/generated/mloop-graphql';

type Props = { roleImpacted?: TrainingStatus | null };

const RoleChangeAlert: FC<Props> = ({ roleImpacted }) => {
  if (!roleImpacted) {
    return null;
  }
  return (
    <Alert severity="warning">
      <AlertTitle>Interviewer’s role will change</AlertTitle>
      <Typography variant="body2">
        Making this change will result in the interviewer being {snakeCaseToInitCase(roleImpacted).toLowerCase()}{' '}
        immediately, regardless of any required or pending approvals.
      </Typography>
    </Alert>
  );
};

export default RoleChangeAlert;
