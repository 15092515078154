import React from 'react';

import Label, { LabelProps } from 'src/components/label';

import IsoTimestamp from 'src/types/IsoTimestamp';

import useDurationLabel from './useDurationLabel';

export { default as useDurationLabel } from './useDurationLabel';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type DurationLabelProps = {
  timestamp: IsoTimestamp;
  labelProps?: LabelProps;
};

const DurationLabel = ({ timestamp, labelProps }: DurationLabelProps) => {
  const durationLabel = useDurationLabel(timestamp);

  return <Label {...labelProps}>{durationLabel}</Label>;
};

export default DurationLabel;
