import { useCallback } from 'react';

import { gql } from '@apollo/client';
import { max, min } from 'lodash';

import {
  InterviewMeetingLocationType,
  MeetingLocationCreateInput,
  useZoomInterviewMeetingLocationCreateMutation,
} from 'src/generated/mloop-graphql';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import {
  updateInterviewMeetingLocationId,
  updateVideoMeetingHostEmployeeIdForSlotId,
  updateVideoMeetingLinkHostEmployeeId,
  updateZoomInfo,
  updateZoomInfoForSlotId,
  updateZoomUserId,
  updateZoomUserIdForSlotId,
  updateinterviewMeetingLocationIdBySlotId,
} from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import { removeTypename } from 'src/utils/removeTypename';

import { useDispatch } from 'src/store';

type Props = {
  scheduleId: string;
};

const usePopulateZoomInterviewMeetingLocation = ({ scheduleId }: Props) => {
  const dispatch = useDispatch();
  const schedule = useScheduleWithoutBreaks(scheduleId);

  const [interviewMeetingLocationCreateMutation] = useZoomInterviewMeetingLocationCreateMutation();

  const callback = useCallback(
    async ({
      zoomUserId,
      hostEmployeeId,
      slotId,
    }: {
      zoomUserId: string;
      hostEmployeeId?: string;
      slotId?: string;
    }) => {
      // If slotId is provided, we are creating a meeting for a specific interview
      if (slotId) {
        const interviewEvent = schedule?.events.find((event) => event.slotId === slotId);
        if (!interviewEvent) return;

        const meetingLocationCreateInput: MeetingLocationCreateInput = {
          locationType: InterviewMeetingLocationType.Zoom,
          videoMeeting: {
            timeRange: { startAt: interviewEvent.startAt, endAt: interviewEvent.endAt },
            title: interviewEvent.name,
            remoteVideoHostUserId: zoomUserId,
            hostEmployeeId,
          },
        };

        const { data } = await interviewMeetingLocationCreateMutation({
          variables: {
            input: meetingLocationCreateInput,
          },
        });

        if (data?.meetingLocationCreate?.meetingLocation?.videoMeetingLink) {
          const { meetingLocation } = data.meetingLocationCreate;

          dispatch(
            updateinterviewMeetingLocationIdBySlotId({ scheduleId, slotId, meetingLocationId: meetingLocation.id })
          );

          if (hostEmployeeId) {
            dispatch(
              updateVideoMeetingHostEmployeeIdForSlotId({
                scheduleId,
                slotId,
                videoMeetingHostEmployeeId: hostEmployeeId,
              })
            );
          }

          dispatch(updateZoomUserIdForSlotId(scheduleId, slotId, zoomUserId));

          dispatch(
            updateZoomInfoForSlotId(scheduleId, slotId, {
              joinURL: meetingLocation.videoMeetingLink || '',
              meetingID: meetingLocation.details?.zoomInfo?.meetingId || '',
              password: meetingLocation.details?.zoomInfo?.password || '',
              pstnPassword: meetingLocation.details?.zoomInfo?.pstnPassword
                ? parseInt(meetingLocation.details?.zoomInfo?.pstnPassword, 10)
                : undefined,
              dialInfo:
                meetingLocation.details?.zoomInfo?.dialInfo?.map((dialInfo) => ({
                  city: dialInfo.city || '',
                  country: dialInfo.country || '',
                  countryName: dialInfo.countryName || '',
                  number: dialInfo.number || '',
                  type: dialInfo.type || '',
                })) || [],
            })
          );

          dispatch(
            addInterviewScheduleUpdate({
              type: 'ScheduleUpdateVideoUrl',
              updateType: ScheduleUpdateType.EDIT,
              scheduleId,
              applicationStageInterviewId: interviewEvent.id,
            })
          );
        }
      } else if (schedule) {
        // If slotId is not provided, we are creating a meeting for the whole schedule
        const startAt = min(schedule.events.map((event) => event.startAt));
        const endAt = max(schedule.events.map((event) => event.endAt));

        const meetingLocationCreateInput: MeetingLocationCreateInput = {
          locationType: InterviewMeetingLocationType.Zoom,
          videoMeeting: { timeRange: { startAt, endAt }, hostEmployeeId, remoteVideoHostUserId: zoomUserId },
        };

        const { data } = await interviewMeetingLocationCreateMutation({
          variables: {
            input: meetingLocationCreateInput,
          },
        });

        const meetingLocation = data?.meetingLocationCreate?.meetingLocation;

        if (meetingLocation?.id) {
          dispatch(updateInterviewMeetingLocationId(scheduleId, meetingLocation.id));
        }

        if (hostEmployeeId) {
          dispatch(updateVideoMeetingLinkHostEmployeeId(scheduleId, hostEmployeeId));
        }

        const zoomInfo = meetingLocation?.details?.zoomInfo;

        dispatch(updateZoomUserId(scheduleId, zoomUserId));
        dispatch(
          updateZoomInfo(scheduleId, {
            joinURL: data?.meetingLocationCreate?.meetingLocation?.videoMeetingLink || '',
            meetingID: zoomInfo?.meetingId || '',
            password: zoomInfo?.password || '',
            pstnPassword: zoomInfo?.pstnPassword as unknown as number,
            dialInfo: zoomInfo?.dialInfo
              ? removeTypename(zoomInfo.dialInfo).map((info) => ({
                  type: info.type,
                  number: info.number,
                  city: info.city,
                  countryName: info.countryName,
                  country: info.country,
                }))
              : undefined,
          })
        );

        schedule?.events?.forEach((interview) => {
          dispatch(
            addInterviewScheduleUpdate({
              type: 'ScheduleUpdateVideoUrl',
              updateType: ScheduleUpdateType.EDIT,
              scheduleId: schedule?.id || '',
              applicationStageInterviewId: interview.id,
            })
          );
        });
      }
    },
    [dispatch, interviewMeetingLocationCreateMutation, schedule, scheduleId]
  );

  return callback;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const InterviewMeetingLocationCreateMutation = gql`
  mutation ZoomInterviewMeetingLocationCreate($input: MeetingLocationCreateInput!) {
    meetingLocationCreate(input: $input) {
      meetingLocation {
        id
        type
        videoMeetingLink
        details {
          zoomInfo {
            password
            meetingId
            userId
            pstnPassword
            dialInfo {
              type
              number
              city
              countryName
              country
            }
          }
        }
      }
    }
  }
`;

export default usePopulateZoomInterviewMeetingLocation;
