import { useIdentifyAmplitudeUser } from 'src/utils/logger/identifyAmplitudeUser';
import { useIdentifyDatadogUser } from 'src/utils/logger/identifyDataDogUser';
import { useIdentifyLaunchDarklyUser } from 'src/utils/logger/identifyLaunchDarklyUser';
import { useIdentifyLogRocketUser } from 'src/utils/logger/identifyLogRocketUser';
import { useIdentifySentryUser } from 'src/utils/logger/identifySentryUser';

import useAuth from './useAuth';

export * from 'src/constants/logger';

/**
 * Hook to setup user identities in all of our logging systems.
 * Must be called within a child of AuthProvider and CookieConsentProvider.
 */
const useIdentifyLoggers = () => {
  const { userInfo } = useAuth();
  useIdentifyLaunchDarklyUser(userInfo);
  useIdentifySentryUser(userInfo);
  useIdentifyLogRocketUser(userInfo);
  useIdentifyAmplitudeUser(userInfo);
  useIdentifyDatadogUser(userInfo);
};

export default useIdentifyLoggers;
