import { Conflict } from 'src/store/slices/conflicts';

import { State } from 'src/store';

export const getConflictsById = (state: State): { [eventUid: string]: Conflict } => state.conflicts.byId;

export const getHardConflicts = (state: State): Conflict[] => {
  const { byId } = state.conflicts;
  const conflicts: Conflict[] = [];

  Object.keys(byId).forEach((eventUid) => {
    if (!byId[eventUid].isHardConflict) return;
    conflicts.push(byId[eventUid]);
  });

  return conflicts;
};
