import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';

import { ActivityLogApplicationCandidatePortalNoteDeleted_ActivityLogFragment } from 'src/generated/mloop-graphql';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';

type Fragments = {
  activityLog: ActivityLogApplicationCandidatePortalNoteDeleted_ActivityLogFragment;
};

const ActivityLogApplicationCandidatePortalNoteDeleted: FCWithFragments<Fragments> = ({ activityLog }) => {
  // eslint-disable-next-line no-underscore-dangle
  if (!activityLog.details || activityLog.details.__typename !== 'ActivityLogCandidatePortalNoteDetails') {
    throw new Error('ActivityLogCandidatePortalNoteDetails: details is not ActivityLogCandidatePortalNoteDetails');
  }

  return <ActivityLogBase activityLog={activityLog} actionLabel="removed the candidate portal note" />;
};

ActivityLogApplicationCandidatePortalNoteDeleted.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogApplicationCandidatePortalNoteDeleted_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogCandidatePortalNoteDetails {
          candidatePortalNoteId
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogApplicationCandidatePortalNoteDeleted;
