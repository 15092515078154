import React, { useMemo } from 'react';

import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  SxProps,
} from '@mui/material';

import { BaseProps, createSxProps } from 'src/components/types';

import { Theme } from 'src/themeMui5/type';

import Label from '../label';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type SwitchProps = BaseProps & {
  /**
   * If true, the component is checked.
   */
  checked?: boolean;
  /**
   * function(boolean) => void
   * event: The event source of the callback. Passes the checked state directly into the onChange handler.
   */
  onChange?: (value: boolean) => void;

  /**
   * If true, the switch will be disabled.
   */
  disabled?: boolean;

  /**
   * The text to be used in an enclosing label element.
   */
  label?: React.ReactNode;
  /**
   * Caption
   */
  caption?: React.ReactNode;

  /**
   * If set then the label will take full width of its container.
   */
  fullWidth?: boolean;

  /**
   * Placement of the label relative to switch control.
   */
  labelPlacement?: FormControlLabelProps['labelPlacement'];

  /**
   * Styles applied to the form element when label is used.
   */
  sxFormControl?: SxProps<Theme>;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface SwitchSxProps extends MuiSwitchProps {
  fullWidth?: boolean;
}

const useSxProps = (props: SwitchSxProps) => {
  const { disabled, fullWidth } = props;

  const sxProps = useMemo(() => {
    return {
      switch: {
        flex: '0 0 auto',
        width: '36px',
        height: '20px',
        padding: 0,
        borderRadius: '10px',
        backgroundColor: (theme: Theme) => theme.palette.background.paper,
        outlineColor: (theme: Theme) => theme.palette.border,
        '& .MuiSwitch-switchBase': {
          padding: '4px',
          '&.Mui-checked': (theme: Theme) => ({
            transform: 'translateX(16px)',
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
              border: 'none',
            },
            '&.Mui-disabled': {
              transform: 'translateX(16px)',
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.action.disabled,
                opacity: 1,
                border: 'none',
              },
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: disabled ? theme.palette.action.disabled : theme.palette.background.default,
            },
          }),
        },
        '.MuiSwitch-thumb': {
          width: '12px',
          height: '12px',
          borderRadius: '6px',
          backgroundColor: (theme: Theme) => (disabled ? theme.palette.action.disabled : theme.grey.alpha.max),
        },
        '.MuiSwitch-track': (theme: Theme) => ({
          backgroundColor: theme.palette.background.default,
          outline: `1px solid ${theme.grey.alpha.low}`,
          outlineOffset: '-1px',
          opacity: 1,
          borderRadius: '10px',
        }),
      } as SxProps,
      switchContainer: {
        '&:hover': () => {
          if (!disabled)
            return {
              backgroundColor: (theme: Theme) => theme.palette.action.hover,
              cursor: 'pointer',
            };
          return {};
        },
        '.MuiFormControlLabel-label': {
          whiteSpace: 'normal',
          overflow: 'auto',
          marginRight: '8px',
          marginLeft: '8px',
          flex: fullWidth ? 1 : '0 0 auto',
        },
        borderRadius: '6px',
        whitespace: 'normal',
        overflow: 'auto',
        marginLeft: '-8px',
        padding: '5px 8px',
      },
      switchContainerFullWidth: {
        width: `calc(100% + 16px)`,
      },
    };
  }, [disabled, fullWidth]);

  return createSxProps(sxProps);
};

/**
 * @deprecated - Use `Switch` from `@mui/material` instead
 */
const Switch = ({
  checked,
  disabled,
  onChange,
  label,
  labelPlacement,
  caption,
  fullWidth,
  sxFormControl,
  dataTestId,
}: SwitchProps): JSX.Element => {
  const sxProps = useSxProps({ disabled, fullWidth });
  const sxFormControlLabelProps = useMemo(
    () => ({
      ...sxProps.switchContainer,
      ...(fullWidth ? sxProps.switchContainerFullWidth : {}),
      ...(sxFormControl || {}),
    }),
    [fullWidth, sxFormControl, sxProps.switchContainer, sxProps.switchContainerFullWidth]
  );
  const handleChange = () => {
    if (!onChange) return;
    onChange(!checked);
  };

  const switchComponent = (
    <MuiSwitch
      size="small"
      data-testid={dataTestId ?? 'component-switch-mui-switch'}
      checked={checked}
      disabled={disabled}
      onChange={handleChange}
    />
  );

  if (label) {
    let labelContent = label;
    if (typeof label === 'string') {
      labelContent = (
        <Label variant="body" color={disabled ? 'high-contrast-grey' : 'foreground'}>
          {label}
        </Label>
      );
    }

    let captionContent = caption;
    if (typeof captionContent === 'string') {
      captionContent = (
        <Label variant="captions" color={disabled ? 'high-contrast-grey' : 'max-contrast-grey'}>
          {caption}
        </Label>
      );
    }

    const labelJsx = (
      <Box>
        {labelContent}
        {captionContent}
      </Box>
    );
    return (
      <FormControlLabel
        data-testId="switch-container"
        sx={sxFormControlLabelProps}
        disabled={disabled}
        control={switchComponent}
        label={labelJsx}
        labelPlacement={labelPlacement ?? 'start'}
      />
    );
  }

  return switchComponent;
};

export default Switch;
