/* eslint-disable max-lines */
import { useCallback, useMemo } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import * as Sentry from '@sentry/react';
import { toArray } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  TaskCreationSource,
  UseSubmitCreateScheduleRest_InterviewPlanFragment,
  useCreateScheduleSaveInterviewPlanMutation,
  useScheduleNowTaskCreateMutation,
} from 'src/generated/mloop-graphql';

import getSaveInterviewPlanInput from 'src/entities/InterviewPlan/getSaveInterviewPlanInput';

import { useCreateLocationHeader } from 'src/hooks/amplitude/useCreateLocationHeader';
import useEmployeeId from 'src/hooks/useEmployeeId';
import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';
import { SearchParamKnownKeys, useUrlSearchParams } from 'src/hooks/useUrlSearchParams';

import { FileStatus, FileUploadFlow, UIFile } from 'src/slices/files';
import slice, {
  getInterviewers,
  getSubmitErrorMessage,
  populateEventData,
  validSlackChannelName,
  validateCandidateComms,
} from 'src/slices/scheduling';

import {
  setApplicationStageIdByScheduleId,
  updateDebriefRescheduleDetails,
} from 'src/store/actions/schedule-communications';
import { getAllJobStageInterviewByJobStageId } from 'src/store/selectors/job-stage-interview';
import {
  getApplicationStageIdByScheduleId,
  getScheduleContent,
  getVideoMeetingLinkHostEmployeeId,
  getVideoMeetingLinkHostEmployees,
} from 'src/store/selectors/schedule-communications';

import cancelSchedule from 'src/utils/api/cancelSchedule';
import updateCandidateAvailability from 'src/utils/api/candidate/updateCandidateAvailability';
import createSchedule, { Interviewer, InterviewerContent, RequestBody } from 'src/utils/api/createSchedule';
import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';
import { getErrorStatus, getExternalErrorMessage, getInternalErrorMessage } from 'src/utils/error';
import htmlToMrkdwn from 'src/utils/htmlToMrkdwn';
import logMessage from 'src/utils/logMessage';

import useRefetchTaskListQuery from 'src/views-new/ApplicationDetailsTaskView/ApplicationContent/useRefetchTaskList';
import useSaveInterviewPlan, { useSaveInterviewPlanInput } from 'src/views-new/InterviewPlan/useSaveInterviewPlan';
import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import store, { useDispatch, useSelector } from 'src/store';

import useGetInterviewers from './useGetInterviewers';
import usePopulateEventDataFromFragment from './usePopulateEventData';

type Options = {
  taskQueueId: string;
};

type Fragments = {
  interviewPlan: UseSubmitCreateScheduleRest_InterviewPlanFragment | undefined;
};

type Props = {
  isReschedule: boolean;
  options: Options;
};

const useSubmitCreateScheduleRest: FunctionWithFragments<
  Fragments,
  Props,
  (onComplete: () => void, internalOnly?: boolean) => Promise<void>
> = ({ interviewPlan }, { isReschedule, options }) => {
  const thisEmployeeId = useEmployeeId();
  const dispatch = useDispatch();
  const refetchTaskList = useRefetchTaskListQuery();

  const interviewPlanEntityInScheduleFlowsEnabled = useFlag('interview_plan_entity_in_schedule_flows');
  const orgBreakImprovementsEnabled = useFlag('org_break_improvements');
  const scheduleFlowData = useScheduleFlowData();

  const urlSearchParams = useUrlSearchParams();
  const urlJobStageId = urlSearchParams.get(SearchParamKnownKeys.jobStageId) ?? undefined;
  const urlTaskId = urlSearchParams.get(SearchParamKnownKeys.taskId) ?? undefined;

  const createLocationHeader = useCreateLocationHeader();
  const [createTask] = useScheduleNowTaskCreateMutation({
    context: {
      headers: {
        ...createLocationHeader,
      },
    },
  });

  const storeState = useSelector((state) => state);
  const { scheduling, fileUpload, scheduleCommunications } = storeState;
  const { selectedScheduleId } = scheduling;
  const scheduleContent = useSelector(getScheduleContent);

  const getInterviewersFromEvent = useGetInterviewers({ interviewPlan }, { scheduleId: selectedScheduleId || '' });

  const {
    applicationId,
    applicationStageId,
    candidateTimezone,
    interviewPlanJobStageId,
    jobStageId,
    taskId: existingTaskId,
  } = scheduleFlowData;

  const storeAllJobStageInterviewIds = useSelector((state) =>
    getAllJobStageInterviewByJobStageId(state, interviewPlanJobStageId)
  ).map((i) => i.id);

  const fragmentAllJobStageInterviewIds = useMemo(
    () =>
      (interviewPlan?.jobStageInterviewGroups?.map((group) => group.jobStageInterviews || []).flat() || []).map(
        (i) => i.id
      ),
    [interviewPlan?.jobStageInterviewGroups]
  );

  const allJobStageInterviewIds = interviewPlanEntityInScheduleFlowsEnabled
    ? fragmentAllJobStageInterviewIds
    : storeAllJobStageInterviewIds;

  const newCustomJobStageId = useMemo(() => {
    return uuid();
  }, []);
  const storeGroups = useSaveInterviewPlanInput(
    !existingTaskId ? urlJobStageId || interviewPlanJobStageId || '' : interviewPlanJobStageId,
    false /* generateNewIds */,
    !existingTaskId ? newCustomJobStageId : undefined
  );

  const fragmentGroups = getSaveInterviewPlanInput(
    { interviewPlan: interviewPlan || null },
    {
      finalJobStageId: existingTaskId ? interviewPlan?.id : newCustomJobStageId,
      generateNewIds: !existingTaskId,
    }
  );

  const groups = interviewPlanEntityInScheduleFlowsEnabled ? fragmentGroups.groups : storeGroups;

  let storeSchedule = useScheduleWithoutBreaks(selectedScheduleId);
  storeSchedule = populateEventData(
    storeState,
    interviewPlanJobStageId,
    storeSchedule as InterviewSchedule
  ) as unknown as InterviewSchedule;

  const fragmentSchedule = usePopulateEventDataFromFragment({ interviewPlan }, { scheduleId: selectedScheduleId });

  const schedule = interviewPlanEntityInScheduleFlowsEnabled ? fragmentSchedule : storeSchedule;

  const { candidateAvailabilities } = scheduleFlowData;
  const videoMeetingHostEmployeeIds = useSelector((state) =>
    getVideoMeetingLinkHostEmployees(state, selectedScheduleId)
  );
  const videoMeetingHostEmployeeId = useSelector((state) =>
    getVideoMeetingLinkHostEmployeeId(state, selectedScheduleId)
  );

  // Save interview plan mutation will only be called when we have a task present.
  const [storeSaveInterviewPlanMutation] = useSaveInterviewPlan({
    jobStageId: interviewPlanJobStageId,
    customJobStageId: urlJobStageId || (!urlTaskId && !existingTaskId) ? newCustomJobStageId : undefined,
  });

  const [fragmentSaveInterviewPlanMutation] = useCreateScheduleSaveInterviewPlanMutation({
    variables: {
      input: {
        jobStageId: existingTaskId ? interviewPlanJobStageId : newCustomJobStageId,
        groups: fragmentGroups.groups,
        schedulingWindow:
          orgBreakImprovementsEnabled && fragmentGroups.schedulingWindow
            ? { seconds: fragmentGroups.schedulingWindow.seconds }
            : undefined,
        excludedEmployeeIds: orgBreakImprovementsEnabled ? fragmentGroups.excludedEmployeeIds : undefined,
      },
    },
  });

  const saveInterviewPlanMutation = interviewPlanEntityInScheduleFlowsEnabled
    ? fragmentSaveInterviewPlanMutation
    : storeSaveInterviewPlanMutation;

  const inputApplicationStageId = useSelector((state) =>
    getApplicationStageIdByScheduleId(state, selectedScheduleId || '')
  );

  const finalInputApplicationStageId = useMemo(() => {
    return inputApplicationStageId || uuid();
  }, [inputApplicationStageId]);

  const callback = useCallback(
    async (onComplete: () => void, internalOnly?: boolean) => {
      dispatch(slice.actions.setScheduleContentStepSubmitting());

      if (!schedule || !selectedScheduleId) {
        dispatch(slice.actions.setScheduleContentStepError({ error: 'No schedule selected' }));
        dispatch(slice.actions.clearScheduleContentStepSubmitting());
        return;
      }

      const frontendError = getSubmitErrorMessage(storeState, internalOnly);
      if (frontendError) {
        dispatch(slice.actions.setScheduleContentStepError({ error: frontendError }));
        dispatch(slice.actions.clearScheduleContentStepSubmitting());
        return;
      }

      const getMeetingRoomIds = (eventId: string): string[] | undefined => {
        if (!selectedScheduleId) return [];
        if (scheduleCommunications.byId[selectedScheduleId]?.meetingRoomSuggestionsPerInterviewEvent[eventId]) {
          return scheduleCommunications.byId[selectedScheduleId]?.meetingRoomSuggestionsPerInterviewEvent[eventId]
            .map((room) => room.room?.id)
            .filter((id) => Boolean(id)) as string[];
        }

        if (!scheduleCommunications.byId[selectedScheduleId]?.commonMeetingRoomSuggestionByEvent[eventId]) {
          return undefined;
        }

        return scheduleCommunications.byId[selectedScheduleId]?.commonMeetingRoomSuggestionByEvent[eventId]
          .map((room) => room.room?.id)
          .filter((id) => Boolean(id)) as string[];
      };

      const fragmentInterviews =
        interviewPlan?.jobStageInterviewGroups?.map((group) => group.jobStageInterviews || []).flat() || [];

      const InterviewerEvent = scheduleContent
        ? (scheduleContent.interviewerEventContents
            .map((item) => {
              let { event } = item;

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              event = schedule?.events?.find((e) => e.id === item.event.id) as any;

              const storeJobStageInterviewId =
                event.slotId && allJobStageInterviewIds.includes(event.slotId) ? event.slotId : undefined;

              const fragmentJobStageInterview = fragmentInterviews.find((i) => i.id === event.slotId);

              const jobStageInterviewId = interviewPlanEntityInScheduleFlowsEnabled
                ? fragmentJobStageInterview?.id
                : storeJobStageInterviewId;

              const storeInterviewers: Interviewer[] = getInterviewers({
                event,
                getState: store.getState,
                interviewPlanJobStageId,
              }).map((interviewer) => ({
                interviewerId: interviewer.interviewer_id as string,
                role: interviewer.role as string,
                interviewId: interviewer.interview_id as string,
                isOptional: interviewer.is_optional as boolean,
                jobStageInterviewSeatId: interviewer.job_stage_interview_seat_id as string,
              }));

              const fragmentInterviewers = getInterviewersFromEvent(event).map((interviewer) => ({
                interviewerId: interviewer.employeeId as string,
                role: interviewer.role as string,
                interviewId: interviewer.interviewModuleId as string,
                isOptional: interviewer.isOptional as boolean,
                jobStageInterviewSeatId: interviewer.jobStageInterviewSeatId as string,
              }));

              const interviewers = interviewPlanEntityInScheduleFlowsEnabled ? fragmentInterviewers : storeInterviewers;

              const interview: InterviewerContent = {
                interviewer: interviewers,
                videoMeetingUrl: item.location || '',
                startAt: new Date(event.startAt).toISOString(),
                endAt: new Date(event.endAt).toISOString(),
                summary: item.summary,
                description: item.description,
                codingInterviewUrl: item.codingInterviewURL || '',
                interviewName: event.name || '',
                jobStageInterviewId,
                meetingRoomIds: event.id ? getMeetingRoomIds(event.id) : undefined,
                isHiddenFromCandidate: event.isHiddenFromCandidate,
                videoMeetingHostEmployeeId:
                  (event.slotId ? videoMeetingHostEmployeeIds[event.slotId] : undefined) || videoMeetingHostEmployeeId,
              };

              if (item.zoomInfo && item.zoomUserId) {
                interview.zoomInfo = {
                  userId: item.zoomUserId,
                  password: item.zoomInfo.password,
                  meetingId: item.zoomInfo.meetingID,
                  pstnPassword: item.zoomInfo.pstnPassword,
                  dialInfo: item.zoomInfo.dialInfo,
                };
              }

              if (item.isCoderPad) {
                interview.codingInterviewUrl = item.codingInterviewURL || '';
              }

              if (event.atsInterviewDefinitionId) {
                interview.atsInterviewDefinitionId = event.atsInterviewDefinitionId;
              }

              if (item.emailTemplateID) {
                interview.emailTemplateId = item.emailTemplateID;
              }

              return interview;
            })

            .filter((interview) => interview !== null) as InterviewerContent[])
        : [];

      if (!inputApplicationStageId) {
        dispatch(setApplicationStageIdByScheduleId(selectedScheduleId, finalInputApplicationStageId));
      }

      const data: RequestBody = {
        applicationId,
        interviewerCalendarId: scheduling.interviewerCalendarId || '',
        candidateCalendarId: scheduling.candidateCalendarId || '',
        candidateTimezone,
        interviewerContents: InterviewerEvent,
        isPrivateCalendarEvent: scheduling.isPrivateCalendarEvent,
        isReschedule,
        applicationStageId: finalInputApplicationStageId,
      };

      if (scheduling.candidateCommunicationsEnabled && !internalOnly) {
        const attachments = toArray(fileUpload[FileUploadFlow.SCHEDULING])
          .filter((file: UIFile) => file.status === FileStatus.UPLOADED)
          .map((file: UIFile) => {
            return { name: file.name, path: file.path || '' };
          });

        data.candidateEmailContent = {
          toEmailAddress: scheduling.selectedToEmailAddress || '',
          ccEmployeeIds: scheduling.ccEmployeeIDs,
          bccEmployeeIds: scheduling.bccEmployeeIDs,
          ccExternalAddresses: scheduling.ccExternalAddresses,
          bccExternalAddresses: scheduling.bccExternalAddresses,
          description: scheduleContent?.candidateEventContent?.description || '',
          summary: scheduleContent?.candidateEventContent?.summary || '',
          emailTemplateId: scheduleContent?.candidateEventContent?.emailTemplateID || '',
          attachments,
        };
      }

      if (scheduling.candidateEventCommunicationsEnabled && !internalOnly) {
        data.candidateEventContent = {
          toEmailAddress: scheduling.selectedToEmailAddress || '',
          summary: scheduling.hasSameContent
            ? scheduleContent?.candidateEventContent.summary || ''
            : scheduleContent?.candidateCalendarEventContent.subject || '',
          description: scheduling.hasSameContent
            ? scheduleContent?.candidateEventContent.description || ''
            : scheduleContent?.candidateCalendarEventContent.body || '',
          videoMeetingUrl: scheduling.hasSameContent
            ? scheduleContent?.candidateEventContent?.location || ''
            : scheduleContent?.candidateCalendarEventContent.location || '',
          calendarEventTemplateId: scheduleContent?.candidateCalendarEventContent.templateID || '',
        };
      }

      const scheduleCommsData = scheduleCommunications.byId[selectedScheduleId] || {};

      if (scheduleCommsData?.slackChannelEnabled) {
        data.slackChannelContent = {
          slackChannelInterviewerEmployeeIds: scheduleCommsData.slackChannelInterviewerEmployeeIDs,
          slackChannelRecruitingTeamEmployeeIds: scheduleCommsData.slackChannelRecruitingTeamEmployeeIDs,
          slackChannelHiringManagerEmployeeIds: scheduleCommsData.slackChannelHiringManagerEmployeeIDs,
          slackChannelMessageContent: htmlToMrkdwn(scheduleCommsData.slackChannelContent || ''),
        };

        /*
          Logic -
          - if there's a remote id, user has selected an existing channel
          - if there's a conversation id, user has selected a previously used channel
          - if there's only a name, they are creating a new slack channel
        */
        if (scheduleCommsData.slackChannelRemoteId) {
          data.slackChannelContent.slackChannelId = scheduleCommsData.slackChannelRemoteId;
        } else if (scheduleCommsData.conversationId) {
          data.slackChannelContent.conversationId = scheduleCommsData.conversationId;
        } else {
          data.slackChannelContent.slackChannelName = scheduleCommsData.slackChannelName;
        }
      }

      try {
        const toEmailAddress = scheduling.selectedToEmailAddress ?? '';
        validateCandidateComms(
          toEmailAddress,
          scheduling.candidateCommunicationsEnabled,
          scheduling.candidateEventCommunicationsEnabled
        );
        validSlackChannelName(data.slackChannelContent?.slackChannelName);

        let taskId = urlTaskId || scheduleFlowData.taskId || scheduleFlowData.createdTaskId;

        if (!taskId) {
          const taskResult = await createTask({
            variables: {
              input: {
                applicationId,
                jobStageId,
                assigneeEmployeeId: thisEmployeeId,
                isUrgent: false,
                isDebriefRequired: false,
                creationSource: TaskCreationSource.ScheduleNow,
                customInterviewPlan: groups,
                customJobStageId: newCustomJobStageId,
                candidateTimezone,
                availability: candidateAvailabilities,
                taskQueueId: options.taskQueueId ?? '',
              },
            },
          });
          taskId = taskResult?.data?.taskCreate?.task?.id;
          if (taskId) {
            scheduleFlowData.setCreatedTaskId(taskId);
          }
        }

        if (taskId) {
          data.taskId = taskId;
        }

        const response = await createSchedule(data);
        onComplete();
        refetchTaskList();

        const hasAttendeeAdded = response.debrief_reschedule_details?.has_attendee_added;
        const hasScheduleTimeAfterDebriefStartAt =
          response.debrief_reschedule_details?.has_schedule_time_after_debrief_start_at;

        dispatch(
          updateDebriefRescheduleDetails(selectedScheduleId, hasAttendeeAdded, hasScheduleTimeAfterDebriefStartAt)
        );

        if (existingTaskId) {
          try {
            await Promise.all([
              saveInterviewPlanMutation(),
              updateCandidateAvailability({
                applicationId,
                jobStageId: interviewPlanJobStageId,
                candidateTimezone,
                candidateAvailability: candidateAvailabilities.map((availability) => ({
                  start: availability.startAt,
                  end: availability.endAt,
                })),
                taskId: existingTaskId,
              }),
            ]);
          } catch (error) {
            logMessage(
              'Error saving candidate availability & interview plan to task after creating schedule',
              error.message
            );
          }
        }
        dispatch(slice.actions.setScheduleContentStepError({ error: null }));

        if (isReschedule && applicationStageId) {
          await cancelSchedule({ applicationStageId, isReschedule });
        }
      } catch (error) {
        const externalErrorMessage = getExternalErrorMessage(error, /* recommend try again */ false);
        const internalErrorMessage = getInternalErrorMessage(error);

        dispatch(slice.actions.setScheduleContentStepError({ error: externalErrorMessage }));

        if (getErrorStatus(error) >= 500) {
          Sentry.withScope(() => {
            Sentry.setTag('category', 'createScheduleError');
            Sentry.setTag('external_error', externalErrorMessage);
            Sentry.setTag('internal_error', internalErrorMessage);
            logMessage('Customer received 5XX error when trying to create a schedule', 'fatal', { error });
          });
          return; // dont re-enable submit
        }
      }

      dispatch(slice.actions.clearScheduleContentStepSubmitting());
    },
    [
      dispatch,
      schedule,
      selectedScheduleId,
      storeState,
      interviewPlan?.jobStageInterviewGroups,
      scheduleContent,
      inputApplicationStageId,
      applicationId,
      scheduling.interviewerCalendarId,
      scheduling.candidateCalendarId,
      scheduling.isPrivateCalendarEvent,
      scheduling.candidateCommunicationsEnabled,
      scheduling.candidateEventCommunicationsEnabled,
      scheduling.selectedToEmailAddress,
      scheduling.ccEmployeeIDs,
      scheduling.bccEmployeeIDs,
      scheduling.ccExternalAddresses,
      scheduling.bccExternalAddresses,
      scheduling.hasSameContent,
      candidateTimezone,
      isReschedule,
      finalInputApplicationStageId,
      scheduleCommunications.byId,
      allJobStageInterviewIds,
      interviewPlanEntityInScheduleFlowsEnabled,
      interviewPlanJobStageId,
      getInterviewersFromEvent,
      videoMeetingHostEmployeeIds,
      videoMeetingHostEmployeeId,
      fileUpload,
      urlTaskId,
      scheduleFlowData,
      refetchTaskList,
      existingTaskId,
      applicationStageId,
      createTask,
      jobStageId,
      thisEmployeeId,
      groups,
      newCustomJobStageId,
      candidateAvailabilities,
      options.taskQueueId,
      saveInterviewPlanMutation,
    ]
  );

  return callback;
};

useSubmitCreateScheduleRest.fragments = {
  interviewPlan: gql`
    ${useGetInterviewers.fragments.interviewPlan}
    ${getSaveInterviewPlanInput.fragments.interviewPlan}
    ${usePopulateEventDataFromFragment.fragments.interviewPlan}
    fragment useSubmitCreateScheduleRest_interviewPlan on JobStage {
      id
      ...useGetInterviewers_interviewPlan
      ...getSaveInterviewPlanInput_interviewPlan
      ...usePopulateEventData_interviewPlan
    }
  `,
};

export default useSubmitCreateScheduleRest;
