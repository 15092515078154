import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ApolloError } from '@apollo/client';

import { ResponseStatus, useInterviewInterviewerDeclinedLazyQuery } from 'src/generated/mloop-graphql';

import { InterviewerResponseStatus } from 'src/utils/api/getScheduleOptions';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

export type InterviewerDeclinedResultType = { loading?: boolean; error?: ApolloError };

const useFetchInterviewResponseStatus = (
  scheduleFlowType
): [InterviewerResponseStatus[] | undefined, InterviewerDeclinedResultType] => {
  const { applicationStageID } = useParams<{ applicationStageID: string }>();
  const [fetchInterviewInterviewerDeclined, interviewerDeclinedResult] = useInterviewInterviewerDeclinedLazyQuery();
  const { data: interviewerDeclinedData } = interviewerDeclinedResult;

  useEffect(() => {
    if (scheduleFlowType !== ScheduleFlowType.RESCHEDULE) return;
    fetchInterviewInterviewerDeclined({ variables: { id: applicationStageID } });
  }, [applicationStageID, fetchInterviewInterviewerDeclined, scheduleFlowType]);

  if (scheduleFlowType !== ScheduleFlowType.RESCHEDULE) {
    return [undefined, { loading: interviewerDeclinedResult.loading, error: interviewerDeclinedResult.error }];
  }

  const responseStatuses: InterviewerResponseStatus[] = [];
  interviewerDeclinedData?.applicationStage?.applicationStageInterviews?.forEach((asi) => {
    asi?.applicationStageInterviewInterviewers?.forEach((interviewer) => {
      if (interviewer?.declines?.length && interviewer.interviewerId && asi.startAt && asi.endAt) {
        responseStatuses.push({
          employeeId: interviewer.interviewerId,
          startAt: asi?.startAt,
          endAt: asi?.endAt,
          responseStatus: ResponseStatus.Declined,
        });
      }
    });
  });

  return [responseStatuses, { loading: interviewerDeclinedResult.loading, error: interviewerDeclinedResult.error }];
};

export default useFetchInterviewResponseStatus;
