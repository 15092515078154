import { useEffect } from 'react';

import LogRocket from 'logrocket';

import type { UserInfo } from 'src/contexts/auth0/Auth0Context';

/**
 * Identifies the user in LogRocket. Sends name, user, email and organization,
 * Our dashboard: https://app.logrocket.com/eg3oxb/modernloop
 * LogRocket API Docs: https://docs.logrocket.com/reference/identify
 *
 * @param userInfo from useAuth();
 * @returns void
 */
export function identifyLogRocketUser(userInfo: UserInfo | null): void {
  if (!userInfo || !userInfo.userId) {
    return;
  }

  if (!userInfo.orgId) {
    return;
  }

  LogRocket.identify(userInfo.userId, {
    name: userInfo.userName ?? '',
    organizationName: userInfo.orgName ?? '',
  });
}

/**
 * Identifies the user in LogRocket. Sends name, user, email and organization,
 * Must be called within a child of AuthProvider and CookieConsentProvider.
 */
export const useIdentifyLogRocketUser = (userInfo: UserInfo | null) => {
  useEffect(
    () => {
      identifyLogRocketUser(userInfo);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(userInfo)]
  );
};
