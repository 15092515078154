import React from 'react';

import { gql } from '@apollo/client';
import { MaybeTooltip } from '@modernloop/shared/components';
import { StarFilledIcon } from '@modernloop/shared/icons';
import { snakeCaseToInitCase } from '@modernloop/shared/utils';
import { Avatar, Box, Stack, Tooltip, Typography } from '@mui/material';

import { ModuleMember_InterviewModuleMemberFragment } from 'src/generated/mloop-graphql';

import { PauseIcon } from 'src/components/icons';
import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';

import {
  doesModuleMemberNeedsApproval,
  getModuleMemberPendingTrainingCount,
  isModuleMemberAllRequiredInterviewsScheduled,
  isModuleMemberTrained,
  isModuleMembreOrEmployeePaused,
} from 'src/entities/InterviewModuleMember/utils';

type Fragments = {
  interviewModuleMember: ModuleMember_InterviewModuleMemberFragment;
};

type Props = { preferred?: boolean; eventsStr?: string; flagIcon?: JSX.Element | null };

const ModuleMember: FCWithFragments<Fragments, Props> = ({ interviewModuleMember, preferred, eventsStr, flagIcon }) => {
  const pendingTrainingCount = getModuleMemberPendingTrainingCount({ moduleMember: interviewModuleMember }, null);
  const needsApproval = doesModuleMemberNeedsApproval(
    interviewModuleMember.status,
    interviewModuleMember.stats?.currentTrainingSessionStatus || undefined
  );
  const allInterviewsScheduled = isModuleMemberAllRequiredInterviewsScheduled(
    interviewModuleMember.stats?.currentTrainingSessionStatus || undefined
  );
  const paused = isModuleMembreOrEmployeePaused({ moduleMember: interviewModuleMember }, null);

  const getTraineeHelperText = () => {
    if (isModuleMemberTrained(interviewModuleMember.status)) {
      return null;
    }
    return (
      <>
        {pendingTrainingCount > 0 && !allInterviewsScheduled && (
          <Typography variant="body2" color="text.secondary">
            {pendingTrainingCount} training remaining
          </Typography>
        )}
        {pendingTrainingCount > 0 && allInterviewsScheduled && (
          <Typography variant="body2" color="text.secondary" whiteSpace="nowrap">
            All trainings scheduled for {snakeCaseToInitCase(interviewModuleMember.status).toLowerCase()}
          </Typography>
        )}
        {pendingTrainingCount <= 0 && !needsApproval && (
          <Typography variant="body2" color="text.secondary">
            All trainings scheduled
          </Typography>
        )}
        {pendingTrainingCount <= 0 && needsApproval && (
          <Stack direction="row" spacing={0.5}>
            <Typography variant="body2" color="text.secondary">
              All trainings scheduled
            </Typography>
            <Typography variant="body2" color="warning.main" whiteSpace="nowrap">
              (Pending approval)
            </Typography>
          </Stack>
        )}
      </>
    );
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      flexWrap="nowrap"
      width="100%"
      overflow="hidden"
      key={`${interviewModuleMember.employeeId}-${interviewModuleMember.interviewModuleId}`}
    >
      <Avatar
        alt={interviewModuleMember.employee?.fullName || ''}
        src={interviewModuleMember.employee?.slackImageUrl || ''}
      />
      <Stack direction="row" spacing={0.5} justifyContent="space-between" width="calc(100% - 30px)">
        <Stack direction="column" width="calc(100% - 30px)">
          <Stack direction="row" spacing={1}>
            <MaybeTooltip
              label={interviewModuleMember.employee?.fullName || ''}
              title={interviewModuleMember.employee?.fullName || ''}
            />
            {preferred && <StarFilledIcon color="warning" />}
          </Stack>
          <Label color="high-contrast-grey" variant="captions" noWrap>
            {interviewModuleMember?.employee?.title || ''}
          </Label>
          {getTraineeHelperText()}
          {eventsStr && (
            <MaybeTooltip
              labelProps={{ variant: 'body2', color: 'text.secondary' }}
              label={eventsStr}
              title={eventsStr}
            />
          )}
        </Stack>
        {paused && !flagIcon && (
          <Box display="flex" alignSelf="center">
            <Tooltip title="Paused">
              <PauseIcon color="max-contrast-grey" />
            </Tooltip>
          </Box>
        )}
        {flagIcon && (
          <Box display="flex" alignSelf="center">
            {flagIcon}
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

ModuleMember.fragments = {
  interviewModuleMember: gql`
    ${isModuleMembreOrEmployeePaused.fragments.moduleMember}
    ${getModuleMemberPendingTrainingCount.fragments.moduleMember}
    fragment ModuleMember_interviewModuleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      employee {
        id
        fullName
        slackImageUrl
        title
      }
      stats {
        currentTrainingSessionStatus
      }
      ...isModuleMembreOrEmployeePaused_ModuleMember
      ...getModuleMemberPendingTrainingCount_ModuleMember
    }
  `,
};

export default ModuleMember;
