import React, { useCallback, useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';

import { CodingLink_JobStageInterviewFragment, DynamicLinkType } from 'src/generated/mloop-graphql';

import { CodeIcon, CodeSignalIcon, CoderPadIcon, CodilityIcon, CrossIcon, HackerRankIcon } from 'src/components/icons';

import { useIntegrations } from 'src/hooks/api/integration';

type Props = {
  jobStageInterviewId: string;
  onUpdated: (jobStageInterview: CodingLink_JobStageInterviewFragment) => void;
};

type Fragments = {
  jobStageInterview: CodingLink_JobStageInterviewFragment;
};

const CodingLink: FCWithFragments<Fragments, Props> = ({ jobStageInterviewId, jobStageInterview, onUpdated }) => {
  const { data: integrations } = useIntegrations();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const selectedDynamicLinkType = useMemo(() => {
    return jobStageInterview.dynamicLinkTypes?.length ? jobStageInterview.dynamicLinkTypes[0] : undefined;
  }, [jobStageInterview.dynamicLinkTypes]);

  const handleUpdateCodingLink = useCallback(
    (dynamicLinkType: DynamicLinkType) => {
      onUpdated({ ...jobStageInterview, dynamicLinkTypes: [dynamicLinkType] });
      setShowMenu(false);
    },
    [jobStageInterview, onUpdated]
  );

  const menuItems = useMemo(() => {
    const items: JSX.Element[] = [];

    if (integrations?.codility) {
      items.push(
        <MenuItem
          key={`menu-item-${DynamicLinkType.Codility}`}
          data-testid={`menu-item-${DynamicLinkType.Codility}`}
          onClick={() => handleUpdateCodingLink(DynamicLinkType.Codility)}
        >
          <ListItemIcon>
            <CodilityIcon />
          </ListItemIcon>
          <ListItemText>Codility</ListItemText>
        </MenuItem>
      );
    }

    if (integrations?.coderpad) {
      items.push(
        <MenuItem
          key={`menu-item-${DynamicLinkType.Coderpad}`}
          data-testid={`menu-item-${DynamicLinkType.Coderpad}`}
          onClick={() => handleUpdateCodingLink(DynamicLinkType.Coderpad)}
        >
          <ListItemIcon>
            <CoderPadIcon />
          </ListItemIcon>
          <ListItemText>Coderpad</ListItemText>
        </MenuItem>
      );
    }

    if (integrations?.codesignal) {
      items.push(
        <MenuItem
          key={`menu-item-${DynamicLinkType.Codesignal}`}
          data-testid={`menu-item-${DynamicLinkType.Codesignal}`}
          onClick={() => handleUpdateCodingLink(DynamicLinkType.Codesignal)}
        >
          <ListItemIcon>
            <CodeSignalIcon />
          </ListItemIcon>
          <ListItemText>CodeSignal</ListItemText>
        </MenuItem>
      );
    }

    if (integrations?.hackerrank) {
      items.push(
        <MenuItem
          key={`menu-item-${DynamicLinkType.Hackerrank}`}
          data-testid={`menu-item-${DynamicLinkType.Hackerrank}`}
          onClick={() => handleUpdateCodingLink(DynamicLinkType.Hackerrank)}
        >
          <ListItemIcon>
            <HackerRankIcon />
          </ListItemIcon>
          <ListItemText>HackerRank</ListItemText>
        </MenuItem>
      );
    }

    return items;
  }, [
    handleUpdateCodingLink,
    integrations?.coderpad,
    integrations?.codesignal,
    integrations?.codility,
    integrations?.hackerrank,
  ]);

  const codingLinkIcon = useMemo(() => {
    if (!selectedDynamicLinkType) return null;
    if (selectedDynamicLinkType === DynamicLinkType.Coderpad) return <CoderPadIcon />;
    if (selectedDynamicLinkType === DynamicLinkType.Codility) return <CodilityIcon />;
    if (selectedDynamicLinkType === DynamicLinkType.Codesignal) return <CodeSignalIcon />;
    if (selectedDynamicLinkType === DynamicLinkType.Hackerrank) return <HackerRankIcon />;

    return null;
  }, [selectedDynamicLinkType]);

  const codingLinkLabel = useMemo(() => {
    if (selectedDynamicLinkType === DynamicLinkType.Coderpad) return 'Coderpad';
    if (selectedDynamicLinkType === DynamicLinkType.Codesignal) return 'CodeSignal';
    if (selectedDynamicLinkType === DynamicLinkType.Codility) return 'Codility';
    if (selectedDynamicLinkType === DynamicLinkType.Hackerrank) return 'HackerRank';

    return '';
  }, [selectedDynamicLinkType]);

  const hasCodingIntegration = useMemo(() => {
    return Boolean(
      integrations?.coderpad || integrations?.codility || integrations?.codesignal || integrations?.hackerrank
    );
  }, [integrations]);

  const handleShowCodingLinkMenu = () => {
    if (menuItems.length === 1) {
      if (selectedDynamicLinkType) return;

      if (integrations?.codility) {
        handleUpdateCodingLink(DynamicLinkType.Codility);
      } else if (integrations?.coderpad) {
        handleUpdateCodingLink(DynamicLinkType.Coderpad);
      } else if (integrations?.codesignal) {
        handleUpdateCodingLink(DynamicLinkType.Codesignal);
      } else if (integrations?.hackerrank) {
        handleUpdateCodingLink(DynamicLinkType.Hackerrank);
      }
      return;
    }

    setShowMenu(true);
  };

  const handleRemoveCodingLink = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onUpdated({ ...jobStageInterview, dynamicLinkTypes: undefined });

    // To not show the menu
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      {(!jobStageInterview.dynamicLinkTypes || jobStageInterview.dynamicLinkTypes.length === 0) && (
        <Tooltip title={hasCodingIntegration ? 'Select coding tool' : 'No coding tool is integrated'}>
          <IconButton
            data-testid="job-stage-interview-no-coding-link-button"
            disabled={!hasCodingIntegration}
            ref={buttonRef}
            size="small"
            onClick={handleShowCodingLinkMenu}
          >
            <CodeIcon />
          </IconButton>
        </Tooltip>
      )}
      {jobStageInterview.dynamicLinkTypes && Boolean(jobStageInterview.dynamicLinkTypes.length) && (
        <Tooltip title={codingLinkLabel || undefined}>
          <Button
            data-testid="job-stage-interview-coding-link-button"
            size="small"
            ref={buttonRef}
            startIcon={codingLinkIcon}
            variant="contained"
            onClick={handleShowCodingLinkMenu}
            endIcon={
              <IconButton
                onClick={handleRemoveCodingLink}
                data-testid="job-stage-interview-coding-link-delete-icon-button"
                size="small"
                sx={{ mr: -1.25 }}
              >
                <CrossIcon />
              </IconButton>
            }
          >
            {codingLinkLabel}
          </Button>
        </Tooltip>
      )}

      {showMenu && (
        <Menu
          open
          data-testid="job-stage-interview-coding-link-menu"
          disableScrollLock
          anchorEl={buttonRef.current}
          id={`job-stage-interview-coding-link-${jobStageInterviewId}`}
          onClose={() => setShowMenu(false)}
        >
          {menuItems}
        </Menu>
      )}
    </>
  );
};

CodingLink.fragments = {
  jobStageInterview: gql`
    fragment CodingLink_jobStageInterview on JobStageInterview {
      id
      dynamicLinkTypes
    }
  `,
};

export default CodingLink;
