/* eslint-disable max-lines */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CodeLinkType, InterviewMeetingLocationType, MeetingRoomSuggestionsQuery } from 'src/generated/mloop-graphql';

import { ArrayElement } from 'src/types/utils';

import { InterviewEvent, InterviewSchedule } from 'src/utils/api/getScheduleOptions';

// TODO (cam): https://linear.app/modernloop/issue/ENG-3723/fe-or-initialize-schedule-communications-once
// https://github.com/ModernLoop/mloop-js/pull/1858#discussion_r1024924568

export type MeetingRoomSuggestionInterface = ArrayElement<
  Exclude<MeetingRoomSuggestionsQuery['meetingRoomSuggestions'], null | undefined>['items']
>;

// Dictionary of coding URL's by Coding URL types per interview event id, Map<slotId, url>.
export type CodingUrlBySlotId = { [codeLinkType in CodeLinkType]?: { [slotId: string]: string } };

export type CustomVideoMeetingLinkUrlBySlotId = { [key: string]: string };

export interface ZoomDialInInfo {
  country: string;
  countryName: string;
  city: string;
  number: string;
  type: string;
}

export interface ZoomInfo {
  joinURL: string;
  meetingID: number;
  password: string;
  pstnPassword?: number;
  dialInfo?: ZoomDialInInfo[];
}

export type ZoomInfoBySlotId = { [key: string]: ZoomInfo };
export type VideoMeetingUserIdBySlotId = { [key: string]: string | undefined };
export type VideoMeetingLinkBySlotId = { [key: string]: string };
export type InterviewMeetingLocationIdBySlotId = { [slotId: string]: string };

export type Location = 'none' | 'zoom' | 'google' | 'microsoft' | 'custom';
export enum MeetingLinksConfig {
  One = 'one',
  Many = 'many',
}

export interface InterviewerEventContent {
  emails: string[];
  summary: string;
  description: string;
  isCoderPad: boolean;
  event: InterviewEvent;
  location: string | null;
  codingInterviewURL: string | null;
  zoomUserId: string | null;
  zoomInfo?: ZoomInfo;
  emailTemplateID: string | null;
  customVideoMeetingUrl: string;
  slotId: string;
  filledBodyTokens?: { [key: string]: string };
}

export interface CandidateEventContent {
  summary: string;
  description: string;
  schedule: InterviewSchedule;
  location: string | null;
  emailTemplateID: string | null;
  filledBodyTokens?: { [key: string]: string };
}

export interface CandidateCalendarEventContent {
  subject: string;
  body: string;
  schedule: InterviewSchedule;
  location: string | null;
  templateID: string | null;
  filledBodyTokens?: { [key: string]: string };
}

interface SlackChannelMessageContent {
  message: string;
  emailTemplateID: string | null;
  filledBodyTokens?: { [key: string]: string };
}

export interface ScheduleContent {
  candidateEventContent: CandidateEventContent;
  candidateCalendarEventContent: CandidateCalendarEventContent;
  interviewerEventContents: InterviewerEventContent[];
  slackChannelMessageContent: SlackChannelMessageContent;
}

type ScheduleCommunication = {
  commonMeetingRoomSuggestionByEvent: { [eventId: string]: MeetingRoomSuggestionInterface[] };
  meetingRoomSuggestionsPerInterviewEvent: { [eventId: string]: MeetingRoomSuggestionInterface[] };

  // Stores the locally generated application_stage_id that is passed to createSchedule.
  applicationStageId?: string;

  codingUrlBySlotId: CodingUrlBySlotId;

  // Slack card information
  slackChannelEnabled: boolean;
  // This is the slack channels id from SLACK
  slackChannelRemoteId: string;
  // This is the "slack channel" (aka Conversation) id in our own DB
  conversationId: string;
  slackChannelName: string;
  prevSlackChannelName?: string;
  slackChannelContent?: string;
  slackChannelFilledBodyTokens?: { [key: string]: string };
  slackChannelMessageTemplateID?: string;
  slackChannelInterviewerEmployeeIDs: string[];
  slackChannelRecruitingTeamEmployeeIDs: string[];
  slackChannelHiringManagerEmployeeIDs: string[];

  // Meeting links common config
  location: InterviewMeetingLocationType;
  meetingLinkConfig: MeetingLinksConfig;
  interviewMeetingLocationId: string | undefined;
  interviewMeetingLocationIdBySlotId: InterviewMeetingLocationIdBySlotId;

  // CustomLink, CustomText, CustomPhoneNumber, CandidatePhoneNumber VC meeting links information
  customVideoMeetingLink?: string;
  customVideoMeetingLinkUrls: CustomVideoMeetingLinkUrlBySlotId;

  // Microsoft Teams & Google Meets meeting url
  videoMeetingLink?: string;
  videoMeetingLinkHostEmployeeId?: string;
  videoMeetingLinkBySlotId: { [slotId: string]: string };
  videoMeetingHostEmployeeIdBySlotId: VideoMeetingUserIdBySlotId; // slotId → employeeId

  // Zoom meeting info
  zoomUserId?: string;
  zoomInfo?: ZoomInfo;
  zoomUserIds: VideoMeetingUserIdBySlotId; // jobStageInterviewId/slotId key
  zoomInfos: ZoomInfoBySlotId; // jobStageInterviewId/slotId key

  // Schedule content
  scheduleContent: ScheduleContent | undefined;

  //
  applicationId: string;
  isDebriefFlow: boolean;

  // Whether or not to save candidate and slack templates back to job settings
  shouldSaveJobTemplates: boolean;

  // Flags indicating if debrief needs to be rescheduled.
  hasScheduleTimeAfterDebriefStartAt?: boolean;
  hasAttendeeAdded?: boolean;
};

export type ScheduleCommunicationsState = {
  byId: { [scheduleId: string]: ScheduleCommunication };
};

const getInitialScheduleCommunication = (): ScheduleCommunication => {
  return {
    commonMeetingRoomSuggestionByEvent: {},
    meetingRoomSuggestionsPerInterviewEvent: {},
    applicationStageId: undefined,
    codingUrlBySlotId: {},
    conversationId: '',
    slackChannelRemoteId: '',
    slackChannelEnabled: false,
    slackChannelName: '',
    slackChannelContent: undefined,
    slackChannelFilledBodyTokens: {},
    slackChannelMessageTemplateID: undefined,
    prevSlackChannelName: undefined,
    slackChannelInterviewerEmployeeIDs: [],
    slackChannelRecruitingTeamEmployeeIDs: [],
    slackChannelHiringManagerEmployeeIDs: [],
    applicationId: '',
    isDebriefFlow: false,
    shouldSaveJobTemplates: false,

    location: InterviewMeetingLocationType.NotSpecified,
    meetingLinkConfig: MeetingLinksConfig.One,
    interviewMeetingLocationId: undefined,
    interviewMeetingLocationIdBySlotId: {},

    customVideoMeetingLink: undefined,
    customVideoMeetingLinkUrls: {},

    videoMeetingLink: undefined,
    videoMeetingLinkHostEmployeeId: undefined,
    videoMeetingLinkBySlotId: {},
    videoMeetingHostEmployeeIdBySlotId: {},

    zoomUserId: undefined,
    zoomInfo: undefined,
    zoomUserIds: {},
    zoomInfos: {},

    scheduleContent: undefined,

    hasScheduleTimeAfterDebriefStartAt: undefined,
    hasAttendeeAdded: undefined,
  };
};

const getInitialState = (): ScheduleCommunicationsState => {
  return {
    byId: {},
  };
};

const scheduleCommunicationsSlice = createSlice({
  name: 'scheduleCommunicationsState',
  initialState: getInitialState(),
  reducers: {
    clearScheduleCommunications(state: ScheduleCommunicationsState, action: PayloadAction<{ scheduleId: string }>) {
      const { scheduleId } = action.payload;
      if (!state.byId[scheduleId]) return;

      state.byId[scheduleId] = { ...getInitialScheduleCommunication() };
    },

    setFlow(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; applicationId: string; isDebrief: boolean }>
    ) {
      const { scheduleId, applicationId, isDebrief } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].applicationId = applicationId;
      state.byId[scheduleId].isDebriefFlow = isDebrief;
    },

    setCommonMeetingRoomSuggestionsByEventId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        eventIds: string[];
        roomSuggestions: MeetingRoomSuggestionInterface[];
      }>
    ) {
      const { scheduleId, eventIds, roomSuggestions } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      eventIds.forEach((eventId) => {
        state.byId[scheduleId].commonMeetingRoomSuggestionByEvent[eventId] = roomSuggestions;
      });
    },

    setMeetingRoomSuggestionsForInterviewEventId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        interviewEventId: string;
        roomSuggestions: MeetingRoomSuggestionInterface[];
      }>
    ) {
      const { scheduleId, interviewEventId, roomSuggestions } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }
      state.byId[scheduleId].meetingRoomSuggestionsPerInterviewEvent[interviewEventId] = roomSuggestions;
    },

    setApplicationStageIdByScheduleId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        applicationStageId: string;
      }>
    ) {
      const { scheduleId, applicationStageId } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }
      state.byId[scheduleId].applicationStageId = applicationStageId;
    },

    addCodeLinkType(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; codeLinkType: CodeLinkType }>
    ) {
      const { scheduleId, codeLinkType } = action.payload;

      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      if (!state.byId[scheduleId].codingUrlBySlotId[codeLinkType]) {
        state.byId[scheduleId].codingUrlBySlotId[codeLinkType] = {};
      }
    },

    deleteCodeLinkType(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; codeLinkType: CodeLinkType }>
    ) {
      const { scheduleId, codeLinkType } = action.payload;

      if (!state.byId[scheduleId]) return;

      delete state.byId[scheduleId].codingUrlBySlotId[codeLinkType];
    },

    resetUnusedCodeLinkTypes(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; codeLinkTypeToKeep: CodeLinkType }>
    ) {
      // reset all unused codeLinkTypes
      const { scheduleId, codeLinkTypeToKeep } = action.payload;

      const existingCodingUrl = state.byId[scheduleId].codingUrlBySlotId[codeLinkTypeToKeep];

      if (existingCodingUrl) {
        state.byId[scheduleId].codingUrlBySlotId = { [codeLinkTypeToKeep]: existingCodingUrl };
      } else {
        state.byId[scheduleId].codingUrlBySlotId = {};
      }
    },

    setCodingUrl(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        codeLinkType: CodeLinkType;
        slotId: string;
        url: string;
      }>
    ) {
      const { scheduleId, slotId, codeLinkType, url } = action.payload;

      if (!state.byId[scheduleId]) {
        return;
      }

      const codingUrlByInterviewEvent = state.byId[scheduleId].codingUrlBySlotId[codeLinkType];
      if (!codingUrlByInterviewEvent) return;
      codingUrlByInterviewEvent[slotId] = url;
    },

    deleteCodingUrl(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        codeLinkType: CodeLinkType;
        interviewEventId: string;
      }>
    ) {
      const { scheduleId, interviewEventId, codeLinkType } = action.payload;

      if (!state.byId[scheduleId]) {
        return;
      }

      const codingUrlByInterviewEvent = state.byId[scheduleId].codingUrlBySlotId[codeLinkType];
      if (!codingUrlByInterviewEvent) return;
      delete codingUrlByInterviewEvent[interviewEventId];
    },

    updateRemoteSlackChannelId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        remoteId: string;
      }>
    ) {
      const { scheduleId, remoteId } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].slackChannelRemoteId = remoteId;
    },

    updateConversationId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        conversationId: string;
      }>
    ) {
      const { scheduleId, conversationId } = action.payload;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line max-lines
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].conversationId = conversationId;
    },

    updateSlackChannelMessage(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        message: string;
        filledBodyTokens?: { [key: string]: string };
      }>
    ) {
      const { scheduleId, message, filledBodyTokens } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].slackChannelContent = message;

      if (filledBodyTokens) state.byId[scheduleId].slackChannelFilledBodyTokens = filledBodyTokens;
    },

    updateSlackChannelName(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        name: string;
      }>
    ) {
      const { scheduleId, name } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].slackChannelName = name;
    },

    updatePrevSlackChannelName(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        name: string;
      }>
    ) {
      const { scheduleId, name } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].prevSlackChannelName = name;
    },

    updateSlackChannelEnabled(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        isEnabled: boolean;
      }>
    ) {
      const { scheduleId, isEnabled } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].slackChannelEnabled = isEnabled;
    },

    updateSlackChannelInterviewerEmployeeIDs(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        employeeIDs: string[];
      }>
    ) {
      const { scheduleId, employeeIDs } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].slackChannelInterviewerEmployeeIDs = employeeIDs;
    },

    updateSlackChannelRecruitingTeamEmployeeIDs(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        employeeIDs: string[];
      }>
    ) {
      const { scheduleId, employeeIDs } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].slackChannelRecruitingTeamEmployeeIDs = employeeIDs;
    },

    updateSlackChannelHiringManagerEmployeeIDs(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        employeeIDs: string[];
      }>
    ) {
      const { scheduleId, employeeIDs } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].slackChannelHiringManagerEmployeeIDs = employeeIDs;
    },

    updateSlackChannelMessageTemplateID(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        templateId: string;
      }>
    ) {
      const { scheduleId, templateId } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].slackChannelMessageTemplateID = templateId;
    },

    updateCustomVideoMeetingLinksConfig(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        config: MeetingLinksConfig;
      }>
    ) {
      const { scheduleId, config } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].meetingLinkConfig = config;
      state.byId[scheduleId].customVideoMeetingLink = getInitialScheduleCommunication().customVideoMeetingLink;
      state.byId[scheduleId].customVideoMeetingLinkUrls = getInitialScheduleCommunication().customVideoMeetingLinkUrls;
    },

    updateInterviewMeetingLocationId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        meetingLocationId?: string;
      }>
    ) {
      const { scheduleId, meetingLocationId } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].interviewMeetingLocationId = meetingLocationId;
    },

    updateinterviewMeetingLocationIdBySlotId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        slotId: string;
        meetingLocationId?: string;
      }>
    ) {
      const { scheduleId, slotId, meetingLocationId } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      if (meetingLocationId) state.byId[scheduleId].interviewMeetingLocationIdBySlotId[slotId] = meetingLocationId;
      else delete state.byId[scheduleId].interviewMeetingLocationIdBySlotId[slotId];
    },

    updateCustomVideoMeetingLink(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; customVideoMeetingLink: string }>
    ) {
      const { scheduleId, customVideoMeetingLink } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].customVideoMeetingLink = customVideoMeetingLink;
    },

    clearCustomVideoMeetingLinkUrl(state: ScheduleCommunicationsState, action: PayloadAction<{ scheduleId: string }>) {
      const { scheduleId } = action.payload;
      if (!state.byId[scheduleId]) return;

      state.byId[scheduleId].customVideoMeetingLink = getInitialScheduleCommunication().customVideoMeetingLink;
    },

    updateCustomVideoMeetingLinkUrlBySlotId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        videoMeetingUrl: string;
        slotId: string;
      }>
    ) {
      const { scheduleId, slotId, videoMeetingUrl } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].customVideoMeetingLinkUrls[slotId] = videoMeetingUrl;
    },

    clearCustomVideoMeetingLinkUrlBySlotId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
      }>
    ) {
      const { scheduleId } = action.payload;
      if (!state.byId[scheduleId]) return;

      state.byId[scheduleId].customVideoMeetingLinkUrls = getInitialScheduleCommunication().customVideoMeetingLinkUrls;
    },

    updateLocation(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; location: InterviewMeetingLocationType }>
    ) {
      const { scheduleId, location } = action.payload;
      const initial = getInitialScheduleCommunication();
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = initial;
      }

      state.byId[scheduleId].location = location;

      // Cleanup
      state.byId[scheduleId].meetingLinkConfig = initial.meetingLinkConfig;
      state.byId[scheduleId].interviewMeetingLocationId = initial.interviewMeetingLocationId;
      state.byId[scheduleId].interviewMeetingLocationIdBySlotId = initial.interviewMeetingLocationIdBySlotId;
      state.byId[scheduleId].videoMeetingLink = initial.videoMeetingLink;
      state.byId[scheduleId].videoMeetingLinkHostEmployeeId = initial.videoMeetingLinkHostEmployeeId;
      state.byId[scheduleId].videoMeetingLinkBySlotId = initial.videoMeetingLinkBySlotId;
      state.byId[scheduleId].videoMeetingHostEmployeeIdBySlotId = initial.videoMeetingHostEmployeeIdBySlotId;
      state.byId[scheduleId].customVideoMeetingLink = initial.customVideoMeetingLink;
      state.byId[scheduleId].customVideoMeetingLinkUrls = initial.customVideoMeetingLinkUrls;
      state.byId[scheduleId].zoomUserId = initial.zoomUserId;
      state.byId[scheduleId].zoomInfo = initial.zoomInfo;
      state.byId[scheduleId].zoomUserIds = initial.zoomUserIds;
      state.byId[scheduleId].zoomInfos = initial.zoomInfos;
    },

    updateVideoMeetingLinkConfig(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        config: MeetingLinksConfig;
      }>
    ) {
      const { scheduleId, config } = action.payload;

      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].meetingLinkConfig = config;
      state.byId[scheduleId].videoMeetingLink = undefined;
      state.byId[scheduleId].videoMeetingLinkHostEmployeeId = undefined;
      state.byId[scheduleId].videoMeetingLinkBySlotId = {};
      state.byId[scheduleId].videoMeetingHostEmployeeIdBySlotId = {};
    },

    updateVideoMeetingLinkHostEmployeeId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; hostEmployeeId?: string }>
    ) {
      const { scheduleId, hostEmployeeId } = action.payload;

      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].videoMeetingLinkHostEmployeeId = hostEmployeeId;
    },

    updateVideoMeetingLink(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        videoMeetingUrl: string | undefined;
      }>
    ) {
      const { scheduleId, videoMeetingUrl } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].videoMeetingLink = videoMeetingUrl;
    },

    updateVideoMeetingLinkForSlotId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{
        scheduleId: string;
        videoMeetingUrl: string;
        slotId: string;
      }>
    ) {
      const { scheduleId, slotId, videoMeetingUrl } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].videoMeetingLinkBySlotId[slotId] = videoMeetingUrl;
    },

    updateVideoMeetingHostEmployeeIdForSlotId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; slotId: string; videoMeetingHostEmployeeId?: string }>
    ) {
      const { scheduleId, slotId, videoMeetingHostEmployeeId } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }
      state.byId[scheduleId].videoMeetingHostEmployeeIdBySlotId[slotId] = videoMeetingHostEmployeeId;
    },

    updateZoomMeetingConfig(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; config: MeetingLinksConfig }>
    ) {
      const { scheduleId, config } = action.payload;

      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }
      state.byId[scheduleId].meetingLinkConfig = config;
      state.byId[scheduleId].zoomInfo = undefined;
      state.byId[scheduleId].zoomUserId = undefined;
      state.byId[scheduleId].zoomInfos = {};
      state.byId[scheduleId].zoomUserIds = {};
    },

    updateZoomUserId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; zoomUserId?: string }>
    ) {
      const { scheduleId, zoomUserId } = action.payload;

      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].zoomUserId = zoomUserId;
    },

    updateZoomInfo(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; zoomInfo?: ZoomInfo }>
    ) {
      const { scheduleId, zoomInfo } = action.payload;

      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      state.byId[scheduleId].zoomInfo = zoomInfo;
    },

    updateZoomUserIdForSlotId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; slotId: string; zoomUserId?: string }>
    ) {
      const { scheduleId, slotId, zoomUserId } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }
      state.byId[scheduleId].zoomUserIds[slotId] = zoomUserId;
    },

    updateZoomInfoForSlotId(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; slotId: string; zoomInfo?: ZoomInfo }>
    ) {
      const { scheduleId, slotId, zoomInfo } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }

      if (zoomInfo) state.byId[scheduleId].zoomInfos[slotId] = zoomInfo;
      else delete state.byId[scheduleId].zoomInfos[slotId];
    },

    updateShouldSaveJobTemplates(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; shouldSaveJobTemplates: boolean }>
    ) {
      const { scheduleId, shouldSaveJobTemplates } = action.payload;
      state.byId[scheduleId].shouldSaveJobTemplates = shouldSaveJobTemplates;
    },

    updateScheduleContent(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; scheduleContent: ScheduleContent }>
    ) {
      const { scheduleId, scheduleContent } = action.payload;
      if (!state.byId[scheduleId]) {
        state.byId[scheduleId] = getInitialScheduleCommunication();
      }
      state.byId[scheduleId].scheduleContent = scheduleContent;
    },

    updateCandidateEventContent(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; content: CandidateEventContent }>
    ) {
      const { scheduleId, content } = action.payload;
      const scheduleContent = state.byId[scheduleId]?.scheduleContent;
      if (scheduleContent?.candidateEventContent) {
        scheduleContent.candidateEventContent = content;
      }
    },

    updateCandidateCalendarEventContent(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; content: CandidateCalendarEventContent }>
    ) {
      const { scheduleId, content } = action.payload;
      const scheduleContent = state.byId[scheduleId]?.scheduleContent;
      if (scheduleContent?.candidateCalendarEventContent) {
        scheduleContent.candidateCalendarEventContent = content;
      }
    },

    updateInterviewerContentViaIndex(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; content: InterviewerEventContent; eventId: string }>
    ) {
      const { scheduleId, content, eventId } = action.payload;
      const scheduleContent = state.byId[scheduleId]?.scheduleContent;
      if (scheduleContent?.interviewerEventContents) {
        const index = scheduleContent.interviewerEventContents.findIndex((c) => c.event.id === eventId);
        if (index >= 0) scheduleContent.interviewerEventContents[index] = content;
      }
    },

    updateCandidateContentSummary(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; value: string }>
    ) {
      const { scheduleId, value } = action.payload;
      const candidateEventContent = state.byId[scheduleId]?.scheduleContent?.candidateEventContent;
      if (!candidateEventContent) {
        return;
      }
      candidateEventContent.summary = value;
    },

    updateCandidateContentDescription(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; value: string }>
    ) {
      const { scheduleId, value } = action.payload;
      const candidateEventContent = state.byId[scheduleId]?.scheduleContent?.candidateEventContent;
      if (!candidateEventContent) {
        return;
      }
      candidateEventContent.description = value;
    },

    updateInterviewerContentSummary(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; value: string; eventId: string }>
    ) {
      const { scheduleId, value, eventId } = action.payload;
      const interviewerEventContent = state.byId[scheduleId]?.scheduleContent?.interviewerEventContents.find(
        (c) => c.event.id === eventId
      );
      if (!interviewerEventContent) {
        return;
      }
      interviewerEventContent.summary = value;
    },

    updateInterviewerContentDescription(
      state: ScheduleCommunicationsState,
      action: PayloadAction<{ scheduleId: string; value: string; eventId: string }>
    ) {
      const { scheduleId, value, eventId } = action.payload;
      const interviewerEventContent = state.byId[scheduleId]?.scheduleContent?.interviewerEventContents.find(
        (c) => c.event.id === eventId
      );
      if (!interviewerEventContent) {
        return;
      }
      interviewerEventContent.description = value;
    },

    updateCandidateCalendarDescription: (state, action: PayloadAction<{ scheduleId: string; body: string }>) => {
      const { scheduleId, body } = action.payload;
      const candidateCalendarEventContent = state.byId[scheduleId]?.scheduleContent?.candidateCalendarEventContent;
      if (!candidateCalendarEventContent) {
        return;
      }
      candidateCalendarEventContent.body = body;
    },

    updateCandidateCalendarSummary: (state, action: PayloadAction<{ scheduleId: string; subject: string }>) => {
      const { scheduleId, subject } = action.payload;
      const candidateCalendarEventContent = state.byId[scheduleId]?.scheduleContent?.candidateCalendarEventContent;
      if (!candidateCalendarEventContent) {
        return;
      }
      candidateCalendarEventContent.subject = subject;
    },

    updateDebriefRescheduleDetails: (
      state,
      action: PayloadAction<{
        scheduleId: string;
        hasAttendeeAdded?: boolean;
        hasScheduleTimeAfterDebriefStartAt?: boolean;
      }>
    ) => {
      const { scheduleId, hasAttendeeAdded, hasScheduleTimeAfterDebriefStartAt } = action.payload;

      // If we don't have schedule state setup by now then we have much bigger problems.
      if (!state.byId[scheduleId]) return;

      state.byId[scheduleId].hasAttendeeAdded = hasAttendeeAdded;
      state.byId[scheduleId].hasScheduleTimeAfterDebriefStartAt = hasScheduleTimeAfterDebriefStartAt;
    },
  },
});

export const { reducer } = scheduleCommunicationsSlice;
export default scheduleCommunicationsSlice;
