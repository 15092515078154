import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { formatTimeRange } from '@modernloop/shared/datetime';
import { LinkIcon } from '@modernloop/shared/icons';
import { Avatar, Stack, Tooltip, Typography } from '@mui/material';

import { DebriefContentHeader_DebriefFragment } from 'src/generated/mloop-graphql';

import { ZoomIcon } from 'src/components/icons';

import DebriefContentMenu from './DebriefContentMenu';

type Fragments = {
  debrief: DebriefContentHeader_DebriefFragment;
};

type Props = {
  timezone: string;
};

const DebriefContentHeader: FCWithFragments<Fragments, Props> = ({ timezone, debrief }) => {
  const locationIcon = useMemo(() => {
    if (debrief.videoMeetingUrl?.toLowerCase().indexOf('zoom') !== -1) {
      return <ZoomIcon tooltip="Zoom" fontSize={16} />;
    }

    if (debrief.videoMeetingUrl?.toLowerCase().indexOf('meet.google.com') !== -1) {
      return (
        <Tooltip title="Google Meets">
          <Avatar
            src="/static/images/integrations/google-meets.png"
            sx={{ width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(2) }}
          />
        </Tooltip>
      );
    }

    if (debrief.videoMeetingUrl?.toLowerCase().indexOf('teams.microsoft.com') !== -1) {
      return (
        <Tooltip title="Microsoft Teams">
          <Avatar
            src="/static/images/integrations/microsoft-teams.png"
            sx={{ width: (theme) => theme.spacing(2), height: (theme) => theme.spacing(2) }}
          />
        </Tooltip>
      );
    }

    if (debrief.videoMeetingUrl?.length) {
      return <LinkIcon tooltip="Includes a custom meeting link" fontSize="small" />;
    }

    return null;
  }, [debrief.videoMeetingUrl]);

  return (
    <Stack direction="row" justifyContent="space-between" pl={2.5} pr={1.5} py={0.5}>
      <Stack>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography variant="body1">{debrief.name}</Typography>
        </Stack>
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Typography variant="body2" color="text.secondary">
            {formatTimeRange(debrief.startAt, debrief.endAt, timezone, true /* showTzAbbr */)}
          </Typography>

          {locationIcon}
        </Stack>
      </Stack>

      <DebriefContentMenu debrief={debrief} />
    </Stack>
  );
};

DebriefContentHeader.fragments = {
  debrief: gql`
    ${DebriefContentMenu.fragments.debrief}
    fragment DebriefContentHeader_debrief on ApplicationDebrief {
      id
      name
      startAt
      endAt
      videoMeetingUrl
      ...DebriefContentMenu_debrief
    }
  `,
};

export default DebriefContentHeader;
