import React, { FC } from 'react';

import { ApolloQueryResult, gql } from '@apollo/client';

import {
  ActivityType,
  TaskDetailsActivityLogsListQuery,
  useTaskDetailsActivityLogsListQuery,
} from 'src/generated/mloop-graphql';

import { Error, Loader } from 'src/components/HelperComponents';

import { useFeatureFlagNumber } from 'src/hooks/feature-flag';

import { PAGER_DEFAULT } from 'src/constants';

import ActivityLogsList from '.';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface TaskDetailsActivityLogsListProps {
  taskId: string;
  activityTypes?: ActivityType[];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const TaskDetailsActivityLogsList: FC<TaskDetailsActivityLogsListProps> = ({ taskId, activityTypes }) => {
  const taskPolling = useFeatureFlagNumber('user_task_polling_seconds');
  const { data, loading, error, fetchMore, updateQuery } = useTaskDetailsActivityLogsListQuery({
    variables: {
      taskId,
      input: {
        activityTypes,
        pageInput: {
          limit: PAGER_DEFAULT,
        },
      },
    },
    fetchPolicy: 'network-only',
    pollInterval: taskPolling ? taskPolling * 1000 : undefined,
  });

  // If we are not careful we get the following error:
  //   > Expression produces a union type that is too complex
  // We are getting around that by casting to any.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let options = (data?.task?.activityLogs?.items ?? []) as any[];

  const loadMoreData = () => {
    if (!data?.task?.activityLogs?.nextCursor || loading || error || !fetchMore) return;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    fetchMore({
      variables: {
        input: {
          pageInput: {
            limit: PAGER_DEFAULT,
            cursor: data?.task?.activityLogs?.nextCursor,
          },
        },
      },
    }).then((res: ApolloQueryResult<TaskDetailsActivityLogsListQuery>) => {
      // If we are not careful we get the following error:
      //   > Expression produces a union type that is too complex
      // We are getting around that by casting to any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newOptions = (res?.data?.task?.activityLogs?.items ?? []) as any[];

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      if (newOptions.length > 0) {
        options = [...options, ...newOptions];
      }

      updateQuery((prev) => {
        if (!res?.data?.task?.activityLogs?.items) {
          return prev;
        }

        return {
          task: {
            id: prev.task?.id,
            activityLogs: {
              ...res?.data?.task.activityLogs,
              // If we are not careful we get the following error:
              //   > Expression produces a union type that is too complex
              // We are getting around that by casting to any.
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              items: [...(prev?.task?.activityLogs?.items ?? []), ...newOptions] as any[],
              nextCursor: res?.data?.task.activityLogs.nextCursor,
            },
          },
        };
      });
    });
  };

  if (loading) {
    return <Loader loading />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <ActivityLogsList
      activityLogs={options}
      onViewMoreClick={loadMoreData}
      showViewMoreButton={Boolean(data?.task?.activityLogs?.nextCursor)}
    />
  );
};

export default TaskDetailsActivityLogsList;

export const TaskDetailsActivityLogsListQueryGQL = gql`
  ${ActivityLogsList.fragments.activityLogs}
  fragment TaskDetailsActivityLogsList_activityLog on ActivityLog {
    id
    ...ActivityLogsList_activityLog
  }
  query TaskDetailsActivityLogsList(
    $taskId: uuid!
    $input: ActivityLogsForSourceInput!
    $showTaskName: Boolean! = false
  ) {
    task(id: $taskId) {
      id
      activityLogs(input: $input) {
        items {
          ...TaskDetailsActivityLogsList_activityLog
        }
        nextCursor
      }
    }
  }
`;
