import { logMessage } from '../utils/logMessage';
import IsoTimestamp, { assertIsoTimestamp } from './IsoTimestamp';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export type IsoDate = string & {
  __type: 'Date represented in ISO 8601 format: YYYY-MM-D';
};

export const IsoDateFormat = 'yyyy-MM-dd';

export type IsoDateRange = {
  start: IsoDate;
  end: IsoDate;
};

/**
 * Checks if the input string is an ISO date.
 * @param input - The value to check.
 * @returns True if the input is an ISO date, false otherwise.
 */
export const isIsoDate = (input: unknown): input is IsoDate => {
  if (typeof input !== 'string') {
    return false;
  }

  // test for YYYY-MM-DD format
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(input)) {
    return false;
  }

  // test for valid date
  const dateMs = Date.parse(input);
  return !Number.isNaN(dateMs);
};

/**
 * Asserts that the given string is a valid ISO date.
 * @param input - The string to assert.
 * @param environment - The environment this function is being called in.
 * @throws Throws an error in development if the string is not a valid ISO date.
 */
// note assertions must not be arrow functions
export function assertIsoDate(input: string, environment = 'development'): IsoDate {
  if (!isIsoDate(input)) {
    const msg = `'${input}' is not a valid ISO date string 'YYYY-MM-DD'`;
    if (environment.toLowerCase() !== 'production') {
      throw Error(msg);
    } else {
      logMessage(msg, 'error');
      return input as IsoDate;
    }
  }
  return input;
}

type ConvertableToIsoDate = IsoDate | IsoTimestamp | string;

/**
 * Converts the given value to an ISO date if possible.
 * @param input - The value to convert.
 * @param timeZone - optional timezone to represent the date in, otherwise uses browser timezone
 * @returns The converted ISO date, or undefined if the conversion failed.
 */
export const convertToIsoDate = (input: ConvertableToIsoDate, timeZone?: string | null): IsoDate => {
  if (isIsoDate(input)) {
    return input;
  }
  const isoTimestamp = assertIsoTimestamp(input);
  const date = timeZone ? utcToZonedTime(new Date(isoTimestamp), timeZone) : new Date(isoTimestamp);
  const output = format(date, IsoDateFormat);

  return assertIsoDate(output);
};

type ConvertableDateRange = {
  start: ConvertableToIsoDate;
  end: ConvertableToIsoDate;
};

/**
 * Converts a date range to an ISO date range.
 * @param input - The date range to convert.
 * @param timeZone - The time zone to use for the conversion.
 * @returns The converted ISO date range.
 */
export const convertToIsoDateRange = (input: ConvertableDateRange, timeZone?: string | null): IsoDateRange => {
  return {
    start: convertToIsoDate(input.start, timeZone),
    end: convertToIsoDate(input.end, timeZone),
  };
};

/**
 * Converts an array of date ranges to an array of ISO date ranges.
 * @param input - The array of date ranges to convert.
 * @param timeZone - The time zone to use for the conversion. If not provided, the local time zone will be used.
 * @returns An array of ISO date ranges.
 */
export const convertToIsoDateRanges = (input: ConvertableDateRange[], timeZone?: string | null): IsoDateRange[] => {
  return input.map((dateRange) => convertToIsoDateRange(dateRange, timeZone));
};

/**
 * Sorts an array of IsoDateRange objects in ascending or descending order.
 * @param input - The array of IsoDateRange objects to sort.
 * @param order - The order in which to sort the array. Defaults to 'DESC'.
 * @returns A sorted array of IsoDateRange objects.
 */
export const sortIsoDateRanges = (input: IsoDateRange[], order: 'ASC' | 'DESC' = 'ASC'): IsoDateRange[] => {
  const BIGGER = order === 'ASC' ? 1 : -1;
  const SMALLER = order === 'ASC' ? -1 : 1;
  return input.sort((a, b) => {
    if (a.start === b.start) {
      if (a.end === b.end) {
        return 0;
      }
      return a.end > b.end ? BIGGER : SMALLER;
    }
    return a.start > b.start ? BIGGER : SMALLER;
  });
};
