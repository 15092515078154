import React from 'react';

import { Dialog, DialogProps } from '@modernloop/shared/components';
import { Box } from '@mui/material';

import { BaseProps } from 'src/components/types';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import FilterList, { FilterListProps } from './index';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type FilterListModalProps<TData, TabType extends string | number> = BaseProps &
  FilterListProps<TData, TabType> &
  Omit<DialogProps, 'children'>;

const FilterListModal = <TData, TabType extends string | number = string>({
  dataTestId,
  open,
  title,
  submitOptions,
  cancelOptions,
  onClose,
  decoration,
  ...filterListProps
}: FilterListModalProps<TData, TabType>): JSX.Element => {
  return (
    <ConditionalThemeProvider>
      <Dialog
        maxWidth="xs"
        decoration={decoration}
        title={title}
        open={open}
        submitOptions={submitOptions}
        cancelOptions={cancelOptions}
        onClose={onClose}
      >
        <Box height={460}>
          <FilterList {...filterListProps} autoFocus wrapInPaper={false} />
        </Box>
      </Dialog>
    </ConditionalThemeProvider>
  );
};

export default FilterListModal;
