import React, { FC } from 'react';

import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import DialogMoreMenu from './DialogMoreMenu';
import DialogTitle from './DialogTitle';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type DialogPageProps = {
  onClose: () => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const DialogPage: FC<DialogPageProps> = (props) => {
  const { children, onClose } = props;

  let childrenArray = React.Children.toArray(children) as React.ReactElement[];
  /**
   * This handles the below case
   * <DialogPage {...props}>
   *   {page === 1 && (
   *     <>
   *       <DialogMoreMenu />
   *       <DialogTitle>...</DialogTitle>
   *       <DialogContent>...</DialogContent>
   *       <DialogActions>...</DialogActions>
   *     </>
   *   )}
   *   {page === 2 && (
   *     <>
   *       <DialogMoreMenu />
   *       <DialogTitle>...</DialogTitle>
   *       <DialogContent>...</DialogContent>
   *       <DialogActions>...</DialogActions>
   *     </>
   *   )}
   * <DialogPage>
   */
  if (childrenArray.length === 1 && childrenArray[0].type === React.Fragment) {
    childrenArray = React.Children.toArray(childrenArray[0].props.children) as React.ReactElement[];
  }

  if (childrenArray.length < 2) {
    throw new Error('DialogPage must have atleast 2 children: DialogActions and DialogContent');
  }

  const moreMenu = childrenArray.filter((child) => child.type === DialogMoreMenu);
  const title = childrenArray.filter((child) => child.type === DialogTitle);
  const content = childrenArray.filter((child) => child.type === DialogContent);
  const actions = childrenArray.filter((child) => child.type === DialogActions);

  if (moreMenu.length > 1) {
    throw new Error('DialogPage can only have 1 DialogMoreMenu');
  }

  if (title.length > 1) {
    throw new Error('DialogPage can only have 1 DialogTitle');
  }

  if (content.length !== 1) {
    throw new Error('DialogPage must have DialogContent as a child');
  }

  if (actions.length !== 1) {
    throw new Error('DialogPage must have DialogActions as a child');
  }

  return (
    <>
      {moreMenu.length ? moreMenu : <DialogMoreMenu onClose={onClose} />}
      {title}
      {content}
      {actions}
    </>
  );
};

export default DialogPage;

export { default as DialogActions } from './DialogActions';
export { default as DialogContent } from './DialogContent';
export { default as DialogMoreMenu } from './DialogMoreMenu';
export { default as DialogTitle } from './DialogTitle';
