/**
 * Number based feature flags.
 *
 * https://docs.launchdarkly.com/home/getting-started/feature-flags
 */

export const NUMBER_FEATURE_FLAGS = [
  'user_task_polling_seconds',
  'user_interview_plan_auto_save_debounce_timeout',
] as const;

export type FeatureFlagNumber = typeof NUMBER_FEATURE_FLAGS[number];
