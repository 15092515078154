import React from 'react';

import { find, toArray } from 'lodash';

import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';

import { useOrgAtsService } from 'src/hooks/atsService';
import useDisplayTimezone from 'src/hooks/useDisplayTimezone';
import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';

import { FileStatus, FileUploadFlow } from 'src/slices/files';
import {
  confirmUpdateSchedule,
  updateCandidateCommunicationsEnabled,
  updateCandidateEventCommunicationsEnabled,
} from 'src/slices/scheduling';

import { getScheduleContent } from 'src/store/selectors/schedule-communications';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';
import { BaseCommunicationsPreview } from 'src/views-new/ScheduleFlow/Steps/Communications/CommunicationsPreview';
import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useDispatch, useSelector } from 'src/store';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type UpdateCommunicationsPreviewProps = {
  onComplete: () => void;
};

const UpdateCommunicationsPreview: React.FC<UpdateCommunicationsPreviewProps> = ({ onComplete }) => {
  const dispatch = useDispatch();
  const { logEvent } = useLogEvent();
  const timezone = useDisplayTimezone();

  const atsService = useOrgAtsService();

  const scheduleFlowData = useScheduleFlowData();

  const helpCenterUrl = ZenDeskHelpCenterUrl.RESCHEDULING_AND_UPDATING_INTERVIEWS;

  const {
    stepScheduleContent: { error, isWaiting, isSubmitting },
    candidateCommunicationsEnabled,
    candidateEventCommunicationsEnabled,
    hasSameContent,
    isPrivateCalendarEvent,
    originalCalendarPrivacy,
    selectedScheduleId,
  } = useSelector((state) => state.scheduling);
  const scheduleContent = useSelector(getScheduleContent);

  const { applicationId, applicationStageId, candidateTimezone, interviewPlanJobStageId } = scheduleFlowData;

  const {
    slackChannelEnabled: slackChannelEnabledV2,
    slackChannelName: slackChannelNameV2,
    prevSlackChannelName: prevSlackChannelNameV2,
  } = useSelector((state) => state.scheduleCommunications.byId[selectedScheduleId || ''] || {});

  const handleSubmit = async (internalOnly?: boolean): Promise<void> => {
    // If we don't have ats then there are other bigger issues
    if (!atsService) return;

    const logUpdateEvent = () => {
      logEvent(LoggerEvent.CLIENT_UPDATE_FLOW_COMPLETE, { applicationID: applicationId, internalOnly });
      onComplete();
    };

    // TODO actionCall to java backend update api ->cook object as per java contract
    dispatch(
      confirmUpdateSchedule(atsService, logUpdateEvent, {
        applicationStageId: applicationStageId || '',
        candidateTimezone,
        jobStageId: interviewPlanJobStageId,
      })
    );
  };

  const handleEnableCandidateCommunications = async (): Promise<void> => {
    dispatch(updateCandidateCommunicationsEnabled(true));
    dispatch(updateCandidateEventCommunicationsEnabled(true));
  };

  const dictFiles = useSelector((state) => state.fileUpload[FileUploadFlow.SCHEDULING]);
  const filesArr = toArray(dictFiles);
  const isAttachmentUploading = !!find(filesArr, { status: FileStatus.UPLOADING });
  const isAttachmentErrored = !!find(filesArr, { status: FileStatus.ERRORED });
  const hasZeroInterviews = scheduleContent?.interviewerEventContents.length === 0;

  if (!scheduleContent) return null;

  return (
    <BaseCommunicationsPreview
      applicationId={applicationId}
      timezone={timezone}
      error={error}
      submitting={isSubmitting}
      disabled={isAttachmentUploading || isAttachmentErrored || hasZeroInterviews || isWaiting}
      scheduleContent={scheduleContent}
      slackChannelEnabled={slackChannelEnabledV2}
      slackChannelName={slackChannelNameV2 || ''}
      prevSlackChannelName={prevSlackChannelNameV2}
      candidateCommunicationsEnabled={candidateCommunicationsEnabled}
      candidateEventCommunicationsEnabled={candidateEventCommunicationsEnabled}
      hasSameContent={hasSameContent}
      onSubmit={handleSubmit}
      onEnableCandidateCommuncations={handleEnableCandidateCommunications}
      scheduleFlowType={ScheduleFlowType.UPDATE}
      privateEvents={isPrivateCalendarEvent}
      updateWarning="Some changes, like meeting links, may need to be updated to match changed interviews"
      learnMore={helpCenterUrl}
      candidatePrivacyChanged={isPrivateCalendarEvent !== originalCalendarPrivacy}
    />
  );
};

export default UpdateCommunicationsPreview;
