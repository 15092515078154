import { useMemo } from 'react';

import { gql } from '@apollo/client';
import { IsoDate, IsoDateFormat, IsoTimestamp, assertIsoDate } from '@modernloop/shared/datetime';
import { format, parseISO } from 'date-fns';

import {
  CompanyHolidayIcon_CompanyHolidaysFragment,
  useCompanyHolidaysForDaysQuery,
} from 'src/generated/mloop-graphql';

import CompanyHolidayIcon from 'src/entities/CompanyHolidayIcon';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CompanyHolidaysForDaysQyery = gql`
  ${CompanyHolidayIcon.fragments.companyHolidays}
  query CompanyHolidaysForDays($input: CompanyHolidaysForDaysInput!) {
    companyHolidaysForDays(input: $input) {
      items {
        date
        companyHolidays {
          ...CompanyHolidayIcon_companyHolidays
        }
      }
    }
  }
`;

export type CompanyHolidaysForDays = { [key: IsoDate]: CompanyHolidayIcon_CompanyHolidaysFragment[] };

const useGetCompanyHolidaysForDay = (startAt: IsoTimestamp, endAt: IsoTimestamp = startAt): CompanyHolidaysForDays => {
  const startAtRangeIsoDate: IsoDate = assertIsoDate(format(parseISO(startAt), IsoDateFormat));
  const endAtRangeIsoDate: IsoDate = assertIsoDate(format(parseISO(endAt), IsoDateFormat));
  const { data } = useCompanyHolidaysForDaysQuery({
    variables: {
      input: {
        startAt: startAtRangeIsoDate,
        endAt: endAtRangeIsoDate,
      },
    },
    skip: !startAtRangeIsoDate,
  });

  const dateToCompanyHolidays: CompanyHolidaysForDays = useMemo(() => {
    return (
      data?.companyHolidaysForDays?.items?.reduce((acc, current) => {
        const { date, companyHolidays } = current;
        // Check if the date key already exists, if not, initialize it as an empty array
        if (!acc[date]) {
          acc[date] = [];
        }
        // Push the rest of the object into the array for the corresponding date
        if (companyHolidays.length) {
          acc[date].push(...companyHolidays);
        }
        return acc;
      }, {}) || {}
    );
  }, [data?.companyHolidaysForDays?.items]);

  return dateToCompanyHolidays;
};

export default useGetCompanyHolidaysForDay;
