import React, { FC, MutableRefObject } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PopperJs from 'popper.js';

import Label from 'src/components/label';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/theme/type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '6px',
      boxShadow: theme.shadows[2],
      minWidth: '0px',
    },
    noMaxWidth: {
      maxWidth: 'none',
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type TooltipProps = BaseProps & {
  arrow?: MuiTooltipProps['arrow'];
  interactive?: MuiTooltipProps['interactive'];
  enterDelay?: MuiTooltipProps['enterDelay'];
  leaveDelay?: MuiTooltipProps['leaveDelay'];
  tooltip: JSX.Element | string;
  placement?: MuiTooltipProps['placement'];
  disableHoverListener?: MuiTooltipProps['disableHoverListener'];
  className?: MuiTooltipProps['className'];
  style?: MuiTooltipProps['style'];

  /**
   * popperRef is used in the case where the tooltip content can change after showing,
   * which changes the size of tooltip and can cause it to show out of the viewport.
   * https://github.com/mui-org/material-ui/issues/18310
   */
  popperRef?: MutableRefObject<PopperJs | null>;
  fullWidth?: boolean;
  displayUnset?: boolean; // used to unset display: none
};

/**
 * @deprecated - Use `Tooltip` from `@mui/material` instead
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const Tooltip: FC<TooltipProps> = ({
  dataTestId,
  children,
  arrow,
  interactive,
  enterDelay,
  leaveDelay,
  tooltip,
  placement,
  disableHoverListener,
  className,
  style,
  popperRef,
  fullWidth,
  displayUnset,
}): JSX.Element => {
  const classes = useStyles();

  if (!tooltip) return <>{children}</>;

  let tooltipJSX = tooltip;
  if (typeof tooltip === 'string') {
    tooltipJSX = <Label variant="captions">{tooltip}</Label>;
  }

  const getSpanDisplayStyle = () => {
    if (displayUnset) {
      return 'unset';
    }
    if (fullWidth) {
      return 'block';
    }
    return 'flex';
  };

  return (
    <MuiTooltip
      data-testid={dataTestId}
      title={tooltipJSX}
      placement={placement ?? 'top'}
      arrow={arrow}
      interactive={interactive}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      classes={{ tooltip: clsx(fullWidth ? classes.noMaxWidth : {}, classes.tooltip) }}
      style={style}
      disableHoverListener={disableHoverListener}
      PopperProps={{
        popperRef,
      }}
    >
      <span style={{ display: getSpanDisplayStyle() }} className={className}>
        {children}
      </span>
    </MuiTooltip>
  );
};

export default Tooltip;
