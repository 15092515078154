import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { IsoTimestamp } from '@modernloop/shared/datetime';
import { Divider, Paper } from '@mui/material';

import {
  ApplicationStatus,
  ScheduleContentInterview_CandidateEventFragment,
  ScheduleContentInterview_InterviewFragment,
} from 'src/generated/mloop-graphql';

import ScheduleContentInterviewDetails from './ScheduleContentInterviewDetails';
import ScheduleContentInterviewHeader from './ScheduleContentInterviewHeader';

type Fragments = {
  interview: ScheduleContentInterview_InterviewFragment;
  candidateEvent: ScheduleContentInterview_CandidateEventFragment | undefined;
};

type Props = {
  applicationStageId: string;
  applicationStatus: ApplicationStatus;
  timezone: string;
  frozenAt?: IsoTimestamp;
};

const ScheduleContentInterview: FCWithFragments<Fragments, Props> = ({
  applicationStageId,
  applicationStatus,
  interview,
  candidateEvent,
  timezone,
  frozenAt,
}) => {
  return (
    <Paper variant="outlined" sx={{ borderRadius: (theme) => theme.spacing(0.75) }}>
      <ScheduleContentInterviewHeader
        applicationStageId={applicationStageId}
        applicationStatus={applicationStatus}
        interview={interview}
        candidateEvent={candidateEvent}
        timezone={timezone}
        frozenAt={frozenAt}
      />
      <Divider />
      <ScheduleContentInterviewDetails interview={interview} candidateEvent={candidateEvent} />
    </Paper>
  );
};

ScheduleContentInterview.fragments = {
  interview: gql`
    ${ScheduleContentInterviewHeader.fragments.interview}
    ${ScheduleContentInterviewDetails.fragments.interview}
    fragment ScheduleContentInterview_interview on ApplicationStageInterview {
      id
      ...ScheduleContentInterviewHeader_interview
      ...ScheduleContentInterviewDetails_interview
    }
  `,
  candidateEvent: gql`
    ${ScheduleContentInterviewHeader.fragments.candidateEvent}
    ${ScheduleContentInterviewHeader.fragments.candidateEvent}
    fragment ScheduleContentInterview_candidateEvent on ApplicationStageCandidateEvent {
      id
      responseStatus
      ...ScheduleContentInterviewHeader_candidateEvent
      ...ScheduleContentInterviewHeader_candidateEvent
    }
  `,
};

export default ScheduleContentInterview;
