import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { InterviewMeetingLocationType } from 'src/generated/mloop-graphql';

import PageTabs, { PageTab } from 'src/components/PageTabs';
import Paper from 'src/components/Paper';
import { GoogleMeetIcon, LinkIcon, MicrosoftTeamsIcon, ZoomIcon } from 'src/components/icons';
import Label from 'src/components/label';

import { useIntegrations } from 'src/hooks/api/integration';
import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { updateLocation } from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { getLocation } from 'src/store/selectors/schedule-communications';
import { getInterviewerEventContents } from 'src/store/selectors/scheduling';
import { MeetingLinksConfig } from 'src/store/slices/schedule-communications';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useDispatch, useSelector } from 'src/store';

import CustomLinksSection from './CustomLinks/CustomLinksSection';
import VideoMeetingLinks from './VideoMeetingLinks';
import ZoomMeetingSection from './Zoom';

enum VideoOptionLabels {
  zoom = 'Zoom',
  google = 'Google Meet',
  microsoft = 'Microsoft Teams',
  custom = 'Custom link',
  none = 'None',
}

interface Props {
  applicationId?: string;
  customVideoMeetingURL?: string | null;
  handleZoomMeetingConfigChange?: (config: MeetingLinksConfig) => void;
  handleCustomMeetingLinkConfigChange?: (config: MeetingLinksConfig) => void;
  onMeetingLinksConfigChange?: (meetingLinksConfig: MeetingLinksConfig) => void;
  scheduleFlow: ScheduleFlowType;
  meetingLinkConfig?: MeetingLinksConfig;
  selectedScheduleId: string;
}

const LocationCard: FC<Props> = ({
  applicationId,
  customVideoMeetingURL,
  scheduleFlow,
  handleZoomMeetingConfigChange,
  meetingLinkConfig,
  selectedScheduleId,
  handleCustomMeetingLinkConfigChange,
  onMeetingLinksConfigChange,
}) => {
  const dispatch = useDispatch();
  const integrations = useIntegrations();
  const hasZoom = Boolean(integrations.data?.zoom?.active);
  const interviewerEventContents = useSelector(getInterviewerEventContents);
  const location = useSelector((state) => getLocation(state, selectedScheduleId));
  const schedule = useScheduleWithoutBreaks(selectedScheduleId);

  const [initialLocationSet, setInitialLocationSet] = useState(false);

  const setLocation = useCallback(
    (eventLocation: InterviewMeetingLocationType) => {
      if (!eventLocation) return;

      dispatch(updateLocation(selectedScheduleId, eventLocation));

      if (scheduleFlow === ScheduleFlowType.UPDATE) {
        if (eventLocation !== InterviewMeetingLocationType.Zoom) {
          if (schedule && schedule.events) {
            schedule.events.map((interview) => {
              return dispatch(
                addInterviewScheduleUpdate({
                  type: 'ScheduleUpdateVideoUrl',
                  updateType: ScheduleUpdateType.NEW,
                  scheduleId: schedule.id,
                  applicationStageInterviewId: interview.id,
                })
              );
            });
          }
        }
      }
    },
    [scheduleFlow, dispatch, selectedScheduleId, schedule]
  );

  const handleTabChange = (value: InterviewMeetingLocationType) => {
    setLocation(value);
  };

  useEffect(() => {
    if (hasZoom && !initialLocationSet && location === InterviewMeetingLocationType.NotSpecified) {
      setLocation(InterviewMeetingLocationType.Zoom);
      setInitialLocationSet(true);
    }
  }, [hasZoom, initialLocationSet, location, setLocation]);

  return (
    <Paper color="alternate">
      <Label variant="body" fontWeight={600}>
        Video Meeting Link
      </Label>
      <Box mt={2} mb={1}>
        <PageTabs /* currentValue={activeTab} */ currentValue={location} onChange={handleTabChange}>
          {hasZoom && (
            <PageTab value={InterviewMeetingLocationType.Zoom} label={VideoOptionLabels.zoom} icon={<ZoomIcon />} />
          )}
          {integrations.data?.google && (
            <PageTab
              value={InterviewMeetingLocationType.GoogleMeet}
              label={VideoOptionLabels.google}
              icon={<GoogleMeetIcon />}
            />
          )}
          {(integrations.data?.microsoft_gcc_high?.active || integrations.data?.microsoft?.active) && (
            <PageTab
              value={InterviewMeetingLocationType.MicrosoftTeams}
              label={VideoOptionLabels.microsoft}
              icon={<MicrosoftTeamsIcon />}
            />
          )}
          <PageTab
            value={InterviewMeetingLocationType.CustomLink}
            label={VideoOptionLabels.custom}
            icon={<LinkIcon />}
          />
          <PageTab value={InterviewMeetingLocationType.NotSpecified} label={VideoOptionLabels.none} />
        </PageTabs>
      </Box>
      {location === InterviewMeetingLocationType.Zoom ? (
        <ZoomMeetingSection
          scheduleFlow={scheduleFlow}
          applicationId={applicationId}
          zoomMeetingConfig={meetingLinkConfig}
          showUniqueLinksCheckbox={Boolean(interviewerEventContents && interviewerEventContents?.length > 1)}
          handleZoomMeetingConfigChange={handleZoomMeetingConfigChange}
        />
      ) : null}
      {(location === InterviewMeetingLocationType.GoogleMeet ||
        location === InterviewMeetingLocationType.MicrosoftTeams) &&
        selectedScheduleId && (
          <VideoMeetingLinks
            scheduleId={selectedScheduleId}
            location={location}
            meetingLinksConfig={meetingLinkConfig}
            onMeetingLinksConfigChange={onMeetingLinksConfigChange}
          />
        )}
      {location === InterviewMeetingLocationType.CustomLink ? (
        <CustomLinksSection
          title="Custom Meeting Link"
          interviewerEventContents={interviewerEventContents}
          location={location}
          customMeetingLinkConfig={meetingLinkConfig}
          scheduleFlow={scheduleFlow}
          customVideoMeetingURL={customVideoMeetingURL}
          handleCustomMeetingLinkConfigChange={handleCustomMeetingLinkConfigChange}
          schedule={schedule}
        />
      ) : null}
    </Paper>
  );
};

export default LocationCard;
