import React, { FC, useState } from 'react';

import { Dialog } from '@modernloop/shared/components';
import { Box, Stack, Tab, Tabs } from '@mui/material';

import useOrgId from 'src/hooks/useOrgId';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import BrowserTab from './Tabs/BrowserTab';
import CandidateTab from './Tabs/CandidateTab';
import FeatureFlagsTab from './Tabs/FeatureFlagsTab';
import TasksTab from './Tabs/TasksTab';
import TimezonesTab from './Tabs/TimezonesTab';

type Props = {
  onClose: () => void;
};

const E2E_ORG_ID = 'c14d06f6-f99b-4812-90d3-0061c5b3224d';

const DevToolsModal: FC<Props> = ({ onClose }) => {
  const [tab, setTab] = useState('Browser');
  const orgId = useOrgId();

  return (
    <ConditionalThemeProvider>
      <Dialog maxWidth="xl" open title="Dev Tools" onClose={onClose} enableBackdropClickClose enableEscapeClose>
        <Box height={500}>
          <Stack spacing={1}>
            <Tabs value={tab} onChange={(__, newValue) => setTab(newValue)}>
              <Tab value="Browser" label="Values/Browser" />
              <Tab value="Tasks" label="Tasks" />
              <Tab value="Timezones" label="Timezones" />
              <Tab value="Feature flags" label="Feature flags" />
              {orgId === E2E_ORG_ID && <Tab value="Candidate" label="Candidate" />}
            </Tabs>

            {tab === 'Browser' && <BrowserTab />}

            {tab === 'Tasks' && <TasksTab />}

            {tab === 'Timezones' && <TimezonesTab />}

            {tab === 'Feature flags' && <FeatureFlagsTab />}

            {tab === 'Candidate' && <CandidateTab onClose={onClose} />}
          </Stack>
        </Box>
      </Dialog>
    </ConditionalThemeProvider>
  );
};

export default DevToolsModal;
