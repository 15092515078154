import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import IsoTimestamp from 'src/types/IsoTimestamp';

export type ScheduleDatesFilter = IsoTimestamp[];

export type ScheduleFilters = {
  dates?: ScheduleDatesFilter;
};

const getInitialState = (): ScheduleFilters => {
  return {};
};

const scheduleFiltersSlice = createSlice({
  name: 'scheduleFilters',
  initialState: getInitialState(),
  reducers: {
    clearFilters(state: ScheduleFilters) {
      if (Object.keys(state).length) {
        state = getInitialState();
      }
      return state;
    },
    clearDateFilter(state: ScheduleFilters) {
      if (state.dates) {
        state.dates = undefined;
      }
    },
    setDateFilter(state: ScheduleFilters, action: PayloadAction<{ dates: ScheduleDatesFilter }>) {
      const { dates } = action.payload;
      state.dates = dates;
    },
  },
});

export const { reducer } = scheduleFiltersSlice;
export default scheduleFiltersSlice;
