import { formatToTimeZone } from './Format';
import { IsoDate, IsoDateFormat, assertIsoDate } from './IsoDate';
import IsoTimestamp, { assertIsoTimestamp } from './IsoTimestamp';
import getTZAbbr from './getTZAbbr';
import {
  add,
  addMilliseconds as dateFnsAddMilliseconds,
  addMinutes as dateFnsAddMinutes,
  addBusinessDays as dateFnsAddBusinessDays,
  endOfDay as dateFnsEndOfDay,
  endOfMonth as dateFnsEndOfMonth,
  endOfWeek as dateFnsEndOfWeek,
  startOfDay as dateFnsStartOfDay,
  startOfMonth as dateFnsStartOfMonth,
  startOfWeek as dateFnsStartOfWeek,
  parseISO,
} from 'date-fns';
import { format, getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const startOfDay = (dateInput: IsoTimestamp, tz: string): IsoTimestamp => {
  const date = utcToZonedTime(dateInput, tz);
  const start = dateFnsStartOfDay(date);
  return assertIsoTimestamp(zonedTimeToUtc(start, tz).toISOString());
};

export const startOfWeek = (dateInput: IsoTimestamp, tz: string): IsoTimestamp => {
  const date = utcToZonedTime(dateInput, tz);
  const start = dateFnsStartOfWeek(date);
  return assertIsoTimestamp(zonedTimeToUtc(start, tz).toISOString());
};

export const startOfWeekFromDate = (dateInput: IsoDate): IsoDate => {
  const start = dateFnsStartOfWeek(new Date(dateInput), { weekStartsOn: 0 });
  return assertIsoDate(format(start, IsoDateFormat));
};

export const endOfDay = (dateInput: IsoTimestamp, tz: string): IsoTimestamp => {
  const date = utcToZonedTime(dateInput, tz);
  const end = dateFnsEndOfDay(date);
  return assertIsoTimestamp(zonedTimeToUtc(end, tz).toISOString());
};

export const endOfWeek = (dateInput: IsoTimestamp, tz: string): IsoTimestamp => {
  const date = utcToZonedTime(dateInput, tz);
  const end = dateFnsEndOfWeek(date);
  return assertIsoTimestamp(zonedTimeToUtc(end, tz).toISOString());
};

export const endOfWeekFromDate = (dateInput: IsoDate): IsoDate => {
  const end = dateFnsEndOfWeek(new Date(dateInput), { weekStartsOn: 0 });
  return assertIsoDate(format(end, IsoDateFormat));
};

// eslint-disable-next-line max-params
export const addMinutes = (dateInput: IsoTimestamp, tz: string, minutes: number): IsoTimestamp => {
  const zonedTime = utcToZonedTime(dateInput, tz);
  const newTime = dateFnsAddMinutes(zonedTime, minutes);
  return assertIsoTimestamp(zonedTimeToUtc(newTime, tz).toISOString());
};

// eslint-disable-next-line max-params
export const addDays = (dateInput: IsoTimestamp, tz: string, days: number): IsoTimestamp => {
  const date = startOfDay(dateInput, tz);
  return assertIsoTimestamp(add(parseISO(date), { days }).toISOString());
};

export const addDaysToDate = (dateInput: IsoDate, days: number): IsoDate => {
  // add days starting from IsoDate
  const newDate = add(parseISO(dateInput), { days });
  return assertIsoDate(format(newDate, IsoDateFormat));
};

// eslint-disable-next-line max-params
export const addWeeks = (dateInput: IsoTimestamp, tz: string, weeks: number): IsoTimestamp => {
  const date = startOfDay(dateInput, tz);
  return assertIsoTimestamp(add(parseISO(date), { weeks }).toISOString());
};

// eslint-disable-next-line max-params
export const addMonths = (dateInput: IsoTimestamp, tz: string, months: number): IsoTimestamp => {
  const date = startOfDay(dateInput, tz);
  return assertIsoTimestamp(add(parseISO(date), { months }).toISOString());
};

export const startOfMonth = (dateInput: IsoTimestamp, tz: string): IsoTimestamp => {
  const date = utcToZonedTime(dateInput, tz);
  const start = dateFnsStartOfMonth(date);
  return assertIsoTimestamp(zonedTimeToUtc(start, tz).toISOString());
};

export const endOfMonth = (dateInput: IsoTimestamp, tz: string): IsoTimestamp => {
  const date = utcToZonedTime(dateInput, tz);
  const start = dateFnsEndOfMonth(date);
  return assertIsoTimestamp(zonedTimeToUtc(start, tz).toISOString());
};

/**
 * If the local time object passed has 14:30 then it will return time which translates to 14:30 in the timezone.
 * E.g. time ('2021-12-12T09:00:00.000Z') represents 2:30 PM in 'Asia/Kolkata' (local time) and timezone is 'America/Los_Angeles'
 *      then it will return ('2021-12-12T22:30:00.000Z') which is 2:30 PM in 'America/Los_Angeles'
 * @param time in local timezone
 * @param timezone to which the time needs to be returned in.
 * @param hours the hour value of the time returned.
 * @param minutes the minute value of the time returned.
 * @returns Returns the time in the timezone represented by hours and minutes.
 */
export const getTimeInTimezone = (
  time: IsoTimestamp,
  timezone: string,
  hours?: number,
  minutes?: number
  // eslint-disable-next-line max-params
): IsoTimestamp => {
  const newDate = parseISO(time);
  const result = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()));
  result.setUTCHours(hours ?? newDate.getHours(), minutes ?? newDate.getMinutes(), 0, 0);
  return assertIsoTimestamp(dateFnsAddMilliseconds(result, -getTimezoneOffset(timezone, result)).toISOString());
};

// eslint-disable-next-line max-params
export const isSameDay = (d1: IsoTimestamp, d2: IsoTimestamp, tz: string) => {
  return (
    format(utcToZonedTime(d1, tz), IsoDateFormat, { timeZone: tz }) ===
    format(utcToZonedTime(d2, tz), IsoDateFormat, { timeZone: tz })
  );
};

export const getUniqueDays = (dates: IsoTimestamp[], tz: string) => {
  const uniqueStartOfDays: IsoTimestamp[] = [];
  dates.forEach((date) => {
    const start = startOfDay(date, tz);
    if (uniqueStartOfDays.includes(start)) return;
    uniqueStartOfDays.push(start);
  });

  return uniqueStartOfDays;
};

// eslint-disable-next-line max-params
export const addBusinessDays = (dateInput: IsoTimestamp, tz: string, days: number): IsoTimestamp => {
  const zonedTime = utcToZonedTime(dateInput, tz);
  const newDate = dateFnsAddBusinessDays(zonedTime, days);
  const utcTime = zonedTimeToUtc(newDate, tz);
  return assertIsoTimestamp(utcTime.toISOString());
};

/**
 *  10:30 am - 12 pm
 *  12 - 12:30 pm
 *  9:45 - 10:30 am
 */
export const getEventTimeRange = (
  startAt: IsoTimestamp,
  endAt: IsoTimestamp,
  tz: string,
  options?: {
    showFullTime: boolean;
    showTimezoneAbbreviation: boolean;
    useUppercaseMerridian: boolean;
  }
  // eslint-disable-next-line max-params
): string => {
  const timeOptions = options ?? {
    showFullTime: false,
    showTimezoneAbbreviation: true,
    useUppercaseMerridian: true,
  };
  const sameMeridiem = formatToTimeZone(startAt, 'a', tz) === formatToTimeZone(endAt, 'a', tz);
  const startHasMinutes = formatToTimeZone(startAt, 'mm', tz) !== '00';
  const endHasMinutes = formatToTimeZone(endAt, 'mm', tz) !== '00';
  const merridianFormat = timeOptions.useUppercaseMerridian ? 'a' : 'aaa';
  const startFormat =
    startHasMinutes || timeOptions.showFullTime
      ? `h:mm${sameMeridiem ? '' : ` ${merridianFormat}`}`
      : `h${sameMeridiem ? '' : ` ${merridianFormat}`}`;
  const endFormat = endHasMinutes || timeOptions.showFullTime ? `h:mm ${merridianFormat}` : `h ${merridianFormat}`;
  return `${formatToTimeZone(startAt, startFormat, tz)} - ${formatToTimeZone(endAt, endFormat, tz)} ${
    timeOptions.showTimezoneAbbreviation ? getTZAbbr(tz) : ''
  }`;
};

// helper funtion for adding milliseconds to a date
// eslint-disable-next-line max-params
export const addMilliseconds = (dateInput: IsoTimestamp, tz: string, milliseconds: number): IsoTimestamp => {
  const zonedTime = utcToZonedTime(dateInput, tz);
  const newDate = dateFnsAddMilliseconds(zonedTime, milliseconds);
  const utcTime = zonedTimeToUtc(newDate, tz);
  return assertIsoTimestamp(utcTime.toISOString());
};
