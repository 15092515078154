import { BaseProps } from '@modernloop/shared/components';
import { CaretRightIcon } from '@modernloop/shared/icons';
import { Menu, MenuProps, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { FC, useRef, useState } from 'react';

type Props = BaseProps & {
  // Start icon for the menu item. This will be displayed before the title.
  startIcon?: React.ReactNode;
  title: string;
  sx?: MenuProps['sx'];
  onClose: MenuProps['onClose'];
};

const NestedMenuItem: FC<Props> = ({ children, title, startIcon, sx, dataTestId, onClose }) => {
  const menuItemRef = useRef<HTMLLIElement>(null);
  const [open, setOpen] = useState(false);

  const handleHoverOnMenuItem = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const x = e.nativeEvent.x;
    const y = e.nativeEvent.y;

    // Check if the mouse pointer is currently hovering on the menu item.
    const currentTargetRect = e.currentTarget.getBoundingClientRect();
    const isHoverOnMenuItem =
      x > currentTargetRect.x && x < currentTargetRect.right && y > currentTargetRect.y && y < currentTargetRect.bottom;

    if (isHoverOnMenuItem) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <MenuItem
      ref={menuItemRef}
      data-testid={dataTestId}
      onClick={() => setOpen(true)}
      onKeyDown={(e: React.KeyboardEvent<HTMLLIElement>) => {
        if (e.key === 'Enter' || e.key === 'ArrowRight') {
          setOpen(true);
        }
      }}
      selected={open}
      onMouseMove={handleHoverOnMenuItem}
    >
      {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
      <ListItemText>{title}</ListItemText>
      <CaretRightIcon />

      {open && (
        <Menu
          open
          anchorEl={menuItemRef.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          MenuListProps={{
            onMouseMove: (e) => {
              // This is to prevent the parent menu from closing when the mouse is over the nested menu
              // It prevents the event from bubbling up to the parent menu
              e.stopPropagation();
            },
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'ArrowLeft') {
              setOpen(false);
            }
            e.preventDefault();
            e.stopPropagation();
          }}
          // eslint-disable-next-line no-restricted-syntax
          sx={sx}
          onClose={onClose}
        >
          {children}
        </Menu>
      )}
    </MenuItem>
  );
};

export default NestedMenuItem;
