import React, { useMemo } from 'react';

import { ListItem, useTheme } from '@mui/material';
import MuiAutocomplete, {
  AutocompleteRenderGetTagProps as Mui5AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState as Mui5AutocompleteRenderOptionState,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';

import VirtualList from 'src/components/VirtualList';
import { WarningIcon } from 'src/components/icons';
import { BaseProps, createSxProps } from 'src/components/types';

import TextField from '../TextField';
import Chip from '../chip';

import AutocompleteDeprecated, { AutocompleteProps as Mui4AutocompleteProps } from './AutocompleteDeprecated';

export { useAutocompleteStyles } from './AutocompleteDeprecated';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Optional<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  error?: boolean;
  idField?: string; // field on the options objects to use as the id
  renderTags?: (values: T[], getTagProps: Mui5AutocompleteRenderGetTagProps) => React.ReactNode; // function to render custom tags
  renderOption?: (option: T, state: Mui5AutocompleteRenderOptionState) => React.ReactNode;
} & BaseProps;

export const useSxProps = ({ error }: { error: boolean | undefined }) => {
  const theme = useTheme();
  const sxProps = useMemo(() => {
    return {
      root: () => ({
        '& .MuiAutocomplete-root': {
          border: `1px solid ${error ? theme.palette.error.light : theme.palette.border}`,
          backgroundColor: theme.palette.background.default,
          borderRadius: '6px',
          padding: '2px 8px',
        },
        '& .MuiInput-underline:before': {
          border: 'none',
        },
        '&:hover .MuiInput-underline:before': {
          border: 'none',
        },
        '& .MuiInput-underline:after': {
          border: 'none',
        },
        '&:hover .MuiInput-underline:after': {
          border: 'none',
        },
        '& .MuiAutocomplete-endAdornment': {
          top: 'auto',
        },
      }),
    };
  }, [error, theme.palette.error.light, theme.palette.border, theme.palette.background.default]);
  return createSxProps(sxProps);
};

export const AutocompleteBase = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
): JSX.Element => {
  const { renderInput } = props;
  const { error, dataTestId, idField = 'id', getOptionLabel, renderTags, renderOption, ...restProps } = props;
  const sxProps = useSxProps({ error });

  const getIdValue = (value: T) => {
    return idField ? value[idField] : '';
  };

  const getLabelValue = (value: T) => {
    return getOptionLabel ? getOptionLabel(value) : '';
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  const renderTagDefault = (value: T, index: number, getTagProps: Mui5AutocompleteRenderGetTagProps) => {
    return (
      <Chip
        key={typeof value === 'string' ? value : getIdValue(value) ?? ''}
        label={typeof value === 'string' ? value : getLabelValue(value) ?? ''}
        variant="default"
        tagProps={getTagProps({ index })}
      />
    );
  };

  const renderTagsDefault = (values: T[], getTagProps: Mui5AutocompleteRenderGetTagProps): React.ReactNode => {
    return values.map((value, index) => renderTagDefault(value, index, getTagProps));
  };

  const defaultRenderInput = (params) => (
    <TextField
      error={error}
      dataTestId="autocomplete-textfield"
      {...params}
      InputProps={{
        ...params.InputProps,
        startAdornment: error ? <WarningIcon color="error" /> : params?.InputProps?.startAdornment ?? undefined,
      }}
    />
  );

  const wrappedRenderOption = (
    renderOptionProps: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: { inputValue: string; selected: boolean; index: number }
    // eslint-disable-next-line max-params
  ) => {
    if (renderOption) {
      return <ListItem {...renderOptionProps}> {renderOption(option, state)}</ListItem>;
    }

    return null;
  };

  return (
    <MuiAutocomplete
      sx={sxProps.root}
      data-testid={dataTestId}
      disableListWrap
      ListboxComponent={VirtualList as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      renderInput={renderInput || defaultRenderInput}
      renderTags={renderTags || renderTagsDefault}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption ? wrappedRenderOption : undefined}
      {...restProps}
    />
  );
};

/**
 * @deprecated - Use `Autocomplete` from `@mui/material` instead
 */
// eslint-disable-next-line react/no-multi-comp
const AutocompleteWrapper = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props:
    | AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
    | Mui4AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
): JSX.Element => {
  return <AutocompleteDeprecated {...(props as Mui4AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>)} />;
};

export default AutocompleteWrapper;
