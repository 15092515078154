import React, { useCallback, useEffect, useMemo } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { v4 as uuid } from 'uuid';

import { JobStageInterviewGroupInput } from 'src/generated/mloop-graphql';

import { getJobStageById } from 'src/store/selectors/job-stage';

import { BaseInterviewPlan } from 'src/views-new/InterviewPlan';
import { useFetchInterviewPlanForJobStage } from 'src/views-new/InterviewPlan/UseFetchInterviewPlan';
import { useSaveInterviewPlanInput } from 'src/views-new/InterviewPlan/useSaveInterviewPlan';
import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useSelector } from 'src/store';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type InterviewPlanWrapperProps = {
  /**
   * This is used as a key to force remount of the component when the jobStageId changes.
   * This is needed to ensure that we create a new custom interview plan when the jobStageId changes.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  key: string;

  /**
   * Original job stage id.
   */
  jobStageId: string;

  /**
   * To be used while displaying interview plan in task side panel.
   */
  customJobStageId?: string;

  /**
   * To be used while displaying interview plan in task side panel so that any changes made are auto saved.
   */
  autoSave?: boolean;

  /**
   * To be used to prevent interview plan from being edited.
   * We need this when there is a active self schedule request.
   */
  readonly?: boolean;

  /**
   * When interview plan is changed, primarily to be used when creating a task.
   */
  onChange?: (customJobStageId: string, groups: JobStageInterviewGroupInput[]) => Promise<void>;

  /**
   * Called when auto save is complete.
   */
  onAutoSaveComplete?: (complete: boolean) => void;
};

/**
 * Supports 2 cases:
 * - Create task flow:
 *   ▶ Create a new customJobStageId and custom interview plan each time the jobStageId changes.
 *   ▶ We don't persist custom interview plan to DB until the task is created so if user changes job stage
 *     we create a fresh interview plan and discard the old one.
 *   ▶ Any changes to interview plan will be communicated through onChange.
 *
 * - Task side panel where we have custom interview plan for that task:
 *   ▶ Will fetch interview plan against customJobStageId.
 *   ▶ Any changes made will be auto saved.
 */
const InterviewPlanWrapper = ({
  jobStageId,
  customJobStageId,
  autoSave,
  readonly,
  onChange,
  onAutoSaveComplete,
}: InterviewPlanWrapperProps) => {
  const finalJobStageId = useMemo(() => customJobStageId ?? uuid(), [customJobStageId]);

  useFetchInterviewPlanForJobStage(customJobStageId || jobStageId, finalJobStageId);

  const jobStage = useSelector((state) => getJobStageById(state, finalJobStageId));
  const groups = useSaveInterviewPlanInput(finalJobStageId);

  useEffect(() => {
    if (!onChange || !jobStage) return;

    onChange(finalJobStageId, groups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobStage]);

  const handleChange = useCallback(async () => {
    if (!onChange) return;
    onChange(finalJobStageId, groups);
  }, [finalJobStageId, groups, onChange]);

  if (!jobStage) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress size={32} />
      </Box>
    );
  }

  return (
    <BaseInterviewPlan
      interviewPlanJobStageId={jobStage.id}
      schedulable={false}
      scheduleFlowType={ScheduleFlowType.CREATE_TASK}
      autoSave={autoSave}
      readonly={readonly}
      onSave={autoSave ? undefined : handleChange}
      onAutoSaveComplete={onAutoSaveComplete}
    />
  );
};

export default InterviewPlanWrapper;
