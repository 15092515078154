import React, { FC, useEffect, useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/styles';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Stack } from '@mui/material';
import { isEmpty, isEqual } from 'lodash';

import { InterviewMeetingLocationType } from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import TextField from 'src/components/TextField';
import Button from 'src/components/button';
import { WarningIcon } from 'src/components/icons';

import { setScheduleContentStepWaiting } from 'src/slices/scheduling';

import { updateCustomVideoMeetingLink } from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useDispatch } from 'src/store';

import usePopulateCustomInterviewMeetingLocation from './usePopulateCustomInterviewMeetingLocation';

const useStyles = makeStyles(() => ({
  textField: { width: '80%' },
  submitButton: { width: 'max-content' },
}));

enum SubmitButtonStates {
  Apply,
  Applying,
  Applied,
}

const SubmitButtonStateStringsMap = {
  [SubmitButtonStates.Apply]: 'Apply',
  [SubmitButtonStates.Applying]: 'Applying...',
  [SubmitButtonStates.Applied]: '✓ Applied!',
};

interface Props {
  scheduleFlow: ScheduleFlowType;
  location: InterviewMeetingLocationType;
  customVideoMeetingURL?: string | null;
  schedule: InterviewSchedule | null;
}

const CustomLinkSingle: FC<Props> = ({ scheduleFlow, schedule, location, customVideoMeetingURL }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const populateVideoInterviewMeetingLocation = usePopulateCustomInterviewMeetingLocation({
    scheduleId: schedule?.id || '',
    interviewMeetingLocationType: location,
  });

  const [inputText, setInputText] = useState<string>(customVideoMeetingURL ?? '');
  const [submitButtonState, setSubmitButtonState] = useState<SubmitButtonStates>(SubmitButtonStates.Apply);

  const savedLink = useRef<string>(inputText);

  useEffect(() => {
    setInputText(customVideoMeetingURL ?? '');
  }, [customVideoMeetingURL]);

  useEffect(() => {
    if (scheduleFlow !== ScheduleFlowType.UPDATE && !customVideoMeetingURL) {
      dispatch(setScheduleContentStepWaiting(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onSubmit = async () => {
    setSubmitButtonState(SubmitButtonStates.Applying);

    if (schedule && schedule?.events) {
      schedule?.events?.forEach((interview) => {
        dispatch(
          addInterviewScheduleUpdate({
            type: 'ScheduleUpdateVideoUrl',
            updateType: ScheduleUpdateType.EDIT,
            scheduleId: schedule?.id || '',
            applicationStageInterviewId: interview.id,
          })
        );
      });
    }

    try {
      savedLink.current = inputText;

      setSubmitButtonState(SubmitButtonStates.Applied);
    } catch (e) {
      setSubmitButtonState(SubmitButtonStates.Apply);
    }
  };

  const onCustomLinkChange = () => {
    if (schedule?.id) {
      if (scheduleLocationEnabled) {
        populateVideoInterviewMeetingLocation({ customLocation: inputText });
      } else {
        dispatch(updateCustomVideoMeetingLink(schedule.id, inputText));
      }
    }

    if (!isEqual(savedLink.current, inputText) && submitButtonState !== SubmitButtonStates.Apply) {
      setSubmitButtonState(SubmitButtonStates.Apply);
    }
  };

  const disableSubmitButton =
    isEqual(savedLink.current, inputText) ||
    isEmpty(inputText) ||
    submitButtonState === SubmitButtonStates.Applied ||
    submitButtonState === SubmitButtonStates.Applying;

  const displayAlert =
    !isEqual(savedLink.current, inputText) && submitButtonState === SubmitButtonStates.Apply && !disableSubmitButton;

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <TextField
          disabled={submitButtonState === SubmitButtonStates.Applying}
          className={classes.textField}
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
          onBlur={onCustomLinkChange}
          fullWidth
        />

        <Button
          label={SubmitButtonStateStringsMap[submitButtonState]}
          variant="outlined"
          disabled={disableSubmitButton}
          onClick={onSubmit}
          className={classes.submitButton}
        />
      </Stack>
      {displayAlert ? (
        <Alert
          sx={{ width: 'max-content' }}
          icon={<WarningIcon color="warning" />}
          status="warning"
          title="You have not applied your changes."
        />
      ) : null}
    </Stack>
  );
};

export default CustomLinkSingle;
