import React, { useEffect, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';
import { Search } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { isEmpty } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

import {
  EmployeeFilterFragment,
  InterviewModuleMemberSearchOrderByProperty,
  Sort,
  useInterviewModuleMemberSearchLazyQuery,
} from 'src/generated/mloop-graphql';

import PageTabs, { PageTab } from 'src/components/PageTabs';
import Paper from 'src/components/Paper';
import Button from 'src/components/button';
import AttributeIcon from 'src/components/icons/Attribute';
import DirectoryIcon from 'src/components/icons/Directory';
import { TabProps } from 'src/components/tabs';

import { clearJobStageInterviewSeatFilters } from 'src/store/actions/job-stage-interview-seat';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import { DEBOUNCE_TIMEOUT, PAGE_SIZE } from 'src/constants';
import { useDispatch } from 'src/store';

import AttributesFilter from './AttributesFilter';
import EmployeeFilter from './EmployeeFilter';

enum EmployeeAndAttributesFilterTabs {
  ATTRIBUTES = 'attributes',
  PEOPLE = 'people',
}

type Props = {
  interviewModuleMembers: EmployeeFilterFragment[];
  interviewModuleId: string;
  seat: JobStageInterviewSeat;
  skipRedux?: boolean;
  onUpdated: (seat: JobStageInterviewSeat) => void;
};

const useSxProps = () => {
  return useMemo(() => {
    return {
      paper: {
        minWidth: '360px',
      },
    };
  }, []);
};

const TABS: TabProps<string>[] = [
  {
    id: EmployeeAndAttributesFilterTabs.PEOPLE,
    label: 'People',
  },
  {
    id: EmployeeAndAttributesFilterTabs.ATTRIBUTES,
    label: 'Attributes',
  },
];

const EmployeeAndAttributesFilter = ({
  interviewModuleMembers,
  interviewModuleId,
  seat,
  skipRedux,
  onUpdated,
}: Props): JSX.Element | null => {
  const sxProps = useSxProps();
  const dispatch = useDispatch();

  const [attributesEmpty, setAttributesEmpty] = useState(false);
  const [activeTab, setActiveTab] = useState(EmployeeAndAttributesFilterTabs.PEOPLE);
  const [filterText, setFilterText] = useState('');

  const [searchInterviewModuleMembers, { data }] = useInterviewModuleMemberSearchLazyQuery();

  // Hide button if there are no selected values
  const showClearButton =
    (seat.selectedReverseShadowInterviewerIds?.length ?? 0) > 0 ||
    (seat.selectedShadowInterviewerIds?.length ?? 0) > 0 ||
    (seat.selectedTrainedInterviewerIds?.length ?? 0) > 0 ||
    Object.values(seat.attributeMap ?? {})?.length > 0;

  useEffect(() => {
    // If attributes are selected then default to Attributes tab
    if (!isEmpty(seat.attributeMap)) {
      setActiveTab(EmployeeAndAttributesFilterTabs.ATTRIBUTES);
    }
  }, [seat]);

  const handleClearAll = () => {
    if (skipRedux) return;
    dispatch(clearJobStageInterviewSeatFilters(seat.id));
  };

  const handleActiveTabChange = (option: string) => {
    setFilterText('');
    handleClearAll();
    setActiveTab(option as EmployeeAndAttributesFilterTabs);
  };

  const debouncedSearchInterviewModuleMembers = useDebouncedCallback(searchInterviewModuleMembers, DEBOUNCE_TIMEOUT);

  const isFastTrackEnabled = useFlag('user_fast_track_interview_module');

  const handleFilterTextChange = (search: string) => {
    debouncedSearchInterviewModuleMembers({
      variables: {
        input: {
          pageInput: {
            limit: PAGE_SIZE,
            offset: 0,
          },
          interviewModuleId,
          search,
          isArchived: false,
          isAtsDisabled: false,
          isDirectoryDisabled: false,
          orderBy: {
            property: isFastTrackEnabled
              ? InterviewModuleMemberSearchOrderByProperty.ProgressPercentage
              : InterviewModuleMemberSearchOrderByProperty.Name,
            direction: isFastTrackEnabled ? Sort.Desc : Sort.Asc,
          },
        },
      },
    });
    setFilterText(search);
  };

  const tabs = TABS.map((tab) => {
    switch (tab.id) {
      case EmployeeAndAttributesFilterTabs.ATTRIBUTES: {
        if (attributesEmpty) return null;

        if (activeTab === tab.id) {
          tab.icon = <AttributeIcon color="primary" />;
        }
        return tab;
      }
      case EmployeeAndAttributesFilterTabs.PEOPLE: {
        if (activeTab === tab.id) {
          tab.icon = <DirectoryIcon color="primary" />;
        }
        return tab;
      }
      default:
        return null;
    }
  }).filter((tab) => !!tab) as TabProps<string>[];

  const handleNoAttributes = () => {
    setAttributesEmpty(true);
    setActiveTab(EmployeeAndAttributesFilterTabs.PEOPLE);
  };

  if (!tabs) return null;

  // before the user has searched for moduleMembers, use the interviewModuleMembers passed from the parent query
  const moduleMemberSearchResults = data?.interviewModuleMemberSearch?.items;
  const displayedInterviewModuleMembers = moduleMemberSearchResults || interviewModuleMembers;

  return (
    <Paper sx={sxProps.paper}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Search
            autoFocus
            fullWidth
            placeholder={
              activeTab === EmployeeAndAttributesFilterTabs.PEOPLE ? 'Search interviewers' : 'Search attributes'
            }
            value={filterText}
            onChange={handleFilterTextChange}
          />
        </Grid>

        <Grid item container justifyContent="space-between">
          <Grid item>
            <PageTabs currentValue={activeTab} onChange={(value) => handleActiveTabChange(value as string)}>
              {tabs.map((tab) => (
                <PageTab key={tab.id} value={tab.id} label={tab.label} />
              ))}
            </PageTabs>
          </Grid>
          {showClearButton && (
            <Grid item>
              <Button size="small" label="Clear selection" variant="link" onClick={handleClearAll} />
            </Grid>
          )}
        </Grid>

        <Grid item>
          {activeTab === EmployeeAndAttributesFilterTabs.ATTRIBUTES && (
            <AttributesFilter
              seat={seat}
              filterText={filterText}
              onNoAttributes={handleNoAttributes}
              onUpdated={onUpdated}
            />
          )}
          {activeTab === EmployeeAndAttributesFilterTabs.PEOPLE && (
            <EmployeeFilter
              interviewModuleMembers={displayedInterviewModuleMembers}
              seat={seat}
              onUpdated={onUpdated}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

EmployeeAndAttributesFilter.fragment = {
  employeeAndAttributesFilter: gql`
    ${EmployeeFilter.fragments?.interviewModuleMembers}
    fragment EmployeeAndAttributesFilter on InterviewModuleMember {
      ...EmployeeFilter
    }
  `,
};

export const ModuleMemberSearch = gql`
  ${EmployeeFilter.fragments?.interviewModuleMembers}
  query InterviewModuleMemberSearch($input: InterviewModuleMemberSearchInput!) {
    interviewModuleMemberSearch(input: $input) {
      items {
        ...EmployeeFilter
      }
    }
  }
`;

export default EmployeeAndAttributesFilter;
