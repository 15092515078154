import React, { useState } from 'react';

import { ApolloError, gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';
import { CrossIcon, InfoIcon, JumpIcon, NoteIcon } from '@modernloop/shared/icons';
import { getExternalErrorMessage } from '@modernloop/shared/utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';

import {
  ApplicationNote_ApplicationFragment,
  useApplicationNoteUpdateMutationMutation,
} from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import Editor from 'src/components/Editor';
import DeprecatedIconButton from 'src/components/IconButton';
import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';

import ZenDeskHelpCenterUrls from 'src/constants/ZenDeskHelpCenterUrl';

import { DEBOUNCE_TIMEOUT } from 'src/constants';

import ApplicationNoteEditor from './ApplicationNoteEditor';

type Fragments = {
  application: ApplicationNote_ApplicationFragment;
};

const ApplicationNote: FCWithFragments<Fragments> = ({ application }) => {
  const [applicationNoteUpdateMutation] = useApplicationNoteUpdateMutationMutation();
  const newCandidateAccordionUplift = useFlag('update_candidate_details_page_to_accordion');
  const [showEditor, setShowEditor] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleApplicationNoteUpdate = (value: string) => {
    const div = document.createElement('div');
    div.innerHTML = value;
    let noteValue = value;

    if (!(div.innerText || div.textContent)) {
      noteValue = '';
    }

    applicationNoteUpdateMutation({
      variables: {
        input: {
          applicationId: application.id,
          note: noteValue,
        },
      },
    }).catch((error: ApolloError) => {
      enqueueSnackbar(getExternalErrorMessage(error), { variant: 'error' });
      setErrorMessage(error.message);
    });
  };

  const debouncedHandleApplicationNoteUpdate = useDebouncedCallback(handleApplicationNoteUpdate, DEBOUNCE_TIMEOUT);

  if (newCandidateAccordionUplift) {
    return (
      <Accordion variant="outlined" disableGutters defaultExpanded>
        <AccordionSummary>
          <Stack>
            <Typography variant="subtitle2">Internal note</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack rowGap={2}>
            {application.internalNote ? (
              <Stack rowGap={2}>
                <Editor
                  readOnly
                  onChange={() => {}}
                  value={application.internalNote || ''}
                  modules={{ toolbar: false }}
                  sx={{
                    border: 'none',
                    boxShadow: 'none',
                    '& .ql-editor': {
                      padding: '0',
                    },
                  }}
                />
                <Stack direction="row" justifyContent="flex-end">
                  <Button onClick={() => setShowEditor(true)} size="small">
                    Edit
                  </Button>
                </Stack>
                <Divider />
                <ListItemButton
                  sx={{
                    marginX: -1.5,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.open(ZenDeskHelpCenterUrls.COMMUNICATION_TOKENS, '_blank');
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: (theme: Theme) => theme.palette.primary.main,
                    }}
                  >
                    <JumpIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: (theme: Theme) => theme.palette.primary.main,
                    }}
                  >
                    Learn more
                  </ListItemText>
                </ListItemButton>
              </Stack>
            ) : (
              <Stack rowGap={1.5}>
                <ListItemButton
                  sx={{
                    marginX: -1.5,
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowEditor(true)}
                >
                  <ListItemIcon
                    sx={{
                      color: (theme: Theme) => theme.palette.primary.main,
                    }}
                  >
                    <NoteIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: (theme: Theme) => theme.palette.primary.main,
                    }}
                  >
                    Add an internal note
                  </ListItemText>
                </ListItemButton>
                <Divider />
                <ListItemButton
                  sx={{
                    marginX: -1.5,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.open(ZenDeskHelpCenterUrls.COMMUNICATION_TOKENS, '_blank');
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: (theme: Theme) => theme.palette.primary.main,
                    }}
                  >
                    <JumpIcon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: (theme: Theme) => theme.palette.primary.main,
                    }}
                  >
                    Learn more
                  </ListItemText>
                </ListItemButton>
              </Stack>
            )}
            {showEditor && <ApplicationNoteEditor onClose={() => setShowEditor(false)} application={application} />}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Label variant="captions" color="high-contrast-grey" fontWeight={600}>
          Internal note
        </Label>
        <DeprecatedIconButton
          onClick={() => {
            window.open(
              'https://modernloop.zendesk.com/hc/en-us/articles/5262478729876-Communication-Tokens',
              '_blank'
            );
          }}
          tooltip="Add this note to any internal template using the {{INTERNAL_APPLICATION_NOTE}} token. Click to learn more"
        >
          <InfoIcon />
        </DeprecatedIconButton>
      </Stack>
      <Editor
        defaultValue={application.internalNote || ''}
        placeholder="This note can be used with the {{INTERNAL_APPLICATION_NOTE}} token in any internal template."
        onChange={debouncedHandleApplicationNoteUpdate}
        height={144}
      />
      {errorMessage && (
        <Alert
          status="error"
          title="Error saving note"
          caption={errorMessage}
          actions={
            <DeprecatedIconButton onClick={() => setErrorMessage(null)}>
              <CrossIcon />
            </DeprecatedIconButton>
          }
        />
      )}
    </Stack>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ApplicationNoteUpdateMutation = gql`
  mutation applicationNoteUpdateMutation($input: ApplicationUpdateInput!) {
    applicationUpdate(input: $input) {
      id
      internalNote
    }
  }
`;

ApplicationNote.fragments = {
  application: gql`
    ${ApplicationNoteEditor.fragments.application}
    fragment ApplicationNote_application on Application {
      id
      internalNote
      ...ApplicationNoteEditor_application
    }
  `,
};

export default ApplicationNote;
