import { SvgIcon, SvgIconProps } from './SvgIcon';

export const RsvpMaybeLeftIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62469 18.7002C2.96993 19.2241 2 18.7579 2 17.9194V5C2 3.34315 3.34315 2 5 2H15C16.6569 2 18 3.34315 18 5V13C18 14.6569 16.6569 16 15 16H8.05234C7.37113 16 6.7102 16.2318 6.17826 16.6574L3.62469 18.7002ZM9.96469 5.5C9.47844 5.5 9.1188 5.72152 8.92218 6.01308C8.69059 6.3565 8.22445 6.44715 7.88103 6.21556C7.53761 5.98396 7.44696 5.51782 7.67855 5.1744C8.16223 4.45718 8.99462 4 9.96469 4C11.4695 4 12.5504 5.16884 12.5504 6.43748C12.5504 7.61908 11.8767 8.26629 11.4114 8.70678C10.9192 9.17285 10.7237 9.37541 10.7145 9.76769C10.7047 10.1818 10.3611 10.5096 9.94701 10.4998C9.53291 10.49 9.20514 10.1464 9.2149 9.73231C9.23956 8.68709 9.88411 8.08281 10.3069 7.68647L10.3072 7.68619C10.3323 7.66259 10.3567 7.63972 10.3802 7.61755C10.8239 7.19739 11.0504 6.93834 11.0504 6.43748C11.0504 6.01865 10.6627 5.5 9.96469 5.5ZM11.0002 12.75C11.0002 13.3023 10.5525 13.75 10.0002 13.75C9.44794 13.75 9.00022 13.3023 9.00022 12.75C9.00022 12.1977 9.44794 11.75 10.0002 11.75C10.5525 11.75 11.0002 12.1977 11.0002 12.75Z"
        fill="#787882"
      />
    </SvgIcon>
  );
};
