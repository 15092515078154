/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { CircularProgress, Stack } from '@mui/material';
import { addDays, startOfDay as startOfDayDateFns } from 'date-fns';
import { uniq } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  DynamicAudience,
  EmployeeFragment,
  NotificationPreferenceInput,
  RemoteAttachmentInput,
  Sort,
  TemplateType,
  useCandidateAvailabilityRequestUpsertMutation,
  useCandidateAvailabilityTaskCreateMutation,
  useCandidateDetailsQuery,
} from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import Button from 'src/components/button';
import Label from 'src/components/label';

import { useCreateLocationHeader } from 'src/hooks/amplitude/useCreateLocationHeader';
import useAuth from 'src/hooks/useAuth';
import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';

import { FileUploadFlow } from 'src/slices/files';

import { getFilesByUploadFlow } from 'src/store/selectors/files';
import { CandidateAvailabilityOptions } from 'src/store/slices/candidate-availability-options';

import IsoTimestamp, { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { Tokens as CandidateAvailablityToken } from 'src/utils/PlaceholderFiller/CandidateAvailabilityPlaceholderFiller';
import { getExternalErrorMessage } from 'src/utils/error';

import useRefetchTaskListQuery from 'src/views-new/ApplicationDetailsTaskView/ApplicationContent/useRefetchTaskList';
import EmailContent from 'src/views-new/EmailContent';
import { TaskCreateInputWrapper, getTaskCreateInput } from 'src/views-new/ScheduleTask/CreateModals/shared/types';
import { NumberOfDays } from 'src/views-new/SelfSchedule/TimeframePicker';
import { TemplateInterface } from 'src/views-new/Settings/TemplateComposer/TemplateInterface';
import { useOrganizationTemplatesQuery } from 'src/views-new/Settings/TemplateComposer/useTemplateQueries';

import { TEMPLATE_TOKEN_REGEX } from 'src/constants';
import { useSelector } from 'src/store';

import MissingCandidateAvailabilityTokenAlert from './MissingCandidateAvailabilityTokenAlert';
import { useCandidateAvailabilityEmailFiller } from './useCandidateAvailabilityEmailFiller';

/**
 * Duplicating task creation mutation because we get logs with the mutation name
 */
export const CREATE_TASK_MUTATION = gql`
  mutation CandidateAvailabilityTaskCreate($input: TaskCreateInput!) {
    taskCreate(input: $input) {
      task {
        id
      }
    }
  }
`;

export const CandidateAvailabilityRequestMutation = gql`
  mutation CandidateAvailabilityRequestUpsert($input: CandidateAvailabilityOptionsUpsertInput!) {
    candidateAvailabilityOptionsUpsert(input: $input) {
      options {
        id
      }
    }
  }
`;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type EmailPreviewProps = {
  applicationId: string;
  candidateId: string;
  jobStageId: string;
  defaultTemplateId?: string;
  options: CandidateAvailabilityOptions;
  taskId?: string;
  jobId?: string;
  taskCreatorId?: string;
  taskInput?: TaskCreateInputWrapper;
  onOptionsChanged: (options: CandidateAvailabilityOptions) => void;
  onSaveSuccess?: () => void;
  onEditOptions?: () => void;
  onClose: (emailSent?: boolean, taskId?: string) => void;
  defaultCcEmployeeIds?: string[];
  ccRecipients?: NotificationPreferenceInput | null;
  bccRecipients?: NotificationPreferenceInput | null;
};

const CandidateAvailabilityEmailPreview = ({
  applicationId,
  candidateId,
  jobStageId,
  options,
  taskId,
  taskCreatorId,
  jobId,
  taskInput,
  onOptionsChanged,
  onSaveSuccess,
  onEditOptions,
  onClose,
  defaultTemplateId,
  defaultCcEmployeeIds,
  ccRecipients,
  bccRecipients,
}: EmailPreviewProps): JSX.Element => {
  const auth = useAuth();
  const { logEvent } = useLogEvent();

  const [selectedTemplate, setSelectedTemplate] = useState<TemplateInterface | undefined>(undefined);
  const [toFreeform, setToFreeform] = useState<string[]>([]);
  const [ccEmployeeIds, setCcEmployeeIds] = useState<string[]>(
    uniq((defaultCcEmployeeIds || []).concat(ccRecipients?.employeeIds || []))
  );
  const [ccDynamicAudience, setCcDynamicAudience] = useState<DynamicAudience[]>(ccRecipients?.dynamicAudiences || []);
  const [bccDynamicAudience, setBccDynamicAudience] = useState<DynamicAudience[]>(
    bccRecipients?.dynamicAudiences || []
  );
  const [bccEmployeeIds, setBccEmployeeIds] = useState<string[]>(bccRecipients?.employeeIds || []);
  const [ccFreeform, setCcFreeform] = useState<string[]>(ccRecipients?.externalEmailAddresses || []);
  const [bccFreeform, setBccFreeform] = useState<string[]>(bccRecipients?.externalEmailAddresses || []);
  const [selectedToFreeformEmail, setSelectedToFreeformEmail] = useState<string[]>([]);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [savingOptionsWithoutCandidateCommunication, setSavingOptionsWithoutCandidateCommunication] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [showMissingTokenModal, setShowMissingTokenModal] = useState(false);
  const [isMissingCandidateAvailailityUrlToken, setIsMissingCandidateAvailabilityUrlToken] = useState(false);
  const refetchTaskList = useRefetchTaskListQuery();

  const [subject, setSubject] = useState<string>(selectedTemplate?.subject || '');
  const [bodyHtml, setBodyHtml] = useState<string>(selectedTemplate?.body || '');

  const { data, loading } = useCandidateDetailsQuery({ variables: { id: candidateId } });

  const files = useSelector((state) => getFilesByUploadFlow(state, FileUploadFlow.CANDIDATE_AVAILABILITY));

  const { enqueueSnackbar } = useSnackbar();

  const [createAvailabilityRequest] = useCandidateAvailabilityRequestUpsertMutation();

  const createLocationHeader = useCreateLocationHeader();
  const [createTask] = useCandidateAvailabilityTaskCreateMutation({
    context: {
      headers: {
        ...createLocationHeader,
      },
    },
  });

  const candidateAvailabilityTemplateFiller = useCandidateAvailabilityEmailFiller(
    applicationId,
    jobStageId,
    undefined /* availabilities */,
    data,
    taskId,
    taskInput?.assigneeEmployeeId
  );

  useEffect(() => {
    const additionalEmails = (
      data?.candidate?.additionalEmails ? [...data?.candidate?.additionalEmails] : []
    ) as string[];
    const preferredEmail = (data?.candidate?.email || data?.candidate?.additionalEmails?.[0]) as string;

    // candidate.email might not exist in the list from the ATS (candidate.additionalEmails)
    // if it does, use the ATS list directly
    // if it doesn't, expand the list with the candidate.email as the first item
    let emailList: string[] = [];
    if (additionalEmails.indexOf(preferredEmail) > -1) {
      emailList = additionalEmails;
    } else {
      if (additionalEmails.length) {
        emailList = additionalEmails;
      }
      if (preferredEmail) {
        emailList.unshift(preferredEmail);
      }
    }
    setToFreeform(emailList);
    setSelectedToFreeformEmail(preferredEmail ? [preferredEmail] : []);
  }, [data]);

  useEffect(() => {
    setSubject(candidateAvailabilityTemplateFiller(selectedTemplate?.subject || ''));
    setBodyHtml(candidateAvailabilityTemplateFiller(selectedTemplate?.body || ''));
  }, [candidateAvailabilityTemplateFiller, selectedTemplate]);

  useEffect(() => {
    setIsMissingCandidateAvailabilityUrlToken(
      bodyHtml.indexOf(CandidateAvailablityToken.CANDIDATE_AVAILABILITY_URL) === -1
    );
  }, [bodyHtml]);

  const { data: templatesData, loading: loadingTemplate } = useOrganizationTemplatesQuery({
    types: [TemplateType.CandidateAvailabilityRequest],
    showDefaults: false,
    sortByName: Sort.Asc,
  });

  const templates = templatesData?.templates?.items;

  useEffect(() => {
    templates?.forEach((template) => {
      if (!defaultTemplateId) {
        if (template.isOrganizationDefault) {
          setSelectedTemplate(template);
        }
      } else if (template.id === defaultTemplateId) {
        setSelectedTemplate(template);
      }
    });
  }, [templates, defaultTemplateId]);

  const templateOptions: TemplateInterface[] = [];
  if (templates?.length) {
    templateOptions.splice(templateOptions.length, 0, ...templates);
  }

  const handleEmailTemplateChange = (template: TemplateInterface) => {
    if (!template) return;
    setSelectedTemplate(template);
    setSubject(template.subject || '');
    setBodyHtml(template.body || '');
  };

  const handleTo = (employeeResult: EmployeeFragment[], freeformEmails: string[]) => {
    setSelectedToFreeformEmail(freeformEmails);
  };

  const handleCc = (
    employeeResult: EmployeeFragment[],
    freeformEmails: string[],
    dynamicAudiences: DynamicAudience[]
    // eslint-disable-next-line max-params
  ) => {
    setCcEmployeeIds(employeeResult.map((e) => e.id));
    setCcFreeform(freeformEmails);
    setCcDynamicAudience(dynamicAudiences);
  };

  const handleBcc = (
    employeeResult: EmployeeFragment[],
    freeformEmails: string[],
    dynamicAudiences: DynamicAudience[]
    // eslint-disable-next-line max-params
  ) => {
    setBccEmployeeIds(employeeResult.map((e) => e.id));
    setBccFreeform(freeformEmails);
    setBccDynamicAudience(dynamicAudiences);
  };

  const handleSendEmail = (bypassCandidateAvailabilityUrlTokenCheck = false, saveWithoutSending = false) => {
    if (!bypassCandidateAvailabilityUrlTokenCheck && isMissingCandidateAvailailityUrlToken) {
      setShowMissingTokenModal(true);
      return;
    }
    const attachments: RemoteAttachmentInput[] = [];

    if (files) {
      files.forEach((file) => {
        if (!file.path) return;
        attachments.push({ name: file.name, path: file.path });
      });
    }

    if (!saveWithoutSending) {
      if (!data?.candidate?.email) return;
      if (!auth.userInfo?.employeeEmail) return;
      if (!selectedToFreeformEmail.length) return;
    }

    if (saveWithoutSending) setSavingOptionsWithoutCandidateCommunication(true);
    else setSendingEmail(true);

    if (!selectedTemplate && !saveWithoutSending) return;
    const finalOptions = { ...options };

    // Deleting the UI only fields
    delete finalOptions.error;
    delete finalOptions.loading;
    delete finalOptions.isValidInterviewPlan;

    async function submitOptions() {
      try {
        const getInclusionDays = (value: NumberOfDays) => {
          let date = startOfDayDateFns(new Date());
          const inclusionDays: IsoTimestamp[] = [];

          for (let i = 0; i < value; i++) {
            inclusionDays.push(assertIsoTimestamp(date.toISOString()));
            date = addDays(date, 1);
          }

          return inclusionDays;
        };

        const isValidPlan = options.isValidInterviewPlan ?? false;

        let inclusionDays: string[] | undefined;
        // Only set inclusion days if rolling days is off
        // Default to inclusiongDays on options, otherwise 14 days worth
        if (!finalOptions.useRollingDays) {
          inclusionDays = finalOptions.inclusionDays ?? getInclusionDays(finalOptions.timeframeNumberOfDays ?? 14);
        }

        const { data: createdAvailabilityRequest } = await createAvailabilityRequest({
          variables: {
            input: {
              taskId: finalOptions.taskId,
              numberOfDays: finalOptions.numberOfDays,
              minutesPerDays: finalOptions.minutesPerDays,
              minimumTimeBlockMinutes: finalOptions.minimumTimeBlockMinutes,
              advanceNoticeMinutes: finalOptions.advanceNoticeMinutes ?? 24 * 60,
              timezone: getLocalTimezone(),
              candidateNote: finalOptions.candidateNote,
              shouldRespectLoadLimit: isValidPlan && (finalOptions.shouldRespectLoadLimit ?? true),
              suggestOverRecruitingKeywords: isValidPlan && (finalOptions.canScheduleOverRecruitingKeywords ?? false),
              suggestOverAvailableKeywords: isValidPlan && (finalOptions.canScheduleOverAvailableKeywords ?? false),
              suggestOverFreeTime: isValidPlan && (finalOptions.canScheduleOverFreeTime ?? false),
              rollingDays: finalOptions.useRollingDays ? finalOptions.timeframeNumberOfDays : 0,
              inclusionDays,
              email: saveWithoutSending
                ? undefined
                : {
                    toEmailAddress: selectedToFreeformEmail[0],
                    ccEmployeeIds,
                    bccEmployeeIds,
                    ccExternalAddresses: ccFreeform || [],
                    bccExternalAddresses: bccFreeform,
                    summary: subject,
                    description: bodyHtml,
                    attachments,
                    emailTemplateId: selectedTemplate?.id,
                  },
            },
          },
        });
        refetchTaskList();
        logEvent(LoggerEvent.CLIENT_CANDIDATE_AVAILABILITY_OPTIONS, { applicationId, jobStageId });
        logEvent(LoggerEvent.CLIENT_CANDIDATE_AVAILABILITY_EMAIL_SENT, { applicationId, jobStageId });

        onOptionsChanged({
          ...options,
          id: createdAvailabilityRequest?.candidateAvailabilityOptionsUpsert?.options?.id,
          taskId: finalOptions.taskId,
          lastCandidateCommunicationSentAt: new Date().toISOString(),
        });

        setSendingEmail(false);
        setSavingOptionsWithoutCandidateCommunication(false);

        if (!saveWithoutSending) {
          enqueueSnackbar('Email sent to candidate', {
            variant: 'success',
          });
        }

        if (onSaveSuccess) onSaveSuccess();
        onClose(!saveWithoutSending, finalOptions.taskId);
      } catch (e) {
        setError(getExternalErrorMessage(e));
        setSendingEmail(false);
        setSavingOptionsWithoutCandidateCommunication(false);
      }
    }

    if (taskId) {
      finalOptions.taskId = taskId;
    }

    if (!taskId && taskInput) {
      const saveInput = getTaskCreateInput(taskInput);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/catch-or-return
      createTask({
        variables: {
          input: saveInput,
        },
      }).then((taskResult) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line promise/always-return
        const resultTaskId = taskResult.data?.taskCreate?.task?.id;
        finalOptions.taskId = resultTaskId;
        submitOptions();
      });
    } else {
      submitOptions();
    }
  };

  const bodyUnusedTokens = bodyHtml.match(TEMPLATE_TOKEN_REGEX) || [];
  const subjectUnusedTokens = subject.match(TEMPLATE_TOKEN_REGEX) || [];
  const unusedTokens =
    uniq([...bodyUnusedTokens, ...subjectUnusedTokens]).filter(
      (value) => value !== `{{${CandidateAvailablityToken.CANDIDATE_AVAILABILITY_URL}}}`
    ) || [];
  const hasUnusedToken = !!unusedTokens.length;

  const getSendEmailDisabledTooltip = () => {
    if (hasUnusedToken) {
      return `There are unused tokens: ${unusedTokens.join(', ')}`;
    }

    if (!data?.candidate?.email) {
      return 'Candidate email not present';
    }

    return undefined;
  };

  const isSendEmailDisabled =
    loading ||
    savingOptionsWithoutCandidateCommunication ||
    sendingEmail ||
    hasUnusedToken ||
    !selectedToFreeformEmail.length ||
    !subject;

  return (
    <>
      <EmailContent
        useDialogPage
        candidateId={candidateId}
        applicationId={applicationId}
        title="Email request to candidate"
        subTitle={taskId || !taskInput ? 'Step 2 of 2: Email this request' : 'Step 3 out of 3: Email this request'}
        headerActions={
          <Stack direction="row" alignItems="center" spacing={1}>
            {!onEditOptions && <Button label="Cancel" size="small" variant="outlined" onClick={() => onClose()} />}
            {onEditOptions && (
              <Button
                disabled={!toFreeform.length || savingOptionsWithoutCandidateCommunication || sendingEmail}
                endIcon={savingOptionsWithoutCandidateCommunication ? <CircularProgress size={16} /> : undefined}
                label="Save without sending?"
                size="small"
                variant="outlined"
                onClick={() => {
                  handleSendEmail(true /* bypassCandidateAvailabilityUrlTokenCheck */, true /* saveWithoutSending */);
                }}
              />
            )}
            <Button
              disabled={isSendEmailDisabled}
              endIcon={sendingEmail ? <CircularProgress size={16} /> : undefined}
              label={onEditOptions ? 'Save & send email' : 'Send email'}
              color="primary"
              size="small"
              variant="contained"
              onClick={() => handleSendEmail()}
              tooltip={getSendEmailDisabledTooltip()}
            />
          </Stack>
        }
        dialogActions={{
          cancelOptions: onEditOptions
            ? { label: 'Previous step', onClick: onEditOptions, startIcon: 'CaretLeftIcon' }
            : { label: 'Cancel', onClick: () => onClose() },
          submitOptions: {
            label: onEditOptions ? 'Save & send email' : 'Send email',
            isDisabled: isSendEmailDisabled,
            isLoading: sendingEmail,
            tooltip: getSendEmailDisabledTooltip(),
            onClick: () => handleSendEmail(),
          },
          secondaryOptions: onEditOptions
            ? {
                label: 'Save without sending',
                isDisabled: !toFreeform.length || savingOptionsWithoutCandidateCommunication || sendingEmail,
                isLoading: savingOptionsWithoutCandidateCommunication,
                onClick: () => {
                  handleSendEmail(true /* bypassCandidateAvailabilityUrlTokenCheck */, true /* saveWithoutSending */);
                },
              }
            : undefined,
        }}
        onClose={() => onClose()}
        alerts={
          <Alert
            alignItems="center"
            status="neutral"
            title={
              <Label variant="captions" fontWeight={400}>
                We’ll insert the link for {`{{CANDIDATE_AVAILABILITY_URL}}`} when you send your email. If the token is
                missing, we’ll add it automatically to the end of your email.
              </Label>
            }
          />
        }
        sendError={error}
        toFreeformEmails={toFreeform}
        ccEmployeeIds={ccEmployeeIds}
        defaultCcEmployeeIds={ccEmployeeIds}
        defaultCcFreeformEmails={ccFreeform}
        ccFreeformEmails={ccFreeform}
        ccDynamicAudiences={ccDynamicAudience}
        defaultBccEmployeeIds={bccEmployeeIds}
        bccFreeformEmails={bccFreeform}
        defaultBccFreeformEmails={bccFreeform}
        bccEmployeeIds={bccEmployeeIds}
        bccDynamicAudiences={bccDynamicAudience}
        loading={loading || loadingTemplate}
        subject={subject}
        bodyHtml={bodyHtml}
        fileUploadFlow={FileUploadFlow.CANDIDATE_AVAILABILITY}
        templateTypes={[TemplateType.CandidateAvailabilityRequest]}
        selectedTemplateId={selectedTemplate?.id}
        selectedToFreeformEmail={selectedToFreeformEmail.length ? selectedToFreeformEmail[0] : undefined}
        assigneeEmployeeId={taskInput?.assigneeEmployeeId}
        taskId={taskId}
        jobId={jobId}
        taskCreatorId={taskCreatorId}
        subscriberEmployeeIds={taskInput?.subscriberEmployeeIds ?? []}
        onChangeSubject={setSubject}
        onChangeBodyHtml={setBodyHtml}
        onChangeTemplate={handleEmailTemplateChange}
        onToChanged={handleTo}
        onCcChanged={handleCc}
        onBccChanged={handleBcc}
        goBack={onEditOptions}
      />
      {showMissingTokenModal && (
        <MissingCandidateAvailabilityTokenAlert
          goBack={() => setShowMissingTokenModal(false)}
          onAppendAndContinue={() => {
            setShowMissingTokenModal(false);
            handleSendEmail(true /* bypassCandidateAvailabilityUrlTokenCheck */);
          }}
        />
      )}
    </>
  );
};

export default CandidateAvailabilityEmailPreview;
