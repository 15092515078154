import React, { FC } from 'react';

import { gql } from '@apollo/client';
import { Skeleton } from '@mui/material';
import _ from 'lodash';

import { Conversation, useExistingSlackChannelsQuery } from 'src/generated/mloop-graphql';

import SelectMenu, { MenuOption } from 'src/components/SelectMenu';
import { LockIcon, PlusIcon, SearchIcon } from 'src/components/icons';
import Label from 'src/components/label';

export enum SlackMenuIds {
  NEW_CHANNEL_ID = 'new-slack-channel',
  EXISTING_CHANNEL_ID = 'existing-slack-channel',
  EMPTY_STATE_ID = 'empty-state-item',
  PREVIOUS_SLACKS_SUBHEADER_ID = 'sub-header',
}

const PREVIOUS_APPS_SUB_HEADER: MenuOption = {
  id: SlackMenuIds.PREVIOUS_SLACKS_SUBHEADER_ID,
  value: 'Previously used channels for this application',
  isSubheader: true,
  customOption: (
    <Label color="max-contrast-grey" fontWeight={600} variant="captions">
      Previously used channels for this application
    </Label>
  ),
};

const NEW_CHANNEL_OPTION: MenuOption = {
  id: SlackMenuIds.NEW_CHANNEL_ID,
  value: 'Create a new private channel',
  customOption: (
    <Label
      style={{
        gap: '8px',
        paddingLeft: '8px',
      }}
      color="info"
      icon={<PlusIcon color="info" />}
    >
      Create a new private channel
    </Label>
  ),
};

const EXISTING_CHANNEL_OPTION: MenuOption = {
  id: SlackMenuIds.EXISTING_CHANNEL_ID,
  value: 'Use another existing channel',
  customOption: (
    <Label
      style={{
        gap: '8px',
        paddingLeft: '8px',
      }}
      color="info"
      icon={<SearchIcon color="info" />}
    >
      Use another existing channel
    </Label>
  ),
};

export type ExistingChannel = Pick<Conversation, 'id' | 'remoteName' | 'isArchived'>;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface ExistingSlackChannelsMenuProps {
  applicationId: string;
  selectedConversationId?: string;
  existingSlackChannels?: ExistingChannel[];
  onSelect: (option: MenuOption) => void;
}

export const ExistingChannelsGQL = gql`
  query ExistingSlackChannels($applicationId: uuid!) {
    application(id: $applicationId) {
      existingConversations {
        id
        remoteId
        remoteName
        isArchived
      }
    }
  }
`;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const ExistingSlackChannelsMenu: FC<ExistingSlackChannelsMenuProps> = ({
  applicationId,
  selectedConversationId,
  existingSlackChannels,
  onSelect,
}) => {
  const { data, loading } = useExistingSlackChannelsQuery({
    variables: {
      applicationId,
    },
    fetchPolicy: 'network-only',
  });

  const dataOptions = [...(data?.application?.existingConversations || []), ...(existingSlackChannels || [])];
  const options =
    _.uniqBy(dataOptions, 'id')
      ?.filter((channel) => channel && !channel.isArchived)
      ?.map((value) => {
        return {
          id: value?.id || '',
          value: value?.remoteName || '',
          label: value?.remoteName || '',
          icon: <LockIcon />,
        };
      }) || [];

  const selectedOption = options.find((option) => option.id === selectedConversationId);

  let combinedOptions: MenuOption[] = [];
  // Only show previous if there are existing channels
  if (options.length !== 0) {
    combinedOptions.push(PREVIOUS_APPS_SUB_HEADER);
    combinedOptions = [...combinedOptions, ...options];
  }
  // Show options and then existing channel then new channel
  combinedOptions = [...combinedOptions, EXISTING_CHANNEL_OPTION, NEW_CHANNEL_OPTION];

  if (loading) {
    return <Skeleton variant="rounded" width="100%" height="32px" />;
  }

  return (
    <SelectMenu
      size="medium"
      placeholder="New private channel"
      startIcon={selectedOption ? 'LockIcon' : undefined}
      options={combinedOptions}
      selectedOption={selectedOption}
      onSelect={(option) => {
        onSelect(option);
      }}
    />
  );
};

export default ExistingSlackChannelsMenu;
