/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Avatar, CircularProgress, Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Autocomplete, AutocompleteGetTagProps, AutocompleteInputChangeReason } from '@material-ui/lab';
import { uniqBy } from 'lodash';
import { DebouncedState, useDebouncedCallback } from 'use-debounce';

import { EmployeeFragment, useEmployeesLazyQuery } from 'src/generated/mloop-graphql';

import Stack from 'src/components/Stack';
import TextField from 'src/components/TextField';
import Chip from 'src/components/chip';
import { EmailIcon, UnknownUserIcon } from 'src/components/icons';
import Label from 'src/components/label';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

import { Theme } from 'src/theme/type';

import { DEBOUNCE_TIMEOUT, EMAIL_REGEX, PAGE_SIZE } from 'src/constants';

import AtsName from '../Ats/AtsName';

const DUMMY_LOADING_EMPLOYEE_ID = 'DUMMY_LOADING_EMPLOYEE_ID';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: '20px',
      height: '20px',
    },
    invalidEmail: {
      border: `1px solid ${theme.palette.error.main} !important`,
      backgroundColor: `${theme.palette.background.error} !important`,
    },
    employeeName: {
      flexShrink: 0,
    },
    employeeDisableReason: {
      minWidth: 0,
      pointerEvents: 'auto',
    },
  })
);

export type Value<TData extends EmployeeFragment, Freeform extends boolean | undefined> = Freeform extends
  | false
  | undefined
  ? TData
  : TData | string;

export type Values<TData extends EmployeeFragment, Freeform extends boolean | undefined> = Value<TData, Freeform>[];

export const renderTagDefault = <TData extends EmployeeFragment, Freeform extends boolean | undefined>(
  value: Value<TData, Freeform>,
  index: number,
  getTagProps: AutocompleteGetTagProps,
  invalidEmailClass?: string
  // eslint-disable-next-line max-params
) => {
  const isInvalidEmail =
    typeof value === 'string'
      ? value.match(EMAIL_REGEX) === null
      : value.isArchived || value.isAtsDisabled || !value.hasAtsId || value.isDirectoryDisabled;

  return (
    <Chip
      key={typeof value === 'string' ? value : value.id}
      label={typeof value === 'string' ? value : value.fullName ?? ''}
      variant="default"
      className={isInvalidEmail ? invalidEmailClass : undefined}
      avatar={typeof value === 'string' ? <EmailIcon /> : <Avatar src={value.slackImageUrl ?? undefined} />}
      tagProps={getTagProps({ index })}
    />
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type BaseEmployeePickerProps<TData extends EmployeeFragment, Freeform extends boolean | undefined> = {
  dataTestId?: string;
  employees: Values<TData, Freeform>;
  loading: boolean;
  freeformEnabled?: boolean;
  label?: string;
  error?: boolean;
  selectedEmployees?: Values<TData, Freeform>;
  defaultSelectedEmployees?: Values<TData, Freeform>;
  additionalEmployeeOptions?: Values<TData, Freeform>;

  // Allows selecting employees who are not in ATS.
  // E.g. On Microsoft orgs we allow selecting employees who are not in ATS for storing it in arr_outlook_shared_calendar_emails pref
  allowSelectingAllEmployees?: boolean;

  onChange: (EmployeeFragment: Values<TData, Freeform>) => void;
  autoFocus?: boolean;
  placeholder?: string;
  onClose?: () => void;
  renderTags?: (value: Values<TData, Freeform>, getTagProps: AutocompleteGetTagProps) => React.ReactNode;
  open?: boolean;
  filterOptions?: (employees: Values<TData, Freeform>) => Values<TData, Freeform>;
  onInputChange?: (event: React.ChangeEvent, value: string, reason: AutocompleteInputChangeReason) => void;
  className?: string;
  getOptionDisabled?: (option: Freeform extends false | undefined ? TData : TData | string) => boolean;
  disabled?: boolean;
  getOptionSelected?: (option: Value<TData, Freeform>, value: Value<TData, Freeform>) => boolean;
};

export const BaseEmployeePicker = <TData extends EmployeeFragment, Freeform extends boolean | undefined>({
  dataTestId,
  loading,
  freeformEnabled,
  label,
  error,
  employees,
  selectedEmployees,
  defaultSelectedEmployees,
  additionalEmployeeOptions,
  allowSelectingAllEmployees,
  open,
  autoFocus,
  placeholder,
  onChange,
  onClose,
  renderTags,
  filterOptions,
  onInputChange,
  className,
  getOptionDisabled,
  disabled,
  getOptionSelected,
}: BaseEmployeePickerProps<TData, Freeform>): JSX.Element => {
  const classes = useStyles();

  const employeesOptions = useMemo((): Values<TData, Freeform> => {
    if (filterOptions && employees.length) {
      return filterOptions(
        uniqBy([...employees, ...(selectedEmployees || []), ...(additionalEmployeeOptions || [])], (emp) =>
          typeof emp === 'string' ? emp : emp.id
        )
      );
    }

    /**
     * The below sort tries to push any user who satisfies the below conditions to the bottom of list:
     * - Is archived in ModernLoop.
     * - Has not been invited to ATS.
     * - Has been invited to ATS but has not accepted the invite and created an account.
     */
    return uniqBy([...employees, ...(selectedEmployees || []), ...(additionalEmployeeOptions || [])], (emp) =>
      typeof emp === 'string' ? emp : emp.id
    ).sort((a, b) => {
      if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b);
      }
      if (typeof a === 'string') {
        return 1;
      }
      if (typeof b === 'string') {
        return -1;
      }
      return (
        Number(Boolean(a.isArchived || a.isAtsDisabled || !a.hasAtsId)) -
        Number(Boolean(b.isArchived || b.isAtsDisabled || !b.hasAtsId))
      );
    });
  }, [employees, filterOptions, additionalEmployeeOptions, selectedEmployees]);

  const handleChange = (_event: React.ChangeEvent, value: Values<TData, Freeform>) => {
    const freeformEmails = value.filter((val) => typeof val === 'string') as string[];
    const hasInvalidEmail = freeformEmails.reduce((prev, data) => {
      if (prev) return prev;
      return data.match(EMAIL_REGEX) === null;
    }, false);

    if (hasInvalidEmail) return;

    onChange(value);
  };

  const getIsOptionSelected = (option: Value<TData, Freeform>, value: Value<TData, Freeform>) => {
    if (getOptionSelected) {
      return getOptionSelected(option, value);
    }

    const optionId = typeof option === 'string' ? option : option.id;
    const valueId = typeof value === 'string' ? value : value.id;
    return optionId === valueId;
  };

  const renderTagsDefault = (values: Values<TData, Freeform>, getTagProps: AutocompleteGetTagProps) => {
    return values.map((value, index) => renderTagDefault(value, index, getTagProps, classes.invalidEmail));
  };

  const getLoadingIcon = (): JSX.Element => {
    return (
      <Stack justifyContent="center">
        <CircularProgress size={24} />
      </Stack>
    );
  };

  const renderOption = (option: Value<TData, Freeform>) => {
    // We don't render freeform in options.
    if (typeof option === 'string') return null;

    if (option.id === DUMMY_LOADING_EMPLOYEE_ID) {
      return getLoadingIcon();
    }

    let disabledLabel: string | JSX.Element = '';
    if (option.isAtsDisabled) {
      disabledLabel = (
        <>
          Disabled in <AtsName />
        </>
      );
    } else if (!option.hasAtsId) {
      disabledLabel = (
        <>
          User has not accepted <AtsName /> invite
        </>
      );
    } else if (option.isArchived) {
      disabledLabel = 'Disabled in ModernLoop';
    }

    return (
      <Grid container spacing={1} alignItems="center" wrap="nowrap">
        <Grid item>
          {option.id && <Avatar src={option.slackImageUrl ?? ''} className={classes.avatar} />}
          {!option.id && <UnknownUserIcon />}
        </Grid>
        <Grid item className={classes.employeeName}>
          {option.fullName}
        </Grid>
        {!allowSelectingAllEmployees && disabledLabel && (
          <Grid
            item
            className={classes.employeeDisableReason}
            onClick={(e) => {
              // This prevents a disabled user from being clicked
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <MaybeTooltip
              tooltip={disabledLabel}
              label={disabledLabel}
              labelProps={{ noWrap: true, variant: 'captions' }}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const additionalProps: { open?: boolean } = {};
  if (open !== undefined) {
    additionalProps.open = open;
  }

  return (
    <Autocomplete
      loading={loading}
      disabled={disabled}
      autoHighlight
      fullWidth
      multiple
      disableClearable
      filterSelectedOptions
      freeSolo={freeformEnabled}
      popupIcon={null}
      className={className}
      options={loading ? [{ id: DUMMY_LOADING_EMPLOYEE_ID } as TData, ...employeesOptions] : employeesOptions}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            dataTestId={dataTestId}
            error={error}
            label={label}
            autoFocus={autoFocus}
            placeholder={placeholder}
            variant="standard"
          />
        );
      }}
      value={selectedEmployees}
      defaultValue={defaultSelectedEmployees}
      getOptionLabel={(option: TData) => option.fullName ?? ''}
      renderOption={renderOption}
      renderTags={renderTags || renderTagsDefault}
      onChange={handleChange}
      getOptionSelected={getIsOptionSelected}
      onClose={handleClose}
      getOptionDisabled={(option) => {
        if (
          typeof option !== 'string' &&
          (option.id === DUMMY_LOADING_EMPLOYEE_ID ||
            option.isAtsDisabled ||
            (!allowSelectingAllEmployees && !option.hasAtsId) ||
            option.isArchived)
        ) {
          return true;
        }
        if (getOptionDisabled) {
          getOptionDisabled(option);
        }
        return false;
      }}
      onInputChange={onInputChange}
      loadingText={getLoadingIcon()}
      {...additionalProps}
    />
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type EmployeePickerProps<TData extends EmployeeFragment, Freeform extends boolean | undefined> = {
  dataTestId?: string;
  autoFocus?: boolean;
  freeformEnabled?: Freeform;
  label?: string;
  error?: boolean;
  placeholder?: string;
  selectedEmployees?: Values<TData, Freeform>;
  defaultSelectedEmployees?: Values<TData, Freeform>;
  additionalEmployeeOptions?: Values<TData, Freeform>;
  allowSelectingAllEmployees?: boolean;
  onChange: (EmployeeFragment: Values<TData, Freeform>) => void;
  onClose?: () => void;
  renderTags?: (value: Values<TData, Freeform>, getTagProps: AutocompleteGetTagProps) => React.ReactNode;
  open?: boolean;
  filterOptions?: (employees: Values<TData, Freeform>) => Values<TData, Freeform>;
  requestWithATSid?: boolean;
  disabled?: boolean;
  onInputChange?: (event: React.ChangeEvent, value: string, reason: AutocompleteInputChangeReason) => void;
  getOptionSelected?: (option: Value<TData, Freeform>, value: Value<TData, Freeform>) => boolean;
  showUnassignedOption?: boolean;
};

export const useSearch = (
  val: string,
  hasNext?: boolean
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
): [searchStr: string, debouncedHandleSearch: DebouncedState<(event: React.ChangeEvent, value: string) => void>] => {
  const [searchStr, setSearchStr] = useState<string>('');
  const handleSearch = (_event: React.ChangeEvent, value: string) => {
    if (hasNext === false && !searchStr) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line max-lines
      return;
    }
    setSearchStr(value.toLowerCase());
  };
  const debouncedHandleSearch = useDebouncedCallback(handleSearch, DEBOUNCE_TIMEOUT);
  return [searchStr, debouncedHandleSearch];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const EmployeePicker = <TData extends EmployeeFragment, Freeform extends boolean | undefined = undefined>(
  props: EmployeePickerProps<TData, Freeform>
): JSX.Element => {
  const [fetchEmployee, { loading, data, error }] = useEmployeesLazyQuery();

  const [searchStr, setSearchStr] = useSearch('', !!data?.employees?.nextCursor);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  const handleInputChange = (event: React.ChangeEvent, value: string, reason: AutocompleteInputChangeReason) => {
    const { onInputChange } = props;
    if (onInputChange) {
      onInputChange(event, value, reason);
    }
    setSearchStr(event, value);
  };

  const { requestWithATSid, showUnassignedOption } = props;

  const employees = useMemo(() => {
    let employeeList = data?.employees?.items ?? [];

    if (showUnassignedOption) {
      employeeList = [
        {
          id: null,
          fullName: 'Unassigned',
          hasAtsId: true,
          email: '',
          isDirectoryDisabled: false,
          orgId: '',
          isAtsDisabled: false,
        } as EmployeeFragment,
        ...employeeList,
      ];
    }

    return employeeList;
  }, [data, showUnassignedOption]);
  useEffect(() => {
    fetchEmployee({
      variables: {
        input: {
          pageInput: {
            limit: PAGE_SIZE,
          },
          isArchived: !searchStr ? false : undefined,
          isAtsDisabled: !searchStr ? false : undefined,
          isDirectoryDisabled: false,
          hasAtsId: !!requestWithATSid,
          search: searchStr,
        },
      },
    });
  }, [fetchEmployee, requestWithATSid, searchStr]);

  if (error) return <Label>Error</Label>;

  return <BaseEmployeePicker {...props} onInputChange={handleInputChange} employees={employees} loading={loading} />;
};

export default EmployeePicker;
