import React, { useCallback, useMemo, useRef, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';
import { ListItem, ListItemButton } from '@mui/material';
import { merge, uniqBy } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

import {
  MemberSelect_UserInfoFragment,
  OrgMembersQuery,
  OrgUserInfoSortBy,
  Permission,
  useOrgMembersQuery,
} from 'src/generated/mloop-graphql';

import FilterSelect, {
  FilterListSelectInterface,
  Props as FilterSelectProps,
} from 'src/components/FilterList/FilterSelect';

import useEmployeeId from 'src/hooks/useEmployeeId';

import { DEBOUNCE_TIMEOUT, PAGE_SIZE } from 'src/constants';

import MemberOption from './MemberOption';

type Props = {
  additionalOptions?: MemberSelect_UserInfoFragment[];
  renderOption?: FilterSelectProps<MemberSelect_UserInfoFragment>['renderOption'];
  getLabel: FilterSelectProps<MemberSelect_UserInfoFragment>['getLabel'];
  onMemberSelect?: (member: MemberSelect_UserInfoFragment) => void;
};

const SingleMemberSelect = ({ additionalOptions, renderOption, getLabel, onMemberSelect }: Props) => {
  const shouldFilterByPermission = useFlag('org_interviewer_portal');
  const currentEmployeeId = useEmployeeId();
  const [search, setSearch] = useState('');
  const debouncedSetSearch = useDebouncedCallback(setSearch, DEBOUNCE_TIMEOUT);
  const filterListSelectInterfaceRef = useRef<FilterListSelectInterface | null>(null);
  const { data, loading, error, fetchMore, updateQuery } = useOrgMembersQuery({
    variables: {
      input: {
        pageInput: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        query: search,
        sortBy: OrgUserInfoSortBy.Name,
        requiredPermission: shouldFilterByPermission ? Permission.CanSchedule : undefined,
      },
    },
  });

  const options = useMemo(() => {
    const memoOptions = data?.thisOrg?.userInfos?.items?.filter((member) => !!member.employee?.id) ?? [];

    if (additionalOptions?.length) {
      return [...additionalOptions, ...memoOptions];
    }

    return memoOptions;
  }, [data, additionalOptions]);

  const handleChange = (event: React.MouseEvent, values: MemberSelect_UserInfoFragment[]) => {
    if (!values.length || !onMemberSelect) return;
    onMemberSelect(values[0]);
    filterListSelectInterfaceRef.current?.dismiss();
  };

  const defaultRenderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: MemberSelect_UserInfoFragment) => {
      if (!currentEmployeeId) return null;

      return (
        <ListItem {...props}>
          <ListItemButton>
            <MemberOption currentEmployeeId={currentEmployeeId} member={option} />
          </ListItemButton>
        </ListItem>
      );
    },
    [currentEmployeeId]
  );

  const handleScrollToEnd = () => {
    if (
      !data?.thisOrg?.userInfos ||
      !data?.thisOrg?.userInfos.items ||
      loading ||
      !data?.thisOrg?.userInfos.nextCursor ||
      !fetchMore
    ) {
      return;
    }

    fetchMore({
      variables: {
        input: {
          pageInput: {
            cursor: data?.thisOrg?.userInfos.nextCursor,
            limit: PAGE_SIZE,
          },
          query: search,
          sortBy: OrgUserInfoSortBy.Name,
        },
      },
    })?.then((result: ApolloQueryResult<OrgMembersQuery>) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      if (!updateQuery) return;

      updateQuery((prevResult: OrgMembersQuery) => {
        const userInfos = prevResult.thisOrg?.userInfos;
        const newResults = merge({}, prevResult, result.data);

        if (newResults.thisOrg?.userInfos.items) {
          newResults.thisOrg.userInfos.items = uniqBy(
            [...(userInfos?.items || []), ...(result.data?.thisOrg?.userInfos.items || [])],
            'userId'
          ).filter((option) => option.employee?.id);
        }

        return { ...newResults };
      });
    });
  };

  return (
    <FilterSelect
      placeholderText="Search members..."
      filterListSelectInterfaceRef={filterListSelectInterfaceRef}
      options={options}
      loading={loading}
      errorMsg={error?.message}
      onChange={handleChange}
      getLabel={getLabel}
      getOptionLabel={(option) => option.userName || ''}
      renderOption={renderOption ?? defaultRenderOption}
      onInputChange={(event: React.ChangeEvent<HTMLInputElement>, value) => {
        if (!search && !data?.thisOrg?.userInfos.nextCursor) return;
        debouncedSetSearch(value || '');
      }}
      onScrollToEnd={handleScrollToEnd}
    />
  );
};

export default SingleMemberSelect;
