import { useMemo } from 'react';

import { gql } from '@apollo/client';

import { TaskStatus, UseSchduleTaskMenuOptions_TaskFragment } from 'src/generated/mloop-graphql';

import { MenuOption } from 'src/components/menu';
import { FunctionWithFragments } from 'src/components/types';

export enum ScheduleTaskMenuOptions {
  MarkAsUrgent = 'MarkAsUrgent',
  RemoveUrgent = 'RemoveUrgent',
  CopyLinkToTask = 'CopyLinkToTask',
  CloseTask = 'CloseTask',
}

type UseScheduleTaskMenuOptionsFragmentProps = {
  task: UseSchduleTaskMenuOptions_TaskFragment | undefined;
};

const useSchduleTaskMenuOptions: FunctionWithFragments<
  UseScheduleTaskMenuOptionsFragmentProps,
  undefined,
  MenuOption[] | undefined
> = ({ task }) => {
  return useMemo(() => {
    if (!task) return undefined;

    const options: MenuOption[] = [];

    options.push({ id: ScheduleTaskMenuOptions.CopyLinkToTask, value: 'Copy link to task' });

    if (task.status !== TaskStatus.Canceled) {
      options.push({ isDivider: true });
      options.push({ id: ScheduleTaskMenuOptions.CloseTask, color: 'error', value: 'Cancel schedule task' });
    }

    return options;
  }, [task]);
};

useSchduleTaskMenuOptions.fragments = {
  task: gql`
    fragment UseSchduleTaskMenuOptions_task on Task {
      id
      isUrgent
      status
    }
  `,
};

export default useSchduleTaskMenuOptions;
