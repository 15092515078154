import React, { FC } from 'react';

import { Dialog as MuiDialog } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DialogActionsProps } from './DialogActions';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DialogMoreMenuProps } from './DialogMoreMenu';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DialogTitleProps } from './DialogTitle';
import useFullScreen from './useFullScreen';

import { DialogProps } from '.';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ChildPropDialogProps = Omit<DialogProps, keyof (DialogActionsProps & DialogMoreMenuProps & DialogTitleProps)> & {
  onClose: () => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const ChildPropDialog: FC<ChildPropDialogProps> = (props) => {
  const isFullScreen = useFullScreen();
  const { children, isOpen, size = 'sm', onClose } = props;

  /**
   * By default, only the cross icon can close the modal.
   */
  const handleClose = (_e: unknown, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose();
  };

  const fullScreenPaperProps = isFullScreen
    ? {
        borderRadius: '0',
        margin: '0',
        maxHeight: '100%',
        height: '100%',
        width: '100%',
      }
    : {};

  return (
    <MuiDialog
      sx={{
        borderRadius: '12px',
        outlineOffset: '-1px',
      }}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          width: size === 'sm' ? '484px' : '604px',
          ...fullScreenPaperProps,
        },
      }}
      scroll="paper"
      open={isOpen}
      onClose={handleClose}
    >
      {children}
    </MuiDialog>
  );
};

export default ChildPropDialog;
