import schedulesSlice, { ScheduleOverride } from 'src/store/slices/schedules';

import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';

import { AppThunk } from 'src/store';

export const saveSchedules =
  (count: number, schedules: InterviewSchedule[]): AppThunk =>
  async (dispatch) => {
    dispatch(schedulesSlice.actions.saveSchedules({ count, schedules }));
  };

export const clearSchedules = (): AppThunk => async (dispatch) => {
  dispatch(schedulesSlice.actions.clear());
};

export const updateScheduleScrollToIndex =
  (scrollToIndex: number): AppThunk =>
  async (dispatch) => {
    dispatch(schedulesSlice.actions.updateScheduleScrollToIndex({ scrollToIndex }));
  };

export const setScheduleOverrideConfig =
  (scheduleOverride: ScheduleOverride): AppThunk =>
  async (dispatch) => {
    dispatch(schedulesSlice.actions.setScheduleOverrideConfig(scheduleOverride));
  };
