import React from 'react';

import { gql } from '@apollo/client';

import { FCWithFragments } from 'src/components/types';

import AvailabilityRequestedModal from '../CandidateAvailabilityOptions/Options/AvailabilityRequestedModal';
import { selfScheduleOptionsFragment } from '../JobDetailsPage/JobStageDetails/JobStageCommunicationSettings';

import SelfScheduleOptions from './SelfScheduleOptions';
import { Fragment as Fragments, Props, SelfScheduleOptionsModal } from './SelfScheduleOptionsModal';

export const GQL_CANDIDATE_INFO = gql`
  query CandidateCoordinatorAndRecruiter($candidateId: uuid!) {
    candidate(id: $candidateId) {
      coordinatorId
      recruiterId
      email
    }
  }
`;

const SelfScheduleOptionsModalWrapper: FCWithFragments<Fragments, Props & { isAvailabilityRequested?: boolean }> = (
  props
) => {
  const { isAvailabilityRequested, onClose } = props;

  if (isAvailabilityRequested) {
    return <AvailabilityRequestedModal onClose={onClose} />;
  }

  return <SelfScheduleOptionsModal {...props} />;
};

SelfScheduleOptionsModalWrapper.fragments = {
  selfSchedule: gql`
    ${SelfScheduleOptions.fragments?.options}
    fragment SelfScheduleOptionsModal_selfSchedule on SelfScheduleOptions {
      id
      taskId
      createdAt
      creatorEmployeeId
      deletedAt
      jobStageId
      candidateEmailTemplateId
      candidateEventTemplateId
      interviewerEventTemplateId
      lastUpdatedByEmployeeId
      options {
        ...SelfScheduleOptions_options
      }
      rescheduledAt
      selfScheduleEmailTemplateId
      selfScheduleEmailSentAt
      updatedAt
    }
  `,
  settings: gql`
    ${selfScheduleOptionsFragment}
    fragment SelfScheduleOptionSettings_JobStageSettings on JobStageSettings {
      ...SelfScheduleOptions_jobStageSettings
      selfScheduleRequestEmailTemplateId
      selfScheduleCandidateEmailTemplateId
      selfScheduleInterviewerEventTemplateId
    }
  `,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SelfScheduleOptionsUpsertMutation = gql`
  ${SelfScheduleOptionsModalWrapper.fragments?.selfSchedule}
  mutation SelfScheduleOptionsUpsert($input: SelfScheduleOptionsUpsertInput!) {
    selfScheduleOptionsUpsert(input: $input) {
      ...SelfScheduleOptionsModal_selfSchedule
    }
  }
`;

export default SelfScheduleOptionsModalWrapper;
