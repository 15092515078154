import React from 'react';

import BaseIcon, { Props } from './Icon';

const ModulesIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2.68751L14 2.6875C15.5533 2.6875 16.8125 3.9467 16.8125 5.5V8.02066C17.4868 8.13633 18 8.72382 18 9.43117L18 16.1224C18 16.9128 17.3592 17.5536 16.5688 17.5536H9.43118C8.64077 17.5536 8.00001 16.9128 8.00001 16.1224L8.00001 13.2362H7.24217L4.38366 15.839C3.54079 16.6064 2.1875 16.0084 2.1875 14.8685V5.50001C2.1875 3.94671 3.4467 2.68752 5 2.68751ZM15.1875 5.5V8L9.43118 8C8.64077 8 8.00002 8.64076 8.00002 9.43117L8.00002 11.6112H6.92768C6.72533 11.6112 6.53027 11.6867 6.38065 11.8229L3.8125 14.1613V5.50001C3.8125 4.84417 4.34416 4.31251 5 4.31251L14 4.3125C14.6558 4.3125 15.1875 4.84416 15.1875 5.5ZM11 10.75C10.5858 10.75 10.25 11.0858 10.25 11.5C10.25 11.9142 10.5858 12.25 11 12.25H15C15.4142 12.25 15.75 11.9142 15.75 11.5C15.75 11.0858 15.4142 10.75 15 10.75H11ZM11 13.75C10.5858 13.75 10.25 14.0858 10.25 14.5C10.25 14.9142 10.5858 15.25 11 15.25H15C15.4142 15.25 15.75 14.9142 15.75 14.5C15.75 14.0858 15.4142 13.75 15 13.75H11Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ModulesIcon;
