import { SvgIcon, SvgIconProps } from './SvgIcon';

export const HomeIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3.5C5 2.94772 5.44772 2.5 6 2.5C6.55229 2.5 7 2.94772 7 3.5V4.90345L8.91881 3.39581C9.55338 2.89722 10.4466 2.89722 11.0812 3.39581L17.4634 8.41038C17.7891 8.66629 17.8456 9.13778 17.5897 9.46349C17.3338 9.78919 16.8623 9.84577 16.5366 9.58986L16.25 9.36465V16.5001C16.25 16.9143 15.9142 17.2501 15.5 17.2501H4.5C4.08579 17.2501 3.75 16.9143 3.75 16.5001V9.36465L3.46337 9.58986C3.13766 9.84577 2.66617 9.78919 2.41026 9.46349C2.15435 9.13778 2.21093 8.66629 2.53664 8.41038L5 6.47488V3.5ZM10.1545 4.57529C10.0638 4.50406 9.9362 4.50406 9.84555 4.57529L5.25 8.18608V15.7501H7.25V12.5C7.25 10.9812 8.48122 9.75 10 9.75C11.5188 9.75 12.75 10.9812 12.75 12.5V15.7501H14.75V8.18608L10.1545 4.57529ZM11.25 15.7501V12.5C11.25 11.8096 10.6904 11.25 10 11.25C9.30965 11.25 8.75 11.8096 8.75 12.5V15.7501H11.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
