import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { CanDisable } from '@modernloop/shared/helper-components';
import { EyeCrossedIcon } from '@modernloop/shared/icons';
import { Paper, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import {
  DateTimeRangeInput,
  JobStageInterview_JobFragment,
  JobStageInterview_JobStageInterviewFragment,
  JobStageInterview_JobStageInterviewGroupsFragment,
  JobStageInterview_JobStageInterviewsFragment,
  JobStageInterview_LinkableInterviewsFragment,
} from 'src/generated/mloop-graphql';

import { OrderLockIcon, OrderShuffleIcon } from 'src/components/icons';

import { isBreak } from 'src/utils/interview';

import Break from './Break';
import BreakSlot from './BreakSlot';
import InterviewCardHeader from './InterviewCardHeader';
import InterviewSeatsModule from './InterviewSeatsModule';
import { InterviewMenuOptions } from './MoreMenuOptions';

type Props = {
  jobStageInterviewId: string;
  groupJobStageInterviewIds: string[];
  index: number;
  groupIndex: number;
  schedulable: boolean;
  readonly?: boolean;
  candidateAvailabilities?: DateTimeRangeInput[];
  onUpdated: (jobStageInterview: JobStageInterview_JobStageInterviewFragment) => void;
  onDelete: (jobStageInterviewId: string) => void;
  onMenuSelect: (jobStageInterviewId: string, option: InterviewMenuOptions) => void;
};

type Fragments = {
  job: JobStageInterview_JobFragment;
  jobStageInterview: JobStageInterview_JobStageInterviewFragment;
  linkableInterviews: JobStageInterview_LinkableInterviewsFragment[];
  jobStageInterviews: JobStageInterview_JobStageInterviewsFragment[];
  jobStageInterviewGroups: JobStageInterview_JobStageInterviewGroupsFragment[];
};

const JobStageInterview: FCWithFragments<Fragments, Props> = ({
  job,
  jobStageInterview,
  linkableInterviews,
  jobStageInterviews,
  jobStageInterviewGroups,
  index,
  groupIndex,
  jobStageInterviewId,
  groupJobStageInterviewIds,
  readonly,
  schedulable,
  candidateAvailabilities,
  onUpdated,
  onDelete,
  onMenuSelect,
}): JSX.Element => {
  const breakImprovementsEnabled = useFlag('org_break_improvements');

  const handleMenuSelect = (option: InterviewMenuOptions) => {
    switch (option) {
      case InterviewMenuOptions.MOVE_UP:
      case InterviewMenuOptions.MOVE_DOWN:
      case InterviewMenuOptions.GROUP_WITH_ABOVE:
      case InterviewMenuOptions.GROUP_WITH_BELOW: {
        onMenuSelect(jobStageInterview.id, option);
        break;
      }
      case InterviewMenuOptions.HIDE_FROM_CANDIDATE:
      case InterviewMenuOptions.SHOW_TO_CANDIDATE: {
        onUpdated({
          ...jobStageInterview,
          isHiddenFromCandidate: option === InterviewMenuOptions.HIDE_FROM_CANDIDATE,
        });
        break;
      }
      default:
    }
  };

  if (isBreak(jobStageInterview.interviewType)) {
    if (breakImprovementsEnabled) {
      return (
        <Grid>
          <Break breakSlot={jobStageInterview} readonly={readonly} onUpdated={onUpdated} />
        </Grid>
      );
    }
    return (
      <Grid
        p={
          (!breakImprovementsEnabled && groupJobStageInterviewIds.length === 1) ||
          (breakImprovementsEnabled && groupJobStageInterviewIds.length === 2)
            ? 0
            : 1
        }
      >
        <BreakSlot
          breakSlot={jobStageInterview}
          jobStageInterviewGroups={jobStageInterviewGroups}
          index={index}
          readonly={readonly}
          isOnlyInterviewInGroup={groupJobStageInterviewIds.length === 1}
          onUpdated={onUpdated}
          onDelete={onDelete}
          onMenuSelect={handleMenuSelect}
        />
      </Grid>
    );
  }

  const originalSeats = linkableInterviews.map((interview) => interview.jobStageInterviewSeats || []).flat() || [];
  const prevInterviewsCount = Math.floor(
    jobStageInterviewGroups
      .filter((g, i) => i < groupIndex)
      .map((g) => g.jobStageInterviews)
      .flat().length / 2
  );

  return (
    <Grid p={(!breakImprovementsEnabled && groupJobStageInterviewIds.length === 1) || breakImprovementsEnabled ? 0 : 1}>
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          overflow: 'hidden',
          borderRadius: groupJobStageInterviewIds.length > (breakImprovementsEnabled ? 2 : 1) ? '6px' : '12px',
        }}
      >
        <CanDisable disabled={Boolean(readonly)}>
          <Grid container direction="column" spacing={2} wrap="nowrap">
            {jobStageInterview.isHiddenFromCandidate && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paperHeader,
                  borderBottom: breakImprovementsEnabled
                    ? undefined
                    : (theme) => `1px solid ${theme.palette.borderState.default}`,
                  padding: (theme) => theme.spacing(1, 2.5),
                  margin: breakImprovementsEnabled ? -1 : -0.5,
                  mb: breakImprovementsEnabled ? 1 : undefined,
                }}
              >
                <EyeCrossedIcon fontSize="small" />
                <Typography variant={breakImprovementsEnabled ? 'body2' : 'subtitle2'} color="text.secondary">
                  Hidden from candidate
                </Typography>
              </Stack>
            )}
            {breakImprovementsEnabled && groupJobStageInterviewIds.length === 2 && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paperHeader,
                  padding: (theme) => theme.spacing(1, 2.5),
                  margin: -1,
                }}
              >
                {jobStageInterview.isLockedOrder ? <OrderLockIcon fontSize={16} /> : <OrderShuffleIcon fontSize={16} />}
                <Typography variant="body2" color="text.secondary">
                  {jobStageInterview.isLockedOrder
                    ? `This interview will happen in position ${prevInterviewsCount + 1}`
                    : 'This interview may swap positions'}
                </Typography>
              </Stack>
            )}
            {breakImprovementsEnabled && groupJobStageInterviewIds.length > 2 && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paperHeader,
                  padding: 1,
                  margin: -1,
                }}
              >
                {jobStageInterview.isLockedOrder ? <OrderLockIcon fontSize={16} /> : <OrderShuffleIcon fontSize={16} />}
                <Typography variant="body2" color="text.secondary">
                  {jobStageInterview.isLockedOrder
                    ? `This interview will happen in position ${index / 2 + 1} in this group`
                    : 'This interview can swap positions with another in this group'}
                </Typography>
              </Stack>
            )}
            <Grid>
              <InterviewCardHeader
                job={job}
                jobStageInterview={jobStageInterview}
                jobStageInterviews={jobStageInterviews}
                jobStageInterviewGroups={jobStageInterviewGroups}
                index={index}
                schedulable={schedulable}
                candidateAvailabilities={candidateAvailabilities}
                onUpdated={onUpdated}
                onDelete={onDelete}
                onMenuSelect={handleMenuSelect}
              />
            </Grid>
            <Grid>
              <InterviewSeatsModule
                jobStageInterviewId={jobStageInterviewId}
                readonly={readonly}
                onUpdated={(seats) => onUpdated({ ...jobStageInterview, jobStageInterviewSeats: seats })}
                onDelete={(seatId: string) => {
                  const updatedInterview: JobStageInterview_JobStageInterviewFragment = {
                    ...jobStageInterview,
                    jobStageInterviewSeats: jobStageInterview?.jobStageInterviewSeats?.filter(
                      (seat) => seat.id !== seatId
                    ),
                  };
                  onUpdated(updatedInterview);
                }}
                seats={jobStageInterview?.jobStageInterviewSeats || []}
                originalSeats={originalSeats}
                linkableInterviews={linkableInterviews}
              />
            </Grid>
          </Grid>
        </CanDisable>
      </Paper>
    </Grid>
  );
};

JobStageInterview.fragments = {
  job: gql`
    ${InterviewCardHeader.fragments.job}
    fragment JobStageInterview_job on Job {
      id
      ...InterviewCardHeader_job
    }
  `,
  jobStageInterview: gql`
    ${InterviewCardHeader.fragments.jobStageInterview}
    ${InterviewSeatsModule.fragments.seats}
    ${BreakSlot.fragments.breakSlot}
    ${Break.fragments.breakSlot}
    fragment JobStageInterview_jobStageInterview on JobStageInterview {
      id
      ...InterviewCardHeader_jobStageInterview
      ...BreakSlot_breakSlot
      ...Break_breakSlot
      jobStageInterviewSeats {
        id
        ...InterviewSeatsModule_seats
      }
    }
  `,
  linkableInterviews: gql`
    ${InterviewSeatsModule.fragments.linkableInterviews}
    ${InterviewSeatsModule.fragments.originalSeats}
    fragment JobStageInterview_linkableInterviews on JobStageInterview {
      id
      ...InterviewSeatsModule_linkableInterviews
      jobStageInterviewSeats {
        id
        ...InterviewSeatsModule_originalSeats
      }
    }
  `,
  jobStageInterviews: gql`
    ${InterviewCardHeader.fragments.jobStageInterviews}
    fragment JobStageInterview_jobStageInterviews on JobStageInterview {
      id
      ...InterviewCardHeader_jobStageInterviews
    }
  `,
  jobStageInterviewGroups: gql`
    ${BreakSlot.fragments.jobStageInterviewGroups}
    ${InterviewCardHeader.fragments.jobStageInterviewGroups}
    fragment JobStageInterview_jobStageInterviewGroups on JobStageInterviewGroup {
      id
      ...BreakSlot_jobStageInterviewGroups
      ...InterviewCardHeader_jobStageInterviewGroups
    }
  `,
};

export default JobStageInterview;
