import { PayloadAction, createSlice } from '@reduxjs/toolkit';

enum ApplicationStatus {
  ACTIVE = 'active',
  CONVERTED = 'converted',
  HIRED = 'hired',
  REJECTED = 'rejected',
}

export type Application = {
  id: string;
  organizationId: string;
  status: ApplicationStatus;
  candidateId: string;
  createdAt: string;
  updatedAt: string;
  jobId: string;
  jobStageId: string | null;
  atsId: string;
  nextScheduledInterviewAt: string;
  applicationStages: string[];
};

export type UpdateApplicationPayload = Pick<Application, 'id'> & Partial<Omit<Application, 'id'>>;

type ApplicationState = {
  byId: { [key: string]: Application };
  loadingById: { [key: string]: boolean };
  errorById: { [key: string]: string };
};

const getInitialState = (): ApplicationState => {
  return {
    byId: {},
    loadingById: {},
    errorById: {},
  };
};

const applicationSlice = createSlice({
  name: 'jobs',
  initialState: getInitialState(),
  reducers: {
    addApplication(state: ApplicationState, action: PayloadAction<Application>) {
      const { payload } = action;
      state.byId[payload.id] = payload;
    },

    updateApplication(state: ApplicationState, action: PayloadAction<UpdateApplicationPayload>) {
      const { payload } = action;
      state.byId[payload.id] = {
        ...state.byId[payload.id],
        ...payload,
      };
    },

    deleteApplication(state: ApplicationState, action: PayloadAction<string>) {
      const { payload: applicationId } = action;
      delete state.byId[applicationId];
    },

    addApplicationError(state: ApplicationState, action: PayloadAction<{ applicationId: string; error: string }>) {
      const { applicationId, error } = action.payload;
      state.errorById[applicationId] = error;
    },

    deleteApplicationError(state: ApplicationState, action: PayloadAction<string>) {
      const { payload: applicationId } = action;
      delete state.errorById[applicationId];
    },

    addApplicationLoading(state: ApplicationState, action: PayloadAction<{ applicationId: string; loading: boolean }>) {
      const { applicationId, loading } = action.payload;
      state.loadingById[applicationId] = loading;
    },

    deleteApplicationLoading(state: ApplicationState, action: PayloadAction<string>) {
      const { payload: applicationId } = action;
      delete state.loadingById[applicationId];
    },
  },
});

export const { reducer } = applicationSlice;
export default applicationSlice;
