// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = { [key: string]: any };

/**
 * Helper function to remove __typename from objects and any nested objects
 * We use this frequently when sending merged data to the server in a mutation call
 * @param obj - the object to remove __typename from
 * @returns the object without __typename
 */
export function removeTypename(obj: AnyObject): AnyObject {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  const newObj: AnyObject = Array.isArray(obj) ? [] : {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (key === '__typename') {
      // eslint-disable-next-line no-continue
      continue;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    newObj[key] = removeTypename(obj[key]);
  }

  return newObj;
}
