import React, { useMemo } from 'react';

import { pluralize } from '@modernloop/shared/utils';
import { Box, Divider, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { differenceInMinutes, parseISO } from 'date-fns';
import { format as formatTz } from 'date-fns-tz';
import { isEmpty } from 'lodash';

import { IntegrationType } from 'src/generated/mloop-graphql';

import DialogContainer, { DialogContainerContent, DialogContainerHeader } from 'src/components/DialogContainer';
import ImageBlock from 'src/components/ImageBlock';
import Button from 'src/components/button';
import Chip from 'src/components/chip';
import CheckIcon from 'src/components/icons/Check';
import CrossIcon from 'src/components/icons/Cross';
import QuestionMarkIcon from 'src/components/icons/QuestionMark';
import UserOptionalIcon from 'src/components/icons/UserOptional';
import WarningIcon from 'src/components/icons/Warning';
import Label from 'src/components/label';
import Tooltip from 'src/components/tooltip';

import useDirectoryInfo from 'src/hooks/useDirectoryInfo';

import { Conflict } from 'src/store/slices/conflicts';

import { Attendee, AttendeeRole, Event, REDACTED_EVENT_ATTENDEE, RSVP } from 'src/utils/api/getEmployeeCalendarEvents';
import { formatRange } from 'src/utils/dateUtils';

import EventDetailsConflictButtons from './EventDetailsConflictButtons';
import EventInfoAlert from './EventInfoAlert';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type EventDetailsProps = {
  event: Event;
  timezone: string;
  open: boolean;
  disableConflict?: boolean;
  isConflict?: boolean;
  conflict?: Conflict;
  markAsConflict?: (isConflict: boolean) => void;
  onClose?: () => void;
};

const useSxProps = () => {
  const theme = useTheme();

  return useMemo(() => {
    return {
      warningHeader: {
        backgroundColor: theme.palette.background.error,
        borderRadius: '6px',
        padding: '8px',
      },
      listItem: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      listItemPlaceholder: {
        width: '20px',
      },
      rsvpIcon: {
        display: 'flex',
      },
      optionalLabel: {
        color: theme.grey.alpha.high,
      },
      description: {
        wordBreak: 'break-word',
      },
    };
  }, [theme.grey.alpha.high, theme.palette.background.error]);
};

const EventDetails = ({
  event,
  timezone,
  open,
  disableConflict,
  isConflict,
  conflict,
  markAsConflict,
  onClose,
}: EventDetailsProps): JSX.Element => {
  const sxProps = useSxProps();
  const directoryInfo = useDirectoryInfo();

  const title = event.is_private || isEmpty(event.title) ? 'Busy' : event.title;
  const allAttendees = event.attendee ?? [];
  // Employees that do not have info redacted
  const visibleAttendees = allAttendees.filter((attendee) => attendee.email !== REDACTED_EVENT_ATTENDEE);
  // Count of employees that have info redacted
  const redactedCount = allAttendees.length - visibleAttendees.length;
  const isAllDay = event.is_all_day;
  const accepted = allAttendees.filter((value) => value.rsvp === RSVP.RSVP_ACCEPTED);
  const tentative = allAttendees.filter((value) => value.rsvp === RSVP.RSVP_TENTATIVE);
  const declined = allAttendees.filter((value) => value.rsvp === RSVP.RSVP_DECLINED);
  const needsAction = allAttendees.filter(
    (value) => value.rsvp === RSVP.RSVP_NEEDS_ACTION || value.rsvp === RSVP.RSVP_NONE
  );

  const attendeeLabel =
    allAttendees.length === 0
      ? '1 accepted'
      : [
          accepted.length > 0 ? `${accepted.length} accepted` : null,
          tentative.length > 0 ? `${tentative.length} maybe` : null,
          declined.length > 0 ? `${declined.length} declined` : null,
          needsAction.length > 0 ? `${needsAction.length} pending` : null,
        ]
          .filter((value) => !!value)
          .join(', ');

  const renderAttendee = (attendee: Attendee) => {
    return (
      <ListItem key={attendee.email} disableGutters sx={sxProps.listItem}>
        <Stack direction="row" alignItems="center">
          <Box sx={sxProps.rsvpIcon}>
            {attendee.rsvp === RSVP.RSVP_ACCEPTED && <CheckIcon color="success" />}
            {attendee.rsvp === RSVP.RSVP_TENTATIVE && <QuestionMarkIcon color="warning" />}
            {attendee.rsvp === RSVP.RSVP_DECLINED && <CrossIcon color="error" />}
            {(attendee.rsvp === RSVP.RSVP_NEEDS_ACTION || attendee.rsvp === RSVP.RSVP_NONE) && (
              <Box sx={sxProps.listItemPlaceholder} />
            )}
          </Box>
          <Chip label={attendee.name || attendee.email || ''} variant="default" />
          {attendee.role === AttendeeRole.ATTENDEE_ROLE_OPTIONAL && (
            <Tooltip tooltip={<Label variant="captions">Optional</Label>}>
              <UserOptionalIcon />
            </Tooltip>
          )}
          {attendee.email === event.organizer?.email && (
            <Label variant="captions" style={sxProps.optionalLabel}>
              Organizer
            </Label>
          )}
        </Stack>
      </ListItem>
    );
  };

  let dateLabel = formatRange(event.start ?? Date.now(), event.end ?? Date.now(), timezone);
  if (isAllDay && event.start && event.end) {
    const eventStart = parseISO(event.start);
    const eventEnd = parseISO(event.end);

    if (differenceInMinutes(eventEnd, eventStart) === 24 * 60) {
      dateLabel = formatTz(eventStart, 'EEEE, MMMM d', { timeZone: timezone });
    } else {
      dateLabel = `${formatTz(eventStart, 'EEEE, MMMM d', { timeZone: timezone })} - ${formatTz(
        eventEnd,
        'EEEE, MMMM d',
        { timeZone: timezone }
      )}`;
    }
  }

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!onClose) return;
    onClose();
  };

  return (
    <DialogContainer open={open} onClose={handleClose}>
      <DialogContainerHeader
        title="Event detail"
        headerActions={<Button variant="outlined" size="small" label="Close" onClick={handleClose} />}
      />
      <DialogContainerContent>
        <Stack spacing={2}>
          <EventInfoAlert event={event} />

          {!disableConflict && isConflict && (
            <Box sx={sxProps.warningHeader}>
              <ImageBlock
                alignItems="center"
                image={<WarningIcon color="error" />}
                title="Overlaps with an interview"
              />
            </Box>
          )}

          <Stack>
            <Label variant="title">{title}</Label>
            <Label>{dateLabel}</Label>
          </Stack>

          {!disableConflict && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box sx={{ display: 'flex', alignSelf: 'baseline' }}>
                  <WarningIcon color={conflict?.isHardConflict === true ? 'error' : undefined} />
                </Box>
                <Stack>
                  <Label variant="captions">Avoid scheduling at this time</Label>
                  {IntegrationType.Microsoft === directoryInfo.type && !event.uid && (
                    <Label variant="captions" color="max-contrast-grey">
                      Private events cannot be avoided due to Outlook limitations
                    </Label>
                  )}
                </Stack>
              </Stack>
              <EventDetailsConflictButtons
                isMarkedAsConflict={conflict?.isHardConflict}
                disabled={!event.uid}
                markAsConflict={(value) => {
                  if (markAsConflict) {
                    markAsConflict(value);
                  }
                }}
              />
            </Stack>
          )}

          <Divider light />

          <Stack>
            <Label variant="captions" fontWeight={600}>{`${event.attendee?.length ?? 1} invited`}</Label>
            <Label variant="captions">{attendeeLabel}</Label>
          </Stack>

          {!event.is_private && (
            <List>
              {renderAttendee({
                email: event.organizer?.email,
                name: event.organizer?.name,
                is_rsvp_requested: true,
                rsvp: RSVP.RSVP_ACCEPTED,
              })}
              {visibleAttendees.length > 0 && (
                <>
                  {visibleAttendees.map((attendee) => {
                    if (attendee.email === event.organizer?.email) return null;
                    return renderAttendee(attendee);
                  })}
                </>
              )}
              {redactedCount > 0 && (
                <ListItem key="redacted-count" disableGutters sx={sxProps.listItem}>
                  <Typography marginLeft="32px" color="text.secondary">
                    {redactedCount} {pluralize('redacted external attendee', redactedCount)}
                  </Typography>
                </ListItem>
              )}
            </List>
          )}

          <Label>
            <span
              style={{ wordBreak: 'break-word' }}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: event.description ?? 'No description' }}
            />
          </Label>
        </Stack>
      </DialogContainerContent>
    </DialogContainer>
  );
};

export default EventDetails;
