import React from 'react';

import { Box, Paper, Typography } from '@mui/material';

const InterviewLogManual = () => {
  return (
    <Paper variant="outlined">
      <Box p={1} textAlign="center">
        <Typography variant="body2">Logged manually. No interview data available.</Typography>
      </Box>
    </Paper>
  );
};

export default InterviewLogManual;
