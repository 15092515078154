import React, { useCallback, useEffect, useState } from 'react';

import { Employee, InterviewModule, JobStageInterviewSeatType } from 'src/generated/mloop-graphql';

import { PREFERENCE_LEVEL_NON_PREFERRED } from 'src/constants/InterviewPlan';

import { deleteJobStageInterviewSeat, updateJobStageInterviewSeat } from 'src/store/actions/job-stage-interview-seat';
import { getLinkableInterviewById } from 'src/store/selectors/job-stage-interview';
import { getJobStageInterviewSeatById } from 'src/store/selectors/job-stage-interview-seat';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import { useDispatch, useSelector } from 'src/store';

import BaseEditInterviewSeatModule from './BaseEditInterviewSeatModule';
import BaseReadInterviewSeatModule from './BaseReadInterviewSeatModule';
import { AutoCompleteOption, AutoCompleteOptionType, BaseInterviewSeatModuleProps } from './types';

enum InterviewSeatMode {
  EDIT = 'edit',
  READ = 'read',
}

const BaseInterviewSeatModule = (props: BaseInterviewSeatModuleProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const [mode, setMode] = useState<InterviewSeatMode>(InterviewSeatMode.READ);
  const { jobStageInterviewId, jobStageInterviewSeatId, seatIndex, onUpdated } = props;

  const jobStageInterviewSeat = useSelector((state) => getJobStageInterviewSeatById(state, jobStageInterviewSeatId));
  const linkableInterviews = useSelector((state) => getLinkableInterviewById(state, jobStageInterviewId));
  useEffect(() => {
    if (
      jobStageInterviewSeat &&
      ((jobStageInterviewSeat.type === JobStageInterviewSeatType.Freeform &&
        jobStageInterviewSeat.employeeIds?.length === 0) ||
        (jobStageInterviewSeat.type === JobStageInterviewSeatType.Module && !jobStageInterviewSeat.interviewId) ||
        (jobStageInterviewSeat.type === JobStageInterviewSeatType.Linked && !jobStageInterviewSeat.interviewSeatId))
    ) {
      setMode(InterviewSeatMode.EDIT);
    }
  }, [jobStageInterviewSeat]);

  const handleEditComplete = useCallback(
    (options: AutoCompleteOption[]) => {
      setMode(InterviewSeatMode.READ);
      if (!options.length) {
        return;
      }
      const { type } = options[0];
      let seat: JobStageInterviewSeat | undefined;

      if (type === AutoCompleteOptionType.EMPLOYEE) {
        const ids = options.map((option) => option.employee?.id as string).filter((x) => x);

        const interviewerPreferrenceLevelMap: { [employeeId: string]: number } = {};
        if (jobStageInterviewSeat.type === JobStageInterviewSeatType.Freeform) {
          jobStageInterviewSeat.freeformSeat?.jobStageInterviewSeatEmployees?.forEach((emp) => {
            if (emp?.employeeId) {
              interviewerPreferrenceLevelMap[emp.employeeId] = emp.preferenceLevel || PREFERENCE_LEVEL_NON_PREFERRED;
            }
          });
        }

        seat = {
          id: jobStageInterviewSeatId,
          jobStageInterviewId,
          type: JobStageInterviewSeatType.Freeform,
          index: seatIndex,
          employeeIds: ids,
          interviewId: undefined,
          interviewSeatId: undefined,
          freeformSeat: {
            jobStageInterviewSeatEmployees: options.map((option) => {
              return {
                jobStageInterviewSeatId,
                employeeId: option?.employee?.id,
                employee: option.employee as Employee,
                preferenceLevel: option?.employee?.id
                  ? interviewerPreferrenceLevelMap[option.employee.id] || PREFERENCE_LEVEL_NON_PREFERRED
                  : PREFERENCE_LEVEL_NON_PREFERRED,
              };
            }),
          },
          moduleSeat: undefined,
          linkedSeat: undefined,
        };
      } else if (type === AutoCompleteOptionType.INTERVIEW) {
        seat = {
          id: jobStageInterviewSeatId,
          jobStageInterviewId,
          type: JobStageInterviewSeatType.Module,
          index: seatIndex,
          employeeIds: undefined,
          interviewId: options[0].interview?.id,
          interviewSeatId: undefined,
          freeformSeat: undefined,
          moduleSeat: {
            interviewModuleId: options[0].interview?.id,
            interviewModule: options[0].interview as InterviewModule,
            selectedEmployeeIds: [],
          },
        };
      } else if (type === AutoCompleteOptionType.SEAT) {
        seat = {
          id: jobStageInterviewSeatId,
          jobStageInterviewId,
          type: JobStageInterviewSeatType.Linked,
          index: seatIndex,
          employeeIds: undefined,
          interviewId: undefined,
          interviewSeatId: options[0].seat?.id,
          freeformSeat: undefined,
          moduleSeat: undefined,
          linkedSeat: {
            linkedJobStageInterviewSeatId: options[0].seat?.id,
          },
        };
      }

      if (!seat) return;
      dispatch(updateJobStageInterviewSeat(seat));
      onUpdated();
    },
    [
      dispatch,
      jobStageInterviewId,
      jobStageInterviewSeat.freeformSeat?.jobStageInterviewSeatEmployees,
      jobStageInterviewSeat.type,
      jobStageInterviewSeatId,
      onUpdated,
      seatIndex,
    ]
  );

  const handleInterviewerPreferenceChanged = useCallback(
    (employeeId: string, preferenceLevel: number) => {
      if (jobStageInterviewSeat.type !== JobStageInterviewSeatType.Freeform) return;

      dispatch(
        updateJobStageInterviewSeat({
          ...jobStageInterviewSeat,
          freeformSeat: {
            ...jobStageInterviewSeat.freeformSeat,
            jobStageInterviewSeatEmployees: jobStageInterviewSeat.freeformSeat?.jobStageInterviewSeatEmployees?.map(
              (emp) => (emp?.employeeId === employeeId ? { ...emp, preferenceLevel } : emp)
            ),
          },
        })
      );
      onUpdated();
    },
    [dispatch, jobStageInterviewSeat, onUpdated]
  );

  const handleDelete = useCallback(() => {
    dispatch(deleteJobStageInterviewSeat(jobStageInterviewId, jobStageInterviewSeatId));
    onUpdated();
  }, [dispatch, jobStageInterviewId, jobStageInterviewSeatId, onUpdated]);

  if (!jobStageInterviewSeat) return null;

  if (mode === InterviewSeatMode.READ) {
    return (
      <BaseReadInterviewSeatModule
        {...props}
        onEdit={() => {
          setMode(InterviewSeatMode.EDIT);
        }}
        jobStageInterviewSeat={jobStageInterviewSeat}
        onDelete={handleDelete}
        linkableInterviews={linkableInterviews}
        onUpdated={onUpdated}
        onInterviewerPreferenceChanged={handleInterviewerPreferenceChanged}
      />
    );
  }

  return (
    <BaseEditInterviewSeatModule
      {...props}
      jobStageInterviewSeat={jobStageInterviewSeat}
      onEditComplete={handleEditComplete}
      onDelete={handleDelete}
      linkableInterviews={linkableInterviews}
    />
  );
};

export default BaseInterviewSeatModule;
