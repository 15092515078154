/* eslint-disable max-lines */
import React, { CSSProperties, useCallback, useMemo, useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { addMinutes, parseISO } from 'date-fns';
import { isEmpty, uniq } from 'lodash';

import { AtsInterviewDefinition, DateTimeRangeInput } from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import IconButton from 'src/components/IconButton';
import Stack from 'src/components/Stack';
import TextField from 'src/components/TextField';
import Button from 'src/components/button';
import DurationTimePicker from 'src/components/date-time-picker/DurationTimePicker';
import {
  DurationFilledIcon,
  OrderLockIcon,
  OrderShuffleIcon,
  ScorecardFilledIcon,
  ScorecardNoneIcon,
  TrashIcon,
} from 'src/components/icons';
import Label from 'src/components/label';
import { DURATION_OPTIONS } from 'src/components/menu/DurationMenu';

import { getAtsInterviewDefinitionFields } from 'src/hooks/atsService/util';
import useDisplayTimezone from 'src/hooks/useDisplayTimezone';

import { deleteJobStageInterview, updateJobStageInterview } from 'src/store/actions/job-stage-interview';
import { lockJobStageInterviewGroup } from 'src/store/actions/job-stage-interview-group';
import { getJobStageById } from 'src/store/selectors/job-stage';
import { getInterviewEndTimeByJobStageId, getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';
import { getJobStageInterviewGroupById } from 'src/store/selectors/job-stage-interview-group';

import { Theme } from 'src/theme/type';

import { assertHexColor } from 'src/types/color';

import { formatRange, startOfDay } from 'src/utils/dateUtils';

import TimezoneSelect from 'src/views-new/TimezoneSelect';

import { useDispatch, useSelector } from 'src/store';
import { DEFAULT_INTERVIEW_NAME } from 'src/strings';

import AtsScorecardSelect from '../Scorecards/AtsScorecardSelect';

import CodingLink from './CodingLink';
import MoreMenuOptions from './MoreMenuOptions';
import useWarningForStartAt from './useWarningForStartAt';

export enum InterviewOptions {
  EDIT_DETAILS = 'edit_details',
  MOVE_UP = 'move_up',
  MOVE_DOWN = 'move_down',
  GROUP_WITH_ABOVE = 'group_with_above',
  GROUP_WITH_BELOW = 'group_with_below',
}

interface Props {
  jobStageInterviewId: string;
  groupId: string;
  index: number; // This is index of slot in group
  schedulable: boolean;
  candidateAvailabilities?: DateTimeRangeInput[];
  onUpdated: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      borderColor: theme.palette.common.transparent,

      '&:hover': {
        borderColor: theme.grey.alpha.low,
      },

      '&:focus-within': {
        borderColor: theme.palette.action.focus,
      },
    },
    candidateTimezoneRead: {
      borderRadius: '6px',
      width: '240px',
      justifyContent: 'left',
    },
  })
);

const InterviewCardHeader = ({
  jobStageInterviewId,
  groupId,
  index,
  schedulable,
  candidateAvailabilities,
  onUpdated,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const displayTimezone = useDisplayTimezone();
  const classes = useStyles();

  const jobStageInterview = useSelector((state) => getJobStageInterviewById(state, jobStageInterviewId));
  const jobStage = useSelector((state) => getJobStageById(state, jobStageInterview.jobStageId));
  const jobStageInterviewEndTime = useSelector((state) =>
    getInterviewEndTimeByJobStageId(state, jobStageInterview.jobStageId)
  );
  const group = useSelector((state) => getJobStageInterviewGroupById(state, groupId));
  const startAtWarning = useWarningForStartAt(jobStageInterviewId, candidateAvailabilities);

  const [hasNameChanged, setHasNameChanged] = useState(false);
  const [showDurationTimePicker, setShowDurationTimePicker] = useState(false);

  const uniqueCandidateAvailabilityDays = useMemo(() => {
    return uniq(
      candidateAvailabilities?.map((availability) => {
        return startOfDay(availability.startAt, displayTimezone).getTime();
      })
    );
  }, [candidateAvailabilities, displayTimezone]);

  const durationOptionsMenu = useRef<HTMLButtonElement>(null);

  const interviewSlotDuration = jobStageInterview.duration;

  let durationText =
    DURATION_OPTIONS.find((option) => option.id === interviewSlotDuration)?.value ||
    `${interviewSlotDuration} ${interviewSlotDuration === 1 ? 'min' : 'mins'}`;

  if (jobStageInterview.forcedStartAt) {
    const startTime = parseISO(jobStageInterview.forcedStartAt);
    const endTime = addMinutes(startTime, interviewSlotDuration);
    durationText = `${formatRange(startTime, endTime, displayTimezone, 'MMM d', 'h:mm aaa')} · ${durationText}`;
  }

  const handlePlanOrderClick = () => {
    dispatch(updateJobStageInterview({ id: jobStageInterviewId, isLockedOrder: !jobStageInterview.isLockedOrder }));

    if (group.jobStageInterviewIds.length === 1) {
      dispatch(lockJobStageInterviewGroup(groupId, !jobStageInterview.isLockedOrder));
    }
    onUpdated();
  };

  const handleInterviewNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setHasNameChanged(true);
    dispatch(updateJobStageInterview({ id: jobStageInterviewId, name: event.target.value }));
    onUpdated();
  };

  const handleRemoveJobStageInterview = () => {
    dispatch(deleteJobStageInterview(jobStageInterviewId));
    onUpdated();
  };

  const handleDurationTimepickerClose = () => {
    setShowDurationTimePicker(false);
  };

  const handleDurationTimeChange = useCallback(
    (duration: number, dateTime: Date | null) => {
      if (dateTime) {
        dispatch(updateJobStageInterview({ id: jobStageInterviewId, duration, forcedStartAt: dateTime.toISOString() }));
      } else {
        dispatch(
          updateJobStageInterview({ id: jobStageInterviewId, duration, forcedStartAt: jobStageInterview.forcedStartAt })
        );
      }
      onUpdated();
    },
    [dispatch, jobStageInterview, jobStageInterviewId, onUpdated]
  );

  const handleClearStartAt = () => {
    dispatch(updateJobStageInterview({ id: jobStageInterviewId, forcedStartAt: null }));
    onUpdated();
  };

  const handleAtsInterviewDefinitionSelect = (atsInterviewDefinition: AtsInterviewDefinition) => {
    let { name } = jobStageInterview;

    if (!hasNameChanged) {
      name = atsInterviewDefinition.name ?? DEFAULT_INTERVIEW_NAME;
    }

    dispatch(
      updateJobStageInterview({
        id: jobStageInterviewId,
        atsInterviewDefinition,

        name,
        duration:
          getAtsInterviewDefinitionFields(atsInterviewDefinition?.atsFields, 'estimatedMinutes') ||
          jobStageInterview.duration,
      })
    );
    onUpdated();
  };

  const itemStyles: { [key: number]: CSSProperties } = { 1: { flexGrow: 1 } };
  const getStartAtForDurationTimePicker = (): Date => {
    if (jobStageInterviewEndTime) return jobStageInterviewEndTime;

    const availabilityStartTimes: Date[] =
      candidateAvailabilities && !isEmpty(candidateAvailabilities)
        ? candidateAvailabilities?.map((value) => parseISO(value.startAt)).sort((a, b) => a.getTime() - b.getTime())
        : [new Date()];

    const now = availabilityStartTimes[0];
    now.setSeconds(0);
    return now;
  };

  const getNoScorecardLabel = () => {
    return (
      <Button
        startIcon={<ScorecardNoneIcon />}
        variant="contained"
        color="default"
        label={
          <Label variant="captions" fontWeight={500} color="max-contrast-grey" noWrap>
            No scorecard
          </Label>
        }
        tooltip="No scorecard"
      />
    );
  };

  const getLabel = () => {
    if (!jobStageInterview?.atsInterviewDefinition?.atsId) {
      return getNoScorecardLabel();
    }

    return (
      <Button
        size="small"
        label={
          <Label variant="captions" fontWeight={500} color="max-contrast-grey" noWrap>
            {jobStageInterview?.atsInterviewDefinition?.name || 'No scorecard'}
          </Label>
        }
        startIcon={<ScorecardFilledIcon color={assertHexColor('#915208')} />}
        variant="contained"
        color="default"
        tooltip={jobStageInterview?.atsInterviewDefinition?.name || 'No scorecard'}
      />
    );
  };

  return (
    <>
      <Stack direction="column" spacing={1}>
        <Stack alignItems="center" itemStyles={itemStyles} spacing={1} wrap="nowrap">
          <IconButton onClick={handlePlanOrderClick}>
            {jobStageInterview.isLockedOrder && <OrderLockIcon tooltip="Locked position" />}
            {!jobStageInterview.isLockedOrder && <OrderShuffleIcon tooltip="Shuffle position" />}
          </IconButton>
          <TextField
            className={classes.textField}
            value={jobStageInterview.name}
            fullWidth
            InputProps={{ style: { fontWeight: 600 } }}
            onChange={handleInterviewNameChange}
          />
          <MoreMenuOptions index={index} jobStageInterviewId={jobStageInterviewId} onUpdated={onUpdated} />
          <IconButton tooltip="Delete interview" onClick={handleRemoveJobStageInterview}>
            <TrashIcon />
          </IconButton>
        </Stack>
        <Stack alignItems="center" spacing={1} wrap="nowrap">
          <Button
            ref={durationOptionsMenu}
            label={
              <Label variant="captions" fontWeight={500} color="max-contrast-grey" noWrap>
                {durationText}
              </Label>
            }
            size="small"
            startIcon={<DurationFilledIcon color={assertHexColor('#FA7800')} />}
            variant="contained"
            color="default"
            tooltip={`Duration: ${durationText}`}
            onClick={() => setShowDurationTimePicker(true)}
          />

          <AtsScorecardSelect
            atsJobId={jobStage?.atsJobId}
            selectScorecard={handleAtsInterviewDefinitionSelect}
            getLabel={getLabel}
          />
          <CodingLink jobStageInterviewId={jobStageInterview.id} onUpdated={onUpdated} />
        </Stack>
        {startAtWarning && <Alert status="warning" title={startAtWarning} alignItems="center" />}
      </Stack>

      {showDurationTimePicker && (
        <DurationTimePicker
          anchorEl={durationOptionsMenu}
          duration={jobStageInterview.duration}
          utcTime={
            jobStageInterview.forcedStartAt
              ? parseISO(jobStageInterview.forcedStartAt)
              : getStartAtForDurationTimePicker()
          }
          timezone={displayTimezone}
          showTimePicker={Boolean(jobStageInterview.forcedStartAt)}
          utcAvailability={uniqueCandidateAvailabilityDays}
          maxDuration={720}
          timezoneSelectComponent={
            <TimezoneSelect openOnFocus fullWidth shouldUpdateDisplayTimezone value={displayTimezone} />
          }
          hideTimePicker={!schedulable}
          onChange={handleDurationTimeChange}
          clearTime={handleClearStartAt}
          onClose={handleDurationTimepickerClose}
        />
      )}
    </>
  );
};

export default InterviewCardHeader;
