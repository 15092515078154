import { ApolloError } from '@apollo/client';
import { Typography } from '@mui/material';
import { AxiosError } from 'axios';

export const PublicError = ({ error }: { error?: ApolloError | AxiosError | string }): JSX.Element | null => {
  let errorMsg = '';
  if (typeof error === 'object') {
    errorMsg = error.message;
  } else if (typeof error === 'string') {
    errorMsg = error;
  }
  if (errorMsg) {
    return (
      <Typography variant="caption" color="error">
        {errorMsg || 'Something went wrong please try again later.'}
      </Typography>
    );
  }
  return null;
};
