import { useMemo } from 'react';

import { QueryResult, gql } from '@apollo/client';

import { DebriefTokensQuery, RenderType, TemplateToken, useDebriefTokensQuery } from 'src/generated/mloop-graphql';

import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';

import { EMPTY_OBJECT } from 'src/constants';

import { getInterviewerIds } from './InterviewPlaceholderFiller/input';
import placeholders from './utils/placeholders';

export const DebriefPlaceholderFillerQuery = gql`
  query DebriefTokens($id: String, $renderType: RenderType!, $input: TokenDebriefScheduleInput!) {
    DEBRIEF_SCHEDULE: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      DEBRIEF_SCHEDULE(input: $input)
    }
  }
`;

/**
 * TOKENS FETCHED
 */
export enum Tokens {
  DEBRIEF_SCHEDULE = 'DEBRIEF_SCHEDULE',
}
export type DebriefPlaceholderFillerOptions = Pick<TemplateToken, keyof typeof Tokens>;

export default class DebriefPlaceholderFiller {
  static getFilledText = (input: string, options: DebriefPlaceholderFillerOptions): string => {
    return placeholders(input, options);
  };

  static getOptions = (data?: DebriefTokensQuery): DebriefPlaceholderFillerOptions => {
    if (!data || !Object.keys(data).length) return EMPTY_OBJECT;
    return {
      DEBRIEF_SCHEDULE: data.DEBRIEF_SCHEDULE?.DEBRIEF_SCHEDULE || '',
    };
  };
}

export type Input = {
  renderType: RenderType;
  schedule: InterviewSchedule | null;
  isFetch?: boolean;
};

export const useDebriefPlaceholderFiller = ({
  renderType,
  schedule,
  isFetch = true,
}: Input): [DebriefPlaceholderFillerOptions, Pick<QueryResult, 'data' | 'error' | 'loading'>] => {
  const event = useMemo(() => {
    return schedule?.events && schedule.events[0];
  }, [schedule]);

  const empIds = useMemo(() => {
    if (!event) return [];
    return getInterviewerIds(event);
  }, [event]);

  const { data, loading, error } = useDebriefTokensQuery({
    skip: !isFetch || !schedule?.id || !empIds.length,
    variables: {
      id: schedule?.id,
      renderType,
      input: {
        employeeIds: empIds,
        name: event?.name || '',
        timeRange: {
          endAt: event?.endAt,
          startAt: event?.startAt,
        },
      },
    },
    errorPolicy: 'all',
  });

  return useMemo(() => {
    return [DebriefPlaceholderFiller.getOptions(data), { data, error, loading }];
  }, [data, error, loading]);
};
