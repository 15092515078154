import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import Box from '@material-ui/core/Box';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import Grid from '@material-ui/core/Grid';

import Stack, { StackProps } from 'src/components/Stack';
import Label, { LabelProps } from 'src/components/label';
import Tooltip from 'src/components/tooltip';
import { BaseProps } from 'src/components/types';

import { SupportedTextColor } from '../utils/color';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface ImageBlockProps extends BaseProps {
  /**
   * Required. Image element of the image block.
   */
  image?: JSX.Element;
  /**
   * Required. Title text of the image block
   */
  title: JSX.Element | string;

  /**
   * Optional. Caption text of the image block
   */
  caption?: JSX.Element | string;

  /**
   * Optional. Actions that can be taken like a button
   */
  actions?: JSX.Element;

  /**
   * Optional. Tooltip to show when hovering on caption.
   */
  captionTooltip?: JSX.Element | string;

  // Support for nowrap when a string is directly passed in.
  noWrap?: LabelProps['noWrap'];

  alignItems?: StackProps['alignItems'];

  fontWeight?: 400 | 500 | 600; // normal, medium, semibold

  color?: SupportedTextColor;

  variant?: 'huge' | 'title' | 'header' | 'body' | 'captions' | 'std-button' | 'small-button';
}

const ImageBlock = ({
  dataTestId,
  image,
  title,
  caption,
  actions,
  captionTooltip,
  noWrap,
  alignItems,
  fontWeight,
  color = 'foreground',
  variant = 'body',
}: ImageBlockProps): JSX.Element => {
  let titleJSX = title;
  if (typeof title === 'string') {
    titleJSX = (
      <Label variant={variant} color={color} noWrap={noWrap} fontWeight={fontWeight}>
        {title}
      </Label>
    );
    if (noWrap) {
      // Need to show the contents if it's cut off.
      titleJSX = <Tooltip tooltip={title}>{titleJSX}</Tooltip>;
    }
  }

  let captionJSX = caption;
  if (typeof caption === 'string') {
    captionJSX = (
      <Label variant="captions" color="high-contrast-grey" noWrap={noWrap}>
        {caption}
      </Label>
    );
    if (noWrap) {
      // Need to show the contents if it's cut off.
      captionJSX = <Tooltip tooltip={captionTooltip ?? caption}>{captionJSX}</Tooltip>;
    }
  }

  return (
    <Grid container wrap="nowrap" spacing={1} alignItems={alignItems} data-testid={dataTestId}>
      {image && (
        <Grid item style={alignItems ? { display: 'flex', alignItems, marginLeft: '-4px' } : { marginLeft: '-4px' }}>
          {image}
        </Grid>
      )}

      <Grid item zeroMinWidth>
        <Stack direction="column">
          {titleJSX}
          {captionJSX && <Box pt="4px">{captionJSX}</Box>}
        </Stack>
      </Grid>
      {actions && (
        <Grid
          xs
          item
          style={{ justifyContent: 'end', display: 'flex', alignItems, flexShrink: 0, flexBasis: 'content' }}
        >
          {actions}
        </Grid>
      )}
    </Grid>
  );
};

export default ImageBlock;
