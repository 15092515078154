import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import _, { difference } from 'lodash';

import {
  Communications_CandidateFragment,
  Communications_InterviewPlanFragment,
  Communications_JobStageSettingsFragment,
} from 'src/generated/mloop-graphql';

import TwoColumnLayout from 'src/components/Layout/TwoColumnLayout';
import Stack from 'src/components/Stack';
import Button from 'src/components/button';
import { CaretLeftIcon, SlackIcon } from 'src/components/icons';
import Label from 'src/components/label';

import { useIntegrations } from 'src/hooks/api/integration';
import { useCodingIntegrationTypes } from 'src/hooks/api/integration/useCodingIntegration';
import { useFlag } from 'src/hooks/feature-flag';

import { updateLastUsedCandidateEmailTemplateID, updateLastUsedCandidateEventTemplateID } from 'src/slices/persist';
import { updateCandidateCalendarTemplateID, updateCandidateTemplateID } from 'src/slices/scheduling';

import {
  addCodeLinkType,
  updateSlackChannelEnabled as newUpdateSlackChannelEnabled,
  updateCandidateCalendarDescription,
  updateCandidateCalendarSummary,
  updateCandidateContentDescription as updateCandidateContentDescriptionV2,
  updateCandidateContentSummary as updateCandidateContentSummaryV2,
  updateCustomVideoMeetingLinksConfig,
  updateVideoMeetingLinkConfig,
  updateZoomMeetingConfig,
} from 'src/store/actions/schedule-communications';
import {
  getAddedCodeLinkTypes,
  getCustomVideoMeetingLink,
  getScheduleContent,
} from 'src/store/selectors/schedule-communications';
import { getCandidateCalendarTemplateID, getCandidateTemplateID } from 'src/store/selectors/scheduling';
import { getLeftNavExpanded } from 'src/store/selectors/ui-state';
import { MeetingLinksConfig } from 'src/store/slices/schedule-communications';

import { EmployeePrefName } from 'src/utils/api/employee';
import getCodingUrlTitle from 'src/utils/getCodingUrlTitle';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';
import ScheduleIssuesButton from 'src/views-new/ScheduleFlow/Steps/Common/ScheduleIssues/ScheduleIssuesButton';
import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import LoadingContent from 'src/views/booking/BookingCreateView/LoadingContent';

import { useDispatch, useSelector } from 'src/store';

import { CalendarCardStoreWrapper } from './CalendarCard';
import CandidateEmailCard from './CandidateEmailCard';
import CandidateEventCard from './CandidateEventCard';
import CodingCard, { iconMap } from './CodingCard';
import CommunicationsPreview from './CommunicationsPreview';
import InterviewerCommunicationCards from './InterviewerCommunicationCards';
import LocationCard from './LocationCard';
import SlackChannelCard from './SlackChannelCard';
import useInitScheduleCommunicationContent from './TemplateContent/useInitScheduleCommunicationContent';
import { useCommunicationTemplatesPref } from './useCommunicationTemplatesPref';

type Fragments = {
  candidate: Communications_CandidateFragment | undefined;
  interviewPlan: Communications_InterviewPlanFragment | undefined;
  jobStageSettings: Communications_JobStageSettingsFragment | undefined;
};

type Props = {
  scheduleFlowType: ScheduleFlowType;
  gotoPrevStep: () => void;
  onComplete: () => void;
};

const Communications: FCWithFragments<Fragments, Props> = ({
  candidate,
  interviewPlan,
  jobStageSettings,
  scheduleFlowType,
  gotoPrevStep,
  onComplete,
}): JSX.Element => {
  const dispatch = useDispatch();
  const { data: integrations, isLoading } = useIntegrations();
  const codingIntegrationTypes = useCodingIntegrationTypes();

  const scheduleFlowData = useScheduleFlowData();

  const {
    stepScheduleContent: { loading },
    candidate: storeCandidate,
    selectedScheduleId,
  } = useSelector((state) => state.scheduling);
  const showEmployeePrefData = useFlag('user_last_used_template_ids_employee_pref');

  const handleUpdateLastUsedCandidateEmailTemplateIDPref = useCommunicationTemplatesPref(
    EmployeePrefName.STR_CANDIDATE_EMAIL_CONFIRMATION_TEMPLATE_ID
  );

  const handleUpdateLastUsedCandidateEventTemplateIDPref = useCommunicationTemplatesPref(
    EmployeePrefName.STR_CANDIDATE_CALENDAR_INVITE_TEMPLATE_ID
  );

  useInitScheduleCommunicationContent(selectedScheduleId);

  const candidateName = storeCandidate ? storeCandidate.fullName : '';

  const addedCodeLinkTypes = useSelector((state) => {
    if (!selectedScheduleId) return [];
    return getAddedCodeLinkTypes(state, selectedScheduleId);
  });

  const candidateTemplateID = useSelector(getCandidateTemplateID);
  const candidateCalendarEventTemplateId = useSelector(getCandidateCalendarTemplateID);

  const scheduleContent = useSelector(getScheduleContent);
  const leftNavExpanded = useSelector(getLeftNavExpanded);

  const customVideoMeetingLink = useSelector((state) => getCustomVideoMeetingLink(state, selectedScheduleId || ''));

  const { candidateTimezone } = scheduleFlowData;

  const { slackChannelEnabled, meetingLinkConfig } = useSelector(
    (state) => state.scheduleCommunications.byId[selectedScheduleId || ''] || {}
  );

  const startAt = new Date(
    _.minBy(scheduleContent?.candidateEventContent?.schedule?.events, 'startAt')?.startAt ?? Date.now()
  );

  if (loading || isLoading) {
    return <LoadingContent />;
  }

  const showSlack = integrations?.slack?.active;
  const pendingCodeLinkTypes = difference(codingIntegrationTypes, addedCodeLinkTypes);
  const showAdd = !slackChannelEnabled && pendingCodeLinkTypes.length > 0;

  const handleZoomMeetingConfigChange = (config: MeetingLinksConfig) => {
    if (!selectedScheduleId) return;
    dispatch(updateZoomMeetingConfig(selectedScheduleId, config));
  };

  const handleCustomMeetingLinkConfigChange = (config: MeetingLinksConfig) => {
    dispatch(updateCustomVideoMeetingLinksConfig(selectedScheduleId || '', config));
  };

  const handleMeetingLinksConfigChange = (meetingLinksConfig: MeetingLinksConfig) => {
    if (!selectedScheduleId) return;
    dispatch(updateVideoMeetingLinkConfig(selectedScheduleId, meetingLinksConfig));
  };

  const leftPane = (
    <Stack spacing={2} direction="column">
      <Stack alignItems="center" justifyContent="space-between">
        <Button startIcon={<CaretLeftIcon />} label="Schedule" variant="outlined" onClick={gotoPrevStep} />
        <ScheduleIssuesButton />
      </Stack>
      <LocationCard
        handleZoomMeetingConfigChange={handleZoomMeetingConfigChange}
        scheduleFlow={ScheduleFlowType.SCHEDULE}
        meetingLinkConfig={meetingLinkConfig}
        interviewMeetingLocationSetting={
          jobStageSettings?.interviewMeetingLocationSettingWithLevel?.interviewMeetingLocationSetting || undefined
        }
        customVideoMeetingURL={customVideoMeetingLink}
        candidatePreferredPhoneNumber={candidate?.preferredPhoneNumber || undefined}
        handleCustomMeetingLinkConfigChange={handleCustomMeetingLinkConfigChange}
        selectedScheduleId={selectedScheduleId || ''}
        onMeetingLinksConfigChange={handleMeetingLinksConfigChange}
      />
      <CalendarCardStoreWrapper />
      <Stack direction="column" spacing={1}>
        {showSlack && slackChannelEnabled && (
          <SlackChannelCard
            scheduleId={selectedScheduleId || ''}
            candidateName={candidateName}
            startAt={startAt}
            candidateTimezone={candidateTimezone}
            interviewPlan={interviewPlan}
          />
        )}
      </Stack>
      {selectedScheduleId && <CodingCard interviewPlan={interviewPlan} scheduleId={selectedScheduleId} />}
      <Stack spacing={1}>
        {showAdd && (
          <div style={{ paddingTop: '8px', paddingLeft: '24px' }}>
            <Label variant="body">Add:</Label>
          </div>
        )}
        {showSlack && !slackChannelEnabled && (
          <Button
            fullWidth
            label="Slack"
            variant="outlined"
            size="medium"
            startIcon={<SlackIcon />}
            onClick={() => {
              dispatch(newUpdateSlackChannelEnabled(selectedScheduleId || '', !slackChannelEnabled));
            }}
          />
        )}
        {pendingCodeLinkTypes.map((pendingCodeLinkType) => {
          return (
            <Button
              key={pendingCodeLinkType}
              fullWidth
              label={getCodingUrlTitle(pendingCodeLinkType)}
              variant="outlined"
              size="medium"
              startIcon={iconMap[pendingCodeLinkType]}
              onClick={() => {
                dispatch(addCodeLinkType(selectedScheduleId || '', pendingCodeLinkType));
              }}
            />
          );
        })}
      </Stack>
      <CandidateEmailCard
        interviewPlan={interviewPlan}
        summary={scheduleContent?.candidateEventContent.summary}
        onSummaryChange={(value) => {
          if (selectedScheduleId) dispatch(updateCandidateContentSummaryV2(selectedScheduleId, value));
        }}
        description={scheduleContent?.candidateEventContent.description}
        onDescriptionChange={(value) => {
          if (selectedScheduleId) dispatch(updateCandidateContentDescriptionV2(selectedScheduleId, value));
        }}
        timezone={candidateTimezone}
        onTimezoneChange={(tz) => {
          if (tz) {
            scheduleFlowData.setCandidateTimezone(tz);
          }
        }}
        templateID={candidateTemplateID}
        onTemplateIDChange={(value) => {
          dispatch(updateCandidateTemplateID(value));

          if (value && showEmployeePrefData) {
            handleUpdateLastUsedCandidateEmailTemplateIDPref(value);
          } else {
            dispatch(updateLastUsedCandidateEmailTemplateID(value));
          }
        }}
      />
      <CandidateEventCard
        interviewPlan={interviewPlan}
        subject={scheduleContent?.candidateCalendarEventContent.subject}
        onSubjectChange={(subject) => {
          if (selectedScheduleId) dispatch(updateCandidateCalendarSummary(selectedScheduleId, subject));
        }}
        body={scheduleContent?.candidateCalendarEventContent.body}
        onBodyChange={(body) => {
          if (selectedScheduleId) dispatch(updateCandidateCalendarDescription(selectedScheduleId, body));
        }}
        templateID={candidateCalendarEventTemplateId}
        onTemplateIDChange={(templateID) => {
          dispatch(updateCandidateCalendarTemplateID(templateID));

          if (templateID && showEmployeePrefData) {
            handleUpdateLastUsedCandidateEventTemplateIDPref(templateID);
          } else {
            dispatch(updateLastUsedCandidateEventTemplateID(templateID));
          }
        }}
      />
      <InterviewerCommunicationCards interviewPlan={interviewPlan} />
    </Stack>
  );

  return (
    <TwoColumnLayout
      leftComponent={leftPane}
      rightComponent={
        <CommunicationsPreview
          interviewPlan={interviewPlan}
          scheduleFlowType={scheduleFlowType}
          onComplete={onComplete}
        />
      }
      mobileBreakpoint={leftNavExpanded ? 'md' : 'sm'}
    />
  );
};

Communications.fragments = {
  candidate: gql`
    fragment Communications_candidate on Candidate {
      id
      preferredPhoneNumber
    }
  `,
  interviewPlan: gql`
    ${CandidateEmailCard.fragments.interviewPlan}
    ${CandidateEventCard.fragments.interviewPlan}
    ${CodingCard.fragments.interviewPlan}
    ${CommunicationsPreview.fragments.interviewPlan}
    ${InterviewerCommunicationCards.fragments.interviewPlan}
    ${SlackChannelCard.fragments.interviewPlan}
    fragment Communications_interviewPlan on JobStage {
      id
      ...CandidateEmailCard_interviewPlan
      ...CandidateEventCard_interviewPlan
      ...CodingCard_interviewPlan
      ...CommunicationsPreview_interviewPlan
      ...InterviewerCommunicationCards_interviewPlan
      ...SlackChannelCard_interviewPlan
    }
  `,
  jobStageSettings: gql`
    ${LocationCard.fragments.interviewMeetingLocationSetting}
    fragment Communications_jobStageSettings on JobStageSettingsV2 {
      id
      interviewMeetingLocationSettingWithLevel {
        interviewMeetingLocationSetting {
          ...LocationCard_interviewMeetingLocationSetting
        }
      }
    }
  `,
};

export default Communications;
