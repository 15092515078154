import React from 'react';

import BaseIcon, { Props } from './Icon';

const SchedulingIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.25C7.41421 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48121 17.75 6L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H5.00002C3.48124 17.75 2.25002 16.5188 2.25002 15L2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H6.25V2C6.25 1.58579 6.58579 1.25 7 1.25ZM6.25 4.75H5C4.30964 4.75 3.75 5.30965 3.75 6L3.75002 15C3.75002 15.6904 4.30966 16.25 5.00002 16.25L15 16.25C15.6904 16.25 16.25 15.6904 16.25 15L16.25 6C16.25 5.30964 15.6903 4.75 15 4.75H13.75V5C13.75 5.41421 13.4142 5.75 13 5.75C12.5858 5.75 12.25 5.41421 12.25 5V4.75H7.75V5C7.75 5.41421 7.41421 5.75 7 5.75C6.58579 5.75 6.25 5.41421 6.25 5V4.75ZM10 6.75C10.4142 6.75 10.75 7.08579 10.75 7.5V9.75H13C13.4142 9.75 13.75 10.0858 13.75 10.5C13.75 10.9142 13.4142 11.25 13 11.25H10.75V13.5C10.75 13.9142 10.4142 14.25 10 14.25C9.58579 14.25 9.25 13.9142 9.25 13.5V11.25H7C6.58579 11.25 6.25 10.9142 6.25 10.5C6.25 10.0858 6.58579 9.75 7 9.75H9.25V7.5C9.25 7.08579 9.58579 6.75 10 6.75Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SchedulingIcon;
