import useHistory from './useHistory';

/**
 * useGoBackOrHome
 *
 * This hook provides a function to navigate back to the previous page
 * within the app or redirect to the home page if there is no history.
 *
 * @returns {Function} goBackOrHome - Function to navigate back or to the home page
 */
const useGoBackOrHome = () => {
  const history = useHistory();

  /**
   * Navigates back to the previous page if history length is greater than 1.
   * Redirects to the home page if there is no previous page in the history stack.
   */
  const goBackOrHome = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(`/`);
    }
  };

  return goBackOrHome;
};

export default useGoBackOrHome;
