import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ReorderIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <circle cx="12.2999" cy="6" r="1" fill="currentColor" />
      <circle cx="8.29987" cy="6" r="1" fill="currentColor" />
      <circle cx="12.2999" cy="10" r="1" fill="currentColor" />
      <circle cx="8.29987" cy="10" r="1" fill="currentColor" />
      <circle cx="12.2999" cy="14" r="1" fill="currentColor" />
      <circle cx="8.29987" cy="14" r="1" fill="currentColor" />
    </SvgIcon>
  );
};
