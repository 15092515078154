import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;

  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.81282 11.9444C2.73887 10.8705 2.73887 9.12929 3.81282 8.05535L8.05546 3.81271C9.1294 2.73877 10.8706 2.73877 11.9445 3.81271L16.1872 8.05535C17.2611 9.12929 17.2611 10.8705 16.1872 11.9444L11.9445 16.1871C10.8706 17.261 9.1294 17.261 8.05546 16.1871L3.81282 11.9444ZM4.87348 9.11601C4.38532 9.60416 4.38532 10.3956 4.87348 10.8838L9.11612 15.1264C9.60427 15.6146 10.3957 15.6146 10.8839 15.1264L15.1265 10.8838C15.6147 10.3956 15.6147 9.60416 15.1265 9.11601L10.8839 4.87337C10.3957 4.38521 9.60427 4.38521 9.11612 4.87337L4.87348 9.11601ZM10.0002 7.24996C10.4144 7.24996 10.7502 7.58575 10.7502 7.99996V9.24992L12.0002 9.24992C12.4144 9.24992 12.7502 9.58571 12.7502 9.99992C12.7502 10.4141 12.4144 10.7499 12.0002 10.7499L10.7502 10.7499V12C10.7502 12.4142 10.4144 12.75 10.0002 12.75C9.58595 12.75 9.25017 12.4142 9.25017 12V10.7499L8.00021 10.7499C7.58599 10.7499 7.25021 10.4141 7.25021 9.99992C7.25021 9.58571 7.58599 9.24992 8.00021 9.24992H9.25017L9.25017 7.99996C9.25017 7.58575 9.58595 7.24996 10.0002 7.24996Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
