import { DeprecatedTimezoneMap } from './deprecatedTimezones';
import { RecognizedTimezones } from './timezone-data';

export const getLocalTimezone = () => {
  // eslint-disable-next-line no-restricted-syntax
  const browserTimezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

  if (RecognizedTimezones.includes(browserTimezone)) {
    return browserTimezone;
  }

  if (DeprecatedTimezoneMap[browserTimezone]) {
    return DeprecatedTimezoneMap[browserTimezone];
  }

  return browserTimezone;
};
