import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { CounterNumberInput, Dialog } from '@modernloop/shared/components';
import { PublicError } from '@modernloop/shared/helper-components';
import { ReverseShadowIcon, ShadowIcon } from '@modernloop/shared/icons';
import { Checkbox, CircularProgress, Divider, FormControlLabel, Stack, Typography } from '@mui/material';

import {
  AddToTrainingPlan_ModuleMemberFragment,
  TrainingStatus,
  useInterviewModuleMemberUpdateMutation,
} from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import { resetModuleMemberListCache } from 'src/entities/InterviewModuleMember/utils';

import { MAX_COUNT_OF_TRAINING_INTERVIEWS, MIN_COUNT_OF_TRAINING_INTERVIEWS } from 'src/constants';

type Props = {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
};

type Fragments = {
  moduleMember: AddToTrainingPlan_ModuleMemberFragment;
};

const AddToTrainingPlan: FCWithFragments<Fragments, Props> = ({ moduleMember, open, onClose, onUpdate }) => {
  const [updateMember, { loading, error }] = useInterviewModuleMemberUpdateMutation();
  const [additionalShadows, setAdditionalShadows] = useState<number>(MIN_COUNT_OF_TRAINING_INTERVIEWS);
  const [additionalReverseShadows, setAdditionalReverseShadows] = useState<number>(MIN_COUNT_OF_TRAINING_INTERVIEWS);
  const [clearTrainedAt, setClearTrainedAt] = useState<boolean | undefined>(undefined);

  const handleShadowRequiredChange = (newValue: number) => {
    setAdditionalShadows(newValue);
  };

  const handleReverseShadowRequiredChange = (newValue: number) => {
    setAdditionalReverseShadows(newValue);
  };

  const handleUpdate = async () => {
    try {
      await updateMember({
        variables: {
          input: {
            employeeId: moduleMember.employeeId,
            interviewModuleId: moduleMember.interviewModuleId,
            shadowRequiredCount:
              moduleMember?.stats?.completedAsShadow + moduleMember?.stats?.manualCompletedAsShadow + additionalShadows,
            reverseShadowRequiredCount:
              moduleMember?.stats?.completedAsReverseShadow +
              moduleMember?.stats?.manualCompletedAsReverseShadow +
              additionalReverseShadows,
            status: additionalShadows ? TrainingStatus.Shadow : TrainingStatus.ReverseShadow,
            clearTrainedAt,
          },
        },
        refetchQueries: ['InterviewModuleMemberSidepanelQuery'],
        awaitRefetchQueries: true,
        update: (cache) => {
          resetModuleMemberListCache({
            cache,
            moduleId: moduleMember.interviewModuleId,
            employeeIds: [moduleMember.employeeId],
          });
        },
      });
      onUpdate();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog
      cancelOptions={{ label: 'Cancel', onClick: onClose }}
      submitOptions={{
        label: 'Add to training plan',
        onClick: handleUpdate,
        isDisabled: loading || (!additionalShadows && !additionalReverseShadows),
        endIcon: loading ? <CircularProgress size="16px" /> : undefined,
      }}
      title="Add to training plan"
      maxWidth="xs"
      open={open}
      onClose={onClose}
    >
      <Stack direction="column" spacing={2}>
        <Typography>How many additional trainings does this interviewer need?</Typography>
        <Stack rowGap={1} direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography>
            <Stack display="flex" direction="row" alignItems="center" columnGap={1}>
              <ShadowIcon />
              Shadows
            </Stack>
          </Typography>

          <CounterNumberInput
            min={MIN_COUNT_OF_TRAINING_INTERVIEWS}
            max={MAX_COUNT_OF_TRAINING_INTERVIEWS}
            onChange={(value) => {
              handleShadowRequiredChange(value);
            }}
            value={additionalShadows}
          />
        </Stack>

        <Stack rowGap={1} direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography>
            <Stack display="flex" direction="row" alignItems="center" columnGap={1}>
              <ReverseShadowIcon />
              Reverse shadows
            </Stack>
          </Typography>
          <CounterNumberInput
            min={MIN_COUNT_OF_TRAINING_INTERVIEWS}
            max={MAX_COUNT_OF_TRAINING_INTERVIEWS}
            onChange={(value) => {
              handleReverseShadowRequiredChange(value);
            }}
            value={additionalReverseShadows}
          />
        </Stack>

        <Divider />

        <FormControlLabel
          control={<Checkbox checked={clearTrainedAt} onChange={() => setClearTrainedAt(!clearTrainedAt)} />}
          label="Remove fully trained date from key dates"
          onChange={() => setClearTrainedAt(!clearTrainedAt)}
        />
      </Stack>

      <PublicError error={error} />
    </Dialog>
  );
};

AddToTrainingPlan.fragments = {
  moduleMember: gql`
    fragment AddToTrainingPlan_moduleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      shadowsRequired
      reverseShadowsRequired
      stats {
        completedAsShadow
        completedAsReverseShadow
        manualCompletedAsShadow
        manualCompletedAsReverseShadow
      }
    }
  `,
};

export default AddToTrainingPlan;
