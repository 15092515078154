/* eslint-disable react/no-multi-comp */
import React from 'react';
import type { FC } from 'react';

import MomentUtils from '@date-io/moment';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { StylesProvider, ThemeProvider, jssPreset } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ScrollReset } from '@modernloop/shared/helper-components';
import { CookieConsentProvider } from '@modernloop/shared/hooks';
import { ThemeProvider as ThemeProviderMuiV5 } from '@mui/material';
import { ThemeProvider as ThemeProviderMuiV5Styles } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';

import { AutoRefreshingRouter } from 'src/components/AutoRefreshingRouter';
import Toast from 'src/components/Toast';

import LDProviderWrapper from 'src/contexts/LDProviderWrapper';
import { AuthProvider } from 'src/contexts/auth0/Auth0Context';

import { Theme } from 'src/themeMui5/type';

import { AnalyticsServices } from 'src/views-new/AnalyticsServices';

import GlobalStyles from 'src/shared/app/GlobalStyles';
import IdentifyLoggers from 'src/shared/app/IdentifyLoggers';
import createTheme from 'src/theme';
import { createMergedTheme } from 'src/themeMui5';

// // This sets up the correct types for `makeStyles` from `@mui/styles`
declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

/**
 * The component that wraps the entire application for authenticated users.
 * This component sets up the theme, and provides the context providers for the application.
 */
const BaseApp: FC = ({ children }) => {
  const mui5Theme = createMergedTheme();
  return (
    <AutoRefreshingRouter>
      <ThemeProvider theme={createTheme()}>
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ThemeProviderMuiV5Styles theme={mui5Theme}>
                <ThemeProviderMuiV5 theme={mui5Theme}>
                  <SnackbarProvider
                    TransitionProps={{ direction: 'up' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    Components={{ default: Toast, error: Toast, info: Toast, success: Toast, warning: Toast }}
                    dense
                    maxSnack={3}
                  >
                    <GlobalStyles />
                    <ScrollReset />
                    <AuthProvider>
                      <LDProviderWrapper>
                        <CookieConsentProvider>
                          <AnalyticsServices />
                          <IdentifyLoggers />
                          {children}
                        </CookieConsentProvider>
                      </LDProviderWrapper>
                    </AuthProvider>
                  </SnackbarProvider>
                </ThemeProviderMuiV5>
              </ThemeProviderMuiV5Styles>
            </LocalizationProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </AutoRefreshingRouter>
  );
};

export default BaseApp;
