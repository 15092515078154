import { logError } from '../utils/logError';
import { FeatureFlagJson } from './FeatureFlagJson';
import { useFeatureFlagLocal } from './useFeatureFlagLocal';
// eslint-disable-next-line no-restricted-imports
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';

/**
 * JSON based feature flag checker.
 * It complains if it can't find the specified flag in the LaunchDarkly payload.
 *
 * https://docs.launchdarkly.com/home/getting-started/feature-flags
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFeatureFlagJson(key: FeatureFlagJson): any {
  const flags = useFlags();
  const { localStorageFeatureFlags } = useFeatureFlagLocal();

  if (!(key in flags)) {
    if (!isEmpty(flags)) {
      logError(`Feature Flag "${key}" is not being sent to client`);
    }
    return null;
  }

  if (flags['MLOnly'] === true) {
    const localFeatureFlagValue = localStorageFeatureFlags[key];
    if (localFeatureFlagValue !== undefined) {
      return JSON.parse(localFeatureFlagValue as string);
    }
  }

  return flags[key];
}
