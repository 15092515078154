/* eslint-disable max-lines */
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { useFlag } from '@modernloop/shared/feature-flag';
import { isNil } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  CandidateAvailabilityOptionsInterviewPlanQuery,
  CandidateAvailabilityOptionsSettings_JobStageSettingsFragment,
  CandidateAvailabilityOptions_JobStageSettingsFragment,
  CreateTaskDialogPage_InterviewPlanFragment,
  JobStageInterviewGroupInput,
  JobStage_InterviewPlanFragment,
  StringOrgPref,
  TaskCreationSource,
  useCandidateAvailabilityOptionsInterviewPlanLazyQuery,
  useCandidateAvailabilityOptionsInterviewPlanQuery,
  useCandidateAvailabilityOptionsModalQuery,
  useCandidateDetailsQuery,
} from 'src/generated/mloop-graphql';

import ChildPropsDialog from 'src/components/Dialog/ChildPropsDialog';
import ShareDialog, { CreateType } from 'src/components/Dialogs/ShareDialog';

import { EmployeeFragment } from 'src/entities/EmployeePicker/EmployeeGraphQL';
import cloneInterviewPlan from 'src/entities/InterviewPlan/cloneInterviewPlan';
import getSaveInterviewPlanInput from 'src/entities/InterviewPlan/getSaveInterviewPlanInput';

import { useOrgPrefString } from 'src/hooks/api/org';
import { useDefaultCcEmployeeIds } from 'src/hooks/useDefaultCCEmployeeIds';

import {
  CandidateAvailabilityOptions,
  DEFAULT_MINIMUM_TIME_BLOCK_MINUTES,
  DEFAULT_MINUTES_PER_DAY,
  DEFAULT_NUMBER_OF_DAYS,
} from 'src/store/slices/candidate-availability-options';

import { useCandidatePortalUrl } from 'src/urls/hooks/useCandidatePortalUrl';

import { OrgPrefName } from 'src/utils/api/org';
import { isDayBreak } from 'src/utils/interview';

import { TaskShareModalContext } from '../ApplicationDetailsTaskView/ApplicationContent/TaskShareDialogHandler';
import {
  DAY_CALC_FROM_INTERVIEW_PLAN,
  DURATION_CALC_FROM_INTERVIEW_PLAN,
} from '../JobDetailsPage/OrgSelfScheduleOptions';
import CreateTaskDialogPage from '../ScheduleTask/CreateModals/shared/CreateTaskDialogPage';
import { TaskCreateInputWrapper } from '../ScheduleTask/CreateModals/shared/types';
import useDefaultTaskAssignee from '../ScheduleTask/CreateModals/shared/useDefaultTaskAssignee';
import { NumberOfDays } from '../SelfSchedule/TimeframePicker';

import Options from './Options';
import DeleteCandidateAvailabilityConfirmationDialog from './Options/DeleteCandidateAvailabilityConfirmationDialog';
import ShareOptions from './ShareOptions';

type JobStageInterviewGroups =
  | JobStage_InterviewPlanFragment['jobStageInterviewGroups']
  | JobStageInterviewGroupInput[];

export enum CandidateAvailabilityOptionsModalPage {
  BASIC_CONFIGURATION = 0,
  SAVE_SUCCESS = 1,
  SHARE_AVAILABILITY = 2,
  DELETE_AVAILABILITY_CONFIRMATION = 3,
  CREATE_TASK = 4,
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type CandidateAvailabilityOptionsModalFragmentProps = {
  settings?: CandidateAvailabilityOptionsSettings_JobStageSettingsFragment | null;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type CandidateAvailabilityOptionsModalProps = {
  applicationId: string;
  candidateId: string;
  jobStageId: string;
  jobId: string;
  interviewPlanId?: string;
  /**
   * If taskId is provided, the modal will skip the task create step
   * If taskId is not provided, the modal will show the task create step and allow task creation
   */
  taskId?: string;
  /**
   * Show create task step
   */
  showCreateTaskStep?: boolean;
  timezone: string;
  initialPage?: CandidateAvailabilityOptionsModalPage;
  disableEditOptions?: boolean;
  taskOptions?: CandidateAvailabilityOptions;
  onClose: () => void;
  onSaveSuccess?: () => void;
  onDelete?: () => void;
  onJobStageIdChange?: (jobStageId: string) => void;
};

export const GQL_CANDIDATE_AVAILABILITY = gql`
  ${CreateTaskDialogPage.fragments.application}
  ${EmployeeFragment}
  query CandidateAvailabilityOptionsModal($applicationId: uuid!) {
    application(id: $applicationId) {
      id
      ...CreateTaskDialogPage_application
    }
    thisEmployee {
      id
      ...Employee
    }
  }
`;

export const JobStageInterviewPlan = gql`
  ${CreateTaskDialogPage.fragments.interviewPlan}
  ${cloneInterviewPlan.fragments.interviewPlan}
  ${getSaveInterviewPlanInput.fragments.interviewPlan}
  ${Options.fragments.interviewPlan}

  query CandidateAvailabilityOptionsInterviewPlan($jobStageId: uuid!) {
    jobStage(id: $jobStageId) {
      id
      ...CreateTaskDialogPage_interviewPlan
      ...cloneInterviewPlan_interviewPlan
      ...getSaveInterviewPlanInput_interviewPlan
      ...CandidateAvailabilityOption_interviewPlan
    }
  }
`;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
export const CandidateAvailabilityOptionsModal = ({
  applicationId,
  candidateId,
  jobStageId,
  jobId,
  interviewPlanId,
  taskId,
  showCreateTaskStep,
  timezone,
  initialPage,
  disableEditOptions,
  taskOptions,
  settings: availabilitySettings,
  onClose,
  onSaveSuccess,
  onDelete,
  onJobStageIdChange,
}: CandidateAvailabilityOptionsModalFragmentProps & CandidateAvailabilityOptionsModalProps): JSX.Element => {
  const ignoreRollingDaysSetting = useFlag('user_ignore_rolling_window_setting');
  const interviewPlanEntityInTaskEnabled = useFlag('interview_plan_entity_in_tasks');
  const showDefaultTaskComment = useFlag('org_default_task_comment');
  const orgBreakImprovementsEnabled = useFlag('org_break_improvements');

  const shareContext = useContext(TaskShareModalContext);
  const [currentPage, setCurrentPage] = useState<CandidateAvailabilityOptionsModalPage>(
    initialPage || CandidateAvailabilityOptionsModalPage.BASIC_CONFIGURATION
  );
  const [shareViaShareDialog, setShareViaShareDialog] = useState(false);
  const [options, setOptions] = useState<CandidateAvailabilityOptions | undefined>(taskOptions);

  const { data: candidate } = useCandidateDetailsQuery({ variables: { id: candidateId } });
  const { data: modalData } = useCandidateAvailabilityOptionsModalQuery({
    variables: { applicationId },
    skip: !applicationId,
  });
  const { assignee, loading: assigneeLoading } = useDefaultTaskAssignee(
    applicationId,
    OrgPrefName.STR_TASK_DEFAULT_ASSIGNEE_AVAILABILITY_REQUESTS
  );

  // This needs to be done temporarily because some bad settings got into place where these
  // values were set to 0 and it was causing the failure of the availability request creation.
  // This can be removed once we are sure that all the bad settings have been fixed.
  const settings = useMemo(() => {
    if (!availabilitySettings || !availabilitySettings.candidateAvailabilityOptions) {
      return availabilitySettings;
    }

    const newOptions = { ...availabilitySettings.candidateAvailabilityOptions };
    const newSettings = { ...availabilitySettings };
    if (!newSettings.candidateAvailabilityOptions) {
      return newSettings;
    }

    if (!newOptions.numberOfDays) {
      newOptions.numberOfDays = DAY_CALC_FROM_INTERVIEW_PLAN;
    }
    if (!newOptions.minutesPerDays) {
      newOptions.minutesPerDays = DURATION_CALC_FROM_INTERVIEW_PLAN;
    }
    if (!newSettings.candidateAvailabilityOptions?.minimumTimeBlockMinutes) {
      newOptions.minimumTimeBlockMinutes = DURATION_CALC_FROM_INTERVIEW_PLAN;
    }
    if (isNil(newOptions.advanceNoticeMinutes)) {
      newOptions.advanceNoticeMinutes = 24 * 60;
    }

    return {
      ...availabilitySettings,
      candidateAvailabilityOptions: newOptions,
    };
  }, [availabilitySettings]);

  const { candidatePortalUrl } = useCandidatePortalUrl(applicationId, { taskId: options?.taskId || taskId });

  const [input, setInput] = React.useState<TaskCreateInputWrapper>({
    applicationId,
    jobStageId,
    assigneeEmployeeId: assignee?.id,
    assignee,
    candidateTimezone: modalData?.application?.candidate?.timezone || getLocalTimezone(),
    isUrgent: false,
    isDebriefRequired: false,
    creationSource: TaskCreationSource.CandidateAvailabilityRequest,
    customInterviewPlan: [],
    customJobStageId: null,
    taskQueueId: modalData?.application?.job?.jobSettings.defaultTaskQueue?.id,
  });

  const [interviewPlan, setInterviewPlan] = useState<CreateTaskDialogPage_InterviewPlanFragment>({
    id: '',
    jobStageInterviewGroups: [],
  });

  const onInterviewPlanFetchCompleted = (data: CandidateAvailabilityOptionsInterviewPlanQuery) => {
    if (!data.jobStage) return;

    const newCustomJobStageId = interviewPlanId || uuid();

    const interviewPlanClone = cloneInterviewPlan(
      { interviewPlan: data.jobStage },
      { customJobStageId: newCustomJobStageId }
    );

    setInterviewPlan(interviewPlanClone);

    const interviewPlanInput = getSaveInterviewPlanInput(
      { interviewPlan: interviewPlanClone },
      { finalJobStageId: newCustomJobStageId }
    );

    if (orgBreakImprovementsEnabled) {
      setInput((prev) => ({
        ...prev,
        customJobStageId: newCustomJobStageId,
        interviewPlan: {
          groups: interviewPlanInput.groups,
          schedulingWindow: interviewPlanInput.schedulingWindow
            ? { seconds: interviewPlanInput.schedulingWindow.seconds }
            : undefined,
          excludedEmployeeIds: interviewPlanInput.excludedEmployeeIds || [],
        },
      }));
    } else {
      setInput((prev) => ({
        ...prev,
        customJobStageId: newCustomJobStageId,
        customInterviewPlan: interviewPlanInput.groups,
      }));
    }
  };

  const [defaultCommentPrefValue, { loading: defaultTaskCommentLoading }] = useOrgPrefString(
    StringOrgPref.StrDefaultTaskComment
  );

  useEffect(() => {
    if (defaultTaskCommentLoading || !showDefaultTaskComment) {
      return;
    }

    setInput((prev) => ({
      ...prev,
      note: defaultCommentPrefValue || '',
    }));
  }, [defaultCommentPrefValue, defaultTaskCommentLoading, setInput, showDefaultTaskComment]);

  // After we load the assignee, we need to set the assignee in the input
  useEffect(() => {
    if (assigneeLoading) {
      return;
    }

    setInput((prev) => ({
      ...prev,
      assigneeEmployeeId: assignee?.id,
      assignee,
    }));
  }, [assigneeLoading, assignee, setInput]);

  const { loading: interviewPlanLoading } = useCandidateAvailabilityOptionsInterviewPlanQuery({
    variables: { jobStageId: interviewPlanId || input.jobStageId || jobStageId },
    skip: !interviewPlanEntityInTaskEnabled,
    onCompleted: onInterviewPlanFetchCompleted,
  });

  const [fetchInterviewPlan, { loading: interviewPlanLazyLoading }] =
    useCandidateAvailabilityOptionsInterviewPlanLazyQuery({
      variables: { jobStageId: interviewPlanId || input.jobStageId || jobStageId },
      onCompleted: onInterviewPlanFetchCompleted,
    });

  const availabilityUrl = candidatePortalUrl;

  const defaultCcEmployeeIds = useDefaultCcEmployeeIds(
    candidate?.candidate?.coordinatorId,
    candidate?.candidate?.recruiterId
  );

  const handleOptionsChange = (newOptions: CandidateAvailabilityOptions) => {
    setOptions(newOptions);
  };

  const getBasicAvailabilityOptionsConfig = (
    groups: JobStageInterviewGroups,
    candidateAvailabilitySettings?: CandidateAvailabilityOptions_JobStageSettingsFragment | null
  ) => {
    const savedOptions = candidateAvailabilitySettings?.candidateAvailabilityOptions;
    let numOfDays = DEFAULT_NUMBER_OF_DAYS;
    let minsPerDays = 0;
    let minTimeBlockMinutes = 0;
    const interviews = groups?.map((group) => group?.jobStageInterviews).flat();
    if (interviews && interviews.length) {
      interviews.forEach((interview) => {
        if (interview?.interviewType && isDayBreak(interview?.interviewType)) {
          numOfDays += 1;
        }

        if (interview?.duration && minTimeBlockMinutes < interview?.duration) {
          minTimeBlockMinutes = interview.duration;
        }

        minsPerDays += interview?.duration || 0;
      });
    } else {
      minsPerDays = DEFAULT_MINUTES_PER_DAY;
      minTimeBlockMinutes = DEFAULT_MINIMUM_TIME_BLOCK_MINUTES;
    }

    const exactMinsPerDay =
      savedOptions?.minutesPerDays !== DURATION_CALC_FROM_INTERVIEW_PLAN
        ? savedOptions?.minutesPerDays ?? minsPerDays
        : minsPerDays;

    const exactMinimumTimeBlockMinutes =
      savedOptions?.minimumTimeBlockMinutes !== DURATION_CALC_FROM_INTERVIEW_PLAN
        ? savedOptions?.minimumTimeBlockMinutes ?? minTimeBlockMinutes
        : minTimeBlockMinutes;

    return {
      numberOfDays:
        savedOptions?.numberOfDays !== DAY_CALC_FROM_INTERVIEW_PLAN
          ? savedOptions?.numberOfDays ?? numOfDays
          : numOfDays,
      minutesPerDays: exactMinsPerDay,
      minimumTimeBlockMinutes: exactMinimumTimeBlockMinutes,
    };
  };

  if (currentPage === CandidateAvailabilityOptionsModalPage.SAVE_SUCCESS) {
    return (
      <ShareDialog
        longUrl={availabilityUrl}
        content="This is your candidate’s personal link for submitting availability for this stage of their application."
        onClose={onClose}
        onSendEmail={() => {
          setShareViaShareDialog(true);
          setCurrentPage(CandidateAvailabilityOptionsModalPage.SHARE_AVAILABILITY);
        }}
      />
    );
  }

  const onOptionsBack =
    showCreateTaskStep && !taskId
      ? () => {
          setCurrentPage(CandidateAvailabilityOptionsModalPage.CREATE_TASK);
        }
      : undefined;

  const jsx = (
    <>
      {currentPage === CandidateAvailabilityOptionsModalPage.CREATE_TASK &&
        !assigneeLoading &&
        modalData?.application &&
        showCreateTaskStep && (
          <CreateTaskDialogPage
            allowEmptyPlan
            title="Request Availability"
            application={modalData.application}
            onClose={onClose}
            input={input}
            interviewPlan={interviewPlan}
            interviewPlanLoading={interviewPlanLoading || interviewPlanLazyLoading}
            source={TaskCreationSource.CandidateAvailabilityRequest}
            setInput={setInput}
            onContinue={(newInput) => {
              setInput(newInput);
              if (input.customInterviewPlan) {
                const availabilityConfig = getBasicAvailabilityOptionsConfig(input.customInterviewPlan, settings);
                if (options) {
                  let changeOptions: CandidateAvailabilityOptions = {
                    ...options,
                    ...{
                      advanceNoticeMinutes: settings?.candidateAvailabilityOptions?.advanceNoticeMinutes ?? 24 * 60,
                      candidateNote: settings?.candidateAvailabilityOptions?.candidateNote ?? '',
                    },
                    numberOfDays: availabilityConfig.numberOfDays,
                    minutesPerDays: availabilityConfig.minutesPerDays,
                    minimumTimeBlockMinutes: availabilityConfig.minimumTimeBlockMinutes,
                  };

                  changeOptions = {
                    ...changeOptions,
                    useRollingDays: ignoreRollingDaysSetting
                      ? true
                      : settings?.candidateAvailabilityOptions?.useRollingDays ?? true,
                    timeframeNumberOfDays:
                      settings?.candidateAvailabilityOptions?.timeframeNumberOfDays ?? NumberOfDays.TwoWeeks,
                    canScheduleOverAvailableKeywords:
                      settings?.candidateAvailabilityOptions?.canScheduleOverAvailableKeywords ?? false,
                    canScheduleOverRecruitingKeywords:
                      settings?.candidateAvailabilityOptions?.canScheduleOverRecruitingKeywords ?? false,
                    canScheduleOverFreeTime: settings?.candidateAvailabilityOptions?.canScheduleOverFreeTime ?? false,
                    shouldRespectLoadLimit: settings?.candidateAvailabilityOptions?.shouldRespectLoadLimit ?? true,
                    candidateNote: settings?.candidateAvailabilityOptions?.candidateNote ?? '',
                  };

                  handleOptionsChange(changeOptions);
                }
              }
              setCurrentPage(CandidateAvailabilityOptionsModalPage.BASIC_CONFIGURATION);
            }}
            onJobStageIdChange={(newJobStageId) => {
              onJobStageIdChange?.(newJobStageId);

              if (interviewPlanEntityInTaskEnabled) {
                fetchInterviewPlan({ variables: { jobStageId: newJobStageId } });
              }
            }}
            onInterviewPlanChange={(newInterviewPlan) => {
              setInterviewPlan(newInterviewPlan);

              const interviewPlanInput = getSaveInterviewPlanInput(
                { interviewPlan: newInterviewPlan },
                { finalJobStageId: newInterviewPlan.id }
              );

              if (orgBreakImprovementsEnabled) {
                setInput((prev) => ({
                  ...prev,
                  customJobStageId: newInterviewPlan.id,
                  interviewPlan: {
                    groups: interviewPlanInput.groups,
                    schedulingWindow: interviewPlanInput.schedulingWindow
                      ? { seconds: interviewPlanInput.schedulingWindow.seconds }
                      : undefined,
                    excludedEmployeeIds: interviewPlanInput.excludedEmployeeIds || [],
                  },
                }));
              } else {
                setInput((prev) => ({
                  ...prev,
                  customJobStageId: newInterviewPlan.id,
                  customInterviewPlan: interviewPlanInput.groups,
                }));
              }
            }}
          />
        )}
      {currentPage === CandidateAvailabilityOptionsModalPage.BASIC_CONFIGURATION && options && (
        <Options
          applicationId={applicationId}
          taskId={taskId}
          taskInput={input}
          timezone={timezone}
          options={options}
          interviewPlan={interviewPlan}
          onOptionsChanged={handleOptionsChange}
          onBack={onOptionsBack}
          onContinue={() => {
            setCurrentPage(CandidateAvailabilityOptionsModalPage.SHARE_AVAILABILITY);
          }}
          onClose={onClose}
        />
      )}
      {currentPage === CandidateAvailabilityOptionsModalPage.SHARE_AVAILABILITY && options && (
        <ShareOptions
          applicationId={applicationId}
          candidateId={candidateId}
          jobStageId={jobStageId}
          defaultTemplateId={settings?.availabilityRequestEmailTemplateId}
          defaultCcEmployeeIds={defaultCcEmployeeIds}
          options={options}
          taskId={taskId}
          jobId={jobId}
          taskCreatorId={modalData?.thisEmployee?.id}
          taskInput={input}
          onOptionsChanged={handleOptionsChange}
          onSaveSuccess={onSaveSuccess}
          onEditOptions={
            disableEditOptions || shareViaShareDialog
              ? undefined
              : () => setCurrentPage(CandidateAvailabilityOptionsModalPage.BASIC_CONFIGURATION)
          }
          onClose={(emailSent: boolean, createdTaskId: string) => {
            if (emailSent === undefined || emailSent === true) {
              onClose();
            } else if (shareContext?.onTaskShare) {
              shareContext.onTaskShare(CreateType.CANDIDATE_AVAILABILITY, createdTaskId);
              onClose();
            } else {
              setCurrentPage(CandidateAvailabilityOptionsModalPage.SAVE_SUCCESS);
            }
          }}
          ccRecipients={settings?.candidateAvailabilityOptions?.ccRecipients}
          bccRecipients={settings?.candidateAvailabilityOptions?.bccRecipients}
        />
      )}
      {options?.id && currentPage === CandidateAvailabilityOptionsModalPage.DELETE_AVAILABILITY_CONFIRMATION && (
        <DeleteCandidateAvailabilityConfirmationDialog
          candidateAvailabilityOptionsId={options.id}
          onClose={onClose}
          onDelete={onDelete}
        />
      )}
    </>
  );

  return (
    <ChildPropsDialog isOpen size="md" onClose={onClose}>
      {jsx}
    </ChildPropsDialog>
  );
};
