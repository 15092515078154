import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import BaseIcon, { Props } from '../../Icon';

const TotalRsvpAllGoing = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62469 18.7002C2.96993 19.2241 2 18.7579 2 17.9194V5C2 3.34315 3.34315 2 5 2H15C16.6569 2 18 3.34315 18 5V13C18 14.6569 16.6569 16 15 16H8.05234C7.37113 16 6.7102 16.2318 6.17826 16.6574L3.62469 18.7002ZM15.7483 6.43945C16.0579 6.71464 16.0857 7.18869 15.8106 7.49828L11.8106 11.9983C11.5354 12.3079 11.0613 12.3358 10.7517 12.0606C10.4421 11.7854 10.4143 11.3113 10.6894 11.0017L14.6894 6.50174C14.9646 6.19215 15.4387 6.16426 15.7483 6.43945ZM11.7483 6.43946C12.0579 6.71465 12.0857 7.1887 11.8106 7.49829L7.81056 11.9983C7.67333 12.1527 7.4785 12.2436 7.27203 12.2497C7.06556 12.2558 6.86573 12.1764 6.71967 12.0303L4.21967 9.53035C3.92678 9.23745 3.92678 8.76258 4.21967 8.46969C4.51256 8.17679 4.98744 8.17679 5.28033 8.46969L7.21789 10.4072L10.6894 6.50174C10.9646 6.19216 11.4387 6.16427 11.7483 6.43946Z"
        fill="#00A39E"
      />
    </BaseIcon>
  );
};

export default TotalRsvpAllGoing;
