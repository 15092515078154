import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';
import { EyeCrossedIcon } from '@modernloop/shared/icons';
import { Stack, Theme, Typography } from '@mui/material';

import { DateTimeRangeInput } from 'src/generated/mloop-graphql';

import { CanDisable } from 'src/components/HelperComponents';
import Paper from 'src/components/Paper';

import { getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';
import { getJobStageInterviewGroupById } from 'src/store/selectors/job-stage-interview-group';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { isBreak } from 'src/utils/interview';

import { useSelector } from 'src/store';

import BreakSlot from './BreakSlot';
import InterviewCardHeader from './InterviewCardHeader';
import InterviewSeatsModule from './InterviewSeatsModule';

interface Props {
  jobStageInterviewId: string;
  groupId: string;
  index: number;
  schedulable: boolean;
  readonly?: boolean;
  candidateAvailabilities?: DateTimeRangeInput[];
  onUpdated: () => void;
}

const JobStageInterview = ({
  groupId,
  index,
  jobStageInterviewId,
  readonly,
  schedulable,
  candidateAvailabilities,
  onUpdated,
}: Props): JSX.Element => {
  const jobStageInterview = useSelector((state) => getJobStageInterviewById(state, jobStageInterviewId));
  const { jobStageInterviewIds } = useSelector((state) =>
    getJobStageInterviewGroupById(state, jobStageInterview.jobStageGroupId)
  );

  if (isBreak(jobStageInterview.type)) {
    return (
      <Grid item>
        <BreakSlot jobStageInterviewId={jobStageInterviewId} index={index} readonly={readonly} onUpdated={onUpdated} />
      </Grid>
    );
  }

  return (
    <Grid item>
      <Paper size={jobStageInterviewIds.length > 1 ? 'medium' : 'large'} sx={{ overflow: 'hidden' }}>
        <CanDisable disabled={Boolean(readonly)}>
          <Grid container direction="column" spacing={2} wrap="nowrap">
            {jobStageInterview.isHiddenFromCandidate && (
              <ConditionalThemeProvider>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    backgroundColor: (theme: Theme) => theme.palette.background.paperHeader,
                    borderBottom: (theme: Theme) => `1px solid ${theme.palette.borderState.default}`,
                    padding: 1,
                    margin: -0.5,
                  }}
                >
                  <EyeCrossedIcon />
                  <Typography variant="subtitle2" color="text.secondary">
                    Hidden from candidate
                  </Typography>
                </Stack>
              </ConditionalThemeProvider>
            )}
            <Grid item>
              <InterviewCardHeader
                jobStageInterviewId={jobStageInterviewId}
                groupId={groupId}
                index={index}
                schedulable={schedulable}
                candidateAvailabilities={candidateAvailabilities}
                onUpdated={onUpdated}
              />
            </Grid>
            <Grid item>
              <InterviewSeatsModule
                jobStageInterviewId={jobStageInterviewId}
                readonly={readonly}
                onUpdated={onUpdated}
              />
            </Grid>
          </Grid>
        </CanDisable>
      </Paper>
    </Grid>
  );
};

export default JobStageInterview;
