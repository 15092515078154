import React from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';

import {
  DynamicAudience,
  UseJobSmartOptions_JobCoordinatorDataFragment,
  UseJobSmartOptions_JobRecruiterDataFragment,
  UseJobSmartOptions_JobSourcerDataFragment,
} from 'src/generated/mloop-graphql';

import { OptionRenderer } from './OptionRenderer';

type Fragments = {
  jobCoordinatorData: UseJobSmartOptions_JobCoordinatorDataFragment[];
  jobRecruiterData: UseJobSmartOptions_JobRecruiterDataFragment[];
  jobSourcerData: UseJobSmartOptions_JobSourcerDataFragment[];
};

type HookReturn = {
  options: DynamicAudience[];
  renderer: (props: { optionType: DynamicAudience; isDisabled: boolean; isChecked: boolean }) => JSX.Element | null;
  getData: (
    optionType: DynamicAudience
  ) =>
    | UseJobSmartOptions_JobCoordinatorDataFragment[]
    | UseJobSmartOptions_JobRecruiterDataFragment[]
    | UseJobSmartOptions_JobSourcerDataFragment[]
    | undefined
    | null;
};

export const useJobSmartOptions: FunctionWithFragments<Fragments, null, HookReturn> = ({
  jobCoordinatorData,
  jobRecruiterData,
  jobSourcerData,
}) => {
  const returnOptionData = (optionType: DynamicAudience) => {
    switch (optionType) {
      case DynamicAudience.JobCoordinators:
        return jobCoordinatorData;
      case DynamicAudience.JobSourcers:
        return jobSourcerData;
      case DynamicAudience.JobRecruiters:
        return jobRecruiterData;
      default:
        return null;
    }
  };

  return {
    options: [DynamicAudience.JobCoordinators, DynamicAudience.JobRecruiters, DynamicAudience.JobSourcers],
    renderer: ({
      optionType,
      isDisabled,
      isChecked,
    }: {
      optionType: DynamicAudience;
      isDisabled: boolean;
      isChecked: boolean;
    }) => {
      const disabledForContext = isDisabled;
      switch (optionType) {
        case DynamicAudience.JobCoordinators:
          return (
            <OptionRenderer
              type={DynamicAudience.JobCoordinators}
              employees={returnOptionData(DynamicAudience.JobCoordinators)}
              isDisabled={disabledForContext}
              isChecked={isChecked}
            />
          );
        case DynamicAudience.JobRecruiters:
          return (
            <OptionRenderer
              type={DynamicAudience.JobRecruiters}
              employees={returnOptionData(DynamicAudience.JobRecruiters)}
              isDisabled={disabledForContext}
              isChecked={isChecked}
            />
          );
        case DynamicAudience.JobSourcers:
          return (
            <OptionRenderer
              type={DynamicAudience.JobSourcers}
              employees={returnOptionData(DynamicAudience.JobSourcers)}
              isDisabled={disabledForContext}
              isChecked={isChecked}
            />
          );

        default:
          return null;
      }
    },
    getData: (optionType: DynamicAudience) => {
      switch (optionType) {
        case DynamicAudience.JobCoordinators:
          return returnOptionData(DynamicAudience.JobCoordinators);
        case DynamicAudience.JobRecruiters:
          return returnOptionData(DynamicAudience.JobRecruiters);
        case DynamicAudience.JobSourcers:
          return returnOptionData(DynamicAudience.JobSourcers);
        default:
          return [];
      }
    },
  };
};

useJobSmartOptions.fragments = {
  jobCoordinatorData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useJobSmartOptions_jobCoordinatorData on Employee {
      ...OptionRenderer_employees
    }
  `,
  jobRecruiterData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useJobSmartOptions_jobRecruiterData on Employee {
      ...OptionRenderer_employees
    }
  `,
  jobSourcerData: gql`
    ${OptionRenderer.fragments.employees}
    fragment useJobSmartOptions_jobSourcerData on Employee {
      ...OptionRenderer_employees
    }
  `,
};
