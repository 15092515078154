import React from 'react';

import { gql } from '@apollo/client';
import { Dialog } from '@modernloop/shared/components';
import { Stack, Typography } from '@mui/material';

import { SetModuleMemberAsTrainee_ModuleMemberFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import AddToTrainingPlan from './AddToTrainingPlan';

type Props = {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
};

type Fragments = {
  moduleMember: SetModuleMemberAsTrainee_ModuleMemberFragment;
};

const SetModuleMemberAsTrainee: FCWithFragments<Fragments, Props> = ({ moduleMember, open, onClose, onUpdate }) => {
  if (moduleMember.stats?.upcoming) {
    return (
      <Dialog
        submitOptions={{
          label: 'Okay',
          onClick: onClose,
        }}
        title="Remove from upcoming interviews"
        subTitle="Cannot set to trainee until interviews are updated"
        open={open}
        onClose={onClose}
      >
        <Stack direction="column" spacing={1}>
          <Typography>
            This interviewer is scheduled as the trained interviewer on one or more already scheduled interviews. You’ll
            need to replace them on those interviews before they can be set back to a trainee.
          </Typography>
        </Stack>
      </Dialog>
    );
  }

  return <AddToTrainingPlan moduleMember={moduleMember} open={open} onClose={onClose} onUpdate={onUpdate} />;
};

SetModuleMemberAsTrainee.fragments = {
  moduleMember: gql`
    ${AddToTrainingPlan.fragments.moduleMember}
    fragment SetModuleMemberAsTrainee_moduleMember on InterviewModuleMember {
      id
      employeeId
      interviewModuleId
      stats {
        upcoming
      }
      ...AddToTrainingPlan_moduleMember
    }
  `,
};

export default SetModuleMemberAsTrainee;
