import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { assertIsoDate, convertToIsoDate, formatDate, getLocalTimezone } from '@modernloop/shared/datetime';
import { DateIcon } from '@modernloop/shared/icons';
import { Timeline, timelineItemClasses } from '@mui/lab';
import { Box, Typography } from '@mui/material';

import { DebriefContentEntity_DebriefFragment } from 'src/generated/mloop-graphql';

import TimelineItemWrapper from '../common/TimelineItemWrapper';

import DebriefContentActions from './DebriefContentActions';
import DebriefContentMeeting from './DebriefContentMeeting';

type Props = {
  hideActions?: boolean;
  highlightAction?: boolean;
};

type Fragments = {
  debrief: DebriefContentEntity_DebriefFragment;
};

const DebriefContent: FCWithFragments<Fragments, Props> = ({ debrief, hideActions = false }) => {
  const timezone = getLocalTimezone();

  return (
    <Box>
      {!hideActions && <DebriefContentActions debrief={debrief} />}
      <Timeline
        position="right"
        sx={{
          backgroundColor: (theme) => theme.palette.background.contrast,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineItemWrapper icon={<DateIcon color="action" />} key={debrief.id}>
          <Typography variant="subtitle2">
            {formatDate(assertIsoDate(convertToIsoDate(debrief.startAt)), timezone, 'MMMM d, yyyy')}
          </Typography>
        </TimelineItemWrapper>
        <TimelineItemWrapper icon={undefined} key={debrief.id}>
          <DebriefContentMeeting debrief={debrief} timezone={timezone} />
        </TimelineItemWrapper>
        <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 1 }}>
          Created {formatDate(debrief.createdAt, timezone, 'MMM d, yyyy, h:mm aaa')} by {debrief.creator?.fullName}
        </Typography>
      </Timeline>
    </Box>
  );
};

DebriefContent.fragments = {
  debrief: gql`
    ${DebriefContentActions.fragments.debrief}
    ${DebriefContentMeeting.fragments.debrief}
    fragment DebriefContentEntity_debrief on ApplicationDebrief {
      id
      ...DebriefContentActions_debrief
      ...DebriefContentMeeting_debrief
      startAt
      endAt
      creator {
        fullName
      }
      createdAt
    }
  `,
};

export default DebriefContent;
