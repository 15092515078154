import { SvgIcon, SvgIconProps } from './SvgIcon';

export const WorldWithClockIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45449 13.6265C6.48698 13.7023 6.52054 13.7771 6.55519 13.8506C6.5188 13.5722 6.50003 13.2883 6.50003 13C6.50003 11.9658 6.74155 10.9881 7.17126 10.12C7.14104 9.75961 7.125 9.38517 7.125 9C7.125 8.56832 7.14515 8.1501 7.18283 7.75H9.16685C10.2415 6.964 11.5666 6.5 13 6.5C13.9791 6.5 14.9075 6.71645 15.7402 7.10411C14.9136 4.15926 12.209 2 9 2C5.13401 2 2 5.13401 2 9C2 12.209 4.15929 14.9136 7.10416 15.7402C6.84204 15.1772 6.65819 14.5704 6.5666 13.9338C5.59081 13.4516 4.77865 12.6884 4.2358 11.75H5.8877C6.0271 12.4422 6.21869 13.0763 6.45449 13.6265ZM3.64267 10.25H5.67692C5.64271 9.8436 5.625 9.42562 5.625 9C5.625 8.57438 5.64271 8.1564 5.67692 7.75H3.64267C3.54934 8.15157 3.5 8.57002 3.5 9C3.5 9.42998 3.54934 9.84843 3.64267 10.25ZM7.8332 4.96441C7.67365 5.3367 7.53431 5.76949 7.42241 6.25H10.5776C10.4657 5.76949 10.3264 5.3367 10.1668 4.96441C9.9531 4.46579 9.71791 4.11019 9.49494 3.89014C9.2752 3.67328 9.10881 3.625 9 3.625C8.89119 3.625 8.7248 3.67328 8.50506 3.89014C8.28209 4.11019 8.0469 4.46579 7.8332 4.96441ZM12.1123 6.25H13.7642C13.2144 5.2996 12.3884 4.52898 11.396 4.04791C11.4481 4.15389 11.498 4.26258 11.5455 4.37353C11.7813 4.92372 11.9729 5.55778 12.1123 6.25ZM4.2358 6.25H5.8877C6.0271 5.55778 6.21869 4.92372 6.45449 4.37353C6.50204 4.26258 6.55187 4.15389 6.60403 4.04791C5.61158 4.52898 4.78558 5.2996 4.2358 6.25ZM17.9999 13C17.9999 15.7614 15.7613 18 12.9999 18C10.2385 18 7.99991 15.7614 7.99991 13C7.99991 10.2386 10.2385 8 12.9999 8C15.7613 8 17.9999 10.2386 17.9999 13ZM12.9999 9.75C13.4141 9.75 13.7499 10.0858 13.7499 10.5V12.6893L15.0302 13.9697C15.3231 14.2626 15.3231 14.7374 15.0302 15.0303C14.7373 15.3232 14.2625 15.3232 13.9696 15.0303L12.4696 13.5303C12.3289 13.3897 12.2499 13.1989 12.2499 13V10.5C12.2499 10.0858 12.5857 9.75 12.9999 9.75Z"
        fillOpacity="0.6"
      />
    </SvgIcon>
  );
};
