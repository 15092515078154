import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';

import { candidateAvailabilityOptionsFragment } from '../JobDetailsPage/JobStageDetails/JobStageCommunicationSettings';
import SelfScheduleRequestedAlert from '../SelfSchedule/SelfScheduleRequestedAlert';

import {
  CandidateAvailabilityOptionsModal,
  CandidateAvailabilityOptionsModalFragmentProps,
  CandidateAvailabilityOptionsModalProps,
} from './CandidateAvailabilityOptionsModal';

const CandidateAvailabilityOptionsModalWrapper: FCWithFragments<
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/restric-fragments-name.cjs
  CandidateAvailabilityOptionsModalFragmentProps,
  CandidateAvailabilityOptionsModalProps & {
    isSelfScheduleRequested?: boolean;
  }
> = (props) => {
  const { isSelfScheduleRequested, onClose } = props;

  if (isSelfScheduleRequested) {
    return <SelfScheduleRequestedAlert onClose={onClose} />;
  }

  return <CandidateAvailabilityOptionsModal {...props} />;
};

CandidateAvailabilityOptionsModalWrapper.fragments = {
  settings: gql`
    ${candidateAvailabilityOptionsFragment}
    fragment CandidateAvailabilityOptionsSettings_JobStageSettings on JobStageSettings {
      ...CandidateAvailabilityOptions_jobStageSettings
      availabilityRequestEmailTemplateId
    }
  `,
};

export default CandidateAvailabilityOptionsModalWrapper;
