import React, { FC, useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Stack from '../Stack';
import Button from '../button';
import { TriangleDownIcon } from '../icons';
import Label from '../label';
import Menu, { MenuOption } from '../menu';
import { BaseProps } from '../types';
import { IconType, getDisabledIcon, getIconFromEnum } from '../utils/icons';

export type { MenuOption } from '../menu';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type SelectProps = BaseProps & {
  className?: string;
  fullWidth?: boolean;
  placeholder?: string;
  title?: string | null;
  options?: MenuOption[];
  startIcon?: IconType | React.ReactNode;
  size?: 'small' | 'medium';
  disabled?: boolean;
  onSelect: (option: MenuOption) => void;
  selectedOption?: MenuOption;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectButton: (props: SelectProps) => {
      let height = '32px';
      if (props.title && props.size === 'small') {
        height = '60px';
      } else if (props.title) {
        height = '64px';
      } else if (props.size === 'small') {
        height = '28px';
      }
      return {
        border: props.disabled
          ? `1px solid ${theme.palette.common.transparent} !important`
          : `1px solid ${theme.palette.border}`,
        borderRadius: '6px',
        height,
        maxWidth: '100%',
        backgroundColor: props.disabled ? theme.grey.solid.min : theme.palette.background.default,
      };
    },
    title: (props: SelectProps) => {
      return {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginBottom: props.size === 'small' ? '6px' : '8px',
      };
    },
  })
);

/**
 * @deprecated - Use `Select` from `@mui/material` instead
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const Select: FC<SelectProps> = (props) => {
  const {
    dataTestId,
    title,
    startIcon,
    disabled,
    size,
    placeholder,
    className,
    fullWidth,
    onSelect,
    options,
    selectedOption,
  } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const selectRef = useRef<HTMLButtonElement>(null);

  const classes = useStyles(props);

  function handleSelect(option) {
    setIsMenuOpen(false);
    onSelect(option);
  }

  return (
    <>
      <Button
        ref={selectRef}
        disabled={disabled}
        dataTestId={dataTestId}
        className={clsx(classes.selectButton, className)}
        fullWidth={fullWidth}
        size="small"
        variant="outlined"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <Grid direction="column" alignItems="flex-start" container>
          {title && (
            <Grid item zeroMinWidth xs wrap="nowrap">
              <Label
                className={classes.title}
                variant="captions"
                fontWeight={500}
                // eslint-disable-next-line no-nested-ternary
                color={isFocused ? 'info' : disabled ? 'mid-contrast-grey' : 'inherit'}
              >
                {title}
              </Label>
            </Grid>
          )}
          <Stack itemStyles={{ 1: { flexShrink: 0 } }} justifyContent="space-between" wrap="nowrap">
            <Stack alignItems="center" spacing={1} wrap="nowrap" itemStyles={startIcon ? { 0: { flexShrink: 0 } } : {}}>
              {startIcon && (
                <div style={{ marginLeft: '-4px' }}>
                  {React.isValidElement(startIcon) && startIcon}
                  {typeof startIcon === 'string' && (
                    <>{disabled ? getDisabledIcon(startIcon as IconType) : getIconFromEnum(startIcon as IconType)}</>
                  )}
                </div>
              )}
              <Label
                style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                variant={size === 'small' ? 'captions' : 'body'}
                // eslint-disable-next-line no-nested-ternary
                color={disabled ? 'mid-contrast-grey' : selectedOption?.value ? 'foreground' : 'high-contrast-grey'}
              >
                {selectedOption?.value || placeholder}
              </Label>
            </Stack>
            <div style={{ marginRight: '-4px', color: disabled ? 'mid-contrast-grey' : 'inherit' }}>
              <TriangleDownIcon color={disabled ? 'mid-contrast-grey' : 'inherit'} />
            </div>
          </Stack>
        </Grid>
      </Button>
      <Menu
        menuWidth={selectRef?.current?.offsetWidth}
        open={isMenuOpen}
        options={(options ?? []).map((option) => {
          if (option.id === selectedOption?.id) {
            return { ...option, selected: true };
          }
          return option;
        })}
        id="menu-option"
        anchorEl={selectRef.current}
        onSelect={(option) => handleSelect(option)}
        onClose={() => setIsMenuOpen(false)}
      />
    </>
  );
};

export default Select;
