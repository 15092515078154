import { Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

// If the operation is a mutation, we don't want to retry it.
const getIsRetryEnabled = (operation: Operation) => {
  const definition = getMainDefinition(operation.query);
  const isMutation = definition.kind === 'OperationDefinition' && definition.operation === 'mutation';
  return !isMutation;
};

export default getIsRetryEnabled;
