import TurndownService from 'turndown';

const turndown = new TurndownService({
  emDelimiter: '_',
  bulletListMarker: '•',
});

turndown.remove('img');
turndown.remove('picture');

// Slack doesnt respect Markdown Escapes. Let's turn them off.
TurndownService.prototype.escape = (text: string) => {
  return text;
};

turndown.addRule('div', {
  filter: 'div',

  replacement(content) {
    return `${content}\n`;
  },
});

const STAR = '*';
turndown.addRule('strong', {
  filter: ['strong', 'b'],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, max-params
  replacement(content, _node, options) {
    if (!content.trim()) return '';
    return STAR + content.replace(/~/gm, '') + STAR;
  },
});

const TILDE = '~';
turndown.addRule('strikethrough', {
  filter: ['del', 's', 'strike'],
  replacement(content) {
    if (!content.trim()) return '';
    return TILDE + content.replace(/\*/gm, '') + TILDE;
  },
});

turndown.addRule('link', {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filter(node, _options) {
    return node.nodeName === 'A' && node.getAttribute('href');
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, max-params
  replacement(content, node, _options) {
    const href = node.getAttribute('href');
    return content ? `<${href}|${content}>` : `<${href}>`;
  },
});

const htmlToMrkdwn = (content: string): string => {
  if (!content) {
    return '';
  }

  return turndown.turndown(content.replace(/<div><br><\/div>/g, '<div></div>'));
};

export default htmlToMrkdwn;
