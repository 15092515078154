import { FilterSelect, MaybeTooltip } from '..';
import { TimezoneOption, timezoneStrings as timezoneOptions } from '@modernloop/shared/datetime';
import { useFlag } from '@modernloop/shared/feature-flag';
import { WorldWithClockIcon } from '@modernloop/shared/icons';
import { createFilterOptions, ListItem, ListItemButton, Stack, TextField, Tooltip } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface Props {
  onTimezoneChange: (timezone: string) => void;
  value: string;
  fullWidth?: boolean;
  disableClearable?: boolean;
}

const nullOption = {
  label: 'Set a timezone',
  value: '',
  keywords: '',
  category: '',
};

export const TimezoneSelect: FC<Props> = ({ onTimezoneChange, value, fullWidth = true, disableClearable }) => {
  const useFilterSelect = useFlag('user_timezone_filter_select');
  const [displayValue, setDisplayValue] = useState<TimezoneOption>(nullOption);
  const getOptionLabel = (option: TimezoneOption) => option.label;

  useEffect(() => {
    const timezone = timezoneOptions.find((option) => option.value === value);
    if (timezone) {
      setDisplayValue(timezone);
    } else {
      setDisplayValue(nullOption);
    }
  }, [value]);

  const filterOptions = createFilterOptions({
    stringify: (tz: TimezoneOption) => `${tz.label},${tz.value},${tz.keywords}`,
  });

  if (useFilterSelect) {
    return (
      <FilterSelect
        single
        getLabel={() => (
          <Stack direction="row">
            <WorldWithClockIcon />
            <MaybeTooltip title={displayValue.label} label={displayValue.label} />
          </Stack>
        )}
        renderOption={(props, option) => (
          <ListItem {...props}>
            <ListItemButton>
              <MaybeTooltip label={getOptionLabel?.(option)} title={getOptionLabel?.(option)} />
            </ListItemButton>
          </ListItem>
        )}
        getOptionLabel={getOptionLabel}
        options={timezoneOptions}
        onChange={(_event, value) => {
          onTimezoneChange(value[0]?.value || '');
        }}
      />
    );
  }

  return (
    <Autocomplete
      disableClearable={disableClearable}
      value={displayValue}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <Tooltip title={value}>
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: <WorldWithClockIcon />,
            }}
            value={value}
          />
        </Tooltip>
      )}
      options={timezoneOptions}
      onChange={(_event, value) => {
        onTimezoneChange(value?.value || '');
      }}
      // eslint-disable-next-line no-restricted-syntax
      sx={{ width: fullWidth ? '100%' : '300px' }}
    />
  );
};
