import React from 'react';

import BaseIcon, { Props } from './Icon';

// Flag icon used for Tasks
const Flag = ({ color, fontSize, tooltip }: Props) => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5899 4.25864C13.7024 4.57619 12.4643 3.96733 11.2285 3.34895L11.1826 3.32595C10.5905 3.02951 9.93478 2.70126 9.23535 2.55802C8.47471 2.40225 7.67816 2.46437 6.80803 2.89859C6.60314 3.00084 6.45618 3.19109 6.409 3.41517L4.98043 10.2009L3.55186 16.9866C3.46653 17.3919 3.72594 17.7897 4.13127 17.875C4.53659 17.9603 4.93435 17.7009 5.01969 17.2956L6.35766 10.9402C7.29125 10.7153 8.05355 11.0158 9.16862 11.4942L9.22301 11.5176C10.4755 12.0552 12.041 12.7272 14.3492 12.5313C14.679 12.5033 14.9515 12.2624 15.0197 11.9385L16.4483 5.15275C16.4994 4.91005 16.4272 4.65777 16.2554 4.47885C16.0836 4.29992 15.8345 4.21749 15.5899 4.25864Z"
        fill="#D7241D"
      />
    </BaseIcon>
  );
};

export default Flag;
