import React from 'react';

import BaseIcon, { Props } from './Icon';

const CaretRightIcon = ({ color, fontSize, tooltip, className, style }: Props): JSX.Element => {
  return (
    <BaseIcon style={style} color={color} fontSize={fontSize} tooltip={tooltip} className={className}>
      <path
        d="M8.5 15L12.5 10L8.5 5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default CaretRightIcon;
