import { SvgIcon, SvgIconProps } from '@mui/material';

export const BeverageCoffeeIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;

  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.18969 2.56928C7.50405 2.46449 7.67395 2.12469 7.56916 1.81033C7.46437 1.49596 7.12458 1.32607 6.81021 1.43086L6.12191 1.66029C5.57133 1.84382 5.19995 2.35907 5.19995 2.93944C5.19995 3.61944 5.7063 4.19303 6.38105 4.27737L8.18113 4.50238C8.30614 4.51801 8.39995 4.62428 8.39995 4.75026C8.39995 4.88822 8.28811 5.00007 8.15015 5.00007H4.99995C4.66858 5.00007 4.39995 5.26869 4.39995 5.60007C4.39995 5.93144 4.66858 6.20007 4.99995 6.20007H8.15015C8.95085 6.20007 9.59995 5.55096 9.59995 4.75026C9.59995 4.01909 9.0555 3.40234 8.32997 3.31165L6.52989 3.08664C6.45566 3.07736 6.39995 3.01425 6.39995 2.93944C6.39995 2.87559 6.44081 2.8189 6.50138 2.79871L7.18969 2.56928ZM10.7999 7.00007H2.79996C2.46859 7.00007 2.19996 7.26869 2.19996 7.60006L2.19995 9.60006L2.79995 9.60006L2.19995 9.60006C2.19996 12.1406 4.25944 14.2001 6.79994 14.2001C8.85229 14.2001 10.5907 12.856 11.183 11.0001H11.7999C12.9045 11.0001 13.7999 10.1046 13.7999 9.00007C13.7999 7.8955 12.9045 7.00007 11.7999 7.00007H10.7999ZM3.39996 8.20007L3.39995 9.00006H5.99995C6.33132 9.00006 6.59995 9.26869 6.59995 9.60006C6.59995 9.93143 6.33132 10.2001 5.99995 10.2001H3.45273C3.73612 11.7917 5.12691 13.0001 6.79994 13.0001C8.67771 13.0001 10.1999 11.4778 10.1999 9.60005V8.20007L3.39996 8.20007ZM11.3999 9.60005V8.20007H11.7999C12.2418 8.20007 12.5999 8.55824 12.5999 9.00007C12.5999 9.44189 12.2418 9.80007 11.7999 9.80007H11.3957C11.3985 9.73375 11.3999 9.66707 11.3999 9.60005ZM7.99995 9.00006C7.66857 9.00006 7.39995 9.26869 7.39995 9.60006C7.39995 9.93143 7.66857 10.2001 7.99995 10.2001H8.79994C9.13132 10.2001 9.39995 9.93143 9.39995 9.60006C9.39995 9.26869 9.13132 9.00006 8.79994 9.00006H7.99995Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
