/* eslint-disable import/prefer-default-export */
import { v4 as uuid } from 'uuid';

import { JobStageInterviewSeatType, TrainingStatus } from 'src/generated/mloop-graphql';

import { updateJobStageInterview } from 'src/store/actions/job-stage-interview';
import { getJobStageById } from 'src/store/selectors/job-stage';
import { getAllJobStageInterviewByJobStageId, getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';
import { getJobStageInterviewGroupById } from 'src/store/selectors/job-stage-interview-group';
import {
  getJobStageInterviewSeatById,
  getNormalizedJobStageInterviewSeatsById,
} from 'src/store/selectors/job-stage-interview-seat';
import jobStageInterviewSeatSlice, {
  JobStageInterviewSeat,
  UpdateJobStageInterviewSeatPayload,
} from 'src/store/slices/job-stage-interview-seat';

import { AppThunk } from 'src/store';

export const createJobStageInterviewSeat =
  (jobStageInterviewId: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const jobStageInterview = getJobStageInterviewById(state, jobStageInterviewId);

    const jobStageInterviewSeat: JobStageInterviewSeat = {
      id: uuid(),
      jobStageInterviewId,
      index: jobStageInterview.seatIds.length,
      type: JobStageInterviewSeatType.Freeform,
      employeeIds: [],
    };

    dispatch(jobStageInterviewSeatSlice.actions.addJobStageInterviewSeat(jobStageInterviewSeat));

    dispatch(
      updateJobStageInterview({
        id: jobStageInterviewId,
        seatIds: [...jobStageInterview.seatIds, jobStageInterviewSeat.id],
      })
    );
  };

export const addJobStageInterviewSeat =
  (jobStageInterviewSeat: JobStageInterviewSeat): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageInterviewSeatSlice.actions.addJobStageInterviewSeat(jobStageInterviewSeat));
  };

export const updateJobStageInterviewSeat =
  (jobStageInterviewSeat: UpdateJobStageInterviewSeatPayload): AppThunk =>
  async (dispatch, getState) => {
    // Update any linked seat
    const state = getState();

    if (!jobStageInterviewSeat.jobStageInterviewId) return;

    const jobStageInterview = getJobStageInterviewById(state, jobStageInterviewSeat.jobStageInterviewId);

    if (!jobStageInterview) return;

    const allJobStageInterview = getAllJobStageInterviewByJobStageId(state, jobStageInterview.jobStageId);

    allJobStageInterview.forEach((interview) => {
      if (interview.id === jobStageInterviewSeat.jobStageInterviewId) return;

      interview.seatIds.forEach((seatId) => {
        const seat = getJobStageInterviewSeatById(state, seatId);

        if (
          seat.type === JobStageInterviewSeatType.Linked &&
          seat.interviewSeatId === jobStageInterviewSeat.id &&
          jobStageInterviewSeat.type === JobStageInterviewSeatType.Linked
        ) {
          dispatch(
            jobStageInterviewSeatSlice.actions.updateJobStageInterviewSeat({
              ...seat,
              interviewSeatId: jobStageInterviewSeat.interviewSeatId,
            })
          );
        }
      });
    });

    // Update the seat that user updated.
    dispatch(jobStageInterviewSeatSlice.actions.updateJobStageInterviewSeat(jobStageInterviewSeat));
  };

export const deleteJobStageInterviewSeat =
  (jobStageInterviewId: string, jobStageInterviewSeatId: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const jobStageInterview = getJobStageInterviewById(state, jobStageInterviewId);

    const jobStage = getJobStageById(state, jobStageInterview.jobStageId);
    const seatIdsToDelete: string[] = [];

    jobStage.jobStageInterviewGroupIds.forEach((groupId) => {
      const group = getJobStageInterviewGroupById(state, groupId);

      group.jobStageInterviewIds.forEach((interviewId) => {
        if (interviewId === jobStageInterviewSeatId) return;

        const interview = getJobStageInterviewById(state, interviewId);
        const seatIdsToDeleteFromInterview: string[] = [];

        interview.seatIds.forEach((seatId) => {
          const seat = getJobStageInterviewSeatById(state, seatId);
          if (seat.type === JobStageInterviewSeatType.Linked && seat.interviewSeatId === jobStageInterviewSeatId) {
            seatIdsToDelete.push(seatId);
            seatIdsToDeleteFromInterview.push(seatId);
          }
        });

        const newInterviewSeatIds = interview.seatIds.filter((id) => seatIdsToDeleteFromInterview.indexOf(id) === -1);
        dispatch(updateJobStageInterview({ id: interviewId, seatIds: newInterviewSeatIds }));
      });
    });

    // Remove seat from JobStageInterview
    const seatIds = jobStageInterview.seatIds.filter((id) => id !== jobStageInterviewSeatId);
    dispatch(updateJobStageInterview({ id: jobStageInterview.id, seatIds }));

    seatIdsToDelete.forEach((id) => dispatch(jobStageInterviewSeatSlice.actions.deleteJobStageInterviewSeat(id)));
    dispatch(jobStageInterviewSeatSlice.actions.deleteJobStageInterviewSeat(jobStageInterviewSeatId));
  };

export const updateJobStageInterviewSeatAttributes =
  (
    jobStageInterviewSeatId: string,
    attributeNameId: string,
    attributeValueId: string,
    selected: boolean
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      jobStageInterviewSeatSlice.actions.updateJobStageInterviewSeatAttributes({
        jobStageInterviewSeatId,
        attributeNameId,
        attributeValueId,
        selected,
      })
    );
  };

export const updateJobStageInterviewSeatPeople =
  (
    jobStageInterviewSeatId: string,
    employeeId: string,
    status: TrainingStatus,
    selected: boolean
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      jobStageInterviewSeatSlice.actions.updateJobStageInterviewSeatPeople({
        jobStageInterviewSeatId,
        employeeId,
        status,
        selected,
      })
    );
  };

export const updateJobStageInterviewSeatModuleMembers =
  (jobStageInterviewSeatId: string, moduleMembers: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      jobStageInterviewSeatSlice.actions.updateJobStageInterviewSeatModuleMembers({
        jobStageInterviewSeatId,
        moduleMembers,
      })
    );
  };

export const clearJobStageInterviewSeatFilters =
  (jobStageInterviewSeatId: string): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageInterviewSeatSlice.actions.clearJobStageInterviewSeatFilters({ jobStageInterviewSeatId }));
  };

export const clearAnyLinkedJobStageInterviewSeats =
  (jobStageInterviewSeatId: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const jobStageInterviewSeat = getJobStageInterviewSeatById(state, jobStageInterviewSeatId);

    if (!jobStageInterviewSeat) return;

    const jobStageInterview = getJobStageInterviewById(state, jobStageInterviewSeat.jobStageInterviewId);

    if (!jobStageInterview) return;

    const allJobStageInterview = getAllJobStageInterviewByJobStageId(state, jobStageInterview.jobStageId);

    allJobStageInterview.forEach((jsi) => {
      jsi.seatIds.forEach((seatId) => {
        const seat = getJobStageInterviewSeatById(state, seatId);

        if (!seat || seat.type !== JobStageInterviewSeatType.Linked) return;

        const originalSeat = getNormalizedJobStageInterviewSeatsById(state, [seat.id]);

        if (!originalSeat || originalSeat.length === 0 || jobStageInterviewSeatId !== originalSeat[0].id) return;

        dispatch(
          jobStageInterviewSeatSlice.actions.updateJobStageInterviewSeat({
            ...originalSeat[0],
            id: seat.id,
            interviewSeatId: undefined,
          })
        );
      });
    });
  };
