import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import type { CSSProperties, FC } from 'react';

export interface Props {
  color?: 'primary' | 'secondary' | 'teal' | 'white';
}
const useSxProps = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      path: {
        stroke: 'none',
        fillRule: 'nonzero',
        fillOpacity: 1,
      },
      primary: {
        fill: theme.palette.primary.main,
      },
      secondary: {
        fill: theme.palette.secondary.main,
      },
      teal: {
        fill: 'rgb(40.392157%,56.862745%,60.784314%)',
      },
      white: {
        fill: 'white',
      },
    }),
    [theme.palette.primary.main, theme.palette.secondary.main]
  );
};

/**
 * @deprecated Use `UnbrandedLoader` instead.
 */
export const ModernLoopLoader: FC<Props> = ({ color = 'primary' }) => {
  const sxProps = useSxProps();
  const pathSxProps = useMemo(() => ({ ...sxProps.path, ...sxProps[color] }), [color, sxProps]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="32pt"
      height="32pt"
      viewBox="0 0 32 32"
      version="1.1"
    >
      <g id="surface1">
        <path
          // TODO: Fix this the next time the file is edited
          // eslint-disable-next-line no-restricted-syntax
          style={pathSxProps as CSSProperties}
          d="M 8.070312 7.195312 C 7.933594 7.191406 7.792969 7.207031 7.65625 7.242188 C 6.902344 7.441406 6.398438 8.097656 6.398438 8.851562 L 6.398438 23.144531 C 6.398438 24.050781 7.15625 24.804688 8.0625 24.804688 C 8.992188 24.804688 9.746094 24.050781 9.746094 23.144531 L 9.746094 14.613281 L 14.425781 23.925781 C 14.730469 24.476562 15.28125 24.804688 15.886719 24.804688 C 16.492188 24.804688 17.042969 24.476562 17.347656 23.949219 L 22.253906 14.460938 L 22.253906 18.136719 C 22.253906 19.042969 23.007812 19.796875 23.914062 19.796875 C 24.84375 19.796875 25.601562 19.042969 25.601562 18.136719 L 25.601562 8.851562 C 25.601562 8.097656 25.070312 7.441406 24.34375 7.242188 C 23.613281 7.066406 22.832031 7.390625 22.480469 8.046875 L 15.910156 20.199219 L 9.519531 8.070312 C 9.234375 7.519531 8.664062 7.199219 8.070312 7.195312 Z M 23.914062 21.460938 C 23.007812 21.460938 22.253906 22.214844 22.253906 23.144531 C 22.253906 24.050781 23.007812 24.804688 23.914062 24.804688 C 24.84375 24.804688 25.601562 24.050781 25.601562 23.144531 C 25.601562 22.214844 24.84375 21.460938 23.914062 21.460938 Z M 23.914062 21.460938 "
        />
      </g>
      <animateTransform
        attributeName="transform"
        type="scale"
        values="1; 1.5; 1.25; 1.5; 1.5; 1;"
        dur="1s"
        repeatCount="indefinite"
        additive="sum"
      />
    </svg>
  );
};
