import { ThemeOptions } from '@mui/material';

const CARD_CONTENT_PADDING = 2.5;

export const card: ThemeOptions['components'] = {
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme }) => {
        return {
          padding: theme.spacing(CARD_CONTENT_PADDING),
          '&:last-child': {
            paddingBottom: theme.spacing(CARD_CONTENT_PADDING),
          },
        };
      },
    },
  },
};
