import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskChangedStatus_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';

import logError from 'src/utils/logError';
import { snakeToSentenceCase } from 'src/utils/strings';

interface Fragments {
  activityLog: ActivityLogTaskChangedStatus_ActivityLogFragment;
}

const ActivityLogTaskChangedStatus: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;
  let status = 'Unknown';

  // eslint-disable-next-line no-underscore-dangle
  if (details?.__typename === 'ActivityLogTaskStatusDetails') {
    status = snakeToSentenceCase(details.taskStatus);
  } else {
    logError('ActivityLogTaskChangedStatus: details is not ActivityLogTaskStatusDetails');
  }

  return <ActivityLogBase activityLog={activityLog} actionLabel={`set status to ${status}`} />;
};

ActivityLogTaskChangedStatus.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskChangedStatus_activityLog on ActivityLog {
      id
      details {
        __typename
        ... on ActivityLogTaskStatusDetails {
          taskStatus
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskChangedStatus;
