import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CaretUpIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fill="none"
        d="M15 11.5L10 7.5L5 11.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
