import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';

import { State } from 'src/store';

export const getScheduleCount = (state: State): number => state.schedules.count;

export const getScheduleById = (state: State, scheduleId: string): InterviewSchedule =>
  state.schedules.byId[scheduleId];

export const getScheduleIndexById = (state: State, scheduleId: string): number => {
  const keys = Object.keys(state.schedules.byId);
  return keys.indexOf(scheduleId);
};

export const getAllSchedules = (state: State): InterviewSchedule[] => Object.values(state.schedules.byId);

export const getScheduleScrollToIndex = (state: State): number => state.schedules.scrollToIndex;

export const getIsMultiDayOverrite = (state: State) => {
  return state.schedules.config.scheduleOverride.multiDay;
};
