import React from 'react';
import type { FC } from 'react';
import { Redirect } from 'react-router';

import { Routes } from 'src/constants/routes';

import useAuth from 'src/hooks/useAuth';

/**
 * Guard that only allows non authenticated users to access the children components
 * Otherwise, it will redirect to the landing page. This is useful for login and register pages that should
 * not be accessed by authenticated users.
 * Note: Guards should check only one thing. Don't add any other checks to this Guard.
 */
const UserIsLoggedOutGuard: FC = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to={Routes.MySchedulingTasks} />;
  }

  return <>{children}</>;
};

export default UserIsLoggedOutGuard;
