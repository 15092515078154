import React, { FC, useState } from 'react';

import { MagicIcon } from '@modernloop/shared/icons';
import { IconButton, ListItemButton, ListItemIcon, Tooltip, Typography } from '@mui/material';

import { useIsMLoopInternalOnly } from 'src/hooks/feature-flag/useIsMLoopInternalOnly';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import DevToolsModal from './DevToolsModal';

type Props = {
  expanded: boolean;
};

const DevToolsNavButton: FC<Props> = ({ expanded }) => {
  const [isDevToolsOpen, setIsDevToolsOpen] = useState(false);

  const isMLOnly = useIsMLoopInternalOnly();
  if (!isMLOnly) {
    return null;
  }

  const handleDevToolsClick = () => {
    setIsDevToolsOpen(!isDevToolsOpen);
  };

  return (
    <ConditionalThemeProvider>
      {!expanded && (
        <Tooltip title="ModernLoop Internal Only - Dev Tools">
          <IconButton onClick={handleDevToolsClick}>
            <MagicIcon />
          </IconButton>
        </Tooltip>
      )}
      {expanded && (
        <ListItemButton onClick={handleDevToolsClick}>
          <ListItemIcon>
            <MagicIcon />
          </ListItemIcon>
          <Typography color="text.secondary" fontWeight="500" variant="body2">
            Dev Tools
          </Typography>
        </ListItemButton>
      )}
      {isDevToolsOpen && <DevToolsModal onClose={handleDevToolsClick} />}
    </ConditionalThemeProvider>
  );
};

export default DevToolsNavButton;
