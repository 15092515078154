import { useCallback } from 'react';

import { useApolloRefetchQuery } from 'src/hooks/useApolloQuery';

export function useSubscribersSelectRefetch() {
  const refetchQuery = useApolloRefetchQuery();

  const refetch = useCallback(() => {
    return refetchQuery(['SubscribersList']);
  }, [refetchQuery]);

  return refetch;
}
