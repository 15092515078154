/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

const isArray = (a): boolean => {
  return Array.isArray(a);
};

const isObject = (o: any): boolean => {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

/**
 * Converts a string to camel case
 * @deprecated If you must convert a key, instead create a data mapper to maintain type safety
 */
export const toCamelCase_TYPE_UNSAFE_DO_NOT_USE = (s: any) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

/**
 * Converts keys of an object to camel case
 * @deprecated If you must convert objects, instead create a data mapper to maintain type safety
 */
export const keysToCamel_TYPE_UNSAFE_DO_NOT_USE = <T>(o: any): T => {
  if (isObject(o)) {
    const n = {} as T;

    Object.keys(o).forEach((k) => {
      n[toCamelCase_TYPE_UNSAFE_DO_NOT_USE(k)] = keysToCamel_TYPE_UNSAFE_DO_NOT_USE(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel_TYPE_UNSAFE_DO_NOT_USE(i);
    });
  }

  return o;
};
