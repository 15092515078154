import React, { useState } from 'react';

import { CopyTextField } from '@modernloop/shared/components';
import { TriangleDownIcon } from '@modernloop/shared/icons';
import { Avatar, Button, LinearProgress, Stack, Typography } from '@mui/material';

import { EmployeeFragment, useEmployeeByIdsQuery } from 'src/generated/mloop-graphql';

import SingleEmployeeSelect from 'src/entities/Select/EmployeeSelect/SingleEmployeeSelect';

type Props = {
  hostEmployeeId: string | undefined;
  url: string | undefined;
  interviewerIds: string[];
  onHostChanged: (hostEmployeeId: string) => Promise<void>;
};

const VideoMeetingLinkWithHost = ({ hostEmployeeId, url, interviewerIds, onHostChanged }: Props) => {
  const [loadingUrl, setLoadingUrl] = useState(false);
  const [employee, setEmployee] = useState<EmployeeFragment>();

  useEmployeeByIdsQuery({
    variables: { input: [hostEmployeeId] },
    skip: !hostEmployeeId,
    onCompleted: (data) => {
      if (data.employeeByIds?.length) {
        setEmployee(data.employeeByIds[0]);
      }
    },
  });

  const handleHostChanged = async (emp: EmployeeFragment) => {
    setLoadingUrl(true);

    try {
      setEmployee(emp);
      await onHostChanged(emp.id);
    } finally {
      setLoadingUrl(false);
    }
  };

  return (
    <Stack spacing={1}>
      <SingleEmployeeSelect
        interviewerIds={interviewerIds}
        onEmployeeSelect={handleHostChanged}
        getLabel={() => {
          return (
            <Button
              fullWidth
              endIcon={<TriangleDownIcon fontSize="small" />}
              sx={{ borderRadius: 0.75, alignItems: 'center', justifyContent: 'space-between' }}
            >
              <Stack direction="row" spacing={1}>
                {employee && (
                  <Avatar src={employee.slackImageUrl || undefined} alt={employee.fullName || ''}>
                    {employee.slackImageUrl ? null : employee.fullName?.charAt(0).toUpperCase()}
                  </Avatar>
                )}
                <Typography>{employee?.fullName || 'Select user'}</Typography>
              </Stack>
            </Button>
          );
        }}
      />
      {loadingUrl && <LinearProgress />}
      {!loadingUrl && url && <CopyTextField value={url} />}
    </Stack>
  );
};

export default VideoMeetingLinkWithHost;
