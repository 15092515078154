import { SvgIcon, SvgIconProps } from './SvgIcon';

export const MeetingRoomIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox={props.viewBox ? props.viewBox : '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43937 5.03861L10.75 3.96095V5.00036V16.0398L6.25 14.9148V5.28114C6.25 5.16642 6.32807 5.06643 6.43937 5.03861ZM4.75 5.28114V14.7504H3C2.58579 14.7504 2.25 15.0862 2.25 15.5004C2.25 15.9146 2.58579 16.2504 3 16.2504H5.40767L10.6968 17.5727C11.4858 17.7699 12.25 17.1732 12.25 16.36V5.75036H13.75V15.5004C13.75 15.9146 14.0858 16.2504 14.5 16.2504H17C17.4142 16.2504 17.75 15.9146 17.75 15.5004C17.75 15.0862 17.4142 14.7504 17 14.7504H15.25V5.00036C15.25 4.58615 14.9142 4.25036 14.5 4.25036H12.25V3.64075C12.25 2.82754 11.4858 2.23084 10.6968 2.42807L6.07556 3.58339C5.29652 3.77815 4.75 4.47812 4.75 5.28114ZM9 11.5004C9.55228 11.5004 10 11.0527 10 10.5004C10 9.94808 9.55228 9.50037 9 9.50037C8.44772 9.50037 8 9.94808 8 10.5004C8 11.0527 8.44772 11.5004 9 11.5004Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
