export enum IframeStateActionType {
  SetWindow = 'set-window',
}

export type IframeStateAction = {
  type: IframeStateActionType;
  iframeWindow: Window | null;
};

export interface IframeState {
  iframeWindow: Window | null;
}
