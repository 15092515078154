import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskCandidateSubmittedAvailability_ActivityLogFragment } from 'src/generated/mloop-graphql';

import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import ActivityLogContent from 'src/entities/ActivityLog/Helpers/ActivityLogContent';

import logError from 'src/utils/logError';

interface Fragments {
  activityLog: ActivityLogTaskCandidateSubmittedAvailability_ActivityLogFragment;
}

const ActivityLogTaskCandidateSubmittedAvailability: FCWithFragments<Fragments> = ({ activityLog }) => {
  let note = '';
  let sourcingLinkId = null;

  // eslint-disable-next-line no-underscore-dangle
  if (activityLog?.details?.__typename === 'ActivityLogTaskAvailabilityDetails') {
    note = activityLog.details.note;
    sourcingLinkId = activityLog.details.sourcingLinkId;
  } else {
    logError('ActivityLogTaskCandidateSubmittedAvailability: details is not ActivityLogTaskAvailabilityDetails');
  }

  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={`submitted availability${sourcingLinkId ? ' via sourcing link' : ''}`}
    >
      {note && (
        <ActivityLogContent
          sx={{
            borderRadius: '4px 12px 12px 12px',
          }}
          content={
            <Label color="max-contrast-grey" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} variant="body">
              {note}
            </Label>
          }
        />
      )}
    </ActivityLogBase>
  );
};

ActivityLogTaskCandidateSubmittedAvailability.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskCandidateSubmittedAvailability_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogTaskAvailabilityDetails {
          sourcingLinkId
          note
        }
      }

      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskCandidateSubmittedAvailability;
