import { SvgIcon, SvgIconProps } from './SvgIcon';

export const EventWithCancelIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props} display="flex">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.25C7.41421 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48122 17.75 6V9C17.75 9.41421 17.4142 9.75 17 9.75C16.5858 9.75 16.25 9.41421 16.25 9V6C16.25 5.30964 15.6903 4.75 15 4.75H13H7H5C4.30964 4.75 3.75 5.30965 3.75 6L3.75001 15C3.75001 15.6904 4.30966 16.25 5.00001 16.25H8.99997C9.41418 16.25 9.74997 16.5858 9.74997 17C9.74997 17.4142 9.41418 17.75 8.99997 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H6.25V2C6.25 1.58579 6.58578 1.25 7 1.25ZM11.25 14C11.25 12.4812 12.4812 11.25 14 11.25C14.4869 11.25 14.9442 11.3765 15.3409 11.5985L11.5985 15.3409C11.3765 14.9442 11.25 14.4869 11.25 14ZM12.6591 16.4015L16.4015 12.6591C16.6235 13.0558 16.75 13.5131 16.75 14C16.75 15.5188 15.5188 16.75 14 16.75C13.5131 16.75 13.0558 16.6235 12.6591 16.4015ZM14 9.75C11.6528 9.75 9.75 11.6528 9.75 14C9.75 16.3472 11.6528 18.25 14 18.25C16.3472 18.25 18.25 16.3472 18.25 14C18.25 11.6528 16.3472 9.75 14 9.75ZM14 7H6C5.44772 7 5 7.44772 5 8V10C5 10.5523 5.44772 11 6 11H9.38947C10.3708 9.4949 12.0692 8.5 14 8.5C14.3415 8.5 14.6757 8.53112 15 8.59069V8C15 7.44772 14.5523 7 14 7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
