import React from 'react';

import BaseIcon, { Props } from './Icon';

const DateTimeIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 2C7.75 1.58579 7.41421 1.25 7 1.25C6.58579 1.25 6.25 1.58579 6.25 2V3.25H5C3.48122 3.25 2.25 4.48122 2.25 6L2.25002 15C2.25002 16.5188 3.48124 17.75 5.00002 17.75H9.75865C10.6815 18.381 11.7977 18.75 13 18.75C16.1756 18.75 18.75 16.1756 18.75 13C18.75 11.7977 18.381 10.6815 17.75 9.75862V6C17.75 4.48122 16.5188 3.25 15 3.25H13.75V2C13.75 1.58579 13.4142 1.25 13 1.25C12.5858 1.25 12.25 1.58579 12.25 2V3.25H7.75V2ZM6.25 4.75V5C6.25 5.41421 6.58579 5.75 7 5.75C7.41421 5.75 7.75 5.41421 7.75 5V4.75H12.25V5C12.25 5.41421 12.5858 5.75 13 5.75C13.4142 5.75 13.75 5.41421 13.75 5V4.75H15C15.6903 4.75 16.25 5.30964 16.25 6V8.25591C15.3254 7.62133 14.2061 7.25 13 7.25C9.82436 7.25 7.25 9.82436 7.25 13C7.25 14.2061 7.62134 15.3255 8.25593 16.25L5.00002 16.25C4.30966 16.25 3.75002 15.6904 3.75002 15L3.75 6C3.75 5.30965 4.30964 4.75 5 4.75H6.25ZM16.3882 10.434C16.3959 10.4448 16.4039 10.4554 16.4121 10.4658C16.9385 11.1734 17.25 12.0503 17.25 13C17.25 15.3472 15.3472 17.25 13 17.25C12.0502 17.25 11.1731 16.9384 10.4655 16.4119C10.4553 16.4038 10.4449 16.396 10.4343 16.3884C9.41094 15.6124 8.75 14.3834 8.75 13C8.75 10.6528 10.6528 8.75 13 8.75C14.3832 8.75 15.6121 9.41081 16.3882 10.434ZM13 9.75C13.4142 9.75 13.75 10.0858 13.75 10.5V12.6893L15.0303 13.9697C15.3232 14.2626 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2626 15.3232 13.9697 15.0303L12.4697 13.5303C12.329 13.3897 12.25 13.1989 12.25 13V10.5C12.25 10.0858 12.5858 9.75 13 9.75Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DateTimeIcon;
