import React from 'react';

import BaseIcon, { Props } from './Icon';

const Google = ({ color, fontSize, tooltip }: Props) => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <rect width="20" height="20" fill="white" />
      <path
        d="M17.425 10.1758C17.425 9.62734 17.3758 9.1 17.2844 8.59375H10V11.5891H14.1625C13.9797 12.5523 13.4312 13.368 12.6086 13.9164V15.8641H15.1188C16.5812 14.5141 17.425 12.5312 17.425 10.1758Z"
        fill="#4285F4"
      />
      <path
        d="M10 17.7344C12.0883 17.7344 13.8391 17.0453 15.1188 15.8641L12.6086 13.9164C11.9196 14.3805 11.0406 14.6617 10 14.6617C7.98909 14.6617 6.28049 13.3047 5.66877 11.4766H3.09534V13.4734C4.36799 15.9977 6.97659 17.7344 10 17.7344Z"
        fill="#34A853"
      />
      <path
        d="M5.66875 11.4695C5.51406 11.0055 5.42266 10.5133 5.42266 10C5.42266 9.48673 5.51406 8.99454 5.66875 8.53047V6.5336H3.09531C2.56797 7.57423 2.26562 8.74844 2.26562 10C2.26562 11.2516 2.56797 12.4258 3.09531 13.4664L5.09922 11.9055L5.66875 11.4695Z"
        fill="#FBBC05"
      />
      <path
        d="M10 5.34531C11.1391 5.34531 12.1516 5.73906 12.9602 6.49844L15.175 4.28359C13.8321 3.03203 12.0883 2.26562 10 2.26562C6.97659 2.26562 4.36799 4.00234 3.09534 6.53359L5.66877 8.53047C6.28049 6.70234 7.98909 5.34531 10 5.34531Z"
        fill="#EA4335"
      />
    </BaseIcon>
  );
};

export default Google;
