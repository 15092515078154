import React, { useMemo } from 'react';

import { isAfter, isBefore, isEqual, parseISO } from 'date-fns';

import Stack from 'src/components/Stack';
import { MeetingRoomIcon } from 'src/components/icons';
import Label from 'src/components/label';

import useDisplayTimezone from 'src/hooks/useDisplayTimezone';
import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { getSelectedScheduleId } from 'src/store/selectors/scheduling';

import { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { formatToTimeZone } from 'src/utils/datetime/Conversions';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';
import {
  getFirstEventStartTimeForDay,
  getScheduleEndTimeForDayEventEnds,
  getUniqueEventDaysFromScheduleEventStarts,
} from 'src/views-new/ScheduleFlow/Steps/Schedule/utils';

import { useSelector } from 'src/store';

import MeetingRoom from '.';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type MeetingRoomCommonProps = {
  scheduleId: string;
  scheduleFlowType?: ScheduleFlowType;
};

const MeetingRoomCommon = ({ scheduleId, scheduleFlowType }: MeetingRoomCommonProps) => {
  const timezone = useDisplayTimezone();
  const selectedScheduleId = useSelector(getSelectedScheduleId);
  const schedule = useScheduleWithoutBreaks(selectedScheduleId);

  const timeRanges = useMemo(() => {
    if (!schedule) return null;
    const uniqueDays = getUniqueEventDaysFromScheduleEventStarts(schedule, timezone);
    return uniqueDays.map((day) => {
      const firstEventStartAt = getFirstEventStartTimeForDay(schedule, day, timezone);
      const lastEventEndAt = getScheduleEndTimeForDayEventEnds(schedule, day, timezone);
      return {
        startAt: assertIsoTimestamp(new Date(firstEventStartAt).toISOString()),
        endAt: assertIsoTimestamp(new Date(lastEventEndAt).toISOString()),
      };
    });
  }, [schedule, timezone]);

  if (scheduleFlowType === ScheduleFlowType.UPDATE) {
    return (
      <Stack direction="column" spacing={1}>
        <Label icon={<MeetingRoomIcon />}>Meeting room</Label>
        <Label color="high-contrast-grey">Meeting rooms can be updated individually below</Label>
      </Stack>
    );
  }

  if (!timeRanges) return null;

  return (
    <Stack direction="column" spacing={1}>
      <Label icon={<MeetingRoomIcon />}>Meeting room</Label>

      {timeRanges.map((timeRange) => {
        const commonInterviewEventIds: string[] = [];
        if (schedule?.events?.length) {
          /**
           * Get a list of all the eventId's that start within the time span represented by timeRange.
           * It is used by `<MeetingRoom />` to set common meeting room for all the events.
           */
          schedule.events.forEach((event) => {
            if (
              (isAfter(parseISO(event.startAt), parseISO(timeRange.startAt)) ||
                isEqual(parseISO(event.startAt), parseISO(timeRange.startAt))) &&
              (isBefore(parseISO(event.startAt), parseISO(timeRange.endAt)) ||
                isEqual(parseISO(event.startAt), parseISO(timeRange.endAt)))
            ) {
              commonInterviewEventIds.push(event.id);
            }
          });
        }

        return (
          <div
            key={`${timeRange.startAt}-${timeRange.endAt}`}
            style={{ display: 'flex', flexDirection: 'column', rowGap: '4px', width: '100%' }}
          >
            <Label fontWeight={600} variant="captions">
              {formatToTimeZone(timeRange.startAt, 'MMM d, yyyy, E', timezone)}
            </Label>
            <MeetingRoom
              scheduleId={scheduleId}
              key={`${timeRange.startAt}-${timeRange.endAt}`}
              startAt={timeRange.startAt}
              endAt={timeRange.endAt}
              commonInterviewEventIds={commonInterviewEventIds}
            />
          </div>
        );
      })}
    </Stack>
  );
};

export default MeetingRoomCommon;
