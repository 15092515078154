import React, { useEffect } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box, ListItem } from '@material-ui/core';

import {
  CurrentTrainingSessionStatus,
  InterviewerRole,
  TrainingStatus,
  useInterviewModuleMemberLookupLazyQuery,
} from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import Stack from 'src/components/Stack';
import Label from 'src/components/label';
import { MenuOption } from 'src/components/menu';

import { snakeCaseToInitCase } from 'src/utils/plainText';

const useTraineeModuleOptions = (
  prefix: string,
  interviewerId: string,
  interviewerName: string,
  interviewerInterviewModuleMap: {
    interviewerId: string;
    interviewerName: string;
    interviewerSlackImageUrl: string;
    interviewModuleId: string;
    traineeId?: string;
    traineeName?: string;
    traineeRole?: InterviewerRole;
  }[] // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
MenuOption[] => {
  const [fetchMemberModules, { data, loading, error }] = useInterviewModuleMemberLookupLazyQuery({
    variables: {
      memberIds: [interviewerId],
      interviewModuleIds: interviewerInterviewModuleMap.map((iim) => iim.interviewModuleId),
    },
  });

  useEffect(() => {
    if (!interviewerId) return;
    fetchMemberModules();
  }, [fetchMemberModules, interviewerId]);

  if (loading) {
    return [
      {
        id: 'freeform-interviewer-options-loading',
        value: 'Loading…',
        disabled: true,
      },
    ];
  }

  if (error) {
    return [
      {
        id: 'freeform-interviewer-options-loading',
        value: `Error: ${error.message}`,
        disabled: true,
      },
    ];
  }

  if (
    !data ||
    !data.interviewModuleMemberLookup ||
    !data.interviewModuleMemberLookup.items ||
    data.interviewModuleMemberLookup.items.length === 0
  ) {
    const customOption = (
      <Box width="260px">
        <Label color="high-contrast-grey" style={{ whiteSpace: 'pre-wrap' }}>
          {interviewerName || 'Interviewer'} is not training for any modules used in this interview
        </Label>
      </Box>
    );
    return [
      {
        id: 'freeform-interviewer-options-loading',
        customOption,
        disabled: true,
      },
    ];
  }

  const { items } = data.interviewModuleMemberLookup;

  return items
    .filter((i) => i.status !== TrainingStatus.Trained)
    .map((i) => {
      const interviewers = interviewerInterviewModuleMap.filter((iim) => iim.interviewModuleId === i.interviewModuleId);

      return interviewers.map((interviewer) => {
        let status = snakeCaseToInitCase(i.status);
        if (interviewer?.traineeId) {
          status = `${interviewer.traineeName} is training with ${interviewer.interviewerName}`;
        } else if (i.stats?.currentTrainingSessionStatus === CurrentTrainingSessionStatus.NeedsApproval) {
          status = 'Needs approval';
        } else if (i.status === TrainingStatus.Shadow) {
          status = `Shadow ${i.stats?.completedAsShadow || 0 + i.stats?.shadowOffset || 0 + 1}`;
        } else if (i.status === TrainingStatus.ReverseShadow) {
          status = `Reverse shadow ${i.stats?.completedAsReverseShadow || 0 + i.stats?.reverseShadowOffset || 0 + 1}`;
        }

        return {
          id: `${prefix}-${i.interviewModuleId}_${interviewer.interviewerId}`,
          // Storing the training status in value field,
          // it is used when the option is selected to determine if interviewer is shadow or reverse shadow
          value: i.status,
          customOption: (
            <ListItem disableGutters>
              <Box px="12px" width="260px">
                <Stack spacing={1}>
                  <Avatar
                    alt={interviewer?.interviewerName || ''}
                    src={interviewer?.interviewerSlackImageUrl || ''}
                    size="20px"
                  />
                  <Stack direction="column">
                    <Label>{interviewer?.interviewerName}</Label>
                    <Label color="high-contrast-grey" fontWeight={400} variant="captions">
                      {i.interviewModule?.name}
                    </Label>
                    <Label color="high-contrast-grey" fontWeight={400} variant="captions">
                      {status}
                    </Label>
                  </Stack>
                </Stack>
              </Box>
            </ListItem>
          ),
          disabled:
            Boolean(interviewer?.traineeId) ||
            i.stats?.currentTrainingSessionStatus === CurrentTrainingSessionStatus.NeedsApproval,
        };
      });
    })
    .flat();
};

export default useTraineeModuleOptions;
