import React, { FC } from 'react';

import { useTheme } from '@mui/material';

import Tag, { TagProps } from '.';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface UrgentTagProps {
  size?: TagProps['size'];
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const UrgentTag: FC<UrgentTagProps> = ({ size = 'medium' }) => {
  const theme = useTheme();

  return (
    <Tag size={size} variant="filled" label="Urgent" sx={{ backgroundColor: '#FFE252', color: theme.grey.solid.max }} />
  );
};

export default UrgentTag;
