import React from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

/**
 * Referenced from
 * https://github.com/mui-org/material-ui/blob/bb3b9adbd42d8d0aa5f50b5da60f7ee377118a48/docs/src/pages/components/autocomplete/Virtualize.tsx
 */
import { BaseProps } from 'src/components/types';

const LISTBOX_PADDING = 8; // px

export type VirtualListOnScrollParams = {
  scrollDirection: 'forward' | 'backward';
  scrollOffset: number;
  scrollUpdateWasRequested: boolean;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type VirtualListboxProps = BaseProps & {
  getChildSize?: (child: React.ReactNode, index: number) => number;
  onScroll?: (params: VirtualListOnScrollParams) => void;
  innerRef?: React.RefObject<HTMLElement>;
  outerRef?: React.RefObject<HTMLElement>;
  height?: number;
  children?: React.ReactNode;
};

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>(function OuterElementType(props, ref) {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useResetCache = (itemCount: number, selectedItemIndex: number) => {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [itemCount, selectedItemIndex]);

  React.useEffect(() => {
    if (ref.current && selectedItemIndex !== -1) {
      ref.current.scrollToItem(selectedItemIndex, 'smart');
    }
  }, [selectedItemIndex]);

  return ref;
};

// Adapter for react-window
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const VirtualList = React.forwardRef<HTMLDivElement, VirtualListboxProps>(function VirtualList(props, ref) {
  const { children, getChildSize, onScroll, innerRef, outerRef, height, dataTestId, ...other } = props;
  const itemData = React.Children.toArray(children);
  const itemCount = itemData.length;
  const itemSize = 32;

  const selectedItemIndex = itemData.findIndex((data: React.ReactElement) => data.props['aria-selected']);

  const defaultGetChildSize = () => {
    return itemSize;
  };

  const defaultGetHeight = () => {
    if (itemCount > 8) {
      const first8Items = itemData.slice(0, 8);
      return first8Items.map(getChildSize ?? defaultGetChildSize).reduce((a, b) => a + b, 0) + 2 * LISTBOX_PADDING;
    }
    return itemData.map(getChildSize ?? defaultGetChildSize).reduce((a, b) => a + b, 0) + 2 * LISTBOX_PADDING;
  };

  const gridRef = useResetCache(itemCount, selectedItemIndex);

  return (
    <div ref={ref} data-testid={dataTestId}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={height || defaultGetHeight()}
          width="auto"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: number) => {
            return (getChildSize ?? defaultGetChildSize)(itemData[index], index);
          }}
          onScroll={onScroll}
          overscanCount={5}
          itemCount={itemCount}
          style={{
            overflowX: 'hidden',
          }}
          innerRef={innerRef}
          outerRef={outerRef}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default VirtualList;
