import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Chip, Theme, Tooltip } from '@mui/material';

import { ModuleMemberPausedChip_ModuleMemberFragment } from 'src/generated/mloop-graphql';

import { PauseIcon } from 'src/components/icons';

import {
  getModuleMemberEmployeePausedText,
  getModuleMemberPauseText,
  isModuleMemberEmployeePaused,
  isModuleMemberPauseIndefinitely,
  isModuleMemberPaused,
} from '../utils';

type Fragments = {
  moduleMember: ModuleMemberPausedChip_ModuleMemberFragment;
};

const ModuleMemberPausedChip: FCWithFragments<Fragments> = ({ moduleMember }) => {
  let pausedTooltip = '';
  if (moduleMember.employee && isModuleMemberEmployeePaused({ moduleMember }, null)) {
    pausedTooltip = getModuleMemberEmployeePausedText({ moduleMember }, null);
  }

  if (isModuleMemberPauseIndefinitely({ moduleMember }, null)) {
    pausedTooltip = 'Paused from module indefinitely';
  }

  if (isModuleMemberPaused({ moduleMember }, null)) {
    pausedTooltip = getModuleMemberPauseText({ moduleMember }, null);
  }

  if (pausedTooltip) {
    return (
      <Tooltip title={pausedTooltip}>
        <Chip
          label="Paused"
          sx={{
            border: (theme: Theme) => `1px solid ${theme.palette.secondary.dark}`,
          }}
          color="secondary"
          icon={<PauseIcon />}
        />
      </Tooltip>
    );
  }
  return null;
};

ModuleMemberPausedChip.fragments = {
  moduleMember: gql`
    ${isModuleMemberEmployeePaused.fragments.moduleMember}
    ${isModuleMemberPaused.fragments.moduleMember}
    ${isModuleMemberPauseIndefinitely.fragments.moduleMember}
    ${getModuleMemberEmployeePausedText.fragments.moduleMember}
    ${getModuleMemberPauseText.fragments.moduleMember}
    fragment ModuleMemberPausedChip_moduleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      isPausedIndefinitely
      isPaused
      pausedUntil
      id
      employee {
        id
        isPaused
        timezone
        interviewPauseDates {
          start
          end
        }
      }
      ...isModuleMemberEmployeePaused_ModuleMember
      ...isModuleMembreOrEmployeePaused_ModuleMember
      ...isModuleMemberPauseIndefinitely_ModuleMember
      ...getModuleMemberEmployeePausedText_ModuleMember
      ...getModuleMemberPauseText_ModuleMember
    }
  `,
};

export default ModuleMemberPausedChip;
