import React, { useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { Loader } from '@modernloop/shared/helper-components';
import { pluralize } from '@modernloop/shared/utils';
import { ListSubheader, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';

import {
  QueueSelect_DefaultTaskQueueFragment,
  QueueSelect_SelectedTaskQueueFragment,
  useTaskQueuesSelectListQuery,
} from 'src/generated/mloop-graphql';

import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';
import { FCWithFragments } from 'src/components/types';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { NO_QUEUE, PAGE_SIZE } from 'src/constants';

import { TaskQueueSelectZeroState } from './TaskQueueSelectZeroState';

type Props = {
  onOptionChange: (value: string) => void;
  groupQueue?: boolean;
};

type Fragments = {
  selectedTaskQueue: QueueSelect_SelectedTaskQueueFragment | undefined | null;
  defaultTaskQueue: QueueSelect_DefaultTaskQueueFragment | undefined | null;
};

const QueueSelect: FCWithFragments<Fragments, Props> = ({
  selectedTaskQueue,
  onOptionChange,
  groupQueue,
  defaultTaskQueue,
}) => {
  const [selectedQueueId, setSelectedQueueId] = useState('');
  const { data, loading } = useTaskQueuesSelectListQuery({
    variables: {
      input: {
        pageInput: {
          limit: PAGE_SIZE,
          offset: 0,
        },
      },
    },
  });

  const handleOptionSelect = (option: SelectChangeEvent) => {
    setSelectedQueueId(option.target.value);
    if (option.target.value === NO_QUEUE) {
      onOptionChange('');
      return;
    }
    onOptionChange(option.target.value);
  };

  const options = useMemo(() => {
    return (
      data?.taskQueueSearch?.items.map((queue) => {
        return {
          value: queue.id.toString(),
          label: `${queue.name} (${queue.memberCount} ${pluralize('member', queue.memberCount)})`,
          id: queue.id,
        };
      }) || []
    );
  }, [data?.taskQueueSearch?.items]);

  const selectedValue = selectedQueueId || selectedTaskQueue?.id.toString() || NO_QUEUE;

  const renderContent = () => {
    if (loading) {
      return <Loader loading />;
    }

    if (options.length) {
      return options
        .filter((option) => {
          if (groupQueue) {
            return option.id !== defaultTaskQueue?.id;
          }
          return option;
        })
        .map((option) => (
          <MenuItem value={option.value} key={option.id}>
            <Stack maxWidth="280px" flexWrap="nowrap">
              <MaybeTooltip label={option.label} tooltip={option.label} />
            </Stack>
          </MenuItem>
        ));
    }

    if (!options.length && !loading) {
      return <TaskQueueSelectZeroState />;
    }

    return null;
  };
  const taskQueueDefault = options.find((option) => option.id === defaultTaskQueue?.id.toString());

  const selectJsx = () => {
    if (groupQueue) {
      return (
        <Select fullWidth onChange={handleOptionSelect} value={selectedValue}>
          <ListSubheader>Job default</ListSubheader>
          {!taskQueueDefault?.id && <MenuItem value="no_queue">No queue</MenuItem>}
          {taskQueueDefault?.id && <MenuItem value={taskQueueDefault?.id}>{taskQueueDefault?.label}</MenuItem>}
          {!!options.length && <ListSubheader>Other queues</ListSubheader>}
          {taskQueueDefault?.id && <MenuItem value="no_queue">No queue</MenuItem>}
          {renderContent()}
        </Select>
      );
    }

    return (
      <Select fullWidth onChange={handleOptionSelect} value={selectedValue}>
        <MenuItem value="no_queue">No queue</MenuItem>
        {renderContent()}
      </Select>
    );
  };

  return <ConditionalThemeProvider>{selectJsx()}</ConditionalThemeProvider>;
};

QueueSelect.fragments = {
  selectedTaskQueue: gql`
    fragment QueueSelect_selectedTaskQueue on TaskQueue {
      id
      name
      memberCount
    }
  `,
  defaultTaskQueue: gql`
    fragment QueueSelect_defaultTaskQueue on TaskQueue {
      id
      ...QueueSelect_selectedTaskQueue
    }
  `,
};

export const TaskQueuesSelectListQueryGQL = gql`
  query TaskQueuesSelectList($input: TaskQueueSearchInput!) {
    taskQueueSearch(input: $input) {
      items {
        ...QueueSelect_selectedTaskQueue
      }
    }
  }
`;

export default QueueSelect;
