import { useEffect } from 'react';

import { gql } from '@apollo/client';

import { useShortUrlCreateMutation } from 'src/generated/mloop-graphql';

export const ShortUrlCreateMutation = gql`
  mutation ShortUrlCreate($input: ShortUrlCreateInput!) {
    shortUrlCreate(input: $input) {
      shortUrl
    }
  }
`;

const useShortUrlCreate = (longUrl: string) => {
  const [createShortUrl, result] = useShortUrlCreateMutation();

  useEffect(() => {
    if (!longUrl) return;
    createShortUrl({ variables: { input: { longUrl } } });
  }, [createShortUrl, longUrl]);

  return result;
};

export default useShortUrlCreate;
