import React, { useCallback, useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { addMilliseconds, differenceInMilliseconds } from 'date-fns';

import Button from 'src/components/button';
import { DateIcon } from 'src/components/icons';

import { formatToTimeZone } from 'src/utils/dateUtils';

import DatePicker, { useDateButtonStyle } from './DatePickerPopover';
import TimeRangePicker from './TimeRangePicker';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface TimePickerProps {
  /** Whether or not this is being rendered on mobile sizes */
  isMobile?: boolean;

  value: { start: Date; end: Date };

  timezone: string;

  coalesceTimeUpdates?: boolean;

  onChange: (utcStartDateTime: string, utcEndDateTime: string) => void;

  hideClockIcons?: boolean;
}

const useStyle = makeStyles((isMobile: boolean) =>
  createStyles({
    boxCell: {
      padding: '0px 8px 0px 0px',
    },
    button: {
      borderRadius: '6px',
      width: !isMobile ? '150px' : '100%',
    },
  })
);

const DateTimeRangePicker = ({
  isMobile,
  timezone,
  value: { start, end },
  coalesceTimeUpdates = true,
  onChange,
  hideClockIcons,
}: TimePickerProps): JSX.Element => {
  const classes = useStyle(isMobile ?? false);
  const dateButtonStyle = useDateButtonStyle();
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [selectedDatePickerRef, setSelectedDatePickerRef] = useState(useRef<HTMLButtonElement>(null));

  const openDatePicker = (datePickerRef: React.RefObject<HTMLButtonElement>) => {
    setDatePickerOpen(true);
    setSelectedDatePickerRef(datePickerRef);
  };

  const handleDateChange = useCallback(
    (date: Date) => {
      const diffInMillis = differenceInMilliseconds(end, start);
      onChange(date.toISOString(), addMilliseconds(date, diffInMillis).toISOString());
    },
    [start, end, onChange]
  );

  const handleTimeRangeChange = useCallback(
    (sHour: Date, eHour: Date) => {
      onChange(sHour.toISOString(), eHour.toISOString());
    },
    [onChange]
  );

  const handleDatePickerClose = () => {
    setDatePickerOpen(false);
  };
  const datePickerRef = React.createRef<HTMLButtonElement>();

  return (
    <>
      <Box p={1} order={1} className={classes.boxCell}>
        <Button
          fullWidth={isMobile}
          className={clsx(classes.button, dateButtonStyle.button)}
          label={formatToTimeZone(start, 'MMM d yyy', timezone)}
          ref={datePickerRef}
          variant="outlined"
          size="medium"
          startIcon={<DateIcon />}
          onClick={() => openDatePicker(datePickerRef)}
        />
        <DatePicker
          utcDate={start}
          timezone={timezone}
          open={datePickerOpen}
          dateAnchorEl={selectedDatePickerRef}
          onChange={handleDateChange}
          onClose={handleDatePickerClose}
        />
      </Box>
      <Box p={1} order={2} className={classes.boxCell}>
        <TimeRangePicker
          isMobile={isMobile}
          value={{ start, end }}
          timezone={timezone}
          coalesceTimeUpdates={coalesceTimeUpdates}
          onChange={handleTimeRangeChange}
          hideClockIcons={hideClockIcons}
        />
      </Box>
    </>
  );
};

export default DateTimeRangePicker;
