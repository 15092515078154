import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { Loader, PublicError } from '@modernloop/shared/helper-components';
import { CaretRightIcon, DiamondFilled, DiamondOutline } from '@modernloop/shared/icons';
import { pluralize } from '@modernloop/shared/utils';
import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  AddMembersModal_ModuleFragment,
  EmployeeFragment,
  TrainingStatus,
  useAddMembersToModuleMutation,
} from 'src/generated/mloop-graphql';

import { getRoleToBeAdded, resetModuleMemberListCache } from 'src/entities/InterviewModuleMember/utils';

import { apolloCacheDeleteByQuery } from 'src/utils/apolloHelpers';

import InterviewModuleEmployeePicker from 'src/views-new/InterviewModuleDetailsDeprecated/Shared/InterviewModuleEmployeePicker';

type Fragments = {
  module: AddMembersModal_ModuleFragment;
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const AddMembersModal: FCWithFragments<Fragments, Props> = ({ module, open, onClose }) => {
  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeFragment[]>([]);

  const onSelectedEmployeesChange = (employees: EmployeeFragment[]) => {
    setSelectedEmployees(employees);
  };

  const [save, { loading, error }] = useAddMembersToModuleMutation();

  const { enqueueSnackbar } = useSnackbar();
  const handleSave = async (asTrained?: boolean) => {
    const members = selectedEmployees.map((employee) => {
      return {
        level: 0,
        memberId: employee.id,
        status: asTrained
          ? TrainingStatus.Trained
          : getRoleToBeAdded(module.shadowsRequired, module.reverseShadowsRequired),
        shadowRequiredCount: asTrained ? 0 : module.shadowsRequired,
        reverseShadowRequiredCount: asTrained ? 0 : module.reverseShadowsRequired,
      };
    });

    try {
      await save({
        variables: {
          input: {
            interviewModuleId: module.id,
            addMembers: members,
          },
        },
        update: (cache) => {
          apolloCacheDeleteByQuery(cache, 'interviewModule');
          resetModuleMemberListCache({
            cache,
            moduleId: module.id,
            employeeIds: selectedEmployees.map((employee) => employee.id),
          });
        },
      });
      enqueueSnackbar(`${pluralize('Interviewer', members.length)} added successfully`, { variant: 'success' });
      onClose();
    } catch (e) {
      enqueueSnackbar(`Error adding ${pluralize('interviewer', members.length)}`, { variant: 'error' });
    }
  };

  return (
    <Dialog
      title="Add interviewers"
      open={open}
      decoration={
        <img
          style={{ width: '64px', height: '64px' }}
          src="/static/images/interviewModule/zero_state_member_list.svg"
          alt="Interviewer"
        />
      }
      onClose={onClose}
      maxWidth="xs"
    >
      <Stack direction="column" spacing={2.5}>
        <Stack gap={1}>
          <Typography variant="subtitle1">Select interviewers</Typography>
          <InterviewModuleEmployeePicker
            interviewModuleId={module.id}
            placeholder={selectedEmployees.length === 0 ? 'Search people' : ''}
            onChange={onSelectedEmployeesChange}
            selectedEmployees={selectedEmployees}
          />
        </Stack>
        <Stack gap={1}>
          <Typography variant="subtitle1">Add as...</Typography>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            sx={{ borderRadius: '5px' }}
            disabled={selectedEmployees.length === 0 || loading}
            endIcon={<CaretRightIcon color="inherit" />}
            startIcon={<DiamondFilled color="inherit" />}
            onClick={() => {
              handleSave(true);
            }}
          >
            <Typography width="100%" textAlign="left" color="inherit">
              Fully trained
            </Typography>
          </Button>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            sx={{ borderRadius: '5px' }}
            disabled={selectedEmployees.length === 0 || loading}
            endIcon={<CaretRightIcon color="inherit" />}
            startIcon={<DiamondOutline color="inherit" />}
            onClick={() => {
              handleSave();
            }}
          >
            <Typography width="100%" textAlign="left" color="inherit">
              Trainee
            </Typography>
          </Button>
        </Stack>
        <PublicError error={error} />
        {loading && <Loader loading={loading} />}
      </Stack>
    </Dialog>
  );
};

export const InterviewModuleAddMembersMutation = gql`
  mutation AddMembersToModule($input: InterviewModuleAddMembersInput!) {
    interviewModuleAddMembers(input: $input) {
      interviewModule {
        id
      }
    }
  }
`;

AddMembersModal.fragments = {
  module: gql`
    fragment AddMembersModal_module on InterviewModule {
      id
      shadowsRequired
      reverseShadowsRequired
    }
  `,
};

export default AddMembersModal;
