import { useEffect, useRef } from 'react';

/**
 * This hook provides similar functionality to `prevProps` in componentWillUpdate, componentDidUpdate, etc.
 * @param value takes any value.
 * @returns returns the previous saved value of the passed in value.
 */
const usePrevious = <T>(value: T): T | null => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T | null>(null);
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export default usePrevious;
