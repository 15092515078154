/* eslint-disable max-lines */
import React, { useCallback, useState } from 'react';

import { gql } from '@apollo/client';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { getExternalErrorMessage, logError } from '@modernloop/shared/utils';
import { Box, CircularProgress, Stack, StackProps, SxProps } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  EmployeeFragmentDoc,
  ScheduleTaskDetails_TaskFragment,
  SourceType,
  TaskStatus,
  TaskTagsBase_TaskTagFragment,
  useTaskSetAssigneeMutation,
  useTaskSetIsUrgentMutation,
  useTaskSetTagsMutation,
  useTaskUpdateQueueMutation,
} from 'src/generated/mloop-graphql';

import Link from 'src/components/Link';
import Switch from 'src/components/Switch';
import Tag from 'src/components/Tag';
import UrgentTag from 'src/components/Tag/UrgentTag';
import EmployeeChip from 'src/components/chip/EmployeeChip';
import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';

import Candidate from 'src/entities/Candidate';
import TaskTags from 'src/entities/Filters/TaskTags';
import QueueSelect from 'src/entities/Select/QueueSelect/QueueSelect';
import { useSubscribersSelectRefetch } from 'src/entities/SubscribersSelect/useSubscribersSelectRefetch';
import TaskName from 'src/entities/common/TaskName';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useEmployeeId from 'src/hooks/useEmployeeId';

import { formatToTimeZone } from 'src/utils/datetime/Format';

import { NewBaseApplicationAlert } from 'src/views-new/ApplicationDetailsTaskView/ApplicationContent/ApplicationAlert';
import ApplicationConflict from 'src/views-new/ApplicationDetailsTaskView/ApplicationContent/ApplicationConflict';
import useMySchedulingTasksRefetch from 'src/views-new/ScheduleTaskDashboard/ScheduleTaskDashboardTables/useMySchedulingTasksRefetch';
import useScheduleTaskDashboardTaskSearchRefetch from 'src/views-new/ScheduleTaskDashboard/ScheduleTaskDashboardTables/useScheduleTaskDashboardTaskSearchRefetch';
import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

import ScheduleTaskAssignee from './Common/ScheduleTaskAssignee';
import ScheduleTaskFlags from './ScheduleTaskFlags';
import ScheduleTaskStatus from './ScheduleTaskStatus';
import ScheduleTaskStatusSubText from './ScheduleTaskStatusSubText';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
const ScheduleTaskDetailsRow = ({
  alignItems,
  label,
  component,
  sx,
}: {
  alignItems: StackProps['alignItems'];
  label: string | React.ReactNode;
  component: React.ReactNode | undefined;
  sx?: SxProps;
}) => {
  return (
    <Stack direction="row" alignItems={alignItems} spacing={2} sx={{ ...(sx || {}), minHeight: '28px' }}>
      {!React.isValidElement(label) && (
        <Label variant="captions" color="high-contrast-grey" fontWeight={600} style={{ flexShrink: 0, width: '80px' }}>
          {label}
        </Label>
      )}
      {React.isValidElement(label) && label}
      {component}
    </Stack>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleTaskDetailsFragmentProps = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/component-with-fragments.cjs
  task: ScheduleTaskDetails_TaskFragment;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/restric-fragments-name.cjs
const ScheduleTaskDetails: FCWithFragments<ScheduleTaskDetailsFragmentProps> = ({ task }) => {
  const [taskSetTagsMutation] = useTaskSetTagsMutation();
  const [taskSetAssigneeMutation] = useTaskSetAssigneeMutation();
  const [taskSetIsUrgentMutation] = useTaskSetIsUrgentMutation();
  const [taskUpdateQueueMutation] = useTaskUpdateQueueMutation();
  const refetchActivityFeed = useActivityFeedRefetch(SourceType.SourceTypeTask);
  const refetchSubscriberSelect = useSubscribersSelectRefetch();
  const refetchScheduleTaskDashboardTaskSearch = useScheduleTaskDashboardTaskSearchRefetch();
  const refetchMySchedulingTasks = useMySchedulingTasksRefetch();

  const employeeId = useEmployeeId();
  const { enqueueSnackbar } = useSnackbar();

  const [assigningToMe, setAssigningToMe] = useState(false);

  const handleTagsChange = useCallback(
    (tags: TaskTagsBase_TaskTagFragment[]) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/catch-or-return
      taskSetTagsMutation({
        variables: {
          input: {
            taskId: task.id,
            tagIds: tags.map((tag) => tag.id),
          },
        },
        optimisticResponse: {
          taskSetTags: {
            __typename: 'Task',
            id: task.id,
            tags,
          },
        },
      })
        .then(() => refetchActivityFeed())
        .catch((e) => {
          enqueueSnackbar(getExternalErrorMessage(e), { variant: 'error' });
        });
    },
    [enqueueSnackbar, refetchActivityFeed, task.id, taskSetTagsMutation]
  );

  const handleTaskQueueChange = useCallback(
    async (queueId) => {
      try {
        await taskUpdateQueueMutation({
          variables: {
            input: {
              taskId: task.id,
              taskQueueId: queueId,
            },
          },
        });
        refetchMySchedulingTasks();
        refetchActivityFeed();
      } catch (error) {
        logError(`Failed to update task queue ${error}`);
        enqueueSnackbar(getExternalErrorMessage(error), {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, refetchActivityFeed, refetchMySchedulingTasks, task.id, taskUpdateQueueMutation]
  );

  const handleChangeTaskUrgency = useCallback(
    (value) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/catch-or-return
      taskSetIsUrgentMutation({
        variables: { input: { taskId: task.id, isUrgent: value } },
        optimisticResponse: { taskSetIsUrgent: { id: task.id, isUrgent: value, __typename: 'Task' } },
      }).then(() => refetchActivityFeed());
    },
    [refetchActivityFeed, task.id, taskSetIsUrgentMutation]
  );

  const handleTaskSetAssignee = useCallback(() => {
    if (!employeeId) return;

    setAssigningToMe(true);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    taskSetAssigneeMutation({
      variables: {
        input: {
          taskId: task.id,
          assigneeEmployeeId: employeeId,
        },
      },
    }) // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      .then(() => {
        refetchSubscriberSelect();
        refetchScheduleTaskDashboardTaskSearch();
        refetchMySchedulingTasks();
        refetchActivityFeed();
      })
      .catch((e) => {
        enqueueSnackbar(e?.message, { variant: 'error' });
      })
      .finally(() => setAssigningToMe(false));
  }, [
    employeeId,
    enqueueSnackbar,
    refetchActivityFeed,
    refetchMySchedulingTasks,
    refetchScheduleTaskDashboardTaskSearch,
    refetchSubscriberSelect,
    task.id,
    taskSetAssigneeMutation,
  ]);

  return (
    <Stack spacing={1.5}>
      <ScheduleTaskDetailsRow
        alignItems="center"
        label={
          <Label variant="title" fontWeight={600}>
            Schedule -&nbsp;
            <TaskName task={task} />
          </Label>
        }
        component={undefined}
      />

      <ScheduleTaskDetailsRow
        alignItems="center"
        label={
          <Label
            variant="captions"
            color="high-contrast-grey"
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '4px' }}
          >
            {task.application.candidate && (
              <Candidate
                candidate={task.application.candidate}
                applicationId={task.application.id}
                onClick={() => {
                  SidePanelManager.closeSidePanel();
                }}
                variant="subtitle2"
              />
            )}
            for <strong style={{ fontWeight: 600 }}>{task.jobStage.job?.name}</strong>
            {task.jobStage.job?.location?.map((loc) => {
              return <Tag key={loc} variant="filled" color="high-contrast-grey" label={loc} size="small" />;
            })}
          </Label>
        }
        component={undefined}
        sx={{ marginTop: '4px !important' }}
      />

      <Stack>
        <NewBaseApplicationAlert status={task.application.status} />
      </Stack>

      <ApplicationConflict task={task} />

      <ScheduleTaskDetailsRow
        alignItems="baseline"
        label="Status"
        component={
          <Stack spacing={0.5}>
            <Box mt="5px">
              <ScheduleTaskStatus status={task.status} />
            </Box>
            <ScheduleTaskStatusSubText task={task} />
          </Stack>
        }
      />

      <ScheduleTaskDetailsRow
        alignItems="center"
        label="Urgent"
        component={
          <Stack direction="row" spacing={1} alignItems="center">
            <Switch
              disabled={task.status === TaskStatus.Canceled}
              checked={task.isUrgent}
              onChange={handleChangeTaskUrgency}
            />
            {task?.isUrgent && <UrgentTag size="small" />}
          </Stack>
        }
      />

      <ScheduleTaskDetailsRow
        alignItems="center"
        label="Assignee"
        component={
          <Stack direction="row" alignItems="center" spacing={0.5} sx={{ flexGrow: 1, marginLeft: '8px !important' }}>
            <ScheduleTaskAssignee
              shouldRefecthTaskSearch
              shouldRefetchMySchedulingTasks
              shouldRefetchSubscribersList
              task={task}
            />
            {assigningToMe && <CircularProgress size={20} />}
            {!task.assignee && !assigningToMe && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <Link
                color="info"
                onClick={handleTaskSetAssignee}
                variant="captions"
                fontWeight={500}
                style={{ textDecoration: 'none', flexShrink: 0 }}
              >
                Assign to me
              </Link>
            )}
          </Stack>
        }
      />
      <ScheduleTaskDetailsRow
        alignItems="center"
        label="Queue"
        component={
          <Stack direction="row" spacing={1} alignItems="center">
            <QueueSelect
              groupQueue
              onOptionChange={handleTaskQueueChange}
              defaultTaskQueue={task.application.job?.jobSettings.defaultTaskQueue}
              selectedTaskQueue={task.taskQueue}
            />
          </Stack>
        }
      />

      <ScheduleTaskDetailsRow
        label="Created by"
        alignItems="center"
        component={
          <Box sx={{ marginLeft: '8px !important' }}>
            <EmployeeChip
              name={task.createdBy.fullName || ''}
              photoSrc={task.createdBy.slackImageUrl || ''}
              variant="default"
            />
          </Box>
        }
      />

      <ScheduleTaskDetailsRow
        label="Created"
        alignItems="center"
        component={
          <Label variant="captions" color="foreground">
            {`${formatToTimeZone(task.createdAt, 'MMM d, yyyy', getLocalTimezone())}  at ${formatToTimeZone(
              task.createdAt,
              'h:mm aaa',
              getLocalTimezone()
            )}`}
          </Label>
        }
      />

      <ScheduleTaskDetailsRow
        alignItems="center"
        label="Stage"
        component={
          <Label variant="captions" color="foreground">
            {task.jobStage.name}
          </Label>
        }
      />

      <ScheduleTaskDetailsRow
        alignItems="baseline"
        label={
          <Label
            variant="captions"
            color="high-contrast-grey"
            fontWeight={600}
            style={{ flexShrink: 0, width: '80px', marginTop: '5px' }}
          >
            Tags
          </Label>
        }
        component={<TaskTags selectedTaskTags={task.tags} onChange={handleTagsChange} />}
      />

      <ScheduleTaskDetailsRow
        alignItems="flex-start"
        label={
          <Label
            variant="captions"
            color="high-contrast-grey"
            fontWeight={600}
            style={{ flexShrink: 0, width: '80px', marginTop: '5px' }}
          >
            Flags
          </Label>
        }
        component={
          <Box pt={0.5}>
            <ScheduleTaskFlags taskFlags={task.flags} />
          </Box>
        }
      />
    </Stack>
  );
};

ScheduleTaskDetails.fragments = {
  task: gql`
    ${TaskTags.fragments.selectedTaskTags}
    ${ScheduleTaskFlags.fragments.taskFlags}
    ${ScheduleTaskAssignee.fragments.task}
    ${EmployeeFragmentDoc}
    ${ScheduleTaskStatusSubText.fragments.task}
    ${TaskName.fragments.task}
    ${Candidate.fragments.candidate}
    ${QueueSelect.fragments.selectedTaskQueue}
    ${QueueSelect.fragments.defaultTaskQueue}
    ${ApplicationConflict.fragments.task}
    fragment ScheduleTaskDetails_task on Task {
      id
      isUrgent
      name
      status
      application {
        id
        job {
          id
          jobSettings {
            defaultTaskQueue {
              ...QueueSelect_defaultTaskQueue
            }
          }
        }
        status
        candidate {
          id
          fullName

          ...Candidate_candidate
        }
      }
      taskQueue {
        ...QueueSelect_selectedTaskQueue
      }
      createdBy {
        ...Employee
      }
      createdAt
      jobStage {
        id
        name
        job {
          id
          name
          location
        }
      }
      tags {
        ...TaskTags_selectedTaskTag
      }
      flags {
        ...ScheduleTaskFlags_taskFlag
      }
      ...TaskName_task
      ...ScheduleTaskAssignee_task
      ...ScheduleTaskStatusSubText_task
      ...ApplicationConflict_task
    }
  `,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TaskSetTagsMutation = gql`
  ${TaskTags.fragments.selectedTaskTags}
  mutation TaskSetTags($input: TaskSetTagsInput!) {
    taskSetTags(input: $input) {
      id
      tags {
        ...TaskTags_selectedTaskTag
      }
    }
  }
`;

export const TaskSetQueueMutation = gql`
  ${QueueSelect.fragments.selectedTaskQueue}
  mutation TaskUpdateQueue($input: TaskUpdateInput!) {
    taskUpdate(input: $input) {
      id
      taskQueue {
        ...QueueSelect_selectedTaskQueue
      }
    }
  }
`;

export default ScheduleTaskDetails;
