import { CodeLinkType } from 'src/generated/mloop-graphql';

import { ApiVerb, apiPost } from '.';

/// HackerRank API ///
interface HackerRankUrlRequest {
  title: string;
  candidateName: string;
  candidateEmail: string;
}

interface HackerRankUrlResponse {
  url: string;
}

const createHackerRankUrl = async (payload: HackerRankUrlRequest): Promise<HackerRankUrlResponse> => {
  const response = await apiPost(ApiVerb.API_CREATE_HACKERRANK, payload);
  return response.data;
};

/// Codility API ///
interface CodilityUrlRequest {
  roomName: string;
}

interface CodilityUrlResponse {
  url: string;
}

const createCodilityUrl = async (payload: CodilityUrlRequest): Promise<CodilityUrlResponse> => {
  const response = await apiPost(ApiVerb.API_CREATE_CODILITY, payload);
  return response.data;
};

/// CoderPad API ///
interface CoderPadUrlRequest {
  title: string;
}

interface CoderPadUrlResponse {
  url: string;
}

const createCoderPadUrl = async (payload: CoderPadUrlRequest): Promise<CoderPadUrlResponse> => {
  const response = await apiPost(ApiVerb.API_CREATE_CODERPAD, payload);
  return response.data;
};

/// CodeSignal API ///
interface CodeSignalUrlRequest {
  candidateName: string;
  candidateEmail: string;
}

interface CodeSignalUrlResponse {
  url: string;
}

const createCodeSignalUrl = async (payload: CodeSignalUrlRequest): Promise<CodeSignalUrlResponse> => {
  const response = await apiPost(ApiVerb.API_CREATE_CODESIGNAL, payload);
  return response.data;
};

/// Options for creating a new coding url
interface CreateCodingUrlOptions {
  candidateName: string;
  candidateEmail: string;
}

const createCodingInterviewUrl = async (type: CodeLinkType, options: CreateCodingUrlOptions): Promise<string> => {
  switch (type) {
    case CodeLinkType.Hackerrank: {
      const response = await createHackerRankUrl({
        title: options.candidateEmail,
        candidateName: options.candidateName,
        candidateEmail: options.candidateEmail,
      });
      return response.url;
    }
    case CodeLinkType.Codility: {
      const response = await createCodilityUrl({
        roomName: options.candidateName,
      });
      return response.url;
    }
    case CodeLinkType.Coderpad: {
      const response = await createCoderPadUrl({
        title: options.candidateEmail,
      });
      return response.url;
    }
    case CodeLinkType.Codesignal: {
      const response = await createCodeSignalUrl({
        candidateName: options.candidateName,
        candidateEmail: options.candidateEmail,
      });
      return response.url;
    }
    default:
      throw new Error(`Unsupported coding url type: ${type}`);
  }
};

export default createCodingInterviewUrl;
