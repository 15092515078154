import React from 'react';

import Menu, { MenuOption } from 'src/components/menu';
import { BaseProps } from 'src/components/types';

import { IS_PRODUCTION } from 'src/constants';

export type MaxDuration =
  | 5
  | 10
  | 15
  | 20
  | 25
  | 30
  | 35
  | 40
  | 45
  | 50
  | 55
  | 60
  | 75
  | 90
  | 105
  | 115
  | 120
  | 135
  | 150
  | 165
  | 180
  | 195
  | 210
  | 225
  | 240
  | 300
  | 360
  | 420
  | 480
  | 540
  | 600
  | 660
  | 720;

interface Props extends BaseProps {
  id: string;
  open: boolean;
  anchorEl: React.RefObject<HTMLElement>;
  selected?: number;
  maxDuration?: MaxDuration;
  additionalOptions?: MenuOption[];
  onSelect: (mins: number) => void;
  onClose: () => void;
}

export const DURATION_OPTIONS: MenuOption[] = [
  { id: 5, value: '5 mins' },
  { id: 10, value: '10 mins' },
  { id: 15, value: '15 mins' },
  { id: 20, value: '20 mins' },
  { id: 25, value: '25 mins' },
  { id: 30, value: '30 mins' },
  { id: 35, value: '35 mins' },
  { id: 40, value: '40 mins' },
  { id: 45, value: '45 mins' },
  { id: 50, value: '50 mins' },
  { id: 55, value: '55 mins' },
  { id: 60, value: '1 hr' },
  { id: 75, value: '1 hr 15 mins' },
  { id: 80, value: '1 hr 20 mins' },
  { id: 90, value: '1 hr 30 mins' },
  { id: 105, value: '1 hr 45 mins' },
  { id: 115, value: '1 hr 55 mins' }, // This is backfill for old data
  { id: 120, value: '2 hrs' },
  { id: 135, value: '2 hrs 15 mins' },
  { id: 150, value: '2 hrs 30 mins' },
  { id: 165, value: '2 hrs 45 mins' },
  { id: 180, value: '3 hrs' },
  { id: 195, value: '3 hrs 15 mins' },
  { id: 210, value: '3 hrs 30 mins' },
  { id: 225, value: '3 hrs 45 mins' },
  { id: 240, value: '4 hrs' },
  { id: 300, value: '5 hrs' },
  { id: 360, value: '6 hrs' },
  { id: 420, value: '7 hrs' },
  { id: 480, value: '8 hrs' },
  { id: 540, value: '9 hrs' },
  { id: 600, value: '10 hrs' },
  { id: 660, value: '11 hrs' },
  { id: 720, value: '12 hrs' },
  { id: 0, value: '1 Day' },
];

const DurationMenu = ({
  additionalOptions,
  dataTestId,
  id,
  open,
  anchorEl,
  selected,
  maxDuration,
  onSelect,
  onClose,
}: Props): JSX.Element => {
  const handleSelect = (option: MenuOption) => {
    onSelect(option.id as number);
    onClose();
  };

  let options =
    selected !== undefined
      ? [...(additionalOptions ?? []), ...DURATION_OPTIONS].map((option) => {
          if (selected === option.id) {
            return {
              ...option,
              selected: true,
            };
          }
          return option;
        })
      : [...(additionalOptions ?? []), ...DURATION_OPTIONS];

  options = maxDuration
    ? options.filter((option) => option.id && (option.id as number) <= maxDuration && option.id !== 0)
    : options;

  if (!IS_PRODUCTION) {
    options = [{ id: 1, value: '1 min [NOT-SHOWN-IN-PROD]' }, ...options];
  }

  return (
    <Menu
      id={id}
      open={open}
      dataTestId={dataTestId}
      disableScrollLock
      options={options}
      anchorEl={anchorEl.current}
      menuWidth={anchorEl.current?.clientWidth ? anchorEl.current?.clientWidth - 16 : undefined}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onSelect={handleSelect}
      onClose={onClose}
    />
  );
};

export default DurationMenu;
