import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';

import {
  AtsNotSchedulableReason,
  UseApplicationJobStageSchedulable_ApplicationFragment,
} from 'src/generated/mloop-graphql';

import useWorkdayEnabled from './atsService/useWorkdayEnabled';

type Fragments = {
  application: UseApplicationJobStageSchedulable_ApplicationFragment;
};

/**
 * JobstageId is the selected jobstage in UI
 */
type Props = {
  jobStageId: string;
};

type ReturnType = {
  isSchedulable: boolean;
  reason?: AtsNotSchedulableReason;
};

const useApplicationJobStageSchedulable: FunctionWithFragments<Fragments, Props, ReturnType> = (
  { application },
  { jobStageId }
) => {
  const isWorkdayEnabled = useWorkdayEnabled();

  /**
   * If Feature flag disabled return true , always schedulable
   */
  if (!isWorkdayEnabled) {
    return { isSchedulable: true };
  }

  /**
   * Application is only schedulable on current application job stage
   */
  if (
    application?.atsApplication?.notSchedulableReason === AtsNotSchedulableReason.NotSchedulable ||
    (!application.atsApplication?.canMoveJobStage && jobStageId !== application.jobStageId)
  ) {
    return { isSchedulable: false, reason: AtsNotSchedulableReason.NotSchedulable };
  }

  /**
   * Check if schedulable for ATS application job stage current step
   */
  if (application?.atsApplication?.notSchedulableReason === AtsNotSchedulableReason.NotSchedulableOnCurrentStep) {
    return { isSchedulable: false, reason: AtsNotSchedulableReason.NotSchedulableOnCurrentStep };
  }

  return { isSchedulable: true };
};

/** Data fetched for checking is schedulable  */
useApplicationJobStageSchedulable.fragments = {
  application: gql`
    fragment useApplicationJobStageSchedulable_application on Application {
      id
      jobStageId
      atsApplication {
        atsId
        notSchedulableReason
        canMoveJobStage
      }
    }
  `,
};
export default useApplicationJobStageSchedulable;
