import React, { ReactNode } from 'react';

import { gql } from '@apollo/client';
import { assertIsoTimestamp } from '@modernloop/shared/datetime';
import { Stack, Typography } from '@mui/material';

import {
  Maybe,
  RecjectetCandidateAvailabilitySectionAlert_AvailabilityRequestFragment,
} from 'src/generated/mloop-graphql';

import { useDurationLabel } from 'src/components/DurationLabel';
import { FCWithFragments } from 'src/components/types';

type Fragments = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/component-with-fragments.cjs
  availabilityRequest?: Maybe<RecjectetCandidateAvailabilitySectionAlert_AvailabilityRequestFragment>;
};

type Props = {
  heading: string;
  actionsJSX?: ReactNode;
};

const RecjectetCandidateAvailabilitySectionAlert: FCWithFragments<Fragments, Props> = ({
  availabilityRequest,
  heading,
  actionsJSX,
}) => {
  const durationLabel = useDurationLabel(
    availabilityRequest?.lastCandidateCommunicationSentAt
      ? assertIsoTimestamp(availabilityRequest?.lastCandidateCommunicationSentAt)
      : undefined
  );
  const updatedAtDurationLabel = useDurationLabel(
    availabilityRequest?.updatedAt ? assertIsoTimestamp(availabilityRequest?.updatedAt) : undefined
  );
  return (
    <Stack>
      <Typography color="error" variant="subtitle1">
        {heading}
      </Typography>

      {availabilityRequest?.lastCandidateCommunicationSentAt && (
        <Typography color="error" variant="caption">
          A link was sent to the candidate by {availabilityRequest?.creator?.fullName} {durationLabel}.
        </Typography>
      )}
      {!availabilityRequest?.lastCandidateCommunicationSentAt && (
        <Typography color="error" variant="caption">
          Request created {updatedAtDurationLabel} by {availabilityRequest?.creator?.fullName}.
        </Typography>
      )}
      {actionsJSX}
    </Stack>
  );
};

RecjectetCandidateAvailabilitySectionAlert.fragments = {
  availabilityRequest: gql`
    fragment RecjectetCandidateAvailabilitySectionAlert_availabilityRequest on CandidateAvailabilityOptions {
      id
      updatedAt
      lastCandidateCommunicationSentAt
      creator {
        fullName
      }
    }
  `,
};

export default RecjectetCandidateAvailabilitySectionAlert;
