import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import BaseIcon, { Props } from '../../Icon';

const CandidateRsvpDecline = ({ color, fontSize, tooltip, tiny }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} tiny={tiny}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2226 12.4818C12.5549 12.7033 13 12.4651 13 12.0657V3C13 1.89543 12.1046 1 11 1H3C1.89543 1 1 1.89543 1 3V9C1 10.1046 1.89543 11 3 11H9.09167C9.68395 11 10.263 11.1753 10.7558 11.5038L12.2226 12.4818ZM4.46967 3.46967C4.17678 3.76256 4.17678 4.23744 4.46967 4.53033L5.93934 6L4.46967 7.46967C4.17678 7.76256 4.17678 8.23744 4.46967 8.53033C4.76256 8.82322 5.23744 8.82322 5.53033 8.53033L7 7.06066L8.46967 8.53033C8.76256 8.82322 9.23744 8.82322 9.53033 8.53033C9.82322 8.23744 9.82322 7.76256 9.53033 7.46967L8.06066 6L9.53033 4.53033C9.82322 4.23744 9.82322 3.76256 9.53033 3.46967C9.23744 3.17678 8.76256 3.17678 8.46967 3.46967L7 4.93934L5.53033 3.46967C5.23744 3.17678 4.76256 3.17678 4.46967 3.46967Z"
        fill="#D7241D"
      />
    </BaseIcon>
  );
};

export default CandidateRsvpDecline;
