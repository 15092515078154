import React from 'react';

import BaseIcon, { Props } from './Icon';

const PlusIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 5.00001C10.75 4.5858 10.4142 4.25001 10 4.25001C9.58578 4.25001 9.25 4.5858 9.25 5.00001V9.25H5.00001C4.58579 9.25 4.25001 9.58579 4.25001 10C4.25001 10.4142 4.58579 10.75 5.00001 10.75H9.25V15C9.25 15.4142 9.58578 15.75 10 15.75C10.4142 15.75 10.75 15.4142 10.75 15V10.75H15C15.4142 10.75 15.75 10.4142 15.75 10C15.75 9.58579 15.4142 9.25 15 9.25H10.75V5.00001Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default PlusIcon;
