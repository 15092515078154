import { ThemeOptions } from '@mui/material';

export const dialogContent: ThemeOptions['components'] = {
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.body1,
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        padding: theme.spacing(2.5),
      }),
    },
  },
};
