/* eslint-disable max-lines */
import React, { useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { BeverageCoffeeIcon, MoonWithArchedArrowIcon, MoonWithStarsIcon } from '@modernloop/shared/icons';
import { getExternalErrorMessage } from '@modernloop/shared/utils';
import { Alert, Box, Checkbox, CircularProgress, FormControlLabel, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { formatDuration, intervalToDuration } from 'date-fns';
import { useSnackbar } from 'notistack';

import {
  BaseInterviewPlanPreview_JobStageInterviewGroupsFragment,
  InterviewPlanPreview_JobStageFragment,
  InterviewType,
  useSaveInterviewPlanToJobStageMutation,
} from 'src/generated/mloop-graphql';

import IconButton from 'src/components/IconButton';
import ImageBlock from 'src/components/ImageBlock';
import Paper from 'src/components/Paper';
import Stack from 'src/components/Stack';
import Button from 'src/components/button';
import { CrossIcon, OrderLockIcon, OrderShuffleIcon, OvernightIcon } from 'src/components/icons';
import Label from 'src/components/label';

import getSaveInterviewPlanInput from 'src/entities/InterviewPlan/getSaveInterviewPlanInput';
import useIsInterviewPlanValid, {
  IsInterviewPlanValidResult,
} from 'src/entities/InterviewPlan/useIsInterviewPlanValid';

import { updateJobStagePlanConfig } from 'src/store/actions/job-stage-plan-config';
import { getHasBreaksByJobStageId, getShouldRememberByJobStageId } from 'src/store/selectors/job-stage-plan-config';

import { Theme as ThemeV5 } from 'src/themeMui5/type';

import { isBreak } from 'src/utils/interview';
import logError from 'src/utils/logError';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';
import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useDispatch, useSelector } from 'src/store';
import { DEFAULT_INTERVIEW_NAME } from 'src/strings';

import useFetchInterviewResponseStatus, { InterviewerDeclinedResultType } from './UseFetchInterviewResponseStatus';
import useSubmitInterviewPlan from './useSubmitInterviewPlan';

type Props = {
  error: string | null;
  hasBreaks: boolean;
  shouldRemember: boolean;
  schedulable: boolean;
  submitting: boolean;
  scheduleFlowType: ScheduleFlowType;
  interviewerDeclinedResult: InterviewerDeclinedResultType;
  isInterviewPlanValidResult: IsInterviewPlanValidResult;
  clearError: () => void;
  setShouldSave: (save: boolean) => void;
  findSchedulesClicked: () => void;
  saveInterviewPlan: () => Promise<void>;
};

type Fragments = {
  jobStageInterviewGroups: BaseInterviewPlanPreview_JobStageInterviewGroupsFragment[];
};

const useSxProps = () => {
  return useMemo(() => {
    return {
      paper: {
        backgroundColor: (theme: ThemeV5) => theme.palette.background.alternate,
      },
    };
  }, []);
};

export const BaseInterviewPlanPreview: FCWithFragments<Fragments, Props> = ({
  jobStageInterviewGroups,
  error,
  hasBreaks,
  shouldRemember,
  schedulable,
  scheduleFlowType,
  interviewerDeclinedResult,
  isInterviewPlanValidResult,
  submitting,
  clearError,
  setShouldSave,
  findSchedulesClicked,
  saveInterviewPlan,
}): JSX.Element => {
  const sxProps = useSxProps();
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState(false);
  const orgBreakImprovementsEnabled = useFlag('org_break_improvements');

  const { loading: interviewerDeclinedLoading, error: interviewerDeclinedError } = interviewerDeclinedResult;

  const getDurationText = (duration: number) => {
    const start = Date.now();
    const end = start + duration * 60 * 1000;
    return formatDuration(intervalToDuration({ end, start }));
  };

  const interviews = jobStageInterviewGroups.map((group) => group.jobStageInterviews || []).flat();

  const getTooltipWrapper = (tooltip: string, item: JSX.Element) => (
    <Tooltip title={tooltip} disableInteractive>
      <Box>{item}</Box>
    </Tooltip>
  );

  return (
    <Stack direction="column" spacing={2}>
      <Paper sx={sxProps.paper}>
        <Stack direction="column" wrap="nowrap" spacing={2}>
          <Label variant="title">Setup</Label>
          {schedulable && (
            <Button
              label="Next: Find schedules"
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                submitting ||
                interviews.length === 0 ||
                (scheduleFlowType === ScheduleFlowType.RESCHEDULE &&
                  (interviewerDeclinedLoading || Boolean(interviewerDeclinedError))) ||
                !isInterviewPlanValidResult.isValid
              }
              onClick={findSchedulesClicked}
              endIcon={submitting ? <CircularProgress size={20} sx={{ marginLeft: '4px' }} /> : undefined}
            />
          )}
          {!isInterviewPlanValidResult.isValid && <Alert severity="warning">{isInterviewPlanValidResult.error}</Alert>}
          {!schedulable && (
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              label="Save"
              disabled={saving}
              endIcon={saving ? <CircularProgress size={16} /> : undefined}
              onClick={() => {
                setSaving(true);
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line promise/catch-or-return
                saveInterviewPlan() // TODO: Fix this the next time the file is edited.
                  // eslint-disable-next-line promise/always-return
                  .then(() => {
                    enqueueSnackbar('Interview plan saved', { variant: 'success' });
                  })
                  .catch((interviewPlanMutationError) => {
                    logError(interviewPlanMutationError);
                    enqueueSnackbar('Error occured while saving interview plan', { variant: 'error' });
                  })
                  .finally(() => {
                    setSaving(false);
                  });
              }}
            />
          )}
          {error && (
            <Alert
              severity="error"
              action={
                <IconButton onClick={clearError}>
                  <CrossIcon fontSize={16} />
                </IconButton>
              }
            >
              {error}
              {scheduleFlowType === ScheduleFlowType.RESCHEDULE &&
                interviewerDeclinedError &&
                interviewerDeclinedError.message}
            </Alert>
          )}
          {hasBreaks && (
            <Typography
              variant="caption"
              sx={{ backgroundColor: (theme) => theme.palette.background.info, borderRadius: '6px', padding: '8px' }}
            >
              Additional breaks may be added to avoid conflicts.
            </Typography>
          )}
          <Label variant="captions" fontWeight={600}>
            Sample schedule
          </Label>
          {interviews.map((interview, index) => {
            if (
              interview.interviewType === InterviewType.AnyTimeLater ||
              (interview.interviewType === InterviewType.Break && interview.duration === 0) ||
              (index === interviews.length - 1 && interview.interviewType !== InterviewType.Interview)
            ) {
              return null;
            }

            const isInterviewBreak = isBreak(interview.interviewType);
            let durationText = getDurationText(interview.duration || 0);

            const icons = [] as JSX.Element[];
            if (interview.interviewType === InterviewType.Break) {
              durationText = `${durationText} break`;
              icons.push(getTooltipWrapper('Break', <BeverageCoffeeIcon />));
            } else if (interview.interviewType === InterviewType.NextDay) {
              durationText = 'Then, the next day';
              icons.push(getTooltipWrapper('Then, the next day', <MoonWithStarsIcon />));
            } else if (interview.interviewType === InterviewType.DayDivider) {
              if (orgBreakImprovementsEnabled) {
                durationText = 'Then, the next day or later';
                icons.push(getTooltipWrapper('Then, the next day or later', <MoonWithArchedArrowIcon />));
              } else {
                durationText = '1 day break';
                icons.push(getTooltipWrapper('Day break', <OvernightIcon />));
              }
            }

            if (interview.interviewType === InterviewType.Interview) {
              if (interview.isLockedOrder) {
                icons.push(getTooltipWrapper('Locked position', <OrderLockIcon />));
              } else {
                icons.push(getTooltipWrapper('Shuffle position', <OrderShuffleIcon />));
              }
            }

            const iconWrap = icons.map((img, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={i}>{img}</Grid>
            ));

            return (
              <ImageBlock
                key={interview.id}
                image={
                  <Grid container spacing={0.5}>
                    {iconWrap}
                  </Grid>
                }
                title={isInterviewBreak ? durationText : interview.name ?? DEFAULT_INTERVIEW_NAME}
                caption={isInterviewBreak ? undefined : durationText}
                alignItems={isInterviewBreak ? 'center' : undefined}
              />
            );
          })}
        </Stack>
      </Paper>
      {schedulable && scheduleFlowType === ScheduleFlowType.SCHEDULE && (
        <FormControlLabel
          disabled={submitting}
          control={<Checkbox checked={shouldRemember} color="primary" />}
          label={
            <Typography py={0.5} color={submitting ? 'text.disabled' : undefined} variant="body2">
              Remember setup of this job stage plan for future candidates
            </Typography>
          }
          onChange={() => setShouldSave(!shouldRemember)}
        />
      )}
    </Stack>
  );
};

BaseInterviewPlanPreview.fragments = {
  jobStageInterviewGroups: gql`
    fragment BaseInterviewPlanPreview_jobStageInterviewGroups on JobStageInterviewGroup {
      id
      jobStageInterviews {
        id
        name
        interviewType
        duration
        isLockedOrder
      }
    }
  `,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type InterviewPlanPreviewProps = {
  jobStageId: string;
  schedulable: boolean;
  scheduleFlowType: ScheduleFlowType;
  gotoNextStep?: () => void;
};

type InterviewPlanPreviewFragments = {
  jobStage: InterviewPlanPreview_JobStageFragment;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/restric-fragments-name.cjs
const InterviewPlanPreview: FCWithFragments<InterviewPlanPreviewFragments, InterviewPlanPreviewProps> = ({
  jobStage,
  jobStageId,
  schedulable,
  scheduleFlowType,
  gotoNextStep,
}): JSX.Element => {
  const dispatch = useDispatch();
  const hasBreaks = useSelector((state) => getHasBreaksByJobStageId(state, jobStageId));
  const shouldRemember = useSelector((state) => getShouldRememberByJobStageId(state, jobStageId));

  const [saveInterviewPlanMutation] = useSaveInterviewPlanToJobStageMutation();
  const { jobStageId: baseJobStageId } = useScheduleFlowData();

  const submitInterviewPlan = useSubmitInterviewPlan({ interviewPlan: jobStage }, { increment: true });

  const [responseStatuses, interviewerDeclinedResult] = useFetchInterviewResponseStatus(scheduleFlowType);
  const isInterviewPlanValidResult = useIsInterviewPlanValid({ jobStage }, {});

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleShouldSave = (save: boolean) => {
    dispatch(updateJobStagePlanConfig({ jobStageId, shouldRemember: save }));
  };

  const handleSaveInterviewPlan = async () => {
    const interviewPlanInput = getSaveInterviewPlanInput(
      { interviewPlan: jobStage },
      { finalJobStageId: baseJobStageId, generateNewIds: true }
    );
    await saveInterviewPlanMutation({ variables: { input: interviewPlanInput } });
  };

  const handleFindSchedules = async () => {
    try {
      setSubmitting(true);
      await submitInterviewPlan({ interviewersResponseStatuses: responseStatuses });
      setSubmitting(false);
      if (gotoNextStep) gotoNextStep();
    } catch (e) {
      setError(getExternalErrorMessage(e));
      setSubmitting(false);
    }

    if (shouldRemember) {
      handleSaveInterviewPlan();
    }
  };

  const handleClearError = () => {
    setError(null);
  };

  return (
    <BaseInterviewPlanPreview
      jobStageInterviewGroups={jobStage.jobStageInterviewGroups || []}
      hasBreaks={hasBreaks}
      shouldRemember={shouldRemember}
      schedulable={schedulable}
      error={error}
      submitting={submitting}
      scheduleFlowType={scheduleFlowType}
      interviewerDeclinedResult={interviewerDeclinedResult}
      isInterviewPlanValidResult={isInterviewPlanValidResult}
      clearError={handleClearError}
      setShouldSave={handleShouldSave}
      findSchedulesClicked={handleFindSchedules}
      saveInterviewPlan={handleSaveInterviewPlan}
    />
  );
};

InterviewPlanPreview.fragments = {
  jobStage: gql`
    ${getSaveInterviewPlanInput.fragments.interviewPlan}
    ${useSubmitInterviewPlan.fragments.interviewPlan}
    ${useIsInterviewPlanValid.fragments.jobStage}
    ${BaseInterviewPlanPreview.fragments.jobStageInterviewGroups}
    fragment InterviewPlanPreview_jobStage on JobStage {
      id
      jobStageInterviewGroups {
        id
        ...BaseInterviewPlanPreview_jobStageInterviewGroups
      }
      ...getSaveInterviewPlanInput_interviewPlan
      ...useIsInterviewPlanValid_jobStage
      ...useSubmitInterviewPlan_interviewPlan
    }
  `,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SaveInterviewPlanToJobStage = gql`
  mutation SaveInterviewPlanToJobStage($input: JobStageInterviewPlanUpsertInput!) {
    jobStageInterviewPlanUpsert(input: $input) {
      jobStage {
        id
      }
    }
  }
`;

export default InterviewPlanPreview;
