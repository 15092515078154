import { useContext } from 'react';
import { generatePath } from 'react-router';

import { gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';

import { useGoogleIntegrationHealthCheckQuery } from 'src/generated/mloop-graphql';

import { Routes } from 'src/constants/routes';

import { OnboardingContext } from 'src/contexts/OnboardingProvider';

import useCurrentRoute from 'src/hooks/useCurrentRoute';
import useEmployeeId from 'src/hooks/useEmployeeId';
import useOrgId from 'src/hooks/useOrgId';

import { DefaultReturnType } from './constants';
import { IntegrationGrantType, ReturnType } from './types';
import { getRedirectParams, isIgnoredRoutes } from './utils';

export const GoogleIntegration = gql`
  query GoogleIntegrationHealthCheck {
    integrationCheckHealth(input: { force: false }) {
      google {
        canSendGmail
      }
    }
    thisOrg {
      id
      integrations {
        google {
          adminUserId
        }
      }
    }
    thisEmployee {
      id
      integrations {
        google {
          active
        }
      }
    }
  }
`;

/**
 * Hook to check if Google user has granted access to gmail.send permission,
 * when we don't have permission to send email on their behalf either as part of delegated auth or Google workspace app
 * If not return redirect params to naviagte to Google workspace app grant access screen
 */
const useGmailGrantAccess = (): ReturnType => {
  const currentRoute = useCurrentRoute();
  const isGoogleWorkspaceAppUserOauthBlockEnabled = useFlag('google-workspace-app-user-auth-block');

  const orgId = useOrgId();
  const employeeId = useEmployeeId();

  const onboardingContext = useContext(OnboardingContext);

  const { data, loading, error } = useGoogleIntegrationHealthCheckQuery({
    skip: !orgId || !employeeId || !isGoogleWorkspaceAppUserOauthBlockEnabled,
    context: {
      batch: false,
    },
  });

  if (
    loading ||
    error ||
    isIgnoredRoutes(currentRoute) ||
    !isGoogleWorkspaceAppUserOauthBlockEnabled ||
    onboardingContext.isDirectoryOAuthModalDismissed
  ) {
    return DefaultReturnType;
  }

  if (
    data?.integrationCheckHealth?.google &&
    !data.integrationCheckHealth.google.canSendGmail &&
    data.thisOrg?.integrations?.google?.adminUserId &&
    !data?.thisEmployee?.integrations?.google?.active
  ) {
    const pathname = generatePath(Routes.GrantAccess, {
      integrationType: IntegrationGrantType.Gmail,
    });
    return getRedirectParams(pathname, currentRoute);
  }

  return DefaultReturnType;
};

export default useGmailGrantAccess;
