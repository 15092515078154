import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { FC } from 'react';
import ReactQuill, { Quill } from 'react-quill';

import { SxProps } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useDebouncedCallback } from 'use-debounce';

import Paper from 'src/components/Paper';
import Label from 'src/components/label';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/themeMui5/type';

import CustomLinkSanitizer from './CustomLinkSanitizer';
import registerQuillModules from './registerQuillModules';
import useQuillEditorModules from './useQuillEditorModules';
import { useQuillEditorSxProps } from './useQuillEditorStyles';

type Ref = React.MutableRefObject<ReactQuill | null>;
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type QuillEditorProps = BaseProps &
  Omit<React.ComponentProps<typeof ReactQuill>, 'value' | 'defaultValue'> & {
    quillRef?: Ref;
    value?: string;
    defaultValue?: string;
    editorHeight?: number;
    debounceTimeout?: number;
    sx?: SxProps<Theme>;
  };

// Timeout interval to wait when a user is typing fast and prevent updating the components to frequently.
export const DEFAULT_DEBOUNCE_TIMEOUT_MS = 250;

// Need to register all the quill modules here to get correct behavior
registerQuillModules();

// See issue here: https://github.com/quilljs/parchment/issues/87
// if a <br> gets in the beginning or end of a node ex `<em><br>foobar</em>` quill will fail
const makeValueSafe = (value: string | null): string => {
  if (!value) return ''; // must return a string
  return (
    value
      // turn <div><br>Text<div> to <div>&nbsp;<br>Text<div> unless it's <div><br></div>
      .replaceAll(/><br>/g, '>&nbsp;<br>')
      .replaceAll(/>&nbsp;<br></g, '><br><')
      // turn <div>Text<br><div> to <div>Text<br>&nbsp;<div> unless it's <div><br></div>
      .replaceAll(/<br></g, '<br>&nbsp;<')
      .replaceAll(/><br>&nbsp;</g, '><br><')
  );
};

// We are using <div> going forward to text blocks
// <div>s are single spaced, <p> are double.
// We need to add a new line when converting from <p>
const convertFromPTagToDivTag = (value: string | null): string => {
  if (!value) return ''; // must return a string
  return value
    .replaceAll(/<\/p>/g, '</div><div><br></div>') // end tags, add a newline
    .replaceAll(/<p>/g, '<div>'); // start tags
};

export const useReactQuillRef = (): Ref | undefined => {
  return useRef<ReactQuill | null>(null);
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const QuillEditor: FC<QuillEditorProps> = ({
  dataTestId,
  sx,
  value,
  defaultValue,
  editorHeight,
  quillRef,
  debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT_MS,
  onChange,
  modules: propModules,
  ...rest
}) => {
  const sxProps = useQuillEditorSxProps({ height: editorHeight });
  const modules = useQuillEditorModules(quillRef);
  const newValue = makeValueSafe(convertFromPTagToDivTag(value || ''));
  const newDefaultValue =
    defaultValue === undefined ? undefined : makeValueSafe(convertFromPTagToDivTag(defaultValue || ''));
  const [keyRef, setKeyRef] = useState<number>(0);
  const contentRef = useRef<string | undefined>(newValue);
  const fallback = () => <Label variant="captions">Something went wrong loading this content :(</Label>;

  // Since we are using ReactQuill in "uncontrolled" mode by passing defaultValue, we have this
  // useEffect in cases where we need to reset the content (likely due to placeholder token values being filled)
  // It does this by updating the key which causes ReactQuill to do a full remount, updating it's content to
  // the value passed in.
  useEffect(() => {
    // if the `value` prop is same as the string value passed to onChange then we can skip re-render.
    if (value === contentRef.current) return;

    // Otherwise, set the current content to the passed in value and update the keyRef to cause a re-render
    // Setting currentContent here prevents maxDepth exceeded
    contentRef.current = value;
    setKeyRef(keyRef + 1);
  }, [value, keyRef]);

  const handleChange = (...args: Parameters<Exclude<typeof onChange, undefined>>) => {
    if (!onChange) return;

    // Update current content ref so it always has latest user input content.
    if (args && args.length) {
      const content = args[0];
      contentRef.current = content;
    }
    onChange(...args);
  };

  const debouncedOnChange = useDebouncedCallback(handleChange, debounceTimeout || DEFAULT_DEBOUNCE_TIMEOUT_MS);

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag('text', value?.substring(0, 500) || '<EMPTY>');
      }}
      fallback={fallback}
    >
      <Paper
        sx={useMemo(() => ({ ...sxProps.root, ...(sx || {}) }), [sx, sxProps.root])}
        size="medium"
        color="default"
        disablePadding
        dataTestId={dataTestId}
      >
        <ReactQuill
          {...rest}
          key={keyRef}
          defaultValue={newDefaultValue ?? newValue ?? undefined}
          ref={quillRef}
          modules={propModules ?? modules}
          theme="snow"
          onChange={debounceTimeout ? debouncedOnChange : handleChange}
        />
      </Paper>
    </Sentry.ErrorBoundary>
  );
};

/** * Code Patch start ** */
// https://github.com/quilljs/quill/issues/2096

Quill.register(CustomLinkSanitizer);

const Inline = Quill.import('blots/inline');
class CustomColor extends Inline {
  constructor(domNode, value) {
    super(domNode, value);

    // Map <font> properties
    domNode.style.color = domNode.color;

    const span = this.replaceWith(new Inline(Inline.create()));

    span.children.forEach((child) => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    this.remove();

    return span;
  }
}

CustomColor.blotName = 'customColor';
CustomColor.tagName = 'FONT';

Quill.register(CustomColor, true);
/** * Code Patch end ** */

QuillEditor.displayName = 'QuillEditor';
export default QuillEditor;
