import React, { useMemo } from 'react';

import { CircularProgress, DialogActions as MuiDialogActions, Stack } from '@mui/material';

import Button from 'src/components/button';
import { Props as IconProps } from 'src/components/icons/Icon';
import { IconType, getIconFromEnum } from 'src/components/utils/icons';

import useFullScreen from './useFullScreen';

/**
 * The options for the submit and cancels bottoms in the footer
 */
export type ButtonOptions = {
  dataTestId?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isDangerous?: boolean;
  label?: string;
  tooltip?: string;
  startIcon?: IconType;
  startIconProps?: IconProps;
  endIcon?: IconType;
  endIconProps?: IconProps;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type DialogActionsProps = {
  /**
   * The options for the submit button in the bottom right
   * If this is not provided, the dialog will not have a submit button in the bottom right
   */
  submitOptions?: ButtonOptions;

  /**
   * The options for the secondary submit button in the bottom right
   * If this is not provided, the dialog will not have a second button in the bottom right
   * This will only display if the submit button is also provided
   */
  secondaryOptions?: ButtonOptions;

  /**
   * The options for the cancel button in the bottom left
   * If this is not provided, the dialog will not have a cancel button in the bottom left
   *
   * There is always a close icon button in the top right of the modal
   */
  cancelOptions?: ButtonOptions;
};

const useSxProps = () => {
  return useMemo(() => {
    return {
      root: {
        borderRadius: '0 0 12px 12px',
        position: 'sticky',
        bottom: 0,
        padding: '20px',
        paddingLeft: '12px',
        width: '100%',
        backdropFilter: 'blur(4px)',
        borderTop: (theme) => `1px solid ${theme.grey.alpha.min}`,
        backgroundColor: (theme) => theme.palette.background.header,
      },
      fullScreenActionStyles: {
        flexGrow: 1,
        position: 'relative',
        paddingLeft: '20px',
        flexDirection: 'column-reverse',
        justifyContent: 'flex-end',
      },
      fullScreenButtonGroupStyles: {
        width: '100%',
        marginBottom: '8px',
      },
    };
  }, []);
};

const DialogActions = ({ submitOptions, secondaryOptions, cancelOptions }: DialogActionsProps) => {
  const sxProps = useSxProps();
  const isFullScreen = useFullScreen();

  // Primary button
  const shouldShowSubmitButton = !!submitOptions;
  const submitWordingToUse = submitOptions?.label ?? 'Submit';

  // Secondary button
  const shouldShowSecondaryButton = !!secondaryOptions;
  const secondaryButtonWordingToUse = secondaryOptions?.label ?? 'Optional';

  // Close button
  const shouldShowCloseButton = !!cancelOptions;
  const closeWordingToUse = cancelOptions?.label ?? 'Close';

  const shouldShowActions = shouldShowSubmitButton || shouldShowCloseButton || shouldShowSecondaryButton;

  if (!shouldShowActions) return null;

  let actionsJustify = 'space-between';
  if (shouldShowSubmitButton && !shouldShowCloseButton) {
    actionsJustify = 'flex-end';
  } else if (!shouldShowSubmitButton && shouldShowCloseButton) {
    actionsJustify = 'flex-start';
  }

  return (
    <MuiDialogActions
      sx={{
        ...sxProps.root,
        justifyContent: actionsJustify,
        ...(isFullScreen ? sxProps.fullScreenActionStyles : {}),
      }}
    >
      {shouldShowCloseButton && (
        <Button
          dataTestId={cancelOptions?.dataTestId}
          style={{
            padding: '5px 20px',
            whiteSpace: 'nowrap',
          }}
          disabled={cancelOptions?.isDisabled}
          variant="unstyled"
          onClick={cancelOptions?.onClick}
          fullWidth={isFullScreen}
          tooltip={cancelOptions?.tooltip}
          startIcon={
            cancelOptions.startIcon ? getIconFromEnum(cancelOptions.startIcon, cancelOptions.startIconProps) : undefined
          }
          endIcon={
            // eslint-disable-next-line no-nested-ternary
            cancelOptions?.isLoading ? (
              <CircularProgress size={20} />
            ) : cancelOptions.endIcon ? (
              getIconFromEnum(cancelOptions.endIcon, cancelOptions.endIconProps)
            ) : undefined
          }
        >
          {closeWordingToUse}
        </Button>
      )}
      {shouldShowSecondaryButton && shouldShowSubmitButton && (
        <Stack
          sx={isFullScreen ? sxProps.fullScreenButtonGroupStyles : undefined}
          direction={isFullScreen ? 'column-reverse' : 'row'}
          spacing={1}
        >
          <Button
            style={{
              padding: '5px 20px',
            }}
            disabled={secondaryOptions?.isDisabled}
            onClick={secondaryOptions?.onClick}
            variant="outlined"
            fullWidth={isFullScreen}
            tooltip={secondaryOptions?.tooltip}
            startIcon={
              secondaryOptions.startIcon
                ? getIconFromEnum(secondaryOptions.startIcon, secondaryOptions.startIconProps)
                : undefined
            }
            endIcon={
              // eslint-disable-next-line no-nested-ternary
              secondaryOptions?.isLoading ? (
                <CircularProgress size={20} />
              ) : secondaryOptions.endIcon ? (
                getIconFromEnum(secondaryOptions.endIcon, secondaryOptions.endIconProps)
              ) : undefined
            }
          >
            {secondaryButtonWordingToUse}
          </Button>
          <Button
            dataTestId={submitOptions?.dataTestId}
            style={{
              padding: '5px 20px',
            }}
            disabled={submitOptions?.isDisabled}
            variant="contained"
            color={submitOptions?.isDangerous ? 'error' : 'info'}
            onClick={submitOptions?.onClick}
            fullWidth={isFullScreen}
            tooltip={submitOptions?.tooltip}
            startIcon={
              submitOptions.startIcon
                ? getIconFromEnum(submitOptions.startIcon, submitOptions.startIconProps)
                : undefined
            }
            endIcon={
              // eslint-disable-next-line no-nested-ternary
              submitOptions?.isLoading ? (
                <CircularProgress size={20} />
              ) : submitOptions.endIcon ? (
                getIconFromEnum(submitOptions.endIcon, submitOptions.endIconProps)
              ) : undefined
            }
          >
            {submitWordingToUse}
          </Button>
        </Stack>
      )}
      {!shouldShowSecondaryButton && shouldShowSubmitButton && (
        <Button
          dataTestId={submitOptions?.dataTestId}
          style={{
            padding: '5px 20px',
            marginBottom: isFullScreen ? '8px' : undefined,
          }}
          disabled={submitOptions?.isDisabled}
          variant="contained"
          color={submitOptions?.isDangerous ? 'error' : 'info'}
          onClick={submitOptions?.onClick}
          fullWidth={isFullScreen}
          tooltip={submitOptions?.tooltip}
          startIcon={
            submitOptions.startIcon ? getIconFromEnum(submitOptions.startIcon, submitOptions.startIconProps) : undefined
          }
          endIcon={
            // eslint-disable-next-line no-nested-ternary
            submitOptions?.isLoading ? (
              <CircularProgress size={20} />
            ) : submitOptions.endIcon ? (
              getIconFromEnum(submitOptions.endIcon, submitOptions.endIconProps)
            ) : undefined
          }
        >
          {submitWordingToUse}
        </Button>
      )}
    </MuiDialogActions>
  );
};

export default DialogActions;
