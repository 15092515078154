import { AxiosInstance } from 'axios';
import LogRocket from 'logrocket';

import axiosInterceptorRequestAddJWT from './axiosInterceptorRequestAddJWT';
import axiosInterceptorResponseError from './axiosInterceptorResponseError';
import axiosRetry from './axiosRetry';
import axioxInterceptorMlTraceId from './axioxInterceptorMlTraceId';

type SetupOptions = {
  retries: number;
};

const setupAxiosInstance = (axiosInstance: AxiosInstance, options: SetupOptions) => {
  LogRocket.getSessionURL((sessionUrl) => {
    if (axiosInstance.defaults?.headers?.common) {
      axiosInstance.defaults.headers.common['x-user-session-url'] = sessionUrl;
    }
  });

  axiosRetry(axiosInstance, options.retries);
  axiosInstance.defaults.timeoutErrorMessage = `Sorry, it seems like something's taking longer than usual. Please refresh the page and try again.`;
  axiosInstance.interceptors.request.use(axiosInterceptorRequestAddJWT);
  axiosInstance.interceptors.request.use(axioxInterceptorMlTraceId);
  axiosInstance.interceptors.response.use(
    (successRes) => successRes,
    axiosInterceptorResponseError('client_axios_error')
  );

  return axiosInstance;
};

export default setupAxiosInstance;
