import type { Timezone } from './timezones';
import moment from 'moment-timezone';

const getTZAbbr = (tz: string | Timezone): string => {
  switch (tz) {
    case 'America/Los_Angeles':
    case 'America/Vancouver':
      return 'PT';
    case 'America/Denver':
    case 'America/Edmonton':
      return 'MT';
    case 'America/Chicago':
    case 'America/Winnipeg':
      return 'CT';
    case 'America/New_York':
    case 'America/Toronto':
    case 'America/Indiana/Indianapolis':
      return 'ET';
    case 'Asia/Singapore':
    case 'Singapore':
      return 'SGT';
    case 'Europe/Minsk':
      return 'MSK';
    case 'America/Argentina/Buenos_Aires':
    case 'America/Argentina/Catamarca':
    case 'America/Argentina/Cordoba':
    case 'America/Argentina/La_Rioja':
    case 'America/Argentina/Mendoza':
    case 'America/Argentina/Salta':
    case 'America/Argentina/San_Juan':
    case 'America/Argentina/Tucuman':
    case 'America/Argentina/Ushuaia':
      return 'ART';
    case 'America/Araguaina':
    case 'America/Bahia':
    case 'America/Belem':
    case 'America/Fortaleza':
    case 'America/Recife':
    case 'America/Sao_Paulo':
      return 'BRT';
    case 'Asia/Kuala_Lumpur':
      return 'MYT';
    case 'Asia/Dubai':
      return 'GST';
    default: {
      try {
        const abbr = moment().tz(tz).zoneAbbr();
        if (abbr.includes('+') || abbr.includes('-')) {
          return `GMT${abbr}`; // if we have a number based timezone, return GMT + offset
        }
        return abbr;
      } catch (e) {
        // We have seen that in some cases, the timezone is undefined which causes moment().tz() to return undefined.
        return tz;
      }
    }
  }
};

export default getTZAbbr;
