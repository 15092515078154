import React from 'react';

import Dialog from 'src/components/Dialog';
import Label from 'src/components/label';

type Props = {
  onClose: () => void;
};

const AvailabilityRequestedModal = ({ onClose }: Props) => {
  return (
    <Dialog isOpen onClose={onClose} title="Availability request pending">
      <Label fontWeight={400}>
        There is an availability request already out to this candidate. To enable self-schedule, please close your
        availability request.
      </Label>
    </Dialog>
  );
};

export default AvailabilityRequestedModal;
