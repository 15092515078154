import React from 'react';

import { Stack, SxProps } from '@mui/material';

import Label from 'src/components/label';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type DialogTitleProps = {
  /**
   * The decoration of the dialog (avatar, icon, etc)
   * TODO: Add Zach's new icons as IconType and have this be a Union type
   */
  decoration?: JSX.Element;

  /**
   * The title of the dialog
   */
  title?: string;

  /**
   * The subtitle of the dialog
   */
  subTitle?: string;

  sx?: SxProps;
};

const DialogTitle = ({ title, subTitle, decoration, sx }: DialogTitleProps) => {
  return (
    <Stack spacing={0} sx={sx || { padding: '28px 0px 20px' }}>
      {Boolean(decoration) && <div style={{ marginTop: '-28px', marginBottom: '12px' }}>{decoration}</div>}

      {title && <Label variant="title">{title}</Label>}
      {subTitle && (
        <Label style={{ marginTop: '2px' }} variant="body" color="high-contrast-grey">
          {subTitle}
        </Label>
      )}
    </Stack>
  );
};

export default DialogTitle;
