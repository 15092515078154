import { addMinutes, isAfter, parseISO } from 'date-fns';

import { JobStageInterview } from 'src/store/slices/job-stage-interview';

import { isBreak, isDayBreak } from 'src/utils/interview';

import { State } from 'src/store';

import { getJobStageById } from './job-stage';

export const getJobStageInterviewById = (state: State, jobStageInterviewId: string): JobStageInterview =>
  state.jobStageInterview.byId[jobStageInterviewId];

export const getAllJobStageInterviewByJobStageId = (state: State, jobStageId: string): JobStageInterview[] => {
  const jobStage = state.jobStage.byId[jobStageId];

  if (!jobStage) return [];

  return jobStage.jobStageInterviewGroupIds
    .map((groupId) => {
      const group = state.jobStageInterviewGroup.byId[groupId];

      return group.jobStageInterviewIds.map((interviewId) => {
        return state.jobStageInterview.byId[interviewId];
      });
    })
    .flat();
};

export const getJobStageInterviewErrorById = (state: State, jobStageInterviewId: string): string =>
  state.jobStageInterview.errorById[jobStageInterviewId];

export const getJobStageInterviewLoadingById = (state: State, jobStageInterviewId: string): boolean =>
  state.jobStageInterview.loadingById[jobStageInterviewId];

export const getLinkableInterviewById = (state: State, jobStageInterviewId: string): JobStageInterview[] => {
  const { jobStageInterviewGroupIds } = getJobStageById(
    state,
    state.jobStageInterview.byId[jobStageInterviewId].jobStageId
  );

  const linkableInterviewIds = Object.keys(state.jobStageInterview.byId).filter(
    (id) =>
      id !== jobStageInterviewId &&
      state.jobStageInterview.byId[id].jobStageId === state.jobStageInterview.byId[jobStageInterviewId].jobStageId &&
      jobStageInterviewGroupIds.includes(state.jobStageInterview.byId[id].jobStageGroupId) // Check that interview are in jobstage groups
  );
  const linkableInterviews: JobStageInterview[] = [];

  linkableInterviewIds.forEach((id) => {
    const interview = state.jobStageInterview.byId[id];

    if (isBreak(interview.type)) return;
    if (isDayBreak(interview.type)) return;
    if (!interview.name) return;

    linkableInterviews.push(interview);
  });

  return linkableInterviews;
};

export const getInterviewEndTimeByJobStageId = (state: State, jobStageId: string): Date | null => {
  const interviews = getAllJobStageInterviewByJobStageId(state, jobStageId);

  let endTime: Date | null = null;
  interviews.forEach((interview) => {
    if (!interview.forcedStartAt) return;

    if (!endTime) {
      endTime = parseISO(interview.forcedStartAt);
    }

    const interviewEndTime = addMinutes(parseISO(interview.forcedStartAt), interview.duration);

    if (isAfter(interviewEndTime, endTime)) {
      endTime = interviewEndTime;
    }
  });

  return endTime;
};
