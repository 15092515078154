import { combineReducers } from '@reduxjs/toolkit';

import { reducer as fileUploadReducer } from 'src/slices/files';
import { reducer as persistReducer } from 'src/slices/persist';
import { reducer as schedulingReducer } from 'src/slices/scheduling';

import { reducer as applicationReducer } from 'src/store/slices/application';
import { reducer as candidateReducer } from 'src/store/slices/candidate';
import { reducer as conflictsReducer } from 'src/store/slices/conflicts';
import { reducer as debriefReducer } from 'src/store/slices/debrief';
import { reducer as jobStageReducer } from 'src/store/slices/job-stage';
import { reducer as jobStageInterviewReducer } from 'src/store/slices/job-stage-interview';
import { reducer as jobStageInterviewGroupReducer } from 'src/store/slices/job-stage-interview-group';
import { reducer as jobStageInterviewSeatReducer } from 'src/store/slices/job-stage-interview-seat';
import { reducer as jobStagePlanConfigReducer } from 'src/store/slices/job-stage-plan-config';
import { reducer as scheduleCommunicationsReducer } from 'src/store/slices/schedule-communications';
import { reducer as scheduleFiltersReducer } from 'src/store/slices/schedule-filters';
import { reducer as scheduleUpdateReducer } from 'src/store/slices/schedule-update';
import { reducer as schedulesReducer } from 'src/store/slices/schedules';
import { reducer as uiStateReducer } from 'src/store/slices/ui-state';

const rootReducer = combineReducers({
  application: applicationReducer,
  candidate: candidateReducer,
  conflicts: conflictsReducer,
  debrief: debriefReducer,
  fileUpload: fileUploadReducer,
  jobStage: jobStageReducer,
  jobStageInterview: jobStageInterviewReducer,
  jobStageInterviewGroup: jobStageInterviewGroupReducer,
  jobStageInterviewSeat: jobStageInterviewSeatReducer,
  jobStagePlanConfig: jobStagePlanConfigReducer,
  persist: persistReducer,
  scheduleCommunications: scheduleCommunicationsReducer,
  scheduleFilters: scheduleFiltersReducer,
  schedules: schedulesReducer,
  scheduleUpdate: scheduleUpdateReducer,
  scheduling: schedulingReducer,
  uiState: uiStateReducer,
});

export default rootReducer;
