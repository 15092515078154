import React, { FC, useState } from 'react';

import { TimezoneSelect } from '@modernloop/shared/components';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { Stack, Table, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const TimezonesTab: FC = () => {
  const [timezone, setTimezone] = useState(getLocalTimezone());
  const [isoTimestamp, setIsoTimestamp] = useState(new Date().toISOString());
  return (
    <Stack spacing={1}>
      <TextField
        helperText="IsoTimestamp now"
        disabled
        value={isoTimestamp}
        onChange={(e) => setIsoTimestamp(e.target.value)}
      />

      <TimezoneSelect value={timezone} onTimezoneChange={setTimezone} />
      <Table>
        <TableBody>
          <TableRow hover>
            <TableCell>Browser Time</TableCell>
            <TableCell>{format(new Date(isoTimestamp), 'MMM, d yyyy HH:mm')}</TableCell>
          </TableRow>
          <TableRow hover>
            <TableCell>Timezone Time</TableCell>
            <TableCell>
              {format(
                utcToZonedTime(zonedTimeToUtc(new Date(isoTimestamp), getLocalTimezone()), timezone),
                'MMM, d yyyy HH:mm'
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
};

export default TimezonesTab;
