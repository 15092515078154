import React from 'react';

import BaseIcon, { Props } from './Icon';

const ScheduleLiveWithBg = ({ dataTestId, color, fontSize = 32, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon
      dataTestId={dataTestId}
      color={color}
      fontSize={fontSize}
      tooltip={tooltip}
      viewBox={`0 0 ${fontSize} ${fontSize}`}
    >
      <rect width={fontSize} height={fontSize} rx="6" fill="#FFE252" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5806 11.5806C11.8735 11.2877 11.8735 10.8129 11.5806 10.52C11.2877 10.2271 10.8128 10.2271 10.5199 10.52C7.49336 13.5465 7.49336 18.4536 10.5199 21.4801C10.8128 21.773 11.2877 21.773 11.5806 21.4801C11.8735 21.1872 11.8735 20.7124 11.5806 20.4195C9.13981 17.9787 9.13981 14.0214 11.5806 11.5806ZM21.4801 10.52C21.1872 10.2271 20.7123 10.2271 20.4194 10.52C20.1265 10.8129 20.1265 11.2877 20.4194 11.5806C22.8602 14.0214 22.8602 17.9787 20.4194 20.4195C20.1265 20.7124 20.1265 21.1872 20.4194 21.4801C20.7123 21.773 21.1872 21.773 21.4801 21.4801C24.5066 18.4536 24.5066 13.5465 21.4801 10.52ZM13.7019 12.6413C13.9948 12.9342 13.9948 13.409 13.7019 13.7019C12.4327 14.9711 12.4327 17.0289 13.7019 18.2981C13.9948 18.591 13.9948 19.0659 13.7019 19.3588C13.409 19.6517 12.9341 19.6517 12.6412 19.3588C10.7863 17.5038 10.7863 14.4963 12.6412 12.6413C12.9341 12.3484 13.409 12.3484 13.7019 12.6413ZM18.2981 12.6413C18.591 12.3484 19.0659 12.3484 19.3588 12.6413C21.2137 14.4963 21.2137 17.5038 19.3588 19.3588C19.0659 19.6517 18.591 19.6517 18.2981 19.3588C18.0052 19.0659 18.0052 18.591 18.2981 18.2981C19.5673 17.0289 19.5673 14.9711 18.2981 13.7019C18.0052 13.409 18.0052 12.9342 18.2981 12.6413ZM17.5 16C17.5 16.8285 16.8284 17.5 16 17.5C15.1716 17.5 14.5 16.8285 14.5 16C14.5 15.1716 15.1716 14.5 16 14.5C16.8284 14.5 17.5 15.1716 17.5 16Z"
        fill="#494A56"
      />
    </BaseIcon>
  );
};

export default ScheduleLiveWithBg;
