/* eslint-disable max-lines */
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { FilterListPopover, MaybeTooltip } from '@modernloop/shared/components';
import { EmailIcon, PlusIcon } from '@modernloop/shared/icons';
import {
  AutocompleteRenderGroupParams,
  Avatar,
  Checkbox,
  Chip,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { isEmpty, uniqBy } from 'lodash';

import {
  DynamicAudience,
  OptionRenderer_EmployeesFragment,
  SmartEmployeeSelectFragment,
  useDynamicEmployeeQuery,
  useSmartEmployeeByIdsQuery,
  useSmartEmployeeSelectMembersLazyQuery,
} from 'src/generated/mloop-graphql';

import { useOrgAtsService } from 'src/hooks/atsService';

import { EMAIL_REGEX, EMPTY_UUID, PAGE_SIZE } from 'src/constants';

import { DynamicAudienceChip } from './DynamicAudienceChip';
import { DynamicAudienceContext } from './types';
import { useCandidateSmartOptions } from './useCandidateSmartOptions';
import { useJobSmartOptions } from './useJobSmartOptions';
import { useTaskSmartOptions } from './useTaskSmartOptions';
import { getSmartOptionLabel } from './utils';

type Props = {
  label: string;
  placeholder: string;
  context: DynamicAudienceContext;
  // pass context.application as id with candidateId for server context
  candidateId?: string;
  onChange: ({
    employees,
    freeformEmails,
    dynamicAudiences,
  }: {
    employees: SmartEmployeeSelectFragment[];
    freeformEmails: string[];
    dynamicAudiences: DynamicAudience[];
  }) => void;
  selectedEmployeeIds?: string[];
  selectedDynamicAudiences?: DynamicAudience[];
  selectedFreeformEmails?: string[];
  filterDynamicAudiences?: DynamicAudience[];
};

const getEmployeeName = (employee: SmartEmployeeSelectFragment) =>
  employee.fullName ?? employee.givenName ?? employee.familyName ?? '';

const SmartEmployeeSelect: FC<Props> = ({
  context,
  label,
  placeholder,
  candidateId,
  onChange,
  selectedEmployeeIds,
  selectedDynamicAudiences,
  selectedFreeformEmails,
  filterDynamicAudiences,
}) => {
  const [fetchEmployee, { data }] = useSmartEmployeeSelectMembersLazyQuery();
  const currentAtsService = useOrgAtsService();

  const localTaskContext = typeof context.task !== 'string' && typeof context.task !== 'boolean' && !!context.task;
  const noTaskContext = !context.task || typeof context.task === 'boolean';

  const localJobContext = typeof context.job !== 'string' && typeof context.job !== 'boolean' && !!context.job;
  const noJobContext = !context.job || typeof context.job === 'boolean';

  const localCandidateContext =
    (typeof context.application !== 'string' && typeof context.application !== 'boolean') || !candidateId;
  const noCandidateContext = !context.application || typeof context.application === 'boolean';

  const { data: employeesData } = useSmartEmployeeByIdsQuery({ variables: { input: selectedEmployeeIds || [] } });

  const [searchStr, setSearchStr] = useState('');

  const { data: taskdata } = useDynamicEmployeeQuery({
    variables: {
      id: `${context.task}`,
      taskId: typeof context.task === 'string' ? context.task : EMPTY_UUID,
      skipTask: localTaskContext || noTaskContext,
      skipJob: localJobContext || noJobContext,
      jobId: typeof context.job === 'string' ? context.job : EMPTY_UUID,
      candidateId: candidateId ?? EMPTY_UUID,
      applicationId: typeof context.application === 'string' ? context.application : EMPTY_UUID,
      skipCandidate: localCandidateContext || noCandidateContext,
    },
  });

  useEffect(() => {
    fetchEmployee({
      variables: {
        input: {
          pageInput: {
            limit: PAGE_SIZE,
          },
          isArchived: !searchStr ? false : undefined,
          isAtsDisabled: !searchStr ? false : undefined,
          isDirectoryDisabled: false,
          search: searchStr,
        },
      },
    });
  }, [fetchEmployee, searchStr]);

  const {
    options: taskOptions,
    renderer: renderTaskOption,
    getData: getTaskData,
  } = useTaskSmartOptions(
    {
      taskAssigneeData: taskdata?.task?.TASK_ASSIGNEE ? [taskdata?.task?.TASK_ASSIGNEE] : [],
      taskCreatorData: taskdata?.task?.TASK_CREATOR ? [taskdata?.task?.TASK_CREATOR] : [],
      taskSubscriberData: taskdata?.task?.TASK_SUBSCRIBERS ?? [],
      taskDebriefData: taskdata?.task?.DEBRIEF_ATTENDEES ?? [],
      // TODO: pass INTERVIEWERS_ON_SCHEDULE data
      taskInterviewersData: [],
    },
    { task: context.task }
  );

  const {
    options: jobOptions,
    renderer: renderJobOption,
    getData: getJobData,
  } = useJobSmartOptions(
    {
      jobCoordinatorData: taskdata?.job?.JOB_COORDINATORS ?? [],
      jobRecruiterData: taskdata?.job?.JOB_RECRUITERS ?? [],
      jobSourcerData: taskdata?.job?.JOB_SOURCERS ?? [],
    },
    null
  );

  const {
    options: candidateOptions,
    renderer: renderCandidateOption,
    getData: getCandidateData,
  } = useCandidateSmartOptions(
    {
      candidateCoordinatorData: taskdata?.candidate?.CANDIDATE_COORDINATOR
        ? [taskdata?.candidate?.CANDIDATE_COORDINATOR]
        : [],
      candidateRecruiterData: taskdata?.candidate?.CANDIDATE_RECRUITER
        ? [taskdata?.candidate?.CANDIDATE_RECRUITER]
        : [],
      candidateSourcerData: taskdata?.candidate?.CANDIDATE_SOURCER ? [taskdata?.candidate?.CANDIDATE_SOURCER] : [],
      previousCandidateInterviewersData: taskdata?.candidate?.INTERVIEWERS_FOR_THIS_CANDIDATE ?? [],
    },
    null
  );

  const [showPopover, setShowPopover] = useState(false);

  const sortedOptions = useMemo(() => {
    const members = uniqBy([...(data?.employees?.items ?? [])], 'id').filter((option) => option.id);

    // Sorting so that selected options come at top
    return (
      members.sort((a, b) => {
        if (selectedEmployeeIds?.includes(a?.id) && selectedEmployeeIds.includes(b?.id)) return 0;
        if (selectedEmployeeIds?.includes(a?.id)) return -1;
        if (selectedEmployeeIds?.includes(b?.id)) return 1;

        return 0;
      }) ?? []
    );
  }, [data?.employees?.items, selectedEmployeeIds]);

  const getChipData = useCallback(
    (type: DynamicAudience) => {
      if (taskOptions.includes(type)) {
        return getTaskData(type);
      }
      if (candidateOptions.includes(type)) {
        return getCandidateData(type);
      }
      if (jobOptions.includes(type)) {
        return getJobData(type);
      }
      return null;
    },
    [taskOptions, candidateOptions, jobOptions, getTaskData, getCandidateData, getJobData]
  );

  const options = useMemo(() => {
    const allOptions: DynamicAudience[] = [];
    if (context.task) {
      allOptions.push(...taskOptions);
    }
    if (context.application) {
      allOptions.push(...candidateOptions);
    }
    if (context.job) {
      allOptions.push(...jobOptions);
    }

    return allOptions;
  }, [context.task, context.application, context.job, taskOptions, candidateOptions, jobOptions]);

  // mapping additional options to employee object for rendering in the list component
  const additionalEmployeeOptions = useMemo(() => {
    return options.map((option) => ({
      fullName: getSmartOptionLabel(option),
      familyName: getSmartOptionLabel(option),
      givenName: getSmartOptionLabel(option),
      email: option,
      id: option,
      isDirectoryDisabled: false,
      orgId: option,
      isAtsDisabled: false,
      hasAtsId: false,
      isArchived: false,
    }));
  }, [options]);

  const getOptionDisabled = (option: SmartEmployeeSelectFragment) => {
    if (context.enableOptionsWithMissingData) {
      return false;
    }

    if (taskOptions.includes(option.id)) {
      return isEmpty(getTaskData(option.id)) || !getTaskData(option.id);
    }
    if (candidateOptions.includes(option.id)) {
      return isEmpty(getCandidateData(option.id)) || !getCandidateData(option.id);
    }
    if (jobOptions.includes(option.id)) {
      return isEmpty(getJobData(option.id)) || !getJobData(option.id);
    }
    return false;
  };

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, employee: SmartEmployeeSelectFragment) => {
    if (filterDynamicAudiences?.includes(employee.id)) {
      return null;
    }
    const getRow = () => {
      let disabledLabel: string | JSX.Element = '';
      if (employee.isAtsDisabled) {
        disabledLabel = `(Disabled in ${currentAtsService?.name ?? 'ATS'})`;
      } else if (!employee.hasAtsId) {
        disabledLabel = `(User has not accepted ${currentAtsService?.name ?? 'ATS'} invite)`;
      } else if (employee.isArchived) {
        disabledLabel = '(Disabled in ModernLoop)';
      }

      if (taskOptions.includes(employee.id)) {
        return renderTaskOption({
          optionType: employee.id,
          isDisabled: getOptionDisabled(employee),
          isChecked: !!selectedDynamicAudiences?.includes(employee.id),
        });
      }
      if (candidateOptions.includes(employee.id)) {
        return renderCandidateOption({
          optionType: employee.id,
          isDisabled: getOptionDisabled(employee),
          isChecked: !!selectedDynamicAudiences?.includes(employee.id),
        });
      }
      if (jobOptions.includes(employee.id)) {
        return renderJobOption({
          optionType: employee.id,
          isDisabled: getOptionDisabled(employee),
          isChecked: !!selectedDynamicAudiences?.includes(employee.id),
        });
      }

      return (
        <ListItemButton disabled={!!disabledLabel}>
          <Stack direction="row" alignItems="center" columnGap={1} width="100%">
            <Checkbox checked={selectedEmployeeIds?.includes(employee.id)} />
            <Avatar src={employee.slackImageUrl ?? ''} alt={employee.fullName || ''} />
            <MaybeTooltip
              title={`${getEmployeeName(employee)} ${disabledLabel}`}
              label={`${getEmployeeName(employee)} ${disabledLabel}`}
              placement="top"
            />
          </Stack>
        </ListItemButton>
      );
    };

    return <ListItem {...props}>{getRow()}</ListItem>;
  };

  const handleChange = (event: React.ChangeEvent, value) => {
    if (!value) return;
    const dynamicAudiences = value.filter((option) => additionalEmployeeOptions.find((item) => item.id === option.id));
    const employees = value.filter((option) => !additionalEmployeeOptions.find((item) => item.id === option.id));
    onChange({
      employees,
      freeformEmails: selectedFreeformEmails ?? [],
      dynamicAudiences: dynamicAudiences.map((option) => option.id),
    });
  };

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader key={params.key}>{params.group}</ListSubheader>,
    params.children,
  ];

  const groupBy = (option) => {
    if (taskOptions.includes(option.id)) {
      return 'Task';
    }
    if (candidateOptions.includes(option.id)) {
      return 'Candidate';
    }
    if (jobOptions.includes(option.id)) {
      return 'Job';
    }
    if (selectedEmployeeIds?.includes(option.id)) {
      return 'Selected';
    }
    return 'All employees';
  };

  const allSelectedEmployees = useMemo(() => {
    if (selectedDynamicAudiences) {
      const findAllSelectedDynamicAudiences = additionalEmployeeOptions.filter((option) =>
        selectedDynamicAudiences.includes(option.id)
      );
      return [...(employeesData?.employeeByIds ?? []), ...findAllSelectedDynamicAudiences];
    }
    return employeesData?.employeeByIds ?? [];
  }, [additionalEmployeeOptions, employeesData?.employeeByIds, selectedDynamicAudiences]);

  const deleteDynamicAudience = (item: DynamicAudience) => {
    onChange({
      employees: employeesData?.employeeByIds ?? [],
      freeformEmails: selectedFreeformEmails ?? [],
      dynamicAudiences: (selectedDynamicAudiences ?? []).filter((dynamicAudience) => dynamicAudience !== item),
    });
  };
  const deleteEmployee = (item: OptionRenderer_EmployeesFragment) => {
    onChange({
      employees: (employeesData?.employeeByIds ?? []).filter((employee) => employee.id !== item.id),
      freeformEmails: selectedFreeformEmails ?? [],
      dynamicAudiences: selectedDynamicAudiences ?? [],
    });
  };
  const deleteFreeformEmail = (item: string) => {
    onChange({
      employees: employeesData?.employeeByIds ?? [],
      freeformEmails: (selectedFreeformEmails ?? []).filter((email) => email !== item),
      dynamicAudiences: selectedDynamicAudiences ?? [],
    });
  };

  const splitGroup = (type: DynamicAudience) => {
    const dynamicAudiences = selectedDynamicAudiences?.filter((dynamicAudience) => dynamicAudience !== type);
    let employees = [...(employeesData?.employeeByIds ?? [])];

    if (taskOptions.includes(type)) {
      employees = employees.concat(...(getTaskData(type) ?? []));
    }

    if (candidateOptions.includes(type)) {
      employees = employees.concat(...(getCandidateData(type) ?? []));
    }

    if (jobOptions.includes(type)) {
      employees = employees.concat(...(getJobData(type) ?? []));
    }

    onChange({
      employees: uniqBy(employees, 'id'),
      freeformEmails: selectedFreeformEmails ?? [],
      dynamicAudiences: dynamicAudiences ?? [],
    });
  };

  const getEmptyState = () => {
    const validEmail = searchStr.match(EMAIL_REGEX);

    if (validEmail) {
      return (
        <Stack width="100%">
          <ListItemButton
            onClick={() => {
              onChange({
                employees: employeesData?.employeeByIds ?? [],
                freeformEmails: [...(selectedFreeformEmails ?? []), searchStr],
                dynamicAudiences: selectedDynamicAudiences ?? [],
              });
              setSearchStr('');
            }}
          >
            <Stack direction="row" alignItems="center" columnGap={1}>
              <PlusIcon />
              <ListItemText primary={searchStr} />
            </Stack>
          </ListItemButton>
        </Stack>
      );
    }

    return (
      <Stack alignItems="center" justifyContent="center" p={2}>
        <Typography color="text.secondary">No results found</Typography>
      </Stack>
    );
  };

  return (
    <FilterListPopover
      open={showPopover}
      values={allSelectedEmployees}
      renderOption={renderOption}
      options={[...additionalEmployeeOptions, ...(sortedOptions ?? [])]}
      onChange={handleChange}
      getOptionLabel={(option) => option.fullName ?? option.familyName ?? option.givenName ?? ''}
      groupBy={groupBy}
      placeholderText="Search people"
      getChildSize={() => 36}
      onPopoverClose={() => {
        setShowPopover(false);
      }}
      getOptionDisabled={(option) => getOptionDisabled(option)}
      autoFocus
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onAutoCompleteClose={() => {
        setShowPopover(false);
      }}
      onInputChange={(event: React.ChangeEvent, value: string) => {
        setSearchStr(value);
      }}
      noOptionsText={getEmptyState()}
      getListHeight={() => 350}
      renderGroup={renderGroup}
    >
      <Stack
        borderRadius={2}
        p={1}
        border={(theme: Theme) => `1px solid ${theme.palette.border}`}
        onClick={() => {
          setShowPopover(true);
        }}
      >
        <Typography color="text.secondary" variant="subtitle2">
          {label}
        </Typography>
        <Stack
          gap={1}
          flexWrap="wrap"
          direction="row"
          sx={{
            cursor: 'pointer',
          }}
        >
          {selectedDynamicAudiences?.map((audience) => {
            return (
              <DynamicAudienceChip
                key={audience}
                onDelete={deleteDynamicAudience}
                dynamicAudience={audience}
                renderData={getChipData(audience)}
                context={context}
                onSplit={splitGroup}
              />
            );
          })}
          {employeesData?.employeeByIds?.map((employee) => (
            <Chip
              key={employee.id}
              variant="outlined"
              icon={<Avatar src={employee.slackImageUrl ?? ''} alt="User chip" />}
              size="small"
              label={getEmployeeName(employee)}
              onDelete={() => deleteEmployee(employee)}
            />
          ))}
          {selectedFreeformEmails?.map((email) => (
            <Chip
              key={email}
              variant="outlined"
              icon={<EmailIcon />}
              size="small"
              label={email}
              onDelete={() => deleteFreeformEmail(email)}
            />
          ))}
          <Typography variant="body1" color="text.secondary">
            {placeholder}
          </Typography>
        </Stack>
      </Stack>
    </FilterListPopover>
  );
};

export const MemberQuery = gql`
  ${useTaskSmartOptions.fragments.taskAssigneeData}
  ${useTaskSmartOptions.fragments.taskCreatorData}
  ${useTaskSmartOptions.fragments.taskDebriefData}
  ${useTaskSmartOptions.fragments.taskSubscriberData}
  ${useTaskSmartOptions.fragments.taskInterviewersData}
  ${useJobSmartOptions.fragments.jobCoordinatorData}
  ${useJobSmartOptions.fragments.jobRecruiterData}
  ${useJobSmartOptions.fragments.jobSourcerData}
  ${useCandidateSmartOptions.fragments.candidateCoordinatorData}
  ${useCandidateSmartOptions.fragments.candidateRecruiterData}
  ${useCandidateSmartOptions.fragments.candidateSourcerData}
  ${useCandidateSmartOptions.fragments.previousCandidateInterviewersData}
  fragment SmartEmployeeSelect on Employee {
    id
    fullName
    givenName
    familyName
    slackImageUrl
    email
    isArchived
    isAtsDisabled
    hasAtsId
    isDisabled
    orgId
  }
  query SmartEmployeeSelectMembers($input: EmployeesInput!) {
    employees(input: $input) {
      items {
        ...SmartEmployeeSelect
      }
      nextCursor
    }
  }
  query SmartEmployeeByIds($input: [uuid!]!) {
    employeeByIds(ids: $input) {
      ...SmartEmployeeSelect
    }
  }
  query DynamicEmployee(
    $id: String!
    $taskId: uuid!
    $jobId: uuid!
    $candidateId: uuid!
    $applicationId: uuid!
    $skipTask: Boolean!
    $skipJob: Boolean!
    $skipCandidate: Boolean!
  ) {
    task: dynamicEmployee(id: $id) @skip(if: $skipTask) {
      TASK_ASSIGNEE(input: { taskId: $taskId }) {
        ...useTaskSmartOptions_taskAssigneeData
      }
      TASK_SUBSCRIBERS(input: { taskId: $taskId }) {
        ...useTaskSmartOptions_taskSubscriberData
      }
      TASK_CREATOR(input: { taskId: $taskId }) {
        ...useTaskSmartOptions_taskCreatorData
      }
      DEBRIEF_ATTENDEES(input: { taskId: $taskId }) {
        ...useTaskSmartOptions_taskDebriefData
      }
      # INTERVIEWERS_ON_SCHEDULE(input: { employeeIds: $employeeIds }) {
      #   ...OptionRenderer_employees
      # }
    }
    job: dynamicEmployee(id: $id) @skip(if: $skipJob) {
      JOB_COORDINATORS(input: { jobId: $jobId }) {
        ...useJobSmartOptions_jobCoordinatorData
      }
      JOB_RECRUITERS(input: { jobId: $jobId }) {
        ...useJobSmartOptions_jobRecruiterData
      }
      JOB_SOURCERS(input: { jobId: $jobId }) {
        ...useJobSmartOptions_jobSourcerData
      }
    }
    candidate: dynamicEmployee(id: $id) @skip(if: $skipCandidate) {
      CANDIDATE_COORDINATOR(input: { candidateId: $candidateId }) {
        ...useCandidateSmartOptions_candidateCoordinatorData
      }
      CANDIDATE_RECRUITER(input: { candidateId: $candidateId }) {
        ...useCandidateSmartOptions_candidateRecruiterData
      }
      CANDIDATE_SOURCER(input: { candidateId: $candidateId }) {
        ...useCandidateSmartOptions_candidateSourcerData
      }
      INTERVIEWERS_FOR_THIS_CANDIDATE(input: { applicationId: $applicationId }) {
        ...useCandidateSmartOptions_previousCandidateInterviewersData
      }
    }
  }
`;

export default SmartEmployeeSelect;
