import {
  AshbyCandidate,
  AtsCandidate,
  AtsFieldsCandidate,
  AtsFieldsInterviewDefinition,
  GreenhouseCandidate,
  GreenhouseInterviewDefinition,
  LeverCandidate,
  LeverInterviewDefinition,
  Maybe,
} from 'src/generated/mloop-graphql';

export const getAtsInterviewDefinitionFields = (
  atsFields: Maybe<Omit<AtsFieldsInterviewDefinition, 'greenhouseInterviewKitId'>> | undefined,
  key?: keyof (LeverInterviewDefinition & Omit<GreenhouseInterviewDefinition, 'greenhouseInterviewKitId'>)
) => {
  if (atsFields && key) return atsFields[key];
  return undefined;
};

export const getCandidateAtsField = (
  atsFields?: Partial<AtsFieldsCandidate> | null,
  key?: keyof (AshbyCandidate & LeverCandidate & GreenhouseCandidate & AtsCandidate)
) => {
  if (atsFields && key) return atsFields[key];
  return undefined;
};
