import React from 'react';

import BaseIcon, { Props } from './Icon';

const AttributeIcon = ({ color, fontSize, tooltip, fillOpacity }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08579 4.5C5.41912 4.16667 5.58579 4 5.79289 4C6 4 6.16667 4.16667 6.5 4.5L8.08579 6.08579C8.41912 6.41912 8.58579 6.58579 8.58579 6.79289C8.58579 7 8.41912 7.16667 8.08579 7.5L8.08579 7.5L6.5 9.08579C6.16667 9.41912 6 9.58579 5.79289 9.58579C5.58579 9.58579 5.41912 9.41912 5.08579 9.08579L5.08579 9.08579L3.5 7.5C3.16667 7.16667 3 7 3 6.79289C3 6.58579 3.16667 6.41912 3.5 6.08579L5.08579 4.5ZM14.2929 11.7929C14.0042 11.7929 13.8118 12.1262 13.4269 12.7929L12.1278 15.0429C11.7429 15.7096 11.5505 16.0429 11.6948 16.2929C11.8392 16.5429 12.2241 16.5429 12.9939 16.5429H15.5919C16.3617 16.5429 16.7466 16.5429 16.891 16.2929C17.0353 16.0429 16.8429 15.7096 16.458 15.0429L16.458 15.0429L15.1589 12.7929C14.774 12.1262 14.5816 11.7929 14.2929 11.7929ZM5.79289 11.7929C4.41218 11.7929 3.29289 12.9122 3.29289 14.2929C3.29289 15.6736 4.41218 16.7929 5.79289 16.7929C7.1736 16.7929 8.29289 15.6736 8.29289 14.2929C8.29289 12.9122 7.1736 11.7929 5.79289 11.7929ZM12.8232 4.26256C12.5303 3.96967 12.0555 3.96967 11.7626 4.26256C11.4697 4.55546 11.4697 5.03033 11.7626 5.32322L13.2322 6.79289L11.7626 8.26256C11.4697 8.55546 11.4697 9.03033 11.7626 9.32322C12.0555 9.61612 12.5303 9.61612 12.8232 9.32322L14.2929 7.85355L15.7626 9.32322C16.0555 9.61612 16.5303 9.61612 16.8232 9.32322C17.1161 9.03033 17.1161 8.55546 16.8232 8.26256L15.3536 6.79289L16.8232 5.32322C17.1161 5.03033 17.1161 4.55546 16.8232 4.26256C16.5303 3.96967 16.0555 3.96967 15.7626 4.26256L14.2929 5.73223L12.8232 4.26256Z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </BaseIcon>
  );
};

export default AttributeIcon;
