import { ApplicationStatus } from 'src/generated/mloop-graphql';

/**
 * Returns true if the application status is rejected and the feature flag for rejected task alerts is enabled.
 */
const useIsApplicationRejected = (status?: ApplicationStatus) => {
  return (
    status === ApplicationStatus.Rejected ||
    status === ApplicationStatus.Archived ||
    status === ApplicationStatus.Converted
  );
};

export default useIsApplicationRejected;
