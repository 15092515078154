import { useMemo } from 'react';

import { CodeLinkType } from 'src/generated/mloop-graphql';

import useIntegrations from './useIntegrations';

export function useCodingIntegrationTypes(): CodeLinkType[] {
  const { data } = useIntegrations();

  return useMemo(() => {
    if (!data) {
      return [];
    }

    const codeLinkTypes: CodeLinkType[] = [];

    if (data.hackerrank) {
      codeLinkTypes.push(CodeLinkType.Hackerrank);
    }

    if (data.codility) {
      codeLinkTypes.push(CodeLinkType.Codility);
    }

    if (data.coderpad) {
      codeLinkTypes.push(CodeLinkType.Coderpad);
    }

    if (data.codesignal) {
      codeLinkTypes.push(CodeLinkType.Codesignal);
    }

    return codeLinkTypes;
  }, [data]);
}
