import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import MUIAvatar from '@material-ui/core/Avatar';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Theme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { BaseProps } from 'src/components/types';

import Label from '../label';

// Slack default example URL - https://secure.gravatar.com/avatar/...
const SLACK_GRAVATAR_URL = 'secure.gravatar.com';
// Slack uploaded example URL - https://avatars.slack-edge.com/....
const SLACK_UPLOAD_URL = 'slack-edge.com';

// Helper const
const BACKGROUND_COLORS = ['primary', 'secondary', 'tertiary', 'error', 'warning'];
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface AvatarProps extends BaseProps {
  /**
   * First letter is image if the src isn't valid
   */
  alt: string;

  /**
   * Source of picture
   */
  src?: string;

  /**
   * sets height and width
   */
  size?: '16px' | '20px' | '24px' | '28px' | '36px' | '40px' | '52px' | '64px';

  /**
   * Passed classname
   */
  className?: string;

  /**
   * Children of the avatar. Used for letters.
   */
  children?: JSX.Element | string;
}

const avatarStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: `inset 0 0 0 1px ${theme.palette.border}`,
  },
  '16px': {
    width: '16px',
    height: '16px',
    borderRadius: '6px',
  },
  '20px': {
    width: '20px',
    height: '20px',
    borderRadius: '6px',
  },
  '24px': {
    width: '24px',
    height: '24px',
    borderRadius: '6px',
  },
  '28px': {
    width: '28px',
    height: '28px',
    borderRadius: '6px',
  },
  '36px': {
    width: '36px',
    height: '36px',
    borderRadius: '6px',
  },
  '40px': {
    width: '40px',
    height: '40px',
    borderRadius: '6px',
  },
  '52px': {
    width: '52px',
    height: '52px',
    borderRadius: '6px',
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  tertiary: {
    backgroundColor: theme.palette.tertiary.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
}));

/**
 * @deprecated - Use `Avatar` from `@mui/material` instead
 */
const Avatar = ({ alt, src, size, className, dataTestId, children }: AvatarProps): JSX.Element => {
  const styles = avatarStyles();
  const sizeClassName = styles[size || '20px'];
  // Pseudo-random background color for letter default avatar
  const backgroundColorClassName = styles[BACKGROUND_COLORS[alt.length % BACKGROUND_COLORS.length]];

  // Helper function to determine if this is a large avatar
  const isLarge = (avatarSize) => {
    if (avatarSize === '36px' || avatarSize === '40px' || avatarSize === '52px') {
      return true;
    }

    return false;
  };
  // Handle updating slack sizing as well as defaulting away from slack gravatar
  let imageUrl = src;
  // We want to default to first initial if user doesn't have image set in slack
  if (src && src.includes(SLACK_GRAVATAR_URL)) {
    imageUrl = undefined;
  } else if (src && isLarge(size) && src.includes(SLACK_UPLOAD_URL)) {
    // Upscale slack image for larger avatars
    imageUrl = src.replace('24.', '48.');
  }

  // Create a single initial as fallback if there is no src
  let initialsJSX;
  if (alt && alt.trim()) {
    const initials = alt.trim().charAt(0).toUpperCase();
    initialsJSX = (
      <Label
        variant={isLarge(size) ? 'header' : 'captions'}
        fontWeight={size === '20px' || size === undefined ? 400 : 500}
        color="background"
      >
        {initials}
      </Label>
    );
  }

  return (
    <MUIAvatar
      alt={alt || ''}
      src={imageUrl}
      className={clsx(sizeClassName, imageUrl ? '' : backgroundColorClassName, className, styles.root)}
      data-testid={dataTestId}
    >
      {imageUrl ? null : children || initialsJSX}
    </MUIAvatar>
  );
};

export default Avatar;
