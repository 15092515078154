import React from 'react';

import { Box, Typography } from '@mui/material';

import useOrgName from 'src/hooks/useOrgName';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

export const LockedforOrg: React.FC = () => {
  const { orgName } = useOrgName();

  return (
    <ConditionalThemeProvider>
      <Box mt={1}>
        <Typography variant="body2" color="text.secondary">
          Locked for {orgName}
        </Typography>
      </Box>
    </ConditionalThemeProvider>
  );
};
