import React from 'react';

import { Stack } from '@mui/material';

import Label from 'src/components/label';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
const CreateDetailsRow = ({
  label,
  component,
}: {
  label: string | React.ReactNode;
  component: React.ReactNode | undefined;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      {!React.isValidElement(label) && (
        <Label variant="body" style={{ flexShrink: 0 }}>
          {label}
        </Label>
      )}
      {React.isValidElement(label) && label}
      {component}
    </Stack>
  );
};

export default CreateDetailsRow;
