import React from 'react';

import BaseIcon, { Props } from './Icon';

const ModernLoopLogoIcon = ({ dataTestId, color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon dataTestId={dataTestId} color={color} fontSize={fontSize} tooltip={tooltip}>
      <rect width="20" height="20" rx="4" fill="#01458E" />
      <path
        d="M13.8483 11.3194C13.8483 11.8885 14.3226 12.3628 14.8917 12.3628C15.4766 12.3628 15.9509 11.8885 15.9509 11.3194V5.48584C15.9509 5.01156 15.6189 4.60052 15.1605 4.47405C14.702 4.36338 14.2119 4.5689 13.9906 4.97993L9.8645 12.6157L5.84899 4.99562C5.62766 4.56877 5.13758 4.36326 4.67912 4.47391C4.20485 4.60038 3.88867 5.01142 3.88867 5.4857V14.4653C3.88867 15.0345 4.36294 15.5087 4.93206 15.5087C5.51701 15.5087 5.99129 15.0345 5.99129 14.4653V9.10611L8.93169 14.9555C9.1214 15.3034 9.4692 15.5089 9.84861 15.5089C10.228 15.5089 10.5758 15.3034 10.7655 14.9713L13.8483 9.01132V11.3194ZM15.9509 14.4655C15.9509 13.8805 15.4766 13.4063 14.8916 13.4063C14.3225 13.4063 13.8482 13.8805 13.8482 14.4655C13.8482 15.0346 14.3225 15.5089 14.8916 15.5089C15.4766 15.5089 15.9509 15.0346 15.9509 14.4655Z"
        fill="white"
      />
    </BaseIcon>
  );
};

export default ModernLoopLogoIcon;
