import React, { useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, Editor, FCWithFragments } from '@modernloop/shared/components';
import { getExternalErrorMessage, logError } from '@modernloop/shared/utils';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import { ApplicationNoteEditor_ApplicationFragment, useInternalNoteUpdateMutation } from 'src/generated/mloop-graphql';

type Props = {
  onClose: () => void;
};

type Fragments = {
  application: ApplicationNoteEditor_ApplicationFragment;
};

const ApplicationNoteEditor: FCWithFragments<Fragments, Props> = ({ application, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [content, setContent] = useState(application.internalNote || '');
  const [updateInternalNote, { loading }] = useInternalNoteUpdateMutation();

  const handleUpdateNote = async () => {
    const div = document.createElement('div');
    div.innerHTML = content;
    let noteValue = content;

    if (!(div.innerText || div.textContent)) {
      noteValue = '';
    }

    try {
      await updateInternalNote({
        variables: {
          input: {
            applicationId: application.id,
            note: noteValue,
          },
        },
      });

      onClose();
    } catch (e) {
      logError(e);
      enqueueSnackbar(getExternalErrorMessage(e), { variant: 'error' });
    }
  };

  const plainTextNote = useMemo(() => {
    if (!application.internalNote) return '';
    const div = document.createElement('div');
    // replacing all occurrences of <br> and <br /> with \n to maintain line breaks
    div.innerHTML = application.internalNote.replaceAll(/<br\s*[/]?><div\s*[/]?>/gi, '');
    return div.innerText || div.textContent || '';
  }, [application.internalNote]);

  return (
    <Dialog
      open
      title={plainTextNote ? 'Update internal note' : 'Add internal note'}
      subTitle="Add this note to any internal template using the {{INTERNAL_APPLICATION_NOTE}} token"
      onClose={onClose}
      submitOptions={{
        isDisabled: loading,
        isLoading: loading,
        onClick: handleUpdateNote,
        label: plainTextNote ? 'Update' : 'Add',
        dataTestId: 'internal-note-update-button',
      }}
      cancelOptions={{ onClick: onClose, label: 'Cancel', dataTestId: 'internal-note-cancel-button' }}
    >
      <Stack spacing={1}>
        <Editor
          dataTestId="candidate-portal-note-editor"
          placeholder="Type your note..."
          defaultValue={content}
          autoFocus
          sx={{
            minHeight: 200,
            border: `1px solid silver`,
            borderRadius: '6px',
            '& .ql-editor': { minHeight: 144, maxHeight: '480px' },
          }}
          onChange={setContent}
          modules={{
            toolbar: {
              container: [
                ['bold', 'italic', 'underline'], // toggle buttons
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link'],
              ],
            },
            clipboard: {
              matchVisual: false,
            },
          }}
          formats={['bold', 'italic', 'underline', 'link', 'list']}
        />
      </Stack>
    </Dialog>
  );
};

ApplicationNoteEditor.fragments = {
  application: gql`
    fragment ApplicationNoteEditor_application on Application {
      id
      internalNote
    }
  `,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ApplicationNoteUpdateMutation = gql`
  mutation InternalNoteUpdate($input: ApplicationUpdateInput!) {
    applicationUpdate(input: $input) {
      id
      internalNote
    }
  }
`;

export default ApplicationNoteEditor;
