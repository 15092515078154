import { TemplateType } from 'src/generated/mloop-graphql';

export const CandidateTemplateType: TemplateType[] = [
  TemplateType.CandidateConfirmation,
  TemplateType.CandidateInvite,
  TemplateType.CandidateAvailabilityRequest,
  TemplateType.SelfScheduleRequestCandidateEmail,
];

export const InternalTemplateType: TemplateType[] = [
  TemplateType.InterviewerInvite,
  TemplateType.SlackChannelMessage,
  TemplateType.DebriefConfirmationConversationMessage,
  TemplateType.DebriefCalendarInvite,
];

export const TemplateTypeOrder: TemplateType[] = [...CandidateTemplateType, ...InternalTemplateType];

export const TemplateTypeMap = {
  candidate: {
    header: 'Candidate',
    types: CandidateTemplateType,
  },
  internal: {
    header: 'Internal',
    types: InternalTemplateType,
  },
};

export const CANDIDATE_CONFIRMATION_TEXT = 'Candidate Email Confirmation';
export const CANDIDATE_INVITE_TEXT = 'Candidate Calendar Invite';
export const INTERVIEWER_INVITE_TEXT = 'Interviewer Calendar Invite';
export const SLACK_CHANNEL_MESSAGE_TEXT = 'Slack Channel Message';
export const DEBRIEF_CALENDAR_INVITE_TEXT = 'Debrief Calendar Invite';
export const DEBRIEF_CONFIRMATION_CONVERSATION_MESSAGE = 'Slack Debrief Message';
export const CANDIDATE_AVAILABILITY_REQUEST_TEXT = 'Candidate Availability Request';
export const SELF_SCHEDULE_REQUEST = 'Self-schedule Request';
export const OTHER_TEXT = 'Other';

export const getTextForTemplateType = (token: TemplateType | null): string => {
  switch (token) {
    case TemplateType.CandidateConfirmation:
      return CANDIDATE_CONFIRMATION_TEXT;
    case TemplateType.CandidateInvite:
      return CANDIDATE_INVITE_TEXT;
    case TemplateType.InterviewerInvite:
      return INTERVIEWER_INVITE_TEXT;
    case TemplateType.SlackChannelMessage:
      return SLACK_CHANNEL_MESSAGE_TEXT;
    case TemplateType.DebriefCalendarInvite:
      return DEBRIEF_CALENDAR_INVITE_TEXT;
    case TemplateType.CandidateAvailabilityRequest:
      return CANDIDATE_AVAILABILITY_REQUEST_TEXT;
    case TemplateType.DebriefConfirmationConversationMessage:
      return DEBRIEF_CONFIRMATION_CONVERSATION_MESSAGE;
    case TemplateType.SelfScheduleRequestCandidateEmail:
      return SELF_SCHEDULE_REQUEST;
    default:
      return OTHER_TEXT;
  }
};

export default TemplateType;
