import { useMemo } from 'react';

import { QueryResult, gql } from '@apollo/client';

import {
  CandidateTokensRequestQuery,
  RenderType,
  TemplateToken,
  useCandidateTokensRequestQuery,
} from 'src/generated/mloop-graphql';

import { EMPTY_OBJECT } from 'src/constants';

import placeholders from './utils/placeholders';

export const CandidatePlaceholderFillerQuery = gql`
  query CandidateTokensRequest(
    $id: String
    $renderType: RenderType!
    $candidateId: uuid!
    $applicationId: uuid!
    $jobId: uuid!
    $candidateLinkLabel: String
    $hasApplicationId: Boolean!
    $hasJobId: Boolean!
  ) {
    CANDIDATE_NAME: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_NAME(input: { id: $candidateId, jobId: $jobId })
    }
    CANDIDATE_FIRST_NAME: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_FIRST_NAME(input: { id: $candidateId, jobId: $jobId })
    }
    CANDIDATE_LAST_NAME: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_LAST_NAME(input: { id: $candidateId, jobId: $jobId })
    }
    CANDIDATE_LAST_INITIAL: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_LAST_INITIAL(input: { id: $candidateId, jobId: $jobId })
    }
    CANDIDATE_EMAIL_ADDRESS: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_EMAIL_ADDRESS(input: { id: $candidateId, jobId: $jobId })
    }
    CANDIDATE_PHONE: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_PHONE(input: { id: $candidateId, jobId: $jobId })
    }
    CANDIDATE_PRONOUNS: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_PRONOUNS(input: { id: $candidateId, jobId: $jobId })
    }
    CANDIDATE_LINKEDIN: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_LINKEDIN(input: { id: $candidateId })
    }
    COORDINATOR_EMAIL: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      COORDINATOR_EMAIL(input: { id: $candidateId, jobId: $jobId })
    }
    COORDINATOR: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      COORDINATOR(input: { id: $candidateId, jobId: $jobId })
    }
    RECRUITER_EMAIL: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      RECRUITER_EMAIL(input: { id: $candidateId, jobId: $jobId })
    }
    RECRUITER: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      RECRUITER(input: { id: $candidateId, jobId: $jobId })
    }
    HIRING_MANAGER: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasJobId) {
      id
      HIRING_MANAGER(input: { jobId: $jobId })
    }
    CANDIDATE_LINK: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_LINK(input: { id: $candidateId, label: $candidateLinkLabel })
    }
    SOURCER: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasApplicationId) {
      id
      SOURCER(input: { id: $applicationId })
    }
    CANDIDATE_SOURCER: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_SOURCER(id: $candidateId)
    }
  }
`;

/**
 * TOKENS Dispalyed
 */
export enum Tokens {
  CANDIDATE_NAME = 'CANDIDATE_NAME',
  CANDIDATE_EMAIL_ADDRESS = 'CANDIDATE_EMAIL_ADDRESS',
  CANDIDATE_FIRST_NAME = 'CANDIDATE_FIRST_NAME',
  CANDIDATE_LAST_NAME = 'CANDIDATE_LAST_NAME',
  CANDIDATE_LAST_INITIAL = 'CANDIDATE_LAST_INITIAL',
  CANDIDATE_LINK = 'CANDIDATE_LINK',
  CANDIDATE_LINKEDIN = 'CANDIDATE_LINKEDIN',
  CANDIDATE_PHONE = 'CANDIDATE_PHONE',
  COORDINATOR = 'COORDINATOR',
  HIRING_MANAGER = 'HIRING_MANAGER',
  SOURCER = 'SOURCER',
  RECRUITER = 'RECRUITER',
  COORDINATOR_EMAIL = 'COORDINATOR_EMAIL',
  RECRUITER_EMAIL = 'RECRUITER_EMAIL',
  CANDIDATE_SOURCER = 'CANDIDATE_SOURCER',
}

/**
 * TOKENS FETCHED
 * Tokens displayed are a subset of the tokens fetched
 */
export enum NonDisplayTokens {
  CANDIDATE_PRONOUNS = 'CANDIDATE_PRONOUNS',
}
export type CandidatePlaceholderFillerOptions = Pick<TemplateToken, keyof typeof Tokens> &
  Pick<TemplateToken, keyof typeof NonDisplayTokens>;

export default class CandidatePlaceholderFiller {
  static getFilledText = (input: string, options: CandidatePlaceholderFillerOptions): string => {
    return placeholders(input, options);
  };

  static getOptions = (data?: CandidateTokensRequestQuery): CandidatePlaceholderFillerOptions => {
    if (!data || !Object.keys(data).length) {
      return EMPTY_OBJECT;
    }
    return {
      CANDIDATE_NAME: data.CANDIDATE_NAME?.CANDIDATE_NAME,
      CANDIDATE_FIRST_NAME: data.CANDIDATE_FIRST_NAME?.CANDIDATE_FIRST_NAME,
      CANDIDATE_LAST_NAME: data.CANDIDATE_LAST_NAME?.CANDIDATE_LAST_NAME,
      CANDIDATE_LAST_INITIAL: data.CANDIDATE_LAST_INITIAL?.CANDIDATE_LAST_INITIAL,
      CANDIDATE_EMAIL_ADDRESS: data.CANDIDATE_EMAIL_ADDRESS?.CANDIDATE_EMAIL_ADDRESS,
      CANDIDATE_PHONE: data.CANDIDATE_PHONE?.CANDIDATE_PHONE,
      CANDIDATE_PRONOUNS: data.CANDIDATE_PRONOUNS?.CANDIDATE_PRONOUNS,
      CANDIDATE_LINKEDIN: data.CANDIDATE_LINKEDIN?.CANDIDATE_LINKEDIN,
      CANDIDATE_LINK: data.CANDIDATE_LINK?.CANDIDATE_LINK,
      COORDINATOR_EMAIL: data.COORDINATOR_EMAIL?.COORDINATOR_EMAIL,
      COORDINATOR: data.COORDINATOR?.COORDINATOR,
      RECRUITER_EMAIL: data.RECRUITER_EMAIL?.RECRUITER_EMAIL,
      RECRUITER: data.RECRUITER?.RECRUITER,
      HIRING_MANAGER: data.HIRING_MANAGER?.HIRING_MANAGER,
      SOURCER: data.SOURCER?.SOURCER,
      CANDIDATE_SOURCER: data.CANDIDATE_SOURCER?.CANDIDATE_SOURCER,
    };
  };
}

export type Input = {
  renderType?: RenderType;
  applicationId: string;
  candidateId: string;
  jobId: string;
  isFetch?: boolean;
};

export const useCandidatePlaceholderFillerOptions = ({
  renderType,
  applicationId,
  candidateId,
  jobId,
  isFetch = true,
}: Input): [CandidatePlaceholderFillerOptions, Pick<QueryResult, 'data' | 'error' | 'loading'>] => {
  const { data, loading, error } = useCandidateTokensRequestQuery({
    skip: !candidateId || !isFetch,
    variables: {
      id: candidateId,
      candidateId,
      applicationId,
      jobId,
      renderType: renderType || RenderType.Html,
      hasApplicationId: !!applicationId,
      hasJobId: !!jobId,
    },
    errorPolicy: 'all',
  });

  return useMemo(() => {
    return [CandidatePlaceholderFiller.getOptions(data), { data, error, loading }];
  }, [data, error, loading]);
};
