import React, { useCallback, useRef } from 'react';

// eslint-disable-next-line no-restricted-imports
import { ListSubheader } from '@material-ui/core';
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line no-restricted-imports
import { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import { useFlag } from '@modernloop/shared/feature-flag';
import { ListItem, ListItemButton, Stack } from '@mui/material';

import { EmployeeFragment } from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import FilterSelect, {
  Props as FilterListProps,
  FilterListSelectInterface,
} from 'src/components/FilterList/FilterSelect';
import { MagicIcon } from 'src/components/icons';
import Label from 'src/components/label';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

import useEmployeeId from 'src/hooks/useEmployeeId';

import { Theme } from 'src/theme/type';

import { Categories, SMART_OPTIONS_INTERVIEWERS_ID, SMART_OPTIONS_SCHEDULER_ID, SmartOptions } from './types';
import useEmployeeSelectData from './useEmployeeSelectData';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionSubheaderLabel: {
      fontWeight: theme.typography.fontWeightMedium,
      padding: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    },
  })
);

type Props = {
  showSmartOptions?: boolean;
  interviewerIds?: string[];
  getLabel: FilterListProps<EmployeeFragment>['getLabel'];
  onEmployeeSelect: (employee: EmployeeFragment) => void;
  onSmartOptionSelected?: (type: SmartOptions) => void;
};

const SingleEmployeeSelect = ({
  showSmartOptions,
  interviewerIds,
  getLabel,
  onEmployeeSelect,
  onSmartOptionSelected,
}: Props) => {
  const currentEmployeeId = useEmployeeId();
  const classes = useStyles();
  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const filterListSelectInterfaceRef = useRef<FilterListSelectInterface | null>(null);

  const { options, loading, error, hasMore, search, handleInputChange, handleScrollToEnd } = useEmployeeSelectData({
    showSmartOptions,
    interviewerIds,
  });

  const handleChange = (event: React.MouseEvent, values: { type: Categories; employee: EmployeeFragment }[]) => {
    if (!values.length || !onEmployeeSelect) return;

    // The nested if is needed to handle the case where onSmartOptionSelected is not defined and the type is SmartOptions
    if (values[0].type === Categories.SmartOptions) {
      if (onSmartOptionSelected) {
        if (values[0].employee.id === SMART_OPTIONS_INTERVIEWERS_ID) {
          onSmartOptionSelected(SmartOptions.Interviewer);
        } else if (values[0].employee.id === SMART_OPTIONS_SCHEDULER_ID) {
          onSmartOptionSelected(SmartOptions.Scheduler);
        }
      }
    } else {
      onEmployeeSelect(values[0].employee);
    }

    filterListSelectInterfaceRef.current?.dismiss();
  };

  const defaultRenderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, values: { type: Categories; employee: EmployeeFragment }) => {
      if (!currentEmployeeId) return null;
      const option = values.employee;

      if (values.type === Categories.SmartOptions) {
        let label = 'Interviewer';
        let subtitle = 'One interviewer from each interview will be assigned as host';

        if (scheduleLocationEnabled) {
          if (option.id === SMART_OPTIONS_INTERVIEWERS_ID) {
            label = 'Interviewers';
            subtitle =
              'ModernLoop will create a unique interview link for each interview. Each interview link will have one of its interviewers as the host.';
          } else if (option.id === SMART_OPTIONS_SCHEDULER_ID) {
            label = 'Scheduler';
            subtitle = 'Whoever schedules the interview will be assigned as host.';
          }
        }

        return (
          <ListItem {...props}>
            <ListItemButton>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                flexWrap="nowrap"
                data-testid={SMART_OPTIONS_INTERVIEWERS_ID}
              >
                <MagicIcon color="tertiary" />
                <Stack>
                  <MaybeTooltip label={label} tooltip={label} />
                  <Label variant="captions" color="high-contrast-grey">
                    {subtitle}
                  </Label>
                </Stack>
              </Stack>
            </ListItemButton>
          </ListItem>
        );
      }

      return (
        <ListItem {...props}>
          <ListItemButton>
            <Stack direction="row" spacing={1} alignItems="center" flexWrap="nowrap">
              <Avatar alt={option.fullName || ''} src={option.slackImageUrl || undefined} />
              <MaybeTooltip
                label={`${option.fullName}${option.id === currentEmployeeId ? ' (you)' : ''}`}
                tooltip={option.fullName || ''}
              />
            </Stack>
          </ListItemButton>
        </ListItem>
      );
    },
    [currentEmployeeId, scheduleLocationEnabled]
  );

  const renderGroup = (params: AutocompleteRenderGroupParams) => {
    const group: React.ReactNode[] = [];
    if (params.group) {
      group.push(
        <ListSubheader disableSticky key={params.key} component="div" className={classes.optionSubheaderLabel}>
          <Label variant="captions" color="info" fontWeight={600}>
            {params.group === Categories.AllEmployees && 'All employees'}
            {params.group === Categories.InterviewPanel && 'Interview Panel'}
            {params.group === Categories.SmartOptions && 'Smart Options'}
          </Label>
        </ListSubheader>
      );
    }
    group.push(params.children);
    return group;
  };

  return (
    <FilterSelect
      placeholderText="Search employees..."
      filterListSelectInterfaceRef={filterListSelectInterfaceRef}
      options={options}
      loading={loading}
      errorMsg={error?.message}
      onChange={handleChange}
      groupBy={(option) => option.type}
      renderGroup={renderGroup}
      getLabel={getLabel}
      getOptionLabel={(option) => option.employee.fullName || ''}
      renderOption={defaultRenderOption}
      onInputChange={(event: React.ChangeEvent<HTMLInputElement>, value) => {
        if (!search && !hasMore) return;
        handleInputChange(value || '');
      }}
      onScrollToEnd={handleScrollToEnd}
      getChildSize={(child: React.ReactNode) => {
        if (!child) return 0;

        const children = (child as React.ReactElement)?.props?.children
          ? React.Children.toArray((child as React.ReactElement)?.props.children)
          : undefined;

        if (
          children &&
          children.length === 1 &&
          React.isValidElement(children[0]) &&
          children[0].props &&
          children[0].props.children &&
          children[0].props.children.props
        ) {
          if (
            !scheduleLocationEnabled &&
            children[0].props.children.props['data-testid'] === SMART_OPTIONS_INTERVIEWERS_ID
          ) {
            return 66;
          }
          if (children[0].props.children.props['data-testid'] === SMART_OPTIONS_INTERVIEWERS_ID) return 80;
          if (children[0].props.children.props['data-testid'] === SMART_OPTIONS_SCHEDULER_ID) return 100;
        }

        return 32;
      }}
    />
  );
};

export default SingleEmployeeSelect;
