import urlcat from 'urlcat';

import { SearchParamKnownKeys } from 'src/hooks/useUrlSearchParams';

/**
 * Debrief Flow Url
 * @param applicationId
 * @returns string
 */
const getDebriefFlowUrl = (applicationId: string, options?: { useRelativeUrl?: boolean; taskId?: string }): string => {
  const { useRelativeUrl = true, taskId } = options || {};
  const currentUrl = new URL(window.location.href);
  const baseUrl = useRelativeUrl ? '' : currentUrl.origin;
  return urlcat(baseUrl, '/debrief/:applicationId', { applicationId, [SearchParamKnownKeys.taskId]: taskId });
};

export default getDebriefFlowUrl;
