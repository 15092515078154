import React, { useCallback, useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog } from '@modernloop/shared/components';

import { useTaskSetDebriefRequiredMutation } from 'src/generated/mloop-graphql';

import TrashIllustration from 'src/assets/images/illustrations/trash_64.png';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type RemoveDebriefRequirementProps = {
  taskId: string;
  open: boolean;
  onClose: () => void;
  onRemove?: () => void;
};

const RemoveDebriefRequirementConfirmation = ({ taskId, open, onClose, onRemove }: RemoveDebriefRequirementProps) => {
  const [loading, setLoading] = useState(false);
  const [taskSetDebriefRequiredMutation] = useTaskSetDebriefRequiredMutation();
  const activityFeedRefetch = useActivityFeedRefetch();

  const handleRemoveDebriefRequirement = useCallback(() => {
    setLoading(true);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    taskSetDebriefRequiredMutation({
      variables: {
        input: {
          taskId,
          isDebriefRequired: false,
        },
      },
      optimisticResponse: {
        taskSetDebriefRequired: {
          id: taskId,
          isDebriefRequired: false,
        },
      },
    })
      .then(() => activityFeedRefetch())
      .finally(() => {
        setLoading(false);
        onClose();
        onRemove?.();
      });
  }, [activityFeedRefetch, onClose, onRemove, taskId, taskSetDebriefRequiredMutation]);

  return (
    <Dialog
      dataTestId="delete-tag-modal"
      open={open}
      onClose={onClose}
      title="Remove requirement?"
      subTitle="Data for requirements cannot be restored"
      decoration={<img style={{ width: '64px', height: '64px' }} src={TrashIllustration} alt="Delete tag" />}
      cancelOptions={{
        onClick: onClose,
      }}
      submitOptions={{
        label: 'Remove requirement',
        onClick: handleRemoveDebriefRequirement,
        isDangerous: true,
        isDisabled: loading,
        isLoading: loading,
      }}
    >
      Removing this requirement will also cancel any currently scheduled debrief. You can re-add this requirement later,
      but the current data for it will not be restored.
    </Dialog>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TaskSetDebriefRequiredMutation = gql`
  mutation TaskSetDebriefRequired($input: TaskSetDebriefRequiredInput!) {
    taskSetDebriefRequired(input: $input) {
      id
      isDebriefRequired
    }
  }
`;

export default RemoveDebriefRequirementConfirmation;
