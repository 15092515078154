import React from 'react';

import BaseIcon, { Props } from './Icon';

const UserAddIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6C4 4.34315 5.34315 3 7 3C8.65685 3 10 4.34315 10 6V7C10 8.65685 8.65685 10 7 10C5.34315 10 4 8.65685 4 7V6ZM9.2241 11.4991C11.1762 11.9522 13.0378 13.0841 13.5912 15.0651C13.742 15.6049 13.574 16.1139 13.2527 16.4651C12.9416 16.8053 12.4878 17.0034 12.0115 17.0065L4.01148 17.059C3.04051 17.0653 2.25 16.28 2.25 15.309V13.5561C2.25 12.9541 2.56535 12.3318 3.18513 12.0415C4.96083 11.2099 7.253 11.0416 9.2241 11.4991ZM14.5 5.25C14.9142 5.25 15.25 5.58579 15.25 6L15.25 7.75H17C17.4142 7.75 17.75 8.08579 17.75 8.5C17.75 8.91421 17.4142 9.25 17 9.25H15.25L15.25 11C15.25 11.4142 14.9142 11.75 14.5 11.75C14.0858 11.75 13.75 11.4142 13.75 11L13.75 9.25H12C11.5858 9.25 11.25 8.91421 11.25 8.5C11.25 8.08579 11.5858 7.75 12 7.75H13.75L13.75 6C13.75 5.58579 14.0858 5.25 14.5 5.25Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default UserAddIcon;
