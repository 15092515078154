import { Quill } from 'react-quill';

// https://github.com/quilljs/quill/issues/2351
const PIXEL_LEVELS = [1, 2, 3, 4, 5, 6, 7, 8];
const TAB_MULTIPLIER = 30;

const Parchment = Quill.import('parchment');

class IndentAttributor extends Parchment.Attributor.Style {
  constructor(formatName: string, styleProperty: unknown, attributorOptions: unknown) {
    super(formatName, styleProperty, attributorOptions);
  }

  private level: number = 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add(node: any, value: any) {
    if (this.level < 8 && value === '+1') {
      this.level += 1;
    } else if (this.level > 0 && value === '-1') {
      this.level -= 1;
    }

    if (this.level === 0) {
      return super.remove(node);
    }
    return super.add(node, `${this.level * TAB_MULTIPLIER}px`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value(node: any) {
    return parseFloat(super.value(node)) / TAB_MULTIPLIER || undefined; // Don't return NaN
  }
}

const IndentStyle = new IndentAttributor('indent', 'margin-left', {
  scope: Parchment.Scope.BLOCK,
  whitelist: PIXEL_LEVELS.map((value) => `${value * TAB_MULTIPLIER}px`),
});

export default IndentStyle;
