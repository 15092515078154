import React from 'react';

import { Dialog } from '@modernloop/shared/components';

type Props = {
  onClose: () => void;
  onContinue: () => void;
};

const SelfScheduledApplicationStageAlert = ({ onClose, onContinue }: Props) => {
  return (
    <Dialog
      open
      dataTestId="schedule-object-self-scheduled-application-stage-alert"
      title="Heads up"
      onClose={onClose}
      cancelOptions={{
        dataTestId: 'schedule-object-self-scheduled-application-stage-alert-cancel-button',
        label: 'Cancel',
        onClick: onClose,
      }}
      submitOptions={{
        dataTestId: 'schedule-object-self-scheduled-application-stage-alert-continue',
        label: 'Continue',
        onClick: onContinue,
      }}
    >
      This interview was self-scheduled by the candidate. Making changes to this schedule will remove their ability to
      reschedule it using their self-schedule link.
    </Dialog>
  );
};

export default SelfScheduledApplicationStageAlert;
