import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CodeIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7155 4.28183C12.1123 4.40085 12.3374 4.81896 12.2184 5.21571L9.21837 15.2157C9.09935 15.6125 8.68123 15.8376 8.28449 15.7186C7.88774 15.5995 7.66261 15.1814 7.78163 14.7847L10.7816 4.78469C10.9007 4.38794 11.3188 4.1628 11.7155 4.28183ZM6.53033 6.46988C6.82322 6.76277 6.82322 7.23765 6.53033 7.53054L4.06066 10.0002L6.53033 12.4699C6.82322 12.7628 6.82322 13.2376 6.53033 13.5305C6.23744 13.8234 5.76256 13.8234 5.46967 13.5305L2.46967 10.5305C2.17678 10.2376 2.17678 9.76277 2.46967 9.46988L5.46967 6.46988C5.76256 6.17699 6.23744 6.17699 6.53033 6.46988ZM14.5303 6.46988C14.2374 6.17699 13.7626 6.17699 13.4697 6.46988C13.1768 6.76277 13.1768 7.23765 13.4697 7.53054L15.9393 10.0002L13.4697 12.4699C13.1768 12.7628 13.1768 13.2376 13.4697 13.5305C13.7626 13.8234 14.2374 13.8234 14.5303 13.5305L17.5303 10.5305C17.8232 10.2376 17.8232 9.76277 17.5303 9.46988L14.5303 6.46988Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
