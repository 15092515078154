/* eslint-disable max-lines */
import React, { MutableRefObject, useLayoutEffect } from 'react';

import { useFlag } from '@modernloop/shared/feature-flag';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box, Typography } from '@mui/material';
import { formatDuration } from 'date-fns';
import { round } from 'lodash';
import PopperJs from 'popper.js';

import { InterviewLimitType, InterviewerFlag, StringEmployeePref } from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import Avatar from 'src/components/Avatar';
import Divider from 'src/components/Divider';
import ImageBlock from 'src/components/ImageBlock';
import Stack from 'src/components/Stack';
import { DayIcon, LastWeekIcon, LinkIcon, PauseIcon, TimezoneIcon, WeekIcon } from 'src/components/icons';
import TraineeIcon from 'src/components/icons/Trainee';
import WarningIcon from 'src/components/icons/Warning';
import Label from 'src/components/label';

import { useEmployeePrefString } from 'src/hooks/api/employee/get';

import { ArrayElement } from 'src/types/utils';

import { InterviewEvent, InterviewerLoadAndLimit } from 'src/utils/api/getScheduleOptions';

import InternalInterviewerNote from 'src/views-new/InterviewerDetailsPage/InterviewerInfo/InternalInterviewerNoteSection/InternalNote';

import {
  getImageBlockForFlag,
  getInterviewerFlagSeverity,
  isLinkedSeat,
  isReverseShadowTrainee,
  isShadowTrainee,
  renderEventTimeRange,
} from './utils';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleInterviewerDetailsProps = {
  event?: InterviewEvent;
  interviewer: ArrayElement<NonNullable<InterviewEvent['interviewers']>>;
  loadAndLimit?: InterviewerLoadAndLimit;
  popperRef?: MutableRefObject<PopperJs | null>;
};

const ScheduleInterviewerDetails = ({
  event,
  interviewer,
  loadAndLimit,
  popperRef,
}: ScheduleInterviewerDetailsProps): JSX.Element => {
  const isFastTrackTrainingEnabled = useFlag('user_fast_track_interview_module');
  const showInternalNote = useFlag('user-internal-interviewer-note');

  const interviewerTimeRange = event ? renderEventTimeRange(event, interviewer.employee.timezone || 'UTC') : '';
  const flags = interviewer.flags || [];
  const warnings = flags.filter((flag) => getInterviewerFlagSeverity(flag) === 'warning');
  const errors = flags.filter((flag) => getInterviewerFlagSeverity(flag) === 'error');
  const successes = flags.filter((flag) => getInterviewerFlagSeverity(flag) === 'success');
  const paused = flags.includes(InterviewerFlag.Paused);
  const events = interviewer.eventsWithConflicts || [];

  const [internalNote] = useEmployeePrefString(
    StringEmployeePref.StrInternalInterviewerNote,
    interviewer.employeeId || ''
  );

  useLayoutEffect(() => {
    if (!popperRef?.current) return;
    popperRef.current.update();
  }, [popperRef]);

  const getDailyLoadLabel = (value: InterviewerLoadAndLimit): string => {
    if (value.dailyInterviewLimit) {
      if (value.dailyInterviewLimit.type === InterviewLimitType.NumberOfMinutes) {
        return `${
          value.dailyLoadMinutes
            ? formatDuration({
                hours: value.dailyLoadMinutes ? round(value.dailyLoadMinutes / 60, 2) : 0,
              })
            : '0 hours'
        } today`;
      }
    }
    return `${value.dailyLoad ?? 0} ${value.dailyLoad === 1 ? 'interview' : 'interviews'} today`;
  };

  const getWeeklyLoadLabel = (value: InterviewerLoadAndLimit): string => {
    if (value.weeklyInterviewLimit) {
      if (value.weeklyInterviewLimit.type === InterviewLimitType.NumberOfMinutes) {
        return `${
          value.weeklyLoadMinutes
            ? formatDuration({
                hours: value.weeklyLoadMinutes ? round(value.weeklyLoadMinutes / 60, 2) : 0,
              })
            : '0 hours'
        } this week`;
      }
    }
    return `${value.weeklyLoad ?? 0} ${value.weeklyLoad === 1 ? 'interview' : 'interviews'} this week`;
  };

  const getDailyLimitLabel = (value: InterviewerLoadAndLimit): string => {
    if (value.dailyInterviewLimit) {
      if (value.dailyInterviewLimit.limit === 0) return 'No limit';
      if (value.dailyInterviewLimit.type === InterviewLimitType.NumberOfMinutes) {
        return `Limit: ${
          value.dailyInterviewLimit.limit
            ? formatDuration({
                hours: round(value.dailyInterviewLimit.limit / 60, 2),
              })
            : '0 hours'
        } per day`;
      }
      return `Limit: ${value.dailyInterviewLimit.limit} ${
        value.dailyInterviewLimit.limit === 1 ? 'interview' : 'interviews'
      } per day`;
    }

    return `No limit`;
  };

  const getWeeklyLimitLabel = (value: InterviewerLoadAndLimit): string => {
    if (value.weeklyInterviewLimit) {
      if (value.weeklyInterviewLimit.limit === 0) return 'No limit';
      if (value.weeklyInterviewLimit.type === InterviewLimitType.NumberOfMinutes) {
        return `Limit: ${
          value.weeklyInterviewLimit.limit
            ? formatDuration({
                hours: round(value.weeklyInterviewLimit.limit / 60, 2),
              })
            : '0 hours'
        } per week`;
      }
      return `Limit: ${value.weeklyInterviewLimit.limit} ${
        value.weeklyInterviewLimit.limit === 1 ? 'interview' : 'interviews'
      } per week`;
    }

    return `No limit`;
  };

  const getLastWeeklyLoadLabel = (value: InterviewerLoadAndLimit): string => {
    if (value.weeklyInterviewLimit) {
      if (value.weeklyInterviewLimit.type === InterviewLimitType.NumberOfMinutes) {
        return `${
          value.lastWeeklyLoadMinutes
            ? formatDuration({
                hours: value.lastWeeklyLoadMinutes ? round(value.lastWeeklyLoadMinutes / 60, 2) : 0,
              })
            : '0 hours'
        } last week`;
      }
    }
    return `${value.lastWeeklyLoad ?? 0} ${value.lastWeeklyLoad === 1 ? 'interview' : 'interviews'} last week`;
  };

  return (
    <Box
      // Fixing the width so that the tooltip always show up either to the right or left.
      // Without fixed width the width varies and depending on width of window
      // for some interviewers it shows on right and some it shows on left.
      width="280px"
      maxHeight="60vh"
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      onClick={(e) => {
        // This is to prevent going to calendar view when user clicks on this component
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <Stack style={{ padding: '8px', paddingBottom: 0 }} direction="column" spacing={1}>
        <Stack direction="column" spacing={1}>
          <ImageBlock
            image={
              interviewer.employee.slackImageUrl ? (
                <Avatar
                  alt={interviewer.employee.fullName || ''}
                  size="36px"
                  src={interviewer.employee.slackImageUrl}
                />
              ) : (
                <></>
              )
            }
            title={
              <Label fontWeight={600} variant="body">
                {interviewer.employee.fullName}
              </Label>
            }
            caption={interviewer.employee.title ?? interviewer.employee.email}
          />
          {paused && (
            <Alert
              alignItems="center"
              status="warning"
              icon={<PauseIcon />}
              title="Interviewer is paused from interviewing at this time and cannot be selected."
            />
          )}
          <Divider />
          {isShadowTrainee(flags) && (
            <ImageBlock
              image={<TraineeIcon color="max-contrast-grey" />}
              title={isFastTrackTrainingEnabled ? 'Trainee' : 'Shadow'}
              alignItems="center"
            />
          )}
          {isReverseShadowTrainee(flags) && (
            <ImageBlock
              image={<TraineeIcon color="max-contrast-grey" />}
              title={isFastTrackTrainingEnabled ? 'Trainee' : 'Reverse Shadow'}
              alignItems="center"
            />
          )}
          {isLinkedSeat(flags) && (
            <ImageBlock image={<LinkIcon color="max-contrast-grey" />} title="Linked seat" alignItems="center" />
          )}
          <ImageBlock
            image={<TimezoneIcon color="max-contrast-grey" />}
            title={interviewerTimeRange}
            caption={interviewer.employee.timezone || undefined}
            alignItems="center"
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <div>
            <Label variant="captions" fontWeight={600}>
              Interview load
            </Label>
            <Label variant="captions" color="high-contrast-grey">
              Not including this schedule
            </Label>
          </div>
          <ImageBlock
            alignItems="center"
            image={<DayIcon color="max-contrast-grey" />}
            title={getDailyLoadLabel(
              loadAndLimit ?? interviewer.loadAndLimit ?? { employeeId: interviewer.employeeId, dailyLoad: 0 }
            )}
            caption={getDailyLimitLabel(
              loadAndLimit ??
                interviewer.loadAndLimit ?? {
                  employeeId: interviewer.employeeId,
                  dailyInterviewLimit: { type: InterviewLimitType.NumberOfInterviews, limit: 0 },
                }
            )}
          />
          <ImageBlock
            alignItems="center"
            image={<WeekIcon color="max-contrast-grey" />}
            title={getWeeklyLoadLabel(
              loadAndLimit ?? interviewer.loadAndLimit ?? { employeeId: interviewer.employeeId, weeklyLoad: 0 }
            )}
            caption={getWeeklyLimitLabel(
              loadAndLimit ??
                interviewer.loadAndLimit ?? {
                  employeeId: interviewer.employeeId,
                  weeklyInterviewLimit: { type: InterviewLimitType.NumberOfInterviews, limit: 0 },
                }
            )}
          />
          <ImageBlock
            image={<LastWeekIcon color="max-contrast-grey" />}
            title={getLastWeeklyLoadLabel(
              loadAndLimit ?? interviewer.loadAndLimit ?? { employeeId: interviewer.employeeId, lastWeeklyLoad: 0 }
            )}
            alignItems="center"
          />
        </Stack>
      </Stack>

      <Stack
        direction="column"
        style={{
          marginTop: '8px',
          gap: '8px',
        }}
      >
        {successes.length > 0 && (
          <Alert
            status="success"
            disableIcon
            title={
              <Stack direction="column" spacing={0}>
                {successes.map((flag) => getImageBlockForFlag(flag, events))}
              </Stack>
            }
          />
        )}
        {warnings.length > 0 && (
          <Alert
            status="warning"
            disableIcon
            title={
              <Stack direction="column" spacing={0}>
                {warnings.map((flag) => getImageBlockForFlag(flag, events))}
              </Stack>
            }
          />
        )}
        {errors.length > 0 && (
          <Alert
            status="error"
            disableIcon
            title={
              <Stack direction="column" spacing={1}>
                <ImageBlock
                  image={<WarningIcon color="error" />}
                  title={
                    <Label color="error" fontWeight={600} variant="captions">
                      Issues
                    </Label>
                  }
                />
                {errors.map((flag) => getImageBlockForFlag(flag, events, interviewer.companyHolidays))}
              </Stack>
            }
          />
        )}
        {internalNote && showInternalNote && (
          <>
            <Typography variant="subtitle2" paddingX="8px" color="text.primary">
              Interviewer Internal Note
            </Typography>
            <InternalInterviewerNote note={internalNote} />
          </>
        )}
      </Stack>
    </Box>
  );
};

export default ScheduleInterviewerDetails;
