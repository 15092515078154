/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { CrossIcon, EditIcon } from '@modernloop/shared/icons';
import { Avatar, Box, CircularProgress, IconButton, Popover, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { unset } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  AttributeNameValueMapInput,
  InterviewModuleMemberSearchOrderByProperty,
  JobStageInterviewSeatType,
  ModuleSeat as ModuleSeatGql,
  ModuleSeat_SeatFragment,
  Sort,
  TrainingStatus,
  useInterviewModuleMemberFilteredByAttributesV2Query,
  useModuleMemberStatsV2Query,
} from 'src/generated/mloop-graphql';

import Chip from 'src/components/chip';
import { DirectoryIcon, FilterIcon, ModulesIcon, TraineeIcon } from 'src/components/icons';
import Tooltip from 'src/components/tooltip';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

import { AttributeMap, JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

// eslint-disable-next-line modernloop/restrict-imports.cjs
import EmployeeAndAttributesFilter from 'src/views-new/EmployeeAndAttributesFilter';

import { PAGE_SIZE } from 'src/constants';

import { useAvatarSxProps, useGridSxProps, useSxProps } from './sxProps';

interface Props {
  rowRef: React.RefObject<HTMLDivElement>;
  handleMouseOut: () => void;
  handleMouseOver: () => void;
  seatIndex: number;
  onEdit: () => void;
  showOptions: boolean;
  onDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onUpdated: (seat: ModuleSeat_SeatFragment) => void;
}

interface Fragments {
  seat: ModuleSeat_SeatFragment;
}

const ModuleSeat: FCWithFragments<Fragments, Props> = ({
  seat,
  rowRef,
  handleMouseOut,
  handleMouseOver,
  seatIndex,
  onEdit,
  showOptions,
  onDelete,
  onUpdated,
}) => {
  const sxProps = useSxProps();
  const gridSxProps = useGridSxProps();
  const avatarSxProps = useAvatarSxProps();
  const interviewModule = seat?.moduleSeat?.interviewModule;

  const attributeMap = useMemo(() => {
    const result: AttributeMap = {};
    if (seat?.moduleSeat?.jobStageInterviewSeatAttributes) {
      seat.moduleSeat.jobStageInterviewSeatAttributes.forEach((attribute) => {
        if (!result[attribute.attributeNameId]) {
          result[attribute.attributeNameId] = [];
        }
        result[attribute.attributeNameId].push(attribute.attributeTagValueId);
      });
    }
    return result;
  }, [seat?.moduleSeat?.jobStageInterviewSeatAttributes]);

  const [showAttributeFilter, setShowAttributeFilter] = useState(false);

  // load all the interview module stats
  // this is used to show the # of trained when no filters are applied
  const { data: statsData, loading: statsLoading, refetch: statsRefetch } = useModuleMemberStatsV2Query();

  const isFastTrackEnabled = useFlag('user_fast_track_interview_module');
  const moduleMemberQueryVaraibles = useMemo(() => {
    const variables = {
      input: {
        interviewModuleId: interviewModule?.id,
        pageInput: {
          limit: PAGE_SIZE,
          offset: 0,
        },
        isArchived: false,
        isAtsDisabled: false,
        isDirectoryDisabled: false,
        orderBy: {
          property: InterviewModuleMemberSearchOrderByProperty.ProgressPercentage,
          direction: Sort.Desc,
        },
      },
    };

    if (!isFastTrackEnabled) {
      unset(variables, 'input.orderBy');
    }

    return variables;
  }, [interviewModule?.id, isFastTrackEnabled]);

  const {
    data: searchModuleMembersData,
    loading: moduleMembersLoading,
    refetch: searchModuleMembers,
  } = useInterviewModuleMemberFilteredByAttributesV2Query({
    variables: moduleMemberQueryVaraibles,
  });

  const interviewModuleMembers = searchModuleMembersData?.interviewModuleMemberSearch?.items;

  // when we have the search results, filter into trained, shadow, reverse shadow
  let filteredTrained =
    interviewModuleMembers?.filter((value) => {
      return value.status === TrainingStatus.Trained;
    }) || [];

  const selectedTrainedInterviewers = interviewModuleMembers?.filter((member) => {
    return (
      member.status === TrainingStatus.Trained && seat.moduleSeat?.selectedEmployeeIds?.includes(member.employeeId)
    );
  });

  const selectedTraineeInterviewers = interviewModuleMembers?.filter((member) => {
    return (
      member.status !== TrainingStatus.Trained && seat.moduleSeat?.selectedEmployeeIds?.includes(member.employeeId)
    );
  });

  const jobStageInterviewSeat: JobStageInterviewSeat = useMemo(() => {
    return {
      id: seat.id,
      jobStageInterviewId: '', // This is not used by EmployeeAndAttributesFilter
      index: seatIndex,
      type: JobStageInterviewSeatType.Module,
      interviewId: seat.moduleSeat?.interviewModuleId,
      attributeMap,
      selectedTrainedInterviewerIds: selectedTrainedInterviewers?.map((member) => member.employeeId) || [],
      selectedShadowInterviewerIds:
        selectedTraineeInterviewers
          ?.filter(
            (member) =>
              member.status === TrainingStatus.Shadow &&
              seat.moduleSeat?.selectedEmployeeIds.includes(member.employeeId)
          )
          .map((member) => member.employeeId) || [],
      selectedReverseShadowInterviewerIds:
        selectedTraineeInterviewers
          ?.filter(
            (member) =>
              member.status === TrainingStatus.ReverseShadow &&
              seat.moduleSeat?.selectedEmployeeIds.includes(member.employeeId)
          )
          .map((member) => member.employeeId) || [],
      moduleSeat: {
        interviewModuleId: seat.moduleSeat?.interviewModuleId,
        interviewModule: seat.moduleSeat?.interviewModule,
        selectedEmployeeIds: seat.moduleSeat?.selectedEmployeeIds || [],
        jobStageInterviewSeatAttributes: seat.moduleSeat?.jobStageInterviewSeatAttributes,
      } as ModuleSeatGql,
    };
  }, [
    attributeMap,
    seat.id,
    seat.moduleSeat?.interviewModule,
    seat.moduleSeat?.interviewModuleId,
    seat.moduleSeat?.jobStageInterviewSeatAttributes,
    seat.moduleSeat?.selectedEmployeeIds,
    seatIndex,
    selectedTrainedInterviewers,
    selectedTraineeInterviewers,
  ]);

  const handleShowAttributeFilter = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowAttributeFilter(true);

    // This is to prevent onEdit from trigging which is attached to the parent <Grid />
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onEdit();
  };

  const handleHideAttributeFilter = () => {
    setShowAttributeFilter(false);
  };

  useEffect(() => {
    // rerun the filter query every time the user changes their attributes selection
    if (!interviewModule || !showAttributeFilter) {
      return;
    }

    const attributesInput: AttributeNameValueMapInput[] = [];
    for (const [key, value] of Object.entries(attributeMap)) {
      attributesInput.push({ attributeNameId: key, attributeValueIds: value });
    }

    searchModuleMembers({ input: { ...moduleMemberQueryVaraibles.input, attributeNameValueMap: attributesInput } });
    statsRefetch();
  }, [
    attributeMap,
    interviewModule,
    moduleMemberQueryVaraibles,
    searchModuleMembers,
    showAttributeFilter,
    statsRefetch,
  ]);

  // Pre calculating a flat list of all the tagId's applied to module
  let flatAppliedAttributes: string[] = [];
  Object.keys(attributeMap ?? {}).forEach((nameId) => {
    if (!attributeMap || !attributeMap[nameId]) return;
    flatAppliedAttributes = flatAppliedAttributes.concat(attributeMap[nameId]);
  });

  if (!seat) return null;
  if (!seat.moduleSeat?.interviewModuleId) return <Typography variant="caption">None Selected</Typography>;

  if (!interviewModule) return null;

  const hasManuallySelectedTrainedInterviewers = selectedTrainedInterviewers && selectedTrainedInterviewers.length > 0;
  const hasManuallySelectedInTrainingInterviewers =
    selectedTraineeInterviewers && selectedTraineeInterviewers.length > 0;

  filteredTrained = hasManuallySelectedTrainedInterviewers ? selectedTrainedInterviewers : filteredTrained;

  const interviewStats = statsData?.interviewModuleMemberStats?.filter(
    (stats) => stats?.interviewModule?.id === interviewModule?.id
  )?.[0];

  let trainingLabel = '';

  let totalShadow = [];
  let totalReverseShadow = [];

  if (interviewStats) {
    totalShadow = interviewStats.interviewModule?.membersInShadowCount;
    totalReverseShadow = interviewStats.interviewModule?.membersInReverseShadowCount;
  }

  if (isFastTrackEnabled) {
    const totalInTrainig = Number(totalShadow || 0) + Number(totalReverseShadow || 0);
    trainingLabel = `(${totalInTrainig} in training)`;
  } else if (totalShadow && totalReverseShadow) {
    trainingLabel = `(${totalShadow} shadow, ${totalReverseShadow} reverse shadow)`;
  } else if (totalShadow) {
    trainingLabel = `(${totalShadow} shadow)`;
  } else if (totalReverseShadow) {
    trainingLabel = `(${totalReverseShadow} reverse shadow)`;
  } else {
    trainingLabel = `(0 in training)`;
  }

  const trained = filteredTrained?.length || 0;

  return (
    <>
      <Grid ref={rowRef}>
        <Box
          data-testid="module-seat-box-container"
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseOut}
          sx={sxProps.box}
          onClick={handleEdit}
        >
          <Grid container justifyContent="space-between" sx={gridSxProps.root} spacing={1} tabIndex={0} wrap="nowrap">
            <Grid style={{ flexGrow: 1 }}>
              <Grid container direction="column">
                <Grid container alignItems="center" spacing={1} style={{ width: '100%' }}>
                  <Grid>
                    <Avatar sx={[avatarSxProps.root, trained === 0 && !moduleMembersLoading && sxProps.errorChip]}>
                      {seatIndex + 1}
                    </Avatar>
                  </Grid>
                  <Grid>
                    <Typography>One from:</Typography>
                  </Grid>
                  <Grid style={{ overflow: 'hidden', maxWidth: '100%' }}>
                    <Chip
                      key={seat.moduleSeat.interviewModuleId}
                      sx={trained === 0 && !moduleMembersLoading ? sxProps.errorChip : undefined}
                      label={<MaybeTooltip tooltip={interviewModule.name} label={interviewModule.name} />}
                      avatar={<ModulesIcon />}
                      variant={showOptions ? 'outlined' : 'default'}
                    />
                  </Grid>
                  {flatAppliedAttributes.length > 0 && (
                    <Grid>
                      <Chip
                        sx={trained === 0 && !moduleMembersLoading ? sxProps.errorChip : undefined}
                        label={`${flatAppliedAttributes.length}`}
                        avatar={<FilterIcon />}
                        variant={showOptions ? 'outlined' : 'default'}
                      />
                    </Grid>
                  )}
                  {(hasManuallySelectedTrainedInterviewers || hasManuallySelectedInTrainingInterviewers) && (
                    <Grid>
                      <Chip
                        label={`${
                          (selectedTrainedInterviewers?.length || 0) + (selectedTraineeInterviewers?.length || 0)
                        }`}
                        avatar={<DirectoryIcon />}
                        variant={showOptions ? 'outlined' : 'default'}
                      />
                    </Grid>
                  )}
                  <Grid>
                    <Typography
                      data-testid="module-seat-trained-label"
                      sx={trained === 0 && !moduleMembersLoading ? sxProps.errorLabel : undefined}
                    >{`(${trained} trained)`}</Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" spacing={1} sx={sxProps.shadow}>
                  <Grid>
                    <TraineeIcon />
                  </Grid>
                  <Grid>
                    <Typography>Training:</Typography>
                  </Grid>
                  <Grid style={{ overflow: 'hidden', maxWidth: '100%' }}>
                    <Chip
                      key={seat.moduleSeat.interviewModuleId}
                      sx={trained === 0 && !moduleMembersLoading ? sxProps.errorChip : undefined}
                      label={<MaybeTooltip tooltip={interviewModule.name} label={interviewModule.name} />}
                      avatar={<ModulesIcon />}
                      variant={showOptions ? 'outlined' : 'default'}
                    />
                  </Grid>
                  <Grid>
                    <>
                      {statsLoading && <CircularProgress size={24} />}
                      {statsData && <Typography data-testid="module-seat-training-label">{trainingLabel}</Typography>}
                    </>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" alignItems="center" xs wrap="nowrap" sx={sxProps.options}>
              <Grid>
                <Tooltip tooltip="Apply attributes">
                  <IconButton
                    sx={sxProps.actionButton}
                    onClick={handleShowAttributeFilter}
                    size="small"
                    data-testid="module-seat-filter-icon-button"
                  >
                    <FilterIcon fontSize={20} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid>
                <Tooltip tooltip="Edit">
                  <IconButton
                    sx={sxProps.actionButton}
                    onClick={handleEdit}
                    size="small"
                    data-testid="module-seat-edit-icon-button"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid>
                <Tooltip tooltip="Delete">
                  <IconButton
                    sx={sxProps.actionButton}
                    onClick={onDelete}
                    size="small"
                    data-testid="module-seat-delete-icon-button"
                  >
                    <CrossIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {showAttributeFilter && interviewModule.name && (
        <Popover
          data-testid="module-seat-attribute-filter-popover"
          id={seat.moduleSeat.interviewModuleId}
          open={showAttributeFilter}
          anchorEl={rowRef.current}
          onClose={handleHideAttributeFilter}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {moduleMembersLoading && <CircularProgress size={24} />}
          {!moduleMembersLoading && (
            <EmployeeAndAttributesFilter
              interviewModuleMembers={interviewModuleMembers || interviewModule?.interviewModuleMembers?.items || []}
              interviewModuleId={interviewModule?.id}
              seat={jobStageInterviewSeat}
              skipRedux
              onUpdated={(updatedSeat: JobStageInterviewSeat) => {
                const jobStageInterviewSeatAttributes = Object.keys(updatedSeat.attributeMap || {})
                  .map((attributeNameId) => {
                    if (!updatedSeat.attributeMap) return [];
                    return updatedSeat.attributeMap[attributeNameId].map((attributeTagValueId) => {
                      return {
                        id: uuid(),
                        jobStageInterviewSeatId: seat.id,
                        attributeNameId,
                        attributeTagValueId,
                      };
                    });
                  })
                  .flat();

                onUpdated({
                  id: updatedSeat.id,
                  moduleSeat: {
                    interviewModuleId: updatedSeat.moduleSeat?.interviewModuleId,
                    interviewModule: updatedSeat.moduleSeat?.interviewModule,
                    selectedEmployeeIds: [
                      ...(updatedSeat.selectedTrainedInterviewerIds || []),
                      ...(updatedSeat.selectedShadowInterviewerIds || []),
                      ...(updatedSeat.selectedReverseShadowInterviewerIds || []),
                    ],
                    jobStageInterviewSeatAttributes: jobStageInterviewSeatAttributes.length
                      ? jobStageInterviewSeatAttributes
                      : undefined,
                  },
                });
              }}
            />
          )}
        </Popover>
      )}
    </>
  );
};

ModuleSeat.fragments = {
  seat: gql`
    fragment ModuleSeat_seat on JobStageInterviewSeat {
      id
      moduleSeat {
        interviewModuleId
        interviewModule {
          id
          orgId
          shadowsRequired
          reverseShadowsRequired
          membersCount
          name
          membersTrainedCount
          membersInTrainingCount
          membersInReverseShadowCount
          membersInShadowCount
          membersInPendingApprovalCount
          graduateFromShadowApprovalType
          graduateFromReverseShadowApprovalType
          interviewModuleMembers(input: { pageInput: { limit: 500, offset: 0 } }) {
            items {
              id
              interviewModuleId
              ...EmployeeFilter
            }
          }
        }
        selectedEmployeeIds
        jobStageInterviewSeatAttributes {
          id
          jobStageInterviewSeatId
          attributeNameId
          attributeTagValueId
        }
      }
    }
  `,
};

export const InterviewModuleFilteredByAttributesQuery = gql`
  ${EmployeeAndAttributesFilter.fragment.employeeAndAttributesFilter}

  fragment EditModuleSeatInterviewModuleV2 on InterviewModuleMember {
    interviewModuleId
    ...EmployeeAndAttributesFilter
  }

  query InterviewModuleMemberFilteredByAttributesV2($input: InterviewModuleMemberSearchInput!) {
    interviewModuleMemberSearch(input: $input) {
      items {
        ...EditModuleSeatInterviewModuleV2
      }
    }
  }
`;

export const InterviewModuleStats = gql`
  fragment EditModuleSeatInterviewStatsV2 on InterviewModule {
    id
    name
    membersCount
    membersInReverseShadowCount
    membersInShadowCount
    membersTrainedCount
  }

  query ModuleMemberStatsV2 {
    interviewModuleMemberStats {
      interviewModule {
        ...EditModuleSeatInterviewStatsV2
      }
    }
  }
`;

export default ModuleSeat;
