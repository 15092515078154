import React from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { ScheduleTask_TaskFragment, TaskStatus } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ScheduleTaskDetails from './ScheduleTaskDetails';
import ScheduleTaskRequirements from './ScheduleTaskRequirements';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleTaskProps = {
  showRequirements: boolean;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleTaskFragmentProps = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/component-with-fragments.cjs
  task: ScheduleTask_TaskFragment;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const ScheduleTask: FCWithFragments<ScheduleTaskFragmentProps, ScheduleTaskProps> = ({ task, showRequirements }) => {
  if (!task) return null;

  const { status } = task;

  return (
    <Stack spacing={2} p={2.5} pt={0}>
      <ScheduleTaskDetails task={task} />

      {showRequirements && <ScheduleTaskRequirements taskId={task.id} isCancelled={status === TaskStatus.Canceled} />}
    </Stack>
  );
};

ScheduleTask.fragments = {
  task: gql`
    ${ScheduleTaskDetails.fragments.task}
    fragment ScheduleTask_task on Task {
      status
      ...ScheduleTaskDetails_task
    }
  `,
};

export default ScheduleTask;
