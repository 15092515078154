/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';

import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import ApolloSentryLink from 'apollo-sentry-link';
import { buildAxiosFetch } from 'axios-fetch';

import { ErrorLink } from 'src/contexts/ApolloProvider/ErrorLink';

import { API_URL } from 'src/utils/api';
import axios from 'src/utils/axios';

const interviewer = new HttpLink({
  uri: `${API_URL}/graphql/interviewer/`,
  fetch: buildAxiosFetch(axios),
});

const client = new ApolloClient({
  link: ApolloLink.from([ErrorLink, ApolloSentryLink, interviewer]),
  cache: new InMemoryCache({}),
});

const ApolloProviderX: FC = ({ children }) => <ApolloProvider client={client}>{children}</ApolloProvider>;

export default ApolloProviderX;
