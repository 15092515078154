/* eslint-disable max-lines */
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { addMinutes, parseISO } from 'date-fns';
import { keyBy, uniq } from 'lodash';

import { setScheduleOptionsSetupRefresh } from 'src/slices/scheduling';

import { updateZoomInfo, updateZoomUserId } from 'src/store/actions/schedule-communications';
import { getHardConflicts } from 'src/store/selectors/conflicts';
import {
  getCustomVideoMeetingLink,
  getSlackChannelTemplateId,
  getVideoMeetingLink,
  getZoomInfo,
  getZoomUserId,
} from 'src/store/selectors/schedule-communications';
import { getScheduleUpdates } from 'src/store/selectors/schedule-update';
import { getScheduleById } from 'src/store/selectors/schedules';
import debriefSlice, {
  DebriefStage,
  DebriefStartTimeType,
  Employee,
  HiringTeamRoles,
  InterviewerEventContent,
} from 'src/store/slices/debrief';
import scheduleCommunicationsSlice, { MeetingRoomSuggestionInterface } from 'src/store/slices/schedule-communications';

import { TimeBlock, TimeBlockWeek } from 'src/types/preferenes';

import getPlaceholders from 'src/utils/PlaceholderFiller/getPlaceholders';
import createDebrief, { InterviewerRequestBodyData, RequestBody } from 'src/utils/api/createDebrief';
import getDebriefOptions from 'src/utils/api/getDebriefOptions';
import { getExternalErrorMessage } from 'src/utils/error';
import { getTimePeriods } from 'src/utils/getTimePeriods';
import htmlToMrkdwn from 'src/utils/htmlToMrkdwn';
import logError from 'src/utils/logError';

import { applyUpdatesToSchedule } from 'src/views-new/ScheduleFlow/Steps/Schedule/applyUpdatesToSchedule';

import { AppThunk } from 'src/store';

import { saveSchedules } from './schedules';

export const clear =
  (applicationId: string): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.clear(applicationId));
  };

export const initialize =
  (applicationId: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    dispatch(debriefSlice.actions.clear(applicationId));
    dispatch(
      debriefSlice.actions.initialize({
        applicationId,
        timezone: state.uiState.displayTimezone || getLocalTimezone(),
      })
    );
  };

export const setDebriefTimezone =
  (applicationId: string, timezone: string): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.setTimezone({ applicationId, timezone }));
  };

export const setDebriefStartTimeType =
  (
    applicationId: string,
    debriefStartTimeType: DebriefStartTimeType,
    debriefStartTime: string,
    timeBlockWeek: TimeBlockWeek // TODO: Fix this the next time the file is edited.
  ): // eslint-disable-next-line max-params
  AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { duration, timezone } = state.debrief.byId[applicationId];
    const timePeriods = getTimePeriods(debriefStartTime, timeBlockWeek, timezone);

    switch (debriefStartTimeType) {
      case DebriefStartTimeType.AUTOMATIC:
      case DebriefStartTimeType.WITHIN_TIME_RANGE: {
        dispatch(debriefSlice.actions.setTimePeriods({ applicationId, timePeriods }));
        break;
      }
      case DebriefStartTimeType.EXACT_TIME: {
        dispatch(
          debriefSlice.actions.setTimePeriods({
            applicationId,
            timePeriods: [
              {
                start: debriefStartTime,
                end: addMinutes(parseISO(debriefStartTime), duration + 15).toISOString(),
              },
            ],
          })
        );
        break;
      }
      default:
    }

    dispatch(debriefSlice.actions.setDebriefStartTimeType({ applicationId, debriefStartTimeType }));
  };

export const addDebriefTimePeriod =
  (applicationId: string, timePeriod: TimeBlock): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.addTimePeriod({ applicationId, timePeriod }));
  };

export const updateDebriefTimePeriod =
  (
    applicationId: string,
    timePeriod: TimeBlock,
    index: number
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateTimePeriod({ applicationId, timePeriod, index }));
  };

export const deleteDebriefTimePeriod =
  (applicationId: string, index: number): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.deleteTimePeriod({ applicationId, index }));
  };

export const updateDebriefDuration =
  (applicationId: string, duration: number): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateDuration({ applicationId, duration }));
  };

export const updateDebriefStartTime =
  (applicationId: string, debriefStartTime: string): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateDebriefStartTime({ applicationId, debriefStartTime }));
  };

export const updateDebriefAttendees =
  (applicationId: string, debriefAttendees: DebriefStage[]): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateDebriefAttendees({ applicationId, debriefAttendees }));
  };

export const updateDebriefHiringTeamAttendees =
  (applicationId: string, debriefHiringTeamAttendees: Employee[]): AppThunk =>
  async (dispatch) => {
    dispatch(
      debriefSlice.actions.updateDebriefHiringTeamAttendees({
        applicationId,
        debriefHiringTeamAttendees,
      })
    );
  };

export const updateSelectedAttendeeIds =
  (applicationId: string, selectedAttendeeIds: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateSelectedAttendeeIds({ applicationId, selectedAttendeeIds }));
  };

export const updateFetchedAttendee =
  (applicationId: string, value: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateFetchedAttendee({ applicationId, value }));
  };

export const updateAdditionalAttendees =
  (applicationId: string, additionalAttendees: Employee[]): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateAdditionalAttendees({ applicationId, additionalAttendees }));
  };

export const updateInterviewerCalendarId =
  (applicationId: string, interviewerCalendarId: string): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateInterviewerCalendarId({ applicationId, interviewerCalendarId }));
  };

export const submitDebriefSetupStep =
  (applicationId: string, gotoNextStep: () => void): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { duration, timePeriods, selectedAttendeeIds } = state.debrief.byId[applicationId];

    dispatch(
      debriefSlice.actions.updateStepScheduleOptions({
        applicationId,
        loading: true,
        submitting: true,
        error: '',
      })
    );
    dispatch(debriefSlice.actions.resetSelectedScheduleId({ applicationId }));
    dispatch(setScheduleOptionsSetupRefresh(false));

    getDebriefOptions({
      duration,
      interviewerIds: selectedAttendeeIds,
      time: { ranges: timePeriods },
      hardConflicts: getHardConflicts(state).map((value) => ({
        eventUid: value.eventUid,
        startAt: value.startAt,
        endAt: value.endAt,
        organizerEmail: value.organizerEmail,
        attendeesEmails: value.attendeesEmails,
      })),
      applicationId,
    })
      .then((optionsResponse) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line promise/always-return
        if (optionsResponse.schedules?.length) {
          dispatch(saveSchedules(optionsResponse.count, optionsResponse.schedules));
        } else {
          throw new Error('No debrief options found.');
        }
        dispatch(
          debriefSlice.actions.updateStepScheduleOptions({
            applicationId,
            loading: false,
            submitting: false,
            error: '',
          })
        );
        gotoNextStep();
      })
      .catch((error) => {
        dispatch(
          debriefSlice.actions.updateStepScheduleOptions({
            applicationId,
            loading: false,
            submitting: false,
            error: getExternalErrorMessage(error),
          })
        );
      });
  };

export const updateSelectedSchdeuleId =
  (applicationId: string, scheduleId: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { debrief } = state;
    dispatch(scheduleCommunicationsSlice.actions.setFlow({ scheduleId, applicationId, isDebrief: true }));
    dispatch(debriefSlice.actions.updateSelectedScheduleId({ applicationId, scheduleId }));

    const schedule = getScheduleById(state, scheduleId);
    const scheduleUpdates = getScheduleUpdates(state, scheduleId);
    const updatedSchedule = applyUpdatesToSchedule(schedule, scheduleUpdates);
    const debriefAttendeeIdsFromSchedule = uniq(
      updatedSchedule.events.map((event) => event.interviewers.map((interviewer) => interviewer.employeeId)).flat()
    );

    if (debriefAttendeeIdsFromSchedule.length) {
      const hiringTeam = debrief.byId[applicationId].debriefHiringTeamAttendees;
      const hiringTeamMap = keyBy(hiringTeam, 'employeeId');
      const hiringManager: string[] = [];
      const recruiters: string[] = [];
      const interviewerIds: string[] = [];

      debriefAttendeeIdsFromSchedule.forEach((id) => {
        if (hiringTeamMap[id] && hiringTeamMap[id].role === HiringTeamRoles.HIRING_MANAGER) {
          hiringManager.push(id);
        } else if (hiringTeamMap[id]) {
          recruiters.push(id);
        } else {
          interviewerIds.push(id);
        }
      });

      if (interviewerIds) {
        dispatch(
          scheduleCommunicationsSlice.actions.updateSlackChannelInterviewerEmployeeIDs({
            scheduleId,
            employeeIDs: interviewerIds,
          })
        );
      }

      if (hiringManager) {
        dispatch(
          scheduleCommunicationsSlice.actions.updateSlackChannelHiringManagerEmployeeIDs({
            scheduleId,
            employeeIDs: hiringManager,
          })
        );
      }

      if (recruiters) {
        dispatch(
          scheduleCommunicationsSlice.actions.updateSlackChannelRecruitingTeamEmployeeIDs({
            scheduleId,
            employeeIDs: recruiters,
          })
        );
      }
    }

    if (debrief.byId[applicationId]?.slackChannelEnabled) {
      dispatch(
        scheduleCommunicationsSlice.actions.updateSlackChannelEnabled({
          scheduleId,
          isEnabled: true,
        })
      );
    }

    if (debrief.byId[applicationId]?.taskConversationId) {
      dispatch(
        scheduleCommunicationsSlice.actions.updateConversationId({
          scheduleId,
          conversationId: debrief.byId[applicationId]?.taskConversationId || '',
        })
      );
    }

    if (debrief.byId[applicationId]?.taskConversationName) {
      dispatch(
        scheduleCommunicationsSlice.actions.updateSlackChannelName({
          scheduleId,
          name: debrief.byId[applicationId]?.taskConversationName || '',
        })
      );
    }
  };

export const resetSelectedSchdeuleIndex =
  (applicationId: string): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.resetSelectedScheduleId({ applicationId }));
  };

export const resetZoomUserInfo =
  (
    applicationId: string,
    scheduleId: string,
    zoomUserId?: string
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.setScheduleContentStepWaiting({ applicationId, isWaiting: true }));
    dispatch(updateZoomUserId(scheduleId, zoomUserId));
    dispatch(updateZoomInfo(scheduleId, undefined));
  };

export const updateInterviewerEventContent =
  (applicationId: string, interviewerEventContent: InterviewerEventContent): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateInterviewerEventContent({ applicationId, interviewerEventContent }));
  };

export const updateInterviewerContentDescription =
  (applicationId: string, description: string): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateInterviewerContentDescription({ applicationId, description }));
  };

export const updateInterviewerContentSummary =
  (applicationId: string, summary: string): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateInterviewerContentSummary({ applicationId, summary }));
  };

export const updateInterviewerTemplateID =
  (applicationId: string, interviewerTemplateID: string): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateInterviewerTemplateID({ applicationId, interviewerTemplateID }));
  };

export const updateIsPrivateCalendarEvent =
  (applicationId: string, enabled: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.updateIsPrivateCalendarEvent({ applicationId, enabled }));
  };

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
const validate = (interviewerCalendarId: string | null, summary: string, description: string) => {
  if (interviewerCalendarId === null) {
    throw new Error(`Missing calendar`);
  }
  // token validation
  let matches = getPlaceholders(summary) as string[];
  if (matches.length > 0) {
    throw new Error(`Found unused tokens in interviewer email title: ${matches.join(' ')}`);
  }
  matches = getPlaceholders(description) as string[];
  if (matches.length > 0) {
    throw new Error(`Found unused tokens in interviewer email body: ${matches.join(' ')}`);
  }
};

export const submitDebrief =
  (
    applicationId: string,
    onComplete: () => void,
    taskId?: string
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(debriefSlice.actions.setScheduleContentStepSubmitting({ applicationId }));

    const state = getState();
    const { scheduleCommunications } = state;
    const debrief = state.debrief.byId[applicationId];

    if (!debrief || !debrief.interviewerEventContent || !debrief.selectedScheduleId) {
      return;
    }

    if (!debrief.interviewerTemplateID) {
      dispatch(
        debriefSlice.actions.setScheduleContentStepError({
          applicationId,
          error: 'Missing interviewer communication template',
        })
      );
      dispatch(debriefSlice.actions.clearScheduleContentStepSubmitting({ applicationId }));
      return;
    }

    const schedule = applyUpdatesToSchedule(
      state.schedules.byId[debrief.selectedScheduleId],
      state.scheduleUpdate.byId[debrief.selectedScheduleId]
    );

    const selectedInterviewerIds = (schedule.events ?? [])
      .map((event) => event.interviewers.map((interviewer) => interviewer.employee.id))
      .flat();

    const attendeesList = (schedule.events ?? [])
      .map((event) =>
        event.interviewers.map((interviewer) => {
          const interviewerObject: InterviewerRequestBodyData = {
            interviewerId: interviewer.employee.id,
          };
          if (interviewer?.isOptional) {
            interviewerObject.isOptional = interviewer?.isOptional;
          }

          return interviewerObject;
        })
      )
      .flat();

    const { interviewerEventContent } = debrief;
    const { description, summary } = interviewerEventContent;
    const { interviewerCalendarId } = debrief;
    const slackChannelMessageTemplateId = getSlackChannelTemplateId(state, debrief.selectedScheduleId);
    try {
      validate(interviewerCalendarId, summary, description);

      const zoomUserId = getZoomUserId(state, debrief.selectedScheduleId);
      const zoomInfo = getZoomInfo(state, debrief.selectedScheduleId);

      const data: RequestBody = {
        applicationId,
        taskId,
        interviewerCalendarId: interviewerCalendarId as string,
        summary,
        description,
        startAt: new Date(interviewerEventContent.event.startAt).toISOString(),
        endAt: new Date(interviewerEventContent.event.endAt).toISOString(),
        videoMeetingUrl:
          getCustomVideoMeetingLink(state, debrief.selectedScheduleId) ||
          getVideoMeetingLink(state, debrief.selectedScheduleId) ||
          zoomInfo?.joinURL ||
          '',
        interviewName: interviewerEventContent.event.name || '',
        interviewerIds: selectedInterviewerIds,
        isPrivateCalendarEvent: debrief.isPrivateCalendarEvent,
        meetingRoomIds: debrief.meetingRoomSuggestions.reduce((prev: string[], current) => {
          if (current.room?.id) {
            prev.push(current.room.id);
          }
          return prev;
        }, []),
        attendees: attendeesList,
        conversationTemplateId: slackChannelMessageTemplateId,
        emailTemplateId: debrief.interviewerTemplateID || undefined,
      };
      if (zoomInfo && zoomUserId) {
        data.zoomInfo = {
          userId: zoomUserId,
          password: zoomInfo.password,
          meetingId: zoomInfo.meetingID,
          pstnPassword: zoomInfo.pstnPassword,
          dialInfo: zoomInfo.dialInfo,
        };
      }

      const scheduleCommsData = scheduleCommunications.byId[debrief.selectedScheduleId || ''] || {};
      if (scheduleCommsData.slackChannelEnabled && scheduleCommsData.slackChannelContent) {
        if (htmlToMrkdwn(scheduleCommsData.slackChannelContent || '') === '') {
          throw new Error('Missing Slack channel message content');
        }

        data.slackChannelContent = {
          slackChannelInterviewerEmployeeIds: scheduleCommsData.slackChannelInterviewerEmployeeIDs,
          slackChannelRecruitingTeamEmployeeIds: scheduleCommsData.slackChannelRecruitingTeamEmployeeIDs,
          slackChannelHiringManagerEmployeeIds: scheduleCommsData.slackChannelHiringManagerEmployeeIDs,
          slackChannelMessageContent: htmlToMrkdwn(scheduleCommsData.slackChannelContent || ''),
        };

        /*
          Logic -
          - if there's a remote id, user has selected an existing channel
          - if there's a conversation id, user has selected a previously used channel
          - if there's only a name, they are creating a new slack channel
        */
        if (scheduleCommsData.slackChannelRemoteId) {
          data.slackChannelContent.slackChannelId = scheduleCommsData.slackChannelRemoteId;
        } else if (scheduleCommsData.conversationId) {
          data.slackChannelContent.conversationId = scheduleCommsData.conversationId;
        } else {
          data.slackChannelContent.slackChannelName = scheduleCommsData.slackChannelName;
        }
      }
      await createDebrief(data);
      onComplete();
    } catch (error) {
      logError(error);
      dispatch(
        debriefSlice.actions.setScheduleContentStepError({
          applicationId,
          error: getExternalErrorMessage(error),
        })
      );
    }
    dispatch(debriefSlice.actions.clearScheduleContentStepSubmitting({ applicationId }));
  };

export const setCandidateName =
  (applicationId: string, candidateName: string): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.setCandidateName({ applicationId, candidateName }));
  };

export const setMeetingRoomSuggestions =
  (applicationId: string, meetingRoomSuggestions: MeetingRoomSuggestionInterface[]) => async (dispatch) => {
    dispatch(debriefSlice.actions.setMeetingRoomSuggestions({ applicationId, meetingRoomSuggestions }));
  };

export const setScheduleContentStepWaiting =
  (applicationId: string, isWaiting: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(debriefSlice.actions.setScheduleContentStepWaiting({ applicationId, isWaiting }));
  };

export const setPreviousSlackChannelInfo =
  (
    applicationId: string,
    taskConversationId: string,
    taskConversationName: string
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      debriefSlice.actions.setPreviousSlackChannelInfo({
        applicationId,
        taskConversationId,
        taskConversationName,
      })
    );
  };

export const setTaskConversationTemplateId =
  (applicationId: string, taskConversationTemplateId: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      debriefSlice.actions.setTaskConversationTemplateId({
        applicationId,
        taskConversationTemplateId,
      })
    );
  };

export const setSlackChannelEnabled =
  (applicationId: string, enabled: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(
      debriefSlice.actions.setSlackChannelEnabled({
        applicationId,
        enabled,
      })
    );
  };
