import React, { useCallback, useState } from 'react';

import { gql } from '@apollo/client';
import { Box, CircularProgress, Stack } from '@mui/material';

import {
  SourceType,
  useScheduleTaskQuery,
  useTaskCancelMutation,
  useTaskSetIsUrgentMutation,
} from 'src/generated/mloop-graphql';

import ZeroState from 'src/components/ZeroState';
import Button from 'src/components/button';
import { WarningIcon } from 'src/components/icons';
import { MenuOption } from 'src/components/menu';

import { useFeatureFlagNumber } from 'src/hooks/feature-flag';
import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import getTaskUrl from 'src/urls/getTaskUrl';

import ActivityFeed from 'src/views-new/ActivityFeed';
import ScheduleTask from 'src/views-new/ScheduleTask';
import CancelTaskConfirmationDialog from 'src/views-new/ScheduleTask/CancelTaskConfirmationDialog';
import SidePanelContainer from 'src/views-new/sidepanel/common/SidePanelContainer';
import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

import useScheduleTaskMenuOptions, { ScheduleTaskMenuOptions } from './useScheduleTaskMenuOptions';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleTaskSidePanelProps = {
  scheduleTaskId: string;
  showRequirements: boolean;
};

const ScheduleTaskSidePanel = ({ scheduleTaskId, showRequirements }: ScheduleTaskSidePanelProps) => {
  const [showCancelScheduleTaskModal, setShowCancelScheduleTaskModal] = useState<boolean>(false);
  const refetchActivityFeed = useActivityFeedRefetch(SourceType.SourceTypeTask);
  const refetchScheduleTask = useRefetchScheduleTaskQuery();
  const taskPolling = useFeatureFlagNumber('user_task_polling_seconds');

  const { data, loading, error } = useScheduleTaskQuery({
    variables: { scheduleTaskId },
    fetchPolicy: 'cache-and-network',
    pollInterval: taskPolling ? taskPolling * 1000 : undefined,
  });
  const [taskSetIsUrgentMutation] = useTaskSetIsUrgentMutation();
  const [taskCancelMutation] = useTaskCancelMutation();

  const menuOptions = useScheduleTaskMenuOptions({ task: data?.task || undefined }, undefined);

  const applicationId = data?.task?.application.id;
  const candidateId = data?.task?.application.candidate?.id;

  const handleMenuOptionSelected = useCallback(
    (option: MenuOption) => {
      switch (option.id) {
        case ScheduleTaskMenuOptions.MarkAsUrgent: {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line promise/catch-or-return
          taskSetIsUrgentMutation({
            variables: { input: { taskId: scheduleTaskId, isUrgent: true } },
            optimisticResponse: { taskSetIsUrgent: { id: scheduleTaskId, isUrgent: true, __typename: 'Task' } },
          }).then(() => refetchActivityFeed());
          break;
        }
        case ScheduleTaskMenuOptions.RemoveUrgent: {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line promise/catch-or-return
          taskSetIsUrgentMutation({
            variables: { input: { taskId: scheduleTaskId, isUrgent: false } },
            optimisticResponse: { taskSetIsUrgent: { id: scheduleTaskId, isUrgent: false, __typename: 'Task' } },
          }).then(() => refetchActivityFeed());
          break;
        }
        case ScheduleTaskMenuOptions.CopyLinkToTask: {
          if (!applicationId || !candidateId) return;
          navigator.clipboard.writeText(
            getTaskUrl(candidateId, scheduleTaskId, { applicationId, useRelativeUrl: false })
          );
          break;
        }
        case ScheduleTaskMenuOptions.CloseTask: {
          setShowCancelScheduleTaskModal(true);
          break;
        }
        default:
      }
    },
    [applicationId, candidateId, refetchActivityFeed, scheduleTaskId, taskSetIsUrgentMutation]
  );

  const handleCancelTask = useCallback(async () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/always-return
    await taskCancelMutation({ variables: { input: { taskId: scheduleTaskId } } }).then(() => {
      refetchScheduleTask();
      refetchActivityFeed();
    });
    SidePanelManager.closeSidePanel();
  }, [refetchActivityFeed, refetchScheduleTask, scheduleTaskId, taskCancelMutation]);

  return (
    <SidePanelContainer moreMenuOptions={menuOptions} onMenuOptionSelected={handleMenuOptionSelected}>
      {loading && !data?.task && (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <CircularProgress size={32} />
        </Box>
      )}

      {error && (
        <Box padding={2.5}>
          <ZeroState label="Error fetching task details" icon={<WarningIcon color="error" fontSize={32} />} />
        </Box>
      )}

      {!error && !loading && !data?.task && (
        <Box padding={2.5}>
          <ZeroState
            label="This task doesn't exist"
            actions={
              <Button
                variant="outlined"
                label="Close"
                onClick={() => {
                  SidePanelManager.closeSidePanel();
                }}
              />
            }
          />
        </Box>
      )}

      {data?.task && (
        <Stack maxWidth="100%" direction="column" mt={2.5} flexGrow={1}>
          <ScheduleTask task={data.task} showRequirements={showRequirements} />
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.contrast,
              borderTop: (theme) => `1px solid ${theme.palette.border}`,
              flexGrow: 1,
              padding: 2.5,
              mt: 0,
            }}
          >
            <ActivityFeed
              sourceId={scheduleTaskId}
              sourceType={SourceType.SourceTypeTask}
              onHasUnsavedNoteChangesChange={(value: boolean) => {
                SidePanelManager.setHasUnsavedNoteChanges(value);
              }}
              onNoteCreated={() => {
                SidePanelManager.setHasUnsavedNoteChanges(false);
              }}
            />
          </Box>
        </Stack>
      )}

      {showCancelScheduleTaskModal && (
        <CancelTaskConfirmationDialog
          onClose={() => setShowCancelScheduleTaskModal(false)}
          onConfirm={handleCancelTask}
        />
      )}
    </SidePanelContainer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TaskSetIsUrgentMutation = gql`
  mutation TaskSetIsUrgent($input: TaskSetIsUrgentInput!) {
    taskSetIsUrgent(input: $input) {
      id
      isUrgent
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TaskCancelMutation = gql`
  mutation TaskCancel($input: TaskCancelInput!) {
    taskCancel(input: $input) {
      id
    }
  }
`;

export const ScheduleTaskQuery = gql`
  ${ScheduleTask.fragments.task}
  ${useScheduleTaskMenuOptions.fragments.task}
  query ScheduleTask($scheduleTaskId: uuid!) {
    task(id: $scheduleTaskId) {
      application {
        id
        candidate {
          id
        }
      }
      ...ScheduleTask_task
      ...UseSchduleTaskMenuOptions_task
    }
  }
`;

export default ScheduleTaskSidePanel;
