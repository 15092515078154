/* eslint-disable max-lines */
import { gql } from '@apollo/client';

import ApplicationNote from 'src/views-new/ApplicationDetailsTaskView/CandidateSecondaryContent/InternalNoteSection/ApplicationNote';

import CandidateHeader from './CandidateHeader';

export const JobStageFragment = gql`
  fragment JobStageFragment on JobStage {
    id
    name
    jobId
    schedulable
    atsId
    index
  }
`;

export const CandidateDetailsQuery = gql`
  ${CandidateHeader.fragments.atsCandidate}
  ${ApplicationNote.fragments.application}
  query CandidateDetails($id: uuid!) {
    candidate(id: $id) {
      id

      fullName
      lastActivityAt
      email
      coordinatorId
      recruiterId
      familyName
      givenName
      atsId
      atsType
      atsCandidate {
        atsId
        atsUrl
        ...CandidateHeader_atsCandidate
      }
      linkedinUrl
      phoneNumbers
      pronoun
      recruiterId
      timezone
      externalId
      updatedAt
      additionalEmails
      recruiter {
        id
        fullName
        slackImageUrl
        email
        isDisabled
        timezone
        atsId
        atsUser {
          id
          atsId
        }
      }
      coordinator {
        id
        fullName
        slackImageUrl
        email
        isDisabled
        timezone
        atsId
        atsUser {
          id
          atsId
        }
      }
      applications {
        id

        existingConversations(input: { isCancelled: false }) {
          id
          remoteId
          remoteName
          isArchived
        }
        job {
          id
          name
          atsId
          atsType
          location
          jobStagesAggregate
          sourcers {
            id
            fullName
            slackImageUrl
          }
          hiringManagers {
            id
            fullName
            slackImageUrl
          }
        }
        jobStage {
          id
          name
          jobId
          schedulable
          atsId
          index
        }
        applicationStages(input: { orderBy: { createdAt: DESC }, isCancelled: false }) {
          id
          startAt
          endAt
          createdAt
          updatedAt
          name
          timezone
          selfScheduleOptionsId
          jobStageId
          creator {
            id
            fullName
          }
          jobStage {
            id
            name
            index
          }
          isHoldForRsvp
          isReadyForCandidateComms
          hasCandidateTentative
          hasInterviewerTentative
          isCancelled
          hasCandidateDeclined
          hasInterviewerDeclined
          applicationStageCandidateEvents(input: { orderBy: { createdAt: DESC } }) {
            id
            startAt
            endAt
            responseStatus
            googleEventUrl
            isCancelled
            name
            googleEventId
            googleCalendarId
          }
          applicationStageInterviews(input: { orderBy: { startAt: ASC }, isCancelled: false }) {
            id
            startAt
            endAt
            name
            calendarEventTitle
            isCancelled
            googleEventId
            googleEventUrl
            googleCalendarId
            applicationStageInterviewInterviewers {
              role
              isOptional
              interviewerId
              responseStatus
              applicationStageInterviewId
              interviewer {
                id
                slackImageUrl
                fullName
              }
            }
            applicationStageInterviewRooms {
              applicationStageInterviewId
              meetingRoomId
              responseStatus
              meetingRoom {
                id
                name
              }
            }
          }
          selfScheduleOptionsId
        }
        applicationDebriefs(input: { orderBy: { createdAt: DESC }, isCancelled: false }) {
          id
          startAt
          endAt
          isCancelled
          isPrivateCalendarEvent
          attendees {
            interviewerId
            responseStatus
            interviewer {
              id
              fullName
              email
              slackImageUrl
            }
            isOptional
          }
          meetingRooms {
            applicationDebriefId
            meetingRoomId
            responseStatus
          }
        }
        status
        jobStageCount
        state {
          isSchedulable
          isNotScheduleable
          isUnscheduled
          isAvailabilityRequested
          isReadyToSchedule
          isCanceled
          isOngoing
          isScheduled
          isCompleted
          isScheduledInAts
          isCompletedInAts
          isCandidateDeclined
          isInterviewerDeclined
          isSelfScheduleRequested
          isHoldForRsvp
          isReadyForCandidateComms
        }
        upcomingApplicationStage {
          id
          startAt
          endAt
          selfScheduleOptionsId
          minStartAt
          applicationStageInterviews(input: { orderBy: { startAt: ASC }, isCancelled: false }) {
            id
            startAt
            endAt
          }
        }
        ...ApplicationNote_application
      }
    }
  }
`;

export const JobStageDetails = gql`
  query JobStageDetails($input: JobStagesInput!) {
    jobStages(input: $input) {
      items {
        ...JobStageFragment
      }
    }
  }
`;

export const JobStageUpdatedAt = gql`
  query JobStageUpdatedAt($id: uuid!) {
    jobStage(id: $id) {
      id
      updatedAt
    }
  }
`;

export const CandidateAvailabilityFragment = gql`
  fragment CandidateAvailabilityFragment on CandidateAvailability {
    id
    createdAt
    updatedAt
    candidateEnteredAvailability {
      startAt
      endAt
    }
    rcEnteredAvailability {
      startAt
      endAt
    }
    lastUpdatedByEmployeeId
    lastUpdatedByEmployee {
      id
      fullName
    }
    employeeUpdatedAt
    candidateUpdatedAt
  }
`;

// Used in Schedule Flow
export const GQLConfidentialJobFromJobStage = gql`
  query ConfidentialJobByJobStage($id: uuid!) {
    jobStage(id: $id) {
      job {
        isConfidential
        id
      }
    }
  }
`;

// Used in Debrief flow
export const GQLConfidentialJobFromApplication = gql`
  query ConfidentialJobByApplication($id: uuid!) {
    application(id: $id) {
      job {
        isConfidential
        id
      }
    }
  }
`;
