/**
 * TODO @Ashutosh, need find a solution for this, lambda not able to refer the code from mloop-shared
 */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

const NETWORK_ERROR = 'Network Error';
enum ErrorReason {
  NOT_ALL_REQUIRED_SCOPES_CONSENTED_BY_USER = 'NOT_ALL_REQUIRED_SCOPES_CONSENTED_BY_USER',
}

export const getErrorStatus = (error: any): number => {
  return error?.response?.status || 0;
};

export const getErrorMessageForReason = (reason: ErrorReason, metadata: { [key: string]: string } = {}) => {
  const metadataStr = Object.keys(metadata)
    .map((key) => `${key}: ${metadata[key]}`)
    .join(', ');

  switch (reason) {
    case ErrorReason.NOT_ALL_REQUIRED_SCOPES_CONSENTED_BY_USER: {
      return `User did not grant all the required permissions (${metadataStr})`;
    }
    default:
  }

  return 'Something went wrong. Please try again';
};

export const getExternalErrorMessage = (error: any, tryAgain = true): string => {
  const status = getErrorStatus(error);
  const message = error?.response?.data?.error?.message || error?.response?.data?.message || error.message;

  // Likely a network error if it is an axios error and there is no response
  const isAxiosNetworkError = !!error.isAxiosError && !error.response;

  if (status === 502 || message === NETWORK_ERROR || isAxiosNetworkError) {
    return tryAgain
      ? 'Request timed out. Please try again.'
      : 'Request timed out. Please reach out to ModernLoop staff via your shared Slack channel or support@modernloop.io before trying again.';
  }

  if (status >= 500) {
    return tryAgain
      ? 'Something went wrong. Please try again.'
      : 'Something went wrong. Please reach out to ModernLoop staff via your shared Slack channel or support@modernloop.io before trying again.';
  }

  if (status === 412) {
    if (error?.response?.data?.message) return error?.response?.data?.message; // java
    if (error?.response?.data?.details?.length)
      return getErrorMessageForReason(error.response.data.details[0].reason, error.response.data.details[0].metadata);
  }

  return message || 'Sorry, something went wrong.';
};

export const getInternalErrorMessage = (error: any): string => {
  return error?.response?.data?.error?.message || error?.response?.data?.message || error.message || '';
};

export const isPermissionsError = (error: any): boolean => {
  return getInternalErrorMessage(error).indexOf('permission') !== -1;
};
