import { useApolloClient } from '@apollo/client';

import { useJobStageDetailsQuery } from 'src/generated/mloop-graphql';

import { Routes } from 'src/constants/routes';

import useHistory from 'src/hooks/useHistory';
import { SearchParamKnownKeys, urlQueryBuilder } from 'src/hooks/useUrlSearchParams';

import { CandidateAvailabilityOptions } from 'src/store/slices/candidate-availability-options';

import { apolloCacheDeleteByQuery } from 'src/utils/apolloHelpers';

import { PAGE_SIZE } from 'src/constants';

import { ApplicationStageInterface, JobStageInterface } from './types';

export const useNavigateToCandidateDetailsPage = (): ((candidateId: string, applicationId?: string) => void) => {
  const history = useHistory();
  return (candidateId: string, applicationId?: string) => {
    history.push(
      urlQueryBuilder(
        `${Routes.Candidates}/${candidateId}`,
        applicationId
          ? {
              [SearchParamKnownKeys.applicationId]: applicationId,
            }
          : {}
      )
    );
  };
};

export const useNavigateToRescheduleFlow = (clearCache = false): ((id: string) => void) => {
  const history = useHistory();
  const client = useApolloClient();
  return (id: string) => {
    if (clearCache) {
      apolloCacheDeleteByQuery(client.cache, 'ApplicationStage', { id });
    }
    history.push(`/reschedule/${id}`);
  };
};

export const useNavigateToUpdateFlow = (clearCache = false): ((id: string, candidateComms?: boolean) => void) => {
  const history = useHistory();
  const client = useApolloClient();
  return (id: string, candidateComms = false) => {
    if (clearCache) {
      apolloCacheDeleteByQuery(client.cache, 'applicationStage', { id });
    }
    history.push({
      pathname: `/update/${id}`,
      search: candidateComms ? `?candidateComms=${candidateComms}` : undefined,
    });
  };
};

export const useNavigateToJobDetailsPage = (): ((id: string, jobStageId?: string) => void) => {
  const history = useHistory();
  return (id: string, jobStageId?: string) => {
    if (jobStageId) {
      history.push(`${Routes.Jobs}/${id}/job_stage/${jobStageId}`);
      return;
    }
    history.push(`${Routes.Jobs}/${id}/`);
  };
};

export const isUpcomingEvent = (startTime: string): boolean => {
  return new Date(startTime).getTime() > new Date().getTime();
};

export const isCompletedEvent = (endTime: string): boolean => {
  return new Date(endTime).getTime() < new Date().getTime();
};

export const isEventInProgress = (startTime: string, endTime: string): boolean => {
  return new Date(startTime).getTime() < new Date().getTime() && new Date(endTime).getTime() > new Date().getTime();
};

export type IsAvaliablityRequestedReturnType = {
  isFutureAvaliablityRequested: (schedules: ApplicationStageInterface[]) => boolean;
  options: CandidateAvailabilityOptions | undefined;
};

export const useNextSchedulableJobStage = (jobId: string, currentIndex: number): JobStageInterface | null => {
  const { data } = useJobStageDetailsQuery({
    variables: {
      input: {
        pageInput: {
          limit: PAGE_SIZE,
          offset: 0,
        },
        jobId,
        hasActiveApplications: false,
        isSchedulable: false,
      },
    },
  });

  const jobStages = data?.jobStages?.items || [];

  if (!jobStages.length) return null;

  return jobStages.find((jobStage) => {
    return jobStage.index > currentIndex && jobStage.schedulable;
  });
};
