import { gql } from '@apollo/client';

import { PrefSet, StringEmployeePref, useUpdateEmployeePrefMutation } from 'src/generated/mloop-graphql';

import { EmployeePrefName } from 'src/utils/api/employee/constants';

import { AllEmployeePref } from './types';

export const UpdateEmployeePref = gql`
  mutation UpdateEmployeePref($input: EmployeePrefUpdateInput!) {
    employeePrefUpdate(input: $input) {
      employeePref {
        orgId
        employeeId
        prefName
        timestamp
      }
    }
  }
`;

export function useSetEmployeePref(employeeId: string, onCompleted?: () => void) {
  const [update] = useUpdateEmployeePrefMutation();
  const updateEmployeePrefs = (prefName: EmployeePrefName | AllEmployeePref, prefValue: PrefSet) => {
    return update({
      variables: {
        input: {
          employeeId,
          prefName,
          set: prefValue,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: ['EmployeePrefs'],
    }).then(() => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      if (onCompleted) onCompleted();
    });
  };

  return { updateEmployeePrefs };
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useSetEmployeePrefBool(prefName: EmployeePrefName, employeeId: string, onCompleted?: () => void) {
  const { updateEmployeePrefs } = useSetEmployeePref(employeeId, onCompleted);
  return (value: boolean) => {
    return updateEmployeePrefs(prefName, { boolValue: value });
  };
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useSetEmployeePrefInt(prefName: EmployeePrefName, employeeId: string, onCompleted?: () => void) {
  const { updateEmployeePrefs } = useSetEmployeePref(employeeId, onCompleted);
  return (value: number) => {
    return updateEmployeePrefs(prefName, { intValue: value });
  };
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useSetEmployeePrefString(
  prefName: EmployeePrefName | StringEmployeePref,
  employeeId: string,
  onCompleted?: () => void
) {
  const { updateEmployeePrefs } = useSetEmployeePref(employeeId, onCompleted);
  return (value: string) => {
    return updateEmployeePrefs(prefName, { stringValue: value });
  };
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useSetEmployeePrefStringArray(
  prefName: EmployeePrefName,
  employeeId: string,
  onCompleted?: () => void
) {
  const { updateEmployeePrefs } = useSetEmployeePref(employeeId, onCompleted);
  return (value: string[] | null) => {
    return updateEmployeePrefs(prefName, { stringArray: value || [] });
  };
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useSetEmployeePrefJson(prefName: EmployeePrefName, employeeId: string, onCompleted?: () => void) {
  const { updateEmployeePrefs } = useSetEmployeePref(employeeId, onCompleted);
  return (value: unknown) => {
    return updateEmployeePrefs(prefName, { json: JSON.stringify(value) });
  };
}

/**
 * @param prefName
 * @returns mutationHook
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useSetEmployeePrefJsonArray(prefName: EmployeePrefName, employeeId: string, onCompleted?: () => void) {
  const { updateEmployeePrefs } = useSetEmployeePref(employeeId, onCompleted);
  return (jsonArray: unknown[] | null) => {
    return updateEmployeePrefs(prefName, { jsonArray: jsonArray ? jsonArray.map((json) => JSON.stringify(json)) : [] });
  };
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export function useSetEmployeePrefTimestamp(prefName: EmployeePrefName, employeeId: string, onCompleted?: () => void) {
  const { updateEmployeePrefs } = useSetEmployeePref(employeeId, onCompleted);
  return (value: string) => {
    return updateEmployeePrefs(prefName, { timestamp: value });
  };
}
