import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskFlagResolvedChangeRequested_ActivityLogFragment, ActorType } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import logError from 'src/utils/logError';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';

interface Fragments {
  activityLog: ActivityLogTaskFlagResolvedChangeRequested_ActivityLogFragment;
}

const ActivityLogTaskFlagResolvedChangeRequested: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (details?.__typename !== 'ActivityLogFlagDetails') {
    logError('ActivityLogTaskFlagResolvedChangeRequested: details is not ActivityLogFlagDetails');
    return null;
  }

  const { flagContent } = details;

  const flagContentShort = flagContent.length > 30 ? `${flagContent.slice(0, 30)}...` : flagContent;

  const actionLabel = `${
    ActorType.Candidate === activityLog.activityActorType ? 'canceled their change request' : 'resolved a flag'
  }${flagContent ? ': ' : ''}${flagContent ? flagContentShort : ''}`;

  return <ActivityLogBase activityLog={activityLog} actionLabel={actionLabel} actionLabelTooltip={flagContent} />;
};

ActivityLogTaskFlagResolvedChangeRequested.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskFlagResolvedChangeRequested_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogFlagDetails {
          flagContent
          __typename
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskFlagResolvedChangeRequested;
