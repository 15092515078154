import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskClosed_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import ActivityLogText from 'src/entities/ActivityLog/Helpers/ActivityLogText';
import TaskName from 'src/entities/common/TaskName';

interface Fragments {
  activityLog: ActivityLogTaskClosed_ActivityLogFragment;
}

const ActivityLogTaskClosed: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (!details || details.__typename !== 'ActivityLogTaskDetails') {
    throw new Error('ActivityLogTaskCreated: details is not ActivityLogTaskDetails');
  }

  const { task } = details;
  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={
        <ActivityLogText>
          closed&nbsp;
          <TaskName task={task} />
        </ActivityLogText>
      }
    />
  );
};

ActivityLogTaskClosed.fragments = {
  activityLog: gql`
    ${TaskName.fragments.task}
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskClosed_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogTaskDetails {
          __typename
          taskId
          task {
            id
            ...TaskName_task
          }
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskClosed;
