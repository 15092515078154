import { ThemeOptions } from '@mui/material';

/**
 * View Typography props and classes at https://mui.com/material-ui/api/typography/#css
 */
export const typography: ThemeOptions['components'] = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        subtitle1: 'p',
        subtitle2: 'p',
      },
    },
  },
};
