import logMessage from 'src/utils/logMessage';

import { enviromentConfig } from 'src/config';

export type HexColor = string & {
  __type: 'Color represented in #RRGGBB format';
};

export const assertHexColor = (input: string): HexColor => {
  if (!/^#[0-9A-F]{6}$/i.test(input)) {
    if (enviromentConfig.environment?.toLowerCase() !== 'production') {
      throw Error(`'${input}' is not a valid hex color string`);
    } else {
      logMessage(`'${input}' is not a valid hex color string`, 'error');
      return input as HexColor;
    }
  }

  return input as HexColor;
};
