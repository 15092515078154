import { useEffect } from 'react';

import * as Sentry from '@sentry/react';

import type { UserInfo } from 'src/contexts/auth0/Auth0Context';

const organizationRetoolURL =
  'https://retool.prod.modernloop.io/apps/67e059be-521e-11ec-b6e1-7bb6682d8b51/Investigation/Organization%20Details#orgId=';
const userRetoolURL =
  'https://retool.prod.modernloop.io/apps/854c9b04-4cdb-11ec-8987-3bdb069fccd8/Investigation/Employee%20Details#employeeId=';

/**
 * Identifies the user in Sentry. Sends name, user, email and organization,
 * Our dashboard: https://sentry.io/organizations/modernloop/projects/
 * Sentry API Docs: https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
 *
 * @param userInfo from useAuth();
 * @returns void
 */
export function identifySentryUser(userInfo: UserInfo | null): void {
  if (!userInfo || !userInfo.userId) {
    return;
  }
  if (!userInfo.orgId) {
    return;
  }

  const sentryAttributes = {
    organization_id: userInfo.orgId || undefined,
    organization_retool: `${organizationRetoolURL}${userInfo.orgId}`,
    user_retool: `${userRetoolURL}${userInfo.employeeId}&orgId=${userInfo.orgId}`,
  };

  Sentry.setUser({
    id: userInfo.userId,
    ...sentryAttributes,
  });

  Sentry.setTags(sentryAttributes);
}

export const useIdentifySentryUser = (userInfo: UserInfo | null) => {
  useEffect(() => {
    identifySentryUser(userInfo); // Sentry doesn't use cookies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userInfo)]);
};
