import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AtsInterviewDefinition, DynamicLinkType, InterviewType } from 'src/generated/mloop-graphql';

export type AtsInterviewDefinitionStore = Pick<AtsInterviewDefinition, 'atsId'> &
  Partial<Omit<AtsInterviewDefinition, 'atsId'>>;

export type JobStageInterview = {
  id: string;
  type: InterviewType;
  name: string | null | undefined;
  index: number;
  schedulable: boolean;
  jobStageId: string;
  jobStageGroupId: string;
  duration: number;
  isLockedOrder: boolean;
  seatIds: string[];
  forcedStartAt: string | null;
  staticLinks?: string[];
  dynamicLinkTypes?: DynamicLinkType[];
  atsInterviewDefinition?: AtsInterviewDefinitionStore;
  atsInterviewName?: string | null;
  atsInterviewDuration?: number | null;
  isHiddenFromCandidate?: boolean;
};

export type UpdateJobStageInterviewPayload = Pick<JobStageInterview, 'id'> & Partial<Omit<JobStageInterview, 'id'>>;

type JobStageInterviewState = {
  byId: { [key: string]: JobStageInterview };
  loadingById: { [key: string]: boolean };
  errorById: { [key: string]: string };
};

const getInitialState = (): JobStageInterviewState => {
  return {
    byId: {},
    loadingById: {},
    errorById: {},
  };
};

const jobStageInterviewSlice = createSlice({
  name: 'jobStage',
  initialState: getInitialState(),
  reducers: {
    addJobStageInterview(state: JobStageInterviewState, action: PayloadAction<JobStageInterview>) {
      const { payload } = action;
      state.byId[payload.id] = payload;
    },

    updateJobStageInterview(state: JobStageInterviewState, action: PayloadAction<UpdateJobStageInterviewPayload>) {
      const { payload } = action;
      state.byId[payload.id] = {
        ...state.byId[payload.id],
        ...payload,
      };
    },

    deleteJobStageInterview(state: JobStageInterviewState, action: PayloadAction<string>) {
      const { payload: jobStageInterviewId } = action;
      delete state.byId[jobStageInterviewId];
    },

    addJobStageInterviewError(
      state: JobStageInterviewState,
      action: PayloadAction<{ jobStageInterviewId: string; error: string }>
    ) {
      const { jobStageInterviewId, error } = action.payload;
      state.errorById[jobStageInterviewId] = error;
    },

    deleteJobStageInterviewError(state: JobStageInterviewState, action: PayloadAction<string>) {
      const { payload: jobStageInterviewId } = action;
      delete state.errorById[jobStageInterviewId];
    },

    addJobStageInterviewLoading(
      state: JobStageInterviewState,
      action: PayloadAction<{ jobStageInterviewId: string; loading: boolean }>
    ) {
      const { jobStageInterviewId, loading } = action.payload;
      state.loadingById[jobStageInterviewId] = loading;
    },

    deleteJobStageInterviewLoading(state: JobStageInterviewState, action: PayloadAction<string>) {
      const { payload: jobStageInterviewId } = action;
      delete state.loadingById[jobStageInterviewId];
    },
  },
});

export const { reducer } = jobStageInterviewSlice;
export default jobStageInterviewSlice;
