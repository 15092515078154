import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskDeclinedInterviewerAutoReplaced_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import ActivityLogText from 'src/entities/ActivityLog/Helpers/ActivityLogText';

interface Fragments {
  activityLog: ActivityLogTaskDeclinedInterviewerAutoReplaced_ActivityLogFragment;
}

const ActivityLogTaskDeclinedInterviewerAutoReplaced: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (!details || details.__typename !== 'ActivityLogRsvpDetails') {
    throw new Error('ActivityLogTaskDeclinedInterviewerAutoReplaced: details is not ActivityLogRsvpDetails');
  }
  const { interviewName, previous, current } = details;

  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={
        <ActivityLogText>
          <ActivityLogText>replaced</ActivityLogText>
          <ActivityLogText fontWeight={600} color="foreground" noWrap>
            &nbsp;{previous?.fullName}&nbsp;
          </ActivityLogText>
          <ActivityLogText>with</ActivityLogText>
          <ActivityLogText fontWeight={600} color="foreground" noWrap>
            &nbsp;{current?.fullName}&nbsp;
          </ActivityLogText>
          <ActivityLogText>on</ActivityLogText>
          &nbsp;{interviewName}
        </ActivityLogText>
      }
    />
  );
};

ActivityLogTaskDeclinedInterviewerAutoReplaced.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskDeclinedInterviewerAutoReplaced_activityLog on ActivityLog {
      id
      details {
        __typename
        ... on ActivityLogRsvpDetails {
          interviewName
          previous: interviewer {
            fullName
          }
          current: addedInterviewer {
            fullName
          }
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskDeclinedInterviewerAutoReplaced;
