import { useCallback, useEffect } from 'react';

import { useShouldLogStatistics } from '@modernloop/shared/hooks';
import * as amplitude from 'amplitude-js';
import LogRocket from 'logrocket';

import { LoggerEvent } from 'src/constants/logger';

import useOrgId from './useOrgId';

export * from 'src/constants/logger';

type LoggerValue = string | number | boolean | string[] | number[] | boolean[];
type LoggerValueWithEmpties = LoggerValue | undefined | null;

type LoggerData = {
  [key: string]: LoggerValue;
};

type LoggerDataWithEmpties = {
  [key: string]: LoggerValueWithEmpties;
};

const filterEmpties = (obj: LoggerDataWithEmpties): LoggerData => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => !!v)) as LoggerData;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
const logEventHelper = (shouldLogStatistics: boolean, orgId: string | null, event: string, data?: LoggerData) => {
  if (!shouldLogStatistics || process.env.NODE_ENV === 'test') {
    return;
  }
  if (!data) {
    data = {};
  }
  LogRocket.track(event, filterEmpties(data));
  console.log(`Event ${event} was logged.`, data);
  if (LogRocket.sessionURL) {
    data.sessionUrl = LogRocket.sessionURL;
  }
  if (orgId) {
    amplitude.getInstance().logEventWithGroups(event, data, { organizationID: orgId });
  } else {
    amplitude.getInstance().logEvent(event, data);
  }
};

/**
 * This hook logs an event every time once on render
 * @param event - event to log
 * @param data - data to log
 * @returns void
 * @example
 *  const data = { applicationId: '123' };
 *  useLogEventOnce(LoggerEvent.CLIENT_SCHEDULE_FLOW_BEGIN, {
 *    applicationId: '123'
 *  });
 */
export const useLogEventOnce = (event: LoggerEvent, data: LoggerData = {}) => {
  const orgId = useOrgId();
  const shouldLogStatistics = useShouldLogStatistics();
  useEffect(() => {
    logEventHelper(shouldLogStatistics, orgId, event, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, shouldLogStatistics, event, JSON.stringify(data)]);
};

/**
 * This hook logs an event if the condition is true.
 * @param shouldLog - boolean condition to log the event
 * @param event - event to log
 * @param data - data to log
 * @returns void
 * @example
 *   const data = { applicationId: '123' };
 *   useLogEventIf(!loading, LoggerEvent.CLIENT_SCHEDULE_FLOW_BEGIN, {
 *     applicationId: '123'
 *   });
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const useLogEventIf = (shouldLog: boolean, event: LoggerEvent, data: LoggerData = {}) => {
  const orgId = useOrgId();
  const shouldLogStatistics = useShouldLogStatistics();
  useEffect(() => {
    if (shouldLog) {
      logEventHelper(shouldLogStatistics, orgId, event, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLog, orgId, shouldLogStatistics, event, JSON.stringify(data)]);
};

/**
 * This hook returns a function that can be used to log an event.
 * @returns logEvent function
 * @example
 *  const { logEvent } = useLogEvent();
 *  logEvent(LoggerEvent.CLIENT_SCHEDULE_FLOW_BEGIN, {
 *    applicationId: '123'
 *  });
 */
export const useLogEvent = (): { logEvent: (event: LoggerEvent, data?: LoggerDataWithEmpties) => void } => {
  const orgId = useOrgId();
  const shouldLogStatistics = useShouldLogStatistics();
  const logEvent = useCallback(
    (event: string, data?: LoggerData) => logEventHelper(shouldLogStatistics, orgId, event, data),
    [orgId, shouldLogStatistics]
  );

  return { logEvent };
};
