import { useMutation, useQueryClient } from 'react-query';

import { IntegrationType } from 'src/generated/mloop-graphql';

import { putIntegration } from './integrationPut';
import { QUERY_KEY } from './types';

const INTEGRATION_TYPE: IntegrationType = IntegrationType.GreenhouseApiKey;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
function putGreenhouseIntegration(apiKey: string, subdomain: string, webhook_secret?: string) {
  return putIntegration({
    integration_type: INTEGRATION_TYPE,
    greenhouse: {
      api_key: apiKey,
      subdomain,
      webhook_secret,
    },
  });
}

export default function useGreenhouseIntegrationPut() {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { apiKey: string; subdomain: string; webhook_secret?: string }) =>
      putGreenhouseIntegration(data.apiKey, data.subdomain, data.webhook_secret),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY]);
        queryClient.invalidateQueries([QUERY_KEY, INTEGRATION_TYPE]);
      },
    }
  );
}
