import React, { FC, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { CircularProgress, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { useDebounce } from 'use-debounce';

import { SourceType, useActivityNoteCreateMutation } from 'src/generated/mloop-graphql';

import { Error } from 'src/components/HelperComponents';
import TextField from 'src/components/TextField';
import Button from 'src/components/button';

import { OS, getOS } from 'src/utils/browserUtils';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface CreateNoteTextFieldProps {
  onSubmit: (note: string) => void;
  onChange: (note: string) => void;
  sourceId: string;
  sourceType: SourceType;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const CreateNoteTextField: FC<CreateNoteTextFieldProps> = ({ onSubmit, sourceId, sourceType, onChange }) => {
  const [activityNoteCreateMutation, { loading, error }] = useActivityNoteCreateMutation();

  const [newNote, setNewNote] = useState<string>('');

  const [debouncedNewNote] = useDebounce(newNote, 500);

  const cmdKOrCtrlEnter = useMemo(() => {
    if ([OS.MacOS, OS.iOS].includes(getOS())) return '⌘+Enter';
    return 'Ctrl+Enter';
  }, []);

  const onNewNoteSubmit = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line promise/catch-or-return
    activityNoteCreateMutation({
      variables: {
        input: {
          note: debouncedNewNote.trim(),
          sourceId,
          sourceType,
        },
      },
    }) // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      .then(() => {
        setNewNote('');
        onSubmit(debouncedNewNote);
      });
  };

  return (
    <Stack direction="column" spacing={1}>
      <TextField
        placeholder="Add a comment"
        multiline
        value={newNote}
        disabled={loading}
        onChange={(e) => {
          const noteText = e.target.value.trimStart();

          setNewNote(noteText);
          onChange(noteText);
        }}
        inputProps={{
          style: { padding: '2px 0' },
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          style: { alignItems: 'flex-end', padding: '8px 0' },
          endAdornment: loading ? (
            <CircularProgress size={20} />
          ) : (
            <Button
              variant="contained"
              color="primary"
              label="Post"
              size="small"
              onClick={onNewNoteSubmit}
              disabled={isEmpty(newNote)}
              tooltip={isEmpty(newNote) ? undefined : `${cmdKOrCtrlEnter} to post`}
            />
          ),
          onKeyDown: (e) => {
            if (e.key === 'Enter' && (e.ctrlKey || e.metaKey) && newNote) {
              onNewNoteSubmit();
            }
          },
        }}
        style={{ minHeight: 'unset' }}
      />
      {error ? <Error error={error} /> : null}
    </Stack>
  );
};

export const ActivityNoteCreateMutation = gql`
  mutation ActivityNoteCreate($input: ActivityNoteCreateInput!) {
    activityNoteCreate(input: $input) {
      activityLog {
        id
        createdAt
        activityType
        activityActorType
        activityActorEmployeeId
        actorEmployee {
          id
          fullName
          slackImageUrl
        }
        details {
          ... on ActivityLogNoteDetails {
            note
          }
        }
      }
    }
  }
`;

export default CreateNoteTextField;
