import { onError } from '@apollo/client/link/error';

import logError from 'src/utils/logError';
import logMessage from 'src/utils/logMessage';

export const ErrorLink = onError(({ graphQLErrors, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      let exception: Error | undefined =
        error.message && error.message !== 'Sorry, something went wrong.' ? new Error(error.message) : undefined;
      if (!exception && error.extensions?.errorInfo?.length) {
        exception = new Error(`${error.extensions.errorInfo[0].domain} - ${error.extensions.errorInfo[0].reason}`);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } else if (!exception && (error as any).cause?.message) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        exception = new Error(`GraphQL Error: ${(error as any).cause?.message}`);
      } else if (!exception && error.message) {
        exception = new Error(`GraphQL Error: ${error.message}`);
      } else if (!exception) {
        exception = new Error('GraphQL Error');
      }

      const extra = {
        message: error.message,
        path: error.path,
        locations: error.locations,
        extensions: error.extensions,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        errorType: (error as any).errorType,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        exception: (error as any).exception,
        operation: operation.operationName,
      };

      logError(exception, extra);
      logMessage('GraphQLError', 'error', error);
    });
  }
});
