import React from 'react';

import { TriangleDownIcon } from '@modernloop/shared/icons';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';

import { SelfScheduleLocation, SelfScheduleMeetingHost, useEmployeeByIdsQuery } from 'src/generated/mloop-graphql';

import { MagicIcon } from 'src/components/icons';
import useEqualSizeSxProps from 'src/components/utils/useEqualSizeSxProps';

import SingleEmployeeSelect from 'src/entities/Select/EmployeeSelect/SingleEmployeeSelect';
import { SmartOptions } from 'src/entities/Select/EmployeeSelect/types';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

type Props = {
  location: SelfScheduleLocation;
  meetingHost: SelfScheduleMeetingHost | undefined;
  videoMeetingHostEmployeeId: string | undefined;
  onMeetingHostChanged: (value: SelfScheduleMeetingHost) => void;
  onVideoMeetingHostEmployeeIdChanged: (value: string | undefined) => void;
};

const VideoMeetingHostSelect = ({
  location,
  meetingHost,
  videoMeetingHostEmployeeId,
  onMeetingHostChanged,
  onVideoMeetingHostEmployeeIdChanged,
}: Props) => {
  const sxProps = useEqualSizeSxProps();

  const { data: videoMeetingHostEmployeeData } = useEmployeeByIdsQuery({
    variables: { input: [videoMeetingHostEmployeeId] },
    skip: meetingHost !== SelfScheduleMeetingHost.CustomMeetingHost || !videoMeetingHostEmployeeId,
  });

  if (location !== SelfScheduleLocation.Google && location !== SelfScheduleLocation.MicrosoftTeams) {
    return null;
  }

  return (
    <ConditionalThemeProvider>
      <Stack
        alignItems="center"
        direction={{ xs: 'column', sm: 'row' }}
        sx={sxProps.equalSizeChild}
        justifyContent="space-between"
        spacing={1}
      >
        <Box>
          <Typography>Host</Typography>
        </Box>
        <Box>
          <SingleEmployeeSelect
            showSmartOptions
            getLabel={() => {
              let label = 'Loading…';
              let startIcon = <></>;
              if (meetingHost === SelfScheduleMeetingHost.Interviewer) {
                label = 'Interviewer';
                startIcon = <MagicIcon color="tertiary" />;
              } else if (meetingHost === SelfScheduleMeetingHost.Scheduler) {
                label = 'Scheduler';
                startIcon = <MagicIcon color="tertiary" />;
              }
              if (videoMeetingHostEmployeeData?.employeeByIds?.length) {
                label = videoMeetingHostEmployeeData.employeeByIds[0].fullName || '';
              }

              if (videoMeetingHostEmployeeData?.employeeByIds?.length) {
                startIcon = (
                  <Avatar
                    src={videoMeetingHostEmployeeData.employeeByIds[0].slackImageUrl || undefined}
                    alt={videoMeetingHostEmployeeData.employeeByIds[0].fullName || ''}
                  />
                );
              }

              return (
                <Button
                  fullWidth
                  endIcon={<TriangleDownIcon />}
                  sx={{ borderRadius: 0.75, justifyContent: 'space-between' }}
                >
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    {startIcon}
                    <Typography noWrap>{label}</Typography>
                  </Stack>
                </Button>
              );
            }}
            onEmployeeSelect={(emp) => onVideoMeetingHostEmployeeIdChanged(emp.id)}
            onSmartOptionSelected={(smartOption: SmartOptions) => {
              if (smartOption === SmartOptions.Interviewer) {
                onMeetingHostChanged(SelfScheduleMeetingHost.Interviewer);
              } else if (smartOption === SmartOptions.Scheduler) {
                onMeetingHostChanged(SelfScheduleMeetingHost.Scheduler);
              }
            }}
          />
        </Box>
      </Stack>
    </ConditionalThemeProvider>
  );
};

export default VideoMeetingHostSelect;
