import { RadioIcon, RadioSelectedIcon } from '@modernloop/shared/icons';
import { ThemeOptions } from '@mui/material';

/**
 * View Radi props and classes at https://mui.com/material-ui/api/radio/#css
 */
export const radio: ThemeOptions['components'] = {
  MuiRadio: {
    defaultProps: {
      icon: <RadioIcon />,
      checkedIcon: <RadioSelectedIcon />,
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => ({
        borderRadius: '6px',
        '& svg': {
          height: '20px',
          width: '20px',
        },
        ...(ownerState.size === 'small' && {
          padding: theme.spacing(0.5),
        }),
        ...(ownerState.size === 'medium' && {
          padding: theme.spacing(0.75),
        }),
      }),
    },
  },
};
