import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  FreeformSeat,
  JobStageInterviewSeatType,
  LinkedSeat,
  ModuleSeat,
  TrainingStatus,
} from 'src/generated/mloop-graphql';

export type AttributeMap = {
  [key: string]: string[];
};

export type JobStageInterviewSeat = {
  id: string;
  jobStageInterviewId: string;
  index: number;
  type: JobStageInterviewSeatType;
  interviewId?: string; // interview module to which this seat points to.
  interviewSeatId?: string; // will be available for linked interview.
  employeeIds?: string[]; // list of employees for freeform seats.

  // local fields
  attributeMap?: AttributeMap;
  selectedTrainedInterviewerIds?: string[];
  selectedReverseShadowInterviewerIds?: string[];
  selectedShadowInterviewerIds?: string[];

  freeformSeat?: FreeformSeat;
  moduleSeat?: ModuleSeat;
  linkedSeat?: LinkedSeat;
};

export type UpdateJobStageInterviewSeatPayload = Pick<JobStageInterviewSeat, 'id'> &
  Partial<Omit<JobStageInterviewSeat, 'id'>>;

type JobStageInterviewSeatState = {
  byId: { [key: string]: JobStageInterviewSeat };
};

const getInitialState = (): JobStageInterviewSeatState => {
  return {
    byId: {},
  };
};

const jobStageInterviewSeatSlice = createSlice({
  name: 'jobStageInterviewSeat',
  initialState: getInitialState(),
  reducers: {
    addJobStageInterviewSeat: (state: JobStageInterviewSeatState, action: PayloadAction<JobStageInterviewSeat>) => {
      const { payload } = action;
      state.byId[payload.id] = payload;
    },

    updateJobStageInterviewSeat: (
      state: JobStageInterviewSeatState,
      action: PayloadAction<UpdateJobStageInterviewSeatPayload>
    ) => {
      const { payload } = action;

      const seat = state.byId[payload.id];
      if (!seat) return;
      let attributeMap: AttributeMap | undefined;
      let selectedTrainedInterviewerIds: string[] | undefined;
      let selectedReverseShadowInterviewerIds: string[] | undefined;
      let selectedShadowInterviewerIds: string[] | undefined;

      if (seat.interviewId === payload.interviewId) {
        attributeMap = seat.attributeMap;
        selectedTrainedInterviewerIds = seat.selectedTrainedInterviewerIds;
        selectedReverseShadowInterviewerIds = seat.selectedReverseShadowInterviewerIds;
        selectedShadowInterviewerIds = seat.selectedShadowInterviewerIds;
      }

      state.byId[payload.id] = {
        ...state.byId[payload.id],
        ...payload,
        attributeMap,
        selectedTrainedInterviewerIds,
        selectedReverseShadowInterviewerIds,
        selectedShadowInterviewerIds,
      };
    },

    deleteJobStageInterviewSeat: (state: JobStageInterviewSeatState, action: PayloadAction<string>) => {
      const { payload: JobStageInterviewId } = action;
      delete state.byId[JobStageInterviewId];
    },

    updateJobStageInterviewSeatAttributes: (
      state: JobStageInterviewSeatState,
      action: PayloadAction<{
        jobStageInterviewSeatId: string;
        attributeNameId: string;
        attributeValueId: string;
        selected: boolean;
      }>
    ) => {
      const { jobStageInterviewSeatId, attributeNameId, attributeValueId, selected } = action.payload;
      const seat = state.byId[jobStageInterviewSeatId];

      if (!seat || seat.type !== JobStageInterviewSeatType.Module) return;
      const attributeMap = seat.attributeMap ?? {};

      if (selected) {
        if (!attributeMap[attributeNameId]) {
          attributeMap[attributeNameId] = [];
        }
        attributeMap[attributeNameId].push(attributeValueId);
      } else {
        attributeMap[attributeNameId] = attributeMap[attributeNameId].filter((id) => id !== attributeValueId);

        if (attributeMap[attributeNameId].length === 0) {
          delete attributeMap[attributeNameId];
        }
      }

      state.byId[jobStageInterviewSeatId].attributeMap = attributeMap;
      delete state.byId[jobStageInterviewSeatId].selectedTrainedInterviewerIds;
      delete state.byId[jobStageInterviewSeatId].selectedShadowInterviewerIds;
      delete state.byId[jobStageInterviewSeatId].selectedReverseShadowInterviewerIds;
    },

    updateJobStageInterviewSeatPeople: (
      state: JobStageInterviewSeatState,
      action: PayloadAction<{
        jobStageInterviewSeatId: string;
        employeeId: string;
        status: TrainingStatus;
        selected: boolean;
      }>
    ) => {
      const { jobStageInterviewSeatId, employeeId, status, selected } = action.payload;
      const seat = state.byId[jobStageInterviewSeatId];

      if (!seat || seat.type !== JobStageInterviewSeatType.Module) return;

      seat.selectedTrainedInterviewerIds = seat.selectedTrainedInterviewerIds ?? [];
      seat.selectedShadowInterviewerIds = seat.selectedShadowInterviewerIds ?? [];
      seat.selectedReverseShadowInterviewerIds = seat.selectedReverseShadowInterviewerIds ?? [];

      if (status === TrainingStatus.Trained) {
        if (selected) {
          seat.selectedTrainedInterviewerIds.push(employeeId);
        } else {
          seat.selectedTrainedInterviewerIds = seat.selectedTrainedInterviewerIds.filter((id) => id !== employeeId);
        }
      } else if (status === TrainingStatus.Shadow) {
        if (selected) {
          seat.selectedShadowInterviewerIds.push(employeeId);
        } else {
          seat.selectedShadowInterviewerIds = seat.selectedShadowInterviewerIds.filter((id) => id !== employeeId);
        }
      } else if (status === TrainingStatus.ReverseShadow) {
        if (selected) {
          seat.selectedReverseShadowInterviewerIds.push(employeeId);
        } else {
          seat.selectedReverseShadowInterviewerIds = seat.selectedReverseShadowInterviewerIds.filter(
            (id) => id !== employeeId
          );
        }
      }

      delete seat.attributeMap;
      state.byId[jobStageInterviewSeatId] = seat;
    },

    updateJobStageInterviewSeatModuleMembers: (
      state: JobStageInterviewSeatState,
      action: PayloadAction<{
        jobStageInterviewSeatId: string;
        moduleMembers;
      }>
    ) => {
      const { jobStageInterviewSeatId, moduleMembers } = action.payload;
      const seat = state.byId[jobStageInterviewSeatId];

      if (!seat || seat.type !== JobStageInterviewSeatType.Module) return;

      seat.selectedTrainedInterviewerIds = [];
      seat.selectedShadowInterviewerIds = [];
      seat.selectedReverseShadowInterviewerIds = [];

      moduleMembers.forEach((member) => {
        if (member.status === TrainingStatus.Trained && seat.selectedTrainedInterviewerIds) {
          seat.selectedTrainedInterviewerIds.push(member.employeeId);
        } else if (member.status === TrainingStatus.Shadow && seat.selectedShadowInterviewerIds) {
          seat.selectedShadowInterviewerIds.push(member.employeeId);
        } else if (member.status === TrainingStatus.ReverseShadow && seat.selectedReverseShadowInterviewerIds) {
          seat.selectedReverseShadowInterviewerIds.push(member.employeeId);
        }
      });

      delete seat.attributeMap;
      state.byId[jobStageInterviewSeatId] = seat;
    },

    clearJobStageInterviewSeatFilters: (
      state: JobStageInterviewSeatState,
      action: PayloadAction<{ jobStageInterviewSeatId: string }>
    ) => {
      const { jobStageInterviewSeatId } = action.payload;
      state.byId[jobStageInterviewSeatId].attributeMap = {};
      state.byId[jobStageInterviewSeatId].selectedTrainedInterviewerIds = undefined;
      state.byId[jobStageInterviewSeatId].selectedShadowInterviewerIds = undefined;
      state.byId[jobStageInterviewSeatId].selectedReverseShadowInterviewerIds = undefined;
    },
  },
});

export const { reducer } = jobStageInterviewSeatSlice;
export default jobStageInterviewSeatSlice;
