import urlcat from 'urlcat';

import { Routes } from 'src/constants/routes';

export enum AccountSettingsTab {
  GENERAL = 'general',
  APPS = 'apps',
  NOTIFICATIONS = 'notifications',
}

export type AccountSettingsUrlType = `/account/settings/${AccountSettingsTab}`;

type Props = {
  tab: AccountSettingsTab;
};

export const getAccountSettingUrls = ({ tab }: Props): AccountSettingsUrlType => {
  return urlcat('', Routes.AccountSettings, { tab }) as AccountSettingsUrlType;
};
