import React, { useState } from 'react';

import { Dialog } from '@modernloop/shared/components';
import { Stack, Typography } from '@mui/material';

import { useSelfScheduleOptionsDeleteMutation } from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type SelfScheduleOpenAlertProps = {
  selfScheduleOptionId: string;
  onContinue: () => void;
  onClose: () => void;
};

const SelfScheduleOpenAlert = ({ selfScheduleOptionId, onContinue, onClose }: SelfScheduleOpenAlertProps) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selfScheduleDelete, { loading: selfScheduleDeleting }] = useSelfScheduleOptionsDeleteMutation({
    variables: { id: selfScheduleOptionId },
  });

  const handleContinue = () => {
    selfScheduleDelete() // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      .then(() => {
        onContinue();
      })
      .catch((reason) => setErrorMessage(reason.message));
  };

  return (
    <Dialog
      open
      onClose={onClose}
      title="Heads up"
      submitOptions={{
        label: 'Continue',
        isDisabled: selfScheduleDeleting,
        isLoading: selfScheduleDeleting,
        onClick: handleContinue,
      }}
      cancelOptions={{
        isDisabled: selfScheduleDeleting,
        label: 'Cancel',
        onClick: onClose,
      }}
    >
      <Stack spacing={1}>
        {errorMessage && <Alert status="error" title={errorMessage} />}
        <Typography fontWeight={400}>
          There is an open request for the candidate to self-schedule this stage. Scheduling this candidate manually
          will cancel that request.
        </Typography>
      </Stack>
    </Dialog>
  );
};

export default SelfScheduleOpenAlert;
