import React, { useCallback } from 'react';

import { CrossIcon, EditIcon, LinkIcon } from '@modernloop/shared/icons';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { JobStageInterviewSeatType } from 'src/generated/mloop-graphql';

import Chip from 'src/components/chip';
import ModulesIcon from 'src/components/icons/Modules';
import Tooltip from 'src/components/tooltip';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

import { JobStageInterview } from 'src/store/slices/job-stage-interview';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import { useAvatarSxProps, useGridSxProps, useSxProps } from './sxProps';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface LinkedSeatProps {
  jobStageInterviewSeat: JobStageInterviewSeat;
  handleMouseOut: () => void;
  handleMouseOver: () => void;
  seatIndex: number;
  onEdit: () => void;
  showOptions: boolean;
  handleDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
  originalSeat: JobStageInterviewSeat;
  linkableInterviews: JobStageInterview[];
}

const LinkedSeat: React.FC<LinkedSeatProps> = ({
  jobStageInterviewSeat,
  handleMouseOut,
  handleMouseOver,
  onEdit,
  seatIndex,
  showOptions,
  handleDelete,
  originalSeat,
  linkableInterviews,
}) => {
  const sxProps = useSxProps();
  const gridSxProps = useGridSxProps();
  const avatarSxProps = useAvatarSxProps();

  const getJobStageInterview = useCallback(
    (jobStageInterviewId: string): JobStageInterview | undefined => {
      return linkableInterviews.find((interview) => interview.id === jobStageInterviewId);
    },
    [linkableInterviews]
  );

  const linkedJobStageInterview = getJobStageInterview(originalSeat.jobStageInterviewId);

  // This case is hit for a very short window when someone deletes a seat that is linked elsewhere.
  if (!linkedJobStageInterview) return null;

  const originalSeatIndex = linkedJobStageInterview?.seatIds.indexOf(originalSeat.id);

  let label;
  if (originalSeat.type === JobStageInterviewSeatType.Freeform) {
    if (!originalSeat.employeeIds) {
      label = '';
    } else if (originalSeat.employeeIds.length > 1) {
      const text = `(1 of ${originalSeat.employeeIds.length} people)`;
      label = <Typography sx={sxProps.linkedInterviewLabel}>{text}</Typography>;
    } else if (originalSeat.employeeIds.length === 1) {
      const employee = originalSeat?.freeformSeat?.jobStageInterviewSeatEmployees?.[0]?.employee;
      label = (
        <Typography sx={sxProps.linkedInterviewLabel}>
          (
          <Chip
            key={employee?.id}
            label={<MaybeTooltip tooltip={employee?.fullName ?? ''} label={employee?.fullName ?? ''} />}
            sx={sxProps.linkedSeatChip}
            avatar={<Avatar src={employee?.slackImageUrl ?? undefined} />}
            variant={showOptions ? 'outlined' : 'default'}
          />
          )
        </Typography>
      );
    }
  } else if (originalSeat.type === JobStageInterviewSeatType.Module) {
    const interviewModule = originalSeat?.moduleSeat?.interviewModule;

    if (!interviewModule) return null;

    label = (
      <Typography sx={sxProps.linkedInterviewLabel}>
        (
        <Chip
          key={interviewModule.id}
          label={<MaybeTooltip tooltip={interviewModule.name} label={interviewModule.name} />}
          sx={sxProps.linkedSeatChip}
          avatar={<ModulesIcon />}
          variant={showOptions ? 'outlined' : 'default'}
        />
        &nbsp;)
      </Typography>
    );
  }

  const linkedInterviewLabel =
    (linkedJobStageInterview?.name ?? 'Interview name') +
    (linkedJobStageInterview && linkedJobStageInterview?.seatIds.length > 1 ? ` (Seat ${originalSeatIndex + 1})` : '');

  return (
    <Grid>
      <Box onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} sx={sxProps.box} onClick={onEdit}>
        <Grid container justifyContent="space-between" sx={gridSxProps.root} spacing={1} tabIndex={0} wrap="nowrap">
          <Grid xs={10}>
            <Grid container alignItems="center">
              <Grid sx={sxProps.one}>
                <Avatar sx={avatarSxProps.root}>{seatIndex + 1}</Avatar>
              </Grid>
              <Grid sx={sxProps.one}>
                <Typography>Same as:</Typography>
              </Grid>
              <Grid style={{ overflow: 'hidden', maxWidth: '100%' }}>
                <Chip
                  key={jobStageInterviewSeat.id}
                  sx={sxProps.linkedInterviewChip}
                  label={
                    <MaybeTooltip
                      tooltip={linkedInterviewLabel}
                      label={linkedInterviewLabel}
                      labelProps={{ color: 'info' }}
                    />
                  }
                  avatar={<LinkIcon />}
                  variant={showOptions ? 'outlined' : 'default'}
                />
              </Grid>
              <Grid sx={[sxProps.one, { overflow: 'hidden', maxWidth: '100%' }]}>{label}</Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" alignItems="center" xs sx={sxProps.options} wrap="nowrap">
            <Grid>
              <Tooltip tooltip="Edit">
                <IconButton sx={sxProps.actionButton} onClick={onEdit} size="small">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid>
              <Tooltip tooltip="Delete">
                <IconButton sx={sxProps.actionButton} onClick={handleDelete} size="small">
                  <CrossIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default LinkedSeat;
