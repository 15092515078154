import { useIsMobile } from '@modernloop/shared/hooks';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions as MuiDialogActions,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import React, { useMemo } from 'react';

/**
 * The options for the submit and cancels bottoms in the footer
 */
export type ButtonOptions = {
  isDisabled?: boolean;
  isLoading?: boolean;
  isDangerous?: boolean;
  isFullWidth?: boolean;
  label?: string;
  tooltip?: string;
  dataTestId?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick: () => void;
};

export type Props = {
  /**
   * The options for the submit button in the bottom right
   * If this is not provided, the dialog will not have a submit button in the bottom right
   */
  submitOptions?: ButtonOptions;

  /**
   * The options for the secondary submit button in the bottom right
   * If this is not provided, the dialog will not have a second button in the bottom right
   * This will only display if the submit button is also provided
   */
  secondaryOptions?: ButtonOptions;

  /**
   * The options for the cancel button in the bottom left
   * If this is not provided, the dialog will not have a cancel button in the bottom left
   *
   * There is always a close icon button in the top right of the modal
   */
  cancelOptions?: ButtonOptions;
};

const useSxProps = () => {
  const theme = useTheme();

  return useMemo(() => {
    return {
      fullScreenActionStyles: {
        flexGrow: 1,
        position: 'relative',
        paddingLeft: theme.spacing(2.5),
        flexDirection: 'column-reverse',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
      },
    };
  }, [theme]);
};

const DialogActions = ({ submitOptions, secondaryOptions, cancelOptions }: Props) => {
  const sxProps = useSxProps();
  const isFullScreen = useIsMobile();

  // Primary button
  const shouldShowSubmitButton = !!submitOptions;
  const submitWordingToUse = submitOptions?.label ?? 'Submit';

  // Secondary button
  const shouldShowSecondaryButton = !!secondaryOptions;
  const secondaryButtonWordingToUse = secondaryOptions?.label ?? 'Optional';

  // Close button
  const shouldShowCloseButton = !!cancelOptions;
  const closeWordingToUse = cancelOptions?.label ?? 'Close';

  const shouldShowActions = shouldShowSubmitButton || shouldShowCloseButton || shouldShowSecondaryButton;

  if (!shouldShowActions) return null;

  let actionsJustify = 'space-between';
  if (shouldShowSubmitButton && !shouldShowCloseButton) {
    actionsJustify = 'flex-end';
  } else if (!shouldShowSubmitButton && shouldShowCloseButton) {
    actionsJustify = 'flex-start';
  }

  return (
    <MuiDialogActions
      // eslint-disable-next-line no-restricted-syntax
      sx={{
        justifyContent: actionsJustify,
        ...(isFullScreen ? sxProps.fullScreenActionStyles : {}),
      }}
    >
      {shouldShowCloseButton && (
        <Button
          color="inherit"
          variant="text"
          data-testid={cancelOptions.dataTestId}
          disabled={cancelOptions?.isDisabled}
          // variant="text"
          onClick={cancelOptions?.onClick}
          fullWidth={isFullScreen}
          startIcon={cancelOptions.startIcon}
          endIcon={
            cancelOptions?.isLoading ? (
              <CircularProgress size={20} />
            ) : cancelOptions.endIcon ? (
              cancelOptions.endIcon
            ) : undefined
          }
        >
          {closeWordingToUse}
        </Button>
      )}
      {shouldShowSecondaryButton && shouldShowSubmitButton && (
        <Stack
          width={isFullScreen ? '100%' : undefined}
          mb={isFullScreen ? 1 : undefined}
          direction={isFullScreen ? 'column-reverse' : 'row'}
          gap={isFullScreen ? 1 : 0}
          spacing={1}
        >
          <Tooltip title={secondaryOptions?.tooltip}>
            {/* eslint-disable-next-line no-restricted-syntax */}
            <Box sx={{ display: 'inline', width: isFullScreen ? '100%' : undefined }}>
              <Button
                data-testid={secondaryOptions.dataTestId}
                disabled={secondaryOptions?.isDisabled}
                onClick={secondaryOptions?.onClick}
                fullWidth={isFullScreen}
                startIcon={secondaryOptions.startIcon}
                endIcon={
                  secondaryOptions?.isLoading ? (
                    <CircularProgress size={20} />
                  ) : secondaryOptions.endIcon ? (
                    secondaryOptions.endIcon
                  ) : undefined
                }
              >
                {secondaryButtonWordingToUse}
              </Button>
            </Box>
          </Tooltip>
          <Tooltip title={submitOptions?.tooltip}>
            {/* eslint-disable-next-line no-restricted-syntax */}
            <Box sx={{ display: 'inline', width: isFullScreen || submitOptions?.isFullWidth ? '100%' : undefined }}>
              <Button
                data-testid={submitOptions.dataTestId}
                disabled={submitOptions?.isDisabled}
                variant="contained"
                color={submitOptions?.isDangerous ? 'error' : 'primary'}
                onClick={submitOptions?.onClick}
                fullWidth={submitOptions?.isFullWidth || isFullScreen}
                startIcon={submitOptions.startIcon}
                endIcon={
                  submitOptions?.isLoading ? (
                    <CircularProgress size={20} />
                  ) : submitOptions.endIcon ? (
                    submitOptions.endIcon
                  ) : undefined
                }
              >
                {submitWordingToUse}
              </Button>
            </Box>
          </Tooltip>
        </Stack>
      )}
      {!shouldShowSecondaryButton && shouldShowSubmitButton && (
        <Tooltip title={submitOptions?.tooltip}>
          {/* eslint-disable-next-line no-restricted-syntax */}
          <Box sx={{ display: 'inline', width: isFullScreen || submitOptions?.isFullWidth ? '100%' : undefined }}>
            <Button
              data-testid={submitOptions.dataTestId}
              disabled={submitOptions?.isDisabled}
              variant="contained"
              color={submitOptions?.isDangerous ? 'error' : 'primary'}
              onClick={submitOptions?.onClick}
              fullWidth={submitOptions?.isFullWidth || isFullScreen}
              startIcon={submitOptions.startIcon}
              endIcon={
                submitOptions?.isLoading ? (
                  <CircularProgress size={20} />
                ) : submitOptions.endIcon ? (
                  submitOptions.endIcon
                ) : undefined
              }
            >
              {submitWordingToUse}
            </Button>
          </Box>
        </Tooltip>
      )}
    </MuiDialogActions>
  );
};

export default DialogActions;
