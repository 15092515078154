import React, { useMemo } from 'react';

import { Paper as MuiPaper, SxProps, useTheme } from '@mui/material';

import { BaseProps, createSxProps } from 'src/components/types';
import { SupportedFunctionColor, SupportedStatusColor } from 'src/components/utils/color';

import { Theme } from 'src/themeMui5/type';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface PaperProps extends BaseProps {
  /**
   * Allows setting a supported color for background.
   */
  color?: 'default' | 'alternate' | 'contrast' | SupportedStatusColor | SupportedFunctionColor;

  /**
   * Allows setting a supported borderRadius size
   */
  size?: 'medium' | 'large';

  /**
   * Disables inner padding
   */
  disablePadding?: boolean;

  /**
   * Disables border
   */
  disableBorder?: boolean;

  /**
   * Allows setting a custom color for background. E.g. Break divider
   */
  customBackgroundColor?: string;

  /**
   * @deprecated
   * Override styles with a custom styles
   */
  style?: React.CSSProperties;

  /**
   * Override styles with a custom styles
   */
  sx?: SxProps<Theme>;

  /**
   * Shadow depth, corresponds to `dp` in the spec.
   * It accepts values between 0 and 24 inclusive.
   */
  elevation?: number;
}

const useSxProps = (props: PaperProps) => {
  const theme = useTheme();

  const sxProps = useMemo(() => {
    return {
      paper: (() => {
        const styles: SxProps = {
          border: props.disableBorder ? undefined : `1px solid ${theme.palette.border}`,
        };

        switch (props.size) {
          case 'medium': {
            styles.borderRadius = '6px';
            styles.padding = props.disablePadding ? undefined : '8px';
            break;
          }
          default:
          case 'large': {
            styles.borderRadius = '12px';
            styles.padding = props.disablePadding ? undefined : '12px';
            break;
          }
        }

        switch (props.color) {
          case 'alternate':
          case 'contrast':
          case 'info':
          case 'warning':
          case 'error':
          case 'success':
          case 'primary':
          case 'secondary':
          case 'tertiary':
          case 'pending': {
            styles.backgroundColor = theme.palette.background[props.color];
            break;
          }
          case 'default':
          default: {
            styles.backgroundColor = theme.palette.background.paper;
            if (props.customBackgroundColor) {
              styles.backgroundColor = props.customBackgroundColor;
            }
            break;
          }
        }

        return styles;
      })(),
    };
  }, [props, theme]);

  return createSxProps(sxProps);
};

/**
 * @deprecated - Use `Paper` from `@mui/material` instead
 */
const Paper: React.FC<PaperProps> = (props) => {
  const {
    children,
    dataTestId,
    style,
    sx,
    color,
    size,
    disablePadding,
    disableBorder,
    customBackgroundColor,
    elevation = 0,
  } = props;
  const sxProps = useSxProps({ color, size, disablePadding, disableBorder, customBackgroundColor });

  const sxPaperProps = useMemo(() => {
    if (!sx) return sxProps.paper;
    return { ...sxProps.paper, ...sx };
  }, [sx, sxProps.paper]);

  return (
    <MuiPaper style={style} elevation={elevation} sx={sxPaperProps} data-testid={dataTestId}>
      {children}
    </MuiPaper>
  );
};

export default Paper;
