import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ScorecardQuestionMarkIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4189 12.3755C14.6361 12 15.0404 11.75 15.5021 11.75C16.1924 11.75 16.7521 12.3096 16.7521 13C16.7521 13.6904 16.1924 14.25 15.5021 14.25C15.0879 14.25 14.7521 14.5858 14.7521 15C14.7521 15.4142 15.0879 15.75 15.5021 15.75C17.0209 15.75 18.2521 14.5188 18.2521 13C18.2521 11.4812 17.0209 10.25 15.5021 10.25C14.4833 10.25 13.5948 10.8044 13.1205 11.6245C12.913 11.983 13.0356 12.4418 13.3941 12.6492C13.7527 12.8566 14.2115 12.7341 14.4189 12.3755ZM15.4847 18.5C16.0369 18.5 16.4847 18.0523 16.4847 17.5C16.4847 16.9477 16.0369 16.5 15.4847 16.5C14.9324 16.5 14.4847 16.9477 14.4847 17.5C14.4847 18.0523 14.9324 18.5 15.4847 18.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 6.5V8C15.25 8.41421 15.5858 8.75 16 8.75C16.4142 8.75 16.75 8.41421 16.75 8V6.5C16.75 4.98122 15.5188 3.75 14 3.75H13.163C12.8244 2.31665 11.5368 1.25 9.99997 1.25C8.46318 1.25 7.17552 2.31665 6.83694 3.75H6C4.48122 3.75 3.25 4.98122 3.25 6.5V15C3.25 16.5188 4.48122 17.75 6 17.75H11.5C11.9142 17.75 12.25 17.4142 12.25 17C12.25 16.5858 11.9142 16.25 11.5 16.25H6C5.30964 16.25 4.75 15.6904 4.75 15V6.5L15.25 6.5ZM6.24997 10C6.24997 9.58579 6.58576 9.25 6.99997 9.25H11C11.4142 9.25 11.75 9.58579 11.75 10C11.75 10.4142 11.4142 10.75 11 10.75H6.99997C6.58576 10.75 6.24997 10.4142 6.24997 10ZM6.24997 13C6.24997 12.5858 6.58576 12.25 6.99997 12.25H8.99997C9.41418 12.25 9.74997 12.5858 9.74997 13C9.74997 13.4142 9.41418 13.75 8.99997 13.75H6.99997C6.58576 13.75 6.24997 13.4142 6.24997 13ZM11 4C11 4.55228 10.5523 5 9.99997 5C9.44768 5 8.99997 4.55228 8.99997 4C8.99997 3.44772 9.44768 3 9.99997 3C10.5523 3 11 3.44772 11 4Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
