enum ApiVerb {
  API_UPLOAD_FILE = '/fe/interview/file/upload',
  API_UPLOAD = '/fe/interview/upload',
  API_SYNC_JOB = '/fe/interview/syncJobStageAndApplication',
  API_CREATE_CODERPAD = '/fe/interview/createCoderpad',
  API_CREATE_CODESIGNAL = '/fe/interview/createCodesignal',
  API_CREATE_CODILITY = '/fe/interview/createCodility',
  API_CREATE_HACKERRANK = '/fe/interview/createHackerRank',

  // integration
  DELETE_EMPLOYEE_INTEGRATION = '/integration/deleteEmployeeIntegration',
  DELETE_INTEGRATION = '/integration/deleteIntegration',
  GET_INTEGRATION_AUTHORIZE_URL = '/integration/getAuthorizeUrl',
  LIST_EMPLOYEE_INTEGRATION = '/integration/listEmployeeIntegration',
  LIST_INTEGRATION = '/integration/listIntegration',
  GET_ASHBY_INTEGRATION = '/integration/getAshbyIntegration',
  PUT_INTEGRATION = '/integration/putIntegration',

  // Schedule Options Generation
  GET_DEBRIEF_OPTIONS = '/interview/schedule/getDebriefOptions',
  GET_SCHEDULE_OPTIONS = '/interview/schedule/getScheduleOptions',
  GET_RESCHEDULE_OPTIONS = '/interview/schedule/getRescheduleOptions',

  // calendar
  LIST_CALENDAR_EVENT = '/calendar/listEvent',
  GET_EVENT_CONTENT = '/calendar/mgetEvent',
  LIST_CALENDAR = '/calendar/listCalendar',

  // load and limit
  LIST_LOAD_LIMIT = '/fe/interview/listLoadLimit',

  // debrief
  CREATE_DEBRIEF = '/fe/interview/createDebrief',
  CANCEL_DEBRIEF = '/fe/interview/cancelDebrief',

  // org
  ADD_USER_BY_EMPLOYEE_ID = '/org/addUserByEmployeeId',
  LIST_ORG_EMPLOYEE = '/org/listOrgEmployee',
  LIST_ORG_PREF = '/org/pref/list',
  LIST_USER_INFO = '/org/listUserInfo',
  REMOVE_USER = '/org/removeUser',
  SET_ORG_PREF = '/org/pref/set',
  UNSET_ORG_PREF = '/org/pref/unset',

  // employee
  LIST_EMPLOYEE_PREF = '/org/employee/pref/list',

  // Interview
  CANCEL_INTERVIEW = '/fe/interview/cancelInterview',

  // Schedule
  CREATE_SCHEDULE = '/fe/interview/createSchedule',
  UPDATE_SCHEDULE = '/fe/interview/updateSchedule',
  CANCEL_SCHEDULE = '/fe/interview/cancelSchedule',

  // Candidate Sync
  CANDIDATE_SYNC = '/fe/interview/syncCandidate',

  // Candidate availability
  UPDATE_CANDIDATE_AVAILABILITY = '/fe/interview/submitRcAvailability',

  // Candidate update
  UPDATE_CANDIDATE_JOB_STAGE = '/fe/interview/updateJobStage',
}

export default ApiVerb;
