import { SvgIcon, SvgIconProps } from './SvgIcon';

export const PhoneWithArrowPointingInwardsIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9941 9.0645C10.9941 9.26341 11.0731 9.45417 11.2137 9.59483C11.3544 9.73548 11.5452 9.8145 11.7441 9.8145L15.2796 9.81449C15.6938 9.81449 16.0296 9.47871 16.0296 9.06449C16.0296 8.65028 15.6938 8.31449 15.2796 8.31449L13.5547 8.31449L17.2242 4.64508C17.517 4.35218 17.517 3.87731 17.2242 3.58442C16.9313 3.29152 16.4564 3.29152 16.1635 3.58442L12.4941 7.25384L12.4941 5.52896C12.4941 5.11475 12.1583 4.77896 11.7441 4.77896C11.3299 4.77896 10.9941 5.11475 10.9941 5.52896L10.9941 9.0645ZM13.2486 16.3127C11.4403 16.3867 9.29875 15.2986 7.5904 13.5902C5.88204 11.8819 4.79391 9.74035 4.86798 7.93208C4.88059 7.62437 5.05044 7.29136 5.37036 6.97145L6.17618 6.16563C6.27381 6.068 6.4321 6.068 6.52973 6.16563L7.94395 7.57984C8.04158 7.67747 8.04158 7.83576 7.94395 7.93339L7.26172 8.61562C6.86548 9.01187 6.61894 9.65243 6.85781 10.2983C7.17625 11.1594 7.77533 12.0074 8.47428 12.7064C9.17323 13.4053 10.0212 14.0044 10.8823 14.3228C11.5282 14.5617 12.1688 14.3152 12.565 13.9189L13.2472 13.2367C13.3449 13.1391 13.5032 13.1391 13.6008 13.2367L15.015 14.6509C15.1126 14.7485 15.1126 14.9068 15.015 15.0045L14.2092 15.8103C13.8893 16.1302 13.5563 16.3001 13.2486 16.3127ZM3.36924 7.87068C3.27134 10.2608 4.66436 12.7855 6.52974 14.6509C8.39511 16.5163 10.9199 17.9093 13.31 17.8114C14.1258 17.778 14.7946 17.3462 15.2698 16.8709L16.0757 16.0651C16.7591 15.3817 16.7591 14.2737 16.0757 13.5903L14.6615 12.176C13.978 11.4926 12.87 11.4926 12.1866 12.176L11.5044 12.8583C11.471 12.8916 11.4381 12.9076 11.4177 12.9132C11.4084 12.9158 11.4033 12.9158 11.4022 12.9158L11.4022 12.9158C10.794 12.6908 10.1233 12.234 9.53494 11.6457C8.94662 11.0574 8.48987 10.3867 8.26484 9.77845V9.77843C8.26481 9.77737 8.26481 9.77228 8.2674 9.76291C8.27303 9.74255 8.28904 9.70962 8.32238 9.67628L9.00461 8.99406C9.68802 8.31064 9.68802 7.2026 9.00461 6.51918L7.59039 5.10497C6.90698 4.42155 5.79894 4.42155 5.11552 5.10497L4.3097 5.91079C3.83445 6.38604 3.40266 7.05482 3.36924 7.87068Z"
      />
    </SvgIcon>
  );
};
