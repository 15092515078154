import { useEffect, useMemo } from 'react';

import { LazyQueryResult } from '@apollo/client';
import { sortBy } from 'lodash';

import {
  TemplateAttachementsQuery,
  TemplateAttachementsQueryVariables,
  useTemplateAttachementsLazyQuery,
} from 'src/generated/mloop-graphql';

import { FileStatus, FileUploadFlow, UIFile, initFiles } from 'src/slices/files';

import { useDispatch, useSelector } from 'src/store';

export type AttachmentType = {
  name: string;
  timestamp: number;
  path: string;
  size: number;
};

export const useInitAttachmentsForEmailTemplate = (
  id: string | null,
  flow: FileUploadFlow
): LazyQueryResult<TemplateAttachementsQuery, TemplateAttachementsQueryVariables> => {
  const dispatch = useDispatch();
  const [fetchEmailTemplateAttachments, { data, ...rest }] = useTemplateAttachementsLazyQuery();

  useEffect(() => {
    if (!id) return;
    fetchEmailTemplateAttachments({ variables: { id } });
  }, [fetchEmailTemplateAttachments, id]);

  useEffect(() => {
    const attachments: UIFile[] =
      data?.template?.attachments?.map((attachment: AttachmentType) => {
        return { ...attachment, status: FileStatus.UPLOADED };
      }) || [];

    dispatch(initFiles(flow, attachments));
  }, [dispatch, flow, data]);

  return useMemo(
    () => ({ data, ...rest } as LazyQueryResult<TemplateAttachementsQuery, TemplateAttachementsQueryVariables>),
    [data, rest]
  );
};

export const useAttachmentsForUpload = (flow: FileUploadFlow): AttachmentType[] | null => {
  const files =
    sortBy(
      useSelector((state) => state.fileUpload[flow]),
      'timestamp'
    ) || [];

  const attachments: AttachmentType[] = [];
  for (let cnt = 0; cnt < files.length; cnt++) {
    const file: UIFile = files[cnt];
    if (file.status === FileStatus.UPLOADED && file.path) {
      const attachment: AttachmentType = {
        name: file.name,
        timestamp: file.timestamp,
        path: file.path,
        size: file.size,
      };
      attachments.push(attachment);
    }
  }
  return attachments.length ? attachments : null;
};
