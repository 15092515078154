/* eslint-disable no-underscore-dangle */
import React from 'react';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  FieldReadFunction,
  HttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import ApolloSentryLink from 'apollo-sentry-link';
import axios from 'axios';
import { buildAxiosFetch } from 'axios-fetch';
import { sha256 } from 'crypto-hash';

import { API_URL } from 'src/utils/api';
import axiosInstance from 'src/utils/axios';
import setupAxiosInstance from 'src/utils/axios/setupAxiosInstance';

import { GraphQLConfig } from 'src/config';

import { ErrorLink } from './ErrorLink';
import getIsRetryEnabled from './utils/getIsRetryEnabled';

const GQL_ENDPOINT_EPHEMERAL_SEPARATE_REQUESTS = new HttpLink({
  uri: `${API_URL}/graphql/`,
  fetch: buildAxiosFetch(axiosInstance),
});

const axiosNoRetry = setupAxiosInstance(axios.create(), { retries: 0 });

const GQL_ENDPOINT_EPHEMERAL_NO_RETRY_REQUESTS = new HttpLink({
  uri: `${API_URL}/graphql/`,
  fetch: buildAxiosFetch(axiosNoRetry),
});

const GQL_ENDPOINT_EPHEMERAL_BATCHED_REQUESTS = new BatchHttpLink({
  uri: `${API_URL}/graphql/batch`,
  batchMax: GraphQLConfig.batchMax, // No more than X operations per batch
  batchInterval: GraphQLConfig.batchInterval, // Wait no more than Yms after first batched operation
  fetch: buildAxiosFetch(axiosInstance),
});

const GQL_ENDPOINT_PERSISTED_BATCHED_REQUESTS = createPersistedQueryLink({ sha256 }).concat(
  GQL_ENDPOINT_EPHEMERAL_BATCHED_REQUESTS
);

const GQL_ENDPOINT_PERSISTED_SEPARATE_REQUESTS = createPersistedQueryLink({ sha256 }).concat(
  GQL_ENDPOINT_EPHEMERAL_SEPARATE_REQUESTS
);

const GQL_ENDPOINT_PERSISTED_NO_RETRY_REQUESTS = createPersistedQueryLink({ sha256 }).concat(
  GQL_ENDPOINT_EPHEMERAL_NO_RETRY_REQUESTS
);

// Create a new link that will choose between the single or batch endpoints + persisted queries
const mLoopEndpoint = new ApolloLink((operation, forward) => {
  // Check operation context for a custom flag
  const context = operation.getContext();
  const isPersistedEnabled = GraphQLConfig.persistedEnabled;
  const isBatchEnabled = GraphQLConfig.batchEnabled;

  // Get whether the specific opeartion wants to use batch or not
  const { batch } = context;

  // Send to single endpoint if:
  // 1. The operation explicitly sets batch: false in context
  // 2. The global batch flag is false
  const isBatchDisabled = batch === false || !isBatchEnabled;

  const isRetryEnabled = getIsRetryEnabled(operation);

  // Choose the endpoint to use based on batch and persisted setup
  let finalEndpoint: ApolloLink;

  if (isRetryEnabled) {
    if (isPersistedEnabled) {
      // if persisted queries are enabled always hit the persisted endpoint
      if (isBatchDisabled) {
        finalEndpoint = GQL_ENDPOINT_PERSISTED_SEPARATE_REQUESTS;
      } else {
        finalEndpoint = GQL_ENDPOINT_PERSISTED_BATCHED_REQUESTS;
      }

      // if persisted queries are disabled always hit the no persisted endpoint
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isBatchDisabled) {
        finalEndpoint = GQL_ENDPOINT_EPHEMERAL_SEPARATE_REQUESTS;
      } else {
        finalEndpoint = GQL_ENDPOINT_EPHEMERAL_BATCHED_REQUESTS;
      }
    }

    // if retries are disabled always hit the no retry endpoint
  } else {
    // eslint-disable-next-line no-lonely-if
    if (isPersistedEnabled) {
      finalEndpoint = GQL_ENDPOINT_PERSISTED_NO_RETRY_REQUESTS;
    } else {
      finalEndpoint = GQL_ENDPOINT_EPHEMERAL_NO_RETRY_REQUESTS;
    }
  }

  // Forward the operation to the final link
  return finalEndpoint.request(operation, forward);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getRead: (typeName: string) => FieldReadFunction<any, any> = (typeName: string) => {
  return (_, { args, toReference, readField }) => {
    const foundIds: string[] = [];
    args?.ids
      ?.forEach((id) => {
        const idVal = readField('id', { __ref: `${typeName}:${id}` }) as string;
        if (args?.ids.includes(idVal)) {
          foundIds.push(id);
        }
      })
      ?.filter(Boolean);

    if (args?.ids.length === 0 || args?.ids.length !== foundIds.length) return undefined;

    return args?.ids?.map((id) =>
      toReference({
        __typename: typeName,
        id,
      })
    );
  };
};

const client = new ApolloClient({
  link: ApolloLink.from([ErrorLink, ApolloSentryLink, mLoopEndpoint]),
  cache: new InMemoryCache({
    possibleTypes: {
      PortalTemplateBlock: [
        'PortalTemplateBlockContentCard',
        'PortalTemplateBlockDivider',
        'PortalTemplateBlockImage',
        'PortalTemplateBlockLinkedCard',
        'PortalTemplateBlockText',
        'PortalTemplateBlockVideo',
      ],
      TaskFlag: [
        'TaskFlagCandidateDeclined',
        'TaskFlagInterviewerDeclined',
        'TaskFlagRequestedChange',
        'TaskFlagInterviewerDebriefDeclined',
      ],
      ModuleMemberInterview: ['ModuleMemberApplicationStageInterviewLog', 'ModuleMemberManualLog'],
    },
    typePolicies: {
      interview_interviewer: {
        keyFields: ['interviewer_id', 'interview_id'],
      },
      application_debrief_room: {
        keyFields: ['application_debrief_id', 'meeting_room_id'],
      },
      JobStageInterview: {
        fields: {
          forcedStartAt: {
            read: () => {
              // Always return undefined, it is a client side field
              return undefined;
            },
          },
        },
      },
      InterviewModuleMember: {
        keyFields: ['interviewModuleId', 'employeeId'],
        fields: {
          stats: {
            merge: true,
          },
        },
      },
      EmployeeNotificationPreference: {
        keyFields: ['employeeId', 'medium', 'type'],
      },
      EmployeePref: {
        keyFields: ['employeeId', 'orgId', 'prefName'],
      },
      OrgPref: {
        keyFields: ['orgId', 'prefName'],
      },
      RecruitingSource: {
        keyFields: ['source'],
      },
      SettingCalendarId: {
        keyFields: ['remoteCalendarId'],
      },
      SettingTemplateId: {
        keyFields: ['templateId'],
      },
      Template: {
        fields: {
          attachments: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Query: {
        fields: {
          template: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Template',
                id: args?.id,
              });
            },
          },
          employeeByIds: {
            read: getRead('Employee'),
          },
          jobByIds: {
            read: getRead('Job'),
          },
          templateByIds: {
            read: getRead('Template'),
          },
        },
      },
    },
    dataIdFromObject: (object) => {
      switch (object.__typename) {
        default: {
          const rowID = object.row_id || object.rowID || object.id || object.atsId;
          return rowID ? `${object.__typename}:${rowID}` : defaultDataIdFromObject(object);
        }
      }
    },
  }),
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
const ApolloProviderX = ({ children }) => <ApolloProvider client={client}>{children}</ApolloProvider>;

export default ApolloProviderX;
