import uiStateSlice, { CandidateListFilters } from 'src/store/slices/ui-state';

import { AppThunk } from 'src/store';

export const setDisplayTimezone =
  (timezone: string): AppThunk =>
  async (dispatch) => {
    dispatch(uiStateSlice.actions.setDisplayTimezone(timezone));
  };

export const setLeftNavExpanded =
  (expanded: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(uiStateSlice.actions.setLeftNavExpanded(expanded));
  };

export const setCandidateListFilters =
  (filters: CandidateListFilters): AppThunk =>
  async (dispatch) => {
    dispatch(uiStateSlice.actions.setCandidateListFilters(filters));
  };
