import { apiPost } from 'src/utils/api';

import ApiVerb from './ApiVerb';
import {
  InterviewerResponseStatus,
  RequestBody as ScheduleOptionsRequestBody,
  ResponseBody as ScheduleOptionsResponseBody,
  inlineEmployees,
} from './getScheduleOptions';

export type RequestBody = ScheduleOptionsRequestBody & {
  interviewersResponseStatus?: InterviewerResponseStatus[];
};

export type ResponseBody = ScheduleOptionsResponseBody;

const getRescheduleOptions = async (data: RequestBody): Promise<ResponseBody> => {
  const response = await apiPost(ApiVerb.GET_RESCHEDULE_OPTIONS, data);
  return inlineEmployees(response.data);
};

export default getRescheduleOptions;
