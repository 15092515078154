import React from 'react';

import BaseIcon, { Props } from './Icon';

const CaretDownTinyIcon = ({ color, fontSize, tooltip, viewBox }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} viewBox={viewBox}>
      <path
        d="M3.375 5.25L6 7.5L8.625 5.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default CaretDownTinyIcon;
