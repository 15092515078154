import { LocationDescriptor } from 'history';

/**
 * Return type for all the hooks in this file
 */
export type ReturnType = {
  isOnboardingRequired: boolean;
  onboardingRedirectParams: LocationDescriptor<LocationState>;
};

/**
 * Integration Type Enum for grant access page
 * Note: **IMP** If you add a new integration type, please update the isIgnoredRoutes function in utils.ts
 */
export enum IntegrationGrantType {
  Outlook = 'outlook',
  Gmail = 'gmail',
  MicrosoftGccHigh = 'microsoftGccHigh',
}

/**
 * LocationState type for LocationDescriptor
 */
export type LocationState = {
  redirectUrl: string;
};
