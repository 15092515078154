import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

export type Props = {
  content: string;
  collapsedNumberOfLines?: number;
  hideGradient?: boolean;
  useButtonForShowMore?: boolean;

  formatCollapsedContent?: boolean;
};

const ExpandableTextView = ({
  content,
  collapsedNumberOfLines,
  hideGradient,
  useButtonForShowMore,
  formatCollapsedContent,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const isEllipsActive = useCallback(() => {
    if (!contentRef.current) return;

    if (isExpanded) return;

    const truncated = contentRef.current.scrollHeight > contentRef.current.clientHeight;
    setIsTruncated(truncated);

    if (!truncated) {
      setIsExpanded(true);
    }
  }, [isExpanded]);

  const plainTextNote = useMemo(() => {
    const div = document.createElement('div');
    // replacing all occurrences of <br> and <br /> with \n to maintain line breaks
    div.innerHTML = content.replaceAll(/<br\s*[/]?>/gi, '\n');
    return div.innerText || div.textContent || '';
  }, [content]);

  const truncatedSx = useMemo(() => {
    if (isExpanded) return {};
    return {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: collapsedNumberOfLines || 3,
      WebkitBoxOrient: 'vertical',
      maskImage:
        hideGradient || isExpanded ? 'none' : 'linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
    };
  }, [collapsedNumberOfLines, hideGradient, isExpanded]);

  useLayoutEffect(() => {
    isEllipsActive();
    window.addEventListener('resize', isEllipsActive);

    return () => {
      window.removeEventListener('resize', isEllipsActive);
    };

    // Adding `content` as a dependency because we want to re-calculate the truncation when the content changes
  }, [content, isEllipsActive]);

  return (
    <Stack spacing={1}>
      <Typography
        component="span"
        // eslint-disable-next-line no-restricted-syntax
        style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
        variant="body1"
      >
        {!isExpanded && (
          <>
            {formatCollapsedContent && (
              <Box
                dangerouslySetInnerHTML={{ __html: content }}
                ref={contentRef}
                // eslint-disable-next-line no-restricted-syntax
                sx={[{ maxWidth: '100%', ...truncatedSx }]}
              />
            )}
            {!formatCollapsedContent && (
              <Box
                ref={contentRef}
                // eslint-disable-next-line no-restricted-syntax
                sx={[{ maxWidth: '100%', ...truncatedSx }]}
              >
                {plainTextNote}
              </Box>
            )}
          </>
        )}
        {isExpanded && (
          <Box
            ref={contentRef}
            // eslint-disable-next-line no-restricted-syntax
            sx={[
              { maxWidth: '100%', '& a': { color: (theme) => theme.palette.primary.main } },
              !isExpanded || !isTruncated ? truncatedSx : {},
            ]}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </Typography>
      {isTruncated && (
        <>
          {useButtonForShowMore && (
            <Button variant="text" color="primary" onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? 'Show less' : 'Show more'}
            </Button>
          )}
          {!useButtonForShowMore && (
            <Link underline="hover" onClick={() => setIsExpanded(!isExpanded)}>
              <Typography>{isExpanded ? 'Show less' : 'Show more'}</Typography>
            </Link>
          )}
        </>
      )}
    </Stack>
  );
};

export default ExpandableTextView;
