import { ThemeOptions } from '@mui/material';

/*
 * View Button classes and props at https://mui.com/material-ui/api/button/#css
 */
export const button: ThemeOptions['components'] = {
  // **Warning** : Any stylings added here will affect all buttons and button-like components such as MenuItem, ListItemButton, etc.
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
      color: 'secondary',
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
      color: 'secondary',
    },
    styleOverrides: {
      root: ({ theme }) => {
        return {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          border: 'none',
          outline: '1px solid',
          outlineOffset: '-1px',
          borderColor: theme.palette.borderState.default,
          '&:focus': {
            outlineOffset: '-1px',
            outline: `1px solid ${theme.palette.borderState.focus}`,
            boxShadow: `0 0 0 4px ${theme.palette.borderState.focusShadow}`,
          },
          outlineColor: 'transparent',
          textTransform: 'inherit',
        };
      },
      contained: ({ theme, ownerState }) => {
        const color = ownerState.color || 'secondary';
        return {
          backgroundColor: color === 'inherit' ? 'inherit' : theme.palette[color].main,
          '&:hover': {
            backgroundColor: color === 'inherit' ? 'inherit' : theme.palette[color].light,
          },
        };
      },
      containedSecondary: ({ theme }) => ({
        outlineColor: theme.palette.secondary.dark,
      }),
      sizeLarge: {
        height: '40px',
        borderRadius: '20px',
        fontSize: '15px',
      },
      sizeMedium: {
        height: '32px',
        borderRadius: '16px',
        fontSize: '15px',
      },
      sizeSmall: {
        height: '28px',
        borderRadius: '14px',
        fontSize: '13px',
        padding: '4px 12px',
      },
      startIcon: {
        fontSize: '20px',
      },
      endIcon: {
        fontSize: '20px',
      },
    },
  },
};
