import React from 'react';

import { Box, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';

import { SyncedFromOrgSettings } from 'src/components/SyncedFromOrgSettings';

import { OrgSettingsSource } from 'src/constants/routes';

import { LockedforOrg } from 'src/entities/LockedForOrg';

import { useOrgPref } from 'src/hooks/api/org';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import { OrgPrefName } from 'src/utils/api/org';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface EventVisibilitySectionProps {
  isPrivateEvent: boolean | null;
  setIsPrivateEvent: (isPrivateEvent: boolean | null) => void;
}

enum EventVisibilitySectionOuterStatus {
  ORG_DEFAULT = 'orgDefault',
  CUSTOM = 'custom',
}

enum EventVisibilitySectionInnerStatus {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export const EventVisibilitySection: React.FC<EventVisibilitySectionProps> = ({
  isPrivateEvent,
  setIsPrivateEvent,
}) => {
  const [eventVisibility] = useOrgPref(OrgPrefName.BOOL_EVENT_VISIBILITY_IS_PRIVATE);

  const handleOutsideRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === EventVisibilitySectionOuterStatus.ORG_DEFAULT) {
      setIsPrivateEvent(null);
    }
    if (event.target.value === EventVisibilitySectionOuterStatus.CUSTOM) {
      setIsPrivateEvent(true);
    }
  };

  const handleInsideRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === EventVisibilitySectionInnerStatus.PRIVATE) {
      setIsPrivateEvent(true);
    }
    if (event.target.value === EventVisibilitySectionInnerStatus.PUBLIC) {
      setIsPrivateEvent(false);
    }
  };

  return (
    <ConditionalThemeProvider>
      <Stack spacing={1} mb={2}>
        <RadioGroup onChange={handleOutsideRadioGroupChange}>
          <FormControlLabel
            value={EventVisibilitySectionOuterStatus.ORG_DEFAULT}
            control={<Radio checked={isPrivateEvent === null || eventVisibility?.isLocked} />}
            label={`Use org default (${eventVisibility?.boolValue === true ? 'Private' : 'Public'})`}
            disabled={eventVisibility?.isLocked}
          />
          <FormControlLabel
            value={EventVisibilitySectionOuterStatus.CUSTOM}
            control={<Radio checked={!eventVisibility?.isLocked && isPrivateEvent !== null} />}
            label="Custom"
            disabled={eventVisibility?.isLocked}
          />
          {isPrivateEvent !== null && (
            <Box ml={4}>
              <RadioGroup onChange={handleInsideRadioGroupChange}>
                <FormControlLabel
                  value={EventVisibilitySectionInnerStatus.PRIVATE}
                  control={<Radio checked={isPrivateEvent === true && !eventVisibility?.isLocked} />}
                  label="Private"
                  disabled={eventVisibility?.isLocked}
                />
                <FormControlLabel
                  value={EventVisibilitySectionInnerStatus.PUBLIC}
                  control={<Radio checked={isPrivateEvent === false && !eventVisibility?.isLocked} />}
                  label="Public"
                  disabled={eventVisibility?.isLocked}
                />
              </RadioGroup>
            </Box>
          )}
        </RadioGroup>

        {isPrivateEvent === null && <SyncedFromOrgSettings source={OrgSettingsSource.ORG_PRIVACY} />}
        {eventVisibility?.isLocked && <LockedforOrg />}
      </Stack>
    </ConditionalThemeProvider>
  );
};
