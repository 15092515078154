import { getVideoMeetingLink } from 'src/store/selectors/schedule-communications';
import { DebriefStage, DebriefStartTimeType, Employee, InterviewerEventContent } from 'src/store/slices/debrief';
import { MeetingRoomSuggestionInterface } from 'src/store/slices/schedule-communications';

import { TimeBlock } from 'src/types/preferenes';

import { State } from 'src/store';

export const getStepScheduleOptions = (
  state: State,
  applicationId: string
): {
  loading: boolean;
  submitting: boolean;
  error: string;
} => state.debrief.byId[applicationId]?.stepScheduleOptions;

export const getDebriefStartTime = (state: State, applicationId: string): string | null =>
  state.debrief.byId[applicationId]?.debriefStartTime;

export const getDebriefTimezone = (state: State, applicationId: string): string | undefined =>
  state.debrief.byId[applicationId]?.timezone;

export const getDebriefStartTimeType = (state: State, applicationId: string): DebriefStartTimeType | undefined =>
  state.debrief.byId[applicationId]?.debriefStartTimeType;

export const getDebriefTimePeriods = (state: State, applicationId: string): TimeBlock[] | undefined =>
  state.debrief.byId[applicationId]?.timePeriods;

export const getDebriefAttendees = (state: State, applicationId: string): DebriefStage[] =>
  state.debrief.byId[applicationId]?.debriefAttendees;

export const getDebriefHiringTeamAttendees = (state: State, applicationId: string): Employee[] | undefined =>
  state.debrief.byId[applicationId]?.debriefHiringTeamAttendees;

export const getSelectedAttendeeIds = (state: State, applicationId: string): string[] =>
  state.debrief.byId[applicationId]?.selectedAttendeeIds;

export const getFetchedAttendee = (state: State, applicationId: string): boolean =>
  state.debrief.byId[applicationId]?.fetchedAttendee;

export const getAdditionalAttendees = (state: State, applicationId: string): Employee[] =>
  state.debrief.byId[applicationId]?.additionalAttendees;

export const getDuration = (state: State, applicationId: string): number => state.debrief.byId[applicationId]?.duration;

export const getIsScheduleSelected = (state: State, applicationId: string): boolean =>
  state.debrief.byId[applicationId]?.isScheduleSelected;

export const getSelectedScheduleId = (state: State, applicationId: string): string | null =>
  state.debrief.byId[applicationId].selectedScheduleId;

export const getCandidateName = (state: State, applicationId: string): string =>
  state.debrief.byId[applicationId].candidateName;

export const getInterviewerCalendarId = (state: State, applicationId: string): string | null =>
  state.debrief.byId[applicationId]?.interviewerCalendarId;

export const getWorkspaceVideoLink = (state: State, applicationId: string): string | null => {
  const selectedScheduleId = getSelectedScheduleId(state, applicationId);
  return getVideoMeetingLink(state, selectedScheduleId || '') || null;
};

export const getIsPrivateCalendarEvent = (state: State, applicationId: string): boolean | null =>
  state.debrief.byId[applicationId]?.isPrivateCalendarEvent;

export const getInterviewerEventContent = (state: State, applicationId: string): InterviewerEventContent | null =>
  state.debrief.byId[applicationId]?.interviewerEventContent;

export const getInterviewerTemplateId = (state: State, applicationId: string): string | null =>
  state.debrief.byId[applicationId]?.interviewerTemplateID;

export const getStepScheduleContent = (
  state: State,
  applicationId: string
): {
  loading: boolean;
  isWaiting: boolean;
  isSubmitting: boolean;
  error: string;
} => state.debrief.byId[applicationId]?.stepScheduleContent;

export const getMeetingRoomSuggestions = (
  state: State,
  applicationId: string
): MeetingRoomSuggestionInterface[] | undefined => {
  return state.debrief.byId[applicationId]?.meetingRoomSuggestions;
};

export const getTaskConversationTemplateId = (state: State, applicationId: string): string | null | undefined => {
  return state.debrief.byId[applicationId]?.taskConversationTemplateId;
};
