import React from 'react';

import BaseIcon, { Props } from './Icon';

const CrossIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.53033 5.46967C6.23744 5.17678 5.76256 5.17678 5.46967 5.46967C5.17678 5.76256 5.17678 6.23744 5.46967 6.53033L8.93934 10L5.46967 13.4697C5.17678 13.7626 5.17678 14.2374 5.46967 14.5303C5.76256 14.8232 6.23744 14.8232 6.53033 14.5303L10 11.0607L13.4697 14.5303C13.7626 14.8232 14.2374 14.8232 14.5303 14.5303C14.8232 14.2374 14.8232 13.7626 14.5303 13.4697L11.0607 10L14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967C14.2374 5.17678 13.7626 5.17678 13.4697 5.46967L10 8.93934L6.53033 5.46967Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CrossIcon;
