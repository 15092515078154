import React, { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/core';
import { Box, SxProps } from '@mui/material';

import Alert from 'src/components/Alert';
import Link from 'src/components/Link';
import { WarningIcon } from 'src/components/icons';
import Label from 'src/components/label';

import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';

import { useEmployeeEmail } from 'src/hooks/useEmployeeId';

const useStyles = makeStyles(() => ({
  captionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    marginTop: '4px',
  },
}));

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface PersonalCalendarAlertProps {
  sx?: SxProps;
  remoteCalendarId?: string;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const PersonalCalendarAlert: FC<PersonalCalendarAlertProps> = ({ sx, remoteCalendarId }) => {
  const employeeEmail = useEmployeeEmail();
  const classes = useStyles();

  if (employeeEmail !== remoteCalendarId) {
    return null;
  }

  return (
    <Alert
      sx={sx}
      icon={<WarningIcon color="warning" />}
      status="warning"
      title="This looks like your personal calendar"
      caption={
        <Box className={classes.captionContainer}>
          <Label color="inherit" variant="captions">
            Others won&apos;t be able to update or reschedule interviews scheduled on this calendar without edit access.
          </Label>
          <Link
            className={classes.link}
            target="_blank"
            color="info"
            fontWeight={600}
            variant="captions"
            href={ZenDeskHelpCenterUrl.BEST_PRACTICES_OVERVIEW}
          >
            Learn more
          </Link>
        </Box>
      }
    />
  );
};

export default PersonalCalendarAlert;
