import React, { FC } from 'react';
import { Redirect } from 'react-router';

import useAuth from 'src/hooks/useAuth';
import useLoginToParams from 'src/hooks/useLoginToParams';

/**
 * This guard checks that only if the user isAuthenticated.
 * Note: Guards should check only one thing. Don't add any other checks to this Guard.
 */
const UserIsAuthenticatedGuard: FC = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const loginToParams = useLoginToParams();

  if (!isAuthenticated) {
    return <Redirect to={loginToParams} />;
  }

  // Don't add components here, please add components either on the app or on the appropriate Layout component
  return <>{children}</>;
};

export default UserIsAuthenticatedGuard;
