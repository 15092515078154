/* eslint-disable import/prefer-default-export */
import { updateJobStage } from 'src/store/actions/job-stage';
import { getJobStageById } from 'src/store/selectors/job-stage';
import { getJobStageInterviewGroupById } from 'src/store/selectors/job-stage-interview-group';
import jobStageInterviewGroupSlice, {
  JobStageInterviewGroup,
  UpdateJobStageInterviewGroupPayload,
} from 'src/store/slices/job-stage-interview-group';

import { AppThunk } from 'src/store';

export const addJobStageInterviewGroup =
  (jobStageInterviewGroup: JobStageInterviewGroup): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageInterviewGroupSlice.actions.addJobStageInterviewGroup(jobStageInterviewGroup));
  };

export const updateJobStageInterviewGroup =
  (jobStageInterviewGroup: UpdateJobStageInterviewGroupPayload): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageInterviewGroupSlice.actions.updateJobStageInterviewGroup(jobStageInterviewGroup));
  };

export const deleteJobStageInterviewGroup =
  (jobStageInterviewGroupId: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const group = getJobStageInterviewGroupById(state, jobStageInterviewGroupId);
    const jobStage = getJobStageById(state, group.jobStageId);
    dispatch(jobStageInterviewGroupSlice.actions.deleteJobStageInterviewGroup(jobStageInterviewGroupId));

    // Remove group from jobStage
    const jobStageInterviewGroupIds = jobStage.jobStageInterviewGroupIds.filter(
      (id) => id !== jobStageInterviewGroupId
    );
    dispatch(updateJobStage({ id: jobStage.id, jobStageInterviewGroupIds }));
  };

export const lockJobStageInterviewGroup =
  (jobStageInterviewGroupId: string, locked: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageInterviewGroupSlice.actions.lockJobStageInterviewGroup({ jobStageInterviewGroupId, locked }));
  };

export const moveJobStageInterviewInGroup =
  (
    jobStageInterviewGroupId: string,
    jobStageInterviewId: string,
    position: number
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(
      jobStageInterviewGroupSlice.actions.moveJobStageInterviewInGroup({
        jobStageInterviewGroupId,
        jobStageInterviewId,
        position,
      })
    );
  };
