import React from 'react';

import BaseIcon, { Props } from './Icon';

const OvernightIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 4C17.5523 4 18 3.55228 18 3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3C16 3.55228 16.4477 4 17 4ZM8.32711 4.68597C8.50284 4.92075 8.52562 5.23642 8.3854 5.494C7.9804 6.23797 7.75 7.09095 7.75 7.99998C7.75 10.8995 10.1005 13.25 13 13.25C13.3833 13.25 13.7562 13.209 14.1149 13.1315C14.4016 13.0696 14.698 13.1804 14.8737 13.4152C15.0495 13.6499 15.0723 13.9656 14.932 14.2232C13.7892 16.3226 11.5619 17.75 9 17.75C5.27208 17.75 2.25 14.7279 2.25 11C2.25 7.76272 4.52822 5.05927 7.56826 4.40233C7.85491 4.34038 8.15137 4.4512 8.32711 4.68597ZM6.43684 6.41693C4.8335 7.31566 3.75 9.03184 3.75 11C3.75 13.8995 6.10051 16.25 9 16.25C10.4338 16.25 11.7339 15.6754 12.6819 14.7426C9.10169 14.5765 6.25 11.6213 6.25 7.99998C6.25 7.45518 6.31468 6.925 6.43684 6.41693ZM12 6.75C11.5858 6.75 11.25 6.41421 11.25 6C11.25 5.58579 11.5858 5.25 12 5.25H15C15.3033 5.25 15.5768 5.43273 15.6929 5.71299C15.809 5.99324 15.7448 6.31583 15.5303 6.53033L13.8107 8.25H15C15.4142 8.25 15.75 8.58579 15.75 9C15.75 9.41421 15.4142 9.75 15 9.75H12C11.6967 9.75 11.4232 9.56727 11.3071 9.28701C11.191 9.00676 11.2552 8.68417 11.4697 8.46967L13.1893 6.75H12ZM5 3C5 3.55228 4.55228 4 4 4C3.44772 4 3 3.55228 3 3C3 2.44772 3.44772 2 4 2C4.55228 2 5 2.44772 5 3ZM11.5 3C11.7761 3 12 2.77614 12 2.5C12 2.22386 11.7761 2 11.5 2C11.2239 2 11 2.22386 11 2.5C11 2.77614 11.2239 3 11.5 3ZM16 17.5C16 17.7761 15.7761 18 15.5 18C15.2239 18 15 17.7761 15 17.5C15 17.2239 15.2239 17 15.5 17C15.7761 17 16 17.2239 16 17.5ZM17.5 15C17.7761 15 18 14.7761 18 14.5C18 14.2239 17.7761 14 17.5 14C17.2239 14 17 14.2239 17 14.5C17 14.7761 17.2239 15 17.5 15Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default OvernightIcon;
