import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { addMinutes, parseISO } from 'date-fns';

import { DateTimeRangeInput, UseWarningForStartAt_JobStageInterviewsFragment } from 'src/generated/mloop-graphql';

import { isOverlappingOnCalendar, sameOrAfter, sameOrBefore } from 'src/utils/dateUtils';

type Props = {
  jobStageInterviewId: string;
  candidateAvailabilities: DateTimeRangeInput[] | undefined;
};

type Fragments = {
  jobStageInterviews: UseWarningForStartAt_JobStageInterviewsFragment[];
};

const useWarningForStartAt: FunctionWithFragments<Fragments, Props, string | null> = (
  { jobStageInterviews },
  { candidateAvailabilities, jobStageInterviewId }
) => {
  const jobStageInterview = jobStageInterviews.find((jsi) => jsi.id === jobStageInterviewId);

  if (!jobStageInterview || !jobStageInterview.forcedStartAt || !candidateAvailabilities) {
    return null;
  }

  const jobStageInterviewStartAt = parseISO(jobStageInterview.forcedStartAt);
  const jobStageInterviewEndAt = addMinutes(jobStageInterviewStartAt, jobStageInterview.duration || 0);

  const isCandidateAvailable = candidateAvailabilities.reduce((result, candidateAvailability) => {
    if (result) return result;
    const candidateStartAt = parseISO(candidateAvailability.startAt);
    const candidateEndAt = parseISO(candidateAvailability.endAt);
    const isInterviewStartValid = sameOrAfter(jobStageInterviewStartAt, candidateStartAt);
    const isInterviewEndValid = sameOrBefore(jobStageInterviewEndAt, candidateEndAt);
    return isInterviewStartValid && isInterviewEndValid;
  }, false);

  if (!isCandidateAvailable) {
    return 'Start time is outside candidate availability';
  }

  const isInterviewOverlapping = jobStageInterviews
    .filter((otherJobStageInterview) => otherJobStageInterview.id !== jobStageInterview.id)
    .reduce((result, otherJobStageInterview) => {
      if (result || !otherJobStageInterview.forcedStartAt) return result;
      const otherStartAt = parseISO(otherJobStageInterview.forcedStartAt);
      const otherEndAt = addMinutes(
        parseISO(otherJobStageInterview.forcedStartAt),
        otherJobStageInterview.duration || 0
      );
      return isOverlappingOnCalendar(jobStageInterviewStartAt, jobStageInterviewEndAt, otherStartAt, otherEndAt);
    }, false);

  if (isInterviewOverlapping) {
    return 'Time overlaps with another interview';
  }

  return null;
};

useWarningForStartAt.fragments = {
  jobStageInterviews: gql`
    fragment useWarningForStartAt_jobStageInterviews on JobStageInterview {
      id
      duration
      forcedStartAt @client
    }
  `,
};

export default useWarningForStartAt;
