import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box, Grid, useMediaQuery } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import { BaseProps } from 'src/components/types';

import useTheme from 'src/theme/useTheme';

const PRIMARY_WIDTH = '520px';
const NAV_WIDTH = '240px';
const SECONDARY_COLUMN_WIDTH = '320px';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface TwoColumnLayoutProps extends BaseProps {
  leftComponent: JSX.Element;
  rightComponent?: JSX.Element | null;
  mobileBreakpoint?: Breakpoint;
  clearPadding?: boolean; //
  // eslint-disable-next-line react/no-unused-prop-types
  rightTop?: number; // Sticky header can be two sizes due to tabs, so pass in where the secondary top sticks
  isNav?: boolean; // If is nav, secondary (right) should be on the left side
}

const useStyle = makeStyles(() =>
  createStyles({
    primary: { minWidth: PRIMARY_WIDTH, flexGrow: 1 },
    secondary: {
      width: (props: TwoColumnLayoutProps) => (props.isNav ? NAV_WIDTH : SECONDARY_COLUMN_WIDTH),
      maxWidth: (props: TwoColumnLayoutProps) => (props.isNav ? NAV_WIDTH : SECONDARY_COLUMN_WIDTH),
      position: 'sticky',
      top: (props: TwoColumnLayoutProps) => props.rightTop || 8,
    },
    gap: {
      gap: '12px',
    },
  })
);

const TwoColumnLayout = (props: TwoColumnLayoutProps): JSX.Element => {
  const {
    dataTestId,
    leftComponent: primaryComponent,
    rightComponent: secondaryComponent,
    mobileBreakpoint,
    clearPadding = false,
    isNav = false,
  } = props;
  const classes = useStyle(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(mobileBreakpoint || 'sm')); // 960px
  const singleColumn = !isNav && (isMobile || !secondaryComponent);

  // If it's a nav, flip the grid and make "nav" 240px wide
  return (
    <Box
      data-testid={dataTestId}
      mt={clearPadding ? '0px' : '8px'}
      mx={clearPadding ? '0px' : '8px'}
      style={clearPadding ? { flexGrow: 1, height: '100%' } : {}}
    >
      <Grid
        className={clearPadding ? classes.gap : ''}
        container
        style={clearPadding ? { height: '100%' } : {}}
        direction={singleColumn ? 'column' : 'row'}
        spacing={clearPadding ? 0 : 2}
        justifyContent={singleColumn ? undefined : 'space-between'}
        wrap="nowrap"
      >
        {!isNav && (
          <>
            <Grid item className={singleColumn ? undefined : classes.primary}>
              {primaryComponent}
            </Grid>
            <Grid item style={singleColumn ? undefined : { flexShrink: 1 }}>
              <Box className={singleColumn ? undefined : classes.secondary}>{secondaryComponent}</Box>
            </Grid>
          </>
        )}
        {isNav && (
          <>
            <Grid item style={{ flexShrink: 1 }}>
              <Box className={classes.secondary}>{secondaryComponent}</Box>
            </Grid>
            <Grid item className={classes.primary}>
              {primaryComponent}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default TwoColumnLayout;
