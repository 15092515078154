/* eslint-disable react-refresh/only-export-components */
import { CircleWithCheckIcon, ErrorIcon, InfoOutlineIcon } from '@modernloop/shared/icons';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { CustomContentProps } from 'notistack';
import React from 'react';

type Props = CustomContentProps;

const Toast: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (props, ref) => {
  const theme = useTheme();
  const { variant, message } = props;

  const getIcon = () => {
    switch (variant) {
      case 'success':
        return <CircleWithCheckIcon color="success" />;
      case 'error':
        return <ErrorIcon color="error" />;
      case 'warning':
        return <ErrorIcon color="warning" />;
      case 'info':
        return <InfoOutlineIcon color="info" />;
      default:
        return null;
    }
  };

  const getHighlight = () => {
    switch (variant) {
      case 'error':
        return (
          <Box height="4px" left="0px" top="0px" bgcolor={theme.palette.error.main} position="absolute" width="100%" />
        );
      case 'success':
        return (
          <Box
            height="4px"
            left="0px"
            top="0px"
            bgcolor={theme.palette.success.main}
            position="absolute"
            width="100%"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      component="div"
      p="12px"
      maxWidth="fit-content"
      borderRadius="6px"
      ref={ref}
      bgcolor={theme.palette.background.default}
      // TODO: Fix this the next time the file is edited
      // eslint-disable-next-line no-restricted-syntax
      style={{
        boxShadow: theme.shadows[2],
        position: 'relative',
        overflow: 'hidden',
        outline: `1px solid ${theme.palette.borderState.default}}`,
        outlineOffset: '-1px',
      }}
    >
      {getHighlight()}
      <Stack direction="row" spacing={0.5} alignItems="center">
        {getIcon()}
        <Typography color="text.primary">{message}</Typography>
      </Stack>
    </Box>
  );
};

export default React.forwardRef<HTMLDivElement, Props>(Toast);
