import React, { useMemo } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import { addMilliseconds, differenceInMilliseconds, isAfter, isEqual } from 'date-fns';

import { useFlag } from 'src/hooks/feature-flag';

import { Theme } from 'src/theme/type';

import { differenceInCalendarDays, startOfDay } from 'src/utils/dateUtils';

import Stack from '../Stack';
import { TimeIcon } from '../icons';

import TimePicker from './TimePicker';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface TimePickerProps {
  /** Whether or not this is being rendered on mobile screen sizes */
  isMobile?: boolean;
  /**
   * Default time to initialize the date time picker.
   */
  value: { start: Date; end: Date };

  timezone: string;

  coalesceTimeUpdates?: boolean;

  dataTestId?: string;

  onChange: (start: Date, end: Date) => void;

  hideClockIcons?: boolean;

  disabled?: boolean;
}

export const useAutoCompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: ({ error }: { error?: boolean }) => ({
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${error ? theme.palette.error.light : theme.palette.border}`,
      borderRadius: '6px',
      padding: '2px 12px',
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '&:hover .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '&:hover .MuiInput-underline:after': {
        border: 'none',
      },
    }),
    endAdornment: {
      top: 'auto',
    },
    input: {
      fontSize: '15px',
      height: '13px',
      lineHeight: '22px',
    },
    popupIndicatorOpen: {
      transform: 'rotate(0deg)',
    },
    paper: {
      borderRadius: '6px',
    },
    listbox: {
      '& ul': {
        margin: 0,
      },
    },
    option: {
      minHeight: 'auto',
    },
    popper: {
      minWidth: 120,
    },
  })
);

const TimeRangePicker = ({
  isMobile,
  value: { start, end },
  timezone,
  coalesceTimeUpdates = true,
  dataTestId,
  onChange,
  hideClockIcons,
  disabled,
}: TimePickerProps): JSX.Element => {
  const isTimeRangeValid = !isAfter(start, end) && !isEqual(start, end);
  const autoCompleteClasses = useAutoCompleteStyles({ error: !isTimeRangeValid });
  const dontCoalesceEndTime = useFlag('user_time_range_picker_dont_coalesce_end_time');

  const handleStartAtChange = (dateTime: Date) => {
    if (!coalesceTimeUpdates && dontCoalesceEndTime) {
      onChange(dateTime, end);
      return;
    }

    const diffInMillis = differenceInMilliseconds(end, start);
    let endTime = addMilliseconds(dateTime, diffInMillis);

    // This ensures that we do not roll over to the next day.
    // Roll over can be confusing since we don't show date for end time.
    if (differenceInCalendarDays(endTime, dateTime, timezone) > 0) {
      endTime = startOfDay(endTime, timezone);
    }

    onChange(dateTime, endTime);
  };

  const handleEndAtChange = (dateTime: Date) => {
    onChange(start, dateTime);
  };

  const width = useMemo(() => {
    if (isMobile) {
      return '100%';
    }
    // with hidden clock icons
    if (hideClockIcons) {
      return '100px';
    }
    // normal desktop
    return '125px';
  }, [hideClockIcons, isMobile]);

  return (
    <Stack
      spacing={1}
      dataTestId={dataTestId}
      itemStyles={{ 0: { width }, 1: { width } }}
      alignItems="center"
      style={{ flexWrap: 'nowrap' }}
    >
      <TimePicker
        disabled={disabled}
        startAdornment={
          hideClockIcons ? undefined : (
            <Box style={{ marginLeft: '-4px' }}>
              <TimeIcon />
            </Box>
          )
        }
        utcTime={start}
        timezone={timezone}
        error={!isTimeRangeValid}
        onChange={handleStartAtChange}
        overrideAutoCompleteClasses={autoCompleteClasses}
      />
      <TimePicker
        disabled={disabled}
        startAdornment={
          hideClockIcons ? undefined : (
            <Box style={{ marginLeft: '-4px' }}>
              <TimeIcon />
            </Box>
          )
        }
        utcTime={end}
        timezone={timezone}
        error={isTimeRangeValid}
        onChange={handleEndAtChange}
        overrideAutoCompleteClasses={autoCompleteClasses}
        timeRangeUtcStartOfDayForEndTimeSelector={startOfDay(start, timezone)}
      />
    </Stack>
  );
};

export default TimeRangePicker;
