import { useMemo } from 'react';

import { gql } from '@apollo/client';
import { endOfDay, formatToTimeZone, startOfDay } from '@modernloop/shared/datetime';
import { getDay, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { cloneDeep } from 'lodash';

import { TimeBlockWeek, useAbsoluteEmployeeWorkHoursQuery } from 'src/generated/mloop-graphql';

import IsoTimestamp from 'src/types/IsoTimestamp';

import { EmployeeWorkHoursById } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AbsoluteEmployeeWorkHours = gql`
  query AbsoluteEmployeeWorkHours($ids: [uuid!]!, $startAt: datetime!, $endAt: datetime!) {
    employeeByIds(ids: $ids) {
      id
      fullName # Fetching the interviewer name for ease of debugging.
      absoluteWorkHours(input: { range: { startAt: $startAt, endAt: $endAt } }) {
        startAt
        endAt
      }
    }
  }
`;

const EMPTY_WORK_HOURS: TimeBlockWeek = {
  sunday: [],
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
};

const DayNumberToStringMap = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

const useEmployeeWorkHoursByIdForDay = (
  employeeIds: string[],
  timezone: string,
  startAt: IsoTimestamp // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
EmployeeWorkHoursById => {
  const startAtDate = startOfDay(startAt, timezone);
  const endAtDate = endOfDay(startAt, timezone);

  const { data, loading, error } = useAbsoluteEmployeeWorkHoursQuery({
    variables: { ids: employeeIds, startAt: startAtDate, endAt: endAtDate },
  });

  const result = useMemo(() => {
    if (loading || error || !data || !data.employeeByIds) {
      return employeeIds.map((id) => ({ [id]: cloneDeep(EMPTY_WORK_HOURS) })).reduce((a, b) => ({ ...a, ...b }), {});
    }

    const employeeWorkHours: EmployeeWorkHoursById = {};

    data.employeeByIds.forEach((employee) => {
      const workHours = cloneDeep(EMPTY_WORK_HOURS);
      employee.absoluteWorkHours.forEach((block) => {
        const start = formatToTimeZone(block.startAt, 'HH:mm', timezone);

        let end = formatToTimeZone(block.endAt, 'HH:mm', timezone);

        // Need this hack to format end of day as `24:00` because FullCalendar adds an extra space at the bottom which causes
        // the time at the left (eg. 11pm) to be misaligned with rest of the table.
        end = end === '00:00' || end === '23:59' ? '24:00' : end;

        const day = getDay(utcToZonedTime(parseISO(startAtDate), timezone));
        const weekDay = DayNumberToStringMap[day];

        workHours[weekDay].push({ start, end });
      });
      employeeWorkHours[employee.id] = workHours;
    });

    return employeeWorkHours;
  }, [data, employeeIds, error, loading, startAtDate, timezone]);

  return result;
};

export default useEmployeeWorkHoursByIdForDay;
