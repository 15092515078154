import React from 'react';

import BaseIcon, { Props } from './Icon';

const AvailabilityIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.25C7.41421 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48121 17.75 6L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25 6C2.25 4.48122 3.48121 3.25 5 3.25H6.25V2C6.25 1.58579 6.58578 1.25 7 1.25ZM6.25 4.75H5C4.30964 4.75 3.75 5.30965 3.75 6L3.75001 15C3.75001 15.6904 4.30966 16.25 5.00001 16.25H15C15.6904 16.25 16.25 15.6904 16.25 15L16.25 6C16.25 5.30964 15.6903 4.75 15 4.75H13.75V5C13.75 5.41421 13.4142 5.75 13 5.75C12.5858 5.75 12.25 5.41421 12.25 5V4.75H7.75V5C7.75 5.41421 7.41421 5.75 7 5.75C6.58578 5.75 6.25 5.41421 6.25 5V4.75ZM8.89919 8.37554C9.11645 7.99996 9.52068 7.75 9.98242 7.75C10.6728 7.75 11.2324 8.30964 11.2324 9C11.2324 9.69036 10.6728 10.25 9.98242 10.25C9.5682 10.25 9.23242 10.5858 9.23242 11C9.23242 11.4142 9.5682 11.75 9.98242 11.75C11.5012 11.75 12.7324 10.5188 12.7324 9C12.7324 7.48122 11.5012 6.25 9.98242 6.25C8.96358 6.25 8.07513 6.80444 7.60078 7.62446C7.39337 7.983 7.51589 8.4418 7.87444 8.6492C8.23298 8.85661 8.69178 8.73409 8.89919 8.37554ZM9.98243 14.5C10.5347 14.5 10.9824 14.0523 10.9824 13.5C10.9824 12.9477 10.5347 12.5 9.98243 12.5C9.43015 12.5 8.98243 12.9477 8.98243 13.5C8.98243 14.0523 9.43015 14.5 9.98243 14.5Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default AvailabilityIcon;
