import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { formatDate, getLocalTimezone } from '@modernloop/shared/datetime';
import { AddTraineeIcon, EditIcon, ReverseShadowIcon, ShadowIcon } from '@modernloop/shared/icons';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Stack, Typography } from '@mui/material';

import { TrainingPlanSection_ModuleMemberFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import {
  getReverseShadowCompletedCount,
  getReverseShadowPendingCount,
  getShadowCompletedCount,
  getShadowPendingCount,
  isModuleMemberTrained,
} from 'src/entities/InterviewModuleMember/utils';

import EditTrainigPlanModal from '../Modals/EditTrainingPlanModal';
import SetModuleMemberAsTrainee from '../Modals/SetModuleMemberAsTrainee';
import PendingApprovalDisableWrapper from '../PendingApprovalDisableWrapper';

type Fragments = {
  moduleMember: TrainingPlanSection_ModuleMemberFragment;
};

const TrainingPlanSection: FCWithFragments<Fragments> = ({ moduleMember }) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showAddTraineeModal, setShowAddTraineeModal] = useState<boolean>(false);

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const handleAddTrainee = () => {
    setShowAddTraineeModal(true);
  };

  const getSummary = (): string => {
    if (isModuleMemberTrained(moduleMember.status) && moduleMember?.trainedAt) {
      return `Completed on ${formatDate(moduleMember.trainedAt, getLocalTimezone(), "MMM d',' yyyy")}`;
    }
    const shadowPending = getShadowPendingCount({ moduleMember }, undefined);
    const reverseShadowPending = getReverseShadowPendingCount({ moduleMember }, undefined);
    if (shadowPending && reverseShadowPending) {
      return `${shadowPending} shadow & ${reverseShadowPending} reverse shadow pending`;
    }
    if (reverseShadowPending) {
      return `${reverseShadowPending} reverse shadow pending`;
    }
    if (shadowPending) {
      return `${shadowPending} shadow pending`;
    }
    return '';
  };

  return (
    <>
      <Accordion
        variant="outlined"
        disableGutters
        onChange={(_, isExpanded) => setExpanded(isExpanded)}
        expanded={expanded}
      >
        <AccordionSummary>
          <Stack>
            <Typography variant="subtitle2">Training plan</Typography>
            {!expanded && (
              <Typography variant="body2" color="text.secondary">
                {getSummary()}
              </Typography>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column" spacing={2}>
            {isModuleMemberTrained(moduleMember.status) && moduleMember.trainedAt && (
              <Alert variant="standard" color="success" severity="success">
                Marked as trained on {formatDate(moduleMember.trainedAt, getLocalTimezone(), "MMM d',' yyyy")}
              </Alert>
            )}
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <ShadowIcon /> <Typography variant="body2"> Shadows</Typography>
              </Stack>
              <Typography variant="body2">
                {isModuleMemberTrained(moduleMember.status)
                  ? `${getShadowCompletedCount({ moduleMember }, undefined)}`
                  : `${getShadowCompletedCount({ moduleMember }, undefined)} / ${moduleMember.shadowsRequired}`}{' '}
                completed
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <ReverseShadowIcon /> <Typography variant="body2"> Reverse shadows</Typography>
              </Stack>
              <Typography variant="body2">
                {isModuleMemberTrained(moduleMember.status)
                  ? `${getReverseShadowCompletedCount({ moduleMember }, undefined)}`
                  : `${getReverseShadowCompletedCount({ moduleMember }, undefined)} / ${
                      moduleMember.reverseShadowsRequired
                    }`}{' '}
                completed
              </Typography>
            </Stack>

            <Box display="flex" justifyContent="end">
              {isModuleMemberTrained(moduleMember.status) ? (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<AddTraineeIcon />}
                  onClick={handleAddTrainee}
                >
                  Set back to trainee
                </Button>
              ) : (
                <PendingApprovalDisableWrapper
                  currentTrainingSessionStatus={moduleMember.stats?.currentTrainingSessionStatus || undefined}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                </PendingApprovalDisableWrapper>
              )}
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
      {showAddTraineeModal && (
        <SetModuleMemberAsTrainee
          open={showAddTraineeModal}
          moduleMember={moduleMember}
          onClose={() => setShowAddTraineeModal(false)}
          onUpdate={() => setShowAddTraineeModal(false)}
        />
      )}
      {showEditModal && (
        <EditTrainigPlanModal
          moduleMember={moduleMember}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          onUpdate={() => setShowEditModal(false)}
        />
      )}
    </>
  );
};

TrainingPlanSection.fragments = {
  moduleMember: gql`
    ${getReverseShadowCompletedCount.fragments.moduleMember}
    ${getReverseShadowPendingCount.fragments.moduleMember}
    ${getShadowCompletedCount.fragments.moduleMember}
    ${getShadowPendingCount.fragments.moduleMember}
    ${SetModuleMemberAsTrainee.fragments.moduleMember}
    ${EditTrainigPlanModal.fragments.moduleMember}
    fragment TrainingPlanSection_moduleMember on InterviewModuleMember {
      employeeId
      interviewModuleId
      status
      shadowsRequired
      reverseShadowsRequired
      trainedAt
      stats {
        currentTrainingSessionStatus
      }
      ...getReverseShadowCompletedCount_moduleMember
      ...getReverseShadowPendingCount_moduleMember
      ...getShadowCompletedCount_moduleMember
      ...getShadowPendingCount_moduleMember
      ...SetModuleMemberAsTrainee_moduleMember
      ...EditTrainigPlan_moduleMember
    }
  `,
};

export default TrainingPlanSection;
