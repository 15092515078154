import { getTZAbbr } from '@modernloop/shared/datetime';
import { intervalToDuration } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const formatTz = (date: Date, timeZone: string, formatString: string): string =>
  format(utcToZonedTime(date, timeZone), formatString, { timeZone });

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const renderTimeRangeWithDayMthHrMinTz = (startAt, endAt, tz: string): string =>
  `${formatTz(startAt, tz, 'EEEE')}, ${formatTz(startAt, tz, 'MMM')} ${formatTz(startAt, tz, 'd')}, ${formatTz(
    startAt,
    tz,
    'h:mm a'
  )} - ${formatTz(endAt, tz, 'h:mm a')} ${getTZAbbr(tz)}`;

export const renderInterviewerHours = (
  startAt,
  endAt,
  tz: string,
  showTzString = true,
  showDuration = false // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
string => {
  let durationString = '';

  if (showDuration) {
    const duration = intervalToDuration({ start: new Date(startAt), end: new Date(endAt) });

    const hours = duration?.hours ?? 0;
    const minutes = duration?.minutes ?? 0;

    const hoursString = `${hours} h`;
    const minutesString = `${minutes} min`;

    if (hours > 0 && minutes > 0) {
      durationString = `(${hoursString} ${minutesString})`;
    } else if (hours > 0) {
      durationString = `(${hoursString})`;
    } else if (minutes > 0) {
      durationString = `(${minutesString})`;
    }
  }

  return `${formatTz(startAt, tz, 'h:mm a')} - ${formatTz(endAt, tz, 'h:mm a')} ${showTzString ? getTZAbbr(tz) : ''} ${
    showDuration ? durationString : ''
  }`;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const renderTimeRangeWithDateDayMthHrMinTz = (startAt, endAt, tz: string): string =>
  `${formatTz(startAt, tz, 'EEEE')}, ${formatTz(startAt, tz, 'MMM')} ${formatTz(startAt, tz, 'd')}, ${formatTz(
    startAt,
    tz,
    'h:mm a'
  )} - ${formatTz(endAt, tz, 'EEEE')}, ${formatTz(endAt, tz, 'MMM')} ${formatTz(endAt, tz, 'd')}, ${formatTz(
    endAt,
    tz,
    'h:mm a'
  )} ${getTZAbbr(tz)}`;

export const renderTimeRange = (
  tz: string, // timezone is so important I want it first
  startAt: Date,
  endAt: Date // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
string =>
  `${formatTz(startAt, tz, 'h:mm a')} - ${formatTz(endAt, tz, 'h:mm a')} ${getTZAbbr(tz)}, ${formatTz(
    endAt,
    tz,
    'EEE'
  )}`;

export const renderTimeRangeUnix = (
  tz: string, // timezone is so important I want it first
  startAt: number,
  endAt: number // TODO: Fix this the next time the file is edited.
): // TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
string =>
  // eslint-disable-next-line max-params
  renderTimeRange(tz, new Date(startAt), new Date(endAt));

export const renderTimeRangeISO = (
  tz: string, // timezone is so important I want it first
  startAt: string,
  endAt: string // TODO: Fix this the next time the file is edited.
): // TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
string =>
  // eslint-disable-next-line max-params
  renderTimeRange(tz, new Date(startAt), new Date(endAt));
