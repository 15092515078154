import React from 'react';

import BaseIcon, { Props } from './Icon';

const HiredIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 2C15.75 1.58579 15.4142 1.25 15 1.25C14.5858 1.25 14.25 1.58579 14.25 2L14.25 3.25H13C12.5858 3.25 12.25 3.58579 12.25 4C12.25 4.41421 12.5858 4.75 13 4.75H14.25L14.25 6C14.25 6.41421 14.5858 6.75 15 6.75C15.4142 6.75 15.75 6.41421 15.75 6L15.75 4.75H17C17.4142 4.75 17.75 4.41421 17.75 4C17.75 3.58579 17.4142 3.25 17 3.25H15.75L15.75 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2.75L11 2.75C11.4142 2.75 11.75 2.41421 11.75 2C11.75 1.58579 11.4142 1.25 11 1.25L5 1.25C3.48122 1.25 2.25 2.48122 2.25 4.00001L2.25 5.25H1.5C1.08579 5.25 0.75 5.58579 0.75 6C0.75 6.41422 1.08579 6.75 1.5 6.75H2.25001L2.25001 9.25001H1.5C1.08579 9.25001 0.75 9.5858 0.75 10C0.75 10.4142 1.08579 10.75 1.5 10.75H2.25001L2.25002 13.25H1.5C1.08579 13.25 0.75 13.5858 0.75 14C0.75 14.4142 1.08579 14.75 1.5 14.75H2.25002L2.25002 16C2.25002 17.5188 3.48124 18.75 5.00002 18.75L15 18.75C16.5188 18.75 17.75 17.5188 17.75 16V8C17.75 7.58579 17.4142 7.25 17 7.25C16.5858 7.25 16.25 7.58579 16.25 8V16C16.25 16.6904 15.6904 17.25 15 17.25L5.00002 17.25C4.30967 17.25 3.75002 16.6904 3.75002 16L3.75002 14.75H4.5C4.91421 14.75 5.25 14.4142 5.25 14C5.25 13.5858 4.91421 13.25 4.5 13.25H3.75002L3.75001 10.75H4.5C4.91421 10.75 5.25 10.4142 5.25 10C5.25 9.5858 4.91421 9.25001 4.5 9.25001H3.75001L3.75001 6.75H4.5C4.91421 6.75 5.25 6.41422 5.25 6C5.25 5.58579 4.91421 5.25 4.5 5.25H3.75L3.75 4.00001C3.75 3.30965 4.30965 2.75 5 2.75ZM10 5.50001C8.89543 5.50001 8 6.39544 8 7.50001C8 8.60458 8.89543 9.50001 10 9.50001C11.1046 9.50001 12 8.60458 12 7.50001C12 6.39544 11.1046 5.50001 10 5.50001ZM6.66014 13.0165C7.60085 10.3278 12.3991 10.3278 13.3399 13.0165C13.5223 13.5378 13.0523 14 12.5 14L7.5 14C6.94771 14 6.47775 13.5378 6.66014 13.0165Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default HiredIcon;
