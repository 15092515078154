import React, { useEffect, useMemo, useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Tab as MuiTab, SxProps } from '@mui/material';
import clsx from 'clsx';

import IconButton from 'src/components/IconButton';
import { CaretLeftIcon, CaretRightIcon } from 'src/components/icons';
import Label from 'src/components/label';
import { BaseProps, createSxProps } from 'src/components/types';

import { Theme as ThemeOld } from 'src/theme/type';

import { Theme } from 'src/themeMui5/type';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface TabProps<T extends string | number> {
  id: T;
  label: string | JSX.Element;
  icon?: JSX.Element;
  disabled?: boolean;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface TabsProps<T extends string | number> extends BaseProps {
  activeTabId: T;
  hideSeparator?: boolean;
  tabs: TabProps<T>[];
  onChange?: (option: TabProps<T>) => void;
}

const useStyles = makeStyles((theme: ThemeOld) =>
  createStyles({
    scrollIndicator: {
      flexShrink: 0,
      color: theme.grey.solid.max,
      margin: '0 4px',
    },

    scrollIndicatorDisable: {
      color: theme.palette.common.transparent,
      backgroundColor: `${theme.palette.common.transparent} !important`,
    },
  })
);

const useTabSxProps = () => {
  const sxProps = useMemo(() => {
    return {
      root: {
        flexGrow: 1,
        textTransform: 'none',
        borderRadius: '0',
        minHeight: '28px',
        minWidth: 'auto',
        maxWidth: 'auto',
        opacity: 1,
        borderRight: (theme: Theme) => `2px solid ${theme.palette.divider}`,
        padding: (theme: Theme) => theme.spacing(0.5, 1.5),
        flexDirection: 'row',
        '& svg': {
          marginRight: '8px',
        },
        '& > :first-child': {
          // Material UI is appending `margin-bottom: 6px` for `.MuiTab-labelIcon .MuiTab-wrapper > *:first-child`
          // and there is no better way override it.
          marginBottom: '0 !important',
          margin: '0px',
        },
      } as SxProps,
      selected: {
        borderRadius: '6px',
        backgroundColor: (theme: Theme) => theme.palette.background.info,
        border: (theme: Theme) => `1px solid ${theme.palette.info.main}`,
      } as SxProps,
    };
  }, []);

  return createSxProps(sxProps);
};

const useSxProps = () => {
  const sxProps = useMemo(() => {
    return {
      root: {
        display: 'flex',
        flexWrap: 'nowrap',
        maxWidth: '100%',
        border: (theme: Theme) => `1px solid ${theme.palette.border}`,
        backgroundColor: (theme: Theme) => theme.palette.background.default,
        borderRadius: '6px',
      },

      scroller: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        width: '100%',

        // Hide dimensionless scrollbar
        scrollbarWidth: 'none', // Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // Safari + Chrome
        },
      },

      hideRightBorder: {
        borderRight: `2px solid transparent`,
      },
    };
  }, []);

  return createSxProps(sxProps);
};

const SCROLL_FRACTION = 0.33;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
export const Tab = <T extends string | number>({
  tab,
  selected,
  hideRightBorder,
  onClick,
}: {
  tab: TabProps<T>;
  selected: boolean;
  hideRightBorder: boolean;
  onClick?: (tab: TabProps<T>) => void;
}): JSX.Element => {
  const sxProps = useSxProps();
  const tabSxProps = useTabSxProps();
  const handleClick = onClick
    ? (event: React.MouseEvent<HTMLElement>) => {
        const target = event.nativeEvent.target as HTMLElement;
        target.scrollIntoView({ behavior: 'smooth' });
        onClick(tab);
      }
    : undefined;

  return (
    <MuiTab
      key={tab.id}
      value={tab.id}
      label={
        typeof tab.label === 'string' ? (
          <Label variant="captions" fontWeight={500} color={selected ? 'info' : undefined}>
            {tab.label}
          </Label>
        ) : (
          tab.label
        )
      }
      disabled={tab.disabled}
      icon={selected ? tab.icon : undefined}
      sx={
        {
          ...tabSxProps.root,
          ...(selected ? tabSxProps.selected : {}),
          ...(hideRightBorder ? sxProps.hideRightBorder : {}),
        } as SxProps
      }
      onClick={handleClick}
    />
  );
};

/**
 * @deprecated - Use `Tabs` from `@mui/material` instead
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const Tabs = <T extends string | number>({
  dataTestId,
  activeTabId,
  hideSeparator,
  tabs,
  onChange,
}: TabsProps<T>): JSX.Element => {
  const classes = useStyles();
  const sxProps = useSxProps();
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const [disableLeftScrollIndicator, setDisableLeftScrollIndicator] = useState(true);
  const [disableRightScrollIndicator, setDisableRightScrollIndicator] = useState(false);

  const dragScrolling = useRef(false);
  const initialClientX = useRef(0);
  const initialScrollLeft = useRef(0);

  const scrollerRef = useRef<HTMLDivElement | null>(null);

  const selectedTabIndex = tabs.findIndex((tab) => tab.id === activeTabId);

  useEffect(() => {
    setTimeout(() => {
      if (!scrollerRef.current || selectedTabIndex === 0) return;

      // Using `selectedTabIndex - 1` to scroll to because for some reason
      // scrolling to the exact item takes it out of view port slightly
      const tab = scrollerRef.current.children[selectedTabIndex - 1];
      if (!tab) return;
      tab.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }, 0);
    // Disabling because we want to run it only on mount,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  const handleChange = (tab: TabProps<T>) => {
    if (!onChange) return;
    // If user is dragging the tabs then we need to skip the tab change
    // Without this the tab change fires and it is very annoying.
    if (dragScrolling.current) {
      dragScrolling.current = false;
      return;
    }

    onChange(tab);
  };

  const handleRef = (ref: HTMLDivElement | null) => {
    if (!ref) return;
    scrollerRef.current = ref;
    setShowScrollIndicator(ref.clientWidth < ref.scrollWidth);
  };

  const handleScroll = () => {
    if (!scrollerRef.current) return;

    const { clientWidth, scrollLeft, scrollWidth } = scrollerRef.current;

    if (scrollLeft === 0) {
      setDisableLeftScrollIndicator(true);
    } else {
      setDisableLeftScrollIndicator(false);
    }

    // use 1 for the potential rounding error with browser zooms.
    if (scrollLeft < scrollWidth - clientWidth - 1) {
      setDisableRightScrollIndicator(false);
    } else {
      setDisableRightScrollIndicator(true);
    }
  };

  const handleLeftScrollIndicatorClick = () => {
    if (!scrollerRef.current) return;
    scrollerRef.current.scrollBy({ behavior: 'smooth', left: -scrollerRef.current.clientWidth * SCROLL_FRACTION });
  };

  const handleRightScrollIndicatorClick = () => {
    if (!scrollerRef.current) return;
    scrollerRef.current.scrollBy({ behavior: 'smooth', left: scrollerRef.current.clientWidth * SCROLL_FRACTION });
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    let target = event.nativeEvent.target as HTMLElement;

    if (target === scrollerRef.current) {
      target = scrollerRef.current.children[selectedTabIndex] as HTMLElement;
    }

    switch (event.key) {
      case 'ArrowLeft': {
        if (target.previousSibling) {
          (target.previousSibling as HTMLElement).focus();
        }
        break;
      }
      case 'ArrowRight': {
        if (target.nextSibling) {
          (target.nextSibling as HTMLElement).focus();
        }
        break;
      }
      default:
        break;
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!scrollerRef.current) return;

    if (initialClientX.current - event.clientX > 20) {
      dragScrolling.current = true;
    }
    scrollerRef.current.scrollLeft = initialScrollLeft.current + initialClientX.current - event.clientX;
  };

  const handleMouseUp = () => {
    window.removeEventListener('mouseup', handleMouseUp);
    window.removeEventListener('mousemove', handleMouseMove);
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    if (!scrollerRef.current) return;

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);

    initialScrollLeft.current = scrollerRef.current.scrollLeft;
    initialClientX.current = event.clientX;
  };

  return (
    <Box sx={sxProps.root} data-testid={dataTestId}>
      {showScrollIndicator && (
        <IconButton
          className={clsx(classes.scrollIndicator, { [classes.scrollIndicatorDisable]: disableLeftScrollIndicator })}
          disabled={disableLeftScrollIndicator}
          onClick={handleLeftScrollIndicatorClick}
        >
          <CaretLeftIcon />
        </IconButton>
      )}
      <Box
        role="tablist"
        sx={sxProps.scroller}
        tabIndex={-1}
        ref={handleRef}
        onScroll={handleScroll}
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
      >
        {tabs.map((tab, index) => {
          const selected = activeTabId === tab.id;
          return (
            <Tab
              key={tab.id}
              tab={tab}
              selected={selected}
              hideRightBorder={index === tabs.length - 1 || index === selectedTabIndex - 1 || Boolean(hideSeparator)}
              onClick={handleChange}
            />
          );
        })}
      </Box>
      {showScrollIndicator && (
        <IconButton
          className={clsx(classes.scrollIndicator, { [classes.scrollIndicatorDisable]: disableRightScrollIndicator })}
          disabled={disableRightScrollIndicator}
          onClick={handleRightScrollIndicatorClick}
        >
          <CaretRightIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default Tabs;
