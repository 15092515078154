import React from 'react';

import BaseIcon, { Props } from './Icon';

const HelpIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.75C6.54822 3.75 3.75 6.54822 3.75 10C3.75 13.4518 6.54822 16.25 10 16.25C13.4518 16.25 16.25 13.4518 16.25 10C16.25 6.54822 13.4518 3.75 10 3.75ZM2.25 10C2.25 5.71979 5.71979 2.25 10 2.25C14.2802 2.25 17.75 5.71979 17.75 10C17.75 14.2802 14.2802 17.75 10 17.75C5.71979 17.75 2.25 14.2802 2.25 10ZM11 14C11 14.5523 10.5523 15 10 15C9.44772 15 9 14.5523 9 14C9 13.4477 9.44772 13 10 13C10.5523 13 11 13.4477 11 14ZM8.48366 7.62554C8.78737 7.10051 9.3532 6.75 10 6.75C10.9665 6.75 11.75 7.5335 11.75 8.5C11.75 9.4665 10.9665 10.25 10 10.25C9.58579 10.25 9.25 10.5858 9.25 11C9.25 11.4142 9.58579 11.75 10 11.75C11.7949 11.75 13.25 10.2949 13.25 8.5C13.25 6.70507 11.7949 5.25 10 5.25C8.7961 5.25 7.74606 5.90499 7.18525 6.87446C6.97784 7.233 7.10037 7.6918 7.45891 7.8992C7.81746 8.10661 8.27625 7.98409 8.48366 7.62554Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default HelpIcon;
