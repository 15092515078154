import React, { Suspense } from 'react';

const OmniSearchLazy = React.lazy(() => import('./OmniSearch'));

const OmniSearch = () => {
  return (
    <Suspense fallback={null}>
      <OmniSearchLazy />
    </Suspense>
  );
};

export default OmniSearch;
