import React, { useMemo } from 'react';

import { getLocalTimezone, getTZAbbr } from '@modernloop/shared/datetime';
import { Box, Stack } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { isEmpty } from 'lodash';

import Paper from 'src/components/Paper';
import Button from 'src/components/button';
import { AvailabilityIcon, EditIcon, WarningIcon } from 'src/components/icons';
import Label from 'src/components/label';

import useCompanyHolidays from 'src/hooks/useCompanyHolidays';

import { CandidateAvailability as StoreCandidateAvailability } from 'src/store/slices/candidate-availability';
import { CandidateAvailabilityOptions } from 'src/store/slices/candidate-availability-options';

import { assertIsoTimestamp } from 'src/types/IsoTimestamp';

import { formatToTimeZone } from 'src/utils/datetime/Format';
import { getTimezoneDisplayName } from 'src/utils/datetime/Timezone';

import {
  getErrorText,
  getWarningText,
  hasWarningOrError,
} from 'src/views/booking/BookingCreateView/CandidateAvailabilityStep/candidateAvailabilityUtils';

import { CandidateAvailabilityByDate, getCandidateAvailabilityByDate } from './utils';

type Props = {
  candidateTimezone?: string;
  availabilities?: StoreCandidateAvailability;
  options?: CandidateAvailabilityOptions;
  readonly?: boolean;
  showEditIcon?: boolean;
  onAddAvailability: () => void;
};

const CandidateAvailability = ({
  candidateTimezone,
  availabilities,
  options,
  readonly,
  showEditIcon = true,
  onAddAvailability,
}: Props) => {
  const holidays = useCompanyHolidays();

  const timezone = candidateTimezone || options?.timezone || getLocalTimezone();

  const availabilityByDate = useMemo((): CandidateAvailabilityByDate => {
    if (!availabilities) return {};

    return getCandidateAvailabilityByDate(
      availabilities.rcEnteredAvailability,
      availabilities.candidateEnteredAvailability,
      timezone
    );
  }, [availabilities, timezone]);

  if (options && options.lastCandidateCommunicationSentAt && isEmpty(availabilityByDate)) {
    return (
      <Box marginLeft={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box display="flex" alignItems="center">
            <AvailabilityIcon />
          </Box>
          <Label color="max-contrast-grey">Requested</Label>
        </Stack>
      </Box>
    );
  }

  const availabilityPresent = Boolean(
    availabilities?.candidateEnteredAvailability.length || availabilities?.rcEnteredAvailability.length
  );

  return (
    <Paper disablePadding disableBorder>
      <Stack spacing={0.25}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
          <Stack>
            {Object.keys(availabilityByDate).map((date) => {
              return (
                <Stack key={date} direction="row">
                  <Box paddingBottom={1} paddingTop={1} width="236px">
                    <Label>{format(parseISO(date), 'MMMM d, yyyy')}</Label>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    {availabilityByDate[date].map((availability) => {
                      const start = utcToZonedTime(availability.start, timezone);
                      const end = utcToZonedTime(availability.end, timezone);

                      const warningOrError = hasWarningOrError(
                        { id: '', day: availability.start, startAt: availability.start, endAt: availability.end },
                        timezone,
                        holidays
                      );

                      const errorText = warningOrError
                        ? getErrorText({
                            id: '',
                            day: availability.start,
                            startAt: availability.start,
                            endAt: availability.end,
                          })
                        : '';

                      const warningText = warningOrError
                        ? getWarningText(
                            { id: '', day: availability.start, startAt: availability.start, endAt: availability.end },
                            timezone,
                            holidays
                          )
                        : '';

                      const isSpanningMeridiem = start.getHours() < 12 && end.getHours() >= 12;
                      const startHour = format(start, isSpanningMeridiem ? 'h:mm aaa' : 'h:mm');
                      const endHour = format(end, 'h:mm aaa');

                      return (
                        <Box
                          key={`${startHour} - ${endHour}`}
                          display="flex"
                          alignItems="center"
                          paddingBottom={1}
                          paddingTop={1}
                        >
                          <Label color="high-contrast-grey">{`${startHour} - ${endHour} ${getTZAbbr(timezone)}`}</Label>
                          {(errorText || warningText) && (
                            <WarningIcon color={errorText ? 'error' : 'warning'} tooltip={errorText || warningText} />
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                </Stack>
              );
            })}
          </Stack>
          {!readonly && availabilityPresent && showEditIcon && (
            <Button
              variant="outlined"
              label="Edit"
              style={{ flexShrink: 0 }}
              startIcon={<EditIcon color={readonly ? 'min-contrast-grey' : undefined} />}
              onClick={onAddAvailability}
            />
          )}
        </Stack>
        {availabilityPresent && availabilities?.updatedAt && (
          <Label color="high-contrast-grey" variant="captions">
            Last updated{' '}
            {formatToTimeZone(assertIsoTimestamp(availabilities.updatedAt), 'MMMM d, yyyy h:mm aaa', timezone)}
          </Label>
        )}
        {availabilityPresent && (
          <Label color="high-contrast-grey" variant="captions">
            Shown in candidate timezone: {getTimezoneDisplayName(candidateTimezone || 'UTC')}
          </Label>
        )}
      </Stack>
    </Paper>
  );
};

export default CandidateAvailability;
