import React, { useCallback } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { Paper, Stack, Typography } from '@mui/material';

import {
  InterviewMeetingLocationType,
  LocationCard_InterviewMeetingLocationSettingFragment,
} from 'src/generated/mloop-graphql';

import InterviewMeetingLocationMenu from 'src/entities/InterviewMeetingLocationModal/InterviewMeetingLocationMenu';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { updateLocation } from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { getLocation } from 'src/store/selectors/schedule-communications';
import { getInterviewerEventContents } from 'src/store/selectors/scheduling';
import { MeetingLinksConfig } from 'src/store/slices/schedule-communications';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import { useDispatch, useSelector } from 'src/store';

import CustomLinksSection from './CustomLinks/CustomLinksSection';
import LocationConfigMenu from './LocationConfigMenu';
import VideoMeetingLinks from './VideoMeetingLinks';
import ZoomMeetingSection from './Zoom';
import LocationCardOld from './indexOld';
import usePopulateInterviewMeetingLocation from './usePopulateInterviewMeetingLocation';

type Fragments = {
  interviewMeetingLocationSetting: LocationCard_InterviewMeetingLocationSettingFragment | undefined;
};

type Props = {
  applicationId?: string;
  customVideoMeetingURL?: string | null;
  handleZoomMeetingConfigChange?: (config: MeetingLinksConfig) => void;
  handleCustomMeetingLinkConfigChange?: (config: MeetingLinksConfig) => void;
  onMeetingLinksConfigChange?: (meetingLinksConfig: MeetingLinksConfig) => void;
  scheduleFlow: ScheduleFlowType;
  meetingLinkConfig?: MeetingLinksConfig;
  selectedScheduleId: string;
  candidatePreferredPhoneNumber: string | undefined;
};

const LocationCard: FCWithFragments<Fragments, Props> = (props) => {
  const {
    applicationId,
    customVideoMeetingURL,
    meetingLinkConfig,
    selectedScheduleId,
    scheduleFlow,
    interviewMeetingLocationSetting,
    candidatePreferredPhoneNumber,
    handleCustomMeetingLinkConfigChange,
    handleZoomMeetingConfigChange,
    onMeetingLinksConfigChange,
  } = props;

  const dispatch = useDispatch();
  const schedule = useScheduleWithoutBreaks(selectedScheduleId);
  const location = useSelector((state) => getLocation(state, selectedScheduleId));
  const interviewerEventContents = useSelector(getInterviewerEventContents);

  const scheduleLocationEnabled = useFlag('org_schedule_location');

  usePopulateInterviewMeetingLocation(
    { interviewMeetingLocationSetting },
    { scheduleId: selectedScheduleId, candidatePreferredPhoneNumber }
  );

  const setLocation = useCallback(
    (eventLocation: InterviewMeetingLocationType) => {
      if (!eventLocation) return;

      dispatch(updateLocation(selectedScheduleId, eventLocation));

      if (scheduleFlow === ScheduleFlowType.UPDATE) {
        if (eventLocation !== InterviewMeetingLocationType.Zoom) {
          if (schedule && schedule.events) {
            schedule.events.map((interview) => {
              return dispatch(
                addInterviewScheduleUpdate({
                  type: 'ScheduleUpdateVideoUrl',
                  updateType: ScheduleUpdateType.NEW,
                  scheduleId: schedule.id,
                  applicationStageInterviewId: interview.id,
                })
              );
            });
          }
        }
      }
    },
    [scheduleFlow, dispatch, selectedScheduleId, schedule]
  );

  const handleLocationConfigChange = (value: MeetingLinksConfig) => {
    if (location === InterviewMeetingLocationType.Zoom && handleZoomMeetingConfigChange) {
      handleZoomMeetingConfigChange(value);
    } else if (
      [
        InterviewMeetingLocationType.CustomLink,
        InterviewMeetingLocationType.CustomPhone,
        InterviewMeetingLocationType.CustomText,
      ].includes(location) &&
      handleCustomMeetingLinkConfigChange
    ) {
      handleCustomMeetingLinkConfigChange(value);
    } else if (
      (location === InterviewMeetingLocationType.GoogleMeet ||
        location === InterviewMeetingLocationType.MicrosoftTeams) &&
      onMeetingLinksConfigChange
    ) {
      onMeetingLinksConfigChange(value);
    }
  };

  if (!scheduleLocationEnabled) {
    return <LocationCardOld {...props} />;
  }

  const showLocationConfigMenu =
    schedule &&
    schedule.events.length > 1 &&
    location !== InterviewMeetingLocationType.NotSpecified &&
    location !== InterviewMeetingLocationType.CandidatePhone;

  let customLinkTitle = 'Custom link';
  if (location === InterviewMeetingLocationType.CandidatePhone) customLinkTitle = 'Candidate phone number';
  else if (location === InterviewMeetingLocationType.CustomPhone) customLinkTitle = 'Custom phone number';
  else if (location === InterviewMeetingLocationType.CustomText) customLinkTitle = 'Custom text';

  return (
    <Paper variant="outlined" sx={{ backgroundColor: (theme) => theme.palette.background.alternate, p: 2.5 }}>
      <Stack spacing={2}>
        <Typography variant="h5">Schedule location</Typography>

        <Stack direction="row" flexWrap="wrap" spacing={2}>
          <Stack flexGrow={1} maxWidth="50%" spacing={0.5}>
            <Typography variant="subtitle2">Location</Typography>
            <InterviewMeetingLocationMenu
              disabled={false}
              interviewMeetingLocationType={location}
              onChange={setLocation}
            />
          </Stack>
          {showLocationConfigMenu && (
            <Stack flexGrow={1} maxWidth="50%" spacing={0.5}>
              <Typography variant="subtitle2">&nbsp;</Typography>
              <LocationConfigMenu
                meetingLinkConfig={meetingLinkConfig || MeetingLinksConfig.One}
                onChange={handleLocationConfigChange}
              />
            </Stack>
          )}
        </Stack>

        {location === InterviewMeetingLocationType.Zoom ? (
          <ZoomMeetingSection
            scheduleFlow={scheduleFlow}
            applicationId={applicationId}
            zoomMeetingConfig={meetingLinkConfig}
            showUniqueLinksCheckbox={Boolean(interviewerEventContents && interviewerEventContents?.length > 1)}
            handleZoomMeetingConfigChange={handleZoomMeetingConfigChange}
          />
        ) : null}
        {(location === InterviewMeetingLocationType.GoogleMeet ||
          location === InterviewMeetingLocationType.MicrosoftTeams) &&
          selectedScheduleId && (
            <VideoMeetingLinks
              scheduleId={selectedScheduleId}
              location={location}
              meetingLinksConfig={meetingLinkConfig}
              onMeetingLinksConfigChange={onMeetingLinksConfigChange}
            />
          )}
        {location === InterviewMeetingLocationType.CustomLink ||
        location === InterviewMeetingLocationType.CandidatePhone ||
        location === InterviewMeetingLocationType.CustomPhone ||
        location === InterviewMeetingLocationType.CustomText ? (
          <CustomLinksSection
            title={customLinkTitle}
            interviewerEventContents={interviewerEventContents}
            location={location}
            customMeetingLinkConfig={meetingLinkConfig}
            scheduleFlow={scheduleFlow}
            customVideoMeetingURL={customVideoMeetingURL}
            handleCustomMeetingLinkConfigChange={handleCustomMeetingLinkConfigChange}
            schedule={schedule}
          />
        ) : null}
      </Stack>
    </Paper>
  );
};

LocationCard.fragments = {
  interviewMeetingLocationSetting: gql`
    ${usePopulateInterviewMeetingLocation.fragments.interviewMeetingLocationSetting}
    fragment LocationCard_interviewMeetingLocationSetting on InterviewMeetingLocationSettingPref {
      ...usePopulateInterviewMeetingLocation_interviewMeetingLocationSetting
    }
  `,
};

export default LocationCard;
