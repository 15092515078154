import React from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import Button from 'src/components/button';
import Label from 'src/components/label';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface ConfirmDialogProps {
  title: string;
  children: React.ReactNode;
  open: boolean;
  setOpen: (boolean) => void;
  onConfirm: () => void;
  destructive?: boolean;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const { title, children, open, setOpen, onConfirm, destructive } = props;
  return (
    <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
      <DialogTitle style={{ paddingBottom: 8 }}>
        <Label variant="body" fontWeight={600}>
          {title}
        </Label>
      </DialogTitle>
      <DialogContent>
        <Label variant="body">{children}</Label>
      </DialogContent>
      <DialogActions>
        <Button label="No" size="small" variant="contained" onClick={() => setOpen(false)} />
        <Button
          color={destructive ? 'error' : 'primary'}
          label="Yes"
          size="small"
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
