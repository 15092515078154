/**
 * Typography constants
 * FIGMA → https://www.figma.com/file/Yq6Wgf9c3VvcNAAu25KYAZ/Styles?node-id=54%3A42&viewport=-2100%2C289%2C1.5428613424301147
 */

/**
 * Font weights
 */
export const FONT_WEIGHT_NORMAL = '400';
export const FONT_WEIGHT_MEDIUM = '500';
export const FONT_WEIGHT_SEMI_BOLD = '600';

/**
 * Title
 */
export const TITLE_FAMILY = 'Inter';
export const TITLE_STYLE = 'normal';
export const TITLE_WEIGHT = '600';
export const TITLE_SIZE = '30px';
export const TITLE_LINE_HEIGHT = '36px';

/**
 * Sub title
 */
export const SUB_TITLE_FAMILY = 'Inter';
export const SUB_TITLE_STYLE = 'normal';
export const SUB_TITLE_WEIGHT = '600';
export const SUB_TITLE_SIZE = '22px';
export const SUB_TITLE_LINE_HEIGHT = '28px';

/**
 * Body
 */
export const BODY_FAMILY = 'Inter';
export const BODY_STYLE = 'normal';
export const BODY_WEIGHT = 'normal';
export const BODY_SIZE = '15px';
export const BODY_LINE_HEIGHT = '22px';

/**
 * Caption
 */
export const CAPTION_FAMILY = 'Inter';
export const CAPTION_STYLE = 'normal';
export const CAPTION_WEIGHT = 'normal';
export const CAPTION_SIZE = '13px';
export const CAPTION_LINE_HEIGHT = '18px';

export const NORMAL_BUTTON_WEIGHT = 400;
/**
 * Standard button
 */
export const STD_BUTTON_FAMILY = 'Inter';
export const STD_BUTTON_STYLE = 'normal';
export const STD_BUTTON_WEIGHT = 500;
export const STD_BUTTON_SIZE = '15px';
export const STD_BUTTON_LINE_HEIGHT = '22px';
export const STD_BUTTON_HEIGHT = '32px';
export const STD_BUTTON_BORDER_RADIUS = '16px';

/**
 * Small button
 */
export const SMALL_BUTTON_FAMILY = 'Inter';
export const SMALL_BUTTON_STYLE = 'normal';
export const SMALL_BUTTON_WEIGHT = '500';
export const SMALL_BUTTON_SIZE = '13px';
export const SMALL_BUTTON_LINE_HEIGHT = '18px';
export const SMALL_BUTTON_HEIGHT = '28px';
export const SMALL_BUTTON_BORDER_RADIUS = '14px';
