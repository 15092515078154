import { useState } from 'react';

import { chromeExtConfig } from '../config';
import { isChrome } from '../utils/browserUtils';

export type ChromeExtension = {
  hasExtension: boolean;
  extensionBannerText: string;
  extensionBannerUrlText: string;
};

export default function useHasChromeExtension(): ChromeExtension {
  const extensionBannerText = 'Add ModernLoop to your ATS to quickly jump from candidates to scheduling.';
  const extensionBannerUrlText = 'Enable the Chrome extension';
  const [hasExtension, setHasExtension] = useState(true);
  const [hasExtensionError, setHasExtensionError] = useState(false);
  if (isChrome() && !hasExtensionError) {
    const extensionId = chromeExtConfig.id;
    const requiredVersion = chromeExtConfig.version;
    try {
      chrome.runtime.sendMessage(extensionId, { message: 'version' }, function responseCallback(reply) {
        if (reply && reply.version && reply.version !== requiredVersion) {
          console.log('reply.version', reply.version);
        }
      });
    } catch (error) {
      // handle error
      console.log('error', error);
      if (error.toString().indexOf('Invalid extension id') === -1) {
        setHasExtensionError(true);
        setHasExtension(false);
      } else if (error.toString().indexOf("Cannot read properties of undefined (reading 'sendMessage')") === -1) {
        console.log("Cannot read properties of undefined (reading 'sendMessage')");
      } else {
        console.log('invalid chrome extension id', extensionId);
      }
    }
  }
  return {
    hasExtension,
    extensionBannerText,
    extensionBannerUrlText,
  };
}
