import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import BaseIcon, { Props } from '../../Icon';

const InterviewerRsvpDecline = ({ dataTestId, color, fontSize, tooltip, tiny }: Props): JSX.Element => {
  return (
    <BaseIcon dataTestId={dataTestId} color={color} fontSize={fontSize} tooltip={tooltip} tiny={tiny}>
      <path
        d="M13.5 3V9C13.5 10.3807 12.3807 11.5 11 11.5H4.90833C4.41476 11.5 3.93225 11.6461 3.52158 11.9199L2.0547 12.8978C1.39015 13.3408 0.5 12.8644 0.5 12.0657V3C0.5 1.61929 1.61929 0.5 3 0.5H11C12.3807 0.5 13.5 1.61929 13.5 3Z"
        fill="white"
        stroke="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.77735 12.4818C1.44507 12.7033 1 12.4651 1 12.0657V3C1 1.89543 1.89543 1 3 1H11C12.1046 1 13 1.89543 13 3V9C13 10.1046 12.1046 11 11 11H4.90833C4.31605 11 3.73703 11.1753 3.24423 11.5038L1.77735 12.4818ZM4.46967 3.46967C4.17678 3.76256 4.17678 4.23744 4.46967 4.53033L5.93934 6L4.46967 7.46967C4.17678 7.76256 4.17678 8.23744 4.46967 8.53033C4.76256 8.82322 5.23744 8.82322 5.53033 8.53033L7 7.06066L8.46967 8.53033C8.76256 8.82322 9.23744 8.82322 9.53033 8.53033C9.82322 8.23744 9.82322 7.76256 9.53033 7.46967L8.06066 6L9.53033 4.53033C9.82322 4.23744 9.82322 3.76256 9.53033 3.46967C9.23744 3.17678 8.76256 3.17678 8.46967 3.46967L7 4.93934L5.53033 3.46967C5.23744 3.17678 4.76256 3.17678 4.46967 3.46967Z"
        fill="#D7241D"
      />
    </BaseIcon>
  );
};

export default InterviewerRsvpDecline;
