// Used to copy content to clipboard with rich text formatting
// So that when pasted in rich text editor, it maintains the formatting
const copyRichTextToClipboard = (html: string) => {
  const element = document.createElement('div');
  // replacing all occurrences of <br> and <br /> with \n to maintain line breaks
  element.innerHTML = html.replaceAll(/<br\s*[/]?>/gi, '\n');
  const plainBlobInput = new Blob([element.innerText || element.textContent || ''], { type: 'text/plain' });

  const richBlobInput = new Blob([html], { type: 'text/html' });

  const clipboardItemInput = new ClipboardItem({
    [richBlobInput.type]: Promise.resolve(richBlobInput),
    // Without passing plainBlobInput as well, copy does not work.
    [plainBlobInput.type]: Promise.resolve(plainBlobInput),
  });

  return navigator.clipboard.write([clipboardItemInput]);
};

export default copyRichTextToClipboard;
