import React from 'react';

import BaseIcon, { Props } from './Icon';

const MailIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43117 2.25002C5.22654 2.25002 4.25 3.22656 4.25 4.43118V5.56242L2.64685 6.46854C1.7837 6.9564 1.25 7.87112 1.25 8.86259V10.6304V15C1.25 16.5188 2.48122 17.75 4 17.75H16C17.5188 17.75 18.75 16.5188 18.75 15V10.6304V8.86259V8.57086C18.75 7.75965 18.3133 7.01125 17.6071 6.61209C17.566 6.58882 17.5245 6.567 17.4828 6.54662C17.4405 6.51951 17.3973 6.49347 17.3532 6.46854L15.75 5.56241L15.75 4.43118C15.75 3.22655 14.7734 2.25002 13.5688 2.25002H6.43117ZM14.25 6.01255L14.25 11.3961L10.4852 12.9818C10.1749 13.1125 9.82506 13.1125 9.51479 12.9818L5.75 11.3961V6.01347C5.75018 6.00405 5.75018 5.99462 5.75 5.98519V4.43118C5.75 4.05499 6.05497 3.75002 6.43117 3.75002L13.5688 3.75002C13.945 3.75002 14.25 4.05499 14.25 4.43118L14.25 5.9861C14.2498 5.99493 14.2498 6.00375 14.25 6.01255ZM4.25 7.28544L3.38493 7.77439C2.99259 7.99614 2.75 8.41192 2.75 8.86259V10.1325L4.25 10.7643V7.28544ZM17.25 10.1325L15.75 10.7643V8.57086C15.75 8.04076 16.2774 7.69221 16.7518 7.86393C17.063 8.09812 17.25 8.46691 17.25 8.86259V10.1325ZM17.25 11.7602L15.2911 12.5852L11.0675 14.3642C10.3849 14.6517 9.61514 14.6517 8.93254 14.3642L2.75 11.7602V15C2.75 15.6904 3.30964 16.25 4 16.25H16C16.6904 16.25 17.25 15.6904 17.25 15V11.7602ZM7.25 6.50002C7.25 6.0858 7.58578 5.75002 8 5.75002H12C12.4142 5.75002 12.75 6.0858 12.75 6.50002C12.75 6.91423 12.4142 7.25002 12 7.25002H8C7.58578 7.25002 7.25 6.91423 7.25 6.50002ZM8 8.75002C7.58578 8.75002 7.25 9.0858 7.25 9.50002C7.25 9.91423 7.58578 10.25 8 10.25H12C12.4142 10.25 12.75 9.91423 12.75 9.50002C12.75 9.0858 12.4142 8.75002 12 8.75002H8Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MailIcon;
