import { formatDuration, minutesToHours } from 'date-fns';

export const getHoursAndMinutesString = (minutes: number) => {
  if (minutes <= 0) return '';

  const hours = minutesToHours(minutes);
  const minutesLeft = minutes % 60;

  return formatDuration({ hours, minutes: minutesLeft });
};
