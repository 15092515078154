/**
 * String based feature flags
 *
 * https://docs.launchdarkly.com/home/getting-started/feature-flags
 */

export const STRING_FEATURE_FLAGS = [
  // Show Modernloop maintenance screen to all users
  'user_show_maintenance_screen',

  // Show Modernloop Candidate portal maintenance screen to all users
  'cp_show_maintenance_screen',
] as const;

export type FeatureFlagString = typeof STRING_FEATURE_FLAGS[number];
