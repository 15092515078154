import React, { FC } from 'react';

import { Dialog, DialogProps } from '@modernloop/shared/components';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type DeleteNoteModalProps = Pick<DialogProps, 'open' | 'onClose'> & {
  onSubmit: () => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const DeleteNoteModal: FC<DeleteNoteModalProps> = ({ onSubmit, onClose, open }) => {
  return (
    <ConditionalThemeProvider>
      <Dialog
        open={open}
        onClose={onClose}
        title="Delete comment?"
        subTitle="This cannot be undone"
        cancelOptions={{ onClick: () => onClose?.(), label: 'Cancel' }}
        submitOptions={{
          onClick: () => {
            onSubmit();
          },
          isDangerous: true,
          label: 'Delete',
        }}
      >
        <></>
      </Dialog>
    </ConditionalThemeProvider>
  );
};

export default DeleteNoteModal;
