import { CircleWithCheckIcon, InfoOutlineIcon, ErrorIcon, WarningIcon, CrossIcon } from '@modernloop/shared/icons';
import { darken, lighten, ThemeOptions } from '@mui/material';

/**
 * View Alert props and classes at https://mui.com/material-ui/api/alert/#css
 */
export const alert: ThemeOptions['components'] = {
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        error: <ErrorIcon />,
        info: <InfoOutlineIcon />,
        success: <CircleWithCheckIcon />,
        warning: <WarningIcon />,
      },
      components: {
        CloseIcon: CrossIcon,
      },
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1.5),
        gap: theme.spacing(1),
        alignItems: 'center',
      }),
      standard: ({ theme, ownerState }) => {
        // Remove this once we migrate all alerts to the new version
        // `pending` Alerts no longer exists in our themed world
        if (ownerState?.severity === 'pending') {
          return {};
        }
        const defaultProps = {
          backgroundColor: theme.palette[ownerState?.severity || 'success'].tint,
        };

        if (ownerState.severity === 'info') {
          return {
            ...defaultProps,
            color: theme.palette.info.contrastText,
          };
        }

        return defaultProps;
      },
      message: {
        padding: 0,
        minHeight: '22px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },

      action: {
        maxHeight: '22px',
        padding: 0,
        alignItems: 'center',
        marginRight: 0,
      },

      icon: ({ theme, ownerState }) => {
        const getColor = theme.palette.mode === 'light' ? darken : lighten;
        const defaultOptions = {
          padding: 0,
          marginRight: 0,
          marginTop: '1px',
          alignSelf: 'start',
        };
        if (ownerState?.severity === 'pending') {
          return defaultOptions;
        }
        if (ownerState?.severity === 'info') {
          return {
            ...defaultOptions,
            color: `${theme.palette.info.contrastText} !important`,
          };
        }
        return {
          ...defaultOptions,
          color: `${getColor(theme.palette[ownerState.severity ?? 'success'].light, 0.6)} !important`,
        };
      },
    },
  },
  MuiAlertTitle: {
    defaultProps: {},
    styleOverrides: {
      root: {
        margin: 0,
        fontWeight: 600,
      },
    },
  },
};
