import { ThemeOptions } from '@mui/material';

/**
 * View Icon props and classes at https://mui.com/material-ui/api/icon/#css
 */
export const icon: ThemeOptions['components'] = {
  MuiIcon: {
    defaultProps: {
      fontSize: 'medium',
    },
    styleOverrides: {},
  },
  MuiSvgIcon: {
    defaultProps: {
      fontSize: 'medium',
    },
    styleOverrides: {
      fontSizeSmall: {
        height: '16px',
        width: '16px',
      },
      fontSizeMedium: {
        height: '20px',
        width: '20px',
      },
    },
  },
};
