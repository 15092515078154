import React, { FC } from 'react';

import { Box, Button, Stack, Switch, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { getJSONWebToken } from 'src/contexts/auth0/utils';

import useAuth from 'src/hooks/useAuth';

import { MLoopBackendUrlNamespace } from 'src/utils/api/types';

const localBackendUrl = `http://localhost:8080`;

const BrowserTab: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useAuth();
  const handleCopyBearerToken = async () => {
    const token = await getJSONWebToken();
    navigator.clipboard.writeText(`Bearer ${token}`);
    enqueueSnackbar('Bearer token copied to clipboard', { variant: 'success' });
  };

  const setLocalStorageBackendUrl = (backendUrl: string | undefined) => {
    if (backendUrl === undefined) {
      localStorage.removeItem(MLoopBackendUrlNamespace);
    } else {
      localStorage.setItem(MLoopBackendUrlNamespace, backendUrl);
    }
  };

  const getLocalStorageBackendUrl = () => {
    return localStorage.getItem(MLoopBackendUrlNamespace);
  };

  const useLocalBackend = getLocalStorageBackendUrl() === localBackendUrl;

  const orgName = userInfo?.orgName ?? '';
  const orgId = userInfo?.orgId ?? '';
  const employeeId = userInfo?.employeeId ?? '';
  const employeeName = userInfo?.employeeName ?? '';
  const userId = userInfo?.userId ?? '';
  const userName = userInfo?.userName ?? '';
  const handleCopyOrgId = async () => {
    navigator.clipboard.writeText(orgId);
    enqueueSnackbar('Org ID copied to clipboard', { variant: 'success' });
  };
  const handleCopyEmployeeId = async () => {
    navigator.clipboard.writeText(employeeId);
    enqueueSnackbar('Employee ID copied to clipboard', { variant: 'success' });
  };
  const handleCopyUserId = async () => {
    navigator.clipboard.writeText(userId);
    enqueueSnackbar('User ID copied to clipboard', { variant: 'success' });
  };

  return (
    <Stack useFlexGap spacing={1}>
      <Button onClick={handleCopyBearerToken}>Copy Bearer Token</Button>

      <Box mt={3}>
        <Stack spacing={1}>
          <Button onClick={handleCopyOrgId}>Copy JWT Org ID for {orgName}</Button>
          <Button onClick={handleCopyEmployeeId}>Copy JWT Employee ID for {employeeName}</Button>
          <Button onClick={handleCopyUserId}>Copy JWT User ID for {userName}</Button>
        </Stack>
      </Box>

      <Box mt={3}>
        <Stack spacing={1}>
          <Button
            color="error"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            Clear Local Storage
          </Button>
          <Button
            color="error"
            onClick={() => {
              sessionStorage.clear();
              window.location.reload();
            }}
          >
            Clear Session Storage
          </Button>
        </Stack>
      </Box>

      <Stack useFlexGap spacing={1} direction="row">
        <Typography>Use local backend?</Typography>
        <Switch
          checked={useLocalBackend}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setLocalStorageBackendUrl(event.target.checked ? localBackendUrl : undefined);
            window.location.reload();
          }}
        />
      </Stack>
    </Stack>
  );
};

export default BrowserTab;
