import React, { FC, useState } from 'react';

import { gql } from '@apollo/client';
import _, { noop } from 'lodash';

import { SelfScheduleLocation, useTaskShareDialogQuery } from 'src/generated/mloop-graphql';

import ChildPropsDialog from 'src/components/Dialog/ChildPropsDialog';
import ShareDialog, { CreateType } from 'src/components/Dialogs/ShareDialog';

import { useCreateLocation } from 'src/hooks/amplitude/useCreateLocationHeader';
import { useDefaultCcEmployeeIds } from 'src/hooks/useDefaultCCEmployeeIds';
import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';

import { useCandidatePortalUrl } from 'src/urls/hooks/useCandidatePortalUrl';

import CandidateAvailabilityEmailPreview from 'src/views-new/CandidateAvailabilityOptions/ShareOptions';
import SelfScheduleEmail from 'src/views-new/SelfSchedule/SelfScheduleEmail';
import useSelfScheduleOptionsUpsert from 'src/views-new/SelfSchedule/useSelfScheduleOptionsUpsert';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface TaskShareDialogProps {
  taskId: string;
  type: CreateType;
  onClose: () => void;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const TaskShareDialog: FC<TaskShareDialogProps> = ({ taskId, type, onClose }) => {
  const { data, loading } = useTaskShareDialogQuery({
    variables: {
      taskId,
    },
  });

  const { candidatePortalUrl } = useCandidatePortalUrl(data?.task?.application?.id, { taskId });

  const { logEvent } = useLogEvent();
  const createLocation = useCreateLocation();

  const [isAvailabilitySendModalOpen, setIsAvailabilitySendModalOpen] = useState(false);
  const [isSelfScheduleModalOpen, setIsSelfScheduleModalOpen] = useState(false);

  const candidate = data?.task?.application.candidate;

  const defaultCcEmployeeIds = useDefaultCcEmployeeIds(candidate?.coordinatorId, candidate?.recruiterId);

  const availabilityRequest = _.first(data?.task?.availabilityRequests);
  const selfScheduleRequest = _.first(data?.task?.selfScheduleRequests);

  // Handle self schedule options upsert
  const selfScheduleOptionsUpsert = useSelfScheduleOptionsUpsert(
    {
      taskId,
      candidateEmailTemplateId: selfScheduleRequest?.candidateEmailTemplateId,
      candidateEventTemplateId: selfScheduleRequest?.candidateEventTemplateId,
      interviewerEventTemplateId: selfScheduleRequest?.interviewerEventTemplateId,
      candidateEmailContent: {
        toEmailAddress: '',
        description: '',
        summary: '',
      },
      options: {
        candidateCalendarId: selfScheduleRequest?.options?.candidateCalendarId || '',
        interviewerCalendarId: selfScheduleRequest?.options?.interviewerCalendarId || '',
        location: selfScheduleRequest?.options?.location || SelfScheduleLocation.None,
        inclusionDays: selfScheduleRequest?.options?.inclusionDays,
        advanceNoticeInHours: selfScheduleRequest?.options?.advanceNoticeInHours,
        rollingDays: selfScheduleRequest?.options?.rollingDays,
        zoomHost: selfScheduleRequest?.options?.zoomHost,
        zoomHostUserId: selfScheduleRequest?.options?.zoomHostUserId,
      },
    },
    taskId
  );

  const longUrl = candidatePortalUrl;

  if (loading) {
    return null;
  }

  if (!data?.task) {
    return null;
  }

  const handleAvailabilityClose = () => {
    setIsAvailabilitySendModalOpen(false);
    onClose();
  };

  const handleSelfScheduleClose = () => {
    setIsSelfScheduleModalOpen(false);
    onClose();
  };

  return (
    <>
      {(!isAvailabilitySendModalOpen || !isSelfScheduleModalOpen) && (
        <ShareDialog
          longUrl={longUrl}
          content={`This link is unique to this task for ${candidate?.fullName}`}
          onClose={onClose}
          onSendEmail={() => {
            if (type === CreateType.CANDIDATE_AVAILABILITY) {
              setIsAvailabilitySendModalOpen(true);
            } else {
              setIsSelfScheduleModalOpen(true);
            }
            logEvent(LoggerEvent.CLIENT_SHARE_DIALOG_SEND_EMAIL, {
              type,
              location: createLocation,
            });
          }}
        />
      )}
      {availabilityRequest && isAvailabilitySendModalOpen && (
        <ChildPropsDialog isOpen size="md" onClose={onClose}>
          <CandidateAvailabilityEmailPreview
            taskId={taskId}
            options={{
              id: availabilityRequest.id,
              applicationId: availabilityRequest.applicationId,
              jobStageId: availabilityRequest.jobStageId,
              taskId,
              numberOfDays: availabilityRequest.numberOfDays,
              minutesPerDays: availabilityRequest.minutesPerDays,
              minimumTimeBlockMinutes: availabilityRequest.minimumTimeBlockMinutes,
              timezone: availabilityRequest.timezone,
              availabilityStartDate: availabilityRequest.availabilityStartDate,
              availabilityEndDate: availabilityRequest.availabilityEndDate,
              advanceNoticeMinutes: availabilityRequest.advanceNoticeMinutes,
              candidateNote: availabilityRequest.candidateNote || undefined,
              externalId: availabilityRequest.externalId,
              createdAt: availabilityRequest.createdAt,
              updatedAt: availabilityRequest.updatedAt,
              lastCandidateCommunicationSentAt: availabilityRequest.lastCandidateCommunicationSentAt,
            }}
            onSaveSuccess={handleAvailabilityClose}
            onClose={handleAvailabilityClose}
            applicationId={availabilityRequest.applicationId}
            candidateId={candidate?.id}
            jobStageId={availabilityRequest.jobStageId}
            onOptionsChanged={noop}
            defaultCcEmployeeIds={defaultCcEmployeeIds}
          />
        </ChildPropsDialog>
      )}
      {selfScheduleRequest && isSelfScheduleModalOpen && (
        <ChildPropsDialog isOpen size="md" onClose={onClose}>
          <SelfScheduleEmail
            taskId={taskId}
            applicationId={selfScheduleRequest.applicationId}
            candidateId={candidate?.id || ''}
            jobStageId={selfScheduleRequest.jobStageId}
            selfScheduleOptionsUpsert={selfScheduleOptionsUpsert}
            onSave={handleSelfScheduleClose}
            onCancel={handleSelfScheduleClose}
            defaultCcEmployeeIds={defaultCcEmployeeIds}
          />
        </ChildPropsDialog>
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TASK_SHARE_DIALOG_QUERY = gql`
  query TaskShareDialog($taskId: uuid!) {
    task(id: $taskId) {
      id
      application {
        id
        jobId
        candidate {
          id
          fullName
          coordinatorId
          recruiterId
        }
      }
      availabilityRequests {
        id
        applicationId
        availabilityEndDate
        availabilityStartDate
        candidateNote
        externalId
        createdAt
        jobStageId
        minimumTimeBlockMinutes
        minutesPerDays
        numberOfDays
        timezone
        updatedAt
        lastCandidateCommunicationSentAt
        advanceNoticeMinutes
      }
      selfScheduleRequests {
        id
        applicationId
        createdAt
        creatorEmployeeId
        deletedAt
        jobStageId
        interviewerEventTemplateId
        candidateEventTemplateId
        candidateEmailTemplateId
        lastUpdatedByEmployeeId
        options {
          inclusionDays
          rollingDays
          advanceNoticeInHours
          candidateCalendarId
          interviewerCalendarId
          location
          zoomHost
          zoomHostUserId
          candidateEmail
        }
        rescheduledAt
        selfScheduleEmailTemplateId
        selfScheduleEmailSentAt
        updatedAt
      }
    }
  }
`;

export default TaskShareDialog;
