import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskCalendarChanges_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import useDirectoryInfo from 'src/hooks/useDirectoryInfo';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';

interface Fragments {
  activityLog: ActivityLogTaskCalendarChanges_ActivityLogFragment;
}

const ActivityLogTaskCalendarChanges: FCWithFragments<Fragments> = ({ activityLog }) => {
  const directoryInfo = useDirectoryInfo();

  return (
    <ActivityLogBase activityLog={activityLog} actionLabel={`synced changes from ${directoryInfo.calendarName}`} />
  );
};

ActivityLogTaskCalendarChanges.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskCalendarChanges_activityLog on ActivityLog {
      id

      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskCalendarChanges;
