import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Dialog } from '@modernloop/shared/components';
import { getExternalErrorMessage, logDatadogError } from '@modernloop/shared/utils';
import { useSnackbar } from 'notistack';

import { useActivityFeedRefetch } from 'src/hooks/useActivityFeedRefetch';
import useDirectoryInfo from 'src/hooks/useDirectoryInfo';
import useRefetchScheduleTaskQuery from 'src/hooks/useRefetchScheduleTaskQuery';

import cancelDebrief from 'src/utils/api/cancelDebrief';
import { apolloCacheDeleteById } from 'src/utils/apolloHelpers';

type Props = {
  interviewId: string;
  onClose: () => void;
};

const DebriefContentCancelDebriefDialog = ({ interviewId, onClose }: Props) => {
  const directoryInfo = useDirectoryInfo();

  const [canceling, setCanceling] = useState(false);

  const client = useApolloClient();

  const { enqueueSnackbar } = useSnackbar();
  const refetchActivityFeed = useActivityFeedRefetch();
  const refetchScheduleTaskQuery = useRefetchScheduleTaskQuery();

  const handleCancelEvent = async () => {
    setCanceling(true);
    // eslint-disable-next-line promise/catch-or-return
    cancelDebrief({ applicationDebriefId: interviewId })
      .then(() => {
        refetchScheduleTaskQuery();
        refetchActivityFeed();
        onClose();
        apolloCacheDeleteById(client.cache, interviewId, 'ApplicationDebrief');
        return undefined; // Returning to prevent eslint error
      })
      .catch((error) => {
        logDatadogError(error);
        enqueueSnackbar(getExternalErrorMessage(error), { variant: 'error' });
      })
      .finally(() => setCanceling(false));
  };

  return (
    <Dialog
      open
      title="Mark as canceled?"
      subTitle="This cannot be undone."
      onClose={onClose}
      submitOptions={{
        label: 'Cancel event',
        onClick: handleCancelEvent,
        isDangerous: true,
        isDisabled: canceling,
        isLoading: canceling,
      }}
      cancelOptions={{ label: 'Keep event', onClick: onClose, isDisabled: canceling }}
    >
      All records of this debrief will be deleted. The debrief event in {directoryInfo.label} Calendar will also be
      deleted.
      <br />
      No communication will be sent to the candidate. Candidate calendar events will not be changed.
    </Dialog>
  );
};

export default DebriefContentCancelDebriefDialog;
