import type { FC } from 'react';

import useIdentifyLoggers from 'src/hooks/useIdentifyLoggers';

/**
 * Sets up the logger context and identifies the user for all our systems.
 * @returns Null react compoenent
 */
const IdentifyLoggers: FC = () => {
  useIdentifyLoggers();
  return null;
};

export default IdentifyLoggers;
