import { SvgIcon, SvgIconProps } from '@mui/material';

export const ClockWithArchedArrowIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;

  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5557 4.86504L17.9123 4.28361C18.231 4.14705 18.6005 4.21588 18.8486 4.45801C19.0968 4.70015 19.1746 5.0679 19.0458 5.38978L17.903 8.24693C17.7272 8.68646 17.2283 8.90024 16.7888 8.72443L13.9317 7.58157C13.6098 7.45282 13.3971 7.14284 13.3929 6.79619C13.3887 6.44953 13.5937 6.13446 13.9123 5.9979L15.1553 5.4652C14.0285 3.82511 12.1392 2.75001 9.99998 2.75001C7.72737 2.75001 5.73722 3.96251 4.64238 5.77949C4.52495 5.97438 4.3664 6.32755 4.22564 6.66905C4.15837 6.83223 4.09994 6.98091 4.05832 7.08882C4.03756 7.14267 4.02109 7.18608 4.00991 7.21575L3.99728 7.24945L3.99419 7.25774L3.9935 7.2596L3.9934 7.25989C3.84986 7.64844 3.41853 7.84707 3.02998 7.70354C2.64143 7.56001 2.4428 7.12867 2.58633 6.74012L3.28986 7.00001L2.58636 6.74005L2.58639 6.73994L2.5865 6.73967L2.58682 6.7388L2.5879 6.73589L2.59179 6.72542L2.60619 6.68702C2.61859 6.6541 2.63646 6.60699 2.6588 6.54908C2.70337 6.4335 2.76613 6.27378 2.83883 6.0974C2.978 5.75979 3.17452 5.30916 3.3576 5.00533C4.71255 2.75666 7.17995 1.25001 9.99998 1.25001C12.7606 1.25001 15.1829 2.69299 16.5557 4.86504ZM5.75 12C5.75 9.6528 7.65279 7.75001 10 7.75001C12.3472 7.75001 14.25 9.6528 14.25 12C14.25 14.3472 12.3472 16.25 10 16.25C7.65279 16.25 5.75 14.3472 5.75 12ZM10 6.25001C6.82436 6.25001 4.25 8.82437 4.25 12C4.25 15.1756 6.82436 17.75 10 17.75C13.1756 17.75 15.75 15.1756 15.75 12C15.75 8.82437 13.1756 6.25001 10 6.25001ZM10.75 9.50001C10.75 9.08579 10.4142 8.75001 10 8.75001C9.58579 8.75001 9.25 9.08579 9.25 9.50001V12C9.25 12.2508 9.37532 12.4849 9.58397 12.624L11.084 13.624C11.4286 13.8538 11.8943 13.7607 12.124 13.416C12.3538 13.0714 12.2607 12.6057 11.916 12.376L10.75 11.5986V9.50001Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
