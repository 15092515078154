import { ThemeOptions } from '@mui/material';

/**
 * View FormControl props and classes at https://mui.com/material-ui/api/form-control/
 */
export const formControl: ThemeOptions['components'] = {
  MuiFormControlLabel: {
    defaultProps: {},
    styleOverrides: {
      root: ({ theme }) => ({
        // width: `calc(100% + 24px)`,
        gap: '2px', // TODO: Look at this for label placement start
        borderRadius: '6px',
        marginLeft: '-12px',
        marginRight: '0',
        padding: theme.spacing(0, 1.5, 0, 0.75),
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      }),
      labelPlacementStart: ({ theme }) => ({
        marginLeft: '0',
        marginRight: '-12px',
        padding: theme.spacing(0, 0.75, 0, 1.5),
      }),
    },
  },
};
