import React from 'react';

import BaseIcon, { Props } from './Icon';

const SendIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        d="M9.5 10.5C3.53086 7.78676 7.74189 9.70086 4.77115 8.35052C3.9508 7.97764 3.99895 6.79573 4.85378 6.51063C8.69533 5.2294 11.5105 4.50165 15.7673 3.64581C16.1159 3.57572 16.4243 3.88409 16.3542 4.23269C15.4984 8.4895 14.7706 11.3047 13.4894 15.1462C13.2043 16.0011 12.0224 16.0492 11.6495 15.2288C10.2991 12.2581 12.2132 16.4691 9.5 10.5ZM9.5 10.5L15.5 4.5M3 13L4.5 11.5M7 17L8.5 15.5M4 16L5.5 14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default SendIcon;
