import { useIframeState } from '../components/Iframe/IframeStateContext';
import { Breakpoint, useTheme, useMediaQuery } from '@mui/material';

export const MOBILE_BREAKPOINT: Breakpoint = 'sm';

export const useIsMobile = (breakpoint?: number | Breakpoint) => {
  const { iframeWindow: window } = useIframeState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint || MOBILE_BREAKPOINT), {
    matchMedia: window?.matchMedia,
  });
  return isMobile;
};
