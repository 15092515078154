import { FilterSelectGroup } from './types';
import { AutocompleteRenderGroupParams, ListSubheader, Typography } from '@mui/material';
import { has } from 'lodash';

export const defaultRenderGroup = (params: AutocompleteRenderGroupParams) => {
  return [
    <ListSubheader
      // eslint-disable-next-line no-restricted-syntax
      sx={{ alignContent: 'center' }}
      key={params.key}
      disableGutters
    >
      <Typography color="text.secondary" fontWeight={600} variant="body2">
        {params.group === FilterSelectGroup.SUGGESTED && 'Suggestions'}
        {params.group === FilterSelectGroup.SELECTED && 'Selected'}
        {params.group === FilterSelectGroup.OTHER && 'Other'}
      </Typography>
    </ListSubheader>,
    params.children,
  ];
};

export const defaultGetOptionSelected = <TData,>(option: TData, value: TData) => {
  if (!option || !value) return false;
  if (
    (typeof option === 'string' && typeof value === 'string') ||
    (typeof option === 'number' && typeof value === 'number') ||
    (typeof option === 'boolean' && typeof value === 'boolean')
  ) {
    return option === value;
  }

  if (typeof option === 'object' && typeof value === 'object' && has(option, 'id') && has(value, 'id')) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    return (option as unknown as { id: unknown })?.id === (value as unknown as { id: unknown })?.id;
  }

  return false;
};
