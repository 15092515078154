import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date (example format: 2023-03-28) */
  date: any;
  /** Date time */
  datetime: any;
  /** A JSONB scalar */
  jsonb: any;
  /** Local Time (example format: 23:59) */
  localtime: any;
  /** numeric */
  numeric: any;
  /** Timezone */
  timezone: any;
  /** UUID */
  uuid: any;
};




export type AppStgCandidateEventsAppStgInput = {
  orderBy?: Maybe<DateOrderByInput>;
};

export type ApplicationStageApplicationInput = {
  isCancelled?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<DateOrderByInput>;
};

export enum BrandingThemeMode {
  Light = 'LIGHT',
  Dark = 'DARK'
}

export type BufferTime = {
  __typename?: 'BufferTime';
  before: Duration;
  after: Duration;
};

export type BufferTimeInput = {
  before: DurationInput;
  after: DurationInput;
};

export type BufferTimeSettings = {
  __typename?: 'BufferTimeSettings';
  bufferTime?: Maybe<BufferTime>;
  level: SettingsLevel;
};

export enum CandidateCancellationReason {
  NotSet = 'NOT_SET',
  CantMakeTime = 'CANT_MAKE_TIME',
  NoLongerInterested = 'NO_LONGER_INTERESTED',
  AcceptedAnotherOffer = 'ACCEPTED_ANOTHER_OFFER',
  Other = 'OTHER'
}

export type DateBlock = {
  __typename?: 'DateBlock';
  /**  yyyy-MM-dd format */
  start?: Maybe<Scalars['String']>;
  /**  yyyy-MM-dd format */
  end?: Maybe<Scalars['String']>;
};

export type DateBlockInput = {
  /**  yyyy-MM-dd format */
  start?: Maybe<Scalars['String']>;
  /**  yyyy-MM-dd format */
  end?: Maybe<Scalars['String']>;
};

export type DateOrderByInput = {
  createdAt?: Maybe<Sort>;
  updatedAt?: Maybe<Sort>;
  startAt?: Maybe<Sort>;
  endAt?: Maybe<Sort>;
};

export type DateTimeRangeInput = {
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
};

export type DateTimeRangeOutput = {
  __typename?: 'DateTimeRangeOutput';
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  /** @deprecated Field no longer supported */
  start: Scalars['datetime'];
  /** @deprecated Field no longer supported */
  end: Scalars['datetime'];
};

export enum DeleteType {
  Upcoming = 'UPCOMING',
  Past = 'PAST',
  All = 'ALL'
}

export type Duration = {
  __typename?: 'Duration';
  seconds: Scalars['numeric'];
  nanos: Scalars['numeric'];
};

export type DurationInput = {
  seconds: Scalars['numeric'];
  nanos?: Maybe<Scalars['numeric']>;
};

export type EmptyResult = {
  __typename?: 'EmptyResult';
  result?: Maybe<Result>;
};

export type FeedbackWritingTime = {
  __typename?: 'FeedbackWritingTime';
  duration?: Maybe<Duration>;
  level: SettingsLevel;
};

export type FeedbackWritingTimeInput = {
  duration: DurationInput;
};

export type InterviewLimitInput = {
  type: InterviewLimitType;
  value: Scalars['numeric'];
};

export type InterviewLimitSettings = {
  __typename?: 'InterviewLimitSettings';
  currentLimit: LoadLimit;
  orgLimit: LoadLimit;
  orgMinimum: MinimumLoadLimit;
  level: SettingsLevel;
};

export enum InterviewLimitType {
  NumberOfMinutes = 'NUMBER_OF_MINUTES',
  NumberOfInterviews = 'NUMBER_OF_INTERVIEWS'
}

export type InterviewLimits = {
  __typename?: 'InterviewLimits';
  dailyInterviewLimit: InterviewLimitSettings;
  weeklyInterviewLimit: InterviewLimitSettings;
};

export type InterviewLimitsInput = {
  dailyInterviewLimit?: Maybe<InterviewLimitInput>;
  weeklyInterviewLimit?: Maybe<InterviewLimitInput>;
};

export type InterviewLocation = {
  __typename?: 'InterviewLocation';
  /**  This can be null if the type is not yet understood by BE. */
  type?: Maybe<InterviewLocationType>;
  value?: Maybe<Scalars['String']>;
};

export enum InterviewLocationType {
  GoogleMeet = 'GOOGLE_MEET',
  Zoom = 'ZOOM',
  MicrosoftTeams = 'MICROSOFT_TEAMS',
  CustomLink = 'CUSTOM_LINK',
  Phone = 'PHONE'
}

/**
 * Most of these fields are marked as nullable since they can have NONE permissions set on the
 * corresponding InterviewerPortalFieldPermission. Fields that are non-nullable will have minimum
 * READ permission for that field and can't go below that permission.
 */
export type Interviewer = {
  __typename?: 'Interviewer';
  id: Scalars['uuid'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  timezoneSettings: TimezoneSettings;
  interviewLimits?: Maybe<InterviewLimits>;
  workHours?: Maybe<WorkHoursSettings>;
  pauseDates?: Maybe<Array<LocalDateBlock>>;
  tosAcceptedAt?: Maybe<Scalars['datetime']>;
  plannedAvailability?: Maybe<Array<PlannedAvailability>>;
  keywords: InterviewerKeywords;
  profilePicture?: Maybe<ProfilePicture>;
  pronouns?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  bufferTimeSettings?: Maybe<BufferTimeSettings>;
  feedbackWritingTime?: Maybe<FeedbackWritingTime>;
};

export type InterviewerFieldPermissions = {
  __typename?: 'InterviewerFieldPermissions';
  firstName?: Maybe<InterviewerPortalFieldPermission>;
  lastName?: Maybe<InterviewerPortalFieldPermission>;
  title?: Maybe<InterviewerPortalFieldPermission>;
  emailAddress?: Maybe<InterviewerPortalFieldPermission>;
  linkedinUrl?: Maybe<InterviewerPortalFieldPermission>;
  timezone?: Maybe<InterviewerPortalFieldPermission>;
  interviewLoadCapacity?: Maybe<InterviewerPortalFieldPermission>;
  interviewAvailability?: Maybe<InterviewerPortalFieldPermission>;
  temporaryAvailability?: Maybe<InterviewerPortalFieldPermission>;
  pauseFromInterviews?: Maybe<InterviewerPortalFieldPermission>;
  interviewerKeywords?: Maybe<InterviewerPortalFieldPermission>;
  profilePicture?: Maybe<InterviewerPortalFieldPermission>;
  pronouns?: Maybe<InterviewerPortalFieldPermission>;
  bio?: Maybe<InterviewerPortalFieldPermission>;
  feedbackWritingTime?: Maybe<InterviewerPortalFieldPermission>;
  bufferTime?: Maybe<InterviewerPortalFieldPermission>;
};

export type InterviewerKeywords = {
  __typename?: 'InterviewerKeywords';
  avoid: StringArrayPref;
  available: StringArrayPref;
  outOfOffice: StringArrayPref;
  recruitingBlocks: StringArrayPref;
};

export type InterviewerPlannedAvailability = {
  __typename?: 'InterviewerPlannedAvailability';
  id: Scalars['uuid'];
  employeeId: Scalars['uuid'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  createdAt: Scalars['datetime'];
  updatedAt: Scalars['datetime'];
  timezone: Scalars['timezone'];
  workHours: TimeBlockWeek;
};

export enum InterviewerPortalFieldPermission {
  None = 'NONE',
  Read = 'READ',
  Write = 'WRITE'
}

export type KeywordsInput = {
  avoid?: Maybe<Array<Scalars['String']>>;
  available?: Maybe<Array<Scalars['String']>>;
  recruitingBlocks?: Maybe<Array<Scalars['String']>>;
  outOfOffice?: Maybe<Array<Scalars['String']>>;
};

export type LoadLimit = {
  __typename?: 'LoadLimit';
  type: InterviewLimitType;
  value: Scalars['numeric'];
};

export type LocalDateBlock = {
  __typename?: 'LocalDateBlock';
  start: Scalars['date'];
  end: Scalars['date'];
};

export type LocalTimeBlock = {
  __typename?: 'LocalTimeBlock';
  start: Scalars['localtime'];
  end: Scalars['localtime'];
};

export type LocalTimeBlockInput = {
  start: Scalars['localtime'];
  end: Scalars['localtime'];
};

export type MinimumLoadLimit = {
  __typename?: 'MinimumLoadLimit';
  interviews: Scalars['numeric'];
  minutes: Scalars['numeric'];
};

export type Mutation = {
  __typename?: 'Mutation';
  updateInterviewer: Interviewer;
  plannedAvailabilityCreate: Interviewer;
  plannedAvailabilityUpdate: Interviewer;
  plannedAvailabilityDelete?: Maybe<EmptyResult>;
  acceptTermsOfService?: Maybe<EmptyResult>;
};


export type MutationUpdateInterviewerArgs = {
  input: UpdateInterviewerInput;
};


export type MutationPlannedAvailabilityCreateArgs = {
  input: PlannedAvailabilityCreateInput;
};


export type MutationPlannedAvailabilityUpdateArgs = {
  input: PlannedAvailabilityUpdateInput;
};


export type MutationPlannedAvailabilityDeleteArgs = {
  input: PlannedAvailabilityDeleteInput;
};

export type Org = {
  __typename?: 'Org';
  id: Scalars['uuid'];
  name: Scalars['String'];
  fieldPermissions: InterviewerFieldPermissions;
};

export enum Permission {
  CanAdmin = 'CAN_ADMIN',
  CanManageOrgSettings = 'CAN_MANAGE_ORG_SETTINGS',
  CanSchedule = 'CAN_SCHEDULE',
  CanInterview = 'CAN_INTERVIEW'
}

export type PlannedAvailability = {
  __typename?: 'PlannedAvailability';
  id: Scalars['uuid'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  timezone: Scalars['timezone'];
  workHours: TimeBlockWeek;
};

export type PlannedAvailabilityCreateInput = {
  interviewerId: Scalars['uuid'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  timezone: Scalars['timezone'];
  workHours: TimeBlockWeekInput;
};

export type PlannedAvailabilityDeleteInput = {
  id: Scalars['uuid'];
};

export type PlannedAvailabilityUpdateInput = {
  id: Scalars['uuid'];
  startAt: Scalars['datetime'];
  endAt: Scalars['datetime'];
  timezone: Scalars['timezone'];
  workHours: TimeBlockWeekInput;
};

export enum PortalJobOptionsTabVisibilityType {
  Always = 'ALWAYS',
  Never = 'NEVER',
  JobStage = 'JOB_STAGE'
}

export type ProfilePicture = {
  __typename?: 'ProfilePicture';
  imageOriginal?: Maybe<Scalars['String']>;
  image32px?: Maybe<Scalars['String']>;
  image64px?: Maybe<Scalars['String']>;
  image128px?: Maybe<Scalars['String']>;
  image256px?: Maybe<Scalars['String']>;
  image512px?: Maybe<Scalars['String']>;
  image1024px?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  hello?: Maybe<Scalars['String']>;
  actorUserInfo: UserInfo;
  thisOrg?: Maybe<Org>;
  thisInterviewer?: Maybe<Interviewer>;
};

export enum ResponseStatus {
  NeedsAction = 'NEEDS_ACTION',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Tentative = 'TENTATIVE',
  Missed = 'MISSED'
}

export enum Result {
  Success = 'SUCCESS',
  Failure = 'FAILURE'
}

export enum SelfScheduleLocation {
  None = 'NONE',
  Zoom = 'ZOOM',
  Google = 'GOOGLE',
  Phone = 'PHONE',
  Custom = 'CUSTOM',
  MicrosoftTeams = 'MICROSOFT_TEAMS'
}

export enum SelfScheduleMeetingHost {
  Interviewer = 'INTERVIEWER',
  Scheduler = 'SCHEDULER',
  CustomMeetingHost = 'CUSTOM_MEETING_HOST'
}

export enum SelfScheduleZoomHost {
  NotSet = 'NOT_SET',
  Interviewer = 'INTERVIEWER',
  Scheduler = 'SCHEDULER',
  CustomZoomHost = 'CUSTOM_ZOOM_HOST'
}

export enum SettingsLevel {
  Org = 'ORG',
  Interviewer = 'INTERVIEWER'
}

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SourcingLinkTaskAction {
  SelfSchedule = 'SELF_SCHEDULE',
  Availability = 'AVAILABILITY'
}

export type StringArrayPref = {
  __typename?: 'StringArrayPref';
  /**  This returns employee pref if set, org pref if employee pref is null */
  current: Array<Scalars['String']>;
  org: Array<Scalars['String']>;
  level?: Maybe<SettingsLevel>;
};

export type TimeBlockWeek = {
  __typename?: 'TimeBlockWeek';
  monday: Array<LocalTimeBlock>;
  tuesday: Array<LocalTimeBlock>;
  wednesday: Array<LocalTimeBlock>;
  thursday: Array<LocalTimeBlock>;
  friday: Array<LocalTimeBlock>;
  saturday: Array<LocalTimeBlock>;
  sunday: Array<LocalTimeBlock>;
};

export type TimeBlockWeekInput = {
  monday: Array<LocalTimeBlockInput>;
  tuesday: Array<LocalTimeBlockInput>;
  wednesday: Array<LocalTimeBlockInput>;
  thursday: Array<LocalTimeBlockInput>;
  friday: Array<LocalTimeBlockInput>;
  saturday: Array<LocalTimeBlockInput>;
  sunday: Array<LocalTimeBlockInput>;
};

export type TimeRangeCount = {
  __typename?: 'TimeRangeCount';
  range: DateTimeRangeOutput;
  count: Scalars['numeric'];
};

export type TimezoneSettings = {
  __typename?: 'TimezoneSettings';
  interviewerTimezone: Scalars['timezone'];
  calendarTimezone: Scalars['timezone'];
  useCalendarTimezone: Scalars['Boolean'];
};

export type TimezoneSettingsInput = {
  interviewerTimezone?: Maybe<Scalars['timezone']>;
  useCalendarTimezone?: Maybe<Scalars['Boolean']>;
};

export type UpdateInterviewerInput = {
  id: Scalars['uuid'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  timezone?: Maybe<TimezoneSettingsInput>;
  interviewLimits?: Maybe<InterviewLimitsInput>;
  workHours?: Maybe<TimeBlockWeekInput>;
  pauseDates?: Maybe<Array<DateBlockInput>>;
  keywords?: Maybe<KeywordsInput>;
  bufferTime?: Maybe<BufferTimeInput>;
  feedbackWritingTime?: Maybe<FeedbackWritingTimeInput>;
  profilePictureUrl?: Maybe<Scalars['String']>;
  pronouns?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  permissions: Array<Permission>;
};

export type WorkHoursSettings = {
  __typename?: 'WorkHoursSettings';
  hours: TimeBlockWeek;
  level: SettingsLevel;
  orgHours: TimeBlockWeek;
};








export type DevFetchInterviewerQueryVariables = Exact<{ [key: string]: never; }>;


export type DevFetchInterviewerQuery = (
  { __typename?: 'Query' }
  & { thisInterviewer?: Maybe<(
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
    & { plannedAvailability?: Maybe<Array<(
      { __typename?: 'PlannedAvailability' }
      & Pick<PlannedAvailability, 'id'>
    )>> }
  )> }
);

export type DevDeleteTemporaryAvailabilityMutationVariables = Exact<{
  input: PlannedAvailabilityDeleteInput;
}>;


export type DevDeleteTemporaryAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { plannedAvailabilityDelete?: Maybe<(
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  )> }
);

export type DevUpdateInterviewerMutationVariables = Exact<{
  input: UpdateInterviewerInput;
}>;


export type DevUpdateInterviewerMutation = (
  { __typename?: 'Mutation' }
  & { updateInterviewer: (
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
  ) }
);

export type UserTermsOfServiceQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTermsOfServiceQuery = (
  { __typename?: 'Query' }
  & { thisInterviewer?: Maybe<(
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id' | 'tosAcceptedAt'>
  )> }
);

export type OrgNameQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgNameQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id' | 'name'>
  )> }
);

export type TimeBlockWeekFragment = (
  { __typename?: 'TimeBlockWeek' }
  & { monday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, tuesday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, wednesday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, thursday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, friday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, saturday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, sunday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )> }
);

export type EditAvailabilityDialog_InterviewerFragment = (
  { __typename?: 'Interviewer' }
  & { workHours?: Maybe<(
    { __typename?: 'WorkHoursSettings' }
    & Pick<WorkHoursSettings, 'level'>
    & { hours: (
      { __typename?: 'TimeBlockWeek' }
      & TimeBlockWeekFragment
    ), orgHours: (
      { __typename?: 'TimeBlockWeek' }
      & TimeBlockWeekFragment
    ) }
  )> }
);

export type EditLimitsButton_InterviewerFragment = (
  { __typename?: 'Interviewer' }
  & { interviewLimits?: Maybe<(
    { __typename?: 'InterviewLimits' }
    & { dailyInterviewLimit: (
      { __typename?: 'InterviewLimitSettings' }
      & Pick<InterviewLimitSettings, 'level'>
      & { currentLimit: (
        { __typename?: 'LoadLimit' }
        & Pick<LoadLimit, 'type' | 'value'>
      ), orgMinimum: (
        { __typename?: 'MinimumLoadLimit' }
        & Pick<MinimumLoadLimit, 'interviews' | 'minutes'>
      ), orgLimit: (
        { __typename?: 'LoadLimit' }
        & Pick<LoadLimit, 'type' | 'value'>
      ) }
    ), weeklyInterviewLimit: (
      { __typename?: 'InterviewLimitSettings' }
      & Pick<InterviewLimitSettings, 'level'>
      & { currentLimit: (
        { __typename?: 'LoadLimit' }
        & Pick<LoadLimit, 'type' | 'value'>
      ), orgMinimum: (
        { __typename?: 'MinimumLoadLimit' }
        & Pick<MinimumLoadLimit, 'interviews' | 'minutes'>
      ), orgLimit: (
        { __typename?: 'LoadLimit' }
        & Pick<LoadLimit, 'type' | 'value'>
      ) }
    ) }
  )> }
);

export type EditTimezoneDialog_InterviewerFragment = (
  { __typename?: 'Interviewer' }
  & Pick<Interviewer, 'id'>
  & { timezoneSettings: (
    { __typename?: 'TimezoneSettings' }
    & Pick<TimezoneSettings, 'interviewerTimezone' | 'calendarTimezone' | 'useCalendarTimezone'>
  ) }
);

export type InterviewerWorkHoursFragment = (
  { __typename?: 'Interviewer' }
  & { interviewLimits?: Maybe<(
    { __typename?: 'InterviewLimits' }
    & { dailyInterviewLimit: (
      { __typename?: 'InterviewLimitSettings' }
      & { orgMinimum: (
        { __typename?: 'MinimumLoadLimit' }
        & Pick<MinimumLoadLimit, 'interviews' | 'minutes'>
      ) }
    ), weeklyInterviewLimit: (
      { __typename?: 'InterviewLimitSettings' }
      & { orgMinimum: (
        { __typename?: 'MinimumLoadLimit' }
        & Pick<MinimumLoadLimit, 'interviews' | 'minutes'>
      ) }
    ) }
  )>, workHours?: Maybe<(
    { __typename?: 'WorkHoursSettings' }
    & { hours: (
      { __typename?: 'TimeBlockWeek' }
      & { monday: Array<(
        { __typename?: 'LocalTimeBlock' }
        & Pick<LocalTimeBlock, 'start' | 'end'>
      )>, tuesday: Array<(
        { __typename?: 'LocalTimeBlock' }
        & Pick<LocalTimeBlock, 'start' | 'end'>
      )>, wednesday: Array<(
        { __typename?: 'LocalTimeBlock' }
        & Pick<LocalTimeBlock, 'start' | 'end'>
      )>, thursday: Array<(
        { __typename?: 'LocalTimeBlock' }
        & Pick<LocalTimeBlock, 'start' | 'end'>
      )>, friday: Array<(
        { __typename?: 'LocalTimeBlock' }
        & Pick<LocalTimeBlock, 'start' | 'end'>
      )>, saturday: Array<(
        { __typename?: 'LocalTimeBlock' }
        & Pick<LocalTimeBlock, 'start' | 'end'>
      )>, sunday: Array<(
        { __typename?: 'LocalTimeBlock' }
        & Pick<LocalTimeBlock, 'start' | 'end'>
      )> }
    ) }
  )> }
);

export type GetAvailabilityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailabilityQuery = (
  { __typename?: 'Query' }
  & { thisInterviewer?: Maybe<(
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
    & { timezoneSettings: (
      { __typename?: 'TimezoneSettings' }
      & Pick<TimezoneSettings, 'interviewerTimezone' | 'calendarTimezone' | 'useCalendarTimezone'>
    ), interviewLimits?: Maybe<(
      { __typename?: 'InterviewLimits' }
      & { dailyInterviewLimit: (
        { __typename?: 'InterviewLimitSettings' }
        & Pick<InterviewLimitSettings, 'level'>
        & { currentLimit: (
          { __typename?: 'LoadLimit' }
          & Pick<LoadLimit, 'type' | 'value'>
        ) }
      ), weeklyInterviewLimit: (
        { __typename?: 'InterviewLimitSettings' }
        & Pick<InterviewLimitSettings, 'level'>
        & { currentLimit: (
          { __typename?: 'LoadLimit' }
          & Pick<LoadLimit, 'type' | 'value'>
        ) }
      ) }
    )> }
    & EditLimitsButton_InterviewerFragment
    & EditAvailabilityDialog_InterviewerFragment
    & InterviewerWorkHoursFragment
  )> }
);

export type UpdateInterviewerAvailabilityMutationVariables = Exact<{
  input: UpdateInterviewerInput;
}>;


export type UpdateInterviewerAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { updateInterviewer: (
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
    & { timezoneSettings: (
      { __typename?: 'TimezoneSettings' }
      & Pick<TimezoneSettings, 'interviewerTimezone' | 'calendarTimezone' | 'useCalendarTimezone'>
    ), interviewLimits?: Maybe<(
      { __typename?: 'InterviewLimits' }
      & { dailyInterviewLimit: (
        { __typename?: 'InterviewLimitSettings' }
        & Pick<InterviewLimitSettings, 'level'>
        & { currentLimit: (
          { __typename?: 'LoadLimit' }
          & Pick<LoadLimit, 'type' | 'value'>
        ) }
      ), weeklyInterviewLimit: (
        { __typename?: 'InterviewLimitSettings' }
        & Pick<InterviewLimitSettings, 'level'>
        & { currentLimit: (
          { __typename?: 'LoadLimit' }
          & Pick<LoadLimit, 'type' | 'value'>
        ) }
      ) }
    )> }
    & InterviewerWorkHoursFragment
  ) }
);

export type GetContactInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactInfoQuery = (
  { __typename?: 'Query' }
  & { thisInterviewer?: Maybe<(
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id' | 'email' | 'linkedinUrl'>
  )> }
);

export type UpdateInterviewerContactInfoMutationVariables = Exact<{
  input: UpdateInterviewerInput;
}>;


export type UpdateInterviewerContactInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateInterviewer: (
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id' | 'email' | 'linkedinUrl'>
  ) }
);

export type ExternalInfoFragment = (
  { __typename?: 'Interviewer' }
  & Pick<Interviewer, 'id' | 'firstName' | 'lastName' | 'title' | 'email' | 'linkedinUrl' | 'pronouns' | 'bio'>
  & { profilePicture?: Maybe<(
    { __typename?: 'ProfilePicture' }
    & Pick<ProfilePicture, 'imageOriginal' | 'image128px'>
  )> }
);

export type GetExternalInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExternalInfoQuery = (
  { __typename?: 'Query' }
  & { thisInterviewer?: Maybe<(
    { __typename?: 'Interviewer' }
    & ExternalInfoFragment
  )> }
);

export type UpdateInterviewerExternalInfoMutationVariables = Exact<{
  input: UpdateInterviewerInput;
}>;


export type UpdateInterviewerExternalInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateInterviewer: (
    { __typename?: 'Interviewer' }
    & ExternalInfoFragment
  ) }
);

export type AvailableKeywordsField_InterviewerFragment = (
  { __typename?: 'Interviewer' }
  & Pick<Interviewer, 'id'>
  & { keywords: (
    { __typename?: 'InterviewerKeywords' }
    & { available: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current' | 'org' | 'level'>
    ), recruitingBlocks: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ), avoid: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ), outOfOffice: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ) }
  ) }
);

export type UpdateInterviewerAvailabileKeywordsMutationVariables = Exact<{
  input: UpdateInterviewerInput;
}>;


export type UpdateInterviewerAvailabileKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { updateInterviewer: (
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
    & { keywords: (
      { __typename?: 'InterviewerKeywords' }
      & { available: (
        { __typename?: 'StringArrayPref' }
        & Pick<StringArrayPref, 'current' | 'org' | 'level'>
      ) }
    ) }
  ) }
);

export type AvoidKeywordsField_InterviewerFragment = (
  { __typename?: 'Interviewer' }
  & Pick<Interviewer, 'id'>
  & { keywords: (
    { __typename?: 'InterviewerKeywords' }
    & { available: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ), recruitingBlocks: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ), avoid: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current' | 'org' | 'level'>
    ), outOfOffice: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ) }
  ) }
);

export type UpdateInterviewerAvoidKeywordsMutationVariables = Exact<{
  input: UpdateInterviewerInput;
}>;


export type UpdateInterviewerAvoidKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { updateInterviewer: (
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
    & { keywords: (
      { __typename?: 'InterviewerKeywords' }
      & { avoid: (
        { __typename?: 'StringArrayPref' }
        & Pick<StringArrayPref, 'current' | 'org' | 'level'>
      ) }
    ) }
  ) }
);

export type OutOfOfficeKeywordsField_InterviewerFragment = (
  { __typename?: 'Interviewer' }
  & Pick<Interviewer, 'id'>
  & { keywords: (
    { __typename?: 'InterviewerKeywords' }
    & { available: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ), recruitingBlocks: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ), avoid: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ), outOfOffice: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current' | 'org' | 'level'>
    ) }
  ) }
);

export type UpdateInterviewerOutOfOfficeKeywordsMutationVariables = Exact<{
  input: UpdateInterviewerInput;
}>;


export type UpdateInterviewerOutOfOfficeKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { updateInterviewer: (
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
    & { keywords: (
      { __typename?: 'InterviewerKeywords' }
      & { outOfOffice: (
        { __typename?: 'StringArrayPref' }
        & Pick<StringArrayPref, 'current' | 'org' | 'level'>
      ) }
    ) }
  ) }
);

export type RecruitingBlocksKeywordsField_InterviewerFragment = (
  { __typename?: 'Interviewer' }
  & Pick<Interviewer, 'id'>
  & { keywords: (
    { __typename?: 'InterviewerKeywords' }
    & { available: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ), recruitingBlocks: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current' | 'org' | 'level'>
    ), avoid: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ), outOfOffice: (
      { __typename?: 'StringArrayPref' }
      & Pick<StringArrayPref, 'current'>
    ) }
  ) }
);

export type UpdateInterviewerRecruitingBlocksKeywordsMutationVariables = Exact<{
  input: UpdateInterviewerInput;
}>;


export type UpdateInterviewerRecruitingBlocksKeywordsMutation = (
  { __typename?: 'Mutation' }
  & { updateInterviewer: (
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
    & { keywords: (
      { __typename?: 'InterviewerKeywords' }
      & { recruitingBlocks: (
        { __typename?: 'StringArrayPref' }
        & Pick<StringArrayPref, 'current' | 'org' | 'level'>
      ) }
    ) }
  ) }
);

export type InterviewerKeywordsQueryVariables = Exact<{ [key: string]: never; }>;


export type InterviewerKeywordsQuery = (
  { __typename?: 'Query' }
  & { thisInterviewer?: Maybe<(
    { __typename?: 'Interviewer' }
    & AvailableKeywordsField_InterviewerFragment
    & AvoidKeywordsField_InterviewerFragment
    & OutOfOfficeKeywordsField_InterviewerFragment
    & RecruitingBlocksKeywordsField_InterviewerFragment
  )> }
);

export type WorkHoursFragment = (
  { __typename?: 'TimeBlockWeek' }
  & { monday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, tuesday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, wednesday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, thursday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, friday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, saturday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )>, sunday: Array<(
    { __typename?: 'LocalTimeBlock' }
    & Pick<LocalTimeBlock, 'start' | 'end'>
  )> }
);

export type PlannedAvailabilityFragment = (
  { __typename?: 'PlannedAvailability' }
  & Pick<PlannedAvailability, 'id' | 'startAt' | 'endAt' | 'timezone'>
  & { workHours: (
    { __typename?: 'TimeBlockWeek' }
    & WorkHoursFragment
  ) }
);

export type GetPlannedAvailabilityQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlannedAvailabilityQuery = (
  { __typename?: 'Query' }
  & { thisInterviewer?: Maybe<(
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
    & { plannedAvailability?: Maybe<Array<(
      { __typename?: 'PlannedAvailability' }
      & PlannedAvailabilityFragment
    )>>, pauseDates?: Maybe<Array<(
      { __typename?: 'LocalDateBlock' }
      & Pick<LocalDateBlock, 'start' | 'end'>
    )>>, workHours?: Maybe<(
      { __typename?: 'WorkHoursSettings' }
      & { orgHours: (
        { __typename?: 'TimeBlockWeek' }
        & WorkHoursFragment
      ) }
    )> }
  )> }
);

export type UpdateInterviewerPauseDatesMutationVariables = Exact<{
  input: UpdateInterviewerInput;
}>;


export type UpdateInterviewerPauseDatesMutation = (
  { __typename?: 'Mutation' }
  & { updateInterviewer: (
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
    & { pauseDates?: Maybe<Array<(
      { __typename?: 'LocalDateBlock' }
      & Pick<LocalDateBlock, 'start' | 'end'>
    )>> }
  ) }
);

export type CreatePlannedAvailabilityMutationVariables = Exact<{
  input: PlannedAvailabilityCreateInput;
}>;


export type CreatePlannedAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { plannedAvailabilityCreate: (
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
    & { plannedAvailability?: Maybe<Array<(
      { __typename?: 'PlannedAvailability' }
      & PlannedAvailabilityFragment
    )>> }
  ) }
);

export type UpdatePlannedAvailabilityMutationVariables = Exact<{
  input: PlannedAvailabilityUpdateInput;
}>;


export type UpdatePlannedAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { plannedAvailabilityUpdate: (
    { __typename?: 'Interviewer' }
    & Pick<Interviewer, 'id'>
    & { plannedAvailability?: Maybe<Array<(
      { __typename?: 'PlannedAvailability' }
      & PlannedAvailabilityFragment
    )>> }
  ) }
);

export type DeletePlannedAvailabilityMutationVariables = Exact<{
  input: PlannedAvailabilityDeleteInput;
}>;


export type DeletePlannedAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { plannedAvailabilityDelete?: Maybe<(
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  )> }
);

export type SaveTermsOfServiceMutationVariables = Exact<{ [key: string]: never; }>;


export type SaveTermsOfServiceMutation = (
  { __typename?: 'Mutation' }
  & { acceptTermsOfService?: Maybe<(
    { __typename?: 'EmptyResult' }
    & Pick<EmptyResult, 'result'>
  )> }
);

export type SchedulerPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type SchedulerPermissionQuery = (
  { __typename?: 'Query' }
  & { actorUserInfo: (
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'permissions'>
  ) }
);

export type GetInterviewerConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInterviewerConfigQuery = (
  { __typename?: 'Query' }
  & { thisOrg?: Maybe<(
    { __typename?: 'Org' }
    & Pick<Org, 'id'>
    & { fieldPermissions: (
      { __typename?: 'InterviewerFieldPermissions' }
      & Pick<InterviewerFieldPermissions, 'firstName' | 'lastName' | 'title' | 'emailAddress' | 'linkedinUrl' | 'timezone' | 'interviewLoadCapacity' | 'interviewAvailability' | 'temporaryAvailability' | 'pauseFromInterviews' | 'interviewerKeywords' | 'profilePicture' | 'bio' | 'pronouns'>
    ) }
  )> }
);

export const TimeBlockWeekFragmentDoc = gql`
    fragment TimeBlockWeek on TimeBlockWeek {
  monday {
    start
    end
  }
  tuesday {
    start
    end
  }
  wednesday {
    start
    end
  }
  thursday {
    start
    end
  }
  friday {
    start
    end
  }
  saturday {
    start
    end
  }
  sunday {
    start
    end
  }
}
    `;
export const EditAvailabilityDialog_InterviewerFragmentDoc = gql`
    fragment EditAvailabilityDialog_interviewer on Interviewer {
  workHours {
    hours {
      ...TimeBlockWeek
    }
    orgHours {
      ...TimeBlockWeek
    }
    level
  }
}
    ${TimeBlockWeekFragmentDoc}`;
export const EditLimitsButton_InterviewerFragmentDoc = gql`
    fragment EditLimitsButton_interviewer on Interviewer {
  interviewLimits {
    dailyInterviewLimit {
      currentLimit {
        type
        value
      }
      orgMinimum {
        interviews
        minutes
      }
      orgLimit {
        type
        value
      }
      level
    }
    weeklyInterviewLimit {
      currentLimit {
        type
        value
      }
      orgMinimum {
        interviews
        minutes
      }
      orgLimit {
        type
        value
      }
      level
    }
  }
}
    `;
export const EditTimezoneDialog_InterviewerFragmentDoc = gql`
    fragment EditTimezoneDialog_interviewer on Interviewer {
  id
  timezoneSettings {
    interviewerTimezone
    calendarTimezone
    useCalendarTimezone
  }
}
    `;
export const InterviewerWorkHoursFragmentDoc = gql`
    fragment InterviewerWorkHours on Interviewer {
  interviewLimits {
    dailyInterviewLimit {
      orgMinimum {
        interviews
        minutes
      }
    }
    weeklyInterviewLimit {
      orgMinimum {
        interviews
        minutes
      }
    }
  }
  workHours {
    hours {
      monday {
        start
        end
      }
      tuesday {
        start
        end
      }
      wednesday {
        start
        end
      }
      thursday {
        start
        end
      }
      friday {
        start
        end
      }
      saturday {
        start
        end
      }
      sunday {
        start
        end
      }
    }
  }
}
    `;
export const ExternalInfoFragmentDoc = gql`
    fragment ExternalInfo on Interviewer {
  id
  firstName
  lastName
  title
  email
  linkedinUrl
  profilePicture {
    imageOriginal
    image128px
  }
  pronouns
  bio
}
    `;
export const AvailableKeywordsField_InterviewerFragmentDoc = gql`
    fragment AvailableKeywordsField_interviewer on Interviewer {
  id
  keywords {
    available {
      current
      org
      level
    }
    recruitingBlocks {
      current
    }
    avoid {
      current
    }
    outOfOffice {
      current
    }
  }
}
    `;
export const AvoidKeywordsField_InterviewerFragmentDoc = gql`
    fragment AvoidKeywordsField_interviewer on Interviewer {
  id
  keywords {
    available {
      current
    }
    recruitingBlocks {
      current
    }
    avoid {
      current
      org
      level
    }
    outOfOffice {
      current
    }
  }
}
    `;
export const OutOfOfficeKeywordsField_InterviewerFragmentDoc = gql`
    fragment OutOfOfficeKeywordsField_interviewer on Interviewer {
  id
  keywords {
    available {
      current
    }
    recruitingBlocks {
      current
    }
    avoid {
      current
    }
    outOfOffice {
      current
      org
      level
    }
  }
}
    `;
export const RecruitingBlocksKeywordsField_InterviewerFragmentDoc = gql`
    fragment RecruitingBlocksKeywordsField_interviewer on Interviewer {
  id
  keywords {
    available {
      current
    }
    recruitingBlocks {
      current
      org
      level
    }
    avoid {
      current
    }
    outOfOffice {
      current
    }
  }
}
    `;
export const WorkHoursFragmentDoc = gql`
    fragment WorkHours on TimeBlockWeek {
  monday {
    start
    end
  }
  tuesday {
    start
    end
  }
  wednesday {
    start
    end
  }
  thursday {
    start
    end
  }
  friday {
    start
    end
  }
  saturday {
    start
    end
  }
  sunday {
    start
    end
  }
}
    `;
export const PlannedAvailabilityFragmentDoc = gql`
    fragment PlannedAvailability on PlannedAvailability {
  id
  startAt
  endAt
  timezone
  workHours {
    ...WorkHours
  }
}
    ${WorkHoursFragmentDoc}`;
export const DevFetchInterviewerDocument = gql`
    query DevFetchInterviewer {
  thisInterviewer {
    id
    plannedAvailability {
      id
    }
  }
}
    `;

/**
 * __useDevFetchInterviewerQuery__
 *
 * To run a query within a React component, call `useDevFetchInterviewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevFetchInterviewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevFetchInterviewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useDevFetchInterviewerQuery(baseOptions?: Apollo.QueryHookOptions<DevFetchInterviewerQuery, DevFetchInterviewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevFetchInterviewerQuery, DevFetchInterviewerQueryVariables>(DevFetchInterviewerDocument, options);
      }
export function useDevFetchInterviewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevFetchInterviewerQuery, DevFetchInterviewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevFetchInterviewerQuery, DevFetchInterviewerQueryVariables>(DevFetchInterviewerDocument, options);
        }
export type DevFetchInterviewerQueryHookResult = ReturnType<typeof useDevFetchInterviewerQuery>;
export type DevFetchInterviewerLazyQueryHookResult = ReturnType<typeof useDevFetchInterviewerLazyQuery>;
export type DevFetchInterviewerQueryResult = Apollo.QueryResult<DevFetchInterviewerQuery, DevFetchInterviewerQueryVariables>;
export const DevDeleteTemporaryAvailabilityDocument = gql`
    mutation DevDeleteTemporaryAvailability($input: PlannedAvailabilityDeleteInput!) {
  plannedAvailabilityDelete(input: $input) {
    result
  }
}
    `;
export type DevDeleteTemporaryAvailabilityMutationFn = Apollo.MutationFunction<DevDeleteTemporaryAvailabilityMutation, DevDeleteTemporaryAvailabilityMutationVariables>;

/**
 * __useDevDeleteTemporaryAvailabilityMutation__
 *
 * To run a mutation, you first call `useDevDeleteTemporaryAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDevDeleteTemporaryAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [devDeleteTemporaryAvailabilityMutation, { data, loading, error }] = useDevDeleteTemporaryAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDevDeleteTemporaryAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<DevDeleteTemporaryAvailabilityMutation, DevDeleteTemporaryAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DevDeleteTemporaryAvailabilityMutation, DevDeleteTemporaryAvailabilityMutationVariables>(DevDeleteTemporaryAvailabilityDocument, options);
      }
export type DevDeleteTemporaryAvailabilityMutationHookResult = ReturnType<typeof useDevDeleteTemporaryAvailabilityMutation>;
export type DevDeleteTemporaryAvailabilityMutationResult = Apollo.MutationResult<DevDeleteTemporaryAvailabilityMutation>;
export type DevDeleteTemporaryAvailabilityMutationOptions = Apollo.BaseMutationOptions<DevDeleteTemporaryAvailabilityMutation, DevDeleteTemporaryAvailabilityMutationVariables>;
export const DevUpdateInterviewerDocument = gql`
    mutation DevUpdateInterviewer($input: UpdateInterviewerInput!) {
  updateInterviewer(input: $input) {
    id
  }
}
    `;
export type DevUpdateInterviewerMutationFn = Apollo.MutationFunction<DevUpdateInterviewerMutation, DevUpdateInterviewerMutationVariables>;

/**
 * __useDevUpdateInterviewerMutation__
 *
 * To run a mutation, you first call `useDevUpdateInterviewerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDevUpdateInterviewerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [devUpdateInterviewerMutation, { data, loading, error }] = useDevUpdateInterviewerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDevUpdateInterviewerMutation(baseOptions?: Apollo.MutationHookOptions<DevUpdateInterviewerMutation, DevUpdateInterviewerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DevUpdateInterviewerMutation, DevUpdateInterviewerMutationVariables>(DevUpdateInterviewerDocument, options);
      }
export type DevUpdateInterviewerMutationHookResult = ReturnType<typeof useDevUpdateInterviewerMutation>;
export type DevUpdateInterviewerMutationResult = Apollo.MutationResult<DevUpdateInterviewerMutation>;
export type DevUpdateInterviewerMutationOptions = Apollo.BaseMutationOptions<DevUpdateInterviewerMutation, DevUpdateInterviewerMutationVariables>;
export const UserTermsOfServiceDocument = gql`
    query UserTermsOfService {
  thisInterviewer {
    id
    tosAcceptedAt
  }
}
    `;

/**
 * __useUserTermsOfServiceQuery__
 *
 * To run a query within a React component, call `useUserTermsOfServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTermsOfServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTermsOfServiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTermsOfServiceQuery(baseOptions?: Apollo.QueryHookOptions<UserTermsOfServiceQuery, UserTermsOfServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserTermsOfServiceQuery, UserTermsOfServiceQueryVariables>(UserTermsOfServiceDocument, options);
      }
export function useUserTermsOfServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTermsOfServiceQuery, UserTermsOfServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserTermsOfServiceQuery, UserTermsOfServiceQueryVariables>(UserTermsOfServiceDocument, options);
        }
export type UserTermsOfServiceQueryHookResult = ReturnType<typeof useUserTermsOfServiceQuery>;
export type UserTermsOfServiceLazyQueryHookResult = ReturnType<typeof useUserTermsOfServiceLazyQuery>;
export type UserTermsOfServiceQueryResult = Apollo.QueryResult<UserTermsOfServiceQuery, UserTermsOfServiceQueryVariables>;
export const OrgNameDocument = gql`
    query OrgName {
  thisOrg {
    id
    name
  }
}
    `;

/**
 * __useOrgNameQuery__
 *
 * To run a query within a React component, call `useOrgNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgNameQuery(baseOptions?: Apollo.QueryHookOptions<OrgNameQuery, OrgNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgNameQuery, OrgNameQueryVariables>(OrgNameDocument, options);
      }
export function useOrgNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgNameQuery, OrgNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgNameQuery, OrgNameQueryVariables>(OrgNameDocument, options);
        }
export type OrgNameQueryHookResult = ReturnType<typeof useOrgNameQuery>;
export type OrgNameLazyQueryHookResult = ReturnType<typeof useOrgNameLazyQuery>;
export type OrgNameQueryResult = Apollo.QueryResult<OrgNameQuery, OrgNameQueryVariables>;
export const GetAvailabilityDocument = gql`
    query GetAvailability {
  thisInterviewer {
    ...EditLimitsButton_interviewer
    ...EditAvailabilityDialog_interviewer
    id
    timezoneSettings {
      interviewerTimezone
      calendarTimezone
      useCalendarTimezone
    }
    ...InterviewerWorkHours
    interviewLimits {
      dailyInterviewLimit {
        currentLimit {
          type
          value
        }
        level
      }
      weeklyInterviewLimit {
        currentLimit {
          type
          value
        }
        level
      }
    }
  }
}
    ${EditLimitsButton_InterviewerFragmentDoc}
${EditAvailabilityDialog_InterviewerFragmentDoc}
${InterviewerWorkHoursFragmentDoc}`;

/**
 * __useGetAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailabilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<GetAvailabilityQuery, GetAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailabilityQuery, GetAvailabilityQueryVariables>(GetAvailabilityDocument, options);
      }
export function useGetAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailabilityQuery, GetAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailabilityQuery, GetAvailabilityQueryVariables>(GetAvailabilityDocument, options);
        }
export type GetAvailabilityQueryHookResult = ReturnType<typeof useGetAvailabilityQuery>;
export type GetAvailabilityLazyQueryHookResult = ReturnType<typeof useGetAvailabilityLazyQuery>;
export type GetAvailabilityQueryResult = Apollo.QueryResult<GetAvailabilityQuery, GetAvailabilityQueryVariables>;
export const UpdateInterviewerAvailabilityDocument = gql`
    mutation UpdateInterviewerAvailability($input: UpdateInterviewerInput!) {
  updateInterviewer(input: $input) {
    id
    timezoneSettings {
      interviewerTimezone
      calendarTimezone
      useCalendarTimezone
    }
    interviewLimits {
      dailyInterviewLimit {
        currentLimit {
          type
          value
        }
        level
      }
      weeklyInterviewLimit {
        currentLimit {
          type
          value
        }
        level
      }
    }
    ...InterviewerWorkHours
  }
}
    ${InterviewerWorkHoursFragmentDoc}`;
export type UpdateInterviewerAvailabilityMutationFn = Apollo.MutationFunction<UpdateInterviewerAvailabilityMutation, UpdateInterviewerAvailabilityMutationVariables>;

/**
 * __useUpdateInterviewerAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewerAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewerAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewerAvailabilityMutation, { data, loading, error }] = useUpdateInterviewerAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewerAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewerAvailabilityMutation, UpdateInterviewerAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewerAvailabilityMutation, UpdateInterviewerAvailabilityMutationVariables>(UpdateInterviewerAvailabilityDocument, options);
      }
export type UpdateInterviewerAvailabilityMutationHookResult = ReturnType<typeof useUpdateInterviewerAvailabilityMutation>;
export type UpdateInterviewerAvailabilityMutationResult = Apollo.MutationResult<UpdateInterviewerAvailabilityMutation>;
export type UpdateInterviewerAvailabilityMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewerAvailabilityMutation, UpdateInterviewerAvailabilityMutationVariables>;
export const GetContactInfoDocument = gql`
    query GetContactInfo {
  thisInterviewer {
    id
    email
    linkedinUrl
  }
}
    `;

/**
 * __useGetContactInfoQuery__
 *
 * To run a query within a React component, call `useGetContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetContactInfoQuery, GetContactInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactInfoQuery, GetContactInfoQueryVariables>(GetContactInfoDocument, options);
      }
export function useGetContactInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactInfoQuery, GetContactInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactInfoQuery, GetContactInfoQueryVariables>(GetContactInfoDocument, options);
        }
export type GetContactInfoQueryHookResult = ReturnType<typeof useGetContactInfoQuery>;
export type GetContactInfoLazyQueryHookResult = ReturnType<typeof useGetContactInfoLazyQuery>;
export type GetContactInfoQueryResult = Apollo.QueryResult<GetContactInfoQuery, GetContactInfoQueryVariables>;
export const UpdateInterviewerContactInfoDocument = gql`
    mutation UpdateInterviewerContactInfo($input: UpdateInterviewerInput!) {
  updateInterviewer(input: $input) {
    id
    email
    linkedinUrl
  }
}
    `;
export type UpdateInterviewerContactInfoMutationFn = Apollo.MutationFunction<UpdateInterviewerContactInfoMutation, UpdateInterviewerContactInfoMutationVariables>;

/**
 * __useUpdateInterviewerContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewerContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewerContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewerContactInfoMutation, { data, loading, error }] = useUpdateInterviewerContactInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewerContactInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewerContactInfoMutation, UpdateInterviewerContactInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewerContactInfoMutation, UpdateInterviewerContactInfoMutationVariables>(UpdateInterviewerContactInfoDocument, options);
      }
export type UpdateInterviewerContactInfoMutationHookResult = ReturnType<typeof useUpdateInterviewerContactInfoMutation>;
export type UpdateInterviewerContactInfoMutationResult = Apollo.MutationResult<UpdateInterviewerContactInfoMutation>;
export type UpdateInterviewerContactInfoMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewerContactInfoMutation, UpdateInterviewerContactInfoMutationVariables>;
export const GetExternalInfoDocument = gql`
    query GetExternalInfo {
  thisInterviewer {
    ...ExternalInfo
  }
}
    ${ExternalInfoFragmentDoc}`;

/**
 * __useGetExternalInfoQuery__
 *
 * To run a query within a React component, call `useGetExternalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExternalInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetExternalInfoQuery, GetExternalInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalInfoQuery, GetExternalInfoQueryVariables>(GetExternalInfoDocument, options);
      }
export function useGetExternalInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalInfoQuery, GetExternalInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalInfoQuery, GetExternalInfoQueryVariables>(GetExternalInfoDocument, options);
        }
export type GetExternalInfoQueryHookResult = ReturnType<typeof useGetExternalInfoQuery>;
export type GetExternalInfoLazyQueryHookResult = ReturnType<typeof useGetExternalInfoLazyQuery>;
export type GetExternalInfoQueryResult = Apollo.QueryResult<GetExternalInfoQuery, GetExternalInfoQueryVariables>;
export const UpdateInterviewerExternalInfoDocument = gql`
    mutation UpdateInterviewerExternalInfo($input: UpdateInterviewerInput!) {
  updateInterviewer(input: $input) {
    ...ExternalInfo
  }
}
    ${ExternalInfoFragmentDoc}`;
export type UpdateInterviewerExternalInfoMutationFn = Apollo.MutationFunction<UpdateInterviewerExternalInfoMutation, UpdateInterviewerExternalInfoMutationVariables>;

/**
 * __useUpdateInterviewerExternalInfoMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewerExternalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewerExternalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewerExternalInfoMutation, { data, loading, error }] = useUpdateInterviewerExternalInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewerExternalInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewerExternalInfoMutation, UpdateInterviewerExternalInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewerExternalInfoMutation, UpdateInterviewerExternalInfoMutationVariables>(UpdateInterviewerExternalInfoDocument, options);
      }
export type UpdateInterviewerExternalInfoMutationHookResult = ReturnType<typeof useUpdateInterviewerExternalInfoMutation>;
export type UpdateInterviewerExternalInfoMutationResult = Apollo.MutationResult<UpdateInterviewerExternalInfoMutation>;
export type UpdateInterviewerExternalInfoMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewerExternalInfoMutation, UpdateInterviewerExternalInfoMutationVariables>;
export const UpdateInterviewerAvailabileKeywordsDocument = gql`
    mutation UpdateInterviewerAvailabileKeywords($input: UpdateInterviewerInput!) {
  updateInterviewer(input: $input) {
    id
    keywords {
      available {
        current
        org
        level
      }
    }
  }
}
    `;
export type UpdateInterviewerAvailabileKeywordsMutationFn = Apollo.MutationFunction<UpdateInterviewerAvailabileKeywordsMutation, UpdateInterviewerAvailabileKeywordsMutationVariables>;

/**
 * __useUpdateInterviewerAvailabileKeywordsMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewerAvailabileKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewerAvailabileKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewerAvailabileKeywordsMutation, { data, loading, error }] = useUpdateInterviewerAvailabileKeywordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewerAvailabileKeywordsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewerAvailabileKeywordsMutation, UpdateInterviewerAvailabileKeywordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewerAvailabileKeywordsMutation, UpdateInterviewerAvailabileKeywordsMutationVariables>(UpdateInterviewerAvailabileKeywordsDocument, options);
      }
export type UpdateInterviewerAvailabileKeywordsMutationHookResult = ReturnType<typeof useUpdateInterviewerAvailabileKeywordsMutation>;
export type UpdateInterviewerAvailabileKeywordsMutationResult = Apollo.MutationResult<UpdateInterviewerAvailabileKeywordsMutation>;
export type UpdateInterviewerAvailabileKeywordsMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewerAvailabileKeywordsMutation, UpdateInterviewerAvailabileKeywordsMutationVariables>;
export const UpdateInterviewerAvoidKeywordsDocument = gql`
    mutation UpdateInterviewerAvoidKeywords($input: UpdateInterviewerInput!) {
  updateInterviewer(input: $input) {
    id
    keywords {
      avoid {
        current
        org
        level
      }
    }
  }
}
    `;
export type UpdateInterviewerAvoidKeywordsMutationFn = Apollo.MutationFunction<UpdateInterviewerAvoidKeywordsMutation, UpdateInterviewerAvoidKeywordsMutationVariables>;

/**
 * __useUpdateInterviewerAvoidKeywordsMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewerAvoidKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewerAvoidKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewerAvoidKeywordsMutation, { data, loading, error }] = useUpdateInterviewerAvoidKeywordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewerAvoidKeywordsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewerAvoidKeywordsMutation, UpdateInterviewerAvoidKeywordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewerAvoidKeywordsMutation, UpdateInterviewerAvoidKeywordsMutationVariables>(UpdateInterviewerAvoidKeywordsDocument, options);
      }
export type UpdateInterviewerAvoidKeywordsMutationHookResult = ReturnType<typeof useUpdateInterviewerAvoidKeywordsMutation>;
export type UpdateInterviewerAvoidKeywordsMutationResult = Apollo.MutationResult<UpdateInterviewerAvoidKeywordsMutation>;
export type UpdateInterviewerAvoidKeywordsMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewerAvoidKeywordsMutation, UpdateInterviewerAvoidKeywordsMutationVariables>;
export const UpdateInterviewerOutOfOfficeKeywordsDocument = gql`
    mutation UpdateInterviewerOutOfOfficeKeywords($input: UpdateInterviewerInput!) {
  updateInterviewer(input: $input) {
    id
    keywords {
      outOfOffice {
        current
        org
        level
      }
    }
  }
}
    `;
export type UpdateInterviewerOutOfOfficeKeywordsMutationFn = Apollo.MutationFunction<UpdateInterviewerOutOfOfficeKeywordsMutation, UpdateInterviewerOutOfOfficeKeywordsMutationVariables>;

/**
 * __useUpdateInterviewerOutOfOfficeKeywordsMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewerOutOfOfficeKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewerOutOfOfficeKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewerOutOfOfficeKeywordsMutation, { data, loading, error }] = useUpdateInterviewerOutOfOfficeKeywordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewerOutOfOfficeKeywordsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewerOutOfOfficeKeywordsMutation, UpdateInterviewerOutOfOfficeKeywordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewerOutOfOfficeKeywordsMutation, UpdateInterviewerOutOfOfficeKeywordsMutationVariables>(UpdateInterviewerOutOfOfficeKeywordsDocument, options);
      }
export type UpdateInterviewerOutOfOfficeKeywordsMutationHookResult = ReturnType<typeof useUpdateInterviewerOutOfOfficeKeywordsMutation>;
export type UpdateInterviewerOutOfOfficeKeywordsMutationResult = Apollo.MutationResult<UpdateInterviewerOutOfOfficeKeywordsMutation>;
export type UpdateInterviewerOutOfOfficeKeywordsMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewerOutOfOfficeKeywordsMutation, UpdateInterviewerOutOfOfficeKeywordsMutationVariables>;
export const UpdateInterviewerRecruitingBlocksKeywordsDocument = gql`
    mutation UpdateInterviewerRecruitingBlocksKeywords($input: UpdateInterviewerInput!) {
  updateInterviewer(input: $input) {
    id
    keywords {
      recruitingBlocks {
        current
        org
        level
      }
    }
  }
}
    `;
export type UpdateInterviewerRecruitingBlocksKeywordsMutationFn = Apollo.MutationFunction<UpdateInterviewerRecruitingBlocksKeywordsMutation, UpdateInterviewerRecruitingBlocksKeywordsMutationVariables>;

/**
 * __useUpdateInterviewerRecruitingBlocksKeywordsMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewerRecruitingBlocksKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewerRecruitingBlocksKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewerRecruitingBlocksKeywordsMutation, { data, loading, error }] = useUpdateInterviewerRecruitingBlocksKeywordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewerRecruitingBlocksKeywordsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewerRecruitingBlocksKeywordsMutation, UpdateInterviewerRecruitingBlocksKeywordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewerRecruitingBlocksKeywordsMutation, UpdateInterviewerRecruitingBlocksKeywordsMutationVariables>(UpdateInterviewerRecruitingBlocksKeywordsDocument, options);
      }
export type UpdateInterviewerRecruitingBlocksKeywordsMutationHookResult = ReturnType<typeof useUpdateInterviewerRecruitingBlocksKeywordsMutation>;
export type UpdateInterviewerRecruitingBlocksKeywordsMutationResult = Apollo.MutationResult<UpdateInterviewerRecruitingBlocksKeywordsMutation>;
export type UpdateInterviewerRecruitingBlocksKeywordsMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewerRecruitingBlocksKeywordsMutation, UpdateInterviewerRecruitingBlocksKeywordsMutationVariables>;
export const InterviewerKeywordsDocument = gql`
    query InterviewerKeywords {
  thisInterviewer {
    ...AvailableKeywordsField_interviewer
    ...AvoidKeywordsField_interviewer
    ...OutOfOfficeKeywordsField_interviewer
    ...RecruitingBlocksKeywordsField_interviewer
  }
}
    ${AvailableKeywordsField_InterviewerFragmentDoc}
${AvoidKeywordsField_InterviewerFragmentDoc}
${OutOfOfficeKeywordsField_InterviewerFragmentDoc}
${RecruitingBlocksKeywordsField_InterviewerFragmentDoc}`;

/**
 * __useInterviewerKeywordsQuery__
 *
 * To run a query within a React component, call `useInterviewerKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewerKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewerKeywordsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterviewerKeywordsQuery(baseOptions?: Apollo.QueryHookOptions<InterviewerKeywordsQuery, InterviewerKeywordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewerKeywordsQuery, InterviewerKeywordsQueryVariables>(InterviewerKeywordsDocument, options);
      }
export function useInterviewerKeywordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewerKeywordsQuery, InterviewerKeywordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewerKeywordsQuery, InterviewerKeywordsQueryVariables>(InterviewerKeywordsDocument, options);
        }
export type InterviewerKeywordsQueryHookResult = ReturnType<typeof useInterviewerKeywordsQuery>;
export type InterviewerKeywordsLazyQueryHookResult = ReturnType<typeof useInterviewerKeywordsLazyQuery>;
export type InterviewerKeywordsQueryResult = Apollo.QueryResult<InterviewerKeywordsQuery, InterviewerKeywordsQueryVariables>;
export const GetPlannedAvailabilityDocument = gql`
    query GetPlannedAvailability {
  thisInterviewer {
    id
    plannedAvailability {
      ...PlannedAvailability
    }
    pauseDates {
      start
      end
    }
    workHours {
      orgHours {
        ...WorkHours
      }
    }
  }
}
    ${PlannedAvailabilityFragmentDoc}
${WorkHoursFragmentDoc}`;

/**
 * __useGetPlannedAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetPlannedAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlannedAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlannedAvailabilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlannedAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<GetPlannedAvailabilityQuery, GetPlannedAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlannedAvailabilityQuery, GetPlannedAvailabilityQueryVariables>(GetPlannedAvailabilityDocument, options);
      }
export function useGetPlannedAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlannedAvailabilityQuery, GetPlannedAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlannedAvailabilityQuery, GetPlannedAvailabilityQueryVariables>(GetPlannedAvailabilityDocument, options);
        }
export type GetPlannedAvailabilityQueryHookResult = ReturnType<typeof useGetPlannedAvailabilityQuery>;
export type GetPlannedAvailabilityLazyQueryHookResult = ReturnType<typeof useGetPlannedAvailabilityLazyQuery>;
export type GetPlannedAvailabilityQueryResult = Apollo.QueryResult<GetPlannedAvailabilityQuery, GetPlannedAvailabilityQueryVariables>;
export const UpdateInterviewerPauseDatesDocument = gql`
    mutation UpdateInterviewerPauseDates($input: UpdateInterviewerInput!) {
  updateInterviewer(input: $input) {
    id
    pauseDates {
      start
      end
    }
  }
}
    `;
export type UpdateInterviewerPauseDatesMutationFn = Apollo.MutationFunction<UpdateInterviewerPauseDatesMutation, UpdateInterviewerPauseDatesMutationVariables>;

/**
 * __useUpdateInterviewerPauseDatesMutation__
 *
 * To run a mutation, you first call `useUpdateInterviewerPauseDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterviewerPauseDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterviewerPauseDatesMutation, { data, loading, error }] = useUpdateInterviewerPauseDatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInterviewerPauseDatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterviewerPauseDatesMutation, UpdateInterviewerPauseDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterviewerPauseDatesMutation, UpdateInterviewerPauseDatesMutationVariables>(UpdateInterviewerPauseDatesDocument, options);
      }
export type UpdateInterviewerPauseDatesMutationHookResult = ReturnType<typeof useUpdateInterviewerPauseDatesMutation>;
export type UpdateInterviewerPauseDatesMutationResult = Apollo.MutationResult<UpdateInterviewerPauseDatesMutation>;
export type UpdateInterviewerPauseDatesMutationOptions = Apollo.BaseMutationOptions<UpdateInterviewerPauseDatesMutation, UpdateInterviewerPauseDatesMutationVariables>;
export const CreatePlannedAvailabilityDocument = gql`
    mutation CreatePlannedAvailability($input: PlannedAvailabilityCreateInput!) {
  plannedAvailabilityCreate(input: $input) {
    id
    plannedAvailability {
      ...PlannedAvailability
    }
  }
}
    ${PlannedAvailabilityFragmentDoc}`;
export type CreatePlannedAvailabilityMutationFn = Apollo.MutationFunction<CreatePlannedAvailabilityMutation, CreatePlannedAvailabilityMutationVariables>;

/**
 * __useCreatePlannedAvailabilityMutation__
 *
 * To run a mutation, you first call `useCreatePlannedAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlannedAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlannedAvailabilityMutation, { data, loading, error }] = useCreatePlannedAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlannedAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlannedAvailabilityMutation, CreatePlannedAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlannedAvailabilityMutation, CreatePlannedAvailabilityMutationVariables>(CreatePlannedAvailabilityDocument, options);
      }
export type CreatePlannedAvailabilityMutationHookResult = ReturnType<typeof useCreatePlannedAvailabilityMutation>;
export type CreatePlannedAvailabilityMutationResult = Apollo.MutationResult<CreatePlannedAvailabilityMutation>;
export type CreatePlannedAvailabilityMutationOptions = Apollo.BaseMutationOptions<CreatePlannedAvailabilityMutation, CreatePlannedAvailabilityMutationVariables>;
export const UpdatePlannedAvailabilityDocument = gql`
    mutation UpdatePlannedAvailability($input: PlannedAvailabilityUpdateInput!) {
  plannedAvailabilityUpdate(input: $input) {
    id
    plannedAvailability {
      ...PlannedAvailability
    }
  }
}
    ${PlannedAvailabilityFragmentDoc}`;
export type UpdatePlannedAvailabilityMutationFn = Apollo.MutationFunction<UpdatePlannedAvailabilityMutation, UpdatePlannedAvailabilityMutationVariables>;

/**
 * __useUpdatePlannedAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdatePlannedAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlannedAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlannedAvailabilityMutation, { data, loading, error }] = useUpdatePlannedAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlannedAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlannedAvailabilityMutation, UpdatePlannedAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlannedAvailabilityMutation, UpdatePlannedAvailabilityMutationVariables>(UpdatePlannedAvailabilityDocument, options);
      }
export type UpdatePlannedAvailabilityMutationHookResult = ReturnType<typeof useUpdatePlannedAvailabilityMutation>;
export type UpdatePlannedAvailabilityMutationResult = Apollo.MutationResult<UpdatePlannedAvailabilityMutation>;
export type UpdatePlannedAvailabilityMutationOptions = Apollo.BaseMutationOptions<UpdatePlannedAvailabilityMutation, UpdatePlannedAvailabilityMutationVariables>;
export const DeletePlannedAvailabilityDocument = gql`
    mutation DeletePlannedAvailability($input: PlannedAvailabilityDeleteInput!) {
  plannedAvailabilityDelete(input: $input) {
    result
  }
}
    `;
export type DeletePlannedAvailabilityMutationFn = Apollo.MutationFunction<DeletePlannedAvailabilityMutation, DeletePlannedAvailabilityMutationVariables>;

/**
 * __useDeletePlannedAvailabilityMutation__
 *
 * To run a mutation, you first call `useDeletePlannedAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlannedAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePlannedAvailabilityMutation, { data, loading, error }] = useDeletePlannedAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePlannedAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<DeletePlannedAvailabilityMutation, DeletePlannedAvailabilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePlannedAvailabilityMutation, DeletePlannedAvailabilityMutationVariables>(DeletePlannedAvailabilityDocument, options);
      }
export type DeletePlannedAvailabilityMutationHookResult = ReturnType<typeof useDeletePlannedAvailabilityMutation>;
export type DeletePlannedAvailabilityMutationResult = Apollo.MutationResult<DeletePlannedAvailabilityMutation>;
export type DeletePlannedAvailabilityMutationOptions = Apollo.BaseMutationOptions<DeletePlannedAvailabilityMutation, DeletePlannedAvailabilityMutationVariables>;
export const SaveTermsOfServiceDocument = gql`
    mutation SaveTermsOfService {
  acceptTermsOfService {
    result
  }
}
    `;
export type SaveTermsOfServiceMutationFn = Apollo.MutationFunction<SaveTermsOfServiceMutation, SaveTermsOfServiceMutationVariables>;

/**
 * __useSaveTermsOfServiceMutation__
 *
 * To run a mutation, you first call `useSaveTermsOfServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTermsOfServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTermsOfServiceMutation, { data, loading, error }] = useSaveTermsOfServiceMutation({
 *   variables: {
 *   },
 * });
 */
export function useSaveTermsOfServiceMutation(baseOptions?: Apollo.MutationHookOptions<SaveTermsOfServiceMutation, SaveTermsOfServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTermsOfServiceMutation, SaveTermsOfServiceMutationVariables>(SaveTermsOfServiceDocument, options);
      }
export type SaveTermsOfServiceMutationHookResult = ReturnType<typeof useSaveTermsOfServiceMutation>;
export type SaveTermsOfServiceMutationResult = Apollo.MutationResult<SaveTermsOfServiceMutation>;
export type SaveTermsOfServiceMutationOptions = Apollo.BaseMutationOptions<SaveTermsOfServiceMutation, SaveTermsOfServiceMutationVariables>;
export const SchedulerPermissionDocument = gql`
    query SchedulerPermission {
  actorUserInfo {
    permissions
  }
}
    `;

/**
 * __useSchedulerPermissionQuery__
 *
 * To run a query within a React component, call `useSchedulerPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulerPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulerPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchedulerPermissionQuery(baseOptions?: Apollo.QueryHookOptions<SchedulerPermissionQuery, SchedulerPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchedulerPermissionQuery, SchedulerPermissionQueryVariables>(SchedulerPermissionDocument, options);
      }
export function useSchedulerPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchedulerPermissionQuery, SchedulerPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchedulerPermissionQuery, SchedulerPermissionQueryVariables>(SchedulerPermissionDocument, options);
        }
export type SchedulerPermissionQueryHookResult = ReturnType<typeof useSchedulerPermissionQuery>;
export type SchedulerPermissionLazyQueryHookResult = ReturnType<typeof useSchedulerPermissionLazyQuery>;
export type SchedulerPermissionQueryResult = Apollo.QueryResult<SchedulerPermissionQuery, SchedulerPermissionQueryVariables>;
export const GetInterviewerConfigDocument = gql`
    query GetInterviewerConfig {
  thisOrg {
    id
    fieldPermissions {
      firstName
      lastName
      title
      emailAddress
      linkedinUrl
      timezone
      interviewLoadCapacity
      interviewAvailability
      temporaryAvailability
      pauseFromInterviews
      interviewerKeywords
      profilePicture
      bio
      pronouns
    }
  }
}
    `;

/**
 * __useGetInterviewerConfigQuery__
 *
 * To run a query within a React component, call `useGetInterviewerConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInterviewerConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInterviewerConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInterviewerConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetInterviewerConfigQuery, GetInterviewerConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInterviewerConfigQuery, GetInterviewerConfigQueryVariables>(GetInterviewerConfigDocument, options);
      }
export function useGetInterviewerConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInterviewerConfigQuery, GetInterviewerConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInterviewerConfigQuery, GetInterviewerConfigQueryVariables>(GetInterviewerConfigDocument, options);
        }
export type GetInterviewerConfigQueryHookResult = ReturnType<typeof useGetInterviewerConfigQuery>;
export type GetInterviewerConfigLazyQueryHookResult = ReturnType<typeof useGetInterviewerConfigLazyQuery>;
export type GetInterviewerConfigQueryResult = Apollo.QueryResult<GetInterviewerConfigQuery, GetInterviewerConfigQueryVariables>;