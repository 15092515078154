import { InterviewTokensQuery } from 'src/generated/mloop-graphql';

import placeholders from '../utils/placeholders';

import { EMPTY_MERGED_OPTIONS, InterviewPlaceholderFillerOptions } from './types';

const regex = /<a\b[^>]*>(.*?)<\/a>/g;

/**
 * TOKENS TO DISPLAY
 * Note Only add if eligible for display
 * */
export enum Tokens {
  VIDEO_MEETING_LINK = 'VIDEO_MEETING_LINK',
  INTERVIEW_DAYS = 'INTERVIEW_DAYS',
  CODE_LINK = 'CODE_LINK',
  INTERVIEWER_NAMES = 'INTERVIEWER_NAMES',
  INTERVIEW_KIT_LINK = 'INTERVIEW_KIT_LINK',
  INTERVIEW_NAME = 'INTERVIEW_NAME',
  INTERVIEW_SCHEDULE = 'INTERVIEW_SCHEDULE',
  INTERVIEW_SCHEDULE_START_TIME = 'INTERVIEW_SCHEDULE_START_TIME',
  INTERNAL_INTERVIEW_SCHEDULE = 'INTERNAL_INTERVIEW_SCHEDULE',
  INTERVIEW_SCORECARD_NAME = 'INTERVIEW_SCORECARD_NAME',
  ZOOM_MEETING_INFO = 'ZOOM_MEETING_INFO',
  ZOOM_MEETING_DIAL_IN_INFO = 'ZOOM_MEETING_DIAL_IN_INFO',
}

/**
 * TOKENS FETCHED
 * Tokens displayed are a subset of the tokens fetched
 */

const getMergeTokenSeprator = (token: keyof InterviewPlaceholderFillerOptions): string => {
  switch (token) {
    case 'INTERVIEW_DAYS':
    case 'INTERVIEW_SCHEDULE_START_TIME':
    case 'CODE_LINK':
      return ', ';
    case 'ZOOM_MEETING_DIAL_IN_INFO':
      return '<br/>';
    case 'ZOOM_MEETING_INFO':
      return '<br/><br/>';
    case 'VIDEO_MEETING_LINK':
      return '\n';
    default:
      return ' ';
  }
};

const getOption = (
  token: keyof InterviewPlaceholderFillerOptions,
  option: string | null,
  options: InterviewPlaceholderFillerOptions
  // eslint-disable-next-line max-params
) => {
  switch (token) {
    case 'ZOOM_MEETING_INFO':
      if (options.INTERVIEW_NAME && option) {
        return `${options.INTERVIEW_NAME}<br/>${option}`;
      }
      return option;
      break;
    case 'INTERVIEW_SCORECARD_NAME':
    case 'INTERVIEW_KIT_LINK':
      option = null;
      break;
    case 'CODE_LINK':
      if (option) {
        const regexVal = option.match(regex) || [];
        option = regexVal[0] || '';
      }
      break;
    default:
      break;
  }
  return option;
};

const getMergedOption = (
  key: keyof InterviewPlaceholderFillerOptions,
  mergedOptions: InterviewPlaceholderFillerOptions,
  options: InterviewPlaceholderFillerOptions // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
string | null => {
  const mergedOption = mergedOptions[key] || '';
  const option = getOption(key, options[key] || '', options);
  return mergedOption && option ? `${mergedOption}${getMergeTokenSeprator(key)}${option}` : mergedOption || option;
};

export default class InterviewPlaceholderFiller {
  static getFilledText = (input: string, options: InterviewPlaceholderFillerOptions): string => {
    return placeholders(input, options);
  };

  static getOptions = (data: InterviewTokensQuery): InterviewPlaceholderFillerOptions => {
    if (!data || !Object.keys(data).length) return { ...EMPTY_MERGED_OPTIONS };
    return {
      INTERVIEW_NAME: data.INTERVIEW_NAME?.INTERVIEW_NAME,
      INTERVIEWER_NAMES: data.INTERVIEWER_NAMES?.INTERVIEWER_NAMES,
      INTERVIEW_DAYS: data.INTERVIEW_DAYS?.INTERVIEW_DAYS,
      INTERVIEW_SCHEDULE: data.INTERVIEW_SCHEDULE?.INTERVIEW_SCHEDULE,
      INTERNAL_INTERVIEW_SCHEDULE: data.INTERNAL_INTERVIEW_SCHEDULE?.INTERNAL_INTERVIEW_SCHEDULE,
      INTERVIEW_SCHEDULE_START_TIME: data.INTERVIEW_SCHEDULE_START_TIME?.INTERVIEW_SCHEDULE_START_TIME,
      INTERVIEW_SCORECARD_NAME: data.INTERVIEW_SCORECARD_NAME?.INTERVIEW_SCORECARD_NAME,
      INTERVIEW_KIT_LINK: data.INTERVIEW_KIT_LINK?.INTERVIEW_KIT_LINK,
      CODE_LINK: data.CODE_LINK?.CODE_LINK,
      VIDEO_MEETING_LINK: data.VIDEO_MEETING_LINK?.VIDEO_MEETING_LINK,
      ZOOM_MEETING_DIAL_IN_INFO: data.ZOOM_MEETING_DIAL_IN_INFO?.ZOOM_MEETING_DIAL_IN_INFO,
      ZOOM_MEETING_INFO: data.ZOOM_MEETING_INFO?.ZOOM_MEETING_INFO,
      INTERVIEWER_TRAINING_INFO: data.INTERVIEWER_TRAINING_INFO?.INTERVIEWER_TRAINING_INFO || '',
      SCHEDULE_INTERVIEWER_NAMES: data.SCHEDULE_INTERVIEWER_NAMES?.INTERVIEWER_NAMES || undefined,
    };
  };

  static getInterviewMergedTokens = (data: InterviewPlaceholderFillerOptions): InterviewPlaceholderFillerOptions => {
    if (!data || !Object.keys(data).length) return { ...EMPTY_MERGED_OPTIONS };
    const mergedOptions: InterviewPlaceholderFillerOptions = {
      INTERVIEWER_TRAINING_INFO: '', // not sure why this is required...
    };
    Object.keys(data).forEach((slotId) => {
      const options = data[slotId];
      Object.keys(options).forEach((key: keyof InterviewPlaceholderFillerOptions) => {
        if (key === 'INTERVIEW_SCHEDULE' || key === 'INTERNAL_INTERVIEW_SCHEDULE') {
          mergedOptions[key] = options[key];
        } else if (key === 'INTERVIEW_NAME' || key === 'INTERVIEW_SCHEDULE_START_TIME' || key === 'INTERVIEW_DAYS') {
          mergedOptions[key] = mergedOptions[key] ? mergedOptions[key] : options[key];
        } else if (key === 'INTERVIEWER_NAMES') {
          mergedOptions[key] = options.SCHEDULE_INTERVIEWER_NAMES;
        } else if (key === 'INTERVIEWER_TRAINING_INFO') {
          mergedOptions[key] = getMergedOption(key, mergedOptions, options) || '';
        } else {
          mergedOptions[key] = getMergedOption(key, mergedOptions, options) || undefined;
        }
      });
    });
    return mergedOptions;
  };
}
