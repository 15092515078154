import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { MeetingRoomSuggestionInterface } from 'src/store/slices/schedule-communications';

import IsoTimestamp from 'src/types/IsoTimestamp';

import { RichInterviewer, UIInterviewSeat } from 'src/utils/api/getScheduleOptions';

export enum ScheduleUpdateType {
  EDIT = 'edit',
  NEW = 'new',
  DELETE = 'delete',
}

export type SchdeuleUpdateInterviewName = {
  type: 'SchdeuleUpdateInterviewName';
  scheduleId: string;
  applicationStageInterviewId: string;
  name: string;
};

export type SchdeuleUpdateInterviewTime = {
  type: 'SchdeuleUpdateInterviewTime';
  scheduleId: string;
  applicationStageInterviewId: string;
  startAt: string;
  endAt: string;
  duration: number;
};

export type ScheduleUpdateScorecard = {
  type: 'ScheduleUpdateScorecard';
  scheduleId: string;
  applicationStageInterviewId: string;

  // Ats unification
  atsInterviewDefinitionId?: string;
  atsJobId?: string;
  atsJobStageId?: string;
};

export type ScheduleUpdateInterviewSeat = {
  type: 'ScheduleUpdateInterviewSeat';
  scheduleId: string;
  applicationStageInterviewId: string;
  applicationStageInterviewInterviewerId: string;
  updateType: ScheduleUpdateType;
  seat: UIInterviewSeat | null;
  interviewer: RichInterviewer;
  prevInterviewerId: string;
};

export type ScheduleUpdateNewInterview = {
  type: 'ScheduleUpdateNewInterview';
  scheduleId: string;
  applicationStageInterviewId: string;

  name: string;
  startAt: IsoTimestamp;
  endAt: IsoTimestamp;
  duration: number;

  atsInterviewDefinitionId?: string;
  atsJobId?: string;
  atsJobStageId?: string;
};

export type ScheduleUpdateEditInterview = {
  type: 'ScheduleUpdateEditInterview';
  scheduleId: string;
  applicationStageInterviewId: string;
};

export type ScheduleUpdateDeleteInterview = {
  type: 'ScheduleUpdateDeleteInterview';
  scheduleId: string;
  applicationStageInterviewId: string;
};

export type ScheduleUpdateCodingUrl = {
  type: 'ScheduleUpdateCodingUrl';
  updateType: ScheduleUpdateType;
  scheduleId: string;
  applicationStageInterviewId: string;
};

export type ScheduleUpdateVideoUrl = {
  type: 'ScheduleUpdateVideoUrl';
  updateType: ScheduleUpdateType;
  scheduleId: string;
  applicationStageInterviewId: string;
};

export type ScheduleUpdateInterviewPrivacy = {
  type: 'ScheduleUpdateInterviewPrivacy';
  updateType: ScheduleUpdateType;
  scheduleId: string;
  applicationStageInterviewId: string;
};

export type ScheduleUpdateAddInterviewMeetingRoom = {
  type: 'ScheduleUpdateAddInterviewMeetingRoom';
  scheduleId: string;
  applicationStageInterviewId: string;
  roomSuggestion: MeetingRoomSuggestionInterface;
};

export type ScheduleUpdateDeleteInterviewMeetingRoom = {
  type: 'ScheduleUpdateDeleteInterviewMeetingRoom';
  scheduleId: string;
  applicationStageInterviewId: string;
  roomSuggestion: MeetingRoomSuggestionInterface;
};

export type ScheduleUpdateInterviewerOptional = {
  type: 'ScheduleUpdateInterviewerOptional';
  scheduleId: string;
  applicationStageInterviewId: string;
  applicationStageInterviewerId: string;
  isOptional: boolean;
};

export type ScheduleUpdateInterviewHiddenFromCandidate = {
  type: 'ScheduleUpdateInterviewHiddenFromCandidate';
  scheduleId: string;
  applicationStageInterviewId: string;
  isHiddenFromCandidate: boolean;
};

export type ScheduleUpdate =
  | SchdeuleUpdateInterviewName
  | SchdeuleUpdateInterviewTime
  | ScheduleUpdateScorecard
  | ScheduleUpdateInterviewSeat
  | ScheduleUpdateNewInterview
  | ScheduleUpdateEditInterview
  | ScheduleUpdateDeleteInterview
  | ScheduleUpdateCodingUrl
  | ScheduleUpdateInterviewPrivacy
  | ScheduleUpdateVideoUrl
  | ScheduleUpdateAddInterviewMeetingRoom
  | ScheduleUpdateDeleteInterviewMeetingRoom
  | ScheduleUpdateInterviewerOptional
  | ScheduleUpdateInterviewHiddenFromCandidate;

export type SchdeuleUpdateState = {
  byId: { [scheduleId: string]: ScheduleUpdate[] };
};

const getInitialState = (): SchdeuleUpdateState => {
  return {
    byId: {},
  };
};

const scheduleUpdateStateSlice = createSlice({
  name: 'schdeuleUpdate',
  initialState: getInitialState(),
  reducers: {
    clear() {
      return getInitialState();
    },

    addScheduleUpdate: (state: SchdeuleUpdateState, action: PayloadAction<ScheduleUpdate>) => {
      const { payload } = action;
      const scheduleUpdates = state.byId[payload.scheduleId] ?? [];

      // Always push any updates, this is so that we can provide undo functionality.
      scheduleUpdates.push(payload);

      state.byId[payload.scheduleId] = scheduleUpdates;
    },

    setScheduleUpdates: (
      state: SchdeuleUpdateState,
      action: PayloadAction<{ scheduleId: string; updates: ScheduleUpdate[] }>
    ) => {
      const { scheduleId, updates } = action.payload;
      state.byId[scheduleId] = updates;
    },
  },
});

export const { reducer } = scheduleUpdateStateSlice;
export default scheduleUpdateStateSlice;
