import { useMemo } from 'react';

import { QueryResult, gql } from '@apollo/client';
import { IsoTimestamp, assertIsoTimestamp } from '@modernloop/shared/datetime';
import { find } from 'lodash';

import {
  Duration,
  DurationOrgPref,
  JsonOrgPref,
  Maybe,
  OrgJsonPref,
  OrgPrefsDataFragment,
  OrgPrefsQuery,
  StringOrgPref,
  useOrgPrefsQuery,
} from 'src/generated/mloop-graphql';

import { OrgPrefName } from 'src/utils/api/org';

import { AllOrgPrefName } from './types';

export { OrgPrefName } from 'src/utils/api/org';

type QueryResultType = Omit<QueryResult, 'data'>;

export const OrgPrefsFragment = gql`
  fragment OrgPrefsData on OrgPref {
    orgId
    prefName
    json
    boolValue
    stringValue
    intValue
    stringArray
    jsonArray
    isLocked
    timestamp
    duration {
      seconds
      nanos
    }
    jsonPref {
      ... on InterviewLimit {
        type
        limit
      }
      ... on MinimumInterviewerLoadLimitOrgPref {
        interviews
        minutes
      }
      ... on InterviewMeetingLocationSettingPref {
        interviewMeetingLocationType
        dynamicHost
        hostEmployeeId
        remoteVideoMeetingHostUserId
        customLocation
      }
    }
  }
`;

export const OrgPrefsGqlQuery = gql`
  ${OrgPrefsFragment}
  query OrgPrefs {
    thisOrg {
      id
      orgPrefs {
        ...OrgPrefsData
      }
    }
  }
`;

function useOrgPrefs(): QueryResult<OrgPrefsQuery> {
  return useOrgPrefsQuery();
}

// This single fetch intentially fetches all the org prefrences so we can cache.
export function useOrgPref(pref: AllOrgPrefName | OrgPrefName): [OrgPrefsDataFragment | null, QueryResultType] {
  const { data, ...rest } = useOrgPrefs();

  return [data?.thisOrg?.orgPrefs ? find(data.thisOrg.orgPrefs, { prefName: pref }) || null : null, rest];
}

export function useOrgPrefBool(pref: OrgPrefName): [boolean | null, QueryResultType] {
  const [value, rest] = useOrgPref(pref);
  return [value?.boolValue !== null && value?.boolValue !== undefined ? value?.boolValue : null, rest];
}

export function useOrgPrefString(
  pref: OrgPrefName | StringOrgPref,
  defaultValue: string | null = null
): [string | null, QueryResultType] {
  const [value, rest] = useOrgPref(pref);
  return [value?.stringValue || defaultValue, rest];
}

export function useOrgPrefInt(pref: OrgPrefName, defaultValue: number | null = null): [number | null, QueryResultType] {
  const [value, rest] = useOrgPref(pref);
  return [value?.intValue || defaultValue, rest];
}

export function useOrgPrefStringArray(
  pref: OrgPrefName,
  defaultValue: Maybe<string>[] | null = null
): [Maybe<string>[] | null, QueryResultType] {
  const [value, rest] = useOrgPref(pref);

  return [value?.stringArray || defaultValue, rest];
}

/**
 * @deprecated Use useOrgPrefJsonTyped instead. Make sure to add the necessary data fetch to the query
 * and update to use the GQL type in the consumer.
 */
export function useOrgPrefJson_DEPRECATED<T>(
  pref: OrgPrefName,
  defaultValue: T | null = null
): [T | null, QueryResultType] {
  const [value, rest] = useOrgPref(pref);

  const newValue = useMemo(() => {
    if (!value?.json) return defaultValue;
    return JSON.parse(value.json);
  }, [defaultValue, value]);

  return [newValue, rest];
}

export function useOrgPrefJsonArray<T>(
  pref: OrgPrefName,
  defaultValue: T[] | null = null
): [T[] | null, QueryResultType] {
  const [value, rest] = useOrgPref(pref);
  if (!value?.jsonArray) {
    return [defaultValue, rest];
  }

  const vals = value.jsonArray.map((val: string) => {
    return JSON.parse(val);
  }) as unknown as T[];

  return [vals, rest];
}

export function useOrgPrefTimestamp(
  pref: OrgPrefName,
  defaultValue?: IsoTimestamp
): [IsoTimestamp | null, QueryResultType] {
  const [value, rest] = useOrgPref(pref);
  const timestamp = value?.timestamp || defaultValue;
  return [timestamp ? assertIsoTimestamp(value?.timestamp ?? defaultValue) : null, rest];
}

/**
 * Helper function to get a typed JSON pref using the GQL pref, not json string conversion.
 * When using this function, make sure to add the necessary data fetch to the query.
 */
export function useOrgPrefJsonTyped<T extends OrgJsonPref>(
  pref: JsonOrgPref,
  defaultValue: T | null = null
): [T | null, QueryResultType] {
  const [value, rest] = useOrgPref(pref);

  const newValue = value?.jsonPref ?? defaultValue;

  return [newValue as T, rest];
}

export function useOrgPrefDuration(
  pref: DurationOrgPref,
  defaultValue: Duration | null = null
): [Duration | null, QueryResultType] {
  const [value, rest] = useOrgPref(pref);
  return [value?.duration || defaultValue, rest];
}
