/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments, MaybeTooltip, MoreMenuButton } from '@modernloop/shared/components';
import { IsoTimestamp, assertIsoTimestamp, isAfterNow } from '@modernloop/shared/datetime';
import { CopyIcon, EditIcon, EventWithCancelIcon, LinkIcon, OpenInNewTabIcon } from '@modernloop/shared/icons';
import { copyRichTextToClipboard } from '@modernloop/shared/utils';
import { Avatar, Box, ListItemIcon, ListItemText, ListSubheader, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  ApplicationStatus,
  RenderType,
  ScheduleContentInterviewMenu_CandidateEventFragment,
  ScheduleContentInterviewMenu_InterviewFragment,
  useScheduleContentInterviewMenuQuery,
} from 'src/generated/mloop-graphql';

import { ZoomIcon } from 'src/components/icons';

import useHistory from 'src/hooks/useHistory';
import useIsApplicationRejected from 'src/hooks/useIsApplicationRejected';
import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';
import useUserId from 'src/hooks/useUserId';

import getUpdateFlowUrl from 'src/urls/getUpdateFlowUrl';

// eslint-disable-next-line modernloop/restrict-imports.cjs
import useCalendarPermissionData from 'src/views-new/Common/CalendarPermissionDialog/useCalendarPermissionData';
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

import CalendarPermissionDialog from '../ScheduleContentActions/CalendarPermissionDialog';

import ScheduleContentCancelInterviewDialog from './ScheduleContentCancelInterviewDialog';

export enum MenuOptionsType {
  EditInterview = 'EDIT_INTERVIEW',
  CancelInterivew = 'CANCEL_INTERVIEW',
  CopyCodingLink = 'COPY_CODING_LINK',
  CopyMeetingLink = 'COPY_MEETING_LINK',
  CopyMeetingInfo = 'COPY_MEETING_INFO',
  ViewCandidateEvent = 'VIEW_CANDIDATE_EVENT',
  ViewInterviewerEvent = 'VIEW_INTERVIEWER_EVENT',
}

type Props = {
  applicationStageId: string;
  applicationStatus: ApplicationStatus;
  frozenAt?: IsoTimestamp;
};

type Fragments = {
  interview: ScheduleContentInterviewMenu_InterviewFragment;
  candidateEvent: ScheduleContentInterviewMenu_CandidateEventFragment | undefined;
};

const ScheduleContentInterviewMenu: FCWithFragments<Fragments, Props> = ({
  applicationStageId,
  applicationStatus,
  interview,
  candidateEvent,
  frozenAt,
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = useLogEvent();
  const userId = useUserId();
  const editInterviewUrl = getUpdateFlowUrl(applicationStageId, { stageInterviewId: interview.id });
  const isApplicationRejected = useIsApplicationRejected(applicationStatus);
  const isUpcoming = isAfterNow(assertIsoTimestamp(interview.startAt));

  const [showDeleteInterviewConfirmation, setShowDeleteInterviewConfirmation] = useState(false);
  const [showCalendarPermissionDialog, setShowCalendarPermissionDialog] = useState(false);

  const calendarDisplayData = useCalendarPermissionData({ applicationStageId });

  const { loading: zoomMeetingInfoLoading, data } = useScheduleContentInterviewMenuQuery({
    variables: {
      input: {
        renderType: RenderType.Html,
        id: interview.id,
      },
      zoomMeetingInfoInput: {
        userId: interview.zoomHostUserId,
        joinUrl: interview.videoMeetingUrl || '',
        meetingId: `${interview.zoomMeetingId || ''}`,
        password: interview.zoomPassword || '',
        pstnPassword: `${interview.zoomPstnPassword || ''}`,
        dialInfo: interview.zoomDialInInfo,
      },
      zoomMeetingUserId: interview.zoomHostUserId || '',
    },
    skip: interview.videoMeetingUrl?.toLowerCase().indexOf('zoom') === -1,
  });

  const handleMenuOptionSelect = useCallback(
    async (type: MenuOptionsType) => {
      logEvent(LoggerEvent.CLIENT_SCHEDULE_OBJECT_INTERVIEW_MENU_OPTION_SELECTED, {
        userId,
        interviewId: interview.id,
        type,
      });

      switch (type) {
        case MenuOptionsType.EditInterview: {
          if (!calendarDisplayData.hasPermission) {
            setShowCalendarPermissionDialog(true);
            return;
          }

          SidePanelManager.closeSidePanel();
          history.push(editInterviewUrl);
          break;
        }
        case MenuOptionsType.CancelInterivew: {
          setShowDeleteInterviewConfirmation(true);
          break;
        }
        case MenuOptionsType.CopyCodingLink: {
          if (!interview.codingInterviewUrl) return;
          try {
            navigator.clipboard.writeText(interview.codingInterviewUrl);
            enqueueSnackbar('Coding link copied', { variant: 'success' });
          } catch (error) {
            enqueueSnackbar('Failed to copy coding link', { variant: 'error' });
          }
          break;
        }
        case MenuOptionsType.CopyMeetingLink: {
          if (!interview.videoMeetingUrl) return;
          try {
            navigator.clipboard.writeText(interview.videoMeetingUrl);
            enqueueSnackbar('Meeting link copied', { variant: 'success' });
          } catch (error) {
            enqueueSnackbar('Failed to copy meeting link', { variant: 'error' });
          }
          break;
        }
        case MenuOptionsType.CopyMeetingInfo: {
          if (!interview.videoMeetingUrl) return;

          try {
            const zoomMeetingInfoHtml = `<div>
                ${data?.templateToken?.ZOOM_MEETING_INFO}
                <br />
                ${data?.templateToken?.ZOOM_MEETING_DIAL_IN_INFO}
              </div>
            `;
            await copyRichTextToClipboard(zoomMeetingInfoHtml);
            enqueueSnackbar('Meeting info copied', { variant: 'success' });
          } catch (error) {
            enqueueSnackbar(`Failed to copy meeting info: ${error.message}`, { variant: 'error' });
          }
          break;
        }
        case MenuOptionsType.ViewCandidateEvent: {
          if (!candidateEvent?.googleEventUrl) return;
          window.open(candidateEvent?.googleEventUrl, '_blank');
          break;
        }
        case MenuOptionsType.ViewInterviewerEvent: {
          if (!interview.googleEventUrl) return;
          window.open(interview.googleEventUrl, '_blank');
          break;
        }
        default:
      }
    },
    [
      calendarDisplayData.hasPermission,
      candidateEvent?.googleEventUrl,
      data?.templateToken?.ZOOM_MEETING_DIAL_IN_INFO,
      data?.templateToken?.ZOOM_MEETING_INFO,
      editInterviewUrl,
      enqueueSnackbar,
      history,
      interview.codingInterviewUrl,
      interview.googleEventUrl,
      interview.id,
      interview.videoMeetingUrl,
      logEvent,
      userId,
    ]
  );

  const editMenuOptions = useMemo(() => {
    if (frozenAt) return null;
    return isUpcoming && !isApplicationRejected ? (
      <MenuItem
        id={MenuOptionsType.EditInterview}
        key={MenuOptionsType.EditInterview}
        data-testid={`schedule-content-interview-menu-item-${MenuOptionsType.EditInterview}`}
        onClick={() => handleMenuOptionSelect(MenuOptionsType.EditInterview)}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>Edit interview</ListItemText>
      </MenuItem>
    ) : (
      <MenuItem
        id={MenuOptionsType.CancelInterivew}
        key={MenuOptionsType.CancelInterivew}
        data-testid={`schedule-content-interview-menu-item-${MenuOptionsType.CancelInterivew}`}
        onClick={() => handleMenuOptionSelect(MenuOptionsType.CancelInterivew)}
      >
        <ListItemIcon>
          <EventWithCancelIcon />
        </ListItemIcon>
        <ListItemText>Cancel interview</ListItemText>
      </MenuItem>
    );
  }, [frozenAt, handleMenuOptionSelect, isApplicationRejected, isUpcoming]);

  const locationMenuOptions = useMemo(() => {
    // eslint-disable-next-line modernloop/validate-component-definition.cjs
    const getMenuItem = ({
      id,
      text,
      subText,
      icon,
      disabled,
    }: {
      id: MenuOptionsType;
      text: string;
      subText?: string;
      icon: JSX.Element;
      disabled: boolean;
    }) => {
      return (
        <MenuItem
          id={id}
          key={id}
          data-testid={`schedule-content-interview-menu-item-${id}`}
          onClick={() => handleMenuOptionSelect(id)}
          disabled={disabled}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            secondary={
              subText ? (
                <MaybeTooltip
                  data-testid={`schedule-content-interview-menu-item-secondary-text-${id}`}
                  title={subText}
                  label={subText}
                  labelProps={{ variant: 'body2' }}
                />
              ) : undefined
            }
          >
            {text}
          </ListItemText>
          <ListItemIcon>
            <CopyIcon />
          </ListItemIcon>
        </MenuItem>
      );
    };

    const jsx: JSX.Element[] = [];

    let meetingHostName = interview.videoMeetingHostEmployee?.fullName || '';
    if (data?.zoomUsersByIds?.[0]?.firstName || data?.zoomUsersByIds?.[0]?.lastName) {
      meetingHostName = `${data?.zoomUsersByIds?.[0]?.firstName} ${data?.zoomUsersByIds?.[0]?.lastName}`.trim();
    } else if (data?.zoomUsersByIds?.[0]?.email) {
      meetingHostName = data?.zoomUsersByIds?.[0]?.email;
    }

    const meetingHostText = meetingHostName ? `Host: ${meetingHostName}` : undefined;

    if (interview.videoMeetingUrl?.toLowerCase().indexOf('zoom') !== -1) {
      jsx.push(<ListSubheader key="list-sub-header-zoom">Zoom</ListSubheader>);
      jsx.push(
        getMenuItem({
          id: MenuOptionsType.CopyMeetingLink,
          text: 'Copy meeting link',
          subText: meetingHostText,
          icon: <ZoomIcon />,
          disabled: false,
        })
      );
      jsx.push(
        getMenuItem({
          id: MenuOptionsType.CopyMeetingInfo,
          text: 'Copy meeting info',
          subText: meetingHostText,
          icon: <ZoomIcon />,
          disabled: zoomMeetingInfoLoading,
        })
      );
    } else if (interview.videoMeetingUrl?.toLowerCase().indexOf('meet.google.com') !== -1) {
      jsx.push(<ListSubheader key="list-sub-header-google-meets">Google meets</ListSubheader>);
      jsx.push(
        getMenuItem({
          id: MenuOptionsType.CopyMeetingLink,
          text: 'Copy meeting link',
          subText: meetingHostText,
          icon: <Avatar src="/static/images/integrations/google-meets.png" />,
          disabled: false,
        })
      );
    } else if (interview.videoMeetingUrl?.toLowerCase().indexOf('teams.microsoft.com') !== -1) {
      jsx.push(<ListSubheader key="list-bus-header-microsoft-teams">Microsoft teams</ListSubheader>);
      jsx.push(
        getMenuItem({
          id: MenuOptionsType.CopyMeetingLink,
          text: 'Copy meeting link',
          subText: meetingHostText,
          icon: <Avatar src="/static/images/integrations/microsoft-teams.png" />,
          disabled: false,
        })
      );
    } else if (interview.videoMeetingUrl?.length) {
      jsx.push(<ListSubheader key="list-bus-header-custom-meeting-link">Custom meeting link</ListSubheader>);
      jsx.push(
        getMenuItem({
          id: MenuOptionsType.CopyMeetingLink,
          text: 'Copy meeting link',
          icon: <LinkIcon />,
          disabled: false,
        })
      );
    }

    return jsx;
  }, [
    data?.zoomUsersByIds,
    handleMenuOptionSelect,
    interview.videoMeetingHostEmployee?.fullName,
    interview.videoMeetingUrl,
    zoomMeetingInfoLoading,
  ]);

  const calendarMenuOptions = useMemo(() => {
    // eslint-disable-next-line max-params
    const getMenuItem = (id: MenuOptionsType, text: string, icon: JSX.Element) => {
      return (
        <MenuItem
          id={id}
          key={id}
          data-testid={`schedule-content-interview-menu-item-${id}`}
          onClick={() => handleMenuOptionSelect(id)}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{text}</ListItemText>
          <ListItemIcon>
            <OpenInNewTabIcon />
          </ListItemIcon>
        </MenuItem>
      );
    };

    const jsx: JSX.Element[] = [];

    if (interview.googleEventUrl?.indexOf('google') !== -1) {
      jsx.push(<ListSubheader key="list-bus-header-google calendar">Google calendar</ListSubheader>);
    } else if (interview.googleEventUrl?.indexOf('outlook') !== -1) {
      jsx.push(<ListSubheader key="list-bus-header-outlook">Outlook</ListSubheader>);
    }

    if (!interview.isHiddenFromCandidate) {
      if (candidateEvent?.googleEventUrl?.indexOf('google') !== -1) {
        jsx.push(
          getMenuItem(
            MenuOptionsType.ViewCandidateEvent,
            'View candidate event',
            <Avatar src="/static/images/integrations/google-calendar.png" />
          )
        );
      } else if (candidateEvent?.googleEventUrl?.indexOf('outlook') !== -1) {
        jsx.push(
          getMenuItem(
            MenuOptionsType.ViewCandidateEvent,
            'View candidate event',
            <Avatar src="/static/images/integrations/outlook-calendar.png" />
          )
        );
      }
    }

    if (interview.googleEventUrl?.indexOf('google') !== -1) {
      jsx.push(
        getMenuItem(
          MenuOptionsType.ViewInterviewerEvent,
          'View interviewer event',
          <Avatar src="/static/images/integrations/google-calendar.png" />
        )
      );
    } else if (interview.googleEventUrl?.indexOf('outlook') !== -1) {
      jsx.push(
        getMenuItem(
          MenuOptionsType.ViewInterviewerEvent,
          'View interviewer event',
          <Avatar src="/static/images/integrations/microsoft-teams.png" />
        )
      );
    }

    return jsx;
  }, [
    candidateEvent?.googleEventUrl,
    handleMenuOptionSelect,
    interview.googleEventUrl,
    interview.isHiddenFromCandidate,
  ]);

  const codingLinkMenuOption = useMemo(() => {
    const jsx: JSX.Element[] = [];

    if (interview.codingInterviewUrl) {
      let iconUrl = '';
      if (interview.codingInterviewUrl.indexOf('coderpad') !== -1) {
        iconUrl = '/static/images/integrations/CoderPad.png';
      } else if (interview.codingInterviewUrl.indexOf('codesignal') !== -1) {
        iconUrl = '/static/images/integrations/codesignal.png';
      } else if (interview.codingInterviewUrl.indexOf('codility') !== -1) {
        // Example Codility link - https://app.codility.com/test/XYZ123/",
        iconUrl = '/static/images/integrations/codility.png';
      } else if (interview.codingInterviewUrl.indexOf('hr.gs') !== -1) {
        // Example HackerRank link - https://hr.gs/123456
        iconUrl = '/static/images/integrations/hackerrank.png';
      }

      jsx.push(<ListSubheader key="list-sub-header-copy-coding-link">Coding link</ListSubheader>);
      jsx.push(
        <MenuItem
          id={MenuOptionsType.CopyCodingLink}
          key={MenuOptionsType.CopyCodingLink}
          data-testid={`schedule-content-interview-menu-item-${MenuOptionsType.CopyCodingLink}`}
          onClick={() => handleMenuOptionSelect(MenuOptionsType.CopyCodingLink)}
        >
          <ListItemIcon>
            <Avatar src={iconUrl} />
          </ListItemIcon>
          <ListItemText>Copy coding link</ListItemText>
        </MenuItem>
      );
    }

    return jsx;
  }, [handleMenuOptionSelect, interview.codingInterviewUrl]);

  return (
    <Box key="schedule-content-interview-menu-button">
      <MoreMenuButton data-testid="schedule-content-interview-menu-button" sx={{ width: '320px' }}>
        {editMenuOptions}

        {locationMenuOptions}

        {calendarMenuOptions}

        {codingLinkMenuOption}
      </MoreMenuButton>

      {showDeleteInterviewConfirmation && (
        <ScheduleContentCancelInterviewDialog
          applicationStageInterviewId={interview.id}
          onClose={() => setShowDeleteInterviewConfirmation(false)}
        />
      )}

      {showCalendarPermissionDialog && (
        <CalendarPermissionDialog
          fullName={calendarDisplayData.scheduler?.fullName || ''}
          imageUrl={calendarDisplayData.scheduler?.slackImageUrl || ''}
          email={calendarDisplayData.scheduler?.email || ''}
          candidateCalendarLabel={calendarDisplayData.candidateCalendar}
          candidateId={calendarDisplayData.candidateId}
          candidateEventCalendarId={calendarDisplayData.candidateEventCalendarId}
          interviewerEventCalendarId={calendarDisplayData.interviewerEventCalendarId}
          candidateCalendarFound={calendarDisplayData.candidateCalendarFound}
          interviewerCalendarLabel={calendarDisplayData.interviewerCalendar}
          interviewerCalendarFound={calendarDisplayData.interviewerCalendarFound}
          onClose={() => setShowCalendarPermissionDialog(false)}
        />
      )}
    </Box>
  );
};

ScheduleContentInterviewMenu.fragments = {
  interview: gql`
    fragment ScheduleContentInterviewMenu_interview on ApplicationStageInterview {
      id
      startAt
      googleEventUrl
      videoMeetingUrl
      zoomHostUserId
      zoomDialInInfo
      zoomMeetingId
      zoomPassword
      zoomPstnPassword
      codingInterviewUrl
      isHiddenFromCandidate
      videoMeetingHostEmployee {
        id
        fullName
      }
    }
  `,
  candidateEvent: gql`
    fragment ScheduleContentInterviewMenu_candidateEvent on ApplicationStageCandidateEvent {
      id
      googleEventUrl
    }
  `,
};

export default ScheduleContentInterviewMenu;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ScheduleContentInterviewMenuQuery = gql`
  query ScheduleContentInterviewMenu(
    $input: TemplateTokenInput!
    $zoomMeetingInfoInput: TokenZoomMeetingInput!
    $zoomMeetingUserId: String!
  ) {
    templateToken(input: $input) {
      ZOOM_MEETING_INFO(input: $zoomMeetingInfoInput)
      ZOOM_MEETING_DIAL_IN_INFO(input: $zoomMeetingInfoInput)
    }
    zoomUsersByIds(input: { zoomUserIds: [$zoomMeetingUserId] }) {
      zoomUserId
      firstName
      lastName
      email
    }
  }
`;
