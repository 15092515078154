import { CodeLinkType } from 'src/generated/mloop-graphql';

function getCodingUrlTitle(type: CodeLinkType): string {
  switch (type) {
    case CodeLinkType.Codility:
      return 'Codility';
    case CodeLinkType.Coderpad:
      return 'CoderPad';
    case CodeLinkType.Codesignal:
      return 'CodeSignal';
    case CodeLinkType.Hackerrank:
      return 'HackerRank';
    default:
      return '';
  }
}

export default getCodingUrlTitle;
