import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Avatar, Box, Grid, List, ListItem, ListSubheader } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';

import ZeroState from 'src/components/ZeroState';
import Checkbox from 'src/components/checkbox';
import { SearchIcon } from 'src/components/icons';
import Label from 'src/components/label';

import { Theme } from 'src/theme/type';

export type FilterListItem<T> = {
  id: string;
  label: string;
  image_url?: string;
  isSubheader?: boolean;
  isSelected?: boolean;
  data?: T;
  isDisabled?: boolean;
  tag?: JSX.Element;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type FilterListProps<T> = {
  items: FilterListItem<T>[];
  onItemClick: (data: T, selected: boolean) => void;
};

const useListItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '6px',
      cursor: 'pointer',
      padding: '8px',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '& .MuiCheckbox-root': {
        padding: '0',
        color: theme.palette.primary.main,
      },
    },
  })
);

const useAvatarStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '20px',
      height: '20px',
      borderRadius: '6px',
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      height: '350px',
      overflowY: 'auto',
    },
    listItemBox: {
      width: '100%',
    },
    subHeader: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      padding: '7px 8px',
      userSelect: 'none',
    },
    listItem: {
      padding: '0',
    },
  })
);

const FilterList = <T,>({ items, onItemClick }: FilterListProps<T>): JSX.Element => {
  const classes = useStyles();
  const avatarClasses = useAvatarStyles();
  const listItemClasses = useListItemStyles();

  if (items.length === 0) {
    return <ZeroState icon={<SearchIcon color="high-contrast-grey" fontSize={75} />} label="No interviewers found" />;
  }

  return (
    <List disablePadding className={classes.list}>
      {items.map((value) => {
        if (value.isSubheader) {
          return (
            <ListSubheader key={value.id} disableSticky disableGutters>
              <Label variant="captions" className={classes.subHeader}>
                {value.label}
              </Label>
            </ListSubheader>
          );
        }
        return (
          <ListItem key={value.id} disableGutters classes={listItemClasses}>
            <Box
              className={classes.listItemBox}
              onClick={() => {
                if (!value || !value.data || !!value.isDisabled) return;
                onItemClick(value.data, !value.isSelected);
              }}
            >
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Grid spacing={1} container alignItems="center">
                    <Grid item>
                      <Checkbox checked={value.isSelected} disabled={!!value.isDisabled} />
                    </Grid>
                    <Grid item>
                      <Avatar src={value.image_url} classes={avatarClasses} />
                    </Grid>
                    <Grid item>
                      <Label
                        variant="body"
                        className={classes.listItem}
                        color={value.isDisabled ? 'high-contrast-grey' : 'foreground'}
                      >
                        {value.label}
                      </Label>
                    </Grid>
                  </Grid>
                </Grid>
                {value.tag && <Grid item>{value.tag}</Grid>}
              </Grid>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

export default FilterList;
