import React from 'react';
import type { FC } from 'react';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

import ApolloProvider from 'src/contexts/ApolloProvider';
import OnboardingProvider from 'src/contexts/OnboardingProvider';
import ReactQueryClientProvider from 'src/contexts/ReactQueryClientProvider';
import { SettingsProvider } from 'src/contexts/SettingsContext';

import MaintenancePage from 'src/views-new/MaintenancePage';

import routes, { renderRoutes } from 'src/routes';
import store, { persistor } from 'src/store';

const RecruitingApp: FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SettingsProvider>
          <OnboardingProvider>
            <ReactQueryClientProvider>
              <ApolloProvider>
                <MaintenancePage>
                  <>{renderRoutes(routes)}</>
                </MaintenancePage>
              </ApolloProvider>
            </ReactQueryClientProvider>
          </OnboardingProvider>
        </SettingsProvider>
      </PersistGate>
    </Provider>
  );
};

export default RecruitingApp;
