import { useMutation, useQueryClient } from 'react-query';

import { IntegrationType } from 'src/generated/mloop-graphql';

import { putIntegration } from './integrationPut';
import { QUERY_KEY } from './types';

const INTEGRATION_TYPE: IntegrationType = IntegrationType.CodesignalApiKey;

// eslint-disable-next-line @typescript-eslint/ban-types
function putCoderSignalIntegration(apiKey: string): Promise<{}> {
  return putIntegration({
    integration_type: INTEGRATION_TYPE,
    codesignal: {
      api_key: apiKey,
    },
  });
}

export default function useCodeSignalIntegrationPut() {
  const queryClient = useQueryClient();
  return useMutation((apiKey: string) => putCoderSignalIntegration(apiKey), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
      queryClient.invalidateQueries([QUERY_KEY, INTEGRATION_TYPE]);
    },
  });
}
