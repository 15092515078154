import { useMemo } from 'react';

import { QueryResult, gql } from '@apollo/client';

import {
  OrganizationTemplatesQuery,
  OrganizationTemplatesQueryVariables,
  Sort,
  TemplateType,
  TemplatesOrderByProperty,
  useDefaultTemplateForTypesQuery as useDefaultTemplateForTypesGQL,
  useOrganizationTemplatesQuery as useOrganizationTemplatesGQL,
} from 'src/generated/mloop-graphql';

import { PAGE_SIZE } from 'src/constants';

export const TemplateAttachementFragment = gql`
  fragment Attachments on Template {
    attachments {
      name
      path
      size
      timestamp
    }
  }
`;

export const TemplateFrag = gql`
  fragment TemplateFrag on Template {
    id
    type
    name
    updatedAt
    subject
    body
    isOrganizationDefault
    liveSettingsUsages
  }
`;

export const GQLDefaultTemplateForTypes = gql`
  query DefaultTemplateForTypes($input: TemplatesInput!) {
    templates(input: $input) {
      items {
        ...TemplateFrag
      }
    }
  }
`;

export const GQLOrganizationTemplatesQuery = gql`
  query OrganizationTemplates($input: TemplatesInput!) {
    templates(input: $input) {
      items {
        ...TemplateFrag
      }
    }
  }
`;

export const GQLOrganizationTemplateCreate = gql`
  mutation OrganizationTemplateCreate($input: TemplateCreateInput!) {
    templateCreate(input: $input) {
      template {
        ...TemplateFrag
        ...Attachments
      }
    }
  }
`;

export const GQLOrganizationTemplateUpdate = gql`
  mutation OrganizationTemplateUpdate($input: TemplateUpdateInput!) {
    templateUpdate(input: $input) {
      template {
        ...TemplateFrag
        ...Attachments
      }
    }
  }
`;

export const GQLOrganizationTemplateDelete = gql`
  mutation OrganizationTemplateDelete($id: uuid!) {
    templateDelete(id: $id) {
      template {
        id
      }
    }
  }
`;

export const GQLTemplateAttachementsQuerey = gql`
  query TemplateAttachements($id: uuid!) {
    template(id: $id) {
      id
      ...Attachments
    }
  }
`;

export interface OrganizationTemplatesQueryprops {
  sortByName: Sort;
  search?: string;
  types?: TemplateType[];
  showDefaults?: boolean;
}

export const useOrganizationTemplatesQuery = ({
  sortByName,
  search,
  types,
  showDefaults,
}: OrganizationTemplatesQueryprops): QueryResult<OrganizationTemplatesQuery, OrganizationTemplatesQueryVariables> => {
  return useOrganizationTemplatesGQL({
    variables: {
      input: {
        pageInput: {
          limit: 500,
          offset: 0,
        },
        orderBy: {
          property: TemplatesOrderByProperty.Name,
          direction: sortByName,
        },
        text: search,
        showDefault: showDefaults || undefined,
        types: types || [],
      },
    },
    fetchPolicy: 'cache-and-network',
  });
};

export type DefaultTemplateIdMap = {
  [TemplateType.CandidateConfirmation]?: string;
  [TemplateType.CandidateInvite]?: string;
  [TemplateType.SlackChannelMessage]?: string;
  [TemplateType.InterviewerInvite]?: string;
  [TemplateType.DebriefCalendarInvite]?: string;
  [TemplateType.DebriefConfirmationConversationMessage]?: string;
};

export const useDefaultTemplateForTypeQuery = (
  templateTypes?: TemplateType[]
): [DefaultTemplateIdMap, Omit<QueryResult, 'data'>] => {
  const { data, ...rest } = useDefaultTemplateForTypesGQL({
    variables: {
      input: {
        pageInput: {
          limit: PAGE_SIZE,
          offset: 0,
        },
        showDefault: true,
        types: templateTypes,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const defaultTemplateIdMap: DefaultTemplateIdMap = useMemo(() => {
    const reults: DefaultTemplateIdMap = {};
    const templates = data?.templates?.items;
    if (templates?.length) {
      templates.forEach((template) => {
        reults[template.type || ''] = template.id;
      });
    }
    return reults;
  }, [data]);
  return [defaultTemplateIdMap, rest];
};
