import React, { FC, useMemo } from 'react';

import { createTheme } from '@modernloop/shared/theme';
import { ThemeProvider, useTheme } from '@mui/material';

// If the theme matches the current theme, we don't need to wrap the children in a ThemeProvider
// Otherwise, we need to wrap the children in a ThemeProvider
const ConditionalThemeProvider: FC = ({ children }) => {
  const theme = useTheme();
  const sharedTheme = useMemo(() => createTheme(), []);

  // This is the hacky part. We're comparing the secondary color of the current theme and shared theme because
  // the secondary color is is different between the two themes.
  if (theme.palette.secondary.main === sharedTheme.palette.secondary.main) {
    return <>{children}</>;
  }

  return <ThemeProvider theme={sharedTheme}>{children}</ThemeProvider>;
};

export default ConditionalThemeProvider;
