import { ThemeOptions } from '@mui/material';

/**
 * View ListItem props and classes at https://mui.com/material-ui/api/list-item/#css
 */
export const list: ThemeOptions['components'] = {
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiListItem: {
    defaultProps: {
      disablePadding: true,
      disableGutters: true,
    },
    styleOverrides: {},
  },
  MuiListItemButton: {
    defaultProps: {
      disableTouchRipple: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.spacing(0.75),
        padding: theme.spacing(0.375, 1.5),
        gap: '8px',
      }),
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: '20px',
      },
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: 'transparent',
        padding: theme.spacing(0, 1.5),
        fontSize: theme.typography.body2.fontSize,
        lineHeight: '24px',
        fontWeight: theme.typography.fontWeightBold,
      }),
    },
  },
};
