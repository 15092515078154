import { useCallback } from 'react';

import { TaskListDocument } from 'src/generated/mloop-graphql';

import { useApolloRefetchQuery } from 'src/hooks/useApolloQuery';

const useRefetchTaskListQuery = () => {
  const apolloRefetchQuery = useApolloRefetchQuery();

  return useCallback(async () => {
    await apolloRefetchQuery([TaskListDocument]);
  }, [apolloRefetchQuery]);
};

export default useRefetchTaskListQuery;
