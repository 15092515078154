import React, { FC } from 'react';

import { IsoTimestamp } from '@modernloop/shared/datetime';
import { Stack, Typography } from '@mui/material';

import { useDurationLabel } from 'src/components/DurationLabel';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

type Props = {
  actionsJSX: JSX.Element | null;
  heading: string;
  selfScheduleOptions?: {
    createdAt: IsoTimestamp | undefined;
    selfScheduleEmailSentAt?: IsoTimestamp | undefined;
    creatorName: string;
  };
};

const RejectedScheduleAlert: FC<Props> = ({ actionsJSX, heading, selfScheduleOptions }) => {
  const emailSentAtDurationLabel = useDurationLabel(selfScheduleOptions?.selfScheduleEmailSentAt);
  const durationLabel = useDurationLabel(selfScheduleOptions?.createdAt);

  return (
    <ConditionalThemeProvider>
      <Stack>
        <Typography color="error" variant="subtitle1">
          {heading}
        </Typography>

        {selfScheduleOptions?.selfScheduleEmailSentAt && (
          <Typography color="error" variant="caption">
            A link was sent to the candidate by {selfScheduleOptions.creatorName} {emailSentAtDurationLabel}.
          </Typography>
        )}
        {!selfScheduleOptions?.selfScheduleEmailSentAt && (
          <Typography color="error" variant="caption">
            Request created {durationLabel} by {selfScheduleOptions?.creatorName}.
          </Typography>
        )}
        {actionsJSX}
      </Stack>
    </ConditionalThemeProvider>
  );
};

export default RejectedScheduleAlert;
