import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CircleWithSmallCheckIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 16 16'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM11.5303 6.53033C11.8232 6.23744 11.8232 5.76256 11.5303 5.46967C11.2374 5.17678 10.7626 5.17678 10.4697 5.46967L7 8.93934L5.53033 7.46967C5.23744 7.17678 4.76256 7.17678 4.46967 7.46967C4.17678 7.76256 4.17678 8.23744 4.46967 8.53033L6.46967 10.5303C6.76256 10.8232 7.23744 10.8232 7.53033 10.5303L11.5303 6.53033Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
