import { useEffect } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';

import { UseCandidateCalendarEventTemplateContent_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import { updateCandidateCalendarEventContent } from 'src/store/actions/schedule-communications';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { useDispatch } from 'src/store';

import useLocationUrl from '../TemplateContent/useLocationUrl';
import useScheduleContent from '../TemplateContent/useScheduleContent';

type Fragments = {
  interviewPlan: UseCandidateCalendarEventTemplateContent_InterviewPlanFragment | undefined;
};

type Props = {
  templateId?: string;
};

/**
 * Should only trigger when filledTemplate content changes.
 */
const useCandidateCalendarEventTemplateContent: FunctionWithFragments<Fragments, Props, boolean> = (
  { interviewPlan },
  { templateId }
) => {
  const dispatch = useDispatch();

  const scheduleFlowData = useScheduleFlowData();
  const { candidateTimezone } = scheduleFlowData;

  const { filledTemplate, selectedScheduleId, schedule, loading } =
    useScheduleContent({ interviewPlan }, { templateId, timezone: candidateTimezone }) || {};
  const location = useLocationUrl(selectedScheduleId);

  useEffect(() => {
    if (templateId && filledTemplate && selectedScheduleId) {
      dispatch(
        updateCandidateCalendarEventContent(selectedScheduleId, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          schedule: schedule as any,
          body: filledTemplate?.body || '',
          subject: filledTemplate?.subject || '',
          templateID: templateId,
          location,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filledTemplate, selectedScheduleId]);

  return loading;
};

useCandidateCalendarEventTemplateContent.fragments = {
  interviewPlan: gql`
    fragment useCandidateCalendarEventTemplateContent_interviewPlan on JobStage {
      id
      ...useScheduleContent_interviewPlan
    }
  `,
};

export default useCandidateCalendarEventTemplateContent;
