import React, { FC, useMemo, useState } from 'react';

import { CreateType } from 'src/components/Dialogs/ShareDialog';

import TaskShareDialog from './TaskShareDialog';

type TaskCreateState = {
  taskId: string | null;
  type: CreateType | null;
  isShareModalOpen: boolean;
};

export const ShareModalContextDefault: {
  onTaskShare?: (type: CreateType, taskId: string) => void;
} = {};
export const TaskShareModalContext = React.createContext(ShareModalContextDefault);

const TaskShareDialogHandler: FC = ({ children }) => {
  const [taskCreateState, setTaskCreateState] = useState<TaskCreateState>({
    taskId: null,
    type: null,
    isShareModalOpen: false,
  });

  const shareContext = useMemo(() => {
    const context = { ...ShareModalContextDefault };
    context.onTaskShare = (createType, createdTaskId) => {
      setTaskCreateState({
        taskId: createdTaskId,
        type: createType,
        isShareModalOpen: true,
      });
    };

    return context;
  }, []);
  return (
    <>
      <TaskShareModalContext.Provider value={shareContext}>{children}</TaskShareModalContext.Provider>
      {taskCreateState.isShareModalOpen && taskCreateState.taskId && taskCreateState.type && (
        <TaskShareDialog
          taskId={taskCreateState.taskId}
          type={taskCreateState.type}
          onClose={() => setTaskCreateState({ taskId: null, type: null, isShareModalOpen: false })}
        />
      )}
    </>
  );
};

export default TaskShareDialogHandler;
