import React, { useState } from 'react';

import { Dialog } from '@modernloop/shared/components';
import { JumpIcon } from '@modernloop/shared/icons';
import { logDatadogError } from '@modernloop/shared/utils';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';

import AtsName from 'src/entities/Ats/AtsName';

import { useOrgAtsService } from 'src/hooks/atsService';

import useCheckCreateCandidatePermission from './useCheckCreateCandidatePermission';

enum PermissionCheckStatus {
  Pending = 'Pending',
  Success = 'Success',
  Failed = 'Failed',
}

type Props = {
  jobId: string;
  onHasPermission: () => void;
  onClose: () => void;
  atsPermissionGrantUrl: string;
  permissionsListText: string;
  notAnAdminHelpText: string;
  isLoading?: boolean;
};

const BaseGreenhouseAshbyPermissionDialog = ({
  jobId,
  onHasPermission,
  onClose,
  atsPermissionGrantUrl,
  permissionsListText,
  notAnAdminHelpText,
  isLoading,
}: Props) => {
  const atsService = useOrgAtsService();
  const [loading, setLoading] = useState(false);
  const [permissionCheckStatus, setPermissionCheckStatus] = useState(PermissionCheckStatus.Pending);
  const [testCandidateAtsUrl, setTestCandidateAtsUrl] = useState<string | undefined>();

  const { enqueueSnackbar } = useSnackbar();

  const checkCreateCandidatePermission = useCheckCreateCandidatePermission(jobId);

  const handleCheckPermission = async () => {
    try {
      setLoading(true);
      const { hasPermission: permission, testCandidateAtsUrl: newTestCandidateAtsUrl } =
        await checkCreateCandidatePermission();
      setPermissionCheckStatus(permission ? PermissionCheckStatus.Success : PermissionCheckStatus.Failed);
      setTestCandidateAtsUrl(newTestCandidateAtsUrl || undefined);
    } catch (error) {
      enqueueSnackbar('Failed to check permissions', { variant: 'error' });
      logDatadogError(error, { jobId });
    } finally {
      setLoading(false);
    }
  };

  const permissionsList = (
    <List sx={{ listStyleType: 'disc', margin: (theme) => theme.spacing(0, 2), padding: 0 }}>
      <ListItem sx={{ display: 'list-item' }}>{permissionsListText}</ListItem>
    </List>
  );

  return (
    <Dialog
      open
      maxWidth="xs"
      onClose={onClose}
      title={`We need additional permissions in ${atsService?.name} to continue`}
      decoration={
        <img
          style={{ width: '64px', height: '64px' }}
          src="/static/images/scheduling/warning_64.png"
          alt="Changing calender warning"
        />
      }
      cancelOptions={{
        label: 'Cancel',
        onClick: onClose,
      }}
      submitOptions={{
        label: 'Continue',
        onClick: onHasPermission,
        isDisabled: permissionCheckStatus !== PermissionCheckStatus.Success,
      }}
    >
      <Stack spacing={1.5}>
        <Stack>
          <Typography variant="subtitle1">Step 1</Typography>
          <Typography>Please grant the following permissions:</Typography>
        </Stack>

        <Alert severity="warning">
          <AlertTitle>Candidates</AlertTitle>
          {permissionsList}
        </Alert>

        <Typography variant="body2" color="text.secondary">
          {notAnAdminHelpText}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          Need help?{' '}
          <Link href={ZenDeskHelpCenterUrl.SOURCING_LINK_ATS_INTEGRATION} target="_blank">
            Learn more
          </Link>{' '}
          about permissions
        </Typography>

        <Box>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoading}
            href={atsPermissionGrantUrl}
            target="_blank"
            endIcon={<JumpIcon />}
          >
            Grant permission on <AtsName />
          </Button>
        </Box>

        <Divider flexItem />

        <Stack>
          <Typography variant="subtitle1">Step 2</Typography>
          <Typography>Check if permissions are setup correctly</Typography>
        </Stack>

        {permissionCheckStatus === PermissionCheckStatus.Pending && (
          <Alert severity="info">
            <Typography variant="body2">
              We will create a candidate called ”ModernLoop Test Candidate” to test the permissions in <AtsName />.
            </Typography>
            <br />
            <Typography variant="body2">Please note you will need to delete this test candidate manually.</Typography>
          </Alert>
        )}

        {permissionCheckStatus === PermissionCheckStatus.Success && (
          <Alert
            severity="success"
            action={
              testCandidateAtsUrl ? (
                <Link
                  href={testCandidateAtsUrl}
                  target="_blank"
                  sx={{ flexShrink: 0, display: 'flex', gap: 0.5, mr: 3 }}
                  underline="hover"
                >
                  <JumpIcon color="success" />
                  <Typography color="success.dark" variant="body2">
                    See test candidate
                  </Typography>
                </Link>
              ) : undefined
            }
          >
            <AlertTitle>Success!</AlertTitle>
            <Typography variant="body2">Your permissions are setup correctly.</Typography>
          </Alert>
        )}

        {permissionCheckStatus !== PermissionCheckStatus.Success && (
          <Box>
            <Button
              color="primary"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={24} /> : undefined}
              onClick={handleCheckPermission}
            >
              Check permissions
            </Button>
          </Box>
        )}

        {permissionCheckStatus === PermissionCheckStatus.Failed && (
          <Alert severity="error">
            <Typography variant="body2">We could not find the following permissions:</Typography>
            {permissionsList}
          </Alert>
        )}
      </Stack>
    </Dialog>
  );
};

export default BaseGreenhouseAshbyPermissionDialog;
