import { useMemo } from 'react';

import { uniqBy } from 'lodash';

import { Calendar } from 'src/utils/api/getCalendars';

import { EMPTY_ARRAY, PAGE_SIZE } from 'src/constants';

import useCalendars from './useCalendars';

type HasCalendarAccessInternalReturnType = {
  loading: boolean;
  error: boolean;
  data: Record<string, boolean>;
};

/**
 * Hook to check if the user has access to the requested calendars for the given permission
 */
const useHasCalendarAccess = (calendarIds: string[], readOnly: boolean): HasCalendarAccessInternalReturnType => {
  const { data, isLoading, isError } = useCalendars(PAGE_SIZE, readOnly);

  const calendars = useMemo(() => {
    if (!data?.pages?.length) return EMPTY_ARRAY;

    return uniqBy(data.pages.map((p) => p.calendar).flat(), (c) => c.id);
  }, [data?.pages]);

  // Build a map of calendarIds to boolean indicating if the user has access to the calendar, only of the calendar permissions requested
  const calendarAccess = useMemo(() => {
    const accessMap = {};
    for (const calendarId of calendarIds) {
      accessMap[calendarId] = calendars.some((cal: Calendar) => cal.id === calendarId);
    }
    return accessMap;
  }, [calendars, calendarIds]);

  return { loading: isLoading, error: isError, data: calendarAccess };
};

type HasCalendarAccessReturnType = {
  loading: boolean;
  error: boolean;
  hasAccess: boolean;
};

/**
 * Hook to check if the current user has WRITE access to the requested calendars
 */
export const useHasCalendarWriteAccess = (calendarId: string): HasCalendarAccessReturnType => {
  const { data, loading, error } = useHasCalendarAccess([calendarId], false);
  return { hasAccess: data[calendarId], loading, error };
};

/**
 * Hook to check if the current user has READ access to the requested calendars
 */
export const useHasCalendarReadAccess = (calendarId: string): HasCalendarAccessReturnType => {
  const { data, loading, error } = useHasCalendarAccess([calendarId], true);
  return { hasAccess: data[calendarId], loading, error };
};
