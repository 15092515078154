import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
import { last, sortBy } from 'lodash';

import { ScheduleTaskStatusSubText_TaskFragment, TaskStatus } from 'src/generated/mloop-graphql';

import useDurationLabel from 'src/components/DurationLabel/useDurationLabel';
import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleTaskStatusSubTextFragmentProps = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/component-with-fragments.cjs
  task: ScheduleTaskStatusSubText_TaskFragment;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const ScheduleTaskStatusSubText: FCWithFragments<ScheduleTaskStatusSubTextFragmentProps> = ({ task }) => {
  /**
   * This is a temporary work around to get the latest data.
   * BE is going to add a comparator and send the sorted array soon.
   */
  const taskAvailabilityRequest = task?.availabilityRequests
    ? last(
        sortBy(task.availabilityRequests, (availabilityRequest) => {
          return new Date(availabilityRequest.createdAt);
        })
      )
    : null;

  /**
   * This is a temporary work around to get the latest data.
   * BE is going to add a comparator and send the sorted array soon.
   */
  const taskSelfScheduleRequest = task.selfScheduleRequests
    ? last(
        sortBy(task.selfScheduleRequests, (selfScheduleRequest) => {
          return new Date(selfScheduleRequest.createdAt);
        })
      )
    : null;

  const durationLabel = useDurationLabel(taskAvailabilityRequest?.createdAt || taskSelfScheduleRequest?.createdAt);

  // TODO: (hemant) - Add created by user name once we have it in availabilityRequest & selfScheduleRequest

  const label = useMemo(() => {
    switch (task.status) {
      case TaskStatus.WaitingForCandidate: {
        if (taskAvailabilityRequest?.id) {
          return `Availability request created ${durationLabel}`;
        }
        if (taskSelfScheduleRequest?.id) {
          return `Self-schedule request created ${durationLabel}`;
        }
        return null;
      }
      case TaskStatus.Scheduled:
      case TaskStatus.NeedsAvailability:
      case TaskStatus.ReadyToSchedule:
      case TaskStatus.Hold:
      case TaskStatus.ReadyToSendToCandidate:
      case TaskStatus.Completed:
      case TaskStatus.Canceled:
      default:
        return null;
    }
  }, [durationLabel, task.status, taskAvailabilityRequest?.id, taskSelfScheduleRequest?.id]);

  if (!label) return null;

  return <Label variant="captions">{label}</Label>;
};

ScheduleTaskStatusSubText.fragments = {
  task: gql`
    fragment ScheduleTaskStatusSubText_task on Task {
      id
      status
      availabilityRequests {
        id
        createdAt
      }
      selfScheduleRequests {
        id
        createdAt
        creatorEmployeeId
      }
    }
  `,
};

export default ScheduleTaskStatusSubText;
