export const neutral = {
  0: '#000000',
  5: '#0C0E11',
  10: '#191C22',
  15: '#24282E',
  16: '#2C3036',
  17: '#2A2E34',
  18: '#282C32',
  19: '#262A30',
  20: '#2E3238',
  25: '#3A3D43',
  30: '#45494E',
  35: '#51545A',
  40: '#5D6066',
  50: '#76797F',
  60: '#909399',
  70: '#ADAFB4',
  80: '#C8CBD0',
  90: '#E5E8EC',
  95: '#F5F7FA',
  96: '#F7F9FC',
  97: '#F9FBFD',
  98: '#FAFCFE',
  99: '#FBFDFF',
  100: '#FFFFFF',
};
