import { ThemeOptions } from '@mui/material';

/**
 * View Autocomplete props and classes at https://mui.com/material-ui/api/autocomplete/
 */
export const autocomplete: ThemeOptions['components'] = {
  MuiAutocomplete: {
    styleOverrides: {
      popper: {
        marginTop: '4px!important',
        marginBottom: '4px!important',
      },
    },
  },
};
