import { getTZAbbr } from '@modernloop/shared/datetime';

import { InterviewEvent } from 'src/utils/api/getScheduleOptions';
import { formatToTimeZone } from 'src/utils/dateUtils';

/**
 * @deprecated
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getEventTimeRange = (startAt: string, endAt: string, timezone: string): string => {
  const sameMeridiem =
    formatToTimeZone(new Date(startAt), 'a', timezone) === formatToTimeZone(new Date(endAt), 'a', timezone);
  const startHasMinutes = formatToTimeZone(new Date(startAt), 'mm', timezone) !== '00';
  const endHasMinutes = formatToTimeZone(new Date(endAt), 'mm', timezone) !== '00';
  const startFormat = startHasMinutes ? 'h:mm' : `h${sameMeridiem ? '' : ' a'}`;
  const endFormat = endHasMinutes ? 'h:mm a' : 'h a';
  return `${formatToTimeZone(new Date(startAt), startFormat, timezone)} - ${formatToTimeZone(
    new Date(endAt),
    endFormat,
    timezone
  )} ${getTZAbbr(timezone)}`;
};

/**
 *  10:30 am - 12 pm
 *  12 - 12:30 pm
 *  9:45 - 10:30 am
 */
const renderEventTimeRange = (event: InterviewEvent, timezone: string): string => {
  return getEventTimeRange(event.startAt, event.endAt, timezone);
};

export default renderEventTimeRange;
