import { ThemeOptions } from '@mui/material';

/**
 * View Avatar props and classes at https://mui.com/material-ui/api/avatar/#css
 */
export const avatar: ThemeOptions['components'] = {
  MuiAvatar: {
    defaultProps: {
      variant: 'rounded',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        height: '20px',
        width: '20px',
        ...theme.typography.caption,
        backgroundColor: theme.palette.primary.main,
      }),
    },
  },
};
