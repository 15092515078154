import { UseQueryResult, useQuery } from 'react-query';

import { utcToZonedTime } from 'date-fns-tz';

import { ListLoadLimitResponse, getLoadAndLimit } from 'src/utils/api/getListLoadLimit';
import { InterviewerLoadAndLimit } from 'src/utils/api/getScheduleOptions';
import { keysToCamel_TYPE_UNSAFE_DO_NOT_USE } from 'src/utils/snakeToCamelCaseUtil';

const useFetchLoadAndLimit = (
  date: number,
  timezone: string,
  employeeIds: string[] // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
UseQueryResult<InterviewerLoadAndLimit[], Error> => {
  return useQuery(['list_load_limit', date, timezone, employeeIds], async () => {
    if (date === 0 || employeeIds.length === 0) return [];

    const response = await getLoadAndLimit(utcToZonedTime(date, timezone), timezone, employeeIds);
    return (keysToCamel_TYPE_UNSAFE_DO_NOT_USE(response.data) as ListLoadLimitResponse).loadAndLimit;
  });
};

export default useFetchLoadAndLimit;
