/* eslint-disable import/prefer-default-export */
import { v4 as uuid } from 'uuid';

import {
  addJobStageInterviewGroup,
  deleteJobStageInterviewGroup,
  updateJobStageInterviewGroup,
} from 'src/store/actions/job-stage-interview-group';
import { getJobStageById } from 'src/store/selectors/job-stage';
import { getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';
import { getJobStageInterviewGroupById } from 'src/store/selectors/job-stage-interview-group';
import jobStageSlice, { JobStage, UpdateJobStagePayload } from 'src/store/slices/job-stage';
import { JobStageInterviewGroup } from 'src/store/slices/job-stage-interview-group';

import { AppThunk } from 'src/store';

import { updateJobStageInterview } from './job-stage-interview';

export const addJobStage =
  (jobStage: JobStage): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageSlice.actions.addJobStage(jobStage));
  };

export const updateJobStage =
  (jobStage: UpdateJobStagePayload): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageSlice.actions.updateJobStage(jobStage));
  };

export const deleteJobStage =
  (jobStageId: string): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageSlice.actions.deleteJobStage(jobStageId));
  };

export const addJobStageError =
  (jobStageId: string, error: string): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageSlice.actions.addJobStageError({ jobStageId, error }));
  };

export const deleteJobStageError =
  (jobStageId: string): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageSlice.actions.deleteJobStageError(jobStageId));
  };

export const addJobStageLoading =
  (jobStageId: string, loading: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageSlice.actions.addJobStageLoading({ jobStageId, loading }));
  };

export const deleteJobStageLoading =
  (jobStageId: string): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageSlice.actions.deleteJobStageLoading(jobStageId));
  };

export const moveJobStageInterviewGroupId =
  (
    jobStageId: string,
    groupId: string,
    position: number
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch) => {
    dispatch(jobStageSlice.actions.moveJobStageInterviewGroupId({ jobStageId, groupId, position }));
  };

export const groupJobStageInterviewGroup =
  (
    jobStageId: string,
    sourceGroupIndex: number,
    destGroupIndex: number
    // eslint-disable-next-line max-params
  ): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();

    // We want to keep visual order while grouping interviews so smaller index is always destination and delete the source.
    // Other way around will work too 🙂.
    let sourceIndex = sourceGroupIndex;
    let destIndex = destGroupIndex;

    if (sourceGroupIndex < destGroupIndex) {
      sourceIndex = destGroupIndex;
      destIndex = sourceGroupIndex;
    }

    const jobStage = getJobStageById(state, jobStageId);
    const sourceGroupId = jobStage.jobStageInterviewGroupIds[sourceIndex];
    const destGroupId = jobStage.jobStageInterviewGroupIds[destIndex];

    const sourceGroup = getJobStageInterviewGroupById(state, sourceGroupId);
    const destGroup = getJobStageInterviewGroupById(state, destGroupId);

    const jobStageInterviewIds = [...destGroup.jobStageInterviewIds, ...sourceGroup.jobStageInterviewIds];
    dispatch(updateJobStageInterviewGroup({ id: destGroupId, jobStageInterviewIds }));

    // Update group id for interviews that were moved to other group.
    sourceGroup.jobStageInterviewIds.forEach((id) => {
      dispatch(updateJobStageInterview({ id, jobStageGroupId: destGroupId }));
    });

    dispatch(
      updateJobStage({
        id: jobStageId,
        jobStageInterviewGroupIds: jobStage.jobStageInterviewGroupIds.filter((id) => id !== sourceGroupId),
      })
    );

    dispatch(deleteJobStageInterviewGroup(sourceGroupId));
  };

export const ungroupJobStageInterviewGroup =
  (jobStageId: string, jobStageInterviewGroupId: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const jobStage = getJobStageById(state, jobStageId);
    const groupIndex = jobStage.jobStageInterviewGroupIds.indexOf(jobStageInterviewGroupId);
    const group = getJobStageInterviewGroupById(state, jobStageInterviewGroupId);

    const newGroupIds: string[] = [];
    group.jobStageInterviewIds.forEach((id) => {
      const interview = getJobStageInterviewById(state, id);
      const newGroup: JobStageInterviewGroup = {
        id: uuid(),
        jobStageId,
        jobStageInterviewIds: [id],
        locked: interview.isLockedOrder,
      };
      dispatch(addJobStageInterviewGroup(newGroup));

      dispatch(updateJobStageInterview({ id, jobStageGroupId: newGroup.id }));

      newGroupIds.push(newGroup.id);
    });

    const jobStageInterviewGroupIds = [...jobStage.jobStageInterviewGroupIds];
    jobStageInterviewGroupIds.splice(groupIndex, 1, ...newGroupIds);

    dispatch(updateJobStage({ id: jobStageId, jobStageInterviewGroupIds }));
    dispatch(deleteJobStageInterviewGroup(group.id));
  };
