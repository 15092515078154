import React from 'react';

import BaseIcon, { Props } from './Icon';

const MaximizeIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.25002L14.1893 4.25001L10.4697 7.96968C10.1768 8.26258 10.1768 8.73745 10.4697 9.03034C10.7626 9.32324 11.2374 9.32324 11.5303 9.03034L15.25 5.31067L15.25 7.50001C15.25 7.91423 15.5858 8.25001 16 8.25001C16.4142 8.25001 16.75 7.91422 16.75 7.50001L16.75 3.50001C16.75 3.0858 16.4142 2.75001 16 2.75001L12 2.75002C11.5858 2.75002 11.25 3.0858 11.25 3.50001C11.25 3.91423 11.5858 4.25002 12 4.25002ZM9.03034 11.5303C9.32324 11.2375 9.32324 10.7626 9.03035 10.4697C8.73745 10.1768 8.26258 10.1768 7.96969 10.4697L4.25 14.1894L4.25001 12C4.25001 11.5858 3.91422 11.25 3.50001 11.25C3.0858 11.25 2.75001 11.5858 2.75001 12L2.75 16C2.75 16.1989 2.82902 16.3897 2.96967 16.5303C3.11032 16.671 3.30109 16.75 3.5 16.75L7.50001 16.75C7.91422 16.75 8.25001 16.4142 8.25001 16C8.25001 15.5858 7.91422 15.25 7.50001 15.25L5.31067 15.25L9.03034 11.5303Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MaximizeIcon;
