import React from 'react';

import { useFlag } from '@modernloop/shared/feature-flag';
import { InfoIcon } from '@modernloop/shared/icons';
import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';

import {
  CandidateAvailabilityOptionsSettingInput,
  CandidateAvailabilityOptionsSettings,
} from 'src/generated/mloop-graphql';

import useEqualSizeSxProps from 'src/components/utils/useEqualSizeSxProps';

import { CandidateAvailabilityOptions } from 'src/store/slices/candidate-availability-options';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import TimeFrame, { NumberOfDays } from 'src/views-new/SelfSchedule/TimeframePicker';

import { CandidateAvailabilityOptionsPreferences } from '../CandidateAvailabilityOptions/Options/CandidateAvailabilityOptionsPreferences';

import { DURATION_CALC_FROM_INTERVIEW_PLAN } from './OrgSelfScheduleOptions';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
export function OrgCandidateAvailabilityOptions({
  candidateAvailabilityOptions,
  roundedMinsPerDay,
  availabilityTemplateId,
  onAvailabilityRequestTemplateChange,
  onOptionsChanged,
}: {
  candidateAvailabilityOptions: CandidateAvailabilityOptionsSettings;
  roundedMinsPerDay: number;
  availabilityTemplateId: string | undefined;
  onAvailabilityRequestTemplateChange: (availabilityRequestEmailTemplateId: string | undefined) => void;
  onOptionsChanged: (options: CandidateAvailabilityOptionsSettingInput) => void;
}) {
  const ignoreRollingDaysSetting = useFlag('user_ignore_rolling_window_setting');
  const equalSizeSxProps = useEqualSizeSxProps();

  const handleOptionChanged = (settingInput: CandidateAvailabilityOptionsSettings) => {
    onOptionsChanged(settingInput as CandidateAvailabilityOptionsSettingInput);
  };

  return (
    <Stack>
      <ConditionalThemeProvider>
        <Stack spacing={1} mb={1}>
          <Typography fontWeight={600}>What days can the candidate submit availability for? 123</Typography>

          <Stack
            alignItems="center"
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            spacing={1}
            sx={equalSizeSxProps.equalSizeChild}
          >
            <Typography>Timeframe</Typography>
            <TimeFrame
              timeFrame={candidateAvailabilityOptions.timeframeNumberOfDays ?? NumberOfDays.TwoWeeks}
              onChange={(numberOfDays) => {
                onOptionsChanged({
                  ...candidateAvailabilityOptions,
                  timeframeNumberOfDays: numberOfDays,
                });
              }}
            />
          </Stack>

          {!ignoreRollingDaysSetting && (
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} mb={1}>
              <FormControlLabel
                sx={{
                  width: '100%',
                  justifyContent: 'space-between',
                }}
                labelPlacement="start"
                label="Use rolling window"
                control={
                  <Switch
                    size="small"
                    checked={candidateAvailabilityOptions.useRollingDays ?? false}
                    onChange={(event) => {
                      onOptionsChanged({
                        ...candidateAvailabilityOptions,
                        useRollingDays: event.target.checked,
                        timeframeNumberOfDays:
                          candidateAvailabilityOptions.timeframeNumberOfDays ?? NumberOfDays.TwoWeeks,
                      });
                    }}
                  />
                }
              />
              <InfoIcon tooltip="Based on the selection above. Ensures the last selectable date is always that number of days out from when the candidate is viewing their options. Not available when selecting custom dates." />
            </Stack>
          )}
        </Stack>
      </ConditionalThemeProvider>

      <CandidateAvailabilityOptionsPreferences
        options={candidateAvailabilityOptions as CandidateAvailabilityOptions}
        roundedMinsPerDay={roundedMinsPerDay}
        onNumberOfDaysChange={(numberOfDays) => {
          handleOptionChanged({ ...candidateAvailabilityOptions, numberOfDays });
        }}
        onMinutesPerDayChange={(minutesPerDays) => {
          const settingInput = { ...candidateAvailabilityOptions, minutesPerDays };
          if (minutesPerDays === DURATION_CALC_FROM_INTERVIEW_PLAN) {
            settingInput.minimumTimeBlockMinutes = DURATION_CALC_FROM_INTERVIEW_PLAN;
          }
          handleOptionChanged(settingInput);
        }}
        onMinTimeBlockMinutesChange={(minimumTimeBlockMinutes) => {
          handleOptionChanged({ ...candidateAvailabilityOptions, minimumTimeBlockMinutes });
        }}
        onAdvanceNoticeHoursChange={(advanceNoticeHour) => {
          handleOptionChanged({ ...candidateAvailabilityOptions, advanceNoticeMinutes: advanceNoticeHour * 60 });
        }}
        onCandidateNoteBlur={(candidateNote) => {
          handleOptionChanged({ ...candidateAvailabilityOptions, candidateNote: candidateNote.target.value });
        }}
        onRespectLoadLimitChange={(shouldRespectLoadLimit) => {
          handleOptionChanged({ ...candidateAvailabilityOptions, shouldRespectLoadLimit });
        }}
        onScheduleOverAvailableKeywordsChange={(canScheduleOverAvailableKeywords) => {
          handleOptionChanged({
            ...candidateAvailabilityOptions,
            canScheduleOverAvailableKeywords,
          });
        }}
        onScheduleOverFreeTimeChange={(canScheduleOverFreeTime) => {
          handleOptionChanged({ ...candidateAvailabilityOptions, canScheduleOverFreeTime });
        }}
        onScheduleOverRecruitingKeywordsChange={(canScheduleOverRecruitingKeywords) => {
          handleOptionChanged({ ...candidateAvailabilityOptions, canScheduleOverRecruitingKeywords });
        }}
        availabilityTemplateId={availabilityTemplateId}
        onAvailabilityRequestTemplateChange={onAvailabilityRequestTemplateChange}
        onCcRecipientsChange={(ccRecipients) => {
          onOptionsChanged({ ccRecipients });
        }}
        onBccRecipientsChange={(bccRecipients) => {
          onOptionsChanged({ bccRecipients });
        }}
        ccRecipients={candidateAvailabilityOptions.ccRecipients}
        bccRecipients={candidateAvailabilityOptions.bccRecipients}
        allowUnsettingOptions
      />
    </Stack>
  );
}
