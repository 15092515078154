import { useQuery } from 'react-query';

import { ApiVerb, apiPost } from 'src/utils/api';

import {
  AshbyIntegration,
  CodeSignalIntegration,
  CoderPadIntegration,
  CodilityIntegration,
  GemIntegration,
  GoogleIntegration,
  GreenhouseIntegration,
  HackerRankIntegration,
  LeverIntegration,
  MicrosoftGccHighIntegration,
  MicrosoftIntegration,
  QUERY_KEY,
  SlackOrgIntegration,
  WorkdayIntegration,
  ZoomIntegration,
} from './types';

interface ListIntegrationResponse {
  workday: WorkdayIntegration;
  gem: GemIntegration;
  org_id?: string;
  ashby?: AshbyIntegration;
  coderpad?: CoderPadIntegration;
  codesignal?: CodeSignalIntegration;
  google?: GoogleIntegration;
  microsoft_gcc_high?: MicrosoftGccHighIntegration;
  microsoft?: MicrosoftIntegration;
  greenhouse?: GreenhouseIntegration;
  lever?: LeverIntegration;
  zoom?: ZoomIntegration;
  slack?: SlackOrgIntegration;
  hackerrank?: HackerRankIntegration;
  codility?: CodilityIntegration;
}

async function listIntegration(): Promise<ListIntegrationResponse> {
  const response = await apiPost(ApiVerb.LIST_INTEGRATION, {});
  return response.data;
}

/**
 * @deprecated - Use thisOrg.integrations GQL instead
 */
export default function useIntegrations() {
  return useQuery(QUERY_KEY, () => listIntegration());
}
