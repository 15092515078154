import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from 'src/components/button';

import { LoggerEvent, useLogEvent } from 'src/hooks/useLogEvent';

import ScheduleIssuesDialog from './ScheduleIssuesDialog';

const ScheduleIssuesButton = (): JSX.Element | null => {
  const { logEvent } = useLogEvent();
  const { applicationStageID } = useParams<{ applicationStageID: string }>();
  const [showDialog, setShowDialog] = useState(false);

  if (!applicationStageID) return null;

  return (
    <>
      <Button
        label="Previous issues"
        variant="outlined"
        onClick={() => {
          logEvent(LoggerEvent.CLIENT_RESCHEDULE_FLOW_PREVIOUS_ISSUES_OPENED);
          setShowDialog(true);
        }}
      />
      {showDialog && (
        <ScheduleIssuesDialog applicationStageId={applicationStageID} onClose={() => setShowDialog(false)} />
      )}
    </>
  );
};

export default ScheduleIssuesButton;
