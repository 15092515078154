import React from 'react';

import BaseIcon, { Props } from './Icon';

const BreakIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50001 8.75L14.5 8.75H15.75C17.1307 8.75 18.25 9.86929 18.25 11.25C18.25 12.6307 17.1307 13.75 15.75 13.75H14.9788C14.2385 16.0699 12.0654 17.75 9.49999 17.75C6.32436 17.75 3.75001 15.1756 3.75 12L4.5 12L3.75 12L3.75001 9.5C3.75002 9.08578 4.0858 8.75 4.50001 8.75ZM5.25 11.25L5.25001 10.25L13.75 10.25V12C13.75 14.3472 11.8472 16.25 9.49999 16.25C7.4087 16.25 5.67021 14.7395 5.31597 12.75H8.49999C8.91421 12.75 9.24999 12.4142 9.24999 12C9.24999 11.5858 8.91421 11.25 8.49999 11.25H5.25ZM15.25 10.25V12C15.25 12.0838 15.2482 12.1671 15.2447 12.25H15.75C16.3023 12.25 16.75 11.8023 16.75 11.25C16.75 10.6977 16.3023 10.25 15.75 10.25H15.25ZM10.25 12C10.25 11.5858 10.5858 11.25 11 11.25H12C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75H11C10.5858 12.75 10.25 12.4142 10.25 12Z"
        fill="currentColor"
      />
      <path
        d="M7.25 7H11.1877C11.7744 7 12.25 6.52441 12.25 5.93774V5.93774C12.25 5.40202 11.8511 4.95014 11.3195 4.88369L9.0694 4.60243C8.60129 4.54391 8.25 4.14598 8.25 3.67422V3.67422C8.25 3.27158 8.50765 2.91412 8.88962 2.78679L9.75 2.5"
        stroke="currentColor"
        strokeOpacity="0.58"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </BaseIcon>
  );
};

export default BreakIcon;
