import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import BaseIcon, { Props } from '../../Icon';

const CandidateRsvpAwaiting = ({ color, fontSize, tooltip, tiny }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip} tiny={tiny}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2226 12.4818C12.5549 12.7033 13 12.4651 13 12.0657V3C13 1.89543 12.1046 1 11 1H3C1.89543 1 1 1.89543 1 3V9C1 10.1046 1.89543 11 3 11H9.09167C9.68395 11 10.263 11.1753 10.7558 11.5038L12.2226 12.4818ZM3.75 7.5C4.44036 7.5 5 6.94036 5 6.25C5 5.55964 4.44036 5 3.75 5C3.05964 5 2.5 5.55964 2.5 6.25C2.5 6.94036 3.05964 7.5 3.75 7.5ZM8.25 6.25C8.25 6.80228 7.80228 7.25 7.25 7.25C6.69772 7.25 6.25 6.80228 6.25 6.25C6.25 5.69772 6.69772 5.25 7.25 5.25C7.80228 5.25 8.25 5.69772 8.25 6.25ZM10.75 7C11.1642 7 11.5 6.66421 11.5 6.25C11.5 5.83579 11.1642 5.5 10.75 5.5C10.3358 5.5 10 5.83579 10 6.25C10 6.66421 10.3358 7 10.75 7Z"
        fill="#B9B9BE"
      />
    </BaseIcon>
  );
};

export default CandidateRsvpAwaiting;
