import { FeatureFlagBool, FeatureFlagJson, FeatureFlagNumber, FeatureFlagString } from './';
import { useEffect, useState } from 'react';

export type FeatureFlagNames = FeatureFlagBool | FeatureFlagJson | FeatureFlagNumber | FeatureFlagString;

export type LocalStorageFlags = Record<FeatureFlagNames, unknown>;

const MLoopFeatureFlagsNamespace = `mLoopFeatureFlags`;

// don't export
const _setLocalStorageFeatureFlags = (flags: LocalStorageFlags | undefined) => {
  localStorage.setItem(MLoopFeatureFlagsNamespace, JSON.stringify(flags || {}));
};

// don't export
const _getLocalStorageFeatureFlags = (): LocalStorageFlags => {
  const localFlags = localStorage.getItem(MLoopFeatureFlagsNamespace);
  if (!localFlags) return {} as LocalStorageFlags;

  return JSON.parse(localFlags);
};

export const useFeatureFlagLocal = () => {
  const _localStorageFeatureFlags = _getLocalStorageFeatureFlags();
  const [localStorageFeatureFlags, setLocalStorageFeatureFlags] =
    useState<LocalStorageFlags>(_localStorageFeatureFlags);

  useEffect(() => {
    // update localStorage when state changes
    _setLocalStorageFeatureFlags(localStorageFeatureFlags);
  }, [localStorageFeatureFlags]);

  return {
    setLocalStorageFeatureFlags,
    localStorageFeatureFlags,
  };
};
