import { IframeStateAction, IframeState } from './types';
import { noop } from 'lodash';
import React, { useContext } from 'react';

export const IframeStateContext = React.createContext<IframeState>({
  iframeWindow: null,
});

const IframeDispatchContext = React.createContext<React.Dispatch<IframeStateAction>>(noop);

// eslint-disable-next-line react-refresh/only-export-components
export const useIframeDispatch = () => {
  const dispatch = useContext(IframeDispatchContext);
  return dispatch;
};

export default IframeDispatchContext;
