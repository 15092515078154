import React from 'react';

import BaseIcon, { Props } from './Icon';

const SidebarOffIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75002 6C3.75002 5.30964 4.30966 4.75 5.00002 4.75H10H11.25V15.25H10L5.00002 15.25C4.30966 15.25 3.75002 14.6904 3.75002 14V6ZM9.99879 16.75H5.00002C3.48124 16.75 2.25002 15.5188 2.25002 14V6C2.25002 4.48122 3.48124 3.25 5.00002 3.25H10H12H12.75H15C16.5188 3.25 17.75 4.48121 17.75 6L17.75 14C17.75 15.5188 16.5188 16.75 15 16.75L12.75 16.75H12H10C9.9996 16.75 9.99919 16.75 9.99879 16.75ZM12.75 15.25H15C15.6904 15.25 16.25 14.6904 16.25 14L16.25 6C16.25 5.30964 15.6903 4.75 15 4.75H12.75V15.25Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default SidebarOffIcon;
