import { generatePath } from 'react-router';

import { gql } from '@apollo/client';

import { useMicrosoftIntegrationQuery } from 'src/generated/mloop-graphql';

import { Routes } from 'src/constants/routes';

import useCurrentRoute from 'src/hooks/useCurrentRoute';
import useEmployeeId from 'src/hooks/useEmployeeId';
import useOrgId from 'src/hooks/useOrgId';

import { DefaultReturnType } from './constants';
import { IntegrationGrantType, ReturnType } from './types';
import { getRedirectParams, isIgnoredRoutes } from './utils';

export const MicrosoftIntegration = gql`
  query MicrosoftIntegration {
    thisOrg {
      id
      integrations {
        microsoft {
          active
        }
      }
    }
    thisEmployee {
      id
      integrations {
        microsoft {
          active
        }
      }
    }
  }
`;

/**
 * Hook to check if outlook user has granted access, if yes return redirect params to naviagte to outlook grant access screen
 * If needed to be export, lets discuss once
 */
const useOutlookGrantAccess = (): ReturnType => {
  /** Getting the current location */
  const currentRoute = useCurrentRoute();

  const orgId = useOrgId();
  const employeeId = useEmployeeId();

  const { data, loading, error } = useMicrosoftIntegrationQuery({
    skip: !orgId || !employeeId,
    context: {
      batch: false,
    },
  });

  if (loading || error || !data?.thisOrg?.integrations?.microsoft?.active || isIgnoredRoutes(currentRoute)) {
    return DefaultReturnType;
  }

  if (data?.thisOrg?.integrations?.microsoft.active && !data?.thisEmployee?.integrations?.microsoft?.active) {
    const pathname = generatePath(Routes.GrantAccess, {
      integrationType: IntegrationGrantType.Outlook,
    });
    return getRedirectParams(pathname, currentRoute);
  }

  return DefaultReturnType;
};

export default useOutlookGrantAccess;
