import React from 'react';

import BaseIcon, { Props } from './Icon';

const MetricsIconFilled = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 4.75C13 3.50736 14.0074 2.5 15.25 2.5C16.4926 2.5 17.5 3.50736 17.5 4.75C17.5 5.99264 16.4926 7 15.25 7C15.092 7 14.9378 6.98371 14.789 6.95273L12.5954 10.4624C12.8504 10.8272 13 11.2711 13 11.75C13 12.9926 11.9926 14 10.75 14C9.58343 14 8.62422 13.1122 8.51115 11.9754L6.37663 11.3045C5.96697 11.7331 5.38966 12 4.75 12C3.50736 12 2.5 10.9926 2.5 9.75C2.5 8.50736 3.50736 7.5 4.75 7.5C5.99264 7.5 7 8.50736 7 9.75C7 9.80922 6.99771 9.86791 6.99322 9.92599L8.86866 10.5154C9.27085 9.90378 9.96327 9.5 10.75 9.5C10.9663 9.5 11.1754 9.53052 11.3734 9.58748L13.5073 6.17327C13.1902 5.7855 13 5.28996 13 4.75ZM2 17C2 16.4477 2.44772 16 3 16H17C17.5523 16 18 16.4477 18 17C18 17.5523 17.5523 18 17 18H3C2.44772 18 2 17.5523 2 17Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MetricsIconFilled;
