import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Alert, Stack, Typography } from '@mui/material';

import { ApplicationConflict_TaskFragment } from 'src/generated/mloop-graphql';

type Fragments = {
  task: ApplicationConflict_TaskFragment;
};

const ApplicationConflict: FCWithFragments<Fragments> = ({ task }) => {
  const isTaskAndApplicationConflict = task.jobStage.job?.id !== task.application.jobStage?.job?.id;

  if (!isTaskAndApplicationConflict) return null;

  return (
    <Stack>
      <Alert severity="error">
        <Typography variant="body2">
          This task was created for job{' '}
          <Typography component="span" variant="subtitle2">
            {task.jobStage.job?.name}
          </Typography>{' '}
          but this candidate&apos;s application is currently for{' '}
          <Typography component="span" variant="subtitle2">
            {task.application.jobStage?.job?.name}{' '}
          </Typography>
          .
        </Typography>
      </Alert>
    </Stack>
  );
};

ApplicationConflict.fragments = {
  task: gql`
    fragment ApplicationConflict_task on Task {
      id
      jobStage {
        id
        job {
          id
          name
        }
      }
      application {
        id
        jobStage {
          id
          job {
            id
            name
          }
        }
      }
    }
  `,
};

export default ApplicationConflict;
