import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CopyIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25001 5C2.25001 3.48122 3.48122 2.25 5.00001 2.25H11C12.5188 2.25 13.75 3.48122 13.75 5V6.25H15C16.5188 6.25 17.75 7.48122 17.75 9L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H9.00001C7.48122 17.75 6.25001 16.5188 6.25001 15V13.75H5.00001C3.48122 13.75 2.25001 12.5188 2.25001 11V5ZM12.25 5V6.25H9.00001C7.48122 6.25 6.25001 7.48122 6.25001 9V12.25H5.00001C4.30965 12.25 3.75001 11.6904 3.75001 11V5C3.75001 4.30964 4.30965 3.75 5.00001 3.75H11C11.6904 3.75 12.25 4.30964 12.25 5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
