import { useCallback } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { isEmpty } from 'lodash';

import {
  InterviewerInput,
  InterviewerRole,
  UseGetInterviewers_InterviewPlanFragment,
} from 'src/generated/mloop-graphql';

import { getScheduleUpdates } from 'src/store/selectors/schedule-update';
import { ScheduleUpdateInterviewerOptional } from 'src/store/slices/schedule-update';

import { InterviewEvent } from 'src/utils/api/getScheduleOptions';

import { useSelector } from 'src/store';

type Fragments = {
  interviewPlan: UseGetInterviewers_InterviewPlanFragment | undefined;
};

type Props = {
  scheduleId: string;
};

const useGetInterviewers: FunctionWithFragments<Fragments, Props, (event: InterviewEvent) => InterviewerInput[]> = (
  { interviewPlan },
  { scheduleId }
) => {
  const optionalInterviewerUpdates = useSelector((state) => getScheduleUpdates(state, scheduleId))?.filter?.(
    (update) => update?.type === 'ScheduleUpdateInterviewerOptional'
  ) as ScheduleUpdateInterviewerOptional[];

  return useCallback(
    (event: InterviewEvent) => {
      if (!event.filledInterviewSeats) return [];

      const findInterviewerOptionalStatus = (interviewerId: string, interviewId: string) => {
        const currentOptionalInterviewerUpdate = optionalInterviewerUpdates?.find(
          (val) =>
            val?.applicationStageInterviewId === interviewId && val?.applicationStageInterviewerId === interviewerId
        );

        return Boolean(currentOptionalInterviewerUpdate?.isOptional);
      };

      const allInterviews =
        interviewPlan?.jobStageInterviewGroups?.flatMap((group) => group.jobStageInterviews || []) || [];

      const allInterviewSeats = allInterviews.map((i) => i.jobStageInterviewSeats || []).flat();

      const interview = allInterviews.find((i) => i.id === event.slotId);

      const allInterviewSeatIds = interview?.jobStageInterviewSeats?.map((s) => s.id || '').flat() || [];

      return event.filledInterviewSeats?.flatMap((filledSeat) => {
        let trainee: InterviewerInput | null = null;
        let interviewer: InterviewerInput | null = null;

        let interviewId: string | null = null;

        if (!isEmpty(filledSeat.seat.moduleSeat) || !isEmpty(filledSeat.seat.linkedSeat)) {
          if (filledSeat.seat.moduleSeat) {
            interviewId = filledSeat.seat.moduleSeat?.interviewId || null;
          } else {
            const originalSeat = allInterviewSeats.find(
              (seat) => seat.id === filledSeat.seat.linkedSeat?.interviewSeatId
            );

            if (originalSeat) {
              interviewId = originalSeat?.moduleSeat?.interviewModuleId || null;
            }
          }

          if (filledSeat.traineeId) {
            let seatId = filledSeat.seat.moduleSeat?.id || filledSeat.seat.linkedSeat?.id || null;
            seatId = seatId && allInterviewSeatIds.includes(seatId) ? seatId : null;

            trainee = {
              employeeId: filledSeat.traineeId,
              interviewModuleId: interviewId,
              role: filledSeat.traineeRole as InterviewerRole,
              jobStageInterviewSeatId: seatId,
            };

            const traineeOptionalStatus = findInterviewerOptionalStatus(filledSeat?.traineeId, event?.id);

            if (trainee && traineeOptionalStatus) {
              trainee.isOptional = traineeOptionalStatus;
            }
          }
        }
        if (filledSeat.interviewerId) {
          let seatId =
            filledSeat.seat.moduleSeat?.id ||
            filledSeat.seat.linkedSeat?.id ||
            filledSeat.seat.freeformSeat?.id ||
            null;
          seatId = seatId && allInterviewSeatIds.includes(seatId) ? seatId : null;

          interviewer = {
            employeeId: filledSeat.interviewerId,
            interviewModuleId: interviewId,
            role: InterviewerRole.Interviewer,
            jobStageInterviewSeatId: seatId,
          };

          const interviewerOptionalStatus = findInterviewerOptionalStatus(filledSeat?.interviewerId, event?.id);

          if (interviewerOptionalStatus) {
            interviewer.isOptional = interviewerOptionalStatus;
          }
        }

        return [trainee, interviewer].filter((a) => a) as InterviewerInput[]; // remove nulls
      });
    },
    [interviewPlan?.jobStageInterviewGroups, optionalInterviewerUpdates]
  );
};

useGetInterviewers.fragments = {
  interviewPlan: gql`
    fragment useGetInterviewers_interviewPlan on JobStage {
      id
      jobStageInterviewGroups {
        id
        jobStageInterviews {
          id
          jobStageInterviewSeats {
            id
            freeformSeat {
              jobStageInterviewSeatEmployees {
                jobStageInterviewSeatId
                employeeId
                preferenceLevel
              }
            }
            moduleSeat {
              interviewModuleId
            }
            linkedSeat {
              linkedJobStageInterviewSeatId
            }
          }
        }
      }
    }
  `,
};

export default useGetInterviewers;
