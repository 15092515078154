import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
import { Alert, AlertTitle, Box, Stack, Typography, useTheme } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

import {
  ApplicationAlert_ApplicationFragment,
  ApplicationStatus,
  TaskFlagType,
  UpcomingScheduleAlert_ApplicationStagesFragment,
} from 'src/generated/mloop-graphql';

import { ArchivedIcon, DeclinedInterviewIcon, HiredIcon, RejectIcon, ScheduleIcon } from 'src/components/icons';
import { FCWithFragments } from 'src/components/types';
import { SupportedColor } from 'src/components/utils/color';

import { Theme } from 'src/themeMui5/type';

import { isEventInProgress, isUpcomingEvent } from 'src/views-new/CandidateDetails/utils';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type UpcomingScheduleAlertProps = {
  jobStageId: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type UpcomingScheduleAlertFragmentProps = {
  applicationStages: UpcomingScheduleAlert_ApplicationStagesFragment[];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type NewBaseApplicationAlertProps = {
  status: ApplicationStatus;
};

const useSxProps = () => {
  const theme = useTheme();
  return useMemo(() => {
    return {
      root: {
        borderRadius: '6px',
        padding: theme.spacing(1.5),
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        '& > .MuiGrid-container > .MuiGrid-root.MuiGrid-item.MuiGrid-zeroMinWidth': {
          width: '100%',
        },
      },
      iconWrapper: {
        marginRight: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        padding: '6px',
        borderRadius: '100%',
      },
      rejected: {
        background: theme.palette.error.dark,
      },
      converted: {
        background: theme.grey.solid.max,
      },
      archived: {
        background: theme.grey.solid.max,
      },
      hired: {
        background: theme.palette.success.dark,
      },
      alertWrapper: {
        borderRadius: '6px',
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
      },
    };
  }, [theme]);
};

export const NewBaseApplicationAlert = ({ status }: NewBaseApplicationAlertProps) => {
  const sxProps = useSxProps();

  const content = useMemo(() => {
    const label = `Application ${status.toLowerCase()} `;
    let icon = <></>;
    const alertStyles = sxProps[status.toLowerCase()];
    const iconColor = alertStyles?.background as SupportedColor;

    switch (status) {
      case ApplicationStatus.Archived:
        icon = <ArchivedIcon color={iconColor} />;
        break;

      case ApplicationStatus.Hired:
        icon = <HiredIcon color={iconColor} />;
        break;

      case ApplicationStatus.Converted:
      case ApplicationStatus.Rejected:
        icon = <RejectIcon color={iconColor} />;
        break;

      default:
    }

    return { label, icon, alertStyles };
  }, [status, sxProps]);

  return (
    <>
      {status !== ApplicationStatus.Active ? (
        <Stack direction="row" sx={{ ...sxProps.root, ...content.alertStyles }}>
          <Box sx={sxProps.iconWrapper}>{content.icon}</Box>
          <Typography fontWeight={600} color="inherit">
            {content.label}
          </Typography>
        </Stack>
      ) : null}
    </>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/restric-fragments-name.cjs
const UpcomingScheduleAlert: FCWithFragments<UpcomingScheduleAlertFragmentProps, UpcomingScheduleAlertProps> = ({
  applicationStages,
  jobStageId,
}) => {
  const schedules = useMemo(
    () => applicationStages.filter((stage) => stage.jobStageId === jobStageId),
    [applicationStages, jobStageId]
  );

  const onGoingSchedule = schedules.find((schedule) => isEventInProgress(schedule.startAt, schedule.endAt));

  if (onGoingSchedule) {
    const hasInterviewerDeclinedFlag = onGoingSchedule.task?.flags?.find(
      (flag) => flag.flagType === TaskFlagType.InterviewDeclined && !flag.resolvedAt
    );
    const onGoingInterview = onGoingSchedule.applicationStageInterviews?.find((interview) =>
      isEventInProgress(interview.startAt, interview.endAt)
    );

    if (onGoingInterview) {
      const hasInterviewerDeclined = onGoingInterview.applicationStageInterviewInterviewers?.find(
        (interviewer) => interviewer.declines?.length
      );

      if (hasInterviewerDeclined && hasInterviewerDeclinedFlag) {
        return (
          <Alert
            sx={{
              marginBottom: (theme: Theme) => theme.spacing(1),
            }}
            severity="warning"
          >
            <AlertTitle>Interviewer declined — Interviews in progress</AlertTitle>
          </Alert>
        );
      }
      return (
        <Alert severity="warning">
          <AlertTitle>Interviews in progress</AlertTitle>
        </Alert>
      );
    }

    const upcomingInterview = onGoingSchedule.applicationStageInterviews?.find((interview) =>
      isUpcomingEvent(interview?.startAt)
    );

    if (upcomingInterview) {
      const hasInterviewerDeclined = upcomingInterview.applicationStageInterviewInterviewers?.find(
        (interviewer) => interviewer.declines?.length
      );
      if (hasInterviewerDeclined && hasInterviewerDeclinedFlag) {
        return (
          <Alert
            sx={{
              marginBottom: (theme: Theme) => theme.spacing(1),
            }}
            icon={<DeclinedInterviewIcon color="error" />}
            severity="error"
          >
            <AlertTitle>
              Interviewer declined — Interviews begin in {formatDistanceToNow(new Date(upcomingInterview.startAt))}.
            </AlertTitle>
          </Alert>
        );
      }
      return (
        <Alert
          sx={{
            marginBottom: (theme: Theme) => theme.spacing(1),
            alignItems: 'center',
          }}
          icon={<ScheduleIcon color="info" />}
          severity="info"
        >
          <AlertTitle>
            Stage scheduled — Interviews begin in {formatDistanceToNow(new Date(upcomingInterview.startAt))}.
          </AlertTitle>
        </Alert>
      );
    }
  }

  const upcomingSchedule = schedules.find((schedule) => isUpcomingEvent(schedule?.startAt));

  if (upcomingSchedule) {
    const hasInterviewerDeclinedFlag = upcomingSchedule.task?.flags?.find(
      (flag) => flag.flagType === TaskFlagType.InterviewDeclined && !flag.resolvedAt
    );
    if (upcomingSchedule.hasInterviewerDeclined && hasInterviewerDeclinedFlag) {
      return (
        <Alert
          sx={{
            marginBottom: (theme: Theme) => theme.spacing(1),
          }}
          icon={<DeclinedInterviewIcon color="error" />}
          severity="error"
        >
          <AlertTitle>
            Interviewer declined — Interviews begin in {formatDistanceToNow(new Date(upcomingSchedule.startAt))}.
          </AlertTitle>
        </Alert>
      );
    }

    return (
      <Alert
        sx={{
          marginBottom: (theme: Theme) => theme.spacing(1),
          alignItems: 'center',
        }}
        icon={<ScheduleIcon color="info" />}
        severity="info"
      >
        <AlertTitle>
          Stage scheduled — Interviews begin in {formatDistanceToNow(new Date(upcomingSchedule.startAt))}.
        </AlertTitle>
      </Alert>
    );
  }

  return null;
};

UpcomingScheduleAlert.fragments = {
  applicationStages: gql`
    fragment UpcomingScheduleAlert_applicationStages on ApplicationStage {
      id
      jobStageId
      startAt
      endAt
      hasInterviewerDeclined
      task {
        id
        flags {
          id
          flagType
          resolvedAt
        }
      }
      applicationStageInterviews {
        id
        startAt
        endAt
        applicationStageInterviewInterviewers {
          interviewerId
          declines {
            applicationStageInterviewId
          }
        }
      }
    }
  `,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ApplicationAlertProps = {
  jobStageId: string;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ApplicationAlertFragmentProps = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/component-with-fragments.cjs
  application: ApplicationAlert_ApplicationFragment;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/restric-fragments-name.cjs
const ApplicationAlert: FCWithFragments<ApplicationAlertFragmentProps, ApplicationAlertProps> = ({
  application,
  jobStageId,
}) => {
  if (application.status === ApplicationStatus.Active && application.applicationStages) {
    return <UpcomingScheduleAlert applicationStages={application.applicationStages} jobStageId={jobStageId} />;
  }
  return <NewBaseApplicationAlert status={application.status} />;
};

ApplicationAlert.fragments = {
  application: gql`
    ${UpcomingScheduleAlert.fragments.applicationStages}
    fragment ApplicationAlert_application on Application {
      id
      status
      applicationStages(input: { isCancelled: false }) {
        ...UpcomingScheduleAlert_applicationStages
      }
    }
  `,
};

export default ApplicationAlert;
