import { enviromentConfig } from 'src/config';

export const APP_VERSION = '3.1.0';

export const PAGER_VALUES = [10, 50, 100];
export const PAGER_DEFAULT = 25;

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  MODERNLOOP_UIKIT: 'MODERNLOOP_UIKIT',
  MODERNLOOP: 'MODERNLOOP',
};

export const DEBOUNCE_TIMEOUT = 300;
export const MIN_COUNT_OF_TRAINING_INTERVIEWS = 0;
export const MAX_COUNT_OF_TRAINING_INTERVIEWS = 20;

export const PAGE_SIZE = 500;

export const DEFAULT_SELF_SCHEDULE_STEP_DURATION = 30;

export const EMPTY_UUID = '00000000-0000-0000-0000-000000000000';

// Checks for email@domain.topleveldomain
export const EMAIL_REGEX = /^(.+)@(.+)\.(.+)$/;

export const TEMPLATE_TOKEN_REGEX = /\{\{.*?\}\}/gi;

export const VALID_UUID_REGEX =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const MILLISECONDS_IN_A_DAY = 86400000;
export const SECONDS_IN_A_DAY = 86400;

export const IS_PRODUCTION = enviromentConfig.environment?.toLowerCase() === 'production';

// This constant will be used to display the `Missing renderers` error in the UI after Schedule task launch.
export const IS_DEV = enviromentConfig.environment?.toLowerCase() === 'development';

export const EMPTY_OBJECT = Object.freeze({});

export const EMPTY_ARRAY = Object.freeze([]);

export const NO_QUEUE = 'no_queue';

export const DEFAULT_REQUIRED_SHADOWS_COUNT = 2;
export const DEFAULT_REQUIRED_REVERSE_SHADOWS_COUNT = 2;

export const MAX_LOAD_LIMIT = 10000;

export const MAX_COUNT_OF_TARGET_JOBS_FOR_IMPORT = 40;
