export const green = {
  0: '#000000',
  10: '#00210D',
  20: '#00391B',
  25: '#004522',
  30: '#00522A',
  35: '#005F32',
  40: '#006D3A',
  50: '#00894A',
  60: '#18A65D',
  70: '#41C175',
  80: '#61DE8E',
  90: '#7EFBA8',
  95: '#C3FFCF',
  98: '#EAFFEA',
  99: '#F5FFF3',
  100: '#FFFFFF',
};
