import React, { useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Button from 'src/components/button';
import { TriangleDownIcon } from 'src/components/icons';
import Menu, { MenuOption } from 'src/components/menu';

import { Theme } from 'src/theme';

export enum NumberOfDays {
  Custom = -1,
  OneWeek = 7,
  TwoWeeks = 14,
  ThreeWeeks = 21,
  FourWeeks = 28,
  TwoMonths = 60,
}

const TIME_RANGE_OPTION: MenuOption[] = [
  { id: NumberOfDays.OneWeek, value: 'Next week' },
  { id: NumberOfDays.TwoWeeks, value: 'Next 2 weeks' },
  { id: NumberOfDays.ThreeWeeks, value: 'Next 3 weeks' },
  { id: NumberOfDays.FourWeeks, value: 'Next 4 weeks' },
  { id: NumberOfDays.TwoMonths, value: 'Next 2 months' },
];

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type TimeFramePickerProps = {
  timeFrame: NumberOfDays;
  onChange: (value: NumberOfDays) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '6px',
      fontWeight: theme.typography.fontWeightRegular,
      minWidth: '200px',
      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  })
);

const TimeFramePicker = ({ timeFrame, onChange }: TimeFramePickerProps): JSX.Element => {
  const classes = useStyles();
  const [showDaysMenu, setShowDaysMenu] = useState(false);
  const daysButtonRef = useRef<HTMLButtonElement>(null);

  const timeFrameString = TIME_RANGE_OPTION.find((option) => option.id === timeFrame)?.value;

  const handleShowMenu = () => setShowDaysMenu(true);
  const handleHideMenu = () => setShowDaysMenu(false);
  const handleSelectTimeFrame = (option: MenuOption) => {
    if (!option.id || typeof option.id !== 'number') return;
    onChange(option.id);
    handleHideMenu();
  };

  return (
    <>
      <Button
        variant="outlined"
        className={classes.button}
        label={timeFrameString ?? 'Custom'}
        ref={daysButtonRef}
        endIcon={<TriangleDownIcon />}
        onClick={handleShowMenu}
      />
      {showDaysMenu && (
        <Menu
          id="select-time-frame-menu"
          anchorEl={daysButtonRef.current}
          open={showDaysMenu}
          options={TIME_RANGE_OPTION.map((option) => {
            if (option.id === timeFrame) return { ...option, selected: true };
            return option;
          })}
          menuWidth={daysButtonRef.current?.clientWidth ? daysButtonRef.current?.clientWidth - 16 : undefined}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleHideMenu}
          onSelect={handleSelectTimeFrame}
        />
      )}
    </>
  );
};

export default TimeFramePicker;
