/* eslint-disable max-lines */
import React from 'react';

import { gql } from '@apollo/client';
import { Editor } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { AlertTitle, Box, Divider, Alert as MuiAlert, Stack, Typography } from '@mui/material';

import {
  CreateTaskStep_ApplicationFragment,
  CreateTaskStep_JobStageFragment,
  TaskCreationSource,
} from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import Avatar from 'src/components/Avatar';
import { FilterSelectGroup } from 'src/components/FilterList/FilterSelect';
import Link from 'src/components/Link';
import SelectButton from 'src/components/SelectButton';
import Switch from 'src/components/Switch';
import Label from 'src/components/label';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';
import { FCWithFragments } from 'src/components/types';

import ZenDeskHelpCenterUrl from 'src/constants/ZenDeskHelpCenterUrl';

import TaskTags from 'src/entities/Filters/TaskTags';
import InterviewPlan from 'src/entities/InterviewPlan';
import getSaveInterviewPlanInput from 'src/entities/InterviewPlan/getSaveInterviewPlanInput';
import useInterviewPlanEntityHasInterviewHiddenFromCandidate from 'src/entities/InterviewPlan/useInterviewPlanHasInterviewHiddenFromCandidate';
import { useResetInterviewPlanToBaseJobStage } from 'src/entities/InterviewPlan/useResetInterviewPlanToBaseJobStage';
import MultiMemberSelect from 'src/entities/Select/MemberSelect/MultiMemberSelect';
import QueueSelect from 'src/entities/Select/QueueSelect/QueueSelect';
import AvatarFacePile from 'src/entities/common/AvatarFacePile';

import useEmployeeId from 'src/hooks/useEmployeeId';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import useInterviewPlanHasInterviewHiddenFromCandidate from 'src/views-new/InterviewPlan/useInterviewPlanHasInterviewHiddenFromCandidate';
import AssigneeSelect from 'src/views-new/ScheduleTask/ScheduleTaskDetails/Common/ScheduleTaskAssignee/AssigneeSelect';
import InterviewPlanWrapper from 'src/views-new/ScheduleTask/ScheduleTaskRequirements/Schedule/InterviewPlanWrapper';

import JobStageSelect from 'src/recruiting-portal/pages/extension/sidebar/ApplicationSidebar/JobStageSelect';

import AvailabilityInput from './AvailabilityInput';
import CreateDetailsRow from './CreateDetailsRow';
import { TaskCreateInputWrapper } from './types';

interface Props {
  isAvailabilityEnabled?: boolean;
  jobStageLoading: boolean;
  input: TaskCreateInputWrapper;
  source: TaskCreationSource;
  onInputChange: (input: TaskCreateInputWrapper) => void;
  setIsImportingAvailability: (isImporting: boolean) => void;
  onInterviewPlanChange: (interviewPlan: CreateTaskStep_JobStageFragment) => void;
}

type Fragments = {
  application: CreateTaskStep_ApplicationFragment;
  jobStage: CreateTaskStep_JobStageFragment | undefined;
};

const CreateTaskStep: FCWithFragments<Fragments, Props> = ({
  application,
  jobStage,
  jobStageLoading,
  onInputChange,
  input,
  source,
  isAvailabilityEnabled,
  setIsImportingAvailability,
  onInterviewPlanChange,
}) => {
  const interviewPlanEntityInTasksEnabled = useFlag('interview_plan_entity_in_tasks');
  const currentEmployeeId = useEmployeeId();
  const job = application?.job;
  const orgBreakImprovementsEnabled = useFlag('org_break_improvements');

  const { resetPlan } = useResetInterviewPlanToBaseJobStage({
    jobStageId: input.jobStageId,
    customJobStageId: input.customJobStageId,
    doNotPersist: true,
  });
  const hasInterviewHiddenFromCandidateStore = useInterviewPlanHasInterviewHiddenFromCandidate(input.customJobStageId);
  const hasInterviewHiddenFromCandidateEntity = useInterviewPlanEntityHasInterviewHiddenFromCandidate({
    interviewPlan: jobStage,
  });
  const hasInterviewHiddenFromCandidate = interviewPlanEntityInTasksEnabled
    ? hasInterviewHiddenFromCandidateEntity
    : hasInterviewHiddenFromCandidateStore;

  if (!job || !application.jobStage) {
    return null;
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Label fontWeight={600}>Scheduling details</Label>
      <CreateDetailsRow
        label="Assign to"
        component={
          <Box sx={{ width: '50%' }}>
            <AssigneeSelect
              onChange={(newMember) => {
                if (!newMember || newMember?.employee?.id === null) {
                  onInputChange({ ...input, assigneeEmployeeId: null, assignee: null });
                  return;
                }
                onInputChange({ ...input, assigneeEmployeeId: newMember?.employee?.id, assignee: newMember.employee });
              }}
              getLabel={() => {
                let label;
                let startIcon;

                if (!input.assignee) {
                  label = 'Unassigned';
                  startIcon = 'UnknownUserIcon';
                } else {
                  label = (
                    <Stack direction="row" spacing={1}>
                      <Box
                        sx={{
                          marginLeft: '-4px',
                        }}
                      >
                        <Avatar alt={input.assignee.fullName ?? ''} src={input.assignee.slackImageUrl ?? undefined} />
                      </Box>

                      <Box
                        sx={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        <MaybeTooltip label={input.assignee.fullName ?? ''} tooltip={input.assignee.fullName ?? ''} />
                      </Box>
                    </Stack>
                  );
                }

                return <SelectButton variant="outlined" startIcon={startIcon} label={label} size="medium" />;
              }}
            />
          </Box>
        }
      />
      <CreateDetailsRow
        label="Additional subscribers"
        component={
          <Box width="50%">
            <MultiMemberSelect
              header={
                <Box mt={(input.subscriberEmployeeIds?.length ?? 0) > 0 ? 2.5 : 0}>
                  <Alert
                    status="neutral"
                    title=""
                    caption="A task’s creator and assignee are always be added as subscribers when the task is created."
                  />
                </Box>
              }
              sortMembers={(a, b) => {
                // Sort members so all subscribers are at the top
                // Then either the coordinator or recruiter
                // Then alphabetically with the current user at the top

                if (input.subscriberEmployeeIds?.includes(a.employee?.id)) return -1;
                if (input.subscriberEmployeeIds?.includes(b.employee?.id)) return 1;

                if (a.employee?.id === application.candidate?.coordinator?.id) return -1;
                if (b.employee?.id === application.candidate?.coordinator?.id) return 1;

                if (a.employee?.id === application.candidate?.recruiter?.id) return -1;
                if (b.employee?.id === application.candidate?.recruiter?.id) return 1;

                if (a.employee?.id === currentEmployeeId) return -1;
                if (b.employee?.id === currentEmployeeId) return 1;

                return a.employee?.fullName?.localeCompare(b.employee?.fullName ?? '') ?? 0;
              }}
              groupBy={(option) => {
                if (input.subscriberEmployeeIds?.includes(option.employee?.id)) return FilterSelectGroup.SELECTED;

                if (option.employee?.id === application.candidate?.coordinator?.id) return FilterSelectGroup.SUGGESTED;

                if (option.employee?.id === application.candidate?.recruiter?.id) return FilterSelectGroup.SUGGESTED;

                return FilterSelectGroup.OTHER;
              }}
              placeholderText="Search"
              selectedEmployeeIds={input.subscriberEmployeeIds ?? []}
              getLabel={(selectedMembers) => {
                const label =
                  selectedMembers.length > 0 ? (
                    <Stack direction="row" spacing={1}>
                      <Box
                        sx={{
                          marginLeft: '-4px',
                        }}
                      >
                        <Box ml={1}>
                          <AvatarFacePile
                            size="sm"
                            max={1}
                            interviewers={selectedMembers?.map((val) => {
                              return {
                                interviewerId: val.employee?.id,
                                interviewer: {
                                  id: val.employee?.id,
                                  fullName: val.employee?.fullName,
                                  slackImageUrl: val.employee?.slackImageUrl,
                                },
                                responseStatus: null,
                                isOptional: false,
                              };
                            })}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        <MaybeTooltip
                          label={selectedMembers?.map((val) => val.employee?.fullName).join(', ')}
                          tooltip={selectedMembers?.map((val) => val.employee?.fullName).join(', ')}
                        />
                      </Box>
                    </Stack>
                  ) : (
                    <Label color="mid-contrast-grey">Search people...</Label>
                  );
                return <SelectButton variant="outlined" label={label} size="medium" />;
              }}
              onChange={(newMembers) => {
                const employeeIds = newMembers.map((member) => member.employee?.id);
                onInputChange({ ...input, subscriberEmployeeIds: employeeIds });
              }}
            />
          </Box>
        }
      />
      <CreateDetailsRow
        label="Add to queue"
        component={
          <Box width="50%">
            <QueueSelect
              groupQueue
              defaultTaskQueue={application.job?.jobSettings.defaultTaskQueue}
              onOptionChange={(queueId) => {
                onInputChange({ ...input, taskQueueId: queueId });
              }}
              selectedTaskQueue={application.job?.jobSettings.defaultTaskQueue}
            />
          </Box>
        }
      />
      <CreateDetailsRow
        label="Stage"
        component={
          <Box width="50%">
            {application.atsApplication?.canMoveJobStage && (
              <JobStageSelect
                applicationJobStageId={application.jobStage.id}
                job={job}
                selectedJobStageId={input.jobStageId}
                onChange={(id) => {
                  if (id !== input.jobStageId) {
                    onInputChange({ ...input, jobStageId: id, customJobStageId: null });
                  }
                }}
              />
            )}
            {!application.atsApplication?.canMoveJobStage && <Typography>{application.jobStage.name}</Typography>}
          </Box>
        }
      />
      <Box p={0}>
        <Switch
          fullWidth
          label="Mark task as urgent"
          dataTestId="urgent-switch"
          checked={input.isUrgent}
          onChange={(urgent) => {
            onInputChange({ ...input, isUrgent: urgent });
          }}
        />
      </Box>
      <TaskTags
        onChange={(newTags) => {
          if (newTags && newTags.length === 0) {
            onInputChange({ ...input, taskTags: null, tagIds: null });
            return;
          }
          onInputChange({ ...input, taskTags: newTags, tagIds: newTags.map((tag) => tag.id) });
        }}
        selectedTaskTags={input.taskTags ?? []}
      />
      <Link href={ZenDeskHelpCenterUrl.SCHEDULE_TASK} target="_blank" color="info">
        Learn more about tasks
      </Link>
      <Divider />
      <Typography variant="subtitle1">Interviews to be scheduled</Typography>
      {source === TaskCreationSource.SelfScheduleRequest && hasInterviewHiddenFromCandidate && (
        <ConditionalThemeProvider>
          <MuiAlert severity="error">
            <AlertTitle>Self-schedule unavailable</AlertTitle>
            Self-schedule is unavailable for interview plans with hidden events. Remove these events or send an
            availability request instead.
          </MuiAlert>
        </ConditionalThemeProvider>
      )}
      <Box>
        {interviewPlanEntityInTasksEnabled ? (
          <InterviewPlan
            onResetToJobStageDefault={async () => {
              const newInterviewPlan = await resetPlan();

              if (newInterviewPlan) {
                onInterviewPlanChange({ ...newInterviewPlan });

                const interviewPlanInput = getSaveInterviewPlanInput(
                  { interviewPlan: newInterviewPlan },
                  { finalJobStageId: input.customJobStageId }
                );
                onInputChange({
                  ...input,
                  customInterviewPlan: !orgBreakImprovementsEnabled ? interviewPlanInput.groups : undefined,
                  interviewPlan: orgBreakImprovementsEnabled
                    ? {
                        groups: interviewPlanInput.groups,
                        schedulingWindow: interviewPlanInput.schedulingWindow
                          ? { seconds: interviewPlanInput.schedulingWindow.seconds }
                          : undefined,
                        excludedEmployeeIds: interviewPlanInput.excludedEmployeeIds || [],
                      }
                    : undefined,
                });
              }
            }}
            schedulable={false}
            job={job || undefined}
            jobStage={jobStage}
            jobStageLoading={jobStageLoading}
            onUpdated={(newInterviewPlan) => {
              onInterviewPlanChange(newInterviewPlan);

              const interviewPlanInput = getSaveInterviewPlanInput(
                { interviewPlan: newInterviewPlan },
                { finalJobStageId: input.customJobStageId }
              );
              onInputChange({
                ...input,
                customInterviewPlan: !orgBreakImprovementsEnabled ? interviewPlanInput.groups : undefined,
                interviewPlan: orgBreakImprovementsEnabled
                  ? {
                      groups: interviewPlanInput.groups,
                      schedulingWindow: interviewPlanInput.schedulingWindow
                        ? { seconds: interviewPlanInput.schedulingWindow.seconds }
                        : undefined,
                      excludedEmployeeIds: interviewPlanInput.excludedEmployeeIds || [],
                    }
                  : undefined,
              });
            }}
          />
        ) : (
          <InterviewPlanWrapper
            key={`interview-plan-wrapper-${input.jobStageId}`}
            jobStageId={input.jobStageId}
            customJobStageId={input.customJobStageId ? input.customJobStageId : undefined}
            onChange={async (customJobStageId, plan) => {
              onInputChange({
                ...input,
                customJobStageId,
                customInterviewPlan: plan,
              });
            }}
          />
        )}
      </Box>
      <Box p={0}>
        <Switch
          fullWidth
          label="Schedule a debrief after interview"
          dataTestId="debrief-switch"
          checked={input.isDebriefRequired}
          onChange={(shouldDebrief) => {
            onInputChange({ ...input, isDebriefRequired: shouldDebrief });
          }}
        />
      </Box>
      {isAvailabilityEnabled && <Divider />}
      {isAvailabilityEnabled && (
        <AvailabilityInput
          applicationId={application.id}
          candidateTimezone={input.candidateTimezone}
          value={input.availability ?? []}
          onChange={(timezone, newTimes) => {
            onInputChange({ ...input, availability: newTimes, candidateTimezone: timezone });
          }}
          setIsImportingAvailability={setIsImportingAvailability}
        />
      )}
      <Divider />
      <Typography variant="subtitle1">Comment for the recruiting team</Typography>
      <Editor
        defaultValue={input.note || ''}
        sx={{
          border: `1px solid silver`,
          borderRadius: '6px',
          '& .ql-editor': { minHeight: '104px', maxHeight: '104px' },
        }}
        formats={[]}
        modules={{
          toolbar: false,
          clipboard: {
            matchVisual: false,
          },
        }}
        // eslint-disable-next-line max-params
        onChange={(value, _, __, editor) => {
          const cleanedText = editor.getText().trim();
          if (cleanedText) {
            onInputChange({ ...input, note: cleanedText });
          } else {
            onInputChange({ ...input, note: '' });
          }
        }}
      />
    </Stack>
  );
};

CreateTaskStep.fragments = {
  application: gql`
    ${InterviewPlan.fragments.job}
    ${JobStageSelect.fragments.job}
    ${QueueSelect.fragments.selectedTaskQueue}
    ${QueueSelect.fragments.defaultTaskQueue}
    fragment CreateTaskStep_application on Application {
      id
      jobStage {
        id
        name
      }
      job {
        id
        ...InterviewPlan_job
        ...JobStageSelect_job
        jobSettings {
          defaultTaskQueue {
            ...QueueSelect_defaultTaskQueue
          }
        }
      }
      candidate {
        id
        coordinator {
          id
          fullName
        }
        recruiter {
          id
          fullName
        }
      }
      atsApplication {
        atsId
        canMoveJobStage
      }
    }
  `,
  jobStage: gql`
    ${InterviewPlan.fragments.jobStage}
    ${useInterviewPlanEntityHasInterviewHiddenFromCandidate.fragments.interviewPlan}
    fragment CreateTaskStep_jobStage on JobStage {
      id
      ...InterviewPlan_jobStage
      ...useInterviewPlanHasInterviewHiddenFromCandidate_interviewPlan
    }
  `,
};

export default CreateTaskStep;
