import React, { FC, useMemo } from 'react';

import { gql } from '@apollo/client';
import { CopyTextField } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { CrossIcon } from '@modernloop/shared/icons';
import { Alert, Box, Divider, IconButton, LinearProgress, Stack } from '@mui/material';
import { maxBy, minBy } from 'lodash';

import { useZoomMeetingCreateMutation } from 'src/generated/mloop-graphql';

import ZoomUserSelect from 'src/entities/Select/ZoomUserSelect';
import { ZoomUserDataWithCategory } from 'src/entities/Select/ZoomUserSelect/types';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { setScheduleContentStepWaiting } from 'src/slices/scheduling';

import {
  clearZoomUserInfo,
  updateVideoMeetingLinkHostEmployeeId,
  updateZoomInfo,
  updateZoomUserId,
} from 'src/store/actions/schedule-communications';
import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { getZoomInfo, getZoomUserId } from 'src/store/selectors/schedule-communications';
import { ZoomDialInInfo } from 'src/store/slices/schedule-communications';
import { ScheduleUpdateType } from 'src/store/slices/schedule-update';

import { Theme } from 'src/themeMui5/type';

import { InterviewEvent } from 'src/utils/api/getScheduleOptions';
import { removeTypename } from 'src/utils/removeTypename';

import { getInterviewerIdsFromSchedule } from 'src/views-new/ScheduleFlow/Steps/Schedule/utils';

import { useDispatch, useSelector } from 'src/store';

import usePopulateZoomInterviewMeetingLocation from './usePopulateZoomInterviewMeetingLocation';

export const ZoomMeetingCreate = gql`
  mutation ZoomMeetingCreate($input: ZoomMeetingCreateInput!) {
    zoomMeetingCreate(input: $input) {
      joinUrl
      meetingId
      password
      pstnPassword
      dialInfo {
        country
        countryName
        city
        number
        type
      }
    }
  }
`;

const useSxProps = () => {
  return useMemo(() => {
    return {
      progress: {
        width: '100%',
        marginTop: (theme: Theme) => theme.spacing(1.5),
        marginBottom: (theme: Theme) => theme.spacing(1.5),
      },
    };
  }, []);
};

const ZoomSingle: FC = () => {
  const dispatch = useDispatch();
  const { selectedScheduleId } = useSelector((state) => state.scheduling);
  const zoomUserId = useSelector((state) => getZoomUserId(state, selectedScheduleId || ''));
  const zoomInfo = useSelector((state) => getZoomInfo(state, selectedScheduleId ?? ''));
  const { scheduling } = useSelector((state) => state);
  const sxProps = useSxProps();

  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const [zoomMeetingCreateMutation, { loading, error }] = useZoomMeetingCreateMutation();
  const populateZoomInterviewMeetingLocation = usePopulateZoomInterviewMeetingLocation({
    scheduleId: selectedScheduleId || '',
  });

  const schedule = useScheduleWithoutBreaks(selectedScheduleId);
  const interviewerIds = getInterviewerIdsFromSchedule(schedule);

  if (!selectedScheduleId) return null;

  const handleUserSelect = async (value: ZoomUserDataWithCategory) => {
    const newZoomUserId = value.zoomUserId;
    dispatch(updateZoomUserId(selectedScheduleId, newZoomUserId));

    if (value.employeeId) {
      dispatch(updateVideoMeetingLinkHostEmployeeId(selectedScheduleId, value.employeeId));
    }

    if (!schedule || !schedule.events) {
      return;
    }

    dispatch(setScheduleContentStepWaiting(true));

    if (newZoomUserId) {
      if (scheduleLocationEnabled) {
        populateZoomInterviewMeetingLocation({
          zoomUserId: newZoomUserId,
          hostEmployeeId: value.employeeId || undefined,
        });
      } else {
        await zoomMeetingCreateMutation({
          variables: {
            input: {
              zoomUserId: newZoomUserId,
              timeRange: {
                startAt: new Date(
                  (minBy(schedule?.events, 'startAt') as unknown as InterviewEvent).startAt
                ).toISOString(),
                endAt: new Date((maxBy(schedule?.events, 'endAt') as unknown as InterviewEvent).endAt).toISOString(),
              },
              title: scheduling.candidate?.fullName,
            },
          },
        })
          .then(async (result) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line promise/always-return
            try {
              const { data } = result;
              if (!data || !data.zoomMeetingCreate) return;

              const { zoomMeetingCreate: zoomData } = data;
              if (selectedScheduleId) {
                dispatch(
                  updateZoomInfo(selectedScheduleId, {
                    joinURL: zoomData.joinUrl || '',
                    meetingID: zoomData.meetingId,
                    password: zoomData.password || '',
                    pstnPassword: zoomData.pstnPassword as unknown as number,
                    dialInfo: zoomData?.dialInfo ? (removeTypename(zoomData.dialInfo) as ZoomDialInInfo[]) : undefined,
                  })
                );
              }
              schedule?.events?.forEach((interview) => {
                dispatch(
                  addInterviewScheduleUpdate({
                    type: 'ScheduleUpdateVideoUrl',
                    updateType: ScheduleUpdateType.EDIT,
                    scheduleId: schedule?.id || '',
                    applicationStageInterviewId: interview.id,
                  })
                );
              });
            } catch (err) {
              dispatch(setScheduleContentStepWaiting(false));
            }
          })
          .catch(() => {
            dispatch(setScheduleContentStepWaiting(false));
          });
      }
    }
  };

  return (
    <Stack data-testid="communications-zoom-single" spacing={1}>
      <ZoomUserSelect
        key={zoomUserId}
        dataTestId="communications-zoom-single-select"
        onChange={handleUserSelect}
        value={zoomUserId ?? undefined}
        panelInterviewerIds={interviewerIds}
      />
      {loading && <LinearProgress data-testid="communications-zoom-single-linearprogress" sx={sxProps.progress} />}
      {!scheduleLocationEnabled && zoomInfo?.joinURL && (
        <Box mt={2} data-testid="communications-zoom-single-join-url">
          <CopyTextField value={zoomInfo.joinURL} />
        </Box>
      )}
      {scheduleLocationEnabled && zoomInfo?.joinURL && (
        <Stack direction="row" mt={2} gap={1} data-testid="communications-zoom-single-join-url">
          <CopyTextField value={zoomInfo.joinURL} />
          <Divider flexItem orientation="vertical" />
          <IconButton onClick={() => dispatch(clearZoomUserInfo(selectedScheduleId))}>
            <CrossIcon />
          </IconButton>
        </Stack>
      )}
      {error && (
        <Alert severity="error">
          {error.name} - {error.message}
        </Alert>
      )}
    </Stack>
  );
};

export default ZoomSingle;
