import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ScheduleIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 2C7.75 1.58579 7.41422 1.25 7 1.25C6.58579 1.25 6.25 1.58579 6.25 2V3.25H5C3.48122 3.25 2.25 4.48122 2.25 6L2.25002 15C2.25002 16.5188 3.48124 17.75 5.00002 17.75H15C16.5188 17.75 17.75 16.5188 17.75 15L17.75 5.99999C17.75 4.48121 16.5188 3.25 15 3.25H13.75V2C13.75 1.58579 13.4142 1.25 13 1.25C12.5858 1.25 12.25 1.58579 12.25 2V3.25H7.75V2ZM6.99829 4.75L7 4.75L7.00171 4.75H12.9983L13 4.75L13.0017 4.75H15C15.6903 4.75 16.25 5.30964 16.25 6L16.25 15C16.25 15.6904 15.6904 16.25 15 16.25H5.00002C4.30966 16.25 3.75002 15.6904 3.75002 15L3.75 6C3.75 5.30964 4.30965 4.75 5 4.75H6.99829ZM7 9C7 9.55229 6.55229 10 6 10C5.44772 10 5 9.55229 5 9C5 8.44772 5.44772 8 6 8C6.55229 8 7 8.44772 7 9ZM6 14C6.55229 14 7 13.5523 7 13C7 12.4477 6.55229 12 6 12C5.44772 12 5 12.4477 5 13C5 13.5523 5.44772 14 6 14ZM8.25 9C8.25 8.58579 8.58579 8.25 9 8.25H14C14.4142 8.25 14.75 8.58579 14.75 9C14.75 9.41421 14.4142 9.75 14 9.75H9C8.58579 9.75 8.25 9.41421 8.25 9ZM9 12.25C8.58579 12.25 8.25 12.5858 8.25 13C8.25 13.4142 8.58579 13.75 9 13.75H14C14.4142 13.75 14.75 13.4142 14.75 13C14.75 12.5858 14.4142 12.25 14 12.25H9Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
