import getCandidatePortalApplicationUrl from './getCandidatePortalApplicationUrl';

/**
 * Candidate scheduling url
 * @param options - orgSlug, applicationId, taskId
 * @returns string
 */
const getCandidateSchedulingUrl = (options: { orgSlug: string; applicationId: string; taskId: string }): string => {
  const { orgSlug, applicationId, taskId } = options;
  const basePortalUrl = getCandidatePortalApplicationUrl({
    orgSlug,
    applicationId,
  });

  return `${basePortalUrl}/request/${taskId}`;
};

export default getCandidateSchedulingUrl;
