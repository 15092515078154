import ApiVerb from './ApiVerb';
import { DebriefRescheduleDetails, InterviewerContent, SlackChannelContent } from './createSchedule';
import { apiPost } from './index';
import { ZoomInfo } from './types';

interface CandidateEventContent {
  toEmailAddress: string;
  summary: string;
  description: string;
  videoMeetingUrl: string;
  calendarEventTemplateId?: string;
}

interface CandidateEmailContent {
  toEmailAddress: string;
  ccEmployeeIds: string[];
  bccEmployeeIds: string[];
  ccExternalAddresses: string[];
  bccExternalAddresses: string[];
  description: string;
  summary: string;
  attachments: { name: string; path: string }[];
  emailTemplateId?: string;
}

export interface UpdateInterviewer {
  interviewerId: string;
  interviewerRole: string;
  interviewId: string;
  jobStageInterviewSeatId?: string;
}

export interface UpdateInterviewEvent {
  applicationStageInterviewId: string;
  interviewer: UpdateInterviewer[];
  videoMeetingUrl: string;
  startAt: string;
  endAt: string;
  summary: string;
  description: string;
  codingInterviewUrl: string;
  interviewName: string;

  atsInterviewDefinitionId?: string;
  removeAtsScorecard?: boolean;
  emailTemplateId?: string;
  zoomInfo?: ZoomInfo;
  meetingRoomIds?: string[];
  isHiddenFromCandidate?: boolean;
  videoMeetingHostEmployeeId?: string;
}

export interface RequestBody {
  applicationStageId: string;
  interviewerContents: UpdateInterviewEvent[];
  interviewerCalendarId?: string;
  candidateCalendarId?: string;
  deletedApplicationStageInterviewIds?: string[];
  candidateEmailContent?: CandidateEmailContent;
  candidateEventContent?: CandidateEventContent;
  isPrivateCalendarEvent?: boolean;
  candidateTimezone?: string;
  addedInterviewerContents?: InterviewerContent[];
  slackChannelContent?: SlackChannelContent;
}

interface ResponseBody {
  error?: string;
  debrief_reschedule_details?: DebriefRescheduleDetails;
}

const updateSchedule = async (data: RequestBody): Promise<ResponseBody> => {
  const response = await apiPost(ApiVerb.UPDATE_SCHEDULE, data, {
    'axios-retry': {
      retries: 0,
    },
  });
  return response.data;
};

export default updateSchedule;
