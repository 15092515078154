import { useMemo } from 'react';

import { QueryResult, gql } from '@apollo/client';

import {
  MeetingsTokenQuery,
  RenderType,
  TemplateToken,
  TokenVideoMeetingLinkInput,
  TokenZoomMeetingInput,
  ZoomInfoInput,
  useMeetingsTokenQuery,
} from 'src/generated/mloop-graphql';

import {
  CustomVideoMeetingLinkUrlBySlotId,
  VideoMeetingLinkBySlotId,
  ZoomInfo,
  ZoomInfoBySlotId,
} from 'src/store/slices/schedule-communications';

import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';

import { EMPTY_OBJECT, EMPTY_UUID } from 'src/constants';

import placeholders from './utils/placeholders';

export const Messages = {
  UNIQUE_ZOOM_LINK_HELPER_EMAIL_TEMPLATE_MSG: 'See schedule details for Zoom meeting links',
  UNIQUE_MULTIPLE_CUSTOM_LINK_HELPER_EMAIL_TEMPLATE_MSG: 'See schedule details for meeting links',
};

/**
 * Meeting placeholder filler is responsible for fetching all the tokens related to meeting
 * NOTE: VIDEO_MEETING_LINK , ZOOM_MEETING_DIAL_IN_INFO  & ZOOM_MEETING_INFO are only fetched when single link
 */

export const MeetingTokensQuery = gql`
  query MeetingsToken(
    $id: String
    $renderType: RenderType!
    $zoom: TokenZoomMeetingInput!
    $videoMeetingLink: TokenVideoMeetingLinkInput!
    $hasVideoMeetingLink: Boolean!
    $hasZoom: Boolean!
  ) {
    VIDEO_MEETING_LINK: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasVideoMeetingLink) {
      id
      VIDEO_MEETING_LINK(input: $videoMeetingLink)
    }
    ZOOM_MEETING_DIAL_IN_INFO: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasZoom) {
      id
      ZOOM_MEETING_DIAL_IN_INFO(input: $zoom)
    }
    ZOOM_MEETING_INFO: templateToken(input: { id: $id, renderType: $renderType }) @include(if: $hasZoom) {
      id
      ZOOM_MEETING_INFO(input: $zoom)
    }
  }
`;

/**
 * TOKENS TO DISPLAY
 * Note Only add if eligible for display
 * */
export enum Tokens {
  VIDEO_MEETING_LINK = 'VIDEO_MEETING_LINK',
  ZOOM_MEETING_INFO = 'ZOOM_MEETING_INFO',
  ZOOM_MEETING_DIAL_IN_INFO = 'ZOOM_MEETING_DIAL_IN_INFO',
}

/**
 * TOKENS FETCHED
 * Tokens displayed are a subset of the tokens fetched
 */
export type MeetingPlaceholderFillerOptions = Pick<TemplateToken, keyof typeof Tokens>;
export default class MeetingPlaceholderFiller {
  static getFilledText = (input: string, options: MeetingPlaceholderFillerOptions): string => {
    return placeholders(input, options);
  };

  static getOptions = (data?: MeetingsTokenQuery): MeetingPlaceholderFillerOptions => {
    if (!data || !Object.keys(data).length) return EMPTY_OBJECT;
    return {
      VIDEO_MEETING_LINK: data.VIDEO_MEETING_LINK?.VIDEO_MEETING_LINK,
      ZOOM_MEETING_DIAL_IN_INFO: data.ZOOM_MEETING_DIAL_IN_INFO?.ZOOM_MEETING_DIAL_IN_INFO,
      ZOOM_MEETING_INFO: data.ZOOM_MEETING_INFO?.ZOOM_MEETING_INFO,
    };
  };
}

export const getZoomLinkInput = (zoomInfo?: ZoomInfo): ZoomInfoInput | undefined => {
  if (!zoomInfo) return undefined;
  return {
    joinUrl: zoomInfo?.joinURL || '',
    meetingId: zoomInfo?.meetingID ? String(zoomInfo.meetingID) : EMPTY_UUID,
    password: zoomInfo?.password || '',
  };
};

export const getZoomInput = (zoomInfo?: ZoomInfo): TokenZoomMeetingInput => {
  const zoomLinkInput = getZoomLinkInput(zoomInfo);
  const zoomMeetinInput: TokenZoomMeetingInput = {
    meetingId: zoomLinkInput?.meetingId || '',
    joinUrl: zoomLinkInput?.joinUrl || '',
    password: zoomLinkInput?.password || '',
    pstnPassword: zoomInfo && zoomInfo.pstnPassword ? `${zoomInfo.pstnPassword}` : '',
  };

  if (zoomInfo?.dialInfo?.length) {
    zoomMeetinInput.dialInfo = zoomInfo.dialInfo.map((dialInfo) => {
      return {
        number: dialInfo.number,
        country: dialInfo.country,
        city: dialInfo.city,
      };
    });
  }

  return zoomMeetinInput;
};

export const getCustomVideoMeetingLinkInput = (customVideoMeetingLinkUrl?: string): TokenVideoMeetingLinkInput => {
  if (!customVideoMeetingLinkUrl) return EMPTY_OBJECT;
  return {
    customVideoMeetingLinkUrl,
  };
};

export const getWorkspaceVideoLinkInput = (workspaceVideoLink: string): TokenVideoMeetingLinkInput => {
  return {
    videoMeetingLink: workspaceVideoLink,
  };
};

export const getVideoMeetingLinkInput = (
  zoomInfo?: ZoomInfo,
  customVideoMeetingLinkUrl?: string | null,
  workspaceVideoLink?: string | null // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
TokenVideoMeetingLinkInput => {
  const zoomLinkInput = getZoomLinkInput(zoomInfo);
  if (zoomLinkInput) {
    return { zoomInfo: zoomLinkInput };
  }

  if (customVideoMeetingLinkUrl) {
    return getCustomVideoMeetingLinkInput(customVideoMeetingLinkUrl);
  }

  if (workspaceVideoLink) {
    return getWorkspaceVideoLinkInput(workspaceVideoLink);
  }

  // Fallback when there is no link generated.
  return EMPTY_OBJECT;
};

export type Input = {
  schedule: InterviewSchedule | null;
  slotId?: string;
  renderType?: RenderType;
  zoomInfo?: ZoomInfo;
  zoomInfos?: ZoomInfoBySlotId;
  customVideoMeetingLinkUrl?: string;
  customVideoMeetingLinkUrls?: CustomVideoMeetingLinkUrlBySlotId;
  videoMeetingLinkUrls?: VideoMeetingLinkBySlotId;
  workspaceVideoLink?: string;
  hasZoomUniqueLinks?: boolean;
  hasCustomUniqueLinks?: boolean;
  isFetch?: boolean;
};

// This placeholder filler is responsible for filling the single meeting links.
// Unique links is handled by InterviewPlaceholderFiller.
export const useMeetingPlaceholderFillerOptions = ({
  schedule,
  slotId,
  renderType,
  zoomInfo,
  zoomInfos,
  customVideoMeetingLinkUrl,
  customVideoMeetingLinkUrls,
  videoMeetingLinkUrls,
  workspaceVideoLink,
  isFetch = true,
}: Input): [MeetingPlaceholderFillerOptions, Pick<QueryResult, 'data' | 'error' | 'loading'>] => {
  const scheduleId = schedule?.id || '';

  // When there is only one non-hidden interview event and user has generated unique meeting links,
  // we will use the unique link for that event to populate the VIDEO_MEETING_LINK token.
  // This is needed because BE filters out hidden events when populating INTERVIEW_SCHEDULE token.
  const nonHiddenInterviewEvents = useMemo(
    () => schedule?.events.filter((event) => !event.isHiddenFromCandidate) || [],
    [schedule?.events]
  );

  // We want this to be true only when slotId is not provided and there is only one non-hidden interview event.
  const hasOnlyOneNonHiddenInterviewEvent = Boolean(!slotId && schedule && nonHiddenInterviewEvents.length === 1);

  const hasZoomUniqueLinks = !hasOnlyOneNonHiddenInterviewEvent && zoomInfos && Object.keys(zoomInfos).length > 1;
  const hasCustomUniqueLinks =
    !hasOnlyOneNonHiddenInterviewEvent &&
    ((customVideoMeetingLinkUrls && Object.keys(customVideoMeetingLinkUrls).length > 1) ||
      (videoMeetingLinkUrls && Object.keys(videoMeetingLinkUrls).length > 1));

  const zInfo = useMemo(() => {
    let result = zoomInfo;

    if (hasOnlyOneNonHiddenInterviewEvent && nonHiddenInterviewEvents.length === 1) {
      result =
        zoomInfos && zoomInfos[nonHiddenInterviewEvents[0].slotId || '']
          ? zoomInfos[nonHiddenInterviewEvents[0].slotId || '']
          : zoomInfo;
    }

    return result;
  }, [hasOnlyOneNonHiddenInterviewEvent, nonHiddenInterviewEvents, zoomInfo, zoomInfos]);

  const customLink = useMemo(() => {
    return customVideoMeetingLinkUrl;
  }, [customVideoMeetingLinkUrl]);

  const videoMeetingLinkInput = useMemo(() => {
    let nonHiddenInterviewEventMeetingLink = '';

    if (hasOnlyOneNonHiddenInterviewEvent && nonHiddenInterviewEvents.length === 1) {
      nonHiddenInterviewEventMeetingLink = videoMeetingLinkUrls
        ? videoMeetingLinkUrls[nonHiddenInterviewEvents[0].slotId || '']
        : '';
    }

    return getVideoMeetingLinkInput(zInfo, customLink, nonHiddenInterviewEventMeetingLink || workspaceVideoLink);
  }, [
    customLink,
    hasOnlyOneNonHiddenInterviewEvent,
    nonHiddenInterviewEvents,
    videoMeetingLinkUrls,
    workspaceVideoLink,
    zInfo,
  ]);

  const hasVideoMeetingLink = !!(
    videoMeetingLinkInput.customVideoMeetingLinkUrl ||
    videoMeetingLinkInput.videoMeetingLink ||
    (videoMeetingLinkInput.zoomInfo?.meetingId && videoMeetingLinkInput.zoomInfo?.meetingId !== EMPTY_UUID)
  );

  const zoom = useMemo(() => {
    return getZoomInput(zInfo);
  }, [zInfo]);
  const hasZoom = !!zoom?.meetingId && zoom.meetingId !== EMPTY_UUID;

  const { data, loading, error } = useMeetingsTokenQuery({
    skip: !isFetch || hasZoomUniqueLinks || hasCustomUniqueLinks,
    variables: {
      id: scheduleId,
      renderType: renderType || RenderType.Html,
      videoMeetingLink: videoMeetingLinkInput,
      zoom,
      hasVideoMeetingLink,
      hasZoom,
    },
    errorPolicy: 'all',
  });

  return useMemo(() => {
    if (hasCustomUniqueLinks || hasZoomUniqueLinks) {
      // Returning early to avoid replacing for interviewer communications.
      // Meeting token replacements for interviewer communications are handled by InterviewPlaceholdeFiller.
      if (slotId) {
        return [EMPTY_OBJECT as MeetingPlaceholderFillerOptions, { data, loading, error }];
      }
      const text = hasCustomUniqueLinks
        ? Messages.UNIQUE_MULTIPLE_CUSTOM_LINK_HELPER_EMAIL_TEMPLATE_MSG
        : Messages.UNIQUE_ZOOM_LINK_HELPER_EMAIL_TEMPLATE_MSG;
      return [{ VIDEO_MEETING_LINK: text } as MeetingPlaceholderFillerOptions, { data, loading, error }];
    }

    return [MeetingPlaceholderFiller.getOptions(data), { data, loading, error }];
  }, [data, error, hasCustomUniqueLinks, hasZoomUniqueLinks, loading, slotId]);
};
