import React, { PropsWithChildren, useMemo, useRef } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Popover, PopoverProps } from '@material-ui/core';

import Paper from 'src/components/Paper';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/themeMui5/type';

import FilterList, { FilterListProps } from './index';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type FilterListPopoverProps<TData, TabType extends string | number> = BaseProps &
  FilterListProps<TData, TabType> &
  Pick<PopoverProps, 'open' | 'PaperProps'> & {
    onPopoverClose?: PopoverProps['onClose'];
    fullWidth?: boolean;
  };

const useSxProps = () => {
  const sxProps = useMemo(() => {
    return {
      filterListContainer: {
        display: 'flex',
        padding: (theme: Theme) => theme.spacing(1),
        flexDirection: 'column',
        overflow: 'hidden',
        '& > div': {
          flexGrow: 1,
        },
      },
    };
  }, []);

  return sxProps;
};

const FilterListPopover = <TData, TabType extends string | number = string>({
  dataTestId,
  open,
  PaperProps,
  onPopoverClose,
  children,
  fullWidth,
  ...filterListProps
}: PropsWithChildren<FilterListPopoverProps<TData, TabType>>): JSX.Element => {
  const sxProps = useSxProps();
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div style={fullWidth ? { width: '100%' } : {}}>
      <div ref={wrapperRef} data-testid={dataTestId}>
        {children}
      </div>
      <Popover
        open={open}
        anchorEl={wrapperRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        PaperProps={PaperProps ?? { style: { width: wrapperRef.current?.clientWidth } }}
        onClose={onPopoverClose}
        disableScrollLock
      >
        <Paper sx={sxProps.filterListContainer}>
          <FilterList {...filterListProps} />
        </Paper>
      </Popover>
    </div>
  );
};

export default FilterListPopover;
