import React, { useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Popover } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';

import IconButton from 'src/components/IconButton';
import Paper from 'src/components/Paper';
import Stack from 'src/components/Stack';
import Button from 'src/components/button';
import DateTimePicker from 'src/components/date-time-picker/DateTimePicker';
import { CaretDownIcon } from 'src/components/icons';
import Label from 'src/components/label';
import Menu, { MenuOption } from 'src/components/menu';
import DurationPicker, { DURATION_OPTIONS, MaxDuration } from 'src/components/menu/DurationMenu';

import { Theme } from 'src/theme/type';

import { IS_PRODUCTION } from 'src/constants';

import CalendarDateDisplay from './CalendarDateDisplay';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type DurationTimePickerProps = {
  anchorEl: React.RefObject<HTMLElement>;
  duration: number;
  utcTime: Date;
  timezone: string;
  showTimePicker?: boolean;
  utcAvailability?: number[];
  maxDuration?: MaxDuration;
  timezoneSelectComponent?: JSX.Element;
  hideTimePicker?: boolean;
  onChange: (duration: number, utcNewStartAt: Date | null) => void;
  clearTime: () => void;
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    setStartTime: {
      backgroundColor: theme.grey.solid.min,
      '& p': {
        color: theme.palette.info.main,
      },

      '&:hover': {
        backgroundColor: theme.grey.solid.min,
        '& p': {
          color: theme.palette.info.main,
        },
      },
    },
    durationButton: {
      borderRadius: '6px',
    },
    calendarButton: {
      '& button': {
        height: '32px',
        width: '32px',
        margin: '2px 4px',
      },
    },
    calendarButtonBorder: {
      '& button': {
        border: `1px solid ${theme.palette.border}`,
        borderRadius: '50%',
      },
    },
    calendarAvailabilityDay: {
      '& button': {
        border: `1px solid ${theme.palette.info.main}`,
        color: 'inherit',
      },
    },
  })
);

const SET_START_TIME_OPTION_ID = 'set-start-time';

const DurationTimePicker = ({
  anchorEl,
  duration,
  utcTime,
  timezone,
  showTimePicker,
  utcAvailability,
  maxDuration,
  timezoneSelectComponent,
  hideTimePicker,
  onChange,
  clearTime,
  onClose,
}: DurationTimePickerProps): JSX.Element => {
  const classes = useStyles();
  const [showDatePicker, setShowDatePicker] = useState(Boolean(showTimePicker));
  const [showDurationPicker, setShowDurationPicker] = useState(false);
  const [newDuration, setNewDuration] = useState(duration);
  const [newUtcTime, setNewUtcTime] = useState(utcTime);

  const durationPickerRef = useRef<HTMLButtonElement>(null);

  if (!showDatePicker) {
    let options = DURATION_OPTIONS.map((option) => {
      if (option.id === duration) {
        return { ...option, selected: true };
      }
      return option;
    });

    options = maxDuration
      ? options.filter(
          (option) => option.id && !Number.isNaN(option.id) && Number(option.id) <= maxDuration && option.id !== 0
        )
      : options;

    if (!IS_PRODUCTION) {
      options = [{ id: 1, value: '1 min [NOT-SHOWN-IN-PROD]' }, ...options];
    }

    if (!hideTimePicker) {
      options.unshift({
        id: SET_START_TIME_OPTION_ID,
        value: 'Set start time',
        isSticky: true,
        className: classes.setStartTime,
      });
    }

    const handleDurationSelect = (option: MenuOption) => {
      if (option.id === SET_START_TIME_OPTION_ID) {
        setShowDatePicker(true);
        return;
      }

      if (option.id && typeof option.id === 'number') {
        onChange(option.id, null);
      }
      onClose();
    };

    return (
      <Menu
        anchorEl={anchorEl.current}
        id="duration-time-picker"
        disableScrollLock
        open
        options={options}
        onSelect={handleDurationSelect}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            onClose();
          }
        }}
      />
    );
  }

  const durationText =
    DURATION_OPTIONS.find((option) => option.id === newDuration)?.value ||
    `${newDuration} ${newDuration === 1 ? 'min' : 'mins'}`;

  const handleClose = () => {
    onChange(newDuration, newUtcTime);
    onClose();
  };

  const handleClear = () => {
    clearTime();
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl.current}
      open
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      disableScrollLock
    >
      <Paper>
        <Stack direction="column" spacing={2}>
          <Label fontWeight={600} color="info" variant="captions">
            Start time
          </Label>
          {timezoneSelectComponent}
          <Stack spacing={1}>
            <DateTimePicker
              utcTime={newUtcTime}
              timezone={timezone}
              onChange={(time: Date) => setNewUtcTime(time)}
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line max-params
              renderDay={(day, selectedDate, _, dayComponent) => (
                <CalendarDateDisplay
                  day={day}
                  selectedDate={selectedDate}
                  dayComponent={dayComponent}
                  utcAvailability={utcAvailability}
                />
              )}
            />
            <Button label="Clear" variant="link" onClick={handleClear} />
          </Stack>
          <Label fontWeight={600} color="info" variant="captions">
            Duration
          </Label>
          <Stack>
            <Button
              fullWidth
              variant="outlined"
              className={classes.durationButton}
              label={durationText}
              ref={durationPickerRef}
              onClick={() => setShowDurationPicker(true)}
            />
            <IconButton onClick={() => setShowDurationPicker(true)}>
              <CaretDownIcon />
            </IconButton>
          </Stack>
          <DurationPicker
            id="duration-time-picker"
            open={showDurationPicker}
            anchorEl={durationPickerRef}
            maxDuration={maxDuration}
            onClose={() => setShowDurationPicker(false)}
            onSelect={(mins: number) => {
              setNewDuration(mins);
              setShowDurationPicker(false);
            }}
          />
        </Stack>
      </Paper>
    </Popover>
  );
};

export default DurationTimePicker;
