import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments, MoreMenuButton } from '@modernloop/shared/components';
import { isAfterNow } from '@modernloop/shared/datetime';
import { useFlag } from '@modernloop/shared/feature-flag';
import { ReverseShadowIcon, ShadowIcon, TraineeIcon, UserOptionalIcon } from '@modernloop/shared/icons';
import { Avatar, Box, MenuItem, Stack, Typography } from '@mui/material';

import {
  InterviewerRole,
  ResponseStatus,
  ScheduleContentInterviewDetailsInterviewer_InterviewFragment,
  ScheduleContentInterviewDetailsInterviewer_InterviewerFragment,
} from 'src/generated/mloop-graphql';

import AdjustInterviewerRsvp from 'src/entities/ScheduleContent/ScheduleContentInterview/AdjustInterviewerRsvp';
import getResponseStatusNode from 'src/entities/common/getResponseStatusNode';

type Fragments = {
  interview: ScheduleContentInterviewDetailsInterviewer_InterviewFragment;
  interviewer: ScheduleContentInterviewDetailsInterviewer_InterviewerFragment;
};

const ScheduleContentInterviewDetailsInterviewer: FCWithFragments<Fragments> = ({ interview, interviewer }) => {
  const isFastTrackTrainingEnabled = useFlag('user_fast_track_interview_module');
  const [showChangeRsvpDialog, setShowChangeRsvpDialog] = useState(false);

  const handleChangeRsvp = () => {
    setShowChangeRsvpDialog(true);
  };

  const getTraineeIcon = () => {
    if (
      isFastTrackTrainingEnabled &&
      isAfterNow(interview.startAt) &&
      interviewer.role !== InterviewerRole.Interviewer
    ) {
      return <TraineeIcon tooltip="Trainee" />;
    }
    if (interviewer.role === InterviewerRole.Shadow) {
      return <ShadowIcon color="action" tooltip="Shadow" />;
    }
    if (interviewer.role === InterviewerRole.ReverseShadow) {
      return <ReverseShadowIcon color="action" tooltip="Reverse shadow" />;
    }
    return null;
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      key={interviewer.interviewerId}
      data-testid="schedule-content-interview-details-interviewer"
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar src={interviewer.interviewer?.slackImageUrl || ''}>
              {interviewer.interviewer?.fullName?.charAt(0)}
            </Avatar>
            <Typography variant="body2" data-testid="schedule-content-interview-details-interviewer-name">
              {interviewer.interviewer?.fullName}
            </Typography>
          </Stack>

          {interviewer.calendarEventNote && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Box width="20px" />
              <Typography
                data-testid="schedule-content-interview-details-interviewer-event-note"
                variant="body2"
                color="text.secondary"
              >
                {interviewer.calendarEventNote}
              </Typography>
            </Stack>
          )}
        </Stack>

        {getTraineeIcon()}
        {interviewer.isOptional && (
          <UserOptionalIcon
            data-testid="schedule-content-interview-details-interviewer-optional-icon"
            tooltip="Optional"
          />
        )}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={0.5}>
        {getResponseStatusNode(interviewer.responseStatus || ResponseStatus.NeedsAction)}
        <MoreMenuButton dataTestId="schedule-content-interview-details-interviewer-more-menu-button">
          <MenuItem
            data-testid="schedule-content-interview-details-interviewer-change-rsvp-menu-item"
            id="change_rsvp"
            key="change_rsvp"
            onClick={handleChangeRsvp}
          >
            Change RSVP
          </MenuItem>
        </MoreMenuButton>
      </Stack>

      {showChangeRsvpDialog && (
        <AdjustInterviewerRsvp
          interview={interview}
          interviewerId={interviewer.interviewerId}
          onClose={() => setShowChangeRsvpDialog(false)}
        />
      )}
    </Stack>
  );
};

ScheduleContentInterviewDetailsInterviewer.fragments = {
  interview: gql`
    ${AdjustInterviewerRsvp.fragments.interview}
    fragment ScheduleContentInterviewDetailsInterviewer_interview on ApplicationStageInterview {
      id
      ...AdjustInterviewerRsvp_interview
    }
  `,
  interviewer: gql`
    fragment ScheduleContentInterviewDetailsInterviewer_interviewer on ApplicationStageInterviewInterviewer {
      applicationStageInterviewId
      interviewerId
      interviewer {
        id
        fullName
        slackImageUrl
      }
      responseStatus
      calendarEventNote
      isOptional
      role
    }
  `,
};

export default ScheduleContentInterviewDetailsInterviewer;
