import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskRescheduleLogEdited_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import ActivityLogText from 'src/entities/ActivityLog/Helpers/ActivityLogText';

interface Fragments {
  activityLog: ActivityLogTaskRescheduleLogEdited_ActivityLogFragment;
}

const ActivityLogTaskRescheduleLogEdited: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (!details || details.__typename !== 'ActivityLogRescheduleLogDetails') {
    throw new Error('ActivityLogTaskRescheduleLogEdited: details is not ActivityLogRescheduleLogDetails');
  }

  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={<ActivityLogText>edited a reschedule log&nbsp;</ActivityLogText>}
    />
  );
};

ActivityLogTaskRescheduleLogEdited.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}

    fragment ActivityLogTaskRescheduleLogEdited_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogRescheduleLogDetails {
          __typename
          rescheduleLog {
            id
            note
          }
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskRescheduleLogEdited;
