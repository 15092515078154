import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { CrossIcon, EditIcon, StarFilledIcon, StarIcon } from '@modernloop/shared/icons';
import { Box, IconButton, Avatar as MuiAvatar, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { EmployeeFragment, FreeformSeat_SeatFragment } from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import Chip from 'src/components/chip';
import Tooltip from 'src/components/tooltip';

import { PREFERENCE_LEVEL_NON_PREFERRED, PREFERENCE_LEVEL_PREFERRED } from 'src/constants/InterviewPlan';

import { EmployeeFragment as EmployeeFragmentDoc } from 'src/entities/EmployeePicker/EmployeeGraphQL';

import { useAvatarSxProps, useGridSxProps, useSxProps } from './sxProps';

type Props = {
  handleMouseOut: () => void;
  handleMouseOver: () => void;
  seatIndex: number;
  onEdit: () => void;
  showOptions: boolean;
  onDelete: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onInterviewerPreferenceChanged: (employeeId: string, preferenceLevel: number) => void;
};

interface Fragments {
  seat: FreeformSeat_SeatFragment;
}

const FreeformSeat: FCWithFragments<Fragments, Props> = ({
  seat,
  handleMouseOut,
  handleMouseOver,
  onEdit,
  seatIndex,
  showOptions,
  onDelete,
  onInterviewerPreferenceChanged,
}) => {
  const sxProps = useSxProps();
  const gridSxProps = useGridSxProps();
  const avatarSxProps = useAvatarSxProps();

  if (!seat.freeformSeat) return null;

  const { jobStageInterviewSeatEmployees } = seat.freeformSeat;
  if (!jobStageInterviewSeatEmployees) return null;

  const employeeIdMap: { [key: string]: EmployeeFragment } = {};
  seat.freeformSeat?.jobStageInterviewSeatEmployees?.forEach((emp) => {
    if (emp?.employee) {
      employeeIdMap[emp.employeeId] = emp.employee;
    }
  });

  const employeeDisabled = jobStageInterviewSeatEmployees.reduce((disabled, emp) => {
    return (
      disabled ||
      !employeeIdMap[emp.employeeId]?.hasAtsId ||
      !employeeIdMap[emp.employeeId] ||
      employeeIdMap[emp.employeeId].isArchived ||
      employeeIdMap[emp.employeeId].isAtsDisabled ||
      employeeIdMap[emp.employeeId].isDirectoryDisabled
    );
  }, false);

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onEdit();
  };

  return (
    <Grid>
      <Box
        data-testid="freeform-seat-box-container"
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        sx={sxProps.box}
        onClick={handleEdit}
      >
        <Grid container justifyContent="space-between" sx={gridSxProps.root} spacing={1} tabIndex={0} wrap="nowrap">
          <Grid xs={10}>
            <Grid
              container
              wrap="nowrap"
              alignItems={jobStageInterviewSeatEmployees.length > 1 ? 'baseline' : 'center'}
            >
              <Grid sx={sxProps.one}>
                <MuiAvatar
                  data-testid={
                    employeeDisabled ? 'freeform-seat-index-label-with-disabled-employees' : 'freeform-seat-index-label'
                  }
                  sx={[avatarSxProps.root, employeeDisabled && sxProps.errorChip]}
                >
                  {seatIndex + 1}
                </MuiAvatar>
              </Grid>

              <Grid>
                <Grid container direction="column">
                  {jobStageInterviewSeatEmployees.length > 1 && (
                    <Grid sx={sxProps.one} data-testid="freeform-seat-one-of-label">
                      <Typography>One of:</Typography>
                    </Grid>
                  )}
                  {jobStageInterviewSeatEmployees.map((emp, index) => {
                    const id = emp.employeeId;
                    const preferenceLevel =
                      jobStageInterviewSeatEmployees?.find((e) => e.employeeId === id)?.preferenceLevel ||
                      PREFERENCE_LEVEL_NON_PREFERRED;

                    return (
                      <Grid container key={id} data-testid={`freeform-seat-interviewers-container-${index}`}>
                        <Tooltip
                          tooltip={
                            <Stack direction="column">
                              <Typography color="text.primary">{employeeIdMap[id].fullName}</Typography>
                              <Typography color="text.primary">{employeeIdMap[id].email}</Typography>
                            </Stack>
                          }
                        >
                          <Chip
                            sx={
                              !employeeIdMap[id]?.hasAtsId ||
                              employeeIdMap[id]?.isDirectoryDisabled ||
                              employeeIdMap[id]?.isAtsDisabled ||
                              employeeIdMap[id]?.isArchived
                                ? sxProps.errorChip
                                : undefined
                            }
                            label={employeeIdMap[id]?.fullName}
                            avatar={
                              <Avatar
                                src={employeeIdMap[id]?.slackImageUrl || undefined}
                                alt={employeeIdMap[id]?.fullName || ''}
                              />
                            }
                            variant={showOptions ? 'outlined' : 'default'}
                          />
                        </Tooltip>
                        <Tooltip
                          tooltip={
                            preferenceLevel === PREFERENCE_LEVEL_NON_PREFERRED
                              ? 'Mark as preferred'
                              : 'Mark as not preferred'
                          }
                          placement="right"
                        >
                          <IconButton
                            data-testid={`freeform-seat-preferred-interviewer-icon-button-${index}`}
                            size="small"
                            onClick={(e: React.MouseEvent) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onInterviewerPreferenceChanged(
                                id,
                                preferenceLevel === PREFERENCE_LEVEL_NON_PREFERRED
                                  ? PREFERENCE_LEVEL_PREFERRED
                                  : PREFERENCE_LEVEL_NON_PREFERRED
                              );
                            }}
                          >
                            {preferenceLevel === PREFERENCE_LEVEL_NON_PREFERRED ? (
                              <StarIcon data-testid={`freeform-seat-star-icon-${index}`} />
                            ) : (
                              <StarFilledIcon data-testid={`freeform-seat-star-filled-icon-${index}`} color="warning" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end" alignItems="center" xs sx={sxProps.options} wrap="nowrap">
            <Grid>
              <Tooltip tooltip="Edit">
                <IconButton
                  sx={sxProps.actionButton}
                  onClick={handleEdit}
                  size="small"
                  data-testid="freeform-seat-edit-icon-button"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid>
              <Tooltip tooltip="Delete">
                <IconButton
                  sx={sxProps.actionButton}
                  onClick={onDelete}
                  size="small"
                  data-testid="freeform-seat-delete-icon-button"
                >
                  <CrossIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

FreeformSeat.fragments = {
  seat: gql`
    ${EmployeeFragmentDoc}
    fragment FreeformSeat_seat on JobStageInterviewSeat {
      id
      freeformSeat {
        jobStageInterviewSeatEmployees {
          jobStageInterviewSeatId
          employeeId
          employee {
            id
            ...Employee
          }
          preferenceLevel
        }
      }
    }
  `,
};

export default FreeformSeat;
