import { useMemo } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';

import { InterviewModuleMemberMenuOptionsFragment, TrainingStatus } from 'src/generated/mloop-graphql';

import { MenuOption } from 'src/components/menu';

export enum MoreMenuOptions {
  Delete = 'Delete',
  SET_AS_TRAINEE = 'SET_AS_TRAINEE',
  SET_AS_TRAINED = 'SET_AS_TRAINED',
}

export const useInterviewModuleMemberMenuOptions: FunctionWithFragments<
  { moduleMember: InterviewModuleMemberMenuOptionsFragment | undefined },
  undefined,
  MenuOption[] | undefined
> = ({ moduleMember }) => {
  return useMemo(() => {
    if (!moduleMember) return undefined;

    const options: MenuOption[] = [];

    if (moduleMember.status === TrainingStatus.Trained) {
      options.push({ id: MoreMenuOptions.SET_AS_TRAINEE, value: 'Set as trainee' });
    } else {
      options.push({ id: MoreMenuOptions.SET_AS_TRAINED, value: 'Set as trained' });
    }

    options.push({ id: MoreMenuOptions.Delete, value: 'Remove from module', color: 'error' });

    return options;
  }, [moduleMember]);
};

useInterviewModuleMemberMenuOptions.fragments = {
  moduleMember: gql`
    fragment InterviewModuleMemberMenuOptions on InterviewModuleMember {
      employeeId
      interviewModuleId
      status
    }
  `,
};
