/* eslint-disable max-lines */
import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { EventContentArg } from '@fullcalendar/common';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box, Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FCWithFragments } from '@modernloop/shared/components';
import { getTZAbbr } from '@modernloop/shared/datetime';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import {
  EventContent_InterviewFragment,
  EventContent_OriginalInterviewsFragment,
  ResponseStatus,
} from 'src/generated/mloop-graphql';

import Stack from 'src/components/Stack';
import { CalendarEvent } from 'src/components/calendar';
import { CheckIcon, WarningIcon } from 'src/components/icons';
import Label from 'src/components/label';

import { Conflict } from 'src/store/slices/conflicts';

import { Theme } from 'src/theme/type';

import { CalendarFreeBusyStatus, Event, EventTag, RSVP } from 'src/utils/api/getEmployeeCalendarEvents';
import { InterviewEvent, RichInterviewer } from 'src/utils/api/getScheduleOptions';
import { formatRange } from 'src/utils/dateUtils';

import InterviewDetails from 'src/views-new/ScheduleFlow/Steps/Schedule/InterviewDetails';
import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import EventDetails from './EventDetails';
import { CalendarEventType, getEmployeeIdFromCalendarEventId } from './utils';

type Fragments = {
  interview: EventContent_InterviewFragment | undefined;
  originalInterviews: EventContent_OriginalInterviewsFragment[];
};

type Props = {
  organizationId: string;
  scheduleId: string;
  atsJobId: string;
  interviewPlanId: string;
  args: EventContentArg;
  disableConflict?: boolean;
  conflict: Conflict | undefined;
  scheduleFlowType: ScheduleFlowType;
  markAsConflict?: (event: Event, isConflict: boolean, eventTz: string, eventInterviewer: RichInterviewer) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventContainer: {
      backgroundColor: theme.palette.background.alternate,
      border: `1px solid ${theme.grey.alpha.mid}`,
      borderRadius: '6px',
      padding: '0 4px',
      height: '100%',
      overflow: 'hidden',
      cursor: 'pointer',
    },

    eventContainerBackground: {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.dark}`,
    },

    hiddenEventContainerBackground: {
      border: `2px solid ${theme.palette.primary.main}`,
    },

    eventContainerConflictBackground: {
      backgroundColor: theme.palette.background.error,
      border: `2px solid ${theme.palette.error.main}`,
    },

    eventContainerHardConflictBackground: {
      backgroundColor: theme.palette.error.main,
    },

    // Required for showing ellipses on long event title
    eventLabelContainer: {
      width: '100%',
    },

    eventLabel: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline-flex',
    },

    eventLabelDeclined: {
      textDecoration: 'line-through',
    },

    eventLabelInterviewEvent: {
      color: theme.palette.primary.contrastText,
    },

    hiddenEventLabelInterviewEvent: {
      color: theme.palette.primary.main,
    },

    eventLabelInterviewEventConflict: {
      color: theme.palette.error.main,
    },

    eventLabelInterviewEventHardConflict: {
      color: theme.palette.primary.contrastText,
    },

    candidateEventOverlappingConflict: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      border: `2px solid ${theme.palette.error.dark}`,
    },

    hiddenEventOverlappingConflict: {
      border: `2px solid ${theme.palette.warning.main}`,
    },

    hiddenEventLabelOverlappingConflict: {
      color: theme.palette.text.secondary,
    },
  })
);

enum PrependEventDisplayStatus {
  CONFLICT = 'Conflict',
  OOO = 'Out of office',
  AVOID = 'Avoid',
  RECRUITING_BLOCK = 'Recruiting block',
  AVAILABLE_TIME_BLOCK = 'Available',
  FREE = 'Free',
}

const getPrependEventTitle = (event: CalendarEvent, isConflict: boolean): JSX.Element | null => {
  if (event.is_all_day) return null;
  let prependText = '';
  if (event.event_tag?.includes(EventTag.OOO)) {
    prependText = PrependEventDisplayStatus.OOO;
  } else if (event.event_tag?.includes(EventTag.UNAVAILABLE_TIME_BLOCK)) {
    prependText = PrependEventDisplayStatus.AVOID;
  } else if (isConflict) {
    prependText = PrependEventDisplayStatus.CONFLICT;
  } else if (event.event_tag?.includes(EventTag.RECRUITING_BLOCK)) {
    prependText = PrependEventDisplayStatus.RECRUITING_BLOCK;
  } else if (event.event_tag?.includes(EventTag.AVAILABLE_TIME_BLOCK)) {
    prependText = PrependEventDisplayStatus.AVAILABLE_TIME_BLOCK;
  } else if (event.free_busy === CalendarFreeBusyStatus.STATUS_FREE) {
    prependText = PrependEventDisplayStatus.FREE;
  }
  if (prependText) {
    return (
      <Label color="inherit" fontWeight={600}>
        {prependText}:&nbsp;
      </Label>
    );
  }
  return null;
};

const EventContent: FCWithFragments<Fragments, Props> = ({
  organizationId,
  scheduleId,
  args,
  disableConflict,
  conflict,
  scheduleFlowType,
  interview,
  originalInterviews,
  atsJobId,
  interviewPlanId,
  markAsConflict,
}): JSX.Element | null => {
  const classes = useStyles();
  const type = args.event.extendedProps?.type as CalendarEventType;
  const isConflict = args.event.extendedProps?.isConflict as boolean;
  const isConflictWithHiddenEvent = args.event.extendedProps?.isConflictWithHiddenEvent as boolean;
  const eventTz = args.event.extendedProps?.timezone as string;

  const [showEventDetails, setShowEventDetails] = useState(false);
  const [selectedInterviewEvent, setSelectedInterviewEvent] = useState<InterviewEvent | null>(null);

  const formatTimeRangeTz = (startAt: string | Date, endAt: string | Date) => {
    return `${formatRange(startAt, endAt, eventTz, '', 'h:mm aaa', 'MMM d', 'h:mm aaa')} ${getTZAbbr(eventTz)}`;
  };

  if (type === CalendarEventType.INTERVIEWER_CALENDAR_EVENTS) {
    const employeeEvent = args.event.extendedProps?.employeeEvent as Event;
    const interviewer = args.event.extendedProps?.interviewer as RichInterviewer;
    const showDefaultConflictBackground =
      isConflict && (!conflict || conflict.isHardConflict === undefined) && !disableConflict;
    const isMarkedAsHardConflict = conflict?.isHardConflict === true && !disableConflict;

    if (!employeeEvent || !interviewer) return null;

    const eventTitle = isEmpty(args.event.title) ? 'Busy' : args.event.title;

    return (
      <Box
        className={clsx(classes.eventContainer, {
          [classes.eventContainerConflictBackground]: showDefaultConflictBackground,
          [classes.eventContainerHardConflictBackground]: isMarkedAsHardConflict,
        })}
        onClick={() => {
          setShowEventDetails(true);
        }}
        data-testid="schedule-interviewer-calendar-event-content"
      >
        <Grid container direction="column">
          <Stack className={classes.eventLabelContainer} justifyContent="space-between" wrap="nowrap">
            <Label
              className={clsx(classes.eventLabel, {
                [classes.eventLabelDeclined]: employeeEvent.rsvp === RSVP.RSVP_DECLINED,
                [classes.eventLabelInterviewEventConflict]: showDefaultConflictBackground,
                [classes.eventLabelInterviewEventHardConflict]: isMarkedAsHardConflict,
              })}
            >
              <>
                {getPrependEventTitle(args.event.extendedProps.employeeEvent, isConflict)}
                {eventTitle}
              </>
            </Label>
            <Stack>
              {showDefaultConflictBackground && <WarningIcon color="error" />}
              {isMarkedAsHardConflict && <WarningIcon color="background" />}
              {employeeEvent.rsvp === RSVP.RSVP_ACCEPTED && (
                <CheckIcon color={isMarkedAsHardConflict ? 'background' : undefined} />
              )}
            </Stack>
          </Stack>
          {!args.event.allDay && (
            <Grid item className={classes.eventLabelContainer}>
              <Label
                className={clsx(classes.eventLabel, {
                  [classes.eventLabelInterviewEventConflict]: showDefaultConflictBackground,
                  [classes.eventLabelInterviewEventHardConflict]: isMarkedAsHardConflict,
                })}
              >
                {(!employeeEvent.start || !employeeEvent.end) && args.timeText}
                {employeeEvent.start && employeeEvent.end && formatTimeRangeTz(employeeEvent.start, employeeEvent.end)}
              </Label>
            </Grid>
          )}
        </Grid>
        {showEventDetails && (
          <EventDetails
            open
            disableConflict={disableConflict}
            isConflict={isConflict}
            conflict={conflict}
            event={employeeEvent}
            timezone={eventTz}
            markAsConflict={(isMarkedConflict: boolean) => {
              if (markAsConflict) {
                markAsConflict(employeeEvent, isMarkedConflict, eventTz, interviewer);
              }
              setShowEventDetails(false);
            }}
            onClose={() => setShowEventDetails(false)}
          />
        )}
      </Box>
    );
  }

  const event = args.event.extendedProps?.event as InterviewEvent;

  const handleInterviewDetailsClose = () => {
    setSelectedInterviewEvent(null);
  };

  const interviewDetailsJsx = selectedInterviewEvent ? (
    <InterviewDetails
      organizationId={organizationId}
      open
      scheduleId={scheduleId}
      interviewEvent={selectedInterviewEvent}
      scheduleFlowType={scheduleFlowType}
      atsJobId={atsJobId}
      interviewPlanId={interviewPlanId}
      interview={interview}
      originalInterviews={originalInterviews}
      onClose={handleInterviewDetailsClose}
    />
  ) : null;

  if (type === CalendarEventType.INTERVIEWER_EVENT) {
    const { interviewers } = event;
    const interviewerId = getEmployeeIdFromCalendarEventId(args.event.id);
    const interviewer = interviewers.find((value) => value.employee.id === interviewerId);

    if (!interviewer) return null;
    const { responseStatus } = interviewer;
    const hasDeclined = responseStatus === ResponseStatus.Declined;

    return (
      <Box
        className={clsx(classes.eventContainer, {
          [classes.eventContainerBackground]: !event.isHiddenFromCandidate,
          [classes.hiddenEventContainerBackground]: event.isHiddenFromCandidate,
          [classes.eventContainerConflictBackground]: hasDeclined,
          [classes.eventContainerHardConflictBackground]: hasDeclined,
        })}
        onClick={() => {
          setSelectedInterviewEvent(event);
        }}
        data-testid="schedule-interviewer-event-content"
      >
        <Grid container direction="column">
          <Grid item container wrap="nowrap" className={classes.eventLabelContainer}>
            <Label
              className={clsx(classes.eventLabel, {
                [classes.eventLabelInterviewEvent]: !event.isHiddenFromCandidate,
                [classes.hiddenEventLabelInterviewEvent]: event.isHiddenFromCandidate,
              })}
            >
              {event.isHiddenFromCandidate ? (
                <Label color="inherit" fontWeight={600}>
                  Hidden:&nbsp;
                </Label>
              ) : null}
              {args.event.title}
            </Label>
            {hasDeclined && <WarningIcon color="background" />}
          </Grid>
          <Grid item className={classes.eventLabelContainer}>
            <Label
              className={clsx(classes.eventLabel, {
                [classes.eventLabelInterviewEvent]: !event.isHiddenFromCandidate,
                [classes.hiddenEventLabelInterviewEvent]: event.isHiddenFromCandidate,
              })}
            >
              {(!args.event.start || !args.event.end) && args.timeText}
              {args.event.start && args.event.end && formatTimeRangeTz(args.event.start, args.event.end)}
            </Label>
          </Grid>
        </Grid>
        {interviewDetailsJsx}
      </Box>
    );
  }

  return (
    <Box
      className={clsx(classes.eventContainer, {
        [classes.eventContainerBackground]: !event.isHiddenFromCandidate,
        [classes.hiddenEventContainerBackground]: event.isHiddenFromCandidate,
        [classes.candidateEventOverlappingConflict]: isConflict && !event.isHiddenFromCandidate,
        [classes.hiddenEventOverlappingConflict]: isConflictWithHiddenEvent && event.isHiddenFromCandidate,
      })}
      onClick={() => {
        setSelectedInterviewEvent(event);
      }}
      data-testid="schedule-candidate-event-content"
    >
      <Grid container direction="column">
        <Grid item className={classes.eventLabelContainer}>
          <Label
            className={clsx(classes.eventLabel, {
              [classes.eventLabelInterviewEvent]: !event.isHiddenFromCandidate,
              [classes.hiddenEventLabelInterviewEvent]: event.isHiddenFromCandidate,
              [classes.hiddenEventLabelOverlappingConflict]: isConflictWithHiddenEvent && event.isHiddenFromCandidate,
            })}
          >
            {event.isHiddenFromCandidate ? (
              <Label color="inherit" fontWeight={600}>
                Hidden:&nbsp;
              </Label>
            ) : null}
            {args.event.title}
          </Label>
        </Grid>
        <Grid item className={classes.eventLabelContainer}>
          <Label
            className={clsx(classes.eventLabel, {
              [classes.eventLabelInterviewEvent]: !event.isHiddenFromCandidate,
              [classes.hiddenEventLabelInterviewEvent]: event.isHiddenFromCandidate,
              [classes.hiddenEventLabelOverlappingConflict]: isConflictWithHiddenEvent && event.isHiddenFromCandidate,
            })}
          >
            {(!args.event.start || !args.event.end) && args.timeText}
            {args.event.start && args.event.end && formatTimeRangeTz(args.event.start, args.event.end)}
          </Label>
        </Grid>
      </Grid>
      {interviewDetailsJsx}
    </Box>
  );
};

EventContent.fragments = {
  interview: gql`
    ${InterviewDetails.fragments.interview}
    fragment EventContent_interview on JobStageInterview {
      id
      ...InterviewDetails_interview
    }
  `,
  originalInterviews: gql`
    ${InterviewDetails.fragments.originalInterviews}
    fragment EventContent_originalInterviews on JobStageInterview {
      id
      ...InterviewDetails_originalInterviews
    }
  `,
};

export default EventContent;
