import React, { useRef } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Typography,
} from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Alert } from '@material-ui/lab';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/styles';
import { find, first, sortBy } from 'lodash';

import Button from 'src/components/button';

import { FileStatus, FileUploadFlow, UIFile, removeUploadFile, startFileUpload } from 'src/slices/files';

import { useDispatch, useSelector } from 'src/store';

const useStyles = makeStyles({
  containerCommon: {
    marginLeft: 0,
    marginRight: 0,
  },
  listRoot: {
    marginLeft: 0,
    marginRight: 0,
  },
});

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface AddAttachmentProps {
  flow: FileUploadFlow;
  disabled?: boolean;
}

const MAX_ATTACHMENT_LIMIT = 20 * 1024 * 1024; // 20 MB
const SINGLE_ATTACHMENT_LIMIT = 10 * 1024 * 1024; // 10 MB
const ALLOWED_MIME_TYPES = [
  'image/*', // all images
  'application/pdf', // pdf
  'application/msword', // doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/vnd.ms-excel', // xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  'application/vnd.ms-powerpoint', // ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
];

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
export default function AddAttachment({ flow, disabled = false }: AddAttachmentProps): JSX.Element | null {
  const styles = useStyles();
  const dictFiles = useSelector((state) => state.fileUpload[flow]);
  const dispatch = useDispatch();
  const files = sortBy(dictFiles, 'timestamp') as UIFile[];
  const existingAttachmentSize = files.reduce((total, file) => total + file.size, 0);
  const [error, setError] = React.useState('');
  const fileRef = useRef<HTMLInputElement>(null);

  const showError = (message: string) => {
    // emptying the input value to allow the user to upload the file again after the error
    if (fileRef.current) {
      fileRef.current.value = '';
    }
    setError(message);
  };

  const fileOnChange = (e) => {
    const file = first(e.target.files) as File;
    if (!file) return;
    if (find(files, { name: file.name })) {
      setError(`Cannot upload duplicate file ${file.name}`);
      return;
    }
    if (file.size > SINGLE_ATTACHMENT_LIMIT) {
      showError(`Exceeds maximum per attachment size of 10MB`);
      return;
    }
    if (existingAttachmentSize + file.size > MAX_ATTACHMENT_LIMIT) {
      showError(`Exceeds maximum attachment size of 20MB`);
      return;
    }
    dispatch(startFileUpload(flow, file));
    // clear out input value
    if (fileRef.current) {
      fileRef.current.value = '';
    }
  };

  const removeFile = (file) => {
    dispatch(removeUploadFile(flow, file.name));
  };

  return (
    <Container classes={{ root: styles.containerCommon }} disableGutters>
      {files.length > 0 && (
        <Container classes={{ root: styles.listRoot }} maxWidth="xs" disableGutters>
          <List>
            {files.map((file: UIFile) => {
              return (
                <React.Fragment key={file.name}>
                  {file.status !== FileStatus.UPLOADING && (
                    <ListItem dense>
                      <ListItemIcon>
                        <InsertDriveFileOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>{file.name}</ListItemText>
                      {!disabled && (
                        <IconButton onClick={() => removeFile(file)}>
                          <HighlightOffIcon fontSize="small" />
                        </IconButton>
                      )}
                    </ListItem>
                  )}
                  {file.status === FileStatus.UPLOADING && (
                    <ListItem dense>
                      <ListItemText>Uploading {file.name}...</ListItemText>
                    </ListItem>
                  )}
                  {file.status === FileStatus.ERRORED && (
                    <ListItem dense>
                      <ListItemText primaryTypographyProps={{ color: 'error' }}>
                        Error uploading {file.name}
                      </ListItemText>
                    </ListItem>
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </Container>
      )}
      {!disabled && (
        <>
          <Button variant="contained" component="label" label="Add attachment">
            <input type="file" accept={ALLOWED_MIME_TYPES.join(',')} ref={fileRef} onChange={fileOnChange} hidden />
          </Button>
          <Box mt={1} ml={1}>
            <Typography variant="caption">Up to 10MB per attachment and a total of 20MB for all attachments</Typography>
          </Box>
        </>
      )}
      <Snackbar open={error.length > 0} autoHideDuration={5000} onClose={() => setError('')}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </Container>
  );
}
