import { v4 as uuid } from 'uuid';

import { EmployeeFragment, TaskCreateInput, TaskTagsBase_TaskTagFragment } from 'src/generated/mloop-graphql';

export type TaskCreateInputWrapper = TaskCreateInput & {
  assignee?: Pick<EmployeeFragment, 'id' | 'fullName' | 'slackImageUrl'> | undefined | null;
  taskTags?: TaskTagsBase_TaskTagFragment[] | undefined | null;
};

const emptyInterviewPlan = (customJobStageId: string) => [
  {
    id: uuid(),
    index: 0,
    jobStageId: customJobStageId,
    jobStageInterviews: [],
    locked: false,
  },
];

export const getTaskCreateInput = (task: TaskCreateInputWrapper): TaskCreateInput => {
  const { assignee, taskTags, ...rest } = task;

  // If there is no plan, create an empty plan before saving
  if (rest.customInterviewPlan?.length === 0) {
    rest.customInterviewPlan = emptyInterviewPlan(rest.customJobStageId);
  }

  return {
    ...rest,
  };
};
