import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useHistory as useReactRouterHistory } from 'react-router-dom';

import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Action,
  History, // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Location, // eslint-disable-next-line @typescript-eslint/no-unused-vars
  LocationDescriptorObject,
  LocationState, // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Path, // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TransitionPromptHook,
} from 'history';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import useIsInExtension from 'src/recruiting-portal/pages/extension/utils/useIsInExtension';

/**
 * This class is a wrapper around the History class from the history package
 * It overrides the push & replace methods to open the link in a new tab.
 */
class ExtensionHistory<HistoryLocationState = LocationState> implements History {
  private history: History<HistoryLocationState>;

  constructor(history: History<HistoryLocationState>) {
    this.history = history;
  }

  public get length() {
    return this.history.length;
  }

  public get action() {
    return this.history.action;
  }

  public get location() {
    return this.history.location;
  }

  go = (n: number) => {
    this.history.go(n);
  };

  goBack = () => {
    this.history.goBack();
  };

  goForward = () => {
    this.history.goForward();
  };

  block = (prompt?: boolean | string | TransitionPromptHook<HistoryLocationState>) => {
    return this.history.block(prompt);
  };

  listen = (listener: (location: Location<HistoryLocationState>, action: Action) => void) => {
    return this.history.listen(listener);
  };

  createHref = (location: LocationDescriptorObject<HistoryLocationState>) => {
    return this.history.createHref(location);
  };

  /**
   * Below 2 methods are the only ones that are different from the original History class
   */

  public push = (path: Path | LocationDescriptorObject<HistoryLocationState> /* , state?: HistoryLocationState */) => {
    if (typeof path === 'string') {
      window.open(window.origin + path, '_blank');
    } else {
      window.open(this.history.createHref(path), '_blank');
    }
  };

  public replace = (
    path: Path | LocationDescriptorObject<HistoryLocationState> /* , state?: HistoryLocationState */
  ) => {
    if (typeof path === 'string') {
      window.open(window.origin + path, '_blank');
    } else {
      window.open(this.history.createHref(path), '_blank');
    }
  };
}

const useHistory = () => {
  const history = useReactRouterHistory();
  const isInExtension = useIsInExtension();

  return useMemo(() => {
    if (isInExtension) {
      const mLHistory = new ExtensionHistory(history);
      return mLHistory;
    }
    return history;
  }, [history, isInExtension]);
};

export default useHistory;
