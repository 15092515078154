import React from 'react';

import BaseIcon, { Props } from './Icon';

const InterruptedIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        d="M6.62914 6.6244C7.62188 5.9793 8.7722 5.5 9.99997 5.5C12.79 5.5 15.18 7.97503 16.23 9.2463C16.3958 9.45302 16.4876 9.72159 16.4876 10.0001C16.4876 10.2785 16.3958 10.5471 16.23 10.7538C15.6512 11.4546 14.6652 12.5211 13.4296 13.3372M11.5 14.2697C11.0176 14.4156 10.5156 14.5001 9.99997 14.5001C7.20997 14.5001 4.81997 12.0251 3.76997 10.7538C3.60413 10.5471 3.51233 10.2785 3.51233 10.0001C3.51233 9.72159 3.60413 9.45302 3.76997 9.2463C4.10264 8.84352 4.56982 8.31991 5.14162 7.79247"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4142 11.4142C12.1953 10.6332 12.1953 9.36683 11.4142 8.58578C10.6332 7.80474 9.36683 7.80474 8.58578 8.58578"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 16.5L3.5 3.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default InterruptedIcon;
