import React from 'react';

import { Alert, Stack, Typography } from '@mui/material';

import { DateTimeRangeInput } from 'src/generated/mloop-graphql';

import { AvailabilityContent } from './AvailabilityContent';

interface Props {
  availability: DateTimeRangeInput;
  timezone: string;
  onChange: (start: string, end: string) => void;
  onDelete: () => void;
  warningText?: string;
  errorText?: string;
}

export const AvailabilityRow: React.FC<Props> = ({
  availability,
  timezone,
  onChange,
  onDelete,
  warningText,
  errorText,
}) => {
  if (warningText || errorText) {
    return (
      <Alert severity={errorText ? 'error' : 'warning'} sx={{ width: '100%', display: 'flex' }} icon={false}>
        <Stack gap={1}>
          <AvailabilityContent
            availability={availability}
            timezone={timezone}
            onChange={onChange}
            onDelete={onDelete}
          />
          <Typography variant="body2">{errorText || warningText}</Typography>
        </Stack>
      </Alert>
    );
  }

  return (
    <AvailabilityContent availability={availability} timezone={timezone} onChange={onChange} onDelete={onDelete} />
  );
};
