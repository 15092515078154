import { ThemeOptions } from '@mui/material';

/**
 * View Grid props and classes at https://mui.com/material-ui/api/grid/#css
 */
export const grid: ThemeOptions['components'] = {
  MuiGrid: {
    defaultProps: {
      spacing: 2.5,
    },
  },
  MuiGrid2: {
    defaultProps: {
      spacing: 2.5,
    },
  },
};
