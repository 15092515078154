import React, { FC } from 'react';

import { ResponseStatus } from 'src/generated/mloop-graphql';

import { InterviewerRsvpDeclineIcon, InterviewerRsvpGoingIcon, InterviewerRsvpMaybeIcon } from 'src/components/icons';

interface Props {
  dataTestId?: string;
  responseStatus: ResponseStatus | undefined | null;
}

const InterviewerRsvpIcon: FC<Props> = ({ dataTestId, responseStatus }) => {
  const iconProps = { fontSize: 14, tiny: true, dataTestId };

  switch (responseStatus) {
    case ResponseStatus.Accepted:
      return <InterviewerRsvpGoingIcon tooltip="Going" {...iconProps} />;
    case ResponseStatus.Declined:
      return <InterviewerRsvpDeclineIcon tooltip="Declined" {...iconProps} />;
    case ResponseStatus.Tentative:
      return <InterviewerRsvpMaybeIcon tooltip="Maybe" {...iconProps} />;
    default:
      return null;
  }
};

export default InterviewerRsvpIcon;
