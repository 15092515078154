import React from 'react';

import BaseIcon, { Props } from './Icon';

const VisibleIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        d="M16.23 9.2463C16.3958 9.45302 16.4876 9.72159 16.4876 10.0001C16.4876 10.2785 16.3958 10.5471 16.23 10.7538C15.18 12.0251 12.79 14.5001 9.99997 14.5001C7.20997 14.5001 4.81997 12.0251 3.76997 10.7538C3.60413 10.5471 3.51233 10.2785 3.51233 10.0001C3.51233 9.72159 3.60413 9.45302 3.76997 9.2463C4.81997 7.97503 7.20997 5.5 9.99997 5.5C12.79 5.5 15.18 7.97503 16.23 9.2463Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10C8 11.1046 8.89543 12 10 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default VisibleIcon;
