/* eslint-disable max-lines */
import React, { FC, useCallback, useEffect, useState } from 'react';

import { CircularProgress, Stack } from '@mui/material';
import { uniq } from 'lodash';
import { useSnackbar } from 'notistack';

import {
  DynamicAudience,
  EmployeeFragment,
  NotificationPreferenceInput,
  Sort,
  TemplateType,
  useCandidateDetailsQuery,
} from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import Button from 'src/components/button';
import Label from 'src/components/label';

import { FileUploadFlow, removeAllUploadedFiles } from 'src/slices/files';

import { SelfScheduleTokens } from 'src/utils/PlaceholderFiller/useTemplateTokens';

import EmailContent from 'src/views-new/EmailContent/index';
import { TemplateInterface } from 'src/views-new/Settings/TemplateComposer/TemplateInterface';
import { useOrganizationTemplatesQuery } from 'src/views-new/Settings/TemplateComposer/useTemplateQueries';

import { TEMPLATE_TOKEN_REGEX } from 'src/constants';
import { useDispatch } from 'src/store';

import MissingSelfScheduleLinkTokenModal from './MissingSelfScheduleLinkTokenModal';
import useSelfScheduleOptionsEmailFiller from './useSelfScheduleOptionsEmailFiller';
import useSelfScheduleOptionsUpsert from './useSelfScheduleOptionsUpsert';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type SelfScheduleEmailProps = {
  applicationId: string;
  candidateId: string;
  jobStageId: string;
  subTitle?: string;
  selfScheduleOptionsUpsert: ReturnType<typeof useSelfScheduleOptionsUpsert>;
  goBack?: () => void;
  onCancel?: () => void;
  onSave: (emailSent: boolean, taskId: string | null) => void;
  canSaveWithoutSending?: boolean;
  taskId?: string;
  assigneeId?: string;
  subscriberEmployeeIds?: string[];
  jobId?: string;
  selfScheduleRequestEmailTemplateId?: string;
  defaultCcEmployeeIds?: string[];
  taskCreatorId?: string;
  ccRecipients?: NotificationPreferenceInput | null;
  bccRecipients?: NotificationPreferenceInput | null;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const SelfScheduleEmail: FC<SelfScheduleEmailProps> = ({
  applicationId,
  candidateId,
  jobStageId,
  subTitle,
  selfScheduleOptionsUpsert,
  goBack,
  onCancel,
  onSave,
  subscriberEmployeeIds,
  jobId,
  canSaveWithoutSending = true,
  taskId,
  assigneeId,
  taskCreatorId,
  selfScheduleRequestEmailTemplateId,
  defaultCcEmployeeIds = [],
  ccRecipients,
  bccRecipients,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const [ccEmployeeIds, setCcEmployeeIds] = useState<string[]>(
    uniq(defaultCcEmployeeIds.concat(ccRecipients?.employeeIds || []))
  );
  const [bccEmployeeIds, setBccEmployeeIds] = useState<string[]>(bccRecipients?.employeeIds || []);
  const [ccDynamicAudience, setCcDynamicAudience] = useState<DynamicAudience[]>(ccRecipients?.dynamicAudiences || []);

  const [bccDynamicAudience, setBccDynamicAudience] = useState<DynamicAudience[]>(
    bccRecipients?.dynamicAudiences || []
  );
  const [toFreeform, setToFreeform] = useState<string[]>([]);
  const [ccFreeform, setCcFreeform] = useState<string[]>(ccRecipients?.externalEmailAddresses || []);
  const [bccFreeform, setBccFreeform] = useState<string[]>(bccRecipients?.externalEmailAddresses || []);
  const [selectedToFreeformEmail, setSelectedToFreeformEmail] = useState<string[]>([]);

  const [showMissingTokenModal, setShowMissingTokenModal] = useState(false);
  const [isMissingSelfScheduleLinkToken, setIsMissingSelfScheduleLinkToken] = useState(false);

  const [sendingEmail, setSendingEmail] = useState(false);
  const [savingOptionsWithoutCandidateCommunication, setSavingOptionsWithoutCandidateCommunication] = useState(false);
  const [error, setError] = useState<string>();

  const [selectedTemplate, setSelectedTemplate] = useState<TemplateInterface | undefined>(undefined);
  const [subject, setSubject] = useState<string>('');
  const [bodyHtml, setBodyHtml] = useState<string>('');

  const { data: candidateData, loading: loadingCandidateDetails } = useCandidateDetailsQuery({
    variables: { id: candidateId },
  });

  const selfScheduleOptionsEmailFiller = useSelfScheduleOptionsEmailFiller(
    applicationId,
    jobStageId,
    candidateData,
    taskId,
    assigneeId
  );

  const { data: templatesData, loading: loadingTemplates } = useOrganizationTemplatesQuery({
    types: [TemplateType.SelfScheduleRequestCandidateEmail],
    showDefaults: false,
    sortByName: Sort.Asc,
  });

  const templates = templatesData?.templates?.items;
  useEffect(() => {
    templates?.forEach((template) => {
      if (!selfScheduleRequestEmailTemplateId) {
        if (template.isOrganizationDefault) {
          setSelectedTemplate(template);
        }
      } else if (template.id === selfScheduleRequestEmailTemplateId) {
        setSelectedTemplate(template);
      }
    });
  }, [templates, selfScheduleRequestEmailTemplateId]);

  useEffect(() => {
    dispatch(removeAllUploadedFiles());
  }, [dispatch]);

  useEffect(() => {
    const additionalEmails = (
      candidateData?.candidate?.additionalEmails ? [...candidateData?.candidate?.additionalEmails] : []
    ) as string[];

    const preferredEmail = (candidateData?.candidate?.email ||
      candidateData?.candidate?.additionalEmails?.[0]) as string;

    // candidate.email might not exist in the list from the ATS (candidate.additionalEmails)
    // if it does, use the ATS list directly
    // if it doesn't, expand the list with the candidate.email as the first item
    let emailList: string[] = [];
    if (additionalEmails.indexOf(preferredEmail) > -1) {
      emailList = additionalEmails;
    } else {
      if (additionalEmails.length) {
        emailList = additionalEmails;
      }
      if (preferredEmail) {
        emailList = [preferredEmail, ...emailList];
      }
    }
    setToFreeform(emailList);
    setSelectedToFreeformEmail(preferredEmail ? [preferredEmail] : []);
  }, [candidateData]);

  useEffect(() => {
    setSubject(selfScheduleOptionsEmailFiller(selectedTemplate?.subject || ''));
    setBodyHtml(selfScheduleOptionsEmailFiller(selectedTemplate?.body || ''));
  }, [selectedTemplate, selfScheduleOptionsEmailFiller]);

  useEffect(() => {
    setIsMissingSelfScheduleLinkToken(bodyHtml.indexOf(SelfScheduleTokens.SELF_SCHEDULE_LINK) === -1);
  }, [bodyHtml]);

  const handleTo = (employeeResult: EmployeeFragment[], freeformEmails: string[]) => {
    setSelectedToFreeformEmail(freeformEmails);
  };

  const handleCc = (
    employeeResult: EmployeeFragment[],
    freeformEmails: string[],
    dynamicAudiences: DynamicAudience[]
    // eslint-disable-next-line max-params
  ) => {
    setCcEmployeeIds(employeeResult.map((e) => e.id));
    setCcFreeform(freeformEmails);
    setCcDynamicAudience(dynamicAudiences);
  };

  const handleBcc = (
    employeeResult: EmployeeFragment[],
    freeformEmails: string[],
    dynamicAudiences: DynamicAudience[]
    // eslint-disable-next-line max-params
  ) => {
    setBccEmployeeIds(employeeResult.map((e) => e.id));
    setBccFreeform(freeformEmails);
    setBccDynamicAudience(dynamicAudiences);
  };

  const handleEmailTemplateChange = (template: TemplateInterface) => {
    if (!template || template.id === selectedTemplate?.id) return;
    setSelectedTemplate(template);
    setSubject(template.subject || '');
    setBodyHtml(template.body || '');
    setIsMissingSelfScheduleLinkToken(template.body?.indexOf(SelfScheduleTokens.SELF_SCHEDULE_LINK) === -1);
  };

  const handleSendSelfScheduleLinkEmail = useCallback(
    (bypassSelfScheduleLinkTokenCheck: boolean, saveWithoutSending = false) => {
      if (!selectedTemplate || !candidateData) return;

      // if this is being called from MissingSelfScheduleLinkTokenModal, we can skip this check because we don't want to show the modal again
      if (isMissingSelfScheduleLinkToken && !bypassSelfScheduleLinkTokenCheck) {
        setShowMissingTokenModal(true);
        return;
      }

      if (saveWithoutSending) {
        setSavingOptionsWithoutCandidateCommunication(true);
      } else {
        setSendingEmail(true);
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/catch-or-return
      selfScheduleOptionsUpsert(
        selectedToFreeformEmail[0],
        ccFreeform,
        bccFreeform,
        ccEmployeeIds,
        bccEmployeeIds,
        selectedTemplate,
        subject,
        `${bodyHtml}`,
        saveWithoutSending
      )
        .then((createdTaskId: string | null) => {
          onSave(!saveWithoutSending, createdTaskId);

          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line promise/always-return
          enqueueSnackbar(`Self-schedule request ${saveWithoutSending ? 'saved' : 'sent'} successfully!`, {
            variant: 'success',
          });
        })
        .catch((reason) => {
          setError(reason.message);
          enqueueSnackbar('There was an error while creating the self-schedule request', {
            variant: 'error',
          });
        })
        .finally(() => {
          setSendingEmail(false);
          setSavingOptionsWithoutCandidateCommunication(false);
        });
    },
    [
      bccEmployeeIds,
      bccFreeform,
      bodyHtml,
      candidateData,
      ccEmployeeIds,
      ccFreeform,
      enqueueSnackbar,
      isMissingSelfScheduleLinkToken,
      onSave,
      selectedTemplate,
      selectedToFreeformEmail,
      selfScheduleOptionsUpsert,
      subject,
    ]
  );

  const bodyUnusedTokens = bodyHtml.match(TEMPLATE_TOKEN_REGEX) || [];
  const subjectUnusedTokens = subject.match(TEMPLATE_TOKEN_REGEX) || [];
  const unusedTokens =
    uniq([...bodyUnusedTokens, ...subjectUnusedTokens]).filter(
      (value) => value !== `{{${SelfScheduleTokens.SELF_SCHEDULE_LINK}}}`
    ) || [];
  const hasUnusedToken = !!unusedTokens.length;

  const getSendEmailDisabledTooltip = () => {
    if (hasUnusedToken) {
      return `There are unused tokens: ${unusedTokens?.join(', ')}`;
    }

    if (selectedToFreeformEmail.length === 0) {
      return 'Candidate email not present';
    }

    return undefined;
  };

  return (
    <>
      <EmailContent
        useDialogPage
        title="Email request to candidate"
        subTitle={subTitle}
        candidateId={candidateId}
        assigneeEmployeeId={assigneeId}
        ccEmployeeIds={ccEmployeeIds}
        bccEmployeeIds={bccEmployeeIds}
        ccDynamicAudiences={ccDynamicAudience}
        bccDynamicAudiences={bccDynamicAudience}
        bccFreeformEmails={bccFreeform}
        applicationId={applicationId}
        taskId={taskId}
        subscriberEmployeeIds={subscriberEmployeeIds}
        taskCreatorId={taskCreatorId}
        ccFreeformEmails={ccFreeform}
        jobId={jobId}
        headerActions={
          <Stack direction="row" spacing={1}>
            {!goBack && <Button variant="outlined" label="Cancel" onClick={onCancel} />}
            {goBack && (
              <Button
                variant="outlined"
                disabled={!toFreeform.length || savingOptionsWithoutCandidateCommunication || sendingEmail}
                endIcon={savingOptionsWithoutCandidateCommunication ? <CircularProgress size={16} /> : undefined}
                label="Save without sending"
                onClick={() =>
                  handleSendSelfScheduleLinkEmail(
                    true /* bypassSelfScheduleLinkTokenCheck */,
                    true /* saveWithoutSending */
                  )
                }
              />
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={
                sendingEmail ||
                !subject ||
                !bodyHtml ||
                hasUnusedToken ||
                savingOptionsWithoutCandidateCommunication ||
                selectedToFreeformEmail.length === 0
              }
              endIcon={sendingEmail ? <CircularProgress size={16} /> : undefined}
              label={goBack ? 'Save & send email' : 'Send email'}
              onClick={() => handleSendSelfScheduleLinkEmail(false)}
              tooltip={getSendEmailDisabledTooltip()}
            />
          </Stack>
        }
        dialogActions={{
          cancelOptions: goBack
            ? { label: 'Previous step', onClick: goBack, startIcon: 'CaretLeftIcon' }
            : { label: 'Cancel', onClick: onCancel || (() => {}) },
          submitOptions: {
            label: goBack ? 'Save & send email' : 'Send email',
            isDisabled:
              sendingEmail ||
              !subject ||
              !bodyHtml ||
              hasUnusedToken ||
              savingOptionsWithoutCandidateCommunication ||
              selectedToFreeformEmail.length === 0,
            isLoading: sendingEmail,
            tooltip: getSendEmailDisabledTooltip(),
            onClick: () => handleSendSelfScheduleLinkEmail(false),
          },
          secondaryOptions: goBack
            ? {
                label: 'Save without sending',
                tooltip: !canSaveWithoutSending ? 'Cannot save without candidate email' : undefined,
                isDisabled:
                  !toFreeform.length ||
                  savingOptionsWithoutCandidateCommunication ||
                  sendingEmail ||
                  !canSaveWithoutSending,
                isLoading: savingOptionsWithoutCandidateCommunication,
                onClick: () =>
                  handleSendSelfScheduleLinkEmail(
                    true /* bypassSelfScheduleLinkTokenCheck */,
                    // TODO: Fix this the next time the file is edited.
                    // eslint-disable-next-line max-lines
                    true /* saveWithoutSending */
                  ),
              }
            : undefined,
          // eslint-disable-next-line max-lines
        }}
        onClose={onCancel}
        // eslint-disable-next-line max-lines
        alerts={
          <Alert
            alignItems="center"
            status="neutral"
            title={
              <Label variant="captions" fontWeight={400}>
                We’ll insert the link for {`{{SELF_SCHEDULE_LINK}}`} when you send your email. If the token is missing,
                we’ll add it automatically to the end of your email.
              </Label>
            }
          />
        }
        sendError={error}
        goBack={goBack}
        loading={loadingCandidateDetails || loadingTemplates}
        subject={subject}
        bodyHtml={bodyHtml}
        fileUploadFlow={FileUploadFlow.SELF_SCHEDULE_LINK}
        templateTypes={[TemplateType.SelfScheduleRequestCandidateEmail]}
        selectedTemplateId={selectedTemplate?.id}
        selectedToFreeformEmail={selectedToFreeformEmail[0]}
        toFreeformEmails={toFreeform}
        defaultCcFreeformEmails={ccFreeform}
        defaultBccFreeformEmails={bccFreeform}
        defaultCcEmployeeIds={ccEmployeeIds}
        defaultBccEmployeeIds={bccEmployeeIds}
        onToChanged={handleTo}
        onCcChanged={handleCc}
        onBccChanged={handleBcc}
        onChangeSubject={setSubject}
        onChangeBodyHtml={setBodyHtml}
        onChangeTemplate={handleEmailTemplateChange}
      />
      {showMissingTokenModal && (
        <MissingSelfScheduleLinkTokenModal
          goBack={() => setShowMissingTokenModal(false)}
          onAppendAndContinue={() => {
            setShowMissingTokenModal(false);
            handleSendSelfScheduleLinkEmail(true);
          }}
        />
      )}
    </>
  );
};

export default SelfScheduleEmail;
