import IframeDispatchContext from './IframeDispatchContext';
import IframeStateContext from './IframeStateContext';
import reducer from './reducer';
import { FC, useReducer } from 'react';

const IframeProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { iframeWindow: null });

  return (
    <IframeStateContext.Provider value={state}>
      <IframeDispatchContext.Provider value={dispatch}>{children}</IframeDispatchContext.Provider>
    </IframeStateContext.Provider>
  );
};

export default IframeProvider;
