import React, { useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';

import { InterviewType } from 'src/generated/mloop-graphql';

import { CanDisable } from 'src/components/HelperComponents';
import IconButton from 'src/components/IconButton';
import Paper from 'src/components/Paper';
import Button from 'src/components/button';
import { BreakIcon, OrderLockIcon, OrderShuffleIcon, TrashIcon, TriangleDownIcon } from 'src/components/icons';
import OvernightIcon from 'src/components/icons/Overnight';
import DurationMenu, { DURATION_OPTIONS } from 'src/components/menu/DurationMenu';

import { useFlag } from 'src/hooks/feature-flag/';

import { deleteJobStageInterview, updateJobStageInterview } from 'src/store/actions/job-stage-interview';
import { lockJobStageInterviewGroup } from 'src/store/actions/job-stage-interview-group';
import { getJobStageInterviewById } from 'src/store/selectors/job-stage-interview';
import { getJobStageInterviewGroupById } from 'src/store/selectors/job-stage-interview-group';

import { isBreak, isDayBreak } from 'src/utils/interview';

import { useDispatch, useSelector } from 'src/store';

import MoreMenuOptions from './MoreMenuOptions';

interface Props {
  jobStageInterviewId: string;
  index: number;
  readonly?: boolean;
  onUpdated: () => void;
}

const BreakSlot = ({ jobStageInterviewId, index, readonly, onUpdated }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const orgShuffleAbleBreak = useFlag('org-shuffle-able-break');

  const breakSlot = useSelector((state) => getJobStageInterviewById(state, jobStageInterviewId));
  const group = useSelector((state) => getJobStageInterviewGroupById(state, breakSlot.jobStageGroupId));

  const { jobStageInterviewIds } = useSelector((state) =>
    getJobStageInterviewGroupById(state, breakSlot.jobStageGroupId)
  );

  const interviewSlotDuration = breakSlot.duration;
  const durationString = DURATION_OPTIONS.find((value) => value.id === interviewSlotDuration)?.value;

  const openBreakDurationMenu = () => {
    setOpen(true);
  };

  const closeBreakDurationMenu = () => {
    setOpen(false);
  };

  const handleDurationSelect = (duration: number) => {
    const type = duration === 0 ? InterviewType.DayDivider : InterviewType.Break;
    dispatch(
      updateJobStageInterview({
        id: jobStageInterviewId,
        duration,
        type,
        name: duration === 0 ? 'Day Divider' : 'Break',
      })
    );
    onUpdated();
  };

  const handleDeleteBreakSlot = () => {
    dispatch(deleteJobStageInterview(jobStageInterviewId));
    onUpdated();
  };

  const handlePlanOrderClick = () => {
    /* * Addidtional check in case the click is triggered, Day divider are not Shufflable * */
    if (isDayBreak(breakSlot.type)) {
      return;
    }
    dispatch(updateJobStageInterview({ id: jobStageInterviewId, isLockedOrder: !breakSlot.isLockedOrder }));

    if (group.jobStageInterviewIds.length === 1) {
      dispatch(lockJobStageInterviewGroup(group.id, !breakSlot.isLockedOrder));
    }
    onUpdated();
  };

  return (
    <Paper color="contrast" size={jobStageInterviewIds.length > 1 ? 'medium' : 'large'}>
      <CanDisable disabled={Boolean(readonly)}>
        <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
          <Grid item xs={10}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  {orgShuffleAbleBreak && !isDayBreak(breakSlot.type) && (
                    // Day divider are not Shufflable
                    <Grid item>
                      <IconButton onClick={handlePlanOrderClick}>
                        {breakSlot.isLockedOrder && <OrderLockIcon tooltip="Locked position" />}
                        {!breakSlot.isLockedOrder && <OrderShuffleIcon tooltip="Shuffle position" />}
                      </IconButton>
                    </Grid>
                  )}

                  {!orgShuffleAbleBreak && (
                    <Grid item>
                      <OrderLockIcon tooltip="Locked position" />
                    </Grid>
                  )}
                  {isBreak(breakSlot.type) && (
                    <Grid item>
                      <BreakIcon />
                    </Grid>
                  )}
                  {isDayBreak(breakSlot.type) && (
                    <Grid item>
                      <OvernightIcon />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  label={durationString}
                  variant="unstyled"
                  size="medium"
                  ref={anchorEl}
                  endIcon={<TriangleDownIcon />}
                  onClick={openBreakDurationMenu}
                />
                <DurationMenu
                  id="break-slot-duration-menu"
                  open={open}
                  anchorEl={anchorEl}
                  onSelect={handleDurationSelect}
                  onClose={closeBreakDurationMenu}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" wrap="nowrap" spacing={1}>
              <Grid item>
                <MoreMenuOptions index={index} jobStageInterviewId={jobStageInterviewId} onUpdated={onUpdated} />
              </Grid>
              <Grid item>
                <IconButton onClick={handleDeleteBreakSlot}>
                  <TrashIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CanDisable>
    </Paper>
  );
};

export default BreakSlot;
