import { SvgIcon, SvgIconProps } from './SvgIcon';

export const SparkleIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3C10.6569 3 12 1.65685 12 0C12 1.65685 13.3431 3 15 3C13.3431 3 12 4.34315 12 6C12 4.34315 10.6569 3 9 3ZM0 9C3.31371 9 6 6.31371 6 3C6 6.31371 8.68629 9 12 9C8.68629 9 6 11.6863 6 15C6 11.6863 3.31371 9 0 9Z"
      />
    </SvgIcon>
  );
};
