import { ThemeOptions } from '@mui/material';

export const iconButton: ThemeOptions['components'] = {
  MuiIconButton: {
    styleOverrides: {
      sizeSmall: ({ theme }) => ({
        padding: theme.spacing(0.5),
        height: '28px',
      }),
      sizeMedium: ({ theme }) => ({
        padding: theme.spacing(0.75),
      }),
      sizeLarge: ({ theme }) => ({
        padding: theme.spacing(1.25),
      }),
    },
  },
};
