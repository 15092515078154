import React from 'react';

import BaseIcon, { Props as BaseProps } from './Icon';

/**
 * Component supports both percentage value or even calculating the percentage on the basic of index and total
 */
export interface Props extends BaseProps {
  percentage?: number;
  index?: number;
  total?: number;
}

const ProgessIcon = ({ color, fontSize, tooltip, percentage, index = 0, total = 1 }: Props): JSX.Element => {
  percentage = percentage ?? (++index / total) * 100;
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <circle cx="10" cy="10" r="7" stroke="currentColor" strokeWidth="1.5" fill="none" />
      <circle
        r="2.5"
        cx="10"
        cy="10"
        fill="transparent"
        stroke="currentColor"
        strokeWidth="5"
        strokeDasharray={`calc(${percentage} * 15.7/100) 15.7`}
        transform="rotate(-90) translate(-20)"
      />
    </BaseIcon>
  );
};

export default ProgessIcon;
