import { useMemo } from 'react';

import { QueryResult, gql } from '@apollo/client';

import {
  CandidateAvailabilityTokensQuery,
  RenderType,
  TemplateToken,
  useCandidateAvailabilityTokensQuery,
} from 'src/generated/mloop-graphql';

import { CandidateAvailability } from 'src/store/slices/candidate-availability';

import { EMPTY_OBJECT } from 'src/constants';

import placeholders from './utils/placeholders';

export const CandidateAvailabilitylaceholderFillerQuery = gql`
  query CandidateAvailabilityTokens(
    $id: String
    $renderType: RenderType!
    $candidateAvailabilityInput: TokenCandidateAvailabilityInput!
    $timezone: timezone!
    $applicationId: uuid!
    $jobStageId: uuid!
  ) {
    CANDIDATE_AVAILABILITY: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_AVAILABILITY(input: $candidateAvailabilityInput)
    }
    CANDIDATE_AVAILABILITY_TIMEZONE: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_AVAILABILITY_TIMEZONE(input: { timezone: $timezone })
    }
    CANDIDATE_AVAILABILITY_NOTE: templateToken(input: { id: $id, renderType: $renderType }) {
      id
      CANDIDATE_AVAILABILITY_NOTE(input: { applicationId: $applicationId, jobStageId: $jobStageId })
    }
  }
`;

/**
 * TOKENS FETCHED
 */
export enum Tokens {
  CANDIDATE_AVAILABILITY = 'CANDIDATE_AVAILABILITY',
  CANDIDATE_AVAILABILITY_TIMEZONE = 'CANDIDATE_AVAILABILITY_TIMEZONE',
  CANDIDATE_AVAILABILITY_NOTE = 'CANDIDATE_AVAILABILITY_NOTE',
  CANDIDATE_AVAILABILITY_URL = 'CANDIDATE_AVAILABILITY_URL',
}
export type CandidateAvailabilityPlaceholderFillerOptions = Pick<TemplateToken, keyof typeof Tokens>;

export default class CandidateAvailabilityPlaceholderFiller {
  static getFilledText = (input: string, options: CandidateAvailabilityPlaceholderFillerOptions): string => {
    return placeholders(input, options);
  };

  static getOptions = (data?: CandidateAvailabilityTokensQuery): CandidateAvailabilityPlaceholderFillerOptions => {
    if (!data || !Object.keys(data).length) {
      return EMPTY_OBJECT;
    }
    return {
      CANDIDATE_AVAILABILITY: data.CANDIDATE_AVAILABILITY?.CANDIDATE_AVAILABILITY,
      CANDIDATE_AVAILABILITY_TIMEZONE: data.CANDIDATE_AVAILABILITY_TIMEZONE?.CANDIDATE_AVAILABILITY_TIMEZONE,
      CANDIDATE_AVAILABILITY_NOTE: data.CANDIDATE_AVAILABILITY_NOTE?.CANDIDATE_AVAILABILITY_NOTE,
    };
  };
}

export type Input = {
  renderType?: RenderType;
  applicationId: string;
  jobStageId: string;
  timezone: string;
  candidateAvailability?: CandidateAvailability;
  isFetch?: boolean;
};

export const useCandidateAvailabilityPlaceholderFillerOptions = ({
  renderType,
  applicationId,
  jobStageId,
  timezone,
  candidateAvailability,
  isFetch = true,
}: Input): [CandidateAvailabilityPlaceholderFillerOptions, Pick<QueryResult, 'data' | 'error' | 'loading'>] => {
  const { data, loading, error } = useCandidateAvailabilityTokensQuery({
    skip: !isFetch || !applicationId || !jobStageId,
    variables: {
      renderType: renderType || RenderType.Html,
      id: jobStageId,
      applicationId,
      jobStageId,
      timezone,
      candidateAvailabilityInput: {
        timezone: candidateAvailability?.candidateTimezone || timezone,
        timeRanges: [
          ...(candidateAvailability?.candidateEnteredAvailability || []),
          ...(candidateAvailability?.rcEnteredAvailability || []),
        ].map((timeRange) => ({ startAt: timeRange.start, endAt: timeRange.end })),
      },
    },
    errorPolicy: 'all',
  });

  return useMemo(() => {
    return [CandidateAvailabilityPlaceholderFiller.getOptions(data), { data, error, loading }];
  }, [data, error, loading]);
};
