import { ThemeOptions } from '@mui/material';

/**
 * View Tooltip props and classes at https://mui.com/material-ui/api/tooltip/#css
 */
export const tooltip: ThemeOptions['components'] = {
  MuiTooltip: {
    defaultProps: {
      placement: 'top',
    },
    styleOverrides: {
      tooltip: ({ theme }) => ({
        backgroundColor: theme.palette.primary.dark,
      }),
      arrow: ({ theme }) => ({
        color: theme.palette.primary.dark,
      }),
    },
  },
};
