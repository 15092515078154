import React, { useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Stack from 'src/components/Stack';
import Button from 'src/components/button';
import { DateIcon } from 'src/components/icons';

import { Theme } from 'src/theme/type';

import { formatToTimeZone } from 'src/utils/dateUtils';

import DatePicker, { DatePickerProps, useDateButtonStyle } from './DatePickerPopover';
import TimePicker from './TimePicker';
import { useAutoCompleteStyles } from './TimeRangePicker';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type DateTimePickerProps = {
  utcTime: Date;
  timezone: string;
  onChange: (utcTime: Date) => void;
} & Pick<DatePickerProps, 'renderDay'>;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    boxCell: {
      padding: '0px 4px 0px 0px',
    },

    timePicker: {
      width: '150px',
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '6px',
    },
  })
);

const DateTimePicker = ({ utcTime, timezone, onChange, renderDay }: DateTimePickerProps): JSX.Element => {
  const classes = useStyle();
  const dateButtonStyle = useDateButtonStyle();
  const autoCompleteClasses = useAutoCompleteStyles({});
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const datePickerRef = useRef<HTMLButtonElement>(null);

  const openDatePicker = () => {
    setDatePickerOpen(true);
  };

  const handleDatePickerClose = () => {
    setDatePickerOpen(false);
  };

  return (
    <Stack spacing={1}>
      <>
        <Button
          className={dateButtonStyle.button}
          label={formatToTimeZone(utcTime, 'MMM d yyy', timezone)}
          ref={datePickerRef}
          variant="outlined"
          size="medium"
          startIcon={<DateIcon />}
          onClick={() => openDatePicker()}
        />

        <DatePicker
          dateAnchorEl={datePickerRef}
          open={datePickerOpen}
          utcDate={utcTime}
          timezone={timezone}
          renderDay={renderDay}
          onChange={onChange}
          onClose={handleDatePickerClose}
        />
      </>

      <div className={classes.timePicker}>
        <TimePicker
          overrideAutoCompleteClasses={autoCompleteClasses}
          utcTime={utcTime}
          timezone={timezone}
          onChange={onChange}
          forceIcon
        />
      </div>
    </Stack>
  );
};

export default DateTimePicker;
