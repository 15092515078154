import React, { FC, useMemo, useState } from 'react';

import { ApolloQueryResult, gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';
import { ListItem, ListItemButton, Stack } from '@mui/material';
import { merge, uniqBy } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

import {
  MemberSelect_UserInfoFragment,
  OrgMembersQuery,
  OrgUserInfoSortBy,
  Permission,
  useOrgMembersQuery,
  useUserInfoByEmployeeIdsQuery,
} from 'src/generated/mloop-graphql';

import Avatar from 'src/components/Avatar';
import FilterSelect, { FilterSelectGroup } from 'src/components/FilterList/FilterSelect';
import Checkbox from 'src/components/checkbox';
import MaybeTooltip from 'src/components/tooltip/MaybeTooltip';

import useEmployeeId from 'src/hooks/useEmployeeId';

import { DEBOUNCE_TIMEOUT, PAGE_SIZE } from 'src/constants';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface MemberSelectProps {
  selectedEmployeeIds: string[];
  placeholderText: string;
  onChange: (newMembers: MemberSelect_UserInfoFragment[]) => void;
  getLabel: (selectedMembers: MemberSelect_UserInfoFragment[]) => string | JSX.Element;
  onClose?: () => void;
  additionalOptions?: MemberSelect_UserInfoFragment[];
  isAdditionalOptionId?: (optionId: string) => boolean;
  renderAdditionalOption?: (option: MemberSelect_UserInfoFragment, checked: boolean) => React.ReactNode;
  groupBy?: (option: MemberSelect_UserInfoFragment) => FilterSelectGroup;
  sortMembers?: (optionA: MemberSelect_UserInfoFragment, optionB: MemberSelect_UserInfoFragment) => number;
  header?: JSX.Element;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const MemberSelect: FC<MemberSelectProps> = ({
  selectedEmployeeIds,
  placeholderText,
  onChange,
  getLabel,
  onClose,
  additionalOptions,
  isAdditionalOptionId,
  renderAdditionalOption,
  groupBy,
  sortMembers,
  header,
}) => {
  const shouldFilterByPermission = useFlag('org_interviewer_portal');
  const [search, setSearch] = useState('');
  const debouncedSetSearch = useDebouncedCallback(setSearch, DEBOUNCE_TIMEOUT);
  const currentEmployeeId = useEmployeeId();

  const { data, loading, error, fetchMore, updateQuery } = useOrgMembersQuery({
    variables: {
      input: {
        pageInput: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        query: search,
        sortBy: OrgUserInfoSortBy.Name,
        requiredPermission: shouldFilterByPermission ? Permission.CanSchedule : undefined,
      },
    },
  });

  const filteredSelectedIds = useMemo(
    () =>
      selectedEmployeeIds.filter((id) => {
        // Not fetching additional options
        if (isAdditionalOptionId) {
          return !isAdditionalOptionId(id);
        }

        return true;
      }),
    [isAdditionalOptionId, selectedEmployeeIds]
  );

  const {
    data: membersByIdData,
    loading: loadingSelectedMembersData,
    error: selectedMembersDataError,
  } = useUserInfoByEmployeeIdsQuery({
    variables: {
      input: filteredSelectedIds,
    },
    skip: !filteredSelectedIds.length,
  });

  const selectedMembersData = useMemo(() => {
    if (
      !membersByIdData ||
      !membersByIdData.userInfoByEmployeeIds ||
      loadingSelectedMembersData ||
      selectedMembersDataError
    ) {
      return [];
    }

    return membersByIdData.userInfoByEmployeeIds;
  }, [loadingSelectedMembersData, membersByIdData, selectedMembersDataError]);

  const options = useMemo(() => {
    const members = uniqBy(
      [...(additionalOptions ?? []), ...(data?.thisOrg?.userInfos.items || []), ...selectedMembersData],
      'userId'
    ).filter((option) => option.employee?.id);

    // Sorting so that selected options come at top
    return members.sort((a: MemberSelect_UserInfoFragment, b: MemberSelect_UserInfoFragment) => {
      if (sortMembers) return sortMembers(a, b);

      if (selectedEmployeeIds.includes(a.employee?.id) && selectedEmployeeIds.includes(b.employee?.id)) return 0;
      if (selectedEmployeeIds.includes(a.employee?.id)) return -1;
      if (selectedEmployeeIds.includes(b.employee?.id)) return 1;

      // Sorting so that additional options come at top
      if (a.employee?.id === currentEmployeeId) {
        if (isAdditionalOptionId && isAdditionalOptionId(b.employee?.id)) {
          return 1;
        }
        return -1;
      }

      return 0;
    }) as MemberSelect_UserInfoFragment[];
  }, [
    additionalOptions,
    data,
    selectedMembersData,
    selectedEmployeeIds,
    currentEmployeeId,
    isAdditionalOptionId,
    sortMembers,
  ]);

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: MemberSelect_UserInfoFragment) => {
    const checked = selectedEmployeeIds.includes(option.employee?.id);

    if (
      option.employee?.id &&
      isAdditionalOptionId &&
      isAdditionalOptionId(option.employee?.id) &&
      renderAdditionalOption
    ) {
      return (
        <ListItem {...props}>
          <ListItemButton>{renderAdditionalOption(option, checked)}</ListItemButton>
        </ListItem>
      );
    }

    return (
      <ListItem {...props}>
        <ListItemButton>
          <Stack direction="row" spacing={1} alignItems="center" flexWrap="nowrap">
            <Checkbox checked={checked} />
            <Avatar alt={option.userName || ''} src={option?.employee?.slackImageUrl || undefined} />
            <MaybeTooltip
              label={`${option.userName}${option.employee?.id === currentEmployeeId ? ' (you)' : ''}`}
              tooltip={option.userName || ''}
            />
          </Stack>
        </ListItemButton>
      </ListItem>
    );
  };

  const defaultGroupBy = (option: MemberSelect_UserInfoFragment) => {
    return selectedEmployeeIds.includes(option.employee?.id) ? FilterSelectGroup.SELECTED : FilterSelectGroup.SUGGESTED;
  };

  const handleOnChange = (event: React.ChangeEvent, values: MemberSelect_UserInfoFragment[]) => {
    onChange(values);
  };

  const handleScrollToEnd = () => {
    if (
      !data?.thisOrg?.userInfos ||
      !data?.thisOrg?.userInfos.items ||
      loading ||
      !data?.thisOrg?.userInfos.nextCursor ||
      !fetchMore
    ) {
      return;
    }

    fetchMore({
      variables: {
        input: {
          pageInput: {
            cursor: data?.thisOrg?.userInfos.nextCursor,
            limit: PAGE_SIZE,
          },
          query: search,
          sortBy: OrgUserInfoSortBy.Name,
        },
      },
    })?.then((result: ApolloQueryResult<OrgMembersQuery>) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      if (!updateQuery) return;

      updateQuery((prevResult: OrgMembersQuery) => {
        const userInfos = prevResult.thisOrg?.userInfos;
        const newResults = merge({}, prevResult, result.data);

        if (newResults.thisOrg?.userInfos.items) {
          newResults.thisOrg.userInfos.items = uniqBy(
            [...(userInfos?.items || []), ...(result.data?.thisOrg?.userInfos.items || [])],
            'userId'
          ).filter((option) => option.employee?.id);
        }

        return { ...newResults };
      });
    });
  };

  const selectedMemberOptions: MemberSelect_UserInfoFragment[] = useMemo(() => {
    return [
      ...selectedMembersData,
      ...(additionalOptions?.filter((option) => selectedEmployeeIds.includes(option.employee?.id)) ?? []),
    ];
  }, [additionalOptions, selectedEmployeeIds, selectedMembersData]);

  return (
    <FilterSelect
      header={header}
      isOptionEqualToValue={(option, value) => option?.employee?.id === value?.employee?.id}
      options={options}
      values={selectedMemberOptions}
      loading={loading}
      errorMsg={error?.message}
      placeholderText={placeholderText}
      getLabel={() => getLabel(selectedMemberOptions)}
      getOptionLabel={(option) => option.userName || ''}
      onScrollToEnd={handleScrollToEnd}
      renderOption={renderOption}
      groupBy={groupBy ?? defaultGroupBy}
      onChange={handleOnChange}
      onInputChange={(event: React.ChangeEvent<HTMLInputElement>, value) => {
        if (!search && !data?.thisOrg?.userInfos.nextCursor) return;
        debouncedSetSearch(value || '');
      }}
      onPopoverClose={onClose}
    />
  );
};

export default MemberSelect;

export const MembersQuery = gql`
  fragment MemberSelect_userInfo on UserInfo {
    userId
    userName
    employee {
      id
      fullName
      slackImageUrl
    }
  }

  query OrgMembers($input: OrgUserInfoInput!) {
    thisOrg {
      id
      userInfos(input: $input) {
        items {
          ...MemberSelect_userInfo
        }
        nextCursor
      }
    }
  }

  query UserInfoByEmployeeIds($input: [uuid!]!) {
    userInfoByEmployeeIds(ids: $input) {
      ...MemberSelect_userInfo
    }
  }
`;
