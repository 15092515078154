import { useMemo } from 'react';

import { ApolloError, gql } from '@apollo/client';

import { TemplateType, useSelfScheduleTemplatesQuery } from 'src/generated/mloop-graphql';

import { useDefaultTemplateForTypeQuery } from '../Settings/TemplateComposer/useTemplateQueries';

export const SelfScheduleTemplatesQuery = gql`
  query SelfScheduleTemplates($jobStageId: uuid!) {
    jobStage(id: $jobStageId) {
      jobStageSettings {
        interviewerEventTemplateId
        candidateEventTemplateId
        candidateEmailTemplateId
      }
    }
  }
`;

interface Templates {
  interviewerEventTemplateId?: string;
  candidateEventTemplateId?: string;
  candidateEmailTemplateId?: string;
}

/**
 * This function is used to get the default templates for the self schedule modal flow.
 * It will first try to get the templates from the job stage settings, if they are not set
 * it will use the default templates for the organization.
 */
export const useSelfScheduleJobStageTemplates = (
  jobStageId: string
): { templates: Templates; loading: boolean; error: ApolloError | undefined } => {
  const {
    data,
    loading: jobTemplatesLoading,
    error: jobTemplatesError,
  } = useSelfScheduleTemplatesQuery({
    variables: {
      jobStageId,
    },
    skip: !jobStageId,
  });

  const [templateMap, { loading, error }] = useDefaultTemplateForTypeQuery([
    TemplateType.InterviewerInvite,
    TemplateType.CandidateInvite,
    TemplateType.CandidateConfirmation,
  ]);

  const defaultTemplates = useMemo(() => {
    if (loading || error || jobTemplatesLoading || jobTemplatesError) {
      return {
        interviewerEventTemplateId: '',
        candidateEventTemplateId: '',
        candidateEmailTemplateId: '',
      };
    }

    let templates = {
      interviewerEventTemplateId: templateMap[TemplateType.InterviewerInvite],
      candidateEventTemplateId: templateMap[TemplateType.CandidateInvite],
      candidateEmailTemplateId: templateMap[TemplateType.CandidateConfirmation],
    };

    const jobStageSettings = data?.jobStage?.jobStageSettings;
    if (jobStageSettings) {
      if (jobStageSettings.interviewerEventTemplateId) {
        templates = {
          ...templates,
          interviewerEventTemplateId: jobStageSettings.interviewerEventTemplateId,
        };
      }

      if (jobStageSettings.candidateEventTemplateId) {
        templates = {
          ...templates,
          candidateEventTemplateId: jobStageSettings.candidateEventTemplateId,
        };
      }

      if (jobStageSettings.candidateEmailTemplateId) {
        templates = {
          ...templates,
          candidateEmailTemplateId: jobStageSettings.candidateEmailTemplateId,
        };
      }
    }

    return templates;
  }, [data, jobTemplatesLoading, jobTemplatesError, templateMap, loading, error]);

  return {
    templates: defaultTemplates,
    loading: loading || jobTemplatesLoading,
    error: error || jobTemplatesError,
  };
};
