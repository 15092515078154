import React, { useState } from 'react';

import { Dialog } from '@modernloop/shared/components';
import { Stack, Typography } from '@mui/material';

import useSelfScheduleOptionsDelete from './useSelfScheduleOptionsDelete';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type SelfScheduleOpenAlertProps = {
  selfScheduleOptionsId: string;
  onDelete: () => void;
  onClose: () => void;
};

const SelfScheduledApplicationStageAlert = ({
  selfScheduleOptionsId,
  onDelete,
  onClose,
}: SelfScheduleOpenAlertProps) => {
  const [deleting, setDeleting] = useState(false);

  const { mutation: selfScheduleDelete } = useSelfScheduleOptionsDelete();

  const handleDelete = async () => {
    setDeleting(true);
    await selfScheduleDelete(selfScheduleOptionsId) // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line promise/always-return
      .then(() => {
        onDelete();
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  return (
    <Dialog
      open
      onClose={onClose}
      title="Cancel request?"
      submitOptions={{
        label: 'Cancel request',
        isDisabled: deleting,
        isLoading: deleting,
        onClick: handleDelete,
        isDangerous: true,
      }}
      cancelOptions={{
        label: 'Don’t cancel',
        onClick: onClose,
      }}
    >
      <Stack spacing={1}>
        <Typography fontWeight={400}>
          Canceling this request will make it impossible for the candidate to book, update, or cancel this schedule on
          their own.
        </Typography>
      </Stack>
    </Dialog>
  );
};

export default SelfScheduledApplicationStageAlert;
