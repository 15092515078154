import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import { Box, Button, Checkbox, FormControlLabel, Popover, Stack, Typography } from '@mui/material';
import { remove } from 'lodash';

import { TriangleDownIcon } from 'src/components/icons';
import { BaseProps } from 'src/components/types';

/**
 * Note:
 * This can be extended for color, className etc
 */
export interface OptionInterface<T> {
  value: T;
  label: string;
  icon?: React.ReactNode;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface FilterDropdownProps<T> extends BaseProps {
  defaultText: string;
  options: OptionInterface<T>[];
  selected?: T[];
  getLabel?: (values: T[]) => string;
  onChange?: (value: T[]) => void;
  onClose?: (value: T[]) => void;
  zeroState?: React.ReactNode;
}

const FilterDropdown = <T extends string | number = string>({
  dataTestId,
  defaultText,
  options,
  selected,
  getLabel,
  onChange,
  onClose,
  zeroState,
}: PropsWithChildren<FilterDropdownProps<T>>): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const [filters, setFilters] = useState<T[]>([]);

  useEffect(() => {
    if (!selected) return;
    setFilters(selected);
  }, [selected]);

  const isChecked = (value: T): boolean => {
    return filters.includes(value);
  };

  const onCheckBoxClick = (event: React.ChangeEvent<HTMLInputElement>, value: T) => {
    const updatedFilters = [...filters];
    if (filters.includes(value)) {
      remove(updatedFilters, (filter) => {
        return filter === value;
      });
    } else {
      updatedFilters.push(value);
    }

    setFilters(updatedFilters);
    if (onChange) {
      onChange(updatedFilters);
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose(filters);
    }
  };

  const getBtnText = (): string => {
    if (!filters.length) return defaultText;
    if (getLabel) return getLabel(filters);
    if (filters.length === 1) return options.filter((option) => option.value === filters[0])[0].label;
    return `${filters.length} types`;
  };

  return (
    <>
      <Button
        color={filters.length > 0 ? 'info' : undefined}
        ref={btnRef}
        endIcon={<TriangleDownIcon />}
        data-testid={dataTestId}
        onClick={handleClick}
        size="small"
      >
        {getBtnText()}
      </Button>

      <Popover
        open={open}
        anchorEl={btnRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box m={1} pl={1}>
          <Stack>
            {options.map((option) => {
              return (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  checked={isChecked(option.value)}
                  control={
                    <Checkbox
                      key={option.value}
                      onChange={(event) => {
                        onCheckBoxClick(event, option.value);
                      }}
                    />
                  }
                  label={
                    option.icon ? (
                      <Stack spacing={1} direction="row" alignItems="center">
                        {option.icon}
                        <Typography>{option.label}</Typography>
                      </Stack>
                    ) : (
                      option.label
                    )
                  }
                />
              );
            })}
            {!options.length && zeroState}
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

export default FilterDropdown;
