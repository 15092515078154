import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskCandidateSelfScheduleReminderSent_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';

interface Fragments {
  activityLog: ActivityLogTaskCandidateSelfScheduleReminderSent_ActivityLogFragment;
}

const ActivityLogTaskCandidateSelfScheduleReminderSent: FCWithFragments<Fragments> = ({ activityLog }) => {
  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel="sent a reminder to the candidate to complete the self-schedule request"
    />
  );
};

ActivityLogTaskCandidateSelfScheduleReminderSent.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskCandidateSelfScheduleReminderSent_activityLog on ActivityLog {
      id

      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskCandidateSelfScheduleReminderSent;
