import { SvgIcon, SvgIconProps } from './SvgIcon';

export const WarningIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3816 3.125C11.3231 1.29167 8.6769 1.29167 7.61843 3.125L1.55625 13.625C0.497773 15.4583 1.82087 17.75 3.93782 17.75H16.0622C18.1791 17.75 19.5022 15.4583 18.4437 13.625L12.3816 3.125ZM8.91746 3.875C9.39859 3.04167 10.6014 3.04167 11.0825 3.875L17.1447 14.375C17.6258 15.2083 17.0244 16.25 16.0622 16.25H3.93782C2.97557 16.25 2.37416 15.2083 2.85529 14.375L8.91746 3.875ZM8.92774 7.01516C9.07024 6.84694 9.27954 6.74994 9.50001 6.74994H10.5C10.7205 6.74994 10.9298 6.84694 11.0723 7.01516C11.2148 7.18338 11.276 7.40578 11.2398 7.62324L10.7398 10.6232C10.6795 10.9849 10.3666 11.2499 10 11.2499C9.63337 11.2499 9.32048 10.9849 9.26021 10.6232L8.76021 7.62324C8.72397 7.40577 8.78524 7.18338 8.92774 7.01516ZM11 13.9999C11 14.5522 10.5523 14.9999 10 14.9999C9.44772 14.9999 9 14.5522 9 13.9999C9 13.4477 9.44772 12.9999 10 12.9999C10.5523 12.9999 11 13.4477 11 13.9999Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
