import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';

import Button, { Props as ButtonProps } from 'src/components/button';
import Label from 'src/components/label';
import { createSxProps } from 'src/components/types';
import { SupportedBackgroundColor, getBackgroundColorFromTheme } from 'src/components/utils/color';
import { IconType, getIconFromEnum } from 'src/components/utils/icons';

import { Theme } from 'src/themeMui5/type';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface StyleProps {
  backgroundColor: SupportedBackgroundColor;
  isExpanded: boolean;
  hasSubTitle: boolean;
  isExpansionLocked: boolean;
  accordionDetailsPadding?: CSSProperties['padding'];
}

const useSxProps = (props: StyleProps) => {
  const sxProps = useMemo(() => {
    return {
      accordionRoot: {
        overflow: 'hidden',
        backgroundColor: (theme: Theme) => getBackgroundColorFromTheme(props.backgroundColor, theme),
        border: (theme: Theme) => `1px solid ${theme.palette.border}`,
        borderRadius: (theme: Theme) => `${theme.spacing(1.5)} !important`,
        boxShadow: 'none',
        '&.MuiAccordion-root:before': {
          backgroundColor: 'transparent',
        },
      },
      accordionSummaryRoot: (() => {
        const padding = props.hasSubTitle ? '12px 20px' : '8px 20px';
        const base = {
          backgroundColor: (theme: Theme) => theme.palette.background.contrast,
          borderTopLeftRadius: (theme: Theme) => theme.spacing(1.5),
          borderTopRightRadius: (theme: Theme) => theme.spacing(1.5),
          minHeight: '48px !important',
          padding,
          '& .MuiAccordionSummary-content': {
            margin: 'inherit !important',
            width: '100%',
            minWidth: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            padding: '6px',
          },
          cursor: props.isExpansionLocked ? 'default !important' : 'pointer',
        };

        if (props.isExpanded) {
          return {
            ...base,
            borderBottom: (theme: Theme) => `1px solid ${theme.palette.border}`,
          };
        }

        return {
          ...base,
          borderBottomLeftRadius: (theme: Theme) => theme.spacing(1.5),
          borderBottomRightRadius: (theme: Theme) => theme.spacing(1.5),
        };
      })(),
      accordionDetailsRoot: {
        display: 'block',
        padding: (theme: Theme) => props.accordionDetailsPadding ?? theme.spacing(2.5),
      },
    };
  }, [props]);

  return createSxProps(sxProps);
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface AccordionCardProps {
  title: string | JSX.Element;

  subTitle?: string;

  isExpanded?: boolean;

  defaultExpanded?: boolean;

  isExpansionLocked?: boolean;

  showExpandIcon?: boolean;

  expandIconType?: IconType;

  bodyBackgroundColor?: SupportedBackgroundColor;

  accordionDetailsPadding?: CSSProperties['padding'];

  onClick?: (isExpanded: boolean) => void;

  showActionButton?: boolean;

  actionButtonProps?: ButtonProps;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const AccordionCard: FC<AccordionCardProps> = ({
  title,
  subTitle,
  isExpanded,
  defaultExpanded = true,
  isExpansionLocked,
  showExpandIcon = true,
  expandIconType = 'CaretDownIcon',
  bodyBackgroundColor = 'paper',
  accordionDetailsPadding,
  showActionButton = false,
  actionButtonProps,
  onClick,
  children,
}) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const sxProps = useSxProps({
    backgroundColor: bodyBackgroundColor,
    isExpanded: expanded,
    hasSubTitle: !!subTitle,
    isExpansionLocked: !!isExpansionLocked,
    accordionDetailsPadding,
  });

  useEffect(() => {
    if (isExpanded !== undefined) {
      setExpanded(isExpanded);
    }
  }, [isExpanded]);

  const onActionButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    if (actionButtonProps?.onClick) {
      actionButtonProps.onClick(e);
    }
  };

  return (
    <Accordion
      sx={sxProps.accordionRoot}
      expanded={expanded}
      onChange={() => {
        if (isExpansionLocked) return;

        if (onClick) {
          onClick(!expanded);
        }
        if (isExpanded === undefined) {
          setExpanded(!expanded);
        }
      }}
      disableGutters
    >
      <AccordionSummary
        expandIcon={showExpandIcon ? getIconFromEnum(expandIconType) : undefined}
        sx={sxProps.accordionSummaryRoot}
      >
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
          <Stack sx={{ minWidth: 0 }}>
            {typeof title === 'string' ? (
              <Label variant="body" fontWeight={600}>
                {title}
              </Label>
            ) : (
              title
            )}
            {subTitle && (
              <Label color="max-contrast-grey" variant="captions">
                {subTitle}
              </Label>
            )}
          </Stack>
          {showActionButton ? (
            <Button variant="contained" {...actionButtonProps} onClick={onActionButtonClick} />
          ) : null}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={sxProps.accordionDetailsRoot}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionCard;
