import React, { FC, useEffect, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/styles';
import { find } from 'lodash';

import TextField from 'src/components/TextField';
import EmployeeChip from 'src/components/chip/EmployeeChip';
import Label from 'src/components/label';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { getCustomVideoMeetingLinkUrlForSlotId } from 'src/store/selectors/schedule-communications';

import { InterviewEvent } from 'src/utils/api/getScheduleOptions';
import { renderTimeRangeUnix } from 'src/utils/renderTimeRange';

import { useScheduleFlowData } from 'src/views-new/ScheduleFlow/ScheduleFlowDataProvider';

import { useSelector } from 'src/store';

interface Props {
  interview: InterviewEvent;
  scheduleId: string;
  disabled?: boolean;
  onCustomLinkChange: (slotId: string, eventId: string, text: string) => void;
}
const useStyles = makeStyles(() =>
  createStyles({
    userSelectWrapper: { paddingBottom: '16px' },
    progress: { width: '100%', marginTop: 12, marginBottom: 12 },
  })
);

const CustomLinksContentRow: FC<Props> = ({ interview, scheduleId, disabled = false, onCustomLinkChange }) => {
  const classes = useStyles();

  const scheduleFlowData = useScheduleFlowData();
  const { candidateTimezone } = scheduleFlowData;

  const schedule = useScheduleWithoutBreaks(scheduleId);

  const { slotId } = interview;

  const currentCustomMeetingLinkURL = useSelector((state) =>
    getCustomVideoMeetingLinkUrlForSlotId(state, scheduleId, slotId || '')
  );

  const event: InterviewEvent | undefined = find(schedule?.events || [], (e) => e.slotId === slotId);
  const eventId = event?.id || '';

  const [inputText, setInputText] = useState<string>('');

  useEffect(() => {
    let existingUrl = '';
    if (currentCustomMeetingLinkURL && slotId) {
      existingUrl = currentCustomMeetingLinkURL;
      onCustomLinkChange(slotId, eventId, existingUrl);
    }

    setInputText(existingUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item>
        <Label variant="captions" fontWeight={600}>
          {interview.name}
        </Label>
      </Grid>
      <Grid item>
        <Label variant="captions">
          {renderTimeRangeUnix(
            candidateTimezone,
            new Date(interview.startAt).getTime(),
            new Date(interview.endAt).getTime()
          )}
          {interview.interviewers.map((i) => (
            <EmployeeChip
              key={i.employeeId}
              name={i?.employee?.fullName || ''}
              photoSrc={i?.employee?.slackImageUrl || undefined}
              variant="default"
            />
          ))}
        </Label>
      </Grid>
      <Grid item className={classes.userSelectWrapper}>
        <TextField
          disabled={disabled}
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
          onBlur={() => {
            if (!slotId) return;
            onCustomLinkChange(slotId, eventId, inputText);
          }}
          fullWidth
        />
      </Grid>
    </>
  );
};

export default CustomLinksContentRow;
