import React from 'react';

import BaseIcon, { Props } from './Icon';

const ScorecardNoneIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5303 12.5303C17.8232 12.2374 17.8232 11.7626 17.5303 11.4697C17.2374 11.1768 16.7626 11.1768 16.4697 11.4697L14.5 13.4393L12.5303 11.4697C12.2374 11.1768 11.7626 11.1768 11.4697 11.4697C11.1768 11.7626 11.1768 12.2374 11.4697 12.5303L13.4393 14.5L11.4697 16.4697C11.1768 16.7626 11.1768 17.2374 11.4697 17.5303C11.7626 17.8232 12.2374 17.8232 12.5303 17.5303L14.5 15.5607L16.4697 17.5303C16.7626 17.8232 17.2374 17.8232 17.5303 17.5303C17.8232 17.2374 17.8232 16.7626 17.5303 16.4697L15.5607 14.5L17.5303 12.5303Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 6.5V9C15.25 9.41421 15.5858 9.75 16 9.75C16.4142 9.75 16.75 9.41421 16.75 9V6.5C16.75 4.98121 15.5188 3.75 14 3.75H13.163C12.8245 2.31665 11.5368 1.25 10 1.25C8.46321 1.25 7.17555 2.31665 6.83697 3.75H6.00003C4.48125 3.75 3.25003 4.98121 3.25003 6.5V15C3.25003 16.5188 4.48125 17.75 6.00003 17.75H9C9.41421 17.75 9.75 17.4142 9.75 17C9.75 16.5858 9.41421 16.25 9 16.25H6.00003C5.30967 16.25 4.75003 15.6904 4.75003 15V6.5L15.25 6.5ZM6.25 9C6.25 8.58579 6.58579 8.25 7 8.25H13C13.4142 8.25 13.75 8.58579 13.75 9C13.75 9.41421 13.4142 9.75 13 9.75H7C6.58579 9.75 6.25 9.41421 6.25 9ZM6.25 12C6.25 11.5858 6.58579 11.25 7 11.25H9C9.41421 11.25 9.75 11.5858 9.75 12C9.75 12.4142 9.41421 12.75 9 12.75H7C6.58579 12.75 6.25 12.4142 6.25 12ZM11 4C11 4.55228 10.5523 5 10 5C9.44772 5 9 4.55228 9 4C9 3.44771 9.44772 3 10 3C10.5523 3 11 3.44771 11 4Z"
        fill="currentColor"
        fillOpacity="0.43"
      />
    </BaseIcon>
  );
};

export default ScorecardNoneIcon;
