import { createSelector } from '@reduxjs/toolkit';

import { JobStageInterviewSeatType } from 'src/generated/mloop-graphql';

import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import { State } from 'src/store';

import { getJobStageById } from './job-stage';

export const getJobStageInterviewSeatById = (state: State, jobStageInterviewSeatId: string): JobStageInterviewSeat =>
  state.jobStageInterviewSeat.byId[jobStageInterviewSeatId];

export const getNormalizedJobStageInterviewSeatsById = createSelector(
  (state: State, jobStageInterviewSeatIds: string[]): JobStageInterviewSeat[] => {
    return jobStageInterviewSeatIds.map((seatId): JobStageInterviewSeat => {
      const seat = state.jobStageInterviewSeat.byId[seatId];
      if (seat && seat.type === JobStageInterviewSeatType.Linked && seat.interviewSeatId) {
        return state.jobStageInterviewSeat.byId[seat.interviewSeatId];
      }
      return seat;
    });
  },
  (value) => value
);

export const getAllJobStageInterviewSeatByJobStageId = (state: State, jobStageId: string): JobStageInterviewSeat[] => {
  const jobStage = state.jobStage.byId[jobStageId];

  if (!jobStage) return [];

  return jobStage.jobStageInterviewGroupIds
    .map((groupId) => {
      const group = state.jobStageInterviewGroup.byId[groupId];

      return group.jobStageInterviewIds
        .map((interviewId) => {
          const interview = state.jobStageInterview.byId[interviewId];

          return interview.seatIds.map((seatId) => {
            return state.jobStageInterviewSeat.byId[seatId];
          });
        })
        .flat();
    })
    .flat();
};

export const getLinkableSeatsByInterviewIds = (
  state: State,
  jobStageInterviewId: string,
  jobStageInterviewIds: string[] // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
JobStageInterviewSeat[] => {
  const linkableSeats: JobStageInterviewSeat[] = [];

  const { jobStageId } = state.jobStageInterview.byId[jobStageInterviewId];
  const jobStage = getJobStageById(state, jobStageId);

  // Exclude any seats that are already linked to the jobStageInterviewId
  const seatIdsToExclude: string[] = [];
  state.jobStageInterview.byId[jobStageInterviewId].seatIds.forEach((seatId) => {
    const seat = state.jobStageInterviewSeat.byId[seatId];
    if (seat.type !== JobStageInterviewSeatType.Linked) return;
    if (seat.interviewSeatId) {
      seatIdsToExclude.push(seat.interviewSeatId);
    }
  });

  jobStageInterviewIds.forEach((id) => {
    const jobStageInterview = state.jobStageInterview.byId[id];

    // Return if the interview is not part of current job stages group.
    // We can land in this position when we navigate away from schedule page and come back.
    if (!jobStage.jobStageInterviewGroupIds.includes(jobStageInterview.jobStageGroupId)) return;

    jobStageInterview.seatIds.forEach((seatId) => {
      const seat = state.jobStageInterviewSeat.byId[seatId];

      if (seat.type === JobStageInterviewSeatType.Linked) return;
      if (seat.type === JobStageInterviewSeatType.Freeform && seat.employeeIds && seat.employeeIds.length === 0) return;
      if (seat.type === JobStageInterviewSeatType.Module && !seat.interviewId) return;
      if (seatIdsToExclude.includes(seat.id)) return;

      linkableSeats.push(seat);
    });
  });

  return linkableSeats;
};
