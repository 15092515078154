import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogCandidateViewedTabDetails_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBold from '../../Helpers/ActivityLogBold';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogText from '../../Helpers/ActivityLogText';

type Fragments = {
  activityLog: ActivityLogCandidateViewedTabDetails_ActivityLogFragment;
};

const ActivityLogCandidateViewedTabDetails: FCWithFragments<Fragments> = ({ activityLog }) => {
  // eslint-disable-next-line no-underscore-dangle
  if (!activityLog.details || activityLog.details.__typename !== 'ActivityLogCandidateViewedTabDetails') {
    throw new Error('ActivityLogCandidateViewedTabDetails: details is not ActivityLogCandidateViewedTabDetails');
  }

  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={
        <ActivityLogText>
          viewed&nbsp;<ActivityLogBold>{activityLog.details.tabName}</ActivityLogBold>&nbsp;tab
        </ActivityLogText>
      }
    />
  );
};

ActivityLogCandidateViewedTabDetails.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogCandidateViewedTabDetails_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogCandidateViewedTabDetails {
          __typename
          tabName
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogCandidateViewedTabDetails;
