/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/styles';

import { UserIcon, UserOptionalIcon } from 'src/components/icons';
import Label from 'src/components/label';

import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  userIcon: {
    '&:hover': {
      backgroundColor: theme.grey.alpha.min,
    },
    padding: theme.spacing(0.3),
    borderRadius: '50%',
  },
}));

interface Props {
  onUserIconClick: () => void;
  isAttendeeOptional: boolean;
}

const OptionalAttendeeToggle: FC<Props> = ({ onUserIconClick, isAttendeeOptional }) => {
  const classes = useStyles();

  return (
    <div onClick={onUserIconClick} className={classes.userIcon}>
      {isAttendeeOptional ? (
        <UserOptionalIcon tooltip={<Label variant="captions">Mark required</Label>} />
      ) : (
        <UserIcon tooltip={<Label variant="captions">Mark optional</Label>} />
      )}
    </div>
  );
};

export default OptionalAttendeeToggle;
