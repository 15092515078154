import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Collapse, Grid } from '@mui/material';

import { CandidateEmailCard_InterviewPlanFragment, EmployeeFragment } from 'src/generated/mloop-graphql';

import Paper from 'src/components/Paper';
import Checkbox from 'src/components/checkbox';
import Label from 'src/components/label';

import TemplateType from 'src/constants/TemplateType';

import { FileUploadFlow } from 'src/slices/files';
import {
  updateBCCEmployeeIDs,
  updateBCCExternalAddresses,
  updateCCEmployeeIDs,
  updateCCExternalAddresses,
  updateCandidateCommunicationsEnabled,
  updateHasSameContent,
  updateToEmailAddress,
} from 'src/slices/scheduling';

import {
  getBCCEmployeeIDs,
  getBCCExternalAddresses,
  getCCEmployeeIDs,
  getCCExternalAddresses,
  getCandidateCommunicationsEnabled,
} from 'src/store/selectors/scheduling';

import EmailContent from 'src/views-new/EmailContent';
import { TemplateInterface } from 'src/views-new/Settings/TemplateComposer/TemplateInterface';
import TimezoneSelect from 'src/views-new/TimezoneSelect';

import { useDispatch, useSelector } from 'src/store';

import useCandidateEventTemplateContent from './useCandidateEventTemplateContent';

type Fragments = {
  interviewPlan: CandidateEmailCard_InterviewPlanFragment | undefined;
};

type Props = {
  summary: string | undefined;
  onSummaryChange: (value: string) => void;
  description: string | undefined;
  onDescriptionChange: (value: string) => void;
  templateID: string | null;
  onTemplateIDChange: (value: string) => void;
  timezone: string;
  onTimezoneChange: (value: string | null) => void;
  update?: boolean;
};

const CandidateEmailCard: FCWithFragments<Fragments, Props> = ({
  interviewPlan,
  summary,
  description,
  templateID,
  onDescriptionChange,
  onSummaryChange,
  onTemplateIDChange,
  timezone,
  onTimezoneChange,
  update = false,
}) => {
  const dispatch = useDispatch();
  const { toEmailAddresses, selectedToEmailAddress } = useSelector((state) => state.scheduling);

  const ccEmployeeIDs = useSelector(getCCEmployeeIDs);
  const bccEmployeeIDs = useSelector(getBCCEmployeeIDs);
  const candidateCommunicationsEnabled = useSelector(getCandidateCommunicationsEnabled);
  const ccExternalAddresses = useSelector(getCCExternalAddresses);
  const bccExternalAddresses = useSelector(getBCCExternalAddresses);

  const toggleCandidateComms = () => {
    if (candidateCommunicationsEnabled) {
      dispatch(updateHasSameContent(false));
    }
    dispatch(updateCandidateCommunicationsEnabled(!candidateCommunicationsEnabled));
  };

  const filterCcAndBccOptions = (options: EmployeeFragment[]) => {
    return options.filter((option) => {
      return option?.email !== selectedToEmailAddress;
    });
  };

  const handleTemplateChange = (template: TemplateInterface) => {
    if (!template) return;
    onTemplateIDChange(template.id);
  };

  const loading = useCandidateEventTemplateContent({ interviewPlan }, { templateId: templateID || undefined });

  return (
    <>
      <Grid container spacing={1} direction="column" style={{ paddingTop: '24px' }}>
        <Grid item style={{ marginLeft: '8px' }}>
          <Label variant="title">Candidate</Label>
        </Grid>
        {candidateCommunicationsEnabled && (
          <Grid item>
            <Paper color="alternate">
              <Label variant="captions" fontWeight={600}>
                Timezone
              </Label>
              <TimezoneSelect fullWidth disableClearable openOnFocus value={timezone} onChange={onTimezoneChange} />
            </Paper>
          </Grid>
        )}
        <Grid item style={{ marginLeft: '8px' }}>
          <Checkbox
            checked={candidateCommunicationsEnabled}
            label={update ? 'Send email update' : 'Email to candidate'}
            onChange={toggleCandidateComms}
          />
        </Grid>
      </Grid>
      <Collapse in={candidateCommunicationsEnabled}>
        <Paper color="alternate">
          <EmailContent
            loading={loading}
            noPadding
            title="Email to Candidate"
            subject={summary ?? ''}
            fileUploadFlow={FileUploadFlow.SCHEDULING}
            onChangeTemplate={handleTemplateChange}
            onChangeSubject={onSummaryChange}
            bodyHtml={description ?? ''}
            onChangeBodyHtml={onDescriptionChange}
            templateTypes={[TemplateType.CandidateConfirmation]}
            selectedTemplateId={templateID || undefined}
            toFreeformEmails={toEmailAddresses}
            ccEmployeeIds={ccEmployeeIDs}
            bccEmployeeIds={bccEmployeeIDs}
            ccDynamicAudiences={[]}
            bccFreeformEmails={bccExternalAddresses}
            ccFreeformEmails={ccExternalAddresses}
            filterCcOptions={filterCcAndBccOptions}
            filterBccOptions={filterCcAndBccOptions}
            selectedToFreeformEmail={selectedToEmailAddress ?? ''}
            onToChanged={(employees: EmployeeFragment[], freeformEmails: string[]) => {
              if (!freeformEmails) return;
              dispatch(updateToEmailAddress(freeformEmails[0]));
            }}
            onCcChanged={(employees: EmployeeFragment[], freeformEmails: string[]) => {
              const ids = employees.map((emp) => emp.id);
              dispatch(updateCCEmployeeIDs(ids));
              dispatch(updateCCExternalAddresses(freeformEmails));
            }}
            onBccChanged={(employess: EmployeeFragment[], freeformEmails: string[]) => {
              const ids = employess.map((emp) => emp.id);
              dispatch(updateBCCEmployeeIDs(ids));
              dispatch(updateBCCExternalAddresses(freeformEmails));
            }}
          />
        </Paper>
      </Collapse>
    </>
  );
};

CandidateEmailCard.fragments = {
  interviewPlan: gql`
    ${useCandidateEventTemplateContent.fragments.interviewPlan}
    fragment CandidateEmailCard_interviewPlan on JobStage {
      id
      ...useCandidateEventTemplateContent_interviewPlan
    }
  `,
};

export default CandidateEmailCard;
