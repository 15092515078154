/* eslint-disable max-lines */
import React, { useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { assertIsoTimestamp, guessIsoTimestamp } from '@modernloop/shared/datetime';
import { logDatadogError } from '@modernloop/shared/utils';
import { CircularProgress, Stack } from '@mui/material';
import { startOfDay } from 'date-fns';
import { useSnackbar } from 'notistack';

import {
  InterviewerRole,
  ModuleMemberInterviewStatus,
  TrainingStatus,
  UpdateModuleLogModal_ModuleMemberFragment,
  UpdateModuleLogModal_ModuleMemberInterviewFragment,
  useApplicationStageInterviewInterviewerUpsertMutation,
  useInterviewModuleMemberLogUpdateMutation,
} from 'src/generated/mloop-graphql';

import {
  getReverseShadowPendingCount,
  getShadowPendingCount,
  resetModuleMemberListCache,
} from 'src/entities/InterviewModuleMember/utils';

import RoleChangeAlert from '../RoleChangeAlert';

import ModalBody from './Common/ModalBody';
import DeleteModuleLogModal from './DeleteModuleLogModal';

type Props = {
  moduleMemberId: string;
  onClose: () => void;
};

type Fragments = {
  moduleMemberInterview: UpdateModuleLogModal_ModuleMemberInterviewFragment;
  moduleMember: UpdateModuleLogModal_ModuleMemberFragment;
};

const UpdateModuleLogModal: FCWithFragments<Fragments, Props> = ({
  onClose,
  moduleMemberId,
  moduleMemberInterview,
  moduleMember,
}) => {
  const moduleMemberInterviewRole: TrainingStatus | 'NONE' =
    moduleMemberInterview.status !== ModuleMemberInterviewStatus.DidntCount
      ? moduleMemberInterview.trainingRole
      : 'NONE';
  const [selectedRole, setSelectedRole] = useState<TrainingStatus | 'NONE'>(moduleMemberInterviewRole);
  const [selectedDate, setSelectedDate] = useState(
    moduleMemberInterview.interviewDate
      ? guessIsoTimestamp(moduleMemberInterview.interviewDate)
      : assertIsoTimestamp(startOfDay(new Date()).toISOString())
  );
  const [openDeleteModuleLogModal, setOpenDeleteModuleLogModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [updateShouldCountForModule, { loading: shouldCountForModuleLoading }] =
    useApplicationStageInterviewInterviewerUpsertMutation({
      refetchQueries: ['InterviewModuleMemberSidepanelQuery', 'InterviewModuleMemberModuleLogSidepanel'],
    });
  const [updateManualLog, { loading: updatemanualLogLoading }] = useInterviewModuleMemberLogUpdateMutation({
    refetchQueries: ['InterviewModuleMemberSidepanelQuery', 'InterviewModuleMemberModuleLogSidepanel'],
  });

  const roleImpacted: TrainingStatus | null = useMemo(() => {
    // If member is already trained, no role will be changed
    if (moduleMember.status === TrainingStatus.Trained) {
      return null;
    }

    // If interview log member role is getting changed
    if (selectedRole !== moduleMemberInterviewRole) {
      // If member status is shadow
      if (moduleMember.status === TrainingStatus.Shadow) {
        // If the shadows getting added
        if (
          selectedRole === TrainingStatus.Shadow &&
          moduleMember.stats?.manualCompletedAsShadow + moduleMember.stats?.completedAsShadow + 1 >=
            moduleMember.shadowsRequired
        ) {
          // Check if can move to reverse shadow
          if (getReverseShadowPendingCount({ moduleMember }, undefined) > 0) {
            return TrainingStatus.ReverseShadow;
          }
          return TrainingStatus.Trained;
        }

        // If the  shadow getting removed but still meets the requirement
        if (
          selectedRole !== TrainingStatus.Shadow &&
          moduleMemberInterviewRole === TrainingStatus.Shadow &&
          moduleMember.stats?.manualCompletedAsShadow + moduleMember.stats?.completedAsShadow - 1 >=
            moduleMember.shadowsRequired
        ) {
          // Check if can move to reverse shadow
          if (
            selectedRole === TrainingStatus.ReverseShadow &&
            moduleMember.stats?.manualCompletedAsReverseShadow + moduleMember.stats?.completedAsReverseShadow + 1 <
              moduleMember.reverseShadowsRequired
          ) {
            return TrainingStatus.ReverseShadow;
          }
          return TrainingStatus.Trained;
        }

        // If reverse shadow getting added
        if (
          selectedRole === TrainingStatus.ReverseShadow &&
          getShadowPendingCount({ moduleMember }, undefined) <= 0 &&
          moduleMember.stats?.manualCompletedAsReverseShadow + moduleMember.stats?.completedAsReverseShadow + 1 >=
            moduleMember.reverseShadowsRequired
        ) {
          return TrainingStatus.Trained;
        }
      }

      // If current role reverse shadow
      if (moduleMember.status === TrainingStatus.ReverseShadow) {
        // Check if shadow log is impacted
        if (moduleMemberInterviewRole === TrainingStatus.Shadow && selectedRole !== TrainingStatus.Shadow) {
          // If the  shadows not satisfied the required number of shadows
          if (
            moduleMember.stats?.manualCompletedAsShadow + moduleMember.stats?.completedAsShadow - 1 <
            moduleMember.shadowsRequired
          ) {
            return TrainingStatus.Shadow;
          }
        }
        if (
          moduleMemberInterviewRole !== TrainingStatus.ReverseShadow &&
          selectedRole === TrainingStatus.ReverseShadow
        ) {
          // If the reverse shadows still satisfied the required number of reverse shadows
          if (
            moduleMember.stats?.manualCompletedAsReverseShadow + moduleMember.stats?.completedAsReverseShadow + 1 >=
            moduleMember.reverseShadowsRequired
          ) {
            return TrainingStatus.Trained;
          }
        }
      }
    }
    return null;
  }, [moduleMember, moduleMemberInterviewRole, selectedRole]);

  const getRoleOptions = () => {
    const Defaultoptions = [
      {
        key: TrainingStatus.Shadow,
        label: 'Shadow',
      },
      {
        key: TrainingStatus.ReverseShadow,
        label: 'Reverse shadow',
      },
      {
        key: TrainingStatus.Trained,
        label: 'Trained',
      },
    ];
    // eslint-disable-next-line no-underscore-dangle
    if (moduleMemberInterview.__typename === 'ModuleMemberApplicationStageInterviewLog') {
      return [{ key: 'NONE', label: 'None' }, ...Defaultoptions];
    }
    return Defaultoptions;
  };

  const getContent = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (moduleMemberInterview.__typename === 'ModuleMemberApplicationStageInterviewLog') {
      return (
        <ModalBody
          setSelectedDate={setSelectedDate}
          setSelectedRole={setSelectedRole}
          selectedDate={selectedDate}
          selectedRole={selectedRole}
          roleOptions={getRoleOptions()}
          isDateDisabled
        />
      );
    }
    return (
      <ModalBody
        setSelectedDate={setSelectedDate}
        setSelectedRole={setSelectedRole}
        selectedDate={selectedDate}
        selectedRole={selectedRole}
        roleOptions={getRoleOptions()}
      />
    );
  };

  const getInterviewerRole = () => {
    switch (selectedRole) {
      case TrainingStatus.Shadow:
        return InterviewerRole.Shadow;
      case TrainingStatus.ReverseShadow:
        return InterviewerRole.ReverseShadow;
      case TrainingStatus.Trained:
        return InterviewerRole.Interviewer;
      default:
        return null;
    }
  };

  const handleUpdate = async () => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      if (moduleMemberInterview.__typename === 'ModuleMemberApplicationStageInterviewLog') {
        await updateShouldCountForModule({
          variables: {
            input: {
              applicationStageInterviewId: moduleMemberInterview.applicationStageInterview?.id,
              interviewerId: moduleMemberId,
              shouldCountForModule: selectedRole !== 'NONE',
              interviewerRole: selectedRole !== 'NONE' ? getInterviewerRole() : null,
            },
          },
          update: (cache) => {
            if (roleImpacted) {
              resetModuleMemberListCache({
                cache,
                moduleId: moduleMember.interviewModuleId,
                employeeIds: [moduleMember.employeeId],
              });
            }
          },
        });
      } else {
        await updateManualLog({
          variables: {
            input: {
              id: moduleMemberInterview.id,
              interviewDate: selectedDate,
              role: selectedRole as TrainingStatus,
            },
          },
          update: (cache) => {
            if (roleImpacted) {
              resetModuleMemberListCache({
                cache,
                moduleId: moduleMember.interviewModuleId,
                employeeIds: [moduleMember.employeeId],
              });
            }
          },
        });
      }
      enqueueSnackbar('Module log updated', { variant: 'success' });
      onClose();
    } catch (error) {
      logDatadogError(error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const getActionButton = () => {
    // eslint-disable-next-line no-underscore-dangle
    if (moduleMemberInterview.__typename !== 'ModuleMemberApplicationStageInterviewLog') {
      return {
        label: 'Delete log',
        onClick: () => setOpenDeleteModuleLogModal(true),
      };
    }
    return undefined;
  };

  return (
    <>
      <Dialog
        decoration={
          <img style={{ width: '64px', height: '64px' }} src="/static/images/scheduling/edit_64.png" alt="Share link" />
        }
        cancelOptions={{ label: 'Cancel', onClick: onClose }}
        secondaryOptions={getActionButton()}
        submitOptions={{
          label: 'Save',
          onClick: handleUpdate,
          isDisabled: false,
          endIcon:
            shouldCountForModuleLoading || updatemanualLogLoading ? (
              <CircularProgress color="inherit" size="16px" />
            ) : undefined,
        }}
        title="Edit module log"
        open
        maxWidth="xs"
        onClose={onClose}
      >
        <Stack spacing={2.5}>
          {getContent()}
          <RoleChangeAlert roleImpacted={roleImpacted} />
        </Stack>
      </Dialog>

      {openDeleteModuleLogModal && (
        <DeleteModuleLogModal
          onClose={() => {
            setOpenDeleteModuleLogModal(false);
          }}
          onDelete={() => {
            setOpenDeleteModuleLogModal(false);
            onClose();
          }}
          moduleMemberInterview={moduleMemberInterview}
          moduleMember={moduleMember}
        />
      )}
    </>
  );
};

export const UpdateManualLogMutation = gql`
  mutation InterviewModuleMemberLogUpdate($input: InterviewModuleMemberLogUpdateInput!) {
    interviewModuleMemberLogUpdate(input: $input) {
      interviewModuleMemberManualLog {
        id
      }
    }
  }
`;

export const UpdateDontCountMutation = gql`
  mutation ApplicationStageInterviewInterviewerUpsert($input: ApplicationStageInterviewInterviewerUpsertInput!) {
    applicationStageInterviewInterviewerUpsert(input: $input) {
      result
    }
  }
`;

UpdateModuleLogModal.fragments = {
  moduleMemberInterview: gql`
    ${DeleteModuleLogModal.fragments.moduleMemberInterview}

    fragment updateModuleLogModal_moduleMemberInterview on ModuleMemberInterview {
      id
      interviewDate
      trainingRole
      status
      ... on ModuleMemberApplicationStageInterviewLog {
        applicationStageInterview {
          id
        }
        __typename
      }
      __typename
      ...deleteModuleLogModal_moduleMemberInterview
    }
  `,
  moduleMember: gql`
    ${DeleteModuleLogModal.fragments.moduleMember}
    ${getReverseShadowPendingCount.fragments.moduleMember}
    fragment updateModuleLogModal_moduleMember on InterviewModuleMember {
      id
      employeeId
      interviewModuleId
      shadowsRequired
      status
      reverseShadowsRequired
      stats {
        completedAsShadow
        completedAsReverseShadow
        manualCompletedAsShadow
        manualCompletedAsReverseShadow
      }
      ...deleteModuleLogModal_moduleMember
      ...getReverseShadowCompletedCount_moduleMember
    }
  `,
};

export default UpdateModuleLogModal;
