import { useEffect } from 'react';

import { useShouldLogStatistics } from '@modernloop/shared/hooks';
import * as amplitude from 'amplitude-js';

import type { UserInfo } from 'src/contexts/auth0/Auth0Context';

/**
 * Identifies the user in Amplitude. Sends name, user, email and organization,
 * Our dashboard: https://analytics.amplitude.com/modernloop/activity
 * Api Docs: https://developers.amplitude.com/docs/javascript#setting-a-user-property
 *
 * @param userInfo from useAuth();
 * @returns void
 */
export function identifyAmplitudeUser(userInfo: UserInfo | null): void {
  if (!userInfo || !userInfo.userId) {
    return;
  }

  if (!userInfo.orgId) {
    return;
  }

  const employeeName = userInfo?.employeeName || null;
  const employeeGivenName = employeeName?.split(' ').slice(0, -1).join(' ');
  const employeeFamilyName = employeeName?.split(' ').slice(-1).join(' ');

  amplitude.getInstance().setUserId(userInfo?.userId || null);
  if (userInfo?.orgId) {
    amplitude.getInstance().setGroup('organizationID', userInfo?.orgId);
  }

  if (userInfo?.userId) {
    amplitude.getInstance().setUserProperties({
      user_type: 'employee',
      email: userInfo?.userEmail,
      org_id: userInfo?.orgId,
      org_name: userInfo?.orgName,
      // keeping organization in case any legacy dashboards use it
      organization: userInfo?.orgId
        ? {
            name: userInfo?.orgName,
            id: userInfo?.orgId,
          }
        : null,
      // TODO: get name from auth0 if employee record doesn't exist
      name: employeeName
        ? {
            full: employeeName,
            given: employeeGivenName,
            family: employeeFamilyName,
          }
        : null,
    });
  }

  const identify = new amplitude.Identify();
  amplitude.getInstance().identify(identify);
}

/**
 * Identifies the user in Amplitude. Sends name, user, email and organization,
 * Must be called within a child of AuthProvider and CookieConsentProvider.
 */
export const useIdentifyAmplitudeUser = (userInfo: UserInfo | null) => {
  const shouldLogStatistics = useShouldLogStatistics();
  useEffect(
    () => {
      if (!shouldLogStatistics) return;
      identifyAmplitudeUser(userInfo);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(userInfo), shouldLogStatistics]
  );
};
