import { gql } from '@apollo/client';
import { trim } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  InterviewType,
  JobStageInterviewGroupInput,
  JobStageInterviewInput,
  JobStageInterviewSeatInput,
  useSaveJobStageInterviewPlanMutation,
} from 'src/generated/mloop-graphql';

import { PREFERENCE_LEVEL_NON_PREFERRED } from 'src/constants/InterviewPlan';

import useGenericOnError from 'src/hooks/useGenericOnError';

import { getAllJobStageInterviewByJobStageId } from 'src/store/selectors/job-stage-interview';
import { getAllJobStageGroupByJobStageId } from 'src/store/selectors/job-stage-interview-group';
import { getAllJobStageInterviewSeatByJobStageId } from 'src/store/selectors/job-stage-interview-seat';
import { JobStageInterview } from 'src/store/slices/job-stage-interview';
import { JobStageInterviewGroup } from 'src/store/slices/job-stage-interview-group';
import { JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import { isBreak, isDayBreak } from 'src/utils/interview';
import logError from 'src/utils/logError';

import { useSelector } from 'src/store';

import { JobStageInterviewPlanFragment } from './UseFetchInterviewPlan';

export const SaveJobStageInterviewPlanQuery = gql`
  ${JobStageInterviewPlanFragment}
  mutation SaveJobStageInterviewPlan($input: JobStageInterviewPlanUpsertInput!) {
    jobStageInterviewPlanUpsert(input: $input) {
      jobStage {
        ...JobStage_InterviewPlan
      }
    }
  }
`;

export function getShouldUseAtsNameAndDuration(jobStageInterview: JobStageInterview) {
  const useAtsName =
    trim(jobStageInterview.name || undefined) === trim(jobStageInterview?.atsInterviewName || undefined);
  // for lever useAtsDuration this will immediately be set to false...
  const useAtsDuration = jobStageInterview.duration === jobStageInterview?.atsInterviewDuration;
  return {
    useAtsName,
    useAtsDuration,
  };
}

export const getSaveInterviewPlanInput = ({
  jobStageId,
  generateNewIds,
  jobStageInterviewGroups,
  jobStageInterviews,
  jobStageInterviewSeats,
}: {
  jobStageId: string;
  generateNewIds?: boolean;
  jobStageInterviewGroups: JobStageInterviewGroup[];
  jobStageInterviews: JobStageInterview[];
  jobStageInterviewSeats: JobStageInterviewSeat[];
}): JobStageInterviewGroupInput[] => {
  const getId = (id: string): string => {
    return generateNewIds ? uuid() : id;
  };

  const linkSeatIdMap: {
    [linkedSeatId: string]: string;
  } = {};

  const groups = jobStageInterviewGroups.map((group, jobStageInterviewGroupIndex) => {
    const finalGroupId = getId(group.id);
    const interviews: JobStageInterviewInput[] = group.jobStageInterviewIds
      .map((interviewId, jobStageInterviewIndex) => {
        const interview = jobStageInterviews.find((i) => i.id === interviewId);

        const finalInterviewId = getId(interviewId);

        if (!interview) return null;

        const seats: JobStageInterviewSeatInput[] =
          interview?.seatIds.map((seatId, jobStageInterviewSeatIndex) => {
            const seat = jobStageInterviewSeats.find((s) => s.id === seatId);
            // If this seat has a linked seat to another interview, we need to make sure we use the same id
            if (seat?.interviewSeatId) {
              linkSeatIdMap[seat.interviewSeatId] = linkSeatIdMap[seat.interviewSeatId] || getId(seat.interviewSeatId);
            }

            linkSeatIdMap[seatId] = linkSeatIdMap[seatId] || getId(seatId);
            const finalSeatId = linkSeatIdMap[seatId];

            const interviewerPreferrenceLevelMap: { [employeeId: string]: number } = {};
            if (seat?.freeformSeat) {
              seat.freeformSeat.jobStageInterviewSeatEmployees?.forEach((emp) => {
                if (emp?.employeeId) {
                  interviewerPreferrenceLevelMap[emp.employeeId] =
                    emp.preferenceLevel || PREFERENCE_LEVEL_NON_PREFERRED;
                }
              });
            }

            return {
              id: finalSeatId,
              jobStageInterviewId: finalInterviewId,
              index: jobStageInterviewSeatIndex,
              moduleSeat: seat?.interviewId
                ? {
                    interviewModuleId: seat.interviewId,
                    jobStageInterviewSeatAttributes: seat.attributeMap
                      ? Object.keys(seat.attributeMap)
                          .map((attributeNameId) => {
                            if (!seat.attributeMap) return [];
                            return seat.attributeMap[attributeNameId].map((attributeTagValueId) => ({
                              id: uuid(),
                              attributeNameId,
                              attributeTagValueId,
                              jobStageInterviewSeatId: finalSeatId,
                            }));
                          })
                          .flat()
                      : undefined,
                    selectedEmployeeIds: [
                      ...(seat.selectedTrainedInterviewerIds || []),
                      ...(seat.selectedShadowInterviewerIds || []),
                      ...(seat.selectedReverseShadowInterviewerIds || []),
                    ],
                  }
                : undefined,
              linkedSeat: seat?.interviewSeatId
                ? { linkedJobStageInterviewSeatId: linkSeatIdMap[seat.interviewSeatId] }
                : undefined,
              freeformSeat: seat?.employeeIds
                ? {
                    jobStageInterviewSeatEmployees: seat.employeeIds.map((employeeId) => ({
                      employeeId,
                      jobStageInterviewSeatId: finalSeatId,
                      preferenceLevel: interviewerPreferrenceLevelMap[employeeId],
                    })),
                  }
                : undefined,
            };
          }) || [];

        const interviewNameDuration = getShouldUseAtsNameAndDuration(interview);

        const interviewObj: JobStageInterviewInput = {
          id: finalInterviewId,
          jobStageInterviewGroupId: finalGroupId,
          jobStageId,
          interviewType: (isBreak(interview?.type) || isDayBreak(interview?.type)
            ? interview?.type.toUpperCase()
            : InterviewType.Interview) as InterviewType,
          customDuration: interview?.duration,
          name: interview?.name,
          index: jobStageInterviewIndex,
          duration: interview?.duration,
          isLockedOrder: interview?.isLockedOrder,
          useAtsName: interviewNameDuration.useAtsName,
          useAtsDuration: interviewNameDuration.useAtsDuration,
          staticLinks: interview?.staticLinks,
          dynamicLinkTypes: interview?.dynamicLinkTypes,
          jobStageInterviewSeats: seats,
          isHiddenFromCandidate: interview?.isHiddenFromCandidate,
        };

        interviewObj.atsInterviewDefinitionId = interview?.atsInterviewDefinition?.atsId;

        return interviewObj;
      })
      .filter((i) => Boolean(i)) as JobStageInterviewInput[];

    const result: JobStageInterviewGroupInput = {
      id: finalGroupId,
      index: jobStageInterviewGroupIndex,
      name: group.name,
      jobStageId,
      jobStageInterviews: interviews,
      locked: group.locked,
    };
    return result;
  });

  return groups;
};

export const useSaveInterviewPlanInput = (
  jobStageId: string,
  generateNewIds?: boolean,
  customJobStageId?: string
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
): JobStageInterviewGroupInput[] => {
  const finalJobStageId = customJobStageId || jobStageId;

  const jobStageInterviewGroups = useSelector((state) => getAllJobStageGroupByJobStageId(state, jobStageId));
  const jobStageInterviews = useSelector((state) => getAllJobStageInterviewByJobStageId(state, jobStageId));
  const jobStageInterviewSeats = useSelector((state) => getAllJobStageInterviewSeatByJobStageId(state, jobStageId));

  return getSaveInterviewPlanInput({
    jobStageId: finalJobStageId,
    generateNewIds,
    jobStageInterviewGroups,
    jobStageInterviews,
    jobStageInterviewSeats,
  });
};

export const useSaveInterviewPlanWithInput = ({
  jobStageId,
  customJobStageId,
  groups,
}: {
  jobStageId: string;
  customJobStageId?: string;
  groups: JobStageInterviewGroupInput[];
}) => {
  const genericOnError = useGenericOnError();

  return useSaveJobStageInterviewPlanMutation({
    variables: {
      input: {
        jobStageId: customJobStageId || jobStageId,
        groups,
      },
    },
    onError: (error) => {
      genericOnError(error);
      logError(error);
    },
  });
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
const useSaveInterviewPlan = ({
  jobStageId, // This is the jobStageId of the jobStage that the interview plan is being saved for
  customJobStageId,
  generateNewIds,
}: {
  jobStageId: string;
  customJobStageId?: string;
  generateNewIds?: boolean;
}) => {
  const finalJobStageId = customJobStageId || jobStageId;
  const saveInterviewPlanInput = useSaveInterviewPlanInput(finalJobStageId, generateNewIds);

  return useSaveInterviewPlanWithInput({
    jobStageId,
    customJobStageId,
    groups: saveInterviewPlanInput,
  });
};

export default useSaveInterviewPlan;
