import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ButtonBase, Typography } from '@mui/material';

type Props = {
  options: number;
  day: MaterialUiPickersDate;
  selected: boolean;
};

const OptionDay = ({ options, day, selected }: Props) => {
  return (
    <ButtonBase
      data-testid="self-schedule-day-option-container"
      data-selected={selected} // Used by tests
      disableRipple={false}
      disableTouchRipple={false}
      sx={[
        {
          borderRadius: '6px',
          width: '44px',
          height: '44px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
          border: (theme) => `1px solid ${theme.palette.border}`,
          overflow: 'hidden',
        },
        selected && {
          border: 'none',
          backgroundColor: (theme) => theme.palette.background.info,
        },
      ]}
    >
      <Typography
        variant="body2"
        data-testid="self-schedule-option-day"
        fontWeight={selected ? 600 : 500}
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          color: selected ? (theme) => theme.palette.primary.main : undefined,
        }}
      >
        <span style={{ textAlign: 'center', width: '100%' }}>{day?.format('D')}</span>
      </Typography>

      <Typography
        variant="body2"
        data-testid="self-schedule-option-count"
        fontWeight={500}
        sx={[
          !selected && {
            width: '100%',
            backgroundColor: (theme) => theme.palette.background.contrast,
          },
          selected &&
            options > 0 && {
              width: '100%',
              backgroundColor: (theme) => theme.palette.primary.light,
              color: (theme) => theme.palette.secondary.light,
            },
          selected &&
            options === 0 && {
              width: '100%',
              backgroundColor: (theme) => theme.grey.solid.high,
              color: (theme) => theme.palette.secondary.light,
            },
        ]}
      >
        {options}
      </Typography>
    </ButtonBase>
  );
};

export default OptionDay;
