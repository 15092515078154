/**
 * Color constants
 * FIGMA → https://www.figma.com/file/Yq6Wgf9c3VvcNAAu25KYAZ/Styles?node-id=54%3A42&viewport=-2100%2C289%2C1.5428613424301147
 */
// eslint-disable-next-line no-restricted-imports, modernloop/restrict-imports.cjs
import { blue, whitescale, blackscale, red, green, neutral, yellow } from '../../colors';
import { ThemeOptions, alpha } from '@mui/material';

export const palette: ThemeOptions['palette'] = {
  mode: 'dark',
  borderState: {
    focus: whitescale[25],
    focusShadow: whitescale[70],
    // This always matches theme.palette.error.main
    error: red[60],
    default: whitescale[90], // same as divider
    subtle: neutral[25],
  },
  common: {
    white: whitescale[0],
    black: blackscale[0],
  },
  divider: whitescale[90],
  text: {
    primary: whitescale[0],
    secondary: whitescale[35],
    disabled: whitescale[60],
  },
  // These seems to map to "default"
  action: {
    active: whitescale[40],
    focus: whitescale[80],
    selected: whitescale[90],
    hover: whitescale[95],
    disabled: whitescale[60],
    disabledBackground: whitescale[98],
  },
  background: {
    default: neutral[18],
    paper: neutral[18],
    contrast: neutral[15],
    navigation: alpha(neutral[15], 0.7), // should be the same as background.contrast but lightened to 70%
    paperHeader: alpha(neutral[15], 0.5), // should be the same as background.contrast but lightened to 50%
    paperFooter: alpha(neutral[15], 0.3), // should be the same as background.contrast but lightened to 30%
    elevation: {
      one: neutral[19], // Reserved for hover states.
      two: neutral[20], // Drawers, menus, rich tooltips, floating cards.
      three: neutral[20], // snackbars, floating actionbars (FAB)
      four: neutral[20], // Maybe hover action on FAB's
      five: neutral[20], // Dialogs.
    },
  },
  primary: {
    tint: alpha(blue[35], 0.2),
    light: blue[60],
    main: blue[50],
    dark: blue[40],
    contrastText: blue[100],
  },
  secondary: {
    tint: alpha(neutral[35], 0.2),
    light: neutral[10],
    main: neutral[15],
    dark: neutral[25],
    contrastText: neutral[98],
  },
  success: {
    tint: alpha(green[35], 0.2),
    light: green[80],
    main: green[70],
    dark: green[60],
    contrastText: green[10],
  },
  info: {
    tint: alpha(blue[35], 0.2),
    light: blue[90],
    main: blue[80],
    dark: blue[70],
    contrastText: blue[10],
  },
  warning: {
    tint: alpha(yellow[35], 0.2),
    light: yellow[90],
    main: yellow[80],
    dark: yellow[70],
    contrastText: yellow[10],
  },
  error: {
    tint: alpha(red[35], 0.2),
    light: red[70],
    main: red[60],
    dark: red[50],
    contrastText: red[10],
  },
};
