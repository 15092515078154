import React, { ElementType } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import MuiLink, { LinkProps as MuiLinkProps } from '@material-ui/core/Link';
import { LocationDescriptor } from 'history';

import Label, { LabelProps } from 'src/components/label';
import { BaseProps } from 'src/components/types';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import useIsInExtension from 'src/recruiting-portal/pages/extension/utils/useIsInExtension';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type LinkProps = BaseProps &
  Omit<MuiLinkProps, 'variant' | 'color'> & {
    color?: LabelProps['color'];
    variant?: LabelProps['variant'];
    fontWeight?: LabelProps['fontWeight'];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component?: ElementType<any>;
    to?: LocationDescriptor;
  };

const Link = ({
  dataTestId,
  variant,
  color,
  children,
  fontWeight,
  style,
  target,
  ...props
}: LinkProps): JSX.Element => {
  const isInExtension = useIsInExtension();

  return (
    <MuiLink
      color={color === 'inherit' ? 'inherit' : undefined}
      {...props}
      style={{ cursor: 'pointer', ...style }}
      data-testid={dataTestId}
      // If target is explicitly passed from outside then use it otherwise default open new tab when in extension
      target={isInExtension ? target || '_blank' : target}
    >
      <Label variant={variant} fontWeight={fontWeight} color={color} style={{ display: 'inline' }}>
        {children}
      </Label>
    </MuiLink>
  );
};

export default Link;
