import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { MeetingRoomIcon } from '@modernloop/shared/icons';
import { Box, Stack, Typography } from '@mui/material';

import { DebriefContentDetailsMeetingRoom_MeetingRoomFragment, ResponseStatus } from 'src/generated/mloop-graphql';

import getResponseStatusNode from 'src/entities/common/getResponseStatusNode';

type Fragments = {
  meetingRoom: DebriefContentDetailsMeetingRoom_MeetingRoomFragment;
};

const DebriefContentDetailsMeetingRoom: FCWithFragments<Fragments> = ({ meetingRoom }) => {
  return (
    <Stack direction="row" justifyContent="space-between" spacing={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <MeetingRoomIcon color="action" />
        <Typography variant="body2">{meetingRoom.meetingRoom?.description}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {getResponseStatusNode(meetingRoom.responseStatus || ResponseStatus.NeedsAction)}
        <Box width={32} height={32} />
      </Stack>
    </Stack>
  );
};

DebriefContentDetailsMeetingRoom.fragments = {
  meetingRoom: gql`
    fragment DebriefContentDetailsMeetingRoom_meetingRoom on ApplicationDebriefRoom {
      applicationDebriefId
      meetingRoomId
      responseStatus
      meetingRoom {
        id
        name
        description
      }
    }
  `,
};

export default DebriefContentDetailsMeetingRoom;
