import { logError } from '../utils/logError';
import { FeatureFlagString } from './FeatureFlagString';
import { useFeatureFlagLocal } from './useFeatureFlagLocal';
// eslint-disable-next-line no-restricted-imports
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';

/**
 * String based feature flag checker.
 * It complains if it can't find the specified flag in the LaunchDarkly payload.
 *
 * https://docs.launchdarkly.com/home/getting-started/feature-flags
 */
export function useFeatureFlagString(key: FeatureFlagString): string {
  const flags = useFlags();
  const { localStorageFeatureFlags } = useFeatureFlagLocal();

  if (!(key in flags)) {
    if (!isEmpty(flags)) {
      logError(`Feature Flag "${key}" is not being sent to client`);
    }
    return '';
  }

  if (flags['MLOnly'] === true) {
    const localFeatureFlagValue = localStorageFeatureFlags[key];
    if (localFeatureFlagValue !== undefined && typeof localFeatureFlagValue === 'string') {
      return localFeatureFlagValue;
    }
  }

  return flags[key];
}
