import React from 'react';

import BaseIcon, { Props } from './Icon';

const CalendarIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 2C7.75 1.58579 7.41422 1.25 7 1.25C6.58579 1.25 6.25 1.58579 6.25 2V3.25H5C3.48122 3.25 2.25 4.48122 2.25 6L2.25002 15C2.25002 16.5188 3.48124 17.75 5.00002 17.75H15C16.5188 17.75 17.75 16.5188 17.75 15L17.75 6C17.75 4.48121 16.5188 3.25 15 3.25H13.75V2C13.75 1.58579 13.4142 1.25 13 1.25C12.5858 1.25 12.25 1.58579 12.25 2V3.25H7.75V2ZM6.25 4.75V5C6.25 5.41421 6.58579 5.75 7 5.75C7.41422 5.75 7.75 5.41421 7.75 5V4.75H12.25V5C12.25 5.41421 12.5858 5.75 13 5.75C13.4142 5.75 13.75 5.41421 13.75 5V4.75H15C15.6903 4.75 16.25 5.30964 16.25 6L16.25 15C16.25 15.6904 15.6904 16.25 15 16.25H5.00002C4.30966 16.25 3.75002 15.6904 3.75002 15L3.75 6C3.75 5.30965 4.30965 4.75 5 4.75H6.25Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CalendarIcon;
