import { SvgIcon, SvgIconProps } from './SvgIcon';

export const DividerIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, sx, fill, ...rest } = props;
  return (
    // TODO: Fix this the next time the file is edited
    // eslint-disable-next-line no-restricted-syntax
    <SvgIcon {...rest} sx={{ ...sx, fill: fill || 'none' }} viewBox={viewBox ?? '0 0 20 20'}>
      <line x1="17.25" y1="9.25" x2="2.75" y2="9.25" stroke="#787882" strokeWidth="1.5" strokeLinecap="round" />
      <rect x="4" y="13" width="12" height="5" rx="1" fill="#161727" fillOpacity="0.3" />
      <rect x="4" y="2" width="12" height="4" rx="1" fill="#161727" fillOpacity="0.3" />
    </SvgIcon>
  );
};
