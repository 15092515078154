import { ApolloError } from '@apollo/client';

export type CandidateAvailabilityOptions = {
  id?: string;
  applicationId: string;
  jobStageId: string;
  taskId?: string;
  numberOfDays: number;
  minutesPerDays: number;
  minimumTimeBlockMinutes: number;
  advanceNoticeMinutes?: number;
  candidateNote?: string;
  createdAt?: string;
  updatedAt?: string;
  lastCandidateCommunicationSentAt?: string;
  creator?: string;
  useRollingDays?: boolean;
  timeframeNumberOfDays?: number;
  inclusionDays?: string[];
  canScheduleOverFreeTime?: boolean;
  canScheduleOverRecruitingKeywords?: boolean;
  canScheduleOverAvailableKeywords?: boolean;
  shouldRespectLoadLimit?: boolean;

  // TODO (cam) - `user_show_suggested_availability` - remove these fields once released
  timezone: string;
  availabilityStartDate?: string; // ISO date string
  availabilityEndDate?: string; // ISO date string
  externalId?: string;
  employeeId?: string;

  // These fields are only to be used on UI
  isValidInterviewPlan?: boolean;
  loading?: boolean;
  error?: ApolloError | undefined;
};

export type UpdateCandidateAvailabilityOptions = Pick<CandidateAvailabilityOptions, 'applicationId' | 'jobStageId'> &
  Partial<Omit<CandidateAvailabilityOptions, 'applicationId' | 'jobStageId'>>;

export const DEFAULT_NUMBER_OF_DAYS = -1;
export const DEFAULT_MINUTES_PER_DAY = 300; // 5 HOURS
export const DEFAULT_MINIMUM_TIME_BLOCK_MINUTES = 60; // 1 HOUR
