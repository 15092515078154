import { SvgIcon, SvgIconProps } from '@mui/material';

export const CompanyHolidayIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;

  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <rect width="20" height="20" rx="10" fill="#FADC6E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2798 4.79317C13.5801 4.93322 13.7101 5.29021 13.57 5.59053L13.4719 5.80098C15.2554 6.87565 16.7721 9.08684 15.9221 11.8899C15.8472 12.1371 15.6225 12.3085 15.3643 12.3156C15.1062 12.3226 14.8724 12.1637 14.7841 11.921C14.6574 11.5728 14.3337 11.2429 13.8978 11.0396C13.462 10.8364 13.0012 10.8005 12.653 10.9272C12.5034 10.9817 12.3384 10.9745 12.1942 10.9072C12.05 10.84 11.9384 10.7182 11.8839 10.5686C11.8133 10.3747 11.6816 10.1864 11.5034 10.0225L9.89668 13.4682C11.3638 13.6509 12.7705 14.2082 13.6496 15.2028C13.8691 15.451 13.8457 15.8302 13.5974 16.0497C13.3491 16.2691 12.97 16.2458 12.7505 15.9975C11.8615 14.9918 10.0183 14.4753 8.17181 14.6256C6.76378 14.7402 5.52689 15.2313 4.84961 15.9975C4.63014 16.2458 4.25096 16.2692 4.00268 16.0497C3.7544 15.8302 3.73104 15.4511 3.9505 15.2028C4.91214 14.1149 6.50119 13.5576 8.07446 13.4295C8.24905 13.4153 8.4256 13.4063 8.60328 13.4024L10.4159 9.51529C10.1758 9.48412 9.94679 9.50426 9.75278 9.57487C9.60324 9.62929 9.43821 9.62209 9.29399 9.55484C9.14977 9.48759 9.03818 9.3658 8.98375 9.21627C8.85702 8.86807 8.53333 8.53811 8.09747 8.33486C7.66162 8.13162 7.20079 8.09576 6.85259 8.22249C6.60991 8.31082 6.33793 8.23395 6.17739 8.03165C6.01685 7.82935 6.00377 7.54702 6.14492 7.33075C7.74583 4.87778 10.4147 4.61838 12.3843 5.29384L12.4824 5.08339C12.6225 4.78306 12.9795 4.65313 13.2798 4.79317ZM8.60461 7.24729C8.41203 7.15749 8.20686 7.08473 7.99491 7.03349C8.91297 6.27955 10.04 6.06805 11.0853 6.21C10.5253 6.59613 9.97561 7.12475 9.49621 7.86388C9.23532 7.60556 8.92692 7.39759 8.60461 7.24729ZM10.6276 8.33344C11.1423 7.60774 11.7334 7.16671 12.3081 6.87708C12.4556 7.50344 12.4977 8.23975 12.2726 9.10053C12.0402 8.89526 11.7775 8.72682 11.5048 8.59967C11.2321 8.47252 10.9342 8.37953 10.6276 8.33344ZM14.405 9.95205C14.0827 9.80176 13.7251 9.69919 13.3595 9.66538C13.6176 8.82304 13.6692 8.06215 13.605 7.38498C14.3857 8.09446 14.9481 9.09378 14.9607 10.2817C14.7852 10.1523 14.5976 10.0419 14.405 9.95205Z"
        fill="#221C04"
      />
    </SvgIcon>
  );
};
