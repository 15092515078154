import { useEmployeePrefStringArray } from 'src/hooks/api/employee/get';
import { useSetEmployeePrefStringArray } from 'src/hooks/api/employee/set';
import useEmployeeId from 'src/hooks/useEmployeeId';

import { EmployeePrefName } from 'src/utils/api/employee';
import { DoNotShowAgainDialogTypes } from 'src/utils/api/employee/constants';

const useDoNotShowAgainModalEmployeePref = (modalType: DoNotShowAgainDialogTypes, onCompleted?: () => void) => {
  const currentEmployeeId = useEmployeeId();

  const [data, { loading, refetch }] = useEmployeePrefStringArray(
    EmployeePrefName.ARR_DO_NOT_SHOW_AGAIN_MODALS,
    currentEmployeeId || ''
  );

  const doNotShowAgainModalList = (data || []) as DoNotShowAgainDialogTypes[];

  const updatePref = useSetEmployeePrefStringArray(
    EmployeePrefName.ARR_DO_NOT_SHOW_AGAIN_MODALS,
    currentEmployeeId || '',
    onCompleted
  );

  const addToDoNotShowAgainList = async (value: DoNotShowAgainDialogTypes) => {
    const newList = [...doNotShowAgainModalList, value];
    await updatePref(newList);
    await refetch();
  };

  return {
    doNotShowAgainModalList,
    addToDoNotShowAgainList,
    showModal: !doNotShowAgainModalList.includes(modalType),
    loading,
    refetch,
  };
};

export default useDoNotShowAgainModalEmployeePref;
