import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Typography } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { BaseProps } from 'src/components/types';
import {
  SupportedBackgroundColor,
  SupportedTextColor,
  getBackgroundColorFromTheme,
  getTextColorFromTheme,
} from 'src/components/utils/color';

import { Theme } from 'src/theme/type';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type LabelProps = BaseProps & {
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  color?: SupportedTextColor;
  // eslint-disable-next-line react/no-unused-prop-types
  backgroundColor?: SupportedBackgroundColor;
  // eslint-disable-next-line react/no-unused-prop-types
  fontWeight?: 400 | 500 | 600; // normal, medium, semibold
  variant?: 'huge' | 'title' | 'header' | 'body' | 'captions' | 'std-button' | 'small-button';
  noWrap?: boolean;
  style?: React.CSSProperties;
  innerRef?: React.RefObject<HTMLElement>;
  icon?: JSX.Element | null; // An icon added to the label
  component?: 'span'; // The component used for the root node. This is done to avoid nesting a <p> inside another <p>.
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: LabelProps) => {
      const color = props.color ? getTextColorFromTheme(props.color, theme) : theme.palette.text.primary;
      const backgroundColor = props.backgroundColor
        ? getBackgroundColorFromTheme(props.backgroundColor, theme)
        : undefined;
      const padding = props.backgroundColor ? 4 : undefined;
      const borderRadius = props.backgroundColor ? 4 : undefined;
      const display = props.backgroundColor || props.icon ? 'inline-flex' : undefined;
      const alignItems = props.icon ? 'center' : undefined;
      const gap = props.icon ? 8 : undefined;
      return {
        fontFamily: theme.typography.fontFamily,
        color,
        backgroundColor,
        borderRadius,
        padding,
        display,
        alignItems,
        gap,
      };
    },
    huge: (props: LabelProps) => ({
      fontSize: '44px',
      lineHeight: '56px',
      fontWeight: props.fontWeight ?? 600,
    }),
    title: (props: LabelProps) => ({
      fontSize: '22px',
      lineHeight: '28px',
      fontWeight: props.fontWeight ?? 600,
    }),
    header: (props: LabelProps) => ({
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: props.fontWeight ?? 600,
    }),
    body: (props: LabelProps) => ({
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: props.fontWeight ?? 400,
    }),
    captions: (props: LabelProps) => ({
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: props.fontWeight ?? 400,
    }),
    'std-button': (props: LabelProps) => ({
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: props.fontWeight ?? 500,
    }),
    'small-button': (props: LabelProps) => ({
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: props.fontWeight ?? 500,
    }),
  })
);

/**
 * @deprecated - Use `Typography` from `@mui/material` instead
 */
const Label: React.FC<LabelProps> = (props) => {
  const { children, className, dataTestId, variant = 'body', noWrap = false, style, innerRef, icon, component } = props;

  const classes = useStyles(props);

  const conditionalProps = component
    ? {
        component,
      }
    : {};

  return (
    <Typography
      style={style}
      noWrap={noWrap}
      className={clsx(classes.root, classes[variant], className)}
      data-testid={dataTestId}
      innerRef={innerRef}
      {...conditionalProps}
    >
      {icon}
      {children}
    </Typography>
  );
};

export default Label;
