import { SvgIcon, SvgIconProps } from './SvgIcon';

export const RsvpDeclineLeftIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62469 18.7002C2.96993 19.2241 2 18.7579 2 17.9194V5C2 3.34315 3.34315 2 5 2H15C16.6569 2 18 3.34315 18 5V13C18 14.6569 16.6569 16 15 16H8.05234C7.37113 16 6.7102 16.2318 6.17826 16.6574L3.62469 18.7002ZM13.5303 5.46967C13.8232 5.76256 13.8232 6.23744 13.5303 6.53033L11.0607 9L13.5303 11.4697C13.8232 11.7626 13.8232 12.2374 13.5303 12.5303C13.2374 12.8232 12.7626 12.8232 12.4697 12.5303L10 10.0607L7.53033 12.5303C7.23744 12.8232 6.76256 12.8232 6.46967 12.5303C6.17678 12.2374 6.17678 11.7626 6.46967 11.4697L8.93934 9L6.46967 6.53033C6.17678 6.23744 6.17678 5.76256 6.46967 5.46967C6.76256 5.17678 7.23744 5.17678 7.53033 5.46967L10 7.93934L12.4697 5.46967C12.7626 5.17678 13.2374 5.17678 13.5303 5.46967Z"
        fill="#EC6660"
      />
    </SvgIcon>
  );
};
