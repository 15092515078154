/* eslint-disable max-lines */

/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Divider } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useFlag } from '@modernloop/shared/feature-flag';
import { LinearProgress, useTheme } from '@mui/material';

import { DynamicAudience, EmployeeFragment, TemplateFragment, TemplateType } from 'src/generated/mloop-graphql';

import AddAttachments from 'src/components/AddAttachments';
import Alert from 'src/components/Alert';
import { DialogActionsProps } from 'src/components/Dialog/DialogActions';
import DialogPage, { DialogActions, DialogContent, DialogTitle } from 'src/components/Dialog/DialogPage';
import { DialogContainerContent, DialogContainerHeader } from 'src/components/DialogContainer';
import Editor from 'src/components/Editor';
import { useReactQuillRef } from 'src/components/QuillEditor';
import Select, { MenuOption } from 'src/components/Select';
import Stack from 'src/components/Stack';
import TextField from 'src/components/TextField';
import Button from 'src/components/button';

import { Values } from 'src/entities/EmployeePicker';
import EmployeePickerExtended from 'src/entities/EmployeePicker/EmployeePickerExtended';
import SmartEmployeeSelect from 'src/entities/Select/SmartEmployeeSelect';
import TemplateSelect from 'src/entities/Template/TemplateSelect';

import { useInitAttachmentsForEmailTemplate } from 'src/hooks/useAttachments';

import { FileUploadFlow } from 'src/slices/files';

import { TemplateInterface } from 'src/views-new/Settings/TemplateComposer/TemplateInterface';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type EmailContentProps = {
  noPadding?: boolean;
  title: string;
  subTitle?: string;
  candidateId?: string;
  applicationId?: string;
  headerActions?: React.ReactNode;
  dialogActions?: DialogActionsProps;
  alerts?: React.ReactNode;
  taskId?: string;
  jobId?: string;
  taskCreatorId?: string;
  subscriberEmployeeIds?: string[];
  assigneeEmployeeId?: string;
  /** TODO: when `toFreeformEmails === true` fetch email's for employee represented by `toEmployeeIds` */
  useEmployeePickerForTo?: boolean;
  toEmployeeIds?: string[];
  defaultToEmployeeIds?: string[];
  ccEmployeeIds?: string[];
  defaultCcEmployeeIds?: string[];
  bccEmployeeIds?: string[];
  defaultBccEmployeeIds?: string[];
  toFreeformEmails?: string[];
  defaultToFreeformEmails?: string[];
  ccFreeformEmails?: string[];
  defaultCcFreeformEmails?: string[];
  bccFreeformEmails?: string[];
  defaultBccFreeformEmails?: string[];
  ccDynamicAudiences?: DynamicAudience[];
  bccDynamicAudiences?: DynamicAudience[];
  /**
   * selectedToFreeformEmail email is used to determine selected email addres when `useEmployeePickerForTo` is falsy
   */
  selectedToFreeformEmail?: string;
  loading?: boolean;
  subject: string;
  bodyHtml: string;
  fileUploadFlow: FileUploadFlow;
  sendError?: string;

  templateTypes?: TemplateType[];

  selectedTemplateId?: string;
  useDialogPage?: boolean;
  goBack?: () => void;
  onClose?: () => void;
  onToChanged?: (employees: EmployeeFragment[], freeformEmails: string[]) => void;
  onCcChanged?: (employees: EmployeeFragment[], freeformEmails: string[], dynamicAudiences: DynamicAudience[]) => void;
  onBccChanged?: (employees: EmployeeFragment[], freeformEmails: string[], dynamicAudiences: DynamicAudience[]) => void;
  onChangeSubject: (value: string) => void;
  onChangeBodyHtml: (value: string) => void;
  onChangeTemplate?: (value: TemplateInterface) => void;
  filterCcOptions?: (
    employees: Values<EmployeeFragment, boolean | undefined>
  ) => Values<EmployeeFragment, boolean | undefined>;
  filterBccOptions?: (
    employees: Values<EmployeeFragment, boolean | undefined>
  ) => Values<EmployeeFragment, boolean | undefined>;
};

const useStyles = makeStyles(() =>
  createStyles({
    divider: {
      margin: '8px -20px 12px -20px',
    },
    to: {
      width: '100%',
    },
    cc: {
      minWidth: 'unset',
    },
    bcc: {
      minWidth: 'unset',
    },
  })
);

const useSxProps = () => {
  const theme = useTheme();
  return useMemo(() => {
    return {
      dialogContainerHeader: {
        padding: `12px 0px !important`,
        boxShadow: `inset 0 -1px 0 ${theme.palette.divider}`,
        marginBottom: '1px',
      },
      dialogContainerContent: {
        overflow: 'auto',
        padding: '20px 0px !important',
        width: '100%',
      },
    };
  }, [theme.palette.divider]);
};

const EmailContent = ({
  noPadding = false,
  title,
  subTitle,
  headerActions,
  dialogActions,
  alerts,
  useEmployeePickerForTo,
  toEmployeeIds,
  defaultToEmployeeIds,
  ccEmployeeIds,
  defaultCcEmployeeIds,
  bccEmployeeIds,
  defaultBccEmployeeIds,
  candidateId,
  taskId,
  jobId,
  taskCreatorId,
  subscriberEmployeeIds,
  assigneeEmployeeId,
  applicationId,
  ccDynamicAudiences,
  toFreeformEmails,
  defaultToFreeformEmails,
  ccFreeformEmails,
  defaultCcFreeformEmails,
  bccFreeformEmails,
  bccDynamicAudiences,
  defaultBccFreeformEmails,
  selectedToFreeformEmail,
  loading,
  subject,
  bodyHtml,
  fileUploadFlow,
  sendError,
  templateTypes,
  selectedTemplateId,
  useDialogPage,
  goBack,
  onClose,
  onToChanged,
  onCcChanged,
  onBccChanged,
  onChangeSubject,
  onChangeBodyHtml,
  onChangeTemplate,
  filterCcOptions,
  filterBccOptions,
}: EmailContentProps) => {
  const classes = useStyles();
  const sxProps = useSxProps();
  const [useCc, setUseCc] = useState(
    Boolean(
      defaultCcEmployeeIds?.length ||
        ccEmployeeIds?.length ||
        defaultCcFreeformEmails?.length ||
        ccFreeformEmails?.length
    )
  );
  const [useBcc, setUseBcc] = useState(
    Boolean(
      defaultBccEmployeeIds?.length ||
        bccEmployeeIds?.length ||
        defaultBccFreeformEmails?.length ||
        bccFreeformEmails?.length
    )
  );
  const smartOptionsEnabled = useFlag('user_employee_smart_options');

  const [internalSelectedTemplate, setInternalSelectedTemplate] = useState<TemplateInterface>();

  const getSelectedTemplateId = () => {
    return selectedTemplateId || internalSelectedTemplate?.id;
  };

  useInitAttachmentsForEmailTemplate(getSelectedTemplateId(), fileUploadFlow);

  const quillRef = useReactQuillRef();

  const toEmailOptions: MenuOption[] = useMemo(() => {
    if (!toFreeformEmails) return [];
    return toFreeformEmails.map((toEmail) => ({
      id: toEmail,
      value: toEmail,
      selected: toEmail === selectedToFreeformEmail,
    }));
  }, [selectedToFreeformEmail, toFreeformEmails]);

  const handleToggleUseCc = useCallback(() => setUseCc(!useCc), [useCc]);
  const handleToggleUseBcc = useCallback(() => setUseBcc(!useBcc), [useBcc]);

  const handleTo = useCallback(
    (employees: EmployeeFragment[], freeformEmails: string[]) => {
      if (onToChanged) onToChanged(employees, freeformEmails);
    },
    [onToChanged]
  );

  const handleSmartCc = useCallback(
    ({ employees, freeformEmails, dynamicAudiences }) => {
      if (onCcChanged) onCcChanged(employees, freeformEmails, dynamicAudiences);
    },
    [onCcChanged]
  );

  const handleSmartBcc = useCallback(
    ({ employees, freeformEmails, dynamicAudiences }) => {
      if (onBccChanged) onBccChanged(employees, freeformEmails, dynamicAudiences);
    },
    [onBccChanged]
  );

  const handleCc = useCallback(
    (employees: EmployeeFragment[], freeformEmails: string[]) => {
      if (onCcChanged) onCcChanged(employees, freeformEmails, []);
    },
    [onCcChanged]
  );

  const handleBcc = useCallback(
    (employees: EmployeeFragment[], freeformEmails: string[]) => {
      if (onBccChanged) onBccChanged(employees, freeformEmails, []);
    },
    [onBccChanged]
  );

  const handleTemplateSelectEmailTemplateChange = useCallback(
    (template: TemplateFragment) => {
      if (!template || !onChangeTemplate) return;
      const templateInterface: TemplateInterface = {
        id: template.id,
        name: template.name,
        body: template.body,
        subject: template.subject,
        isOrganizationDefault: template.isOrganizationDefault,
        liveSettingsUsages: undefined,
        type: template.type,
        updatedAt: template.updatedAt,
      };
      setInternalSelectedTemplate(templateInterface);
      onChangeTemplate(templateInterface);
    },
    [onChangeTemplate]
  );

  const jsx = (
    <>
      <Stack direction="column" spacing={2}>
        {sendError && <Alert alignItems="center" status="error" title={sendError} />}

        {useEmployeePickerForTo && (
          <EmployeePickerExtended
            label={
              (!toEmployeeIds || toEmployeeIds.length === 0) && (!toFreeformEmails || toFreeformEmails.length === 0)
                ? 'Missing email'
                : 'To'
            }
            freeformEnabled
            defaultSelectedEmployeeIds={defaultToEmployeeIds}
            selectedEmployeeIds={toEmployeeIds}
            defaultFreeformEmails={defaultToFreeformEmails}
            freeformEmails={toFreeformEmails}
            onChange={handleTo}
          />
        )}

        {!useEmployeePickerForTo && (
          <Select
            title="To"
            selectedOption={toEmailOptions.find((option) => option.id === selectedToFreeformEmail)}
            options={toEmailOptions}
            disabled={!toEmailOptions.length}
            onSelect={(option) => handleTo([], [option.value || ''])}
            className={classes.to}
          />
        )}

        {!useCc && !useBcc && (
          <Stack alignItems="center">
            <Button label="CC" className={classes.cc} variant="link" onClick={handleToggleUseCc} />
            <Button label="BCC" className={classes.bcc} variant="link" onClick={handleToggleUseBcc} />
          </Stack>
        )}

        {(useCc || useBcc) && (
          <Stack direction="column" spacing={2}>
            {!useCc && <Button label="CC" className={classes.bcc} variant="link" onClick={handleToggleUseCc} />}
            {useCc && (
              <>
                {smartOptionsEnabled && (
                  <SmartEmployeeSelect
                    label="CC"
                    placeholder="Add names or emails"
                    filterDynamicAudiences={[DynamicAudience.DebriefAttendees]}
                    context={{
                      task: taskId ?? {
                        TASK_ASSIGNEE: assigneeEmployeeId ?? '',
                        TASK_CREATOR: taskCreatorId ?? '',
                        TASK_SUBSCRIBERS: subscriberEmployeeIds ?? [],
                        DEBRIEF_ATTENDEES: [],
                        INTERVIEWERS_ON_SCHEDULE: [],
                      },
                      job: jobId,
                      application: applicationId,
                      enableOptionsWithMissingData: false,
                    }}
                    candidateId={candidateId}
                    onChange={handleSmartCc}
                    selectedEmployeeIds={ccEmployeeIds}
                    selectedDynamicAudiences={ccDynamicAudiences}
                    selectedFreeformEmails={ccFreeformEmails}
                  />
                )}
                {!smartOptionsEnabled && (
                  <EmployeePickerExtended
                    label="CC"
                    freeformEnabled
                    dynamicAudiencesEnabled={false}
                    defaultSelectedEmployeeIds={defaultCcEmployeeIds}
                    selectedEmployeeIds={ccEmployeeIds}
                    defaultFreeformEmails={defaultCcFreeformEmails}
                    freeformEmails={ccFreeformEmails}
                    onChange={handleCc}
                    filterOptions={filterCcOptions}
                    placeholder={
                      (ccEmployeeIds && ccEmployeeIds.length > 0) || (ccFreeformEmails && ccFreeformEmails.length > 0)
                        ? ''
                        : 'Enter a name or an email address'
                    }
                  />
                )}
              </>
            )}

            {!useBcc && <Button label="BCC" className={classes.bcc} variant="link" onClick={handleToggleUseBcc} />}
            {useBcc && smartOptionsEnabled && (
              <SmartEmployeeSelect
                label="BCC"
                placeholder="Add names or emails"
                filterDynamicAudiences={[DynamicAudience.DebriefAttendees]}
                context={{
                  task: taskId ?? {
                    TASK_ASSIGNEE: assigneeEmployeeId ?? '',
                    TASK_CREATOR: taskCreatorId ?? '',
                    TASK_SUBSCRIBERS: subscriberEmployeeIds ?? [],
                    DEBRIEF_ATTENDEES: [],
                    INTERVIEWERS_ON_SCHEDULE: [],
                  },
                  job: jobId,
                  application: applicationId,
                  enableOptionsWithMissingData: false,
                }}
                candidateId={candidateId}
                onChange={handleSmartBcc}
                selectedEmployeeIds={bccEmployeeIds}
                selectedDynamicAudiences={bccDynamicAudiences}
                selectedFreeformEmails={bccFreeformEmails}
              />
            )}
            {useBcc && !smartOptionsEnabled && (
              <EmployeePickerExtended
                label="BCC"
                freeformEnabled
                dynamicAudiencesEnabled={false}
                defaultSelectedEmployeeIds={defaultBccEmployeeIds}
                selectedEmployeeIds={bccEmployeeIds}
                defaultFreeformEmails={defaultBccFreeformEmails}
                freeformEmails={bccFreeformEmails}
                onChange={handleBcc}
                filterOptions={filterBccOptions}
                placeholder={
                  (bccEmployeeIds && bccEmployeeIds.length > 0) || (bccFreeformEmails && bccFreeformEmails.length > 0)
                    ? ''
                    : 'Enter a name or an email address'
                }
              />
            )}
          </Stack>
        )}
      </Stack>

      {!useDialogPage && <Divider className={classes.divider} />}

      <Stack direction="column" spacing={2}>
        <TemplateSelect
          minimalUX
          useSelectButton
          onTemplateSelect={handleTemplateSelectEmailTemplateChange}
          selectedTemplateId={getSelectedTemplateId()}
          types={templateTypes}
        />
        {loading && <LinearProgress sx={{ width: '100%' }} />}

        <TextField
          fullWidth
          value={subject}
          onChange={(event) => {
            if (!event?.target) return;
            onChangeSubject(event.target.value);
          }}
          error={!subject && !loading}
          label={!subject ? 'Subject is required' : 'Subject'}
          disabled={loading}
        />
        <Editor
          readOnly={loading}
          sx={loading ? { opacity: 0.5 } : {}}
          height={480}
          value={bodyHtml}
          quillRef={quillRef}
          onChange={(newValue: string) => {
            onChangeBodyHtml(newValue);
          }}
        />
        {alerts}
        <AddAttachments flow={fileUploadFlow} />
      </Stack>
    </>
  );

  if (useDialogPage) {
    return (
      <DialogPage onClose={onClose || (() => {})}>
        <DialogContent>
          <DialogTitle title={title} subTitle={subTitle} />
          {jsx}
        </DialogContent>
        <DialogActions {...dialogActions} />
      </DialogPage>
    );
  }

  return (
    <>
      <DialogContainerHeader
        sx={noPadding ? sxProps.dialogContainerHeader : undefined}
        title={title}
        headerActions={headerActions}
        goBack={goBack}
      />
      <DialogContainerContent sx={noPadding ? sxProps.dialogContainerContent : undefined}>{jsx}</DialogContainerContent>
    </>
  );
};

export default EmailContent;
