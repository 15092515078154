import { SvgIcon, SvgIconProps } from './SvgIcon';

export const PersonIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99936 2.75C8.20443 2.75 6.74936 4.20507 6.74936 6V7C6.74936 8.79493 8.20443 10.25 9.99936 10.25C11.7943 10.25 13.2494 8.79493 13.2494 7V6C13.2494 4.20507 11.7943 2.75 9.99936 2.75ZM8.24936 6C8.24936 5.0335 9.03286 4.25 9.99936 4.25C10.9659 4.25 11.7494 5.0335 11.7494 6V7C11.7494 7.9665 10.9659 8.75 9.99936 8.75C9.03286 8.75 8.24936 7.9665 8.24936 7V6ZM4.90713 16.2313C4.8834 16.2211 4.86738 16.2091 4.85807 16.2001C5.09498 15.3753 5.72307 14.708 6.65128 14.2329C7.58767 13.7536 8.77988 13.5 9.99936 13.5C11.2188 13.5 12.411 13.7536 13.3474 14.2329C14.2756 14.708 14.9037 15.3753 15.1407 16.2001C15.1313 16.2091 15.1153 16.221 15.0916 16.2313C15.0649 16.2427 15.033 16.25 14.9982 16.25L5.00051 16.25C4.96576 16.25 4.93382 16.2428 4.90713 16.2313ZM16.5886 15.808C16.2109 14.4652 15.2212 13.5069 14.0309 12.8976C12.8435 12.2899 11.4078 12 9.99935 12C8.59094 12 7.15525 12.2899 5.96785 12.8977C4.77753 13.5069 3.78778 14.4652 3.41011 15.808C3.25745 16.3508 3.42773 16.8621 3.75186 17.2135C4.0655 17.5535 4.52229 17.75 5.00051 17.75L14.9982 17.75C15.4764 17.75 15.9332 17.5534 16.2469 17.2134C16.571 16.8621 16.7413 16.3508 16.5886 15.808Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
