import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogTaskFlagResolvedInterviewDeclined_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import logError from 'src/utils/logError';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogText from '../../Helpers/ActivityLogText';

interface Fragments {
  activityLog: ActivityLogTaskFlagResolvedInterviewDeclined_ActivityLogFragment;
}

const ActivityLogTaskFlagResolvedInterviewDeclined: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (details?.__typename !== 'ActivityLogTaskInterviewerDetails') {
    logError('ActivityLogTaskFlagResolvedInterviewDeclined: details is not ActivityLogTaskInterviewerDetails');
    return null;
  }

  const { interviewName, interviewer } = details;

  const { fullName } = interviewer;

  return (
    <ActivityLogBase
      activityLog={activityLog}
      actionLabel={
        <ActivityLogText dataTestId="activity-log-task-flag-resolved-interview-declined-resolved-an-interviewer-decline">
          resolved an interviewer decline:&nbsp;
          <ActivityLogText
            component="span"
            color="foreground"
            fontWeight={600}
            dataTestId="activity-log-task-flag-resolved-interview-declined-interviewer-name"
          >
            {fullName}
          </ActivityLogText>
          &nbsp;declined&nbsp;
          <ActivityLogText dataTestId="activity-log-task-flag-resolved-interview-declined-interview-name">
            {interviewName}
          </ActivityLogText>
        </ActivityLogText>
      }
    />
  );
};

ActivityLogTaskFlagResolvedInterviewDeclined.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskFlagResolvedInterviewDeclined_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogTaskInterviewerDetails {
          __typename

          interviewName
          interviewer {
            id
            fullName
          }
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskFlagResolvedInterviewDeclined;
