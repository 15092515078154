import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
import { getLocalTimezone, getTZAbbr } from '@modernloop/shared/datetime';

import { ActivityLogTaskCandidateRsvp_ActivityLogFragment, ResponseStatus } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import { formatToTimeZone } from 'src/utils/datetime/Conversions';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogBase from '../../Helpers/ActivityLogBase';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ActivityLogContent from '../../Helpers/ActivityLogContent';

interface Fragments {
  activityLog: ActivityLogTaskCandidateRsvp_ActivityLogFragment;
}

const ActivityLogTaskCandidateRsvp: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (!details || details.__typename !== 'ActivityLogRsvpDetails') {
    throw new Error('ActivityLogTaskCandidateRsvp: details is not ActivityLogRsvpDetails');
  }

  const { startAt, endAt, responseStatus, comment } = details;
  const actionLabel = useMemo(() => {
    const timezone = getLocalTimezone();

    const startDate = formatToTimeZone(startAt, 'MMM d, yyyy', timezone);
    const startTime = formatToTimeZone(startAt, 'h:mm a', timezone);
    const endTime = formatToTimeZone(endAt, 'h:mm a', timezone);

    const timezoneAbbr = getTZAbbr(timezone);

    switch (responseStatus) {
      case ResponseStatus.Accepted:
        return `accepted their interview on ${startDate} from ${startTime} to ${endTime} ${timezoneAbbr}`;
      case ResponseStatus.Declined:
        return `declined their interview on ${startDate} from ${startTime} to ${endTime} ${timezoneAbbr}`;
      case ResponseStatus.Tentative:
        return `responded maybe for their interview on ${startDate} from ${startTime} to ${endTime} ${timezoneAbbr}`;
      default:
        return '';
    }
  }, [endAt, responseStatus, startAt]);

  return (
    <ActivityLogBase activityLog={activityLog} responseStatus={responseStatus} actionLabel={actionLabel}>
      {comment ? <ActivityLogContent content={comment} /> : null}
    </ActivityLogBase>
  );
};

ActivityLogTaskCandidateRsvp.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogTaskCandidateRsvp_activityLog on ActivityLog {
      id
      details {
        ... on ActivityLogRsvpDetails {
          startAt
          endAt
          responseStatus
          comment
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogTaskCandidateRsvp;
