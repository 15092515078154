import React from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import { PublicError } from '@modernloop/shared/helper-components';
import { Alert, CircularProgress, Stack, Typography } from '@mui/material';

import {
  SetModuleMemberAsTrained_InterviewModuleMemberFragment,
  TrainingStatus,
  useInterviewModuleMemberUpdateMutation,
} from 'src/generated/mloop-graphql';

import { resetModuleMemberListCache } from 'src/entities/InterviewModuleMember/utils';

type Props = {
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
};

type Fragments = {
  interviewModuleMember: SetModuleMemberAsTrained_InterviewModuleMemberFragment;
};

const SetModuleMemberAsTrained: FCWithFragments<Fragments, Props> = ({
  interviewModuleMember,
  open,
  onClose,
  onUpdate,
}) => {
  const [updateMember, { loading, error }] = useInterviewModuleMemberUpdateMutation();

  const handleUpdate = async () => {
    try {
      await updateMember({
        variables: {
          input: {
            employeeId: interviewModuleMember.employeeId,
            interviewModuleId: interviewModuleMember.interviewModuleId,
            status: TrainingStatus.Trained,
          },
        },
        refetchQueries: ['InterviewModuleMemberSidepanelQuery', 'InterviewModuleMemberModuleLogSidepanel'],
        update: (cache) => {
          resetModuleMemberListCache({
            cache,
            employeeIds: [interviewModuleMember.employeeId],
            moduleId: interviewModuleMember.interviewModuleId,
          });
        },
      });
      onUpdate();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog
      cancelOptions={{ label: 'Cancel', onClick: onClose }}
      submitOptions={{
        label: 'Confirm',
        onClick: handleUpdate,
        isDisabled: loading,
        endIcon: loading ? <CircularProgress size="16px" /> : undefined,
      }}
      title="Set interviewer as fully trained?"
      open={open}
      onClose={onClose}
    >
      <Stack direction="column" spacing={2}>
        <Typography>
          This interviewer will be able to conduct an interview with this module without another trained interviewer.
          Any unfinished training requirements in their training plan will be removed. Any pending approvals will be
          disregarded.
        </Typography>
        {!!interviewModuleMember.stats?.upcoming && (
          <Alert variant="outlined" color="warning" severity="warning">
            <Typography>
              This member is scheduled for an upcoming interview as a trainee. Be sure to remove them or update any
              event details as necessary.
            </Typography>
          </Alert>
        )}
      </Stack>

      <PublicError error={error} />
    </Dialog>
  );
};

SetModuleMemberAsTrained.fragments = {
  interviewModuleMember: gql`
    fragment SetModuleMemberAsTrained_interviewModuleMember on InterviewModuleMember {
      employeeId
      interviewModuleId
      stats {
        upcoming
      }
    }
  `,
};

export default SetModuleMemberAsTrained;
