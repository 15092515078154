/* eslint-disable react/no-multi-comp */
import React from 'react';
import type { FC } from 'react';

import MaintenancePage from 'src/views-new/MaintenancePage';

import ApolloProvider from 'src/interviewer-portal/contexts/ApolloProvider';
import { interviewerRoutes, renderRoutes } from 'src/routes';

const App: FC = () => {
  return (
    <ApolloProvider>
      <MaintenancePage>
        <>{renderRoutes(interviewerRoutes)}</>
      </MaintenancePage>
    </ApolloProvider>
  );
};

export default App;
