import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import {
  FilterListInterface,
  FilterListPopover,
  FilterListPopoverProps,
  FilterListProps,
  ZeroState,
} from '@modernloop/shared/components';
import {
  Alert,
  AutocompleteChangeReason,
  AutocompleteRenderGroupParams,
  Button,
  ListSubheader,
  PaperProps as MuiPaperProps,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { has } from 'lodash';

import { SearchIcon, TriangleDownIcon } from 'src/components/icons';
import { BaseProps } from 'src/components/types';

export enum FilterSelectGroup {
  SELECTED = 'selected',
  SUGGESTED = 'suggested',
  OTHER = 'other',
}

export type FilterListSelectInterface = {
  dismiss: () => void;
};

export type Props<TData> = BaseProps & {
  options: TData[];
  values?: TData[];
  loading?: FilterListProps<TData>['loading'];
  errorMsg?: string;
  isOptionEqualToValue?: FilterListProps<TData>['isOptionEqualToValue'];
  placeholderText: FilterListProps<TData>['placeholderText'];
  filterListSelectInterfaceRef?: MutableRefObject<FilterListSelectInterface | null>;

  PaperProps?: MuiPaperProps;
  getLabel: () => string | React.ReactNode;
  getOptionLabel: FilterListProps<TData>['getOptionLabel'];
  renderOption?: FilterListProps<TData>['renderOption'];
  renderGroup?: FilterListProps<TData>['renderGroup'];
  groupBy?: FilterListProps<TData>['groupBy'];
  onChange: FilterListProps<TData>['onChange'];
  onPopoverClose?: FilterListPopoverProps<TData>['onPopoverClose'];
  onInputChange?: FilterListProps<TData>['onInputChange'];
  getChildSize?: (child: React.ReactNode) => number;
  filterOptions?: FilterListProps<TData>['filterOptions'];
  onScrollToEnd?: FilterListProps<TData>['onScrollToEnd'];
  filterSelectedOptions?: FilterListProps<TData>['filterSelectedOptions'];
  header?: React.ReactNode;
  hideClear?: boolean;
  buttonSxProps?: SxProps;
  startIcon?: JSX.Element;
  zeroState?: JSX.Element;
};

const DEFAULT_ITEM_HEIGHT = 32;
const NUMBER_OF_ITEMS = 10;
// This includes the height of search bar (40px) and 8px padding top and bottom
const SEARCH_BAR_HEIGHT_WITH_PADDING = 56;

const FilterSelect = <TData,>({
  options,
  values,
  loading,
  errorMsg,
  placeholderText,
  PaperProps,
  filterListSelectInterfaceRef,
  dataTestId,
  getLabel,
  getOptionLabel,
  isOptionEqualToValue,
  onChange,
  onPopoverClose,
  onInputChange,
  renderGroup,
  renderOption,
  groupBy,
  getChildSize,
  filterOptions,
  onScrollToEnd,
  filterSelectedOptions,
  header,
  hideClear,
  startIcon,
  zeroState,
  buttonSxProps,
}: Props<TData>): JSX.Element => {
  const [showFilterSelect, setShowFilterSelect] = useState(false);

  const jobButtonRef = useRef<HTMLButtonElement>(null);
  const filterListInterfaceRef = useRef<FilterListInterface | null>(null);

  useEffect(() => {
    if (!filterListSelectInterfaceRef) return;
    filterListSelectInterfaceRef.current = {
      dismiss: () => setShowFilterSelect(false),
    };
  }, [filterListSelectInterfaceRef]);

  const getListHeight = () => {
    if (options.length > NUMBER_OF_ITEMS) {
      return NUMBER_OF_ITEMS * DEFAULT_ITEM_HEIGHT;
    }
    return options.length * DEFAULT_ITEM_HEIGHT + SEARCH_BAR_HEIGHT_WITH_PADDING;
  };

  const handleClear = () => {
    if (!filterListInterfaceRef.current) return;
    filterListInterfaceRef.current.clearSelected();
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  const handleChange = (event: React.ChangeEvent, value: TData[], reason: AutocompleteChangeReason) => {
    if (onChange) {
      onChange(event, value, reason);
    }
  };

  const handleClose = (event, reason: 'backdropClick' | 'escapeKeyDown') => {
    setShowFilterSelect(false);

    if (onPopoverClose) onPopoverClose(event, reason);
  };

  const defaultRenderGroup = (params: AutocompleteRenderGroupParams) => [
    <ListSubheader key={params.key} component="div" disableGutters>
      <Stack alignItems="center" justifyContent="space-between" direction="row">
        <Typography
          fontWeight={600}
          variant="caption"
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: (theme) => theme.spacing(1),
            paddingTop: (theme) => theme.spacing(1.5),
          }}
        >
          {params.group === FilterSelectGroup.SUGGESTED && 'Suggestions'}
          {params.group === FilterSelectGroup.SELECTED && 'Selected'}
          {params.group === FilterSelectGroup.OTHER && 'Other'}
        </Typography>
        {!hideClear && params.group === FilterSelectGroup.SELECTED && (
          <Button
            variant="text"
            color="primary"
            onClick={handleClear}
            sx={{ pr: 1.5, ':hover': { backgroundColor: 'transparent' } }}
          >
            <Typography variant="subtitle2">Clear all</Typography>
          </Button>
        )}
      </Stack>
    </ListSubheader>,
    params.children,
  ];

  const label = getLabel();

  const defaultIsOptionEqualToValue = (option: TData, value: TData) => {
    if (!option || !value) return false;
    if (
      (typeof option === 'string' && typeof value === 'string') ||
      (typeof option === 'number' && typeof value === 'number') ||
      (typeof option === 'boolean' && typeof value === 'boolean')
    ) {
      return option === value;
    }

    if (typeof option === 'object' && typeof value === 'object' && has(option, 'id') && has(value, 'id')) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      return option?.['id'] === value?.['id'];
    }

    return false;
  };

  return (
    <FilterListPopover
      dataTestId={dataTestId}
      filterSelectedOptions={filterSelectedOptions}
      autoFocus
      open={showFilterSelect}
      PaperProps={PaperProps || { style: { width: 400 } }}
      placeholderText={placeholderText}
      loading={loading}
      options={options}
      values={values}
      noOptionsText={
        zeroState || (
          <ZeroState
            label="No results found"
            icon={<SearchIcon color="mid-contrast-grey" fontSize={80} />}
            actions={<Button onClick={handleClear}>Clear search</Button>}
          />
        )
      }
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      renderGroup={renderGroup || defaultRenderGroup}
      renderOption={renderOption}
      isOptionEqualToValue={isOptionEqualToValue || defaultIsOptionEqualToValue}
      filterListInterfaceRef={filterListInterfaceRef}
      listChildren={
        <>
          {header}
          {errorMsg && <Alert severity="error" title={errorMsg} />}
        </>
      }
      onChange={handleChange}
      onPopoverClose={handleClose}
      getChildSize={getChildSize || (() => DEFAULT_ITEM_HEIGHT)}
      getListHeight={getListHeight}
      onInputChange={onInputChange}
      filterOptions={filterOptions}
      onScrollToEnd={onScrollToEnd}
    >
      {React.isValidElement(label) &&
        React.cloneElement(label as JSX.Element, {
          onClick: () => setShowFilterSelect(true),
          ref: jobButtonRef,
        })}
      {!React.isValidElement(label) && (
        <Button
          size="small"
          startIcon={startIcon}
          endIcon={<TriangleDownIcon />}
          ref={jobButtonRef}
          onClick={() => setShowFilterSelect(true)}
          color={values?.length ? 'info' : undefined}
          data-testid="filter-select-button"
          sx={buttonSxProps}
        >
          {label}
        </Button>
      )}
    </FilterListPopover>
  );
};

// eslint-disable-next-line max-params
export function defaultGetLabel<TData>(selectedValues: TData[], label, pluralLabel?: string) {
  if (selectedValues.length) {
    return selectedValues.length === 1
      ? `${selectedValues.length} ${label}`
      : `${selectedValues.length} ${pluralLabel || `${label}s`}`;
  }
  return label;
}

export default FilterSelect;
