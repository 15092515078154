import React from 'react';

import BaseIcon, { Props } from './Icon';

const TrashIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.94187 4.25C9.25836 4.25 8.61191 4.5607 8.18492 5.09444L8.06047 5.25H11.9395L11.8151 5.09444C11.3881 4.5607 10.7416 4.25 10.0581 4.25H9.94187ZM13.8605 5.25L12.9864 4.15739C12.2747 3.26784 11.1973 2.75 10.0581 2.75H9.94187C8.80269 2.75 7.72526 3.26784 7.01362 4.15739L6.13953 5.25H5.00002H4C3.58579 5.25 3.25 5.58579 3.25 6C3.25 6.41421 3.58579 6.75 4 6.75H4.31805L5.08098 14.7607C5.2154 16.1721 6.40081 17.25 7.81859 17.25H12.1814C13.5992 17.25 14.7846 16.1721 14.919 14.7607L15.682 6.75H16C16.4142 6.75 16.75 6.41421 16.75 6C16.75 5.58579 16.4142 5.25 16 5.25H15H13.8605ZM13.494 6.75H13.5069H14.1752L13.4258 14.6185C13.3647 15.2601 12.8259 15.75 12.1814 15.75H7.81859C7.17415 15.75 6.63532 15.2601 6.57422 14.6185L5.82484 6.75H6.49307H6.50597H13.494ZM12.5303 8.46967C12.8232 8.76256 12.8232 9.23744 12.5303 9.53033L11.0607 11L12.5303 12.4697C12.8232 12.7626 12.8232 13.2374 12.5303 13.5303C12.2374 13.8232 11.7626 13.8232 11.4697 13.5303L10 12.0607L8.53033 13.5303C8.23744 13.8232 7.76256 13.8232 7.46967 13.5303C7.17678 13.2374 7.17678 12.7626 7.46967 12.4697L8.93934 11L7.46967 9.53033C7.17678 9.23744 7.17678 8.76256 7.46967 8.46967C7.76256 8.17678 8.23744 8.17678 8.53033 8.46967L10 9.93934L11.4697 8.46967C11.7626 8.17678 12.2374 8.17678 12.5303 8.46967Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default TrashIcon;
