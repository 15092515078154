import React, { FC } from 'react';
import { Redirect } from 'react-router';

import { gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';

import { Permission, useGetUserPermissionsQuery } from 'src/generated/mloop-graphql';

import LoadingScreen from 'src/shared/components/base/screenLoaders/LoadingScreen';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PERMISSIONS_GQL = gql`
  query GetUserPermissions {
    thisUserInfo {
      permissions
    }
  }
`;

/**
 * Guard that checks if the user has the permission to schedule interviews.
 * Note: Guards should check only one thing. Don't add any other checks to this Guard.
 */
const RPUserHasSchedulerPermissionGuard: FC = ({ children }) => {
  const interviewerPortalOn = useFlag('org_interviewer_portal');
  const { data, loading } = useGetUserPermissionsQuery();

  const canSchedule = data?.thisUserInfo?.permissions?.includes(Permission.CanSchedule);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!canSchedule && interviewerPortalOn) {
    return <Redirect to="/ip" />;
  }

  // Don't add components here, please add components either on the app or on the appropriate Layout component
  return <>{children}</>;
};

export default RPUserHasSchedulerPermissionGuard;
