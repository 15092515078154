import React, { FC } from 'react';

import { useFlag } from '@modernloop/shared/feature-flag';
import { Box, Stack, Typography } from '@mui/material';

import { InterviewMeetingLocationType } from 'src/generated/mloop-graphql';

import Checkbox from 'src/components/checkbox';

import { InterviewerEventContent, MeetingLinksConfig } from 'src/store/slices/schedule-communications';

import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';

import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';

import CustomLinkSingle from './CustomLinkSingle';
import CustomLinksMulti from './CustomLinksMulti';

interface Props {
  title: string;
  scheduleFlow: ScheduleFlowType;
  schedule: InterviewSchedule | null;
  interviewerEventContents: InterviewerEventContent[] | undefined;
  location: InterviewMeetingLocationType;
  customMeetingLinkConfig?: MeetingLinksConfig;
  customVideoMeetingURL?: string | null;
  handleCustomMeetingLinkConfigChange?: (config: MeetingLinksConfig) => void;
}

const CustomLinksSection: FC<Props> = ({
  title,
  scheduleFlow,
  interviewerEventContents,
  location,
  customMeetingLinkConfig,
  customVideoMeetingURL,
  handleCustomMeetingLinkConfigChange,
  schedule,
}) => {
  const scheduleLocationEnabled = useFlag('org_schedule_location');

  return (
    <Stack mt={2} spacing={0.5}>
      <Box mb={1}>
        {!scheduleLocationEnabled &&
          scheduleFlow !== ScheduleFlowType.DEBRIEF &&
          interviewerEventContents &&
          interviewerEventContents?.length > 1 && (
            <Checkbox
              checked={customMeetingLinkConfig === MeetingLinksConfig.Many}
              label="Use unique links for each interview"
              onChange={(e) => {
                if (e.target.checked) {
                  if (handleCustomMeetingLinkConfigChange) handleCustomMeetingLinkConfigChange(MeetingLinksConfig.Many);
                } else if (handleCustomMeetingLinkConfigChange)
                  handleCustomMeetingLinkConfigChange(MeetingLinksConfig.One);
              }}
            />
          )}
      </Box>

      {scheduleLocationEnabled && <Typography variant="subtitle2">{title}</Typography>}

      {customMeetingLinkConfig === MeetingLinksConfig.One || scheduleFlow === ScheduleFlowType.DEBRIEF ? (
        <CustomLinkSingle
          scheduleFlow={scheduleFlow}
          customVideoMeetingURL={customVideoMeetingURL}
          schedule={schedule}
          location={location}
        />
      ) : null}
      {customMeetingLinkConfig === MeetingLinksConfig.Many && scheduleFlow !== ScheduleFlowType.DEBRIEF ? (
        <CustomLinksMulti scheduleFlow={scheduleFlow} location={location} />
      ) : null}
    </Stack>
  );
};

export default CustomLinksSection;
