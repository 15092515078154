import { SvgIcon, SvgIconProps } from './SvgIcon';

export const TextBubbleIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.75C4.99594 1.75 1.75 4.99594 1.75 9C1.75 13.0041 4.99594 16.25 9 16.25C10.0446 16.25 11.0357 16.0296 11.9309 15.6334C12.0594 15.5765 12.2013 15.5569 12.3405 15.5767L15.7861 16.069C15.9511 16.0925 16.0925 15.9511 16.069 15.7861L15.5767 12.3405C15.5569 12.2013 15.5765 12.0594 15.6334 11.9309C16.0296 11.0357 16.25 10.0446 16.25 9C16.25 4.99594 13.0041 1.75 9 1.75ZM0.25 9C0.25 4.16751 4.16751 0.25 9 0.25C13.8325 0.25 17.75 4.16751 17.75 9C17.75 10.18 17.516 11.3071 17.0913 12.3361L17.5539 15.574C17.7189 16.7289 16.7289 17.7189 15.574 17.5539L12.3361 17.0913C11.3071 17.516 10.18 17.75 9 17.75C4.16751 17.75 0.25 13.8325 0.25 9ZM5.25 7C5.25 6.58579 5.58579 6.25 6 6.25H12C12.4142 6.25 12.75 6.58579 12.75 7C12.75 7.41421 12.4142 7.75 12 7.75H6C5.58579 7.75 5.25 7.41421 5.25 7ZM6 10.25C5.58579 10.25 5.25 10.5858 5.25 11C5.25 11.4142 5.58579 11.75 6 11.75H9C9.41421 11.75 9.75 11.4142 9.75 11C9.75 10.5858 9.41421 10.25 9 10.25H6Z"
      />
    </SvgIcon>
  );
};
