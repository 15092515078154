export enum OmniSearchOptionType {
  Candidate = 'CANDIDATE',
  Categories = 'CATEGORIES',
  Interviewers = 'INTERVIEWERS',
  InterviewModule = 'INTERVIEW_MODULE',
  Job = 'JOB',
  OtherPages = 'OTHER_PAGES',
}

export type OmniSearchOption = {
  type: OmniSearchOptionType;
  key: string;
  searchLabel: string;
  disabled?: boolean;
  onSelect: () => void;
  render: () => React.ReactNode;
};

export type OmniSearchOptionsList = {
  loading: boolean;
  options: OmniSearchOption[];
  hasMore: boolean;
};

export enum OmniSearchActionType {
  Hide = 'hide',
  Show = 'show',
  IsInExtension = 'is-in-extension',
}

export type HideOmniSearchAction = {
  type: OmniSearchActionType.Hide;
};

export type ShowOmniSearchAction = {
  type: OmniSearchActionType.Show;
};

export type IsInExtensionOmniSearchAction = {
  type: OmniSearchActionType.IsInExtension;
  isInExtension: boolean;
};

export type OmniSearchAction = HideOmniSearchAction | ShowOmniSearchAction | IsInExtensionOmniSearchAction;
