import { SvgIcon, SvgIconProps } from './SvgIcon';

export const PreferencesIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 5C12.75 4.30964 13.3096 3.75 14 3.75C14.6904 3.75 15.25 4.30964 15.25 5C15.25 5.69036 14.6904 6.25 14 6.25C13.3096 6.25 12.75 5.69036 12.75 5ZM11.3535 5.75C11.68 6.90425 12.7412 7.75 14 7.75C15.5188 7.75 16.75 6.51878 16.75 5C16.75 3.48122 15.5188 2.25 14 2.25C12.7412 2.25 11.68 3.09575 11.3535 4.25H4C3.58579 4.25 3.25 4.58579 3.25 5C3.25 5.41421 3.58579 5.75 4 5.75H11.3535ZM7.25 10C7.25 9.30964 6.69036 8.75 6 8.75C5.30964 8.75 4.75 9.30964 4.75 10C4.75 10.6904 5.30964 11.25 6 11.25C6.69036 11.25 7.25 10.6904 7.25 10ZM16 9.25H8.64648C8.32002 8.09575 7.25878 7.25 6 7.25C4.48122 7.25 3.25 8.48122 3.25 10C3.25 11.5188 4.48122 12.75 6 12.75C7.25878 12.75 8.32002 11.9043 8.64648 10.75H16C16.4142 10.75 16.75 10.4142 16.75 10C16.75 9.58579 16.4142 9.25 16 9.25ZM3.25 15C3.25 14.5858 3.58579 14.25 4 14.25H11.3535C11.68 13.0957 12.7412 12.25 14 12.25C15.5188 12.25 16.75 13.4812 16.75 15C16.75 16.5188 15.5188 17.75 14 17.75C12.7412 17.75 11.68 16.9043 11.3535 15.75H4C3.58579 15.75 3.25 15.4142 3.25 15ZM14 16.25C13.3096 16.25 12.75 15.6904 12.75 15C12.75 14.3096 13.3096 13.75 14 13.75C14.6904 13.75 15.25 14.3096 15.25 15C15.25 15.6904 14.6904 16.25 14 16.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
