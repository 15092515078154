function cleanText(text: string) {
  return text
    .replace(/<p><br><\/p>/gi, '')
    .replace(/<br>/gi, '')
    .replace(/(\r\n|\n|\r|\\n)/gm, '')
    .replace(/>\s+</gm, '');
}

function removeImage(text: string): string {
  return text.replace(/<img[^>]*>/g, '');
}

export function plainText(text: string): string {
  if (!text) return '';
  return cleanText(removeImage(text))
    .replace(/(<(br[^>]*)>)/gi, '\n\n')
    .replace(/(<(li[^>]*)>)/gi, '\n\n')
    .replace(/(<(p[^>]*)>)/gi, '\n\n')
    .replace(/<(.|\n)*?>/g, '')
    .replace(/\n\n\n\n/gi, '')
    .replace(/\n\n\n/gi, '')
    .replace(/&nbsp;/g, ' ');
}

export const snakeCaseToInitCase = (text: string): string => {
  if (!text) return text;
  return text
    .replaceAll('_', ' ')
    .toLowerCase()
    .split(' ')
    .map((name) => (name && name.length > 1 ? name[0].toUpperCase() + name.slice(1) : name))
    .join(' ');
};

export const snakeCaseToCapitalize = (text: string): string => {
  if (!text) return text;
  const sanitizedText = text.replaceAll('_', ' ').toLowerCase();
  return sanitizedText.toLowerCase().charAt(0).toUpperCase() + sanitizedText.slice(1);
};
