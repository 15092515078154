import { lightThemeOptions } from './light';
import { createTheme as createMuiTheme, responsiveFontSizes, ThemeOptions, Theme } from '@mui/material';
import { merge } from 'lodash';

const baseOptions: ThemeOptions = {
  direction: 'ltr',
};

export const createTheme = (themeOption: ThemeOptions = lightThemeOptions): Theme => {
  let theme = createMuiTheme(merge({}, baseOptions, themeOption));
  theme = responsiveFontSizes(theme);
  return theme;
};
