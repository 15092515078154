/**
 * Boolean based feature flags.
 *
 * Append new Boolean based flags here with a date and a description. The dates
 * help us audit the feature flags for clean-up.
 *
 * https://docs.launchdarkly.com/home/getting-started/feature-flags
 */

export const BOOL_FEATURE_FLAGS = [
  // PERMENANT FLAGS - Internal only flag, these are for feature you only want to ship to
  // ModernLoop orgs for testing purposes. Ex. 1-min interview duration.
  'MLOnly',

  // Whether or not to show our Health check banner
  'health_check_banner',
  // Whether or not to hide cancelled tasks. This is used for AE demos.
  'user_hide_cancelled_tasks',

  // Lets user connect user from ats to a user in ModernLoop.
  // Primarily for Lyra.
  'user_ats_user_select',

  // 2023-05-22 (Cam) - Gate whether or not to skip adding hiring managers to the slack channel by default
  // This is a special flag for Brex and should move to org pref long-term.
  'user_skip_adding_slack_hiring_managers',

  // 2023-10-05 (Cam) - Flag that shows the old debrief section and allows create without tasks
  'user_allow_non_task_debriefs',

  // END PERMENANT FLAGS

  // 2022-08 - Enables suffable breaks in our interview plan UI
  // THis is only on for ModernLoop, can we remove?
  'org-shuffle-able-break',

  // 2022-09 - Flag for gating TinyMce
  // Let's clean this up and add back later
  'tiny_mce_editor',

  // 2023-02-06 - Make end time not dependent on start time in time range picker.
  // Only on for Lyra.
  'user_time_range_picker_dont_coalesce_end_time',

  // 2023-02-06 - New Google workspace app settings
  // Where are we at with this?
  'user_google_workspace_app_testing',

  // 2023-02-14 - Use PhoneNumberInput on Account page
  'user_new_phone_number_input',

  // 2023-03-29 - Flag to gate the Custom {{INTERVIEW_SCHEDULE}} template in Org settings Templates page.
  // This is in cold-storage essentially.
  'user_custom_interview_schedule_template',

  // 2023-11-08 (Gaurav) - Use employee pref for recently used zoom users
  // Not released - let's push this
  'user_migrate_recent_zoom_users_to_employee_pref',

  // 2023-12-12 (hemant) - Flag to show a blocking screen to user if we do not have gmail.send permission
  // as part of delegated auth or Workspace app and user has not gone through OAuth flow
  // Not released at all, what's the plan?
  'google-workspace-app-user-auth-block',

  // 2023-12-12 (Gaurav) - Flag that retains search string after selecting an option
  // Not released at all, what's the plan?
  'user_provide_input_value_to_filterselect',

  // 2023-12-20`(Gaurav) - Flag to move recently used tz to employee pref
  // Not released, what's the plan?
  'user_move_recently_used_timezones_to_employee_pref',

  // 2023-12-20`(Gaurav) - Flag to move do not show states to employee pref
  // Released Feb 8th
  'user_move_do_not_show_states_to_employee_pref',

  // 2024-01-31 (Gaurav) - Flag that shows suggested availability in candidate portal
  'user_last_used_template_ids_employee_pref',

  // 2024-03-08 (Yaron) - SAML and SCIM Self Serve
  'org_saml_scim_self_serve',

  // 2024-03-15 (Cam) - Sourcing Links
  'user_sourcing_links',

  // 2024-04-08 (Ashutosh) - Enable GEM integration
  'user_gem_integration',

  // 2024-04-10 (Ashutosh) - Fats track Interview module training
  'user_fast_track_interview_module',

  // 2024-04-09 (Naveen) - Fast track interview module
  'user_fast_track_interview_module',

  // 2024-05-07 (Cam) - Flag to completely ignore the rolling window setting and default to true
  'user_ignore_rolling_window_setting',

  //2024-05-07 (Gaurav) - Import job setup from another job
  'org_import_job_setup',

  // 2024-05-10 (Larry) - Allow schedulers to set custom event invite on self schedule requests
  'user_custom_self_schedule_event_template',

  // 2024-05-15 (Larry) - Use new ATS disconnect dialog
  'user_new_ats_disconnect_modal',

  // 2o24-05-17 (Cam) - New interviewer portal
  'org_interviewer_portal',

  // 2024-05-22 (hemant) - Adding support for Microsoft GCC High
  'org_microsoft_gcc_high',

  // 2024-05-28 (hemant) - Using the new InterviewPlan entity in tasks details, create task via availability request, self-schedule request and request a team member to schdule flow
  'interview_plan_entity_in_tasks',

  // 2024-05-31 (Larry) - Allow admins to set minimum requirements for load balancing
  'org_interviewer_minimum_requirements',

  // 2024-05-06 (Ashutosh) - Enable workday integration
  'user_workday_integration',

  // 2024-06-12 (Vivek)
  'org_auto_replace_declined_interviewers',

  // 2024-06-18 (Gaurav)
  'org_use_gql_api_to_create_schedule',

  // 2024-06-12 (hemant) - Using InterviewPlan entity component in schedule flows (schedule, update and reschedule)
  'interview_plan_entity_in_schedule_flows',

  // 2024-06-17 (hemant) - Allowing users to add a new interview to an completed schedule.
  'org_add_interview_to_completed_schedule',

  // 2024-07-01 (gaurav) -  Org pref for storing start time interval
  'org_setting_for_start_times',

  // 2024-07-04 (hemant) - Reset to Stage Plan on Interview Plan object.
  'user_reset_to_stage_plan',

  // 2023-07-11 (Naveen) - On the Candidate Details page, update the look of the sidebar to use the new accordions
  'update_candidate_details_page_to_accordion',

  // 2024-07-12 (cam) - Allows individual interviewers to set their own keywords
  'org_interviewer_keywords',

  // 2024-07-10 (hemant) - Interview plan break improvements
  'org_break_improvements',

  // 2024-07-19 (gaurav) - Show/use default task comment
  'org_default_task_comment',

  // 2024-07-24 (cam) - Show timezone picker as a filterselect
  'user_timezone_filter_select',

  // 2024-07-25 (larry) - Use external interviewer profile
  'org_external_interviewer_profiles',

  // 2024-07-29 (Naveen) - New org settings sidebar
  'user_new_org_settings_sidebar',

  //2024-07-30 ( Ashutosh ) - PRD-112
  'org_use_application_posting_job_name',

  // 2024-07-28 (hemant) - Schedule location org and job stage settings.
  'org_schedule_location',

  // 2024-08-01 (gaurav) - Show internal interviewer note
  'user-internal-interviewer-note',

  // 2024-08-01 (cam) - Whether or not we block self schedule flow if there is an unknown calendar
  'user_should_block_unknown_calendars',

  // 2024-07-31 (Ashutosh) - Enable PRD-110 Conflict of interest feature
  'user_conflict_off_interest',

  // 2024-08-05 (Ashutosh) - Enable delet all system attributes values
  'user_system_attributes_all_values_delete',

  // 2024-08-12 (Naveen) - Employee smart options
  'user_employee_smart_options',

  // 2024-08-07 (Kalpak) - Enable new Greenhouse dialog
  'user_new_greenhouse_modal',
] as const;

export type FeatureFlagBool = typeof BOOL_FEATURE_FLAGS[number];
