import {
  Employee,
  EventsWithConflicts,
  GetTextForFlag_CompanyHolidayFragment,
  InterviewLimit,
  InterviewType,
  InterviewerFlag,
  InterviewerRole,
  ResponseStatus,
} from 'src/generated/mloop-graphql';

import { apiPost } from 'src/utils/api';

import ApiVerb from './ApiVerb';

export type InterviewerResponseStatus = {
  employeeId: string;
  startAt: string;
  endAt: string;
  responseStatus: ResponseStatus;
};

export type UIInterviewSlot = {
  id?: string;
  interviewType?: InterviewType;
  customDuration?: number;
  seats?: UIInterviewSeat[];
  name?: string;
  locked?: boolean;
  forcedStartAt?: string;
  isHiddenFromCandidate?: boolean;
};

export type UICandidateAvailability = {
  startAt: string;
  endAt: string;
};

export type UIHardConflict = {
  startAt?: string;
  endAt?: string;
  organizerEmail?: string;
  eventUid?: string;
};

export interface UIInterviewGroup {
  id: string;
  locked: boolean;
  interviewSlotIds: string[];
}

export interface RequestBody {
  applicationId?: string; // TODO after user_conflict_off_interest release this will be required
  jobStageId?: string;
  interviewSlots?: UIInterviewSlot[];
  interviewGroups?: UIInterviewGroup[];
  candidateAvailabilities?: UICandidateAvailability[];
  candidateTimezone?: string;
  excludeInterviewerIds?: string[];
  hasBreaks?: boolean;
  hasMultiDay?: boolean;
  hardConflicts?: UIHardConflict[];
  throwOnZero?: boolean;
  numberOfDays?: number;
  // sort_order?: SortOrder; // We do not provide option to sort in UI, uncomment when we do.
  interviewersResponseStatus?: InterviewerResponseStatus[];
  avoidAligningStartTime?: boolean;
  // requestType?: RequestType; // Not used in current call to getScheduleOptions
}

export type InterviewerLoadAndLimit = {
  employeeId?: string;
  dailyLoad?: number;
  weeklyLoad?: number;
  lastWeeklyLoad?: number;
  dailyLoadMinutes?: number;
  weeklyLoadMinutes?: number;
  lastWeeklyLoadMinutes?: number;
  dailyInterviewLimit?: InterviewLimit;
  weeklyInterviewLimit?: InterviewLimit;
};

export type RichInterviewer = {
  employeeId: string;
  role?: InterviewerRole;
  loadAndLimit?: InterviewerLoadAndLimit;
  flags?: InterviewerFlag[];
  eventsWithConflicts?: EventsWithConflicts[];
  companyHolidays?: GetTextForFlag_CompanyHolidayFragment[];

  /**
   * Extra fields not returned by getScheduleOptions api.
   */
  // Populated from response of getScheduleOptions
  employee: Employee;

  // Used in update schedule flow.
  responseStatus?: ResponseStatus;
  interviewId?: string;

  // This is only used on UI
  isOptional?: boolean;

  /** End extra fields */
};

type UIInterviewer = {
  id: string;
  preferenceLevel: number;
};

export type UIInterviewSeatFreeform = {
  id: string;
  interviewers: UIInterviewer[];
};

export type AttributeMap = { [key: string]: { attributeValues: string[] } };

export type UIInterviewSeatModule = {
  id: string;
  interviewId: string;

  attributeMap?: AttributeMap;
  selectedTrainedInterviewerIds?: string[];
  selectedReverseShadowInterviewerIds?: string[];
  selectedShadowInterviewerIds?: string[];
};

export type UIInterviewSeatLinked = {
  id: string;
  interviewSeatId: string;
};

export type UIInterviewSeat = {
  freeformSeat?: UIInterviewSeatFreeform;
  moduleSeat?: UIInterviewSeatModule;
  linkedSeat?: UIInterviewSeatLinked;
};

export type UIFilledInterviewSeat = {
  seat: UIInterviewSeat;
  interviewerId: string;
  traineeId?: string;
  traineeRole?: InterviewerRole;
};

export type InterviewEvent = {
  id: string;
  slotId?: string;
  interviewId?: string;
  name?: string;
  startAt: string;
  endAt: string;
  duration?: number;
  interviewers: RichInterviewer[];
  isBreak?: boolean;
  greenhouseEventId?: string;
  greenhouseCalendarId?: string;
  greenhouseScheduledInterviewId?: string;
  disabled?: boolean;
  filledInterviewSeats?: UIFilledInterviewSeat[];
  groupId?: string;
  isHiddenFromCandidate?: boolean;

  // TODO: used in update flow
  atsInterviewDefinitionId?: string;
  atsScheduledInterviewId?: string;
  atsJobId?: string;
  atsJobStageId?: string;
  googleEventId?: string;
  googleCalendarId?: string;
  jobStageInterviewId?: string;
};

export type InterviewSchedule = {
  id: string;
  jobStageId?: string;
  days?: number;
  weekends?: number;
  paused?: number;
  conflicts?: number;
  outsideRecruitingBlocks?: number;
  insideRecruitingBlocks?: number;
  outsideWorkHours?: number;
  missingCalendars?: number;
  interviewConflict?: number;
  totalDailyLoad?: number;
  totalWeeklyLoad?: number;
  totalLastWeekLoad?: number;
  overlimits?: number;
  repeats?: number;
  breaks?: number;
  hasTrainee?: boolean;
  schedulableConflict?: number;
  unschedulableConflict?: number;
  ooo?: number;
  externalConflict?: number;
  numberOfConflict?: number;
  holdConflict?: number;
  oneOnOneConflict?: number;
  events: InterviewEvent[];
  googleId?: string;
  googleUrl?: string;
  dailyOverlimits?: number;
  weeklyOverlimits?: number;
  middleOfTheNight?: number;
  outsideWorkHoursBreakTime?: number;
  outsideWorkHours30?: number;
  outsideWorkHours60?: number;
  outsideWorkHours90?: number;
  outsideWorkHours120?: number;
  weeklyOverModuleLimits?: number;
};

export interface ResponseBody {
  schedules?: InterviewSchedule[];
  count: number;
  error?: string;
  employees?: { [id: string]: Employee };
  config_override?: {
    multi_day?: boolean;
  };
}

/**
 * inline employees in the response.
 * @param response
 */
export function inlineEmployees(response: ResponseBody): ResponseBody {
  const { employees } = response;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const expandEmployee = (compactEmployee: any) => {
    const nestedEmployeeId = compactEmployee.employee_id;
    if (nestedEmployeeId) {
      compactEmployee.employee = employees ? employees[nestedEmployeeId] || {} : {};
    }
    return compactEmployee;
  };
  const schedules = (response.schedules || []).map((schedule) => {
    schedule.events = schedule.events.map((event) => {
      event.interviewers = (event?.interviewers || []).map(expandEmployee);
      // event.shadow_interviewers = (event?.shadow_interviewers || []).map(expandEmployee);
      // event.reverse_shadow_interviewers = (event?.reverse_shadow_interviewers || []).map(expandEmployee);
      return event;
    });
    return schedule;
  });
  return { ...response, schedules, count: schedules.length };
}

const getScheduleOptions = async (data: RequestBody): Promise<ResponseBody> => {
  const response = await apiPost(ApiVerb.GET_SCHEDULE_OPTIONS, data);
  return inlineEmployees(response.data);
};

export default getScheduleOptions;
