import React from 'react';

import { gql } from '@apollo/client';

import { ActivityLogAvatar_ActivityLogFragment, ActorType, ResponseStatus } from 'src/generated/mloop-graphql';

import { CandidateIconFilled, ModernLoopLogoIcon } from 'src/components/icons';
import { FCWithFragments } from 'src/components/types';

import AvatarWithRsvp from 'src/entities/common/AvatarWithRsvp';
import IconWithRsvp from 'src/entities/common/IconWithRsvp';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface ActivityLogAvatarProps {
  actorName: string;
  responseStatus: ResponseStatus | null | undefined;
}

interface Fragment {
  activityLog: ActivityLogAvatar_ActivityLogFragment;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const ActivityLogAvatar: FCWithFragments<Fragment, ActivityLogAvatarProps> = ({
  activityLog,
  actorName,
  responseStatus,
}) => {
  const { activityActorType, actorEmployee } = activityLog;

  switch (activityActorType) {
    case ActorType.Employee:
      return (
        <AvatarWithRsvp
          size="20px"
          dataTestId={`activity-log-base-avatar-rsvp-${actorName}-${responseStatus}`}
          alt={actorName}
          responseStatus={responseStatus}
          src={actorEmployee?.slackImageUrl ?? actorEmployee?.fullName ?? undefined}
        />
      );
    case ActorType.System:
      return <ModernLoopLogoIcon fontSize={20} dataTestId={`activity-log-base-avatar-rsvp-${actorName}`} />;
    case ActorType.Candidate:
      return (
        <IconWithRsvp
          icon={<CandidateIconFilled fontSize={20} dataTestId="activity-log-base-candidate-icon-filled" />}
          responseStatus={responseStatus}
          interviewerRsvpIconDataTestId={`activity-log-base-candidate-rsvp-icon-${responseStatus}`}
        />
      );
    default:
      return null;
  }
};

ActivityLogAvatar.fragments = {
  activityLog: gql`
    fragment ActivityLogAvatar_activityLog on ActivityLog {
      id
      activityActorType
      actorEmployee {
        id
        fullName
        slackImageUrl
      }
    }
  `,
};

export default ActivityLogAvatar;
