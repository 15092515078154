import useOrgSlug from 'src/hooks/useOrgSlug';

import getCandidatePortalApplicationUrl from '../getCandidatePortalApplicationUrl';
import getCandidateSchedulingUrl from '../getCandidateSchedulingUrl';

export const useCandidatePortalUrl = (
  applicationId: string,
  options?: {
    taskId?: string;
  }
) => {
  const { orgSlug, loading, ...rest } = useOrgSlug();
  let candidatePortalUrl = '';

  if (loading || !orgSlug) return { candidatePortalUrl: '', loading, ...rest };

  candidatePortalUrl = getCandidatePortalApplicationUrl({ orgSlug, applicationId });

  if (options?.taskId) {
    candidatePortalUrl = getCandidateSchedulingUrl({ orgSlug, applicationId, taskId: options.taskId });
  }

  return { candidatePortalUrl, loading, ...rest };
};
