import { ThemeOptions } from '@mui/material';

/**
 * View BottomNavigation props and classes at https://mui.com/material-ui/api/bottom-navigation-action/#css
 */
export const bottomNavigation: ThemeOptions['components'] = {
  MuiBottomNavigation: {
    defaultProps: {
      showLabels: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        position: 'fixed',
        height: '62px',
        left: '50%',
        backgroundColor: 'transparent',
        transform: 'translateX(-50%)',
        [theme.breakpoints.down('sm')]: {
          bottom: 0,
          width: 'inherit',
          height: '54px',
          borderTop: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.navigation,
          backdropFilter: 'blur(4px)',
        },
      }),
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        width: '90px',
      },
      label: ({ theme }) => ({
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
      }),
      selected: ({ theme }) => ({
        color: theme.palette.primary.main,
        '& svg': {
          color: theme.palette.primary.main,
        },
      }),
    },
  },
};
