import { MutableRefObject, useCallback } from 'react';
import ReactQuill from 'react-quill';

export type ReactQuillProps = ReactQuill['props'];
const useQuillEditorModules = (
  quillRef: MutableRefObject<ReactQuill | null> | undefined
): ReactQuillProps['modules'] => {
  const imageHandler = useCallback(() => {
    if (!quillRef || !quillRef.current) return;
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    if (!range) return;
    const value = prompt('Please copy and paste the image url here.');
    if (value) {
      quill.insertEmbed(range.index, 'image', value, 'user');
    }
  }, [quillRef]);

  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'], // toggled buttons

        /* [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }], */ // font size and type
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        /* [{ 'indent': '-1' }, { 'indent': '+1' }],       */ // outdent/indent

        ['link', 'image'],
        ['clean'], // remove formatting button
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  return modules;
};

export default useQuillEditorModules;
