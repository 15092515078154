import React, { CSSProperties } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';

import Stack from 'src/components/Stack';

import { Theme } from 'src/theme/type';

import OmniSearch from 'src/views-new/OmniSearch';
import OmniSearchProvider from 'src/views-new/OmniSearch/OmniSearchProvider';
import SidePanel from 'src/views-new/sidepanel';

import AppNav from './components/AppNav';
import Banners from './components/banners';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.default,
    },
  })
);

const DashboardLayout: React.FC = ({ children }): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme() as Theme;

  const itemStyles: { [key: number]: CSSProperties } = {
    0: { flexShrink: 0, flexWrap: 'nowrap', height: '100%', overflowX: 'hidden' },
    1: {
      boxShadow: theme.shadows[3],
      flexGrow: 1,
      overflowX: 'hidden',
      overflowY: 'auto',
      backgroundColor: theme.palette.common.white,
      width: '100%',
      height: '100%',
    },
  };

  return (
    <OmniSearchProvider>
      <Stack className={classes.root} itemStyles={itemStyles} wrap="nowrap">
        <AppNav />
        <Grid container wrap="nowrap" direction="column" style={{ height: '100%' }}>
          <Banners />
          <Stack style={{ height: '100%' }} itemStyles={{ 0: { width: '100%' } }}>
            {children}
          </Stack>
        </Grid>
      </Stack>
      <SidePanel />
      <OmniSearch />
    </OmniSearchProvider>
  );
};

export default DashboardLayout;
