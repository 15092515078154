import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { isWithinInterval } from '@modernloop/shared/datetime';

import {
  GetCandidateEventByInterviewId_CandidateEventsFragment,
  GetCandidateEventByInterviewId_InterviewsFragment,
} from 'src/generated/mloop-graphql';

type Fragments = {
  interviews: GetCandidateEventByInterviewId_InterviewsFragment[];
  candidateEvents: GetCandidateEventByInterviewId_CandidateEventsFragment[];
};

type CandidateEventByInterviewId = {
  [interviewEventId: string]: string;
};

const getCandidateEventByInterviewId: FunctionWithFragments<Fragments, void, CandidateEventByInterviewId> = ({
  interviews,
  candidateEvents,
}) => {
  const interviewsByCandidateEventId: CandidateEventByInterviewId = {};

  interviews.forEach((interview) => {
    const candidateEvent = candidateEvents.find(
      (event) =>
        isWithinInterval(interview.startAt, { start: event.startAt, end: event.endAt }) &&
        isWithinInterval(interview.endAt, { start: event.startAt, end: event.endAt })
    );

    if (candidateEvent) {
      interviewsByCandidateEventId[interview.id] = candidateEvent.id;
    }
  });

  return interviewsByCandidateEventId;
};

getCandidateEventByInterviewId.fragments = {
  interviews: gql`
    fragment getCandidateEventByInterviewId_interviews on ApplicationStageInterview {
      id
      startAt
      endAt
    }
  `,
  candidateEvents: gql`
    fragment getCandidateEventByInterviewId_candidateEvents on ApplicationStageCandidateEvent {
      id
      startAt
      endAt
    }
  `,
};

export default getCandidateEventByInterviewId;
