import React, { FC } from 'react';

import { Dialog as MUIDialog } from '@mui/material';

import { BaseProps } from 'src/components/types';

import DialogActions, { DialogActionsProps } from './DialogActions';
import DialogContent from './DialogContent';
import DialogMoreMenu, { DialogMoreMenuProps } from './DialogMoreMenu';
import DialogTitle, { DialogTitleProps } from './DialogTitle';
import useFullScreen from './useFullScreen';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type DialogProps = BaseProps &
  DialogActionsProps &
  DialogMoreMenuProps &
  DialogTitleProps & {
    /**
     * Whether the dialog is open or not
     */
    isOpen: boolean;

    /**
     * Callback when the dialog is closed from the top right X icon
     */
    onClose: () => void;

    /**
     * The content of the dialog
     * Can be a string or a JSX element
     * If it's a string, it will be rendered as a Label
     */
    children: React.ReactNode | React.ReactNode[];

    /**
     * The size of the dialog
     * Defaults to 'sm'
     * 'sm' is 484px wide
     * 'md' is 604px wide
     */
    size?: 'sm' | 'md';
  };

/**
 *
 * @deprecated
 * Use directly from mloop-shared
 *
 * ModernLoop dialog compoenent that renders main content
 * with sticky top right header actions and a sticky bottom actions
 *
 * This dialog will automatically go full screen on mobile and screen sizes less than 484px
 *
 * This component is meant to be more rigid than the MUI Dialog component so
 * it's easier to quickly build dialogs with the same look and feel
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const Dialog: FC<DialogProps> = ({
  isOpen,
  onClose,
  submitOptions,
  secondaryOptions,
  cancelOptions,
  actionButtons,
  decoration,
  title,
  subTitle,
  size = 'sm',
  children,
  dataTestId,
}): JSX.Element => {
  const isFullScreen = useFullScreen();

  // Extra paper styles when in full screen mode
  const fullScreenPaperProps = isFullScreen
    ? {
        borderRadius: '0',
        margin: '0',
        maxHeight: '100%',
        height: '100%',
        width: '100%',
      }
    : {};

  /**
   * By default, only the cross icon can close the modal.
   */
  const handleClose = (_e: unknown, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    onClose();
  };

  return (
    <MUIDialog
      data-testid={dataTestId}
      sx={{
        borderRadius: '12px',
        outlineOffset: '-1px',
      }}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          width: size === 'sm' ? '484px' : '604px',
          ...fullScreenPaperProps,
        },
      }}
      scroll="paper"
      open={isOpen}
      onClose={handleClose}
    >
      <DialogMoreMenu actionButtons={actionButtons} onClose={onClose} />
      <DialogContent>
        <DialogTitle title={title} subTitle={subTitle} decoration={decoration} />
        {children}
      </DialogContent>
      <DialogActions cancelOptions={cancelOptions} secondaryOptions={secondaryOptions} submitOptions={submitOptions} />
    </MUIDialog>
  );
};

export default Dialog;
