import React, { useCallback, useMemo } from 'react';

import { ApolloError, gql } from '@apollo/client';
import { MaybeTooltip } from '@modernloop/shared/components';
import { PlusIcon } from '@modernloop/shared/icons';
import { Box, Button, Checkbox, ListItem, ListItemButton, Stack, useTheme } from '@mui/material';

import { TaskTagsBase_TaskTagFragment, useTaskTagsQuery } from 'src/generated/mloop-graphql';

import FilterSelect, { FilterSelectGroup } from 'src/components/FilterList/FilterSelect';
import Chip from 'src/components/chip';
import Label from 'src/components/label';
import { FCWithFragments } from 'src/components/types';

const useSxProps = () => {
  const theme = useTheme();
  return useMemo(() => {
    return {
      label: {
        borderRadius: '6px',
        height: theme.spacing(3),
        maxWidth: '100%',
        padding: '0 4px',
      },
    };
  }, [theme]);
};

type Props = {
  loading: boolean;
  error?: ApolloError;
  selectedTaskTags: TaskTagsBase_TaskTagFragment[];
  onChange: (newTaskTags: TaskTagsBase_TaskTagFragment[]) => void;
  getLabel?: () => React.ReactNode;
  hideSelectedTagChips?: boolean;
  onClose?: () => void;
};

type Fragments = {
  taskTags: TaskTagsBase_TaskTagFragment[];
};

const TaskTagsBase: FCWithFragments<Fragments, Props> = ({
  loading,
  error,
  taskTags,
  selectedTaskTags,
  onChange,
  getLabel,
  hideSelectedTagChips,
  onClose,
}) => {
  const sxProps = useSxProps();

  const options = useMemo(() => {
    const selectedTaskTagIds = selectedTaskTags.map((taskTag) => taskTag.id);
    return [...taskTags]
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => {
        if (selectedTaskTagIds.includes(a.id) && selectedTaskTagIds.includes(b.id)) return 0;
        if (selectedTaskTagIds.includes(a.id)) return -1;
        return 1;
      });
  }, [selectedTaskTags, taskTags]);

  const groupBy = useCallback(
    (taskTag: TaskTagsBase_TaskTagFragment) => {
      return selectedTaskTags.findIndex((selectedTag) => selectedTag.id === taskTag.id) !== -1
        ? FilterSelectGroup.SELECTED
        : FilterSelectGroup.SUGGESTED;
    },
    [selectedTaskTags]
  );

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, taskTag: TaskTagsBase_TaskTagFragment) => {
      const checked = selectedTaskTags.findIndex((selectedTag) => selectedTag.id === taskTag.id) !== -1;
      return (
        <ListItem {...props}>
          <ListItemButton>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ minWidth: 0 }}
              data-testid={`entity-task-tags-filter-select-option-${taskTag.id}`}
            >
              <Checkbox checked={checked} sx={{ flexShrink: 0 }} />
              <MaybeTooltip label={taskTag.name} title={taskTag.name} />
            </Stack>
          </ListItemButton>
        </ListItem>
      );
    },
    [selectedTaskTags]
  );

  const renderLabel = useCallback(() => {
    const label = 'Add tags';
    return (
      <Button
        variant="text"
        data-testid="entity-task-tags-filter-button"
        startIcon={<PlusIcon fontSize="small" />}
        size="small"
        color="primary"
        sx={sxProps.label}
      >
        <MaybeTooltip label={label} labelProps={{ color: 'info', variant: 'body2' }} title={label} />
      </Button>
    );
  }, [sxProps.label]);

  const handleDelete = useCallback(
    (taskTag: TaskTagsBase_TaskTagFragment) => {
      onChange(selectedTaskTags.filter((selectedTag) => selectedTag.id !== taskTag.id));
    },
    [onChange, selectedTaskTags]
  );

  const renderFilterSelect = useCallback(() => {
    return (
      <FilterSelect
        dataTestId="entity-task-tags-filter-select"
        options={options}
        values={selectedTaskTags}
        loading={loading}
        errorMsg={error?.message}
        placeholderText="Search tags"
        PaperProps={{ style: { width: '300px' } }}
        groupBy={groupBy}
        getLabel={getLabel || renderLabel}
        getOptionLabel={(taskTag) => taskTag.name}
        renderOption={renderOption}
        onChange={(event, values) => onChange(values)}
        onPopoverClose={onClose}
      />
    );
  }, [
    error?.message,
    getLabel,
    groupBy,
    loading,
    onChange,
    onClose,
    options,
    renderLabel,
    renderOption,
    selectedTaskTags,
  ]);

  return hideSelectedTagChips ? (
    renderFilterSelect()
  ) : (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '4px', width: '100%' }}>
      {renderFilterSelect()}

      {selectedTaskTags.map((taskTag) => {
        return (
          <Chip
            key={taskTag.id}
            dataTestId={`entity-task-tags-filter-chip-${taskTag.id}`}
            variant="default"
            label={<Label variant="captions">{taskTag.name}</Label>}
            tagProps={{ onDelete: () => handleDelete(taskTag) }}
            sx={{ margin: 0 }}
          />
        );
      })}
    </Box>
  );
};

TaskTagsBase.fragments = {
  taskTags: gql`
    fragment TaskTagsBase_taskTag on TaskTag {
      id
      name
    }
  `,
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type TaskTagsFragmentProps = {
  selectedTaskTags: TaskTagsBase_TaskTagFragment[];
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type TaskTagsProps = Pick<Props, 'selectedTaskTags' | 'onChange' | 'getLabel' | 'hideSelectedTagChips' | 'onClose'>;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/restric-fragments-name.cjs
const TaskTags: FCWithFragments<TaskTagsFragmentProps, TaskTagsProps> = ({
  selectedTaskTags,
  getLabel,
  onChange,
  hideSelectedTagChips,
  onClose,
}) => {
  const { data, loading, error } = useTaskTagsQuery();

  return (
    <TaskTagsBase
      loading={loading}
      error={error}
      taskTags={data?.taskTags?.items || []}
      selectedTaskTags={selectedTaskTags}
      onChange={onChange}
      getLabel={getLabel}
      hideSelectedTagChips={hideSelectedTagChips}
      onClose={onClose}
    />
  );
};

TaskTags.fragments = {
  selectedTaskTags: gql`
    ${TaskTagsBase.fragments.taskTags}
    fragment TaskTags_selectedTaskTag on TaskTag {
      ...TaskTagsBase_taskTag
    }
  `,
};

export default TaskTags;

export const TaskTagsQuery = gql`
  ${TaskTags.fragments.selectedTaskTags}
  query TaskTags {
    taskTags(input: { page: { offset: 0, limit: 100 } }) {
      items {
        ...TaskTags_selectedTaskTag
      }
    }
  }
`;
