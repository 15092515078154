import React, { useMemo, useState } from 'react';
import type { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/core';
import { StarFilledIcon } from '@modernloop/shared/icons';
import { Stack } from '@mui/material';
import { isNil } from 'lodash';

import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog';
import EmployeeChip from 'src/components/chip/EmployeeChip';
import Label from 'src/components/label';
import Tooltip from 'src/components/tooltip';

import { PREFERENCE_LEVEL_PREFERRED } from 'src/constants/InterviewPlan';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { addInterviewScheduleUpdate } from 'src/store/actions/schedule-update';
import { getOptionalAttendeeIdsList } from 'src/store/selectors/schedule-update';
import { getScheduleById } from 'src/store/selectors/schedules';

import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';
import { renderInterviewerHours } from 'src/utils/renderTimeRange';

import OptionalAttendeeToggle from 'src/views-new/ScheduleFlow/Steps/Communications/OptionalAttendeeToggle';

import { useDispatch, useSelector } from 'src/store';
import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: { marginBottom: '8px' },
  editor: {
    '& .ql-editor': {
      height: 500,
    },
  },
  interviewerRow: {
    '&:hover': {
      backgroundColor: theme.grey.solid.min,
    },
    paddingRight: theme.spacing(1),
    borderRadius: '6px',
  },
}));

interface Props {
  interviewEventId: string;
  scheduleId: string;
  employeeId: string;
  fullName: string;
  slackImageUrl: string | undefined | null;
  timezone: string | null;
  eventStartAt: number | Date | null;
  eventEndAt: number | Date | null;
  interviewersCount?: number;
  employeeChipClassName?: string;
  employeeNameLabelClassName?: string;
  isOptional?: boolean;
}

const InterviewerRow: FC<Props> = ({
  employeeId,
  fullName,
  slackImageUrl,
  timezone = 'UTC',
  interviewEventId,
  scheduleId,
  eventStartAt,
  eventEndAt,
  interviewersCount,
  employeeChipClassName,
  employeeNameLabelClassName,
  isOptional: isOptionalProp = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isOptional, setIsOptional] = useState<boolean>(isOptionalProp);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const optionalAttendeeIdsList = useSelector((state) =>
    getOptionalAttendeeIdsList(state, scheduleId, interviewEventId)
  );

  const originalSchedule = useSelector((state) => getScheduleById(state, scheduleId));
  const schedule = useScheduleWithoutBreaks(scheduleId);
  const isPreferred = useMemo(() => {
    const fn = (s: InterviewSchedule | null) => {
      const interview = s?.events.find((event) => event.id === interviewEventId);
      if (!interview) return false;

      const interviewer = interview.interviewers.find((i) => i.employeeId === employeeId);
      if (!interviewer) return false;

      const filledInterviewSeat = interview.filledInterviewSeats?.find(
        (seat) => seat.interviewerId === interviewer.employeeId
      );
      if (!filledInterviewSeat) return false;

      const preferredInterviewerIndex =
        filledInterviewSeat.interviewerId === employeeId &&
        filledInterviewSeat.seat.freeformSeat?.interviewers?.findIndex((i) => {
          return i.id === employeeId && i.preferenceLevel === PREFERENCE_LEVEL_PREFERRED;
        });
      return !isNil(preferredInterviewerIndex) && preferredInterviewerIndex !== -1;
    };

    // Any newly added interviewers will be present in schedule object.
    // And any delete interviewwers will be present in the originalSchedule object.
    return fn(schedule) || fn(originalSchedule);
  }, [employeeId, interviewEventId, originalSchedule, schedule]);

  const changeOptionalStatus = () => {
    setIsOptional((prev) => {
      const newState = !prev;

      dispatch(
        addInterviewScheduleUpdate({
          type: 'ScheduleUpdateInterviewerOptional',
          applicationStageInterviewerId: employeeId,
          applicationStageInterviewId: interviewEventId,
          isOptional: newState,
          scheduleId,
        })
      );

      return newState;
    });
  };

  const onUserIconClick = () => {
    // Checking if we are marking all the attendees as optional.
    if (!isNil(interviewersCount) && !isOptional && interviewersCount === optionalAttendeeIdsList.length + 1) {
      setOpenConfirmDialog(true);
    } else {
      changeOptionalStatus();
    }
  };

  return (
    <>
      <Stack
        direction="row"
        className={classes.interviewerRow}
        key={employeeId}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={1} width="100%">
          <EmployeeChip
            className={employeeChipClassName}
            name={fullName}
            photoSrc={slackImageUrl || ''}
            variant="default"
          />
          {isPreferred && (
            <Tooltip tooltip="Preferred interviewer">
              <StarFilledIcon color="warning" />
            </Tooltip>
          )}
          {timezone && (
            <Label className={employeeNameLabelClassName} variant="captions" fontWeight={400}>
              {renderInterviewerHours(eventStartAt, eventEndAt, timezone)}
            </Label>
          )}
        </Stack>

        <OptionalAttendeeToggle isAttendeeOptional={isOptional} onUserIconClick={onUserIconClick} />
      </Stack>
      <ConfirmDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        destructive
        title="Mark attendee as optional?"
        onConfirm={changeOptionalStatus}
      >
        <Label variant="body">
          You are marking all the interviewers as optional to attend this interview. Do you want to continue?
        </Label>
      </ConfirmDialog>
    </>
  );
};

export default InterviewerRow;
