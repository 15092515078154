import React from 'react';

import BaseIcon, { Props } from './Icon';

const ReverseShadow = ({ dataTestId, color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon dataTestId={dataTestId} color={color} fontSize={fontSize} tooltip={tooltip ?? 'Reverse shadow'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.92902 8.58582C2.14798 9.36686 2.14798 10.6332 2.92902 11.4142L8.58588 17.0711C9.36693 17.8521 10.6333 17.8521 11.4143 17.0711L17.0712 11.4142C17.8522 10.6332 17.8522 9.36686 17.0712 8.58582L11.4143 2.92896C10.6333 2.14791 9.36693 2.14791 8.58588 2.92896L2.92902 8.58582ZM8.8333 5.7501C8.09692 5.7501 7.49997 6.34705 7.49997 7.08343L7.49997 10.5001L7.49997 13.5001C7.49997 13.9143 7.83576 14.2501 8.24997 14.2501C8.66418 14.2501 8.99997 13.9143 8.99997 13.5001V11.2501L10.142 11.2501L11.5944 13.8643C11.7955 14.2264 12.2521 14.3569 12.6142 14.1557C12.9763 13.9546 13.1067 13.498 12.9056 13.1359L11.6465 10.8696C12.4566 10.3911 13 9.50906 13 8.5001C13 6.98132 11.7688 5.7501 10.25 5.7501H8.8333ZM10.25 9.7501H8.99997V7.2501H10.25C10.9403 7.2501 11.5 7.80974 11.5 8.5001C11.5 9.19046 10.9403 9.7501 10.25 9.7501Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default ReverseShadow;
