import { useMemo } from 'react';

import { QueryResult, gql } from '@apollo/client';

import { RenderType, TemplateToken, useUserTokensQuery } from 'src/generated/mloop-graphql';

import useAuth from 'src/hooks/useAuth';
import useEmployeeId from 'src/hooks/useEmployeeId';

import { EMPTY_OBJECT } from 'src/constants';

import placeholders from './utils/placeholders';

export const UserTemplateTokenQuery = gql`
  query UserTokens($id: String, $renderType: RenderType!) {
    templateToken(input: { id: $id, renderType: $renderType }) {
      id
      ME
      MY_FULL_NAME
      MY_FIRST_NAME
      MY_PHONE_NUMBER
      MY_SIGNATURE
      COMPANY_NAME
      COMPANY
    }
  }
`;

/**
 * TOKENS TO DISPLAY
 * Note Only add if eligible for display
 * */
export enum Tokens {
  MY_FIRST_NAME = 'MY_FIRST_NAME',
  MY_FULL_NAME = 'MY_FULL_NAME',
  MY_PHONE_NUMBER = 'MY_PHONE_NUMBER',
  MY_SIGNATURE = 'MY_SIGNATURE',
  COMPANY_NAME = 'COMPANY_NAME',
}

/**
 * USER TOKENS
 * These tokens are not displayed in the UI, change here also if UserPlaceholderFillerOptions is changed
 */
export const getNonDisplayFillers = () => {
  return ['ME', 'COMPANY'];
};

/**
 * TOKENS FETCHED
 * Tokens displayed are a subset of the tokens fetched
 */
export type NonDisplayFillers = Pick<TemplateToken, 'ME' | 'COMPANY'>;
export type UserPlaceholderFillerOptions = Pick<TemplateToken, keyof typeof Tokens> & NonDisplayFillers;

export default class UserPlaceholderFiller {
  static getFilledText = (input: string, options: UserPlaceholderFillerOptions): string => {
    return placeholders(input, options);
  };
}

export type Input = {
  renderType?: RenderType;
  isFetch?: boolean;
};

export const useUserPlaceholderFillerOptions = ({
  renderType,
  isFetch = true,
}): [UserPlaceholderFillerOptions, Pick<QueryResult, 'data' | 'error' | 'loading'>] => {
  const auth = useAuth();
  const employeeId = useEmployeeId();
  const orgName = auth.userInfo?.orgName;

  const {
    data: userTokensData,
    loading: userTokensLoading,
    error: userTokensError,
  } = useUserTokensQuery({
    skip: !isFetch || !employeeId,
    variables: { renderType: renderType || RenderType.Html, id: employeeId },
    errorPolicy: 'all',
  });

  const userPlaceholderFillerOptionsNew = useMemo(() => {
    if (!userTokensData) return EMPTY_OBJECT;

    const { templateToken } = userTokensData;

    let signature = templateToken?.MY_SIGNATURE;
    if (signature) {
      try {
        signature = decodeURIComponent(signature);
      } catch (e) {
        // Do nothing
      }
      // Replace any escaped double quotes with unescaped double quotes
      signature = signature.replace(/\\"/g, '"');
    }

    const placeholderOptions: UserPlaceholderFillerOptions = {
      ME: templateToken?.ME || '',
      MY_FIRST_NAME: templateToken?.MY_FIRST_NAME || '',
      MY_FULL_NAME: templateToken?.MY_FULL_NAME || '',
      MY_PHONE_NUMBER: templateToken?.MY_PHONE_NUMBER || undefined,
      MY_SIGNATURE: signature || `<strong>${templateToken?.MY_FULL_NAME || ''}</strong><br/>${orgName || ''}`,
      COMPANY_NAME: templateToken?.COMPANY_NAME,
      COMPANY: templateToken?.COMPANY,
    };

    return placeholderOptions;
  }, [orgName, userTokensData]);

  return useMemo(() => {
    return [
      userPlaceholderFillerOptionsNew,
      { data: userTokensData, error: userTokensError, loading: userTokensLoading },
    ];
  }, [userPlaceholderFillerOptionsNew, userTokensData, userTokensError, userTokensLoading]);
};
