import urlcat from 'urlcat';

/**
 * Reschedule Flow Url
 * @param applicationStageId
 * @returns string
 */
const getRescheduleFlowUrl = (applicationStageId: string, options?: { useRelativeUrl?: boolean }): string => {
  const { useRelativeUrl = true } = options || {};
  const currentUrl = new URL(window.location.href);
  const baseUrl = useRelativeUrl ? '' : currentUrl.origin;
  return urlcat(baseUrl, '/reschedule/:applicationStageId', { applicationStageId });
};

export default getRescheduleFlowUrl;
