import React from 'react';

import { Editor, IAllProps } from '@tinymce/tinymce-react';

import { tinyMceConfig } from 'src/config';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type TinyMceEditorProps = IAllProps;

const TinyMceEditor = (props: TinyMceEditorProps) => {
  const { init, ...restProps } = props;
  return (
    <Editor
      apiKey={tinyMceConfig.apiKey}
      {...restProps}
      init={{
        height: 500,
        menubar: false,
        branding: false,
        elementpath: false,
        plugins: [
          'advlist',
          'anchor',
          'autolink',
          'charmap',
          'code',
          'code',
          'fullscreen',
          'help',
          'image',
          'insertdatetime',
          'link',
          'lists',
          'media',
          'noneditable',
          'paste',
          'preview',
          'print',
          'searchreplace',
          'table',
          'visualblocks',
          'wordcount',
        ],
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic underline | forecolor backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | link | image | table | tokens',
        toolbar_mode: 'wrap',
        content_style: `
          body { font-family:Helvetica,Arial,sans-serif; font-size:14px }
          .token {
            background-color: #D6F0FF;
            padding: 1px 3px;
            color: #44719B;
            font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
            font-size: 0.9375em;
          }
        `,
        ...init,

        noneditable_class: 'token',

        // The noneditable_regexp option transforms matched text into spans which is then
        // visually styled to look like tags.
        // https://www.tiny.cloud/docs/plugins/noneditable/
        // noneditable_regexp: /\{\{[^\}]+\}\}/g, // matches {{handlebar}},
        noneditable_regexp: /\{\{[^}]+\}\}/g, // matches {{handlebar}},

        // images_upload_handler: (blobInfo, progress) =>
        //   new Promise((resolve, reject) => {
        //     console.log(JSON.stringify(blobInfo.filename()));
        //   }),
      }}
    />
  );
};

export default TinyMceEditor;
