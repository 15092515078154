import React from 'react';

import { gql } from '@apollo/client';
import { formatDayOffDateRange } from '@modernloop/shared/datetime';
import { CompanyHolidayIcon as Icon } from '@modernloop/shared/icons';
import { Box, BoxProps, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { CompanyHolidayIcon_CompanyHolidaysFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

type Props = {
  iconWrapperProps?: BoxProps;
};

type Fragments = {
  companyHolidays: CompanyHolidayIcon_CompanyHolidaysFragment[] | null;
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 360,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05)',
  },
}));

const CompanyHolidayIcon: FCWithFragments<Fragments, Props> = ({ companyHolidays, iconWrapperProps }) => {
  if (!companyHolidays?.length) return null;

  return (
    <ConditionalThemeProvider>
      <CustomWidthTooltip
        title={
          <Stack minWidth="280px" direction="column" spacing={1} padding={1}>
            <Typography variant="body2" fontWeight={600} color="text.secondary">
              Company Holidays
            </Typography>
            <Stack direction="column" spacing={1}>
              {companyHolidays.map((holiday) => (
                <Stack direction="row" justifyContent="space-between" gap={0.5} flexWrap="nowrap" key={holiday.id}>
                  <Stack direction="column" flexWrap="wrap">
                    <Typography variant="body1" color="text.primary">
                      {holiday.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {formatDayOffDateRange(holiday.startAt, holiday.endAt)}
                    </Typography>
                  </Stack>
                  <Stack gap={0.5} direction="row" flexWrap="wrap" maxWidth="150px">
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {holiday?.countries?.length ? (
                      holiday?.countries?.length === 1 ? (
                        <Chip label={holiday.countries[0].value} size="small" variant="outlined" />
                      ) : (
                        <Tooltip
                          title={holiday.countries.reduce((countries, val) => {
                            return countries ? `${countries}, ${val.value}` : val.value;
                          }, '')}
                        >
                          <Chip label={`${holiday.countries.length} countries`} size="small" variant="outlined" />
                        </Tooltip>
                      )
                    ) : (
                      <Chip label="All" size="small" variant="outlined" />
                    )}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        }
      >
        <Box {...iconWrapperProps} sx={{ cursor: 'pointer' }} display="flex" alignItems="center">
          <Icon />
        </Box>
      </CustomWidthTooltip>
    </ConditionalThemeProvider>
  );
};

CompanyHolidayIcon.fragments = {
  companyHolidays: gql`
    fragment CompanyHolidayIcon_companyHolidays on CompanyHoliday {
      countries {
        id
        value
      }
      id
      name
      startAt
      endAt
    }
  `,
};

export default CompanyHolidayIcon;
