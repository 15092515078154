import React, { useMemo } from 'react';

import { Stack, useTheme } from '@mui/material';

import IconButton from 'src/components/IconButton';
import { IconType, getIconFromEnum } from 'src/components/utils/icons';

/**
 * The action buttons in the top right next to the close icon button
 */
export type ActionIconButtonOptions = {
  tooltip: string;
  icon: IconType;
  onClick: () => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type DialogMoreMenuProps = {
  /**
   * The action buttons in the top right next to the close icon button
   * If this is not provided, the dialog will not have any extra action buttons
   */
  actionButtons?: ActionIconButtonOptions[];

  onClose: () => void;
};

const DialogMoreMenu = ({ actionButtons, onClose }: DialogMoreMenuProps) => {
  const currentTheme = useTheme();

  const actions: ActionIconButtonOptions[] = useMemo(() => {
    return actionButtons?.length
      ? [...actionButtons, { tooltip: 'Close', icon: 'CrossIcon', onClick: onClose }]
      : [{ tooltip: 'Close', icon: 'CrossIcon', onClick: onClose }];
  }, [actionButtons, onClose]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={1}
      sx={{
        position: 'sticky',
        top: 0,
        height: 0,
        zIndex: 1000,
        paddingRight: '20px',
      }}
    >
      {actions.map((action) => {
        const { tooltip, icon, onClick } = action;
        return (
          <IconButton
            dataTestId={`dialog-action-icon-button-${icon}`}
            key={icon}
            tooltip={tooltip}
            // Choosing style here to avoid makeStyles and make conversion to future sx prop easier
            style={{
              marginTop: '12px',
              height: '24px',
              width: '32px',
              borderRadius: '4px',
              backgroundColor: currentTheme.grey.solid.min,
            }}
            aria-label={tooltip}
            onClick={onClick}
          >
            {getIconFromEnum(icon)}
          </IconButton>
        );
      })}
    </Stack>
  );
};

export default DialogMoreMenu;
