/**
 * @deprecated Don't use this, string manupulation is weird, instead filter down to the values you want.
 * Groups the array by the given keys ex: "key1value-key2value-key3value"
 * @param keys to group by
 * @returns objected keyed by values of the given keys
 */
const groupBy =
  (keys: Array<string>) =>
  <T>(array: Array<T>): { [key: string]: Array<T> } =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = keys.map((key) => obj[key]).join('-');
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

export default groupBy;
