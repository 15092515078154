import { CircularProgress, Stack } from '@mui/material';

export const Loader = ({ loading, size = 30 }: { loading: boolean; size?: number }): JSX.Element | null => {
  if (loading) {
    return (
      <Stack direction="row" data-testId="loader" width="100%" justifyContent="center">
        <CircularProgress size={size} />
      </Stack>
    );
  }
  return null;
};
