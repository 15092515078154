import React, { useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { Dialog, FCWithFragments } from '@modernloop/shared/components';
import {
  IsoTimestamp,
  addDays,
  assertIsoTimestamp,
  endOfDay,
  formatTZ,
  formatToTimeZone,
  getLocalTimezone,
} from '@modernloop/shared/datetime';
import { PublicError } from '@modernloop/shared/helper-components';
import { logError } from '@modernloop/shared/utils';
import { Alert, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';

import {
  PauseMemberModal_ModuleMemberFragment,
  usePauseMemberModalInterviewModuleMemberUpdateMutation,
} from 'src/generated/mloop-graphql';

import DateTimePickerPopover from 'src/components/date-time-picker/DatePickerPopoverV2';

import { resetModuleMemberListCache } from 'src/entities/InterviewModuleMember/utils';

type Props = {
  onClose: () => void;
};

type Fragments = {
  moduleMember: PauseMemberModal_ModuleMemberFragment;
};

enum PauseValues {
  Indefinite = 'indefinite',
  TwoWeek = '14',
  OneMonth = '30',
  ThreeMonths = '90',
  Custom = 'custom',
}

const PauseOptions = [
  {
    label: '2 weeks',
    value: PauseValues.TwoWeek,
  },
  {
    label: '1 month',
    value: PauseValues.OneMonth,
  },
  {
    label: '3 months',
    value: PauseValues.ThreeMonths,
  },
  {
    label: 'Indefinitely',
    value: PauseValues.Indefinite,
  },
];

const PauseMemberModal: FCWithFragments<Fragments, Props> = ({ onClose, moduleMember }) => {
  const newDate = new Date();
  const newDateString = newDate.toISOString();
  const datePickerAnchorEl = useRef(null);
  const timezone = moduleMember.employee?.timezone || getLocalTimezone();

  const [customPauseDate, setCustomPauseDate] = useState<IsoTimestamp>(
    addDays(assertIsoTimestamp(newDateString), timezone, 1)
  );

  const [pauseValue, setPauseValue] = useState<PauseValues>();
  const [openDatePicker, setDatePickerOpen] = useState<boolean>(false);

  const [updateInterviewMemberPause, { loading, error: memberPauseError }] =
    usePauseMemberModalInterviewModuleMemberUpdateMutation();

  const getParams = () => {
    switch (pauseValue) {
      case PauseValues.Indefinite: {
        return { isPausedIndefinitely: true };
      }
      /** Get the Date according to the timezone in UTC
       * addDays returns date in UTC
       * BE requires in ISO string so converting to ISO string
       */
      case PauseValues.TwoWeek: {
        return {
          pausedUntil: endOfDay(
            addDays(assertIsoTimestamp(newDateString), timezone, Number(PauseValues.TwoWeek)),
            timezone
          ),
        };
      }
      case PauseValues.OneMonth: {
        return {
          pausedUntil: endOfDay(
            addDays(assertIsoTimestamp(newDateString), timezone, Number(PauseValues.OneMonth)),
            timezone
          ),
        };
      }
      case PauseValues.ThreeMonths: {
        return {
          pausedUntil: endOfDay(
            addDays(assertIsoTimestamp(newDateString), timezone, Number(PauseValues.ThreeMonths)),
            timezone
          ),
        };
      }
      case PauseValues.Custom: {
        return { pausedUntil: customPauseDate };
      }
      default: {
        return null;
      }
    }
  };

  const handleUpdate = async () => {
    const params = getParams();
    try {
      await updateInterviewMemberPause({
        variables: {
          input: {
            employeeId: moduleMember.employeeId,
            interviewModuleId: moduleMember.interviewModuleId,
            ...params,
          },
        },
        refetchQueries: ['InterviewModuleMemberSidepanelQuery'],
        awaitRefetchQueries: true,
        update: (cache) => {
          resetModuleMemberListCache({
            cache,
            employeeIds: [moduleMember.employeeId],
            moduleId: moduleMember.interviewModuleId,
          });
        },
      });
      onClose();
    } catch (error) {
      logError(error);
    }
  };

  const getAlertInfoText = () => {
    const params = getParams();

    if (!params) {
      return 'Select a pause duration';
    }

    if (params?.isPausedIndefinitely) {
      return 'Resumes never';
    }

    if (pauseValue === PauseValues.Custom) {
      return `Resumes ${formatToTimeZone(addDays(customPauseDate, timezone, 1), 'LLLL d, yyyy', timezone)}`;
    }

    if (params?.pausedUntil) {
      return `Resumes ${formatToTimeZone(params?.pausedUntil, 'LLLL d, yyyy', timezone)}`;
    }

    return '';
  };

  return (
    <Dialog
      decoration={
        <img
          style={{ width: '64px', height: '64px' }}
          src="/static/images/companyDaysOff/umbrella_island.png"
          alt="Share link"
        />
      }
      cancelOptions={{ label: 'Cancel', onClick: onClose }}
      submitOptions={{
        label: 'Pause',
        onClick: handleUpdate,
        isDisabled: loading,
        isLoading: loading,
      }}
      title="Pause from module"
      subTitle="Interviewer won’t be considered for this module until pause ends."
      open
      maxWidth="xs"
      onClose={onClose}
    >
      <Typography variant="body1">Pause for…</Typography>
      <Stack rowGap={1.5}>
        <Stack ml={1.5}>
          <FormControl>
            <RadioGroup
              onChange={(event, value: PauseValues) => {
                setPauseValue(value);
              }}
              name="radio-buttons-pause-group"
            >
              {PauseOptions.map((option) => {
                if (option.value === PauseValues.Custom) {
                  return null;
                }
                return (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio checked={pauseValue === option.value} />}
                    label={option.label}
                  />
                );
              })}

              <FormControlLabel
                key={PauseValues.Custom}
                value={PauseValues.Custom}
                control={<Radio checked={pauseValue === PauseValues.Custom} />}
                ref={datePickerAnchorEl}
                label={
                  <Stack
                    display="flex"
                    columnGap={8}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>Custom date</Typography>
                    <TextField
                      ref={datePickerAnchorEl}
                      variant="outlined"
                      value={customPauseDate ? formatTZ(customPauseDate, timezone, 'MMM d, yyyy') : ''}
                      onClick={() => {
                        setPauseValue(PauseValues.Custom);
                        setDatePickerOpen(true);
                      }}
                    />
                  </Stack>
                }
                onChange={() => {
                  setDatePickerOpen(true);
                }}
              />
            </RadioGroup>
          </FormControl>
          <DateTimePickerPopover
            open={openDatePicker}
            utcDate={customPauseDate}
            timezone={timezone}
            dateAnchorEl={datePickerAnchorEl}
            onChange={setCustomPauseDate}
            onClose={() => {
              setDatePickerOpen(false);
            }}
          />
        </Stack>

        <Alert variant="standard" severity="info">
          <Typography variant="body2">{getAlertInfoText()}</Typography>
        </Alert>
      </Stack>
      {memberPauseError && <PublicError error={memberPauseError} />}
    </Dialog>
  );
};

export const PauseMemberModalInterviewModuleMemberUpdate = gql`
  mutation PauseMemberModalInterviewModuleMemberUpdate($input: InterviewModuleMemberUpdateInput!) {
    interviewModuleMemberUpdate(input: $input) {
      interviewModuleMember {
        ...pauseMemberModal_moduleMember
      }
    }
  }
`;

PauseMemberModal.fragments = {
  moduleMember: gql`
    fragment pauseMemberModal_moduleMember on InterviewModuleMember {
      id
      employeeId
      interviewModuleId
      isPausedIndefinitely
      isPaused
      pausedUntil
      employee {
        id
        timezone
      }
    }
  `,
};

export default PauseMemberModal;
