import { CrossIcon } from '@modernloop/shared/icons';
import { IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

/**
 * The action buttons in the top right next to the close icon button
 */
export type ActionIconButtonOptions = {
  tooltip: string;
  icon: React.ReactNode;
  onClick: () => void;
};

export type Props = {
  /**
   * The action buttons in the top right next to the close icon button
   * If this is not provided, the dialog will not have any extra action buttons
   */
  actionButtons?: ActionIconButtonOptions[];

  onClose?: () => void;
};

const useSxProps = () => {
  const theme = useTheme();
  return useMemo(() => {
    return {
      iconButton: {
        marginTop: theme.spacing(1.5),
        height: theme.spacing(3),
        width: theme.spacing(4),
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.background.contrast,
      },
    };
  }, [theme]);
};

const DialogMoreMenu = ({ actionButtons, onClose }: Props) => {
  const sxProps = useSxProps();

  const actions: ActionIconButtonOptions[] = useMemo(() => {
    const defaultActionButtons: ActionIconButtonOptions[] = onClose
      ? [{ tooltip: 'Close', icon: <CrossIcon />, onClick: onClose }]
      : [];

    return actionButtons?.length ? [...actionButtons, ...defaultActionButtons] : defaultActionButtons;
  }, [actionButtons, onClose]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={1}
      position="sticky"
      top="16px"
      height={0}
      zIndex={1000}
      pr={2.5}
    >
      {actions.map((action, index) => {
        const { tooltip, icon, onClick } = action;
        return (
          <Tooltip title={tooltip} key={index}>
            <IconButton
              data-testid="dialog-action-icon-button"
              // eslint-disable-next-line no-restricted-syntax
              sx={sxProps.iconButton}
              aria-label={tooltip}
              onClick={onClick}
            >
              {icon}
            </IconButton>
          </Tooltip>
        );
      })}
    </Stack>
  );
};

export default DialogMoreMenu;
