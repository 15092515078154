import React from 'react';

import BaseIcon, { Props } from './Icon';

const OrderLockIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 4C3.25 4.41421 3.58579 4.75 4 4.75H16C16.4142 4.75 16.75 4.41421 16.75 4C16.75 3.58579 16.4142 3.25 16 3.25H4C3.58579 3.25 3.25 3.58579 3.25 4ZM3.25 8C3.25 7.58579 3.58579 7.25 4 7.25H9C9.41421 7.25 9.75 7.58579 9.75 8C9.75 8.41421 9.41421 8.75 9 8.75H4C3.58579 8.75 3.25 8.41421 3.25 8ZM3.25 12C3.25 11.5858 3.58579 11.25 4 11.25H7C7.41421 11.25 7.75 11.5858 7.75 12C7.75 12.4142 7.41421 12.75 7 12.75H4C3.58579 12.75 3.25 12.4142 3.25 12ZM4 16.75C3.58579 16.75 3.25 16.4142 3.25 16C3.25 15.5858 3.58579 15.25 4 15.25H7C7.41421 15.25 7.75 15.5858 7.75 16C7.75 16.4142 7.41421 16.75 7 16.75H4ZM12.25 10C12.25 9.30964 12.8096 8.75 13.5 8.75C14.1904 8.75 14.75 9.30964 14.75 10V10.2556L12.25 10.2618V10ZM10.75 10.271V10C10.75 8.48122 11.9812 7.25 13.5 7.25C15.0188 7.25 16.25 8.48122 16.25 10V10.258C17.0899 10.3285 17.75 11.0325 17.75 11.8911V16.0959C17.75 16.9995 17.0187 17.7326 16.1152 17.7348L10.893 17.7478C9.98624 17.75 9.25 17.0156 9.25 16.1089V11.904C9.25 11.0459 9.90964 10.3415 10.75 10.271ZM15 14C15 14.8284 14.3284 15.5 13.5 15.5C12.6716 15.5 12 14.8284 12 14C12 13.1716 12.6716 12.5 13.5 12.5C14.3284 12.5 15 13.1716 15 14Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default OrderLockIcon;
