import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { v4 as uuid } from 'uuid';

import { CloneInterviewPlan_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import { InterviewPlan } from '.';

type Fragments = {
  interviewPlan: CloneInterviewPlan_InterviewPlanFragment;
};

type Props = {
  customJobStageId: string;
};

const cloneInterviewPlan: FunctionWithFragments<Fragments, Props, CloneInterviewPlan_InterviewPlanFragment> = (
  { interviewPlan },
  { customJobStageId }
) => {
  // Original id to new id mapping
  const linkedSeatIdMap: {
    [linkedSeatId: string]: string;
  } = {};

  return {
    id: customJobStageId,
    job: interviewPlan.job,
    jobStageInterviewGroups:
      interviewPlan.jobStageInterviewGroups?.map((group) => {
        const newGroupId = uuid();

        return {
          ...group,
          id: newGroupId,
          jobStageId: customJobStageId,
          jobStageInterviews:
            group.jobStageInterviews?.map((interview) => {
              const newInterviewId = uuid();

              return {
                ...interview,
                id: newInterviewId,
                jobStageInterviewGroupId: newGroupId,
                jobStageInterviewSeats:
                  interview.jobStageInterviewSeats?.map((seat) => {
                    // Create an id for the other seat if it doesn't exist
                    if (seat.linkedSeat) {
                      const originalSeatId = seat.linkedSeat.linkedJobStageInterviewSeatId;
                      linkedSeatIdMap[originalSeatId] = linkedSeatIdMap[originalSeatId] || uuid();
                    }

                    // Add this interview to the linked seat map
                    linkedSeatIdMap[seat.id] = linkedSeatIdMap[seat.id] || uuid();
                    const seatId = linkedSeatIdMap[seat.id];

                    return {
                      ...seat,
                      id: seatId,
                      moduleSeat: seat.moduleSeat
                        ? {
                            ...seat.moduleSeat,
                            jobStageInterviewSeatAttributes: seat.moduleSeat.jobStageInterviewSeatAttributes?.map(
                              (attr) => ({
                                id: attr.id,
                                jobStageInterviewSeatId: seatId,
                                attributeNameId: attr.attributeNameId,
                                attributeTagValueId: attr.attributeTagValueId,
                              })
                            ),
                          }
                        : undefined,
                      linkedSeat: seat.linkedSeat
                        ? {
                            linkedJobStageInterviewSeatId:
                              linkedSeatIdMap[seat.linkedSeat.linkedJobStageInterviewSeatId],
                          }
                        : undefined,
                      freeformSeat: seat.freeformSeat
                        ? {
                            jobStageInterviewSeatEmployees: seat.freeformSeat.jobStageInterviewSeatEmployees?.map(
                              (employee) => ({
                                employee: employee.employee,
                                employeeId: employee.employeeId,
                                jobStageInterviewSeatId: seatId,
                                preferenceLevel: employee.preferenceLevel,
                              })
                            ),
                          }
                        : undefined,
                    };
                  }) || [],
              };
            }) || [],
        };
      }) || [],
    schedulingWindow: interviewPlan.schedulingWindow ? { seconds: interviewPlan.schedulingWindow?.seconds } : undefined,
    excludedEmployees: interviewPlan.excludedEmployees,
  };
};

cloneInterviewPlan.fragments = {
  interviewPlan: gql`
    ${InterviewPlan.fragments.job}
    ${InterviewPlan.fragments.jobStage}
    fragment cloneInterviewPlan_interviewPlan on JobStage {
      id
      job {
        id
        ...InterviewPlan_job
      }
      ...InterviewPlan_jobStage
    }
  `,
};

export default cloneInterviewPlan;
