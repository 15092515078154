import { useMemo } from 'react';

import { QueryResult, gql } from '@apollo/client';

import { RenderType, TemplateToken, useTaskTokensQuery } from 'src/generated/mloop-graphql';

import useUserId from 'src/hooks/useUserId';

import { EMPTY_OBJECT } from 'src/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import placeholders from './utils/placeholders';

export const TaskTokensQueryDoc = gql`
  query TaskTokens($id: String, $renderType: RenderType!, $taskId: uuid, $assigneeId: uuid) {
    templateToken(input: { id: $id, renderType: $renderType }) {
      id
      TASK_CREATOR(input: { taskId: $taskId })
      TASK_ASSIGNEE(input: { taskId: $taskId, assigneeId: $assigneeId })
    }
  }
`;

/**
 * TOKENS TO DISPLAY
 * Note Only add if eligible for display
 * */
export enum Tokens {
  TASK_CREATOR = 'TASK_CREATOR',
  TASK_ASSIGNEE = 'TASK_ASSIGNEE',
}

/**
 * TOKENS FETCHED
 * Tokens displayed are a subset of the tokens fetched
 */
export type TaskPlaceholderFillerOptions = Pick<TemplateToken, keyof typeof Tokens>;

export default class TaskPlaceholderFiller {
  static getFilledText = (input: string, options: TaskPlaceholderFillerOptions): string => {
    return placeholders(input, options);
  };
}

export type Input = {
  renderType?: RenderType;
  isFetch?: boolean;
  // Task id is passed in when the user is editing an existing task
  taskId?: string;

  // Assignee id is passed in when the user is creating a new task
  assigneeId?: string;
};

/**
 * Placeholder filler for all Task tokens. It fetches the tokens based on 2 scenarios:
 * 1. There is an existing task. In this case, the taskId is passed in and the tokens are fetched for that task.
 * 2. They are in the process of creating a new task. In this case, the assigneeId is passed in and the tokens are fetched for that user.
 */
export const useTaskPlaceholderFiller = ({
  renderType,
  taskId,
  assigneeId,
  isFetch = true,
}: Input): [TaskPlaceholderFillerOptions, Pick<QueryResult, 'data' | 'error' | 'loading'>] => {
  const currentUserId = useUserId();

  const id = taskId ?? assigneeId ?? currentUserId;
  const {
    data: taskTokensData,
    loading: taskTokensLoading,
    error: taskTokensError,
  } = useTaskTokensQuery({
    skip: !id || !isFetch,
    variables: {
      id,
      renderType: renderType || RenderType.Html,
      taskId,
      assigneeId,
    },
    // Need to use cache-and-network in-case the user changes the assignee id after the task tokens have already been fetched once
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  });

  const taskPlaceholderFillerOptions = useMemo(() => {
    if (!taskTokensData) return EMPTY_OBJECT;

    const { templateToken } = taskTokensData;

    const placeholderFiller: TaskPlaceholderFillerOptions = {
      TASK_CREATOR: templateToken?.TASK_CREATOR || undefined,
      TASK_ASSIGNEE: templateToken?.TASK_ASSIGNEE || undefined,
    };

    return placeholderFiller;
  }, [taskTokensData]);

  return useMemo(() => {
    return [taskPlaceholderFillerOptions, { data: taskTokensData, error: taskTokensError, loading: taskTokensLoading }];
  }, [taskPlaceholderFillerOptions, taskTokensData, taskTokensError, taskTokensLoading]);
};
