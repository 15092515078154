import { AtsType } from 'src/generated/mloop-graphql';

import { useIntegrations } from '../api/integration';

import AshbyAtsService from './AshbyAtsService';
import AtsSevice from './AtsService';
import GemAtsService from './GemAtsService';
import GreenhouseAtsService from './GreenhouseAtsService';
import LeverAtsService from './LeverAtsService';
import WorkdayAtsService from './WorkdayAtsService';

/* *
 * @description
 * Fetches the ATS type from the integrations, new ats to be added here
 * FE should be completely agnostic to the ATS type
 * This hook should be used strictly within this file and never be used directly
 * Infact even on ATS service, we should not have any ats type stored
 * IMP: Never export
 * */

const useCurrentATSType = (): AtsType | null => {
  const { data } = useIntegrations();

  if (!data) {
    return null;
  }

  if (data.greenhouse) {
    return AtsType.Greenhouse;
  }

  if (data.lever) {
    return AtsType.Lever;
  }

  if (data.ashby) {
    return AtsType.Ashby;
  }

  if (data.gem) {
    return AtsType.Gem;
  }

  if (data.workday) {
    return AtsType.Workday;
  }

  return null;
};

/**
 * @param atsType
 * @returns boolean
 * @description
 * Returns true if the org ATS is the same as the atsType passed
 * Currently only used at 2 place
 * Note: If used too many places, consider moving this in AtsSevice
 */
const useIsOnCurrentAts = (atsType?: AtsType): boolean => {
  const ats = useCurrentATSType();
  return atsType === ats;
};

/* *
 * @param atsType
 * @returns AtsSevice
 * @description Returns the ATS service based on the ATS type.
 * To be used when atsType is know from an entity eg Application, Candidate or Job
 * If not always use useOrgAtsService
 * */
const getAtsService = (atsType: AtsType): AtsSevice => {
  switch (atsType) {
    case AtsType.Greenhouse:
      return GreenhouseAtsService;
    case AtsType.Lever:
      return LeverAtsService;
    case AtsType.Ashby:
      return AshbyAtsService;
    case AtsType.Gem:
      return GemAtsService;
    case AtsType.Workday:
      return WorkdayAtsService;
    default:
      throw new Error(`Unsupported ATS type: ${atsType}`);
  }
};

/* *
 * @description
 * Returns the ATS service based on the org ATS type
 * */
const useOrgAtsService = () => {
  const ats = useCurrentATSType();
  if (!ats) return null;
  return getAtsService(ats);
};

export { useOrgAtsService, getAtsService, useIsOnCurrentAts };
