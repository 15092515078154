import React, { useCallback, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { isBeforeNow } from '@modernloop/shared/datetime';
import { Button, Stack } from '@mui/material';

import { DebriefContentActions_DebriefFragment } from 'src/generated/mloop-graphql';

import useHistory from 'src/hooks/useHistory';

import getDebriefFlowUrl from 'src/urls/getDebriefFlowUrl';

// eslint-disable-next-line modernloop/restrict-imports.cjs
import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

import DebriefContentCancelDebriefDialog from './DebriefContentMeeting/DebriefContentCancelDebriefDialog';

type Fragments = {
  debrief: DebriefContentActions_DebriefFragment;
};

export enum DebriefState {
  UPCOMING = 'upcoming',
  COMPLETED = 'completed',
}

const DebriefContentActions: FCWithFragments<Fragments> = ({ debrief }) => {
  const history = useHistory();
  const [showCancelScheduleDialog, setShowCancelScheduleDialog] = useState(false);

  const handleUpdate = useCallback(() => {
    const updateUrl = getDebriefFlowUrl(debrief.applicationId, { taskId: debrief.task?.id });
    history.push(updateUrl);
    SidePanelManager.closeSidePanel();
  }, [debrief.applicationId, debrief.task?.id, history]);

  const handleCancelSchedule = useCallback(() => setShowCancelScheduleDialog(true), []);

  const debriefState = useMemo(() => {
    const isCompleted = isBeforeNow(debrief.endAt);
    if (isCompleted) {
      return DebriefState.COMPLETED;
    }

    return DebriefState.UPCOMING;
  }, [debrief.endAt]);

  const actionButtons = useMemo(() => {
    switch (debriefState) {
      case DebriefState.UPCOMING: {
        return [
          <Button key="schedule-content-actions-update-button" size="small" onClick={handleUpdate}>
            Update
          </Button>,

          <Button key="schedule-content-actions-cancel-button" size="small" onClick={handleCancelSchedule}>
            Cancel debrief
          </Button>,
        ];
      }
      case DebriefState.COMPLETED: {
        return [
          <Button key="schedule-content-actions-cancel-button" size="small" onClick={handleCancelSchedule}>
            Mark canceled
          </Button>,
        ];
      }

      default:
        return null;
    }
  }, [handleCancelSchedule, handleUpdate, debriefState]);

  return (
    <Stack direction="row" gap={1} px={2.5} py={1.5}>
      {actionButtons}
      {showCancelScheduleDialog && (
        <DebriefContentCancelDebriefDialog
          interviewId={debrief.id}
          onClose={() => setShowCancelScheduleDialog(false)}
        />
      )}
    </Stack>
  );
};

DebriefContentActions.fragments = {
  debrief: gql`
    fragment DebriefContentActions_debrief on ApplicationDebrief {
      id
      applicationId
      startAt
      endAt
      task {
        id
      }
    }
  `,
};

export default DebriefContentActions;
