import FilterList, { Props as FilterListProps } from './index';
import { BaseProps } from '@modernloop/shared/components';
import { Box, Paper, Popover, PopoverProps } from '@mui/material';
import { PropsWithChildren, useRef } from 'react';

export type Props<TData> = BaseProps &
  FilterListProps<TData> &
  Pick<PopoverProps, 'open' | 'PaperProps'> & {
    onPopoverClose?: PopoverProps['onClose'];
    listChildren?: FilterListProps<TData>['children'];
  };

const FilterListPopover = <TData,>({
  dataTestId,
  open,
  PaperProps,
  onPopoverClose,
  listChildren,
  children,
  ...filterListProps
}: PropsWithChildren<Props<TData>>): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div ref={wrapperRef} data-testid={dataTestId}>
        {children}
      </div>
      <Popover
        open={open}
        anchorEl={wrapperRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        slotProps={{
          paper: PaperProps ?? {
            elevation: 2,
            style: { width: wrapperRef.current?.clientWidth },
          },
        }}
        onClose={onPopoverClose}
        disableScrollLock
      >
        <Paper>
          <Box p={1}>
            <FilterList {...filterListProps}>{listChildren}</FilterList>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

export default FilterListPopover;
