import React from 'react';

import { gql } from '@apollo/client';

import { TaskName_TaskFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

type Fragments = {
  task: TaskName_TaskFragment;
};

// Gets the task name from the task object
const TaskName: FCWithFragments<Fragments> = ({ task }) => {
  const { name } = task;

  return <>{name}</>;
};

TaskName.fragments = {
  task: gql`
    fragment TaskName_task on Task {
      id
      name
    }
  `,
};

export default TaskName;
