import { apiPost } from 'src/utils/api';
import ApiVerb from 'src/utils/api/ApiVerb';
import { AxiosResponse } from 'src/utils/axios';
import { TimeRange } from 'src/utils/getTimePeriods';

export type RequestParams = {
  applicationId: string;
  jobStageId: string;
  candidateAvailability: TimeRange[];
  candidateTimezone: string;
  taskId?: string;
};

const updateCandidateAvailability = (request: RequestParams): Promise<AxiosResponse> => {
  return apiPost(ApiVerb.UPDATE_CANDIDATE_AVAILABILITY, request);
};

export default updateCandidateAvailability;
