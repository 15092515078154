import { Dispatch, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UploadFileResponse } from 'src/hooks/api/file/useUploadFile';

import { ApiVerb, apiPost } from 'src/utils/api';

import { AppThunk } from 'src/store';

export enum FileStatus {
  UPLOADED = 'uploaded',
  UPLOADING = 'uploading',
  ERRORED = 'errored',
}
export interface UIFile {
  name: string;
  status: FileStatus;
  timestamp: number;
  path?: string;
  size: number;
}

export enum FileUploadFlow {
  SCHEDULING = 'scheduling_editor',
  RESCHEDULING = 'rescheduling_editor',
  EMAIL_TEMPLATE = 'email_template_editor',
  CANDIDATE_AVAILABILITY = 'candidate_availability',
  SELF_SCHEDULE_LINK = 'self_schedule_link',
}

interface FileUploadState {
  [FileUploadFlow.SCHEDULING]: {
    [filename: string]: UIFile;
  };
  [FileUploadFlow.RESCHEDULING]: {
    [filename: string]: UIFile;
  };
  [FileUploadFlow.EMAIL_TEMPLATE]: {
    [filename: string]: UIFile;
  };
  [FileUploadFlow.CANDIDATE_AVAILABILITY]: {
    [filename: string]: UIFile;
  };
  [FileUploadFlow.SELF_SCHEDULE_LINK]: {
    [filename: string]: UIFile;
  };
}

const initialState: FileUploadState = {
  [FileUploadFlow.SCHEDULING]: {},
  [FileUploadFlow.RESCHEDULING]: {},
  [FileUploadFlow.EMAIL_TEMPLATE]: {},
  [FileUploadFlow.CANDIDATE_AVAILABILITY]: {},
  [FileUploadFlow.SELF_SCHEDULE_LINK]: {},
};

// reducers
const slice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    initFiles: (state, { payload: { flow, files } }: PayloadAction<{ flow: FileUploadFlow; files: UIFile[] }>) => {
      state[flow] = {};
      for (let cnt = 0; cnt < files.length; cnt++) {
        state[flow][files[cnt].name] = files[cnt];
      }
    },
    addFile: (state, { payload: { flow, file } }: PayloadAction<{ flow: FileUploadFlow; file: UIFile }>) => {
      state[flow][file.name] = file;
    },
    updateFile: (state, { payload: { flow, file } }: PayloadAction<{ flow: FileUploadFlow; file: UIFile }>) => {
      // if file is deleted, it is no-op
      if (!state[flow][file.name]) return { ...state };
      return {
        ...state,
        [flow]: {
          ...state[flow],
          [file.name]: file,
        },
      };
    },
    removeFile: (state, { payload: { flow, name } }: PayloadAction<{ flow: FileUploadFlow; name: string }>) => {
      delete state[flow][name];
    },
    removeAllUploadedFiles: () => {
      return initialState;
    },
  },
});

export const { reducer } = slice;
export default reducer;

export const startFileUpload = (flow: FileUploadFlow, file: File) => {
  return async (dispatch: Dispatch): Promise<void> => {
    const uiFile: UIFile = {
      name: file.name,
      status: FileStatus.UPLOADING,
      timestamp: new Date().getTime(),
      size: file.size,
    };
    dispatch(slice.actions.addFile({ flow, file: uiFile }));
    try {
      const formData = new FormData();
      formData.append('file', file);
      if (flow === FileUploadFlow.EMAIL_TEMPLATE) {
        formData.append('persist', 'true');
      }
      const uploadedFile = (await apiPost(ApiVerb.API_UPLOAD_FILE, formData)).data as UploadFileResponse;
      // update file to be done
      const updateUiFile = {
        ...uiFile,
        status: FileStatus.UPLOADED,
        path: uploadedFile.url,
      };
      dispatch(slice.actions.updateFile({ flow, file: updateUiFile }));
    } catch (error) {
      // update file to have error
      const updateUiFile = {
        ...uiFile,
        status: FileStatus.ERRORED,
      };
      dispatch(slice.actions.updateFile({ flow, file: updateUiFile }));
    }
  };
};

export const initFiles =
  (flow: FileUploadFlow, files: UIFile[]): AppThunk =>
  (dispatch: Dispatch) =>
    dispatch(slice.actions.initFiles({ flow, files }));

export const removeUploadFile =
  (flow: FileUploadFlow, name: string): AppThunk =>
  (dispatch: Dispatch) =>
    dispatch(slice.actions.removeFile({ flow, name }));

export const removeAllUploadedFiles = (): AppThunk => (dispatch: Dispatch) =>
  dispatch(slice.actions.removeAllUploadedFiles());
