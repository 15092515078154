import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { SendIcon } from '@modernloop/shared/icons';
import { Alert, AlertTitle, Button } from '@mui/material';

import { HoldForCandidateCommunications_ApplicationStageFragment } from 'src/generated/mloop-graphql';

import useHistory from 'src/hooks/useHistory';

import getUpdateFlowUrl from 'src/urls/getUpdateFlowUrl';

// eslint-disable-next-line modernloop/restrict-imports.cjs
import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

type Fragments = {
  applicationStage: HoldForCandidateCommunications_ApplicationStageFragment;
};

const HoldForCandidateCommunications: FCWithFragments<Fragments> = ({ applicationStage }) => {
  const history = useHistory();

  if (!applicationStage.isHoldForRsvp && !applicationStage.isReadyForCandidateComms) return null;

  return (
    <Alert
      severity="pending"
      sx={{ my: 1.5 }}
      action={
        <Button
          variant="text"
          color="inherit"
          startIcon={<SendIcon />}
          onClick={() => {
            history.push(getUpdateFlowUrl(applicationStage.id, { candidateComms: true }));
            SidePanelManager.closeSidePanel();
          }}
        >
          Send
        </Button>
      }
    >
      <AlertTitle>Candidate communications are on hold</AlertTitle>
      Please review all interviewer RSVPs before sending
    </Alert>
  );
};

HoldForCandidateCommunications.fragments = {
  applicationStage: gql`
    fragment HoldForCandidateCommunications_applicationStage on ApplicationStage {
      id
      isHoldForRsvp
      isReadyForCandidateComms
    }
  `,
};

export default HoldForCandidateCommunications;
