import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { trim } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  FreeformSeatInput,
  GetSaveInterviewPlanInput_InterviewPlanFragment,
  InterviewGroup_JobStageInterviewsFragment,
  JobStageInterviewInput,
  JobStageInterviewPlanUpsertInput,
  JobStageInterviewSeatEmployeeInput,
  JobStageInterviewSeatInput,
  LinkedSeatInput,
  ModuleSeatInput,
} from 'src/generated/mloop-graphql';

import { getAtsInterviewDefinitionFields } from 'src/hooks/atsService/util';

import { InterviewPlan } from '.';

type Fragments = {
  interviewPlan: GetSaveInterviewPlanInput_InterviewPlanFragment | null;
};

export function getShouldUseAtsNameAndDuration(jobStageInterview: InterviewGroup_JobStageInterviewsFragment) {
  const useAtsName =
    trim(jobStageInterview.name || undefined) === trim(jobStageInterview?.atsInterviewDefinition?.name || undefined);
  const useAtsDuration =
    jobStageInterview.duration ===
    getAtsInterviewDefinitionFields(jobStageInterview?.atsInterviewDefinition?.atsFields, 'estimatedMinutes');
  return {
    useAtsName,
    useAtsDuration,
  };
}

const getSaveInterviewPlanInput: FunctionWithFragments<
  Fragments,
  { finalJobStageId?: string; generateNewIds?: boolean },
  JobStageInterviewPlanUpsertInput
> = ({ interviewPlan }, { finalJobStageId, generateNewIds = false }) => {
  const getId = (id: string): string => {
    return generateNewIds ? uuid() : id;
  };

  const jobStageId = finalJobStageId ?? uuid();

  if (!interviewPlan || !interviewPlan?.jobStageInterviewGroups) {
    return {
      jobStageId,
      groups: [],
      schedulingWindow: interviewPlan?.schedulingWindow?.seconds
        ? { seconds: interviewPlan?.schedulingWindow?.seconds }
        : undefined,
      excludedEmployeeIds: interviewPlan?.excludedEmployees?.map((employee) => employee.id),
    };
  }

  // Original id to new id mapping
  const linkSeatIdMap: {
    [linkedSeatId: string]: string;
  } = {};

  const groups = interviewPlan.jobStageInterviewGroups.map((group, groupIndex) => {
    const groupId = getId(group.id);
    return {
      id: groupId,
      locked: group.locked,
      jobStageId,
      index: groupIndex,
      name: group.name,
      jobStageInterviews: group?.jobStageInterviews?.map((interview, interviewIndex) => {
        const { useAtsName, useAtsDuration } = getShouldUseAtsNameAndDuration(interview);
        const interviewId = getId(interview.id);

        return {
          id: interviewId,
          isLockedOrder: interview.isLockedOrder,
          jobStageId,
          index: interviewIndex,
          name: interview.name,
          interviewType: interview.interviewType,
          duration: interview.duration,
          isHiddenFromCandidate: interview.isHiddenFromCandidate,
          atsInterviewDefinitionId: interview.atsInterviewDefinition?.atsId,
          jobStageInterviewGroupId: groupId,
          useAtsName,
          useAtsDuration,
          dynamicLinkTypes: interview.dynamicLinkTypes,
          jobStageInterviewSeats: interview?.jobStageInterviewSeats?.map((seat, seatIndex) => {
            // Create an id for the other seat if it doesn't exist
            if (seat.linkedSeat) {
              const linkedSeatId = seat.linkedSeat.linkedJobStageInterviewSeatId;
              linkSeatIdMap[linkedSeatId] = linkSeatIdMap[linkedSeatId] || getId(linkedSeatId);
            }
            // Add this interview to the linked seat map
            linkSeatIdMap[seat.id] = linkSeatIdMap[seat.id] || getId(seat.id);
            const seatId = linkSeatIdMap[seat.id];

            return {
              id: seatId,
              index: seatIndex,
              jobStageInterviewId: interviewId,
              moduleSeat: seat.moduleSeat
                ? ({
                    interviewModuleId: seat.moduleSeat.interviewModuleId,
                    selectedEmployeeIds: seat.moduleSeat.selectedEmployeeIds,
                    jobStageInterviewSeatAttributes: seat.moduleSeat.jobStageInterviewSeatAttributes?.map((attr) => ({
                      id: attr.id,
                      attributeNameId: attr.attributeNameId,
                      attributeTagValueId: attr.attributeTagValueId,
                      jobStageInterviewSeatId: seatId,
                    })),
                  } as ModuleSeatInput)
                : undefined,
              linkedSeat: seat.linkedSeat
                ? ({
                    linkedJobStageInterviewSeatId: linkSeatIdMap[seat.linkedSeat.linkedJobStageInterviewSeatId],
                  } as LinkedSeatInput)
                : undefined,
              freeformSeat: seat.freeformSeat
                ? ({
                    jobStageInterviewSeatEmployees: seat.freeformSeat.jobStageInterviewSeatEmployees?.map(
                      (employee) =>
                        ({
                          employeeId: employee.employeeId,
                          jobStageInterviewSeatId: seatId,
                          preferenceLevel: employee.preferenceLevel,
                        } as JobStageInterviewSeatEmployeeInput)
                    ),
                  } as FreeformSeatInput)
                : undefined,
            } as JobStageInterviewSeatInput;
          }),
        } as JobStageInterviewInput;
      }),
    };
  });

  const input: JobStageInterviewPlanUpsertInput = {
    jobStageId,
    groups,
    schedulingWindow: interviewPlan?.schedulingWindow?.seconds
      ? { seconds: interviewPlan.schedulingWindow?.seconds }
      : undefined,
    excludedEmployeeIds: interviewPlan.excludedEmployees?.map((employee) => employee.id),
  };

  return input;
};

// TODO: How do we gaurantee this is the fragment passed?
getSaveInterviewPlanInput.fragments = {
  interviewPlan: gql`
    ${InterviewPlan.fragments.jobStage}
    fragment getSaveInterviewPlanInput_interviewPlan on JobStage {
      id
      ...InterviewPlan_jobStage
    }
  `,
};

export default getSaveInterviewPlanInput;
