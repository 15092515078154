/* eslint-disable max-lines */
import React, { useMemo } from 'react';

import { gql } from '@apollo/client';
import { getLocalTimezone } from '@modernloop/shared/datetime';
import { minutesInHour } from 'date-fns';

import {
  Availability_ApplicationFragment,
  Availability_AvailabilityFragment,
  Availability_AvailabilityRequestFragment,
  Availability_CandidateFragment,
  Availability_InterviewPlanFragment,
  Availability_JobStageFragment,
  Availability_ScheduleFragment,
  Maybe,
  TaskStatus,
} from 'src/generated/mloop-graphql';

import { Props as IconProps } from 'src/components/icons/Icon';
import { FCWithFragments } from 'src/components/types';
import { IconType } from 'src/components/utils/icons';

import { useIsOnCurrentAts } from 'src/hooks/atsService';
import { useFlag } from 'src/hooks/feature-flag';

import {
  DEFAULT_MINIMUM_TIME_BLOCK_MINUTES,
  DEFAULT_MINUTES_PER_DAY,
  DEFAULT_NUMBER_OF_DAYS,
} from 'src/store/slices/candidate-availability-options';

import { isDayBreak } from 'src/utils/interview';

import CandidateAvailabilityOptionsModal from 'src/views-new/CandidateAvailabilityOptions/CandidateAvailabilityOptionsModalWrapper';
import BaseCandidateAvailabilitySection from 'src/views-new/CandidateDetails/CandidateAvailabilitySection';
import { CandidateAvailabilityFragment } from 'src/views-new/CandidateDetails/useCandidateDetailsQueries';
import { JobStageInterviewPlanFragment } from 'src/views-new/InterviewPlan/UseFetchInterviewPlan';
import {
  DAY_CALC_FROM_INTERVIEW_PLAN,
  DURATION_CALC_FROM_INTERVIEW_PLAN,
} from 'src/views-new/JobDetailsPage/OrgSelfScheduleOptions';
import { NumberOfDays } from 'src/views-new/SelfSchedule/TimeframePicker';

import RequirementContainer from '../Common/RequirementContainer';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type AvailabilityProps = {
  taskId: string;
  selfScheduleRequestId: string | undefined;
  status?: TaskStatus;
  availabilityRequestedAfterSchedule: boolean;
  availabilityReceivedAfterSchedule: boolean;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type AvailabilityFragmentProps = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line modernloop/component-with-fragments.cjs
  application: Availability_ApplicationFragment;
  availability?: Maybe<Availability_AvailabilityFragment>;
  availabilityRequest?: Maybe<Availability_AvailabilityRequestFragment>;
  candidate: Availability_CandidateFragment;
  jobStage: Availability_JobStageFragment;
  interviewPlan?: Maybe<Availability_InterviewPlanFragment>;
  schedule?: Maybe<Availability_ScheduleFragment>;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restric-fragments-name.cjs
const Availability: FCWithFragments<AvailabilityFragmentProps, AvailabilityProps> = ({
  taskId,
  application,
  availability,
  availabilityRequest,
  candidate,
  jobStage,
  interviewPlan,
  schedule,
  selfScheduleRequestId,
  status,
  availabilityRequestedAfterSchedule,
  availabilityReceivedAfterSchedule,
}) => {
  const isCurrentAts = useIsOnCurrentAts(candidate.atsType || undefined);
  const ignoreRollingDaysSetting = useFlag('user_ignore_rolling_window_setting');

  const candidateAvailabilityOptionSettings = jobStage?.jobStageSettings?.candidateAvailabilityOptions || null;

  const { numberOfDays, minutesPerDays, minimumTimeBlockMinutes } = useMemo(() => {
    let numOfDays = DEFAULT_NUMBER_OF_DAYS;
    let minsPerDays = 0;
    let minTimeBlockMinutes = 0;

    const jobStageInterviewsGroups = interviewPlan?.jobStageInterviewGroups;

    const interviews = jobStageInterviewsGroups?.map((group) => group?.jobStageInterviews).flat();

    if (interviews && interviews.length) {
      interviews.forEach((interview) => {
        if (interview?.interviewType && isDayBreak(interview?.interviewType)) {
          numOfDays += 1;
        }

        if (interview?.duration && minTimeBlockMinutes < interview?.duration) {
          minTimeBlockMinutes = interview.duration;
        }

        minsPerDays += interview?.duration || 0;
      });
    } else {
      minsPerDays = DEFAULT_MINUTES_PER_DAY;
      minTimeBlockMinutes = DEFAULT_MINIMUM_TIME_BLOCK_MINUTES;
    }

    const cao = candidateAvailabilityOptionSettings;
    return {
      numberOfDays:
        !!cao?.numberOfDays && cao?.numberOfDays !== DAY_CALC_FROM_INTERVIEW_PLAN ? cao?.numberOfDays : numOfDays,
      // Round off to nearest multiple of 30 mins
      minutesPerDays:
        !!cao?.minutesPerDays && cao?.minutesPerDays !== DURATION_CALC_FROM_INTERVIEW_PLAN
          ? cao?.minutesPerDays
          : Math.ceil(minsPerDays / 30) * 30,
      minimumTimeBlockMinutes:
        !!cao?.minimumTimeBlockMinutes && cao?.minimumTimeBlockMinutes !== DURATION_CALC_FROM_INTERVIEW_PLAN
          ? cao?.minimumTimeBlockMinutes
          : Math.ceil(minTimeBlockMinutes / 30) * 30,
    };
  }, [interviewPlan, candidateAvailabilityOptionSettings]);

  const iconInfo = useMemo((): { type: IconType; props: IconProps } => {
    if (availabilityRequest?.externalId) {
      if (availabilityReceivedAfterSchedule) {
        return { type: 'CircleWithCheckIcon', props: { color: 'success' } };
      }
      if (availabilityRequestedAfterSchedule) {
        return { type: 'SendIcon', props: { color: 'pending' } };
      }
      return availability?.candidateEnteredAvailability?.length || availability?.rcEnteredAvailability?.length
        ? { type: 'CircleWithCheckIcon', props: { color: 'success' } }
        : { type: 'SendIcon', props: { color: 'pending' } };
    }
    if (schedule?.id || status === TaskStatus.ReadyToSchedule) {
      return { type: 'CircleWithCheckIcon', props: { color: 'success' } };
    }
    return { type: 'RadioIcon', props: {} };
  }, [
    availability?.candidateEnteredAvailability?.length,
    availability?.rcEnteredAvailability?.length,
    availabilityRequest?.externalId,
    schedule?.id,
    status,
    availabilityRequestedAfterSchedule,
    availabilityReceivedAfterSchedule,
  ]);

  const settings = {
    useRollingDays: ignoreRollingDaysSetting ? true : candidateAvailabilityOptionSettings?.useRollingDays ?? false,
    timeframeNumberOfDays: candidateAvailabilityOptionSettings?.timeframeNumberOfDays ?? NumberOfDays.TwoWeeks,
    canScheduleOverFreeTime: candidateAvailabilityOptionSettings?.canScheduleOverAvailableKeywords ?? false,
    canScheduleOverAvailableKeywords: candidateAvailabilityOptionSettings?.canScheduleOverAvailableKeywords ?? false,
    canScheduleOverRecruitingKeywords: candidateAvailabilityOptionSettings?.canScheduleOverRecruitingKeywords ?? false,
    shouldRespectLoadLimit: candidateAvailabilityOptionSettings?.shouldRespectLoadLimit ?? true,
  };

  const hasAvailability =
    availability?.candidateEnteredAvailability?.length || availability?.rcEnteredAvailability?.length;

  const requirementTitle = useMemo(() => {
    if (schedule?.id && !availabilityRequestedAfterSchedule && !hasAvailability) {
      return 'Collect candidate availability (skipped because interview was scheduled)';
    }

    return 'Collect candidate availability';
  }, [hasAvailability, schedule?.id, availabilityRequestedAfterSchedule]);

  return (
    <RequirementContainer title={requirementTitle} iconType={iconInfo.type} iconProps={iconInfo.props}>
      <BaseCandidateAvailabilitySection
        taskId={taskId}
        application={application}
        availabilityRequest={availabilityRequest}
        applicationId={application.id}
        jobId={application?.jobStage?.job?.id}
        candidateId={candidate.id}
        jobStageName={jobStage.name}
        jobStageId={jobStage.id}
        interviewPlanId={interviewPlan?.id}
        candidateTimezone={candidate.timezone || undefined}
        lastApplicationStageCreatedAt={schedule?.createdAt}
        selfScheduleRequestId={selfScheduleRequestId}
        isCurrentAts={isCurrentAts}
        readonly={Boolean(schedule?.id)}
        availabilities={{
          id: availability?.id,
          createdAt: availability?.createdAt,
          updatedAt: availability?.updatedAt,
          lastUpdatedByEmployeeId: availability?.lastUpdatedByEmployeeId,
          lastUpdatedByEmployee: availability?.lastUpdatedByEmployee
            ? {
                id: availability.lastUpdatedByEmployee.id,
                fullName: availability.lastUpdatedByEmployee.fullName || '',
              }
            : undefined,
          candidateEnteredAvailability:
            availability?.candidateEnteredAvailability?.map((candidateEnteredAvailability) => ({
              end: candidateEnteredAvailability.endAt,
              start: candidateEnteredAvailability.startAt,
            })) || [],
          rcEnteredAvailability:
            availability?.rcEnteredAvailability?.map((rcEnteredAvailability) => ({
              end: rcEnteredAvailability.endAt,
              start: rcEnteredAvailability.startAt,
            })) || [],
          candidateTimezone: candidate.timezone || undefined,
          employeeUpdatedAt: availability?.employeeUpdatedAt,
          candidateUpdatedAt: availability?.candidateUpdatedAt,
        }}
        options={
          availabilityRequest
            ? {
                id: availabilityRequest.id,
                applicationId: availabilityRequest.applicationId,
                jobStageId: availabilityRequest.jobStageId,
                taskId,
                numberOfDays: availabilityRequest.numberOfDays,
                minutesPerDays: availabilityRequest.minutesPerDays,
                minimumTimeBlockMinutes: availabilityRequest.minimumTimeBlockMinutes,
                timezone: availabilityRequest.timezone,
                availabilityStartDate: availabilityRequest.availabilityStartDate,
                availabilityEndDate: availabilityRequest.availabilityEndDate,
                advanceNoticeMinutes: availabilityRequest.advanceNoticeMinutes,
                candidateNote: availabilityRequest.candidateNote || undefined,
                externalId: availabilityRequest.externalId,
                createdAt: availabilityRequest.createdAt,
                updatedAt: availabilityRequest.updatedAt,
                lastCandidateCommunicationSentAt: availabilityRequest.lastCandidateCommunicationSentAt,
                creator: availabilityRequest.creator?.fullName || undefined,
                shouldRespectLoadLimit: availabilityRequest.shouldRespectLoadLimit,
                canScheduleOverAvailableKeywords: availabilityRequest.suggestOverAvailableKeywords,
                canScheduleOverFreeTime: availabilityRequest.suggestOverFreeTime,
                canScheduleOverRecruitingKeywords: availabilityRequest.suggestOverRecruitingKeywords,
                useRollingDays: Boolean(availabilityRequest.rollingDays),
                timeframeNumberOfDays: ignoreRollingDaysSetting
                  ? availabilityRequest.rollingDays || NumberOfDays.Custom
                  : availabilityRequest.rollingDays,
                inclusionDays: availabilityRequest.inclusionDays,
              }
            : {
                applicationId: application.id,
                jobStageId: jobStage.id,
                taskId,
                numberOfDays,
                minutesPerDays,
                minimumTimeBlockMinutes,
                advanceNoticeMinutes: candidateAvailabilityOptionSettings?.advanceNoticeMinutes || minutesInHour * 24,
                candidateNote: candidateAvailabilityOptionSettings?.candidateNote || '',
                timezone: candidate.timezone || getLocalTimezone(),
                loading: false,
                error: undefined,
                ...settings,
              }
        }
        availabilityRequestedAfterSchedule={availabilityRequestedAfterSchedule}
        availabilityReceivedAfterSchedule={availabilityReceivedAfterSchedule}
        settings={jobStage?.jobStageSettings}
      />
    </RequirementContainer>
  );
};

Availability.fragments = {
  application: gql`
    ${BaseCandidateAvailabilitySection.fragments.application}
    fragment Availability_application on Application {
      id
      jobStage {
        id
        job {
          id
        }
      }
      ...BaseCandidateAvailabilitySection_application
    }
  `,
  availability: gql`
    ${CandidateAvailabilityFragment}
    fragment Availability_availability on CandidateAvailability {
      ...CandidateAvailabilityFragment
    }
  `,
  availabilityRequest: gql`
    ${BaseCandidateAvailabilitySection.fragments.availabilityRequest}
    fragment Availability_availabilityRequest on CandidateAvailabilityOptions {
      id
      applicationId
      availabilityEndDate
      availabilityStartDate
      candidateNote
      externalId
      createdAt
      jobStageId
      minimumTimeBlockMinutes
      minutesPerDays
      numberOfDays
      timezone
      updatedAt
      lastCandidateCommunicationSentAt
      advanceNoticeMinutes
      creator {
        fullName
      }
      shouldRespectLoadLimit
      suggestOverRecruitingKeywords
      suggestOverAvailableKeywords
      suggestOverFreeTime
      rollingDays
      inclusionDays
      ...BaseCandidateAvailabilitySection_availabilityRequest
    }
  `,
  candidate: gql`
    fragment Availability_candidate on Candidate {
      id
      coordinatorId
      recruiterId
      timezone
      atsType
    }
  `,
  jobStage: gql`
    ${CandidateAvailabilityOptionsModal.fragments.settings}
    fragment Availability_jobStage on JobStage {
      id
      name
      jobStageSettings {
        ...CandidateAvailabilityOptionsSettings_JobStageSettings
      }
    }
  `,
  interviewPlan: gql`
    ${JobStageInterviewPlanFragment}
    fragment Availability_interviewPlan on JobStage {
      ...JobStage_InterviewPlan
    }
  `,
  schedule: gql`
    fragment Availability_schedule on ApplicationStage {
      id
      createdAt
    }
  `,
};

export default Availability;
