import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CircleWithQuestionIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 16 16'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM7.93999 5.75C7.6899 5.75 7.49978 5.87023 7.38746 6.05142C7.16921 6.40347 6.70689 6.51194 6.35484 6.29369C6.00278 6.07544 5.89432 5.61312 6.11257 5.26107C6.48665 4.65765 7.14881 4.25 7.93999 4.25C9.18959 4.25 10.0025 5.29161 10.0025 6.31249C10.0025 6.77829 9.88125 7.15993 9.65351 7.47242C9.45223 7.74861 9.19461 7.92668 9.03916 8.03413L9.02316 8.04519C8.8384 8.17311 8.77976 8.2198 8.7415 8.26998L8.74095 8.27071C8.72678 8.28926 8.68999 8.33744 8.68999 8.5C8.68999 8.91421 8.3542 9.25 7.93999 9.25C7.52577 9.25 7.18999 8.91421 7.18999 8.5C7.18999 8.0402 7.31626 7.66535 7.54863 7.36056C7.74337 7.10512 7.99223 6.93384 8.14076 6.83162L8.16931 6.81193C8.34415 6.69088 8.40355 6.64074 8.44129 6.58896C8.45964 6.56378 8.50248 6.50098 8.50248 6.31249C8.50248 6.02088 8.26537 5.75 7.93999 5.75ZM8.75001 10.75C8.75001 11.1642 8.41423 11.5 8.00001 11.5C7.5858 11.5 7.25001 11.1642 7.25001 10.75C7.25001 10.3358 7.5858 10 8.00001 10C8.41423 10 8.75001 10.3358 8.75001 10.75Z"
        fill="fillColor"
      />
    </SvgIcon>
  );
};
