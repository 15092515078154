import React from 'react';

import { TaskStatus } from 'src/generated/mloop-graphql';

import Tag, { TagProps } from 'src/components/Tag';

import { getColorForTaskStatus, getDisplayStringForTaskStatus } from 'src/views-new/ScheduleTaskDashboard/utils';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type ScheduleTaskStatusProps = {
  status: TaskStatus;
  size?: TagProps['size'];
};

const ScheduleTaskStatus = ({ status, size }: ScheduleTaskStatusProps) => {
  const label = getDisplayStringForTaskStatus(status);
  const color = getColorForTaskStatus(status);

  return (
    <Tag
      size={size}
      variant="filled"
      color={color}
      label={label}
      invertedColor={
        status === TaskStatus.Canceled ||
        status === TaskStatus.ReadyToSendToCandidate ||
        status === TaskStatus.Completed
      }
    />
  );
};

export default ScheduleTaskStatus;
