/* eslint-disable max-lines */
import React from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { IsoTimestamp, assertIsoTimestamp, isBefore } from '@modernloop/shared/datetime';
import { WarningIcon } from '@modernloop/shared/icons';
import { endOfToday, startOfToday } from 'date-fns';

import {
  EventsWithConflicts,
  GetTextForFlag_CompanyHolidayFragment,
  InterviewerFlag,
} from 'src/generated/mloop-graphql';

import ImageBlock from 'src/components/ImageBlock';
import {
  CalendarIcon,
  CheckIcon,
  CopyIcon,
  DayIcon,
  DurationIcon,
  ModulesIcon,
  MonthIcon,
  OvernightIcon,
  PauseIcon,
  ScheduleIcon,
  TimeIcon,
  TimezoneIcon,
  TraineeIcon,
  UserOptionalIcon,
  WeekIcon,
} from 'src/components/icons';
import CompanyErrorHolidayIcon from 'src/components/icons/CompanyHolidayIcon';
import Label from 'src/components/label';

import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';
import logError from 'src/utils/logError';

export { default as renderEventTimeRange } from './renderEventTimeRange';

export type InterviewerFlagSeverity = 'ignore' | 'success' | 'warning' | 'error';

export const isLinkedSeat = (flags: InterviewerFlag[]): boolean => {
  return (flags || []).includes(InterviewerFlag.Linked);
};

export const isShadowTrainee = (flags: InterviewerFlag[]): boolean => {
  return (flags || []).includes(InterviewerFlag.Shadow);
};

export const isReverseShadowTrainee = (flags: InterviewerFlag[]): boolean => {
  return (flags || []).includes(InterviewerFlag.ReverseShadow);
};

export const isTrainee = (flags: InterviewerFlag[]): boolean => {
  return isShadowTrainee(flags) || isReverseShadowTrainee(flags);
};

const renderFlagAndEvents = (flagLabel: string, eventListString: string) => {
  return eventListString ? `${flagLabel}: ${eventListString}` : flagLabel;
};

const filterEventsWithFlag = (flag: InterviewerFlag, eventsWithConflicts: EventsWithConflicts[]) => {
  for (const eventsWithConflict of eventsWithConflicts) {
    if (eventsWithConflict.flag === flag) {
      return eventsWithConflict.events || [];
    }
  }
  return [];
};

export const isEventBasedFlag = (flag: InterviewerFlag): boolean => {
  return [
    InterviewerFlag.Conflict,
    InterviewerFlag.InsideRecruitingBlock,
    InterviewerFlag.UnschedulableConflict,
    InterviewerFlag.SchedulableConflict,
    InterviewerFlag.HoldConflict,
    InterviewerFlag.OneOnOneConflict,
    InterviewerFlag.ExternalConflict,
  ].includes(flag);
};

type TextForFlagFragment = {
  companyHoliday: GetTextForFlag_CompanyHolidayFragment | null;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type GetTextForFlagProps = {
  flag: InterviewerFlag;
  eventsWithConflict: EventsWithConflicts[];
};

export const getTextForFlag: FunctionWithFragments<TextForFlagFragment, GetTextForFlagProps, string | null> = (
  { companyHoliday },
  { flag, eventsWithConflict }
) => {
  const eventListString = filterEventsWithFlag(flag, eventsWithConflict).join(', ');
  switch (flag) {
    case InterviewerFlag.Conflict:
      return renderFlagAndEvents('Conflict', eventListString);
    case InterviewerFlag.Ooo:
      return renderFlagAndEvents('Out of office', eventListString);
    case InterviewerFlag.UnschedulableConflict:
      return renderFlagAndEvents('Matches "avoid" keywords', eventListString);
    case InterviewerFlag.SchedulableConflict:
      return renderFlagAndEvents('Matches "available" keywords', eventListString);
    case InterviewerFlag.HoldConflict:
      return renderFlagAndEvents('Calendar hold', eventListString);
    case InterviewerFlag.OneOnOneConflict:
      return renderFlagAndEvents('One on one', eventListString);
    case InterviewerFlag.ExternalConflict:
      return renderFlagAndEvents('External meeting', eventListString);
    case InterviewerFlag.InterviewConflict:
      return renderFlagAndEvents('Interview conflict', eventListString);
    case InterviewerFlag.OutsideWorkHours:
      return 'Outside work hours';
    case InterviewerFlag.OverWeeklyModuleLimit:
      return 'Module limit exceeded for the week';
    case InterviewerFlag.MiddleOfTheNight:
      return 'Outside work hours (night time)';
    case InterviewerFlag.OutsideWorkHoursBreakTime:
      return 'During break time';
    case InterviewerFlag.OutsideWorkHours_30:
      return 'Outside work hours by 30 min';
    case InterviewerFlag.OutsideWorkHours_60:
      return 'Outside work hours by 1 hr';
    case InterviewerFlag.OutsideWorkHours_90:
      return 'Outside work hours by 1 hr 30 min';
    case InterviewerFlag.OutsideWorkHours_120:
      return 'Outside work hours by 2 hours';
    case InterviewerFlag.MissingCalendar:
      return 'Missing calendar information';
    case InterviewerFlag.MissingTimezone:
      return 'Missing timezone information';
    case InterviewerFlag.OutsideRecruitingBlock:
      return "Outside interviewer's recruiting block";
    case InterviewerFlag.OverDailyLimit:
      return 'Daily interview load exceeded';
    case InterviewerFlag.OverWeeklyLimit:
      return 'Weekly interview load exceeded';
    case InterviewerFlag.Paused:
      return 'Paused from all interviews';
    case InterviewerFlag.Repeat:
      return 'Interviewer is repeated on schedule';
    case InterviewerFlag.Weekend:
      return "Interview is on interviewer's weekend";
    case InterviewerFlag.InsideRecruitingBlock:
      return "Scheduled inside interviewer's recruiting block";
    case InterviewerFlag.PausedInModule:
      return 'Paused in module';
    case InterviewerFlag.Preferred:
      return 'Selected as a preferred interviewer';
    case InterviewerFlag.NotPreferred:
      return 'Not a preferred interviewer';
    case InterviewerFlag.Linked:
    case InterviewerFlag.ReverseShadow:
    case InterviewerFlag.Shadow:
    case InterviewerFlag.Required:
      return null;
    case InterviewerFlag.CompanyCountryHoliday:
      if (companyHoliday?.name) {
        return `Company day off: ${companyHoliday.name}`;
      }
      return '';
    case InterviewerFlag.ConflictOfInterestWithCandidate:
      return 'Conflict of interest with candidate';
    case InterviewerFlag.PreviousInterviewsForApplication:
      return 'Repeat interviewer: Previously interviewed this candidate';
    default:
      logError(new Error(`unknown flag in getTextForFlag ${flag}`));
      return flag || null;
  }
};
getTextForFlag.fragments = {
  companyHoliday: gql`
    fragment getTextForFlag_companyHoliday on CompanyHoliday {
      id
      name
      startAt
    }
  `,
};

export const getIconForFlag = (flag: InterviewerFlag): JSX.Element | null => {
  switch (flag) {
    case InterviewerFlag.Ooo:
    case InterviewerFlag.Conflict:
    case InterviewerFlag.UnschedulableConflict:
    case InterviewerFlag.SchedulableConflict:
    case InterviewerFlag.ExternalConflict:
    case InterviewerFlag.InterviewConflict:
      return <ScheduleIcon />;
    case InterviewerFlag.HoldConflict:
      return <UserOptionalIcon />;
    case InterviewerFlag.PreviousInterviewsForApplication:
    case InterviewerFlag.ConflictOfInterestWithCandidate:
      return <UserOptionalIcon color="error" />;
    case InterviewerFlag.OneOnOneConflict:
      return <TraineeIcon />;
    case InterviewerFlag.OutsideRecruitingBlock:
      return <DurationIcon />;
    case InterviewerFlag.MissingCalendar:
      return <MonthIcon />;
    case InterviewerFlag.MissingTimezone:
      return <TimezoneIcon />;
    case InterviewerFlag.OverDailyLimit:
      return <DayIcon />;
    case InterviewerFlag.OverWeeklyLimit:
      return <WeekIcon />;
    case InterviewerFlag.Paused:
    case InterviewerFlag.Weekend:
      return <OvernightIcon />;
    case InterviewerFlag.Repeat:
      return <CopyIcon />;
    case InterviewerFlag.OutsideWorkHours:
    case InterviewerFlag.MiddleOfTheNight:
    case InterviewerFlag.OutsideWorkHoursBreakTime:
    case InterviewerFlag.OutsideWorkHours_30:
    case InterviewerFlag.OutsideWorkHours_60:
    case InterviewerFlag.OutsideWorkHours_90:
    case InterviewerFlag.OutsideWorkHours_120:
      return <CalendarIcon />;
    case InterviewerFlag.InsideRecruitingBlock:
      return <TimeIcon />;
    case InterviewerFlag.PausedInModule:
      return <PauseIcon />;
    case InterviewerFlag.OverWeeklyModuleLimit:
      return <ModulesIcon />;
    case InterviewerFlag.Preferred:
      return <CheckIcon />;
    case InterviewerFlag.NotPreferred:
      return <WarningIcon fontSize="small" />;
    case InterviewerFlag.CompanyCountryHoliday:
      return <CompanyErrorHolidayIcon />;
    case InterviewerFlag.Linked:
    case InterviewerFlag.ReverseShadow:
    case InterviewerFlag.Shadow:
    case InterviewerFlag.Required:
    default:
      return null;
  }
};

const getImageBlockIconColor = (flag: InterviewerFlag) => {
  switch (flag) {
    case InterviewerFlag.CompanyCountryHoliday:
      return 'error';
    default:
      return 'max-contrast-grey';
  }
};

const imageBlockForFlag = (
  flag: InterviewerFlag,
  allEvents: EventsWithConflicts[],
  companyHoliday?: GetTextForFlag_CompanyHolidayFragment
): // eslint-disable-next-line max-params
JSX.Element => {
  const icon = getIconForFlag(flag);
  const title = getTextForFlag({ companyHoliday: companyHoliday || null }, { flag, eventsWithConflict: allEvents });
  if (!title) return <></>;
  return (
    <ImageBlock
      key={flag}
      image={
        icon
          ? React.cloneElement(icon, {
              color: getImageBlockIconColor(flag),
            })
          : undefined
      }
      title={
        <Label variant="captions" color="max-contrast-grey">
          {title}
        </Label>
      }
    />
  );
};

export const getImageBlockForFlag = (
  flag: InterviewerFlag,
  allEvents: EventsWithConflicts[],
  companyHolidays?: GetTextForFlag_CompanyHolidayFragment[]
): // eslint-disable-next-line max-params
JSX.Element | JSX.Element[] | null => {
  if (flag === InterviewerFlag.CompanyCountryHoliday && companyHolidays?.length) {
    return companyHolidays.map((holiday) => imageBlockForFlag(flag, allEvents, holiday));
  }
  return imageBlockForFlag(flag, allEvents);
};

export const getInterviewerFlagSeverity = (flag: InterviewerFlag): InterviewerFlagSeverity => {
  switch (flag) {
    case InterviewerFlag.Conflict:
    case InterviewerFlag.MissingCalendar:
    case InterviewerFlag.MissingTimezone:
    case InterviewerFlag.OutsideRecruitingBlock:
    case InterviewerFlag.OutsideWorkHours:
    case InterviewerFlag.ExternalConflict:
    case InterviewerFlag.Ooo:
    case InterviewerFlag.OverDailyLimit:
    case InterviewerFlag.OverWeeklyLimit:
    case InterviewerFlag.Paused:
    case InterviewerFlag.Repeat:
    case InterviewerFlag.Weekend:
    case InterviewerFlag.UnschedulableConflict:
    case InterviewerFlag.InterviewConflict:
    case InterviewerFlag.PausedInModule:
    case InterviewerFlag.CompanyCountryHoliday:
    case InterviewerFlag.ConflictOfInterestWithCandidate:
    case InterviewerFlag.PreviousInterviewsForApplication:
    case InterviewerFlag.MiddleOfTheNight: {
      return 'error';
    }

    case InterviewerFlag.HoldConflict:
    case InterviewerFlag.OneOnOneConflict:
    case InterviewerFlag.OutsideWorkHoursBreakTime:
    case InterviewerFlag.OutsideWorkHours_30:
    case InterviewerFlag.OutsideWorkHours_60:
    case InterviewerFlag.OutsideWorkHours_90:
    case InterviewerFlag.OutsideWorkHours_120:
    case InterviewerFlag.OverWeeklyModuleLimit:
    case InterviewerFlag.NotPreferred:
      return 'warning';

    case InterviewerFlag.SchedulableConflict:
    case InterviewerFlag.InsideRecruitingBlock:
    case InterviewerFlag.Preferred:
      return 'success';

    case InterviewerFlag.Linked:
    case InterviewerFlag.ReverseShadow:
    case InterviewerFlag.Shadow:
    case InterviewerFlag.Required:
      return 'ignore';

    default:
      logError(new Error(`unknown flag in getInterviewerFlagSeverity ${flag}`));
      return 'error';
  }
};

export const getMaxInterviewerFlagSeverity = (flags: InterviewerFlag[]): InterviewerFlagSeverity => {
  let maxSeverity: InterviewerFlagSeverity = 'ignore';
  for (const flag of flags) {
    const severity = getInterviewerFlagSeverity(flag);
    if (severity === 'error') {
      return 'error';
    }
    if (severity === 'warning') {
      maxSeverity = 'warning';
    }
  }
  return maxSeverity;
};

export const getMinStartAtAndMaxEndAtFormScheduleOptions = (
  schedules: InterviewSchedule[]
): { minStartAt: IsoTimestamp; maxEndAt: IsoTimestamp } => {
  if (!schedules.length) {
    return {
      minStartAt: assertIsoTimestamp(startOfToday().toISOString()),
      maxEndAt: assertIsoTimestamp(endOfToday().toISOString()),
    };
  }

  let minStartAt = schedules[0]?.events[0]?.startAt;
  let maxEndAt = schedules[0]?.events[0]?.endAt;

  schedules?.forEach((item) => {
    item.events.forEach((event) => {
      const { startAt, endAt } = event;

      if (isBefore(assertIsoTimestamp(startAt), assertIsoTimestamp(minStartAt))) {
        minStartAt = startAt;
      }

      if (isBefore(assertIsoTimestamp(maxEndAt), assertIsoTimestamp(endAt))) {
        maxEndAt = endAt;
      }
    });
  });

  return { minStartAt: assertIsoTimestamp(minStartAt), maxEndAt: assertIsoTimestamp(maxEndAt) };
};
