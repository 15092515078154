import React from 'react';

import { gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';

import { InterviewMeetingLocationType } from 'src/generated/mloop-graphql';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { MeetingLinksConfig } from 'src/store/slices/schedule-communications';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import VideoMeetingLinkMultiple from './VideoMeetingLinkMultiple';
import VideoMeetingLinkSingle from './VideoMeetingLinkSingle';

type Props = {
  scheduleId: string;
  location: InterviewMeetingLocationType;
  meetingLinksConfig: MeetingLinksConfig | undefined;
  onMeetingLinksConfigChange?: (meetingLinksConfig: MeetingLinksConfig) => void;
};

const VideoMeetingLinks = ({
  scheduleId,
  location,
  meetingLinksConfig = MeetingLinksConfig.One,
  onMeetingLinksConfigChange,
}: Props) => {
  const schedule = useScheduleWithoutBreaks(scheduleId);
  const scheduleLocationEnabled = useFlag('org_schedule_location');

  const handleMeetingLinksConfigChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onMeetingLinksConfigChange) return;
    if (event.target.value === MeetingLinksConfig.One) {
      onMeetingLinksConfigChange(MeetingLinksConfig.One);
    } else if (event.target.value === MeetingLinksConfig.Many) {
      onMeetingLinksConfigChange(MeetingLinksConfig.Many);
    }
  };

  return (
    <ConditionalThemeProvider>
      <Stack px={1}>
        {!scheduleLocationEnabled && schedule && schedule.events.length > 1 && (
          <RadioGroup onChange={handleMeetingLinksConfigChange} sx={{ pb: 1 }}>
            <FormControlLabel
              value={MeetingLinksConfig.One}
              control={<Radio size="small" checked={meetingLinksConfig === MeetingLinksConfig.One} />}
              label="Same link for all interviews"
            />
            <FormControlLabel
              value={MeetingLinksConfig.Many}
              control={<Radio size="small" checked={meetingLinksConfig === MeetingLinksConfig.Many} />}
              label="Different links for each interview"
            />
          </RadioGroup>
        )}

        {meetingLinksConfig === MeetingLinksConfig.One && (
          <VideoMeetingLinkSingle location={location} scheduleId={scheduleId} />
        )}
        {meetingLinksConfig === MeetingLinksConfig.Many && (
          <VideoMeetingLinkMultiple scheduleId={scheduleId} location={location} />
        )}
      </Stack>
    </ConditionalThemeProvider>
  );
};

export default VideoMeetingLinks;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VideoMeetingLinksQuery = gql`
  mutation CalendarMeetingCreate($input: CalendarMeetingCreateInput!) {
    calendarMeetingCreate(input: $input) {
      url
    }
  }
`;
