import React, { useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';

import {
  CreateTaskDialogPage_ApplicationFragment,
  CreateTaskDialogPage_InterviewPlanFragment,
  TaskCreationSource,
} from 'src/generated/mloop-graphql';

import DialogPage, { DialogActions, DialogContent, DialogTitle } from 'src/components/Dialog/DialogPage';
import { FCWithFragments } from 'src/components/types';

import cloneInterviewPlan from 'src/entities/InterviewPlan/cloneInterviewPlan';
import useInterviewPlanEntityHasInterviewHiddenFromCandidate from 'src/entities/InterviewPlan/useInterviewPlanHasInterviewHiddenFromCandidate';
import useIsInterviewPlanEntityValid from 'src/entities/InterviewPlan/useIsInterviewPlanValid';

import useInterviewPlanHasInterviewHiddenFromCandidate from 'src/views-new/InterviewPlan/useInterviewPlanHasInterviewHiddenFromCandidate';
import useIsInterviewPlanValid from 'src/views-new/InterviewPlan/useIsInterviewPlanValid';

import CreateTaskStep from './CreateTaskStep';
import { TaskCreateInputWrapper } from './types';

interface Props {
  title: string;
  input: TaskCreateInputWrapper;
  interviewPlanLoading: boolean;
  source: TaskCreationSource;
  setInput: (input: TaskCreateInputWrapper) => void;
  onContinue: (input: TaskCreateInputWrapper) => void;
  onClose: () => void;
  allowEmptyPlan?: boolean;
  onJobStageIdChange?: (jobStageId: string) => void;
  onInterviewPlanChange: (interviewPlan: CreateTaskDialogPage_InterviewPlanFragment) => void;
}

type Fragments = {
  application: CreateTaskDialogPage_ApplicationFragment; // TODO: Import fragment type from mloop-graphql
  interviewPlan: CreateTaskDialogPage_InterviewPlanFragment;
};

const CreateTaskDialogPage: FCWithFragments<Fragments, Props> = ({
  allowEmptyPlan = false,
  title,
  input,
  interviewPlan,
  interviewPlanLoading,
  application,
  source,
  onClose,
  onContinue,
  onJobStageIdChange,
  onInterviewPlanChange,
  setInput,
}) => {
  const [isImportingAvailability, setIsImportingAvailability] = useState(false);

  const interviewPlanEntityInTaskEnabled = useFlag('interview_plan_entity_in_tasks');

  const isValidInterviewPlanEntity = useIsInterviewPlanEntityValid(
    { jobStage: interviewPlan || { id: input.customJobStageId, jobStageInterviewGroups: [] } },
    {
      isDayBreakAllowed: source === TaskCreationSource.CandidateAvailabilityRequest,
      allowEmptyPlan,
      allowEmptyInterviews: allowEmptyPlan,
      allowAllInterviewsHiddenFromCandiate: true,
      allowDynamicBreaks: source !== TaskCreationSource.SelfScheduleRequest,
    }
  );

  // If customJobStageId is null, we are loading the interview plan
  const hasInterviewPlanLoaded = interviewPlanEntityInTaskEnabled ? !interviewPlanLoading : !!input.customJobStageId;

  const isValidInterviewPlanObj = useIsInterviewPlanValid(input.customJobStageId, {
    isDayBreakAllowed: source === TaskCreationSource.CandidateAvailabilityRequest,
    allowEmptyPlan,
    allowEmptyInterviews: allowEmptyPlan,
    allowAllInterviewsHiddenFromCandiate: true,
  });
  const {
    isValid,
    error: interviewPlanError,
    title: interviewPlanErrorTitle,
  } = interviewPlanEntityInTaskEnabled ? isValidInterviewPlanEntity : isValidInterviewPlanObj;

  const hasInterviewHiddenFromCandidateStore = useInterviewPlanHasInterviewHiddenFromCandidate(input.customJobStageId);
  const hasInterviewHiddenFromCandidateEntity = useInterviewPlanEntityHasInterviewHiddenFromCandidate({
    interviewPlan,
  });
  const hasInterviewHiddenFromCandidate = interviewPlanEntityInTaskEnabled
    ? hasInterviewHiddenFromCandidateEntity
    : hasInterviewHiddenFromCandidateStore;

  const submitOptionTooltip = useMemo(() => {
    if (!isValid) {
      return `${interviewPlanErrorTitle} 𐄁 ${interviewPlanError}`;
    }
    if (hasInterviewHiddenFromCandidate && source === TaskCreationSource.SelfScheduleRequest) {
      return 'Self-schedule is unavailable for interview plans with hidden events';
    }
    return undefined;
  }, [hasInterviewHiddenFromCandidate, interviewPlanError, interviewPlanErrorTitle, isValid, source]);

  return (
    <DialogPage onClose={onClose}>
      <DialogContent>
        <DialogTitle title={title} subTitle="Step 1 out of 3: Create a task to track this request" />
        <CreateTaskStep
          application={application}
          jobStage={interviewPlan || undefined}
          jobStageLoading={interviewPlanLoading}
          onInputChange={(updatedInput) => {
            if (updatedInput.jobStageId !== input.jobStageId) {
              onJobStageIdChange?.(updatedInput.jobStageId);
            }
            setInput(updatedInput);
          }}
          input={input}
          isAvailabilityEnabled={false}
          source={source}
          setIsImportingAvailability={setIsImportingAvailability}
          onInterviewPlanChange={onInterviewPlanChange}
        />
      </DialogContent>
      <DialogActions
        cancelOptions={{
          label: 'Cancel',
          onClick: onClose,
        }}
        submitOptions={{
          isDisabled:
            !isValid ||
            !hasInterviewPlanLoaded ||
            (hasInterviewHiddenFromCandidate && source === TaskCreationSource.SelfScheduleRequest) ||
            isImportingAvailability,
          tooltip: submitOptionTooltip,
          label: 'Continue',
          onClick: () => {
            onContinue(input);
          },
        }}
      />
    </DialogPage>
  );
};

CreateTaskDialogPage.fragments = {
  application: gql`
    ${CreateTaskStep.fragments.application}
    fragment CreateTaskDialogPage_application on Application {
      id
      ...CreateTaskStep_application
      job {
        ...JobStageSelect_job
      }
      jobStage {
        id
      }
      candidate {
        id
        coordinator {
          id
          fullName
        }
        timezone
      }
    }
  `,
  interviewPlan: gql`
    ${CreateTaskStep.fragments.jobStage}
    ${cloneInterviewPlan.fragments.interviewPlan}
    ${useIsInterviewPlanEntityValid.fragments.jobStage}
    ${useInterviewPlanEntityHasInterviewHiddenFromCandidate.fragments.interviewPlan}

    fragment CreateTaskDialogPage_interviewPlan on JobStage {
      id
      ...CreateTaskStep_jobStage
      ...cloneInterviewPlan_interviewPlan
      ...useIsInterviewPlanValid_jobStage
      ...useInterviewPlanHasInterviewHiddenFromCandidate_interviewPlan
    }
  `,
};

export default CreateTaskDialogPage;
