import React from 'react';

import { Stack, Tooltip, Typography } from '@mui/material';

interface Props {
  title: string;
  description: string;
  children: React.ReactElement;
}

export const KeywordTooltip: React.FC<Props> = ({ title, description, children }) => {
  return (
    <Tooltip
      title={
        <Stack gap={1}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="body2">{description}</Typography>
        </Stack>
      }
    >
      {children}
    </Tooltip>
  );
};
