/**
 * Should only trigger when filledTemplate content changes.
 */
import { useEffect } from 'react';

import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';

import { UseInterviewEventTemplateContent_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import { updateInterviewerContentViaIndex } from 'src/store/actions/schedule-communications';
import {
  getAddedCodeLinkTypes,
  getAllCodingUrls,
  getZoomUserId,
  getZoomUserIds,
} from 'src/store/selectors/schedule-communications';

import useScheduleContent from 'src/views-new/ScheduleFlow/Steps/Communications/TemplateContent/useScheduleContent';

import { useDispatch, useSelector } from 'src/store';

type Fragments = {
  interviewPlan: UseInterviewEventTemplateContent_InterviewPlanFragment | undefined;
};

type Props = {
  eventId: string;
  templateId?: string;
  slotId?: string;
};

const useInterviewEventTemplateContent: FunctionWithFragments<Fragments, Props, boolean> = (
  { interviewPlan },
  { eventId, templateId, slotId }
) => {
  const dispatch = useDispatch();
  const {
    filledTemplate,
    selectedScheduleId,
    schedule,
    zoomInfo,
    zoomInfos,
    customVideoMeetingLinkUrl,
    customVideoMeetingLinkUrls,
    videoMeetingLinkUrls,
    workspaceVideoLink,
    loading,
  } = useScheduleContent({ interviewPlan }, { templateId, slotId }) || {};
  const zoomUserId = useSelector((state) => getZoomUserId(state, selectedScheduleId || ''));
  const zoomUserIds = useSelector((state) => getZoomUserIds(state, selectedScheduleId || ''));

  const codeLinkTypes = useSelector((state) => getAddedCodeLinkTypes(state, selectedScheduleId || ''));
  const allCodingUrlsByEvent = useSelector((state) => getAllCodingUrls(state, selectedScheduleId || ''));

  useEffect(() => {
    if (templateId && filledTemplate && selectedScheduleId && slotId) {
      let codingInterviewURL: string | null = null;
      if (codeLinkTypes.length && allCodingUrlsByEvent) {
        codingInterviewURL = allCodingUrlsByEvent[codeLinkTypes[0]]?.[slotId] || null;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const event = schedule?.events?.find((e) => e.slotId === slotId) || ({} as any);
      dispatch(
        updateInterviewerContentViaIndex(
          selectedScheduleId,
          {
            event,
            emails: schedule?.events
              ?.find((e) => e.slotId === slotId)
              ?.interviewers.map((i) => i.employee.email || '')
              .filter(Boolean) as string[],
            summary: filledTemplate?.subject || '',
            description: filledTemplate?.body || '',
            location:
              (customVideoMeetingLinkUrls && customVideoMeetingLinkUrls[slotId]) ||
              customVideoMeetingLinkUrl ||
              (zoomInfos && zoomInfos[slotId]?.joinURL) ||
              zoomInfo?.joinURL ||
              (videoMeetingLinkUrls && videoMeetingLinkUrls[slotId]) ||
              workspaceVideoLink ||
              '',
            zoomUserId: (zoomUserIds && zoomUserIds[slotId]) || zoomUserId || '',
            zoomInfo: (zoomInfos && zoomInfos[slotId]) || zoomInfo,
            emailTemplateID: templateId,
            codingInterviewURL,
            customVideoMeetingUrl:
              customVideoMeetingLinkUrls && slotId
                ? customVideoMeetingLinkUrls[slotId]
                : customVideoMeetingLinkUrl || '',
            slotId,
            isCoderPad: true, // This is always true even in production.
          },
          eventId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, eventId, slotId, filledTemplate, selectedScheduleId]);

  return loading;
};

useInterviewEventTemplateContent.fragments = {
  interviewPlan: gql`
    ${useScheduleContent.fragments.interviewPlan}
    fragment useInterviewEventTemplateContent_interviewPlan on JobStage {
      id
      ...useScheduleContent_interviewPlan
    }
  `,
};

export default useInterviewEventTemplateContent;
