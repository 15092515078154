import React, { FC, useState } from 'react';

import { SearchIcon } from '@modernloop/shared/icons';
import { Stack, TextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { InterviewModuleMemberState, TrainingStatus } from 'src/generated/mloop-graphql';

import FilterDropdown, { OptionInterface } from 'src/components/FilterDropdown';

import { DEBOUNCE_TIMEOUT } from 'src/constants';

export const roleOptions: OptionInterface<TrainingStatus>[] = [
  {
    label: 'Shadow',
    value: TrainingStatus.Shadow,
  },
  {
    label: 'Reverse Shadow',
    value: TrainingStatus.ReverseShadow,
  },
  {
    label: 'Trained',
    value: TrainingStatus.Trained,
  },
];

export const statusOptions: OptionInterface<InterviewModuleMemberState>[] = [
  {
    label: 'Paused',
    value: InterviewModuleMemberState.Paused,
  },
  {
    label: 'Needs approval',
    value: InterviewModuleMemberState.NeedsApproval,
  },
];

interface Props {
  onRolesFilter: (rolesFilter: TrainingStatus[]) => void;
  onStatusFilter: (status: InterviewModuleMemberState[]) => void;
  onSearch: (val: string) => void;
}

const Filters: FC<Props> = ({ onRolesFilter, onStatusFilter, onSearch }) => {
  const [search, setSearch] = useState<string>('');

  const debouncedHandleSearch = useDebouncedCallback(onSearch, DEBOUNCE_TIMEOUT);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debouncedHandleSearch(e.target.value);
  };

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <TextField
        size="small"
        variant="outlined"
        placeholder="Search"
        value={search}
        type="text"
        onChange={handleSearch}
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
      />
      <FilterDropdown
        defaultText="Role"
        options={roleOptions}
        onChange={onRolesFilter}
        getLabel={(values) => {
          if (values.length === 1) {
            return roleOptions.find((option) => option.value === values[0])?.label || '';
          }
          return `${values.length} roles`;
        }}
      />
      <FilterDropdown
        defaultText="Status"
        options={statusOptions}
        onChange={onStatusFilter}
        getLabel={(values) => {
          if (values.length === 1) {
            return statusOptions.find((option) => option.value === values[0])?.label || '';
          }
          return `${values.length} statuses`;
        }}
      />
    </Stack>
  );
};

export default Filters;
