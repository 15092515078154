import React, { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box, Grid, Theme, makeStyles } from '@material-ui/core';

import Paper from 'src/components/Paper';
import Checkbox from 'src/components/checkbox';
import Label from 'src/components/label';

import {
  updateCandidateCalendarId,
  updateInterviewerCalendarId,
  updateIsPrivateCalendarEvent,
} from 'src/slices/scheduling';

import {
  getCandidateCalendarId,
  getInterviewerCalendarId,
  getIsPrivateCalendarEvent,
} from 'src/store/selectors/scheduling';

import { Theme as ThemeV5 } from 'src/themeMui5/type';

import { useDispatch, useSelector } from 'src/store';

import CalendarSelect from './CalendarSelect';
import PersonalCalendarAlert from './PersonalCalendarAlert';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  eventHeader: {
    color: theme.palette.primary.dark,
    paddingBottom: theme.spacing(1),
  },
  alert: {
    marginTop: theme.spacing(1),
  },
}));

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface CalendarCardProps {
  candidateCalendarId?: string | null;
  interviewerCalendarId?: string | null;
  isPrivateEvent?: boolean;
  onCandidateCalendarChange?: (string) => void;
  onInterviewerCalendarChange?: (string) => void;
  onPrivacyChange?: (bool) => void;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const CalendarCard: FC<CalendarCardProps> = ({
  candidateCalendarId,
  interviewerCalendarId,
  isPrivateEvent,
  onCandidateCalendarChange,
  onInterviewerCalendarChange,
  onPrivacyChange,
}) => {
  const classes = useStyles();

  return (
    <Paper color="alternate">
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Box pb={2}>
            <Label variant="body" fontWeight={600}>
              Calendars
            </Label>
          </Box>
        </Grid>
        <Grid container item spacing={1} direction="row" justifyContent="space-between">
          {onCandidateCalendarChange && (
            <Grid item xs>
              <Label className={classes.eventHeader} variant="captions" fontWeight={600}>
                Candidate events
              </Label>
              <CalendarSelect
                dataTestId="candidate-calendar-select"
                fullWidth
                onChange={(id) => {
                  if (id) {
                    onCandidateCalendarChange(id);
                  }
                }}
                calendarId={candidateCalendarId}
              />
              <PersonalCalendarAlert
                sx={{ marginTop: (theme: ThemeV5) => theme.spacing(1) }}
                remoteCalendarId={candidateCalendarId || ''}
              />
            </Grid>
          )}
          {onInterviewerCalendarChange && (
            <Grid item xs>
              <Label className={classes.eventHeader} variant="captions" fontWeight={600}>
                Internal events
              </Label>
              <CalendarSelect
                dataTestId="interviewer-calendar-select"
                fullWidth
                onChange={(id) => {
                  if (id) {
                    onInterviewerCalendarChange(id);
                  }
                }}
                calendarId={interviewerCalendarId}
              />
              <PersonalCalendarAlert
                sx={{ marginTop: (theme: ThemeV5) => theme.spacing(1) }}
                remoteCalendarId={interviewerCalendarId || ''}
              />
            </Grid>
          )}
        </Grid>
        {onPrivacyChange && (
          <Grid item>
            <Checkbox
              checked={isPrivateEvent}
              label="Make all candidate and internal events private"
              onChange={(event) => {
                onPrivacyChange(event.target.checked);
              }}
            />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default CalendarCard;

export const CalendarCardStoreWrapper = React.memo(() => {
  const dispatch = useDispatch();
  const candidateCalendarId = useSelector(getCandidateCalendarId);
  const interviewerCalendarId = useSelector(getInterviewerCalendarId);
  const isPrivateCalendarEvent = useSelector(getIsPrivateCalendarEvent);

  return (
    <CalendarCard
      candidateCalendarId={candidateCalendarId}
      interviewerCalendarId={interviewerCalendarId}
      isPrivateEvent={isPrivateCalendarEvent}
      onCandidateCalendarChange={(id) => dispatch(updateCandidateCalendarId(id))}
      onInterviewerCalendarChange={(id) => dispatch(updateInterviewerCalendarId(id))}
      onPrivacyChange={(val) => dispatch(updateIsPrivateCalendarEvent(val))}
    />
  );
});
