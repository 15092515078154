import logMessage from 'src/utils/logMessage';

import { IS_PRODUCTION } from 'src/constants';

type IsoTimestamp = string & {
  __type: 'Time represented in ISO 8601, YYYY-MM-DDTHH:mm:ss.sssZ | YYYY-MM-DDTHH:mm:ssZ format';
};

export default IsoTimestamp;

export const isIsoTimestamp = (input: string): input is IsoTimestamp => {
  const dateMs = Date.parse(input);

  if (Number.isNaN(dateMs)) {
    return false;
  }

  return new Date(input).toISOString() === input || new Date(input).toISOString().replace(/.\d\d\dZ$/g, 'Z') === input;
};

export const assertIsoTimestamp = (input: string): IsoTimestamp => {
  if (!isIsoTimestamp(input)) {
    const msg = `'${input}' is not a valid ISO timestamp string 'YYYY-MM-DDTHH:mm:ss.sssZ'`;
    if (!IS_PRODUCTION) {
      throw Error(msg);
    } else {
      logMessage(msg, 'error');
      return input as IsoTimestamp;
    }
  }

  return input;
};
