// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';

import { ZoomUserFragmentFragment } from 'src/generated/mloop-graphql';

export enum Categories {
  AllAccounts = 'ALL_ACCOUNTS',
  InterviewPanel = 'INTERVIEW_PANEL',
  RecentAccounts = 'RECENT_ACCOUNTS',
  AdditionalOptions = 'ADDITIONAL_OPTIONS',
}

export interface ZoomUserDataWithCategory extends ZoomUserFragmentFragment {
  id?: string;
  category?: Categories;
}

export type ZoomUserSelectValue<
  TData extends ZoomUserDataWithCategory,
  InputValue extends string | string[] | undefined
> = InputValue extends string[] ? TData[] : TData;

export type ZoomUserSelectProps<
  TData extends ZoomUserDataWithCategory,
  InputValue extends string | string[] | undefined
> = {
  dataTestId?: string;
  disabled?: boolean;
  value?: InputValue;
  additionalOptions?: ZoomUserDataWithCategory[];
  panelInterviewerIds: string[];
  getLabel?: (label: string) => string | React.ReactNode;
  onChange?: (value: ZoomUserSelectValue<TData, InputValue>) => void;
  isAdditionalOptionId?: (zoomUserId: string) => boolean;
  renderAdditionalOption?: (option: ZoomUserDataWithCategory) => React.ReactNode;
  renderAdditionalOptionGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode[];
  getAdditionalOptionSize?: (child: React.ReactNode) => number;
  getAdditionalOptionLabel?: (option: ZoomUserDataWithCategory) => string;
  shouldHidePopoverOnSelect?: boolean;
  shouldInvokeOnChangeOnExistingUsersDataFetch?: boolean;
};
