import React, { FC } from 'react';

// eslint-disable-next-line no-restricted-imports
import { LDProvider } from 'launchdarkly-react-client-sdk';

import useAuth from 'src/hooks/useAuth';

import { buildLaunchDarklyUser } from 'src/utils/logger/identifyLaunchDarklyUser';

import { launchDarklyConfig } from 'src/config';

const LDProviderWrapper: FC = ({ children }) => {
  const { userInfo, isInitialised } = useAuth();

  const userContext = isInitialised ? buildLaunchDarklyUser(userInfo) : undefined;
  return (
    <LDProvider
      clientSideID={launchDarklyConfig.clientSideID}
      deferInitialization
      reactOptions={{
        useCamelCaseFlagKeys: false,
      }}
      options={{ bootstrap: 'localStorage' }}
      context={userContext}
    >
      {children}
    </LDProvider>
  );
};

export default LDProviderWrapper;
