import React, { forwardRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Theme, createStyles, makeStyles } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';

import * as Typography from 'src/components/constants/Typography';
import { Props as IconProps } from 'src/components/icons/Icon';

import Stack from '../Stack';
import Button from '../button';
import { TriangleDownIcon } from '../icons';
import Label from '../label';
import MaybeTooltip from '../tooltip/MaybeTooltip';
import { IconType, getDisabledIcon, getIconFromEnum } from '../utils/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectButton: (props: SelectButtonProps) => {
      let height = '32px';
      if (props.title && props.size === 'small') {
        height = '60px';
      } else if (props.title) {
        height = '64px';
      } else if (props.size === 'small') {
        height = '28px';
      }

      if (props.variant === 'unstyled') {
        return {
          border: 'none',
          height,
          borderRadius:
            props.size === 'small' ? Typography.SMALL_BUTTON_BORDER_RADIUS : Typography.STD_BUTTON_BORDER_RADIUS,
          width: '100%',
          backgroundColor: 'transparent',
          display: 'block',
          '&:hover': {
            backgroundColor: theme.grey.alpha.min,
            boxShadow: 'none',
          },
        };
      }

      return {
        border: props.isDisabled
          ? `1px solid ${theme.palette.common.transparent} !important`
          : `1px solid ${theme.palette.border}`,
        borderRadius: '6px',
        height,
        width: '100%',
        backgroundColor: props.isDisabled ? `${theme.grey.solid.min}` : `${theme.palette.background.default}`,
        display: 'block',
      };
    },
    title: (props: SelectButtonProps) => {
      return {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginBottom: props.size === 'small' ? '6px' : '8px',
      };
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface SelectButtonProps {
  dataTestId?: string;
  shrink?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  title?: string | null;
  startIcon?: IconType;
  startIconProps?: IconProps;
  endIcon?: JSX.Element;
  label: string | JSX.Element;
  tooltip?: string;
  size: 'small' | 'medium';
  className?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  variant?: 'outlined' | 'unstyled';
}

const SelectButton: React.ForwardRefRenderFunction<HTMLButtonElement, SelectButtonProps> = (
  props,
  ref: React.Ref<HTMLButtonElement>
) => {
  const classes = useStyles(props);
  const {
    isDisabled,
    title,
    startIcon,
    startIconProps,
    endIcon,
    label,
    onClick,
    size,
    className,
    shrink,
    tooltip,
    dataTestId,
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Button
      fullWidth
      dataTestId={dataTestId}
      ref={ref}
      disabled={isDisabled}
      className={shrink ? className : clsx(classes.selectButton, className)}
      size="small"
      variant="outlined"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      tooltip={tooltip}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <Grid direction="column" alignItems="flex-start" container>
        {title && (
          <Grid item zeroMinWidth xs wrap="nowrap">
            <Label
              className={classes.title}
              variant="captions"
              fontWeight={500}
              // eslint-disable-next-line no-nested-ternary
              color={isFocused ? 'info' : isDisabled ? 'mid-contrast-grey' : 'inherit'}
            >
              {title}
            </Label>
          </Grid>
        )}
        <Stack
          itemStyles={{ 1: { flexShrink: 0 } }}
          justifyContent={shrink ? 'flex-start' : 'space-between'}
          wrap="nowrap"
        >
          <Stack alignItems="center" spacing={1} wrap="nowrap" itemStyles={startIcon ? { 0: { flexShrink: 0 } } : {}}>
            {startIcon && (
              <div style={{ marginLeft: '-4px' }}>
                {isDisabled ? getDisabledIcon(startIcon) : getIconFromEnum(startIcon, startIconProps)}
              </div>
            )}
            {React.isValidElement(label) && label}
            {!React.isValidElement(label) && (
              <div style={{ width: '100%' }}>
                <MaybeTooltip
                  label={label as string}
                  tooltip={label}
                  labelProps={{
                    variant: size === 'small' ? 'captions' : 'body',
                    color: isDisabled ? 'mid-contrast-grey' : 'foreground',
                  }}
                />
              </div>
            )}
          </Stack>
          {endIcon && <div style={{ marginLeft: '-4px' }}>{endIcon}</div>}
          {!endIcon && (
            <div style={{ marginRight: '-4px', color: isDisabled ? 'mid-contrast-grey' : 'inherit' }}>
              <TriangleDownIcon color={isDisabled ? 'mid-contrast-grey' : 'inherit'} />
            </div>
          )}
        </Stack>
      </Grid>
    </Button>
  );
};

export default forwardRef<HTMLButtonElement, SelectButtonProps>(SelectButton);
