import React, { useCallback, useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments, NestedMenuItem } from '@modernloop/shared/components';
import { CanDisable } from '@modernloop/shared/helper-components';
import {
  ArrowDownIcon,
  BeverageCoffeeIcon,
  ClockWithArchedArrowIcon,
  MoonWithArchedArrowIcon,
  MoonWithStarsIcon,
  TriangleDownIcon,
} from '@modernloop/shared/icons';
import { Button, Menu, MenuItem, Stack } from '@mui/material';

import { Break_BreakSlotFragment, InterviewType } from 'src/generated/mloop-graphql';

type Fragments = {
  breakSlot: Break_BreakSlotFragment;
};

type Props = {
  readonly?: boolean;
  onUpdated: (breakSlot: Break_BreakSlotFragment) => void;
};

const MINUTES_IN_DAY = 1440;

export const DURATION_OPTIONS: { id: number; value: string }[] = [
  { id: 5, value: '5 mins' },
  { id: 10, value: '10 mins' },
  { id: 15, value: '15 mins' },
  { id: 20, value: '20 mins' },
  { id: 25, value: '25 mins' },
  { id: 30, value: '30 mins' },
  { id: 35, value: '35 mins' },
  { id: 40, value: '40 mins' },
  { id: 45, value: '45 mins' },
  { id: 50, value: '50 mins' },
  { id: 55, value: '55 mins' },
  { id: 60, value: '1 hr' },
  { id: 75, value: '1 hr 15 mins' },
  { id: 80, value: '1 hr 20 mins' },
  { id: 90, value: '1 hr 30 mins' },
  { id: 105, value: '1 hr 45 mins' },
  { id: 115, value: '1 hr 55 mins' }, // This is backfill for old data
  { id: 120, value: '2 hrs' },
  { id: 135, value: '2 hrs 15 mins' },
  { id: 150, value: '2 hrs 30 mins' },
  { id: 165, value: '2 hrs 45 mins' },
  { id: 180, value: '3 hrs' },
  { id: 195, value: '3 hrs 15 mins' },
  { id: 210, value: '3 hrs 30 mins' },
  { id: 225, value: '3 hrs 45 mins' },
  { id: 240, value: '4 hrs' },
  { id: 300, value: '5 hrs' },
  { id: 360, value: '6 hrs' },
  { id: 420, value: '7 hrs' },
  { id: 480, value: '8 hrs' },
  { id: 540, value: '9 hrs' },
  { id: 600, value: '10 hrs' },
  { id: 660, value: '11 hrs' },
  { id: 720, value: '12 hrs' },
  // { id: MINUTES_IN_DAY, value: '1 Day' },
];

const BreakSlot: FCWithFragments<Fragments, Props> = ({ readonly, breakSlot, onUpdated }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const label = useMemo(() => {
    switch (breakSlot.interviewType) {
      case InterviewType.AnyTimeLater: {
        return 'Then, anytime time later';
      }
      case InterviewType.Break: {
        if (breakSlot.duration === 0) {
          return 'Then, immediately after';
        }
        return `Then, ${DURATION_OPTIONS.find((option) => option.id === breakSlot.duration)?.value} later`;
      }
      case InterviewType.NextDay: {
        return 'Then, the next day';
      }
      case InterviewType.DayDivider: {
        return 'Then, the next day or later';
      }
      default:
        return '';
    }
  }, [breakSlot.duration, breakSlot.interviewType]);

  const icon = useMemo(() => {
    switch (breakSlot.interviewType) {
      case InterviewType.AnyTimeLater: {
        return <ClockWithArchedArrowIcon data-testid="break-clock-with-arched-arrow-icon" />;
      }
      case InterviewType.Break: {
        if (breakSlot.duration === 0) {
          return <ArrowDownIcon data-testid="break-arrow-down-icon" />;
        }
        return <BeverageCoffeeIcon data-testid="break-beverage-coffee-icon" />;
      }
      case InterviewType.NextDay: {
        return <MoonWithStarsIcon data-testid="break-moon-with-stars-icon" />;
      }
      case InterviewType.DayDivider: {
        return <MoonWithArchedArrowIcon data-testid="break-moon-with-arched-arrow-icon" />;
      }
      default:
        return null;
    }
  }, [breakSlot.duration, breakSlot.interviewType]);

  const handleSelect = useCallback(
    (interviewType: InterviewType, duration?: number) => {
      if (duration === MINUTES_IN_DAY) {
        onUpdated({ ...breakSlot, interviewType: InterviewType.NextDay, duration: 0 });
      } else {
        onUpdated({ ...breakSlot, interviewType, duration });
      }
      setShowMenu(false);
    },
    [breakSlot, onUpdated]
  );

  return (
    <CanDisable disabled={Boolean(readonly)}>
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Button
          ref={buttonRef}
          startIcon={icon}
          endIcon={<TriangleDownIcon />}
          data-testid="break-label-button"
          onClick={() => setShowMenu(true)}
        >
          {label}
        </Button>
      </Stack>
      <Menu
        anchorEl={buttonRef.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        open={showMenu}
        data-testid="break-duration-picker-menu"
        onClose={() => setShowMenu(false)}
      >
        <MenuItem
          value={InterviewType.AnyTimeLater}
          data-testid="break-any-time-later-menu-option"
          onClick={() => handleSelect(InterviewType.AnyTimeLater)}
        >
          Any time later
        </MenuItem>
        <MenuItem
          value={`${InterviewType.Break}-0`}
          data-testid="break-immediately-after-menu-option"
          onClick={() => handleSelect(InterviewType.Break, 0)}
        >
          Immediately after
        </MenuItem>
        <NestedMenuItem
          title="Specific duration after…"
          sx={{ height: '480px' }}
          dataTestId="break-specific-duration-picker-menu"
          onClose={() => setShowMenu(false)}
        >
          {DURATION_OPTIONS.map((option) => {
            return (
              <MenuItem
                key={option.id}
                value={option.id}
                data-testid={`break-specific-duration-picker-menu-${option.id}`}
                onClick={() => handleSelect(InterviewType.Break, option.id)}
              >
                {option.value}
              </MenuItem>
            );
          })}
        </NestedMenuItem>
        <MenuItem
          value={InterviewType.DayDivider}
          data-testid="break-next-day-or-later-menu-option"
          onClick={() => handleSelect(InterviewType.DayDivider)}
        >
          The next day or later
        </MenuItem>
      </Menu>
    </CanDisable>
  );
};

BreakSlot.fragments = {
  breakSlot: gql`
    fragment Break_breakSlot on JobStageInterview {
      id
      interviewType
      name
      duration
      isLockedOrder
    }
  `,
};

export default BreakSlot;
