import { useEffect, useState } from 'react';

/**
 * OneTrust is a consent management tool, supplied as a script that gets loaded through a script tag in our index.html file.
 * When the script is loaded, it exposes a global variable called OnetrustActiveGroups, which we can use to check user consents.
 * The OneTrust script also emits events that we can hook into, for example, when it finshes loading, when a user takes action, etc.
 * Documentation on the OneTrust SDK's properties and events:
 *  - Client-side cookie management: https://my.onetrust.com/s/article/UUID-518074a1-a6da-81c3-be52-bae7685d9c94?language=en_US&topicId=0TO1Q000000ssJBWAY
 *  - Banner SDK JS API: https://my.onetrust.com/s/article/UUID-d8291f61-aa31-813a-ef16-3f6dec73d643?language=en_US&topicId=0TO1Q000000ssJBWAY
 *  - OnetrustActiveGroups: https://my.onetrust.com/s/article/UUID-66bcaaf1-c7ca-5f32-6760-c75a1337c226?language=en_US
 *
 * OneTrust cookie categories:
 *  - C0001: Strictly necessary
 *  - C0002: Performance / analytics (maps to statistics)
 *  - C0003: Functional (maps to preferences)
 *  - C0004: Targeting (maps to marketing)
 * - C0005: Social media (not used)
 *
 * OneTrust allows us to use different consent banners based on a user's location
 * Generally, GDPR is opt-in (requires consent before using local storage / cookies)
 * US rules are opt-out (we can use cookies but must present a clear way for users to opt out of sharing data)
 **/

export const useOneTrustLoader = () => {
  const [preferencesLoaded, setPreferencesLoaded] = useState(false);
  const [consents, setConsents] = useState({
    preferences: false,
    marketing: false,
    statistics: false,
  });
  const oneTrustEnabled = !!window.OnetrustActiveGroups;

  useEffect(() => {
    if (!oneTrustEnabled) {
      return;
    }

    if (!preferencesLoaded) {
      setConsents({
        preferences: window.OnetrustActiveGroups.includes('C0003'),
        marketing: window.OnetrustActiveGroups.includes('C0004'),
        statistics: window.OnetrustActiveGroups.includes('C0002'),
      });
      setPreferencesLoaded(true);
    }

    const updateConsents = () => {
      setConsents({
        preferences: window.OnetrustActiveGroups.includes('C0003'),
        marketing: window.OnetrustActiveGroups.includes('C0004'),
        statistics: window.OnetrustActiveGroups.includes('C0002'),
      });
      setPreferencesLoaded(true);
    };
    window.addEventListener('OneTrustGroupsUpdated', updateConsents);
    window.addEventListener('OTConsentApplied', updateConsents);

    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', updateConsents);
      window.removeEventListener('OTConsentApplied', updateConsents);
    };
  }, [preferencesLoaded, consents, oneTrustEnabled]);

  if (!oneTrustEnabled) {
    // if OneTrust is not enabled, return null for consents
    return { consents: null, oneTrustEnabled };
  }

  return { consents, oneTrustEnabled };
};
