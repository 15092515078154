import React, { FC } from 'react';

import { Alert, AlertTitle, Box } from '@mui/material';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

interface Props {
  enabled: boolean;
  loading: boolean;
  count: number;
  error?: string;
  text?: string;
}

const OptionsCountAlert: FC<Props> = ({ enabled, loading, count, error, text = 'suggest' }) => {
  return (
    <ConditionalThemeProvider>
      {!enabled && (
        <Alert severity="warning">
          <AlertTitle>Suggested times are off</AlertTitle>
          Use the toggles above to enable suggestions
        </Alert>
      )}
      {!error && enabled && (
        <Box mt={1}>
          {!loading && Boolean(count) && (
            <Alert severity="success">
              <AlertTitle>
                Found {count} times to {text}
              </AlertTitle>
            </Alert>
          )}
          {!loading && !count && (
            <Alert severity="error">
              <AlertTitle>No times found to {text}</AlertTitle>
              This is based on work hours, calendar conflicts, and interview load limits.
            </Alert>
          )}
        </Box>
      )}
      {error && enabled && (
        <Box mt={1}>
          <Alert severity="error">
            <AlertTitle>{error}</AlertTitle>
          </Alert>
        </Box>
      )}
    </ConditionalThemeProvider>
  );
};

export default OptionsCountAlert;
