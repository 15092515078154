// We are migrating away from packages/app-client/src/types/preferenes.ts
// So we can use the backend endpoints.
export enum OrgPrefName {
  ARR_COMPANY_HOLIDAYS = 'arr_company_holidays',
  ARR_HARD_CONFLICT_PHRASES = 'arr_hard_conflict_phrases',
  ARR_OUT_OF_OFFICE_PHRASES = 'arr_out_of_office_phrases',
  ARR_OUTLOOK_SHARED_CALENDAR_OWNER_IDS = 'arr_outlook_shared_calendar_owner_ids',
  ARR_RECRUITING_BLOCK_PHRASES = 'arr_recruiting_block_phrases',
  ARR_SOFT_CONFLICT_PHRASES = 'arr_soft_conflict_phrases',

  BOOL_AUTO_CANCEL_TASKS_ON_APPLICATION_CLOSE = 'bool_auto_cancel_tasks_on_application_close',
  BOOL_AUTOMATICALLY_REPLACE_DECLINED_INTERVIEWERS = 'bool_automatically_replace_declined_interviewer',
  BOOL_HAS_VERIFIED_CANDIDATE_WRITE_PERMISSION = 'bool_has_verified_candidate_write_permission',
  BOOL_NOTIFY_SLACK_24_HOURS_REMINDER = 'bool_notify_slack_24hoursreminder',
  BOOL_NOTIFY_SLACK_24_HOURS_REMINDER_INTERVIEWER_DIRECT = 'bool_notify_slack_24_hours_reminder_interviewer_direct',
  BOOL_NOTIFY_SLACK_INTERVIEWER_INCLUDE_RC_NO_RESPONSE = 'bool_notify_slack_interviewer_include_rc_no_response',
  BOOL_NOTIFY_SLACK_INTERVIEWER_NO_RESPONSE = 'bool_notify_slack_interviewer_no_response',

  OBJ_ADD_ZOOM_HOST_AS_INTERNAL_INVITEE = 'obj_add_zoom_host_as_internal_invitee',
  OBJ_CUSTOM_INTERVIEW_SCHEDULE_TOKEN = 'obj_custom_interview_schedule_token',
  OBJ_DAILY_INTERVIEW_LIMIT = 'obj_daily_interview_limit',
  OBJ_DEFAULT_WORK_HOURS = 'obj_default_work_hours',
  OBJ_MINIMUM_DAILY_INTERVIEWER_LOAD_LIMIT = 'obj_minimum_daily_interviewer_load_limit',
  OBJ_MINIMUM_WEEKLY_INTERVIEWER_LOAD_LIMIT = 'obj_minimum_weekly_interviewer_load_limit',
  OBJ_PREMIUM_CANDIDATE_PORTAL = 'obj_premium_candidate_portal',
  OBJ_TRAINING_MODULE_GRADUATION_NOTIFICATION = 'obj_training_module_graduation_notification',
  OBJ_WEEKLY_INTERVIEW_LIMIT = 'obj_weekly_interview_limit',

  STR_CSM_NAME = 'str_csm_name',
  STR_DEFAULT_CANDIDATE_CALENDAR = 'str_default_candidate_calendar',
  STR_DEFAULT_INTERVIEW_PLAN_BREAK_TYPE = 'str_default_interview_plan_break_type',
  STR_DEFAULT_INTERVIEWER_CALENDAR = 'str_default_interviewer_calendar',
  STR_SCHEDULE_CANDIDATE_EVENT_SPLIT = 'str_schedule_candidate_event_split',
  STR_SLACK_CHANNEL_NAME_FORMAT = 'str_slack_channel_name_format',
  STR_TASK_DEFAULT_ASSIGNEE_AVAILABILITY_REQUESTS = 'str_task_default_assignee_availability_requests',
  STR_TASK_DEFAULT_ASSIGNEE_REQUEST_TO_SCHEDULE = 'str_task_default_assignee_request_to_schedule',
  STR_TASK_DEFAULT_ASSIGNEE_SELF_SCHEDULE_REQUESTS = 'str_task_default_assignee_self_schedule_requests',

  INT_DAYS_UNTIL_HIRED_APPLICATION_CAN_SEE_PORTAL = 'int_days_until_hired_application_can_see_portal',
  INT_DAYS_UNTIL_REJECTED_APPLICATION_CAN_SEE_PORTAL = 'int_days_until_rejected_application_can_see_portal',

  // Candidate Notification Reminder settings
  INT_CANDIDATE_AVAILABILITY_REMINDER_MINUTES = 'int_candidate_availability_reminder_minutes',
  INT_SELF_SCHEDULE_REMINDER_MINUTES = 'int_self_schedule_reminder_minutes',
  INT_CANDIDATE_INTERVIEW_REMINDER_MINUTES = 'int_candidate_interview_reminder_minutes',

  // Privacy settings
  BOOL_CANDIDATE_FACING_SHOW_INTERVIEW_NAME = 'bool_candidate_facing_show_interview_name',
  BOOL_EVENT_VISIBILITY_IS_PRIVATE = 'bool_event_visibility_is_private',
  OBJ_CANDIDATE_FACING_INTERVIEWER_INFO = 'obj_candidate_facing_interviewer_info',
  OBJ_CANDIDATE_FACING_HIRING_TEAM_CONTACT_INFO = 'obj_candidate_facing_hiring_team_contact_info',

  // ATS disconnect settings
  TS_ATS_DISCONNECTED_AT = 'ts_ats_disconnected_at',
  TS_COMPANY_CONTRACT_END_DATE = 'ts_company_contract_end_date',
}
