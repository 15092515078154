import { InterviewType } from 'src/generated/mloop-graphql';

export const isBreak = (type: InterviewType): boolean => {
  return (
    type?.toUpperCase() === InterviewType.Break ||
    type.toUpperCase() === InterviewType.DayDivider ||
    type.toUpperCase() === InterviewType.NextDay ||
    type.toUpperCase() === InterviewType.AnyTimeLater
  );
};

export const isDayBreak = (type: InterviewType): boolean => {
  return type?.toUpperCase() === InterviewType.DayDivider || type?.toUpperCase() === InterviewType.NextDay;
};
