import React from 'react';

import BaseIcon, { Props } from './Icon';

const MinimizeIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5304 4.03035C16.8233 3.73746 16.8233 3.26259 16.5304 2.96969C16.2375 2.6768 15.7626 2.6768 15.4697 2.96969L11.75 6.68936L11.75 4.50002C11.75 4.08581 11.4143 3.75002 11 3.75002C10.5858 3.75002 10.25 4.0858 10.25 4.50002L10.25 8.50002C10.25 8.69893 10.329 8.8897 10.4697 9.03035C10.6104 9.17101 10.8011 9.25002 11 9.25002L15 9.25002C15.4143 9.25001 15.75 8.91423 15.75 8.50001C15.75 8.0858 15.4143 7.75001 15 7.75002L12.8107 7.75002L16.5304 4.03035ZM4.50004 12.25L6.68937 12.25L2.9697 15.9697C2.67681 16.2626 2.67681 16.7375 2.9697 17.0304C3.26259 17.3233 3.73747 17.3233 4.03036 17.0304L7.75004 13.3107L7.75004 15.5C7.75004 15.9142 8.08583 16.25 8.50004 16.25C8.91426 16.25 9.25004 15.9142 9.25004 15.5L9.25003 11.5C9.25003 11.0858 8.91424 10.75 8.50003 10.75L4.50004 10.75C4.08583 10.75 3.75004 11.0858 3.75004 11.5C3.75004 11.9143 4.08583 12.25 4.50004 12.25Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MinimizeIcon;
