import { useCallback } from 'react';

import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';

const useGenericOnError = () => {
  const { enqueueSnackbar } = useSnackbar();

  const errorHandler = useCallback(
    (error: ApolloError) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    [enqueueSnackbar]
  );

  return errorHandler;
};

export default useGenericOnError;
