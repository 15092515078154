import { logError } from '../utils/logError';
import { BOOL_FEATURE_FLAGS, FeatureFlagBool } from './FeatureFlagBool';
import { LocalStorageFlags, useFeatureFlagLocal } from './useFeatureFlagLocal';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
// eslint-disable-next-line no-restricted-imports
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

type Args = {
  serverFlags: LDFlagSet;
  localFlags: LocalStorageFlags;
  flagKey: FeatureFlagBool;
};

const getFeatureFlagBool = ({ serverFlags, localFlags, flagKey }: Args): boolean => {
  if (!(flagKey in serverFlags) && !isEmpty(serverFlags)) {
    logError(`Feature Flag "${flagKey}" is not being sent to client`);
    return false; // fail closed
  }

  if (serverFlags['MLOnly']) {
    // local feature flags override query params
    const localFeatureFlagValue = localFlags[flagKey];

    if (localFeatureFlagValue !== undefined && typeof localFeatureFlagValue === 'boolean') {
      return localFeatureFlagValue;
    }
  }

  return !!serverFlags[flagKey];
};

/**
 * Boolean based feature flag checker.
 *
 * It complains if it can't find the specified flag in the LaunchDarkly payload.
 * This function also allows us to override flags so we can more easily UI test
 * different features with the same user account.
 *
 * https://docs.launchdarkly.com/home/getting-started/feature-flags
 */
export function useFeatureFlagBool(flagKey: FeatureFlagBool): boolean {
  const serverFlags = useFlags();
  const { localStorageFeatureFlags: localFlags } = useFeatureFlagLocal();

  return getFeatureFlagBool({ serverFlags, localFlags, flagKey });
}

/**
 * Returns all evaluated frontend feature flags.
 *
 * It complains if it can't find the specified flag in the LaunchDarkly payload.
 * This function also allows us to override flags so we can more easily UI test
 * different features with the same user account.
 *
 * https://docs.launchdarkly.com/home/getting-started/feature-flags
 */
export const useFeatureFlagBoolList = (): Record<FeatureFlagBool, boolean> => {
  const serverFlags = useFlags();
  const { localStorageFeatureFlags: localFlags } = useFeatureFlagLocal();

  const list = useMemo(() => {
    const flagsList: Partial<Record<FeatureFlagBool, boolean>> = {};
    BOOL_FEATURE_FLAGS.forEach((flagKey) => {
      flagsList[flagKey] = getFeatureFlagBool({ serverFlags, localFlags, flagKey });
    });
    return flagsList;
  }, [serverFlags, localFlags]);

  return list as Record<FeatureFlagBool, boolean>;
};
