import React from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Button, Divider, Grid } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Label from 'src/components/label';

import { Theme } from 'src/theme/type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: `1px solid ${theme.palette.border}`,
      borderRadius: '12px',
      flexShrink: 0,
      width: 'fit-content',
    },
    disabled: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
    button: {
      borderRadius: '12px',
      border: `1px solid ${theme.palette.common.transparent}`,
      textTransform: 'none',
    },
    divider: {
      margin: '8px 0',
      width: '2px',
    },
    dividerTransparent: {
      backgroundColor: theme.palette.common.transparent,
    },
    notAConflict: {
      backgroundColor: theme.palette.action.selected,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    avoid: {
      backgroundColor: theme.palette.background.error,
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
const EventDetailsConflictButtons = ({
  isMarkedAsConflict,
  disabled,
  markAsConflict,
}: {
  isMarkedAsConflict?: boolean;
  disabled?: boolean;
  markAsConflict: (isConflict: boolean) => void;
}): JSX.Element => {
  const classes = useStyles();

  const handleMarkAsConflict = (event: React.MouseEvent, isConflict: boolean) => {
    event.preventDefault();
    event.stopPropagation();

    markAsConflict(isConflict);
  };

  return (
    <Grid container className={clsx(classes.container, { [classes.disabled]: disabled })}>
      <Grid item>
        <Button
          variant="text"
          disabled={disabled}
          className={clsx(classes.button, { [classes.notAConflict]: isMarkedAsConflict === false })}
          onClick={(event) => handleMarkAsConflict(event, false)}
        >
          <Label variant="captions" color={disabled ? 'high-contrast-grey' : undefined}>
            Not a conflict
          </Label>
        </Button>
      </Grid>
      <Divider
        orientation="vertical"
        className={clsx(classes.divider, { [classes.dividerTransparent]: isMarkedAsConflict !== undefined })}
        flexItem
      />
      <Grid item>
        <Button
          variant="text"
          disabled={disabled}
          className={clsx(classes.button, { [classes.avoid]: isMarkedAsConflict === true })}
          onClick={(event) => handleMarkAsConflict(event, true)}
        >
          <Label variant="captions" color={disabled ? 'high-contrast-grey' : undefined}>
            Avoid
          </Label>
        </Button>
      </Grid>
    </Grid>
  );
};

export default EventDetailsConflictButtons;
