import { ApiVerb, apiPost } from 'src/utils/api';
import { keysToCamel_TYPE_UNSAFE_DO_NOT_USE } from 'src/utils/snakeToCamelCaseUtil';

export type QueryFilter = {
  remoteId?: string;
  isReadOnly?: boolean;
};

export interface ListCalendarRequest {
  limit?: number;
  cursor?: string;
  filter?: QueryFilter;
}

export interface Calendar {
  id: string | null;
  summary: string;
  color?: string;
  isReadOnly?: boolean;
  isOwner?: boolean;
  employeeId?: string;
}

export interface ListCalendarResponse {
  calendar: Calendar[];
  nextCursor?: string;
}

const getCalendars = async (request: ListCalendarRequest): Promise<ListCalendarResponse> => {
  const response = await apiPost(ApiVerb.LIST_CALENDAR, request);

  // The db returns "name" instead of "summary", so we need to swap those keys in our returned object
  const correctFormat = response.data.calendar.map(({ name: summary, ...rest }) => ({
    summary,
    ...rest,
  }));

  // We also need to map "remote_id" to "id"
  correctFormat.forEach((element) => {
    element.id = element.remote_id;
    delete element.remote_id;
  });

  // Now we need to camel case the calendar keys
  response.data.calendar = keysToCamel_TYPE_UNSAFE_DO_NOT_USE(correctFormat);

  // Lastly, we need to camel "next_cursor"
  return keysToCamel_TYPE_UNSAFE_DO_NOT_USE(response.data);
};

export default getCalendars;
