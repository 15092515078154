import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';

import useHistory from 'src/hooks/useHistory';

/**
 *  Know list of url search parameters
 */
export enum SearchParamKnownKeys {
  applicationId = 'applicationId',
  error = 'error',
  errorDescription = 'error_description',
  interviewerId = 'interviewerId',
  addNewInterview = 'addNewInterview',
  panelObjectId = 'pId',
  panelType = 'pType',
  stageInterviewId = 'stageInterviewId',
  candidateComms = 'candidateComms',
  taskId = 'tId',
  jobStageId = 'jsId',
  templateBuilderNew = 'tbn',
  moduleMemberId = 'mmId',
  interviewModuleId = 'imId',
  targetJobIds = 'targetJobIds',
  jobImportSettingsSource = 'jobImportSettingsSource',
}

export enum SidePanelType {
  Default = 'default',
  ScheduleTask = 'st',
  InterviewModuleMember = 'imm',
}

/**
 * args : none
 * @returns URLSearchParams
 * Eg let query = useUrlSearchParams();
 *    query.get(SearchParamKnownKeys[keyname]);
 */

export function useUrlSearchParams(): URLSearchParams {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const useAddUrlSearchParams = (): ((
  identifiers: { [key in SearchParamKnownKeys]?: string },
  operation?: 'push' | 'replace'
) => void) => {
  const location = useLocation();
  const history = useHistory();
  return useCallback(
    (identifiers: { [key in SearchParamKnownKeys]?: string }, operation: 'push' | 'replace' = 'push') => {
      const queryParams = new URLSearchParams(location.search);
      Object.keys(identifiers).forEach((key) => {
        queryParams.set(key, identifiers[key]);
      });
      if (operation === 'replace') {
        history.replace({ search: queryParams.toString() });
      } else {
        history.push({ search: queryParams.toString() });
      }
    },
    [history, location.search]
  );
};

/**
 * args none
 * @returns urlParams delete handler with args => identifier
 * Eg const deleteUrlSearchParam = useDeleteUrlSearchParams();
 *    deleteUrlSearchParam(SearchParamKnownKeys[keyname]);
 */
export enum HistoryOperation {
  PUSH = 'push',
  REPLACE = 'replace',
}
export function useDeleteUrlSearchParams(): (
  identifiers: SearchParamKnownKeys[],
  operation?: HistoryOperation
) => void {
  const location = useLocation();
  const history = useHistory();
  return useCallback(
    (identifiers: SearchParamKnownKeys[], operation: HistoryOperation = HistoryOperation.PUSH) => {
      const queryParams = new URLSearchParams(location.search);
      identifiers.forEach((identifier) => queryParams.delete(identifier));
      if (operation === 'replace') {
        history.replace({ search: queryParams.toString() });
      } else {
        history.push({ search: queryParams.toString() });
      }
    },
    [history, location.search]
  );
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
const generatePath = (url: string, paramName: string, paramValue: string | boolean | number) => {
  if (paramValue == null) {
    paramValue = '';
  }
  const pattern = new RegExp(`\\b(${paramName}=).*?(&|#|$)`);
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, `$1${paramValue}$2`);
  }
  url = url.replace(/[?#]$/, '');
  return `${url + (url.indexOf('?') > 0 ? '&' : '?') + paramName}=${paramValue}`;
};

export const urlQueryBuilder = (resource: string, params: Record<string, string | boolean | number>): string => {
  params = params || {};
  if (Object.keys(params).length === 0) return resource;
  Object.keys(params).forEach((key) => {
    resource = generatePath(resource, key, params[key]);
  });
  return resource;
};
