import React from 'react';

import { gql } from '@apollo/client';
import { formatDate, formatTimeRange, getLocalTimezone } from '@modernloop/shared/datetime';
import { OpenNewTaskIcon } from '@modernloop/shared/icons';
import { Chip, Divider, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';

import {
  GraduationApproverType,
  InterviewLogInterview_InterviewFragment,
  InterviewLogInterview_ModuleMemberFragment,
  TrainingStatus,
} from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

import ApprovalFeedback from './ApprovalFeedback';
import Interviewfeedback from './InterviewFeedback';

type Fragments = {
  interview: InterviewLogInterview_InterviewFragment;
  moduleMember: InterviewLogInterview_ModuleMemberFragment;
};

type Props = {
  role: TrainingStatus;
  showNagButton?: boolean;
};

const InterviewLogInterview: FCWithFragments<Fragments, Props> = ({ interview, role, showNagButton, moduleMember }) => {
  if (!interview || !moduleMember) {
    return null;
  }

  const handleOpenNewTaskClick = () => {
    SidePanelManager.closeSidePanel();
    SidePanelManager.openScheduleTaskSidePanel(interview.applicationStage?.taskId, {});
  };

  const hasFeedback =
    interview?.trainedFeedback?.reviewerId ||
    interview?.traineeFeedback?.reviewerId ||
    interview?.schedulerFeedback?.reviewerId;

  return (
    <Paper variant="outlined">
      <Stack direction="column">
        <Stack spacing={0.5} padding={1.5} paddingTop={0.5}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <Typography fontWeight={600}>{interview?.name}</Typography>
            <Tooltip title="Open Task">
              <IconButton onClick={handleOpenNewTaskClick}>
                <OpenNewTaskIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body2">{interview?.applicationStage?.application?.candidate?.fullName}</Typography>
            {interview.isHiddenFromCandidate && <Chip variant="filled" color="default" label="Internal" />}
          </Stack>
          <Typography variant="caption" color="text.secondary">
            {formatDate(interview.startAt, getLocalTimezone(), 'MMMM d, yyyy')}&nbsp;&#183;&nbsp;
            {formatTimeRange(interview?.startAt, interview?.endAt, getLocalTimezone())}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {interview?.applicationStage?.application?.job?.name}&nbsp;&#183;&nbsp;
            {interview?.applicationStage?.application?.jobStage?.name}
          </Typography>
        </Stack>
        {hasFeedback && (
          <>
            <Divider />
            <Stack direction="column" spacing={1} padding={1.5}>
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Typography color="text.secondary" variant="subtitle2" fontWeight={600}>
                  Feedback
                </Typography>
                <Typography color="text.secondary" variant="subtitle2" fontWeight={600}>
                  Evaluation
                </Typography>
              </Stack>
              {interview?.trainedFeedback && (
                <Interviewfeedback
                  interviewfeedback={interview.trainedFeedback}
                  feedbackType={GraduationApproverType.Trained}
                  applicationStageInterviewId={interview.id}
                  moduleId={moduleMember.interviewModuleId}
                  moduleMemberId={moduleMember.employeeId}
                  showNagButton={showNagButton}
                />
              )}
              {interview?.traineeFeedback && (
                <Interviewfeedback
                  interviewfeedback={interview.traineeFeedback}
                  feedbackType={GraduationApproverType.Trainee}
                  applicationStageInterviewId={interview.id}
                  moduleId={moduleMember.interviewModuleId}
                  moduleMemberId={moduleMember.employeeId}
                  showNagButton={showNagButton}
                />
              )}
              {!showNagButton && (
                <ApprovalFeedback
                  role={role}
                  trainedFeedback={interview?.trainedFeedback || undefined}
                  traineeFeedback={interview?.traineeFeedback || undefined}
                  schedulerFeedback={interview?.schedulerFeedback || undefined}
                />
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Paper>
  );
};

InterviewLogInterview.fragments = {
  moduleMember: gql`
    fragment InterviewLogInterview_moduleMember on InterviewModuleMember {
      id
      employeeId
      interviewModuleId
    }
  `,
  interview: gql`
    ${Interviewfeedback.fragments.interviewfeedback}
    ${ApprovalFeedback.fragments.traineeFeedback}
    ${ApprovalFeedback.fragments.trainedFeedback}
    ${ApprovalFeedback.fragments.schedulerFeedback}
    fragment InterviewLogInterview_interview on ApplicationStageInterview {
      id
      name
      startAt
      endAt
      googleEventUrl
      applicationStageInterviewInterviewers {
        responseStatus
        interviewerId
      }
      applicationStage {
        id
        taskId
        application {
          id
          candidate {
            id
            fullName
          }
          job {
            id
            name
          }
          jobStage {
            id
            name
          }
        }
      }
      traineeFeedback: traineeFeedback(reviewerType: TRAINEE) {
        ...Interviewfeedback_interviewfeedback
        ...ApprovalFeedback_traineeFeedback
      }
      trainedFeedback: traineeFeedback(reviewerType: TRAINED) {
        ...Interviewfeedback_interviewfeedback
        ...ApprovalFeedback_trainedFeedback
      }
      schedulerFeedback: traineeFeedback(reviewerType: SCHEDULER) {
        ...Interviewfeedback_interviewfeedback
        ...ApprovalFeedback_schedulerFeedback
      }
      isHiddenFromCandidate
    }
  `,
};

export default InterviewLogInterview;
