export enum EmployeePrefName {
  ARR_ALL_TASKS_DASHBOARD_LAYOUT = 'arr_all_tasks_dashboard_layout',
  ARR_MY_TASKS_DASHBOARD_LAYOUT = 'arr_my_tasks_dashboard_layout',
  ARR_RECENTLY_USED_TIMEZONES = 'arr_recently_used_timezones',
  ARR_DO_NOT_SHOW_AGAIN_MODALS = 'arr_do_not_show_again_modals',

  // Keywords
  ARR_AVAILABLE_KEYWORDS = 'arr_available_keywords',
  ARR_AVOID_KEYWORDS = 'arr_avoid_keywords',
  ARR_OUT_OF_OFFICE_KEYWORDS = 'arr_out_of_office_keywords',
  ARR_RECRUITING_BLOCK_KEYWORDS = 'arr_recruiting_block_keywords',

  BOOL_SHOW_TASKS_ON_CANDIDATE_DASHBOARD = 'bool_show_tasks_on_candidate_dashboard',

  OBJ_ALL_TASKS_DASHBOARD_FILTERS = 'obj_all_tasks_dashboard_filters',
  OBJ_CANDIDATE_PAGE_FILTERS = 'obj_candidate_page_filters',
  OBJ_DAILY_INTERVIEW_LIMIT = 'obj_daily_interview_limit',
  OBJ_RECENTLY_USED_ZOOM_USERS = 'obj_recently_used_zoom_users',
  OBJ_MY_TASKS_DASHBOARD_ASSIGNED_TO_ME_FILTERS = 'obj_my_tasks_dashboard_assigned_to_me_filters',
  OBJ_MY_TASKS_DASHBOARD_MY_QUEUES_FILTERS = 'obj_my_tasks_dashboard_my_queues_filters',
  OBJ_MY_TASKS_DASHBOARD_CREATED_BY_ME_FILTERS = 'obj_my_tasks_dashboard_created_by_me_filters',
  OBJ_MY_TASKS_DASHBOARD_SUBSCRIBED_BY_ME_FILTERS = 'obj_my_tasks_dashboard_subscribed_by_me_filters',
  OBJ_METRICS_PAGE_INTERVIEWER_FILTERS = 'obj_metrics_page_interviewer_filters',
  OBJ_METRICS_PAGE_RECRUITING_TEAM_FILTERS = 'obj_metrics_page_recruiting_team_filters',
  OBJ_UPCOMING_EVENTS_PAGE_FILTERS = 'obj_upcoming_events_page_filters',
  OBJ_WEEKLY_INTERVIEW_LIMIT = 'obj_weekly_interview_limit',

  STR_DEFAULT_CANDIDATE_CALENDAR = 'str_default_candidate_calendar',
  STR_DEFAULT_INTERVIEWER_CALENDAR = 'str_default_interviewer_calendar',
  STR_SIGNATURE = 'str_signature',

  STR_CANDIDATE_EMAIL_CONFIRMATION_TEMPLATE_ID = 'str_candidate_email_confirmation_template_id',
  STR_CANDIDATE_CALENDAR_INVITE_TEMPLATE_ID = 'str_candidate_calendar_invite_template_id',
  STR_INTERVIEWER_CALENDAR_INVITE_TEMPLATE_ID = 'str_interviewer_calendar_invite_template_id',
  STR_INTERVIEWER_CONVERSATION_MESSAGE_TEMPLATE_ID = 'str_interviewer_conversation_message_template_id',

  TS_NUX_SEND_INTERNAL_ONLY_DISMISSED_AT = 'ts_nux_send_internal_only_dismissed_at',
  TS_TOS_ACCEPTED_AT = 'ts_tos_accepted_at',
}

export enum InterviewLimitType {
  NUMBER_OF_INTERVIEWS = 'NUMBER_OF_INTERVIEWS',
  NUMBER_OF_MINUTES = 'NUMBER_OF_MINUTES',
}

export enum DoNotShowAgainDialogTypes {
  SEND_INTERNAL_ONLY = 'send_internal_only',
  EDITING_CALENDER_WARNING = 'editing_calendar_warning',
}
