/* eslint-disable import/prefer-default-export */
import { JobStageInterviewGroup } from 'src/store/slices/job-stage-interview-group';

import { State } from 'src/store';

export const getJobStageInterviewGroupById = (state: State, jobStageInterviewGroupId: string): JobStageInterviewGroup =>
  state.jobStageInterviewGroup.byId[jobStageInterviewGroupId];

export const getAllJobStageGroupByJobStageId = (state: State, jobStageId: string): JobStageInterviewGroup[] => {
  const jobStage = state.jobStage.byId[jobStageId];

  if (!jobStage) return [];

  return jobStage.jobStageInterviewGroupIds.map((id) => {
    return state.jobStageInterviewGroup.byId[id];
  });
};

export const getStageInterviewGroupIndex = (state: State, jobStageInterviewGroupId: string): number => {
  const jobStageInterviewGroup = state.jobStageInterviewGroup.byId[jobStageInterviewGroupId];
  const jobStage = state.jobStage.byId[jobStageInterviewGroup.jobStageId];
  return jobStage.jobStageInterviewGroupIds.indexOf(jobStageInterviewGroupId);
};
