import React, { FC } from 'react';

import { gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { useIntegrationHealthCheckBannersQuery } from 'src/generated/mloop-graphql';

import useGemEnabled from 'src/hooks/atsService/useGemEnabled';
import useEmployeeId from 'src/hooks/useEmployeeId';

import { AppWideBannerAction } from '../AppWideBanner';

import AshbyHealthCheckBanner from './AshbyHealthCheckBanner';
import GemHealthCheckBanner from './GemHealthCheckBanner';
import GoogleHealthCheckBanner from './GoogleHealthCheckBanner';
import GreenhouseHealthCheckBanner from './GreenhouseHealthCheckBanner';
import LeverHealthCheckBanner from './LeverHealthCheckBanner';
import MicrosoftGccHighHealthCheckBanner from './MicrosoftGccHighHealthCheckBanner';
import MicrosoftHealthCheckBanner from './MicrosoftHealthCheckBanner';
import ZoomHealthCheckBanner from './ZoomHealthCheckBanner';
import { BUTTON_LABEL_TRY_AGAIN } from './constants';

export const IntegrationCheckHealth = gql`
  ${GoogleHealthCheckBanner.fragments.google}
  ${MicrosoftHealthCheckBanner.fragments.microsoft}
  ${MicrosoftGccHighHealthCheckBanner.fragments.microsoftGccHigh}
  ${GreenhouseHealthCheckBanner.fragments.greenhouse}
  ${LeverHealthCheckBanner.fragments.lever}
  ${AshbyHealthCheckBanner.fragments.ashby}
  ${ZoomHealthCheckBanner.fragments.zoom}
  ${GemHealthCheckBanner.fragments.gem}
  query IntegrationHealthCheckBanners($force: Boolean!, $includeGem: Boolean!) {
    integrationCheckHealth(input: { force: $force }) {
      google {
        ...GoogleHealthCheckBanner_google
      }
      microsoft {
        ...MicrosoftHealthCheckBanner_microsoft
      }
      microsoftGccHigh {
        ...MicrosoftHealthCheckBanner_microsoftGccHigh
      }
      zoom {
        ...ZoomHealthCheckBanner_zoom
      }
      greenhouse {
        ...GreenhouseHealthCheckBanner_greenhouse
      }
      ashby {
        ...AshbyHealthCheckBanner_ashby
      }
      lever {
        ...LeverHealthCheckBanner_lever
      }
      gem @include(if: $includeGem) {
        ...GemHealthCheckBanner_gem
      }
    }
  }
`;

// TODO Workday
const IntegrationHealthCheckBannersInner: FC = () => {
  const [isRefetching, setIsRefetching] = React.useState(false);
  const isGemEnabled = useGemEnabled();
  const { data, loading, error, refetch } = useIntegrationHealthCheckBannersQuery({
    variables: {
      force: false,
      includeGem: isGemEnabled,
    },
    context: {
      batch: false,
    },
  });

  const retry: AppWideBannerAction = {
    label: isRefetching ? 'Trying...' : BUTTON_LABEL_TRY_AGAIN,
    onClick: async () => {
      setIsRefetching(true);
      await refetch({ force: true });
      setIsRefetching(false);
    },
  };

  if (loading) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  const google = data?.integrationCheckHealth?.google;
  const microsoft = data?.integrationCheckHealth?.microsoft;
  const microsoftGccHigh = data?.integrationCheckHealth?.microsoftGccHigh;
  const greenhouse = data?.integrationCheckHealth?.greenhouse;
  const lever = data?.integrationCheckHealth?.lever;
  const ashby = data?.integrationCheckHealth?.ashby;
  const zoom = data?.integrationCheckHealth?.zoom;
  const gem = data?.integrationCheckHealth?.gem;

  return (
    <>
      {google && <GoogleHealthCheckBanner google={google} retry={retry} />}
      {microsoft && <MicrosoftHealthCheckBanner microsoft={microsoft} retry={retry} />}
      {microsoftGccHigh && <MicrosoftGccHighHealthCheckBanner microsoftGccHigh={microsoftGccHigh} retry={retry} />}
      {greenhouse && <GreenhouseHealthCheckBanner greenhouse={greenhouse} retry={retry} />}
      {lever && <LeverHealthCheckBanner lever={lever} retry={retry} />}
      {ashby && <AshbyHealthCheckBanner ashby={ashby} retry={retry} />}
      {zoom && <ZoomHealthCheckBanner zoom={zoom} retry={retry} />}
      {gem && <GemHealthCheckBanner gem={gem} retry={retry} />}
    </>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const IntegrationHealthCheckBanners: FC = () => {
  const showHealthCheckBanner = useFlag('health_check_banner');
  const empId = useEmployeeId();

  if (!showHealthCheckBanner || !empId) {
    // dont show if feature flag is off or if we dont have an employee id
    return null;
  }

  return <IntegrationHealthCheckBannersInner />;
};

export default IntegrationHealthCheckBanners;
