import React from 'react';

import BaseIcon, { Props } from './Icon';

const Unscheduled = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 2C7.75 1.58579 7.41422 1.25 7 1.25C6.58579 1.25 6.25 1.58579 6.25 2V3.25H5.00002C3.48123 3.25 2.25 4.48123 2.25002 6.00003L2.25002 6.04001C2.25002 6.45422 2.58581 6.79 3.00003 6.79C3.41424 6.79 3.75002 6.45421 3.75002 6.03999L3.75002 6.00001C3.75001 5.30965 4.30966 4.75 5.00002 4.75H6.25V5C6.25 5.41421 6.58579 5.75 7 5.75C7.41422 5.75 7.75 5.41421 7.75 5V4.75H12.25V5C12.25 5.41421 12.5858 5.75 13 5.75C13.4142 5.75 13.75 5.41421 13.75 5V4.75H15C15.6903 4.75 16.25 5.30964 16.25 6V6.04C16.25 6.45421 16.5858 6.79 17 6.79C17.4142 6.79 17.75 6.45421 17.75 6.04V6C17.75 4.48121 16.5188 3.25 15 3.25H13.75V2C13.75 1.58579 13.4142 1.25 13 1.25C12.5858 1.25 12.25 1.58579 12.25 2V3.25H7.75V2ZM3.75 9C3.75 8.58578 3.41421 8.25 2.99999 8.25C2.58578 8.25 2.25 8.58579 2.25 9L2.25002 12C2.25002 12.4142 2.58581 12.75 3.00003 12.75C3.41424 12.75 3.75002 12.4142 3.75002 12L3.75 9ZM17.75 9C17.75 8.58579 17.4142 8.25 17 8.25C16.5858 8.25 16.25 8.58579 16.25 9L16.25 12C16.25 12.4142 16.5858 12.75 17 12.75C17.4142 12.75 17.75 12.4142 17.75 12L17.75 9ZM3.75002 14.96C3.75002 14.5458 3.41423 14.21 3.00002 14.21C2.5858 14.21 2.25002 14.5458 2.25002 14.96L2.25002 15C2.25002 16.5188 3.48124 17.75 5.00002 17.75H5.04C5.45421 17.75 5.79 17.4142 5.79 17C5.79 16.5858 5.45421 16.25 5.04 16.25H5.00002C4.30966 16.25 3.75002 15.6904 3.75002 15V14.96ZM17.75 14.96C17.75 14.5458 17.4142 14.21 17 14.21C16.5858 14.21 16.25 14.5458 16.25 14.96V15C16.25 15.6904 15.6903 16.25 15 16.25H14.96C14.5458 16.25 14.21 16.5858 14.21 17C14.21 17.4142 14.5458 17.75 14.96 17.75H15C16.5188 17.75 17.75 16.5188 17.75 15V14.96ZM8 16.25C7.58579 16.25 7.25 16.5858 7.25 17C7.25 17.4142 7.58579 17.75 8 17.75H12C12.4142 17.75 12.75 17.4142 12.75 17C12.75 16.5858 12.4142 16.25 12 16.25H8Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default Unscheduled;
