import React from 'react';

import BaseIcon, { Props } from './Icon';

const DirectoryIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2.75L15 2.74999C15.6904 2.74999 16.25 3.30963 16.25 3.99999L16.25 16C16.25 16.6904 15.6904 17.25 15 17.25L5.00002 17.25C4.30967 17.25 3.75002 16.6904 3.75002 16L3.75002 14.75H4.5C4.91421 14.75 5.25 14.4142 5.25 14C5.25 13.5858 4.91421 13.25 4.5 13.25H3.75002L3.75001 10.75H4.5C4.91421 10.75 5.25 10.4142 5.25 10C5.25 9.58579 4.91421 9.25 4.5 9.25H3.75001L3.75001 6.75H4.5C4.91421 6.75 5.25 6.41421 5.25 6C5.25 5.58578 4.91421 5.25 4.5 5.25H3.75L3.75 4C3.75 3.30964 4.30965 2.75 5 2.75ZM2.25 5.25L2.25 4C2.25 2.48122 3.48122 1.25 5 1.25L15 1.24999C16.5188 1.24999 17.75 2.48121 17.75 3.99999L17.75 16C17.75 17.5188 16.5188 18.75 15 18.75L5.00002 18.75C3.48124 18.75 2.25002 17.5188 2.25002 16L2.25002 14.75H1.5C1.08579 14.75 0.75 14.4142 0.75 14C0.75 13.5858 1.08579 13.25 1.5 13.25H2.25002L2.25001 10.75H1.5C1.08579 10.75 0.75 10.4142 0.75 10C0.75 9.58579 1.08579 9.25 1.5 9.25H2.25001L2.25001 6.75H1.5C1.08579 6.75 0.75 6.41421 0.75 6C0.75 5.58578 1.08579 5.25 1.5 5.25H2.25ZM10 5.5C8.89543 5.5 8 6.39543 8 7.5C8 8.60457 8.89543 9.5 10 9.5C11.1046 9.5 12 8.60457 12 7.5C12 6.39543 11.1046 5.5 10 5.5ZM6.66014 13.0165C7.60085 10.3278 12.3991 10.3278 13.3399 13.0165C13.5223 13.5378 13.0523 14 12.5 14L7.5 14C6.94771 14 6.47775 13.5378 6.66014 13.0165Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default DirectoryIcon;
