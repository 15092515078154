import React, { FC } from 'react';

import { useFeatureFlagJson } from 'src/hooks/feature-flag';

import AppWideBanner, { AppWideBannerAction } from './AppWideBanner';

const LaunchDarklyBanner: FC = () => {
  const inAppBanner = useFeatureFlagJson('in-app-banner');

  if (!inAppBanner || !inAppBanner.text) return null;

  const { text } = inAppBanner;

  let action: AppWideBannerAction | undefined;
  if (inAppBanner.url) {
    action = {
      label: inAppBanner.urlText || 'Learn more',
      href: inAppBanner.url,
    };
  }

  return <AppWideBanner text={text} action={action} />;
};

export default LaunchDarklyBanner;
