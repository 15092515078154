import React from 'react';

import BaseIcon, { Props } from './Icon';

const VerticalIndicatorIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4142 2.91422C10.6332 2.13317 9.36683 2.13317 8.58579 2.91422L2.91421 8.58579C2.13316 9.36684 2.13316 10.6332 2.91421 11.4142L8.58579 17.0858C9.36683 17.8668 10.6332 17.8668 11.4142 17.0858L17.0858 11.4142C17.8668 10.6332 17.8668 9.36684 17.0858 8.58579L11.4142 2.91422ZM8.93774 5.50364C9.08011 5.34237 9.28488 5.25 9.5 5.25H10.5C10.7151 5.25 10.9199 5.34237 11.0623 5.50364C11.2046 5.66492 11.2709 5.87956 11.2442 6.09302L10.7442 10.093C10.6973 10.4683 10.3782 10.75 10 10.75C9.62176 10.75 9.30271 10.4683 9.25579 10.093L8.75579 6.09302C8.72911 5.87956 8.79537 5.66492 8.93774 5.50364ZM11 13.5C11 14.0523 10.5523 14.5 10 14.5C9.44772 14.5 9 14.0523 9 13.5C9 12.9477 9.44772 12.5 10 12.5C10.5523 12.5 11 12.9477 11 13.5Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default VerticalIndicatorIcon;
