import { useMemo } from 'react';

import { gql } from '@apollo/client';

import { CalendarPermissionsQuery, useCalendarPermissionsQuery } from 'src/generated/mloop-graphql';

type ApplicationStageResult = Exclude<CalendarPermissionsQuery['applicationStage'], null | undefined>;

type Scheduler = NonNullable<ApplicationStageResult>['creator'];

export const CalendarPermissions = gql`
  query CalendarPermissions($applicationStageId: uuid!) {
    applicationStage(id: $applicationStageId) {
      id
      application {
        id
        candidateId
      }
      creator {
        id
        orgId
        email
        fullName
        slackImageUrl
      }
      applicationStageCandidateEvents {
        googleCalendarId
        startAt
        endAt
        name
        googleEventId
        calendar {
          name
          isPartOfCalendarList
          isReadOnly
        }
      }
      applicationStageInterviews {
        calendarEventTitle
        googleCalendarId
        id
        name
        startAt
        endAt
        calendar {
          id
          name
          remoteId
          employeeId
          isPartOfCalendarList
          isReadOnly
        }
      }
    }
  }
`;

export const useCalendarPermissionData = ({ applicationStageId }: { applicationStageId: string }) => {
  const { loading, data: calendarPermissionsData } = useCalendarPermissionsQuery({
    variables: { applicationStageId },
    skip: !applicationStageId,
  });

  const candidateId = calendarPermissionsData?.applicationStage?.application?.candidateId;

  const calendarDisplayData = useMemo(() => {
    const data: {
      scheduler: Scheduler;
      candidateCalendar: string;
      candidateCalendarFound: boolean;
      interviewerCalendar: string;
      interviewerCalendarFound: boolean;
      hasPermission: boolean;
      candidateEventCalendarId: string;
      interviewerEventCalendarId: string;
    } = {
      scheduler: undefined,
      candidateCalendar: 'No calendar event',
      candidateCalendarFound: false,
      interviewerCalendar: 'No calendar event',
      interviewerCalendarFound: false,
      hasPermission: true,
      candidateEventCalendarId: '',
      interviewerEventCalendarId: '',
    };
    if (!calendarPermissionsData) return data;

    const { applicationStage } = calendarPermissionsData;

    data.scheduler = applicationStage?.creator || undefined;

    if (!applicationStage) {
      return data;
    }

    const { applicationStageCandidateEvents: candidateEvents, applicationStageInterviews: interviews } =
      applicationStage;

    const candidateEvent = candidateEvents?.[0];
    if (candidateEvent) {
      const { calendar } = candidateEvent;

      if (calendar?.isPartOfCalendarList && !calendar?.isReadOnly) {
        data.candidateCalendarFound = true;
        data.candidateCalendar = calendar?.name || 'Unknown Calendar';
      } else {
        data.candidateCalendar = calendar?.name || 'Unknown Calendar';
      }
    } else {
      data.candidateCalendarFound = true;
    }

    const interview = interviews?.[0];
    if (interview) {
      const { calendar } = interview;
      if (calendar?.isPartOfCalendarList && !calendar?.isReadOnly) {
        data.interviewerCalendarFound = true;
        data.interviewerCalendar = calendar?.name || 'Unknown Calendar';
      } else {
        data.interviewerCalendar = calendar?.name || 'Unknown Calendar';
      }
    } else {
      data.interviewerCalendarFound = true;
    }

    if (!data.candidateCalendarFound || !data.interviewerCalendarFound) {
      data.candidateEventCalendarId = applicationStage?.applicationStageCandidateEvents?.[0]?.googleCalendarId ?? '';
      data.interviewerEventCalendarId = applicationStage?.applicationStageCandidateEvents?.[0]?.googleCalendarId ?? '';
      data.hasPermission = false;
    } else {
      data.hasPermission = true;
    }

    return data;
  }, [calendarPermissionsData]);

  return { ...calendarDisplayData, candidateId, loading };
};

export default useCalendarPermissionData;
