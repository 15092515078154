import React from 'react';

import { TeamIcon, UserSparkleIcon } from '@modernloop/shared/icons';

import { DynamicAudience } from 'src/generated/mloop-graphql';

export const getSmartOptionLabel = (type: DynamicAudience) => {
  switch (type) {
    case DynamicAudience.TaskAssignee:
      return 'Task assignee';
    case DynamicAudience.TaskCreator:
      return 'Task creator';
    case DynamicAudience.TaskSubscribers:
      return 'Task subscribers';
    case DynamicAudience.CandidateCoordinator:
      return 'Candidate coordinator';
    case DynamicAudience.CandidateRecruiter:
      return 'Candidate recruiter';
    case DynamicAudience.CandidateSourcer:
      return 'Candidate sourcer';
    case DynamicAudience.PreviousCandidateInterviewers:
      return 'Everyone who interviewed the candidate';
    case DynamicAudience.JobRecruiters:
      return 'Job recruiters';
    case DynamicAudience.JobCoordinators:
      return 'Job coordinators';
    case DynamicAudience.JobSourcers:
      return 'Job sourcers';
    case DynamicAudience.DebriefAttendees:
      return 'Attendees on the debrief';
    case DynamicAudience.InterviewersOnSchedule:
      return 'Interviewers on the schedule';
    default:
      return '';
  }
};

export const getDacIcon = (type: DynamicAudience) => {
  switch (type) {
    case DynamicAudience.TaskAssignee:
    case DynamicAudience.TaskCreator:
    case DynamicAudience.CandidateRecruiter:
    case DynamicAudience.CandidateCoordinator:
    case DynamicAudience.CandidateSourcer:
      return <UserSparkleIcon color="inherit" />;
    case DynamicAudience.TaskSubscribers:
    case DynamicAudience.PreviousCandidateInterviewers:
    case DynamicAudience.DebriefAttendees:
    case DynamicAudience.JobCoordinators:
    case DynamicAudience.JobSourcers:
    case DynamicAudience.JobRecruiters:
    case DynamicAudience.InterviewersOnSchedule:
      return <TeamIcon color="inherit" />;
    default:
      return <></>;
  }
};
