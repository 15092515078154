import React from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import { MaybeTooltip } from '@modernloop/shared/components';
import { CanDisable } from '@modernloop/shared/helper-components';
import {
  CircularProgress,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import { first } from 'lodash';

import FilterSelect from 'src/components/FilterList/FilterSelect';
import { Error } from 'src/components/HelperComponents';
import SelectButton from 'src/components/SelectButton';

import { Theme } from 'src/theme/type';

import { snakeCaseToInitCase } from 'src/utils/plainText';

import { Categories, ZoomUserDataWithCategory, ZoomUserSelectProps } from './types';
import useZoomUserSelectData from './useZoomUserSelectData';

const EMPTY_ROW_ID = 'loading-empty-row';

export const GQLZoomUserFragment = gql`
  fragment ZoomUserFragment on ZoomUser {
    orgId
    zoomUserId
    status
    email
    employeeId
    firstName
    lastName
  }
`;

export const GQLZoomUsersListQuery = gql`
  query ZoomUsersList($input: ZoomUsersInput!) {
    zoomUsers(input: $input) {
      items {
        ...ZoomUserFragment
      }
      nextCursor
    }
  }
`;

export const GQLZoomUsersByIdsQuery = gql`
  query ZoomUsersByIds($input: ZoomUsersByIdsInput!) {
    zoomUsersByIds(input: $input) {
      ...ZoomUserFragment
    }
  }
`;

export const GQLZoomUserByIdsQuery = gql`
  query ZoomUserByIds($input: ZoomUsersByIdsInput!) {
    zoomUsersByIds(input: $input) {
      ...ZoomUserFragment
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      width: '100%',
      marginTop: `${theme.spacing(1.5)}px`,
      marginBottom: `${theme.spacing(1.5)}px`,
    },

    box: {
      marginTop: `${theme.spacing(1.5)}px`,
    },
  })
);

const ZoomUserSelect = <TData extends ZoomUserDataWithCategory, InputValue extends string | string[] | undefined>(
  props: ZoomUserSelectProps<TData, InputValue>
): JSX.Element => {
  const classes = useStyles();

  const {
    dataTestId,
    disabled,
    getLabel,
    isAdditionalOptionId,
    renderAdditionalOption,
    renderAdditionalOptionGroup,
    getAdditionalOptionSize,
    getAdditionalOptionLabel,
  } = props;

  const {
    filterListSelectInterfaceRef,
    handleChange,
    handleScrollToEnd,
    isFetchingMore,
    onInputChange,
    options,
    selectedUsers,
    selectedValues,
    isZoomUsersListLoading,
    isZoomUsersListQueryError,
    isSearchEmpty,
  } = useZoomUserSelectData(props);

  const renderGroup = (params: AutocompleteRenderGroupParams) => {
    if (renderAdditionalOptionGroup && params.group === Categories.AdditionalOptions) {
      return renderAdditionalOptionGroup(params);
    }

    const group: React.ReactNode[] = [];
    if (params.group) {
      group.push(
        <ListSubheader
          disableSticky
          key={params.key}
          component="div"
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontWeight: (theme) => theme.typography.fontWeightMedium,
            padding: (theme) => `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
          }}
        >
          <Typography variant="caption" color="info" fontWeight={600}>
            {params.group === Categories.RecentAccounts && snakeCaseToInitCase(Categories.RecentAccounts)}
            {params.group === Categories.InterviewPanel && snakeCaseToInitCase(Categories.InterviewPanel)}
            {params.group === Categories.AllAccounts && snakeCaseToInitCase(Categories.AllAccounts)}
          </Typography>
        </ListSubheader>
      );
    }
    group.push(params.children);
    return group;
  };

  const getOptionLabel = (option: ZoomUserDataWithCategory) => {
    if (option.id && getAdditionalOptionLabel && isAdditionalOptionId && isAdditionalOptionId(option.id)) {
      return getAdditionalOptionLabel(option);
    }
    return `${option.firstName} ${option.lastName} (${option.email})`;
  };

  const renderOption = (liProps: React.HTMLAttributes<HTMLLIElement>, option: ZoomUserDataWithCategory) => {
    let jsx: React.ReactNode = null;
    if (option.id === EMPTY_ROW_ID) {
      jsx = (
        <Stack direction="row" justifyContent="center">
          <CircularProgress size={20} />
        </Stack>
      );
    }
    if (option.id && isAdditionalOptionId && isAdditionalOptionId(option.id) && renderAdditionalOption) {
      return (
        <ListItem {...liProps} data-testid={option.id}>
          <ListItemButton>{renderAdditionalOption(option)}</ListItemButton>
        </ListItem>
      );
    }

    if (option.id) if (!option.email) return null;

    jsx = (
      <MaybeTooltip
        title={`${option.firstName} ${option.lastName} (${option.email})`}
        label={`${option.firstName} ${option.lastName} (${option.email})`}
      />
    );

    return (
      <ListItem {...liProps}>
        <ListItemButton>{jsx}</ListItemButton>
      </ListItem>
    );
  };

  const defaultGetLabel = () => {
    const firstSelectedUser = first(selectedUsers);

    const label = firstSelectedUser ? getOptionLabel(firstSelectedUser) : 'Select Zoom user';
    if (getLabel) {
      return getLabel(label);
    }
    return (
      <SelectButton
        size="medium"
        onClick={() => {}}
        label={
          <Typography noWrap color="text.secondary">
            {label}
          </Typography>
        }
        className={classes.box}
      />
    );
  };

  const getChildSize = (child: React.ReactNode) => {
    if (!child) return 0;

    // These values is by inspecting DOM.
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 32;
    }

    const children = (child as React.ReactElement)?.props?.children
      ? React.Children.toArray((child as React.ReactElement)?.props.children)
      : undefined;
    if (
      children &&
      children.length === 1 &&
      React.isValidElement(children[0]) &&
      isAdditionalOptionId &&
      children[0].props.children.props['data-testid'] &&
      isAdditionalOptionId(children[0].props.children.props['data-testid']) &&
      getAdditionalOptionSize
    ) {
      return getAdditionalOptionSize(child);
    }
    return 32;
  };

  if (isFetchingMore) {
    options.push({
      id: EMPTY_ROW_ID,
    } as ZoomUserDataWithCategory);
  }

  // Adding a check for search query to avoid unmounting of FilterSelect on entering a search query.
  if (isZoomUsersListLoading && isSearchEmpty) {
    return <LinearProgress />;
  }

  if (isZoomUsersListQueryError) {
    return <Error error="Zoom Users failed to load." />;
  }

  return (
    <CanDisable disabled={Boolean(disabled)}>
      <FilterSelect
        dataTestId={dataTestId}
        values={selectedValues}
        isOptionEqualToValue={(option, listValue) => option?.zoomUserId === listValue?.zoomUserId}
        filterSelectedOptions
        filterListSelectInterfaceRef={filterListSelectInterfaceRef}
        options={options}
        loading={isZoomUsersListLoading}
        placeholderText="Select Zoom user"
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderGroup={renderGroup}
        groupBy={(option) => option?.category ?? ''}
        onChange={handleChange}
        getLabel={defaultGetLabel}
        onScrollToEnd={handleScrollToEnd}
        onInputChange={onInputChange}
        getChildSize={getChildSize}
      />
    </CanDisable>
  );
};

export default ZoomUserSelect;
