import React, { FC, useMemo } from 'react';
import ApexChart, { Props as ApexChartProps } from 'react-apexcharts';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core';
import { flatMap } from 'lodash';

import { ArrayElement, ElementType } from 'src/types/utils';

import ZeroState, { ZeroStateProps as InternalZeroStateProps } from '../ZeroState';

export const useStyles = makeStyles(() =>
  createStyles({
    noDataWrapper: (props: { height?: number }) => ({
      minHeight: props.height && `${props.height}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  })
);

/**
 * Data point always is x & y as per FE and BE data contract
 */
export type DataPoint = Exclude<
  ElementType<
    ArrayElement<
      Exclude<
        ArrayElement<ElementType<ApexChartProps['series']>>,
        number | [number, number | null][] | [number, (number | null)[]][]
      >['data']
    >
  >,
  number | [number, number | null] | [number, (number | null)[]]
>;

export type DataPoints = DataPoint[];
type SeriesRest = Omit<Exclude<ArrayElement<ElementType<ApexChartProps['series']>>, number>, 'data'>;
export type SingleSeries = SeriesRest & { data: DataPoints };
export type Series = SingleSeries[];

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export interface ZeroStateProps extends InternalZeroStateProps {
  type: ApexChartProps['type'];
  series?: ApexChartProps['series'];
  height?: number;
}
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export const ZeroStateWrapper: FC<ZeroStateProps> = ({ children, series, type, height, ...zeroState }) => {
  const classes = useStyles({ height });

  const isEmpty = useMemo(() => {
    if (series?.length) {
      if (type === 'pie') {
        const toComputeSeries = series as number[];
        return !toComputeSeries.find((value) => {
          return value !== 0;
        });
      }
      const allData = flatMap(series, (value: SingleSeries) => {
        return value.data;
      }) as DataPoints;
      return !allData.find((value) => {
        return value.y !== 0;
      });
    }
    return true;
  }, [series, type]);

  if (isEmpty) {
    return (
      <div className={classes.noDataWrapper}>
        <ZeroState {...zeroState} />
      </div>
    );
  }

  return <>{children}</>;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp, modernloop/restrict-props-name.cjs
const Chart: FC<ApexChartProps> = (props) => {
  return <ApexChart {...props} />;
};
export default Chart;
