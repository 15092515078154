import React, { FC, useCallback, useState } from 'react';

import { gql } from '@apollo/client';

import { useInterviewModuleMemberMenuOptionsQuery } from 'src/generated/mloop-graphql';

import { MenuOption } from 'src/components/menu';

import InterviewModuleMember from 'src/views-new/Common/InterviewModuleMember/Sidepanel';
import PauseMemberModal from 'src/views-new/Common/InterviewModuleMember/Sidepanel/Modals/PauseMemberModal';
import RemoveMemberModal from 'src/views-new/Common/InterviewModuleMember/Sidepanel/Modals/RemoveMemberModal';
import SetModuleMemberAsTrained from 'src/views-new/Common/InterviewModuleMember/Sidepanel/Modals/SetModuleMemberAsTrained';
import SetModuleMemberAsTrainee from 'src/views-new/Common/InterviewModuleMember/Sidepanel/Modals/SetModuleMemberAsTrainee';

import SidePanelContainer from '../common/SidePanelContainer';
import { SidePanelManager } from '../common/SidePanelManager';

import { MoreMenuOptions, useInterviewModuleMemberMenuOptions } from './useInterviewModuleMemberOptions';

// TODO Naveen destructure your pause modal fragment here
export const InterviewModuleMemberMenuOptions = gql`
  ${useInterviewModuleMemberMenuOptions.fragments.moduleMember}
  ${RemoveMemberModal.fragments.interviewModuleMember}
  ${SetModuleMemberAsTrained.fragments.interviewModuleMember}
  ${PauseMemberModal.fragments.moduleMember}
  ${SetModuleMemberAsTrainee.fragments.moduleMember}
  query InterviewModuleMemberMenuOptions($interviewModuleId: uuid!, $employeeId: uuid!) {
    interviewModuleMember(interviewModuleId: $interviewModuleId, employeeId: $employeeId) {
      employeeId
      interviewModuleId
      ...InterviewModuleMemberMenuOptions
      ...RemoveMemberModal_interviewModuleMember
      ...SetModuleMemberAsTrained_interviewModuleMember
      ...pauseMemberModal_moduleMember
      ...SetModuleMemberAsTrainee_moduleMember
    }
  }
`;

type Props = {
  interviewModuleId: string;
  moduleMemberId: string;
};

const InterviewModuleMemberSidepanel: FC<Props> = ({ interviewModuleId, moduleMemberId }) => {
  const { data } = useInterviewModuleMemberMenuOptionsQuery({
    variables: {
      interviewModuleId,
      employeeId: moduleMemberId,
    },
    skip: !interviewModuleId || !moduleMemberId,
  });

  const menuOptions = useInterviewModuleMemberMenuOptions(
    { moduleMember: data?.interviewModuleMember || undefined },
    undefined
  );

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPauseModal, setOpenPauseModal] = useState(false);
  const [openSetModuleMemberAsTrained, setOpenSetModuleMemberAsTrained] = useState(false);
  const [openSetModuleMemberAsTrainee, setOpenSetModuleMemberAsTrainee] = useState(false);

  const handleMenuOptionSelected = useCallback((option: MenuOption) => {
    switch (option.id) {
      case MoreMenuOptions.Delete: {
        setOpenDeleteModal(true);
        break;
      }
      case MoreMenuOptions.SET_AS_TRAINEE: {
        setOpenSetModuleMemberAsTrainee(true);
        break;
      }
      case MoreMenuOptions.SET_AS_TRAINED: {
        setOpenSetModuleMemberAsTrained(true);
        break;
      }
      default:
    }
  }, []);

  return (
    <SidePanelContainer moreMenuOptions={menuOptions} onMenuOptionSelected={handleMenuOptionSelected}>
      <InterviewModuleMember interviewModuleId={interviewModuleId} moduleMemberId={moduleMemberId} />
      {openDeleteModal && data?.interviewModuleMember && (
        <RemoveMemberModal
          interviewModuleMember={data?.interviewModuleMember}
          onDelete={() => {
            setOpenDeleteModal(false);
            SidePanelManager.closeSidePanel();
          }}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
          onPauseClick={() => {
            setOpenDeleteModal(false);
            setOpenPauseModal(true);
          }}
          open={openDeleteModal}
        />
      )}
      {openPauseModal && data?.interviewModuleMember && (
        <PauseMemberModal onClose={() => setOpenPauseModal(false)} moduleMember={data?.interviewModuleMember} />
      )}
      {openSetModuleMemberAsTrained && data?.interviewModuleMember && (
        <SetModuleMemberAsTrained
          interviewModuleMember={data?.interviewModuleMember}
          onClose={() => {
            setOpenSetModuleMemberAsTrained(false);
          }}
          onUpdate={() => {
            setOpenSetModuleMemberAsTrained(false);
          }}
          open={openSetModuleMemberAsTrained}
        />
      )}
      {openSetModuleMemberAsTrainee && data?.interviewModuleMember && (
        <SetModuleMemberAsTrainee
          moduleMember={data?.interviewModuleMember}
          onClose={() => {
            setOpenSetModuleMemberAsTrainee(false);
          }}
          onUpdate={() => {
            setOpenSetModuleMemberAsTrainee(false);
          }}
          open={openSetModuleMemberAsTrainee}
        />
      )}
    </SidePanelContainer>
  );
};

export default InterviewModuleMemberSidepanel;
