import React, { useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { assertIsoTimestamp, formatToTimeZone, getLocalTimezone } from '@modernloop/shared/datetime';
import { PublicError } from '@modernloop/shared/helper-components';
import { EditIcon, PauseIcon, TrashIcon } from '@modernloop/shared/icons';
import { logError } from '@modernloop/shared/utils';
import { IconButton, ListItem, ListItemButton, Stack, Typography } from '@mui/material';

import {
  ModuleLoadPauseRow_ModuleMemberFragment,
  useModuleLoadPauseRowInterviewModuleMemberUpdateMutation,
} from 'src/generated/mloop-graphql';

import {
  isModuleMemberEmployeePaused,
  isModuleMemberPauseIndefinitely,
  isModuleMemberPaused,
  resetModuleMemberListCache,
} from 'src/entities/InterviewModuleMember/utils';

import PauseMemberModal from '../Modals/PauseMemberModal';

type Fragments = { moduleMember: ModuleLoadPauseRow_ModuleMemberFragment };

const ModuleLoadPauseRow: FCWithFragments<Fragments> = ({ moduleMember }) => {
  const [clearPause, { error: pauseError }] = useModuleLoadPauseRowInterviewModuleMemberUpdateMutation();

  const [openPauseMemberModal, setOpenPauseMemberModal] = useState(false);

  const timezone = moduleMember.employee?.timezone || getLocalTimezone();

  const getPauseText = () => {
    if (moduleMember?.isPausedIndefinitely) {
      return 'Resumes never';
    }
    if (!moduleMember?.pausedUntil) {
      return 'Paused from module';
    }

    return `Paused until ${formatToTimeZone(assertIsoTimestamp(moduleMember?.pausedUntil), 'LLLL d, yyyy', timezone)}`;
  };

  const handleClearPause = async () => {
    try {
      await clearPause({
        variables: {
          input: {
            employeeId: moduleMember.employeeId,
            interviewModuleId: moduleMember.interviewModuleId,
            clearPaused: true,
          },
        },
        update: (cache) => {
          resetModuleMemberListCache({
            cache,
            employeeIds: [moduleMember.employeeId],
            moduleId: moduleMember.interviewModuleId,
          });
        },
      });
    } catch (error) {
      logError(error);
    }
  };

  const getPauseContent = () => {
    let jsx: JSX.Element | null = null;
    if (isModuleMemberPaused({ moduleMember }, null) || isModuleMemberPauseIndefinitely({ moduleMember }, null)) {
      jsx = (
        <Stack flexDirection="row" width="100%" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">{getPauseText()}</Typography>
          <Stack flexDirection="row">
            <IconButton
              onClick={() => {
                setOpenPauseMemberModal(true);
              }}
            >
              <EditIcon color="action" />
            </IconButton>
            <IconButton
              onClick={() => {
                handleClearPause();
              }}
            >
              <TrashIcon color="action" />
            </IconButton>
          </Stack>
        </Stack>
      );
    }

    if (jsx) {
      return (
        <Stack direction="row" alignItems="center" columnGap={1}>
          <PauseIcon color={isModuleMemberEmployeePaused({ moduleMember }, null) ? 'disabled' : 'action'} />
          <Stack
            width="100%"
            color={isModuleMemberEmployeePaused({ moduleMember }, null) ? 'text.secondary' : 'action'}
          >
            {jsx}
          </Stack>
        </Stack>
      );
    }

    return (
      <ListItem>
        <ListItemButton
          sx={{
            marginX: -1.5,
            cursor: 'pointer',
          }}
          onClick={() => {
            setOpenPauseMemberModal(true);
          }}
        >
          <Stack direction="row" alignItems="center" columnGap={1}>
            <PauseIcon color="primary" />
            <Typography color="primary" variant="body2">
              Pause from module
            </Typography>
          </Stack>
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <>
      {getPauseContent()}

      {pauseError && <PublicError error={pauseError} />}
      {openPauseMemberModal && (
        <PauseMemberModal moduleMember={moduleMember} onClose={() => setOpenPauseMemberModal(false)} />
      )}
    </>
  );
};

ModuleLoadPauseRow.fragments = {
  moduleMember: gql`
    ${PauseMemberModal.fragments.moduleMember}

    ${isModuleMemberEmployeePaused.fragments.moduleMember}
    ${isModuleMemberPaused.fragments.moduleMember}
    ${isModuleMemberPauseIndefinitely.fragments.moduleMember}
    fragment moduleLoadPauseRow_moduleMember on InterviewModuleMember {
      id
      interviewModuleId
      employeeId
      weeklyNumInterviewLimit
      ...pauseMemberModal_moduleMember
      employee {
        id
        isPaused
        timezone
        interviewPauseDates {
          start
          end
        }
      }
      ...isModuleMemberEmployeePaused_ModuleMember
      ...isModuleMembreOrEmployeePaused_ModuleMember
      ...isModuleMemberPauseIndefinitely_ModuleMember
    }
  `,
};

export const ModuleLoadPauseRowInterviewModuleMemberUpdate = gql`
  ${ModuleLoadPauseRow.fragments.moduleMember}
  mutation ModuleLoadPauseRowInterviewModuleMemberUpdate($input: InterviewModuleMemberUpdateInput!) {
    interviewModuleMemberUpdate(input: $input) {
      interviewModuleMember {
        ...moduleLoadPauseRow_moduleMember
      }
    }
  }
`;

export default ModuleLoadPauseRow;
