import { SvgIcon, SvgIconProps } from './SvgIcon';

export const TraineePendingApprovalIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.34152 4.32934L9.44086 2.27967C9.79277 2.10372 10.207 2.10372 10.5589 2.27967L14.6582 4.32934C15.211 4.60574 15.211 5.39459 14.6582 5.67098L10.5589 7.72065C10.207 7.89661 9.79277 7.89661 9.44086 7.72065L5.34152 5.67098C4.78873 5.39459 4.78873 4.60574 5.34152 4.32934ZM6.99989 7.99993V7.61796L9.10547 8.67075C9.66852 8.95227 10.3313 8.95227 10.8943 8.67075L12.9999 7.61796V7.99993C12.9999 9.65678 11.6567 10.9999 9.99989 10.9999C8.34304 10.9999 6.99989 9.65678 6.99989 7.99993ZM9.99989 12.2499C10.622 12.2499 11.2651 12.3275 11.8861 12.489C11.3318 13.1755 10.9999 14.049 10.9999 15C10.9999 16.0652 11.4162 17.0331 12.0951 17.7499L6.00009 17.7499C5.52143 17.7499 5.06543 17.5531 4.75173 17.2139C4.42824 16.8641 4.2567 16.3562 4.40147 15.8154C5.06675 13.33 7.67907 12.2499 9.99989 12.2499ZM15.2499 7.99995C15.2499 7.58574 14.9141 7.24995 14.4999 7.24995C14.0857 7.24995 13.7499 7.58574 13.7499 7.99995V8.99995C13.7499 9.41416 14.0857 9.74995 14.4999 9.74995C14.9141 9.74995 15.2499 9.41416 15.2499 8.99995V7.99995ZM14.94 12.5C14.6899 12.5 14.4998 12.6202 14.3874 12.8014C14.1692 13.1535 13.7069 13.2619 13.3548 13.0437C13.0028 12.8254 12.8943 12.3631 13.1126 12.0111C13.4866 11.4077 14.1488 11 14.94 11C16.1896 11 17.0025 12.0416 17.0025 13.0625C17.0025 13.5283 16.8812 13.9099 16.6535 14.2224C16.4522 14.4986 16.1946 14.6767 16.0391 14.7841L16.0231 14.7952C15.8384 14.9231 15.7798 14.9698 15.7415 15.02L15.7409 15.0207C15.7268 15.0393 15.69 15.0875 15.69 15.25C15.69 15.6642 15.3542 16 14.94 16C14.5258 16 14.19 15.6642 14.19 15.25C14.19 14.7902 14.3163 14.4154 14.5486 14.1106C14.7434 13.8551 14.9922 13.6839 15.1407 13.5816L15.1407 13.5816L15.1693 13.5619C15.3441 13.4409 15.4035 13.3908 15.4413 13.339C15.4596 13.3138 15.5025 13.251 15.5025 13.0625C15.5025 12.7709 15.2654 12.5 14.94 12.5ZM15.75 17.5C15.75 17.9142 15.4142 18.25 15 18.25C14.5858 18.25 14.25 17.9142 14.25 17.5C14.25 17.0858 14.5858 16.75 15 16.75C15.4142 16.75 15.75 17.0858 15.75 17.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
