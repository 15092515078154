import { SvgIcon, SvgIconProps } from './SvgIcon';

export const RsvpWaitingLeftIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62469 18.7002C2.96993 19.2241 2 18.7579 2 17.9194V5C2 3.34315 3.34315 2 5 2H15C16.6569 2 18 3.34315 18 5V13C18 14.6569 16.6569 16 15 16H8.05234C7.37113 16 6.7102 16.2318 6.17826 16.6574L3.62469 18.7002ZM6.25 10.5C6.94036 10.5 7.5 9.94036 7.5 9.25C7.5 8.55964 6.94036 8 6.25 8C5.55964 8 5 8.55964 5 9.25C5 9.94036 5.55964 10.5 6.25 10.5ZM11.25 9.25C11.25 9.80228 10.8023 10.25 10.25 10.25C9.69772 10.25 9.25 9.80228 9.25 9.25C9.25 8.69772 9.69772 8.25 10.25 8.25C10.8023 8.25 11.25 8.69772 11.25 9.25ZM14.25 10C14.6642 10 15 9.66421 15 9.25C15 8.83579 14.6642 8.5 14.25 8.5C13.8358 8.5 13.5 8.83579 13.5 9.25C13.5 9.66421 13.8358 10 14.25 10Z"
        fill="#B9B9BE"
      />
    </SvgIcon>
  );
};
