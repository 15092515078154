import React, { FC } from 'react';

import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineProps,
  TimelineSeparator,
} from '@mui/lab';

type Props = {
  icon: React.ReactNode;
  sx?: TimelineProps['sx'];
};

const TimelineItemWrapper: FC<Props> = ({ children, icon, sx = {} }) => {
  return (
    <TimelineItem sx={{ minHeight: 'unset', ...sx }}>
      <TimelineSeparator sx={{ px: 0 }}>
        {icon && (
          <TimelineDot sx={{ backgroundColor: 'transparent', boxShadow: 'none', padding: 0.25, my: 0.5 }}>
            {icon}
          </TimelineDot>
        )}
        {!icon && <TimelineDot sx={{ p: 0, mx: 1.5 }} />}
        <TimelineConnector sx={{ backgroundColor: (theme) => theme.palette.border, width: '1px' }} />
      </TimelineSeparator>
      <TimelineContent sx={{ mt: icon ? 0.5 : -1, mb: 0.5, px: 0.25 }}>{children}</TimelineContent>
    </TimelineItem>
  );
};

export default TimelineItemWrapper;
