/* eslint-disable max-lines */
import React, { FC, useState } from 'react';
import { useParams } from 'react-router';

import { useFlag } from '@modernloop/shared/feature-flag';
import { InfoIcon, MagicIcon, TriangleDownIcon } from '@modernloop/shared/icons';
import { Box, FormControlLabel, Stack, Switch, Typography } from '@mui/material';

import {
  DynamicAudience,
  EmployeeByIdsQuery,
  EmployeeFragment,
  NotificationPreferenceInput,
  OptionsInput,
  SelfScheduleLocation,
  SelfScheduleMeetingHost,
  SelfScheduleOptionsSettingInput,
  SelfScheduleZoomHost,
  TemplateType,
} from 'src/generated/mloop-graphql';

import Alert from 'src/components/Alert';
import Divider from 'src/components/Divider';
import Link from 'src/components/Link';
import Select, { MenuOption } from 'src/components/Select';
import TextField from 'src/components/TextField';
import Button from 'src/components/button';
import Checkbox from 'src/components/checkbox';
import Label from 'src/components/label';
import useEqualSizeSxProps from 'src/components/utils/useEqualSizeSxProps';

import EmployeePickerExtended from 'src/entities/EmployeePicker/EmployeePickerExtended';
import SmartEmployeeSelect from 'src/entities/Select/SmartEmployeeSelect';
import { ZoomUserDataWithCategory } from 'src/entities/Select/ZoomUserSelect/types';
import ZoomUserSelectWithSmartOptions, { SmartOptions } from 'src/entities/Select/ZoomUserSelectWithSmartOptions';
import TemplateSelect from 'src/entities/Template/TemplateSelect';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import CalendarSelect from 'src/views-new/ScheduleFlow/Steps/Communications/CalendarCard/CalendarSelect';

import VideoMeetingHostSelect from './HostSelect/VideoMeetingHostSelect';
import { KeywordTooltip } from './KeywordTooltip';
import RequiredAdvanceNotice from './RequiredAdvanceNotice';

export interface SelfScheduleSelectedTemplates {
  candidateEmailTemplateId?: string;
  candidateEventTemplateId?: string;
  interviewerEventTemplateId?: string;
  requestEmailTemplateId?: string;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface SelfScheduleAdditionalOptionsProps {
  title?: string;
  classes: Record<string, string>;
  alertJsx?: JSX.Element | boolean;
  onOptionsChanged: (value: SelfScheduleOptionsSettingInput) => void;
  options: SelfScheduleOptionsSettingInput;
  templates: SelfScheduleSelectedTemplates;
  defaultTemplates: SelfScheduleSelectedTemplates;
  isPersonalizedEmailChecked: boolean;
  onNoteChanged: (value: string) => void;
  onTemplatesChanged: (value: SelfScheduleSelectedTemplates) => void;
  locationOptions: MenuOption[];
  handleCustomLinksInput: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleCustomLinksInputComplete: () => void;
  handleZoomUserChanged: (value: ZoomUserDataWithCategory) => void;
  employeeMissingZoomUserIds: string[] | undefined;
  missingEmployeesData: EmployeeByIdsQuery | undefined;
  handlePersonalizedEmailCheckboxChange: (checked: boolean) => void;
  hideRequestTemplateSelector: boolean;
  showCandidateEventTemplateSelector: boolean;
  showScheduleLocation?: boolean;
  handleCcRecipients?: (ccs: NotificationPreferenceInput) => void;
  handleBccRecipients?: (bccs: NotificationPreferenceInput) => void;
  ccRecipients?: NotificationPreferenceInput | null;
  bccRecipients?: NotificationPreferenceInput | null;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const SelfSchedulePreferences: FC<SelfScheduleAdditionalOptionsProps> = ({
  title,
  classes,
  alertJsx,
  onOptionsChanged,
  options,
  templates,
  defaultTemplates,
  onNoteChanged,
  onTemplatesChanged,
  locationOptions,
  handleCustomLinksInput,
  handleCustomLinksInputComplete,
  handleZoomUserChanged,
  employeeMissingZoomUserIds,
  missingEmployeesData,
  isPersonalizedEmailChecked,
  handlePersonalizedEmailCheckboxChange,
  hideRequestTemplateSelector,
  showCandidateEventTemplateSelector,
  showScheduleLocation,
  handleCcRecipients,
  handleBccRecipients,
  ccRecipients,
  bccRecipients,
}: SelfScheduleAdditionalOptionsProps) => {
  const sxProps = useEqualSizeSxProps();
  const [showBccBtn, setShowBccBtn] = useState(false);
  const useCustomSelfScheduleEventInvite = useFlag('user_custom_self_schedule_event_template');
  const smartOptionsEnabled = useFlag('user_employee_smart_options');
  const { jobID } = useParams<{ jobID: string }>();

  let zoomUserSelectWithSmartOptionsValue = options.zoomHostUserId ?? '';
  if (options.zoomHost === SelfScheduleZoomHost.Interviewer) {
    zoomUserSelectWithSmartOptionsValue = SmartOptions.Interviewer;
  } else {
    zoomUserSelectWithSmartOptionsValue = SmartOptions.Scheduler;
  }

  return (
    <Stack
      direction="column"
      sx={{
        margin: 0,
      }}
      spacing={2}
    >
      <ConditionalThemeProvider>
        <Stack direction="column" spacing={1}>
          {title && <Typography variant="subtitle1">{title}</Typography>}

          <KeywordTooltip
            title="Free times"
            description="Empty space in an interviewer’s calendar or events where the status is set to free (not busy)."
          >
            <FormControlLabel
              sx={{
                justifyContent: 'space-between',
              }}
              control={
                <Switch
                  checked={!!options.canScheduleOverFreeTime}
                  onChange={() =>
                    onOptionsChanged({
                      ...options,
                      canScheduleOverFreeTime: !options.canScheduleOverFreeTime,
                    })
                  }
                />
              }
              labelPlacement="start"
              label="Schedule over free time"
            />
          </KeywordTooltip>

          <KeywordTooltip
            title="Recruiting blocks"
            description="Priority interviewing time! Interviewers are expected to move other events if needed. We’ll schedule over any other non-interview event during this time that isn’t marked with an Avoid keyword or Out Of Office."
          >
            <FormControlLabel
              sx={{
                justifyContent: 'space-between',
              }}
              control={
                <Switch
                  checked={!!options.canScheduleOverRecruitingKeywords}
                  onChange={() =>
                    onOptionsChanged({
                      ...options,
                      canScheduleOverRecruitingKeywords: !options.canScheduleOverRecruitingKeywords,
                    })
                  }
                />
              }
              labelPlacement="start"
              label="Schedule over recruiting block keywords"
            />
          </KeywordTooltip>

          <KeywordTooltip
            title="Available"
            description="Events with these keywords are treated as safe to schedule over."
          >
            <FormControlLabel
              sx={{
                justifyContent: 'space-between',
              }}
              control={
                <Switch
                  checked={!!options.canScheduleOverAvailableKeywords}
                  onChange={() =>
                    onOptionsChanged({
                      ...options,
                      canScheduleOverAvailableKeywords: !options.canScheduleOverAvailableKeywords,
                    })
                  }
                />
              }
              labelPlacement="start"
              label="Schedule over available keywords"
            />
          </KeywordTooltip>

          {(options.canScheduleOverAvailableKeywords ||
            options.canScheduleOverRecruitingKeywords ||
            options.canScheduleOverFreeTime) && (
            <KeywordTooltip
              title="Load limits"
              description="The number of events or hours an interviewer can dedicate to interviewing in a day or week."
            >
              <FormControlLabel
                sx={{
                  justifyContent: 'space-between',
                }}
                control={
                  <Switch
                    checked={!options.shouldRespectLoadLimit}
                    onChange={() =>
                      onOptionsChanged({
                        ...options,
                        shouldRespectLoadLimit: !options.shouldRespectLoadLimit,
                      })
                    }
                  />
                }
                labelPlacement="start"
                label="Ignore interviewer load limits"
              />
            </KeywordTooltip>
          )}

          <RequiredAdvanceNotice
            value={options.advanceNoticeInHours}
            onChange={(value) => onOptionsChanged({ ...options, advanceNoticeInHours: value || 0 })}
          />

          {alertJsx}
        </Stack>
      </ConditionalThemeProvider>

      {showScheduleLocation && <Divider />}

      {showScheduleLocation && (
        <Stack direction="column" spacing={2}>
          <Label fontWeight={600}>Where does this interview take place?</Label>

          <Stack
            alignItems="center"
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            sx={sxProps.equalSizeChild}
            spacing={1}
          >
            <Box>
              <Label>Location</Label>
            </Box>
            <Box>
              <Select
                fullWidth
                dataTestId="self-schedule-options-location-select"
                options={locationOptions}
                onSelect={(value) => {
                  onOptionsChanged({
                    ...options,
                    location: value.id as SelfScheduleLocation,
                    zoomHost:
                      value.id === SelfScheduleLocation.Zoom
                        ? SelfScheduleZoomHost.Interviewer
                        : SelfScheduleZoomHost.NotSet,
                    zoomHostUserId:
                      value.id === SelfScheduleLocation.Zoom ? SmartOptions.Interviewer : SelfScheduleLocation.None,
                    meetingHost:
                      value.id === SelfScheduleLocation.Google || value.id === SelfScheduleLocation.MicrosoftTeams
                        ? SelfScheduleMeetingHost.Interviewer
                        : undefined,
                    videoMeetingHostEmployeeId: undefined,
                  });
                }}
                selectedOption={locationOptions.find((val) => val.id === options.location) || locationOptions[0]}
              />
            </Box>
          </Stack>

          {options.location === SelfScheduleLocation.Custom && (
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              sx={sxProps.equalSizeChild}
              justifyContent="space-between"
            >
              <Box>
                <Label>Custom link</Label>
              </Box>
              <Box>
                <TextField
                  placeholder="Paste your link here"
                  onChange={handleCustomLinksInput}
                  onBlur={handleCustomLinksInputComplete}
                  defaultValue={options?.customLocation ?? ''}
                />
              </Box>
            </Stack>
          )}
          {options.location === SelfScheduleLocation.Zoom && (
            <Stack
              alignItems="center"
              direction={{ xs: 'column', sm: 'row' }}
              sx={sxProps.equalSizeChild}
              justifyContent="space-between"
              spacing={1}
            >
              <Box>
                <Label>Zoom host</Label>
              </Box>
              <Box>
                <ZoomUserSelectWithSmartOptions
                  value={zoomUserSelectWithSmartOptionsValue}
                  panelInterviewerIds={[]}
                  onChange={handleZoomUserChanged}
                  getLabel={(label) => {
                    return (
                      <Button
                        variant="outlined"
                        color="default"
                        fullWidth
                        className={classes.zoomUserButton}
                        startIcon={
                          options.zoomHost === SelfScheduleZoomHost.Interviewer ||
                          options.zoomHost === SelfScheduleZoomHost.Scheduler ? (
                            <MagicIcon color="primary" />
                          ) : undefined
                        }
                        endIcon={<TriangleDownIcon />}
                        label={
                          <Label noWrap className={classes.zoomUserLabel}>
                            {label}
                          </Label>
                        }
                        tooltip={label}
                      />
                    );
                  }}
                />
              </Box>
            </Stack>
          )}
          {options.location && (
            <VideoMeetingHostSelect
              location={options.location}
              meetingHost={options.meetingHost || undefined}
              videoMeetingHostEmployeeId={options.videoMeetingHostEmployeeId}
              onMeetingHostChanged={(meetingHost: SelfScheduleMeetingHost) => {
                onOptionsChanged({
                  ...options,
                  meetingHost,
                  videoMeetingHostEmployeeId: undefined,
                });
              }}
              onVideoMeetingHostEmployeeIdChanged={(videoMeetingHostEmployeeId: string) => {
                onOptionsChanged({
                  ...options,
                  meetingHost: SelfScheduleMeetingHost.CustomMeetingHost,
                  videoMeetingHostEmployeeId,
                });
              }}
            />
          )}

          {employeeMissingZoomUserIds && employeeMissingZoomUserIds.length > 0 && (
            <Alert
              status="warning"
              icon={<InfoIcon />}
              title="Some interviewers in your interview plan do not have Zoom host accounts and will not be included in schedule options."
              caption={`${employeeMissingZoomUserIds.length} ${
                employeeMissingZoomUserIds.length === 1 ? 'interviewer' : 'interviewers'
              } won’t be included: ${missingEmployeesData?.employeeByIds?.map((e) => e.fullName).join(', ')}`}
            />
          )}
        </Stack>
      )}

      <Divider />

      <>
        <Stack spacing={1}>
          <Label color="foreground">
            <strong style={{ fontWeight: 600 }}>Note to the candidate</strong> (optional)
          </Label>
          <TextField
            fullWidth
            multiline
            maxRows={4}
            minRows={4}
            defaultValue={
              (options as SelfScheduleOptionsSettingInput)?.candidateNote || (options as OptionsInput)?.note || ''
            }
            placeholder="Add additional instructions for the candidate"
            onBlur={(candidateNote) => onNoteChanged(candidateNote.target.value)}
          />
        </Stack>
        <Divider />
      </>

      {!hideRequestTemplateSelector && (
        <>
          <Stack direction="column" spacing={1}>
            <Label fontWeight={600} variant="body">
              Request email
            </Label>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              sx={sxProps.equalSizeChild}
            >
              <Label>Template</Label>
              <TemplateSelect
                hideTitle
                showOrgDefaultOption
                useSelectButton
                selectedTemplateId={templates.requestEmailTemplateId}
                onSelect={(value) => {
                  onTemplatesChanged({
                    ...templates,
                    requestEmailTemplateId: value,
                  });
                }}
                types={[TemplateType.SelfScheduleRequestCandidateEmail]}
              />
            </Stack>
          </Stack>
          {handleBccRecipients && handleCcRecipients && (
            <>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                marginTop={2}
                justifyContent="space-between"
                spacing={2}
                sx={sxProps.equalSizeChild}
              >
                <Label>Additional recipients</Label>
                <Stack direction="column" alignItems="start" spacing={1}>
                  <Stack width="100%">
                    {smartOptionsEnabled && (
                      <SmartEmployeeSelect
                        label="CC"
                        placeholder="Add names or emails"
                        context={{
                          task: true,
                          application: true,
                          job: jobID,
                          enableOptionsWithMissingData: true,
                        }}
                        filterDynamicAudiences={[DynamicAudience.DebriefAttendees]}
                        onChange={({ employees, freeformEmails, dynamicAudiences }) => {
                          const ids = employees.map((e) => e.id);
                          handleCcRecipients({
                            employeeIds: ids,
                            externalEmailAddresses: freeformEmails,
                            dynamicAudiences,
                          });
                        }}
                        selectedFreeformEmails={ccRecipients?.externalEmailAddresses || []}
                        selectedEmployeeIds={ccRecipients?.employeeIds || []}
                        selectedDynamicAudiences={ccRecipients?.dynamicAudiences || []}
                      />
                    )}
                  </Stack>
                  {!smartOptionsEnabled && (
                    <EmployeePickerExtended
                      label="CC"
                      placeholder="Add names or emails"
                      dynamicAudiencesEnabled={false}
                      freeformEnabled
                      freeformEmails={ccRecipients?.externalEmailAddresses || []}
                      selectedEmployeeIds={ccRecipients?.employeeIds || []}
                      onChange={(selectedEmployees: EmployeeFragment[], freeformEmails: string[]) => {
                        const ids = selectedEmployees.map((e) => e.id);
                        handleCcRecipients({
                          employeeIds: ids,
                          externalEmailAddresses: freeformEmails,
                        });
                      }}
                    />
                  )}
                  {!showBccBtn && !bccRecipients && (
                    <Button variant="link" onClick={() => setShowBccBtn(true)}>
                      Add BCC
                    </Button>
                  )}
                  <Stack width="100%">
                    {smartOptionsEnabled && (showBccBtn || bccRecipients) && (
                      <SmartEmployeeSelect
                        label="BCC"
                        placeholder="Add names or emails"
                        context={{
                          task: true,
                          application: true,
                          job: jobID,
                          enableOptionsWithMissingData: true,
                        }}
                        filterDynamicAudiences={[DynamicAudience.DebriefAttendees]}
                        onChange={({ employees, freeformEmails, dynamicAudiences }) => {
                          const ids = employees.map((e) => e.id);
                          handleBccRecipients({
                            employeeIds: ids,
                            externalEmailAddresses: freeformEmails,
                            dynamicAudiences,
                          });
                        }}
                        selectedFreeformEmails={bccRecipients?.externalEmailAddresses || []}
                        selectedEmployeeIds={bccRecipients?.employeeIds || []}
                        selectedDynamicAudiences={bccRecipients?.dynamicAudiences || []}
                      />
                    )}
                  </Stack>
                  {!smartOptionsEnabled && (showBccBtn || bccRecipients) && (
                    <EmployeePickerExtended
                      label="BCC"
                      dynamicAudiencesEnabled={false}
                      placeholder="Add names or emails"
                      freeformEnabled
                      freeformEmails={bccRecipients?.externalEmailAddresses || []}
                      selectedEmployeeIds={bccRecipients?.employeeIds || []}
                      onChange={(selectedEmployees: EmployeeFragment[], freeformEmails: string[]) => {
                        const ids = selectedEmployees.map((e) => e.id);
                        handleBccRecipients({
                          employeeIds: ids,
                          externalEmailAddresses: freeformEmails,
                        });
                      }}
                    />
                  )}
                </Stack>
              </Stack>
            </>
          )}
          <Divider />
        </>
      )}

      <Stack direction="column" spacing={2}>
        <Label fontWeight={600}>Candidate event & email details</Label>
        <Alert
          status="neutral"
          title={
            <Label color="max-contrast-grey" variant="captions">
              Event descriptions and confirmation email content sent to candidates for self-scheduled interviews are set
              by ModernLoop.{' '}
              <Link
                color="info"
                variant="captions"
                href="https://modernloop.zendesk.com/hc/en-us/articles/11396518676884"
                target="_blank"
              >
                Learn more
              </Link>
            </Label>
          }
        />
        <Stack
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          sx={sxProps.equalSizeChild}
          spacing={1}
          justifyContent="space-between"
        >
          <Box>
            <Label>Book on calendar</Label>
          </Box>
          <Box>
            <CalendarSelect
              disableClearable
              fullWidth
              dataTestId="self-schedule-options-candidate-calendar-google-select"
              error={!options.candidateCalendarId}
              calendarId={options.candidateCalendarId}
              onChange={(candidateCalendarId) => {
                // Dont add a return check for falsy value
                onOptionsChanged({ ...options, candidateCalendarId: candidateCalendarId || '' });
              }}
              resetUnknownCalendarsOnLoad={false}
            />
          </Box>
        </Stack>
        {useCustomSelfScheduleEventInvite && showCandidateEventTemplateSelector && (
          <Stack
            alignItems="center"
            direction={{ xs: 'column', sm: 'row' }}
            sx={sxProps.equalSizeChild}
            spacing={1}
            justifyContent="space-between"
          >
            <Label>Event details</Label>
            <Box>
              <TemplateSelect
                useSelectButton
                showPreviews
                hideTitle
                labelColor="foreground"
                types={[TemplateType.CandidateInvite]}
                selectedTemplateId={templates.candidateEventTemplateId}
                onTemplateSelect={(template) =>
                  onTemplatesChanged({
                    ...templates,
                    candidateEventTemplateId: template.id,
                  })
                }
              />
            </Box>
          </Stack>
        )}

        {/*
        TODO: Add this back when we have customizable InterviewSchedule tokens
        <Stack direction="row" justifyContent="space-between">
          <Box sx={{ width: '55%' }}>
            <Label>Event details</Label>
          </Box>
          <Box sx={{ width: '40%' }}>
            <TemplateSelect
              useSelectButton
              showErrorOnEmpty
              showPreviews
              hideTitle
              labelColor="foreground"
              selectedTemplateId={templates.candidateEventTemplateId}
              types={[TemplateType.CandidateInvite]}
              onTemplateSelect={(template) =>
                onTemplatesChanged({
                  ...templates,
                  candidateEventTemplateId: template.id,
                })
              }
            />
          </Box>
        </Stack> */}

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={sxProps.equalSizeChild}
          spacing={1}
          justifyContent="space-between"
        >
          <Box sx={{ marginLeft: '-8px' }}>
            <Checkbox
              checked={isPersonalizedEmailChecked}
              label="Send personalized email"
              caption="Sent in addition to a default confirmation"
              onChange={(event) => {
                handlePersonalizedEmailCheckboxChange(event.target.checked);

                if (event.target.checked) {
                  onTemplatesChanged({
                    ...templates,
                    candidateEmailTemplateId: defaultTemplates.candidateEmailTemplateId,
                  });
                } else {
                  onTemplatesChanged({
                    ...templates,
                    candidateEmailTemplateId: undefined,
                  });
                }
              }}
            />
          </Box>
          {isPersonalizedEmailChecked && (
            <Box>
              <TemplateSelect
                useSelectButton
                showErrorOnEmpty
                showPreviews
                hideTitle
                labelColor="foreground"
                types={[TemplateType.CandidateConfirmation]}
                selectedTemplateId={templates.candidateEmailTemplateId}
                onTemplateSelect={(template) =>
                  onTemplatesChanged({
                    ...templates,
                    candidateEmailTemplateId: template.id,
                  })
                }
              />
            </Box>
          )}
        </Stack>
      </Stack>

      <Divider />

      <Stack direction="column" spacing={2}>
        <Label fontWeight={600}>Interviewer event details</Label>

        <Stack
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          sx={sxProps.equalSizeChild}
          spacing={1}
          justifyContent="space-between"
        >
          <Box>
            <Label>Book on calendar</Label>
          </Box>
          <Box>
            <CalendarSelect
              disableClearable
              fullWidth
              dataTestId="self-schedule-options-interviewer-calendar-google-select"
              error={!options.interviewerCalendarId}
              calendarId={options.interviewerCalendarId}
              onChange={(interviewerCalendarId) => {
                // Dont add a return check for falsy value
                onOptionsChanged({
                  ...options,
                  interviewerCalendarId: interviewerCalendarId || '',
                });
              }}
              resetUnknownCalendarsOnLoad={false}
            />
          </Box>
        </Stack>

        <Stack
          alignItems="center"
          direction={{ xs: 'column', sm: 'row' }}
          sx={sxProps.equalSizeChild}
          spacing={1}
          justifyContent="space-between"
        >
          <Label>Event details</Label>
          <Box>
            <TemplateSelect
              useSelectButton
              showErrorOnEmpty
              showPreviews
              hideTitle
              labelColor="foreground"
              types={[TemplateType.InterviewerInvite]}
              selectedTemplateId={templates.interviewerEventTemplateId}
              onTemplateSelect={(template) =>
                onTemplatesChanged({
                  ...templates,
                  interviewerEventTemplateId: template.id,
                })
              }
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelfSchedulePreferences;
