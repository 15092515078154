import { IframeState } from './types';
import React, { useContext } from 'react';

const IframeStateContext = React.createContext<IframeState>({
  iframeWindow: null,
});

// eslint-disable-next-line react-refresh/only-export-components
export const useIframeState = () => {
  const state = useContext(IframeStateContext);
  return state;
};

export default IframeStateContext;
