import React from 'react';

import BaseIcon, { Props } from './Icon';

const CheckboxIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5C2.25 3.48122 3.48122 2.25 5 2.25H15C16.5188 2.25 17.75 3.48121 17.75 5L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25 5ZM5 3.75C4.30964 3.75 3.75 4.30965 3.75 5L3.75001 15C3.75002 15.6904 4.30966 16.25 5.00001 16.25H15C15.6903 16.25 16.25 15.6904 16.25 15L16.25 5C16.25 4.30964 15.6903 3.75 15 3.75H5Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default CheckboxIcon;
