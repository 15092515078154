import { useMemo } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Use useEqualSizeSxProps when you have 2 child elements in a Stack or flex container
 * and you want them to be equal size on larger screens & full width on smaller screens.
 */
const useEqualSizeSxProps = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo(() => {
    return {
      equalSizeChild: {
        '& > *': {
          flex: 1,
          width: matches ? '50%' : '100%',
        },
      },
    };
  }, [matches]);
};

export default useEqualSizeSxProps;
