import { MoreIcon } from '@modernloop/shared/icons';
import { IconButton, Menu, MenuProps, IconButtonOwnProps } from '@mui/material';
import { useRef, useState } from 'react';

export type Props = Omit<MenuProps, 'open'> & {
  disabled?: boolean;
  menuDataTestId?: string;
  dataTestId?: string;
  size?: IconButtonOwnProps['size'];
};

const MoreMenuButton = ({
  children,
  menuDataTestId,
  dataTestId,
  disabled,
  size = 'medium',
  ...menuProps
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const moreButtonRef = useRef<HTMLButtonElement>(null);

  const switchPopover = () => setOpen(!open);

  return (
    <>
      <IconButton
        data-testid={dataTestId}
        component="button"
        disabled={disabled}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(true);
        }}
        ref={moreButtonRef}
        size={size}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        data-testid={menuDataTestId}
        anchorEl={moreButtonRef.current}
        onSelect={(e) => {
          e.preventDefault();
          e.stopPropagation();
          switchPopover();
        }}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          switchPopover();
        }}
        onClose={() => setOpen(false)}
        MenuListProps={{ autoFocus: true }}
        {...menuProps}
        open={open}
      >
        {children}
      </Menu>
    </>
  );
};

export default MoreMenuButton;
