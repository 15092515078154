import { useMemo } from 'react';

import { getScheduleUpdates } from 'src/store/selectors/schedule-update';
import { getScheduleById } from 'src/store/selectors/schedules';

import { InterviewSchedule } from 'src/utils/api/getScheduleOptions';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { applyUpdatesToSchedule } from 'src/views-new/ScheduleFlow/Steps/Schedule/applyUpdatesToSchedule';

import { useSelector } from 'src/store';

const useScheduleWithoutBreaks = (id: string | null): InterviewSchedule | null => {
  const selectedSchedule = useSelector((state) => {
    if (!id) return null;
    return getScheduleById(state, id);
  });
  const updateSchedule = useSelector((state) => {
    if (!id) return null;
    return getScheduleUpdates(state, id);
  });

  const scheduleWithoutBreaks = useMemo(() => {
    if (!selectedSchedule) return null;
    return {
      ...selectedSchedule,
      events: selectedSchedule?.events?.filter((event) => !event.isBreak) || [],
    };
  }, [selectedSchedule]);

  return useMemo(() => {
    if (!scheduleWithoutBreaks) return null;
    if (!updateSchedule?.length) return scheduleWithoutBreaks;
    const updatedSchedule = applyUpdatesToSchedule(scheduleWithoutBreaks, updateSchedule);

    updatedSchedule.events?.sort((a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime());

    return updatedSchedule;
  }, [scheduleWithoutBreaks, updateSchedule]);
};

export default useScheduleWithoutBreaks;
