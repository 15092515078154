import { useMutation, useQueryClient } from 'react-query';

import { IntegrationType } from 'src/generated/mloop-graphql';

import { putIntegration } from './integrationPut';
import { QUERY_KEY } from './types';

const INTEGRATION_TYPE: IntegrationType = IntegrationType.CoderpadApiKey;

function putCoderPadIntegration(apiKey: string) {
  return putIntegration({
    integration_type: INTEGRATION_TYPE,
    coderpad: {
      api_key: apiKey,
    },
  });
}

export default function useCoderpadIntegrationPut() {
  const queryClient = useQueryClient();
  return useMutation((apiKey: string) => putCoderPadIntegration(apiKey), {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY);
      queryClient.invalidateQueries([QUERY_KEY, INTEGRATION_TYPE]);
    },
  });
}
