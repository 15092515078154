import { useCallback } from 'react';

import { CandidateDetailsQuery, RenderType } from 'src/generated/mloop-graphql';

import ApplicationPlaceholderFiller, {
  useApplicationPlaceholderFillerOptions,
} from 'src/utils/PlaceholderFiller/ApplicationPlaceholderFiller';
import CandidatePlaceholderFiller, {
  useCandidatePlaceholderFillerOptions,
} from 'src/utils/PlaceholderFiller/CandidatePlaceholderFiller';
import JobPlaceholderFiller, { useJobPlaceholderFiller } from 'src/utils/PlaceholderFiller/JobPlaceholderFiller';
import TaskPlaceholderFiller, { useTaskPlaceholderFiller } from 'src/utils/PlaceholderFiller/TaskPlaceholderFiller';
import UserPlaceholderFiller, {
  useUserPlaceholderFillerOptions,
} from 'src/utils/PlaceholderFiller/UserPlaceholderFiller';

const useSelfScheduleOptionsEmailFiller = (
  applicationId: string,
  jobStageId: string,
  candidateDetails: CandidateDetailsQuery | undefined,
  taskId?: string,
  assigneeId?: string
  // eslint-disable-next-line max-params
) => {
  const jobId = candidateDetails?.candidate?.applications?.find((application) => application?.id === applicationId)?.job
    ?.id;

  const [applicationPlaceholderFillerOptions] = useApplicationPlaceholderFillerOptions({ applicationId });

  const [candidatePlaceholderFillerOptions] = useCandidatePlaceholderFillerOptions({
    applicationId,
    candidateId: candidateDetails?.candidate?.id,
    jobId: candidateDetails?.candidate?.applications?.find((application) => application?.id === applicationId)?.job?.id,
  });

  const [taskPlaceholderFillerOptions] = useTaskPlaceholderFiller({
    renderType: RenderType.Html,
    taskId,
    assigneeId,
  });

  const [jobPlaceholderFillerOptions] = useJobPlaceholderFiller({
    renderType: RenderType.Html,
    jobId,
    jobStageId,
    applicationId,
  });

  const [userPlaceholderFillerOptions] = useUserPlaceholderFillerOptions({
    renderType: RenderType.Html,
  });

  const result = useCallback(
    (input: string) => {
      let filledResult = CandidatePlaceholderFiller.getFilledText(input, candidatePlaceholderFillerOptions);
      filledResult = TaskPlaceholderFiller.getFilledText(filledResult, taskPlaceholderFillerOptions);
      filledResult = JobPlaceholderFiller.getFilledText(filledResult, jobPlaceholderFillerOptions);
      filledResult = UserPlaceholderFiller.getFilledText(filledResult, userPlaceholderFillerOptions);
      filledResult = ApplicationPlaceholderFiller.getFilledText(filledResult, applicationPlaceholderFillerOptions);
      return filledResult;
    },
    [
      candidatePlaceholderFillerOptions,
      taskPlaceholderFillerOptions,
      jobPlaceholderFillerOptions,
      userPlaceholderFillerOptions,
      applicationPlaceholderFillerOptions,
    ]
  );

  return result;
};

export default useSelfScheduleOptionsEmailFiller;
