import React from 'react';
import type { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';

// const THRESHOLD = 1000 * 60 * 60 * 6; // 6 hours in milliseconds

/**
 * React hook to refresh
 * @returns shouldRefresh, whether or not the Browser should hard refresh
 */
// const useShouldHardRefresh = () => {
//   const [shouldRefresh, setShouldRefresh] = React.useState(false);
//   React.useEffect(() => {
//     const id = setTimeout(() => setShouldRefresh(true), THRESHOLD);
//     return () => clearTimeout(id);
//   }, []);
//   return shouldRefresh;
// };

/**
 * Component that will turn Link tags into <a> tags after 6 hours.
 * Meaning the first link click after 6 hours will hard refresh the site.
 * Purpose: Reduce Load chunk errors and attempts to get people on the latest version more quickly.
 */
export const AutoRefreshingRouter: FC = ({ children }) => {
  const shouldRefresh = false;
  return <BrowserRouter forceRefresh={shouldRefresh}>{children}</BrowserRouter>;
};
