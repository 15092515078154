import ApiVerb from './ApiVerb';
import { SlackChannelContent } from './createSchedule';
import { apiPost } from './index';
import { ZoomInfo } from './types';

export interface InterviewerRequestBodyData {
  interviewerId: string;
  isOptional?: boolean;
}

// TODO: Add slack channel stuff
export interface RequestBody {
  applicationId: string;
  interviewerCalendarId: string;
  startAt: string;
  endAt: string;
  summary: string;
  description: string;
  videoMeetingUrl: string;
  interviewName: string;
  interviewerIds: string[];
  isPrivateCalendarEvent: boolean;
  zoomInfo?: ZoomInfo;
  meetingRoomIds?: string[];
  slackChannelContent?: SlackChannelContent;
  attendees: InterviewerRequestBodyData[];
  taskId?: string;
  emailTemplateId?: string;
  conversationTemplateId?: string;
}

interface ResponseBody {
  error?: string;
}

const createDebrief = async (data: RequestBody): Promise<ResponseBody> => {
  const response = await apiPost(ApiVerb.CREATE_DEBRIEF, data, {
    'axios-retry': {
      retries: 0,
    },
  });
  return response.data;
};

export default createDebrief;
