import { SvgIcon, SvgIconProps } from './SvgIcon';

export const CaretLeftIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9685 4.41438C12.292 4.67313 12.3444 5.1451 12.0857 5.46855L8.46047 10L12.0857 14.5315C12.3444 14.855 12.292 15.3269 11.9685 15.5857C11.6451 15.8444 11.1731 15.792 10.9143 15.4685L6.91435 10.4685C6.69522 10.1946 6.69522 9.80542 6.91435 9.53151L10.9143 4.53151C11.1731 4.20806 11.6451 4.15562 11.9685 4.41438Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
