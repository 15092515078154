import React from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';

// eslint-disable-next-line modernloop/restrict-imports.cjs
import { GemHealthCheckBanner_GemFragment } from 'src/generated/mloop-graphql';

import AppWideBanner, { AppWideBannerAction } from '../AppWideBanner';

import { integrationUnhealthyMessage } from './constants';

interface Fragments {
  gem: GemHealthCheckBanner_GemFragment;
}

interface Props {
  retry: AppWideBannerAction;
}

const GemHealthCheckBanner: FCWithFragments<Fragments, Props> = ({ gem, retry }) => {
  // hide if its not installed or is healthy
  if (!gem || !gem?.isInstalled || gem?.isHealthy) {
    return null;
  }

  const endpoints = [
    !gem.canReadUsers && 'list users',
    !gem.canReadUser && 'retrieve user',
    !gem.canReadJobs && 'list jobs',
    !gem.canReadJob && 'retrieve job',
    !gem.canReadJobStages && 'list job stages',
    !gem.canReadJobStageForJob && 'list job stage for job',
    !gem.canReadJobPostings && 'list job postings',
    !gem.canReadUserRoles && 'list user roles',
    !gem.canReadInterviews && 'list interviews',
    !gem.canReadInterview && 'retrieve interview',
    !gem.canReadCandidates && 'list candidates',
    !gem.canReadCandidate && 'retrieve candidate',
    !gem.canReadActivityFeed && 'retreive activity feed',
    !gem.canReadApplications && 'list applications',
    !gem.canReadApplication && 'retrieve application',
  ].filter(Boolean) as string[];

  return (
    <AppWideBanner
      color="foreground"
      backgroundColor="error"
      text={integrationUnhealthyMessage('Gem', endpoints)}
      action={retry}
    />
  );
};

GemHealthCheckBanner.fragments = {
  gem: gql`
    fragment GemHealthCheckBanner_gem on GemIntegrationHealth {
      isInstalled
      isHealthy
      canReadUsers
      canReadUser
      canReadJobs
      canReadJob
      canReadJobStages
      canReadJobStageForJob
      canReadJobPostings
      canReadUserRoles
      canReadInterviews
      canReadInterview
      canReadCandidates
      canReadCandidate
      canReadActivityFeed
      canReadApplications
      canReadApplication
    }
  `,
};

export default GemHealthCheckBanner;
