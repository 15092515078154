/* eslint-disable max-params */
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';
import type { Action } from '@reduxjs/toolkit';
import LogRocket from 'logrocket';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import type { ThunkAction } from 'redux-thunk';

import { ENABLE_REDUX_DEV_TOOLS } from 'src/constants';

import rootReducer from './rootReducer';

export type State = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: 'root',
  version: 2,
  storage,
  whitelist: ['persist', 'uiState'],
  stateReconciler: (
    inboundState: Pick<State, 'persist' | 'uiState'>,
    originalState: State,
    reducedState: State,
    config
  ) => {
    const newInboundState: Pick<State, 'persist' | 'uiState'> = {
      ...inboundState,
      uiState: {
        ...inboundState.uiState,
        // We don't want to restore the below fields from localStorage.
        // Always use what's is defined in the initial state.
        displayTimezone: originalState.uiState.displayTimezone,
        leftNavExpanded: originalState.uiState.leftNavExpanded,
      },
    };
    return autoMergeLevel2(newInboundState, originalState, reducedState, config) as State;
  },
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  devTools: ENABLE_REDUX_DEV_TOOLS,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
    }).concat(LogRocket.reduxMiddleware() as ReturnType<typeof getDefaultMiddleware>), // https://github.com/reduxjs/redux-toolkit/issues/368#issuecomment-878018388
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = (): AppDispatch => useReduxDispatch<AppDispatch>();

export default store;
export const persistor = persistStore(store);
