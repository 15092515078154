import { generatePath } from 'react-router';

import { gql } from '@apollo/client';
import { useFlag } from '@modernloop/shared/feature-flag';

import { useMicrosoftGccHighIntegrationQuery } from 'src/generated/mloop-graphql';

import { Routes } from 'src/constants/routes';

import useCurrentRoute from 'src/hooks/useCurrentRoute';
import useEmployeeId from 'src/hooks/useEmployeeId';
import useOrgId from 'src/hooks/useOrgId';

import { DefaultReturnType } from './constants';
import { IntegrationGrantType, ReturnType } from './types';
import { getRedirectParams, isIgnoredRoutes } from './utils';

export const MicrosoftGccHighIntegration = gql`
  query MicrosoftGccHighIntegration {
    thisOrg {
      id
      integrations {
        microsoftGccHigh {
          active
        }
      }
    }
    thisEmployee {
      id
      integrations {
        microsoftGccHighEmployee {
          active
        }
      }
    }
  }
`;

/**
 * Hook to check if outlook user has granted access, if yes return redirect params to naviagte to outlook grant access screen
 * If needed to be export, lets discuss once
 */
const useMicrosoftGccHighGrantAccess = (): ReturnType => {
  /** Getting the current location */
  const currentRoute = useCurrentRoute();
  const microsoftGccHighEnabled = useFlag('org_microsoft_gcc_high');

  const orgId = useOrgId();
  const employeeId = useEmployeeId();

  const { data, loading, error } = useMicrosoftGccHighIntegrationQuery({
    skip: !orgId || !employeeId || !microsoftGccHighEnabled,
  });

  if (
    !microsoftGccHighEnabled ||
    loading ||
    error ||
    !data?.thisOrg?.integrations?.microsoftGccHigh ||
    isIgnoredRoutes(currentRoute)
  ) {
    return DefaultReturnType;
  }

  if (
    data?.thisOrg?.integrations?.microsoftGccHigh.active &&
    !data?.thisEmployee?.integrations?.microsoftGccHighEmployee?.active
  ) {
    const pathname = generatePath(Routes.GrantAccess, {
      integrationType: IntegrationGrantType.MicrosoftGccHigh,
    });
    return getRedirectParams(pathname, currentRoute);
  }

  return DefaultReturnType;
};

export default useMicrosoftGccHighGrantAccess;
