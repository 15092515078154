import { Quill } from 'react-quill';

import IndentStyle from './IndentStyle';

const registerQuillModules = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const Parchment = Quill.import('parchment');

  // configure Quill to use inline styles so the email's format properly
  // https://github.com/quilljs/quill/issues/1451
  const DirectionAttribute = Quill.import('attributors/attribute/direction');
  Quill.register(DirectionAttribute, true);

  const AlignClass = Quill.import('attributors/class/align');
  Quill.register(AlignClass, true);

  Quill.register({ 'formats/indent': IndentStyle }, true);

  const BackgroundClass = Quill.import('attributors/class/background');
  Quill.register(BackgroundClass, true);

  const ColorClass = Quill.import('attributors/class/color');
  Quill.register(ColorClass, true);

  const DirectionClass = Quill.import('attributors/class/direction');
  Quill.register(DirectionClass, true);

  const FontClass = Quill.import('attributors/class/font');
  Quill.register(FontClass, true);

  const SizeClass = Quill.import('attributors/class/size');
  Quill.register(SizeClass, true);

  const AlignStyle = Quill.import('attributors/style/align');
  Quill.register(AlignStyle, true);

  const BackgroundStyle = Quill.import('attributors/style/background');
  Quill.register(BackgroundStyle, true);

  const ColorStyle = Quill.import('attributors/style/color');
  Quill.register(ColorStyle, true);

  const DirectionStyle = Quill.import('attributors/style/direction');
  Quill.register(DirectionStyle, true);

  const FontStyle = Quill.import('attributors/style/font');
  Quill.register(FontStyle, true);

  const SizeStyle = Quill.import('attributors/style/size');
  Quill.register(SizeStyle, true);

  // p tags have double spacing associated with them. divs are single spaced.
  const Block = Quill.import('blots/block');
  Block.tagName = 'div';
  Quill.register(Block);
};

export default registerQuillModules;
