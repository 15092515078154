import React from 'react';

import { AtsInterviewDefinition, AtsInterviewDefinitionFragment } from 'src/generated/mloop-graphql';

import ScorecardIllustration from 'src/assets/images/illustrations/scorecard_64@2x.png';

import FilterListModal from 'src/components/FilterList/FilterListModal';

import { createJobStageInterviewFromAts } from 'src/store/actions/job-stage-interview';
import { getAtsJobIdByJobStageId } from 'src/store/selectors/job-stage';

import { useDispatch, useSelector } from 'src/store';

import { BaseAtsScorecardProps, useGetChildSize, useGroupBy, useRenderGroup, useRenderOption } from './AtsScorecard';
import useAtsScorecardOptions from './useAtsScorecardOptions';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type BaseAtsScorecardModalProps = Omit<BaseAtsScorecardProps, 'result' | 'readonly' | 'selectScorecard'> & {
  result: ReturnType<typeof useAtsScorecardOptions>;
  selectScorecard: (scorecard: AtsInterviewDefinitionFragment) => void;
  onClose: () => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type AtsScorecardModalProps = {
  atsJobId?: string;
  jobStageId: string;
  onClose: () => void;
  onSelectScorecard?: (scorecard: AtsInterviewDefinitionFragment) => void;
};

const BaseAtsScorecardModal = ({ result, selectScorecard, onClose }: BaseAtsScorecardModalProps): JSX.Element => {
  const { loading, error, options } = result;

  const renderOption = useRenderOption();
  const renderGroup = useRenderGroup();
  const getChildSize = useGetChildSize();
  const groupBy = useGroupBy();

  return (
    <FilterListModal
      open
      decoration={<img style={{ width: '64px', height: '64px' }} src={ScorecardIllustration} alt="Scorecard" />}
      title="Select a scorecard"
      cancelOptions={{
        label: 'Cancel',
        onClick: onClose,
      }}
      onClose={onClose}
      loading={loading}
      errorMsg={error?.message}
      options={options}
      getOptionLabel={(option) => option.name ?? 'No Scorecard'}
      groupBy={groupBy}
      renderOption={renderOption}
      renderGroup={renderGroup}
      onChange={(event: React.ChangeEvent, values: AtsInterviewDefinitionFragment[]) => {
        if (values && values.length > 0) {
          selectScorecard(values[0]);
        }
      }}
      getChildSize={getChildSize}
    />
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const AtsScorecardModal = ({
  atsJobId,
  jobStageId,
  onClose,
  onSelectScorecard,
}: AtsScorecardModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const storeAtsJobId = useSelector((state) => getAtsJobIdByJobStageId(state, jobStageId));

  const result = useAtsScorecardOptions(atsJobId || storeAtsJobId);

  const handleScorecardSelectClose = (scorecard: AtsInterviewDefinition) => {
    if (onSelectScorecard) {
      onSelectScorecard(scorecard);
    } else {
      dispatch(createJobStageInterviewFromAts(jobStageId, scorecard));
    }
    onClose();
  };

  return <BaseAtsScorecardModal result={result} selectScorecard={handleScorecardSelectClose} onClose={onClose} />;
};

export default AtsScorecardModal;
