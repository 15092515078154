import React from 'react';

import BaseIcon, { Props } from './Icon';

const ArchivedSmallIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <rect width="16" height="16" rx="2" fill="#F8F8F8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.0791 3.02927C4.40366 2.54243 4.95007 2.25 5.53518 2.25H10.4648C11.0499 2.25 11.5963 2.54243 11.9209 3.02927L13.4561 5.33205C13.6477 5.61952 13.75 5.95728 13.75 6.30278V12C13.75 12.9665 12.9665 13.75 12 13.75H4C3.0335 13.75 2.25 12.9665 2.25 12V6.30278C2.25 5.95728 2.35227 5.61952 2.54391 5.33205L4.0791 3.02927ZM5.53518 3.75C5.4516 3.75 5.37354 3.79178 5.32717 3.86133L4.06805 5.75H11.9319L10.6728 3.86133C10.6265 3.79178 10.5484 3.75 10.4648 3.75H5.53518ZM6 8.25C5.58579 8.25 5.25 8.58579 5.25 9V10C5.25 10.4142 5.58579 10.75 6 10.75H10C10.4142 10.75 10.75 10.4142 10.75 10V9C10.75 8.58579 10.4142 8.25 10 8.25H6Z"
        fill="#70717D"
      />
    </BaseIcon>
  );
};

export default ArchivedSmallIcon;
