import { useMemo } from 'react';

import { TemplateType } from 'src/generated/mloop-graphql';

import { Tokens as ApplicationTokens } from './ApplicationPlaceholderFiller';
import { Tokens as AvailabilityTokens } from './CandidateAvailabilityPlaceholderFiller';
import { Tokens as CandidateTokens } from './CandidatePlaceholderFiller';
import { Tokens as DebriefTokens } from './DebriefPlaceholderFiller';
import { Tokens as InterviewTokens } from './InterviewPlaceholderFiller/types';
import { Tokens as JobTokens } from './JobPlaceholderFiller';
import { Tokens as MeetingTokens } from './MeetingPlaceholderFiller';
import { Tokens as TaskTokens } from './TaskPlaceholderFiller';
import { Tokens as UserTokens } from './UserPlaceholderFiller';

export enum SelfScheduleTokens {
  SELF_SCHEDULE_LINK = 'SELF_SCHEDULE_LINK',
}

export const DefaultBodyTemplateTokens = [
  ...Object.values(CandidateTokens),
  ...Object.values(JobTokens),
  ...Object.values(UserTokens),
  ...Object.values(InterviewTokens),
  ...Object.values(DebriefTokens),
  ...Object.values(AvailabilityTokens),
  ...Object.values(SelfScheduleTokens),
  ...Object.values(TaskTokens),
  ApplicationTokens.INTERNAL_APPLICATION_NOTE,
];

export const DefaultSubjectTemplateTokens = [
  CandidateTokens.CANDIDATE_NAME,
  CandidateTokens.CANDIDATE_FIRST_NAME,
  JobTokens.JOB_NAME,
  JobTokens.JOB_STAGE_NAME,
  CandidateTokens.CANDIDATE_LAST_INITIAL,
  InterviewTokens.INTERVIEW_NAME,
  InterviewTokens.INTERVIEWER_NAMES,
  JobTokens.EXTERNAL_JOB_NAME,
  InterviewTokens.INTERVIEW_SCHEDULE_START_TIME,
  InterviewTokens.INTERVIEW_SCORECARD_NAME,
  UserTokens.COMPANY_NAME,
];

/**
 * Tokens which are fetched can only be displayed in the template editor
 */
export type DisplayTokenType =
  | ApplicationTokens
  | CandidateTokens
  | JobTokens
  | UserTokens
  | InterviewTokens
  | AvailabilityTokens
  | MeetingTokens
  | SelfScheduleTokens
  | DebriefTokens
  | TaskTokens;

export type DisplayTokenTypes = DisplayTokenType[];

/**
 * MeetingTokens is subset of InterviewTokens, have not applied unique on the array.
 * If duplicate tokens are added add unique to the array
 */
export const getDisplayTemplateTokensForBody = (tType: TemplateType): DisplayTokenTypes => {
  switch (tType) {
    case TemplateType.CandidateConfirmation:
    case TemplateType.CandidateInvite:
      return [
        ...Object.values(CandidateTokens),
        ...Object.values(JobTokens),
        ...Object.values(UserTokens),
        InterviewTokens.VIDEO_MEETING_LINK,
        InterviewTokens.CODE_LINK,
        InterviewTokens.INTERVIEW_DAYS,
        InterviewTokens.INTERVIEWER_NAMES,
        InterviewTokens.INTERVIEW_NAME,
        InterviewTokens.INTERVIEW_SCHEDULE,
        InterviewTokens.INTERVIEW_SCHEDULE_START_TIME,
        InterviewTokens.ZOOM_MEETING_INFO,
        InterviewTokens.ZOOM_MEETING_DIAL_IN_INFO,
        ...Object.values(TaskTokens),
        ApplicationTokens.APPLICATION_CANDIDATE_PORTAL_URL,
      ];
    case TemplateType.SlackChannelMessage:
      return [
        ...Object.values(CandidateTokens),
        ...Object.values(JobTokens),
        ...Object.values(UserTokens),
        ...Object.values(InterviewTokens),
        ...Object.values(TaskTokens),
        ApplicationTokens.INTERNAL_APPLICATION_NOTE,
      ];
    case TemplateType.CandidateAvailabilityRequest:
      return [
        ...Object.values(CandidateTokens),
        ...Object.values(JobTokens),
        ...Object.values(UserTokens),
        ...Object.values(AvailabilityTokens),
        ...Object.values(TaskTokens),
        ApplicationTokens.APPLICATION_CANDIDATE_PORTAL_URL,
      ];
    case TemplateType.SelfScheduleRequestCandidateEmail:
      return [
        ...Object.values(CandidateTokens),
        ...Object.values(JobTokens),
        ...Object.values(UserTokens),
        ...Object.values(MeetingTokens),
        ...Object.values(SelfScheduleTokens),
        ...Object.values(TaskTokens),
        ApplicationTokens.APPLICATION_CANDIDATE_PORTAL_URL,
      ];
    case TemplateType.DebriefConfirmationConversationMessage:
    case TemplateType.DebriefCalendarInvite:
      return [
        ...Object.values(CandidateTokens),
        ...Object.values(JobTokens),
        ...Object.values(UserTokens),
        ...Object.values(MeetingTokens),
        ...Object.values(TaskTokens),
        InterviewTokens.INTERVIEW_DAYS,
        InterviewTokens.INTERVIEWER_NAMES,
        InterviewTokens.INTERVIEW_NAME,
        InterviewTokens.INTERVIEW_SCHEDULE,
        InterviewTokens.INTERVIEW_SCHEDULE_START_TIME,
        InterviewTokens.INTERNAL_INTERVIEW_SCHEDULE,
        ApplicationTokens.INTERNAL_APPLICATION_NOTE,
        ...Object.values(DebriefTokens),
      ];
    default:
      return DefaultBodyTemplateTokens;
  }
};

export const useDisplayTemplateTokensForBody = (tType: TemplateType): DisplayTokenTypes => {
  return useMemo(() => {
    const tokens = getDisplayTemplateTokensForBody(tType);

    return tokens.sort();
  }, [tType]);
};

export const getDisplayTemplateTokensForSubject = (tType: TemplateType): DisplayTokenTypes => {
  switch (tType) {
    case TemplateType.CandidateAvailabilityRequest:
    case TemplateType.SelfScheduleRequestCandidateEmail:
      return [
        CandidateTokens.CANDIDATE_NAME,
        CandidateTokens.CANDIDATE_FIRST_NAME,
        JobTokens.JOB_NAME,
        JobTokens.EXTERNAL_JOB_NAME,
        JobTokens.JOB_STAGE_NAME,
        CandidateTokens.CANDIDATE_LAST_INITIAL,
        UserTokens.COMPANY_NAME,
      ];
    default:
      return DefaultSubjectTemplateTokens;
  }
};

export const useDisplayTemplateTokensForSubject = (tType: TemplateType): DisplayTokenTypes => {
  return useMemo(() => {
    return getDisplayTemplateTokensForSubject(tType).sort();
  }, [tType]);
};

export const TemplateTokenDisplay = {
  [CandidateTokens.CANDIDATE_EMAIL_ADDRESS]: 'Candidate email address',
  [CandidateTokens.CANDIDATE_FIRST_NAME]: 'First name',
  [CandidateTokens.CANDIDATE_LAST_NAME]: 'Last name',
  [CandidateTokens.CANDIDATE_LAST_INITIAL]: 'Candidate last initial',
  [CandidateTokens.CANDIDATE_LINK]: 'Candidate link',
  [CandidateTokens.CANDIDATE_LINKEDIN]: 'Candidate LinkedIn',
  [CandidateTokens.CANDIDATE_NAME]: 'Candidate name',
  [CandidateTokens.CANDIDATE_PHONE]: 'Candidate phone',
  [CandidateTokens.COORDINATOR]: 'Coordinator',
  [CandidateTokens.HIRING_MANAGER]: 'Hiring manager',
  [CandidateTokens.SOURCER]: 'Sourcer',
  [CandidateTokens.CANDIDATE_SOURCER]: 'Candidate sourcer',
  [CandidateTokens.RECRUITER]: 'Recruiter',
  [CandidateTokens.RECRUITER_EMAIL]: 'Recruiter email',
  [CandidateTokens.COORDINATOR_EMAIL]: 'Coordinator email',
  [InterviewTokens.CODE_LINK]: 'Code link',
  [InterviewTokens.INTERVIEWER_NAMES]: 'Interviewer names',
  [InterviewTokens.INTERVIEW_DAYS]: 'Interview days',
  [InterviewTokens.INTERVIEW_KIT_LINK]: 'Interview kit link',
  [InterviewTokens.INTERVIEW_NAME]: 'Interview name',
  [InterviewTokens.INTERVIEW_SCHEDULE]: 'Interview schedule',
  [InterviewTokens.INTERVIEW_SCHEDULE_START_TIME]: 'Interview schedule start time',
  [InterviewTokens.INTERNAL_INTERVIEW_SCHEDULE]: 'Internal interview schedule',
  [InterviewTokens.INTERVIEW_SCORECARD_NAME]: 'Interview scorecard name',
  [InterviewTokens.VIDEO_MEETING_LINK]: 'Video link',
  [InterviewTokens.ZOOM_MEETING_INFO]: 'Zoom meeting info',
  [InterviewTokens.ZOOM_MEETING_DIAL_IN_INFO]: 'Zoom meeting dial in info',
  [JobTokens.JOB_NAME]: 'Job name',
  [JobTokens.JOB_STAGE_NAME]: 'Job stage',
  [JobTokens.JOB_LOCATION]: 'Job location',
  [JobTokens.EXTERNAL_JOB_NAME]: 'External job name',
  [UserTokens.COMPANY_NAME]: 'Company name',
  [UserTokens.MY_FIRST_NAME]: 'My first name',
  [UserTokens.MY_FULL_NAME]: 'My full name',
  [UserTokens.MY_SIGNATURE]: 'Signature',
  [UserTokens.MY_PHONE_NUMBER]: 'My phone number',
  [AvailabilityTokens.CANDIDATE_AVAILABILITY_URL]: 'Candidate availability url',
  [AvailabilityTokens.CANDIDATE_AVAILABILITY_TIMEZONE]: 'Candidate availability timezone',
  [AvailabilityTokens.CANDIDATE_AVAILABILITY]: 'Candidate availability',
  [AvailabilityTokens.CANDIDATE_AVAILABILITY_NOTE]: 'Candidate availability note',
  [DebriefTokens.DEBRIEF_SCHEDULE]: 'Debrief schedule',
  [SelfScheduleTokens.SELF_SCHEDULE_LINK]: 'Self-schedule request link',
  [ApplicationTokens.INTERNAL_APPLICATION_NOTE]: 'Internal application note',
  [ApplicationTokens.APPLICATION_CANDIDATE_PORTAL_URL]: 'Candidate portal url for the application',
  [TaskTokens.TASK_CREATOR]: 'Task creator',
  [TaskTokens.TASK_ASSIGNEE]: 'Task assignee',
};

export const getTooltipForTemplateToken = (token: DisplayTokenType): string | null => {
  switch (token) {
    case CandidateTokens.CANDIDATE_EMAIL_ADDRESS:
      return 'ex. chris@exampe.com';
    case CandidateTokens.CANDIDATE_FIRST_NAME:
      return 'ex. John';
    case CandidateTokens.CANDIDATE_LAST_NAME:
      return 'ex. Doe';
    case CandidateTokens.CANDIDATE_LINK:
      return 'Link to candidate in applicant tracking system';
    case CandidateTokens.CANDIDATE_NAME:
      return "Candidate's ful name. ex. John Doe";
    case CandidateTokens.CANDIDATE_PHONE:
      return "Candidate's phone number";
    case InterviewTokens.CODE_LINK:
      return 'Link from CodeSignal or CoderPad';
    case CandidateTokens.COORDINATOR:
      return 'Recruiting coordintor for this candidate. Ex. Erin Hannon';
    case CandidateTokens.CANDIDATE_SOURCER:
      return 'Sourcer for this candidate. Ex. Dwight Schrute';
    case CandidateTokens.HIRING_MANAGER:
      return 'Hiring manager for this candidate. Ex. Michael Scott';
    case InterviewTokens.INTERVIEWER_NAMES:
      return 'List out all the names of interviewers scheduled on the interview loop. Ex. Chris Triolo and Lydia Han';
    case InterviewTokens.INTERVIEW_DAYS:
      return 'Days of the interview spelled out. Ex. May 14th and May 15th';
    case InterviewTokens.INTERVIEW_KIT_LINK:
      return 'Link for interviewer to submit their scorecard.';
    case InterviewTokens.INTERVIEW_NAME:
      return 'Name of specific interview that interviewer is conducting. Ex. Systems Architecture';
    case InterviewTokens.INTERVIEW_SCHEDULE:
      return 'Full interview schedule created through ModernLoop.';
    case InterviewTokens.INTERVIEW_SCHEDULE_START_TIME:
      return 'Start time of the interview. Ex. Monday October 11, 10:00am PDT';
    case JobTokens.JOB_NAME:
      return 'Name of the job candidate is interviewing for. Ex. Senior Software Engineer, Fullstack';
    case JobTokens.JOB_STAGE_NAME:
      return 'Stage the candidate is in. Ex. Onsite';
    case JobTokens.JOB_LOCATION:
      return 'Locations the job is in. Ex. San Francisco, New York City';
    case UserTokens.MY_FIRST_NAME:
      return 'Your first name. Ex. Pam';
    case UserTokens.MY_FULL_NAME:
      return 'Your full name. Ex. Pam Beesley';
    case UserTokens.MY_SIGNATURE:
      return 'Your signature. (Note: Set up your signature in the Personal preferences)';
    case CandidateTokens.SOURCER:
      return 'Sourcer for the candidate. Ex. Kelly Kapoor';
    case CandidateTokens.RECRUITER:
      return 'Recruiter for the candidate. Ex. Toby Flenderson';
    case InterviewTokens.VIDEO_MEETING_LINK:
      return 'Video call link (Zoom or Google Meets). Ex. https://zoom.us/j/... or https://meet.google.com/...';
    case InterviewTokens.ZOOM_MEETING_INFO:
      return 'Add Zoom meeting details including link, meeting id, and passcode';
    case UserTokens.COMPANY_NAME:
      return 'ex. Moderloop.io';
    case AvailabilityTokens.CANDIDATE_AVAILABILITY_URL:
      return 'Availability URL accessible to candidate. Ex. https://app.modernloop.io/portal/availability/...';
    case AvailabilityTokens.CANDIDATE_AVAILABILITY_TIMEZONE:
      return 'The timezone in which the candidate availability constraints will be evaluated.';
    case AvailabilityTokens.CANDIDATE_AVAILABILITY_NOTE:
      return 'Candidate availability note by the recruiter. Ex. Give as much availability as possible.';
    case CandidateTokens.COORDINATOR_EMAIL:
      return 'Email of the recruiting coordinator assigned to the candidate, or if none is found for the candidate, for the job';
    case CandidateTokens.RECRUITER_EMAIL:
      return 'Email of the recruiter assigned to the candidate, or if none is found for the candidate, for the job';
    case DebriefTokens.DEBRIEF_SCHEDULE:
      return 'Debrief schedule including all attendees';
    case SelfScheduleTokens.SELF_SCHEDULE_LINK:
      return 'Link for self schedule for the candidate';
    case ApplicationTokens.APPLICATION_CANDIDATE_PORTAL_URL:
      return 'Link to candidate portal for the application';
    case ApplicationTokens.INTERNAL_APPLICATION_NOTE:
      return 'A note added by recruiter on any application';
    case TaskTokens.TASK_CREATOR:
      return 'The name of the task creator';
    case TaskTokens.TASK_ASSIGNEE:
      return 'The name of the task assignee';
    default:
      return null;
  }
};
