import React, { FC } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Theme, createStyles, makeStyles } from '@material-ui/core';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { ChromeIcon } from '../../../../components/icons';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import Label from '../../../../components/label';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-imports.cjs
import ZenDeskHelpCenterUrl from '../../../../constants/ZenDeskHelpCenterUrl';

import AppWideBanner from './AppWideBanner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    linkAnchor: {
      color: theme.palette.primary.main,
      marginLeft: '2px',
      textDecoration: 'underline',
    },
    avatar: {
      height: 20,
      width: 20,
    },
  })
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface MissingChromeExtensionBannerProps {
  text: string | JSX.Element;
  bannerUrlText?: string | null; // Defaults to 'Read more'
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
const MissingChromeExtensionBanner: FC<MissingChromeExtensionBannerProps> = ({ text, bannerUrlText }) => {
  const classes = useStyles();
  const linkJSX = (
    <a className={classes.linkAnchor} href={ZenDeskHelpCenterUrl.CHROME_EXTENSION} target="_blank" rel="noreferrer">
      {bannerUrlText}
    </a>
  );
  const textJsx = (
    <Label>
      {text} {linkJSX}
    </Label>
  );

  return <AppWideBanner text={<Label icon={<ChromeIcon />}>{textJsx}</Label>} backgroundColor="alternate" showClose />;
};

export default MissingChromeExtensionBanner;
