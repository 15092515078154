import { lightThemeOptions as sharedTheme } from '@modernloop/shared/theme';
import { createTheme as createMuiTheme } from '@mui/material';
import _ from 'lodash';

import lightTheme from './light';
import { Theme, ThemeOptions } from './type';

const baseOptions: ThemeOptions = {
  direction: 'ltr',
};

export const createMergedTheme = (): Theme => {
  const theme = createMuiTheme(_.merge({}, baseOptions, lightTheme, sharedTheme));
  return theme;
};

export default createMergedTheme;
