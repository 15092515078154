import React from 'react';

import { gql } from '@apollo/client';
import { Checkbox, Stack } from '@mui/material';

import { InterviewModuleMember_InterviewModuleMemberFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ModuleMember from 'src/entities/InterviewModuleMember/Scheduling/ModuleMember';

type Props = { selected: boolean };

type Fragments = {
  interviewModuleMember: InterviewModuleMember_InterviewModuleMemberFragment;
};

const InterviewModuleMember: FCWithFragments<Fragments, Props> = ({ interviewModuleMember, selected }) => {
  return (
    <Stack
      key={`${interviewModuleMember.employeeId}-${interviewModuleMember.interviewModuleId}`}
      direction="row"
      spacing={1}
      alignItems="center"
      flexWrap="nowrap"
      width="100%"
    >
      <Checkbox
        checked={!!selected}
        id={`${interviewModuleMember.employeeId}-${interviewModuleMember.interviewModuleId}`}
      />
      <ModuleMember interviewModuleMember={interviewModuleMember} />
    </Stack>
  );
};

InterviewModuleMember.fragments = {
  interviewModuleMember: gql`
    ${ModuleMember.fragments.interviewModuleMember}
    fragment InterviewModuleMember_interviewModuleMember on InterviewModuleMember {
      interviewModuleId
      employeeId
      ...ModuleMember_interviewModuleMember
    }
  `,
};

export default InterviewModuleMember;
