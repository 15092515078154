import React, { useMemo } from 'react';

import { Box, DialogTitle, Stack, SxProps, useTheme } from '@mui/material';

import IconButton from 'src/components/IconButton';
import { CaretLeftIcon } from 'src/components/icons';
import Label from 'src/components/label';
import { BaseProps } from 'src/components/types';

import { Theme } from 'src/themeMui5/type';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type DialogContainerHeaderProps = BaseProps & {
  title: string | JSX.Element;
  headerActions?: React.ReactNode;
  sx?: SxProps<Theme>;
  goBack?: () => void;
};

const useSxProps = () => {
  const theme = useTheme();
  return useMemo(() => {
    return {
      root: {
        padding: `12px 20px`,
        boxShadow: `inset 0 -1px 0 ${theme.palette.divider}`,
        marginBottom: '1px',
      },
      backButton: {
        border: `1px solid ${theme.palette.border}`,
      },
      title: {
        flexGrow: 1,
      },
    };
  }, [theme]);
};

const DialogContainerHeader = ({
  dataTestId,
  title,
  headerActions,
  sx,
  goBack,
}: DialogContainerHeaderProps): JSX.Element => {
  const sxProps = useSxProps();
  const sxRootProps = useMemo(() => ({ ...sxProps.root, ...(sx || {}) }), [sxProps.root, sx]);

  return (
    <DialogTitle sx={sxRootProps} data-testid={dataTestId}>
      <Stack alignItems="center" direction="row" spacing={1}>
        {goBack && (
          <IconButton style={sxProps.backButton} onClick={goBack}>
            <CaretLeftIcon />
          </IconButton>
        )}

        {typeof title === 'string' && (
          <Label variant="header" fontWeight={600} style={sxProps.title}>
            {title}
          </Label>
        )}

        {typeof title !== 'string' && title}

        <Box>{headerActions}</Box>
      </Stack>
    </DialogTitle>
  );
};

export default DialogContainerHeader;
