import { Typography, TypographyProps, Tooltip, TooltipProps } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';

type Props = Omit<TooltipProps, 'children'> & {
  label: string;
  labelProps?: TypographyProps;
};

const useShowTooltip = (ref: React.RefObject<HTMLElement>, label: string): boolean => {
  const [showTooltip, setShowTooltip] = useState(false);

  const compareSize = useCallback(() => {
    if (!ref.current) return;
    const compare = ref.current.scrollWidth > ref.current.clientWidth;
    setShowTooltip(compare);
  }, [ref]);

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, [compareSize]);

  // If the label changes, we need to re-run the compareSize effect
  useEffect(() => {
    if (!label) return;
    compareSize();
  }, [label, compareSize]);

  return showTooltip;
};

export const MaybeTooltip = (props: Props): JSX.Element => {
  const { label, labelProps, ...tooltipProps } = props;

  const textElementRef = useRef<HTMLElement>(null);
  const showTooltip = useShowTooltip(textElementRef, label);

  return (
    <Tooltip {...tooltipProps} disableHoverListener={!showTooltip}>
      <Typography
        // eslint-disable-next-line no-restricted-syntax
        sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        {...labelProps}
        ref={textElementRef}
      >
        {label}
      </Typography>
    </Tooltip>
  );
};
