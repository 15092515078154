import { CANDIDATE_PORTAL_BASE_URL } from 'src/config';

/**
 * Candidate portal application url
 * @param options - orgSlug, applicationId
 * @returns string
 */
const getCandidatePortalApplicationUrl = (options: { orgSlug: string; applicationId: string }): string => {
  const { orgSlug, applicationId } = options;

  return `${CANDIDATE_PORTAL_BASE_URL.url}o/${orgSlug}/a/${applicationId}`;
};

export default getCandidatePortalApplicationUrl;
