/* eslint-disable max-lines */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { addMinutes, differenceInMinutes, parseISO } from 'date-fns';
import _ from 'lodash';

import { TimeBlock } from 'src/types/preferenes';

import { InterviewEvent } from 'src/utils/api/getScheduleOptions';

import { MeetingRoomSuggestionInterface } from './schedule-communications';

export enum HiringTeamRoles {
  RECRUITER = 'recruiter',
  COORDINATOR = 'coordinator',
  HIRING_MANAGER = 'hiring-manager',
  SOURCER = 'sourcer',
  CANDIDADATE_SOURCER = 'candidate-sourcer',
}

export interface Employee {
  employeeId: string;
  fullName: string | null | undefined;
  slackImageUrl: string | null | undefined;
  googleDisabled?: boolean;
  hasAtsId?: boolean;

  isAtsDisabled: boolean;
  role?: HiringTeamRoles;
}

export interface DebriefStage {
  name: string;
  startAt: string;
  endAt: string;
  attendees: Employee[];
}

export enum DebriefStartTimeType {
  AUTOMATIC = 'automatic',
  WITHIN_TIME_RANGE = 'within_time_range',
  EXACT_TIME = 'exact_time',
}

export interface InterviewerEventContent {
  emails: string[];
  summary: string;
  description: string;
  event: InterviewEvent;
  location: string | null;
  zoomUserId: string | null;
  emailTemplateID: string | null;
  filledBodyTokens?: { [string: string]: string };
}

type Debrief = {
  applicationId: string;
  stepScheduleOptions: {
    loading: boolean;
    submitting: boolean;
    error: string;
  };
  stepScheduleContent: {
    loading: boolean;
    isWaiting: boolean;
    isSubmitting: boolean;
    error: string;
  };
  isPrivateCalendarEvent: boolean;
  candidateName: string;
  debriefStartTime: string | null;
  timezone: string;
  debriefStartTimeType: DebriefStartTimeType;
  timePeriods: TimeBlock[];
  debriefAttendees: DebriefStage[];
  debriefHiringTeamAttendees: Employee[];
  selectedAttendeeIds: string[];
  fetchedAttendee: boolean;
  additionalAttendees: Employee[];
  duration: number;
  isScheduleSelected: boolean;
  selectedScheduleId: string | null;
  interviewerCalendarId: string | null;
  event: InterviewEvent | null;
  interviewerEventContent: InterviewerEventContent | null;
  interviewerTemplateID: string | null;
  meetingRoomSuggestions: MeetingRoomSuggestionInterface[];
  isInitialized: boolean;
  slackChannelEnabled: boolean;
  taskConversationId: string | null | undefined;
  taskConversationName: string | null | undefined;
  taskConversationTemplateId: string | null | undefined;
};

type DebriefState = {
  byId: { [applicationId: string]: Debrief };
};

const getInitialDebrief = (applicationId: string): Debrief => {
  return {
    applicationId,
    stepScheduleOptions: {
      loading: true,
      submitting: false,
      error: '',
    },
    stepScheduleContent: {
      loading: true,
      isWaiting: false,
      isSubmitting: false,
      error: '',
    },
    candidateName: '',
    debriefStartTime: null,
    timezone: '',
    debriefStartTimeType: DebriefStartTimeType.AUTOMATIC,
    timePeriods: [],
    debriefAttendees: [],
    debriefHiringTeamAttendees: [],
    selectedAttendeeIds: [],
    fetchedAttendee: false,
    additionalAttendees: [],
    duration: 30,
    isPrivateCalendarEvent: false,
    isScheduleSelected: false,
    selectedScheduleId: null,
    interviewerCalendarId: null,
    event: null,
    interviewerEventContent: null,
    interviewerTemplateID: null,
    meetingRoomSuggestions: [],
    isInitialized: false,
    slackChannelEnabled: false,
    taskConversationId: null,
    taskConversationName: null,
    taskConversationTemplateId: null,
  };
};

const getInitialState = (): DebriefState => {
  return {
    byId: {},
  };
};

const debriefSlice = createSlice({
  name: 'debrief',
  initialState: getInitialState(),
  reducers: {
    /// /////////////////
    // Initialization //
    /// /////////////////

    initialize(state: DebriefState, action: PayloadAction<{ applicationId: string; timezone: string }>) {
      const { applicationId, timezone } = action.payload;

      state.byId[applicationId] = getInitialDebrief(applicationId);
      state.byId[applicationId].timezone = timezone;
      state.byId[applicationId].isInitialized = true;
    },

    clear(state: DebriefState, action: PayloadAction<string>) {
      const applicationId = action.payload;
      const initialState = getInitialDebrief(applicationId);
      const debrief = state.byId[applicationId];

      if (!debrief) return;

      const { timezone } = debrief;
      _.merge(state.byId[applicationId], initialState);
      state.byId[applicationId].timezone = timezone;
    },

    updateStepScheduleOptions(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; loading: boolean; submitting: boolean; error: string }>
    ) {
      const { applicationId, loading, submitting, error } = action.payload;
      state.byId[applicationId].stepScheduleOptions = {
        loading,
        submitting,
        error,
      };
    },

    setTimezone(state: DebriefState, action: PayloadAction<{ applicationId: string; timezone: string }>) {
      const { applicationId, timezone } = action.payload;
      state.byId[applicationId].timezone = timezone;
    },

    setDebriefStartTimeType(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; debriefStartTimeType: DebriefStartTimeType }>
    ) {
      const { applicationId, debriefStartTimeType } = action.payload;
      state.byId[applicationId].debriefStartTimeType = debriefStartTimeType;
    },

    setTimePeriods(state: DebriefState, action: PayloadAction<{ applicationId: string; timePeriods: TimeBlock[] }>) {
      const { applicationId, timePeriods } = action.payload;
      state.byId[applicationId].timePeriods = timePeriods;
    },

    addTimePeriod(state: DebriefState, action: PayloadAction<{ applicationId: string; timePeriod: TimeBlock }>) {
      const { applicationId, timePeriod } = action.payload;
      state.byId[applicationId].timePeriods.push(timePeriod);
    },

    updateTimePeriod(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; timePeriod: TimeBlock; index: number }>
    ) {
      const { applicationId, timePeriod, index } = action.payload;
      state.byId[applicationId].timePeriods[index] = timePeriod;
    },

    deleteTimePeriod(state: DebriefState, action: PayloadAction<{ applicationId: string; index: number }>) {
      const { applicationId, index } = action.payload;
      state.byId[applicationId].timePeriods.splice(index, 1);
    },

    updateDuration(state: DebriefState, action: PayloadAction<{ applicationId: string; duration: number }>) {
      const { applicationId, duration } = action.payload;
      state.byId[applicationId].duration = duration;

      const { timePeriods } = state.byId[applicationId];
      const newTimePeriods = timePeriods.map((timePeriod) => {
        const diffInMins = differenceInMinutes(parseISO(timePeriod.end), parseISO(timePeriod.start));
        if (duration <= diffInMins) return timePeriod;
        return {
          start: timePeriod.start,
          end: addMinutes(parseISO(timePeriod.start), duration).toISOString(),
        };
      });
      state.byId[applicationId].timePeriods = newTimePeriods;
    },

    updateDebriefStartTime(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; debriefStartTime: string }>
    ) {
      const { applicationId, debriefStartTime } = action.payload;
      state.byId[applicationId].debriefStartTime = debriefStartTime;
    },

    updateDebriefAttendees(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; debriefAttendees: DebriefStage[] }>
    ) {
      const { applicationId, debriefAttendees } = action.payload;
      state.byId[applicationId].debriefAttendees = debriefAttendees;
    },

    updateDebriefHiringTeamAttendees(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; debriefHiringTeamAttendees: Employee[] }>
    ) {
      const { applicationId, debriefHiringTeamAttendees } = action.payload;
      state.byId[applicationId].debriefHiringTeamAttendees = debriefHiringTeamAttendees;
    },

    updateSelectedAttendeeIds(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; selectedAttendeeIds: string[] }>
    ) {
      const { applicationId, selectedAttendeeIds } = action.payload;
      state.byId[applicationId].selectedAttendeeIds = selectedAttendeeIds;
    },

    updateFetchedAttendee(state: DebriefState, action: PayloadAction<{ applicationId: string; value: boolean }>) {
      const { applicationId, value } = action.payload;
      state.byId[applicationId].fetchedAttendee = value;
    },

    updateSelectedScheduleId(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; scheduleId: string }>
    ) {
      const { applicationId, scheduleId } = action.payload;
      state.byId[applicationId].selectedScheduleId = scheduleId;
      state.byId[applicationId].isScheduleSelected = true;
    },

    resetSelectedScheduleId(state: DebriefState, action: PayloadAction<{ applicationId: string }>) {
      const { applicationId } = action.payload;
      state.byId[applicationId].selectedScheduleId = null;
      state.byId[applicationId].isScheduleSelected = false;
    },

    updateAdditionalAttendees(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; additionalAttendees: Employee[] }>
    ) {
      const { applicationId, additionalAttendees } = action.payload;
      state.byId[applicationId].additionalAttendees = additionalAttendees;
    },

    updateInterviewerCalendarId(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; interviewerCalendarId: string | null }>
    ) {
      const { applicationId, interviewerCalendarId } = action.payload;
      state.byId[applicationId].interviewerCalendarId = interviewerCalendarId;
    },

    updateInterviewerEventContent(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; interviewerEventContent: InterviewerEventContent }>
    ) {
      const { applicationId, interviewerEventContent } = action.payload;
      state.byId[applicationId].interviewerEventContent = interviewerEventContent;
    },

    updateInterviewerTemplateID(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; interviewerTemplateID: string }>
    ) {
      const { applicationId, interviewerTemplateID } = action.payload;
      state.byId[applicationId].interviewerTemplateID = interviewerTemplateID;
    },

    updateIsPrivateCalendarEvent(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; enabled: boolean }>
    ) {
      const { enabled, applicationId } = action.payload;
      state.byId[applicationId].isPrivateCalendarEvent = enabled;
    },

    updateInterviewerContentDescription(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; description: string }>
    ) {
      const { applicationId, description } = action.payload;
      const { interviewerEventContent } = state.byId[applicationId];
      if (!interviewerEventContent) return;
      interviewerEventContent.description = description;
    },

    updateInterviewerContentSummary(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; summary: string }>
    ) {
      const { applicationId, summary } = action.payload;
      const { interviewerEventContent } = state.byId[applicationId];
      if (!interviewerEventContent) return;
      interviewerEventContent.summary = summary;
    },

    setScheduleContentStepSubmitting(state: DebriefState, action: PayloadAction<{ applicationId: string }>) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line max-lines
      const { applicationId } = action.payload;
      state.byId[applicationId].stepScheduleContent.isSubmitting = true;
      state.byId[applicationId].stepScheduleContent.isWaiting = true;
    },

    clearScheduleContentStepSubmitting(state: DebriefState, action: PayloadAction<{ applicationId: string }>) {
      const { applicationId } = action.payload;
      state.byId[applicationId].stepScheduleContent.isSubmitting = false;
      state.byId[applicationId].stepScheduleContent.isWaiting = false;
    },

    setScheduleContentStepWaiting(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; isWaiting: boolean }>
    ) {
      const { applicationId, isWaiting } = action.payload;
      state.byId[applicationId].stepScheduleContent.isWaiting = isWaiting;
    },

    setScheduleContentStepError(state: DebriefState, action: PayloadAction<{ applicationId: string; error: string }>) {
      const { applicationId, error } = action.payload;
      state.byId[applicationId].stepScheduleContent.error = error;
    },

    setCandidateName(state: DebriefState, action: PayloadAction<{ applicationId: string; candidateName: string }>) {
      const { applicationId, candidateName } = action.payload;
      state.byId[applicationId].candidateName = candidateName;
    },

    setMeetingRoomSuggestions(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; meetingRoomSuggestions: MeetingRoomSuggestionInterface[] }>
    ) {
      const { applicationId, meetingRoomSuggestions } = action.payload;
      state.byId[applicationId].meetingRoomSuggestions = meetingRoomSuggestions;
    },

    setPreviousSlackChannelInfo(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; taskConversationId: string; taskConversationName: string }>
    ) {
      const { applicationId, taskConversationId, taskConversationName } = action.payload;
      state.byId[applicationId].taskConversationId = taskConversationId;
      state.byId[applicationId].taskConversationName = taskConversationName;
    },

    setTaskConversationTemplateId(
      state: DebriefState,
      action: PayloadAction<{ applicationId: string; taskConversationTemplateId: string }>
    ) {
      const { applicationId, taskConversationTemplateId } = action.payload;
      state.byId[applicationId].taskConversationTemplateId = taskConversationTemplateId;
    },

    setSlackChannelEnabled(state: DebriefState, action: PayloadAction<{ applicationId: string; enabled: boolean }>) {
      const { applicationId, enabled } = action.payload;
      state.byId[applicationId].slackChannelEnabled = enabled;
    },
  },
});

export const { reducer } = debriefSlice;
export default debriefSlice;
