import { SvgIcon, SvgIconProps } from './SvgIcon';

export const EyeDropperIcon = (props: SvgIconProps): JSX.Element => {
  const { viewBox, ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox={viewBox ?? '0 0 20 20'}>
      <path
        d="M3.5 17.5C2.94771 17.5 2.5 17.0523 2.5 16.5V13.9559C2.5 13.6906 2.60536 13.4363 2.79289 13.2488L9.95833 6.08331L9.37006 5.51533C9.03119 5.18814 9.03119 4.64516 9.37006 4.31797C9.69661 4.00268 10.2156 4.00723 10.5366 4.3282L11.5417 5.33331L14.125 2.74998C14.1944 2.68054 14.2812 2.62498 14.3854 2.58331C14.4896 2.54165 14.5972 2.52081 14.7083 2.52081C14.8194 2.52081 14.9236 2.54165 15.0208 2.58331C15.1181 2.62498 15.2083 2.68054 15.2917 2.74998L17.25 4.70831C17.3194 4.79165 17.375 4.88192 17.4167 4.97915C17.4583 5.07637 17.4792 5.18054 17.4792 5.29165C17.4792 5.40276 17.4583 5.5104 17.4167 5.61456C17.375 5.71873 17.3194 5.80554 17.25 5.87498L14.6875 8.43748L15.6938 9.47025C16.0137 9.79857 16.0103 10.323 15.6861 10.6472C15.3549 10.9784 14.8165 10.9736 14.4912 10.6367L13.9167 10.0416L6.75123 17.2071C6.56369 17.3946 6.30934 17.5 6.04412 17.5H3.5ZM4.16667 15.8333H5.79167L12.7083 8.87498L11.125 7.29165L4.16667 14.2083V15.8333ZM13.4792 7.29165L15.4792 5.29165L14.7083 4.52081L12.7083 6.52081L13.4792 7.29165Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
