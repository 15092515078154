import React from 'react';

import { gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/core';
import { FCWithFragments } from '@modernloop/shared/components';

import { InterviewerCommunicationCards_InterviewPlanFragment } from 'src/generated/mloop-graphql';

import Stack from 'src/components/Stack';
import Label from 'src/components/label';

import useScheduleWithoutBreaks from 'src/hooks/useScheduleWithoutBreaks';

import { getSelectedScheduleId } from 'src/store/selectors/scheduling';

import { useSelector } from 'src/store';

import InterviewerCommonTemplate from './InterviewerCommonTemplate';
import InterviewerContentCard from './InterviewerContentCard';

const useStyles = makeStyles(() => ({
  root: { marginBottom: '8px' },
  label: {
    padding: '24px 0px 0px 8px',
  },
}));

type Fragments = {
  interviewPlan: InterviewerCommunicationCards_InterviewPlanFragment | undefined;
};

const InterviewerCommunicationCards: FCWithFragments<Fragments> = ({ interviewPlan }): JSX.Element | null => {
  const classes = useStyles();
  const selectedScheduleId = useSelector(getSelectedScheduleId);
  const schedule = useScheduleWithoutBreaks(selectedScheduleId);

  if (!selectedScheduleId) return null;

  const interviewerEvents = schedule?.events;

  return (
    <Stack spacing={2} direction="column">
      <Label className={classes.label} variant="title">
        Internal
      </Label>
      {schedule && interviewerEvents?.length && interviewerEvents.length > 1 && (
        <InterviewerCommonTemplate scheduleId={selectedScheduleId} />
      )}
      {schedule &&
        interviewerEvents?.map((event) => {
          return (
            <InterviewerContentCard
              scheduleId={selectedScheduleId}
              key={event.id}
              eventId={event.id}
              interviewPlan={interviewPlan}
            />
          );
        })}
    </Stack>
  );
};

InterviewerCommunicationCards.fragments = {
  interviewPlan: gql`
    ${InterviewerContentCard.fragments.interviewPlan}
    fragment InterviewerCommunicationCards_interviewPlan on JobStage {
      id
      ...InterviewerContentCard_interviewPlan
    }
  `,
};

export default InterviewerCommunicationCards;
