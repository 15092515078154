import React, { useCallback, useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';

import {
  BaseInterviewSeatModule_LinkableInterviewsFragment,
  BaseInterviewSeatModule_LinkedInterviewFragment,
  BaseInterviewSeatModule_OriginalSeatFragment,
  BaseInterviewSeatModule_SeatFragment,
  Employee,
  InterviewModule,
  JobStageInterviewSeatType,
} from 'src/generated/mloop-graphql';

import { PREFERENCE_LEVEL_NON_PREFERRED } from 'src/constants/InterviewPlan';

import BaseEditInterviewSeatModule from './BaseEditInterviewSeatModule';
import BaseReadInterviewSeatModule from './BaseReadInterviewSeatModule';
import { AutoCompleteOption, AutoCompleteOptionType, BaseInterviewSeatModuleProps as Props } from './types';

enum InterviewSeatMode {
  EDIT = 'edit',
  READ = 'read',
}

type Fragments = {
  seat: BaseInterviewSeatModule_SeatFragment;
  originalSeat: BaseInterviewSeatModule_OriginalSeatFragment | undefined;
  linkedInterview: BaseInterviewSeatModule_LinkedInterviewFragment | undefined;
  linkableInterviews: BaseInterviewSeatModule_LinkableInterviewsFragment[];
};

const BaseInterviewSeatModule: FCWithFragments<Fragments, Props> = (props): JSX.Element | null => {
  const [mode, setMode] = useState<InterviewSeatMode>(InterviewSeatMode.READ);
  const { seat, originalSeat, linkedInterview, linkableInterviews, seatIndex, onUpdated, onDelete } = props;

  useEffect(() => {
    if (
      seat &&
      ((seat.type === JobStageInterviewSeatType.Freeform &&
        seat.freeformSeat?.jobStageInterviewSeatEmployees?.length === 0) ||
        (seat.type === JobStageInterviewSeatType.Module && !seat.moduleSeat?.interviewModuleId) ||
        (seat.type === JobStageInterviewSeatType.Linked && !seat.linkedSeat?.linkedJobStageInterviewSeatId))
    ) {
      setMode(InterviewSeatMode.EDIT);
    }
  }, [seat]);

  const handleEditComplete = useCallback(
    (options: AutoCompleteOption[]) => {
      setMode(InterviewSeatMode.READ);
      if (!options.length) {
        return;
      }
      const { type } = options[0];
      let updatedSeat: BaseInterviewSeatModule_SeatFragment | undefined;

      if (type === AutoCompleteOptionType.EMPLOYEE) {
        const interviewerPreferrenceLevelMap: { [employeeId: string]: number } = {};
        if (seat.type === JobStageInterviewSeatType.Freeform) {
          seat.freeformSeat?.jobStageInterviewSeatEmployees?.forEach((emp) => {
            if (emp?.employeeId) {
              interviewerPreferrenceLevelMap[emp.employeeId] = emp.preferenceLevel || PREFERENCE_LEVEL_NON_PREFERRED;
            }
          });
        }

        updatedSeat = {
          id: seat.id,
          type: JobStageInterviewSeatType.Freeform,
          index: seatIndex,
          freeformSeat: {
            jobStageInterviewSeatEmployees: options.map((option) => {
              return {
                jobStageInterviewSeatId: seat.id,
                employeeId: option?.employee?.id,
                employee: option.employee as Employee,
                preferenceLevel: option?.employee?.id
                  ? interviewerPreferrenceLevelMap[option.employee.id] || PREFERENCE_LEVEL_NON_PREFERRED
                  : PREFERENCE_LEVEL_NON_PREFERRED,
              };
            }),
          },
        };
      } else if (type === AutoCompleteOptionType.INTERVIEW) {
        updatedSeat = {
          id: seat.id,
          type: JobStageInterviewSeatType.Module,
          index: seatIndex,
          moduleSeat: {
            interviewModuleId: options[0].interview?.id,
            interviewModule: options[0].interview as InterviewModule,
            selectedEmployeeIds: [],
          },
        };
      } else if (type === AutoCompleteOptionType.SEAT) {
        updatedSeat = {
          id: seat.id,
          type: JobStageInterviewSeatType.Linked,
          index: seatIndex,
          linkedSeat: {
            linkedJobStageInterviewSeatId: options[0].originalSeatId,
          },
        };
      }

      if (!updatedSeat) return;
      onUpdated({
        id: updatedSeat.id,
        type: updatedSeat.type,
        index: updatedSeat.index,
        freeformSeat: updatedSeat.freeformSeat,
        moduleSeat: updatedSeat.moduleSeat,
        linkedSeat: updatedSeat.linkedSeat,
      });
    },
    [onUpdated, seat.freeformSeat?.jobStageInterviewSeatEmployees, seat.id, seat.type, seatIndex]
  );

  const handleInterviewerPreferenceChanged = useCallback(
    (employeeId: string, preferenceLevel: number) => {
      if (seat.type !== JobStageInterviewSeatType.Freeform) return;

      onUpdated({
        ...seat,
        freeformSeat: {
          ...seat.freeformSeat,
          jobStageInterviewSeatEmployees: seat.freeformSeat?.jobStageInterviewSeatEmployees?.map((emp) =>
            emp?.employeeId === employeeId ? { ...emp, preferenceLevel } : emp
          ),
        },
      });
    },
    [onUpdated, seat]
  );

  const handleDelete = useCallback(() => {
    onDelete(seat.id);
  }, [onDelete, seat.id]);

  if (!seat) return null;

  if (mode === InterviewSeatMode.READ) {
    return (
      <BaseReadInterviewSeatModule
        {...props}
        onEdit={() => {
          setMode(InterviewSeatMode.EDIT);
        }}
        seat={seat}
        originalSeat={originalSeat}
        onDelete={handleDelete}
        linkedInterview={linkedInterview}
        onUpdated={onUpdated}
        onInterviewerPreferenceChanged={handleInterviewerPreferenceChanged}
      />
    );
  }

  return (
    <BaseEditInterviewSeatModule
      {...props}
      seat={seat}
      originalSeatId={originalSeat?.id}
      onEditComplete={handleEditComplete}
      onDelete={handleDelete}
      linkableInterviewsFragment={linkableInterviews}
      linkedInterview={linkedInterview}
    />
  );
};

BaseInterviewSeatModule.fragments = {
  seat: gql`
    ${BaseReadInterviewSeatModule.fragments.seat}
    ${BaseEditInterviewSeatModule.fragments.seat}
    fragment BaseInterviewSeatModule_seat on JobStageInterviewSeat {
      id
      type
      ...BaseReadInterviewSeatModule_seat
      ...BaseEditInterviewSeatModule_seat
    }
  `,
  originalSeat: gql`
    ${BaseReadInterviewSeatModule.fragments.originalSeat}
    fragment BaseInterviewSeatModule_originalSeat on JobStageInterviewSeat {
      id
      ...BaseReadInterviewSeatModule_originalSeat
    }
  `,
  linkedInterview: gql`
    ${BaseReadInterviewSeatModule.fragments.linkedInterview}
    ${BaseEditInterviewSeatModule.fragments.linkedInterview}
    fragment BaseInterviewSeatModule_linkedInterview on JobStageInterview {
      id
      ...BaseReadInterviewSeatModule_linkedInterview
      ...BaseEditInterviewSeatModule_linkedInterview
    }
  `,
  linkableInterviews: gql`
    ${BaseEditInterviewSeatModule.fragments.linkableInterviewsFragment}
    fragment BaseInterviewSeatModule_linkableInterviews on JobStageInterview {
      id
      ...BaseEditInterviewSeatModule_linkableInterviews
    }
  `,
};

export default BaseInterviewSeatModule;
