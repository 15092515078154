/**
 * Org prefs type definitions
 */

export enum OrganizationPreference {
  DEFAULT_WORK_HOURS = 'obj_default_work_hours',
  SLACK_CHANNEL_NAME_FORMAT = 'str_slack_channel_name_format',
  COMPANY_HOLIDAYS = 'arr_company_holidays',
}

export interface TimeBlock {
  start: string;
  end: string;
}

export type NullableTimeBlockWeek = TimeBlockWeek | null;

export interface TimeBlockWeek {
  sunday: TimeBlock[];
  monday: TimeBlock[];
  tuesday: TimeBlock[];
  wednesday: TimeBlock[];
  thursday: TimeBlock[];
  friday: TimeBlock[];
  saturday: TimeBlock[];
}

export const DEFAULT_WORK_HOUR: TimeBlock = { start: '09:00', end: '17:00' };

export const DEFAULT_WORK_HOURS: TimeBlockWeek = {
  sunday: [],
  monday: [DEFAULT_WORK_HOUR],
  tuesday: [DEFAULT_WORK_HOUR],
  wednesday: [DEFAULT_WORK_HOUR],
  thursday: [DEFAULT_WORK_HOUR],
  friday: [DEFAULT_WORK_HOUR],
  saturday: [],
};

export const isTimeBlockWeekNotEmpty = (
  timeBlockWeek: TimeBlockWeek | null | undefined
): timeBlockWeek is TimeBlockWeek => {
  if (!timeBlockWeek) return false; // is empty
  // must have 1 or more time blocks
  return (
    (timeBlockWeek?.sunday?.length || 0) +
      (timeBlockWeek?.monday?.length || 0) +
      (timeBlockWeek?.tuesday?.length || 0) +
      (timeBlockWeek?.wednesday?.length || 0) +
      (timeBlockWeek?.thursday?.length || 0) +
      (timeBlockWeek?.friday?.length || 0) +
      (timeBlockWeek?.saturday?.length || 0) >
    0
  );
};

export enum DynamicAudienceType {
  JOB_COORDINATOR = 'JOB_COORDINATOR',
  JOB_RECRUITER = 'JOB_RECRUITER',
  CANDIDATE_COORDINATOR = 'CANDIDATE_COORDINATOR',
  CANDIDATE_RECRUITER = 'CANDIDATE_RECRUITER',
}

export type NotificationPreference = {
  employeeIds?: string[];
  externalEmailAddresses?: string[];
  types?: DynamicAudienceType[];
};
