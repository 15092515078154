import React from 'react';

import { Dialog } from '@modernloop/shared/components';

type Props = {
  onClose: () => void;
  onContinue: () => void;
};

const AvailabilityCloseAlertModal = ({ onClose, onContinue }: Props) => {
  return (
    <Dialog
      open
      dataTestId="schedule-content-actions-availability-request-alert-dialog"
      title="Your request will be closed"
      subTitle="The candidate will not be able to make any more changes"
      onClose={onClose}
      cancelOptions={{
        dataTestId: 'schedule-content-actions-availability-request-alert-cancel-button',
        label: 'Cancel',
        onClick: onClose,
      }}
      submitOptions={{ label: 'Continue', onClick: onContinue }}
    >
      There is an open availability request out to the candidate for this task. After your changes have been made, this
      request will be closed.
    </Dialog>
  );
};

export default AvailabilityCloseAlertModal;
