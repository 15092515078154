import React from 'react';

import BaseIcon, { Props } from './Icon';

const ArrowDownIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        d="M10 16L5.5 12M10 16L14.5 12M10 16V3.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export default ArrowDownIcon;
