import React, { useMemo, useRef } from 'react';

import { Box, SxProps, Typography, useTheme } from '@mui/material';

import Tooltip from 'src/components/tooltip';
import { useShowTooltip } from 'src/components/tooltip/MaybeTooltip';
import { BaseProps, createSxProps } from 'src/components/types';
import {
  SupportedFunctionColor,
  SupportedGrey,
  SupportedStatusColor,
  getBackgroundColorForTextColorFromTheme,
  getColorFromTheme,
  getTextColorFromTheme,
  isSupportedFunctionColor,
  isSupportedTextColor,
} from 'src/components/utils/color';

import { Theme } from 'src/themeMui5/type';

//
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type TagProps = BaseProps & {
  label: string | JSX.Element;
  icon?: React.ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  color?: SupportedStatusColor | SupportedFunctionColor | SupportedGrey | 'foreground' | 'default';
  // eslint-disable-next-line react/no-unused-prop-types
  fontWeight?: 400 | 500 | 600; // normal, medium, semibold
  invertedColor?: boolean;
  size?: 'medium' | 'small';
  variant?: 'filled' | 'outlined';
  sx?: SxProps<Theme>;
};

const useSxProps = (props: TagProps) => {
  const theme = useTheme();

  const sxProps = useMemo(() => {
    return {
      root: (() => {
        let color = theme.palette.text.primary;
        if (props.color && isSupportedTextColor(props.color)) {
          color = getTextColorFromTheme(props.color, theme);
        }
        if (props.color && isSupportedFunctionColor(props.color)) {
          color = getColorFromTheme(props.color, theme);
        }

        if (props.color === 'info') {
          color = theme.palette.info.contrastText;
        }

        const backgroundColor =
          props.color && props.variant === 'filled'
            ? getBackgroundColorForTextColorFromTheme(props.color, theme)
            : theme.palette.background.default;

        return {
          display: 'flex',
          alignItems: 'center',
          fontFamily: theme.typography.fontFamily,
          color,
          backgroundColor,
          borderRadius: '6px',
          padding: `0 ${theme.spacing(0.5)}`,
          border: `1px solid ${theme.grey.alpha.min}`,
          maxWidth: 'fit-content',
        };
      })(),
      rootInvertedColor: (() => {
        let backgroundColor = theme.palette.background.default;
        if (props.color && isSupportedTextColor(props.color)) {
          backgroundColor = getTextColorFromTheme(props.color, theme);
        } else if (props.color && isSupportedFunctionColor(props.color)) {
          backgroundColor = getColorFromTheme(props.color, theme);
        }

        const color = theme.palette.background.default;
        return {
          display: 'flex',
          alignItems: 'center',
          fontFamily: theme.typography.fontFamily,
          color,
          backgroundColor,
          borderRadius: '6px',
          padding: `${theme.spacing(props.size === 'small' ? 0 : 0.25)} ${theme.spacing(0.5)}`,
          border: `1px solid ${theme.grey.alpha.min}`,
          maxWidth: 'fit-content',
        };
      })(),
      small: {
        fontSize: '13px',
        lineHeight: '18px',
        height: '20px',
        paddingLeft: '4px',
        paddingRight: '4px',
        fontWeight: props.fontWeight ?? 400,
      },
      medium: {
        fontSize: '15px',
        lineHeight: '22px',
        height: '24px',
        paddingLeft: '8px',
        paddingRight: '8px',
        fontWeight: props.fontWeight ?? 400,
      },
    };
  }, [props, theme]);

  return createSxProps(sxProps);
};

/**
 * @deprecated - Use `Chip` from `@mui/material` instead
 */
const Tag = (props: TagProps): JSX.Element => {
  const { dataTestId, label, icon, sx, invertedColor, size = 'small', variant = 'outlined' } = props;
  const sxProps = useSxProps({ ...props, size, variant });

  const textElementRef = useRef<HTMLElement>(null);
  const showTooltip = useShowTooltip(textElementRef, label);

  const sxPropsRoot = useMemo(() => {
    return { ...(invertedColor ? sxProps.rootInvertedColor : sxProps.root), ...sxProps[size], ...sx };
  }, [invertedColor, size, sx, sxProps]);

  return (
    <Tooltip tooltip={label} disableHoverListener={!showTooltip} style={{ overflow: 'hidden' }}>
      <Typography sx={sxPropsRoot} noWrap data-testid={dataTestId} ref={textElementRef}>
        {icon && (
          <Box display="flex" pr="4px">
            {icon}
          </Box>
        )}
        {label}
      </Typography>
    </Tooltip>
  );
};

export default Tag;
