import { SvgIcon, SvgIconProps } from './SvgIcon';

export const MoreIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <circle cx="5" cy="10.5" r="1.5" fill="currentColor" />
      <circle cx="10" cy="10.5" r="1.5" fill="currentColor" />
      <circle cx="15" cy="10.5" r="1.5" fill="currentColor" />
    </SvgIcon>
  );
};
