import { TimeRange } from './types';
import { assertIsoTimestamp, getTimeInTimezone, isSameDay, startOfDay } from '@modernloop/shared/datetime';
import {
  areIntervalsOverlapping,
  compareAsc,
  hoursToMinutes,
  addDays,
  getDay,
  isAfter,
  isEqual,
  isPast,
  parseISO,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { round } from 'lodash';
import _ from 'lodash';

export const sortTimeRanges = (ranges: TimeRange[]): TimeRange[] => {
  return ranges.sort((first, second) => {
    const cmpResult = compareAsc(parseISO(first.startAt), parseISO(second.startAt));
    return cmpResult === 0 ? compareAsc(parseISO(first.endAt), parseISO(second.endAt)) : cmpResult;
  });
};

export const sortDateTimeRanges = (ranges: TimeRange[]): TimeRange[] => {
  if (!ranges.length) return ranges;
  return ranges.sort((first, second) => {
    const cmpResult = compareAsc(parseISO(first.startAt), parseISO(second.startAt));
    return cmpResult === 0 ? compareAsc(parseISO(first.endAt), parseISO(second.endAt)) : cmpResult;
  });
};

/**
 * Merges overlapping or adjacent time ranges into a single time range.
 * @param ranges The TimeRange array to merge.
 * @param inclusive Whether the ranges should be considered inclusive or not.
 * - We support inclusive parameter because this could merge times across days and we may not want that.
 * @returns An array of TimeRange such that all the TimeRange are non-overlapping.
 */
export const mergeTimeRanges = (ranges: TimeRange[], inclusive: boolean = true): TimeRange[] => {
  const sortedRanges = sortTimeRanges([...ranges]);

  let index = 0;
  const mergedRanges: TimeRange[] = [];

  sortedRanges.forEach((_, i) => {
    const range = { ...sortedRanges[i] };
    if (i === 0) {
      mergedRanges.push(range);
      return;
    }

    if (
      areIntervalsOverlapping(
        { start: parseISO(mergedRanges[index].startAt), end: parseISO(mergedRanges[index].endAt) },
        { start: parseISO(range.startAt), end: parseISO(range.endAt) },
        { inclusive }
      )
    ) {
      mergedRanges[index].endAt =
        parseISO(mergedRanges[index].endAt).getTime() > parseISO(range.endAt).getTime()
          ? mergedRanges[index].endAt
          : range.endAt;
    } else {
      index++;
      mergedRanges.push(range);
    }
  });

  return mergedRanges;
};

/**
 * A time range of (9am to 5pm) in PST spans 2 days in IST (9:30pm to midnight) & (midnight to 5:30am)
 * This function helps with the above conversion.
 * The main use case is for showing correct dates in TimeRangePicker and DateTimeRangePicker components.
 * @param ranges The TimeRange array in UTC.
 * @param timezone The timezone to which the time ranges needs to be returned in.
 * @returns An array of TimeRange such that all the TimeRange are within the same day for passed timezone
 */

export const getTimeRangesInTimezone = (ranges: TimeRange[], timezone: string): TimeRange[] => {
  const dateRanges = sortTimeRanges([...ranges]).map((range) => ({
    startAt: parseISO(range.startAt),
    endAt: parseISO(range.endAt),
  }));

  const tempResult: TimeRange[] = [];

  dateRanges.forEach((dateRange) => {
    if (
      isSameDay(
        assertIsoTimestamp(dateRange.startAt.toISOString()),
        assertIsoTimestamp(dateRange.endAt.toISOString()),
        timezone
      )
    ) {
      tempResult.push({ startAt: dateRange.startAt.toISOString(), endAt: dateRange.endAt.toISOString() });
      return;
    }

    tempResult.push({
      startAt: dateRange.startAt.toISOString(),
      endAt: startOfDay(assertIsoTimestamp(addDays(dateRange.startAt, 1).toISOString()), timezone).toString(),
    });

    // TODO: Maybe handle the scenario where the start and end date span multiple days instead of consecutive days.
    tempResult.push({
      startAt: startOfDay(assertIsoTimestamp(dateRange.endAt.toISOString()), timezone).toString(),
      endAt: dateRange.endAt.toISOString(),
    });
  });

  const mergedResult = mergeTimeRanges(tempResult, false);
  const result: TimeRange[] = [];
  let index = 0;

  mergedResult.forEach((mergedRange, i) => {
    if (i === 0) {
      result.push(mergedRange);
      return;
    }

    if (
      result[index].endAt === mergedRange.startAt &&
      isSameDay(assertIsoTimestamp(result[index].startAt), assertIsoTimestamp(mergedRange.endAt), timezone)
    ) {
      result[index].endAt = mergedRange.endAt;
    } else {
      result.push(mergedRange);
      index++;
    }
  });

  return result;
};

export const TIME_UNITS = {
  Day: 'day',
  Hour: 'hour',
  Min: 'min',
};

// Function that returns a string depending on the number of decimal points.
// eslint-disable-next-line max-params
export const getTimeUnitString = (num: number, decimalPoints: number, dateUnit: string) => {
  // Checking if the number is a whole number.

  const newNumber = round(num, decimalPoints);

  return `${newNumber} ${dateUnit}${newNumber === 1 ? '' : 's'}`;
};

export const hoursToDayDisplay = (hours: number): string => {
  let time = '';

  const isNegative = !!(hours < 0);

  // Calculating the date according to the absolute value of hours.
  const absoluteHours = Math.abs(hours);

  // If hours are greater than 24 -> String should be in 'days'
  if (absoluteHours > 24) {
    const days = parseFloat((absoluteHours / 24).toFixed(1));

    time += getTimeUnitString(days, 1, TIME_UNITS.Day);
  }
  // If hours are greater than 1 AND less than 24 -> String should be in 'hours'
  else if (absoluteHours > 1) {
    time += getTimeUnitString(absoluteHours, 1, TIME_UNITS.Hour);
  }
  // If hours are less than 1 -> String should be in 'mins'
  else {
    const minutes = parseFloat(hoursToMinutes(absoluteHours).toFixed(1));

    time += getTimeUnitString(minutes, 1, TIME_UNITS.Min);
  }

  return isNegative ? `-${time.trim()}` : time.trim();
};

export const getUniqueTimeRanges = (timeRanges: TimeRange[]): TimeRange[] => {
  return _.uniqWith(timeRanges, _.isEqual);
};

export interface TimeBlockWeek {
  sunday: TimeRange[];
  monday: TimeRange[];
  tuesday: TimeRange[];
  wednesday: TimeRange[];
  thursday: TimeRange[];
  friday: TimeRange[];
  saturday: TimeRange[];
}

export const getTimeBlockWeekArray = (timeBlockWeek: TimeBlockWeek): TimeRange[][] => {
  return [
    timeBlockWeek.sunday,
    timeBlockWeek.monday,
    timeBlockWeek.tuesday,
    timeBlockWeek.wednesday,
    timeBlockWeek.thursday,
    timeBlockWeek.friday,
    timeBlockWeek.saturday,
  ];
};

/**
 * Follows the following logic:
 * - Calculates all the time periods in the local time zone and then converts it to the given timezone.
 * @param startAt Time as which the last interview ends for a candidate.
 * @param timeBlockWeek Working hours.
 * @param timezone The timezone in which the time periods needs to be created.
 * @returns Time periods calculated based on start time, working hours and timezone.
 *          Here the start and end date are ISO date string and not `HH:mm` as expected
 */

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line max-params
export const getTimePeriods = (startAt: string, timeBlockWeek: TimeBlockWeek, timezone: string): TimeRange[] => {
  const timeBlockWeekArray = getTimeBlockWeekArray(timeBlockWeek);

  const getTime = (time: Date, hourMins: string) => {
    const parts = hourMins.split(':');

    // Set the time in the local timezone.
    const result = new Date(time.getFullYear(), time.getMonth(), time.getDate());
    result.setHours(parseInt(parts[0], 10), parseInt(parts[1], 10), 0, 0);

    return result;
  };

  let startTime = utcToZonedTime(parseISO(startAt), timezone);

  // If the date is in past then use the next hour as start time.
  if (isPast(startTime)) {
    startTime = new Date();
    startTime.setMinutes(0);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    startTime.setHours(startTime.getHours() + 1);
  }

  let day = getDay(startTime);
  let timeBlocks = timeBlockWeekArray[day];
  if (!timeBlocks) {
    timeBlocks = [
      {
        startAt: '09:00',
        endAt: '17:00',
      },
    ];
  }

  const timePeriods: TimeRange[] = [];

  // Add available time for the current day
  timeBlocks.forEach((timeBlock) => {
    const timeBlockEnd = getTime(startTime, timeBlock.endAt);
    if (isAfter(startTime, timeBlockEnd) || isEqual(startTime, timeBlockEnd)) {
      return;
    }

    const timeBlockStart = getTime(startTime, timeBlock.startAt);

    if (isAfter(startTime, timeBlockStart)) {
      timePeriods.push({
        startAt: getTimeInTimezone(assertIsoTimestamp(startTime.toISOString()), timezone),
        endAt: getTimeInTimezone(assertIsoTimestamp(timeBlockEnd.toISOString()), timezone),
      });
    } else {
      timePeriods.push({
        startAt: getTimeInTimezone(assertIsoTimestamp(timeBlockStart.toISOString()), timezone),
        endAt: getTimeInTimezone(assertIsoTimestamp(timeBlockEnd.toISOString()), timezone),
      });
    }
  });

  // Add the next 2 business days as availability
  [1, 2].forEach(() => {
    let dayCount = 0;
    timeBlocks = [];
    while (dayCount < 7) {
      day = ((day + 1) % 7) as 0 | 1 | 2 | 3 | 4 | 5 | 6;
      startTime = addDays(startTime, 1);
      timeBlocks = timeBlockWeekArray[day];
      if (timeBlocks && timeBlocks.length) break;
      dayCount++;
    }

    if (timeBlocks && timeBlocks.length) {
      timeBlocks.forEach((timeBlock) => {
        timePeriods.push({
          startAt: getTimeInTimezone(assertIsoTimestamp(getTime(startTime, timeBlock.startAt).toISOString()), timezone),
          endAt: getTimeInTimezone(assertIsoTimestamp(getTime(startTime, timeBlock.endAt).toISOString()), timezone),
        });
      });
    }
  });

  return timePeriods;
};

export const isTimeBlockWeekNotEmpty = (
  timeBlockWeek: TimeBlockWeek | null | undefined
): timeBlockWeek is TimeBlockWeek => {
  if (!timeBlockWeek) return false; // is empty
  // must have 1 or more time blocks
  return (
    (timeBlockWeek?.sunday?.length || 0) +
      (timeBlockWeek?.monday?.length || 0) +
      (timeBlockWeek?.tuesday?.length || 0) +
      (timeBlockWeek?.wednesday?.length || 0) +
      (timeBlockWeek?.thursday?.length || 0) +
      (timeBlockWeek?.friday?.length || 0) +
      (timeBlockWeek?.saturday?.length || 0) >
    0
  );
};

export const DEFAULT_WORK_HOUR: TimeRange = { startAt: '09:00', endAt: '17:00' };

export const DEFAULT_WORK_HOURS: TimeBlockWeek = {
  sunday: [],
  monday: [DEFAULT_WORK_HOUR],
  tuesday: [DEFAULT_WORK_HOUR],
  wednesday: [DEFAULT_WORK_HOUR],
  thursday: [DEFAULT_WORK_HOUR],
  friday: [DEFAULT_WORK_HOUR],
  saturday: [],
};
