import { Stack, Typography } from '@mui/material';

export type Props = {
  /**
   * The decoration of the dialog (avatar, icon, etc)
   * TODO: Add Zach's new icons as IconType and have this be a Union type
   */
  decoration?: JSX.Element;

  /**
   * The title of the dialog
   */
  title?: string;

  /**
   * The subtitle of the dialog
   */
  subTitle?: string;
};

const DialogTitle = ({ title, subTitle, decoration }: Props) => {
  return (
    <Stack spacing={0} pb={2.5}>
      {Boolean(decoration) && decoration}

      {title && (
        <Typography mt={1.5} variant="h4">
          {title}
        </Typography>
      )}
      {subTitle && (
        <Typography variant="body1" color="text.secondary">
          {subTitle}
        </Typography>
      )}
    </Stack>
  );
};

export default DialogTitle;
