import { SvgIcon, SvgIconProps } from './SvgIcon';

export const ChatIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.75C5.99594 2.75 2.75 5.99594 2.75 10C2.75 14.0041 5.99594 17.25 10 17.25C11.0446 17.25 12.0357 17.0296 12.9309 16.6334C13.0594 16.5765 13.2013 16.5569 13.3405 16.5767L16.7861 17.069C16.9511 17.0925 17.0925 16.9511 17.069 16.7861L16.5767 13.3405C16.5569 13.2013 16.5765 13.0594 16.6334 12.9309C17.0296 12.0357 17.25 11.0446 17.25 10C17.25 5.99594 14.0041 2.75 10 2.75ZM1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 11.18 18.516 12.3071 18.0913 13.3361L18.5539 16.574C18.7189 17.7289 17.7289 18.7189 16.574 18.5539L13.3361 18.0913C12.3071 18.516 11.18 18.75 10 18.75C5.16751 18.75 1.25 14.8325 1.25 10ZM6.25 8C6.25 7.58579 6.58579 7.25 7 7.25H13C13.4142 7.25 13.75 7.58579 13.75 8C13.75 8.41421 13.4142 8.75 13 8.75H7C6.58579 8.75 6.25 8.41421 6.25 8ZM7 11.25C6.58579 11.25 6.25 11.5858 6.25 12C6.25 12.4142 6.58579 12.75 7 12.75H10C10.4142 12.75 10.75 12.4142 10.75 12C10.75 11.5858 10.4142 11.25 10 11.25H7Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
