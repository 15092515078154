import React from 'react';

import { gql } from '@apollo/client';
import { Loader } from '@modernloop/shared/helper-components';
import { CrossIcon } from '@modernloop/shared/icons';
import { logDatadogError } from '@modernloop/shared/utils';
import { Box, Button, Divider, Paper, Stack, Theme, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  PendingApproval_ModuleMemberFragment,
  usePendingApprovalApplicationStageInterviewInterviewerUpsertMutation,
} from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ModuleMemberTrainingRole from 'src/entities/InterviewModuleMember/Statuses/ModuleMemberTrainingRole';
import { resetModuleMemberListCache } from 'src/entities/InterviewModuleMember/utils';

import InterviewLog from '../InterviewLog';
import InterviewLogInterview from '../InterviewLog/InterviewLogInterview';

import ActionButtons from './ActionButtons';
import PendingApprovalAlert from './PendingApprovalAlert';

type Fragments = {
  moduleMember: PendingApproval_ModuleMemberFragment;
};

const PendingApproval: FCWithFragments<Fragments> = ({ moduleMember }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateShouldCountForModule, { loading: shouldCountForModuleLoading }] =
    usePendingApprovalApplicationStageInterviewInterviewerUpsertMutation({
      refetchQueries: ['InterviewModuleMemberSidepanelQuery', 'InterviewModuleMemberModuleLogSidepanel'],
    });

  const pendingApprovalInterview = moduleMember.pendingApprovalApplicationStageInterview;
  if (!pendingApprovalInterview) {
    return null;
  }

  const handleUpdate = async () => {
    try {
      // eslint-disable-next-line no-underscore-dangle
      await updateShouldCountForModule({
        variables: {
          input: {
            applicationStageInterviewId: moduleMember?.pendingApprovalApplicationStageInterview?.id,
            interviewerId: moduleMember.employeeId,
            shouldCountForModule: false,
            interviewerRole: null,
          },
        },
        update: (cache) => {
          resetModuleMemberListCache({
            cache,
            moduleId: moduleMember.interviewModuleId,
            employeeIds: [moduleMember.employeeId],
          });
        },
      });
      enqueueSnackbar("Interview marked as didn't count", { variant: 'success' });
    } catch (err) {
      logDatadogError(err);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <Paper variant="outlined" sx={{ width: '100%' }}>
      <Stack
        direction="column"
        width="100%"
        sx={{
          backgroundImage: (theme: Theme) =>
            `linear-gradient(180deg, ${theme.palette.warning.tint} 0%, transparent 100%)`,
          backgroundColor: (theme: Theme) => theme.palette.warning.tint,
        }}
      >
        <Stack padding="12px 20px" spacing={1.5}>
          <PendingApprovalAlert moduleMember={moduleMember} />
          <ActionButtons moduleMember={moduleMember} disableActions={shouldCountForModuleLoading} />
        </Stack>

        <Divider />
        <Stack padding="12px 20px" spacing={1.5}>
          {!shouldCountForModuleLoading && (
            <Stack direction="column" spacing={1} width="100%">
              <Stack direction="row" justifyContent="space-between" p={1}>
                <Typography color="text.secondary" variant="subtitle2">
                  Interview being evaluated
                </Typography>
                <ModuleMemberTrainingRole status={moduleMember.status} />
              </Stack>
              <InterviewLogInterview
                moduleMember={moduleMember}
                interview={pendingApprovalInterview}
                role={moduleMember.status}
                showNagButton
              />
            </Stack>
          )}
          {!shouldCountForModuleLoading && (
            <Button
              startIcon={<CrossIcon />}
              fullWidth={false}
              variant="contained"
              color="secondary"
              onClick={handleUpdate}
              sx={{ width: 'fit-content', alignSelf: 'end' }}
              size="small"
            >
              This interview didn’t count
            </Button>
          )}
          {shouldCountForModuleLoading && (
            <Box p={2}>
              <Loader loading={shouldCountForModuleLoading} />
            </Box>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

PendingApproval.fragments = {
  moduleMember: gql`
    ${InterviewLog.fragments.interviewLog}
    ${ActionButtons.fragments.moduleMember}
    ${InterviewLogInterview.fragments.interview}
    ${InterviewLogInterview.fragments.moduleMember}
    ${PendingApprovalAlert.fragments.moduleMember}
    fragment PendingApproval_moduleMember on InterviewModuleMember {
      id
      interviewModuleId
      employeeId
      status
      stats {
        currentTrainingSessionStatus
      }
      pendingApprovalApplicationStageInterview {
        id
        startAt
        ...InterviewLogInterview_interview
      }
      ...ActionButtons_moduleMember
      ...InterviewLogInterview_moduleMember
      ...PendingApprovalAlert_moduleMember
    }
  `,
};

export const UpdateDontCountMutation = gql`
  mutation PendingApprovalApplicationStageInterviewInterviewerUpsert(
    $input: ApplicationStageInterviewInterviewerUpsertInput!
  ) {
    applicationStageInterviewInterviewerUpsert(input: $input) {
      result
    }
  }
`;

export default PendingApproval;
