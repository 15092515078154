import { SvgIcon, SvgIconProps } from './SvgIcon';

export const OpenNewTaskIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M4.24998 5C4.24998 3.48122 5.48119 2.25 6.99998 2.25H7.99997C8.41419 2.25 8.74997 2.58579 8.74997 3C8.74997 3.41421 8.41419 3.75 7.99997 3.75H6.99998C6.30962 3.75 5.74998 4.30965 5.74998 5L5.74998 6C5.74998 6.41421 5.4142 6.75 4.99998 6.75C4.58577 6.75 4.24998 6.41421 4.24998 6L4.24998 5ZM13.25 3C13.25 2.58579 13.5858 2.25 14 2.25H15C16.5187 2.25 17.75 3.48121 17.75 5L17.75 6C17.75 6.41421 17.4142 6.75 17 6.75C16.5858 6.75 16.25 6.41422 16.25 6L16.25 5C16.25 4.30964 15.6903 3.75 15 3.75H14C13.5858 3.75 13.25 3.41421 13.25 3ZM17 11.25C17.4142 11.25 17.75 11.5858 17.75 12L17.75 13C17.75 14.5188 16.5188 15.75 15 15.75H14C13.5858 15.75 13.25 15.4142 13.25 15C13.25 14.5858 13.5858 14.25 14 14.25H15C15.6903 14.25 16.25 13.6904 16.25 13L16.25 12C16.25 11.5858 16.5858 11.25 17 11.25ZM5.68934 13.2501L3.50001 13.2501C3.0858 13.2501 2.75001 12.9143 2.75001 12.5001C2.75001 12.0858 3.0858 11.7501 3.50001 11.7501L7.5 11.7501C7.91421 11.7501 8.25 12.0858 8.25 12.5001L8.25001 16.5001C8.25001 16.9143 7.91423 17.2501 7.50001 17.2501C7.0858 17.2501 6.75001 16.9143 6.75001 16.5001L6.75 14.3107L3.03033 18.0304C2.73744 18.3233 2.26256 18.3233 1.96967 18.0304C1.67678 17.7375 1.67678 17.2626 1.96967 16.9697L5.68934 13.2501ZM14.0511 8.00874C14.332 7.70438 14.313 7.22988 14.0087 6.94893C13.7043 6.66797 13.2298 6.68695 12.9489 6.99132L10.6992 9.42846L9.5202 8.29312C9.22183 8.00581 8.74704 8.01477 8.45972 8.31313C8.17241 8.6115 8.18137 9.08629 8.47973 9.3736L10.2105 11.0403C10.3558 11.1801 10.5512 11.2556 10.7528 11.2497C10.9544 11.2438 11.1451 11.1569 11.2818 11.0087L14.0511 8.00874Z"
      />
    </SvgIcon>
  );
};
