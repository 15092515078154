import React from 'react';

import * as SharedIcons from '@modernloop/shared/icons';

import ConditionalThemeProvider from 'src/themeMui5/ConditionalThemeProvider';

import * as Icons from '../icons';
import { Props } from '../icons/Icon';

// Readonly array of all the icons
export const ALL_ICONS = [
  'AllSchedulingTasksFilledIcon',
  'AllSchedulingTasksIcon',
  'ArchivedIcon',
  'ArchivedSmallIcon',
  'ArrowDownIcon',
  'ArrowRightIcon',
  'ArrowsUpDownIcon',
  'ArrowUpIcon',
  'AttributeIcon',
  'AvailabilityIcon',
  'AverageIcon',
  'BreakIcon',
  'CalendarClock',
  'CalendarIcon',
  'CandidateIcon',
  'CandidateIconFilled',
  'CandidatePortalIcon',
  'CandidateRsvpAwaitingIcon',
  'CandidateRsvpDeclineIcon',
  'CandidateRsvpGoingIcon',
  'CandidateRsvpMaybeIcon',
  'CaretDownIcon',
  'CaretDownTinyIcon',
  'CaretLeftIcon',
  'CaretRightIcon',
  'CaretRightSmallIcon',
  'CaretUpIcon',
  'CaretUpTinyIcon',
  'CheckboxIcon',
  'CheckboxSelectedIcon',
  'CheckIcon',
  'ChromeIcon',
  'CircleWithCheckIcon',
  'ClockIcon',
  'CodeIcon',
  'CoderPadIcon',
  'CodeSignalIcon',
  'CodilityIcon',
  'CompletedInterviewIcon',
  'CopyIcon',
  'CrossIcon',
  'DateFilledIcon',
  'DateIcon',
  'DayIcon',
  'DebriefIcon',
  'DeclinedInterviewIcon',
  'DescriptionOnIcon',
  'DirectoryIcon',
  'DirectoryIconFilled',
  'DownloadIcon',
  'DurationFilledIcon',
  'DurationIcon',
  'EditIcon',
  'EmailIcon',
  'FilterIcon',
  'FlagIcon',
  'GoogleCalendarIcon',
  'GoogleIcon',
  'GoogleMeetIcon',
  'HackerRankIcon',
  'HelpIcon',
  'HiredIcon',
  'HistoryIcon',
  'InfoIcon',
  'IntegrationsIcon',
  'InterviewerRsvpDeclineIcon',
  'InterviewerRsvpGoingIcon',
  'InterviewerRsvpMaybeIcon',
  'InterviewIcon',
  'JobIcon',
  'JobIconFilled',
  'LastWeekIcon',
  'LinkIcon',
  'LiveIcon',
  'LockIcon',
  'LogoutIcon',
  'MagicIcon',
  'MaximizeIcon',
  'MeetingRoomIcon',
  'MenuIcon',
  'MetricsIcon',
  'MetricsIconFilled',
  'MicrosoftIcon',
  'MicrosoftTeamsIcon',
  'MinimizeIcon',
  'MinusIcon',
  'ModernloopIcon',
  'ModernLoopLogoIcon',
  'ModulesIcon',
  'ModulesIconFilled',
  'MonthIcon',
  'MoreIcon',
  'MultipleUsersIcon',
  'MyTasksIcon',
  'NoIcon',
  'NoneIcon',
  'NoteIcon',
  'NotificationIcon',
  'OrderLockIcon',
  'OrderShuffleIcon',
  'OvernightIcon',
  'PauseIcon',
  'PlusIcon',
  'PreferencesIcon',
  'ProgressIcon',
  'QuestionMarkIcon',
  'RadioIcon',
  'RadioSelectedIcon',
  'RejectIcon',
  'ReorderIcon',
  'ResetIcon',
  'ReverseShadowIcon',
  'RsvpMaybeIcon',
  'RsvpNo',
  'RsvpYes',
  'ScheduleCompleteWithBgIcon',
  'ScheduleFilledIcon',
  'ScheduleFilledWithBgIcon',
  'ScheduleIcon',
  'ScheduleIconFilled',
  'ScheduleLiveWithBgIcon',
  'SchedulingIcon',
  'ScorecardFilledIcon',
  'ScorecardIcon',
  'ScorecardNoneIcon',
  'SearchIcon',
  'SendIcon',
  'SettingsIcon',
  'SettingsIconFilled',
  'ShadowIcon',
  'ShareIcon',
  'SidebarOffIcon',
  'SidebarOnIcon',
  'SlackIcon',
  'SuccessIcon',
  'TaskTagIcon',
  'TemplateIcon',
  'TimeIcon',
  'TimezoneIcon',
  'TipIcon',
  'TotalRsvpAllGoingIcon',
  'TotalRsvpAwaitingIcon',
  'TotalRsvpWarningIcon',
  'TraineeIcon',
  'TrashIcon',
  'TriangleDownIcon',
  'UnknownUserIcon',
  'UnscheduledIcon',
  'UserAddIcon',
  'UserEditIcon',
  'UserIcon',
  'UserOptionalIcon',
  'UserSlashIcon',
  'VideoIcon',
  'WarningIcon',
  'WeekIcon',
  'ZoomIcon',
  'VisibleIcon',
  'InterruptedIcon',
  'RescheduleReasonsIcon',
  'WrenchSetupIcon',
  'MentorIcon',
] as const;

// Union type of all the icons "ArrowUp" | "ArrowDown" | ....
export type IconType = typeof ALL_ICONS[number];

/**
 * This maps any icon type to any React Icon element.
 * It allows passing of type and shared components can manage icon state.
 */
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/validate-component-definition.cjs
export function getIconFromEnum(type: IconType, props?: Props): JSX.Element | null {
  const IconComponent = Icons[type];
  return <IconComponent {...props} />;
}

export function getSharedIconFromEnum(type: IconType, props?: Props): JSX.Element | null {
  if (type === 'WarningIcon') {
    const SharedIconComponent = SharedIcons[type];
    return (
      <ConditionalThemeProvider>
        <SharedIconComponent />
      </ConditionalThemeProvider>
    );
  }
  return getIconFromEnum(type, props);
}

// Helper functions
export function getDisabledIcon(type: IconType, props?: Props) {
  const disabledProps: Props = { ...props, color: 'mid-contrast-grey' };
  return getIconFromEnum(type, disabledProps);
}

export function getFocusedIcon(type: IconType, props?: Props) {
  const focusedProps: Props = { ...props, color: 'info' };
  return getIconFromEnum(type, focusedProps);
}
