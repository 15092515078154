import { gql } from '@apollo/client';
import { FunctionWithFragments } from '@modernloop/shared/components';
import { IsoDate, convertToIsoDate } from '@modernloop/shared/datetime';

import { GroupInterviewEventsByDate_InterviewsFragment } from 'src/generated/mloop-graphql';

type GroupedInterviewEvents = { [dateYyyyMmDd: IsoDate]: GroupInterviewEventsByDate_InterviewsFragment[] };

type Fragments = {
  interviews: GroupInterviewEventsByDate_InterviewsFragment[];
};

type Props = {
  timezone: string;
};

const groupInterviewEventsByDate: FunctionWithFragments<Fragments, Props, GroupedInterviewEvents> = (
  { interviews },
  { timezone }
): GroupedInterviewEvents => {
  const groupedInterviews: GroupedInterviewEvents = {};

  interviews.forEach((interview) => {
    const dateYyyyMmDd = convertToIsoDate(interview.startAt, timezone);

    if (!groupedInterviews[dateYyyyMmDd]) {
      groupedInterviews[dateYyyyMmDd] = [];
    }

    groupedInterviews[dateYyyyMmDd].push(interview);
  });

  return groupedInterviews;
};

groupInterviewEventsByDate.fragments = {
  interviews: gql`
    fragment groupInterviewEventsByDate_interviews on ApplicationStageInterview {
      id
      startAt
    }
  `,
};

export default groupInterviewEventsByDate;
