import React from 'react';

import BaseIcon, { Props } from './Icon';

const WeekIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.25C7.41422 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48121 17.75 5.99999L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H6.25V2C6.25 1.58579 6.58579 1.25 7 1.25ZM6.25 5V4.75H5C4.30964 4.75 3.75 5.30964 3.75 6L3.75001 15C3.75001 15.6904 4.30966 16.25 5.00001 16.25H15C15.6904 16.25 16.25 15.6904 16.25 15L16.25 6C16.25 5.30964 15.6903 4.75 15 4.75H13.75V5C13.75 5.41421 13.4142 5.75 13 5.75C12.5858 5.75 12.25 5.41421 12.25 5V4.75H7.75V5C7.75 5.41421 7.41422 5.75 7 5.75C6.58579 5.75 6.25 5.41421 6.25 5ZM7.21837 8.28449C7.09935 7.88774 6.68123 7.66261 6.28449 7.78163C5.88774 7.90065 5.66261 8.31877 5.78163 8.71551L7.28163 13.7155C7.36999 14.01 7.62916 14.2208 7.93551 14.2472C8.24186 14.2737 8.53331 14.1104 8.67082 13.8354L10 11.1771L11.3292 13.8354C11.4667 14.1104 11.7581 14.2737 12.0645 14.2472C12.3708 14.2208 12.63 14.01 12.7184 13.7155L14.2184 8.71551C14.3374 8.31877 14.1123 7.90065 13.7155 7.78163C13.3188 7.66261 12.9007 7.88774 12.7816 8.28449L11.8251 11.4731L10.6708 9.16459C10.5438 8.9105 10.2841 8.75 10 8.75C9.71592 8.75 9.45622 8.9105 9.32918 9.16459L8.17494 11.4731L7.21837 8.28449Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default WeekIcon;
