import React from 'react';

import BaseIcon, { Props } from './Icon';

const MonthIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 1.25C7.41422 1.25 7.75 1.58579 7.75 2V3.25H12.25V2C12.25 1.58579 12.5858 1.25 13 1.25C13.4142 1.25 13.75 1.58579 13.75 2V3.25H15C16.5188 3.25 17.75 4.48121 17.75 5.99999L17.75 7.9951C17.75 7.99673 17.75 7.99837 17.75 8C17.75 8.00164 17.75 8.00327 17.75 8.0049L17.75 12.4965C17.75 12.4977 17.75 12.4988 17.75 12.5C17.75 12.5012 17.75 12.5023 17.75 12.5035L17.75 15C17.75 16.5188 16.5188 17.75 15 17.75H12.5024C12.5016 17.75 12.5008 17.75 12.5 17.75C12.4992 17.75 12.4984 17.75 12.4976 17.75H7.50242C7.50161 17.75 7.50081 17.75 7.5 17.75C7.49919 17.75 7.49839 17.75 7.49758 17.75H5.00001C3.48123 17.75 2.25002 16.5188 2.25001 15L2.25001 12.5039C2.25 12.5026 2.25 12.5013 2.25 12.5C2.25 12.4987 2.25 12.4974 2.25001 12.4961L2.25 8.00217C2.25 8.00145 2.25 8.00073 2.25 8C2.25 7.99928 2.25 7.99856 2.25 7.99783L2.25 6C2.25 4.48122 3.48122 3.25 5 3.25H6.25V2C6.25 1.58579 6.58579 1.25 7 1.25ZM3.75 8.75L3.75001 11.75H6.75V8.75H3.75ZM3.75001 13.25L3.75001 15C3.75001 15.6904 4.30966 16.25 5.00001 16.25H6.75V13.25H3.75001ZM8.25 16.25H11.75V13.25H8.25V16.25ZM13.25 16.25H15C15.6904 16.25 16.25 15.6904 16.25 15L16.25 13.25H13.25V16.25ZM16.25 11.75L16.25 8.75H13.25V11.75H16.25ZM16.25 7.25H12.5004H12.5H12.4996H7.50016H7.5H7.49984H3.75L3.75 6C3.75 5.30964 4.30964 4.75 5 4.75H6.25V5C6.25 5.41421 6.58579 5.75 7 5.75C7.41422 5.75 7.75 5.41421 7.75 5V4.75H12.25V5C12.25 5.41421 12.5858 5.75 13 5.75C13.4142 5.75 13.75 5.41421 13.75 5V4.75H15C15.6903 4.75 16.25 5.30964 16.25 6L16.25 7.25ZM8.25 11.75V8.75H11.75V11.75H8.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 4.75H5C4.30964 4.75 3.75 5.30964 3.75 6V7.25H16.25V6C16.25 5.30964 15.6904 4.75 15 4.75H13.75V5C13.75 5.41421 13.4142 5.75 13 5.75C12.5858 5.75 12.25 5.41421 12.25 5V4.75H7.75V5C7.75 5.41421 7.41421 5.75 7 5.75C6.58579 5.75 6.25 5.41421 6.25 5V4.75Z"
        fill="currentColor"
        fillOpacity="0.43"
      />
    </BaseIcon>
  );
};

export default MonthIcon;
