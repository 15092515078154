import ApiVerb from './ApiVerb';
import { apiPost } from './index';

interface CalendarEvent {
  remoteCalendarId: string;
  remoteEventId: string;
}

export interface RequestBody {
  calendarEventIds: CalendarEvent[];
}

interface ResponseBody {
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  events: any[];
}

const getEventContent = async (data: RequestBody): Promise<ResponseBody> => {
  const response = await apiPost(ApiVerb.GET_EVENT_CONTENT, data);
  return response.data;
};

export default getEventContent;
