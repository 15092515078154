import React from 'react';

import BaseIcon, { Props } from './Icon';

const NoteIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 4.99716C4.75 4.30681 5.30965 3.74716 6 3.74716H10.5C10.9142 3.74716 11.25 3.41138 11.25 2.99716C11.25 2.58295 10.9142 2.24716 10.5 2.24716H6C4.48122 2.24716 3.25 3.47838 3.25 4.99717L3.25002 14.9972C3.25002 16.5159 4.48124 17.7472 6.00002 17.7472H14C15.5188 17.7472 16.75 16.5159 16.75 14.9972V10.5C16.75 10.0858 16.4142 9.75 16 9.75C15.5858 9.75 15.25 10.0858 15.25 10.5V14.9972C15.25 15.6875 14.6904 16.2472 14 16.2472H6.00002C5.30966 16.2472 4.75002 15.6875 4.75002 14.9972L4.75 4.99716ZM12.7199 3.71952C13.7032 2.73646 15.2972 2.73658 16.2804 3.71977C17.2637 4.70308 17.2637 6.29734 16.2804 7.28062L11.6769 11.8839C11.4425 12.1183 11.1245 12.25 10.793 12.25L8.5 12.25C8.30108 12.25 8.11031 12.171 7.96966 12.0303C7.82901 11.8897 7.74999 11.6989 7.75 11.5L7.7501 9.2057C7.75011 8.87414 7.88186 8.55616 8.11634 8.32174L12.7199 3.71952ZM15.2198 4.78043C14.8223 4.38296 14.1779 4.38292 13.7804 4.78033L13.3106 5.24995L14.7502 6.6895L15.2197 6.21994C15.6173 5.82243 15.6173 5.17794 15.2198 4.78043ZM9.2501 9.30933L12.2498 6.31046L13.6895 7.75013L10.6895 10.75L9.25003 10.75L9.2501 9.30933Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default NoteIcon;
