import React from 'react';

import BaseIcon, { Props } from './Icon';

const MentorIcon = ({ color, fontSize, tooltip }: Props): JSX.Element => {
  return (
    <BaseIcon color={color} fontSize={fontSize} tooltip={tooltip}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7071 2.70711C10.3166 2.31658 9.68341 2.31658 9.29289 2.70711L7.27853 4.72146C6.88801 5.11199 6.88801 5.74515 7.27853 6.13568L9.29289 8.15003C9.68341 8.54056 10.3166 8.54056 10.7071 8.15003L12.7215 6.13568C13.112 5.74515 13.112 5.11199 12.7215 4.72146L10.7071 2.70711ZM6.13569 7.27864C5.74517 6.88812 5.112 6.88812 4.72148 7.27864L2.70712 9.293C2.3166 9.68352 2.3166 10.3167 2.70712 10.7072L4.72148 12.7216C5.112 13.1121 5.74517 13.1121 6.13569 12.7216L8.15005 10.7072C8.54057 10.3167 8.54057 9.68352 8.15005 9.293L6.13569 7.27864ZM13.8643 7.27864C14.2548 6.88812 14.888 6.88812 15.2785 7.27864L17.2929 9.293C17.6834 9.68352 17.6834 10.3167 17.2929 10.7072L15.2785 12.7216C14.888 13.1121 14.2548 13.1121 13.8643 12.7216L11.8499 10.7072C11.4594 10.3167 11.4594 9.68352 11.8499 9.293L13.8643 7.27864ZM10.7071 11.8502C10.3166 11.4597 9.68341 11.4597 9.29289 11.8502L7.27853 13.8645C6.88801 14.2551 6.88801 14.8882 7.27853 15.2787L9.29289 17.2931C9.68341 17.6836 10.3166 17.6836 10.7071 17.2931L12.7215 15.2787C13.112 14.8882 13.112 14.2551 12.7215 13.8645L10.7071 11.8502Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default MentorIcon;
