import { useEffect } from 'react';

import { identifyDatadogUser } from '@modernloop/shared/utils';

import type { UserInfo } from 'src/contexts/auth0/Auth0Context';

/**
 * Identifies the user in DataDog. Sends name, email and organization,
 * @param userInfo from useAuth();
 * @returns void
 */
function identifyDatadogEmployeeUser(userInfo: UserInfo | null): void {
  if (!userInfo || !userInfo.userId || !userInfo.orgId || !userInfo.orgName) {
    return;
  }

  identifyDatadogUser({
    type: 'employee',
    // employee doesn't always exist before onboarding the directory.
    id: userInfo.employeeId || userInfo.userEmail, // if the employee doesn't exist, use the user email as obvious differentiator
    name: userInfo.employeeName || userInfo.userName,
    email: userInfo.employeeEmail || userInfo.userEmail,
    org_id: userInfo.orgId,
    org_name: userInfo.orgName,
  });
}

export const useIdentifyDatadogUser = (userInfo: UserInfo | null) => {
  useEffect(() => {
    identifyDatadogEmployeeUser(userInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userInfo)]);
};
