export const recruiterPortal = {
  url: process.env.BASE_URL as string,
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  audience: 'hasura', // This is just a name give to the application in Auth0, Nothing to do with Hasura
  useRefreshTokens: true,
};

export const amplitudeConfig = {
  apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
};

export const sentryConfig = {
  dns: process.env.REACT_APP_SENTRY_DNS_REACT,
};

export const netlifyConfig = {
  commitRef: process.env.REACT_APP_COMMIT_REF ?? 'unknown',
  nodeEnv: process.env.NODE_ENV,
};

export const launchDarklyConfig = {
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID as string,
};

export const fullcalendarConfig = {
  licenseKey: process.env.REACT_APP_FULLCALENDAR_LICENSE_KEY,
};

export const backendConfig = {
  url: process.env.REACT_APP_BACKEND_URL,
};

export const logrocketConfig = {
  clientSideID: process.env.REACT_APP_LOG_ROCKET_CLIENT_SIDE_ID as string,
  candidatePortalClientSideID: process.env.REACT_APP_CANDIDATE_PORTAL_LOG_ROCKET_CLIENT_SIDE_ID as string,
  proxyUrl: process.env.REACT_APP_LOG_ROCKET_PROXY_URL as string,
};

export const enviromentConfig = {
  environment: process.env.REACT_APP_ENVIRONMENT as 'development' | 'staging' | 'production',
};

export const tinyMceConfig = {
  apiKey: process.env.REACT_APP_TINY_MCE_API_KEY as string,
};

export const chromeExtConfig = {
  id: process.env.REACT_APP_CHROME_EXT_ID as string,
  version: process.env.REACT_APP_CHROME_EXT_VERSION as string,
};

export const CANDIDATE_PORTAL_BASE_URL = {
  url: process.env.CANDIDATE_PORT_BASE_URL as string,
};

type DataDogEnv = 'dev' | 'stage' | 'prod';

export const DatadogConfig = {
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID as string,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
  sessionSampleRate: parseInt(process.env.REACT_APP_DATADOG_SESSION_SAMPLE_RATE || '0', 10),
  sessionReplaySampleRate: parseInt(process.env.REACT_APP_DATADOG_SESSION_REPLAY_SAMPLE_RATE || '0', 10),
  service: process.env.REACT_APP_DATADOG_SERVICE as string,
  env: process.env.DATA_DOG_BE_ENV_MAP as DataDogEnv,
};

export const GraphQLConfig = {
  persistedEnabled: process.env.REACT_APP_GRAPHQL_PERSISTED_QUERY_ENABLED === 'true',
  batchEnabled: process.env.REACT_APP_GRAPHQL_BATCH_ENABLED === 'true',
  batchInterval: parseInt(process.env.REACT_APP_GRAPHQL_BATCH_INTERVAL || '0', 10),
  batchMax: parseInt(process.env.REACT_APP_GRAPHQL_BATCH_MAX || '0', 10),
};
