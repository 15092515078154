import React from 'react';

import { gql } from '@apollo/client';
import { Typography } from '@mui/material';

import { ActivityLogCandidatePreferredPhoneNumberChangeDetails_ActivityLogFragment } from 'src/generated/mloop-graphql';

import { FCWithFragments } from 'src/components/types';

import ActivityLogBase from 'src/entities/ActivityLog/Helpers/ActivityLogBase';
import ActivityLogContent from 'src/entities/ActivityLog/Helpers/ActivityLogContent';
import ActivityLogText from 'src/entities/ActivityLog/Helpers/ActivityLogText';

import logError from 'src/utils/logError';

interface Fragments {
  activityLog: ActivityLogCandidatePreferredPhoneNumberChangeDetails_ActivityLogFragment;
}

const ActivityLogCandidatePreferredPhoneNumberChangeDetails: FCWithFragments<Fragments> = ({ activityLog }) => {
  const { details } = activityLog;

  // eslint-disable-next-line no-underscore-dangle
  if (details?.__typename !== 'ActivityLogCandidatePreferredPhoneNumberChangeDetails') {
    logError(
      'ActivityLogCandidatePreferredPhoneNumberChangeDetails: details is not ActivityLogCandidatePreferredPhoneNumberChangeDetails'
    );
    return null;
  }

  const label = <ActivityLogText>edited a phone number</ActivityLogText>;

  return (
    <ActivityLogBase activityLog={activityLog} actionLabel={label}>
      <ActivityLogContent
        sx={{ borderRadius: '12px' }}
        content={<Typography>{details.updatedPhoneNumber || 'None'}</Typography>}
      />
    </ActivityLogBase>
  );
};

ActivityLogCandidatePreferredPhoneNumberChangeDetails.fragments = {
  activityLog: gql`
    ${ActivityLogBase.fragments.activityLog}
    fragment ActivityLogCandidatePreferredPhoneNumberChangeDetails_activityLog on ActivityLog {
      id
      details {
        __typename
        ... on ActivityLogCandidatePreferredPhoneNumberChangeDetails {
          updatedPhoneNumber
        }
      }
      ...ActivityLogBase_activityLog
    }
  `,
};

export default ActivityLogCandidatePreferredPhoneNumberChangeDetails;
