import React, { useRef, useState } from 'react';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Button from 'src/components/button';
import { TriangleDownIcon } from 'src/components/icons';
import Menu, { MenuOption } from 'src/components/menu';

import { DEFAULT_NUMBER_OF_DAYS } from 'src/store/slices/candidate-availability-options';

import { Theme } from 'src/theme';

const DAYS_MENU_OPTION: MenuOption[] = [
  { id: DEFAULT_NUMBER_OF_DAYS, value: 'No minimum needed' },
  { id: 1, value: '1 day' },
  { id: 2, value: '2 days' },
  { id: 3, value: '3 days' },
  { id: 4, value: '4 days' },
  { id: 5, value: '5 days' },
  { id: 6, value: '6 days' },
  { id: 7, value: '7 days' },
  { id: 8, value: '8 days' },
  { id: 9, value: '9 days' },
  { id: 10, value: '10 days' },
  { id: 11, value: '11 days' },
  { id: 12, value: '12 days' },
  { id: 13, value: '13 days' },
  { id: 14, value: '14 days' },
];

export type DayCount = -1 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export type DayCountPickerProps = {
  additionalOptions?: MenuOption[];
  days: DayCount;
  onChange: (value: DayCount) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: '6px',
      fontWeight: theme.typography.fontWeightRegular,
      minWidth: '200px',
      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  })
);

const DayCountPicker = ({ additionalOptions, days, onChange }: DayCountPickerProps): JSX.Element => {
  const classes = useStyles();
  const [showDaysMenu, setShowDaysMenu] = useState(false);
  const daysButtonRef = useRef<HTMLButtonElement>(null);

  const daysString = [...(additionalOptions ?? []), ...DAYS_MENU_OPTION].find((option) => option.id === days)?.value;

  const handleShowDaysMenu = () => setShowDaysMenu(true);
  const handleHideDaysMenu = () => setShowDaysMenu(false);
  const handleSelectDays = (option: MenuOption) => {
    if (!option.id || typeof option.id !== 'number') return;
    onChange(option.id as DayCount);
    handleHideDaysMenu();
  };

  return (
    <>
      <Button
        dataTestId="day-count-picker"
        variant="outlined"
        className={classes.button}
        label={daysString}
        ref={daysButtonRef}
        endIcon={<TriangleDownIcon />}
        onClick={handleShowDaysMenu}
      />
      {showDaysMenu && (
        <Menu
          id="select-days-menu"
          anchorEl={daysButtonRef.current}
          open={showDaysMenu}
          options={[...(additionalOptions ?? []), ...DAYS_MENU_OPTION].map((option) => {
            if (option.id === days) return { ...option, selected: true };
            return option;
          })}
          menuWidth={daysButtonRef.current?.clientWidth ? daysButtonRef.current?.clientWidth - 16 : undefined}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleHideDaysMenu}
          onSelect={handleSelectDays}
        />
      )}
    </>
  );
};

export default DayCountPicker;
