export const orange = {
  0: '#000000',
  10: '#321200',
  20: '#532200',
  25: '#642B00',
  30: '#763400',
  35: '#883D00',
  40: '#9A4600',
  50: '#C15900',
  60: '#E27121',
  70: '#FF8D43',
  80: '#FFB68C',
  90: '#FFDBC9',
  95: '#FFEDE5',
  98: '#FFF8F6',
  99: '#FDFBFF',
  100: '#FFFFFF',
};
