import React, { FC } from 'react';

import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { MAX_LOAD_LIMIT } from 'src/constants';

export const ModuleLoadLimitOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: 'Unlimited',
    value: MAX_LOAD_LIMIT,
  },
];

type Props = {
  selectedValue: number;
  onChange: (value: number) => void;
  disabled?: boolean;
};

const LoadLimitSelect: FC<Props> = ({ selectedValue, onChange, disabled }) => {
  return (
    <Select
      id="simple-load-limit-select"
      fullWidth
      value={String(selectedValue)}
      onChange={(event: SelectChangeEvent) => onChange(Number(event.target.value))}
      disabled={disabled}
    >
      {ModuleLoadLimitOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LoadLimitSelect;
