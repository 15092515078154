import React from 'react';

import { ApolloError, gql } from '@apollo/client';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { Box, Grid, List, ListItem, ListSubheader } from '@material-ui/core';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-restricted-imports
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';

import {
  AttributeNameType,
  AttributesFilterFragment,
  useEmployeeAttributeNamesQuery,
} from 'src/generated/mloop-graphql';

import Checkbox from 'src/components/checkbox';
import Label from 'src/components/label';

import { updateJobStageInterviewSeatAttributes } from 'src/store/actions/job-stage-interview-seat';
import { AttributeMap, JobStageInterviewSeat } from 'src/store/slices/job-stage-interview-seat';

import { Theme } from 'src/theme/type';

import { useDispatch } from 'src/store';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type BaseAttributesFilterProps = {
  loading: boolean;
  filterText: string;
  error?: ApolloError;
  data: AttributesFilterFragment[];
  attributeMap?: { [key: string]: string[] };
  onAttributeUpdate: (attributeId: string, attributeValueId, selected: boolean) => void;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
type AttributesFilterProps = {
  seat: JobStageInterviewSeat;
  filterText: string;
  onNoAttributes: () => void;
  onUpdated: (seat: JobStageInterviewSeat) => void;
};

const useListItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '6px',
      cursor: 'pointer',
      padding: '8px',
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      '& .MuiCheckbox-root': {
        padding: '0',
        color: theme.palette.primary.main,
      },
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      height: '350px',
      overflowY: 'auto',
    },
    listItemBox: {
      width: '100%',
    },
    subHeader: {
      color: theme.palette.primary.main,
      fontWeight: 600,
      padding: '7px 8px',
      userSelect: 'none',
    },
    listItem: {
      padding: '0',
    },
  })
);

export const BaseAttributesFilter = ({
  loading,
  error,
  data,
  attributeMap,
  filterText,
  onAttributeUpdate,
}: BaseAttributesFilterProps): JSX.Element | null => {
  const classes = useStyles();
  const listItemClasses = useListItemStyles();

  if (loading)
    return (
      <Label variant="captions" className={classes.list}>
        Loading…
      </Label>
    );
  if (error) return <Label variant="captions">Error…</Label>;

  if (!data) return null;

  const filteredData = filterText
    ? data.map((value) => {
        const tagValues = value?.values?.filter((tagValue) => {
          return (
            value.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
            tagValue?.value.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
          );
        });

        if (tagValues?.length === 0) return null;

        const result: AttributesFilterFragment = {
          ...value,
          values: tagValues,
        };

        return result;
      })
    : data;

  return (
    <List disablePadding className={classes.list}>
      {filteredData.map((value) => {
        if (!value) return null;

        return (
          <>
            <ListSubheader key={value.id} disableSticky disableGutters>
              <Label variant="captions" className={classes.subHeader}>
                {value.name}
              </Label>
            </ListSubheader>
            {value?.values?.map((innerValue) => {
              const selectedAttributes = (attributeMap ? attributeMap[value.id] : []) as string[];
              const checked = selectedAttributes ? selectedAttributes.indexOf(innerValue?.id) !== -1 : false;
              const handleChange = () => onAttributeUpdate(value.id, innerValue?.id, !checked);

              return (
                <ListItem key={innerValue?.id} disableGutters classes={listItemClasses}>
                  <Box className={classes.listItemBox} onClick={handleChange}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid spacing={1} container alignItems="center">
                        <Grid item>
                          <Checkbox checked={checked} />
                        </Grid>
                        <Grid item>
                          <Label variant="body" className={classes.listItem}>
                            {innerValue?.value}
                          </Label>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </ListItem>
              );
            })}
          </>
        );
      })}
    </List>
  );
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line react/no-multi-comp
const AttributesFilter = ({ seat, filterText, onNoAttributes, onUpdated }: AttributesFilterProps): JSX.Element => {
  const dispatch = useDispatch();

  const {
    loading: attributesLoading,
    error: attributesError,
    data: attributesData,
  } = useEmployeeAttributeNamesQuery({
    variables: {
      input: {
        types: [...Object.values(AttributeNameType)], // fetching all attribute types
      },
    },
    fetchPolicy: 'network-only', // force this to fetch from the backend each time in case attributes are added while in the scheduling flow
  });

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line max-params
  const handleAttributeUpdate = (attributeNameId: string, attributeValueId, selected: boolean) => {
    // Delete the dispatch once we move to the prop based version of interview plan.
    dispatch(updateJobStageInterviewSeatAttributes(seat.id, attributeNameId, attributeValueId, selected));

    const updatedSeat = cloneDeep(seat);
    const attributeMap: AttributeMap = {};

    // This deepCopy is needed because the attributes we get from backend are immutable
    Object.keys(seat.attributeMap ?? {}).forEach((key) => {
      if (seat.attributeMap) {
        attributeMap[key] = [...(seat.attributeMap[key] || [])];
      }
    });

    if (selected) {
      if (!attributeMap[attributeNameId]) {
        attributeMap[attributeNameId] = [];
      }
      attributeMap[attributeNameId].push(attributeValueId);
    } else {
      attributeMap[attributeNameId] = attributeMap[attributeNameId].filter((id) => id !== attributeValueId);

      if (attributeMap[attributeNameId].length === 0) {
        delete attributeMap[attributeNameId];
      }
    }
    updatedSeat.attributeMap = attributeMap;
    delete updatedSeat.selectedTrainedInterviewerIds;
    delete updatedSeat.selectedShadowInterviewerIds;
    delete updatedSeat.selectedReverseShadowInterviewerIds;
    onUpdated(updatedSeat);
  };

  if (
    attributesData &&
    (!attributesData.employeeAttributeNames?.items || attributesData.employeeAttributeNames.items.length === 0)
  ) {
    onNoAttributes();
  }

  return (
    <BaseAttributesFilter
      loading={attributesLoading && !attributesData}
      filterText={filterText}
      error={attributesError}
      data={attributesData?.employeeAttributeNames?.items as AttributesFilterFragment[]}
      attributeMap={seat.attributeMap}
      onAttributeUpdate={handleAttributeUpdate}
    />
  );
};

AttributesFilter.fragments = {
  employeeAttributeNames: gql`
    fragment AttributesFilter on EmployeeAttributeName {
      id
      name
      type
      values {
        id
        value
      }
    }
  `,
};

export const EmployeeAttributeNamesQuery = gql`
  ${AttributesFilter.fragments.employeeAttributeNames}
  query employeeAttributeNames($input: EmployeeAttributeNamesInput!) {
    employeeAttributeNames(input: $input) {
      items {
        ...AttributesFilter
      }
    }
  }
`;

export default AttributesFilter;
