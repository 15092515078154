import React, { createContext, useCallback, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { encode } from 'querystring';

import { DecodedToken } from 'src/contexts/auth0/types';

import useHistory from 'src/hooks/useHistory';
import { LocationState } from 'src/hooks/useLoginToParams';
import { useQueryStringParam } from 'src/hooks/useQueryStringParam';

import LoadingBackdrop from 'src/shared/components/base/screenLoaders/LoadingBackdrop';
import SplashScreen from 'src/shared/components/base/screenLoaders/SplashScreen';

import { getAuth0Client } from './utils';

interface UserInfoBase {
  userId: string;
  userName: string;
  userEmail: string;

  employeeId?: string;
  employeeName?: string;
  employeeEmail?: string;
  employeeImageUrl?: string;

  orgId?: string;
  orgName?: string;
  orgCreatedAt?: number;
}

export interface UserInfo extends UserInfoBase {
  impersonatingUserInfo?: UserInfoBase;
}

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  isLoggingOut: boolean;
  userInfo: UserInfo | null;
  jwt: string | null;
}

export interface AuthContextValue extends AuthState {
  method: 'Auth0';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loginWithPopup: (options?: any) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loginWithRedirect: (options?: any) => Promise<void>;
  logout: () => void;
}

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    userInfo: UserInfo | null;
    jwt: string | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    userInfo: UserInfo;
    jwt: string | null;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action = InitialiseAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isLoggingOut: false,
  userInfo: null,
  jwt: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, userInfo, jwt } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        userInfo,
        jwt,
      };
    }
    case 'LOGIN': {
      const { userInfo, jwt } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        userInfo,
        jwt,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        // let auth0 redirect instead of setting isAuthenticated to false
        isLoggingOut: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  loginWithRedirect: () => Promise.resolve(),
  logout: () => {},
});

const getUserInfo = (user): UserInfo => {
  const tokenUserInfo = (user['https://modernloop.io/user_info'] ||
    {}) as DecodedToken['https://modernloop.io/user_info'];

  const userInfo: UserInfo = {
    userId: tokenUserInfo.user_id,
    userEmail: tokenUserInfo.user_email,
    userName: tokenUserInfo.user_name,
    employeeId: tokenUserInfo.employee_id,
    employeeEmail: tokenUserInfo.employee_email,
    employeeName: tokenUserInfo.employee_name,
    employeeImageUrl: tokenUserInfo.employee_image_url,
    orgId: tokenUserInfo.org_id,
    orgName: tokenUserInfo.org_name,
  };
  const impersonatingUserInfo = tokenUserInfo.impersonating_user_info;
  if (impersonatingUserInfo) {
    userInfo.impersonatingUserInfo = {
      userId: impersonatingUserInfo.user_id,
      userEmail: impersonatingUserInfo.user_email,
      userName: impersonatingUserInfo.user_name,
      employeeId: impersonatingUserInfo.employee_id,
      employeeEmail: impersonatingUserInfo.employee_email,
      employeeName: impersonatingUserInfo.employee_name,
      employeeImageUrl: impersonatingUserInfo.employee_image_url,
      orgId: impersonatingUserInfo.org_id,
      orgName: impersonatingUserInfo.org_name,
    };
  }
  return userInfo;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line modernloop/restrict-props-name.cjs
export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const location = useLocation<LocationState>();
  const error = useQueryStringParam('error');
  const errorDescription = useQueryStringParam('error_description');
  const history = useHistory();

  /**
   * Opens popup to login with Auth0 Universal Login and handles post login submission and redirect
   * @param options PopupLoginOptions passed to auth0 login with Popup
   * @returns void
   */
  const loginWithPopup = async (options) => {
    const auth0Client = await getAuth0Client();
    if (!auth0Client) return;
    await auth0Client.loginWithPopup({ ...options, prompt: 'login' });

    const isAuthenticated = await auth0Client.isAuthenticated();
    if (isAuthenticated) {
      await auth0Client.getTokenSilently();
      const user = await auth0Client.getUser();
      const claims = await auth0Client.getIdTokenClaims();
      dispatch({
        type: 'LOGIN',
        payload: {
          userInfo: getUserInfo(user),
          // eslint-disable-next-line
          jwt: claims?.__raw ?? '',
        },
      });
      if (location.state && location.state.redirectUrl) {
        history.replace(location.state.redirectUrl);
      }
    }
  };

  /**
   * Redirects user to Universal Login Page, sets redirect url
   * @param options PopupLoginOptions passed to auth0 login with Popup
   * @returns void
   */
  const loginWithRedirect = async (options) => {
    const auth0Client = await getAuth0Client();
    if (!auth0Client) return;
    await auth0Client.loginWithRedirect({
      appState: { returnTo: location?.state?.redirectUrl || location.pathname },
      ...options,
      prompt: 'login',
    });
  };

  const logout = useCallback(async () => {
    const auth0Client = await getAuth0Client();
    if (!auth0Client) return;
    auth0Client.logout({ returnTo: window.location.origin });
    // Attempt to logout of Zendesk if it's been added

    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // this use effect runs the initialization process once per app load
  useEffect(() => {
    const initialise = async () => {
      try {
        const auth0Client = await getAuth0Client();

        /**
         * Handles callback when using loginWithRedirect.
         * Further redirects users if there is a returnTo field in appState.
         */
        if (matchPath(location.pathname, { path: '/callback', exact: false })) {
          if (error) {
            const qs = encode({ error, error_description: errorDescription });
            history.push(`/login?${qs}`);
          }
          const { appState } = await auth0Client.handleRedirectCallback();
          if (appState && appState.returnTo) {
            history.push(appState.returnTo);
          }
        }

        /*
         * Handles logout and redirects to login page
         */
        if (matchPath(location.pathname, { path: '/logout', exact: false })) {
          logout();
        }

        await auth0Client.getTokenSilently();
        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          let user = await auth0Client.getUser();
          let userInfo = getUserInfo(user);
          if (!userInfo.employeeId) {
            // If there is no employee id, do a full refresh of the user/jwt
            // This is especially important for the transition between
            // before google connect to after google is connected.
            // Otherwise the user would have to do a full logout to refresh the jwt
            // and be able to use the product.
            await auth0Client.getTokenSilently({ ignoreCache: true });
            user = await auth0Client.getUser();
            userInfo = getUserInfo(user);
          }
          const claims = await auth0Client.getIdTokenClaims();
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              userInfo: getUserInfo(user),
              // eslint-disable-next-line
              jwt: claims?.__raw || null,
            },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              userInfo: null,
              jwt: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            userInfo: null,
            jwt: null,
          },
        });
      }
    };

    initialise();
  }, [location.pathname, history, logout, error, errorDescription]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginWithPopup,
        method: 'Auth0',
        loginWithRedirect,
        logout,
      }}
    >
      <LoadingBackdrop open={state.isLoggingOut} />
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
