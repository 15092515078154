/* eslint-disable max-lines */
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { Alert, Button, IconButton, Stack, TextField, Tooltip, inputBaseClasses } from '@mui/material';
import { addMinutes, isAfter, parseISO } from 'date-fns';
import { isEmpty, uniq } from 'lodash';

import {
  AtsInterviewDefinition,
  DateTimeRangeInput,
  InterviewCardHeader_JobFragment,
  InterviewCardHeader_JobStageInterviewFragment,
  InterviewCardHeader_JobStageInterviewGroupsFragment,
  InterviewCardHeader_JobStageInterviewsFragment,
} from 'src/generated/mloop-graphql';

import DurationTimePicker from 'src/components/date-time-picker/DurationTimePicker';
import {
  DurationFilledIcon,
  OrderLockIcon,
  OrderShuffleIcon,
  ScorecardFilledIcon,
  ScorecardNoneIcon,
  TrashIcon,
} from 'src/components/icons';

import { getAtsInterviewDefinitionFields } from 'src/hooks/atsService/util';
import useDisplayTimezone from 'src/hooks/useDisplayTimezone';

import { assertHexColor } from 'src/types/color';

import { formatRange, startOfDay } from 'src/utils/dateUtils';

// eslint-disable-next-line modernloop/restrict-imports.cjs
import AtsScorecardSelect from 'src/views-new/Scorecards/AtsScorecardSelect';
// eslint-disable-next-line modernloop/restrict-imports.cjs
import TimezoneSelect from 'src/views-new/TimezoneSelect';

import { DEFAULT_INTERVIEW_NAME } from 'src/strings';

import { DURATION_OPTIONS } from './Break';
import CodingLink from './CodingLink';
import MoreMenuOptions, { InterviewMenuOptions } from './MoreMenuOptions';
import useWarningForStartAt from './useWarningForStartAt';

export enum InterviewOptions {
  EDIT_DETAILS = 'edit_details',
  MOVE_UP = 'move_up',
  MOVE_DOWN = 'move_down',
  GROUP_WITH_ABOVE = 'group_with_above',
  GROUP_WITH_BELOW = 'group_with_below',
}

type Props = {
  index: number; // This is index of slot in group
  schedulable: boolean;
  candidateAvailabilities?: DateTimeRangeInput[];
  onUpdated: (jobStageInterview: InterviewCardHeader_JobStageInterviewFragment) => void;
  onDelete: (jobStageInterviewId: string) => void;
  onMenuSelect: (option: InterviewMenuOptions) => void;
};

type Fragments = {
  job: InterviewCardHeader_JobFragment;
  jobStageInterview: InterviewCardHeader_JobStageInterviewFragment;
  jobStageInterviews: InterviewCardHeader_JobStageInterviewsFragment[];
  jobStageInterviewGroups: InterviewCardHeader_JobStageInterviewGroupsFragment[];
};

const InterviewCardHeader: FCWithFragments<Fragments, Props> = ({
  job,
  jobStageInterview,
  jobStageInterviews,
  jobStageInterviewGroups,
  index,
  schedulable,
  candidateAvailabilities,
  onUpdated,
  onDelete,
  onMenuSelect,
}): JSX.Element => {
  const displayTimezone = useDisplayTimezone();

  let jobStageInterviewEndTime: Date | null = null;
  jobStageInterviews.forEach((interview) => {
    if (!interview.forcedStartAt || !interview.duration) return;

    let { duration } = interview;
    if (interview.useAtsDuration) {
      duration =
        getAtsInterviewDefinitionFields(interview?.atsInterviewDefinition?.atsFields, 'estimatedMinutes') ?? duration;
    }

    if (!jobStageInterviewEndTime) {
      jobStageInterviewEndTime = parseISO(interview.forcedStartAt);
    }

    const interviewEndTime = addMinutes(parseISO(interview.forcedStartAt), duration);

    if (isAfter(interviewEndTime, jobStageInterviewEndTime)) {
      jobStageInterviewEndTime = interviewEndTime;
    }
  });

  const startAtWarning = useWarningForStartAt(
    { jobStageInterviews },
    { jobStageInterviewId: jobStageInterview.id, candidateAvailabilities }
  );

  const [hasNameChanged, setHasNameChanged] = useState(false);
  const [showDurationTimePicker, setShowDurationTimePicker] = useState(false);

  const name = useMemo(() => {
    if (jobStageInterview.useAtsName && jobStageInterview.atsInterviewDefinition?.name) {
      return jobStageInterview.atsInterviewDefinition.name;
    }
    return jobStageInterview.name;
  }, [jobStageInterview.atsInterviewDefinition?.name, jobStageInterview.name, jobStageInterview.useAtsName]);

  const uniqueCandidateAvailabilityDays = useMemo(() => {
    return uniq(
      candidateAvailabilities?.map((availability) => {
        return startOfDay(availability.startAt, displayTimezone).getTime();
      })
    );
  }, [candidateAvailabilities, displayTimezone]);

  const durationOptionsMenu = useRef<HTMLButtonElement>(null);

  const interviewSlotDuration = useMemo(() => {
    let duration = jobStageInterview.duration || 45 * 60 * 1000;
    if (jobStageInterview.useAtsDuration) {
      duration =
        getAtsInterviewDefinitionFields(jobStageInterview?.atsInterviewDefinition?.atsFields, 'estimatedMinutes') ??
        duration;
    }
    return duration;
  }, [jobStageInterview]);

  let durationText =
    DURATION_OPTIONS.find((option) => option.id === interviewSlotDuration)?.value ||
    `${interviewSlotDuration} ${interviewSlotDuration === 1 ? 'min' : 'mins'}`;

  if (jobStageInterview.forcedStartAt) {
    const startTime = parseISO(jobStageInterview.forcedStartAt);
    const endTime = addMinutes(startTime, interviewSlotDuration);
    durationText = `${formatRange(startTime, endTime, displayTimezone, 'MMM d', 'h:mm aaa')} · ${durationText}`;
  }

  const handlePlanOrderClick = () => {
    onUpdated({ ...jobStageInterview, isLockedOrder: !jobStageInterview.isLockedOrder });
  };

  const handleInterviewNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setHasNameChanged(true);
    onUpdated({
      ...jobStageInterview,
      name: event.target.value,
      useAtsName: event.target.value === jobStageInterview.atsInterviewDefinition?.name,
    });
  };

  const handleRemoveJobStageInterview = () => {
    onDelete(jobStageInterview.id);
  };

  const handleDurationTimepickerClose = () => {
    setShowDurationTimePicker(false);
  };

  const handleDurationTimeChange = useCallback(
    (duration: number, dateTime: Date | null) => {
      onUpdated({
        ...jobStageInterview,
        duration,
        forcedStartAt: dateTime?.toISOString() || jobStageInterview.forcedStartAt,
        useAtsDuration: false,
      });
    },
    [jobStageInterview, onUpdated]
  );

  const handleClearStartAt = () => {
    onUpdated({ ...jobStageInterview, forcedStartAt: undefined });
  };

  const handleAtsInterviewDefinitionSelect = (atsInterviewDefinition: AtsInterviewDefinition) => {
    let { name: interviewName } = jobStageInterview;

    if (!hasNameChanged) {
      interviewName = atsInterviewDefinition.name ?? DEFAULT_INTERVIEW_NAME;
    }

    onUpdated({
      ...jobStageInterview,
      atsInterviewDefinition: {
        atsId: atsInterviewDefinition.atsId,
        name: atsInterviewDefinition.name,
        atsJobId: atsInterviewDefinition.atsJobId,
        atsFields: atsInterviewDefinition.atsFields,
      },
      name: interviewName,
      useAtsName: true,
      duration:
        getAtsInterviewDefinitionFields(atsInterviewDefinition?.atsFields, 'estimatedMinutes') ||
        jobStageInterview.duration,
    });
  };

  const getStartAtForDurationTimePicker = (): Date => {
    if (jobStageInterviewEndTime) return jobStageInterviewEndTime;

    const availabilityStartTimes: Date[] =
      candidateAvailabilities && !isEmpty(candidateAvailabilities)
        ? candidateAvailabilities?.map((value) => parseISO(value.startAt)).sort((a, b) => a.getTime() - b.getTime())
        : [new Date()];

    const now = availabilityStartTimes[0];
    now.setSeconds(0);
    return now;
  };

  const getNoScorecardLabel = () => {
    return (
      <Tooltip title="No scorecard">
        <Button data-testid="interview-card-header-no-scorecard-button" size="small" startIcon={<ScorecardNoneIcon />}>
          No scorecard
        </Button>
      </Tooltip>
    );
  };

  const getLabel = () => {
    if (!jobStageInterview?.atsInterviewDefinition?.atsId) {
      return getNoScorecardLabel();
    }

    return (
      <Tooltip title={jobStageInterview?.atsInterviewDefinition?.name || 'No scorecard'}>
        <Button
          data-testid="interview-card-header-scorecard-button"
          size="small"
          startIcon={<ScorecardFilledIcon color={assertHexColor('#915208')} />}
        >
          {jobStageInterview?.atsInterviewDefinition?.name || 'No scorecard'}
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1} flexWrap="nowrap">
          <Tooltip title={jobStageInterview.isLockedOrder ? 'Locked position' : 'Shuffle position'}>
            <IconButton onClick={handlePlanOrderClick} data-testid="interview-card-header-locked-order-button">
              {jobStageInterview.isLockedOrder && <OrderLockIcon />}
              {!jobStageInterview.isLockedOrder && <OrderShuffleIcon />}
            </IconButton>
          </Tooltip>
          <TextField
            data-testid="interview-card-header-name-textfield"
            defaultValue={name}
            fullWidth
            inputProps={{ style: { fontWeight: 600 } }}
            sx={{
              '& fieldset': { border: 'none' },
              [`& .${inputBaseClasses.root}`]: {
                backgroundColor: 'transparent',
                border: '1px solid transparent',
                '&:hover': { border: (theme) => `1px solid ${theme.palette.border}` },
              },
            }}
            onChange={handleInterviewNameChange}
          />
          <MoreMenuOptions
            index={index}
            jobStageInterview={jobStageInterview}
            jobStageInterviewGroups={jobStageInterviewGroups}
            onMenuSelect={onMenuSelect}
          />
          <Tooltip title="Delete interview">
            <IconButton onClick={handleRemoveJobStageInterview} data-testid="interview-card-header-delete-button">
              <TrashIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} flexWrap="nowrap">
          <Tooltip title={`Duration: ${durationText}`}>
            <Button
              ref={durationOptionsMenu}
              data-testid="interview-card-header-duration-button"
              size="small"
              startIcon={<DurationFilledIcon color={assertHexColor('#FA7800')} />}
              onClick={() => setShowDurationTimePicker(true)}
            >
              {durationText}
            </Button>
          </Tooltip>

          <AtsScorecardSelect
            atsJobId={job?.atsId}
            selectScorecard={handleAtsInterviewDefinitionSelect}
            getLabel={getLabel}
          />
          <CodingLink
            jobStageInterview={jobStageInterview}
            jobStageInterviewId={jobStageInterview.id}
            onUpdated={onUpdated}
          />
        </Stack>
        {startAtWarning && <Alert severity="warning">{startAtWarning}</Alert>}
      </Stack>

      {showDurationTimePicker && (
        <DurationTimePicker
          anchorEl={durationOptionsMenu}
          duration={interviewSlotDuration}
          utcTime={
            jobStageInterview.forcedStartAt
              ? parseISO(jobStageInterview.forcedStartAt)
              : getStartAtForDurationTimePicker()
          }
          timezone={displayTimezone}
          showTimePicker={Boolean(jobStageInterview.forcedStartAt)}
          utcAvailability={uniqueCandidateAvailabilityDays}
          maxDuration={720}
          timezoneSelectComponent={
            <TimezoneSelect openOnFocus fullWidth shouldUpdateDisplayTimezone value={displayTimezone} />
          }
          hideTimePicker={!schedulable}
          onChange={handleDurationTimeChange}
          clearTime={handleClearStartAt}
          onClose={handleDurationTimepickerClose}
        />
      )}
    </>
  );
};

InterviewCardHeader.fragments = {
  job: gql`
    fragment InterviewCardHeader_job on Job {
      id
      atsId
    }
  `,
  jobStageInterview: gql`
    ${CodingLink.fragments.jobStageInterview}
    fragment InterviewCardHeader_jobStageInterview on JobStageInterview {
      id
      name
      isLockedOrder
      useAtsDuration
      useAtsName
      duration
      forcedStartAt @client
      atsInterviewDefinition {
        atsId
        name
        atsJobId
        atsFields {
          ... on GreenhouseInterviewDefinition {
            estimatedMinutes
            index
          }
        }
      }
      ...CodingLink_jobStageInterview
      ...MoreMenuOptions_jobStageInterview
    }
  `,
  jobStageInterviews: gql`
    ${useWarningForStartAt.fragments.jobStageInterviews}
    fragment InterviewCardHeader_jobStageInterviews on JobStageInterview {
      id
      duration
      forcedStartAt @client
      useAtsDuration
      atsInterviewDefinition {
        atsId
        atsFields {
          ... on GreenhouseInterviewDefinition {
            estimatedMinutes
            index
          }
        }
      }
      ...useWarningForStartAt_jobStageInterviews
    }
  `,
  jobStageInterviewGroups: gql`
    ${MoreMenuOptions.fragments.jobStageInterviewGroups}
    fragment InterviewCardHeader_jobStageInterviewGroups on JobStageInterviewGroup {
      id
      ...MoreMenuOptions_jobStageInterviewGroups
    }
  `,
};

export default InterviewCardHeader;
