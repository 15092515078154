import React, { useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { useFlag } from '@modernloop/shared/feature-flag';
import { EyeCrossedIcon, EyeIcon } from '@modernloop/shared/icons';
import { IconButton, Tooltip } from '@mui/material';

import {
  InterviewType,
  MoreMenuOptions_JobStageInterviewFragment,
  MoreMenuOptions_JobStageInterviewGroupsFragment,
} from 'src/generated/mloop-graphql';

import { MoreIcon } from 'src/components/icons';
import Menu, { MenuOption } from 'src/components/menu';

export enum InterviewMenuOptions {
  MOVE_UP = 'move_up',
  MOVE_DOWN = 'move_down',
  GROUP_WITH_ABOVE = 'group_with_above',
  GROUP_WITH_BELOW = 'group_with_below',
  HIDE_FROM_CANDIDATE = 'hide_from_candidate',
  SHOW_TO_CANDIDATE = 'show_to_candidate',
}

type Props = {
  index: number;
  onMenuSelect: (option: InterviewMenuOptions) => void;
};

type Fragments = {
  jobStageInterview: MoreMenuOptions_JobStageInterviewFragment;
  jobStageInterviewGroups: MoreMenuOptions_JobStageInterviewGroupsFragment[];
};

const MoreMenuOptions: FCWithFragments<Fragments, Props> = ({
  index,
  jobStageInterview,
  jobStageInterviewGroups,
  onMenuSelect,
}): JSX.Element => {
  const breakImprovementsEnabled = useFlag('org_break_improvements');

  const moreOptionsAnchorEl = useRef<HTMLButtonElement>(null);
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const group = jobStageInterviewGroups.find((g) =>
    g.jobStageInterviews?.some((interview) => interview.id === jobStageInterview.id)
  );
  const firstGroup = jobStageInterviewGroups.length > 0 ? jobStageInterviewGroups[0] : undefined;
  const lastGroup =
    jobStageInterviewGroups.length > 0 ? jobStageInterviewGroups[jobStageInterviewGroups.length - 1] : undefined;
  const groupIndex = jobStageInterviewGroups.findIndex((g) => g.id === group?.id);

  const options = useMemo(() => {
    /**
     * Default header as options
     */
    const menuOptions: MenuOption[] = [];

    /**
     * Show hide / show interview to candidate option
     */

    if (jobStageInterview.interviewType === InterviewType.Interview) {
      menuOptions.push({ isSubheader: true, value: 'Options', id: 'more-menu-options-divider-1' });
      if (jobStageInterview.isHiddenFromCandidate) {
        menuOptions.push({
          id: InterviewMenuOptions.SHOW_TO_CANDIDATE,
          value: 'Show to candidate',
          icon: <EyeIcon fontSize="small" />,
        });
      } else {
        menuOptions.push({
          id: InterviewMenuOptions.HIDE_FROM_CANDIDATE,
          value: 'Hide from candidate',
          icon: <EyeCrossedIcon fontSize="small" />,
        });
      }
      menuOptions.push({ isDivider: true, id: 'more-menu-options-divider-2' });
    }

    /**
     * Divider & header for organize options
     */
    menuOptions.push({ isSubheader: true, value: 'Organize', id: 'more-menu-options-subheader-organize' });

    /**
     * Disabled if slot is:
     * 1. First item in a group with more than one interview
     * 2. First item of first group.
     */
    menuOptions.push({
      id: InterviewMenuOptions.MOVE_UP,
      value: 'Move up',
      disabled: Boolean(
        (group?.jobStageInterviews &&
          group.jobStageInterviews.length > (breakImprovementsEnabled ? 2 : 1) &&
          index === 0) ||
          (firstGroup?.jobStageInterviews?.length &&
            firstGroup.jobStageInterviews[0].id === jobStageInterview.id &&
            groupIndex === 0)
      ),
    });

    /**
     * Disabled if slot is:
     * If it is the last item in group with more than one interview.
     * If it is the last item in last group.
     */
    menuOptions.push({
      id: InterviewMenuOptions.MOVE_DOWN,
      value: 'Move down',
      disabled: Boolean(
        (group?.jobStageInterviews &&
          group.jobStageInterviews.length > (breakImprovementsEnabled ? 2 : 1) &&
          group.jobStageInterviews[group.jobStageInterviews.length - (breakImprovementsEnabled ? 2 : 1)].id ===
            jobStageInterview.id) ||
          (lastGroup &&
            lastGroup.jobStageInterviews &&
            lastGroup.jobStageInterviews.length > (breakImprovementsEnabled ? 2 : 1) &&
            lastGroup.jobStageInterviews[lastGroup.jobStageInterviews.length - (breakImprovementsEnabled ? 2 : 1)]
              .id === jobStageInterview.id)
      ),
    });

    /**
     * Show only if it is the only item in group
     */
    if (group?.jobStageInterviews?.length === (breakImprovementsEnabled ? 2 : 1)) {
      /**
       * Disabled for first group
       */
      menuOptions.push({
        id: InterviewMenuOptions.GROUP_WITH_ABOVE,
        value: 'Group with above',
        disabled: firstGroup && firstGroup.id === group.id,
      });
    }

    /**
     * Show only if it is the only item in group
     */
    if (group?.jobStageInterviews?.length === (breakImprovementsEnabled ? 2 : 1)) {
      /**
       * Disabled for last group
       */
      menuOptions.push({
        id: InterviewMenuOptions.GROUP_WITH_BELOW,
        value: 'Group with below',
        disabled: lastGroup && lastGroup.id === group.id,
      });
    }

    return menuOptions;
  }, [
    jobStageInterview.interviewType,
    jobStageInterview.id,
    jobStageInterview.isHiddenFromCandidate,
    group?.jobStageInterviews,
    group?.id,
    breakImprovementsEnabled,
    index,
    firstGroup,
    groupIndex,
    lastGroup,
  ]);

  const toggleMoreOptionsMenu = () => setShowMoreOptions(!showMoreOptions);

  const handleSelect = (option: MenuOption) => {
    if (!option.id) return;

    toggleMoreOptionsMenu();
    onMenuSelect(option.id as InterviewMenuOptions);
  };

  return (
    <>
      <Tooltip title="More options">
        <IconButton ref={moreOptionsAnchorEl} onClick={toggleMoreOptionsMenu}>
          <MoreIcon fontSize={20} />
        </IconButton>
      </Tooltip>
      <Menu
        id="interview-menu-options"
        options={options}
        anchorEl={moreOptionsAnchorEl.current}
        open={showMoreOptions}
        disableScrollLock
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onSelect={handleSelect}
        onClose={toggleMoreOptionsMenu}
      />
    </>
  );
};

MoreMenuOptions.fragments = {
  jobStageInterview: gql`
    fragment MoreMenuOptions_jobStageInterview on JobStageInterview {
      id
      interviewType
      jobStageInterviewGroupId
      isHiddenFromCandidate
    }
  `,
  jobStageInterviewGroups: gql`
    fragment MoreMenuOptions_jobStageInterviewGroups on JobStageInterviewGroup {
      id
      jobStageInterviews {
        id
      }
    }
  `,
};

export default MoreMenuOptions;
