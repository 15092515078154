import { useMemo } from 'react';

import { gql } from '@apollo/client';
import { uniq } from 'lodash';

import { CompanyHolidaysFragment, useCompanyHolidaysQuery } from 'src/generated/mloop-graphql';

import { PAGE_SIZE } from 'src/constants';

export type CompanyHolidaysType = CompanyHolidaysFragment[];

export const getCompanyHolidayCountryNames = (holidays: CompanyHolidaysType): string => {
  const hasAllCountries = holidays.find((holiday) => holiday?.countries?.length === 0);
  if (hasAllCountries) {
    return 'All';
  }
  const allCountries = holidays.flatMap((item) => item?.countries?.map((country) => country?.value)) || [];
  return uniq(allCountries).join(', ') || '';
};

const useCompanyHolidays = (): CompanyHolidaysType => {
  const { data } = useCompanyHolidaysQuery({
    variables: {
      input: {
        pageInput: {
          limit: PAGE_SIZE,
        },
      },
    },
  });

  return useMemo(() => {
    if (data?.companyHolidays?.items?.length) {
      return data.companyHolidays.items.map((item) => {
        return {
          ...item,
          start: item.startAt,
          end: item.endAt,
        };
      });
    }

    return [];
  }, [data]);
};

useCompanyHolidays.fragments = {
  companyHolidays: gql`
    fragment CompanyHolidays on CompanyHoliday {
      id
      start: startAt
      end: endAt
      countries {
        id
        value
      }
    }
  `,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CompanyDaysOffQuerey = gql`
  ${useCompanyHolidays.fragments.companyHolidays}
  query AllCompanyHolidays($input: CompanyHolidaysInput!) {
    companyHolidays(input: $input) {
      items {
        ...CompanyHolidays
      }
    }
  }
`;

export default useCompanyHolidays;
