import { ThemeOptions } from '@mui/material';

/**
 * View Paper props and classes at https://mui.com/material-ui/api/paper/#css
 */
export const paper: ThemeOptions['components'] = {
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      rounded: ({ theme }) => ({
        borderRadius: theme.spacing(1.5),
      }),
      outlined: ({ theme }) => ({
        borderColor: theme.palette.borderState.default,
      }),
      elevation1: ({ theme }) => ({
        backgroundColor: theme.palette.background.elevation.one,
        boxShadow:
          '0px 3px 3px -2px rgba(0,0,0,0.1), 0px 3px 4px 0px rgba(0,0,0,0.07), 0px 1px 8px 0px rgba(0,0,0,0.06)',
      }),
      elevation2: ({ theme }) => ({
        backgroundColor: theme.palette.background.elevation.two,
        boxShadow:
          '0px 3px 5px -1px rgba(0,0,0,0.1), 0px 6px 10px 0px rgba(0,0,0,0.07), 0px 1px 18px 0px rgba(0,0,0,0.06)',
      }),
      elevation3: ({ theme }) => ({
        backgroundColor: theme.palette.background.elevation.three,
        boxShadow:
          '0px 7px 8px -4px rgba(0,0,0,0.1), 0px 12px 17px 2px rgba(0,0,0,0.07), 0px 5px 22px 4px rgba(0,0,0,0.06)',
      }),
      elevation4: ({ theme }) => ({
        backgroundColor: theme.palette.background.elevation.four,
        boxShadow:
          '0px 9px 11px -5px rgba(0,0,0,0.1), 0px 18px 28px 2px rgba(0,0,0,0.07), 0px 7px 34px 6px rgba(0,0,0,0.06)',
      }),
      elevation5: ({ theme }) => ({
        backgroundColor: theme.palette.background.elevation.five,
        boxShadow:
          '0px 11px 15px -7px rgba(0,0,0,0.1), 0px 24px 38px 3px rgba(0,0,0,0.07), 0px 9px 46px 8px rgba(0,0,0,0.06)',
      }),
    },
  },
};
