import { CodeLinkType, InterviewTokensQueryVariables, InterviewerRole, RenderType } from 'src/generated/mloop-graphql';

import { CodingUrlBySlotId } from 'src/store/slices/schedule-communications';

import { InterviewEvent, InterviewSchedule } from 'src/utils/api/getScheduleOptions';

import { EMPTY_UUID } from 'src/constants';

import {
  MeetingType,
  getCodingLinkInput,
  getDaysInput,
  getEventsInput,
  getInternalInterviewScheduleInput,
  getInterviewNameInput,
  getInterviewerNamesInput,
  getKitLinksInput,
  getScheduleInput,
  getScheduleInterviewerNamesInput,
  getScheduleStartTimeInput,
  getVideoMeetingLinkInput,
  getZoomInput,
} from './input';

export type Input = {
  renderType?: RenderType;
  applicationId: string;
  candidateId: string;
  schedule: InterviewSchedule;
  timezone?: string;
  codingUrlByInterviewEvent?: CodingUrlBySlotId;
  isFetch?: boolean;
  skipMeetingLinkWithSchedule?: boolean;
  slotId?: string;
  candidateTimezone?: string; // Expliclity pass for INTERVIEW_SCHEDULE  token to be computed in candidate timezone
} & MeetingType;

/**
 * Returns the inputs needed for an interview event request
 * @param requestId - The ID of the request.
 * @param params - The input parameters for the request.
 * @param event - The interview event.
 * @returns The inputs for the interview event request, or null if the request cannot be made.
 */
const getRequestInputsForInterviewEvent = (
  requestId: string,
  params: Input,
  event: InterviewEvent // TODO: Fix this the next time the file is edited.
): // eslint-disable-next-line max-params
InterviewTokensQueryVariables | null => {
  const {
    renderType,
    applicationId,
    candidateId,
    schedule,
    timezone,
    zoomInfo,
    zoomInfos,
    customVideoMeetingLinkUrl,
    customVideoMeetingLinkUrls,
    videoMeetingLinkUrls,
    workspaceVideoLink,
    codingUrlByInterviewEvent,
    skipMeetingLinkWithSchedule,
    candidateTimezone,
  } = params;
  const scheduleEvents = getEventsInput({
    schedule,
    zoomInfo,
    zoomInfos,
    customVideoMeetingLinkUrl,
    customVideoMeetingLinkUrls,
    videoMeetingLinkUrls,
    workspaceVideoLink,
    codingUrlByInterviewEvent,
    skipMeetingLinkWithSchedule,
  });

  if (!schedule.events?.length) return null;
  if (!event.slotId) return null;

  /** If timezone passed to the hook always honour timezone passed, if not check for interviewers in the event.
   * If only one interviewer pass employee timezone else pass candidateTimezone */
  const scheduleTokenTimezone =
    timezone ||
    (event.interviewers.length === 1 ? event?.interviewers[0]?.employee?.timezone : candidateTimezone) ||
    undefined;

  // INTERVIEW_SCHEDULE_START_TIME
  const scheduleStartTime = getScheduleStartTimeInput(schedule.events[0], scheduleTokenTimezone);

  // INTERVIEW_NAME
  const name = getInterviewNameInput(event);
  const hasName = !!name.jobStageInterviewId;

  // INTERVIEWER_NAMES
  const names = getInterviewerNamesInput(event);

  // SCHEDULE_INTERVIEWER_NAMES
  const scheduleNames = getScheduleInterviewerNamesInput(schedule.events);
  const hasNames = !!names.employeeIds?.length;

  // INTERVIEW_KIT_LINK
  const kitLink = getKitLinksInput(
    applicationId,
    candidateId,
    event.atsInterviewDefinitionId,
    event.atsScheduledInterviewId
  );

  // CODE_LINK
  const codeLink = getCodingLinkInput(event.slotId, codingUrlByInterviewEvent) || {
    url: '',
    type: CodeLinkType.None,
  };
  const hasCodeLink = !!codeLink?.url;

  // INTERVIEW_DAYS
  const days = getDaysInput(scheduleEvents, scheduleTokenTimezone);
  const hasDays = !!days.events?.length;

  // INTERNAL_INTERVIEW_SCHEDULE
  const internalInterviewSchedule = getInternalInterviewScheduleInput(scheduleEvents, candidateId);
  const hasInternalInterviewSchedule = !!internalInterviewSchedule.events?.length;

  // INTERVIEW_SCHEDULE
  const scheduleParam = getScheduleInput(scheduleEvents, candidateId, scheduleTokenTimezone, applicationId);
  const hasSchedule = !!scheduleParam.events?.length;

  // INTERVIEW_SCHEDULE_START_TIME
  const hasScheduleStartTime = !!scheduleStartTime.startAt;

  /**
   * NOTE: VIDEO_MEETING_LINK , ZOOM_MEETING_DIAL_IN_INFO  & ZOOM_MEETING_INFO are only fetched when multiple link
   */
  const videoMeetingLink = getVideoMeetingLinkInput(event.slotId, {
    zoomInfos,
    customVideoMeetingLinkUrls,
    videoMeetingLinkUrls,
  });
  const hasVideoMeetingLink =
    !!videoMeetingLink?.customVideoMeetingLinkUrl ||
    !!videoMeetingLink?.videoMeetingLink ||
    (!!videoMeetingLink?.zoomInfo?.meetingId && videoMeetingLink?.zoomInfo?.meetingId !== EMPTY_UUID);
  const zoomInput = getZoomInput(event.slotId, undefined, zoomInfos);
  const hasZoom = !!zoomInput?.meetingId && zoomInput.joinUrl !== EMPTY_UUID;

  // INTERVIEWER_TRAINING_INFO
  const trainingInfoInterviewers = event.interviewers.map((interviewer) => {
    return {
      employeeId: interviewer.employeeId,
      role: interviewer.role || InterviewerRole.Interviewer,
    };
  });

  // INTERVIEW_SCORECARD_NAME
  const hasScorecard = Boolean(event.atsInterviewDefinitionId);

  return {
    id: `${event.slotId}-${requestId}`, // ID should be unique for each slotId & request id for apollo cache to work
    uuid: event.slotId,
    renderType: renderType || RenderType.Html,
    name: {
      name: name.name,
    },
    names,
    scheduleNames,
    kitLink,
    days,
    internalInterviewSchedule,
    schedule: scheduleParam,
    scheduleStartTime,
    codeLink,
    videoMeetingLink,
    zoom: zoomInput,
    trainingInfo: { interviewers: trainingInfoInterviewers },
    hasVideoMeetingLink,
    hasCodeLink,
    hasZoom,
    hasName,
    hasNames,
    hasScorecard,
    hasDays,
    hasInternalInterviewSchedule,
    hasSchedule,
    hasScheduleStartTime,
    atsInterviewDefinitionId: event.atsInterviewDefinitionId || undefined,
  };
};

export default getRequestInputsForInterviewEvent;
