/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from 'react';

import { gql } from '@apollo/client';
import { FCWithFragments } from '@modernloop/shared/components';
import { getCurrentDateIso, isAfter, isAfterNow, isWithinInterval } from '@modernloop/shared/datetime';
import { useFlag } from '@modernloop/shared/feature-flag';
import { PlusIcon } from '@modernloop/shared/icons';
import { Button, Stack } from '@mui/material';

import {
  ApplicationStatus,
  ScheduleContentActions_ApplicationStageFragment,
  ScheduleContentActions_InterviewsFragment,
} from 'src/generated/mloop-graphql';

import useWorkdayEnabled from 'src/hooks/atsService/useWorkdayEnabled';
import useHistory from 'src/hooks/useHistory';

import { ScheduleState } from 'src/types/schedule';

import getRescheduleFlowUrl from 'src/urls/getRescheduleFlowUrl';
import getUpdateFlowUrl from 'src/urls/getUpdateFlowUrl';

// eslint-disable-next-line modernloop/restrict-imports.cjs
import { useCalendarPermissionData } from 'src/views-new/Common/CalendarPermissionDialog/useCalendarPermissionData';
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { ScheduleFlowType } from 'src/views-new/ScheduleFlow/Steps/Schedule/types';
// eslint-disable-next-line modernloop/restrict-imports.cjs
import { SidePanelManager } from 'src/views-new/sidepanel/common/SidePanelManager';

import AvailabilityCloseAlertModal from './AvailabilityCloseAlertModal';
import CalendarPermissionDialog from './CalendarPermissionDialog';
import CancelScheduleDialog from './CancelScheduleDialog';
import SelfScheduledApplicationStageAlert from './SelfScheduleApplicationStageAlert';

type Props = {
  applicationStatus: ApplicationStatus;
};

type Fragments = {
  applicationStage: ScheduleContentActions_ApplicationStageFragment;
  interviews: ScheduleContentActions_InterviewsFragment[];
};

const ScheduleContentActions: FCWithFragments<Fragments, Props> = ({
  applicationStage,
  applicationStatus,
  interviews,
}) => {
  const history = useHistory();
  const [showCancelScheduleDialog, setShowCancelScheduleDialog] = useState(false);
  const [showCalendarPermissionDialog, setShowCalendarPermissionDialog] = useState(false);
  const [showAvailabilityRequestAlert, setShowAvailabilityRequestAlert] = useState(false);
  const [showSelfScheduleAlert, setShowSelfScheduleAlert] = useState(false);
  const [scheduleFlowType, setScheduleFlowType] = useState<ScheduleFlowType>();

  const addInterviewToCompletedScheduleEnabled = useFlag('org_add_interview_to_completed_schedule');

  const calendarDisplayData = useCalendarPermissionData({ applicationStageId: applicationStage.id });

  const isWorkdayEnabled = useWorkdayEnabled();

  const checkPermission = useCallback(() => {
    if (!calendarDisplayData.hasPermission) {
      setShowCalendarPermissionDialog(true);
      return false;
    }
    return true;
  }, [calendarDisplayData.hasPermission]);

  const checkAvailabilityRequest = useCallback(
    (flowType: ScheduleFlowType) => {
      if (applicationStage.task?.availabilityRequests?.length && applicationStage.task?.availabilityRequests[0].id) {
        setScheduleFlowType(flowType);
        setShowAvailabilityRequestAlert(true);
        return true;
      }
      return false;
    },
    [applicationStage.task?.availabilityRequests]
  );

  const checkSelfSchedule = useCallback(
    (flowType: ScheduleFlowType) => {
      if (!applicationStage.selfScheduleOptionsId) return false;
      setScheduleFlowType(flowType);
      setShowSelfScheduleAlert(true);
      return true;
    },
    [applicationStage.selfScheduleOptionsId]
  );

  const handleUpdate = useCallback(() => {
    const updateUrl = getUpdateFlowUrl(applicationStage.id);
    history.push(updateUrl);
    SidePanelManager.closeSidePanel();
  }, [applicationStage.id, history]);

  const checkAndHandleUpdate = useCallback(() => {
    if (!checkPermission()) return;
    if (checkAvailabilityRequest(ScheduleFlowType.UPDATE)) return;
    if (checkSelfSchedule(ScheduleFlowType.UPDATE)) return;

    handleUpdate();
  }, [checkAvailabilityRequest, checkPermission, checkSelfSchedule, handleUpdate]);

  const handleReschedule = useCallback(() => {
    const rescheduleUrl = getRescheduleFlowUrl(applicationStage.id);
    history.push(rescheduleUrl);
    SidePanelManager.closeSidePanel();
  }, [applicationStage.id, history]);

  const checkAndHandleReschedule = useCallback(() => {
    if (!checkPermission()) return;
    if (checkAvailabilityRequest(ScheduleFlowType.RESCHEDULE)) return;
    if (checkSelfSchedule(ScheduleFlowType.RESCHEDULE)) return;

    handleReschedule();
  }, [checkAvailabilityRequest, checkPermission, checkSelfSchedule, handleReschedule]);

  const handleAddInterview = useCallback(() => {
    if (!checkPermission()) return;
    const updateUrl = getUpdateFlowUrl(applicationStage.id, { addNewInterview: true });
    history.push(updateUrl);
    SidePanelManager.closeSidePanel();
  }, [applicationStage.id, checkPermission, history]);

  const handleCancelSchedule = useCallback(() => {
    if (!checkPermission()) return;

    setShowCancelScheduleDialog(true);
  }, [checkPermission]);

  const upcomingInterviews: ScheduleContentActions_InterviewsFragment[] = useMemo(
    () =>
      interviews
        ?.filter((schedule) => isAfterNow(schedule.endAt))
        ?.sort((a, b) => {
          if (!a || !b) return 0;
          if (isAfter(a.startAt, b.startAt)) {
            return 1;
          }
          return -1;
        }) ?? [],

    [interviews]
  );

  const scheduleState = useMemo(() => {
    if (upcomingInterviews.length === 0) {
      return ScheduleState.COMPLETED;
    }

    let status = ScheduleState.UPCOMING;

    upcomingInterviews?.forEach((interview) => {
      if (
        interview &&
        isWithinInterval(getCurrentDateIso(), {
          start: interview.startAt,
          end: interview.endAt,
        })
      ) {
        status = ScheduleState.IN_PROGRESS;
      }
    });
    return status as ScheduleState;
  }, [upcomingInterviews]);

  const actionButtons = useMemo(() => {
    if (applicationStatus === ApplicationStatus.Archived) {
      return null;
    }

    const addInterviewButton = addInterviewToCompletedScheduleEnabled && !applicationStage.frozenAt && (
      <Button
        key="schedule-content-actions-add-interview-button"
        data-testid="schedule-content-actions-add-interview-button"
        size="small"
        startIcon={<PlusIcon />}
        onClick={handleAddInterview}
      >
        Add interview
      </Button>
    );

    switch (scheduleState) {
      case ScheduleState.UPCOMING: {
        if (applicationStage.frozenAt && isWorkdayEnabled) {
          return [
            <Button
              key="schedule-content-actions-cancel-button"
              data-testid="schedule-content-actions-cancel-button"
              size="small"
              onClick={handleCancelSchedule}
            >
              Cancel
            </Button>,
          ];
        }
        return [
          <Button
            key="schedule-content-actions-update-button"
            data-testid="schedule-content-actions-update-button"
            size="small"
            onClick={checkAndHandleUpdate}
          >
            Update
          </Button>,
          <Button
            key="schedule-content-actions-reschedule-button"
            data-testid="schedule-content-actions-reschedule-button"
            size="small"
            onClick={checkAndHandleReschedule}
          >
            Reschedule
          </Button>,
          <Button
            key="schedule-content-actions-cancel-button"
            data-testid="schedule-content-actions-cancel-button"
            size="small"
            onClick={handleCancelSchedule}
          >
            Cancel
          </Button>,
        ];
      }
      case ScheduleState.COMPLETED: {
        return [
          addInterviewButton,
          <Button
            key="schedule-content-actions-cancel-button"
            data-testid="schedule-content-actions-cancel-button"
            size="small"
            onClick={handleCancelSchedule}
          >
            Mark canceled
          </Button>,
        ];
      }
      case ScheduleState.IN_PROGRESS: {
        return [
          addInterviewButton,
          <Button
            key="schedule-content-actions-cancel-button"
            data-testid="schedule-content-actions-cancel-button"
            size="small"
            onClick={handleCancelSchedule}
          >
            Cancel
          </Button>,
        ];
      }
      default:
        return null;
    }
  }, [
    addInterviewToCompletedScheduleEnabled,
    applicationStage.frozenAt,
    applicationStatus,
    checkAndHandleReschedule,
    checkAndHandleUpdate,
    handleAddInterview,
    handleCancelSchedule,
    isWorkdayEnabled,
    scheduleState,
  ]);

  return (
    <Stack direction="row" gap={1} px={2.5} py={1.5}>
      {actionButtons}
      {showCancelScheduleDialog && (
        <CancelScheduleDialog
          applicationStage={applicationStage}
          interviews={interviews}
          onSuccess={() => setShowCancelScheduleDialog(false)}
          onError={() => setShowCancelScheduleDialog(false)}
          onClose={() => setShowCancelScheduleDialog(false)}
        />
      )}

      {showCalendarPermissionDialog && (
        <CalendarPermissionDialog
          fullName={calendarDisplayData.scheduler?.fullName || ''}
          imageUrl={calendarDisplayData.scheduler?.slackImageUrl || ''}
          email={calendarDisplayData.scheduler?.email || ''}
          candidateCalendarLabel={calendarDisplayData.candidateCalendar}
          candidateId={calendarDisplayData.candidateId}
          candidateEventCalendarId={calendarDisplayData.candidateEventCalendarId}
          interviewerEventCalendarId={calendarDisplayData.interviewerEventCalendarId}
          candidateCalendarFound={calendarDisplayData.candidateCalendarFound}
          interviewerCalendarLabel={calendarDisplayData.interviewerCalendar}
          interviewerCalendarFound={calendarDisplayData.interviewerCalendarFound}
          onClose={() => setShowCalendarPermissionDialog(false)}
        />
      )}

      {showSelfScheduleAlert && (
        <SelfScheduledApplicationStageAlert
          onClose={() => setShowSelfScheduleAlert(false)}
          onContinue={() => {
            if (scheduleFlowType === ScheduleFlowType.UPDATE) {
              handleUpdate();
            } else if (scheduleFlowType === ScheduleFlowType.RESCHEDULE) {
              handleReschedule();
            }
            setScheduleFlowType(undefined);
          }}
        />
      )}

      {showAvailabilityRequestAlert && (
        <AvailabilityCloseAlertModal
          onClose={() => setShowAvailabilityRequestAlert(false)}
          onContinue={() => {
            if (scheduleFlowType === ScheduleFlowType.UPDATE) {
              handleUpdate();
            } else if (scheduleFlowType === ScheduleFlowType.RESCHEDULE) {
              handleReschedule();
            }
            setScheduleFlowType(undefined);
          }}
        />
      )}
    </Stack>
  );
};

ScheduleContentActions.fragments = {
  applicationStage: gql`
    ${CancelScheduleDialog.fragments.applicationStage}
    fragment ScheduleContentActions_applicationStage on ApplicationStage {
      id
      selfScheduleOptionsId
      frozenAt
      task {
        id
        availabilityRequests {
          id
        }
      }
      ...CancelScheduleDialog_applicationStage
    }
  `,
  interviews: gql`
    ${CancelScheduleDialog.fragments.interviews}
    fragment ScheduleContentActions_interviews on ApplicationStageInterview {
      id
      startAt
      endAt
      ...CancelScheduleDialog_interviews
    }
  `,
};

export default ScheduleContentActions;
